









import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { mall } from '@/utils/mall';
import { Mall } from 'ncp-api-supporter';
import { Getter } from 'vuex-class';
import { ClaimType } from '@/types/claim';
import { claimTypes } from '@/const/claim';
import { ClaimParams } from '@/components/shipping/claim/searchOptions.type';

@Component
export default class DelayCount extends Vue {
  @Getter('mall/getMalls')
  private malls: Mall[];

  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  @Prop({ required: true })
  private readonly mallNo!: string;

  @Prop({ required: true })
  private readonly isDelayClicked!: boolean;

  @PropSync('params')
  private paramsSync!: ClaimParams;

  private delayTitleMap = {
    [claimTypes.CANCEL]: this.$t('CLAIM.ALL_LIST.CANCEL_DELAY'),
    [claimTypes.EXCHANGE]: this.$t('CLAIM.ALL_LIST.EXCHANGE_DELAY'),
    [claimTypes.RETURN]: this.$t('CLAIM.ALL_LIST.RETURN_DELAY'),
  };

  private delayedClaimCount = 0;

  @Watch('mallNo')
  resetCount() {
    this.fetchDelayClaims();
  }

  created() {
    this.fetchDelayClaims();
  }

  public async fetchDelayClaims() {
    const mallNos = this.mallNo === '' ? mall.allMallNoString : this.mallNo;
    const { data: delayedClaims } = await this.$api.getClaimsSummaryDelay({
      params: {
        mallNos,
        period: '3',
        unit: 'MONTH',
      },
    });

    const setDelayClaimMap = {
      [claimTypes.CANCEL]: () => (this.delayedClaimCount = delayedClaims.cancelDelayCount),
      [claimTypes.EXCHANGE]: () => (this.delayedClaimCount = delayedClaims.exchangeDelayCount),
      [claimTypes.RETURN]: () => (this.delayedClaimCount = delayedClaims.returnDelayCount),
    };

    setDelayClaimMap[this.claimType]();
  }

  private onClickDelayCount() {
    this.$emit('clickDelayCount');
  }
}
