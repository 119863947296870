








import { Vue, Component, Prop } from 'vue-property-decorator';
import { sendQueryString } from '@/utils/query';
// SearchForm 에서 가공된 requests 를 가공하여 query string 으로 전달
@Component
export default class SearchButton extends Vue {
  // 불특정 파인더(SearchForm)로부터 전달되는 params
  @Prop({ required: true })
  private readonly params!: {};
  @Prop({ required: false, default: () => () => true })
  private readonly validate!: () => boolean;
  @Prop({ required: false, default: false })
  private readonly resetStringQuery!: boolean;
  @Prop({ required: false, default: true })
  private readonly deleteEmptyQuery!: boolean;
  @Prop({ required: false, default: false })
  private readonly duplicateQueryUpdate!: boolean;
  @Prop({ default: true })
  private readonly showResetButton!: boolean;

  public sendQueryUrl(): void {
    // this.validate() 실패시 동작은 this.validate() 안에서 따로 처리하세요.
    if (this.validate()) sendQueryString(this, { ...this.params }, this.deleteEmptyQuery, this.duplicateQueryUpdate);
    this.$emit('search');
  }

  private reset(): void {
    this.resetStringQuery && this.sendQueryUrl();
    this.$emit('reset');
  }
}
