























































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import Grid from '@/components/common/grid/Main.vue';
import { throwPopup } from '@/helpers/popup';
import { range1ToNI18n } from '@/utils/common';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { ProductsSearchContent, PostMarketingProductSettingRequest } from 'ncp-api-supporter';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import copy from 'copy-to-clipboard';
import { addMonth, getToday } from '@/utils/dateFormat';
import { DEFAULT_DATE_RANGE, DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import SearchKeyword from '@/components/marketing/configuration/SearchKeyword.vue';
import { Row } from '@/types/tui-grid';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import {
  gridProps,
  searchKeywordOptions,
  requestParam,
  datePickerOptions,
  SINGLE_ITEM_SELECT_LENGTH,
  THREE_MONTH,
} from '@/views/contents/marketing/naverShopping/naverShoppingProductConfig';
import { MARKETING_TYPE } from '@/const/contents/product';
import { GridEventProps, NaverMarketingData, NullableNaverShoppingEP } from '@/types';
import { defaultNaverShoppingEPInfo } from '@/views/contents/product/basic/ProductAdd';
import { isEmpty } from 'underscore';

@Component({ components: { NoticeBox, MallSelect, SelectBox, SearchButton, Grid, DateRangePicker, SearchKeyword } })
export default class NaverShoppingProductConfig extends Vue {
  @Ref()
  private readonly grid!: Grid;
  @Ref()
  private readonly searchKeywordInput: SearchKeyword;
  @Watch('$route.query')
  private searchProducts() {
    this.fetchMarketingProducts();
  }

  private readonly noticeList = range1ToNI18n(5, 'MARKETING.NAVER_SHOPPING.PRODUCT_EP_GUIDE_');

  private gridProps = gridProps();
  private requestParam = requestParam;
  private datePickerOptions = datePickerOptions;
  private searchKeywordOptions = searchKeywordOptions;
  private naverShoppingEP: NullableNaverShoppingEP = defaultNaverShoppingEPInfo().naverShoppingEP;

  private mallNo = Number(this.$route.query?.mallNo ?? 0);
  private productContents: ProductsSearchContent[] = [];
  private totalCount = 0;
  private startYmdt = addMonth(new Date(), -THREE_MONTH); //NOTE 3개월 전 디폴트
  private endYmdt = getToday();
  private searchKeywords = '';

  private isUsableNaverShopping = false;

  private async fetchMarketingProducts() {
    if (!this.mallNo) {
      alert(this.$t(`MARKETING.CONFIGURATION.PLEASE_SELECT`));
      return;
    }
    this.convertRequest();
    const { data: productsContent } = await this.$api.postProductsSearch(this.requestParam);
    this.productContents = productsContent.contents;
    this.totalCount = productsContent.totalCount;
  }

  private fetchPreviewDBUrl = (mallNo: number, type: 'allEp' | 'salesIndex'): string => {
    const { VUE_APP_NAVER_EP_STORAGE_URI, VUE_APP_NAVER_EP_SELL_COUNT_STORAGE_URI } = process.env;
    return type === 'allEp'
      ? `${VUE_APP_NAVER_EP_STORAGE_URI}/${mallNo}/all_ep.txt`
      : `${VUE_APP_NAVER_EP_SELL_COUNT_STORAGE_URI}/${mallNo}/sales_index_ep.txt`;
  };
  private async createLayerPopup({ data, settings }: NaverMarketingData) {
    const { state } = await throwPopup({
      name: 'ModifyNaverShoppingEPInfoLayerPopup',
      data: { data, settings, mallNo: this.mallNo },
    });

    if (state === 'confirm') {
      this.fetchMarketingProducts();
      return;
    }
  }

  private convertRequest() {
    this.requestParam.data.keywordInfo.keywords = this.searchKeywords.split(',');

    if (this.$route.query?.size && this.$route.query?.page) {
      this.requestParam.data.size = Number(this.$route.query.size);
      this.requestParam.data.page = Number(this.$route.query.page);
    }

    this.requestParam.data.mallNos = [Number(this.mallNo)];
    //전체 기간 선택
    if (!this.startYmdt && !this.endYmdt) {
      this.requestParam.data.periodInfo.period = {
        startYmdt: DEFAULT_DATE_RANGE.MIN + ' ' + DEFAULT_TIME_RANGE.START, //1900-01-01 00:00:00
        endYmdt: DEFAULT_DATE_RANGE.MAX + ' ' + DEFAULT_TIME_RANGE.END, //2999-12-31 23:59:59
      };
      return;
    }
    //특정 기간 선택
    this.requestParam.data.periodInfo.period.startYmdt = this.startYmdt + ` ${DEFAULT_TIME_RANGE.START}`;
    this.requestParam.data.periodInfo.period.endYmdt = this.endYmdt + ` ${DEFAULT_TIME_RANGE.END}`;
  }
  private resetQuery() {
    this.requestParam.data.keywordInfo.type = 'PRODUCT_NAME';
    this.requestParam.data.keywordInfo.keywords = null;
    this.requestParam.data.mallNos = [];
    this.requestParam.data.periodInfo.type = 'REGISTER_DATE';
    this.searchKeywordInput.reset();
    this.searchKeywords = '';
    this.mallNo = 0;
    this.startYmdt = addMonth(new Date(), -THREE_MONTH); //NOTE 3개월 전 디폴트
    this.endYmdt = getToday();
  }

  private handleDownloadDBUrlClick(type: 'allEp' | 'salesIndex') {
    if (!this.mallNo) {
      alert(this.$t(`MARKETING.CONFIGURATION.PLEASE_SELECT`));
      return;
    }
    window.open(this.fetchPreviewDBUrl(this.mallNo, type), '_blank');
  }

  private async handleGridClick({ rowKey, columnName }: GridEventProps) {
    const rowData: Row = this.grid.getRowAt(rowKey);
    const settings = [
      {
        productNo: Number(rowData.mallProductNo),
        displayable: rowData.marketingSettings[0]?.displayable ?? false, // 빈배열의 경우 미노출로 처리
      },
    ];
    switch (columnName) {
      case 'mallProductNo':
        openEditProductWindow(Number(rowData.mallProductNo));
        break;
      case 'additionalInfo':
        this.createLayerPopup({ data: rowData.marketingSettings[0]?.additionalInfo, settings });
        break;
    }
  }

  private async changeMall() {
    const { data } = await this.$api.getProductsConfigurationNaverShopping({
      params: {
        mallNo: this.mallNo,
      },
    });

    this.isUsableNaverShopping = data?.supportsNaverShopping ?? false;
  }

  private handleCopyDBUrlClick() {
    if (!this.mallNo) {
      alert(this.$t(`MARKETING.CONFIGURATION.PLEASE_SELECT`));
      return;
    }
    copy(this.fetchPreviewDBUrl(this.mallNo, 'allEp'));
    const copyContentName = '전체상품 DB URL';
    alert(this.$t('ALERT_COPY_TO_CLIPBOARD', { copyContentName }));
  }
  private async handleBulkModifyClick() {
    const checkedRows: Row[] = this.grid.getCheckedRows();
    if (!checkedRows?.length) {
      alert(this.$t(`MARKETING.CONFIGURATION.PLEASE_CHECK`));
      return false;
    }
    const settings = checkedRows.map(({ mallProductNo, marketingSettings }) => {
      return { productNo: Number(mallProductNo), displayable: marketingSettings[0]?.displayable ?? false }; // 빈배열의 경우 미노출로 처리
    });
    if (checkedRows.length === SINGLE_ITEM_SELECT_LENGTH) {
      this.createLayerPopup({ data: checkedRows[0].marketingSettings[0]?.additionalInfo, settings });
      return;
    }
    this.createLayerPopup({ data: null, settings });
  }
  private async handleDisplayableClick(displayable: boolean) {
    const checkedRows: Row[] = this.grid.getCheckedRows();
    if (!checkedRows?.length) {
      alert(this.$t(`MARKETING.CONFIGURATION.PLEASE_CHECK`));
      return false;
    }
    const checkedProductsSettings = checkedRows.map(product => {
      const additionalInfo = isEmpty(product.marketingSettings[0]?.additionalInfo)
        ? this.naverShoppingEP
        : product.marketingSettings[0]?.additionalInfo;
      //NOTE: 신규 개발건이라, 기존 상품은 AdditionalInfo가 null이기에, null일경우 default값을 넣어달라 요청
      return {
        productNo: Number(product.mallProductNo),
        displayable,
        additionalInfo,
      };
    });

    const request: PostMarketingProductSettingRequest = {
      data: {
        mallNo: Number(this.mallNo),
        channelType: MARKETING_TYPE.NAVER_SHOPPING,
        settings: checkedProductsSettings,
      },
    };
    this.$api
      .postMarketingProductSetting(request)
      .then(() => {
        displayable
          ? alert(
              this.$t('MARKETING.NAVER_SHOPPING.MODIFY_BULK_NAVER_SHOPPING_DISPLAYABLE_SUCCESS_MESSAGE', {
                count: checkedRows.length,
              }),
            )
          : alert(
              this.$t('MARKETING.NAVER_SHOPPING.MODIFY_BULK_NAVER_SHOPPING_NOT_DISPLAYABLE_SUCCESS_MESSAGE', {
                count: checkedRows.length,
              }),
            );
      })
      .catch(() => {
        displayable
          ? alert(
              this.$t('MARKETING.NAVER_SHOPPING.MODIFY_BULK_NAVER_SHOPPING_DISPLAYABLE_FAIL_MESSAGE', {
                count: checkedRows.length,
              }),
            )
          : alert(
              this.$t('MARKETING.NAVER_SHOPPING.MODIFY_BULK_NAVER_SHOPPING_NOT_DISPLAYABLE_FAIL_MESSAGE', {
                count: checkedRows.length,
              }),
            );
      })
      .finally(() => this.fetchMarketingProducts());
  }
}
