























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({
  components: { TextInput },
})
export default class EmailInput extends Vue {
  @Prop({ default: '' })
  private value!: string;
  @Prop({ default: false })
  private disabled!: boolean;
  @Prop({ default: () => ({ id: 'no-part-special', domain: 'no-part-special' }) })
  private validType!: { id: string; domain: string };
  @Prop({ default: () => ({ id: '120px', domain: '150px' }) })
  private width!: { id: string; domain: string };
  @Prop() private wrapStyle;

  private id = '';
  private domain = '';

  @Watch('id')
  @Watch('domain')
  private changeEmail(): void {
    if (this.id || this.domain) {
      this.$emit('input', `${this.id}@${this.domain}`);
    } else {
      this.$emit('input', '');
    }
  }

  @Watch('value')
  private setValue(): void {
    if (this.value !== `${this.id}&${this.domain}`) {
      const arr = this.value?.split('@');
      if (arr[0]) {
        this.id = arr[0];
      }
      if (arr[1]) {
        this.domain = arr[1];
      }
    }
  }

  created() {
    this.setValue();
  }

  public focus(inputName?: string): void {
    if (inputName === 'id') {
      (this.$refs.emailId as HTMLElement).focus();
    } else if (inputName === 'domain') {
      (this.$refs.emailDomain as HTMLElement).focus();
    } else {
      if (!this.id) {
        (this.$refs.emailId as HTMLElement).focus();
      } else if (!this.domain) {
        (this.$refs.emailDomain as HTMLElement).focus();
      } else {
        (this.$refs.emailId as HTMLElement).focus();
      }
    }
  }
}
