export default Object.freeze({
  // COMMON_TREE_
  ALERT_DEPTH_IMPOSSIBLE: '최대 {depth}단계까지 가능합니다.',
  ALERT_HTML_NOT_ALLOW: 'html 태그는 입력할 수 없습니다.',
  ALERT_NOT_SAVED: '저장되지 않은 내용이 있습니다. 화면을 이동하시겠습니까?',
  ALERT_NOT_FOUND_ITEM: '삭제할 아이템이 없습니다.',
  CONFIRM_REMOVE: '선택한 {name}를 삭제 하시겠습니까?',
  ALERT_REMOVE_FAIL: '해당 {name} 삭제가 불가합니다.',
  ALERT_NO_ITEM: '{name}를 먼저 생성해주세요',
  ALERT_NO_NODE: '좌측에서 {name}를 생성하시면, {name} 별 상세 설정이 가능합니다.',
});
