import { Article, ArticleListQuery } from '@/types/board';
import { getToday, addDay } from '@/utils/dateFormat';
import store from '@/store';
import { GridProp, DefaultGridContainerOption } from '@/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils'; //getDeleteHTML
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { RadioBoxGroupOption } from '@/helpers/type';
import { Row } from '@/types/tui-grid/store/data';
import { ellipsisWithIconRenderer } from '@/utils/grid/rendererUtils';

export const searchType = [
  { name: 'BOARD.BASIC.ARTICLE.SEARCH_TYPE_ALL', value: 'ALL' }, //todo
  { name: 'BOARD.BASIC.ARTICLE.SEARCH_TYPE_TITLE', value: 'TITLE' }, //제목
  { name: 'BOARD.BASIC.ARTICLE.SEARCH_TYPE_CONTENT', value: 'CONTENT' }, //내용
  { name: 'BOARD.BASIC.ARTICLE.SEARCH_TYPE_WRITER', value: 'WRITER' }, //작성자
];

export const getArticleListInitQuery = (): ArticleListQuery => {
  const malls = store.getters['mall/getMalls'];
  const startdt = addDay(new Date(), -7) + ' ' + DEFAULT_TIME_RANGE.START;
  const enddt = getToday() + ' ' + DEFAULT_TIME_RANGE.END;
  return {
    mallNo: malls && malls[malls.length - 1] && malls[malls.length - 1].mallNo ? malls[malls.length - 1].mallNo : -1,
    boardNo: -1,
    searchType: searchType[0].value,
    keyword: '',
    noticed: null,
    startYmdt: addDay(new Date(), -7),
    endYmdt: getToday(),
    startDateTime: startdt,
    endDateTime: enddt,
    page: 1,
    pageSize: 30,
  };
};
export const displayOptions: DefaultGridContainerOption = {
  //showsRightArea: false,
  hasExcelDownloadButton: false,
  hasSettingButton: false,
};
export const articleOpenedOption: RadioBoxGroupOption<string> = {
  name: 'articleOpened',
  data: [
    { id: 'public', value: 'true', display: 'BOARD.BASIC.ARTICLE.OPENED_TRUE' },
    { id: 'private', value: 'false', display: 'BOARD.BASIC.ARTICLE.OPENED_FALSE' },
  ],
};

export function getOperator(row: Row, category: string, type: string): string {
  const author = row[`${category}`];
  let authName = '';
  if (!author) {
    return authName;
  }
  switch (author['type']) {
    case 'MEMBER': {
      // member 회원
      const member = author['memberInfo'];
      if (member && member['status'] === 'WITHDRAWN') {
        authName = window.$t('BOARD.BASIC.ARTICLE.EXIT_MEMBER');
        // 탈퇴회원
      } else {
        //회원,휴면회원..
        const memberId = member ? member['id'] : '';
        authName = `${memberId} (${author['no']})`;
        if (type == 'grid') {
          authName = getAnchorHTML(authName);
        }
      }
      break;
    }
    case 'ADMIN': {
      // operator 운영자
      authName = `${author['name']}`;
      break;
    }
    case 'GUEST': {
      // Non-member 비회원
      const nonMember = window.$t('BOARD.BASIC.ARTICLE.NONMEMBER');
      //비회원
      authName = `${author['name']}(${nonMember})`;
      break;
    }
  }

  return authName;
}

export const getArticleListGridOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'No.',
        name: 'index',
        align: 'center',
        minWidth: 60,
        formatter: data => {
          return data.row.noticed
            ? '<div id="article_list"><div class="smsTag type-black">' +
                window.$t('BOARD.BASIC.ARTICLE.NOTICE') +
                '</div></div>'
            : data.value.toString();
        },
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_NO'), //게시글 번호
        name: 'no',
        align: 'center',
        minWidth: 80,
        formatter: ({ value }) => (value ? getAnchorHTML(value.toString()) : ''),
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_TITLE'), //제목
        name: 'title',
        align: 'left',
        minWidth: 200,
        ellipsis: true,
        resizable: true,
        //formatter: ({ value }) =>
        //  value ? getAnchorHTML(value.toString()) + '  <span class="ico-attachment">첨부파일</span>' : '',
        renderer: props => {
          const rowData = props.grid.getRow(props.rowKey);
          if (rowData.hasAttachment) {
            props.iconClass = 'ico-attachment';
          }
          return ellipsisWithIconRenderer(props);
        },
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_REPLY'), // 답글
        name: 'reply',
        align: 'center',
        minWidth: 60,
        //formatter: (data) => (data.value ? getAnchorHTML('답글') : ''),
        formatter: data => {
          let res = '';
          if (data.row.replyUsed && !data.row.deleted) {
            res = getAnchorHTML(window.$t('BOARD.BASIC.ARTICLE.CONTENT_REPLY'));
          }
          return res;
        },
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_REPLY_COUNT'), // 답글수
        name: 'replyCount',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => (value ? getAnchorHTML(value.toString()) : '0'),
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_OPENED'), // 공개여부:공개, 비공개, 삭제(public, private, delete)
        name: 'opened',
        align: 'center',
        minWidth: 80,
        formatter: data => {
          let res = '';
          const deleted = data.row.deleted;
          if (deleted) {
            res = window.$t('BOARD.BASIC.ARTICLE.ARTICLE_DELETE'); //'삭제';
          } else {
            //'공개'/'비공개';
            res = data.value
              ? window.$t('BOARD.BASIC.ARTICLE.ARTICLE_PUBLIC')
              : window.$t('BOARD.BASIC.ARTICLE.ARTICLE_PRIVATE');
          }
          return res;
        },
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_SECRETED'), // 비밀글
        name: 'secreted',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => (value ? 'Y' : 'N'),
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_READ_COUNT'), // 조회수
        name: 'readCount',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => (value ? value.toString() : '0'),
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_WRITER'), // 작성자
        name: 'writer',
        align: 'center',
        minWidth: 120,
        formatter: data => getOperator(data.row, 'writer', 'grid'),
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_CREATION_DATE_TIME'), // 작성일시
        name: 'creationDateTime',
        align: 'center',
        minWidth: 130,
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_EDITOR'), // 최종수정자
        name: 'editor',
        align: 'center',
        minWidth: 120,
        formatter: data => getOperator(data.row, 'editor', 'grid'),
      },
      {
        header: window.$t('BOARD.BASIC.ARTICLE.HEADER_MODIFICATION_DATE_TIME'), // 최종수정일시
        name: 'modificationDateTime',
        align: 'center',
        minWidth: 130,
        formatter: ({ value }) => (value ? value.toString() : ''),
      },
    ],
    options: {
      // keyColumnName: 'no',
      rowHeaders: ['checkbox'],
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 10,
      },
    },
  };
};

export const getArticleInit = (): Article => {
  return {
    title: '',
    mallNo: 0,
    boardNo: -1,
    no: 0,
    opened: true,
    noticed: false,
    secreted: false,
    files: [],
    content: '',
    orderFixed: false,
    readCount: 0,
    writer: { name: '', type: '', no: 0 },
    writerName: '',
    creationDateTime: '',
    parentTitle: '',
    parentContent: '',
    //categoryNo: null,
  };
};

/**
 * get creator no of a article
 */
export function getMemberNo(row: Row, category: string): string | null {
  let memberNo: string | null = null;
  const author = row[`${category}`];

  if (author && author['type'] === 'MEMBER') {
    const member = author['memberInfo'];
    if (member && member['status'] !== 'WITHDRAWN' && author['no']) {
      memberNo = `${author['no']}`;
    }
  }

  return memberNo;
}

export const ExtensionType = ['.jpg', '.jpeg', '.gif', '.png', '.bmp'];
export const MAX_ADDABLE_SIZE = 10;
