

















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import AccountManageThumb from '@/components/marketing/configuration/AccountManageThumb.vue';
import { TranslateResult } from 'vue-i18n';
import {
  CampaignsAccountByMerchantAccountIssues,
  PostCampaignsVerifyDomainRequest,
  PostCampaignsSettingsRequest,
} from 'ncp-api-supporter';
import { getCurrentMallNo } from '@/utils/mall';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { throwWindowPopup, throwPopup, PopupClose } from '@/helpers/popup';
import { getCampaigns } from '@/views/contents/marketing/googleAdConfig';
@Component({
  components: {
    AccountManageThumb,
    ToolTip,
  },
})
export default class MerchantCenterAccountInfo extends Vue {
  private merchantId = '';
  private websiteClaimed: TranslateResult = '';
  private reClaimed!: boolean;
  private accountIssues: CampaignsAccountByMerchantAccountIssues[] = [];
  private issuePending = true;

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  private get mallNoRequest() {
    const request = {
      params: {
        mallNo: this.mallNo,
      },
    };

    return request;
  }

  @Watch('$route.query.mallNo')
  private async changeMallNoQuery(): Promise<void> {
    const result = await getCampaigns(this.mallNo);

    if (result?.getCampaigns) this.fetchMerchantId();
  }

  created(): void {
    this.fetchMerchantId();
  }

  private async fetchMerchantId(): Promise<void> {
    const {
      data: { merchantId, websiteClaimed, accountIssues },
    } = await this.$api.getCampaignsAccountByMerchant(this.mallNoRequest);

    this.merchantId = merchantId;
    this.reClaimed = websiteClaimed;
    this.websiteClaimed = websiteClaimed
      ? this.$t('MARKETING.CONFIGURATION.OWNERSHIP_SUCCESS')
      : this.$t('MARKETING.CONFIGURATION.OWNERSHIP_FALSE');
    this.accountIssues = accountIssues.map(issue => {
      if (issue.detail === '') issue.detail = '-';
      return issue;
    });
    this.issuePending = false;
  }

  private async postReclaimWebsite() {
    const { data } = await this.$api.postCampaignsAccountReclaimWebsite(this.mallNoRequest);

    if (data[0].ssl === false || data[1].ssl === false) {
      this.openReclaimPopup();
      return false;
    }

    if (this.reClaimed) {
      await this.$api.postCampaignsAccountReclaimWebsite(this.mallNoRequest);

      alert('MARKETING.CONFIGURATION.RECLAIM_SUCCESS');
    } else {
      const request: PostCampaignsVerifyDomainRequest = {
        data: {
          channelType: 'Google',
          mallNo: this.mallNo,
        },
      };

      await this.$api.postCampaignsVerifyDomain(request);
      this.moveAccountManagement();
    }
  }

  private openReclaimPopup(): void {
    throwPopup({
      name: 'ReclaimWebsite',
    });
  }

  private async openSellerInformationPopup() {
    const { state } = await throwPopup({
      name: 'CampaignsRequirementsConfirmation',
      data: {
        mallNo: this.mallNo,
      },
    });
    if (state === PopupClose.CLOSE) return;
    this.postCampaignsSettings();
  }

  private async postCampaignsSettings() {
    const request: PostCampaignsSettingsRequest = {
      data: {
        mallNo: this.mallNo,
      },
    };

    try {
      await this.$api.postCampaignsSettings(request);
      this.moveAccountManagement();
    } catch (e) {
      if (e.code === 'DOMAIN_VERIFICATION_FAIL') this.retryVerifyDomain();
      console.error(e);
    }
  }

  private async moveAccountManagement() {
    alert(this.$t('MARKETING.GOOGLE_AD.ALERT_ADD_CODE'));
    const authResult = await throwPopup({
      name: 'SmsAuthentication',
      data: {
        mallNo: this.mallNo,
      },
    });

    if (authResult.state === PopupClose.CONFIRM) {
      this.$router.push({ name: 'AccountManage' });
      return;
    }
  }

  private retryVerifyDomain() {
    const retryVerifyDomain = confirm(this.$t('MARKETING.GOOGLE_AD.ALERT_ADD_CODE_FAIL').toString());
    if (retryVerifyDomain) {
      this.postCampaignsVerifyDomain();
      return;
    }
  }

  private async postCampaignsVerifyDomain() {
    const request: PostCampaignsVerifyDomainRequest = {
      data: {
        channelType: 'Google',
        mallNo: this.mallNo,
      },
    };
    try {
      await this.$api.postCampaignsVerifyDomain(request);
      this.moveAccountManagement();
    } catch (e) {
      this.retryVerifyDomain();
      console.error(e);
    }
  }

  private openUserManagePopup() {
    throwWindowPopup('MerchantUserManage', { mallNo: this.mallNo }, 'md');
  }
}
