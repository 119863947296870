




































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import Tree from '@/components/common/tree/Tree.vue';
import { NestedTreeNode, TreeOptions } from '@/types/tree';
import { namespace } from 'vuex-class';
import nodeElements from '@/views/contents/board/basic/tree/type/nodeElements';
import { EventKeyword } from '@/types/tree';

const tree = namespace('tree');
@Component({
  components: {
    Tree,
  },
})
export default class TreeContainer extends Vue {
  @Prop({ required: true })
  private readonly treeNodes!: NestedTreeNode[];
  @Prop({ default: 1 })
  private readonly defaultSelectNodeIndex!: number;
  @tree.Action('publishEvent')
  private readonly publishEvent: (eventKeyword: EventKeyword) => void;
  @Ref() tree!: Tree;
  private readonly nodeElements = nodeElements();

  private get options(): TreeOptions {
    return {
      internalNodeName: this.$t('CATEGORY').toString(),
      leafNodeName: this.$t('CATEGORY').toString(),
      defaultSelection: true,
      defaultSelectNodeIndex: this.defaultSelectNodeIndex,
      defaultNodeData: {
        children: [],
        used: true,
      },
      customKeywords: {
        nodeName: this.$t('PRODUCT.CATEGORIZATION.NEW').toString(),
        nodeLabel: this.$t('PRODUCT.CATEGORIZATION.NAME').toString(),
      },
    };
  }

  public getSelectedNodeId() {
    return this.tree.getSelectedNodeId();
  }

  public select(nodeId: string) {
    return this.tree.select(nodeId);
  }

  public getTreeData() {
    return this.tree.getTreeData();
  }

  public defaultTreeSelect(nodeIndex = 1, childIndex = 0) {
    return this.tree.defaultTreeSelect(nodeIndex, childIndex);
  }
}
