import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import AceAdmin from '@/views/contents/statistics/ace/AceAdmin.vue';
import AceRegistration from '@/views/contents/statistics/ace/AceRegistration.vue';
import Sales from '@/views/contents/statistics/sales/Sales.vue';
import PeriodSales from '@/views/contents/statistics/sales/PeriodSales.vue';
import ProductSales from '@/views/contents/statistics/sales/ProductSales.vue';
import CategorySales from '@/views/contents/statistics/sales/CategorySales.vue';
import Members from '@/views/contents/statistics/members/Members.vue';
import PeriodMembers from '@/views/contents/statistics/members/PeriodMembers.vue';

export default (): RouteConfig => {
  return {
    path: '/statistics',
    name: 'statistics',
    component: DefaultLayout,
    children: [
      {
        path: 'ace/admin',
        name: 'AceAdmin',
        component: AceAdmin,
      },
      {
        path: 'ace/registration',
        name: 'AceRegistration',
        component: AceRegistration,
      },
      {
        path: 'sales/main',
        name: 'Sales',
        component: Sales,
        redirect: { name: 'PeriodSales' },
        children: [
          {
            path: 'period-sales',
            name: 'PeriodSales',
            component: PeriodSales,
          },
          {
            path: 'product-sales',
            name: 'ProductSales',
            component: ProductSales,
          },
          {
            path: 'category-sales',
            name: 'CategorySales',
            component: CategorySales,
          },
        ],
      },
      {
        path: 'members/main',
        name: 'Members',
        component: Members,
        redirect: { name: 'PeriodMembers' },
        children: [
          {
            path: 'period-members',
            name: 'PeriodMembers',
            component: PeriodMembers,
          },
        ],
      },
    ],
  };
};
