import Vue from 'vue';
import Vuex, { ModuleTree } from 'vuex';
import * as root from './root';

Vue.use(Vuex);

const requireModules = require.context('./modules', false, /\.ts$/);

const modules = requireModules.keys().reduce((modules: ModuleTree<any>, fileName): object => {
  const name = (fileName.match(/([^./]+)\.ts$/) as RegExpMatchArray)[1];
  modules[name] = requireModules(fileName).default;
  return modules;
}, {});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules,
  ...root,
});
