export default Object.freeze({
  TITLE: '쿠폰적용',
  PRODUCT_TITLE: '상품쿠폰',
  PRODUCT_INFO: '상품정보',
  PRODUCT_PRICE: '상품금액',
  CHOSE_COUPON: '쿠폰선택',
  DISCOUNT_AMT: '할인금액',
  AMT_UNIT: '원',
  PRODUCT_NEED_ALL_TEXT: '적용안함',

  CART_TITLE: '장바구니쿠폰',
  CANCEL: '취소',
  ALERT: '올바르지 않은 접근입니다. 수기주문에서 열어주세요.',
  CONFIRM: '이 쿠폰 사용 시 상품쿠폰은 사용할 수 없습니다. 이 쿠폰을 사용하시겠습니까?',
});
