

































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { GridEventProps, QueryString, StatisticSynthesisPeriod } from '@/types';
import {
  GetStatisticsSalesPeriodContents,
  GetStatisticsSalesPeriodRequest,
  GetStatisticsSalesPeriodSummary,
  GetStatisticsSalesPeriodSummaryRequest,
} from 'ncp-api-supporter';
import { getDefaultParams, getSalesByDateGridProps } from '@/views/contents/statistics/sales/periodSales';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import Grid from '@/components/common/grid/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import ShippingChargeSearchForm from '@/views/contents/configuration/delivery/ShippingChargeSearchForm.vue';
import PeriodSalesForm from '@/views/contents/statistics/sales/PeriodSalesForm.vue';
import StatisticSynthesis from '@/components/statistics/sales/StatisticSynthesis.vue';
import StatisticNoticeBox from '@/components/statistics/StatisticNoticeBox.vue';
import { generateQuery, onCreateExcel } from '@/views/contents/statistics/sales/statisticUtils';

@Component({
  components: {
    Grid,
    SelectBox,
    TextInput,
    SearchButton,
    DateRangePicker,
    StatisticNoticeBox,
    PeriodSalesForm,
    ShippingChargeSearchForm,
    StatisticSynthesis,
  },
})
export default class PeriodSales extends Vue {
  @Ref()
  private readonly gridRef!: Grid;
  private resetNoDataMessage = true;

  private salesByDateGridProps = getSalesByDateGridProps();

  private salesByPeriodContentsPeriod: null | StatisticSynthesisPeriod = null;
  private salesByPeriodSummary: null | GetStatisticsSalesPeriodSummary['summary'] = null;
  private salesByDateContents: GetStatisticsSalesPeriodContents[] = [];
  private salesByDateTotalCount = 0;

  @Watch('$route.query')
  private updateQuery(query: QueryString) {
    if (!query?.mallNo) {
      this.setInitState();
      return;
    }
    if (!this.isValidQuery(query)) return;
    const newQuery = generateQuery(query, getDefaultParams);
    return Promise.all([
      this.fetchSalesPeriodStatisticsSummary(newQuery),
      this.fetchSalesPeriodStatistics(newQuery),
    ]).catch(error => error?.data?.code === 'V0004' && alert(error?.data?.message));
  }

  private isValidQuery(query: QueryString): boolean {
    if (Object.keys(query).length === 0) return false;
    if (!query?.payTypes) {
      alert(this.$t('CLAIM.MESSAGE.VALID_PAY_TYPE'));
      return false;
    }
    if (!query?.platformTypes) {
      alert(this.$t('CLAIM.MESSAGE.VALID_PLATFORM_TYPE'));
      return false;
    }
    return true;
  }

  // 기간별 판매현황 종합 fetch
  private async fetchSalesPeriodStatisticsSummary(queries) {
    const params = { ...queries } as GetStatisticsSalesPeriodSummaryRequest['params'];
    const res = await this.$api.getStatisticsSalesPeriodSummary({ params });

    const { summary } = res.data;
    const { startYmd, endYmd } = this.$route.query;
    this.salesByPeriodContentsPeriod = { startYmd: startYmd.toString(), endYmd: endYmd.toString() };
    this.salesByPeriodSummary = summary;
  }

  // 일자별 판매현황 fetch
  private async fetchSalesPeriodStatistics(queries) {
    const params = { ...queries } as GetStatisticsSalesPeriodRequest['params'];
    const res = await this.$api.getStatisticsSalesPeriod({ params });

    const { contents, totalCount } = res.data;

    this.salesByDateContents = contents;
    this.salesByDateTotalCount = totalCount;
    this.isInit = !this.$route.query?.mallNo;
    this.changeNoDataMessage();
  }

  private onItemClicked(gridEvent: GridEventProps) {
    const { ymd } = (gridEvent.instance as any).store.data.rawData[gridEvent.rowKey]; // eslint-disable-line

    switch (gridEvent.columnName) {
      case 'excelDownload':
        onCreateExcel('PERIOD', { ...this.$route.query, servicePlan: 'PRO' });
        break;
      case 'dailyExcelDownload':
        onCreateExcel('PERIOD', { ...this.$route.query, startYmd: ymd, endYmd: ymd, servicePlan: 'PRO' });
        break;
    }
  }

  private resetStatisticSynthesis(): void {
    this.salesByPeriodContentsPeriod = null;
    this.salesByPeriodSummary = null;
    this.salesByDateContents = [];
  }

  private isInit = true;
  private changeNoDataMessage() {
    const message = this.isInit ? 'STATISTIC.SALES.MUST_SEARCH' : 'NO_RESULT';
    this.$nextTick(() => this.gridRef.changeNoDataMessage(this.$t(message).toString()));
  }
  private setInitState() {
    this.isInit = true;
    this.changeNoDataMessage();
  }
  private created() {
    this.setInitState();
  }
}
