






















































































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import Editor from '@/components/common/summernote/Editor.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import NoticeCategorySelector from '@/components/partner/notice/NoticeCategorySelector.vue';

import { OptionData } from '@/helpers/type';
import { ManageNoticeCategoryType, PostNoticeRequest } from 'ncp-api-supporter';

import { ThrowInputValidationError } from '@/utils/validate';
import { getCategorySelectBox } from '@/views/top/partner/notice/PartnerNoticeList';
import FileAttacher, { FileInformation } from '@/components/common/input/fileInput/FileAttacher.vue';
import { getRadioGroup } from '@/views/popups/partner/PartnerNoticeForm';
import { i18nForPartnerNotice } from '@/views/contents/partner/notice/PartnerNoticeList';

export type NoticeForm = Omit<PostNoticeRequest['data'], 'mallNo' | 'noticeAttachFiles'> & {
  mallNo: number;
  noticeAttachFiles: FileInformation[];
  mall?: string;
  writer?: string;
  createdDateTime?: string;
  noticeNo?: number;
  readCount?: number;
};

@Component({
  components: {
    FileAttacher,
    TextInput,
    SelectBox,
    RadioGroup,
    MallSelect,
    Editor,
    FileInput,
    NoticeCategorySelector,
  },
})
export default class PartnerNoticeFormPopup extends Vue {
  @Prop({ required: true })
  private initialNoticeForm: NoticeForm;

  @Prop({ default: '공지사항 등록' })
  private readonly title: string;

  @Prop({ default: false })
  private readonly isModification: boolean;

  @Ref()
  private readonly noticeContentRef: Editor;

  private noticeForm = {} as NoticeForm;

  private readonly radioGroup = getRadioGroup();
  private categorySelectBox: OptionData<ManageNoticeCategoryType>[] = getCategorySelectBox();

  @Watch('initialNoticeForm')
  private mergeInitialState() {
    this.noticeForm = { ...this.initialNoticeForm };
  }

  private fetchNoticeCategories() {
    this.$api.getNoticesCategories().then(({ data }) => this.setCategorySelectBox(getCategorySelectBox(data)));
  }

  private setCategorySelectBox(categories: OptionData<ManageNoticeCategoryType>[]) {
    this.categorySelectBox = categories;
  }

  private upsertNotice() {
    try {
      const noticeFormRequest = this.noticeFormRequest();
      this.validateNoticeForm(noticeFormRequest);
      this.$emit('upsert-notice', noticeFormRequest);
    } catch ({ target, message }) {
      message && alert(i18nForPartnerNotice(message));
      target && (this.$refs[`${target}Ref`] as HTMLElement)?.focus();
    }
  }

  private noticeFormRequest(): PostNoticeRequest['data'] {
    return {
      ...this.noticeForm,
      mallNo: this.noticeForm.mallNo > 0 ? `${this.noticeForm.mallNo}` : '',
      noticeAttachFiles: this.noticeForm.noticeAttachFiles as [], // NOTE supporter 에 [] 로 정의되어있음
      noticeContent: this.noticeContentRef.getHtml(), // TODO dompurify,
      searchContent: this.noticeContentRef.getText(),
    };
  }

  private validateNoticeForm(request: PostNoticeRequest['data']) {
    if (!request.noticeTitle) ThrowInputValidationError.of('noticeTitle', 'PLZ_NOTICE_TITLE'); //제목을 입력해 주세요.
    if (!this.isModification && !request.mallNo) ThrowInputValidationError.of('mallNo', 'PLZ_MALL_NO');
    if (!request.noticeCategory) ThrowInputValidationError.of('noticeCategory', 'PLZ_NOTICE_CATEGORY'); // 공지사항 카테고리를 선택해 주세요.
    if (!request.noticeContent) ThrowInputValidationError.of('noticeContent', 'PLZ_NOTICE_CONTENT'); // 내용을 입력해 주시기 바랍니다.
  }

  private cancel() {
    this.$emit('cancel');
  }

  created() {
    this.mergeInitialState();
    this.fetchNoticeCategories();
  }
}
