






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Shipping } from 'ncp-api-supporter';

@Component
export default class OrderInformation extends Vue {
  @Prop({ required: true })
  private readonly shipping!: Shipping;
}
