












































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ProductSetOption extends Vue {
  private exampleOption1 = {
    optionName: 'PRODUCT.OPTION.CHARACTER',
    optionValue: 'PRODUCT.OPTION.RABBIT',
  };

  private exampleOption2 = {
    optionName: 'PRODUCT.OPTION.SET_OPTION',
    optionValue: 'PRODUCT.OPTION.SLEEPING_SUPPLIES',
  };

  private optionData = [this.exampleOption1, this.exampleOption2];

  private descList = this.$t('PRODUCT.OPTION.SET_TOOL_TIP_DESC')
    .toString()
    .split('\n');
}
