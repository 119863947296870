

import { Component, Vue } from 'vue-property-decorator';
import { PopupClose, PopupData, PopupResult } from '@/helpers/popup';

@Component
export default class WindowPopupMain extends Vue {
  protected data: any = null;

  protected created() {
    if (this['useSessionRetention']) this.sessionIdRetention();
    if (this.popupId) {
      try {
        const localStorageData = localStorage.getItem(`shopby_${this.popupId}`);
        const initialData = localStorageData ? JSON.parse(localStorageData) : '';
        this.data = initialData.data;
      } catch (e) {
        console.error(e);
      }
    }

    if (this['useSessionRetention']) this.sessionDataRetention();
  }
  public get popupId() {
    return this.$route && this.$route.query && this.$route.query?.popupId !== undefined
      ? this.$route.query.popupId
      : sessionStorage.getItem('popupId');
  }
  public onNegativeClick(data: PopupData | null = null) {
    this.closePopup({ state: PopupClose.CLOSE.toString(), data: data });
  }
  public onPositiveClick(data: PopupData | null = null) {
    this.closePopup({ state: PopupClose.CONFIRM.toString(), data: data });
  }
  protected closePopup(result?: PopupResult) {
    // callback 실행
    // window.opener as Window -> window.opener as any로 변경 ?? 무엇을 위해서???
    if (result) window.opener[this.popupId as string](result);
    window.close();
  }

  // TODO: 이 기능은 throwWindowPopup 유틸 함수와 통합되어야함
  private sessionIdRetention() {
    const popupId = this.$route.query?.popupId?.toString() ?? '';

    if (!sessionStorage.getItem('popupId')) {
      sessionStorage.setItem('popupId', popupId);
    }
  }

  private sessionDataRetention() {
    const id = sessionStorage.getItem('popupId');
    if (!id) return;

    const storageKey = `popupData_${id}`;
    const sessionStorageData = sessionStorage.getItem(storageKey);
    const data = sessionStorageData ? JSON.parse(sessionStorageData) : '';
    if (!data) {
      sessionStorage.setItem(storageKey, JSON.stringify(this.data));
    } else {
      this.data = data;
    }
  }

  protected resize(width = 30, height = 110): void {
    // 이미 만들어진 팝업들에 ref 속성주기 힘들어 querySelector 사용하였습니다.
    const _width = (document.querySelector('.container-wrap') as HTMLDivElement).clientWidth + width;
    const _height = (document.querySelector('.container-wrap') as HTMLDivElement).clientHeight + height;

    window.resizeTo(_width, _height);
  }
}
