









































































































































































import { Vue, Prop, Watch, Component, PropSync } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ProductDetails extends Vue {
  @Prop({ required: true }) private postPredict!: {};
  @Prop({ default: false }) private escrowOrder!: boolean;
  @Prop({ default: true }) private orgChargeDeliveryAmt!: boolean;
  @Prop({ default: false }) private reSetData!: boolean;
  @Prop({ required: true }) private displayedDeliveryAmtDiscountMessage!: boolean;
  @Prop({ required: true }) private objJson!: string;
  @Prop({ default: '' }) private claimType!: string;
  @Prop({ default: false }) private responsibleObjectType!: 'BUYER' | 'SELLER';
  @Prop({ default: false }) private modifyClaim!: boolean;
  @Prop({ default: 0 }) private exchangeCnt!: number;
  @Prop({ default: false }) private isPartnerProduct: boolean;
  @PropSync('sellerPaysClaimedDelivery') private sellerPaysClaimedDeliverySync: boolean;

  private responsibleObjectTypeValue = false;

  @Watch('postPredict')
  onPredictChange() {
    this.couponCalculation();
    this.deliveryCalculation();
    this.setResponsibleCheckboxData();
    // this.responsibleObjectTypeValue = (this.$parent.$refs.componentItemsClaim as any).;
    if ((this.$parent.$refs.componentItemsClaim as any).responsibleObjectTypeValue === 'SELLER') {
      this.textDecoration = 'line-through';
      this.chargeDeliveryAmtDivFlg = true;
      this.chargeDeliveryAmtDisabled = true;
    }
  }

  public chargeDeliveryAmt = false;
  private chargeDeliveryAmtDisabled = false;
  private tooltipWrap = false;
  private cartCouponDiscountAmt = 0;
  private differenceOfCartCouponDiscountAmt = 0;
  private differenceOfCartCouponDiscountAmtLine = 'none';
  private cancelCartCoupon = false;
  private cartCouponRestores = false;
  private cancelCartCouponName = '';
  private beforeDeliveryTotalDeliveryAmt = 0;
  private beforeDeliveryTotalDeliveryAmtLine = 'none';
  private afterDeliveryTotalDeliveryAmt = 0;
  private afterDeliveryTotalDeliveryAmtLine = 'none';
  private differenceOfTotalDeliveryAmt = 0;
  private differenceOfTotalDeliveryAmtLine = 'none';
  private orderDiscountAndDeliveryAmt = 0;
  private shippingNo = '';
  private ExchangeBeforeDeliveryTotalDeliveryAmt = 0;
  private ExchangeAfterDeliveryTotalDeliveryAmt = 0;
  private ExchangeDifferenceOfTotalDeliveryAmt = 0;
  private ExchangeShippingNo = '';
  private exchangeDeliveryDiv = false;
  private chargeDeliveryAmtDivFlg = false;
  private totalRecalculation;

  created() {
    this.setTotalRecalculationNm();
  }

  private setTotalRecalculationNm() {
    switch (this.claimType) {
      case 'CancelAll':
      case 'Cancel':
      case 'ExchangePopupBefore':
        this.totalRecalculation = this.$t('CLAIM.CANCELPOPUP.TOTALRECALCULATION').toString();
        break;
      case 'ExchangePopupAfter':
      case 'Return':
        this.totalRecalculation = this.$t('CLAIM.CANCELPOPUP.TOTALRECALCULATION_C').toString();
        break;
    }
  }

  private onClickDeliveryAmtInAdvanceHelp() {
    this.tooltipWrap = !this.tooltipWrap;
  }
  private textDecoration = 'none';

  private chargeDeliveryAmtChecked() {
    if (this.chargeDeliveryAmt) {
      this.textDecoration = 'line-through';
    } else {
      this.textDecoration = 'none';
    }
    if (this.claimType === 'Cancel' || this.claimType === 'CancelAll') {
      this.$emit('setData', 'postPredict');
    } else {
      this.$emit('setData');
    }
  }

  private changeSellerPaysClaimedDelivery() {
    this.$nextTick(() => {
      if (this.claimType === 'Cancel') {
        this.$emit('setData', 'postPredict');
      } else {
        this.$emit('setData', false);
      }
    });
  }

  private getDeliveryDifference(postPredict) {
    if (postPredict.amounts.refundDeliveryAmt !== 0 && postPredict.amounts.refundDeliveryAmt) {
      return postPredict.amounts.refundDeliveryAmt;
    }
    // 추가결제인 경우
    if (
      postPredict.amounts.before.delivery.totalDeliveryAmt <=
      postPredict.amounts.after.delivery.totalDeliveryAmt +
        (postPredict.amounts.returnDelivery?.processedDeliveryAmt || 0)
    ) {
      if (this.claimType === 'Cancel') {
        return (
          postPredict.amounts.refund.delivery.totalDeliveryAmt -
          (postPredict.amounts.returnDelivery?.prepaidProcessedDeliveryAmt || 0)
        );
      } else {
        return (
          postPredict.amounts.refund.delivery.totalDeliveryAmt -
          (postPredict.amounts.returnDelivery?.prepaidProcessedDeliveryAmt || 0) +
          postPredict.amounts.deliveryAdjustAmt
        );
      }
    }
  }

  private afterCartCouponDiscountAmt = 0;
  private couponCalculation() {
    const { amounts } = this.postPredict as any;
    if (this.claimType === 'ExchangePopupBefore' || this.claimType === 'ExchangePopupAfter') {
      this.cartCouponDiscountAmt = (this.postPredict as any).amounts.refund.coupon.cartCouponDiscountAmt * -1;
    } else {
      this.cartCouponDiscountAmt = (this.postPredict as any).amounts.refund.coupon.cartCouponDiscountAmt;
    }

    this.cancelCartCoupon =
      (this.postPredict as any).coupon &&
      (this.postPredict as any).coupon !== null &&
      (this.postPredict as any).coupon.cartCoupon !== null;
    if (this.cancelCartCoupon) {
      this.cancelCartCouponName =
        this.$t('CLAIM.CANCELPOPUP.COUPON_NUMBER') +
        (this.postPredict as any).coupon.cartCoupon.couponNo.toString() +
        ' ' +
        (this.postPredict as any).coupon.cartCoupon.couponName +
        ')';
      this.cartCouponRestores = (this.postPredict as any).coupon.cartCoupon.restores;
    }

    // 주문쿠폰 재계산 금액
    if (this.exchangeCnt === 1) {
      this.afterCartCouponDiscountAmt = amounts.after.coupon.cartCouponDiscountAmt;
    }

    this.differenceOfCartCouponDiscountAmt =
      amounts.after.coupon.cartCouponDiscountAmt - amounts.before.coupon.cartCouponDiscountAmt;
  }
  private deliveryCalculation() {
    switch (this.claimType) {
      case 'CancelAll':
        this.beforeDeliveryTotalDeliveryAmt = (this.postPredict as any).amounts.before.delivery.totalDeliveryAmt;
        this.afterDeliveryTotalDeliveryAmt = (this.postPredict as any).amounts.after.delivery.totalDeliveryAmt;
        this.differenceOfTotalDeliveryAmt = this.beforeDeliveryTotalDeliveryAmt - this.afterDeliveryTotalDeliveryAmt;
        this.orderDiscountAndDeliveryAmt = this.cartCouponDiscountAmt * -1 + this.differenceOfTotalDeliveryAmt;
        this.shippingNo = '';
        break;

      case 'Cancel':
        this.beforeDeliveryTotalDeliveryAmt = (this
          .postPredict as any).amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
        this.beforeDeliveryTotalDeliveryAmtLine =
          (this.postPredict as any).amounts.before.delivery.deliveryGroupAmounts.alreadyPaidTotalDeliveryAmt !==
            (this.postPredict as any).amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt &&
          (this.postPredict as any).shipping.prepaid === true
            ? 'line-through'
            : 'none';
        this.afterDeliveryTotalDeliveryAmt = (this
          .postPredict as any).amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt;
        this.differenceOfTotalDeliveryAmt = this.getDeliveryDifference(this.postPredict) || 0;
        this.orderDiscountAndDeliveryAmt =
          this.cartCouponDiscountAmt * -1 +
          this.differenceOfTotalDeliveryAmt +
          (this.postPredict as any).amounts.deliveryAdjustAmt;
        this.shippingNo =
          this.$t('CLAIM.CANCELPOPUP.SHIPPINGNUMBER') + ':' + (this.postPredict as any).shipping.shippingNo;
        break;

      case 'Return':
        this.beforeDeliveryTotalDeliveryAmt = (this
          .postPredict as any).amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
        this.beforeDeliveryTotalDeliveryAmtLine =
          (this.postPredict as any).amounts.before.delivery.deliveryGroupAmounts.alreadyPaidTotalDeliveryAmt !==
            (this.postPredict as any).amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt &&
          (this.postPredict as any).shipping.prepaid === true
            ? 'line-through'
            : 'none';
        this.afterDeliveryTotalDeliveryAmt =
          (this.postPredict as any).amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt +
          (this.postPredict as any).amounts.returnDelivery?.processedDeliveryAmt;
        this.differenceOfTotalDeliveryAmt = this.getDeliveryDifference(this.postPredict) || 0;
        this.orderDiscountAndDeliveryAmt = this.cartCouponDiscountAmt * -1 + this.differenceOfTotalDeliveryAmt;
        this.shippingNo =
          this.$t('CLAIM.CANCELPOPUP.SHIPPINGNUMBER') + ':' + (this.postPredict as any).shipping.shippingNo;
        break;

      case 'ExchangePopupBefore':
        if (
          (this.postPredict as any).amounts.exchangeDelivery !== undefined &&
          (this.postPredict as any).amounts.exchangeDelivery !== null
        ) {
          this.exchangeDeliveryDiv = true;
          this.beforeDeliveryTotalDeliveryAmt = (this
            .postPredict as any).amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
          this.afterDeliveryTotalDeliveryAmt =
            (this.postPredict as any).amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt +
            ((this.postPredict as any).amounts.returnDelivery?.totalDeliveryAmt || 0);
          this.differenceOfTotalDeliveryAmt = this.getDeliveryDifference(this.postPredict) * -1 || 0;
          this.shippingNo =
            this.$t('CLAIM.CANCELPOPUP.SHIPPINGNUMBER') + ':' + (this.postPredict as any).shipping.shippingNo;
          this.ExchangeBeforeDeliveryTotalDeliveryAmt = 0;
          this.ExchangeAfterDeliveryTotalDeliveryAmt = (this
            .postPredict as any).amounts.exchangeDelivery.totalDeliveryAmt;
          this.ExchangeDifferenceOfTotalDeliveryAmt = (this
            .postPredict as any).amounts.exchangeDelivery.totalPrepaidDeliveryAmt;
          this.ExchangeShippingNo = this.$t('CLAIM.CANCELPOPUP.SHIPPINGNUMBER') + ': 신규';
          this.orderDiscountAndDeliveryAmt =
            this.cartCouponDiscountAmt * -1 +
            this.differenceOfTotalDeliveryAmt +
            this.ExchangeDifferenceOfTotalDeliveryAmt;
        } else {
          this.exchangeDeliveryDiv = false;
          this.beforeDeliveryTotalDeliveryAmt = (this
            .postPredict as any).amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
          this.afterDeliveryTotalDeliveryAmt =
            (this.postPredict as any).amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt +
            ((this.postPredict as any).amounts.returnDelivery?.totalDeliveryAmt || 0);
          this.differenceOfTotalDeliveryAmt = this.getDeliveryDifference(this.postPredict) * -1 || 0;
          this.shippingNo =
            this.$t('CLAIM.CANCELPOPUP.SHIPPINGNUMBER') + ':' + (this.postPredict as any).shipping.shippingNo;
          this.orderDiscountAndDeliveryAmt = this.cartCouponDiscountAmt * -1 + this.differenceOfTotalDeliveryAmt;
        }
        break;

      case 'ExchangePopupAfter':
        this.beforeDeliveryTotalDeliveryAmt = (this
          .postPredict as any).amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
        this.afterDeliveryTotalDeliveryAmt =
          (this.postPredict as any).amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt +
          ((this.postPredict as any).amounts.returnDelivery?.processedDeliveryAmt || 0);
        this.differenceOfTotalDeliveryAmt = this.getDeliveryDifference(this.postPredict) * -1 || 0;
        this.shippingNo =
          this.$t('CLAIM.CANCELPOPUP.SHIPPINGNUMBER') + ':' + (this.postPredict as any).shipping.shippingNo;
        this.orderDiscountAndDeliveryAmt = this.cartCouponDiscountAmt * -1 + this.differenceOfTotalDeliveryAmt;
        break;
    }
  }

  private setResponsibleCheckboxData() {
    this.chargeDeliveryAmtDivFlg =
      (this.$parent.$refs.componentItemsClaim as any).responsibleObjectTypeValue === 'SELLER';
    if (this.chargeDeliveryAmtDivFlg && this.differenceOfTotalDeliveryAmt < 0) {
      this.differenceOfTotalDeliveryAmtLine = 'line-through';
    } else {
      this.differenceOfTotalDeliveryAmtLine = 'none';
    }
    if (this.chargeDeliveryAmtDivFlg && (this.postPredict as any).amounts.returnDelivery?.processedDeliveryAmt > 0) {
      this.afterDeliveryTotalDeliveryAmtLine = 'line-through';
    } else {
      this.afterDeliveryTotalDeliveryAmtLine = 'none';
    }
  }

  mounted() {
    if (this.claimType !== 'CancelAll') {
      this.couponCalculation();
      this.deliveryCalculation();
    }

    if (this.escrowOrder) {
      this.chargeDeliveryAmt = true;
      this.chargeDeliveryAmtDivFlg = true;
      this.chargeDeliveryAmtDisabled = true;
      this.textDecoration = 'line-through';
    }
  }
}
