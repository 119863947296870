import axios from 'axios';
const logApi = axios.create({
  baseURL: 'https://api-logncrash.cloud.toast.com',
  headers: {
    'Content-Type': 'application/json',
  },
});
export function logSend(errBody: string): void {
  if (process.env.VUE_APP_NODE_ENV === 'local' || !errBody || errBody === '{}' || errBody === 'null') {
    return;
  }

  const data = {
    projectName: '0Cb0XVZ6eaA6hQKh',
    projectVersion: '1.0.0',
    logVersion: 'v2',
    body: errBody,
    logSource: process.env.VUE_APP_NODE_ENV,
    logType: 'pro',
    host: location.host,
    fullPath: location.href,
  };
  logApi.post('/v2/log', data);
}
