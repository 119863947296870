






































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { LnbMenu } from '@/const/lnb.ts';
import ExcelDownloadButton from '@/components/navigation/ExcelDownloadButton.vue';
import { getCurrentMenu } from '@/utils/menu';
import { PreviousOrdersMall, GetMenusFavorite, Nullable } from 'ncp-api-supporter';
import { getInitFavoriteMenu } from './lnb';

type GetMenusFavoriteCustom = GetMenusFavorite & { isCheck?: boolean };

@Component({
  components: { ExcelDownloadButton },
})
export default class Lnb extends Vue {
  @Prop() private onSelectApp: (appNo: number) => void;

  @Getter('lnb/getLnbMenu') storeMenus!: LnbMenu[];
  @Getter('lnb/isLnbShowing') isLnbShowing!: boolean;
  @Mutation('lnb/SET_LNB_SHOWING') private setLnbShowing!: (isLnbShowing: boolean) => void;
  // TODO 이전주문 리스트 메뉴 노출 여부는 추후 api 값으로 변경될 예정입니다.
  @Getter('mall/getPreviousMalls') private previousMalls!: PreviousOrdersMall[];

  private initFavoriteMenu = getInitFavoriteMenu();
  private currentRootMenu: LnbMenu = this.initFavoriteMenu;
  private currentSubMenu?: LnbMenu;
  private favoriteMenus: GetMenusFavoriteCustom[] = [];
  private selectedIndex = 0;
  private movedIndex: null | number = null;
  private lnbMenus: LnbMenu[] = [];

  @Watch('$route')
  private handleMenu() {
    if (this.currentRootMenu?.uri !== 'favorite') {
      this.setCurrentMenu(getCurrentMenu(this.$route.path, this.lnbMenus));
    }
  }

  private initMenu() {
    const current = getCurrentMenu(this.$route.path, this.lnbMenus);

    if (this.favoriteMenus.find(menu => (menu.appNo ? menu.appNo === current.appNo : menu.menuNo === current.no))) {
      this.currentRootMenu = this.initFavoriteMenu;

      return;
    }

    this.setCurrentMenu(current);
  }

  private setCurrentMenu(current: LnbMenu) {
    if (!current.parent) {
      this.currentRootMenu = current;
      this.currentSubMenu = this.currentRootMenu.menus[0];
    } else if (!current.parent.parent) {
      this.currentRootMenu = current.parent;
      this.currentSubMenu = current;
    } else {
      this.currentRootMenu = current.parent.parent;
      this.currentSubMenu = current.parent;
    }

    this.$forceUpdate();
  }

  private changeCurrentRootMenu(menu: LnbMenu): void {
    this.currentRootMenu = menu;
    this.currentSubMenu = this.currentRootMenu.menus[0];
    // TODO 이전주문 리스트 메뉴 노출 여부는 추후 api 값으로 변경될 예정입니다.
    // 이전주문몰이 없는 경우 '이전주문 리스트'메뉴 미노출
    // start
    if (this.currentSubMenu?.uri === '/shipping/order') {
      this.currentSubMenu.menus = this.currentSubMenu.menus.map(({ displayable, ...rest }) => ({
        ...rest,
        displayable: rest.uri !== '/shipping/order/previous' ? displayable : !!this.previousMalls.length,
      }));
    }
    this.favoriteMenus = this.favoriteMenus.filter(({ isCheck }) => isCheck);
    this.$forceUpdate();
  }

  private changeCurrentSubMenu(menu: LnbMenu): void {
    this.currentSubMenu = menu;
    this.$forceUpdate();
  }

  private openWindow(uri: string) {
    window.open(uri, '_blank');
  }

  private toggleLnb() {
    this.setLnbShowing(!this.isLnbShowing);
  }

  private async putMenusFavoritesOrders(menu: GetMenusFavoriteCustom[]) {
    await this.$api.putMenusFavoritesOrders({
      data: {
        favorites: menu.map(({ menuFavoriteNo }, order) => ({
          menuFavoriteNo,
          order,
        })),
      },
    });
  }

  private async handlePutFavoritesMenu(menuNo: number, appNo: Nullable<number> = null) {
    await this.$api.putMenusFavorites({ params: { menuNo, appNo } });

    if (this.favoriteMenus.find(item => (appNo ? appNo === item.appNo : menuNo === item.menuNo))) {
      this.favoriteMenus = this.favoriteMenus.map(menu => ({
        ...menu,
        isCheck: (appNo ? appNo === menu.appNo : menuNo === menu.menuNo) ? !menu.isCheck : menu.isCheck,
      }));

      this.putMenusFavoritesOrders(this.favoriteMenus);

      return;
    }

    this.setMenuFavorites();
  }

  private async setMenuFavorites() {
    const { data: favorites } = await this.$api.getMenusFavorites();
    this.favoriteMenus = favorites.map(item => ({
      ...item,
      uri: item.uri.replace('/pro', ''),
      isCheck: true,
    }));
  }

  private dragstart(index: number) {
    this.selectedIndex = index;
  }

  private dragleave(index: number) {
    const element = this.$refs.draggableElement[index] as HTMLDivElement;
    element.classList.remove('over-top', 'over-bottom');
  }

  private dragover({ clientY }: DragEvent, index: number) {
    const overElement = this.$refs.draggableElement[index] as HTMLDivElement;
    const { top, height } = overElement.getBoundingClientRect();
    const isPositionUp = clientY > top + height / 2;

    if (isPositionUp) {
      overElement.classList.add('over-bottom');
      overElement.classList.remove('over-top');
    } else {
      overElement.classList.add('over-top');
      overElement.classList.remove('over-bottom');
    }

    if (
      (this.selectedIndex > index && !isPositionUp) ||
      (this.selectedIndex < index && isPositionUp) ||
      this.selectedIndex === index
    ) {
      this.movedIndex = index;
    }
  }

  private async dragDrop() {
    const filteredMenus = this.favoriteMenus.filter((_, index) => index !== this.selectedIndex);
    filteredMenus.splice(this.movedIndex, 0, this.favoriteMenus[this.selectedIndex]);
    (this.$refs.draggableElement as HTMLDivElement[]).forEach(({ classList }) =>
      classList.remove('over-top', 'over-bottom'),
    );

    this.putMenusFavoritesOrders(filteredMenus);
    this.favoriteMenus = filteredMenus;
  }

  async created() {
    await this.setMenuFavorites();
    this.lnbMenus = [this.initFavoriteMenu, ...this.storeMenus];
    this.initMenu();
  }
}
