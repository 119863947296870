




import { Vue, Component } from 'vue-property-decorator';
import RegisterSearchGrid from '@/components/partner/register/RegisterSearchGrid.vue';

@Component({
  components: {
    RegisterSearchGrid,
  },
})
export default class PartnerRegisterCopySearch extends Vue {
  private writeDetail(partnerNo) {
    this.$router.push({ name: 'PartnerRegisterCopyDetail', params: { partnerNo } });
  }
}
