import { Component, Vue } from 'vue-property-decorator';
import {
  DeleteBoardsBoardNoPostsRequest,
  GetBoards,
  GetBoardsBoardNoPostsRequest,
  GetBoardsRequest,
  NCPResponse,
} from 'ncp-api-supporter';
import { getArticleListInitQuery } from '@/const/contents/board';
import { PopupClose, throwMessagePopup } from '@/helpers/popup';
import { SearchMethod } from '@/types';

@Component
export default class ArticleMixin extends Vue {
  private boardNoList: GetBoards[] = [];
  //private reSearch: SearchMethod;
  // parameter object.
  public query = getArticleListInitQuery();
  // grid data collections
  public contents = [];
  // grid total count.(articl list length)
  public totalCount = 0;

  /**
   * ----- Get parameter boardNo for first searching.------
   * @param mallNo
   * @returns Promise<GetBoards[]>
   * 1. Generate api request parameter.
   * 2. Get board list by calling api.
   * 3. Set boardNos value from board list & save to localStorage
   * api-url: /boards [get]
   *--------------------------------------------------------
   */
  public async getBoardNos(mallNo: number): Promise<GetBoards[]> {
    // 1.
    const param: GetBoardsRequest = {
      params: {
        mallNo: Number(mallNo),
      },
    };

    // 2.
    let boardNos: GetBoards[] = [];
    try {
      const response: NCPResponse<GetBoards[]> = await this.$api.getBoards(param);

      // 3.
      if (response && response.data) {
        boardNos = response.data.filter(item => item.used);
        // for get method
        this.boardNoList = boardNos;
      }
    } catch (err) {
      if (err) {
        console.error(err);
      }
      this.boardNoList = [];
    }
    return boardNos;
  }

  /**
   * board select-box value init. .
   * @param mallNo
   * @returns  Promise<{ name: string; value: number }[]>
   */
  public async getSelectBoardNos(mallNo: number): Promise<{ name: string; value: number }[]> {
    const tBoardNos: { name: string; value: number }[] = [];
    await this.getBoardNos(mallNo);
    this.boardNoList.map(item => {
      tBoardNos.push({ name: item.boardName, value: Number(item.boardNo) });
    });
    return tBoardNos;
  }

  /**
   * ------- Get article List data for gird ----------
   *1.generate request parameter for api
   *2.call api for getting article list.
   *3.set article List to grid.
   * api-url: /boards/{boardNo}/posts [get]
   * -------------------------------------------------
   */
  public getArticleList(): void {
    // 1.
    const request: GetBoardsBoardNoPostsRequest = {
      pathParams: {
        boardNo: Number(this.query.boardNo),
      },
      params: this.query,
    };
    // 2.
    this.$api.getBoardsBoardNoPosts(request).then(response => {
      if (response && response.status === 200) {
        // reset order number to index property in result (article list)
        response.data.contents.forEach((item, i) => {
          item['index'] = i + 1;
          item['replyUsed'] = this.replyUsed;
        });

        // 3.
        this.contents = response.data.contents;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  /**----- Delete articles by calling api ------
   * @param selectedArticles
   * @param searchMethod
   * @returns Promise<void>
   * 1.call api to delete articles
   * 2.refresh grid when deleted successful.
   * api-url: /boards/{boardNo}/posts [delete]
   *---------------------------------------------
   */
  public async deleteArticle(selectedArticles: string, searchMethod: SearchMethod): Promise<void> {
    // confirm: whether to delete.
    const isPass = await throwMessagePopup(this.$t('BOARD.BASIC.ARTICLE.CONFIRM_DELETE_ARTICLE') as string, true);
    console.log(isPass.state);
    if (isPass.state !== PopupClose.CONFIRM) {
      return;
    }
    // generate request parameter
    const obj: DeleteBoardsBoardNoPostsRequest = {
      pathParams: {
        boardNo: this.query.boardNo,
      },
      params: {
        postNos: selectedArticles,
      },
    };

    // 1
    const response = await this.$api.deleteBoardsBoardNoPosts(obj);
    // 2
    if (response && response.status === 200) {
      // alert message of deleted successful
      throwMessagePopup(this.$t('BOARD.BASIC.ARTICLE.COMPLETE_DELETE'), true, null, true);
      // refresh grid.
      searchMethod();
    } else {
      // failed
      //throwMessagePopup(this.$t('삭제하지 못했습니다.'));
    }
  }

  /**
   * article detail popup for (article create,edit,reply,reply-edit)
   * @param: popupParameters{mallNo,boardNo,mode[create|edit|reply|replyModify],row,parentTitle?,parentContent?}
   * @param：searchMethod: callback method
   */
  /*public articleDetailPopup(popupData: PopupParameters, searchMethod: SearchMethod) {
    this.reSearch = searchMethod;
    throwWindowPopup('ArticleDetail', popupData, 'lg', this.detailCallback);
  }*/

  /**
   * ----- callback method for article detail page popup [window popup] ----
   */
  /*private detailCallback(callbackData: { state: string; data: object }): void {
    if (callbackData.state === 'close') return;
    const result = callbackData.data;
    if (result) {
      // refresh articl list of grid.
      this.reSearch();
    }
  }*/

  get replyUsed(): boolean {
    return this.boardNoList.filter(item => item.boardNo === Number(this.query.boardNo))[0].replyUsed;
  }
}
