
























































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import TableTabMenu, { TableTabMenuItem } from '@/components/shipping/order/TableTabMenu.vue';
import OrderProductInfo from '@/components/shipping/order/OrderProductInfo.vue';
import OrderInfo from '@/components/shipping/order/OrderInfo.vue';
import OrderDetails from '@/components/shipping/order/mixins/OrderDetail';
import OrderFirstInfo from '@/components/shipping/order/OrderFirstInfo.vue';
import OrderLastInfo from '@/components/shipping/order/OrderLastInfo.vue';
import OrderOtherPayInfo from '@/components/shipping/order/OrderOtherPayInfo.vue';
import OrderRefundInfo from '@/components/shipping/order/OrderRefundInfo.vue';
import OrderOrdererInfo from '@/components/shipping/order/OrderOrdererInfo.vue';
import OrderReceiverInfo from '@/components/shipping/order/OrderReceiverInfo.vue';
import OrderSettleInfo from '@/components/shipping/order/OrderSettleInfo.vue';
import SyncFailList from '@/components/shipping/order/SyncFailList.vue';
import TaskMessage from '@/components/popup/shipping/order/TaskMessage.vue';
import CsMessage from '@/components/popup/shipping/order/CsMessage.vue';
import { clearBottomNavigation, throwBottomNavigation } from '@/helpers/bottomNav';
import { orderDetailButtonType, orderListTypes } from '@/const/order';
import {
  GetOrderOptionsDetailSummaryViewRequest,
  GetOrdersByOrderNo,
  OrdersExternalFailHistory,
} from 'ncp-api-supporter';
import { throwPopup, throwWindowPopup } from '@/helpers/popup';
import { Getter } from 'vuex-class';
import { Admin } from '@/types';
import { i18n } from '@/main';
import PreviousOrderProductInfo from '@/components/shipping/order/PreviousOrderProductInfo.vue';

@Component({
  components: {
    PreviousOrderProductInfo,
    OrderSettleInfo,
    OrderOrdererInfo,
    OrderReceiverInfo,
    OrderOtherPayInfo,
    OrderRefundInfo,
    OrderLastInfo,
    OrderFirstInfo,
    TableTabMenu,
    OrderProductInfo,
    OrderInfo,
    TaskMessage,
    CsMessage,
    SyncFailList,
  },
})
export default class OrderDetail extends Mixins(OrderDetails) {
  @Getter('admin/getAdmin') private admin: Admin;
  private payType = '';
  private summaryAllOrderCnt = 0;
  private summaryOrderCnt = 0;
  private summaryCancelCnt = 0;
  private summaryReturnCnt = 0;
  private summaryExchangeCnt = 0;
  private menuList: TableTabMenuItem[] = [];
  private focusTabName = 'orderAllListTab';
  private showTaskMessage = false;
  private showCsMessage = false;
  private memberInfoChange = false;
  private escrowPayType = false;
  private showCsMessageIcon = '';
  private showCsMessageIconStyle = 3001;
  private showTaskMessageIcon = '';
  private showTaskMessageIconStyle = 3001;
  private printSpecification = [];
  private syncFailList: OrdersExternalFailHistory[] = [];
  public productInfo = [];
  public taskMessageInfo = [
    {
      orderOptionNo: 0,
      taskMessageStatus: '',
    },
  ];
  private getNavButtons = [
    {
      type: 'left',
      key: 'printSpecification',
      color: 'white',
      text: i18n.t('PRINT_SPECIFICATION'),
    },
    {
      type: 'right',
      key: 'taskMessage',
      color: 'black',
      text: i18n.t('TASK_MESSAGE'),
    },
    {
      type: 'right',
      key: 'csMessage',
      color: 'black',
      text: i18n.t('CS_MESSAGE'),
    },
  ];

  private get isNaverPay() {
    return this.payType === 'NAVER_PAY';
  }

  private onChangeGridTab(name) {
    this.focusTabName = name;
  }

  private getSummary(): void {
    const detailSummary: GetOrderOptionsDetailSummaryViewRequest = {
      params: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api.getOrderOptionsDetailSummaryView(detailSummary).then(res => {
      this.summaryAllOrderCnt = res.data.totalCnt;
      this.summaryCancelCnt = res.data.cancelCnt;
      this.summaryOrderCnt = res.data.orderCnt;
      this.summaryReturnCnt = res.data.returnCnt;
      this.summaryExchangeCnt = res.data.exchangeCnt;
      this.menuList = [
        {
          name: 'orderAllListTab',
          title: this.$t('ORDER.ORDER_DETAIL.ORDER_ALL_LIST')
            .toString()
            .replace('%d', this.summaryAllOrderCnt.toString()),
        },
        {
          name: 'orderListTab',
          title: this.$t('ORDER.ORDER_DETAIL.ORDER_LIST')
            .toString()
            .replace('%d', this.summaryOrderCnt.toString()),
        },
        {
          name: 'orderCancelListTab',
          title: this.$t('ORDER.ORDER_DETAIL.ORDER_CANCEL_LIST')
            .toString()
            .replace('%d', this.summaryCancelCnt.toString()),
        },
        {
          name: 'orderExchangeListTab',
          title: this.$t('ORDER.ORDER_DETAIL.ORDER_EXCHANGE_LIST')
            .toString()
            .replace('%d', this.summaryExchangeCnt.toString()),
        },
        {
          name: 'orderReturnListTab',
          title: this.$t('ORDER.ORDER_DETAIL.ORDER_RETURN_LIST')
            .toString()
            .replace('%d', this.summaryReturnCnt.toString()),
        },
      ];
    });
  }

  private getOrderInfo() {
    const request = { orderNo: this.$route.query.orderNo?.toString() };

    this.$api.getOrdersByOrderNo({ params: request }).then(res => {
      const order: GetOrdersByOrderNo = res.data;

      this.payType = res.data.payType;
      this.escrowPayType = res.data.payType.indexOf('ESCROW') !== -1;
      this.orderInfo = this.getOrderInfoData(order);

      this.getOrderMemberInfo(res.data);
      this.getPaymentInfo();
    });
  }

  private handleEmitProductInfo(info): void {
    this.productInfo = [];
    this.productInfo = [...info];
    this.getPayInfo(this.productInfo);
    this.getTaskProductOptions();
    this.getOtherPayList();
    this.getReturnListAndPayList();
    this.setBottomNavigator();
  }

  private setNavButtonsInfo() {
    let setNavButtonsInfo = this.getNavButtons;
    const canPrintSpecification = [
      'PAY_DONE', // 결제완료
      'PRODUCT_PREPARE', // 상품준비중
      'DELIVERY_PREPARE', // 배송준비중
      'DELIVERY_ING', // 배송중
      'DELIVERY_DONE', // 배송완료
      'BUY_CONFIRM', // 구매확정
    ];

    // 거래명세서 출력조건
    // 주문 상품의 상태가 canPrintSpecification중 하나의 주문상태이면서
    this.printSpecification = this.productInfo.filter(pItem => {
      return canPrintSpecification.includes(pItem.orderStatusType);
    });

    // claim 주문상품이 아닌 경우 거래명세서 출력 대상
    this.printSpecification = this.printSpecification.filter(item => {
      return item.claimNo === null;
    });

    // 거래명세서 출력대상이 없는 주문인 경우 '거래명세서 출력' 버튼 Hidden 처리
    if (this.printSpecification.length === 0) {
      setNavButtonsInfo = this.getNavButtons.filter(item => {
        return item.type === 'right';
      });
    }
    return setNavButtonsInfo;
  }

  private setBottomNavigator(key?) {
    const getNavButtons = this.setNavButtonsInfo();
    let newGetNavButtons = getNavButtons;
    if (key) newGetNavButtons = key;
    throwBottomNavigation({
      buttons: newGetNavButtons,
      onClick: (navType: orderDetailButtonType) => {
        switch (navType) {
          case 'printSpecification':
            this.openPopupPrintSpecification();
            break;
          case 'taskMessage':
            this.openPopupTaskMessage();
            break;
          case 'csMessage':
            this.openPopupCsMessage();
            break;
        }
      },
    });
  }

  private openPopupPrintSpecification() {
    if (orderListTypes.HOLD_DELIVERY === this.orderStatus) {
      alert(this.$t('ORDER.LIST_GRIDS.NO_ORDERNO_ALERT'));
      return;
    }

    const shippingNos = this.printSpecification.map(pItem => {
      return pItem.shippingNo;
    });
    throwWindowPopup('OrderSpecificationPopup', { shippingNos }, 'xlg');
  }

  private openPopupTaskMessage() {
    clearBottomNavigation();
    this.showTaskMessage = !this.showTaskMessage;
    const newNavButtons = [...this.getNavButtons];
    if (this.showTaskMessage) {
      newNavButtons[1].text = i18n.t('TASK_MESSAGE_CLOSE');
    } else {
      newNavButtons[1].text = i18n.t('TASK_MESSAGE');
    }
    this.showTaskMessageIconStyle = this.showCsMessageIconStyle + 1;
    if (this.showTaskMessage) {
      this.showCsMessage === true ? (this.showTaskMessageIcon = '02') : (this.showTaskMessageIcon = '');
    } else {
      this.showCsMessageIcon = '';
    }
    this.setBottomNavigator(newNavButtons);
  }

  private openPopupCsMessage() {
    clearBottomNavigation();
    this.showCsMessage = !this.showCsMessage;
    const newNavButtons = [...this.getNavButtons];
    if (this.showCsMessage) {
      newNavButtons[2].text = i18n.t('CS_MESSAGE_CLOSE');
    } else {
      newNavButtons[2].text = i18n.t('CS_MESSAGE');
    }
    this.showCsMessageIconStyle = this.showTaskMessageIconStyle + 1;
    if (this.showCsMessage) {
      this.showTaskMessage === true ? (this.showCsMessageIcon = '02') : (this.showCsMessageIcon = '');
    } else {
      this.showTaskMessageIcon = '';
    }
    this.setBottomNavigator(newNavButtons);
  }

  private handleEmitOrderProductInfo(info) {
    this.getTotalAccumulation(info);
  }

  public onClickBenefit(): void {
    throwWindowPopup(`Benefit`, { orderNo: this.$route.query.orderNo }, 'md');
  }

  public handleMemberInfoChange(data) {
    if (this.isNaverPay) {
      alert(this.$t('CLAIM.MESSAGE.NAVER_PAY_CENTER_PROCESS_ALERT'));
      return;
    }
    this.memberInfoChange = data;
  }

  public handleMemberInfoConfirm() {
    (this.$refs.orderOrdererInfo as OrderOrdererInfo).handleMemberInfoConfirm();
  }

  created() {
    this.getSummary();
    this.getOrderInfo();
    this.fetchOrdersExternalFailHistory();
  }

  private syncNaverOrder() {
    this.$api
      .putNaverPaySyncProductOrder({ params: { orderNo: this.$route.query.orderNo.toString() } })
      .then(() => {
        alert(this.$t('ORDER.ORDER_DETAIL.SYNC_SUCCESS_MESSAGE'));
        location.reload();
      })
      .catch(() => {
        alert(this.$t('ORDER.ORDER_DETAIL.SYNC_FAIL_MESSAGE'));
      });
  }

  private openNaverProcessInfo() {
    return throwPopup({
      name: 'NaverPayProcessInfo',
    });
  }

  private async fetchOrdersExternalFailHistory() {
    const { data } = await this.$api.getOrdersExternalFailHistory({
      params: {
        orderNo: this.$route.query.orderNo.toString(),
      },
    });

    this.syncFailList = data;
  }
}
