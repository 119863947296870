










































import { Vue, Component } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import Sitemap from '@/components/navigation/Sitemap.vue';
import { logout, logoutConsolidatedUser, refreshToken } from '@/utils/token';
import { throwPopup, openCreditChangePopup } from '@/helpers/popup';
import { Admin } from '@/types';
import { namespace } from 'vuex-class';

import cookies from 'js-cookie';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN } from '@/const/cookie';

const adminStore = namespace('admin');

const { VUE_APP_NODE_ENV, VUE_APP_GODO_API_URL, VUE_APP_CONSOLIDATED_AUTH, VUE_APP_WORKSPACE_URL } = process.env;

@Component({
  components: {
    Sitemap,
  },
  directives: {
    ClickOutside,
  },
})
export default class MyAccountMenu extends Vue {
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;

  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  @adminStore.Action('setCommerceAccount')
  private setCommerceAccount: (commerceAccount: boolean) => void;

  private readonly myGodoUrl = VUE_APP_GODO_API_URL + '/mygodo/main.gd';
  private get badge() {
    return this.admin.plan.toLowerCase();
  }
  private logout() {
    this.commerceAccount && VUE_APP_NODE_ENV !== 'local' ? logoutConsolidatedUser() : logout();
  }

  private get workspaceUrl() {
    return VUE_APP_WORKSPACE_URL;
  }

  private show = false;

  private openEditMyAccountPopup(): void {
    throwPopup({
      name: 'EditMyAccountIframe',
    });
  }

  private openPasswordChangePopup(): void {
    if (this.commerceAccount) {
      window.open(`${VUE_APP_CONSOLIDATED_AUTH}/mypage/change-password`, '_self');
    } else {
      throwPopup({
        name: 'PasswordChange',
      });
    }
  }

  private openLnbSettingPopup(): void {
    throwPopup({
      name: 'LnbSetting',
    });
  }

  onClickOutside(): void {
    this.show = false;
  }

  private checkAuth() {
    const hasAccessToken = Boolean(cookies.get(SHOPBY_ACCESSTOKEN));
    const hasRefreshToken = Boolean(cookies.get(SHOPBY_REFRESHTOKEN));
    if (hasAccessToken) {
      // 엑세스 O, 리프레시 O/X
      return;
    }
    if (hasRefreshToken) {
      // 엑세스X, 리프레시O
      refreshToken();
      return;
    }
    // 엑세스X, 리프레시X
    this.$router.push({ name: 'AuthMain' });
  }

  private async fetchManagerInfo() {
    const { data } = await this.$api.getManagerInfo();
    this.setCommerceAccount(data.commerceAccount);
  }

  private openCreditChangePopup() {
    openCreditChangePopup(this.commerceAccount, this.admin.mallNos[0].toString());
  }

  private created() {
    this.checkAuth();
    this.fetchManagerInfo();
  }
}
