


































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { GetDesignPopupsDisplayUrlsRequest } from 'ncp-api-supporter';
import {
  DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY,
  getDesignPopupListSearchType,
  getInitDisplayUrlOptions,
  getSearchQuery,
} from '@/const/contents/designPopup';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { mall } from '@/utils/mall';
import { DesignPopupListRouterQuery } from '@/types/designPopup';
@Component({
  components: {
    MallSelect,
    SearchButton,
    DateRangePicker,
    SelectBox,
    CheckboxGroup,
    TextInput,
  },
})
export default class Popup extends Vue {
  private isOnlyOneMall = mall.isOnlyOneMall;
  private searchParamTypes = getDesignPopupListSearchType();
  private displayUrlOptions = getInitDisplayUrlOptions();
  private query = { ...DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY };
  @Ref('keyWord') private readonly keyWord!: HTMLInputElement;
  @Ref('periodDatePickerRef') private readonly periodDatePickerRef: DateRangePicker;

  private checkValidation(): boolean {
    if (this.query.keyword && this.query.keyword.length > 50) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.KEYWORD_TOO_LONG'));
      this.keyWord.focus();
      return false;
    }
    if (
      (this.query.startDate && !this.query.endDate) ||
      (!this.query.startDate && this.query.endDate) ||
      Number(this.query.startDate) > Number(this.query.endDate)
    ) {
      alert(this.$t('ERROR_DATE_SPAN'));
      this.periodDatePickerRef.focus();
      return false;
    }
    return true;
  }

  created() {
    this.setQueryFromUrl();
  }

  @Watch('$route.query')
  private setQuery() {
    this.setQueryFromUrl();
  }

  private setQueryFromUrl(): void {
    this.query = { ...DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY };
    this.query = getSearchQuery(this.query, this.$route.query as DesignPopupListRouterQuery);

    if (!this.query.mallNo) {
      const malls = mall.mallsSortedByCreationDate;
      if (malls && malls.length > 0) {
        this.query.mallNo = malls[0].mallNo;
      }
    }
  }

  private resetQuery(): void {
    this.query = { ...DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY };
    const malls = mall.mallsSortedByCreationDate;
    if (malls && malls.length > 0) {
      this.query.mallNo = malls[0].mallNo;
    }
  }

  @Watch('query.mallNo', { immediate: false })
  private watchMallNo() {
    if (!this.query.mallNo || this.query.mallNo == -1) {
      this.displayUrlOptions = getInitDisplayUrlOptions();
    } else {
      this.getDisplayUrls();
    }
    if (!this.query.mallNo || this.query.mallNo == -1 || this.query.displayPageNo === undefined) {
      this.query.displayPageNo = null;
    }
  }

  private async getDisplayUrls(): Promise<void> {
    if (!this.query.mallNo || this.query.mallNo == -1) {
      return;
    }

    const request: GetDesignPopupsDisplayUrlsRequest = {
      params: {
        mallNo: Number(this.query.mallNo),
      },
    };
    await this.$api.getDesignPopupsDisplayUrls(request).then(ret => {
      if (ret && ret.data) {
        const { contents } = ret.data;
        if (contents && contents.length > 0) {
          this.displayUrlOptions = contents
            .map(({ pageNo, displayUrl, pageName }) => ({ value: pageNo, name: pageName + ':' + displayUrl }))
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
          this.displayUrlOptions.splice(0, 0, {
            value: null,
            name: this.$t('DISPLAY.DESIGN_POPUP.DISPLAY_PAGE').toString(),
          });
        } else {
          this.displayUrlOptions = getInitDisplayUrlOptions();
        }
      }
    });
  }

  private checkSelectedMall() {
    if (!this.query.mallNo || this.query.mallNo == -1) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.SELECT_MALL'));
      return;
    }
  }
}
