



































import { Component, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import Editor from '@/components/common/summernote/Editor.vue';

import sanitizeHtml from '@/utils/sanitizeHtml';

@Component({
  components: { Editor, NoticeBox },
})
export default class EntryContractFilePopup extends WindowPopupMainVue {
  @Ref()
  private readonly editorRef?: Editor;

  private emitToOpenerWithData() {
    const htmlContent = sanitizeHtml(this.editorRef.getHtml());
    this.onPositiveClick({ content: htmlContent });
  }

  private cancel() {
    this.onNegativeClick();
  }

  mounted() {
    const htmlContent = sanitizeHtml(this.data.content);
    this.editorRef.setHtml(htmlContent);
  }
}
