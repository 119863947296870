export default Object.freeze({
  // COMMON.ACCOUNT
  CHANGE_PASSWORD: '비밀번호 변경',
  PASSWORD_CHANGE_NOTICE_0:
    '방송통신위원회 고시 [개인정보의 기술적 · 관리적 보호조치 기준]에 따라, 개인정보취급자는 6개월에 1회 이상 비밀번호를 변경하여야 합니다.',
  PASSWORD_CHANGE_NOTICE_1: '쇼핑몰 보안 및 운영자의 개인정보 보호를 위해 비밀번호를 변경하시기 바랍니다.',
  PASSWORD_CHANGE_NOTICE_2: '특수문자는 ! @ # $ % ^ & + = - _ . ( ) 만 사용 가능합니다.',
  PASSWORD_CHANGE_NOTICE_3:
    '연속적인 숫자나 생일, 전화번호 등 추측하기 쉬운 개인정보 및 아이디와 비슷한 비밀번호는 사용하지 않는 것을 권고합니다.',
  PASSWORD_CHANGE_NOTICE_4:
    '비밀번호는 영문,숫자,특수문자 모두 조합하여 8~20자로 입력 가능 합니다.<br>(10자 이상 시 2종류 이상 조합, 10자 미만 시에는 3종류 조합)',

  CURRENT_PASSWORD: '현재 비밀번호',
  NEW_PASSWORD: '새로운 비밀번호',
  NEW_PASSWORD_REPEAT: '새로운 비밀번호 확인',
  CURRENT_PASSWORD_INPUT: '현재 비밀번호 입력',
  NEW_PASSWORD_INPUT: '새로운 비밀번호 입력',
  NEW_PASSWORD_REPEAT_INPUT: '새로운 비밀번호 한번 더 입력',

  ALERT_NEW_PASSWORD_UNMATCHED: '새로운 비밀번호와 새로운 비밀번호 확인 값이 일치하지 않습니다.',
  ALERT_PASSWORD_UNMATCHED: '현재 비밀번호가 일치하지 않습니다.',
  ALERT_BEFORE_PASSWORD_MATCH: '이전에 사용한 비밀번호는 다시 사용하실 수 없습니다. 다른 비밀번호를 입력해 주세요.',
  ALERT_DONE: '비밀번호 변경이 완료되었습니다.',

  OPEN_SETTING: '열림 설정',
  ALWAYS_OPEN: '항상 열기',
  HIDE: '숨기기',
});
