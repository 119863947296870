





































































































































import { Component, Vue, Emit, Ref } from 'vue-property-decorator';
import { ComponentItemsClaimDataType } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import NoticeBox from '@/components/popup/claim/ClaimApplyPopup/NoticeBox.vue';
import OrderProducts from '@/components/popup/claim/ClaimApplyPopup/OrderProducts.vue';
import DiscountShippingFee from '@/components/popup/claim/ClaimApplyPopup/DiscountShippingFee.vue';
import ReturnShippingFee from '@/components/popup/claim/ClaimApplyPopup/ReturnShippingFee.vue';
import RefundExchangeAmount from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeAmount.vue';
import BenefitsPaid from '@/components/popup/claim/ClaimApplyPopup/BenefitsPaid.vue';
import ProductExchange from '@/components/popup/claim/ClaimApplyPopup/ProductExchange.vue';
import ProductDetails from '@/components/popup/claim/ClaimApplyPopup/ProductDetails.vue';
import ReturnAddress from '@/components/popup/claim/ClaimApplyPopup/ReturnAddress.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  PostReturnsPredictRequest,
  GetClaimsOrderOptionsOrderOptionNoApplyRequest,
  ReturnsPredict,
  PostReturnsRequest,
  RefundType,
  GetClaimsOrderOptionsOrderOptionNoApply,
  ResponsibleObjectType,
  ReasonType,
  ReturnsCalculate,
  ReturnOrderOptions,
} from 'ncp-api-supporter';
import { ReturnOrderOption } from '@/types/claim';
import { uniqBy } from '@/utils/common';

@Component({
  components: {
    ReturnShippingFee,
    ReturnAddress,
    NoticeBox,
    OrderProducts,
    ProductExchange,
    DiscountShippingFee,
    ProductDetails,
    RefundExchangeAmount,
    BenefitsPaid,
  },
})
export default class ReturnApplyPopup extends Vue {
  @Ref('componentItemsClaim')
  private readonly refOrderProducts!: OrderProducts;
  @Ref('componentProductDetails')
  private readonly refProductDetails!: ProductDetails;
  @Ref('componentRefundExchangeAmount')
  private readonly refRefundExchangeAmount!: RefundExchangeAmount;
  @Ref('componentBenefitsPaid')
  private readonly refBenefitsPaid!: BenefitsPaid;
  @Ref('componentReturnAddress')
  private readonly refReturnAddress!: ReturnAddress;
  @Ref('componentReturnShippingFee')
  private readonly refReturnShippingFee!: ReturnShippingFee;
  @Ref('componentDiscountShippingFee')
  private readonly refDiscountShippingFee!: DiscountShippingFee;

  @Emit('defaultPredict')
  private defaultPredict() {
    this.postReturnsPredict = {
      refundType: 'ACCOUNT',
      shipping: {
        requiresShipping: false,
        address: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
        shippingNo: 0,
        hasAdjustedExchangeDeliveryAmt: false,
        divided: false,
        deliveryGroupNo: 0,
        deliveryType: '',
        prepaid: true,
        deliveryTemplateNo: 0,
        combined: false,
        originalShippingNo: 0,
      },
      coupon: null,
      amounts: {
        returnDelivery: {
          totalReturnDeliveryAmt: 0,
          totalPureReturnDeliveryAmt: 0,
          prepaidTotalPureReturnDeliveryAmt: 0,
          prepaidProcessedDeliveryAmt: 0,
          returnRemoteDeliveryAmt: 0,
          returnDeliveryAmt: 0,
          prepaidReturnRemoteDeliveryAmt: 0,
          processedDeliveryAmt: 0,
          prepaid: false,
          prepaidTotalReturnDeliveryAmt: 0,
          prepaidReturnDeliveryAmt: 0,
        },
        deliveryAdjustAmt: 0,
        returnDeliveryAdjustAmt: 0,
        adjustedAmounts: {
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          claimAmt: 0,
          additionalPayAmt: 0,
          payAmt: 0,
        },
        before: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        after: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        refund: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
            totalDeliveryAmt: 0,
          },
        },
      },
      overflowsPgAmt: false,
    };
  }
  private refundType = '';
  private showA = false;
  private showB = false;
  private deliveryAddress = true;
  private displayedDeliveryAmtDiscountMessage = false;
  private claimType = 'Return';
  private isShow = false;
  private returnOrderOptions: ReturnOrderOption[] = [];
  private mall = {
    countryCd: 'KR',
  };
  private orderOptionsApply: ComponentItemsClaimDataType = {
    orderNo: '',
    claimableOptions: [],
    refundTypes: [],
    payType: '',
  };
  private postReturnsPredict: ReturnsPredict = {
    refundType: 'ACCOUNT',
    shipping: {
      requiresShipping: false,
      address: {
        address: '',
        name: '',
        jibunAddress: '',
        detailAddress: '',
        zipCd: '',
        contact1: '',
        contact2: '',
      },
      shippingNo: 0,
      hasAdjustedExchangeDeliveryAmt: false,
      divided: false,
      deliveryGroupNo: 0,
      deliveryType: '',
      prepaid: false,
      deliveryTemplateNo: 0,
      combined: false,
      originalShippingNo: 0,
    },
    coupon: {
      productCoupons: [
        {
          couponName: '',
          couponIssueNo: 0,
          orderProductNo: 0,
          restores: false,
          couponNo: 0,
        },
      ],
      cartCoupon: {
        couponName: '',
        couponIssueNo: 0,
        orderProductNo: 0,
        restores: false,
        couponNo: 0,
      },
    },
    amounts: {
      returnDelivery: {
        totalReturnDeliveryAmt: 0,
        totalPureReturnDeliveryAmt: 0,
        prepaidTotalPureReturnDeliveryAmt: 0,
        prepaidProcessedDeliveryAmt: 0,
        returnRemoteDeliveryAmt: 0,
        returnDeliveryAmt: 0,
        prepaidReturnRemoteDeliveryAmt: 0,
        processedDeliveryAmt: 0,
        prepaid: false,
        prepaidTotalReturnDeliveryAmt: 0,
        prepaidReturnDeliveryAmt: 0,
      },
      deliveryAdjustAmt: 0,
      returnDeliveryAdjustAmt: 0,
      adjustedAmounts: {
        mainPayAmt: 0,
        accumulationPayAmt: 0,
        claimAmt: 0,
        additionalPayAmt: 0,
        payAmt: 0,
      },
      before: {
        delivery: {
          deliveryAmt: 0,
          remoteDeliveryAmt: 0,
          totalDeliveryAmt: 0,
          deliveryGroupAmounts: {
            prepaidRemoteDeliveryAmt: 0,
            payOnRemoteDeliveryAmt: 0,
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            payOnDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            prepaidDeliveryAmt: 0,
            totalPrepaidDeliveryAmt: 0,
            alreadyPaidTotalDeliveryAmt: 0,
          },
        },
        coupon: {
          cartCouponDiscountAmt: 0,
          productCouponDiscountAmt: 0,
        },
        accumulation: {
          accumulationAmt: 0,
        },
        order: {
          immediateDiscountAmt: 0,
          additionalDiscountAmt: 0,
          standardAmt: 0,
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          remainingPgPayAmt: 0,
          payAmt: 0,
        },
      },
      after: {
        delivery: {
          deliveryAmt: 0,
          remoteDeliveryAmt: 0,
          totalDeliveryAmt: 0,
          deliveryGroupAmounts: {
            prepaidRemoteDeliveryAmt: 0,
            payOnRemoteDeliveryAmt: 0,
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            payOnDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            prepaidDeliveryAmt: 0,
            totalPrepaidDeliveryAmt: 0,
            alreadyPaidTotalDeliveryAmt: 0,
          },
        },
        coupon: {
          cartCouponDiscountAmt: 0,
          productCouponDiscountAmt: 0,
        },
        accumulation: {
          accumulationAmt: 0,
        },
        order: {
          immediateDiscountAmt: 0,
          additionalDiscountAmt: 0,
          standardAmt: 0,
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          remainingPgPayAmt: 0,
          payAmt: 0,
        },
      },
      refund: {
        delivery: {
          deliveryAmt: 0,
          remoteDeliveryAmt: 0,
          totalDeliveryAmt: 0,
          deliveryGroupAmounts: {
            prepaidRemoteDeliveryAmt: 0,
            payOnRemoteDeliveryAmt: 0,
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            payOnDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            prepaidDeliveryAmt: 0,
            totalPrepaidDeliveryAmt: 0,
            alreadyPaidTotalDeliveryAmt: 0,
          },
        },
        coupon: {
          cartCouponDiscountAmt: 0,
          productCouponDiscountAmt: 0,
        },
        accumulation: {
          accumulationAmt: 0,
        },
        order: {
          immediateDiscountAmt: 0,
          additionalDiscountAmt: 0,
          standardAmt: 0,
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          remainingPgPayAmt: 0,
          payAmt: 0,
          totalDeliveryAmt: 0,
        },
      },
    },
    overflowsPgAmt: false,
  };
  private objJson = '';
  private responsibleObjectType = 'BUYER';
  private refundTypeValue = '';
  private claimOrderOptions!: GetClaimsOrderOptionsOrderOptionNoApply;
  private refundTypeDivShow = true;
  private zeroRefundShow = false;
  private refundBankInfoTrShow = true;
  private holdByReservation = false;
  private escrowOrder = false;
  private orgChargeDeliveryAmt = true;
  private returnAddressButton = false;

  private isPartnerProduct = false;
  private sellerPaysClaimedDelivery = false;
  public setIsPartnerProduct(isPartnerProduct: boolean) {
    this.isPartnerProduct = isPartnerProduct;
  }

  // 최초 predict용 request 객체를 반환하는거로 추정됨. 왜이렇게 했는지 의도는 도저히 파악이 안된다.
  private getPostReturnPredictRequest(): PostReturnsPredictRequest {
    const postReturnsPredictRequest: PostReturnsPredictRequest = {
      data: {
        responsibleObjectType: 'BUYER',
        refundType: 'PG',
        orderNo: '',
        returnDeliveryProposedAmt: 0,
        sellerPaysClaimedDelivery: false,
        returnWayType: this.orderOptionsApply.payType === 'NAVER_PAY' ? 'BUYER_DIRECT_RETURN' : 'SELLER_COLLECT', // 최초 클레임신청된 ReturnWayType을 가져와서 박아야되는데 하드코딩되어있었다. 일단 네이버페이일 경우 BUYER_DIRECT_RETURN 로 set
        returnOrderOptions: [
          {
            claimCnt: 0,
            orderOptionNo: 0,
            reasonType: 'CHANGE_MIND',
            reasonDetail: '',
          },
        ],
        returnAddress: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
      },
    };

    const orderOptionParams: any = [];
    postReturnsPredictRequest.data.responsibleObjectType = 'BUYER';
    postReturnsPredictRequest.data.refundType =
      this.orderOptionsApply.payType === 'NAVER_PAY'
        ? 'PG'
        : (this.orderOptionsApply.refundTypes[0].refundType as RefundType);
    postReturnsPredictRequest.data.orderNo = this.orderOptionsApply.orderNo;
    this.orderOptionsApply.claimableOptions.forEach(item => {
      if (this.escrowOrder) {
        item.checkboxValue = true;
        postReturnsPredictRequest.data.responsibleObjectType = 'SELLER';
        postReturnsPredictRequest.data.sellerPaysClaimedDelivery = true;
      }
      if (item.checkboxValue) {
        orderOptionParams.push({
          claimCnt: item.claimedCnt,
          orderOptionNo: item.orderOptionNo,
          reasonType: 'CHANGE_MIND',
          reasonDetail: '',
        });
      }
    });
    postReturnsPredictRequest.data.returnOrderOptions = orderOptionParams;
    this.returnOrderOptions = orderOptionParams;
    return postReturnsPredictRequest;
  }

  private calculateMethod() {
    this.$api.postReturnsPredict(this.getPostReturnPredictRequest()).then(ret => {
      this.showB = true;
      this.postReturnsPredict = ret.data;
      this.postReturnsPredict.amounts.refund.order.totalDeliveryAmt =
        this.postReturnsPredict.amounts.refund.coupon.cartCouponDiscountAmt -
        this.postReturnsPredict.amounts.refund.delivery.totalDeliveryAmt;
      this.objJson = JSON.stringify(this.postReturnsPredict);
      if (this.postReturnsPredict.amounts.adjustedAmounts.claimAmt === 0) {
        this.refundTypeDivShow = false;
        this.zeroRefundShow = true;
        this.refundBankInfoTrShow = false;
      }
    });
  }

  public getPayType(payType: string) {
    switch (payType) {
      case 'ACCOUNT':
        return 'ACCOUNT';

      case 'ACCUMULATION':
        return 'ACCUMULATION';

      case 'ZERO_PAY':
        return 'ZERO_REFUND';

      case 'VIRTUAL_ACCOUNT':
      case 'ESCROW_VIRTUAL_ACCOUNT':
        return 'PG';

      default:
        return 'PG';
    }
  }

  private async created() {
    const request: GetClaimsOrderOptionsOrderOptionNoApplyRequest = {
      pathParams: {
        orderOptionNo: this.$route.query.orderProductOptionNo.toString(),
      },
      params: {
        claimType: 'RETURN',
      },
    };
    await this.$api
      .getClaimsOrderOptionsOrderOptionNoApply(request)
      .then(ret => {
        this.isShow = true;
        this.claimOrderOptions = ret.data;
        this.refundType = ret.data.refundTypes[0].refundType;

        ret.data.claimableOptions.forEach(item => {
          const claimableOption = {
            ...item,
            checkboxValue: item.orderOptionNo === ret.data.claimedOption.orderOptionNo,
            mallOptionNo: item.mallOptionNo,
            userInputText: item.userInputText,
            orderNo: item.orderNo,
            immediateDiscountAmt: item.immediateDiscountAmt,
            salePrice: item.salePrice,
            orderStatusType: item.orderStatusType,
            optionValue: item.optionValue,
            orderCnt: item.orderCnt,
            addPrice: item.addPrice,
            mallProductNo: item.mallProductNo,
            claimedCnt: item.claimedCnt,
            productName: item.productName,
            additionalDiscountAmt: item.additionalDiscountAmt,
            optionName: item.optionName,
            orderOptionNo: item.orderOptionNo,
            partnerNo: Number(ret.data.partnerNo),
            partnerName: ret.data.partnerName,
            payType: ret.data.payType,
            productUrl: item.productUrl,
            usesOption: item.usesOption,
            hasSetOption: item.hasSetOption,
            setOptions: item.setOptions,
            holdByReservation: item.holdByReservation,
            optionManagementCd: item.optionManagementCd,
          };
          this.holdByReservation = item.holdByReservation;
          this.orderOptionsApply.claimableOptions.push(claimableOption);
        });

        this.orderOptionsApply.refundTypes = ret.data.refundTypes;
        this.orderOptionsApply.payType = ret.data.payType;
        this.orderOptionsApply.orderNo = ret.data.claimedOption.orderNo;
        this.deliveryAddress = this.claimOrderOptions.deliveryAddress !== null;
        this.escrowOrder =
          this.orderOptionsApply.payType.indexOf('ESCROW_REALTIME_ACCOUNT_TRANSFER') >= 0 ||
          this.orderOptionsApply.payType.indexOf('ESCROW_VIRTUAL_ACCOUNT') >= 0;
        this.showA = true;

        this.calculateMethod();
      })
      .catch(() => {
        // alert(error.data.message);
        window.opener.location.reload();
        window.close();
      });
  }
  private validationBeforeApply(): boolean {
    if (this.checkHoldByReservation()) {
      return false;
    }
    let needBankAccount = false;

    if (this.refRefundExchangeAmount.zeroRefundShow) {
      this.refundTypeValue = 'ZERO_REFUND';
    }
    switch (this.refRefundExchangeAmount.refundTypeValue) {
      case 'CASH':
      case 'ACCOUNT':
        needBankAccount = this.refundTypeValue !== 'ZERO_REFUND';
        break;
      case 'PG':
        if (
          this.orderOptionsApply.payType === 'VIRTUAL_ACCOUNT' ||
          this.orderOptionsApply.payType === 'ESCROW_VIRTUAL_ACCOUNT'
        ) {
          needBankAccount = true;
        }
        break;
    }

    if (needBankAccount && this.refRefundExchangeAmount.refundBankValue === '') {
      alert(this.$t('CLAIM.RETURNPOPUP.ALERT4'));
      (this.refRefundExchangeAmount.$refs.refundBank as TextInput).focus();
      return false;
    }
    if (needBankAccount && this.refRefundExchangeAmount.refundBankAccount === '') {
      alert(this.$t('CLAIM.RETURNPOPUP.ALERT5'));
      (this.refRefundExchangeAmount.$refs.refundBankAccountRef as TextInput).focus();
      return false;
    }
    if (needBankAccount && this.refRefundExchangeAmount.refundBankDepositorName === '') {
      alert(this.$t('CLAIM.RETURNPOPUP.ALERT6'));
      (this.refRefundExchangeAmount.$refs.refundBankDepositorNameRef as TextInput).focus();
      return false;
    }
    if (
      this.refReturnShippingFee.returnDeliveryAmtAdjustAmt > 0 &&
      this.refReturnShippingFee.returnDeliveryAmtAdjustReason === ''
    ) {
      alert(this.$t('CLAIM.RETURNPOPUP.ALERT7'));
      (this.refReturnShippingFee.$refs.returnDeliveryAmtAdjustReasonRef as TextInput).focus();
      return false;
    }
    //반품주소지 정보 valid
    if (this.postReturnsPredict.shipping.deliveryType !== 'NONE') {
      if (this.refReturnAddress.returnName === '') {
        alert(this.$t('CLAIM.RETURNPOPUP.ALERT8'));
        (this.refReturnAddress.$refs.returnNameRef as TextInput).focus();
        return false;
      }
      if (this.refReturnAddress.returnContact1 === '') {
        alert(this.$t('CLAIM.RETURNPOPUP.ALERT9'));
        (this.refReturnAddress.$refs.returnContact1Ref as TextInput).focus();
        return false;
      }
      if (this.refReturnAddress.zipCd === '' || this.refReturnAddress.address === '') {
        alert(this.$t('CLAIM.RETURNPOPUP.ALERT10'));
        (this.refReturnAddress.$refs.returnDetailAddress as TextInput).focus();
        return false;
      }
    }
    return true;
  }

  private checkHoldByReservation(): boolean {
    return this.holdByReservation === true && !confirm(this.$t('CLAIM.CANCELPOPUP.CONFIRM').toString());
  }

  private onClickApplyReturn() {
    if (this.refOrderProducts.optionNo.length <= 0) {
      alert(this.$t('CLAIM.RETURNPOPUP.ALERT2'));
      return;
    }

    if (
      this.refRefundExchangeAmount.refundTypeValue !== 'ACCOUNT' &&
      this.refRefundExchangeAmount.refundTypeValue !== 'ACCUMULATION' &&
      this.postReturnsPredict.overflowsPgAmt
    ) {
      alert(this.$t('CLAIM.RETURNPOPUP.ALERT3'));
      return;
    }

    if (this.validationBeforeApply() === false) {
      return;
    }

    if (!confirm(this.$t('CLAIM.RETURNPOPUP.CONFIRM') as string)) {
      return;
    }

    const orderOptionParams: any = [];
    for (let i = 0; i < this.refOrderProducts.optionNo.length; i++) {
      const claimCnt = this.refOrderProducts.orderCntMap.get(this.refOrderProducts.optionNo[i]) || 0;
      orderOptionParams.push({
        claimCnt: claimCnt,
        orderOptionNo: this.refOrderProducts.optionNo[i],
        reasonType: this.refOrderProducts.claimReasonType[i] as ReasonType,
        reasonDetail: this.refOrderProducts.claimReasonDetail[i] as string,
      });
    }

    let bank = '';
    let bankNm = '';
    let refundType;
    if (this.refRefundExchangeAmount.refundBankEditShow === false) {
      bank = null;
      bankNm = null;
    } else {
      bank = this.refRefundExchangeAmount.refundBankValue;
      this.refRefundExchangeAmount.refundBankList.forEach((element: { value: string; name: string }) => {
        if (element.value === this.refRefundExchangeAmount.refundBankValue) {
          bankNm = element.name;
        }
      });
    }
    if (this.refRefundExchangeAmount.claimTotal === 0) {
      refundType = 'ZERO_REFUND';
    } else {
      refundType = this.refRefundExchangeAmount.refundTypeValue;
    }

    const request: PostReturnsRequest = {
      data: {
        returnDeliveryAmtAdjustReason: this.refReturnShippingFee.returnDeliveryAmtAdjustReason,
        reasonType: this.isOnlyOneClaim(orderOptionParams) ? orderOptionParams[0].claimReasonType : null,
        reasonDetail: this.isOnlyOneClaim(orderOptionParams) ? orderOptionParams[0].reasonDetail : null,
        calculateParam: {
          responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue as ResponsibleObjectType,
          refundType: refundType,
          orderNo: this.orderOptionsApply.orderNo,
          returnDeliveryProposedAmt: this.refReturnShippingFee.returnDeliveryAmtAdjustAmt,
          sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
          returnWayType: this.deliveryAddress ? this.refReturnAddress.returnWayType : null,
          returnOrderOptions: uniqBy<ReturnOrderOption>(
            orderOptionParams?.filter(option => Boolean(option?.reasonType)),
            'orderOptionNo',
          ) as PostReturnsRequest['data']['calculateParam']['returnOrderOptions'],
          returnAddress: this.deliveryAddress
            ? {
                address: this.refReturnAddress.address,
                name: this.refReturnAddress.returnName,
                jibunAddress: this.refReturnAddress.returnJibunAddress,
                detailAddress: this.refReturnAddress.detailAddress,
                zipCd: this.refReturnAddress.zipCd,
                contact1: this.refReturnAddress.returnContact1,
                contact2: this.refReturnAddress.returnContact2,
              }
            : null,
        },
        deliveryCompanyType: this.deliveryAddress
          ? this.refReturnAddress.deliveryCompanyType.length
            ? this.refReturnAddress.deliveryCompanyType
            : null
          : null,
        returnDeliveryNote: this.deliveryAddress ? this.refReturnAddress.returnDeliveryNote : null,
        invoiceNo: this.deliveryAddress ? this.refReturnAddress.invoiceNo : '',
        evadesReturnProcess: this.deliveryAddress ? this.refReturnAddress.unApprovedYn : null,
        refundBankAccount: {
          depositorName: this.refRefundExchangeAmount.refundBankDepositorName,
          bank: bank,
          bankName: bankNm,
          account: this.refRefundExchangeAmount.refundBankAccount,
        },
      },
    };

    this.$api.postReturns(request).then(() => {
      alert(this.$t('CLAIM.CANCELPOPUP.PROCESS_SUCCESS_MESSAGE'));
      window.opener.location.reload();
      window.close();
    });
  }

  private isOnlyOneClaim(orderOptionParams: unknown[]) {
    return orderOptionParams.length === 1;
  }

  private beforeReturnDeliveryAmtAdjustAmt = 0;
  private returnWayType = 'SELLER_COLLECT';

  private setReturnOrderOptions(orderOptionParams: ReturnOrderOptions[]) {
    this.returnOrderOptions = orderOptionParams;
  }

  private setData(isSetDefault = true) {
    // claimOrderOptions.deliveryAddress 가 없는경우 반품수거방법UI가 안나타남.
    // 반품수거방법UI가 안나타나면 아래코드로인해 setData가 얼리리턴됨.
    // 현재로서 해당코드가 정책상반드시 필요한건지. 버그막으려고 넣은건지 확인이안됨.
    // 일단 주석으로 코드살려두고 관련이슈가 생긴다면 다시 확인해볼필요있음.
    // if (!this.refReturnAddress) return;

    // 아래 'const request'에서 ref로 하위 컴포넌트의 값을 가지고오고있어서 스크립트상에서 오류가발생함.
    // 코드가 너무 꼬여있어서 이렇게 처리해야할거같음..
    if (!this.refRefundExchangeAmount) {
      return;
    }

    if (this.deliveryAddress) {
      if (this.refReturnAddress.returnWayType === 'BUYER_DIRECT_RETURN') {
        if (this.refReturnShippingFee.returnDeliveryAmtAdjustAmt !== 0) {
          alert(this.$t('CLAIM.RETURNPOPUP.ALERT1'));
        } else {
          if (this.refReturnShippingFee.returnDeliveryAmtAdjustReason !== '') {
            alert(this.$t('CLAIM.RETURNPOPUP.ALERT1'));
          }
        }
        this.refReturnShippingFee.clearText(0);
      }
    }
    const request = {
      data: {
        responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue,
        refundType: this.refRefundExchangeAmount.refundTypeValue as RefundType,
        orderNo: this.orderOptionsApply.orderNo,
        returnDeliveryProposedAmt: this.refReturnShippingFee.returnDeliveryAmtAdjustAmt,
        sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
        returnWayType: this.deliveryAddress ? this.refReturnAddress.returnWayType : null,
        returnOrderOptions: this.returnOrderOptions,
        returnAddress: this.deliveryAddress
          ? {
              address: this.refReturnAddress.address,
              name: this.refReturnAddress.returnName,
              jibunAddress: this.refReturnAddress.returnJibunAddress,
              detailAddress: this.refReturnAddress.detailAddress,
              zipCd: this.refReturnAddress.zipCd,
              contact1: this.refReturnAddress.returnContact1,
              contact2: this.refReturnAddress.returnContact2,
            }
          : null,
      } as ReturnsCalculate,
    };

    if (!this.isPartnerProduct && this.refOrderProducts.responsibleObjectTypeValue === 'SELLER') {
      request.data.sellerPaysClaimedDelivery = true;
    } else if (this.refOrderProducts.responsibleObjectTypeValue === 'SELLER') {
      request.data.sellerPaysClaimedDelivery = this.sellerPaysClaimedDelivery;
    }

    if (!this.validation(request)) {
      return;
    }
    this.$api.postReturnsPredict(request).then(ret => {
      this.showB = true;
      this.postReturnsPredict = ret.data;
      this.postReturnsPredict.amounts.refund.order.totalDeliveryAmt =
        this.postReturnsPredict.amounts.refund.coupon.cartCouponDiscountAmt -
        this.postReturnsPredict.amounts.refund.delivery.totalDeliveryAmt;
      this.objJson = JSON.stringify(this.postReturnsPredict);
      if (
        this.postReturnsPredict.amounts.adjustedAmounts.claimAmt === 0 &&
        !(this.orderOptionsApply.payType === 'ZERO_PAY')
      ) {
        this.refundTypeDivShow = false;
        this.zeroRefundShow = true;
        this.refundBankInfoTrShow = false;
      } else {
        this.refundTypeDivShow = true;
        this.zeroRefundShow = false;
        this.refundBankInfoTrShow = true;
      }
      if (this.postReturnsPredict.amounts.adjustedAmounts.claimAmt > 0) {
        this.zeroRefundShow = true;
        this.refundTypeDivShow = false;
      }
      if (isSetDefault) {
        this.refundTypeValue = request.data.refundType;
      }
    });
    this.refOrderProducts.claimCalculation(isSetDefault);
  }

  private validation(postReturnsPredictRequest: PostReturnsPredictRequest): boolean {
    if (
      this.postReturnsPredict.amounts.returnDelivery.returnDeliveryAmt < 0 ||
      this.postReturnsPredict.amounts.returnDelivery.returnRemoteDeliveryAmt < 0
    ) {
      if (postReturnsPredictRequest.data.returnDeliveryProposedAmt > 0) {
        alert(this.$t('CLAIM.RETURNPOPUP.ALERT11'));
        this.refReturnShippingFee.clearText(this.beforeReturnDeliveryAmtAdjustAmt);
        return false;
      }
    } else if (
      postReturnsPredictRequest.data.returnDeliveryProposedAmt >
      this.postReturnsPredict.amounts.returnDelivery.returnDeliveryAmt +
        this.postReturnsPredict.amounts.returnDelivery.returnRemoteDeliveryAmt
    ) {
      alert(this.$t('CLAIM.RETURNPOPUP.ALERT11'));
      this.refReturnShippingFee.clearText(this.beforeReturnDeliveryAmtAdjustAmt);
      return false;
    }
    this.beforeReturnDeliveryAmtAdjustAmt = postReturnsPredictRequest.data.returnDeliveryProposedAmt;

    if (this.claimOrderOptions.deliveryAddress !== null) {
      if (postReturnsPredictRequest.data.returnAddress.name === '') {
        alert(this.$t('CLAIM.RETURNPOPUP.ALERT12'));
        (this.refReturnAddress.$refs.returnNameRef as TextInput).focus();
        return false;
      }
      if (postReturnsPredictRequest.data.returnAddress.contact1 === '') {
        alert(this.$t('CLAIM.RETURNPOPUP.ALERT9'));
        (this.refReturnAddress.$refs.returnContact1Reff as TextInput).focus();
        return false;
      }
      if (this.refReturnAddress.zipCd === '' || this.refReturnAddress.address === '') {
        alert(this.$t('CLAIM.RETURNPOPUP.ALERT10'));
        (this.refReturnAddress.$refs.returnDetailAddressf as TextInput).focus();
        return false;
      }
    }

    return true;
  }

  public close(): void {
    if (!confirm(this.$t('CLAIM.RETURNPOPUP.CLOSECONFIRM').toString())) {
      return;
    }
    window.close();
  }

  private clickReturnAddressButton(type) {
    type === 'open' ? (this.returnAddressButton = true) : (this.returnAddressButton = false);
  }

  mounted() {
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-1200');
    });
  }
}
