export default Object.freeze({
  POINT_TABLE_TITLE: '조회결과',
  TABLE_HEADER_ORDER_YMDT: '변경 일시',
  TABLE_HEADER_ACCUMULATION: '구분',
  TABLE_HEADER_DETAIL_REASON: '사유',
  TABLE_HEADER_REGISTER_ADMIN: '변경자',
  TABLE_HEADER_ORDER_NO: '주문번호',
  TABLE_HEADER_AMOUNT: '적립',
  TABLE_HEADER_SUBTRACTION_ACCUMULATION: '차감',
  TABLE_HEADER_EXPIRED_DATE_TIME: '유효기간',
  NO_DATA: '검색된 혜택 내역이 없습니다.',
  ACCUMULATION_AMT_USEABLE: '사용가능 임직원포인트',
  EXPIRE_ACCUMULATION_NEXT_MONTH: '익월 소멸예정 임직원포인트',
  STATUS_TITLE: '구분',
  STATUS_ALL: '전체',
  STATUS_GIVE: '적립',
  STAUTS_SUBTRACTION: '차감',
  PERIOD_TYPE_TITLE: '기간',
  SEARCH_BTN: '조회',
  CANCEL_BTN: '초기화',
  OPEN_ACCUMULATION_BTN: '적립금 지급/차감',
  PERIOD_TYPE_REGISTER: '지급일 기준',
  PERIOD_TYPE_EXPIRE: '만료일 기준',
  UNIT: 'won',
  P_UNIT: 'p',
});
