export default Object.freeze({
  NOTICE_REGISTRATION: '공지사항 등록',
  NOTICE_MODIFICATION: '공지사항 수정',
  TITLE: '제목',
  PUBLIC_AVAILABILITY: '공개여부',
  OPEN: '공개',
  PRIVATE: '비공개',
  SHOPPING_MALL: '쇼핑몰',
  NOTICE_CATEGORY: '공지사항 카테고리',
  WRITER: '작성자',
  CREATION_DATE: '작성일시',
  FINALIST: '최종수정자',
  LAST_MODIFICATION_DATE: '최종수정일시',
  POST_NUMBER: '게시글번호',
  VIEWS: '조회수',
  ATTACHED_FILES: '첨부파일 (최대10개)',
  FIND_FILES: '파일찾기',
  CANCEL: '취소',
  ALERT_SIZEFILE: '파일용량이 5MB를 초과하였습니다.',
  ALERT_SUBJECT: '제목을 입력해 주세요.',
  ALERT_MALL: '쇼핑몰을 선택해 주세요.',
  ALERT_CATEGORY: '공지사항 카테고리를 선택해 주세요.',
  ALERT_CONTENT: '내용을 입력해 주시기 바랍니다.',
  ALERT_LENGTH: '본문의 길이가 너무 깁니다.',
  MODIFY: '수정',
  ENROLLMENT: '등록',
  NOTICE: '공지사항을',
  WILL_DO: '하시겠습니까?',
  WRITE: '작성',
  SURE_CANCEL: '취소하시겠습니까?',
  IS_NOT_SAVED: '하신 내용은 저장되지 않습니다.',
  REVISE_SUCCESS: '공지사항이 수정되었습니다.',
});
