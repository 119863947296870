



import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: {
    AdminIframe,
  },
})
export default class ExcelUploadIframe extends Vue {
  private get remoteSrc() {
    const query = this.$route.query;
    const queryObject = { ...query, serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();
    const src =
      process.env.VUE_APP_ADMIN_REMOTE_URL + '/popup/display-category-excel-batch-register' + '?' + queryString;
    return src;
  }

  private receiveMessage(e: MessageEvent) {
    if (e.data === 'SUCCESS') {
      window.opener.postMessage('SUCCESS');
    }
  }

  mounted() {
    window.addEventListener('message', this.receiveMessage);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  }
}
