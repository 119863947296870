import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ReviewInfo, GetReviewsQuery } from '@/types/review';
import { Mall, GetReviewsRequest, GetReviewInfo, ReviewDisplayStatusType, YorN } from 'ncp-api-supporter';
// reviews api 关联 mixin (reviews api 관련 mixin)
@Component
export default class ReviewsMixin extends Vue {
  public reviews: GetReviewInfo[] = [];
  public reviewsTotalCount = 0;
  /**
   * admin had malls
   */
  @Getter('mall/getMalls') private stateMalls!: Mall[];
  /**
   * get product review list
   * @param query GetReviewsQuery
   */
  getReviews(query: GetReviewsQuery): void {
    const request = this.getReviewsRequest(query);
    this.$api.getReviews(request).then(res => {
      const { contents, totalCount } = res.data;
      this.reviews = this.addSomePropertyToList(contents);
      this.reviewsTotalCount = totalCount;
    });
  }
  /**
   * add some property to list
   * @param reviews GetReviewInfo
   * @returns
   */
  addSomePropertyToList(reviews: GetReviewInfo[]): ReviewInfo[] {
    const createdReviews: ReviewInfo[] = [];

    reviews.forEach(review => {
      const findedMall = this.stateMalls.find(mall => mall.mallNo === review.mallNo);
      const createdReview: ReviewInfo = {
        ...review,
        mallName: findedMall ? findedMall.mallName : '',
        reviewType: review.isBestReview ? 'BEST' : 'NORMAL',
      };
      createdReviews.push(createdReview);
    });
    return createdReviews;
  }
  /**
   * create reuqest
   * @param param GetReviewsQuery
   * @returns GetReviewsRequest
   */
  getReviewsRequest({
    mallNo,
    startDate,
    endDate,
    writerName,
    searchType,
    keyword,
    reviewDisplayStatusType,
    reviewType,
    page,
    size,
    mallProductNo,
    memberNo,
    reviewWrittenType,
    rating,
  }: GetReviewsQuery): GetReviewsRequest {
    return {
      params: {
        mallNo,
        startDate,
        endDate,
        writerName,
        searchType,
        keyword,
        reviewDisplayStatusType,
        reviewType,
        page,
        size,
        mallProductNo,
        memberNo,
        reviewWrittenType,
        rating,
      },
    };
  }
  /**
   * change review displayStatusType
   * @param reviewNo string
   * @param displayStatusType ReviewDisplayStatusType
   */
  public async putReviewDisplayStatusType(reviewNo: string, displayStatusType: ReviewDisplayStatusType) {
    const request = {
      pathParams: {
        reviewNo,
      },
      data: {
        reason: '',
        displayStatusType,
      },
    };
    await this.$api.putReviewsReviewNoStatus(request);
  }
  /**
   * change review bestReviewYn
   * @param reviewNo string
   * @param bestReviewYn YorN
   */
  public async putReviewBestReview(reviewNo: string, bestReviewYn: YorN) {
    const request = {
      pathParams: {
        reviewNo,
      },
      data: {
        bestReviewYn,
      },
    };
    await this.$api.putReviewsReviewNoBestReview(request);
  }
}
