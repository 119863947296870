







import { Vue, Component } from 'vue-property-decorator';
import { i18nListByKey } from '@/utils/i18n';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';
@Component({ components: { TabMenu } })
export default class Product extends Vue {
  private menuList: TabMenuI[] = [
    //2차 오픈시 열기
    { display: 'MARKETING.CONFIGURATION.NAVER_SHOPPING', path: 'naver-shopping' },
    { display: 'MARKETING.CONFIGURATION.GOOGLE_AD', path: 'google' },
    // { display: 'MARKETING.CONFIGURATION.FACEBOOK', path: 'facebook' },
  ];
  created() {
    this.menuList = i18nListByKey(this, this.menuList, 'display');
  }
}
