




import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';
import PopupForm from './PopupForm.vue';

@Component({ components: { AdminIframe, PopupForm } })
export default class PopupFormContent extends Vue {
  private get remoteSrc() {
    const queryObject = {
      ...this.$route.query,
      serviceType: 'PRO',
    };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + this.$route.path + '?' + queryString;

    return src;
  }
}
