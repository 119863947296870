




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import {
  GetSmsUnsubscribe,
  GetSmsUnsubscribeRequest,
  NCPResponse,
  PostSmsUnsubscribeSynchronize,
  PostSmsUnsubscribeSynchronizeRequest,
  SmsUnsubscribe,
} from 'ncp-api-supporter';
import { getDisplayOptions, getReject080ListGridOption } from '@/const/contents/sms';
import { getCurrentMallNo } from '@/utils/mall';
import moment from 'moment';
@Component({
  components: { SearchButton, Grid },
})
export default class Reject080List extends Vue {
  // private searchKeyword = '';
  private contents: SmsUnsubscribe[] = [];
  private totalCount = 0;
  private gridOption = getReject080ListGridOption();
  private displayOptions = getDisplayOptions();
  private query = this.getQueryParams();

  private getQueryParams(): GetSmsUnsubscribeRequest['params'] {
    return {
      mallNo: getCurrentMallNo(this),
      page: 1,
      pageSize: 30,
      keyword: '',
      ...this.$route.query,
    };
  }

  private resetValue() {
    this.query.keyword = '';
  }

  private async manualSync() {
    const request: PostSmsUnsubscribeSynchronizeRequest = {
      params: { mallNo: getCurrentMallNo(this) },
    };
    try {
      const response: NCPResponse<PostSmsUnsubscribeSynchronize> = await this.$api.postSmsUnsubscribeSynchronize(
        request,
      );
      if (response && response.status !== 200) return;

      alert(this.$t('MEMBER.REJECT080.SYNC_COMPLETE_MESSAGE'));
    } catch (e) {
      console.error(e);
    }
  }

  @Watch('$route.query')
  private async searchRejectList() {
    this.query = { ...this.query, ...this.$route.query };

    try {
      const response: NCPResponse<GetSmsUnsubscribe> = await this.$api.getSmsUnsubscribe({ params: this.query });
      this.totalCount = response.data.totalCount;

      const dateTime = (a: SmsUnsubscribe, b: SmsUnsubscribe) =>
        moment(b.registerDateTime)
          .toDate()
          .getTime() -
        moment(a.registerDateTime)
          .toDate()
          .getTime();
      this.contents = response.data.contents.sort(dateTime); // 최신순 정렬
    } catch (e) {
      console.error(e);
    }
  }

  private sendQueryUrl() {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }

  created() {
    this.searchRejectList();
  }
}
