





















import { Component } from 'vue-property-decorator';
import CouponListSearchForm from '@/views/top/promotion/basic/CouponList.vue';
import CouponListGrid from '@/views/contents/promotion/basic/CouponList.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { throwMessagePopup } from '@/helpers/popup';
import { SelectedCouponInfo } from '@/types';
import { GetCouponsIssueCheckIssualeRequset, GetCouponsIssueCheckIssuale, NCPResponse } from 'ncp-api-supporter';

@Component({
  components: { CouponListSearchForm, CouponListGrid },
})
export default class InquiryCoupon extends WindowPopupMainVue {
  private selectedCoupon: SelectedCouponInfo = null;

  private async saveSelectedCoupons() {
    if (this.selectedCoupon.coupons.length <= 0) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT'));
      return;
    }
    if (this.data.fromPage === 'fromCouponManagement') {
      if (!(await this.clickCheck())) {
        return;
      }
    }
    this.onPositiveClick(this.selectedCoupon);
  }
  private async clickCheck() {
    const obj: GetCouponsIssueCheckIssualeRequset = {
      params: {
        couponNos: this.selectedCoupon.coupons.join(',').toString(),
        couponIssuePlanTimeType: this.data.couponIssuePlanTimeType,
      },
    };

    const response: NCPResponse<GetCouponsIssueCheckIssuale> = await this.$api.getCouponsIssueCheckIssuale(obj);
    if (response.data.notIssuableCouponNos && response.data.notIssuableCouponNos.length > 0) {
      if (
        !confirm(
          this.data.popType == 'INSERT'
            ? this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_COUPON_NOUSEABLE_PERIOD_ISSUE').toString()
            : this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_COUPON_NOUSEABLE').toString(),
        )
      ) {
        return false;
      } else {
        response.data.notIssuableCouponNos.forEach(item => {
          const index = this.selectedCoupon.coupons.indexOf(item);
          this.selectedCoupon.coupons.splice(index, 1);
          this.selectedCoupon.couponItems.splice(index, 1);
        });
      }
    }
    if (this.selectedCoupon.coupons.length <= 0) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT'));
      return false;
    }

    if (this.selectedCoupon.coupons.length > 10) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT3'));
      return false;
    }
    return true;
  }
  created() {
    if (!(this.data && this.data.mallNo)) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_MALL_IS_EMPTY')).then(() => {
        this.closePopup();
      });
    } else {
      this.selectedCoupon = {
        coupons: [],
        couponItems: [],
        mallNo: this.data.mallNo,
        popType: this.data.popType,
      };
    }
  }
}
