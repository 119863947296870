

















import { Component, Mixins } from 'vue-property-decorator';
import OrderDetails from '@/components/shipping/order/mixins/OrderDetail';
import { Mall, PreviousOrdersOrderNoResponse } from 'ncp-api-supporter';
import { namespace } from 'vuex-class';

const mallStore = namespace('mall');

@Component({
  components: {},
})
export default class OrderDetail extends Mixins(OrderDetails) {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private mallNm = '';
  private orderNo = this.$route.query.orderNo;
  private orderDetail: PreviousOrdersOrderNoResponse | null = null;

  private async fetchOrderDetail() {
    const request = { orderNo: this.$route.query.orderNo.toString() };
    const { data } = await this.$api.getPreviousOrdersOrderNo({ pathParams: request });

    this.orderDetail = data;
  }

  private setMallName() {
    const currentMall = this.malls.find(mall => mall.mallNo === this.orderDetail.mallNo);
    this.mallNm = currentMall.mallName;
  }

  async created() {
    await this.fetchOrderDetail();
    this.setMallName();
  }
}
