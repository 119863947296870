import { LnbMenu } from '@/const/lnb';

export const getCurrentMenu = (path: string, lnbMenus: LnbMenu[]): LnbMenu => {
  if (!path || path === '/') {
    return lnbMenus[0];
  }

  let current = lnbMenus[0];

  let arr = [...lnbMenus];

  while (arr.length > 0) {
    const temp = arr.shift();

    if (path.includes(temp.uri)) {
      if (path !== '/promotion/basic/coupons' || temp.uri !== '/promotion/basic/coupon') {
        current = temp;
      }
    }

    if (temp.menus) {
      arr = arr.concat(temp.menus);
    }
  }

  return current;
};
