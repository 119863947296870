

























import { Vue, Component, Prop } from 'vue-property-decorator';
import Tree from '@/components/common/tree/Tree.vue';
import { NestedTreeNode, SelectTreeEvent, TreeOptions } from '@/types/tree';
import nodeElements from '@/views/contents/member/email/tree/nodeElements';

@Component({
  components: {
    Tree,
  },
})
export default class TreeContainer extends Vue {
  @Prop({ required: true })
  private readonly treeNodes!: NestedTreeNode[];
  @Prop({ default: 1 })
  private readonly defaultSelectNodeIndex!: number;
  @Prop({ required: true })
  private readonly checkUnsavedForm!: (event: SelectTreeEvent, { prevNodeData, nextNodeData }) => boolean;

  private readonly nodeElements = nodeElements();

  private get options(): TreeOptions {
    return {
      internalNodeName: this.$t('CATEGORY').toString(),
      leafNodeName: this.$t('CATEGORY').toString(),
      defaultSelection: true,
      defaultSelectNodeIndex: this.defaultSelectNodeIndex,
      defaultNodeData: {
        children: [],
        used: true,
      },
      customKeywords: {
        nodeName: this.$t('PRODUCT.CATEGORIZATION.NEW').toString(),
        nodeLabel: this.$t('PRODUCT.CATEGORIZATION.NAME').toString(),
      },
    };
  }
}
