import store from '@/store';
import { BottomNavObject } from '@/types/bottomNav';

/*
* [사용 방법]
* : 공통의 하단 버튼 메뉴 UI
*
* buttons : 화면에 출력될 버튼 배열
* type : 왼쪽 혹은 오른쪽 영역에 순차대로 배치
* key : 클릭 응답으로 되돌려 받을 버튼 고유 키값 - 이것으로 어떤 버튼이 눌렸는지 하나의 함수에서 관리
* text : 버튼 텍스트
* color : type-white, gray, red, black
* onClick : 클릭 시 콜백 함수
*
* 하단 버튼 메뉴가 필요한 컴포넌트에서 다음과 같이 호출한다.
* import {throwBottomNavigation} from '@/helpers/bottomNav';
* ...
* created(){
* ...
*     throwBottomNavigation({
      buttons: [
        {
          type: 'left',
          key: 'copy',
          color: 'white',
          text: '복사등록'
        },
        {
          type: 'left',
          key: 'tempSave',
          color: 'white',
          text: '임시저장'
        },
        {
          type: 'right',
          key: 'save',
          color: 'red',
          text: '저장하기'
        }
      ],
      onClick: function(key: string) {
        switch (key) {
          case 'copy': //do something;
            break;
          case 'tempSave': //do something;
            break;
          case 'save': //do something;
            break;
        }
      }
    });
* }
* */

// TODO : 버튼이 많은 페이지가 많으면 코드가 길어질 수 있다. 기획서 확인 후 중복 버튼을 확인하고 중복 버튼에 대한 고려
// TODO : 삭제는 router에서 라우팅 시 처리하고 있다. 상황에 따라 처리할것. (참고 @/router/hooks.ts)

export const throwBottomNavigation = function(options: BottomNavObject) {
  store.commit('bottomNav/SET_BOTTOM_NAV', options);
};

export const clearBottomNavigation = function() {
  store.commit('bottomNav/UNSET_BOTTOM_NAV');
};
