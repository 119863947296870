





























































































import { Vue, Component, Ref } from 'vue-property-decorator';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { Getter } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
import { getWithdrawalMemberInitQuery, getWithdrawalMemberSelectType } from '@/const/contents/member';
import { WithdrawalMemberQuery } from '@/types/member';
import MallSelect from '@/components/common/input/MallSelect.vue';

@Component({ components: { MallSelect, DateRangePicker, SearchButton, SelectBox } })
export default class WithdrawalMember extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  @Ref() private readonly searchButton!: SearchButton;

  private selectType = getWithdrawalMemberSelectType();

  private query: WithdrawalMemberQuery = getWithdrawalMemberInitQuery();

  private startDateTime = this.query.startDateTime.slice(0, 10);
  private endDateTime = this.query.endDateTime.slice(0, 10);

  private withdrawalType = this.selectType.withdrawalType[0].value;
  private rejoinability = this.selectType.rejoinabilityType[0].value;
  private joinType = this.selectType.joinType[0].value;

  created() {
    if (!this.$route.query) {
      return;
    }

    Object.assign(this.query, this.$route.query);

    this.startDateTime = this.query.startDateTime.slice(0, 10);
    this.endDateTime = this.query.endDateTime.slice(0, 10);
    this.updateDateTime();

    const forced = this.$route.query.forced as string | boolean;
    if (forced === 'true' || forced === true) {
      this.query.forced = true;
      this.withdrawalType = this.query.forced + '';
    }
    if (forced === 'false' || forced === false) {
      this.query.forced = false;
      this.withdrawalType = this.query.forced + '';
    }

    if (this.$route.query.memberTypes) {
      if (this.$route.query.memberTypes === this.selectType.joinType[1].value) {
        this.joinType = this.selectType.joinType[1].value;
        delete this.query.openIdProviders;
      } else {
        this.joinType = this.query.openIdProviders;
      }
    }
  }

  private updateDateTime(): void {
    this.query.startDateTime = this.startDateTime ? this.startDateTime + ' 00:00:00' : '';
    this.query.endDateTime = this.endDateTime ? this.endDateTime + ' 23:59:59' : '';
  }

  private changeWithdrawalType(): void {
    if (this.withdrawalType === this.selectType.withdrawalType[0].value) {
      delete this.query.forced;
    } else {
      this.query.forced = this.withdrawalType === 'true';
    }
  }

  private changeRejoinability(): void {
    if (this.rejoinability === this.selectType.rejoinabilityType[0].value) {
      delete this.query.rejoinability;
    } else {
      this.query.rejoinability = this.rejoinability === 'true';
    }
  }

  private changeJoinType(): void {
    if (this.joinType === this.selectType.joinType[0].value) {
      delete this.query.memberTypes;
      delete this.query.openIdProviders;
      return;
    }

    if (this.joinType === this.selectType.joinType[1].value) {
      this.query.memberTypes = this.joinType;
      delete this.query.openIdProviders;
      return;
    }

    this.query.memberTypes = 'OPEN_ID';
    this.query.openIdProviders = this.joinType;
  }

  // click reset button
  private reset(): void {
    this.query = getWithdrawalMemberInitQuery();

    this.startDateTime = this.query.startDateTime.slice(0, 10);
    this.endDateTime = this.query.endDateTime.slice(0, 10);
    this.withdrawalType = this.selectType.withdrawalType[0].value;
    this.rejoinability = this.selectType.rejoinabilityType[0].value;
    this.joinType = this.selectType.joinType[0].value;
  }

  private sendQueryUrl(): void {
    this.searchButton.sendQueryUrl();
  }
}
