export default Object.freeze({
  PLEASE_MALL: '쇼핑몰을 선택하세요.',
  PERIOD_SEARCH: '기간검색',
  PLATFORM_TYPE: '플랫폼 구분',
  DESIGN_SKIN: '디자인 스킨',
  DISPLAY_STATUS: '노출여부',
  BANNER_GROUP: '배너 그룹',
  PLEASE_SKIN: '디자인 스킨을 선택하세요.',
  ALL: '통합검색',
  SKIN_CODE: '디자인 스킨 코드',
  GROUP_NAME: '배너 그룹명',
  BANNER_LINK_URL: '배너 링크주소',
  REGISTER: '등록일',
  UPDATE: '수정일',
  VISIBLE: '노출함',
  INVISIBLE: '노출 안 함',
  PC_MALL: 'PC쇼핑몰',
  MOBILE_MALL: '모바일쇼핑몰',
  PLEASE_BANNER_GROUP: '배너 그룹을 선택하세요.',
  USING_OR_WORKING: ' - 사용/작업중인스킨',
  USING: ' - 사용중인스킨',
  WORKING: ' - 작업중인스킨',
  LOGO: '로고 전용',
  SLIDE: '움직이는 배너',
  NORMAL: '일반 배너',
  DELETE: '선택삭제',
  BANNER_REGISTER: '배너 등록',
  VISIBLE_CHANGE: '노출여부가 변경되었습니다.',
  SURE_CHANGE: '{0} 배너를 {1} 상태로 변경하시겠습니까?',
  PLEASE_CHOOSE_DELETE: '삭제할 배너를 선택해 주세요.',
  SURE_DELETE: '선택한 {0}개의 배너를 정말로 삭제 하시겠습니까?<br>삭제시 복구가 불가능합니다.',
  DELETE_ING: '선택한 {0}개의 배너를 삭제 중입니다.',
  POPUP_TITLE: '배너 삭제',
  DELETE_FAIL: '삭제 실패하였습니다',
  DELETE_ALREADY: '삭제되었습니다.',
  PLEASE_SELECT_MALL: '쇼핑몰을 선택해 주세요.',
  PLEASE_SELECT_SKIN: '디자인 스킨을 선택해 주세요.',
  PLEASE_SELECT_BANNER_GROUP: '배너 그룹을 선택해 주세요.',
  PLEASE_BANNER_TITLE: '배너 제목을 입력해 주세요.',
  PLEASE_BANNER_SIZE: '배너 사이즈를 입력해 주세요.',
  PLEASE_BANNER_IMAGE: '배너 이미지를 등록해 주세요.',
  BASIC_INFO: '기본정보',
  BANNER_TITLE: '배너 제목',
  PLEASE_TITLE: '배너 제목을 입력해주세요.',
  PERIOD_TIME: '노출기간',
  BANNER_IMAGE_SETTING: '배너 이미지 설정',
  BANNER_SIZE: '배너 사이즈',
  SAME_SIZE: '등록한 이미지 사이즈대로 노출',
  WIDTH: '가로크기:',
  WIDTH_TOOLTIP:
    '가로 사이즈를 %로 설정할 경우, 세로 사이즈는 가로 사이즈에 따라 자동 비율 조정되어 노출됩니다.\n여러 장의 이미지는 동일한 사이즈로 등록해주세요.\n 다른 사이즈의 이미지 등록 시 배너가 틀어져 보일 수 있습니다.모바일웹 배너 사이즈는 가로크기 100%로 노출됩니다.',
  HEIGHT: '×세로크기:',
  BANNER_IMAGE: '배너 이미지',
  BANNER_IMAGE_TOOLTIP:
    '웹 접근성 준수 의무화에 따라 배너 이미지 설명을 입력하여 배너를 생성하는 것을 권장합니다.\n사용하지 않는 배너 이미지는 삭제하시는 것을 권장합니다.',
  PLEASE_CHOOSE_GROUP: '배너 그룹을 선택하세요.',
  FIND_FILE: '파일찾기',
  DELETE_FILE: '파일삭제',
  LINK_URL: '링크주소',
  PLEASE_LINK: '이미지 링크를 입력해주세요.',
  SELF: '현재창',
  BLANK: '새창',
  IMAGE_INFO: '이미지 설명',
  PLEASE_IMAGE_INFO: '이미지 설명을 입력해주세요.',
  BANNER_ADD: '배너 추가',
  ORDER: '순서',
  IMAGE: '이미지',
  BANNER_EVERY_INFO: '배너등록/링크/이미지 설명',
  NAVIGATION_BTN: '네비게이션 활성 버튼',
  NAVIGATION_NOT_BTN: '네비게이션 비활성 버튼',
  ALWAYS: '상시 노출',
  NOT_ALWAYS: '상시 노출 안 함',
  PERIOD: '기간 노출',
  BANNER_ALL_SETTING: '배너 이미지 노출 일괄설정',
  USE: '적용',
  BANNER_SETTING_CSS: '배너 효과 설정',
  SPEED_SELECT: '전환 속도 선택',
  FAST_SPEED: '빠르게',
  NORMAL_SPEED: '보통',
  LOW_SPEED: '느리게',
  TIME_SETTING: '전환 시간 설정',
  TIME_TOOLTIP: '"수동" 선택 시에는 사용자가 마우스로 클릭하는 경우에만 이미지가 전환됩니다.',
  CSS_SELECT: '효과 선택',
  SLIDE_CSS: '슬라이드',
  FADE_CSS: '페이드',
  LEFT_RIGHT_BTN: '좌우 전환 버튼',
  BTN_COLOR: '버튼 색상',
  NAVIGATION: '네비게이션',
  DIRECT_REGISTER: '직접 등록',
  TYPE: '종류',
  ACTIVE_COLOR: '활성 색상',
  SIZE: '크기',
  INACTIVE_COLOR: '비활성 색상',
  S1: '1초',
  S2: '2초',
  S3: '3초',
  S4: '4초',
  S5: '5초',
  S6: '6초',
  S7: '7초',
  S8: '8초',
  S9: '9초',
  S10: '10초',
  HAND_OPERATE: '수동',
  NO_SELECT_IMAGE: '선택된 이미지가 없습니다.',
  SAVE_SUCCESS: '저장이 완료되었습니다.',
  MAX_10: '10MB 이하의 파일만 등록해주세요.',
  NOT_ALLOW: '허용하지 않는 확장자입니다.',
  CONTAINER: '용량',
  IN_TYPE: '형식',
  LINK: '링크',
  TARGET: '타켓',
  PC_WEB: 'PC웹',
  MOBILE_WEB: '모바일웹',
  BANNER_GROUPS: '배너그룹',
  WAIT: '대기',
  OVER: '종료',
  LOGIN_REGISTER: '등록일/수정일',
  PREVIEW: '미리보기',
  MOBILE_APP: '모바일 앱',
});
