export default Object.freeze({
  CHROME_GUIDE:
    'shop by 관리자는 크롬 브라우저에 최적화 되어 있습니다. 다른 브라우저에서는 <{tag}>일부 기능이 제한</{tag}> 될 수 있으니 크롬브라우저로 이용 부탁 드립니다.',
  CHROME_DOWNLOAD: '크롬 다운로드',
  TITLE: '비밀번호 재설정 요청 방법',

  EMAIL: '이메일',
  EMAIL_PLACEHOLDER: '이메일을 입력하세요.',

  NOTICE_PASSWORD_CHANGE_0: '운영자 이메일로 비밀번호 변경 링크를 전송합니다.',
  NOTICE_PASSWORD_CHANGE_1: '운영자 아이디와 이메일을 정확히 입력해주세요.',
  NOTICE_PASSWORD_CHANGE_2: '이메일 계정을 작성할 수 없는 경우 1:1문의로 비밀번호 재설정 요청해주세요.',
  NOTICE_PASSWORD_CHANGE_3: '[비밀번호 재설정 요청 방법]',

  NOTICE_PASSWORD_CHANGE_4: '특수문자는 !@#$%^&+=-_.()만 사용 가능합니다.',
  NOTICE_PASSWORD_CHANGE_5:
    '연속적인 숫자나 생일, 전화번호 등 추측하기 쉬운 개인정보 및 아이디와 비슷한 비밀번호는 사용하지 않는 것을 권고합니다.',
  NOTICE_PASSWORD_CHANGE_6: '비밀번호는 영문, 숫자, 특수문자 조합하여 최소 8자, 최대 20자로 입력 가능합니다.',
  NOTICE_PASSWORD_CHANGE_7: '(10자 이상 시 2종류 이상 조합, 10자 미만 시에는 3종류 조합)',
  NOTICE_PASSWORD_CHANGE_8: '솔루션을 신청한 고도홈 회원 운영자 ID 의 비밀번호만 변경 가능합니다.',
  NOTICE_PASSWORD_CHANGE_9:
    '재설정 시 비밀번호는 영문, 숫자, 특수문자 조합하여 최소 8자, 최대 20자로 입력 가능합니다. (10자 이상 시 2종류 이상 조합, 10자 미만 시에는 3종류 조합)',

  NOTICE_PASSWORD_CHANGE_10: '{name}님은 장기간 쇼핑몰 관리 비밀번호를 변경하지 않고 사용 중입니다.',
  NOTICE_PASSWORD_CHANGE_11:
    '방송통신위원회 고시 [개인정보의 기술적 · 관리적 보호조치 기준]에 따라, 개인정보취급자는 6개월에 1회 이상 비밀번호를 변경하여야 합니다.',
  NOTICE_PASSWORD_CHANGE_12: '쇼핑몰 보안 및 운영자의 개인정보 보호를 위해 비밀번호를 변경하시기 바랍니다.',

  NOTICE_FIND_OTP_KEY_0: '이메일로 OTP 키를 전송합니다',
  NOTICE_FIND_OTP_KEY_1: 'OTP 키를 이용하면, Google OTP를 재설정할 수 있습니다.',

  OTP_AUTH_KEY: 'OTP 코드를 입력하겠습니다.',

  NEW_PASSWORD: '새로운 비밀번호',
  NEW_PASSWORD_CONFIRM: '새로운 비밀번호 확인',
  NEW_PASSWORD_PLACEHOLDER: '새로운 비밀번호 입력',
  NEW_PASSWORD_CONFIRM_PLACEHOLDER: '새로운 비밀번호 한번 더 입력',
  CURRENT_PASSWORD: '현재 비밀번호',
  CURRENT_PASSWORD_PLACEHOLDER: '현재 비밀번호 입력',

  PASSWORD_PLACEHOLDER: '비밀번호를 입력하세요.',

  NEW_PASSWORD_DESC: '영문,숫자,특수문자를 조합하여 8~20자리로 설정하셔야 합니다.',
  CONFIRM_PASSWORD_DESC: '비밀번호와 비밀번호확인 값이 일치하지 않습니다.',
  CONFIRM_PASSWORD_DESC_2: '새로운 비밀번호와 새로운 비밀번호 확인 값이 일치하지 않습니다.',

  INVALID_CHANGE_PASSWORD_KEY: 'Key 값이 유효하지 않습니다.',
  INVALID_CHANGE_PASSWORD_KEY_1:
    '이미 비밀번호가 변경 완료되었을 수 있습니다. 비밀번호를 변경하지 못한 경우 어드민 로그인 > 비밀번호 찾기를 통해 다시 시도해주세요.',

  CAN_NOT_USE_OLD_PASSWORD: '이전에 사용한 비밀번호는 다시 사용할 수 없습니다. 다른 비밀번호를 입력해 주세요.',

  ALERT_SEND_EMAIL_SUCCESS: '운영자 님의 메일 주소로 비밀번호 변경 링크가 전송되었습니다.',

  PASSWORD_CHANGE_GUIDE_TITLE: '비밀번호 재설정 요청 방법',

  PASSWORD_CHANGE_GUIDE_MESSAGE_0: '① 마이페이지 > 1:1 문의/답변 페이지로 이동',
  PASSWORD_CHANGE_GUIDE_MESSAGE_1: `② '문의유형 선택' 항목 분류에서 [회원정보 관리 및 결제] > [ID, PW 찾기/회원정보 변경] 선택`,
  PASSWORD_CHANGE_GUIDE_MESSAGE_2: `③ '문의할 서비스 선택' 에서 비밀번호 변경이 필요한 서비스 선택`,
  PASSWORD_CHANGE_GUIDE_MESSAGE_3: `④ '문의내용 작성' 에서 비밀번호 요청 내용 작성`,
  PASSWORD_CHANGE_GUIDE_MESSAGE_4: `⑤ '추가정보 작성' 에서 비밀번호 재설정이 필요한 관리자페이지의 관리자 아이디 및 재설정 비밀번호 입력`,
  PASSWORD_CHANGE_GUIDE_MESSAGE_5: `⑥ 1:1 문의 작성 완료 ('답변완료' 시 요청하신 비밀번호로 변경됩니다.)`,

  MOVE_INQUIRY_BUTTON: '1:1 문의 바로가기',

  NEXT_CHANGE_PASSWORD_BUTTON: '다음에 변경',
  CHANGE_PASSWORD_BUTTON: '비밀번호 변경',

  ALERT_IP_BLOCK: '접속 가능한 IP가 아닙니다.\n관리자에게 문의해주세요.',

  SECOND_AUTH_TITLE: '로그인 보안 인증',
  NOTICE_SECOND_AUTH_0:
    '인증번호 받기 시도 후 발송된 인증번호를 입력란에 입력 후 보안인증 진행 시 로그인이 완료됩니다.',
  NOTICE_SECOND_AUTH_1: '이메일 인증번호는 제한시간 10분까지 유효하며, SMS 인증번호는 제한시간 5분까지 유효합니다.',
  NOTICE_SECOND_AUTH_2: '제한시간이 지난 이후에는 인증번호 재전송 클릭하여 재시도 바랍니다.',
  NOTICE_SECOND_AUTH_3:
    'OTP 인증의 경우 Google Authenticator 앱 설치 후 이용 가능하며, 인증번호 제한시간은 30초 입니다.',

  SECOND_AUTH_METHOD_TITLE: '인증수단 선택',
  SECOND_AUTH_METHOD_DESCRIPTION: '운영자 관리 페이지에서 설정한 인증수단 정보로 인증번호가 발송됩니다.',

  GO_TO_LOGIN_PAGE: '로그인 페이지로 이동',
  PASSWORD_CHANGE_COMPLETE: '비밀번호 변경이 완료되었습니다.',
});
