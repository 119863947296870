import { range } from 'underscore';
import { OptionData } from '@/helpers/type';
import {
  getAppliedImmediateDiscountPriceColumn,
  getDisplayCategoryNamesColumn,
  getMallNameColumn,
  getProductNameColumn,
  getProductNoColumn,
} from '@/components/product/common/girdColumns/ProductCommonGridColumns';
import { OptColumn } from '@/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { WidgetConfig, ExpandedReviewSetting, YorN } from 'ncp-api-supporter';

const getPerPageOption = (): OptionData<number>[] => [
  { name: '4', value: 4 },
  { name: '8', value: 8 },
  { name: '12', value: 12 },
  { name: '16', value: 16 },
  { name: '20', value: 20 },
];

const getWidgetReviewCount = () => {
  return range(1, 21).map(value => ({ name: value.toString(), value }));
};

const getSaleStatusColumn = (): OptColumn => {
  return {
    header: 'PRODUCT.STATUS.SALE.STATUS',
    name: 'saleStatusType',
    align: 'center',
    minWidth: 50,
    formatter: ({ value }) => {
      return i18n.t(`PRODUCT.STATUS.SALE.${value}`).toString();
    },
  };
};

const getDeleteGridColumn = (): OptColumn => {
  return {
    header: 'DELETE',
    name: 'delete',
    align: 'center',
    minWidth: 50,
    formatter: () => {
      return getAnchorHTML(i18n.t('DELETE'));
    },
  };
};

export const selectOption = {
  allReviewCntPerPage: getPerPageOption(),
  photoReviewCntPerPage: getPerPageOption(),
  productReviewCntPerPage: getPerPageOption(),
  widgetReviewCount: getWidgetReviewCount(),
};

export const radioOption = {
  allProductReviewWidgetSettingUseYn: {
    name: 'allProductReviewWidgetSettingUseYn',
    data: [
      { id: 'useAllProductReviewWidgetSetting', value: 'Y', display: 'MEMBER.SMS_AUTO.USED' },
      { id: 'notAllProductReviewWidgetSetting', value: 'N', display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  },
  photoReviewWidgetSettingUseYn: {
    name: 'photoReviewWidgetSettingUseYn',
    data: [
      { id: 'usePhotoReviewWidgetSetting', value: 'Y', display: 'MEMBER.SMS_AUTO.USED' },
      { id: 'notPhotoReviewWidgetSetting', value: 'N', display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  },
  productReviewWidgetSettingUseYn: {
    name: 'productReviewWidgetSettingUseYn',
    data: [
      { id: 'useProductReviewWidgetSetting ', value: 'Y', display: 'MEMBER.SMS_AUTO.USED' },
      { id: 'notProductReviewWidgetSetting ', value: 'N', display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  },

  photoReviewDisplayType: {
    name: 'photoReviewDisplayType',
    data: [
      { id: 'firstType', value: 'FIRST_TYPE', display: '타입1(미리보기)' },
      { id: 'secondType', value: 'SECOND_TYPE', display: '타입2(미리보기)' },
    ],
  },
  gatheringPhotoReviewUseYn: {
    name: 'gatheringPhotoReviewUseYn',
    data: [
      { id: 'useGatheringPhotoReview', value: 'Y', display: 'MEMBER.SMS_AUTO.USED' },
      { id: 'notUseGatheringPhotoReview', value: 'N', display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  },
  reviewRecommendUseYn: {
    name: 'reviewRecommendUseYn',
    data: [
      { id: 'useReviewRecommendUseYn', value: 'Y', display: 'MEMBER.SMS_AUTO.USED' },
      { id: 'notUseReviewRecommendUseYn', value: 'N', display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  },
};

export const gridProps = {
  columns: [
    // 쇼핑몰, 상품번호, 상품명
    getMallNameColumn(),
    getProductNoColumn('productNo'),
    getProductNameColumn(false),
    // 판매상태
    //TODO api 수정되면 상품리스트에서 가져오기
    getSaleStatusColumn(),
    //즉시할인가
    getAppliedImmediateDiscountPriceColumn(),
    // 전시 카테고리
    getDisplayCategoryNamesColumn(),
    // 삭제
    getDeleteGridColumn(),
  ],
  displayOptions: {
    pageSizeKey: 'perPage',
  },
  options: {
    rowHeaders: ['checkbox'],
    keyColumnName: 'productNo',
    scrollY: true,
    pageOptions: {
      perPage: 100,
      page: 1,
    },
    bodyHeight: 300,
  },
};

export const widgetConfigInit: WidgetConfig = {
  allReviewWidgetCount: 1,
  allReviewWidgetUseYn: '' as YorN,
  photoReviewWidgetCount: 1,
  photoReviewWidgetUseYn: '' as YorN,
  productReviewWidgetCount: 1,
  productReviewWidgetUseYn: '' as YorN,
};

export const expandedReviewSettingInit: ExpandedReviewSetting = {
  accumulationRewardNoticeText: '',
  allReviewCntPerPage: 1,
  writingReviewNoticeText: '',
  gatheringPhotoReviewUseYn: '',
  reviewRecommendUseYn: '',
  reviewRewardNoticeText: '',
  photoReviewDisplayType: '',
  photoReviewCntPerPage: 1,
  noPhotoReviewText: '',
  productReviewCntPerPage: 1,
  widgetConfig: widgetConfigInit as WidgetConfig,
};
