



import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class ProductRegistrationIframe extends Vue {
  private get remoteSrc() {
    const query = this.$route.query;
    const queryObject = { serviceType: 'PRO', ...query };
    const queryString = new URLSearchParams(queryObject).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/product/management/choose-type?' + queryString;
  }
}
