






import { Vue, Component } from 'vue-property-decorator';
import { throwWindowPopup } from '@/helpers/popup';

const popupComponentName = 'ServerExcelDownload';

@Component
export default class ExcelDownloadButton extends Vue {
  get href(): string {
    return this.$router.resolve({ name: popupComponentName }).href;
  }

  private windowPopupOpen(): void {
    throwWindowPopup(popupComponentName, null, 'xlg', () => null, 'excel_popup');
  }
}
