


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { OriginalAddress, GetAddressRequest, NCPResponse } from 'ncp-api-supporter';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { GetAddressContent } from 'ncp-api-supporter/dist/types/modules/manage/address';
import Pagination from '@/components/common/Pagination.vue';
import { PaginationOption } from '@/types/tui-pagination';

@Component({
  components: { TextInput, ModalHeader, Pagination },
})
export default class SearchAddress extends Vue {
  @Prop() onPositiveClick!: Function;

  private keyword = '';
  private addresses: null | OriginalAddress[] = null;

  get totalCount(): number {
    if (this.addresses === null) return 0;
    return this.addresses.length;
  }

  private searchKeyword = '';
  private paginationOption: PaginationOption = { totalItems: 0, page: 1, itemsPerPage: 20 };

  private async fetch(): Promise<void> {
    const request: GetAddressRequest = {
      params: {
        keyword: this.searchKeyword,
        page: this.paginationOption.page,
        pageSize: this.paginationOption.itemsPerPage,
      },
    };
    const { data }: NCPResponse<GetAddressContent> = await this.$api.getAddress(request);
    this.addresses = data.items;
    this.paginationOption.totalItems = data.totalCount;
  }

  private async search(): Promise<void> {
    if (!this.keyword) return;

    this.searchKeyword = this.keyword;
    this.paginationOption.page = 1;

    await this.fetch();

    (this.$refs.pagination as Pagination).reset(this.paginationOption.totalItems);
  }

  private changePage(page): void {
    this.paginationOption.page = page;
    this.fetch();
  }
}
