




import { Vue, Component } from 'vue-property-decorator';
import { claimTypes } from '@/const/claim.ts';
import SearchForm from '@/components/shipping/claim/SearchForm.vue';

@Component({
  components: {
    SearchForm,
  },
})
export default class ReturnList extends Vue {
  private ClaimType = claimTypes.REFUND;
}
