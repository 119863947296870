



import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';
@Component({
  components: { AdminIframe },
})
export default class QuestionsIframe extends Vue {
  private remoteSrc = '';

  private setRemoteSrc() {
    const query = this.$route.query;
    const queryObject = { ...query, serviceType: 'PRO', activeTab: query?.activeTab?.toString() ?? 'list' };
    const queryString = new URLSearchParams(queryObject).toString();
    const { activeTab } = queryObject;

    this.remoteSrc =
      process.env.VUE_APP_ADMIN_REMOTE_URL + `/board/management/product-inquiry/${activeTab}?` + queryString;
  }

  created() {
    this.setRemoteSrc();
  }
}
