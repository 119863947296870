













import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import Summernote from '@/components/common/summernote/Summernote.vue';
import { SummernoteOption } from '@/types/summernote/summernote';
import { STYLE_TYPE, TEXT_TYPE } from '@/const/summernote';
import { InputNumber } from '@/types';

@Component({
  components: {
    Summernote,
  },
})
export default class Editor extends Vue {
  @Prop({ required: false })
  private readonly option?: SummernoteOption;
  @Prop({ required: false, default: false })
  private readonly disabled?: boolean;
  @Prop({ required: false, default: '' })
  private readonly data?: string;
  @Prop({ required: false, default: '' })
  private readonly mallNo: InputNumber;
  @Prop({ required: false })
  private readonly editorId?: string;

  @Ref()
  private readonly summernote: Summernote;

  public disable() {
    this.summernote.onStyleEvent(STYLE_TYPE.DISABLE);
  }

  public enable() {
    this.summernote.onStyleEvent(STYLE_TYPE.ENABLE);
  }

  public focus() {
    this.summernote.onStyleEvent(STYLE_TYPE.FOCUS);
  }

  public setHtml(value: string) {
    this.summernote.onSetTextEvent(TEXT_TYPE.CODE, value);
  }

  public getHtml(): string {
    return this.summernote.getHtml();
  }

  public setText(value: string) {
    this.summernote.onSetTextEvent(TEXT_TYPE.TEXT, value);
  }

  public getText(): string {
    return this.summernote.getText();
  }

  mounted() {
    if (this.data !== '') {
      this.setHtml(this.data);
    }
  }

  @Watch('data')
  private updateData(newValue) {
    this.$nextTick(() => {
      this.setHtml(newValue);
    });
  }
}
