
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { range1ToNI18n } from '@/utils/common';

@Component
export default class TermsTemplateNotice extends Vue {
  @Prop() private onNegativeClick!: () => void;

  private noticeTextList = range1ToNI18n(3, 'MEMBER.SMS.TERMS_NOTICE_TEXT_');
}
