import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import PartnerSales from '@/views/contents/partner/PartnerSales.vue';
import Settlements from '@/views/contents/partner/Settlements.vue';
import PartnerRegisterIndex from '@/views/contents/partner/register/PartnerRegisterIndex.vue';
import PartnerRegisterNew from '@/views/contents/partner/register/new/PartnerRegisterNew.vue';
import PartnerRegisterSearch from '@/views/contents/partner/register/contract/PartnerRegisterSearch.vue';
import PartnerRegisterSearchDetail from '@/views/contents/partner/register/contract/PartnerRegisterSearchDetail.vue';
import PartnerRegisterCopySearch from '@/views/contents/partner/register/copy/PartnerRegisterCopySearch.vue';
import PartnerRegisterCopyDetail from '@/views/contents/partner/register/copy/PartnerRegisterCopyDetail.vue';
import PartnerModification from '@/views/contents/partner/modification/PartnerModification.vue';
import PartnerList from '@/views/contents/partner/list/PartnerList.vue';
import PartnerNoticeList from '@/views/contents/partner/notice/PartnerNoticeList.vue';

// NOTE: path 가 '/configuration/partner'인 이유 => LNB상 partner는 configuration의 하위에 있음.
export default (): RouteConfig => {
  return {
    path: '/configuration/partner',
    name: 'Partner',
    component: DefaultLayout,
    children: [
      {
        path: 'sales',
        name: 'PartnerSales',
        component: PartnerSales,
        meta: {
          excelCreateMenuKey: 'PRO_SETTLEMENT_DAILY_SALE',
        },
      },
      {
        path: 'settlements',
        name: 'Settlements',
        component: Settlements,
      },
      // 파트너 조회
      {
        path: 'search',
        name: 'PartnerList',
        component: PartnerList,
      },
      // 파트너 등록
      {
        path: 'register',
        name: 'PartnerRegisterIndex',
        component: PartnerRegisterIndex,
      },
      // 파트너 신규등록
      {
        path: 'register/new',
        name: 'PartnerRegisterNew',
        component: PartnerRegisterNew,
        meta: {
          breadcrumb: ['설정', '파트너 관리', '파트너 등록', '파트너 신규등록'],
        },
      },
      // 파트너 검색/등록 > 사업자 정보 검색
      {
        path: 'register/search',
        name: 'PartnerRegisterSearch',
        component: PartnerRegisterSearch,
        meta: {
          breadcrumb: ['설정', '파트너 관리', '파트너 등록', '파트너 검색/등록'],
        },
      },
      // 파트너 검색/등록 > 세부사항 확인/입력
      {
        path: 'register/search/:partnerNo',
        name: 'PartnerRegisterSearchDetail',
        component: PartnerRegisterSearchDetail,
        meta: {
          breadcrumb: ['설정', '파트너 관리', '파트너 등록', '파트너 검색/등록'],
        },
      },
      // 파트너 복사등록 > 사업자 정보 검색
      {
        path: 'register/copy',
        name: 'PartnerRegisterCopySearch',
        component: PartnerRegisterCopySearch,
        meta: {
          breadcrumb: ['설정', '파트너 관리', '파트너 등록', '파트너 복사등록'],
        },
      },
      // 파트너 복사등록 > 세부사항 확인/입력
      {
        path: 'register/copy/:partnerNo',
        name: 'PartnerRegisterCopyDetail',
        component: PartnerRegisterCopyDetail,
        meta: {
          breadcrumb: ['설정', '파트너 관리', '파트너 등록', '파트너 복사등록'],
        },
      },
      // 파트너 수정
      {
        path: 'modification/:partnerNo',
        name: 'PartnerModification',
        component: PartnerModification,
        meta: {
          breadcrumb: ['설정', '파트너 관리', '파트너 수정'],
        },
      },
      // 파트너 공지사항 관리
      {
        path: 'notice',
        name: 'PartnerNoticeList',
        component: PartnerNoticeList,
      },
    ],
  };
};
