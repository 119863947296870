






































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import CombinationOptionInfoForm from '@/views/popups/product/productAdd/ProductOption/CombinationOption/CombinationOptionInfoForm.vue';
import { ProductOptionType, InputNumber, ValidationFuncType, OptionType } from '@/types';
import {
  isNotTenUnit,
  isEmptyValue,
  isMoreThanImmediateDiscountPrice,
  convertToI18n,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';

@Component({
  components: {
    ToolTip,
    SelectBox,
    TextInput,
    CombinationOptionInfoForm,
  },
})
export default class CombinationOptionForm extends Vue {
  @PropSync('optionCnt', { required: true }) private optionCntSync!: number;
  @Prop({ required: true }) private readonly hasAdditionalSetting!: boolean;
  @Prop({ required: true }) private readonly immediateDiscountPrice!: InputNumber;
  @Prop({ required: true }) private readonly hasSupplyPrice!: boolean;
  @Prop({ required: true }) private readonly isMapping!: boolean;
  @Prop({ required: true }) private readonly isEmptyOptions!: () => boolean;
  @Prop({ required: true }) private readonly isMaster!: boolean;
  @Prop({ required: true }) private readonly isSlave!: boolean;
  @Prop({ required: false, default: false }) private readonly isReadonly!: boolean;

  private optionList: ProductOptionType[] = [];
  private tabIndexes: number[] = [];
  private supplyPrice = null;
  private deletable = true;

  private get optionInfoLength(): number {
    return this.hasAdditionalSetting ? 4 : 2;
  }

  @Watch('optionList', { deep: true })
  private updateOptionForm() {
    this.calculateTabIndex();
    this.optionCntSync = this.optionListLength;
  }

  @Watch('optionCntSync')
  private setOptionList() {
    const differenceValue = Math.abs(this.optionListLength - this.optionCntSync);

    if (this.optionListLength > this.optionCntSync) {
      this.optionList.splice(-differenceValue);
    } else {
      for (let i = 0; i < differenceValue; i++) {
        this.pushOptionList();
      }
    }
  }

  created() {
    this.initOptionList();
  }

  private initOptionList(): void {
    this.optionList = Array(this.optionCntSync).fill(this.getOptionInfoList());
  }

  public setOptionTemplate(optionList: ProductOptionType[], deletable: boolean) {
    this.optionList = optionList;
    this.deletable = deletable;
  }

  private getOptionInfoList(): ProductOptionType {
    //api 개발 후 키값 변경
    const defaultOption = { optionName: '', optionInfoList: [] };
    const defaultOptionInfo = {
      optionValue: '',
      optionPrice: null,
      stockCnt: null,
      managementCd: '',
      supplyPrice: this.supplyPrice,
    };
    defaultOption.optionInfoList.push(defaultOptionInfo);
    return defaultOption;
  }

  private pushOptionList(): void {
    this.optionList.push(this.getOptionInfoList());
  }

  private deleteOptionList(optionIndex: number): void {
    this.$delete(this.optionList, optionIndex);
  }

  private onClickOptionControlBtn(optionIndex: number): void {
    const maxOptionCnt = this.isMapping ? 2 : 5;
    if (optionIndex === 0) {
      if (this.optionCntSync >= maxOptionCnt) return;
      this.optionCntSync++;
      this.pushOptionList();
    } else {
      this.optionCntSync--;
      this.deleteOptionList(optionIndex);
    }
  }

  private getOptionControlBtnClassName(optionCnt: number): 'option-plus' | 'option-minus' {
    return optionCnt === 0 ? 'option-plus' : 'option-minus';
  }

  private calculateTabIndex(): void {
    this.tabIndexes = this.optionList.reduce((acc, _, index) => {
      if (index === 0) {
        acc.push(1);
      } else {
        const prevOptionInfoListLength = this.optionList[index - 1].optionInfoList.length;
        const tabIndex = prevOptionInfoListLength * this.optionInfoLength + 1 + acc[index - 1];
        acc.push(tabIndex);
      }
      return acc;
    }, []);
  }

  private get optionListLength(): number {
    return this.optionList.length;
  }

  private saveOptionList(): void {
    if (this.deletable === false) {
      return alert(convertToI18n('DELETABLE_N'));
    }
    if (this.optionListLength === 0) {
      this.$emit('saveOptionList', this.optionList, true);
      return;
    }
    if (isEmptyValue(this.optionList, 'optionName')) {
      return alert(this.$t('PRODUCT.OPTION.EMPTY_OPTION_NAME_MESSAGE'));
    }
    if (this.validateOptionValue()) {
      return alert(convertToI18n('EMPTY_OPTION_VALUE_MESSAGE'));
    }
    if (this.isMapping) {
      this.$emit('saveOptionList', this.optionList);
      return;
    }
    if (this.validateOptionPrice(isMoreThanImmediateDiscountPrice.bind(this))) {
      return alert(convertToI18n('OPTION_PRICE_MESSAGE'));
    }
    if (this.validateOptionPrice(isNotTenUnit)) {
      return alert(convertToI18n('TIP_OPTION_PRICE_10'));
    }
    this.validationParentValue();
    this.$emit('saveOptionList', this.optionList);
  }

  private validationParentValue() {
    if (this.hasSupplyPrice === false) {
      this.optionList.forEach(({ optionInfoList }) =>
        optionInfoList.forEach(optionInfo => this.$set(optionInfo, 'purchasePrice', 0)),
      );
    }
  }

  private validateOptionPrice(validationCallBack: ValidationFuncType): boolean {
    return this.optionList.some(({ optionInfoList }) =>
      optionInfoList.some(({ optionPrice }) => validationCallBack(optionPrice)),
    );
  }

  private validateOptionValue(): boolean {
    return this.optionList.some(option => isEmptyValue(option.optionInfoList, 'optionValue'));
  }

  private formClassName(index: number): string {
    if (this.isMapping) return 'type-5';
    if ((!this.hasAdditionalSetting || index !== 0) && !this.hasSupplyPrice) return 'type-2';
    if (this.hasAdditionalSetting && index === 0 && this.hasSupplyPrice) return 'type-3';
    if ((!this.hasAdditionalSetting || index !== 0) && this.hasSupplyPrice) return 'type-4';
    return '';
  }

  private get optionType(): OptionType {
    return this.isMapping ? 'SET_OPTION' : 'OPTION';
  }
}
