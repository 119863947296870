import store from '@/store';
import { Mall } from 'ncp-api-supporter';
import { i18n } from '@/main';
import moment from 'moment';

export function getMallName(mallNo: number): string {
  const malls: Mall[] = store.getters['mall/getMalls'];
  const { mallName }: Mall = malls.find(mall => mall.mallNo === mallNo);
  return mallName;
}

export const checkSelectedMall = (mallNo: string): void => {
  mallNo === '' && alert(i18n.t('PRODUCT.PRODUCT.SELECT_MALL'));
};

export const mall = {
  get malls(): Mall[] {
    return store.getters['mall/getMalls'];
  },

  get isOnlyOneMall(): boolean {
    return this.malls.length === 1;
  },

  get onlyOneMallNo(): number {
    return this.malls[0].mallNo;
  },

  get mallsSortedByCreationDate(): Mall[] {
    const sortByLatestCreationDate = (a: Mall, b: Mall): number => {
      const dateL = moment(a.createdDateTime)
        .toDate()
        .getTime();
      const dateR = moment(b.createdDateTime)
        .toDate()
        .getTime();
      return dateL < dateR ? 1 : -1;
    };
    return [...this.malls].sort(sortByLatestCreationDate);
  },

  get firstCreatedMall(): Mall {
    return this.mallsSortedByCreationDate[this.malls.length - 1];
  },

  get lastCreatedMall(): Mall {
    return this.mallsSortedByCreationDate[0];
  },

  get allMallNoString(): string {
    return this.malls.map(({ mallNo }) => mallNo).join(',');
  },
};

export function getCurrentMallNo(vueComponent): number {
  const malls: Mall[] = store.getters['mall/getMalls'];
  return Number(vueComponent.$route.query.mallNo) || (malls.length > 0 ? mall.lastCreatedMall.mallNo : 0);
}

export function getMallNo4Notification(vueComponent): number {
  const malls: Mall[] = store.getters['mall/getMalls'];
  return (
    Number(sessionStorage.getItem('mallNoValue')) ||
    Number(vueComponent.$route.query.mallNo) ||
    (malls.length > 0 ? mall.lastCreatedMall.mallNo : 0)
  );
}
