
































































import { Component, Ref, Watch } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import Grid from '@/components/common/grid/Main.vue';
import { getAppliedDeliveryTemplateGridOption, getDefaultQuery } from '@/const/contents/configuration/deliveryTemplate';
import { GridEventProps, GridProp } from '@/types';
import { NCPResponse, ProductDeliveryTemplatesByNo } from 'ncp-api-supporter';
import { i18nForDeliveryGroupTemplate } from '@/const/contents/configuration/shippingCharge';
import { Row } from '@/types/tui-grid';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { setNoDataMessage } from '@/helpers/grid';
import { AppliedDeliveryTemplateQuery } from '@/types/delivery';
import { ProductDeliveryTemplate } from 'ncp-api-supporter/dist/types/modules/product/product';
@Component({
  components: {
    Grid,
  },
})
export default class AppliedDeliveryTemplatePopup extends WindowPopupMainVue {
  private readonly gridOption: GridProp = getAppliedDeliveryTemplateGridOption();
  private readonly i18nForDeliveryGroupTemplate = i18nForDeliveryGroupTemplate;
  private query = {} as AppliedDeliveryTemplateQuery;
  private products: ProductDeliveryTemplate[] = [];
  private totalCount = 0;

  protected created() {
    this.init();
  }

  private init() {
    setNoDataMessage(this.i18nForDeliveryGroupTemplate('NO_DATA'));
    this.setQuery();
    this.fetchProduct();
  }

  private setQuery() {
    this.query = getDefaultQuery(this.data.templateNo.toString());
  }

  private async fetchProduct() {
    const { data }: NCPResponse<ProductDeliveryTemplatesByNo> = await this.$api.getProductDeliveryTemplatesByNo({
      params: this.query,
    });
    this.products = data.contents;
    this.totalCount = data.totalCount;
  }

  @Watch('$route.query')
  private changeRoute(): void {
    this.query = getDefaultQuery(this.data.templateNo.toString(), Number(this.$route.query.page));
    this.fetchProduct();
  }

  @Ref()
  private readonly grid: Grid;
  private onItemClicked({ rowKey, columnName }: GridEventProps) {
    const rowData: Row = this.grid.getRowAt(rowKey);
    switch (columnName) {
      case 'productNo':
        openEditProductWindow(Number(rowData.productNo));
        break;
    }
  }
}
