import { ActionContext } from 'vuex';
import { OrderState } from '@/types/order';

export default {
  namespaced: true,
  state: { selectedMall: '' },
  actions: {
    setSelectedMall({ commit }: ActionContext<OrderState, any>, { mallNo }) {
      commit('SET_SELECTED_MALL', mallNo);
    },
  },
  mutations: {
    SET_SELECTED_MALL(state: OrderState, mallNo): void {
      state.selectedMall = mallNo;
    },
  },
  getters: {
    getSelectedMall(state: OrderState): string | number {
      return state.selectedMall;
    },
  },
};
