








import { Vue, Component, Prop } from 'vue-property-decorator';
import { PARTNER_REGISTER_CONTRACT_TAB } from '@/const/partner';
import { PartnerRegisterContractTabType } from '@/types/partner';

@Component
export default class PartnerRegisterTab extends Vue {
  @Prop({ required: true })
  private readonly currentTab: PartnerRegisterContractTabType;

  private readonly tabs = [
    { label: '사업자 정보 검색', value: PARTNER_REGISTER_CONTRACT_TAB.SEARCH },
    { label: '세부사항 확인/입력', value: PARTNER_REGISTER_CONTRACT_TAB.DETAIL },
  ];
}
