




import { Vue, Component } from 'vue-property-decorator';
import WithdrawalMember from './WithdrawalMember.vue';

@Component({
  components: { WithdrawalMember },
})
export default class WithdrawalMemberIframe extends Vue {}
