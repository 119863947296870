export default Object.freeze({
  TITEL: '할인쿠폰 지급현황',
  MALL_NAME: '쇼핑몰',
  PAY_MOUNT: '총 지급 수',
  PAY_MOUNT_UNIT: '건',
  COUPON_DETAIL: '쿠폰정보',
  COUPON_DETAIL_NO: '쿠폰번호',
  MEMBER_GRADE: '지급회원등급',
  REQUSET_DATE: '예약지급 일시',
  MEMBER_GROUP: '지급회원그룹',
  ISSUE_DONE: '지급완료 일시',
  HEADER_MEMBER_NO: '회원번호',
  HEADER_MEMBER_NAME: '회원명',
  HEADER_MEMBER_ID: '아이디',
  HEADER_MEMBER_GRADE: '회원등급',
  HEADER_COUPON: '쿠폰번호',
  HEADER_ISSUE_YMDT: '지급일시',
  HEADER_USE_YDMT: '쿠폰사용여부',
  CLOSE: '닫기',
  MORE_COUPON_TEXT: '외 {num}건',
  COUPON_INSSUE_TARGET: '지급회원대상',
  PAY_PERIOD: '지급기간',
  REGISTER_NAME: '지급등록자',
  REGISTER_DATE: '등록일',
  POPUP_TITLE: '조회결과 ',
});
