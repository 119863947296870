
























import { Vue, Component, PropSync } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { DetailOpenOptions } from 'ncp-api-supporter/dist/types/modules/admin/admin';

@Component({
  components: { SelectBox },
})
export default class OrderDetailExposureSetting extends Vue {
  @PropSync('detailOpenOptions', { required: false, default: null })
  private detailOpenOptionsSync!: DetailOpenOptions;

  private exposureTypeOptions = [
    {
      value: 'NEW_TAB',
      name: this.$t('ORDER.EXPOSURE.NEW_TAB'),
    },
    {
      value: 'TAB',
      name: this.$t('ORDER.EXPOSURE.TAB'),
    },
    {
      value: 'NEW_WINDOW',
      name: this.$t('ORDER.EXPOSURE.NEW_WINDOW'),
    },
    {
      value: 'WINDOW',
      name: this.$t('ORDER.EXPOSURE.WINDOW'),
    },
  ];
}
