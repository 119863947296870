import { GridProp } from '@/types/grid';
import { ImmutableRadioRenderer } from '@/utils/grid/rendererUtils';
import { CellRendererProps } from '@/types/tui-grid/renderer';
import { LocaleMessages } from 'vue-i18n';
import { CellValue, Row } from '@/types/tui-grid';
import { i18n } from '@/main';
import { getEditGridColumn } from '@/components/product/common/girdColumns/ProductEdit';
import { getCopyGridColumn } from '@/components/product/common/girdColumns/ProductCopy';
import {
  getProductColumns,
  getBrandNameColumn,
  getSalePeriodColumn,
  getDisplayCategoryNamesColumn,
  getOptionColumn,
  getDiscountColumns,
  getCommonGridColumns,
  getDeliveryColumns,
  getProductManagementCdColumn,
  getSaleStatusColumn,
  getSalePriceColumn,
  getAppliedImmediateDiscountPriceColumn,
  getProductNoColumn,
  getProductNameColumn,
  getAccumulationRateColumn,
} from '@/components/product/common/girdColumns/ProductCommonGridColumns';
import { getUpdateSaleSettingStatusTypeColumn } from '@/components/product/common/girdColumns/edit/UpdateSalesSettingStatus';
import { getUpdateFrontDisplayColumn } from '@/components/product/common/girdColumns/edit/UpdateFrontDisplay';
import { getChangeHistoryColumn } from '@/components/product/common/girdColumns/ChangeHistory';
import { getPlatformDisplayInfoColumn } from '@/components/product/common/girdColumns/PlatformDisplayInfo';
import {
  getReservationStockCountColumn,
  getStockCountColumn,
  getDeliveryWaitingStockCountColumn,
  getSaleCountColumn,
} from '@/components/product/common/girdColumns/StockCount';
import { emptyValueFormatter, periodFormatter } from '@/utils/grid/formatterUtils';
import { getMemberGradeDisplayInfoColumn } from '@/components/product/common/girdColumns/MemberGradeDisplayInfo';
import { getDeleteGridColumn } from '@/components/product/common/girdColumns/ProductDelete';
import { getSupplyPriceHeader, getReviewCountHeader } from '@/components/product/common/girdColumns/ProductSupplyPrice';
import { getFrontDisplayColumn } from '@/components/product/common/girdColumns/FrontDisplay';
import moment from 'moment';
import { getProductJudgementColumn } from '@/components/product/common/girdColumns/ProductJudgement';

export interface CustomCellRendererProps extends CellRendererProps {
  selectOptions?: { text: string | LocaleMessages; value: CellValue }[];
  callback?: Function;
}

export function getProductListGridProps(callback: (eventName: string, event: Event, row: Row) => void): GridProp {
  return {
    columns: [
      // 수정
      getEditGridColumn(),
      // 복사
      getCopyGridColumn(),
      {
        header: 'IMAGE',
        name: 'mainImageUrl',
        align: 'center',
        minWidth: 110,
        formatter: ({ value }) => `<img src="${value}" alt="thumbnail" style="width: 25px; height: 25px"/>`,
      },
      // 쇼핑몰, 상품번호, 상품명
      ...getProductColumns(),
      // 승인상태
      getProductJudgementColumn(),
      // 판매상태
      getSaleStatusColumn(),
      // 판매설정
      getUpdateSaleSettingStatusTypeColumn(callback),
      // 예약 판매기간
      {
        header: 'PRODUCT.RESERVATION_PERIOD',
        name: 'reservationPeriod',
        align: 'center',
        minWidth: 250,
        formatter: cell => periodFormatter(cell.value),
      },
      // 판매기간
      getSalePeriodColumn(),
      // 전시상태 설정
      getUpdateFrontDisplayColumn(callback),
      // 노출 플랫폼
      getPlatformDisplayInfoColumn(),
      // 노출 회원등급
      getMemberGradeDisplayInfoColumn(),
      // 전시 카테고리
      getDisplayCategoryNamesColumn(),
      // 단축 URL
      {
        header: 'PRODUCT.SHORT_URL',
        name: 'urlShortening',
        align: 'center',
        minWidth: 200,
        formatter: ({ value }) => emptyValueFormatter(value),
      },
      // 판매가, 즉시할인, 즉시할인가
      ...getDiscountColumns(),
      // 상품 적립률
      getAccumulationRateColumn(),
      // 옵션
      getOptionColumn(),
      // 예약 재고수량
      getReservationStockCountColumn(),
      // 재고 수량
      getStockCountColumn(),
      // 출고대기
      getDeliveryWaitingStockCountColumn(),
      //프로모션적용, 담당자, 파트너사, 판매방식, 수수료율, 공급가, 매입가
      ...getCommonGridColumns(),
      // 판매 수량
      getSaleCountColumn(),
      // 상품 후기
      {
        header: 'PRODUCT.PRODUCT_REVIEWS',
        name: 'reviewCount',
        align: 'center',
        minWidth: 100,
        formatter: ({ value }) => value.toString(), // TODO : API 구현 후 작업
      },
      // 배송여부, 배송비유형, 배송비, 묶음배송 여부
      ...getDeliveryColumns(),
      // 네이버쇼핑 노출여부.
      // TODO : 1차 오픈 스펙아웃
      // {
      //   header: 'PRODUCT.DISPLAY_NAVER_SHOPPING',
      //   name: 'displayNaverShopping',
      //   align: 'center',
      //   minWidth: 150,
      //   formatter: () => 'no response',
      // },
      // 브랜드
      getBrandNameColumn(),
      // 상품관리코드
      getProductManagementCdColumn(),
      // 등록일/수정일
      {
        header: 'REGISTER_UPDATE_DATE',
        name: 'registerDateTime',
        align: 'center',
        minWidth: 120,
        formatter: ({ value, row }) => {
          const utcDateTime = value as string; // ISO 8601
          const registerDate = moment(utcDateTime).format('YYYY-MM-DD');
          const updateDate = !row?.updateDateTime ? null : moment(row.updateDateTime as string).format('YYYY-MM-DD');
          const returnStr = updateDate === null ? registerDate : registerDate + '<br />' + updateDate;
          return returnStr;
        },
      },
      // 상품정보 변경이력
      getChangeHistoryColumn(),
      // 삭제
      getDeleteGridColumn(),
    ],
    header: {
      height: 33,
      columns: [getSupplyPriceHeader(), getReviewCountHeader()],
    },
    options: {
      rowHeaders: ['checkbox'],
      columnOptions: {
        minWidth: 50,
        resizable: true,
        frozenCount: 6,
      },
      minRowHeight: 50,
      rowHeight: 50,
      bodyHeight: 480,
      useClientSort: false,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOptions: {
      gridName: 'PRO_PRODUCT_GRID',
      pageSizeKey: 'size',
      headerTooltipOption: [
        {
          id: '#supplyPrice',
          popupData: { data: { message: i18n.t('PRODUCT.SUPPLY_PRICE_TOOLTIP_MESSAGE') } },
        },
        {
          id: '#reviewCount',
          popupData: { data: { message: i18n.t('PRODUCT.PRODUCT_REVIEW_TOOLTIP_MESSAGE') } },
        },
      ],
    },
  };
}

export function getDefaultProductInquiryGridProps(): GridProp {
  return {
    columns: [
      // 상품번호
      getProductNoColumn(),
      // 상품명
      getProductNameColumn(),
      // 승인상태
      {
        header: 'PRODUCT.STATUS.APPLY.STATUS',
        name: 'applyStatusType',
        align: 'center',
        minWidth: 100,
        formatter: ({ value }): string => i18n.t(`PRODUCT.STATUS.APPLY.${value}`).toString(),
      },
      // 판매상태
      getSaleStatusColumn(),
      // 예약판매기간
      {
        header: 'PRODUCT.RESERVATION_PERIOD',
        name: 'reservationPeriod',
        align: 'center',
        minWidth: 250,
        formatter: cell => periodFormatter(cell.value),
      },
      // 판매기간
      getSalePeriodColumn(),
      // 전시상태
      getFrontDisplayColumn(),
      // 노출 플랫폼
      getPlatformDisplayInfoColumn(),
      // 노출 회원등급
      getMemberGradeDisplayInfoColumn(),
      // 판매가
      getSalePriceColumn(),
      // 즉시할인가
      getAppliedImmediateDiscountPriceColumn(),
    ],
    header: {
      height: 33,
      columns: [getSupplyPriceHeader()],
    },
    options: {
      rowHeaders: ['checkbox'],
      columnOptions: {
        minWidth: 50,
        resizable: true,
      },
      minRowHeight: 31,
      rowHeight: 31,
      bodyHeight: 480,
      useClientSort: false,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOptions: {
      pageSizeKey: 'size',
    },
  };
}

export function getDesignProductInquiryGridProps(): GridProp {
  return {
    columns: [
      // 상품번호
      getProductNoColumn(),
      // 상품명
      getProductNameColumn(),
      // 승인상태
      {
        header: 'PRODUCT.STATUS.APPLY.STATUS',
        name: 'applyStatusType',
        align: 'center',
        minWidth: 100,
        formatter: ({ value }): string => i18n.t(`PRODUCT.STATUS.APPLY.${value}`).toString(),
      },
      // 판매상태
      getSaleStatusColumn(),
      // 예약판매기간
      {
        header: 'PRODUCT.RESERVATION_PERIOD',
        name: 'reservationPeriod',
        align: 'center',
        minWidth: 250,
        formatter: cell => periodFormatter(cell.value),
      },
      // 판매기간
      getSalePeriodColumn(),
      // 전시상태
      getFrontDisplayColumn(),
      // 노출 플랫폼
      getPlatformDisplayInfoColumn(),
      // 노출 회원등급
      getMemberGradeDisplayInfoColumn(),
      // 판매가
      getSalePriceColumn(),
      // 즉시할인가
      getAppliedImmediateDiscountPriceColumn(),
    ],
    header: {
      height: 33,
      columns: [getSupplyPriceHeader()],
    },
    options: {
      rowHeaders: ['checkbox'],
      columnOptions: {
        minWidth: 50,
        resizable: true,
        frozenCount: 6,
      },
      minRowHeight: 31,
      rowHeight: 31,
      bodyHeight: 480,
      useClientSort: false,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOptions: {
      pageSizeKey: 'size',
    },
  };
}

export function getOrderProductInquiryGridProps(): GridProp {
  return {
    columns: [
      {
        header: 'SELECT',
        name: 'pick',
        align: 'center',
        width: 50,
        renderer: {
          type: ImmutableRadioRenderer,
          options: {
            columnName: 'pick',
          },
        },
      },
      //상품번호, 상품명
      getProductNoColumn(),
      getProductNameColumn(),
      // 판매상태
      getSaleStatusColumn(),
      // 판매기간
      getSalePeriodColumn(),
      // 전시상태
      getFrontDisplayColumn(),
      // 판매가
      getSalePriceColumn(),
      // 즉시할인가
      getAppliedImmediateDiscountPriceColumn(),
      // 상품 적립률
      getAccumulationRateColumn(),
      //프로모션적용, 담당자, 판매방식, 수수료율, 공급가, 매입가
      ...getCommonGridColumns(),
    ],
    header: {
      height: 33,
      columns: [getSupplyPriceHeader()],
    },
    options: {
      columnOptions: {
        minWidth: 50,
        resizable: true,
        frozenCount: 6,
      },
      minRowHeight: 31,
      rowHeight: 31,
      bodyHeight: 480,
      useClientSort: false,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOptions: {
      pageSizeKey: 'size',
    },
  };
}

export function getPromotionProductInquiryGridProps(): GridProp {
  return {
    columns: [
      // 상품번호
      getProductNoColumn(),
      // 상품명
      getProductNameColumn(),
      // 판매상태
      getSaleStatusColumn(),
      // 판매기간
      getSalePeriodColumn(),
      // 전시상태
      getFrontDisplayColumn(),
      // 판매가
      getSalePriceColumn(),
      // 즉시할인가
      getAppliedImmediateDiscountPriceColumn(),
      // 상품 적립률
      getAccumulationRateColumn(),
      //프로모션적용, 담당자, 판매방식, 수수료율, 공급가, 매입가
      ...getCommonGridColumns(),
    ],
    header: {
      height: 33,
      columns: [getSupplyPriceHeader()],
    },
    options: {
      rowHeaders: ['checkbox'],
      columnOptions: {
        minWidth: 50,
        resizable: true,
        frozenCount: 6,
      },
      minRowHeight: 31,
      rowHeight: 31,
      bodyHeight: 480,
      useClientSort: false,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOptions: {
      pageSizeKey: 'size',
    },
  };
}
