import { pgTypes } from '@/const/order.ts';

export default [
  { value: pgTypes.DUMMY, label: 'Dummy' },
  { value: pgTypes.PAYCO, label: 'PAYCO' },
  { value: pgTypes.PAYPAL, label: 'PayPal' },
  { value: pgTypes.STRIPE, label: 'Stripe' },
  { value: pgTypes.KCP, label: 'KCP' },
  { value: pgTypes.INICIS, label: 'INICIS' },
  { value: pgTypes.NONE, label: 'PG없음' },
  { value: pgTypes.KCP_MOBILE, label: 'KCP' },
  { value: pgTypes.KCP_APP, label: 'KCP' },
  { value: pgTypes.NAVER_PAY, label: '네이버페이' },
  { value: pgTypes.LIIVMATE, label: 'LiivMate' },
  { value: pgTypes.PAYPALPRO, label: 'PayPalPro' },
  { value: pgTypes.ATHOR_NET, label: 'AthorizeNet' },
  { value: pgTypes.KAKAO_PAY, label: 'Kakao Pay' },
  { value: pgTypes.NAVER_EASY_PAY, label: '네이버페이 간편결제' },
  { value: pgTypes.LG_U_PLUS, label: '토스페이먼츠' },
];
