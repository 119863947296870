



































































































































































































































































































































































import { Component, Mixins, Watch, Ref } from 'vue-property-decorator';
import DeliveryScanning from '@/components/shipping/order/mixins/DeliveryScanning.ts';
import { getCookie, setCookie, deleteCookie } from '@/utils/cookie';
import { GetDeliveryCompaniesRequest, PutOrderOptionChangeStatusRequest } from 'ncp-api-supporter';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({
  components: { SelectBox, TextInput },
})
export default class DeliveryScan extends Mixins(DeliveryScanning) {
  @Ref('deliveryNo') private deliveryNo!: HTMLInputElement;
  @Ref('invoiceNo') private invoiceNo!: HTMLInputElement;
  @Ref('optionCode') private optionCode!: HTMLInputElement;
  @Watch('orderOptionNo', { immediate: false, deep: true })
  orderOptionNoChange(next: any) {
    if (next) {
      this.invoiceBtn = false;
    } else {
      this.invoiceBtn = true;
    }
  }
  @Watch('deliveryCompanyType')
  onDeliveryCompanyTypeChanged() {
    //  배송지 변경 시 일단 배송번호에 포커스
    this.deliveryNo.focus();
  }
  mounted(): any {
    this.deliveryNo.focus();
    this.getDeliveryCompanies();
  }
  private content: any = {};
  private searchDeliveryNo: any = null;
  private orderOptionNo: any = null;
  private showSearchResult = false;
  private showOptionScan = false;
  private setDefaultDataCheckBox = false;
  private invoiceBtn = true;
  private deliveryCompanyType = '';
  private deliveryCompanyTypeArr: any[] = [];
  private hasTaskMessage = false;
  private lastSearchDeliveryNo: any = null;
  private orderProductOptionArr: any[] = [];
  private alreadyCommunicating = false;
  // 之后查看 改换数据渲染形式
  private formatterAddScannedCnt(content: any): void {
    if (content.length > 0) {
      content.forEach((item: any) => {
        item.theScannedCnt = 0;
        item.filled = false;
      });
    }
  }
  private getDeliveryCompanies(): void {
    this.deliveryCompanyTypeArr.push({ deliveryCompanyType: '', label: this.$t('ORDER.SCANNING.CHOSE_COMPANIES') });
    const request: GetDeliveryCompaniesRequest = {
      params: {
        countryCd: 'KR',
      },
    };
    this.$api.getDeliveryCompanies(request).then(res => {
      this.deliveryCompanyTypeArr = [
        { deliveryCompanyType: '', label: this.$t('ORDER.SCANNING.CHOSE_COMPANIES') },
        ...res.data,
      ];
      this.initDefaultCheck();
    });
  }
  private initMergeRow(): void {
    this.mergeRow('.rowSpanProductName1', false, true);
    this.mergeRow('.rowSpanProductName', false, true);
    this.mergeRow('.rowSpanProductNo', true, false);
    this.mergeRow('.rowSpanProductNo1', true, false);
    this.mergeRow('.rowSpanOrderNo', false, false);
  }
  private handleOptionCode(): void {
    if (this.theOptionCode === null) {
      alert(this.$t('ORDER.SCANNING.ALERT_M7'));
      this.optionCode.focus();
      return;
    }
    const resp: any = this.scanToTable();
    if (!resp.result && resp.msg === this.constants.NO_MATCH) {
      alert(this.$t('ORDER.SCANNING.ALERT_M7'));
      this.theOptionCode = null;
      //$('#optionCode').focus();
      this.optionCode.focus();
    }
    if (resp && resp.isLast) {
      this.$nextTick(() => {
        this.invoiceNo.focus(); // 모든 옵션이 스캐닝 되면 invoiceNo에 포커스
      });
    }
  }

  private handleScanningChange(e: any): void {
    if (this.searchDeliveryNo || this.showSearchResult) {
      if (confirm(this.$t('ORDER.SCANNING.CONFIRM_M12') as string)) {
        this.initScan();
      } else {
        this.$nextTick(() => {
          if (e.target.value === 'INVOICE') {
            this.scanningType = 'OPTION';
          } else if (e.target.value === 'OPTION') {
            this.scanningType = 'INVOICE';
          }
        });
      }
    }
    // radio 스캐닝 방식 변경 시 일단 배송번호에 포커스
    this.deliveryNo.focus();
  }
  private getTotalCount(e: any): void {
    e.orderOptions.forEach((item: any) => {
      if (item.orderCnt !== undefined) {
        this.totalCount += item.orderCnt;
      }
      if (item.orderCnt1 !== undefined) {
        this.totalCount += item.orderCnt1;
      }
    });
  }
  private initScan(): void {
    this.showSearchResult = false;
    this.showOptionScan = false;
    this.searchDeliveryNo = null;
    this.orderOptionNo = null;
    this.setDefaultDataCheckBox = !!(getCookie('scanningType') || getCookie('deliveryCompanyType'));
    this.invoiceNoFlag = true;
    this.totalCount = 0;
    this.currentScannedCnt = 0;
  }
  private handleSearchDeliveryNo(): void {
    this.setDefaultCheck();
    this.searchInit();
    if (this.deliveryCompanyType === '') {
      alert(this.$t('ORDER.SCANNING.ALERT_M1'));
      this.initScan();
      //$('#deliveryNo').focus();
      this.deliveryNo.focus();
      return;
    }
    if (this.searchDeliveryNo === null || this.searchDeliveryNo === '') {
      alert(this.$t('ORDER.SCANNING.ALERT_M13'));
      //$('#deliveryNo').focus();
      this.deliveryNo.focus();
      return;
    }
    if (isNaN(this.searchDeliveryNo) || this.searchDeliveryNo.indexOf('.') !== -1) {
      alert(this.$t('ORDER.SCANNING.ALERT_NEW_M14'));
      //$('#deliveryNo').focus();
      this.deliveryNo.focus();
      this.searchDeliveryNo = null;
      return;
    }
    this.search();
  }
  private handleSaveInvoiceNo(): void {
    this.setDefaultCheck();
    if (this.hasTaskMessage && !confirm(this.$t('ORDER.SCANNING.CONFIRM_M16') as string)) {
      this.orderOptionNo = null;
      //$('#invoiceNo').focus();
      this.invoiceNo.focus();
      return;
    }
    if (this.checkInvoiceNo(this.orderOptionNo) || this.orderOptionNo.indexOf('.') !== -1) {
      alert(this.$t('ORDER.SCANNING.ALERT_M14'));
      this.orderOptionNo = null;
      //$('#invoiceNo').focus();
      this.invoiceNo.focus();
      return;
    }
    if (this.lastSearchDeliveryNo !== this.searchDeliveryNo) {
      alert(this.$t('ORDER.SCANNING.ALERT_M2'));
      this.orderOptionNo = null;
      //$('#invoiceNo').focus();
      this.invoiceNo.focus();
      this.invoiceBtn = true;
      return;
    }
    if (this.deliveryCompanyType === '') {
      alert(this.$t('ORDER.SCANNING.ALERT_M4'));
      this.orderOptionNo = null;
      //$('#invoiceNo').focus();
      this.invoiceNo.focus();
      this.invoiceBtn = true;
      return;
    }
    const checkRequest: any = {
      data: {
        options: [
          {
            shippingNo: Number(this.searchDeliveryNo),
            orderOptionNo: this.orderOptionNo,
          },
        ],
      },
    };
    this.$api.postOrderOptionsCheckDivideShipping(checkRequest).then(res => {
      let confirmResult = true;
      if (res.data.result) {
        confirmResult = confirm(this.$t('ORDER.SCANNING.ALERT_M9') as string);
      }
      if (confirmResult) {
        this.changeStatus();
      } else {
        this.orderOptionNo = '';
      }
    });
  }
  private changeStatus(): void {
    if (this.content.orderOptions.length === 0) {
      return;
    }
    const requestOp: any = [];
    this.content.orderOptions.forEach((item: any) => {
      if (item.orderProductOptionNo !== undefined) {
        // hslee : 나눔 배송 시 0번째 optionNo만 넘어가는 문제가 있어 처리중인데 왜 변수명을 xxxx, xxxx1 이런식으로 나눠놨는지 상상하기 어렵다. 혼나야해. 그냥 관심법으로 처리한다.
        if (item.orderProductOptionNos && item.orderProductOptionNos.length > 1) {
          item.orderProductOptionNos.forEach(orderProductOptionNo => {
            requestOp.push({
              deliveryCompanyType: this.deliveryCompanyType,
              invoiceNo: this.orderOptionNo,
              mallNo: Number(this.$route.query.mallNo),
              orderOptionNo: orderProductOptionNo,
            });
          });
        } else {
          requestOp.push({
            deliveryCompanyType: this.deliveryCompanyType,
            invoiceNo: this.orderOptionNo,
            mallNo: Number(this.$route.query.mallNo),
            orderOptionNo: item.orderProductOptionNo,
          });
        }
      }
      if (item.orderProductOptionNo1 !== undefined) {
        if (item.orderProductOptionNos1 && item.orderProductOptionNos1.length > 1) {
          item.orderProductOptionNos1.forEach(orderProductOptionNo => {
            requestOp.push({
              deliveryCompanyType: this.deliveryCompanyType,
              invoiceNo: this.orderOptionNo,
              mallNo: Number(this.$route.query.mallNo),
              orderOptionNo: orderProductOptionNo,
            });
          });
        } else {
          requestOp.push({
            deliveryCompanyType: this.deliveryCompanyType,
            invoiceNo: this.orderOptionNo,
            mallNo: Number(this.$route.query.mallNo),
            orderOptionNo: item.orderProductOptionNo1,
          });
        }
      }
    });
    const request: PutOrderOptionChangeStatusRequest = {
      data: {
        orderStatusType: 'DELIVERY_ING',
        options: requestOp,
        // holdByReservation: false,
      },
    };
    this.$api.putOrderOptionChangeStatus(request).then(() => {
      // this.alreadyCommunicating = false;
      this.initScan();
      this.deliveryNo.focus(); // 송장 번호 저장이 완료되면 다시 배송 번호에 포커스 처리
    });
  }
  private checkInvoiceNo(str: any): boolean {
    if (str == null) {
      return false;
    } else {
      if (str.match(/[^0-9a-zA-Z]/g) == null) {
        return false;
      } else {
        return true;
      }
    }
  }
  private searchInit(): void {
    this.content = {};
    this.totalCount = 0;
    this.currentScannedCnt = 0;
  }
  private search(): void {
    const request: any = {
      params: {
        shippingNo: this.searchDeliveryNo,
      },
    };
    this.$api
      .getShippingsOptions(request)
      .then((res: any) => {
        this.lastSearchDeliveryNo = this.searchDeliveryNo;
        this.content = [];
        this.content = res.data;
        this.formatterAddScannedCnt(this.content.orderOptions);
        const deliveryPrepareList = res.data.orderOptions.filter((item: any) => {
          return (
            item.status.orderStatusType === 'DELIVERY_PREPARE' &&
            item.status.claimStatusType === null &&
            item.holdDelivery === false
          );
        });
        const deliveryPrepareCnt = deliveryPrepareList.length;
        const deliveryPrepareBeforeCnt = res.data.orderOptions.filter((item: any) => {
          return (
            item.status.orderStatusType === 'PAY_DONE' ||
            item.status.orderStatusType === 'PRODUCT_PREPARE' ||
            item.status.orderStatusType === 'CANCEL_DONE' ||
            item.status.orderStatusType === 'DEPOSIT_WAIT'
          );
        }).length;
        const deliveryPrepareAfterCnt = res.data.orderOptions.filter((item: any) => {
          return (
            item.status.orderStatusType === 'DELIVERY_ING' ||
            item.status.orderStatusType === 'DELIVERY_DONE' ||
            item.status.orderStatusType === 'BUY_CONFIRM'
          );
        }).length;
        const deliveryHoldDeliveryCnt = res.data.orderOptions.filter((item: any) => {
          return item.holdDelivery === false;
        }).length;
        if (!res.data.requiresShipping) {
          alert(this.$t('ORDER.SCANNING.ALERT_M11'));
          this.initScan();
          return;
        }
        if (deliveryPrepareCnt === 0) {
          if (deliveryPrepareAfterCnt > 0) {
            alert(
              (this.$t('ORDER.SCANNING.OUT_STORE_ALERT') as string).replace('{{deliveryNo}}', this.searchDeliveryNo),
            );
          } else if (deliveryPrepareBeforeCnt) {
            alert(this.$t('ORDER.SCANNING.ALERT_M10'));
          } else if (deliveryHoldDeliveryCnt === 0) {
            alert(this.$t('ORDER_SCANNING_ALERT_M17'));
          }
          this.initScan();
          return;
        }
        // 合并商品;
        const usedMallOptionNos: any = [];
        const uniqueDeliveryList: any = [];
        deliveryPrepareList.forEach((row: any, index: number, rows: any): any => {
          if (!usedMallOptionNos.includes(`${row.product.mallOptionNo}/${row.orderNo}`)) {
            usedMallOptionNos.push(`${row.product.mallOptionNo}/${row.orderNo}`);
            row.orderCnt = rows
              .filter(
                (row1: any) => row1.product.mallOptionNo === row.product.mallOptionNo && row1.orderNo === row.orderNo,
              )
              .reduce((p: any, v: any) => p + v.orderCnt, 0);
            row.orderProductOptionNo = rows
              .filter(
                (row1: any) => row1.product.mallOptionNo === row.product.mallOptionNo && row1.orderNo === row.orderNo,
              )
              .map((a: any) => a.orderProductOptionNo);
            uniqueDeliveryList.push(row);
          }
        });
        // orderNo 分组
        const rowBy2: any = [];
        const groupByOrderNo = this.groupBy(uniqueDeliveryList);
        groupByOrderNo.forEach((rows: any) => {
          let i = 0;
          for (; i < parseInt((rows.length / 2) as any); i++) {
            rowBy2.push(this.convertTo2Column(rows[i * 2], rows[i * 2 + 1]));
          }
          if (rows[i * 2]) {
            rowBy2.push(this.convertTo2Column(rows[i * 2]));
          }
        });
        this.content.orderOptions = rowBy2;
        this.hasTaskMessage = !res.data.orderOptions
          .map((item: any) => {
            return item.taskMessageNo;
          })
          .every((value: any) => {
            return value === null;
          });
        this.showSearchResult = true;
        if (this.scanningType === 'OPTION') {
          this.showOptionScan = true;
          this.getTotalCount(res.data);
        } else {
          this.invoiceNoFlag = false;
          this.showOptionScan = false;
        }
        this.$nextTick(() => {
          this.initMergeRow();
          this.optionCode.focus();
        });
      })
      .catch((): any => {
        alert(this.$t('ORDER.SCANNING.ALERT_M5'));
        this.initScan();
      });
  }
  private initDefaultCheck(): void {
    this.setDefaultDataCheckBox = Boolean(getCookie('scanningType') || getCookie('deliveryCompanyType'));
    if (getCookie('scanningType')) {
      this.scanningType = getCookie('scanningType') as string;
    }
    if (getCookie('deliveryCompanyType')) {
      this.deliveryCompanyType = getCookie('deliveryCompanyType') as string;
    }
  }
  @Watch('setDefaultDataCheckBox')
  private setDefaultCheck(): void {
    if (this.setDefaultDataCheckBox) {
      setCookie('scanningType', this.scanningType);
      setCookie('deliveryCompanyType', this.deliveryCompanyType);
    } else {
      deleteCookie('scanningType');
      deleteCookie('deliveryCompanyType');
    }
  }
  private closePopup(): void {
    window.close();
  }
  private created() {
    window.addEventListener('beforeunload', () => this.setDefaultCheck.call(DeliveryScan));
  }
  private unmounted() {
    window.removeEventListener('beforeunload', () => this.setDefaultCheck.call(DeliveryScan));
  }
}
