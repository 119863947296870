export default Object.freeze({
  TITLE: '추가할인 관리',
  DISCOUNT_NO: '추가할인번호',
  ADDITIONAL_DISCOUNT_STATUS_TYPE: '추가할인상태',
  DISCOUNT_NAME: '추가할인명',
  START_YMDT: '시작일',
  END_YMDT: '종료일',
  REGISTER_ADMIN_NAME: '등록자',
  REGISTER_YMDT: '등록일',
  UPDATE_ADMIN_NAME: '최종수정자',
  UPDATE_YMDT: '최종수정일',
  ADDITIONAL_DISCOUNT_ENROLLMENT: '추가할인 등록',
  ADDITIONAL_DISCOUNT_PERIOD: '추가할인 기간',
  ADDITIONAL_DISCOUNT_STATUS_TYPE_SEARCH: '추가할인 상태',
  ALL: '전체',
  DISCOUNT_READY: '진행대기',
  DISCOUNT_ING: '할인중',
  DISCOUNT_EXPIRED: '기간만료',
  DISCOUNT_END: '할인종료',
});
