export default Object.freeze({
  TITLE: '서비스 등록',

  TAB_AGREE_TERMS: '약관동의',
  TAB_REGISTER: '사업자 정보입력',
  TAB_MASTER_REGISTER: '마스터 운영자 정보입력',
  TAB_COMPLETE: '서비스 등록신청 완료',

  AGREE_TERMS_TITLE: '개인정보 수집 및 이용',
  AGREE_TERMS_1: `<h3 class="terms_tit">1. 수집하는 개인정보의 항목</h3>
                <div class="terms_txt_area">
                  <p class="terms_txt">회사가 제공하는 SA 운영자 계정생성 및 SA 서비스 제공을 위해 아래와 같이 이용자의 개인정보를 수집하고 있습니다.</p>
                  <ol>
                    <li>1) 사업자 정보를 수집합니다.
                      <ul>
                        <li>- (필수) 회사명, 사업자 등록번호, 대표자명, 업종, 업태, 대표전화번호, 대표 e메일, 사업장 주소, 쇼핑몰명, 쇼핑몰계좌정보(계좌번호, 예금주명)</li>
                        <li>- (선택) 팩스번호, 통신판매신고번호, 개인정보 관리책임자 정보(담당자명, 전화번호)</li>
                      </ul>
                    </li>
                    <li>2) 계정 생성을 요청한 이용자의 개인정보를 수집합니다.
                      <ul>
                        <li>- (필수) ID, 비밀번호, 성명, 직급, 전화번호, 휴대폰번호, 이메일</li>
                        <li>- (선택) 소속부서, 직책</li>
                      </ul>
                    </li>
                    <li>3) 서비스 이용과정에서 다음과 같은 정보가 생성되어 수집 될 수 있습니다.
                      <ul>
                        <li>- 운영자 아이디, 접속 IP주소, 접속 일시, 서비스 이용내역</li>
                      </ul>
                    </li>
                  </ol>
                </div>`,
  AGREE_TERMS_2: `<h3 class="terms_tit">2. 개인정보 수집목적 및 이용목적</h3>
                <div class="terms_txt_area">
                  <p class="terms_txt">회사는 수집 한 개인정보를 다음과 같은 목적을 위해 활용합니다.</p>
                  <ol>
                    <li>1) 서비스 제공에 대한 계약 이행 및 서비스 제공에 따른 요금정산
                      <ul>
                        <li>- 서비스 및 콘텐츠 제공, 물품배송 또는 청구서 등 발송, 본인인증, 구매 및 요금결제, 요금추심</li>
                      </ul>
                    </li>
                    <li>2) 운영자 계정관리
                      <ul>
                        <li>- 서비스 제공, 개인식별, 부정 이용방지와 비인가 사용방지, 계정등록 의사 확인, 계정중복등록 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달</li>
                      </ul>
                    </li>
                    <li>3) 신규서비스 개발 및 마케팅
                      <ul>
                        <li>- 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계</li>
                      </ul>
                    </li>
                  </ol>
                </div>`,
  AGREE_TERMS_3: `<h3 class="terms_tit">3. 개인정보 보유 및 이용기간</h3>
                <div class="terms_txt_area">
                  <p class="terms_txt" style="font-size: 16px;font-weight: bold;">이용자의 개인정보는 원칙적으로 운영자 삭제 시까지 보존하며 운영자 삭제 시 지체 없이 파기합니다.
단, 관계법령의 규정에 의하여 보전할 필요가 있는 경우 명시한 기간 동안 보존하며, 그 외 다른 목적으로는 절대 사용하지 않습니다.
운영자 삭제 시 해당 운영자의 개인정보를 지체 없이 파기하며, 복구 및 재생되지 않도록 기술적 방법을 이용하여 완전하게 삭제합니다.</p>
                </div>`,
  AGREE_CHECK_MESSAGE: '개인정보 수집 및 이용에 동의합니다.',
  AGREE_ALERT_CHECK: '개인정보 수집 및 이용 에 체크해주세요',

  REGISTER_BUSINESS_INFO: '사업자정보',
  REGISTER_SERVICE_NAME: '서비스명',
  REGISTER_COMPANY_NAME: '회사명',
  REGISTER_BUSINESS_NUMBER: '사업자 등록번호',
  REGISTER_REPRESENTATIVE_NAME: '대표자명',
  REGISTER_BUSINESS_TYPE: '업종',
  REGISTER_BUSINESS_CONDITION: '업태',
  REGISTER_REPRESENTATIVE_PHONE: '대표전화번호',
  REGISTER_FAX: '팩스번호',
  REGISTER_REPRESENTATIVE_EMAIL: '대표 e메일',
  REGISTER_BUSINESS_ONLINE_MARKETING_DECLARATION: '통신판매신고번호',
  REGISTER_ADDRESS: '주소',
  REGISTER_ADDRESS_SEARCH: '우편번호 찾기',

  INSERT_ONLY_NUMBER: '숫자만 입력',
  INSERT_ONLY_NUMBER_DASH: '숫자와 - 만 입력 가능',

  REGISTER_IP_SETTING: '접속 IP 설정',
  REGISTER_IP_REGISTER: 'IP 주소 등록',
  REGISTER_IP_GUIDE: 'IP 주소 등록안내',
  REGISTER_IP_GUIDE_MESSAGE: `<div>
              현재 귀하의 회사 내부에서 사용중인 IP주소를 확인하시고 등록 해 주세요.
              <br>
              운영자 접속 시 IP주소를 통해 회사 내부와 외부를 구분하여 외부접속일 경우 SMS 추가인증을 실시합니다.
            </div>
            <div>
              <br>
              <b>(등록요령)</b>
            </div>
            <div>
              여러 개의 IP주소 등록 시 엔터 키 입력으로 구분 해 주세요.
              <br>
              IP 주소 대역으로 등록하시려면 - 기호로 구분 해 주세요. Ex)
              <span class="highlight">  192.168.10.1-255</span>`,

  REGISTER_PRIVACY_MANAGER_INFO: '개인정보 관리 책임자 정보',
  REGISTER_PRIVACY_MANAGER_NAME: '담당자명',
  REGISTER_PRIVACY_MANAGER_PHONE: '연락처',

  REGISTER_MEMO: '아래 내용을 반드시 확인하시고 등록 진행해주세요.',

  REGISTER_ALERT_INSERT_COMPANY_NAME: '회사명을 입력해주세요.',
  REGISTER_ALERT_INSERT_BUSINESS_NUMBER: '사업자 등록번호를 입력해주세요.',
  REGISTER_ALERT_INSERT_REPRESENTATIVE_NAME: '대표자명을 입력해주세요.',
  REGISTER_ALERT_INSERT_BUSINESS_TYPE: '업종을 입력해주세요.',
  REGISTER_ALERT_INSERT_BUSINESS_CONDITION: '업태를 입력해주세요.',
  REGISTER_ALERT_INSERT_REPRESENTATIVE_PHONE: '대표전화번호를 입력해주세요.',
  REGISTER_ALERT_INSERT_REPRESENTATIVE_EMAIL: '대표 e메일을 입력해주세요.',
  REGISTER_ALERT_INSERT_ADDRESS: '사업장 주소를 입력해주세요.',
  REGISTER_ALERT_IP_MAX_NUM: 'ip대역 전체 길이는 1000자를 넘을 수 없습니다.',
  REGISTER_ALERT_IP_WRONG_ADDRESS: '정확한 ip 대역폭 주소를 입력해 주세요.',
  REGISTER_ALERT_IP_WRONG: '정확한 ip 주소를 입력해 주세요.',

  MASTER_INFO: '마스터 운영자 계정 정보',
  MASTER_ID: '아이디',
  MASTER_BTN_ID_DUPLICATE: '중복확인',
  MASTER_ID_GUIDE: '5~20자리 영문 소문자, 숫자를 사용하실 수 있습니다.',
  MASTER_ID_MAX_NUM: '5자 이상의 아이디를 입력해주세요.',
  MASTER_ID_PASS: '사용 가능한 아이디입니다.',
  MASTER_ID_DUPLICATE: '이미 사용 중인 아이디입니다.',

  MASTER_PASSWORD: '비밀번호',
  MASTER_PASSWORD_GUIDE: '영문,숫자,특수문자를 조합하여 8~20자리로 설정하셔야 합니다.',
  MASTER_PASSWORD_MAX_NUM: '8자리 이상의 비밀번호를 입력해주세요.',
  MASTER_PASSWORD_COMPLEXITY_2: '영문,숫자,특수문자 중 2종류 이상 조합하여 입력해주세요.',
  MASTER_PASSWORD_COMPLEXITY_3: '영문,숫자,특수문자를 3종류 모두 조합하여 입력해주세요.',
  MASTER_PASSWORD_WRONG_SPECIAL: '!@#$%^&amp;+=-_.() 특수문자만 사용 가능합니다.',
  MASTER_PASSWORD_CONFIRM: '비밀번호 확인',
  MASTER_PASSWORD_CONFIRM_FAIL: '비밀번호를 다시 확인해주세요.',
  MASTER_PASSWORD_CONFIRM_PASS: '비밀번호가 동일합니다.',
  MASTER_NAME: '운영자명',
  MASTER_JOB_POSITION: '직급',
  MASTER_PHONE: '전화번호',
  MASTER_MOBILE: '휴대폰번호',
  MASTER_BTN_AUTH_SMS: 'SMS인증',
  MASTER_SEND_SMS_FAIL: '휴대폰번호를 다시 확인 해 주세요.',
  MASTER_SEND_SMS_PASS: '휴대폰 번호로 인증코드가 전송되었습니다.',
  MASTER_INSERT_SMS_AUTH_KEY: '인증코드 입력',
  MASTER_BTN_SMS_AUTH_COMPLETE: '인증완료',
  MASTER_SMS_AUTH_COMPLETE_PASS: '인증 완료되었습니다.',
  MASTER_SMS_AUTH_COMPLETE_FAIL: '인증 실패했습니다.',
  MASTER_EXTERNAL_ACCESS: '외부접속',
  MASTER_EXTERNAL_ACCESS_ENABLE: '가능',
  MASTER_EXTERNAL_ACCESS_NOT_ENABLE: '불가',
  MASTER_EXTERNAL_ACCESS_GUIDE: '※ 외부접속 시 운영자  휴대폰 SMS 인증 절차를 실시합니다.',
  MASTER_ALERT_ID_DUPLICATE: '아이디 중복 확인이 필요합니다.',
  MASTER_ALERT_PASSWORD_CONFIRM: '비밀번호를 확인해주세요.',
  MASTER_ALERT_INSERT_JOB_POSITION: '직급을 입력해주세요.',
  MASTER_ALERT_INSERT_PHONE: '전화번호를 입력해주세요.',
  MASTER_ALERT_INSERT_MOBILE: '휴대폰 번호를 입력해주세요.',
  MASTER_ALERT_MOBILE_VALIDATE: '휴대폰번호 형식을 확인해주세요.',
  MASTER_ALERT_SMS_AUTH: 'SMS 인증을 진행해 주시기 바랍니다.',
  MASTER_CONFIRM_REGISTER: '입력하신 내용으로 마스터 운영자 계정을 신청합니다.',

  COMPLETE_TITLE: 'NCP 서비스 등록 신청이 완료 되었습니다.',
  COMPLETE_ID: '운영자 아이디',
  COMPLETE_NAME: '운영자 정보',
  COMPLETE_GUIDE_MESSAGE_1: '심사가 완료될 때까지 잠시만 기다려주시기 바랍니다.',
  COMPLETE_GUIDE_MESSAGE_2: '심사가 완료되면 {{email}} 로 알려드립니다.',

  ERROR_EXPIRED_TITLE: '신청 이용 기간 만료',
  ERROR_EXPIRED_SUB_TITLE: '이용 가능한 기간이 만료되었습니다.',
  ERROR_EXPIRED_DESC: '확인 후 재 신청 진행해 주시기 바랍니다.<br>감사합니다.',
  ERROR_BTN_MAIN: '메인으로',

  ALERT_EXIST_SERVICE: '이미 등록 완료된 서비스입니다.',
  ALERT_INVESTIGATION_SERVICE: '검토 중인 서비스입니다.',
  ALERT_EXIST_MASTER: '이미 등록 완료된 운영자 계정입니다.',
  ALERT_INVESTIGATION_MASTER: '검토 중인 운영자 계정입니다.',
  ALERT_DELETE_MASTER: '이미 삭제 처리 된 운영자 계정입니다.',
});
