import { isDate } from 'underscore';
import { parse, ParsedQs } from 'qs';

export const sendQueryString = (vueComponent, query, deleteEmptyQuery = true, duplicateQueryUpdate = false): void => {
  for (const key in query) {
    // false 는 제거하지 않도록 수정
    if (deleteEmptyQuery && !query[key] && query[key] !== false) delete query[key];
    // number를 string으로 치환 <- $route.query 값이 모두 string처리 되기 때문에
    if (typeof query[key] === 'number') query[key] = query[key].toString();
  }

  if (duplicateQueryUpdate) {
    // dataRefresh 라는 파라미터를 만들어 유니크 값을 할당한다.
    query['dataRefresh'] = Date.now();
  }

  // 이 행위를 굳이 history 로 남길 이유가 없어보여 push => replace 로 변경.
  vueComponent.$router
    .replace({ query })
    .then(({ query }) => {
      // duplicateQueryUpdate 시 생기는 dataRefresh 파라미터를 push 후 회수.
      if (!query?.dataRefresh) return;
      delete query.dataRefresh;
    })
    // 만일 위 단계가 실패한다면 여기에서 catch 될 것이다.
    .catch(console.error);
};

/**
 * convertQueryParamItemToBoolean
 * @author hslee
 * */
// TODO : 이거 대충 처리해놨는데 query string을 Nuber, boolean으로 모두 변환하게 개선 해야 함
export const convertQueryParamItemToBoolean = (queries: object): object => {
  for (const query in queries) {
    if (queries[query] === 'true' || queries[query] === 'false') {
      queries[query] = queries[query] === 'true';
    }
  }
  return queries;
};

type CoveredType = {
  [key: string]: string | number | boolean | Date | string[] | number[] | boolean[] | CoveredType;
};

/**
 * @parseQueryStringOfBasedObject
 *
 * @param basedObject : key 는 똑같되 정합한 타입의 value 를 지닌 오브젝트 ( ex: default query )
 * @param stringifyQuery : $route.query
 * @param parsedQuery
 *
 * @return BasedObject
 */
export function parseQueryStringOfBasedObject<BasedObject extends CoveredType>(
  basedObject: BasedObject,
  stringifyQuery: string,
  parsedQuery?: ParsedQs,
): BasedObject {
  const query = parsedQuery ? parsedQuery : parse(stringifyQuery.replace('?', ''));

  return Object.entries(query).reduce((acc, [queryKey, queryValue]) => {
    const getTypedValue = value => {
      const ignoreKeyNames = ['dataRefresh'];

      if (ignoreKeyNames.includes(queryKey)) return;
      const valueType = typeof value;
      switch (valueType) {
        case 'string':
          return queryValue.toString();
        case 'number':
          return queryValue === null ? null : Number(queryValue);
        case 'boolean':
          return Boolean(queryValue);
        default:
          if (Array.isArray(value)) {
            const unTypedQueryValue = Array.isArray(queryValue)
              ? (queryValue as string[])
              : (queryValue as string).split(',');

            if (typeof value[0] === 'string') return unTypedQueryValue;
            if (typeof value[0] === 'number') return unTypedQueryValue.map(Number);
            if (typeof value[0] === 'boolean') unTypedQueryValue.map(Boolean);
          }
          if (isDate(value)) {
            return queryValue;
          }

          if (value === null) {
            return query[queryKey];
          }

          if (valueType === 'object' && value !== null) {
            return parseQueryStringOfBasedObject(
              basedObject[queryKey] as CoveredType,
              stringifyQuery,
              query[queryKey] as ParsedQs,
            );
          }
      }
    };

    const typedValue = getTypedValue(basedObject[queryKey]);
    if (typedValue !== null && typedValue !== undefined) acc[queryKey] = typedValue;

    return acc;
  }, {}) as BasedObject;
}
