


























































import { Vue, Component, PropSync, Prop, Watch, Ref } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import { addDay, getStrDate, isSameOrAfter } from '@/utils/dateFormat';
import TextInput from '@/components/common/input/TextInput.vue';
import DateCalendar from '@/components/common/DateCalendar.vue';
import { DateCalendarOption, DateRangeOption, ReservationInformation, StartAndEndYmdHm } from '@/types';

import {
  changeableDate,
  convertToYmdAndHms,
  convertToYmdt,
  getDefaultReservationInfo,
  getDeliveryDate,
  getReservationDateRangeOption,
  today,
} from '@/views/contents/product/basic/ProductAdd';
import CurrencyTextInput from '@/components/common/CurrencyTextInput.vue';
import { IMMUTABLE_FIELD } from '@/const/contents/product';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';

@Component({
  components: {
    Currency: CurrencyTextInput,
    DateCalendar,
    TextInput,
    RadioGroup,
    DateRangePicker,
  },
})
export default class ReservedSales extends Vue {
  @PropSync('reservationInfoModel', { required: true })
  private reservationInfoModelSync: ReservationInformation;

  @PropSync('useReservation', { required: true })
  private useReservationSync: boolean;

  // 예약판매시작일 도래 시, 예약판매 설정 및 시작일 수정 불가
  @PropSync('isOverReservationSale', { required: true })
  private isOverReservationSaleSync: boolean;

  @Prop({ required: true })
  private isEditMode: boolean;

  @Prop({ required: true })
  private isReady: boolean; //승인완료전 상태

  @Prop({ default: 0, required: false })
  private readonly optionCnt: number;

  @Prop({ required: true })
  private readonly useOption: boolean;

  @Prop({ required: true })
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly isProhibited: boolean;

  @Prop({ required: true })
  private readonly isFinishedReservationSales: boolean;

  private radioOption: RadioBoxGroupOption<boolean> = {
    name: 'reservationUseYn',
    data: [
      { id: 'reservationUseYnFalse', value: false, display: 'PRODUCT.ADD.NOT_USE_CONFIG' },
      { id: 'reservationUseYnTrue', value: true, display: 'PRODUCT.ADD.USE_CONFIG' },
    ],
  };

  private IMMUTABLE_FIELD = IMMUTABLE_FIELD;

  /*
   * saved 최종 저장 상태 표기(reservationInfoModelSync.deliveryYmdt 변경 시 최종 값인지 초기 값인지 확인용)
   * 최종 예약판매일 & 예약배송시작일 설정
   * */
  private saved = false;
  private setFinalReservationInfoModel() {
    this.saved = true;
    this.reservationInfoModelSync.startYmdt = convertToYmdt(
      this.reservationDateRange.startYmd,
      this.reservationDateRange.startHms,
    );
    this.reservationInfoModelSync.endYmdt = convertToYmdt(
      this.reservationDateRange.endYmd,
      this.reservationDateRange.endHms,
    );
  }
  private setFinalDeliveryYmdt() {
    if (this.customDeliveryDate) {
      this.reservationInfoModelSync.deliveryYmdt = `${this.customDeliveryDate}`;
      return;
    }
    const { selectedYmd, selectedHour, selectedMinute } = this.deliveryDateCalendarOption;
    this.reservationInfoModelSync.deliveryYmdt = `${selectedYmd} ${selectedHour ?? '00'}:${selectedMinute ?? '00'}:00`;
  }
  public setReservationInfoModel(): void {
    if (!this.useReservationSync) {
      this.reservationInfoModelSync = null;
      return;
    }

    this.setFinalReservationInfoModel();
    this.setFinalDeliveryYmdt();
  }

  @Ref()
  private readonly reservationStockRef!: TextInput;
  public focus(): void {
    this.reservationStockRef.focus();
  }

  private isDatePickerReset = false;
  private isChangedDeliveryDate = false;
  private changeSelectedDateTime(deliveryYmdt: string) {
    this.deliveryDateCalendarOption.selectedYmd = getStrDate(deliveryYmdt);
    this.customDeliveryDate = deliveryYmdt;

    const hms = getStrDate(deliveryYmdt, 'HH:mm:ss').split(':');
    this.deliveryDateCalendarOption.selectedHour = hms[0];
    this.deliveryDateCalendarOption.selectedMinute = hms[1];
    this.isDatePickerReset = false;
    this.isChangedDeliveryDate = true;
  }

  private getReservationDateRangeOption(reservationDateRangeOption: DateRangeOption): DateRangeOption {
    if (!this.useReservationSync) return;
    const disabled = {
      startYmdt: !changeableDate(getStrDate(this.reservationInfoModelSync.startYmdt)),
      endYmdt: isSameOrAfter(today, getStrDate(this.reservationInfoModelSync.endYmdt)),
    };

    const hasBeenStartedReservedPeriod = disabled.startYmdt;
    const fromRanges = hasBeenStartedReservedPeriod ? getStrDate(this.reservationInfoModelSync.startYmdt) : today;
    return {
      ...reservationDateRangeOption,
      fromRanges,
      disabled,
    };
  }

  private customDeliveryDate = '';
  @Watch('reservationInfoModelSync.endYmdt')
  private initDateInfo(): void {
    if (this.saved) {
      this.saved = false;
      return;
    }
    if (!this.isEditMode) return;
    if (!this.reservationInfoModelSync) return;

    this.reservationDateRange = {
      ...convertToYmdAndHms(this.reservationInfoModelSync.startYmdt, this.reservationInfoModelSync.endYmdt),
    };

    this.reservationDateRangeOption = {
      ...this.getReservationDateRangeOption(this.reservationDateRangeOption),
    };
  }

  @Watch('useReservationSync')
  @Watch('reservationDateRange.startYmd')
  private checkDeliveryDate() {
    this.changedUsableReservationStatus();
    this.setCustomDeliveryDate();
    this.setOverDateStatus();
    this.setDeliveryDateCalendarOption();
  }

  private changedUsableReservationStatus() {
    if (!this.useReservationSync) {
      this.reservationDateRange.startYmd = today;
      this.reservationDateRange.startHms = DEFAULT_TIME_RANGE.START;
      return;
    }

    if (this.reservationDateRangeOption?.name === undefined) {
      this.reservationDateRangeOption = getReservationDateRangeOption();
    }
  }

  private setDeliveryDateCalendarOptionBySelectedTime() {
    const selectedTime = this.customDeliveryDate?.split(' ')[1]?.split(':');
    this.deliveryDateCalendarOption.selectedYmd = getStrDate(this.customDeliveryDate);
    this.deliveryDateCalendarOption.selectedHour = selectedTime[0];
    this.deliveryDateCalendarOption.selectedMinute = selectedTime[1];
  }

  private setCustomDeliveryDate() {
    this.customDeliveryDate = this.reservationInfoModelSync.deliveryYmdt;
    this.customDeliveryDate && this.setDeliveryDateCalendarOptionBySelectedTime();
  }

  private setOverDateStatus() {
    this.isOverReservationSaleSync = this.reservationDateRangeOption.disabled?.startYmdt;
  }

  private setDeliveryDateCalendarOption() {
    const { startYmd, endYmd } = this.reservationDateRange;
    const { selectedYmd: deliveryDate } = this.deliveryDateCalendarOption;
    if (!deliveryDate) {
      this.deliveryDateCalendarOption.selectedYmd = getStrDate(getDeliveryDate(endYmd));
    }
    this.deliveryDateCalendarOption.fromRanges = getStrDate(getDeliveryDate(startYmd));
  }

  @Watch('optionCnt')
  @Watch('useOption')
  private setReservationAmount(): void {
    if (this.useOption) {
      this.reservationInfoModelSync.stockCnt = this.optionCnt;
    }
  }

  private onChange(currValue: boolean) {
    if (currValue === true && this.reservationInfoModelSync === null) {
      this.reservationInfoModelSync = getDefaultReservationInfo();
    }
  }

  private created() {
    this.init();
  }

  private init(): void {
    this.isDatePickerReset = true;
  }

  private reservationDateRangeOption: DateRangeOption = {
    name: 'reservation',
    dateType: 'YmdHms',
    hasPeriodBtn: false,
    fromRanges: today,
  };

  private deliveryDateCalendarOption: DateCalendarOption = {
    name: 'reservationDateRangeOption',
    selectedYmd: '',
    fromRanges: '',
  };

  // 예약판매 기간 설정일자
  private reservationDateRange: StartAndEndYmdHm = {
    startYmd: today,
    endYmd: addDay(new Date(), 7),
    startHms: DEFAULT_TIME_RANGE.START,
    endHms: DEFAULT_TIME_RANGE.END,
  };
}
