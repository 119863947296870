

















import { Component, Prop, PropSync, Mixins } from 'vue-property-decorator';
import FileInputMixin from '@/components/common/input/fileInput/FileInputMixin.vue';
import { ThrowInputValidationError } from '@/utils/validate';
import { GetStorageRequest, PostStorageRequest } from 'ncp-api-supporter';
import { saveBinaryDataToFile } from '@/utils/fileSaver';

export interface FileInformation {
  originalFileName: string;
  uploadedFileName: string;
}

@Component
export default class FileAttacher extends Mixins(FileInputMixin) {
  @PropSync('files', { required: true })
  private filesSync: FileInformation[];

  @Prop({ default: 10 })
  private readonly maxSizeForAttachedFiles: number;

  onUpload({ currentTarget }: { currentTarget: HTMLInputElement }) {
    try {
      this.maxSizeForAttachedFiles > 0 && this.checkSize();
      this.executeUploading(currentTarget.files[0]);
    } catch ({ message }) {
      message && alert(message);
    }
  }

  private hasFiles(): boolean {
    return this.filesSync?.length > 0;
  }

  private checkSize() {
    if (this.filesSync.length >= this.maxSizeForAttachedFiles)
      ThrowInputValidationError.of('maxSize', `첨부파일은 최대 ${this.maxSizeForAttachedFiles}개만 등록 가능합니다.`);
  }

  private executeUploading(file: File) {
    this.$api.postStorage(this.generateStorageRequest(file)).then(({ data }) => this.mutateFiles(data));
  }

  private mutateFiles(data: FileInformation) {
    this.filesSync = this.filesSync.concat(data);
  }

  private generateStorageRequest(file: File): PostStorageRequest {
    const data = new FormData();
    data.append('file', file);

    return {
      pathParams: {
        container: 'ncp',
      },
      data,
    };
  }

  private download({ uploadedFileName, originalFileName }: FileInformation) {
    const request: GetStorageRequest = {
      params: { fileName: uploadedFileName, container: 'ncp' },
      responseType: 'blob',
    };

    this.$api.getStorage(request).then(({ data }) => saveBinaryDataToFile(data, originalFileName));
  }

  private removeAttachedFile(targetIndex: number) {
    this.filesSync = this.filesSync.filter((_, index) => index !== targetIndex);
  }
}
