




















































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { validateBankAccount, validateRefundAdjust } from '@/components/shipping/claim/validation';
import { getDefaultForm } from '@/components/popup/claim/claimOptions';
import ClaimPopupMixin from '@/components/popup/claim/ClaimPopupMixin';
import NoticeBox from '@/components/popup/claim/ClaimPopup/NoticeBox.vue';
import ReturnWithdrawReason from '@/components/popup/claim/ClaimPopup/ReturnWithdrawReason.vue';
import ClaimProducts from '@/components/popup/claim/ClaimPopup/ClaimProducts';
import ClaimInformation from '@/components/popup/claim/ClaimPopup/ClaimInformation.vue';
import AmountDetail from '@/components/popup/claim/ClaimPopup/AmountDetail.vue';
import ReturnDeliveryAmount from '@/components/popup/claim/ClaimPopup/ReturnDeliveryAmount.vue';
import RefundAmount from '@/components/popup/claim/ClaimPopup/RefundAmount.vue';
import BenefitRecalculate from '@/components/popup/claim/BenefitRecalculate.vue';
import DeliveryRecalculate from '@/components/popup/claim/ClaimPopup/DeliveryRecalculate.vue';
import NaverPayReturnHold from '@/components/popup/claim/NaverPayReturnHold.vue';
import { ClaimPopupForm } from '@/types/claim';
import {
  isReturnProcBeforeReceive,
  isReturnProcRequestRefund,
  isReturnProcWaitingRefund,
  isReturnRefundAmtAdjustRequested,
  isReturnRequest,
  isReturnRejectRequest,
} from '@/components/popup/claim/claimConditions';
import { claimStatusTypes, claimTypes, responsibleObjectTypes } from '@/const/claim';
import { Amounts, PutReturnsNoRequest } from 'ncp-api-supporter/dist/types';
import NoLocalStorageWindowPopup from '@/views/popups/NoLocalStorageWindowPopup.vue';
import { PostOptionCancelsNoPredictAmounts, ReturnsPredictAmounts } from 'ncp-api-supporter';
import { getOnlyNumbers } from '@/utils/numberFormat';
import { PopupClose, PopupResult, throwPopup, throwWindowPopup } from '@/helpers/popup';
import { payTypes } from '@/const/order';
import NaverPayAmountDetail from '@/components/popup/claim/NaverPayAmountDetail.vue';

// 반품리스트 기획서 p.31
@Component({
  components: {
    DeliveryRecalculate,
    NoticeBox,
    ReturnWithdrawReason,
    ClaimProducts,
    ClaimInformation,
    AmountDetail,
    ReturnDeliveryAmount,
    RefundAmount,
    BenefitRecalculate,
    NaverPayReturnHold,
    NaverPayAmountDetail,
  },
  computed: {
    isReturnRequest,
    isReturnRejectRequest,
    isReturnProcWaitingRefund,
    isReturnProcRequestRefund,
    isReturnProcBeforeReceive,
    isReturnRefundAmtAdjustRequested,
    isNaverPayClaim(): boolean {
      return this.claimNoResponse.originalPayType === 'NAVER_PAY';
    },
    isNoDelivery() {
      // '배송안함' 클레임인지 확인
      return this.claimNoResponse.returnDelivery === null;
    },
    hasWitdhrawReason() {
      return this.claimNoResponse.withdrawReason !== null;
    },
    showWithdrawalButton(): boolean {
      return (
        this.isReturnRequest ||
        this.isReturnProcWaitingRefund ||
        this.isReturnProcRequestRefund ||
        this.isReturnProcBeforeReceive ||
        this.isReturnRefundAmtAdjustRequested
      );
    },
    showApproveButton(): boolean {
      return this.isReturnRequest;
    },
    showWithdrawApproveButton(): boolean {
      return this.isReturnRejectRequest;
    },
    showDepositConfirmButton(): boolean {
      return this.isExchangeProcRequestPay;
    },
    showRefundButton(): boolean {
      return this.isReturnRefundAmtAdjustRequested || this.isReturnProcRequestRefund;
    },
    showNaverPayApproveButton(): boolean {
      if (!this.isNaverPayClaim) {
        return false;
      }

      return this.isReturnProcWaitingRefund;
    },
    showNaverPayWithHoldButton(): boolean {
      if (!this.isNaverPayClaim) {
        return false;
      }

      return this.isReturnProcWaitingRefund;
    },
    showNaverPayWithHoldCancelButton(): boolean {
      if (!this.isNaverPayClaim) {
        return false;
      }

      return this.isReturnProcWaitingRefund;
    },
    showNaverPayAmountDetail() {
      return this.isNaverPayClaim && !!this.claimNoResponse.externalData?.naver;
    },
  },
})
export default class ReturnPopup extends Mixins(ClaimPopupMixin, NoLocalStorageWindowPopup) {
  private formData: ClaimPopupForm = getDefaultForm();

  @Watch('formData.responsibleObjectType', { immediate: false })
  private predictClaimAmounts() {
    this.fetchPredictAmounts();
  }

  private async fetchPredictAmounts() {
    if (!this.isPredictableClaim) {
      return;
    }

    this.formData.sellerPaysClaimedDelivery = this.formData.responsibleObjectType === responsibleObjectTypes.SELLER; // TODO: 추출, 판매자부담 체크박스 유무는 귀책대상을 따라간다.

    const {
      responsibleObjectType,
      returnWayType,
      sellerPaysClaimedDelivery,
      refundAdjustAmt,
      returnDeliveryProposedAmt,
    } = this.formData;

    const { data } = await this.$api.postReturnsNoPredict({
      pathParams: {
        no: String(this.$route.query.claimNo),
      },
      data: {
        responsibleObjectType,
        returnWayType,
        sellerPaysClaimedDelivery,
        refundAdjustAmt: getOnlyNumbers(refundAdjustAmt),
        returnDeliveryProposedAmt: this.formData.sellerPaysClaimedDelivery ? 0 : returnDeliveryProposedAmt,
      },
    });

    this.amounts = data.amounts;
    this.shipping = data.shipping;
  }

  async created() {
    await this.fetchClaimNo();
    this.setDefaultForm();
  }

  private setDefaultForm() {
    this.formData = getDefaultForm(this.claimNoResponse);
  }

  private async onWithdraw() {
    if (
      this.claimStatusType === claimStatusTypes.RETURN_PROC_REQUEST_REFUND &&
      this.claimNoResponse.originalPayType === payTypes.NAVER_PAY
    ) {
      alert(this.$t('CLAIM.MESSAGE.NAVER_PAY_CENTER_PROCESS_ALERT'));
      return;
    }

    const { validationType, afterClaimNos } = await this.checkWithdraw();

    const { state }: PopupResult = await throwPopup({
      name: 'WithdrawMessage',
      data: {
        claimType: this.claimType,
        validationType,
        afterClaimNos,
      },
    });

    if (state !== PopupClose.CONFIRM) {
      return;
    }

    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
    };

    await this.$api.putReturnsNoWithdraw(request);

    alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
    this.onPositiveClick();
  }

  private onReturnWithdrawApprove(): void {
    if (!confirm(this.$t('CLAIM.RETURNPOPUP.ASK_RETURN_WITHDRAW_APPROVE').toString())) {
      return;
    }

    this.$api
      .putReturnsNoApproveWithdraw({
        pathParams: {
          no: this.$route.query.claimNo.toString(),
        },
      })
      .then(() => {
        alert(this.$t('CLAIM.EXCHANGEPOPUP.PROCESS_SUCCESS_MESSAGE'));
        this.onPositiveClick();
      });
  }

  private onRefund() {
    if (!this.validateNaverPayClaim()) {
      return;
    }

    const { refundType, refundBankAccount, refundAdjustAmt, refundAdjustReason } = this.formData;
    if (!validateBankAccount(refundType, refundBankAccount)) {
      return;
    }

    if (!validateRefundAdjust(getOnlyNumbers(refundAdjustAmt), refundAdjustReason)) {
      return;
    }

    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_REFUND_PROCESS').toString())) {
      return;
    }

    const isRefundAdjustReasonEmpty =
      refundAdjustReason === null || refundAdjustReason === undefined || refundAdjustReason.trim().length === 0;
    const { payAmt, additionalPayAmt, accumulationPayAmt } = this.claimNoResponse.claimData.amounts.adjustedAmounts;
    const changedRefundAmt = payAmt - additionalPayAmt + accumulationPayAmt - getOnlyNumbers(refundAdjustAmt);
    const isZeroRefund =
      this.claimNoResponse.claimData.amounts.adjustedAmounts.claimAmt === 0 || changedRefundAmt === 0;

    const request = {
      pathParams: {
        no: String(this.$route.query.claimNo),
      },
      data: {
        refundAdjustReason: isRefundAdjustReasonEmpty ? '' : refundAdjustReason.trim(),
        refundType: isZeroRefund ? 'ZERO_REFUND' : refundType, // claimAmt가 0원일 때 프론트에서 ZERO_REFUND로 바꿔보내줘야한다.,
        refundAdjustAmt: getOnlyNumbers(refundAdjustAmt),
        refundBankAccount: refundType === 'ACCOUNT' ? refundBankAccount : null,
      },
    };

    const apiMap = {
      [claimStatusTypes.RETURN_PROC_REQUEST_REFUND]: () => this.$api.putReturnsNoCashRefund(request),
      [claimStatusTypes.RETURN_REFUND_AMT_ADJUST_REQUESTED]: () => this.$api.putReturnsNoAdjustConfirm(request),
    };

    apiMap[this.claimStatusType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }

  private onApprove() {
    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_RETURN_ACCEPT').toString())) {
      return;
    }

    const { invoiceNo, deliveryCompanyType, evadesReturnProcess } = this.formData.returnDelivery;
    const request = {
      pathParams: {
        no: String(this.$route.query.claimNo),
      },
      data: {
        invoiceNo: invoiceNo ? invoiceNo : null,
        deliveryCompanyType: deliveryCompanyType.length ? deliveryCompanyType : null,
        evadesReturnProcess: evadesReturnProcess,
      },
    };

    this.$api.putReturnsNoApprove(request).then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }

  private async onPutClaim(modifyType: 'reason' | 'refundAccount'): Promise<void> {
    const {
      responsibleObjectType,
      refundType,
      sellerPaysClaimedDelivery,
      returnDeliveryProposedAmt,
      returnDeliveryProposedReason,
      returnWayType,
    } = this.formData;

    let data: PutReturnsNoRequest['data'] = {
      refundType,
      responsibleObjectType,
      sellerPaysClaimedDelivery,
      returnDeliveryProposedAmt,
      returnDeliveryProposedReason,
      returnWayType,
      refundBankAccount: null,
      reasonDetail: '',
      claimOrderOptions: [],
    };

    data = this.getClaimReasonRequestFormat<typeof claimTypes.RETURN>(data, this.formData, modifyType);
    data = this.getClaimRefundAccountRequestFormat<typeof claimTypes.RETURN>(data, this.formData, modifyType);

    await this.$api.putReturnsNo({
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
      data: data as PutReturnsNoRequest['data'],
    });

    const beforeAmounts = this.amounts;
    await this.fetchClaimNo(); // 변경된 데이터만 formData에 set해야해서 다시 fetch

    this.maintainBeforeAmounts(beforeAmounts); // 조정금액 입력 전 금액 UI 유지
    await this.fetchPredictAmounts(); // 변경된 데이터 기준으로 다시 predict

    alert(this.$t('CLAIM.MESSAGE.COMPLETE_SAVE_MODIFY'));
  }

  private maintainBeforeAmounts(beforeAmounts: Amounts | PostOptionCancelsNoPredictAmounts | ReturnsPredictAmounts) {
    this.amounts = beforeAmounts;
  }

  private onNaverPayReturnApprove() {
    if (!confirm(this.$t('CLAIM.MESSAGE.ASK_NAVER_RETURN_REQUEST_APPROVE').toString())) {
      return false;
    }

    this.$api
      .putClaimsNoNaverPayReturnsApprove({
        pathParams: {
          no: this.$route.query.claimNo.toString(),
        },
      })
      .then(() => {
        alert(this.$t('CLAIM.MESSAGE.COMPLETE_REQUEST'));
        this.onPositiveClick();
      });
  }

  private onNaverPayReleaseHold() {
    if (!confirm(this.$t('CLAIM.MESSAGE.ASK_RETURN_RELEASE_HOLD').toString())) {
      return false;
    }

    const request = {
      pathParams: {
        no: String(this.$route.query.claimNo),
      },
    };

    this.$api.putClaimsNoNaverPayReturnsReleaseHold(request).then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_REQUEST'));
      this.onPositiveClick();
    });
  }

  private openNaverPayReturnHold() {
    throwWindowPopup(
      'NaverPayReturnHold',
      { claimNo: String(this.$route.query.claimNo), claimType: claimTypes.RETURN },
      'lg',
      result => {
        if (result.state === 'close') return;

        location.reload();
      },
    );
  }
}
