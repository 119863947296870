







































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import SettingColumnItem from '@/components/common/grid/SettingColumnItem.vue';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import OrderDetailExposureSetting from '@/components/common/OrderDetailExposureSetting.vue';
import { OptionData } from '@/helpers/type';
import { GridLayout } from 'ncp-api-supporter';
import { TranslateResult } from 'vue-i18n';
import { DetailOpenOptions } from 'ncp-api-supporter/dist/types/modules/admin/admin';
import { DefaultColumnOrder } from '@/types';
type Sort = 'default' | 'asc' | 'desc';
type Move = 'prev' | 'next' | 'first' | 'last'; // 방향의 개념이 아니기 때문에 배열이 연상되는 이름들로 바꿈
type Direction = 'left' | 'right';
const sortOption: OptionData<Sort>[] = [
  { value: 'default', name: 'GRID.LAYER.SORT_BY_DEFAULT' }, // 기본순서
  { value: 'asc', name: 'GRID.LAYER.SORT_BY_ASC' }, // 가나다순
  { value: 'desc', name: 'GRID.LAYER.SORT_BY_DESC' }, //가나다역순
];

interface Data {
  columnOrders: ColumnOrder[];
  visibleFixedColumnNames?: string[];
  detailOpenOptions: DetailOpenOptions;
}

export interface ColumnOrder extends GridLayout {
  displayName: TranslateResult;
}
const scrollTo = (el: HTMLElement, to: number): void => {
  el.scrollTop = to;
};
@Component({
  components: {
    OrderDetailExposureSetting,
    SettingColumnItem,
    ModalHeader,
    SelectBox,
  },
})
export default class EditGridLayout extends Vue {
  @Prop() private data: Data;
  private readonly sortOption = sortOption;
  private sortBy: Sort = 'default';
  private columnOrders: DefaultColumnOrder[] = [];
  private detailOpenOptions: DetailOpenOptions | null = null;
  private selectedColumnNames: string[] = [];
  private visibleFixedColumnNames: string[] = [];
  private selectedDirection: null | Direction = null;
  @Ref() scrollBoxLeft!: HTMLDivElement;
  @Ref() scrollBoxRight!: HTMLDivElement;

  private created() {
    const { columnOrders, visibleFixedColumnNames, detailOpenOptions } = this.data;

    this.columnOrders = this.mapDefaultColumnOrder(columnOrders);
    this.detailOpenOptions = detailOpenOptions;
    if (visibleFixedColumnNames) this.visibleFixedColumnNames = visibleFixedColumnNames;
  }

  private mapDefaultColumnOrder(columns: ColumnOrder[]): DefaultColumnOrder[] {
    return columns.map((column, defaultIndex) => {
      Object.assign(column, { defaultIndex });
      return column;
    }) as DefaultColumnOrder[];
  }

  private get gridLayouts(): GridLayout[] {
    return this.columnOrders.map(
      ({ visible, displayOrder, columnName }: DefaultColumnOrder): GridLayout => ({
        visible,
        displayOrder,
        columnName,
      }),
    );
  }

  private get orderedColumnOrders() {
    const columnOrder = [...this.columnOrders];
    if (this.sortBy === 'default') {
      return columnOrder.sort((fst, snd) => fst.defaultIndex - snd.defaultIndex);
      // return columnOrder.sort((fst, snd) => fst.displayOrder - snd.displayOrder);
    }
    return columnOrder.sort((fst, snd) => {
      const order = fst.displayName < snd.displayName ? -1 : fst.displayName > snd.displayName ? 1 : 0; // asc
      if (this.sortBy === 'asc') return order;
      if (this.sortBy === 'desc') return order * -1;
    });
  }

  private get visibleColumnOrders() {
    return this.columnOrders.filter(({ visible }) => visible);
  }
  private isSelected(columnName: string, isEntry: Direction) {
    const isSameColumn = isEntry === this.selectedDirection;

    return isSameColumn && this.selectedColumnNames.includes(columnName);
  }
  private onClickItem({ shiftKey }: MouseEvent, columnName: string, selectEntry: Direction) {
    const isSameColumnSelect = selectEntry === this.selectedDirection;
    if (!isSameColumnSelect) this.reset();
    this.selectedDirection = selectEntry;

    if (!shiftKey) {
      this.selectedColumnNames = [columnName];
      return;
    }
    const selectedIndex = this.selectedColumnNames.indexOf(columnName);
    if (selectedIndex < 0) {
      this.selectedColumnNames.push(columnName);
    } else {
      this.selectedColumnNames.splice(selectedIndex, 1);
    }
  }
  private onVisibleStateToggle(toVisible: boolean) {
    const moveTo = toVisible ? 'right' : 'left';
    if (!this.toggleValidate(moveTo)) return;

    this.selectedColumnNames.forEach(columnName => {
      const selectedColumnOrder = this.columnOrders.find(columnOrder => columnOrder.columnName === columnName);
      if (selectedColumnOrder) selectedColumnOrder.visible = toVisible;
    });
    this.reset();
  }
  private toggleValidate(moveTo: Direction): boolean {
    if (this.selectedDirection === null) {
      switch (moveTo) {
        case 'left':
          alert(this.$t('GRID.LAYER.ALERT_UNSELECT_MOVE_TO_LEFT'));
          break;
        case 'right':
          alert(this.$t('GRID.LAYER.ALERT_UNSELECT_MOVE_TO_RIGHT'));
          break;
      }
      return false;
    }
    const immovable = moveTo === this.selectedDirection;
    if (immovable) return false;
    return true;
  }
  private reset() {
    this.selectedDirection = null;
    this.selectedColumnNames = [];
  }
  /**
   * @onMove 이동 작전
   *
   * 미션 : 선택된 유닛(this.selectedColumnNames) 들이 지정된 위치(Move) 로 이동 해야한다.
   *
   * 모든 유닛 (this.columnOrders)
   * 선택된 유닛 (this.selectedColumnNames)
   *
   * 이동 전략.
   * 두가지의 전략을 사용한다.
   * @forceMove : 선택된 유닛들이 지정된 위치(LANDING_POINT) 로 이동시킨다.
   * @swapMove : 선택된 유닛들이 한열 앞, 혹은 한열 뒤로 위치를 스왑(swap) 한다.
   *
   * @param moveto: 어디로 이동할것인가?
   */
  private onMove(moveto: Move) {
    switch (moveto) {
      case 'first':
        this.forceMove(0);
        this.$nextTick(() => this.scrollTo(moveto));
        break;
      case 'last':
        this.forceMove(this.columnOrders.length - this.selectedColumnNames.length);
        this.$nextTick(() => this.scrollTo(moveto));
        break;
      case 'prev':
        this.swapMove(-1);
        break;
      case 'next':
        this.swapMove(1);
        break;
    }
  }
  /**
   * @forceMove : 선택된 유닛들을 지정된 위치(LANDING_POINT) 로 이동시킨다.
   *
   * @param landingPoint.
   * landingPoint : 0 + n. 이동지점으로 이동
   */
  private forceMove(landingPoint: number): void {
    // 이동 유닛들을 모든 유닛들 중 선발한다.
    const allUnits = [...this.columnOrders];
    const moveUnits = this.columnOrders.reduce((moveUnits, { columnName }, index) => {
      const isSelected = this.selectedColumnNames.some(v => v === columnName);
      if (isSelected) {
        const [unit] = allUnits.splice(index - moveUnits.length, 1);
        moveUnits.push(unit);
      }
      return moveUnits;
    }, []);
    // 이동 유닛 들을 랜딩포인트에 이동 시킨다.
    allUnits.splice(landingPoint, 0, ...moveUnits);
    this.columnOrders = EditGridLayout.updateDisplayOrder(allUnits);
  }
  /**
   * @swapMove : 선택된 유닛들이 landingPoint 에 따라 앞으로 한칸, 뒤로 한칸 이동한다.
   *
   * @param landingPoint.
   * landingPoint === -1 : 뒤로 한칸씩 이동
   * landingPoint === 1 : 앞으로 한칸씩 이동
   */
  private swapMove(landingPoint: -1 | 1): void {
    const swap = (scope, prev, next): void => {
      [scope[prev], scope[next]] = [scope[next], scope[prev]];
    };
    const allUnits = [...this.columnOrders];
    // Array.reduce 를 사용하면서 accumulator 를 안쓴다는거 자체가 조금 이상하긴한데 reduceRight 를 쓰고싶어서..
    // forEachRight 같은게 있다면 그걸 쓸텐데
    const reducer = (unused, { columnName }: ColumnOrder, index) => {
      const isSelected = this.selectedColumnNames.some(v => v === columnName);
      if (!isSelected) return;
      const maxLength = this.columnOrders.length;
      const moveTo = index + landingPoint;
      // 전체 길이를 초과하거나, 전체 길이보다 짦게 이동하는것은 불허
      if (moveTo > -1 && moveTo < maxLength) {
        const moveUnit = allUnits[moveTo];
        const moveUnitIsSwapped = this.selectedColumnNames.some(v => v === moveUnit.columnName);
        if (!moveUnitIsSwapped) swap(allUnits, moveTo, index);
      }
    };
    if (landingPoint === -1) this.columnOrders.reduce(reducer, []);
    if (landingPoint === 1) this.columnOrders.reduceRight(reducer, []); // next 로 이동할 경우 역순으로 움직여라.
    this.columnOrders = EditGridLayout.updateDisplayOrder(allUnits);
  }
  private static updateDisplayOrder(arr: DefaultColumnOrder[]): DefaultColumnOrder[] {
    return arr.map((unit, i) => {
      unit.displayOrder = i;
      return unit;
    });
  }
  private scrollTo(location: 'first' | 'last'): void {
    const isRightSelected = this.selectedColumnNames.some(name =>
      this.columnOrders.some(({ columnName, visible }) => (name === columnName ? visible : false)),
    );
    if (location === 'first') {
      scrollTo(this.scrollBoxLeft, 0);
      if (isRightSelected) scrollTo(this.scrollBoxRight, 0);
    }
    if (location === 'last') {
      scrollTo(this.scrollBoxLeft, this.scrollBoxLeft.scrollHeight);
      if (isRightSelected) scrollTo(this.scrollBoxRight, this.scrollBoxRight.scrollHeight);
    }
  }

  private get isShippingDomain(): boolean {
    return this.$route.path.includes('shipping');
  }
}
