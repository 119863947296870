
































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import { i18n } from '@/main';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import { PutInspections, PutInspectionsRequest } from 'ncp-api-supporter/dist/types/modules/product/product';
import { NCPResponse } from 'ncp-api-supporter';
import NoticeBox from '@/components/common/NoticeBox.vue';

type JudgeMethod = 'salesApproval' | 'approvalRejection';
type ProductNos = { productNos: number[] };

const judgeMethodRadioOptions: RadioBoxGroupOption<JudgeMethod> = {
  name: 'judgeMethods',
  data: [
    {
      id: 'salesApproval',
      name: 'salesApproval',
      value: 'salesApproval',
      display: 'PRODUCT.EDIT.SALES_APPROVAL',
    },
    {
      id: 'approvalRejection',
      name: 'approvalRejection',
      value: 'approvalRejection',
      display: 'PRODUCT.EDIT.APPROVAL_REJECTION',
    },
  ],
};

@Component({
  components: {
    ModalHeader,
    RadioGroup,
    NoticeBox,
  },
})
export default class ChangeProductJudge extends Vue {
  @Prop() private readonly data: ProductNos;
  @Ref() methodSelector: RadioGroup;

  private readonly judgeMethods = judgeMethodRadioOptions;
  private selectMethod: JudgeMethod = 'salesApproval'; //default : 판매승인
  private rejectReasonCheck = false;
  private rejectReason = '';

  get dialogues(): string[] {
    return i18n
      .t('PRODUCT.EDIT.CHANGE_PRODUCT_JUDGE_N', { count: this.productCount })
      .toString()
      .trim()
      .split('\n');
  }

  get productCount(): number {
    return this.data.productNos.length;
  }

  get approvalRejectionMethod(): boolean {
    return this.selectMethod === 'approvalRejection' && this.rejectReasonCheck;
  }
  private validate(): boolean {
    //승인거부 의견등록 체크 & 거절 사유내용X
    if (this.rejectReasonCheck && !this.rejectReason) {
      alert(this.$t('PRODUCT.EDIT.PLEASE_ENTER_YOUR'));
      return false;
    }
    return true;
  }

  private get putInspectionsRequest(): PutInspectionsRequest {
    return {
      data: {
        isConfirm: this.selectMethod === 'salesApproval',
        mallProductNos: this.data.productNos,
        reasonForReject: this.rejectReason,
      },
    };
  }

  private async onSubmit(): Promise<void> {
    if (!this.validate()) return;
    const isApproval = this.selectMethod === 'salesApproval';

    const { data }: NCPResponse<PutInspections> = await this.$api.putInspections(this.putInspectionsRequest);
    const successCount = data.successNos.length; //성공
    const successCountMessage = this.$t('SUCCESS_N', { count: successCount });

    const selectedMethod = isApproval
      ? this.$t('PRODUCT.EDIT.APPROVAL_COMPLETE')
      : this.$t('PRODUCT.EDIT.APPROVAL_REJECTION');

    const successMessage = `${successCountMessage} : ${selectedMethod} ${this.$t('COMPLETE')}`;

    const failCount = data.failures.length; //실패
    const failCountMessage = this.$t('FAIL_N', { count: failCount });
    const failMessages = `${failCountMessage} : ${this.$t('PRODUCT.EDIT.APPROVAL_IMPOSSIBLE_PRODUCT')}`;

    //성공만
    if (!failCount) {
      alert(successMessage);
    } else {
      //실패만
      if (!successCount) alert(failMessages);
      //성공+실패
      else alert(successMessage + '\n' + failMessages);
    }
    this.$emit('confirm');
  }
}
