






















import { Component, Mixins, Watch } from 'vue-property-decorator';
import StatusSummaryBox from '@/components/root/parts/StatusSummaryBox.vue';
import SummaryData, { Summary } from '@/components/root/SummaryMixin';
import { namespace } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
import moment from 'moment';

const mallStore = namespace('mall');

@Component({
  components: { StatusSummaryBox },
})
export default class DelaySummary extends Mixins(SummaryData) {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private summaries: Summary[] = [];

  private get mallNos(): number[] {
    return this.malls.map(({ mallNo }) => mallNo);
  }

  created() {
    if (this.delaySummary) this.mapData();
    this.fetch();
  }

  @Watch('$route.query.latestRefresh')
  @Watch('mallNoQuery')
  async fetch() {
    await this.fetchData();
    this.mapData();
  }

  private async fetchData(): Promise<void> {
    // fetchProductInquiry
    await Promise.all([this.fetchDelaySummary(this.mallNoQuery === 'ALL' ? this.mallNos.join(',') : this.mallNoQuery)]);
  }

  private mapData(): void {
    this.setSummaries();
    this.mapSummaries(this.delaySummary);
  }

  private periodQueryObject = this.getPeriodQueryObject(-3, 'month');

  // 페이지마다 전체몰검색, 단일몰검색 쿼리가 달라 함수로 set
  private setSummaries() {
    this.summaries = [
      {
        key: 'delayPayDone',
        label: 'DELAY_RECEPTION',
        count: null,
        path: {
          name: 'OrderPayDoneIframe',
          query: {
            delay: true,
            searchType: 'ORDER_NO',
            ...this.getMallNoQueryObject('order'),
            ...this.periodQueryObject,
          },
        },
        query: null,
      },
      {
        key: 'delayShipment',
        label: 'DELAY_SHIPMENT',
        count: null,
        path: {
          name: 'OrderProductPrepareIframe',
          query: {
            delay: true,
            searchType: 'ORDER_NO',
            ...this.getMallNoQueryObject('order'),
            ...this.periodQueryObject,
          },
        },
        query: null,
      },
      {
        key: 'delayDeliveryPending',
        label: 'DELAY_DELIVERY_PENDING',
        count: null,
        path: {
          name: 'OrderHoldDeliveryIframe',
          query: {
            delay: true,
            holdDelivery: true,
            includeReservation: false,
            searchType: 'ORDER_NO',
            ...this.getMallNoQueryObject('order'),
            ...this.periodQueryObject,
            endYmd: moment()
              .subtract(3, 'day')
              .format('YYYY-MM-DD'),
          },
        },
        query: null,
      },
      {
        key: 'delayCancellation',
        label: 'DELAY_CANCELLATION',
        count: null,
        path: {
          name: 'CancelIframe',
          query: {
            delay: true,
            ...this.getMallNoQueryObject('claim'),
            ...this.periodQueryObject,
          },
        },
        query: null,
      },
      {
        key: 'delayExchange',
        label: 'DELAY_EXCHANGE',
        count: null,
        path: {
          name: 'ExchangeIframe',
          query: {
            delay: true,
            ...this.getMallNoQueryObject('claim'),
            ...this.periodQueryObject,
          },
        },
      },
      {
        key: 'delayReturn',
        label: 'DELAY_RETURN',
        count: null,
        path: {
          name: 'ReturnIframe',
          query: {
            delay: true,
            ...this.getMallNoQueryObject('claim'),
            ...this.periodQueryObject,
          },
        },
      },
      {
        key: 'delayResponse',
        label: 'DELAY_RESPONSE',
        count: null,
        path: {
          name: 'Questions',
          query: {
            isOfficeHour: true,
            replyStatusType: 'READY',
            ...this.periodQueryObject,
          },
        },
      },
    ];
  }

  private getMallNoQueryObject(pageType: 'order' | 'claim'): { mallNos: number | '' } | { mallNo: number | '' } {
    const isOrderPage = pageType === 'order';
    const value: number | '' = this.mallNoQuery === 'ALL' ? '' : this.mallNoQuery;

    return isOrderPage ? { mallNos: value } : { mallNo: value };
  }

  private mapSummaries<DataType = object>(data: DataType): void {
    Object.entries(data).forEach(([k, v]) => {
      const matched = this.summaries.find(({ key }) => key === k);
      if (matched) matched.count = v;
    });
    this.summaries = this.summaries.map(summary => {
      // 페이지마다 전체몰검색, 단일몰검색 쿼리가 달라 함수로 null로 두고 setSummary 함수에서 문자열로 몰 쿼리 할당함
      summary.query = this.mallNoQuery === 'ALL' ? null : { mallNos: this.mallNoQuery.toString() };
      return summary;
    });
  }
}
