











import { Vue, Component } from 'vue-property-decorator';
import { focusTabName, orderListTypes } from '@/const/order.ts';
import SearchForm from '@/components/shipping/order/SearchForm.vue';

@Component({
  components: {
    SearchForm,
  },
})
export default class OrderBuyConfirm extends Vue {
  private orderListTypes = orderListTypes.BUY_CONFIRM;
  private orderSearchTypes = focusTabName.DELIVERY_TAB;
}
