




import { Vue, Component } from 'vue-property-decorator';
import AppServiceBanner from '@/components/app/basic/AppServiceBanner.vue';

@Component({
  components: { AppServiceBanner },
})
export default class AdditionMain extends Vue {}
