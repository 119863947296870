export default Object.freeze({
  CATEGORY_ICON: '카테고리 아이콘',
  CATEGORY_NO: '카테고리 번호',
  CATEGORY_NAME: '카테고리명',
  DISPLAYORDER: '카테고리 노출순서',
  ABLE: '카테고리 노출여부',
  CONTENT: '카테고리 이미지',
  FULLNAME: '전체 카테고리명',
  MALL: '몰 번호',

  INPUT_NAME: '카테고리 저장시 번호가 할당 됩니다.',
  INPUT_NO: '카테고리 저장시 번호가 할당 됩니다.',
  INPUT_URL: '카테고리 저장시 URL이 변경 됩니다.',

  TITLE: '전시카테고리 관리',
  MALLNAME1: '핑다몰',
  MALLNAME2: '카테고리 관리',
  EXALDOWN: '엑셀 다운로드',
  ADD_BTN: '카테고리 추가',
  REMOVE_BTN: '삭제',
  DEFAULT_TREE_TEXT: '카테고리를 먼저 생성해주세요',
  CONFIG_INFO: '좌측에서 카테고리를 생성하시면, 카테고리 별 상세 설정이 가능합니다.',
  TAB_MENU1: '전시카테고리 설정',
  TAB_MENU2: '전시카테고리 상품관리',
  DISPLAY_CATEGORYNO: '카테고리 번호',
  DISPLAY_CATEGORYURL: '카테고리',
  DISPLAY_CATEGORYNAME: '카테고리명',
  DISPLAY_YN: '전시여부',
  CATEGORY_ICONWRAP: '카테고리 아이콘',
  IMAGE_TEXT: '이미지',
  IMAGE_INFO: '권장',
  INPUTBOX_WRAP: '상단 이미지',
  TOPIMAGEUSEYN1: '등록',
  TOPIMAGEUSEYN2: '등록 안함',
  CONFIG_INFO2: '카테고리 번호, URL은 최종 저장 후에 생성됩니다.',
  SAVEBTN: '변경사항 저장',
  DESCRIPTION1: '해당 전시카테고리에 상품을 등록(추가)/삭제하실 수 있습니다.',
  DESCRIPTION2:
    '상품조회 삭제 및 상품조회 등록 시에는 기존에 상품 삭제/등록 중인 내용을 반드시 저장하신 후에 사용하시기 바랍니다.',
  DESCRIPTION3:
    '상품 정보에 전시카테고리 변경은 상품 심사 항목 입니다. 삭제/등록 후 상품 심사 승인 후에 정상 노출됩니다.',
  DESCRIPTION4: '상품 등록 시 상품 심사 승인 전까지는 현재 전시카테고리에 상품이 조회되지 않습니다.',
  SEARCH_PRODUCT_DELETE: '상품조회 삭제',
  REGISTER: '상품등록',
  IPTITLE1: '상품조회 등록',
  IPTITLE1_BTN: '상품조회',
  IPTITLE2: '상품번호 직접등록',
  IPTITLE2_PLACTHOLDER: '상품번호로 복수 상품 등록이 가능합니다. (Enter 또는 ","로 구분)',
  IPTITLE2_BTN: '상품추가',
  IPTITLE3: '상품번호 일괄등록',
  TABLE_TIT: '등록 상품 총',
  NUM: '개',
  SELECTED_REMOVE: '선택상품 삭제',
  PAGESIZE: '개 보기',
  SAVE: '저장',

  CONFIRM_DELETE_GROUP_TEMPLATE: '해당 폴더에는 템플릿이 포함되어 있습니다. 삭제하시겠습니까?',
  CONFIRM_DELETE_GROUP: '폴더를 삭제하시겠습니까?',
  CONFIRM_DELETE_TEMPLATE: '템플릿을 삭제하시겠습니까?',
  TABLE_HEADER_ONE: '쇼핑몰',
  TABLE_HEADER_TWO: '상품번호',
  TABLE_HEADER_THREE: '상품관리코드',
  TABLE_HEADER_FOUR: '상품명',
  TABLE_HEADER_FIVE: '브랜드',
  TABLE_HEADER_SIX: '파트너사',
  TABLE_HEADER_SEVEN: '승인상태',
  TABLE_HEADER_EIGHT: '판매상태',
  TABLE_HEADER_EIGHT2: '판매설정상태',
  TABLE_HEADER_NINE: '삭제',
});
