export default Object.freeze({
  ORDER_DELETE_TITLE: '주문삭제',

  CANCEL_AMOUNT_DETAIL: '취소상품 금액상세',
  RETURN_AMOUNT_DETAIL: '반품상품 금액상세',

  COLLECT_TITLE: '수거완료 처리',
  MORE_CLAIMED_OPTION: '외 {num}개',

  DEPOSIT_CONFIRM: '입금확인',
  REFUND_PROCESS: '환불처리',

  CANCEL_POPUP_TITLE: '취소처리내역',
  CANCEL_ACCEPT_TITLE: '취소승인처리',
  CANCEL_WITHDRAW: '취소신청 철회',
  CANCEL_ACCEPT: '취소승인',

  EXCHANGE_ACCEPT_POPUP_TITLE: '교환승인처리',
  EXCHANGE_POPUP_TITLE: '교환처리내역',
  EXCHANGE_WITHDRAW: '교환신청 철회',
  EXCHANGE_ACCEPT: '교환승인',

  RETURN_ACCEPT_POPUP_TITLE: '반품승인처리',
  RETURN_POPUP_TITLE: '반품처리내역',
  RETURN_WITHDRAW: '반품신청 철회',
  RETURN_ACCEPT: '반품승인',

  PREPAID_DELIVERY_GUIDE: '착불 배송비 가이드',

  BENEFIT_RECALCULATE_TITLE: '지급(예정)혜택재계산',
  BENEFIT_ACCUMULATION_TITLE: '지급(예정)적립금재계산',

  RETURN_DELIVERY_TITLE: '반품상품 배송비상세 및 조정',

  DELIVERY_RECALCULATE_TITLE: '할인/배송비재계산',

  AMOUNT_DETAIL_TITLE: '교환상품 차액상세',

  TOTAL_REFUND_SUM_A: '교환금액합계 ⓐ',
  TOTAL_DELIVERY_AMOUNT_B: '배송비합계 ⓑ',
  ADJUST_REFUND_CLAIM_AMOUNT_B: '환불 금액조정 ⓓ',

  EXCHANGE_PROCESS_AMOUNT_A_PLUS_B: '교환처리금액 ⓐ + ⓑ',
  EXCHANGE_PROCESS_AMOUNT_A_PLUS_B_PLUS_C: '교환처리금액 ⓐ + ⓑ + ⓒ',

  REFUND_AMOUNT_A_PLUS_B: '환불금액 ⓐ+ⓑ',
  REFUND_AMOUNT_EXPECTATION_A_PLUS_B: '환불예상금액 ⓐ+ⓑ',
  REFUND_AMOUNT_A_MINUS_B_PLUS_C: '환불금액 ⓐ-ⓑ+ⓒ',
  REFUND_AMOUNT_EXPECTATION_A_MINUS_B_PLUS_C: '환불예상금액 ⓐ-ⓑ+ⓒ',
  REFUND_AMOUNT_EXPECTATION_A_MINUS_B_PLUS_C_MINUS_D: '환불예상금액 ⓐ-ⓑ+ⓒ-ⓓ',

  TOTAL_RECALCULATE_AMOUNT_ONE_PLUS_TWO_THAN_B: '재계산합계 ① + ② = ⓑ',
  TOTAL_RECALCULATE_AMOUNT_ONE_PLUS_TWO_THAN_C: '재계산합계 ① + ② = ⓒ',

  DISCOUNT_AMOUNT_CHANGED_ONE: '할인금액변경 ①',
  DELIVERY_AMOUNT_CHANGED_TWO: '배송비변경 ②',

  EXCHANGE_NAVER_PAY_WITHRAW_HOLD: '네이버페이 교환보류 처리',
  RETURN_NAVER_PAY_WITHRAW_HOLD: '네이버페이 반품보류 처리',

  NAVER_PAY_WITH_HOLD_REASON_GUIDE: '보류 사유를 선택 후, 상세사유 입력 시 보류처리가 가능합니다.',
  NAVER_PAY_WITH_HOLD_ADJUSTMENT_GUIDE: '보류 조정 요청금액을 입력한 경우, 입력한 금액으로 조정 요청이 됩니다.',
  NAVER_PAY_WITH_HOLD_ADJUSTMENT_REQUIRE_GUIDE:
    '보류 사유 중 조정 요청금액을 필수로 입력해야 하는 경우, 금액을 입력해야 보류처리가 가능합니다.',
  NAVER_PAY_WITH_HOLD_RELEASE: '보류 처리 후, 보류해제를 해야 이후 주문상태로 변경이 가능합니다.',

  NAVER_PAY_EXCHANGE_WITH_HOLD_REASON: '교환보류 사유',
  NAVER_PAY_RETURN_WITH_HOLD_REASON: '반품보류 사유',

  NAVER_PAY_EXCHANGE_WITH_HOLD_REASON_DETAIL: '교환보류 사유',
  NAVER_PAY_RETURN_WITH_HOLD_REASON_DETAIL: '반품보류 사유',

  NAVER_PAY_EXCHANGE_WITH_HOLD_ADJUST_AMOUNT: '교환보류 조정 요청 금액',
  NAVER_PAY_RETURN_WITH_HOLD_ADJUST_AMOUNT: '반품보류 조정 요청 금액',

  WITH_HOLD_APPROVE: '보류 처리',
});
