import 'slick-carousel'; // 💩
import $ from 'jquery';

interface InitSlider {
  dots?: boolean;
  slideElement: HTMLElement;
  prevArrow: HTMLAnchorElement | HTMLButtonElement;
  nextArrow: HTMLAnchorElement | HTMLButtonElement;
  autoplay?: boolean;
  autoplaySpeed?: number;
  infinite?: boolean;
}

export class PromotionSlider {
  private instance!: JQuery<HTMLElement>;
  private stoped = false;

  constructor(initElements: InitSlider) {
    this.init(initElements);
  }

  private init({ slideElement, prevArrow, nextArrow, dots = true, autoplay = true, autoplaySpeed = 3000, infinite = true }: InitSlider) {
    this.instance = $(slideElement).slick({
      infinite,
      draggable: false,
      autoplay,
      autoplaySpeed,
      speed: 1000,
      arrows: true,
      dots,
      prevArrow,
      nextArrow,
    });
  }

  public stop() {
    this.instance.slick('slickPause');
    this.stoped = true;
  }

  public play() {
    this.instance.slick('slickPlay');
    this.stoped = false;
  }

  public get isStop() {
    return this.stoped;
  }
}
