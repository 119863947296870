



















































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  getToolTipMessageList,
  normalOptionTableHeaders,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import CombinationOptionTableMixin from '@/views/popups/product/productAdd/ProductOption/CombinationOption/Mixins/CombinationOptionTableMixin.vue';
import { unescapeHtml } from '@/utils/common';

@Component({
  components: { TextInput, SelectBox, ToolTip },
})
export default class NormalOptionTable extends Mixins(CombinationOptionTableMixin) {
  @Prop({ required: false, default: false }) private readonly isReadonly;
  @Prop({ required: true }) private readonly isFinishedReservationSales;
  private tableHeaders: string[] = normalOptionTableHeaders;
  private stockStatusToolTipMessage = getToolTipMessageList(3, 'PRODUCT.OPTION.NORMAL_OPTION_STOCK_STATUS_TOOL_TIP');
  private unescapeHtml = unescapeHtml;
}
