export default Object.freeze({
  INSTALL: '설치하기',
  TIT_APP_DETAIL: '앱 상세보기',
  INSTALLED_APP: '설치한 앱',
  TAB_TIT_INSTALLED: '설치',
  TAB_TIT_EXPIRATION: '만료',
  TAB_TIT_DELETED: '삭제',

  SEARCH_RESULT_LIST: '검색 결과 목록',
  NO_SEARCH_RESULT: '앱 내역이 없습니다.',
  SORT_TYPE_REGISTER: '최신순',
  SORT_TYPE_NAME: '가나다순',

  CONFIRM_MSG_FOR_APP_DELETE: '앱을 삭제하시겠습니까? 확인 시 바로 삭제됩니다.',
  ALERT_MSG_FOR_REINSTALL_MY_APP:
    '내 쇼핑몰 전용 앱 설치는 워크스페이스 > 셀러어드민 > 설치 > 앱 메뉴에서 하실 수 있습니다',

  READ: '읽기',
  READ_WRITE: '읽기 + 쓰기',

  READ_DESCRIPTION: '조회',
  READ_WRITE_DESCRIPTION: '조회/수정',

  FREE: '무료',
  IN_APP_PURCHASE: '인앱 결제',

  INSTALLED_DATE_TIME: '설치일',
  EXPIRED_DATE_TIME: '만료 예정일',

  USE: '사용',
  AUTHORITY: '권한',
  DESCRIPTION: '설명',

  AUTHORITY_DESCRIPTION: '{domain} 관련 정보를 {authority}할 수 있습니다.',

  ADMIN_DESCRIPTION: '기본 정보 관리(admin)',
  DELIVERY_DESCRIPTION: '배송(delivery)',
  DISPLAY_DESCRIPTION: '상품 전시(display)',
  MANAGE_DESCRIPTION: '쇼핑몰 운영(manage)',
  MEMBER_DESCRIPTION: '쇼핑몰 회원(member)',
  ORDER_DESCRIPTION: '주문(order)',
  ORDER_FRIENDS_DESCRIPTION: '주문과 관련된 통계, 정산, CS 등',
  PRODUCT_DESCRIPTION: '상품(product)',
  PROMOTION_DESCRIPTION: '프로모션 혜택(promotion)',
  CLAIM_DESCRIPTION: '클레임(claim)',
  WORKSPACE_DESCRIPTION: 'Workspace',
  APP_BANNER_TIT: '앱스토어(APP STORE)가 오픈했습니다',
  APP_BANNER_DESC1: '더 무한한 샵바이의 기능 확장!',
  APP_BANNER_DESC2: '앱스토어 앱을 내 쇼핑몰에 설치해보세요',
  APP_BANNER_LINK_SUB_TEXT_APP_STORE: '앱으로 쇼핑몰 기능 확장하기',
  APP_BANNER_LINK_TEXT_APP_STORE: 'NEW 앱스토어 바로가기',
  APP_BANNER_LINK_SUB_TEXT_GUIDE: '앱 개발 방법이 궁금하다면?',
  APP_BANNER_LINK_TEXT_GUIDE: '앱 개발 가이드 바로가기',
});
