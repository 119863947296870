









import { Component, Vue, Prop } from 'vue-property-decorator';

type StatementContentType = 'sm';

@Component({})
export default class AppStatement extends Vue {
  @Prop({ required: true })
  private readonly title!: string;
  @Prop({ default: null })
  private readonly type?: StatementContentType | null;

  get contentCurrentClassName() {
    return this.type ? `statement__content--${this.type}` : '';
  }
}
