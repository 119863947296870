





























































































































import { Component, Ref } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  convertToI18n,
  getOptions,
  isEmptyValue,
  toolTipMessage,
  SEPARATOR,
  defaultOptionInfo,
  STOCK_STATUS_TYPE,
  optionTypeRadioOption,
  getSetOptions,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import CombinationOptionForm from '@/views/popups/product/productAdd/ProductOption/CombinationOption/CombinationOptionForm.vue';
import CombinationOptionList from '@/views/popups/product/productAdd/ProductOption/CombinationOption/CombinationOptionList.vue';
import CombinationOptionExcel from '@/views/popups/product/productAdd/ProductOption/CombinationOption/CombinationOptionExcel.vue';
import TextOption from '@/views/popups/product/productAdd/ProductOption/TextOption/TextOption.vue';
import { CombinationOptionType, OptionSelectBoxType, ProductOptionType } from '@/types';
import { PopupClose, PopupData, throwWindowPopup } from '@/helpers/popup';

import {
  CustomerDemands,
  GetProductsMallProductNoOptionsRequest,
  NCPResponse,
  OptionSelectType,
  PatchProductsRequest,
} from 'ncp-api-supporter';

import { MallOption } from 'ncp-api-supporter/dist/types/modules/product/option';
import { GetProductsProductNoCustomerDemandsRequest } from 'ncp-api-supporter/dist/types';
import { CustomerDemand } from 'ncp-api-supporter/dist/types/modules/product/product';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { CommissionType } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { unescapeHtml } from '@/utils/common';

export const PRODUCT_OPTION_TYPE = {
  REGISTRATION: 'REGISTRATION',
  MODIFICATION: 'MODIFICATION',
  RESET: 'RESET',
} as const;

export const OptionRegistrationType = {
  SET: 'SET',
  NORMAL: 'NORMAL',
} as const;

export const SaleSettingStatusTypes = {
  AVAILABLE_FOR_SALE: 'AVAILABLE_FOR_SALE',
  STOP_SELLING: 'STOP_SELLING',
  PROHIBITION_SALE: 'PROHIBITION_SALE',
} as const;

@Component({
  components: {
    ToolTip,
    SelectBox,
    CombinationOptionForm,
    CombinationOptionList,
    CombinationOptionExcel,
    TextOption,
    RadioGroup,
  },
})
export default class ProductOption extends WindowPopupMainVue {
  private optionsData: OptionSelectBoxType[] = [];
  private combinationOptionCnt = 1;
  private textOptionCnt = 0;
  private toolTipMessage = '';
  private hasStockAndManagementCd = false;
  private textOptionList: CustomerDemands[] = [];
  private combinationOptionList: CombinationOptionType<string[]>[] = [];
  private optionSelectType: OptionSelectType = 'MULTI';
  private optionTypeRadioOption = optionTypeRadioOption;
  private isMapping = false;

  // private saleSettingStatusType: keyof typeof SaleSettingStatusTypes = SaleSettingStatusTypes.AVAILABLE_FOR_SALE;
  private isSelectable = true;
  private deletable = true;

  @Ref() private readonly combinationOptionListRef!: CombinationOptionList;
  @Ref() private readonly combinationOptionFormRef!: CombinationOptionForm;

  created() {
    this.init();
    window.addEventListener('message', this.receiveMessage, false);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  }

  private receiveMessage(event: MessageEvent) {
    const { origin, data } = event;
    if (origin !== window.location.origin || !data.info) return;
    const { mallNo, deliveryTemplateNo, immediateDiscountPrice, commissionType, useReservation } = data.info;
    if (this.data.mallNo !== mallNo) {
      this.onNegativeClick();
    }
    this.data.immediateDiscountPrice = immediateDiscountPrice;
    this.data.commissionType = commissionType;
    this.data.deliveryTemplateNo = deliveryTemplateNo;
    this.data.useReservation = useReservation;
  }

  private init() {
    this.toolTipMessage = toolTipMessage();
    this.isMapping = this.data.savedOption.optionType === 'MAPPING';
    this.optionsData = this.isMapping ? getSetOptions() : getOptions();
  }

  mounted() {
    if (this.data.type !== PRODUCT_OPTION_TYPE.MODIFICATION) return;
    this.initOptions();
  }

  private initOptions(): void {
    const { customerDemands, options, optionSelectType } = this.data.savedOption;
    this.optionSelectType = optionSelectType;

    if (options.length > 0) {
      this.setCombinationOptionList(options);
      this.checkAdditionalSettings(options);
      this.convertToOptionTemplate();
    } else {
      this.$nextTick(() => (this.combinationOptionCnt = 0));
    }

    if (!customerDemands || customerDemands.length === 0) return;
    this.textOptionList = customerDemands;
    this.textOptionCnt = this.textOptionList.length;
  }

  private checkAdditionalSettings(options: MallOption[] | CombinationOptionType<string[]>[]): void {
    if (this.isMapping) return;
    options.forEach(option => {
      if (option?.stockCnt || option?.optionManagementCd) {
        this.hasStockAndManagementCd = true;
        return;
      }
    });
  }

  //승인완료 이후

  private get isMaster(): boolean {
    return this.data.isMaster;
  }

  private get isSlave(): boolean {
    return this.data.isSynced === true;
  }

  //TODO 이건 승인완료이전 조건
  // private isDisabled(useMasterYn = true): boolean {
  // const isProhibitionSale = this.saleSettingStatusType === SaleSettingStatusTypes.PROHIBITION_SALE;
  // return useMasterYn ? this.data.masterYn === 'N' || isProhibitionSale : isProhibitionSale;
  // }

  private updateOptionData(optionList: CombinationOptionType<string[]>[], imageBtnIndexes: number[]) {
    //여기서 옵션 이름 빈값일때 undefined입력
    optionList = this.setEmptyOptionName(optionList);
    this.combinationOptionListRef.updateOptionData(optionList, imageBtnIndexes);
    this.checkAdditionalSettings(optionList);
    this.convertToOptionTemplate();
  }

  private setEmptyOptionName(optionList: CombinationOptionType<string[]>[]): CombinationOptionType<string[]>[] {
    optionList.forEach(({ optionName }, index) => {
      const optionNameList = optionName.split(SEPARATOR);
      let isChangedName = false;
      optionNameList.forEach((name, nameIndex) => {
        if (name === '__EMPTY') {
          this.$set(optionNameList, nameIndex, 'undefined');
          isChangedName = true;
        }
      });
      if (isChangedName) {
        const name = optionNameList.join(SEPARATOR);
        this.$set(optionList[index], 'optionName', name);
      }
    });
    return optionList;
  }

  private showCombinationOptionList(combinationOptionList: ProductOptionType[], isReset = false): void {
    if (this.combinationOptionListRef.combinationOptionListLength > 0) {
      const confirmDeletion = confirm(convertToI18n('SURE_REGISTER_DELETE').toString());
      if (!confirmDeletion) return;
    }
    if (isReset) {
      this.combinationOptionListRef.resetCombinationOptions();
    } else {
      this.combinationOptionListRef.combineOptions(combinationOptionList);
    }
    this.combinationOptionListRef.resetSelectedOptions();
  }

  private openOtherOptionsLoadPopup(): void {
    throwWindowPopup(
      'OtherProductOption',
      { mallNo: this.data.mallNo, optionCnt: this.combinationOptionListRef.combinationOptionListLength },
      'xlg',
      data => this.otherOptionsLoadPopupCallBack(data),
    );
  }
  private otherOptionsLoadPopupCallBack({ state, data: { productNo } }: PopupData): Promise<void> {
    if (state === PopupClose.CLOSE || productNo === null) return;
    this.searchOptions(productNo);
  }

  private async searchOptions(productNo: number): Promise<void> {
    if (!this.isMapping) {
      await this.searchTextOption(productNo);
    }
    await this.searchCombinationOption(productNo);
    this.convertToOptionTemplate();
  }

  private async searchTextOption(productNo: number): Promise<void> {
    const params: GetProductsProductNoCustomerDemandsRequest['params'] = { productNo: productNo.toString() };
    const { data }: NCPResponse<CustomerDemand[]> = await this.$api.getProductsProductNoCustomerDemands({ params });
    this.textOptionList = data;
    this.textOptionCnt = this.textOptionList.length;
  }

  private async searchCombinationOption(productNo: number): Promise<void> {
    const params: GetProductsMallProductNoOptionsRequest['params'] = { mallProductNo: productNo.toString() };
    const { data }: NCPResponse<MallOption[]> = await this.$api.getProductsMallProductNoOptions({ params });
    this.setCombinationOptionList(data);
    this.setDeletable(data);
  }

  private setDeletable(options: MallOption[]) {
    this.deletable = options.every(({ deletable }) => deletable === true);
  }

  private setCombinationOptionList(options: MallOption[]): void {
    this.combinationOptionList = [];
    let prevOptionValue = '';
    let isSetMappingOption = false;
    const imageBtnIndexes: number[] = [];
    options.forEach((option, index) => {
      const saleStatusType = this.getSaleStatusType(option.stockCnt, option.forcedSoldOut);
      const combinationOption = this.setDefaultOption(option);

      this.$set(this.combinationOptionList, index, combinationOption);
      this.$set(
        this.combinationOptionList[index],
        'optionValue',
        combinationOption.optionValue.split(SEPARATOR).map(value => unescapeHtml(value)),
      );
      this.$set(this.combinationOptionList[index], 'saleStatusType', saleStatusType);
      if (this.isMapping) {
        if (this.data.mallProductNo) {
          isSetMappingOption = true;
        }
        this.$set(this.combinationOptionList[index], 'mappings', combinationOption.mappings);
      }
      const firstOptionValue = option.optionValue[0];
      if (firstOptionValue === prevOptionValue) return;
      prevOptionValue = firstOptionValue;
      imageBtnIndexes.push(index);
    });
    this.checkAdditionalSettings(options);
    this.combinationOptionListRef.updateOptionData(this.combinationOptionList, imageBtnIndexes, isSetMappingOption);
  }

  //옵션 팝업 오픈시 데이터 없으면 디폴트로 채우기
  //TODO 세트옵션도 전체적으로 다시 확인
  private setDefaultOption(option) {
    if (!option.mallOptionNo) {
      const defaultOptionValue = {
        optionImages: [],
        mallOptionNo: 0,
        reservationStockCnt: 0,
        stockNo: 0,
        optionManagementCd: '',
        purchasePrice: 0,
      };
      return { ...option, ...defaultOptionValue };
    }
    return option;
  }

  private getSaleStatusType(stockCnt: number, forcedSoldOut: boolean): keyof typeof STOCK_STATUS_TYPE {
    if (stockCnt === 0) {
      return STOCK_STATUS_TYPE.SOLD_OUT;
    } else if (forcedSoldOut === true) {
      return STOCK_STATUS_TYPE.OUT_OF_STOCK;
    } else {
      return STOCK_STATUS_TYPE.AVAILABLE;
    }
  }

  private validateOptions(): boolean {
    const isEmptyOptionList =
      this.textOptionList.length === 0 && this.combinationOptionListRef.combinationOptionListLength === 0;

    if (isEmptyOptionList) {
      alert(convertToI18n('EMPTY_OPTION_MESSAGE'));
      return false;
    }
    if (!this.combinationOptionListRef.validateOptionList()) {
      return false;
    }

    if (!this.isMapping && isEmptyValue(this.textOptionList, 'inputText')) {
      alert(convertToI18n('TEXT_OPTION_VALIDATION_MESSAGE'));
      return false;
    }
    return true;
  }

  private async getOptionData() {
    if (!this.validateOptions()) return;

    this.combinationOptionListRef.validateParentValue();

    const data: PatchProductsRequest['data'] = {
      optionType: this.isMapping ? 'MAPPING' : 'COMBINATION',
      options: this.combinationOptionListRef.getCombinationOptionList(),
      customerDemands: this.textOptionList,
      optionSelectType: this.optionSelectType,
    };

    if (data.options.length === 0) {
      data.optionType = 'DEFAULT';
    }
    this.isMapping && (await this.combinationOptionListRef.validateConfigurationOption());
    return data;
  }

  private saveOptions() {
    this.getOptionData().then(data => this.onPositiveClick({ data }));
  }

  private getDefaultOptionTemplate(combinationOptions: CombinationOptionType<string>[]): ProductOptionType[] {
    return combinationOptions[0].optionName.split(SEPARATOR).map(optionName => ({ optionName, optionInfoList: [] }));
  }

  private convertToOptionTemplate(): void {
    const combinationOptions = this.combinationOptionListRef.getCombinationOptionList();
    const optionTemplate = this.getDefaultOptionTemplate(combinationOptions);
    const tempOptionValues: Set<string>[] = optionTemplate.map(_ => new Set());

    combinationOptions.forEach(({ optionValue }) => {
      const optionValues = optionValue.split(SEPARATOR);
      optionValues.forEach((optionValue, index) => {
        tempOptionValues[index].add(unescapeHtml(optionValue));
      });
    });

    tempOptionValues.forEach((options, index) => {
      options.forEach(optionValue => optionTemplate[index].optionInfoList.push({ optionValue, ...defaultOptionInfo }));
    });
    this.combinationOptionFormRef.setOptionTemplate(optionTemplate, this.deletable);
  }

  private get hasSupplyPrice(): boolean {
    return this.data.commissionType === CommissionType.PURCHASE && this.data.saleMethodType === 'CONSIGNMENT';
  }

  private get optionRegistrationType(): keyof typeof OptionRegistrationType {
    return this.isMapping ? OptionRegistrationType.SET : OptionRegistrationType.NORMAL;
  }

  private isEmptyCombinationOptionList(): boolean {
    return this.combinationOptionListRef.combinationOptionListLength === 0;
  }
}
