




























































































































































































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  Bank,
  GetOrderConfigsOrder,
  GetOrderConfigsPaymentsPaycoRequest,
  NCPResponse,
  PayConfigs,
  PayType,
  PGPayTypes,
  PGSetting,
  PgType,
} from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { PopupClose, throwPopup } from '@/helpers/popup';
import { getDefaultPayTypes, getUsesOrNot, i18nMallCreate, MallType } from '@/const/contents/configuration/mallCreate';
import { GetOrderConfigsPaymentsPgRequest } from 'ncp-api-supporter/dist/types';
@Component({
  components: { TextInput, ToolTip, RadioGroup, SelectBox },
})
export default class PaymentSetting extends Vue {
  @PropSync('mall', { required: true }) private mallSync!: MallType;
  @PropSync('pgPayTypes', { required: true }) private pgPayTypesSync!: PGPayTypes[];
  @Prop({ required: true }) private readonly bankList!: Bank[];
  @Prop({ required: true }) private readonly orderConfig!: GetOrderConfigsOrder['value'];
  @Prop({ required: true }) private readonly isEditPage!: boolean;
  @Prop({ required: true }) private readonly mallNo!: string;
  private isEnablePgType = false;
  private getUsesOrNot = getUsesOrNot;
  private i18nMallCreate = i18nMallCreate;
  private pgTypeConfig = {} as PGSetting;
  private isDisabledEasyPayco = false;
  private isDisabledNaverEasyPay = false;
  private isDisabledNaverPay = false;
  private payTypesModel = getDefaultPayTypes();

  crated() {
    this.isEnablePayco();
    this.isEnableNaverEasyPay();
    this.isEnableNaverPay();
  }

  private async onBankAccountChangeButtonClicked(bankInfo, index) {
    const authResult = await throwPopup({
      name: 'AuthenticateSecurity',
      data: {
        messages: ['MALL.FORM.AUTHENTICATE_SECURITY_NOTICE'],
        authErrorMsg: 'MEMBER.MAIL.NO_AUTH_ALERT',
      },
    });
    if (authResult.state === PopupClose.CONFIRM) {
      const result = await throwPopup({
        name: 'AddBankAccount',
        data: {
          bankList: this.bankList,
          bank: bankInfo,
        },
      });
      if (result.state === PopupClose.CONFIRM) {
        this.$set(this.mallSync.tradeBanks, index, result.data);
      }
    }
  }

  //참조를 통해 값 바인딩
  @Watch('pgPayTypesSync')
  private async bindModel() {
    await Promise.all([
      await this.updateEnabledPgType(),
      await this.isEnablePayco(),
      await this.isEnableNaverEasyPay(),
      await this.isEnableNaverPay(),
    ]);
    for (const payType in this.payTypesModel) {
      const isEasyPayco = payType === 'EASY_PAYCO';
      const isNaverEasyPay = payType === 'NAVER_EASY_PAY';
      const isAccount = payType === 'ACCOUNT';
      const isNaverPay = payType === 'NAVER_PAY';
      this.payTypesModel[payType] = this.getPayTypesModel(
        payType as PayType,
        isEasyPayco,
        isAccount,
        isNaverEasyPay,
        isNaverPay,
      );
    }
  }

  private getPayTypesModel(
    type: PayType,
    isEasyPayco = false,
    isAccount = false,
    isNaverEasyPay = false,
    isNaverPay = false,
  ): PayConfigs {
    const pg = this.getPg(isEasyPayco, isAccount, isNaverEasyPay, isNaverPay);
    const payType = isEasyPayco ? 'PAYCO' : type;
    //api에서 맞는 pg타입 데이터를 찾는다
    const pgPayTypesIndex = this.pgPayTypesSync.findIndex(({ pgType }) => pgType === pg);
    //찾은 데이터에서 한번 더 들어가 맞는 payType을 찾는다
    //하지만 pg가 kcp가 아니라면 payco hub의 데이터는 없기 때문에 빈 객체를 넣어준다
    if (this.pgTypeConfig.pgType !== 'KCP' && type === 'PAYCO') {
      return { payType: 'PAYCO', enable: false, exposure: false };
    }
    const payConfigsIndex = this.pgPayTypesSync[pgPayTypesIndex]?.payConfigs?.findIndex(
      payConfig => payConfig.payType === payType,
    );
    const noMatchedPayType =
      payConfigsIndex === undefined || payConfigsIndex < 0 || pgPayTypesIndex === undefined || pgPayTypesIndex < 0;

    if (noMatchedPayType) {
      return { payType: type, enable: false, exposure: false };
    } else {
      return this.pgPayTypesSync[pgPayTypesIndex].payConfigs[payConfigsIndex];
    }
  }

  private getPg(isEasyPayco: boolean, isAccount: boolean, isNaverEasyPay: boolean, isNaverPay: boolean): PgType {
    if (isEasyPayco) {
      return 'PAYCO';
    } else if (isAccount) {
      return 'NONE';
    } else if (isNaverEasyPay) {
      return 'NAVER_EASY_PAY';
    } else if (isNaverPay) {
      return 'NAVER_PAY';
    } else {
      return this.pgTypeConfig.pgType;
    }
  }

  //연동된 pg가 존재하는지 확인
  //페이코 허브와 간편결제 페이코를 구분하기 위해 pg타입을 받아온다.
  private async updateEnabledPgType(): Promise<void> {
    const request: GetOrderConfigsPaymentsPgRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    try {
      const response: NCPResponse<PGSetting> = await this.$api.getOrderConfigsPaymentsPg(request);
      if (response && response.status === 200) {
        this.isEnablePgType = true;
        this.pgTypeConfig = response.data;
      }
    } catch (e) {
      this.isEnablePgType = false;
    }
  }
  private isDisabled(type: PayType): boolean {
    const isEnablePayType = this.pgTypeConfig.enablePayTypes?.includes(type);
    return !(this.isEnablePgType && isEnablePayType);
  }
  private async onChangePayType(value: boolean, type: PayType) {
    if (value === false) return;
    await this.updateEnabledPgType();
    if (!this.isDisabled(type)) return;
    this.$nextTick(() => this.$set(this.payTypesModel[type], 'exposure', false));
  }
  private get isKcp() {
    return this.isEnablePgType === true && this.pgTypeConfig.pgType === 'KCP';
  }
  //페이코 허브 -> kcp 연동되어 있고 pg 설정에서 결제수단에 사용 체크했을 경우에만 활성화
  //pg타입이 payco이고 payType이 payco이면 간편결제 페이코를 사용
  //pg타입이 kcp이고 payType이 payco이면 페이코 허브를 사용
  private get isDisabledPaycoHub(): boolean {
    const isPayco = this.pgTypeConfig.enablePayTypes?.includes('PAYCO');
    return !(this.isKcp && isPayco);
  }
  private async onChangePaycoHub(value: boolean) {
    if (value === false) return;
    await this.updateEnabledPgType();
    if (!this.isDisabledPaycoHub) return;
    this.$nextTick(() => this.$set(this.payTypesModel.PAYCO, 'exposure', false));
  }
  private async onChangeEasyPay(value: boolean, type: string) {
    if (value === false) return;
    const isEnablePayType = type === 'EASY_PAYCO' ? await this.isEnablePayco() : await this.isEnableNaverEasyPay();
    this.$nextTick(() => this.$set(this.payTypesModel[type], 'exposure', isEnablePayType));
  }

  //간편결제 페이코를 사용하는가 확인
  private async isEnablePayco(): Promise<boolean> {
    const request: GetOrderConfigsPaymentsPaycoRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    try {
      await this.$api.getOrderConfigsPaymentsPayco(request);
      this.isDisabledEasyPayco = false;
      return true;
    } catch (e) {
      this.isDisabledEasyPayco = true;
      return false;
    }
  }

  // 네이버페이 결제형 설정 : 사용여부
  private async isEnableNaverEasyPay(): Promise<boolean> {
    const request = {
      params: {
        mallNo: this.mallNo,
      },
    };

    try {
      const {
        data: { enables },
      } = await this.$api.getOrderConfigsNaverEasyPayV2(request);

      // 설정이 안되어있으면 각각 빈 스트링값으로 온다
      this.isDisabledNaverEasyPay = !enables;
      return enables;
    } catch (e) {
      this.isDisabledNaverEasyPay = true;
      return false;
    }
  }

  // 네이버페이 주문형 설정 : 사용여부
  private async isEnableNaverPay(): Promise<boolean> {
    const request = {
      params: {
        mallNo: this.mallNo,
      },
    };

    try {
      const {
        data: { enables },
      } = await this.$api.getOrderConfigsNaverpay(request);

      this.isDisabledNaverPay = !enables;
      return enables;
    } catch (e) {
      this.isDisabledNaverPay = true;
      return false;
    }
  }

  //TODO 이부분은 최종 확인이 필요
  public async validatePaymentSetting(): Promise<boolean> {
    await this.updateEnabledPgType();
    //연동된 결제정보 없는데 노출을 사용함으로 표시했을 경우
    //일반,에스크로
    for (const payType in this.payTypesModel) {
      if (payType === 'EASY_PAYCO' || payType === 'NAVER_EASY_PAY' || payType === 'ACCOUNT' || payType === 'NAVER_PAY')
        continue;
      const unSettablePayTypeCondition =
        this.isDisabled(payType as PayType) === true && this.payTypesModel[payType].exposure === true;
      if (unSettablePayTypeCondition) {
        this.getPayCategory(payType as PayType);
        return false;
      }
    }
    //간편결제
    const paycoUnSettableCondition = !(await this.isEnablePayco()) && this.payTypesModel.EASY_PAYCO.exposure === true;
    if (paycoUnSettableCondition) {
      this.alertPayType('EASY_PAY', 'PAYCO');
      return false;
    }

    // 네이버페이 결제형 용도의 유효성 검사긴 하지만,
    const naverUnSettableCondition =
      !(await this.isEnableNaverEasyPay()) && this.payTypesModel.NAVER_EASY_PAY.exposure === true;
    if (naverUnSettableCondition) {
      alert(i18nMallCreate('NAVER_PAY_ALERT'));
      this.payTypesModel.NAVER_EASY_PAY.exposure = false; // `네이버페이 설정에서 사용 안 함으로 변경시, 노출설정은 다시 false로 set
      return false;
    }

    // 네이버페이 사용했지만 사용안함으로 변경
    const naverpayUnSettableCondition =
      !(await this.isEnableNaverPay()) && this.payTypesModel.NAVER_PAY.exposure === true;
    if (naverpayUnSettableCondition) {
      alert(i18nMallCreate('NAVER_PAY_ALERT'));
      this.payTypesModel.NAVER_PAY.exposure = false; // `네이버페이설정에서 네이버페이 사용했지만 사용안함'으로 변경시, 노출설정은 다시 false로 set
      return false;
    }
    return true;
  }
  private getPayCategory(payType: PayType) {
    const escrowTypes = ['ESCROW_REALTIME_ACCOUNT_TRANSFER', 'ESCROW_VIRTUAL_ACCOUNT'];
    const payCategory = escrowTypes.includes(payType) ? 'ESCROW' : 'GENERAL_PAY';
    this.alertPayType(payCategory, payType);
  }
  private alertPayType(payCategory: string, payType: PayType) {
    alert(this.$t('MALL.FORM.PAY_TYPE_ALERT', [i18nMallCreate(payCategory), i18nMallCreate(payType)]));
  }
}
