





















































































































import { Vue, Component, Watch, Ref, Prop } from 'vue-property-decorator';

import MallSelect from '@/components/common/input/MallSelect.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';

import { throwMessagePopup } from '@/helpers/popup';
import { RadioBoxGroupOption, CheckBoxGroupOption, OptionData } from '@/helpers/type';
import {
  CouponListTopDefaultQuery,
  SelectCouponSearchKeywordTypes,
  SelectCouponSearchDateTypes,
  RadioCouponSearchCouponTypes,
  RadioCouponSearchIssueTypes,
  CheckboxCouponSearchStatusTypes,
} from '@/const/contents/promotion';
import { CouponListTopQuery } from '@/types';

@Component({
  components: {
    MallSelect,
    SelectBox,
    SearchButton,
    DateRangePicker,
    RadioGroup,
    CheckboxGroup,
  },
})
export default class CouponList extends Vue {
  @Prop({ required: false, default: false })
  private readonly popupType: boolean;
  @Prop({ required: false, default: '' })
  private readonly mallNo: string;
  @Prop({ required: false, default: '' })
  private readonly popType: string;

  // 검색어
  private searchKeywordTypes: OptionData<string>[] = SelectCouponSearchKeywordTypes;
  // 기간
  private searchDateTypes: OptionData<string>[] = SelectCouponSearchDateTypes;
  // 혜택구분
  private searchCouponTypes: RadioBoxGroupOption<string> = RadioCouponSearchCouponTypes;
  // 발급유형
  private searchIssueTypes: RadioBoxGroupOption<string> = RadioCouponSearchIssueTypes;
  // 발급상태
  private searchStatusTypes: CheckBoxGroupOption<string> = CheckboxCouponSearchStatusTypes;
  @Ref() private readonly statusTypeRef: CheckboxGroup;

  private searchQuery: CouponListTopQuery = null;

  private checkValidation() {
    if (this.searchQuery.statusTypes === '') {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_STATUS_TYPE_IS_EMPTY')).then(() => {
        this.statusTypeRef.focus();
      });
      return false;
    }
    return true;
  }
  private reset() {
    this.searchQuery = CouponListTopDefaultQuery(this.popupType, this.mallNo, this.popType);
  }

  @Watch('$route.query')
  private setQuery() {
    this.searchQuery = CouponListTopDefaultQuery(this.popupType, this.mallNo, this.popType);
    const urlQuery = this.$route.query as CouponListTopQuery;
    if (!urlQuery) {
      return;
    }
    if (urlQuery.popupId) {
      this.searchQuery.popupId = urlQuery.popupId;
    }
    if (urlQuery.mallNo) {
      this.searchQuery.mallNo = urlQuery.mallNo;
    }
    if (urlQuery.searchKeywordType) {
      this.searchQuery.searchKeywordType = urlQuery.searchKeywordType;
    }
    if (urlQuery.keyword) {
      this.searchQuery.keyword = urlQuery.keyword;
    }
    if (urlQuery.searchDateType) {
      this.searchQuery.searchDateType = urlQuery.searchDateType;
    }
    if (urlQuery.startYmd) {
      this.searchQuery.startYmd = urlQuery.startYmd;
    }
    if (urlQuery.endYmd) {
      this.searchQuery.endYmd = urlQuery.endYmd;
    }
    if (urlQuery.couponType) {
      this.searchQuery.couponType = urlQuery.couponType;
    }
    if (urlQuery.issueType) {
      this.searchQuery.issueType = urlQuery.issueType;
    }
    if (urlQuery.statusTypes) {
      this.searchQuery.statusTypes = urlQuery.statusTypes;
    }
    if (urlQuery.size) {
      this.searchQuery.size = Number(urlQuery.size);
    }
  }
  private created() {
    this.setQuery();
  }
}
