































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { i18nSecurity } from '@/const/contents/configuration/operateSecurity';
import { Row } from '@/types/tui-grid';

export interface WorkingDetailType extends Row {
  workingTypeDescription: string;
  ip: string;
  adminId: string;
  menu: string;
  menuName?: string;
  accessDateTime: string;
  workingDetail: any;
  workingType: string;
  pageLabel: string;
  menuLabel: string;
  categoryType: string;
  menuUri?: string;
  pageName?: string;
}

@Component
export default class WorkingDetail extends Vue {
  @Prop() private readonly data!: WorkingDetailType;

  private readonly i18nSecurity = i18nSecurity;
  private type = 'search';
  private searchKeyword = '';
  private identifiers = '';

  mounted() {
    this.getPopupType();
    this.getDetail();
  }

  private getPopupType(): void {
    switch (this.data.workingType) {
      case 'SEARCH':
        this.type = 'search';
        break;

      case 'CHANGE':
      case 'DELETE':
      case 'ADD':
        this.type = 'admin';
        break;

      case 'DOWNLOAD':
      case 'UPLOAD':
      case 'CREATE':
        this.type = 'excel';
        break;

      case 'CERTIFICATION':
      case 'CERTIFICATION_SUCCESS':
      case 'LOGIN':
        this.type = 'certification';
        break;

      default:
        throw new Error('Unknown working type');
    }
  }

  private getDetail(): void {
    const { searchKeyword, identifiers, identifier } = this.data.workingDetail;
    if (searchKeyword) {
      this.searchKeyword = `(${searchKeyword})`;
    }

    if (identifiers?.length > 0) {
      this.identifiers = `{${identifiers.join('},{')}}`;
    } else {
      this.identifiers = identifier ?? '-';
    }
  }

  private getConnectionUrl(url: string): string {
    return process.env.VUE_APP_STANDARD_ADMIN_URL + url;
  }
}
