export default Object.freeze({
  OPERATOR_MANAGEMENT: '운영자 관리',
  SEARCH_WORD: '검색어',
  ENTER_SEARCH_TERM: '검색어 입력',
  DELETE_OPERATOR: '운영자 삭제',
  NEW_REGISTRATION: '신규 운영자 계정등록',
  ALL: '전체',
  OPERATOR_NAME: '운영자명',
  ID: '아이디',
  DEPARTMENT: '부서명',
  RANK: '직급',
  TYPE: '유형',
  MASTER: '마스터',
  NORMAL: '일반',
  DEPARTMENT_NAME: '소속부서',
  POSITION: '직책',
  STATE: '상태',
  FINISHED_APPOINTMENT: '등록완료',
  WAIT_FOR_REGISTRATION: '등록대기',
  UNDER_REVIEW: '검토중',
  LOGIN_LOCK: '로그인 잠금',
  DELETE: '삭제',
  DORMANCY: '휴면',
  STATE_ANOMALIES: '상태 이상',
  'E-MAIL': '이메일',
  PHONE_NUMBER: '전화번호',
  MOBILE_PHONE_NUMBER: '휴대폰번호',
  EXTERNAL_CONNECTION: '외부접속',
  DATE_OF_REGISTRATION: '계정등록일시',
  PASSWORD_CHANGE_DATE: '비밀번호변경일시',
  LAST_LOGIN_DATE: '최종로그인 일시',
  SELECT: '선택',
  CONFIRM_MESSAGE: '삭제한 계정 정보는 복구되지 않습니다.\r\n이 계정을 삭제하시겠습니까?',
  CONFIRM_SELFMESSAGE:
    '이 계정은 현재 접속중인 운영자님의 계정입니다.\r\n삭제한 계정 정보는 복구되지 않으며 삭제 즉시 로그아웃 처리 됩니다.\r\n\r\n정말로 삭제하시겠습니까?',
  ALERT_COMPELET: '운영자가 삭제되었습니다.',
  ALERT_SELECT_OPERATOR: '운영자를 선택해주세요.',
  OPERATOR_ACCOUNT_MANAGEMENT: '운영자 계정관리',
  DEPARTMENTPOSITION_MANAGEMENT: '부서/직책관리',
  OPERATOR_LOG_SEARCH: '운영자 로그조회',
  ALERT_SEARCH: '검색어를 입력하세요.',
});
