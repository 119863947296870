import { Component, Vue } from 'vue-property-decorator';
import {
  DeleteBoardsBoardNoPostsRequest,
  GetBoards,
  GetBoardsBoardNoPostsPostNoReplies,
  GetBoardsBoardNoPostsPostNoRepliesRequest,
  GetBoardsBoardNoPostsPostNoRequest,
  GetBoardsBoardNoPostsPostNoResponse,
  GetBoardsRequest,
  NCPResponse,
  PostBoardsBoardNoPostsFiles,
  PostBoardsBoardNoPostsRequest,
  PutBoardsBoardNoPostsPostNoRequest,
  PutBoardsBoardNoPostsRequest,
} from 'ncp-api-supporter';
import { throwWindowPopup } from '@/helpers/popup';
import { Article, ArticleFile, PopupParameters, SearchMethod } from '@/types';

@Component
export default class ArticleMixin extends Vue {
  private boardNoList: GetBoards[] = [];
  private reSearchMethod: SearchMethod;

  /**
   * ----- Get parameter boardNo for first searching.------
   * @param mallNo
   * @returns Promise<GetBoards[]>
   * 1. Generate api request parameter.
   * 2. Get board list by calling api.
   * 3. Set boardNos value from board list & save to localStorage
   * api-url: /boards [get]
   *--------------------------------------------------------
   */
  public async getBoardNos(mallNo: number): Promise<GetBoards[]> {
    // 1.
    const param: GetBoardsRequest = {
      params: {
        mallNo: Number(mallNo),
      },
    };

    // 2.
    let boardNos: GetBoards[] = [];
    try {
      const response: NCPResponse<GetBoards[]> = await this.$api.getBoards(param);

      // 3.
      if (response && response.data) {
        boardNos = response.data.filter(item => item.used);
        // for get method
        this.boardNoList = boardNos;
      }
    } catch (err) {
      if (err) {
        console.error(err);
        this.boardNoList = [];
      }
    }
    return boardNos;
  }

  /**
   * board select-box value init. .
   * @param mallNo
   * @returns  Promise<{ name: string; value: number }[]>
   */
  public async getSelectBoardNos(mallNo: number, isDefault?: boolean): Promise<{ name: string; value: number }[]> {
    const tBoardNos: { name: string; value: number }[] = [];
    await this.getBoardNos(mallNo);
    if (isDefault) {
      tBoardNos.push({ name: window.$t('BOARD.BASIC.ARTICLE.SELECT_ONE'), value: -1 });
    }
    this.boardNoList.map(item => {
      tBoardNos.push({ name: item.boardName, value: Number(item.boardNo) });
    });
    return tBoardNos;
  }

  /**----- Delete articles by calling api ------
   * @param selectedArticles
   * @param searchMethod: refresh data.
   * @returns Promise<void>
   * 1.generate parameter for api
   * 2.call api to delete articles
   * 3.refresh grid when deleted successful.
   * api-url: /boards/{boardNo}/posts [delete]
   *---------------------------------------------
   */
  public async deleteArticle(boardNo: number, selectedArticles: string, searchMethod: SearchMethod): Promise<void> {
    // confirm: whether to delete.
    /*const isPass = await throwMessagePopup(window.$t('BOARD.BASIC.ARTICLE.CONFIRM_DELETE_ARTICLE') as string, true);
    console.log(isPass.state);
    if (isPass.state !== PopupClose.CONFIRM) {
      return;
    }*/
    if (!confirm(window.$t('BOARD.BASIC.ARTICLE.CONFIRM_DELETE_ARTICLE'))) return;

    // 1
    const obj: DeleteBoardsBoardNoPostsRequest = {
      pathParams: {
        boardNo: boardNo,
      },
      params: {
        postNos: selectedArticles,
      },
    };

    // 2
    const response = await this.$api.deleteBoardsBoardNoPosts(obj);
    // 3
    if (response && response.status === 200) {
      // alert message of deleted successful
      alert(window.$t('BOARD.BASIC.ARTICLE.COMPLETE_DELETE'));
      // refresh grid.
      searchMethod();
    } else {
      // failed
      //throwMessagePopup(window.$t('삭제하지 못했습니다.'));
    }
  }

  /**----Get reply article list by calling api ----
   * @param data PopupParameters
   * 1. generate api request parameters
   * 2. get reply article list by api
   * api: boards/{boardNo}/posts/{postNo}/replies
   *-----------------------------------------------
   */
  public async getArticleReplyList(data: PopupParameters): Promise<GetBoardsBoardNoPostsPostNoReplies[]> {
    // 1.
    const obj: GetBoardsBoardNoPostsPostNoRepliesRequest = {
      pathParams: {
        boardNo: Number(data.row.boardNo),
        postNo: Number(data.row.no),
      },
      params: {
        mallNo: Number(data.mallNo),
        page: data.page ?? 1,
        pageSize: data.pageSize ?? 30,
      },
    };
    // 2.
    //GetBoardsBoardNoPostsPostNoRepliesResponse
    const response: NCPResponse<GetBoardsBoardNoPostsPostNoReplies[]> = await this.$api.getBoardsBoardNoPostsPostNoReplies(
      obj,
    );
    return response.data;
  }

  /**
   * --- article detail popup for (article create,edit,reply,reply-edit) -----
   * @param: popupParameters{mallNo,boardNo,mode[create|edit|reply|replyModify],row,parentTitle?,parentContent?}
   * @param：searchMethod: callback method
   * ------------------------------------------------------------------------
   */
  public articleDetailPopup(popupData: PopupParameters, searchMethod: SearchMethod, name: string): void {
    this.reSearchMethod = searchMethod;
    throwWindowPopup('ArticleDetail', popupData, 'lg', this.detailCallback, name);
  }

  /**
   * ----- callback method for article detail page popup [window popup] ----
   */
  public detailCallback(callbackData: { state: string; data: object }): void {
    if (callbackData.state === 'close') return;
    const result = callbackData.data;
    if (result && this.reSearchMethod) {
      // refresh articl list of grid.
      this.reSearchMethod();
    }
  }

  /**
   * -----  create a article by api  --------
   * @param article: article model
   * @param mode value: [reply, create]
   * 1. generate parameter for api of save article
   * 2. call api to insert data.
   * 3. return result.
   * api: boards/{boardNo}/posts  [post]
   * -------------------------------------------
   */
  public async createArticle(article: Article, mode: string): Promise<string> {
    let result = '';
    // 1
    const param: PostBoardsBoardNoPostsRequest = {
      pathParams: {
        boardNo: article.boardNo,
      },
      data: {
        parentPostNo: mode == 'reply' ? article.no : null,
        imageUrl: '',
        //categoryNo: article.categoryNo, //null TODO
        files: this.convertFileType(article.files),
        opened: article.opened,
        title: article.title,
        mallNo: Number(article.mallNo),
        secreted: mode == 'reply' ? article.secreted : false,
        content: article.content,
        notice: article.noticed,
      },
    };
    // 2
    await this.$api.postBoardsBoardNoPosts(param).then(response => {
      if (response) {
        alert(window.$t('BOARD.BASIC.ARTICLE.ALERT_SUCCESS'));
        result = mode;
      }
    });
    // 3
    return result;
  }

  /**
   * -------- edit a article by api --------------
   * @param article: article model
   * @param mode value: [edit,replyModify]
   * 1. generate parameter for api of edit article
   * 2. call api to insert data.
   * 3. return result.
   * api: boards/{boardNo}/posts/{postNo} [put]
   * ---------------------------------------------
   */
  public async modifyArticle(article: Article, mode: string): Promise<string> {
    let result = '';
    // 1
    const param: PutBoardsBoardNoPostsPostNoRequest = {
      pathParams: {
        boardNo: article.boardNo,
        postNo: article.no,
      },
      data: {
        orderFixed: article.noticed ? article.orderFixed : false,
        imageUrl: '',
        //categoryNo: article.categoryNo, //null TODO
        files: this.convertFileType(article.files),
        opened: article.opened,
        title: article.title,
        mallNo: Number(article.mallNo),
        secreted: article.secreted,
        content: article.content,
        notice: article.noticed,
      },
    };
    // 2
    await this.$api.putBoardsBoardNoPostsPostNo(param).then(response => {
      if (response) {
        alert(window.$t('BOARD.BASIC.ARTICLE.ALERT_SUCCESS'));
        result = mode;
      }
    });
    // 3
    return result;
  }
  /**
   * ---- Convert file arry from Articlefile type to PostBoardsBoardNoPostsFiles type -----
   * @param articleFile : ArticleFile[]
   * @returns  PostBoardsBoardNoPostsFiles[]
   * --------------------------------------------------------------------------------------
   */
  public convertFileType(articleFile: ArticleFile[]): PostBoardsBoardNoPostsFiles[] {
    const uploadFile: PostBoardsBoardNoPostsFiles[] = [];
    if (articleFile) {
      articleFile.forEach(item => {
        uploadFile.push({ originalFileName: item.originalName, uploadedFileName: item.uploadedName });
      });
    }
    return uploadFile;
  }
  /**
   * --------- Moved article from one board to other board ----------
   * @param data :parameters for api
   * @returns result string
   * 1. generate parameter for api
   * 2. call api
   * 3. show result message
   * 4.retrun result flag
   * api url: boards/{boardNo}/posts [put]
   * ----------------------------------------------------------------
   */
  public async movedArticle(data: PopupParameters, boardNo: number): Promise<string> {
    // 1
    const obj: PutBoardsBoardNoPostsRequest = {
      pathParams: {
        boardNo: Number(data.boardNo),
      },
      data: {
        postNos: data.articleNumbers
          .toString()
          .split(',')
          .map(Number),
        mallNo: Number(data.mallNo),
        boardNo: Number(boardNo),
        //categoryNo: null, //todo
      },
    };
    let result = '';
    // 2
    await this.$api.putBoardsBoardNoPosts(obj).then(response => {
      const res = response.data;
      // 3
      const successMessage =
        res.successCount > 0 ? window.$t('BOARD.BASIC.ARTICLE.SAVE_SUCCESS', [res.successCount]) : '';
      if (res.failureCount > 0) {
        const failMessage = window.$t('BOARD.BASIC.ARTICLE.SAVE_FAILED', [res.failureCount]) + res.failureReasons;
        result = 'S&F';
        alert(successMessage + failMessage);
      } else {
        result = 'F';
        alert(successMessage);
      }
    });
    // 4
    return result;
  }

  /**-----------Get article details for replying --------------
   * @param data: PopupParameters
   * @returns Promise<GetBoardsBoardNoPostsPostNoResponse>
   * 1. generate api parameters
   * 2. call api
   * 3. retrun
   * api: boards/{boardNo}/posts/{postNo}
   *----------------------------------------------------------
   */
  public async getArticleDetail(data: PopupParameters): Promise<GetBoardsBoardNoPostsPostNoResponse> {
    // 1
    const param: GetBoardsBoardNoPostsPostNoRequest = {
      pathParams: {
        boardNo: data.boardNo ? Number(data.boardNo) : Number(data.row.boardNo),
        postNo: Number(data.row.no),
      },
      params: { mallNo: Number(data.mallNo) },
    };
    // 2
    const res = await this.$api.getBoardsBoardNoPostsPostNo(param);
    // 3
    return res.data;
  }
}
