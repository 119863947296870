




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({ components: { AdminIframe } })
export default class NaverpayConfigIframe extends Vue {
  private get remoteSrc() {
    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/configuration/payment/naverpay-config?serviceType=PRO';
  }
}
