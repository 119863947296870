import { DirectiveOptions } from 'vue';
import store from '@/store';

const onChanged = function() {
  store.commit('SET_UNSAVED_FORM');
};

const unsavedDetector: DirectiveOptions = {
  bind(el: HTMLInputElement): void {
    // bind(el: HTMLInputElement, binding): void {
    // enabled된 폼만 감지하려면 binding.value를 사용
    el.addEventListener('change', onChanged);
  },
  unbind(el: HTMLInputElement): void {
    el.removeEventListener('change', onChanged);
  },
};
export default unsavedDetector;
