


























































































import { Vue, Component, Emit, Prop, PropSync } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { Mall } from 'ncp-api-supporter';
import { throwPopup } from '@/helpers/popup';
import { validateFileNameExtension } from '@/utils/webExcel';
import { ExcelUploadType, ResultPopupData } from '@/types/ProductThumbnailRegistration';
import { mall } from '@/utils/mall';

@Component({
  components: { SelectBox, ToolTip },
})
export default class UploadForm extends Vue {
  @Emit('open-image-upload-popup')
  private openImageUploadPopup(): boolean {
    return this.checkSelectedMall;
  }
  @Emit('download-sample-excel')
  private downloadSampleExcel(): boolean {
    return this.checkSelectedMall;
  }
  @Emit('post-excel-file')
  private postExcelFile(excelFile: File): ExcelUploadType {
    return { excelFile, resultPopup: this.openResultPopup, mallNo: this.mallNoSync };
  }

  @PropSync('mallNo', { required: true }) private mallNoSync: string;

  @Prop({ required: true }) private type!: string;
  @Prop({ required: false }) private checkBeforeUploadingExcel!: () => boolean;

  private mallList: Mall[] = [];
  private isOnlyOneMall = mall.isOnlyOneMall;

  created() {
    this.setMall();
  }

  private setMall(): void {
    if (mall.isOnlyOneMall) {
      this.mallNoSync = mall.onlyOneMallNo.toString();
    }
    this.mallList = mall.mallsSortedByCreationDate;
  }

  private get checkSelectedMall(): boolean {
    return this.mallNoSync === '';
  }

  private onClickExcelUpload(): void {
    if (this.checkBeforeUploadingExcel && !this.checkBeforeUploadingExcel()) return;
    (this.$el.querySelector('#excelFile') as HTMLElement).click();
  }

  private uploadExcelFile({ target }: { target: HTMLInputElement }) {
    const excelFile = target.files[0];
    target.value = null;
    const isExelFile = validateFileNameExtension(excelFile, `PRODUCT.BULK_IMAGE.EXCEL_UPLOAD_FAILURE_MSG_${this.type}`);
    if (!isExelFile) return;
    this.postExcelFile(excelFile);
  }

  private openResultPopup(result: ResultPopupData) {
    throwPopup({
      name: 'UploadResult',
      data: {
        result,
        type: this.type,
      },
    });
  }
}
