import { CommissionType } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { InputNumber, SupplyPriceCalculatorInfo } from '@/types';
import { commissionRateValidator } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/CalculatorValidator';
import { getOnlyNumbers } from '@/utils/numberFormat';

export class SupplyPriceCalculator {
  private _commissionAmount: InputNumber;
  private commissionType: CommissionType;
  constructor(supplyCalculatorOption: SupplyPriceCalculatorInfo) {
    this._commissionAmount = supplyCalculatorOption.commissionAmount;
    this.commissionType = supplyCalculatorOption.commissionType;
  }

  changeCommission(commission: InputNumber): void {
    if (this.isPurChaseType) {
      this._commissionAmount = getOnlyNumbers(commission.toString());
      return;
    }
    const commissionScaled = this.calcScaledRate(commission);
    commissionRateValidator(commissionScaled);
    this._commissionAmount = commissionScaled;
  }

  get commissionAmount(): number {
    return Number(this._commissionAmount);
  }

  changeCommissionType(commissionType: CommissionType): void {
    this.commissionType = commissionType;
  }

  calcSupplyPrice(discountPriceResult: number): number {
    return this.calcCommissionAmountResult(discountPriceResult);
  }

  private get isPurChaseType(): boolean {
    return this.commissionType === CommissionType.PURCHASE;
  }

  private calcCommissionAmountResult(discountPriceResult: number): number {
    if (this.isPurChaseType) return Number(this._commissionAmount);

    const rate = this.calcScaledRate(this._commissionAmount) / 100;
    // NOTE: 프리미엄 공급가 소수점 절사
    return Math.floor(discountPriceResult - discountPriceResult * rate);
  }

  private calcScaledRate(rate: InputNumber): number {
    const scaled = Math.pow(10, 1);
    return parseInt((Number(rate) * scaled).toString(), 10) / scaled;
  }
}
