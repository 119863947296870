export default Object.freeze({
  AUTHORITY_GROUP_NAME: '권한그룹명',

  PRIVACY_AUTHORITY: '개인정보 목록조회',
  PRODUCT_AUTHORITY: '상품관리 권한',
  SMS_AUTHORITY: 'SMS 수신여부',

  AUTHORITY_GROUP: '권한그룹명',
  AUTHORITY_GROUP_DESC: '권한그룹 설명',
  AUTHORITY_GROUP_ADMIN: '권한보유 운영자',
  AUTHORITY_GROUP_MALL_LENGTH: '쇼핑몰 수',
  AUTHORITY_GROUP_CREATOR: '등록자',
  AUTHORITY_GROUP_CREATE_DATE: '등록일시',
  AUTHORITY_GROUP_LAST_MODIFIER: '최종 수정자',
  AUTHORITY_GROUP_LAST_MODIFY_DATE: '최종 수정일시',

  AUTHORITY_GROUP_REGISTER: '권한그룹 등록',

  ADMIN_ID: '아이디',
  ADMIN_NAME: '운영자명',
  PRODUCT_MANAGER: '개별 상품담당 여부',
  AUTHORITY_REGISTERED_DATE: '계정 등록일시',

  BASIC_AUTHORITY: '기본정보',
  MENU_AUTHORITY: '메뉴 권한',

  SELECTED_MALL: '선택된 쇼핑몰',
  MALL_SELECT: '쇼핑몰 선택',
  ADMIN_GUIDANCE_SMS_AUTHORITY: '운영자 안내<br>SMS 수신여부',

  SELECTED_MALL_TOOLTIP:
    '권한그룹 최초 등록 시 선택된 쇼핑몰이 노출되는 영역입니다.\n선택된 쇼핑몰은 권한 보유 운영자가 없는 경우 수정이 가능하며,\n권한 보유 운영자가 1명이라도 존재하는 경우 수정이 불가합니다.',
  PRIVACY_AUTHORITY_TOOLTIP:
    '마스킹되지 않은 회원의 개인정보를 조회할 수 있는 권한으로,\n권한이 없는 운영자는 회원의 개인정보가 마스킹되어 보여집니다.',
  ADMIN_GUIDANCE_SMS_AUTHORITY_TOOLIP:
    "해당 그룹의 권한그룹을 가진 운영자에게 '운영자 안내' 자동 SMS가 발송됩니다.\n단, SMS 포인트가 부족한 경우 발송되지 않습니다.\n* SMS 관리 &gt; 사용설정 &gt; SMS 포인트 충전하기에서 충전 후\n운영자 안내 SMS 수신여부 '수신함' 설정해주세요.",

  AUTHORITY_GROUP_ADMIN_LIST_PRODUCT_MANAGER_TOOLTIP:
    '운영자가 개별상품의 담당자로 지정되어 있는 경우 "Y"로 표기됩니다.\n개별 상품 담당자로 지정되어 있는 담당자가 있는 경우 해당 권한 그룹은\n상품 관리 권한을 "권한 없음" 또는 "읽기"로 수정이 불가합니다.\n개별 상품 담당자 변경 후 상품 관리 메뉴 권한 변경이 가능합니다.',

  AUTHORITY_GROUP_NAME_PLACEHOLDER: '권한그룹명 입력',
  AUTHORITY_GROUP_DESC_PLACEHOLDER: '권한그룹 설명 입력',

  SMS_RECEIVE: '수신함',
  SMS_NOT_RECEIVE: '수신 안 함',

  NOTICE_MENU_AUTHORITY:
    "상품담당자(MD)권한 체크 시 상품 하위 2차 메뉴와 3차 메뉴 전체 '읽기+쓰기' 권한으로 설정되며 해당 권한이 부여된 운영자의 경우 상품 등록 시 담당자로 지정할 수 있습니다.",

  RESET_AUTHORITY: '권한 초기화',

  VIEW_BY_MENU_1: '1차 메뉴 기준으로 보기',
  VIEW_BY_MENU_2: '2차 메뉴 기준으로 보기',
  VIEW_BY_MENU_3: '3차 메뉴 기준으로 보기',

  SELECT_AUTHORITY_SUB_TEXT: '선택한 메뉴에',
  SELECT_AUTHORITY: '권한 선택',
  AUTHORITY_NONE: '권한없음',
  AUTHORITY_READ: '읽기',
  AUTHORITY_READ_WRITE: '읽기 + 쓰기',
  APPLY_ALL_MENU_AUTHORITY: '일괄적용',

  MENU_NAME: '메뉴명',
  AUTHORITY_SETTING: '권한설정',
  AUTHORITY_MD: '상품담당자(MD)권한',

  INDIVIDUAL_SETTING_SUB_MENU: '하위 메뉴 개별설정',

  CONFIRM_RESET_MENU_AUTHORITY: '전체 메뉴의 권한을 초기화 하시겠습니까? 초기화할 경우 이전 상태로 변경됩니다.',
  ALERT_COMPLETE_RESET_MENU_AUTHORITY: '권한 초기화가 완료 되었습니다.',

  CONFIRM_DELETE: '삭제한 권한그룹 정보는 복구되지 않습니다. 이 권한그룹을 삭제하시겠습니까?',
  ALERT_SUCCESS_DELETE: '권한그룹이 삭제 되었습니다.',
  ALERT_CAN_NOT_DELETE: '운영자가 등록된 권한그룹은 삭제할 수 없습니다.',

  ALERT_SUCCESS_SAVE: '저장 완료되었습니다.',

  ALERT_MAX_100: '권한그룹은 최대 100개까지 등록 가능합니다.',

  ALERT_INSERT_NAME: '권한그룹명을 입력해주세요.',
  ALERT_CHECK_MALL: '쇼핑몰을 선택해주세요.',
  ALERT_CHECK_MENU_AUTHORITY: '권한은 최소 1개 이상 설정해주세요.',

  ALERT_CHANGE_PARENT_MENU_AUTHORITY: '상위 메뉴 변경 시 하위 메뉴 전체 일괄 변경됩니다.',
  CONFIRM_MOVE_COPY_REGIST:
    '해당 권한그룹을 복사등록 하시겠습니까? 복사등록화면으로 이동 시 수정페이지에서 작성된 항목은 저장되지 않습니다.',

  ALERT_SELECT_MENU_AUTHORITY: '권한을 선택해주세요.',
  ALERT_CHECK_MENU: '메뉴를 선택해주세요.',

  CONFIRM_MOVE_LIST: '페이지 이동하시겠습니까? 등록하신 내용은 저장되지 않습니다.',

  COPY_REGIST: '권한그룹 복사등록',
  DELETE: '권한그룹 삭제',
});
