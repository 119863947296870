const nodeElements = () => {
  return {
    leafNode: `
		<div class="tui-tree-content-wrapper" style="padding-left: {{indent}}px !important">
			<span class="tui-tree-text tui-js-tree-text" style="float:left">{{nodeName}}</span>
			<span class="tui-tree-text tui-js-tree-text" style="float:right">
			{{num}}${window.$t('PERSONS')}
      </span>
		</div>
	`,
  };
};

export default nodeElements;
