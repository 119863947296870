import router from '@/router';
import { throwExternalWindowPopup, throwPopup } from '@/helpers/popup';
import store from '@/store';
import { ApplyCode } from '@/api/godo/type';

/* eslint-disable @typescript-eslint/camelcase */
export const codeClassify = {
  service_pg_kcp: 'PG_KCP',
  service_pg_toss: 'PG_TOSS',
  service_pg_inicis: 'PG_INICIS',
  service_pg_easypay: 'PG_EASYPAY',
  service_pg_nicepay: 'PG_NICEPAY',
  service_pg_galaxia: 'PG_GALAXIA',
  service_pg_easypay_ov: 'PG_EASYPAY_OV',
  service_payco: 'PG_PAYCO',
  service_payco_login: 'EASY_LOGIN',
  service_naver_login: 'EASY_LOGIN',
  service_kakao_login: 'EASY_LOGIN',
  service_facebook_login: 'EASY_LOGIN',
  service_db_insurance: 'DB_INSURANCE',
  service_nicekeeper: 'NICE_KEEPER',
  service_dreamsecurity: 'DREAM_SECURITY',
  service_sms_refusal: 'SMS_REFUSAL',
  service_realpacking: 'REAL_PACKING',
  service_kbbridge: 'KB_BRIDGE',
  service_sellerbot: 'SELLER_BOT',
  service_autoledger: 'AUTO_LEDGER',
  service_kollus_live_commerce: 'KOLLUS_LIVE_COMMERCE',
  lalastation: 'LALA_STATION',
  service_box: 'GODO_BOX',
  service_mc_box: 'MC_BOX',
  service_smarteditor: 'SMART_EDITOR',
  service_loudsourcing: 'LOUD_SOURCING',
  service_tyle: 'TYLE',
  service_designsource: 'DESIGN_SOURCE',
  service_sms: 'SMS',
  service_acecounter: 'ACE_COUNTER',
  service_mobilecti: 'MOBILE_CTI',
  marketing_naver_checkout: 'NAVER_EASY_PAY',
  service_kakao: 'KAKAO_ALARM',
  service_instagram: 'INSTAGRAM',
} as const;

const { VUE_APP_GODO_API_URL } = process.env;

export const applyUrl = {
  PG_KCP: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=2&type=9&page=remoteGodoPage',
  BASIC_PG_KCP: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=2&type=11&page=remoteGodoPage',
  PG_TOSS: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=1&type=9&page=remoteGodoPage',
  BASIC_PG_TOSS: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=1&type=11&page=remoteGodoPage',
  PG_INICIS: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=6&type=9&page=remoteGodoPage',
  BASIC_PG_INICIS: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=6&type=11&page=remoteGodoPage',
  PG_EASYPAY: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=5&type=9&page=remoteGodoPage',
  BASIC_PG_EASYPAY: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=5&type=11&page=remoteGodoPage',
  PG_EASYPAY_OV: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=24&type=9&page=remoteGodoPage',
  PG_NICEPAY: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=19&type=9&page=remoteGodoPage',
  BASIC_PG_NICEPAY: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=19&type=11&page=remoteGodoPage',
  PG_GALAXIA: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=23&type=9&page=remoteGodoPage',
  BASIC_PG_GALAXIA: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=23&type=11&page=remoteGodoPage',
  PG_PAYCO: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=10&type=9&page=remoteGodoPage',
  BASIC_PG_PAYCO: VUE_APP_GODO_API_URL + '/service/pg_regist_01.php?mode=10&type=11&page=remoteGodoPage',
  DB_INSURANCE: 'https://www.imet.kr/godo/',
  NICE_KEEPER: 'https://www.niceid.co.kr/czkm_serv_aply.nc',
  DREAM_SECURITY: VUE_APP_GODO_API_URL + '/echost/power/add/member/certification-kcp-process.gd',
  SMS_REFUSAL: VUE_APP_GODO_API_URL + '/echost/power/add/convenience/sms-refusal-process.gd',
  REAL_PACKING: 'https://www.realpacking.com/godo/signup.php',
  SELLER_BOT: 'https://www.sellerbot.co.kr/?e=002',
  AUTO_LEDGER: 'https://www.easyshop.co.kr/taxIntro/taxPtnsGodo.kicc',
  KOLLUS_LIVE_COMMERCE: 'https://bms-commerce.kollus.com/externalSite/regist?siteType=SHOPBY',
  LALA_STATION: 'https://lalastations.com/contact/contact_nh.php',
  GODO_BOX: 'http://hanwellebox.godomall.com/main/index.php',
  MC_BOX: 'http://www.mcbox.co.kr/hosting/godo/main.php',
  SMART_EDITOR: 'http://godo.smarteditor.co.kr',
  LOUD_SOURCING: 'https://loud.kr/?godo',
  TYLE: 'https://tyle.io/?code=ki3TToih97z',
  DESIGN_SOURCE: 'https://design.nhn-commerce.com/source/source-list.php',
  MOBILE_CTI: 'https://mobile-contact.toast.com/',
} as const;

type ApplyUrlKey = keyof typeof applyUrl;

export const goTo = {
  us(mallNo: number, componentName: string) {
    router.push({ name: componentName, query: { mallNo: mallNo.toString() } }).catch(console.error);
  },
  otherWindow(applyUrlKey: ApplyUrlKey) {
    window.open(applyUrl[applyUrlKey]);
  },
  openPopupNiceKeeper() {
    throwExternalWindowPopup(applyUrl['NICE_KEEPER'], 'sm');
  },
  openPopupRealPacking() {
    throwExternalWindowPopup(applyUrl['REAL_PACKING'], 'lg');
  },
  openKbBridgeLayer() {
    throwPopup({ name: 'KbBridgeAppQrCode' }).catch(console.error);
  },
  openMcBox() {
    const godoSno = store.getters['admin/getGodoSno'] as string;
    const encodeGodoSno = btoa(godoSno.toString()).replaceAll('+/=', '-_,');
    window.open(applyUrl['MC_BOX'] + `?userid=${encodeGodoSno}`);
  },
  // sendPostRequest(applyUrlKey, mallId) {
  //   const result = axios.post(applyUrl[applyUrlKey], { mallId });
  //   console.log(result);
  // },
  openPopupPg(applyUrlKey, mallId) {
    const isBasicPlanType = store?.getters['admin/getAdmin']?.plan === 'BASIC';
    if (isBasicPlanType) {
      throwExternalWindowPopup(`${applyUrl['BASIC_' + applyUrlKey]}&mall_id=${mallId}`);
    } else {
      throwExternalWindowPopup(applyUrl[applyUrlKey] + `&mall_id=${mallId}`);
    }
  },
};

export interface ApplyServiceInfo {
  remoteCode: ApplyCode;
  mallNo: number;
  mallId?: string;
}

export const applyService = (info: ApplyServiceInfo): void => {
  const { remoteCode, mallNo, mallId } = info;
  const service = codeClassify[remoteCode];
  if (!service) return alert('없는 서비스 입니다.'); // TODO

  switch (service) {
    case 'INSTAGRAM':
    case 'EASY_LOGIN':
      goTo.us(mallNo, 'ExternalService');
      break;
    case 'SMS':
      goTo.us(mallNo, 'SmsConfig');
      break;
    case 'ACE_COUNTER':
      goTo.us(mallNo, 'AceRegistration');
      break;
    case 'KAKAO_ALARM':
      goTo.us(mallNo, 'NotificationMain');
      break;
    case 'DB_INSURANCE':
    case 'SELLER_BOT':
    case 'AUTO_LEDGER':
    case 'KOLLUS_LIVE_COMMERCE':
    case 'LALA_STATION':
    case 'GODO_BOX':
    case 'SMART_EDITOR':
    case 'LOUD_SOURCING':
    case 'TYLE':
    case 'DESIGN_SOURCE':
    case 'MOBILE_CTI':
      goTo.otherWindow(service);
      break;
    case 'MC_BOX':
      goTo.openMcBox();
      break;
    case 'REAL_PACKING':
      goTo.openPopupRealPacking();
      break;
    case 'KB_BRIDGE':
      goTo.openKbBridgeLayer();
      break;
    case 'NICE_KEEPER':
      goTo.openPopupNiceKeeper();
      break;
    // case 'DREAM_SECURITY':
    // case 'SMS_REFUSAL':
    //   goTo.sendPostRequest(service, mallId);
    //   break;
    case 'PG_KCP':
    case 'PG_TOSS':
    case 'PG_INICIS':
    case 'PG_PAYCO':
    case 'PG_EASYPAY':
    case 'PG_NICEPAY':
    case 'PG_GALAXIA':
    case 'PG_EASYPAY_OV':
      goTo.openPopupPg(service, mallId);
      break;
    case 'NAVER_EASY_PAY':
      goTo.us(mallNo, 'NaverEasyPaySetting');
      break;
  }
};
