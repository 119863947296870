

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { GenderTable } from '@/types';

@Component
export default class GenderCountTable extends Vue {
  @Prop({ required: true })
  private readonly gender: GenderTable;
}
