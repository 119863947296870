

























































import { Component } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { range1ToNI18n } from '@/utils/common';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { validateFileNameExtension } from '@/utils/webExcel';
import { isValidate } from '@/utils/validate';
import { NCPResponse, PostImagesResponse } from 'ncp-api-supporter';

@Component({
  components: { NoticeBox },
})
export default class ImageUpload extends WindowPopupMainVue {
  private readonly noticeList = range1ToNI18n(5, 'PRODUCT.BULK_PRODUCT.IMAGE_UPLOAD_POPUP_NOTICE_');
  private isRegistration = false;
  private uploadedFiles: string[] = [];
  private uploadedFileCnt = 0;

  created() {
    this.isRegistration = this.data.uploadedFiles?.length > 0;
    this.isRegistration && this.init();
  }

  private init() {
    this.uploadedFiles = this.data.uploadedFiles;
    this.uploadedFileCnt = this.uploadedFiles.length;
  }

  private uploadImageFile({ target }: { target: HTMLInputElement }) {
    const files = Array.from(target.files);
    if (files.length === 0) return;

    this.uploadedFileCnt = 0;
    this.uploadedFiles = [];
    if (!files.every(file => this.validateImageFile(file))) return;
    files.forEach(file => this.postImages(file));
  }

  private validateImageFile(file) {
    const EXTENSION_ERROR_MESSAGE = 'PRODUCT.BULK_PRODUCT.IMAGE_UPLOAD_POPUP_EXTENSION_ERR';
    const isImageFile = validateFileNameExtension(file, EXTENSION_ERROR_MESSAGE, /\.(jpg|jpeg|png|gif|bmp)$/);
    return !(!isImageFile || !this.checkImageFileSize(file));
  }

  private checkImageFileSize(file: File): boolean {
    const MAX_SIZE = 10 * 1024 * 1024;
    const fileSize = file.size;
    return isValidate(MAX_SIZE >= fileSize, this.$t('PRODUCT.BULK_PRODUCT.IMAGE_UPLOAD_POPUP_FILE_SIZE_ERR'));
  }

  private async postImages(file: File) {
    const fileData = new FormData();
    fileData.append('file', file);
    try {
      const { data }: NCPResponse<PostImagesResponse> = await this.$api.postImages({
        data: fileData,
        params: { mallNo: this.data.mallNo, overwrite: true },
      });
      this.uploadedFiles.push(data.url);
      this.uploadedFileCnt++;
      this.isRegistration = true;
    } catch (e) {
      console.error(e);
    }
  }
}
