import { countries } from 'countries-list';

export interface FakeCountry {
  name: string;
  pick: boolean;
  id: string;
}

interface MockApi {
  countries: {
    data: FakeCountry[];
    get: () => Promise<FakeCountry[]>;
  };
}

const mockApi: MockApi = {
  countries: {
    data: Object.entries(countries).map(([k, v]) => ({
      name: v.name.toString(),
      pick: Math.floor(Math.random() * 101) > 50, // n개 pick: true
      id: k,
    })),
    get() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(this.data);
        }, 200);
      });
    },
  },
};

export default mockApi;
