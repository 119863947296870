

































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import Grid from '@/components/common/grid/Main.vue';
import SummaryTab from '@/components/product/basic/SummaryTab.vue';
import ProductManageThumb from '@/components/marketing/configuration/ProductManageThumb.vue';
import { Getter } from 'vuex-class';
import { CampaignsProductStatusesEntry, CampaignsProductStatusesType, Mall } from 'ncp-api-supporter';
import { getSummaryTab, gridProp, checkboxData } from '@/views/contents/marketing/googleAd/productManage';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { Row } from '@/types/tui-grid';
import { GridEventProps, GridProp } from '@/types';
import { ProductManageSummaryTab } from '@/types/marketing';
import { CheckBoxGroupOption, ValueType } from '@/helpers/type';
import { getCurrentMallNo } from '@/utils/mall';
import { getCampaigns } from '@/views/contents/marketing/googleAdConfig';

@Component({
  components: {
    ProductManageThumb,
    SummaryTab,
    CheckboxGroup,
    Grid,
    ToolTip,
  },
})
export default class ProductManage extends Vue {
  private contents: CampaignsProductStatusesEntry[] = [];
  private statusType = 'APPROVED,PENDING,DISAPPROVED';
  private summaryTab: ProductManageSummaryTab[] = [];
  private pageNo = 1;
  private pageSize = 30;
  private statusTypeCheck: CheckBoxGroupOption<ValueType> = checkboxData;
  private gridProp: GridProp = gridProp();
  private finalUpdateData = '';

  @Ref()
  private readonly grid!: Grid;

  @Getter('mall/getMalls') private malls!: Mall[];

  @Watch('$route.query.page')
  private makeQuery() {
    this.pageNo = Number(this.$route.query.page);
    this.pageSize = Number(this.$route.query.pageSize);
  }

  @Watch('$route.query.mallNo')
  private async changeMallNoQuery() {
    const result = await getCampaigns(this.mallNo);

    if (result.getCampaigns) {
      this.finalUpdateData = '';
      this.contents = [];
    }

    if (result.status === 'PENDING_BY_PRODUCT') {
      this.grid.changeNoDataMessage(this.$t('MARKETING.CONFIGURATION.PENDING_FOR_MERCHANT_CENTER'));
    } else {
      this.grid.changeNoDataMessage(this.$t('MARKETING.CONFIGURATION.SEARCH_PRODUCT_STATE'));
    }
  }

  async created() {
    const result = await getCampaigns(this.mallNo);

    if (result.status === 'PENDING_BY_PRODUCT') {
      this.grid.changeNoDataMessage(this.$t('MARKETING.CONFIGURATION.PENDING_FOR_MERCHANT_CENTER'));
    } else {
      this.grid.changeNoDataMessage(this.$t('MARKETING.CONFIGURATION.SEARCH_PRODUCT_STATE'));
    }

    this.fetchProductSummaries();
    this.removeClickEvent();
  }

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  private async fetchProductSummaries() {
    const request = {
      params: {
        mallNo: this.mallNo,
      },
    };

    const { data } = await this.$api.getCampaignsCountByProductStatus(request);
    const { approvedCount, expiringCount, pendingCount, disapprovedCount } = data;
    this.summaryTab = getSummaryTab({ approvedCount, expiringCount, pendingCount, disapprovedCount });
  }

  private async fetchProductList() {
    if (this.statusType === '') {
      alert(this.$t('MARKETING.CONFIGURATION.CHECK_PRODUCT_STATE'));
      return false;
    }

    const request = {
      params: {
        mallNo: this.mallNo,
        page: this.pageNo,
        size: this.pageSize,
        statusTypes: this.statusType as CampaignsProductStatusesType,
      },
    };

    const {
      data: { campaignStatus, entries, lastSyncDateTime },
    } = await this.$api.getCampaignsProductStatuses(request);

    if (entries.length === 0) {
      this.contents = [];
      this.grid.changeNoDataMessage(this.$t('MARKETING.CONFIGURATION.NO_SEARCH_PRODUCT_STATE'));

      return false;
    }

    if (campaignStatus === 'PENDING_BY_PRODUCT') {
      this.contents = [];
      this.grid.changeNoDataMessage(this.$t('MARKETING.CONFIGURATION.PENDING_FOR_MERCHANT_CENTER'));

      return false;
    }

    const newEntries = this.getRowspan(entries);

    this.contents = newEntries;
    this.finalUpdateData = lastSyncDateTime.toString().replace('T', ' ');
  }

  private getRowspan(gridArray) {
    return gridArray.flatMap(entry => {
      const count = entry.issues.length;

      if (count === 0) return entry;
      else if (count > 1) {
        entry._attributes = {
          rowSpan: {
            productNo: count,
            productName: count,
            status: count,
            creationDateTime: count,
            lastUpdateDateTime: count,
            expirationDateTime: count,
          },
        };
      }

      return entry.issues.map(issue => ({ ...entry, issues: issue }));
    });
  }

  private resetParams() {
    this.statusType = '';
  }

  private removeClickEvent() {
    document.querySelectorAll('.ncp_tbl td a').forEach(e => {
      e.removeAttribute('href');
      e.classList.add('remove-click-event');
    });
  }

  private onItemClicked({ rowKey, columnName }: GridEventProps): void {
    const rowData: Row = this.grid.getRowAt(rowKey);
    if (columnName === 'productNo') openEditProductWindow(Number(rowData.productNo));
  }
}
