import XLSX, { WorkBook, WorkSheet, WritingOptions, Range, CellObject } from 'xlsx';
import fileSaver from 'file-saver';
import { getStrYMDHMSS } from './dateFormat';
import { TranslateResult } from 'vue-i18n';
import $ from 'jquery';

function _strToArrayBuffer(str) {
  const buf = new ArrayBuffer(str.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != str.length; ++i) view[i] = str.charCodeAt(i) & 0xff;
  return buf;
}

function _getCell(value: CellObject['v']): CellObject {
  const cell: CellObject = { v: value, t: 's' };
  if (cell.v == null) {
    return null;
  }

  if (typeof cell.v === 'number') {
    cell.t = 'n';
  } else if (typeof cell.v === 'boolean') {
    cell.t = 'b';
  } else if (cell.v instanceof Date) {
    cell.v = getStrYMDHMSS(cell.v);
  }

  return cell;
}

interface KeyMapJson {
  [key: string]: TranslateResult;
}

/**
 * @writeExcelFile. premium sa 에서 유래된 함수입니다.
 *
 * @param jsonDataArray : excel 생성할 json 데이터
 * @param keyMapJson : excel 컬럼 header 정보
 * @param fileName : string. normalize(정규화)는 이 함수 내부에서 처리합니다.
 * @param saveAs : fileSaver 로 바로 내려받게 할지 여부
 * @param formatter : grid data 를 excel 로 만들때 사용하는 인자.
 *
 * @return Blob : 가공된 excel 파일 객체. Blob 형식
 */
export function writeExcelFile<T, K = {}>(
  jsonDataArray: T[],
  keyMapJson: KeyMapJson,
  fileName: string,
  saveAs = false,
  formatter?: K,
): Blob {
  let colCnt = 0;
  const rowCnt = jsonDataArray.length;
  const ws: WorkSheet = {};
  // header
  Object.keys(keyMapJson).forEach((key): void => {
    const cellRef = XLSX.utils.encode_cell({ c: colCnt, r: 0 });
    ws[cellRef] = { v: keyMapJson[key], t: 's' };

    colCnt++;
  });

  for (let i = 0; i < jsonDataArray.length; i++) {
    const jsonData = jsonDataArray[i];
    let currentCol = 0;
    Object.keys(keyMapJson).forEach((key): void => {
      let cellValue = jsonData[key] ? jsonData[key] : '';
      if (!cellValue === true) {
        const splitKeys = key.split('.');
        for (let j = 0; j < splitKeys.length; j++) {
          const firstIndex = j === 0;
          cellValue = firstIndex ? jsonData[splitKeys[j]] : cellValue[splitKeys[j]];
          if (!cellValue === true) break;
        }
      }
      const currentRow = i + 1; // 1행 헤띔 +1
      const cellRef = XLSX.utils.encode_cell({ c: currentCol, r: currentRow });
      if (formatter && formatter[key]) {
        const cell = { row: jsonData, value: cellValue };
        cellValue = formatter[key](cell);
      }

      const cell = _getCell(cellValue);
      if (cell !== null) ws[cellRef] = cell;

      currentCol++;
    });
  }

  const range: Range = { s: { c: 0, r: 0 }, e: { c: colCnt, r: rowCnt } };
  ws['!ref'] = XLSX.utils.encode_range(range);

  const workbook: WorkBook = {
    SheetNames: ['Sheet1'],
    Sheets: { Sheet1: ws },
  };

  const wbout: WritingOptions = XLSX.write(workbook, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary',
  });

  const blob = new Blob([_strToArrayBuffer(wbout)], {
    type: 'application/octet-stream',
  });

  if (saveAs) fileSaver.saveAs(blob, fileName.normalize('NFC') + '.xlsx');

  return blob;
}

// 무엇때문에 함수를 이렇게 랩핑하지?
// export function writeExcel(jsonDataArray, keyMapJson, fileName, formatter): void {
//   writeExcelFile(jsonDataArray, keyMapJson, fileName, formatter, true);
// }

export function writeExcelFromArray(data, fileName) {
  const ws = {};

  for (let row = 0; row < data.length; row++) {
    if (Array.isArray(data[row])) {
      for (let col = 0; col < data[row].length; col++) {
        const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
        const cell = _getCell(data[row][col]);
        if (cell != null) {
          ws[cellRef] = cell;
        }
      }
    } else {
      const cellRef = XLSX.utils.encode_cell({ c: 0, r: row });
      const cell = _getCell(data[row]);
      if (cell != null) {
        ws[cellRef] = cell;
      }
    }
  }

  const rowCnt = data.length;
  // hslee : lint error 때문에 빌드가 안되어 하나씩 해결해 나가고 있는 사람입니다.
  // 도대체 이 row는 어디서 왔습니까. 이게 뭡니까. 일단 const row = 0;  로 처리합니다.
  const row = 0;
  const colCnt = Array.isArray(data[row]) ? data[0].length : 1;

  const range = { s: { c: 0, r: 0 }, e: { c: colCnt, r: rowCnt } };
  ws['!ref'] = XLSX.utils.encode_range(range);

  const workbook = {
    SheetNames: ['Sheet1'],
    Sheets: { Sheet1: ws },
  };

  const wbout = XLSX.write(workbook, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary',
  });
  fileSaver.saveAs(
    new Blob([_strToArrayBuffer(wbout)], {
      type: 'application/octet-stream',
    }),
    fileName + '.xlsx',
  );
}

export function gridModelToExcel(
  gridModel,
): {
  keyMapJson;
  formatter;
} {
  const keyMapJson = {};
  const formatter = {};
  gridModel.forEach(function(value): void {
    const columnName = value['name'];
    keyMapJson[columnName] = value['header'];
    if (value['formatter']) {
      formatter[columnName] = value['formatter'];
    }
  });
  return {
    keyMapJson: keyMapJson,
    formatter: formatter,
  };
}

function _processWorkbook(workbook, keyMapJson, callback) {
  const firstSheetName = workbook.SheetNames[0];
  const rowObjectArray = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName]);
  let json = JSON.stringify(rowObjectArray);
  json = json.replace(/"\s+|\s+"/gi, '"');
  $.each(keyMapJson, function(key, value) {
    json = json.replace(new RegExp('"' + (key as string) + '"', 'g'), '"' + value + '"');
  });

  callback(JSON.parse(json));
}

function _arrayBufferToStr(data: ArrayBuffer) {
  let o = '',
    l = 0;
  const w = 10240;
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(null, new Uint16Array(data.slice(l * w, l * w + w)));
  o += String.fromCharCode.apply(null, new Uint16Array(data.slice(l * w)));

  return o;
}

function _reverseKeyMapJson(json) {
  const reverseJson = {};
  $.each(json, (k, v) => {
    reverseJson[v] = k;
  });

  return reverseJson;
}

export function readExcelFile(file: File, keyMapJson: { [key: string]: any }, callBack) {
  try {
    const reverseKeyMapJson = _reverseKeyMapJson(keyMapJson);
    const isUsableReadAsBinaryString = typeof FileReader.prototype.readAsBinaryString !== 'undefined';
    const reader = new FileReader();
    reader.onload = e => {
      const data = (e.target as FileReader).result;
      let workbook;
      if (isUsableReadAsBinaryString) {
        workbook = XLSX.read(data, { type: 'binary' });
      } else {
        const str = _arrayBufferToStr(data as ArrayBuffer);
        workbook = XLSX.read(btoa(str), { type: 'base64' });
      }
      _processWorkbook(workbook, reverseKeyMapJson, callBack);
    };
    if (isUsableReadAsBinaryString) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  } catch (e) {
    console.log(e);
  }
}

export function validateFileNameExtension(file: File, alertMsg: string, extensionRegExp = /\.(xlsx)$/): string {
  if (!file.name.toLowerCase().match(extensionRegExp)) {
    alert(window.$t(alertMsg));
    return '';
  }
  return file.name;
}
