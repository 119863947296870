export default Object.freeze({
  TAB_CONFIG: '사용설정',
  TAB_TEMPLATE: '자동 메일 설정',
  TAB_HISTORY: '메일 발송내역 조회',

  // config
  CONFIG_NOTICE_1:
    '자동 메일 발송 기능을 사용하시려면, 아래 항목을 사용함으로 설정해주세요. 사용함 설정 시 자동발송 됩니다.',
  CONFIG_NOTICE_2:
    '고객센터 이메일 또는 발송전용 이메일 주소는 고객에게 발송되는 발신자 메일 주소로 사용됩니다. 미등록 시 자동 메일 발송 기능을 사용할 수 없습니다.',
  EMAIL_CONFIG: '이메일 사용설정',
  USED: '사용함',
  USE: '사용',
  NOT_USE: '사용 안 함',
  SEND_EMAIL: '발신 이메일 주소',
  SERVICE_CENTER_EMAIL: '고객센터 이메일',
  SERVICE_CENTER_DESCRIPTION_1: '고객센터 메일주소 등록 및 변경은',
  SERVICE_CENTER_DESCRIPTION_2: '[설정 > 기본 정책 > 쇼핑몰 관리]',
  SERVICE_CENTER_DESCRIPTION_3: '에서 가능합니다.',
  SEND_ONLY_EMAIL: '발송전용 이메일',
  SAVE_SUCCESS: '저장되었습니다.',
  NO_OBEMAIL_ALERT: '발송전용 이메일 주소를 입력하세요.',
  NO_AUTH_ALERT: '해당 메뉴의 쓰기 권한이 없습니다.',
  FAIL_TO_SEND_AUTH_NUMBER: '인증번호 발송에 실패하였습니다. 잠시 후 다시 시도해주세요.',

  // template
  TEMPLATE_NOTICE_1: '자동 메일 발송을 설정할 수 있습니다.',
  TEMPLATE_NOTICE_2: '자동 메일 발송은 사용함/사용안함으로 발송 여부를 설정할 수 있습니다.',
  TEMPLATE_NOTICE_3:
    '\'개인정보 이용내역 안내\' 템플릿을 사용하실 경우 매월 1일마다 통지 대상에게 안내가 발송됩니다. <a class="terms_tooltip">더보기</a>',
  TEMPLATE_NOTICE_4:
    '\'광고성 정보 수신현황안내\' 템플릿을 사용하실 경우 매월 1일 통지 대상에게 안내가 발송됩니다. <a class="ad_sms_tooltip">더보기</a>',
  TEMPLATE_NOTICE_5:
    '관계 법령에 따라 정보주체 외 개인정보 수집(간편 로그인/가입, 회원연동) 시 수집출처 등을 안내하여야 합니다. 상세 내용은 <a class="oauth_type_button">[여기]</a>에서 확인 가능합니다.',
  AUTO_MAIL_TYPE: '자동 메일 유형',
  RESET_TEMPLATE: '기본 템플릿 복원',
  REPLACEMENT_TEXT: '치환 텍스트 보기',
  SEND_TEST: '테스트 전송',
  TEMPLATE_GROUP: '템플릿 구분',
  FORM_SENDER: '발송 이메일 설정',
  TITLE: '메일 제목',
  TITLE_PLACEHOLDER: '메일 제목을 입력하세요.',
  CONTENT_PLACEHOLDER: '메일 본문을 입력하세요.',
  TITLE_ALERT: '메일 제목을 입력해 주세요.',
  CONTENT_ALERT: '메일 템플릿을 입력해 주세요.',
  MUST_EMAIL: '사용설정에서 발신이메일 주소를 먼저 등록해주세요.',
  MUST_SERVICE_EMAIL: '고객센터 이메일 주소를 등록해주세요.',
  NOT_USE_ALERT: '이메일이 ‘사용 안 함’상태입니다. 사용설정 후 다시 시도해주세요.',
  TEMPLATE_RESET_CONFIRM: '현재 작성된 내용을 삭제하고\n샵바이프로에서 기본 제공하는 템플릿으로 복구하시겠습니까?',
  ALERT_UNSAVED_TEMPLATE: '템플릿 변경 시 수정사항은 저장되지 않습니다. 템플릿을 변경하시겠습니까?',
  SEND_FAIL: '발송이 실패되었습니다.',
  SEND_SUCCESS: '{0} 운영자님의 이메일 주소로 테스트 전송이 완료되었습니다.',
  INTERCHANGE_GUIDE:
    'e-mail 발송 내용 설정 시 아래 치환텍스트를 이용하여 e-mail 수신자에게 알맞은 내용을 전송할 수 있습니다.',
  TITLE_AD: '광고',
  UNSUBSCRIBE_PHRASE: '수신거부 문구',
  UNSUBSCRIBE_NOTICE:
    '광고성 메일의 경우, 수신자가 메일을 수신 거부할 수 있는 기능이 메일 하단에 아래와 같이 추가됩니다.',
  UNSUBSCRIBE_EXAMPLE_1_1: '메일 수신을 원치 않으시면',
  UNSUBSCRIBE_EXAMPLE_1_2: '[수신거부]',
  UNSUBSCRIBE_EXAMPLE_1_3: '를 클릭하세요.',
  UNSUBSCRIBE_EXAMPLE_2_1: 'If you do not want to receive it, please click a',
  UNSUBSCRIBE_EXAMPLE_2_2: '[Unsubscription]',

  // history
  HISTORY_TITLE: '메일제목',
  SEND_TARGET: '발송대상',
  SEND_STATUS: '발송상태',
  SEND_REQUEST_DATETIME: '발송요청일시',
  COMPLETE: '발송성공',
  FAIL: '발송실패',
  READY: '결과수신대기',
  PROGRESS: '발송대기',
  SEND_ING: '발송중',
  PERIOD_MAX_LIMIT: '조회기간은 최대 1개월까지 가능합니다.',
  DATE_MAX_LIMIT: '조회기간은 최대 3년 이전까지의 내역만 조회가 가능합니다.',

  // history popup
  DETAIL_TITLE: '메일 발송내용 상세보기',
  NOT_OPEN: '인증번호, 비밀번호가 포함된 메일은 상세보기가 불가합니다.',
  CERTIFICATION_NUMBER: '인증번호',
  PASSWORD: '비밀번호',

  // 정보주체 외 개인정보 수집 시 알림 발송 가이드 EmailSendAdNotice
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_TITLE: '정보주체 외 개인정보 수집 시 알림 발송 가이드',
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_1: '개인정보보호법',
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_2: '제20조 2항',
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_3:
    '에 따라 아래 기준에 해당하는 의무대상자의 경우 정보주체 외 개인정보 수집 시 개인정보의 수짐 출처, 처리목적, 처리정지 요구에 대한 권리가 있다는 사실을 정보주체에게 고지하여야합니다. <br />' +
    '- 5만 명 이상의 정보주체에 관하여 민감정보 또는 고유식별정보를 처리하는 자 <br />' +
    '- 100만 명 이상의 정보주체에 관하여 개인정보를 처리하는 자',
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_4:
    "간편 로그인 서비스를 통해 회원 가입 시 '정보주체 외 개인정보를 수집'한 경우에 해당하며, 의무대상자의 경우 관련 <br />" +
    '법령에 따라 수집 출처등을 고지하여야 합니다. <br />' +
    '위반 시 개인정보보호법',
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_5:
    '에 따라 3천만 원 이하의 과태료가 부과될 수 있습니다. <br />' +
    '필요한 조치를 취하지 않아 발생하는 불이익에 대하여 당사는 책임을 지지 않습니다.',
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_6:
    '간편 로그인 서비스를 이용 중이며, 의무대상에 해당할 경우 아래 자동발송 항목을 사용하여 수집 출처 등에 대한 고지가 가능합니다.',
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_7:
    "1. (SMS/이메일) '회원가입 시 정보출처 고지' <br />" +
    "- 해당 항목을 '사용함'으로 설정한 경우, 간편 로그인을 통해 회원가입 시 회원가입 시점에 알림 발송",
  NOTICE_TO_COLLECT_PERSONAL_INFORMATION_CONTENT_8:
    "2. (이메일) '개인정보이용내역안내 (정보주체 외 개인정보 수집 시)' <br />" +
    "- 해당 항목을 '사용함'으로 설정한 경우, 간편 <br />" +
    '로그인을 통해 가입한 회원의 개인정보이용내역 안내 주기(11개월)가 도래할 경우 수집출처등에 대한 고지항목이 포함된 템플릿으로 발송 <br />' +
    "- 간편 로그인을 통해 가입하지 않은 일반 쇼핑몰 회원에 대한 개인정보이용내역 알림 발송은 별도 항목('개인정보이용내역안내')으로 제공",
});
