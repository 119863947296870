export default Object.freeze({
  // PRODUCT.CATEGORIZATION.
  ADD: '카테고리 추가',
  ADD_DEPTH: '{depth}차 카테고리 추가',
  NEW: '신규 카테고리',
  STANDARD: '표준카테고리',
  OPEN_ALL: '전체 열기',
  CLOSE_ALL: '전체 닫기',
  CATEGORY_SETTING: '전시카테고리 설정',
  PATH: '카테고리 경로',
  NUMBER: '카테고리 번호',
  URL: '카테고리 URL',
  NAME: '카테고리명',
  DISPLAY_OPTION: '노출여부',
  PRODUCT_AMOUNT: '등록된 상품 수',
  DISPLAY: '노출함',
  UN_DISPLAY: '노출 안 함',

  CONFIRM_UN_DISPLAY_WARN: '하위 카테고리가 모두 노출 안 함으로 설정됩니다.\n설정 하시겠습니까?',

  ALERT_TREE_BEFORE_MOVE: '해당 카테고리에 등록된 상품이 있어,\n하위 카테고리를 추가할 수 없습니다.',
  ALERT_EMPTY_NUMBER: '카테고리 저장시 번호가 할당 됩니다.',
  ALERT_EMPTY_URL: '카테고리 저장시 URL이 생성 됩니다.',
  ALERT_DELETE_FAIL: '해당 카테고리는 삭제가 불가합니다.\n상품의 전시카테고리를 변경 후 다시 시도해주세요.',

  INFORMATION:
    '전시카테고리 설정은 최종적으로 [변경사항 저장] 버튼을 클릭 후 반영됩니다.\n' +
    '변경사항을 저장하지 않고 다른 메뉴로 이동 시 설정한 내용은 반영되지 않으니 반드시 저장하시길 바랍니다.\n' +
    '카테고리 엑셀 일괄등록은 신규 등록만 가능하며, 카테고리 일괄 수정/삭제 기능은 제공하지 않습니다.\n' +
    '일괄등록 샘플 다운로드에 제공되는 가이드를 참고하여 입력 후 엑셀 일괄 등록하시길 바랍니다.\n' +
    '엑셀 다운로드 시 전체 카테고리를 다운로드 할 수 있습니다.',

  SAMPLE_FILE_NAME: '전시카테고리 엑셀 업로드 양식',

  BULK_REGISTRATION_TITLE: '전시카테고리 일괄등록',

  BULK_REGISTRATION_INFORMATION:
    '일괄등록용 엑셀 다운로드 후 등록이 필요한 카테고리 정보를 입력 후 업로드하세요.\n' +
    '일괄 작성된 전시카테고리 정보는 업로드 완료 시 즉시 저장됩니다.\n' +
    '카테고리는 신규 등록만 가능하며, 카테고리 일괄 수정/삭제 기능은 제공하지 않습니다.\n' +
    '일괄등록용 엑셀 다운로드 파일에서 항목을 변경하는 경우 파일이 정상적으로 업로드되지 않습니다.',

  BULK_REGISTRATION_FAIL_RESULT_FILE_NAME: '전시카테고리_실패결과',
});
