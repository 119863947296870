export default Object.freeze({
  // TAB MENU
  CONFIG_MENU: '알림톡 사용설정',
  TEMPLATE_MENU: '알림톡 템플릿 관리',
  HISTORY_MENU: '알림톡 발송내역 조회',
  BASE_CONFIG: '알림톡 기본 설정',
  PLUS_FRIEND_ID: '플러스친구 아이디',
  REGISTER_PLUS_FRIEND: '플러스친구 등록',
  DELETE_ID: '아이디 삭제',
  MUST_REGISTER_PLUS_ID: '플러스친구 아이디를 등록해주세요.',
  NO_TEMPLATE: '선택된 템플릿이 없습니다.',
  FIRST_REGISTER_PLUS_ID: '알림톡 사용설정에서 카카오톡 플러스친구 아이디를 먼저 등록해주세요.',
  DELETE_CONFIRM_MESSAGE: '플러스친구 아이디를 삭제하면 카카오 알림톡을 사용할 수 없습니다. \n계속하시겠습니까?',
  SMS_NOT_USED: 'SMS 발송을 사용하셔야 알림톡 발송을 사용할 수 있습니다.',
  NO_SMS_SEND_NO: 'SMS 발신번호를 등록하셔야 알림톡 발송을 사용할 수 있습니다.',
  RESET_CONFIG: '플러스 설정이 초기화 되었습니다.',
  SEND_PROFILE_KEY: '발신프로필키',
  SENDER_PROFILE_REGISTER: '발신프로필 등록 후 설정 가능합니다.',
  USE_NOTICE_TALK: '알림톡 사용 여부',
  NOTICE_TALK_TEMPLATE: '알림톡 템플릿 설정',
  TEMPLATE_NOTICE_1:
    'SMS발송 기능을 함께 사용하실 경우, 동일한 발송항목을 SMS, 알림톡 모두 사용함으로 설정하더라도 알림톡이 우선으로 발송됩니다.<br>단, 알림톡 발송 실패 시 알림톡 템플릿에 저장된 내용으로 SMS/LMS 대체 발송됩니다.',
  TEMPLATE_NOTICE_2:
    'ex) ‘회원가입완료’ 항목이 SMS:사용함, 알림톡:사용함 일 경우 알림톡만 발송. 단, 알림톡 발송 실패 시 알림톡 템플릿에 저장된 내용으로 SMS/LMS 발송<br><br>',
  TEMPLATE_NOTICE_3:
    '자동으로 발송되는 알림톡을 설정할 수 있으며, 메시지별로 사용함/사용안함으로 발송 여부를 설정할 수 있습니다.',
  TEMPLATE_NOTICE_4:
    '템플릿은 ‘알림톡 템플릿 관리’에서 등록 후 카카오에서 검수를 진행하며, 승인 받은 템플릿만 선택하여 발송할 수 있습니다.',
  TEMPLATE_NOTICE_5: '자동 알림톡 발송 실패 시, 알림톡 템플릿에 저장된 내용으로 SMS/LMS 대체 발송됩니다.',
  TEMPLATE_NOTICE_6:
    '회원 관련 > 적립금소멸안내 항목 사용 시 적립금 지급에 대한 내용이 약관 등에 포함되어 고객에게 고지가 되어야 합니다. <a class="notice_button">주의사항 확인하기</a>',
  TEMPLATE_NOTICE_7:
    '관계 법령에 따라 정보주체 외 개인정보 수집(간편 로그인) 시 수집출처 등을 안내하여야 합니다. 상세 내용은 <a class="oauth_type_button">[여기]</a>에서 확인 가능합니다.',
  TEMPLATE_PRECAUTIONS_TITLE: '적립금소멸안내 발송항목 사용 시 주의사항',
  TEMPLATE_PRECAUTIONS_1:
    '· 알림톡 정책에 따라 회원가입 시, 적립금 지급에 대한 내용이 약관 등에 포함되어 고객에게 고지가 되었고 이에 동의한 경우에만 고객의 특정 행동에 의해 지급된 적립금에 대한 소멸 안내를 알림톡으로 발송할 수 있습니다.',
  TEMPLATE_PRECAUTIONS_2:
    '· 적립금소멸안내 항목 사용함 설정 시 약관 내용에 적립금 지급에 대한 내용이 포함되어 있는지 반드시 확인해주시기 바랍니다.',
  TEMPLATE_PRECAUTIONS_3:
    '· 적립금 지급에 대한 고객 고지없이 적립금 소멸에 대한 안내가 알림톡으로 발송될 경우 발송되는 메시지의 책임은 발송자에게 있으며, 이후 어뷰징 확인 또는 신고가 다수 접수될 경우 카카오 알림톡 정책 상 해당 프로필에 대한 차단이 이루어질 수 있습니다.',
  TEMPLATE_PRECAUTIONS_4:
    '· 프로필이 차단될 경우, 해당 프로필로 등록된 사업자등록번호로는 영구적으로 알림톡 사용이 불가하오니 유의하여 주시기 바랍니다. 자세한 사항은 카카오로 문의해주시기 바랍니다.',

  // 알림톡 템플릿 관리
  TEMPLATE_CONFIG_NOTICE_1: '알림톡 템플릿을 등록하고, 카카오의 템플릿 검수상태를 조회할 수 있습니다.',
  TEMPLATE_CONFIG_NOTICE_2:
    '템플릿은 등록 요청/검수 중/승인/반려 4가지 상태로 표시되며, ‘승인’ 상태인 템플릿만 알림톡 설정에서 선택이 가능합니다.',
  REGISTER_DATETIME: '등록일시',
  TEMPLATE_TYPE: '템플릿 유형',
  TEMPLATE_STATUE: '템플릿 상태',
  TEMPLATE_USED: '템플릿 적용여부',
  SEARCH_ALL: '통합검색',
  SEARCH_CODE: '템플릿 코드',
  SEARCH_TITLE: '템플릿명',
  SEARCH_CONTENT: '템플릿 내용',
  TEMPLATE_GROUP_MEMBER: '회원',
  TEMPLATE_GROUP_ORDER_DELIVERY: '주문배송',
  TEMPLATE_GROUP_INQUIRY: '문의',
  TEMPLATE_GROUP_ADMIN: '운영자 안내',
  STATUS_REGISTER: '등록',
  STATUS_REQUEST: '검수요청',
  STATUS_APPROVE: '승인',
  STATUS_REJECT: '반려',
  APPLY: '적용중',
  NOT_APPLY: '적용 안 함',
  REGISTER_TEMPLATE: '템플릿 등록',
  APPLICABLE_OR_NOT: '적용여부',
  REGISTER_DATE: '등록일',
  DETAIL_SETTING: '알림톡 발송 상세설정',
  PERIOD_MAX_LIMIT: '조회기간은 최대 1년까지 가능합니다.',

  // 알림톡 발송내역 조회
  SEND_DATE: '발송요청 일시',
  SEND_STATUS: '발송상태',
  RECIPIENT_NO: '수신번호',
  SUCCESS: '발송성공',
  FAIL: '발송실패',
  ALERT_MAX_DATE: '최대 3년 이전의 발송 내역만 조회 가능합니다.',

  // 플러스친구 등록 팝업
  REGISTER_PLUS_ID: '플러스친구 아이디 등록',
  TOOLTIP_BUSINESS_CENTER:
    '검색용 아이디는 <a href="https://business.kakao.com/dashboard/?sid%3Dpfr%26redirect%3Dhttps%3A%2F%2Fcenter-pf.kakao.com%2Fprofiles" target="_blank">\'플러스친구 관리자센터 &gt; 관리 &gt; 상세설정\'</a>에서 확인 가능합니다.<br/>' +
    '플러스친구 아이디는 반드시 홈 공개로 설정하고 비즈니스 인증을 받은 아이디만 등록이 가능합니다.',
  TOOLTIP_BUSINESS_CATEGORY:
    '관리자 연락처는 등록하고자 하는 플러스친구 아이디 채널에 등록된 관리자의 휴대폰번호로만 인증이 가능합니다.<br />' +
    '관리자 정보는 <a href="https://business.kakao.com/dashboard/?sid%3Dpfr%26redirect%3Dhttps%3A%2F%2Fcenter-pf.kakao.com%2Fprofiles" target="_blank" title="새창 열기">\'플러스친구 관리자센터 &gt; 관리 &gt; 관리자\'</a >에서 확인 가능합니다.',
  PLUS_ID_PLACEHOLDER: '플러스친구 검색용 ID를 입력해주세요.',
  ADMIN_PHONE_NO_PLACEHOLDER: '"-"를 제외한 관리자 휴대폰 번호를 입력해주세요.',
  AUTH_NO_PLACEHOLDER: '카카오톡으로 발송된 인증번호를 입력해주세요.',
  BUSINESS_CATEGORY: '사업자 카테고리',
  ADMIN_CONTACTS: '관리자 연락처',
  SEND_AUTH_NO: '인증번호 발송',
  TERMS_TITLE: '개인정보 제3자 제공동의',
  TERMS_CONTENT: `1. 제공받는자 : (주)써머스플랫폼
2. 이용목적 : 카카오알림톡 서비스 신청 및 관리
3. 제공항목 : 카카오톡채널(플러스친구 아이디), 휴대폰번호, 본문내용
4. 보유 및 이용기간 : 서비스 해지 후 파기`,
  AGREE_PERSONAL_INFO: '(필수)개인정보 제3자 제공에 동의합니다.',
  FETCH_CATEGORY_ERROR: '처리중에 오류가 발생하여 실패되었습니다.\n- 실패사유 : {0}',
  MAIN_CATEGORY: '대분류',
  MIDDLE_CATEGORY: '중분류',
  SUB_CATEGORY: '소분류',
  ALERT_ENTER_PLUS_ID: '플러스친구ID를 입력해주세요.',
  ALERT_SELECT_BUSINESS_CATEGORY: '사업자 카테고리를 선택해주세요.',
  ALERT_INPUT_ADMIN_PHONE_NUMBER: '플러스친구 관리자 휴대폰 번호를 입력해주세요.',
  ALERT_CHECK_NUMBER_ALARM: '요청한 번호가 카카오톡 채널 관리자 알림 설정 되어있는지 확인해주세요.',
  ALERT_INPUT_AUTH_NUMBER: '관리자 카카오톡으로 발송된 인증번호를 입력해주세요.',
  ALERT_INVALID_AUTH_NUMBER: '올바른 인증번호가 아닙니다.',
  ALERT_CERTIFICATION_FIRST: '요청한 번호가 카카오톡 채널 관리자 알림 설정 되어있는지 확인해주세요.',
  ALERT_MUST_AGREE: '개인정보 제3자 제공에 동의해주세요.',
  ALERT_INVALID_PLUS_ID: '카카오 플러스친구 아이디 등록 시 비즈니스 인증이 필요합니다. \n인증 후 다시 시도해주세요.',
  ALERT_INVALID_CATEGORY: '최초에 등록하신 카테고리코드로 등록하셔야합니다. ({category})',
  SUCCESS_SEND_AUTH: '카카오톡으로 인증번호가 발송되었습니다.',
  SUCCESS_REGISTER_PLUS_ID: '플러스친구가 등록되었습니다.',

  // 알림톡 등록 팝업
  TEMPLATE_REGISTER_TITLE: '알림톡 템플릿 등록',
  TEMPLATE_CATEGORY_CONFIG: '템플릿 카테고리 설정',
  TEMPLATE_NAME_PLACEHOLDER: '템플릿명을 입력해주세요. (최대 20자)',
  TEMPLATE_SECURITY: '보안 템플릿',
  USE_TEMPLATE_SECURITY: '보안 템플릿 사용',
  INSPECT_RESULT: '검수 결과',
  INSPECT_REFER: '기타 참고사항',
  SECURITY_PRECAUTION: '· 템플릿 내용에 인증번호, 비밀번호 등의 보안정보가 포함된 경우, 반드시 사용해야 합니다.',
  SECURITY_TOOLTIP:
    '아래 정보가 포함된 템플릿은 반드시 보안템플릿을 사용해야 합니다.\n- 인증번호\n- 비밀번호 (재설정, 임시 비밀번호 등)\n' +
    '보안 템플릿 사용을 체크한 템플릿은 메인 디바이스 모바일 외 모든 서브 디바이스에서는 메시지 내용이 노출되지 않으며 “모바일에서 확인해주세요”로만 보여집니다.',
  TEMPLATE_BUTTON_CONFIG: '버튼 설정',
  REGISTER_CONTENT_PLACEHOLDER: '템플릿 내용을 입력해주세요. (최대 1,000자)',
  REGISTER_REFER_PLACEHOLDER: '알림톡 템플릿 검수 담당자에게 전달할 기타 참고사항을 입력해주세요.',
  PREVIEW: '미리보기',
  EDIT: '편집하기',
  GROUP_CATEGORY_SELECT: '그룹 카테고리 선택',
  CATEGORY_SELECT: '카테고리 선택',
  BUTTON_NO: 'No.',
  BUTTON_TYPE: '타입',
  BUTTON_NAME: '버튼명',
  BUTTON_LINK: '버튼 링크',
  WEB_LINK: '웹 링크',
  DELIVERY_SERVICE: '배송조회',
  AUTHOR: '작성자',
  CREATE_TIME: '등록시간',
  STATUS: '상태',
  CONTENT: '내용',
  INSPECT: '[템플릿 검수결과 문의]',
  INSPECT_BUTTON: '문의하기',
  ETC: '기타',
  BUTTON_NAME_PLACEHOLDER: '버튼명을 입력해주세요. (최대 14자)',
  BUTTON_LINK_PLACEHOLDER: '[http://, https://]를 포함한 URL',
  INSPECT_PLACEHOLDER: '알림톡 템플릿 검수 담당자에게 문의 및 전달할 내용을 입력해주세요.',
  BUTTON_DESCRIPTION_1: '알림톡 내용에 택배사와 송장번호가 있을 경우 카카오 배송조회 페이지 링크로 제공됩니다.',
  BUTTON_DESCRIPTION_2: '카카오 배송조회 페이지에서 지원되지 않는 택배사는 배송조회가 되지 않을 수 있습니다.',
  BUTTON_DESCRIPTION_3:
    '지원 택배사 : 우체국, 한진택배, GTX로지스, 롯데택배, KGB택배, 로젠택배, 천일택배, 경동택배, 일양로지스,FEDEX, 합동택배, 농협택배, 호남택배, CU편의점 택배, CVSnet편의점택배, TNT ,  DHL, 대신택배, 건영택배, 한덱스, 굿투럭',
  ALERT_BUTTON_MAX: '버튼은 최대 5개까지만 추가할 수 있습니다.',
  ALERT_TEMPLATE_NAME: '템플릿명을 입력해주세요.',
  ALERT_TEMPLATE_CONTENT: '템플릿 내용을 입력해주세요.',
  ALERT_BUTTON_NAME: '버튼명을 입력해주세요.',
  ALERT_BUTTON_CONTENT: '버튼 링크를 입력주세요.',
  ALERT_TEMPLATE_CODE: '템플릿은 템플릿 유형별 최대 9999개 까지만 등록이 가능합니다.',
  ALERT_INQUIRY_CONTENT: '문의 및 전달할 내용을 입력해주세요.',
  ALERT_SUCCESS: '템플릿 등록을 요청하였습니다.\n카카오에서 템플릿을 검수하며, 승인된 템플릿만 사용이 가능합니다.',
  ALERT_SUCCESS_INQUIRY:
    '템플릿 수정을 요청하였습니다.\n카카오에서 템플릿을 검수하며, 승인된 템플릿만 사용이 가능합니다.',
  ALERT_USING_TEMPLATE:
    '사용중인 템플릿은 삭제할 수 없습니다.\n' +
    '\n' +
    '다른 템플릿을 선택하여 저장 후 해당 템플릿을 삭제하시거나\n' +
    '‘사용 안 함’ 설정 후 삭제해주시기 바랍니다.',
  ALERT_DEFAULT_TEMPLATE: '기본 제공 템플릿은 삭제할 수 없습니다.',
  CONFIRM_INSPECT: '검수 요청을 진행하시겠습니까?',
  CONFIRM_DELETE: '삭제 후 템플릿을 복구할 수 없습니다.\n해당 템플릿을 삭제하시겠습니까?',
  SUCCESS_DELETE_TEMPLATE: '템플릿이 삭제되었습니다.',
  SUCCESS_MODIFY_TEMPLATE:
    '템플릿 수정을 요청하였습니다.\n' + '카카오에서 템플릿을 검수하며, 승인된 템플릿만 사용이 가능합니다.',
  SUCCESS_INSPECT_TEMPLATE:
    '템플릿 검수요청을 하였습니다.\n' + '카카오에서 템플릿을 검수하며, 승인된 템플릿만 사용이 가능합니다.',

  // 치환 텍스트
  MALL_NAME: '쇼핑몰명',
  DOMAIN: '쇼핑몰대표도메인',
  NAME: '회원명',
  MEMBER_ID: '회원아이디',
  TEMP_PASSWORD: '임시비밀번호',
  TEL: '고객센터전화번호',
  BAN_START_DATE: '이용정지시작일',
  BAN_END_DATE: '이용정지종료일',
  INACTIVE_DATE: '휴면회원처리예정일',
  OAUTH_TYPE: '개인정보 수집출처',
  SMS_AGREED: 'sms수신동의여부',
  SMS_AGREE_DATE: 'SMS수신동의설정 최종변경일',
  EMAIL_AGREED: '이메일수신동의여부',
  EMAIL_AGREE_DATE: '이메일수신동의일',
  GRADE_NAME: '회원등급명',
  POINT_NAME: '적립금명',
  POINT_DEL_MONTH: '소멸예정월',
  DUE_DATE: '적립금 소멸예정일',
  POINT_TOTAL: '소멸예정 적립금 합계',
  CERT_CODE: '인증번호',

  ORDER_NAME: '주문자명',
  ORDER_NO: '주문번호',
  BANK_NAME: '은행명',
  ACCOUNT_HOLDER: '예금주명',
  ACCOUNT: '결제계좌번호',
  TOTAL_PAY_AMOUNT: '적립금+실결제금액',
  SUB_PAY_AMOUNT: '적립금',
  MAIN_PAY_AMOUNT: '실결제금액',
  DEPOSIT_DATE: '입금기한',
  DEPOSIT_DATE_SHORT: '입금기한 축약형',
  DEPOSIT_DATE_NO_YEAR: '입금기한축약형(년도제외)',
  PRODUCT: '주문대표상품명',
  PRODUCT_COUNT: '상품수',
  DELIVERY_PRODUCT: '배송대표상품명',
  DELIVERY_COM: '택배사명',
  DELIVERY_NUM: '송장번호',
  RETURN_PRODUCT: '반품대표상품명',
  EXCHANGE_PRODUCT: '교환반품대표상품명',

  REGISTER: '등록',
  REQUEST: '검수 요청',
  APPROVE: '승인',
  REJECT: '반려',

  APPLIED: '적용중',
  NOT_APPLIED: '적용 안 함',

  STOP: '중단',
  NORMAL: '정상',
  WAIT: '대기(발송전)',

  KEYWORDS_TYPE_CONTENT: '내용',
  KEYWORDS_TYPE_PHONE: '수신번호',
  DORMANT_TOOLTIP:
    "• 휴면회원 기능을 '사용함'으로 설정 시 휴면처리에 대한 사전안내를 발송할 수 있습니다. \n• 휴면회원 기능을 '사용 안 함'으로 설정 시 SMS/알림톡/이메일 사용설정 여부와 상관없이 휴면전환사전안내 알림 발송이 되지 않습니다. ",
});
