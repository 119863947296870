import { SecondAuthData } from '@/const/contents/auth';

export enum SECURITY_AUTH_MODE {
  AUTH = 'AUTH',
  OTP_SETTING = 'OTP_SETTING',
}

export enum OTP_MODE {
  NONE = 'NONE',
  QR = 'QR',
  KEY = 'KEY',
  AUTH_KEY = 'AUTH_KEY',
}

export const getDefaultSecondAuthData = (): SecondAuthData => {
  return {
    key: '',
    plan: '',
    target: {
      email: '',
      enableOtp: false,
      mobile: '',
    },
    type: '',
    types: [],
  };
};
