







































































































import { Vue, Component, Watch, PropSync, Prop, Ref } from 'vue-property-decorator';
import { getDefaultQuery, getShippingChargeSelectType } from '@/const/contents/configuration/shippingCharge';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import {
  DeliveryTemplate,
  GetDeliveryTemplateSingleRequest,
} from 'ncp-api-supporter/dist/types/modules/delivery/deliveryTemplate';
import { Warehouse } from 'ncp-api-supporter/dist/types/modules/delivery/warehouses';
import { DEFAULT_DATE_RANGE } from '@/components/common/datepicker/dateRange';
import { DeliveryTemplateSingleQuery } from '@/types/delivery';

@Component({
  components: { DateRangePicker, SearchButton, TextInput, SelectBox },
})
export default class ShippingChargeSearchForm extends Vue {
  @PropSync('query', { required: true })
  private querySync: DeliveryTemplateSingleQuery;

  @PropSync('contents', { required: true })
  private contentsSync: DeliveryTemplate[];

  @PropSync('totalCount', { required: true })
  private totalCountSync: number;

  @Prop({ required: true })
  private readonly i18nForDeliveryGroupTemplate: (word: string, data?: string, useDefault?: boolean) => string;

  @Ref()
  private readonly searchButton: SearchButton;
  private search() {
    this.searchButton.sendQueryUrl();
  }

  private selectType = getShippingChargeSelectType();
  private warehouses: Warehouse[] = [];

  @Watch('$route.query')
  private changeRoute(): void {
    this.getDeliveryTemplateSingle();
  }

  @Watch('warehouses')
  private makeWarehouseSelectData(): void {
    this.selectType.releaseWarehouseNo = [...getShippingChargeSelectType().releaseWarehouseNo];
    this.selectType.returnWarehouseNo = [...getShippingChargeSelectType().returnWarehouseNo];

    this.warehouses.forEach(({ name, warehouseNo }) => {
      this.selectType.releaseWarehouseNo.push({ name, value: warehouseNo });
      this.selectType.returnWarehouseNo.push({ name, value: warehouseNo });
    });
  }

  created() {
    this.getDeliveryTemplateSingle();
    this.getWarehouses();
  }

  public getDeliveryTemplateSingle(): void {
    const params = {
      ...this.querySync,
      page: Number(this.$route.query.page) || 1,
      size: Number(this.$route.query.pageSize) || 30,
      startDate: !this.querySync.startDate ? `${DEFAULT_DATE_RANGE.MIN}` : this.querySync.startDate,
      endDate: !this.querySync.endDate ? `${DEFAULT_DATE_RANGE.MAX}` : this.querySync.endDate,
    } as GetDeliveryTemplateSingleRequest['params'];

    this.$api
      .getDeliveryTemplateSingle({ params })
      .then(response => {
        if (response && response.status === 200) {
          this.contentsSync = response.data.contents;
          this.totalCountSync = response.data.totalCount;
        }
      })
      .catch();
  }

  private getWarehouses(): Promise<void> {
    return this.$api
      .getWarehouses()
      .then(response => {
        if (response && response.status === 200) {
          this.warehouses = response.data.contents;
        }
      })
      .catch();
  }

  private resetAll() {
    this.querySync = getDefaultQuery();
    this.searchButton.sendQueryUrl();
  }
}
