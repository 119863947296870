import { ClaimClassType, ClaimType } from '@/types/claim';
import { claimClassTypes, claimTypes } from '@/const/claim';
import { Amounts } from 'ncp-api-supporter';

// 클레임처리 배송비 계산기
export class DeliveryCalculator {
  private readonly claimType!: ClaimType;
  private readonly claimClassType!: ClaimClassType;
  private _amounts!: Amounts;

  constructor({
    claimType,
    claimClassType,
    amounts,
  }: {
    claimType: ClaimType;
    claimClassType: ClaimClassType;
    amounts: Amounts;
  }) {
    this.claimType = claimType;
    this.claimClassType = claimClassType;
    this.amounts = amounts;
  }

  get amounts() {
    return this._amounts;
  }

  set amounts(amounts) {
    this._amounts = amounts;
  }

  beforeDeliveryAmt = () => {
    if (this.claimClassType === claimClassTypes.ORDER_CANCEL) {
      return this.amounts.before.delivery.totalDeliveryAmt;
    }

    return this.amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
  };

  afterDeliveryAmt = () => {
    if (this.claimType === claimTypes.CANCEL) {
      if (this.claimClassType === claimClassTypes.ORDER_CANCEL) {
        return this.amounts.after.delivery.totalDeliveryAmt;
      }

      if (this.claimClassType === claimClassTypes.OPTION_CANCEL) {
        return this.amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt;
      }
    }

    if (this.claimClassType === claimClassTypes.CANCEL_EXCHANGE) {
      return this.amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt;
    }

    if (this.claimClassType === claimClassTypes.RETURN_EXCHANGE || this.claimType === claimTypes.RETURN) {
      return (
        this.amounts.after.delivery.deliveryGroupAmounts.totalDeliveryAmt +
        this.amounts.returnDelivery.processedDeliveryAmt
      );
    }

    return 0;
  };

  deliveryDifference = () => {
    const refundDeliveryAmt = this.amounts.refundDeliveryAmt;
    const prepaidProcessedDeliveryAmt = this.amounts.returnDelivery?.prepaidProcessedDeliveryAmt ?? 0;

    // 추가결제인 경우
    if (
      this.amounts.before.delivery.totalDeliveryAmt <=
      this.amounts.after.delivery.totalDeliveryAmt + prepaidProcessedDeliveryAmt
    ) {
      const difference =
        refundDeliveryAmt !== 0
          ? refundDeliveryAmt
          : this.amounts.refund.delivery.totalDeliveryAmt - prepaidProcessedDeliveryAmt;
      return this.claimType === claimTypes.EXCHANGE ? -1 * difference : difference;
    }

    // else 환불인 경우
    return this.claimType === claimTypes.EXCHANGE ? -1 * refundDeliveryAmt : refundDeliveryAmt;
  };
}
