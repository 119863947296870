export default Object.freeze({
  COMMENTS: '쇼핑몰 이용 및 탈퇴 시, 안내될 내용을 설정할 수 있습니다.',
  INFORMATION_USE: '이용안내',
  CONTENTS: '내용',
  NOT_USED: '사용 안 함',
  EDTIOR_INFO: '- 쇼핑몰 이용 시, 안내될 내용을 입력해주세요.',
  EDTIORGUIDE_INFO: '- 쇼핑몰 회원 탈퇴 시, 안내될 내용을 입력해주세요.',
  WITHDRAWAL_GUIDE: '탈퇴안내',
  USED: '사용함',
  SAVE_CHANGES: '변경사항 저장',
});
