
import { Component, Vue } from 'vue-property-decorator';
import { throwPopup } from '@/helpers/popup';

@Component
export default class OpenAdNoticePopupMixin extends Vue {
  protected registerClickEvent() {
    const button = this.$el.querySelector('.notice_button');
    const oauthTypeButton = this.$el.querySelector('.oauth_type_button');
    const termsTooltip = this.$el.querySelector('.terms_tooltip');
    const adTooltip = this.$el.querySelector('.ad_sms_tooltip');
    oauthTypeButton?.addEventListener('click', () => this.openNoticePopup('OauthTypeNotice'));
    button?.addEventListener('click', () => this.openNoticePopup('SmsSendAdNotice'));
    termsTooltip?.addEventListener('click', () => this.openNoticePopup('TermsTemplateNotice'));
    adTooltip?.addEventListener('click', () => this.openNoticePopup('AdSmsNotice'));
  }

  private openNoticePopup(name: string) {
    throwPopup({
      name,
      data: { name: this.$route.name },
    });
  }

  mounted() {
    if (!this.$el) return;
    this.registerClickEvent();
  }
}
