export default Object.freeze({
  OUT_STORE_ALERT: '배송번호 {{deliveryNo}}번은 이미 출고된 배송 건입니다.',
  ALERT_M1: '택배사 선택 후 배송번호를 입력해 주세요.',
  ALERT_M2: '배송번호 재확인 후 송장번호를 입력해 주세요.',
  ALERT_M4: '택배사 선택 후 송장번호를 입력해 주세요.',
  ALERT_M5: '존재하지 않는 배송번호입니다.',
  ALERT_M7: '주문정보에 없는 옵션 바코드 입니다.',
  ALERT_M8: '주문수량을 초과하였습니다. 수량 확인 후 출고 처리해 주세요.',
  ALERT_M10: '출고 처리 가능한 상태가 아닙니다.\n배송준비중으로 변경 후 진행해 주세요.',
  ALERT_M9:
    '동일 배송번호 중 같이 출고할 수 없는 옵션이 존재합니다.\n클레임 진행 건을 제외하고 이미출고하기 원할 경우 [확인] 클릭하여 진행해 주세요.',
  ALERT_M11: '배송안함 상품은 스캐닝 출고 처리가 불가합니다.\n개별로 출고 진행해 주세요.',
  CONFIRM_M12: '스캐닝 방식을 변경하시겠습니까?',
  ALERT_M13: '배송번호를 입력해 주세요.',
  ALERT_M14: '숫자와 영문외 다른 정보는 입력이 불가합니다. 확인 후 처리해 주세요.',
  ALERT_M15: '이전 요청한 송장번호를 저장하고 있습니다. 배송번호와 송장번호 확인 후 진행해 주세요.',
  CONFIRM_M16: '업무메시지가 포함된 주문이 있습니다. 출고 처리 하시겠습니까?',
  ALERT_NEW_M14: '숫자외 다른 정보는 입력이 불가합니다. 확인 후 처리해 주세요.',
  ALERT_M17: '배송보류 상태 입니다. 확인 후 처리해 주세요',

  PAGE_TITLE: '스캐닝 출고처리',
  VALIDATION_ALERT: '송장번호 스캔/저장 즉시 자동 출고 처리가 진행됩니다.',
  SCAN_TYPE: '스캐닝 방식',
  TYPE_INVOICE: '송장 스캐닝',
  TYPE_OPTION: '옵션 스캐닝',
  DELIVERY_COMPANY: '택배사',
  DELIVERY_NO: '배송번호',
  SEARCH: '조회',
  INVOICE_NO: '송장번호',
  KEEP: '저장',
  DEFAULT_CHECK: '설정한 스캐닝 방식과 택배사를 다음에도 사용',
  THEAD_ORDER_NAME: '주문자명',
  THEAD_RECEIVE_NAME: '수령자명',
  THEAD_ADDRESS: '배송주소',
  THEAD_OPTION_CODE: '옵션 바코드',
  THEAD_CURRENT_CNT: '처리현황',
  ENTER: '입력',
  THEAD_ORDER_NO: '주문번호',
  THEAD_PRODUCT_NO: '상품번호',
  THEAD_PRODUCT_NAME: '상품명',
  THEAD_OPTION_INFO: '옵션명:옵션값',
  THEAD_OPTION_MANNAGEMENT_CD: '옵션관리코드',
  THEAD_ORDER_CNT: '수량',
  THEAD_TASKMESSAGE_YN: '업무메시지',
  THEAD_SCANNED_CNT: '스캔 수량',
  CHOSE_COMPANIES: '택배사선택',
  CLOSE_POPUP: '닫기',
  ORDER_DETAIL: '주문상세',
});
