














































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { PayType } from 'ncp-api-supporter';

@Component
export default class NoticeBox extends Vue {
  @Prop({ required: true }) private claimType!: string;
  @Prop({ required: false, default: null }) private payType: null | PayType;
  @Prop({ default: false }) private orderStatusDepositWait;
}
