
























import { Vue, Component } from 'vue-property-decorator';
// statics
import links from '@/views/contents/partner/register/links';
// types
import { PartnerRegisterLinks } from '@/views/contents/partner/register/types';

@Component({})
export default class PartnerRegisterIndex extends Vue {
  private readonly links: PartnerRegisterLinks[] = links;
}
