// 판매 상태
export default Object.freeze({
  STATUS: '판매상태',
  SETTING_STATUS: '판매설정',

  // 동일한 결과임에도 중복되는 이유 : API 가 같은 값에 대한 상이한 KEY 를 보냄
  READY: '판매대기',
  ONSALE: '판매중',
  ON_SALE: '판매중',
  FINISHED: '판매종료',
  END_SALE: '판매종료',
  STOP: '판매중지',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION: '판매금지',
  PROHIBITION_SALE: '판매금지',
  WAITING_SALE: '판매대기',
  RESERVATION_ONSALE: '예약판매중',
  ON_PRE_SALE: '예약판매중',
  RESERVATION_FINISHED: '예약판매종료',
  PRE_APPROVAL_STATUS: '승인완료이전',

  REGULAR: '상시',
  PERIOD: '기간',
});
