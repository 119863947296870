export default Object.freeze({
  TITLE: '즉시할인 등록 가이드',
  TIP_UNDER: '정률 할인 설정 시 즉시할인금액 에서 원단위는 절하하여 적용됩니다.',
  EG: '예시)',
  SALE_INFO: '판매정보',
  USE_FIRST: '최초적용',
  AFTER: '원단위 절사 후',
  PRICE: '판매가',
  UNIT: '원',
  DISCOUNT: '즉시할인(정률%)',
  DISCOUNT_PRICE: '즉시할인금액',
  DISCOUNT_PR: '할인적용가',
});
