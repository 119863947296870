




































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { OptionData } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';
import { updateFrontDisplay } from '@/components/product/common/girdColumns/edit/UpdateFrontDisplay';

const allowsFrontDisplay: OptionData<YorN | ''>[] = [
  { value: '', name: 'PRODUCT.EDIT.SELECT_FRONT_DISPLAY' }, // 전시상태 선택
  { value: 'Y', name: 'PRODUCT.STATUS.FRONT_DISPLAY.Y' }, // 전시함
  { value: 'N', name: 'PRODUCT.STATUS.FRONT_DISPLAY.N' }, //전시 안 함
];

@Component({
  components: {
    ModalHeader,
    SelectBox,
  },
})
export default class ChangeFrontDisplay extends Vue {
  @Prop() private data!: number[]; // mallProductNos
  private readonly allowsFrontDisplay = allowsFrontDisplay;
  private selectedAllowsFrontDisplay: YorN | '' = '';

  @Ref() selectBox: SelectBox;

  get productCount(): number {
    return this.data.length;
  }

  private validation(): boolean {
    if (!this.selectedAllowsFrontDisplay) {
      alert(this.$t('PRODUCT.EDIT.ALERT_CHANGE_SALES_FRONT_DISPLAY_MISSING'));
      this.selectBox.focus();
      return false;
    }

    return true;
  }

  private onClickSubmit(): void {
    if (!this.validation()) return;

    updateFrontDisplay(this.selectedAllowsFrontDisplay as YorN, this.data).then(() => {
      this.$emit('confirm');
    });
  }
}
