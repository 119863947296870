















































































































































































import DateCalendar from '@/components/common/DateCalendar.vue';
import { Component, Ref } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { DutyCategoriesTemplate, YorN } from 'ncp-api-supporter';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import {
  ChosenDutyCategoryType,
  DateCalendarOption,
  DateModelType,
  DutyCategoryContent,
  DutyCategoryType,
  DutyContent,
  DutyInfoContent,
  dutyInfoModelType,
  DutyInformation,
  InputNumber,
  ThrowPopupPromise,
} from '@/types';
import { throwPopup } from '@/helpers/popup';
import { getToday, isDateType } from '@/utils/dateFormat';
import { DEFAULT_DATE_RANGE } from '@/components/common/datepicker/dateRange';
import { DUTY_INFO_KEY } from '@/const/contents/product';

@Component({
  components: { DateCalendar, SelectBox, TextInput, RadioGroup },
  computed: {
    isChosenProduct(): boolean {
      return this.productDuty.dutyContent.categoryNo !== 0 && this.productDuty.dutyContent.categoryNo !== '';
    },
  },
})
export default class UpsertDutyInfo extends WindowPopupMainVue {
  private dutySaveRadioOption: RadioBoxGroupOption<YorN> = {
    name: 'useYn',
    data: [
      { id: 'useN', value: 'N', display: 'PRODUCT.COMMON.NO' },
      { id: 'useY', value: 'Y', display: 'PRODUCT.COMMON.YES' },
    ],
  };

  private productDuty: DutyInformation = {
    saveTitle: '',
    useYn: 'N',
    dutyContent: {
      contents: [],
      categoryNo: '',
      categoryName: '',
      categoryDesc: '',
    },
  };

  private getToday = getToday();
  private prevDate = '';
  private getDateOption({ model, contentName }: DutyInfoContent, name: 'use' | 'not_use'): DateCalendarOption {
    if (name === 'use') {
      this.prevDate = model;
    }
    // 직접입력일 경우 이전에 선택되었던 날짜 설정 (이전 선택했던 날짜가 없을 시 당일로 초기화)
    const selectedYmd = name === 'use' ? model : this.prevDate ? this.prevDate : this.getToday;
    return {
      selectedYmd,
      name: `${contentName}_${name}`,
      fromRanges: `${DEFAULT_DATE_RANGE.MIN}`,
    };
  }

  private tempDateModel = '';
  private changedDateModelType(idx: number, type: DateModelType): void {
    this.$set(this.productDuty.dutyContent.contents[idx], 'modelType', type);
    type === 'input' ? this.resetCalendarDate(idx) : this.resetInputDate(idx);
  }

  private resetCalendarDate(idx: number): void {
    const { model } = this.productDuty.dutyContent.contents[idx];
    this.$set(this.productDuty.dutyContent.contents[idx], 'model', '');
    this.tempDateModel = model ? model : this.getToday;
  }

  private resetInputDate(idx: number): void {
    this.$set(
      this.productDuty.dutyContent.contents[idx],
      'model',
      this.tempDateModel ? this.tempDateModel : this.getToday,
    );
    this.tempDateModel = '';
  }

  private changeSelectedDateTime(selectedDate: string): void {
    const { contents } = this.productDuty.dutyContent;
    contents.forEach(content => {
      if (content.modelType && content.modelType === 'calendar') {
        content.model = selectedDate;
      }
    });
  }

  private prevCategoryNo: InputNumber = 0;
  @Ref() private readonly selectBox: SelectBox;
  private setSelectBoxValue(categoryNo: number): void {
    (this.selectBox.$el as HTMLSelectElement).value = categoryNo.toString();
  }

  private chosenDutyCategory(
    type: ChosenDutyCategoryType,
    categoryNo: InputNumber,
    contents?: DutyCategoriesTemplate,
  ): void {
    if (!this.isChangeableDutyTemplate(type)) {
      this.undoCategoryNoChanges();
      return;
    }

    this.prevCategoryNo = categoryNo;
    this.resetProductDetailReferenceMsg();

    switch (type) {
      case 'search':
        this.setSearchDutyInfo(Number(categoryNo));
        break;
      case 'edit':
        this.setEditDutyInfo(Number(categoryNo), contents);
        break;
    }
  }

  private isChangeableDutyTemplate(type: ChosenDutyCategoryType): boolean {
    const hasContent = this.productDuty.dutyContent.contents.some(content => content.model) && type !== 'edit';

    if (hasContent) {
      return confirm(this.$t('PRODUCT.DUTY.NO_SAVED_SURE').toString());
    }
    return true;
  }

  private undoCategoryNoChanges(): void {
    this.$nextTick(() => this.setSelectBoxValue(Number(this.prevCategoryNo)));
    this.productDuty.dutyContent.categoryNo = this.prevCategoryNo;
  }

  private setSearchDutyInfo(categoryNo: number): void {
    this.data.dutyCategories.forEach(
      ({ dutyCategoryNo, dutyCategoryName, dutyCategoryContents, dutyCategoryDescription }) => {
        if (dutyCategoryNo === categoryNo) {
          this.setContents({
            categoryNo,
            categoryName: dutyCategoryName,
            categoryDesc: dutyCategoryDescription,
            contents: dutyCategoryContents,
          });
        }
      },
    );
  }

  private setEditDutyInfo(categoryNo: number, contents: DutyCategoriesTemplate): void {
    const category: DutyCategoryType = this.data.dutyCategories.filter(duty => duty.dutyCategoryNo === categoryNo)[0];
    const dutyCategoryContents = category.dutyCategoryContents;
    const selectedContents = contents.dutyContent.contents;
    this.setSelectBoxValue(categoryNo);
    this.setContents({
      categoryNo: category.dutyCategoryNo,
      categoryName: category.dutyCategoryName,
      contents: dutyCategoryContents.map(content => ({
        ...content,
        descriptions: content.descriptions as string[],
        displayOrder: Number(content.displayOrder),
        model: this.getContentModel(selectedContents, content.contentName),
      })),
    });
  }

  private setContents({ categoryNo, categoryName, contents, categoryDesc }: DutyCategoryContent): void {
    this.productDuty.dutyContent.categoryNo = categoryNo;
    this.productDuty.dutyContent.categoryName = categoryName;
    this.productDuty.dutyContent.contents = contents;
    this.productDuty.dutyContent.categoryDesc = categoryDesc;
    this.setDefaultDataTypeForCalendar();
  }

  private setDefaultDataTypeForCalendar(): void {
    this.productDuty.dutyContent.contents.forEach(content => {
      if (content.dataType === 'date' && !content.modelType) {
        content.modelType = 'input';
      }
    });
  }

  private useAllProductDetailReferenceMsg = false;
  private setAllInputValueByDetailRefMsg(): void {
    const contents = this.productDuty.dutyContent.contents;
    this.productDuty.dutyContent.contents = contents.map(content => ({
      ...content,
      model: this.useAllProductDetailReferenceMsg ? this.$t('PRODUCT.DUTY.DETAIL_REFERENCE') : '',
      modelType: this.getDateModel<dutyInfoModelType>(content.dataType, 'input'),
    }));
  }

  private async openDutyInfoListPopup(): Promise<void> {
    const result: ThrowPopupPromise<DutyCategoriesTemplate> = await throwPopup({
      name: 'DutyInfoList',
      data: this.hasTemplateData(),
    });
    result.data && this.setDutyTemplate(result);
  }

  private hasTemplateData(): boolean {
    return this.productDuty.dutyContent.contents.some(content => content.model);
  }

  private setDutyTemplate({ data }: ThrowPopupPromise<DutyCategoriesTemplate>): void {
    this.resetProductDetailReferenceMsg();
    if (data.dutyContent) {
      this.chosenDutyCategory('edit', data.dutyContent.categoryNo, data);
    }
  }

  private resetProductDetailReferenceMsg(): void {
    this.useAllProductDetailReferenceMsg = false;
  }

  private getContentModel(contents, name): string {
    const foundIdx = contents.findIndex(content => Object.keys(content)[0] === name);
    if (foundIdx < 0) return;
    const value = contents[foundIdx][name];
    contents.splice(foundIdx, 1);
    return value;
  }
  private mappedContents(categoryNo, contents) {
    const { dutyCategoryContents } = this.data.dutyCategories.find(category => category.dutyCategoryNo === categoryNo);
    return dutyCategoryContents
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(content => {
        return {
          ...content,
          contentName: content.contentName,
          model: this.getContentModel(contents, content.contentName),
          modelType: this.getDateModel<dutyInfoModelType>(content.dataType, this.getModelType(content.contentName)),
        };
      });
  }

  private get dutyContentKey(): DUTY_INFO_KEY {
    return this.data.dutyInfo?.willDutyContent ? DUTY_INFO_KEY.WILL_DUTY_CONTENT : DUTY_INFO_KEY.DUTY_CONTENT;
  }
  private setDutyContents(): void {
    const { dutyInfo } = this.data;
    const { categoryNo, categoryName, contents } = dutyInfo[this.dutyContentKey] as DutyContent;
    const originContents = [...contents];

    this.productDuty = {
      saveTitle: dutyInfo.saveTitle,
      useYn: dutyInfo.useYn,
      dutyContent: {
        categoryNo,
        categoryName,
        contents: this.mappedContents(categoryNo, originContents),
      },
    };
  }

  private getDateModel<DateModel>(dataType: DutyInfoContent['dataType'], model: DateModel): DateModel {
    return dataType === 'date' ? model : null;
  }

  private getModelType(model: string): DateModelType {
    if (isDateType(model)) {
      return 'calendar';
    }
    return 'input';
  }

  private getMappedProductDuty(dutyInfo: DutyInformation): DutyInformation {
    dutyInfo.dutyContent.contents = this.productDuty.dutyContent.contents.map((content, idx) => {
      const value = Object.values(this.productDuty.dutyContent.contents[idx])[0];
      return {
        ...content,
        modelType: this.getDateModel<dutyInfoModelType>(content.dataType, this.getModelType(value)),
      };
    });
    return dutyInfo;
  }

  private onSave(): void {
    this.resetError();
    this.verifyDutyContents();
    Object.keys(this.error).length < 1 && this.onPositiveClick(this.getMappedProductDuty({ ...this.productDuty }));
  }

  private error = {} as { [key: string]: boolean };
  private resetError(): void {
    this.error = {};
  }

  @Ref()
  private readonly saveTitleRef: TextInput;
  private saveTitleYn = 'N';
  private verifyDutyContents(): void {
    const { contents } = this.productDuty.dutyContent;
    for (const idx in contents) {
      if (!contents[idx].model) {
        this.error[contents[idx].contentName] = true;
        (this.$refs[`${contents[idx].contentName}_ref`] as TextInput)[0].focus();
        return;
      }
    }

    if (this.saveTitleYn === 'Y') {
      if (!this.productDuty.saveTitle) {
        this.error.saveTitle = true;
        // 기획 확인이 필요한 메시지
        alert('상품정보제공고시 등록 시 제목을 입력해주세요.');
        this.saveTitleRef.focus();
      }
    }
  }

  created(): void {
    this.saveTitleYn = this.data.dutyInfo.saveTitle === '' ? 'N' : 'Y';
    this.data.dutyInfo.dutyContent.categoryNo > 0 && this.setDutyContents();
  }
}
