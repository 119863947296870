

















































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import DateCalendar from '@/components/common/DateCalendar.vue';
import { DateCalendarOption } from '@/types';
import { DEFAULT_DATE_RANGE } from '@/components/common/datepicker/dateRange';

enum ProductLimitDate {
  manufacture = 'manufacture',
  expiration = 'expiration',
}

@Component({
  components: { DateCalendar },
})
export default class ManufactureInfo extends Vue {
  @PropSync('manufactureYmdt')
  private manufactureYmdtSync!: string;
  @PropSync('expirationYmdt')
  private expirationYmdtSync!: string;
  @Prop({ required: true })
  private readonly disabled: boolean;

  private limit = ProductLimitDate;

  private manufactureDateReset = false;
  private expirationDateReset = false;
  private resetDate(type: ProductLimitDate): void {
    this[`${type}YmdtSync`] = '';
    this[`${type}DateReset`] = true;
  }
  private changeSelectedDateTime(type: ProductLimitDate, date: string): void {
    this[`${type}YmdtSync`] = date;
    this[`${type}DateReset`] = false;
  }

  private manufactureDateOption: DateCalendarOption = {
    selectedYmd: '',
    name: this.limit.manufacture,
    fromRanges: `${DEFAULT_DATE_RANGE.MIN}`,
  };
  private expirationDateOption: DateCalendarOption = {
    selectedYmd: '',
    name: this.limit.expiration,
    fromRanges: `${DEFAULT_DATE_RANGE.MIN}`,
  };

  @Watch('manufactureYmdt')
  private initManufactureYmdt() {
    // if (this.manufactureDateReset) return;
    this.manufactureDateOption.selectedYmd = this.manufactureYmdtSync;
  }

  @Watch('expirationYmdt')
  private initExpirationYmdt() {
    // if (this.expirationDateReset) return;
    this.expirationDateOption.selectedYmd = this.expirationYmdtSync;
  }
}
