import { ChangeDisplayOrderMsgOption, DefaultGridContainerOption, GridEvent, GridProp } from '@/types';
import { selectRenderer } from '@/utils/grid/rendererUtils';
import { getAnchorHTML, getDeleteHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { getDisplayOrderHistoryColumn } from '@/components/product/common/girdColumns/ChangeHistory';

export const getImageHtml = (content: string): string => {
  return `
          <div class="image_area_wrap" style="height: 20px;">
            <img alt="" src="${content}" style="width:auto !important; height: 100%;"/>
          </div>
          `;
};

export const displayOptions: DefaultGridContainerOption = {
  showsRightArea: false,
};

export const gridProps = (onGridChangeEvent: GridEvent['onGridChangeEvent']): GridProp => {
  return {
    header: {
      align: 'center',
    },
    options: {
      rowHeaders: ['checkbox'],
      pageOptions: '',
      scrollY: true,
      bodyHeight: 300,
      columnOptions: {
        resizable: true,
      },
      heightResizable: true,
    },
    columns: [
      // 진열순서
      getDisplayOrderHistoryColumn(`PRODUCT.ICON.HEADER_ORDER`, 'displayOrder', 100),
      // 형식
      {
        header: `PRODUCT.ICON.HEADER_TYPE`,
        name: 'stickerType',
        width: 100,
        align: 'center',
        formatter: ({ value }) => window.$t(`PRODUCT.ICON.${value}`),
      },
      // 아이콘명
      {
        header: `PRODUCT.ICON.HEADER_NAME`,
        name: 'stickerName',
        align: 'left',
        resizable: true,
        formatter: ({ value }) => getAnchorHTML(value.toString() || '-'),
      },
      // 미리보기
      {
        header: `PRODUCT.ICON.HEADER_CONTENT`,
        name: 'content',
        width: 250,
        align: 'center',
        resizable: true,
        formatter: ({ row: { content, stickerType } }: any) =>
          stickerType === 'IMAGE' ? getImageHtml(content.toString()) : content,
      },
      // 사용여부
      {
        header: `PRODUCT.ICON.HEADER_USEYN`,
        name: 'useYn',
        width: 170,
        align: 'center',
        renderer: props => {
          props.selectOptions = [
            { text: window.$t(`PRODUCT.ICON.USE`), value: 'Y' },
            { text: window.$t(`PRODUCT.ICON.NOT_USE`), value: 'N' },
          ];
          props.className = `${props.rowKey}-useYn`;
          props.callback = event => onGridChangeEvent(props.columnInfo.name, props.rowKey, event);
          return selectRenderer(props);
        },
      },
      // 적용상품
      {
        header: `PRODUCT.ICON.HEADER_PRODUCT`,
        name: 'productCount',
        width: 80,
        align: 'center',
        formatter: ({ value }) => {
          const unit = i18n.t('EACH');
          return value > 0 ? getAnchorHTML(`${value}${unit}`) : `0${unit}`;
        },
      },
      // 삭제
      {
        header: `PRODUCT.ICON.HEADER_DELETE`,
        name: 'remove',
        width: 80,
        align: 'center',
        formatter: () => getDeleteHTML(),
      },
    ],
  };
};

export const message: ChangeDisplayOrderMsgOption = {
  needOrigin: 'PRODUCT.ICON.NEED_ORIGIN',
  selectedTarget: 'PRODUCT.ICON.SELECTED',
  confirmToRemove: 'PRODUCT.ICON.CONFIRM_REMOVE',
};
