










import { Component, Vue } from 'vue-property-decorator';
import ExcelParseWindow from '@/components/common/window/ExcelParseWindow.vue';

@Component({
  components: {
    ExcelParseWindow,
  },
})
export default class ExcelUpload extends Vue {}
