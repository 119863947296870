import TuiGrid from 'tui-grid';
import { i18n } from '@/main';

export const setNoDataMessage = (message: string) => {
  TuiGrid.setLanguage('ko', {
    display: {
      noData: message,
    },
  });
};

export const resetNoDataMessage = () => {
  const noData = i18n.t('NO_RESULT').toString();
  setNoDataMessage(noData);
};
