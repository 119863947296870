






























import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import NotificationTemplateSearchForm from '@/views/contents/member/kakao/NotificationTemplateSearchForm.vue';
import Grid from '@/components/common/grid/Main.vue';
import {
  GetKakaoConfig,
  GetKakaoConfigRequest,
  GetKakaoTemplatesAutoDetail,
  GetKakaoTemplatesAutoDetailRequest,
  GetKakaoTemplatesAutoRequest,
  KakaoTemplatesAutoContents,
  NCPResponse,
} from 'ncp-api-supporter';
import { getDisplayOptions } from '@/const/contents/sms';
import { range1ToNI18n } from '@/utils/common';
import { getNotificationTemplateGridOption } from '@/const/contents/kakao';
import { setNoDataMessage } from '@/helpers/grid';
import { throwWindowPopup } from '@/helpers/popup';
import { KakaoTemplateType } from '@/views/popups/member/KakaoTemplateManager.vue';
import { getCurrentMallNo } from '@/utils/mall';
import { Route } from 'vue-router';
import { isEmpty, omit } from 'underscore';
import { GridEventProps } from '@/types';
import { Row } from '@/types/tui-grid';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { checkSearchResult } from '@/views/contents/member/kakao/SearchTemplates';

@Component({
  components: { NotificationTemplateSearchForm, NoticeBox, Grid },
})
export default class NotificationTemplate extends Vue {
  private noticeList = range1ToNI18n(2, 'MEMBER.KAKAO.TEMPLATE_CONFIG_NOTICE_');
  private contents: KakaoTemplatesAutoContents[] = [];
  private totalCount = 0;
  private gridOption = getNotificationTemplateGridOption();
  private displayOptions = getDisplayOptions();

  @Ref()
  private readonly grid: Grid;
  private getQuery(): GetKakaoTemplatesAutoRequest['params'] {
    const { query } = this.$route;
    return {
      ...query,
      mallNo: Number(query.mallNo),
      startDate: `${query.startDate} ${DEFAULT_TIME_RANGE.START}`,
      endDate: `${query.endDate} ${DEFAULT_TIME_RANGE.END}`,
    };
  }
  @Watch('$route')
  private async searchTemplate(currRoute: Route, prevRoute: Route) {
    const needsReset = checkSearchResult(currRoute, prevRoute, 'MUST_SEARCH_TEMPLATE', this);
    if (needsReset) return;

    if (isEmpty(omit(this.$route.query, 'mallNo'))) return;

    try {
      const { contents, totalCount } = (await this.$api.getKakaoTemplatesAuto({ params: this.getQuery() })).data;

      this.contents = contents;
      this.totalCount = totalCount;
    } catch (e) {
      console.error(e);
    }
  }

  private async openRegisterTemplatePopup() {
    const request: GetKakaoConfigRequest = { params: { mallNo: getCurrentMallNo(this) } };
    const {
      data: { kakaoPlusId },
    }: NCPResponse<GetKakaoConfig> = await this.$api.getKakaoConfig(request);

    if (!kakaoPlusId) {
      return alert(this.$t('MEMBER.KAKAO.FIRST_REGISTER_PLUS_ID'));
    }
    throwWindowPopup(
      'KakaoTemplateManager',
      { type: KakaoTemplateType.REGISTER },
      'xlg',
      () => null,
      'KakaoTemplateManager',
      {
        mallNo: getCurrentMallNo(this),
      },
    );
  }

  private async openDetailTemplatePopup(templateNo: number) {
    const request: GetKakaoTemplatesAutoDetailRequest = {
      pathParams: {
        templateNo,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
    };
    const { data }: NCPResponse<GetKakaoTemplatesAutoDetail> = await this.$api.getKakaoTemplatesAutoDetail(request);
    throwWindowPopup(
      'KakaoTemplateManager',
      { type: KakaoTemplateType.DETAIL, template: data },
      'xlg',
      () => null,
      'KakaoTemplateManager',
      { mallNo: getCurrentMallNo(this) },
    );
  }
  private onItemClicked({ rowKey, columnName }: GridEventProps): void {
    if (!columnName) return;
    const rowData: Row = this.grid.getRowAt(rowKey);
    const templateNo = Number(rowData.templateNo);
    switch (columnName) {
      case 'templateCode':
      case 'templateName':
        this.openDetailTemplatePopup(templateNo);
        break;
    }
  }

  created() {
    setNoDataMessage(this.$t('MUST_SEARCH_TEMPLATE').toString());
  }
}
