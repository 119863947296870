import { Component, Watch } from 'vue-property-decorator';
import { GridEventProps } from '@/types';
import {
  AccumulationContent,
  AccumulationDailyStatusContent,
  AccumulationsAssemblesRequestNoResultsContents,
  GetAccumulationsPro,
  GetAccumulationsProRequest,
  NCPResponse,
} from 'ncp-api-supporter';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { getStrDate } from '@/utils/dateFormat';
import WindowPopupMain from '@/views/popups/Main.vue';
import { throwExternalWindowPopup } from '@/helpers/popup';

@Component
export default class AccumulationStatusMixin extends WindowPopupMain {
  public contents: (
    | AccumulationDailyStatusContent
    | AccumulationContent
    | AccumulationsAssemblesRequestNoResultsContents
  )[] = [];
  public totalCount = 0;

  @Watch('$route.query')
  protected async fetchData() {
    if (!this.$route.path.includes('popup') || this.$route.name === 'AccumulationManualStatusPopup') return;
    await this.fetchDailyStatusDetail(this.data);
  }

  protected accumulationsRequestParams({
    mallNo,
    date,
  }: {
    mallNo: number;
    date?: string;
  }): GetAccumulationsProRequest['params'] {
    const startDate = date ?? getStrDate(this.$route.query.startDateTime.toString());
    const endDate = date ?? getStrDate(this.$route.query.endDateTime.toString());
    return {
      page: Number(this.$route.query.page) || 1,
      pageSize: Number(this.$route.query.pageSize) || 30,
      mallNo,
      periodType: 'REGISTER',
      startDateTime: `${startDate} ${DEFAULT_TIME_RANGE.START}`,
      endDateTime: `${endDate} ${DEFAULT_TIME_RANGE.END}`,
      statuses: null,
    };
  }

  protected async fetchDailyStatusDetail(paramsData): Promise<void> {
    const request: GetAccumulationsProRequest = {
      params: this.accumulationsRequestParams(paramsData),
    };
    const { data }: NCPResponse<GetAccumulationsPro> = await this.$api.getAccumulationsPro(request);
    this.contents = data.contents;
    this.totalCount = data.totalCount;
  }

  protected onClickMemberNo({ columnName, instance, rowKey }: GridEventProps) {
    const { memberNo, memberStatus } = instance?.getRowAt(Number(rowKey));

    if (columnName !== 'no' || memberStatus === 'WITHDRAWN') return;
    throwExternalWindowPopup(`/pro/crm/${memberNo}`, 'xlg');
  }
}
