





















import { Vue, Component } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { LnbMenu } from '@/const/lnb';
import { PRODUCT_REGISTRATION_TYPE } from '@/views/contents/product/basic/ProductRegistration.vue';
import { getCurrentMenu } from '@/utils/menu';
import { AdminRole } from 'ncp-api-supporter';

const { VUE_APP_CONSOLIDATED_AUTH } = process.env;
const adminStore = namespace('admin');

export type ProductRegistrationType = keyof typeof PRODUCT_REGISTRATION_TYPE;

/*
 * /const/lnb.ts(위치 미정)의 메뉴를 참조하여 Breadcrumb를 만든다.
 * 만약 lnb.ts에 존재하지 않는 경우 (Home) 미노출 시킨다.
 * */
@Component
export default class PageTitle extends Vue {
  @Getter('lnb/getLnbMenu') lnbMenus!: LnbMenu[];

  @adminStore.Getter('selectedAdminRole')
  private readonly selectedAdminRole: AdminRole;

  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  get selectedLnbLabels() {
    if (this.$route.path === '/') {
      return [];
    }

    // 메타 breadcrumb 우선 체크
    const customBreadCrumb = this.$route.meta.breadcrumb;
    if (customBreadCrumb) {
      return this.getCustomBreadCrumb([...customBreadCrumb]);
    }

    const arrLabel = [];

    let current = getCurrentMenu(this.$route.path, this.lnbMenus);
    while (current) {
      arrLabel.unshift(current.name);
      current = current.parent;
    }

    return arrLabel;
  }
  get lastLnbLabel() {
    return this.selectedLnbLabels[this.selectedLnbLabels.length - 1];
  }

  private get titleType(): ProductRegistrationType {
    const currentQueryType = this.$route.query.type;
    if (currentQueryType !== undefined) {
      const registrationTypes: ProductRegistrationType[] = ['SINGLE', 'MAPPING', 'MASS'];
      const registrationType: ProductRegistrationType = registrationTypes.find(type => type === currentQueryType);
      if (registrationType !== undefined) return registrationType;
    }
    return null;
  }

  private getSubTitle(subTitle: string): string {
    const type: ProductRegistrationType = this.titleType;
    return type !== null ? `${this.$t(`PRODUCT.COMMON.${type.toString()}`)} ${subTitle}` : subTitle;
  }

  private getCustomBreadCrumb(customBreadCrumb: string[]): string[] {
    customBreadCrumb[customBreadCrumb.length - 1] = this.getSubTitle(customBreadCrumb[customBreadCrumb.length - 1]);
    return customBreadCrumb;
  }

  private get subTitle(): string {
    const needsAdminSubTitle =
      this.$route.name === 'AdminEdit' && this.selectedAdminRole === 'MASTER' && this.commerceAccount;
    if (needsAdminSubTitle) {
      return `
        <div class="commerce-notice">
          ${this.$t('CONFIGURATION.ADMIN.NOTICE_TO_EDIT_CONSOLIDATION_ADMIN_INFO1')}
          <a href="${VUE_APP_CONSOLIDATED_AUTH}">${this.$t('CONFIGURATION.ADMIN.CONSOLIDATION_MY_PAGE')}</a
          >${this.$t('CONFIGURATION.ADMIN.NOTICE_TO_EDIT_CONSOLIDATION_ADMIN_INFO2')}
        </div>
        `;
    }
    return '';
  }
}
