





















import { Component } from 'vue-property-decorator';
import MemberListSearchForm from '@/views/top/member/basic/MemberList.vue';
import MemberListGrid from '@/views/contents/member/basic/MemberList.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { SelectedMemberInfo } from '@/types/member';
import { GetMembersRequest } from 'ncp-api-supporter';
import { isEmpty } from 'underscore';

@Component({
  components: { MemberListSearchForm, MemberListGrid },
})
export default class InquiryMember extends WindowPopupMainVue {
  private selectedMember: SelectedMemberInfo = { selected: [], rejectCount: 0, selectedMemberSmsInfo: [] };
  private query: GetMembersRequest['params'] = {} as GetMembersRequest['params'];

  private saveSelectedMember() {
    this.onPositiveClick({ selectedMember: this.selectedMember, query: this.query });
  }

  created() {
    if (this.data.oldSelectedMember && this.data.oldSelectedMember.selected) {
      this.selectedMember = this.data.oldSelectedMember;
    }
    if (!isEmpty(this.data.query)) {
      this.query = this.data.query;
    }
  }

  private resetSelectedMember = true;
  private search() {
    this.resetSelectedMember = true;
    this.selectedMember = this.data.oldSelectedMember;
  }
}
