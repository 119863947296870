









































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { formatCurrency, getOnlyNumbers } from '@/utils/numberFormat';

@Component({
  components: { TextInput, SelectBox },
})
export default class TdRefundAdjustRequest extends Vue {
  @Prop({ required: true })
  private readonly isModifyMode: boolean;

  @Prop({ required: true })
  private readonly showRefundAdjustInput: boolean;

  @Prop({ required: false, default: false })
  private readonly disable: boolean;

  @PropSync('refundAdjustAmt')
  private refundAdjustAmtSync!: string;

  @PropSync('refundAdjustReason')
  private refundAdjustReasonSync!: string;

  private onPredict() {
    this.$emit('predict');
  }

  private onChangeRefundHoldProposedAmt(refundHoldProposedAmt: string): string {
    return formatCurrency(getOnlyNumbers(refundHoldProposedAmt));
  }
}
