




































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { CallbackForImage, ImageFileUpload, PostedImagesResponse } from '@/types';
import ImageFileInputMixin from '@/components/common/input/imageFileInput/ImageFileInputMixin.vue';
import { isAddableImage } from '@/views/contents/product/basic/ProductAdd/imagesInfo/ImagesInfo';

@Component
export default class ListImage extends Mixins(ImageFileInputMixin) implements ImageFileUpload {
  @PropSync('mallProductListImage')
  mallProductListImageSync!: string;

  @Prop({ required: true })
  private readonly disabled: boolean;

  callbackForPostedImage(): CallbackForImage {
    return (data: PostedImagesResponse) => {
      this.mallProductListImageSync = data.url;
    };
  }

  private get hasListImage(): boolean {
    return this.mallProductListImageSync !== '';
  }

  private addImage(): void {
    if (!isAddableImage(this)) return;
    this.imageFileInput.click();
  }

  private removeImage(): void {
    this.mallProductListImageSync = '';
  }
}
