import { GetStorageRequest } from 'ncp-api-supporter';
import { api } from '@/api';
import { saveBinaryDataToFile } from '@/utils/fileSaver';

export async function getStorageFile(
  storageFileName: string,
  container = 'ncp',
  downloadFileName?: string,
): Promise<void> {
  const request: GetStorageRequest = {
    params: {
      fileName: storageFileName,
      container,
    },
    responseType: 'blob',
  };
  const { data } = await api.getStorage(request);
  const fileName = downloadFileName ?? storageFileName;
  saveBinaryDataToFile(data, fileName);
}
