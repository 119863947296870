import { i18n } from '@/main';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { formatCurrency } from '@/utils/numberFormat';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import { addDay, getToday } from '@/utils/dateFormat';
import { mall } from '@/utils/mall';

// --- 그리드 옵션
export const getSalesByProductGridProps = () => ({
  header: {
    columns: [
      {
        name: 'excelDownload',
        renderer: props => {
          props.tooltipId = 'excelDownload';
          return headerWithTooptipRenderer(props);
        },
      },
    ],
  },
  columns: [
    {
      header: 'STATISTIC.SALES.PRODUCT_NO',
      name: 'mallProductNo',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'STATISTIC.SALES.MANAGEMENT_CODE',
      name: 'productManagementCd',
      align: 'center',
      minWidth: 80,
    },
    {
      header: 'STATISTIC.SALES.PRODUCT_NAME',
      name: 'productName',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'MALL',
      name: 'mallName',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'STATISTIC.SALES.BRAND',
      name: 'brandName',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'STATISTIC.SALES.SALES_METHOD',
      name: 'saleMethodTypeLabel',
      align: 'right',
      minWidth: 60,
    },
    {
      header: 'STATISTIC.SALES.ORDER_COUNT',
      name: 'lastOrderCnt',
      align: 'right',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.SALES_COUNT',
      name: 'totalSaleCnt',
      align: 'right',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.SALES_AMOUNT_A',
      name: 'totalSaleAmt',
      align: 'right',
      minWidth: 70,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.PURCHASE_AMOUNT',
      name: 'usePurchaseAmt',
      align: 'right',
      minWidth: 60,
      formatter: ({ row }) => {
        return row.totalPurchaseAmt > 0 ? 'Y' : 'N';
      },
    },
    {
      header: 'STATISTIC.SALES.TOTAL_PURCHASE_AMOUNT',
      name: 'totalPurchaseAmt',
      align: 'right',
      minWidth: 60,
      formatter: cell => (cell.value > 0 ? formatCurrency(cell.value) : '-'),
    },
    {
      header: 'STATISTIC.SALES.SUPPLY_AMOUNT',
      name: 'totalSupplyAmt',
      align: 'right',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.COMMISSION_RATE',
      name: 'commissionRate',
      align: 'right',
      minWidth: 50,
      formatter: cell => `${cell.value}%`,
    },
    {
      header: 'STATISTIC.SALES.COMMISSION_AMOUNT',
      name: 'totalCommissionAmt',
      align: 'right',
      minWidth: 90,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.PROMOTION_PRODUCT_DISCOUNT_AMOUNT',
      name: 'totalMallPromotionAmt',
      align: 'right',
      minWidth: 175,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.MALL_PROFIT_AMOUNT',
      name: 'totalMallProfitAmt',
      align: 'center',
      minWidth: 150,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.STANDARD_CATEGORY',
      name: 'fullCategoryName',
      align: 'center',
      minWidth: 80,
    },
    {
      header: 'DOWNLOAD',
      name: 'excelDownload',
      align: 'center',
      minWidth: 80,
      formatter: () => getAnchorHTML(i18n.t('DOWNLOAD')),
    },
  ],
  options: {
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },

  displayOptions: {
    showsRightArea: true,
    hasExcelDownloadButton: false,
    hasSettingButton: false,
    pageSizeKey: 'size',
    headerTooltipOption: [
      {
        id: '#excelDownload',
        popupData: {
          data: { message: i18n.t('STATISTIC.SALES.SALES_BY_DATE_EXCEL_DOWNLOAD_TOOLTIP'), leftAlign: true },
        },
      },
    ],
  },
});

export const getDefaultParams = () => {
  return {
    mallNo: mall.lastCreatedMall.mallNo,
    keyword: '', // 단일 선택(상품명)시 사용하는 검색어 param
    keywords: '', // 복수 선택(상품번호, 상품관리코드)시 사용하는 검색어 param
    productKeywordType: 'NAME',
    startYmd: addDay(new Date(), -7),
    endYmd: getToday(),
    includesClaim: false,
    platformTypes: 'PC,MOBILE_WEB,MOBILE_APP',
    payType:
      'CREDIT_CARD,ACCOUNT,MOBILE,REALTIME_ACCOUNT_TRANSFER,VIRTUAL_ACCOUNT,GIFT,ATM,PAYCO,ZERO_PAY,ACCUMULATION,PHONE_BILL,POINT,YPAY,KPAY,PAYPIN,INIPAY,PAYPAL,STRIPE,NAVER_PAY,KAKAO_PAY,NAVER_EASY_PAY,ESCROW_REALTIME_ACCOUNT_TRANSFER,ESCROW_VIRTUAL_ACCOUNT',
    memberGradeNo: 0,
    genderTypes: 'MALE,FEMALE,UNKNOWN',
    memberType: 'PAYCO,NAVER,KAKAO,FACEBOOK,GOOGLE,APPLE,APP_CARD', // 회원유형은 프로에서 optional 값인데, swagger엔 필수값으로 되어있음.
    isMember: '', // 확인 필요
    displayBrandNo: 0,
    productType: 'PRODUCT,OPTION',
    saleMethodType: 'PURCHASE,CONSIGNMENT',
    page: 1,
    size: 30,
  };
};
