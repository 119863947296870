export default Object.freeze({
  TITLE: 'SMS 관리',
  TAB_ONE: 'SMS 발송',
  TAB_TWO: 'SMS 템플릿 설정',
  TAB_THREE: '자동SMS 설정',
  TAB_FOUR: '자동SMS 발송내역 조회',
  TAB_FIVE: '자동SMS 사용내역 조회',
  DETAIL_ONE: 'ⓘ 광고성/정보성 SMS를 발송할 수 있습니다.',
  DETAIL_TWO: "Ⓘ 광고성 SMS 발송은 '080 수신거부 번호'가 등록되어 있어야 사용이 가능합니다.",
  DETAIL_FOUR_ONE: 'Ⓘ SMS 발송/사용내역은',
  DETAIL_FOUR_LINK: 'TOAST Cloud',
  DETAIL_FOUR_TWO: '에서 확인할 수 있습니다.',
  TR_1: 'SMS유형',
  TR_2: '템플릿 설정',
  TR_2_ADD1: '발송 유형',
  TR_2_ADD2: '발신 번호',
  TR_3: '080 수신거부 번호',
  TR_4: '수신 대상 회원 선택',
  TR_5: '발송 설정',
  TR_6: '제목',
  TR_7: '내용',
  TR_8: '수신거부 문구',
  TEMPLATE_UNUSE: '사용 안 함',
  TEMPLATE_USE: '사용함',
  TEMPLATE_INUSE: '템플릿 선택',
  SENDTYPE_SMS: 'SMS (90byte)',
  SENDTYPE_LMS: 'LMS (2,000byte)',
  DETAIL_KIND: '발송 유형에 따라 SMS 발송 금액이 달라집니다.',
  DETAIL_TEL: "발신번호는 'TOAST Cloud > Notification > SMS > 발신번호관리'에서 등록 및 관리가 가능합니다.",
  DETAIL_EMAIL:
    "080 수신거부 번호는 'TOAST Cloud > Notification > SMS > 080수신거부설정' 에서 신청 및 관리가 가능합니다.",
  PLACEHOLDER_EMAIL: '080 수신거부 번호를 신청해주세요.',
  RECIPIENT_ALL: '전체 회원',
  RECIPIENT_SELECT_MEMBER: '회원 선택',
  MEMBER_SELECT_BTN: '회원등급/그룹 선택',
  MEMBER_SEARCH_BTN: '회원 조회',
  UPLOAD_FILE: '첨부파일',
  DESCRIPTION_1_1: '발송인원 총',
  DESCRIPTION_1_2: '명',
  DESCRIPTION_1_3: '회원 선택 되었습니다. (중복 회원 제외)',
  DESCRIPTION_2: '광고성인 경우, 광고성 수신 미동의 회원은 발송 시 제외됩니다.',
  DESCRIPTION_3: '휴면회원 및 탈퇴회원은 수신 대상에서 제외됩니다.',
  DESCRIPTION_4: '예약발송인 경우 설정일과 발송일 수신 대상 회원이 일치하지 않을 수 있습니다.',
  DESCRIPTION_5: '광고성 메일의 경우, 수신자가 메일을 수신 거부 할 수 있는 기능이 메일 하단에 아래와 같이 추가 됩니다.',
  DESCRIPTION_6: '메일 수신을 원치 않으시면 [수신거부]를 클릭하세요.',
  DESCRIPTION_7: 'If you do not want to receive it, please click a [Unsubscription]',
  SELECT_DELETE: '선택삭제',
  ALL_DELETE: '전체삭제',
  SENDIMM: '즉시 발송',
  SENDBACK: '예약 발송',
  SENDERNO_NULL: '발신번호를 등록해주세요.',
  DIRECT_INPUT_PLACEHOLDER: '예) 010-1111-2222',
  WRONG_PHONE_NUMBER: '잘못된 휴대폰 번호입니다',
  MUST_SELECT_PHONE_NO: '휴대폰번호를 선택해주세요.',
  TITLE_AD: '(광고)',
  CONTENT_AD: '[무료 수신거부]',
  AD_PHRASE: '(광고)\n{content}\n[무료 수신거부] {adBlockNo}',
  TITLE_PLACEHOLDER: '제목을 입력해 주세요.',
  TITLE_PREVIEW: 'SMS 발송내용 미리보기',
  BTN_SEND: '발송',
  BTN_PREVIEW: '미리보기',
  BTN_RESET: '초기화',
  POPUP_TEMPLATE_SELECTOR: '템플릿 선택',
  POPUP_MEMBER_SELECT: '회원등급/그룹 선택 팝업',
  POPUP_MEMBER_SEARCH: '회원 조회 팝업',
  NO_MEMBER_SEND: '전송 가능한 회원이 없습니다.',
  SUCCESS_SEND:
    "발송요청에 성공하였습니다.\n발송결과는 'SMS 발송내역 조회'에서 확인해주세요.\n(예약 발송 대기 건은 ‘SMS 예약발송 관리‘에서 확인 가능합니다.)\n",
  FAIL_SEND: '발송에 실패하였습니다.',
  ERR_APP_KEY: '쇼핑몰 수정에서 SMS Appkey를 등록해 주셔야 발송이 가능합니다.',
  ERR_RECIPIENT_COUNT: '수신대상 회원을 선택해 주세요.',
  ERR_SENDTYPE: 'SMS 유형을 선택해주세요.',
  ERR_TEMPLATESENDTYPE_NULL: '템플릿을 선택해주세요.',
  ERR_TEMPLATESENDTYPE: '템플릿 발송 유형과 현재 발송유형이 같지 않습니다.',
  ERR_SENDERNO: '발신번호가 등록된 후 SMS를 발송할 수 있습니다.\nTOAST Cloud에서 발신번호를 등록해주세요.',
  ERR_TIME: '예약발송일시를 현재 기준 10분 이후로 설정해 주세요',
  ERR_TITLE: '제목을 입력해 주세요.',
  PLACEHOLDER_CONTENT: '내용을 입력해 주세요.',
  NO_TEMPLATE_DATE: '등록된 템플릿이 없습니다.',
  PLACEHOLDER_AD_CONTENT: '(광고)\n내용을 입력해 주세요.\n[무료 수신거부]{0}',
  TOTAL_DESTINATION: '수신대상 총',

  // 수신거부회원 포함 안내
  TITLE_REJECT: '수신거부회원 포함 안내',
  REJECT_NOTICE_1: '대상회원 {allCount}명 중 수신거부회원이 {rejectCount}명 포함되어 있습니다.',
  REJECT_NOTICE_2: '수신거부회원을 제외하고 발송하시겠습니까?',
  REJECT_NOTICE_3: '수신거부한 회원에게 광고성 정보를 발송하는 경우 과태료가 부과될 수 있습니다.',
  EXCEPT_SEND: '제외하고 발송',
  INCLUDE_SEND: '포함하고 발송',
  ALERT_AD: '광고성 SMS는 080 수신거부 번호가 등록되어 있어야 발송할 수 있습니다.',

  // SMS 발송 상세설정 팝업
  POPUP_TITLE: 'SMS 발송 상세설정',
  NIGHT_LIMIT_CONFIG: '야간발송 제한 설정',
  NIGHT_LIMIT: '야간발송 제한',
  SEND_ITEM: '발송항목',
  NIGHT_TIME: '야간시간(21:00 ~ 익일08:00) 발송 제한 설정',
  ALL_CONFIG: '전체설정',

  // 광고성 SMS 전송 시 유의사항
  NOTICE_POPUP_TITLE: '광고성 SMS 전송 시 유의사항',
  NOTICE_TEXT_1:
    '정보통신망법 <a class="new_tab_link article_50">제50조</a>에 따라 광고성 SMS 전송 시에는 야간시간대 발송이 제한되며, 명시사항을 포함하여 발송해야 합니다.',
  NOTICE_TEXT_2:
    '위반 시 정보통신망법 <a class="new_tab_link article_76">제76조</a>에 따라 3천만원 이하의 과태료가 부과될 수 있습니다.',
  NOTICE_TEXT_3: '필요한 조치를 취하지 않아 발생하는 불이익에 대하여 당사는 책임을 지지 않습니다.',
  NOTICE_TEXT_4: '(한국인터넷진흥원 관련 내용 확인)',
  NOTICE_SEND_TITLE: '광고성 SMS 발송 시 다음과 같은 조치를 적용하여 발송하여야 합니다.',
  NOTICE_AUTO_TITLE:
    '자동 발송 항목 중 쿠폰/적립금 지급에 대한 안내는 영리 목적의 광고성 정보에 해당하며, 이에 따라 자동 SMS 항목 중 ‘프로모션 관련’ 항목을 사용하실 경우 다음과 같은 조치를 적용하여 발송하여야 합니다.',
  NOTICE_LIST_1: '1. SMS 수신동의 회원에게만 발송하여야 합니다.',
  NOTICE_LIST_2: '2. 별도 동의 없이는 야간시간대(21:00~익일 08:00) 발송이 불가합니다.',
  NOTICE_LIST_3: '3. 광고성 SMS 발송 시 명시사항을 포함하여 발송하여야 합니다.',
  NOTICE_LIST_DETAIL_1: '- 내용 시작 부분에 (광고) 표기',
  NOTICE_LIST_DETAIL_2: '- 전송자의 명칭 (쇼핑몰명 등)',
  NOTICE_LIST_DETAIL_3: '- 수신거부 또는 수신동의 철회 방식 및 해당 방식이 무료임을 명시',
  NOTICE_DETAIL_AUTO:
    '* 광고성 정보 전송 시 080 수신거부 서비스 등 수신거부 및 수신동의 철회가 가능한 방식을 함께 안내하여야 합니다. 광고성 정보를 자동으로 전송하고자 할 경우 080 수신거부 서비스를 이용하시길 권장합니다.',
  NOTICE_EXAMPLE_1: '(광고)A쇼핑몰',
  NOTICE_EXAMPLE_2: '발송내용',
  NOTICE_EXAMPLE_3: '무료수신거부 080XXXXXXXX',
  NOTICE_SOLUTION_1: '당사에서는 정보통신망법 준수를 위해 {0} 발송 시 다음과 같은 기술적 조치를 제공합니다.',
  SOLUTION_TYPE_SEND: '‘광고성’ SMS 유형',
  SOLUTION_TYPE_AUTO: '‘프로모션 관련’항목 SMS',
  NOTICE_SOLUTION_SEND_2: '· SMS 수신거부 대상에게는 광고 SMS 를 발송하지 않도록 안내하고 있습니다.',
  NOTICE_SOLUTION_SEND_3:
    '· 내용 앞에 (광고), 내용 끝에 [무료 수신거부] 080-XXX-XXXX를 자동으로 추가하여 발송 처리합니다.',
  NOTICE_SOLUTION_4: '* 단, 080 수신거부 서비스 미이용 시 ‘광고성’ SMS 유형 발송이 불가합니다.',
  NOTICE_SOLUTION_AUTO_2:
    '· [발송 상세설정]을 클릭하여 항목별 야간시간 발송여부를 설정할 수 있습니다. (야간시간 발송 제한 시 21:00~익일 08:00에는 미발송)',
  NOTICE_SOLUTION_AUTO_3: '· SMS 수신거부 대상에게는 ‘프로모션 관련’항목 SMS를 발송하지 않습니다.',
});
