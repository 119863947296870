

























import { Component, Vue } from 'vue-property-decorator';
import { CampaignsAccountsAccessUsersType } from 'ncp-api-supporter';
@Component
export default class PaymentMethodRegist extends Vue {
  private adGoogleUrl =
    'https://ads.google.com/aw/billing/billingsetups?ocid=378793410&euid=409025694&__u=9127484206&uscid=378793410&__c=1005655090&authuser=1';
  private mallNo = 0;
  private userType: CampaignsAccountsAccessUsersType = 'ADS';

  created() {
    this.mallNo = Number(this.$route.query.mallNo);
  }

  private async goAdsPaymentRegist() {
    window.open(this.adGoogleUrl); // 새탭에서 열림
  }
}
