









































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OauthTypeNotice extends Vue {
  @Prop() private onNegativeClick!: () => void;
  @Prop() private data;
}
