import { i18n } from '@/main';
import { RadioBoxGroupOption } from '@/helpers/type';
import { GetShopbyConfigExternalServiceConfigurations, PageScriptsType } from 'ncp-api-supporter';

export const i18nExternal = (message: string, key = 'CONFIGURATION.EXTERNAL_SERVICE.'): string => {
  return i18n.t(key + message).toString();
};

export const getExternalServiceSelectType = () => {
  return [
    { name: i18nExternal('MAIN_PAGE'), value: 'MAIN', disabled: false },
    { name: i18nExternal('PRODUCT_PAGE'), value: 'PRODUCT', disabled: false },
    { name: i18nExternal('PRODUCT_SEARCHED_PAGE'), value: 'PRODUCT_SEARCH', disabled: false },
    { name: i18nExternal('PRODUCT_SEARCHED_BY_CATEGORY_PAGE'), value: 'PRODUCT_LIST', disabled: false },
    { name: i18nExternal('SECTION_PRODUCT_PAGE'), value: 'DISPLAY_SECTION', disabled: false },
    { name: i18nExternal('CART_PAGE'), value: 'CART', disabled: false },
    { name: i18nExternal('ORDER_SHEET_PAGE'), value: 'ORDER', disabled: false },
    { name: i18nExternal('ORDER_COMPLETE_PAGE'), value: 'ORDER_COMPLETE', disabled: false },
    { name: i18nExternal('ORDER_DETAIL_PAGE'), value: 'ORDER_DETAIL', disabled: false },
    { name: i18nExternal('MEMBER_JOIN_COMPLETE_PAGE'), value: 'MEMBER_JOIN_COMPLETE', disabled: false },
  ];
};

export const easyLoginUrl = [
  {
    url: 'https://developers.payco.com/application',
    linkText: 'PAYCO_CENTER',
  },
  {
    url: 'https://developers.naver.com/apps/#/register?api=nvlogin',
    linkText: 'NAVER_CENTER',
  },
  {
    url: 'https://developers.kakao.com/console/app',
    linkText: 'KAKAO_CENTER',
  },
  {
    url: 'https://developers.facebook.com/apps/',
    linkText: 'FACEBOOK_CENTER',
  },
];

export const externalScriptOption: RadioBoxGroupOption<boolean> = {
  name: `radio-external-script`,
  data: [
    { id: `use-external-script`, value: true, display: 'USED' },
    { id: `not-external-script`, value: false, display: 'NOT_USED' },
  ],
};

export const toolTipMessage = (): { [key in string]: string } => ({
  google: i18nExternal('GOOGLE_TOOL_TIP'),
  naver: i18nExternal('NAVER_TOOL_TIP'),
  kakao: i18nExternal('KAKAO_TOOL_TIP'),
  easyLogin: i18nExternal('EASY_LOGIN_TOOL_TIP'),
  externalScript: i18nExternal('EXTERNAL_SCRIPT_TOOL_TIP'),
  appKey: i18nExternal('APP_KEY_TOOL_TIP'),
  boardTip: i18nExternal('BOARD_RESERVE_TOOL_TIP'),
});

export const openIdJoinConfig = {
  authenticationUsed: true,
  memberAuthenticationType: '',
  authenticationTimeType: '',
  redirectUrlForPc: '',
  redirectUrlForMobile: '',
  redirectUrlForAos: '',
  redirectUrlForIos: '',
  clientId: {},
  secretKey: {},
};

export const defaultExternalServiceConfig: GetShopbyConfigExternalServiceConfigurations = {
  googleAnalytics: '',
  naverWebmaster: '',
  kakaoMap: {
    kakaoMapKey: '',
    kakaoMapLatitude: '',
    kakaoMapLongitude: '',
  },
};

export const defaultEasyLogin = [
  { id: '', secret: '', name: 'PAYCO' },
  { id: '', secret: '', name: 'NAVER' },
  { id: '', secret: '', name: 'KAKAO' },
  { id: '', secret: '', name: 'FACEBOOK' },
];

export const defaultScript: PageScriptsType = {
  pageType: '',
  deviceType: '',
  content: '',
  used: false,
};

export const defaultPageScript = {
  pageType: 'MAIN',
  content: null,
  used: false,
};

export interface ScriptsType {
  pc: Partial<PageScriptsType>;
  mobile: Partial<PageScriptsType>;
}
