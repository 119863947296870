












import { Vue, Component, PropSync } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { ManageNoticeCategoryType } from 'ncp-api-supporter';
import { OptionData } from '@/helpers/type';
import { getCategorySelectBox } from '@/views/top/partner/notice/PartnerNoticeList';

@Component({
  components: {
    SelectBox,
  },
})
export default class NoticeCategorySelector extends Vue {
  @PropSync('noticeCategory', { required: true })
  private noticeCategorySync: ManageNoticeCategoryType;

  private categorySelectBox: OptionData<ManageNoticeCategoryType>[] = getCategorySelectBox();

  private fetchNoticeCategories() {
    this.$api.getNoticesCategories().then(({ data }) => this.setCategorySelectBox(getCategorySelectBox(data)));
  }

  private setCategorySelectBox(categories: OptionData<ManageNoticeCategoryType>[]) {
    this.categorySelectBox = categories;
  }

  private created() {
    this.fetchNoticeCategories();
  }
}
