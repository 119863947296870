














































import { Component } from 'vue-property-decorator';
import { CheckedRowsType, FailureDataType, StockModificationType, SuccessListType } from '@/types/productStock';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { getNegativeNum } from '@/utils/numberFormat';

enum Stock {
  EDIT = 'EDIT',
  ADJUST = 'ADJUST',
}

@Component({
  components: { TextInput },
})
export default class StockModification extends WindowPopupMainVue {
  private type: Stock.EDIT | Stock.ADJUST = Stock.ADJUST;
  private inputStockCnt: number | null = null;
  private checkedRowsLength = 0;
  private checkedRows: CheckedRowsType[] = [];
  private failureList: FailureDataType[] = [];
  private successList: SuccessListType[] = [];
  private validTypeOption = {
    EDIT: null,
    ADJUST: 'number',
  };

  private replaceHook = {
    EDIT: getNegativeNum,
    ADJUST: null,
  };

  created() {
    this.type = this.data.type;
    this.checkedRows = this.data.checkedRows;
    this.checkedRowsLength = this.checkedRows.length;
  }

  private modifyStock(): null | StockModificationType {
    if (!this.inputStockCnt) {
      alert(this.$t('STOCK.MANAGEMENT.STOCK_MODIFY_EMPTY'));
      return null;
    }

    const confirmMessage = this.$t('STOCK.MANAGEMENT.STOCK_MODIFY_CONFIRM', [this.checkedRowsLength]) as string;
    if (!confirm(confirmMessage)) return null;

    this.separateSuccessAndFailure();

    const failureList = this.checkFailureList();
    this.onPositiveClick(failureList);
  }

  private separateSuccessAndFailure(): void {
    this.checkedRows.forEach(({ mallOptionNo, rowKey, mallProductNo, stockNo, stockCnt, mallNo }: CheckedRowsType) => {
      const isFailureData = this.isEditType && this.checkModifiedStockData(stockCnt);
      if (isFailureData) {
        const failureData = {
          mallOptionNo,
          mallProductNo,
          stockNo,
        };
        this.failureList.push(failureData);
      } else {
        const successData = this.getSuccessData({ mallOptionNo, rowKey, mallProductNo, stockNo, stockCnt, mallNo });
        this.successList.push(successData);
      }
    });
  }

  private get isEditType(): boolean {
    return this.type === Stock.EDIT;
  }

  private checkModifiedStockData(stockCnt: number): boolean {
    //선택한 아이템의 재고수량 + 입력한 수정재고수량 < 0 ? 실패
    return stockCnt + this.inputStockCnt < 0;
  }

  private getSuccessData(rowItem: CheckedRowsType): SuccessListType {
    //조정재고 변경하면? 수정재고 = 조정재고 - 재고수량
    //수정재고 변경하면? 조정재고 = 재고수량 + 수정재고
    const { mallOptionNo, rowKey, mallProductNo, stockNo, stockCnt, mallNo } = rowItem;
    const successData = {
      editStock: null,
      adjustStock: null,
      rowKey,
      mallOptionNo,
      mallProductNo,
      mallNo,
      stockNo,
    };
    if (this.type === Stock.EDIT) {
      successData.adjustStock = stockCnt + this.inputStockCnt;
      successData.editStock = this.inputStockCnt;
    } else {
      successData.editStock = this.inputStockCnt - stockCnt;
      successData.adjustStock = this.inputStockCnt;
    }
    return successData;
  }

  private checkFailureList(): StockModificationType {
    alert(this.$t('STOCK.MANAGEMENT.STOCK_MODIFY_SAVED'));
    const successData = { type: this.type, stockManageCnt: this.inputStockCnt, dataList: this.successList };
    const failureData = this.failureList.length ? this.failureList : null;
    return { successData, failureData };
  }
}
