var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents"},[_c('div',{staticClass:"box-view-wrap",attrs:{"id":"appearance_design_popup_list"}},[_c('grid',{ref:"designPopupsGrid",staticClass:"content_bottom_item",attrs:{"data":_vm.contents,"totalCount":_vm.totalCount,"header":{},"columns":_vm.gridProps.columns,"options":_vm.gridProps.options,"display-options":{
        showsTopArea: true,
        showsRightArea: true,
        hasSettingButton: false,
        hasPageSizeSelector: true,
        hasExcelDownloadButton: false,
        pageSizeKey: 'size',
      },"on-row-checked":_vm.onRowChecked,"on-item-clicked":_vm.onItemClicked}},[_c('span',{staticClass:"table-tit",attrs:{"slot":"tableTit"},slot:"tableTit"},[_vm._v(_vm._s(_vm.$t('RESULT'))+" "),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.totalCount))]),_vm._v(_vm._s(_vm.$t('CASE')))]),_c('div',{staticClass:"btn-wrap-left",attrs:{"slot":"tableLeft"},slot:"tableLeft"},[_c('button',{staticClass:"crmBtn type-white",attrs:{"type":"button"},on:{"click":_vm.deleteDesignPopups}},[_vm._v(" "+_vm._s(_vm.$t('DISPLAY.DESIGN_POPUP.DELETE_BUTTON'))+" ")]),_c('button',{staticClass:"crmBtn type-white type-plus",attrs:{"type":"button"},on:{"click":_vm.addDesignPopups}},[_vm._v(" "+_vm._s(_vm.$t('DISPLAY.DESIGN_POPUP.ADD_BUTTON'))+" ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }