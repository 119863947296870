



































import { Component, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import Editor from '@/components/common/summernote/Editor.vue';

import sanitizeHtml from '@/utils/sanitizeHtml';
// requests
import {
  postContractsEntryContractsRequest,
  getContractsEntryContractsRequest,
} from '@/views/contents/partner/modification/apis/apiRequests';

@Component({
  components: { Editor, NoticeBox },
})
export default class EntryContractFilePopupRegistration extends WindowPopupMainVue {
  @Ref()
  private readonly editorRef?: Editor;

  private async addNewEntryContract() {
    const entryContractContents = sanitizeHtml(this.editorRef.getHtml());
    const request = postContractsEntryContractsRequest(this.data.contractNo.toString(), entryContractContents);

    await this.$api.postContractsEntryContracts(request);
  }

  private async getEntryContracts() {
    const request = getContractsEntryContractsRequest(this.data.contractNo.toString());

    const { data: entryContracts } = await this.$api.getContractsEntryContracts(request);
    const latestEntryContract = entryContracts[entryContracts.length - 1];

    return { entryContracts, latestEntryContract };
  }

  private async emitRegisteredEntryContracts() {
    const { entryContracts } = await this.getEntryContracts();

    const latestEntryContractAgreed = '';
    const entryContractSize = entryContracts.length;

    this.onPositiveClick({ latestEntryContractAgreed, entryContractSize });

    return;
  }

  private async registrationNewEntryContract() {
    await this.addNewEntryContract();
    await this.emitRegisteredEntryContracts();
  }

  created() {
    console.log('created editor Ref: ', this.editorRef);
  }

  mounted() {
    console.log('mounted editor Ref: ', this.editorRef);
  }
}
