import { GroupOptionData, CheckBoxGroupOption, ValueType } from '@/helpers/type';

export type ApplyStatusType =
  | 'ALL'
  | 'FINISHED'
  | 'REGISTRATION_READY'
  | 'APPROVAL_READY'
  | 'APPROVAL_REJECTION'
  | 'SALE_AGREEMENT_READY'
  | 'SALE_AGREEMENT_REJECTION'
  | 'AFTER_APPROVAL_READY'
  | 'AFTER_APPROVAL_REJECTION';

export interface SaleStatusCheckBoxGroupOption<T extends ValueType> extends Omit<CheckBoxGroupOption<T>, 'data'> {
  data: SaleStatusCheckBoxGroupOptionData<T>[];
}

interface SaleStatusCheckBoxGroupOptionData<T extends ValueType> extends GroupOptionData<T> {
  value: T;
}

export type SalesStatusType = 'WAITING_SALE' | 'ON_PRE_SALE' | 'ON_SALE' | 'END_SALE';

export type SalesSettingStatusType = 'AVAILABLE_FOR_SALE' | 'STOP_SELLING' | 'PROHIBITION_SALE';

export type AllowRadioType = 'ALL' | 'Y' | 'N';

export type Deliverable = AllowRadioType;

export type DeliveryFeeType = 'ALL' | 'FREE' | 'CONDITIONAL_FREE' | 'FIXED_FEE' | 'QUANTITY_PROPOSITIONAL_FEE';

export type AllowsShippingTogether = AllowRadioType;

export type AllowsFrontDisplay = AllowRadioType;

export type PlatformType = 'ALL' | 'PC' | 'MOBILE_WEB';

export type OnlyMappingProduct = AllowRadioType;

export type SaleMethodType = 'ALL' | 'PURCHASE' | 'CONSIGNMENT';

export const SALE_METHOD_TYPES = ['ALL', 'PURCHASE', 'CONSIGNMENT'] as const;

export type SortDirection = 'DESC' | 'ASC';

export type SortCriterion = 'MALL_PRODUCT_NO' | 'UPDATE_DATE_TIME' | 'STOCK_CNT' | 'SALE_CNT' | 'REVIEW_CNT';
