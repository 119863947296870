import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import CancelList from '@/views/contents/shipping/claim/CancelList.vue';
import CancelIframe from '@/views/contents/shipping/claim/CancelIframe.vue';
import ExchangeList from '@/views/contents/shipping/claim/ExchangeList.vue';
import ExchangeIframe from '@/views/contents/shipping/claim/ExchangeIframe.vue';
import ReturnList from '@/views/contents/shipping/claim/ReturnList.vue';
import ReturnIframe from '@/views/contents/shipping/claim/ReturnIframe.vue';
import RefundList from '@/views/contents/shipping/claim/RefundList.vue';
import RefundIframe from '@/views/contents/shipping/claim/RefundIframe.vue';
import TaskMessageIframe from '@/views/contents/shipping/claim/TaskMessageIframe.vue';
import CsLogsIframe from '@/views/contents/shipping/claim/CsLogsIframe.vue';
import OrderList from '@/views/contents/shipping/order/OrderList.vue';
import OrderListIframe from '@/views/contents/shipping/order/OrderListIframe.vue';
import OrderDepositWait from '@/views/contents/shipping/order/OrderDepositWait.vue';
import OrderDepositWaitIframe from '@/views/contents/shipping/order/OrderDepositWaitIframe.vue';
import OrderPayDone from '@/views/contents/shipping/order/OrderPayDone.vue';
import OrderPayDoneIframe from '@/views/contents/shipping/order/OrderPayDoneIframe.vue';
import OrderProductPrepare from '@/views/contents/shipping/order/OrderProductPrepare.vue';
import OrderProductPrepareIframe from '@/views/contents/shipping/order/OrderProductPrepareIframe.vue';
import OrderDeliveryPrepare from '@/views/contents/shipping/order/OrderDeliveryPrepare.vue';
import OrderDeliveryPrepareIframe from '@/views/contents/shipping/order/OrderDeliveryPrepareIframe.vue';
import OrderDeliveryIng from '@/views/contents/shipping/order/OrderDeliveryIng.vue';
import OrderDeliveringIframe from '@/views/contents/shipping/order/OrderDeliveringIframe.vue';
import OrderDeliveryDone from '@/views/contents/shipping/order/OrderDeliveryDone.vue';
import OrderDeliveryDoneIframe from '@/views/contents/shipping/order/OrderDeliveryDoneIframe.vue';
import OrderBuyConfirm from '@/views/contents/shipping/order/OrderBuyConfirm.vue';
import OrderBuyConfirmIframe from '@/views/contents/shipping/order/OrderBuyConfirmIframe.vue';
import OrderPayFail from '@/views/contents/shipping/order/OrderPayFail.vue';
import OrderPayFailIframe from '@/views/contents/shipping/order/OrderPayFailIframe.vue';
import OrderHoldDeliveryIframe from '@/views/contents/shipping/order/OrderHoldDeliveryIframe.vue';
import OrderHoldDelivery from '@/views/contents/shipping/order/OrderHoldDelivery.vue';
import OrderPreOrder from '@/views/contents/shipping/order/OrderPreOrder.vue';
import AutoDeposit from '@/views/contents/shipping/withoutBankbook/AutoDeposit.vue';
import UndefinedDepositor from '@/views/contents/shipping/withoutBankbook/UndefinedDepositor.vue';
import OrderDetailIframe from '@/views/contents/shipping/order/OrderDetailIframe.vue';
import DeliveryScan from '@/views/contents/shipping/order/DeliveryScan.vue';
import OrderPrevious from '@/views/contents/shipping/order/OrderPrevious.vue';
import OrderPreviousDetail from '@/views/contents/shipping/order/OrderPreviousDetail.vue';
import OrderDetail from '@/views/contents/shipping/order/OrderDetail.vue';
import OrderPreviousDetailIframe from '@/views/contents/shipping/order/OrderPreviousDetailIframe.vue';
import PreviousOrdersIframe from '@/views/contents/shipping/order/OrderPreviousIframe.vue';
import OrderPreOrderIframe from '@/views/contents/shipping/order/OrderPreOrderIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/shipping',
    name: 'shipping',
    component: DefaultLayout,
    children: [
      // FIXME - 리얼배포시점 삭제
      {
        path: 'claim/cancels-prev',
        name: 'CancelList',
        component: CancelList,
        meta: {
          type: 'cancel',
          excelCreateMenuKey: 'PRO_CANCEL_LIST',
          gridName: 'PRO_CLAIM_CANCEL_GRID',
        },
      },
      {
        path: 'claim/cancels',
        name: 'CancelIframe',
        component: CancelIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'claim/exchanges-prev',
        name: 'ExchangeList',
        component: ExchangeList,
        meta: {
          type: 'exchange',
          excelCreateMenuKey: 'PRO_EXCHANGE_LIST',
          gridName: 'PRO_CLAIM_EXCHANGE_GRID',
        },
      },
      {
        path: 'claim/exchanges',
        name: 'ExchangeIframe',
        component: ExchangeIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'claim/returns-prev',
        name: 'ReturnList',
        component: ReturnList,
        meta: {
          type: 'return',
          excelCreateMenuKey: 'PRO_RETURN_LIST',
          gridName: 'PRO_CLAIM_RETURN_GRID',
        },
      },
      {
        path: 'claim/returns',
        name: 'ReturnIframe',
        component: ReturnIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'claim/refunds-prev',
        name: 'RefundList',
        component: RefundList,
        meta: {
          type: 'refund',
          excelCreateMenuKey: 'PRO_REFUND_LIST',
          gridName: 'PRO_CLAIM_REFUND_GRID',
        },
      },
      {
        path: 'claim/refunds',
        name: 'RefundIframe',
        component: RefundIframe,
      },
      {
        path: 'claim/cs-logs',
        name: 'CsLogsIframe',
        component: CsLogsIframe,
      },
      {
        path: 'claim/task-message',
        name: 'TaskMessageIframe',
        component: TaskMessageIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/list-prev',
        name: 'OrderList',
        component: OrderList,
        meta: {
          type: 'total',
        },
      },
      {
        path: 'order/list',
        name: 'OrderListIframe',
        component: OrderListIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/deposit-wait-prev',
        name: 'OrderDepositWait',
        component: OrderDepositWait,
        meta: {
          type: 'waitingDeposit',
          excelCreateMenuKey: 'PRO_DEPOSIT_WAIT',
        },
      },
      {
        path: 'order/deposit-wait',
        name: 'OrderDepositWaitIframe',
        component: OrderDepositWaitIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/pay-done-prev',
        name: 'OrderPayDone',
        component: OrderPayDone,
        meta: {
          type: 'payed',
          excelCreateMenuKey: 'PRO_PAY_DONE',
        },
      },
      {
        path: 'order/pay-done',
        name: 'OrderPayDoneIframe',
        component: OrderPayDoneIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/product-prepare-prev',
        name: 'OrderProductPrepare',
        component: OrderProductPrepare,
        meta: {
          type: 'preparingProduct',
          excelCreateMenuKey: 'PRO_PRODUCT_PREPARE',
        },
      },
      {
        path: 'order/product-prepare',
        name: 'OrderProductPrepareIframe',
        component: OrderProductPrepareIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/delivery-prepare-prev',
        name: 'OrderDeliveryPrepare',
        component: OrderDeliveryPrepare,
        meta: {
          type: 'preparingDelivery',
        },
      },
      {
        path: 'order/delivery-prepare',
        name: 'OrderDeliveryPrepareIframe',
        component: OrderDeliveryPrepareIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/delivering-prev',
        name: 'OrderDeliveryIng',
        component: OrderDeliveryIng,
        meta: {
          type: 'delivering',
          excelCreateMenuKey: 'PRO_DELIVERY_ING',
        },
      },
      {
        path: 'order/delivering',
        name: 'OrderDeliveringIframe',
        component: OrderDeliveringIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/delivery-done-prev',
        name: 'OrderDeliveryDone',
        component: OrderDeliveryDone,
        meta: {
          type: 'delivered',
          excelCreateMenuKey: 'PRO_DELIVERY_DONE',
        },
      },
      {
        path: 'order/delivery-done',
        name: 'OrderDeliveryDoneIframe',
        component: OrderDeliveryDoneIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/buy-confirm-prev',
        name: 'OrderBuyConfirm',
        component: OrderBuyConfirm,
        meta: {
          type: 'confirmed',
          excelCreateMenuKey: 'PRO_BUY_CONFIRM',
        },
      },
      {
        path: 'order/buy-confirm',
        name: 'OrderBuyConfirmIframe',
        component: OrderBuyConfirmIframe,
        meta: {
          type: 'confirmed',
          excelCreateMenuKey: 'PRO_BUY_CONFIRM',
        },
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/pay-fail-prev',
        name: 'OrderPayFail',
        component: OrderPayFail,
        meta: {
          type: 'failed',
          excelCreateMenuKey: 'PRO_PAY_FAIL',
        },
      },
      {
        path: 'order/pay-fail',
        name: 'OrderPayFailIframe',
        component: OrderPayFailIframe,
      },
      // FIXME - 리얼배포시점 삭제
      {
        path: 'order/delivery-hold-prev',
        name: 'OrderHoldDelivery',
        component: OrderHoldDelivery,
        meta: {
          type: 'deliveryHold',
          excelCreateMenuKey: 'PRO_HOLD_DELIVERY',
        },
      },
      {
        path: 'order/delivery-hold',
        name: 'OrderHoldDeliveryIframe',
        component: OrderHoldDeliveryIframe,
      },
      // FIXME - 리얼배포시점 삭제 ( 이전주문 )
      {
        path: 'order/preorder-prev',
        name: 'OrderPreOrder',
        component: OrderPreOrder,
        meta: {
          type: 'preorder',
          excelCreateMenuKey: 'PRO_RESERVATION_ORDER',
        },
      },
      {
        path: 'order/preorder',
        name: 'OrderPreOrderIframe',
        component: OrderPreOrderIframe,
        meta: {
          type: 'preorder',
          excelCreateMenuKey: 'PRO_RESERVATION_ORDER',
        },
      },
      // FIXME - 리얼배포시점 삭제 ( 이전주문 )
      {
        path: 'order/previous-prev',
        name: 'OrderPrevious',
        component: OrderPrevious,
        meta: {
          type: 'previous',
          excelCreateMenuKey: 'PRO_PREVIOUS_ORDER_LIST',
        },
      },
      {
        path: 'order/previous',
        name: 'PreviousOrdersIframe',
        component: PreviousOrdersIframe,
      },
      {
        path: 'without-bankbook/auto-deposit',
        name: 'AutoDeposit',
        component: AutoDeposit,
      },
      {
        path: 'without-bankbook/depositor',
        name: 'UndefinedDepositor',
        component: UndefinedDepositor,
      },
      {
        path: 'delivery/scanning',
        name: 'DeliveryScan',
        component: DeliveryScan,
      },
      {
        path: 'order/detail',
        name: 'OrderDetailIframe',
        component: OrderDetailIframe,
        meta: {
          breadcrumb: ['주문/배송', '주문관리', '주문상세'],
        },
      },
      // TODO: 임시로 추가, 추후 제거 예정
      {
        path: 'order/detail-prev',
        name: 'OrderDetail',
        component: OrderDetail,
        meta: {
          breadcrumb: ['주문/배송', '주문관리', '주문상세'],
        },
      },
      {
        path: 'order/previous-detail-prev',
        name: 'OrderPreviousDetail',
        component: OrderPreviousDetail,
        meta: {
          breadcrumb: ['주문/배송', '주문관리', '주문상세'],
        },
      },
      {
        path: 'order/previous-detail',
        name: 'OrderPreviousDetailIframe',
        component: OrderPreviousDetailIframe,
        meta: {
          breadcrumb: ['주문/배송', '주문관리', '주문상세'],
        },
      },
    ],
  };
};
