








import { Vue, Component } from 'vue-property-decorator';
import MallSelector from '@/components/MallSelector.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';

@Component({ components: { MallSelector, NoticeBox } })
export default class PgSetting extends Vue {
  private noticeList = [
    this.$t('CONFIGURATION.PG.NOTICE', {
      tagStart: '<a href="https://www.nhn-commerce.com/mygodo/myGodo_pgMain.php" target="_blank"><strong>',
      tagEnd: '</strong></a>',
    }),
  ];
}
