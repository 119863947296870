export default Object.freeze({
  // BEFORE_ 승인완료 이전 상품은 수정이 불가능합니다. // 승인완료, 수정 후 승인대기, 수정 후 승인거부
  ALERT_UPDATED: '수정되었습니다.',
  CONFIRM_CHANGE_TO_PROHIBITION_SALE: '판매금지로 변경 할 경우 판매재개가 불가능합니다.\n판매금지로 변경하시겠습니까?',
  CHANGE_SALES_SETTING_STATUS: '판매설정 변경',
  CHANGE_SALES_STATUS: '판매상태 변경',
  CHANGE_FRONT_DISPLAY: '전시상태 변경',
  CHANGE_CATEGORY: '카테고리 변경',
  ALL_PRODUCT_JUDGEMENT: '일괄 상품심사',
  CHANGE_ADMIN: '담당자 변경',
  SELECTED_CATEGORY: '선택 카테고리',

  TOOLTIP_CHANGE_CATEGORY:
    '카테고리 변경 도중에 전시카테고리가 선택 불가한 상태로 변경될 경우, 해당 카테고리는 선택한 카테고리에서 삭제 후 저장됩니다. \n' +
    '  (선택한 카테고리의 하위카테고리가 추가되거나, 선택한 전시카테고리가 삭제되는 등의 일부 예외 케이스 존재)',

  SELECT_SALES_SETTING_STATUS: '판매설정 선택',
  SELECT_SALES_STATUS: '판매상태 선택',
  SELECT_FRONT_DISPLAY: '전시상태 선택',

  DISPLAY_CATEGORY: '전시 카테고리',
  DISPLAY_CATEGORY_DETAIL: '전시 카테고리 정보',
  Y: '전시함',
  N: '전시 안함',

  TARGET_PRODUCT: '변경 대상상품',
  TREATMENT_METHOD: '처리방법',
  SELECT_TREATMENT_METHOD: '처리방법 선택',
  BULK_CHANGE_CATEGORY_METHOD_DESCRIPTION_START: '선택된 카테고리를 일괄로',
  BULK_CHANGE_CATEGORY_METHOD_DESCRIPTION_END: '합니다.',
  ALERT_BULK_CHANGE_DISPLAY_CATEGORY: '전시카테고리는 동일 쇼핑몰 상품인 경우에만 일괄 변경이 가능합니다.',

  CHANGE_SALES_STATUS_DIALOGUES: `
  선택 된 {selectedGridData}건의 상품에 대해 네이버쇼핑 EP 정보를 일괄 수정합니다.\n
  네이버페이 결제 불가 상품의 경우 입력 내용과 상관 없이 ‘네이버페이 사용 가능 표시/네이버페이 적립 가능 표시 항목’ 미체크 상태로 적용됩니다. 
  `,

  ALERT_CANNOT_BE_CHANGED: '선택하신 상품은 {value} 설정을 변경할 수 없습니다.',
  CONFIRM_CANNOT_BE_CHANGED_DROP:
    '선택하신 상품 중 {value} 설정을 변경할 수 없는 상품이 있습니다.\n해당 상품을 제외하고 변경하시겠습니까?',

  SUCCESS_N: '성공 {count}건',
  FAIL_N: '실패 {count}건',
  SUCCESS_RESULT: '{target} {method} 완료',
  ALERT_SOME_CHANGE_COMPLETE: '{name}(으)로 변경 완료',
  NON_EDITABLE_APPLY_STATUS: '승인완료 이전 상품',
  NON_EDITABLE_PROHIBITION_PRODUCT: '판매금지 상품',
  NON_EDITABLE_PRODUCT: '기타', // DO NOT TOUCH

  NOT_APPLIED: '승인완료 이전 상품',
  SALE_PROHIBITED: '판매금지 상품',
  NOT_FOUND: '기타',

  INVALID_PRODUCT_NO: '유효하지 않은 상품번호',
  OTHER_MALL_PRODUCT: '다른 쇼핑몰 상품',
  CHANGE_DISPLAY_CATEGORY_IS_EMPTY: '존재하지 않는 카테고리',
  UNKNOWN_ERROR: '기타',

  ALERT_CHANGE_SALES_STATUS_MISSING: '변경할 판매상태를 선택해주세요.',
  ALERT_CHANGE_SALES_SETTING_STATUS_MISSING: '변경할 판매설정을 선택해주세요.',
  ALERT_CHANGE_SALES_FRONT_DISPLAY_MISSING: '변경할 전시상태를 선택해주세요.',
  ALERT_CHANGE_DISPLAY_CATEGORY_MISSING: '전시 카테고리를 선택해주세요.',

  PRODUCT_JUDGE: '상품 심사',
  CHANGE_PRODUCT_JUDGE_N: '선택된 {count}건의 상품에 대해 일괄 상품심사를 진행합니다.',
  SALES_APPROVAL: '판매승인',
  APPROVAL_REJECTION: '승인거부',
  APPROVAL_COMPLETE: '승인완료',
  REGISTRATION_OF_APPROVAL: '승인거부 의견 등록',
  SEPARATE_APPROVAL: '별도 승인거부 의견',
  AMENDMENT_REQUEST: '수정요청',
  PLEASE_ENTER_YOUR: '승인거부의견을 입력해주세요',
  ALERT_CANNOT_JUDGE_PRODUCT: '선택하신 상품은 심사 불가능한 상품입니다.',
  CONFIRM_CANNOT_JUDGE_PRODUCT_DROP:
    '선택하신 상품 중 심사 불가능한 상품이 있습니다.\n해당 상품을 제외하고 변경하시겠습니까?',
  APPROVAL_IMPOSSIBLE_PRODUCT: '승인불가한 상품',

  CHANGE_ADMIN_DIALOGUES: `
    파트너사 상품은 담당자 변경 시 상품 재심사 대상이 되어 승인 처리가 필요합니다.
    수정 후 승인대기 상품을 담당자 변경할 경우 기존 수정사항이 삭제되니 승인처리 후 변경하시기 바랍니다.
    선택한 쇼핑몰의 상품만 담당자 일괄변경 가능하며, 판매금지 상품은 변경이 불가능합니다.
    변경 처리 중 담당자 권한이 변경 될 경우 실패처리 되오니, 중간에 권한이 변경되지 않도록 주의하세요.
  `,
  CHANGE_PRODUCT_ADMIN_EXCEL_FORM_NAME: '상품담당자변경',
  ALERT_ADMIN_CHANGE_COMPLETE: '담당자 변경이 완료되었습니다.',
  FAILED_CHANGE_PRODUCT_ADMIN_EXCEL_NAME: '상품담당자변경_실패결과',
});
