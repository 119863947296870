



import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';
@Component({
  components: { AdminIframe },
})
export default class ProductConfigIframe extends Vue {
  private remoteSrc = '';

  private setRemoteSrc() {
    const query = this.$route.query;
    const content = this.$route.params?.content ?? 'naver-shopping';
    const queryObject = { ...query, serviceType: 'PRO' };

    const queryString = new URLSearchParams(queryObject).toString();
    this.remoteSrc = process.env.VUE_APP_ADMIN_REMOTE_URL + `/marketing/product/${content}?` + queryString;
  }

  created() {
    this.setRemoteSrc();
  }
}
