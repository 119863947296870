export default Object.freeze({
  MODIFY_PERMISSION_GROUP: '권한그룹 수정',
  REGISTRATION_AUTHORITY_GROUP: '권한그룹 등록',
  BASIC_INFORMATION_OF_PERMISSION_GROUP: '권한그룹 기본정보',
  CHOOSE_MALL: '쇼핑몰 선택',
  THE_PERMISSION_GROUP: '권한그룹명 입력',
  PERMISSION_GROUP_NAME: '권한그룹명',
  REPEAT_CONFIRMATION: '중복확인',
  THE_PERMISSION_GROUP_THAT: '사용할 수 있는 권한 그룹명 입니다.',
  PLEASE_ENTER_PERMISSION_GROUP_NAME: '권한그룹명을 입력해주세요.',
  DUPLICATE_WITH_OTHER_PERMISSION_GROUP_NAME: '다른 권한그룹명과 중복입니다.',
  PERMISSION_GROUP_DESCRIPTION: '권한그룹 설명',
  ENTER_PERMISSION_GROUP_DESCRIPTION: '권한그룹 설명 입력',
  VIEW_PERSONAL_INFORMATION_LIST: '개인정보 목록조회',
  CLAIM_MANAGEMENT_MENUS: '주문관리 및 클레임관리 메뉴는 해당사항 없음',
  COMMODITY_OWNER: '상품담당자',
  MENU_PERMISSIONS: '메뉴 권한',
  MENU_NAME: '메뉴명',
  MENU_PERMISSIONS_NO_SP: '메뉴권한',
  GLOBAL_SETTINGS: '전체 설정',
  MAYBE_MAYBE: '가능',
  PRODUCTMANAGER: '상품등록/관리를 담당하는 MD 권한',
  WANT_TO_DELETE_THIS_PERMISSION_GROUP: '삭제한 권한그룹 정보는 복구되지 않습니다. 이 권한그룹을 삭제 하시겠습니까',
  THE_PERMISSION_GROUP_HAS_BEEN_DELETED: '권한그룹이 삭제 되었습니다.',
  UNREGISTER_THE_PERMISSION_GROUP: '권한그룹 등록을 취소하시겠습니까? 입력하신 내용은 저장되지 않습니다.',
  NO_SHOPPING_CENTERS_TO_CHOOSE_FROM: '선택된 쇼핑몰이 없습니다. 최소 1개의 쇼핑몰을 설정 해 주세요.',
  PLEASE_CONFIRM_THE_PERMISSION_GROUP_NAME_AGAIN: '권한그룹명 중복확인을 진행해 주세요',
  COMPLETE_THE_REGISTRATION_OF_PERMISSION_GROUP: '권한그룹 등록을 완료 하시겠습니까?',
  CHANGES_HAVE_BEEN_SAVED: '변경사항이 저장 되었습니다.',
  NEW_PERMISSION_GROUP_HAS_BEEN_REGISTERED_NORMALLY: '신규 권한그룹이 정상 등록 되었습니다.',
});
