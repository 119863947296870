







































import { Vue, Component, PropSync, Watch, Prop, Ref } from 'vue-property-decorator';
import { NCPResponse, PlaceOrigin } from 'ncp-api-supporter';
import SelectBox from '@/components/common/SelectBox.vue';
import ChainingSelector from '@/components/common/ChainingSelector.vue';
import { OptionData, ValueType } from '@/helpers/type';
import { PlaceOriginInformation } from '@/types';
import TextInput from '@/components/common/input/TextInput.vue';
import { i18nForProduct } from '@/views/contents/product/basic/ProductAdd';

const DEPTH_TYPE = {
  country: 'country',
  depth1: 'depth1',
} as const;

@Component({
  components: { TextInput, ChainingSelector, SelectBox },
})
export default class PlaceOriginInfo extends Vue {
  @PropSync('placeOriginInfo')
  private placeOriginInfoSync!: PlaceOriginInformation;

  @Prop({ required: true })
  private readonly isDeepSea: boolean;

  @Prop({ required: true })
  private readonly disabled: boolean;

  @Ref()
  private readonly placeOriginRef: SelectBox[];

  @Ref()
  private readonly nameRef: TextInput;

  public focus(type: 'placeOrigin' | 'name'): void {
    type === 'name' && this.nameRef.focus();
    // if (type === 'placeOrigin') {
    //   const idx = this.placeOriginRef.findIndex(info => !info.value);
    //   this.placeOriginRef[idx].focus();
    // } else {
    //   this.nameRef.focus();
    // }
  }

  private get isEtc(): boolean {
    return this.currOriginCountry === i18nForProduct('ETC');
  }

  private get isImported(): boolean {
    const isImported = this.currOriginCountry === i18nForProduct('IMPORTED');
    this.placeOriginInfoSync.isImported = isImported;
    return isImported;
  }

  private get isDirect(): boolean {
    const isDirect = this.currOriginState === i18nForProduct('DIRECT_INPUT_WITHOUT_SPACE');
    this.placeOriginInfoSync.isDirect = isDirect;
    return isDirect;
  }

  private get depthData() {
    return [
      { data: this.originPlacesType, model: this.currOriginCountry },
      { data: this.originStates, model: this.isEtc ? this.placeOriginInfoSync.placeOriginSeq : this.currOriginState },
      { data: this.originSpecificStates, model: this.placeOriginInfoSync.placeOriginSeq },
    ];
  }

  private get depthSize(): number {
    return this.depthData?.length - 1;
  }

  private currOriginCountry = ''; // 1depth
  private currOriginState = ''; // 2depth
  private get placeOriginSeq(): number {
    return this.placeOriginInfoSync.placeOriginSeq === '' ? 0 : this.placeOriginInfoSync.placeOriginSeq;
  }
  @Watch('placeOriginSeq', { deep: true })
  private async currOriginInfo() {
    if (this.placeOriginSeq === 0) return;
    if (this.placeOrigins.length === 0) {
      await this.fetchPlaceOrigins();
    }
    const found = this.placeOrigins.find(country => country.originNo === this.placeOriginSeq);
    if (found === undefined) return;
    this.currOriginCountry = found.originCountry;
    this.currOriginState = found.depth1;
    // this.placeOriginInfoSync.isImported = this.isImported;
  }
  private chosenOriginData(chosenData: string | number, _, { dataset: { depth } }) {
    this.placeOriginInfoSync.use = chosenData !== '';
    if (depth === DEPTH_TYPE.country) {
      this.currOriginCountry = chosenData as string;
      this.resetForChangingOriginCountry();
      this.setEtcState();
      return;
    }
    if (depth === DEPTH_TYPE.depth1) {
      this.currOriginState = chosenData.toString();
      this.setEtcState(Number(chosenData));
      return;
    }
    this.placeOriginInfoSync.placeOriginSeq = Number(chosenData);
  }

  public resetAllOriginCountry() {
    this.currOriginCountry = '';
    this.resetForChangingOriginCountry();
  }

  private resetForChangingOriginCountry() {
    this.currOriginState = '';
    this.placeOriginInfoSync.placeOriginSeq = 0;
    this.placeOriginInfoSync.placeOrigin = '';
  }

  private setEtcState(chosenData?: number): void {
    if (!this.isEtc) return;

    const { name, value } = this.depthData[1].data[0] as OptionData<number>;

    if (chosenData > 0) {
      this.placeOriginInfoSync.placeOriginSeq = chosenData > 0 ? chosenData : value;
      return;
    }
    // 기타 > depth2 (상세참조 기본값으로 설정)
    this.$nextTick(() => {
      (this.placeOriginRef[1].$el as HTMLSelectElement).value = value.toString();
      this.placeOriginInfoSync.placeOriginSeq = value;
    });
    this.currOriginState = name;
  }

  private async created() {
    await this.fetchPlaceOrigins();
  }
  private mounted() {
    this.currOriginInfo();
  }

  private placeOrigins: PlaceOrigin[] = [];
  private async fetchPlaceOrigins(): Promise<void> {
    const result: NCPResponse<PlaceOrigin[]> = await this.$api.getPlaceOrigins();
    this.placeOrigins = result.data;
  }

  @Prop({ required: true })
  private readonly categoryNo: number;
  private get originPlacesType(): OptionData<string>[] {
    const setCountry = new Set<string>();
    this.placeOrigins.forEach(info => {
      this.isDeepSea && !this.categoryNo
        ? setCountry.add(info.originCountry)
        : info.originCountry !== i18nForProduct('PLACE_ORIGIN_OY') && setCountry.add(info.originCountry);
    });
    return [...setCountry].map(country => ({ name: country, value: country }));
  }

  private getEtcSelectData() {
    const setEtc = new Set<{ name: string; value: number }>();
    this.placeOrigins.forEach(item => {
      if (item.originCountry === this.currOriginCountry) {
        setEtc.add({ name: item.depth1, value: item.originNo });
      }
    });
    return [...setEtc];
  }

  private getStatesSelectData() {
    const setStates = new Set<string>();
    this.placeOrigins.forEach(item => {
      if (item.originCountry === this.currOriginCountry) {
        setStates.add(item.depth1);
      }
    });
    return [...setStates].map(origin => ({ name: origin, value: origin }));
  }

  private get originStates(): string[] | OptionData<ValueType>[] {
    if (!this.currOriginCountry) return [];
    // 국산: 도  , 수입산: 대륙(6대주)
    const isCountryType =
      this.currOriginCountry === i18nForProduct('DOMESTIC') || this.currOriginCountry === i18nForProduct('IMPORTED');
    return isCountryType ? this.getStatesSelectData() : this.getEtcSelectData();
  }

  private get originSpecificStates(): OptionData<number>[] {
    if (!this.currOriginState) return [];
    // 국산: 시  , 수입산: 나라
    const set = new Set<{ name: string; value: number }>();
    this.placeOrigins.forEach(item => {
      if (item.depth1 === this.currOriginState) {
        if (item.depth2 !== i18nForProduct('SELECT')) {
          set.add({ name: item.depth2, value: item.originNo });
        }
      }
    });
    return [...set];
  }
}
