import { GridProp } from '@/types';
import { ClaimType } from '@/types/claim';
import { i18n } from '@/main';
import { getAnchorHTML, emptyValueFormatter, getHighlightHTML } from '@/utils/grid/formatterUtils';
import { claimStatusTypes } from '@/const/claim';
import { orderStatusTypes } from '@/const/order';

export const columnFormat = {
  claimYmdt: {
    header: '',
    name: 'claimYmdt',
    minWidth: 131,
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  claimCompleteYmdt: {
    header: '',
    name: 'claimCompleteYmdt',
    minWidth: 131,
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  procEachBtn: {
    header: 'CLAIM.COMMON.CLAIM_PROC',
    name: 'procEachBtn',
    minWidth: 141,
    align: 'center',
  },
  claimCnt: {
    header: '',
    name: 'claimCnt',
    minWidth: 61,
    align: 'left',
    formatter: ({ value }) => {
      const HIGHLIGHT_START_COUNT = 1;

      if (value > HIGHLIGHT_START_COUNT) {
        return getHighlightHTML(value);
      }

      return value;
    },
    copyOptions: {
      useFormattedValue: true,
      customValue: data => {
        return data;
      },
    },
  },
};

export const claimListColumns = {
  취소신청일시: {
    ...columnFormat.claimYmdt,
    header: 'CLAIM.COMMON.CANCEL_YMDT',
  },
  취소완료일시: {
    ...columnFormat.claimCompleteYmdt,
    header: 'CLAIM.COMMON.CANCEL_COMPLETE_YMDT',
  },
  교환신청일시: {
    ...columnFormat.claimYmdt,
    header: 'CLAIM.COMMON.EXCHANGE_YMDT',
  },
  교환완료일시: {
    ...columnFormat.claimCompleteYmdt,
    header: 'CLAIM.COMMON.EXCHANGE_COMPLETE_YMDT',
  },
  반품신청일시: {
    ...columnFormat.claimYmdt,
    header: 'CLAIM.COMMON.RETURN_YMDT',
  },
  반품완료일시: {
    ...columnFormat.claimCompleteYmdt,
    header: 'CLAIM.COMMON.RETURN_COMPLETE_YMDT',
  },
  환불신청일시: {
    ...columnFormat.claimYmdt,
    header: 'CLAIM.COMMON.REFUND_YMDT',
  },
  환불완료일시: {
    ...columnFormat.claimCompleteYmdt,
    header: 'CLAIM.COMMON.REFUND_COMPLETE_YMDT',
    name: 'completeYmdt',
  },
  반품수거방법: {
    header: 'CLAIM.COMMON.RETURN_WAY_TYPE',
    name: 'returnWayType',
    minWidth: 131,
    align: 'center',
    formatter: row => {
      return row.value ? i18n.t(`CLAIM.COMMON.RETURN_WAY_TYPE_${row.value}`) : '-';
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  취소클레임처리: {
    ...columnFormat.procEachBtn,
    formatter: ({ row }) => {
      // 취소리스트 p.30,
      const $cancelAccept = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.CANCEL_ACCEPT'), 'cancel_accept');
      const $alreadyDelivery = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.ALREADY_DELIVERY'), 'already_delivery');
      const $detail = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.SHOW_DETAIL'), 'cancel_detail');

      if (row.claimStatusType === claimStatusTypes.CANCEL_REQUEST) {
        if (row.orderStatusType === orderStatusTypes.PAY_DONE) {
          return $cancelAccept;
        }

        if (
          row.orderStatusType === orderStatusTypes.PRODUCT_PREPARE ||
          row.orderStatusType === orderStatusTypes.DELIVERY_PREPARE
        ) {
          return `${$cancelAccept} ${row.holdProcess ? '' : $alreadyDelivery}`;
        }

        return $detail;
      }

      return $detail;
    },
  },
  교환클레임처리: {
    ...columnFormat.procEachBtn,
    formatter: ({ row }) => {
      // 교환리스트 기획서 p.39
      const $exchangeAccept = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.EXCHANGE_ACCEPT'), 'exchange_accept');
      const $alreadyDelivery = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.ALREADY_DELIVERY'), 'already_delivery');
      const $collect = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.COLLECT_COMPLETE'), 'collect');
      const $disapprovalAccept = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.DISAPPROVAL_ACCEPT'), 'exchange_detail');
      const $detail = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.SHOW_DETAIL'), 'exchange_detail');

      //주문관리 정책서 p.66-클레임 정책 - TODO: 입금대기 상태도 조건으로 추가해야하는지 확인 필요
      // const isCancelExchange =
      //   row.orderStatusType == orderStatusTypes.DEPOSIT_WAIT ||
      //   row.orderStatusType == orderStatusTypes.PAY_DONE ||
      //   row.orderStatusType == orderStatusTypes.PRODUCT_PREPARE ||
      //   row.orderStatusType == orderStatusTypes.DELIVERY_PREPARE;
      const isReturnExchange =
        row.orderStatusType == orderStatusTypes.PAY_DONE ||
        row.orderStatusType == orderStatusTypes.DELIVERY_ING ||
        row.orderStatusType == orderStatusTypes.DELIVERY_DONE ||
        row.orderStatusType == orderStatusTypes.BUY_CONFIRM;

      if (row.claimStatusType === claimStatusTypes.EXCHANGE_REQUEST) {
        if (isReturnExchange) {
          return $exchangeAccept;
        }

        return `${$exchangeAccept} ${row.holdProcess ? '' : $alreadyDelivery}`;
      }

      if (row.claimStatusType === claimStatusTypes.RETURN_REJECT_REQUEST) {
        return $disapprovalAccept;
      }

      if (row.claimStatusType === claimStatusTypes.EXCHANGE_REJECT_REQUEST) {
        return $disapprovalAccept;
      }

      if (row.claimStatusType === claimStatusTypes.EXCHANGE_PROC_BEFORE_RECEIVE) {
        if (isReturnExchange) {
          return $collect;
        }
        return $detail;
      }

      return $detail;
    },
  },
  반품클레임처리: {
    ...columnFormat.procEachBtn,
    formatter: ({ row }) => {
      // 반품리스트 기획서 p.30
      const $returnAccept = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.RETURN_ACCEPT'), 'return_accept');
      const $disapprovalAccept = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.DISAPPROVAL_ACCEPT'), 'exchange_detail');
      const $collect = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.COLLECT_COMPLETE'), 'collect');
      const $detail = getAnchorHTML(i18n.t('CLAIM.ALL_LIST.SHOW_DETAIL'), 'return_detail');

      if (row.claimStatusType === claimStatusTypes.RETURN_REQUEST) {
        return $returnAccept;
      }

      if (row.claimStatusType === claimStatusTypes.RETURN_REJECT_REQUEST) {
        return $disapprovalAccept;
      }

      if (row.claimStatusType === claimStatusTypes.RETURN_PROC_BEFORE_RECEIVE) {
        return $collect;
      }

      return $detail;
    },
  },
  송장번호: {
    header: 'CLAIM.COMMON.INVOICE_NO',
    name: 'invoiceNo',
    minWidth: 101,
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  반품송장번호: {
    header: 'CLAIM.COMMON.RETURN_INVOICE_NO',
    name: 'returnInvoiceNo',
    minWidth: 101,
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
  },
  클레임상태: {
    header: 'CLAIM.COMMON.CLAIM_STATUS_TYPE',
    name: 'claimStatusType',
    minWidth: 136,
    align: 'center',
    formatter: data => {
      if (!data.value) {
        return '';
      }
      return i18n.t(`CLAIM.CLAIM_STATUS_TYPE.${data.value}`);
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  클레임번호: {
    header: 'CLAIM.COMMON.CLAIM_NO',
    name: 'claimNo',
    minWidth: 101,
    align: 'center',
    formatter: ({ value }) => getAnchorHTML(value),
  },
  쇼핑몰구분: {
    header: 'CLAIM.COMMON.MALL_NAME',
    name: 'mallName',
    minWidth: 101,
    align: 'center',
  },
  주문구분: {
    header: 'CLAIM.COMMON.REGISTER_TYPE',
    name: 'orderRegisterType',
    minWidth: 61,
    align: 'center',
    formatter: row => {
      return i18n.t(`CLAIM.COMMON.REGISTER_TYPE_${row.value}`);
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  플랫폼구분: {
    header: 'CLAIM.COMMON.PLATFORM_TYPE',
    name: 'platformType',
    minWidth: 76,
    align: 'center',
    formatter: row => {
      return i18n.t(`CLAIM.COMMON.PLATFORM_TYPE_${row.value}`);
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  예약주문: {
    header: 'CLAIM.COMMON.RESERVED',
    name: 'reserved',
    minWidth: 61,
    align: 'center',
    formatter: row => {
      return row.value ? 'Y' : '-';
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  결제수단: {
    header: 'CLAIM.COMMON.PAYTYPE',
    name: 'payType',
    minWidth: 81,
    align: 'center',
    formatter: data => {
      return i18n.t(`ORDER.PAY_TYPE.${data.value}`);
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  배송보류: {
    header: 'CLAIM.COMMON.HOLD_PROCESS',
    name: 'holdProcess',
    minWidth: 61,
    align: 'center',
    formatter: data => {
      return data.value ? 'Y' : '-';
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  배송구분: {
    // 배송구분
    header: 'CLAIM.COLUMNS.SHIPPING.AREA.TYPE',
    name: 'shippingAreaType',
    minWidth: 101,
    align: 'center',
    formatter: ({ row }) => {
      return row.partnerNo === row.deliveryPartnerNo
        ? i18n.t('CLAIM.COLUMNS.PARTNER.SHIPPING')
        : i18n.t('CLAIM.COLUMNS.MALL.SHIPPING');
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  업무메세지: {
    header: 'CLAIM.COMMON.TASK_MESSAGE',
    name: 'hasTaskMessage',
    minWidth: 81,
    align: 'center',
    formatter: function(data) {
      const { taskMessageCnt, taskMessageProcessCnt } = data.row;
      // taskMessageCnt > 0 이면 처리중으로 표시
      if (taskMessageCnt === 0) {
        return '';
      }

      if (taskMessageProcessCnt > 0) {
        return `<a href="#"><span class="badge-task"/></a>`;
      }

      return `<a href="#"><span class="badge-task02"/></a>`;
    },
    copyOptions: {
      useFormattedValue: true,
      customValue: data => {
        if (data === true) {
          return 'Y';
        } else {
          return '';
        }
      },
    },
  },
  회원그룹: {
    header: 'CLAIM.COMMON.MEMBER_GROUP_INFO_JSON',
    name: 'memberGroupInfoJson',
    minWidth: 61,
    align: 'center',
    formatter: data => {
      if (data.value == null || data.value === '' || data.value === '[]') {
        return '-';
      }

      const memberGroupInfo = JSON.parse(data.value);
      if (memberGroupInfo === null || memberGroupInfo.length <= 0) {
        return '-';
      } else if (memberGroupInfo.length == 1) {
        return memberGroupInfo[0].memberGroupName;
      }

      return `<a href="javascript:;" class="member-group-name-grid" data-json='${JSON.stringify(
        memberGroupInfo,
      ).replace(/"/g, '\\"')}'>${memberGroupInfo[0].memberGroupName}</a>`;
    },
    copyOptions: {
      useFormattedValue: true,
      customValue: function(data) {
        const FIRST_GROUP = 0;
        if (data == null || data === '' || data === '[]') {
          return '-';
        }

        const memberGroupInfo = JSON.parse(data);
        if (memberGroupInfo === null || memberGroupInfo.length <= 0) {
          return '-';
        } else if (memberGroupInfo.length == 1) {
          return memberGroupInfo[FIRST_GROUP].memberGroupName;
        }

        return memberGroupInfo[FIRST_GROUP].memberGroupName;
      },
    },
  },
  주문번호: {
    header: 'CLAIM.COMMON.ORDER_NO',
    name: 'orderNo',
    minWidth: 161,
    align: 'center',
    formatter: ({ value, row }) => {
      const naverPayDiv =
        row.payType === 'NAVER_PAY'
          ? `<div>(<span style="color: #03CF5D">N</span>: ${row?.externalOrderNo ?? '-'})</div>`
          : '';
      return `${getAnchorHTML(value)}${naverPayDiv}`;
    },
  },
  주문자명: {
    header: 'CLAIM.COMMON.ORDERER_NAME',
    align: 'center',
    minWidth: 80,
    name: 'ordererName',
    formatter: ({ value, row }) => {
      if (row.memberStatus === 'WITHDRAWN') {
        return i18n.t('CLAIM.COMMON.WITHDRAWAL_MEMBER');
      }
      return row.memberNo !== 0 ? getAnchorHTML(value) : value;
    },
    copyOptions: {
      useFormattedValue: false,
    },
  },
  '주문자 연락처': {
    header: 'CLAIM.COMMON.ORDERER_CONTACT',
    name: 'ordererContact',
    minWidth: 101,
    align: 'center',
    formatter: data => {
      return data.value;
    },
  },
  회원번호: {
    header: 'CLAIM.COMMON.MEMBER_NO',
    name: 'memberNo',
    minWidth: 81,
    align: 'center',
    formatter: data => {
      return data.value;
    },
  },
  수령자명: {
    header: 'CLAIM.COMMON.RECEIVER_NAME',
    name: 'receiverName',
    minWidth: 71,
    align: 'center',
    formatter: data => {
      return data.value;
    },
  },
  수령자연락처: {
    header: 'CLAIM.COMMON.RECEIVER_CONTACT',
    name: 'receiverContact',
    minWidth: 101,
    align: 'center',
    formatter: data => {
      return data.value;
    },
  },
  상품번호: {
    header: 'CLAIM.COMMON.MALL_PRODUCT_NO',
    name: 'mallProductNo',
    minWidth: 81,
    align: 'center',
    formatter: data => {
      return data.value;
    },
  },
  상품관리코드: {
    header: 'CLAIM.COMMON.PRODUCT_MANAGEMENT_CD',
    name: 'productManagementCd',
    minWidth: 81,
    align: 'center',
    formatter: data => {
      return data.value;
    },
  },
  상품명: {
    header: 'CLAIM.COMMON.PRODUCT_NAME',
    name: 'productName',
    minWidth: 201,
    align: 'left',
  },
  옵션관리코드: {
    header: 'CLAIM.COMMON.OPTION_MANAGEMENT_CD',
    name: 'optionManagementCd',
    minWidth: 81,
    align: 'left',
    formatter: data => {
      return data.value;
    },
  },
  '옵션명:옵션값': {
    header: 'CLAIM.COMMON.DISPLAY_OPTION_NAME',
    name: 'displayOptionName',
    minWidth: 151,
    align: 'left',
    formatter: ({ row }) => {
      return `${row.optionName}:${row.optionValue}`;
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  취소수량: {
    ...columnFormat.claimCnt,
    header: 'CLAIM.COMMON.CNT_CANCEL',
  },
  교환수량: {
    ...columnFormat.claimCnt,
    header: 'CLAIM.COMMON.CNT_EXCHANGE',
  },
  반품수량: {
    ...columnFormat.claimCnt,
    header: 'CLAIM.COMMON.CNT_RETURN',
  },
  환불수량: {
    ...columnFormat.claimCnt,
    header: 'CLAIM.COMMON.CNT_REFUND',
  },
  상품수: {
    ...columnFormat.claimCnt,
    header: 'CLAIM.COMMON.CNT_PRODUCT',
  },
  회원등급: {
    header: 'CLAIM.COMMON.MEMBER_GRADE',
    name: 'memberGradeName',
    minWidth: 131,
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  택배사: {
    header: 'CLAIM.COMMON.DELIVERY_COMPANY_TYPE',
    name: 'deliveryCompanyType',
    minWidth: 131,
    align: 'center',
    formatter: data => {
      if (data.value === null) {
        return '-';
      }
      return i18n.t(`CLAIM.RETURNPOPUP.${data.value}`);
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  수거지주소: {
    header: 'CLAIM.COMMON.RETURN.ADDRESS',
    name: 'address',
    minWidth: 302,
    align: 'left',
    formatter: ({ row }) => {
      return `${row.zipCd ? `(${row.zipCd})` : ''} ${row.address} ${row.detailAddress}`;
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  수거시참고사항: {
    header: 'CLAIM.COMMON.RETURN.MEMO',
    name: 'note',
    minWidth: 121,
    align: 'left',
  },
  즉시할인가: {
    header: 'CLAIM.COMMON.IMMEDIATE_DISCOUNT_PRICE',
    name: 'immediateDiscountedPrice',
    align: 'right',
    minWidth: 80,
    formatter: data => {
      const price = data.row.salePrice + data.row.addPrice - data.row.immediateDiscountAmt;
      return price.toLocaleString();
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  취소금액: {
    header: 'CLAIM.COMMON.CANCEL_CLAIM_AMT',
    name: 'claimAmt',
    align: 'right',
    minWidth: 80,
    formatter: data => {
      return (data.value * -1).toLocaleString();
    },
  },
  교환금액: {
    header: 'CLAIM.COMMON.EXCHANGE_CLAIM_AMT',
    name: 'claimAmt',
    align: 'right',
    minWidth: 80,
    formatter: data => {
      return (data.value * -1).toLocaleString();
    },
  },
  반품금액: {
    header: 'CLAIM.COMMON.RETURN_CLAIM_AMT',
    name: 'claimAmt',
    align: 'right',
    minWidth: 80,
    formatter: data => {
      return (data.value * -1).toLocaleString();
    },
  },
  파트너사: {
    header: 'ORDER.LIST_GRIDS.HEADER_PARTER_NO',
    name: 'partnerNo',
    align: 'center',
    minWidth: 100,
    formatter: function(data) {
      return data.row.shippingAreaType === 'MALL_SHIPPING_AREA' ? '쇼핑몰 자체 상품' : data.row.partnerName;
    },
  },
  환불금액: {
    header: 'CLAIM.COMMON.REFUND_CLAIM_AMT',
    name: 'claimAmt',
    align: 'right',
    minWidth: 80,
    formatter: ({ value }) => {
      return Number(value * -1).toLocaleString();
    },
  },
  환불수단: {
    header: 'CLAIM.COMMON.REFUND_PAY_TYPE',
    align: 'center',
    minWidth: 100,
    name: 'refundType',
    formatter: ({ value }) => {
      if (!value) {
        return '-';
      }

      return i18n.t(`CLAIM.COMMON.${value}_REFUND`);
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  교환여부: {
    header: 'CLAIM.COMMON.EXCHANGED',
    align: 'center',
    minWidth: 100,
    name: 'exchanged',
    formatter: ({ value }) => (value ? 'Y' : '-'),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  주문일시: {
    header: 'CLAIM.COMMON.ORDER_YMDT',
    align: 'center',
    minWidth: 100,
    name: 'orderYmdt',
    formatter: ({ value }) => value,
    copyOptions: {
      useFormattedValue: true,
    },
  },

  결제일시: {
    header: 'CLAIM.COMMON.PAY_YMDT',
    minWidth: 100,
    name: 'payYmdt',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  상품준비중처리일시: {
    header: 'CLAIM.COMMON.ORDER_ACCEPT_YMDT',
    minWidth: 120,
    name: 'orderAcceptYmdt',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  배송준비중처리일시: {
    header: 'CLAIM.COMMON.RELEASE_READY_YMDT',
    minWidth: 120,
    name: 'releaseReadyYmdt',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  배송중처리일시: {
    header: 'CLAIM.COMMON.RELEASE_YMDT',
    minWidth: 120,
    name: 'releaseYmdt',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  배송완료처리일시: {
    header: 'CLAIM.COMMON.DELIVERY_COMPLETE_YMDT',
    minWidth: 120,
    name: 'deliveryCompleteYmdt',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  구매확정일시: {
    header: 'CLAIM.COMMON.BUY_CONFIRM_YMDT',
    minWidth: 100,
    name: 'buyConfirmYmdt',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  공급가: {
    header: 'CLAIM.COMMON.PURCHASE_PRICE',
    minWidth: 100,
    name: 'purchasePrice',
    align: 'right',
    formatter: ({ value }) => {
      return value.toLocaleString();
    },
    copyOptions: {
      useFormattedValue: true,
    },
  },
  아이디: {
    header: 'CLAIM.COMMON.MEMBER_ID',
    minWidth: 100,
    name: 'memberId',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
  주문상품옵션번호: {
    header: 'CLAIM.COMMON.ORDER_PRODUCT_OPTION_NO',
    minWidth: 100,
    name: 'orderOptionNo',
    align: 'center',
    formatter: ({ value }) => emptyValueFormatter(value),
    copyOptions: {
      useFormattedValue: true,
    },
  },
};

export const getClaimColumns = (claimType: ClaimType) => {
  const columns = {
    CANCEL: [
      '취소신청일시',
      '취소완료일시',
      '플랫폼구분',
      '회원등급',
      '택배사',
      '송장번호',
      '취소클레임처리',
      '클레임상태',
      '클레임번호',
      '쇼핑몰구분',
      '교환여부',
      '예약주문',
      '결제수단',
      '배송보류',
      '업무메세지',
      '주문번호',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '취소수량',
      '즉시할인가',
      '공급가',
      '취소금액',
      '주문자명',
      // '아이디', 오픈스펙 제외
      '주문자 연락처',
      '수령자명',
      '수령자연락처',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
    ],
    EXCHANGE: [
      '교환신청일시',
      '교환완료일시',
      '플랫폼구분',
      '회원등급',
      '택배사',
      '송장번호',
      '반품송장번호',
      '교환클레임처리',
      '클레임상태',
      '클레임번호',
      '쇼핑몰구분',
      '교환여부',
      '예약주문',
      '결제수단',
      '배송보류',
      '업무메세지',
      '주문번호',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '교환수량',
      '즉시할인가',
      '공급가',
      '교환금액',
      '주문자명',
      // '아이디', 오픈스펙 제외
      '주문자 연락처',
      '수령자명',
      '수령자연락처',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
    ],
    RETURN: [
      '반품신청일시',
      '반품완료일시',
      '플랫폼구분',
      '회원등급',
      '택배사',
      '송장번호',
      '반품송장번호',
      '반품클레임처리',
      '클레임상태',
      '클레임번호',
      '쇼핑몰구분',
      '교환여부',
      '예약주문',
      '결제수단',
      '업무메세지',
      '주문번호',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '반품수량',
      '즉시할인가',
      '공급가',
      '반품금액',
      '주문자명',
      // '아이디', 오픈스펙 제외
      '주문자 연락처',
      '수령자명',
      '수령자연락처',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
    ],
    REFUND: [
      '환불신청일시',
      '환불완료일시',
      '플랫폼구분',
      '회원등급',
      '클레임상태',
      '클레임번호',
      '쇼핑몰구분',
      '예약주문',
      '결제수단',
      '업무메세지',
      '주문번호',
      '환불수량',
      '환불금액',
      '환불수단',
      // '주문구분',
      '주문자명',
      // '아이디', 오픈스펙 제외
      '주문자 연락처',
      '수령자명',
      '수령자연락처',
      '회원번호',
      '주문상품옵션번호',
    ],
  };

  return columns[claimType].map(columnName => claimListColumns[columnName]);
};

export function getGridProps(claimType: ClaimType): GridProp {
  return {
    header: {
      height: 33,
    },
    columns: [...getClaimColumns(claimType)],
    options: {
      rowHeaders: ['checkbox'],
      columnOptions: {
        minWidth: 50,
        resizable: true,
      },
      minRowHeight: 31,
      rowHeight: 37,
      bodyHeight: 480,
      useClientSort: false,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
}
