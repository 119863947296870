




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductPaymentType, AppProductType } from 'ncp-api-supporter';

@Component({})
export default class AppModalHeader extends Vue {
  @Prop({ required: true }) appNo!: number;
  @Prop({ required: true }) appName!: string;
  @Prop({ required: true }) teamName!: string;
  @Prop({ required: true }) paymentType!: ProductPaymentType;
  @Prop({ required: true }) iconUrl!: string;
  @Prop({ required: true }) productType!: AppProductType;

  private handleRedirectBtn() {
    const url = process.env.VUE_APP_APP_STORE_URL + '/apps/' + this.appNo.toString();
    window.open(url, '_blank');
  }
}
