



































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatCurrency } from '@/utils/numberFormat';
import { PostMemberGradeInfo, PostMemberGradesRequest } from 'ncp-api-supporter';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import { PutMemberGradesGradeNoRequest } from 'ncp-api-supporter/dist/types';
import { getCurrentMallNo } from '@/utils/mall';
import { PopupClose, PopupResult, throwWindowPopup } from '@/helpers/popup';
import { TranslateResult } from 'vue-i18n';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({ components: { TextInput, RadioGroup } })
export default class ModifyGrade extends Vue {
  @Prop() private data;
  @Prop() private onPositiveClick!: Function;
  @Prop() private onNegativeClick!: Function;

  private info: PostMemberGradeInfo = {
    name: '',
    mallNo: getCurrentMallNo(this),
    description: '',
    used: true,
    evaluationCondition: {
      minimumCount: 0,
      minimumPayment: 0,
    },
    reserveAutoSupplying: {
      used: true,
      amount: 0,
      type: 'MONTHLY',
    },
    reserveBenefit: {
      used: true,
      reserveRate: 0,
    },
    couponNos: [],
  };

  private gradeUsedRadioOption: RadioBoxGroupOption<boolean> = {
    name: `gradeUsed`,
    data: [
      { id: `grade_use`, value: true, display: 'MEMBER.GRADE.USE' },
      { id: `grade_not_use`, value: false, display: 'MEMBER.GRADE.NOT_USE' },
    ],
  };
  private reserveAutoSupplyingUsedRadioOption: RadioBoxGroupOption<boolean> = {
    name: `reserveAutoSupplying`,
    data: [
      { id: `reserve_auto_supplying_use`, value: true, display: 'MEMBER.GRADE.USE' },
      { id: `reserve_auto_supplying_not_use`, value: false, display: 'MEMBER.GRADE.NOT_USE' },
    ],
  };

  private reserveRate = '';
  private preReserveRate = '';

  private regularAmount = '';
  private preRegularAmount = '';

  private couponIssueTimeType = 'IMMEDIATELY_ISSUE';
  private coupons = [];
  private couponsItem = [];

  private isEditMode(): boolean {
    return !!this.data.grade;
  }

  private isDefaultGrade(): boolean {
    return this.isEditMode() && this.data.order === 0;
  }

  private get gradeTitle(): TranslateResult {
    return this.isEditMode() ? this.$t('MEMBER.GRADE.EDIT_GRADE') : this.$t('MEMBER.GRADE.ADD_GRADE');
  }

  created() {
    if (this.isEditMode()) {
      this.info = { ...this.info, ...this.data.grade };
      this.info.reserveBenefit.used = this.info.reserveAutoSupplying.used;
      this.couponsItem = [...this.data.grade.coupons];

      this.couponsItem.map(c => {
        this.coupons.push(c.couponNo);
      });

      this.reserveRate = this.info.reserveBenefit.reserveRate + '';
      this.regularAmount = this.info.reserveAutoSupplying.amount + '';
    }
  }

  // post member grade (add grade)
  private postMemberGrades(): void {
    const request: PostMemberGradesRequest = { data: { ...this.info, couponNos: this.coupons } };
    request.data.reserveBenefit.used = request.data.reserveAutoSupplying.used;
    this.$api.postMemberGrades(request).then(response => {
      if (response && response.status === 201) {
        this.onPositiveClick(true);
      }
    });
  }

  // put member grade (edit grade)
  private putMemberGradesGradeNo(): void {
    const request: PutMemberGradesGradeNoRequest = {
      pathParams: {
        gradeNo: this.data.grade.no,
      },
      data: { ...this.info, couponNos: this.coupons },
    };
    this.$api.putMemberGradesGradeNo(request).then(response => {
      if (response && response.status === 204) {
        this.onPositiveClick(true);
      }
    });
  }

  private deleteMemberGrades(): void {
    const request = {
      params: {
        gradeNos: this.data.grade.no + '',
        mallNo: this.info.mallNo,
      },
    };

    this.$api.deleteMemberGrades(request).then(response => {
      if (response && response.status === 204) {
        this.onPositiveClick(true);
      }
    });
  }

  // change name check
  private changeName(): void {
    const lastChar = this.info.name[this.info.name.length - 1];
    if (lastChar === '"' || lastChar === "'") {
      this.info.name = this.info.name.slice(0, this.info.name.length - 1);
    }
  }

  // change used check
  private changeUsed(used: boolean): void {
    if (this.isEditMode() && this.data.memberCount > 0 && !used) {
      alert(this.$t('MEMBER.GRADE.ALERT_ALREADY_EXIST_GRADE'));

      this.$nextTick(() => {
        this.info.used = true;
      });
    }
  }

  // change reserve rate check
  private changeReserveRate(): void {
    let bInputCancel = false;
    const inputKey = this.reserveRate[this.reserveRate.length - 1];

    if ((inputKey < '0' || inputKey > '9') && inputKey !== '.') {
      bInputCancel = true;
    }

    if (this.reserveRate.match(/\./g) && this.reserveRate.match(/\./g).length > 1) {
      bInputCancel = true;
    }

    if (this.reserveRate.indexOf('.') > -1 && !/^\d{1,2}.{1,1}\d{0,1}$/.test(this.reserveRate)) {
      bInputCancel = true;
    }

    if (this.reserveRate.indexOf('.') < 0 && Number(this.reserveRate) > 100) {
      bInputCancel = true;
    }

    if (bInputCancel) {
      this.reserveRate = this.preReserveRate;
    }

    const rate = Number(this.reserveRate);
    if (rate) {
      this.info.reserveBenefit.reserveRate = Number(this.reserveRate);
    } else {
      this.info.reserveBenefit.reserveRate = 0;
    }

    this.preReserveRate = this.reserveRate;
  }

  // change amount check
  private changeAmount(): void {
    let bInputCancel = false;

    const amount = this.regularAmount.replace(/,/g, '');

    if (/[^\d]/.test(amount)) {
      bInputCancel = true;
    }

    if (amount.length > 9) {
      bInputCancel = true;
    }

    if (bInputCancel) {
      this.regularAmount = this.preRegularAmount;
    }

    if (this.regularAmount !== '') {
      this.regularAmount = formatCurrency(Number(amount));
    }

    if (amount.length > 0) {
      this.info.reserveAutoSupplying.amount = Number(amount);
    } else {
      this.info.reserveAutoSupplying.amount = 0;
    }

    this.preRegularAmount = this.regularAmount;
  }

  private openSearchCouponPopup(): void {
    const popupData = { mallNo: getCurrentMallNo(this), popType: 'INSERT', couponList: this.coupons };
    throwWindowPopup('InquiryCoupon', popupData, 'xlg', ({ data, state }: PopupResult) => {
      if (state !== PopupClose.CONFIRM) return;
      this.coupons = [...new Set([...this.coupons, ...data.coupons])];
      const addedCoupon = [];
      data.couponItems.forEach(value => {
        if (
          !this.couponsItem.find(
            ({ couponNo, counponId }) => Number(value.counponId) === couponNo || value.counponId === counponId,
          )
        ) {
          addedCoupon.push(value);
        }
      });
      if (this.couponsItem.length + addedCoupon.length > 10) {
        alert(this.$t('PROMOTION.COUPON.MSG_CONFIRM_GT_10'));
      } else {
        this.couponsItem = [...this.couponsItem, ...addedCoupon];
      }
    });
  }

  private removeCoupon(index: number): void {
    this.coupons.splice(index, 1);
    this.couponsItem.splice(index, 1);
  }

  // save
  private saveGrade(): void {
    if (this.info.name.length === 0) {
      alert(this.$t('MEMBER.GRADE.ADD_GRADE_NAME_PLACEHOLDER'));
      return;
    }
    if (!confirm(this.$t('MEMBER.GRADE.CONFIRM_SAVE').toString())) return;

    if (this.isEditMode()) {
      this.putMemberGradesGradeNo();
    } else {
      this.postMemberGrades();
    }
  }

  // delete
  private deleteGrade(): void {
    if (this.data.order === 0) {
      alert(this.$t('MEMBER.GRADE.ALERT_NOT_DELETE_BASIC'));
      return;
    }

    if (this.data.memberCount > 0) {
      alert(this.$t('MEMBER.GRADE.ALERT_NOT_DELETE_EXIST_MEMBER'));
      return;
    }

    if (confirm(this.$t('MEMBER.GRADE.ALERT_DELETE_CONFIRM') as string)) {
      this.deleteMemberGrades();
    }
  }
}
