















































import { Component, Vue } from 'vue-property-decorator';
import AppModalHeader from '@/components/popup/app/AppModalHeader.vue';
import AppStatement from '@/components/popup/app/AppStatement.vue';

@Component({
  components: { AppStatement, AppModalHeader },
})
export default class AppAgree extends Vue {}
