





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import EmailInput from '@/components/common/input/EmailInput.vue';
import { SecondAuthData } from '@/const/contents/auth';
import { PostAdminsOtpKey } from 'ncp-api-supporter/dist/types';

// TODO 마크업 적용
// TODO OTP Key Find 로직

@Component({ components: { EmailInput } })
export default class AuthFindOtpKeyPopup extends Vue {
  @Prop({ required: true })
  private secondAuthData: SecondAuthData;
  private adminId = '';
  private email = '';

  private openSecondAuthPopup(): void {
    this.$emit('openPopup', 'AuthSecondPopup');
  }

  private isValidate(): boolean {
    if (!this.adminId) {
      (this.$refs.adminId as HTMLElement).focus();
      alert(`${this.$t('ADMIN_ID')}${this.$t('CHECK_INPUT_FILED')}`);
      return false;
    }

    const tempEmail = {
      id: '',
      domain: '',
    };
    if (this.email) {
      [tempEmail.id, tempEmail.domain] = this.email.split('@');
    }

    if (!tempEmail.id || !tempEmail.domain) {
      (this.$refs.email as EmailInput).focus();
      alert(`${this.$t('AUTH.EMAIL')}${this.$t('CHECK_INPUT_FILED')}`);
      return false;
    }

    return true;
  }

  private find(): void {
    if (!this.isValidate()) {
      return;
    }

    const request: PostAdminsOtpKey = {
      data: {
        certKey: this.secondAuthData.key,
        usage: 'LOGIN',
        adminId: this.adminId,
        email: this.email,
      },
    };

    this.$api
      .postAdminsOtpKey(request)
      .then(res => {
        if (res.status === 204) {
          alert(this.$t('ALERT_SEND_EMAIL'));
        }
      })
      .catch(() => {
        alert(this.$t('ALERT_INVALID_EMAIL'));
      });
  }

  private close(): void {
    this.$emit('closePopup');
  }
}
