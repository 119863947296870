







































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SettingGuidePop extends Vue {
  @Prop() private onNegativeClick!: () => void;
}
