export default Object.freeze({
  MONTHLY: '월별',
  PERIOD: '기간별',
  FIRST_PAY_OPTION: '최초결제',
  FIRST_PAY_CLAIM_OPTION: '최초결제 + 클레임결제',
  MEMBER_JOIN_TYPE: '회원 구분',
  MEMBER_GENDER: '회원 성별',
  MEMBER_GRADE: '회원 등급',
  MEMBER: '회원',
  NON_MEMBER: '비회원',
  UNKNOWN: '선택 안 함',

  PERIOD_SALE_TAB: '기간별 판매현황',
  PRODUCT_SALE_TAB: '상품 판매현황',
  CATEGORY_SALE_TAB: '카테코리 판매현황',
  DAY: '일자',
  DAILY_SALES: '일자별 판매현황',
  SALES_BY_PERIOD: '기간별 판매현황 종합',
  PRODUCT_DETAIL_SALES: '상품 세부판매현황',
  CATEGORY_DETAIL_SALES: '카테고리 세부판매현황',
  FIST_PAY: '최초결제 구분',
  PLATFORM: '플랫폼 구분',
  STANDARD_CATEGORY: '표준카테고리',
  DETAIL_SEARCH: '상세검색',
  PAY_TYPE: '결제수단',
  ORDER_COUNT: '판매건수',
  SALES_COUNT: '판매수량',
  PRODUCT_SALES_COUNT: '판매상품수',
  SALES_AMOUNT_A: '판매금액(A)',
  COMMISSION_AMOUNT: '판매수수료(AA)',
  PROMOTION_DISCOUNT_AMOUNT: '프로모션 할인금액(B)',
  PROMOTION_PRODUCT_DISCOUNT_AMOUNT: '프로모션 상품할인금액(쇼핑몰)(B)',
  TOTAL_PAYMENT: '총 상품판매금액(C)(C=A-B)',
  MALL_PROFIT_AMOUNT: '쇼핑몰수익(BB)(BB=AA-B)',
  ACCUMULATION_AMOUNT: '적립금 사용금액(C)',
  DELIVERY_AMOUNT: '배송비(D)',
  EXTERNAL_PAY_AMOUNT: '외부결제(E)',
  TOTAL_PAY_AMOUNT: '총 결제금액(F) (F=A-B-C+D+E)',
  PROMOTION_PRODUCT_DISCOUNT_AMOUNT_B: '프로모션 상품할인금액(B)',
  PRODUCT_NO: '상품번호',
  MANAGEMENT_CODE: '상품관리코드',
  PRODUCT_NAME: '상품명',
  PRODUCT_CNT: '상품수량',
  BRAND: '브랜드',
  SALES_METHOD: '판매방식',
  PURCHASE: '사입',
  CONSIGNMENT: '위탁',
  PURCHASE_AMOUNT: '공급가입력',
  TOTAL_PURCHASE_AMOUNT: '공급가액',
  SUPPLY_AMOUNT: '매입가액',
  COMMISSION_RATE: '수수료율',
  DOWNLOAD: '다운로드',

  DATA_LIMIT_DAY: '판매에 대한 통계 데이터는 최대 3개월까지 검색이 가능하며 집계는 실시간 반영됩니다.',
  FIRST_PAY_SEARCH: '최초결제 구분 검색조건을 활용하여 최초결제 또는 최초결제+클레임결제 포함하여 검색이 가능합니다.',
  FIRST_PAY:
    '최초결제: 최초 주문한 결제 내역 기준으로 필요 시 클레임 완료 상태를 포함하지 않고 데이터 검색이 가능합니다.',
  FIRST_PAY_CLAIM:
    '최초결제 + 클레임결제: 최초결제 후 해당 주문번호에 클레임 완료 상태의 상품을 포함하여 데이터 검색이 가능합니다.<br />- 결제완료~구매확정 건, 취소완료[환불없음], 취소완료[환불완료], 반품완료[환불완료], 반품완료[환불없음],교환완료[결제완료], 교환완료[환불완료], 교환완료[차액없음]',
  CANCEL_COUNT:
    '부분취소(반품) 시 해당 주문건수는 유효하며 판매건수에 카운팅이 유지됩니다. (주문번호 기준 전체 취소된 경우에만 판매건수에 -1건 적용됩니다.)',
  EXCEL_DOWNLOAD:
    '조회조건에 따른 엑셀 다운로드 시 주문번호 기준으로 해당 상품/옵션이 포함된 주문 건이 다운로드 됩니다.',

  SALES_AMT_TOOLTIP_SALE_AMT: '판매금액: 판매가 - 즉시할인금액',
  SALES_AMT_TOOLTIP_SALE_DISCOUNT: '판매금액 할인적용가를 의미합니다.',
  SALES_AMT_TOOLTIP_NAVER_PAY:
    '네이버페이 결제형으로 결제한 주문의 경우 네이버 조정금액으로 인해 오차가 발생할 수 있습니다. 네이버페이 결제형 주문 상세금액은 네이버페이센터를 통해 확인해주세요.',
  SALES_AMT_TOOLTIP: '· 판매금액: 판매가 - 즉시할인금액\n· 판매금액 할인적용가를 의미합니다.',
  SALES_BY_DATE_EXCEL_DOWNLOAD_TOOLTIP:
    '· 다운로드 클릭 시 조회 조건에 따른 기간의 상세 주문 현황이 주문번호 기준으로 해당 상품/옵션 포함되어 다운로드 됩니다.',
  PROMOTION_TOOLTIP: '프로모션 상품할인금액 = 상품적용할인 + 주문적용할인',
  TOTAL_SALES_AMT_TOOLTIP:
    '총 상품판매금액 = 판매금액(즉시할인가) - 프로모션 상품할인금액(상품적용할인 + 주문적용할인)',
  MUST_SEARCH: '판매 현황을 검색해주세요.',
});
