export default Object.freeze({
  EFFECTIVE_DATE: '시행일',
  CLOSE: '닫기',
  ACCESS_TITLE: '이용약관 변경이력',
  ACCESS_NOTICE: '변경/저장된 이용약관을 조회할 수 있습니다.',
  ACCESS_NOTE: '약관 내용',
  ECOMMERCE_TITLE: '전자금융거래이용약관 변경이력',
  ECOMMERCE_NOTICE: '변경/저장된 전자금융거래이용약관을 조회할 수 있습니다.',
  ECOMMERCE_NOTE: `전자금융거래
이용약관 내용`,
  PERSONALINFO_TITLE: '개인정보처리방침 변경이력',
  PERSONALINFO_NOTICE: '변경/저장된 개인정보처리방침을 조회할 수 있습니다.',
  PERSONALINFO_NOTE: `개인정보
처리방침 내용`,
  YEAR: '년',
  MONTH: '월',
  DATE: '일',
  NO_SAVED_CONTENT: '저장된 내용이 없습니다.',
});
