
























































import { Vue, Component, Watch, Ref, Prop } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { getDefaultParams, typedParameters } from '@/components/product/basic/SearchQuery';
import { openCopyProductPopup, canCopiedProduct } from '@/components/product/common/girdColumns/ProductCopy';
import { openEditProductWindow, openProductFrontWindow } from '@/components/product/common/girdColumns/ProductEdit';
import {
  NCPResponse,
  ProductsDetailUrl,
  ProductsSearch,
  ProductsSearchPostRequest,
  ApplyStatusType,
} from 'ncp-api-supporter';
import { RouteQuery } from '@/types/route';
import { GridEventProps, GridProp } from '@/types';
import { Row } from '@/types/tui-grid';
import { DEFAULT_DATE_RANGE, DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { openChangeHistoryLayerPopup } from '@/components/product/common/girdColumns/ChangeHistory';
import { onDeleteProduct } from '@/components/product/common/girdColumns/ProductDelete';
import { throwPopup, throwWindowPopup } from '@/helpers/popup';
import {
  bulkChangeStatusRowsValidation,
  filterByCanChangeStatusProductNo,
} from '@/components/product/common/girdColumns/edit/ChangeStatusRowValidation';
import { sameMallValidation } from '@/components/product/common/girdColumns/edit/UpdateProductsCategoryMappings';
import { ChangeDisplayCategoryData } from '@/components/popup/product/basic/ChangeDisplayCategory.vue';
import { openSupplyPriceDetailsPopup } from '@/components/product/common/girdColumns/ProductSupplyPrice';
import { parseQueryStringOfBasedObject } from '@/utils/query';
import { isEmpty } from 'underscore';
import { searchFormOptions } from '@/components/product/basic/SearchOptions';
import { APPLY_STATUS } from '@/const/common';
import { Grid as TuiGrid } from '@toast-ui/vue-grid';

// 중첩객체 빈 값('', null, undefined) 일 시 제거..
function removeEmpty(obj) {
  const finalObj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const nestedObj = removeEmpty(obj[key]);
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj;
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key];
    }
  });
  return finalObj;
}

@Component({
  components: {
    Grid,
  },
})
export default class SearchResult extends Vue {
  @Prop({ default: true })
  private readonly showProductEditButtons!: boolean;
  @Prop({ default: true })
  private readonly showCreateExcel!: boolean;
  @Prop({ required: true })
  private gridProps!: GridProp;
  @Ref() private readonly grid: Grid;
  @Prop({ default: null })
  private readonly queryLimitSaleStatus!: string | null;
  @Prop({ default: null })
  private readonly queryLimitSaleSettingStatus!: string | null;

  private mallUrls: null | ProductsDetailUrl[] = null;

  private productsSearchResponse: ProductsSearch = {
    contents: [],
    totalPage: 0,
    totalCount: 0,
  };

  created() {
    const needMallUrl = this.gridProps.columns.find(({ name }) => name === 'mainImageUrl');
    if (needMallUrl) this.fetchMallUrl();
  }

  async fetchMallUrl() {
    const { data }: NCPResponse<ProductsDetailUrl[]> = await this.$api.getProductsDetailUrls();
    this.mallUrls = data;
  }

  @Watch('$route.query')
  private updatedQuery(query: RouteQuery) {
    if (isEmpty(query)) return;

    if (location.search) {
      this.fetchProducts(location.search);
    }
  }

  private requestParams: ProductsSearchPostRequest['data'] | null = null;
  public async fetchProducts(query?: string): Promise<void> {
    const defaultParams = getDefaultParams();

    const nonConvertRequestParams = query ? parseQueryStringOfBasedObject(typedParameters, query) : defaultParams;

    this.requestParams = query
      ? this.convertRequestParams({ ...defaultParams, ...nonConvertRequestParams })
      : this.convertRequestParams(nonConvertRequestParams);

    const request: ProductsSearchPostRequest = {
      data: {
        ...this.requestParams,
      },
    };

    try {
      const { data }: NCPResponse<ProductsSearch> = await this.$api.postProductsSearch(request);
      this.productsSearchResponse = data;

      const grid = this.grid.$refs.tuiGrid as TuiGrid;
      const needsAdditionalDiscountYn = this.$route.query.needsAdditionalDiscountInfo;

      if (needsAdditionalDiscountYn === 'Y') {
        grid.invoke('showColumn', 'appliedAdditionalDiscountPrice');
        grid.invoke('showColumn', 'additionalDiscountAmount');
      }

      if (needsAdditionalDiscountYn === 'N') {
        grid.invoke('hideColumn', 'appliedAdditionalDiscountPrice');
        grid.invoke('hideColumn', 'additionalDiscountAmount');
      }
    } catch (error) {
      console.error(error);
    }
  }

  private convertRequestParams(params): ProductsSearchPostRequest['params'] {
    if (params?.mallNos && params?.mallNos.length > 0 && params.mallNos[0] === 0) {
      params.mallNos = [];
    }

    // 기간 : 전체 일 경우
    if (!params?.periodInfo?.period?.startYmdt || !params?.periodInfo?.period?.endYmdt) {
      params.periodInfo.period = {
        startYmdt: DEFAULT_DATE_RANGE.MIN,
        endYmdt: DEFAULT_DATE_RANGE.MAX,
      };
    }

    params.periodInfo.period.startYmdt += ` ${DEFAULT_TIME_RANGE.START}`;
    params.periodInfo.period.endYmdt += ` ${DEFAULT_TIME_RANGE.END}`;

    // saleStatus kinds : WAITING_SALE, ON_PRE_SALE, ON_SALE, END_SALE
    // searchFormOptions.saleStatus.data 에는 ALL 옵션도 있기 때문에 1개를 빼서 비교합니다.
    if (params.saleStatus.types?.length === searchFormOptions.saleStatus.data.length - 1) {
      params.saleStatus.types = [];
      params.saleStatus.isAll = true;
    } else {
      params.saleStatus.isAll = false;
    }

    // saleSettingStatus kinds : AVAILABLE_FOR_SALE, STOP_SELLING, PROHIBITION_SALE
    // searchFormOptions.saleSettingStatus.data 에는 ALL 옵션도 있기 때문에 1개를 빼서 비교합니다.
    if (params.saleSettingStatus.types?.length === searchFormOptions.saleSettingStatus.data.length - 1) {
      params.saleSettingStatus.types = [];
      params.saleSettingStatus.isAll = true;
    } else {
      params.saleSettingStatus.isAll = false;
    }

    if (params.saleStatus?.types === undefined && params.saleSettingStatus.types === undefined) {
      params.saleStatus.types = [];
      params.saleStatus.isAll = true;
      params.saleSettingStatus.types = [];
      params.saleSettingStatus.isAll = true;
    } else if (params.saleStatus?.types === undefined) {
      params.saleStatus = null;
    } else if (params.saleSettingStatus?.types === undefined) {
      params.saleSettingStatus = null;
    }

    // queryLimit 있을 경우 한번 잡아줌
    if (this.queryLimitSaleStatus !== null && (!params.saleStatus || params.saleStatus?.isAll === true)) {
      params.saleStatus = {
        types: this.queryLimitSaleStatus.split(','),
        isAll: false,
      };
    }

    if (
      this.queryLimitSaleSettingStatus !== null &&
      (!params.saleSettingStatus || params.saleSettingStatus?.isAll === true)
    ) {
      params.saleSettingStatus = {
        types: this.queryLimitSaleSettingStatus.split(','),
        isAll: false,
      };
    }

    if (params.applyStatusType === 'REGISTRATION_READY') {
      // 상품상태 = 등록대기 기준 시
      params.saleStatus = null;
      params.saleSettingStatus = null;
    }

    // 페이지/사이즈 쿼리 없을 시
    if (!params.page) params.page = 1;
    if (!params.size) params.size = 30;

    // 재고조건
    params.stockRange.stockCnt = null;
    if (params.stockRange.minStockCnt && params.stockRange.maxStockCnt) params.stockRange.type = 'RANGE';
    if (params.stockRange.minStockCnt && !params.stockRange.maxStockCnt) {
      params.stockRange.type = 'GE';
      params.stockRange.stockCnt = params.stockRange.minStockCnt;
    }
    if (!params.stockRange.minStockCnt && params.stockRange.maxStockCnt) {
      params.stockRange.type = 'LE';
      params.stockRange.stockCnt = params.stockRange.maxStockCnt;
    }

    if (params.stockRange.type === 'NONE') {
      params.stockRange.stockCnt = 0;
    }

    // 카테고리 없을 경우 가드
    if (params.categoryCondition?.no === 0) {
      params.categoryCondition = null;
    }

    return removeEmpty(params);
  }

  private onItemClicked({ rowKey, columnName }: GridEventProps) {
    const rowData: Row = this.grid.getRowAt(rowKey);

    switch (columnName) {
      case 'edit':
      case 'mallProductNo':
        openEditProductWindow(Number(rowData.mallProductNo));
        break;
      case 'mainImageUrl':
        // FIXME: type liar
        openProductFrontWindow((rowData as unknown) as { mallProductNo: string; mallNo: string }, this.mallUrls);
        break;
      case 'copy':
        if (canCopiedProduct(rowData)) {
          openCopyProductPopup(rowData);
        }
        break;
      case 'delete':
        this.onClickDeleteProduct(rowData);
        break;
      case 'changeHistory':
        openChangeHistoryLayerPopup(rowData);
        break;
      case 'supplyPrice':
        openSupplyPriceDetailsPopup(rowData);
        break;
      case 'applyStatusType':
        this.openProductJudgePopup(rowData);
        break;
    }
  }

  private onClickDeleteProduct(row: Row) {
    onDeleteProduct(row)
      .then(() => {
        this.fetchProducts(location.search);
      })
      .catch(error => {
        if (error !== 'UN_CONFIRM') console.error(error);
      });
  }

  private openProductJudgePopup(rowData: Row) {
    const applyStatusType = rowData.applyStatusType as ApplyStatusType;
    const readyStates = [APPLY_STATUS.APPROVAL_READY, APPLY_STATUS.AFTER_APPROVAL_READY] as ApplyStatusType[];
    const rejectStates = [APPLY_STATUS.APPROVAL_REJECTION, APPLY_STATUS.AFTER_APPROVAL_REJECTION] as ApplyStatusType[];
    //승인대기, 수정 후 승인대기
    if (readyStates.includes(applyStatusType)) {
      throwWindowPopup(
        'ProductJudgement',
        {},
        'lg',
        () => {
          this.fetchProducts(location.search);
        },
        'productJudgeInfo',
        undefined,
        undefined,
        undefined,
        {
          mallProductNo: rowData.mallProductNo?.toString() || '',
        },
      );
    }
    //승인거부, 수정 후 승인거부
    if (rejectStates.includes(applyStatusType)) {
      throwPopup({
        name: 'ApprovalRejectionView',
        data: rowData,
      }).then(() => {
        this.fetchProducts(location.search);
      });
    }
    //판매합의거부
    if (applyStatusType === APPLY_STATUS.SALE_AGREEMENT_REJECTION) {
      throwPopup({
        name: 'SaleAgreementRejectionView',
        data: rowData,
      }).then(() => {
        this.fetchProducts(location.search);
      });
    }
  }

  private onOpenChangeStatusBulkEditModal(componentName: 'ChangeSaleStatus' | 'ChangeFrontDisplay') {
    const checkedRows = this.grid.getCheckedRows();

    const statusTranslateNames = {
      ChangeSaleStatus: this.$t('PRODUCT.STATUS.SALE.SETTING_STATUS'),
      ChangeFrontDisplay: this.$t('PRODUCT.STATUS.FRONT_DISPLAY.STATUS'),
    } as const;

    if (!bulkChangeStatusRowsValidation(checkedRows, statusTranslateNames[componentName])) return;
    throwPopup({
      name: componentName,
      data: filterByCanChangeStatusProductNo(checkedRows),
    }).then(({ state }) => {
      if (state === 'confirm') this.fetchProducts(location.search);
    });
  }

  private onOpenDisplayCategoryBulkEditModal() {
    const checkedRows: Row[] = this.grid.getCheckedRows();
    const statusName = this.$t('CATEGORY');

    if (!sameMallValidation(checkedRows) || !bulkChangeStatusRowsValidation(checkedRows, statusName)) return;
    const data: ChangeDisplayCategoryData = {
      mallNo: Number(checkedRows[0].mallNo),
      productNos: filterByCanChangeStatusProductNo(checkedRows),
    };

    throwPopup({
      name: 'ChangeDisplayCategory',
      data,
    }).then(({ state }) => {
      if (state === 'confirm') this.fetchProducts(location.search);
    });
  }

  private onOpenProductJudgeBulkEditModal() {
    const checkedRows: Row[] = this.grid.getCheckedRows();
    const statusName = this.$t('PRODUCT.EDIT.PRODUCT_JUDGE');

    if (!bulkChangeStatusRowsValidation(checkedRows, statusName)) return;

    throwPopup({
      name: 'ChangeProductJudge',
      data: { productNos: checkedRows.map(v => v.mallProductNo as number) },
    }).then(({ state }) => {
      if (state === 'confirm') this.fetchProducts(location.search);
    });
  }

  private onOpenAdminBulkEditModal() {
    throwWindowPopup('ChangeAdmin', {}, 'sm', () => {
      this.fetchProducts(location.search);
    });
  }

  // TODO : 왜 다른 형식을 리턴하지???
  public getCheckedProduct(): Row[] | Pick<Row, string | number>[] {
    const checkedProducts = this.grid.getCheckedRows();
    if (checkedProducts.length > 0) return checkedProducts;
    const radioPickedProduct = this.grid.getData().find(({ pick }) => pick === true);
    if (radioPickedProduct) {
      delete radioPickedProduct['pick'];
      return [radioPickedProduct];
    }
    return [];
  }

  private onClickModifySalePrice() {
    throwWindowPopup('ModifySalePrice', 1, 'md', null);
  }
}
