




































































































































































































































import { Component } from 'vue-property-decorator';
import $ from 'jquery';
import JsBarcode from 'jsbarcode';
import WindowPopupMain from '@/views/popups/Main.vue';
import { NCPResponse, PostOrdersSpecificationsRequest } from 'ncp-api-supporter/dist/types';
import { PostOrdersSpecifications } from 'ncp-api-supporter/dist/types/modules/order/backendOrder';
import { unescapeHtml } from '@/utils/common';
@Component({
  components: {},
})
export default class OrderSpecificationPopup extends WindowPopupMain {
  private currentPageNo = 1;
  private totalPageSize = 0;
  private MAX_ROW_PER_PAGE = 15;
  private theInitData = [];
  private current = 0;
  private total = 0;
  private sumCount = 0;
  private sumAmount = 0;
  private showPrint = true;
  private unescapeHtml = unescapeHtml;

  private theTemplateData(num: number) {
    const arr = [];
    arr.length = this.MAX_ROW_PER_PAGE - num - 1;
    return arr;
  }
  private getBusinessRegistrationNo(no: string) {
    return `${no.substring(0, 3)}-${no.substring(3, 5)}-${no.substring(5)}`;
  }
  private getImmediateDiscountedPrice(orderProductOption) {
    return orderProductOption.salePrice + orderProductOption.addPrice - orderProductOption.immediateDiscountAmt;
  }
  private appendCommon(number: number) {
    return number.toLocaleString();
  }
  private changePage(variation: number) {
    this.theInitData[this.currentPageNo - 1].showSpecification = false;
    if (this.currentPageNo + variation === 0 || this.currentPageNo + variation === this.totalPageSize + 1) {
      return;
    }
    this.currentPageNo += variation;
    this.theInitData[this.currentPageNo - 1].showSpecification = true;
  }
  private print() {
    this.showPrint = false;
    this.theInitData.map(item => {
      item.showSpecification = true;
    });
    this.$nextTick(() => {
      window.print();
      this.showPrint = true;
      this.theInitData.map(item => {
        item.showSpecification = false;
      });
      this.theInitData[this.currentPageNo - 1].showSpecification = true;
    });
    this.postOrdersSpecifications();
  }
  private cancelPopup() {
    self.close();
  }
  private getToday() {
    const date = new Date();

    return `${date.getFullYear()}년 ${this.zeroPad(date.getMonth() + 1, 2)}월 ${this.zeroPad(date.getDate(), 2)}일`;
  }
  private zeroPad(n, width: number) {
    n = `${n}`;
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  private async postOrdersSpecifications(): Promise<PostOrdersSpecifications[]> {
    const request: PostOrdersSpecificationsRequest = {
      data: {
        shippingNos: this.data.shippingNos,
        orderStatusType: this.data.orderStatus,
      },
    };
    try {
      const { data }: NCPResponse<PostOrdersSpecifications[]> = await this.$api.postOrdersSpecifications(request);
      return data;
    } catch (e) {
      console.error(e);
    }
  }
  private async initData() {
    const data = await this.postOrdersSpecifications();
    data.map(delivery => {
      const newDelivery = {
        ...delivery,
        ordererName: '',
        current: 0,
        total: 0,
        sumAmount: 0,
        sumCount: 0,
        showSpecification: false,
      };
      this.totalPageSize += this.getTotalPageSize(delivery.orderOptions.length);
      newDelivery.ordererName = delivery.orderOptions[0].ordererName;
      if (delivery.orderOptions[0].memberId !== null) {
        newDelivery.ordererName = `${delivery.orderOptions[0].ordererName}(${delivery.orderOptions[0].memberId})${
          [...delivery.orderOptions.map(o => o.ordererName)].length > 1 ? '외' : ''
        }`;
      }
      for (let i = 0; i < this.getTotalPageSize(delivery.orderOptions.length); i++) {
        const slicedOrderProductOptions = delivery.orderOptions.slice(
          i * this.MAX_ROW_PER_PAGE,
          i * this.MAX_ROW_PER_PAGE + this.MAX_ROW_PER_PAGE,
        );

        const cloneSpecification = $.extend({}, newDelivery);
        cloneSpecification.orderOptions = slicedOrderProductOptions;
        cloneSpecification.ordererName = newDelivery.ordererName;
        cloneSpecification.current = i + 1;
        cloneSpecification.total = this.getTotalPageSize(delivery.orderOptions.length);

        if (i === cloneSpecification.total - 1) {
          cloneSpecification.sumAmount = delivery.orderOptions.reduce(
            (pre, value) => pre + this.getImmediateDiscountedPrice(value.product) * value.orderCnt,
            0,
          );
          cloneSpecification.sumCount = delivery.orderOptions.reduce((pre, value) => pre + value.orderCnt, 0);
        }

        this.theInitData.push(cloneSpecification);
      }
    });
    this.$nextTick(() => {
      this.changePage(0);
      this.theInitData.map((item, index) => {
        JsBarcode(`#deliveryBarcode_${index}`, item.shippingNo, {
          format: 'CODE128',
          width: 1,
          height: 50,
          displayValue: false,
          margin: 0,
        });
      });
    });
  }
  private getTotalPageSize(optionLength: number) {
    return parseInt(`${(optionLength - 1) / this.MAX_ROW_PER_PAGE + 1}`);
  }

  private mounted() {
    this.initData();
  }
}
