import { ActionContext } from 'vuex';
import { TreeNode, EventKeyword, TreeStoreEvent } from '@/types/tree';
import { RootState } from '@/store/root';
import { isEqual } from 'underscore';

interface TreeState {
  updatedNodes: TreeNode[];
  node: TreeNode;
  parentNode: TreeNode | null;
  nodeId: string;
  path: string[];
  event: TreeStoreEvent | null;
}
export default {
  namespaced: true,
  state: {
    updatedNodes: [],
    node: null,
    parentNode: null,
    nodeId: '',
    path: [],
    event: {
      eventName: null,
      eventCount: 0,
    },
  },
  actions: {
    setTree: ({ commit }: ActionContext<TreeState, RootState>) => {
      commit('RESET_STORE');
      commit('PUBLISH_EVENT', 'SET_TREE');
    },
    setNode: (
      { commit }: ActionContext<TreeState, RootState>,
      { node, path }: { node: TreeNode; path: string[] },
    ): void => {
      commit('SET_NODE', node);
      if (path) commit('SET_PATH', path);
      commit('PUBLISH_EVENT', 'SET_NODE');
    },
    setParentNode: ({ commit }: ActionContext<TreeState, RootState>, node: TreeNode | null): void => {
      commit('SET_PARENT_NODE', node);
      commit('PUBLISH_EVENT', 'SET_NODE');
    },
    recordNode: (
      { state, commit }: ActionContext<TreeState, RootState>,
      { node, uniqueKey }: { node: TreeNode; uniqueKey: string },
    ): void => {
      if (isEqual(state.node, node)) return;

      const uniqueId = node[uniqueKey];
      const targetIndex = state.updatedNodes.findIndex(node => node[uniqueKey] === uniqueId);

      if (!state.updatedNodes.length || targetIndex === -1) {
        commit('SET_UPDATED_NODE', node);
      } else {
        commit('PUT_UPDATED_NODE', { nodeData: node, targetIndex });
      }

      commit('PUBLISH_EVENT', 'RECODE_NODE');
    },
    resetStore: ({ commit }: ActionContext<TreeState, RootState>): void => {
      commit('RESET_STORE');
      commit('PUBLISH_EVENT', 'RESET_STORE');
    },
    resetRecord: ({ commit }: ActionContext<TreeState, RootState>): void => {
      commit('RESET_RECORD');
      commit('PUBLISH_EVENT', 'RESET_RECODE');
    },
    publishEvent: ({ commit }: ActionContext<TreeState, RootState>, eventKeyword: EventKeyword): void => {
      commit('PUBLISH_EVENT', eventKeyword);
    },
  },
  mutations: {
    SET_UPDATED_NODE: (state: TreeState, nodeData: TreeNode): void => {
      state.node = nodeData;
      state.updatedNodes = state.updatedNodes.concat([nodeData]);
    },
    PUT_UPDATED_NODE: (
      state: TreeState,
      { nodeData, targetIndex }: { nodeData: TreeNode; targetIndex: number },
    ): void => {
      const updateNodes = state.updatedNodes.concat();
      updateNodes[targetIndex] = nodeData;
      state.node = nodeData;
      state.updatedNodes = updateNodes;
    },
    SET_NODE: (state: TreeState, nodeData: TreeNode): void => {
      state.node = nodeData;
    },
    SET_PARENT_NODE: (state: TreeState, nodeData: TreeNode | null): void => {
      state.parentNode = nodeData;
    },
    SET_PATH: (state: TreeState, path: string[]): void => {
      state.path = path;
    },
    PUBLISH_EVENT: (state: TreeState, event: EventKeyword): void => {
      state.event.eventCount += 1;
      state.event.eventName = event;
    },
    RESET_STORE: (state: TreeState): void => {
      state.updatedNodes = [];
      state.node = null;
      state.parentNode = null;
      state.nodeId = '';
      state.path = [];
      state.event = {
        eventName: null,
        eventCount: 0,
      };
    },
    RESET_RECORD: (state: TreeState): void => {
      state.updatedNodes = [];
    },
  },
  getters: {
    getUpdatedNodes(state: TreeState): TreeNode[] {
      return [...state.updatedNodes]; // TreeNode === 1차원 배열
    },
    getCurrentUpdatedNode(state: TreeState): TreeNode {
      const LAST_UPDATED_NODE_INDEX = state.updatedNodes.length - 1;
      return { ...state.updatedNodes[LAST_UPDATED_NODE_INDEX] };
    },
    getNode(state: TreeState): TreeNode {
      return state.node !== null ? { ...state.node } : null;
    },
    getParentNode(state: TreeState): TreeNode {
      return state.parentNode !== null ? { ...state.parentNode } : null;
    },
    getPath(state: TreeState): string[] {
      return [...state.path];
    },
    getEvent(state: TreeState): TreeStoreEvent {
      return { ...state.event };
    },
  },
};
