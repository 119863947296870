









import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { responsibleObjectTypes } from '@/const/claim';
import { ResponsibleObjectType } from '@/types/claim';

@Component({ components: { TextInput, SelectBox } })
export default class TdResponibleType extends Vue {
  @Prop({ required: true })
  private readonly modifyMode!: boolean;

  @PropSync('responsibleObjectType')
  private readonly responsibleObjectTypeSync!: ResponsibleObjectType;

  private responsibleObjectTypeOptions = [
    { name: 'CLAIM.COMMON.BUYER', value: responsibleObjectTypes.BUYER },
    { name: 'CLAIM.COMMON.SELLER', value: responsibleObjectTypes.SELLER },
  ];
}
