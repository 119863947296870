
































































































import { Component, Prop, Ref, Mixins } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import {
  useYnRadioOption,
  stickerTypeRadioOption,
  noticeList,
  initialIcon,
} from '@/components/popup/product/management/IconUpsert/IconUpsert';
import { CallbackForImage, Icon, ImageFileUpload, MaxSizeValidOption, Mode, StickerType } from '@/types';
// import { canChangeUseState } from '@/components/popup/product/management/IconUpsert/validation';
import { YorN } from 'ncp-api-supporter';
import { RadioBoxGroupOption } from '@/helpers/type';
import { MODE } from '@/const/common';
import { message } from '@/views/contents/product/management/IconList/IconList';
import ImageFileInputMixin from '@/components/common/input/imageFileInput/ImageFileInputMixin.vue';
import unsavedDetector from '@/directives/input/unsavedDetector';
@Component({
  components: {
    RadioGroup,
    TextInput,
    NoticeBox,
  },
  computed: {
    getImgFileName(): string {
      const fileNames: string[] = this.icon.content.split('/');
      return fileNames[fileNames.length - 1];
    },
  },
  directives: { unsavedDetector },
})
export default class IconUpsert extends Mixins(ImageFileInputMixin) implements ImageFileUpload {
  @Prop() private readonly data!: Icon;
  @Prop() private readonly onNegativeClick!: () => void;
  @Prop() private readonly onPositiveClick!: (icon: Icon) => void;
  @Prop({ default: () => ({ validType: 'thumbnail', alertMsg: 'PRODUCT.ICON.NOTICE_LIMIT_CAPACITY' }) })
  readonly maxSizeValidOption!: MaxSizeValidOption;
  private readonly useYnRadioOption: RadioBoxGroupOption<YorN> = useYnRadioOption;
  private readonly stickerTypeRadioOption: RadioBoxGroupOption<StickerType> = stickerTypeRadioOption;
  private readonly noticeList: string[] = noticeList;
  private icon: Icon = {} as Icon;
  private upsertText: Mode = MODE.EDIT;
  private isEdit = false;
  private mounted(): void {
    this.setIcon();
  }
  private setIcon(): void {
    this.data ? (this.icon = this.data) : this.initializeIcon();
    this.isEdit = !!this.icon.content;
  }
  private initializeIcon(): void {
    this.upsertText = MODE.REGISTER;
    this.icon = { ...initialIcon };
  }

  private get isEditMode(): boolean {
    return this.upsertText === MODE.EDIT;
  }
  private get isImageType(): boolean {
    return this.icon.stickerType === 'IMAGE';
  }

  // 사용여부
  // private useYnStateChange(curr: YorN, prev: YorN): void {
  //   const isChangeable = canChangeUseState(curr);
  //   this.$nextTick(() => (this.icon.useYn = isChangeable ? curr : prev));
  // }
  // 아이콘명 (필수)
  // 아이콘 설정 (필수)
  private reset(): void {
    this.icon.stickerName = '';
    this.icon.content = '';
    this.icon.imgFileName = '';
  }

  public callbackForPostedImage(file: File): CallbackForImage {
    this.icon.imgFileName = file.name;
    return data => {
      this.icon.content = data.url;
    };
  }

  // 등록
  private onSave(): void {
    if (!this.checkRequiredData()) return;
    this.onPositiveClick(this.icon);
    alert(this.$t('PRODUCT.ICON.SAVED_ICON'));
  }

  @Ref() private readonly stickerName: HTMLInputElement;
  @Ref() private readonly textContent: HTMLInputElement;
  private checkRequiredData(): boolean {
    const { stickerName, content, stickerType } = this.icon;
    if (!stickerName) {
      alert(this.$t('PRODUCT.ICON.PLZ_ICON_NAME'));
      this.stickerName.focus();
      return;
    }
    if (!content) {
      alert(this.$t(`PRODUCT.ICON.PLZ_ICON_${stickerType}`));
      this.isImageType ? this.imageFileInput.focus() : this.textContent.focus();
      return;
    }
    return true;
  }

  // 삭제
  private removeIcon() {
    if (confirm(this.$t(message.confirmToRemove).toString())) {
      this.onPositiveClick(null);
    }
  }
}
