import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import { DefaultGridContainerOption } from '@/types';
import { i18n } from '@/main';

export const gridProps = {
  columns: [
    {
      header: 'MALL',
      name: 'mallName',
      minWidth: 150,
      align: 'center',
    },
    {
      header: 'PRODUCT.PRODUCT.MALL_PRODUCT_NO',
      name: 'productNo',
      align: 'center',
      minWidth: 150,
    },
    //총 이미지 등록 수
    {
      header: 'PRODUCT.BULK_IMAGE.TOTAL_COUNT',
      name: 'imageTotalCount',
      align: 'center',
      minWidth: 150,
    },
    //이미지 등록 성공건수
    {
      header: 'PRODUCT.BULK_IMAGE.SUCCESS_COUNT',
      name: 'imageSuccessCount',
      align: 'center',
      minWidth: 150,
    },
    //이미지 등록 실패건수
    {
      header: 'PRODUCT.BULK_IMAGE.FAILURE_COUNT',
      name: 'imageFailCount',
      align: 'center',
      minWidth: 150,
    },
  ],
  header: {
    height: 50,
    columns: [
      {
        name: 'imageFailCount',
        renderer: props => {
          props.tooltipId = 'imageFailCount';
          return headerWithTooptipRenderer(props);
        },
      },
    ],
  },
  options: {
    rowHeaders: ['rowNum'],
    scrollY: true,
    heightResizable: true,
    columnOptions: {
      resizable: true,
    },
    bodyHeight: 300,
  },
};

export const getContainerOptions = (): DefaultGridContainerOption => ({
  showsRightArea: false,
  headerTooltipOption: [
    {
      id: '#imageFailCount',
      popupData: { data: { message: i18n.t('PRODUCT.BULK_IMAGE.HELP_FAILURE_COUNT') } },
    },
  ],
});
