export default Object.freeze({
  NOTICE:
    '서비스 신청정보 및 승인상태 확인, 타 PG사 관련 정보는 <a href="https://www.nhn-commerce.com/mygodo/myGodo_pgMain.php" target="_blank"><strong>마이페이지>쇼핑몰 관리>전자결제 서비스</strong></a>에서 상세히 확인할 수 있습니다.',
  AUTO_SETTING_COMPLETE: '자동 설정 완료',
  SERVICE_APPLY_TITLE: '페이코 결제 서비스를 신청해주세요.',
  SERVICE_APPLY_LINK_TEXT: '페이코 결제 서비스 신청',
  PAYMENT_LINKAGE: '페이코 결제 연동 설정',
  PAYMENT: '결제수단',
  CODE: '가맹점 코드',
  RENEW: '정보 갱신',
  CP_ID: '상점ID',
});
