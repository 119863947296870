export default Object.freeze({
  OPERATOR_REGISTRATION: '운영자 계정 등록',
  OPERATOR_INFORMATION: '운영자계정 정보',
  ACCOUNT_TYPE: '계정유형',
  OPERATOR_NAME: '운영자명',
  ENTER_OPERATOR_NAME: '운영자명 입력',
  FORMAT_INCORRECT: '입력형식이 잘못되었습니다.',
  EMAIL_ADDRESS: 'e메일 주소',
  POSSIBLE: '가능',
  FOR_EXTERNAL_ACCESS: '※ 외부접속 시 운영자 휴대폰 SMS 인증 절차를 실시합니다.',
  SA_AUTHORITY_SETTING: 'SA 권한설정',
  AUTHORITY_GROUP: '권한그룹',
  ENROLLMENT: '등록',
  CANCEL: '취소',
  IMPOSSIBLE: '불가',
  ALERT_SELECT_TYPE: '계정유형을 선택해 주세요.',
  ALERT_ENTER_NAME: '운영자명을 입력해 주세요.',
  ALERT_ENTER_EMAIL: 'e메일을 입력해주세요.',
  ALERT_CHECK_FORMAT: 'e메일 형식을 확인해주세요.',
  ALERT_SELECT_GROUP: '권한 그룹을 선택해 주세요.',
  ALERT_WOULD_YOU_REGISTRATION: '운영자 등록을 완료 하시겠습니까?',
  ALERT_SUCCESSFULLY: '신규 운영자가 정상 등록 되었습니다.',
  SELECT_ACCOUNT_TYPE: '계정유형 선택',
  MASTER: '마스터',
  NORMAL: '일반',
  CHOOSE: '선택하세요',
});
