






















import { Component, Mixins, Watch } from 'vue-property-decorator';
import StatusSummaryBox from '@/components/root/parts/StatusSummaryBox.vue';
import SummaryData, { Summary } from '@/components/root/SummaryMixin';
import { namespace } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';

const mallStore = namespace('mall');

@Component({
  components: { StatusSummaryBox },
})
export default class ProductSummary extends Mixins(SummaryData) {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private summaries: Summary[] = [
    {
      key: 'onSale',
      label: 'ON_SALE',
      count: null,
      path: {
        name: 'ProductListIframe',
        query: {
          params: '{"saleStatus":{"isAll":false,"types":["ON_SALE"]}}', // 'ON_SALE'과 판매설정이 다름
        },
      },
    },
    {
      key: 'soldOut',
      label: 'SOLD_OUT',
      count: null,
      path: {
        name: 'ProductListIframe',
        query: {
          params: '{"stockRange":{"type":"LE","stockCnt":0,"maxStockCnt":0}}',
        },
      },
    },
    {
      key: 'stockUnderTen',
      label: 'STOCK_UNDER_TEN',
      count: null,
      path: {
        name: 'ProductListIframe',
        query: {
          params: '{"stockRange":{"type":"LE","stockCnt":10,"maxStockCnt":10}}',
        },
      },
    },
  ];

  private get mallNos(): number[] {
    return this.malls.map(({ mallNo }) => mallNo);
  }

  created() {
    if (this.productSummary) this.mapData();
    this.fetch();
  }

  @Watch('$route.query.latestRefresh')
  @Watch('mallNoQuery')
  async fetch() {
    await this.fetchData();
    this.mapData();
  }

  private async fetchData(): Promise<void> {
    // fetchProductInquiry
    await this.fetchProductSummary(this.mallNoQuery === 'ALL' ? null : this.mallNoQuery);
  }

  private mapData(): void {
    this.mapSummaries(this.productSummary);
  }

  private mapSummaries<DataType = object>(data: DataType): void {
    Object.entries(data).forEach(([k, v]) => {
      const matched = this.summaries.find(({ key }) => key === k);
      if (matched) matched.count = v;
    });
    this.summaries = this.summaries.map(summary => {
      summary.query = this.mallNoQuery === 'ALL' ? null : { mallNos: this.mallNoQuery.toString() };
      return summary;
    });
  }
}
