export default Object.freeze({
  TITLE_NORMAL_REGISTRATION: '옵션 등록',
  TITLE_SET_REGISTRATION: '세트옵션 등록',
  TITLE_NORMAL_MODIFICATION: '옵션 수정',
  TITLE_SET_MODIFICATION: '세트옵션 수정',
  OPTION_GUIDE: '옵션 등록 가이드',
  SET_OPTION_GUIDE: '세트옵션 등록 가이드',
  SET_OPTION_GUIDE_DESC1: '- 최대 2개의 옵션명 기준으로 옵션값을 조합하여 조합형 세트옵션 등록 가능',
  SET_OPTION_GUIDE_DESC2: '- 세트옵션별로 일반상품의 옵션을 수량별로 조합하여 판매 가능',
  COMBINATION_OPTION: '조합형 옵션',
  COMBINATION_OPTION_DESCRIPTION1: '여러 옵션을 조합하여 선택하는 형태',
  COMBINATION_OPTION_DESCRIPTION2: '최대 5개 옵션 추가 가능',
  TEXT_OPTION_PLACEHOLDER: '구매자가 직접 입력',
  OPTION_NAME: '옵션명',
  OPTION_VALUE: '옵션값',
  OPTION_VALUE1: '옵션값1',
  OPTION_PRICE: '옵션가',
  SET_CONFIGURATION_OPTION: '세트 구성 옵션',
  SET_TOOL_TIP_DESC:
    '토끼 어린이 잠옷 1개(색상 : 핑크ㅣ사이즈 : free)\n' +
    '토끼 칫솔 5개(색상 : 핑크ㅣ타입 : 라운드칫솔모)\n' +
    '토끼 치약 1개(맛 : 딸기맛 75ml)\n' +
    '토끼 헤어밴드 1개(색상 : 핑크)',
  STOCK_CNT: '재고수량',
  MANAGEMENT_CD: '옵션코드',
  TEXT_OPTION: '텍스트 옵션',
  TEXT_OPTION_DESCRIPTION1: '주문자가 직접 옵션 값을 입력하는 형태',
  TEXT_OPTION_DESCRIPTION2: '최대 5개 텍스트 옵션 추가 가능',
  OPTION_NAME1: '옵션명1',
  OPTION_NAME2: '옵션명2',
  OPTION_NAME3: '옵션명3',
  NOT_USED: '사용 안 함',
  MAX_OPTION_COUNT_MESSAGE: '옵션은 최대 {0}개까지 생성 가능합니다.',
  OPTION_NAME_PLACEHOLDER: '255자까지 입력 가능',
  SET_OPTION_NAME_PLACEHOLDER: '25자까지 입력 가능',
  TEXT_OPTION_NAME_PLACEHOLDER: '25자까지 입력 가능',
  OPTION_VALUE_PLACEHOLDER: '255자까지 입력 가능',
  SET_OPTION_VALUE_PLACEHOLDER: '100자까지 입력 가능',
  OPTION_PRICE_PLACEHOLDER: '9자까지 입력 가능(10원 단위)',
  SUPPLY_PRICE_PLACEHOLDER: '9자까지 입력가능',
  STOCK_CNT_PLACEHOLDER: '8자까지 입력 가능',
  MANAGEMENT_CD_PLACEHOLDER: '45자까지 입력 가능',
  ADD: '추가',
  DELETE: '삭제',
  COMBINATION_OPTION_SAVE: '옵션 정보 적용',
  SUPPLY_PRICE: '공급가',
  EMPTY_OPTION_NAME_MESSAGE: '옵션명을 입력해주세요.',
  EMPTY_OPTION_VALUE_MESSAGE: '옵션값을 입력해주세요.',
  OPTION_PRICE_MESSAGE: '마이너스 옵션가는 즉시할인가 이하로 설정 가능합니다.',
  SELECT: '선택',
  OUT_OF_STOCK_STATUS: '품절상태',
  SOLD_OUT: '품절',
  OUT_OF_STOCK: '임시품절',
  NORMAL: '정상',
  WHETHER_TO_USE: '사용여부',
  RESERVATION_STOCK_CNT: '예약재고수량',
  WAITING_FOR_DELIVERY: '출고대기',
  IMAGE: '이미지',
  OPTION_NO: '옵션번호',
  STOCK_NO: '재고번호',
  OPTION_MANAGEMENT_CD: '옵션관리코드',
  REQUIRED_YN: '필수여부',
  TEXT_OPTION_EMPTY_MESSAGE: '옵션 사용설정에서 텍스트옵션을 입력해주세요.',
  NORMAL_COMBINATION_OPTION_EMPTY_MESSAGE: '옵션을 입력해주세요.',
  SET_COMBINATION_OPTION_EMPTY_MESSAGE: '세트옵션을 입력해주세요.',
  REQUIRED: '필수',
  EACH_PRODUCT: '상품별',
  EACH_OPTION: '옵션별',
  MATCH_TYPE: '매칭타입',
  TEXT_OPTION_TITLE: '텍스트옵션 총 ',
  STOCK_STATUS_CHANG_CONFIRM: '품절로 변경 시 옵션의 재고가 0이 됩니다. 변경하시겠습니까?',
  COMBINATION_TOOL_TIP1: '조합형 옵션은 최대 1,000개까지 입력 가능합니다.',
  COMBINATION_TOOL_TIP2: '옵션 입력 시, 추가버튼 및 우측 Enter키를 이용하여 옵션값을 추가할 수 있습니다.',
  COMBINATION_TOOL_TIP3: '옵션 입력 시, Tab키를 이용하여 다음 입력란으로 이동할 수 있습니다.',
  NO_DATA_SELECTED: '선택된 옵션이 없습니다.',
  SET_NO_DATA_SELECTED: '선택된 세트옵션이 없습니다.',
  TOTAL_PRODUCT_LIST: '상품 목록 총 ',
  DELETE_OPTION_MESSAGE: '선택된 {0}개의 옵션을 삭제하시겠습니까?',
  TEXT_OPTION_VALIDATION_MESSAGE: '텍스트옵션의 옵션명을 입력해주세요.',
  EMPTY_OPTION_MESSAGE: '옵션을 등록해주세요.',
  MULTI_TYPE: '분리형',
  FLAT_TYPE: '일체형',
  SURE_CALL_DELETE: '다른 상품 옵션을 불러올 경우 등록되어있는 옵션 정보는 삭제됩니다.\n진행하시겠습니까?',
  SURE_REGISTER_DELETE: '등록된 옵션 상세 정보가 모두 삭제됩니다.\n변경하시겠습니까?',
  CALL_OTHER_OPTION: '다른상품 옵션 불러오기',
  OPTION_INPUT: '옵션입력',
  NORMAL_OPTION_SETTING: '옵션 사용설정',
  SET_OPTION_SETTING: '세트옵션 사용설정',
  SELECT_TYPE: '선택형 옵션',
  TEXT_TYPE: '텍스트 옵션',
  NORMAL_SELECTION_METHOD: '옵션 선택방식',
  SET_SELECTION_METHOD: '세트옵션 선택방식',
  STOCK_SETTING: '재고/옵션코드 설정',
  TOTAL_NORMAL_OPTION: '옵션 목록 총',
  TOTAL_SET_OPTION: '세트옵션 목록 총',
  SELECT_DELETE: '선택삭제',
  SELECT_BATCH_MODIFICATION: '선택 일괄수정',
  COMBINATION_EXCEL_DOWNLOAD: '조합형 엑셀양식 다운로드',
  NO_OPTION: '옵션 사용설정에서 옵션을 선택해주세요.',
  NORMAL_OPTION_STOCK_STATUS_TOOL_TIP1: "옵션의 재고가 0일 경우, 품절상태는 자동으로 '품절'로 변경됩니다.",
  NORMAL_OPTION_STOCK_STATUS_TOOL_TIP2: "옵션의 품절상태를 '품절'로 변경 시, 재고가 0으로 자동 변경됩니다.",
  NORMAL_OPTION_STOCK_STATUS_TOOL_TIP3:
    "옵션의 품절상태를 ‘임시품절‘로 변경하여, 옵션 재고를 유지하면서 특정 옵션을 '품절'로 변경할 수 있습니다.",
  SET_COMBINATION_TOOL_TIP1: '조합형 옵션은 최대 50개까지 입력 가능합니다.',
  CONFIGURATION_PRICE: '구성옵션 판매가',
  SET_PRICE: '세트옵션 판매가',
  SET_CONFIGURATION_BTN: '세트구성옵션',
  STOCK: '재고',
  SET_OPTION_STOCK_STATUS_TOOL_TIP1: "세트옵션의 재고가 0일 경우, 품절상태는 자동으로 '품절'로 변경됩니다.",
  SET_OPTION_STOCK_STATUS_TOOL_TIP2: "세트옵션의 품절상태가 '품절'인 경우, 품절상태 변경이 불가합니다.",
  SET_OPTION_STOCK_STATUS_TOOL_TIP3:
    '세트옵션의 재고가 1 이상인 경우에 한하여. ‘정상’ ↔ ‘임시품절‘로 상태 변경이 가능합니다.',
  SET_OPTION_STOCK_STATUS_TOOL_TIP4:
    "'임시품절'로 품절상태 변경 시, 옵션 재고를 유지하면서 특정 세트옵션을 쇼핑몰에서 품절 처리할 수 있습니다.",

  CONFIGURATION_OPTION_TITLE: '세트구성옵션 조회/선택',

  DIFFER: " (','로 구분)",
  PLEASE_SELECT_INPUT_TYPE: '옵션정보 입력형태를 선택하세요.',
  PLEASE_IMPOSSIBLE_10: '가격은 10원 단위로 입력 가능합니다.',
  USE_INPUT: '입력 내용 적용',
  OPTION_CATEGORY: '옵션 목록',
  SELECT_OPTION: '옵션 형태 선택 :',
  SELECTED_MODIFY_ALL: '선택항목 일괄수정 :',
  MODIFY_ALL: '일괄수정',
  IMG_ALL_REGISTER: '이미지 일괄등록',
  SALE_STATUS: '판매상태',
  SALE_ABLE: '판매가능',
  OPTION_IMG: '옵션이미지',
  TIP_OPTION_INPUT_SURE: '옵션 입력시 확인 가능합니다.',
  REGISTER_OVER: '변경',
  REGISTER: '등록',
  ADD_CATEGORY: '목록 추가',
  TIP_CREATE_BUYER_SURE: '구매자작성형 선택 시 확인 가능합니다.',
  SAVE: '저장',
  CANCEL: '취소',
  REPRESENT_IMG: '대표이미지URL',
  DETAIL_IMG: '상세이미지URL',
  TIP_SURE_STYLE: '파일 양식을 다시 확인해 주세요.',
  TIP_MAX_5: '옵션명은 최대 5개까지 추가 가능합니다.',
  TIP_MAX_5_OPTION: '옵션은 최대 5개까지 추가 가능합니다.',
  PLEASE_SELECT_OPTION: '옵션을 선택해주세요.',
  PLEASE_SELECT_DELETE: '삭제할 옵션을 선택해주세요.',
  TIP_OPTION_NAME_CORRECT: '옵션명을 정확히 입력해주세요',
  TIP_OPTION_VALUE: '옵션값을 입력해주세요.',
  TIP_OPTION_PRICE_10: '옵션가는 10원 단위로 입력 가능합니다.',
  TIP_SAVED: '저장되었습니다.',
  TIP_USEYN_Y_VALUE_0: '사용여부 Y, 옵션가 0인 옵션을 등록해주세요.',
  PLEASE_BUYER_NAME_CORRECT: '구매자작성형 옵션명을 정확히 입력해주세요.',
  TIP_USEYN_Y: '사용여부 Y인 옵션을 등록해주세요.',
  PLEASE_MODIFY: '옵션명은 최대 255자까지 입력 가능합니다.',
  PLEASE_NAME_25: '옵션명은 최대 25자 이내로 입력해주세요.',
  TIP_SPECIAL_TEXT: '옵션명에 "|" 문자는 사용하실 수 없습니다.',
  CALL_OTHER_OPTION_TTL: '다른 상품 옵션 불러오기',
  PRODUCT_NUM: '상품번호',
  PRODUCT_NAME: '상품명',
  SEARCH: '조회',
  INIT: '초기화',
  PRODUCT_CATEGORY: '상품 목록',
  SURE: '확인',
  TIP_NO_CATEGORY_INFO: '상품 목록 정보가 없습니다.',
  OPTION_IMG_REGISTER_MODIFY: '옵션 이미지 등록/수정',
  TIP_10MB: '용량 10MB 이하 파일만 등록 가능합니다.',
  TIP_IMG_1_5: '옵션 대표이미지 최대 1개, 옵션 상세이미지 최대 5개까지 등록 가능합니다.',
  TIP_IMG_REPRESENT_1: '옵션대표이미지 등록 시 옵션 상세 이미지 1개 이상 등록 필수 입니다.',
  TIP_DETAIL_REPRESENT: '옵션상세이미지 등록 시 옵션 대표 이미지 등록 필수 입니다.',
  OPTION_REPRESENT: '옵션 대표이미지',
  SELECT_FILE: '파일선택',
  OPTION_DETAIL_IMG: '옵션 상세이미지',
  MAX_10: '최대 10MB까지 가능합니다.',
  TIP_MAX_5CASE: '최대 5개까지 추가할 수 있습니다.',
  PLEASE_OPTION_REPRESENT: '옵션 대표 이미지를 등록해주세요.',
  PLEASE_OPTION_DETAIL_IMG: '옵션 상세 이미지를 등록해주세요.',
  OPTION_IMG_ALL: '옵션 이미지 일괄등록',
  TIP_IMG_NAME_NUM_SAME: '이미지 파일명을 옵션의 No. 값과 일치하게 등록해야 합니다.',
  TIP_MAX_1_DETAIL_MAX_5: '옵션 대표이미지 최대 1장, 옵션 상세이미지 최대 5장까지 등록 가능합니다.',
  TIP_FILE_NAME: '옵션 대표이미지 파일명 예시. 01.jpg, 02jpg, 03jpg',
  TIP_DETAIL_FILE_NAME: '옵션 상세이미지 파일명 예시. 01_01.jpg, 01_02.jpg, 02_01.jpg, 02_02.jpg, 03_01.jpg',
  OPTION_IMG_TTL: '옵션 이미지',
  TIP_MAX_5_REGISTER: '최대5개 등록 가능합니다.',
  TIP_GUIDE_SURE: '등록 가이드를 확인 후 업로드해 주세요.',
  TIP_ERROR_IMG: '이미지 등록 중 오류가 발생했습니다.',
  TIP_LIST: '등록한 옵션 리스트 중 선택 할 수 있는 형태',
  TIP_NAME_5CASE: '옵션명 최대 5개 등록 가능',
  SELECT_NEED: '단계별 선택이 필수로 필요한 경우',
  REGISTER_5_ABLE: '최대 5단계까지 등록 가능',
  ALL_MORE_1_0_OPTION: '전체 옵션 중 최소 1개 이상 추가금액이 0원인 옵션 필요',
  BUYER_DIRECTLY_INPUT: '구매자가 직접 입력 값을 작성하는 형태',
  MIN_1_SET: '최소 1개 이상 노출로 설정되어 있는 옵션 필요',
  BUYER_DIRECT: '구매자 직접입력',
  SET_OPTION_REGISTER: '세트옵션등록',
  SET_OPTION_INPUT: '세트옵션입력',
  SET_OPTION: '세트옵션',
  SET_OPTION_ALL: '세트옵션 목록 총',
  IMG_MODIFY_ALL: '이미지 일괄수정',
  SEE_WIDE: '넓게보기',
  SALE_PRICE: '옵션판매가',
  PRE_STOCK: '예약재고',
  SET_CONSTITUTE_OPTION: '세트구성옵션',
  NAME_PRICE: '옵션명:옵션값',
  COUNT: '수량',
  SELLING_PRICE: '판매가',
  PLEASE_SET_OPTION_CORRECT: '세트옵션값을 정확히 입력해주세요.',
  CALL_OPTION: '구성옵션 불러오기',
  SEE_ONE_EYE: '한눈에보기',
  PLEASE_MORE_1: '세트옵션에 세트구성옵션을 1개 이상 등록해주세요.',
  PLEASE_MORE_10: '세트옵션의 옵션판매가를 10원 이상으로 입력해주세요.',
  PLEASE_UNDER_10_BILLION: '옵션가격은 10억원 미만으로 입력 가능합니다.',
  PRODUCT_MANAGE_CODE: '상품관리코드',
  OPTION_LIST_ALL: '옵션 목록 총',
  ADD_SELECTED_OPTION: '선택옵션추가',
  SELECT_LIST_ALL: '선택된 구성옵션 목록 총',
  PLEASE_SELECT: '선택된 옵션이 없습니다.',
  PLEASE_ADD: '추가할 옵션을 선택해주세요.',
  PLEASE_MAX_10_SET: '세트구성옵션은 최대 10개까지 추가 가능합니다.',
  ALREADY_ADD: '이미 추가된 옵션입니다.',
  SELECT_SET_OPTION: '세트구성옵션을 선택해주세요.',
  DELETE_SET_OPTION: '선택불가한 세트옵션은 제외하고 등록됩니다.',
  ALREADY_ADD_SUCCESS: '이미 추가된 옵션을 제외하고 추가에 성공했습니다.',
  DUPLICATE_OPTIONS: '중복된 옵션은 제외하고 추가됩니다.',
  OPTION_NAME_PRICE: '옵션명 : 옵션값',
  SALE_ABLE_STOCK: '판매가능재고',
  PRE_APPROVAL_STATUS: '승인완료이전',
  ON_PRE_SALE: '예약판매중',
  WAITING_SALE: '판매대기',
  ON_SALE: '판매중',
  END_SALE: '판매종료',
  ADD_CONFIGURATION_OPTION: '구성옵션으로 추가',
  DELETABLE_N: '세트옵션으로 등록된 옵션은 삭제할 수 없습니다.',
  SELECTABLE_N_SAVE: '일부 세트구성옵션이 등록 불가한 상태로 변경되어 제외됩니다.',
  SELECTABLE_N: '선택불가한 세트구성옵션은 제외하고 등록됩니다.',
  PLACEHOLDER_LIMIT_100: '100자까지 입력가능',
  PLACEHOLDER_LIMIT_45: '45자까지 입력가능',
  SOLD_OUT_MODIFICATION: '품절인 옵션은 제외하고 품절상태가 수정됩니다.',
  CHARACTER: '캐릭터',
  SLEEPING_SUPPLIES: '수면용품세트',
  RABBIT: '토끼',

  IMAGE_FORM_NOTICE_1: '용량 10MB 이하 파일만 등록 가능합니다.',
  IMAGE_FORM_NOTICE_2: '옵션 대표이미지 최대 1장, 옵션 상세이미지 최대 5장까지 등록 가능합니다.',
  IMAGE_FORM_NOTICE_3: '옵션대표이미지 등록 시 옵션 상세 이미지 1개 이상 등록 필수 입니다.',
  IMAGE_FORM_NOTICE_4: '옵션상세이미지 등록 시 옵션 대표 이미지 등록 필수 입니다.',
  EXCEL_SAMPLE_FILE_NAME: '옵션일괄등록_업로드양식',
  PLEASE_VALUE_MODIFY: '옵션값은 최대 255자까지 입력 가능합니다.',
  OPTION_VALUE_NUMBER_ONLY: '옵션가는 숫자 또는 –만 입력 가능합니다.',
  PLEASE_SUPPLY_UNDER_10_BILLION: '공급가는 10억원 미만으로 입력 가능합니다.',
  SUPPLY_NUMBER_ONLY: '공급가는 숫자만 입력 가능합니다.',
  STOCK_COUNT_UNDER_BILLION: '재고수량은 1억 미만으로 입력 가능합니다.',
  OPTION_MANAGEMENT_CD_MODIFY_45: '옵션관리코드는 최대 45자까지 입력 가능합니다.',
  OPTION_MANAGEMENT_CD_INVALID_TEXT: '옵션관리코드에 입력 불가한 문자가 포함되어 있습니다.',
  TIP_USEYN_INVALID: '사용여부는 Y 또는 N으로 입력 가능합니다.',
  CHECK_REPRESENT_IMG_URL: '대표이미지는 URL 형식으로 입력 가능합니다.',
  CHECK_REPRESENT_IMG_TYPE: '대표이미지 파일 형식이 올바르지 않습니다.',
  CHECK_OPTION_IMG_REQUIRED_REPRESENT_IMG: '옵션 이미지 등록 시 대표이미지는 필수 입니다.',
  CHECK_REPRESENT_IMG_REQUIRED_OPTION_IMG: '옵션 이미지 등록 시 상세이미지는 필수 입니다.',
  CHECK_OPTION_IMG_URL: '상세이미지는 URL 형식으로 입력 가능합니다.',
  CHECK_OPTION_IMG_TYPE: '상세이미지 파일 형식이 올바르지 않습니다.',
});
