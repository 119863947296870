





































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { throwMessagePopup, PopupClose } from '@/helpers/popup';

@Component({
  components: {
    TextInput,
  },
})
export default class WithdrawIssue extends Vue {
  @Prop() private data;
  @Prop() private onNegativeClick!: Function;
  @Prop() private onPositiveClick!: Function;

  // 철회사유
  private reason = '';
  @Ref() private readonly reasonRef: TextInput;

  private async withdraw() {
    if (this.reason === '') {
      throwMessagePopup(this.$t('PROMOTION.COUPON.WITHDRAW_REASON_PLACEHOLDER')).then(() => {
        this.reasonRef.focus();
      });
      return;
    }
    const result = await throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_WITHDRAW_CONFIRM'), true);
    if (result.state === PopupClose.CONFIRM) {
      await this.$api.putCouponsIssuesCouponIssueNoWithdraw({
        pathParams: { couponIssueNo: this.data.couponIssueNo },
        data: { reason: this.reason },
      });
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_WITHDRAW')).then(() => {
        this.onPositiveClick();
      });
    }
  }
}
