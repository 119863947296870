






















import { Component, Ref } from 'vue-property-decorator';
import Editor from '@/components/common/summernote/Editor.vue';
import { getCurrentMallNo } from '@/utils/mall';
import WindowPopupMain from '@/views/popups/Main.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import { GUIDE_TYPE } from '@/const/contents/product';
import { i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { PartnerType } from '@/types';
import { PARTNER_TYPE } from '@/const/common';
@Component({
  components: { SubmitButton, Editor },
})
export default class DefaultGuideTemplateForm extends WindowPopupMain {
  private mallNo = getCurrentMallNo(this);
  private i18nForProduct = i18nForProduct;

  private content = '';

  private get type(): keyof typeof GUIDE_TYPE {
    return this.data.type;
  }
  private get partnerNo(): number {
    return this.data.partnerNo;
  }
  private get partnerType(): PartnerType {
    return this.data.partnerType;
  }

  private fetchGuideContent() {
    this.$api
      .getProductsGuides({
        params: {
          partnerNo: this.partnerNo,
          type: this.type,
        },
      })
      .then(res => {
        this.content = res.data.content;
        this.editor.setHtml(res.data.content);
      });
  }

  private init() {
    this.fetchGuideContent();
  }

  created() {
    this.init();
  }

  @Ref()
  private readonly editor!: Editor;
  private onSave() {
    if (!confirm(this.i18nForProduct('CONFIRM_EDIT'))) return;
    const content = this.editor.getHtml();
    this.$api
      .postProductsGuides({
        data: {
          isPartnerShipment: this.partnerType === PARTNER_TYPE.PARTNER,
          partnerNo: this.partnerNo,
          type: this.type,
          content,
        },
      })
      .then(({ status }) => {
        if (status === 201) {
          alert(this.$t('ALERT_UPDATED'));
          this.onPositiveClick({ content });
        }
      });
  }

  private async fetchGuideTemplateDefault() {
    const { data } = await this.$api.getProductsGuidesTemplatesDefault({
      params: {
        templateType: this.type,
      },
    });
    this.content = data.content;
    this.editor.setHtml(data.content);
  }

  private reset() {
    this.fetchGuideTemplateDefault();
  }
}
