




















import { Component, Prop, Vue } from 'vue-property-decorator';
import SecurityAuthInput from '@/components/security/SecurityAuthInput.vue';

@Component({
  components: { SecurityAuthInput },
})
export default class OtpAuthSetting extends Vue {
  @Prop() private data;
  @Prop() private onPositiveClick!: Function;
  @Prop() private onNegativeClick!: Function;

  private authKey = '';

  private async saveSetting(): Promise<void> {
    if (!this.authKey) {
      alert(`${this.$t('AUTH_NUMBER')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }
    try {
      const response = await this.$api.putAdminsOtpConfig({
        data: {
          code: this.authKey,
          enabled: true,
        },
      });

      if (response && response.status === 204) {
        this.onPositiveClick();
      }
    } catch (error) {
      if (error.data.code === 'E0301') {
        alert(this.$t('ALERT_INVALID_OTP_VALUE'));
      }
    }
  }
}
