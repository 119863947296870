





















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getCampaignsRequirementsOption } from '@/components/popup/marketing/CampaignsRequirementsConfirmation';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  CampaignsRequirementsValidation,
  initialCampaignsRequirements,
} from '@/components/popup/marketing/CampaignsRequirementsValidation';

@Component({
  components: {
    ModalHeader,
    NoticeBox,
    ToolTip,
  },
})
export default class CampaignsRequirementsConfirmation extends Vue {
  @Prop() private readonly data: { mallNo: number };
  private campaignsRequirementsOption = getCampaignsRequirementsOption(this.data.mallNo);
  private loadingData = initialCampaignsRequirements;
  private validationData = initialCampaignsRequirements;
  private campaignsRequirements: CampaignsRequirementsValidation | null = null;
  private readonly noticeList = [this.$t('MARKETING.GOOGLE_AD.NOTICE1'), this.$t('MARKETING.GOOGLE_AD.NOTICE2')];

  async created() {
    this.campaignsRequirements = new CampaignsRequirementsValidation(this.data.mallNo);
    await this.campaignsRequirements.init();
    this.loadingData = this.campaignsRequirements.loadingData;
    this.validationData = this.campaignsRequirements.validationData;
  }

  private async handleConfirmClick() {
    await this.campaignsRequirements.init();
    if (this.campaignsRequirements.isAllValid()) this.$emit('confirm');
  }
}
