import router from '@/router';

/**
 * @param componentName 컴포넌트 명
 * @param upperPass /pro/ 를 포함시킬지 말지 여부. default = true
 */
export const getHref = (componentName: string, upperPass = true): string => {
  const { href, route } = router.resolve({ name: componentName });

  return upperPass ? href : route.fullPath;
};
