




import { Vue, Component } from 'vue-property-decorator';
import MallSelector from '@/components/MallSelector.vue';

@Component({
  components: {
    MallSelector,
  },
})
export default class AutoDeposit extends Vue {}
