import { i18n } from '@/main';

export function i18nAllObject(vueEntity, object) {
  Object.keys(object).forEach((key: string): void => {
    object[key] = vueEntity.$t(object[key]);
  });
  return object;
}
export function i18nObjectByKey(vueEntity, object, key: string) {
  object[key] = vueEntity.$t(object[key]);
  return object;
}
export function i18nListByKey(vueEntity, arr, key: string) {
  const data = [];
  arr.forEach(item => {
    item[key] = vueEntity.$t(item[key]);
    data.push(item);
  });
  arr = data;
  return arr;
}
export function i18nInObjectList(vueEntity, obj, listKey: string, key: string) {
  const data = [];
  obj[listKey].forEach(item => {
    item[key] = vueEntity.$t(item[key]);
    data.push(item);
  });
  obj[listKey] = data;
  return obj;
}
export function i18nList(wordList: string[]): string[] {
  return wordList.map(word => i18n.t(word).toString());
}

export function convertToI18n(key: string, word: string, data?: string, useDefault = true): string {
  const value = data ? { value: useDefault ? i18n.t(`${key}${data}`) : data } : null;
  return i18n.t(`${key}${word}`, value).toString();
}

export function i18nForProduct(word: string): string {
  return i18n.t(`PRODUCT.ADD.${word}`).toString();
}
