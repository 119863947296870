

































































































































import { Component, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { CertificationInfo, NCPResponse } from 'ncp-api-supporter';
import DateCalendar from '@/components/common/DateCalendar.vue';
import {
  alertMsg,
  getDefaultCertificationInfoDetails,
  getValidResult,
  hasInvalidSymbol,
  isAllValid,
  noticeList,
} from '@/views/popups/product/productAdd/UpsertCertificationInfo';
import {
  CertificationInfoDetails,
  CertificationInfoNames,
  CertificationNoticeList,
  DateCalendarOption,
  RequiredCertificationType,
  InputNumber,
} from '@/types';
import { DEFAULT_DATE_RANGE } from '@/components/common/datepicker/dateRange';

const requiresInputCategoryNo = {
  kidCertify: 11, // 어린이제품 안전인증
  kidConfirm: 12, // 어린이제품 안전확인
  kidSupply: 13, // 어린이제품 공급자적합성 확인
};

const requiresCalendarCategoryNo = {
  broadEquipCertificate: 8, // 방송통신기자재 적합인증
  broadEquipRegistration: 9, // 방송통신기자재 적합등록
  broadEquipDate: 10, // 방송통신기자재 잠정인증일
};

@Component({
  components: {
    DateCalendar,
    SelectBox,
    NoticeBox,
    TextInput,
  },
})
export default class UpsertCertificationInfo extends WindowPopupMainVue {
  @Ref() private readonly certificationAuthorityRef: TextInput;
  @Ref() private readonly certificationNumberRef: TextInput;
  @Ref() private readonly certificationCompanyNameRef: TextInput;
  @Ref() private readonly certificationDateRef: DateCalendar;
  @Ref() private readonly selectBoxRef: SelectBox;
  private noticeList: CertificationNoticeList = noticeList();

  private isFailStyle(certification: CertificationInfoDetails, type: CertificationInfoNames): boolean {
    return this.isSaved && certification.certificationCategoryNo && !certification[type];
  }

  private isRequire(categoryNo: InputNumber, type: 'input' | 'calender') {
    let isInput = false;
    let isCalender = false;
    let onlyInput = false;
    let bothExist = false;
    const inputCategory = Object.values(requiresInputCategoryNo);
    const CalendarCategory = Object.values(requiresCalendarCategoryNo);

    for (const item of inputCategory) {
      if (item === categoryNo) {
        isInput = true;
      }
    }

    for (const item of CalendarCategory) {
      if (item === categoryNo) {
        isInput = true;
        isCalender = true;
      }
    }

    if (isCalender !== isInput) {
      // 캘린더만 있는 경우는 없습니다.
      onlyInput = true;
    } else if (isCalender && isInput) {
      bothExist = true;
    }

    if (bothExist) {
      return true;
    }

    if (onlyInput && type === 'input') {
      return true;
    }

    if (onlyInput && type === 'calender') {
      return false;
    }

    return false;
  }

  private getDateCalendarOption(idx: number, date: string): DateCalendarOption {
    return { selectedYmd: date ? date : '', name: `certificationDate_${idx}`, fromRanges: `${DEFAULT_DATE_RANGE.MIN}` };
  }

  private selectOptionsForCertificationType: CertificationInfo[] = [];
  private async fetchCertificationInfo(): Promise<void> {
    const { data }: NCPResponse<CertificationInfo[]> = await this.$api.getCertificationInfos({
      params: {
        standardCategoryNo: this.data.categoryNo,
      },
    });
    this.selectOptionsForCertificationType = data;
  }

  private readonly DEFAULT_CERTIFICATION_INFO_SIZE = 5;
  private certificationInfoDetails: CertificationInfoDetails[] = [];
  private get totalSize(): number {
    return this.certificationInfoDetails.length;
  }

  private setCertificationInfoDetails(): void {
    if (this.data.certifications) {
      this.mapCertificationInfoDetails();
    }
    this.setDefaultCertificationInfoDetails();
  }

  private mapCertificationInfoDetails(): void {
    this.certificationInfoDetails = this.data.certifications.map(
      ({ certificationCategoryNo, certificationContents }) => {
        const [
          certificationAuthority,
          certificationNumber,
          certificationCompanyName,
          certificationDate,
        ] = certificationContents;
        return {
          certificationCategoryNo,
          certificationAuthority,
          certificationNumber,
          certificationCompanyName,
          certificationDate,
        };
      },
    );
  }

  private setDefaultCertificationInfoDetails(): void {
    const maxSize = this.certificationInfoDetails
      ? this.DEFAULT_CERTIFICATION_INFO_SIZE - this.totalSize
      : this.DEFAULT_CERTIFICATION_INFO_SIZE;
    for (let i = 0; i < maxSize; i++) {
      this.certificationInfoDetails.push(getDefaultCertificationInfoDetails());
    }
  }

  private changedCertificationNo(idx: number, chosenCategoryNo: number): void {
    if (!this.isChangeable(idx, chosenCategoryNo)) return;

    const newCertificationName = this.selectOptionsForCertificationType.find(
      ({ certificationNo }) => certificationNo === chosenCategoryNo,
    )?.certificationCategory;

    newCertificationName !== undefined &&
      this.$set(this.certificationInfoDetails[idx], 'certificationName', newCertificationName);
  }

  private isChangeable(idx: number, chosenCategoryNo: number): boolean {
    const unchangeable =
      this.certificationInfoDetails.filter(info => info.certificationCategoryNo === chosenCategoryNo).length > 1;

    unchangeable && this.undoChanges(idx, chosenCategoryNo > 0);
    return !unchangeable;
  }

  private undoChanges(idx: number, useAlert: boolean): void {
    useAlert && alertMsg('categoryNo');
    this.$nextTick(() => (this.selectBoxRef[idx].$el.value = ''));
    this.$set(this.certificationInfoDetails, idx, getDefaultCertificationInfoDetails());
  }

  // 인증정보 추가
  private isAdditional(idx: number): boolean {
    return idx > this.DEFAULT_CERTIFICATION_INFO_SIZE - 1;
  }

  private addCertificationInfoDetails(): void {
    if (!this.isAddableCertificationDetails) return;
    this.certificationInfoDetails.push(getDefaultCertificationInfoDetails());
  }

  private get isAddableCertificationDetails(): boolean {
    const MAX_ADD_SIZE = 20;
    if (this.totalSize < this.DEFAULT_CERTIFICATION_INFO_SIZE) return false;
    if (this.totalSize === MAX_ADD_SIZE) {
      alertMsg('add');
      return false;
    }
    return true;
  }

  private deleteCertificationInfoDetails(idx: number): void {
    this.certificationInfoDetails.splice(idx, 1);
  }

  private changedText(text: string): string {
    if (hasInvalidSymbol(text)) {
      text = text.substring(0, text.length - 1);
    }
    return text;
  }

  private changedSelectedDateTime(idx: number, date: string): void {
    this.certificationInfoDetails[idx].certificationDate = date;
  }

  private isSaved = false;
  private onSave(): void {
    this.isSaved = true;
    let invalidType: RequiredCertificationType = '';

    this.certificationInfoDetails.forEach(info => {
      const { isBasic, isKid, isDate } = getValidResult(info);
      if (!isBasic || !isKid) {
        invalidType = 'basic';
        return;
      }
      if (!isDate) {
        invalidType = 'date';
        return;
      }
    });

    if (!isAllValid(invalidType)) return;
    this.onPositiveClick(this.certificationInfoDetails.filter(v => v.certificationCategoryNo > 0));
  }

  created() {
    this.fetchCertificationInfo();
    this.setCertificationInfoDetails();
  }
}
