




import { Vue, Component } from 'vue-property-decorator';
import SearchForm from '@/components/product/basic/SearchForm.vue';
import SearchResult from '@/components/product/basic/SearchResult.vue';
import ProductInquiryCore from '@/views/popups/product/inquiry/ProductInquiryCore.vue';

@Component({
  components: {
    ProductInquiryCore,
    SearchResult,
    SearchForm,
  },
})
export default class ProductInquiry extends Vue {}
