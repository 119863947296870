
























































import { Vue, Component, Ref } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  GetMallsDataRequest,
  Bank,
  NCPResponse,
  OrderconfigsCartResponse,
  GetOrderConfigsOrder,
  GetMembersJoinConfig,
  PostMallsRequest,
  MallsResponse,
  PutOrderconfigsCartRequest,
  GetNotificationsConfigurationsRequest,
  NotificationsConfigurationsResponse,
  PGPayTypes,
  PutOrderConfigsPaymentsPayTypesRequest,
  GetOrderConfigsPaymentsPgRequest,
  PostClaimsConfigsRequest,
  ClaimsConfigs,
  PostMembersJoinconfigRequest,
  PutContractfilesMallsRequest,
  PutSettlementsconfigurationsRequest,
  PatchMallsMallNo,
  MallsDataResponse,
  PutOrderconfigsOrderRequest,
  PostOrderconfigsOrderRequest,
  PutProductsConfigurationRequest,
  PatchMallsMallNoConfigurationDeepLinkRequest,
} from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  getDefaultMallData,
  getDefaultTradeBank,
  i18nMallCreate,
  getDefaultMallJoinConfig,
  getDefaultCartConfig,
  getDefaultOrderConfig,
  getContractFiles,
  tempAccount,
} from '@/const/contents/configuration/mallCreate';
import BasicInfo from '@/views/contents/configuration/basic/MallCreate/BasicInfo.vue';
import PaymentSetting from '@/views/contents/configuration/basic/MallCreate/PaymentSetting.vue';
import MemberAuthentication from '@/views/contents/configuration/basic/MallCreate/MemberAuthentication.vue';
import OrderSetting from '@/views/contents/configuration/basic/MallCreate/OrderSetting.vue';
import ManageContractFile from '@/views/contents/configuration/basic/MallCreate/ContractFileManagement.vue';

import { throwBottomNavigation } from '@/helpers/bottomNav';
import { refreshToken } from '@/utils/token';
import { TradeBankAccountItem } from '@/types/mallCreate';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { PutContractsEntryConfigurationsRequest } from 'ncp-api-supporter/dist/types';
import ContractFileManagement from '@/views/contents/configuration/basic/MallCreate/ContractFileManagement.vue';
import SettlementSetting from '@/views/contents/configuration/basic/MallCreate/SettlementSetting.vue';

interface CartConfig {
  storagePeriod: number;
  cartEquivalentOptionUnitType: string;
  storagePeriodNoLimit: boolean;
  storageMaxQuantity: number;
}
export const Type = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
} as const;
const MAX_ACCOUNT_COUNT = 5;

@Component({
  components: {
    SettlementSetting,
    ContractFileManagement,
    NoticeBox,
    TextInput,
    ToolTip,
    RadioGroup,
    SelectBox,
    BasicInfo,
    PaymentSetting,
    MemberAuthentication,
    OrderSetting,
    ManageContractFile,
  },
})
export default class MallCreate extends Vue {
  private mallNo = this.$route.params.mallId || null;
  private type: keyof typeof Type = Type.CREATE;
  // 기본 몰 정보
  private mall = getDefaultMallData();
  //결제수단 노출여부
  private pgPayTypes: PGPayTypes[] = [];
  // 카트 (장바구니) 설정
  private cartConfig = getDefaultCartConfig();
  // 주문 설정
  private orderConfig = getDefaultOrderConfig();
  // 클레임 설정
  private claimsConfig = true;
  // 회원 인증 설정 - 몰 회원 설정

  // 사업자 계약 서류관리
  private contractFiles = getContractFiles();
  private standardContractFile = '';
  private uploadContractData: PutContractfilesMallsRequest = {
    data: {
      mallNo: 0,
      domesticFiles: [],
      globalFiles: [],
      domesticGuide: '',
      globalGuide: '',
    },
  };
  private productConfig: PutProductsConfigurationRequest['data'] = {
    productDetailUrl: '',
    productDetailUrlForMobile: '',
    wmsUsed: false,
    categoryCommissionUsed: false,
    mallNo: 0,
    displayStock: false,
  };

  private deepLink: PatchMallsMallNoConfigurationDeepLinkRequest['data'] = {
    aosUploadedFileName: null,
    iosUploadedFileName: null,
  };

  private mallJoinConfig = getDefaultMallJoinConfig();
  private notificationsConfig = {} as NotificationsConfigurationsResponse;
  private bankList: Bank[] = [{ name: i18nMallCreate('SELECT_BANK'), value: '' }];

  @Ref() private readonly basicInfoRef: BasicInfo;
  @Ref() private readonly paymentSettingRef: PaymentSetting;
  @Ref() private readonly memberAuthenticationRef: MemberAuthentication;
  @Ref()
  private readonly settlementSettingRef: SettlementSetting;

  created() {
    this.setBottomNavigation();
    this.fetchBank();
    if (!this.mallNo) {
      this.$set(this.mall, 'tradeBanks', [getDefaultTradeBank()]);
      this.setTradeBanks(MAX_ACCOUNT_COUNT - 1);
      return;
    }
    this.initEditPage();
  }

  private async initEditPage() {
    this.type = Type.EDIT;
    await this.fetchMall();
    await this.fetchPayTypes();
    await this.fetchClaimsConfig();
    await this.fetchNotificationsConfig();
    await this.fetchOrderConfig();
    await this.getContractData();
  }
  private setBottomNavigation(): void {
    const buttons = [
      {
        type: 'right',
        key: 'save',
        color: 'red',
        text: this.$t('SAVE'),
      },
    ];
    const onClick = key => key === 'save' && this.onClickSaveButton();
    throwBottomNavigation({
      buttons,
      onClick,
    });
  }
  //저장 api 호출
  private async onClickSaveButton() {
    if (!(await this.basicInfoRef.validateMall())) return;
    const { bankAccount, bank, bankDepositorName } = this.mall.tradeBanks[0];
    if (!bankAccount || !bank || !bankDepositorName) {
      return alert(i18nMallCreate('ACCOUNT_VALIDATION'));
    }
    if (this.isEditPage) {
      if (!(await this.paymentSettingRef.validatePaymentSetting())) return;

      const request: PutOrderConfigsPaymentsPayTypesRequest = {
        data: { enablePays: this.pgPayTypes, mallNo: Number(this.mallNo) },
      };

      // 무통장입금 이용정지 처리가 필요한 경우인지 api 를 통해 확인
      const {
        data: { printConfirm },
      } = await this.$api.postOrderConfigsPaymentsPayTypesCheck(request);

      //printConfirm = true 무통장입금 이용정지 처리 시에 컨펌 출력
      if (printConfirm && !confirm(`${this.$t('CONFIGURATION.PG.CONFIRM_TO_UPDATE_CONFIGS_PAYMENTS_PAY_TYPE')}`)) {
        return;
      }

      await this.editMall();
    } else {
      await this.createMall();
    }

    this.patchDeepLink();
  }

  private patchDeepLink() {
    const requestData = Object.fromEntries(Object.entries(this.deepLink).filter(([_, value]) => value !== null));

    if (requestData) {
      this.$api.patchMallsMallNoConfigurationDeepLink({
        params: { mallNo: this.mallNo },
        data: requestData,
      });
    }
  }

  private async createMall() {
    //1. 몰생성
    const mallNo = Number(await this.createMallNo());
    // 2. 설정 세팅
    await this.postOrderConfigsCart(mallNo);
    await refreshToken();
    alert(i18nMallCreate('ADD_MALL_SUCCESS_ALERT'));
    await this.$router.push({ name: 'MallList', params: { create: 'true' } });
  }
  private async editMall() {
    // 결제수단노출설정, 몰, 장바구니, 회원인증설정, 추가될것 -> 주문설정, 접속설정
    const mallNo = Number(this.mallNo);
    const promiseGroup = [
      this.putClaimsConfigs,
      this.patchMembersJoinConfig,
      this.putSettlementSetting,
      this.putContractFiles,
      this.putStandardContract,
      this.putOrderConfigsOrder,
      this.putProductsConfiguration,
      this.postOrderConfigsCart,
    ];

    try {
      await this.putMall();
      await this.putPayTypes();
    } catch (e) {
      const errorMessage = e.data.errors[0]?.reason ? e.data.errors[0]?.reason : e.data.message;
      alert(errorMessage);
      return;
    }

    Promise.allSettled(promiseGroup.map(promise => promise(mallNo))).then(results => {
      Promise.all(
        results.map(async (result, index) => {
          result.status === 'rejected' && (await promiseGroup[index](mallNo));
        }),
      ).then(() => {
        alert(i18nMallCreate('EDIT_MALL_SUCCESS_ALERT'));
        this.$router.push({ name: 'MallList' });
      });
    });
  }

  private removeEmptyBankData(mall): [TradeBankAccountItem] {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return mall.tradeBanks.filter(({ bank }) => bank).map(({ bankName, ...rest }) => rest);
  }
  private generateMallData(isEdit = true): any {
    const copiedMall = { ...this.mall };
    copiedMall.tradeBanks = this.removeEmptyBankData(copiedMall);
    if (isEdit === false) {
      copiedMall.serviceCenter.outboundEmail = copiedMall.serviceCenter.email;
      delete copiedMall.introRedirection;
    } else {
      delete copiedMall.serviceCenter.outboundEmail;
    }
    return copiedMall;
  }
  // 하단 POST api 모음 => 쇼핑몰 생성
  private async createMallNo(): Promise<string> {
    const mallData = this.generateMallData(false);
    const mall: PostMallsRequest = {
      data: mallData,
    };
    try {
      const { data }: NCPResponse<MallsResponse> = await this.$api.postMalls(mall);
      return data.mallNo;
    } catch (e) {
      alert(e.data.message);
    }
  }
  // 주문 자동처리기간 설정 수정
  private async putOrderConfigsOrder(mallNo: number) {
    const request = ({
      params: {
        mallNo,
      },
      data: {
        ...this.orderConfig,
      },
    } as unknown) as PutOrderconfigsOrderRequest | PostOrderconfigsOrderRequest;

    this.isEditPage
      ? await this.$api.putOrderconfigsOrder(request as PutOrderconfigsOrderRequest)
      : await this.$api.postOrderconfigsOrder(request as PostOrderconfigsOrderRequest);
  }
  // 상품상세 URL 설정 수정
  private async putProductsConfiguration(mallNo: number) {
    const request: PutProductsConfigurationRequest = {
      data: {
        ...this.productConfig,
        mallNo,
      },
    };
    try {
      await this.$api.putProductsConfiguration(request);
    } catch (e) {
      console.error(e);
    }
  }

  //장바구니 설정 등록 및 수정
  private async postOrderConfigsCart(mallNo: number) {
    const cartConfig: PutOrderconfigsCartRequest = {
      params: {
        mallNo,
      },
      data: this.cartConfig,
    };
    this.isEditPage
      ? await this.$api.putOrderconfigsCart(cartConfig)
      : await this.$api.postOrderconfigsCart(cartConfig);
  }
  //클레임 설정 등록 및 수정
  private async putClaimsConfigs(mallNo: number) {
    const request: PostClaimsConfigsRequest = {
      data: {
        mallNo,
        allowsCustomerCancelOrder: this.claimsConfig,
      },
    };
    try {
      await this.$api.putClaimsConfigs(request);
    } catch (e) {
      console.error(e);
    }
  }
  //하단 put api 모음 => 쇼핑몰 설정 수정시
  private async putMall() {
    const mallData = this.generateMallData();
    const request: PatchMallsMallNo = {
      pathParams: {
        mallNo: this.mallNo,
      },
      data: mallData,
    };
    return await this.$api.patchMallsMallNo(request);
  }
  // 회원 인증 설정
  private async patchMembersJoinConfig(mallNo: number) {
    const copiedMallJoinConfig = { ...this.mallJoinConfig };

    //TODO 일단 가입시점으로 2차오픈에서는 변경
    copiedMallJoinConfig.authenticationTimeType = 'JOIN_TIME';
    if (this.memberAuthenticationRef.useAuthentication === false) {
      copiedMallJoinConfig.authenticationTimeType = 'NONE';
      copiedMallJoinConfig.memberAuthenticationType = 'NOT_USED';
    }
    const membersJoinConfig: PostMembersJoinconfigRequest = {
      params: {
        mallNo,
      },
      data: copiedMallJoinConfig,
    };
    await this.$api.patchMembersJoinconfig(membersJoinConfig);
  }
  //결제수단 노출설정 수정
  private async putPayTypes() {
    const request: PutOrderConfigsPaymentsPayTypesRequest = {
      data: { enablePays: this.pgPayTypes, mallNo: Number(this.mallNo) },
    };
    return await this.$api.putOrderConfigsPaymentsPayTypes(request);
  }
  //하단 get api 모음 => 등록 / 수정페이지 오픈 시
  private setTradeBanks(maxCount: number) {
    for (let i = 0; i < maxCount; i++) {
      this.mall.tradeBanks.push(getDefaultTradeBank());
    }
  }
  //결제수단 노출설정 get
  private async fetchPayTypes() {
    const request: GetOrderConfigsPaymentsPgRequest = { params: { mallNo: this.mallNo } };
    try {
      const { data }: NCPResponse<PGPayTypes[]> = await this.$api.getOrderConfigsPaymentsPayTypes(request);

      this.pgPayTypes = data;
    } catch (e) {
      console.error(e);
    }
  }
  private async fetchMall() {
    const commonRequest: GetMallsDataRequest = {
      params: {
        mallNo: Number(this.mallNo),
      },
    };
    const fetchResult: NCPResponse<MallsDataResponse> = await this.$api.getMallsData(commonRequest);

    if (fetchResult.data) {
      this.mall = fetchResult.data;
      //임시계좌가 오면 지워준다.
      this.removeTempAccount();
      const tradeBanksLength = MAX_ACCOUNT_COUNT - this.mall.tradeBanks.length;
      this.setTradeBanks(tradeBanksLength);
    }
  }
  private removeTempAccount() {
    const { bank, bankAccount, bankDepositorName } = this.mall.tradeBanks[0];
    const { tempBank, tempBankAccount, tempBankDepositorName } = tempAccount;
    const isTempAccount =
      bank === tempBank && bankAccount === tempBankAccount && bankDepositorName === tempBankDepositorName;
    if (!isTempAccount) return;
    this.$set(this.mall, 'tradeBanks', [getDefaultTradeBank()]);
  }

  private async fetchNotificationsConfig() {
    const request: GetNotificationsConfigurationsRequest = { params: { mallNo: Number(this.mallNo) } };
    try {
      const { data }: NCPResponse<NotificationsConfigurationsResponse> = await this.$api.getNotificationsConfigurations(
        request,
      );
      this.notificationsConfig = data;
    } catch (e) {
      console.error(e);
    }
  }

  private fetchOrderConfig() {
    this.$api
      .getOrderconfigsCart({
        params: {
          mallNos: this.mallNo.toString(),
        },
      })
      .then((res: NCPResponse<OrderconfigsCartResponse[]>) => {
        if (res.data && res.data[0]) {
          this.cartConfig = res.data[0].value;
        }
      });
    this.$api
      .getOrderConfigsOrder({
        params: {
          mallNos: this.mallNo.toString(),
        },
      })
      .then((res: NCPResponse<GetOrderConfigsOrder[]>) => {
        if (res.data && res.data[0]) {
          const convertData = Object.fromEntries(
            Object.entries(res.data[0].value).filter(
              ([key]) => !['useRecurringPayment', 'recurringPaymentKeys'].includes(key),
            ),
          );
          this.orderConfig = convertData;
        }
      });
    this.$api
      .getMemberJoinConfig({
        params: {
          mallNo: Number(this.mallNo),
        },
      })
      .then((res: NCPResponse<GetMembersJoinConfig>) => {
        if (res.data) {
          this.mallJoinConfig = res.data;
          if (
            this.mallJoinConfig.authenticationTimeType !== 'NONE' ||
            this.mallJoinConfig.memberAuthenticationType !== 'NOT_USED'
          ) {
            this.memberAuthenticationRef.setUseAuthentication(this.mallJoinConfig);
          }
        }
      });
  }

  private async fetchClaimsConfig() {
    const request = {
      params: {
        mallNos: this.mallNo,
      },
    };
    try {
      const { data }: NCPResponse<ClaimsConfigs> = await this.$api.getClaimsConfigs(request);
      this.claimsConfig = data[0].value.allowsCustomerCancelOrder;
    } catch (e) {
      console.error(e);
    }
  }

  private fetchBank() {
    this.$api.getBanks({ params: { countryCode: 'KR' } }).then((res: NCPResponse<Bank[]>) => {
      const bankList = res.data.filter(bank => bank.value !== 'ANONYMOUS');
      this.bankList = this.bankList.concat(bankList);
    });
  }

  private get isEditPage(): boolean {
    return this.type === Type.EDIT;
  }

  private getContractData() {
    this.$api.getContractfilesMalls({ params: { mallNo: Number(this.mallNo) } }).then(res => {
      const result = res.data;
      const uploadData = this.uploadContractData.data;

      this.contractFiles = result;
      uploadData.domesticFiles = result.domesticFiles.map(file => file.uploadedName);
      uploadData.globalFiles = result.globalFiles.map(file => file.uploadedName);
      uploadData.domesticGuide = res.data.domesticGuide;
      uploadData.globalGuide = res.data.globalGuide;
    });
  }

  private addStandardContract(data: string) {
    this.standardContractFile = data;
  }

  private addContractFile({ fileType, files }) {
    this.uploadContractData.data[fileType] = files;
  }

  private addGuide({ guideType, content }) {
    this.uploadContractData.data[guideType] = content;
  }

  // 정산 설정 수정
  private async putSettlementSetting(mallNo: number) {
    const request: PutSettlementsconfigurationsRequest = {
      params: {
        mallNo: `${mallNo}`,
      },
      data: this.settlementSettingRef.settlementConfigurationRequest,
    };
    await this.$api.putSettlementsconfigurations(request);
  }

  private async putContractFiles(mallNo) {
    this.uploadContractData.data.mallNo = mallNo;
    try {
      await this.$api.putContractfilesMalls(this.uploadContractData);
    } catch (err) {
      console.error(err);
    }
  }

  private async putStandardContract(mallNo) {
    const contractRequest: PutContractsEntryConfigurationsRequest = {
      params: { mallNo },
      data: {
        entryContractContentsOfMall: this.standardContractFile,
      },
    };

    try {
      await this.$api.putContractsEntryConfigurations(contractRequest);
    } catch (err) {
      console.error(err);
    }
  }
}
