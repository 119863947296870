





















import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { ProductsSearchContent } from 'ncp-api-supporter/dist/types/modules/product/product';
import { SaleAgreementsmallProductNoView } from 'ncp-api-supporter';

@Component({
  components: {
    ModalHeader,
    NoticeBox,
    TextInput,
  },
})
export default class SaleAgreementRejectionView extends Vue {
  @Prop() private readonly data?: ProductsSearchContent;
  private readonly mallProductNo?: string = this.data?.mallProductNo.toString();
  private comment = '';
  //notice
  private readonly noticeList = [
    this.$t('PRODUCT.COMMON.SALE_AGREEMENT_REJECTION_REASON_NOTI_MESSAGE_1'),
    this.$t('PRODUCT.COMMON.SALE_AGREEMENT_REJECTION_REASON_NOTI_MESSAGE_2'),
  ];

  //상품 판매합의 조회
  private async fetchSaleAgreementsmallProductNoViewData() {
    if (!this.mallProductNo) return;
    const { data } = await this.$api.GetSaleAgreementsmallProductNoView({
      pathParams: { mallProductNo: this.mallProductNo },
    });
    this.setSaleAgreementsmallProductNoData(data);
  }
  private setSaleAgreementsmallProductNoData(data: SaleAgreementsmallProductNoView) {
    this.comment = data?.reason?.comment ?? '';
  }
  created() {
    this.fetchSaleAgreementsmallProductNoViewData(); //상품 판매합의 조회
  }
}
