





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClaimType } from 'ncp-api-supporter';

// 취소리스트 p.21
@Component
export default class WithdrawMessage extends Vue {
  @Prop() private readonly data: { claimType: ClaimType; validationType: string; afterClaimNos: string[] };
  @Prop() private onPositiveClick!: () => void;
  @Prop() private onNegativeClick!: () => void;

  private get isWithdrawable() {
    return this.data.validationType === 'WITHDRAWABLE';
  }
}
