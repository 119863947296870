
























import {
  SIZE_TYPE,
  SLIDE_NAVI_TYPE,
  SLIDE_SPEED,
  SLIDE_TYPE,
  SLIDE_SPEED_NUM,
  BUTTON_BORDER_TYPE,
  BUTTON_SIZE_TYPE,
} from '@/const/contents/banner';
import { SlickOptions } from '@/types/slick';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import Slick from 'vue-slick';
import $ from 'jquery';
import { $el } from '@/utils/element';

@Component({
  components: {
    Slick,
  },
})
export default class KakaoTemplatePrecaution extends Vue {
  @Prop() private onNegativeClick!: () => void;
  @Prop() private data;
  @Ref() private slick!: Slick;

  private slickOptions: SlickOptions = {
    infinite: true,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: SLIDE_SPEED_NUM.NORMAL,
    arrows: true,
    dots: true,
    fade: false,
    // prevArrow: '',
    // nextArrow: '',
    dotsClass: 'init-slick-dot slick-dots',
  };
  @Watch('data.results.bannerImagePreviews')
  bannerImagePreviewsChange() {
    this.styleReturn();
  }
  private refreshSlick(): void {
    const currIndex = this.slick['currentSlide']();
    this.slick['destroy']();
    this.$nextTick(() => {
      this.slick['create']();
      this.slick.goTo(currIndex);
      this.setButtonImage();
    });
  }

  private created(): void {
    if (this.data.results.bannerImagePreviews && this.data.results.bannerImagePreviews.length > 0) {
      this.data.results.bannerImagePreviews.forEach((e, i) => {
        let widthTemporary = 0;
        let heightTemporary = 0;
        this.getImageSizeByUrl(e.imageUrl, function(width, height) {
          widthTemporary = width;
          heightTemporary = height;
        });
        this.data.results.bannerImagePreviews[i]['originWidth'] = widthTemporary;
        this.data.results.bannerImagePreviews[i]['originHeight'] = heightTemporary;
      });
      if (this.data.results.slideBannerConfig) {
        this.slickOptions.autoplaySpeed = this.data.results.slideBannerConfig.slideTime * 1000;
        let speed = 0;
        if (this.data.results.slideBannerConfig.slideSpeedType === SLIDE_SPEED.FAST) {
          speed = SLIDE_SPEED_NUM.FAST;
        } else if (this.data.results.slideBannerConfig.slideSpeedType === SLIDE_SPEED.NORMAL) {
          speed = SLIDE_SPEED_NUM.NORMAL;
        } else if (this.data.results.slideBannerConfig.slideSpeedType === SLIDE_SPEED.SLOW) {
          speed = SLIDE_SPEED_NUM.FAST;
        }
        this.slickOptions.speed = speed;
        this.slickOptions.autoplay = this.data.results.slideBannerConfig.slideTime === 0 ? false : true;

        this.slickOptions.arrows = this.data.results.slideBannerConfig.usableSlideButton;
        if (this.slickOptions.arrows) {
          const colorHexCode = this.data.results.slideBannerConfig.slideButtonColor;
          this.appenToBodyBackColor('.slick-next:before', colorHexCode);
          this.appenToBodyBackColor('.slick-next:after', colorHexCode);
          this.appenToBodyBackColor('.slick-prev:before', colorHexCode);
          this.appenToBodyBackColor('.slick-prev:after', colorHexCode);
        }
        const naviType = this.data.results.slideBannerConfig.slideNavigationType;
        if (naviType === SLIDE_NAVI_TYPE.INVISIBLE) {
          this.slickOptions.dots = false;
        } else if (naviType === SLIDE_NAVI_TYPE.VISIBLE) {
          this.slickOptions.dots = true;
          this.slickOptions.dotsClass = `init-slick-dot slick-dots ${this.naviNowBorder()} ${this.naviNowSize()}`;
          this.appenToBodyBackColor(
            '.slick-dots li.slick-active button',
            this.data.results.slideBannerConfig.slideNavigationInfo.activeButtonColor,
          );
          this.appenToBodyBackColor(
            '.slick-dots li button',
            this.data.results.slideBannerConfig.slideNavigationInfo.inactiveButtonColor,
          );
        } else if (naviType === SLIDE_NAVI_TYPE.UPLOAD) {
          this.slickOptions.dots = true;
          this.slickOptions.dotsClass = 'init-slick-dot slick-dots upload-init';
        }
        this.slickOptions.fade = this.data.results.slideBannerConfig.slideEffectType === SLIDE_TYPE.FADE ? true : false;
      }
    }
  }
  private mounted(): void {
    this.slick.reSlick();
    this.refreshSlick();
    this.styleReturn();
  }
  private getImageSizeByUrl(url: string, callback) {
    // url을 통하여 원본 그림 사이즈 가져오기
    const img = new Image();
    img.src = url;
    if (img.complete) {
      callback(img.width, img.height);
    } else {
      img.onload = function() {
        callback(img.width, img.height);
      };
    }
  }
  private appenToBodyBackColor(className: string, backColor: string): void {
    $(
      `<style type="text/css">.banner-preview-wrap ${className}{background-color: ${backColor} !important}</style>`,
    ).appendTo('body');
  }
  private naviNowBorder(): string {
    const type = this.data.results.slideBannerConfig.slideNavigationInfo.buttonBorderType;
    if (type === BUTTON_BORDER_TYPE.CIRCLE) {
      return 'border-100';
    } else if (type === BUTTON_BORDER_TYPE.ROUND_SQUARE) {
      return 'border-20';
    } else if (type === BUTTON_BORDER_TYPE.SQUARE) {
      return 'border-0';
    }
  }
  private naviNowSize(): string {
    const size = this.data.results.slideBannerConfig.slideNavigationInfo.buttonSizeType;
    if (size === BUTTON_SIZE_TYPE.SIZE_ONE) {
      return 'slick-dots-8';
    } else if (size === BUTTON_SIZE_TYPE.SIZE_TWO) {
      return 'slick-dots-10';
    } else if (size === BUTTON_SIZE_TYPE.SIZE_THREE) {
      return 'slick-dots-12';
    } else if (size === BUTTON_SIZE_TYPE.SIZE_FOUR) {
      return 'slick-dots-16';
    } else if (size === BUTTON_SIZE_TYPE.SIZE_FIVE) {
      return 'slick-dots-20';
    } else if (size === BUTTON_SIZE_TYPE.SIZE_SIX) {
      return 'slick-dots-26';
    }
  }
  private setButtonImage(): void {
    if (this.data.results.bannerImagePreviews && this.data.results.bannerImagePreviews.length > 0) {
      if (this.data.results.slideBannerConfig) {
        if (this.data.results.slideBannerConfig.slideNavigationType === SLIDE_NAVI_TYPE.UPLOAD) {
          const $id = $el(document.querySelector('.upload-init')).getElement();
          this.data.results.bannerImagePreviews.forEach((e, i) => {
            const id = this.findByTag($id, 'BUTTON')[i].id;
            if (e.activeNavigationImageUrl) {
              this.appendToNaviBtn(true, id, e.activeNavigationImageUrl);
            }
            if (e.inactiveNavigationImageUrl) {
              this.appendToNaviBtn(false, id, e.inactiveNavigationImageUrl);
            }
          });
        }
      }
    }
  }
  private findByTag($container: Element, selector: string) {
    const $$elements = [];
    $container.childNodes.forEach(e => {
      if (e.childNodes) {
        e.childNodes.forEach(ele => {
          if (ele.nodeName === selector) {
            $$elements.push(ele);
          }
        });
      } else {
        if (e.nodeName === selector) {
          $$elements.push(e);
        }
      }
    });
    return $$elements;
  }
  private appendToNaviBtn(type: boolean, id: string, url: string): JQuery<HTMLElement> {
    if (type) {
      // 활성 버튼 activeNavigationImageUrl
      return $(
        `<style type="text/css">.banner-preview-wrap .upload-init.slick-dots li.slick-active button#${id}{
            background: url(${url}) no-repeat !important;
            width: auto !important;
            height: auto !important;
            font-size:0 !important;
            }</style>`,
      ).appendTo('body');
    } else {
      // 비활성 버튼 inactiveNavigationImageUrl
      return $(
        `<style type="text/css">.banner-preview-wrap .upload-init.init-slick-dot button#${id}{
            background: url(${url}) no-repeat !important;
            width: auto !important;
            height: auto !important;
            font-size:0 !important;
            }</style>`,
      ).appendTo('body');
    }
  }
  private styleReturn(wrap?: boolean): string {
    if (this.data.results.resizable) {
      if (this.data.results.sizeUnitType === SIZE_TYPE.PIXEL) {
        const width = this.data.results.width;
        const height = this.data.results.height;
        if (wrap) {
          if (Number(width) > 1000) {
            return `width:100%;height:${height}px;`;
          } else {
            return `width:${width}px;height:${height}px;`;
          }
        } else {
          return `width:${width}px;height:${height}px;`;
        }
      } else if (this.data.results.sizeUnitType === SIZE_TYPE.PERCENT) {
        if (wrap) {
          if (Number(this.data.results.width) > 100) {
            return `width:100%;height:auto;`;
          } else {
            return `width:${this.data.results.width}%;height:auto;`;
          }
        } else {
          if (Number(this.data.results.width) > 100) {
            return `width:${this.data.results.width}%;height:auto;`;
          } else {
            return `width:100%;height:auto;`;
          }
        }
      }
    } else {
      if (wrap) {
        let maxWidth = 0;
        let maxHeight = 0;
        this.data.results.bannerImagePreviews.forEach(e => {
          if (e.originWidth > maxWidth) {
            maxWidth = e.originWidth;
          }
          if (e.originHeight > maxHeight) {
            maxHeight = e.originHeight;
          }
        });
        if (maxWidth > 1000) {
          return `width: 100%; height:${maxHeight}px;`;
        } else {
          return `width: ${maxWidth}px; height:${maxHeight}px;`;
        }
      } else {
        return 'width:auto;height:auto;';
      }
    }
  }
}
