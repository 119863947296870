













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  computed: {
    externalEnter(): boolean {
      return this.option.type === 'externalEnter';
    },
  },
})
export default class ExternalEnter extends Vue {
  @Prop({ required: true })
  private readonly option;
}
