





































































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { Amounts, RefundType } from 'ncp-api-supporter';
import {
  isCancelExchange,
  isExchangeProcRequestPay,
  isExchangeProcRequestRefund,
} from '@/components/popup/claim/claimConditions';
import { generateRefundTypeSelectList } from '@/components/popup/claim/claimOptions';
import { ClaimClassType, ClaimStatusType } from '@/types/claim';
import { PgType } from '@/types/order';
import { BankAccount } from 'ncp-api-supporter/dist/types';
import AmountMixin from '@/components/popup/claim/AmountMixin';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TdAdditionalBankAccount from '@/components/popup/claim/ClaimPopup/Exchange/ExchangeProcessAmount/TdAdditionalBankAccount.vue';
import TdAdditionalPayRemitter from '@/components/popup/claim/ClaimPopup/Exchange/ExchangeProcessAmount/TdAdditionalPayRemitter.vue';
import TdRefundType from '@/components/popup/claim/ClaimPopup/Exchange/ExchangeProcessAmount/TdRefundType.vue';
import TdRefundAccount from '@/components/popup/claim/ClaimPopup/RefundAmount/TdRefundAccount.vue';
import { validateAdditionalPayRemitter, validateBankAccount } from '@/components/shipping/claim/validation';

@Component({
  components: {
    TextInput,
    SelectBox,
    TdAdditionalBankAccount,
    TdAdditionalPayRemitter,
    TdRefundType,
    TdRefundAccount,
  },
  computed: {
    isCancelExchange,
    isExchangeProcRequestPay,
    isExchangeProcRequestRefund,
    isAccountRefundType(): boolean {
      return this.refundTypeSync === 'ACCOUNT';
    },
    isPG() {
      return this.pgType !== 'NONE';
    },
    isVirtualAccount() {
      return this.isPG && this.originalPayType === 'VIRTUAL_ACCOUNT';
    },
    hasRefundAccount() {
      return Boolean(this.refundBankAccountSync?.account);
    },
    showModifyButton(): boolean {
      return (this.isExchangeProcRequestPay || this.isExchangeProcRequestRefund) && !this.isZeroClaimAmt;
    },
    showRefundInfo(): boolean {
      return !this.isAdditionalPay;
    },
    showRefundAccount() {
      return (this.isVirtualAccount && this.hasRefundAccount) || (!this.isPG && this.hasRefundAccount);
    },
  },
})
export default class ExchangeProcessAmount extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly mallNo: number;

  @Prop({ required: true })
  private readonly claimStatusType!: ClaimStatusType;

  @Prop({ required: true })
  private readonly claimClassType!: ClaimClassType;

  @Prop({ required: true })
  private readonly pgType!: PgType;

  @Prop({ required: true })
  private readonly originalPayType!: string;

  @Prop({ required: true })
  private readonly originalPayTypeLabel!: string;

  @Prop({ required: true })
  private readonly amounts!: Amounts;

  @Prop({ required: true })
  private readonly additionalPayType!: string;

  @Prop({ required: true })
  private readonly displayRefundTypeLabel!: string;

  @Prop({ required: true })
  private readonly refundPayTypeLabel!: string;

  @Prop({ required: false, default: null })
  private readonly onPutClaim: () => Promise<void>;

  @PropSync('additionalPayRemitter', { required: true })
  private additionalPayRemitterSync!: string;

  @PropSync('refundType', { required: true })
  private refundTypeSync!: RefundType;

  @PropSync('refundBankAccount', { required: true })
  private refundBankAccountSync!: BankAccount;

  @PropSync('additionalPayBankAccount', { required: true })
  private additionalPayBankAccountSync!: BankAccount;

  private isModifyMode = false;

  private refundTypeOptions = generateRefundTypeSelectList(
    this.amounts.adjustedAmounts,
    this.pgType,
    this.originalPayTypeLabel,
  );
  private refundBankOptions = [];

  created() {
    this.fetchRefundBankList();
  }

  get isAccumulationUsed(): boolean {
    return this.additionalPayType === 'ACCUMULATION';
  }

  get isAdditionalPay(): boolean {
    return Boolean(this.additionalPayType);
  }

  get showAdditionalPayBank(): boolean {
    return !this.isAccumulationUsed && this.isAdditionalPay && this.additionalPayBankAccountSync !== null;
  }

  get isZeroClaimAmt(): boolean {
    return this.amounts.adjustedAmounts.claimAmt === 0;
  }

  private async fetchRefundBankList() {
    const banksResponse = await this.$api.getBanks({ params: { countryCode: 'KR' } });
    this.refundBankOptions = banksResponse.data.filter(bank => bank.value !== 'ANONYMOUS');
  }

  private toggleModifyMode() {
    this.isModifyMode = !this.isModifyMode;
  }

  private modifyRefundAccount() {
    if (!validateBankAccount(this.refundTypeSync, this.refundBankAccountSync)) {
      return;
    }
    const checkValid = !this.isZeroClaimAmt && this.showAdditionalPayBank;
    if (!validateAdditionalPayRemitter(this.additionalPayRemitterSync, checkValid)) {
      return;
    }
    this.onPutClaim().then(() => {
      this.toggleModifyMode();
    });
  }
}
