import { Component, Vue } from 'vue-property-decorator';
import {
  ReviewDisplayStatusType,
  YorN,
  PutReviewsStatusResponse,
  PutReviewsBestReviewResponse,
} from 'ncp-api-supporter';
// reviews api 关联 mixin (reviews api 관련 mixin)
@Component
export default class PutReviewsPropertyMixin extends Vue {
  /**
   *
   * @param reviewNos number[]
   * @param displayStatusType ReviewDisplayStatusType
   * @returns  Promise<PutReviewsStatusResponse>
   */
  public async putReviewsDisplayStatusType(
    reviewNos: number[],
    displayStatusType: ReviewDisplayStatusType,
  ): Promise<PutReviewsStatusResponse> {
    const request = {
      data: {
        reviewNos,
        displayStatusType,
      },
    };
    const response = await this.$api.putReviewsStatus(request);
    return response;
  }
  /**
   * change some reviews bestReviewYn
   * @param reviewNos number[]
   * @param bestReviewYn YorN
   * @returns Promise<PutReviewsBestReviewResponse>
   */
  public async putReviewsBestReview(reviewNos: number[], bestReviewYn: YorN): Promise<PutReviewsBestReviewResponse> {
    const request = {
      data: {
        reviewNos,
        bestReviewYn,
      },
    };
    const response = await this.$api.putReviewsBestReview(request);
    return response;
  }
}
