export default Object.freeze({
  ORDER_ALL_LIST: '전체내역(%d)건',
  ORDER_LIST: '상품 주문(%d)건',
  ORDER_CANCEL_LIST: '상품 취소(%d)건',
  ORDER_EXCHANGE_LIST: '상품 교환(%d)건',
  ORDER_RETURN_LIST: '상품 반품(%d)건',
  ORDER_TAB_LIST: '상품 주문',
  ORDER_TAB_CANCEL_LIST: '상품 취소',
  ORDER_TAB_EXCHANGE_LIST: '상품 교환',
  ORDER_TAB_RETURN_LIST: '상품 반품',
  STATUS_DEPOSIT_WAIT: '입금대기',
  STATUS_PAY_DONE: '결제완료',
  STATUS_PRODUCT_PREPARE: '상품준비중',
  STATUS_DELIVERY_PREPARE: '배송준비중',
  STATUS_DELIVERY_ING: '배송중',
  STATUS_DELIVERY_DONE: '배송완료',
  STATUS_BUY_CONFIRM: '구매확정',
  STATUS_CANCEL_DONE: '취소완료',
  STATUS_RETURN_DONE: '반품완료',
  STATUS_EXCHANGE_DONE: '교환완료',
  STATUS_PAY_WAIT: '결제대기',
  STATUS_PAY_CANCEL: '결제포기',
  STATUS_PAY_FAIL: '결제실패',
  STATUS_DELETE: '삭제',
  STATUS_EXCHANGE_WAIT: '교환대기',
  STATUS_REFUND_DONE: '환불완료',
  STATUS_HOLD_DELIVERY: '[배송보류]',
  DIRECT_DELIVERY: '직접배송(화물배달)',

  TITLE: '주문상세',
  STATUS_NUMBER: '주문번호',
  STATUS_ORDER: '상품주문',
  STATUS_CANCEL: '상품취소',
  STATUS_RETURN: '상품반품',
  STATUS_CS: 'CS처리',
  STATUS_TASK_MESSAGE: '업무메시지',
  TOP_UNIT: '건',
  TABLE_TITLE: '주문상품 정보',
  TABLE_HEADER_1: '파트너사',
  TABLE_HEADER_2: '상품번호',
  TABLE_HEADER_3: '배송구분',
  TABLE_HEADER_4: '상품명',
  TABLE_HEADER_5: '수량',
  TABLE_HEADER_6: '판매가',
  TABLE_HEADER_6_SPAN: '(할인적용가)',
  TABLE_HEADER_7: '상품합계',
  TABLE_HEADER_8: '주문상태',
  TABLE_HEADER_9: '클레임처리',
  TABLE_HEADER_10: '배송번호',
  TABLE_HEADER_11: '배송비',
  TABLE_HEADER_12: '택배사/송장번호',
  TABLE_HEADER_BASE: '기본',
  TABLE_HEADER_LOCAL: '지역별',
  DELIVERY_TYPE_MALL: '쇼핑몰배송',
  DELIVERY_TYPE_PARTNER: '파트너배송',
  DETAIL_VIEW: '상세보기',
  CANCEL_SUB: '취소신청',
  CANCEL_ALL: '전체취소',
  EXCHANGE_SUB: '교환신청',
  RETURN_SUB: '반품신청',
  DELIVERY_DISABLED: '배송안함',
  SAVE_INVOICE: '저장',
  CANCEL_BTN: '변경',
  DELIVERY_COMPANY_ALL_TXT: '선택하세요',
  PING_TAG: '세트',
  EXCHANGE_FORWARDING: '교환출고',

  // 支付信息
  PAY_TABLE_TITILE: '결제 정보',
  SALE_PRICE: '판매금액',
  FIRST_DELIVERY_AMT: '배송비',
  FIRST_DISCOUNT_AMT: '할인혜택',
  PAY_AMT: '결제금액',
  PAY_ACCUMULATION: '적립금사용',
  REAL_PAY: '실 결제금액',

  // 最终结算信息
  FINEL_PAY_TABLE_TITLE: '최종결제정보',
  EDIT_RETURN_PAY: '환불 금액조정',

  // 订购信息
  ORDER_INFO_TABLE_TITLE: '주문 정보',
  MALL_NAME: '쇼핑몰구분',
  ORDER_REGISTER_TYPE: '주문 구분',
  ORDER_INFO_PAY_TYPE: '결제방법',
  ORDER_PAY_DETAIL_INFO: '결제상세',
  REGINSTER_YMDT: '주문일시',
  ORDER_PAY_YMDT: '결제일시',
  ORDER_PAY_YMDT_SPAN: '입금확인',
  PAY_CASH_STATUS: '현금영수증',
  ORDER_MEMO: '주문메모',
  INSURANCE: '쇼핑몰보증보험',
  REGISTER_TYPE_NORMAL: '일반주문',
  REGISTER_TYPE_ADMIN: '수기주문',
  REGISTER_TYPE_ARIEL: '외부연계주문',
  PREPAID_TEXT: '착불',

  NO: 'No',
  LAYER_MALL_NUM: '상품번호',
  LAYER_PMCD: '상품관리코드',
  LAYER_PN: '상품명',
  LAYER_MON: '옵션번호',
  LAYER_OMCD: '옵션관리코드',
  LAYER_ON_OV: '옵션명 : 옵션값',
  LAYER_COUNT: '수량',

  //其他付款信息
  OTHER_PAY_TITLE: '추가결제 정보 총',
  EXPAND_BTN: '열기/닫기',
  OTHER_PAY_AMT: '결제금액',
  OTHER_REFOUND_PAY_TYPE: '결제방법',
  CONFIRM_DEPOSIT: '결제일시(입금확인)',
  CONFIRM_DEPOSIT_BTN: '입금확인',
  OTHER_PAY_DETAIL: '결제상세',
  OTHER_PAY_BANK: '결제계좌',

  // 退款信息
  RETURN_LIST_TITLE: '환불 정보 총',
  RETRUN_CLAIM_NO: '클레임번호',
  RETURN_REFUND_AMT: '환불금액',
  RETURN_REFUND_PAY_TYPE: '환불방법',
  RETURN_REFUND_AMOUNTS: '환불상세',
  PRODUCT_AMT: '상품금액',
  EXCHANGE_DELIVERY_AMT: '배송비',
  COUPON_DISCOUNT_AMT: '주문쿠폰재계산',
  DELIVERY_AMT: '배송비차액',
  ADJUST_AMT: '환불 금액조정',
  REFUND_BANK_ACCOUNT: '환불계좌',
  BANK_INFO_BTN: '변경',
  CLAIM_COMPLETE_YMDT: '환불처리일시',
  MANAUAL_REFUND_BTN: '환불처리',
  LAYER_POP_RETURN_TYPE: '환불수단',
  LAYER_POP_RETURN_AMT: '금액',
  LAYER_POP_TYPE_TEXT: '포인트',
  LAYER_POP_RETURN_TITLE: '환불 상세',

  // 利益支付历史
  ACCUMULATION_TITLE: '혜택지급내역',
  TOTAL_ACCUMULATION: '적립금',

  // 订货人信息
  MEMBER_TITLE: '주문자 정보',
  MEMBER_NAME: '주문자명',
  MEMBER_CONTACT_1: '휴대전화',
  MEMBER_EMALI: 'e메일 주소',

  // 收件人信息
  RECEIVER_TABLE_TITLE: '수령자 정보 총',
  RECEIVER_EXPAND_BTN: '열기/닫기',
  RECEIVER_INFO_CHANGE_BTN: '수령자명',
  RECEIVER_NAME: '수령자 정보 변경',
  RECEIVER_CONTACT_1: '휴대전화',
  RECEIVER_CONTACT_2: '일반전화',
  CUSTOMS_ID_NUM: '개인통관',
  CUSTOMS_ID_NUM_BR: '고유부호',
  ADDRESS_VIEW: '배송지주소',
  RECEIVER_SHIPPING_NO: '배송번호',
  RECEIVER_SHIPPING_NO_BR: '/ 송장번호',
  RECEIVER_MEMO: '배송메모',

  // task
  TASK_TABLE_TITLE: '업무메시지',
  TASK_WRITE: '업무메시지 입력',
  TASK_MESSAGE_ADD_BTN: '등록',
  TASK_NIFO_LIST: '업무메시지 내역',
  ALL_TASK_MESSAGE_DETAIL_INPUT: '처리내역 전체보기',
  ORDER_PRODUCT_OPTION_NO_ALL_TEXT: '주문상품 선택',
  TASK_CONTENT_PLACEHOLDER: '운영자에게 전달할 사항을 입력해 주세요.',

  // CS处理
  CS_TITLE: 'CS처리',
  CS_WRITE: 'CS처리내역 입력',
  ADD_CS_BTN: '저장',
  CS_HANDLE_LIST: 'CS처리내역',
  CS_SEE_TOGETHER_INPUT: '처리로그 함께보기',
  CS_CONTENT_PLACEHOLDER: '처리내역을 입력해 주세요.',

  CLAIM_STATUS_CANCEL_NO_REFUND: '취소완료\n[환불없음]',
  CLAIM_STATUS_CANCEL_REQUEST: '취소신청\n[승인대기]',
  CLAIM_STATUS_CANCEL_PROC_REQUEST_REFUND: '취소처리\n[환불보류]',
  CLAIM_STATUS_CANCEL_PROC_WAITING_REFUND: '취소처리\n[환불대기]',
  CLAIM_STATUS_CANCEL_DONE: '취소완료\n[환불완료]',
  CLAIM_STATUS_RETURN_REQUEST: '반품신청\n[승인대기]',
  CLAIM_STATUS_RETURN_REJECT_REQUEST: '반품신청\n[철회대기]',
  CLAIM_STATUS_RETURN_PROC_BEFORE_RECEIVE: '반품처리\n[수거진행]',
  CLAIM_STATUS_RETURN_PROC_REQUEST_REFUND: '반품처리\n[환불보류]',
  CLAIM_STATUS_RETURN_PROC_WAITING_REFUND: '반품처리\n[환불대기]',
  CLAIM_STATUS_RETURN_REFUND_AMT_ADJUST_REQUESTED: '반품처리\n[조정요청]',
  CLAIM_STATUS_RETURN_DONE: '반품완료\n[환불완료]',
  CLAIM_STATUS_RETURN_NO_REFUND: '반품완료\n[환불없음]',
  CLAIM_STATUS_EXCHANGE_REQUEST: '교환신청\n[승인대기]',
  CLAIM_STATUS_EXCHANGE_REJECT_REQUEST: '교환처리\n[철회대기]',
  CLAIM_STATUS_EXCHANGE_PROC_BEFORE_RECEIVE: '교환처리\n[수거진행]',
  CLAIM_STATUS_EXCHANGE_PROC_REQUEST_PAY: '교환처리\n[결제대기]',
  CLAIM_STATUS_EXCHANGE_PROC_WAITING: '교환처리\n[처리대기]',
  CLAIM_STATUS_EXCHANGE_PROC_WAITING_PAY: '교환처리\n[입금처리대기]',
  CLAIM_STATUS_EXCHANGE_DONE_PAY_DONE: '교환완료\n[결제완료]',
  CLAIM_STATUS_EXCHANGE_PROC_REQUEST_REFUND: '교환처리\n[환불보류]',
  CLAIM_STATUS_EXCHANGE_PROC_WAITING_REFUND: '교환처리\n[환불대기]',
  CLAIM_STATUS_EXCHANGE_DONE_REFUND_DONE: '교환완료\n[환불완료]',
  CLAIM_STATUS_EXCHANGE_DONE: '교환완료\n[차액없음]',
  CLAIM_STATUS_NONE: '정상주',

  PAY_TYPE_CREDIT_CARD: '신용카드',
  PAY_TYPE_ACCOUNT: '무통장입금',
  PAY_TYPE_MOBILE: '휴대폰결제',
  PAY_TYPE_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체',
  PAY_TYPE_VIRTUAL_ACCOUNT: '가상계좌',
  PAY_TYPE_GIFT: '상품권',
  PAY_TYPE_ATM: 'ATM',
  PAY_TYPE_PAYCO: 'PAYCO',
  PAY_TYPE_ZERO_PAY: '0원결제',
  PAY_TYPE_ACCUMULATION: '적립금 전액 사용',
  PAY_TYPE_PHONE_BILL: '전화결제',
  PAY_TYPE_POINT: '포인트결제',
  PAY_TYPE_YPAY: '옐로페이',
  PAY_TYPE_KPAY: '케이페이',
  PAY_TYPE_PAYPIN: '페이핀',
  PAY_TYPE_INIPAY: 'INIPay 간편결제',
  PAY_TYPE_PAYPAL: 'PAYPAL',
  PAY_TYPE_STRIPE: 'STRIPE',
  PAY_TYPE_NAVER_PAY: '네이버페이 주문형',
  PAY_TYPE_KAKAO_PAY: '카카오페이',
  PAY_TYPE_NAVER_EASY_PAY: '네이버페이 결제형',
  PAY_TYPE_ESCROW_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체-에스크로',
  PAY_TYPE_ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  PAY_TYPE_VERITRANS_CARD: 'Veritrans CreditCard',
  PAY_TYPE_TOASTCAM: '토스트캠',
  PAY_TYPE_ETC: '기타결제수단',

  ALERT_1: '택배사를 선택하세요.',
  ALERT_2: '송장번호를 입력하세요.',
  ALERT_3: '저장되었습니다.',
  ALERT_4: '택배사 홈페이지를 통해 확인해주세요',
  ALERT_5: '수령자 정보는 송장번호 입력 전 단계에서만 수정이 가능합니다.',
  ALERT_6: '에스크로 결제 건은 배송완료 후 클레임 신청이 가능합니다.',
  ALERT_7: '에스크로 결제 건은 전체취소만 신청이 가능합니다.',
  ALERT_8: '내용을 입력해 주세요.',
  ALERT_9: 'CS를 등록하였습니다.',
  ALERT_10: '주문상품을 선택해 주세요.',
  ALERT_11: '내용을 입력해주세요.',
  ALERT_12: '업무메시지를 등록하였습니다.',
  ALERT_13: '에스크로 결제 건은 교환 신청이 불가합니다. 취소/반품 후 재주문으로 처리해 주세요.',
  ALERT_14: '에스크로 결제 건은 모든 상품 구매확정 후 반품신청이 가능합니다.',
  ALERT_15: '에스크로 결제 건은 전체취소만 신청이 가능합니다.',
  ALERT_17: '임시 비밀번호는 {%n} 입니다.',
  ALERT_INSERT_NAME: '주문자명을 입력하세요.',
  ALERT_INSERT_MOBILE: '휴대폰번호를 입력해주세요.',
  ALERT_INSERT_EMAIL: '이메일을 입력해주세요.',
  CHANGED_ORDER_STATUS_ALERT: '주문상품의 상태가 변경되었습니다.\n확인 후 다시 시도해 주세요.',

  DELETED_USER: '삭제된 운영자',

  CONFIRM_1: '배송보류 주문 건이 포함되어 있습니다.\n' + '클레임 처리 하시겠습니까?',
  CONFIRM_2: '예약주문 건이 포함되어 있습니다.\n클레임 처리 하시겠습니까?',
  CONFIRM_3: '에스크로 결제 건은 전체 반품 신청만 가능합니다. 모두 반품 신청 하시겠습니까?',
  CONFIRM_4: '임시비밀번호를 발급 하시겠습니까?',

  CS_PATH_IN_BOUND: '인바운드',
  CS_PATH_OUT_BOUND: '아웃바운드',
  CS_PATH_ETC: '기타',

  TASK_MESSAGE_STATUS_TYPE_PROCESSING: '처리중',
  TASK_MESSAGE_STATUS_TYPE_DONE: '처리완료',

  TASK_MESSAGE_TYPE_PAY: '결제(입금)',
  TASK_MESSAGE_TYPE_PRODUCT: '주문상품',
  TASK_MESSAGE_TYPE_DELIVERY: '배송',
  TASK_MESSAGE_TYPE_CLAIM: '취소/교환/반품',
  TASK_MESSAGE_TYPE_REFUND: '환불',
  TASK_MESSAGE_TYPE_ETC: '기타',

  MESSAGE_TARGET_TYPE_PARTNER: '파트너 담당자',
  MESSAGE_TARGET_TYPE_SERVICE: '서비스 담당자',

  CHANGE_APY_BANK_TITLE: '입금계좌변경',
  CHANGE_REFUND_BANK_TITILE: '환불계좌변경',
  RECEIVER_INFO_CHANGE_TITLE: '수령자정보 변경',
  MEMBER_PUPOP_TITLE: '회원정보',
  BENEFIT_TITLE: '적립금 상세',
  FIRST_DISCOUNT_TITLE: '주문할인 상세',
  LAST_DISCOUNT_TITLE: '주문할인 상세',
  FIRST_AMT_DETAIL_TITLE: '배송비 상세',
  LAST_AMT_DETAIL_TITLE: '배송비 상세',
  TITLE_1: '반품신청',
  TITLE_2: '교환신청',
  TITLE_3: '상세보기',
  TITLE_4: '취소신청',
  TITLE_5: '입금전 취소신청',
  TITLE_6: '취소신청',

  // Benefit
  BENEFIT_PAGE_TITLE: '적립혜택상세',
  BENEFIT_NAME: '상품명',
  BENEFIT_ACCUMULATION_AMT: '적립금',
  BENEFIT_TOTAL_ACCUMULATION: '합계',
  CLOSE_PUPOP: '닫기',

  // DeliveryFirstAmtDetail
  DELIVERY_AMT_DETAIL_PAGE_TITLE: '배송비 상세',
  BASE_DELIVERY_AMT: '기본배송비합계',
  AREA_DELIVERY_AMT: '지역별추가배송비합계',
  IS_LAST_TEXT: '반품/교환(재발송)배송비 합계',
  TOTAL_DELIVERY_AMT: '배송비합계',

  // OrderFirstDiscount
  DISCOUNT_PAGE_TITLE: '할인혜택 상세',
  PRODUCT_NAME: '상품명',
  ADDTIONAL_DISCOUNT: '상품추가할인',
  PRODUCT_COUPON: '상품쿠폰할인',
  TOTAL_DISCOUNT: '주문쿠폰할인',
  COUPON_NUMS_POPUP: '쿠폰번호',
  DISCOUNT_NUMS_POPUP: '추가할인번호',
  CART_DISCOUNT_POPUP: '장바구니쿠폰번호',
  PRODUCT_COUPON_TITLE: '상품쿠폰할인 상세',
  DISCOUNT_TITLE: '상품추가할인 상세',
  CART_DISCOUNT_TITLE: '장바구니 쿠폰 상세',

  // OrderShipping
  RECEIVERNAME: '수령자명',
  RECEIVERCONTACT1: '휴대전화',
  RECEIVERCONTACT2: '일반전화',
  CUSTOMSIDNUMBER: '개인통관고유부호',
  ADDRESS: '배송 주소',
  DELIVERYMEMO: '배송메시지',
  HIGHLIGHT: '필수',
  SEARCHADDRESSBTN: '우편번호 찾기',
  MODIFYBTN: '저장',
  CANCELBTN: '취소',
  VALIDATION_NAME: '수령자명을 입력하세요',
  VALIDATION_CONTACT1: '휴대폰번호를 입력하세요',
  VALIDATION_ADDRESS: '주소를 정확하게 입력하세요.',
  VALIDATION_CUSTOMS_ID_NUMBER: '개인통관고유번호를 입력하세요.',
  ALERT_SAVE: '변경사항이 저장되었습니다.',
  BTN_ZIP_CODE_SEARCH: '우편번호 찾기',

  // OrderSpecificationPopup
  SPECIFICATION_PRINT_BTN: '출력',
  SPECIFICATION_PREV: '이전',
  SPECIFICATION_NEXT: '다음',
  SPECIFICATION_NOTICE_HEAD:
    '거래명세서는 개인정보에 유의하여 관리해주시고, 불필요한 거래명세서는 파기해주세요.거래명세서로 인해 발생되는 책임은 해당 업체에 있습니다.',
  SPECIFICATION_PAGE_TIT: '거래명세서',
  SPECIFICATION_THRILL_ONE: '공',
  SPECIFICATION_THRILL_TWO: '급',
  SPECIFICATION_THRILL_THREE: '자',
  SPECIFICATION_FORM_ONE: '사업자등록번호',
  SPECIFICATION_JUSTIFY_ONE: '회 사 명',
  SPECIFICATION_JUSTIFY_TWO: '대 표 자',
  SPECIFICATION_JUSTIFY_THREE: '주 소',
  SPECIFICATION_JUSTIFY_FOUR: '업 태',
  SPECIFICATION_JUSTIFY_FIVE: '종 목',
  SPECIFICATION_JUSTIFY_SIX: '전 화 번 호',
  SPECIFICATION_JUSTIFY_SEVEN: '주 문 메 모',
  SPECIFICATION_2_JUSTIFY_ONE: '작 성 일 자',
  SPECIFICATION_2_THRILL_ONE: '수',
  SPECIFICATION_2_THRILL_TWO: '령',
  SPECIFICATION_2_THRILL_THREE: '자',
  SPECIFICATION_2_THRILL_FOUR: '주',
  SPECIFICATION_2_THRILL_FIVE: '문',
  SPECIFICATION_2_THRILL_SIX: '자',
  SPECIFICATION_2_JUSTIFY_TWO: '배 송 번 호',
  SPECIFICATION_2_JUSTIFY_THREE: '수 령 자 명',
  SPECIFICATION_2_JUSTIFY_FOUR: '주 문 자 명',
  SPECIFICATION_2_JUSTIFY_FIVE_1: '수 령 자',
  SPECIFICATION_2_JUSTIFY_FIVE_2: '주 소',
  SPECIFICATION_CONTACT1: '수령자 연락처1',
  SPECIFICATION_CONTACT2: '수령자 연락처2',
  SPECIFICATION_2_JUSTIFY_SIX: '배 송 메 모',
  SPECIFICATION_TABLE_ONE: 'SEQ.',
  SPECIFICATION_TABLE_TWO: '주문번호',
  SPECIFICATION_TABLE_THREE: '상품코드',
  SPECIFICATION_TABLE_FOUR: '상품명',
  SPECIFICATION_TABLE_FIVE: '단가',
  SPECIFICATION_TABLE_SIX: '수량',
  SPECIFICATION_TABLE_SEVEN: '금액',
  SPECIFICATION_TABLE_NODATA: '==이하여백==',
  SPECIFICATION_TABLE_TOTAL: '합계',
  SPECIFICATION_CANCEL_BTN: '닫기',

  SYNC_FAIL_LIST: '연동실패 내역',
  PRODUCT: '상품',
  FAIL_REASON: '실패 사유',

  SYNC_SUCCESS_MESSAGE: '동기화되었습니다.',
  SYNC_FAIL_MESSAGE: '동기화 실패하였습니다.',

  NOT_PRE_PAID: '착불',
  DIVIDE: '나눔',
  COMBINE: '묶음',
  TYPE: '유형',
  SELECT: '선택',
  CONTENT: '내용',
  STATUS: '상태',
  NO_MESSAGE: '등록된 업무메시지 내역이 없습니다.',
  OPEN: '펼치기',
  CLOSE: '접기',
  EDIT: '수정',

  CONFIRM_TO_ISSUE_TEMPORARY_PASSWORD: '임시비밀번호를 발급 하시겠습니까? ',
  ALERT_TEMPORARY_PASSWORD: '임시 비밀번호는 {temporaryPassword} 입니다.',
  BUTTON_TEMPORARY_PASSWORD: '임시 비밀번호 발급',
});
