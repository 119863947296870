





























































































import { Component, Vue } from 'vue-property-decorator';
import { validatePassword, ValidatePasswordState } from '@/utils/validate';

@Component
export default class AuthChangePassword extends Vue {
  private key = '';
  private email = '';

  private password = '';
  private passwordConfirm = '';

  private passwordFailMessage = '';
  private passwordConfirmFailMessage = '';

  created() {
    if (this.$route.query && this.$route.query.key) {
      this.key = this.$route.query.key as string;
    }

    if (this.$route.query && this.$route.query.email) {
      this.email = this.$route.query.email as string;
    }
  }

  mounted() {
    this.getAdminsPasswordKeyValidate();
  }

  private getAdminsPasswordKeyValidate(): void {
    this.$api
      .getAdminsPasswordKeyValidate({ params: { key: this.key } })
      .then(response => {
        if (!response || response.status !== 204) {
          this.key = '';
        }
      })
      .catch(() => {
        this.key = '';
      });
  }

  private onInputPassword({ target }) {
    const { type } = target.dataset;
    const regex = /[\s]/g;
    this[type] = target.value.replace(regex, '').trim();
    this[`${type}FailMessage`] = '';
  }

  private putAdminsPasswordByKey(): void {
    this.$api
      .putAdminsPasswordByKey({
        data: {
          key: this.key,
          newPassword: this.password,
          adminType: 'SERVICE',
          email: this.email,
        },
      })
      .then(response => {
        if (response?.status === 204) {
          alert(this.$t('AUTH.PASSWORD_CHANGE_COMPLETE'));
          this.$router.push({ name: 'AuthMain' });
        }
      })
      .catch(error => {
        // e.data.code: P0020 => 이전 비밀번호는 다시 사용 할 수 없다.
        if (error.data.code === 'P0020') {
          alert(this.$t('AUTH.CAN_NOT_USE_OLD_PASSWORD') as string);
        }
      });
  }

  private validatePassword(): void {
    const validate = {
      table: [
        { status: ValidatePasswordState.LESS_THEN_8, txt: 'ALERT_INVALID_PASSWORD_LEAST_2' },
        { status: ValidatePasswordState.LESS_THEN_10_AT_LEAST_2, txt: 'ALERT_INVALID_PASSWORD_LEAST_2' },
        { status: ValidatePasswordState.MORE_THEN_10_AT_LEAST_1, txt: 'ALERT_INVALID_PASSWORD_LEAST_1' },
        { status: ValidatePasswordState.NOT_ALLOW_SPECIAL, txt: 'ALERT_INVALID_PASSWORD_SPECIAL' },
      ],
      getTxt(value: number): string {
        return this.table.find(data => data.status === value)?.txt;
      },
    };

    const result = validate.getTxt(validatePassword(this.password));
    this.passwordFailMessage = this.$t(result) as string;
  }

  private validatePasswordConfirm(): boolean {
    if (this.password !== this.passwordConfirm) {
      this.passwordConfirmFailMessage = this.$t('AUTH.CONFIRM_PASSWORD_DESC') as string;
      return false;
    }
    return true;
  }

  private changePassword(): void {
    if (!this.password) {
      alert(`${this.$t('AUTH.NEW_PASSWORD')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    if (!this.passwordConfirm) {
      alert(`${this.$t('AUTH.NEW_PASSWORD_CONFIRM')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    if (this.passwordFailMessage) {
      alert(this.passwordFailMessage);
      return;
    }

    if (!this.validatePasswordConfirm()) {
      alert(this.passwordConfirmFailMessage);
      return;
    }

    this.putAdminsPasswordByKey();
  }
}
