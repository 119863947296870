import { GetPartnersRequest } from 'ncp-api-supporter';
import { PARTNER_KEYWORD_TYPE, PARTNER_PERIOD_TYPE, PARTNER_STATUS, REGISTERED_PRODUCT } from '@/const/partner';
import { PartnerStatusType, PartnerRegisteredProductType } from '@/types/partner';
import { addMonth, getToday } from '@/utils/dateFormat';

type PartnerSearchForm = Omit<GetPartnersRequest['params'], 'partnerStatus' | 'productRegistered'> & {
  partnerStatus: PartnerStatusType;
  productRegistered: PartnerRegisteredProductType;
};

const DEFAULT_MONTH_RANGE = -3;
const today = getToday();
const defaultStartDate = addMonth(today, DEFAULT_MONTH_RANGE);

export const getDefaultPartnerSearchForm = (): PartnerSearchForm => ({
  // 검색어
  searchType: PARTNER_KEYWORD_TYPE.ALL,
  keyword: '',
  // 거래쇼핑몰
  mallNo: undefined,
  // 파트너 상태
  partnerStatus: PARTNER_STATUS.ALL,
  // 등록일시
  startDateTime: defaultStartDate,
  endDateTime: today,
  // 담당MD 운영자명
  chargeMdNo: 0,
  // 상품등록
  productRegistered: REGISTERED_PRODUCT.ALL,
  // 계약일시 일정타입
  periodType: PARTNER_PERIOD_TYPE.CREATED,
  page: 1,
  pageSize: 30,
});
