


































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolTipOption } from '@/types/tooltip';
import { numberWithUnitFormatter } from '@/utils/numberFormat';
import NoticeBox from '@/components/common/NoticeBox.vue';

const TYPE = {
  NaverPay: 'NaverPay',
  ImmediateDiscountInfo: 'ImmediateDiscountInfo',
  NaverShoppingEP: 'NaverShoppingEP',
} as const;

@Component({
  components: { NoticeBox },
  methods: {
    numberWithUnitFormatter,
  },
  computed: {
    isNaverPay(): boolean {
      return this.option.type === TYPE.NaverPay;
    },
    isImmediateDiscountInfo(): boolean {
      return this.option.type === TYPE.ImmediateDiscountInfo;
    },
    isNaverShoppingEP(): boolean {
      return this.option.type === TYPE.NaverShoppingEP;
    },
    getWidth(): string {
      return this.isNaverShoppingEP ? '700px' : '550px';
    },
  },
})
export default class ProductAdd extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption<keyof typeof TYPE>;

  private NAVER_PAY_CENTER_URL = 'https://admin.pay.naver.com';
  private NAVER_PAY_UNTREAT_INTRODUCTION_URL = 'https://admin.pay.naver.com/introduction/untreated';
}
