

















































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import BasicInfo from '@/views/contents/product/basic/ProductAdd/basicInfo/BasicInfo.vue';
import RelatedProduct from '@/views/contents/product/basic/ProductAdd/basicInfo/details/RelatedProduct.vue';
import SalesInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/SalesInfo.vue';
import ImagesInfo from '@/views/contents/product/basic/ProductAdd/imagesInfo/ImagesInfo.vue';
import DeliveryInfo from '@/views/contents/product/basic/ProductAdd/deliveryInfo/DeliveryInfo.vue';
import NaverShoppingEPInfo from '@/views/contents/product/basic/ProductAdd/naverShoppingEPInfo/NaverShoppingEPInfo.vue';
import { PRODUCT_REGISTRATION_TYPE } from '@/views/contents/product/basic/ProductRegistration.vue';
import {
  defaultBasicInfo,
  defaultImagesInfo,
  defaultDeliveryInfo,
  defaultNaverShoppingEPInfo,
  defaultSalesInfo,
  navBtnClickEvent,
  getNavBtns,
  defaultExtraInfo,
  INITIAL_DIGIT,
  defaultRelatedProductInfo,
  today,
  threeMonths,
  getDefaultReservationInfo,
  getDefaultSalePeriodInfo,
  immutableFields,
  getDefaultMarketingSettingInfo,
} from '@/views/contents/product/basic/ProductAdd';
import {
  BasicInformation,
  SalesInformation,
  ImagesInformation,
  DeliveryInformation,
  NaverShoppingEPInformation,
  ProductAddButtonType,
  VerifyProductInfoResult,
  BasicInformationModel,
  SalesInformationModel,
  RelatedProductInformation,
  ImageInformationModel,
  EditFlag,
  DeliveryInformationModel,
  MaxPurchaseType,
  MaxBuyCountInfoType,
} from '@/types';
import {
  NCPResponse,
  StandardCategoriesTree,
  GetProduct,
  ProductType,
  YorN,
  ApplyStatusType,
  MarketingSettings,
} from 'ncp-api-supporter';
import { ProductRegistrationType } from '@/components/PageTitle.vue';
import { pick, unescapeHtml } from '@/utils/common';
import { APPLY_STATUS, SALE_STATUS } from '@/const/common';
import { adminStore } from '@/components/board/Inquiries/SearchQuery';
import {
  CONFIG_TYPE,
  CONTENT_TYPE,
  DELETE_ERROR,
  IMMUTABLE_TYPE,
  MARKETING_TYPE,
  PRODUCT_FORM_ERROR_TYPE,
  SORT_CRITERION,
} from '@/const/contents/product';
import { CommissionType } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import MarketingRegistration from '@/views/contents/product/basic/ProductAdd/marketingRegistration/MarketingRegistration.vue';
import { Getter } from 'vuex-class';
const MANAGEMENT_TYPE = {
  edit: 'edit',
  copy: 'copy',
} as const;

export type QueryType = { mallProductNo: string; type: ProductRegistrationType };
export type SalesInfoRefModel = Pick<BasicInformation, 'mallNo' | 'categoryNo' | 'productName' | 'saleMethodType'> &
  Pick<DeliveryInformation, 'deliveryTemplateNo'> & {
    productNo: number;
    standardCategoryCommission: number;
    isSelectedMall: boolean;
  };
export type ProductManagementMode = {
  isMapping: boolean;
  isEdit: boolean;
  isCopy: boolean;
  isCopyWithStockMode: boolean;
  isNotOriginMall: boolean;
};

@Component({
  components: {
    BasicInfo,
    RelatedProduct,
    SalesInfo,
    ImagesInfo,
    DeliveryInfo,
    MarketingRegistration,
    NaverShoppingEPInfo,
  },
})
export default class ProductAdd extends Vue {
  @adminStore.Getter('getServiceNo')
  private readonly serviceNo: number;

  @Getter('partner/getPartnerNo')
  private readonly selectedPartnerNo: number;

  private isForAdults = false;
  // 최대 구매 수량 사용시 어떤 폼을 선택했는지
  private maxPurchaseType: MaxPurchaseType = 'maxBuyTimeCnt';

  private get isDisplayableNaverShopping(): boolean {
    if (!this.marketingSettingInfos?.length) return false;

    return (
      this.marketingSettingInfos.find(({ channelType }) => channelType === MARKETING_TYPE.NAVER_SHOPPING)
        ?.displayable ?? false
    );
  }

  private get productNo(): number {
    const productNo = this.isCopyMode ? Number(this.$route.query.parentNo) : Number(this.$route.query.mallProductNo);
    return isNaN(productNo) ? 0 : productNo;
  }

  private get path(): string {
    return this.$route.path;
  }

  private get isEditMode(): boolean {
    return this.path.includes(MANAGEMENT_TYPE.edit);
  }

  private get isCopyMode(): boolean {
    return this.path.includes(MANAGEMENT_TYPE.copy);
  }
  private get isCopyWithStockMode(): boolean {
    return this.isCopyMode && this.$route.query?.copyStockYn === 'Y';
  }
  private get isCopySyncStockMode(): boolean {
    return this.isCopyMode && this.$route.query?.synced === 'Y';
  }
  private get isCopyProhibitionMode(): boolean {
    return this.isCopyMode && this.editFlag.isProhibited;
  }
  private get isNotOriginMall(): boolean {
    // 복사 시 몰 변경 isNotOriginMall = true;
    return this.isCopyMode && this.$route.query?.originMallYn !== 'Y';
  }

  private get isDeepSea(): boolean {
    return this.basicInfoRef?.isDeepSea;
  }

  private resetCategoryData() {
    this.salesInfoRef.resetAllOriginCountry();
    this.salesInfoRef.resetCertification();
    this.basicInfoRef.resetMinorPurchase();
    this.isForAdults = false;
  }

  private get mode(): ProductManagementMode {
    return {
      isMapping: this.$route.query?.type === PRODUCT_REGISTRATION_TYPE.MAPPING,
      isEdit: this.isEditMode,
      isNotOriginMall: this.isNotOriginMall,
      isCopy: this.isCopyMode,
      isCopyWithStockMode: this.isCopyWithStockMode,
    };
  }

  private get isSelectedMall(): boolean {
    return this.basicInfo.mallNo > 0;
  }

  private async fetchPartnerNo() {
    if (this.$route.path.includes('edit') || this.$route.path.includes('copy')) return;
    const { data } = await this.$api.getServicesByServiceNoDeliveryPartner({
      params: {
        serviceNo: this.serviceNo,
      },
    });
    this.basicInfo.partnerNo = data.partnerNo;
  }

  @Watch('selectedPartnerNo')
  private changePartnerNo() {
    this.basicInfo.partnerNo = this.selectedPartnerNo;
  }

  // 기본정보
  // basic-info
  @Ref()
  private readonly basicInfoRef: BasicInfo;

  private basicInfo: BasicInformation = defaultBasicInfo();

  private get showRelatedProduct(): boolean {
    return this.relatedProductInfo.relatedProductInfo.configType === CONFIG_TYPE.SELECTED;
  }

  // TODO refactor
  private alertMallSelect() {
    this.verifyResultHandler({
      success: false,
      fail: { type: 'basic', name: 'mall', msg: 'PRODUCT.ADD.PLZ_SELECT_SHOPPING_MALL' },
    });
  }

  // TODO refactor
  private alertProductName() {
    this.verifyResultHandler({
      success: false,
      fail: { type: 'basic', name: 'productName', msg: 'PRODUCT.ADD.PLZ_INSERT_PRODUCT_NAME' },
    });
  }

  private resetBasicInformation(): void {
    const defaultMemberGradeDisplayInfo = { check: 'NONE', info: [] };
    const defaultDisplayOrder = {
      type: 'AUTO',
      automaticDisplayType: SORT_CRITERION.LATEST_REGISTER_DATE,
    };

    this.$set(this.basicInfo, 'adminNo', '');
    this.$set(this.basicInfo, 'displayCategoryNos', []);
    this.$set(this.basicInfo, 'memberGradeDisplayInfo', defaultMemberGradeDisplayInfo);
    this.$set(this.basicInfo, 'displayOrder', defaultDisplayOrder);
    this.$set(this.basicInfo, 'categoryNo', '');
    this.$set(this.basicInfo, 'brandName', '');
    this.$set(this.basicInfoModel, 'categoryNo', [INITIAL_DIGIT]);
    this.$set(this.basicInfoModel, 'memberGrades', '');
  }

  // 관련상품
  // related-product-info
  @Ref()
  private readonly relatedProductInfoRef: RelatedProduct;
  private relatedProductInfo: { relatedProductInfo: RelatedProductInformation } = defaultRelatedProductInfo();
  private setFinalRelatedProductsInfo() {
    this.relatedProductInfoRef?.setFinalRelatedProducts();
  }

  private resetRelatedProducts() {
    this.relatedProductInfo = defaultRelatedProductInfo();
  }

  // 판매정보
  // sales-info
  @Ref()
  private readonly salesInfoRef: SalesInfo;

  private salesInfo: SalesInformation = defaultSalesInfo(false);

  private get salesInfoRefModel(): SalesInfoRefModel {
    const { mallNo, categoryNo, productName, saleMethodType } = this.basicInfo;
    return {
      mallNo,
      isSelectedMall: mallNo > 0,
      categoryNo,
      productNo: this.productNo,
      deliveryTemplateNo: this.deliveryInfo.deliveryTemplateNo,
      productName,
      saleMethodType,
      standardCategoryCommission: this.standardCategoryCommission,
    };
  }

  private getFilteredCategory(categories: StandardCategoriesTree[]): StandardCategoriesTree {
    return categories.filter(category => category.categoryNo === this.basicInfo.categoryNo)[0];
  }

  private getSelectedStandardCategory(): StandardCategoriesTree {
    const filtered = this.getFilteredCategory(this.standardCategoriesCache);
    return filtered ? filtered : this.getFilteredCategory(this.standardCategoriesCache.flatMap(tree => tree.child));
  }

  private get standardCategoryCommission(): number {
    const category = this.getSelectedStandardCategory();
    return category && category.commissionRate ? category.commissionRate : 0;
  }

  private standardCategoriesCache: StandardCategoriesTree[] = [];
  private async fetchStandardCategories(mallNo: number): Promise<void> {
    const { data } = await this.$api.getStandardCategoriesTree({
      params: { mallNo: mallNo.toString() },
    });

    this.standardCategoriesCache = data.tree.flatMap(tree => tree.child).flatMap(childTree => childTree.child);
  }

  private resetSalesInformation(): void {
    this.salesInfoRef.reset();
  }

  private setFinalSalesInfo(): void {
    if (this.salesInfo.buyInfo.min === 'N') {
      this.salesInfo.minBuyCnt = 0;
    }

    this.salesInfoRef.setFinalSalesInfo();
  }

  private focusOnStandardCategory(): void {
    this.basicInfoRef.onFocus('category');
  }

  private alertDeliveryTemplate() {
    this.deliveryInfoRef.alertDeliveryTemplate();
  }

  // 이미지정보
  // images-info
  @Ref()
  private readonly imagesInfoRef: ImagesInfo;
  private imagesInfo: ImagesInformation = defaultImagesInfo();

  private resetImagesInformation(): void {
    this.imagesInfo.productGuides = [];
    this.imagesInfo.guideUseStatus = {};
    this.imagesInfoModel.productGuides = null;
    this.imagesInfoRef.resetImagesInfo();
  }

  private setFinalImagesInfo(): void {
    this.imagesInfoRef.setFinalImagesInfo();
  }

  private imagesInfoModel: ImageInformationModel = {
    contents: null,
    productGuides: null,
  };

  // 배송정보
  // delivery-info
  @Ref()
  private readonly deliveryInfoRef: DeliveryInfo;

  private deliveryInfo: DeliveryInformation = defaultDeliveryInfo();

  private resetDeliveryInformation(): void {
    this.deliveryInfo = defaultDeliveryInfo();
  }

  private deliveryInfoModel: DeliveryInformationModel = {
    deliveryTemplateNo: null,
  };
  private naverShoppingEPInfo: NaverShoppingEPInformation = defaultNaverShoppingEPInfo();

  private resetNaverShoppingEPInformation(): void {
    this.naverShoppingEPInfo = defaultNaverShoppingEPInfo();
  }

  //마케팅 정보
  @Ref()
  private readonly marketingSettingInfoRef: MarketingRegistration;

  private marketingSettingInfos: MarketingSettings[] = getDefaultMarketingSettingInfo();

  /*
   * 몰 변경 시 초기화 항목
   * 전시카테고리, 담당자, 회원등급, 관련상품, 아이콘, 안내템플릿, 배송비템플릿
   * 표준카테고리, 브랜드
   * */
  private changedMall(mallNo: number) {
    this.$nextTick(() => {
      this.resetBasicInformation();
      this.resetRelatedProducts();
      this.resetSalesInformation();
      this.resetImagesInformation();
      this.resetDeliveryInformation();
      this.resetNaverShoppingEPInformation();
      this.fetchPartnerNo();
      this.fetchStandardCategories(mallNo);
    });
  }

  private created() {
    this.init();
  }

  private async init() {
    this.generateQueryStringByProductTypeForLnb();
    this.setProductDefaultInfo();
    await this.fetchProductInfo();
    await this.fetchPartnerNo();
    await this.fetchImmutableFields();
    this.setBottomNavigator();

    if (this.$route.query.mallNo) {
      this.basicInfo.mallNo = Number(this.$route.query.mallNo);
    }
  }

  private generateQueryStringByProductTypeForLnb(
    mallProductNo = 0,
    productType: ProductType = null,
    synced = false,
    masterNo = -1,
  ) {
    if (this.$route.name === 'ProductCopy') return;
    const productNo = mallProductNo === 0 ? this.$route.query.mallProductNo : mallProductNo;
    const parentNo = masterNo === -1 ? Number(this.$route.query?.parentNo) : masterNo;
    const type = productType === null ? this.$route.query.type : productType;

    this.$router
      .replace({
        query: {
          mallProductNo: productNo?.toString(),
          parentNo: isNaN(parentNo) ? '0' : parentNo.toString(),
          type,
          synced: synced ? 'Y' : 'N',
        },
      })
      .catch(() => null);
  }

  private setProductDefaultInfo() {
    this.salesInfo = defaultSalesInfo(this.editFlag.isMapping);
  }

  private editFlag: EditFlag = {
    isMapping: false, // 세트상품
    isReady: true, //승인완료 아닌 단계
    isApproved: false, // 승인완료
    isEnded: false, // 판매종료
    isMaster: true,
    isSynced: false, // 재고연동 여부 (slave)
    isSlave: false,
    isProhibited: false,
  };

  private setEditFlags({ productType, applyStatusType, saleStatusType, parentNo, synced, saleSettingStatusType }) {
    if (!this.isEditMode && !this.isCopyMode) return;
    this.editFlag.isMapping = productType === PRODUCT_REGISTRATION_TYPE.MAPPING;
    this.editFlag.isReady = this.isBeforeApproveState(applyStatusType);
    this.editFlag.isApproved = applyStatusType === APPLY_STATUS.FINISHED;
    this.editFlag.isEnded = saleStatusType === SALE_STATUS.END_SALE;
    this.editFlag.isMaster = !parentNo && synced;
    this.editFlag.isSynced = this.isCopyMode ? this.isCopyWithStockMode : synced;
    this.editFlag.isSlave = this.isCopyMode ? this.isCopyWithStockMode : parentNo > 0 && synced; // slave
    this.editFlag.isProhibited = this.isEditMode && saleSettingStatusType === 'PROHIBITION_SALE';
  }

  private isBeforeApproveState(applyStatusType: ApplyStatusType): boolean {
    //등록대기, 승인대기, 승인거부, 판매합의대기, 판매합의거부
    const notApprovedStates: ApplyStatusType[] = [
      APPLY_STATUS.REGISTRATION_READY,
      APPLY_STATUS.APPROVAL_READY,
      APPLY_STATUS.APPROVAL_REJECTION,
      APPLY_STATUS.SALE_AGREEMENT_READY,
      APPLY_STATUS.SALE_AGREEMENT_REJECTION,
    ];
    return notApprovedStates.includes(applyStatusType);
  }

  private productInfoCache = {} as GetProduct;
  // 수정/복사 시 상품번호로 상품정보 조회
  private async fetchProductInfo() {
    if (this.productNo === 0) return;

    try {
      const { data }: NCPResponse<GetProduct> = await this.$api.getProductByNo({
        params: { mallProductNo: this.productNo },
      });
      this.tempSave = data.saleSettingStatusType !== 'PROHIBITION_SALE';
      this.generateQueryStringByProductTypeForLnb(
        data.mallProductNo,
        data.productType as ProductType,
        data.synced,
        data.parentNo,
      );
      this.setEditFlags(data);
      this.productInfoCache = Object.assign({}, this.productInfoCache, data);

      await this.mapProductInfoByInfoType();
    } catch (error) {
      error?.data?.result?.message && alert(error.data.result.message);
    }
  }

  private extraInfo = defaultExtraInfo();
  private async mapProductInfoByInfoType() {
    const infoKeys = ['basic', 'relatedProduct', 'sales', 'images', 'delivery', 'naverShoppingEP', 'extra'];
    const setProduct = key => {
      const infoKey = `${key}Info`;
      const prevInfo = this[infoKey];
      const pickedInfo = pick<GetProduct, BasicInformation>(this.productInfoCache, Object.keys(prevInfo));
      this[infoKey] = {
        ...prevInfo,
        ...pickedInfo,
      };
    };
    await infoKeys.forEach(setProduct);
    this.initializeProductEditInfo();
  }

  /*
   * post/put products requestBody 와 화면에 출력되는 값의 형태가 다른 경우 @@@Model 객체로 관리
   * @@@Model = 화면 출력을 위한 값
   * @@@Info = requestBody 로 사용하기 위한 값
   * */
  private basicInfoModel: BasicInformationModel = {
    productName: '',
    productDesc: '',
    categoryNo: [INITIAL_DIGIT],
    keywords: '',
    memberGrades: '',
  };

  private salesInfoModel: SalesInformationModel = {
    isOverReservationSale: false,
    immediateRate: 0,
    supplyPrice: 0,
    defaultCommissionType: null,
    defaultCommissionRate: null,
    hasOptionImage: false,
    optionUseYn: 'N',
    useOption: false,
    useTextOption: false,
  };

  private initializeMemberGrades(info?: number[]) {
    if (this.$route.query.originMallYn === 'N') {
      this.basicInfo.memberGradeDisplayInfo.info = [];
      this.basicInfo.memberGradeDisplayInfo.check = 'NONE';
      this.basicInfoModel.memberGrades = '';
      return;
    }
    const infos: number[] = info?.length > 0 ? info : this.productInfoCache.memberGradeDisplayInfo.info;
    this.basicInfoModel.memberGrades = infos.join(',');
  }
  private setBasicInfoBy({
    productName,
    promotionTextInfo,
    categoryNo,
    keywords,
    displayCategoryNos,
    shortenUrl,
    extraJson,
    partnerNo,
  }: GetProduct) {
    this.basicInfo.mallNo =
      this.mode.isNotOriginMall || this.isCopyProhibitionMode
        ? Number(this.$route.query.mallNo)
        : this.productInfoCache.mallNo;
    this.basicInfo.partnerNo = partnerNo;
    this.basicInfo.adminNo =
      this.mode.isNotOriginMall || this.isCopyProhibitionMode ? '' : this.productInfoCache.adminNo;
    this.basicInfo.displayCategoryNos =
      this.mode.isNotOriginMall || this.isCopyProhibitionMode ? [] : displayCategoryNos;
    this.basicInfo.productDescInfo.startYmd = promotionTextInfo.startYmd ?? today;
    this.basicInfo.productDescInfo.endYmd = promotionTextInfo.endYmd ?? threeMonths;
    this.basicInfo.productDescInfo.text = promotionTextInfo.text ?? '';
    this.basicInfo.productDescInfo.useYn = promotionTextInfo.text ? 'Y' : 'N';
    this.basicInfo.productDescInfo.periodYn = promotionTextInfo.periodYn === 'Y';
    this.basicInfo.urlShorteningYn = shortenUrl ? 'Y' : 'N';

    // 모바일앱 현재 쓰지않으므로 setBasicInfo 할때 N 으로 데이터 보정
    this.basicInfo.platformDisplayInfo.mobileYn = 'N';

    // 'Y' 네이버페이 결제불가
    this.basicInfo.extraJson.naverPayLimitYn = extraJson.naverPayLimitYn === 'Y';

    this.basicInfoModel.productName = unescapeHtml(productName);
    this.basicInfoModel.productDesc = unescapeHtml(promotionTextInfo.text);
    this.basicInfoModel.categoryNo = this.isCopyProhibitionMode ? [INITIAL_DIGIT] : [categoryNo];
    this.basicInfoModel.keywords = keywords
      .map(keyword => keyword.trim())
      .filter(Boolean)
      .join(',');
    this.initializeMemberGrades();

    if (this.mode.isCopy) {
      this.basicInfo.shortenUrl = null;
    }

    if (this.isNotOriginMall || this.isCopyProhibitionMode) {
      this.basicInfo.displayOrder = {
        type: 'AUTO',
        automaticDisplayType: SORT_CRITERION.LATEST_REGISTER_DATE,
      };
      this.relatedProductInfo = defaultRelatedProductInfo();
      this.salesInfo.stickerInfos = [];
    }
  }

  private resetByMallNo() {
    console.log('전시카테고리, 회원등급 노출 설정, 관련상품 설정, 아이콘, 안내들 초기화');
    console.log('담당자도 초기화 시켰음');
  }

  private setSalesInfoBy({ supplierProductName, productStockCnt }: GetProduct) {
    this.fetchStandardCategories(this.productInfoCache.mallNo);
    this.salesInfo.productStockCnt = productStockCnt;
    this.salesInfo.optionUseYn = this.salesInfo.options.length > 0 ? 'Y' : 'N';
    this.salesInfo.reservationInfoModel = { ...this.productInfoCache.reservationInfo };
    this.salesInfo.useReservation = this.productInfoCache.reservationInfo !== null;
    if (this.productInfoCache.dutyContent?.contents.length > 0) {
      this.salesInfo.dutyInfo.dutyContent = this.productInfoCache.dutyContent;
      this.salesInfo.dutyInfo.willDutyContent = this.productInfoCache.willDutyContent;
    }
    this.salesInfo.buyerProductName = supplierProductName;
    this.salesInfo.contentsIfPausing = this.productInfoCache.extraJson.contentsIfPausing;
    this.salesInfo.useContentsIfPausing = !!this.productInfoCache.extraJson.contentsIfPausing;
    this.salesInfo.salePrice = this.productInfoCache.salePrice;
    this.salesInfo.purchasePrice = this.productInfoCache.purchasePrice;
    this.salesInfo.accumulationRate = this.productInfoCache.accumulationRate;
    this.salesInfo.accumulationSaveYn = this.productInfoCache.accumulationRate === null ? 'N' : 'Y';
    this.salesInfo.buyInfo.min = this.salesInfo.minBuyCnt ? 'Y' : 'N';

    this.iterateMaxBuyCountInfo((property: MaxPurchaseType) => {
      if (this.salesInfo.maxBuyCountInfo[property]) {
        this.salesInfo.buyInfo.max = 'Y';
        this.maxPurchaseType = property === 'maxBuyPeriodCnt' ? 'maxBuyDays' : property;
      }
    });

    this.salesInfoModel.defaultCommissionType = this.productInfoCache.commissionInfo.type;
    this.salesInfoModel.defaultCommissionRate = this.productInfoCache.commissionInfo.rate;
    this.salesInfoModel.supplyPrice = this.productInfoCache.purchasePrice;
    this.salesInfoModel.immediateRate = this.productInfoCache.immediateDiscountInfo.amount;
    this.salesInfoRef.initializeSalesInfo();

    this.salesInfoModel.useOption = this.productInfoCache.optionUseYn === 'Y';
    this.salesInfoModel.useTextOption = this.productInfoCache.customerDemands.length > 0;
    this.salesInfoModel.optionUseYn =
      this.productInfoCache.optionUseYn === 'Y' || this.productInfoCache.customerDemands.length > 0 ? 'Y' : 'N';

    // 복사
    if (this.mode.isCopy) {
      this.salesInfo.reservationInfoModel = {
        ...getDefaultReservationInfo(),
        stockCnt: this.salesInfo.reservationInfoModel.stockCnt,
      };

      this.salesInfo.salePeriodInfo = getDefaultSalePeriodInfo(
        this.salesInfo.salePeriodInfo.periodType,
        this.productInfoCache.salePeriodInfo.endYmdt,
      );

      if (this.mode.isNotOriginMall || this.isCopyProhibitionMode) {
        this.resetByMallNo();
      }

      if (!this.mode.isCopyWithStockMode) {
        this.salesInfo.productStockCnt = 0;
        this.salesInfo.reservationInfoModel.stockCnt = 0;
      }
    }
  }

  private setImagesInfoBy({ content, contentHeader, contentFooter, addOptionImageYn, productGuides }: GetProduct) {
    this.imagesInfoModel.contents = {
      [CONTENT_TYPE.HEADER]: contentHeader,
      [CONTENT_TYPE.BODY]: content,
      [CONTENT_TYPE.FOOTER]: contentFooter,
      addOptionImageYn: addOptionImageYn as YorN,
    };
    this.imagesInfoModel.productGuides = this.isNotOriginMall || this.isCopyProhibitionMode ? [] : productGuides;
  }

  // 조회된 상품 값으로 초기화
  private initializeProductEditInfo() {
    this.setBasicInfoBy(this.productInfoCache);
    this.setSalesInfoBy(this.productInfoCache);
    this.setImagesInfoBy(this.productInfoCache);

    this.deliveryInfoModel.deliveryTemplateNo = this.productInfoCache.deliveryTemplateNo;
  }

  private immutableFields = [];

  // 하단 버튼
  private tempSave = false;
  private setBottomNavigator() {
    throwBottomNavigation({
      buttons: getNavBtns(
        this.isCopyMode || this.$route.path.includes('add') || this.tempSave,
        !this.editFlag.isProhibited,
      ),
      onClick: (navType: ProductAddButtonType) => {
        const navigator = navBtnClickEvent();
        switch (navType) {
          case 'tempSave':
            this.tempSave = true;
            break;
          case 'save':
            this.tempSave = false;
            break;
          case 'list':
            this.goToListPage();
            return;
        }
        this.verifyProductInformation(navigator);
      },
    });
  }

  // 저장 전 최종 검증
  private setFinalProductInfo() {
    this.setFinalRelatedProductsInfo();
    this.setFinalSalesInfo();
    this.setFinalImagesInfo();
  }

  private verifyProductInformation(navigator): void {
    this.setFinalProductInfo();

    this.verifyResultHandler(
      navigator.verifyProductInfo({
        basicInfo: this.basicInfo,
        salesInfo: this.salesInfo,
        imagesInfo: this.imagesInfo,
        deliveryInfo: this.deliveryInfo,
        hasOptionImage: this.salesInfoModel.hasOptionImage,
        optionUseYn: this.salesInfoModel.optionUseYn,
        marketingSettingInfo: this.mapMarketingSettingInfoToBodyRequest().marketingSettings,
      }),
    );
  }

  private verifyResultHandler(verifyResult: VerifyProductInfoResult) {
    const { success, fail } = verifyResult;
    if (!success) {
      alert(this.$t(fail.msg).toString());
      this[`${fail.type}InfoRef`].onFocus(fail.name, fail['sub-type']);
      return;
    }
    this.requestHandler();
  }

  // 일반상품등록
  private postProduct(data: any) {
    this.$api
      .postProducts({ data })
      .then(ret => {
        alert(`${ret.data} 저장되었습니다.`);
        this.goToListPage();
      })
      .catch(this.handleError);
  }

  // 일반상품수정
  private putProduct(data: any) {
    this.$api
      .putProductByNo({
        params: {
          mallProductNo: this.basicInfo.mallProductNo,
        },
        data,
      })
      .then(res => {
        alert(`${res.data} 수정되었습니다.`);
        this.goToListPage();
      })
      .catch(this.handleError);
  }

  // 일반상품복사
  private copyProduct(data: any) {
    if (!this.$route.query.synced || this.$route.query.synced === 'N') {
      this.postProduct(data);
      return;
    }
    // 재고연동하여 등록 시
    this.$api
      .postProductsByMallProductNo({
        params: {
          mallProductNo: this.productNo.toString(),
          copyReview: this.$route.query.copyReviewYn === 'Y',
        },
        data,
      })
      .then(res => {
        alert(`${res.data} 저장되었습니다.`);
        this.goToListPage();
      })
      .catch(this.handleError);
  }

  private mapBasicInfoToBodyRequest() {
    const data = { ...this.basicInfo } as any;
    if (this.basicInfo.productDescInfo.useYn === 'N') return data;

    const { useYn, startYmd, endYmd, periodYn, text } = this.basicInfo.productDescInfo;
    data.promotionTextUseYn = useYn;

    if (!periodYn) {
      data.promotionTextInfo = {
        text,
        periodYn: 'N',
      };
    } else {
      data.promotionTextInfo = {
        startYmd,
        endYmd,
        text,
        periodYn: 'Y',
      };
    }
    return data;
  }
  private mapRelatedInfoToBodyRequest() {
    const data = { ...this.relatedProductInfo } as any;
    const { relatedProductInfo } = this.relatedProductInfo;
    if (relatedProductInfo.configType === CONFIG_TYPE.NOT_USED) {
      data.relatedProductInfo = null;
    }
    return data;
  }
  private mapSalesInfoToBodyRequest() {
    const data = { ...this.salesInfo } as any;
    const {
      buyerProductName,
      placeOriginInfo,
      certificationInfo,
      dutyInfo,
      commissionInfo,
      optionUseYn,
      accumulationSaveYn,
      maxBuyCountInfo,
    } = this.salesInfo;

    const newMaxBuyCountInfo: MaxBuyCountInfoType = {};

    if (buyerProductName) {
      data.supplierProductName = buyerProductName;
    }
    if (!placeOriginInfo.placeOriginSeq) {
      data.placeOriginInfo = null;
    }
    if (certificationInfo.type === 'TARGET') {
      data.certificationInfo.data.forEach(({ certificationContents }, idx) => {
        data.certificationInfo.data[idx].certificationContents = certificationContents.filter(Boolean);
      });
    }
    if (dutyInfo.useYn === 'Y') {
      const { saveTitle } = data.dutyInfo;
      data.dutyInfo.saveTitle = saveTitle === '' ? null : saveTitle;
    } else {
      data.dutyInfo.dutyContent = null;
    }

    if (commissionInfo.type === CommissionType.PURCHASE) {
      data.commissionInfo.rate = '';
    }

    if (optionUseYn === 'N') {
      data.options = null;
      data.optionType = 'DEFAULT';
    }

    if (accumulationSaveYn === 'N') {
      data.accumulationRate = null;
    }

    if (this.salesInfo.buyInfo.max === 'Y') {
      this.iterateMaxBuyCountInfo((property: MaxPurchaseType) => {
        if (
          this.maxPurchaseType === 'maxBuyDays' &&
          (Number(maxBuyCountInfo.maxBuyDays) || Number(maxBuyCountInfo.maxBuyPeriodCnt))
        ) {
          newMaxBuyCountInfo.maxBuyDays = maxBuyCountInfo.maxBuyDays;
          newMaxBuyCountInfo.maxBuyPeriodCnt = maxBuyCountInfo.maxBuyPeriodCnt;
        }

        if (this.maxPurchaseType !== 'maxBuyDays' && this.maxPurchaseType === property && maxBuyCountInfo[property]) {
          newMaxBuyCountInfo[property] = maxBuyCountInfo[property];
        }
      });
    }

    data.maxBuyCountInfo = newMaxBuyCountInfo;
    data.immediateDiscountInfo.amount = this.salesInfoModel.immediateRate;
    return data;
  }
  private mapImagesInfoBodyRequest() {
    const data = { ...this.imagesInfo } as any;

    if (this.imagesInfo.mallProductImages.length === 0) {
      data.mallProductImages = null;
    }

    data.productGuides = data.productGuides.filter(Boolean);
    return data;
  }
  private mapDeliveryInfoBodyRequest() {
    return { ...this.deliveryInfo };
  }

  private mapExtraInfoBodyRequest() {
    const data = { ...this.extraInfo } as any;
    const { naverPayLimitYn } = this.basicInfo.extraJson;

    data.extraJson = {
      ...data.extraJson,
      naverPayLimitYn: naverPayLimitYn ? 'Y' : 'N',
      contentsIfPausing: null,
    };

    if (this.salesInfo.useContentsIfPausing) {
      data.extraJson.contentsIfPausing = this.salesInfo.contentsIfPausing;
    }
    return data;
  }

  private mapMarketingSettingInfoToBodyRequest(): { marketingSettings: MarketingSettings[] } {
    const { naverShoppingEP } = this.naverShoppingEPInfo;
    const convertNaverShoppingInfo = Object.fromEntries(
      Object.entries(naverShoppingEP).filter(([_, value]) => !!value),
    );
    const marketingSettings = this.marketingSettingInfos.map(data => {
      const naverShopping = this.marketingSettingInfos.find(
        ({ channelType }) => channelType === MARKETING_TYPE.NAVER_SHOPPING,
      );
      if (naverShopping) naverShopping.additionalInfo = convertNaverShoppingInfo;
      return data;
    });
    return { marketingSettings };
  }

  private generateBodyRequest() {
    const request = {
      data: {
        ...this.mapBasicInfoToBodyRequest(),
        ...this.mapRelatedInfoToBodyRequest(),
        ...this.mapSalesInfoToBodyRequest(),
        ...this.mapImagesInfoBodyRequest(),
        ...this.mapDeliveryInfoBodyRequest(),
        ...this.mapExtraInfoBodyRequest(),
        ...this.mapMarketingSettingInfoToBodyRequest(),
      },
    } as any;
    request.data.tempSave = this.tempSave;
    return request;
  }

  private requestHandler() {
    const request = this.generateBodyRequest();

    if (this.salesInfo.buyInfo.min === 'Y' && !Number(request.data.minBuyCnt)) {
      alert(this.$t('PRODUCT.ADD.PLEASE_INSERT_MIN_COUNT'));
      this.salesInfoRef.onFocus('minPurchase');
      return;
    }

    if (this.salesInfo.buyInfo.max === 'Y' && !Object.keys(request.data.maxBuyCountInfo).length) {
      alert(this.$t('PRODUCT.ADD.PLEASE_INSERT_MAX_COUNT'));
      return;
    }

    if (this.isCopyWithStockMode) {
      this.copyProduct(request.data);
      return;
    }

    if (this.isEditMode) {
      this.putProduct(request.data);
      return;
    }

    this.postProduct(request.data);
  }

  private goToListPage() {
    this.$router.push({ name: 'ProductList' });
  }

  // error
  private formError = {} as { [errorKey: string]: string };
  private formErrorType: typeof PRODUCT_FORM_ERROR_TYPE = PRODUCT_FORM_ERROR_TYPE;
  private deleteError: typeof DELETE_ERROR = DELETE_ERROR;

  /*
   * 인라인 에러메시지 삭제
   * */
  private deleteErrorHandler(fields: string | string[]) {
    if (Array.isArray(fields)) {
      fields.forEach(field => {
        this.$delete(this.formError, field);
      });
    } else {
      this.$delete(this.formError, fields);
    }
  }

  /**
   * { 에러코드: 메시지 } 형태로 변환
   */
  private convertErrorMessage(errorList: { propertyName: string; error: string; message: string }[]) {
    errorList.forEach(({ error, message }) => this.$set(this.formError, error, message));
  }

  private handleError(error) {
    const { status, data } = error;
    if (status === 400) {
      const {
        result: { code, detail, message },
      } = data;
      if (code === 'PRDT0028' || code === 'MAEC0002') {
        // MAEC0002 : 쇼핑몰 당 최대 등록가능한 상품개수를 초과한 경우
        alert(message);
        return;
      }
      alert(this.$t('PRODUCT.ADD.ERROR'));

      if (detail) {
        this.convertErrorMessage(detail);
        const generalErrorFields = [
          PRODUCT_FORM_ERROR_TYPE.INVALID_MALL_PRODUCT_NO,
          PRODUCT_FORM_ERROR_TYPE.INVALID_PARENT_PRODUCT_NO,
          PRODUCT_FORM_ERROR_TYPE.NON_EDITABLE_PRODUCT,
          PRODUCT_FORM_ERROR_TYPE.NON_EDITABLE_MALL_NO,
        ];
        generalErrorFields.forEach(error => {
          if (Object.keys(detail).includes(error)) {
            alert(detail[error]);
          }
        });
      }
    }
  }

  //수정불가한 항목들
  private fetchImmutableFields() {
    this.tempSave = true;

    if (this.$route.path.includes('add')) return;

    if (this.isCopySyncStockMode) {
      this.immutableFields = immutableFields(IMMUTABLE_TYPE.COPY, IMMUTABLE_TYPE.SYNC);
      return;
    }

    this.tempSave = false;
    let immutableType = null;

    if (this.editFlag.isSlave) {
      immutableType = IMMUTABLE_TYPE.SYNC;
    }
    if (this.editFlag.isProhibited) {
      immutableType = IMMUTABLE_TYPE.PROHIBITION_SALE;
    }
    if (this.isEditMode && !immutableType) {
      immutableType = IMMUTABLE_TYPE.UPDATE;
    }

    this.immutableFields = immutableFields(IMMUTABLE_TYPE.UPDATE, immutableType);
  }

  private iterateMaxBuyCountInfo(fn) {
    const purchasingQuantityInfo = this.salesInfo.maxBuyCountInfo;
    Object.keys(purchasingQuantityInfo).forEach(prop => fn(prop));
  }
}
