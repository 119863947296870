















































































import { Component, Ref, Watch } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import Grid from '@/components/common/grid/Main.vue';

import moment from 'moment';
import { CheckBoxGroupOption } from '@/helpers/type';
import { throwMessagePopup } from '@/helpers/popup';
import { writeExcelFile } from '@/utils/webExcel';
import {
  CheckboxCouponCodeStatusTypes,
  CouponCodeManagementDefaultQuery,
  getCouponCodesListGridOption,
} from '@/const/contents/promotion';
import { GetCouponsCodesCouponNoSearchRequest, PostCouponsCodesCouponNoGenerateRequest } from 'ncp-api-supporter';

@Component({
  components: {
    SearchButton,
    CheckboxGroup,
    Grid,
  },
})
export default class CouponCodeManagementPopup extends WindowPopupMainVue {
  // 발급 여부
  private searchQuery = null;
  private couponCodeStatusTypes: CheckBoxGroupOption<string> = CheckboxCouponCodeStatusTypes;
  @Ref() private readonly couponCodeStatusTypeRef: CheckboxGroup;

  private checkValidation() {
    if (this.searchQuery.couponCodeStatusType === '') {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_CODE_STATUS_IS_EMPTY')).then(() => {
        this.couponCodeStatusTypeRef.focus();
      });
      return false;
    }
    return true;
  }
  private reset() {
    this.searchQuery.couponCodeStatusType = 'ISSUED,NOT_ISSUED';
  }

  // grid
  private couponsCodes = [];
  private totalCount = 0;
  private gridProps = getCouponCodesListGridOption();

  private generateCount = 0;

  @Watch('$route.query')
  private onQueryStringChanged() {
    this.setQueryAndSearch();
  }

  private setQueryAndSearch() {
    this.searchQuery = CouponCodeManagementDefaultQuery(this.popupId, this.data.couponNo);
    const urlQuery = this.$route.query;
    if (!urlQuery) {
      return;
    }
    if (urlQuery.couponCodeStatusType) {
      this.searchQuery.couponCodeStatusType = urlQuery.couponCodeStatusType;
    }

    const couponCodeStatusType =
      this.searchQuery.couponCodeStatusType.split(',').length > 1 ? 'ALL' : this.searchQuery.couponCodeStatusType;
    const request = {
      pathParams: {
        couponNo: this.data.couponNo,
      },
      params: {
        couponCodeStatusType,
        page: (urlQuery.page ?? '1') as string,
        size: '30',
      },
    };
    this.$api.getCouponsCodesCouponNoSearch(request).then(response => {
      if (response && response.status === 200) {
        this.couponsCodes = response.data.contents;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  private changeGenerateCount({ target }: { target: HTMLInputElement }) {
    this.generateCount = Number(target.value);
  }

  private generateCoupon() {
    const request: PostCouponsCodesCouponNoGenerateRequest = {
      pathParams: {
        couponNo: this.data.couponNo,
      },
      data: {
        generateCount: this.generateCount,
      },
    };
    this.$api.postCouponsCodesCouponNoGenerate(request).then(response => {
      if (response?.status === 200) {
        this.setQueryAndSearch();
      }
    });
  }

  private async excelDownload() {
    if (this.couponsCodes.length === 0) {
      alert(this.$t('NOT_DATA'));
      return;
    }
    const request = {
      pathParams: {
        couponNo: this.data.couponNo,
      },
      params: {
        couponCodeStatusType: 'ALL',
        size: this.totalCount.toString(),
      },
    } as GetCouponsCodesCouponNoSearchRequest;
    const { data } = await this.$api.getCouponsCodesCouponNoSearch(request);
    const downloadData = data.contents.map(item => {
      return {
        couponCode: item.couponCode,
        couponCodeStatusType: (item.couponCodeStatusType === 'ISSUED'
          ? this.$t('PROMOTION.COUPON.CODE_STATUS_ISSUED')
          : this.$t('PROMOTION.COUPON.CODE_STATUS_NOT_ISSUED')) as string,
        generateYmdt: moment(item.generateYmdt).format('YYYY-MM-DD HH:mm:ss'),
      };
    });
    writeExcelFile<{ couponCode: string; couponCodeStatusType: string }>(
      downloadData,
      {
        couponCode: this.$t('PROMOTION.COUPON.CODE_TITLE'),
        couponCodeStatusType: this.$t('PROMOTION.COUPON.CODE_STATUS'),
        generateYmdt: this.$t('PROMOTION.COUPON.DATE'),
      },
      `${this.$t('PROMOTION.COUPON.COUPON_CODE')}_${this.data.couponNo}_${moment().format('YYYYMMDDHHmm')}`,
      true,
    );
  }

  async created() {
    if (this.popupId && this.data && this.data.couponNo) {
      this.setQueryAndSearch();
    }
  }
}
