

























































import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import { getInitEmailHistoryQuery, statusTypeOption } from '@/const/contents/mail';
import { ManageMessageStatus } from 'ncp-api-supporter';
import { isValidate } from '@/utils/validate';
import { overPeriodYear } from '@/utils/dateFormat';
import { getCurrentMallNo, mall } from '@/utils/mall';
import { Route } from 'vue-router';

@Component({
  components: { SearchButton, DateRangePicker, SelectBox },
})
export default class EmailHistorySearchForm extends Vue {
  private emailHistoryQuery = { ...getInitEmailHistoryQuery(getCurrentMallNo(this)), ...this.$route.query };
  private readonly statusTypeOption = statusTypeOption;
  private messageStatus = this.$route.query.messageStatus || 'ALL';

  @Watch('messageStatus')
  private changeMessageStatus(currentStatus: ManageMessageStatus | 'ALL') {
    if (currentStatus !== 'ALL') {
      this.emailHistoryQuery.messageStatus = currentStatus;
    } else {
      delete this.emailHistoryQuery.messageStatus;
    }
  }

  @Watch('$route')
  private resetRouteQuery({ query: currQuery }: Route, { query: prevQuery }: Route) {
    const prevMallNo = prevQuery.mallNo ?? mall.lastCreatedMall.mallNo;
    const resetSearchResult = currQuery.mallNo !== prevMallNo.toString();
    if (!resetSearchResult) return;

    this.resetQuery();
  }

  private resetQuery() {
    this.emailHistoryQuery = getInitEmailHistoryQuery(getCurrentMallNo(this));
    this.emailHistoryQuery.mallNo = getCurrentMallNo(this);
    this.messageStatus = 'ALL';
  }

  private validateDate(): boolean {
    const start = moment(this.emailHistoryQuery.startDate);
    const end = moment(this.emailHistoryQuery.endDate);
    const overPeriodMonth = end.diff(start, 'month', true) <= 1;
    const containsPrevYear = !(overPeriodYear(start) || overPeriodYear(end));

    if (!isValidate(containsPrevYear, this.$t('MEMBER.MAIL.DATE_MAX_LIMIT'))) return false;
    return isValidate(overPeriodMonth, this.$t('MEMBER.MAIL.PERIOD_MAX_LIMIT'));
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }
}
