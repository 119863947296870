











































































import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import { Action, namespace } from 'vuex-class';
import { TreeNode } from '@/types/tree';
import unsavedDetector from '@/directives/input/unsavedDetector';
import { ThrowMessagePopup } from '@/helpers/popup';
import { emailTemplate } from '@/const/contents/mail';
import { EmailTemplate } from '@/types/mail';

const tree = namespace('tree');

@Component({
  components: { TextInput, RadioGroup },
  directives: { unsavedDetector },
})
export default class TemplateEditForm extends Vue {
  @tree.Getter('getNode')
  private readonly getNode!: TreeNode;
  @tree.Getter('getPath')
  private readonly getPath!: string[];
  @tree.Action('setNode')
  private readonly setNode!: (data: { node: TreeNode; path: string[] }) => void;
  @Action('setDetectUnsavedFormMessage')
  private readonly setDetectUnsavedFormMessage!: (message: ThrowMessagePopup) => void;

  // 사용여부
  private usedType = '';
  private usedOption: RadioBoxGroupOption<string> = {
    name: 'email-used-option',
    data: [
      { id: 'used-email', value: 'used', display: 'MEMBER.SMS_AUTO.USED' },
      { id: 'notUsed-email', value: 'notUsed', display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  };
  // 발송 이메일 설정
  private sendMailType = '';
  private sendMailOption: RadioBoxGroupOption<string> = {
    name: 'send-mail-option',
    data: [
      { id: 'service-center-email', value: 'CUSTOMER_CENTER', display: 'MEMBER.MAIL.SERVICE_CENTER_EMAIL' },
      { id: 'send-only-email', value: 'OUTBOUND_ONLY', display: 'MEMBER.MAIL.SEND_ONLY_EMAIL' },
    ],
  };
  private initNode!: TreeNode;
  private title = null;
  private content = null;
  private isAdvertising = false;

  @PropSync('isUpdated')
  private isUpdatedSync?: boolean;
  private updatedFlag = {
    [emailTemplate.title]: false,
    [emailTemplate.content]: false,
    [emailTemplate.usedType]: false,
    [emailTemplate.sendMailType]: false,
  };
  private setUpdatedState(key: EmailTemplate, isUpdated = false) {
    this.$set(this.updatedFlag, key, isUpdated);
    this.isUpdatedSync = Object.values(this.updatedFlag).some(Boolean);
  }
  private getInitState(type: EmailTemplate) {
    switch (type) {
      case emailTemplate.usedType:
        return this.initNode.used ? 'used' : 'notUsed';
      case emailTemplate.sendMailType:
        return this.initNode.senderMailType;
      default:
        return this.initNode[type];
    }
  }
  private updateState(type: EmailTemplate) {
    this.initNode && this.setUpdatedState(type, this.getInitState(type) !== this[type]);
  }
  private resetUpdatedState(type?: EmailTemplate) {
    type
      ? this.setUpdatedState(type)
      : Object.keys(emailTemplate).forEach(key => this.setUpdatedState(key as EmailTemplate));
  }

  private get usedIndex(): number {
    return this.getNode?.used ? 0 : 1;
  }

  @Watch('getPath')
  @Watch('getNode')
  private changeNode() {
    const [parentPath, childPath] = this.getPath;
    if (!childPath) return;

    this.isAdvertising = parentPath === '프로모션 관련';
    this.usedType = this.usedOption.data[this.usedIndex].value;
    this.sendMailType = this.getNode.senderMailType;
    this.title = this.getNode.title;
    this.content = this.getNode.content;
    this.initNode = { ...this.getNode };
  }

  @Watch('usedType')
  private changeUsedType() {
    if (this.usedOption.data[this.usedIndex].value === this.usedType) return;
    this.updateState(emailTemplate.usedType);
    this.setNode({ node: { ...this.getNode, used: this.usedType === 'used' }, path: [] });
  }

  @Watch('sendMailType')
  private changeSendMailType() {
    this.updateState(emailTemplate.sendMailType);
    this.setNode({ node: { ...this.getNode, senderMailType: this.sendMailType }, path: [] });
  }

  @Watch('title', { immediate: false })
  @Watch('content', { immediate: false })
  private changeTemplateContent() {
    this.updateState(emailTemplate.title);
    this.updateState(emailTemplate.content);
    this.setNode({ node: { ...this.getNode, title: this.title, content: this.content }, path: [] });
  }

  public resetInitNode() {
    this.setNode({ node: { ...this.initNode }, path: [] });
    this.title = this.getNode.title;
    this.content = this.getNode.content;
    this.resetUpdatedState();
  }

  created() {
    const DEFAULT_INDEX = 0;
    this.usedType = this.usedOption.data[DEFAULT_INDEX].value;
    this.sendMailType = this.sendMailOption.data[DEFAULT_INDEX].value;
    this.setDetectUnsavedFormMessage({
      message: 'CONFIRM_UNSAVED_LEAVE',
      okButtonText: 'EXIT',
      cancelButtonText: 'CANCEL',
    });
  }
}
