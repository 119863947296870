













































































import { Component, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput, { MessageAlert } from '@/components/common/input/TextInput.vue';
import { TranslateResult } from 'vue-i18n';
import { validatePassword, ValidatePasswordState } from '@/utils/validate';

interface Password {
  value: string;
  messageAlert: MessageAlert;
}

interface PasswordField extends Password {
  refName: string;
  label: TranslateResult;
}

type PasswordKey = 'current' | 'new' | 'newRepeat';

@Component({
  components: {
    TextInput,
    NoticeBox,
    ModalHeader,
  },
})
export default class PasswordChange extends Vue {
  private readonly noticeList = [
    this.$t('COMMON.ACCOUNT.PASSWORD_CHANGE_NOTICE_0'),
    this.$t('COMMON.ACCOUNT.PASSWORD_CHANGE_NOTICE_1'),
    this.$t('COMMON.ACCOUNT.PASSWORD_CHANGE_NOTICE_2'),
    this.$t('COMMON.ACCOUNT.PASSWORD_CHANGE_NOTICE_3'),
    this.$t('COMMON.ACCOUNT.PASSWORD_CHANGE_NOTICE_4'),
  ];

  // ⚠️ 객체안에 비밀번호 들어가니 주의
  private password: { current: PasswordField; new: PasswordField; newRepeat: PasswordField } = {
    current: {
      value: '',
      messageAlert: {
        visible: false,
        message: '',
      },
      refName: 'currentPasswordInput',
      label: this.$t('COMMON.ACCOUNT.CURRENT_PASSWORD'),
    },
    new: {
      value: '',
      messageAlert: {
        visible: false,
        message: '',
      },
      refName: 'newPasswordInput',
      label: this.$t('COMMON.ACCOUNT.NEW_PASSWORD'),
    },
    newRepeat: {
      value: '',
      messageAlert: {
        visible: false,
        message: '',
      },
      refName: 'newPasswordRepeatInput',
      label: this.$t('COMMON.ACCOUNT.NEW_PASSWORD_REPEAT'),
    },
  };

  private onFocusOutValidation(password: PasswordField): void {
    const { value } = password;

    password.messageAlert = {
      visible: false,
      message: '',
    };

    if (value === '') {
      password.messageAlert = {
        visible: true,
        message: this.$t('ALERT_INVALID_PASSWORD_LEAST_2'),
      };
      return;
    }

    const result = validatePassword(password.value);
    switch (result) {
      case ValidatePasswordState.LESS_THEN_8:
      case ValidatePasswordState.MORE_THEN_20:
      case ValidatePasswordState.LESS_THEN_10_AT_LEAST_2:
        password.messageAlert = {
          visible: true,
          message: this.$t('ALERT_INVALID_PASSWORD_LEAST_2'),
        };
        return;
      case ValidatePasswordState.MORE_THEN_10_AT_LEAST_1:
        password.messageAlert = {
          visible: true,
          message: this.$t('ALERT_INVALID_PASSWORD_LEAST_1'),
        };
        return;
      case ValidatePasswordState.NOT_ALLOW_SPECIAL:
        password.messageAlert = {
          visible: true,
          message: this.$t('ALERT_INVALID_PASSWORD_SPECIAL'),
        };
        return;
    }

    if (
      this.password.new.value &&
      this.password.newRepeat.value &&
      this.password.new.value !== this.password.newRepeat.value
    ) {
      this.password.newRepeat.messageAlert = {
        visible: true,
        message: this.$t('COMMON.ACCOUNT.ALERT_NEW_PASSWORD_UNMATCHED'),
      };
    } else {
      this.password.newRepeat.messageAlert = {
        visible: false,
        message: '',
      };
    }
  }

  private allFieldValidate(): boolean {
    let isValidate = Object.entries(this.password).every(([_, passwordField]: [PasswordKey, PasswordField]) => {
      const { value, label, refName } = passwordField as PasswordField;
      if (value === '') {
        alert(label + this.$t('CHECK_INPUT_FILED').toString());
        (this.$refs[refName] as TextInput).focus();
        return false;
      }

      const result = validatePassword(value);
      switch (result) {
        case ValidatePasswordState.LESS_THEN_8:
        case ValidatePasswordState.MORE_THEN_20:
        case ValidatePasswordState.LESS_THEN_10_AT_LEAST_2:
          alert(this.$t('ALERT_INVALID_PASSWORD_LEAST_2'));
          (this.$refs[refName] as TextInput).focus();
          return false;
        case ValidatePasswordState.MORE_THEN_10_AT_LEAST_1:
          alert(this.$t('ALERT_INVALID_PASSWORD_LEAST_1'));
          (this.$refs[refName] as TextInput).focus();
          return false;
        case ValidatePasswordState.NOT_ALLOW_SPECIAL:
          alert(this.$t('ALERT_INVALID_PASSWORD_SPECIAL'));
          (this.$refs[refName] as TextInput).focus();
          return false;
      }

      return true;
    });

    if (
      isValidate &&
      this.password.new.value &&
      this.password.newRepeat.value &&
      this.password.new.value !== this.password.newRepeat.value
    ) {
      alert(this.$t('COMMON.ACCOUNT.ALERT_NEW_PASSWORD_UNMATCHED'));
      (this.$refs[this.password.newRepeat.refName] as TextInput).focus();
      isValidate = false;
    }

    return isValidate;
  }

  private putAdminsPassword(): Promise<boolean> {
    return this.$api
      .putAdminsPassword({
        data: {
          currentPassword: this.password.current.value,
          newPassword: this.password.new.value,
        },
      })
      .then(response => {
        if (response && response.status === 204) {
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private onSubmit(): void {
    const allGreen = this.allFieldValidate();

    if (allGreen) {
      this.putAdminsPassword().then(success => {
        if (success) {
          alert(this.$t('COMMON.ACCOUNT.ALERT_DONE'));
          this.$emit('click:close');
        }
      });
    }
  }
}
