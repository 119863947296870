























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SmsSendPreview extends Vue {
  @Prop() private readonly onNegativeClick!: () => void;
  @Prop() private data;
}
