export default Object.freeze({
  PARTNER_NOTICE_MANAGEMENT: '파트너공지사항 관리',
  SHOPPING_MALL: '쇼핑몰선택',
  DELETE_POST: '게시글 삭제',
  GO_TO_POST: '게시글 이동',
  REGISTER_NEW_POSTS: '신규 게시글 등록',
  SHOPPING_MALL_HEADER: '쇼핑몰',
  POST_NUMBER: '게시글 번호',
  TITLE: '제목',
  PUBLIC_AVAILABILITY: '공개여부',
  OPEN: '공개',
  PRIVATE: '비공개',
  VIEWS: '조회수',
  WRITER: '작성자',
  CREATION_DATE: '작성일시',
  FINALIST: '최종수정자',
  LAST_MODIFICATION_DATE: '최종수정일시',
  PLEASE_SELECT_A_NOTICE: '공지사항을 선택해 주세요.',
  ARE_YOU_SURE_DELETED: '선택한 공지사항을 삭제하시겠습니까? 삭제한 공지사항은 복원되지 않습니다.',
  SUCCESS: '성공',
  KEY: '건',
  PARTNER_ANNOUNCEMENT_GO_TO_POST: '파트너 공지사항 게시글 이동',
  POSTS_TO_CHANGE: '변경 대상 게시글',
  NOTICE_CATEGORY: '공지사항 카테고리',
  APPLY: '적용',
  CANCEL: '취소',
  PLEASE_SELECT_A_CATEGORY: '카테고리를 선택해 주세요',
  ANNOUNCEMENT_CATEGORY_SELECTION: '공지사항 카테고리 선택',
});
