












import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import { sortTypes } from '@/const/claim';
import { SortOptionValues } from '@/components/shipping/claim/searchOptions';
import { ClaimType, SortType } from '@/types/claim';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({ components: { SelectBox, TextInput } })
export default class SearchKeywordInput extends Vue {
  @Prop({ required: true })
  private claimType: ClaimType;

  @Prop({ required: true })
  private readonly sortTypeOptions!: OptionData<SortOptionValues>[];

  @PropSync('sortType', { required: true })
  private sortTypeSync!: SortType;

  @PropSync('desc', { required: true })
  private descSync!: boolean;

  private sortOptionValue: SortOptionValues = SortOptionValues.SORT_TYPE_CLAIM_DESC;

  private created() {
    this.convertSortTypeParams();
  }

  public initSortOptionValue() {
    this.sortOptionValue = SortOptionValues.SORT_TYPE_CLAIM_DESC;
    this.convertSortTypeParams();
  }

  private convertSortTypeParams() {
    switch (this.sortOptionValue) {
      case SortOptionValues.SORT_TYPE_CLAIM_DESC:
        this.sortTypeSync = sortTypes.CLAIM_NO;
        this.descSync = true;

        break;

      case SortOptionValues.SORT_TYPE_ORDERER_NAME_ASC:
        this.sortTypeSync = sortTypes.ORDERER;
        this.descSync = false;

        break;

      case SortOptionValues.SORT_TYPE_ORDERER_NAME_DESC:
        this.sortTypeSync = sortTypes.ORDERER;
        this.descSync = true;

        break;

      case SortOptionValues.SORT_TYPE_AMOUNT_ASC:
        this.sortTypeSync = sortTypes.CLAIM_AMT;
        this.descSync = true; // 클레임 금액에 -1을 negate하기 때문에, 반대로 출력되는 문제 발생. 그래서 desc도 반대로 요청한다.

        break;

      case SortOptionValues.SORT_TYPE_AMOUNT_DESC:
        this.sortTypeSync = sortTypes.CLAIM_AMT;
        this.descSync = false; // 클레임 금액에 -1을 negate하기 때문에, 반대로 출력되는 문제 발생. 그래서 desc도 반대로 요청한다.

        break;
    }
  }
}
