import { CountMap, KeyMap, MergedKey, MergedRowData, RowSpan } from '@/types/tui-grid/utils/rowMerger';

/**
 * <T>(KeyOfProperty<T>[], number) => (MergedRowData<T>) => keyof CountMap;
 * @param keys 병합하는 기준 키
 * @param currentStandardKeyIndex 현재 병합 중인 기준키의 index 번호
 *        예) keys = ['partnerNo', 'mallNo', 'orderNo'] -> 이와같이 병합하는 기준 키가 존재한다고 하면
 *        현재 병합중인 키가 'mallNo' 라고 가정하였을 때 generateCountingKey 는 1이 된다.
 * @return (item: MergedRowData<T>) => string
 *  클로저 : keys 에서 현재 확인하고 있는 key 까지의 item 값을 문자열로 합쳐 카운팅키를 만듦
 */
const generateCountingKeyFunction = <T, K extends string>(keys: MergedKey<T, K>[], currentStandardKeyIndex: number) => (
  index,
  item: MergedRowData<T, K>,
) => {
  return keys
    .filter((key, index) => index <= currentStandardKeyIndex)
    .map(key => `${index}_${item[key as any]}`) // TODO 타입 오류 : Type 'K|keyof T' cannot be used to index type 'MergedRowData'
    .join('')
    .trim();
};

/**
 * 그리드 스펙상 병합이 필요하면 item 에 _attributes.rowSpan 을 만들어줘야 함.
 * 예)  _attributes: { rowSpan: { partnerNo: 3, partnerName: 3 } }
 * @param item
 */
const initializeAttributesRowSpan = <T, K>(item: MergedRowData<T, K>): MergedRowData<T, K> => ({
  ...item,
  _attributes: {
    rowSpan: {
      ...item?._attributes?.rowSpan,
    },
  },
});

/**
 *
 * @param index
 * @param item
 * @param countMap
 * @param generateCountingKey (item: MergedRowData<T>) => string
 *  참고사항: index 를 포함하여 키를 만듦으로써 데이터 상 동일하지만 중간에 다른 데이터가 삽입되어있는 경우를 병합대상에 포함하지 않을 수 있음.
 *          예) '0_12345' ... 다른 데이터가 있음 '5_12345' -> 12345 값은 같지만, 중간에 데이터가 있기 때문에 각각 첫번째 로우가 되어야함
 *          예) '0_12345', '1_12345', '2_12345' -> 0_12345 의 count 는 총 3이 됨. -> 이전 키와 동일한 경우만 사용하기 위해 prevKey 를 활용함
 *          예) '0_12345', '1_12345', ..., '5_12345' -> 0_12345 의 count 는 총 2, '5_12345' 의 count 는 총 1
 */
const mapCountInfo = <T, K>({ index, item }, { countMap, generateCountingKey }): MergedRowData<T, K> => {
  const prevKey = generateCountingKey(index - 1, item);
  const prevItem = countMap[prevKey];
  const firstKey = prevItem?.firstKey ?? null;

  const currKey = generateCountingKey(index, item);

  if (!firstKey) {
    item.isFirstRow = true;
    countMap[currKey] = {
      count: 1,
      firstKey: currKey,
    };
  } else {
    countMap[firstKey].count += 1;

    item.isFirstRow = false;
    countMap[currKey] = {
      count: 1,
      firstKey,
    };
  }

  return item;
};

const mapAttributesRowSpan = <T, K extends string>(
  { index, item },
  { countMap, generateCountingKey },
  currentKeys: MergedKey<T, K>[],
): MergedRowData<T, K> => {
  const countingKey = generateCountingKey(index, item);
  const count = countMap[countingKey].count;

  // 동일한 항목이 1개 이상 존재할 때, 첫 번째 row 에만 _attributes.rowSpan 세팅
  if (count > 1 && item.isFirstRow) {
    item._attributes.rowSpan = {
      ...item._attributes.rowSpan,
      ...(Object.fromEntries(currentKeys.map(key => [key, count])) as RowSpan<T>),
    };
  }
  return item;
};

const getMergedRowsByCurrentKeyMap = <T, K extends string>(
  data: MergedRowData<T, K>[],
  currentMergedKeys: MergedKey<T, K>[],
  countMap: CountMap,
  generateCountingKey, // (item: MergedRowData<T>) => string
) => {
  const countInfo = {
    countMap,
    generateCountingKey,
  };
  return data
    .map(initializeAttributesRowSpan)
    .map((item, index) => mapCountInfo({ index, item }, countInfo))
    .map((item, index) => mapAttributesRowSpan({ index, item }, countInfo, currentMergedKeys));
};

/**
 * @param keyMap required
 * @param data required
 * @param initialCountMap?: { [카운트기준키]: 병합될 셀의 수 }
 * @param initialFlag?: number;
 */
const getMergedRowsBy = <T, K extends string>(
  data: MergedRowData<T, K>[],
  keyMap: KeyMap<T, K>,
  initialCountMap?: CountMap,
  initialFlag?: number,
): MergedRowData<T, K>[] => {
  const standardKeys = Object.keys(keyMap) as MergedKey<T, K>[];
  const countMap = initialCountMap ?? ({} as CountMap);

  let flag = initialFlag ?? 0; // 병합하는 기준 키의 길이만큼 재귀문을 돌리기 위한 플래그

  if (flag === standardKeys.length) {
    return data;
  }

  const currentMergedKeys: MergedKey<T, K>[] = keyMap[standardKeys[flag]];
  const generateCountingKey = generateCountingKeyFunction<T, K>(standardKeys, flag);
  const mergedRowsByCurrentKeyMap = getMergedRowsByCurrentKeyMap(
    data,
    currentMergedKeys,
    countMap,
    generateCountingKey,
  );

  return getMergedRowsBy(mergedRowsByCurrentKeyMap, keyMap, countMap, ++flag);
};

/**
 * T(GridData): 그리드 data 로 전달되는 값[0]의 타입
 * K(GridColumnName): 그리드 columns 의 name 만 뽑은 유니온 타입 예) 'partnerNo' | 'partnerName'
 * 목적: 그리드 병합을 위해 필요한 _attributes 정보를 만듦
 * 요구사항:
 *   - n 개의 키를 기준으로 병합
 *    - n-1 기준 키 값이 1개 이상 동일한 값이 있는 경우에만, n + 1 기준 키로 동일한 값 여부를 확인 (이전 값에 영향을 받음)
 *   - 병합이 필요한 첫번째 item 에만 _attributes.rowSpan 값 정의
 * @param keyMap required { 병합기준키: 병합이 필요한 셀이름 }
 *        예) { partnerNo: ['productName', 'countryCode' ] }
 *        partnerNo 가 같으면 productName, countryCode 를 각각 병합
 * @param data required 그리드 data 로 넘겨지는 값
 */
export default <T, K extends string>(data: MergedRowData<T, K>[], keyMap: KeyMap<T, K>) =>
  getMergedRowsBy<T, K>(data, keyMap);
