













import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolTipOption } from '@/types/tooltip';

//TODO: tooltip/contents/List.vue
@Component
export default class DeliveryRecalculate extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption;
}
