import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import ArticleList from '@/views/contents/board/basic/ArticleList.vue';
import BoardSetting from '@/views/contents/board/basic/BoardSetting.vue';
import Inquiries from '@/views/contents/board/basic/Inquiries.vue';
import InquiriesList from '@/views/contents/board/basic/InquiriesList.vue';
import InquiriesType from '@/views/contents/board/basic/InquiriesType.vue';
import Reviews from '@/views/contents/board/basic/Reviews.vue';
import Questions from '@/views/contents/board/basic/Questions.vue';
import BoardSettingIframe from '@/views/contents/board/basic/BoardSettingIframe.vue';
import ArticleListIframe from '@/views/contents/board/basic/ArticleListIframe.vue';
import ReviewsIframe from '@/views/contents/board/basic/ReviewsIframe.vue';
import QuestionsIframe from '@/views/contents/board/basic/QuestionsIframe.vue';
import InquiriesIframe from '@/views/contents/board/basic/InquiriesIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/board',
    name: 'board',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/setting-prev',
        name: 'BoardSettingPrev',
        component: BoardSetting,
        meta: {
          detection: true,
          detectConfirm: true,
        },
      },
      {
        path: 'basic/setting',
        name: 'BoardSettingIframe',
        component: BoardSettingIframe,
        meta: {
          detection: true,
          detectConfirm: true,
        },
      },
      {
        path: 'basic/inquiries-prev',
        name: 'InquiriesPrev',
        component: Inquiries,
        redirect: { name: 'InquiriesList' },
        children: [
          {
            path: 'list',
            name: 'InquiriesList',
            component: InquiriesList,
          },
          {
            path: 'type',
            name: 'InquiriesType',
            component: InquiriesType,
            meta: {
              detection: true,
              // detectConfirm: true,
            },
          },
        ],
      },
      {
        path: 'basic/inquiries',
        name: 'Inquiries',
        component: InquiriesIframe,
        redirect: { name: 'InquiriesListIframe' },
        children: [
          {
            path: 'list',
            name: 'InquiriesListIframe',
            component: InquiriesIframe,
          },
          {
            path: 'category',
            name: 'InquiriesCategoryIframe',
            component: InquiriesIframe,
          },
          {
            path: 'template',
            name: 'InquiriesTemplateIframe',
            component: InquiriesIframe,
          },
          {
            path: 'setting',
            name: 'InquiriesSettingIframe',
            component: InquiriesIframe,
          },
        ],
      },
      {
        path: 'basic/reviews-prev',
        name: 'ReviewsPrev',
        component: Reviews,
      },
      {
        path: 'basic/reviews',
        name: 'Reviews',
        component: ReviewsIframe,
        redirect: { name: 'ReviewsListIframe' },
        children: [
          {
            path: 'list',
            name: 'ReviewsListIframe',
            component: ReviewsIframe,
          },
          {
            path: 'report',
            name: 'ReviewsReportIframe',
            component: ReviewsIframe,
          },
          {
            path: 'exception',
            name: 'ReviewsExceptionIframe',
            component: ReviewsIframe,
          },
          {
            path: 'setting',
            name: 'ReviewsSettingIframe',
            component: ReviewsIframe,
          },
        ],
      },
      {
        path: 'basic/questions-prev',
        name: 'QuestionsPrev',
        component: Questions,
      },
      {
        path: 'basic/questions',
        name: 'Questions',
        component: QuestionsIframe,
        redirect: { name: 'QuestionsListIframe' },
        children: [
          {
            path: 'list',
            name: 'QuestionsListIframe',
            component: QuestionsIframe,
          },
          {
            path: 'setting',
            name: 'QuestionsSettingIframe',
            component: QuestionsIframe,
          },
        ],
      },
      {
        path: 'basic/articles-prev',
        name: 'ArticleListPrev',
        component: ArticleList,
      },
      {
        path: 'basic/articles',
        name: 'ArticleList',
        component: ArticleListIframe,
      },
    ],
  };
};
