





















import { Vue, Component, PropSync, Ref, Watch, Prop } from 'vue-property-decorator';
import { RadioBoxGroupOption } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { throwWindowPopup } from '@/helpers/popup';
import { DutyInformation, UpsertType } from '@/types';

export const UPSERT_TYPE = {
  insert: 'insert',
  upsert: 'upsert',
} as const;

@Component({
  components: {
    RadioGroup,
  },
})
export default class DutyInfo extends Vue {
  @PropSync('dutyInfo')
  private dutyInfoSync!: DutyInformation;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private readonly radioOption: RadioBoxGroupOption<YorN> = {
    name: 'dutyInfo',
    data: [
      { id: 'useYnN', value: 'N', display: 'PRODUCT.COMMON.NOT_USE' },
      { id: 'useYnY', value: 'Y', display: 'PRODUCT.COMMON.USE' },
    ],
  };

  @Ref()
  private readonly dutyRef!: HTMLButtonElement;
  public focus(): void {
    this.dutyRef.focus();
  }

  private async upsertDutyInfo(type: UpsertType = UPSERT_TYPE.insert) {
    const { data: dutyCategories } = await this.$api.getDutyCategories();
    switch (type) {
      case UPSERT_TYPE.insert:
        throwWindowPopup(
          'UpsertDutyInfo',
          { type, dutyCategories, dutyInfo: this.dutyInfoSync },
          'md',
          changedDutyInfo => changedDutyInfo.data && this.bindChangedDutyInfo(changedDutyInfo.data),
        );
    }
  }

  private bindChangedDutyInfo(dutyInfo: DutyInformation) {
    const {
      saveTitle,
      dutyContent: { categoryName, categoryNo, contents },
      useYn,
    } = dutyInfo;
    this.dutyInfoSync.saveTitle = saveTitle;
    this.dutyInfoSync.useYn = useYn;
    this.dutyInfoSync.dutyContent.categoryName = categoryName;
    this.dutyInfoSync.dutyContent.categoryNo = categoryNo;
    const newContents = contents.map(({ contentName, model }) => ({ [contentName]: model }));
    this.$set(this.dutyInfoSync.dutyContent, 'contents', newContents);
  }

  @Watch('dutyInfoSync.dutyContent.categoryNo')
  private changedCategoryNo() {
    if (this.dutyInfoSync.dutyContent === null) return; // 상품 저장 시점에서 null 값으로 세팅
    this.$nextTick(() => (this.dutyInfoSync.useYn = this.dutyInfoSync.dutyContent.categoryNo > 0 ? 'Y' : 'N'));
  }

  private created() {
    this.changedCategoryNo();
  }
}
