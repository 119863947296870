import { Component, Mixins } from 'vue-property-decorator';
import OrderGridsColumns from '@/components/shipping/order/mixins/OrderGridsColumns';

@Component
export default class ContentBottomMixin extends Mixins(OrderGridsColumns) {
  public orderFormatterList(arr: any, key1: any, key2: any, key3: any): any {
    const arry: any = [];
    if (arr && arr.length > 0) {
      arr.forEach((item: any): any => {
        item[key1].forEach((item1: any): any => {
          item1[key2].forEach((item2: any): any => {
            item2[key3].forEach((item3: any): any => {
              const obj: any = {};
              Object.keys(item).forEach((name: any): any => {
                if (name !== key1) {
                  obj[name] = item[name];
                }
              });
              Object.keys(item1).forEach((name1: any): any => {
                if (name1 !== key2) {
                  obj[name1] = item1[name1];
                }
              });
              Object.keys(item2).forEach((name2: any): any => {
                if (name2 !== key3) {
                  obj[name2] = item2[name2];
                }
              });
              Object.keys(item3).forEach((name3: any): any => {
                obj[name3] = item3[name3];
              });
              this.$set(obj, '_attributes', {});
              this.$set(obj['_attributes'], 'rowSpan', {});
              arry.push(obj);
            });
          });
        });
      });
      return arry;
    }
  }

  public orderMergeRow(
    arr: any,
    bigRow: any,
    midRow: any,
    smallRow: any,
    bigGroupFlag: string,
    middleGroupFlag: string,
    smallGroupFlag: string,
  ): any {
    if (arr && arr.length === 0) {
      return;
    }
    const bigGroup: any = [];
    const newArry: any = [];
    arr.forEach((item: any): void => {
      if (bigGroup.indexOf(item[bigGroupFlag]) < 0) {
        bigGroup.push(item[bigGroupFlag]);
      }
    });
    bigGroup.forEach((bigItem: any): void => {
      const nums: any = arr.filter((item: any): any => {
        return bigItem === item[bigGroupFlag];
      });

      let firstRow = true;
      arr.forEach((item: any): void => {
        if (bigItem === item[bigGroupFlag]) {
          if (firstRow && nums.length > 1) {
            const rowSpanTag = bigRow;
            this.addRowSpan(rowSpanTag, item, nums.length);
            firstRow = false;
          }
          newArry.push(item);
        }
      });
      const middleGroup: any = [];
      nums.forEach((numItem: any): void => {
        if (middleGroup.indexOf(numItem[middleGroupFlag]) < 0) {
          middleGroup.push(numItem[middleGroupFlag]);
        }
      });
      middleGroup.forEach((smallItem: any): any => {
        const orderNums: any = nums.filter((item1: any): any => {
          return smallItem === item1[middleGroupFlag];
        });
        let orderFirstRow = true;
        newArry.map((newItem: any): any => {
          if (smallItem === newItem[middleGroupFlag] && newItem[bigGroupFlag] === bigItem && orderFirstRow) {
            const rowSpanTag = midRow;
            this.addRowSpan(rowSpanTag, newItem, orderNums.length);
            orderFirstRow = false;
          }
        });
        const smallGroup: any[] = [];
        orderNums.forEach((sItem: any): any => {
          if (smallGroup.indexOf(sItem[smallGroupFlag]) < 0) {
            smallGroup.push(sItem[smallGroupFlag]);
          }
        });
        smallGroup.forEach((smItem: any): any => {
          const length = orderNums.filter((item2: any): any => {
            return smItem === item2[smallGroupFlag];
          }).length;
          const smallFirstRow = true;
          newArry.map((smNewItem: any): any => {
            if (smItem === smNewItem[smallGroupFlag] && smNewItem[middleGroupFlag] === smallItem && smallFirstRow) {
              const rowSpanTag = smallRow;
              this.addRowSpan(rowSpanTag, smNewItem, length);
              orderFirstRow = false;
            }
          });
        });
      });
    });
    return newArry;
  }

  public addRowSpan(arr: any, tag: any, length: number): void {
    if (arr.length === 0) {
      return;
    }
    arr.forEach((items: any): any => {
      if (length > 1) {
        this.$set(tag['_attributes']['rowSpan'], items, length);
      }
    });
  }
}
