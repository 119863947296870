








































































































































import { Vue, Component } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import { InvoiceFormatObj } from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

// 배송준비중리스트 p.24
@Component({
  components: { TextInput, SelectBox, ToolTip },
})
export default class DeliveryInvoiceForm extends Vue {
  private toolTipOption = {
    name: 'List',
    style: {
      position: 'absolute',
      left: '-250px',
      width: '500px',
    },
    listOption: {
      mainMessage: [
        this.$t('ORDER.POPUP.TOOLTIP_DEFAULT_FORMAT'),
        this.$t('ORDER.POPUP.TOOLTIP_ADDITIONAL_LABEL'),
        this.$t('ORDER.POPUP.TOOLTIP_NONE_LABEL'),
      ],
    },
  };
  private deliveryCompanyType = 'CJ';
  private deliveryCompanyOptions: OptionData<string>[] = [
    // 오픈스펙, CJ 대한통운만 우선 제공한다고함
    {
      value: 'CJ',
      name: 'CJ대한통운',
    },
  ];
  private checkedRowPriorities = [];
  private invoiceFormatList = [];

  private adminNo = 0;
  private downloadCount = 0;
  private invoiceType = this.$route.params.invoiceType.toUpperCase();
  private title =
    this.invoiceType === 'RELEASE' ? this.$t('ORDER.POPUP.INVOICE_TITLE') : this.$t('ORDER.POPUP.INVOICE_TITLE1');

  mounted() {
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-700');
    });
    this.searchInvoiceFormat();
  }

  private searchInvoiceFormat() {
    const request = {
      params: {
        deliveryCompanyType: 'CJ',
        deliveryInvoiceFormatType: this.invoiceType,
      },
    };
    this.$api.getInvoiceFormat(request).then(res => {
      const { adminNo, downloadCount, deliveryInvoiceFormatDetailModels } = res.data;

      this.adminNo = adminNo;
      this.downloadCount = downloadCount;
      this.invoiceFormatList = deliveryInvoiceFormatDetailModels;
    });
  }

  // reference: EditGridLayout.vue
  private onMove(moveto: 'prev' | 'next' | 'first' | 'last') {
    switch (moveto) {
      case 'first':
        this.forceMove(0);
        break;

      case 'last':
        this.forceMove(this.invoiceFormatList.length - this.checkedRowPriorities.length);
        break;

      case 'prev':
        this.swapMove(-1);
        break;

      case 'next':
        this.swapMove(1);
        break;
    }
  }

  // reference: EditGridLayout.vue
  private forceMove(landingPoint: number): void {
    const allUnits = [...this.invoiceFormatList];
    const moveUnits = this.invoiceFormatList.reduce((moveUnits, invoiceFormat, index) => {
      const isSelected = this.checkedRowPriorities.some(priority => priority === invoiceFormat.priority);
      if (isSelected) {
        const [unit] = allUnits.splice(index - moveUnits.length, 1);
        moveUnits.push(unit);
      }
      return moveUnits;
    }, []);

    allUnits.splice(landingPoint, 0, ...moveUnits);

    this.invoiceFormatList = [...allUnits];
  }

  // reference: EditGridLayout.vue
  private swapMove(landingPoint: -1 | 1): void {
    const swap = (scope, prev, next): void => {
      [scope[prev], scope[next]] = [scope[next], scope[prev]];
    };
    const allUnits = [...this.invoiceFormatList];

    const reducer = (unused, invoiceFormat, index) => {
      const isSelected = this.checkedRowPriorities.some(priority => priority === invoiceFormat.priority);
      if (!isSelected) return;
      const maxLength = this.invoiceFormatList.length;
      const moveTo = index + landingPoint;

      if (moveTo > -1 && moveTo < maxLength) {
        const moveUnit = allUnits[moveTo];
        const moveUnitIsSwapped = this.checkedRowPriorities.some(v => v === moveUnit.priority);
        if (!moveUnitIsSwapped) swap(allUnits, moveTo, index);
      }
    };

    if (landingPoint === -1) this.invoiceFormatList.reduce(reducer, []);
    if (landingPoint === 1) this.invoiceFormatList.reduceRight(reducer, []);

    this.invoiceFormatList = [...allUnits];
  }

  private onClickSaveBtn() {
    if (!this.validation()) {
      return;
    }
    const deliveryInvoiceFormatModel = this.createDeliveryInvoiceFormatModel();

    const request = {
      data: deliveryInvoiceFormatModel,
    };

    this.$api.putInvoiceFormat(request).then(() => {
      alert(this.$t('SAVE_SUCCESS'));
      this.onClose();
      opener.location.reload();
    });
  }

  private validation() {
    if (this.invoiceFormatList.some(({ label }) => !label || label.trim().length === 0)) {
      alert(this.$t('ORDER.POPUP.INVOICE_SAVE_CHECK'));
      return false;
    }
    return true;
  }

  private onClose() {
    window.close();
  }

  private createDeliveryInvoiceFormatModel(): InvoiceFormatObj {
    const deliveryInvoiceFormatModelDetails = this.invoiceFormatList.map(
      ({ label, basicInfo, propertyLabel, propertyName, propertyValue }, index) => ({
        label,
        propertyName,
        propertyValue,
        basicInfo,
        priority: index + 1,
        propertyLabel: basicInfo ? propertyLabel : '',
      }),
    );

    return {
      adminNo: this.adminNo,
      downloadCount: this.downloadCount,
      deliveryCompanyType: 'CJ',
      deliveryInvoiceFormatType: this.invoiceType,
      deliveryInvoiceFormatDetailModels: deliveryInvoiceFormatModelDetails,
    };
  }

  private onInitFormatOrders() {
    const request = {
      params: {
        deliveryCompanyType: 'CJ',
        deliveryInvoiceFormatType: this.invoiceType,
      },
    };
    this.$api.getInvoiceFormatInit(request).then(res => {
      this.invoiceFormatList = res.data.deliveryInvoiceFormatDetailModels;
    });
  }

  private onClickAddRowBtn() {
    this.invoiceFormatList.push(this.createRow());
  }

  private createRow() {
    const priority = this.invoiceFormatList.length + 1;

    return {
      basicInfo: false,
      label: '',
      priority,
      propertyLabel: '',
      propertyName: '',
      propertyValue: '',
    };
  }

  private onDeleteDeliveryFormat($index: number) {
    this.invoiceFormatList.splice($index, 1);
  }
}
