export default Object.freeze({
  TITLE: '네이버페이 취급제한상품 안내',
  LIMIT_PRODUCT: '취급제한상품',
  SERVICE_PRODUCT:
    '90일 이상 장기 예약상품, 0원상품, 회원전용상품, 기타 실물 배송이 없는 용역서비스(영상제작, 영상변환, 소프트웨어 등), 서비스상품군',
  TIP_DISABLE:
    '‘즉시할인적용가 (판매가 – 즉시할인가)+옵션가 - 추가할인가 ’ 금액이 ‘즉시할인적용가’ 금액에 -50%를 초과하는 경우 구매 불가합니다.',
  EG: '예) 즉시할인 적용가 10,000 원 / 옵션가 -4,000 원 추가할인 -2,000 원 인 경우 구매불가',
  NAVERPAY_LIMIT:
    '네이버페이 규칙에 의해 취급제한상품은 매매가 허락되지 않습니다. 취급제한상품으로 등록 시 네이버페이 결제수단으로 결제할 수 없습니다.',
  DETAIL_INFO: '자세한 사항은 네이버페이에 문의 바랍니다.',
  CLOSE: '닫기',
});
