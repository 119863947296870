export default Object.freeze({
  TITLE: '회원 조회',
  SIMPLE_QUERY: '간편조회',
  BASIC_INFORMATION: '기초정보',
  TYPE: '회원유형',
  STATUS: '회원상태',
  GRADE: '회원등급',
  GROUP: '회원그룹',
  CERTIFICATION: '본인인증',
  BLACKLIST: '블랙리스트',
  BLACKLIST_MEMBER: '블랙리스트 회원',
  JOIN_DATA: '회원가입일',
  JOIN_TYPE: '가입유형',
  JOIN_WAY: '회원가입경로',
  PC_WEB: 'PC웹',
  MOBILE_WEB: '모바일웹',
  MEMBER_CERTIFY_STATUS: '회원인증여부',
  COMPLETE_CERTIFY: '인증완료',
  NOT_CERTIFY: '인증 안 함',
  ACTIVITY: '활동량',
  POINT: '적립금',
  LOGIN_COUNT: '로그인 횟수',
  ALL_ACTUAL_ORDER: '총 실 주문건수',
  ALL_ACTUAL_PAYMENT: '총 실 결제금액',
  SEARCH_BIRTH: '생일 검색',
  LAST_LOGIN_DATA: '최종 로그인일',
  DORMANT_RELEASE_DATE: '휴면 해제일',
  LAST_LOGIN: '최종로그인',
  LONG_TIME_NOUSE_DATA: '장기 미사용 기간',
  LONG_TERM: '장기 미사용 회원',
  INFORMATION: '개인정보',
  INFORMATION2: '회원정보',
  GENDER: '성별',
  AGE: '연령대',
  AGREED: '마케팅 수신동의',
  APPPUSHAGREED: '앱푸시 수신동의',
  APPPUSHAGREED2: '푸시 수신동의',
  SMSAGREED: 'SMS 수신동의',
  EMAILAGREED: '이메일 수신동의',
  DMGREED: 'DM 수신동의',
  COMBINED_POP: '회원연동',
  COMBIN: '회원 통합',
  COMBINED: '회원통합여부',
  COMBINED_DT: '회원 연동일',
  COMBINED_DATA: '회원통합일',
  PUSH_NOTIFICATION: '푸시알림 수신',
  SMS_AGREED: 'SMS수신',
  MAIL_AGREED: '메일수신',
  DM_AGREED: 'DM 수신',
  COLLECTION_AND_USE: '개인정보 수집/이용',
  PROCESS_CONSIGNMENT: '개인정보 처리/위탁',
  THIRD_PARTY_PROVISION: '개인정보 제3자 제공',
  PERIOD_SEARCH: '기간검색',
  PERIOD_SELECT: '기간 선택',
  BUTTON_ACCUMULATION: '적립금 지급/차감',
  CRMBUTTON_RATING: '회원등급 변경',
  CRMBUTTON_GROUP: '회원그룹 변경',
  CRMBUTTON_COUPON: '쿠폰 지급',
  COUPON: '쿠폰',

  BASIC_MEMBER: '일반회원',
  SIMPLE_LOGIN: '간편로그인',
  NOT_SELECT: '선택 안 함',
  LAST_LOGIN_DATE: '최종로그인일',
  NO_LOGIN_MEMBER: '로그인일자 없는 회원',
  NO_DORMANT_MEMBER: '휴면해제일 없는 회원',
  DORMANT_DATE: '휴면해제일',
  COMBINED_AGREE: '연동함',
  COMBINED_NOTAGREE: '연동안함',
  PROHIBIT: '이용정지',
  EXPEL: '강제탈퇴',
  MODIFICATION_ACCUMULATION: '{0} 지급/차감',
  ORDER_NO: '주문번호',
  MEMBER_NO: '회원번호',
  ID: '아이디',
  NAME: '이름',
  NICKNAME: '닉네임',
  EMAIL: '이메일',
  EMAIL2: 'e메일 주소',
  MAILADDRESS: 'e메일주소',
  MOBILE: '휴대폰번호',
  CALL_NUMBER: '전화번호',
  AGE_COUNT1: '10대',
  AGE_COUNT2: '20대',
  AGE_COUNT3: '30대',
  AGE_COUNT4: '40대',
  AGE_COUNT5: '50대',
  AGE_COUNT6: '60대',
  AGE_OVER_COUNT6: '60대 이상',
  STATUS1: '가입대기',
  STATUS2: '가입완료',
  STATUS3: '휴면',
  STATUS4: '이용정지',
  MALL_NAME: '쇼핑몰',
  MEMBER_NAME: '회원명',
  ERROR_SELECT_MEMBER_COUNT: '회원을 선택해 주세요.',
  ALERT_SELECT_MEMBER_NONE: '회원을 선택해주세요.',
  ALERT_NON_MALL: '회원 검색 후 진행해야 합니다.',
  ALERT_NON_KEYWORD: '검색어를 입력하세요.',
  ALERT_NON_MEMBER: '등록된 회원정보가 없습니다.',
  ERROR_NON_MEMBER: '회원정보를 가져오는데 실패하였습니다.',
  ALERT_INPUT_ACCUMULATION: '적립금 입력이 올바르지 않습니다.',
  ALERT_LOGIN_COUNT: '로그인 횟수 입력이 올바르지 않습니다.',
  ALERT_MUST_ALL_ACTUAL_ORDER: '총 실 주문건수를 입력해주세요.',
  ALERT_INPUT_ALL_ACTUAL_ORDER: '총 실 주문건수 입력이 올바르지 않습니다.',
  ALERT_MUST_ALL_ACTUAL_PAYMENT: '총 실 결제금액을 입력해주세요.',
  ALERT_INPUT_ALL_ACTUAL_PAYMENT: '총 실 결제금액 입력이 올바르지 않습니다.',

  SORT_BASE: '정렬기준',
  SORT_DESC_LAST_LOGIN: '최종로그인일↓',
  SORT_ASC_LAST_LOGIN: '최종로그인일↑',
  SORT_DESC_DORMANT_RELEASE: '휴면해제일↓',
  SORT_ASC_DORMANT_RELEASE: '휴면해제일↑',
  SORT_DESC_LOGIN_COUNT: '로그인횟수↓',
  SORT_ASC_LOGIN_COUNT: '로그인횟수↑',
  SORT_DESC_TOTAL_ORDER: '실 주문건수↓',
  SORT_ASC_TOTAL_ORDER: '실 주문건수↑',
  SORT_DESC_TOTAL_PAYMENT: '실 결제금액↓',
  SORT_ASC_TOTAL_PAYMENT: '실 결제금액↑',

  // tooltip
  ORDER_COUNT_TOOLTIP_MESSAGE:
    '총 실 주문건수는 해당 회원의 가입 이후 누적된 <strong>정상 주문 상태의 주문 건수(주문번호 기준)</strong>를 의미합니다.<br/>취소/교환/반품 등을 통하여 해당 주문번호의 모든 주문상품이 클레임 처리 완료된 경우 누적건수에서 제외됩니다.<br/>(정상주문 상태 : 결제완료 / 상품준비중 / 배송준비중 / 배송중 / 배송완료 / 구매확정)',
  PAY_AMOUNT_TOOLTIP_MESSAGE:
    '총 실 결제금액은 해당 회원의 가입 이후 누적된 정상 주문 상태의 주문건에 대한 실제 결제한 금액의 합을 의미합니다.<br/>' +
    '취소/교환/반품 등을 통하여 클레임 처리가 완료된 건은 금액에서 총 실 결제금액에서 제외됩니다.<br/>' +
    '(정상주문 상태 : 결제완료 / 상품준비중 / 배송준비중 / 배송중 / 배송완료 / 구매확정) ',
  ORDER_COUNT_TOOLTIP_4:
    '동일 주문번호 내에서 부분 취소 시에도 다른 상품이 정상주문 상태인 경우 실 주문건수에 포함됩니다.',
  ORDER_COUNT_TOOLTIP_5: '총 실 주문건수는 주문 및 클레임 처리 시 실시간으로 반영됩니다.',
  ORDER_COUNT_TOOLTIP_6:
    '또한, 보다 정확한 데이터 제공을 위하여 매일 00시를 기준으로 주문정보와 대조하여 업데이트됩니다.',
  ORDER_COUNT_TOOLTIP_TH_1: '주문번호',
  ORDER_COUNT_TOOLTIP_TH_2: '상품',
  ORDER_COUNT_TOOLTIP_TH_3: '주문상태',
  ORDER_COUNT_TOOLTIP_TH_4: '실 주문건수',
  ORDER_COUNT_TOOLTIP_TH_5: '총 실 주문건수',
  ORDER_COUNT_TOOLTIP_STATUS_1: '결제완료',
  ORDER_COUNT_TOOLTIP_STATUS_2: '상품준비중',
  ORDER_COUNT_TOOLTIP_STATUS_3: '배송완료',
  ORDER_COUNT_TOOLTIP_STATUS_4: '취소완료',
  ORDER_COUNT_TOOLTIP_STATUS_5: '반품완료',
  ORDER_COUNT_TOOLTIP_STATUS_6: '입금대기',
  PAY_AMOUNT_TOOLTIP_1: '실 결제금액 = 총 주문상품금액(판매가 - 즉시항인 + 옵션가) - 쿠폰할인 + 배송비 - 적립금사용',

  ORDER_BY_DEFAULT: '정렬기준',
  ORDER_BY_LAST_LOGIN: '최종로그인일↑',
  ORDER_BY_LAST_LOGIN_DESC: '최종로그인일↓',
  ORDER_BY_RELEASE: '휴면해제일↑',
  ORDER_BY_RELEASE_DESC: '휴면해제일↓',
  ORDER_BY_LOGIN_COUNT: '로그인횟수↑',
  ORDER_BY_LOGIN_COUNT_DESC: '로그인횟수↓',

  MEMBER: '회원',
  SELECTED: '명 선택됨',
});
