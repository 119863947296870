








import { Vue, Component } from 'vue-property-decorator';
import { getHref } from '@/utils/route';

@Component
export default class Logo extends Vue {
  get homeUrl() {
    return getHref('Home', false);
  }
}
