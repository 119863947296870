


















import { Vue, Component, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { InputNumber } from '@/types';
import { i18nAdditional } from '@/views/contents/addition/basic/AdditionalService';
import MallSelect from '@/components/common/input/MallSelect.vue';

@Component({ components: { MallSelect, SelectBox, NoticeBox } })
export default class MallSelector extends Vue {
  @Prop()
  private readonly onNegativeClick!: () => void;
  @Prop()
  private readonly onPositiveClick!: ({ mallNo: number }) => void;

  private noticeList = [i18nAdditional('NOTICE_ADDITIONAL')];

  private mallNo: InputNumber = '';

  private onSave(): void {
    // TODO: 신청불가한 쇼핑몰만 존재하는 경우, 쇼핑몰을 선택할 수 없기 때문에 이 조건을 수정해야 함.
    if (this.mallNo > 0) {
      this.onPositiveClick({ mallNo: this.mallNo });
      return;
    }
    alert(i18nAdditional('ALERT_SELECT_MALL'));
  }
}
