export default Object.freeze({
  OWN_SKIN_LIST_TOOLTIP_MESSAGE: '사용스킨 변경 시 쇼핑몰 반영 시까지 일정 시간이 소요될 수 있습니다.',
  TIME_DELAY: '사용스킨 변경 시 쇼핑몰 반영 시까지 일정 시간이 소요될 수 있습니다.',
  SELECT_MALL: '쇼핑몰을 선택하세요.',
  TITLE: '분류명',
  PERIOD_SEARCH: '기간검색',
  CHANGE_STATUS_CONFIRM: '{title} 메인분류를 {value} 상태로 변경하시겠습니까?',
  DISPLAYABLE_CHANGED: '노출여부가 변경되었습니다.',
  BASIC_INFO: '기본정보',
  SECTION_NO: '분류번호',
  PLATFORM_TYPE: '플랫폼 구분',
  TITLE_PLACEHOLDER: '분류명을 입력해주세요.',
  DISPLAYABLE: '노출여부',
  DISPLAYABLE2: '전시상태',
  ORDER_SETTING: '메인분류 진열 설정',
  DISPLAY_TYPE: '디스플레이 유형',
  DISPLAY_CNT: '상품노출 개수',
  ACROSS: '가로',
  VERTICAL: '세로',
  ORDER_PRODUCT: '진열상품',
  PRODUCT_ORDER_SETTING: '진열상품 설정',
  ORDER: '진열순서',
  ORDER_AUTO: '자동진열',
  REGISTER_ORDER: '최신등록순',
  SALE_ORDER: '판매량순',
  BEST_REVIEW_ORDER: '상품후기순',
  ORDER_MANUAL: '수동진열',
  ORDER_PRODUCT_SELECT: '진열상품 선택',
  DELETE_SELECT: '선택 삭제',
  PRODUCT_SEARCH: '상품 조회',
  ORDER_CATEGORY_SELECT: '진열 카테고리 선택',
  RECOMMEND_PRODUCT_ORDER_SETTING: '추천상품 진열 설정',
  TOOLTIP_HTML:
    '상품은 최대 500개까지 선택할 수 있습니다.<br />500개를 초과하여 상품 선택 시 기존 선택된 상품이 자동 삭제됩니다.',
  EXCEPT_PRODUCT: '제외상품',
  NO_ORDER_PRODUCT: '등록된 진열상품이 없습니다.',
  ALERT1: '개별상품은 제외상품 설정을 할수 없습니다.',
  ALERT2: '삭제할 상품을 선택해주세요',
  CONFIRM1: '선택한 {length}개의 상품을 삭제하시겠습니까?',
  CONFIRM2: '선택한 상품을 삭제하시겠습니까?',
  ALERT3: '쇼핑몰을 선택해 주세요',
  ALERT4: '분류명을 입력해 주세요',
  PRE_APPROVAL_STATUS: '승인완료이전',
  ON_PRE_SALE: '예약판매중',
  WAITING_SALE: '판매대기',
  ON_SALE: '판매중',
  END_SALE: '판매종료',
  GALLERY: '갤러리형',
  LIST: '리스트형',
  CART: '장바구니형',
  SIMPLE_IMAGE: '심플 이미지형',
  PRODUCT_MOVE: '상품 이동형',
  SWIPE: '스와이프형',
  ALL_PRODUCT: '전체 상품',
  CATEGORY: '카테고리',
  PRODUCT: '개별상품',
  DISPLAY_Y: '설정함',
  DISPLAY_N: '설정 안함',
  MESSAGE_1: '상품을 선택해주세요.',
  MESSAGE_2: '선택한 상품을',
  MESSAGE_3: '선택한 상품을 삭제 하시겠습니까?',
  UPDATE: '수정',
  MALL_NAME: '쇼핑몰',
  REGISTER_YMDT: '등록일/수정일',
  PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  APPLIED_IMMEDIATE_DISCOUNT_PRICE: '즉시할인가',
  SALE_STATUS_TYPE: '판매상태',
  SALE_SETTING_STATUS_TYPE: '판매설정',
  DISPLAYABLE_Y: '노출함',
  DISPLAYABLE_Y2: '전시함',
  DISPLAYABLE_N: '노출안함',
  DISPLAYABLE_N2: '전시 안 함',
  SOLD_OUT: '품절상태',
  SOLD_OUT_Y: '품절',
  SOLD_OUT_N: '판매가능',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION_SALE: '판매금지',
  ALERT5: '메인분류 진열상품을 선택해주세요.',
  ALERT6: '추천상품 진열상품을 선택해주세요.',
  ALERT7: '노출개수는 최대 50까지 설정 가능합니다.',
  ADD_PRODUCT_SUCCESS_MESSAGE: '성공 {successProducts}건 : 대상상품 추가 완료\n',
  ADD_PRODUCT_FAIL_MESSAGE: '실패 {prohibitionProducts}건 : 판매금지 상품\n',
  ADD_PRODUCT_FAIL2_MESSAGE: '실패 {addedProducts}건 : 이미 선택된 상품\n',
});
