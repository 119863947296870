






import { Vue, Component } from 'vue-property-decorator';
import SearchForm from '@/components/partner/Settlement/SearchForm.vue';

@Component({
  components: {
    SearchForm,
  },
})
export default class Settlements extends Vue {}
