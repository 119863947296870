

































































































































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { sendQueryString } from '@/utils/query';
import { FocusTabName, OrderListTypes } from '@/types/order';
import { getDefaultOrderStatusTypes, getDefaultParams } from '@/components/shipping/order/mixins/searchQuery';
import {
  searchFormOptions,
  SortDeliveryNoOptionValues,
  SortOrderNoOptionValues,
} from '@/components/shipping/order/mixins/searchOptions';
import RadioGroup from '@/components/common/RadioGroup.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import SortTypeSelect from '@/components/shipping/order/SortTypeSelect.vue';
import DelayCount from '@/components/shipping/order/DelayCount.vue';
import OrderSearchKeywordInput from '@/components/common/input/OrderSearchKeywordInput.vue';
import { Getter, namespace } from 'vuex-class';
import { OrderParams } from '@/components/shipping/order/mixins/searchOptions.type';
import { OptionData } from '@/helpers/type';
import {
  DeliveryCompany,
  GetMemberGradesRequest,
  PreviousOrdersMall,
  Grade,
  Mall,
  NCPResponse,
} from 'ncp-api-supporter';
import { mall } from '@/utils/mall';
import { i18n } from '@/main';
import { orderListTypes as orderListTypeConsts } from '@/const/order';
import _ from 'underscore';
import { DefaultRequestParams } from '@/const/finderOptions';
import { addDay, addMonth, getToday } from '@/utils/dateFormat';
import PartnerFinder from '@/components/common/input/PartnerFinder.vue';
import { PartnerType } from '@/types';
import { PARTNER_TYPE } from '@/const/common';

const orderStore = namespace('order');
const partnerStore = namespace('partner');

@Component({
  components: {
    PartnerFinder,
    MallSelect,
    SearchButton,
    SelectBox,
    DateRangePicker,
    CheckBoxGroup,
    SortTypeSelect,
    DelayCount,
    TextInput,
    OrderSearchKeywordInput,
    RadioGroup,
  },
})
export default class SearchForm extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  @Getter('mall/getPreviousMalls') private previousMalls!: PreviousOrdersMall[];
  @orderStore.Action('setSelectedMall') setSelectedMallAction;
  @partnerStore.Getter('getPartnerNo')
  private readonly partnerNo: number;
  @partnerStore.Getter('partnerType')
  private readonly partnerType: PartnerType;
  @partnerStore.Action('resetPartnerState')
  private readonly resetPartnerState: () => void;

  @Prop({ required: true }) private readonly orderListTypes!: OrderListTypes;
  @Prop({ required: true }) public orderSearchType!: FocusTabName;
  @Prop({ default: false, required: false }) private isDelayOrder?: boolean;
  @Prop({ default: false, required: false }) private orderStatusTypes?: boolean;
  @Prop({ default: false, required: false }) private invoiceNoRegistered?: boolean;
  @Prop({ default: false, required: false }) private includesHoldDelivery?: boolean;

  @Ref('mallSelect')
  private readonly mallSelectRef!: MallSelect;
  @Ref('orderTabSortSelect')
  private orderTabSortSelectRef: SortTypeSelect;
  @Ref('deliveryTabSortSelect')
  private deliveryTabSortSelectRef: SortTypeSelect;
  private isOnlyOneMall = mall.isOnlyOneMall;
  private searchFormOptions = searchFormOptions(this.orderListTypes);
  private params: OrderParams = getDefaultParams(this.orderListTypes, this.$route);
  private statusRadioTypeValue = 'ORDER';
  private tabLabel: FocusTabName = this.orderSearchType;
  private delaySummary = {};
  private mallNOsArr = [];
  private initMallNos = '';

  @Watch('$route.query')
  setEmptyQuery() {
    if (_.isEmpty(this.$route.query)) {
      this.resetParams();
    }
  }

  @Watch('statusRadioTypeValue')
  setDefaultClaimStatus() {
    // 정상주문 검색일 때 claimStatusTypes를 지우는 함수
    if (this.statusRadioTypeValue === 'ORDER') {
      delete this.params.claimStatusTypes;
      return;
    }

    this.params.claimStatusTypes = DefaultRequestParams.claimStatusTypes.join(',');
  }

  private get delayCountTitle(): string | string[] {
    const title = {
      [orderListTypeConsts.PAY_DONE]: '접수지연',
      [orderListTypeConsts.PRODUCT_PREPARE]: '발송지연',
      [orderListTypeConsts.DELIVERY_PREPARE]: '네이버페이 발송지연',
      [orderListTypeConsts.HOLD_DELIVERY]: ['배송보류 처리', '배송보류 지연'],
    };

    return title[this.orderListTypes];
  }

  private get showDelayCount(): boolean {
    const delayCountTarget: OrderListTypes[] = [
      orderListTypeConsts.PAY_DONE,
      orderListTypeConsts.PRODUCT_PREPARE,
      orderListTypeConsts.DELIVERY_PREPARE,
      orderListTypeConsts.HOLD_DELIVERY,
    ];

    return delayCountTarget.includes(this.orderListTypes);
  }

  private get showDateSearchOptions(): boolean {
    const displayTargets: OrderListTypes[] = [
      orderListTypeConsts.PAY_DONE,
      orderListTypeConsts.BUY_CONFIRM,
      orderListTypeConsts.PRODUCT_PREPARE,
      orderListTypeConsts.DELIVERY_PREPARE,
      orderListTypeConsts.DELIVERY_DONE,
      orderListTypeConsts.HOLD_BY_RESERVATION,
      orderListTypeConsts.HOLD_DELIVERY,
      orderListTypeConsts.DELIVERY_ING,
    ];

    return displayTargets.includes(this.orderListTypes);
  }

  private get isDeliveryPrepared(): boolean {
    return this.orderListTypes === orderListTypeConsts.DELIVERY_PREPARE;
  }

  private get isHoldByReservation(): boolean {
    return this.orderListTypes === orderListTypeConsts.HOLD_BY_RESERVATION;
  }

  private get isHoldDelivery(): boolean {
    return this.orderListTypes === orderListTypeConsts.HOLD_DELIVERY;
  }

  private get isPrevious(): boolean {
    return this.orderListTypes === orderListTypeConsts.PREVIOUS;
  }

  private get isPayFail(): boolean {
    return this.orderListTypes === orderListTypeConsts.PAY_FAIL;
  }

  @Watch('$route.query.tabLabel')
  private updateQuery() {
    this.toggleTabLabel();
  }

  @Watch('partnerNo')
  private changePartnerNo(newPartnerNo) {
    this.params.partnerNo = newPartnerNo;
  }

  private toggleTabLabel() {
    this.tabLabel = this.$route.query.tabLabel ? (this.$route.query.tabLabel as FocusTabName) : this.tabLabel;
    if (
      (this.orderListTypes === 'ORDER_LIST' || this.orderListTypes === 'DELIVERY_PREPARE') &&
      !this.$route.query.tabLabel
    ) {
      this.tabLabel = 'deliveryTab';
    }
    this.params.sortType = '';
    this.params.desc = false;
  }

  // TODO: 정리필요
  search(delay?, holdDelivery?) {
    if (!this.validateParams()) {
      return;
    }

    const routeQuery = { ...this.params };

    routeQuery.tabLabel = this.$route.query?.tabLabel?.toString() ?? this.tabLabel;
    routeQuery.delay = delay === true;
    routeQuery.naverDeliveryDelay = this.isDeliveryPrepared && delay === true;
    routeQuery.holdDelivery = this.isHoldDelivery || holdDelivery === true;
    routeQuery.claimType = this.statusRadioTypeValue === 'ORDER' ? 'NONE' : undefined;
    routeQuery.orderStatusTypes =
      this.statusRadioTypeValue === 'ORDER'
        ? routeQuery.orderStatusTypes
        : getDefaultOrderStatusTypes(this.orderListTypes);

    if (this.isHoldByReservation) {
      delete routeQuery.claimStatusTypes;
    }
    if (this.isPrevious) {
      routeQuery.claimType = this.statusRadioTypeValue;
    }

    sendQueryString(this, routeQuery, false, true);
  }

  private validateParams(): boolean {
    // 예약주문일 땐 클레임 상태 유효성 검증 제외
    if (!this.isHoldByReservation && this.params.claimStatusTypes?.length === 0) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_CLAIM_STATUS'));
      return false;
    }

    if (this.params.platformTypes.length === 0) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_PLATFORM_TYPE'));
      return false;
    }

    if (this.params.payTypes.length === 0) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_PAY_TYPE'));
      return false;
    }

    const validationTargets = ['ORDER_LIST', 'HOLD_DELIVERY', 'HOLD_BY_RESERVATION']; // 주문상태 validation 대상리스트
    if (validationTargets.includes(this.orderListTypes) && this.params.orderStatusTypes.length === 0) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_ORDER_STATUS'));
      return false;
    }

    if (this.partnerType === PARTNER_TYPE.PARTNER && !this.partnerNo) return false;

    return true;
  }

  private resetParams(): void {
    this.params = { ...getDefaultParams(this.orderListTypes) };
    if (this.isPrevious) {
      this.isOnlyOneMall = this.previousMalls.length === 1;
      this.params.mallNos = this.previousMalls[0].mallNo;
      this.params.claimType = 'ORDER';
      this.changeUsed(this.params.claimType);
    }
    this.resetSortType();
    this.resetPartnerState();
  }

  private resetSortType() {
    const defaultValue =
      this.tabLabel === 'orderTab'
        ? SortOrderNoOptionValues.SORT_TYPE_ORDER_NO_ASC
        : SortDeliveryNoOptionValues.SORT_TYPE_DELIVER_NO_ASC;

    if (this.tabLabel === 'orderTab') {
      this.orderTabSortSelectRef.sortOptionValue = defaultValue;
    } else {
      this.deliveryTabSortSelectRef.sortOptionValue = defaultValue;
    }
  }

  private changeUsed(used: string): void {
    this.statusRadioTypeValue = used;
  }

  private validateMallNoSelected() {
    if (!this.params.mallNos) {
      alert('쇼핑몰을 먼저 선택해주세요.');
    }
  }

  @Watch('params.mallNos')
  private resetDelaySummary() {
    this.fetchDelaySummary();
  }
  @Watch('params.mallNos')
  private async fetchMemberGrades(): Promise<void> {
    if (!this.params.mallNos) {
      this.setMemberGradeOptions([]);
      return;
    }
    const request: GetMemberGradesRequest = {
      params: {
        mallNo: Number(this.params.mallNos),
      },
    };

    const { data }: NCPResponse<Grade[]> = await this.$api.getMemberGrades(request);
    const gradeOptions = data
      .filter(({ used }) => used === true)
      .map(grade => ({
        value: grade.no,
        name: grade.name,
      }));

    this.setMemberGradeOptions(gradeOptions);
  }
  private setMemberGradeOptions(memberGradeOptions: OptionData<number>[]) {
    const DEFAULT = 0;
    this.searchFormOptions.memberGradeOptions = [
      this.searchFormOptions.memberGradeOptions[DEFAULT],
      ...memberGradeOptions,
    ];

    this.params.memberGradeNo = DEFAULT;
  }

  private async fetchDeliveryCompanies(): Promise<void> {
    const { data }: NCPResponse<DeliveryCompany[]> = await this.$api.getDeliveryCompanies({
      params: { countryCd: 'KR' },
    });

    const deliveryCompanyOptions = data.map(deliveryCompany => ({
      name: deliveryCompany.label,
      value: deliveryCompany.deliveryCompanyType,
    }));

    this.setDeliveryCompanyOptions(deliveryCompanyOptions);
  }

  private setDeliveryCompanyOptions(deliveryCompanyOptions: OptionData<string>[]) {
    this.searchFormOptions.deliveryCompanyOptions = [...deliveryCompanyOptions];

    this.setDefaultDeliveryCompany();
  }

  private setDefaultDeliveryCompany() {
    this.params.deliveryCompanyType = '';

    const DEFAULT = '';
    const deliveryCompanyTypeQuery = this.$route.query?.deliveryCompanyType;

    if (!deliveryCompanyTypeQuery) {
      this.params.deliveryCompanyType = DEFAULT;
    }

    const hasDeliveryCompany = this.searchFormOptions.deliveryCompanyOptions.find(
      deliveryCompanyOption => deliveryCompanyOption.value === deliveryCompanyTypeQuery,
    );

    this.params.deliveryCompanyType = hasDeliveryCompany ? deliveryCompanyTypeQuery : DEFAULT;
  }

  private getTheMalls() {
    this.malls.map(item => {
      this.mallNOsArr.push(item.mallNo);
      return { option: item.mallName, value: item.mallNo };
    });
    this.initMallNos = this.mallNOsArr.join(',');
  }

  private async fetchDelaySummary(): Promise<void> {
    if (!this.showDelayCount) {
      return;
    }

    const mallNos = this.params.mallNos === '' ? mall.allMallNoString : this.params.mallNos.toString();

    const request = { params: { mallNos, orderStatusTypes: this.orderListTypes } };
    let res;
    switch (this.orderListTypes) {
      case 'DELIVERY_PREPARE':
        res = await this.$api.getOrderOptionsNaverDelaySummary({ params: { mallNos: request.params.mallNos } });
        break;

      case 'HOLD_DELIVERY':
        res = await this.$api.getOrderOptionsDeliveryHoldSummary(request);
        break;

      default:
        res = await this.$api.getOrderOptionsMallDelaySummary(request);
        break;
    }

    this.delaySummary = res.data;
  }

  private handleDelaySearch() {
    this.search(true);
  }

  private handleHoldDeliverySearch(delay: boolean) {
    if (delay) {
      this.params.startYmd = addMonth(new Date(), -1);
      this.params.endYmd = addDay(new Date(), -4);
    } else {
      this.params.startYmd = addMonth(new Date(), -1);
      this.params.endYmd = getToday();
    }

    this.search(delay, true);
  }

  async mounted() {
    this.params = Object.assign(this.params, this.$route.query);
    if (this.isPrevious) {
      this.isOnlyOneMall = this.previousMalls.length === 1;
      this.params.mallNos = this.previousMalls[0].mallNo;
    }
    await Promise.all([this.fetchDelaySummary(), this.fetchMemberGrades(), this.fetchDeliveryCompanies()]);
  }

  private onChangeMallSelect() {
    this.setSelectedMallAction({ mallNo: this.params.mallNos as string });
  }
}
