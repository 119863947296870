






















import { Vue, Component, Model, Prop, Ref } from 'vue-property-decorator';
import { RadioBoxGroupOption, ValueType } from '@/helpers/type';
import unsavedDetector from '@/directives/input/unsavedDetector';
@Component({
  directives: { unsavedDetector },
})
export default class RadioGroup extends Vue {
  /*
   * 이 컴포넌트는 프리미엄 어드민의 CompRadioBox에서 유래되었습니다.
   * */
  @Prop({ required: true }) private options!: RadioBoxGroupOption<ValueType>;
  @Prop() private disabled?: boolean;
  @Prop({ required: false })
  private readonly labelStyle;

  @Model('change') readonly value!: ValueType;
  @Ref() radios: HTMLInputElement[];

  get modelValue() {
    if (this.value === undefined) return '';
    return this.value;
  }
  set modelValue(val) {
    this.$emit('change', val, this.value, this.options);
  }

  public focus(): void {
    const FIRST_INDEX = 0;
    this.radios[FIRST_INDEX].focus();
  }
}
