var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isOpenCosPopup && _vm.cosPopup)?_c('div',{ref:"cosPopupWrapElementRef",staticClass:"cos-popup-wrap"},[_c('div',{ref:"cosPopupElementRef",staticClass:"cos-popup",attrs:{"id":"cosPopup"}},[_c('ul',{ref:"cosPopupUlElementRef",staticClass:"cos-popup-list"},_vm._l((_vm.cosPopup.posts),function(post){return _c('li',{key:post.postNo,staticStyle:{"overflow":"hidden"}},[_c('div',{domProps:{"innerHTML":_vm._s(post.bodyText)}})])}),0),_c('div',{staticClass:"action-btn"},[_c('div',{staticClass:"page-btn"},[(_vm.cosPopup.posts.length > 1)?_c('div',{staticClass:"flex-wrap"},[_c('a',{ref:"prevArrowRef",staticClass:"btn_prev slick-arrow",attrs:{"href":"#cosPopup"}}),_c('span',{staticClass:"page-per"},[_c('em',{staticStyle:{"color":"#1d2939"}},[_vm._v(_vm._s(_vm.cosPopupIndex))]),_vm._v("  / "+_vm._s(_vm.cosPopup.posts.length)+" ")]),_c('a',{ref:"nextArrowRef",staticClass:"btn_next slick-arrow",attrs:{"href":"#cosPopup"}})]):_vm._e()]),_c('div',{staticClass:"close-btn"},[_c('button',{staticClass:"expire-popup-btn",on:{"click":function($event){return _vm.closeModal({
              name: _vm.sectionCode,
              isSetCookie: true,
              key: _vm.sectionCode,
              value: 'close',
              expireDate: _vm.cosPopup.cookieAliveDay,
            })}}},[_c('input',{attrs:{"type":"checkbox"}}),_c('span',[_vm._v(" "+_vm._s(_vm.cosPopup.cookieAliveDay ? _vm.$t('HOME.POPUP.NOT_SHOW_N_DAY', [_vm.cosPopup.cookieAliveDay]) : _vm.$t('HOME.POPUP.NOT_TODAY_SHOW'))+" ")])]),_c('button',{staticClass:"close-popup-btn",on:{"click":function($event){return _vm.closeModal({
              name: _vm.sectionCode,
              isSetCookie: false,
              key: _vm.sectionCode,
              value: 'close',
            })}}},[_vm._v(" "+_vm._s(_vm.$t('HOME.POPUP.CLOSE'))+" ")])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }