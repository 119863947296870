










import { Component } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import PartnerNoticeForm, { NoticeForm } from '@/views/popups/partner/PartnerNoticeForm.vue';
import { GetNoticeByNoticeNo, PutNoticeByNoticeNoRequest } from 'ncp-api-supporter';

@Component({
  components: { PartnerNoticeForm },
})
export default class PartnerNoticeFormModificationPopup extends WindowPopupMainVue {
  private initialNoticeForm = {} as NoticeForm;

  private fetchNotice() {
    const request = {
      pathParams: {
        noticeNo: `${this.data.noticeNo}`,
      },
    };

    this.$api.getNoticeByNoticeNo(request).then(({ data }) => {
      const {
        noticeAttachFiles,
        noticeCategory,
        noticeContent,
        noticeTitle,
        opened,
        searchContent,
        mall,
        writer,
        createdDateTime,
        noticeNo,
        readCount,
      } = data as GetNoticeByNoticeNo; // supporter 에 GetNoticeByNoticeNo[] 로 정의되어있음. 실제로 데이터는 객체임...

      this.initialNoticeForm = {
        mallNo: undefined,
        noticeAttachFiles,
        noticeCategory,
        noticeContent,
        noticeTitle,
        opened,
        searchContent,
        mall,
        writer,
        createdDateTime,
        noticeNo,
        readCount,
      };
    });
  }

  private modifyNotice(noticeForm: PutNoticeByNoticeNoRequest['data']) {
    const request = {
      params: {
        noticeNo: this.data.noticeNo,
      },
      data: noticeForm,
    };
    this.$api.PutNoticeByNoticeNo(request).then(() => {
      alert('게시글이 수정되었습니다.');
      this.onPositiveClick();
    });
  }

  created() {
    this.fetchNotice();
  }
}
