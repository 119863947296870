import { i18n } from '@/main';

// 더블체크 대표운영자
export const hasAdminName = (adminName: string, onFocus: Function) => {
  if (adminName.length) return true;

  alert(i18n.t('PARTNER.ERR_ADMIN_NAME_NULL'));
  onFocus();
  return false;
};

const isInvalidDomain = (domain: string) => {
  const invalidDotPosition = domain.indexOf('.') === 0 || domain.lastIndexOf('.') === domain.length - 1;
  const noDot = domain.indexOf('.') === -1;
  return invalidDotPosition || noDot;
};

// 더블체크 이메일
export const hasEmail = (email: string, onFocus: Function) => {
  const [id, domain] = email.split('@');

  if (!email || !id || !domain) alert(i18n.t('PARTNER.ERR_EMAIL_FORMAT'));

  if (!id || !email) {
    onFocus('id');
    return false;
  }

  if (!domain) {
    onFocus('domain');
    return false;
  }

  if (isInvalidDomain(domain)) {
    alert(i18n.t('PARTNER.ERR_EMAIL_FORMAT'));
    onFocus('domain');
    return false;
  }

  return true;
};

// 더블체크 거래 쇼핑몰
export const hasMallNo = (mallNo: string) => {
  if (mallNo) return true;

  alert(i18n.t('PARTNER.ERR_MALL_NO_NULL'));
  return false;
};

// 더블체크 입점 계약서
export const doubleCheckEntryContract = (entryContractContent: string, useEntryContract: boolean) => {
  // 입점계약서를 사용하지 않으면 유효성 검사를 할 필요가 없음.
  if (!useEntryContract) return true;

  if (!entryContractContent) {
    alert(i18n.t('PARTNER.ERR_CONTRACT_NULL'));
  }

  return Boolean(entryContractContent);
};

// 더블체크 담당MD
export const hasMd = (mdNo: number) => {
  if (mdNo) return true;

  alert(i18n.t('PARTNER.ERR_MD_NO_NULL'));
  return false;
};

// 데블체크 수수료율
export const hasFeeRate = (feeRate: string, onFocus: Function) => {
  if (feeRate.length) return true;

  alert(i18n.t('PARTNER.ERR_RATE_NULL'));
  onFocus();
  return false;
};
