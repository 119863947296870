














































































































import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import DateRangePicker from '@/components/common/DateRangePicker.vue';

import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';

import { Mall } from 'ncp-api-supporter';
import { Getter } from 'vuex-class';
import { getArticleListInitQuery, searchType } from '@/const/contents/board';

//import { sendQueryString } from '@/utils/query';
import { throwMessagePopup } from '@/helpers/popup';
import ArticleListMixin from '@/components/board/basic/mixins/ArticleListMixin';
import { mall } from '@/utils/mall';

@Component({
  components: {
    SearchButton,
    DateRangePicker,
    SelectBox,
    TextInput,
    MallSelect,
  },
})
export default class ArticleList extends Mixins(ArticleListMixin) {
  @Getter('mall/getMalls') private malls!: Mall[];
  private boardNos: { name: string; value: number }[] = [];
  private selectType = searchType;
  @Ref() private readonly keywordRef!: TextInput;
  @Ref() private readonly mallSelect!: MallSelect;
  @Ref() private readonly boardSelect!: SelectBox;
  private isOnlyOneMall = mall.isOnlyOneMall;

  async created() {
    // query parameters reset
    this.initQuery();

    // board select-box init
    await this.initBoardSelectbox();
  }

  @Watch('$route.query.mallNo')
  private onQueryStringChanged(toVal: number, fromVal: number): void {
    /** when clicking menu [게시글 관리] again after searching action
     *  need to reset searching condition on top page.
     *  for it's same as reseted list result on list contents page.
     */
    if (!toVal && fromVal) {
      this.reSet();
    }
  }

  /**
   * --- init values of parameters ------
   */
  private async initQuery(): Promise<void> {
    if (this.$route.query) {
      Object.assign(this.query, this.$route.query);
    }
  }
  /**
   * --- init values of board select-box ------
   */
  private async initBoardSelectbox(): Promise<void> {
    this.boardNos = await this.getSelectBoardNos(this.query.mallNo);
    if (this.query.boardNo === -1 && this.boardNos.length > 0) {
      this.query.boardNo = Number(this.boardNos[0].value);
    }
  }

  /**
   * ----- form validation -----
   */
  private checkValidation(): boolean {
    // keyword required
    if (this.query.searchType !== 'ALL' && !this.query.keyword) {
      throwMessagePopup(this.$t('BOARD.BASIC.ARTICLE.ENTER_SEARCH_WORD'), true, null, true).then(() => {
        this.keywordRef.focus();
      });
      return false;
    }
    // mall select-box required
    if (Number(this.query.mallNo) <= 0) {
      throwMessagePopup(this.$t('BOARD.BASIC.ARTICLE.ALERT_MALL_NO_SELECT'), true, null, true).then(() => {
        this.mallSelect.focus();
      });
      return false;
    }
    // board select-box required
    if (Number(this.query.boardNo) <= 0) {
      throwMessagePopup(this.$t('BOARD.BASIC.ARTICLE.ALERT_BOARD_NO_SELECT'), true, null, true).then(() => {
        this.boardSelect.focus();
      });
      return false;
    }
    return true;
  }
  /**
   * --- mall select-box changed logic.----
   */
  private changeMall(): void {
    this.query.boardNo = -1;
    this.initBoardSelectbox();
  }
  /**
   * re-set search condition.
   */
  private reSet(): void {
    this.query = { ...this.query, ...getArticleListInitQuery() };
    this.changeMall();
  }
  /**
   * Modify query.noticed value.
   * notice check-box change event.
   */
  private setCheckboxVal(): void {
    if (!this.query.noticed) {
      this.query.noticed = null;
    }
  }
}
