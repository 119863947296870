





































































import { Component, Mixins } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SmsAutomationGetTemplateVue from '@/views/contents/member/sms/mixins/SmsAutomationGetTemplate.vue';
import {
  PutSmsTemplatesAutoNightData,
  PutSmsTemplatesAutoNightRequest,
  GetSmsAutoTemplates,
  PutKakaoTemplateUseNightSettingRequest,
} from 'ncp-api-supporter';

@Component
export default class SmsManager extends Mixins(WindowPopupMainVue, SmsAutomationGetTemplateVue) {
  private checkAll: boolean[] = [];

  private mapRequestData() {
    return this.templateGroups.flatMap(({ templates }) => {
      return templates.map(({ type, nightUsable }) => {
        if (type === undefined || nightUsable === undefined) return;
        return { templateType: type, nightUsable: !nightUsable };
      });
    });
  }

  private get smsNightSetting(): PutSmsTemplatesAutoNightData[] {
    return this.mapRequestData();
  }

  private get kakaoNightSetting(): PutKakaoTemplateUseNightSettingRequest['templatesNight'] {
    return this.mapRequestData() as PutKakaoTemplateUseNightSettingRequest['templatesNight'];
  }

  private getTemplateByName(name: string): GetSmsAutoTemplates[] {
    return this.templateGroups.filter(template => template.name === name)[0].templates;
  }

  private checkAllHandler(name: string, checkedIndex: number) {
    this.getTemplateByName(name).forEach((_, templateIndex) => {
      this.smsNightSetting.forEach(() => {
        this.checkAll[checkedIndex] = !!this.checkAll[checkedIndex];
        this.templateGroups[checkedIndex].templates[templateIndex].nightUsable = this.checkAll[checkedIndex];
      });
    });
  }

  private async saveChangeNight(): Promise<void> {
    if (this.data.type === 'sms') {
      const request: PutSmsTemplatesAutoNightRequest = {
        params: { mallNo: this.data.mallNo },
        data: { night: this.smsNightSetting },
      };

      try {
        await this.$api.putSmsTemplatesAutoNight(request);
        this.onPositiveClick();
      } catch (err) {
        console.error(err);
        throw err;
      }
    } else {
      const request: PutKakaoTemplateUseNightSettingRequest = {
        mallNo: this.data.mallNo,
        templatesNight: [...this.kakaoNightSetting],
      };
      try {
        await this.$api.putKakaoTemplateUseNightSetting(request);
        this.onPositiveClick();
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  }

  private async resetValue() {
    await this.getTemplateGroups(this.data.type, this.data.mallNo);
    this.templateGroups = this.templateGroups.filter(({ type }) => type !== 'MEMBER');
    this.checkAll = this.templateGroups.map(({ templates }) => {
      return templates.every(({ nightUsable }) => nightUsable === true);
    });
  }

  created() {
    this.resetValue();
  }
}
