export default Object.freeze({
  // SETTLEMENT_LIST_
  TITLE: '정산 현황 조회',
  CONFIRMED_HISTORY: '확정내역',
  UNCONFIRMED: '미확정내역',
  ESTIMATED_AMT: '정산예상금액',
  PERIOD_TYPE_MONTH: '월정산',
  PERIOD_TYPE_WEEK: '주정산',
  PERIOD_TYPE_DAY: '일정산',
  STATE_PENDING: '미확정',
  STATE_CONFIRM: '확정',
  GRID_TITLE: '정산예상금액 상세',
  SALE_METHOD_PURCHASE: '사입',

  HEADER_DATE: '정산예정일',
  HEADER_PERIOD_TYPE: '정산유형',
  HEADER_STATE: '확정여부',
  HEADER_PERIOD: '집계기간',
  HEADER_PARTNER_NAME: '파트너',
  HEADER_PARTNER_TYPE: '국내/해외',
  HEADER_SALES_AMT: '판매액(A)',
  HEADER_COMMISSION_AMT: '판매수수료(B)',
  HEADER_DELIVERY_AMT: '배송비(C)',
  HEADER_PARTNER_DISCOUNT_AMT: '추가/상품할인파트너부담(D)',
  HEADER_DEDUCTED_AMT: '공제금액(E)',
  HEADER_AMT: '정산예상금액(F=A-B+C-D-E)',
  HEADER_BANK_NAME: '은행명',
  HEADER_TRADE_BANK_ACCOUNT: '계좌번호',
  HEADER_TRADE_BANK_DEPOSITOR_NAME: '예금주',
  HEADER_EXCEL_BTN: '상세보기',
});
