












import { Vue, Component } from 'vue-property-decorator';
import PromotionBanner from '@/components/addition/PromotionBanner.vue';

@Component({
  components: { PromotionBanner },
})
export default class AdditionMain extends Vue {}
