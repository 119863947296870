var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layerpopup-bg-type width700",staticStyle:{"width":"682px"}},[_c('modal-header',{attrs:{"title":_vm.$t('GRID.LAYER.TITLE')},on:{"click:close":function($event){return _vm.$emit('click:close')}}}),_c('div',{staticClass:"ly-cont"},[_c('div',{staticClass:"grid-set-wrap"},[_c('div',{staticClass:"left-wrap"},[_c('div',{staticClass:"grid-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('GRID.LAYER.ALL_ITEM')))]),_c('select-box',{staticClass:"small",staticStyle:{"width":"100px"},attrs:{"name":"sort","data":_vm.sortOption},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1),_c('div',{ref:"scrollBoxLeft",staticClass:"grid-list",staticStyle:{"overflow-y":"auto"}},[_c('ul',{staticStyle:{"overflow":"initial"}},_vm._l((_vm.orderedColumnOrders),function(ref){
var displayName = ref.displayName;
var columnName = ref.columnName;
var visible = ref.visible;
return _c('li',{key:columnName},[_c('button',{class:{ disabled: visible, selected: _vm.isSelected(columnName, 'left') },attrs:{"disabled":visible},on:{"click":function($event){return _vm.onClickItem($event, columnName, 'left')},"dblclick":function($event){return _vm.onVisibleStateToggle(true)}}},[_vm._v(" "+_vm._s(displayName)+" ")])])}),0)])]),_c('div',{staticClass:"center-wrap"},[_c('button',{staticClass:"addBtn",attrs:{"type":"button"},on:{"click":function($event){return _vm.onVisibleStateToggle(true)}}}),_c('button',{staticClass:"delBtn",attrs:{"type":"button"},on:{"click":function($event){return _vm.onVisibleStateToggle(false)}}})]),_c('div',{staticClass:"right-wrap"},[_c('div',{staticClass:"grid-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('GRID.LAYER.DISPLAY_ITEM')))]),_c('div',{staticClass:"control-wrap"},[_c('button',{staticClass:"treeBtn type-downn",on:{"click":function($event){$event.preventDefault();return _vm.onMove('last')}}}),_c('button',{staticClass:"treeBtn type-down-2",on:{"click":function($event){$event.preventDefault();return _vm.onMove('next')}}}),_c('button',{staticClass:"treeBtn type-up-2",on:{"click":function($event){$event.preventDefault();return _vm.onMove('prev')}}}),_c('button',{staticClass:"treeBtn type-upp",on:{"click":function($event){$event.preventDefault();return _vm.onMove('first')}}})])]),_c('div',{ref:"scrollBoxRight",staticClass:"grid-list",staticStyle:{"overflow-y":"auto"}},[_c('ul',{staticStyle:{"overflow":"initial"}},_vm._l((_vm.visibleColumnOrders),function(ref){
var displayName = ref.displayName;
var columnName = ref.columnName;
return _c('li',{key:columnName},[_c('button',{class:{
                  disabled: _vm.visibleFixedColumnNames && _vm.visibleFixedColumnNames.includes(columnName),
                  selected: _vm.isSelected(columnName, 'right'),
                },attrs:{"disabled":_vm.visibleFixedColumnNames && _vm.visibleFixedColumnNames.includes(columnName)},on:{"click":function($event){return _vm.onClickItem($event, columnName, 'right')},"dblclick":function($event){return _vm.onVisibleStateToggle(false)}}},[_vm._v(" "+_vm._s(displayName)+" ")])])}),0)])])]),_c('span',{staticClass:"description text-red"},[_vm._v(_vm._s(_vm.$t('GRID.LAYER.WARN_SETTING')))]),_c('span',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('GRID.LAYER.INFO_SETTING')))]),(_vm.isShippingDomain)?_c('order-detail-exposure-setting',{attrs:{"detail-open-options":_vm.data.detailOpenOptions},on:{"update:detailOpenOptions":function($event){return _vm.$set(_vm.data, "detailOpenOptions", $event)},"update:detail-open-options":function($event){return _vm.$set(_vm.data, "detailOpenOptions", $event)}}}):_vm._e(),_c('div',{staticClass:"btn-wrap"},[_c('button',{staticClass:"btnDef type-white",on:{"click":function($event){return _vm.$emit('click:close')}}},[_vm._v(_vm._s(_vm.$t('CANCEL')))]),_c('button',{staticClass:"btnDef type-red",on:{"click":function($event){return _vm.$emit('confirm', { gridLayouts: _vm.gridLayouts, detailOpenOptions: _vm.detailOpenOptions })}}},[_vm._v(" "+_vm._s(_vm.$t('SETTING'))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }