














































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import DatePicker from 'tui-date-picker';
import { DEFAULT_DATE_RANGE, HOUR_SELECT_OPTIONS, DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { getToday, getStrDate, getUnitDigitStr } from '@/utils/dateFormat';
import { range } from 'underscore';
import { throwLoadingSpinner } from '@/helpers/loading';
import { OptionData } from '@/helpers/type';
import { DateCalendarOption } from '@/types';
import moment from 'moment';

export type DateRange = [Date, Date][];

@Component({
  components: { SelectBox },
})
export default class DateCalendar extends Vue {
  @Ref('selectedYmd')
  private readonly selectedYmdInput!: HTMLInputElement;

  @Prop({ default: false })
  private readonly disabled!: boolean;
  @Prop({ default: false })
  private readonly isReset!: boolean;
  // :isYmdType="true" is equals to :options="{dateType: 'Ymd'}"
  @Prop({ default: false })
  private readonly isYmdType!: boolean;
  @Prop({ default: 1 })
  private readonly everyMinute!: number; // {everyMinute}분 간격
  @Prop({ required: false })
  private readonly options: DateCalendarOption;
  @Prop({ required: false })
  private readonly placeholder: string;
  @Prop({ default: '100%' })
  private width!: string;
  @Prop({ default: false })
  private readonly resetAllToEmptyValue: boolean;

  private hoursOption = HOUR_SELECT_OPTIONS;
  private selectedYmd = getToday();
  private selectedHour = '00';
  private selectedMinute = '00';
  private selectDatepicker!: DatePicker; // Property 'addOpener' does not exist on type 'default'.
  private calendarInfo: DateCalendarOption = {
    dateType: 'YmdHm',
    name: 'calendar', // 한 페이지에 DateCalendar 2개 이상 사용 시 name 필수
    fromRanges: DEFAULT_DATE_RANGE.MIN,
    toRanges: DEFAULT_DATE_RANGE.MAX,
    selectedYmd: getToday(),
    selectedHour: '00',
    selectedMinute: '00',
  };

  private get minutesOption(): OptionData<string>[] {
    const size = Math.floor(60 / this.everyMinute);

    return range(0, size).map(value => {
      const option = getUnitDigitStr(value * this.everyMinute);
      return { name: option, value: option };
    });
  }

  private get selectedDateTime(): string {
    if (this.isYmdType) {
      return this.selectedYmd;
    }
    let tempDateTime = this.selectedYmd;
    switch (this.calendarInfo.dateType) {
      case 'Ymd':
        tempDateTime = this.calendarInfo.isEndDate
          ? `${this.selectedYmd} ${DEFAULT_TIME_RANGE.END}`
          : `${this.selectedYmd} ${DEFAULT_TIME_RANGE.START}`;
        break;
      case 'YmdH':
        tempDateTime = this.calendarInfo.isEndDate
          ? `${this.selectedYmd} ${this.selectedHour}:59:59`
          : `${this.selectedYmd} ${this.selectedHour}:00:00`;
        break;
      case 'YmdHm':
        tempDateTime = this.calendarInfo.isEndDate
          ? `${this.selectedYmd} ${this.selectedHour}:${this.selectedMinute}:59`
          : `${this.selectedYmd} ${this.selectedHour}:${this.selectedMinute}:00`;
        break;
    }
    return tempDateTime;
  }
  changeHour(newValue, oldValue) {
    this.checkAndChange('hour', newValue, oldValue);
  }
  changeMinute(newValue, oldValue) {
    this.checkAndChange('minute', newValue, oldValue);
  }
  checkAndChange(changeType: 'ymd' | 'hour' | 'minute', newValue?: string, oldValue?: string) {
    let changeFlg = true;
    if (this.calendarInfo.checkDate) {
      let changeValue = '';
      switch (changeType) {
        case 'ymd':
          changeValue = getStrDate(this.selectDatepicker.getDate());
          break;
        case 'hour':
        case 'minute':
          changeValue = newValue;
          break;
      }
      changeFlg = this.calendarInfo.checkDate({ changeType, changeValue });
    }

    if (changeFlg) {
      if (changeType === 'ymd') {
        this.selectedYmd = getStrDate(this.selectDatepicker.getDate());
      }
      this.$emit('changeSelectedDateTime', this.selectedDateTime, changeType);
    } else {
      switch (changeType) {
        case 'ymd':
          if (this.selectedYmd) {
            this.selectDatepicker.setDate(moment(this.selectedYmd).toDate());
          } else {
            this.resetValue(true);
          }
          break;
        case 'hour':
          this.$nextTick(() => {
            this.selectedHour = oldValue;
          });
          break;
        case 'minute':
          this.$nextTick(() => {
            this.selectedMinute = oldValue;
          });
          break;
      }
    }
  }

  @Watch('isReset', { immediate: false })
  public resetValue(reset: boolean) {
    if (!(this.isReset || reset)) return;
    if (this.resetAllToEmptyValue) {
      this.selectedYmd = '';
      this.selectedHour = '';
      this.selectedMinute = '';
      return;
    }
    if (this.calendarInfo.selectedYmd === '') {
      this.selectDatepicker.setNull();
    }
    this.selectedYmd = this.calendarInfo.selectedYmd;
    this.selectedHour = this.calendarInfo.selectedHour;
    this.selectedMinute = this.calendarInfo.selectedMinute;
  }

  public focus() {
    this.selectedYmdInput.focus();
  }

  public setValue(ymd?: string, hour?: string, minute?: string) {
    if (ymd) {
      this.selectedYmd = ymd;
      this.selectDatepicker.setDate(moment(ymd).toDate());
    }
    if (hour) {
      this.selectedHour = hour;
    }
    if (minute) {
      this.selectedMinute = minute;
    }
  }

  public setDatePicker() {
    /*
		* interface DatePickerOptions {
				date?: Date;
				language?: string;
				showToday?: boolean;
				showJumpButtons?: boolean;
				type?: CalendarType;
				usageStatistics?: boolean;
				input?: {
					element?: HTMLElement | string;
					format?: string;
				};
			}
		* */
    const selectableRanges: DateRange = [
      [moment(this.calendarInfo.fromRanges).toDate(), moment(this.calendarInfo.toRanges).toDate()],
    ];
    this.selectDatepicker = new DatePicker(`#selectYmdt_layer_${this.calendarInfo.name}`, {
      language: 'ko',
      date: moment(this.selectedYmd).toDate(),
      input: {
        element: `#selectedYmd_${this.calendarInfo.name}`,
        format: 'yyyy-MM-dd',
      },
      selectableRanges,
    });

    this.selectDatepicker.on('change', (): void => {
      if (!this.selectDatepicker) return;
      this.checkAndChange('ymd');
      this.selectDatepicker.close();
    });
    this.selectDatepicker.on('open', (): void => throwLoadingSpinner(true));
    this.selectDatepicker.on('close', (): void => throwLoadingSpinner(false));
  }

  @Watch('options.fromRanges')
  @Watch('options.selectedYmd')
  private setRangesOption(): void {
    this.calendarInfo.fromRanges = this.options.fromRanges;
    this.calendarInfo.selectedYmd = this.options.selectedYmd;

    if (!this.selectDatepicker) return;
    const dateRanges: DateRange = [
      [moment(this.calendarInfo.fromRanges).toDate(), moment(this.calendarInfo.toRanges).toDate()],
    ];
    this.selectDatepicker.setRanges(dateRanges);
    this.selectDatepicker.setDate(moment(this.calendarInfo.selectedYmd).toDate());
  }

  public unsetDatePicker() {
    if (!this.selectDatepicker) return;
    this.selectDatepicker.destroy();
    this.selectDatepicker = null;
  }

  created() {
    if (!this.options && !this.isYmdType) return;
    this.calendarInfo = Object.assign({}, this.calendarInfo, this.options);
    this.selectedYmd = this.calendarInfo.selectedYmd;
    this.selectedHour = this.calendarInfo.selectedHour;
    this.selectedMinute = this.calendarInfo.selectedMinute;
    if (this.isYmdType) this.calendarInfo.dateType = 'Ymd';
  }

  mounted() {
    this.setDatePicker();
  }

  destroyed() {
    this.unsetDatePicker();
  }
}
