import { textWidthButtonRenderer } from '@/utils/grid/rendererUtils';
import { GridProp } from '@/types';
import { i18n } from '@/main';
import { getAnchorHTML, emptyValueFormatter } from '@/utils/grid/formatterUtils';

export const i18nMallList = (message: string, key = 'MALL.LIST.'): string => {
  return i18n.t(key + message).toString();
};

const gridProps = (openCommercePaymentPopup): GridProp => ({
  columns: [
    {
      header: 'MALL.LIST.MALL_NO',
      name: 'mallNo',
      align: 'center',
      minWidth: 138,
      formatter({ value }) {
        return getAnchorHTML(`${value}`);
      },
    },
    {
      header: 'MALL.LIST.NAME_TITLE',
      name: 'mallName',
      align: 'center',
      minWidth: 230,
    },
    {
      header: 'MALL.LIST.EXPIRATION_DUE_DAYS',
      name: 'expirationDueDays',
      align: 'center',
      minWidth: 230,
      renderer: props => {
        const { expirationDueDays, paymentDueDate, planType, mallNo } = props.grid.getRow(props.rowKey);
        const dueDays = i18n.t('MALL.LIST.DUE_DAYS', { expirationDueDays }).toString();
        const paymentDate = i18n.t('MALL.LIST.PAYMENT_DATE', { paymentDueDate }).toString();
        const text = expirationDueDays > 0 ? dueDays + paymentDate : dueDays;
        const callback = () => openCommercePaymentPopup('shopbyExtend', mallNo);
        return textWidthButtonRenderer({
          text: planType === 'PRO' ? text : '-',
          buttonText: i18n.t('MALL.LIST.EXTENSION'),
          isShowButton: planType === 'PRO' && expirationDueDays <= 0,
          callback,
        });
      },
    },
    {
      header: 'MALL.LIST.REGISTERADMIN_TITLE',
      name: 'registerAdmin',
      align: 'center',
      minWidth: 138,
      formatter: function({ value, row }) {
        if (value === null && row.modifierAdmin === null) {
          return i18nMallList('DELETED_ADMIN');
        } else {
          return emptyValueFormatter(value);
        }
      },
    },
    {
      header: 'MALL.LIST.CREATEDDATETIME_TITLE',
      name: 'createdDateTime',
      align: 'center',
      minWidth: 230,
    },
    {
      header: 'MALL.LIST.LAST_MODIFIER',
      name: 'modifierAdmin',
      align: 'center',
      minWidth: 137,
      formatter: function({ value, row }) {
        if (value === null && row.registerAdmin === null) {
          return i18nMallList('DELETED_ADMIN');
        } else {
          return emptyValueFormatter(value);
        }
      },
    },
    {
      header: 'MALL.LIST.LAST_MODIFIED_DATE',
      name: 'modifiedDateTime',
      align: 'center',
      minWidth: 229,
      formatter: ({ value }) => emptyValueFormatter(value),
    },
  ],
  options: {
    keyColumnName: 'mallNo',
    pageOptions: null,
    rowHeight: 47,
  },

  displayOptions: {
    showsRightArea: false,
    headerTooltipOption: [
      {
        id: '#mallName',
        popupData: { data: { message: i18n.t('MALL.LIST.HELP_WHATIS_STANDARD_MALL'), leftAlign: true } },
      },
    ],
  },
});

export default gridProps;
