export default Object.freeze({
  GOOGLE_STATISTICS: '구글 통계',
  MEASUREMENT_ID: '측정 ID',
  MEASUREMENT_ID_PLACEHOLDER: '측정 ID 입력',
  GOOGLE_ANALYTICS: '구글 애널리틱스 바로가기',
  NAVER_WEB_MASTER: '네이버 웹마스터',
  GO: '바로가기',
  PAYCO_CENTER: '페이코 개발자 센터',
  NAVER_CENTER: '네이버 개발자 센터',
  KAKAO_CENTER: '카카오 개발자 센터',
  FACEBOOK_CENTER: '페이스북 개발자 센터',
  SITE_MAP: '사이트맵',
  DOMAIN: '도메인',
  KAKAO_MAP: '카카오 맵',
  KEY: '키',
  LATITUDE: '위도',
  LONGITUDE: '경도',
  SEARCH: '찾기',
  EASY_LOGIN: '간편로그인 연동',
  APPLY_FOR_USE: '사용 신청',
  SCRIPT: '외부 스크립트',
  SCRIPT_ALL: '외부 스크립트 전체',
  API_KEY: '외부 API 연동 Key',
  USED_YN: '사용여부',
  HEADER_COMMON: '상단 공통영역',
  SCRIPT_AREA: '스크립트 영역',
  BOTTOM_SCRIPT: '하단 공통영역',
  PAGE_DELETE: '페이지 삭제',
  PAGE_ADD: '페이지 추가',
  SELECTION_PAGE: '선택 페이지',
  API_INFO: '외부 API 연동 정보',
  ALERT_DELETED: '삭제되었습니다.',
  SCRIPT_NOT_APPLIED: '스크립트 미적용',
  SAME_PC: 'PC웹 내용과 동일하게 적용',

  GOOGLE_TOOL_TIP:
    'Google 애널리틱스 내 계정을 생성하고 추적 ID 등록을 통해 쇼핑몰 데이터 분석이 가능합니다.\n' +
    '구글 통계에 대한 상세 내용은 Google 애널리틱스(<a href="https://www.google.com/webmasters/#?modal_active=none" target="_blank">https://www.google.com/webmasters/#?modal_active=none</a>) 사이트를 통해 확인해주세요.',
  NAVER_TOOL_TIP:
    '네이버 Search Advisor 내 계정을 생성하고 Appkey 생성을 통해 쇼핑몰 검색 진단 및 최적화가 가능합니다.\n' +
    '네이버 웹마스터 상세 내용은 네이버서치어드바이저(<a href="https://searchadvisor.naver.com/" target="_blank">https://searchadvisor.naver.com/</a>) 사이트를 통해 확인해주세요.\n',
  // '사이트맵 도메인의경우 네이버서치어드바이저에 신청한 도메인 정보입니다.',
  KAKAO_TOOL_TIP:
    '카카오 개발자 센터 내 계정을 생성하고 Javascript키, 위도 및 경도 값을 통해 원하는 위치를 쇼핑몰에 적용 가능합니다.\n' +
    '카카오맵에 대한 상세 내용은 카카오 개발자 센터(<a href="https://developers.kakao.com/" target="_blank">https://developers.kakao.com/</a>) 사이트를 통해 확인해주세요.',
  EASY_LOGIN_TOOL_TIP:
    '간편로그인 인증업체 내 계정을 생성하고 Client ID, Client Secret 생성을 통해 연동이 가능합니다.\n' +
    '각 인증업체의 연동방법은 매뉴얼을 확인해주세요.\n' +
    '* 연동하려는 인증업체의 개발자 센터에서 서비스 URL (PC웹, 모바일웹), Callback URL을 정확히 입력하지 않는 경우 연동이 불가합니다.\n' +
    ' - 기본 스킨 사용 시 사용중인 기본스킨에 따라 연동하려는 인증업체 개발자센터에 Callback URL을 다음과 같이 입력하시기 바랍니다.\n' +
    ' - Another (개별형 스킨) \n' +
    '   * 개별형 스킨은 PC와 모바일 각각 총 2개의 Callback URL을 입력하셔야 합니다.\n' +
    '   └ PC: https://쇼핑몰도메인/callback/auth-callback.html\n' +
    '   └ 모바일: https://쇼핑몰도메인/m/callback/auth-callback.html\n' +
    ' - Aurora (통합형 스킨)\n' +
    '   └ PC, 모바일: https://쇼핑몰도메인/callback/auth-callback\n' +
    '기본 스킨 외 별도로 구매한 스킨의 경우는 스킨 제작사에 문의바랍니다.',
  APP_KEY_TOOL_TIP:
    '외부 제휴 서비스 연동 시 필요한 API Key 정보입니다.\n' +
    '샵바이 솔루션과 API 연동을 원할 경우 제휴 서비스 프로그램에 API 연동 Key를 입력하여 연동 설정을 하실 수 있습니다.',
  EXTERNAL_SCRIPT_TOOL_TIP:
    '쇼핑몰에서 사용하는 다양한 외부스크립트를 등록 및 관리할 수 있습니다.\n' +
    '- 스크립트 입력은 스크립트의 사용 목적과 가이드에 맞춰 입력하시기 바랍니다.\n' +
    '- 해당 영역에 여러 개의 스크립트를 추가할 수 있습니다.\n' +
    '- 메타태그(meta tag)의 경우 [상단 공통영역 스크립트] 영역에 추가해 주시기 바랍니다.\n' +
    '- 선택 페이지의 경우 [ + 페이지 추가] 버튼으로 페이지를 추가하여 스크립트를 입력할 수 있습니다.\n' +
    '- 스크립트의 구분이 필요한 경우 주석 처리가 가능합니다.\n' +
    '  # 주석 처리 방법\n' +
    '    아래 내용을 복사 후 붙여넣기 하여 수정해주세요.\n' +
    '    &lt;!-- 구분할 내용 입력// -->\n',
  CLIENT_ID_ALERT: '{0} Client ID를 입력해주세요.',
  CLIENT_SECRET_ALERT: '{0} Client Secret을 입력해주세요.',
  DELETED_ALERT: '삭제되었습니다.',
  SCRIPT_DELETED_ALERT: '삭제하신 선택 페이지의 입력된 스크립트는 복구가 불가능합니다.\n삭제하시겠습니까?',
  MAX_PAGE_SCRIPTS_BY_COUNT: '선택 페이지는 최대 {count}개까지 추가 가능합니다.',
  //payco login
  PAYCO_LOGIN_TITLE_REGISTER: '페이코 아이디 로그인 사용 신청',
  PAYCO_LOGIN_TITLE_EDIT: '페이코 아이디 로그인 재신청',
  PAYCO_LOGIN_NOTICE:
    '· 신청된 정보가 다를 경우 재신청 해주시기 바랍니다.\n' +
    '· 재신청 시 Client ID가 변경 되면, 페이코에서는 새로운 쇼핑몰로 인식하게 되어 기존에 연동된 쇼핑몰 회원이 해제되므로 변경 시 주의하여 주시기 바랍니다.\n' +
    '· 삭제된 Client ID 정보는 복구가 불가능합니다.\n' +
    '· 쇼핑몰 회원가입 시 수집이 필요한 항목은 페이코 개발자 센터 > 애플리케 이션 관리에서 선택이 가능합니다.\n' +
    ' - 개인정보 제공 항목 선택 시, 쇼핑몰 회원 가입할 경우 선택된 항목을 사용 자 동의하에 제공받을 수 있습니다.',
  PAYCO_LOGIN_MALL_NAME: '쇼핑몰 이름',
  PAYCO_LOGIN_MALL_URL: '쇼핑몰 URL',
  PAYCO_LOGIN_COMPANY: '상호(회사명)',
  REQUIRED: '[필수]',
  PAYCO_AGREE_TERMS: '페이코 개발자센터 서비스 이용 약관',
  AGREE: '에 동의합니다.',
  PAYCO_LOGIN_SAVE_REGISTER: '사용신청',
  PAYCO_LOGIN_SAVE_EDIT: '재신청',
  EMPTY_MALL_NAME: '쇼핑몰 이름 입력해주세요.',
  EMPTY_COMPANY: '쇼핑몰 이름 입력해주세요.',
  PAYCO_LOGIN_FAILURE: '페이코 아이디 로그인 사용 신청이 실패하였습니다. 다시 한번 진행해주세요.',
  PAYCO_LOGIN_EXCEEDED: '애플리케이션 최대 등록 개수를 초과하였습니다. 페이코 개발자 센터에서 삭제 후 등록해 주세요.',
  BOARD_RESERVE_TOOL_TIP:
    '상품후기에 대한 적립금 지급 설정은 ‘<a href="/pro/member/accumulation/setting" target="_blank">회원 > 적립금 관리 > 적립금 설정 관리</a>’ 에서 하실 수 있습니다.',
  DEVICE_INFORMATION: '디바이스 정보',
  CATEGORY_CODE: '카테고리 코드',
  PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  PRODUCT_AMT: '상품가격',
  STOCK_CNT: '상품수량',
  SOLD_OUT: '품절여부',
  DEVICE: '디바이스',
  ORDER_NO: '주문번호',
  ORDER_PRICE: '주문금액',
  VIEW_VARIABLE: '변수보기',
  VARIABLE_GUIDE: '변수 조회 가이드',
  VARIABLE_GUIDE_API_SPEC_INTRO:
    '공통, 상품상세, 상품 검색결과, 카테고리 상품조회, 메인분류 상품조회, 장바구니, 주문서 작성/결제, 주문완료, 주문상세, 회원가입 완료 페이지에 스크립트 등록 시 아래 가이드를 참고하여 필요한 정보를 추가할 수 있습니다.',
  VARIABLE_GUIDE_API_SPEC_INTRO_WARN:
    '※ 기본 디자인 스킨 외에 별도 스킨을 사용하시는 경우, 아래 변수들을 각 페이지의 전역변수로 할당하셔야 스크립트를 삽입하거나 변수를 사용할 수 있습니다.',
  VARIABLE_GUIDE_API_SPEC_TABLE_GUIDE:
    '각 페이지별 조회 API 응답값을 사용할 수 있으며, 사용 가능한 API와 페이지별 전역변수는 아래 표에서 확인 가능합니다.',
  VARIABLE_GUIDE_API_SPEC_TABLE_GUIDE_OTHER_VARIABLE_WARN:
    '상세보기에서 공유된 API 외 다른 API 응답값은 변수로 사용이 불가합니다.',
  VARIABLE_GUIDE_API_SPEC_TABLE_GUIDE_OTHER_DEVICE_WARN:
    '디바이스 정보, 회원 정보는 페이지 구분 없이 공통으로 사용 가능합니다.',
  VARIABLE_GUIDE_API_PRIVACY_POLICY_WARN:
    '※ 외부 스크립트를 이용하여 쇼핑몰 이용자의 개인정보를 제3자에게 제공하거나, 개인정보처리업무를 위탁하는 경우 개인정보보호법에 따라 (제3자 제공의 경우) 별도 동의를 받거나, (위탁의 경우) 이를 정보주체가 알기 쉽도록 공개하여야 합니다.',
  VARIABLE_GUIDE_API_PRIVACY_POLICY_UPDATE_WARN:
    "※ '설정 > 기본 정책 > 약관/개인정보처리방침 관리'에서 개인정보처리방침 및 개인정보 수집 및 이용에 대한 동의 항목을 설정할 수 있습니다. 필요한 조치를 취하지 않아 발생하는 불이익에 대하여 당사는 책임을 지지 않습니다.",
  COMMON: '공통',
  DEVICE_INFO: '디바이스 정보',
  MEMBER_INFO: '회원 정보',
  PRODUCT_PAGE: '상품 상세 페이지',
  PRODUCT_SEARCHED_PAGE: '상품 검색결과 페이지',
  PRODUCT_SEARCHED_BY_CATEGORY_PAGE: '카테고리 상품조회 페이지',
  SECTION_PRODUCT_PAGE: '메인분류 상품조회 페이지',
  ORDER_SHEET_PAGE: '주문서 작성/결제 페이지',
  CART_PAGE: '장바구니 페이지',
  MAIN_PAGE: '메인 페이지',
  ORDER_COMPLETE_PAGE: '주문완료 페이지',
  ORDER_DETAIL_PAGE: '주문상세 페이지',
  MEMBER_JOIN_COMPLETE_PAGE: '회원가입 완료 페이지',
  ORDER_COMPLETE_AND_DETAIL_PAGE: '주문완료, 주문상세 페이지',
  PROFILE_API: '회원정보 조회 API',
  PRODUCT_API: '상품 상세 조회 API',
  PRODUCT_SEARCHED_API: '상품 검색 API',
  SECTION_PRODUCT_API: '상품 디자인 섹션 조회 API',
  ORDER_SHEET_API: '주문서 조회 API',
  MEMBER_CART_API: '회원 장바구니 조회 API',
  GUEST_CART_API: '비회원 장바구니 조회 API',
  MEMBER_ORDER_API: '회원 주문 조회 API',
  GUEST_ORDER_API: '비회원 주문 조회 API',
  SCRIPT_WRITE_GUIDE: '※ 스크립트 작성 가이드',
  SCRIPT_WRITE_GUIDE_SUB_TITLE_1: '[중첩된 객체내의 특정 값을 조회하고자 하는 경우]',
  SCRIPT_WRITE_GUIDE_SUB_CONTENT_1:
    '장바구니의 구매금액 합(buyAmt) 을 조회하고자 하는 경우 마침표 프로퍼티 접근 연산자(.) 를 이용하여 값을 조회할 수 있습니다.',
  SCRIPT_WRITE_GUIDE_SUB_TITLE_2: '[복수개의 데이터의 값을 조회하고자 하는 경우]',
  SCRIPT_WRITE_GUIDE_SUB_CONTENT_2:
    '장바구니의 담긴 상품의 옵션 번호(optionNo) 을 모두 조회하고자 하는 경우 Array.prototype.map 메서드를 이용하여 값을 조회할 수 있습니다.',
  PAGE: '페이지',
  EXPLANATION: '설명',
  VARIABLE: '변수',
  VIEW_DETAILS: '상세보기',

  INSTAGRAM: '인스타그램',
  INSTAGRAM_INTEGRATION: '인스타그램 연동',
  INSTAGRAM_DEINTEGRATION: '인스타그램 연동 해제',
  INSTAGRAM_CONTENT_EMPTY: '등록된 게시물이 없습니다.',
  INSTAGRAM_INTEGRATION_FAIL_MESSAGE: '인스타그램 연동에 실패하였습니다. 다시 연동해주세요',
  INSTAGRAM_INTEGRATION_INVALID_MESSAGE: '인스타그램 연동 정보가 유효하지 않습니다. 다시 연동해주세요.',
  INSTAGRAM_DEINTEGRATION_ALERT_MESSAGE: '인스타그램 연동이 해제되었습니다.',
  INSTAGRAM_NO_CONTENT: '등록된 게시물이 없습니다.',

  INSTAGRAM_INTEGRATION_GUIDE_MESSAGE: `쇼핑몰 대표 인스타그램 계정을 생성 후, 인스타그램 연동을 하면 게시물을 쇼핑몰에 노출 시킬 수 있습니다.
인스타그램 게시글은 최대 12개 노출되며 1시간마다 자동 갱신됩니다.
인스타그램 계정 문제로 연동이 실패한 경우 연동 해제 될 수 있으며 연동 해제 시 다시 연동을 시도해주세요.
인스타그램 위젯은 PC(6*2) / 모바일 (3*4) 로 노출 됩니다.
  `,
});
