




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class CustomPropertiesIframe extends Vue {
  private get remoteSrc() {
    return process.env.VUE_APP_ADMIN_REMOTE_URL + `/product/management/custom-properties?serviceType=PRO`
  }
}
