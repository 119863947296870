import {
  GetAccumulationsAssemblesRequest,
  GetAccumulationsDailyRequest,
  ManageAccumulationsAssemblesSearchType,
  ManageAccumulationsRequestGroupType,
  ManagePeriodType,
  PutAccumulationsAssemblesRequestNoRequest,
} from 'ncp-api-supporter';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { DateRangeOption, GridProp, OptColumn } from '@/types';
import { addDay, changeDateFormat, getStrDate, getToday } from '@/utils/dateFormat';
import { emptyValueFormatter, getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { AccumulationStatusRenderer } from '@/views/contents/member/accumulation/AccumulationPaymentDeduct';
import { mall } from '@/utils/mall';

export const accumulationManualInitQuery = (): GetAccumulationsAssemblesRequest['params'] => {
  return {
    page: 1,
    pageSize: 30,
    startDateTime: `${addDay(new Date(), -7)} ${DEFAULT_TIME_RANGE.START}`,
    endDateTime: `${getToday()} ${DEFAULT_TIME_RANGE.END}`,
    searchType: 'ALL',
    keyword: '',
    periodType: 'REGISTER',
    statuses: 'WAITING,COMPLETED,CANCELED,PROCESSING',
    requestGroupType: 'ALL',
    requestTypes: 'DIRECT_ADD,RESERVE_ADD,DIRECT_SUB',
    mallNos: mall.allMallNoString,
  };
};

export const searchTypeOption: OptionData<ManageAccumulationsAssemblesSearchType>[] = [
  { value: 'ALL', name: 'ALL' },
  { value: 'REQUEST_NO', name: 'ACCUMULATION.PAYMENT_DEDUCT_NO' },
  { value: 'REASON', name: 'ACCUMULATION.PAYMENT_DEDUCT_REASON' },
  { value: 'REGISTER', name: 'ACCUMULATION.REGISTRAR' },
  { value: 'UPDATER', name: 'ACCUMULATION.LATEST_UPDATER' },
];

export const periodTypeOption: OptionData<ManagePeriodType>[] = [
  { value: 'REGISTER', name: 'ACCUMULATION.CONDITION_REGISTER' },
  { value: 'UPDATE', name: 'ACCUMULATION.CONDITION_UPDATE' },
  { value: 'REQUEST', name: 'ACCUMULATION.PAYMENT_REQUEST_DATETIME' },
];

export const dateRangeOption: DateRangeOption = {
  name: 'paymentDeductPeriod',
  periodTypes: ['td', '1w', '1m', '3m', '6m', '1y'],
};

export const paymentDeductOption: RadioBoxGroupOption<ManageAccumulationsRequestGroupType> = {
  name: 'paymentDeduct',
  data: [
    { id: 'all-payment-deduct', value: 'ALL', display: 'ALL' },
    { id: 'payment', value: 'ADD', display: 'ACCUMULATION.PAYMENT' },
    { id: 'deduct', value: 'SUB', display: 'ACCUMULATION.DEDUCT' },
  ],
};

export const paymentTypeOption: RadioBoxGroupOption<string> = {
  name: 'paymentType',
  data: [
    { id: 'all-payment', value: 'DIRECT_ADD,RESERVE_ADD,DIRECT_SUB', display: 'ALL' },
    { id: 'immediately-payment', value: 'DIRECT_ADD', display: 'ACCUMULATION.IMMEDIATELY_PAYMENT' },
    { id: 'reservation-payment', value: 'RESERVE_ADD', display: 'ACCUMULATION.RESERVATION_PAYMENT' },
  ],
};

export const putAccumulationsAssemblesRequestNoRequestData: PutAccumulationsAssemblesRequestNoRequest['data'] = {
  mallNo: 0,
  paymentRequestDate: '',
  paymentRequestHour: 0,
  immediately: true,
  amount: 0,
  reason: '',
  reasonDetail: '',
  validPeriod: 0,
  expireDateTime: '',
  targets: [],
  excludeMemberNos: [],
  notificationChannels: [],
};

export const getAccumulationGirdOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'ACCUMULATION.NO',
        name: 'no',
        align: 'center',
        width: 100,
        formatter: ({ row }) => getAnchorHTML(row.no.toString()),
      },
      {
        header: 'ACCUMULATION.SEARCH_MALL_TITLE',
        name: 'mallName',
        align: 'center',
        width: 150,
      },
      {
        header: 'ACCUMULATION.SORTATION',
        name: 'requestGroupLabel',
        align: 'center',
        width: 130,
      },
      {
        header: 'ACCUMULATION.TYPE',
        name: 'immediately',
        align: 'center',
        width: 130,
        formatter: ({ row }) => {
          if (row.requestGroup === 'SUB') {
            return '-';
          }
          return row.immediately
            ? i18n.t('ACCUMULATION.IMMEDIATELY_PAYMENT').toString()
            : i18n.t('ACCUMULATION.RESERVATION_PAYMENT').toString();
        },
      },
      {
        header: 'ACCUMULATION.STATUS',
        name: 'status',
        align: 'center',
        width: 150,
        renderer: {
          type: AccumulationStatusRenderer,
        },
      },
      {
        header: 'ACCUMULATION.REASON',
        name: 'reasonDetail',
        align: 'center',
        width: 180,
      },
      {
        header: 'ACCUMULATION.RESERVATION_DATE',
        name: 'requestDateTime',
        align: 'center',
        width: 180,
        formatter: ({ row }) => {
          if (row.requestGroup === 'SUB' || row.requestType === 'DIRECT_ADD') {
            return '-';
          }
          return changeDateFormat(row.requestDateTime.toString(), 'YYYY-MM-DD HH:mm');
        },
      },
      {
        header: 'ACCUMULATION.CURRENT_SITUATION',
        name: 'paymentCount',
        align: 'center',
        width: 130,
        formatter: ({ row }) => {
          return emptyValueFormatter(row.paymentCount, getAnchorHTML(`${row.paymentCount} / ${row.tryCount}`));
        },
      },
      {
        header: 'ACCUMULATION.REGISTRAR',
        name: 'registerAdminName',
        align: 'center',
        width: 130,
        formatter: ({ row }) => {
          return row.registerAdminStatus === 'LOCKED'
            ? i18n.t('ACCUMULATION.DELETED_ADMIN').toString()
            : row.registerAdminName.toString();
        },
      },
      {
        header: 'ACCUMULATION.REGISTER',
        name: 'registerDateTime',
        align: 'center',
        width: 180,
      },
      {
        header: 'ACCUMULATION.LATEST_UPDATER',
        name: 'updateAdminName',
        align: 'center',
        width: 180,
        formatter: ({ row }) => {
          return row.updateAdminStatus && row.updateAdminStatus === 'LOCKED'
            ? i18n.t('ACCUMULATION.DELETED_ADMIN').toString()
            : emptyValueFormatter(row.updateAdminName);
        },
      },
      {
        header: 'ACCUMULATION.LATEST_UPDATE',
        name: 'updateDateTime',
        align: 'center',
        width: 180,
        formatter: ({ row }) => emptyValueFormatter(row.updateDateTime),
      },
    ],
    header: {
      align: 'center',
    },
  };
};

export const getAccumulationDisplayOption = () => {
  return { showExcelButtonPreRight: true };
};

export const accumulationHistoryInitQuery = (
  mallNo: number,
): GetAccumulationsDailyRequest['params'] & { method: 'daily' | 'case' } => {
  return {
    page: 1,
    pageSize: 30,
    mallNo,
    startDateTime: `${addDay(new Date(), -7)} ${DEFAULT_TIME_RANGE.START}`,
    endDateTime: `${getToday()} ${DEFAULT_TIME_RANGE.END}`,
    method: 'daily',
  };
};

export const inquiryMethodRadioOption: RadioBoxGroupOption<'daily' | 'case'> = {
  name: 'inquiryMethod',
  data: [
    { id: 'daily', value: 'daily', display: 'ACCUMULATION.INQUIRY_DAILY' },
    { id: 'case', value: 'case', display: 'ACCUMULATION.INQUIRY_CASE' },
  ],
};

export const getHistoryGridOption = (): GridProp => {
  return {
    header: {
      align: 'center',
    },
    displayOptions: {
      ...getAccumulationDisplayOption(),
      hasSettingButton: false,
    },
  };
};

export const statusOption: GridProp = {
  ...getHistoryGridOption(),
  options: {
    bodyHeight: 280,
  },
};

export const inquiryByDailyColumn: OptColumn[] = [
  {
    header: 'ACCUMULATION.DATE',
    name: 'date',
    align: 'center',
  },
  {
    header: 'ACCUMULATION.CONFIG.PAYMENT_UNIT',
    name: 'accumulateAmount',
    align: 'center',
    formatter: ({ row }) => `+${row.accumulateAmount?.toLocaleString()}`,
  },
  {
    header: 'ACCUMULATION.DEDUCT',
    name: 'deductionAmount',
    align: 'center',
    formatter: ({ row }) => `-${row.deductionAmount?.toLocaleString()}`,
  },
  {
    header: 'DETAILS',
    name: 'detail',
    align: 'center',
    formatter: () => getAnchorHTML(i18n.t('VIEW')),
  },
];

export const statusCommonColumn = (isManualType = false): OptColumn[] => {
  return [
    {
      header: 'MEMBER_NO',
      name: 'no',
      align: 'center',
      minWidth: 100,
      formatter: ({ row }) => {
        const memberNo = row.memberNo?.toString();
        if (row.memberStatus === 'WITHDRAWN') {
          return memberNo;
        } else {
          return getAnchorHTML(memberNo);
        }
      },
    },
    {
      header: 'ACCUMULATION.ID',
      name: 'memberId',
      align: 'center',
      minWidth: 150,
      formatter: ({ row }) => {
        if (row.memberStatus === 'WITHDRAWN') {
          return i18n.t('ACCUMULATION.WITHDRAWAL_MEMBER').toString();
        } else {
          return row.memberId?.toString();
        }
      },
    },
    {
      header: 'ACCUMULATION.MEMBER_GRADE_NAME',
      name: isManualType ? 'memberGradeName' : 'memberGrade',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'ACCUMULATION.CONFIG.PAYMENT_UNIT',
      name: 'paymentAmount',
      align: 'center',
      minWidth: 150,
      formatter: ({ row }) => {
        const status = isManualType
          ? row.requestGroup?.toString()?.includes('ADD')
          : row.status?.toString()?.includes('GIVE');
        return emptyValueFormatter(status, `+${row.amount?.toLocaleString()}`);
      },
    },
    {
      header: 'ACCUMULATION.DEDUCT',
      name: 'deductAmount',
      align: 'center',
      minWidth: 150,
      formatter: ({ row }) => {
        const status = isManualType ? row.requestGroup?.toString() : row.status?.toString();
        return emptyValueFormatter(status?.includes('SUB'), `-${row.amount?.toLocaleString()}`);
      },
    },
  ];
};

const paymentDeductDateColumn = (isManualType = false): OptColumn[] => {
  return [
    {
      header: 'ACCUMULATION.PAYMENT_DEDUCT_DATE',
      name: isManualType ? 'paymentDateTime' : 'createdDateTime',
      align: 'center',
      minWidth: 200,
      formatter: ({ value, row }) => {
        if (isManualType && row.status !== 'COMPLETED') return '-';
        return isManualType ? emptyValueFormatter(value) : value?.toString();
      },
    },
  ];
};

const validPeriodColumn = (isManualType = false): OptColumn[] => {
  return [
    {
      header: 'ACCUMULATION.VALID_PERIOD',
      name: 'validPeriod',
      align: 'center',
      minWidth: 200,
      formatter: ({ row }) => {
        if (isManualType && (row.status !== 'COMPLETED' || row.requestGroup === 'SUB')) return '-';
        if (!isManualType && row.status === 'SUB') return '-';

        const start = isManualType ? row.registerDateTime : row.startedDateTime;
        const end = isManualType ? row.expireDateTime : row.expiredDateTime;
        const paymentDate = start ? getStrDate(start.toString()) : '';
        const expireDate = end && end !== '9999-12-31 23:59:59' ? getStrDate(end.toString()) : '';

        if (paymentDate && expireDate) {
          return `${paymentDate} ~ ${expireDate}`;
        } else if (paymentDate && !expireDate) {
          return `${paymentDate} ~ ${i18n.t('MEMBER.POPUP.VALIDITY_PERIOD_NONE')}`;
        } else {
          return '-';
        }
      },
    },
  ];
};

// 적립금 지급/차감 현황(탭) - 건별
export const inquiryByCaseColumn: OptColumn[] = [
  ...paymentDeductDateColumn(),
  ...statusCommonColumn(),
  ...validPeriodColumn(),
  {
    header: 'ACCUMULATION.REASON',
    name: 'detailReason',
    align: 'center',
    minWidth: 200,
  },
];

// 일별 적립금 지급/차감 현황(팝업) - daily status
export const dailyStatusColumn: OptColumn[] = [
  ...statusCommonColumn(),
  ...paymentDeductDateColumn(),
  ...validPeriodColumn(),
  {
    header: 'ACCUMULATION.REASON',
    name: 'detailReason',
    align: 'center',
    minWidth: 200,
  },
];

// 적립금 지급/차감 현황(팝업) - manual status
export const detailStatusColumn: OptColumn[] = [
  ...statusCommonColumn(true),
  {
    header: 'ACCUMULATION.SUCCESS_STATUS',
    name: 'status',
    align: 'center',
    formatter: ({ value }) =>
      value === 'COMPLETED' ? i18n.t('ACCUMULATION.SUCCESS').toString() : i18n.t('ACCUMULATION.FAILURE').toString(),
  },
  ...paymentDeductDateColumn(true),
  ...validPeriodColumn(true),
];
