export default Object.freeze({
  MSG1: '시행일을 선택해주세요.',
  MSG2: '저장 되었습니다.',
  MSG3: '쇼핑몰/회사소개',
  MSG4: '이용약관',
  MSG5: '전자금융거래이용약관',
  MSG6: '개인정보처리방침',
  MSG7: '개인정보 수집/동의 항목',
  MSG8: '이용/탈퇴안내',
  MSG9: '지난 시행일은 저장할 수 없습니다.',
});
