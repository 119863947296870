


































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { getInquiriesListGridOption } from '@/views/contents/board/basic/InquiriesList';
import {
  getDefaultParams,
  renameParameterKeys,
  DefaultQuery,
  adminStore,
} from '@/components/board/Inquiries/SearchQuery';
import {
  NCPResponse,
  GetInquiriesRequest,
  GetInquiries,
  GetInquiriesCounts,
  GetInquiriesCountsRequest,
} from 'ncp-api-supporter';
import { RouteQuery } from '@/types/route';
import { GridEventProps, Admin } from '@/types'; // todo
import { Row } from '@/types/tui-grid';
import { DEFAULT_DATE_RANGE, DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { throwExternalWindowPopup, PopupClose, throwPopup, throwWindowPopupWithProps } from '@/helpers/popup';
import SummaryTab, { SummaryItem } from '@/components/board/Inquiries/SummaryTab.vue';
import SearchForm from '@/components/board/Inquiries/SearchForm.vue';
import { getCurrentMallNo } from '@/utils/mall';
import { InquiryType } from 'ncp-api-supporter/dist/types/modules/manage/inquiryType';
import { GetInquiryTypeRequest } from 'ncp-api-supporter/dist/types';

export type TabState = '' | 'TAB1' | 'TAB2' | 'TAB3';

export interface RendererEventCallback {
  (eventName: string, event: Event, row: Row): void;
}

function removeEmptyStringValueParams(object) {
  return Object.fromEntries(Object.entries(object).filter(([_, v]) => v !== ''));
}

@Component({
  components: {
    SummaryTab,
    SearchForm,
    Grid,
  },
})
export default class InquiriesList extends Vue {
  @Ref() private readonly grid: Grid;
  @Ref()
  private readonly searchForm!: SearchForm;
  @adminStore.Getter('getAdmin')
  private readonly me!: Admin;
  private gridProps = getInquiriesListGridOption();
  private requestParams = null;
  private isInquiryAssignee = false;

  private inquiryTypeSearchResponse: GetInquiries = {
    contents: [],
    totalCount: 0,
  };

  // SummaryTab 관련
  private summaryTab: SummaryItem<TabState>[] = [];
  private selectedClassification: TabState = '';

  private get mallNo() {
    return getCurrentMallNo(this);
  }

  private async fetchProductSummaries() {
    const requestSummary = {
      params: {
        mallNo: getCurrentMallNo(this),
      },
    } as GetInquiriesCountsRequest;
    const { data }: NCPResponse<GetInquiriesCounts> = await this.$api.getInquiriesCounts(requestSummary);

    // const { issuedCount, unspecifiedCount, inProgressCount, my } = data;
    const { issuedCount, inProgressCount, my } = data;
    this.summaryTab = [
      {
        name: this.$t('BOARD.INQUIRIES.UNANSWER').toString(),
        count: issuedCount + inProgressCount,
        value: 'TAB1',
      },
      // {
      //   name: this.$t('BOARD.INQUIRIES.NOADMIN').toString(),
      //   count: unspecifiedCount,
      //   value: 'TAB2',
      // },
    ];
    if (this.isInquiryAssignee === false) return;
    const unanswered = {
      name: this.me.name.concat(this.$t('BOARD.INQUIRIES.UNANSWER_SELF').toString()),
      count: my.inProgressCount + my.issuedCount,
      value: 'TAB3' as TabState,
    };
    this.summaryTab.push(unanswered);
  }

  private query = { ...DefaultQuery };

  @Watch('$route.query')
  private updatedQuery(query: RouteQuery): void {
    this.fetchProducts(renameParameterKeys(query));
  }

  async created() {
    //순서대로 실행되어야 함
    await this.fetchInquiryTypes();
    await this.fetchProductSummaries();
    this.fetchProducts(renameParameterKeys(this.$route.query));
  }

  mounted() {
    const { selectedClassification } = this.$route.query;
    const tabStates: TabState[] = ['TAB1', 'TAB2', 'TAB3'];
    const matched = tabStates.some(state => state === selectedClassification);
    if (selectedClassification && matched) return;

    // TODO : 하위컴포넌트에서 $nextTick 을 남용해서 여기서도 걸 수 밖에없는 슬픈상황이 연출되고있음
    this.$nextTick(() => {
      this.searchForm.search();
    });
  }

  private async fetchInquiryTypes(): Promise<void> {
    const request: GetInquiryTypeRequest = { params: { mallNo: Number(this.mallNo) } };
    const { data }: NCPResponse<InquiryType[]> = await this.$api.getInquiryType(request);
    const assigneeNos = data.flatMap(({ assignees }) => assignees.flatMap(({ no }) => no));
    this.isInquiryAssignee = assigneeNos.includes(this.me.no);
  }

  async fetchProducts(params = {}): Promise<void> {
    const nonConvertRequestParams = Object.assign(renameParameterKeys(getDefaultParams()), params);
    this.requestParams = InquiriesList.convertRequestParams(nonConvertRequestParams);

    if (this.requestParams.mallNo === 0) {
      this.requestParams.mallNo = getCurrentMallNo(this);
    }

    const request = {
      params: {
        ...this.requestParams,
      },
    } as GetInquiriesRequest;
    try {
      const { data }: NCPResponse<GetInquiries> = await this.$api.getInquiries(request);
      this.inquiryTypeSearchResponse = data;
    } catch (error) {
      console.error(error);
    }
  }

  // FIXME: 이 메서드가 점점 커지고있다. static 이니 아예 별도파일로 빼는게..?
  private static convertRequestParams(params): any {
    const startYmdt = 'startDateTime';
    const endYmdt = 'endDateTime';

    if (params['noAdmin'] == 'NODECIDE') {
      //bug 142
      params['isUnspecified'] = true;
    }
    // 기간 : 전체 일 경우
    if (!params[startYmdt]) params[startYmdt] = DEFAULT_DATE_RANGE.MIN;
    if (!params[endYmdt]) params[endYmdt] = DEFAULT_DATE_RANGE.MAX;

    // Date Format API 요구에 맞게 변경
    if (params?.isAll) {
      params[startYmdt] = DEFAULT_DATE_RANGE.MIN;
      params[endYmdt] = DEFAULT_DATE_RANGE.MAX;
    }

    params[startYmdt] += ` ${DEFAULT_TIME_RANGE.START}`;
    params[endYmdt] += ` ${DEFAULT_TIME_RANGE.END}`;

    if (params.mallNos > 0) {
      params['mallNo'] = params.mallNos;
    }
    if (params['inquiryStatuses'] === 'ALL') params['inquiryStatuses'] = 'ISSUED,ANSWERED,IN_PROGRESS';

    return removeEmptyStringValueParams(params);
  }

  private onItemClicked(gridProps: GridEventProps): void {
    const rowData: Row = this.grid.getRowAt(gridProps.rowKey);
    switch (gridProps.columnName) {
      case 'inquiryTitle': {
        this.openReviewDetail(rowData, rowData.inquiryNo as number);

        break;
      }
      case 'inquiryNo': {
        this.openReviewDetail(rowData, rowData.inquiryNo as number);

        break;
      }
      case 'issuer': {
        this.goToCrm(rowData);
        break;
      }
    }
  }

  private openReviewDetail(rowData: Row, inquiryNo: number) {
    throwWindowPopupWithProps({
      componentName: 'InquiryDetail',
      data: { rowData },
      query: { inquiryNo },
      size: 'xlg',
      onClose: this.detailCallback,
    });
  }

  private goToCrm(rowData: any) {
    if (rowData.issuer.issuerStatus === 'WITHDRAWN') return;
    throwExternalWindowPopup(`/pro/crm/${rowData.issuer.issuerNo}`, 'xxlg');
  }

  private detailCallback() {
    this.fetchProducts(renameParameterKeys(this.$route.query));
  }

  private onOpenTypeAndAdmin(): any {
    const checkedRows: Row[] = this.grid.getCheckedRows();
    if (checkedRows.length === 0) {
      alert(this.$t('BOARD.INQUIRIES.WARN_NOROWS'));
      return;
    }

    const checkedNaverPayInquiries = checkedRows.filter(inquiry => inquiry.inquiryTypeChannel === 'NAVER_PAY');
    if (checkedNaverPayInquiries.length && checkedNaverPayInquiries.length !== checkedRows.length) {
      alert(this.$t('BOARD.INQUIRIES.WARN_NCP'));
      return;
    }

    if (!this.sameMallValidation(checkedRows)) return;
    throwPopup({
      name: 'TypeAndAdminPopup',
      data: {
        // popup parameter data (props)
        name: checkedRows[0].mallName,
        mallNo: Number(checkedRows[0].mallNo),
        inquiryTypeNo: Number(checkedRows[0].inquiryTypeNo),
        channel: checkedRows[0].inquiryTypeChannel,
        inquiryNos: checkedRows.map((item: any) => item.inquiryNo).join(','),
      },
    }).then(({ state }) => {
      if (state === PopupClose.CONFIRM) this.fetchProducts(renameParameterKeys(this.$route.query));
    });
  }

  private sameMallValidation = (rows: Row[]): boolean => {
    const allSame = rows.every(({ mallNo }) => rows[0].mallNo === mallNo);
    if (allSame) return allSame;
    alert(this.$t('BOARD.INQUIRIES.WARN_MALL'));
    return allSame;
  };
}
