import { saveAs } from 'file-saver';

export const saveBlobToFile = (blob: Blob, fileName: string) => {
  saveAs(blob, fileName.normalize('NFC'));
};

export const saveBinaryDataToFile = (arrayBuffer: ArrayBuffer, fileName: string): void => {
  const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
  saveBlobToFile(blob, fileName);
};
