export const getBoardRadioBoxOption = () => {
  return {
    boardTypeRadioOption: {
      name: 'boardType',
      data: [
        { id: 'list', value: 'LIST', display: 'BOARD.BOARD.TYPE_OPTION_1' },
        { id: 'inquiry', value: 'INQUIRY', display: 'BOARD.BOARD.TYPE_OPTION_2' },
        { id: 'reply', value: 'REPLY', display: 'BOARD.BOARD.TYPE_OPTION_3' },
        { id: 'card', value: 'CARD', display: 'BOARD.BOARD.TYPE_OPTION_4' },
      ],
    },

    productBoardTypeRadioOption: {
      name: 'boardType',
      data: [
        { id: 'list', value: 'LIST', display: 'BOARD.BOARD.TYPE_OPTION_1' },
        { id: 'card', value: 'CARD', display: 'BOARD.BOARD.TYPE_OPTION_4' },
      ],
    },

    boardImageRadioOption: {
      name: 'boardImage',
      data: [
        { id: 'not_used', value: false, display: 'BOARD.BOARD.IMAGE_OPTION_1' },
        { id: 'attachment', value: true, display: 'BOARD.BOARD.IMAGE_OPTION_2' },
      ],
    },
    inquiriesImageRadioOption: {
      name: 'inquiriesboardImage',
      data: [
        { id: 'not_used', value: 'NOT_USED', display: 'BOARD.BOARD.IMAGE_OPTION_1' },
        { id: 'attachment', value: 'ATTACHMENT', display: 'BOARD.BOARD.IMAGE_OPTION_2' },
      ],
    },
    productBoardImageRadioOption: {
      name: 'productboardImage',
      data: [
        { id: 'not_used', value: 'NONE', display: 'BOARD.BOARD.IMAGE_OPTION_1' },
        { id: 'product', value: 'PRODUCT_MAIN_IMAGE', display: 'BOARD.BOARD.IMAGE_OPTION_3' },
      ],
    },
    reviewBoardImageRadioOption: {
      name: 'reviewboardImage',
      data: [
        { id: 'not_used', value: 'NONE', display: 'BOARD.BOARD.IMAGE_OPTION_1' },
        { id: 'attachment', value: 'ATTACH_IMAGE', display: 'BOARD.BOARD.IMAGE_OPTION_2' },
        { id: 'product', value: 'PRODUCT_IMAGE', display: 'BOARD.BOARD.IMAGE_OPTION_3' },
      ],
    },

    boardRadioOption1: {
      name: 'boardCommon1',
      data: [
        { id: 'all-payment-deduct', value: true, display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'payment', value: false, display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
    boardRadioOption2: {
      name: 'boardCommon2',
      data: [
        { id: 'all-payment-deduct', value: true, display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'payment', value: false, display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
    boardRadioOption3: {
      name: 'boardCommon3',
      data: [
        { id: 'all-payment-deduct', value: true, display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'payment', value: false, display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
    boardRadioOption4: {
      name: 'boardCommon4',
      data: [
        { id: 'all-payment-deduct', value: true, display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'payment', value: false, display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
    boardRadioOption5: {
      name: 'boardCommon5',
      data: [
        { id: 'all-payment-deduct', value: true, display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'payment', value: false, display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
    boardRadioOption6: {
      name: 'boardCommon6',
      data: [
        { id: 'all-payment-deduct', value: true, display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'payment', value: false, display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
    boardReplyRadioOption: {
      name: 'boardReply',
      data: [
        { id: 'reply-used', value: true, display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'reply-not-used', value: false, display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
    commentUseOption: {
      name: 'commentUseYn',
      data: [
        { id: 'comment-used', value: 'Y', display: 'BOARD.BOARD.USERD_OPTION_1' },
        { id: 'comment-not-used', value: 'N', display: 'BOARD.BOARD.USERD_OPTION_2' },
      ],
    },
  };
};
