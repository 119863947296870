


































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { exchangeImmediateDiscountedAmt } from '@/components/popup/claim/claimFormula';
import { AdditionalPayType, Amounts, ClaimData, ClaimOption, Shipping } from 'ncp-api-supporter';
import { claimClassTypes, claimTypes, responsibleObjectTypes } from '@/const/claim';
import { ClaimClassType, ResponsibleObjectType } from '@/types/claim';
import { isCancelExchange, isReturnExchange } from '@/components/popup/claim/claimConditions';
import { DeliveryCalculator } from '@/components/popup/claim/ClaimPopup/calculator';
import AmountMixin from '@/components/popup/claim/AmountMixin';
import TdDeliveryDifference from '@/components/popup/claim/ClaimAcceptPopup/ExchangeAmount/TdDeliveryDifference.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

@Component({
  components: { TdDeliveryDifference, ToolTip },
  computed: {
    isCancelExchange,
    isReturnExchange,
    showExchangeShippingInfo() {
      return this.isCancelExchange && !!this.exchangeShipping;
    },
    isAdditionalPay(): boolean {
      return !!this.additionalPayType;
    },
    isSellerResponsible(): boolean {
      return this.responsibleObjectType === responsibleObjectTypes.SELLER;
    },
    lineThrough(): 'line-through' | 'none' {
      // 반품배송비, 교환(재발송) 배송비삭선 조건: https://nhnent.dooray.com/project/posts/2784101162368723396
      return this.isSellerResponsible ? 'line-through' : 'none';
    },
    beforeDeliveryLineThrough() {
      const { alreadyPaidTotalDeliveryAmt, totalDeliveryAmt } = this.amounts.before.delivery.deliveryGroupAmounts;

      return alreadyPaidTotalDeliveryAmt !== totalDeliveryAmt && this.deliveryAmtModified.prepaid
        ? 'line-through'
        : 'none';
    },
    afterDeliveryLineThrough() {
      if (this.isReturnClaim || this.isReturnExchange) {
        if (this.isSellerResponsible && this.sellerPaysClaimedDelivery) {
          return this.amounts.returnDelivery?.processedDeliveryAmt > 0 ? 'line-through' : 'none';
        }
        return 'none';
      }

      return 'none';
    },
  },
})
export default class ExchangeAmount extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly claimClassType!: ClaimClassType;

  @Prop({ required: true })
  private readonly additionalPayType!: AdditionalPayType;

  @Prop({ required: true })
  private readonly responsibleObjectType!: ResponsibleObjectType;

  @Prop({ required: true })
  private readonly exchangeOption!: ClaimOption;

  @Prop({ required: true })
  private readonly claimedOption!: ClaimOption;

  @Prop({ required: true })
  private readonly amounts!: Amounts;

  @Prop({ required: true })
  private readonly shipping!: Shipping;

  @Prop({ required: true })
  private readonly exchangeShipping!: ClaimData['exchangeShipping'];

  @Prop({ required: true })
  private readonly sellerPaysClaimedDelivery!: boolean;

  private deliveryCalculator = null;

  // 상품합계차액 row
  private totalProductAmt = {
    exchangeProductPrice: 0, // 교환 상품
    cancelProductPrice: 0, // 취소 상품
    productAdjustAmt: 0, // 금액 조정

    // 차액
    get difference() {
      return this.exchangeProductPrice - this.cancelProductPrice - this.productAdjustAmt;
    },
  };

  // 상품추가할인차액(-) row 오픈스펙제외
  // private productAdditionalDiscount = {
  //   exchangeProductPrice: 0, // 교환 상품
  //   cancelProductPrice: 0, // 취소 상품
  //
  //   // 차액
  //   get difference(){
  //     return this.exchangeProductPrice - this.cancelProductPrice
  //   },
  // }

  // 상품쿠폰재계산(-) row
  private productCoupon = {
    productCouponDiscountAmt: 0,
  };

  // 반품배송비추가(+) row
  private returnDelivery = {
    prepaid: false,
    deliveryAmt: 0, // 기본배송비
    remoteDeliveryAmt: 0, // 지역별추가
    adjustAmt: 0, // 금액조정

    get additionalReturnDelivery() {
      const { deliveryAmt, remoteDeliveryAmt, adjustAmt } = this;
      return deliveryAmt + remoteDeliveryAmt - adjustAmt;
    },
  };

  // 교환(재발송)배송비추가(+) row
  private exchangeDelivery = {
    prepaid: false,
    deliveryAmt: 0, // 기본배송비,
    remoteDeliveryAmt: 0, // 지역별추가,
    adjustAmt: 0, // 금액조정,

    get additionalExchangeDeliveryAmt() {
      const { deliveryAmt, remoteDeliveryAmt, adjustAmt } = this;
      return deliveryAmt + remoteDeliveryAmt - adjustAmt;
    },
  };

  // 장바구니쿠폰재계산(-) row
  private cartCoupon = {
    cartCouponDiscountAmt: 0,
  };

  // 배송비차액(+) row
  private deliveryAmtModified = {
    shippingNo: 0, // 배송번호
    beforeDeliveryAmt: 0, // 변경전배송비
    afterDeliveryAmt: 0, // 변경후배송비
    deliveryDifference: 0, // 배송비차액
    divided: false, //나눔
    combined: false, //묶음
    prepaid: false, //착불
    // 교환 배송비 정보(취소교환시에만 발생할 수 있는 케이스)
    exchangeShipping: {
      shippingNo: 0,
      deliveryDifference: 0,
      beforeDeliveryAmt: 0,
      afterDeliveryAmt: 0,
      divided: false,
      combined: false,
      prepaid: false,
    },
  };

  created() {
    this.deliveryCalculator = new DeliveryCalculator({
      claimType: claimTypes.EXCHANGE,
      claimClassType: this.claimClassType,
      amounts: this.amounts,
    });
    this.setExchangeAmountData();
  }

  private setExchangeAmountData() {
    this.setTotalProductAmt();
    this.setProductCoupon();
    this.setReturnDelivery();
    this.setExchangeDelivery();
    this.setCartCoupon();
    this.setDeliveryAmtModified();
    this.setExchangeShipping();
  }

  private setTotalProductAmt() {
    this.totalProductAmt.exchangeProductPrice = exchangeImmediateDiscountedAmt({
      claimOptions: this.exchangeOption,
      needSum: true,
    });
    this.totalProductAmt.cancelProductPrice = exchangeImmediateDiscountedAmt({
      claimOptions: this.claimedOption,
      needSum: true,
    });
    this.totalProductAmt.productAdjustAmt = this.amounts.productAdjustAmt;
  }

  private setProductCoupon() {
    this.productCoupon.productCouponDiscountAmt = this.amounts.refund.coupon.productCouponDiscountAmt;
  }

  private setReturnDelivery() {
    if (this.claimClassType !== claimClassTypes.RETURN_EXCHANGE) {
      return;
    }

    this.returnDelivery.prepaid = this.shipping.prepaid;

    const { returnDelivery, returnDeliveryAdjustAmt } = this.amounts;
    this.returnDelivery.deliveryAmt = returnDelivery.returnDeliveryAmt;
    this.returnDelivery.remoteDeliveryAmt = returnDelivery.returnRemoteDeliveryAmt;
    this.returnDelivery.adjustAmt = returnDeliveryAdjustAmt;
  }

  private setExchangeDelivery() {
    this.exchangeDelivery.prepaid = this.exchangeShipping?.prepaid;

    const { exchangeDelivery, exchangeDeliveryAdjustAmt } = this.amounts;
    this.exchangeDelivery.deliveryAmt = exchangeDelivery?.deliveryAmt;
    this.exchangeDelivery.remoteDeliveryAmt = exchangeDelivery?.remoteDeliveryAmt;
    this.exchangeDelivery.adjustAmt = exchangeDeliveryAdjustAmt;
  }

  private setCartCoupon() {
    // 교환 클레임인 경우 negate처리 해주어야 한다.
    this.cartCoupon.cartCouponDiscountAmt = -1 * this.amounts.refund.coupon.cartCouponDiscountAmt;
  }

  private setDeliveryAmtModified() {
    const beforeDeliveryAmt = this.deliveryCalculator.beforeDeliveryAmt();
    const afterDeliveryAmt = this.deliveryCalculator.afterDeliveryAmt();
    const deliveryDifference = this.deliveryCalculator.deliveryDifference();
    const { shippingNo, divided, combined, prepaid } = this.shipping;

    this.deliveryAmtModified = {
      ...this.deliveryAmtModified,
      shippingNo,
      beforeDeliveryAmt,
      afterDeliveryAmt,
      deliveryDifference,
      divided,
      combined,
      prepaid,
    };
  }

  private setExchangeShipping() {
    if (!this.exchangeShipping) {
      this.deliveryAmtModified.exchangeShipping = null;
      return;
    }

    const { totalPrepaidDeliveryAmt, totalDeliveryAmt } = this.amounts.exchangeDelivery;
    const { shippingNo, combined, prepaid, divided } = this.exchangeShipping;
    this.deliveryAmtModified.exchangeShipping = {
      ...this.deliveryAmtModified.exchangeShipping,
      afterDeliveryAmt: totalDeliveryAmt,
      beforeDeliveryAmt: 0,
      deliveryDifference: totalPrepaidDeliveryAmt,
      shippingNo,
      combined,
      prepaid,
      divided,
    };
  }
}
