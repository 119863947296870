import { GridProp } from '@/types';
import { GetMailMessagesRequest, ManageMessageStatus } from 'ncp-api-supporter';
import { addDay, getToday } from '@/utils/dateFormat';
import { OptionData } from '@/helpers/type';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { rowNumRenderer } from '@/utils/grid/rendererUtils';

export const getInitEmailHistoryQuery = (mallNo: number): GetMailMessagesRequest['params'] => {
  return {
    mallNo,
    page: 1,
    pageSize: 30,
    startDate: addDay(new Date(), -7),
    endDate: getToday(),
    messageStatus: null,
    keyword: '',
    searchType: 'RECEIVER',
  };
};

export const statusTypeOption: OptionData<ManageMessageStatus | 'ALL'>[] = [
  { value: 'ALL', name: 'ALL' },
  { value: 'COMPLETE ', name: 'MEMBER.MAIL.COMPLETE' },
  { value: 'PROGRESS ', name: 'MEMBER.MAIL.SEND_ING' },
  { value: 'FAIL', name: 'MEMBER.MAIL.FAIL' },
];

export const getEmailHistoryGridOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'MEMBER.MAIL.HISTORY_TITLE',
        name: 'title',
        align: 'left',
        minWidth: 550,
        formatter: ({ value }) => getAnchorHTML(value.toString()),
      },
      {
        header: 'MEMBER.MAIL.SEND_TARGET',
        name: 'receiverAddress',
        align: 'center',
      },
      {
        header: 'MEMBER.MAIL.SEND_STATUS',
        name: 'statusCode',
        align: 'center',
        formatter: ({ value }) => {
          switch (value) {
            case 'READY':
            case 'PROGRESS':
              return i18n.t('MEMBER.MAIL.SEND_ING').toString();
            case 'COMPLETE':
              return i18n.t('MEMBER.MAIL.COMPLETE').toString();
            case 'FAIL':
              return i18n.t('MEMBER.MAIL.FAIL').toString();
          }
        },
      },
      {
        header: 'MEMBER.MAIL.SEND_REQUEST_DATETIME',
        name: 'requestDateTime',
        align: 'center',
      },
    ],
    header: {
      align: 'center',
    },
    options: {
      rowHeaders: [
        {
          type: 'rowNum',
          renderer: props => {
            return rowNumRenderer(props.value);
          },
        },
      ],
    },
  };
};

export const emailTemplate = {
  title: 'title',
  content: 'content',
  usedType: 'usedType',
  sendMailType: 'sendMailType',
} as const;
