







































import { Vue, Component, Watch } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import {
  DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY,
  getDesignPopupGridProps,
  getSearchQuery,
} from '@/const/contents/designPopup';
import {
  DeleteDesignPopupsRequest,
  GetDesignPopupsPreviewPopupNoRequest,
  GetDesignPopupsRequest,
  PutDesignPopupsPopupNoDisplayStatusRequest,
} from 'ncp-api-supporter';
import { CheckGridEventProps, DesignPopupListRouterQuery } from '@/types';
import { PopupClose, throwMessagePopup, throwPopup } from '@/helpers/popup';
import { mall } from '@/utils/mall';

@Component({
  components: {
    Grid,
  },
})
export default class DesignPopup extends Vue {
  private gridProps = getDesignPopupGridProps(this.onGridCellEvent);
  private query = { ...DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY };

  private contents = [];
  private totalCount = 0;
  private selected = [];

  private created() {
    this.search();
  }

  @Watch('$route.query')
  private onQueryStringChanged() {
    this.search();
  }

  private search(): void {
    this.initQuery();

    const request: GetDesignPopupsRequest = { params: this.query };

    if (!this.query.mallNo || this.query.mallNo == -1) {
      Vue.delete(request.params, 'mallNo');
    }

    if (this.query.popupType && this.query.popupType === 'ALL') {
      Vue.delete(request.params, 'popupType');
    }

    if (!this.query.displayPageNo) {
      Vue.delete(request.params, 'displayPageNo');
    }

    this.$api.getDesignPopups(request).then(response => {
      if (response && response.status === 200) {
        this.contents = response.data.contents;
        this.contents.forEach(elem => {
          elem.registerAndUpdateDate = elem.registerDate;
          if (elem.updateDate) {
            elem.registerAndUpdateDate += ' ' + elem.updateDate;
          }
        });
        this.totalCount = response.data.totalCount;
      }
    });
  }

  private initQuery(): void {
    this.query = { ...DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY };
    const urlQuery = this.$route.query as DesignPopupListRouterQuery;
    this.query = getSearchQuery(this.query, urlQuery);
    if (!this.query.mallNo) {
      const malls = mall.mallsSortedByCreationDate;
      if (malls && malls.length > 0) {
        this.query.mallNo = malls[0].mallNo;
      }
    }
  }

  private deleteDesignPopups(): void {
    if (this.selected.length === 0) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.DELETE_SELECT_POPUP_EMPTY'));
      return;
    }

    throwMessagePopup(
      this.$t('DISPLAY.DESIGN_POPUP.DELETE_POPUPS_CONFIRM', { value: this.selected.length }),
      true,
      this.$t('DISPLAY.DESIGN_POPUP.DELETE_POPUPS_TITLE'),
      false,
      this.$t('CONFIRM'),
      this.$t('CANCEL'),
    ).then(result => {
      if (result.state === PopupClose.CONFIRM) {
        const deleteDesignPopupsRequest: DeleteDesignPopupsRequest = {
          params: {
            popupNos: this.selected.join(','),
          },
        };
        this.$api.deleteDesignPopups(deleteDesignPopupsRequest).then(() => {
          alert(this.$t('DISPLAY.DESIGN_POPUP.DELETE_SUCCESS'));
          this.search();
        });
      } else if (result.state === PopupClose.CLOSE) {
        return;
      }
    });
  }

  private addDesignPopups(): void {
    this.$router.push({ name: 'PopupAdd' });
  }

  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
  }

  private onItemClicked({ columnName, rowKey, targetType, nativeEvent }): void {
    if (targetType === 'cell') {
      switch (columnName) {
        case 'modifyBtnCell':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'A') {
            this.$router.push({
              name: 'PopupModify',
              query: { popupNo: `${rowKey}` },
            });
          }
          break;
      }
    }
  }

  private onGridCellEvent(key: string, event: Event, rowKey: string): void {
    switch (key) {
      case 'goModifyPage': {
        this.$router.push({
          name: 'PopupModify',
          query: { popupNo: `${rowKey}` },
        });
        break;
      }
      case 'changeStatus': {
        const item = this.contents.filter(c => c.popupNo === rowKey)[0];

        if (!item) {
          return;
        }

        const target = ((event.target as HTMLElement) as unknown) as { value: string | boolean };
        const changeToVisible = target.value === 'VISIBLE';

        let confirmMessage: string;
        let displayable: boolean;

        if (changeToVisible) {
          confirmMessage = this.$t('DISPLAY.DESIGN_POPUP.TO_VISIBLE_CONFIRM').toString();
          displayable = true;
        } else {
          confirmMessage = this.$t('DISPLAY.DESIGN_POPUP.TO_INVISIBLE_CONFIRM').toString();
          displayable = false;
        }

        throwMessagePopup(
          confirmMessage,
          true,
          this.$t('DISPLAY.DESIGN_POPUP.STATUS_CHANGE_TITLE'),
          false,
          this.$t('CONFIRM'),
          this.$t('CANCEL'),
        ).then(result => {
          if (result.state === PopupClose.CONFIRM) {
            const putDesignPopupsPopupNoDisplayStatusRequest: PutDesignPopupsPopupNoDisplayStatusRequest = {
              pathParams: {
                popupNo: rowKey,
              },
              params: {
                displayable,
              },
            };
            this.$api.putDesignPopupsPopupNoDisplayStatus(putDesignPopupsPopupNoDisplayStatusRequest).then(res => {
              if (res.data.isSuccess) {
                alert(this.$t('DISPLAY.DESIGN_POPUP.STATUS_CHANGE_SUCCESS'));
              }
            });
          } else if (result.state === PopupClose.CLOSE) {
            if (changeToVisible) {
              target.value = 'INVISIBLE';
            } else {
              target.value = 'VISIBLE';
            }
          }
        });
        break;
      }
      case 'displayPreviewLayer': {
        const getDesignPopupsPreviewPopupNo: GetDesignPopupsPreviewPopupNoRequest = {
          pathParams: {
            popupNo: rowKey,
          },
        };
        this.$api.getDesignPopupsPreviewPopupNo(getDesignPopupsPreviewPopupNo).then(res => {
          throwPopup({
            name: 'DesignPopupPreviewPopup',
            data: {
              popupDesignType: res.data.popupDesignType,
              content: res.data.content,
              pcDesign: res.data.pcDesign,
              mobileDesign: res.data.mobileDesign,
              popupSlideInfo: res.data.popupSlideInfo,
              visibleToday: res.data.visibleToday,
              platformTypes: res.data.platformTypes,
            },
          });
        });
        break;
      }
    }
  }
}
