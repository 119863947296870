




























import { Prop, Vue, Component } from 'vue-property-decorator';
import { ContractFilesContractHistoriesContent } from 'ncp-api-supporter';

@Component
export default class ContractFilesUpdateHistory extends Vue {
  @Prop() private data;
  @Prop() private onNegativeClick!: Function;

  private contents: ContractFilesContractHistoriesContent[] = [];
  private totalCount = 0;
  private moreView = 1;
  private DEFAULT_PAGE_SIZE = 7;

  private async getContractFiles() {
    const { data } = await this.$api.getContractFilesContractHistories({
      params: {
        page: 1,
        pageSize: this.DEFAULT_PAGE_SIZE * this.moreView,
        mallNo: this.data.mallNo,
        partnerNo: Number(this.$route.params.partnerNo),
      },
    });
    this.contents = data.contents;
    this.totalCount = data.totalCount;
  }

  private handleScroll(e) {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const isBottom = scrollHeight === Math.ceil(scrollTop + clientHeight);
    const needMoreView = this.DEFAULT_PAGE_SIZE * this.moreView < this.totalCount;
    if (isBottom && needMoreView) {
      setTimeout(() => {
        this.moreView += 1;
        this.getContractFiles();
      }, 500);
    }
  }

  mounted() {
    this.getContractFiles();
  }
}
