




















































































































































































































import {
  DomainByMall,
  GetMembersJoinConfig,
  NCPResponse,
  NotificationsConfigurationsResponse,
  MembersCommonJoinConfig,
} from 'ncp-api-supporter';
type MemberAuthenticationType = 'AUTHENTICATION_BY_EMAIL' | 'SMS_AUTHENTICATION' | 'AUTHENTICATION_BY_PHONE';
import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import PostRequestButton from '@/components/addition/PostRequestButton.vue';
import {
  getUsesOrNot,
  joinApproveSetting,
  i18nMallCreate,
  MallType,
  kcpAuthentication,
} from '@/const/contents/configuration/mallCreate';
@Component({
  components: { TextInput, ToolTip, RadioGroup, SelectBox, PostRequestButton },
})
export default class MemberAuthentication extends Vue {
  @PropSync('mall') private mallSync!: MallType;
  @PropSync('mallJoinConfig') private mallJoinConfigSync!: Record<string, any>; //TODO api 수정 후 타입 변경 GetMembersJoinConfig
  @Prop({ required: true }) private readonly isEditPage!: boolean;
  @Prop({ required: true }) private readonly notificationsConfig!: NotificationsConfigurationsResponse;
  @Prop({ required: true }) private readonly mallNo!: string | null;
  // private authenticationTimeType = authenticationTimeType;
  private getUsesOrNot = getUsesOrNot;
  private joinApproveSetting = joinApproveSetting;
  private i18nMallCreate = i18nMallCreate;
  private kcpAuthentication = kcpAuthentication;
  private prevMemberAuthenticationType: MemberAuthenticationType = this.mallJoinConfigSync?.memberAuthenticationType;
  private mallId = '';
  private postQuery: { mall_id: string } | null = null;
  private commonJoinConfig: MembersCommonJoinConfig = null;

  public useAuthentication = false;
  created() {
    if (this.isEditPage) {
      this.updateMallId();
      this.getMembersCommonJoinConfig(Number(this.mallNo));
    }
  }
  public setUseAuthentication(mallJoinConfig: Partial<GetMembersJoinConfig>) {
    this.mallJoinConfigSync = mallJoinConfig;
    this.$nextTick(() => (this.useAuthentication = true));
    this.initMemberAuthenticationType(mallJoinConfig);
  }
  private setMemberAuthenticationType() {
    if (this.notificationsConfig.emailUsed === true) {
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'memberAuthenticationType', 'AUTHENTICATION_BY_EMAIL'));
      return;
    }
    if (this.notificationsConfig.smsUsed === true) {
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'memberAuthenticationType', 'SMS_AUTHENTICATION'));
    } else if (this.mallJoinConfigSync.activeIdentityVerificationProvider === 'KCP') {
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'memberAuthenticationType', 'AUTHENTICATION_BY_PHONE'));
    } else {
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'memberAuthenticationType', 'NOT_USED'));
    }
  }
  private initMemberAuthenticationType(mallJoinConfig: Partial<GetMembersJoinConfig>) {
    const { memberAuthenticationType, activeIdentityVerificationProvider } = mallJoinConfig;
    const { emailUsed, smsUsed } = this.notificationsConfig;
    const condition =
      (memberAuthenticationType === 'SMS_AUTHENTICATION' && smsUsed === false) ||
      (memberAuthenticationType === 'AUTHENTICATION_BY_EMAIL' && emailUsed === false) ||
      (memberAuthenticationType === 'AUTHENTICATION_BY_PHONE' && activeIdentityVerificationProvider === 'NONE');
    if (condition) {
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'memberAuthenticationType', 'NOT_USED'));
    }
  }
  private async getMallId() {
    try {
      const { data }: NCPResponse<DomainByMall[]> = await this.$api.getDomainByMalls();
      const index = data.findIndex(domainInfo => domainInfo.mallNo === Number(this.mallNo));
      this.mallId = data[index].mallId;
    } catch (e) {
      console.error(e);
    }
  }
  private updateMallId() {
    this.getMallId();
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.postQuery = { mall_id: this.mallId };
  }
  private onChangeMemberAuthentication(value: boolean) {
    if (!this.isEditPage) return;

    if (value === true) {
      this.setMemberAuthenticationType();
      return;
    }
    if (!confirm(i18nMallCreate('MEMBER_AUTHENTICATION_CONFIRM').toString())) {
      this.$nextTick(() => (this.useAuthentication = true));
    }
  }

  private async getMembersCommonJoinConfig(mallNo: number) {
    this.commonJoinConfig = (await this.$api.getMembersCommonJoinConfig({ params: { mallNo } })).data;
  }

  private onChangeJoinApprove(value: boolean) {
    if (!this.isEditPage) return;

    this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'pendingStatusUsed', value));
  }

  private onChangePhoneAuthentication(value: 'KCP' | 'NONE') {
    if (value === 'KCP' && !this.mallJoinConfigSync.kcpSiteCode) {
      alert(i18nMallCreate('MEMBER_AUTHENTICATION_PHONE_AUTHENTICATION'));
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'activeIdentityVerificationProvider', 'NONE'));
    }
    if (!this.isEditPage || value === 'KCP') return;
    this.confirmPhoneAuthentication();
  }
  @Watch('mallJoinConfigSync.memberAuthenticationType')
  private savePrevMemberAuthenticationType(_, prevValue) {
    this.prevMemberAuthenticationType = prevValue;
  }
  private onChangeMemberAuthenticationType(value: MemberAuthenticationType) {
    if (!this.isEditPage) return;
    if (!confirm(this.$t('MALL.FORM.MEMBER_AUTHENTICATION_TYPE_CONFIRM', [i18nMallCreate(value)]).toString())) {
      this.$nextTick(() => (this.mallJoinConfigSync.memberAuthenticationType = this.prevMemberAuthenticationType));
    }
  }
  private confirmPhoneAuthentication() {
    if (this.mallJoinConfigSync.memberAuthenticationType === 'AUTHENTICATION_BY_PHONE') {
      alert(i18nMallCreate('MEMBER_AUTHENTICATION_TYPE_ALERT'));
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'activeIdentityVerificationProvider', 'KCP'));
      return;
    }
    if (!confirm(i18nMallCreate('MEMBER_AUTHENTICATION_PHONE_AUTHENTICATION_CONFIRM').toString())) {
      this.$nextTick(() => this.$set(this.mallJoinConfigSync, 'activeIdentityVerificationProvider', 'KCP'));
    }
  }
  private getConfigText(isUsed: boolean): string {
    return i18nMallCreate(isUsed ? 'MALL_USED' : 'MALL_USELESS');
  }

  private usableMemberAuthenticationType() {
    const usableType = [];

    if (this.commonJoinConfig?.used) {
      // SMS 인증
      [
        this.commonJoinConfig.memberName,
        this.commonJoinConfig.birthday,
        this.commonJoinConfig.sex,
        this.commonJoinConfig.mobileNo,
      ].every(item => item !== 'NOT_USED') && usableType.push('AUTHENTICATION_BY_PHONE');

      // 휴대폰 인증
      this.commonJoinConfig.mobileNo !== 'NOT_USED' && usableType.push('SMS_OCCUPANCY_AUTHENTICATION');
    } else {
      usableType.push('AUTHENTICATION_BY_PHONE');
      usableType.push('SMS_OCCUPANCY_AUTHENTICATION');
    }

    // 이메일 인증
    usableType.push('AUTHENTICATION_BY_EMAIL');
    console.log(usableType);

    return usableType;
  }
}
