export default Object.freeze({
  // PRODUCT_REVIEW_
  LIST_TITLE: '상품평 관리',
  INQUIRY: '상품평 조회',
  REPORT: '상품평 신고 내역',
  REVIEW_NO: '상품평번호',
  REGISTER_NAME: '작성자',
  REGISTER_NAME_PLACEHOLDER: '작성자 입력',
  SEARCH_TYPE: '상세검색',
  SEARCH_TYPE_PARTNER_NAME: '파트너사',
  STATUS_TYPE: '전시상태',
  DISPLAY_STATUS_TYPE_DISPLAY: '전시중',
  DISPLAY_STATUS_TYPE_BLIND: '블라인드',
  REVIEW_TYPE: '상품평 구분',
  REVIEW_TYPE_NORMAL: '일반 상품평',
  REVIEW_TYPE_BEST: '우수 상품평',
  REPORT_REASON: '신고사유',
  REPORT_REASON_TYPE_COPYRIGHT: '저작권 침해 및 기타사유',
  REPORT_REASON_TYPE_SLANDER: '욕설 또는 비방',
  REPORT_SEARCH_TYPE_REGISTER_NAME: '신고자',

  TABLE_TITLE: '상품조회결과',
  CHANGE_STATUS_BTN: '전시상태 변경',
  CHANGE_BEST_BTN: '상품평 구분 변경',

  HEADER_MALLNO: '쇼핑몰',
  HEADER_PARTNERNAME: '파트너사',
  HEADER_MEMBER: '작성자',
  HEADER_ORDERNO: '주문번호',
  HEADER_HISTORY: '변경이력',
  HEADER_REPORT_NO: '신고번호',
  HEADER_REPORT_YMDT: '신고일',
  HEADER_REPORT_REASON: '신고사유',
  HEADER_REPORT_CONTENT: '신고내용',
  HEADER_REPORT_MEMBER_NAME: '신고자',
  HEADER_REVIEW_CONTENT: '후기내용',

  REPORT_NO_DATA: '등록된 신고내역이 없습니다.',

  WRONG_MEMBER: '잘못된 사용자',
  WITHDRAWAL_MEMBER: '탈퇴회원',
  VIEW: '보기',
  POP_CRM: '회원정보',

  POPUP_CHANGE_STATUS: '전시상태 변경',
  SELECTED: '선택하신',
  SELECTED_CHANGES_DISPLAY_STATUS: '개 상품평에 대해 전시상태를 일괄 변경합니다.',

  LAYER_CHANGE_RESULT: '일괄변경 결과',
  CHANGED: '변경 완료',
  CHANGED_LABEL_1: '개 상품평 중',
  CHANGED_LABEL_2: '개 상품의 상품평 전시상태가 변경되었습니다.',
  CHANGED_LABEL_3: '개 상품의 상품평 구분이 변경되었습니다.',
  CHANGE_STATUS_SUCCESS_MSG: '전시상태가 변경 되었습니다.',
  CHANGE_BEST_SUCCESS_MSG: '상품평 구분이 변경 되었습니다.',
  CANT_CHANGED: '변경 불가',
  CANT_CHANGED_LABEL_1: '개 상품의 상품평 전시상태 변경이 불가합니다.',
  CANT_CHANGED_LABEL_2: '개 상품의 상품평 구분 변경이 불가합니다.',
  CHANGE_STATUS_UPDATED_MSG: '변경 전/후 전시상태 동일한 경우 변경 불가합니다.',
  CHANGE_BEST_UPDATED_MSG: '변경 전/후 상품평 구분이 동일한 경우 변경 불가합니다.',
  CHANGE_DELETED_MSG: '삭제된 상품평은 변경 불가합니다.',

  POPUP_CHANGE_BEST: '상품평 구분 변경',
  SELECTED_CHANGES_BEST: '건 상품평에 대해 상품평 구분을 일괄 변경합니다.',

  REVIEW_HISTORY: '상품평 변경 이력',
  HISTORY_CHANGE_DATE: '변경일',

  HISTORY_PRODUCT_REVIEW: '상품평구분',
  HISTORY_MANAGER: '처리자',
  HISTORY_REASON: '사유',

  ERR_NOT_SELECTED: '상품평을 선택해주세요.',

  DETAIL_TITLE: '상품평 상세정보',
  REGISTER_DATE_TIME: '등록일',
  PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  OPTION_NO: '옵션번호',
  OPTION_NAME: '옵션명',
  REPORT_RECOMMEND_CNT: '신고/추천 횟수',
  REPORT_CNT: '신고',
  RECOMMEND_CNT: '추천',
  RATING: '평점',
  REASON_PLACEHOLDER: '변경사유 입력.',
  CONTENT: '내용',
  CONTENT_CNT: '글자수',
  CONTENT_OPTIONAL: '내용(선택형)',
  ITEM: '항목',
  VALUE: '선택 값',
  IMAGE: '이미지',
  VIDEO: '동영상',
  SAVE_MSG: '저장되었습니다.',

  REPORT_DETAIL_TITLE: '상품평 신고 상세정보',
  REPORT_INFO: '신고 정보',
  REPORT_NO: '신고 번호',
  MEMBER_NAME: '신고자',
  REPORT_YMDT: '신고일',
  REPORT_CONTENT: '신고 내용',
  REVIEW_INFO: '상품평 정보',

  // MemberCrmPostDetail
  TAB_TITLE_REVIEW: '상품평 내역',
  TAB_TITLE_INQUIRY: '상품문의 내역',

  INQUIRYTYPE_PRODUCT: '상품',
  INQUIRYTYPE_DELIVERY: '배송',
  INQUIRYTYPE_CANCEL: '취소',
  INQUIRYTYPE_RETURN: '반품',
  INQUIRYTYPE_EXCHANGE: '교환',
  INQUIRYTYPE_REFUND: '환불',
  INQUIRYTYPE_OTHER: '기타',

  IQGRID_INQUIRYNO: '문의번호',
  IQGRID_REPLIED: '답변상태',
  IQGRID_MALLNO: '쇼핑몰',
  IQGRID_MALLPRODUCTNO: '상품번호',
  IQGRID_PRODUCTNAME: '상품명',
  IQGRID_ADMINYN: '담당자',
  IQGRID_ISSECRET: '비밀',
  IQGRID_INQUIRYTYPE: '문의유형',
  IQGRID_CONTENT: '문의내용',
  IQGRID_ORDERNO: '구매여부',
  IQGRID_MEMBER: '작성자',
  IQGRID_REGISTERDATETIME: '문의일시',
  IQGRID_REPLIEDDATETIME: '답변일시',

  ANSWER_COMPLETED: '답변완료',
  WAITING_FOR_REPLY: '답변대기',
  SECRET: '비밀',
  NORMAL: '일반',
  PURCHASE: '구매',
  DO_NOT_PURCHASE: '구매안함',
  WRONG_USER: '잘못된 사용자',

  SELECTION: '선택',
  PRODUCT_NUMBER: '상품번호',
  PARTNER: '파트너사',
  WRITER: '작성자',
  MANAGER: '담당자',

  ALL: '전체',
});
