export default Object.freeze({
  TITLE: 'CS처리내역 관리',
  TOTAL_TITLE_LEFT: '전체',
  TOTAL_TITLE_COMMON_ONE: '처리중',
  TOTAL_TITLE_COMMON_TWO: '건',
  FORM_ONE: '쇼핑몰',
  SELECT_ALL: '전체',
  FORM_TWO: '검색어',
  PLACEHOLDER: '검색어 입력',
  FORM_THREE: 'CS등록일시',
  FORM_FOUR: 'CS상태',
  FORM_FIVE: 'CS유형',
  FORM_SIX: 'CS경로',
  TABLE_LEFT_CSSTATUSTYPE_BTN: 'CS상태 변경',
  TABLE_LEFT_CSTYPE_BTN: 'CS유형 변경',
  TABLE_LEFT_DELETE_BTN: '삭제',
  SEARCH_TYPE_ORDER_NO: '주문번호',
  SEARCH_TYPE_ORDERER_NAME: '주문자명',
  SEARCH_TYPE_ORDERER_CONTACT1: '주문자연락처',
  SEARCH_TYPE_REGISTER_NAME: '등록자명',
  SEARCH_TYPE_UPDATE_ADMIN_NAME: '처리자명',
  CSSTATUS_TYPE_PROCESSING: '처리중',
  CSSTATUS_TYPE_DONE: '처리완료',
  CS_TYPE_ALL: '전체선택',
  CS_TYPE_PAY: '결제(입금)',
  CS_TYPE_PRODUCT: '주문상품',
  CS_TYPE_DELIVERY: '배송',
  CS_TYPE_CLAIM: '취소/교환/반품',
  CS_TYPE_REFUND: '환불',
  CS_TYPE_ETC: '기타',
  CS_PATH_TYPE_IN_BOUND: '인바운드',
  CS_PATH_TYPE_OUT_BOUND: '아웃바운드',
  CS_PATH_TYPE_ETC: '기타',
  CS_PATH_TYPE_SYSTEM: '처리로그',
  TABLE_HEADER_ONE: 'CS상태',
  TABLE_HEADER_TWO: '쇼핑몰',
  TABLE_HEADER_THREE: 'CS등록일시',
  TABLE_HEADER_FOUR: '주문번호',
  TABLE_HEADER_FIVE: '주문자명',
  TABLE_HEADER_SIX: '주문자 연락처',
  TABLE_HEADER_SEVEN: 'CS유형',
  TABLE_HEADER_EIGHT: 'CS처리내용',
  TABLE_HEADER_NINE: 'CS경로',
  TABLE_HEADER_TEN: '등록자명',
  TABLE_HEADER_ELEVEN: '처리자명',
  TABLE_HEADER_TWELVE: '삭제',
  TABLE_HEADER_THIRTEEN: '처리자번호',
  DELETE_CONFIRM: '해당 CS건을 삭제하시겠습니까?',
  CHECKROWS_ERROR: 'CS건을 선택해주세요.',
  CS_TYPE_LENGTH_ERROR: '조회하려는 CS유형을 체크해주세요.',
  CHANGE_SUCCESS: '저장되었습니다.',
  CS_MODIFY_POPUP_DESCRIPTION_BEFORE: '선택하신 ',
  CS_MODIFY_POPUP_DESCRIPTION_CSSTATUSTYPE: '건 CS내역에 대해 CS상태를 일괄 변경합니다.',
  CS_MODIFY_POPUP_DESCRIPTION_CSTYPE: '건 CS내역에 대해 CS유형을 일괄 변경합니다.',
});
