




import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolTipOption } from '@/types/tooltip';

// Default ToolTip Content
// 전달된 message 길이만큼 툴팁 노출 - message 계행은 \n 으로 구분

@Component
export default class Default extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption;

  private content = '';

  created() {
    const message = this.option.message || '';
    this.content = message
      .split('\n')
      .map(str => str.trim())
      .join('\n');
  }

  private get tooltipContent() {
    return this.option.hasNoPreLine ? this.content : `<span style="white-space: pre;">${this.content}</span>`;
  }
}
