import { OptionData } from '@/helpers/type';
import { GetDeliveryTemplate, YorN } from 'ncp-api-supporter';

export const selectOption: OptionData<YorN>[] = [
  { value: 'Y', name: 'PRODUCT.ADD.DELIVERY_Y' },
  { value: 'N', name: 'PRODUCT.ADD.DELIVERY_N' },
];

export const toolTipMsg = 'PRODUCT.ADD.DELIVERY_INTERNATION_PRODUCT_TOOLTIP';

export interface DefaultDeliveryTemplate extends Partial<GetDeliveryTemplate> {
  deliveryTypeLabel?: string;
}
export const defaultDeliveryTemplate: DefaultDeliveryTemplate = {
  // deliveryType: '',
  deliveryTypeLabel: '',
  // deliveryCompanyType: '',
  deliveryFee: {
    deliveryConditionType: null,
    deliveryConditionTypeLabel: '',
    deliveryAmt: 0,
    returnDeliveryAmt: 0,
  },
  releaseWarehouse: {
    substitutionText: '',
    address: {
      addressStr: '',
    },
  },
  returnWarehouse: {
    substitutionText: '',
    address: {
      addressStr: '',
    },
  },
};
