












import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { OptionData } from '@/helpers/type';
import SelectBox from '@/components/common/SelectBox.vue';

@Component({
  components: {
    TextInput,
    SelectBox,
  },
})
export default class TdRefundType extends Vue {
  @Prop({ required: true })
  private readonly isModifyMode!: boolean;

  @Prop({ required: true })
  private readonly showModifyButton!: boolean;

  @Prop({ required: true })
  private readonly displayRefundTypeLabel!: string;

  @Prop({ required: true })
  private readonly refundPayTypeLabel!: string;

  @Prop({ required: true })
  private readonly refundTypeOptions!: OptionData<string>;

  @PropSync('refundType', { required: true })
  private refundTypeSync!: boolean;
}
