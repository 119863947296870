export default [
  { value: 'DEPOSIT_WAIT', name: '입금대기' },
  { value: 'PAY_DONE', name: '결제완료' },
  { value: 'PRODUCT_PREPARE', name: '상품준비중' },
  { value: 'DELIVERY_PREPARE', name: '배송준비중' },
  { value: 'DELIVERY_ING', name: '배송중' },
  { value: 'DELIVERY_DONE', name: '배송완료' },
  { value: 'BUY_CONFIRM', name: '구매확정' },
  { value: 'CANCEL_DONE', name: '취소완료' },
  { value: 'RETURN_DONE', name: '반품완료' },
  { value: 'EXCHANGE_DONE', name: '교환완료' },
  { value: 'PAY_WAIT', name: '결제대기' },
  { value: 'PAY_CANCEL', name: '결제포기' },
  { value: 'PAY_FAIL', name: '결제실패' },
  { value: 'DELETE', name: '삭제' },
  { value: 'EXCHANGE_WAIT', name: '교환대기' },
  { value: 'REFUND_DONE', name: '환불완료' },
];
