export default Object.freeze({
  //CLAIM_CANCELPOPUP
  REFUND_AMOUNT: '환불금액',
  REFUND_A_PLUS_B: '환불금액 ⓐ + ⓑ',
  RESTITUTION: '적립금사용반환',
  CREDIT: '카드취소/현금환불액',
  METHOD: '환불방법',
  CANCELLATIONAFTERPAYMENT: '결제 후 취소신청',
  PROCESSINGDETAILS: '취소처리내역',
  CDESC4:
    '간편결제 또는 PG결제건은 취소금액만큼 부분취소 처리됩니다. 단, 일부 신용카드 또는 휴대폰결제 등 경우에 따라 부분취소가 안될 경우 환불방법을 ‘현금환불’로 변경하여 현금으로 환불처리 해주세요.',
  DESC2: '무통장입금/가상계좌 입금건은 반드시 환불절차를 완료해주세요.',
  CDESC5: '상품준비중/배송준비중 상품을 취소신청 할 경우 파트너사의 출고여부 확인 절차 후 취소처리됩니다.',
  CDESC1: '‘입금대기’상품의 주문취소 시 전체취소만 가능하며, 별도의 환불처리 절차가 필요 없습니다.',
  CDESC6:
    '네이버페이 주문형 주문의 NCP 화면 내 금액은 예상 금액이며, 네이버페이의 조정 및 정책에 따라 변경될 수 있습니다. ',
  CDESC7: '네이버페이 주문형 주문의 최종 환불 금액은 네이버페이 판매자센터를 통해 확인해 주세요.',
  DESC4: '반품 후 수거된 상품의 재고는 [재고복원] 신청한 경우에만 환불완료 이후 자동으로 복원처리 됩니다.',
  DESC1: '간편결제 또는 PG결제건은 반품금액만큼 부분취소 처리됩니다.',
  MERCHANDISE2: '취소상품',
  CLAIMNO: '클레임번호',
  AVAILABLE: '반품할 수 있는 상품이 없습니다.',
  PARTNERNAME: '파트너사',
  MALLPRODUCTNO: '상품번호',
  PRODUCTNAME: '상품명',
  COL4: '수량',
  COL55: '취소수량',
  COL15: '반품수량',
  COL6: '판매가<br>(할인적용가)',
  COL7: '상품합계',
  ORDERSTATUS: '주문상태',
  DELIVERY: '배송보류',
  OUT_OF_STOCK_SYSTEM: '재고부족(시스템)',
  CANCEL_BEFORE_PAY: '입금전취소',
  COL99: '취소구분/사유',
  COL11: '귀책대상',
  EXTERNALMAPPINGKEY: 'N상품 주문번호:',
  SET: '세트',
  ADD: '추가',
  COUNT: '세트구성:',
  CHANGE_MIND: '단순변심(색상,사이즈)',
  DEFECTIVE_PRODUCT: '상품불량/파손',
  WRONG_DELIVERY: '배송누락/오배송',
  WRONG_PRODUCT_DETAIL: '상품상세 정보와 다름',
  DELAY_DELIVERY: '판매자 배송 지연',
  OUT_OF_STOCK: '상품 품절/재고 없음',
  OTHERS_SELLER: '기타(판매자 귀책)',
  OTHERS_BUYER: '기타(구매자 귀책)',
  CHANGE: '변경',

  CANCELLATIONITEMAMOUNT: '취소상품 금액상세',
  CANCELLATIONITEMAMOUNT2: '취소상품금액',
  ADDITIONALDISCOUNT: '추가할인',
  RECALCULATION: '상품쿠폰 재계산',
  CANCELLATIONAMOUNT: '취소금액합계 ⓐ',
  RESTORE: '복원예정',
  JAEGYESANORHIPPING: '할인/배송비재계산',
  CHANGEDISCOUNTAMOUNT: '할인금액변경 ①',
  JAEGYESANKUPON: '주문쿠폰 재계산',
  SHIPPINGCOSTCHANGE: '배송비변경 ②',
  COSTVARIANCE: '배송비차액',
  DELIVERYCOST: '변경전배송비',
  COLLECTIBLE: '착불',
  DELIVERYSHIPPINGCOST: '변경후배송비',
  NO_COUPON_APPLIED: '(적용된 쿠폰 없음)',
  COUPON_NUMBER: '(쿠폰번호:',
  SHARING: '나눔',
  BUNDLE: '묶음',
  EXCHANGE_DELIVERY_ADDITIONAL_TEXT: '(*이전 클레임 판매자귀책 배송비 차감)',
  SHIPPINGNUMBER: '배송번호',
  BURDEN: '판매자 부담',
  TOTALRECALCULATION: '재계산합계 ①+②=ⓑ',
  TOTALRECALCULATION_C: '재계산합계 ①+②=ⓒ',
  DELIVERYTOOLTIPTITLE: '착불 배송비 가이드',
  DELIVERYTOOLTIP1:
    '배송비는 상품에 적용된 배송비 템플릿의 ‘묶음그룹 > 결제방식 > 선결제/착불’ 설정에 따라 부과됩니다.',
  DELIVERYTOOLTIP2: '착불인 경우 발생하는 배송비는 표기되나 최종 금액에 반영되지 않습니다.',
  REFUND: '환불',
  EXPECTATION: '예상',
  AMOUNT: '금액',
  CANCEL_DEPOSIT: '미입금 취소처리',
  PAYCO: '페이코',
  CASH: '현금환불',
  PAYPAL: '페이팔',
  STRIPE: '스트라이프',
  ZERO: '0원환불',
  DUMMY: '외부연동주문',
  KCP_CREDIT: 'KCP_신용카드',
  KCP_MOBILE: 'KCP_휴대폰결제',
  KCP_POINT: 'KCP_포인트결제',
  KCP_GIFT: 'KCP_상품권',
  KAKAOPAY: '카카오페이',
  SAMSUNGPAY: '삼성페이',
  NAVEREASYPAY: '네이버페이 결제형',
  LGUPLUS: 'LG유플러스',
  TYPE_CREDIT: '페이팔PRO',
  CREDIT_CARD: '신용카드',
  VIRTUAL_ACCOUNT: '가상계좌',
  REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체',
  ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  ESCROW_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체-에스크로',
  ZERO_PAY: '0원결제',
  MOBILE: '휴대폰결제',
  LIIVMATE: 'LIIVMATE포인트',
  ACCUMULATION: '적립금 복원',
  INICIS: '이니시스',
  NAVERPAY: '네이버페이 주문형',
  ACCOUNT: '현금환불',
  CHANGEREFUNDTYPE: '변경',
  PG: 'PG초과',
  ZEROREFUND: '환불금액없음',
  REFUNDACCOUNTNO: '환불계좌번호',
  bankcode000: '미확인은행',
  bankcode002: '산업은행',
  bankcode003: '기업은행',
  bankcode004: '국민은행',
  bankcode005: '외환은행',
  bankcode007: '수협',
  bankcode008: '수출입은행',
  bankcode011: 'NH농협은행',
  bankcode012: '지역농축협',
  bankcode020: '우리은행',
  bankcode023: 'SC제일은행',
  bankcode027: '한국씨티은행',

  bankcode031: '대구은행',
  bankcode032: '부산은행',
  bankcode034: '광주은행',
  bankcode035: '제주은행',
  bankcode037: '전북은행',
  bankcode039: '경남은행',

  bankcode045: '새마을금고',
  bankcode048: '신협',

  bankcode050: '상호저축은행',
  bankcode054: 'HSBC은행',
  bankcode055: '도이치은행',

  bankcode064: '산림조합중앙회',

  bankcode071: '우체국',
  bankcode081: 'KEB하나은행',
  bankcode088: '신한은행',

  bankcode089: '케이뱅크',
  bankcode090: '카카오뱅크',

  bankcode209: '유안타증권',
  bankcode218: 'KB증권',
  bankcode230: '미래에셋증권',
  bankcode238: '미래에셋대우증권',
  bankcode240: '삼성증권',
  bankcode243: '한국투자증권',
  bankcode247: 'NH투자증권',
  bankcode261: '교보증권',
  bankcode262: '하이투자증권',
  bankcode263: 'HMC투자증권',
  bankcode264: '키움증권',
  bankcode265: '이베스트투자증권',
  bankcode266: 'SK증권',
  bankcode267: '대신증권',
  bankcode268: '솔로몬증권',
  bankcode269: '한화투자증권',
  bankcode270: '하나금융투자',
  bankcode278: '신한금융투자',
  bankcode279: 'DB금융투자',
  bankcode280: '유진투자증권',
  bankcode287: '메리츠종합금융증권',
  bankcode290: '부국증권',
  bankcode291: '신영증권',
  bankcode292: '케이프투자증권',

  bankcode901: '미즈호은행',
  bankcode905: 'UFJ은행',
  bankcode909: '미쓰이스미토모은행',
  bankcode910: '리소나은행',
  ACCOUNTNO: '계좌번호',
  ACCOUNTHOLDER: '예금주',
  SCHEDULERECALCULATION: '지급(예정)혜택재계산',
  RESERVESRECALCULATION: '지급(예정)적립금재계산',
  AFTERTHECHANGE: '변경후',
  BEFORETHECHANGE: '변경전',
  APPLYCANCEL: '취소신청',

  BUYER: '구매자',
  SELLER: '판매자',

  SELECT_ONE: '{type}상품을 하나 이상 선택해주세요.',

  BUTTON_CLOSE: '닫기',

  CANCELLBEFOREDEPOS: '입금 전 취소신청',

  ALTER: '요청금액이 최초 실 결제금액을 초과하였습니다. 환불방법을 현금환불로 변경하여 신청해 주세요.',
  ALTER1: '환불은행을 선택해 주세요.',
  ALTER2: '환불계좌번호를 입력해 주세요.',
  ALTER3: '예금주를 입력해 주세요',
  PENDING_DELIVERY: '[배송보류]',
  CONFIRM: '배송보류 주문 건이 포함되어 있습니다.\n클레임 처리 하시겠습니까?',
  RESERVATION_CONFIRM: '예약주문 건이 포함되어 있습니다.\n클레임 처리 하시겠습니까?',
  CONFIRM1: '취소하시겠습니까?',
  APPROVAL2: '취소승인',
  CONFIRM2: '반드시 판매자를 통해 상품출고 여부를 확인 후 진행하세요!\n취소승인 처리하시겠습니까?',
  CONFIRM3: '환불처리 하시겠습니까?',
  PROCESS_SUCCESS_MESSAGE: '처리되었습니다.',
  SAVE_BTN: '변경사항 저장',
  CLOSE_BTN_CONFIRM: '취소신청을 종료하시겠습니까?',
});
