













































































































import { Vue, Component, Watch, PropSync } from 'vue-property-decorator';
import {
  addMonth,
  addYear,
  firstDayOfMonth,
  getDateDiff,
  getFirstAndEndDay,
  getSelectMonths,
  getSelectYears,
} from '@/utils/dateFormat';
import { getPeriodMembersSearchOptions as getSearchOptions } from '@/views/contents/statistics/members/searchOptions';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import { defaultDateInfo, defaultParams, yesterday } from '@/views/contents/statistics/members/PeriodMembers';
import { PeriodType, StatisticSynthesisPeriod } from '@/types';
import { PERIOD_TYPE } from '@/const/contents/statistics';
import { today } from '@/views/contents/product/basic/ProductAdd';
@Component({
  components: {
    MallSelect,
    SelectBox,
    DateRangePicker,
    RadioGroup,
    SearchButton,
    CheckBoxGroup,
  },
})
export default class PeriodMemberForm extends Vue {
  @PropSync('period')
  private periodSync: null | StatisticSynthesisPeriod;
  private params = defaultParams();

  private searchOptions = getSearchOptions();

  private periodType: PeriodType = PERIOD_TYPE.PERIOD;
  private memberType = 'ALL';
  // private selectedOpenId = 'PAYCO,NAVER,KAKAO,FACEBOOK'; 그랜드 오픈이후 스팩
  private selectYear = null;
  private selectMonth = null;

  private get rangeOfYear(): number {
    const beforeFiveYears = addMonth(firstDayOfMonth(), -59);
    const [year] = beforeFiveYears.split('-');
    return new Date().getFullYear() - Number(year);
  }
  private yearList = getSelectYears(this.$t('YEAR').toString(), true, this.rangeOfYear);
  private monthList = getSelectMonths(this.$t('MONTH').toString());

  @Watch('periodType')
  private resetDateParams() {
    this.changeSelectDate();
  }
  @Watch('$route.query')
  private resetParams() {
    if (!this.$route.query?.mallNo && this.$route.query?.page) return;
    if (!this.$route.query?.mallNo) this.reset();
  }

  private get isMonthPeriodType(): boolean {
    return this.periodType === PERIOD_TYPE.MONTH;
  }

  private changeSelectDate() {
    if (this.isMonthPeriodType) {
      const { startYmd, endYmd } = getFirstAndEndDay(this.selectYear, this.selectMonth);

      this.$set(this.params, 'startDate', startYmd);
      this.$set(this.params, 'endDate', endYmd);
    } else {
      this.$set(this.params, 'startDate', yesterday);
      this.$set(this.params, 'endDate', yesterday);
    }
  }

  private validateMallNoSelected() {
    if (!this.params.mallNo) {
      alert(this.$t('쇼핑몰을 먼저 선택해주세요.'));
    }
  }

  private validationInfoBy = {
    overFiveYears({ comparisonDate, startDate }, msgType) {
      const beforeFiveYears = addYear(comparisonDate, -5);
      const overFiveYears = getDateDiff(beforeFiveYears, startDate, 'day') < 0;
      return {
        isOver: overFiveYears,
        date: overFiveYears ? beforeFiveYears : startDate,
        msg: `STATISTIC.MEMBER.VERIFY_MESSAGE_${msgType}_MAX_RANGE`,
      };
    },
    overThreeMonths({ startDate, endDate }, msgType = PERIOD_TYPE.PERIOD) {
      const beforeThreeMonths = addMonth(endDate, -3);
      const overThreeMonths = getDateDiff(beforeThreeMonths, startDate, 'day') < 0;
      return {
        isOver: overThreeMonths,
        date: overThreeMonths && beforeThreeMonths,
        msg: `STATISTIC.MEMBER.VERIFY_MESSAGE_${msgType}_MAX_RANGE`,
      };
    },
    [PERIOD_TYPE.PERIOD]: {
      checkRange({ startDate, endDate }) {
        const overFiveYears = this.overFiveYears({ comparisonDate: today, startDate }, PERIOD_TYPE.MONTH);
        return overFiveYears.isOver ? overFiveYears : this.overThreeMonths({ startDate, endDate });
      },
      undoChange(beforeThreeMonths) {
        this.$set(this.params, 'endDate', null);
        this.$set(this.params, 'startDate', beforeThreeMonths);
        this.$nextTick(() => this.$set(this.params, 'endDate', beforeThreeMonths));
      },
    },
    [PERIOD_TYPE.MONTH]: {
      checkRange({ startDate }) {
        return this.overFiveYears({ comparisonDate: firstDayOfMonth(), startDate }, PERIOD_TYPE.MONTH);
      },
      undoChange(beforeFiveYears) {
        const [year, month] = beforeFiveYears.split('-');
        this.selectYear = year;
        this.selectMonth = month;
        this.$set(this.params, 'startDate', beforeFiveYears);
      },
    },
  };

  private validateDateRange(): boolean {
    const { checkRange, undoChange } = this.validationInfoBy[this.periodType];

    const { date, msg, isOver } = checkRange.call(this.validationInfoBy, this.params);

    if (isOver) {
      alert(this.$t(msg));
      undoChange.call(this, date);
      return !date;
    }

    if (!this.params?.joinRouteTypes) {
      alert(this.$t(`STATISTIC.MEMBER.VERIFY_MESSAGE_PLATFORM`));
      return false;
    }

    return true;
  }

  private reset() {
    this.params = defaultParams();
    this.resetDetailFormValues();
    this.periodSync = null;
    this.$router.replace({ query: null }).catch(() => null);
  }

  // param이 아닌, 폼 컴포넌트 내부에서만 사용하는 form value 리셋
  private resetDetailFormValues() {
    this.periodType = PERIOD_TYPE.PERIOD;
    this.memberType = 'ALL';
    // this.selectedOpenId = 'PAYCO,NAVER,KAKAO,FACEBOOK'; 그랜드 오픈이후 스팩
    this.isMonthPeriodType && this.resetDate();
  }

  private resetDate() {
    const { year, month } = defaultDateInfo();
    this.selectYear = year;
    this.selectMonth = month;
  }
  created() {
    this.resetDate();
    this.changeSelectDate();
  }
}
