import { GridEvent, GridProp } from '@/types';
import { termsHistoryMainTitleType, termsHistorySelectType, TermsSelectOption } from '@/types/terms';
import {
  GetTermsHistoriesRequest,
  History,
  TermsType,
  TermsCustomHistories,
  TermsCustomResponse,
} from 'ncp-api-supporter';
import { getTermsHistoryTitle, getTermsTypeList, i18nForTerms } from '@/views/contents/configuration/basic/terms/Terms';
import { addDay, getDateSpaceTime, getStrDate, isSameOrAfter } from '@/utils/dateFormat';
import { getButtonHTML, getSpanHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { DEFAULT_GRID_PAGE_SIZE_KEY } from '@/const/default';
import { today } from '@/views/contents/product/basic/ProductAdd';
import { CustomCellRendererProps } from '@/views/contents/product/basic/GridProps';
import { selectRenderer } from '@/utils/grid/rendererUtils';

export const FAIR_LOGO = {
  KR_VERTICAL: 'KR_VERTICAL',
  KR_SYMBOL: 'KR_SYMBOL',
  KR_HORIZONTAL: 'KR_HORIZONTAL',
  KR_HORIZONTAL_SMALL: 'KR_HORIZONTAL_SMALL',
  KR_GRAY: 'KR_GRAY',
  EN_VERTICAL: 'EN_VERTICAL',
  EN_HORIZONTAL: 'EN_HORIZONTAL',
} as const;

export enum TERMS_TYPE {
  MALL_INTRODUCTION = 'MALL_INTRODUCTION',
  USE = 'USE',
  PI_PROCESS = 'PI_PROCESS',
  PI_COLLECTION_AND_USE_REQUIRED = 'PI_COLLECTION_AND_USE_REQUIRED',
  PI_COLLECTION_AND_USE_OPTIONAL = 'PI_COLLECTION_AND_USE_OPTIONAL',
  PI_PROCESS_CONSIGNMENT = 'PI_PROCESS_CONSIGNMENT',
  PI_THIRD_PARTY_PROVISION = 'PI_THIRD_PARTY_PROVISION',
  PI_SELLER_PROVISION = 'PI_SELLER_PROVISION',
  PI_COLLECTION_AND_USE_ON_ORDER = 'PI_COLLECTION_AND_USE_ON_ORDER',
  ORDER_INFO_AGREE = 'ORDER_INFO_AGREE',
  CLEARANCE_INFO_COLLECTION_AND_USE = 'CLEARANCE_INFO_COLLECTION_AND_USE',
  TRANSFER_AGREE = 'TRANSFER_AGREE',
  PI_COLLECTION_AND_USE_FOR_GUEST_ON_ARTICLE = 'PI_COLLECTION_AND_USE_FOR_GUEST_ON_ARTICLE',
  WITHDRAWAL_GUIDE = 'WITHDRAWAL_GUIDE',
  ACCESS_GUIDE = 'ACCESS_GUIDE',
}

export const TERMS_HISTORY_MAIN_TITLE = {
  USE: 'TERMS_OF_SERVICE',
  PI_PROCESS: 'PRIVACY_POLICY',
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  TERMS_CUSTOM: 'TERMS_CUSTOM',
  WITHDRAWAL: 'WITHDRAWAL',
} as const;

export const pITypeList = [
  TERMS_TYPE.PI_COLLECTION_AND_USE_REQUIRED,
  TERMS_TYPE.PI_COLLECTION_AND_USE_OPTIONAL,
  TERMS_TYPE.PI_PROCESS_CONSIGNMENT,
  TERMS_TYPE.PI_THIRD_PARTY_PROVISION,
  TERMS_TYPE.PI_COLLECTION_AND_USE_ON_ORDER,
  TERMS_TYPE.PI_SELLER_PROVISION,
  TERMS_TYPE.ORDER_INFO_AGREE,
  TERMS_TYPE.CLEARANCE_INFO_COLLECTION_AND_USE,
  TERMS_TYPE.TRANSFER_AGREE,
  TERMS_TYPE.PI_COLLECTION_AND_USE_FOR_GUEST_ON_ARTICLE,
];

export const withdrawalTypeList = [TERMS_TYPE.ACCESS_GUIDE, TERMS_TYPE.WITHDRAWAL_GUIDE];

const getSelectOption = (termsTypeList: TermsType[]) => {
  const selectOption: termsHistorySelectType[] = [{ name: `CONFIGURATION.TERMS.ALL`, value: 'ALL' }];
  termsTypeList.forEach(termsType => {
    selectOption.push({ name: `CONFIGURATION.TERMS.SELECT_NAME_${termsType}`, value: termsType });
  });
  return selectOption;
};

export const getTermsCustomSelectOption = (termsCustom: TermsCustomResponse[]) => {
  const sortedTerms = termsCustom.sort(({ categoryType }) => (categoryType === 'MEMBER' ? -1 : 1));

  return [
    { name: 'CONFIGURATION.TERMS.ALL', value: 'ALL' },
    ...sortedTerms.map(({ termsName, categoryType, required, customTermsNo: value, deleted }) => {
      const deletePrefix = deleted ? `- ${i18n.t('DELETE')}` : '';
      const prefix =
        categoryType === 'MEMBER' ? i18n.t('CONFIGURATION.TERMS.MEMBER') : i18n.t('CONFIGURATION.TERMS.ORDER');
      const requiredText = required ? i18n.t('CONFIGURATION.TERMS.MUST') : i18n.t('CONFIGURATION.TERMS.OPTIONAL');

      return { name: `${prefix} > ${termsName}${requiredText} ${deletePrefix}`, value: value.toString() };
    }),
  ];
};

export const getTermsSelectOption = (): TermsSelectOption => {
  const fairLogo = [];
  for (const logo in FAIR_LOGO) {
    fairLogo.push({ name: `CONFIGURATION.TERMS.${logo}`, value: logo });
  }

  const termsHistory = {
    depth1: [
      { name: 'CONFIGURATION.TERMS.TERMS_OF_SERVICE', value: 'USE' },
      { name: 'CONFIGURATION.TERMS.PRIVACY_POLICY', value: 'PI_PROCESS' },
      { name: 'CONFIGURATION.TERMS.PERSONAL_INFORMATION', value: 'PERSONAL_INFORMATION' },
      { name: 'CONFIGURATION.TERMS.PERSONAL_INFORMATION_CUSTOM', value: 'TERMS_CUSTOM' },
      { name: 'CONFIGURATION.TERMS.WITHDRAWAL', value: 'WITHDRAWAL' },
    ],
    depth2: {
      [TERMS_HISTORY_MAIN_TITLE.PERSONAL_INFORMATION]: getSelectOption(pITypeList),
      [TERMS_HISTORY_MAIN_TITLE.WITHDRAWAL]: getSelectOption(withdrawalTypeList),
    },
  };
  return { fairLogo, termsHistory };
};

export const getTermsRadioBoxOption = () => {
  return {
    getUsed: name => {
      return {
        name: `radio-${name}`,
        data: [
          {
            id: `use-${name}`,
            value: true,
            display: name === 'useFairLogo' ? 'CONFIGURATION.TERMS.EXPOSURE_Y' : 'CONFIGURATION.TERMS.USED_Y',
          },
          {
            id: `not-use-${name}`,
            value: false,
            display: name === 'useFairLogo' ? 'CONFIGURATION.TERMS.EXPOSURE_N' : 'CONFIGURATION.TERMS.USED_N',
          },
        ],
      };
    },
  };
};

export const getTermsToolTipOption = () => {
  return {
    effectiveDay: {
      name: 'List',
      style: {
        width: '870px',
      },
      listOption: {
        mainMessage: [
          i18nForTerms('EFFECTIVE_DAY_NOTICE_1'),
          i18nForTerms('EFFECTIVE_DAY_NOTICE_2'),
          `<span class="text-red">${i18nForTerms('EFFECTIVE_DAY_NOTICE_3')}</span>`,
        ],
      },
    },
    fairLogo: {
      message: i18nForTerms('FAIR_LOGO_MESSAGE'),
    },
  };
};

// terms history
export const historyNoticeList = [i18nForTerms('HISTORY_NOTICE')];
export const historyGridDisplayOptions = {
  showsTopArea: false,
  showsRightArea: false,
  hasSettingButton: false,
  hasPageSizeSelector: false,
  hasExcelDownloadButton: false,
  pageSizeKey: DEFAULT_GRID_PAGE_SIZE_KEY,
  pageSizeList: null,
  showPageNavigation: true,
};
export const getHistoryGridProp = (): Omit<GridProp, 'columns'> => {
  return {
    header: {},
    options: {
      rowHeaders: ['rowNum'],
      // heightResizable: true,
      bodyHeight: 250,
      minBodyHeight: 60,
      columnOptions: {
        resizable: true,
      },
      pageOptions: {
        perPage: 2,
        page: 1,
      },
      rowHeight: 'auto',
    },
  };
};

const getRegisteredDateTimeColumn = () => [
  {
    header: 'CONFIGURATION.TERMS.REGISTER_DATE',
    name: 'registeredDateTime',
    align: 'center',
    minWidth: 50,
    formatter: ({ value }) => getDateSpaceTime(value.toString()),
  },
];

const getIpColumn = () => [
  {
    header: 'CONFIGURATION.TERMS.IP',
    name: 'ip',
    align: 'center',
    minWidth: 60,
  },
];

// 저장일, 처리자, IP
const getRegisterIdColumn = () => [
  {
    header: 'CONFIGURATION.TERMS.ADMIN',
    name: 'registerId',
    align: 'center',
    minWidth: 110,
    ellipsis: true,
    formatter: ({ row }) => {
      const { registerId, registerName } = row;

      if (!registerId) {
        return '-';
      }

      return `${registerId}(${registerName})`;
    },
  },
];

// 제목
const getTitleColumns = (callback: (row: History) => string) => [
  {
    header: 'CONFIGURATION.TERMS.TERM_TITLE',
    name: 'title',
    align: 'center',
    minWidth: 120,
    ellipsis: true,
    formatter: ({ row }) => callback(row),
  },
];

// 상세보기
const getDetailsColumns = () => [
  {
    header: 'CONFIGURATION.TERMS.SHOW',
    name: 'termsNo',
    align: 'center',
    minWidth: 80,
    formatter: () => getButtonHTML(i18n.t('DETAILS').toString(), 'details'),
  },
];

// 이용약관, 개인정보처리방침 컬럼

// 시행 예정 7일 이전부터 수정,삭제 불가 (삭제 버튼 미노출, 수정 셀렉트박스 비활성화처리)
const LIMIT_DAYS = 8;

const termsOfServiceAndPiProcessColumns = (onGridChangeEvent: GridEvent['onGridChangeEvent'], termsNo?: number) => [
  {
    header: 'CONFIGURATION.TERMS.EFFECTIVE_DAY',
    name: 'enforcementDateTime',
    align: 'center',
    minWidth: 80,
    formatter: ({ row }) => {
      const date = row.enforcementDateTime?.toString();
      const strDate = getStrDate(date);
      const isDeletable = isSameOrAfter(strDate, addDay(today, LIMIT_DAYS));

      // 시행 8일전 삭제 가능 p34
      if (isDeletable) {
        return `${strDate}<br/>${getSpanHTML(i18n.t('DELETE').toString(), 'delete')}`;
      }
      return strDate;
    },
  },
  ...getRegisteredDateTimeColumn(),
  ...getRegisterIdColumn(),
  ...getIpColumn(),
  {
    header: 'CONFIGURATION.TERMS.EXPOSURE_YN',
    name: 'showInHistory',
    align: 'center',
    minWidth: 80,
    renderer: (props: CustomCellRendererProps) => {
      props.className = `${props.rowKey}-useYn`;
      props.selectOptions = [
        { text: i18nForTerms('EXPOSURE_Y'), value: true },
        { text: i18nForTerms('EXPOSURE_N'), value: false },
      ];

      const renderer = selectRenderer(props);
      const $el = renderer.getElement();

      const history = (props.grid.getRow(props.rowKey) as any) as History;
      const isEffective = history.termsNo === termsNo;

      if (isEffective) {
        $el.setAttribute('disabled', 'true');
      }

      $el.value = isEffective ? 'true' : props.value.toString();
      props.callback = event => onGridChangeEvent(props.columnInfo.name, Number(props.rowKey), event);

      return renderer;
    },
  },
  ...getTitleColumns(getTermsHistoryTitle(termsNo)),
  ...getDetailsColumns(),
];

// 개인정보 수집/동의 항목, 이용/탈퇴안내 컬럼
const getPiCollectionAndWithdrawalColumns = () => [
  ...getRegisteredDateTimeColumn(),
  ...getRegisterIdColumn(),
  ...getIpColumn(),
  ...getTitleColumns(getTermsHistoryTitle(null)),
  ...getDetailsColumns(),
];
// MEMBER > [봄 - 회원] 필수인 추가항목 3 (필수)
export const getCustomTermsTitle = (value: TermsCustomHistories['title']) => {
  const memberConvert = value.replace('MEMBER', i18nForTerms('MEMBER'));
  return memberConvert.toString().replace('ORDER', i18nForTerms('ORDER'));
};

export const getTermsHistoriesGridColumns = (
  termsTitle: termsHistoryMainTitleType,
  termsNo?: number,
  onGridChangeEvent?: GridEvent['onGridChangeEvent'],
) => {
  if (termsTitle === TERMS_HISTORY_MAIN_TITLE.TERMS_CUSTOM) {
    return [
      ...getRegisteredDateTimeColumn(),
      {
        align: 'center',
        name: 'modifier',
        header: 'CONFIGURATION.TERMS.ADMIN',
      },
      ...getIpColumn(),
      {
        name: 'title',
        align: 'center',
        ellipsis: true,
        header: 'CONFIGURATION.TERMS.TERM_TITLE',
        formatter: ({ value }) => getCustomTermsTitle(value?.toString() ?? ''),
      },
      {
        header: 'CONFIGURATION.TERMS.CHANGED_THING',
        name: 'workingType',
        align: 'center',
        formatter: ({ value }) => {
          if (value === 'CREATE') {
            return i18n.t('ADD');
          }

          if (value === 'DELETE') {
            return i18n.t('DELETE');
          }

          return i18n.t('MODIFY');
        },
      },
      ...getDetailsColumns(),
    ];
  }
  if (
    termsTitle === TERMS_HISTORY_MAIN_TITLE.PERSONAL_INFORMATION ||
    termsTitle === TERMS_HISTORY_MAIN_TITLE.WITHDRAWAL
  ) {
    return getPiCollectionAndWithdrawalColumns();
  }
  return termsOfServiceAndPiProcessColumns(onGridChangeEvent, termsNo);
};

export const getTermsHistoryDefaultQuery = (
  mallNo: number,
  termsType: termsHistoryMainTitleType,
): GetTermsHistoriesRequest['params'] => {
  return {
    mallNo,
    termsType: getTermsTypeList(termsType),
    page: 1,
    pageSize: 10,
    isExcludeEnforceOrder: true,
  };
};

export const termsFairLogo = {
  [FAIR_LOGO.KR_VERTICAL]:
    'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-terms-fair-trade-logos/kr_vertical.gif',
  [FAIR_LOGO.KR_SYMBOL]:
    'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-terms-fair-trade-logos/kr_symbol.png',
  [FAIR_LOGO.KR_HORIZONTAL]:
    'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-terms-fair-trade-logos/kr_horizontal.png',
  [FAIR_LOGO.KR_HORIZONTAL_SMALL]:
    'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-terms-fair-trade-logos/kr_horizontal_small.gif',
  [FAIR_LOGO.KR_GRAY]:
    'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-terms-fair-trade-logos/kr_gray.png',
  [FAIR_LOGO.EN_VERTICAL]:
    'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-terms-fair-trade-logos/en_vertical.gif',
  [FAIR_LOGO.EN_HORIZONTAL]:
    'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-terms-fair-trade-logos/en_horizontal.gif',
};
