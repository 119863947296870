



























































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { SearchKeywordData, SearchKeywordInputOption } from '@/types';

@Component({
  directives: {
    ClickOutside,
  },
})
export default class SearchKeywordInput extends Vue {
  @PropSync('keyword') private keywordSync!: string;
  @Prop({ required: true }) private options!: SearchKeywordInputOption;
  @Prop({ required: true }) private data!: SearchKeywordData[];
  @Prop({ required: false, default: false }) private readonly disabled!: boolean;

  private NO_RESULT_CODE = -1;
  private areFilteredByKeyword: SearchKeywordData[] = [];
  private inputOption: SearchKeywordInputOption = {
    className: '',
    placeholder: this.$t('PLACEHOLDER_SEARCH'),
    disabled: false,
    isDirect: false,
    showSearchBtn: false,
    useResetBtn: false,
    useCount: false,
    useHighlight: false,
    maxLength: 100,
  };
  private noSearchKeyword: SearchKeywordData = {
    selectKey: this.NO_RESULT_CODE,
    selectDisplayName: this.$t('NO_SEARCH_RESULTS'),
  };

  created() {
    Object.assign(this.inputOption, this.options);
  }

  // make filter data
  @Watch('data')
  private filterByKeyword() {
    if (!this.keywordSync) return;
    this.areFilteredByKeyword = this.data?.filter(item => {
      return (item.selectDisplayName as string).includes(this.keywordSync);
    });
  }

  // reset filter data
  private resetFilter() {
    this.areFilteredByKeyword = [];
  }

  // change main input event
  private onInput(event: InputEvent): void {
    this.keywordSync = (event.target as HTMLInputElement).value;
    this.$nextTick(() => this.filterKeyword('change'));
  }

  private onKeyup(event: InputEvent) {
    this.keywordSync = (event.target as HTMLInputElement).value;
    this.$nextTick(() => this.filterKeyword('debounce'));
  }

  // focus main input event
  private onFocus(): void {
    this.filterKeyword('focus');
  }

  private filterKeyword(emitEvent: string) {
    if (this.keywordSync) {
      this.filterByKeyword();

      if (!this.areFilteredByKeyword?.length) {
        this.areFilteredByKeyword = [{ ...this.noSearchKeyword }];
      }
    } else {
      this.resetFilter();
    }

    this.$emit(emitEvent);
  }

  // click filter item
  private selectItem(item: SearchKeywordData) {
    if (item.selectKey === this.NO_RESULT_CODE) {
      return;
    }

    this.keywordSync = item.selectDisplayName as string;
    this.resetFilter();

    this.$emit('change');
  }

  // click direct button
  private insertDirect(): void {
    if (!this.keywordSync) {
      return;
    }

    this.$emit('create');
    this.resetFilter();
  }

  // click initialize button
  private initialize(): void {
    this.keywordSync = '';
    this.resetFilter();
  }

  // return html filter item
  private getNameHtml(name: string): string {
    if (!this.inputOption.useHighlight) {
      return name;
    }

    const regExp = new RegExp(this.keywordSync, 'g');
    return name.replace(regExp, `<span>${this.keywordSync}</span>`);
  }
}
