




























































import { Vue, Component, Watch } from 'vue-property-decorator';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { getStrDate } from '@/utils/dateFormat';
import {
  accumulationHistoryInitQuery,
  inquiryMethodRadioOption,
  dateRangeOption,
} from '@/const/contents/accumulationPaymentDeduct';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { validateDateRange } from '@/views/contents/member/accumulation/AccumulationPaymentDeduct';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { mall } from '@/utils/mall';
import AccumulationTabMenu from '@/views/top/member/accumulation/AccumulationTabMenu.vue';
@Component({
  components: { AccumulationTabMenu, ToolTip, RadioGroup, DateRangePicker, SearchButton, MallSelect },
})
export default class AccumulationPaymentDeductHistory extends Vue {
  private readonly inquiryMethodRadioOption = inquiryMethodRadioOption;
  private readonly dateRangeOption = dateRangeOption;
  private accumulationHistoryQuery = {
    ...accumulationHistoryInitQuery(mall.mallsSortedByCreationDate[0].mallNo),
    ...this.$route.query,
  };
  private startDateTime = getStrDate(this.accumulationHistoryQuery.startDateTime);
  private endDateTime = getStrDate(this.accumulationHistoryQuery.endDateTime);

  private changeSelectMall(value: string) {
    this.accumulationHistoryQuery.mallNo = Number(value);
  }

  private changeMethod() {
    this.accumulationHistoryQuery.page = 1;
  }

  @Watch('startDateTime')
  private changeStartDateTime(_: string, prev: string) {
    if (!validateDateRange(this.startDateTime, this.endDateTime)) {
      this.$nextTick(() => (this.startDateTime = prev));
    }
    this.accumulationHistoryQuery.startDateTime = `${this.startDateTime} ${DEFAULT_TIME_RANGE.START}`;
  }

  @Watch('endDateTime')
  private changeEndDateTime(curr: string, prev: string) {
    if (!curr || !validateDateRange(this.startDateTime, this.endDateTime)) {
      this.$nextTick(() => (this.endDateTime = prev));
    }
    this.accumulationHistoryQuery.endDateTime = `${this.endDateTime} ${DEFAULT_TIME_RANGE.END}`;
  }

  private resetQuery() {
    this.accumulationHistoryQuery = {
      ...accumulationHistoryInitQuery(mall.mallsSortedByCreationDate[0].mallNo),
    };
    this.startDateTime = getStrDate(this.accumulationHistoryQuery.startDateTime);
    this.endDateTime = getStrDate(this.accumulationHistoryQuery.endDateTime);
  }

  created() {
    this.accumulationHistoryQuery = {
      ...accumulationHistoryInitQuery(mall.mallsSortedByCreationDate[0].mallNo),
      ...this.$route.query,
    };
    this.startDateTime = getStrDate(this.accumulationHistoryQuery.startDateTime);
    this.endDateTime = getStrDate(this.accumulationHistoryQuery.endDateTime);
  }
}
