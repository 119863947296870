export default Object.freeze({
  // TODO PRODUCT/COMMON 에 사용할수 있는 것을 사용
  POP_TITLE: '상품등록정보 심사',
  POP_BASIC_IMFORMATION: '기본정보',
  POP_FIELD: '항목',
  POP_BEFORE_DATA: '변경 전 등록정보',
  POP_DATA: '등록정보',
  POP_AFTER_DATA: '변경 후 등록정보',
  POP_NEED_UPDATE_FIELD: '수정필요항목',
  POP_MALL: '쇼핑몰',
  POP_GROUP_TYPE: '상품군',
  POP_GROUP_TYPE_DELIVERY: '배송상품군',
  POP_GROUP_TYPE_SERVICE: '서비스상품군',
  POP_CLASS_TYPE: '상품유형',
  POP_CLASS_TYPE_DEFAULT: '일반상품',
  POP_CLASS_TYPE_EVENT: '이벤트상품',
  POP_CLASS_TYPE_OFFLINE: '오프라인상품',
  POP_PARTNER: '파트너사',
  POP_SALE_METHOD_TYPE: '판매방식',
  POP_SALE_METHOD_TYPE_PURCHASE: '사입',
  POP_SALE_METHOD_TYPE_CONSIGNMENT: '위탁',
  POP_ADMIN: '담당자',
  POP_CATEGORY: '표준카테고리',
  POP_DISPLAY_CATEGORY: '전시카테고리',
  POP_PLATFORM_DISPLAYINFO: '플랫폼별 노출설정',
  POP_PLATFORM_DISPLAYINFO_MOBILEWEB: '모바일 웹',
  POP_PLATFORM_DISPLAYINFO_MOBILE: '모바일 앱',
  POP_MEMBER_GRADE_DISPLAYINFO: '회원등급 노출 설정',
  POP_MEMBER_GROUP_DISPLAYINFO: '회원그룹 노출 설정',
  POP_PRODUCT_NAME: '상품명',
  POP_PRODUCT_NAME_EN: '영문상품명',
  POP_PROMOTION_TEXT: '홍보문구',
  POP_BRAND: '브랜드',
  POP_MINOR_PURCHASE_YN: '미성년자구매',
  POP_PAYMENT_MEANS: '결제수단제어',
  POP_PAYMENT_MEANS_PAYCO: '페이코',
  POP_PAYMENT_MEANS_CREDIT: '신용카드',
  POP_PAYMENT_MEANS_REALTIME_TRANSFER: '실시간계좌이체',
  POP_PAYMENT_MEANS_DEPOSIT: '가상계좌',
  POP_PAYMENT_MEANS_ESCROW_REALTIME_TRANSFER: '실시간계좌이체-에스크로',
  POP_PAYMENT_MEANS_ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  POP_CART: '장바구니',
  POP_KEYWORDS: '검색어',
  POP_SALE_INFO: '판매정보',
  POP_RESERVATION_INFO: '예약판매',
  POP_SALE_PERIOD_INFO: '판매기간',
  POP_SALE_PERIOD_INFO_REGULAR: '상시 판매',
  POP_SALE_PERIOD_INFO_ELSE: '기간지정 판매',
  POP_COMMISSION_INFO: '판매수수료',
  POP_COMMISSION_INFO_PRODUCT: '상품수수료',
  POP_COMMISSION_INFO_CATEGORY: '카테고리수수료',
  POP_COMMISSION_INFO_PARTNER: '파트너수수료',
  POP_COMMISSION_INFO_PURCHASE_PRICE: '공급가입력',
  POP_SALE_PRICE: '판매가',
  POP_IMMEDIATE_DISCOUNT_INFO: '즉시할인',
  POP_PURCHASE_PRICE: '매입가',
  POP_SUPPLY_PRICE: '공급가',
  POP_PARTNER_CHARGE_AMT: '파트너사분담금',
  POP_ACCUMULATION_RATE: '적립금적립',
  POP_ACCUMULATION_USEYN: '적립금사용',
  POP_PROMOTION_INFO: '프로모션',
  POP_PRODUCT_STOCK_CNT: '재고수량(판매가능재고)',
  POP_MIN_BUY_CNT: '최소구매수량',
  POP_MAX_BUY_COUNT_INFO: '최대구매수량',
  POP_MAX_BUY_COUNT_INFO_PERSON: '1인당 최대 구매 수량',
  POP_MAX_BUY_COUNT_INFO_TIME: '1회당 최대 구매 수량',
  POP_MAX_BUY_COUNT_INFO_DAY: '최대구매수량 기간 제한 - 제한 수량',
  POP_MAX_BUY_COUNT_INFO_CNT: '일동안 최대',
  POP_MAX_BUY_COUNT_INFO_CNT2: '개 구매가능',
  POP_OPTIONS: '옵션',
  POP_MALL_ADDITION_PRODUCT: '추가상품',
  POP_COMPARING_PRICE_SITE_INFO: '가격비교사이트 등록',
  POP_COMPARING_PRICE_SITE_INFO_NAVER: '네이버쇼핑',
  POP_COMPARING_PRICE_SITE_INFO_DAUM: '다음쇼핑하우',
  POP_COMPARING_PRICE_SITE_INFO_ENURI: '에누리',
  POP_COMPARING_PRICE_SITE_INFO_DANAWA: '다나와',
  POP_COMPARING_PRICE_SITE_INFO_COOCHA: '쿠차',
  POP_COMPARING_PRICE_SITE_INFO_PAYCO: '페이코쇼핑',
  POP_PLACE_ORIGIN_INFO: '원산지',
  POP_DUTY_CONTENT: '상품정보고시',
  POP_DUTY_CONTENT2: '상품고시',
  POP_CERTIFICATION_INFO: '인증정보',
  POP_CERTIFICATION_INFO_TARGET: '인증대상',
  POP_CERTIFICATION_INFO_NOT_TARGET: '인증대상아님',
  POP_CERTIFICATION_INFO_DETAIL_PAGE: '상세페이지 별도표기',
  POP_MANUFACTURE_YMDT: '제조일자',
  POP_EXPIRATION_YMDT: '유효일자',
  POP_VALUE_ADDED_TAX_TYPE: '부가세',
  POP_VALUE_ADDED_TAX_TYPE_DUTY: '과세',
  POP_VALUE_ADDED_TAX_TYPE_DUTYFREE: '면세',
  POP_VALUE_ADDED_TAX_TYPE_SMALL: '영세',
  POP_PRODUCT_MANAGEMENT_CD: '상품관리코드',
  POP_REFUNDABLE_YN: '주문환불',
  POP_STICKERS: '스티커',
  POP_IMAGE_INFO: '이미지정보',
  POP_MAIN_IMAGE: '대표이미지',
  POP_LIST_IMAGE: '리스트 이미지',
  POP_CONTENT_HEADER: '상품 상세(상단)',
  POP_CONTENT_FOOTER: '상품 상세(하단)',
  POP_CONTENT: '상품 상세',
  POP_UPDATE: '수정필요',
  POP_UPDATE2: '수정요청',
  POP_DELIVERY_INFO: '배송정보',
  POP_SHIPPING_AREA_TYPE: '배송구분',
  POP_SHIPPING_AREA_TYPE_PARTNER: '파트너 배송',
  POP_SHIPPING_AREA_TYPE_MALL: '쇼핑몰 배송',
  POP_DELIVERY_COMBINATION_YN: '묶음배송 가능여부',
  POP_DELIVERY_INTERNATIONAL_YN: '해외배송 여부',
  POP_DELIVERY_TYPE: '배송방법/택배사',
  POP_DELIVERY_CONDITION_TYPE: '배송비유형',
  POP_RELEASE_WAREHOUSE: '출고지주소',
  POP_RETURN_WAREHOUSE: '반품교환지 주소',
  POP_RETURN_DELIVERY_AMT: '반품/교환 배송비',
  POP_DELIVERY_CUSTOMER_INFO: '판매자 특이사항/고객안내사항',
  POP_ETC_INFO: '별도 승인거부 의견',
  POP_ADD: '지급',
  POP_COUPON: '쿠폰',
  POP_ADDITIONALDISCOUNT: '추가할인',
  POP_REFUND_Y: '환불가능',
  POP_REFUND_N: '불가',
  POP_NO: '등록안함',
  POP_ALL: '등록(전체)',
  POP_USE_REGISTERED_OPTIONAL_IMAGES: '등록된 옵션이미지 사용',
  POP_CAN_DELIVERY_COMBINATION: '묶음배송 가능',
  POP_UNABLE_DELIVERY_COMBINATION: '묶음배송 불가능(상품별 배송비 적용)',
  POP_DELIVERY_INTERNATIONAL_Y: '해외배송상품 (해외 -> 대한민국으로 배송되는 상품)',
  POP_DELIVERY_INTERNATIONAL_N: '국내배송상품',
  SALE_APPROVE: '판매승인',
  APPROVE_REJECT: '승인거부',
  POP_DISPLAY: '노출',
  POP_NOT_SET: '설정 안함',
  POP_ALL_SET: '전체 설정',
  POP_DUE: '설정기간',
  POP_ALL_CAN: '전체가능',
  POP_DUE_SET: '기간설정',
  POP_FIXED_FEE: '배송비',
  POP_ORDER_AMT: '주문금액',
  POP_CONDITION: '원 이상 배송비 무료 미만 시',
  POP_APPROVE_SUCCESS: '승인이 완료되었습니다.',
  POP_REJECT_SUCCESS: '승인을 거절하였습니다.',
  POP_APPROVE_FAIL: '승인을 실패하였습니다.',
  POP_REJECT_FAIL: '승인거절을 실패하였습니다.',
  NON_MEMBER_PURCHASE_N: '비회원 구매 불가',
  NAVER_PAY_LIMIT_Y: '네이버페이 결제 불가',
  POP_FREE: '배송비 무료',
});
