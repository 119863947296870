import { GetMainMenus } from 'ncp-api-supporter';
import store from '@/store';
import { LnbMenu } from '@/const/lnb';

interface LnbState {
  lnbMenus: LnbMenu[];
  isLnbShowing: boolean;
  isExcelMenuShowing: boolean;
}

const isExcelMenuShowing = (serverMenus: GetMainMenus[]): boolean => {
  let arr = [...serverMenus];

  while (arr.length > 0) {
    const current = arr.shift();

    if (current.uri === '/pro/popup/excel-download' && current.readable && current.writable) {
      return true;
    }

    if (current.menus) {
      arr = arr.concat(current.menus);
    }
  }

  return false;
};

const getNewLnbMenu = (serverMenus: GetMainMenus[], parent?: LnbMenu): LnbMenu[] => {
  const result = [];

  for (let i = 0; i < serverMenus.length; i++) {
    if (!serverMenus[i] || !serverMenus[i].name) {
      continue;
    }

    const newMenu: LnbMenu = {
      parent: parent,
      displayable: serverMenus[i].displayable,
      name: serverMenus[i].name,
      menus: [],
      order: serverMenus[i].order,
      no: serverMenus[i].no,
      appNo: serverMenus[i]?.appNo,
    };

    newMenu.menus = getNewLnbMenu(serverMenus[i].menus, newMenu);

    if (newMenu.menus.length > 0) {
      newMenu.readable = false;
      newMenu.writable = false;

      newMenu.menus.map(child => {
        if (child.readable) {
          newMenu.readable = true;
        }

        if (child.writable) {
          newMenu.writable = true;
        }
      });

      newMenu.uri = newMenu.menus[0].uri.slice(0, newMenu.menus[0].uri.lastIndexOf('/'));
    } else {
      newMenu.readable = !!serverMenus[i].readable;
      newMenu.writable = !!serverMenus[i].writable;
      newMenu.uri = serverMenus[i].uri || '';
      if (newMenu.uri[newMenu.uri.length - 1] === '/') {
        newMenu.uri = newMenu.uri.slice(0, newMenu.uri.length - 1);
      }
    }

    newMenu.uri = newMenu.uri.replace('/pro', '');

    result.push(newMenu);
  }

  return result;
};

export default {
  namespaced: true,
  state: {
    lnbMenus: [],
    isLnbShowing: true,
    isExcelMenuShowing: false,
  },
  mutations: {
    INIT_LNB_MENU(state: LnbState): void {
      const serverMenus = store.getters['menu/getMenus'];

      state.isExcelMenuShowing = isExcelMenuShowing(serverMenus);

      state.lnbMenus = getNewLnbMenu(serverMenus);
    },

    SET_LNB_SHOWING(state: LnbState, isLnbShowing: boolean): void {
      state.isLnbShowing = isLnbShowing;
    },
  },
  getters: {
    getLnbMenu(state: LnbState): LnbMenu[] {
      return state.lnbMenus;
    },
    isLnbShowing(state: LnbState): boolean {
      return state.isLnbShowing;
    },
    isExcelMenuShowing(state: LnbState): boolean {
      return state.isExcelMenuShowing;
    },
  },
};
