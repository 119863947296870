export default Object.freeze({
  CONTRACT_TITLE: '파트너 검색/등록',
  COPY_TITLE: '파트너 복사등록',
  TAB_1: '사업자 정보 검색',
  TAB_2: '세부사항 확인/입력',
  CONTRACT_HINT: '등록하실 파트너사의 NCP 등록여부를 확인하기 위해 사업자 정보를 조회하세요.',
  COPY_HINT: '복사등록 하실 파트너사의 사업자 정보를 조회하세요.',
  SEARCH_TITLE: '파트너명 입력',
  COMPANY_NAME: '회사명',
  COMPANY_NAME_PLACEHOLDER: '회사명 입력',
  REGISTRATION_NO: '사업자등록번호',
  BTN_NEXT: '다음',

  HEADER_PARTNER_NO: '파트너번호',
  HEADER_PARTNER_NAME: '파트너명',
  HEADER_COMPANY_NAME: '회사명',
  HEADER_REGISTRATION_NO: '사업자번호',
  HEADER_DECLARATION_NO: '통신판매신고번호',
  HEADER_PHONE_NO: '대표전화번호',
  HEADER_EMAIL: '대표 e메일',

  ERR_COMPANY_NAME_NULL: '검색하실 파트너사의 회사명을 입력하세요.',
  ERR_REGISTRATION_NO_NULL: '검색하실 파트너사의 사업자 등록번호를 입력하세요.',
  ERR_NOT_SELECT: '파트너를 선택해주세요.',

  TABLE_NO_DATA: '회사명과 사업자 등록번호를 입력하여 파트너사를 검색하세요.',
});
