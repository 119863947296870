
























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { throwPopup, throwWindowPopup } from '@/helpers/popup';
import { getCampaigns } from '@/views/contents/marketing/googleAdConfig';
import { getAccessUsers } from '@/views/popups/marketing/userManage';

@Component
export default class AdsAccountInfo extends Vue {
  private customerId = '';
  private paymentAccountId = '';
  private description = '';
  private mallNo = 0;

  @Watch('$route.query.mallNo')
  private async changeMallNoQuery(): Promise<void> {
    this.mallNo = Number(this.$route.query.mallNo);
    const result = await getCampaigns(this.mallNo);

    if (result?.getCampaigns) this.fetchAdsAccount();
  }

  created() {
    this.mallNo = Number(this.$route.query.mallNo);
    this.fetchAdsAccount();
  }

  private async fetchAdsAccount() {
    const request = {
      params: {
        mallNo: this.mallNo,
      },
    };

    const {
      data: {
        customerId,
        billingSetupInfo: { paymentAccountId, status },
      },
    } = await this.$api.getCampaignsAccountsByAds(request);

    this.customerId = customerId;
    this.paymentAccountId = paymentAccountId;
    switch (status) {
      case 'UNSPECIFIED':
        this.description = window.$t('MARKETING.GOOGLE_AD.UNSPECIFIED');
        break;
      case 'UNKNOWN':
        this.description = window.$t('MARKETING.GOOGLE_AD.UNKNOWN');
        break;
      case 'PENDING':
        this.description = window.$t('MARKETING.GOOGLE_AD.PENDING');
        break;
      case 'APPROVED_HELD':
        this.description = window.$t('MARKETING.GOOGLE_AD.APPROVED_HELD');
        break;
      case 'APPROVED':
        this.description = window.$t('MARKETING.GOOGLE_AD.APPROVED');
        break;
      case 'CANCELLED':
        this.description = window.$t('MARKETING.GOOGLE_AD.CANCELLED');
        break;
      default:
        this.description = '';
        break;
    }
  }

  private async paymentMethodRegist() {
    const merchantUsers = await getAccessUsers(this.mallNo, 'MERCHANT');
    const adsUsers = await getAccessUsers(this.mallNo, 'ADS');

    if (merchantUsers.length === 0) {
      alert(this.$t('MARKETING.GOOGLE_AD.PLEASE_REGIST_MERCHANT_USERS'));
      return;
    } else if (adsUsers.length === 0) {
      alert(this.$t('MARKETING.GOOGLE_AD.PLEASE_ADS_USERS'));
      return;
    } else {
      throwPopup({
        name: 'PaymentMethodRegist',
      });
    }
  }

  private openUserManagePopup() {
    throwWindowPopup('AdsUserManage', { mallNo: this.mallNo }, 'md');
  }
}
