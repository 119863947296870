


















import { Vue, Component } from 'vue-property-decorator';
import { i18nExternal } from '@/const/contents/configuration/externalService';

@Component
export default class ProductOption extends Vue {
  private readonly i18nExternal = i18nExternal;
}
