








import { Vue, Component } from 'vue-property-decorator';
import ExcelDownload from '@/components/product/management/standardFee/excel/ExcelDownload';
import { MallSummary } from '@/components/product/categorization/excel/ExcelController.vue';
import { getCurrentMallNo, getMallName } from '@/utils/mall';

@Component
export default class ExcelController extends Vue {
  get currentMall(): MallSummary {
    const mallNo = getCurrentMallNo(this);
    const mallName = getMallName(mallNo);

    return {
      mallNo,
      mallName,
    };
  }

  private downloadStandardCategoriesExcel() {
    const excelDownload = new ExcelDownload(this.currentMall);
    excelDownload.run();
  }
}
