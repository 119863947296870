






























import { Vue, Component, PropSync, Ref } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { getOnlyNumbers } from '@/utils/numberFormat';

import { RadioBoxGroupOption } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';
import { BuyInfo } from '@/types';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({
  components: {
    TextInput,
    RadioGroup,
  },
})
export default class MinPurchaseQuantity extends Vue {
  @PropSync('buyInfo', { required: false })
  private buyInfoSync: BuyInfo;
  @PropSync('minBuyCount')
  private minBuyCountSync!: number;

  @Ref()
  private readonly inputRef: TextInput;
  public focus() {
    this.inputRef.focus();
  }

  private disabled = false;
  private validation = {
    text: '',
    onlyNumber: 'STOCK.MANAGEMENT.INPUT_NUMBER_ERROR',
    rangeNumber: 'PRODUCT.ADD.INPUTABLE_RANGE',
  };

  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'usePurchaseMin',
    data: [
      { id: 'notAllowedMinPurchaseMin', value: 'N', display: 'PRODUCT.COMMON.NOT_USE' },
      { id: 'allowedMinPurchaseMin', value: 'Y', display: 'PRODUCT.COMMON.USE' },
    ],
  };

  private changedUseMinPurchaseStatus(curr: YorN) {
    if (curr === 'Y') this.validation.text = '';
  }

  private handleOnlyNumber({ target }: { target: HTMLInputElement }) {
    const { value } = target;

    this.minBuyCountSync = getOnlyNumbers(value);
    this.validation.text = '';
  }

  private checkMinQuantity({ target }: { target: HTMLInputElement }) {
    const minQuantityCriteria = 2;
    if (Number(target.value) < minQuantityCriteria) {
      this.validation.text = this.validation.rangeNumber;
      return;
    }
    this.validation.text = '';
  }
}
