export default Object.freeze({
  // task
  DEIT_BTN: '수정',
  DELETE_BTN: '삭제',
  COMPLETE_BTN: '처리완료',
  MESSAGE_STATUS_TYPE: '상태',
  MESSAGE_TYPE: '유형',
  REGISTER_ADMIN_NAME: '등록자',
  COMPLETE_ADMIN_NAME: '처리자',
  TARGET_TYPE_LABEL: '처리 담당자',
  PRODUCT_NAME: '상품',
  PRODUCT_NAME_INIT: '전체',
  INFO_TITLE: '전달사항 내용',
  CONTENT_PLACEHOLDER: '서비스 운영자 혹은 파트너 담당자에게 전달할 사항을 입력해 주세요.',
  SAVE_BTN: '저장',
  CANCEL_BTN: '취소',
  ADD_DETAIL_BTN: '등록',
  ORDER_PRODUCT_OPTION_NU_ALL_TEXT: '주문상품 선택',
  FROM_TARGET_TYPE_SERVICE: '서비스 어드민',
  FROM_TARGET_TYPE_PARTNER: '파트너 센터',
  TARGET_TYPE_SERVICE: '서비스 운영자',
  TARGET_TYPE_PARTNER: '파트너 담당자',
  MESSAGE_STATUS_TYPE_PROCESSING: '처리중',
  MESSAGE_STATUS_TYPE_DONE: '처리완료',

  // cs
  LOG_TEXT: '처리로그',
  HANDLE_DONE_TEXT: '처리완료',
  HANDLE_ING_TEXT: '처리중',
  CS_PATH_TYPE: '경로',
  HANDLE_CONENT: '처리내용',
  CS_CONTENT_PLACEHOLDER: '처리내역을 입력해 주세요.',

  CS_PATH_TYPE_IN_BOUND: '인바운드',
  CS_PATH_TYPE_OUT_BOUND: '아웃바운드',
  CS_PATH_TYPE_ETC: '기타',

  MESSAGE_TYPE_PAY: '결제(입금)',
  MESSAGE_TYPE_PRODUCT: '주문상품',
  MESSAGE_TYPE_DELIVERY: '배송',
  MESSAGE_TYPE_CLAIM: '취소/교환/반품',
  MESSAGE_TYPE_REFUND: '환불',
  MESSAGE_TYPE_ETC: '기타',

  ALERT_1: '주문상품을 선택해 주세요.',
  ALERT_2: '내용을 입력해주세요.',
  ALERT_3: '업무메시지를 수정하였습니다.',
  ALERT_4: '업무메시지가 처리완료 되었습니다.',
  ALERT_5: '이미 처리완료된 업무메시지 입니다. 다시 확인해주세요.',
  ALERT_6: '등록되어 있는 업무메시지에 변경사항이 있습니다.\n새로고침 후 다시 시도해 주세요.',
  ALERT_7: '업무메시지 처리 내용을 입력해 주세요.\n',
  ALERT_8: '업무메시지 처리 내용을 입력해주세요.',
  ALERT_9: '업무메시지를 등록하였습니다.',
  ALERT_10: '내용을 입력해 주세요.',
  ALERT_11: 'CS를 수정하였습니다.',
  ALERT_12: 'CS를 처리 완료하였습니다.',

  CONFIRM_1: '삭제하시겠습니까?',
  CONFIRM_2: '처리 완료하시겠습니까?',
  CONFIRM_3: '등록된 댓글이 있습니다. 처리 완료하시겠습니까?',
  CONFIRM_4: '취소하시겠습니까?',
});
