export default Object.freeze({
  PAGE_TITLE: '1:1문의 관리',
  TITLE: '문의유형 설정',
  ADD_GROUP_BTN: '문의유형 추가',
  DEL_BTN: '삭제',
  DETAIL_TITLE: '문의유형 상세설정',
  LIST_EMPTY: '문의유형을 생성 해 주세요.',
  LIST_EMPTY_DETAIL: '좌측에서 문의유형을 추가 하시면, 상세설정이 가능합니다.',
  TH_1: '문의유형명',
  TH_2: '문의유형 설명',
  PLACEHOLDER_NAME: '문의유형명 입력',
  PLACEHOLDER_DESCRIPTION: '문의유형 설명 입력',
  DETAIL_TITLE_2: '담당자 설정',
  DETAIL_CONTACTPERSON: '담당자',
  DETAIL_DEL: '삭제',
  ADD_CONTACTPERSON: '+담당자 추가',
  NAME_PLACEHOLDER: '문의유형명 입력',
  WARN_LENGTH: '선택된 문의유형이 없습니다.',
  WARN_TYPECHANNEL: '네이버페이문의 유형은 삭제할 수 없습니다.',
  WARN_COUNT: '1:1문의 등록이 있어 문의유형을 삭제할 수 없습니다.',
  CONFIRM_DEL: '문의유형을 삭제하시겠습니까?',

  WARM_TYPENAME: '문의유형 이름이 지정되지 않았습니다.',
  WARM_ADMINS: '{0} 문의유형의 담당자가 설정되지 않았습니다.',
  CONFIRM_SAVE: '변경된 내용을 저장하시겠습니까?',
});
