




























































import { Component, Mixins, Prop, Watch, PropSync } from 'vue-property-decorator';

import {
  ClaimClassType,
  ClaimStatusType,
  ClaimType,
  DeliveryAmtModified,
  DiscountAmtModified,
  ResponsibleObjectType,
} from '@/types/claim';
import {
  isCancelClaim,
  isCancelExchange,
  isCancelRequest,
  isExchangeClaim,
  isOrderCancel,
  isReturnClaim,
  isReturnExchange,
} from '@/components/popup/claim/claimConditions';
import { claimClassTypes, claimTypes, responsibleObjectTypes } from '@/const/claim';
import { DeliveryCalculator } from '@/components/popup/claim/ClaimPopup/calculator';
import AmountMixin from '@/components/popup/claim/AmountMixin';
import TdDeliveryDifference from '@/components/popup/claim/ClaimPopup/DeliveryRecalculate/TdDeliveryDifference.vue';
import { Amounts, ClaimCoupon, ClaimData, Shipping } from 'ncp-api-supporter';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

// 할인/배송비재계산
@Component({
  components: { TdDeliveryDifference, ToolTip },
  computed: {
    isCancelRequest,
    isOrderCancel,
    isCancelClaim,
    isExchangeClaim,
    isReturnClaim,
    isCancelExchange,
    isReturnExchange,
    isSellerResponsible() {
      return this.responsibleObjectType === responsibleObjectTypes.SELLER;
    },
    hasCartCoupon() {
      return !!this.discountAmtModified.cartCoupon;
    },
    showCouponRestore() {
      return this.coupon?.cartCoupon?.restores ?? false;
    },
    showExchangeShippingInfo() {
      return this.isCancelExchange && !!this.exchangeShipping;
    },
    payAfterBadge() {
      return !this.deliveryAmtModified.prepaid;
    },
    combinedBadge() {
      return this.deliveryAmtModified.combined;
    },
    divideBadge() {
      return this.deliveryAmtModified.divided;
    },
    beforeDeliveryLineThrough() {
      if (this.isOrderCancel) {
        return 'none';
      }

      const { alreadyPaidTotalDeliveryAmt, totalDeliveryAmt } = this.amounts.before.delivery.deliveryGroupAmounts;

      return alreadyPaidTotalDeliveryAmt !== totalDeliveryAmt && this.deliveryAmtModified.prepaid
        ? 'line-through'
        : 'none';
    },
    afterDeliveryLineThrough() {
      // 취소클레임은 변경후 삭선 대신 차액을 전부 삭선함
      if (this.isCancelClaim || this.isCancelExchange) {
        return 'none';
      }

      if (this.isReturnClaim || this.isReturnExchange) {
        if (this.isSellerResponsible && this.sellerPaysClaimedDeliverySync) {
          return this.amounts.returnDelivery?.processedDeliveryAmt > 0 ? 'line-through' : 'none';
        }
        return 'none';
      }

      return 'none';
    },
    differenceLineThrough() {
      if (
        this.isSellerResponsible &&
        this.sellerPaysClaimedDeliverySync &&
        this.amounts.deliveryAdjustAmt !== this.amounts.refundDeliveryAmt
      ) {
        return 'line-through';
      }

      return 'none';
    },
    showResponsibleCheckbox() {
      return this.isSellerResponsible;
    },
    responsibleCheckboxDisabled() {
      return !this.isModifyMode;
    },
  },
})
export default class DeliveryRecalculate extends Mixins(AmountMixin) {
  // @Prop({ required: true })
  // private readonly isModifyMode!: boolean;
  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  @Prop({ required: true })
  private readonly claimStatusType!: ClaimStatusType;

  @Prop({ required: false, default: null })
  private readonly claimClassType!: null | ClaimClassType;

  @Prop({ required: true })
  private readonly coupon!: ClaimCoupon;

  @Prop({ required: true })
  private readonly amounts!: Amounts;

  @Prop({ required: true })
  private readonly shipping!: Shipping;

  @Prop({ required: false, default: null })
  private readonly exchangeShipping!: null | ClaimData['exchangeShipping'];

  @Prop({ required: true })
  private readonly responsibleObjectType!: ResponsibleObjectType;

  @PropSync('sellerPaysClaimedDelivery')
  private sellerPaysClaimedDeliverySync!: boolean;

  private deliveryCalculator: DeliveryCalculator = null;

  // 할인금액변경 row(1)
  private discountAmtModified: DiscountAmtModified = {
    cartCouponDiscountAmt: 0, // 주문쿠폰 재계산
    cartCoupon: null, // 적용된 쿠폰 정보, null이면 적용된 쿠폰 없음
  };

  // 배송비변경 row(2)
  private deliveryAmtModified: DeliveryAmtModified = {
    claimType: '',
    shippingNo: 0, // 배송번호
    beforeDeliveryAmt: 0, // 변경전배송비
    afterDeliveryAmt: 0, // 변경후배송비
    deliveryDifference: 0, // 배송비차액
    divided: false, //나눔
    combined: false, //묶음
    prepaid: false, //착불
    // 교환 배송비 정보(취소교환시에만 발생할 수 있는 케이스)
    exchangeShipping: {
      shippingNo: 0,
      deliveryDifference: 0,
      beforeDeliveryAmt: 0,
      afterDeliveryAmt: 0,
      divided: false,
      combined: false,
      prepaid: false,
    },
  };

  // 재계산 합계 row
  private recalculatedAmt = {
    totalAmt: 0,
  };

  @Watch('amounts')
  @Watch('responsibleObjectType')
  @Watch('sellerPaysClaimedDeliverySync')
  recalculateAmounts() {
    this.deliveryCalculator.amounts = this.amounts;
    this.setDiscountAmtModified();
    this.setDeliveryAmtModified();
    this.setExchangeShipping();
    this.setRecalculatedAmt();
  }

  created() {
    this.deliveryCalculator = new DeliveryCalculator({
      claimType: this.claimType,
      claimClassType: this.claimClassType,
      amounts: this.amounts,
    });

    this.setDiscountAmtModified();
    this.setDeliveryAmtModified();
    this.setExchangeShipping();
    this.setRecalculatedAmt();
  }

  private setDiscountAmtModified() {
    this.discountAmtModified.cartCouponDiscountAmt =
      this.claimType === claimTypes.EXCHANGE
        ? -1 * this.amounts.refund.coupon.cartCouponDiscountAmt
        : this.amounts.refund.coupon.cartCouponDiscountAmt;

    this.discountAmtModified.cartCoupon = this.coupon?.cartCoupon || null;
  }

  private setDeliveryAmtModified() {
    const claimType = this.claimType;
    const beforeDeliveryAmt = this.deliveryCalculator.beforeDeliveryAmt();
    const afterDeliveryAmt = this.deliveryCalculator.afterDeliveryAmt();
    const deliveryDifference = this.deliveryCalculator.deliveryDifference();
    const { shippingNo, divided, combined, prepaid } = this.shipping;

    this.deliveryAmtModified = {
      ...this.deliveryAmtModified,
      claimType,
      shippingNo,
      beforeDeliveryAmt,
      afterDeliveryAmt,
      deliveryDifference,
      divided,
      combined,
      prepaid,
    };
  }

  private setExchangeShipping() {
    if (!this.exchangeShipping) {
      this.deliveryAmtModified.exchangeShipping = null;
      return;
    }

    const { totalPrepaidDeliveryAmt, totalDeliveryAmt } = this.amounts.exchangeDelivery;
    const { shippingNo, combined, prepaid, divided } = this.exchangeShipping;
    this.deliveryAmtModified.exchangeShipping = {
      ...this.deliveryAmtModified.exchangeShipping,
      afterDeliveryAmt: totalDeliveryAmt,
      beforeDeliveryAmt: 0,
      deliveryDifference: totalPrepaidDeliveryAmt,
      shippingNo,
      combined,
      prepaid,
      divided,
    };
  }

  private setRecalculatedAmt() {
    let result =
      -1 * this.discountAmtModified.cartCouponDiscountAmt +
      (this.isDifferenceAmtLined ? 0 : this.deliveryAmtModified.deliveryDifference);
    if (this.claimType === claimTypes.EXCHANGE && this.claimClassType === claimClassTypes.CANCEL_EXCHANGE) {
      // 착불배송비는 재계산합계에서 제외된다.
      result += this.amounts.exchangeDelivery?.totalPrepaidDeliveryAmt ?? 0;
    }

    this.recalculatedAmt.totalAmt = result;
  }

  // FIXME: differenceLineThrough computed와 중복
  // differenceLineThrough: 스타일값 반환 , isDifferenceAmtLined: boolean 반환
  private get isDifferenceAmtLined(): boolean {
    return (
      this.responsibleObjectType === responsibleObjectTypes.SELLER &&
      this.sellerPaysClaimedDeliverySync &&
      this.amounts.deliveryAdjustAmt !== this.amounts.refundDeliveryAmt
    );
  }
}
