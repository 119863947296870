








import { Vue, Component } from 'vue-property-decorator';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';
import MallSelector from '@/components/MallSelector.vue';
import InquiriesList from '@/views/contents/board/basic/InquiriesList.vue';
import InquiriesType from '@/views/contents/board/basic/InquiriesType.vue';

@Component({ components: { MallSelector, InquiriesList, InquiriesType, TabMenu } })
export default class Inquiries extends Vue {
  private menuList: TabMenuI[] = [
    { display: this.$t('BOARD.INQUIRIES.TAB_1') as string, path: 'list' },
    { display: this.$t('BOARD.INQUIRIES.TAB_2') as string, path: 'type' },
  ];
  private get pageName() {
    return this.$route.name;
  }
}
