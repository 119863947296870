


















import { Vue, Component } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class DownloadManuals extends Vue {
  private readonly manuals: { label: TranslateResult; location: string }[] = [
    {
      label: this.$t('SETTING'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%EC%84%A4%EC%A0%95_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('PRODUCT'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%EC%83%81%ED%92%88_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('SHIPPING'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%EC%A3%BC%EB%AC%B8%EB%B0%B0%EC%86%A1_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('MEMBER'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%ED%9A%8C%EC%9B%90_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('BOARD'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%EA%B2%8C%EC%8B%9C%ED%8C%90_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('PROMOTION'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%ED%94%84%EB%A1%9C%EB%AA%A8%EC%85%98_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('DESIGN'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%EB%94%94%EC%9E%90%EC%9D%B8_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('STATISTICS'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/shopby-pro-manual/for-window/%ED%86%B5%EA%B3%84_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
    {
      label: this.$t('MARKETING'),
      location:
        'https://api-storage.cloud.toast.com/v1/AUTH_69db659103894b00aa9f8b28aa62fe8e/guide/shopby/%EB%A7%88%EC%BC%80%ED%8C%85_%EB%A7%A4%EB%89%B4%EC%96%BC.pdf',
    },
  ];
}
