export default Object.freeze({
  TITLE: '회원등급/그룹 선택',
  GRADE_TITLE: '대상 회원 등급',
  GROUP_TITLE: '대상 회원 그룹',
  SAVE_BTN: '저장',
  CANCEL_BTN: '닫기',
  ALERT_TEXT: '회원 등급/그룹 중 하나 이상 선택해주세요.',
  RECEIPIENT_TITLE: 'SMS/e-mail을 발송할 회원을 선택합니다.',
  SELECT: '전체',
  UNIT: '명',
});
