import { ActionContext } from 'vuex';
import { ThrowMessagePopup } from '@/helpers/popup';

export interface RootState {
  showingLoadingSpinner: boolean;
  hasUnsavedForm: boolean;
  detectUnsavedFormMessage: ThrowMessagePopup;
}

export const state: RootState = {
  showingLoadingSpinner: false,
  hasUnsavedForm: false,
  detectUnsavedFormMessage: {
    message: 'CONFIRM_UNSAVED_LEAVE',
    isConfirm: true,
    okButtonText: 'CONFIRM',
    cancelButtonText: 'CANCEL',
  },
};

export const getters = {
  isShowingLoadingSpinner(state: RootState): boolean {
    return state.showingLoadingSpinner;
  },
  hasUnsavedForm(state: RootState): boolean {
    return state.hasUnsavedForm;
  },
  getDetectUnsavedFormMessage(state: RootState): ThrowMessagePopup {
    return state.detectUnsavedFormMessage;
  },
};
export const actions = {
  setDetectUnsavedFormMessage: ({ commit }: ActionContext<RootState, RootState>, message: ThrowMessagePopup): void => {
    commit('SET_DETECT_UNSAVED_MESSAGE', message);
  },
  setInitDetectUnsavedFormMessage: ({ commit }: ActionContext<RootState, RootState>): void => {
    commit('INIT_DETECT_UNSAVED_MESSAGE');
  },
  setInitUnsavedForm: ({ commit }: ActionContext<RootState, RootState>): void => {
    commit('INIT_UNSAVED_FORM');
  },
  setUnsavedForm: ({ commit }: ActionContext<RootState, RootState>): void => {
    commit('SET_UNSAVED_FORM');
  },
};
export const mutations = {
  SET_SHOWING_LOADING_SPINNER(state: RootState, showing: boolean): void {
    state.showingLoadingSpinner = showing;
  },
  SET_UNSAVED_FORM(state: RootState): void {
    state.hasUnsavedForm = true;
  },
  INIT_UNSAVED_FORM(state: RootState): void {
    state.hasUnsavedForm = false;
  },
  SET_DETECT_UNSAVED_MESSAGE(state: RootState, message: ThrowMessagePopup): void {
    state.detectUnsavedFormMessage = {
      ...state.detectUnsavedFormMessage,
      ...message,
    };
  },
  INIT_DETECT_UNSAVED_MESSAGE(state: RootState): void {
    state.detectUnsavedFormMessage = {
      message: 'CONFIRM_UNSAVED_LEAVE',
      isConfirm: true,
      okButtonText: 'CONFIRM',
      cancelButtonText: 'CANCEL',
    };
  },
};
