import { RadioRendererWithClassName } from '@/views/contents/board/basic/Questions';

/**
 * PartnerRegisterSearch, PartnerRegisterCopySearch
 * '검색등록'과 '복사등록'에서 사용하는 '사업자 정보 검색부분'이 동일한 정책이라 한 벌로 만들어 재사용하고 있습니다.
 * 프리미엄 기반으로 볼 때 정책이 달라질 확률이 매우 적어 동일한 코드 두 벌 작성해서 드는 비용보다 이후 정책이 달라지는 시점에 분리하는 비용이 더 적게 들 것으로 판단되어 한 벌로 작성했습니다.
 */
export const getGridProps = () => ({
  header: {
    height: 32,
  },
  options: {
    rowHeaders: [
      {
        name: 'radioBtn',
        type: 'radio',
        header: '선택',
        renderer: RadioRendererWithClassName,
      },
    ],
    copyOptions: {
      useFormattedValue: true,
    },
    bodyHeight: 155,
    minRowHeight: 35,
    displayRowCount: 10,
    keyColumnName: 'partnerNo',
    columnOptions: {
      resizable: true,
    },
    pageOptions: null,
  },
  columns: [
    {
      header: '파트너번호',
      name: 'partnerNo',
      align: 'center',
      minWidth: 60,
    },
    {
      header: '파트너명',
      name: 'partnerName',
      align: 'center',
      minWidth: 200,
    },
    {
      header: '회사명',
      name: 'companyName',
      align: 'center',
      minWidth: 140,
    },
    {
      header: '사업자번호',
      name: 'businessRegistrationNo',
      align: 'center',
      minWidth: 140,
      formatter: ({ row }) => {
        const MIN_RANGE_OF_REGISTRATION_NO = 6;
        const registrationNo = row.business.registrationNo;

        return registrationNo?.length > MIN_RANGE_OF_REGISTRATION_NO
          ? `${registrationNo.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')}`
          : registrationNo;
      },
    },
    {
      header: '통신판매신고번호',
      name: 'onlineMarketingBusinessDeclarationNo',
      align: 'center',
      minWidth: 180,
      formatter: ({ row }) => row.business.onlineMarketingDeclarationNo ?? '-',
    },
    {
      header: '대표전화번호',
      name: 'representPhoneNo',
      align: 'center',
      minWidth: 120,
      formatter: ({ row }) => row.representative.phoneNo,
    },
    {
      header: '대표 e메일',
      name: 'representEmail',
      align: 'center',
      minWidth: 200,
      formatter: ({ row }) => row.representative.email,
    },
  ],
});
