

































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  getDefaultPGSetting,
  getPGSettingCheckBoxOption,
  getPGSettingRadioBoxOption,
  getPGSettingToolTipOption,
} from '@/const/contents/configuration/pgSetting';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import { DomainByMall, PgType, TaxType } from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TemplateEditForm from '@/views/contents/member/email/tree/TemplateEditForm.vue';
import { clearBottomNavigation, throwBottomNavigation } from '@/helpers/bottomNav';
import { getCurrentMallNo } from '@/utils/mall';
import { payTypes } from '@/const/order';
import { applyUrl } from '@/components/addition/GoTo';
import { Getter } from 'vuex-class';
import store from '@/store';

@Component({ components: { TemplateEditForm, NoticeBox, ToolTip, CheckboxGroup, TextInput, RadioGroup } })
export default class PgSetting extends Vue {
  @Getter('mall/getMallDomains') private readonly mallDomains!: DomainByMall[];

  private toolTipOption = getPGSettingToolTipOption(getCurrentMallNo(this));

  private pgSetting = getDefaultPGSetting();

  private checkBoxOption = getPGSettingCheckBoxOption();

  private checkBoxOptionPayType = { ...this.checkBoxOption.payType, data: [...this.checkBoxOption.payType.data] };

  private radioOption = getPGSettingRadioBoxOption();

  private limitPayWaitDays = '';

  private readonly isBasicPlanType = store?.getters['admin/getAdmin']?.plan === 'BASIC';

  private getPGApplyUrl(pg: string): string {
    const curruntMallNo = getCurrentMallNo(this);
    const currentMallDomain = this.mallDomains.filter(m => m.mallNo === curruntMallNo)[0];

    if (currentMallDomain && currentMallDomain.mallId) {
      return applyUrl[pg] + '&mall_id=' + currentMallDomain.mallId;
    }

    return '';
  }

  private getPGName(): string {
    if (this.isKCP()) {
      return this.$t('CONFIGURATION.PG.KCP') as string;
    }
    if (this.isInicis()) {
      return this.$t('CONFIGURATION.PG.INICIS') as string;
    }
    if (this.isToss()) {
      return this.$t('CONFIGURATION.PG.TOSS') as string;
    }

    return '';
  }

  private getInicisLogoSettingDescription(): string {
    return this.$t('CONFIGURATION.PG.INICIS_LOGO_SETTING_DESC', {
      tagStart: '<a href="https://www.inicis.com/pg-mark" target="_blank"><strong>',
      tagEnd: '</strong></a>',
    }) as string;
  }

  private isExistPG(): boolean {
    return this.isKCP() || this.isInicis() || this.isToss();
  }
  private isKCP(): boolean {
    return this.pgSetting.pgType === 'KCP';
  }
  private isInicis(): boolean {
    return this.pgSetting.pgType === 'INICIS';
  }
  private isToss(): boolean {
    return this.pgSetting.pgType === 'TOSS_PAYMENTS';
  }

  private get enablePayTypes(): string {
    if (!this.pgSetting.enablePayTypes || this.pgSetting.enablePayTypes.length === 0) {
      return '';
    }

    return this.pgSetting.enablePayTypes.join(',');
  }
  private set enablePayTypes(value: string) {
    this.pgSetting.enablePayTypes = this.pgSetting.enablePayTypes.filter(pt => pt !== 'PAYCO');

    if (value.includes('PAYCO')) {
      this.pgSetting.enablePayTypes.push('PAYCO');
    }
  }

  private getSuffixAutoText(text: string): string {
    if (!text) {
      return '';
    }

    return `${text} ${this.$t('CONFIGURATION.PG.AUTO_SETTING_COMPLETE_SUFFIX')}`;
  }

  private validateLimitPayWaitDays(text: string): string {
    const num = Number(text);

    if (num < 0) {
      this.limitPayWaitDays = '';
    } else if (num >= 31) {
      this.limitPayWaitDays = num.toString().slice(0, -1);
    } else {
      this.limitPayWaitDays = text;
    }

    return this.limitPayWaitDays;
  }

  @Watch('$route')
  private initPGSetting() {
    this.toolTipOption = getPGSettingToolTipOption(getCurrentMallNo(this));

    this.getOrderConfigsPaymentsPg().then(() => {
      this.throwBottomNavigation();
    });
  }

  created() {
    this.initPGSetting();
  }

  private getOrderConfigsPaymentsPg(): Promise<void> {
    return this.$api
      .getOrderConfigsPaymentsPg({ params: { mallNo: getCurrentMallNo(this) + '' } })
      .then(response => {
        const pgSetting = getDefaultPGSetting();

        this.limitPayWaitDays = '';

        if (response && response.status === 200) {
          Object.assign(pgSetting, response.data);

          this.pgSetting = pgSetting;

          this.limitPayWaitDays = this.pgSetting.limitPayWaitDays + '';
          this.checkBoxOptionPayType = { ...this.checkBoxOption.payType };

          const data = [...this.checkBoxOption.payType.data];

          if (this.isKCP()) {
            data.push({
              id: payTypes.PAYCO,
              value: payTypes.PAYCO,
              display: window.$t('ORDER.PAY_TYPE.PAYCO'),
              disabled: false,
            });
          }

          this.checkBoxOptionPayType.data = data;
        }
      })
      .catch(() => {
        this.pgSetting = getDefaultPGSetting();
      });
  }

  private postOrderConfigsPaymentKeysShopByProRenewPGConfigs(): Promise<boolean> {
    return this.$api
      .postOrderConfigsPaymentKeysShopByProRenewPGConfigs({
        data: {
          mallNo: getCurrentMallNo(this),
          pgType: this.pgSetting.pgType as PgType,
        },
      })
      .then(response => {
        if (response && response.status === 200 && response.data && response.data.success) {
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private putOrderConfigsPaymentsPg(): Promise<boolean> {
    const request = {
      data: {
        limitPayWaitDays: this.pgSetting.limitPayWaitDays,
        pgType: this.pgSetting.pgType,
        kcpPayco: this.pgSetting.enablePayTypes.filter(pt => pt === 'PAYCO').length > 0,
        logo: this.pgSetting.inicisData ? this.pgSetting.inicisData.logo : null,
        exposeLogo: this.pgSetting.exposeLogo,
        mallNo: getCurrentMallNo(this),
        taxType: this.pgSetting.taxType as TaxType,
      },
    };

    if (!request.data.logo) {
      delete request.data.logo;
    }

    return this.$api
      .putOrderConfigsPaymentsPg(request)
      .then(response => {
        if (response && response.status === 204) {
          return true;
        }
        return false;
      })
      .catch(() => false);
  }

  private renewPGSetting(): void {
    this.postOrderConfigsPaymentKeysShopByProRenewPGConfigs().then(success => {
      if (success) {
        setTimeout(() => {
          this.$router.go(0);
        }, 3000);

        alert(this.$t('CONFIGURATION.PG.ALERT_SUCCESS_UPDATE_INFO'));
      }
    });
  }

  private save(): void {
    if (!this.limitPayWaitDays) {
      alert(`${this.$t('CONFIGURATION.PG.LIMIT_PAY_WAIT_DAYS')}${this.$t('CHECK_INPUT_FILED')}`);
      (this.$refs.limitPayWaitDays as TextInput).focus();
      return;
    }

    this.pgSetting.limitPayWaitDays = Number(this.limitPayWaitDays);

    this.putOrderConfigsPaymentsPg().then(success => {
      if (success) {
        alert(this.$t('ALERT_SAVED'));
      }
    });
  }

  private throwBottomNavigation(): void {
    if (!this.isExistPG()) {
      clearBottomNavigation();
      return;
    }

    const buttons = [];
    buttons.push({
      type: 'right',
      key: 'save',
      color: 'red',
      text: this.$t('SAVE'),
    });

    throwBottomNavigation({
      buttons,
      onClick: (key: string) => {
        switch (key) {
          case 'save':
            this.save();
            break;
        }
      },
    });
  }
}
