






































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TotalSales } from 'ncp-api-supporter';
import { getStrDate } from '@/utils/dateFormat';
import { formatCurrency } from '@/utils/numberFormat';
import { createExcel } from '@/components/common/grid/ExcelCreateUtil';
import { partnerSalesQuery } from '@/const/contents/partner';

const COL_WIDTHS = ['150px', '100px', '100px', '100px', '120px', '110px', '150px', '120px', '150px', '110px'] as const;

type TotalSalesString = { [key in keyof TotalSales]: string };

@Component
export default class TotalSalesTable extends Vue {
  @Prop({ required: true })
  private totalSalesHistory: TotalSales;

  private colWidths = COL_WIDTHS;

  private get totalSalesLocaleString(): TotalSalesString {
    const localeStringEntries = Object.entries(this.totalSalesHistory).map(([key, value]) => [
      key,
      formatCurrency(value),
    ]);

    return Object.fromEntries(localeStringEntries);
  }

  private getSearchYmd(format = 'YYYY-MM-DD'): { startYmd: string; endYmd: string } {
    const startYmd = getStrDate(
      this.$route.query.startDateTime?.toString() ?? partnerSalesQuery().startDateTime,
      format,
    );
    const endYmd = getStrDate(this.$route.query.endDateTime?.toString() ?? partnerSalesQuery().endDateTime, format);
    return { startYmd, endYmd };
  }

  private handleDownloadClick() {
    const hasData = Boolean(this.totalSalesHistory);
    const { startYmd, endYmd } = this.getSearchYmd('YYYYMMDD');
    const { startYmd: startYmdWithDash, endYmd: endYmdWithDash } = this.getSearchYmd('YYYY-MM-DD');
    const extraInfo = `${startYmd}_${endYmd}`;
    createExcel(
      hasData,
      'PRO_SETTLEMENT_TOTAL_SALE',
      {
        ...this.$route.query,
        mallNo: this.$route.query?.mallNo ? this.$route.query.mallNo : partnerSalesQuery().mallNo,
        startDateTime: startYmdWithDash,
        endDateTime: endYmdWithDash,
        servicePlan: 'PRO',
      },
      extraInfo,
    );
  }
}
