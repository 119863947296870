
















































































import { Component, Mixins } from 'vue-property-decorator';
import { getDefaultAcceptForm } from '@/components/popup/claim/claimOptions';
import ClaimPopupMixin from '@/components/popup/claim/ClaimPopupMixin';
import ClaimProduct from '@/components/popup/claim/ClaimAcceptPopup/ClaimProduct.vue';
import ClaimReleaseProduct from '@/components/popup/claim/ClaimAcceptPopup/ClaimReleaseProduct.vue';
import ClaimInformation from '@/components/popup/claim/ClaimAcceptPopup/ClaimInformation.vue';
import OrderInformation from '@/components/popup/claim/ClaimAcceptPopup/OrderInformation.vue';
import ReceiverInfo from '@/components/popup/claim/ClaimAcceptPopup/ReceiverInfo.vue';
import ExchangeAmount from '@/components/popup/claim/ClaimAcceptPopup/ExchangeAmount.vue';
import NoLocalStorageWindowPopup from '@/views/popups/NoLocalStorageWindowPopup.vue';
import { isCancelExchange } from '@/components/popup/claim/claimConditions';

// 교환리스트 기획서 p.65
// 교환처리내역과 팝업디자인이 다르다.
@Component({
  components: {
    ClaimProduct,
    ClaimReleaseProduct,
    ClaimInformation,
    OrderInformation,
    ReceiverInfo,
    ExchangeAmount,
  },
  computed: {
    isCancelExchange,
  },
})
export default class ExchangeAcceptPopup extends Mixins(ClaimPopupMixin, NoLocalStorageWindowPopup) {
  private formData = {
    deliveryCompanyType: '',
    invoiceNo: '',
    evadesReturnProcess: false, // TODO:  무적반품
  };

  async created() {
    await this.fetchClaimNo();
    this.setDefaultForm();
  }

  private setDefaultForm() {
    this.formData = getDefaultAcceptForm(this.claimNoResponse);
  }

  private acceptExchange(): void {
    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_EXCHANGE_ACCEPT') as string)) {
      return;
    }

    const { no: claimNo, claimClassType } = this.claimNoResponse;
    const { deliveryCompanyType, invoiceNo, evadesReturnProcess } = this.formData;

    const request = {
      pathParams: {
        no: claimNo.toString(),
      },
      data: {
        deliveryCompanyType: deliveryCompanyType.length ? deliveryCompanyType : null,
        invoiceNo: invoiceNo ? invoiceNo : null,
        evadesReturnProcess: evadesReturnProcess,
      },
    };
    const apiMap = {
      CANCEL_EXCHANGE: () => this.$api.putCancelExchangesNoApprove({ pathParams: request.pathParams }),
      RETURN_EXCHANGE: () => this.$api.putReturnExchangesNoApprove(request),
    };

    apiMap[claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }
}
