import { i18n } from '@/main';

export const KEY_MAP_JSON = {
  productNo: 'PRODUCT.PRODUCTLIST.PLM_PRODUCT_NUMBER',
  salePrice: 'PRODUCT.PRODUCTLIST.CHANGE_PRICE',
  immediateDiscountValue: 'PRODUCT.PRODUCTLIST.PLSLOTLEFT_IMMEDIATEDISCOUNT',
  immediateDiscountUnitType: 'PRODUCT.PRODUCTLIST.IMMEDIATE_DISCOUNT_UNIT',
  immediateDiscountStartDateTime: 'PRODUCT.PRODUCTLIST.IMMEDIATE_DISCOUNT_START_DATE',
  immediateDiscountEndDateTime: 'PRODUCT.PRODUCTLIST.IMMEDIATE_DISCOUNT_END_DATE',
};

export const i18nModifySalePrice = (message: string, key = 'PRODUCT.PRODUCTLIST.'): string => {
  return i18n.t(key + message).toString();
};
