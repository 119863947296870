export default Object.freeze({
  // 회원 > SMS 관리 > SMS 발송내역 조회
  SEND_DATE: '발송요청일시',
  RESERVATION_DATE: '발송예약일시',
  SEND_STATUS: '발송상태',
  ALL: '전체',
  SUCCESS: '발송성공',
  FAIL: '발송실패',
  TYPE: '유형',
  CONTENT: 'SMS내용',
  SEND_COUNT: '발송건수',
  SEND_COUNT_SUCCESS_FAIL: '발송건수<br/>(성공/실패)',
  PART_FAIL: '발송실패(일부)',
});
