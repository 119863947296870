

































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import TextInput from '@/components/common/input/TextInput.vue';
import { YorN } from 'ncp-api-supporter';
import { InputNumber } from '@/types';
@Component({
  components: { TextInput, ToolTip, RadioGroup },
})
export default class AccumulationRateConfig extends Vue {
  @PropSync('accumulationRate')
  private accumulationRateSync!: number;

  @PropSync('accumulationSaveYn')
  private accumulationSaveYnSync!: YorN;

  @Prop()
  private readonly defaultAccumulationRate!: number;

  @Prop({ required: true })
  private readonly accumulationUseYnDisabled: boolean;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'accumulationRate',
    data: [
      { id: 'accumulationSaveN', value: 'N', display: 'PRODUCT.ADD.NOT_USE_CONFIG' },
      { id: 'accumulationSaveY', value: 'Y', display: 'PRODUCT.ADD.USE_CONFIG' },
    ],
  };

  private get useAccumulation(): boolean {
    return this.accumulationSaveYnSync === 'Y';
  }

  private initVale = false;
  @Watch('accumulationRate')
  private initAccumulationRate() {
    if (this.accumulationRateSync > 0) {
      this.initVale = true;
    }
  }

  @Watch('accumulationSaveYnSync')
  private setInitAccumulationRate() {
    if (this.initVale) {
      this.initVale = false;
      return;
    }
    this.accumulationRateSync = this.defaultAccumulationRate;
  }

  private invalidRange = false;
  private changedAccumulationRate(insertedRate: string): InputNumber {
    const scaled = Math.pow(10, 1);
    const scaledRate = parseInt((parseFloat(insertedRate) * scaled).toString(), 10) / scaled;
    try {
      this.invalidRange = false;
      this.validateRate(scaledRate);
      return scaledRate;
    } catch (e) {
      this.invalidRange = true;
      return '';
    }
  }

  private validateRate(rate: number): void {
    if (rate < 0 || 100 < rate) throw new Error('INVALID-RANGE');
  }
}
