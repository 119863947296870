import { Vue, Component } from 'vue-property-decorator';

@Component({
  filters: {
    comma(value: number): string {
      if (value === 0) {
        return '0';
      }

      return value.toLocaleString();
    },
    minusComma(value: number): string {
      if (value === 0) {
        return '0';
      }
      return (Number(value) * -1).toLocaleString();
    },
  },
})
export default class AmountMixin extends Vue {}
