import { render, staticRenderFns } from "./SkinBannersEdit.vue?vue&type=template&id=4471c04c&"
import script from "./SkinBannersEdit.vue?vue&type=script&lang=ts&"
export * from "./SkinBannersEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports