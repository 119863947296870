export default Object.freeze({
  SHOPPINGMALL: '쇼핑몰',
  SECTION_NO: '상품섹션번호',
  SECTION_TITLE: '상품섹션 타이틀',
  SECTION_TITLE_TOP: '상품섹션타이틀',
  REGISTER_ADMIN_NAME: '등록자',
  REGISTER_YMDT: '등록일',
  UPDATE_ADMIN_NAME: '최종수정자',
  UPDATE_YMDT: '최종수정일',
  INPUT_SECTION: '상품섹션타이틀 입력',
  SECTION_MANAGE: '상품섹션 관리',
  DELETE_SELECT: '선택 삭제',
  SECTION_LOGIN: '상품섹션 등록',
  PLEASE_CHOOSE: '상품섹션을 선택해주세요',
  SURE: '선택 항목을 삭제하시겠습니까?',
  DELETE_OVER: '삭제하였습니다',
  DELETE_FAILED: '실패하였습니다 잠시 후 다시 시도 해 주세요',
  TITLE: '상품조회결과',
});
