































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { RadioBoxGroupOption } from '@/helpers/type';
import RadioGroup from '@/components/common/RadioGroup.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { addDay, getToday } from '@/utils/dateFormat';
import { DEFAULT_DATE_RANGE, DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { DateRangeOption } from '@/types';
import { today } from '@/views/contents/product/basic/ProductAdd';
export const SalesPeriodType = {
  REGULAR: 'REGULAR',
  PERIOD: 'PERIOD',
} as const;

interface SalePeriodOption {
  startYmdt: string;
  periodType: keyof typeof SalesPeriodType; // REGULAR: 상시 판매, PERIOD: 기간지정 판매
  endYmdt: string;
}

@Component({
  components: {
    DateRangePicker,
    RadioGroup,
  },
})
export default class SalesPeriod extends Vue {
  @PropSync('salePeriodInfo')
  private salePeriodInfoSync!: SalePeriodOption;

  @Prop({ required: true })
  private readonly useReservation: boolean;

  @Prop({ required: true })
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly isProhibited;

  private radioOption: RadioBoxGroupOption<keyof typeof SalesPeriodType> = {
    name: 'salesPeriod',
    data: [
      {
        id: 'salesPeriodRegular',
        value: SalesPeriodType.REGULAR,
        display: 'PRODUCT.ADD.SALES_PERIOD_REGULAR',
      },
      {
        id: 'salesPeriodPeriod',
        value: SalesPeriodType.PERIOD,
        display: 'PRODUCT.ADD.SALES_PERIOD_PERIOD',
      },
    ],
  };

  public setSalesPeriod(): void {
    if (!this.isPeriodType) {
      this.startYmd = getToday();
      this.salePeriodInfoSync.startYmdt = `${this.startYmd} ${this.startHms}`;
      this.salePeriodInfoSync.endYmdt = `${DEFAULT_DATE_RANGE.MAX} ${DEFAULT_TIME_RANGE.END}`;
      return;
    } else {
      this.salePeriodInfoSync.startYmdt = `${this.startYmd} ${this.startHms}`;
      this.salePeriodInfoSync.endYmdt = `${this.endYmd} ${this.endHms}`;
    }
  }

  private startYmd: string = getToday();
  private endYmd: string = addDay(new Date(), 90);
  private startHms: string = DEFAULT_TIME_RANGE.START;
  private endHms: string = DEFAULT_TIME_RANGE.END;

  private dateRangeOption: DateRangeOption = {
    name: 'salesPeriod',
    dateType: 'YmdHms',
    periodTypes: ['td', '1w', '30d', '60d', '90d'],
    hasPeriodBtn: true,
    fromStart: true,
  };

  private get isPeriodType(): boolean {
    return this.salePeriodInfoSync.periodType === SalesPeriodType.PERIOD;
  }

  private setInitialPeriod() {
    const [startYmd, startHms] = this.salePeriodInfoSync.startYmdt.split(' ');
    const [endYmd, endHms] = this.salePeriodInfoSync.endYmdt.split(' ');
    this.startYmd = startYmd;
    this.startHms = startHms;
    this.endYmd = endYmd;
    this.endHms = endHms;
  }

  private setInitialDateRangeOption() {
    if (this.isProhibited) return;
    this.dateRangeOption.fromRanges = today;
  }

  @Watch('salePeriodInfo')
  private setInitialData() {
    if (this.isPeriodType) {
      this.setInitialPeriod();
      this.setInitialDateRangeOption();
    }
  }
}
