import { i18n } from '@/main';
import { getDownToday } from '@/utils/dateFormat';
import { DisplayCategoriesExcel, GetDisplayCategoriesExcelRequest, NCPResponse } from 'ncp-api-supporter';
import { TranslateResult } from 'vue-i18n';
import { writeExcelFile } from '@/utils/webExcel';
import { api } from '@/api';
import { MallSummary } from '@/components/product/categorization/excel/ExcelController.vue';

type DisplayCategoriesExcelColumns = { [key in keyof DisplayCategoriesExcel]: TranslateResult };

export default class ExcelDownload {
  private readonly mallSummary!: MallSummary;

  constructor(mallSummary) {
    this.mallSummary = mallSummary;
  }

  private static getColumns = (): DisplayCategoriesExcelColumns => {
    return {
      depth1DisplayCategoryNo: i18n.t('CATEGORY_NO'),
      depth1DisplayCategoryName: '1depth ' + i18n.t('CATEGORY_NAME'),
      depth2DisplayCategoryNo: i18n.t('CATEGORY_NO'),
      depth2DisplayCategoryName: '2depth ' + i18n.t('CATEGORY_NAME'),
      depth3DisplayCategoryNo: i18n.t('CATEGORY_NO'),
      depth3DisplayCategoryName: '3depth ' + i18n.t('CATEGORY_NAME'),
      depth4DisplayCategoryNo: i18n.t('CATEGORY_NO'),
      depth4DisplayCategoryName: '4depth ' + i18n.t('CATEGORY_NAME'),
      depth5DisplayCategoryNo: i18n.t('CATEGORY_NO'),
      depth5DisplayCategoryName: '5depth ' + i18n.t('CATEGORY_NAME'),
    };
  };

  // {쇼핑몰명}전시카테고리_{현재일시}
  private static getFileName = (mallName: string): string => {
    const title = i18n.t('PRODUCT.DISPLAY_CATEGORY');
    const date = getDownToday();

    return `${mallName}${title}_${date}`;
  };

  private static generateWebExcelFile = (data: DisplayCategoriesExcel[], mallName: string): void => {
    const fileName = ExcelDownload.getFileName(mallName);
    const columns = ExcelDownload.getColumns();

    writeExcelFile<DisplayCategoriesExcel>(data, columns, fileName, true);
  };

  private static fetchDisplayCategoriesExcelData = async (mallNo: number): Promise<DisplayCategoriesExcel[]> => {
    const request: GetDisplayCategoriesExcelRequest = {
      params: { mallNo },
    };

    try {
      const { data }: NCPResponse<DisplayCategoriesExcel[]> = await api.getDisplayCategoriesExcel(request);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  public run = async () => {
    const { mallNo, mallName } = this.mallSummary;

    const data: DisplayCategoriesExcel[] = await ExcelDownload.fetchDisplayCategoriesExcelData(mallNo);
    ExcelDownload.generateWebExcelFile(data, mallName);
  };
}
