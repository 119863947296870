import { addDay, getToday } from '@/utils/dateFormat';
import { namespace } from 'vuex-class';

export const adminStore = namespace('admin');

const parameterNames = {
  mallNos: 'mallNos',
  inquiryTypeNo: 'inquiryTypeNo',
  assigneeNo: 'assigneeNo',
  keywordInfoType: 'searchType',
  keywordInfoKeywords: 'keyword',
  periodInfoType: 'periodInfo.type',
  periodInfoStartYmdt: 'startDateTime',
  periodInfoEndYmdt: 'endDateTime',
  inquiryStatuses: 'inquiryStatuses',
  page: 'page',
  pageSize: 'pageSize',
} as const;

export interface Parameters {
  mallNos: number;
  inquiryTypeNo: string;
  assigneeNo: string;
  keywordInfoType: string;
  keywordInfoKeywords: string;
  periodInfoType: string;
  periodInfoStartYmdt: string;
  periodInfoEndYmdt: string;
  inquiryStatuses: string;
  isUnspecified: string;
  page: number;
  pageSize: number;
}

export const emptyParameter: Parameters = {
  mallNos: 0,
  inquiryTypeNo: '',
  assigneeNo: '',
  keywordInfoType: '',
  keywordInfoKeywords: '',
  periodInfoType: 'ALL',
  periodInfoStartYmdt: '',
  periodInfoEndYmdt: '',
  inquiryStatuses: 'ISSUED,ANSWERED,IN_PROGRESS',
  isUnspecified: 'false',
  page: 1,
  pageSize: 30,
};

export const DefaultQuery = {
  startDateTime: '',
  endDateTime: '',
  inquiryStatuses: '',
  assigneeNo: '',
  inquiryTypeNo: '',
  keyword: '',
  searchType: '',
  page: 1,
  pageSize: 30,
};

export const getDefaultParams = (): Parameters => {
  return {
    mallNos: 0,
    inquiryTypeNo: '',
    assigneeNo: '',
    keywordInfoType: 'ALL',
    keywordInfoKeywords: '',
    periodInfoType: 'ALL',
    periodInfoStartYmdt: addDay(new Date(), -7),
    periodInfoEndYmdt: getToday(),
    inquiryStatuses: 'ISSUED,ANSWERED,IN_PROGRESS',
    isUnspecified: 'false',
    page: 1,
    pageSize: 30,
  };
};

const renameKeys = (object, newKeys) => {
  const keyValues = Object.keys(object).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: object[key] };
  });

  return Object.assign({}, ...keyValues);
};

export const renameParameterKeys = parameters => {
  return renameKeys(parameters, parameterNames);
};
