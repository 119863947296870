





import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalIframe from '@/components/iframe/ModalIframe.vue';
import { throwPopup } from '@/helpers/popup';

@Component({ components: { ModalIframe } })

export default class EditMyAccount extends Vue {
  @Prop() private onNegativeClick!: Function;

  private get remoteSrc() {
    const queryObject = { 
      serviceType: 'PRO' 
    };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + '/popup/admin-account-modify-form?' + queryString;

    return src;
  }
    private receiveMessage(e: MessageEvent) {
    if (e.data.close) { 
      this.onNegativeClick()
      return;
    }

    if(e.data.openOtpSettingModal){
      throwPopup({
        name: 'OtpAuthSettingIframe',
      })
    }
  }

  mounted() {
    window.addEventListener('message', this.receiveMessage);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  }

}
