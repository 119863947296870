


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class CancelHttpRequest extends Vue {
  @Prop() private data;
  @Prop() private readonly onNegativeClick!: () => void;

  get headerText(): TranslateResult {
    return this.data.headerText ?? this.$t('ALERT');
  }

  private cancel(): void {
    this.onNegativeClick();
  }
}
