




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ClaimStatusType } from '@/types/claim';
import { claimClassTypes, claimStatusTypes, claimTypes } from '@/const/claim';
import { PopupClose, throwNoPopupIdWindowPopup, throwWindowPopup } from '@/helpers/popup';
import { orderStatusTypes } from '@/const/order';
import { getHref } from '@/utils/route';
import { ActionType } from 'ncp-api-supporter';

@Component
export default class ClaimButtons extends Vue {
  @Prop({ required: true })
  private productInfo: any; // OrderProductInfo 컴포넌트의 ProductInfo임

  private claimType: null | 'CANCEL' | 'EXCHANGE' | 'RETURN' = null;
  private showAccept = false; // 클레임 승인 버튼 등장 flag
  private showAlreadyDelivery = false; // 이미출고 버튼 등장 flag
  private showCollect = false; // 수거완료 버튼 등장 flag
  private showDetail = false; // 상세보기 버튼 등장 flag
  private showDisapprovalAccept = false; // 반려승인처리 버튼 등장 flag

  created() {
    this.setClaimType();
    this.setButtons();
  }

  private setClaimType() {
    const CLAIM_TYPE_NAME_INDEX = 0;
    this.claimType = this.productInfo.claimStatusType.split('_')[CLAIM_TYPE_NAME_INDEX];
  }

  private setButtons() {
    switch (this.claimType) {
      case 'CANCEL':
        this.setCancelButton();
        break;
      case 'EXCHANGE':
        this.setExchangeButton();
        break;
      case 'RETURN':
        this.setReturnButton();
        break;
    }
  }

  // 취소리스트 p.30, 취소 클레임 각 상태별 등장조건
  private setCancelButton() {
    if (this.productInfo.claimStatusType === claimStatusTypes.CANCEL_REQUEST) {
      if (this.productInfo.orderStatusType === orderStatusTypes.PAY_DONE) {
        this.showAccept = true;
        return;
      }

      if (
        this.productInfo.orderStatusType === orderStatusTypes.PRODUCT_PREPARE ||
        this.productInfo.orderStatusType === orderStatusTypes.DELIVERY_PREPARE
      ) {
        this.showAccept = true;
        this.showAlreadyDelivery = !this.productInfo.holdDelivery;
        return;
      }

      this.showDetail = true;
    }

    this.showDetail = true;
  }

  // 교환리스트 기획서 p.39
  private setExchangeButton() {
    const isReturnExchange = [
      orderStatusTypes.PAY_DONE,
      orderStatusTypes.DELIVERY_ING,
      orderStatusTypes.DELIVERY_DONE,
      orderStatusTypes.BUY_CONFIRM,
    ].includes(this.productInfo.orderStatusType);

    if (
      this.productInfo.claimStatusType === claimStatusTypes.RETURN_REJECT_REQUEST ||
      this.productInfo.claimStatusType === claimStatusTypes.EXCHANGE_REJECT_REQUEST
    ) {
      this.showDisapprovalAccept = true;
      return;
    }

    if (this.productInfo.claimStatusType === claimStatusTypes.EXCHANGE_REQUEST) {
      if (isReturnExchange) {
        this.showAccept = true;
        return;
      }

      this.showAccept = true;
      this.showAlreadyDelivery = !this.productInfo.holdDelivery;

      return;
    }

    if (this.productInfo.claimStatusType === claimStatusTypes.EXCHANGE_PROC_BEFORE_RECEIVE) {
      if (isReturnExchange) {
        this.showCollect = true;
        return;
      }

      this.showDetail = true;
      return;
    }

    this.showDetail = true;
    return;
  }

  // 반품리스트 기획서 p.30
  private setReturnButton() {
    if (
      this.productInfo.claimStatusType === claimStatusTypes.RETURN_REJECT_REQUEST ||
      this.productInfo.claimStatusType === claimStatusTypes.EXCHANGE_REJECT_REQUEST
    ) {
      this.showDisapprovalAccept = true;
      return;
    }

    if (this.productInfo.claimStatusType === claimStatusTypes.RETURN_REQUEST) {
      this.showAccept = true;
      return;
    }

    if (this.productInfo.claimStatusType === claimStatusTypes.RETURN_PROC_BEFORE_RECEIVE) {
      this.showCollect = true;
      return;
    }

    this.showDetail = true;
    return;
  }

  private openClaimPopup(claimNo: number, claimStatusType?: ClaimStatusType) {
    const claimType = claimStatusType.split('_')[0];
    const claimPopupTypes = {
      [claimTypes.CANCEL]: () => `/pro/popup/cancel?claimNo=${claimNo}`,
      [claimTypes.EXCHANGE]: () => `/pro/popup/exchange?claimNo=${claimNo}`,
      [claimTypes.RETURN]: () => `/pro/popup/return?claimNo=${claimNo}`,
      [claimTypes.REFUND]: () => {
        const claimType = Object.keys(claimTypes).find(claimType => claimStatusType.includes(claimType));
        return claimPopupTypes[claimType]();
      },
    };

    throwNoPopupIdWindowPopup(claimPopupTypes[claimType](), 'xlg', ({ state }) => {
      if (state === PopupClose.CONFIRM) {
        location.reload();
      }
    });
  }

  private openClaimAcceptPopup(claimNo: number) {
    const query = `claimNo=${claimNo}`;
    const claimPopupTypes = {
      [claimTypes.CANCEL]: () => `${getHref('CancelAcceptPopup')}?${query}`,
      [claimTypes.EXCHANGE]: () => `${getHref('ExchangeAcceptPopup')}?${query}`,
      [claimTypes.RETURN]: () => `${getHref('ReturnAcceptPopup')}?${query}`,
    };

    throwNoPopupIdWindowPopup(claimPopupTypes[this.claimType](), 'xlg', ({ state }) => {
      if (state === PopupClose.CONFIRM) {
        this.reload();
      }
    });
  }

  protected async onClickAlreadyDelivery(claimNo: number) {
    if (this.claimType === claimTypes.RETURN) {
      return;
    }

    const targetClaimStatus =
      this.claimType === claimTypes.CANCEL ? claimTypes.CANCEL : claimClassTypes.CANCEL_EXCHANGE;
    const { nextActionType, shippingNo, afterClaimNos } = await this.checkDelivery(claimNo, targetClaimStatus);

    switch (nextActionType) {
      case 'ALREADY_SHIPPING':
        throwWindowPopup('AlreadyDeliveryPopup', { claimNo }, 'md', ({ state }) => {
          if (state === PopupClose.CONFIRM) {
            this.reload();
          }
        });
        return;

      case 'COMBINE_WITHDRAWAL':
      case 'NORMAL_WITHDRAWAL':
        alert(this.makeAlreadyDeliveryMessage({ type: 'NORMAL_WITHDRAWAL', shippingNo }));
        break;

      case 'EXISTS_AFTER_CLAIM':
        if (!confirm(this.makeAlreadyDeliveryMessage({ type: 'EXISTS_AFTER_CLAIM', afterClaimNos }))) {
          return;
        }
        break;
    }

    await this.withdrawClaim(claimNo, targetClaimStatus);
    alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
    this.reload();
  }

  //이미출고
  protected async checkDelivery(
    claimNo: number,
    targetClaimStatus: typeof claimTypes.CANCEL | typeof claimClassTypes.CANCEL_EXCHANGE,
  ): Promise<{ nextActionType: ActionType; afterClaimNos: string[]; shippingNo: number }> {
    const request = {
      pathParams: {
        no: claimNo.toString(),
      },
    };

    const checkDelivery = {
      CANCEL: () => this.$api.getOptionCancelsNoCheckAlreadyDelivery(request),
      CANCEL_EXCHANGE: () => this.$api.getCancelExchangesNoCheckAlreadyDelivery(request),
    };

    const {
      data: { nextActionType, shippingNo, afterClaimNos },
    } = await checkDelivery[targetClaimStatus]();

    return { nextActionType, shippingNo, afterClaimNos };
  }

  protected makeAlreadyDeliveryMessage({
    type,
    shippingNo,
    afterClaimNos,
  }: {
    type: 'NORMAL_WITHDRAWAL' | 'EXISTS_AFTER_CLAIM';
    shippingNo?: number;
    afterClaimNos?: string[];
  }): string {
    let message = '';

    message +=
      type === 'NORMAL_WITHDRAWAL'
        ? this.$t('CLAIM.MESSAGE.WARNING_AUTO_WITHDRAW_CLAIM').toString()
        : `${this.$t('CLAIM.MESSAGE.ASK_AFTER_CLAIM_WITHDRAW_CONFIRM').toString()}`;

    message +=
      type === 'NORMAL_WITHDRAWAL'
        ? `\n${this.$t('CLAIM.COMMON.DELIVERY_NO').toString()}: ${shippingNo}`
        : `\n${this.$t('CLAIM.COMMON.AFTER_CLAIM_NO').toString()}: ${afterClaimNos}`;

    return message;
  }

  protected async withdrawClaim(
    claimNo: number,
    targetClaimStatus: typeof claimTypes.CANCEL | typeof claimClassTypes.CANCEL_EXCHANGE,
  ): Promise<void> {
    const request = {
      pathParams: {
        no: claimNo.toString(),
      },
    };

    const withdraw = {
      CANCEL: () => this.$api.putOptionCancelsNoWithdraw(request),
      CANCEL_EXCHANGE: () => this.$api.putCancelExchangesNoWithdraw(request),
    };

    await withdraw[targetClaimStatus]();
  }

  private openCollectPopup(claimNo: number) {
    throwWindowPopup('CollectPopup', { claimNo }, 'md', ({ state }) => {
      if (state === PopupClose.CONFIRM) {
        this.reload();
      }
    });
  }

  private reload() {
    location.reload();
  }
}
