




import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class OrderPreOrderIframe extends Vue {
  private get remoteSrc() {
    const query = this.$route.query;
    const queryObject = { ...query, serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + '/fulfillment/order/advance-orders' + '?' + queryString;
    return src;
  }
}
