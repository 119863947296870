












































































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { generateRefundTypeSelectList } from '@/components/popup/claim/claimOptions';
import { PgType } from '@/types/order';
import { ClaimStatusType } from '@/types/claim';
import { BankAccount, ClaimData, RefundType } from 'ncp-api-supporter';
import {
  isCancelDone,
  isCancelNoRefund,
  isCancelProcRequestRefund,
  isCancelProcWaitingRefund,
  isCancelRequest,
  isReturnDone,
  isReturnProcBeforeReceive,
  isReturnProcRequestRefund,
  isReturnRefundAmtAdjustRequested,
  isReturnRequest,
} from '@/components/popup/claim/claimConditions';
import { validateBankAccount, validateRefundAdjustAmount } from '@/components/shipping/claim/validation';
import { getOnlyNumbers } from '@/utils/numberFormat';
import { AdjustedAmounts } from 'ncp-api-supporter/dist/types/modules/claim/claims';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TdRefundType from '@/components/popup/claim/ClaimPopup/RefundAmount/TdRefundType.vue';
import TdRefundAccount from '@/components/popup/claim/ClaimPopup/RefundAmount/TdRefundAccount.vue';
import TdRefundAdjustRequest from '@/components/popup/claim/ClaimPopup/RefundAmount/TdRefundAdjustRequest.vue';
import AmountMixin from '@/components/popup/claim/AmountMixin';

// 취소, 반품 팝업의 환불금액  교환 환불 금액은 ClaimPopup/Exchange/RefundAmount
@Component({
  components: {
    TextInput,
    SelectBox,
    TdRefundType,
    TdRefundAccount,
    TdRefundAdjustRequest,
  },
  computed: {
    isCancelRequest,
    isCancelNoRefund,
    isCancelProcWaitingRefund,
    isCancelProcRequestRefund,
    isCancelDone,
    isReturnRequest,
    isReturnProcRequestRefund,
    isReturnProcBeforeReceive,
    isReturnRefundAmtAdjustRequested,
    isReturnDone,
    hasRefundAmount() {
      // refundAdjustRequestAmt이 0으로 내려온다면 이는 조정요청금액 있는 것. 그래서 조정사유로 조정요청금액이 있는지 확인해야함
      return Boolean(this.refundHoldReason);
    },
    isZeroRefund() {
      // 취소/반품 화면에서는 +로 보이는 값이 환불 - 로 보이는 값이 추가결제... : claimAmt.negate()
      // 교환 화면에서는 -로 보이는 값이 환불 + 로 보이는 값이 추가결제... : claimAmt 랑 동일
      return this.amounts.adjustedAmounts.claimAmt >= 0;
    },
    showRefundAccountSelect() {
      if (
        this.isCancelNoRefund ||
        this.isCancelProcWaitingRefund ||
        this.isReturnProcBeforeReceive ||
        this.isReturnRequest
      ) {
        return false;
      }

      if (this.isAccountRefundType || this.isVirtualAccount) {
        return true;
      }

      return false;

      // TODO: QA 끝나고 무쓸모되면 지우기
      // if (
      //   (this.isCancelProcRequestRefund ||
      //     this.isReturnProcRequestRefund ||
      //     this.isReturnRefundAmtAdjustRequested ||
      //     this.isModifyMode) &&
      //   this.isAccountRefundType
      // ) {
      //   return true;
      // }
      //
      // // 히스토리: https://nhn-playart.dooray.com/project/posts/2955851033463038426
      // if (this.isModifyMode && this.isZeroRefund) {
      //   return true;
      // }
      //
      // return false;
    },
    hasRefundAccount() {
      return Boolean(this.refundBankAccountSync?.account);
    },
    hasRefundHoldReason() {
      return this.refundHoldReason !== null;
    },
    isPG() {
      return this.pgType !== 'NONE';
    },
    isVirtualAccount() {
      // modifymode true/false 시 환불방법 드롭박스 기준으로 가상계좌 파악해야하므로 sync로 판단.
      // 가상계좌: refundType: pg + payType: VIRTUAL_ACCOUNT
      // 일반PG:  refundType: pg + payType: 그 외
      return this.refundTypeSync === 'PG' && this.originalPayType === 'VIRTUAL_ACCOUNT';
    },
    isAccountRefundType() {
      return this.refundTypeSync === 'ACCOUNT'; // TODO: refundType 상수 정리
    },
    showRefundAccount() {
      if (!this.hasRefundAccount) {
        return false;
      }

      if (this.refundTypeSync === 'PG') {
        return false;
      }

      if (this.isCancelNoRefund) {
        return false;
      }

      return true;
    },
    showRefundAdjustInput() {
      return this.isReturnProcRequestRefund || this.isReturnRefundAmtAdjustRequested;
    },

    //TODO: QA 끝나고 무쓸모 되면 지우기
    // 당시 리뷰: https://github.nhnent.com/ncp/shopby-pro-admin/pull/1032
    // showRefundTypeSelect() {
    //   return (
    //     this.isCancelProcRequestRefund ||
    //     this.isCancelRequest ||
    //     this.isReturnProcRequestRefund ||
    //     this.isReturnRefundAmtAdjustRequested
    //   );
    // },
    showModifyButton() {
      return (
        (this.isCancelProcRequestRefund ||
          this.isCancelRequest ||
          this.isReturnProcRequestRefund ||
          this.isReturnRefundAmtAdjustRequested) &&
        !this.isZeroRefund
      );
    },
    showRefundExpectation() {
      return !this.isCancelDone && !this.isCancelNoRefund && !this.isReturnDone;
    },

    isNaverPay() {
      return this.originalPayType === 'NAVER_PAY';
    },
  },
})
export default class RefundAmount extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly claimStatusType: ClaimStatusType;

  @Prop({ required: true })
  private readonly pgType!: PgType;

  @Prop({ required: true })
  private readonly originalPayType!: string;

  @Prop({ required: true })
  private readonly originalPayTypeLabel!: string;

  @Prop({ required: true })
  private readonly amounts!: ClaimData['amounts'];

  @Prop({ required: true })
  private readonly refundTypeLabel: string;

  @Prop({ required: true })
  private readonly refundPayTypeLabel: string;

  @Prop({ required: false, default: null })
  private readonly refundHoldReason: null | string;

  @Prop({ required: false, default: null })
  private readonly refundAdjustRequestAmt: null | number;

  @Prop({ required: false, default: null })
  private readonly onPutClaim: (modifyType: 'reason' | 'refundAccount') => Promise<void>;

  @Prop({ required: false, default: null })
  private readonly responseRefundAdjustAmt: null | number;

  @Prop({ required: false, default: null })
  private readonly responseAdjustedAmounts: null | AdjustedAmounts;

  @PropSync('refundAdjustAmt', { required: false, default: '0' })
  private refundAdjustAmtSync: string;

  @PropSync('refundAdjustReason', { required: false, default: '' })
  private refundAdjustReasonSync: string;

  @PropSync('refundType', { required: true })
  private refundTypeSync!: RefundType;

  @PropSync('refundBankAccount', { required: true })
  private refundBankAccountSync!: BankAccount;

  private refundTypeOptions = generateRefundTypeSelectList(
    this.amounts.adjustedAmounts,
    this.pgType,
    this.originalPayTypeLabel,
  );
  private refundBankOptions = [];

  private isModifyMode = false;

  created() {
    this.fetchRefundBankList();
  }

  private async fetchRefundBankList() {
    const banksResponse = await this.$api.getBanks({ params: { countryCode: 'KR' } });
    this.refundBankOptions = banksResponse.data.filter(bank => bank.value !== 'ANONYMOUS');
  }

  private toggleModifyMode() {
    this.isModifyMode = !this.isModifyMode;
  }

  private modifyRefundAccount() {
    if (!validateBankAccount(this.refundTypeSync, this.refundBankAccountSync, this.isVirtualAccount)) {
      return;
    }

    this.onPutClaim('refundAccount').then(() => {
      this.toggleModifyMode();
    });
  }

  private onPredict() {
    if (
      !validateRefundAdjustAmount({
        formRefundAdjustAmt: getOnlyNumbers(this.refundAdjustAmtSync),
        refundAdjustAmt: this.responseRefundAdjustAmt,
        adjustedAmounts: this.responseAdjustedAmounts,
      })
    ) {
      this.refundAdjustAmtSync = '0';
    }
  }

  private get isVirtualAccount() {
    // 가상계좌: refundType: pg + payType: VIRTUAL_ACCOUNT
    // 일반PG:  refundType: pg + payType: 그 외
    return this.refundTypeSync === 'PG' && this.originalPayType === 'VIRTUAL_ACCOUNT';
  }
}
