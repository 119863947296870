





















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EventKeyword } from '@/types/tree';

const tree = namespace('tree');

@Component
export default class TreeController extends Vue {
  @tree.Action('publishEvent')
  private readonly publishEvent: (eventKeyword: EventKeyword) => void;
}
