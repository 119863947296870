































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { OriginalAddress, PostReturnsPredictRequest, ResponsibleObjectType } from 'ncp-api-supporter';
import { ComponentItemsClaimDataType } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import { PopupClose, PopupResult, throwPopup } from '@/helpers/popup';
@Component({
  components: { RadioGroup, SelectBox, TextInput },
})
export default class ReturnAddress extends Vue {
  @Prop({ required: true }) private claimOrderOptions!: {};
  @Prop({ required: true }) private claimType!: string;
  @Prop({ default: 'BUYER' }) private responsibleObjectType!: string;
  @Prop({ required: true }) private objJson!: string;
  @Prop({ required: true }) private postPredict!: {};
  @Prop({ default: '' }) private refundType!: string;
  @Prop({ default: false }) private modifyClaim!: boolean;
  @Prop({ required: true }) private orderOptionsApply!: ComponentItemsClaimDataType;
  @Prop({ default: false }) private escrowOrder!: boolean;
  @Prop({ default: false }) private returnAddressButton!: boolean;
  @Watch('refundType')
  onRefundTypeChanged() {
    if (this.claimType === 'Return') {
      if (this.isNaverPay) {
        this.returnWayTypeDisabled = true;
        this.returnWayType = 'BUYER_DIRECT_RETURN';
      }

      if (this.escrowOrder) {
        this.returnWayType = 'SELLER_COLLECT';
        if (this.refundType === 'KCP') {
          this.returnWayTypeDisabled = true;
        } else if (this.refundType === 'CASH') {
          this.returnWayTypeDisabled = false;
        } else {
          this.returnWayTypeDisabled = false;
        }
      }
    }
  }
  private orderCntMap: Map<number, number> = new Map();
  private returnWayTypeDisabled = false;
  private unApprovedDisabled = false;
  public unApprovedYn = false;
  private countryCd = 'KR';
  private isExpanded = false;
  public returnWayType = 'SELLER_COLLECT';
  public deliveryCompanyType = '';
  public invoiceNo = '';
  public returnAddress = '';
  public returnName = '';
  public returnJibunAddress = '';
  public returnContact1 = '';
  public returnContact2 = '';
  public exchangeName = '';
  public exchangeJibunAddress = '';
  public exchangeContact1 = '';
  public exchangeContact2 = '';
  public exchangeZipCd = '';
  public exchangeAddress = '';
  public exchangeDetailAddress = '';
  public returnDeliveryNote = '';
  public exchangeDeliveryNote = '';
  public exchangeCustomsIdNumber = '';
  public returnWayTypes = {
    name: 'returnWayType',
    data: [
      {
        id: 'SELLER_COLLECT',
        value: 'SELLER_COLLECT',
        display: 'CLAIM.RETURNPOPUP.RETURNWAYTYPE1',
      },
      {
        id: 'BUYER_DIRECT_RETURN',
        value: 'BUYER_DIRECT_RETURN',
        display: 'CLAIM.RETURNPOPUP.RETURNWAYTYPE2',
      },
    ],
  };
  private get deliveryCompanyTypeList() {
    const list: any = [
      {
        value: '',
        name: this.$t('CLAIM.RETURNPOPUP.SELECTEDVALUE'),
      },
      {
        value: 'CJ',
        name: this.$t('CLAIM.RETURNPOPUP.CJ'),
      },
      {
        value: 'POST',
        name: this.$t('CLAIM.RETURNPOPUP.POST'),
      },
      {
        value: 'HANJIN',
        name: this.$t('CLAIM.RETURNPOPUP.HANJIN'),
      },
      {
        value: 'GTX',
        name: this.$t('CLAIM.RETURNPOPUP.GTX'),
      },
      {
        value: 'LOTTE',
        name: this.$t('CLAIM.RETURNPOPUP.LOTTE'),
      },
      {
        value: 'KGB',
        name: this.$t('CLAIM.RETURNPOPUP.KGB'),
      },
      {
        value: 'LOGEN',
        name: this.$t('CLAIM.RETURNPOPUP.LOGEN'),
      },
      {
        value: 'GSI',
        name: this.$t('CLAIM.RETURNPOPUP.GSI'),
      },
      {
        value: 'KGL',
        name: this.$t('CLAIM.RETURNPOPUP.KGL'),
      },
      {
        value: 'INTRAS',
        name: this.$t('CLAIM.RETURNPOPUP.INTRAS'),
      },
      {
        value: 'UPS',
        name: this.$t('CLAIM.RETURNPOPUP.UPS'),
      },
      {
        value: 'YAMATO',
        name: this.$t('CLAIM.RETURNPOPUP.YAMATO'),
      },
      {
        value: 'SAGAWA',
        name: this.$t('CLAIM.RETURNPOPUP.SAGAWA'),
      },
      {
        value: 'JAPANPOST',
        name: this.$t('CLAIM.RETURNPOPUP.JAPANPOST'),
      },
      {
        value: 'CHUNIL',
        name: this.$t('CLAIM.RETURNPOPUP.CHUNIL'),
      },
      {
        value: 'KDEXP',
        name: this.$t('CLAIM.RETURNPOPUP.KDEXP'),
      },
      {
        value: 'HDEXP',
        name: this.$t('CLAIM.RETURNPOPUP.HDEXP'),
      },
      {
        value: 'ILYANG',
        name: this.$t('CLAIM.RETURNPOPUP.ILYANG'),
      },
      {
        value: 'POST_EMS',
        name: this.$t('CLAIM.RETURNPOPUP.POST_EMS'),
      },
      {
        value: 'KYOUNGDONG',
        name: this.$t('CLAIM.RETURNPOPUP.KYOUNGDONG'),
      },
      {
        value: 'DAESIN',
        name: this.$t('CLAIM.RETURNPOPUP.DAESIN'),
      },
      {
        value: 'CVS',
        name: this.$t('CLAIM.RETURNPOPUP.CVS'),
      },
      {
        value: 'DHL',
        name: this.$t('CLAIM.RETURNPOPUP.DHL'),
      },
      {
        value: 'FEDEX',
        name: this.$t('CLAIM.RETURNPOPUP.FEDEX'),
      },
      {
        value: 'GSM',
        name: this.$t('CLAIM.RETURNPOPUP.GSM'),
      },
      {
        value: 'WARPEX',
        name: this.$t('CLAIM.RETURNPOPUP.WARPEX'),
      },
      {
        value: 'WIZWA',
        name: this.$t('CLAIM.RETURNPOPUP.WIZWA'),
      },
      {
        value: 'ACI',
        name: this.$t('CLAIM.RETURNPOPUP.ACI'),
      },
      {
        value: 'PANTOS',
        name: this.$t('CLAIM.RETURNPOPUP.PANTOS'),
      },
      {
        value: 'CJ_INTERNATIONAL',
        name: this.$t('CLAIM.RETURNPOPUP.CJ_INTERNATIONAL'),
      },
      {
        value: 'TNT',
        name: this.$t('CLAIM.RETURNPOPUP.TNT'),
      },
      {
        value: 'CU',
        name: this.$t('CLAIM.RETURNPOPUP.CU'),
      },
      {
        value: 'KUNYOUNG',
        name: this.$t('CLAIM.RETURNPOPUP.KUNYOUNG'),
      },
      {
        value: 'LOTTE_INTERNATIONAL',
        name: this.$t('CLAIM.RETURNPOPUP.LOTTE_INTERNATIONAL'),
      },
      {
        value: 'HONAM',
        name: this.$t('CLAIM.RETURNPOPUP.HONAM'),
      },
      {
        value: 'HANIPS',
        name: this.$t('CLAIM.RETURNPOPUP.HANIPS'),
      },
      {
        value: 'IPARCEL',
        name: this.$t('CLAIM.RETURNPOPUP.IPARCEL'),
      },
      {
        value: 'SLX',
        name: this.$t('CLAIM.RETURNPOPUP.SLX'),
      },
      {
        value: 'USPS',
        name: this.$t('CLAIM.RETURNPOPUP.USPS'),
      },
      {
        value: 'WONDERS',
        name: this.$t('CLAIM.RETURNPOPUP.WONDERS'),
      },
      {
        value: 'REGISTPOST',
        name: this.$t('CLAIM.RETURNPOPUP.REGISTPOST'),
      },
      {
        value: 'DHLDE',
        name: this.$t('CLAIM.RETURNPOPUP.DHLDE'),
      },
      {
        value: 'EZUSA',
        name: this.$t('CLAIM.RETURNPOPUP.EZUSA'),
      },
      {
        value: 'SWGEXP',
        name: this.$t('CLAIM.RETURNPOPUP.SWGEXP'),
      },
      {
        value: 'DAEWOON',
        name: this.$t('CLAIM.RETURNPOPUP.DAEWOON'),
      },
      {
        value: 'DADREAM',
        name: this.$t('CLAIM.RETURNPOPUP.DADREAM'),
      },
      {
        value: 'CVSNET',
        name: this.$t('CLAIM.RETURNPOPUP.CVSNET'),
      },
      {
        value: 'ETC',
        name: this.$t('CLAIM.RETURNPOPUP.ETC'),
      },
    ];
    return list;
  }
  private showReturncollinfo = false;
  private created() {
    this.showReturncollinfo = true;
    this.returnName = (this.claimOrderOptions as any).returnAddress.receiverName;
    this.returnContact1 = (this.claimOrderOptions as any).returnAddress.receiverContact1;
    this.returnContact2 = (this.claimOrderOptions as any).returnAddress.receiverContact2;
    this.returnJibunAddress = (this.claimOrderOptions as any).returnAddress.receiverJibunAddress;
    this.zipCd = (this.claimOrderOptions as any).returnAddress.receiverZipCd;
    this.address = (this.claimOrderOptions as any).returnAddress.receiverAddress;
    this.detailAddress = (this.claimOrderOptions as any).returnAddress.receiverDetailAddress;
    this.returnDeliveryNote = (this.claimOrderOptions as any).returnAddress.deliveryMemo;
    if (this.claimType === 'ExchangePopupAfter') {
      this.exchangeName = (this.claimOrderOptions as any).exchangeAddress.receiverName;
      this.exchangeContact1 = (this.claimOrderOptions as any).exchangeAddress.receiverContact1;
      this.exchangeContact2 = (this.claimOrderOptions as any).exchangeAddress.receiverContact2;
      this.exchangeZipCd = (this.claimOrderOptions as any).exchangeAddress.receiverZipCd;
      this.exchangeAddress = (this.claimOrderOptions as any).exchangeAddress.receiverAddress;
      this.exchangeDetailAddress = (this.claimOrderOptions as any).exchangeAddress.receiverDetailAddress;
      this.exchangeJibunAddress = (this.claimOrderOptions as any).exchangeAddress.receiverJibunAddress;
      this.exchangeDeliveryNote = (this.claimOrderOptions as any).exchangeAddress.deliveryMemo;
      this.exchangeCustomsIdNumber = (this.claimOrderOptions as any).exchangeAddress.customsIdNumber;
    }
    this.onRefundTypeChanged();
  }
  public zipCd = '';
  public address = '';
  public detailAddress = '';

  private get isNaverPay() {
    return this.orderOptionsApply.payType === 'NAVER_PAY';
  }

  setAddressReturn(address: OriginalAddress) {
    this.zipCd = address.zipCode;
    this.address = address.roadAddress;
    this.detailAddress = address.roadAddressExtra;
    this.returnJibunAddress = address.jibunAddress;
    if (this.claimType === 'ReturnPopup') {
      const orderOptionParams: any = [];
      for (let i = 0; i < (this.$parent.$refs.componentItemsClaim as any).optionno.length; i++) {
        const claimCnt =
          (this.$parent.$refs.componentItemsClaim as any).orderCntMap.get(
            (this.$parent.$refs.componentItemsClaim as any).optionno[i],
          ) || 0;
        orderOptionParams.push({
          claimCnt: claimCnt,
          orderOptionNo: (this.$parent.$refs.componentItemsClaim as any).optionno[i],
        });
      }
      const postReturnsPredictRequest: PostReturnsPredictRequest = {
        data: {
          responsibleObjectType: (this.$parent.$refs.componentItemsClaim as any)
            .responsibleObjectTypeValue as ResponsibleObjectType,
          refundType: 'PG',
          orderNo: this.orderOptionsApply.orderNo,
          returnDeliveryProposedAmt: 0,
          sellerPaysClaimedDelivery: false,
          returnWayType: 'SELLER_COLLECT',
          returnOrderOptions: orderOptionParams,
          returnAddress: {
            address: address.roadAddress,
            name: '',
            jibunAddress: address.jibunAddress,
            detailAddress: address.roadAddressExtra,
            zipCd: address.zipCode,
            contact1: '',
            contact2: '',
          },
        },
      };
      this.$emit('setData', postReturnsPredictRequest, 'A');
    } else if (this.claimType === 'ExchangePopupBefore' || this.claimType === 'ExchangePopupAfter') {
      this.$emit('setData');
    }
  }
  setAddressExchange(address: OriginalAddress) {
    this.exchangeZipCd = address.zipCode;
    this.exchangeAddress = address.roadAddress;
    this.exchangeDetailAddress = address.roadAddressExtra;
    this.exchangeJibunAddress = address.jibunAddress;
    if (this.claimType === 'ExchangePopupBefore' || this.claimType === 'ExchangePopupAfter') {
      this.$emit('setData');
    }
  }
  callbackReturn(address: OriginalAddress) {
    (window as any).addressCallback = undefined;
    this.setAddressReturn(address);
  }
  callbackExchange(address: OriginalAddress) {
    (window as any).addressCallback = undefined;
    this.setAddressExchange(address);
  }
  private onChangeReturnWayType() {
    if (this.returnWayType === 'SELLER_COLLECT') {
      this.showReturncollinfo = true;
      this.unApprovedDisabled = true;
      this.unApprovedYn = false;
    } else {
      if (
        this.responsibleObjectType === 'SELLER' &&
        (this.postPredict as any).amounts.returnDelivery.returnDeliveryAmt > 0
      ) {
        alert(this.$t('CLAIM.RETURNPOPUP.ONCHANGERETURNWAYTYPE'));
      }
      this.showReturncollinfo = false;
      this.unApprovedDisabled = false;
    }
    if (this.claimType === 'ExchangePopupAfter' || this.claimType === 'Return') {
      this.$emit('setData');
    }
  }
  private onSearchAddress(type) {
    throwPopup({
      name: 'SearchAddress',
      data: { keyword: '' },
    }).then((result: PopupResult) => {
      if (result && result.state === PopupClose.CONFIRM) {
        const { zipCode, roadAddress, roadAddressExtra, jibunAddress } = result.data;
        if (type === 'return') {
          this.returnJibunAddress = jibunAddress;
          this.zipCd = zipCode;
          this.address = roadAddress;
          this.detailAddress = roadAddressExtra;
        } else if (type === 'exchange') {
          this.exchangeJibunAddress = jibunAddress;
          this.exchangeZipCd = zipCode;
          this.exchangeAddress = roadAddress;
          this.exchangeDetailAddress = roadAddressExtra;
        }
      }
      this.$emit('setData');
      // this.$emit('callCalculateMethod');
    });
  }

  mounted() {
    this.$emit('setData');
  }
}
