export default Object.freeze({
  TITLE: '상품섹션 등록',
  TITLE_UPDATE: '상품섹션 수정',
  TABLIE_TITLE: '기본정보',
  SHOPPING_MALL: '쇼핑몰',
  COMMODITY_SECTION_TITLE: '상품 섹션 타이틀',
  DISPLAY_CATEGORY_NAME_DOWN: '홍보문구 입력',
  DESCRIPTION_OF_COMMODITY_PARTITION: '상품 섹션 설명',
  BACKGROUND_IMAGE: '배경 이미지',
  FIND_IMAGES: '이미지찾기',
  PASSENGER: '좌 : ',
  RIGHT: '우 : ',
  FILL_THE_LEFT_AND_RIGHT_BLANK_WITH_THE_CORRESPONDING_COLOR: '좌우 여백 발생 시 해당 색으로 채워집니다.',
  TARGET_PRODUCT_SETTINGS: '대상 상품 설정',
  UNABLE_TO_LOGIN_PRODUCT_STATUS: '등록불가 상품 상태(판매완료, 판매금지, 삭제요청, 등록대기)의 상품은',
  UNABLE_TO_LOGIN_PRODUCT_STATUS_DOWN: '상품섹션 대상 상품으로 등록할 수 없습니다.',
  COMMODITY_INQUIRY_REGISTRATION: '상품조회 등록',
  COMMODITY_INQUIRY: '상품조회',
  DIRECT_REGISTRATION_OF_COMMODITY_NUMBER: '상품번호 직접등록',
  COMMODITY_NUMBER_PLURAL_COMMODITY_REGISTRATION:
    "상품번호 복수 상품 등록, 최대 500개까지 가능합니다.(Enter 또는 ',' 로 구분)",
  ADD_ITEM: '상품추가',
  ENTER_NUMBERS_AND_COMMAS: '숫자와 콤마만 입력할 수 있습니다',
  REGISTRATION_OF_COMMODITY_NUMBER: '상품번호 일괄등록',
  EXPOSURE_INFORMATION: '노출정보',
  COMMODITY_SECTOR_EXPOSURE_SETTINGS:
    '‘상품섹션’과 ‘상품’에 설정된 ‘노출 설정’ 값이 다른 경우, 상품섹션에 상품이 노출되지 않을 수 있습니다.',
  PLATFORM_EXPOSURE_SETTINGS: '플랫폼별 노출 설정',
  MEMBER_LEVEL_EXPOSURE_SETTINGS: '회원등급 노출 설정',
  MEMBERSHIP_LEVEL_LONG: '회원등급을 ‘설정’ 한 상품은 비회원에게 노출되지 않습니다.',
  MEMBER_GROUP_EXPOSURE_SETTINGS: '회원그룹 노출 설정',
  CLOSE: '닫기',
  MEMBER_GROUP: '회원그룹을 ‘설정’ 한 상품은 비회원에게 노출되지 않습니다.',
  VIEW_MEMBER_GROUP_SETTINGS: '회원그룹 설정보기',
  REGISTERED_MERCHANDISE_GUNS: '등록된 상품 총',
  DELETE_SELECTED_ITEM: '선택상품 삭제',
  TOP: '최상단',
  BOTTOM: '최하단',
  STORAGE: '저장',
  CANCEL: '취소',

  REGISTER: '홍보문구 등록',
  ALL: '전체',
  SETTING: '설정',
  MOBIKE_APP: '모바일 앱',
  MOBILE_WEB: '모바일 웹',
  NOT_SET: '설정안함',
  MEMBERSHIP_LEVEL: '전체 회원등급',
  ALL_MEMBER_GROUPS: '전체 회원그룹',
  THE_MOST_ADVANCED: '퍼스트어패럴',

  ORDER: '순서',
  MALLPRODUCTNO: '상품번호',
  PRODUCTNAME: '상품명',
  BRAND: '브랜드',
  PARTNERNAME: '파트너사',
  STATEOFRECOGNITION: '승인상태',
  SALESETTINGSTATUSTYPE: '판매상태',
  SALESETTINGSTATUS: '판매설정',
  APPLIEDIMMEDIATEDISCOUNTPRICE: '할인적용가',
  INVENTORYQUANTITY: '재고수량',
  MEMBERGRADEDISPLAYINFO: '회원등급 노출',
  MEMBERGROUPDISPLAYINFO: '회원그룹 노출',
  EXHIBITION: '전시',
  TIMEOFEXPOSURE: '노출기간',
  PRE_APPROVAL_STATUS: '승인완료이전',
  ON_PRE_SALE: '예약판매중',
  WAITING_SALE: '판매대기',
  END_SALE: '판매종료',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION_SALE: '판매금지',

  APPROVAL_COMPLETE: '승인완료',
  APPROVAL_AFTER_AMENDMENT: '수정 후 승인대기',
  WAITING_FOR_APPROVAL: '승인대기',
  ON_SALE: '판매중',
  REGULARLY: '상시',
  SET: '세트',
  REJECT_APPROVAL_AFTER_MODIFICATION: '수정 후 승인거부',
  FORBID_THE_SALE: '판매금지',
  APPLICABLE_PRICE_OF_DISCOUNT: '할인적용가',
  INVENTORY_QUANTITY: '재고수량',
  OUT_OF_STOCK: '품절',
  MEMBER_LEVEL_EXPOSURE: '회원등급 노출',
  EXPOSURE_OF_MEMBER_GROUPS: '회원그룹 노출',
  NO_DISPLAY: '전시안함',
  TIME_OF_EXPOSURE: '노출기간',
  DELETE: '삭제',

  SUCCESS: '성공',
  REGISTRATION_RESULTS: '등록결과',
  REGISTRATION_FAILED_PLEASE_CONFIRM_THE_EXTENSION_AGAIN: '등록 실패하였습니다. 확장자명을 다시 확인해주세요',
  PLEASE_LOG_IN_THE_PRODUCT_SECTION_TITLE: '상품섹션 타이틀을 등록해 주세요',
  COMMODITY_PARTITION_MODIFIED_SUCCESSFULLY: '상품섹션 수정 성공',
  PRODUCT_PARTITION_REGISTRATION_SUCCESSFUL: '상품섹션 등록 성공',
  DELETE_ITEMS_FROM_THE_PRODUCT_SECTION: '상품을 상품섹션에서 삭제하시겠습니까?',
  PLEASE_SELECT_THE_ITEM_TO_DELETE_FROM_THE_PRODUCT_SECTION: '상품섹션에서 삭제할 상품을 선택해주세요',
  PLEASE_SELECT_THE_PRODUCT_TO_MOVE: '이동할 상품을 선택해 주세요',
  DELETE_THE_SELECTED_PRODUCT_FROM_THE_PRODUCT_SECTION: '선택하신 상품을 상품섹션에서 삭제하시겠습니까?',
  NO_DATA: '등록된 상품이 없습니다.',
});
