







































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import { Skin } from '@/types/design';
import { unescapeHtml } from '@/utils/common';

@Component({
  components: {
    ModalHeader,
  },
})
export default class DesignSkinInfo extends Vue {
  @Prop() private data!: Skin;
  private unescapeHtml = unescapeHtml;
}
