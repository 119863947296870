import { centerWinOptions, escapeHtml } from '@/utils/common';
import router from '@/router/index';
import $el from '@/utils/element';
import { CellRendererProps } from '@/types/tui-grid/renderer';
import { RowKey } from '@/types/tui-grid';
import { throwPopup } from '@/helpers/popup';
import { i18n } from '@/main';

export const mustHeaderRenderer = props => {
  const div = document.createElement('div');
  const em = document.createElement('em');
  const b = document.createElement('b');
  em.style.color = '#643cff';
  em.textContent = '*';
  const onMousedown = (event: Event): void => {
    event.stopPropagation();
  };
  div.addEventListener('mousedown', onMousedown);
  b.textContent = props.columnInfo.header;
  div.appendChild(em);
  div.appendChild(b);
  return {
    getElement(): HTMLDivElement {
      return div;
    },
    beforeDestroy(): void {
      div.removeEventListener('mousedown', onMousedown);
    },
  };
};
// 组件里的方法必须要有orderChange
export function orderChangeRenderer(props, that) {
  const { grid, rowKey } = props;
  const div = document.createElement('div');
  div.classList.add(...['location_control', 'small']);
  div.setAttribute('style', 'float: initial; padding-left: 23px;');

  const span1 = document.createElement('span');
  const classList = ['controlBtn', 'actionBtn'];
  if (!rowKey) {
    classList.push('disabled');
  }
  span1.classList.add(...classList);
  span1.innerText = '▲';
  span1.addEventListener('click', (e): void => {
    if (!(e.target as HTMLSpanElement).classList.contains('disabled')) {
      const selectedRowKey = rowKey;
      that.orderChange(selectedRowKey, 'up');
    }
  });

  const span2 = document.createElement('span');
  const classList2 = ['controlBtn', 'actionBtn'];
  if (rowKey === grid.store.data.viewData.length - 1) {
    classList2.push('disabled');
  }
  span2.classList.add(...classList2);
  span2.innerText = '▼';
  span2.addEventListener('click', (e): void => {
    if (!(e.target as HTMLSpanElement).classList.contains('disabled')) {
      const selectedRowKey = rowKey;
      that.orderChange(selectedRowKey, 'down');
    }
  });

  div.appendChild(span1);
  div.appendChild(span2);
  return {
    getElement(): HTMLDivElement {
      return div;
    },
  };
}
export const helpHeaderRenderer = props => {
  const div = document.createElement('div');
  const b = document.createElement('b');
  const onMousedown = (ev): void => {
    ev.stopPropagation();
  };
  div.addEventListener('mousedown', onMousedown);
  b.textContent = props.columnInfo.header;
  const button = document.createElement('button');
  button.type = 'button';
  button.id = props.columnInfo.name + 'HelpBtn';
  button.classList.add('help');
  button.style.background = 'url(/static/ncp/css/img/sp.png) no-repeat -76px 0;';
  div.appendChild(b);
  div.appendChild(button);
  return {
    getElement(): HTMLDivElement {
      return div;
    },
    beforeDestroy(): void {
      div.removeEventListener('mousedown', onMousedown);
    },
  };
};
export const boldHeaderRenderer = props => {
  const b = document.createElement('b');
  const onMousedown = (ev): void => {
    ev.stopPropagation();
  };
  b.addEventListener('mousedown', onMousedown);
  b.textContent = props.columnInfo.header;

  return {
    getElement(): HTMLElement {
      return b;
    },
    beforeDestroy(): void {
      b.removeEventListener('mousedown', onMousedown);
    },
  };
};

/*
 * 높은 확률로 사용할 필요가 없습니다.
 * a href로 이동하게 되면 새로고침 되어 불필요한 오버헤드 발생
 * [example]
 * renderer: (props)=>{
          props.linkTo = {path: '/promotion/discount/modify', query: {discountNo: props.value}};
          return aHrefRenderer(props);
        }
* [example2]
* renderer: (props)=>{
          props.linkTo = {path: `/board/popup/faq/${props.row.faqNo}`};
          props.width = 888;
          props.height = 610;
          return aHrefRenderer(props);
        }
 * [example3]
 * renderer: (props)=>{
          props.linkTo = {path: '/promotion/discount/modify', query: {discountNo: props.value}};
          props.callback = function(path){
              do something
              location.href = path;
          };
          return aHrefRenderer(props);
        }
 * */
export const aHrefRenderer = props => {
  const text = props.text || props.value.toString();
  const linkTo = props.linkTo;
  const width = props.width || 1000;
  const height = props.width || 800;
  const callback = props.callback;

  let path: string;
  // linkTo가 object 형식으로 들어오면 urlString으로 변경
  if (typeof linkTo === 'string') {
    path = linkTo;
  } else {
    path = router.resolve(linkTo).href;
  }
  const isPopupLinkTo = path.toLowerCase().indexOf('popup') > -1;

  const aTag = document.createElement('a');

  const onMouseDown = (event: Event): void => {
    event.preventDefault();
    if (callback) {
      callback(path);
    } else {
      if (isPopupLinkTo) {
        window.open(path, '_blank', centerWinOptions(width, height));
      } else {
        router.push({ path: path });
      }
    }
  };

  aTag.innerHTML = escapeHtml(text.toString());
  aTag.setAttribute('href', path);
  aTag.addEventListener('mousedown', onMouseDown);

  return {
    getElement(): HTMLElement {
      return aTag;
    },
    beforeDestroy(): void {
      aTag.removeEventListener('mousedown', onMouseDown);
    },
  };
};

export const warpHeaderTit = ({ columnInfo }) => {
  const div = document.createElement('div');
  div.innerHTML = columnInfo.header;
  return {
    getElement(): HTMLDivElement {
      return div;
    },
    render(): void {
      //  console.log()
    },
  };
};

export const selectRenderer = props => {
  const selectOptions = props.selectOptions;
  const select: HTMLSelectElement = document.createElement('select');
  select.className = `select-${props.className}`;

  selectOptions.forEach(selectOption => {
    const option: HTMLOptionElement = document.createElement('option');

    option.value = selectOption.value;
    option.innerHTML = selectOption.text;
    option.selected = props.value;

    select.appendChild(option);

    select.value = props.value;
    select.style.width = props.width || '70%';
    select.style.margin = '0';
    select.disabled = props.disabled;
  });

  const changeEvent = (event: Event): void => {
    if (props.callback) {
      props.callback(event);
    }
  };

  select.addEventListener('change', changeEvent);

  return {
    render(): void {
      // console.log()
    },
    getElement(): HTMLSelectElement {
      return select;
    },
    beforeDestroy(): void {
      select.removeEventListener('change', changeEvent);
    },
  };
};
export const multiSelectRenderer = props => {
  const div: HTMLDivElement = document.createElement('div');
  div.className = 'tui-grid-cell-content';
  const changeEvent = (event: Event): void => {
    if (props.change) {
      props.change(event);
    }
  };
  if (props.isSelect) {
    const internalDiv: HTMLDivElement = document.createElement('div');
    internalDiv.className = `inputbox_wrap ${props.className}`;
    const selectOptions = props.selectOptions;
    const select = document.createElement('select');
    select.className = props.className;

    selectOptions.forEach(selectOption => {
      const option: HTMLOptionElement = document.createElement('option');

      option.value = selectOption.value;
      option.innerHTML = selectOption.text;
      option.selected = props.value;

      select.appendChild(option);

      select.value = props.value;
    });

    select.addEventListener('change', changeEvent);
    internalDiv.appendChild(select);
    div.appendChild(internalDiv);
  } else {
    div.textContent = props.text;
  }

  return {
    render(): void {
      // console.log()
    },
    getElement(): HTMLDivElement {
      return div;
    },
  };
};

/*
 * deprecated
 * */
export const buttonRenderer = props => {
  const button = document.createElement('button');
  button.innerHTML = props.btnText;

  const clickEvent = (event): void => {
    if (props.callback) {
      console.log(props.callback);
      props.callback(event);
    }
  };
  button.addEventListener('click', clickEvent);

  return {
    render(): void {
      // console.log();
    },
    getElement(): HTMLButtonElement {
      return button;
    },
    beforeDestroy(): void {
      button.removeEventListener('click', clickEvent);
    },
  };
};

export const textWidthButtonRenderer = ({ text, buttonText, isShowButton = true, callback }) => {
  const wrapElement = document.createElement('div');
  wrapElement.innerText = text;

  if (isShowButton) {
    const buttonElement = document.createElement('button');
    const onClick = () => {
      callback();
    };
    buttonElement.className = 'crmBtn type-white ml-2';
    buttonElement.style.minWidth = '25px';
    buttonElement.innerText = buttonText;
    wrapElement.append(buttonElement);
    buttonElement.addEventListener('click', onClick);
  }

  return {
    getElement(): HTMLElement {
      return wrapElement;
    },
  };
};

/*
* tui-grid 헤더에 툴팁이 필요한 경우(히스토리 참고: https://nhnent.dooray.com/project/posts/2981253634027918264)
* 툴팁에 id값을 넣어 주어야 합니다.
*  [ example ]
* const gridProps = {
  header: {
    columns: [
      {
        name: 'mallName',
        renderer: props => {
          props.tooltipId = 'mallName';
          return headerWithTooptipRenderer(props);
        }
      }
    ]
  },
  columns: [
   ...
  grid - displayOption props로 아래처럼 설정해 줍니다.
    headerTooltipOption: [
      {
        id: '#supplyPrice',
        popupData: { data: { message: i18n.t('PRODUCT.PRODUCT.SUPPLY_PRICE_HELP') } },
      },
    ],
* */
export const headerWithTooptipRenderer = props => {
  const $container = $el(document.createElement('div'));

  const tempToolTip = document.createElement(`div`);
  tempToolTip.innerHTML = `<div class="tool-tip-wrap">
        <button type="button" class="icon-tooltip icon-help" id="${props.tooltipId}"></button>
      </div>`;

  $container.text(props.columnInfo.header);
  $container.append(tempToolTip.firstChild as HTMLElement);

  return {
    render() {
      // 적어도 여기에선 do nothing
    },
    getElement(): HTMLElement {
      return $container.getElement() as HTMLElement;
    },
  };
};

export const hiddenDescriptionRenderer = props => {
  const $container = $el(document.createElement('div'));
  $container.addClass('tui-grid-cell-content');
  ($container.getElement() as HTMLElement).style.textOverflow = 'ellipsis';

  const descriptionBox = document.createElement(`div`);
  descriptionBox.innerHTML = `<div class="tool-tip-wrap">
        <div class="tool-tip">
          ${props.hiddenDescription}
        </div>
      </div>`;
  descriptionBox.style.position = 'absolute';

  $container.text(props.cellText);
  $container.append(descriptionBox as HTMLElement);

  const onMouseEnter = (): void => {
    const $descriptionBox = $container.find('.tool-tip')[0];
    $descriptionBox.addClass('on');
  };
  const onMouseLeave = (): void => {
    const $descriptionBox = $container.find('.tool-tip')[0];
    $descriptionBox.removeClass('on');
  };

  if (props.hiddenDescription.length > 0) {
    $container.getElement().addEventListener('mouseenter', onMouseEnter);
    $container.getElement().addEventListener('mouseleave', onMouseLeave);
  }

  return {
    render() {
      // 적어도 여기에선 do nothing
    },
    getElement(): HTMLElement {
      return $container.getElement() as HTMLElement;
    },
    beforeDestroy(): void {
      $container.getElement().removeEventListener('mouseenter', onMouseEnter);
      $container.getElement().removeEventListener('mouseleave', onMouseLeave);
    },
  };
};

export class ThumbnailImageRenderer {
  el!: HTMLImageElement;

  constructor(props: CellRendererProps) {
    const { value, columnInfo } = props;

    const el = document.createElement('img');

    const src = value;
    const { alt } = columnInfo.renderer.options.defaultImage;
    const { width, height } = columnInfo.renderer.options;
    el.style.width = width;
    el.style.height = height;

    this.el = el;
    this.render({ src, alt });
  }

  getElement = () => this.el;

  render({ src, alt }) {
    this.el.src = src;
    this.el.alt = alt;
  }
}

export class ImmutableRadioRenderer {
  protected readonly el!: HTMLInputElement;
  protected readonly columnName!: string;
  protected readonly relationColumnName!: string;
  protected readonly rowKey!: RowKey;

  constructor(props: CellRendererProps) {
    const { value, columnInfo, rowKey } = props;

    const el: HTMLInputElement = document.createElement('input');
    el.type = 'radio';
    el.checked = value as boolean;

    this.el = el;
    this.columnName = columnInfo.renderer.options.columnName;
    this.relationColumnName = columnInfo.renderer.options.relationColumnName;
    this.rowKey = rowKey;

    el.name = this.columnName;

    this.el.addEventListener('change', event => this.checkEvent(event, props));
  }

  /**
   * 오버라이딩 가능
   */
  checkEvent({ target }: Event, { grid }: CellRendererProps): void {
    const checked: boolean = (target as HTMLInputElement).checked;
    grid.setValue(this.rowKey, this.columnName, checked);
  }

  public getElement(): HTMLInputElement {
    return this.el;
  }
}

// Not Working grid has scroll
export class RadioRenderer {
  el: HTMLInputElement;

  constructor(props: CellRendererProps) {
    const { grid, rowKey, value } = props;
    const radioBtn = document.createElement('input');
    radioBtn.type = 'radio';
    radioBtn.name = 'listRadio';

    const eventHandler = props.columnInfo.renderer.options?.eventHandler;
    radioBtn.addEventListener('change', (event): void => {
      grid.uncheckAll();
      grid.check(rowKey);
      if (eventHandler) eventHandler(event, rowKey);
    });

    this.el = radioBtn;
    this.render(value, radioBtn);
  }

  getElement(): HTMLInputElement {
    return this.el;
  }

  render(value, radioBtn: HTMLInputElement): void {
    if (typeof value === 'boolean') {
      radioBtn.checked = value;
    }
  }
}

/**
 * Generate cell content of ellipisis style in grid and setting its end a custom icon.
 * @param props CellRendererProps
 * [props.iconClass]: icon class name. [ex. 'ico-attachment']
 * [props.isLink]: whether the content is a anchor link.
 * @returns
 * @description
 * 1. get cell text value and ellipsis,icon flag.
 * 2. Defined style const
 * 3. create container div and set style
 * 4. create cell wrap div and set class&style [include ellipsis,icon]
 *    and append to container div.
 * 5. create cell content element (a link or span)
 *    and append to cell warp div.
 * 6. create icon and append to container div
 * 7. return render object.
 */
export const ellipsisWithIconRenderer = props => {
  // 1.
  const text: string = props.value.toString() || '';
  const isEllipsis = props.columnInfo.ellipsis;
  const iconClass = props.iconClass;
  const isLink: boolean = props.isLink || true;
  // 2.
  const cellType = 'display:inline-block;vertical-align:middle;';
  const ellipsisType = cellType + 'text-overflow:ellipsis;';
  const cellEllipsisWithIconType = ellipsisType + 'max-width:95%;';
  const cellEllipsisType = ellipsisType + 'width:100%;';
  // 3.
  const $container = $el(document.createElement('div'));
  $container.attr('style', 'padding-right:2px;');
  // 4.
  const $cellDiv = $el(document.createElement('div'));
  $cellDiv.addClass('tui-grid-cell-content');
  $cellDiv.attr('style', cellType);

  if (isEllipsis) {
    iconClass ? $cellDiv.attr('style', cellEllipsisWithIconType) : $cellDiv.attr('style', cellEllipsisType);
  }
  $container.append($cellDiv.getElement() as HTMLElement);
  // 5.
  const content = isLink ? document.createElement('a') : document.createElement('span');
  content.innerHTML = text;
  $cellDiv.append(content);
  // 6.
  if (iconClass) {
    const iconSpan = document.createElement('span');
    iconSpan.setAttribute('class', iconClass);
    $container.append(iconSpan);
  }
  // 7.
  return {
    getElement(): HTMLElement {
      return $container.getElement() as HTMLElement;
    },
  };
};

export const reverseRowNumRenderer = ({ rowKey }, totalCount: number) => {
  const el = document.createElement('span');
  const text = document.createTextNode((totalCount - rowKey).toString());
  el.appendChild(text);

  return {
    getElement(): HTMLSpanElement {
      return el;
    },
  };
};

export const rowNumRenderer = (value, pageSize = 30) => {
  const el = document.createElement('span');
  const currentPage = new URLSearchParams(window.location.search).get('page') || 1;
  const queryPageSize = new URLSearchParams(window.location.search).get('pageSize') || pageSize;
  const rowNum = (value + Number(queryPageSize) * (Number(currentPage) - 1)).toString();

  const text = document.createTextNode(rowNum);
  el.appendChild(text);

  return {
    getElement(): HTMLSpanElement {
      return el;
    },
  };
};

// TODO : 디테일한 고려없이 짜서 나중에 개선
export class DisplayCategoryDetailOpenerRenderer {
  protected el!: HTMLDivElement;

  constructor(props: CellRendererProps) {
    const { value } = props;

    const el: HTMLDivElement = document.createElement('div');
    el.classList.add('tui-grid-cell-content');
    const textList = value as string[];
    if (textList.length === 0) {
      el.textContent = '-';
    } else if (textList.length === 1) {
      el.textContent = textList[0];
    } else {
      const button: HTMLAnchorElement = document.createElement('a');
      button.appendChild(document.createTextNode(textList[0]));
      button.addEventListener('click', () => this.clickEvent(textList));
      el.appendChild(button);
    }

    this.el = el;
  }

  private clickEvent(data: string[]): void {
    throwPopup({
      name: 'DisplayCategoryDetail',
      data,
    }).catch(console.error);
  }

  public getElement(): HTMLDivElement {
    return this.el;
  }
}

export const hyphenRenderer = () => {
  const el: HTMLSpanElement = document.createElement('span');
  el.innerText = '-';

  return {
    getElement(): HTMLSpanElement {
      return el;
    },
  };
};

export const issueTooltipMaker = (renderOption: {
  formattedValue: string;
  value: { detail: string; documentation: string };
}) => {
  function getTooltipDetailTemplate(detail, documentation?) {
    const linkText = documentation
      ? `
    <a href="${documentation}" target="_blank">
      ${i18n.t('MARKETING.CONFIGURATION.MORE').toString()}
    </a>
    `
      : '';

    return `
        <div class="tool-tip-wrap">
          <button type="button" class="icon-tooltip icon-help"></button>
          <div class="tool-tip">
            <div class="tool-tip-cont">
            <p>${detail}</p>
            <p>${linkText}</p>
            </div>
          </div>
        </div>
      `;
  }

  function onClickTooltip({ currentTarget }: MouseEvent) {
    if (!currentTarget) return;

    const AllTooltipEls = document.querySelectorAll('.tool-tip');

    AllTooltipEls.forEach(tooltipEl => {
      if (tooltipEl.classList.contains('on')) tooltipEl.classList.remove('on');
    });

    const currentTooltipEl = (currentTarget as HTMLButtonElement).nextElementSibling as HTMLDivElement;

    if (currentTooltipEl.classList.contains('on')) {
      currentTooltipEl.classList.remove('on');
    } else {
      currentTooltipEl.classList.add('on');
    }
  }

  function renderTooltip() {
    const rootEl = document.createElement('div');
    rootEl.classList.add('alert-desc');

    if (renderOption.value.detail) {
      const tooltipContainer = document.createElement('span');

      tooltipContainer.innerHTML = getTooltipDetailTemplate(
        renderOption.value.detail,
        renderOption.value.documentation,
      );

      tooltipContainer.classList.add('tooltip-container');
      (tooltipContainer.querySelector('button') as HTMLButtonElement).addEventListener('click', onClickTooltip);

      rootEl.appendChild(tooltipContainer);
    }

    const textContainer = document.createElement('span');
    textContainer.innerHTML = renderOption.formattedValue;

    rootEl.appendChild(textContainer);

    return rootEl;
  }

  return {
    getElement(): HTMLDivElement {
      return renderTooltip();
    },
  };
};
