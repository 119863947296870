

























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class StockModificationResult extends Vue {
  @Prop() private readonly data;
  @Prop() private readonly onNegativeClick!: Function;
}
