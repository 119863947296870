export default Object.freeze({
  ALL: '전체',
  NORMAL: '일반',
  NOTICE: '공지',
  HAZARD_INFORMATION: '위해정보',
  SAFE_TRANSACTION: '안전거래',
  SYSTEM: '시스템',
  VIEWS: '개 보기',
  PROMOTION_NOTICE: '프로모션 공지',
  PLEASE_INPUT: '검색어를 입력하세요.',
});
