export default Object.freeze({
  NOTICE_LIST_NOTICE1:
    '네이버페이 사용을 위한 설정/관리 메뉴입니다. 네이버페이 신청/설정에 대한 안내사항을 확인해주세요.',
  NOTICE_LIST_NOTICE1_LINK: '안내 바로가기>',

  SETTING_MANAGEMENT: '네이버페이 결제형 설정/관리',

  PARTNER_ID: '파트너 ID',
  CLIENT_ID: 'Client ID',
  CLIENT_SECRET: 'Client SECRET',

  NEED_PARTNER_ID: '파트너 ID를 입력해주세요.',
  NEED_CLIENT_ID: 'Client ID를 입력해주세요.',
  NEED_CLIENT_SECRET: 'Client SECRET을 입력해주세요.',

  GO_TO_NAVER_PAY_CENTER_RIGHT_AWAY: '네이버페이센터 바로가기',
  SAVE_SUCCESS: '저장되었습니다',
});
