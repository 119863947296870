export default Object.freeze({
  SELECT_ALL_TEXT: '전체',
  SELECT_ORDER_NO: '주문번호',
  SELECT_RECEIVER_NAME: '수령자명',
  SELECT_RECEIVER_CONTACT_1: '수령자 휴대폰번호',
  SELECT_MALL_PRODUCT_NO: '상품번호',
  SELECT_PRODUCT_NAME: '상품명',
  KEYWORD_PLACEHOLDER: '검색어 입력',
  NO_DATA_TEXT: '검색된 주문 내역이 없습니다.',
  GRID_HEADER_ORDER_YMDT: '주문일시',
  GRID_HEADER_ORDER_NO: '주문번호',
  GRID_HEADER_PAY_TYPE_LABEL: '결제수단',
  GRID_HEADER_ORDER_NAME: '주문자명',
  GRID_HEADER_LAST_PAY_AMT: '실 결제금액',
  GRID_HEADER_ORDER_STATUS_TYPE_LABEL: '주문상태',
  GRID_HEADER_CLAM_STATUS_TYPE_LABEL: '클레임상태',
  GRID_HEADER_PARTNER_NAME: '파트너사',
  GRID_HEADER_MALL_PRODUCT_NO: '상품번호',
  GRID_HEADER_PRODUCT_NAME: '상품명',
  GRID_HEADER_DISPLAY_OPTION_NAME: '옵션명: 옵션값',
  GRID_HEADER_IMMEDIATE_DISCOUNTER_PRICE: '판매가(할인적용가)',
  GRID_HEADER_ORDER_CNT: '수량',

  TOTAL_ORDER_CNT: '전체주문건수',
  REAL_ORDER_CNT: '실주문건수',
  REAL_ORDER_OPTION_CNT: '실주문상품수',
  TOTAL_PAY_AMT: '실결제금액',
  BTN_SEARCH: '조회',
  BTN_CANCEL: '초기화',
  TIME_TR_TEXT: '기간',
  KEY_WORD_TR_TEXT: '검색어',
  TOTAL_COUNT_TEXT: '조회결과 총',

  COUNT_UNIT: '건',
  UNIT: '원',
  EMPTY_SEARCH_ALERT: '검색어를 입력하세요.',
});
