


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolTipOption } from '@/types/tooltip';

@Component
export default class InvoiceNo extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption;
}
