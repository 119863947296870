export default Object.freeze({
  TITLE: '묶음배송 일괄처리',
  // eslint-disable-next-line quotes
  HEADER_NOTICE1:
    "묶음배송이 가능한 조건은 아래와 같으며, 세부 정보가 다를 경우 '더블 클릭'하여 정보를 변경할 수 있습니다.",
  HEADER_NOTICE2: '(※ 묶음배송되는 주문의 배송비는 총합으로 표기되며, 클레임 시 개별 처리됩니다.)',
  NOTICE_1: '묶음배송 그룹이 같은 주문',
  NOTICE_2: '수령자명/수령자휴대전화/배송지주소/개인통관고유부호 정보가 모두 동일한 주문',
  NOTICE_3: '단, 지번/도로명으로 각각 다르게 입력된 경우 실제주소지가 동일하더라도 묶음배송 처리가 불가능합니다.',
  NOTICE_4: 'WMS를 사용하지 않는 쇼핑몰의 주문',
  VALIDATION_ALERT: '묶음배송을 위해 배송번호와 기본주소를 확인해 주세요.',
  GRIDS_HEADER_GROUP_NAME: '처리',
  GRIDS_HEADER_RECEIVER_NAME: '수령자명',
  GRIDS_HEADER_RECEIVER_CONTANT: '수령자휴대전화',
  GRIDS_HEADER_DELIVERY_GROUP_NO: '묶음배송그룹',
  GRIDS_HEADER_SHIPPING_NO: '배송번호',
  GRIDS_HEADER_RECEIVER_ZIP: '우편번호',
  GRIDS_HEADER_RECEIVER_ADDRESS: '기본주소',
  GRIDS_HEADER_RECEIVER_DETAIL_ADDRESS: '상세주소',
  GRIDS_HEADER_CUSTOMS_ID_NO: '개인통관고유부호',
  GRIDS_HEADER_DELIVERY_TYPE: '배송방법',
  GRIDS_HEADER_ORDER_NO: '주문번호',
  GRIDS_HEADER_PRODUCT_MANAGEMENT: '상품관리코드',
  GRIDS_HEADER_PRODUCT_NAME: '상품명',
  GRIDS_HEADER_OPTION_MANAGEMENT: '옵션관리코드',
  GRIDS_HEADER_OPTION_NAME: '옵션명: 옵션값',
  GRIDS_HEADER_ORDER_CNT: '수량',
  GRIDS_HEADER_HAS_TASK_MSG: '업무메시지',
  BUTTON_EDIT: '묶음',
  DETAIL_POPUP_TITLE: '주문상세보기',
  HAS_TASK_MESSAGE_CONFIRM:
    '업무메시지가 포함된 주문이 있습니다.\n묶음배송 일괄처리 하시겠습니까?\n(기존에 입력된 택배사/송장번호는 초기화 됩니다.)',
  ATLEAST_TWO_CONFIRM: '묶음배송 처리할 배송번호를 2개 이상 체크 하세요.',
  MAKE_SURE_COMBINE_CONFIRM: '체크하신 행들을 묶음배송 하시겠습니까?\n(기존에 입력된 택배사/송장번호는 초기화 됩니다.)',
  NO_DATA: '묶음배송 처리 가능한 대상이 없습니다.',
  ALERT_TEXT_RECEIVER_ZIP_CD: '우편번호',
  ALERT_TEXT_RECEIVER_ADDRESS: '기본주소',
  ALERT_TEXT_RECEIVER_DETAIL_ADDRESS: '상세주소',
  ALERT_TEXT_CUSTOMS_ID_NUMBER: '개인통관고유부호',
  NOT_SAME_ALERT: '선택하신 행들 중 {str}이(가) 일치하지 않는 부분이 있어서 묶음배송이 불가능 합니다.',
  CHANGE_DATA_CONFIRM: '내용이 수정되었습니다. 저장하시겠습니까?',
  CONFIRM: '묶음배송 처리 되었습니다.',
  DELIVERY_TYPE_PARCEL_DELIVERY: '택배/등기/소포',
  DELIVERY_TYPE_DIRECT_DELIVERY: '직접배송(화물배달)',
});
