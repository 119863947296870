import { ActionContext } from 'vuex';
import { PartnerType } from '@/types';

export interface PartnerState {
  partnerType?: PartnerType;
  partnerNo?: number;
}

export default {
  namespaced: true,
  state: { partnerType: 'ALL', partnerNo: 0 },
  actions: {
    setPartnerType({ commit }: ActionContext<PartnerState, PartnerType>, { partnerType }) {
      commit('SET_PARTNER_TYPE', partnerType);
    },
    setPartnerNo({ commit }: ActionContext<PartnerState, number>, { partnerNo }) {
      commit('SET_PARTNER_NO', partnerNo);
    },
    resetPartnerState({ commit }: ActionContext<PartnerState, void>) {
      commit('RESET_PARTNER_STATE');
    },
  },
  mutations: {
    SET_PARTNER_TYPE(state: PartnerState, partnerType): void {
      state.partnerType = partnerType;
    },
    SET_PARTNER_NO(state: PartnerState, partnerNo): void {
      state.partnerNo = partnerNo;
    },
    RESET_PARTNER_STATE(state: PartnerState): void {
      state.partnerNo = 0;
      state.partnerType = 'ALL';
    },
  },
  getters: {
    getPartnerType(state: PartnerState) {
      return state.partnerType;
    },
    getPartnerNo(state: PartnerState) {
      return state.partnerNo;
    },
  },
};
