























import { Vue, Component, Prop, Model } from 'vue-property-decorator';

import { TabState } from '@/views/contents/board/basic/InquiriesList.vue';
export interface SummaryItem<T> {
  name: string;
  count: number;
  value: T;
}

// 본래 쿼리까지 심어주던 아이였으나 클릭이벤트만 emit 해주는 선으로 강등함
@Component
export default class SummaryTab extends Vue {
  @Model('select') private readonly value: TabState;
  @Prop({ required: true })
  private readonly tabData: SummaryItem<TabState>[];
  @Prop({ required: false, default: '' })
  private readonly unitString: string;

  private selectedTabState: TabState = '';

  private onClickTab(value: TabState): void {
    this.selectedTabState = value;
    this.$emit('select', this.selectedTabState);
  }
}
