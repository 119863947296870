export default Object.freeze({
  // SUMMARY.
  ALERT_REFRESH_BLOCKED: '업데이트는 1분에 1회 가능합니다.\n{second}초 뒤 다시 시도해주세요.',

  SALES_STATUS: '판매현황',
  QUESTION_STATUS: '문의/답변 현황',
  CLAIM_STATUS: '클레임 현황',
  DELAY_STATUS: '처리지연 현황',
  PRODUCT_STATUS: '상품 현황',
  LATELY: '최근',
  PERIOD_MONTH: '{period}개월',
  PERIOD_YEAR: '{period}년',

  LOOKUP_SETTING: '조회설정',
  INQUIRY_PERIOD: '조회기간',
  INQUIRY_RANGE: '조회범위',
  DISPLAYS: '노출항목',

  NEW_ORDER: '신규주문',

  DEPOSIT_WAIT: '입금대기',
  PAY_DONE: '결제완료',
  PRODUCT_PREPARE: '상품준비중',
  DELIVERY_PREPARE: '배송준비중',
  HOLD_DELIVERY: '배송보류',
  RESERVATION: '예약주문',
  DELIVERY_ING: '배송중',
  DELIVERY_DONE: '배송완료',
  BUY_CONFIRM: '구매확정',

  UNANSWERED_INQUIRIES: '미답변 문의',
  PRODUCT_INQUIRIES_ALL: '상품문의 전체',
  PRODUCT_INQUIRIES_UNANSWERED: '상품문의 미답변',
  ONE_ON_ONE_INQUIRIES_ALL: '1:1문의 전체',
  ONE_ON_ONE_INQUIRIES_NO_ASSIGN: '1:1문의 담당자 미지정',
  ONE_ON_ONE_INQUIRIES_UNANSWERED: '1:1문의 미답변',
  ONE_ON_ONE_INQUIRIES_PROGRESS: '1:1문의 진행중',

  TOTAL_CLAIM: '환불/보류/대기/조정 요청',
  CANCELLATION_REQUEST: '취소신청',
  CANCELLATION_PROCESSING: '취소처리',
  RETURN_REQUEST: '반품신청',
  RETURN_PROCESSING: '반품처리',
  EXCHANGE_REQUEST: '교환신청',
  EXCHANGE_PROCESSING: '교환처리',

  DELAY_RECEPTION: '접수지연',
  DELAY_SHIPMENT: '발송지연',
  DELAY_DELIVERY_PENDING: '배송보류지연',
  DELAY_NAVER_PAY: '네이버페이지연',
  DELAY_CANCELLATION: '취소지연',
  DELAY_EXCHANGE: '교환지연',
  DELAY_RETURN: '반품지연',
  DELAY_RESPONSE: '답변지연',

  ON_SALE: '판매중',
  SOLD_OUT: '품절상품',
  STOCK_UNDER_TEN: '재고 10개 이하',
});
