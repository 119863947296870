







import { Mall } from 'ncp-api-supporter';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { namespace } from 'vuex-class';
import {
  getChatbotConfig,
  chatbotEmbedSrc,
  chatbotEmbedSrcId,
  chatbotConfigScriptId,
} from '@/components/chatBot/chatBot';

const summaryStore = namespace('summary');

@Component
export default class ChatBot extends Vue {
  @summaryStore.Getter('getMallNoQuery')
  protected readonly mallNoQuery!: 'ALL' | number;

  @Getter('mall/getMalls')
  private readonly malls!: Mall[];
  private firstMallNo = '';
  private shopUrl = '';
  private sno = '';
  private tempShopUrl = '';
  private userId = '';
  private showButton = false;

  private async checkShowButtonDisplay() {
    const { data } = await this.$api.getManagerInfo();

    if (data.role === 'MASTER') this.showButton = true;
  }
  // - 실행시점: store > summary > state.mallNo 변경 시
  // - 목적: 메인 페이지에서 몰 넘버 선택 시 해당 몰의 챗봇 api 호출
  @Watch('mallNoQuery')
  private changeMallNo() {
    this.fetchChatbotWithVal(this.mallNoQuery);
  }

  //- 실행시점: route path 변경시
  //- 목적: 요구사항으로 페이지가 변경될 시 챗봇이 닫히면 좋겠다고 해서 만들었습니다.
  @Watch('$route.path')
  private reOpenChatbot() {
    this.fetchChatbotWithVal(this.mallNoQuery);
  }

  async created() {
    this.firstMallNo = this.malls[0].mallNo.toString();
    await this.checkShowButtonDisplay();
    await this.fetchChatbot(this.firstMallNo);
  }

  private async fetchChatbot(mallNo: string) {
    if (!this.showButton) return;

    const request = {
      params: {
        mallNo: mallNo,
      },
    };

    const { data } = await this.$api.getServiceChatbot(request);

    this.shopUrl = data.shopUrl;
    this.sno = data.sno;
    this.tempShopUrl = data.tempShopUrl;
    this.userId = data.userId;

    this.rendorChatbot();
  }

  private fetchChatbotValidate(mallNo: number | 'ALL') {
    if (!this.showButton) return;

    const retuenResult = this.resetDom(mallNo);

    let stringMallNo = '';

    if (retuenResult === 'ALL') {
      stringMallNo = this.firstMallNo.toString();
    } else {
      stringMallNo = mallNo.toString();
    }

    return stringMallNo;
  }

  public async fetchChatbotWithVal(mallNo: number | 'ALL') {
    const validateMallNo = this.fetchChatbotValidate(mallNo);

    this.fetchChatbot(validateMallNo);
  }

  private rendorChatbot() {
    const chatbotConfig = getChatbotConfig(this.sno, this.shopUrl, this.tempShopUrl, this.userId);

    const chatbotConfigScript = document.createElement('script');
    chatbotConfigScript.type = 'text/javascript';
    chatbotConfigScript.id = chatbotConfigScriptId;
    chatbotConfigScript.innerHTML = chatbotConfig;
    document.body.appendChild(chatbotConfigScript);

    const chatbotEmbed = document.createElement('script');
    chatbotEmbed.type = 'text/javascript';
    chatbotEmbed.id = chatbotEmbedSrcId;
    chatbotEmbed.src = chatbotEmbedSrc;
    document.body.appendChild(chatbotEmbed);
  }

  private resetDom(mallNo: number | 'ALL') {
    if (mallNo === 'ALL') {
      this.domClear();
      return 'ALL';
    }

    this.domClear();
  }

  private domClear() {
    const icon = document.getElementById('ic3_thread');
    const scriptEle01 = document.getElementById('ic3_data');
    const scriptEle02 = document.getElementById(chatbotConfigScriptId);
    const scriptEle03 = document.getElementById(chatbotEmbedSrcId);

    scriptEle01.parentNode.removeChild(scriptEle01);
    scriptEle02.parentNode.removeChild(scriptEle02);
    scriptEle03.parentNode.removeChild(scriptEle03);
    icon.innerHTML = '';
  }
}
