




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class CrmMainIframe extends Vue {
  private onLoadIframe() {
    window.location.replace(process.env.VUE_APP_ADMIN_REMOTE_URL + '/crm/' + this.$route.params.memberNo);
  }

  private get remoteSrc() {
    const queryObject = { ...this.$route.query, serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/crm/' + this.$route.params.memberNo + '?' + queryString;
  }
}
