export default Object.freeze({
  TITLE: '할인쿠폰 관리',
  PAYBACK_TITLE: '쿠폰 철회',
  VIEW_TITLE: '쿠폰 지급 현황',
  COUPON_SEARCH_TITLE: '쿠폰조회',
  TAB_COUPON_LIST: '할인쿠폰 발행 관리',
  TAB_COUPON_ISSUE_LIST: '할인쿠폰 지급 관리',
  TOP_TBODY_MALL: '쇼핑몰',
  TOP_TBODY_MALL_ALL: '전체',
  TOP_TBODY_KEYWORD: '검색어',
  TOP_TBODY_KEYWORD_PLACEHOLDER: '검색어 입력',
  TOP_TBODY_DATE: '기간',
  TOP_TBODY_COUPON_ISSUE_STATUS: '지급설정 상태',
  TOP_TBODY_PAYTYPE: '지급유형',
  TOP_TBODY_PAYTYPE1: '지급 유형',
  TOP_TBODY_MALL_NOTE: '쇼핑몰을 선택하세요. ',

  TOP_TBODY_STATUS_ALL: '전체선택',
  TOP_TBODY_STATUS_ISSUE_READY: '지급대기',
  TOP_TBODY_STATUS_ISSUE_ING: '지급중',
  TOP_TBODY_STATUS_ISSUE_DONE: '지급완료',
  TOP_TBODY_STATUS_ISSUE_END: '지급종료',
  TOP_TBODY_STATUS_ISSUE_COUPON: '쿠폰 확인필요', //ADD BY KING for target 118
  TOP_TBODY_STATUS_ISSUE_CANCEL: '지급취소',

  TOP_TBODY_SEARCH_DATE_TYPE_REGISTRATION: '지급조건 등록일', //ADD BY KING for target 118
  TOP_TBODY_SEARCH_DATE_TYPE_MODIFIED: '지급조건 수정일', //ADD BY KING for target 118

  TOP_TBODY_ADD_COUPON_ISSUE: '할인쿠폰 지급등록',

  TOP_TBODY_SEARCH_TYPE_ALL: '전체',
  TOP_TBODY_SEARCH_TYPE_ISSUE_NO: '지급번호',
  TOP_TBODY_SEARCH_TYPE_REGISTRANT: '지급등록자',
  TOP_TBODY_SEARCH_TYPE_MODIFIER: '최종수정자',
  TOP_TBODY_SEARCH_TYPE_REGISTRANTER: '등록자', //ADD BY KING for target 118

  TOP_TBODY_DATE_TYPE_REGISTER: '등록일시',
  TOP_TBODY_DATE_TYPE_ISSUE: '발행일시',
  TOP_TBODY_DATE_TYPE_SCHEDULED: '예약일시',
  TOP_TBODY_DATE_TYPE_UPDATE: '최종수정일시',

  TOP_TBODY_SET_STATUS_ALL: '전체',
  TOP_TBODY_SET_STATUS_IMMEDIATELY: '즉시지급',
  TOP_TBODY_SET_STATUS_SCHEDULED: '예약지급',
  TOP_TBODY_SET_STATUS_PERIOD: '기간지급',
  TOP_TBODY_SET_STATUS_AUTO: '자동지급', //ADD BY KING FOR 118
  TOP_TBODY_SET_STATUS_BIRTHDAY: '생일', //ADD BY KING FOR 118
  TOP_TBODY_SET_STATUS_SIGNUP: '회원가입', //ADD BY KING FOR 118

  FORM_HEADER_PAYTERMS: '지급 조건',
  FORM_HEADER_NO: '지급번호',
  FORM_HEADER_COPY: '복사',
  FORM_HEADER_MALLNAME: '쇼핑몰',
  FORM_HEADER_ISSUE_TIME_TYPE: '지급설정',
  FORM_HEADER_ISSUE_STATUS_TYPE: '지급상태',
  FORM_HEADER_ISSUE_COUNT: '지급현황',
  FORM_HEADER_ISSUE_REASON: '지급사유',
  FORM_HEADER_REQUEST_YMDT: '예약일시',
  FORM_HEADER_ISSUE_YMDT: '지급일시',
  FORM_HEADER_REGISTER_ADMIN_NAME: '지급등록자',
  FORM_HEADER_REGISTER_YMDT: '등록일시',
  FORM_HEADER_UPDATE_ADMIN_NAME: '최종수정자',
  FORM_HEADER_UPDATE_YMDT: '최종수정일',
  FORM_HEADER_COPY_VALUE: '복사등록',
  FORM_HEADER_ISSUE_PAYTYPE: '지급유형', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_PAYTERM: '지급조건', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_RESERVATIONDATE: '예약일', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_PAYMENTPERIOD: '지급기간', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_REGISTRANT: '등록자', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_REGISTRATIONDATE: '등록일', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_FINALMODIFIER: '최종 수정자', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_LASTMODIFIED: '최종 수정일', //ADD BY KING FOR 118
  FORM_HEADER_ISSUE_PAYTARGET: '지급대상',

  FORM_HEADER_MALLNO: '쇼핑몰',
  FORM_PAYMENT_VALIDPERIOD_1: '지급일 기준',
  FORM_PAYMENT_VALIDPERIOD_2: '년',
  FORM_PAYMENT_VALIDPERIOD_3: '개월<br/>(',
  FORM_PAYMENT_VALIDPERIOD_4: '개월)',
  FORM_PAYMENT_VALIDPERIOD_5: '기본 유효기간에 따름',
  FORM_PAYMENT_VIEW_DETAIL_VIEW: '보기',
  FORM_PAYMENT_IMMEDIATELY_NOW: '즉시지급',
  FORM_PAYMENT_IMMEDIATELY_NOT: '예약지급',
  FORM_PAYMENT_REASON_TAG: '예약',
  FORM_HEADER_NO_NEXT_LINE_1: '적립금',
  FORM_HEADER_NO_NEXT_LINE_2: '번호',
  FORM_PAYBACK_REASON: '철회 사유',
  FORM_PAY_DETAIL: '지급 상세',
  EXCEL_BATCH_REGISTRATION: '엑셀 일괄등록',
  FORM_POINT_PAYMENT: '지급 시점',
  FORM_PAYMENT_REASON: '지급 사유',
  FORM_PAYMENT_REASON_PLACEHOLDER: '지급 사유를 입력해주세요.',
  FORM_SELECT_COUPON: '지급 쿠폰 선택',
  FORM_COUPON_INQUIRY: '쿠폰 조회',
  FORM_COUPON_SELECT: '선택 쿠폰',
  FORM_NO_COUPON: '선택된 쿠폰이 없습니다.',
  FORM_NOTIFICATION_SET: '알림 설정',
  FORM_NOTIFICATION: '알림수단',
  FORM_MAIL: '이메일',

  GRID_MEMBER_NUMBER: '회원번호',
  GRID_ID: '아이디',
  GRID_MEMBER_LEVEL: '회원등급',
  GRID_COUPON_NUMBER: '쿠폰번호',
  GRID_COUPON_STATUS: '사용상태',
  GRID_COUPON_ISSUEYMDT: '발급일',
  GRID_COUPON_USEENDYMDT: '만료일',
  GRID_COUPON_WITHDRAWYMDT: '철회일',
  GRID_COUPON_ADMINISSUEREASON: '철회사유',
  GRID_NO: '지급번호',
  GRID_COPY: '복사',
  GRID_MALL: '쇼핑몰',
  GRID_TIMETYPE: '지급유형',
  GRID_TIME_TYPE: '지급조건',
  GRID_ISSUESTATUSTYPE: '지급상태',
  GRID_REQUESTYMDT: '예약일',
  GRID_ISSUEYMDTSTR: '지급기간',
  GRID_ISSUECOUNT: '지급현황',
  GRID_REGISTERADMINNAME: '등록자',
  GRID_REGISTERYMDT: '등록일',
  GRID_UPDATEADMINNAME: '최종 수정자',
  GRID_UPDATEYMDT: '최종 수정일',

  COUPON_STATUS_AVAILABLE: '사용가능',
  COUPON_STATUS_USED: '사용완료',
  COUPON_STATUS_EXPIRED: '기간만료',
  COUPON_STATUS_WITHDRAW: '철회완료',

  PAYBACK: '지급철회',
  COUPON_ADD: '+ 지급 등록',

  KEYWORD_IS_EMPTY: '검색어를 입력해주세요.',
  KEYWORD_ONLY_NUMBER: '숫자만 입력할 수 있습니다.',
  PERIOD_MAX_LIMIT: '조회기간은 최대 12개월까지 가능합니다.',
  CHECKBOX_MUST_SELECT: '지급상태값은 1개 이상 선택해야 합니다.',
  NO_DATA: '데이터가 존재하지 않습니다.',
  DATE_RROR: '시작일자는 종료일자보다 클 수 없습니다.',
  DATE_MORE: '조회기간은 최대 1년까지 가능합니다.',
  NO_PAY_TYPE: '지급상태를 선택해주세요.',

  TITLE_NAME_COUPON_SET: '쿠폰 지급 설정 ',
  TARGET_MEMBER: '대상 회원',
  BTN_MEMBER_SELECT: '회원조회',
  LABLE_MEMEBER_START: '회원이',
  LABLE_MEMEBER_END: '명 선택되었습니다.',
  LABLE_MEMEBER_NOTE: '선택후대상회원중일부가휴면회원및탈퇴회원으로전환된경우해당회원은발급대상에서제외됩니다.',

  TOOLMESSAGE: `· 쿠폰 확인필요 건이란 지급상태가 ‘지급대기’, ‘지급중’인 지급 건 중 발급불가한 쿠폰이 선택되어 있는 상태의 지급 건을 의미합니다.
  · 지급유형이 즉시지급, 예약지급일 경우, 선택한 쿠폰이 ‘발급종료’ 상태가 되면 해당 쿠폰은 지급되지 않습니다.
  · 지급유형이 자동지급일 경우, 선택한 ‘발급중지‘, ‘발급종료’ 상태가 되면 해당 쿠폰은 지급되지 않습니다.
  · 지급번호 클릭 시 출력되는 지급 설정 팝업에서 지급할 쿠폰을 변경할 수 있습니다. `,
  TOOLTIPMESSAGE: `· 지급 시점을 ‘예약지급‘으로 설정한 경우, 쿠폰의 발급상태 및 회원의 상태에 따라 쿠폰 발급이 불가할 수 있습니다.
  · 예약시점이 도래하기 전에 선택한 쿠폰의 발급상태가 ‘발급종료‘로 변경될 경우, 해당 쿠폰은 발급되지 않습니다.
  · 예약시점이 도래하기 전에 선택한 회원이 휴면회원 혹은 탈퇴회원으로 전환될 경우, 해당 회원은 제외하고 쿠폰을 발급합니다.
`,
  TOOLTIPMESSAGECOUPON: `· 선택 쿠폰 중 발급이 불가한 쿠폰은 삭선으로 표기됩니다.
· 자동지급 시, 발급 상태가 ‘발급중‘ 상태인 ‘자동발급’ 쿠폰만 지급 가능합니다.
· 즉시/예약지급 시, 전체 발급유형의 쿠폰 선택이 가능하며, ‘발급종료‘를 제외한 모든 상태의 쿠폰 발급이 가능합니다.
`,
  TOOLTIPMESSAGENOTIFICATION: `· 알림 발송 시점의 수단별 상태에 따라 알림이 발송되지 않을 수 있습니다.
· 알림수단의 사용여부, 자동 발송항목의 사용여부 및 야간발송 제한 설정에 따라 알림이 발송되지 않을 수 있습니다.
· SMS 선택 시, SMS 포인트가 부족할 경우 부족한 포인트만큼 일부 또는 전체 회원을 대상으로 알림 발송에 실패할 수 있습니다.
· 회원별 수신동의여부에 따라 알림이 발송되지 않을 수 있습니다.
`,
  TOOLTIPMESSAGEPAYTERM: `· ‘생일’ 선택 시, 지급기간 동안 매일 당일이 생일인 회원을 대상으로 쿠폰이 지급됩니다.
· ‘회원가입’ 선택 시, 회원가입 시점에 가입한 회원을 대상으로 쿠폰이 지급됩니다.
`,
  CANCELMESSAGE: `쿠폰 지급을 취소하시겠습니까?
취소 후에는 상태 변경이 불가능합니다.`,
  NOTICE_MESSAGE: '지급 불가한 쿠폰이 포함되어 있습니다.',
  CONFIRM1: '쿠폰 지급을 취소하시겠습니까?\n취소 후에는 상태 변경이 불가능합니다.',
  CONFIRM2: '쿠폰 지급을 종료하시겠습니까?\n종료 시 지급 종료일이 현재시점으로 변경됩니다.',
  PAYBACK_REASON_MSG: '철회사유를 입력해주세요',
  NO_REASON: '철회사유를 입력해주세요.',
  ADD_REASON_CONFIRM: '지급된 쿠폰을 철회할 경우 고객의 쿠폰리스트에서 삭제됩니다.쿠폰을 철회하시겠습니까?',
  PAYBACK_OK: '철회가 완료되었습니다.',
  DESCRIPTION: '선택 후 대상 회원 중 일부가 휴면회원 및 탈퇴회원으로 전환된 경우 해당 회원은 발급 대상에서 제외됩니다.',
  NOW: '현재 ',
  CANNOT_SENT: ' 알림 발송이 불가합니다.',
  CANCEL_PAYMENT: '지급 취소',
  NOT_SET: '설정 안 함',
  SET: '설정함',
  ALERT: '지급할 쿠폰을 선택해주세요.',
  ALERT2: '지급대상을 선택해주세요.',
  ALERT3: '지급 쿠폰은 최대 10개까지 선택 가능합니다.',
  ALERT4: '쿠폰 지급 설정이 변경되어 선택 쿠폰 적용이 불가합니다.',
  ALERT_AFTER_THIS_TIME: '예약지급 시점은 현재 시점 이후로만 설정 가능합니다.',
  ALERT_AFTER_THIS_TIME1: '지급 시작일은 현재 시점 이후로만 설정 가능합니다.',
  ALERT_AFTER_THIS_TIME2: '시작일자는 종료일자보다 클 수 없습니다.',
  ALERT_NOTICE_METHOD: '알림수단을 선택해주세요.',
  CONFIRM_DO_COUPON: '선택한 {0}개의 쿠폰을 지급하시겠습니까?',
  CONFIRM_END_COUPON: '쿠폰 지급을 종료하시겠습니까? \n종료 시 지급 종료일이 현재시점으로 변경됩니다.',
  CONFIRM_CANCEL_COUPON: '쿠폰 지급을 종료하시겠습니까? \n종료 시 지급 종료일이 현재시점으로 변경됩니다.',
  COUPON_WARN:
    '쿠폰 지급 설정이 완료되었습니다.\n선택한 회원의 수가 많을 경우, 실제 지급 시까지 시간이 다소 소요될 수 있습니다.\n(지급 결과는 ‘쿠폰 지급 관리‘ 메뉴에서 확인할 수 있습니다.)',
  CONFIRM_CHANGE_MALL: '쇼핑몰을 수정할 경우 일부 설정값이 초기화됩니다.수정하시겠습니까?',
  CONFIRM_CHANGE_PAYTYPE: '지급유형을 수정할 경우 일부 설정값이 초기화됩니다.수정하시겠습니까?',
  NO_MALL: '쇼핑몰을 선택해주세요.',
  SAVE_ADD: '선택 추가',
  CONFIRM_PAYMENT_ACCUMULATION: '현재 알림 발송이 불가한 상태입니다.\n쿠폰을 지급하시겠습니까?',
  CONFIRM_COUPON_NOUSEABLE_PERIOD_ISSUE:
    '발급상태가 ‘발급중지’, ‘발급종료’인 쿠폰은 선택이 불가합니다.\n해당 쿠폰을 제외하고 추가하시겠습니까?',
  CONFIRM_COUPON_NOUSEABLE: '발급상태가 ‘발급종료’인 쿠폰은 선택이 불가합니다.\n해당 쿠폰을 제외하고 추가하시겠습니까?',

  FAILURE: '실패',
  SUCCESS: '성공',
  STATUS_COUNT: '총 {total} 건',

  ISSUE_STATUS_NOTICE_1:
    '지급 내역 전체 건수 확인이 가능하며, 지급에 성공한 건수과 실패한 건수를 별도로 확인할 수 있습니다.',
  ISSUE_STATUS_NOTICE_2: '건수를 클릭할 경우, 해당하는 내역만 하단에 출력하여 조회 가능합니다.',
});
