import { AdminState, Admin } from '@/types/admin';
import { GetMainMenus, NCPResponse, GetServicesByServiceNo, AdminRole } from 'ncp-api-supporter';
import Cookies from 'js-cookie';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/root';
import { api } from '@/api';
import { DetailOpenOptions } from 'ncp-api-supporter/dist/types/modules/admin/admin';

export default {
  namespaced: true,
  state: {
    admin: null,
    adminDetail: null,
    menuGroups: null,
    godoSno: null,
    detailOpenOptions: null,
    commerceAccount: false,
    selectedAdminRole: 'MASTER',
  },
  actions: {
    async fetchGodoSno({ commit }: ActionContext<AdminState, RootState>): Promise<void> {
      const { serviceNo } = JSON.parse(Cookies.get('adminInfo'));
      const { data }: NCPResponse<GetServicesByServiceNo> = await api.getServicesByServiceNo({ params: { serviceNo } });
      const { godoSno } = (data as unknown) as { godoSno: string };

      commit('SET_ADMIN_DETAIL', data);
      commit('SET_GODO_SNO', Number(godoSno));
    },
    setCommerceAccount({ commit }: ActionContext<AdminState, RootState>, commerceAccount: boolean) {
      commit('SET_COMMERCE_ACCOUNT', commerceAccount);
    },
    setSelectedAdmin({ commit }: ActionContext<AdminState, RootState>, selectedAdminRole: AdminRole) {
      commit('SET_SELECTED_ADMIN', selectedAdminRole);
    },
  },
  mutations: {
    SET_GODO_SNO(state: AdminState, godoSno: number): void {
      state.godoSno = godoSno;
    },
    SET_MENUS(state: AdminState, menus: GetMainMenus[]): void {
      state.menuGroups = menus;
    },
    SET_ADMIN(state: AdminState, admin: Admin): void {
      state.admin = admin;
      Cookies.set('adminInfo', JSON.stringify(admin));
    },
    SET_ADMIN_DETAIL(state: AdminState, adminDetail: GetServicesByServiceNo): void {
      state.adminDetail = adminDetail;
    },
    SET_DETAIL_OPEN_OPTIONS(state: AdminState, detailOpenOptions: DetailOpenOptions | null): void {
      state.detailOpenOptions = detailOpenOptions;
    },
    SET_COMMERCE_ACCOUNT(state: AdminState, commerceAccount: boolean): void {
      state.commerceAccount = commerceAccount;
    },
    SET_SELECTED_ADMIN(state: AdminState, selectedAdminRole: AdminRole): void {
      state.selectedAdminRole = selectedAdminRole;
    },
  },
  getters: {
    getGodoSno(state: AdminState): number | null {
      return state.godoSno;
    },
    getAdmin(state: AdminState): Admin | undefined {
      if (state.admin) {
        return state.admin;
      }
      if (Cookies.get('adminInfo')) {
        return JSON.parse(Cookies.get('adminInfo'));
      }
    },
    getServiceNo(): number {
      return JSON.parse(Cookies.get('adminInfo')).serviceNo;
    },
    getMenuGroups(state: AdminState): GetMainMenus[] | undefined {
      if (state.menuGroups) {
        return state.menuGroups;
      }
    },
    getAdminDetail(state: AdminState): GetServicesByServiceNo {
      return { ...state.adminDetail };
    },
    getDetailOpenOptions(state: AdminState): DetailOpenOptions {
      return state.detailOpenOptions;
    },
    commerceAccount(state: AdminState): boolean {
      return state.commerceAccount;
    },
    selectedAdminRole(state: AdminState): AdminRole {
      return state.selectedAdminRole;
    },
  },
};
