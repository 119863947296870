export default Object.freeze({
  TITLE: '인증정보{0}',
  PLEASE_INPUT_CORRECT: `인증이 필요한 상품에 대하여 정확한 인증정보를 입력해주세요. 상품정보제공고시에 'KC인증'항목이 있을 경우 상품정보제공고시 항목 내에 설정한 인증정보가 표기됩니다`,
  PLEASE_HONEST: '사실과 다른 인증정보 입력 시, 관리자에 의해 제제 또는 관련법에 의하여 처벌받으실 수 있습니다.',
  ADD_INFO: '인증정보 추가',
  TYPE: '인증유형',
  MAX_50: '인증기관 (최대 50자)',
  MAX_25: '인증번호 (최대 25자)',
  MAX_25_NUM: '인증상호 (최대 25자)',
  DATE: '인증일자 선택',
  INFO1: '방송통신기자재의 인증상호는',
  INFO2: '국립전파연구원',
  INFO3: '인증현황검색',
  INFO4: '에서 확인하실 수 있습니다.',
  TIP1: '어린이제품의 인증상호(제조사 또는 수입업체명)는',
  TIP2: '제품안전정보센터',
  TIP3: '인증정보검색',
  SURE: '확인',
  CANCEL: '취소',
  CERTIFICATION_20: '인증정보는 20개까지 입력 가능합니다.',
  SAME: '동일한 인증 유형이 이미 있습니다.',
  MUST_ITEM: '인증기관, 인증번호, 인증상호 항목은 필수 입력사항입니다.',
  MUST_DATE: '인증일자는 필수항목입니다.',
  NEXT_PROHIBIT: '아래문자는 사용할수 없습니다.\n',
  MUST_DATE_TYPE_1: '방송통신기자재 적합인증',
  MUST_DATE_TYPE_2: '방송통신기자재 적합등록',
  MUST_DATE_TYPE_3: '방송통신기자재 잠정인증',
  MUST_KID_TYPE_1: '어린이제품 안전확인',
  MUST_KID_TYPE_2: '어린이제품 안전인증',
  MUST_KID_TYPE_3: '어린이제품 공급자적합성 확인',
});
