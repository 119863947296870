import { RadioBoxGroupOption } from '@/helpers/type';
import { Icon, StickerType } from '@/types';
import { YorN } from 'ncp-api-supporter';

export const initialIcon: Icon = {
  stickerName: '',
  displayOrder: 0,
  stickerNo: 0,
  useYn: 'Y',
  stickerType: 'IMAGE',
  content: '',
};

export const noticeList: string[] = [
  'PRODUCT.ICON.ADD_NOTICE_1',
  'PRODUCT.ICON.ADD_NOTICE_2',
  'PRODUCT.ICON.ADD_NOTICE_3',
  'PRODUCT.ICON.ADD_NOTICE_4',
];

export const useYnRadioOption: RadioBoxGroupOption<YorN> = {
  name: 'useYn',
  data: [
    {
      id: 'Y',
      value: 'Y',
      display: 'PRODUCT.ICON.USE',
    },
    {
      id: 'N',
      value: 'N',
      display: 'PRODUCT.ICON.NOT_USE',
    },
  ],
};

export const stickerTypeRadioOption: RadioBoxGroupOption<StickerType> = {
  name: 'stickerType',
  data: [
    {
      id: 'IMAGE',
      value: 'IMAGE',
      display: 'PRODUCT.ICON.IMAGE',
    },
    {
      id: 'TEXT',
      value: 'TEXT',
      display: 'PRODUCT.ICON.TEXT',
    },
  ],
};
