import {
  GetClaimsSummaryRequest,
  GetManageInquiresSummary,
  NCPResponse,
  ProductInquiriesSummary,
  GetManageInquiresSummaryRequest,
  GetClaimsSummary,
  ProductsSummaries,
  GetOrderOptionsSaleSummaryMallRequest,
  GetOrderOptionsSaleSummaryMall,
  GetProductInquiriesSummaryRequest,
  GetOrderOptionsMallDelaySummaryRequest,
  GetOrderOptionsDeliveryHoldSummaryRequest,
  GetClaimsSummaryDelayRequest,
  GetInquiriesCounts,
  GetProductInquiriesDelayedCountRequest,
  GetProductsSummariesBySearchTypeRequest,
} from 'ncp-api-supporter'; // GetMallsMdsRequest
import { api } from '@/api';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/root';
import { NotificationInquiriesSummary, SummaryState } from '@/types/summary';
import { menageInquiriesSummaryKey } from '@/const/words/summary';
import { addMonth } from '@/utils/dateFormat';

export default {
  namespaced: true,
  state: {
    /* 쿼리 */
    mallNo: 'ALL',
    /* 알림 현황 */
    productInquirySummary: null,
    productSummaries: null,
    manageInquirySummary: {
      issued: null,
      inProgress: null,
      all: null,
    },
    claimInquirySummary: null,
    /* 메인 현황 */
    saleInquirySummary: null,
    oneToOneInquirySummary: null,
    delayInquirySummary: {
      delayShipment: null,
      delayDeliveryPending: null,
      delayCancellation: null,
      delayExchange: null,
      delayReturn: null,
      delayResponse: null,
    },
    productMainInquirySummary: {
      onSale: null,
      soldOut: null,
      stockUnderTen: null,
    },
  },
  actions: {
    /* 알림 현황 */
    // 상품 문의 현황
    async fetchProductInquiry(
      { commit }: ActionContext<SummaryState, RootState>,
      params: GetProductInquiriesSummaryRequest['params'] = { mallNo: null },
    ): Promise<void> {
      const { data }: NCPResponse<ProductInquiriesSummary> = await api.getProductInquiriesSummary({ params });

      commit('SET_PRODUCT_INQUIRY', data);
    },
    async fetchOneToOneInquiry(
      { commit }: ActionContext<SummaryState, RootState>,
      params: { mallNos: number } | null,
    ): Promise<void> {
      const request = params === null ? null : { params };

      const { data }: NCPResponse<GetInquiriesCounts> = await api.getInquiriesCounts(request);

      commit('SET_ONE_TO_ONE_INQUIRY', data);
    },
    // 품절 상품 현황 등
    async fetchProductSummaries({ commit }: ActionContext<SummaryState, RootState>): Promise<void> {
      const { data }: NCPResponse<ProductsSummaries> = await api.getProductsSummaries();

      commit('SET_PRODUCT_SUMMARIES', data);
    },
    // 1:1 문의 현황
    async fetchManageInquiresSummary(
      { commit }: ActionContext<SummaryState, RootState>,
      params: GetManageInquiresSummaryRequest['params'],
    ): Promise<void> {
      const { data }: NCPResponse<GetManageInquiresSummary> = await api.getManageInquiresSummary({ params });

      commit('SET_MANAGED_INQUIRY_SUMMARY', { data, params });
    },
    // 취소 현황
    async fetchClaimInquiresSummary(
      { commit }: ActionContext<SummaryState, RootState>,
      params: GetClaimsSummaryRequest['params'],
    ): Promise<void> {
      const { data }: NCPResponse<GetClaimsSummary> = await api.getClaimsSummary({ params });

      commit('SET_CLAIM_INQUIRY_SUMMARY', data);
    },
    /* 메인 현황 */
    // 판매 요약
    async fetchSaleInquirySummary(
      { commit }: ActionContext<SummaryState, RootState>,
      params: GetOrderOptionsSaleSummaryMallRequest['params'],
    ): Promise<void> {
      const { data }: NCPResponse<GetOrderOptionsSaleSummaryMall> = await api.getOrderOptionsSaleSummaryMall({
        params,
      });

      commit('SET_SALE_INQUIRY_SUMMARY', data);
    },
    // 지연 현황 조회
    async fetchDelaySummary({ commit }: ActionContext<SummaryState, RootState>, mallNos: string): Promise<void> {
      const getOrderOptionsPayDoneSummaryRequest: GetOrderOptionsMallDelaySummaryRequest = {
        // 접수지연
        params: {
          mallNos,
          orderStatusTypes: 'PAY_DONE',
        },
      };
      const getOrderOptionsMallDelaySummaryRequest: GetOrderOptionsMallDelaySummaryRequest = {
        // 발송지연
        params: {
          mallNos,
          orderStatusTypes: 'PRODUCT_PREPARE',
        },
      };
      const getOrderOptionsDeliveryHoldSummaryRequest: GetOrderOptionsDeliveryHoldSummaryRequest = {
        // 배송 보류 지연
        params: {
          mallNos,
        },
      };
      const getClaimsSummaryDelayRequest: GetClaimsSummaryDelayRequest = {
        // 취소지연, 반품지연, 교환지연
        params: {
          mallNos,
          period: '3', // TODO : 1차 오픈 이후에 기간 설정해줘야함
          unit: 'MONTH',
        },
      };
      const getProductInquiriesDelayedCountRequest: GetProductInquiriesDelayedCountRequest = {
        // 답변 지연
        params: {
          mallNo: mallNos.toString().split(',').length > 1 ? null : mallNos,
        },
      };

      const values = await Promise.all([
        api.getOrderOptionsMallDelaySummary(getOrderOptionsPayDoneSummaryRequest),
        api.getOrderOptionsMallDelaySummary(getOrderOptionsMallDelaySummaryRequest), // 발송 지연
        api.getOrderOptionsDeliveryHoldSummary(getOrderOptionsDeliveryHoldSummaryRequest), // 배송 보류 지연
        api.getClaimsSummaryDelay(getClaimsSummaryDelayRequest), // 취소지연, 반품지연, 교환지연
        api.getProductInquiriesDelayedCount(getProductInquiriesDelayedCountRequest), // 답변 지연
      ]);

      const result = {
        delayPayDone: values[0].data.delayCount,
        delayShipment: values[1].data.delayCount,
        delayDeliveryPending: values[2].data.delayedCount,
        delayCancellation: values[3].data.cancelDelayCount,
        delayExchange: values[3].data.exchangeDelayCount,
        delayReturn: values[3].data.returnDelayCount,
        delayResponse: Number(values[4].data),
      };

      commit('SET_DELAY_SUMMARY', result);
    },
    // 상품 현황 조회
    async fetchProductSummary({ commit }: ActionContext<SummaryState, RootState>, mallNo: null | number) {
      const startYmd = addMonth(new Date(), -3);

      const params: GetProductsSummariesBySearchTypeRequest['params'] = {
        startYmd,
        mallNo,
      } as GetProductsSummariesBySearchTypeRequest['params']; // TODO : supporter 및 PREMIUM 정의 이상함.

      const values = await Promise.all([
        api.getProductsSummariesBySearchType({ pathParams: { searchType: 'ON_SALE' }, params }), // 판매중
        api.getProductsSummariesBySearchType({ pathParams: { searchType: 'SOLD_OUT' }, params }), // 품절상품
        api.getProductsSummariesBySearchType({ pathParams: { searchType: 'STOCK_UNDER_TEN' }, params }), // 재고 10개 이하
      ]);

      const result = {
        onSale: values[0].data.count,
        soldOut: values[1].data.count,
        stockUnderTen: values[2].data.count,
      };

      commit('SET_PRODUCT_MAIN_SUMMARY', result);
    },
  },
  mutations: {
    SET_MALL_NO(state: SummaryState, mallNo: SummaryState['mallNo']): void {
      state.mallNo = mallNo === 'ALL' ? mallNo : Number(mallNo);
    },
    SET_PRODUCT_INQUIRY(state: SummaryState, productInquiry: SummaryState['productInquirySummary']): void {
      state.productInquirySummary = productInquiry;
    },
    SET_PRODUCT_SUMMARIES(state: SummaryState, productSummaries: SummaryState['productSummaries']): void {
      state.productSummaries = productSummaries;
    },
    SET_MANAGED_INQUIRY_SUMMARY(
      state: SummaryState,
      { data, params }: { data: GetManageInquiresSummary; params: GetManageInquiresSummaryRequest['params'] },
    ): void {
      if (params?.statuses === undefined) {
        state.manageInquirySummary.all = data.inquiryCount;
        return;
      }

      const key = menageInquiriesSummaryKey[params.statuses];
      state.manageInquirySummary[key] = data.inquiryCount;
    },
    SET_CLAIM_INQUIRY_SUMMARY(state: SummaryState, claimInquiry: SummaryState['claimInquirySummary']): void {
      state.claimInquirySummary = claimInquiry;
    },
    SET_SALE_INQUIRY_SUMMARY(state: SummaryState, saleInquiry: SummaryState['saleInquirySummary']): void {
      state.saleInquirySummary = saleInquiry;
    },
    SET_DELAY_SUMMARY(state: SummaryState, delayInquiry: SummaryState['delayInquirySummary']): void {
      state.delayInquirySummary = delayInquiry;
    },
    SET_PRODUCT_MAIN_SUMMARY(state: SummaryState, productInquiry: SummaryState['productMainInquirySummary']): void {
      state.productMainInquirySummary = productInquiry;
    },
    SET_ONE_TO_ONE_INQUIRY(state: SummaryState, oneToOneInquiry: SummaryState['oneToOneInquirySummary']): void {
      state.oneToOneInquirySummary = oneToOneInquiry;
    },
  },
  getters: {
    // 쿼리
    getMallNoQuery(state: SummaryState): 'ALL' | number {
      return state.mallNo;
    },
    /* 알림 현황 */
    getProductInquirySummary(state: SummaryState): ProductInquiriesSummary {
      if (state.productInquirySummary) return { ...state.productInquirySummary };
    },
    getProductSummaries(state: SummaryState): ProductsSummaries {
      if (state.productSummaries) return { ...state.productSummaries };
    },
    getManageInquirySummary(state: SummaryState): SummaryState['manageInquirySummary'] {
      if (state.manageInquirySummary) return { ...state.manageInquirySummary };
    },
    getClaimInquirySummary(state: SummaryState): GetClaimsSummary {
      if (state.claimInquirySummary) return { ...state.claimInquirySummary };
    },
    getAllSummary(state: SummaryState): SummaryState {
      return { ...state };
    },
    getNotificationSummary(state: SummaryState): NotificationInquiriesSummary {
      return {
        productDelayedReplyCount: state.productInquirySummary?.delayedReplyCountInLastYear ?? 0,
        oneOnOneIssueCount: state.manageInquirySummary?.issued ?? 0,
        cancellationRequestCount: state.claimInquirySummary?.cancelRequestCount ?? 0,
        returnRequestCount: state.claimInquirySummary?.returnRequestCount ?? 0,
        exchangeRequestCount: state.claimInquirySummary?.exchangeRequestCount ?? 0,
        soldOutCount: state.productSummaries?.soldOutCnt ?? 0,
      };
    },
    /* 메인 현황 */
    getSaleSummary(state: SummaryState): GetOrderOptionsSaleSummaryMall {
      if (state.saleInquirySummary) return { ...state.saleInquirySummary };
    },
    getOneToOneSummary(state: SummaryState): GetInquiriesCounts {
      if (state.oneToOneInquirySummary) return { ...state.oneToOneInquirySummary };
    },
    getDelaySummary(state: SummaryState): SummaryState['delayInquirySummary'] {
      if (Object.entries(state.delayInquirySummary).every(([_, v]) => v !== null))
        return { ...state.delayInquirySummary };
    },
    getProductSummary(state: SummaryState): SummaryState['productMainInquirySummary'] {
      if (Object.entries(state.productMainInquirySummary).every(([_, v]) => v !== null))
        return { ...state.productMainInquirySummary };
    },
  },
};
