


























import { Vue, Component, PropSync, Prop, Ref } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { InputNumber } from '@/types';
import { Mall } from 'ncp-api-supporter';
@Component({
  components: { SelectBox },
})
export default class MallSelector extends Vue {
  @PropSync('mallNo')
  private mallNoSync!: InputNumber;

  @Prop()
  private readonly sortedMallsByCreationDate!: Mall[];

  @Prop()
  private readonly hasOneMall!: boolean;

  @Prop({ required: true })
  private readonly disabled: boolean;

  @Prop({ required: true })
  private readonly formError;

  @Ref() private readonly mallSelect!: SelectBox;
  focus(): void {
    this.mallSelect.focus();
  }

  private changedMallNo(curr: number, prev: number): void {
    if (!curr) {
      this.$nextTick(() => (this.mallNoSync = prev));
      return;
    }
    if (curr === prev) return;
    this.$emit('changedMall', curr);
  }
}
