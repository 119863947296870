



















































import { Component, Ref } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';

import NoticeBox from '@/components/common/NoticeBox.vue';
import Editor from '@/components/common/summernote/Editor.vue';
import TextInput from '@/components/common/input/TextInput.vue';

import sanitizeHtml from '@/utils/sanitizeHtml';
// requests
import {
  getContractsEntryContractsRequest,
  postContractsEntryContractsRequest,
} from '@/views/contents/partner/modification/apis/apiRequests';

@Component({
  components: { Editor, NoticeBox, TextInput },
})
export default class EntryContractFilePopupModification extends WindowPopupMainVue {
  @Ref()
  private readonly editorRef?: Editor;

  private modificationReason = '';

  private async getEntryContracts() {
    const request = getContractsEntryContractsRequest(this.data.contractNo.toString());

    const { data: entryContracts } = await this.$api.getContractsEntryContracts(request);
    const latestEntryContract = entryContracts[entryContracts.length - 1];

    return { entryContracts, latestEntryContract };
  }

  private async emitRegisteredEntryContracts() {
    const { entryContracts, latestEntryContract } = await this.getEntryContracts();

    const latestEntryContractAgreed = latestEntryContract.agreed;
    const entryContractSize = entryContracts.length;

    this.onPositiveClick({ latestEntryContractAgreed, entryContractSize });

    return;
  }

  private async modifyEntryContract() {
    const doModification = confirm(this.$t('CONTRACT.POPUP.MODIFICATION_CONFIRM_MSG').toString());
    const hasModificationReason = this.modificationReason.length;

    if (!doModification) return;

    if (doModification && hasModificationReason) {
      const newContents = sanitizeHtml(this.editorRef.getHtml());

      const request = postContractsEntryContractsRequest(this.data.contractNo, newContents, this.modificationReason);

      await this.$api.postContractsEntryContracts(request);
      await this.emitRegisteredEntryContracts();

      return;
    }

    alert(this.$t('CONTRACT.POPUP.MODIFICATION_NO_CHANGE_REASON_MSG'));
  }

  private async init() {
    const { latestEntryContract } = await this.getEntryContracts();
    this.editorRef.setHtml(sanitizeHtml(latestEntryContract.contents));
  }

  mounted() {
    this.init();
  }
}
