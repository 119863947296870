export default Object.freeze({
  TITLE: '상품항목추가 관리',
  POP_TITLE: '상품항목추가 적용상품 보기',
  GUIDE1: '‘항목’은 최대 5개, ‘항목값’은 최대 10개까지 추가할 수 있습니다.',
  GUIDE2: '항목값을 등록한 상품은 ‘적용상품’ 에 보여집니다.',
  GUIDE3: '등록 상품이 있는 항목의 ‘항목명’과 ‘항목값’은 수정/삭제할 수 없습니다.',
  GUIDE4: '(항목값은 최초 저장 시, 수정이 불가 합니다.)',
  GUIDE5: '등록 상품이 있는 항목의 ‘복수 선택 여부’는 수정할 수 없습니다.',
  ADD: '항목 추가',
  SAVE: '변경사항 저장',
  MULTIPLE: '복수선택여부',
  MULTIPLE_GUIDE1: '복수선택여부가 ‘Y’인 항목은 상품등록/수정 시, 항목값을 1개 이상 선택할 수 있습니다.',
  MULTIPLE_GUIDE2: '복수선택여부가 ‘N’인 항목은 상품등록/수정 시, 항목값을 1개만 선택할 수 있습니다.',
  USEYN: '사용여부',
  USEYN_GUIDE1: '사용여부가 ‘Y’인 항목은 ‘상품등록/수정’ 항목에 추가됩니다. (선택값으로 제공)',
  USEYN_GUIDE2: '사용여부가 ‘Y’인 항목은 ‘상품정보 조회/수정’ 메뉴의 검색조건 및 조회그리드에 추가됩니다.',
  USEYN_GUIDE3: '단, 사용여부가 ‘N’인 항목은 ‘상품등록/수정’, ‘상품정보 조회/수정’ 메뉴에 출력되지 않습니다.',
  HEADER_PROPERTY_NAME: '항목명',
  HEADER_ORDER: '순서',
  HEADER_PROPERTY_VALUE: '항목값',
  HEADER_PRODUCT: '적용상품',
  HEADER_DELETE: '삭제',
  ALERT_SAVED: '저장되였습니다',
  ALERT_PROPNAME: '항목명을 입력해주세요.',
  ALERT_PROPVALUE: '항목값을 입력해주세요.',
  DELETE: '삭제',
  PLACEHOLDER_VALUE: '15자까지 입력가능',
  PLACEHOLDER_VALUE10: '10자까지 입력가능',
  MALL_NAME: '쇼핑몰',
  MALL_PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  ADMIT_STATUS: '승인상태',
  SALE_STATUS: '판매상태',
  SALE_PERIOD: '판매기간',
  REGISTRATION_READY: '등록대기',
  APPROVAL_READY: '승인대기',
  APPROVAL_REJECTION: '승인거부',
  SALE_AGREEMENT_READY: '판매합의대기',
  SALE_AGREEMENT_REJECTION: '판매합의거부',
  AFTER_APPROVAL_READY: '수정 후 승인대기',
  AFTER_APPROVAL_REJECTION: '수정 후 승인거부',
  APPROVAL_COMPLETE: '승인완료',
  WAITING_SALE: '판매대기',
  ON_SALE: '판매중',
  END_SALE: '판매종료',
  CLOSE: '닫기',
  TABLE_TIT: '등록 상품 (총 ',
  TABLE_TIT_TOTAL: '개) ',
});
