



















































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  contentsEditorOption,
  contentsRadioOption,
  getContents,
  getContentStatus,
  getGuidesTemplates,
  getStatus,
  templateRadioOption,
} from '@/views/contents/product/basic/ProductAdd/imagesInfo/ImagesInfo';
import {
  InputNumber,
  ImagesInformation,
  ImagesInfoRadioOption,
  ContentOption,
  ImageInformationModel,
  EditFlag,
  PartnerType,
} from '@/types';
import ListImage from '@/components/product/common/imageUpload/ListImage.vue';
import MainImage from '@/components/product/common/imageUpload/MainImage.vue';
import Editor from '@/components/common/summernote/Editor.vue';
import {
  CONTENT_TYPE,
  CONTENT_USE_TYPE,
  DELETE_ERROR,
  GUIDE_TYPE,
  IMMUTABLE_FIELD,
  PRODUCT_FORM_ERROR_TYPE,
} from '@/const/contents/product';
import { getNoImageIdx, i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { PopupClose, throwWindowPopup } from '@/helpers/popup';
import { Getter } from 'vuex-class';

@Component({
  components: { Editor, RadioGroup, ListImage, MainImage },
})
export default class ImagesInfo extends Vue {
  @Getter('partner/getPartnerType')
  private readonly selectedPartnerType: PartnerType;

  @PropSync('imagesInfo')
  private imagesInfoSync!: ImagesInformation;

  @Prop({ required: true })
  private imagesInfoModel: ImageInformationModel;

  @Prop({ required: true })
  private mallNo!: InputNumber;

  @Prop({ required: true })
  private readonly partnerNo: number;

  @Prop({ required: true })
  private readonly isEditMode: boolean;

  @Prop({ required: true })
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly editFlag: EditFlag;

  private readonly IMMUTABLE_FIELD = IMMUTABLE_FIELD;

  private readonly CONTENT_USE_TYPE = CONTENT_USE_TYPE;

  private i18nForProduct = i18nForProduct;

  private get isMallSelected(): boolean {
    return this.mallNo > 0;
  }

  public setFinalImagesInfo() {
    this.setContents();
    this.setGuides();
  }

  public resetImagesInfo() {
    this.guides = getGuidesTemplates();
    this.changedPartnerNo();
  }

  public onFocus(name) {
    this.$refs[`editor${name}`][0].focus();
  }

  private alertMallSelect(): void {
    this.$emit('alertMallSelect');
  }
  // LimitOption
  private limitOption = { message: '추가이미지는 최대 9개까지 등록 가능합니다', size: 9 };

  //
  private contents: ContentOption<keyof typeof CONTENT_TYPE> = getContents();
  private contentsRadioOptions: ImagesInfoRadioOption['content'] = contentsRadioOption();
  private contentsEditorOption = contentsEditorOption();

  private setOptionImageYn() {
    const optionState = this.contents[CONTENT_TYPE.BODY].status === CONTENT_USE_TYPE.OPTION_IMAGE ? 'Y' : 'N';
    this.$set(this.imagesInfoSync, 'addOptionImageYn', optionState);
  }

  private setContentsHTML(name: keyof typeof CONTENT_TYPE) {
    const contentName = {
      [CONTENT_TYPE.HEADER]: 'contentHeader',
      [CONTENT_TYPE.BODY]: 'content',
      [CONTENT_TYPE.FOOTER]: 'contentFooter',
    };

    if (this.contents[name].status === CONTENT_USE_TYPE.USE) {
      this.imagesInfoSync.useStatus[name] = true;
    }

    if (this.contents[name].status === CONTENT_USE_TYPE.NOT_USE) {
      this.imagesInfoSync[contentName[name]] = null;
      return;
    }
    this.imagesInfoSync[contentName[name]] = this.$refs[`editor${name}`][0].getHtml();
  }

  private setContents() {
    Object.values(CONTENT_TYPE).forEach(type => {
      type === CONTENT_TYPE.BODY && this.setOptionImageYn();
      this.setContentsHTML(type);
    });
  }

  // guides templates
  private guides: ContentOption<keyof typeof GUIDE_TYPE> = getGuidesTemplates();
  private guidesRadioOptions: ImagesInfoRadioOption['guide'] = templateRadioOption();

  private openDefaultGuideTemplateFormPopup(type: keyof typeof GUIDE_TYPE) {
    throwWindowPopup(
      'DefaultGuideTemplateForm',
      { type, partnerNo: this.partnerNo, partnerType: this.selectedPartnerType },
      'md',
      ({ state, data }) => {
        if (state === PopupClose.CONFIRM) {
          this.guides[type].defaultContent = data.content;
          this.fetchGuide(type);
        }
      },
      null,
      null,
      null,
      600,
    );
  }

  private fetchGuide(type: keyof typeof GUIDE_TYPE) {
    if (this.partnerNo === 0) return; //as resetPartnerState
    this.$api
      .getProductsGuides({
        params: {
          partnerNo: this.partnerNo,
          type,
        },
      })
      .then(({ data, status }) => {
        if (status === 200) {
          this.$set(this.guides[type], 'defaultContent', data.content);
          this.$set(this.guides[type], 'defaultTemplateNo', data.templateNo);
        }
      });
  }

  private fetchDefaultGuides(guideType: (keyof typeof GUIDE_TYPE)[]) {
    guideType.forEach(type => {
      this.fetchGuide(type);
    });
  }

  @Watch('partnerNo')
  private changedPartnerNo() {
    this.fetchDefaultGuides(Object.values(GUIDE_TYPE));
  }

  // set final guides templates
  private getGuide(key, status) {
    const guide = {
      type: key,
      templateNo: null,
      content: null,
    };

    if (status === CONTENT_USE_TYPE.NOT_USE) {
      return null;
    }

    if (status === CONTENT_USE_TYPE.USE_DEFAULT_TEMPLATE) {
      guide.templateNo = this.guides[key].defaultTemplateNo;
      return guide;
    }

    const refName = `editor${key}`;
    guide.content = this.$refs[refName][0].getHtml();
    return guide;
  }
  private setGuides() {
    const useStatus = {};
    this.imagesInfoSync.productGuides = Object.values(GUIDE_TYPE).map(key => {
      useStatus[key] = this.guides[key].status;
      const { status } = this.guides[key];
      return this.getGuide(key, status);
    });
    this.$set(this.imagesInfoSync, 'guideUseStatus', useStatus);
  }

  private initContentInfo() {
    if (this.imagesInfoModel.contents === null) return;
    const { addOptionImageYn } = this.imagesInfoModel.contents;
    Object.values(CONTENT_TYPE).forEach(type => {
      const content = this.imagesInfoModel.contents[type];
      if (type === CONTENT_TYPE.BODY) {
        this.contents[type].status =
          addOptionImageYn === 'Y' ? CONTENT_USE_TYPE.OPTION_IMAGE : getContentStatus(content);
      } else {
        this.contents[type].status = getContentStatus(content);
      }
      this.contents[type].content = content ?? '';
    });
  }

  @Watch('imagesInfoModel.contents')
  private setInitContents() {
    this.initContentInfo();
  }

  @Watch('imagesInfoModel.productGuides')
  private setInitGuides() {
    // if (this.imagesInfoModel.productGuides.length === 0) return;
    const useTypes = [];
    this.imagesInfoModel.productGuides.forEach(guide => {
      if (guide?.type) {
        useTypes.push(guide.type);
        this.guides[guide.type].templateNo = guide.templateNo;
        this.guides[guide.type].content = guide.content;
        this.guides[guide.type].status =
          guide.templateNo > 0
            ? CONTENT_USE_TYPE.USE_DEFAULT_TEMPLATE
            : guide.content !== null
            ? CONTENT_USE_TYPE.DIRECT
            : CONTENT_USE_TYPE.NOT_USE;
      }
    });

    const notUseTypes = Object.values(GUIDE_TYPE).filter(type =>
      useTypes.length === 0 ? type : !useTypes.includes(type),
    );
    notUseTypes.forEach(type => {
      this.guides[type].status = CONTENT_USE_TYPE.NOT_USE;
    });
  }

  @Watch('imagesInfoSync.mallProductImages')
  private initMallProductImages() {
    if (this.imagesInfoSync.mallProductImages.length === 0) return;
    // 메인이미지 미등록 시 자동으로 no-image-available 이 저장됨
    const noImageIdx = this.imagesInfoSync.mallProductImages.findIndex(
      image =>
        image.mainYn === 'Y' && (image.imageUrl.includes('No-image-available') || image.imageUrl.includes('no_img')),
    );
    this.$delete(this.imagesInfoSync.mallProductImages, noImageIdx);
  }

  @Watch('imagesInfo.mallProductImages')
  private removeNoImage() {
    const noImageIdx = getNoImageIdx(this.imagesInfoSync.mallProductImages);
    noImageIdx >= 0 && this.$delete(this.imagesInfoSync.mallProductImages, noImageIdx);
  }

  private created() {
    if (this.isEditMode) {
      this.setEditInfo();
    }
  }

  private initGuidesInfo() {
    this.imagesInfoSync.productGuides.forEach(guid => {
      const { type, templateNo, content } = guid;

      this.$set(this.guides, type, {
        ...guid,
        defaultContent: '',
        status: getStatus(templateNo, content),
      });
    });
  }

  private setEditInfo() {
    this.initContentInfo();
    this.initGuidesInfo();
  }

  // error
  @Prop({ required: true })
  private readonly formError: { [errorKey: string]: string };
  @Prop({ required: true })
  private readonly formErrorType: typeof PRODUCT_FORM_ERROR_TYPE;
  @Prop({ required: true })
  private readonly deleteError: typeof DELETE_ERROR;
}
