export default Object.freeze({
  DEPOSIT_WAIT: '입금대기',
  PAY_DONE: '결제완료',
  PRODUCT_PREPARE: '상품준비중',
  DELIVERY_PREPARE: '배송준비중',
  DELIVERY_ING: '배송중',
  DELIVERY_DONE: '배송완료',
  BUY_CONFIRM: '구매확정',
  REFUND_DONE: '환불완료',
});
