export default Object.freeze({
  PAGE_TIT: '디자인 스킨 리스트',

  SKIN_PLATFORM: '플랫폼 구분',
  SKIN_PLATFORM_PC: 'PC웹',
  SKIN_PLATFORM_MOBILE_WEB: '모바일웹',
  SKIN_CODE: '스킨코드',
  SKIN_NAME: '스킨명',
  SKIN_GIT_URL: 'GitLab URL',
  SKIN_UPLOAD: '업로드',
  SKIN_THUMBNAIL: '썸네일 이미지',
  SKIN_SHOPBY_PRO: '적용가능 솔루션',
  SKIN_LANGUAGE: '스킨 언어',
  SKIN_RELEASED_DATE: '설치일자',
  SKIN_VERSION: '스킨버전',
  SKIN_LICENSE: '라이센스',
  SKIN_AUTHOR: '제작자',
  SKIN_AUTHOR_COMPANY: '제작사',

  CURRENT_TABLE_TIT: '현재 설정된 디자인 스킨',
  CURRENT_LIVE_SKIN: '사용중인 디자인 스킨',
  CURRENT_WORK_SKIN: '작업중인 디자인 스킨',
  CURRENT_SKIN_BTN_PREVIEW: '미리보기',
  CURRENT_SKIN_BTN_REDEPLOY: '재배포',
  CURRENT_LIVE_SKIN_MSG1: '쇼핑몰 방문자에게 보여지는 스킨입니다.',
  CURRENT_LIVE_SKIN_MSG2: '선택된 스킨이 실제 쇼핑몰에서 보여집니다.',
  CURRENT_WORK_SKIN_MSG1: '미리보기를 위한 스킨입니다.',
  CURRENT_WORK_SKIN_MSG2: '선택된 스킨을 실제 쇼핑몰에 적용하지 않고',
  CURRENT_WORK_SKIN_MSG3: '보여집니다.',

  DEPLOY_SKIN: '스킨 배포',
  DEPLOY_SKIN_MSG_CONFIRM: '스킨의 최신 버전으로 적용하시겠습니까?',
  DEPLOY_SKIN_MSG_DEPLOYING: '{skinName} 스킨을 적용하는 중입니다.',
  DEPLOY_SKIN_MSG_SUCCESS: '적용되었습니다.',
  DEPLOY_SKIN_MSG_FAIL: '처리 중에 오류가 발생하여 실패되었습니다.\n다시 시도해 주세요.',

  RETAINED_TABLE_TIT: '보유 스킨 리스트',
  RETAINED_TABLE_COL_NAME: '디자인 스킨명',
  RETAINED_TABLE_COL_LIVE: '사용스킨',
  RETAINED_TABLE_COL_WORK: '작업스킨',
  RETAINED_TABLE_COL_BTN: '관리',
  RETAINED_SKIN_BTN_INFO: '스킨정보',
  RETAINED_SKIN_MSG_NOT_CHANGE: '수정/삭제가 불가한 기본 디자인 스킨입니다.',
  RETAINED_SKIN_RADIO_LABEL: '사용함',
  RETAINED_MENU_TAB_PC: 'PC스킨 ({retainedPcSkinsCount})',
  RETAINED_MENU_TAB_MOBILE: 'Mobile스킨 ({retainedMobileSkinsCount})',

  BOTTOM_NAV_BTN_NEW_SKIN_UPLOAD: '+ 신규스킨업로드',
  BOTTOM_NAV_BTN_NEW_SKIN_ADD: '+ 신규스킨추가',

  RETAINED_SKIN_DOWNLOAD_POPUP_TIT: '스킨 다운로드',
  RETAINED_SKIN_DOWNLOAD_MSG_CONFIRM: '{skinName} 스킨을 ZIP 파일로 압축 후 다운로드 하시겠습니까?',
  RETAINED_SKIN_DOWNLOAD_MSG_ZIP_GENERATING: '{skinName} 스킨을 ZIP 파일로 압축 중입니다.',
  RETAINED_SKIN_DOWNLOAD_MSG_SUCCESS: '상단 또는 하단의 상태바에서 스킨 파일을 저장 또는 \n 저장된 파일을 확인하세요',

  RETAINED_SKIN_DELETE_POPUP_TIT: '스킨 삭제',
  RETAINED_SKIN_DELETE_MSG_CONFIRM1: '{skinName} 스킨을 정말로 삭제 하시겠습니까?',
  RETAINED_SKIN_DELETE_MSG_CONFIRM2: '삭제시 복구가 불가능합니다.',
  RETAINED_SKIN_DELETE_MSG_CONFIRM3: '· 쇼핑몰에 사용중인 스킨을 삭제하면 기본 스킨으로 대체되어 적용됩니다.',
  RETAINED_SKIN_DELETE_MSG_DELETING1: '{skinName} 스킨을 삭제하는 중입니다.',
  RETAINED_SKIN_DELETE_MSG_DELETING2: '만약 {skinName} 스킨이 남아 있다면 다시 한번 삭제 진행을 해주세요.',
  RETAINED_SKIN_DELETE_MSG_SUCCESS: '삭제되었습니다.',

  RETAINED_SKIN_STATUS_TO_LIVE_CONFIRM: '{skinName} 스킨을 사용스킨으로 변경하시겠습니까?',
  RETAINED_SKIN_STATUS_TO_LIVE_SUCCESS:
    '사용스킨이 변경되었습니다. 변경된 스킨은 쇼핑몰 반영 시까지 일정 시간이 소요될 수 있습니다.',
  RETAINED_SKIN_STATUS_TO_LIVE_FAILURE: '사용스킨으로 변경이 실패되었습니다. 다시 시도해주세요.',
  RETAINED_SKIN_STATUS_TO_WORK_CONFIRM: '{skinName} 스킨을 작업스킨으로 변경하시겠습니까?',
  RETAINED_SKIN_STATUS_TO_WORK_SUCCESS: '작업스킨이 변경되었습니다.',
  RETAINED_SKIN_STATUS_TO_WORK_FAILURE: '작업스킨으로 변경이 실패되었습니다. 다시 시도해주세요.',

  SKIN_NEW_POPUP_TIT: '신규 스킨 업로드',
  SKIN_NEW_POPUP_NO_CODE: '스킨 저장시 자동 생성됩니다.',
  SKIN_NEW_POPUP_NAME_PLACEHOLD: '스킨명을 입력하세요.',
  SKIN_NEW_POPUP_BTN_UPLOAD: '스킨 업로드 하기',
  SKIN_NEW_POPUP_UPLOAD_MSG1: '· 스킨은 zip 형태의 파일만 등록 가능합니다.',
  SKIN_NEW_POPUP_UPLOAD_MSG2: '· 파일의 용량은 15MB 미만이여야 정상적으로 업로드가 가능합니다.',
  SKIN_NEW_POPUP_THUMBNAIL_MSG1: '· jpg, jpeg, png, gif만 등록 가능하며, 기본 이미지는 200x200 pixel 입니다.',
  SKIN_NEW_POPUP_THUMBNAIL_MSG2: '· 이미지명은 되도록이면 영문으로 올려주세요.',
  SKIN_NEW_POPUP_THUMBNAIL_MSG3: '· 이미지 파일의 용량은 10MB 미만이여야 정상적으로 업로드가 가능합니다.',
  SKIN_NEW_POPUP_ZIP_FILE_EXT: 'zip 파일만 업로드 할 수 있습니다.',
  SKIN_NEW_POPUP_UPLOAD_FAILED:
    '처리중에 오류가 발생하여 실패되었습니다.\n' + '· 실패사유: 스킨 파일이 유효하지 않습니다.',
  SKIN_NEW_POPUP_ZIP_FILE_LIMIT_CAPACITY: 'zip 파일은 최대 10MB까지 업로드 할 수 있습니다.',
  SKIN_NEW_POPUP_THUMBNAIL_FILE_EXT: '이미지 파일만 업로드 가능합니다. \n (확장자 : JPG, JPEG, PNG, GIF)',
  SKIN_NEW_POPUP_THUMBNAIL_FILE_LIMIT_CAPACITY: '이미지 파일은 1개당 최대 10MB까지 업로드 가능합니다.',
  SKIN_NEW_POPUP_MSG_UPLOAD_SUCCESS: '스킨이 업로드 되었습니다. 보유 스킨 리스트에서 확인하십시요.',
  SKIN_NEW_POPUP_MSG_CHECK_NAME: '스킨명을 입력해주세요.',
  SKIN_NEW_POPUP_MSG_CHECK_ZIP: 'zip 파일을 업로드하십시오.',
  SKIN_NEW_POPUP_MSG_CHECK_FILE_NAME: '업로드 파일명은 영문과 숫자만 입력해주세요.',
  SKIN_NEW_POPUP_MSG_FILE_NAME_CHK: '업로드 파일명은 영문과 숫자만 입력해주세요.',
  SKIN_NEW_POPUP_UPLOADING_TIT: '스킨 업로드',
  SKIN_NEW_POPUP_UPLOADING_MSG: '{skinName} 스킨을 업로드 중입니다',

  SKIN_MODIFY_POPUP_TIT: '스킨 수정',
  SKIN_MODIFY_POPUP_MODIFYING_MSG: '스킨을 수정 중입니다',
  SKIN_MODIFY_POPUP_MSG_MODIFY_SUCCESS: '스킨정보가 수정되었습니다.',

  SKIN_INFO_POPUP_TIT: '스킨 정보',

  WORKSPACE_CURRENT_SKIN_INFO_TIT: '현재 설정된 디자인 스킨 정보를 확인할 수 없습니다.',
  WORKSPACE_CURRENT_SKIN_INFO_NOTICE1:
    '에이전시를 통해 스킨이 적용된 쇼핑몰은 어드민 내에서 스킨 조회 및 변경이 불가합니다.',
  WORKSPACE_CURRENT_SKIN_INFO_NOTICE2: '스킨 변경 희망 시 에이전시(스킨판매자)에 문의하세요.',
  WORKSPACE_RETAINED_SKIN_INFO_TIT: '보유 스킨리스트를 조회할 수 없습니다.',
  WORKSPACE_RETAINED_SKIN_INFO_NOTICE1:
    '에이전시를 통해 스킨이 적용된 쇼핑몰은 어드민 내에서 스킨 조회 및 변경이 불가합니다.',
  WORKSPACE_RETAINED_SKIN_INFO_NOTICE2: '스킨 변경 희망 시 에이전시(스킨판매자)에 문의하세요.',

  WORKSPACE_NOT_ALLOWED_UPLOADING: '에이전시를 통해 스킨이 적용된 쇼핑몰은 신규스킨 업로드가 \n' + '불가합니다.',
  WORKSPACE_NOT_ALLOWED_ADDING: '에이전시를 통해 스킨이 적용된 쇼핑몰은 신규스킨 추가가 \n' + '불가합니다.',
});
