export default Object.freeze({
  // 파트너 관리 > 매출 내역 조회
  NOTICE_1: '조회일자 기준으로 전일 데이터까지 집계되며, 옵션 매출 금액은 실제 지급 받을 금액과 다를 수 있습니다.',
  NOTICE_2: `실제 지급 금액은 <a style="text-decoration: underline" href="${window.location.origin}/pro/configuration/partner/settlements" target="_blank">설정 > 파트너관리 > 정산 현황 조회</a> 에서 확인 가능합니다.`,
  NOTICE_3: '공제금액 = 각 금액조정 파트너부담+쇼핑몰 배송비-환불보류 금액조정',
  NOTICE_4: '판매수수료는 주문 시점 상품 수수료기준으로 산출됩니다.',
  NOTICE_5:
    '판매수수료 계산 방식 : 공급가 직접 입력인 경우 (판매액-공급가), 상품수수료율 방식인 경우 (판매액*수수료율), 사입상품인 경우 (판매액-매입가)',
  TYPE: '파트너구분',
  PARTNER_TYPE_DOMESTIC: '국내 파트너',
  PARTNER_TYPE_OVERSEAS: '해외 파트너',
  // grid
  TOTAL_SALES: '매출 합계',
  DAILY_SALES: '일별 매출 상세',
  HEADER_SEARCH_YMD: '조회일자',
  HEADER_ORDER_CNT: '판매수량',
  HEADER_REFUND_CNT: '환불수량',
  HEADER_SALES_AMT: '판매액(A)',
  HEADER_COMMISSION_AMT: '판매수수료(B)',
  HEADER_DELIVERY_AMT: '배송비(C)',
  HEADER_PARTNER_DISCOUNT_AMT: '추가/상품할인파트너부담<br/>(D)',
  HEADER_DEDUCTED_AMT: '공제금액(E)',
  HEADER_SETTLEMENT_AMT: '정산예상금액<br/>(F=A-B+C-D-E)',

  EXCEL_NAME_TOTAL_SALES: '매출합계',
  EXCEL_NAME_DAILY_SALES: '일별매출',
});
