










import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import $ from 'jquery';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN } from '@/const/cookie';
import cookies from 'js-cookie';

@Component({})
export default class GuideModalIframe extends Vue {
  @Prop({ required: true, default: '' })
  private src: string;
  @Prop()
  private handleClose;

  @Ref()
  public readonly iFrame: HTMLIFrameElement;

  @Watch('src')
  private updateSrc() {
    this.sendMessageIFrame();
    $('#iframe-guide-loading').show();

    this.iFrame.setAttribute('url', this.src);
  }

  private receiveMessage(e: MessageEvent) {
    if (e.data === 'loaded') {
      this.sendMessageIFrame();
      $('#iframe-guide-loading').hide();

      return;
    }

    if (e.data.close) {
      this.handleClose();
    }
  }

  private sendMessageIFrame() {
    this.iFrame?.contentWindow.postMessage(
      {
        SHOPBY_ACCESS_TOKEN: cookies.get(SHOPBY_ACCESSTOKEN),
        SHOPBY_REFRESH_TOKEN: cookies.get(SHOPBY_REFRESHTOKEN),
      },
      process.env.VUE_APP_ADMIN_REMOTE_URL,
    );
  }

  mounted() {
    this.iFrame.onload = () => {
      $('#iframe-guide-loading').hide();
    };

    window.addEventListener('message', this.receiveMessage);
  }

  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }
}
