import { RadioBoxGroupOption } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';

const syncStockOptions: RadioBoxGroupOption<YorN> = {
  name: 'syncOptions',
  data: [
    {
      id: 'N',
      value: 'N',
      display: 'PRODUCT.COPY.SYNC_N',
    },
    {
      id: 'Y',
      value: 'Y',
      display: 'PRODUCT.COPY.SYNC_Y',
    },
  ],
};

const copyStockOptions: RadioBoxGroupOption<YorN> = {
  name: 'copyStockOptions',
  data: [
    {
      id: 'stock_N',
      value: 'N',
      display: 'PRODUCT.COPY.COPY_N',
    },
    {
      id: 'stock_Y',
      value: 'Y',
      display: 'PRODUCT.COPY.COPY_Y',
    },
  ],
};

const copyReviewOptions: RadioBoxGroupOption<YorN> = {
  name: 'copyReviewOptions',
  data: [
    {
      id: 'review_N',
      value: 'N',
      display: 'PRODUCT.COPY.COPY_N',
    },
    {
      id: 'review_Y',
      value: 'Y',
      display: 'PRODUCT.COPY.COPY_Y',
    },
  ],
};

export const copyFormOptions = {
  syncStockOptions,
  copyStockOptions,
  copyReviewOptions,
} as const;
