






























import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import AppliedProductSearchForm from '@/components/popup/product/management/AppliedProductSearchForm.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { Brand, GetProductsSearchStickerRequest, Mds, NCPResponse, ProductsSearchSticker } from 'ncp-api-supporter';
import {
  convertQueryToNumber,
  displayOptions,
  GetProductsSearchStickerParameter,
  getQueryParams,
  gridProps,
  noticeList,
} from '@/components/popup/product/management/AppliedProduct.ts';
import { DefaultGridContainerOption, GridEventProps, GridProp } from '@/types';
import { Row } from '@/types/tui-grid';
import { ProductsSearchStickerContents } from 'ncp-api-supporter/dist/types/modules/product/product';
import { setNoDataMessage } from '@/helpers/grid';
import { convertQueryParamItemToBoolean } from '@/utils/query';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
@Component({
  components: {
    AppliedProductSearchForm,
    Grid,
    NoticeBox,
  },
})
export default class AppliedProduct extends Vue {
  @Prop() private readonly data!: { mallNo: number; stickerNos: number };
  @Prop() private readonly onNegativeClick!: (data: null) => void;
  private noticeList: string[] = noticeList;

  private created() {
    this.initializeAllData();
    setNoDataMessage(this.$t('PRODUCT.ICON.NO_DATA_MSG_FOR_APPLIED_PRODUCT').toString());
  }

  @Ref('appliedProductGrid') private readonly grid: Grid;
  private gridProps: GridProp = gridProps();
  private displayOptions: DefaultGridContainerOption = displayOptions;

  private onItemClicked({ rowKey, columnName }: GridEventProps): void {
    if (!columnName) return;
    const rowData: Row = this.grid.getRowAt(rowKey);
    const mallProductNo = Number(rowData.mallProductNo);
    switch (columnName) {
      case 'edit':
      case 'mallProductNo':
        openEditProductWindow(mallProductNo);
        break;
    }
  }

  // 브랜드 && 담당자 조회
  private mallNo = 0;
  private iconNo = 0;

  private async initializeAllData(): Promise<void> {
    this.mallNo = this.data.mallNo;
    this.iconNo = this.data.stickerNos;
    await this.fetchAllData();
  }

  private admins: Mds[] = [];
  private brands: Brand[] = [];

  private async fetchAllData(): Promise<void> {
    try {
      const res: [NCPResponse<Mds[]>, NCPResponse<Brand[]>] = await Promise.all([
        this.$api.getAdminsServiceMerchandisers({
          params: {
            mallNo: this.mallNo.toString(),
          },
        }),
        this.$api.getBrands({
          params: {
            mallNo: this.mallNo,
          },
        }),
      ]);
      this.admins = res[0].data;
      this.brands = res[1].data;
      await this.fetchProducts();
    } catch (e) {
      console.error(e);
    }
  }

  // 적용상품 보기
  private queryParams: GetProductsSearchStickerParameter = getQueryParams(this.mallNo, this.iconNo);
  private products: ProductsSearchStickerContents[] = [];
  private totalCount = 0;
  private totalPage = 0;

  private async fetchProducts(): Promise<void> {
    try {
      this.queryParams.mallNo = this.mallNo;
      this.queryParams.stickerNos = this.iconNo;
      const {
        data: { contents, totalCount, totalPage },
      }: NCPResponse<ProductsSearchSticker> = await this.$api.getProductsSearchSticker({
        params: this.queryParams as GetProductsSearchStickerRequest['params'],
      });
      this.products = contents;
      this.totalCount = totalCount;
      this.totalPage = totalPage;
      this.gridProps.options.pageOptions = {
        totalCount: this.totalCount,
      };
    } catch (e) {
      console.error(e);
    }
  }

  // 적용상품 검색
  @Ref() private readonly search!: AppliedProductSearchForm;

  @Watch('$route')
  private onQueryChanged(): void {
    const newQuery = this.$route.query;
    this.queryParams = {
      stickerNos: this.queryParams.stickerNos,
      ...convertQueryToNumber(convertQueryParamItemToBoolean(newQuery)),
    };
    this.fetchProducts();

    newQuery && this.search.sendQuery();
  }

  private onClose(): void {
    this.$route.query.mallNo = this.data.mallNo.toString();
    this.onNegativeClick(null);
  }
}
