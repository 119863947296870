
import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator';
import { CombinationOptionType, ImagePopupData, ProductOptionType } from '@/types';
import {
  selectOptions,
  STOCK_STATUS_TYPE,
  validateOptionPrice,
  validateTenUnit,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import { PopupClose, throwWindowPopup } from '@/helpers/popup';
import { OptionData } from '@/helpers/type';
import { formatCurrency, getNegativeNum, getOnlyNumbers } from '@/utils/numberFormat';
import { getCurrency } from '@/utils/common';

interface SelectBoxOptionsType {
  saleStatusType: (isMapping: boolean) => OptionData<keyof typeof STOCK_STATUS_TYPE>[];
  useYn: OptionData<string>[];
}

@Component({})
export default class CombinationOptionTableMixin extends Vue {
  @PropSync('optionColumnList', { required: true })
  protected optionColumnListSync!: CombinationOptionType<string[]>[];
  @PropSync('optionList', { required: true })
  protected optionListSync!: ProductOptionType[];
  @PropSync('selectedOptions', { required: true })
  protected selectedOptionsSync!: number[];

  @Prop({ required: true }) protected readonly imageBtnIndexes!: number[];
  @Prop({ required: true }) protected readonly useReservation!: boolean;
  @Prop({ required: true }) protected readonly hasSupplyPrice!: () => boolean;
  @Prop({ required: true }) protected readonly mallNo!: number;
  @Prop({ required: true }) protected readonly isMaster!: boolean;
  @Prop({ required: true }) protected readonly isSlave!: boolean;
  @Prop({ required: true }) private readonly isDeletable!: (index: number) => boolean;

  protected saleStatusDisabledList: boolean[] = [];
  protected selectBoxOptions: SelectBoxOptionsType = selectOptions;
  protected getNegativeNum = getNegativeNum;
  protected getCurrency = getCurrency;
  protected formatCurrency = formatCurrency;
  protected validateTenUnit = validateTenUnit;

  @Watch('optionColumnListSync')
  private updateSaleStatusDisabledList() {
    this.saleStatusDisabledList = this.optionColumnListSync.map(({ stockCnt }) => stockCnt === 0);
  }

  protected deleteOption(index: number): void {
    if (this.isDeletable(index) === false) return;
    this.optionColumnListSync.splice(index, 1);
    if (this.optionColumnListSync.length === 0) {
      this.optionListSync = [];
    }
  }

  protected onChangeSaleStatusType(currSaleStatus: string, prevSaleStatus: string, stockCnt: number, index: number) {
    if (stockCnt === 0 || currSaleStatus !== STOCK_STATUS_TYPE.SOLD_OUT) return;
    if (confirm(this.$t('PRODUCT.OPTION.STOCK_STATUS_CHANG_CONFIRM').toString())) {
      this.$set(this.optionColumnListSync[index], 'stockCnt', 0);
      this.$set(this.saleStatusDisabledList, index, true);
    } else {
      this.$nextTick(() => this.$set(this.optionColumnListSync[index], 'saleStatusType', prevSaleStatus));
    }
  }

  protected onKeyupStockCnt(saleStatusType: string, event: { target: HTMLInputElement }, index: number): void {
    const numberStockCnt = Number(event.target.value);
    this.setSaleStatusType(saleStatusType, numberStockCnt, index);
    this.updateValue(event, 'stockCnt', index);
  }

  public updateStockStatus(funcName: 'setStockCnt' | 'setSaleStatusType'): void {
    this.optionColumnListSync.forEach(({ stockCnt, saleStatusType }, index) =>
      this[funcName](saleStatusType, stockCnt, index),
    );
  }

  private setStockCnt(saleStatusType: string, stockCnt: number, index: number): void {
    if (stockCnt === 0 || saleStatusType !== STOCK_STATUS_TYPE.SOLD_OUT) return;
    this.$set(this.optionColumnListSync[index], 'stockCnt', 0);
    this.$set(this.saleStatusDisabledList, index, true);
  }

  private setSaleStatusType(saleStatusType: string, stockCnt: number, index: number): void {
    if (saleStatusType === STOCK_STATUS_TYPE.SOLD_OUT && stockCnt > 0) {
      this.$set(this.optionColumnListSync[index], 'saleStatusType', STOCK_STATUS_TYPE.AVAILABLE);
      this.$set(this.saleStatusDisabledList, index, false);
    }
    if (saleStatusType !== STOCK_STATUS_TYPE.SOLD_OUT && stockCnt === 0) {
      this.$set(this.optionColumnListSync[index], 'saleStatusType', STOCK_STATUS_TYPE.SOLD_OUT);
      this.$set(this.saleStatusDisabledList, index, true);
    }
  }

  @Watch('selectedOptionsSync')
  private isAllChecked(): boolean {
    const columnLength = this.optionColumnListSync.length;
    if (columnLength === 0) {
      return false;
    }
    return this.selectedOptionsSync.length === columnLength;
  }

  protected selectAllOptions(checked: boolean) {
    this.selectedOptionsSync = checked ? this.optionColumnListSync.map((_, i) => i) : [];
  }

  protected selectOption(index: number, checked: boolean): void {
    if (checked) {
      this.selectedOptionsSync.push(index);
    } else {
      this.selectedOptionsSync = this.selectedOptionsSync.filter(option => option !== index);
    }
  }

  protected isRegisteredImage(optionColumn: CombinationOptionType<string[]>): boolean {
    if (!optionColumn?.optionImages) {
      return false;
    }
    return optionColumn.optionImages.length !== 0;
  }

  protected updateValue(
    { target }: { target: HTMLInputElement },
    key: string,
    index: number,
    isNegative = false,
  ): void {
    if (key === 'addPrice') {
      target.value = validateOptionPrice(target.value);
      this.$set(this.optionColumnListSync[index], key, target.value);
    } else {
      this.$set(this.optionColumnListSync[index], key, getOnlyNumbers(target.value, isNegative));
    }
  }

  protected notUpdateValueAfterBlur(value: string): string {
    return value;
  }

  protected popupImageUploadForm(optionColumn: CombinationOptionType<string[]>, index: number): void {
    const images = optionColumn.optionImages || [];
    const data: ImagePopupData = { mallNo: this.mallNo, images, index };
    throwWindowPopup(
      'ProductOptionImage',
      data,
      'lg',
      ({ state, data }) => {
        if (state === PopupClose.CONFIRM) {
          this.$set(this.optionColumnListSync[data.index], 'optionImages', data.images);
        }
      },
      null,
      null,
      null,
      600,
    );
  }

  protected getImageUploadBtnClassName(optionColumn: CombinationOptionType<string[]>): string {
    return this.isRegisteredImage(optionColumn) ? 'type-black' : 'type-white';
  }
}
