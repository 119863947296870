












































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { getToday, addDay, addMonth } from '@/utils/dateFormat';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import { RouteQuery } from '@/types/route';
import { QuestionsParams } from '@/types/questions';
import { i18n } from '@/views/contents/board/basic/Questions';
import MallSelect from '@/components/common/input/MallSelect.vue';

@Component({
  components: {
    SelectBox,
    DateRangePicker,
    SearchButton,
    MallSelect,
  },
})
export default class QuestionsTop extends Vue {
  @Ref('searchBtn')
  private readonly searchBtn;

  @Watch('$route.query')
  private onQueryStringChanged(query: RouteQuery) {
    if (query.size) {
      Object.assign(this.query, { size: query.size });
    }
  }

  private t(txt: string): string {
    return i18n.call(this, txt);
  }

  public get isOfficeHour(): boolean {
    const isOfficeHour = this.$route.query.isOfficeHour;
    if (typeof isOfficeHour === 'boolean') {
      return isOfficeHour;
    }

    if (typeof isOfficeHour === 'string' && isOfficeHour === 'true') {
      return true;
    }
    return false;
  }

  private delayCount = 0;

  private dateOpts = {
    name: 'joinDatePeriod',
    periodTypes: ['td', '1w', '15d', '3m', '1y'],
  };

  private query: QuestionsParams = {
    mallNo: '',
    keyword: '',
    startYmd: addDay(new Date(), -7),
    endYmd: getToday(),
    searchType: 'ALL',
    searchDateType: 'REGISTER_YMDT',
    replyStatusType: 'ALL',
    displayStatusType: '',
    page: 1,
    size: 30,
  };

  private queryClone = { ...this.query };

  private searchDateType = [
    { name: this.t('REGISTRATION_DATE'), value: 'REGISTER_YMDT' },
    { name: this.t('REPLY_DATE'), value: 'REPLY_YMDT' },
  ];

  private searchType = [
    { name: this.t('SEARCH'), value: 'ALL' },
    { name: this.t('PRODUCT_NUMBER'), value: 'MALL_PRODUCT_NO' },
    { name: this.t('NAME'), value: 'MEMBER_ID' },
  ];

  private displayStatusType = [
    { name: this.t('EXHIBITION_STATUS'), value: '' },
    { name: this.t('EXHIBITION'), value: 'DISPLAY' },
    { name: this.t('BLIND'), value: 'BLIND' },
  ];

  private replyStatusType = [
    { value: 'ALL', name: this.t('RESPONSE_STATUS') },
    { value: 'READY', name: this.t('WAITING_FOR_REPLY') },
    { value: 'COMPLETE', name: this.t('ANSWER_COMPLETED') },
  ];

  private checkValidation(): boolean {
    return true;
  }

  private addDelayYn() {
    if (this.isOfficeHour) {
      return;
    }

    this.query = { ...this.queryClone };
    const date = new Date();
    let endYmd = addDay(date, -1);
    if (date.getHours() < 9) {
      endYmd = addDay(date, -2);
    }
    const startYmd = addMonth(new Date(addDay(date, 0)), -3);
    Object.assign(this.query, { replyStatusType: 'READY', startYmd, endYmd });
    this.$router.push({
      query: {
        isOfficeHour: 'true',
        replyStatusType: 'READY',
        startYmd,
        endYmd,
      },
    });
  }

  private reset() {
    this.query = { ...this.queryClone };
    if (!this.isOfficeHour) return;
    this.$router.push({
      query: {},
    });
  }

  private created() {
    this.$api.getProductInquiriesDelayedCount().then(({ data }): void => {
      this.delayCount = data;
    });
    Object.assign(this.query, { ...this.$route.query });
  }
}
