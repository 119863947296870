
import { Component, Vue, Watch } from 'vue-property-decorator';
import { GetSmsUnsubscribeConfigRequest, GetSmsUnsubscribeConfig, NCPResponse } from 'ncp-api-supporter';
import { getCurrentMallNo } from '@/utils/mall';

export enum UnsubscribeStatus {
  NONE = 'NONE',
  APPLICATION = 'APPLICATION',
  PAID = 'PAID',
  OPEN = 'OPEN',
  TERMINATION = 'TERMINATION',
}

export type UnsubscribeStatusType = keyof typeof UnsubscribeStatus;

export type ApplyInfoDescription<DescriptionValue> = {
  [key in UnsubscribeStatus]?: DescriptionValue;
};

@Component
export default class Reject080StatusMixin extends Vue {
  protected unsubscribeStatus: UnsubscribeStatusType = UnsubscribeStatus.NONE;
  protected createdDate = '';
  protected cancelDate = '';
  protected unsubscribeNo = '';
  protected unsubscribeUsed = false;

  @Watch('$route.query')
  protected async getUnsubscribeConfig() {
    const request: GetSmsUnsubscribeConfigRequest = {
      params: { mallNo: getCurrentMallNo(this) },
    };

    try {
      const { data }: NCPResponse<GetSmsUnsubscribeConfig> = await this.$api.getSmsUnsubscribeConfig(request);

      this.unsubscribeStatus = UnsubscribeStatus[data.status] || UnsubscribeStatus.NONE;
      this.createdDate = data.createdDate || '';
      this.cancelDate = data.updatedDate ?? '';
      this.unsubscribeNo = data.unsubscribeNo
        ? data.unsubscribeNo.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
        : '';
      this.unsubscribeUsed = data.used || false;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
