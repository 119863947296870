











































import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator';

export type Unique = string | number;

export interface KeyMap {
  name: string;
  pick: string;
  unique: Unique;
  order?: string;
}

export interface DataType {
  [key: string]: string | number | boolean;
}

type HeadLines = [string, string];

@Component
export default class GridPicker extends Vue {
  @PropSync('data', { required: true }) dataSync!: DataType[];
  @Prop({ required: true }) keyMap!: KeyMap;
  @Prop({ required: false }) headlines: HeadLines;

  // 상위 컴포넌트에서 이 div 를 스크롤 시킬 일이 있어 public 으로 공개했는데, 우아한 방법은 아닌것같다.
  @Ref() public readonly leftBox!: HTMLDivElement;
  @Ref() public readonly rightBox!: HTMLDivElement;

  protected selectedItems: Unique[] = [];

  get pickedData(): DataType[] {
    return this.dataSync
      .filter(v => v[this.keyMap.pick])
      .sort((fst, snd) => Number(fst[this.keyMap.order]) - Number(snd[this.keyMap.order]));
  }

  private onClickItem(shiftKey: MouseEvent['shiftKey'], unique: Unique) {
    if (!shiftKey) {
      this.selectedItems = [unique];
      return;
    }

    const selectedIndex = this.selectedItems.indexOf(unique);
    if (selectedIndex < 0) {
      this.selectedItems.push(unique);
    } else {
      this.selectedItems.splice(selectedIndex, 1);
    }
  }

  private pick(add: boolean): void {
    this.selectedItems.forEach(unique => {
      const selectedItem = this.dataSync.find(data => data[this.keyMap.unique] === unique);
      if (selectedItem) selectedItem[this.keyMap.pick] = add;
    });
    this.reset();
    this.$emit('changed:data');
  }

  private reset() {
    this.selectedItems = [];
  }
}
