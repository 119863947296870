export default Object.freeze({
  //파트너 조회
  LIST_TITLE: '파트너 조회',
  WAITING: '등록대기',
  INVESTIGATION: '검토중',
  ACTIVE: '등록완료',
  REGISTERED: '있음',
  NOT_REGISTERED: '없음',
  PARTNER_NO: '파트너번호',
  PARTNER_NAME: '파트너명',
  COUNTRY: '국적',
  COMPANY_NAME: '회사명',
  PARTNER_SHOPBY_STATUS: 'shop by등록여부',
  PRODUCT_REGISTERED: '상품등록',
  REGISTRATION_NO: '사업자번호',
  OMD_NO: '통신판매신고번호',
  SEARCH_DATE_TIME: '기간검색',
  CONTRACTED_DATE_TIME: '거래일시',
  CREATED_DATE_TIME: '등록일시',
  COMMISSION_RATE: '수수료율',
  NOT_USED: '사용 안 함',
  CHOOSE: '선택',
  SEARCHTYPE_TTL: '검색어',
  SHOPPING_MALL: '거래쇼핑몰',
  PARTNER_STATUS: '파트너 상태',
  CONTRACT_STATUS: '거래 상태',
  CONTRACT_STATUS_WAITING: '거래대기',
  CONTRACT_STATUS_INVESTIGATION: '검토중',
  CONTRACT_STATUS_ACTIVE: '거래중',
  CONTRACT_STATUS_SUSPEND: '거래 일시중단',
  MD_NAME: '담당MD 운영자명',
  NOT_REGISTERED_PARTNER: '상태없음',
  PLEASE_INPUT: '검색어를 입력하세요',
  PLACEHOLDER: '검색어 입력',
  ALL: '전체',

  MALL_NAME: '쇼핑몰명',
  CONTRACT: '거래일시',
  CREATED: '등록일시',
});
