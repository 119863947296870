export default Object.freeze({
  BASIC_INFO: '기본정보',
  MALL: '쇼핑몰',
  GROUP_TYPE: '상품군',
  GROUP_TYPE_DELIVERY: '배송상품군',
  GROUP_TYPE_SERVICE: '서비스상품군',
  CLASS_TYPE: '상품유형',
  CLASS_TYPE_DEFAULT: '일반상품',
  CLASS_TYPE_EVENT: '이벤트상품',
  CLASS_TYPE_OFFLINE: '오프라인상품',
  USE: '사용함',
  NOT_USE: '사용 안 함',
  PARTNER: '파트너사',
  SALE_METHOD_TYPE: '판매방식',
  SALE_METHOD_TYPE_PURCHASE: '사입',
  SALE_METHOD_TYPE_CONSIGNMENT: '위탁',
  ADMIN: '담당자',
  CATEGORY: '표준카테고리',
  DISPLAY_CATEGORY: '전시카테고리',
  IMMEDIATE_DISCOUNT: '즉시할인가',
  PLATFORM_DISPLAYINFO: '플랫폼별 노출설정',
  PLATFORM_DISPLAYINFO_MOBILEWEB: '모바일 웹',
  PLATFORM_DISPLAYINFO_MOBILE: '모바일 앱',
  MEMBER_GRADE_DISPLAYINFO: '회원등급 노출 설정',
  MEMBER_GROUP_DISPLAYINFO: '회원그룹 노출 설정',
  PRODUCT_NAME: '상품명',
  PRODUCT_NAME_EN: '영문상품명',
  PROMOTION_TEXT: '홍보문구',
  BRAND: '브랜드',
  MINOR_PURCHASE_YN: '성인인증',
  PAYMENT_MEANS: '결제수단설정',
  NAVER_PAY_LIMIT_Y: '네이버페이 결제 불가',
  PAYMENT_MEANS_PAYCO: '페이코',
  PAYMENT_MEANS_CREDIT: '신용카드',
  PAYMENT_MEANS_REALTIME_TRANSFER: '실시간계좌이체',
  PAYMENT_MEANS_DEPOSIT: '가상계좌',
  PAYMENT_MEANS_ESCROW_REALTIME_TRANSFER: '실시간계좌이체-에스크로',
  PAYMENT_MEANS_ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  CART: '장바구니',
  KEYWORDS: '검색어',
  SALE_INFO: '판매정보',
  RESERVATION_INFO: '예약판매',
  RESERVATION_DELIVERY_STARTYMD: '예약배송시작일',
  RESERVATION_PERIOD: '예약판매기간',
  SALE_PERIOD_INFO: '판매기간',
  SALE_PERIOD_INFO_REGULAR: '상시 판매',
  SALE_PERIOD_INFO_PERIOD: '기간지정 판매',
  COMMISSION_INFO: '판매수수료',
  COMMISSION_INFO_PRODUCT: '상품수수료',
  COMMISSION_INFO_CATEGORY: '카테고리수수료',
  COMMISSION_INFO_PARTNER: '파트너수수료',
  COMMISSION_INFO_PURCHASE_PRICE: '공급가입력',
  SALE_PRICE: '판매가',
  CONTENTS_IF_PAUSING: '가격대체문구 사용',
  IMMEDIATE_DISCOUNT_INFO: '즉시할인',
  IMMEDIATE_DISCOUNT_PRICE: '즉시할인가',
  PURCHASE_PRICE: '매입가',
  SUPPLY_PRICE: '공급가',
  PARTNER_CHARGE_AMT: '파트너사분담금',
  ACCUMULATION_RATE: '개별 적립금 설정',
  ACCUMULATION_USEYN: '적립금사용',
  PROMOTION_INFO: '프로모션',
  PRODUCT_STOCK_CNT: '재고수량',
  MIN_BUY_CNT: '최소구매수량',
  MAX_BUY_COUNT_INFO: '최대구매수량',
  MAX_BUY_COUNT_INFO_PERSON: '1인당 최대 구매 수량',
  MAX_BUY_COUNT_INFO_TIME: '1회당 최대 구매 수량',
  MAX_BUY_COUNT_INFO_DAY: '최대구매수량 기간 제한 - 제한 수량',
  MAX_BUY_COUNT_INFO_CNT: '일동안 최대',
  MAX_BUY_COUNT_INFO_CNT2: '개 구매가능',
  MAX_BUY_COUNT_INFO_CNT3: '개 이상 구매가능',
  OPTION_USE_YN: '옵션 사용여부',
  OPTIONS: '옵션',
  MALL_ADDITION_PRODUCT: '추가상품',
  COMPARING_PRICE_SITE_INFO: '가격비교사이트 등록',
  COMPARING_PRICE_SITE_INFO_NAVER: '네이버쇼핑',
  COMPARING_PRICE_SITE_INFO_DAUM: '다음쇼핑하우',
  COMPARING_PRICE_SITE_INFO_ENURI: '에누리',
  COMPARING_PRICE_SITE_INFO_DANAWA: '다나와',
  COMPARING_PRICE_SITE_INFO_COOCHA: '쿠차',
  COMPARING_PRICE_SITE_INFO_PAYCO: '페이코쇼핑',
  PLACE_ORIGIN_INFO: '원산지',
  DUTY_CONTENT: '상품정보고시',
  DUTY_CONTENT2: '상품고시',
  CERTIFICATION_INFO: '인증정보',
  CERTIFICATION_INFO_TARGET: '인증대상',
  CERTIFICATION_INFO_NOT_TARGET: '인증대상아님',
  CERTIFICATION_INFO_DETAIL_PAGE: '상세페이지 별도표기',
  SUPPLIER_PRODUCT_NAME: '매입처 상품명',
  MANUFACTURE_YMDT: '제조일자',
  EXPIRATION_YMDT: '유효일자',
  VALUE_ADDED_TAX_TYPE: '부가세',
  VALUE_ADDED_TAX_TYPE_DUTY: '과세',
  VALUE_ADDED_TAX_TYPE_DUTYFREE: '면세',
  VALUE_ADDED_TAX_TYPE_SMALL: '영세',
  PRODUCT_NO: '상품번호',
  PRODUCT_MANAGEMENT_CD: '상품관리코드',
  HS_CODE: 'HS CODE',
  REFUNDABLE_YN: '주문환불',
  ICON_ALWAYS_DISPLAY: '아이콘 - 상시노출',
  ICON_EXPOSURE_PERIOD: '아이콘 - 노출기간',
  ICON_PERIOD_DISPLAY: '아이콘 - 기간노출',
  IMAGE_INFO: '이미지정보',
  MAIN_IMAGE: '대표이미지',
  PRODUCT_IMAGE: '상품이미지',
  ADD_IMAGE: '추가이미지',
  LIST_IMAGE: '리스트 이미지',
  CONTENT_HEADER: '상품 상세(상단)',
  CONTENT_FOOTER: '상품 상세(하단)',
  CONTENT: '상품 상세',
  DELIVERY_GUIDE: '배송안내',
  AFTER_SERVICE_GUIDE: 'AS안내',
  REFUND_GUIDE: '환불안내',
  EXCHANGE_GUIDE: '교환안내',
  DEFAULT_TEMPLATE: '기본 템플릿',
  EDIT: '수정',
  UPDATE: '수정필요',
  DELIVERY_YN: '배송여부',
  DELIVERY_Y: '배송',
  DELIVERY_N: '배송안함',
  DELIVERY_INFO: '배송정보',
  SHIPPING_AREA_TYPE: '배송구분',
  SHIPPING_AREA_TYPE_PARTNER: '파트너 배송',
  SHIPPING_AREA_TYPE_MALL: '쇼핑몰 배송',
  DELIVERY_COMBINATION_YN: '묶음배송 가능여부',
  DELIVERY_INTERNATIONAL_YN: '해외배송 여부',
  DELIVERY_TEMPLATE: '배송 템플릿 목록',
  DELIVERY_TYPE: '배송방법',
  DELIVERY_CONDITION_TYPE: '배송비유형',
  EACH: '개당',
  RELEASE_WAREHOUSE: '출고지',
  RETURN_WAREHOUSE: '반품교환지',
  RETURN_DELIVERY_AMT: '반품/교환 배송비',
  ONEWAY_STANDARD: '편도기준',
  DELIVERY_CUSTOMER_INFO: '판매자 특이사항/고객안내사항',
  ETC_INFO: '별도 승인거부 의견',
  UPDATE_REQUEST: '수정요청',
  ADD: '지급',
  COUPON: '쿠폰',
  FREE_GIFT: '사은품',
  ADDITIONALDISCOUNT: '추가할인',
  REFUND_Y: '환불가능',
  REFUND_N: '불가',
  NO: '등록안함',
  YES: '등록',
  ALL: '등록(전체)',
  USE_REGISTERED_OPTIONAL_IMAGES: '등록된 옵션이미지 사용',
  CAN_DELIVERY_COMBINATION: '묶음배송 가능',
  UNABLE_DELIVERY_COMBINATION: '묶음배송 불가능(상품별 배송비 적용)',
  DELIVERY_INTERNATIONAL_Y: '해외배송상품 (해외 -> 대한민국으로 배송되는 상품)',
  DELIVERY_INTERNATIONAL_N: '국내배송상품',
  SALE_APPROVE: '판매승인',
  APPROVE_REJECT: '승인거부',
  DISPLAY: '노출',
  NOT_SET: '설정 안함',
  ALL_SET: '전체 설정',
  DUE: '설정기간',
  ALL_CAN: '전체가능',
  DUE_SET: '기간설정',
  DUE_LIMIT: '기간제한',
  FIXED_FEE: '배송비',
  ORDER_AMT: '주문금액',
  CONDITION: '원 이상 배송비 무료 미만 시',
  FREE: '배송비 무료',
  SELECTED: '개별상품',
  FOR_ALL: '전체(회원+비회원)',
  FOR_MEMBER: '회원 전용(비회원 제외)',
  FOR_SPECIAL: '특정회원등급',
  LATEST_REGISTER_DATE: '최신 등록순',
  SALES_COUNT: '판매량순',
  BY_VTR: '조회순',
  REVIEW_COUNT: '상품 후기순',

  TARGET_LOCATION: '번 위치로',
  MOVE: '이동',
  NEED_TARGET: '이동 순서를 입력해주세요.',
  FRONT_DISPLAYABLE: '전시상태',
  FRONT_DISPLAY: '전시함',
  FRONT_NO_DISPLAY: '전시 안 함',
  ADVANCED_SEARCH: '상세검색',
  APPLY_STATUS_TYPE: '승인상태',

  REGISTRATION_READY: '등록대기',
  APPROVAL_READY: '승인대기',
  APPROVAL_REJECTION: '승인거부',
  SALE_AGREEMENT_READY: '판매합의대기',
  SALE_AGREEMENT_REJECTION: '판매합의거부',
  FINISHED: '승인완료',
  AFTER_APPROVAL_READY: '수정 후 승인대기',
  AFTER_APPROVAL_REJECTION: '수정 후 승인거부',
  CUSTOM_ORDER: '수동진열',
  PRODUCT_INSERT_INFO_JUDGEMENT: '상품등록정보 심사',
  SALE_STATUS_TYPE: '판매상태',

  PRE_APPROVAL_STATUS: '승인완료이전',
  ON_PRE_SALE: '예약판매중',
  WAITING_SALE: '판매대기',
  ON_SALE: '판매중',
  END_SALE: '판매종료 ',

  FRONT_DISPLAYABLE_Y: '전시함',
  FRONT_DISPLAYABLE_N: '전시 안 함',

  SUPPLY_PRICE_DETAILS_TITLE: '옵션 공급가 상세보기',
  SUPPLY_PRICE_DETAILS_OPTION_INFO: '옵션정보',
  SUPPLY_PRICE_DETAILS_SUPPLY_PRICE: '공급가',

  IMAGE_FILE_MAX_SIZE: '이미지 파일만 업로드 가능합니다. \n (확장자 : JPG, JPEG, GIF, PNG, BMP)',

  ADDITIONAL_SERVICE: '부가서비스',
  DETAILS_VIEW: '상세보기',
  APPLY: '신청하기',

  SINGLE: '일반',
  MAPPING: '세트',
  MASS: '대량',

  REGISTRATION_TYPE: '상품등록 유형',
  PLZ_SELECT_REGISTRATION_TYPE: '원하시는 상품등록 유형을 선택하세요.',
  TITLE_SINGLE: '일반 상품',
  TITLE_MAPPING: '세트 상품',
  TITLE_MASS: '대량 상품 등록',
  DESC_SINGLE_1: '일반 상품 개별 등록',
  DESC_SINGLE_2: '(옵션, 텍스트옵션 등록 가능)',
  DESC_MAPPING_1: '기등록된 옵션을 불러와',
  DESC_MAPPING_2: '세트로 등록',
  DESC_MASS_1: '엑셀을 통해',
  DESC_MASS_2: '대량의 상품을 일괄 등록',

  ITEM: '항목',
  REGISTRATION_INFORMATION: '등록정보',
  BEFORE_REGISTRATION_INFORMATION: '변경 전 등록정보',
  AFTER_REGISTRATION_INFORMATION: '변경 후 등록정보',
  CHANGE_ITEM: '수정필요항목',
  TEXTAREA_PLACEHOLDER: '위 항목에 대한 요청사항을 입력할 수 있습니다.(최대 500자)',

  PRODUCT_JUDGE_RESULT_UPDATE_ITEMS: '상품심사 결과 수정필요항목 ',
  PRODUCT_JUDGE_RESULT_NOTI_MESSAGE_1: '판매진행을 위해 수정이 필요한 항목입니다.',
  PRODUCT_JUDGE_RESULT_NOTI_MESSAGE_2: '항목별로 상세 내용 확인/수정 후 재승인요청 부탁드립니다.',
  PRODUCT_UPDATE: '상품수정',

  APPROVE_SUCCESS: '승인이 완료되었습니다.',
  REJECT_SUCCESS: '승인을 거절하였습니다.',
  APPROVE_FAIL: '승인을 실패하였습니다.',
  REJECT_FAIL: '승인거절을 실패하였습니다.',

  SALE_AGREEMENT_REJECTION_REASON: '판매합의거부 사유',
  SALE_AGREEMENT_REJECTION_REASON_NOTI_MESSAGE_1: '판매합의거부 사유 입니다.',
  SALE_AGREEMENT_REJECTION_REASON_NOTI_MESSAGE_2: '상품정보 수정 후 승인절차를 다시 진행해 주시기 바랍니다.',
});
