






















































































































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import CurrencyTextInput from '@/components/common/CurrencyTextInput.vue';

import { RadioBoxGroupOption } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';
import { MaxPurchaseType, SalesInformation } from '@/types';
import TextInput from '@/components/common/input/TextInput.vue';
import { getCurrency, getOnlyNumbers } from '@/utils/numberFormat';
import { PRODUCT_FORM_ERROR_TYPE } from '@/const/contents/product';

@Component({
  components: {
    TextInput,
    RadioGroup,
    Currency: CurrencyTextInput,
  },
})
export default class MaxPurchaseQuantity extends Vue {
  @PropSync('salesInfo')
  private salesInfoSync: SalesInformation;

  @PropSync('maxPurchaseType')
  private maxPurchaseTypeSync: MaxPurchaseType;

  @Prop()
  private formError = {} as { [errorKey: string]: string };

  @Prop()
  private readonly formErrorType: Pick<
    typeof PRODUCT_FORM_ERROR_TYPE,
    | typeof PRODUCT_FORM_ERROR_TYPE['INVALID_MIN_BUY_CNT_OVER_MAX_BUY_PERSON_CNT']
    | typeof PRODUCT_FORM_ERROR_TYPE['INVALID_MIN_BUY_CNT_OVER_MAX_BUY_TIME_CNT']
    | typeof PRODUCT_FORM_ERROR_TYPE['INVALID_MAX_BUY_PERIOD_CNT']
    | typeof PRODUCT_FORM_ERROR_TYPE['INVALID_MIN_BUY_CNT_OVER_MAX_BUY_PERIOD_CNT']
    | typeof PRODUCT_FORM_ERROR_TYPE['INVALID_MAX_BUY_DAYS']
  >;

  private getCurrency = getCurrency;

  private validation = {
    type: '',
    text: '',
    atLeastOne: 'PRODUCT.ADD.AT_LEAST_ONE',
  };

  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'usePurchaseMax',
    data: [
      { id: 'notAllowedMinPurchaseMax', value: 'N', display: 'PRODUCT.COMMON.NOT_USE' },
      { id: 'allowedMinPurchaseMax', value: 'Y', display: 'PRODUCT.COMMON.USE' },
    ],
  };

  private handleOnlyNumber({ target: { value, id } }) {
    this.salesInfoSync.maxBuyCountInfo[id] = value.replaceAll(',', '');
  }

  private handleQuantityCount({ target: { value, name } }) {
    if (name === 'maxDay') return;

    if (!value || Number(value) < 1) {
      this.validation.type = this.maxPurchaseTypeSync;
      this.validation.text = this.validation.atLeastOne;
      return;
    }

    this.validation.type = '';
    this.validation.text = '';

    this.handleFocus();
  }

  private handleFocus() {
    if (!Object.keys(this.formError).length) return;
    this.$emit('focus');
  }

  private changedBuyCnt(value: string): string {
    return getOnlyNumbers(value).toString();
  }
}
