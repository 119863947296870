


































































































































































































































































































































































































































import { Vue, Component, Watch, Ref, PropSync, Prop } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  relatedProductRadioOption,
  saleMethodSelectOptions,
  getDisplayCategoryToolTipMsg,
} from '@/views/contents/product/basic/ProductAdd/basicInfo/BasicInfo';
import { BasicInfoFocusType, BasicInformation, BasicInformationModel, RelatedProductInformation } from '@/types';
import MultiStandardCategoriesSelector from '@/components/common/categories/MultiStandardCategoriesSelector.vue';
import MultiDisplayCategoriesSelector from '@/components/common/categories/MultiDisplayCategoriesSelector.vue';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import UpgradeService from '@/views/contents/product/basic/ProductAdd/basicInfo/details/UpgradeService.vue';
import PlatformDisplay from '@/views/contents/product/basic/ProductAdd/basicInfo/details/PlatformDisplay.vue';
import MemberGrade from '@/views/contents/product/basic/ProductAdd/basicInfo/details/MemberGrade.vue';
import ProductDesc from '@/views/contents/product/basic/ProductAdd/basicInfo/details/ProductDesc.vue';
import ProductName from '@/views/contents/product/basic/ProductAdd/basicInfo/details/ProductName.vue';
import UrlDirectDisplay from '@/views/contents/product/basic/ProductAdd/basicInfo/details/UrlDirectDisplay.vue';
import Brands from '@/views/contents/product/basic/ProductAdd/basicInfo/details/Brands.vue';
import AddKeywords from '@/views/contents/product/basic/ProductAdd/basicInfo/details/AddKeywords.vue';
import MinorPurchase from '@/views/contents/product/basic/ProductAdd/basicInfo/details/MinorPurchase.vue';
import MallSelector from '@/views/contents/product/basic/ProductAdd/basicInfo/details/MallSelector.vue';
import AdminsInfo from '@/views/contents/product/basic/ProductAdd/basicInfo/details/AdminsInfo.vue';
import DisplayOrderInfo from '@/views/contents/product/basic/ProductAdd/basicInfo/details/DisplayOrderInfo.vue';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { SaleMethodType, StandardCategory } from 'ncp-api-supporter';
import { mall } from '@/utils/mall';
import {
  CONFIG_TYPE,
  DELETE_ERROR,
  IMMUTABLE_FIELD,
  PRODUCT_FORM_ERROR_TYPE,
  SORT_CRITERION,
} from '@/const/contents/product';
import { ProductManagementMode } from '@/views/contents/product/basic/ProductAdd.vue';
import PartnerFinder from '@/components/common/input/PartnerFinder.vue';
import { Getter } from 'vuex-class';

@Component({
  components: {
    MinorPurchase,
    DisplayOrderInfo,
    AdminsInfo,
    MallSelector,
    AddKeywords,
    Brands,
    UrlDirectDisplay,
    ProductName,
    ProductDesc,
    MemberGrade,
    PlatformDisplay,
    UpgradeService,
    MultiDisplayCategoriesSelector,
    MultiStandardCategoriesSelector,
    SelectBox,
    DateRangePicker,
    RadioGroup,
    CheckboxGroup,
    TextInput,
    ToolTip,
    SearchKeyword,
    PartnerFinder,
  },
})
export default class BasicInfo extends Vue {
  @Getter('partner/getPartnerNo') private readonly partnerNo: number;
  @PropSync('basicInfo')
  private basicInfoSync!: BasicInformation;

  @PropSync('basicInfoModel')
  private basicInfoModelSync!: BasicInformationModel;

  @PropSync('relatedProductInfo', { required: true })
  private relatedProductInfoSync: RelatedProductInformation;

  @PropSync('isForAdults')
  private isForAdultsSync: boolean;

  @Prop({ required: true })
  private readonly resetCategoryData!: () => void;

  @Prop()
  private readonly isSelectedMall: boolean;

  @Prop({ required: true })
  private readonly mode: ProductManagementMode;

  @Prop({ required: true })
  private readonly editFlag: {
    isMapping: boolean;
    isReady: boolean;
    isApproved: boolean; // 승인완료
    isEnded: boolean; // 판매종료
    isMaster: boolean;
    isSynced: boolean; // 재고연동 여부
    isProhibited: boolean;
  };

  @Prop({ required: true })
  private readonly immutableFields;

  private readonly IMMUTABLE_FIELD = IMMUTABLE_FIELD;

  @Ref()
  private readonly adminRef: AdminsInfo;

  @Ref()
  private readonly categoryRef: MultiStandardCategoriesSelector;

  @Ref()
  private readonly platformRef: PlatformDisplay;

  @Ref()
  private readonly gradeRef: MemberGrade;

  @Ref()
  private readonly minorPurchase: MinorPurchase;

  get shouldInitiate() {
    return this.mode.isEdit || this.mode.isCopy;
  }
  public onFocus(focusTarget: BasicInfoFocusType): void {
    this[`${focusTarget}Ref`].focus();
  }

  public resetMinorPurchase() {
    this.minorPurchase.resetMinorPurchase();
  }

  public alertMallSelect(): void {
    alert(this.$t('ALERT_NO_SELECTED', { value: this.$t('MALL') }));
    this.focusOnMallSelect();
  }

  private alertNoData(): void {
    alert(this.$t('PRODUCT.ADD.CATEGORY_EMPTY'));
  }

  private CONFIG_TYPE = CONFIG_TYPE;
  private relatedProductRadioOption: RadioBoxGroupOption<keyof typeof CONFIG_TYPE> = relatedProductRadioOption;
  private saleMethodSelectOptions: OptionData<SaleMethodType>[] = saleMethodSelectOptions;
  private displayCategoryToolTipMsg = getDisplayCategoryToolTipMsg();

  // NOTE: 현 버전에서는 기본 값 세팅 후 'hidden' 처리
  private isUpgradedService = false;

  private changedMall(mallNo: number): void {
    this.$emit('changedMall', mallNo);
    this.categoryRef.resetSelectData();
  }

  @Ref() private readonly mallRef: MallSelector;

  private focusOnMallSelect(): void {
    this.mallRef.focus();
  }

  private hasOneMall = false;
  private sortedMallsByCreationDate = [];
  private isBook = false;

  private setMallState() {
    this.hasOneMall = mall.isOnlyOneMall;
    if (this.hasOneMall) {
      this.basicInfoSync.mallNo = mall.onlyOneMallNo;
      this.changedMall(this.basicInfoSync.mallNo);
    }
    this.sortedMallsByCreationDate = mall.mallsSortedByCreationDate;
  }

  @Watch('partnerNo')
  private setPartnerNo() {
    this.basicInfoSync.partnerNo = this.partnerNo;
  }

  @Ref() private productNameRef: ProductName;
  @Ref() private productDescRef: ProductDesc;
  @Ref() private keywordsRef: AddKeywords;

  @Watch('basicInfoModel.categoryNo')
  private setStandardCategoryNo(): void {
    const [categoryNo] = this.basicInfoModelSync.categoryNo;
    this.basicInfoSync.categoryNo = categoryNo > 0 ? categoryNo : 0;
  }

  public get isDeepSea(): boolean {
    return (
      this.categoryRef.standardCategories?.find(
        (item: StandardCategory) => item.depth4CategoryNo === this.basicInfoModelSync.categoryNo[0],
      )?.originType === 'DEEP_SEA'
    );
  }

  private updateMinorPurchaseYn(isForAdults: boolean) {
    this.isForAdultsSync = isForAdults;
    this.basicInfoSync.minorPurchaseYn = isForAdults ? 'N' : 'Y';
    this.basicInfoSync.nonmemberPurchaseYn = isForAdults ? 'N' : 'Y';
  }

  private setIsBookCategory(isBook: boolean) {
    this.isBook = isBook;
  }

  private changedUseRelatedProductStatus(curr: keyof typeof CONFIG_TYPE) {
    if (curr !== CONFIG_TYPE.NOT_USED && !this.isSelectedMall) {
      this.alertMallSelect();
      this.$nextTick(() => {
        this.relatedProductInfoSync.configType = CONFIG_TYPE.NOT_USED;
      });
    }
    if (curr === CONFIG_TYPE.DISPLAY_CATEGORY) {
      this.$set(this.relatedProductInfoSync, 'sortCriterion', SORT_CRITERION.LATEST_REGISTER_DATE);
    }
  }
  mounted() {
    // setMallState => changedMall => categoryRef.resetSelectData 하는데 created 시점에 하면
    // 어?? categoryRef.resetSelectData 가 없는데?? 하기 때문에 mounted 에 겁니다.
    this.setMallState();
  }

  // error
  @Prop({ required: true })
  private readonly formError: { [errorKey: string]: string };
  @Prop({ required: true })
  private readonly formErrorType: typeof PRODUCT_FORM_ERROR_TYPE;
  @Prop({ required: true })
  private readonly deleteError: typeof DELETE_ERROR;

  private get isSyncStock(): boolean {
    return this.$route.query?.synced === 'Y';
  }
}
