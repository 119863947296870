export default Object.freeze({
  TITLE: '적립금 설정',
  BASIC_PAGE_TITLE: '적립금 기본 설정',
  DISCLAIMER: '필수입력',
  MALL: '쇼핑몰',
  RATE: '기본적립률',
  RATE_DESCRIPTION: '상품별 적립금을 설정하지 않은 경우 기본 적립률이 적용됩니다.',
  NAME: '적립금 명칭',
  NAME_PLACEHOLDER: '쇼핑몰 적립금 표시 영역에 입력한 값이 노출됩니다.',
  UNIT: '적립금 표시단위',
  UNIT_PLACEHOLDER: '예시) 원, 포인트, 점, p, 코인 등',
  RESEVER_TIME: '적립금 지급 시점',
  PERIOD: '적립금 유효기간',
  ALERT_PERIOD: '적립금 유효기간은 최소 1개월 이상입니다.',
  EXPIRED: '적립금 만료',
  NOTIFICATION_TIME: '알림시점',
  NOTIFICATION_METHOD: '알림수단',
  N_DAYS_AGO: '{0}일 전',
  PERIOD_TYPE_EXPIRE: '만료일 기준',
  NOTICE_SETTING: '[설정 안내]',
  NOTICE_REASON: '[사유 안내]',
  NOTICE_GUIDE: '현재 {0} 알림 방송이 불가합니다.',
  NOT_USE: '사용여부 : 사용 안 함',
  NOT_AUTO: '자동 발송항목 설정',
  NOT_AUTO_NIGHT: '야간시간대 발송 불가',
  BY_CASE: '건별',
  SMS_POINT_REMAIN:
    'SMS 보유 포인트 부족 ({case}사용 예정 포인트 : <span class="text-red">{toBeUsedPoint}</span> / 보유 포인트 : <span class="text-red">{retainPoint}</span>)',
  CHARGE_SMS_POINT: 'SMS 포인트 충전하기 >',
  GO_SMS_SETTING: 'SMS 설정 바로가기 >',
  GO_EMAIL_SETTING: '이메일 설정 바로가기 >',
  EXPIRED_NOTIFICATION_TIME: '적립금 만료일 알림 시점',
  DISPLAY_FORMAT_TYPE_DESCRIPTION: '적립금이 표시되는 영역에 설정한 포맷 형식으로 노출할 수 있습니다.',
  UNIT_DESCRIPTION: 'ex) 점, 원, point, 코인',
  UNIT_ALERT: '입력형식이 잘못되었습니다.',
  AFTER_PURCHASE_CONFIRM: '구매확정 후',
  PAY_DESCRIPTION_1: '운영 중 지급시점을 변경하는 경우 변경 이후 주문 건 부터 적용됩니다.',
  PAY_DESCRIPTION_2:
    '지급 시점을 단기로 할 경우 주문취소/반품에 대한 처리에 문제가 발생될 수 있습니다. 설정 시 유의하시기 바랍니다.',
  BASE_ON_PAY_TIME: '적립금 지급일 기준',
  PERIOD_DESCRIPTION: '개별 유효기간을 설정한 적립금을 제외한 적립금 유효기간에 적용됩니다.',
  MONTH_UNIT: '개월',
  NOTIFICATION_Y: '사용함',
  NOTIFICATION_N: '사용 안 함',
  BASE_ON_ACCUMULATION: '적립금 만료일 기준',
  SET_NOTIFICATION_CHANNELS: '적립금 만료 알림 설정 수단',
  NOTIFICATION_KAKAO: '카카오 알림톡',
  SMS: 'SMS',
  EMAIL: '이메일',

  SET: '설정함',
  SET_NOT: '설정 안 함',
  USE: '사용함',
  USE_NOT: '사용 안 함',

  PAY_TITLE: '적립금 지급 설정',
  PURCHASE: '구매 적립금',
  PRODUCT_PURCHASE: '상품 구매 적립금 지급',
  BASIC: '기본 적립',
  BASIC_RATE: '기본 적립률',
  MEMBER_PURCHASE: '회원 구매 적립금 지급',
  PURCHASE_BASE_AMOUNT: '구매 적립금 기준금액',
  PURCHASE_BASE_TIME: '구매 적립금 기준시점',
  SET_PRODUCT_NUMS: '상품 금액 적립 설정',
  LIMIT_POINT: '구매 시 적립 제한',
  IMPOSSIBLE_USE_POINT: '적립금 사용 시 적립불가',
  IMPOSSIBLE_USE_COUPON: '쿠폰 사용 시 적립불가',
  SIGNUP: '회원가입 적립금',
  REVIEW: '상품후기 적립금',
  BASIC_REVIEW: '일반후기',
  PHOTO_REVIEW: '포토후기',
  BIRTHDAY: '생일 적립금',
  NUMBER_PLACEHOLDER: '숫자만 입력하세요.',
  NUMBER_OVER: '최소 {min}에서 {max}까지만 설정 가능합니다.',
  MAX_NUMBER_OVER: '최대 사용 적립금은 기준금액의 100%까지 설정 가능합니다.',
  MAX_NUMBER_UNDER: '최대 사용 적립금은 기준금액의 1%부터 설정 가능합니다.',
  REVIEW_UNDER: '상품후기 글자수는 1자부터 설정 가능합니다.',
  REVIEW_OVER: '상품후기 글자수는 1,000자까지 설정 가능합니다.',
  MUST_MIN_PRICE: '10원 이상으로 입력해주세요.',
  MUST_UNIT: '적립금은 10원 단위로 입력 가능합니다.',
  LENGTH_PLACEHOLDER: '글자수를 입력하세요.',
  POINT_PLACEHOLDER: '적립금을 입력하세요.',
  PRODUCT_NUMS: '상품금액 적립 기준을',
  PRODUCT_NUMS_DESCRIPTION_1: '할인 적용가는 판매가에서 즉시 할인 적용된 금액입니다.',
  PRODUCT_NUMS_DESCRIPTION_2: '프로모션 할인은 상품 추가 할인과 상품 쿠폰 할인 금액입니다.',
  PRODUCT_NUMS_DESCRIPTION_3: '총 결제금액에서 해당 기준을 적용하는 것이 아니라,',
  PRODUCT_NUMS_DESCRIPTION_4: '결제한 상품별로 기준을 적용하여 적립금이 지급',
  PRODUCT_NUMS_DESCRIPTION_5: '되는 점 유의하시기 바랍니다.',

  OVER_N_WRITE: '자 이상 작성 시',
  POINT_WON: '원 적립',
  NUM_TYPE_ERR_1: '입력형식이 잘못되었습니다.',
  NUM_MAX_LIMIT_ERR: '999,999,999 이하로 입력해주세요.',
  BTN_SAVE: '적립금 설정 저장',
  BTN_TEMPORARY: '임시저장',

  SIGN_UP_TITLE: '회원가입적립금',
  SIGN_UP_DESCRIPTION_1: '회원가입 시 최초 1회 지급되는 적립금입니다.',
  SIGN_UP_DESCRIPTION_2: '회원가입완료 시 즉시 지급됩니다.',
  SIGN_UP_DESCRIPTION_3: '100 단위로 입력 가능합니다. ex) 100, 1000, 1100, 2500',

  REVIEWS_TITLE: '상품평 적립금',
  PRODUCT_REVIEW: '상품평 적립금',
  PHOTO_REVIEW_EVALUATION: '포토 상품평 적립금',
  REVIEW_DESCRIPTION_1: '상품평 작성 시 지급되는 적립금을 설정할 수 있습니다.',
  REVIEW_DESCRIPTION_2: '상품평 작성 시 즉시 제공됩니다.',
  REVIEW_DESCRIPTION_3: '100 단위로 입력 가능합니다. ex) [500]자 이상 [100]적립',
  REVIEW_DESCRIPTION_4:
    '상품평 적립금과 포토 상품평 적립금은 중복으로 지급되지 않습니다. (포토 상품평 적립금이 우선순위로 지급됨)',
  REVIEW_MORE: '자 이상',
  REVIEW_SAVING: '적립',

  BIRTHDAY_TITLE: '생일자 회원 적립금',
  BIRTHDAY_DESCRIPTION_1: '회원가입 이후 생일 날짜 기준 일년 1회 지급되는 적립금 입니다.',
  BIRTHDAY_DESCRIPTION_2: '년내 지정된 날짜기준 회원 아이디 1개당 1회 발송됩니다.',
  BIRTHDAY_DESCRIPTION_3: '100 단위로 입력 가능합니다. ex) 100, 1000, 1100, 2500',
  BIRTHDAY_DESCRIPTION_4: '해당일 기준 대상 회원에게 순차 발송됩니다. 참고해주시기 바랍니다.',
  BIRTHDAY_DESCRIPTION_5: '운영 중 변경된 경우 변경 이후 생일 날짜부터 적용됩니다.',

  APP_INSTALL_TITLE: '앱설치 적립금',
  APP_INSTALL_DESCRIPTION_1: '모바일앱 설치한 회원에게 지급되는 적립금 입니다.',
  APP_INSTALL_DESCRIPTION_2: '앱 삭제 후 재 설치한 회원인 경우 지급된 내용이 있으면 재 지급되지 않습니다.',
  APP_INSTALL_DESCRIPTION_3: '100 단위로 입력 가능합니다. ex) 100, 1000, 1100, 2500',
  APP_INSTALL_DESCRIPTION_4: '운영 중 변경된 경우 변경 이후 앱 설치 회원부터 변경된 사항으로 적용됩니다.',

  USE_SET_TITLE: '적립금 사용 설정',
  MIN_LIMIT_TITLE: '최소 기준금액 설정',
  LIMIT_PRICE_NOT: '제한없음',
  LIMIT_ACCUMULATION_NOT: '제한없음',
  LIMIT_PRICE_OF_NOT: '제한없음',
  LIMIT_PRICE: '기준금액',
  LIMIT_ACCUMULATION: '적립금',
  LIMIT_PRICE_OF: '기준금액의',
  PAY_TIME: '지급일 기준',
  VALIDITY_PERIOD: '까지 사용가능 (총 <strong>{0}</strong> 개월)',
  MIN_LIMIT_DESCRIPTION_1: '원 이상부터 사용 가능',
  MIN_LIMIT_DESCRIPTION_2: '% 까지 사용가능',

  MIN_PRICE_LIMIT_TITLE: '최소 사용 적립금 설정',
  MIN_PRICE_LIMIT_POINT_USE: '부터 적립금 사용 가능',
  MIN_PRICE_DESCRIPTION_1: '최소 누적 적립금을 설정한 경우, 설정된 적립금 이상인 경우 사용 가능합니다.',
  MIN_PRICE_DESCRIPTION_2: '적립금 입력은 100 단위로 가능합니다.',

  MAX_RATE_TITLE: '최대 사용 적립금 설정',
  MAX_RATE_BEFORE_TEXT: '결제금액 총합계 기준 최대',
  MAX_RATE_AFTER_TEXT: '사용가능',
  MAX_RATE_DESCRIPTION: '기본 적립률은 최대 100%까지 설정 가능합니다.',

  EXCLUDE_TITLE: '적립금 지급 제외',
  EXCLUDE_ACCUMULATION: '일부라도 적립금으로 결제하는상품은 적립금을 지급하지 않습니다.',
  EXCLUDE_COUPON: '일부라도 쿠폰 할인으로 결제하는 상품은 적립금을 지급하지 않습니다.',
  EXCLUDE_PRODUCT:
    '상품 구매 적립금을 사용 안 함으로 변경할 경우, 상품별 설정에 따른 구매 적립금이 지급되지 않습니다.\n설정을 변경하시겠습니까?',
  EXCLUDE_MEMBER:
    '회원 구매 적립금을 사용 안 함으로 변경할 경우, 상품별 설정에 따른 구매 적립금이 지급되지 않습니다.\n설정을 변경하시겠습니까?',

  MEMO_TITLE: '운영자 전달 메모',
  MEMO_NOTICE: '운영자 메모',

  FORMATE_TYPE_FIXED_AMT: '정액(KRW) 단일표시 (ex 200원)',
  FORMATE_TYPE_FIXED_RATE: '정률(%) 단일표시 (ex 2%)',
  FORMATE_TYPE_FIRST_FIXED_RATE: '정률(%)정액(KRW) 동시표시 (ex 2%/200원)',
  FORMATE_TYPE_FIRST_FIXED_AMT: '정액(KRW)정률(%) 동시표시 (ex 200원/2%)',

  IMMEDIATE: '즉시',
  NEXTDAY: '익일',
  DAY_AFTER_TOMMOROW: '2일',
  AFTER_A_WEEK: '7일',
  AFTER_TWO_WEEK: '14일',
  AFTER_TWENTY_DAYS: '20일',
  AFTER_THIRTY_DAYS: '30일',
  NEXT_MONTH: '익월',

  SALE_PRICE: '판매가',
  DISCOUNT_PRICE: '즉시할인금액',
  OPTION_PRICE: '옵션가',
  PROMOTION_PRICE: '상품쿠폰할인금액',

  ALERT_NOTICE_METHOD: '알림수단을 선택해주세요.',
  ALERT_BASIC_RATE: '구매 시 기본 적립률을 입력해주세요.',
  ALERT_SIGNUP: '회원가입 적립금을 입력해주세요.',
  ALERT_REVIEW_SIZE: '상품후기 적립금 글자수를 입력해주세요.',
  ALERT_REVIEW: '상품후기 적립금을 입력해주세요.',
  ALERT_BIRTHDAY: '생일 적립금을 입력해주세요.',
  ALERT_MIN_PRICE: '최소 기준금액을 입력해주세요.',
  ALERT_MIN_USE: '최소 사용 적립금을 입력해주세요.',
  ALERT_MAX_USE: '최대 사용 적립금을 입력해주세요.',

  CONFIRM_1: '작성한 내용을 취소하시겠습니까?',
  CONFIRM_2: '적립금 설정을 임시저장하시겠습니까?',
  CONFIRM_3: '적립금 설정을 완료하시겠습니까?',

  PLACEHOLDER_ENTER: '적립금 입력',
  PLACEHOLDER_ENTER_NUMBER: '글자수 입력',

  TOOLTIP_EXPIRE: `알림 발송 시점의 수단별 상태에 따라 알림이 발송되지 않을 수 있습니다.
                알림수단의 사용여부, 자동 발송항목의 사용여부에 따라 알림이 발송되지 않을 수 있습니다.
                SMS 선택 시, SMS 포인트가 부족할 경우 부족한 포인트만큼 일부 또는 전체 회원을 대상으로 알림 발송에 실패할 수 있습니다.
                적립금 소멸 안내는 정보성 안내에 해당하므로, 알림 발송 시 별도의 수신동의 없이 발송이 가능합니다.
                단, 템플릿 수정을 통해 포인트 사용을 촉구하는 문구 또는 상품/쇼핑몰에 대한 홍보문구를 추가하게 될 경우, 이는 광고성 안내에 해당되며
                수신동의 하지 않은 회원에게 알림이 발송 시 정보통신망법에 저촉될 수 있습니다.`,
  TOOLTIP_PAY: `구매 적립금은 구매 시 지급되는 적립금입니다.
                  상품별, 회원등급별로 구매 적립금 지급 시 적립률을 설정할 수 있으며, 상품/회원등급 구매 적립금은 중복으로 지급 가능합니다.`,
  TOOLTIP_PAY_PRODUCT: `상품별 구매적립금 지급 여부를 설정할 수 있습니다.
                  지급 설정을 ‘사용함’으로 설정 시 상품별 적립금 적립 설정에 따라 구매 적립금이 지급됩니다.
                  기본 적립률이란, 상품별 적립금을 설정하지 않은 경우 기본으로 적용되는 적립률을 의미합니다.
                  지급 설정을 ‘사용 안 함’으로 설정할 경우 상품별 설정에 따른 구매 적립금이 지급되지 않습니다.`,
  TOOLTIP_PAY_GRADE: `회원등급별 구매적립금 지급 여부를 설정할 수 있습니다.
                  지급 설정을 ‘사용함’으로 설정 시 회원등급별 적립금 적립 설정에 따라 구매 적립금이 지급됩니다.
                  지급 설정을 ‘사용 안 함’으로 설정할 경우 회원등급별 설정에 따른 구매 적립금이 지급되지 않습니다.`,
  TOOLTIP_PAY_POINT: `운영 중 지급시점을 변경하는 경우, 변경 이후 주문 건부터 설정한 내용이 적용됩니다.
                지급시점을 단기로 설정할 경우, 지급된 적립금을 사용 후 주문한 상품을 반품하는 등 악용사례가 발생할 수 있으니 유의하시기 바랍니다.`,
  TOOLTIP_JOIN: `신규 회원가입 시 지급되는 적립금입니다.
                회원가입 적립금은 회원가입 즉시 지급됩니다.`,
  TOOLTIP_REVIEW: `상품후기 작성 시 지급되는 적립금입니다.
                상품후기 적립금은 상품후기 작성 즉시 지급됩니다.
                상품후기 작성시점에 적립금 지급조건을 달성하지 못했더라도, 수정 시점에 조건을 달성한 경우 적립금이 지급됩니다. (1회만 지급)
                일반후기 적립금과 포토후기 적립금은 중복으로 지급되지 않습니다.
                (일반후기, 포토후기 적립금 지급 조건을 모두 만족한 경우 포토후기 적립금 지급)`,
  TOOLTIP_BIRTH: `회원의 생일 당일에 지급되는 적립금입니다.
                ID당 1년에 1회만 지급되며, 회원정보 수정을 통해 생일정보를 변경하더라도 동일년도에 생일 적립금을 지급한 회원에게는 지급되지 않습니다.`,
  TOOLTIP_MIN: `구매금액이 기준금액 이상일 경우에 적립금 사용이 가능합니다.
                구매금액은 상품/쿠폰 할인이 적용되고, 배송비가 포함된 최종 결제 금액을 의미합니다.`,
});
