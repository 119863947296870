import { productGuideQuery } from '@/types/productAdd';
import { OptionData } from '@/helpers/type';
import { ApplyStatusType } from '@/components/product/basic/SearchOptionTypes';
import VueI18n from 'vue-i18n';

export const OPTION_TYPE = {
  STANDARD: 'STANDARD',
  COMBINATION: 'COMBINATION',
  DEFAULT: 'DEFAULT',
  MAPPING: 'MAPPING',
} as const;

export const GUIDE_TYPE = {
  DELIVERY: 'DELIVERY',
  AFTER_SERVICE: 'AFTER_SERVICE',
  REFUND: 'REFUND',
  EXCHANGE: 'EXCHANGE',
} as const;

export const CONTENT_USE_TYPE = {
  USE: 'USE',
  NOT_USE: 'NOT_USE',
  OPTION_IMAGE: 'OPTION_IMAGE',
  DIRECT: 'DIRECT',
  USE_DEFAULT_TEMPLATE: 'USE_DEFAULT_TEMPLATE',
} as const;

export const CONTENT_TYPE = {
  HEADER: 'HEADER',
  BODY: 'BODY',
  FOOTER: 'FOOTER',
} as const;

export const IMAGE_CONTENT = {
  [CONTENT_TYPE.HEADER]: 'contentHeader',
  [CONTENT_TYPE.BODY]: 'content',
  [CONTENT_TYPE.FOOTER]: 'contentFooter',
};

export const CONFIG_TYPE = {
  NOT_USED: 'NOT_USED',
  DISPLAY_CATEGORY: 'DISPLAY_CATEGORY',
  SELECTED: 'SELECTED',
} as const;

export const SORT_CRITERION = {
  LATEST_REGISTER_DATE: 'LATEST_REGISTER_DATE',
  SALES_COUNT: 'SALES_COUNT',
  REVIEW_COUNT: 'REVIEW_COUNT',
  CUSTOM_ORDER: 'CUSTOM_ORDER',
} as const;

export const getDefaultGuideInfo = (guideInfo: Partial<productGuideQuery>): productGuideQuery => {
  return Object.assign(
    {
      partnerNo: 0,
      isPartnerShipment: false,
      type: GUIDE_TYPE.DELIVERY,
      content: '',
    },
    guideInfo,
  );
};

export const getProductAddSelectOption = (): { displayOrder: OptionData<keyof typeof SORT_CRITERION>[] } => {
  return {
    displayOrder: [
      {
        name: 'PRODUCT.COMMON.LATEST_REGISTER_DATE', // 최신 등록순
        value: SORT_CRITERION.LATEST_REGISTER_DATE,
      },
      {
        name: 'PRODUCT.COMMON.SALES_COUNT', // 판매량순
        value: SORT_CRITERION.SALES_COUNT,
      },
      // { NOTE: 오픈 스펙에서 제외
      //   name: 'PRODUCT.COMMON.BY_VTR', // 조회순
      //   value: 'R',
      // },
      {
        name: 'PRODUCT.COMMON.REVIEW_COUNT', // 상품 후기순
        value: SORT_CRITERION.REVIEW_COUNT,
      },
    ],
  };
};

export const IMMUTABLE_TYPE = {
  COPY: 'COPY',
  UPDATE: 'UPDATE',
  PROHIBITION_SALE: 'PROHIBITION_SALE',
  REGISTRATION_READY: 'REGISTRATION_READY',
  COPY_WITH_STOCK: 'COPY_WITH_STOCK',
  SYNC: 'SYNC', // 재고연동
  NOT_SAME_MALL: 'NOT_SAME_MALL',
} as const;

export const IMMUTABLE_FIELD = {
  productStockCnt: 'productStockCnt',
  reservationStockCnt: 'reservationStockCnt',
  mallNo: 'mallNo',
  partnerNo: 'partnerNo',
  saleMethodType: 'saleMethodType',
  adminNo: 'adminNo',
  categoryNo: 'categoryNo',
  displayCategoryNos: 'displayCategoryNos',
  platformDisplayInfo: 'platformDisplayInfo',
  memberGradeDisplayInfo: 'memberGradeDisplayInfo',
  promotionTextInfo: 'promotionTextInfo',
  brandNo: 'brandNo',
  minorPurchaseYn: 'minorPurchaseYn',
  deliveryYn: 'deliveryYn',
  deliveryInternationalYn: 'deliveryInternationalYn',
  optionUseYn: 'optionUseYn',
  productManagementCd: 'productManagementCd',
} as const;

export const getImmutableFields = (type: keyof typeof IMMUTABLE_TYPE): string[] => {
  const field = {
    [IMMUTABLE_TYPE.COPY]: ['mallNo'],
    [IMMUTABLE_TYPE.COPY_WITH_STOCK]: ['productStockCnt', 'reservationStockCnt'],
    [IMMUTABLE_TYPE.UPDATE]: ['saleMethodType'],
  };
  return field[type];
};

export const PRODUCT_FORM_ERROR_TYPE = {
  INVALID_MALL_NO: 'INVALID_MALL_NO',
  INVALID_SALE_METHOD_TYPE: 'INVALID_SALE_METHOD_TYPE',
  INVALID_MALL_PRODUCT_NO: 'INVALID_MALL_PRODUCT_NO',
  NON_EDITABLE_PRODUCT: 'NON_EDITABLE_PRODUCT',
  NON_EDITABLE_MALL_PRODUCT_NO: 'NON_EDITABLE_MALL_PRODUCT_NO',
  INVALID_PARENT_PRODUCT_NO: 'INVALID_PARENT_PRODUCT_NO',
  INVALID_PARTNER_NO: 'INVALID_PARTNER_NO',
  INVALID_ADMIN_NO: 'INVALID_ADMIN_NO',
  INVALID_CATEGORY: 'INVALID_CATEGORY',
  INVALID_DISPLAY_CATEGORY: 'INVALID_DISPLAY_CATEGORY',
  INVALID_URL_DIRECT_DISPLAY_YN: 'INVALID_URL_DIRECT_DISPLAY_YN',
  INVALID_PLATFORM_DISPLAY: 'INVALID_PLATFORM_DISPLAY',
  INVALID_SEARCH_ENGINE_DISPLAY_YN: 'INVALID_SEARCH_ENGINE_DISPLAY_YN',
  INVALID_MEMBER_GRADE_INFO: 'INVALID_MEMBER_GRADE_INFO',
  INVALID_MEMBER_GRADE_DISPLAY_YN: 'INVALID_MEMBER_GRADE_DISPLAY_YN',
  INVALID_ALL_MEMBER_GRADE_DISPLAY_YN: 'INVALID_ALL_MEMBER_GRADE_DISPLAY_YN',
  INVALID_MEMBER_GRADE: 'INVALID_MEMBER_GRADE',
  INVALID_MEMBER_GROUP_DISPLAY_YN: 'INVALID_MEMBER_GROUP_DISPLAY_YN',
  INVALID_ALL_MEMBER_GROUP_DISPLAY_YN: 'INVALID_ALL_MEMBER_GROUP_DISPLAY_YN',
  INVALID_PRODUCT_NAME_EMPTY: 'INVALID_PRODUCT_NAME_EMPTY',
  INVALID_PRODUCT_NAME_NOT_ALLOWED_EMOJI: 'INVALID_PRODUCT_NAME_NOT_ALLOWED_EMOJI',
  INVALID_PRODUCT_NAME_LENGTH: 'INVALID_PRODUCT_NAME_LENGTH',
  INVALID_BRAND_NAME_LENGTH: 'INVALID_BRAND_NAME_LENGTH',
  INVALID_PROMOTION_TEXT_USE_YN: 'INVALID_PROMOTION_TEXT_USE_YN',
  INVALID_PROMOTION_TEXT_PERIOD: 'INVALID_PROMOTION_TEXT_PERIOD',
  NON_EDITABLE_PROMOTION_TEXT_INFO: 'NON_EDITABLE_PROMOTION_TEXT_INFO',
  INVALID_PROMOTION_TEXT: 'INVALID_PROMOTION_TEXT',
  INVALID_PROMOTION_TEXT_LENGTH: 'INVALID_PROMOTION_TEXT_LENGTH',
  INVALID_MINOR_PURCHASE_YN: 'INVALID_MINOR_PURCHASE_YN',
  INVALID_PAYMENT_CONTROL_YN: 'INVALID_PAYMENT_CONTROL_YN',
  INVALID_PAYMENT_MEANS: 'INVALID_PAYMENT_MEANS',
  INVALID_MINOR_PURCHASE_YN_NONMEMBER_PURCHASE_YN: 'INVALID_MINOR_PURCHASE_YN_NONMEMBER_PURCHASE_YN',
  INVALID_KEYWORD_LENGTH: 'INVALID_KEYWORD_LENGTH',
  INVALID_KEYWORD_COUNT: 'INVALID_KEYWORD_COUNT',
  INVALID_RESERVATION_STOCK_COUNT: 'INVALID_RESERVATION_STOCK_COUNT',
  INVALID_RESERVATION_USE_YN: 'INVALID_RESERVATION_USE_YN',
  INVALID_RESERVATION_INFO: 'INVALID_RESERVATION_INFO',
  INVALID_RESERVATION_PERIOD: 'INVALID_RESERVATION_PERIOD',
  INVALID_RESERVATION_PERIOD_AFTER_SALE_START_DATE: 'INVALID_RESERVATION_PERIOD_AFTER_SALE_START_DATE',
  INVALID_RESERVATION_DELIVERY_DATE: 'INVALID_RESERVATION_DELIVERY_DATE',
  INVALID_SALE_PERIOD_TYPE: 'INVALID_SALE_PERIOD_TYPE',
  INVALID_SALE_PERIOD: 'INVALID_SALE_PERIOD',
  INVALID_COMMISSION_INFO: 'INVALID_COMMISSION_INFO',
  INVALID_COMMISSION_RATE_TYPE: 'INVALID_COMMISSION_RATE_TYPE',
  INVALID_COMMISSION_RATE: 'INVALID_COMMISSION_RATE',
  INVALID_COMMISSION_RATE_PURCHASE_PRICE: 'INVALID_COMMISSION_RATE_PURCHASE_PRICE',
  INVALID_SALE_PRICE_RANGE: 'INVALID_SALE_PRICE_RANGE',
  INVALID_SALE_PRICE_UNIT: 'INVALID_SALE_PRICE_UNIT',
  INVALID_UNIT_PRICE_YN: 'INVALID_UNIT_PRICE_YN',
  INVALID_UNIT_NAME_TYPE: 'INVALID_UNIT_NAME_TYPE',
  INVALID_UNIT_PRICE: 'INVALID_UNIT_PRICE',
  INVALID_UNIT_NAME: 'INVALID_UNIT_NAME',
  INVALID_EVENT_PRODUCT_SALE_PRICE_MUST_BE_ZERO: 'INVALID_EVENT_PRODUCT_SALE_PRICE_MUST_BE_ZERO',
  INVALID_IMMEDIATE_DISCOUNT_INFO: 'INVALID_IMMEDIATE_DISCOUNT_INFO',
  INVALID_IMMEDIATE_DISCOUNT_UNIT_TYPE: 'INVALID_IMMEDIATE_DISCOUNT_UNIT_TYPE',
  INVALID_IMMEDIATE_DISCOUNT_AMOUNT_UNIT: 'INVALID_IMMEDIATE_DISCOUNT_AMOUNT_UNIT',
  INVALID_IMMEDIATE_DISCOUNT_AMOUNT_RANGE: 'INVALID_IMMEDIATE_DISCOUNT_AMOUNT_RANGE',
  INVALID_IMMEDIATE_DISCOUNT_RATE_RANGE: 'INVALID_IMMEDIATE_DISCOUNT_RATE_RANGE',
  INVALID_IMMEDIATE_DISCOUNT_PERIOD: 'INVALID_IMMEDIATE_DISCOUNT_PERIOD',
  INVALID_IMMEDIATE_DISCOUNT_PERIOD_WITHIN_SALE_PERIOD: 'INVALID_IMMEDIATE_DISCOUNT_PERIOD_WITHIN_SALE_PERIOD',
  INVALID_ACCUMULATION_RATE: 'INVALID_ACCUMULATION_RATE',
  INVALID_ACCUMULATION_USE_YN: 'INVALID_ACCUMULATION_USE_YN',
  INVALID_STOCK_COUNT: 'INVALID_STOCK_COUNT',
  INVALID_STOCK_COUNT_MISMATCH_OPTION: 'INVALID_STOCK_COUNT_MISMATCH_OPTION',
  INVALID_STOCK_COUNT_RANGE: 'INVALID_STOCK_COUNT_RANGE',
  INVALID_OPTION_IS_EMPTY: 'INVALID_OPTION_IS_EMPTY',
  INVALID_OPTION_IMAGE_EMPTY: 'INVALID_OPTION_IMAGE_EMPTY',
  INVALID_COMPARING_PRICE_SITE_YN: 'INVALID_COMPARING_PRICE_SITE_YN',
  INVALID_COMPARING_PRICE_SITE: 'INVALID_COMPARING_PRICE_SITE',
  INVALID_COMPARING_PRICE_SITE_ALL: 'INVALID_COMPARING_PRICE_SITE_ALL',
  INVALID_COMPARING_PRICE_SITE_EMPTY: 'INVALID_COMPARING_PRICE_SITE_EMPTY',
  INVALID_DUTY_INFO: 'INVALID_DUTY_INFO',
  INVALID_CERTIFICATION_INFO: 'INVALID_CERTIFICATION_INFO',
  INVALID_CERTIFICATION_TYPE: 'INVALID_CERTIFICATION_TYPE',
  INVALID_CERTIFICATION_JSON: 'INVALID_CERTIFICATION_JSON',
  INVALID_PLACE_ORIGIN_INFO: 'INVALID_PLACE_ORIGIN_INFO',
  INVALID_PLACE_ORIGIN_SEQ: 'INVALID_PLACE_ORIGIN_SEQ',
  INVALID_PLACE_ORIGIN_IMPORTER: 'INVALID_PLACE_ORIGIN_IMPORTER',
  INVALID_PLACE_ORIGIN_DIRECT_INPUT: 'INVALID_PLACE_ORIGIN_DIRECT_INPUT',
  INVALID_VALUE_ADDED_TAX_TYPE: 'INVALID_VALUE_ADDED_TAX_TYPE',
  INVALID_PRODUCT_MANAGEMENT_CD: 'INVALID_PRODUCT_MANAGEMENT_CD',
  INVALID_HSCODE: 'INVALID_HSCODE',
  INVALID_EANCODE: 'INVALID_EANCODE',
  INVALID_REFUNDABLE_YN: 'INVALID_REFUNDABLE_YN',
  INVALID_IMAGE_MAIN: 'INVALID_IMAGE_MAIN',
  INVALID_IMAGE_ADDITIONAL_COUNT: 'INVALID_IMAGE_ADDITIONAL_COUNT',
  INVALID_IMAGE_URL: 'INVALID_IMAGE_URL',
  INVALID_LIST_IMAGE_URL: 'INVALID_LIST_IMAGE_URL',
  INVALID_ADD_OPTION_IMAGE_YN: 'INVALID_ADD_OPTION_IMAGE_YN',
  INVALID_CONTENT: 'INVALID_CONTENT',
  INVALID_DELIVERY_YN: 'INVALID_DELIVERY_YN',
  INVALID_SHIPPING_AREA_TYPE: 'INVALID_SHIPPING_AREA_TYPE',
  INVALID_DELIVERY_COMBINATION_YN: 'INVALID_DELIVERY_COMBINATION_YN',
  INVALID_DELIVERY_INTERNATIONAL_YN: 'INVALID_DELIVERY_INTERNATIONAL_YN',
  INVALID_DELIVERY_TEMPLATE_NO: 'INVALID_DELIVERY_TEMPLATE_NO',
  SYNC_PRODUCT_CANNOT_CHANGE_OPTION: 'SYNC_PRODUCT_CANNOT_CHANGE_OPTION',
  INVALID_CUSTOM_PROPERTY_VALUE: 'INVALID_CUSTOM_PROPERTY_VALUE',
  NON_EDITABLE_DELIVERY_TEMPLATE_NO: 'NON_EDITABLE_DELIVERY_TEMPLATE_NO',
  NON_EDITABLE_MALL_NO: 'NON_EDITABLE_MALL_NO',
  NON_EDITABLE_GROUP_TYPE: 'NON_EDITABLE_GROUP_TYPE',
  NON_EDITABLE_CLASS_TYPE: 'NON_EDITABLE_CLASS_TYPE',
  NON_EDITABLE_PARTNER_NO: 'NON_EDITABLE_PARTNER_NO',
  NON_EDITABLE_SALE_METHOD_TYPE: 'NON_EDITABLE_SALE_METHOD_TYPE',
  NON_EDITABLE_ADMIN_NO: 'NON_EDITABLE_ADMIN_NO',
  NON_EDITABLE_CATEGORY_NO: 'NON_EDITABLE_CATEGORY_NO',
  NON_EDITABLE_URL_DIRECT_DISPLAY_YN: 'NON_EDITABLE_URL_DIRECT_DISPLAY_YN',
  NON_EDITABLE_PLATFORM_DISPLAY_INFO: 'NON_EDITABLE_PLATFORM_DISPLAY_INFO',
  NON_EDITABLE_SEARCHENGINE_DISPLAY_YN: 'NON_EDITABLE_SEARCHENGINE_DISPLAY_YN',
  NON_EDITABLE_MEMBER_GRADE_DISPLAY_INFO: 'NON_EDITABLE_MEMBER_GRADE_DISPLAY_INFO',
  NON_EDITABLE_MEMBER_GROUP_DISPLAY_INFO: 'NON_EDITABLE_MEMBER_GROUP_DISPLAY_INFO',
  NON_EDITABLE_PRODUCT_NAME: 'NON_EDITABLE_PRODUCT_NAME',
  NON_EDITABLE_PRODUCT_NAME_EN: 'NON_EDITABLE_PRODUCT_NAME_EN',
  NON_EDITABLE_BRAND: 'NON_EDITABLE_BRAND',
  NON_EDITABLE_MINOR_PURCHASE_YN: 'NON_EDITABLE_MINOR_PURCHASE_YN',
  NON_EDITABLE_PAYMENT_MEANS: 'NON_EDITABLE_PAYMENT_MEANS',
  NON_EDITABLE_NONMEMBER_PURCHASE_YN: 'NON_EDITABLE_NONMEMBER_PURCHASE_YN',
  NON_EDITABLE_KEYWORDS: 'NON_EDITABLE_KEYWORDS',
  NON_EDITABLE_FRONT_DISPLAY_YN: 'NON_EDITABLE_FRONT_DISPLAY_YN',
  NON_EDITABLE_SALE_PERIOD_INFO: 'NON_EDITABLE_SALE_PERIOD_INFO',
  NON_EDITABLE_COMMISSION_INFO: 'NON_EDITABLE_COMMISSION_INFO',
  NON_EDITABLE_SALE_PRICE: 'NON_EDITABLE_SALE_PRICE',
  NON_EDITABLE_UNIT_PRICE_INFO: 'NON_EDITABLE_UNIT_PRICE_INFO',
  NON_EDITABLE_IMMEDIATE_DISCOUNT_INFO: 'NON_EDITABLE_IMMEDIATE_DISCOUNT_INFO',
  NON_EDITABLE_ACCUMULATION_RATE: 'NON_EDITABLE_ACCUMULATION_RATE',
  NON_EDITABLE_PARTNER_CHARGE_AMT: 'NON_EDITABLE_PARTNER_CHARGE_AMT',
  NON_EDITABLE_ACCUMULATION_USE_YN: 'NON_EDITABLE_ACCUMULATION_USE_YN',
  NON_EDITABLE_MAX_BUY_COUNT_INFO: 'NON_EDITABLE_MAX_BUY_COUNT_INFO',
  NON_EDITABLE_COMPARING_PRICE_SITE_INFO: 'NON_EDITABLE_COMPARING_PRICE_SITE_INFO',
  NON_EDITABLE_DUTY_CONTENT: 'NON_EDITABLE_DUTY_CONTENT',
  NON_EDITABLE_CERTIFICATION_INFO: 'NON_EDITABLE_CERTIFICATION_INFO',
  NON_EDITABLE_PLACE_ORIGIN_INFO: 'NON_EDITABLE_PLACE_ORIGIN_INFO',
  NON_EDITABLE_MANUFACTURE_YMDT: 'NON_EDITABLE_MANUFACTURE_YMDT',
  NON_EDITABLE_EXPIRATION_YMDT: 'NON_EDITABLE_EXPIRATION_YMDT',
  NON_EDITABLE_VALUE_ADDED_TAX_TYPE: 'NON_EDITABLE_VALUE_ADDED_TAX_TYPE',
  NON_EDITABLE_PRODUCT_MANAGEMENT_CD: 'NON_EDITABLE_PRODUCT_MANAGEMENT_CD',
  NON_EDITABLE_HS_CODE: 'NON_EDITABLE_HS_CODE',
  NON_EDITABLE_EAN_CODE: 'NON_EDITABLE_EAN_CODE',
  NON_EDITABLE_CONTENT_HEADER: 'NON_EDITABLE_CONTENT_HEADER',
  NON_EDITABLE_CONTENT: 'NON_EDITABLE_CONTENT',
  NON_EDITABLE_CONTENT_FOOTER: 'NON_EDITABLE_CONTENT_FOOTER',
  NON_EDITABLE_DELIVERY_YN: 'NON_EDITABLE_DELIVERY_YN',
  NON_EDITABLE_SHIPPING_AREA_TYPE: 'NON_EDITABLE_SHIPPING_AREA_TYPE',
  NON_EDITABLE_DELIVERY_COMBINATION_YN: 'NON_EDITABLE_DELIVERY_COMBINATION_YN',
  NON_EDITABLE_DELIVERY_INTERNATIONAL_YN: 'NON_EDITABLE_DELIVERY_INTERNATIONAL_YN',
  NON_EDITABLE_DELIVERY_CUSTOMER_INFO: 'NON_EDITABLE_DELIVERY_CUSTOMER_INFO',
  NON_EDITABLE_PRODUCT_TYPE: 'NON_EDITABLE_PRODUCT_TYPE',
  NON_EDITABLE_DISPLAY_CATEGORY_NOS: 'NON_EDITABLE_DISPLAY_CATEGORY_NOS',
  NON_EDITABLE_MALL_PRODUCT_IMAGES: 'NON_EDITABLE_MALL_PRODUCT_IMAGES',
  NON_EDITABLE_MALL_PRODUCT_LIST_IMAGE: 'NON_EDITABLE_MALL_PRODUCT_LIST_IMAGE',
  NON_EDITABLE_RESERVATION_INFO: 'NON_EDITABLE_RESERVATION_INFO',
  NON_EDITABLE_STICKER_NOS: 'NON_EDITABLE_STICKER_NOS',
  INVALID_MIN_BUY_CNT_OVER_MAX_BUY_PERSON_CNT: 'INVALID_MIN_BUY_CNT_OVER_MAX_BUY_PERSON_CNT',
  INVALID_MIN_BUY_CNT_OVER_MAX_BUY_TIME_CNT: 'INVALID_MIN_BUY_CNT_OVER_MAX_BUY_TIME_CNT',
  INVALID_MAX_BUY_PERIOD_CNT: 'INVALID_MAX_BUY_PERIOD_CNT',
  INVALID_MAX_BUY_DAYS: 'INVALID_MAX_BUY_DAYS',
  INVALID_MIN_BUY_CNT_OVER_MAX_BUY_PERIOD_CNT: 'INVALID_MIN_BUY_CNT_OVER_MAX_BUY_PERIOD_CNT',
} as const;

export const OPTION_FORM_ERROR_TYPE = {
  INVALID_OPTION_NAME: 'INVALID_OPTION_NAME',
  INVALID_OPTION_VALUE: 'INVALID_OPTION_VALUE',
  INVALID_OPTION_DISPLAY_ORDER: 'INVALID_OPTION_DISPLAY_ORDER',
  INVALID_OPTION_ADD_PRICE: 'INVALID_OPTION_ADD_PRICE',
  INVALID_OPTION_USE_YN: 'INVALID_OPTION_USE_YN',
  INVALID_OPTION_TYPE: 'INVALID_OPTION_TYPE',
  STANDARD_OPTION_ADD_PRICE_MUST_BE_ZERO: 'STANDARD_OPTION_ADD_PRICE_MUST_BE_ZERO',
  INVALID_OPTION_ADD_PRICE_UNIT: 'INVALID_OPTION_ADD_PRICE_UNIT',
  INVALID_OPTION_ADD_PRICE_COMPARE_PRICE: 'INVALID_OPTION_ADD_PRICE_COMPARE_PRICE',
  INVALID_OPTION_NAME_COUNT: 'INVALID_OPTION_NAME_COUNT',
  INVALID_OPTION_NAME_SIZE: 'INVALID_OPTION_NAME_SIZE',
  INVALID_OPTION_VALUE_COUNT: 'INVALID_OPTION_VALUE_COUNT',
  INVALID_OPTION_VALUE_SIZE: 'INVALID_OPTION_VALUE_SIZE',
  INVALID_OPTION_MANAGEMENT_CD_ALLOW_COMBINATION: 'INVALID_OPTION_MANAGEMENT_CD_ALLOW_COMBINATION',
  INVALID_OPTION_MANAGEMENT_CD_LENGTH: 'INVALID_OPTION_MANAGEMENT_CD_LENGTH',
  OPTION_MUST_HAVE_USE_YN_Y: 'OPTION_MUST_HAVE_USE_YN_Y',
  INVALID_OPTION_DETAIL_IMAGE_COUNT: 'INVALID_OPTION_DETAIL_IMAGE_COUNT',
  INVALID_MAX_BUY_PERIOD_CNT: 'INVALID_MAX_BUY_PERIOD_CNT',
} as const;

export const DELETE_ERROR = 'delete-error';

export const MARKETING_TYPE = {
  NAVER_SHOPPING: 'NAVER_SHOPPING',
  GOOGLE: 'GOOGLE',
} as const;

export const NAVER_PRODUCT_SALE_TYPE = {
  NONE: 'NONE',
  WHOLESALE: 'WHOLESALE',
  RENTAL: 'RENTAL',
  LENT: 'LENT',
  INSTALLMENT: 'INSTALLMENT',
  RESERVATION_SALE: 'RESERVATION_SALE',
  SUBSTITUTION: 'SUBSTITUTION',
  RESERVATION_BUY: 'RESERVATION_BUY',
} as const;

export const NAVER_PRODUCT_STATUS = {
  NEW: 'NEW',
  USED: 'USED',
  REFURB: 'REFURB',
  DISPLAY: 'DISPLAY',
  RETURN: 'RETURN',
  SCRATCH: 'SCRATCH',
} as const;

export const NAVER_EXTRA = {
  isCrossBorderShipping: 'CROSS_BORDER_SHIPPING',
  isParallelImport: 'PARALLEL_IMPORT',
  isCustomOrder: 'CUSTOM_ORDER',
  hasExtraCharge: 'EXTRA_CHARGE',
} as const;

export enum DUTY_INFO_KEY {
  WILL_DUTY_CONTENT = 'willDutyContent',
  DUTY_CONTENT = 'dutyContent',
}

//등록대기,승인대기,승인거부,판매합의대기,판매합의거부
export const BEFORE_APPROVAL_STATUS: ApplyStatusType[] = [
  'REGISTRATION_READY',
  'APPROVAL_READY',
  'APPROVAL_REJECTION',
  'SALE_AGREEMENT_READY',
  'SALE_AGREEMENT_REJECTION',
];

//상품공시정보, 인증정보, 옵션버튼 아이디 : 변경하지 말 것.
export const APPROVAL_ITEMS_BTN_ID = {
  dutyContent: 'dutyContent',
  inspectionDutyContent: 'inspectionDutyContent',
  certificationInfo: 'certificationInfo',
  inspectionCertificationInfo: 'inspectionCertificationInfo',
  productOptionInfo: 'productOptionInfo',
  inspectionProductOptionInfo: 'inspectionProductOptionInfo',
};

export interface JudegementData {
  check: boolean;
  reason: string;
  name: VueI18n.TranslateResult;
  ref?: string;
}

export interface JudegementInfo {
  [key: string]: JudegementData;
}

export const getCheckKeysBy = (object: JudegementInfo) => {
  return Object.keys(object).filter(obj => object[obj].check);
};
