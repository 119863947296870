










import { Component, Vue } from 'vue-property-decorator';
import AppModal from '@/components/popup/app/AppModal.vue';

@Component({
  components: { AppModal },
})
export default class AppDetail extends Vue {}
