export default Object.freeze({
  // BOARD_LIST_
  FAQ_CATEGORY: 'FAQ 카테고리',
  SEARCH_WORD: '검색어',
  ENTER_SEARCH: '검색어 입력',
  CREATION_DATE: '작성일시',
  TODAY: '오늘',
  ALL: '전체',
  MEMBER: '회원',
  PRODUCT: '상품',
  DELIVERY: '배송',
  ORDER: '주문',
  TITLE: '제목',
  CONTENTS: '내용',
  '1WEEK': '1주일',
  '1MONTH': '1개월',
  '3MONTHS': '3개월',
  '6MONTHS': '6개월',
  '1YEAR': '1년',
  POST_NUMBER: '게시글 번호',
  CLOSE: '닫기',
  BOARD_LIST_TITLE: '게시판 관리',
});
