// 상품 리스트 & 대량 상품 등록에서 공통으로 사용하는 상품 정보 디스플레이 관련 로직을 정의합니다.
import { OptColumn } from '@/types';
import { productNameConvertor } from '@/utils/common';
import { emptyValueFormatter, getAnchorHTML, periodFormatter } from '@/utils/grid/formatterUtils';
import { formatCurrency } from '@/utils/numberFormat';
import { ApplyStatusType, SALE_METHOD_TYPES, SaleMethodType } from '@/components/product/basic/SearchOptionTypes';
import { getSupplyPriceColumn } from '@/components/product/common/girdColumns/ProductSupplyPrice';
import { i18n } from '@/main';
import { Row } from '@/types/tui-grid';
import { DisplayCategoryDetailOpenerRenderer } from '@/utils/grid/rendererUtils';
import { BEFORE_APPROVAL_STATUS } from '@/const/contents/product';

// 쇼핑몰
export const getMallNameColumn = (): OptColumn => ({
  header: 'MALL',
  name: 'mallName',
  align: 'center',
  minWidth: 80,
});

// 상품번호
export const getProductNoColumn = (name: 'mallProductNo' | 'productNo' = 'mallProductNo'): OptColumn => ({
  header: 'PRODUCT.NUMBER',
  name,
  align: 'center',
  minWidth: 110,
  formatter: ({ row }) => {
    return getAnchorHTML(row[name].toString());
  },
});

// 상품명
export const getProductNameColumn = (useConvertor = true): OptColumn => ({
  header: 'PRODUCT.NAME',
  name: 'productName',
  align: 'left',
  minWidth: 200,
  formatter: ({ value, row }): string => {
    if (!useConvertor) return value.toString();
    return productNameConvertor(value, row);
  },
});

// 상품관리코드
export const getProductManagementCdColumn = (
  name: 'managementCode' | 'productManagementCd' = 'productManagementCd',
): OptColumn => ({
  header: 'PRODUCT.PRODUCT_MANAGEMENT_CODE',
  name,
  align: 'center',
  minWidth: 120,
  formatter: ({ value }) => emptyValueFormatter(value),
});

// 판매상태
export const getSaleStatusColumn = (name: 'saleStatus' | 'saleStatusType' = 'saleStatusType'): OptColumn => ({
  header: 'PRODUCT.STATUS.SALE.STATUS',
  name,
  align: 'center',
  minWidth: 100,
  formatter: ({ value, row }): string => {
    if (BEFORE_APPROVAL_STATUS.includes(row.applyStatusType as ApplyStatusType)) {
      return '-';
    }
    const applyStatus = i18n.t(`PRODUCT.STATUS.SALE.${value}`).toString();

    if (name !== 'saleStatus' && value === 'ON_SALE' && row.salePeriod['periodType']) {
      const period = i18n.t(`PRODUCT.STATUS.SALE.${row.salePeriod['periodType']}`).toString();
      return applyStatus + `(${period})`;
    }

    return applyStatus;
  },
});

const getPeriodForDeliveryTemplate = (row: Row): string => {
  const isPeriod = row.saleStartDate && row.saleEndDate;
  if (!isPeriod) return '-';
  const start = row.saleStartDate.toString().split(' ')[0];
  const end = row.saleEndDate.toString().split(' ')[0];
  return `${start} ~ ${end}`;
};

//판매기간
export const getSalePeriodColumn = (minWidth = 250, type = ''): OptColumn => ({
  header: 'PRODUCT.SALE_PERIOD',
  name: 'salePeriod',
  align: 'center',
  minWidth,
  formatter: ({ row }: any): string => {
    if (type === 'salePeriodForDeliveryTemplate') {
      return getPeriodForDeliveryTemplate(row);
    }
    const isPeriod = row.salePeriod && row.salePeriod.startYmdt && row.salePeriod.endYmdt;
    return isPeriod ? periodFormatter(row.salePeriod) : '-';
  },
});

//전시카테고리
export const getDisplayCategoryNamesColumn = (minWidth = 300): OptColumn => ({
  header: 'PRODUCT.DISPLAY_CATEGORY',
  name: 'displayCategoryNames',
  align: 'left',
  minWidth,
  // TODO: 추후 개선
  renderer: {
    type: DisplayCategoryDetailOpenerRenderer,
  },
});

//판매가
export const getSalePriceColumn = (minWidth = 80): OptColumn => ({
  header: 'PRODUCT.SALE_PRICE',
  name: 'salePrice',
  align: 'center',
  minWidth,
  formatter: ({ value }) => formatCurrency(value as number),
});

//즉시할인
export const getImmediateDiscountAmountColumn = (minWidth = 80): OptColumn => ({
  header: 'PRODUCT.IMMEDIATE_DISCOUNT',
  name: 'immediateDiscountInfo',
  align: 'center',
  minWidth,
  formatter: ({ value }: any) => {
    const percent = value?.unitType === 'PERCENT';
    const currency = formatCurrency(value.amount as number);
    return percent ? currency + '%' : currency;
  },
});

//즉시할인가
export const getAppliedImmediateDiscountPriceColumn = (minWidth = 80): OptColumn => ({
  header: 'PRODUCT.IMMEDIATE_DISCOUNT_PRICE',
  name: 'appliedImmediateDiscountPrice',
  align: 'center',
  minWidth,
  formatter: ({ value }) => formatCurrency(value as number),
});

// 추가할인
export const getAdditionalDiscountAmountColumn = (minWidth = 80): OptColumn => ({
  header: 'PRODUCT.ADDITIONAL_DISCOUNT',
  name: 'additionalDiscountAmount',
  align: 'center',
  minWidth,
  formatter: ({ value }) => formatCurrency(value as number),
});

// 추가할인가
export const getAppliedAdditionalDiscountPriceColumn = (minWidth = 80): OptColumn => ({
  header: 'PRODUCT.ADDITIONAL_DISCOUNT_PRICE',
  name: 'appliedAdditionalDiscountPrice',
  align: 'center',
  minWidth,
  formatter: ({ value }) => formatCurrency(value as number),
});

//옵션가
export const getOptionColumn = (minWidth = 80): OptColumn => ({
  header: 'PRODUCT.OPTION',
  name: 'hasOption',
  align: 'center',
  minWidth,
  formatter: ({ row, value }) => {
    if (row.optionType === 'MAPPING') return '-';
    return value ? window.$t('PRODUCT.ICON.USE') : window.$t('PRODUCT.ICON.NOT_USE');
  },
});

//프로모션 적용
export const getPromotionColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.APPLY_PROMOTION',
  name: 'canPromotion',
  align: 'center',
  minWidth,
  formatter: ({ row, value }) => {
    if (row.applyStatusType === 'REGISTRATION_READY') return '-';
    return value ? 'Y' : 'N';
  },
});

//담당자
export const getAdminNameColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.HANDLER',
  name: 'adminName',
  align: 'center',
  minWidth,
});

//파트너사
export const getPartnerNameColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.PARTNER',
  name: 'partnerName',
  align: 'center',
  minWidth,
});

//판매방식
export const getSaleMethodTypeColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.SALES_METHOD',
  name: 'saleMethodType',
  align: 'center',
  minWidth,
  formatter: ({ value }) => {
    if (!SALE_METHOD_TYPES.includes(value as SaleMethodType)) return;
    return window.$t(`PRODUCT.PRODUCT.${value}`);
  },
});

//수수료율
export const getCommissionColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.COMMISSION_RATE',
  name: 'commission',
  align: 'center',
  minWidth,
  formatter: ({ row, value }: any) => {
    if (row.saleMethodType === 'PURCHASE' || row.commission.type === 'PURCHASE_PRICE') {
      return '-';
    }
    return `${value.rate}%`;
  },
});

//매입가
export const getPurchasePriceColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.PURCHASE_PRICE',
  name: 'purchasePrice',
  align: 'center',
  minWidth,
  formatter: ({ row, value }) => {
    if (row.saleMethodType === 'CONSIGNMENT') return '-';
    return formatCurrency(value as number);
  },
});

//배송여부
export const getDeliverableColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.DELIVERABLE',
  name: 'deliverable',
  align: 'center',
  minWidth,
  formatter: ({ value }) =>
    value ? window.$t('PRODUCT.PRIORITY.DELIVERY') : window.$t('PRODUCT.PRIORITY.NO_DELIVERY'),
});

//배송비유형
export const getDeliveryFeeTypeColumn = (minWidth = 130): OptColumn => ({
  header: 'PRODUCT.DELIVERY_FEE_TYPE',
  name: 'deliveryFeeType',
  align: 'center',
  minWidth,
  formatter: ({ value, row }) => {
    if (!row.deliverable) return '';
    switch (value) {
      case 'CONDITIONAL':
        return (
          i18n.t(`PRODUCT.PRODUCT.${value}`).toString() +
          `(${formatCurrency(row.freeShippingConditionAmount as number)})`
        );

      case 'QUANTITY_PROPOSITIONAL_FEE':
        return (
          i18n.t(`PRODUCT.PRODUCT.${value}`).toString() +
          `(${formatCurrency(row.quantityConditionPerOrderCnt as number)}${i18n.t('PRODUCT.COMMON.EACH').toString()})`
        );

      default:
        return value ? i18n.t(`PRODUCT.PRODUCT.${value}`).toString() : '-';
    }
  },
});

//배송비
export const getDeliveryChargeColumn = (minWidth = 130): OptColumn => ({
  header: 'PRODUCT.DELIVERY_CHARGE',
  name: 'deliveryCharge',
  align: 'center',
  minWidth,
  formatter: ({ value }) => {
    if (!value) return '';
    return formatCurrency(Number(value));
  },
});

//묶음배송 여부
export const getShippingCombinableColumn = (
  minWidth = 100,
  name: 'shippingCombinable' | 'bundleDeliveryAvailable' = 'shippingCombinable',
): OptColumn => ({
  header: name === 'bundleDeliveryAvailable' ? 'PRODUCT.POSSIBLE_SHIPPING_COMBINABLE' : 'PRODUCT.SHIPPING_COMBINABLE',
  name,
  align: 'center',
  minWidth,
  formatter: ({ value }) => {
    return value === true ? i18n.t('MAY').toString() : i18n.t('UNABLE').toString();
  },
});

//브랜드
export const getBrandNameColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.BRAND',
  name: 'brandName',
  align: 'center',
  minWidth,
  formatter: ({ value }) => emptyValueFormatter(value),
});

//판매가, 즉시할인, 즉시할인가
export const getDiscountColumns = (): OptColumn[] => {
  return [
    getSalePriceColumn(),
    getImmediateDiscountAmountColumn(),
    getAppliedImmediateDiscountPriceColumn(),
    getAdditionalDiscountAmountColumn(),
    getAppliedAdditionalDiscountPriceColumn(),
  ];
};

//프로모션적용, 담당자, 파트너사, 판매방식, 수수료율, 공급가, 매입가
export const getCommonGridColumns = (): OptColumn[] => {
  return [
    getPromotionColumn(),
    getAdminNameColumn(),
    getPartnerNameColumn(),
    getSaleMethodTypeColumn(),
    getCommissionColumn(),
    getSupplyPriceColumn(),
    getPurchasePriceColumn(),
  ];
};

//배송여부, 배송비유형, 묶음배송 여부
export const getDeliveryColumns = (): OptColumn[] => {
  return [getDeliverableColumn(), getDeliveryFeeTypeColumn(), getDeliveryChargeColumn(), getShippingCombinableColumn()];
};

// 쇼핑몰명, 상품번호 상품명
export const getProductColumns = (useConvertor = true): OptColumn[] => {
  return [
    {
      header: 'MALL',
      name: 'mallName',
      align: 'center',
      minWidth: 80,
    },
    getProductNoColumn(),
    getProductNameColumn(useConvertor),
  ];
};

// 상품 적립률
export const getAccumulationRateColumn = (minWidth = 80): OptColumn => ({
  header: 'PRODUCT.ACCUMULATION_RATE',
  name: 'accumulationRate',
  align: 'center',
  minWidth,
  formatter: ({ value }) => {
    return value ? `${value}%` : i18n.t('NOT_USED').toString();
  },
});
