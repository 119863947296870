





















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import sellerTaxationTypes from '@/const/words/sellerTaxationTypes';
import { GetPartnersActivePartnerNo } from 'ncp-api-supporter';

@Component
export default class PartnerRegisterBasicInformation extends Vue {
  @Prop({ required: true })
  private partner: GetPartnersActivePartnerNo['partner'];

  private get sellerTaxationTypeLabel() {
    return sellerTaxationTypes.find(({ value }) => value === this.partner.sellerTaxationType)?.name;
  }
}
