const nodeElements = {
  internalNode: `
		<div data-product-count="{{ productCount }}" class="tui-tree-content-wrapper">
			<button type="button" class="tui-tree-toggle-btn tui-js-tree-toggle-btn">-<span class="tui-ico-tree"></span></button>
			<span class="tui-tree-text tui-js-tree-text">
				<span class="tui-tree-ico tui-ico-file-line"></span>
				<span class="tui-tree-ico tui-ico-folder"></span>
				<span class="tui-node-name">{{nodeName}}</span>
				<span class="ico_noshow display-node-{{ displayYn }}"></span>
				{{if productCount}}
        {{else}}<button class="tui-ico-plus" data-add-child-node></button>
        {{/if}}
			</span>
	  </div>
	  <ul class="tui-tree-subtree tui-js-tree-subtree">{{children}}</ul>
	`,
  leafNode: `
		<div data-product-count="{{ productCount }}" class="tui-tree-content-wrapper">
			<span class="tui-tree-text tui-js-tree-text">
				<span class="tui-tree-ico tui-ico-file-line"></span>
				<span class="tui-tree-ico tui-ico-file"></span>
				<span class="tui-node-name">{{nodeName}}</span>
				<span class="ico_noshow display-node-{{ displayYn }}"></span>
				{{if productCount}}
        {{else}}<button class="tui-ico-plus" data-add-child-node></button>
        {{/if}}
			</span>
		</div>
	`,
};

export default nodeElements;
