






















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import { BankAccount } from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';

@Component({
  components: {
    TextInput,
    SelectBox,
  },
})
export default class TdRefundAccount extends Vue {
  @Prop({ required: true })
  private readonly isModifyMode: boolean;

  @Prop({ required: true })
  private readonly showRefundAccountSelect: boolean;

  @Prop({ required: true })
  private readonly showRefundAccount: boolean;

  @Prop({ required: true })
  private readonly refundBankOptions: OptionData<string>[];

  @PropSync('refundBankAccount')
  private refundBankAccountSync!: BankAccount;

  private onChangeBankName() {
    const selectedBank = this.refundBankOptions.find(
      refundBank => refundBank.value === this.refundBankAccountSync.bank,
    );
    this.refundBankAccountSync.bankName = selectedBank.name;
  }
}
