import {
  getProductColumns,
  getDiscountColumns,
} from '@/components/product/common/girdColumns/ProductCommonGridColumns';
import { ProductsSearchPostRequest, MarketingChannelsType } from 'ncp-api-supporter';
import { i18n } from '@/main';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import { OptionData } from '@/helpers/type';
import { addMonth, getToday } from '@/utils/dateFormat';
import { MARKETING_TYPE } from '@/const/contents/product';

export const authorityMenuNo = 8654;

export const reqeustParam: ProductsSearchPostRequest = {
  data: {
    mallNos: [],
    keywordInfo: {
      type: 'PRODUCT_NAME',
      keywords: [''],
    },
    marketingChannels: [] as MarketingChannelsType[],
    page: 1,
    size: 30,
    periodInfo: {
      type: 'REGISTER_DATE',
      period: {
        startYmdt: addMonth(new Date(), -3),
        endYmdt: getToday(),
      },
    },
    applyStatusType: 'ALL',
    deliverable: 'ALL',
    deliveryFeeType: 'ALL',
    allowsShippingTogether: 'ALL',
    allowsFrontDisplay: 'ALL',
    platformType: 'ALL',
    saleMethodType: 'ALL',
    brandNo: null,
    adminNo: null,
    partnerNo: null,
    allowsPromotion: null,
    canApplyCoupon: null,
    canApplyAdditionalDiscount: null,
    hasAdditionalDiscount: null,
    commission: {
      type: null,
    },
    shippingAreaType: null,
    onlyParentProduct: null,
    customPropertyValueNos: null,
  },
};

export const gridProp = () => ({
  header: {
    columns: [
      {
        name: 'displayable',
        renderer: props => {
          props.tooltipId = 'displayable';
          return headerWithTooptipRenderer(props);
        },
      },
      {
        name: 'available',
        renderer: props => {
          props.tooltipId = 'availableAds';
          return headerWithTooptipRenderer(props);
        },
      },
    ],
  },
  columns: [
    ...getProductColumns(),
    ...getDiscountColumns(),
    {
      header: 'MARKETING.CONFIGURATION.DISPLAYABLE',
      name: 'displayable',
      align: 'center',
      formatter: ({ row }) => {
        if (
          !row.marketingSettings ||
          !row.marketingSettings?.find(({ channelType }) => channelType === MARKETING_TYPE.GOOGLE)?.displayable
        ) {
          return i18n.t('MARKETING.CONFIGURATION.NO_DISPLAYABLE');
        }
        return i18n.t('MARKETING.CONFIGURATION.YES_DISPLAYABLE');
      },
    },
    {
      header: 'MARKETING.CONFIGURATION.AVAILABLE_ADS',
      name: 'available',
      align: 'center',
      formatter: ({ row }) =>
        //NOTE: EP가 없었을때, 운영중이던 상품에 대해서 연동상태 Y로 표기 하기 위해 null , emptyArray도 Y로 표기
        !row.marketingSettings?.length ||
        row.marketingSettings?.find(({ channelType }) => channelType === MARKETING_TYPE.GOOGLE)?.available
          ? 'Y'
          : 'N',
    },
    {
      header: 'MARKETING.CONFIGURATION.ADDITIONAL_INFO',
      name: 'additionalInfo',
      align: 'center',
      // NOTE: 해당 그리드 컬럼은 네이버마케팅 / 페이스북 전용이라, 구글마케팅은 항상 -로 노출
      formatter: () => '-',
    },
  ],
  options: {
    rowHeaders: ['checkbox'],
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },
  displayOptions: {
    hasExcelDownloadButton: false,
    hasSettingButton: false,
    pageSizeKey: 'size',
    headerTooltipOption: [
      {
        id: '#displayable',
        popupData: { data: { message: i18n.t('MARKETING.CONFIGURATION.DISPLAYABLE_TOOLTIP'), leftAlign: true } },
      },
      {
        id: '#availableAds',
        popupData: { data: { message: i18n.t('MARKETING.CONFIGURATION.VAILABLE_ADS_TOOLTIP'), leftAlign: true } },
      },
    ],
  },
});

export const searchKeywordList = [
  { name: 'MARKETING.CONFIGURATION.PRODUCT_NAME', value: 'PRODUCT_NAME' },
  { name: 'MARKETING.CONFIGURATION.MALL_PRODUCT_NO', value: 'MALL_PRODUCT_NO' },
  // { name: 'MARKETING.CONFIGURATION.PRODUCT_MANAGEMENT_CD', value: 'PRODUCT_MANAGEMENT_CD' },
  // { name: 'MARKETING.CONFIGURATION.PRODUCT_MANAGEMENT_CD_LIKE', value: 'PRODUCT_MANAGEMENT_CD_LIKE' },
  // { name: 'MARKETING.CONFIGURATION.GROUP_MANAGEMENT_CODE', value: 'GROUP_MANAGEMENT_CODE' },
];

export const periodInfo: OptionData<string>[] = [
  {
    value: 'REGISTER_DATE',
    name: 'PRODUCT.SEARCH.REGISTER_DATE',
  },
  {
    value: 'PRODUCT_MODIFIED_DATE',
    name: 'PRODUCT.SEARCH.MODIFIED_DATE',
  },
  {
    value: 'SALE_START_DATE',
    name: 'PRODUCT.SEARCH.SALE_START_DATE',
  },
  {
    value: 'SALE_END_DATE',
    name: 'PRODUCT.SEARCH.SALE_END_DATE',
  },
  {
    value: 'PRE_SALE_START_DATE',
    name: 'PRODUCT.SEARCH.PRE_SALE_START_DATE',
  },
  {
    value: 'PRE_SALE_END_DATE',
    name: 'PRODUCT.SEARCH.PRE_SALE_END_DATE',
  },
];
