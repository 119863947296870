export default [
  {
    value: '',
    name: '은행선택',
  },
  {
    value: 'DIRECT',
    name: '직접입력',
  },
  {
    value: 'ANONYMOUS',
    name: '미확인은행',
  },
  {
    value: 'KDB',
    name: '산업은행',
  },
  {
    value: 'IBK',
    name: '기업은행',
  },
  {
    value: 'KB',
    name: '국민은행',
  },
  {
    value: 'KEB',
    name: '외환은행',
  },
  {
    value: 'SUHYUP',
    name: '수협',
  },
  {
    value: 'KEXIM',
    name: '수출입은행',
  },
  {
    value: 'NH',
    name: 'NH농협은행',
  },
  {
    value: 'NHLOCAL',
    name: '지역농축협',
  },
  {
    value: 'WOORI',
    name: '우리은행',
  },
  {
    value: 'SC',
    name: 'SC제일은행',
  },
  {
    value: 'CITY',
    name: '한국씨티은행',
  },
  {
    value: 'DAEGU',
    name: '대구은행',
  },
  {
    value: 'PUSAN',
    name: '부산은행',
  },
  {
    value: 'GWANGJU',
    name: '광주은행',
  },
  {
    value: 'JEJU',
    name: '제주은행',
  },
  {
    value: 'JEONBUK',
    name: '전북은행',
  },
  {
    value: 'GYEONGNAM',
    name: '경남은행',
  },
  {
    value: 'KFCC',
    name: '새마을금고',
  },
  {
    value: 'CU',
    name: '신협',
  },
  {
    value: 'SANGHO',
    name: '상호저축은행',
  },
  {
    value: 'HSBC',
    name: 'HSBC은행',
  },
  {
    value: 'DEUTSCHE',
    name: '도이치은행',
  },
  {
    value: 'NFCF',
    name: '산림조합중앙회',
  },
  {
    value: 'EPOST',
    name: '우체국',
  },
  {
    value: 'KEBHANA',
    name: 'KEB하나은행',
  },
  {
    value: 'SHINHAN',
    name: '신한은행',
  },
  {
    value: 'KBANK',
    name: '케이뱅크',
  },
  {
    value: 'KAKAO',
    name: '카카오뱅크',
  },
  {
    value: 'YUANTA',
    name: '유안타증권',
  },
  {
    value: 'KBSEC',
    name: 'KB증권',
  },
  {
    value: 'MIRAE',
    name: '미래에셋증권',
  },
  {
    value: 'MIRAEDAEWOO',
    name: '미래에셋대우증권',
  },
  {
    value: 'SAMSUNG',
    name: '삼성증권',
  },
  {
    value: 'HANKOOK',
    name: '한국투자증권',
  },
  {
    value: 'NH_INVEST',
    name: 'NH투자증권',
  },
  {
    value: 'KYOBO',
    name: '교보증권',
  },
  {
    value: 'HI_INVEST',
    name: '하이투자증권',
  },
  {
    value: 'HMC_INVEST',
    name: 'HMC투자증권',
  },
  {
    value: 'KIWOOM',
    name: '키움증권',
  },
  {
    value: 'EBEST',
    name: '이베스트투자증권',
  },
  {
    value: 'SK',
    name: 'SK증권',
  },
  {
    value: 'DAISHIN',
    name: '대신증권',
  },
  {
    value: 'SOLOMON_INVEST',
    name: '솔로몬증권',
  },
  {
    value: 'HANHWA',
    name: '한화투자증권',
  },
  {
    value: 'HANA_INVEST',
    name: '하나금융투자',
  },
  {
    value: 'SHINHAN_INVEST',
    name: '신한금융투자',
  },
  {
    value: 'DONGBU',
    name: 'DB금융투자',
  },
  {
    value: 'EUGENE_INVEST',
    name: '유진투자증권',
  },
  {
    value: 'MERITZ_COMPREHENSIVE',
    name: '메리츠종합금융증권',
  },
  {
    value: 'BOOKOOK',
    name: '부국증권',
  },
  {
    value: 'SHINYOUNG',
    name: '신영증권',
  },
  {
    value: 'CAPE',
    name: '케이프투자증권',
  },
  {
    value: 'TOSS',
    name: '토스뱅크',
  },
  {
    value: 'MIZUHO',
    name: '미즈호은행',
  },
  {
    value: 'UFJ',
    name: 'UFJ은행',
  },
  {
    value: 'SMBC',
    name: '미쓰이스미토모은행',
  },
  {
    value: 'RESONA',
    name: '리소나은행',
  },
];
