


















































































import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class DiscountShippingFee extends Vue {
  @Prop({ required: true }) private objJson!: string;
  @Prop({ required: true }) private postPredict!: {};
  @Prop({ default: '' }) private claimType!: string;
  @Prop({ default: false }) private reSetData!: boolean;
}
