






























import { Vue, Component, Prop, Ref, PropSync, Watch } from 'vue-property-decorator';
import { PopupClose, PopupData, throwWindowPopup } from '@/helpers/popup';
import { PRODUCT_OPTION_TYPE } from '@/views/popups/product/productAdd/ProductOption.vue';
import { OptionSelectType, OptionType, YorN } from 'ncp-api-supporter';
import { CombinationOptionType, CustomerDemands, EditFlag, Mode, OptionPopupDataType } from '@/types';
import { getDefaultOption, i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { ProductManagementMode, SalesInfoRefModel } from '@/views/contents/product/basic/ProductAdd.vue';
import { SalePriceInformation, UseState } from '@/views/contents/product/basic/ProductAdd/salesInfo/SalesInfo.vue';
import { MODE } from '@/const/common';
import { SaleSettingStatusTypes } from '@/views/top/product/stock/ProductStock';
import {
  CommissionType,
  SalesInfoCalculator,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { OPTION_TYPE } from '@/const/contents/product';
import { unescapeHtml } from '@/utils/common';

@Component
export default class OptionDetails extends Vue {
  // STANDARD: 단독형, COMBINATION: 조합형, DEFAULT: 기본생성, MAPPING: 맵핑
  @PropSync('optionType')
  private optionTypeSync: OptionType;

  @PropSync('options')
  private optionsSync: CombinationOptionType<string>[];

  @PropSync('optionUseYn', { required: true })
  private optionUseYnSync: YorN;

  @PropSync('optionPrice', { required: true })
  private optionPriceSync: number;

  @PropSync('customerDemands', { required: true })
  private customerDemandsSync: CustomerDemands[];

  @PropSync('optionSelectType', { required: true })
  private optionSelectTypeSync: OptionSelectType;

  @PropSync('hasOptionImage', { required: true })
  private hasOptionImageSync: boolean;

  @PropSync('supplyPrice', { required: true })
  private supplyPriceSync: number;

  @Prop({ required: true })
  private readonly salesInfoRefModel: SalesInfoRefModel;

  @Prop({ required: true })
  private readonly mode: ProductManagementMode;

  @Prop({ required: true })
  private readonly editFlag: EditFlag;

  @Prop({ required: true })
  private readonly useState: UseState;

  @Prop({ required: true })
  private readonly salePriceInfo: SalePriceInformation;

  @Prop({ required: true })
  private readonly saleSettingStatusType: SaleSettingStatusTypes;

  @Prop({ required: true })
  private readonly calculator: SalesInfoCalculator;

  @Prop({ required: true })
  private readonly productName: string;

  @Prop({ required: true })
  private readonly productStockCnt: number;

  @Prop({ required: true })
  private readonly isSelectedMall: boolean;

  @Prop({ required: true })
  private readonly isFinishedReservationSales: boolean;

  private readonly MODE = MODE;

  private savedOption: OptionPopupDataType = getDefaultOption();

  @Ref() private readonly optionsRef!: HTMLButtonElement;
  public focus(): void {
    this.optionsRef.focus();
  }

  public addOptionDefault() {
    this.optionUseYnSync = 'Y';
    this.optionTypeSync = 'COMBINATION';
    this.optionsSync.push({
      optionName: this.productName,
      optionValue: this.productName,
      addPrice: 0,
      useYn: 'N',
      stockCnt: Number(this.productStockCnt),
      saleStatusType: 'PRE_APPROVAL_STATUS',
    });
  }

  public setSavedOptions(): void {
    if (!this.useState.useOption) {
      this.optionTypeSync = 'DEFAULT';
      this.optionUseYnSync = 'N';
      this.optionsSync = null;
      this.customerDemandsSync = null;
      this.optionSelectTypeSync = 'MULTI';
      return;
    }
    this.optionTypeSync = this.savedOption.optionType;
    this.optionsSync = this.savedOption.options;
    this.optionUseYnSync = this.savedOption.optionType !== 'DEFAULT' ? 'Y' : 'N';
    this.customerDemandsSync = this.savedOption.customerDemands;
    this.optionSelectTypeSync = this.savedOption.optionSelectType;
  }

  private get isOptionAddable(): boolean {
    return !(this.savedOption.options?.length > 0 || this.savedOption.customerDemands?.length > 0);
  }

  private get isMappingOption(): boolean {
    return this.optionTypeSync === OPTION_TYPE.MAPPING;
  }

  private get optionDetailsType(): OptionType {
    const isRequiredSetOptionType = this.mode.isMapping && this.optionTypeSync === OPTION_TYPE.DEFAULT;
    return isRequiredSetOptionType ? OPTION_TYPE.MAPPING : this.optionTypeSync;
  }

  private setOptionPopupState(isOpened: boolean): void {
    this.$emit('setOptionPopupState', isOpened);
  }

  private get hasDeliveryTemplate(): boolean {
    return this.salesInfoRefModel.deliveryTemplateNo > 0;
  }

  private alertMessageType(): 'alertSalePrice' | 'alertMallSelect' | 'alertDeliveryTemplate' | null {
    if (!this.mode.isMapping && !this.salePriceInfo.hasSalePrice) return 'alertSalePrice';
    if (!this.mode.isMapping) return null;
    if (!this.isSelectedMall) return 'alertMallSelect';
    if (!this.hasDeliveryTemplate) return 'alertDeliveryTemplate';
    return null;
  }
  private canPopUp(): boolean {
    const messageType = this.alertMessageType();

    messageType && this.$emit(messageType);
    return !messageType;
  }

  private openOptionPopup(popupType: Mode) {
    if (!this.canPopUp()) return;
    this.setOptionPopupState(true);

    const type = popupType === MODE.REGISTER ? PRODUCT_OPTION_TYPE.REGISTRATION : PRODUCT_OPTION_TYPE.MODIFICATION;
    const { saleMethodType, mallNo, productNo: mallProductNo, deliveryTemplateNo } = this.salesInfoRefModel;
    const { commissionType, immediateDiscountPrice } = this.salePriceInfo;
    throwWindowPopup(
      'ProductOption',
      {
        type,
        mallNo,
        mallProductNo,
        deliveryTemplateNo,
        saleMethodType,
        commissionType,
        immediateDiscountPrice,
        useReservation: this.useState.reservation,
        purchasePrice: this.supplyPriceSync,
        savedOption: {
          ...this.savedOption,
          options: this.savedOption.options.map(option => ({ ...option, optionName: unescapeHtml(option.optionName) })),
          optionSelectType: this.optionSelectTypeSync,
        },
        isMaster: this.isMaster,
        isSynced: this.isSynced, // synced && is slave
        saleSettingStatusType: this.saleSettingStatusType,
        isFinishedReservationSales: this.isFinishedReservationSales,
      },
      'xlg',
      this.saveOptions,
      '',
      null,
      this.sendPostMessage,
    );
  }

  private get isMaster() {
    return this.editFlag.isMaster && this.editFlag.isSynced;
  }

  private get isSynced() {
    return this.editFlag.isSlave;
  }

  private popup: Window;
  private targetOrigin = '';
  private sendPostMessage(childWindow: Window, path: string) {
    this.popup = childWindow;
    this.targetOrigin = `${window.location.origin}${path}`;
  }

  @Watch('salesInfoRefModel.mallNo')
  @Watch('salesInfoRefModel.deliveryTemplateNo')
  @Watch('salePriceInfo', { deep: true })
  @Watch('useState.reservation')
  private changedStatus() {
    if (this.popup === undefined) return;
    const info = {
      mallNo: this.salesInfoRefModel.mallNo,
      deliveryTemplateNo: this.salesInfoRefModel.deliveryTemplateNo,
      immediateDiscountPrice: this.salePriceInfo.immediateDiscountPrice,
      commissionType: this.salePriceInfo.commissionType,
      useReservation: this.useState.reservation,
    };
    this.popup.postMessage(
      {
        // 쇼핑몰, 공급사, 배송비템플릿, 판매상태, 즉시할인가, 수수료타입(예: 카테고리 수수료, 공급가), 예약판매 설정여부
        info,
      },
      this.targetOrigin,
    );
  }

  private saveOptions({ state, data }: PopupData): void {
    this.setOptionPopupState(false);

    if (state === PopupClose.CLOSE) return;
    const { data: optionData }: { data: OptionPopupDataType } = data;

    this.savedOption = optionData;
    this.setOptions(optionData);
    this.salePriceInfo.commissionType === CommissionType.PURCHASE && this.setSupplyPrice(optionData);
  }

  private setMinusOptionPrice(optionData: OptionPopupDataType): void {
    const filtered = optionData.options?.filter(option => option.addPrice < 0);

    if (!filtered || filtered.length === 0) {
      this.optionPriceSync = 0;
      return;
    }

    const minusOptionPrice = filtered.sort((a, b) => a.addPrice - b.addPrice)[0];
    if (minusOptionPrice.addPrice) {
      this.optionPriceSync = minusOptionPrice.addPrice;
    }
  }

  private checkOptionImage(optionData: OptionPopupDataType): void {
    // 수정일 경우 옵션 @watch length
    const hasOptionImage = optionData.options?.some(option => {
      return option.optionImages?.some(image => image?.mallOptionImageUrl !== '');
    });
    this.hasOptionImageSync = hasOptionImage;
  }
  private changedSalePrice({ options }: OptionPopupDataType): void {
    if (!this.mode.isMapping) return;

    const result = options.sort((a, b) => a.addPrice - b.addPrice);
    const minPrice = result.length > 0 ? result[0].addPrice : 0;
    this.$emit('changedSalePrice', minPrice);
  }
  private setOptions(optionData: OptionPopupDataType): void {
    this.setMinusOptionPrice(optionData);
    this.checkOptionImage(optionData);
    this.changedSalePrice(optionData);
    // 옵션가 중에 마이너스 옵션가가 있으면 즉시할인가 -
    //
    this.optionUseYnSync = optionData.optionType === 'DEFAULT' ? 'N' : 'Y';
    this.optionTypeSync = optionData.optionType;
    this.optionSelectTypeSync = optionData.optionSelectType;
    this.optionsSync = optionData.options.map(option => ({
      ...option,
      stockNo: !option.stockNo ? null : option.stockNo,
      optionManagementCd: !option.optionManagementCd ? null : option.optionManagementCd,
      mallOptionNo: !option.mallOptionNo ? null : option.mallOptionNo,
    }));
    this.customerDemandsSync = [...optionData.customerDemands];
  }

  private setSupplyPrice(optionData: OptionPopupDataType): void {
    // 옵션 공급가의 총 합 필요 없을 것 같은데 일단 삭제는 보류
    this.supplyPriceSync = optionData.options.reduce((acc, option) => {
      return (acc += option.purchasePrice);
    }, 0);
  }

  private resetOption() {
    this.savedOption = getDefaultOption(this.optionDetailsType);
    this.optionUseYnSync = 'N';
  }

  private reset(): void {
    if (!confirm(i18nForProduct('CONFIRM_RESET_OPTION'))) return;
    this.resetOption();
  }

  private created(): void {
    this.setInitialSavedOption();
  }

  @Watch('optionsSync')
  @Watch('customerDemandsSync')
  private setInitialSavedOption(): void {
    this.savedOption = {
      ...this.savedOption,
      optionType: this.optionDetailsType,
      options: this.optionsSync,
      customerDemands: this.customerDemandsSync,
      optionSelectType: this.optionSelectTypeSync,
    };
    this.optionUseYnSync = this.optionDetailsType === OPTION_TYPE.DEFAULT ? 'N' : 'Y';
    this.checkOptionImage(this.savedOption);
    this.setMinusOptionPrice(this.savedOption);
  }
}
