import { Row } from '@/types/tui-grid';
import { ProductsApplyStatusType } from 'ncp-api-supporter';
import { i18n } from '@/main';
import { TranslateResult } from 'vue-i18n';

// 상태를 변경할 수 있는 승인상태 리스트
const changeApproveApplyStatusType: ProductsApplyStatusType[] = [
  'FINISHED',
  'AFTER_APPROVAL_READY',
  'AFTER_APPROVAL_REJECTION',
];

//심사가능한 승인상태 리스트
const judgeApproveApplyStatusType: ProductsApplyStatusType[] = ['APPROVAL_READY', 'AFTER_APPROVAL_READY'];

/**
 * @changeSaleSettingStatusRowValidation
 * 해당 row 의 상태를 변경할 수 있는지 판별한다
 *
 * history:
 * 'ncp 어드민 스팩과 동일' 이라고 되어잇는데 판태가 변경가능한지 체크하는 로직(pin)을 ncp 어드민 하고 다르게 구성함.
 * 이유는 아래 조건에 부합하지않으면 변경 요청 날려도 api 단에서 반려되기때문
 *
 * logic :
 * 승인상태(applyStatusType) FINISHED: 승인완료, AFTER_APPROVAL_READY: 수정 후 승인대기, AFTER_APPROVAL_REJECTION: 수정 후 승인거부 중 하나인가
 * 판매상태(saleSettingStatusType) PROHIBITION_SALE: 판매금지 상태가 아닌가
 *
 * use status :
 * 판매상태 (SalesSettingStatus)
 * 전시상태 (AllowFrontDisplay)
 */

export const changeStatusRowValidation = (
  { applyStatusType, saleSettingStatusType }: Row,
  isProductJudge = false,
): boolean => {
  const isApprove = isProductJudge
    ? judgeApproveApplyStatusType.some(v => v === applyStatusType)
    : changeApproveApplyStatusType.some(v => v === applyStatusType);
  const isNotProhibitionSale = saleSettingStatusType !== 'PROHIBITION_SALE';
  return isApprove && isNotProhibitionSale;
};

// rows 를 받아 판매상태를 변경할 수 있는 mallProductNo 를 리턴한다. BulkEdit 시 사용
export const filterByCanChangeStatusProductNo = (rows: Row[], isProductJudge = false): number[] => {
  return rows.filter(item => changeStatusRowValidation(item, isProductJudge)).map(item => item.mallProductNo as number);
};

/**
 * @bulkChangeStatusRowsValidation
 * 상품리스트 그리드에서 '선택 행 변경' 기능이 있음에 따라 이 판별 함수를 추가하였다.
 * 선택된 행(row) 들의 상태를 변경할 수 있는가를 판별한다.
 *
 * @param
 * rows : Row[] = 변경하고자 하는 복수의 행(row)
 * translateName: alert 메시지에 쓰이는 TranslateResult (i18n.t())
 *
 * @return boolean
 * 변경가능한 행 없음 : false
 * 선택된 행들 모두 변경가능 : true
 * 변경가능한 행/불가능한 행이 혼재되어 있음 : confirm(): boolean
 *
 * use status :
 * 판매상태 (SalesSettingStatus)
 * 전시상태 (AllowFrontDisplay)
 */
export const bulkChangeStatusRowsValidation = (rows: Row[], translateName: TranslateResult): boolean => {
  const isProductJudge = translateName === i18n.t('PRODUCT.EDIT.PRODUCT_JUDGE');
  // 선택한 상품이 없음
  if (!rows.length) {
    alert(i18n.t('ALERT_NO_SELECTED', { value: i18n.t('PRODUCT') }));
    return false;
  }

  const mallProductNos: number[] = rows.map(item => item.mallProductNo as number);
  const canChangeMallProductNos: number[] = filterByCanChangeStatusProductNo(rows, isProductJudge);

  if (mallProductNos.length === canChangeMallProductNos.length) return true; // 모든 상품 변경 가능. 통과

  if (!canChangeMallProductNos.length) {
    // 변경 가능한 상품 없음
    isProductJudge
      ? alert(i18n.t('PRODUCT.EDIT.ALERT_CANNOT_JUDGE_PRODUCT'))
      : alert(i18n.t('PRODUCT.EDIT.ALERT_CANNOT_BE_CHANGED', { value: translateName }));
    return false;
  }

  // 변경 가능한 상품과 불가능한 상품이 혼재되어 있을 경우
  return isProductJudge
    ? confirm(i18n.t('PRODUCT.EDIT.CONFIRM_CANNOT_JUDGE_PRODUCT_DROP').toString())
    : confirm(i18n.t('PRODUCT.EDIT.CONFIRM_CANNOT_BE_CHANGED_DROP', { value: translateName }).toString());
};
