





























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PlayListTypes, YoutubePlayListsResponseContent } from 'ncp-api-supporter';
import chunk from 'lodash.chunk';

@Component({})
export default class YoutubeButton extends Vue {
  private isShowModal = false;
  private youtubeType = 'SHOPBY_PRO';
  private youtubeVideos: YoutubePlayListsResponseContent[] = [];
  private perPageYoutubeVideos: YoutubePlayListsResponseContent[][] = [];
  private isError = false;
  private currentPageNum = 0;
  private perPageLength = 10;

  created() {
    this.getYoutubePlayLists();
  }

  @Watch('$route', { immediate: true })
  private changedRoute() {
    this.postCollectorStatistics('V');
  }

  private getYoutubePlayLists() {
    this.$api
      .getYoutubePlayLists({ params: { playListType: this.youtubeType as PlayListTypes } })
      .then(response => {
        this.youtubeVideos = response.data;
        this.divisionVideos();
      })
      .catch(err => {
        if (err) this.isError = true;
      });
  }

  private divisionVideos() {
    this.perPageYoutubeVideos = chunk(this.youtubeVideos, this.perPageLength);
  }

  /* eslint-disable */
  private postCollectorStatistics(action_type: 'C' | 'V') {
    fetch(process.env.VUE_APP_COLLECTOR_STATISTICS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        base_time: new Date(),
        solution: 'SHOPBY_PRO',
        banner_id: 'banner_2',
        action_type,
      }),
    });
  }

  private handleModalOpen(status: boolean) {
    if (status) {
      this.postCollectorStatistics('C');
      this.getYoutubePlayLists();
    }
    this.isShowModal = status;
  }

  private handlePaging(direction: string) {
    const paging = {
      table: {
        prev: currentNum => (currentNum > 0 ? currentNum - 1 : 0),
        next: (currentNum, totalNum) => {
          return currentNum < totalNum ? currentNum + 1 : totalNum;
        },
      },
      change(direction, currentNum, totalNum) {
        return this.table[direction]?.(currentNum, totalNum);
      },
    };

    this.currentPageNum = paging.change(direction, this.currentPageNum, this.perPageYoutubeVideos.length);
  }
}
