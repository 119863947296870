






































































import { Component } from 'vue-property-decorator';
import { DeliveryCompany, GetClaimsNo, NCPResponse, PutOptionCancelsNoAlreadyDeliveryRequest } from 'ncp-api-supporter';
import { addDay, getToday } from '@/utils/dateFormat';
import { OptionData } from '@/helpers/type';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import DateCalendar from '@/components/common/DateCalendar.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({
  components: { SelectBox, DateCalendar, TextInput },
})
export default class AlreadyDeliveryPopup extends WindowPopupMainVue {
  private deliveryCompanyOptions: OptionData<string>[] = [
    {
      value: '',
      name: 'CLAIM.COMMON.DELIVERY_COMPANY',
    },
  ];

  private claimDetailResponse: GetClaimsNo = {} as GetClaimsNo;
  private deliveryType = '';
  private formData = {
    releaseYmd: '',
    deliveryCompanyType: '',
    invoiceNo: '',
  };
  private endDateOption = {
    dateType: 'Ymd',
    name: 'endDateOption',
    toRanges: addDay(new Date(), 0),
  };

  private get orderOptionName(): string {
    if (!this.claimDetailResponse.claimedOptions) {
      return '';
    }

    const FIRST_ITEM = 0;
    return `${this.claimDetailResponse.claimedOptions[FIRST_ITEM].productName}`;
  }

  private get productDetail(): string {
    if (!this.isOrderOptionsMoreThan(0)) {
      return '';
    }

    const { optionName, optionValue } = this.claimDetailResponse.claimedOptions[0];
    const optionNameForDisplay = `${optionName}: ${optionValue}`;

    return `${
      this.isOrderOptionsMoreThan(1)
        ? this.$t('CLAIM.POPUP.MORE_CLAIMED_OPTION', { num: this.orderOptionLength - 1 })
        : optionNameForDisplay
    }`;
  }

  private get isDirectDelivery(): boolean {
    return this.deliveryType === 'DIRECT_DELIVERY';
  }

  private get deliveryTypeLabel(): string {
    return this.deliveryType.length === 0 ? '' : this.$t(`CLAIM.COMMON.${this.deliveryType}`).toString();
  }

  created() {
    this.fetchDeliveryCompanies();
    this.fetchClaimData();
  }

  private async fetchDeliveryCompanies(): Promise<void> {
    const { data }: NCPResponse<DeliveryCompany[]> = await this.$api.getDeliveryCompanies({
      params: { countryCd: 'KR' },
    });

    const deliveryCompanyOptions = data.map(deliveryCompany => ({
      name: deliveryCompany.label,
      value: deliveryCompany.deliveryCompanyType,
    }));

    this.setDeliveryCompanyOptions(deliveryCompanyOptions);
  }

  private setDeliveryCompanyOptions(deliveryCompanyOptions: OptionData<string>[]) {
    this.deliveryCompanyOptions = [...deliveryCompanyOptions];
  }

  private async fetchClaimData() {
    const { data } = await this.$api.getClaimsNo({ pathParams: { no: this.data.claimNo } });

    this.claimDetailResponse = data;
    // 디폴트를 무조건 선택하세요로 하라는데, 일단 임시주석처리
    // const { deliveryCompanyType, invoiceNo, deliveryType } = data.claimData.shipping;

    const { deliveryType } = data.claimData.shipping;
    this.deliveryType = deliveryType;

    this.formData = {
      ...this.formData,
      releaseYmd: getToday(),
      // deliveryCompanyType: deliveryCompanyType ? deliveryCompanyType : '',
      // invoiceNo: invoiceNo,
    };
  }

  private onRelease(): void {
    if (!this.validation()) {
      return;
    }

    const request: PutOptionCancelsNoAlreadyDeliveryRequest | PutOptionCancelsNoAlreadyDeliveryRequest = {
      pathParams: {
        no: this.data.claimNo,
      },
      // 빈문자열은 null로 보내야함
      data: {
        releaseYmd: this.formData.releaseYmd,
        invoiceNo: this.formData.invoiceNo?.trim().length ? this.formData.invoiceNo : null,
        deliveryCompanyType: this.formData.deliveryCompanyType?.length ? this.formData.deliveryCompanyType : null,
      },
    } as any; // DeliveryCompanyType이 null을 허용안함. 고치면 사이트 이펙트 우려가 있어 any 단언

    const alreadyDeliveryApis = {
      CANCEL: () => this.$api.putOptionCancelsNoAlreadyDelivery(request),
      EXCHANGE: () => this.$api.putCancelExchangesNoAlreadyDelivery(request),
    };

    alreadyDeliveryApis[this.claimDetailResponse.claimType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_ALREADY_DELIVERY'));
      this.onPositiveClick();
    });
  }

  private validation(): boolean {
    if (this.isDirectDelivery) {
      return true;
    }

    if (!this.isDirectDelivery && !this.formData.deliveryCompanyType) {
      alert(this.$t('CLAIM.MESSAGE.VALID_DELIVERY_COMPANY'));
      return false;
    }

    if (!this.isDirectDelivery && !this.formData.invoiceNo) {
      alert(this.$t('CLAIM.MESSAGE.VALID_INVOICE_NO'));
      return false;
    }

    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_ALREADY_DELIVERY').toString())) {
      return false;
    }

    return true;
  }

  private get orderOptionLength() {
    return this.claimDetailResponse.claimedOptions.filter(({ usesOption }) => usesOption).length;
  }

  private isOrderOptionsMoreThan(size: number) {
    return this.orderOptionLength > size;
  }

  private mounted() {
    this.$nextTick(() => this.resize());
  }
}
