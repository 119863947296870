import { api } from '@/api';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/root';
import { GetMainMenus, NCPResponse } from 'ncp-api-supporter';
import store from '@/store';

interface MenuState {
  menus: GetMainMenus[];
}

export default {
  namespaced: true,
  state: {
    menus: [],
  },
  actions: {
    async fetchMenus({ commit }: ActionContext<MenuState, RootState>): Promise<void> {
      const { data }: NCPResponse<GetMainMenus[]> = await api.getMainMenus(); // TODO: PRO 메뉴 여야함
      commit('SET_MENUS', data);

      store.commit('lnb/INIT_LNB_MENU');
    },
  },
  mutations: {
    SET_MENUS(state: MenuState, menus: GetMainMenus[]): void {
      state.menus = menus;
    },
  },
  getters: {
    getMenus(state: MenuState): GetMainMenus[] {
      return [...state.menus];
    },
  },
};
