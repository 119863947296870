


































import { Component, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { Item, Reason } from 'ncp-api-supporter';

@Component({
  components: {
    TextInput,
  },
})
export default class EtcTable extends Vue {
  private etcInfo = {
    etcEditText: {
      reason: '',
      name: this.$t('PRODUCT.COMMON.UPDATE_REQUEST'),
    },
  };

  @Watch('etcInfo', { deep: true })
  private getReasons() {
    const reasons: Reason[] = [];
    const etcReason: Item[] = [
      {
        item: this.etcInfo.etcEditText.name.toString(),
        detail: {
          data: '',
          comment: this.etcInfo.etcEditText.reason,
        },
      },
    ];
    const isNotWrittenReason = this.etcInfo.etcEditText.reason === '';
    reasons.push({ section: 'ETC_INFO', items: isNotWrittenReason ? [] : etcReason });
    this.$emit('reasons', reasons);
  }
}
