export default Object.freeze({
  // 080 수신거부 설정
  CONFIG_TITLE: '080 수신거부 설정',
  LIST_TITLE: '080 수신거부 리스트',
  WHETHER_TO_USE: '사용여부',
  UNSUBSCRIBE_NUMBER: '080 수신거부 번호',
  UNSUBSCRIBE_NUMBER_EXTENSION: '080 수신거부 번호 연장',
  APPLY_INFO: '신청정보',
  APPLY_INFO_TOOLTIP:
    '<strong>080 수신거부 서비스 기간연장(추가 결제) 방법</strong><br>NHN커머스 홈페이지 > 마이페이지 > 쇼핑몰관리 > 부가서비스 신청관리 > 080 수신거부 서비스 > [관리] 버튼 선택 > [결제 조회 및 납부] 버튼 클릭 후 연장 및 결제',
  NO_APPLY_TITLE: '080 수신거부 신청',
  PREV_PAY_TITLE: '신규등록 (미결제)',
  COMPLETE_PAY_TITLE: '결제완료',
  COMPLETE_ACTIVE_TITLE: '개통완료',
  CANCEL_TITLE: '해지상태 (해지일 : {year}년 {month}월 {day}일)',
  STATUS_NO_APPLY: '현재 080 서비스 미신청 상태입니다. 사용신청을 먼저 해주시기 바랍니다.',
  STATUS_PREV_PAY:
    '080 수신거부 서비스 사용을 위해 <strong>“NHN커머스 홈페이지 > 마이페이지 > 쇼핑몰 관리”</strong>에서 결제하여 주시기 바랍니다.',
  STATUS_COMPLETE_PAY: '결제가 완료되었으며, 개통대기 중입니다.',
  STATUS_COMPLETE_ACTIVE_1:
    '080 수신거부 서비스의 사용기간이 만료되면 서비스가 해지되며, 기존에 등록된 080 수신거부 번호가 모두 삭제됩니다.\n',
  STATUS_COMPLETE_ACTIVE_2: '반드시 사용기간 만료 전에 서비스를 연장하여 주시기 바랍니다.',
  STATUS_CANCEL: '해지 전 SMS 자동발송 시 수신거부 번호를 삽입하였다면, 해당 문구를 제거하여 주시기 바랍니다.',

  // 080 수신거부 리스트
  REJECT_NUMBER: '수신거부 번호',
  MANUAL_SYNC: '수동 동기화',
  REGISTER_DATE: '등록일',
  SYNC_COMPLETE_MESSAGE: '동기화가 완료되었습니다.',
});
