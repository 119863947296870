















































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { getAuthRadioBoxOption, SecondAuthData } from '@/const/contents/auth';
import { AUTH_METHOD } from '@/const/contents/configuration/operateSecurity';
import { getDefaultSecondAuthData, OTP_MODE, SECURITY_AUTH_MODE } from '@/const/contents/secondAuth';
import { getBase64FromBlob } from '@/utils/fileReader';
import { PostAdminsOtpQrCodeRequest } from 'ncp-api-supporter/dist/types';

// TODO 마크업 적용해야 함
// TODO 추후 email, sms 추가

@Component({ components: { RadioGroup, ToolTip } })
export default class SecurityAuthInput extends Vue {
  @Prop({ default: SECURITY_AUTH_MODE.AUTH })
  private from: SECURITY_AUTH_MODE;

  @Prop({ default: getDefaultSecondAuthData })
  private secondAuthData?: SecondAuthData;

  @PropSync('authKey', { default: '' })
  private authKeySync!: string;

  private OTP_MODE = OTP_MODE;

  private secondAuthMethodRadioOption = getAuthRadioBoxOption().secondAuthMethodRadioOption;

  private authMethod = this.secondAuthData.types[0] || this.secondAuthMethodRadioOption.data[0].value;

  private otpMode = OTP_MODE.NONE;
  private otpQr = '';
  private otpSecretEmail = '';
  private otpSecretKey = '';

  private isAuth(): boolean {
    return this.from === 'AUTH';
  }

  private isEmail(): boolean {
    return this.authMethod === AUTH_METHOD.EMAIL;
  }
  private isSms(): boolean {
    return this.authMethod === AUTH_METHOD.SMS;
  }
  private isOtp(): boolean {
    return this.authMethod === AUTH_METHOD.OTP;
  }

  private isOtpQrMode(): boolean {
    return this.otpMode === OTP_MODE.QR;
  }
  private isOtpKeyMode(): boolean {
    return this.otpMode === OTP_MODE.KEY;
  }
  private isOtpAuthKeyMode(): boolean {
    return this.otpMode === OTP_MODE.AUTH_KEY;
  }

  created() {
    if (!this.isAuth()) {
      this.authMethod = AUTH_METHOD.OTP;
    }

    if (this.isOtp()) {
      if (!this.isAuth() || !this.secondAuthData.target.enableOtp) {
        this.changeOtpMode(OTP_MODE.QR);
      } else {
        this.changeOtpMode(OTP_MODE.AUTH_KEY);
      }
    }
  }

  private async postAdminsOtpQrCode(): Promise<void> {
    const request: PostAdminsOtpQrCodeRequest = { data: {} };
    if (this.isAuth()) {
      request.data.certKey = this.secondAuthData.key;
      request.data.usage = 'LOGIN';
    }
    const { data } = await this.$api.postAdminsOtpQrCode(request);

    this.otpQr = (await getBase64FromBlob(data)) || '';
  }

  private async postAdminsOtpSecret(): Promise<void> {
    const request: PostAdminsOtpQrCodeRequest = { data: {} };
    if (this.isAuth()) {
      request.data.certKey = this.secondAuthData.key;
      request.data.usage = 'LOGIN';
    }

    const { data } = await this.$api.postAdminsOtpSecret(request);

    this.otpSecretEmail = data.email;
    this.otpSecretKey = data.secret;
  }

  private changeOtpMode(mode: OTP_MODE): void {
    this.otpMode = mode;

    if (this.otpMode === OTP_MODE.QR && !this.otpQr) {
      this.postAdminsOtpQrCode();
    } else if (this.otpMode === OTP_MODE.KEY && !this.otpSecretKey) {
      this.postAdminsOtpSecret();
    }
  }

  private openFindOtpKeyPopup(): void {
    this.$emit('openPopup', 'AuthFindOtpKeyPopup');
  }

  private checkMaxLength(e): void {
    const maxLen = 6;
    const blockKeys = ['e', '-', '+'];

    if (blockKeys.includes(e.key)) {
      e.preventDefault();
    }

    this.authKeySync = this.authKeySync.length < maxLen ? this.authKeySync : this.authKeySync.substring(0, maxLen - 1);
  }
}
