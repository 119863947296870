













































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { OrderListTypes } from '@/types/order';
import { Grid as TuiGrid } from '@toast-ui/vue-grid';
import {
  PopupClose,
  PopupResult,
  throwMessagePopup,
  throwNoPopupIdWindowPopup,
  throwPopup,
  throwWindowPopup,
} from '@/helpers/popup';
import { getDefaultParams } from '@/components/shipping/order/mixins/searchQuery';
import store from '@/store';
import { DeleteShippingsCombineRequest, NaverOrderDeliveryMethodType } from 'ncp-api-supporter';
import { writeExcelFile } from '@/utils/webExcel';
import { getDownToday } from '@/utils/dateFormat';
import { throwLoadingSpinner } from '@/helpers/loading';
import {
  Invoice,
  PostOptionCancelsSoldOutRequest,
  PostOrderOptionsCheckDivideShippingRequest,
  PutOrderOptionChangeStatusRequest,
  PutOrderOptionHoldDeliveryRequest,
  PutShippingsAssignInvoicesRequest,
  GetOrderOptionCheckReservationRequest,
} from 'ncp-api-supporter';
import { Row } from '@/types/tui-grid';
import _ from 'underscore';
import { Getter } from 'vuex-class';

interface NaverpayConfigs {
  enables: boolean;
  certiKey?: string;
  merchantId?: string;
  buttonKey?: string;
  naverOrderDeliveryMethodType?: NaverOrderDeliveryMethodType;
}

@Component({ components: { SelectBox } })
export default class ContentButton extends Vue {
  @Getter('order/getSelectedMall') private selectedMall: string | number;
  @Prop({ required: true }) public orderListTypes!: OrderListTypes;
  // @Prop({ required: true }) public deliveryCompanyTypeArr: DeliveryCompany[]; // TODO: 현재 CJ만 제공
  @Prop({ required: true }) public data;
  // @Prop({ required: true }) public showNaverPayDelayButton!: boolean;

  private orderListButtonTypes = this.orderListTypes;
  private selectedValue = '';
  private mallNo = 0;
  private orderStatusTypeArr = [];
  private showNaverPayDelayButton = false;
  private naverConfig: NaverpayConfigs | null = null;

  public selectExcelInfo = {
    columns: {},
    formatters: {},
    fileName: '',
  };

  private deliveryCompanyTypeArr = [
    {
      deliveryCompanyType: 'CJ',
      label: 'CJ대한통운',
    },
  ];

  @Watch('$route.query')
  private updateQuery() {
    this.setButton();
  }

  @Watch('selectedMall', { immediate: false })
  private checkNaverPay() {
    const selectedMall = this.selectedMall;
    this.fetchNaverPayConfig(selectedMall);
    this.setGridButton(this.orderListButtonTypes, 'delayProductOrderByNaverBtn'); // setGridButton로 set하길래 일관성 유지를 위해 사용하긴 하지만 TODO: 추후 리팩토링 대상
  }

  get deliveryCompanyType() {
    return this.deliveryCompanyTypeArr[0].deliveryCompanyType || '';
  }

  private changeStatusBtnClick() {
    if (!this.selectedValue) {
      alert(this.$t('ORDER.LIST_GRIDS.CHECK_ROW_ALERT'));
      return;
    }

    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }

    if (!confirm(this.$t('ORDER.LIST_GRIDS.CONFIRM_HANDLE_CHANGE') as string)) {
      return;
    }

    let holdDeliveryState = false;
    let holdByReservation = false;
    const checkList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    checkList.map(hData => {
      if ((hData as any).holdDelivery) {
        holdDeliveryState = (hData as any).holdDelivery;
      }
      if ((hData as any).holdByReservation) {
        holdByReservation = (hData as any).holdByReservation;
      }
    });
    if (holdDeliveryState) {
      alert(this.$t('ORDER.ORDER_DETAIL.CONFIRM_1'));
      return;
    }
    if (holdByReservation && !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_2') as string)) {
      return;
    }
    this.changeStatusProc(this.selectedValue, checkList);
  }
  public changeStatusProc(targetStatusType, checkedRowList) {
    const requestOp = checkedRowList.map(item => {
      let deliveryCompany;
      if (item.deliveryCompanyType === undefined) {
        deliveryCompany = checkedRowList.find(checkedItem => checkedItem.shippingNo === item.shippingNo)
          .deliveryCompanyType;
      }

      return {
        deliveryCompanyType: item.deliveryCompanyType !== undefined ? item.deliveryCompanyType : deliveryCompany,
        invoiceNo: item.invoiceNo,
        mallNo: item.mallNo,
        orderOptionNo: item.orderProductOptionNo,
      };
    });

    const request: PutOrderOptionChangeStatusRequest = {
      data: {
        orderStatusType: targetStatusType,
        options: requestOp,
        // holdByReservation: this.orderListTypes === 'HOLD_BY_RESERVATION',
      },
    };

    const requestCheckList = checkedRowList.map(item => {
      return {
        orderOptionNo: item.orderProductOptionNo,
      };
    });

    const requestCheckDivide: PostOrderOptionsCheckDivideShippingRequest = {
      data: {
        options: requestCheckList,
      },
    };
    this.$api.postOrderOptionsCheckDivideShipping(requestCheckDivide).then(res => {
      if (targetStatusType === 'DELIVERY_ING') {
        if (res.data.result === true) {
          if (!confirm(this.$t('ORDER.LIST_GRIDS.DIVIDE_DELIVERY_CONFIRM') as string)) {
            return;
          }
        }
      }
      this.$api.putOrderOptionChangeStatus(request).then(() => {
        alert(this.$t('ORDER.LIST_GRIDS.HANDLE_DONE'));
        this.$emit('fetchOrder');
      });
    });
  }

  private confirmStatusPayDoneBtnClick() {
    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }

    if (this.hasNaverPayOrder()) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return;
    }

    if (!confirm(this.$t('ORDER.LIST_GRIDS.CHANGE_STATUS_CONFIRM') as string)) {
      return;
    }
    const checkList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    const requestCheckList = checkList.map(item => {
      return item.orderNo;
    });

    this.$api.putPaymentsConfirm(requestCheckList).then(() => {
      alert(this.$t('ORDER.LIST_GRIDS.PAY_CONFIRM_HANDLE_DONE'));
      this.$emit('fetchOrder');
    });
  }

  private shippingBtnClick() {
    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }
    const checkedRowList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    let hasInvoiceInfos = true;
    let isParcelDelivery = true;
    const theOption = [];

    checkedRowList.forEach((value, index) => {
      if (!value.deliveryCompanyType) {
        if (index !== 0 && checkedRowList[Number(index) - 1].shippingNo === checkedRowList[index].shippingNo) {
          value.deliveryCompanyType = checkedRowList[Number(index) - 1].deliveryCompanyType;
          return;
        }
      }

      // 다수 묶음 처리된 주문 송장번호 저장할 때 에러발생함
      if (
        (value.deliveryCompanyType =
          (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getElement', value.rowKey, 'deliveryCompanyType') === null)
      ) {
        return;
      }
      value.deliveryCompanyType = (this.$parent.$refs.tuiGrid as TuiGrid)
        .invoke('getElement', value.rowKey, 'deliveryCompanyType')
        .querySelector('select').value;

      value.invoiceNo = (this.$parent.$refs.tuiGrid as TuiGrid)
        .invoke('getElement', value.rowKey, 'invoiceNo')
        .querySelector('input').value;

      if (
        value.deliveryType === 'PARCEL_DELIVERY' &&
        value.delivery === true &&
        (this.isEmpty(value.deliveryCompanyType) || this.isEmpty(value.invoiceNo))
      ) {
        hasInvoiceInfos = false;
      } else if (value.deliveryType !== 'PARCEL_DELIVERY') {
        isParcelDelivery = false;
      }
      theOption.push({
        shippingNo: Number(value.shippingNo),
        deliveryCompanyType: value.deliveryCompanyType as string,
        invoiceNo: value.invoiceNo,
      });
    });

    if (!hasInvoiceInfos) {
      alert(this.$t('ORDER.LIST_GRIDS.INVOICE_NO_EMPTY_ALERT'));

      return;
    }
    if (!isParcelDelivery) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.IS_PARCEL_DELIVERY_ALERT'));

      return;
    }

    if (!confirm(this.$t('ORDER.DELIVERY_PREPARE.DO_DELIVERY_CONFIRM') as string)) {
      return;
    }
    const request: PutShippingsAssignInvoicesRequest = {
      data: {
        requestChannelType: 'SERVICE',
        releaseYn: 'N',
        invoices: theOption as Invoice[],
      },
    };
    this.$api.putShippingsAssignInvoices(request).then(() => {
      alert(this.$t('ORDER.LIST_GRIDS.HANDLE_DONE'));
      this.$emit('fetchOrder');
    });
  }
  private scanBtnBtnClick() {
    if (this.data.length > 0) {
      this.mallNo = this.data[0].mallNo;
    }
    window.open(`/pro/shipping/delivery/scanning?mallNo=${this.mallNo}`, 'scanning');
  }
  private holdDeliveryBtnClick() {
    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }

    if (this.hasNaverPayOrder()) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return;
    }

    const checkedRowList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    const hasTaskMessage = checkedRowList
      .map(row => {
        return row.taskMessage;
      })
      .some(value => value === true);
    let message;
    if (hasTaskMessage) {
      message = 'ORDER.LIST_GRIDS.PRODUCT_PREPARE_CHANGE_STATUS_CONFIR_1';
    } else {
      message = 'ORDER.LIST_GRIDS.PRODUCT_PREPARE_CHANGE_STATUS_CONFIRM_2';
    }
    if (!confirm(this.$t(message) as string)) {
      return;
    }
    const orderOptionNos = this.getCheckedItems(['orderProductOptionNo']).map((item: any) => {
      return item.orderProductOptionNo;
    });
    const holdDeliveryRequest: PutOrderOptionHoldDeliveryRequest = {
      params: {
        orderOptionNos: orderOptionNos.join(','),
      },
      data: {},
    };
    this.$api.putOrderOptionHoldDelivery(holdDeliveryRequest).then(() => {
      alert(this.$t('ORDER.LIST_GRIDS.HANDLE_DONE'));
      this.$emit('fetchOrder');
    });
  }

  private async delayProductOrderByNaverBtnClick() {
    const checkedlist = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows') as Row[];

    if (!this.validateNaverDelay(checkedlist)) {
      return;
    }

    const orderProductOptionNos = checkedlist.map(({ orderProductOptionNo }) => orderProductOptionNo);
    await this.$api.getNaverPayValidDelayProductOrder({ params: { orderOptionNos: orderProductOptionNos.toString() } });

    throwWindowPopup('NaverDelay', { orderProductOptionNos }, 'md', result => {
      if (result?.state === PopupClose.CONFIRM) {
        this.$emit('fetchOrder');
      }
    });
  }

  private validateNaverDelay(checkedRows: Row[]) {
    if (checkedRows.length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return false;
    }

    const isAllNaverPay = checkedRows.every(row => row.payType === 'NAVER_PAY');
    if (!isAllNaverPay) {
      alert(this.$t('ORDER.LIST_GRIDS.CHECK_NAVER_PAY_ORDER'));
      return false;
    }

    return true;
  }

  private popupCombineDeliveringBtnClick() {
    const params = { ...getDefaultParams(this.orderListTypes), ...this.$route.query };
    params.mallNos = store.getters['mall/getMalls'].map(d => d.mallNo);
    const info = `keyword=${params.keyword}&searchDateType=${params.searchDateType}&startYmd=${params.startYmd}&endYmd=${params.endYmd}&delay=${params.delay}&mallNos=${params.mallNos}&searchType=${params.searchType}&orderStatusType=${params.orderStatusType}&orderStatusTypes=${params.orderStatusTypes}&claimStatusTypes=${params.claimStatusTypes}&shippingAreaType=${params.shippingAreaType}&payTypes=${params.payTypes}&includeHoldDelivery=${params.includeHoldDelivery}&holdByReservation=${params.holdByReservation}&taskMessageSearchType=${params.taskMessageSearchType}&memberGroupNo=${params.memberGroupNo}&includeReservation=${params.includeReservation}&includeRecommender=${params.includeRecommender}&platformTypes=${params.platformTypes}&deliveryType=${params.deliveryType}&orderRegisterType=${params.orderRegisterType}&page=${params.page}&`;
    throwNoPopupIdWindowPopup(`/pro/popup/delivery/combine?${info}`, 'xlg');
  }
  private cancelCombineBtnClick() {
    let combineDeliveryState = true;
    (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows').map(hData => {
      if (!hData.combineDelivery) {
        combineDeliveryState = false;
      }
    });
    if (!combineDeliveryState) {
      alert(this.$t('ORDER.LIST_GRIDS.CHECK_COMBINE_DELIVERY_STATE_ALERT'));
      return;
    }

    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }
    if (!confirm(this.$t('ORDER.LIST_GRIDS.CANCEL_COMBINE_CONFIRM') as string)) {
      return;
    }
    const theShippingNos = (this.$parent.$refs.tuiGrid as TuiGrid)
      .invoke('getCheckedRows')
      .map(row => {
        return row.shippingNo;
      })
      .join(',');
    const deleteRequest: DeleteShippingsCombineRequest = {
      params: {
        shippingNos: theShippingNos as any,
      },
    };
    this.$api.deleteShippingsCombine(deleteRequest).then(() => {
      alert(this.$t('ORDER.LIST_GRIDS.CONCEL_COMBINE_SUCCESS_ALERT'));
      this.$emit('fetchOrder');
    });
  }
  private downloadInvoiceFormatExcelBtnClick() {
    if (this.data.length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.EXCEL_NO_DATA_ALERT'));
      return;
    }
    const request: any = {
      params: { ...getDefaultParams(this.orderListTypes), ...this.$route.query },
    };
    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      if (!confirm(this.$t('ORDER.LIST_GRIDS.DOWNLOAD_ALL_EXCEL_CONFIRM') as string)) {
        return;
      }
      request.params.size = null;
      request.params.orderNos = null;
      request.params.mallNos = store.getters['mall/getMalls'].map(d => d.mallNo);
      throwLoadingSpinner(true);
      this.$api
        .getOrderOptionsDeliveryReleaseGridExcel(request)
        .then(res => {
          if (res.data.length > 0) {
            this.downloadSelectedInvoiceExcel(res.data, this.deliveryCompanyType);
          } else {
            alert(this.$t('ORDER.LIST_GRIDS.EXCEL_NO_DATA_ALERT'));
          }
        })
        .finally(() => {
          throwLoadingSpinner(false);
        });
    } else {
      request.params.size = null;
      request.params.mallNos = store.getters['mall/getMalls'].map(d => d.mallNo);
      const orderNosArr = [];
      (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows').map(item => {
        if (orderNosArr.indexOf(item.orderNo) < 0) {
          orderNosArr.push(item.orderNo);
        }
      });
      request.params.orderNos = orderNosArr;
      throwLoadingSpinner(true);
      this.$api
        .getOrderOptionsDeliveryReleaseGridExcel(request)
        .then(res => {
          if (res.data.length > 0) {
            this.downloadSelectedInvoiceExcel(res.data, this.deliveryCompanyType);
          } else {
            alert(this.$t('ORDER.LIST_GRIDS.EXCEL_NO_DATA_ALERT'));
          }
        })
        .finally(() => {
          throwLoadingSpinner(false);
        });
    }
  }
  private invoiceFormatBtnClick() {
    throwNoPopupIdWindowPopup('/pro/popup/delivery/invoiceFormat/release', 'xlg');
  }
  private statusCancelBtnClick() {
    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }

    if (this.hasNaverPayOrder()) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return;
    }

    if (!confirm(this.$t('ORDER.LIST_GRIDS.CONFIRM_HANDLE_CANCEL_CHANGE') as string)) {
      return;
    }

    const checkList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');

    const requestCheckList = checkList.map(item => {
      return item.orderNo;
    });
    const request = {
      data: {
        orderNos: requestCheckList as string[],
      },
    };
    this.$api.postOrderCancelsBeforePay(request).then(res => {
      const failOrderNos = res.data.failOrderNos;
      if (failOrderNos === null || failOrderNos.length === 0) {
        alert(this.$t('ORDER.LIST_GRIDS.HANDLE_DONE'));
        this.$emit('fetchOrder');
        return;
      }

      alert(`${this.$t('ORDER.LIST_GRIDS.CANCEL_FAIL')}${res.data.failOrderNos.toString()}`);
    });
  }
  private soldOutCancelBtnBtnClick() {
    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }
    const checkedRowList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    const ids = [];
    let flag = false;
    checkedRowList.forEach(value => {
      if (value.isSetOption === true) {
        flag = true;
      }
      ids.push(value.orderProductOptionNo);
    });
    if (flag) {
      if (!confirm(this.$t('ORDER.LIST_GRIDS.PAY_DONE_SET_CANCEL_CONFIRM') as string)) {
        return;
      }
    } else {
      if (!confirm(this.$t('ORDER.LIST_GRIDS.PAY_DONE_CONFIRM_SET_CANCEL') as string)) {
        return;
      }
    }
    const cancelRequest: PostOptionCancelsSoldOutRequest = {
      data: {
        orderOptionNos: ids,
      },
    };
    this.$api.postOptionCancelsSoldOut(cancelRequest).then(() => {
      alert(this.$t('ORDER.LIST_GRIDS.HANDLE_DONE'));
      this.$emit('fetchOrder');
    });
  }
  private setOptionCancelBtnClick() {
    // TODO: 세트상품 1차 스펙 아웃
  }
  private statusDepositWaitBtnClick() {
    if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }

    if (this.hasNaverPayOrder()) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_IMPOSSIBLE_ALERT').toString());
      return;
    }

    if (!confirm(this.$t('ORDER.LIST_GRIDS.CONFIRM_HANDLE_CHANGE_DEPOSIT') as string)) {
      return;
    }

    const checkList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    this.changeStatusProc('DEPOSIT_WAIT', checkList);
  }
  // private statusPayDoneBtnClick() {
  //   // 예약주문관리 구 '결제완료 처리' 지금은 쓰지 않는데 임시로 주석처리
  //   if ((this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRowKeys').length === 0) {
  //     alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
  //     return;
  //   }
  //
  //   if (!confirm(this.$t('ORDER.LIST_GRIDS.CONFIRM_HANDLE_CHANGE_PAY') as string)) {
  //     return;
  //   }
  //
  //   const checkList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
  //   this.changeStatusProc('PAY_DONE', checkList);
  // }
  private releaseHoldByReservationBtnClick() {
    const checkedRowList = (this.$parent.$refs.tuiGrid as TuiGrid)
      .invoke('getCheckedRows')
      .map(row => row.orderProductOptionNo);
    if (checkedRowList.length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NEED_ORDER_PRODUCT_SELECT'));
      return;
    }
    if (confirm(this.$t('ORDER.LIST_GRIDS.HOLD_RESERVATION_CONFIRM') as string)) {
      const obj: GetOrderOptionCheckReservationRequest = {
        params: {
          orderOptionNos: checkedRowList.join(','),
        },
      };
      this.$api.getOrderOptionCheckReservation(obj).then(({ data }: any) => {
        const wantsToMove =
          (data.reservation === true && confirm(this.$t('ORDER.LIST_GRIDS.CHECKLIST_RESERVATION_TRUE') as string)) ||
          data.reservation === false;
        if (wantsToMove) {
          this.$api.putOrderOptionReserveToNormal(obj).then(() => {
            alert(this.$t('ORDER.ORDER_CONTENT_TOP.PUT_SUCCESS'));
            this.$emit('fetchOrder');
          });
        }
      });
    }
  }
  private async orderDeleteBtnClick() {
    const checkedRows = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows') as Row[];
    const checkedClaimCount = checkedRows.length;

    if (checkedClaimCount === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NEED_ORDER_PRODUCT_SELECT'));
      return;
    }

    const orderNos = this.removeDuplicateOrders(checkedRows);
    const { state }: PopupResult = await throwPopup({
      name: 'RemoveOrderMessage',
      data: {
        listType: 'ORDER',
        checkedClaimCount: orderNos.length,
      },
    });

    if (state === PopupClose.CLOSE) {
      return;
    }

    await this.$api.deleteOrders({
      params: {
        orderNos: orderNos.toString(),
      },
    });

    await throwMessagePopup(
      this.$t('ORDER.LIST_GIRD.DELETE_SUCCESS'),
      false,
      this.$t('ORDER.LIST_GIRD.ORDER_DELETE_TITLE'),
    );
  }
  private releaseHoldDeliveryBtnClick() {
    const checkedRowList = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    if (checkedRowList.length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NOT_CHECK_ROW_ALERT'));
      return;
    }

    if (this.hasNaverPayOrder()) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return;
    }

    const flag = checkedRowList.map(row => row.taskMessage).every(value => value === false);
    let message;
    if (!flag) {
      message = 'ORDER.LIST_GRIDS.CHECKLIST_FALSE';
    } else {
      message = 'ORDER.LIST_GRIDS.CHECKLIST_TRUE';
    }
    if (!confirm(this.$t(message) as string)) {
      return;
    }
    const obj = {
      params: {
        orderOptionNos: checkedRowList.map(row => row.orderProductOptionNo).join(','),
      },
    };
    this.$api.putOrderOptionsReleaseHoldDelivery(obj).then(() => {
      alert(this.$t('ORDER.LIST_GRIDS.PAY_CONFIRM_HANDLE_DONE'));
      this.$emit('fetchOrder');
    });
  }

  private setGridButton(orderListTypes: OrderListTypes, btnType: string) {
    const gridButton = {
      ORDER_LIST: {
        statusBtn: true,
        shippingBtn: true,
      },
      ORDER_LIST_TAB2: {
        statusBtn: true,
      },
      DEPOSIT_WAIT: {
        statusPayDoneBtn: true,
        statusCancelBtn: true,
      },
      PAY_DONE: {
        statusBtn: true,
        statusCancelBtn: false,
        soldOutCancelBtn: true,
        // setOptionCancelBtn: true, // TODO: 1차 세트상품 등록 스펙 아웃
      },
      PRODUCT_PREPARE: {
        statusBtn: true,
        putDeliveryBtn: true,
        holdDeliveryBtn: true,
      },
      DELIVERY_PREPARE: {
        statusBtn: true,
        shippingBtn: true,
        scanBtn: true,
        holdDeliveryBtn: true,
        delayProductOrderByNaverBtn: this.showNaverPayDelayButton,
        popupCombineDeliveringBtn: true,
        cancelCombineBtn: true,
        downloadInvoiceFormatExcelBtn: true,
        invoiceFormatBtn: true,
      },
      DELIVERY_PREPARE_TAB2: {
        statusBtn: true,
        holdDeliveryBtn: true,
        delayProductOrderByNaverBtn: this.showNaverPayDelayButton,
        popupCombineDeliveringBtn: true,
        cancelCombineBtn: true,
      },
      DELIVERY_ING: {
        statusBtn: true,
      },
      DELIVERY_DONE: {
        statusBtn: true,
      },
      BUY_CONFIRM: {
        statusBtn: true,
      },
      PAY_FAIL: {
        orderDeleteBtn: true,
      },
      HOLD_DELIVERY: {
        releaseHoldDeliveryBtn: true,
      },
      HOLD_BY_RESERVATION: {
        statusDepositWaitBtn: true,
        reserveStatusPayDoneBtn: true,
        releaseHoldByReservationBtn: true,
      },
    };
    return gridButton[orderListTypes][btnType];
  }

  private setOrderStatusTypeArr() {
    const orderStatus = [
      {
        name: '주문상태 선택',
        value: '',
      },
      {
        name: '입금대기',
        value: 'DEPOSIT_WAIT',
      },
      {
        name: '결제완료',
        value: 'PAY_DONE',
      },
      {
        name: '상품준비중',
        value: 'PRODUCT_PREPARE',
      },
      {
        name: '배송준비중',
        value: 'DELIVERY_PREPARE',
      },
      {
        name: '배송중',
        value: 'DELIVERY_ING',
      },
      {
        name: '배송완료',
        value: 'DELIVERY_DONE',
      },
      {
        name: '구매확정',
        value: 'BUY_CONFIRM',
      },
    ];

    this.orderStatusTypeArr = orderStatus.filter(item => item.value !== this.orderListTypes);
  }

  public isEmpty(value): boolean {
    return value === null || value === undefined || value === '';
  }

  private getCheckedItems(arr) {
    return (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows').map(item => {
      const theItem = {};
      if (arr.length > 0) {
        arr.forEach(key => {
          theItem[key] = item[key];
        });
      }
      return theItem;
    });
  }

  private setButton() {
    this.orderListButtonTypes = this.orderListTypes;
    if (this.orderListTypes === 'ORDER_LIST' || this.orderListTypes === 'DELIVERY_PREPARE') {
      this.orderListButtonTypes =
        this.$route.query.tabLabel === 'deliveryTab'
          ? this.orderListTypes
          : ((this.orderListTypes + '_TAB2') as OrderListTypes);
    }
  }

  public downloadSelectedInvoiceExcel(data, deliveryCompanyType: string) {
    if (data && data.length > 0) {
      this.getInvoiceFormat(deliveryCompanyType, res => {
        const getDeliveryInvoiceData = res.deliveryInvoiceFormatDetailModels;
        if (getDeliveryInvoiceData.length > 0) {
          getDeliveryInvoiceData.map(item => {
            this.selectExcelInfo.columns[item.propertyName] = item.label;
            for (let i = 0; i < data.length; i++) {
              if (item.propertyLabel === '') {
                data[i][item.propertyName] = item.propertyValue;
              }
            }
          });
        }
        const time = getDownToday().substring(0, 8);
        const downloadCount = 3;
        const name = this.$t('ORDER.LIST_GRIDS.EXCEL_DOWNLOAD_EXCEL_NAME', {
          time: time,
          count: downloadCount,
        }) as string;
        writeExcelFile(data, this.selectExcelInfo.columns, name, true);
      });
    } else {
      alert(this.$t('COMMON_NOT_DATA'));
    }
  }

  public async getInvoiceFormat(deliveryCompanyType, callback: Function): Promise<any> {
    const obj = {
      params: {
        partnerNo: this.$store.getters['admin/admin']?.partnerNo || 0,
        deliveryCompanyType: deliveryCompanyType,
        deliveryInvoiceFormatType: 'RELEASE',
      },
    };
    await this.$api.getInvoiceFormat(obj).then(res => {
      callback(res.data);
    });
  }

  private removeDuplicateOrders(checkedRows: Row[]) {
    return _.unique(checkedRows.map(({ orderNo }) => orderNo));
  }

  async mounted() {
    this.setButton();
    this.setOrderStatusTypeArr();
    await this.fetchNaverPayConfig(this.$route.query.mallNos);
    this.setGridButton(this.orderListButtonTypes, 'delayProductOrderByNaverBtn'); // setGridButton로 set하길래 일관성 유지를 위해 사용하긴 하지만 TODO: 추후 처단대상으로 본다.
  }

  hasNaverPayOrder() {
    const checkedOrders = (this.$parent.$refs.tuiGrid as TuiGrid).invoke('getCheckedRows');
    return checkedOrders.some(({ payType }) => payType === 'NAVER_PAY');
  }

  // 네이버페이 지연처리 버튼 등장 조건: https://nhnent.dooray.com/project/posts/3079736876658384341
  private async fetchNaverPayConfig(selectedMallNo) {
    if (!selectedMallNo) {
      this.showNaverPayDelayButton = false;
      return;
    }

    const { data } = await this.$api.getOrderConfigsNaverpay({ params: { mallNo: selectedMallNo } });
    this.naverConfig = { ...data } as any;
    this.showNaverPayDelayButton = this.naverConfig.enables;
  }
}
