































import { Component, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import { Getter, Mutation } from 'vuex-class';
import { setCookie } from '@/utils/cookie';
import { LNB_SETTING } from '@/const/cookie';
import { addMonth } from '@/utils/dateFormat';
import moment from 'moment';

export const lnbSettingOption: RadioBoxGroupOption<boolean> = {
  name: 'lnb-setting-option',
  data: [
    {
      id: 'open',
      value: true,
      display: 'COMMON.ACCOUNT.ALWAYS_OPEN',
    },
    {
      id: 'close',
      value: false,
      display: 'COMMON.ACCOUNT.HIDE',
    },
  ],
};

@Component({
  components: {
    RadioGroup,
    ModalHeader,
  },
})
export default class LnbSetting extends Vue {
  @Getter('lnb/isLnbShowing')
  private readonly isLnbShowing!: boolean;
  @Mutation('INIT_UNSAVED_FORM')
  private readonly unsavedStatusReset!: () => void;
  @Mutation('lnb/SET_LNB_SHOWING')
  private setLnbShowing!: (isLnbShowing: boolean) => void;

  private status = true;
  private readonly lnbSettingOption = lnbSettingOption;

  created() {
    this.status = this.isLnbShowing;
  }

  onSubmit() {
    const expireDate = moment(addMonth(new Date(), 1)).toDate(); // 기간에 대한 명시가 없어 expire date 1개월 설정
    setCookie(LNB_SETTING, this.status.toString(), expireDate);
    this.unsavedStatusReset();
    this.setLnbShowing(this.status);

    this.$emit('click:close');
  }
}
