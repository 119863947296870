










import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import $ from 'jquery';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN } from '@/const/cookie';
import cookies from 'js-cookie';
import { sendTokenPostMessage } from './util';

@Component({})
export default class AdminIframe extends Vue {
  @Prop({ required: true, default: '' })
  private src: string;

  @Prop() private readonly onLoadIframe!: () => void;

  @Prop({ required: false, default: false })
  private isPopup: boolean;

  @Ref()
  public readonly iFrame: HTMLIFrameElement;

  @Watch('src')
  private updateSrc() {
    this.sendMessageIFrame();
    $('#iframe-loading').show();

    this.iFrame.setAttribute('url', this.src);
  }

  private receiveMessage(e: MessageEvent) {
    if (e.data === 'loaded') {
      this.sendMessageIFrame();
      $('#iframe-loading').hide();
      this.onLoadIframe?.();

      return;
    }

    if (e.data.type === 'frame-size' && e.data.height) {
      this.iFrame.style.height = `${e.data.height}px`;
    }

    if (e.data.type === 'refreshToken') {
      e.data.SHOPBY_ACCESSTOKEN &&
        cookies.set(SHOPBY_ACCESSTOKEN, e.data.SHOPBY_ACCESSTOKEN.value, {
          expires: e.data.SHOPBY_ACCESSTOKEN.expires,
        });
      e.data.SHOPBY_REFRESHTOKEN &&
        cookies.set(SHOPBY_REFRESHTOKEN, e.data.SHOPBY_REFRESHTOKEN.value, {
          expires: e.data.SHOPBY_REFRESHTOKEN.expires,
        });

      return;
    }

    if (e.data.url || e.data.queryParam) {
      const queryString = new URLSearchParams(e.data.queryParam).toString();

      const url = `${e.data.url ?? ''}${queryString ? '?' + queryString : ''}`;

      if (e.data.replace === true) {
        this.$router.push(url);
      } else {
        this.$router.replace(url);
      }
    }
  }

  private sendMessageIFrame() {
    sendTokenPostMessage(this.iFrame);
  }

  mounted() {
    this.iFrame.onload = () => {
      $('#iframe-loading').hide();
    };

    window.addEventListener('message', this.receiveMessage);
  }

  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }
}
