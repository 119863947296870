export default Object.freeze({
  NAME: '상품명',
  NUMBER: '상품번호',
  MANAGEMENT_CODE: '상품관리코드',
  STATUS: '상품상태',

  PLACEHOLDER_KEYWORDS: "복수 상품 검색이 가능합니다. (Enter 또는 ','로 구분)",

  ALERT_KEYWORD_LIMIT_OVER: '검색어는 최대 {0}개까지 입력 가능합니다.',
  ALERT_MAX_CHAR: '검색어는 최대 {0}자까지 입력 가능합니다.',
  ALERT_STOCK_ERROR: '재고수량 입력이 올바르지 않습니다.',

  REGISTER_DATE: '상품등록일',
  MODIFIED_DATE: '상품수정일',
  SALE_START_DATE: '판매시작일',
  SALE_END_DATE: '판매종료일',
  PRE_SALE_START_DATE: '예약판매시작일',
  PRE_SALE_END_DATE: '예약판매종료일',

  SALE_STATUS: '판매상태',
  SALE_STATUS_ALL: '전체',
  SALE_STATUS_WAITING_SALE: '판매대기',
  SALE_STATUS_ON_PRE_SALE: '예약판매중',
  SALE_STATUS_ON_SALE: '판매중',
  SALE_STATUS_END_SALE: '판매종료',

  SALES_SETTING_STATUS_TITLE: '판매설정',
  SALES_SETTING_STATUS: '판매설정상태',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION_SALE: '판매금지',

  SHIPPING_FEE: '배송비',

  DELIVERABLE: '배송여부',
  DELIVERABLE_Y: '배송함',
  DELIVERABLE_N: '배송 안 함',

  DELIVERY_FEE_TYPE: '배송비유형',
  DELIVERY_FEE_TYPE_FREE: '무료',
  DELIVERY_FEE_TYPE_CONDITIONAL_FREE: '조건부 무료',
  DELIVERY_FEE_TYPE_FIXED_FEE: '유료 (고정배송비)',
  DELIVERY_FEE_TYPE_QUANTITY_PROPOSITIONAL_FEE: '수량비례',

  ALLOWS_SHIPPING_TOGETHER: '묶음배송 여부',
  ALLOWS_SHIPPING_TOGETHER_Y: '가능',
  ALLOWS_SHIPPING_TOGETHER_N: '불가능',

  ALLOWS_FRONT_DISPLAY: '전시상태',
  ALLOWS_FRONT_DISPLAY_Y: '전시함',
  ALLOWS_FRONT_DISPLAY_N: '전시 안 함',

  PLATFORM_TYPE: '노출 플랫폼',
  PLATFORM_TYPE_PC: 'PC웹',
  PLATFORM_TYPE_MOBILE_WEB: '모바일웹',

  ONLY_MAPPING_PRODUCT: '상품등록 유형',
  ONLY_MAPPING_PRODUCT_N: '일반상품',
  ONLY_MAPPING_PRODUCT_Y: '세트상품',

  SALE_METHOD_TYPE: '판매방식',
  SALE_METHOD_TYPE_PURCHASE: '사입',
  SALE_METHOD_TYPE_CONSIGNMENT: '위탁',

  MEMBER_GRADE: '노출 회원등급',
  BRAND: '브랜드',
  MERCHANDISER: '담당자',

  SORT_BY: '정렬기준',
  SORT_BY_MODIFY_DESC: '수정일↓',
  SORT_BY_MODIFY_ASC: '수정일↑',
  SORT_BY_STOCK_DESC: '재고수량↓',
  SORT_BY_STOCK_ASC: '재고수량↑',
  SORT_BY_SALE_DESC: '판매량↓',
  SORT_BY_REVIEW_DESC: '상품후기↓',
});
