




import { Vue, Component, Prop } from 'vue-property-decorator';
import TuiPagination from 'tui-pagination';
import { PaginationOption } from '@/types/tui-pagination';

@Component
export default class Pagination extends Vue {
  @Prop({ required: true }) private options!: PaginationOption;

  private pagination;

  mounted() {
    this.pagination = new TuiPagination(this.$refs.pagination, this.options);

    this.pagination.on('afterMove', ({ page }) => {
      this.$emit('change', page);
    });
  }

  destroy() {
    this.pagination.off('afterMove');
  }

  public getCurrentPage(): number {
    return this.pagination.getCurrentPage();
  }
  public movePageTo(targetPage: number): void {
    this.pagination.movePageTo(targetPage);
  }
  public reset(totalItems?: number): void {
    this.pagination.reset(totalItems);
  }
  public setItemsPerPage(itemCount: number): void {
    this.pagination.setItemsPerPage(itemCount);
  }
  public setTotalItems(itemCount: number): void {
    this.pagination.setTotalItems(itemCount);
  }
}
