




































































import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import {
  GetNotificationsConfigurationsRequest,
  GetSmsTemplatesAutoTemplatesTemplateType,
  NCPResponse,
  AutoSmsTemplateType,
  AutoEmailTemplateType,
} from 'ncp-api-supporter';
import { TranslateResult } from 'vue-i18n';
import {
  notificationAvailableInfo,
  notificationMethodCheckboxOption,
  getRadioBoxOption,
  notificationTimeSelectOption,
  NotificationGuideType,
  NotificationGuideTypeKeywords,
} from '@/const/contents/accumulationSetting';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import SmsPoint from '@/views/contents/member/sms/mixins/SmsPoint.vue';
import { NotificationAvailable } from '@/types';
import { getMallNo4Notification } from '@/utils/mall';
import { ValueType } from '@/helpers/type';
import { getToday } from '@/utils/dateFormat';
import moment from 'moment';

@Component({
  components: { CheckboxGroup, SelectBox, RadioGroup },
})
export default class NotificationGuide extends SmsPoint {
  @PropSync('notificationUsable', { default: false })
  private notificationUsableSync!: boolean;
  @PropSync('notificationTime', { default: 30 })
  private notificationTimeSync!: number;
  @PropSync('notificationMethodType', { default: () => ['EMAIL', 'SMS'] })
  private notificationMethodTypeSync!: ValueType[];
  @Prop({ default: NotificationGuideType.expired })
  private readonly type!: NotificationGuideTypeKeywords;
  @Prop({ default: 'ACCUMULATION_EXPIRED' })
  private readonly templateType!: string;
  @Prop({ default: `${getToday()} ${new Date().getHours()}` })
  private readonly paymentTime!: string;
  @Prop({ default: false })
  private readonly disabled!: boolean;
  @Prop({ default: '' })
  private readonly mallNoValue!: string;
  @Prop({ default: false })
  private readonly mallNoMust!: boolean;
  @Prop({ default: true })
  private readonly showSettingInfo!: boolean;
  @Ref()
  private readonly notificationMethodCheckbox!: CheckboxGroup;

  private readonly expireNotificationOption = getRadioBoxOption(
    'radio_expire',
    'SET',
    this.isPaymentDeductType || this.isCouponType,
  );
  private notificationTimeSelectOption = notificationTimeSelectOption();
  private notificationAvailableInfo = notificationAvailableInfo();
  private notificationMethodCheckboxOption = notificationMethodCheckboxOption;
  private notificationMethod = 'SMS, EMAIL';
  private toBeUsedPoint = '1.0';
  private smsNightUsable = false;

  public focusCheckbox() {
    this.notificationMethodCheckbox.focus();
  }

  private get isExpiredType(): boolean {
    return this.type === NotificationGuideType.expired;
  }

  private get isPaymentDeductType(): boolean {
    return this.type === NotificationGuideType.paymentDeduct;
  }

  private get isCouponType(): boolean {
    return this.type === NotificationGuideType.coupon;
  }

  private get isValidationType(): boolean {
    return this.type === NotificationGuideType.validation;
  }

  private get mallNo(): number {
    return this.getMallNoValue();
  }

  public get haveNotification() {
    this.changeNotificationMethodTypeSync();
    return (
      this.showSettingInfo && this.notificationGuideContents.length > 0 && this.getNotificationGuideTitle().length > 0
    );
  }

  @Watch('mallNoValue')
  async onMallNoChanged() {
    sessionStorage.setItem('mallNoValue', this.mallNoValue);
    await Promise.all([
      this.getNotificationsConfigurations(),
      this.getSmsTemplatesAutoTemplatesTemplateType(),
      this.getMailTemplatesAutoTemplates(),
      this.fetchInitConfig(),
    ]);
  }

  @Watch('templateType')
  private async changeTemplateType() {
    sessionStorage.setItem('mallNoValue', this.mallNoValue);
    await Promise.all([this.getSmsTemplatesAutoTemplatesTemplateType(), this.getMailTemplatesAutoTemplates()]);
  }

  @Watch('notificationUsableSync')
  private changeNotificationUsable(curr: boolean, prev: boolean) {
    if (curr && curr !== prev && this.showSettingInfo) {
      this.notificationMethodTypeSync = ['SMS', 'EMAIL'];
    }
  }

  private getMallNoValue(): number {
    if (this.mallNoValue != null && this.mallNoValue != undefined && this.mallNoValue != '') {
      return Number(this.mallNoValue);
    } else {
      return getMallNo4Notification(this);
    }
  }

  @Watch('notificationMethodTypeSync')
  private changeNotificationMethodTypeSync() {
    this.notificationMethod = this.notificationMethodTypeSync.join(',');
    const [sms, email] = this.notificationAvailableInfo;
    sms.bChecked = this.notificationMethodTypeSync.includes('SMS');
    email.bChecked = this.notificationMethodTypeSync.includes('EMAIL');
    this.isPossibleToSend();
  }

  private setNotificationMethodType(value: string) {
    this.notificationMethodTypeSync = value ? value.split(',') : [];
  }

  private getNotificationGuideTitle(): TranslateResult {
    const usedNotification = this.notificationAvailableInfo
      .map(({ key, bUsed, bAutoTemplate, text }, index) => {
        const unavailableNotification =
          this.notificationMethodTypeSync.includes(key) &&
          ((index === 0 && Number(this.toBeUsedPoint) > Number(this.point)) ||
            !bUsed ||
            !bAutoTemplate ||
            this.isCheckNight);

        if (unavailableNotification) return text;
      })
      .filter(value => value)
      .join(' / ');

    if (!usedNotification.length) return '';
    return this.$t('MEMBER.POPUP.NOTICE_ERROR_MSG', [usedNotification]);
  }

  private getAutoTemplatePhrase(value: NotificationAvailable, unUsableNightSms: boolean): string {
    const notUseAutoTemplate = !value.bAutoTemplate ? this.$t('ACCUMULATION.CONFIG_MODIFY.NOTIFICATION_N') : '';
    const unableNightPhrase = unUsableNightSms ? this.$t('ACCUMULATION.CONFIG_MODIFY.NOT_AUTO_NIGHT') : '';
    const phrase = [notUseAutoTemplate, unableNightPhrase].filter(value => value).join(' / ');
    return `${value.text} ${this.$t('ACCUMULATION.CONFIG_MODIFY.NOT_AUTO')} : ${phrase}`;
  }

  private getSmsPointPhrase(): string {
    const pointInfo = {
      case: this.isPaymentDeductType ? '' : `${this.$t('ACCUMULATION.CONFIG_MODIFY.BY_CASE')} `,
      toBeUsedPoint: this.toBeUsedPoint,
      retainPoint: this.point,
    };
    const chargePointText = this.isValidationType
      ? ''
      : `<a class="open_charge">${this.$t('ACCUMULATION.CONFIG_MODIFY.CHARGE_SMS_POINT')}</a>`;

    return `${this.$t('ACCUMULATION.CONFIG_MODIFY.SMS_POINT_REMAIN', pointInfo)} ${chargePointText}`;
  }

  private isUnusableNightSms(key: 'SMS' | 'EMAIL') {
    return key === 'SMS' && !this.smsNightUsable && this.isCheckNight();
  }

  private isNotEnoughSmsPoint(key: 'SMS' | 'EMAIL') {
    return key === 'SMS' && Number(this.toBeUsedPoint) > Number(this.point);
  }

  private get notificationGuideContents(): string[] {
    const contents = [];

    this.notificationAvailableInfo.forEach(value => {
      if (!value.bChecked) return;
      // NOTE: SMS 또는 이메일 사용여부
      if (!value.bUsed) {
        contents.push(`${value.text} ${this.$t('ACCUMULATION.CONFIG_MODIFY.NOT_USE')}`);
      }
      // NOTE: SMS 또는 이메일 자동발송항목 설정
      const unUsableNightSms = this.isUnusableNightSms(value.key);
      if (!value.bAutoTemplate || unUsableNightSms) {
        contents.push(this.getAutoTemplatePhrase(value, unUsableNightSms));
      }
      // NOTE: SMS 포인트 부족
      if (this.isNotEnoughSmsPoint(value.key)) {
        contents.push(this.getSmsPointPhrase());
      }
    });
    return contents;
  }

  private isCheckNight() {
    const currentHour = moment(this.paymentTime).hours();
    const isNightTime = currentHour >= 21 || currentHour <= 8;
    return isNightTime;
  }

  private async getNotificationsConfigurations(): Promise<void> {
    const request: GetNotificationsConfigurationsRequest = {
      params: { mallNo: this.getMallNoValue() },
    };
    const { data } = await this.$api.getNotificationsConfigurations(request);
    const [sms, email] = this.notificationAvailableInfo;
    sms.bUsed = data?.smsUsed ?? false;
    email.bUsed = data?.emailUsed ?? false;
  }

  private async getSmsTemplatesAutoTemplatesTemplateType(): Promise<void> {
    const {
      data,
    }: NCPResponse<GetSmsTemplatesAutoTemplatesTemplateType> = await this.$api.getSmsTemplatesAutoTemplatesTemplateType(
      {
        pathParams: {
          templateType: this.templateType as AutoSmsTemplateType,
        },
        params: {
          mallNo: this.getMallNoValue(),
        },
      },
    );

    if (!data) return;
    this.notificationAvailableInfo[0].bAutoTemplate = data.usable;
    this.smsNightUsable = data.nightUsable;
  }

  private async getMailTemplatesAutoTemplates(): Promise<void> {
    this.notificationAvailableInfo[1].bAutoTemplate = false;

    const { data } = await this.$api.getMailTemplatesAutoTemplates({
      pathParams: {
        templateType: this.templateType as AutoEmailTemplateType,
      },
      params: {
        mallNo: this.getMallNoValue(),
      },
    });

    if (!data) return;
    this.notificationAvailableInfo[1].bAutoTemplate = data.used;
  }

  private isPossibleToSend() {
    const possibleToSend = this.notificationAvailableInfo.every(({ bUsed, bAutoTemplate, bChecked, key }) => {
      if (!bChecked) return true;
      if (this.isNotEnoughSmsPoint(key)) return false;
      if (this.isUnusableNightSms(key)) return false;
      return bUsed && bAutoTemplate;
    });

    this.$emit('possibleToSend', possibleToSend);
  }

  private async init() {
    await Promise.all([
      this.getNotificationsConfigurations(),
      this.getSmsTemplatesAutoTemplatesTemplateType(),
      this.getMailTemplatesAutoTemplates(),
      // this.fetchInitConfig(),
    ]);

    this.isPossibleToSend();
    if (this.type === 'validation') this.changeNotificationMethodTypeSync();
  }

  private registerClickEvent() {
    const linkTag = document.querySelector('.open_charge');
    linkTag?.addEventListener('click', this.openChargePopup);
  }

  created() {
    this.init();
    if (this.mallNoValue != null && this.mallNoValue != undefined && this.mallNoValue != '') {
      sessionStorage.setItem('mallNoValue', this.mallNoValue);
    }
  }

  updated() {
    this.registerClickEvent();
  }

  destroyed() {
    const linkTag = document.querySelector('.open_charge');
    linkTag?.removeEventListener('click', this.openChargePopup);
    sessionStorage.removeItem('mallNoValue');
  }
}
