




import { Vue, Component } from 'vue-property-decorator';
import { claimTypes } from '@/const/claim';
import ContentBottom from '@/components/shipping/claim/ContentBottom.vue';

@Component({
  components: {
    ContentBottom,
  },
})
export default class ReturnList extends Vue {
  private claimType = claimTypes.RETURN;
}
