import { OptionData, GroupOptionData, CheckBoxGroupOption } from '@/helpers/type';
import { ClaimType, SearchDateType } from '@/types/claim';
import { platformTypes } from '@/const/order';
import { claimTypes, searchDateTypes } from '@/const/claim';
import {
  PayTypeCheckValue,
  MemberOptionValue,
  ClaimStatusCheckValue,
  PlatformTypesCheckValue,
  InvoiceNoRegisteredOptionValue,
  ReturnWayTypeValue,
} from './searchOptions.type';
import claimStatusTypes from '@/const/words/claimStatusTypes.ts';
import payType from '@/const/words/payType.ts';

export const enum SortOptionValues {
  // SORT_TYPE_CLAIM_DEFAULT,
  SORT_TYPE_CLAIM_DESC, // 정렬기준(클레임 번호 ↓)
  SORT_TYPE_ORDERER_NAME_DESC, // 주문자명 ↓
  SORT_TYPE_ORDERER_NAME_ASC, // 주문자명 ↑
  SORT_TYPE_AMOUNT_DESC, // 클레임금액 ↓
  SORT_TYPE_AMOUNT_ASC, // 클레임금액 ↑
}

// 모든 클레임을 종합해 환불상태만 뽑아낸 상태, 실제론 클레임상태에 해당되지 않고 리스트에서만 사용되서 여기다 정의
export const enum RefundStatus {
  REQUEST = 'REQUEST',
  WAITING = 'WAITING',
  ADJUST_REQUESTED = 'ADJUST_REQUESTED',
  DONE = 'DONE',
}

const payTypesCheckData = (): GroupOptionData<PayTypeCheckValue>[] =>
  payType.map(({ value, name }) => ({ id: value, value: value, display: name }));

const claimStatusCheckData = (claimType: ClaimType): GroupOptionData<ClaimStatusCheckValue | RefundStatus>[] => {
  if (claimType === claimTypes.REFUND) {
    return [
      { id: '환불보류', value: RefundStatus.REQUEST, display: 'CLAIM.CLAIM_STATUS_TYPE.REFUND_REQUEST' },
      { id: '환불대기', value: RefundStatus.WAITING, display: 'CLAIM.CLAIM_STATUS_TYPE.REFUND_WAITING' },
      {
        id: '조정요청',
        value: RefundStatus.ADJUST_REQUESTED,
        display: 'CLAIM.CLAIM_STATUS_TYPE.REFUND_ADJUST_REQUESTED',
      },
      { id: '환불완료', value: RefundStatus.DONE, display: 'CLAIM.CLAIM_STATUS_TYPE.REFUND_DONE' },
    ];
  }

  return claimStatusTypes
    .filter(claimStatus => claimStatus.value.includes(claimType))
    .map(({ value, name }) => ({ id: value, value: value, display: name }));
};

const searchDateOptions = (claimType: ClaimType): OptionData<SearchDateType>[] => [
  {
    value: searchDateTypes.APPLY,
    name: `CLAIM.ALL_LIST.${claimType}_APPLY_YMDT`,
  },
  {
    value: searchDateTypes.COMPLETE,
    name: `CLAIM.ALL_LIST.${claimType}_COMPLETE_YMDT`,
  },
];

// 프리미엄이랑 다르게 프로에서는 고정값으로 내림, 임시주석처리
const searchKeywordTypeOptions = (claimType: ClaimType): OptionData<string>[] => {
  // 하... 순서 지켜라해서 중복 코드
  if (claimType === claimTypes.REFUND) {
    return [
      { name: '클레임번호', value: 'CLAIM_NO' }, // Default
      { name: '주문번호', value: 'ORDER_NO' },
      { name: '주문자명', value: 'ORDERER_NAME' },
      { name: '주문자연락처', value: 'ORDERER_CONTACT1' },
      { name: '수령자명', value: 'RECEIVER_NAME' },
      { name: '수령자연락처', value: 'RECEIVER_CONTACT1' },
      // { name: '네이버페이 주문번호', value: 'NAVER_PAY_ORDER_NO' }, // 기능 제공때 까지 hidden
      // { name: '페이코 주문번호', value: 'NAVER_PAY_ORDER_NO' }, // 기능 제공때 까지 hidden
    ];
  }

  return [
    { name: '클레임번호', value: 'CLAIM_NO' }, // Default
    { name: '주문번호', value: 'ORDER_NO' },
    { name: '주문자명', value: 'ORDERER_NAME' },
    // { name: '회원번호', value: 'MEMBER_NO' },
    { name: '상품번호', value: 'MALL_PRODUCT_NO' },
    { name: '주문자연락처', value: 'ORDERER_CONTACT1' },
    // { name: '상품관리코드', value: 'PRODUCT_MANAGEMENT_CD' },
    { name: '상품명', value: 'PRODUCT_NAME' },
    // { name: '옵션관리코드', value: 'OPTION_MANAGEMENT_CD' },
    { name: '옵션값', value: 'OPTION_VALUE' },
    // { name: '송장번호', value: 'INVOICE_NO' },
    { name: '수령자명', value: 'RECEIVER_NAME' },
    { name: '수령자연락처', value: 'RECEIVER_CONTACT1' },
    // { name: '네이버페이 주문번호', value: 'NAVER_PAY_ORDER_NO' }, // 기능 제공때 까지 hidden
    // { name: '페이코 주문번호', value: 'NAVER_PAY_ORDER_NO' }, // 기능 제공때 까지 hidden
  ];
};

const platformTypeOptions: CheckBoxGroupOption<PlatformTypesCheckValue> = {
  name: 'platformTypes',
  hasAll: true,
  noBrackets: true,
  nonI18nConvert: false,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    {
      id: 'pc',
      value: platformTypes.PC,
      display: 'PC_WEB',
    },
    {
      id: 'mobileWeb',
      value: platformTypes.MOBILE_WEB,
      display: 'MOBILE_WEB',
    },
    // TODO: 샵바이 프로에서 모바일 앱 기능 제공 시점에 노출 (취소리스트 p.20)
    // {
    //   id: 'mobileApp',
    //   value: platformTypes.MOBILE_APP,
    //   display: 'MOBILE_APP',
    // },
  ],
};

const payTypes: CheckBoxGroupOption<PayTypeCheckValue> = {
  name: 'payTypes',
  hasAll: true,
  noBrackets: true,
  nonI18nConvert: false,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    ...payTypesCheckData(),
  ],
};

const claimStatusOptions = (claimType: ClaimType): CheckBoxGroupOption<ClaimStatusCheckValue | RefundStatus> => {
  return {
    name: 'claimStatusTypes',
    hasAll: true,
    noBrackets: true,
    data: [
      {
        id: 'all',
        value: 'ALL',
        display: 'ALL_CHECK',
      },
      ...claimStatusCheckData(claimType),
    ],
  };
};

const memberOptions: OptionData<MemberOptionValue>[] = [
  {
    value: '',
    name: 'CLAIM.SEARCH_FORM.IS_MEMBER',
  },
  {
    value: true,
    name: 'CLAIM.SEARCH_FORM.MEMBER',
  },
  {
    value: false,
    name: 'CLAIM.SEARCH_FORM.NON_MEMBER',
  },
];

const memberGradeOptions: OptionData<number>[] = [
  {
    value: 0,
    name: 'CLAIM.COMMON.MEMBER_GRADE',
  },
];

const deliveryCompanyOptions: OptionData<string>[] = [
  {
    value: '',
    name: 'CLAIM.COMMON.DELIVERY_COMPANY',
  },
];

const invoiceNoRegisteredOptions: OptionData<InvoiceNoRegisteredOptionValue>[] = [
  {
    value: '',
    name: 'CLAIM.SEARCH_FORM.IS_INVOICE_REGISTERED',
  },
  {
    value: true,
    name: 'CLAIM.SEARCH_FORM.INVOICE_REGISTERED',
  },
  {
    value: false,
    name: 'CLAIM.SEARCH_FORM.NON_INVOICE_REGISTERED',
  },
];

const returnWayTypeOptions: OptionData<ReturnWayTypeValue>[] = [
  {
    value: '',
    name: 'CLAIM.SEARCH_FORM.RETURN_WAY_TYPE_ALL',
  },
  {
    value: 'SELLER_COLLECT',
    name: 'CLAIM.SEARCH_FORM.RETURN_WAY_TYPE_SELLER_COLLECT',
  },
  {
    value: 'BUYER_DIRECT_RETURN',
    name: 'CLAIM.SEARCH_FORM.RETURN_WAY_TYPE_BUYER_DIRECT_RETURN',
  },
];

const sortTypeOptions = (claimType: ClaimType): OptionData<SortOptionValues>[] => [
  {
    value: SortOptionValues.SORT_TYPE_CLAIM_DESC,
    name: 'CLAIM.SEARCH_FORM.SORT_TYPE_STANDARD',
  },
  {
    value: SortOptionValues.SORT_TYPE_ORDERER_NAME_DESC,
    name: 'CLAIM.SEARCH_FORM.SORT_TYPE_ORDERER_NAME_DESC',
  },
  {
    value: SortOptionValues.SORT_TYPE_ORDERER_NAME_ASC,
    name: 'CLAIM.SEARCH_FORM.SORT_TYPE_ORDERER_NAME_ASC',
  },
  {
    value: SortOptionValues.SORT_TYPE_AMOUNT_DESC,
    name: `CLAIM.SEARCH_FORM.SORT_TYPE_${claimType}_AMOUNT_DESC`,
  },
  {
    value: SortOptionValues.SORT_TYPE_AMOUNT_ASC,
    name: `CLAIM.SEARCH_FORM.SORT_TYPE_${claimType}_AMOUNT_ASC`,
  },
];

export const searchFormOptions = (claimType: ClaimType) => ({
  payTypes,
  memberOptions,
  memberGradeOptions,
  platformTypeOptions,
  returnWayTypeOptions,
  deliveryCompanyOptions,
  searchKeywordTypeOptions: searchKeywordTypeOptions(claimType),
  invoiceNoRegisteredOptions,
  sortTypeOptions: sortTypeOptions(claimType),
  searchDateOptions: searchDateOptions(claimType),
  claimStatusOptions: claimStatusOptions(claimType),
});
