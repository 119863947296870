// text helper
import { i18n } from '@/main';
// types
import {
  PartnerRendererProps,
  PartnerRowData,
  RendererMapByContractStatus,
} from '@/views/contents/partner/modification/PartnerModificationTypes';
import { CellRenderer } from '@/types/tui-grid/renderer';
import tuiGrid, { RowKey } from '@/types/tui-grid';
// get html
import {
  getGridTextSpan,
  getRadioContainer,
  getRadio,
  getLabel,
  appendChildren,
} from '@/views/contents/partner/modification/grid/gridHTMLs';

const getAgreedText = () => i18n.t('PARTNER.EDIT.AGREED').toString();
const getDisAgreedText = () => i18n.t('PARTNER.EDIT.DISAGREED').toString();

export const renderPromotionAgreedText = (isPromotionAgreed: boolean) => {
  return {
    getElement(): HTMLElement {
      return getGridTextSpan(isPromotionAgreed ? getAgreedText() : getDisAgreedText());
    },
    render() {
      return;
    },
  };
};

const renderPromotionAgreedRadioGroup = (grid: tuiGrid, rowKey: RowKey, promotionAgreed: boolean) => {
  const elementKey = `promotionAgreed-${rowKey}`;
  const yRadioId = `${elementKey}-Y`;
  const nRadioId = `${elementKey}-N`;

  const promotionAgreedRadioContainer = getRadioContainer(elementKey);

  const promotionAgree = (agree: boolean) => {
    grid.setValue(rowKey, 'promotionAgreed', agree);
  };

  const useRadio = getRadio(yRadioId, elementKey, !!promotionAgreed, () => promotionAgree(true));
  const useLabel = getLabel(yRadioId, getAgreedText());

  const unuseRadio = getRadio(nRadioId, elementKey, !promotionAgreed, () => promotionAgree(false));
  const unuseLabel = getLabel(nRadioId, getDisAgreedText());

  const children = [useRadio, useLabel, unuseRadio, unuseLabel];
  appendChildren(promotionAgreedRadioContainer, children);

  return {
    getElement(): HTMLElement {
      return promotionAgreedRadioContainer;
    },

    render() {
      return;
    },
  };
};

// *** promotionAgreedRendererByContractStatus ***
export default (props: PartnerRendererProps<'promotionAgreed'>): CellRenderer => {
  const contractStatus = props.grid.getValue<PartnerRowData, 'contractStatus'>(props.rowKey, 'contractStatus');

  const rendererMap: RendererMapByContractStatus<'promotionAgreed'> = {
    WAITING({ value: isAgreed }) {
      return renderPromotionAgreedText(isAgreed);
    },

    INVESTIGATION({ grid, rowKey, value: isAgreed }) {
      return renderPromotionAgreedRadioGroup(grid, rowKey, isAgreed);
    },

    ACTIVE({ grid, rowKey, value: isAgreed }) {
      return renderPromotionAgreedRadioGroup(grid, rowKey, isAgreed);
    },
  };
  return rendererMap[contractStatus](props);
};
