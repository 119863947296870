import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { initLogin, initState, detectUnsavedForm } from './hooks';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  base: '/pro', //process.env.VUE_APP_BASE_URL,
  routes,
  linkActiveClass: 'on', // active class for non-exact links.
  linkExactActiveClass: 'on', // active class for *exact* links.
  scrollBehavior: (to, from) => {
    if (to.name === 'ProductEdit' || to.name === 'ProductCopy' || to.name === 'ProductAdd') {
      return { x: 0, y: 0 };
    }
    if (to.path !== from.path) return { x: 0, y: 0 };
  },
});

router.beforeEach(initLogin);
router.beforeEach(detectUnsavedForm);
router.beforeEach(initState);

export default router;

/*
 * meta 가이드
 * 1. custom breadcrumb > meta: { breadcrumb: ['설정', '기본정책', '쇼핑몰 생성'], }
 * 2. unsaved form detection > meta: { detection: true }
 *
 * */
