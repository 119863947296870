



















































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { Mall, Assignees, MallAuthority, GetInquiryTypesInquiryTypeNo, InquiryType } from 'ncp-api-supporter';
import MallSelector from '@/components/MallSelector.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { getCurrentMallNo } from '@/utils/mall';
import TreeContainer from '@/views/contents/board/basic/tree/type/TreeContainer.vue';
import { NestedTreeNode, TreeNode, TreeStoreEvent } from '@/types/tree';
import { replaceKeyNames } from '@/utils/keyReplace';

const tree = namespace('tree');

@Component({
  name: 'InquiriesType',
  components: { MallSelector, TreeContainer, SelectBox, TextInput },
})
export default class InquiriesType extends Vue {
  @Ref('typeTree') private readonly tTree!: TreeContainer;

  private getTreeData() {
    return this.tTree.getTreeData();
  }

  @tree.Action('setTree')
  private readonly setTree!: () => void;
  @tree.Getter('getNode')
  private readonly getNode: TreeNode;
  @tree.Getter('getPath')
  private readonly getPath: string[];
  @tree.Getter('getEvent')
  private readonly getEvent: TreeStoreEvent;
  @Getter('mall/getMalls') private malls!: Mall[];
  private mallNo: number;
  private inquirTypes: InquiryType[] = [];
  private isOrder = false;
  private hasBoard = false;
  private inquirIndex = 0;
  private inquirFirstNo = -1;
  private selectedNodeId = '';
  private selectNodeNo = 1;
  private treeNodes: NestedTreeNode[] = [];
  private defaultSelectNodeIndex = 1;
  private admins: MallAuthority[] = [];
  private tmpAssignees: Assignees[] = [{ name: '', no: -1 }];
  private tmpInquirDetail: GetInquiryTypesInquiryTypeNo = {
    description: '',
    channel: '',
    no: -1,
    assignees: [{ name: '', no: -1 }],
    name: '',
  };
  private origInquirDetail: GetInquiryTypesInquiryTypeNo = {
    description: '',
    channel: '',
    no: -1,
    assignees: [{ name: '', no: -1 }],
    name: '',
  };

  private addTypeTree(): any {
    if (this.inquirTypes.filter(type => type.channel !== 'NAVER_PAY').length >= 50) {
      alert(this.$t('BOARD.INQUIRIES.WARN_TYPE_LENGTH'));
      return false;
    }
    this.selectedNodeId = this.tTree.getSelectedNodeId();
    const obj: InquiryType = {
      name: this.$t('BOARD.INQUIRIES.NEW_TYPE_NAME').toString(),
      no: -1,
      channel: '',
      assignees: [],
    };
    this.$set(obj, 'num', obj.assignees.length);
    this.inquirTypes.push(obj);
    this.setTreeNodes(this.inquirTypes);
    this.$nextTick(() => this.tTree.defaultTreeSelect(this.inquirTypes.length));
    this.$nextTick(() => {
      this.tTree.defaultTreeSelect(this.getTreeData().length);
      this.inquirFirstNo = -1;
      this.onItemSelect(this.inquirFirstNo);
    });
  }

  private getTreeNodeSelectedByNo(no: number): NestedTreeNode {
    const treeDates = this.getTreeData();
    if (no > 0) {
      return treeDates.find(date => date.no === no);
    } else {
      const newDate = treeDates[treeDates.length - 1];
      return newDate;
    }
  }

  private async onClickRemoveBtn() {
    if (this.inquirTypes.length === 0) {
      alert(this.$t('BOARD.INQUIRIES.WARN_LENGTH'));
      return false;
    }

    const request = {
      params: {
        inquiryStatuses: 'ISSUED,IN_PROGRESS',
        mallNo: this.mallNo,
        inquiryTypeNo: this.inquirFirstNo,
        page: 1,
        pageSize: 1,
      },
    };
    const result = await this.$api.getInquiries(request);

    if (result.data.totalCount > 0) {
      alert(this.$t('BOARD.INQUIRIES.WARM_TYPE_DEL_COUNT', { inquiryName: this.getPath[0] }));
      return false;
    }

    // if (this.inquirTypes[this.inquirIndex].channel === 'NAVER_PAY') {
    //   alert(this.$t('BOARD.INQUIRIES.WARN_TYPECHANNEL'));
    //   return false;
    // }

    // if (this.inquirTypes[this.inquirIndex].assignees.length != 0) {
    //   alert(this.$t('BOARD.INQUIRIES.WARN_COUNT'));
    //   return false;
    // }
    if (confirm(this.$t('BOARD.INQUIRIES.CONFIRM_DEL').toString())) {
      this.doDeleteBoard(this.inquirFirstNo);
    }
  }

  private doDeleteBoard(no: number): void {
    if (no <= 0) {
      this.inquirTypes.splice(this.inquirTypes.length - 1, 1);
    } else {
      const request = {
        pathParams: {
          inquiryTypeNo: no.toString(),
        },
        params: {
          mallNo: this.mallNo,
        },
      };
      this.$api.deleteInquiryTypesInquiryTypeNo(request).then((): void => {
        this.getInquirTypes();
      });
    }
  }

  private async onOrderUp(no: number) {
    if (no > 0) {
      if (this.inquirTypes[0].no !== no) {
        // order 변경 시 sequence를 0부터가 아닌 1부터 수정하도록 변경
        this.selectNodeNo = this.tmpInquirDetail.no;
        await this.doChangeInquirOrder(no, +1);
      }
    }
  }
  private async onOrderDown(no: number) {
    if (no > 0) {
      if (this.inquirTypes[this.inquirTypes.length - 1].no !== no) {
        // order 변경 시 sequence를 0부터가 아닌 1부터 수정하도록 변경
        this.selectNodeNo = this.tmpInquirDetail.no;
        await this.doChangeInquirOrder(no, -1);
      }
    }
  }

  private async doChangeInquirOrder(no: number, step: number) {
    const reverseIndex = this.inquirTypes.length - this.inquirTypes.findIndex(item => item.no === no) - 1;
    const request = {
      pathParams: {
        inquiryTypeNo: no.toString(),
      },
      data: {
        newOrder: reverseIndex + step,
        mallNo: this.mallNo,
      },
    };
    await this.$api.putInquiryTypesInquiryTypeNoOrder(request).then(() => {
      this.getInquirTypes();
    });
  }

  private onDetailNameChange(nameInfo: any): any {
    if (this.inquirTypes[this.inquirIndex].channel === 'NAVER_PAY') {
      return;
    }
    if (nameInfo.type === 'list') {
      this.tmpInquirDetail.name = nameInfo.name;
    } else {
      if (this.inquirIndex === -1) {
        this.inquirTypes[0].name = nameInfo.name;
      } else {
        this.inquirTypes[this.inquirIndex].name = nameInfo.name;
      }
    }
  }

  private async onItemSelect(inquirNo: number) {
    if (inquirNo >= 0) {
      await this.refreshGradeDetail(inquirNo);
    } else {
      const defaultAdmin = this.admins.find(admin => admin.role === 'MASTER');
      this.tmpInquirDetail = {
        description: '',
        channel: '',
        no: -1,
        assignees: [
          {
            name: defaultAdmin ? defaultAdmin.name : '',
            no: defaultAdmin ? defaultAdmin.no : -1,
          },
        ],
        name: '',
      };
      this.tmpAssignees = JSON.parse(JSON.stringify(this.tmpInquirDetail.assignees));
    }
  }

  private async refreshGradeDetail(inquirNo: number) {
    const request = {
      pathParams: {
        inquiryTypeNo: inquirNo.toString(),
      },
      params: {
        mallNo: this.mallNo,
      },
    };
    await this.$api.getInquiryTypesInquiryTypeNo(request).then((ret): void => {
      this.origInquirDetail = JSON.parse(JSON.stringify(ret.data));
      this.tmpInquirDetail = ret.data;
      if (!this.tmpInquirDetail.assignees || this.tmpInquirDetail.assignees.length == 0) {
        this.tmpInquirDetail.assignees = [
          {
            name: '',
            no: -1,
          },
        ];
        this.origInquirDetail.assignees = [
          {
            name: '',
            no: -1,
          },
        ];
      }

      this.tmpAssignees = JSON.parse(JSON.stringify(this.tmpInquirDetail.assignees));
    });
  }

  private addAssignees(): void {
    // 대표운영자
    const newAssigness: Assignees = {
      name: '',
      no: -1,
    };
    this.tmpAssignees.push(newAssigness);

    if (this.tmpAssignees.length >= 100) {
      alert(this.$t('BOARD.INQUIRIES.WARN_ADMIN_OVER100'));
    }
    this.tmpInquirDetail.assignees = JSON.parse(JSON.stringify(this.tmpAssignees));
  }

  private delAssignees(index: number): void {
    this.tmpAssignees.splice(index, 1);
    this.tmpInquirDetail.assignees = JSON.parse(JSON.stringify(this.tmpAssignees));
  }

  private selectValid(index: number) {
    if (this.tmpInquirDetail.assignees.some(assignee => this.tmpAssignees[index].no === assignee.no)) {
      alert(this.$t('BOARD.INQUIRIES.WARN_SAME_ADMIN'));
    }
    const newAdmin = this.admins.find(admin => admin.no === this.tmpAssignees[index].no);
    if (newAdmin) {
      this.tmpAssignees[index].name = newAdmin.name;
    }

    this.tmpInquirDetail.assignees = JSON.parse(JSON.stringify(this.tmpAssignees));
  }

  private doSetting(): void {
    if (this.tmpInquirDetail.name === '') {
      alert(this.$t('BOARD.INQUIRIES.WARN_TYPENAME'));
      return;
    }

    if (this.tmpInquirDetail.assignees.length == 0) {
      alert(this.$t('BOARD.INQUIRIES.WARN_ADMINS'));
      return;
    }

    if (this.tmpInquirDetail.assignees.find(assignee => !assignee.no || assignee.no <= 0)) {
      alert(this.$t('BOARD.INQUIRIES.WARN_ADMINS'));
      return;
    }

    if (this.inquirTypes.filter(type => type.channel !== 'NAVER_PAY').length == 0) {
      alert(this.$t('BOARD.INQUIRIES.WARN_NO_TYPE'));
      return;
    }

    if (confirm(this.$t('BOARD.INQUIRIES.CONFIRM_SAVE').toString())) {
      if (this.tmpInquirDetail.no <= 0) {
        this.doAddInquiry();
      } else {
        this.doModifyInquirDetail();
      }
    }
  }

  private async doAddInquiry() {
    const request = {
      data: {
        mallNo: this.mallNo,
        inquiryTypeName: this.tmpInquirDetail.name,
        inquiryTypeDescription: this.tmpInquirDetail.description,
        // assigneeNos: this.getAssigneeNos(this.tmpInquirDetail.assignees),
        assigneeNos: this.tmpInquirDetail.assignees.filter(assignee => assignee.no).map(assignee => assignee.no),
        // assigneeNos: this.tmpInquirDetail.assignees.map(assignee => {if(assignee.no){assignee.no}}).join(',') // 타입이 string이던데 원하는 형태가 이 형태일까요?
      },
    };

    await this.$api.postInquiryTypes(request).then((): void => {
      alert(this.$t('BOARD.INQUIRIES.SUCCESS_MOD'));
      this.getInquirTypes();
    });
  }

  private async doModifyInquirDetail() {
    const request = {
      pathParams: {
        inquiryTypeNo: this.tmpInquirDetail.no.toString(),
      },
      data: {
        mallNo: this.mallNo,
        inquiryTypeName: this.tmpInquirDetail.name,
        inquiryTypeDescription: this.tmpInquirDetail.description,
        assigneeNos: this.tmpInquirDetail.assignees.filter(assignee => assignee.no).map(assignee => assignee.no),
      },
    };

    await this.$api.putInquiryTypesInquiryTypeNo(request).then(() => {
      alert(this.$t('BOARD.INQUIRIES.SUCCESS_MOD'));
      this.getInquirTypes();
    });
  }

  private getAdmins(): void {
    const authRequest = {
      params: {
        mallNo: this.mallNo,
        includeMaster: true,
      },
    };
    this.$api.getAdminsServiceMallAuthority(authRequest).then((result): void => {
      this.admins = result.data;
    });
  }

  private lengthInUtf8Bytes(str): number {
    const m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str ? str.length : 0 + (m ? m.length : 0);
  }

  private getWordCount(msg, maxByte): number[] {
    let letter;
    let msgByte = 0;
    let msgLength = 0;
    const obj = [];

    for (let i = 0; i < msg.length; i++) {
      letter = msg.charAt(i);
      msgByte += this.lengthInUtf8Bytes(letter);

      msgLength += 1;
      if (msgByte >= maxByte) {
        obj[0] = msgLength;
        obj[1] = msgByte;
        // obj[1] = maxByte;

        return obj;
      }
    }
    obj[0] = msgLength;
    obj[1] = msgByte;

    return obj;
  }

  private onChangeContent(msg, maxByte): void {
    this.tmpInquirDetail.description = this.tmpInquirDetail.description.substring(
      0,
      this.getWordCount(msg, maxByte)[0],
    );
  }

  private async getInquirTypes() {
    const templateRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    await this.$api.getInquiryType(templateRequest).then((result): void => {
      this.inquirTypes = result.data;
      this.inquirTypes.forEach(type => {
        this.$set(type, 'num', type.assignees.length);
      });
      this.selectNodeNo = this.tmpInquirDetail.no;
      this.setTreeNodes(this.inquirTypes);
      this.$nextTick(() => {
        this.getTreeNodeSelectedByNo(this.selectNodeNo).index;
        this.tTree.defaultTreeSelect(this.getTreeNodeSelectedByNo(this.selectNodeNo).index);
        this.inquirFirstNo = this.selectNodeNo;
        this.onItemSelect(this.inquirFirstNo);
      });
    });
  }

  private async getInquirTypesforint() {
    const templateRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    await this.$api.getInquiryType(templateRequest).then((result): void => {
      this.inquirTypes = result.data;
      this.inquirTypes.forEach(type => {
        this.$set(type, 'num', type.assignees.length);
      });
      this.setTreeNodes(this.inquirTypes);
      this.inquirFirstNo = this.inquirTypes ? this.inquirTypes[0].no : -1;
      this.onItemSelect(this.inquirFirstNo);
    });
  }

  private setTreeNodes(templates: InquiryType[]): void {
    this.treeNodes = replaceKeyNames<NestedTreeNode>(templates, { name: 'nodeName', num: 'num' });
    this.setTree();
  }

  private init(): void {
    this.getAdmins();
    this.getInquirTypesforint();

    throwBottomNavigation({
      buttons: [
        {
          type: 'right',
          key: 'save',
          color: 'red',
          text: this.$t('SAVE'),
        },
      ],
      onClick: (key: string) => {
        switch (key) {
          case 'save': //do something;
            this.doSetting();
            break;
        }
      },
    });
  }

  @Watch('$route')
  private onQueryChanged(): void {
    if (this.$route.query.mallNo) {
      this.mallNo = Number(this.$route.query.mallNo);
    } else {
      this.mallNo = getCurrentMallNo(this);
    }
    this.init();
  }

  @Watch('getPath')
  private changeNode(): void {
    if (this.getNode) {
      // todo tree leave with changed
      // if (this.isChanged(this.tmpInquirDetail, this.origInquirDetail)) {
      //   if (confirm(this.$t('BOARD.INQUIRIES.CONFIRM_LEAVE').toString())) {
      //     this.inquirFirstNo = this.getNode.no;
      //     this.onItemSelect(this.inquirFirstNo);
      //     this.selectedNodeId = this.tTree.getSelectedNodeId();
      //   } else {
      //     this.tTree.select(this.selectedNodeId);
      //   }
      // } else {
      this.inquirFirstNo = this.getNode.no;
      this.onItemSelect(this.inquirFirstNo);
      this.selectedNodeId = this.tTree.getSelectedNodeId();
      this.selectNodeNo = this.tmpInquirDetail.no;
      // }
    }
  }

  @Watch('getEvent.eventCount', { deep: true })
  subscribeEvent(): void {
    const eventKeyword = this.getEvent.eventName;
    switch (eventKeyword) {
      case 'ADD_NODE_TYPE': {
        this.addTypeTree();
        break;
      }
      case 'REMOVE_NODE_TYPE': {
        this.onClickRemoveBtn();
        break;
      }
      case 'UP_NODE': {
        this.onOrderUp(this.inquirFirstNo);
        break;
      }
      case 'DOWN_NODE': {
        this.onOrderDown(this.inquirFirstNo);
        break;
      }
    }
  }

  created() {
    this.mallNo = getCurrentMallNo(this);
    this.init();
  }
}
