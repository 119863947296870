






































































import { Component, Watch, Ref, Mixins } from 'vue-property-decorator';
import ArticleGrid from '@/components/common/grid/Main.vue';
import { Mall, GetBoards } from 'ncp-api-supporter';
import { Row } from '@/types/tui-grid/store/data';
import { Getter } from 'vuex-class';
import { getArticleListGridOption, getArticleListInitQuery, displayOptions, getMemberNo } from '@/const/contents/board';
import {
  CheckGridEventProps,
  GridEventProps,
  DefaultGridContainerOption,
  PopupParameters,
  ArticleListQuery,
} from '@/types';
import { throwMessagePopup, PopupClose, throwWindowPopup, throwPopup, throwExternalWindowPopup } from '@/helpers/popup';
import ArticleListMixin from '@/components/board/basic/mixins/ArticleListMixin';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import ExcelCreateButton from '@/components/common/grid/ExcelCreateButton.vue';

@Component({
  components: {
    ArticleGrid,
    ExcelCreateButton,
  },
})
export default class ArticleList extends Mixins(ArticleListMixin) {
  @Getter('mall/getMalls') private malls!: Mall[];
  @Ref('articleGrid') private readonly grid: ArticleGrid;

  // grid columns and options
  private gridProps = getArticleListGridOption();
  private displayOptions: DefaultGridContainerOption = displayOptions;
  private boardNos: GetBoards[] = [];
  private subBtnShow = false;
  private selected = [];

  //---- create method in vue life cycle.-----
  created() {
    this.search();
  }

  mounted() {
    // add listener for refreshing parent page, listen reply popup saved
    // 제목 link to open edit article popup -> clicking reply button to open reply popup
    window.addEventListener('message', this.refreshByChildren);
  }

  // ---- watch router changed. ------
  // refresh grid when router changed.
  // ---------------------------------
  @Watch('$route')
  private onQueryStringChanged(): void {
    // refresh article list data (grid)
    this.search();
  }

  // ----- Get searching result data.-----
  // 1.request parameter init. for api.
  // 2.set grid data from getting article list by calling api
  // -------------------------------------
  private async search(): Promise<void> {
    // 1.
    await this.initQuery();
    // 2.
    this.getArticleList();
  }

  /**
   * ---- searching parameter initialization.----
   */
  private async initQuery(): Promise<void> {
    // reset query parameters
    this.query = { ...getArticleListInitQuery() };
    // get parameter from route 'query'.
    Object.assign(this.query, this.$route.query);

    // init. period date time.
    this.query.startDateTime = this.query.startYmdt + ' ' + DEFAULT_TIME_RANGE.START;
    this.query.endDateTime = this.query.endYmdt + ' ' + DEFAULT_TIME_RANGE.END;

    // init. parameter :boardNo at first searching.(board select-box value)
    this.boardNos = await this.getBoardNos(this.query.mallNo);
    if (this.query.boardNo === -1) {
      this.query.boardNo = this.boardNos[0].boardNo;
    }
  }

  /**
   * ----Delete article in grid data (article list).----
   * 1. get seleted article data in grid for deleting
   * 2. delete to be seleted article by calling api and refresh grid data with callback.
   * ----------------------------------------------------
   */
  private clickDeleteBtn(): void {
    // 1
    const selectedArticles = this.grid.getCheckedRowKeys();
    if (selectedArticles.length === 0) {
      throwMessagePopup(this.$t('BOARD.BASIC.ARTICLE.SELECT_ARTICLE'), true, null, true);
      return;
    }

    // get parameters for api of delete aticle.ex:['2332','3538']
    const param = selectedArticles.map(key => (this.grid.getRowAt(key) as Row).no).join(',');

    // 2
    this.deleteArticle(param, this.getArticleList);
  }

  /**
   * ----- To open article moving popup page ------
   * 1. Get&Check parameters data for popup page.
   * 2. Call common methode to open popup page
   * ---------------------------------------------
   */
  private clickMoveBtn(): void {
    // 1
    const mallNo = this.query.mallNo;
    // get selected row data for moving
    const selectedNumbers = this.grid.getCheckedRowKeys();
    // check whether to seleted
    if (selectedNumbers.length === 0) {
      throwMessagePopup(this.$t('BOARD.BASIC.ARTICLE.SELECT_ARTICLE'), true, null, true);
      return;
    }
    // get nos of seleted rows. ex:['2233','1578']
    const queryString = selectedNumbers.map(key => (this.grid.getRowAt(key) as Row).no).join(',');
    const boardNo = selectedNumbers.map(key => (this.grid.getRowAt(key) as Row).boardNo)[0];

    // get moving article title.
    let movedArticleTitle = '';
    if (selectedNumbers.length === 1) {
      // title for only moving one article
      movedArticleTitle = this.contents.filter(item => item.no == queryString)[0].title;
    } else {
      // title for moving multipule article. (moving article counted)
      movedArticleTitle = selectedNumbers.length + this.$t('CASE').toString(); //'건';
    }
    //2
    // layer popup
    throwPopup({
      name: 'ArticleMovePopup', // popup page name.
      data: {
        // popup parameter data (props)
        name: movedArticleTitle,
        mallNo: mallNo,
        articleNumbers: queryString,
        boardNo: boardNo,
      },
    }).then((result: { state: string; data: object }) => {
      //callback logic when popup close.
      if (result && result.state === PopupClose.CONFIRM) {
        //this.search();
        this.getArticleList();
      }
    });
  }

  /**
   * Parameters of getting all article list.
   * includeReplies is true => all article (include reply)
   */
  get allArticleListParam() {
    const param = { ...this.getExcleDownloadParameter() };
    param.includeReplies = true;
    return param;
  }

  /**
   * Parameters of getting top article list.
   * includeReplies is false =>top article (not include reply)
   */
  get articleListParam() {
    const param = { ...this.getExcleDownloadParameter() };
    param.includeReplies = false;
    return param;
  }

  /**
   * excel download parameter [TODO-TEST excel download]
   */
  private getExcleDownloadParameter(): ArticleListQuery {
    this.query.pageSize = null;
    this.query.page = 1;
    //this.query.includeReplies = isInculde;
    delete this.query.startYmdt;
    delete this.query.endYmdt;
    return this.query;
  }

  /**
   * reply view popup(layer popup)[unused]
   */
  private replyViewPopup(rowData: Row): void {
    const mallName = this.malls.filter(m => m.mallNo === Number(this.query.mallNo))[0].mallName;
    const boardName = this.boardNos.filter(b => b.boardNo === rowData.boardNo)[0].boardName;
    throwPopup({
      name: 'ArticleReplyViewPopup', // popup page name.
      data: {
        // popup parameter data (props)
        mallNo: this.query.mallNo,
        mallName: mallName,
        boardName: boardName,
        row: rowData,
      },
    }).then(result => {
      //callback logic when popup close.
      if (result) {
        // refresh
        this.search();
      }
    });
  }

  /**
   * reply view popup [widow.open]
   */
  private replyView(rowData: Row): void {
    const mallName = this.malls.filter(m => m.mallNo === Number(this.query.mallNo))[0].mallName;
    const boardName = this.boardNos.filter(b => b.boardNo === rowData.boardNo)[0].boardName;
    const popupData = { mallNo: this.query.mallNo, mallName: mallName, boardName: boardName, row: rowData };
    throwWindowPopup('ArticleReplyView', popupData, 'xlg', this.detailCallback, `replyView_${rowData.no}`);
  }

  /**
   * --------article detail popup for (article create,edit,reply,reply-edit)-----
   * 1. generate parameter for popup
   * 2. opening article detail popup by calling common method.
   * ---------------------------------------------------------------------------
   */
  private articleDetail(rowData: Row, mode = 'create', name?: string): void {
    // 1
    const popupData: PopupParameters = {
      mallNo: this.query.mallNo,
      boardNo: this.query.boardNo,
      mode: mode,
      row: rowData,
    };
    // 2
    throwWindowPopup('ArticleDetail', popupData, 'lg', this.detailCallback, name);
    //article.articleDetailPopup(popupData, this.search);
  }

  /**
   * ----- callback method for article detail page popup [window popup] ----
   */
  public detailCallback(callbackData: { state: string; data: object }): void {
    if (callbackData.state === 'close') return;
    const result = callbackData.data;
    if (result) {
      // refresh articl list of grid.
      //this.search();
      this.getArticleList();
    }
  }

  /**
   * ---- refresh article list for childern reply popup saved -----
   * {self of article list page
   *    childern:{reply view popup,
   *                children:{areticle edit popup, (제목 link to open)
   *                     children:{ reply popup}
   *                  }
   *              }
   * }
   */
  private refreshByChildren(_event: MessageEvent): void {
    if (_event.data === 'refresh_articleList') {
      this.getArticleList();
    }
  }

  /**
   * ------ Open popup page to do logic when clicking grid cell---------
   * 1. open reply-view window popup (click cell link in '답글수' cloumn of grid)
   * 2. open article detail window popup (edit,reply)
   *    (click cell link in '게시글 번호','제목','답글' cloumn of gird)
   * 3. open crm popup page (작성자가,회원 또는 휴면회원인 경우 )
   * grid columns refer getArticleListGridOption @/const/contents/board.ts
   *-------------------------------------------------------------------
   */
  private onItemClicked(gridProps: GridEventProps): void {
    const rowData: Row = this.grid.getRowAt(gridProps.rowKey);

    // clicking header cell to return.
    if (rowData === null) {
      return;
    }
    switch (gridProps.columnName) {
      case 'replyCount': //답글수
        // 1.
        rowData.replyCount > 0 ? this.replyView(rowData) : null;
        break;
      case 'no': //게시글 번호
      case 'title': //제목
        // 2.
        this.articleDetail(rowData, 'edit', `article_${rowData.no}`);
        break;
      case 'reply': //답글
        // 2.
        rowData.replyUsed && !rowData.deleted ? this.articleDetail(rowData, 'reply') : null;
        break;
      case 'writer': //작성자
      case 'editor': //최종수정자
        // 3 crm
        this.openMemberCrm(rowData, gridProps.columnName);
        break;
    }
  }
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
  }

  private showSubBtn() {
    this.subBtnShow = !this.subBtnShow;
  }

  private addLight($event) {
    $event.currentTarget.className = 'on';
  }
  private removeLight($event) {
    $event.currentTarget.className = '';
  }

  /**
   * ----- open member crm popup ()--------------
   * 1. get memberNo for crm popup
   * 2. call common method to open member crm popup.
   * --------------------------------------------
   */
  private openMemberCrm(rowData: Row, category: string): void {
    // 1
    const memberNo = getMemberNo(rowData, category);
    // not open crm without member no.
    if (!memberNo) return;

    // 2
    throwExternalWindowPopup(`/pro/crm/${memberNo}`, 'xlg');
  }

  private destroy() {
    window.removeEventListener('message', this.refreshByChildren);
  }
}
