







































import { Component } from 'vue-property-decorator';
import WindowPopupMain from '@/views/popups/Main.vue';
@Component
export default class DeliveryAmtDetail extends WindowPopupMain {
  private orderInfo: any = {
    baseDeliveryAmt: 0,
    areaDeliveryAmt: 0,
    totalDeliveryAmt: 0,
  };
  private isLast = false;
  public created(): void {
    this.checkFirstOrLast();
  }
  private checkFirstOrLast(): void {
    this.isLast = this.data.orderType === 'last';
  }
  private getOrderInfo(): void {
    const orderRequest: any = {
      params: {
        orderNo: this.data.orderNo as string,
      },
    };
    this.$api.getOrdersByOrderNo(orderRequest).then((res: any): any => {
      const order = res.data;
      let baseDeliveryAmt = 0;
      let areaDeliveryAmt = 0;
      const totalDeliveryAmt =
        order.lastBalance.deliveryAmt +
        order.lastBalance.remoteDeliveryAmt -
        order.lastBalance.deliveryCouponDiscountAmt;
      order.shippings.map((item: any): any => {
        if (item.prepaid === false) {
          baseDeliveryAmt += 0;
          areaDeliveryAmt += 0;
        } else {
          baseDeliveryAmt += Number(item.adjustedDeliveryAmt);
          areaDeliveryAmt += Number(item.adjustedRemoteDeliveryAmt);
        }
      });
      if (this.isLast) {
        this.orderInfo = {
          baseDeliveryAmt: baseDeliveryAmt.toLocaleString(),
          areaDeliveryAmt: areaDeliveryAmt.toLocaleString(),
          totalDeliveryAmt: totalDeliveryAmt.toLocaleString(),
          returnExchangesDeliveryAmt: (totalDeliveryAmt - areaDeliveryAmt - baseDeliveryAmt).toLocaleString(),
        };
      } else {
        this.orderInfo = {
          shippingNo: order.shippings[0].shippingNo,
          baseDeliveryAmt: order.firstBalance.deliveryAmt.toLocaleString(),
          areaDeliveryAmt: order.firstBalance.remoteDeliveryAmt.toLocaleString(),
          totalDeliveryAmt: (
            order.firstBalance.deliveryAmt +
            order.firstBalance.remoteDeliveryAmt -
            order.firstBalance.deliveryCouponDiscountAmt
          ).toLocaleString(),
        };
      }
    });
  }

  public mounted() {
    this.getOrderInfo();
    this.$nextTick(() => this.resize(30, 76));
  }
}
