export default Object.freeze({
  FORWARDINGPRIOR: '출고 전 교환신청',
  BACKWARDINGPRIOR: '출고 후 교환신청',
  EXDESC1: '교환방법에 따라 ‘동일상품교환’ 또는 ‘다른상품교환’ 버튼을 클릭합니다.',
  CLAIMTEXT3:
    '동일상품교환 : 별도의 교환출고상품을 선택하지 않고 교환취소상품과 동일한 상품으로 교환 처리합니다.(단, 옵션변경 가능)',
  EXDESC4: '교환처리금액이 추가로 발생하거나 부분환불 될 수 있습니다.',
  EXDESC6:
    "환불 : '교환처리금액이 음수(-)인 경우에 해당하며 최초 결제 시 결제수단 또는 현금으로 환불처리 할 수 있습니다.",
  CLAIMTEXT4:
    "추가결제 : '교환처리금액이 양수(+)인 경우에 해당하며 무통장입금 또는 적립금차감으로 결제처리 할 수 있습니다.",
  CLAIMTEXT2:
    '단, 일부 신용카드 또는 휴대폰결제 등 경우에 따라 부분취소가 안될 경우 환불방법을 ‘현금환불’로 변경하여 현금으로 환불처리 해주세요.',
  CLAIMTEXT5: '반품 후 수거된 상품의 재고는 [재고복원]신청한 경우에만 환불완료 이후 자동으로 복원처리 됩니다.',
  CLAIMTEXT6:
    '네이버페이 주문형 주문의 NCP 화면 내 금액은 예상 금액이며, 네이버페이의 조정 및 정책에 따라 변경될 수 있습니다.',
  CLAIMTEXT7: '네이버페이 주문형 주문의 최종 환불 금액은 네이버페이 판매자센터를 통해 확인해 주세요.',
  CLEARINGOFF: '교환취소상품',
  COL999: '교환구분/사유',
  EXDESC3: '다른상품교환 : 교환출고상품을 직접 선택하여 교환 처리합니다.',
  FORWARDINGPRODUCT: '교환출고상품',
  DELETE: '삭제',
  FOLD: '접기',
  EXPAND: '펼치기',
  RETURN_INFO: '반품정보',
  SAMEPRODUCT: '동일상품교환',
  SAME_OPTION: '동일옵션교환',
  DIFFENTPRODUCT: '다른상품교환',
  CHOICE: '선택',
  PARTNERNAME: '파트너사',
  MALLPRODUCTNO: '상품번호',
  PRODUCTNAME: '상품명',
  ORDERQUANTITY: '주문수량',
  IMMEDIATE_DISCOUNT: '즉시할인가',
  COL6: '판매가<br>(할인적용가)',
  COL7: '상품합계',
  NOEXCHANGEITEM: '교환 상품이 없습니다.',
  DETAILSDIFFERENCE: '교환상품 차액상세',
  EXCHANGEAMOUNT: '교환상품금액',
  DIFFERENCECOUNT: '상품합계차액',
  BARTERGOODS: '교환상품',
  MERCHANDISE2: '취소상품',
  ANNUALCASH: '추가할인차액',
  ADJUSTMENTOPTIONAL: '금액조정(선택)',
  ADJUSTMENT: '조정사유',
  RECALCULATION: '상품쿠폰 재계산',
  RESTORE: '복원예정',
  EXCHANGEAMOUNTA: '교환금액합계 ⓐ',
  APPLYEXCHANGE: '교환신청',
  WITHDRAWAL: '철회',
  REPLACEMENT: '교환상품 배송비상세 및 조정',
  RETURNSHIPPING: '반품배송비',
  DEFAULTSHIPPINGCOST: '기본배송비',
  ADDITIONALREGIONAL: '지역별추가',
  EXCHANGECHARGES: '교환(재발송)배송비',
  TOTALDELIVERYCOST2: '배송비합계 ⓑ',
  CLEARINGFORWARDING: '교환출고정보',
  RECEIVER: '수령자명',
  CELLPHONE: '휴대폰번호',
  LANDLINE: '전화번호',
  SHIPPINGADDRESS: '배송지주소',
  CHANGEPOSTALCODE: '우편번호변경',
  SHIPPINGMESSAGE: '배송메시지',
  ALTER: '교환출고상품의 재고가 부족하여 교환신청을 할 수 없습니다.',
  ALTER1: '삭제할 상품을 선택하세요.',
  EXCHANGEPROCESSING: '교환처리금액',
  EXCHANGEPROCESSINGAMOUNT: '교환처리금액 ⓐ+ⓑ',
  EXCHANGEPROCESSINGAMOUNT2: '교환처리금액 ⓐ+ⓑ+ⓒ',
  PAYMENTMETHOD: '결제방법',
  AVAILABLERESERVE: '사용가능적립금',
  DEPOSITACCOUNT2: '입금계좌번호',
  ADEPOSITOR: '입금자',
  TXT1: '추가결제금액만큼 적립금에서 차감합니다. (즉시결제)',
  TXT2: '교환처리 금액이 발생하지 않았습니다.',
  MAKEADEPOSIT: '무통장입금',
  RESERVEAMOUNTDEDUCTION: '적립금차감',
  SETTLEMENT: '적립금결제',
  ALTER3: '요청금액이 최초 실 결제금액을 초과하였습니다. 환불방법을 현금환불로 변경하여 신청해 주세요.',
  ALTER4: '교환출고상품을 선택해 주세요!',
  ALTER5: '금액조정은 교환금액합계 초과할 수 없습니다.',
  ALTER6: '조정사유를 입력해주세요.',
  ALTER7: '환불은행을 선택해 주세요.',
  ALTER8: '환불계좌번호를 입력해 주세요.',
  ALTER9: '예금주를 입력해 주세요',
  ALTER10: '환불은행을 선택해 주세요.',
  ALTER11: '입금자명을 입력해 주세요',
  ALTER12: '수령자명을 입력해 주세요.',
  ALTER13: '교환출고정보 휴대전화를 입력해 주세요.',
  ALTER14: '교환출고 배송지주소를 입력해 주세요.',
  ALTER15: '반품자명을 입력해 주세요.',
  ALTER16: '반품수거정보 휴대전화를 입력해 주세요.',
  ALTER17: '반품수거정보 배송지주소를 입력해 주세요.',
  ALTER18: '상품정보 변경으로 인해 주문할 수 없습니다.',
  ALTER19: '정확한 휴대폰번호를 입력해 주세요.',
  ALERT_ADJUSTMENT_DELIVERY: '금액조정은 기본배송비와 지역별 추가배송비의 합을 초과할 수 없습니다.',
  ACCOUNTHOLDER: '예금주',
  EXCHANGEAPPROVAL: '교환승인',
  APPROVALWITHDRAW: '승인철회',
  MANUALREFUND: '환불처리',
  CONFRIMDEPOSIT: '입금확인',
  CONFIRM: '철회하시겠습니까?',
  CONFIRM2: '배송보류 주문 건이 포함되어 있습니다.클레임 처리 하시겠습니까?',
  CANCEL: '취소',
  EXCHANGE: '교환',
  RETURN: '반품',
  STOCK: '재고',
  SHIPPED: '이미출고',
  ANNOUNCEMENT: '안내',
  PLUS: '더하기',
  MINUS: '빼기',
  APPROVALWITHDRAWEXCHANGE: '교환반려신청 승인',
  CONFIRM3: '이미 등록된 상품이 있습니다. 등록된 상품을 삭제하시겠습니까?',
  WITHDRAWREASON: '반려신청사유',
  PROCESS_SUCCESS_MESSAGE: '처리되었습니다.',
  CLOSECONFIRM: '교환신청을 종료하시겠습니까?',
  CLAIM_CNT_MINIMUM_MESSAGE: '최소 {type} 수량은 1 개 입니다.',
  CLAIM_CNT_MAXIMUM_MESSAGE: '최대 {type} 수량은 {num} 개 입니다.',
  CLAIM_EXCHANGE_MINIMUM_MESSAGE: '최소 구매 수량은 1 개 입니다.',
  CLAIM_EXCHANGE_MAXIMUM_MESSAGE: '최대 구매 수량은 {num} 개 입니다.',
  SHOPPING_MALL_RESPONSIBLE_MESSAGE: '※ 쇼핑몰 부담으로 조정됩니다.',
  SELLER_RESPONSIBLE_MESSAGE: '※ 판매자 부담으로 조정됩니다.',
  RETURN_TYPE: '반품수거방법',
  RETURN_RECEIPT_INFO: '반품접수정보',
  RETURN_ADDRESS: '수거지주소',
  ZIP_CODE_FIND: '우편번호 찾기',
  NOTES_ON_COLLECTION: '수거시참고사항',
  PERSONAL_CUSTOMS_CODE: '개인통관부호',
  COUPON_NAME: '쿠폰이름',
  ASK_EXCHANGE_WITHDRAW_APPROVE: '교환반려신청 승인하시겠습니까?',
  CLAIM_MIN_BUY_COUNT_BELOW_MESSAGE:
    '선택한 교환출고상품의 수량이 최소구매수량 미만입니다. 교환신청을 계속 하시겠습니까?',
  CLAIM_MAX_BUY_COUNT_ABOVE_MESSAGE:
    '선택한 교환출고상품의 수량이 최대구매수량 초과입니다. 교환신청을 계속 하시겠습니까?',
});
