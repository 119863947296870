import { i18n } from '@/main';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';

export const i18nForPartnerNotice = (word: string) => `${i18n.t(`PARTNER.NOTICE.${word}`)}`;

export const getGridProps = () => ({
  header: {
    height: 32,
  },
  columns: [
    {
      header: i18nForPartnerNotice('MALL_NAME'),
      name: 'mall',
      align: 'center',
      minWidth: 100,
    },
    {
      header: i18nForPartnerNotice('NOTICE_NO'),
      name: 'noticeNo',
      align: 'center',
      minWidth: 80,
    },
    {
      header: i18nForPartnerNotice('NOTICE_CATEGORY'),
      name: 'noticeCategory',
      align: 'left',
      minWidth: 120,
      formatter: ({ value }) => (value ? i18nForPartnerNotice(value) : '-'),
      copyOptions: {
        useFormattedValue: true,
      },
    },
    {
      header: i18nForPartnerNotice('TITLE'),
      name: 'noticeTitle',
      align: 'left',
      minWidth: 270,
      formatter: ({ value }) => getAnchorHTML(value),
      copyOptions: {
        useFormattedValue: true,
      },
    },
    {
      header: i18nForPartnerNotice('DISCLOSURE_YN'),
      name: 'opened',
      align: 'center',
      minWidth: 25,
      formatter: ({ value }) => i18nForPartnerNotice(value ? 'DISCLOSURE_Y' : 'DISCLOSURE_N'),
    },
    {
      header: i18nForPartnerNotice('VIEWS'),
      name: 'readCount',
      align: 'center',
      minWidth: 10,
    },
    {
      header: i18nForPartnerNotice('WRITER'),
      name: 'writer',
      align: 'center',
      minWidth: 60,
    },
    {
      header: i18nForPartnerNotice('CREATED_DATE_TIME'),
      name: 'createdDateTime',
      align: 'center',
      minWidth: 130,
    },
    {
      header: i18nForPartnerNotice('UPDATER'),
      name: 'modifier',
      align: 'center',
      minWidth: 60,
    },
    {
      header: i18nForPartnerNotice('MODIFIED_DATE_TIME'),
      name: 'modifiedDateTime',
      align: 'center',
      minWidth: 130,
    },
  ],
  options: {
    rowHeaders: ['checkbox', 'rowNum'],
    keyColumnName: 'noticeNo',
    columnOptions: {
      minWidth: 50,
      resizable: true,
    },
    pageOptions: {
      perPage: 30,
      page: 1,
      totalCount: 0,
    },
  },
});
