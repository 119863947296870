











































import { Component, Vue } from 'vue-property-decorator';
import EmailInput from '@/components/common/input/EmailInput.vue';

@Component({ components: { EmailInput } })
export default class AuthPasswordChangeGuidePopup extends Vue {
  private close(): void {
    this.$emit('closePopup');
  }

  private openNHNCommerceInquiryPage(): void {
    window.open('https://www.nhn-commerce.com/mygodo/helper_write.html', '_blank');
  }
}
