







import { Vue, Component, Ref } from 'vue-property-decorator';
import SummaryTab, { SummaryItem } from '@/components/product/basic/SummaryTab.vue';
import SearchForm from '@/components/product/basic/SearchForm.vue';
import { NCPResponse, ProductsSummaries } from 'ncp-api-supporter';
import { mall } from '@/utils/mall';

// 실질적인 탭 종류는 'ALL' | 'ON_SALE' | 'SOLD_OUT' 만 존재하기 때문에,
// 그 외의 것은 없애는 쪽으로 로직변경, 혹은 이름 변경이 좋을 것 같다.
// ('STOCK_UNDER_TEN' | 'ON_SALE_SUMMARY' 는  메인 상품 현황에서 리다이렉팅 될 때 로직상 필요한 항목들)
export type TabState = '' | 'ALL' | 'ON_SALE' | 'SOLD_OUT' | 'STOCK_UNDER_TEN' | 'ON_SALE_SUMMARY';

@Component({
  components: {
    SummaryTab,
    SearchForm,
  },
})
export default class ProductList extends Vue {
  @Ref()
  private readonly searchForm!: SearchForm;

  async created() {
    await this.fetchProductSummaries();
    // this.setDefaultQueryString([this.summaryTab[0].query]); 기본선택 아니야
  }

  mounted() {
    const { selectedClassification } = this.$route.query;
    const tabStates: TabState[] = ['ALL', 'ON_SALE', 'SOLD_OUT', 'STOCK_UNDER_TEN', 'ON_SALE_SUMMARY'];
    const matched = tabStates.some(state => state === selectedClassification);
    if (selectedClassification && matched) return;

    // //TODO : 하위컴포넌트에서 $nextTick 을 남용해서 여기서도 걸 수 밖에없는 슬픈상황이 연출되고있음
    this.$nextTick(() => {
      this.searchForm.sendQueryUrl();
    });
  }

  private get fixedMallNo(): number | null {
    return mall.malls.length === 1 ? mall.malls[0].mallNo : null;
  }

  // SummaryTab 관련
  private summaryTab: SummaryItem<TabState>[] = [];
  private selectedClassification: TabState = '';

  private async fetchProductSummaries() {
    const { data }: NCPResponse<ProductsSummaries> = await this.$api.getProductsSummaries();
    const { totalCnt, onSaleCnt, soldOutCnt } = data;
    this.summaryTab = [
      {
        name: this.$t('PRODUCT.TOTAL').toString(),
        count: totalCnt,
        value: 'ALL',
      },
      {
        name: this.$t('PRODUCT.ON_SALE').toString(),
        count: onSaleCnt,
        value: 'ON_SALE',
      },
      {
        name: this.$t('PRODUCT.SOLD_OUT').toString(),
        count: soldOutCnt,
        value: 'SOLD_OUT',
      },
    ];
  }
}
