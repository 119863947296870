















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { ContractedPartner, GetMallsContractedPartnersByMallNoRequest, NCPResponse } from 'ncp-api-supporter';
import RadioGroup from '@/components/common/RadioGroup.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { GroupOption, GroupOptionData } from '@/helpers/type';
import { PartnerType } from '@/types';
import { PARTNER_TYPE } from '@/const/common';
import { namespace } from 'vuex-class';
import { PartnerState } from '@/store/modules/partner';
const partnerStore = namespace('partner');

@Component({
  components: {
    RadioGroup,
  },
  directives: {
    ClickOutside,
  },
})

/**
 * 상품리스트, 주문리스트, 상품등록/수정페이지에서 사용
 * 라디오 버튼 : 전체(리스트:default), 쇼핑몰 자체 상품(등록: default), 파트너사
 * 텍스트 박스 : 파트너사입력 || 파트너사 노출
 * */
export default class PartnerFinder extends Vue {
  @partnerStore.Action('setPartnerType')
  private readonly setPartnerType!: (state: PartnerState) => void;
  @partnerStore.Action('setPartnerNo')
  private readonly setPartnerNo!: (state: PartnerState) => void;
  @Prop({ required: false, default: true })
  private readonly hasAllType?: boolean; //'전체' 라디오 버튼
  @Prop({ required: true })
  private readonly mallNo!: number;
  @Prop({ required: true })
  private readonly mallSelect!: MallSelect;
  @Prop({ required: false, default: false })
  private readonly shouldInitiate?: boolean; //상품 수정 페이지, 리스트 query param 파트너사
  @Prop({ required: false, default: 0 })
  private readonly initPartnerNo?: number; //상품 수정 파트너사, 리스트 query param 파트너사
  @Prop({ required: false, default: false })
  private readonly disabled?: boolean;

  private partners: ContractedPartner[] = [];
  private displayPartners: ContractedPartner[] = [];
  private isFocus = false;
  private defaultPartnerType: PartnerType = this.hasAllType ? PARTNER_TYPE.ALL : PARTNER_TYPE.SHOP;
  private partnerRadioTypeValue: PartnerType = this.defaultPartnerType;
  private keyword?: string = '';
  private partnerRadioTypeData: GroupOptionData<string>[] = [
    { id: 'all', value: PARTNER_TYPE.ALL, display: 'PARTNER.COMMON.ALL' },
    { id: 'shop', value: PARTNER_TYPE.SHOP, display: 'PARTNER.COMMON.SHOP' },
    { id: 'partner', value: PARTNER_TYPE.PARTNER, display: 'PARTNER.COMMON.PARTNER' },
  ];
  private isCreated = false;

  private get isNotSelectedPartnerType(): boolean {
    return this.partnerRadioTypeValue !== PARTNER_TYPE.PARTNER;
  }

  private get partnerTypeRadio(): GroupOption<string> {
    const noneAllTypeData = this.partnerRadioTypeData.filter(data => data.value !== PARTNER_TYPE.ALL);
    return {
      name: 'partnerRadioType',
      data: this.hasAllType ? this.partnerRadioTypeData : noneAllTypeData,
    };
  }

  @Watch('mallNo', { immediate: false })
  private async initPartner() {
    if (!this.mallNo) return;
    //NOTE: 수정페이지에서 basicInfo.mallNo가 '' 에서 api 에서 받은 mallNo로 변경됨. created에서 처리할 수 없음..
    await this.fetchPartners(this.mallNo.toString());
    //NOTE : 상품수정페이지, 리스트 페이지 새로고침
    if (this.shouldInitiate && this.isCreated) {
      this.setInitPartner();
      this.isCreated = false;
      return;
    }
    if (this.isCreated && this.initPartnerNo) {
      // search-form 에서 partnerNo 지정해 줄 때
      this.setInitPartner();
      return;
    }
    this.resetPartnerRadioType();
    if (!this.hasAllType) this.setShopPartner(); //상품등록페이지에서만
    this.isCreated = false;
  }

  private resetPartnerRadioType() {
    this.partnerRadioTypeValue = this.defaultPartnerType;
    this.resetKeyword();
    this.setPartnerNo({ partnerNo: this.hasAllType ? 0 : this.initPartnerNo });
    this.setPartnerType({ partnerType: this.defaultPartnerType });
  }

  private async fetchPartners(mallNo: string): Promise<void> {
    const pathParams: GetMallsContractedPartnersByMallNoRequest['pathParams'] = {
      mallNo,
    };
    //특정 쇼핑몰에 계약된 파트너 모두 조회
    const { data }: NCPResponse<ContractedPartner[]> = await this.$api.getMallsContractedPartnersByMallNo({
      pathParams,
    });
    this.partners = data.filter(
      partner =>
        partner.partnerStatus === 'ACTIVE' &&
        (partner.contractStatus === 'ACTIVE' || partner.contractStatus === 'SUSPEND'),
    );
  }

  //상품 수정페이지, 리스트페이지에서 파트너사 셋팅
  @Watch('initPartnerNo')
  private setInitPartner(): void {
    if (!this.mallNo) return;
    this.setPartnerNo({ partnerNo: this.initPartnerNo });
    const productPartner = this.partners.find(partner => partner.partnerNo === this.initPartnerNo);
    this.partnerRadioTypeValue = productPartner?.partnerType === 'SHOPBY' ? PARTNER_TYPE.SHOP : PARTNER_TYPE.PARTNER;
    this.setPartnerType({ partnerType: this.partnerRadioTypeValue });
    this.keyword = productPartner?.partnerType === 'SHOPBY' ? '' : productPartner?.partnerName;
  }

  private onClickOutside(event: MouseEvent): void {
    const hasPartnerName = this.partners.some(partner => partner.partnerName === this.keyword);
    this.isFocus = false;
    const element = event.target as HTMLElement;

    if (!hasPartnerName && this.partnerRadioTypeValue === PARTNER_TYPE.PARTNER && element.tagName !== 'A') {
      alert(this.$t('PARTNER.COMMON.ALERT_NOT_INPUT_PARTNER_NAME'));
      this.resetPartnerRadioType(); //NOTE: 강제로 첫번째 값으로 이동 , 사유 -> 쇼핑몰 변경이나 다른 값 변경할 때 파트너사 입력하라고 얼럿발생
    }
  }

  // @Watch('partnerRadioTypeValue')
  private changePartnerType(selectedPartnerType: string): void {
    if (!this.mallNo && selectedPartnerType !== PARTNER_TYPE.ALL) {
      this.alertMallSelect();
      this.$nextTick(() => {
        this.partnerRadioTypeValue = this.defaultPartnerType;
        this.setPartnerType({ partnerType: this.partnerRadioTypeValue });
      });
    }

    this.partnerRadioTypeValue = selectedPartnerType;
    this.setPartnerType({ partnerType: this.partnerRadioTypeValue });

    if (selectedPartnerType === PARTNER_TYPE.ALL) {
      this.setPartnerNo({ partnerNo: 0 });
      this.resetKeyword();
    } else if (selectedPartnerType === PARTNER_TYPE.SHOP) {
      this.setShopPartner();
      this.resetKeyword();
    } else if (selectedPartnerType === PARTNER_TYPE.PARTNER && this.hasAllType) {
      // 리스트인 경우
      this.setPartnerNo({ partnerNo: 0 });
    }
  }
  private alertMallSelect(): void {
    alert(this.$t('ALERT_NO_SELECTED', { value: this.$t('MALL') })); // 쇼핑몰을 선택해주세요.
    this.focusOnMallSelect();
  }
  private focusOnMallSelect(): void {
    this.mallSelect.focus();
  }

  private setPartner(partner: ContractedPartner) {
    this.keyword = partner.partnerName;
    this.setPartnerNo({ partnerNo: partner.partnerNo });
    this.isFocus = false;
  }

  private filterPartners(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    this.keyword = value;
    if (value) {
      this.displayPartners = this.partners.filter(
        (partner: ContractedPartner) => partner.partnerName.includes(value) && partner.partnerType !== 'SHOPBY',
      );
    } else {
      this.displayPartners = [];
    }
  }

  //쇼핑몰 자체 상품
  private setShopPartner(): void {
    this.setPartnerType({ partnerType: PARTNER_TYPE.SHOP });
    const shopPartner = this.partners.find(partner => partner.partnerType === 'SHOPBY');
    if (this.partnerRadioTypeValue === PARTNER_TYPE.SHOP && shopPartner) {
      this.setPartnerNo({ partnerNo: shopPartner.partnerNo });
    }
  }

  private resetKeyword() {
    this.keyword = '';
    this.displayPartners = [];
  }

  private matchedKeywordHighlight(string: string, keyword: string): string {
    return string.split(keyword).join(`<span>${keyword}</span>`);
  }

  created() {
    this.isCreated = true;
  }
}
