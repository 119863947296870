export default Object.freeze({
  PAGE_TITLE: '1:1문의 상세보기',
  INFO_TITLE: '1:1 문의 정보',
  BTN_CHANGE: '변경',
  POP_MODIFY: '문의유형/담당자',
  WITHDRAWN: '탈퇴회원',
  T1_TH_1: '문의유형 / 담당자',
  T1_TH_2: '문의번호 / 문의자',
  T1_TH_3: '처리상태',
  T1_TH_4: '문의일시',
  ADMIN_TITLE: '변경내역',
  T2_TH_1: '변경일시',
  T2_TH_2: '변경자',
  T2_TH_3: '문의유형',
  T2_TH_4: '담당자',
  T2_TH_5: '변경사유',
  CONTENT_TITLE: '1:1문의 내용',
  T3_TH_1: '문의제목',
  T3_TH_2: '문의내용',
  T3_TH_3: '상품정보',
  T3_TH_4: '첨부파일',
  ORDER_TITLE: '주문정보',
  T4_TH_1: '주문번호',
  T4_TH_2: '결제일시',
  T4_TH_3: '담당자명1',
  T4_TH_4: '담당자명2',
  T4_TH_5: '결제금액',
  T4_TH_6: '배송비',
  T4_TH_7: '상품주문번호',

  TEMPLETE_ALL: '답변 템플릿 설정',

  STATUS_ISSUED: '답변대기',
  STATUS_ANSWERED: '답변완료',
  STATUS_IN_PROGRESS: '진행중',

  SMS_CHECKBOX: 'SMS 답변 알림',
  EMAIL_CHECKBOX: 'e메일 동시 답변',
  TRANSMISSION: '전송함',
  CELLPHONENUMBER: '휴대폰번호',
  MAILADDRESS: 'e메일주소',
  ANSWER_TITLE: '1:1 문의 답변',
  T5_TH_1: '답변일시',
  T5_TH_2: '답변내용',
  T5_TH_3: 'SMS 답변 알림',
  T5_TH_4: '이메일 답변 알림',
  T5_SHIP: '수신함 / ',
  T5_SHIP_NONUM: '수신함',
  T5_BALSONG: '수신 안 함',

  WORN_NOADMIN: '문의유형/담당자를 선택 및 저장해주세요.',
  WORN_NOREASON: '답변내용을 작성해주세요.',
  BTN_SAVE: '임시저장',
  BTN_SEND: '답변완료',
  BTN_MODIFY: '답변수정',
  CONFIRM_SAVE: '답변을 임시저장 하시겠습니까?',
  CONFIRM_SEND_NCP: '답변을 등록하시겠습니까?',
  CONFIRM_SEND:
    '답변을 등록하시겠습니까? \nSMS/이메일 답변 알림이 수신함인 경우, \n회원 정보로 답변 등록에 대한 알림이 발송됩니다.',
  CONFIRM_MODIFY_NCP: '고객이 확인 한 답변을 수정할 경우, \n추가 클레임이 발생할 수 있습니다. 그래도 수정하시겠습니까?',
  CONFIRM_MODIFY:
    '고객이 확인 한 답변을 수정할 경우, \n추가 클레임이 발생할 수 있습니다. 그래도 수정하시겠습니까? \n답변수정 시 SMS/이메일 알림은 발송되지 않습니다.',

  CONFIRM_HAD_CONTENT: '이미 등록된 답변이 있습니다. 업데이트 하시겠습니까?',
  WARN_SAVE: '이미 등록된 답변이 있을 경우 임시저장이 불가합니다',
  SUCCESS_SAVE: '임시저장되었습니다.',
  SUCCESS_SEND: '답변이 등록되었습니다.',
  SUCCESS_MODIFY: '답변이 수정되었습니다.',
  ERRER_SEND_NCP: '답변 등록이 실패되어 ‘임시저장’처리되었습니다. \n확인 후 다시 시도해주세요.',
  ERRER_MODIFY_NCP: '답변 수정에 실패하였습니다. \n잠시 후 다시 시도해주세요.',
  POP_CRM: '회원정보',
});
