









































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { OptionData } from '@/helpers/type';
import { CONFIG_TYPE, getProductAddSelectOption, SORT_CRITERION } from '@/const/contents/product';

@Component({
  components: { SelectBox },
})
export default class DisplayOrderInfo extends Vue {
  @PropSync('sortCriterion', { required: true })
  private sortCriterionSync: keyof typeof SORT_CRITERION;

  @Prop({ required: true })
  private readonly configType: keyof typeof CONFIG_TYPE;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private selectOption: OptionData<keyof typeof SORT_CRITERION>[] = getProductAddSelectOption().displayOrder;
  private sortType: keyof typeof SORT_CRITERION = SORT_CRITERION.LATEST_REGISTER_DATE;
  private isCustomOrder = false; // true 수동, false 자동
  private isSelectedProducts = this.configType === CONFIG_TYPE.SELECTED;

  @Watch('configType')
  private changedConfigType() {
    this.isSelectedProducts = this.configType === CONFIG_TYPE.SELECTED;
    if (this.configType !== CONFIG_TYPE.SELECTED) {
      this.$nextTick(() => (this.isCustomOrder = false));
    }
  }

  private changed(type: 'custom' | 'sort', value: boolean | keyof typeof SORT_CRITERION) {
    if (type === 'sort') {
      // value: SORT_CRITERION
      this.sortCriterionSync = value as keyof typeof SORT_CRITERION;
      this.$nextTick(() => (this.isCustomOrder = false));
      return;
    }
    // value: boolean
    this.sortCriterionSync = value ? SORT_CRITERION.CUSTOM_ORDER : SORT_CRITERION.LATEST_REGISTER_DATE;
  }

  private created() {
    this.init();
  }

  @Watch('sortCriterionSync')
  private changedSortCriterionSync() {
    this.isCustomOrder = this.sortCriterionSync === SORT_CRITERION.CUSTOM_ORDER;
    if (!this.isCustomOrder) {
      // 자동진열 정렬 조건 설정
      this.sortType = this.sortCriterionSync;
    }
  }

  private init() {
    this.sortType =
      this.sortCriterionSync === SORT_CRITERION.CUSTOM_ORDER
        ? SORT_CRITERION.LATEST_REGISTER_DATE
        : this.sortCriterionSync;
  }
}
