














































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import WindowPopupMain from '@/views/popups/Main.vue';
import AccumulationStatusMixin from '@/views/contents/member/accumulation/AccumulationStatusMixin';
import Grid from '@/components/common/grid/Main.vue';
import { detailStatusColumn, statusOption } from '@/const/contents/accumulationPaymentDeduct';
import {
  GetAccumulationsAssemblesRequestNo,
  GetAccumulationsAssemblesRequestNoRequest,
  GetAccumulationsAssemblesRequestNoResults,
  GetAccumulationsAssemblesRequestNoResultsRequest,
  NCPResponse,
} from 'ncp-api-supporter';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { ToolTipOption } from '@/types/tooltip';

@Component({
  components: { ToolTip, Grid },
})
export default class AccumulationManualStatusPopup extends Mixins(WindowPopupMain, AccumulationStatusMixin) {
  private gridOption = statusOption;
  private gridColumn = detailStatusColumn;
  private tooltipOption: ToolTipOption = {
    message: this.$t('ACCUMULATION.STATUS_TOOLTIP').toString(),
    tooltipClass: 'right-type',
    style: { width: '400px', whiteSpace: 'pre-line' },
    hasNoPreLine: true,
  };
  private status: GetAccumulationsAssemblesRequestNo = {} as GetAccumulationsAssemblesRequestNo;
  private resultStatuses = null;

  private get isPayment(): boolean {
    return this.paymentDeduct === '지급';
  }

  private get paymentDeduct(): string {
    return this.status.requestGroupLabel;
  }

  private get sortation(): string {
    const paymentMethod = `ACCUMULATION.${this.data.immediately ? 'IMMEDIATELY_PAYMENT' : 'RESERVATION_PAYMENT'}`;
    const currentState = this.isPayment ? `-${this.$t(paymentMethod)}` : '';
    return `${this.paymentDeduct}${currentState} (${this.data.no})`;
  }

  private getAmount(amount: number): string {
    return `${this.isPayment ? '+' : '-'}${amount?.toLocaleString()}`;
  }

  private getStatusesHref(statuses?: 'COMPLETED' | 'FAILED') {
    this.resultStatuses = statuses;
  }

  private async fetchAccumulationStatus() {
    const { mallNo, no } = this.data;
    const request: GetAccumulationsAssemblesRequestNoRequest = {
      pathParams: {
        requestNo: no,
      },
      params: {
        mallNo,
      },
    };
    const response: NCPResponse<GetAccumulationsAssemblesRequestNo> = await this.$api.getAccumulationsAssemblesRequestNo(
      request,
    );
    this.status = response.data;
  }

  @Watch('resultStatuses')
  @Watch('$route.query')
  private async fetchAccumulationAssembleResults(): Promise<void> {
    const { mallNo, no: requestNo } = this.data;
    const request: GetAccumulationsAssemblesRequestNoResultsRequest = {
      pathParams: {
        requestNo,
      },
      params: {
        mallNo,
        statuses: this.resultStatuses,
        page: Number(this.$route.query.page) || 1,
        pageSize: Number(this.$route.query.pageSize) || 30,
      },
    };
    const {
      data,
    }: NCPResponse<GetAccumulationsAssemblesRequestNoResults> = await this.$api.getAccumulationsAssemblesRequestNoResults(
      request,
    );
    this.contents = data.contents;
    this.totalCount = data.totalCount;
  }

  async created() {
    await Promise.all([this.fetchAccumulationStatus(), this.fetchAccumulationAssembleResults()]);
  }
}
