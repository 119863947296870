




























import { Component, Vue, Prop } from 'vue-property-decorator';
import { KeywordData, KeywordKeys } from '@/components/common/categories/MultiCategoriesSelector.vue';
import ClickOutside from 'vue-click-outside';

@Component({
  directives: {
    ClickOutside,
  },
})
export default class KeywordFinder extends Vue {
  @Prop({ required: true })
  private readonly categories: KeywordData[];
  @Prop({ required: true })
  private readonly keys!: KeywordKeys;
  @Prop({ required: false })
  private readonly disabled!: boolean;

  @Prop({ required: false, default: '' })
  private readonly className: string;
  @Prop({ required: false, default: false })
  private readonly disabledKeyword: boolean;
  @Prop({ default: false })
  private readonly noQuery!: boolean;

  private matchedCategorties: KeywordData[] = [];
  private keyword = '';
  private isFocus = false;

  get placeholder(): string {
    return this.categories.length <= 0 && this.noQuery
      ? this.$t('PRODUCT.ADD.CATEGORY_EMPTY').toString()
      : this.$t('PRODUCT.ADD.CATEGORY_INPUT').toString();
  }

  private onInput(value: string): void {
    const keyword = value.trim();
    keyword ? this.search(keyword) : this.reset();
    this.keyword = keyword;
  }

  private onClickOutside(): void {
    this.isFocus = false;
  }

  private searchTimer = null;
  private search(value: string): void {
    if (this.searchTimer) clearTimeout(this.searchTimer);
    // debouncing
    this.searchTimer = setTimeout(() => {
      this.matchedCategorties = this.categories.filter(category => category[this.keys.fullNameKey].indexOf(value) > -1);
    }, 250);
  }

  private pick(category: KeywordData): void {
    this.$emit('pick', category);

    this.reset();
  }

  public reset() {
    this.keyword = '';
    this.matchedCategorties = [];
  }

  private matchedKeywordHighlight(string: string, keyword: string): string {
    return string.split(keyword).join(`<span>${keyword}</span>`);
  }
}
