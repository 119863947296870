export default Object.freeze({
  // 기본정보
  // 상단 타이틀
  BASIC_INFO: '기본정보',
  SHOPPING_MALL: '쇼핑몰',
  GROUP_TYPE: '상품군',
  PRODUCT_TYPE: '상품유형',
  PARTNER_NAME: '파트너사',
  SALE_METHOD_TYPE: '판매방식',
  ADMIN: '담당자',
  STANDARD_CATEGORY: '표준카테고리',
  DISPLAY_CATEGORY: '전시카테고리',
  SELECTED: '개별상품',
  PLATFORM_DISPLAY_INFO: '플랫폼별 노출 설정',
  PC_WEB: 'PC웹',
  MOBILE_WEB: '모바일웹',
  ALL_GRADES: '전체회원등급',
  EXPOSE_GRADE: '회원등급 노출 설정',
  PRODUCT_NAME: '상품명',
  PRODUCT_DSC: '홍보문구',
  EXPOSE_PERIOD_CONFIG: '노출기간 설정',
  EXPOSE_PERIOD: '노출기간',
  BRAND: '브랜드',
  PAY_METHOD_CONFIG: '결제수단 설정',
  NAVER_PAY_LIMIT: '네이버페이 결제 불가',
  ADULT_CERTIFICATION: '성인인증',
  NOTICE_ADULT_CERTIFICATION: '성인인증 설정은 본인인증 서비스 사용 시에만 정상적으로 적용됩니다.',
  GO_TO_ADULT_CERTIFICATION: '본인인증 서비스 설정 바로가기',
  KEYWORD: '검색어',
  URL_USE_YN: '단축 URL 사용여부',
  COPY: '복사',

  RELATED_PRODUCT_CONFIG: '관련상품 설정',
  // 플레이스홀더
  PLACEHOLDER_PARTNER: '파트너사명입력',
  PLACEHOLDER_PRODUCT_NAME: '상품명을 입력해주세요.',
  PLACEHOLDER_PRODUCT_DESC: '홍보문구를 입력해주세요.',
  SELECT_MALL: '쇼핑몰을 선택하세요.',
  SELECT_ADMIN: '담당자를 선택하세요.',
  KEYWORD_DSC: '검색어는 최대 10개까지 입력 가능합니다. (‘,’로 구분)',

  // 관련상품 등록
  SELECT_RELATED_PRODUCT: '관련상품 선택',

  REMOVE_SELECTED: '선택삭제',
  PRODUCT_SEARCH: '상품 조회',
  NOTICE_LIMIT_SIZE:
    '상품은 최대 500개까지 선택할 수 있습니다.<br />500개를 초과하여 상품 선택 시 기존 선택된 상품이 자동 삭제됩니다.',
  PRODUCT: '상품',
  // 그리드
  DISPLAY_ORDER: '진열순서',
  AUTO_DISPLAY: '자동진열',
  MANUAL_DISPLAY: '수동진열',
  PRODUCT_NO: '상품번호',
  IMMEDIATE_DISCOUNT_AMOUNT: '즉시할인가',
  SALE_STATUS_TYPE: '판매상태',
  SALE_SETTING_STATUS_TYPE: '판매설정',
  EXPOSE_YN: '노출여부',
  DISPLAY_YN: '전시상태',
  DISPLAY_Y: '전시함',
  DISPLAY_N: '전시 안 함',
  SOLD_OUT: '품절상태',
  REMOVE: '삭제',
  SELECT: '선택',
  CONFIRM_REMOVE_N: '선택한 {value}개의 상품을 삭제하시겠습니까?',
  PLZ_SELECT_PRODUCT_TO_REMOVE: '삭제할 상품을 선택해 주세요.',
  Y_EXPOSE: '노출함',
  N_EXPOSE: '노출 안 함',
  Y_STOCK: '정상',
  N_STOCK: '품절',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION_SALE: '판매금지',
  PRE_APPROVAL_STATUS: '승인완료이전',
  SALE_PERIOD: '기간판매',
  ON_PRE_SALE: '예약판매중',
  WAITING_SALE: '판매대기',
  ON_SALE: '판매중',
  END_SALE: '판매종료',
  REMOVED: '삭제가 완료되었습니다.',

  SELECTED_TARGET_MSG: '선택한 상품을',
  NO_DATA_MSG_FOR_RELATED_PRODUCT: '등록된 관련상품이 없습니다.',
  PLZ_SELECT_PRODUCT_TO_MOVE: '이동할 상품을 선택해주세요.',

  HEADER_NO: 'No.',

  // 검색기능 관련
  CATEGORY_CLICK: '카테고리 찾기로 변경',
  CATEGORY_SEARCH: '카테고리 검색으로 변경',
  CATEGORY_INPUT: '카테고리명을 입력해주세요.',
  CATEGORY_EMPTY: '등록된 카테고리가 없습니다.',

  // 툴팁메세지
  STANDARD_CATEGORY_TOOLTIP: `상품 등록/수정 도중에 전시카테고리가 선택 불가한 상태로 변경될 경우, 해당 카테고리는 선택한 카테고리에서 삭제 후 저장됩니다.<br />
                          (선택한 카테고리의 하위카테고리가 추가되거나, 선택한 전시카테고리가 삭제되는 등의 일부 예외 케이스 존재)`,
  PRODUCT_NAME_TOOLTIP: `상품명에 입력한 문자 중 일부는 PG사 정책에 따라 PG 통신 과정에서 제거될 수 있습니다.`,
  PRODUCT_DESC_TOOLTIP_1: '쇼핑몰에 상품에 대한 정보를 추가로 노출할 수 있습니다.',
  PRODUCT_DESC_TOOLTIP_2: '상품에 대한 추가 설명, 홍보문구 등으로 활용이 가능합니다.',
  PRODUCT_DESC_TOOLTIP_3: '노출기간을 설정하여 설정한 특정기간 동안만 홍보문구가 노출되도록 설정할 수 있습니다.',
  USL_DIRECT_DISPLAY_TOOLTIP_1:
    '단축 URL 사용여부를 ‘사용함’으로 설정한 경우 자동으로 단축 URL이 생성되며, 생성된 단축 URL은 상품리스트에서도 확인할 수 있습니다.',
  USL_DIRECT_DISPLAY_TOOLTIP_2: `단축 URL 사용 시, 상품 상세 SNS 공유하기 팝업에서 URL 복사 시에도 단축 URL로 복사하여 사용할 수 있습니다.`,
  USL_DIRECT_DISPLAY_TOOLTIP_3: 'URL 복사 기능은 ‘마케팅 > 마케팅 설정 > SNS 공유하기’에서 설정할 수 있습니다',
  MINOR_PURCHASE_TOOLTIP: '표준카테고리에서 ‘성인 카테고리‘ 선택 시 성인인증 ‘사용함’처리됩니다.',

  REMOVE_CATEGORY_MESSAGE:
    '등록된 원산지, 인증정보, 카테고리수수료, 성인인증 사용여부가\n' + '초기화됩니다. 변경하시겠습니까?',

  GO_DIRECT: '바로가기&gt;',

  // 네이버페이 툴팁
  NAVER_PAY_TOOLTIP_TITLE: '네이버페이 취급 제한/불가상품 안내',
  NAVER_PAY_TOOLTIP_SUB_TITLE: '네이버페이 결제수단에 따른 결제 제한 조건',
  NAVER_PAY_TOOLTIP_CHECK_RESTRICTIONS: '취급 제한/불가 상품확인하기',
  NAVER_PAY_TOOLTIP_NOTICE_1: `네이버페이 규칙에 의해 매매가 허락되지 않는 ‘취급제한 상품’과 현행 법령상 매매가 금지된 ‘취급불가 상품’은
          네이버페이 수단으로 결제할 수 없습니다.`,
  NAVER_PAY_TOOLTIP_NOTICE_ORDER_TYPE: '네이버페이 주문형',
  NAVER_PAY_TOOLTIP_NOTICE_PAYMENT_TYPE: '네이버페이 결제형',
  NAVER_PAY_TOOLTIP_NOTICE_ORDER_TYPE_DESC:
    "'즉시할인가 (판매가-즉시할인금액) + 옵션가' 금액이 '즉시할인가'금액에 - 50%를 초과",
  NAVER_PAY_TOOLTIP_NOTICE_ORDER_TYPE_DESC2: '하는 상품',
  NAVER_PAY_TOOLTIP_NOTICE_PAYMENT_TYPE_DESC: '총 결제금액 100원 미만',
  NAVER_PAY_EXAMPLE: '예) 판매가 10,000원 즉시할인금액 2,000원',
  NAVER_PAY_TOOLTIP_NOTICE_4: '자세한 사항은',
  NAVER_PAY_TOOLTIP_NOTICE_5: '에 문의바랍니다.',
  NAVER_PAY_CENTER: '네이버페이 센터',
  NEW_POPUP: '새창 열기',

  // 판매정보
  SALES_INFO: '판매정보',
  RESERVED_SALES: '예약판매',
  NOT_USE_CONFIG: '설정 안 함',
  USE_CONFIG: '설정함',
  USE_CONFIG2: '설정',
  RESERVED_SALES_STARTED_DATE: '예약배송시작일',
  RESERVED_SALES_STOCK_CNT: '예약재고수량',
  STOCK_CNT_UNIT: '개',
  PLACEHOLDER_ONLY_NUMBER: '숫자만 입력',
  NOTICE_STOCK_CNT: '옵션 설정 시, 옵션별 예약재고수량이 자동으로 합산됩니다.',
  USE_ALTERNATIVE_PHRASE: '가격대체문구 사용',
  PLACEHOLDER_USE_ALTERNATIVE_PHRASE: '가격대체문구를 입력해주세요.',
  NOTICE_USE_ALTERNATIVE_PHRASE:
    '가격대체문구 사용 시 ‘판매중지’ 상태에서 상품의 가격정보 대신 입력하신 문구가 노출됩니다.',

  SALES_PERIOD: '판매기간',
  SALES_PERIOD_REGULAR: '상시판매',
  SALES_PERIOD_PERIOD: '기간지정 판매',

  COMMISSION_INFO: '판매수수료',
  COMMISSION_INFO_PRODUCT: '상품수수료',
  COMMISSION_INFO_CATEGORY: '카테고리수수료',
  COMMISSION_INFO_PARTNER: '파트너수수료',
  COMMISSION_INFO_PURCHASE_PRICE: '공급가입력',

  SALE_PRICE: '판매가',
  ALERT_MIN_PRICE: '판매가는 즉시할인금액 이상으로만 입력 가능합니다.',
  ALERT_MIN_PRICE_WITH_OPTION_PRICE: '판매가는 즉시할인금액과 마이너스 옵션가를 합한 금액 \n이상으로 입력 가능합니다.',
  OPTION_PRICE: '옵션가',
  PAY_AMOUNT: '결제금액',
  WHETHER_TO_PURCHASE: '구매가능 여부',
  PURCHASABLE: '구매가능',
  UNPURCHASABLE: '구매불가',
  IMMEDIATE_DISCOUNT: '즉시할인',
  ADULT: '(성인)',
  IMMEDIATE_DISCOUNT_PRICE: '즉시할인가',
  AMOUNT: '원',
  PERCENT: '%',
  MAX: '최대',
  NOTICE_LIMIT_UNIT: '가격은 10원 단위로 입력 가능합니다.',
  NOTICE_PRICE_RANGE: '0 ~ 100 사이로 입력해주세요.',
  ALERT_MAX_DISCOUNT_PRICE: '즉시할인금액은 판매가 이하로만 입력 가능합니다',
  ALERT_MAX_DISCOUNT_PRICE_WITH_OPTION_PRICE:
    '즉시할인금액은 마이너스 옵션가가 적용된 \n' + '판매가 이하로만 입력 가능합니다.',

  IMMEDIATE_DISCOUNT_GUIDE: '즉시할인 등록 가이드',
  NOTICE_SET_UNIT: '정률 할인 설정 시 즉시할인금액에서 원단위는 절하하여 적용됩니다.',
  NOTICE_SET_UNIT_TITLE_PRICE: '판매정보',
  NOTICE_SET_UNIT_PRICE: '판매가',
  NOTICE_SET_UNIT_1: '최초적용',
  NOTICE_SET_UNIT_2: '원단위 절사 후',
  NOTICE_SET_UNIT_TITLE_PERCENT: '즉시할인(정률%)',
  NOTICE_SET_UNIT_TITLE_IMMEDIATE_DISCOUNT_AMOUNT: '즉시할인금액',
  NOTICE_SET_UNIT_TITLE_IMMEDIATE_DISCOUNT_PRICE: '즉시할인가',
  PERIOD_CONFIG: '기간설정',
  PLACEHOLDER_NUMBER: '숫자만 입력',
  PURCHASE_PRICE_CONSIGNMENT: '공급가', // 위탁
  PURCHASE_PRICE_PURCHASE: '매입가', // 사입

  ACCUMULATION_RATE_CONFIG: '개별 적립금 설정',
  ACCUMULATION_RATE_PAYMENT: '% 지급',

  STOCK_CNT: '재고수량',
  CNT_UNIT: '개',
  PLACEHOLDER_STOCK_CNT: '숫자만 입력하세요.',
  DESC_STOCK_CNT: '옵션 설정 시, 옵션 별 재고수량이 자동으로 합산됩니다.',

  USE_OPTIONS: '옵션 사용여부',
  OPTION: '옵션',
  ADD_OPTION: '옵션등록',
  MODIFY_OPTION: '옵션수정',
  RESET_OPTION: '옵션초기화',
  CONFIRM_RESET_OPTION: '옵션을 초기화하시겠습니까?',
  SET_OPTION: '세트옵션',
  ADD_SET_OPTION: '세트옵션 등록',
  MODIFY_SET_OPTION: '세트옵션 변경',
  RESET_SET_OPTION: '초기화',

  DUTY_INFO: '상품정보고시',

  ACCUMULATION_USE: '적립금 사용',
  ACCUMULATION_USE_Y: '가능',
  ACCUMULATION_USE_N: '불가능',

  PROMOTION: '프로모션',

  CERTIFICATION_INFO: '인증정보',
  CERTIFICATION_INFO_TARGET: '인증대상',
  CERTIFICATION_INFO_NOT_TARGET: '인증대상 아님',
  CERTIFICATION_INFO_DETAIL_PAGE: '상세페이지 별도 표기',
  CERTIFICATION_INFO_KC_CERTIFICATION: `'인증대상'으로 설정한 경우, 상품정보제공고시 품목 내 'KC인증'값에 설정한 인증정보가 표기됩니다.`,
  ADD: '등록',
  MODIFY: '변경',

  PLACE_ORIGIN: '원산지',
  PLACE_ORIGIN_OY: '원양산',
  PRODUCT_DETAILS_REFERENCE: '상품상세참조',
  ETC: '기타',
  DOMESTIC: '국산',
  IMPORTED: '수입산',

  BUYER_PRODUCT_NAME: '매입처 상품명',
  CHECK_MSG_BUYER_PRODUCT_NAME: '체크 시 상품명이 매입처 상품명에 추가됩니다.',
  PLACEHOLDER_BUYER_PRODUCT_NAME: '매입처 상품명을 입력해주세요.',

  PRODUCT_MANAGEMENT_CD: '상품관리코드',
  PLACEHOLDER_PRODUCT_MANAGEMENT_CD: '한글/영문/숫자/특수문자 포함 45자 이내로 입력해주세요.',
  PRODUCT_MANAGEMENT_CD_TOOL_TIP: '특수문자 \\* ? `' + `' " < >의 입력은 불가합니다.`,
  PLACEHOLDER_PRODUCT_HS_CODE: 'HS CODE를 입력해주세요',
  DUPLICATE_CHECK: '중복체크',

  MANUFACTURE_DATE: '제조일자',
  EXPIRATION_DATE: '유효일자',
  RESET: '초기화',

  VALUE_ADDED_TAX: '부가세',
  DUTY: '과세',
  DUTYFREE: '면세',
  SMALL: '영세',

  TOOLTIP_COMMISSION: `카테고리 수수료 선택 시, 선택 시점의 표준카테고리 수수료를 불러와 적용할 수 있습니다.
                          상품 등록/수정 이후 카테고리 수수료가 변경되더라도 수수료가 즉시 변경되지 않습니다.
                          공급가 직접 선택 시, 공급가를 직접 입력하여 적용할 수 있습니다. (옵션 사용 시, 옵션별 공급가 설정 가능)`,
  TOOLTIP_ICON: `아이콘 순서는 ‘상품 > 상품 운영 관리 > 아이콘 관리’ 메뉴에서 설정한 순서에 따라 상품 등록/수정
                 페이지 및 쇼핑몰에 노출됩니다.
                 상시노출 아이콘과 기간노출 아이콘을 모두 사용하시는 경우, 상시노출 아이콘  기간노출 아이콘
                 순서로 노출됩니다.
                 `,
  TOOLTIP_ACCUMULATION_RATE_CONFIG: `상품 개별 적립금을 설정할 수 있습니다.
                                     설정 안 함으로 선택 시 쇼핑몰 기본 적립금이 구매 적립금으로 지급됩니다.`,
  TOOLTIP_PROMOTION:
    '추가할인/쿠폰 모두 가능으로 설정 시, 모든 프로모션 적용이 가능합니다.\n"추가할인"만 가능으로 설정 시, 추가할인만 적용이 가능합니다.\n"쿠폰"만 가능으로 설정 시, 쿠폰 할인 (상품적용쿠폰, 주문적용쿠폰)만 적용이 가능합니다.',

  ALERT_RANGE_MSG_FOR_COMMISSION: '수수료는 100% 미만으로 입력해주세요.',

  INLINE_RANGE_MSG_FOR_ACCUMULATION_RATE: '입력형식이 잘못되었습니다.',
  CATEGORY_DESCRIPTION:
    '표준카테고리 변경 시 등록된 원산지, 인증정보, 카테고리수수료, 성인인증 사용여부 초기화 됩니다.',
  BOOKS_CATEGORY_DESCRIPTION:
    '도서정가제 할인 제한 규정에 따라 표준카테고리 내 도서 카테고리 선택 시 네이버페이 적립가능 표시 불가 처리됩니다.',

  // image-info
  PRODUCT_IMAGE_TITLE: '상품 이미지',
  LIST_IMAGE_TITLE: '리스트 이미지',
  IMAGE_MAX_SIZE: '최대 10MB까지 가능합니다.',
  IMAGE_ADD: '추가',
  IMAGE_LIST: '리스트',
  IMAGE_ADD_DESCRIPTION:
    '상품이미지 등록 시 자동으로 리스트, 확대, 고정 이미지에 리사이징되어 전시됩니다. (추가이미지 최대 9개 등록가능)',

  // delivery-info
  DELIVERY_INFO: '배송 정보',
  DELIVERY_YN: '배송여부',
  DELIVERY_Y: '배송',
  DELIVERY_N: '배송 안 함 ',
  DELIVERY_COMBINATION_YN: '묶음배송 가능여부',
  DELIVERY_COMBINATION_N_FEE: '묶음배송 불가능(상품별 배송비 적용)',
  DELIVERY_INTERNATION_YN: '해외배송 여부',
  DELIVERY_INTERNATION_PRODUCT: '해외배송 상품',
  DELIVERY_INTERNATION_PRODUCT_TOOLTIP: `해외에서 국내로 배송되는 상품을 의미합니다.
    해외배송 상품으로 체크 시, 쇼핑몰 주문서 작성 페이지에 개인통관고유부호를 추가로 입력 받을 수 있습니다.`,
  DELIVERY_TEMPLATE: '배송 템플릿',
  DELIVERY_ADD_TEMPLATE: '신규등록',
  DELIVERY_METHOD: '배송방법',
  DELIVERY_FEE_TYPE: '배송비유형',
  RELEASE_WAREHOUSE_ADDRESS: '출고지',
  RETURN_WAREHOUSE_ADDRESS: '반품/교환지',
  RETURN_DELIVERY_AMT: '반품/교환배송비',
  DELIVERY_ONE_WAY: '편도기준',
  WON: '원',
  PLZ_ADD_TEMPLATE: '등록된 템플릿이 없습니다. 템플릿을 먼저 등록해주세요',
  PLZ_SELECT_TEMPLATE: '배송 템플릿을 먼저 선택해주세요',
  NOTICE_DELIVERY_TEMPLATE: '세트상품은 배송 상품으로만 등록할 수 있습니다.',

  // naver-shopping-ep-info
  NAVER_SHOPPING_EP_TOOLTIP_TITLE: '네이버 쇼핑 정보 등록 안내',
  NAVER_SHOPPING_EP_TOOLTIP_1: `‘판매방식 구분’, ‘상품상태’, ‘수입 및 제작 여부’는 각 항목에 해당하는 상품의 경우 필수로 전송해야 하는 값입니다.`,
  NAVER_SHOPPING_EP_TOOLTIP_2: `해당 상품임에도 값을 전송하지 않은 경우, 네이버 쇼핑에 의해서 상품이 삭제되며, 등급이 하락할 수 있습니다.`,
  NAVER_SHOPPING_EP_TOOLTIP_3: `해당 사항 없는 경우 선택하지 않아도 무방합니다.`,

  NAVER_PAY_AVAILABLE_TOOLTIP: `네이버쇼핑 페이지에 네이버페이 결제 가능 아이콘 표시여부를 선택 합니다.
  결제수단 설정 – ‘네이버페이 결제 불가‘ 체크 시 선택하신 값과 상관 없이 네이버페이 사용 불가로 상품EP가 생성됩니다
  `,

  NAVER_PAY_ACCUMULATION_TOOLTIP: `네이버쇼핑 페이지에 네이버페이 적립 가능 표시여부를 선택 합니다.
  결제수단 설정 – ‘네이버페이 결제 불가‘ 체크 시 적립가능 표시가 불가합니다.
  결제수단 설정 – ‘네이버페이 결제 불가‘ 체크 시 선택하신 값과 상관 없이 네이버페이 적립 불가로 상품EP가 생성됩니다.
  도서상품의 경우 네이버페이 적립이 불가합니다.
  `,

  NAVER_SHOPPING_EP_TITLE: '네이버쇼핑 EP 정보 등록',
  NAVER_SHOPPING_EP_TOOLTIP_BTN_TITLE: '네이버 쇼핑 정보 등록 안내',
  NAVER_SHOPPING_EP_EXPOSURE: '네이버쇼핑 노출여부',
  NAVER_SHOPPING_EP_CATEGORY_ID: '네이버 카테고리 ID',
  NAVER_SHOPPING_EP_CATEGORY_ID_NOTICE_1: '해당하는 카테고리에 매칭하는데 도움이 됩니다.',
  NAVER_SHOPPING_EP_CATEGORY_ID_NOTICE_2: '네이버쇼핑의 전체 카테고리 리스트는',
  NAVER_SHOPPING_EP_CATEGORY_ID_NOTICE_3: '[네이버 쇼핑파트너 센터]',
  NAVER_SHOPPING_EP_CATEGORY_ID_NOTICE_4: '에서 다운로드 할 수 있습니다.',
  NAVER_SHOPPING_EP_COMPARE_PRICE_ID: '가격비교 페이지 ID',
  NAVER_SHOPPING_EP_COMPARE_PRICE_NOTICE_1:
    '네이버 가격비교 페이지 ID를 입력할 경우 네이버 가격비교 추천에 도움이 됩니다.',
  NAVER_SHOPPING_EP_COMPARE_PRICE_NOTICE_2: '예) http://shopping.naver.com/detail/detail.nhn?nv_mid=',
  NAVER_SHOPPING_EP_COMPARE_PRICE_NOTICE_3: '8535546055',
  NAVER_SHOPPING_EP_COMPARE_PRICE_NOTICE_4: '&cat_id=50000151에서 다운로드 할 수 있습니다.',
  NAVER_SHOPPING_EP_SALES_METHOD: '판매방식 구분',
  NAVER_SHOPPING_EP_SALES_METHOD_PLACEHOLDER: '판매방식을 선택해주세요.',
  NAVER_SHOPPING_EP_PRODUCT_STATUS: '상품상태',
  NAVER_SHOPPING_EP_PRODUCT_STATUS_PLACEHOLDER: '상품상태를 선택해주세요.',
  NAVER_SHOPPING_EP_IMPORT_TYPE: '수입 및 제작 여부',
  NAVER_SHOPPING_EP_MONTHLY_RENTAL_FEE: '월렌탈료',
  NAVER_SHOPPING_EP_RENTAL_CONTRACT_PERIOD: '렌탈계약기간',
  NAVER_SHOPPING_EP_MONTHLY_RENTAL_FEE_NOTICE: '월렌탈료가 총렌탈료(=판매가)보다 작아야 네이버쇼핑에 정상 노출됩니다.',

  INSERT_MONTHLY_FEE: '월렌탈료를 입력해 주세요.',
  INSERT_CONTRACT_PERIOD: '렌탈계약기간을 입력해 주세요.',

  EXPOSURE_Y: '노출',
  EXPOSURE_N: '노출 안 함',

  DUTY_INFO_LIST_TITLE: '저장된 상품정보제공고시',
  DUTY_INFO_LIST_NOTICE: '자주 쓰는 상품정보제공고시를 저장해서 사용하세요(최대 100개 등록가능)',

  SELECT_MANUFACTURE_DATE: '제조일자 선택',
  SELECT_EXPIRATION_DATE: '유효일 선택',

  VALID_MANAGEMENT_CD: '사용할 수 있는 상품관리코드 입니다.',
  SAME_MANAGEMENT_CD: '동일한 상품관리코드를 사용하고 있는 상품이 있습니다.',
  PLZ_MANAGEMENT_CD: '상품관리코드를 입력해주세요.',

  ICON: '아이콘',
  REGULAR_EXPOSURE_ICON: '상시노출 아이콘',
  PERIOD_EXPOSURE_ICON: '기간노출 아이콘',

  PLZ_SELECT_STANDARD_CATEGORY_FOR_CERTIFICATION: '인증정보 입력을 위해 표준카테고리를 먼저 선택해주세요.',

  USE: '사용함',
  NOT_USE: '사용 안 함',
  DIRECT_INPUT: '직접 입력',
  DIRECT_INPUT_WITHOUT_SPACE: '직접입력',
  USE_DEFAULT_TEMPLATE: '기본 템플릿 사용',
  IMPORTER: '수입사',

  PLZ_SELECT_MALL_FOR_IMAGES: '- 판매할 상품과 관련된 공지/추가구성 등의 정보를 등록해주세요.',

  DELIVERY: '배송안내',
  AFTER_SERVICE: 'AS안내',
  REFUND: '환불안내',
  EXCHANGE: '교환안내',

  HEADER: '상품 상세(상단)',
  FOOTER: '상품 상세(하단)',
  BODY: '상품 상세',

  ERROR: '입력 불가한 항목이 있습니다. 다시확인해주세요.',
  PLZ_INSERT_SALE_PRICE_FOR_OPTION: '옵션 등록전 판매가 항목을 우선 입력해주세요.',
  PLZ_SELECT_SHOPPING_MALL: '쇼핑몰을 선택해주세요',
  PLZ_SELECT_ADMIN: '담당자를 선택해주세요.',
  PLZ_SELECT_STANDARD_CATEGORY: '표준카테고리를 선택해주세요.',
  PLZ_SELECT_PLATFORM: '노출 대상 플랫폼을 선택해주세요.',
  PLZ_SELECT_GRADE: '노출 대상 회원등급을 선택해주세요.',
  PLZ_INSERT_PRODUCT_NAME: '상품명을 입력해주세요.',
  PLZ_INSERT_PRODUCT_DESC: '상품설명을 입력해주세요.',
  PLZ_INSERT_RESERVATION_STOCK_COUNT: '예약재고를 입력해주세요.',
  PLZ_INSERT_ALTERNATIVE_PHRASE: '가격대체문구를 입력해주세요.',
  PLZ_INSERT_SALE_PRICE: '판매가를 입력해주세요.',
  PLZ_SET_IMMEDIATE_DISCOUNT_PERIOD: '즉시할인기간을 설정해주세요.',
  PLZ_INSERT_STOCK_COUNT: '재고수량을 입력해주세요.',
  PLZ_INSERT_OPTIONS: '옵션을 등록해주세요.',
  PLZ_INSERT_DUTY_INFO: '상품정보고시를 등록해주세요.',
  PLZ_INSERT_CERTIFICATION: '인증정보를 등록해주세요.',
  PLZ_SELECT_DELIVERY_TEMPLATE: '배송템플릿을 선택해주세요.',
  PLZ_FIRST_SELECT_MALL: '쇼핑몰을 먼저 선택해주세요.',
  NO_ICONS_RESULTS: '등록된 아이콘이 없습니다.',
  PLZ_SELECT_PLACE_ORIGIN: '원산지를 선택해주세요.',
  PLZ_INSET_PLACE_COMPANY_NAME: '수입사를 입력해주세요.',
  PLZ_INSERT_PLACE_ORIGIN: '원산지를 입력해주세요.',

  // related-products
  SUCCESS: '성공 {value}건: 대상상품 추가 완료',
  FAIL_DELETED_PRODUCTS: '실패 {value}건: 삭제상품',
  FAIL_PROHIBITED_PRODUCTS: '실패 {value}건: 판매금지 상품',
  FAIL_SELECTED_PRODUCTS: '실패 {value}건: 이미 선택된 상품',
  CONFIRM_DELETE_SOME_PRODUCTS:
    '선택한 상품 추가 시 상품이 500개가 초과되며, \n초과된 개수만큼 기존 상품이 삭제됩니다. \n(리스트 순서대로 하단 상품부터 삭제) \n선택한 상품을 추가하시겠습니까?\n',

  // GUIDE
  EDIT_TEMPLATE: '템플릿 수정',
  CONFIRM_EDIT: '기본 템플릿을 사용중인 모든 상품에 수정 내용이 적용됩니다.\n' + '수정하시겠습니까?',

  ALERT_SALE_PERIOD_RANGE: '판매기간은 예약판매기간 이후로 설정 가능합니다.',

  PLACEHOLDER_INSERT_BRAND: '브랜드명을 입력해주세요.',

  // 네이버쇼핑 EP
  NONE: '해당사항 없음',
  WHOLESALE: '도매',
  RENTAL: '렌탈',
  LENT: '대여',
  INSTALLMENT: '할부',
  RESERVATION_SALE: '예약판매',
  SUBSTITUTION: '구매대행',
  RESERVATION_BUY: '예약구매',

  NEW: '신상품',
  USED: '중고',
  REFURB: '리퍼',
  DISPLAY: '전시',
  RETURN: '반품',
  SCRATCH: '스크래치',

  CROSS_BORDER_SHIPPING: '해외구매대행',
  PARALLEL_IMPORT: '병행수입',
  CUSTOM_ORDER: '주문제작',
  EXTRA_CHARGE: '별도 설치비 있음',

  // 재고 연동 상품
  SYNCED_NOTICE_1: '해당 상품은',
  SYNCED_NOTICE_2: '상품의 재고와 연동된 상품입니다.',
  SYNCED_NOTICE_3: '재고 및 옵션 수정이 필요한 경우, 위의 상품의 정보를 수정하여 주세요.',

  //마케팅 등록
  FACEBOOK: '페이스북',
  ALL: '전체 선택',
  GOOGLE_AD: '구글 광고',
  CRITEO: '크리테오',
  MOBON: '모비온',
  NAVER_SHOPPING: '네이버 쇼핑',
  EXPOSE: '노출함',
  NO_EXPOSE: '노출 안 함',
  MARKETING_CHANNEL_REGIST: '마케팅 채널 등록',
  MARKETING_CHANNEL_DISPLAYABLE: '마케팅 채널 노출',
  MARKETING_CHANNEL_DISPLAYABLE_SETTING: '마케팅 채널 노출 설정',
  SELECT_MARKETING_CHANNEL: '노출할 마케팅 채널을 선택해주세요.',
  CHANNEL_TYPE: '채널 구분',
  MARKETING_CHANNEL_DESCRIPTION:
    '상품 등록 이후 마케팅 채널에 상품 노출이 필요한 경우 마케팅 > 마케팅 설정 > 마케팅 상품 설정에서 일괄 관리로 수정이 가능합니다.',
  MARKETING_CHANNEL_DESCRIPTION_SETTING: '마케팅 노출 상품 설정',
  MORE_THEN_PURCHASE_AVAILABLE: '이상 구매 가능',
  MINIMUM_TWO_SELECT: '최소 2개 이상 입력 가능합니다.',
  TXT_CAUTION_POSSIBILITY: '까지 구매 가능',
  TXT_CAUTION_DAYS_MAX: '일동안 최대',
  MAXIMUM_FOR_ONE_PERSON_PURCHASE: '1인 구매 시 최대',
  MAXIMUM_ON_A_SINGLE_PURCHASE: '1회 구매 시 최대',
  NON_MEMBERS_CAN_ONLY_LIMIT_THE_MAXIMUM_ONCE: '비회원은 1회 최대구매수량 제한만 가능합니다.',
  INPUTABLE_RANGE: '최소 2개부터 999개 까지 입력 가능합니다.',
  NUMBER_LIMIT_RANGE: '최대 999개 이하로 입력해주세요',
  PEOPLE_LIMIT_RANGE: '최대 99,999,999개 이하로 입력해주세요.',
  PERIODL_IMIT_RANGE: '최대 9,999일 동안 최대 99,999,999개 까지 입력 가능합니다.',
  MINIMUM_SELECT: '최소 1개이상 입력해주세요.',
  AT_LEAST_ONE: '최소 1개이상 입력해주세요',
  PLEASE_INSERT_MIN_COUNT: '최소구매수량 항목을 입력해주세요.',
  PLEASE_INSERT_MAX_COUNT: '최대구매수량 항목을 입력해주세요.',
});
