















import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

export interface TableTabMenuItem {
  name: string;
  title: TranslateResult;
}

@Component
export default class TableTabMenu extends Vue {
  @Prop({ required: true }) private list: TableTabMenuItem[];
  @Model('change', { type: String }) readonly focus!: string;

  private isFocusMenu(name: string): boolean {
    return this.focus === name;
  }

  created() {
    if (this.list.length > 0 && this.list.filter(item => item.name === this.focus).length === 0) {
      this.$emit('change', this.list[0].name);
    }
  }

  private changeFocus(name: string): void {
    this.$emit('change', name);
  }
}
