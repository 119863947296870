




import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({ components: { AdminIframe } })
export default class HeadlessBanners extends Vue {
  private get remoteSrc() {
    const queryObject = { serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + this.$route.path + '?' + queryString;

    return src;
  }
}
