



























import { Vue, Component, Watch } from 'vue-property-decorator';
import { GridEventProps, StatisticSynthesisPeriod } from '@/types';
import { getDefaultParams, getSalesByCategoryGridProps } from '@/views/contents/statistics/sales/categorySales';
import {
  GetStatisticsSalesCategoryContents,
  GetStatisticsSalesCategoryRequest,
  GetStatisticsSalesCategorySummaryContents,
  GetStatisticsSalesCategorySummaryRequest,
} from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import StatisticNoticeBox from '@/components/statistics/StatisticNoticeBox.vue';
import CategorySalesForm from '@/views/contents/statistics/sales/CategorySalesForm.vue';
import StatisticSynthesis from '@/components/statistics/sales/StatisticSynthesis.vue';
import { generateQuery, onCreateExcel } from '@/views/contents/statistics/sales/statisticUtils';

@Component({
  components: {
    Grid,
    CategorySalesForm,
    StatisticSynthesis,
    StatisticNoticeBox,
  },
})
export default class CategorySales extends Vue {
  private salesByCategoryGridProps = getSalesByCategoryGridProps();

  private salesByCategoryPeriod: null | StatisticSynthesisPeriod = null;
  private salesByCategorySummary: null | GetStatisticsSalesCategorySummaryContents = null;
  private salesByCategoryContents: GetStatisticsSalesCategoryContents[] = [];
  private salesByCategoryTotalCount = 0;

  @Watch('$route.query')
  private updateQuery() {
    const newQuery = generateQuery(this.$route.query, getDefaultParams);
    return Promise.all([
      this.fetchSalesCategoryStatisticsSummary(newQuery),
      this.fetchSalesCategoryStatistics(newQuery),
    ]);
  }

  // 카테고리별 판매현황 종합 fetch
  private async fetchSalesCategoryStatisticsSummary(queries) {
    const params = { ...queries } as GetStatisticsSalesCategorySummaryRequest['params'];
    const res = await this.$api.getStatisticsSalesCategorySummary({ params });

    const { summary, startYmd, endYmd } = res.data;
    this.salesByCategoryPeriod = { startYmd, endYmd };
    this.salesByCategorySummary = summary;
  }

  // 카테고리별 판매현황 목록 fetch
  private async fetchSalesCategoryStatistics(queries) {
    const params = { ...queries } as GetStatisticsSalesCategoryRequest['params'];
    const res = await this.$api.getStatisticsSalesCategory({ params });

    const { contents, totalCount } = res.data;

    this.salesByCategoryContents = contents;
    this.salesByCategoryTotalCount = totalCount;
  }

  private onItemClicked({ columnName }: GridEventProps) {
    switch (columnName) {
      case 'download':
        onCreateExcel('CATEGORY', { ...this.$route.query });
    }
  }
}
