import { Component, Vue } from 'vue-property-decorator';
import {
  Amounts,
  GetClaimsNo,
  NCPResponse,
  PostOptionCancelsNoPredictAmounts,
  ReturnsPredict,
  ReturnsPredictAmounts,
} from 'ncp-api-supporter';
import { ClaimClassType, ClaimPopupForm, ClaimStatusType, ClaimType } from '@/types/claim';
import { Shipping } from 'ncp-api-supporter/dist/types/modules/claim/claims';
import { Nullable, PutOptionCancelsNoRequest, PutReturnsNoRequest } from 'ncp-api-supporter/dist/types';
import { getClaimOrderOptions } from '@/components/popup/claim/claimOptions.ts';
import { claimClassTypes, claimStatusTypes, claimTypes } from '@/const/claim';
import { payTypes } from '@/const/order';

type PutRequestData<T> = T extends typeof claimTypes.CANCEL
  ? PutOptionCancelsNoRequest['data']
  : PutReturnsNoRequest['data'];
@Component
export default class ClaimPopupMixin extends Vue {
  protected claimNoResponse: null | GetClaimsNo = null;
  protected claimType: null | ClaimType = null;
  protected claimStatusType: Nullable<ClaimStatusType> = null;
  protected claimClassType: null | ClaimClassType = null;
  protected amounts: null | Amounts | PostOptionCancelsNoPredictAmounts | ReturnsPredictAmounts = null;
  protected shipping: null | Shipping | ReturnsPredict['shipping'] = null;

  protected async fetchClaimNo() {
    try {
      const { data }: NCPResponse<GetClaimsNo> = await this.$api.getClaimsNo({
        pathParams: {
          no: this.$route.query.claimNo.toString(),
        },
      });

      const { claimType, claimClassType, claimStatusType } = data;
      this.claimNoResponse = data;
      this.claimType = claimType as ClaimType; // supporter가 그냥 string
      this.claimClassType = claimClassType as ClaimClassType;
      this.claimStatusType = claimStatusType as Nullable<ClaimStatusType>;
      this.amounts = data.claimData.amounts;
      this.shipping = data.claimData.shipping ?? null;
    } catch (err) {
      alert('리소스 찾을 수 없음.');
    }
  }

  protected async checkWithdraw(): Promise<{
    validationType: string;
    afterClaimNos: string[];
  }> {
    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
    };
    const {
      data: { validationType, afterClaimNos },
    } = await this.$api.getClaimsNoCheckWithdraw(request);

    return { validationType, afterClaimNos };
  }

  /*
   * TODO: 리팩토링, 굳이 requestData까지 분해해서 보낼 필요 있나 싶다
   *
   * https://nhnent.dooray.com/project/posts/2973056759744303061
   * 클레임단위 사유변경, 옵션단위 사유변경에 따라 보내는 데이터 포맷이 달라짐.
   * 다중: claimOrderOptions 담아서 요청
   * 단일: claimOrderOptions의 첫번째 아이템만 reasonType,reasonDetail 최상단에 뿌려 요청
   * */
  protected getClaimReasonRequestFormat<T extends typeof claimTypes.CANCEL | typeof claimTypes.RETURN>(
    requestData: PutRequestData<T>,
    formData: ClaimPopupForm,
    modifyType: 'reason' | 'refundAccount',
    claimClassType = null,
  ): PutRequestData<T> {
    const isReasonModify = modifyType === 'reason';
    const targetData = isReasonModify
      ? formData
      : { claimOrderOptions: getClaimOrderOptions(this.claimNoResponse.claimedOptions) };

    if (claimClassType !== claimClassTypes.ORDER_CANCEL && formData.claimOrderOptions.length > 1) {
      return { ...requestData, reasonType: null, reasonDetail: null, claimOrderOptions: targetData.claimOrderOptions };
    }

    const JUST_FIRST_ITEM = 0;
    const { claimOrderOptions } = targetData;
    return {
      ...requestData,
      reasonType: claimOrderOptions[JUST_FIRST_ITEM].reasonType,
      reasonDetail: claimOrderOptions[JUST_FIRST_ITEM].reasonDetail,
    };
  }

  protected getClaimRefundAccountRequestFormat<T extends typeof claimTypes.CANCEL | typeof claimTypes.RETURN>(
    requestData: PutRequestData<T>,
    formData: ClaimPopupForm,
    modifyType: 'reason' | 'refundAccount',
  ): PutRequestData<T> {
    const isRefundAccountModify = modifyType === 'refundAccount';
    const targetData = isRefundAccountModify
      ? formData
      : {
          refundType: this.claimNoResponse.displayRefundType.refundType,
          refundBankAccount: this.claimNoResponse.refundBankAccount,
        };

    if (targetData.refundType === 'ACCOUNT' || this.isVirtualAccount(targetData.refundType)) {
      return {
        ...requestData,
        refundType: targetData.refundType,
        refundBankAccount: targetData.refundBankAccount,
      };
    }

    return {
      ...requestData,
      refundType: targetData.refundType,
      refundBankAccount: null,
    };
  }

  validateNaverPayClaim() {
    if (this.claimNoResponse.originalPayType === payTypes.NAVER_PAY) {
      alert(this.$t('CLAIM.MESSAGE.NAVER_PAY_CENTER_PROCESS_ALERT'));
      return false;
    }

    return true;
  }

  // 가상계좌는 PG임
  private isVirtualAccount(refundType) {
    return refundType === 'PG' && this.claimNoResponse.originalPayType === 'VIRTUAL_ACCOUNT';
  }

  //TODO: includes로 변경
  protected get isPredictableClaim(): boolean {
    return (
      this.claimStatusType === claimStatusTypes.CANCEL_PROC_REQUEST_REFUND ||
      this.claimStatusType === claimStatusTypes.CANCEL_REQUEST ||
      this.claimStatusType === claimStatusTypes.RETURN_REFUND_AMT_ADJUST_REQUESTED ||
      this.claimStatusType === claimStatusTypes.RETURN_PROC_REQUEST_REFUND ||
      this.claimStatusType === claimStatusTypes.RETURN_PROC_BEFORE_RECEIVE ||
      this.claimStatusType === claimStatusTypes.RETURN_REQUEST
    );
  }

  protected mounted() {
    this.$nextTick(() => {
      const $wrapper = document.querySelector('#wrapper');
      if (this.$route.path.includes('accept')) {
        $wrapper.classList.add('type-900');
      } else {
        $wrapper.classList.add('type-1200');
      }
    });
  }
}
