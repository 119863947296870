export default Object.freeze({
  BULK_PRODUCT_NOTICE_TITLE: '대량 상품 등록 가이드',
  BULK_PRODUCT_NOTICE_1: '① 상품을 등록할 쇼핑몰을 선택합니다.',
  BULK_PRODUCT_NOTICE_2:
    '② [엑셀 양식 다운로드]를 클릭하여 업로드 양식을 다운로드합니다. (엑셀 업로드 양식 내 우측 시트에서 상품 코드 정보 확인 가능)',
  BULK_PRODUCT_NOTICE_3: ' ③ 상품 이미지를 등록하고자 하실 경우, 업로드 전 이미지 파일을 먼저 업로드합니다.',
  BULK_PRODUCT_NOTICE_3_1:
    '- 외부 이미지 저장소에 등록된 이미지를 사용하고자 하실 경우, 파일 업로드 없이 경로를 입력하여 이미지를 저장하실 수 있습니다.',
  BULK_PRODUCT_NOTICE_4: '④ ② 에서 다운로드 받은 양식에 등록할 상품의 정보를 입력합니다.',
  BULK_PRODUCT_NOTICE_4_1: '- 상품은 1회 업로드 시 최대 100개까지 등록 가능합니다.',
  BULK_PRODUCT_NOTICE_4_2: '- basic의 경우 50개, pro의 경우 쇼핑몰 당 최대 30,000개까지 상품 등록이 가능합니다.',
  BULK_PRODUCT_NOTICE_5:
    '⑤ 엑셀을 업로드하여 상품을 대량 등록할 수 있으며, 업로드 시 성공/실패 여부를 확인할 수 있습니다. (업로드는 xlsx 파일만 가능)',
  BULK_PRODUCT_NOTICE_5_1: '- 등록 실패한 상품이 1건 이상일 경우, 실패 결과 파일이 즉시 다운로드 됩니다.',
  BULK_PRODUCT_NOTICE_5_2:
    '- 쇼핑몰 제한 개수를 초과하여 실패 처리된 경우 실패 결과 파일에 전체 항목이 색상 구분되어 표기됩니다.',
  BULK_PRODUCT_NOTICE_ADDITION:
    '· 파트너사 상품 대량 등록 기능은 지원되지 않습니다. 대량 등록 시 쇼핑몰 상품만 등록 가능하오니 참고 부탁드립니다.',
  UPLOAD_TITLE: '상품 업로드',
  FAILED_EXCEL_FILE_NAME: '상품대량등록_실패결과',
  //image upload popup
  IMAGE_UPLOAD_POPUP_TITLE: '이미지 등록',
  IMAGE_UPLOAD_POPUP_NOTICE_1: '이미지 파일 용량은 1개당 최대 10MB입니다.',
  IMAGE_UPLOAD_POPUP_NOTICE_2: '지원하는 이미지 파일 형식은 JPG, JPEG, GIF, PNG, BMP 입니다.',
  IMAGE_UPLOAD_POPUP_NOTICE_3:
    '엑셀 파일에 사전에 업로드한 파일명(확장자 포함)을 입력하여 상품이미지로 등록할 수 있습니다.',
  IMAGE_UPLOAD_POPUP_NOTICE_4:
    '외부 이미지 저장소에 등록된 이미지를 사용하고자 하실 경우, 파일 업로드 없이 경로를 입력하여 이미지를 저장하실 수 있습니다.  (http:// 또는 https://로 시작하는 url 경로만 입력 가능)',
  IMAGE_UPLOAD_POPUP_NOTICE_5: '저작권 등 다른 사람의 권리를 침해하는 사진은 관련 법규에 의해 제재를 받을 수 있습니다.',
  IMAGE_UPLOAD_POPUP_FILE_UPLOAD: '이미지 파일 업로드',
  IMAGE_UPLOAD_POPUP_EXTENSION_ERR: '이미지 파일만 업로드 가능합니다. (확장자 : JPG, JPEG, GIF, PNG, BMP)',
  IMAGE_UPLOAD_POPUP_FILE_SIZE_ERR: '이미지 파일은 1개당 최대 10MB까지 업로드 가능합니다.',
  IMAGE_UPLOAD_POPUP_REGISTRATION_MESSAGE: '개의 이미지가 정상적으로 등록되었습니다.',
  IMAGE_UPLOAD_POPUP_REGISTRATION: '등록',
  IMAGE_UPLOAD_POPUP_CHANGE: '변경',
  EXCEL_SAMPLE_FILE_NAME: '상품대량등록_업로드양식_',
});
