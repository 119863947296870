





































































































import { Component } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import DateCalendar from '@/components/common/DateCalendar.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import {
  PostCouponsIssuePlansRequest,
  PromotionCouponIssueTimeType,
  PromotionNotificationToolsType,
} from 'ncp-api-supporter';
import { PopupClose, PopupResult, throwPopup, throwWindowPopup } from '@/helpers/popup';
import NotificationGuide from '@/components/member/NotificationGuide.vue';
import TargetMemberList from '@/components/member/TargetMemberList.vue';

@Component({
  components: { TargetMemberList, NotificationGuide, TextInput, RadioGroup, ToolTip, DateCalendar, CheckBoxGroup },
})
export default class CouponIssuePlansPopup extends WindowPopupMainVue {
  private couponIssueTimeType = 'IMMEDIATELY_ISSUE';
  private couponList = [];
  private couponsItem = [];
  private disableInit = true;
  private possibleToSend = false;

  // 지급 유형
  private couponIssueTimeTypeOption = {
    name: 'couponIssueTimeTypeOption',
    data: [
      { id: 'immediately', value: 'IMMEDIATELY_ISSUE', display: this.$t('MEMBER.POPUP.COUPON_ISSUE_TIME_TYPE_TITLE1') },
      { id: 'scheduled', value: 'SCHEDULED_ISSUE', display: this.$t('MEMBER.POPUP.COUPON_ISSUE_TIME_TYPE_TITLE2') },
      { id: 'period', value: 'PERIOD_ISSUE', display: this.$t('MEMBER.POPUP.COUPON_ISSUE_TIME_TYPE_TITLE3') },
    ],
  };

  // 사유
  private reasonText = '';

  // 알림 설정
  private isNoticeSetOption = false;
  private notificationChannel = ['SMS', 'EMAIL'];

  // 옵션 데이터
  get optionData() {
    return this.data.option.optionData[0];
  }

  get mallName() {
    return this.data.contents[0].mallName;
  }

  // 레이어 타이틀
  get headerText(): string {
    return this.optionData.headerText ? this.optionData.headerText : this.$t('ALERT');
  }

  // TOP 가이드
  get topNoticeMessage(): object[] {
    return this.optionData.topNoticeMessage ? this.optionData.topNoticeMessage : [];
  }

  // 변경사유 타이틀
  get reasonTitle(): string {
    return this.optionData.reasonData.thTitle ? this.optionData.reasonData.thTitle : '';
  }

  // 쿠폰 팝업 호출 타입
  get couponPopupType(): string {
    return this.data.type;
  }

  private searchCoupon() {
    const mallNo = this.data.mallNo;
    if (!mallNo) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.NO_MALL'));
    }
    const popupData = { mallNo, couponList: this.couponList };
    throwWindowPopup('InquiryCoupon', popupData, 'xlg', ({ data, state }: PopupResult) => {
      if (state !== PopupClose.CONFIRM) return;
      this.couponList = [...new Set([...this.couponList, ...data.coupons])];
      data.couponItems.forEach(value => {
        if (!this.couponsItem.find(({ counponId }) => value.counponId === counponId)) {
          this.couponsItem.push(value);
        }
      });
      this.disableInit = false;
    });
  }

  private removeCoupon(index: number): void {
    this.couponList.splice(index, 1);
    this.couponsItem.splice(index, 1);
  }

  // API 연동을 위해 고정값 지정 추후 변수로 교체 필요
  private get couponIssuePlansRequest(): PostCouponsIssuePlansRequest {
    return {
      data: {
        notificationTools: this.isNoticeSetOption ? (this.notificationChannel as PromotionNotificationToolsType[]) : [],
        useNotification: this.isNoticeSetOption,
        notificationRequestYmdt: '',
        couponIssueTargetType: 'MEMBER',
        couponIssueTargetMember: {
          couponIssueMemberType: 'MEMBER_NO',
          memberGroupNos: [],
          memberGradeNos: [],
          memberNos: this.data.memberNos,
        },
        requestYmdt: '',
        mallNo: this.data.mallNo,
        couponNos: this.couponList,
        periodStartYmdt: '',
        couponIssueTimeType: this.couponIssueTimeType as PromotionCouponIssueTimeType,
        periodEndYmdt: '',
        issueReason: this.reasonText,
      },
    };
  }

  private async validate(): Promise<boolean> {
    if (this.couponList.length <= 0) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT'));
      return false;
    }

    if (this.isNoticeSetOption == true && this.notificationChannel.length <= 0) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT_NOTICE_METHOD'));
      return false;
    }

    if (this.isNoticeSetOption == true && !this.possibleToSend) {
      const authResult = await throwPopup({
        name: 'NotificationNotAvailable',
        data: {
          headerText: this.$t('MEMBER.POPUP.NOTIFICATION_HEADER_TEXT'),
          message: this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_PAYMENT_ACCUMULATION'),
          isConfirm: true,
          mallNoValue: this.data.mallNo,
          mallNoMust: true,
        },
      });
      if (authResult.state !== PopupClose.CONFIRM) {
        return false;
      }
    }
    return confirm(this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_DO_COUPON', [this.couponList.length]).toString());
  }

  private async onSaveClick() {
    if (!(await this.validate())) return;
    await this.sendApiRequest();
  }

  private async sendApiRequest(): Promise<void> {
    const response = await this.$api.postCouponsIssuePlans(this.couponIssuePlansRequest);
    if (response.status == 201) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.COUPON_WARN'));
      this.onPositiveClick();
    }
  }
}
