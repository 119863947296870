/* eslint-disable quotes */
export default Object.freeze({
  // ProductsList.vue
  PRODUCT_INFORMATION_INQUIRY: '상품정보 조회/수정',
  BRAND_NAME: '브랜드명',
  PARTNER: '파트너사',
  SELECT_DETAILS: '상세선택',

  YOU_CAN_SEARCH_FOR_MULTIPLE_PRODUCTS: `복수 상품 검색이 가능합니다. (Enter 또는 ','로 구분)`,
  PARTIAL_SEARCH_IS_POSSIBLE_USING_SOME: '일부 상품관리코드와 %를 이용한 부분 검색이 가능합니다.',
  ENTER_BRAND_NAME: '브랜드명 입력',
  ENTER_PARTNER_NAME: '파트너명 입력',
  ENTER_THE_PERSON_IN_CHARGE: '담당자 입력',

  NO_SEARCH_RESULTS: '검색결과 없음.',
  SELECT_ALL: '전체선택',
  //ProductListCheckBox.vue
  ON_PRE_SALE: '예약판매중',
  WAITING_SALE: '판매대기',
  ON_SALE: '판매중',
  END_SALE: '판매종료',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION_SALE: '판매금지',

  NOT_SET: '설정안함',
  VIEW_SETTINGS: '설정보기',
  // NOT_SET: '설정안함',
  // VIEW_SETTINGS: '설정보기',

  //ProductListImageArea.vue
  ADDITIONAL_IMAGE: '추가이미지',
  RECOMMENDED_SIZE: '권장 640x640',
  // ADDITIONAL_IMAGE:'추가이미지',
  // RECOMMENDED_SIZE:'권장 640x640',
  REPRESENTATION: '대표',
  ADD: '추가',
  PLIMAGEAREA_ALERT1: '이미지파일(Jpg,jpge,gif,png,bmp)만 등록해주세요.',
  PLIMAGEAREA_ALERT2: '추가이미지는 최대 9 개까지 등록 가능합니다',

  //ProductListContent.vue
  SEARCH_WORD: '검색어',
  PRODUCT_STATUS: '상품상태',
  SALES_STATUS: '판매상태',
  TERM: '기간',
  SELECT_PERIOD: '기간선택',
  CATEGORY: '카테고리',
  ADVANCED_SEARCH: '상세검색',
  SALES_FEE_METHOD: '판매수수료 방식',
  ONLY_PRODUCTS_FOR_WHICH_THE_SUPPLY: '* 공급가 직접 입력된 상품만 조회됩니다.',
  SEE_WITH_ADDITIONAL_DISCOUNTS: '추가할인 함께 보기',
  VIEW_SET_ITEMS_ONLY: '세트상품만 보기',
  ADDITIONAL_ITEMS: '추가항목',
  // ADDITIONAL_ITEMS: '추가항목',

  //ProductListSlotLeft.vue
  PLSLOTLEFT_BUTTON1: '판매상태 변경',
  PLSLOTLEFT_BUTTON2: '전시상태 변경',
  PLSLOTLEFT_BUTTON3: '판매기간변경',
  PLSLOTLEFT_BUTTON4: '판매가변경',
  PLSLOTLEFT_BUTTON5: '즉시할인설정',
  PLSLOTLEFT_BUTTON6: '홍보문구',
  PLSLOTLEFT_BUTTON7: '배송정보',
  PLSLOTLEFT_BUTTON8: '상품정보고시',
  PLSLOTLEFT_BUTTON9: '일괄 상품심사',
  PLSLOTLEFT_BUTTON10: '프론트 미노출 설정',
  PLSLOTLEFT_BUTTON11: '판매가/즉시할인 일괄변경',
  PLSLOTLEFT_MSG1: '선택하신 상품 중 ',
  PLSLOTLEFT_MSG2: '설정을 변경할 수 없는 상품이 있습니다.\n해당 상품을 제외하고 변경하시겠습니까?',
  PLSLOTLEFT_MSG3: '선택하신 상품은',
  PLSLOTLEFT_MSG4: '설정을 변경할 수 없습니다.',
  PLSLOTLEFT_SALESTATUS: '판매상태',
  PLSLOTLEFT_FRONTDISPLAY: '전시상태',
  PLSLOTLEFT_SALEPERIOD: '판매기간',
  PLSLOTLEFT_SALEPRICE: '판매가',
  PLSLOTLEFT_IMMEDIATEDISCOUNT: '즉시할인',
  PLSLOTLEFT_PROMOTIONTEXT: '홍보문구',
  PLSLOTLEFT_DUTYINFO: '상품정보고시',
  PLSLOTLEFT_DELIVERY: '배송정보',
  PLSLOTLEFT_ALERT1: '상품을 선택해주세요',
  PLSLOTLEFT_ALERT2: '상품을 선택해 주세요.',
  PLSLOTLEFT_ALERT3: '선택하신 상품은 심사 불가능한 상품입니다. 상품을 다시 선택해주세요',
  PLSLOTLEFT_ALERT4: '상품을 선택해 주세요.',
  PLSLOTLEFT_ALERT5: '선택하신 상품은 프론트 노출 설정을 변경할 수 없습니다.',
  PLSLOTLEFT_ALERT6:
    '선택하신 상품 중 프론트 노출 설정을 변경할 수 없는 상품이 있습니다. 해당 상품을 제외하고 변경하시겠습니까?',
  PLSLOTLEFT_ALERT7: '상품을 선택해주세요',
  PLSLOTLEFT_ALERT8:
    '배송정보 일괄 변경은 동일한 쇼핑몰과 파트너사 상품일 경우만 가능합니다. 다시 확인 후 상품을 선택해주세요',

  //ProductsSummary.vue
  ALL: '전체',
  KEY: '건',
  SALE: '판매중',
  WAITING_FOR_SALE: '판매대기',
  SOLD_OUT: '품절',
  WAITING_FOR_APPROVAL: '승인대기',
  REJECTION_OF_APPROVAL: '승인거부',
  REFUSAL_OF_AGREEMENT: '합의거부',
  // STOP_SELLING: '판매중지',

  //ProductListMallInfoMixin.vue
  SHOPPING_MALL: '쇼핑몰',

  //ProductListMethodsMixin.ts
  PLM_BATCH_CHANGE_RESULT: '일괄변경 결과',
  PLM_ALL: '전체',
  PLM_YOU_CAN_ONLY_ENTER_NUMBERS_AND_COMMAS: '숫자와 콤마만 입력할 수 있습니다',
  PLM_SHOPPING_MALL: '쇼핑몰',
  PLM_PRODUCT_NUMBER: '상품번호',
  PLM_PRODUCT_NAME: '상품명',
  PLM_PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  PLM_BRAND: '브랜드',
  PLM_SALES_METHOD: '판매방식',
  PLM_PARTNER: '파트너사',
  PLM_MANAGER: '담당자',
  PLM_COMMISSION_RATE: '수수료율',
  PLM_SUPPLY_PRICE: '공급가',
  PLM_PURCHASE_PRICE: '매입가',
  PLM_APPROVAL_STATUS: '승인상태',
  PLM_SALES_STATUS: '판매상태',
  PLM_EXHIBITION_STATUS: '전시상태',
  PLM_PRE_ORDER_PERIOD: '예약판매기간',
  PLM_SALES_PERIOD: '판매기간',
  PLM_PRICE: '판매가',
  PLM_IMMEDIATE_DISCOUNT: '즉시할인',
  PLM_DISCOUNT_APPLICABLE_PRICE: '할인적용가',
  PLM_RESERVE_STOCK_QUANTITY: '예약재고수량',
  PLM_AVAILABLE_STOCK: '재고수량',
  PLM_SALES_QUANTITY: '판매수량',
  PLM_APPLY_PROMOTION: '프로모션 적용',
  PLM_REGISTER_PRICE_COMPARISON_SITE: '가격비교사이트 등록',
  PLM_EXHIBITION_CATEGORY: '전시카테고리',
  PLM_PRODUCT_GROUP: '상품군',
  PLM_DELIVERY_STATUS: '배송여부',
  PLM_SHIPPING_CLASSIFICATION: '배송구분',
  PLM_SHIPPING_COST_TYPE: '배송비유형',
  PLM_SHIPPING_FEE: '배송비',
  PLM_BUNDLED_SHIPPING: '묶음배송',
  PLM_DELETE_REQUEST: '삭제요청',
  PLM_BUY: '사입',
  PLM_CONSIGNMENT: '위탁',
  PLM_WAITING_FOR_REGISTRATION: '등록대기',
  PLM_WAITING_FOR_APPROVAL: '승인대기',
  PLM_REJECTION_OF_APPROVAL: '승인거부',
  PLM_WAITING_FOR_SALES_AGREEMENT: '판매합의대기',
  PLM_REJECTION_OF_SALES_AGREEMENT: '판매합의거부',
  PLM_WAITING_FOR_APPROVAL_AFTER_MODIFICATION: '수정 후 승인대기',
  PLM_REJECT_APPROVAL_AFTER_MODIFICATION: '수정 후 승인거부',
  PLM_APPROVAL_COMPLETED: '승인완료',
  PLM_BEFORE_APPROVAL_COMPLETION: '승인완료이전',
  PLM_WAITING_FOR_PRE_ORDER: '예약판매대기',
  PLM_PRE_ORDER: '예약판매중',
  PLM_WAITING_FOR_SALE: '판매대기',
  PLM_SALE: '판매중',
  PLM_END_OF_SALE: '판매종료',
  PLM_AVAILABLE_FOR_SALE: '판매가능',
  PLM_STOP_SELLING: '판매중지',
  PLM_PROHIBITION_OF_SALE: '판매금지',
  PLM_DISPLAY_POSSIBLE: '전시가능',
  PLM_NO_EXHIBITION: '전시안함',
  // PLM_BUY: '사입',
  // PLM_CONSIGNMENT: '위탁',
  PLM_DELIVERY: '배송',
  PLM_NO_DELIVERY: '배송안함',
  PLM_PARTNER_DELIVERY: '파트너사 배송',
  PLM_SHOPPING_MALL_SHIPPING: '쇼핑몰 배송',
  // PLM_ALL: '전체',
  PLM_FREE: '무료',
  PLM_DELETE: '삭제',
  PLM_IF_YOU_CHANGE_TO_PROHIBITION_OF_SALES:
    '판매금지로 변경 할 경우 판매재개가 불가능합니다. 판매금지로 변경하시겠습니까?',
  PLM_IT_IS_CHANGED: '수정되었습니다.',
  // PLM_IT_IS_CHANGED: '수정되었습니다.',
  PLM_PRODUCT_INFORMATION_CHANGE_HISTORY: '상품정보 변경이력',
  PLM_ITEMS_THAT_NEED_TO_BE_MODIFIED_AS_A_RESULT: '상품심사 결과 수정필요항목',
  PLM_PRODUCT_MODIFICATION: '상품수정',
  PLM_PLEASE_ENTER_A_SEARCH_TERM: '검색어를 입력해주세요.',
  PLM_CONDITIONALLY_FREE: '조건부 무료',
  PLM_PAID_FIXED_SHIPPING_FEE: '유료(고정 배송비)',
  PLM_ITEM: '항목',
  PLM_REGISTRATION_INFORMATION: '등록정보',
  PLM_CHANGE_DATE: '변경일',
  // PLM_APPROVAL_STATUS: '승인상태',
  // PLM_SALES_STATUS: '판매상태',
  PLM_CONTENTS: '내용',
  // PLM_MANAGER: '담당자',
  PLM_THIS_ITEM_NEEDS_TO_BE_MODIFIED_FOR: '판매진행을 위해 수정이 필요한 항목입니다.',
  PLM_PLEASE_CHECKMODIFY_THE_DETAILS_FOR: '항목별로 상세 내용 확인/수정 후 재승인요청 부탁드립니다.',

  //ProductListDataMixin.ts
  PLD_PLEASE_SELECT_A_SHOPPING_MALL_FIRST: '쇼핑몰을 먼저 선택해주세요',
  PLD_APPROVAL_STATUS: '승인상태',
  PLD_WAITING_FOR_REGISTRATION: '등록대기',
  PLD_WAITING_FOR_APPROVAL: '승인대기',
  PLD_REJECTION_OF_APPROVAL: '승인거부',
  PLD_WAITING_FOR_SALES_AGREEMENT: '판매합의대기',
  PLD_REJECTION_OF_SALES_AGREEMENT: '판매합의거부',
  PLD_APPROVAL_COMPLETED: '승인완료',
  PLD_WAITING_FOR_APPROVAL_AFTER_MODIFICATION: '수정 후 승인대기',
  PLD_REJECT_APPROVAL_AFTER_MODIFICATION: '수정 후 승인거부',
  PLD_PRODUCT_NUMBER: '상품번호',
  PLD_PRODUCT_NAME: '상품명',
  PLD_PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  PLD_PRODUCT_MANAGEMENT_CODE_PARTIAL_SEARCH: '상품관리코드|부분검색',
  PLD_PRODUCT_REGISTRATION_DATE: '상품등록일',
  PLD_START_DATE_OF_PRE_ORDER: '예약판매시작일',
  PLD_END_OF_SALES_BY_SUBSCRIPTION: '예약판매종료일',
  PLD_SALES_START_DATE: '판매시작일',
  PLD_SALE_END_DATE: '판매종료일',
  PLD_LAST_MODIFIED_DATE: '최종수정일 ',
  PLD_EXHIBITION_CATEGORY: '전시 카테고리',
  PLD_STANDARD_CATEGORY: '표준 카테고리',
  PLD_PLATFORM_CLASSIFICATION: '플랫폼구분',
  PLD_MOBILE_APP: '모바일앱',
  PLD_MOBILE_WEB: '모바일웹 ',
  PLD_WHETHER_FRONT_EXPOSURE: '프론트 노출 여부',
  PLD_FRONT_EXPOSURE: '프론트 노출',
  PLD_FRONT_UNEXPOSED: '프론트 미노출',
  PLD_MEMBERSHIP_LEVEL_EXPOSURE: '회원등급 노출',
  PLD_MEMBER_GROUP_EXPOSURE: '회원그룹 노출',
  PLD_AVAILABILITY: '재고여부',
  PLD_NO_STOCK: '재고없음',
  PLD_IN_STOCK: '재고 있음',
  PLD_N5_OR_LESS: '5개 이하',
  PLD_N10_OR_LESS: '10개 이하',
  PLD_N50_OR_LESS: '50개 이하',
  PLD_N100_OR_LESS: '100개 이하',
  PLD_REMAINING_SALES_PERIOD: '남은판매기간',
  PLD_N3_DAYS: '3일',
  PLD_N7_DAYS: '7일',
  PLD_N15TH: '15일',
  PLD_N30_DAYS: '30일',
  PLD_SALES_METHOD: '판매방식',
  PLD_BUY: '사입',
  PLD_CONSIGNMENT: '위탁',
  PLD_SHIPPING_CLASSIFICATION: '배송구분',
  PLD_PARTNER_DELIVERY: '파트너사 배송',
  PLD_SHOPPING_MALL_SHIPPING: '쇼핑몰 배송',
  PLD_SHIPPING_COST_TYPE: '배송비유형',
  PLD_FREE: '무료',
  PLD_CONDITIONALLY_FREE: '조건부 무료',
  PLD_PAID_FIXED_SHIPPING_FEE: '유료(고정 배송비)',
  PLD_BUNDLED_SHIPPING: '묶음배송',
  PLD_POSSIBLE: '가능',
  PLD_IMPOSSIBLE: '불가',
  PLD_WHETHER_PROMOTION: '프로모션 여부',
  // PLD_POSSIBLE: '가능',
  // PLD_IMPOSSIBLE: '불가',
  PLD_ALL: '전체',
  // PLD_POSSIBLE: '가능',
  PLD_IMPOSSIBLE2: '불가능',
  // PLD_ALL: '전체',
  PLD_COMMISSION_RATE: '수수료율',
  PLD_SUPPLY_PRICE: '공급가',
  PLD_SHOPPING_MALL: '쇼핑몰',
  PLD_BEFORE_APPROVAL_COMPLETION: '승인완료이전',
  PLD_WAITING_FOR_PRE_ORDER: '예약판매대기',
  PLD_PRE_ORDER: '예약판매중',
  PLD_WAITING_FOR_SALE: '판매대기',
  PLD_SALE: '판매중',
  PLD_END_OF_SALE: '판매종료',
  PLD_AVAILABLE_FOR_SALE: '판매가능',
  PLD_STOP_SELLING: '판매중지',
  PLD_PROHIBITION_OF_SALE: '판매금지',
  PLD_IT_IS_CHANGED: '수정되었습니다.',
  PLD_MODIFY: '수정',
  // PLD_MODIFY: '수정',
  PLD_EXAMPLE: '보기',
  PLD_COPY: '복사',
  // PLD_SHOPPING_MALL: '쇼핑몰',
  // PLD_PRODUCT_NUMBER: '상품번호',
  // PLD_PRODUCT_NAME: '상품명',
  // PLD_PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  PLD_BRAND: '브랜드',
  // PLD_SALES_METHOD: '판매방식',
  // PLD_BUY: '사입',
  // PLD_CONSIGNMENT: '위탁',
  PLD_PARTNER: '파트너사',
  PLD_MANAGER: '담당자',
  // PLD_COMMISSION_RATE: '수수료율',
  // PLD_SUPPLY_PRICE: '공급가',
  PLD_PURCHASE_PRICE: '매입가',
  // PLD_APPROVAL_STATUS: '승인상태',
  // PLD_WAITING_FOR_REGISTRATION: '등록대기',
  // PLD_WAITING_FOR_APPROVAL: '승인대기',
  // PLD_REJECTION_OF_APPROVAL: '승인거부',
  // PLD_WAITING_FOR_SALES_AGREEMENT: '판매합의대기',
  // PLD_REJECTION_OF_SALES_AGREEMENT: '판매합의거부',
  // PLD_WAITING_FOR_APPROVAL_AFTER_MODIFICATION: '수정 후 승인대기',
  // PLD_REJECT_APPROVAL_AFTER_MODIFICATION: '수정 후 승인거부',
  // PLD_APPROVAL_COMPLETED: '승인완료',
  PLD_SALES_STATUS: '판매상태',
  PLD_SALES_SETTING_STATUS: '판매설정',
  PLD_END_OF_SUBSCRIPTION_SALE: '예약판매종료',
  // PLD_END_OF_SALE: '판매종료',
  // PLD_PRE_ORDER: '예약판매중',
  PLD_ON_SALEON_RESERVATION_SALE: '판매가능', //'판매중/예약판매중',
  // PLD_STOP_SELLING: '판매중지',
  // PLD_PROHIBITION_OF_SALE: '판매금지',
  PLD_EXHIBITION_STATUS: '전시상태',
  PLD_DISPLAY_POSSIBLE: '전시가능',
  PLD_NO_EXHIBITION: '전시안함',
  // PLD_FRONT_UNEXPOSED: '프론트 미노출',
  // PLD_FRONT_EXPOSURE: '프론트 노출',
  // PLD_FRONT_UNEXPOSED: '프론트 미노출',
  // PLD_MEMBERSHIP_LEVEL_EXPOSURE: '회원등급 노출',
  // PLD_MEMBER_GROUP_EXPOSURE: '회원그룹 노출',
  PLD_PRE_ORDER_PERIOD: '예약판매기간',
  PLD_SALES_PERIOD: '판매기간',
  PLD_PRICE: '판매가',
  PLD_IMMEDIATE_DISCOUNT: '즉시할인',
  PLD_DISCOUNT_APPLICABLE_PRICE: '할인적용가',
  PLD_ADDITIONAL_DISCOUNT: '추가할인',
  PLD_ADDITIONAL_DISCOUNT_APPLICABLE: '추가할인 적용가',
  PLD_RESERVE_STOCK_QUANTITY: '예약재고수량',
  PLD_INVENTORY_QUANTITY_SALEABLE_INVENTORY: '재고수량', //old: 재고수량&(판매가능재고)
  PLD_WAITING_FOR_DELIVERY: '출고대기',
  PLD_SALES_QUANTITY: '판매수량',
  PLD_OPTION: '옵션',
  // PLD_MODIFY: '수정',
  // PLD_EXAMPLE: '보기',
  PLD_ADDITIONAL_PRODUCTS: '추가상품',
  // PLD_MODIFY: '수정',
  // PLD_EXAMPLE: '보기',
  PLD_APPLY_PROMOTION: '프로모션 적용',
  PLD_IMAGE_INFORMATION: '이미지정보',
  // PLD_MODIFY: '수정',
  // PLD_EXAMPLE: '보기',
  PLD_PRODUCT_INFORMATION_NOTICE: '상품정보고시',
  // PLD_MODIFY: '수정',
  // PLD_EXAMPLE: '보기',
  PLD_REGISTER_PRICE_COMPARISON_SITE: '가격비교사이트 등록',
  PLD_PRODUCT_INFORMATION_CHANGE_HISTORY: '상품정보 변경이력',
  // PLD_EXHIBITION_CATEGORY: '전시카테고리',
  PLD_PRODUCT_GROUP: '상품군',
  // PLD_BUY: '사입',
  // PLD_CONSIGNMENT: '위탁',
  PLD_DELIVERY_STATUS: '배송여부',
  PLD_DELIVERY: '배송',
  PLD_NO_DELIVERY: '배송안함',
  // PLD_SHIPPING_CLASSIFICATION: '배송구분',
  // PLD_PARTNER_DELIVERY: '파트너사 배송',
  // PLD_SHOPPING_MALL_SHIPPING: '쇼핑몰 배송',
  // PLD_ALL: '전체',
  // PLD_SHIPPING_COST_TYPE: '배송비유형',
  // PLD_PAID_FIXED_SHIPPING_FEE: '유료(고정 배송비)',
  // PLD_FREE: '무료',
  PLD_SHIPPING_FEE: '배송비',
  // PLD_BUNDLED_SHIPPING: '묶음배송',
  PLD_DELETE_REQUEST: '삭제요청',
  PLD_BASIC_INFORMATION: '기본정보',
  PLD_SALES_INFORMATION: '판매정보',
  // PLD_IMAGE_INFORMATION: '이미지정보',
  PLD_SHIPPING_INFORMATION: '배송정보',
  PLD_SEPARATE_APPROVAL_REJECTION_OPINION: '별도 승인거부 의견',
  PLD_COMMONMSG1: `'상품관리코드|부분검색' 안내`,
  PLD_COMMONMSG2: `'상품관리코드|부분검색'은 일부 상품관리코드와 %를 이용한 부분검색이 가능합니다.`,
  PLD_COMMONMSG3: `검색어`,
  PLD_COMMONMSG4: `입력: 검색어가 포함된 상품관리코드를 검색합니다.`,
  PLD_COMMONMSG5: `검색어%`,
  PLD_COMMONMSG6: `입력: 검색어가 앞에 포함된 상품관리코드를 검색합니다.`,
  PLD_COMMONMSG7: `%검색어`,
  PLD_COMMONMSG8: `입력: 검색어가 뒤에 포함된 상품관리코드를 검색합니다.`,
  PLD_COMMONMSG9: `프론트 미노출 설정 안내`,
  PLD_COMMONMSG10: `'프론트 미노출' 인 상품은 '전시카테고리' 및 '쇼핑몰 검색영역' 에 노출되지 않습니다.`,
  PLD_COMMONMSG11: `단, '기획전' 및 '상품섹션'에 등록된 상품이 노출됩니다. (전시상태가 '전시안함' 인 경우, 노출되지 않습니다.)`,

  NO_RESULTS_WERE_FOUND_FOR_YOUR_SEARCH: '검색결과가 없습니다.',

  SALESTATUSTYPE_READY: '판매대기',
  SALESTATUSTYPE_ONSALE: '판매중',
  SALESTATUSTYPE_FINISHED: '판매종료',
  SALESTATUSTYPE_STOP: '판매중지',
  SALESTATUSTYPE_PROHIBITION: '판매금지',
  SALESTATUSTYPE_RESERVATION_ONSALE: '예약판매중',
  SALESTATUSTYPE_RESERVATION_FINISHED: '예약판매종료',

  SET: '세트',
  DELETE_COMFIRM_TXT: '삭제하신 상품은 판매가 불가능합니다. 정말 삭제하시겠습니까?',
  SALE_STATUS_SETTING: '판매설정',

  //ModifySalePrice
  MODIFY_PRICE_NOTICE:
    '· 복수 상품의 판매가와 즉시할인을 엑셀 업로드로 일괄 변경 가능합니다.\n' +
    '· 상품에 따라 판매가/즉시할인 변경이 불가할 수 있습니다.\n' +
    '· 세트상품의 경우 판매가/즉시할인 변경이 불가합니다.\n',
  ALERT_DISALLOW_EXTENSION: '잘못된 형식의 파일입니다.',
  CHANGE_PRICE: '변경할 판매가',
  IMMEDIATE_DISCOUNT_UNIT: '즉시할인 단위',
  IMMEDIATE_DISCOUNT_START_DATE: '즉시할인 시작일시',
  IMMEDIATE_DISCOUNT_END_DATE: '즉시할인 종료일시',
  UPLOAD_MAX_LENGTH: '1회 업로드 시 최대 500개까지 변경 가능합니다.',
  MODIFY_SALE_PRICE: '가격 일괄변경',
  EXCEL_SAMPLE_FILE_NAME: '상품가격일괄변경_업로드양식',
  EXCEL_RESULT_FILE_NAME: '상품가격일괄변경_결과파일',
  MSFAILURE: '실패',
  MSSUCCESS: '성공',
  MSUPLOAD_FAILED_NO_INPUT_VALUE: '업로드 실패 : 입력값 없음',
  MSBATCH_CHANGE_RESULT: '일괄변경 결과',
  RESULT_MESSAGE: '업로드가 완료되었습니다.\n성공 : {0} 건, 실패 : {1} 건',
  RESULT_DOWN: '결과 다운로드',
  UPLOAD: '업로드',
});
