export default Object.freeze({
  PAGE_TITLE: '1:1문의 관리',
  TAB_LIST: '1:1문의 답변',
  TAB_TYPE: '문의유형/담당자 관리',
  TAB_TEMPLATE: '답변 템플릿 관리',
  TEMPLATE_SETTING: '답변 템플릿 설정',
  TEMPLATE_BTN_ADD: '답변템플릿 추가',
  TEMPLATE_NODATA: '답변템플릿을 생성 해 주세요.',
  TEMPLATE_DETAIL_TITLE: '답변 템플릿 상세설정',
  TEMPLATE_DETAIL_NODATA: '좌측에서 답변 템플릿을 추가 하시면, 상세설정이 가능합니다.',
  TEMPLATE_NAME: '답변 탬플릿명',
  PLACEHOLDER_TEMPLATE_NAME: '답변템플릿명 입력',
  TEMPLATE_CONTENT: '답변내용',
  TAMPLATE_NAME_NEW: '새 답변템플릿',
  TEMPLATE_NAME_NODATA: '템플릿명이 입력되지 않았습니다.',
  TEMPLATE_CONTENT_NODATA: '의 답변내용이 입력되지 않았습니다.',
  TEMPLATE_SAVE_FAIL: '템플릿 변경사항 저장에 실패했습니다.',
  TEMPLATE_SAVE_NEW: '새 답변템플릿 저장 후에 변경이 가능합니다.',
  TEMPLATE_SAVE_NEW_ORDER: '새 답변템플릿 저장 후 순서 변경이 가능합니다.',
});
