import { GridProp, OptColumn } from '@/types';
import { GroupOption } from '@/helpers/type';

export const datePeriodTypes = {
  periodTypes: ['ytd', '3d', '1w', '1m'],
};

export const checkboxData: GroupOption<string> = {
  name: 'statusCheckType',
  data: [
    { id: 'date', value: 'DATE', display: 'MARKETING.CONFIGURATION.DATE' },
    { id: 'product', value: 'PRODUCT', display: 'MARKETING.CONFIGURATION.PRODUCT' },
  ],
};

const commonReportGridColumns: OptColumn[] = [
  {
    header: 'MARKETING.CONFIGURATION.AD_BILL',
    name: 'costMicros',
    align: 'center',
  },
  {
    header: 'MARKETING.CONFIGURATION.PURCHASE_CONVERSIONS_COUNT',
    name: 'conversions',
    align: 'center',
  },
  {
    header: 'MARKETING.CONFIGURATION.PURCHASE_CONVERSIONS_BILL',
    name: 'conversionsValue',
    align: 'center',
  },
  {
    header: 'MARKETING.CONFIGURATION.EXPOSE_COUNT',
    name: 'impressions',
    align: 'center',
  },
  {
    header: 'MARKETING.CONFIGURATION.CLICK_COUNT',
    name: 'clicks',
    align: 'center',
  },
  {
    header: 'MARKETING.CONFIGURATION.CLICK_PER',
    name: 'ctr',
    align: 'center',
  },
  {
    header: 'MARKETING.CONFIGURATION.CLICK_BILL',
    name: 'averageCpcMicros',
    align: 'center',
  },
];

const commonReportGridOptions = {
  displayOptions: {
    hasExcelDownloadButton: false,
    hasSettingButton: false,
    hasPageSizeSelector: false,
  },
  options: {},
  header: {},
};

export const totalGridProp = (): GridProp<void> => ({
  columns: commonReportGridColumns,
  ...commonReportGridOptions,
});

export const dateGridProp = (): GridProp<void> => ({
  columns: [
    {
      header: 'MARKETING.CONFIGURATION.DAY',
      name: 'date',
      align: 'center',
    },
    ...commonReportGridColumns,
  ],
  ...commonReportGridOptions,
});

export const productGridProp = (): GridProp<void> => ({
  columns: [
    {
      header: 'MARKETING.CONFIGURATION.PRODUCT_NO',
      name: 'productNo',
      align: 'center',
    },
    {
      header: 'MARKETING.CONFIGURATION.PRODUCT_NAME',
      name: 'productName',
      align: 'center',
    },
    ...commonReportGridColumns,
  ],
  ...commonReportGridOptions,
});
