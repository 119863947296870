























import { Component, Vue, Prop } from 'vue-property-decorator';
import EmailInput from '@/components/common/input/EmailInput.vue';
import PreviewThumb from '@/components/popup/board/PreviewThumb.vue';
import { getCurrentMallNo } from '@/utils/mall';
import {
  GetReviewsConfigurationsWidget,
  GetReviewsConfigurationsWidgetPreviewReviewedProducts,
} from 'ncp-api-supporter';
import { WidgetTypeAndCount } from '@/types';

@Component({ components: { PreviewThumb, EmailInput } })
export default class PreviewPopup extends Vue {
  @Prop({ required: true })
  private data: WidgetTypeAndCount;
  private widgetList: GetReviewsConfigurationsWidget[] | GetReviewsConfigurationsWidgetPreviewReviewedProducts[] = [];
  private mallNo = 0;

  async created() {
    this.mallNo = getCurrentMallNo(this);

    if (this.data.type === 'REVIEWED_PRODUCTS') {
      await this.getReviewedProducts();
      return;
    }

    await this.getWidgetPreview();
  }

  private getRequest(mallNo: number, widgetType?: string) {
    return {
      params: {
        mallNo,
        widgetType,
      },
    };
  }

  private async getReviewedProducts() {
    const request = {
      params: {
        mallNo: this.mallNo,
        widgetCount: this.data.widgetCount,
      },
    };
    const { data } = await this.$api.getReviewsConfigurationsWidgetPreviewReviewedProducts(request);
    this.widgetList = data;
  }

  private async getWidgetPreview() {
    const request = {
      params: {
        mallNo: this.mallNo,
        widgetType: this.data.type,
        widgetCount: this.data.widgetCount,
      },
    };

    const { data } = await this.$api.getReviewsConfigurationsWidgetPreview(request);
    this.widgetList = data;
  }
}
