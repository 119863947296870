




























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import CurrencyTextInput from '@/components/common/CurrencyTextInput.vue';
import { eventType } from '@/types';
import { EVENT_TYPE } from '@/const/common';
import { i18nForDelivery } from '@/const/contents/configuration/shippingCharge';
@Component({
  components: { Currency: CurrencyTextInput },
})
export default class SelectedAreaFee extends Vue {
  @PropSync('batchFee', { required: true })
  private batchFeeSync: number;

  @Prop({ required: true })
  private readonly isKorea: boolean;

  @Prop({ required: false, default: false })
  private readonly isPartner: boolean;

  private readonly i18nForDelivery = i18nForDelivery;

  private EVENT_TYPE = EVENT_TYPE;
  private onClick({ target }: { target: HTMLButtonElement }) {
    if (target.dataset.type === undefined) return;

    const type: eventType = target.dataset.type;
    this.$emit(type);
  }

  destroy() {
    this.$off('click');
  }
}
