












































































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import AmountMixin from '@/components/popup/claim/AmountMixin';

@Component
export default class TdDeliveryDifference extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly isExchangeClaim!: boolean;

  @Prop({ required: true })
  private readonly differenceLineThrough!: 'none' | 'line-through';

  @Prop({ required: true })
  private readonly afterDeliveryLineThrough!: 'none' | 'line-through';

  @Prop({ required: true })
  private readonly beforeDeliveryLineThrough!: 'none' | 'line-through';

  @Prop({ required: true })
  private readonly deliveryAmtModified;

  @Prop({ required: true })
  private readonly showExchangeShippingInfo!: boolean;

  @Prop({ required: true })
  private readonly showResponsibleCheckbox!: boolean;

  @Prop({ required: true })
  private readonly responsibleCheckboxDisabled!: boolean;

  @PropSync('sellerPaysClaimedDelivery')
  private sellerPaysClaimedDeliverySync!: boolean;
}
