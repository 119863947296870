export default Object.freeze({
  // MEMBER_COLUMN_CONFIG
  TITLE: '그리드 컬럼 개인설정', // MEMBER_COLUMN_CONFIG_TITLE

  BTN_DISPLAY_SELECT: '체크컬럼 노출',
  BTN_UNDISPLAY_SELECT: '체크컬럼 미노출',
  BTN_DISPLAY_ALL: '전체 노출',
  BTN_UNDISPLAY_ALL: '전체 미노출',

  COLUMN_ORDER: '순서',
  COLUMN_NAME: '컬럼명',
  COLUMN_VISIBLE: '컬럼명',

  VISIBLE: '노출',
  UNVISIBLE: '미노출',

  BTN_SETTING: '저장',
  BTN_CLOSE: '닫기',
  BTN_RESET: '초기화',

  ALERT_COLUMN_UNVISIBLE_ALL: '그리드 컬럼은 최소 1개이상 노출되어야합니다.',
});
