export const adminFormRadioOption = {
  ipAccessControl: {
    name: 'ipAccessControl',
    data: [
      {
        id: 'IP_ACCESS_CONTROL_NOT_USE',
        display: 'NOT_USED',
        value: false,
      },
      {
        id: 'IP_ACCESS_CONTROL_USE',
        display: 'USED',
        value: true,
      },
    ],
  },
  externalAccessOptions: {
    name: 'externalAccessOptions',
    data: [
      {
        id: 'POSSIBLE',
        display: '가능',
        value: true,
      },
      {
        id: 'IMPOSSIBLE',
        display: '불가',
        value: false,
      },
    ],
  },
};
