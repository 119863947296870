export default Object.freeze({
  OPERATOR_EDIT: '운영자 수정',
  OPERATOR_INFORMATION: '운영자계정 정보',
  REQUIRED: '* 필수입력',
  ACCOUNT_TYPE: '계정유형',
  ID: '아이디',
  OPERATOR_NAME: '운영자명',
  EMAIL_ADDRESS: 'e메일 주소',
  RANK: '직급',
  DEPARTMENT_NAME: '소속부서',
  POSITION: '직책',
  PHONE_NUMBER: '전화번호',
  MOBILE_PHONE_NUMBER: '휴대폰번호',
  EXTERNAL_CONNECTION: '외부접속',
  POSSIBLE: '가능',
  IMPOSSIBLE: '불가능',
  FOR_EXTERNAL_ACCESS: '※ 외부접속 시 운영자 휴대폰 SMS 인증 절차를 실시합니다.',
  SA_AUTHORITY_SETTING: 'SA 권한설정',
  AUTHORITY_GROUP: '권한그룹',
  SAVE: '변경사항 저장',
  DELETE_ADMIN: '계정삭제',
  SELECT_DEPARTMENT: '소속부서 선택',
  SELECT_JOB_DUTY: '직책선택',
  CHOOSE: '선택하세요',
  CANCEL_MODIFY: '수정을 취소하시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
  CONFIRM_MESSAGE: '삭제한 계정 정보는 복구되지 않습니다.\r\n이 계정을 삭제하시겠습니까?',
  CONFIRM_SELFMESSAGE:
    '이 계정은 현재 접속중인 운영자님의 계정입니다.\r\n삭제한 계정 정보는 복구되지 않으며 삭제 즉시 로그아웃 처리 됩니다.\r\n\r\n정말로 삭제하시겠습니까?',
  DELETE_COMPLETE: '운영자가 삭제되었습니다.',
  RE_CHECK_SMS_AUTH: 'SMS 인증을 진행해 주시기 바랍니다.',
  INSERT_EMAIL: 'e메일을 입력해주세요.',
  RE_CHECK_FORMAT: 'e메일 형식을 확인해주세요.',
  INSERT_POSITION: '직급을 입력해 주세요.',
  INSERT_PHONE: '전화번호를 입력해 주세요.',
  INSERT_MOBILE: '휴대폰번호를 입력해 주세요.',
  RE_CHECK_MOBILE: '휴대폰번호 형식을 확인해주세요.',
  SELECT_AUTH_GROUP: '권한그룹을 선택해 주세요',

  // 운영자 IP 접속제한 안내 팝업
  IP_ACCESS_CONTROL_NOTICE_1:
    '운영자 IP 접속제한 사용여부를 ‘사용함’으로 설정 시 관리자 접속가능 IP와는 무관하게 운영자 접속제한 IP 외 외부 IP는 접근이 차단됩니다.',
  IP_ACCESS_CONTROL_NOTICE_2:
    '운영자 접속제한 사용여부를 ‘사용 안 함’으로 설정하였더라도 관리자 접속 IP 설정에 따라 외부 IP에서의 접근이 차단될 수 있습니다.',
  IP_ACCESS_CONTROL_NOTICE_3:
    '관리자 접속가능 IP 또는 운영자 접속가능 IP를 설정한 경우 허용된 IP에 대해 접근이 허용됩니다.',
  IP_ACCESS_CONTROL_NOTICE_4:
    "관리자 접속가능 IP 설정은 [설정 > 보안정책 > 운영 보안 설정]의 '관리자 IP 접속제한 사용여부’ 에서 설정 가능합니다.",
  IP_ACCESS_CONTROL_NOTICE_4_PARTNER: "관리자 접속가능 IP 설정은 '접속 IP 설정 > IP 주소 등록' 에서 설정 가능합니다.",
  IP_ACCESS_CONTROL_NOTICE_5:
    '설정 된 운영자 접속가능 IP가 관리자 접속가능 IP 대역에 포함된 경우에도 접근은 가능하지만, 개인정보보호법 제29조(안전조치 의무)의 망분리로 인정되기 위해서는 운영자 접속가능 IP가 관리자 접속가능 IP 대역에 포함되지 않아야 합니다.',
  IP_ACCESS_CONTROL_NOTICE_ADMIN_IP: '관리자 IP',
  IP_ACCESS_CONTROL_NOTICE_MANAGER_IP: '운영자 IP',
  IP_ACCESS_CONTROL_NOTICE_NETWORK_SEPARATION: '망분리 인정 여부',
  USE_NETWORK_SEPARATION: '망분리 적용',
  NOT_USE_NETWORK_SEPARATION: '망분리 미적용',
});
