import { GridProp } from '@/types/grid';
import { OptColumn } from '@/types';
import { InquiryType } from '@/types/questions';
import { selectRenderer } from '@/utils/grid/rendererUtils';
import QuestionsVue from '@/views/contents/board/basic/Questions.vue';
import { CellRendererProps } from '@/types/tui-grid/renderer';
import { TranslateResult } from 'vue-i18n';
import { CellValue } from '@/types/tui-grid/store/data';

//Because neet to add a custom class, I wrote this Renderer. not use common's defined.
export class RadioRendererWithClassName {
  el: HTMLInputElement;
  constructor(props: CellRendererProps) {
    const { grid, rowKey, className } = props;
    const radioBtn = document.createElement('input');
    radioBtn.type = 'radio';
    radioBtn.name = 'listRadio';

    const eventHandler = props.columnInfo.renderer.options?.eventHandler;
    radioBtn.addEventListener('change', (event): void => {
      grid.uncheckAll();
      grid.check(rowKey);
      if (eventHandler) eventHandler(event, rowKey);
    });

    this.el = radioBtn;
    this.render(!!className, radioBtn);
  }

  getElement(): HTMLInputElement {
    return this.el;
  }

  render(value, radioBtn: HTMLInputElement): void {
    if (typeof value === 'boolean') {
      radioBtn.checked = value;
    }
  }
}
//I18n sugar for Quesions
export function i18n(i18nTxt: string, list: string[] = []): TranslateResult {
  return this.$t('BOARD.BASIC.QUESTIONS.' + i18nTxt, list);
}

const getProductDetailUrl = ({ value }): string => {
  const url = `<a href="javascript: void(0);" data-mall-product-no="${value}">${value}</a>`;
  return url;
};

export const getRepliedGridColumn = (minWidth = 75): OptColumn => {
  return {
    header: 'BOARD.BASIC.QUESTIONS.RESPONSE_STATUS',
    name: 'replied',
    align: 'center',
    minWidth,
    formatter: ({ value }): string => {
      return value
        ? window.$t('BOARD.BASIC.QUESTIONS.ANSWER_COMPLETED')
        : window.$t('BOARD.BASIC.QUESTIONS.WAITING_FOR_REPLY');
    },
    copyOptions: {
      customValue: (value: CellValue): string => {
        return value
          ? window.$t('BOARD.BASIC.QUESTIONS.ANSWER_COMPLETED')
          : window.$t('BOARD.BASIC.QUESTIONS.WAITING_FOR_REPLY');
      },
    },
  };
};
export const getMallGridColumn = (minWidth = 100): OptColumn => {
  return {
    header: 'MALL',
    name: 'mallNo',
    align: 'center',
    minWidth,
    formatter: ({ row }): string => {
      if (typeof row.mall === 'object' && row.mall['mallName']) {
        return row.mall['mallName'];
      }
    },
    copyOptions: {
      useFormattedValue: true,
    },
  };
};
export const getMallProductNoGridColumn = (minWidth = 80): OptColumn => {
  return {
    header: 'BOARD.BASIC.QUESTIONS.PRODUCT_NUMBER',
    name: 'mallProductNo',
    align: 'center',
    minWidth,
    formatter: getProductDetailUrl,
    copyOptions: {
      useFormattedValue: false,
    },
  };
};
export const getProductNameGridColumn = (minWidth = 200): OptColumn => {
  return {
    header: 'BOARD.BASIC.QUESTIONS.PRODUCT_NAME',
    name: 'productName',
    align: 'left',
    escapeHTML: true,
    minWidth,
    resizable: true,
    copyOptions: {
      useFormattedValue: false,
    },
  };
};
export const getInquiryTypeGridColumn = (minWidth = 100): OptColumn => {
  return {
    header: 'BOARD.BASIC.QUESTIONS.INQUIRY_TYPE',
    name: 'inquiryType',
    align: 'center',
    minWidth,
    formatter: ({ value }): string => {
      const inquiryType: InquiryType = {
        PRODUCT: window.$t('BOARD.BASIC.QUESTIONS.PRODUCT'),
        DELIVERY: window.$t('BOARD.BASIC.QUESTIONS.DELIVERY'),
        CANCEL: window.$t('BOARD.BASIC.QUESTIONS.CANCEL'),
        RETURN: window.$t('BOARD.BASIC.QUESTIONS.RETURN'),
        EXCHANGE: window.$t('BOARD.BASIC.QUESTIONS.EXCHANGE'),
        REFUND: window.$t('BOARD.BASIC.QUESTIONS.REFUND'),
        OTHER: window.$t('BOARD.BASIC.QUESTIONS.OTHER'),
      };
      return inquiryType[value + ''];
    },
    copyOptions: {
      useFormattedValue: true,
    },
  };
};
export const getIsSecretGridColumn = (minWidth = 75): OptColumn => {
  return {
    header: 'BOARD.BASIC.QUESTIONS.SECRET',
    name: 'isSecret',
    align: 'center',
    minWidth,
    formatter: ({ value }): string => {
      return value ? window.$t('BOARD.BASIC.QUESTIONS.ISSECRET') : window.$t('BOARD.BASIC.QUESTIONS.NORMAL');
    },
    copyOptions: {
      useFormattedValue: true,
    },
  };
};

export function getGridProps(Que: QuestionsVue): GridProp {
  return {
    columns: [
      getRepliedGridColumn(),
      {
        header: 'BOARD.BASIC.QUESTIONS.DISPLAYTYPE',
        name: 'displayStatusType',
        align: 'center',
        minWidth: 100,
        renderer: props => {
          props.selectOptions = [
            { text: window.$t('BOARD.BASIC.QUESTIONS.DISPLAY'), value: 'DISPLAY' },
            { text: window.$t('BOARD.BASIC.QUESTIONS.BLIND'), value: 'BLIND' },
          ];
          props.width = '60%';
          props.callback = event =>
            Que['changeDisplayStatusByGrid'](props.rowKey, event.target.value, props.value, event);
          return selectRenderer(props);
        },
        copyOptions: {
          customValue: (value: CellValue): string => {
            return value === 'DISPLAY'
              ? window.$t('BOARD.BASIC.QUESTIONS.DISPLAY')
              : window.$t('BOARD.BASIC.QUESTIONS.BLIND');
          },
        },
      },
      getMallGridColumn(),
      getMallProductNoGridColumn(),
      getProductNameGridColumn(),
      getInquiryTypeGridColumn(),
      getIsSecretGridColumn(),
      {
        header: 'BOARD.BASIC.QUESTIONS.TITLE',
        name: 'title',
        align: 'left',
        minWidth: 280,
        resizable: true,
        formatter: ({ value, row }): string => {
          return `<div class="tui-grid-cell-content" style="white-space:nowrap;max-height:31px"><div class="break-all">${
            value && value.toString().trim().length > 0 ? value : '-'
          }</div><input type="hidden" class="title" value="${row.title}" /></div>`;
        },
        copyOptions: {
          useFormattedValue: false,
        },
      },
      {
        header: 'BOARD.BASIC.QUESTIONS.INQUIRIES',
        name: 'content',
        align: 'left',
        minWidth: 280,
        resizable: true,
        formatter: ({ value, row }): string => {
          return `<div class="tui-grid-cell-content" style="white-space:nowrap;max-height:31px"><div class="break-all">${value}</div><input type="hidden" class="inquiryNo" value="${row.inquiryNo}" /></div>`;
        },
        copyOptions: {
          useFormattedValue: false,
        },
      },
      {
        header: 'BOARD.BASIC.QUESTIONS.PURCHASE_HEADER',
        name: 'orderNo',
        align: 'center',
        minWidth: 75,
        formatter: ({ value }): string => {
          return value ? window.$t('BOARD.BASIC.QUESTIONS.PURCHASE') : window.$t('BOARD.BASIC.QUESTIONS.NOT_PURCHASE');
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'BOARD.BASIC.QUESTIONS.WRITER',
        name: 'member',
        align: 'center',
        minWidth: 150,
        formatter: ({ value, row: { registerNo } }): string => {
          if (!value) {
            return window.$t('BOARD.BASIC.QUESTIONS.WRONG_USER');
          }

          if (typeof value === 'object') {
            if (value['memberStatus'] === 'WITHDRAWN' || value['memberStatus'] === 'WITHDRAWN') {
              return window.$t('BOARD.BASIC.QUESTIONS.WITHDRAWAL_MEMBER');
            }
            /*if (value['memberId']) {
              const memberName = `${value['memberId']} (${registerNo})`;
              return `<a href="javascript:void(0);" data-register-no="${registerNo}">${memberName}</a>`;
            } else {
              return `<a href="javascript:void(0);" data-register-no="${registerNo}">${registerNo}</a>`;
            }*/

            //  비회원 => 작성자명(비회원)
            if (Number(value['memberNo']) === 0) {
              return value['memberName'] + '(' + window.$t('BOARD.BASIC.QUESTIONS.NON_MEMBER') + ')';
            }

            // 회원, 휴면회원 => id(회원번호)
            const memberName = `${value['memberId']} (${registerNo})`;
            return `<a href="javascript:void(0);" data-register-no="${registerNo}">${memberName}</a>`;
          }
        },
        copyOptions: {
          customValue: (value: CellValue): string => {
            if (!value) {
              return window.$t('BOARD.BASIC.QUESTIONS.WRONG_USER');
            }

            if (typeof value === 'object') {
              const memberId = value['memberId'];
              return memberId ? memberId : '';
            }

            return '';
          },
        },
      },
      {
        header: 'BOARD.BASIC.QUESTIONS.INQUIRY_DATE_AND_TIME',
        name: 'registerDateTime',
        align: 'center',
        minWidth: 140,
      },
      {
        header: 'BOARD.BASIC.QUESTIONS.RESPONSE_DATE_AND_TIME',
        name: 'repliedDateTime',
        align: 'center',
        minWidth: 140,
      },
    ],
    header: {
      height: 33,
    },
    options: {
      rowHeaders: [
        {
          name: 'radioBtn',
          type: 'radio',
          header: window.$t('BOARD.BASIC.QUESTIONS.SELECT'),
          renderer: RadioRendererWithClassName,
        },
      ],
      pageOptions: {
        page: 1,
        perPage: 10,
        totalCount: 0,
      },
    },
  };
}
