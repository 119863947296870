






































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import TextInput from '@/components/common/input/TextInput.vue';
import { throwPopup, PopupClose } from '@/helpers/popup';
import { GetServicesMe, NCPResponse } from 'ncp-api-supporter';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { TranslateResult } from 'vue-i18n';
import EmailInput from '@/components/common/input/EmailInput.vue';

@Component({
  components: { TextInput, ToolTip, EmailInput },
})
export default class BasicInfo extends Vue {
  // 최소한의 초기 data
  private servicesData: GetServicesMe = {
    representative: {},
    address: {},
    privacyManager: {},
    business: {},
  } as GetServicesMe;

  private registrationNo = '';

  created() {
    throwBottomNavigation({
      buttons: [
        {
          type: 'right',
          key: 'save',
          color: 'red',
          text: this.$t('SAVE'),
        },
      ],
      onClick: key => {
        if (key === 'save') {
          this.saveServiceInfo();
        }
      },
    });

    this.getServicesMe();
  }

  private getInputPlaceholder(fieldName: TranslateResult, withoutHyphen = false): string {
    const guideText = withoutHyphen ? this.$t('WITHOUT_HYPHEN_INPUT') : this.$t('INPUT');
    return `${fieldName} ${guideText}`;
  }

  private async getServicesMe() {
    try {
      const { data }: NCPResponse<GetServicesMe> = await this.$api.getServicesMe();
      this.servicesData = data;

      this.registrationNo = this.servicesData.business.registrationNo + '';
    } catch (err) {
      console.error(err);
    }
  }

  private async saveServiceInfo() {
    if (!this.validateServiceInfo()) return;

    await this.$api.putServicesByServiceNo({
      data: { ...this.servicesData },
      params: {
        serviceNo: this.servicesData.serviceNo,
      },
    });
    alert(this.$t('ALERT_SAVED'));
  }

  private onAddressSearchClicked() {
    console.log('onAddressSearchClicked');

    throwPopup({
      name: 'SearchAddress',
      data: { keyword: '' },
    }).then((result: any) => {
      if (result && result.state === PopupClose.CONFIRM) {
        const data = result.data;
        this.servicesData.address.zipCode = data.zipCode;
        this.servicesData.address.address = data.roadAddress + data.roadAddressExtra;
        this.servicesData.address.detailAddress = '';

        this.servicesData.address.jibun = data.jibunAddress;
        this.servicesData.address.jibunDetail = '';
      }
    });
  }
  private get companyRegNumberURL() {
    return 'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=' + this.registrationNo;
  }

  private validateServiceInfo() {
    const email = {
      id: '',
      domain: '',
    };
    if (this.servicesData.representative.email) {
      [email.id, email.domain] = this.servicesData.representative.email.split('@');
    }

    const arr = [
      { name: this.$t('SERVICE.INFO.COMPANY_NAME'), value: this.servicesData.companyName, refName: 'companyName' },
      {
        name: this.$t('SERVICE.INFO.REPRESENTATIVE_NAME'),
        value: this.servicesData.representative.name,
        refName: 'representativeName',
      },
      {
        name: this.$t('SERVICE.INFO.COMPANY_REG_NUMBER'),
        value: this.servicesData.business.registrationNo,
        refName: 'businessRegistrationNo',
      },
      {
        name: this.$t('SERVICE.INFO.REPRESENTATIVE_PHONE_NUMBER'),
        value: this.servicesData.representative.phoneNo,
        refName: 'representativePhoneNo',
      },
      {
        name: this.$t('SERVICE.INFO.MAIL_ORDER_REPORT_NUMBER'),
        value: this.servicesData.business.onlineMarketingDeclarationNo,
        refName: 'businessOnlineMarketingDeclarationNo',
      },
      {
        name: this.$t('SERVICE.INFO.REPRESENTATIVE_EMAIL'),
        value: email.id,
        refName: 'representativeEmail',
      },
      {
        name: this.$t('SERVICE.INFO.REPRESENTATIVE_EMAIL'),
        value: email.domain,
        refName: 'representativeEmail',
      },
      // { name: this.$t('SERVICE.INFO.FAX_NUMBER'), value: this.servicesData.faxNo, refName: 'faxNo' },
      { name: this.$t('SERVICE.INFO.SECTORS'), value: this.servicesData.business.type, refName: 'businessType' },
      {
        name: this.$t('SERVICE.INFO.BUSINESS'),
        value: this.servicesData.business.condition,
        refName: 'businessCondition',
      },
      { name: this.$t('SERVICE.INFO.ADDRESS'), value: this.servicesData.address.zipCode, refName: 'address' },
      {
        name: this.$t('SERVICE.INFO.OFFICER_NAME'),
        value: this.servicesData.privacyManager.name,
        refName: 'privacyManagerName',
      },
    ];

    if (this.servicesData.address.address && !this.servicesData.address.jibun) {
      arr.push({
        name: this.$t('SERVICE.INFO.DETAIL_ADDRESS'),
        value: this.servicesData.address.detailAddress,
        refName: 'detailAddress',
      });
    } else if (!this.servicesData.address.address && this.servicesData.address.jibun) {
      arr.push({
        name: this.$t('SERVICE.INFO.DETAIL_ADDRESS'),
        value: this.servicesData.address.jibunDetail,
        refName: 'jibunDetail',
      });
    } else if (this.servicesData.address.address && this.servicesData.address.jibun) {
      if (!this.servicesData.address.detailAddress && !this.servicesData.address.jibunDetail) {
        arr.push({
          name: this.$t('SERVICE.INFO.DETAIL_ADDRESS'),
          value: this.servicesData.address.detailAddress,
          refName: 'detailAddress',
        });
      }
    }

    const privacyManagerEmail = {
      id: '',
      domain: '',
    };
    if (this.servicesData.privacyManager.email) {
      [privacyManagerEmail.id, privacyManagerEmail.domain] = this.servicesData.privacyManager.email.split('@');
    }
    if (privacyManagerEmail.id && !privacyManagerEmail.domain) {
      arr.push({
        name: this.$t('SERVICE.INFO.OFFICER_EMAIL'),
        value: privacyManagerEmail.domain,
        refName: 'privacyManagerEmail',
      });
    }
    if (!privacyManagerEmail.id && privacyManagerEmail.domain) {
      arr.push({
        name: this.$t('SERVICE.INFO.OFFICER_EMAIL'),
        value: privacyManagerEmail.id,
        refName: 'privacyManagerEmail',
      });
    }

    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].value) {
        alert(`${arr[i].name}${this.$t('CHECK_INPUT_FILED')}`);
        (this.$refs[arr[i].refName] as TextInput | EmailInput).focus();
        return false;
      }
    }

    return true;
  }
}
