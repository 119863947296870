import { GridProp } from '@/types';
import { buttonRenderer } from '@/utils/grid/rendererUtils';

const getDesignPopupDisplayUrlManageGridProps = (onGridCellEvent): GridProp => {
  return {
    columns: [
      //수정
      {
        header: 'MODIFY',
        name: 'modifyBtnCell',
        align: 'center',
        minWidth: 60,
        renderer: props => {
          props.btnText = window.$t('MODIFY');
          const row = props.grid.getRow(props.rowKey);

          props['pageNo'] = row.pageNo;
          props['pageName'] = row.pageName;
          props['displayUrl'] = row.displayUrl;
          props['pcDesignPlatform'] = row.platformTypes.includes('PC');
          props['mobileDesignPlatform'] = row.platformTypes.includes('MOBILE_WEB');

          props.callback = event => {
            onGridCellEvent('modifyBtnCell', event, props);
          };
          return buttonRenderer(props);
        },
      },
      //플랫폼 구분
      {
        header: 'DISPLAY.DESIGN_POPUP.PLATFORM_TYPES',
        name: 'platformTypes',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          const platForms: string[] = cell.row.platformTypes as string[];
          if (platForms.indexOf('PC') !== -1 && platForms.indexOf('MOBILE_WEB') !== -1) {
            return 'PC<br/>' + window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
          } else if (platForms.indexOf('PC') !== -1) {
            return 'PC';
          } else if (platForms.indexOf('MOBILE_WEB') !== -1) {
            return window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
          }
        },
        copyOptions: {
          customValue: (value): string => {
            const platForms: string[] = value as string[];
            if (platForms.indexOf('PC') !== -1 && platForms.indexOf('MOBILE_WEB') !== -1) {
              return 'PC,' + window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
            } else if (platForms.indexOf('PC') !== -1) {
              return 'PC';
            } else if (platForms.indexOf('MOBILE_WEB') !== -1) {
              return window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
            }
          },
        },
      },
      //페이지명/URL
      {
        header: 'DISPLAY.DESIGN_POPUP.MANAGE_DISPLAYURLS_GRID_PAGENAME_URL',
        name: 'nameAndUrl',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          return cell.row.pageName + '<br/>' + cell.row.displayUrl;
        },
      },
      //등록일/수정일
      {
        header: 'REGISTER_UPDATE_DATE',
        name: 'registerAndUpdateDateTime',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          let showTime = '';
          if (cell.row.registerDateTime) {
            showTime = cell.row.registerDateTime.toLocaleString();
          }
          if (cell.row.updateDateTime) {
            showTime +=
              showTime !== ''
                ? '<br/>' + cell.row.updateDateTime.toLocaleString()
                : cell.row.updateDateTime.toLocaleString();
          }
          return showTime;
        },
        copyOptions: {
          customValue: (value): string => {
            return value.toLocaleString();
          },
        },
      },
    ],
    options: {
      keyColumnName: 'pageNo',
      rowHeaders: [
        'checkbox',
        {
          type: 'rowNum',
          width: 61,
        },
      ],
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
      bodyHeight: 100,
    },
    header: {
      height: 51,
    },
    displayOptions: {
      showsTopArea: true,
      showsRightArea: true,
      hasSettingButton: false,
      hasPageSizeSelector: true,
      hasExcelDownloadButton: false,
      pageSizeKey: 'perPage',
      pageSizeList: [
        {
          value: '30',
          name: 'PAGE_SIZE_30',
        },
        {
          value: '50',
          name: 'PAGE_SIZE_50',
        },
        {
          value: '100',
          name: 'PAGE_SIZE_100',
        },
        {
          value: '200',
          name: 'PAGE_SIZE_200',
        },
      ],
    },
  };
};

export default getDesignPopupDisplayUrlManageGridProps;
