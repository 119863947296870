import { DiscountType } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { DiscountPriceCalculatorInfo } from '@/types';
import {
  discountAmountValidator,
  salePriceValidator,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/CalculatorValidator';
import { SalesInfoCalculatorCreatorByRoundingStrategy } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculatorCreatorByRoundingStrategy';

export class DiscountPriceCalculator {
  private _salePrice: number;
  private discountAmount: number;
  private discountType: DiscountType;
  private optionPrice: number;
  private readonly roundingStrategy: SalesInfoCalculatorCreatorByRoundingStrategy;

  constructor({ salePrice, optionPrice, discountAmount, discountType }: DiscountPriceCalculatorInfo, roundingStrategy) {
    this._salePrice = salePrice;
    this.optionPrice = optionPrice;
    this.discountAmount = discountAmount;
    this.discountType = discountType;
    this.roundingStrategy = roundingStrategy;
  }

  get salePrice(): number {
    return this._salePrice;
  }

  set salePrice(value: number) {
    this._salePrice = value;
  }

  changeDiscountAmount(discount: number): void {
    try {
      const info = {
        discount,
        type: this.discountType,
        salePrice: this.salePrice,
        optionPrice: this.optionPrice,
        maxDiscountAmount: this.maxDiscountAmount,
        maxDiscountRate: this.maxDiscountRate,
      };
      discountAmountValidator(info);
      this.discountAmount = discount;
    } catch (e) {
      this.discountAmount = 0;
      throw e;
    }
  }

  changeDiscountType(discountType: DiscountType): void {
    this.discountType = discountType;
  }

  changeOptionPrice(price: number) {
    this.optionPrice = price;
  }

  changeSalePrice(price): void {
    try {
      salePriceValidator(price, this.optionPrice, this.discountAmount);
      this.salePrice = price;
    } catch (e) {
      this.salePrice = 0;
      throw e;
    }
  }

  get discountPriceResult(): number {
    return this.salePrice - this.roundingStrategy(this.discountAmountResult);
  }

  private get discountAmountResult(): number {
    let discountPrice = this.discountAmount;
    if (this.discountType === DiscountType.PERCENTAGE) {
      discountPrice = this.salePrice * (this.discountAmount / 100);
    }
    return discountPrice;
  }

  private get maxDiscountRate(): number {
    // optionPrice 는 마이너스 옵션가일 경우만 확인
    const maxRate = ((this.salePrice + this.optionPrice) / this.salePrice) * 100;
    return Math.floor(maxRate);
  }

  get maxDiscountAmount(): number {
    if (!this.salePrice) return;
    if (this.discountType === DiscountType.PERCENTAGE) {
      return this.maxDiscountRate;
    }
    // optionPrice 는 마이너스 옵션가일 경우만 확인
    return Math.max(0, this.salePrice + this.optionPrice);
  }
}
