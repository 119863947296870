


































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { AdditionalServiceData } from '@/types/addition';

import { PopupClose, PopupResult, throwPopup } from '@/helpers/popup';
import { Getter } from 'vuex-class';
import { DomainByMall, Mall } from 'ncp-api-supporter';
import { ApplyCode, RemoteCode } from '@/api/godo/type';
import { applyService, codeClassify, applyUrl } from '@/components/addition/GoTo';
import PostRequestButton from '@/components/addition/PostRequestButton.vue';
import { getCurrentMallNo } from '@/utils/mall';
import { ItemViewType } from '@/components/addition/ServiceSection.vue';
import store from '@/store';

@Component({
  components: { PostRequestButton },
})
export default class ServiceItem extends Vue {
  @Getter('mall/getMalls')
  private readonly malls!: Mall[];
  @Getter('mall/getMallDomains')
  private readonly mallDomains!: DomainByMall[];
  @Prop({ required: true })
  private readonly item: AdditionalServiceData;
  @Prop({ required: true })
  private readonly currentRemoteCode!: RemoteCode | null;
  @Prop({ default: null })
  private readonly viewType?: ItemViewType;
  @Prop({ required: true })
  private readonly isDetail!: boolean;
  @Ref()
  private readonly postRequestButton!: PostRequestButton;

  private applyUrl = applyUrl;
  private codeClassify = codeClassify;
  private postQuery: { [key: string]: string } | null = null;

  get justOneMall(): boolean {
    return this.malls.length <= 1;
  }

  get firstMallId(): string {
    return this.mallDomains[0].mallId;
  }

  private readonly isBasicPlanType = store?.getters['admin/getAdmin']?.plan === 'BASIC';

  /* PG 프로모션 진행중으로 언제든지 내용이 바뀔 수 있음 */
  private tags =
    this.item.applyCode === 'service_pg_inicis' && !this.isBasicPlanType
      ? ['#결제/간편결제', '#가입비무료', '#통합간편결제']
      : this.item.applyCode === 'service_pg_inicis' && this.isBasicPlanType
      ? ['#결제/간편결제', '#통합간편결제']
      : this.item.tags;

  private async apply(
    remoteCode: ApplyCode,
    options?: { postMethod?: boolean; needRedirection?: boolean },
  ): Promise<void> {
    // '신청하기' 버튼을 눌렀을 때 다른 페이지로 이동해야하는 경우
    if (options?.needRedirection) {
      applyService({ remoteCode, mallNo: getCurrentMallNo(this) });
      return;
    }

    if (this.justOneMall) {
      if (options?.postMethod) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.postQuery = { mall_id: this.firstMallId };
        return;
      }
      applyService({ remoteCode, mallNo: this.malls[0].mallNo, mallId: this.firstMallId });

      return;
    }

    throwPopup({
      name: 'MallDomainSelector',
    }).then((result: PopupResult) => {
      if (result && result.state === PopupClose.CONFIRM) {
        const { mallNo, mallId } = result.data;
        if (options?.postMethod) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.postQuery = { mall_id: mallId };
          return;
        }
        applyService({ remoteCode, mallNo, mallId });
      }
    });
  }

  private handleAppDetailPopup() {
    throwPopup({
      // name: 'AppDetail',
      name: 'AppAgree', // 앱 설치 동의하기 (테스트로 추가함)
    });
  }

  private handleAppInstallPopup() {
    throwPopup({
      name: 'AppInstall',
    });
  }

  private isCurrentRemoteCode(item: AdditionalServiceData): boolean {
    const isPgCurrentRemoteCode = item.applyCode === this.currentRemoteCode;
    const isOtherCurrentRemoteCode = item.remoteCode === this.currentRemoteCode;
    return item.remoteCode.includes('service_pg_info') ? isPgCurrentRemoteCode : isOtherCurrentRemoteCode;
  }
}
