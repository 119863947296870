//TODO: 프론트에서만 쓸 수 있는 key 고민중

export const claimTypes = {
  CANCEL: 'CANCEL',
  EXCHANGE: 'EXCHANGE',
  RETURN: 'RETURN',
  REFUND: 'REFUND',
} as const;

export const claimStatusTypes = {
  CANCEL_NO_REFUND: 'CANCEL_NO_REFUND', // 취소완료[환불없음]
  CANCEL_REQUEST: 'CANCEL_REQUEST', // 취소신청[승인대기]
  CANCEL_PROC_REQUEST_REFUND: 'CANCEL_PROC_REQUEST_REFUND', // 취소처리[환불보류]
  CANCEL_PROC_WAITING_REFUND: 'CANCEL_PROC_WAITING_REFUND', // 취소처리[환불대기]
  CANCEL_DONE: 'CANCEL_DONE', // 취소완료[환불완료]
  RETURN_REQUEST: 'RETURN_REQUEST', // 반품신청[승인대기]
  RETURN_REJECT_REQUEST: 'RETURN_REJECT_REQUEST', // 반품처리[철회대기]
  RETURN_PROC_BEFORE_RECEIVE: 'RETURN_PROC_BEFORE_RECEIVE', // 반품처리[수거진행]
  RETURN_PROC_REQUEST_REFUND: 'RETURN_PROC_REQUEST_REFUND', // 반품처리[환불보류]
  RETURN_PROC_WAITING_REFUND: 'RETURN_PROC_WAITING_REFUND', // 반품처리[환불대기]
  RETURN_REFUND_AMT_ADJUST_REQUESTED: 'RETURN_REFUND_AMT_ADJUST_REQUESTED', // 반품처리[조정요청]
  RETURN_NO_REFUND: 'RETURN_NO_REFUND', // 반품완료[환불없음]
  RETURN_DONE: 'RETURN_DONE', // 반품완료[환불완료]
  EXCHANGE_REQUEST: 'EXCHANGE_REQUEST', // 교환신청[승인대기]
  EXCHANGE_REJECT_REQUEST: 'EXCHANGE_REJECT_REQUEST', // 교환처리[철회대기]
  EXCHANGE_PROC_BEFORE_RECEIVE: 'EXCHANGE_PROC_BEFORE_RECEIVE', // 교환처리[수거진행]
  EXCHANGE_PROC_REQUEST_PAY: 'EXCHANGE_PROC_REQUEST_PAY', // 교환처리[결제대기]
  EXCHANGE_PROC_WAITING: 'EXCHANGE_PROC_WAITING', // 교환처리[처리대기]
  EXCHANGE_PROC_WAITING_PAY: 'EXCHANGE_PROC_WAITING_PAY', // 교환처리[입금처리대기]
  EXCHANGE_DONE_PAY_DONE: 'EXCHANGE_DONE_PAY_DONE', // 교환완료[결제완료]
  EXCHANGE_PROC_REQUEST_REFUND: 'EXCHANGE_PROC_REQUEST_REFUND', // 교환처리[환불보류]
  EXCHANGE_PROC_WAITING_REFUND: 'EXCHANGE_PROC_WAITING_REFUND', // 교환처리[환불대기]
  EXCHANGE_DONE_REFUND_DONE: 'EXCHANGE_DONE_REFUND_DONE', // 교환완료[환불완료]
  EXCHANGE_DONE: 'EXCHANGE_DONE', // 교환완료[차액없음]
  NONE: 'NONE',
} as const;

export const claimClassTypes = {
  ORDER_CANCEL: 'ORDER_CANCEL', // 주문취소 (전체취소)
  OPTION_CANCEL: 'OPTION_CANCEL', // 옵션취소 (부분취소)
  CANCEL_EXCHANGE: 'CANCEL_EXCHANGE', // 취소교환
  RETURN_EXCHANGE: 'RETURN_EXCHANGE', // 반품교환
} as const;

export const responsibleObjectTypes = {
  SELLER: 'SELLER',
  BUYER: 'BUYER',
} as const;

export const returnWayTypes = {
  SELLER_COLLECT: 'SELLER_COLLECT',
  BUYER_DIRECT_RETURN: 'BUYER_DIRECT_RETURN',
} as const;

export const claimReasonTypes = {
  CHANGE_MIND: 'CHANGE_MIND',
  DEFECTIVE_PRODUCT: 'DEFECTIVE_PRODUCT',
  WRONG_DELIVERY: 'WRONG_DELIVERY',
  OUT_OF_STOCK_SYSTEM: 'OUT_OF_STOCK_SYSTEM',
  CANCEL_BEFORE_PAY: 'CANCEL_BEFORE_PAY',
  WRONG_PRODUCT_DETAIL: 'WRONG_PRODUCT_DETAIL',
  DELAY_DELIVERY: 'DELAY_DELIVERY',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  OTHERS_SELLER: 'OTHERS_SELLER',
  OTHERS_BUYER: 'OTHERS_BUYER',
} as const;

export const searchDateTypes = {
  APPLY: 'APPLY_YMDT', // 신청일자
  COMPLETE: 'COMPLETE_YMDT', // 완료일자
} as const;

export const sortTypes = {
  CLAIM_NO: '',
  ORDERER: 'ORDERER_NAME',
  RECEIVER: 'RECEIVER_NAME',
  CLAIM_AMT: 'CLAIM_AMT',
} as const;
