



















































































































import { Component, Mixins } from 'vue-property-decorator';
import TableTabMenu, { TableTabMenuItem } from '@/components/shipping/order/TableTabMenu.vue';
import OrderInfo from '@/components/shipping/order/OrderInfo.vue';
import OrderDetails from '@/components/shipping/order/mixins/OrderDetail';
import OrderFirstInfo from '@/components/shipping/order/OrderFirstInfo.vue';
import OrderLastInfo from '@/components/shipping/order/OrderLastInfo.vue';
import OrderOrdererInfo from '@/components/shipping/order/OrderOrdererInfo.vue';
import OrderSettleInfo from '@/components/shipping/order/OrderSettleInfo.vue';
import PreviousOrderProductInfo from '@/components/shipping/order/PreviousOrderProductInfo.vue';
import PreviousOrderRefundInfo from '@/components/shipping/order/PreviousOrderRefundInfo.vue';
import PreviousOrderReceiveInfo from '@/components/shipping/order/PreviousOrderReceiveInfo.vue';
import {
  AdminMemo,
  PaymentMethod,
  PayType,
  PreviousOrderProduct,
  PreviousOrdersOrderNoResponse,
} from 'ncp-api-supporter';
import { Getter } from 'vuex-class';
import { Admin } from '@/types';
import { formatCurrency } from '@/utils/numberFormat';
import PreviousOrderAdminMemo from '@/components/shipping/order/PreviousOrderAdminMemo.vue';
import sanitizeHtml from '@/utils/sanitizeHtml';
import { replaceNextLineContent } from '@/utils/common';

@Component({
  components: {
    PreviousOrderAdminMemo,
    PreviousOrderReceiveInfo,
    PreviousOrderRefundInfo,
    PreviousOrderProductInfo,
    OrderSettleInfo,
    OrderOrdererInfo,
    OrderLastInfo,
    OrderFirstInfo,
    TableTabMenu,
    OrderInfo,
  },
})
export default class OrderPreviousDetail extends Mixins(OrderDetails) {
  @Getter('admin/getAdmin') private admin: Admin;
  private payType: PayType | '' = '';
  private menuList: TableTabMenuItem[] = [];
  private focusTabName = 'all';
  private memberInfoChange = false;
  private escrowPayType = false;
  private previousOrderProductInfos: PreviousOrderProduct[] = [];
  private orderDetail: PreviousOrdersOrderNoResponse = {
    accumulationAmt: 0,
    adminMemo: [],
    firstPayment: null,
    lastPayment: null,
    mallNo: 0,
    orderProduct: [],
    orderer: null,
    paymentMethod: null,
    receiver: [],
    refund: null,
  };
  private adminMemos: AdminMemo[] = [];

  private formatCurrency = formatCurrency;

  private getOrderInfo() {
    const request = { orderNo: this.$route.query.orderNo?.toString() };

    this.$api.getPreviousOrdersOrderNo({ pathParams: request }).then(({ data }) => {
      this.orderDetail = data;
      this.previousOrderProductInfos = data.orderProduct;
      this.payType = data.paymentMethod.payType;
      this.escrowPayType = data.paymentMethod.payType.indexOf('ESCROW') !== -1;
      this.orderInfo = this.getPreviousOrderInfo(data); // firstPayInfo, lastPayInfo
      this.memberInfo = this.getPreviousOrderMemberInfo(data); // memberInfo
      this.adminMemos = this.getPreviousAdminMemos(data.adminMemo);
      this.settleInfo = {
        ...this.settleInfo,
        ...this.getPreviousSettlementInfo(data.paymentMethod, data.firstPayment.mainPayAmt),
      };
      this.menuList.push({ name: 'all', title: `전체 내역 (${data.orderProduct.length}건)` });
    });
  }

  private getPreviousOrderInfo(order: PreviousOrdersOrderNoResponse) {
    return {
      firstPayInfo: {
        salePrice: formatCurrency(order.firstPayment.standardAmt),
        deliveryAmt: formatCurrency(order.firstPayment.deliveryAmt),
        totalDeliveryAmt: formatCurrency(order.firstPayment.deliveryAmt),
        couponAmt: formatCurrency(order.firstPayment.discountAmt),
        payAmt: formatCurrency(order.firstPayment.payAmt),
        subPayAmt: formatCurrency(order.firstPayment.subPayAmt),
        realPay: formatCurrency(order.firstPayment.payAmt - order.firstPayment.subPayAmt),
        registerYmdt: '',
      },
      lastPayInfo: {
        salePrice: formatCurrency(order.lastPayment?.standardAmt),
        deliveryAmt: formatCurrency(order.lastPayment?.deliveryAmt),
        totalDeliveryAmt: formatCurrency(order.lastPayment?.deliveryAmt),
        couponAmt: formatCurrency(order.lastPayment?.discountAmt),
        payAmt: formatCurrency(order.lastPayment?.payAmt),
        subPayAmt: formatCurrency(order.lastPayment?.subPayAmt),
        realPay: formatCurrency(order.lastPayment?.payAmt - order.lastPayment?.subPayAmt),
        editReturnPay: formatCurrency(this.editReturnPay),
      },
    };
  }

  private getPreviousOrderMemberInfo({ mallNo, orderer }: PreviousOrdersOrderNoResponse) {
    const { ordererEmail, ordererName, memberId, memberGradeNames, ordererMobileNumber } = orderer;
    const [emailId, emailDomain] = ordererEmail.split('@');

    const isGuest = orderer.memberNo === 0;
    const displayNameLabel = [ordererName, memberId, memberGradeNames.join(',')].filter(Boolean).join(' / ');
    const ordererNameLabel = isGuest ? ordererName : `${displayNameLabel}`;

    return {
      ...orderer,
      name: ordererNameLabel,
      contact1: ordererMobileNumber, // OrderDetail 공통에서 이미 contatc1 (휴대폰 번호)로 쓰이고 있이고 있음. 변경하기엔 영향범위 확인 리소스가 너무 큼
      email1: emailId, // OrderDetail 공통에서 이미 email1,2 로 사용하고 있음
      email2: emailDomain,
      memberType: isGuest ? 'guest' : 'member',
      mallNo,
      memberStatus: '',
      isPreviousOrder: true,
    };
  }

  private getPreviousAdminMemos(memos: AdminMemo[]): AdminMemo[] {
    return memos.map(adminMemo => ({ ...adminMemo, memo: sanitizeHtml(replaceNextLineContent(adminMemo.memo)) }));
  }

  private getPreviousSettlementInfo(data: PaymentMethod, mainPayAmt: number) {
    const detailInfo = this.getDetailInfoLabel(data, mainPayAmt);

    return {
      infoPayType: data.payTypeLabel,
      detailInfo,
      registerYmdt: data.orderYmdt,
      payYmdt: data.payYmdt,
    };
  }

  private getDetailInfoLabel({ payType, payTypeLabel, payDetail }: PaymentMethod, mainPayAmt: number) {
    const discountAmtLabel = payDetail.discountAmt ? formatCurrency(payDetail.discountAmt) : '';
    const payTypeAmountLabel = `${payTypeLabel}(${formatCurrency(mainPayAmt)})`;

    switch (payType) {
      case 'ACCOUNT':
      case 'VIRTUAL_ACCOUNT':
        return `${payTypeAmountLabel} - 은행명: ${payDetail.bank}, 계좌번호: ${payDetail.account}, 입금자명: ${payDetail.depositor}`;
      case 'NAVER_PAY':
        return `${payTypeAmountLabel} - 네이버페이 할인금액: ${discountAmtLabel}`;
      default:
        return payTypeAmountLabel;
    }
  }

  created() {
    this.getOrderInfo();
  }
}
