























import { Vue, Component } from 'vue-property-decorator';
import { refreshToken } from '@/utils/token';

@Component({
  components: {},
})
export default class NoPermission extends Vue {
  created() {
    refreshToken();
  }

  private moveRoot(): void {
    this.$router.push('/');
  }
}
