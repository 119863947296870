import { PartnersInfoByNo } from 'ncp-api-supporter';
import { GroupOption, ValueType } from '@/helpers/type';
import { ToolTipOption } from '@/types/tooltip';
import { i18n } from '@/main';

// 컴포넌트 initial state
export const partnerModificationInitialState: PartnersInfoByNo = {
  partnerNo: '',
  partnerName: '',
  companyName: '',
  partnerStatus: 'WAITING',
  faxNo: '',
  sampleUrl: '',
  permittedIpAddress: '',
  countryCode: '',
  sellerTaxationType: 'NORMAL',
  supportsInternationalShipping: false,
  business: {
    registrationNo: '',
    onlineMarketingDeclarationNo: '',
    condition: '',
    type: '',
  },
  office: {
    country: '',
    zipCode: '',
    address: '',
    detailAddress: '',
    jibun: '',
    jibunDetail: '',
    city: '',
    stateOrRegion: '',
    localAddress: '',
  },
  representative: {
    name: '',
    phoneNo: '',
    email: '',
  },
  manager: {
    name: '',
    department: '',
    jobDuty: '',
    jobPosition: '',
    phoneNo: '',
    email: '',
  },
  privacyManager: {
    name: '',
    phoneNo: '',
  },
  settlementManager: {
    name: '',
    phoneNo: '',
    email: '',
  },
  tradeBank: {
    bank: '',
    bankName: '',
    account: '',
    depositorName: '',
  },
  internationalCode: {
    swiftCode: '',
    abaRoutingNo: '',
    ibanCode: '',
    bsbCode: '',
  },
  masterAdmin: {
    departmentName: '',
    phone: '',
    mobile: '',
    name: '',
    jobDutyName: '',
    id: '',
    jobPositionName: '',
    email: '',
    externalAccessEnabled: false,
    permittedIpAddresses: null,
  },
  csManager: {
    name: '',
    phoneNo: '',
    email: '',
    operationInfo: '',
  }
};

// 대표운영자 외부접속 가능/불가 버튼옵션
export const getExternalAccessOptions = (): GroupOption<ValueType> => {
  return {
    name: 'externalAccessOptions',
    data: [
      {
        id: 'externalAccessY',
        display: i18n.t('PARTNER.EDIT.EXTERNAL_ACCESS_Y'),
        value: true,
      },
      {
        id: 'externalAccessN',
        display: i18n.t('PARTNER.EDIT.EXTERNAL_ACCESS_N'),
        value: false,
      },
    ],
  };
};

// 접속 IP 설정 툴팁
export const getIpToolTipInformation = (): ToolTipOption => {
  return {
    name: 'List',
    style: {
      width: '600px',
    },
    listOption: {
      mainTitle: `${i18n.t('PARTNER.EDIT.ADD_IP_GUIDE')}`,
      mainMessage: [
        `${i18n.t('PARTNER.EDIT.ADD_IP_GUIDE_P1')}`,
        `${i18n.t('PARTNER.EDIT.ADD_IP_GUIDE_P2')}`,
        `<strong>${i18n.t('PARTNER.EDIT.ADD_IP_GUIDE_P3')}</strong>`,
        `${i18n.t('PARTNER.EDIT.ADD_IP_GUIDE_P4')}`,
        `${i18n.t('PARTNER.EDIT.ADD_IP_GUIDE_P5')}&nbsp; <span style="color: tomato">${i18n.t(
          'PARTNER.EDIT.SAMPLE_IP',
        )}</span>`,
      ],
    },
  };
};

export const ipAccessControlRadioOption = {
  name: 'ipAccessControl',
  data: [
    {
      id: 'IP_ACCESS_CONTROL_NOT_USE',
      display: 'NOT_USED',
      value: false,
    },
    {
      id: 'IP_ACCESS_CONTROL_USE',
      display: 'USED',
      value: true,
    },
  ],
};
