








































import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import {
  MOVE_TYPE,
  INITIAL_DIGIT,
  mapOriginAndPreviousDisplayOrder,
  sortByDisplayOrder,
  setChangeHistory,
  getPreviousKeys,
} from '@/components/product/changeDisplayOrder/ChangeDisplayOrder';
import TextInput from '@/components/common/input/TextInput.vue';
import { ChangeDisplayOption, ChangeDisplayOrderMsgOption } from '@/types';
import GridComponent from '@/components/common/grid/Main.vue';
@Component({
  components: {
    TextInput,
  },
})
export default class ChangeDisplayOrder extends Vue {
  @PropSync('selected', { required: true })
  private selectedSync: number[];
  @PropSync('data', { required: true })
  private dataSync!: ChangeDisplayOption[];
  @Prop({ required: true })
  private readonly totalSize: number;
  @Prop({ required: false })
  private readonly message!: ChangeDisplayOrderMsgOption;
  @Prop({ required: false, default: true })
  private readonly maintainCheckedState: boolean;
  @Prop({ required: false, default: null })
  private readonly customizePreviousKeys: (prevKeys: number[]) => void;
  @Prop({ required: false, default: true })
  private readonly useCustomMove: boolean;
  @Prop({ required: false })
  private readonly customChangeOrder!: Function;
  @Prop({ default: false })
  private readonly hasFixedButton!: boolean;

  private readonly grid = this.$parent as GridComponent;

  private readonly MOVE_TYPE = MOVE_TYPE;
  private origins: number[] = [];
  private originSize: number = this.origins.length;
  private maxSize: number = this.totalSize - this.originSize;
  // typeof customKey is number : 0 으로 할당하는 경우 화면에 0 이 출력되어 '' 로 초기화
  private customKey = '';
  get fixedSize() {
    return this.dataSync.filter(data => data.fixed).length;
  }
  receivedData(fixedSize: number) {
    this.$nextTick(() => {
      this.initData(fixedSize);
    });
  }
  initData(fixedSize: number) {
    this.dataSync = this.dataSync.map((data, index) => {
      data.originDisplayOrder = data.displayOrder;
      data.fixed = fixedSize > index;
      return data;
    });
  }
  private checkOrigins(event: Event): void {
    if (!this.totalSize) {
      alert(this.$t(this.message.needOrigin));
      return;
    }
    const target = event.target as HTMLButtonElement;
    this.initializeAllStates();
    this.setOrigins();
    if (!this.originSize) {
      alert(this.$t(this.message.needOrigin));
      return;
    }
    this.checkTargets(target);
  }
  private fixedDisplayOrder: number[] = [];
  private initializeAllStates(): void {
    this.setMsg();
    if (this.customChangeOrder) {
      return;
    }
    this.dataSync = sortByDisplayOrder(this.dataSync);
    mapOriginAndPreviousDisplayOrder(this.dataSync);
  }
  private setMsg(): void {
    if (!this.message.needTarget) {
      this.message.needTarget = this.$t('PRODUCT.NEED_TARGET').toString();
    }
  }
  private setOrigins(): void {
    this.origins = this.selectedSync;
    this.originSize = this.origins.length;
    this.maxSize = this.totalSize - this.originSize;
  }

  private checkRows(rowKeys: number[]) {
    rowKeys.forEach(key => this.$nextTick(() => this.grid.checkRow(key)));
  }

  private checkTargets(target: HTMLButtonElement): void {
    const { moveType }: DOMStringMap = target.dataset;
    const needsTarget: boolean = Number(moveType) === MOVE_TYPE.CUSTOM && !this.customKey;
    if (needsTarget) {
      alert(this.$t(this.message.needTarget));
      this.initializeCustomKey();
      return;
    }
    this.executeChanges(Number(moveType));
    this.$emit('changed');

    setChangeHistory(this.totalSize, this.dataSync);
    if (!this.customChangeOrder) {
      this.setChangedDisplayOrder(this.dataSync);
      this.selectedSync = [];
    }

    if (this.maintainCheckedState || this.customizePreviousKeys !== null) {
      const previousKeys = getPreviousKeys(this.dataSync, this.origins);

      this.maintainCheckedState && this.checkRows(previousKeys);
      this.customizePreviousKeys !== null && this.customizePreviousKeys(previousKeys);
    }
  }
  private initializeCustomKey(): void {
    this.customKey = '';
  }
  private executeChanges(type: MOVE_TYPE): void {
    const MIN_SIZE: number = this.customChangeOrder
      ? INITIAL_DIGIT + this.fixedSize + 1
      : INITIAL_DIGIT + this.fixedSize;
    let target: number;
    switch (type) {
      case MOVE_TYPE.TOP:
        this.moveSideBySide(MIN_SIZE);
        break;
      case MOVE_TYPE.BOTTOM:
        this.maxSize = this.totalSize - this.originSize;
        this.moveSideBySide(this.maxSize);
        break;
      case MOVE_TYPE.UP:
        this.moveStepByStep(-1);
        break;
      case MOVE_TYPE.DOWN:
        this.origins = this.origins.reverse();
        this.maxSize = this.totalSize;
        this.moveStepByStep(1);
        break;
      case MOVE_TYPE.FIX:
        this.setFix();
        break;
      case MOVE_TYPE.CUSTOM:
        target = Number(this.customKey) - 1;
        if (target <= MIN_SIZE) target = MIN_SIZE;
        if (target > this.maxSize) target = this.maxSize;
        this.moveSideBySide(target);
        break;
    }
  }
  setFix() {
    this.origins.forEach(origin => {
      this.dataSync[origin].fixed = !this.dataSync[origin].fixed;
    });
    sortByDisplayOrder(this.dataSync);
    this.fixedDisplayOrder = [];
    this.dataSync.forEach((data, index) => {
      if (data.fixed) this.fixedDisplayOrder.push(index + 1);
    });

    this.$emit('setFixedDisplayOrder', this.fixedDisplayOrder);
  }
  private moveSideBySide(target: number): void {
    const stack: number[] = [];
    for (let i = 0; i < this.originSize; i++) {
      // 위치해야하는 목표 진열순서가 변동되기 때문에 target + i 로 설정
      const targetIndex: number = target + i;
      // 목표 진열순서를 기준으로 위/아래로 나눈다
      // 올리는 작업부터 하게 되면 진열순서가 꼬이기 때문에 먼저
      // '오리지널 진열순서 > 목표 진열순서' 를 위로 올린다
      // '오리지널 진열순서 <= 목표 진열순서' 는 스택에 쌓아두고 올리는 작업이 끝나면
      // 목표 진열순서와 가장 가까운 오리지널 진열순서 부터 이동한다
      if (this.origins[i] <= targetIndex) {
        stack.push(this.origins[i]);
        continue;
      }
      this.changeDisplayOrder(this.origins[i], targetIndex);
    }
    while (stack.length) {
      this.changeDisplayOrder(stack.pop(), stack.length + target);
    }
  }

  private moveStepByStep(diff: number): void {
    let unChangeableCount = 0;
    // 연속된 일련의 숫자가 이동이 불가 한 경우를 unChangeableCount 로 제어한다
    let overOriginSize = false; // totalSize보다 선택한 row가 작을 경우에 알럿을 띄우기 위한 장치

    // 예) 1,2,3,5 -> up 이동 시 unChangeableCount 로 1,2,3 의 이동제한
    for (let idx = 0; idx < this.originSize; idx++) {
      const _origin: number = this.origins[idx];
      const _target: number = _origin + diff;
      if (!this.isChangeable(_target - unChangeableCount)) {
        unChangeableCount++;
        if (_origin >= this.totalSize) {
          overOriginSize = true;
        }
        continue;
      }
      this.changeDisplayOrder(_origin, _target);
    }
    if (overOriginSize && this.customChangeOrder) {
      alert(this.$t(this.message.cannotMove));
    }
  }

  private isChangeable(target: number): boolean {
    if (this.totalSize === this.originSize) return false;
    // if (target < 0) return false;
    // return target < this.totalSize;
    return target < this.fixedSize ? false : target < this.totalSize;
  }
  private changeDisplayOrder(origin: number, target: number): void {
    if (this.customChangeOrder) {
      this.customChangeOrder(origin, target);
      return;
    }
    const originData = this.dataSync[origin];

    if (target < origin) {
      // up
      for (let i = origin; i > target; i--) {
        this.dataSync[i] = this.dataSync[i - 1];
      }
    } else {
      // down
      for (let i = origin; i < target; i++) {
        this.dataSync[i] = this.dataSync[i + 1];
      }
    }
    this.dataSync[target] = originData;
  }

  private setChangedDisplayOrder(changedDataSync: ChangeDisplayOption[]): void {
    this.dataSync = sortByDisplayOrder(changedDataSync);
    this.initializeCustomKey();
  }
}
