export default Object.freeze({
  //grids_header
  HEADER_PLATFORM_TYPE: '플랫폼구분',
  HEADER_MALLNO: '쇼핑몰구분',
  HEADER_ORDER_REGISTER_TYPE: '주문구분',
  HEADER_RESERVATION_YN: '예약주문',
  HEADER_MEMBER_GROUP_INFO_JSON: '회원그룹',
  HEADER_RECOMMENDER_ID: '추천인',
  HEADER_PAY_TYPE: '결제수단',
  HEADER_ORDER_NO: '주문번호',
  HEADER_ORDER_NAME: '주문자명',
  HEADER_ORDER_CONTACT1: '주문자 연락처',
  HEADER_HOLD_DELIVERY_YN: '배송보류',
  HEADER_MEMBER_NO: '회원번호',
  HEADER_SHIPPING_ARER_TYPE_LABEL: '배송구분',
  HEADER_TASK_MESSAGE_YN: '업무메시지',
  HEADER_ORDER_STATUS_TYPE: '주문상태',
  HEADER_CLAIM_STATUS_TYPE: '클레임상태',
  HEADER_PARTER_NO: '파트너사',
  HEADER_HS_CODE: 'HS CODE',
  HEADER_MALL_PRODUCT_NO: '상품번호',
  HEADER_PRODUCT_MANAGEMENT_CD: '상품관리코드',
  HEADER_PRODUCT_NAME: '상품명',
  HEADER_PRODUCT_NAME_EN: '영문상품명',
  HEADER_OPTION_MANAGEMENT_CD: '옵션관리코드',
  HEADER_DISPLAY_OPTION_NAME: '옵션명: 옵션값',
  HEADER_IMMEDIATE_DISCOUNT_PRICE: '즉시할인가',
  HEADER_ADDITIONAL_DISCOUNT_AMT: '추가할인',
  HEADER_BUY_PRICE: '추가할인적용가',
  HEADER_ORDER_CNT: '수량',
  HEADER_TOTAL_BUY_AMT: '상품합계',
  HEADER_RECEIVER_NAME: '수령자명',
  HEADER_RECEIVER_CONTACT1: '수령자연락처',
  HEADER_RECEIVER_ZIP_CD: '우편번호',
  HEADER_ADDRESS: '주소',
  HEADER_DELIVER_NO: '배송번호',
  HEADER_DELIVER_AMT_ADVANCE_YN: '배송비 결제방식',
  HEADER_DELIVER_CONDITION_TYPE: '배송비 유형',
  HEADER_CUSTOMS_ID_NUMBER: '개인통관고유부호',
  HEADER_DELIVER_TYPE: '배송방법',
  HEADER_DELIVER_COMPANY_TYPE: '택배사',
  HEADER_INVOICE_NO: '송장번호',
  HEADER_ORDER_YMDT: '주문일시',
  HEADER_PAY_YMDT: '결제일시',
  HEADER_ORDER_ACCEPT_YMDT: '상품준비중처리일시',
  HEADER_RELEASE_READY_YMDT: '배송준비중처리일시',
  HEADER_RELEASE_YMDT: '배송중처리일시',
  HEADER_DELIVER_COMPLETE_YMDT: '배송완료처리일시',
  HEADER_BUY_CONFIRM_YMDT: '구매확정일시',
  HEADER_PROC_BTN: '입금확인처리',
  HEADER_DELIVERY_AMT_INADVANCE_YN: '배송비 결제방식',
  HEADER_ADJUST_AMT: '기본배송비',
  HEADER_REMOTE_DELIVERY_AMT: '지역별배송비',
  HEADER_TOTAL_DELIVERY_AMT: '배송비합계',
  HEADER_TOTAL_MAIN_AMT: '실결제금액',
  HEADER_PRCO_EACH_BTN: '주문접수처리',
  HEADER_EXCHANGE: '교환여부',
  HEADER_RECEIVER_CONTACT1_TWO: '수령자 연락처',
  HEADER_ORDER_STATUS_TYPE_TWO: '주문상태',
  HEADER_LAST_PRODUCT_COUPON_DISCOUNT_AMT: '상품쿠폰할인',
  HEADER_PRODUCT_COUPON_DISCOUNT_AMT: '쿠폰적용가',
  HEADER_LAST_CART_COUPON_DISCOUNT_AMT: '주문쿠폰할인',
  HEADER_DELIVERY_MEMO: '배송메모',
  HEADER_ORDER_MEMO: '주문메모',
  HEADER_DELIVERY_COMPANY_TYPE: '택배사',
  HEADER_RETURN_INVOICE_NO: '반품 송장번호',
  HEADER_INVOICE_NO_TWO: '송장번호',
  HEADER_LAST_PAY_AMT: '주문합계(① + ②)',
  HEADER_LAST_SUB_PAY_AMT: '적립금사용',
  HEADER_RESERVATION_START_YMDT: '예약판매시작일',
  HEADER_ORDER_STATUS_TYPE_THREE: '구분',
  HEADER_FAIL_INFO: '실패사유',
  HEADER_PROC_EACH_BTN: '출고준비처리',
  HEADER_PROC_BTN_1: '출고처리',
  HEADER_TOTAL_LAST_PRODUCT_COUPON_DISCOUNT_AMT: '상품쿠폰할인',
  HEADER_ORDER_AMT: '주문금액',
  HEADER_DELIVERYING_PROC_BTN: '배송완료처리',
  HEADER_DRINK_BUY: '주류구매',
  HEADER_MEMBER_GROUP: '회원등급',
  HEADER_MEMBER_ID: '아이디',
  HEADER_SUPPLY_PRICE: '공급가',
  HEADER_INFORMATION_CONSENT: '정보동의',

  HEADER_PROC_BTN_TWO: '출고처리',
  HEADER_PROC_BTN_THREE: '배송완료처리',
  HEADER_CLAIM_YMDT: '취소신청일시',
  HEADER_CLAIM_COMPLETE_YMDT: '취소완료일시',
  HEADER_CLAIM_YMDT_TWO: '반품신청일시',
  HEADER_CLAIM_COMPLETE_YMDT_TWO: '반품완료일시',
  HEADER_CLAIM_YMDT_THREE: '교환신청일시',
  HEADER_CLAIM_COMPLETE_YMDT_THREE: '교환완료일시',
  HEADER_MALL_NAME: '쇼핑몰구분',
  HEADER_ORDER_PRODUCT_OPTION_NO: '품목별 주문번호',
  HEADER_CLAIM_NO: '클레임번호',
  HEADER_CLAIM_CNT: '취소수량',
  HEADER_CLAIM_AMT: '취소금액',
  HEADER_CLAIM_CNT_THREE: '반품수량',
  HEADER_CLAIM_AMT_THREE: '반품금액',
  HEADER_CLAIM_CNT_FOUR: '교환수량',
  HEADER_CLAIM_AMT_FOUR: '교환금액',
  HEADER_CLAIM_YMDT_FOUR: '환불신청일시',
  HEADER_UPDATE_TMDT: '환불완료일시',
  HEADER_REFUND_TMDT: '환불금액',
  HEADER_REFUND_PAY_TYPE: '환불수단',
  HEADER_CLAIM_CNT_TWO: '상품수',
  HEADER_REFURN_WAY_TYPE: '반품수거방법',
  HEADER_HOLD_DELIVERY_YMDT: '배송보류 처리 일시',
  HEADER_DELIVERY_EXTERNAL_KEY: 'WMS 연동번호',
  HEADER_ORDER_OPTION_NO: '주문상품옵션번호',

  ADD: '추가',
  DELIVERY_NUIT: '묶음',
  SHARE: '나눔',
  DELIVERY_AMT_TYPE_ARRIVE: '착불',
  DELIVERY_AMT_TYPE_BEFORE: '선결제',
  PRODUCT_NAME_TAG: '세트',
  EXCHANGE_FORWARDING: '교환출고',
  PAY_DONE_PUT_BTN: '주문접수 처리',
  DESPOSIT_WAIT_PUT_BTN: '입금확인 처리',
  DELIVERY_PREPARE_BTN: '출고준비 처리',
  PAY_DONE_BTN: '결제완료 복원',
  HOLD_DELIVERY_BTN: '배송보류 처리',
  DELIVERY_ING_BTN: '출고 처리',
  PRODUCT_PREPARE_BTN: '상품준비중 복원',
  ASSIGN_INVOICE_BTN: '송장번호 저장',
  NAVER_SEND_DELAY_BTN: '네이버페이 발송지연 처리',
  DELIVERY_TAB: '배송번호 기준 리스트',
  ORDER_TAB: '주문번호 기준 리스트',
  CHECK_ROW_ALERT: '주문상태를 선택해 주세요.',
  CHECK_COMBINE_DELIVERY_STATE_ALERT:
    '선택된 주문에 일반 배송 주문이 포함되어 있습니다.\n 묶음배송 건만 선택 후 진행해 주세요.',
  NOT_CHECK_ROW_ALERT: '주문상품을 선택해 주세요.',
  CHECK_NAVER_PAY_ORDER: '네이버페이 주문형 주문만 처리 가능합니다.',
  NO_ORDERNO_ALERT: '출력 가능한 주문 상품이 없습니다.',
  CONFIRM_HANDLE_CHANGE: '선택한 주문의 상태를 변경하시겠습니까?',
  CONFIRM_HANDLE_CANCEL_CHANGE: '선택한 주문을 취소처리 하시겠습니까?',
  CONFIRM_HANDLE_CHANGE_DEPOSIT:
    '선택한 주문의 상태를 변경하시겠습니까?\n(입금대기 처리는 주문번호 단위로 변경됩니다.)',
  CONFIRM_HANDLE_CHANGE_PAY:
    '선택한 주문을 결제완료 상태 변경하시겠습니까?\n(입금확인 처리는 주문번호 단위로 변경됩니다.)',
  CANCEL_COMBINE_CONFIRM: '묶음배송 해제하시겠습니까?',
  CONCEL_COMBINE_SUCCESS_ALERT: '묶음배송 해제 처리가 완료되었습니다.',
  HANDLE_DONE: '처리되었습니다.',
  CANCEL_FAIL:
    '취소처리에 실패한 주문이 있습니다. 리스트에서 취소가 불가한 경우 주문 상세페이지에서 다시 시도해주세요.\n · 주문번호: ',
  EXCEL_NO_DATA_ALERT: '검색된 주문 상품이 없습니다.',
  DOWNLOAD_ALL_EXCEL_CONFIRM: '조회 결과를 전체 다운로드 하시겠습니까? 전체 다운 시 시간이 소요될 수 있습니다.',
  EXCEL_DOWNLOAD_EXCEL_NAME: '송장_{time}_{count}차_CJ출고양식',
  NEED_ORDER_PRODUCT_SELECT: '선택된 주문 상품이 없습니다.',
  DELETE_SUCCESS: '삭제되었습니다',
  ORDER_DELETE_TITLE: '주문삭제',
  RELEASE_N: '처리안함',
  RELEASE_Y: '배송중 처리',
  ORDER_NAME_POPUP_TITLE: '회원정보',
  MEMBER_GROUP_LAYER_TITLE: '회원그룹 상세',
  LIST_TABLE_TITLE: '조회결과 주문 건수',
  //deposit-wait
  PROC_BTN: '입금확인',
  CHANGE_STATUS_CONFIRM: '상태를 변경하시겠습니까?',
  PROC_EACH_BTN: '주문접수',
  TARGET_DELIVERY_PREPARE_MESSAGE: '업무메시지가 포함된 주문이 있습니다.\n출고준비 처리 하시겠습니까?',
  TARGET_PAY_DONE_MESSAGE: '업무메시지가 포함된 주문이 있습니다.\n결제완료로 복원하시겠습니까?',
  TARGET_DELIVERY_ING_MESSAGE: '업무메시지가 포함된 주문이 있습니다.\n출고 처리 하시겠습니까?',
  TARGET_PRODUCT_PREPARE_MESSAGE: '업무메시지가 포함된 주문이 있습니다.\n상품준비중으로 복원하시겠습니까?',
  PAY_CONFIRM_HANDLE_DONE: '처리 되었습니다.',
  PEPOSIT_WAIT_TABLE_TITEL: '조회결과 주문 건수',
  //pay-done
  PAY_DONE_LAYER_TITLE: '교환여부',
  PAY_DONE_LAYER_TEXT1: '교환 신청을 통해 신규 배송되는 상품을 확인할 수 있습니다.',
  PAY_DONE_LAYER_TEXT2: "교환 신청된 상품은 'Y'로 표기됩니다.",
  PAY_DONE_TABLE_TITEL: '조회결과 주문 건수',
  ORDER_CANCEL_POPUP_TITLE: '세트상품 품절취소 처리',
  PAY_DONE_SET_CANCEL_CONFIRM:
    '세트상품이 포함된 주문이 있습니다.\n세트로 구성된 옵션이 전체 품절취소 처리 됩니다.\n세트상품의 옵션 중 일부만 품절처리 할 경우에는\n세트상품 품절취소 처리를 이용해 주세요.\n전체 품절처리 하시겠습니까?',
  PAY_DONE_CONFIRM_SET_CANCEL: '선택한 상품을 품절취소 처리하시겠습니까?',
  PAY_DONE_EXCEL_CONFIRM: '엑셀 다운로드시 주문접수 처리되어 상품준비중 상태로 변경됩니다.\n계속하시겠습니까?',
  // product-prepare
  PROC_EACH_BTN_VALUE: '출고준비',
  MESSAGE_CONFIRM_1: '업무메시지가 포함된 주문이 있습니다.\n출고준비 처리 하시겠습니까?',
  MESSAGE_CONFIRM_2: '업무메시지가 포함된 주문이 있습니다.\n결제완료로 복원하시겠습니까?',
  MESSAGE_CONFIRM_3: '업무메시지가 포함된 주문이 있습니다.\n출고 처리 하시겠습니까?',
  MESSAGE_CONFIRM_4: '업무메시지가 포함된 주문이 있습니다.\n상품준비중으로 복원하시겠습니까?',
  INVOICE_NO_EMPTY_ALERT: '택배사 / 송장번호를 모두 입력해주세요.',
  PRODUCT_PREPARE_CHANGE_STATUS_CONFIR_1:
    '업무메시지가 포함된 주문이 있습니다.\n배송보류 처리하시면 해제할 때까지 출고 처리가 불가합니다.\n계속 진행하시겠습니까?\n배송보류 처리를 원하지 않을 경우 취소 후 다시 시도해주세요.',
  PRODUCT_PREPARE_CHANGE_STATUS_CONFIRM_2:
    '선택한 항목을 배송보류 처리 하시겠습니까?\n배송보류 처리하시면 해제할 때까지 출고 처리가 불가합니다.',
  PROC_BTN_TEXT: '출고',
  ORDER_CONTACT1_HEADER_NO_SPACE: '주문자연락처',
  ORDER_DISPLAY_OPTION_NAME_NO_SPACE: '옵션명:옵션값',
  DELIVERY_TAG: '출고',
  OUT_STORE_TAG: '출고준비',
  //delivery-prepare
  HEADER_DELIVERY_PREPARE_PROC_BTN: '배송중처리',
  // buy-confirm
  BUY_CONFIRM_TABLE_TITEL: '조회결과 배송 건수',
  // delivery-done
  DELIVERY_DONE_TABLE_TITEL: '조회결과 배송 건수',
  // deliverying
  DELIVERY_NONE_BTN: '배송완료 처리',
  DELIVERY_PREPARE_RESTORE_BTN: '배송준비중 복원',
  DELIVERY_GRIDS_TITEL: '조회결과 배송 건수',
  DELIVERYING_PROC_BTN_TXT: '배송완료',

  HEADER_OTHER_STATUS_TYPE: '구분',
  HEADER_OTHER_STATUS_TYPE_LABEL: '결제실패',
  HEADER_OTHER_STATUS_TYPE_LABEL_ELSE: '결제포기',
  HEADER_HOLD_DELIVERY_YMT: '배송보류 처리 일시',
  HEADER_RESERVATION_START_YMT: '예약판매시작일',

  DIVIDE_DELIVERY_CONFIRM:
    '동일 배송번호 상품 중 나눔배송 처리되는 상품이 있습니다.\n계속 진행하시겠습니까?\n(나눔배송을 원하지 않을 경우 관련 배송번호의 상품을 배송준비중 상태로 변경 후 함께 출고처리 하세요)',
  CHECKLIST_FALSE:
    '업무메시지가 포함된 주문이 있습니다.\n배송보류 해제 하시겠습니까?\n배송보류 하시면 출고 처리가 가능합니다.\n',
  CHECKLIST_TRUE:
    '선택한 항목을 배송보류 해제 하시겠습니까?\n배송보류 해제하시면 출고 처리가 가능합니다.\n계속 진행하시겠습니까?',
  CHECKLIST_RESERVATION_TRUE:
    '배송시작일 이전 주문이 있습니다.\n주문메뉴로 이동 시, 주문처리가 가능해집니다.\n정말 이동하시겠습니까?\n',
  HOLD_RESERVATION_CONFIRM: '예약배송시작일 이전 주문을 주문메뉴로 이동 하시겠습니까?',

  //invoice
  RESULT_SHIPPING_NO: '배송번호',
  RESULT_RECEIVER_NAME: '수령자명',
  RESULT_RECEIVER_CONTACT1: '수령자휴대전화',
  RESULT_RECEIVER_ZIP_CD: '우편번호',
  RESULT_ADDRESS: '주소',
  RESULT_CUSTOMS_ID_NUMBER: '개인통관고유부호',
  RESULT_DEVELIVERY_TYPE_LABEL: '배송방법',
  RESULT_INVOICE_NO: '송장번호',
  RESULT_ORDER_YMDT: '주문일시',
  RESULT_ORDER_NO: '주문번호',
  RESULT_ORDER_NAME: '주문자명',
  RESULT_PRODUCT_NAME: '상품명',
  RESULT_SUCCESS_YN: '실패여부',
  RESULT_FAIL_REASON: '실패사유',
  RESULT_SUCCESS_Y: '성공',
  RESULT_SUCCESS_N: '실패',
  HAS_TASK_MESSAGE_CONFIRM: '업무메시지가 포함된 주문이 있습니다. \n확인 후 송장번호를 입력해 주세요.',
  NO_EXCEL_DOWN_ALERT: '출력 가능한 주문 상품이 없습니다.',
});
