import Vue from 'vue';
import pattern from './pattern';

Vue.directive('pattern', pattern);
Vue.directive('not-double-click', {
  bind(el: HTMLElement): void {
    el.addEventListener('click', (): void => {
      (el as HTMLInputElement).disabled = true;
      setTimeout((): void => {
        (el as HTMLInputElement).disabled = false;
      }, 1000);
    });
  },
});
Vue.directive('positive-number', {
  bind(el: HTMLElement): void {
    el.addEventListener('input', (e: Event): void => {
      let value = (e.target as HTMLInputElement).value;
      if (value.indexOf(',') > -1) {
        value = value.replace(',', '');
      }
      if (Number.isNaN(Number(value))) {
        value = '0';
      }
      (e.target as HTMLInputElement).value = value.replace('-', '');
    });
  },
});
