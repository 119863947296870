export default Object.freeze({
  TITLE: '기획전 관리',
  REGISTER_DATE: '등록일',
  DISPLAY_PERIOD: '전시기간',
  EVENT_NAME: '기획전명',
  EVENT_NO: '기획전 번호',
  EVENT_TYPE: '기획전 유형',
  BTN_REGISTER: '기획전 등록',
  ADMIN: '담당자',
  ING_STATUS: '진행상태',
  READY: '진행대기',
  ING: '진행중',
  END: '진행종료',
  GENERAL: '일반',
  EXTERNAL: '외부링크',
  VIEWRESULTS: '조회결과 총',
  MODIFY: '수정',
  COPY: '복사',
  NUMBER: '기획전번호',
  PERIOD: '기간',
  TYPE: '유형',
  COUPON: '할인쿠폰 적용',
  PRODUCT: '적용대상 상품',
  BTN_DOWN: '엑셀 다운로드',
  URL: '기획전 URL',
  PRODUCT_NO: '상품번호',
});
