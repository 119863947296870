









































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Mall, NCPResponse } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import _ from 'underscore';
import gridProps, { i18nMallList } from '@/const/contents/configuration/mallList';
import { GridEventProps } from '@/types/grid';
import { namespace } from 'vuex-class';
import { DomainByMall } from 'ncp-api-supporter/dist/types/modules/admin/mallDomain';
import { Admin } from '@/types';
import {
  PopupClose,
  throwExternalWindowPopup,
  throwMessagePopupWithProps,
  openCreditChangePopup,
} from '@/helpers/popup';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { refreshToken } from '@/utils/token';
const mallStore = namespace('mall');
const adminStore = namespace('admin');

@Component({
  components: {
    Grid,
    NoticeBox,
  },
})
export default class MallList extends Vue {
  @mallStore.Getter('getUsedMalls')
  private mallList: Mall[];
  private gridProps = gridProps(this.openCommercePaymentPopup);
  @mallStore.Action('fetchMalls')
  private readonly fetchMalls: Function;
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;
  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  private readonly noticeList = [i18nMallList('NOTICE_AFTER_MALL_REGISTER_INFO')];

  created() {
    this.getMalls();
  }

  @Watch('mallList')
  private get usedMalls() {
    return this.mallList;
  }

  private async getMalls() {
    await this.fetchMalls();
  }

  private get mallListLength(): number {
    return this.mallList.length;
  }

  private get minMallNo(): number {
    return Math.min(...this.mallList.map(({ mallNo }) => mallNo));
  }

  private onAddingMallButtonClicked() {
    if (this.admin.plan === 'BASIC') {
      const result = confirm(window.$t('MALL.LIST.ALERT_BASIC_NOT_REGISTERED'));
      result && this.openCreditChangePopup();

      return;
    }

    if (!this.commerceAccount) {
      throwMessagePopupWithProps({
        headerText: i18nMallList('MESSAGE_PAYMENT_INFO_TITLE'),
        message: i18nMallList('MESSAGE_PAYMENT_CONTENT'),
        isConfirm: true,
        showOkButtonOnly: true,
        okButtonText: i18nMallList('BUTTON_GO_COMMERCE'),
      }).then(result => {
        if (result.state === PopupClose.CONFIRM) {
          window.open(process.env.VUE_APP_GODO_API_URL);
        }
      });
      return;
    }

    this.openCommercePaymentPopup('shopbyAdd', this.admin.mallNos[0].toString());
  }

  private async receiveMessage(e: MessageEvent, type) {
    if (e.data === 'commercePayComplete') {
      type === 'shopbyAdd' && (await refreshToken());
      this.getMalls();
    }
  }

  private async openCommercePaymentPopup(type, mallNo) {
    const request = {
      pathParams: { mallNo },
    };
    try {
      //NOTE : https://nhnent.dooray.com/project/pages/3503608109683620414
      const response = await this.$api.getMallsMallNoGodoAuth(request);
      throwExternalWindowPopup(`${process.env.VUE_APP_GODO_API_URL}/order/regular-payment-popup.gd`, 'md', {
        token: response.data.token,
        shopNo: response.data.sno,
        type,
      });
      window.addEventListener('message', e => this.receiveMessage(e, type));
    } catch (err) {
      console.error(err);
    }
  }

  private async onItemClicked(gridProps: GridEventProps) {
    const mall = _.findWhere(this.mallList, { mallNo: Number(gridProps.rowKey) });
    switch (gridProps.columnName) {
      case 'mallNo':
        if (!gridProps.rowKey) return;

        // eslint-disable-next-line no-case-declarations
        const adminMallStatus = this.mallList.find(({ mallNo }) => mallNo === gridProps.rowKey).mallStatus;
        if (
          adminMallStatus === 'DELETE_RESERVED' ||
          adminMallStatus === 'DELETED' ||
          adminMallStatus === 'ADMIN_BLOCKED'
        ) {
          this.$router.replace({ name: 'NoPermission' });
          return;
        }
        this.$router.push({ path: `/configuration/basic/mall/${gridProps.rowKey}` });
        break;
      case 'pc':
        if (mall) {
          const domain = await this.getMainDomain(Number(gridProps.rowKey), 'PC');
          window.open(domain, '_blank');
        }
        break;
      case 'mobile':
        if (mall) {
          const domain = await this.getMainDomain(Number(gridProps.rowKey), 'MOBILE');
          window.open(domain, '_blank');
        }
        break;
    }
  }

  private async getMainDomain(mallNo: number, type: 'PC' | 'MOBILE'): Promise<string> {
    try {
      const { data }: NCPResponse<DomainByMall[]> = await this.$api.getDomainByMalls();
      const index = data.findIndex(domainInfo => domainInfo.mallNo === mallNo);
      const domain = data[index].domains;
      return `http://${domain[type].mainDomain}`;
    } catch (e) {
      console.error(e);
    }
  }

  private openCreditChangePopup() {
    openCreditChangePopup(this.commerceAccount, this.admin.mallNos[0].toString());
  }
}
