




import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class SkinListIframe extends Vue {
  private get remoteSrc() {
    const queryObject = { ...this.$route.query, serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + this.$route.path.replace('_dev', '') + '?' + queryString;
  }
}
