






































import { Vue, Component } from 'vue-property-decorator';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import HeaderNav from '@/components/navigation/Header.vue';
import PopupList from '@/components/popup/Container.vue';
import BottomNav from '@/components/BottomNav.vue';
import $el from '@/utils/element';
import { AdminsService, GetAdminsTempService } from 'ncp-api-supporter';
import AdminRegister from '@/views/contents/configuration/management/AdminRegister.vue';
import Alert from '@/components/common/Alert.vue';
import AdminRegisterSuccess from '@/views/contents/configuration/management/AdminRegisterSuccess.vue';
import { AdminStatus } from 'ncp-api-supporter/dist/types/enum';

// 운영자 계정등록시 adminsService 데이터들과 GetAdminsTempService
// response 데이터들을 합쳐서 사용
export interface AdminInfoCustom extends AdminsService {
  contactInfo?: {
    mobileNo: string;
    phoneNo: string;
    email: string;
  };
  adminStatus?: AdminStatus;
}

@Component({
  components: {
    BottomNav,
    LoadingSpinner,
    HeaderNav,
    PopupList,
    AdminRegister,
    AdminRegisterSuccess,
    Alert,
  },
})
export default class AdminRegisterLayout extends Vue {
  private tempCode = '';
  private adminInfo: AdminInfoCustom = {
    adminName: '',
    authorityGroup: {
      no: 0,
      name: '',
    },
    jobDuty: {
      no: 0,
      name: '',
    },
    contact: {
      mobileNo: '',
      phoneNo: '',
      email: '',
    },
    adminId: '',
    adminRole: null,
    otp: {
      used: false,
    },
    department: {
      no: 0,
      name: '',
    },
    jobPositionName: '',
    externalAccessEnabled: false,
    commerceAccount: false,
    permittedIpAddresses: [],
  };
  private adminStatus = '';
  private isRegistered = false;

  private deleteAlertOption = {
    mainTitle: this.$t('CONFIGURATION.ADMIN.REGISTER_DELETE'),
    subTitle: [this.$t('CONFIGURATION.ADMIN.REGISTER_DELETE_SUB')],
    message: [this.$t('CONFIGURATION.ADMIN.REGISTER_RETRY_MESSAGE'), this.$t('THANK_YOU')],
  };

  private errorAlertOption = {
    mainTitle: this.$t('CONFIGURATION.ADMIN.REGISTER_PERIOD_EXPIRED'),
    subTitle: [this.$t('CONFIGURATION.ADMIN.REGISTER_PERIOD_EXPIRED_SUB')],
    message: [this.$t('CONFIGURATION.ADMIN.REGISTER_RETRY_MESSAGE'), this.$t('THANK_YOU')],
  };

  private isWaiting(): boolean {
    return this.adminStatus === 'WAITING';
  }
  private isActive(): boolean {
    return this.adminStatus === 'ACTIVE';
  }
  private isDelete(): boolean {
    return this.adminStatus === 'DELETE';
  }
  private isError(): boolean {
    return this.adminStatus === 'ERROR';
  }

  created() {
    $el(document.body).removeClass('min');

    this.tempCode = this.$route.query.tempCode as string;
    this.init();
  }

  private async init() {
    const data = await this.getAdminsTempService();
    if (data) this.setAdminInfo(data);
    if (!this.isRegistered) this.checkAlreadyAdmin();
  }

  private setAdminInfo(data: GetAdminsTempService): void {
    this.adminInfo = { ...this.adminInfo, ...data };
    this.adminInfo.contact = data.contactInfo;
    this.adminStatus = data.adminStatus;
  }

  private checkAlreadyAdmin(): void {
    if (this.isActive() && this.adminInfo.adminId) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_ALEADY_ADMIN'));
      this.$router.push({ name: 'AuthMain' });
    }
  }

  private async getAdminsTempService(): Promise<GetAdminsTempService> {
    const request = {
      params: {
        tempCode: this.tempCode,
      },
    };

    try {
      const { status, data } = await this.$api.getAdminsTempService(request);

      if (status === 200) return data;
      this.adminStatus = 'ERROR';
    } catch (error) {
      const errorPage = {
        404: () => 'AdminAccountError',
        400: () => 'AdminExpiryTerm',
      };
      this.$router.push({ name: errorPage[error.status]?.() ?? 'AuthMain' });
    }
  }

  private updateStatus(): void {
    this.isRegistered = true;
    this.init();
  }
}
