



















import { Component, Vue, Model, Ref, Prop } from 'vue-property-decorator';
import MultiCategoriesSelector, {
  MultiCategorySelectorData,
  MultiCategorySelectorOptions,
  SearchMode,
} from '@/components/common/categories/MultiCategoriesSelector.vue';
import {
  GetStandardCategoriesRequest,
  GetStandardCategoriesTreeRequest,
  NCPResponse,
  StandardCategory,
  GetStandardCategoriesTree,
  YorN,
} from 'ncp-api-supporter';
import { getCurrentMallNo } from '@/utils/mall';

const options: MultiCategorySelectorOptions = {
  selectMode: 'single',
  keywordKeys: {
    uniqueKey: 'depth4CategoryNo',
    fullNameKey: 'fullCategoryName',
  },
  selectKeys: {
    uniqueKey: 'categoryNo',
    fullNameKey: 'fullCategoryName',
    nameKey: 'name',
    childKey: 'child',
  },
} as const;

/**
 * @MultiStandardCategoriesSelector
 *
 *  getStandardCategories, getStandardCategoriesTree API 를 사용합니다.
 *  @getStandardCategories : created 시점에 단 한번 호출
 *  @getStandardCategoriesTree : MultiCategoriesSelector 컴포넌트가 select 모드로 전환되는 시점에 단 한번 호출
 */
@Component({
  components: {
    MultiCategoriesSelector,
  },
})
export default class MultiStandardCategoriesSelector extends Vue {
  @Model('change') readonly value: number[];
  @Prop({ default: false })
  private disabled?: boolean;
  @Prop({ required: false, default: '' })
  private readonly className: string;
  @Ref() selector!: MultiCategoriesSelector;
  @Prop({ default: true })
  private readonly canUseStandard: boolean; // TODO: 표준을 사용할 수 있습니까? 무슨뜻이지?
  @Prop({ default: true })
  private readonly updateMinorPurchaseYn: (isForAdults: boolean) => void;
  @Prop({ default: true })
  private readonly setIsBookCategory: (isBook: boolean) => void;
  @Prop({ required: false })
  private readonly resetCategoryData!: () => void;
  @Prop({ required: false })
  private readonly minorPurchaseYn?: YorN;

  private data: MultiCategorySelectorData = {
    keywordFindData: [],
    selectFindData: [],
  };
  private readonly options: MultiCategorySelectorOptions = options;

  created() {
    this.fetchKeywordFindData(getCurrentMallNo(this));
  }

  public resetSelectData() {
    this.selector.reset();
  }

  private async fetchKeywordFindData(mallNo: number): Promise<void> {
    const request: GetStandardCategoriesRequest = {
      params: {
        mallNo: mallNo.toString(),
      },
    };

    const { data }: NCPResponse<StandardCategory[]> = await this.$api.getStandardCategories(request);
    this.data.keywordFindData = data;
  }

  private async fetchSelectFindData(mallNo: number): Promise<void> {
    const request: GetStandardCategoriesTreeRequest = {
      params: {
        mallNo: mallNo.toString(),
      },
    };

    const {
      data: { tree },
    }: NCPResponse<GetStandardCategoriesTree> = await this.$api.getStandardCategoriesTree(request);
    this.data.selectFindData = tree;
  }

  private noDataEvent(type: SearchMode): void {
    if (type === 'keyword') {
      this.$emit('error:no-data');
    }
    if (type === 'select') {
      this.fetchSelectFindData(getCurrentMallNo(this));
    }
  }

  public focus(): void {
    this.selector.focus();
  }

  public get standardCategories(): StandardCategory[] {
    return this.data.keywordFindData as StandardCategory[];
  }
}
