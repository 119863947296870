

























































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GUIDE_TYPE } from '@/const/contents/product';
import { throwWindowPopup } from '@/helpers/popup';
import { InspectionProductInfo, OriginProductInfo } from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import { GetInspectionsView, ProductsGuide, Item, Reason } from 'ncp-api-supporter/dist/types/modules/product/product';
import { APPLY_STATUS } from '@/const/common';
import { ProductsGuidesType } from 'ncp-api-supporter/dist/types/enum';
type MergedProductGuide = {
  templateNo?: number;
  type: ProductsGuidesType;
  originContent?: OriginProductInfo['productGuides'][number]['content'];
  inspectionContent?: InspectionProductInfo['productGuides'][number]['content'];
};

@Component({
  components: {
    TextInput,
  },
})
export default class ImageTable extends Vue {
  @Prop({ required: true }) private readonly inspectionView?: GetInspectionsView;
  @Prop({ required: true }) private readonly isApprovalReady?: boolean;
  @Prop({ required: true }) private readonly isAfterApprovalReady?: boolean;
  private readonly originProductInfo?: OriginProductInfo = this.inspectionView?.originProductInfo;
  private readonly inspectionProductInfo?: InspectionProductInfo = this.inspectionView?.inspectionProductInfo;
  private readonly modifiedParam: string[] = this.inspectionView?.modifiedParam ?? [];
  private readonly inspectionStatus: string = this.inspectionView?.inspectionStatus ?? '';
  private mergedProductGuides?: MergedProductGuide[];

  private imageInfo = {
    imageEditText: {
      reason: '',
      name: this.$t('PRODUCT.COMMON.UPDATE'),
    },
  };

  get isExposureImageInfo() {
    const imageInfoItems = [
      'mallProductImages',
      'mallProductListImage',
      'contentHeader',
      'content',
      'contentFooter',
      'productGuides',
    ];
    return (
      this.modifiedParam.some(param => imageInfoItems.includes(param)) ||
      this.inspectionStatus === APPLY_STATUS.APPROVAL_READY
    );
  }

  //노출항목
  private isExposureItem(modifiedItem: string): boolean {
    //승인대기
    if (this.inspectionStatus === APPLY_STATUS.APPROVAL_READY) return true;
    //수정후 승인대기
    if (this.inspectionStatus === APPLY_STATUS.AFTER_APPROVAL_READY) {
      return this.modifiedParam.includes(modifiedItem);
    }
    return false;
  }

  @Watch('imageInfo', { deep: true })
  private getReasons() {
    const isReason = this.imageInfo.imageEditText.reason.length > 0;
    const reasons: Reason[] = [];
    const imageReason: Item[] = [
      {
        item: this.imageInfo.imageEditText.name.toString(),
        detail: {
          data: '',
          comment: this.imageInfo.imageEditText.reason,
        },
      },
    ];
    const isNotWrittenReason = this.imageInfo.imageEditText.reason === '';
    if (isReason) reasons.push({ section: 'IMAGE_INFO', items: isNotWrittenReason ? [] : imageReason });
    this.$emit('reasons', reasons);
  }

  //상품가이드 ( productGuides[] :  배송안내, AS안내, 환불안내, 교환안내 )
  private createMergedProductGuides() {
    if (!this.inspectionProductInfo?.productGuides || !this.originProductInfo?.productGuides) return;
    this.setInitProductGuides(this.originProductInfo?.productGuides);
    this.setInitProductGuides(this.inspectionProductInfo?.productGuides);

    const inspectionProductGuideMap = new Map();
    this.inspectionProductInfo.productGuides.forEach(({ type, content }) => {
      inspectionProductGuideMap.set(type, content);
    });

    this.mergedProductGuides = this.originProductInfo.productGuides.map(guide => ({
      ...guide,
      originContent: guide.content,
      inspectionContent: inspectionProductGuideMap.get(guide.type),
    }));
  }

  //NOTE: 상품가이드에서 "사용안함"으로 설정된 항목 경우 productGuides에 데이터가 없어 기본 셋팅이 필요함
  private setInitProductGuides(productGuides: ProductsGuide[]) {
    if (!this.inspectionProductInfo?.productGuides || !this.originProductInfo?.productGuides) return;
    if (productGuides.length >= 4) return;

    const initProductGuides: ProductsGuide[] = [
      { templateNo: undefined, type: 'DELIVERY', content: this.$t('PRODUCT.COMMON.NOT_USE').toString() },
      { templateNo: undefined, type: 'AFTER_SERVICE', content: this.$t('PRODUCT.COMMON.NOT_USE').toString() },
      { templateNo: undefined, type: 'REFUND', content: this.$t('PRODUCT.COMMON.NOT_USE').toString() },
      { templateNo: undefined, type: 'EXCHANGE', content: this.$t('PRODUCT.COMMON.NOT_USE').toString() },
    ];

    if (productGuides.length === 0) {
      this.setProductGuides(initProductGuides);
      return;
    }

    const updatedProductGuides = initProductGuides.map(initProductGuide => {
      const newProductGuide = productGuides.find(({ type }) => type === initProductGuide.type);
      return newProductGuide ?? initProductGuide;
    });
    this.setProductGuides(updatedProductGuides);
  }

  private setProductGuides(productGuides: ProductsGuide[]) {
    if (this.inspectionStatus === APPLY_STATUS.AFTER_APPROVAL_READY) {
      this.inspectionProductInfo.productGuides = productGuides;
    }
    this.originProductInfo.productGuides = productGuides;
  }

  //팝업 : 기본템플릿
  private openDefaultGuideTemplateFormPopup(type: keyof typeof GUIDE_TYPE) {
    throwWindowPopup(
      'DefaultGuideTemplateForm',
      { type, partnerNo: this.originProductInfo?.partnerNo, isReadonly: true },
      'md',
      () => null,
      '',
      undefined,
      undefined,
      600,
    );
  }

  created() {
    this.createMergedProductGuides();
  }
}
