import { GridProp } from '@/types';
import { i18n } from '@/main';

export const gridProps: GridProp = {
  header: {
    height: 33,
  },
  options: {
    rowHeaders: [],
    heightResizable: true,
    bodyHeight: 'fitToParent',
    pageOptions: {
      perPage: 10,
      page: 1,
      totalCount: 0,
    },
    // minBodyHeight: 60,
  },
  columns: [
    {
      header: 'PRODUCT.ADD.DUTY_INFO',
      name: 'title',
      minWidth: 100,
      formatter: ({ value }) => value.toString(),
    },
    {
      header: 'SELECT',
      name: 'select',
      width: 60,
      align: 'center',
      formatter: () => i18n.t('SELECT').toString(),
    },
    {
      header: 'DELETE',
      name: 'delete',
      width: 60,
      align: 'center',
      formatter: () => i18n.t('DELETE').toString(),
    },
  ],
};
