

















































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import OrderProducts from '@/components/popup/claim/ClaimApplyPopup/OrderProducts.vue';
import ProductDetails from '@/components/popup/claim/ClaimApplyPopup/ProductDetails.vue';
import DiscountShippingFee from '@/components/popup/claim/ClaimApplyPopup/DiscountShippingFee.vue';
import RefundExchangeAmount from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeAmount.vue';
import BenefitsPaid from '@/components/popup/claim/ClaimApplyPopup/BenefitsPaid.vue';
import ProductExchange from '@/components/popup/claim/ClaimApplyPopup/ProductExchange.vue';
import { CancelExchangesPredict } from 'ncp-api-supporter';

@Component({
  components: { TextInput },
})
export default class ProductExchangeDetails extends Vue {
  @Prop({ required: true }) private claimType!: string;
  @Prop({ required: true }) private postPredict!: {};
  @Prop({ required: true }) private objJson!: string;
  @Prop({ default: false }) private isPartnerProduct: boolean;
  @Prop({ default: '' }) private responsibleObjectTypeValue: string;

  @Watch('postPredict')
  onPredictChange() {
    this.postPredictCalculation();
  }

  private refOrderProducts;
  private refProductDetails;
  private refDiscountShippingFee;
  private refRefundExchangeAmount;
  private refBenefitsPaid;
  private refProductExchange;
  private showWhoDoesPay = false;
  private cancelTotalPrice = 0;
  private exchangeTotalPrice = 0;
  private exchangeCancelTotalPrice = 0;
  private exchangeAddTotalPrice = 0;
  private cancelAddTotalPrice = 0;
  private exchangeAddCancelAddTotalPrice = 0;
  private productCouponDiscountAmt = 0;
  public exchangeProductAmtAdjustAmt = 0;
  public exchangeProductAmtAdjustReason = '';
  private totalPayAmt = 0;
  private orgTotalPayAmt = 0;
  private totalDeliveryAmt = 0;
  private totalExchangeAmt = 0;
  private displayedDeliveryAmtDiscountMessage = false;
  private specOut = false;
  private couponNo = 0;
  private couponName = '';

  private mounted() {
    this.setRefComponent();
    this.postPredictCalculation();
    this.defaultCalculation();
  }
  public defaultCalculation() {
    this.cancelTotalPrice = 0;
    this.exchangeTotalPrice = 0;
    this.cancelAddTotalPrice = 0;
    this.exchangeAddCancelAddTotalPrice = 0;
    this.orgTotalPayAmt = 0;
    this.totalExchangeAmt = 0;
    this.totalPayAmt = 0;
  }
  private onExchangeChange() {
    if (this.exchangeProductAmtAdjustAmt > this.orgTotalPayAmt) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER5'));
      this.exchangeProductAmtAdjustAmt = 0;
      this.exchangeProductAmtAdjustReason = '';
    }
    if (!this.exchangeProductAmtAdjustAmt) {
      this.exchangeProductAmtAdjustAmt = 0;
      this.exchangeProductAmtAdjustReason = '';
    }
    const refundOrderPrice = (this.postPredict as CancelExchangesPredict).amounts.refund.order;
    const totalRefundOrderPrice =
      refundOrderPrice.exchangeTotalPrice - refundOrderPrice.cancelTotalPrice + refundOrderPrice.additionalDiscountAmt;

    if (totalRefundOrderPrice < this.exchangeProductAmtAdjustAmt) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER5'));
      this.exchangeProductAmtAdjustAmt = 0;
      return this.onExchangeChange();
    } else {
      if (this.claimType === 'ExchangePopupBefore' || this.claimType === 'ExchangePopupAfter') {
        this.showWhoDoesPay = Number(this.exchangeProductAmtAdjustAmt) > 0;
        this.$emit('setData');
      }
    }
  }

  private postPredictCalculation() {
    this.refOrderProducts.claimCalculation();
    const postPredict = this.postPredict as CancelExchangesPredict;
    this.cancelTotalPrice = this.refOrderProducts.claimableOptions[0].totalProductPriceOrderCnt;
    this.exchangeTotalPrice =
      this.cancelTotalPrice -
      (postPredict.amounts.refund.order.standardAmt -
        postPredict.amounts.refund.order.immediateDiscountAmt -
        postPredict.amounts.productAdjustAmt);
    this.exchangeCancelTotalPrice = this.exchangeTotalPrice - this.cancelTotalPrice;
    this.exchangeAddTotalPrice = this.refProductExchange.optionAddPrice * this.refProductExchange.exchangeCnt;
    this.cancelAddTotalPrice =
      this.refOrderProducts.claimableOptions[0].additionalDiscountAmt * this.refOrderProducts.orderCntArray[0];
    this.exchangeAddCancelAddTotalPrice = this.exchangeAddTotalPrice - this.cancelAddTotalPrice;
    this.productCouponDiscountAmt = postPredict.amounts.refund.coupon.productCouponDiscountAmt * -1;
    this.totalPayAmt =
      this.exchangeCancelTotalPrice -
      this.exchangeAddCancelAddTotalPrice -
      this.productCouponDiscountAmt -
      this.exchangeProductAmtAdjustAmt;
    this.orgTotalPayAmt =
      this.exchangeCancelTotalPrice - this.exchangeAddCancelAddTotalPrice - this.productCouponDiscountAmt;
    this.totalDeliveryAmt =
      postPredict.amounts.refund.coupon.cartCouponDiscountAmt - postPredict.amounts.refund.delivery.totalDeliveryAmt;
    this.totalExchangeAmt = this.totalPayAmt - this.totalDeliveryAmt;
    this.displayedDeliveryAmtDiscountMessage =
      postPredict.amounts.before.delivery.deliveryGroupAmounts.alreadyPaidTotalDeliveryAmt !==
      postPredict.amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
    if (!postPredict.coupon.productCoupons) return;
    this.couponNo = postPredict.coupon.productCoupons[0]?.couponNo;
    this.couponName = postPredict.coupon.productCoupons[0]?.couponName;
  }

  protected onlyCommaNumber(event: Event) {
    const $input = event.target as HTMLInputElement;
    if ($input.value === '' || $input.value === '0') {
      $input.value = '0';
      return;
    }
    $input.value = $input.value
      .toString()
      .replace(/\D/g, '')
      .replace(/^0+/, '');
  }

  private setRefComponent() {
    this.refOrderProducts = this.$parent.$refs.componentItemsClaim as OrderProducts;
    this.refProductDetails = this.$parent.$refs.componentProductDetails as ProductDetails;
    this.refDiscountShippingFee = this.$parent.$refs.componentDiscountShippingFee as DiscountShippingFee;
    this.refRefundExchangeAmount = this.$parent.$refs.componentRefundExchangeAmount as RefundExchangeAmount;
    this.refBenefitsPaid = this.$parent.$refs.componentBenefitsPaid as BenefitsPaid;
    this.refProductExchange = this.$parent.$refs.componentProductExchange as ProductExchange;
    // this.refReturnShippingFee = this.$parent.$refs.componentReturnShippingFee as ReturnShippingFee;
  }

  private get couponDiscountAmt(): string {
    if (this.postPredict === undefined) {
      return '';
    }
    const productCouponDiscountAmt = this.productCouponDiscountAmt.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    if (!this.couponNo || !this.couponName || this.productCouponDiscountAmt === 0) {
      return productCouponDiscountAmt;
    }
    return `${productCouponDiscountAmt}<br/>
            ${this.$t('CLAIM.EXCHANGEPOPUP.COUPON_NAME')}:${this.couponNo} ${this.couponName})<br/>
            <span class="shop-list-badge">${this.$t('CLAIM.EXCHANGEPOPUP.RESTORE')}</span>`;
  }
}
