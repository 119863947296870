






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { hasClass } from '@/utils/element';
import { range1ToNI18n } from '@/utils/common';
@Component
export default class SmsSendAdNotice extends Vue {
  @Prop() private onNegativeClick!: () => void;
  @Prop() private data;

  private lawLink1 =
    'https://www.law.go.kr/법령/정보통신망이용촉진및정보보호등에관한법률/(20200910,17358,20200609)/제50조';
  private lawLink2 =
    'https://www.law.go.kr/법령/정보통신망이용촉진및정보보호등에관한법률/(20200910,17358,20200609)/제76조';
  private lawLink3 = 'https://spam.kisa.or.kr/spam/sub62.do';

  private noticeTextList = range1ToNI18n(3, 'MEMBER.SMS_DISPATCH.NOTICE_TEXT_');
  private noticeDetailList = range1ToNI18n(3, 'MEMBER.SMS_DISPATCH.NOTICE_LIST_DETAIL_');

  private get isSmsSend(): boolean {
    return this.data.name === 'SmsSend';
  }

  private get currentOption(): string {
    return this.isSmsSend ? 'SEND' : 'AUTO';
  }

  private registerClickEvent() {
    const linkTag = this.$el.querySelectorAll('.new_tab_link');
    linkTag.forEach(tag => {
      const link = hasClass(tag, 'article_50') ? this.lawLink1 : this.lawLink2;
      tag.addEventListener('click', () => this.openNewTab(link));
    });
  }

  private openNewTab(link: string) {
    window.open(link, '_blank');
  }

  mounted() {
    this.registerClickEvent();
  }
}
