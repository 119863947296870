import { CheckBoxGroupOption, OptionData, RadioBoxGroupOption, ValueType } from '@/helpers/type';
import { ConvertToCheckBoxGroupOption } from '@/types';
import { SaleMethodType } from 'ncp-api-supporter';
import { i18n } from '@/main';
import { CONFIG_TYPE } from '@/const/contents/product';

export const relatedProductRadioOption: RadioBoxGroupOption<keyof typeof CONFIG_TYPE> = {
  name: 'relatedProduct',
  data: [
    {
      id: 'none',
      name: 'relatedProduct',
      value: CONFIG_TYPE.NOT_USED,
      display: 'PRODUCT.COMMON.NOT_USE',
    },
    {
      id: 'displayCategory',
      name: 'relatedProduct',
      value: CONFIG_TYPE.DISPLAY_CATEGORY,
      display: 'PRODUCT.COMMON.DISPLAY_CATEGORY',
    },
    {
      id: 'individual',
      name: 'relatedProduct',
      value: CONFIG_TYPE.SELECTED,
      display: 'PRODUCT.COMMON.SELECTED',
    },
  ],
};

export const saleMethodSelectOptions: OptionData<SaleMethodType>[] = [
  {
    name: 'PRODUCT.COMMON.SALE_METHOD_TYPE_PURCHASE',
    value: 'PURCHASE',
  },
  {
    name: 'PRODUCT.COMMON.SALE_METHOD_TYPE_CONSIGNMENT',
    value: 'CONSIGNMENT',
  },
];

export const getDisplayCategoryToolTipMsg = () => `<li>${i18n.t('PRODUCT.ADD.STANDARD_CATEGORY_TOOLTIP')}</li>`;

/*
 * @params: 데이터에 들어갈 타입을 <> 안에 정의
 * */
export const convertToCheckBoxGroupOptionType = ({
  name,
  hasAll,
  noBrackets,
  data,
  valueKey,
  displayName,
}: ConvertToCheckBoxGroupOption<any>): CheckBoxGroupOption<ValueType> => {
  data = data.map(each => ({
    id: each[valueKey],
    value: each[valueKey],
    display: each[displayName],
  }));
  data.unshift({
    id: 'ANY',
    value: 'ANY',
    display: i18n.t('PRODUCT.ADD.ALL_GRADES'),
  });
  return {
    name,
    hasAll,
    noBrackets,
    data,
    nonI18nConvert: true,
  };
};
