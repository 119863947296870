



















import { Component, Model, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OptionData, ValueType } from '@/helpers/type';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { orderListTypes } from '@/const/order.ts';
// import { GetOrdersSearchTypesContent } from 'ncp-api-supporter';

@Component({
  components: {
    SelectBox,
    TextInput,
  },
})
export default class OrderSearchKeywordInput extends Vue {
  @PropSync('keyword') private keywordSync!: string;
  @PropSync('searchType') private selectedValue!: string;
  @Prop({ required: true }) private readonly orderListTypes!: string;
  @Prop({ default: '202px' }) private width!: string;
  @Model('change') private readonly value!: string;

  // API가 아닌 고정값으로 변경됨
  // 히스토리: https://nhnent.dooray.com/project/posts/3011544286456351781
  private searchTypeArr: OptionData<ValueType>[] = [
    { name: '주문번호', value: 'ORDER_NO' }, // Default
    { name: '주문자명', value: 'ORDERER_NAME' },
    ...(this.orderListTypes !== orderListTypes.PREVIOUS ? [{ name: '상품번호', value: 'MALL_PRODUCT_NO' }] : []),
    { name: '주문자연락처', value: 'ORDERER_CONTACT1' },
    { name: '상품명', value: 'PRODUCT_NAME' },
    ...(this.orderListTypes !== orderListTypes.PREVIOUS ? [{ name: '옵션값', value: 'OPTION_VALUE' }] : []),
    { name: '수령자명', value: 'RECEIVER_NAME' },
    { name: '수령자연락처', value: 'RECEIVER_CONTACT1' },
    // { name: '페이코 주문번호', value: 'PAYCO_ORDER_NO' },  // 기능 제공때 까지 hidden
  ];

  created() {
    this.selectedValue = 'ORDER_NO';
    // this.handleSearchTypeArr();
  }

  private handleKeyDownEnter(event: KeyboardEvent) {
    if (event.isComposing || event.keyCode === 229) return;
    this.$emit('search');
  }

  // pro에선 API 요청이 아닌 고정값을 출력하므로 임시 주석처리
  // private async handleSearchTypeArr() {
  //   const searchResponse = await this.$api.getOrdersSearchTypes({
  //     params: {
  //       groupByKey: '',
  //       orderStatus:
  //         this.orderListTypes === 'ORDER_LIST' ||
  //         this.orderListTypes === 'HOLD_DELIVERY' ||
  //         this.orderListTypes === 'HOLD_BY_RESERVATION'
  //           ? 'DEPOSIT_WAIT'
  //           : this.orderListTypes,
  //     },
  //   });
  //   const searchType: GetOrdersSearchTypesContent[] = searchResponse.data;
  //   this.handleSearchType(searchType);
  // }
  //
  // private handleSearchType(data) {
  //   this.searchTypeArr = [];
  //   if (data.types.length === 0) {
  //     return;
  //   }
  //   data.types.forEach(ele => {
  //     this.searchTypeArr.push({
  //       name: ele.name,
  //       value: ele.value,
  //     });
  //   });
  // }
}
