















import { Component, Prop, Vue } from 'vue-property-decorator';
import ReplacementTextTable from '@/components/common/ReplacementTextTable.vue';
import { MessageTemplate } from '@/types/member';
import NoticeBox from '@/components/common/NoticeBox.vue';

@Component({
  components: { ReplacementTextTable, NoticeBox },
})
export default class ReplacementTextList extends Vue {
  @Prop() private onNegativeClick!: () => void;
  @Prop({ required: true }) private data: { template: MessageTemplate[]; noticeList: []; leftAlign?: boolean };
}
