







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetProductsHistories } from 'ncp-api-supporter';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';

@Component({
  components: {
    ModalHeader,
  },
})
export default class ChangeHistoryPopup extends Vue {
  @Prop() private data!: GetProductsHistories;
}
