

























































































































































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  PostCsAdminRegisteredCsRequest,
  DeleteCsAdminRequest,
  PutCsAdminRegisteredCsCsNoRequest,
  GetCsRequest,
} from 'ncp-api-supporter';
import { namespace } from 'vuex-class';
import { Admin } from '@/types';
import FilterDeletedUser from '@/components/popup/shipping/mixins/FilterDeletedUser';

interface RegisterAudit {
  clientIp: string;
  name: string;
  adminNo: number;
  timestamp: string;
}

const adminStore = namespace('admin');

@Component({
  components: { SelectBox },
})
export default class CsMessage extends Mixins(FilterDeletedUser) {
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;

  @Prop() private showCsMessage?: boolean;
  @Prop() private orderProductOptionNoArr;
  @Prop() private serviceNo;
  @Prop() private showCsMessageIcon;
  @Prop() private showCsMessageIconStyle;
  private allCsLog = true;
  private taskContentLength = 0;
  private csAutoList = [];
  private needHandleList = [];
  private needHandleCsNums = 0;
  private processingCsNums = 0;
  public csProcessNum = 0;
  public csTotalNum = 0;

  public cs = {
    csStatusType: 'PROCESSING',
    csType: 'PRODUCT',
    csPathType: 'IN_BOUND',
    orderProductOptionNo: undefined,
    content: null,
  };

  public taskMessageStatusTypeArr = [
    { value: 'PROCESSING', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_STATUS_TYPE_PROCESSING' },
    { value: 'DONE', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_STATUS_TYPE_DONE' },
  ];
  public taskMessageTypeArr = [
    { value: 'PAY', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_PAY' },
    { value: 'PRODUCT', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_PRODUCT' },
    { value: 'DELIVERY', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_DELIVERY' },
    { value: 'CLAIM', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_CLAIM' },
    { value: 'REFUND', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_REFUND' },
    { value: 'ETC', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_ETC' },
  ];

  public csPathTypeArr = [
    { value: 'IN_BOUND', label: 'ORDER.ORDER_DETAIL.CS_PATH_IN_BOUND' },
    { value: 'OUT_BOUND', label: 'ORDER.ORDER_DETAIL.CS_PATH_OUT_BOUND' },
    { value: 'ETC', label: 'ORDER.ORDER_DETAIL.CS_PATH_ETC' },
  ];

  private get isMaster(): boolean {
    return this.admin.role === 'MASTER';
  }
  private canUpdate({ registerAudit }): boolean {
    if (this.isMaster) return true;
    return this.admin.no === registerAudit.adminNo;
  }
  public taskContentInput() {
    if (this.cs.content) {
      this.taskContentLength = this.cs.content.length;
    } else {
      this.taskContentLength = 0;
    }
  }

  private postCsInfo() {
    if (!this.cs.content) {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_8'));
      return;
    }
    const addCsRequest: PostCsAdminRegisteredCsRequest = {
      data: {
        orderNo: this.$route.query.orderNo as string,
        csStatusType: this.cs.csStatusType,
        csType: this.cs.csType,
        orderOptionNo: this.cs.orderProductOptionNo,
        csPathType: this.cs.csPathType,
        content: this.cs.content,
      },
    };
    this.$api.postCsAdminRegisteredCs(addCsRequest).then(() => {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_9'));
      this.handleGetCs();
      this.cs.content = '';
      this.taskContentLength = 0;
    });
  }

  private handleGetCs(): void {
    const csRequest: GetCsRequest = {
      params: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api.getCs(csRequest).then(({ data }) => {
      this.formatterCsList(data);
    });
  }

  private formatterCsList(arr): void {
    if (arr.length === 0) {
      return;
    }
    arr.map(item => {
      this.$set(item, 'contentLength', item.content.length);
      this.$set(item, 'newContent', item.content);
      this.$set(item, 'newCsStatusType', item.csStatusType);
      this.$set(item, 'newCsType', item.csType);
      this.$set(item, 'newCsPathType', item.csPathType);
      this.$set(item, 'showEdit', false);
      if (item.orderOptionNo) {
        this.$set(item, 'newOrderOptionNo', item.orderOptionNo);
      } else {
        this.$set(item, 'newOrderOptionNo', undefined);
      }
    });
    this.csAutoList = arr.filter(item => {
      return item.csPathType === 'SYSTEM';
    });
    this.needHandleList = arr.filter(item => {
      return item.csPathType !== 'SYSTEM';
    });
    this.needHandleCsNums = this.needHandleList.length;
    if (this.needHandleList.length > 0) {
      this.processingCsNums = this.needHandleList.filter(cs => {
        return cs.csStatusType === 'PROCESSING';
      }).length;
    }
    this.handleTheCsNums(this.needHandleCsNums, this.processingCsNums);
  }
  public handleTheCsNums(allNum: number, processingNum: number) {
    this.csProcessNum = processingNum;
    this.csTotalNum = allNum;
  }
  public replaceContent(content) {
    return content.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
  private handleEditCs(theItem) {
    this.$set(theItem, 'showEdit', true);
  }
  private onClickDeleteBtn({ csNo }): void {
    if (!confirm(this.$t('ORDER.CS_TASK.CONFIRM_1') as string)) {
      return;
    }
    const deleteRequest: DeleteCsAdminRequest = {
      params: {
        csNos: csNo,
      },
    };
    this.$api.deleteCsAdmin(deleteRequest).then(() => {
      this.handleGetCs();
    });
  }
  private handleCsComplated(theItem): void {
    const putCsRequest: PutCsAdminRegisteredCsCsNoRequest = {
      pathParams: {
        csNo: theItem.csNo,
      },
      data: {
        orderNo: theItem.orderNo,
        csStatusType: 'DONE',
        csType: theItem.newCsType,
        orderOptionNo: theItem.newOrderOptionNo,
        csPathType: theItem.newCsPathType,
        content: theItem.newContent,
      },
    };
    this.$api.putCsAdminRegisteredCsCsNo(putCsRequest).then(() => {
      alert(this.$t('ORDER.CS_TASK.ALERT_12'));
      this.handleGetCs();
    });
  }
  private getCsTypeName(csType) {
    let name;
    switch (csType) {
      case 'PAY':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_PAY');
        break;
      case 'PRODUCT':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_PRODUCT');
        break;
      case 'DELIVERY':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_DELIVERY');
        break;
      case 'CLAIM':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_CLAIM');
        break;
      case 'REFUND':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_REFUND');
        break;
      case 'ETC':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_ETC');
        break;
      default:
        name = '';
    }
    return name;
  }
  private getCsPathName(path) {
    let name;
    switch (path) {
      case 'IN_BOUND':
        name = this.$t('ORDER.CS_TASK.CS_PATH_TYPE_IN_BOUND');
        break;
      case 'OUT_BOUND':
        name = this.$t('ORDER.CS_TASK.CS_PATH_TYPE_OUT_BOUND');
        break;
      case 'ETC':
        name = this.$t('ORDER.CS_TASK.CS_PATH_TYPE_ETC');
        break;
      default:
        name = '';
    }
    return name;
  }
  private csContentInput(event) {
    if (event.newContent) {
      event.contentLength = event.newContent.length;
    } else {
      event.contentLength = 0;
    }
  }
  private handlePutCs(theItem) {
    if (!theItem.newContent) {
      alert(this.$t('ORDER.CS_TASK.ALERT_10'));
      theItem.newContent.focus();
      return;
    }
    const putCsRequest: PutCsAdminRegisteredCsCsNoRequest = {
      pathParams: {
        csNo: theItem.csNo as string,
      },
      data: {
        orderNo: theItem.orderNo,
        csStatusType: theItem.newCsStatusType,
        csType: theItem.newCsType,
        orderOptionNo: theItem.newOrderOptionNo,
        csPathType: theItem.newCsPathType,
        content: theItem.newContent,
      },
    };
    this.$api.putCsAdminRegisteredCsCsNo(putCsRequest).then(() => {
      alert(this.$t('ORDER.CS_TASK.ALERT_11'));
      this.handleGetCs();
    });
  }
  private handleCancelEditCs(theItem) {
    this.$set(theItem, 'showEdit', false);
    this.$set(theItem, 'contentLength', theItem.content.length);
    this.$set(theItem, 'newContent', theItem.content);
    this.$set(theItem, 'newCsStatusType', theItem.csStatusType);
    this.$set(theItem, 'newCsType', theItem.csType);
    this.$set(theItem, 'newCsPathType', theItem.csPathType);
    if (theItem.orderOptionNo) {
      this.$set(theItem, 'newOrderOptionNo', theItem.orderOptionNo);
    } else {
      this.$set(theItem, 'newOrderOptionNo', undefined);
    }
  }
  private mounted() {
    this.handleGetCs();
  }
}
