






























import { Vue, Component } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { gridProps, getContainerOptions } from '@/views/contents/product/basic/ProductThumbnailRegistration';
import { Getter } from 'vuex-class';
import {
  GetProductBulkImagesUploadInfoResponse,
  GetProductsExcelFailResultsRequest,
  GetProductsExcelRegisterFormRequest,
  Mall,
  NCPResponse,
  PostProductsExcelBulkImages,
  SuccessResults,
} from 'ncp-api-supporter';
import { throwExternalWindowPopup } from '@/helpers/popup';
import { ExcelUploadType, ImageData, ImageSuccessData } from '@/types/ProductThumbnailRegistration';
import UploadForm from '@/components/product/common/UploadForm.vue';
import { setNoDataMessage } from '@/helpers/grid';
import { getDownToday, getToday } from '@/utils/dateFormat';
import { saveBinaryDataToFile } from '@/utils/fileSaver';
import { getMallName } from '@/utils/mall';

export enum IMAGE_UPLOAD_MESSAGE {
  OK = 'callbackOk',
  CANCEL = 'callbackCancel',
  COMPLETE = 'callbackComplete',
}

@Component({
  components: { Grid, UploadForm },
})
export default class ProductThumbnailRegistration extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  private gridProps = gridProps;
  private gridContainerOptions = getContainerOptions();
  private uploadDate = '';
  private totalCount = 0;
  private uploadedImageCount = 0;
  private mallNo = '';
  private imageUploadPopup: Window;
  private uploadedImageData: ImageSuccessData[];
  private productImagesData: SuccessResults[] = [];

  created() {
    window.addEventListener('message', this.receiveMessage);
    setNoDataMessage(this.$t('PRODUCT.BULK_IMAGE.NO_DATA').toString());
  }

  destroyed() {
    window.removeEventListener('message', this.receiveMessage);
  }

  private receiveMessage(event: MessageEvent) {
    const TOAST_CLOUD = 'product.cloud.toast.com';
    const { data, origin } = event;

    if (!data || !origin || origin.indexOf(TOAST_CLOUD) === -1) return;
    switch (data.type) {
      case IMAGE_UPLOAD_MESSAGE.OK:
        return this.closeImageUploadPopup();
      case IMAGE_UPLOAD_MESSAGE.CANCEL:
        return this.closeImageUploadPopup();
      case IMAGE_UPLOAD_MESSAGE.COMPLETE:
        return this.saveImageInfos(data);
      default:
        throw new Error('Unknown data type');
    }
  }

  private closeImageUploadPopup(): void {
    this.imageUploadPopup.close();
  }

  private saveImageInfos({ response }: ImageData): void {
    this.uploadedImageData = response.successes;
  }

  private async openImageUploadPopup() {
    try {
      const url = await this.getImageUploadPopupUrl();
      this.imageUploadPopup = throwExternalWindowPopup(url, 'md');
    } catch (err) {
      console.error(err);
    }
  }

  private async getImageUploadPopupUrl(): Promise<string> {
    let url = '';
    try {
      const {
        data,
      }: NCPResponse<GetProductBulkImagesUploadInfoResponse> = await this.$api.getProductBulkImagesUploadInfo();
      url = data.url;
    } catch (e) {
      console.error(e);
    }
    return url;
  }

  private hasUploadedImageData(): boolean {
    if (!this.uploadedImageData) {
      alert(this.$t('PRODUCT.BULK_IMAGE.IMAGE_UPLOAD_MESSAGE'));
      return false;
    }
    return true;
  }

  private async postExcelFile({ excelFile, resultPopup, mallNo }: ExcelUploadType) {
    const uploadedImages = this.uploadedImageData.map(({ url }) => url);
    const formData = new FormData();
    formData.append('excelFile', excelFile);
    formData.append('mallNo', mallNo);
    formData.append('uploadedImages', uploadedImages.toString());

    try {
      const { data }: NCPResponse<PostProductsExcelBulkImages> = await this.$api.postProductsExcelBulkImages({
        data: formData,
      });
      const { totalCount, totalSuccessCount, totalFailCount, failSeq, dateTime, successResults } = data;
      resultPopup({ successCount: totalSuccessCount, failCount: totalFailCount, totalCount });
      this.totalCount = totalCount;
      this.uploadedImageCount = totalSuccessCount;
      this.uploadDate = dateTime;
      if (failSeq) {
        const mallName = getMallName(Number(mallNo));
        await this.downloadFailedExcel(failSeq, mallName);
      }
      this.productImagesData = successResults;
    } catch (e) {
      console.error(e);
    }
  }

  private async downloadFailedExcel(failureNumber: number, mallName: string) {
    const fileName = `${mallName}_${this.$t('PRODUCT.BULK_IMAGE.FAIL_EXCEL_NAME')}_${getDownToday()}.xlsx`;
    const params: GetProductsExcelFailResultsRequest['params'] = {
      seq: failureNumber,
      mallNo: Number(this.mallNo),
      excelBulkType: 'PRODUCT_IMAGE_BULK',
    };
    try {
      const { data } = await this.$api.getProductsExcelFailResults({ params, responseType: 'arraybuffer' });
      saveBinaryDataToFile(data, fileName);
    } catch (e) {
      console.error(e);
    }
  }

  private async downloadSampleExcel() {
    try {
      const request: GetProductsExcelRegisterFormRequest = {
        params: {
          mallNo: Number(this.mallNo),
          excelBulkType: 'PRODUCT_IMAGE_BULK',
        },
      };
      const mallName = getMallName(Number(this.mallNo));
      const fileName = `${mallName}_${this.$t('PRODUCT.BULK_IMAGE.SAMPLE_EXCEL_NAME')}_${getToday('YYYYMMDD')}.xlsx`;
      const { data }: NCPResponse<ArrayBuffer> = await this.$api.getProductsExcelRegisterForm(request);
      saveBinaryDataToFile(data, fileName);
    } catch (e) {
      console.error(e);
    }
  }
}
