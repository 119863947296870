import { KakaoTemplateObjectType, MessageTemplate, MessageTemplateDesc } from '@/types/member';
import { i18n } from '@/main';

// sms
export const messageTemplate = (): MessageTemplate[] => {
  return [
    {
      key: '${mallName}',
      desc: i18n.t('MEMBER.SMS.MALL_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MALL_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_MALL_NAME').toString(),
    },
    {
      key: '${name}',
      desc: i18n.t('MEMBER.SMS.MEMBER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    {
      key: '${pwd}',
      desc: i18n.t('MEMBER.SMS.TEMP_PWD').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_TEMP_PWD').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_TEMP_PWD').toString(),
    },
    {
      key: '${tel}',
      desc: i18n.t('MEMBER.SMS.SERVICE_CENTER_TEL').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_CENTER_TEL').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_CENTER_TEL').toString(),
    },
    {
      key: '${banStartDate}',
      desc: i18n.t('MEMBER.SMS.BAN_START_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: '',
    },
    {
      key: '${banEndDate}',
      desc: i18n.t('MEMBER.SMS.BAN_END_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_EXPIRED').toString(),
    },
    {
      key: '${inactiveDate}',
      desc: i18n.t('MEMBER.SMS.INACTIVE_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: '',
    },
    {
      key: '${oauthType}',
      desc: i18n.t('MEMBER.SMS.OAUTH_TYPE').toString(),
      example: i18n.t('PAYCO_KO').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_OAUTH_TYPE').toString(),
    },
    {
      key: '${smsAgreeDate}',
      desc: i18n.t('MEMBER.SMS.SMS_AGREE_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: '',
    },
    {
      key: '${pointNm}',
      desc: i18n.t('MEMBER.SMS.POINT_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_POINT_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_POINT_NAME').toString(),
    },
    {
      key: '${pointDelMonth}',
      desc: i18n.t('MEMBER.SMS.POINT_DEL_MONTH').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_POINT_DEL_MONTH').toString(),
      note: '',
    },
    {
      key: '${dueDate}',
      desc: i18n.t('MEMBER.SMS.POINT_DEL_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_POINT_DEL_DATE').toString(),
      note: '',
    },
    {
      key: '${pointTotal}',
      desc: i18n.t('MEMBER.SMS.POINT_DEL_TOTAL').toString(),
      example: '27,000',
      note: '',
    },
    {
      key: '${bankName}',
      desc: i18n.t('MEMBER.SMS.BANK_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BANK_NAME').toString(),
      note: '',
    },
    {
      key: '${accountHolder}',
      desc: i18n.t('MEMBER.SMS.ACCOUNT_HOLDER').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    {
      key: '${account}',
      desc: i18n.t('MEMBER.SMS.ACCOUNT').toString(),
      example: '1234567890',
      note: i18n.t('MEMBER.SMS.NOTE_ACCOUNT').toString(),
    },
    {
      key: '${totalPayAmt}',
      desc: i18n.t('MEMBER.SMS.TOTAL_PAY_AMT').toString(),
      example: '27,000',
      note: '',
    },
    {
      key: '${subPayAmt}',
      desc: i18n.t('MEMBER.SMS.SUB_PAY_AMT').toString(),
      example: '2,000',
      note: '',
    },
    {
      key: '${mainPayAmt}',
      desc: i18n.t('MEMBER.SMS.MAIN_PAY_AMT').toString(),
      example: '25,000',
      note: '',
    },
    {
      key: '${depositDate}',
      desc: i18n.t('MEMBER.SMS.DEPOSIT_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_DEPOSIT_DATE').toString(),
    },
    {
      key: '${depositDateShort}',
      desc: i18n.t('MEMBER.SMS.DEPOSIT_DATE_SHORT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_POINT_DEL_DATE').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_DEPOSIT_DATE_SHORT').toString(),
    },
    {
      key: '${shortDepositDateNoYear}',
      desc: i18n.t('MEMBER.SMS.DEPOSIT_DATE_SHORT_EXPECT_YEAR').toString(),
      example: '3/7',
      note: '',
    },
    {
      key: '${product}',
      desc: i18n.t('MEMBER.SMS.PRODUCT_MAIN_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${count}',
      desc: i18n.t('MEMBER.SMS.PRODUCT_COUNT').toString(),
      example: '1',
      note: '',
    },
    {
      key: '${deliProduct}',
      desc: i18n.t('MEMBER.SMS.PRODUCT_MAIN_DELIVERY').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${deliCom}',
      desc: i18n.t('MEMBER.SMS.DELIVERY_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_DELIVERY_NAME').toString(),
      note: '',
    },
    {
      key: '${deliNum}',
      desc: i18n.t('MEMBER.SMS.DELIVERY_NUMBER').toString(),
      example: '12345678',
      note: '',
    },
    {
      key: '${returnProduct}',
      desc: i18n.t('MEMBER.SMS.RETURN_PRODUCT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${id}',
      desc: i18n.t('MEMBER.SMS.MEMBER_ID_SMS').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_ID').toString(),
      note: '',
    },
    {
      key: '${gradeName}',
      desc: i18n.t('MEMBER.SMS.GRADE_NAME').toString(),
      example: 'basic',
      note: '',
    },
    {
      key: '${smsAgreed}',
      desc: i18n.t('MEMBER.SMS.SMS_AGREED_ACCEPT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SMS_AGREED').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SMS_AGREED').toString(),
    },
    {
      key: '${smsAgreedDate}',
      desc: i18n.t('MEMBER.SMS.SMS_AGREED_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SMS_AGREED_DATE').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SMS_AGREED_DATE').toString(),
    },
    {
      key: '${emailAgreed}',
      desc: i18n.t('MEMBER.SMS.EMAIL_AGREED').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_EMAIL_AGREED').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_EMAIL_AGREED').toString(),
    },
    {
      key: '${emailAgreedDate}',
      desc: i18n.t('MEMBER.SMS.EMAIL_AGREED_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_EMAIL_AGREED_DATE').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_EMAIL_AGREED_DATE').toString(),
    },
    {
      key: '${ordererName}',
      desc: i18n.t('MEMBER.SMS.ORDERER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_ORDERER_NAME').toString(),
      note: '',
    },
    {
      key: '${orderNo}',
      desc: i18n.t('MEMBER.SMS.ORDER_NO').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_ORDER_NO').toString(),
      note: '',
    },
    {
      key: '${exchangeProduct}',
      desc: i18n.t('MEMBER.SMS.EXCHANGE_PRODUCT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${domain}',
      desc: i18n.t('MEMBER.SMS.DOMAIN').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_DOMAIN').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_DOMAIN').toString(),
    },
    {
      key: '${authCode}',
      desc: i18n.t('MEMBER.SMS.CERT_CODE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_CERT_CODE').toString(),
      note: '',
    },
  ];
};

export const member = (): MessageTemplate[] => {
  return [
    {
      key: '${mallName}',
      desc: i18n.t('MEMBER.SMS.MALL_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MALL_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_MALL_NAME').toString(),
    },
    {
      key: '${name}',
      desc: i18n.t('MEMBER.SMS.MEMBER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    {
      key: '${gradeName}',
      desc: i18n.t('MEMBER.SMS.GRADE_NAME').toString(),
      example: 'basic',
      note: '',
    },
    {
      key: '${inactiveDate}',
      desc: i18n.t('MEMBER.SMS.INACTIVE_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: '',
    },
  ];
};

export const direct = (): MessageTemplate[] => {
  return [
    {
      key: '${mallName}',
      desc: i18n.t('MEMBER.SMS.MALL_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MALL_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_MALL_NAME').toString(),
    },
  ];
};

export const excel = (): MessageTemplate[] => {
  return [
    {
      key: '${mallName}',
      desc: i18n.t('MEMBER.SMS.MALL_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MALL_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_MALL_NAME').toString(),
    },
    {
      key: '${name}',
      desc: i18n.t('MEMBER.SMS.EXCEL_MEMBER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
  ];
};

export const order = (): MessageTemplate[] => {
  return [
    {
      key: '${mallName}',
      desc: i18n.t('MEMBER.SMS.MALL_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MALL_NAME').toString(),
      note: '',
    },
    {
      key: '${ordererName}',
      desc: i18n.t('MEMBER.SMS.ORDER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    { key: '${orderNo}', desc: i18n.t('MEMBER.SMS.ORDER_NO').toString(), example: '202005171609370000', note: '' },
    {
      key: '${bankName}',
      desc: i18n.t('MEMBER.SMS.BANK_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BANK_NAME').toString(),
      note: '',
    },
    {
      key: '${accountHolder}',
      desc: i18n.t('MEMBER.SMS.ACCOUNT_HOLDER').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    { key: '${account}', desc: i18n.t('MEMBER.SMS.ACCOUNT').toString(), example: '1234567890', note: '' },
    { key: '${totalPayAmt}', desc: i18n.t('MEMBER.SMS.TOTAL_PAY_AMT').toString(), example: '27,000', note: '' },
    { key: '${mainPayAmt}', desc: i18n.t('MEMBER.SMS.MAIN_PAY_AMT').toString(), example: '25,000', note: '' },
    {
      key: '${depositDate}',
      desc: i18n.t('MEMBER.SMS.DEPOSIT_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: '',
    },
    {
      key: '${depositDateShort}',
      desc: i18n.t('MEMBER.SMS.DEPOSIT_DATE_SHORT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_POINT_DEL_DATE').toString(),
      note: '',
    },
    {
      key: '${shortDepositDateNoYear}',
      desc: i18n.t('MEMBER.SMS.DEPOSIT_DATE_SHORT_EXPECT_YEAR').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_DEPOSIT_DATE_SHORT_EXPECT_YEAR').toString(),
      note: '',
    },
    {
      key: '${product}',
      desc: i18n.t('MEMBER.SMS.PRODUCT_MAIN_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: '',
    },
    { key: '${count}', desc: i18n.t('MEMBER.SMS.PRODUCT_COUNT').toString(), example: '1', note: '' },
    {
      key: '${deliProduct}',
      desc: i18n.t('MEMBER.SMS.PRODUCT_MAIN_DELIVERY').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: '',
    },
    {
      key: '${deliCom}',
      desc: i18n.t('MEMBER.SMS.DELIVERY_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_DELIVERY_NAME').toString(),
      note: '',
    },
    { key: '${deliNum}', desc: i18n.t('MEMBER.SMS.DELIVERY_NUMBER').toString(), example: '12345678', note: '' },
    {
      key: '${returnProduct}',
      desc: i18n.t('MEMBER.SMS.RETURN_PRODUCT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: '',
    },
    {
      key: '${exchangeProduct}',
      desc: i18n.t('MEMBER.SMS.EXCHANGE_PRODUCT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: '',
    },
  ];
};

// mail
export const mailTemplate = (): MessageTemplate[] => {
  return [
    {
      key: '${mall.mallName}',
      desc: i18n.t('MEMBER.SMS.MALL_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MALL_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SHOP_MALL_NAME').toString(),
    },
    {
      key: '${mall.pcWebUrl}',
      desc: i18n.t('MEMBER.SMS.PC_WEB_DOMAIN').toString(),
      example: 'www.abcmall.com',
      note: i18n.t('MEMBER.SMS.NOTE_PC_WEB_DOMAIN').toString(),
    },
    {
      key: '${mall.mobileWebUrl}',
      desc: i18n.t('MEMBER.SMS.MOBILE_WEB_DOMAIL').toString(),
      example: 'm.abcmall.com',
      note: i18n.t('MEMBER.SMS.NOTE_MOBILE_WEB_DOMAIN').toString(),
    },
    {
      key: '${member.memberName}',
      desc: i18n.t('MEMBER.SMS.MEMBER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_MEMBER_NAME').toString(),
    },
    { key: '${member.memberId}', desc: i18n.t('MEMBER.SMS.MEMBER_ID').toString(), example: 'hongzzang', note: '' },
    {
      key: '${memberGrade}',
      desc: i18n.t('MEMBER.SMS.GRADE_NAME').toString(),
      example: 'basic',
      note: '',
    },
    {
      key: '${certNo}',
      desc: i18n.t('MEMBER.SMS.CERT_NO').toString(),
      example: 'ABCD1234',
      note: i18n.t('MEMBER.SMS.NOTE_CERT_NO').toString(),
    },
    {
      key: '${expireYmdt}',
      desc: i18n.t('MEMBER.SMS.EXPIRE_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${mailSendYmdt}',
      desc: i18n.t('MEMBER.SMS.CHANGE_PWD_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${tempPassword}',
      desc: i18n.t('MEMBER.SMS.NOT_MEMBER_TEMP_PWD').toString(),
      example: 'membermemberName',
      note: i18n.t('MEMBER.SMS.EXAMPLE_TEMP_PWD_DETAIL').toString(),
    },
    { key: '${url}', desc: i18n.t('MEMBER.SMS.RESET_PWD_LINK').toString(), example: 'http://www.abc.mall~', note: '' },
    {
      key: '${code}',
      desc: i18n.t('MEMBER.SMS.CERT_EMAIL').toString(),
      example: 'ABCD1234',
      note: i18n.t('MEMBER.SMS.NOTE_CERT_NO').toString(),
    },
    {
      key: '${prohibitionBeginYmd}',
      desc: i18n.t('MEMBER.SMS.BAN_START_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: '',
    },
    {
      key: '${prohibitionExpireYmd}',
      desc: i18n.t('MEMBER.SMS.BAN_END_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BAN_START_DATE').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_EXPIRED').toString(),
    },
    {
      key: '${prohibitionReason}',
      desc: i18n.t('MEMBER.SMS.PROHIBITION_REASON').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PROHIBITION_REASON').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_REASON').toString(),
    },
    {
      key: '${dormantDueYmdt}',
      desc: i18n.t('MEMBER.SMS.INACTIVE_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${oauthType}',
      desc: i18n.t('MEMBER.SMS.OAUTH_TYPE').toString(),
      example: i18n.t('PAYCO_KO').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_OAUTH_TYPE').toString(),
    },
    {
      key: '${expelYmdt}',
      desc: i18n.t('MEMBER.SMS.EXPEL_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${expelReason}',
      desc: i18n.t('MEMBER.SMS.EXPEL_REASON').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_EXPEL_REASON').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_REASON').toString(),
    },
    {
      key: '${smsAgreed}',
      desc: i18n.t('MEMBER.SMS.SMS_AGREED').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_AGREED').toString(),
      note: '',
    },
    {
      key: '${smsAgreeYmdt}',
      desc: i18n.t('MEMBER.SMS.SMS_AGREE_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${directMailAgreed}',
      desc: i18n.t('MEMBER.SMS.DIRECT_MAIL_AGREED').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_AGREED').toString(),
      note: '',
    },
    {
      key: '${directMailAgreeYmdt}',
      desc: i18n.t('MEMBER.SMS.DIRECT_MAIL_AGREED_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${pushNotificationAgreed}',
      desc: i18n.t('MEMBER.SMS.PUSH_NOTIFICATION_AGREED').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_AGREED').toString(),
      note: '',
    },
    {
      key: '${pushNotificationAgreeYmdt}',
      desc: i18n.t('MEMBER.SMS.PUSH_NOTIFICATION_AGREED_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${notificationDate}',
      desc: i18n.t('MEMBER.SMS.NOTIFICATION_DATE').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_NOTIFICATION_DATE').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_NOTIFICATION_DATE').toString(),
    },
    {
      key: '${accumulationName}',
      desc: i18n.t('MEMBER.SMS.ACCUMULATION_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_POINT_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_POINT_NAME').toString(),
    },
    {
      key: '${accumulations}',
      desc: i18n.t('MEMBER.SMS.ACCUMULATIONS').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_ACCUMULATIONS').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_ACCUMULATIONS').toString(),
    },
    {
      key: '${accumulation.expireYmdt}',
      desc: i18n.t('MEMBER.SMS.ACCUMULATION_EXPIRE_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${accumulation.accumulationAmt}',
      desc: i18n.t('MEMBER.SMS.ACCUMULATION_AMT').toString(),
      example: '27,000',
      note: '',
    },
    {
      key: '${orderMail.bankName}',
      desc: i18n.t('MEMBER.SMS.BANK_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_BANK_NAME_2').toString(),
      note: '',
    },
    {
      key: '${orderMail.bankDepositorName}',
      desc: i18n.t('MEMBER.SMS.ACCOUNT_HOLDER').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    {
      key: '${orderMail.bankAccount}',
      desc: i18n.t('MEMBER.SMS.BANK_ACCOUNT').toString(),
      example: '123-456-789',
      note: '',
    },
    {
      key: '${orderMail.limitPayYmdt}',
      desc: i18n.t('MEMBER.SMS.LIMIT_PAY_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_DEPOSIT_DATE').toString(),
    },
    {
      key: '${orderMail.registerYmdt}',
      desc: i18n.t('MEMBER.SMS.REGISTER_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${depositCompleteDateTime}',
      desc: i18n.t('MEMBER.SMS.DEPOSIT_DATETIME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    { key: '${orderMail.orderNo}', desc: i18n.t('MEMBER.SMS.ORDER_NO').toString(), example: '123456789', note: '' },
    {
      key: '${orderMail.orderName}',
      desc: i18n.t('MEMBER.SMS.ORDER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    {
      key: '${orderMail.orderProductOptions.productName}',
      desc: i18n.t('MEMBER.SMS.PRODUCT_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${orderMail.orderProductOptions.displayOptionName}',
      desc: i18n.t('MEMBER.SMS.OPTION_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_OPTION_NAME').toString(),
      note: '',
    },
    {
      key: '${orderProductOption.userInputText}',
      desc: i18n.t('MEMBER.SMS.USER_INPUT_TEXT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_USER_INPUT_TEXT').toString(),
      note: '',
    },
    {
      key: '${orderMail.orderProductOptions.immediateDiscountedPrice}',
      desc: i18n.t('MEMBER.SMS.DISCOUNTED_PRICE').toString(),
      example: '25,000',
      note: '',
    },
    {
      key: '${orderMail.orderProductOptions.orderCnt}',
      desc: i18n.t('MEMBER.SMS.ORDER_CNT').toString(),
      example: '2',
      note: '',
    },
    {
      key: '${orderMail.orderProductOptions.immediateDiscountedAmt}',
      desc: i18n.t('MEMBER.SMS.IMMEDIATE_DISCOUNTED_AMT').toString(),
      example: '50,000',
      note: '',
    },
    {
      key: '${orderMail.totalPayAmt}',
      desc: i18n.t('MEMBER.SMS.TOTAL_PAY_AMT').toString(),
      example: '55,000',
      note: '',
    },
    { key: '${orderMail.subPayAmt}', desc: i18n.t('MEMBER.SMS.SUB_PAY_AMT').toString(), example: '5,000', note: '' },
    { key: '${orderMail.mainPayAmt}', desc: i18n.t('MEMBER.SMS.MAIN_PAY_AMT').toString(), example: '50,000', note: '' },
    {
      key: '${orderMail.totalDeliveryAmt}',
      desc: i18n.t('MEMBER.SMS.DELIVERY_AMT').toString(),
      example: '5,000',
      note: '',
    },
    {
      key:
        '${orderMail.lastAdditionalDiscountAmt + orderMail.lastCartCouponDiscountAmt + orderMail.lastProductCouponDiscountAmt + orderMail.lastDeliveryCouponDiscountAmt}',
      desc: i18n.t('MEMBER.SMS.DISCOUNT_AMT').toString(),
      example: '25,000',
      note: '',
    },
    {
      key: '${orderMail.payTypeLabel}',
      desc: i18n.t('MEMBER.SMS.PAY_TYPE_LABEL').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PAY_TYPE_LABEL').toString(),
      note: '',
    },
    {
      key: '${orderMail.receiverName}',
      desc: i18n.t('MEMBER.SMS.RECEIVER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_MEMBER_NAME').toString(),
      note: '',
    },
    {
      key: '${orderMail.receiverContact1}',
      desc: i18n.t('MEMBER.SMS.RECEIVER_CONTACT').toString(),
      example: '010-1234-5678',
      note: '',
    },
    {
      key: '${orderMail.address}',
      desc: i18n.t('MEMBER.SMS.RECEIVER_ADDRESS').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_RECEIVER_ADDRESS').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    { key: '${orderProductOption.orderCnt}', desc: i18n.t('MEMBER.SMS.ORDER_CNT').toString(), example: '2', note: '' },
    {
      key: '${orderMail.deliveryCompanyTypeLabel}',
      desc: i18n.t('MEMBER.SMS.DELIVERY_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_DELIVERY_NAME').toString(),
      note: '',
    },
    {
      key: '${orderMail.invoiceNo}',
      desc: i18n.t('MEMBER.SMS.DELIVERY_NUMBER').toString(),
      example: '12345678',
      note: '',
    },
    {
      key: '${orderMail.refundProductAmt}',
      desc: i18n.t('MEMBER.SMS.REFUND_PRODUCT_AMT').toString(),
      example: '25,000',
      note: '',
    },
    {
      key: '${orderMail.refundDeliveryAmt}',
      desc: i18n.t('MEMBER.SMS.REFUND_DELIVERY_AMT').toString(),
      example: '5,000',
      note: '',
    },
    {
      key: '${orderMail.refundSubtractionAmt}',
      desc: i18n.t('MEMBER.SMS.REFUND_SUBTRACTION_AMT').toString(),
      example: '5,000',
      note: '',
    },
    {
      key: '${orderMail.refundPayAmt}',
      desc: i18n.t('MEMBER.SMS.REFUND_AMT').toString(),
      example: '20,000',
      note: i18n.t('MEMBER.SMS.EXAMPLE_REFUND_AMT').toString(),
    },
    {
      key: '${orderMail.refundSubPayAmt}',
      desc: i18n.t('MEMBER.SMS.REFUND_SUB_PAY_AMT').toString(),
      example: '3,000',
      note: '',
    },
    {
      key: '${orderMail.extraData}',
      desc: i18n.t('MEMBER.SMS.ADDITIONAL_MALL_INFO').toString(),
      example: '',
      note: '',
    },
    {
      key: '${inquiry.inquiryContent}',
      desc: i18n.t('MEMBER.SMS.INQUIRY_CONTENT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_INQUIRY_CONTENT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${answerContent}',
      desc: i18n.t('MEMBER.SMS.ANSWER_CONTENT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_ANSWER_CONTENT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${inquiryCd}',
      desc: i18n.t('MEMBER.SMS.INQUIRY_CD').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_INQUIRY_CD').toString(),
      note: '',
    },
    {
      key: '${registerYmdt}',
      desc: i18n.t('MEMBER.SMS.INQUIRY_REGISTER_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    { key: '${mallProductNo}', desc: i18n.t('MEMBER.SMS.MALL_PRODUCT_NO').toString(), example: 'A12345', note: '' },
    {
      key: '${productName}',
      desc: i18n.t('MEMBER.SMS.PRODUCT_INQUIRY_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_PRODUCT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${content}',
      desc: i18n.t('MEMBER.SMS.INQUIRY_PRODUCT_CONTENT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_INQUIRY_PRODUCT_CONTENT').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${replyContent}',
      desc: i18n.t('MEMBER.SMS.INQUIRY_PRODUCT_ANSWER').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_INQUIRY_PRODUCT_ANSWER').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_PRODUCT').toString(),
    },
    {
      key: '${replyRegisterYmdt}',
      desc: i18n.t('MEMBER.SMS.INQUIRY_REPLY_YMDT').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_YMDT').toString(),
      note: '',
    },
    {
      key: '${mall.serviceCenterPhoneNo}',
      desc: i18n.t('MEMBER.SMS.MALL_SERVICE_CENTER_NO').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_CENTER_TEL').toString(),
      note: i18n.t('MEMBER.SMS.EXAMPLE_MALL_SERVICE_CENTER_NO').toString(),
    },
    {
      key: '${service.companyName}',
      desc: i18n.t('MEMBER.SMS.SERVICE_COMPANY_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_COMPANY_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_COMPANY_NAME').toString(),
    },
    {
      key: '${service.representativeName}',
      desc: i18n.t('MEMBER.SMS.SERVICE_REPRESENTATIVE_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_REPRESENTATIVE_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_REPRESENTATIVE_NAME').toString(),
    },
    {
      key: '${service.businessRegistrationNo}',
      desc: i18n.t('MEMBER.SMS.SERVICE_BUSINESS_REGISTRATION_NO').toString(),
      example: '123-12-12345',
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_BUSINESS_REGISTRATION_NO').toString(),
    },
    {
      key: '${service.onlineMarketingBusinessDeclarationNo}',
      desc: i18n.t('MEMBER.SMS.SERVICE_ONLINE_MARKETING_BUSINESS_DECLARATION_NO').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_ONLINE_MARKETING_BUSINESS_DECLARATION_NO').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_ONLINE_MARKETING_BUSINESS_DECLARATION_NO').toString(),
    },
    {
      key: '${service.privacyManagerName}',
      desc: i18n.t('MEMBER.SMS.SERVICE_PRIVACY_MANAGER_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_REPRESENTATIVE_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_PRIVACY_MANAGER_NAME').toString(),
    },
    {
      key: '${service.zipCd}',
      desc: i18n.t('MEMBER.SMS.SERVICE_ZIP_CD').toString(),
      example: '8393',
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_ADDRESS').toString(),
    },
    {
      key: '${service.address}',
      desc: i18n.t('MEMBER.SMS.SERVICE_NEW_ADDRESS').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_NEW_ADDRESS').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_ADDRESS').toString(),
    },
    {
      key: '${service.addressDetail}',
      desc: i18n.t('MEMBER.SMS.SERVICE_NEW_ADDRESS_DETAIL').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_COMPANY_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_ADDRESS').toString(),
    },
    {
      key: '${service.jibunAddress}',
      desc: i18n.t('MEMBER.SMS.SERVICE_JIBUN_ADDRESS').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_JIBUN_ADDRESS').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_ADDRESS').toString(),
    },
    {
      key: '${service.jibunAddressDetail}',
      desc: i18n.t('MEMBER.SMS.SERVICE_JIBUN_ADDRESS_DETAIL').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_SERVICE_COMPANY_NAME').toString(),
      note: i18n.t('MEMBER.SMS.NOTE_SERVICE_ADDRESS').toString(),
    },
    {
      key: '${adminName}',
      desc: i18n.t('MEMBER.SMS.ADMIN_NAME').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_ADMIN_NAME').toString(),
      note: '',
    },
    {
      key: '${admin.adminId}',
      desc: i18n.t('MEMBER.SMS.ADMIN_ID').toString(),
      example: i18n.t('MEMBER.SMS.EXAMPLE_ADMIN_ID').toString(),
      note: '',
    },
  ];
};

// terms
export const getTermsMessageTemplate = (): MessageTemplate[] => {
  return [
    {
      key: window.$t('${service.companyName}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_COMPANY_NAME_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_COMPANY_NAME_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_COMPANY_NAME_NOTE'),
    },
    {
      key: window.$t('${service.representativeName}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_REPRESENTATIVE_NAME_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_REPRESENTATIVE_NAME_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_REPRESENTATIVE_NAME_NOTE'),
    },
    {
      key: window.$t('${service.businessRegistrationNo}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_BESINESS_REGISTRATION_NO_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_BESINESS_REGISTRATION_NO_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_BESINESS_REGISTRATION_NO_NOTE'),
    },
    {
      key: window.$t('${service.onlineMarketingBusinessDeclarationNo}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_ONLINE_MARKETING_BUSINESS_DECLATION_NO_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_ONLINE_MARKETING_BUSINESS_DECLATION_NO_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_ONLINE_MARKETING_BUSINESS_DECLATION_NO_NOTE'),
    },
    {
      key: window.$t('${service.privacyManagerName}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_PRIVACY_MANAGER_NAME_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_PRIVACY_MANAGER_NAME_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_PRIVACY_MANAGER_NAME_NOTE'),
    },
    {
      key: window.$t('${service.zipCd}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_ZIP_CD_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_ZIP_CD_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_ZIP_CD_NOTE'),
    },
    {
      key: window.$t('${service.address}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_ADDRESS_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_ADDRESS_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_ADDRESS_NOTE'),
    },
    {
      key: window.$t('${service.addressDetail}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_ADDRESS_DETAIL_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_ADDRESS_DETAIL_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_ADDRESS_DETAIL_NOTE'),
    },
    {
      key: window.$t('${service.jibunAddress}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_JIBUN_ADDRESS_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_JIBUN_ADDRESS_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_JIBUN_ADDRESS_NOTE'),
    },
    {
      key: window.$t('${service.jibunAddressDetail}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_JIBUN_ADDRESS_DETAIL_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_JIBUN_ADDRESS_DETAIL_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_JIBUN_ADDRESS_DETAIL_NOTE'),
    },
    {
      key: window.$t('${mallName}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_MALL_NAME_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_MALL_NAME_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_MALL_NAME_NOTE'),
    },
    {
      key: window.$t('${webDomain}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_WEB_DOMAIN_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_WEB_DOMAIN_EXAMPLE'),
      note: '',
    },
    {
      key: window.$t('${termsStartDate}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_TERMS_START_DATE_DESC'),
      example: window.$t('CONFIGURATION.TERMS.RT_TERMS_START_DATE_EXAMPLE'),
      note: window.$t('CONFIGURATION.TERMS.RT_TERMS_START_DATE_NOTE'),
    },
    {
      key: window.$t('${partnerName}'),
      desc: window.$t('CONFIGURATION.TERMS.RT_PARTNER_NAME_DESC'),
      example: '',
      note: window.$t('CONFIGURATION.TERMS.RT_PARTNER_NAME_NOTE'),
    },
    {
      key: window.$t('${service.representativePhoneNo}'),
      desc: window.$t('CONFIGURATION.TERMS.REPRESENTATIVE_PHONE'),
      example: window.$t('CONFIGURATION.TERMS.REPRESENTATIVE_PHONE_EX'),
      note: window.$t('CONFIGURATION.TERMS.REPRESENTATIVE_PHONE_NOTE'),
    },
    {
      key: window.$t('${service.representativeEmail}'),
      desc: window.$t('CONFIGURATION.TERMS.REPRESENTATIVE_EMAIL'),
      example: window.$t('CONFIGURATION.TERMS.REPRESENTATIVE_EMAIL_EX'),
      note: window.$t('CONFIGURATION.TERMS.REPRESENTATIVE_EMAIL_NOTE'),
    },
    {
      key: window.$t('${service.businessType}'),
      desc: window.$t('CONFIGURATION.TERMS.BUSINESS_TYPE'),
      example: window.$t('CONFIGURATION.TERMS.BUSINESS_TYPE_EX'),
      note: window.$t('CONFIGURATION.TERMS.BUSINESS_TYPE_NOTE'),
    },
    {
      key: window.$t('${service.businessCondition}'),
      desc: window.$t('CONFIGURATION.TERMS.BUSINESS_CONDITION'),
      example: window.$t('CONFIGURATION.TERMS.BUSINESS_CONDITION_EX'),
      note: window.$t('CONFIGURATION.TERMS.BUSINESS_CONDITION_NOTE'),
    },
    {
      key: window.$t('${service.privacyManagerPosition}'),
      desc: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_POSITION'),
      example: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_POSITION_EX'),
      note: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_POSITION_NOTE'),
    },
    {
      key: window.$t('${service.privacyManagerTeam}'),
      desc: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_TEAM'),
      example: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_TEAM_EX'),
      note: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_TEAM_NOTE'),
    },
    {
      key: window.$t('${service.privacyManagerEmail}'),
      desc: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_EMAIL'),
      example: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_EMAIL_EX'),
      note: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_EMAIL_NOTE'),
    },
    {
      key: window.$t('${service.privacyManagerPhoneNo}'),
      desc: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_PHONE'),
      example: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_PHONE_EX'),
      note: window.$t('CONFIGURATION.TERMS.PRIVACY_MANAGER_PHONE_NOTE'),
    },
  ];
};

// kakao
export const getKakaoMemberTemplate = (): MessageTemplateDesc => {
  return [
    { key: '#{mallName}', desc: i18n.t('MEMBER.KAKAO.MALL_NAME').toString() },
    { key: '#{domain}', desc: i18n.t('MEMBER.KAKAO.DOMAIN').toString() },
    { key: '#{name}', desc: i18n.t('MEMBER.KAKAO.NAME').toString() },
    { key: '#{id}', desc: i18n.t('MEMBER.KAKAO.MEMBER_ID').toString() },
    { key: '#{pwd}', desc: i18n.t('MEMBER.KAKAO.TEMP_PASSWORD').toString() },
    { key: '#{tel}', desc: i18n.t('MEMBER.KAKAO.TEL').toString() },
    { key: '#{banStartDate}', desc: i18n.t('MEMBER.KAKAO.BAN_START_DATE').toString() },
    { key: '#{banEndDate}', desc: i18n.t('MEMBER.KAKAO.BAN_END_DATE').toString() },
    { key: '#{inactiveDate}', desc: i18n.t('MEMBER.KAKAO.INACTIVE_DATE').toString() },
    { key: '#{oauthType}', desc: i18n.t('MEMBER.KAKAO.OAUTH_TYPE').toString() },
    { key: '#{smsAgreed}', desc: i18n.t('MEMBER.KAKAO.SMS_AGREED').toString() },
    { key: '#{smsAgreeDate}', desc: i18n.t('MEMBER.KAKAO.SMS_AGREE_DATE').toString() },
    { key: '#{emailAgreed}', desc: i18n.t('MEMBER.KAKAO.EMAIL_AGREED').toString() },
    { key: '#{emailAgreedDate}', desc: i18n.t('MEMBER.KAKAO.EMAIL_AGREE_DATE').toString() },
    { key: '#{gradeName}', desc: i18n.t('MEMBER.KAKAO.GRADE_NAME').toString() },
    { key: '#{pointNm}', desc: i18n.t('MEMBER.KAKAO.POINT_NAME').toString() },
    { key: '#{pointDelMonth}', desc: i18n.t('MEMBER.KAKAO.POINT_DEL_MONTH').toString() },
    { key: '#{dueDate}', desc: i18n.t('MEMBER.KAKAO.DUE_DATE').toString() },
    { key: '#{pointTotal}', desc: i18n.t('MEMBER.KAKAO.POINT_TOTAL').toString() },
    { key: '#{authCode}', desc: i18n.t('MEMBER.KAKAO.CERT_CODE').toString() },
  ];
};

export const getKakaoDeliveryTemplate = (): MessageTemplateDesc => {
  return [
    { key: '#{mallName}', desc: i18n.t('MEMBER.KAKAO.MALL_NAME').toString() },
    { key: '#{domain}', desc: i18n.t('MEMBER.KAKAO.DOMAIN').toString() },
    { key: '#{ordererName}', desc: i18n.t('MEMBER.KAKAO.ORDER_NAME').toString() },
    { key: '#{orderNo}', desc: i18n.t('MEMBER.KAKAO.ORDER_NO').toString() },
    { key: '#{bankName}', desc: i18n.t('MEMBER.KAKAO.BANK_NAME').toString() },
    { key: '#{accountHolder}', desc: i18n.t('MEMBER.KAKAO.ACCOUNT_HOLDER').toString() },
    { key: '#{account}', desc: i18n.t('MEMBER.KAKAO.ACCOUNT').toString() },
    { key: '#{totalPayAmt}', desc: i18n.t('MEMBER.KAKAO.TOTAL_PAY_AMOUNT').toString() },
    { key: '#{subPayAmt}', desc: i18n.t('MEMBER.KAKAO.SUB_PAY_AMOUNT').toString() },
    { key: '#{mainPayAmt}', desc: i18n.t('MEMBER.KAKAO.MAIN_PAY_AMOUNT').toString() },
    { key: '#{depositDate}', desc: i18n.t('MEMBER.KAKAO.DEPOSIT_DATE').toString() },
    { key: '#{depositDateShort}', desc: i18n.t('MEMBER.KAKAO.DEPOSIT_DATE_SHORT').toString() },
    { key: '#{shortDepositDateNoYear}', desc: i18n.t('MEMBER.KAKAO.DEPOSIT_DATE_NO_YEAR').toString() },
    { key: '#{product}', desc: i18n.t('MEMBER.KAKAO.PRODUCT').toString() },
    { key: '#{count}', desc: i18n.t('MEMBER.KAKAO.PRODUCT_COUNT').toString() },
    { key: '#{deliProduct}', desc: i18n.t('MEMBER.KAKAO.DELIVERY_PRODUCT').toString() },
    { key: '#{deliCom}', desc: i18n.t('MEMBER.KAKAO.DELIVERY_COM').toString() },
    { key: '#{deliNum}', desc: i18n.t('MEMBER.KAKAO.DELIVERY_NUM').toString() },
    { key: '#{returnProduct}', desc: i18n.t('MEMBER.KAKAO.RETURN_PRODUCT').toString() },
    { key: '#{exchangeProduct}', desc: i18n.t('MEMBER.KAKAO.EXCHANGE_PRODUCT').toString() },
  ];
};

export const getKakaoInquiryTemplate = (): MessageTemplateDesc => {
  return [
    { key: '#{mallName}', desc: i18n.t('MEMBER.KAKAO.MALL_NAME').toString() },
    { key: '#{domain}', desc: i18n.t('MEMBER.KAKAO.DOMAIN').toString() },
    { key: '#{name}', desc: i18n.t('MEMBER.KAKAO.NAME').toString() },
  ];
};

export const getKakaoAdminTemplate = (): MessageTemplateDesc => {
  return [
    { key: '#{mallName}', desc: i18n.t('MEMBER.KAKAO.MALL_NAME').toString() },
    { key: '#{domain}', desc: i18n.t('MEMBER.KAKAO.DOMAIN').toString() },
    { key: '#{name}', desc: i18n.t('MEMBER.KAKAO.NAME').toString() },
    { key: '#{ordererName}', desc: i18n.t('MEMBER.KAKAO.ORDER_NAME').toString() },
    { key: '#{orderNo}', desc: i18n.t('MEMBER.KAKAO.ORDER_NO').toString() },
    { key: '#{mainPayAmt}', desc: i18n.t('MEMBER.KAKAO.MAIN_PAY_AMOUNT').toString() },
    { key: '#{product}', desc: i18n.t('MEMBER.KAKAO.PRODUCT').toString() },
    { key: '#{count}', desc: i18n.t('MEMBER.KAKAO.PRODUCT_COUNT').toString() },
  ];
};

export const kakaoTemplateObject: KakaoTemplateObjectType = {
  MEMBER: getKakaoMemberTemplate,
  ORDER_DELIVERY: getKakaoDeliveryTemplate,
  INQUIRY: getKakaoInquiryTemplate,
  ADMIN: getKakaoAdminTemplate,
};
