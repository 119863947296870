










































































































































































































































































































































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  Mall,
  NCPResponse,
  PutAccumulationsConfigurationsRequest,
  GetAccumulationConfigurations,
} from 'ncp-api-supporter';
import {
  getRadioBoxOption,
  initConfig,
  notificationAvailableInfo,
  arrAccumulationReserveTime,
  reviewAccumulation,
  validationCondition,
  accumulationToolTipOption,
} from '@/const/contents/accumulationSetting';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { getSelectMonths } from '@/utils/dateFormat';
import { isValidate } from '@/utils/validate';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import NotificationGuide from '@/components/member/NotificationGuide.vue';
import { getCurrentMallNo } from '@/utils/mall';

@Component({
  components: { NotificationGuide, CheckboxGroup, RadioGroup, SelectBox, TextInput, ToolTip },
})
export default class AccumulationSetting extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  @Ref() private readonly notificationGuideSection!: NotificationGuide;
  @Ref() private readonly accumulationRateInput!: TextInput;
  @Ref() private readonly signUpInput!: TextInput;
  @Ref() private readonly birthdayInput!: TextInput;
  @Ref() private readonly productPriceMinimumInput!: TextInput;
  @Ref() private readonly minimumUsePriceInput!: TextInput;
  @Ref() private readonly maximumUseRateInput!: TextInput;

  private accumulationConfig = initConfig(getCurrentMallNo(this));
  private accumulationToolTipOption = accumulationToolTipOption;
  private validPeriod = false;
  private validPeriodYear = 1;
  private validPeriodMonth = 0;
  private validityYearSelectOption = getSelectMonths(this.$t('YEAR').toString(), 0, 5);
  private validityMonthSelectOption = getSelectMonths(this.$t('MONTHS').toString(), 0, 11);

  private readonly productAccumulationOption = getRadioBoxOption('radio_product_accumulation');
  private readonly memberAccumulationOption = getRadioBoxOption('radio_member_accumulation');
  private readonly signupAccumulationOption = getRadioBoxOption('radio_sign_up_accumulation');
  private readonly reviewAccumulationOption = getRadioBoxOption('radio_review_accumulation_usable');
  private readonly birthdayAccumulationOption = getRadioBoxOption('radio_birth_accumulation_usable');
  private readonly productMinLimitOption = getRadioBoxOption('radio_product_min_limit_usable', 'LIMIT_PRICE', true);
  private readonly minUsePriceLimitOption = getRadioBoxOption('radio_min_use_price', 'LIMIT_ACCUMULATION', true);
  private readonly maxUsePriceLimitOption = getRadioBoxOption('radio_max_use_rate', 'LIMIT_PRICE_OF', true);

  private notificationMethodType = 'SMS, EMAIL';
  private notificationAvailableInfo = notificationAvailableInfo();
  private arrAccumulationReserveTime = arrAccumulationReserveTime;
  private reviewAccumulation = reviewAccumulation;

  @Watch('$route')
  onRouteChanged() {
    this.init();
  }

  mounted() {
    this.init();
  }

  private get selectedMallNo() {
    return getCurrentMallNo(this);
  }

  @Watch('validPeriodYear')
  private changeValidPeriodYear(_: number, prev: number) {
    this.validatePeriod('validPeriodYear', prev);
  }

  @Watch('validPeriodMonth')
  private changeValidPeriodMonth(_: number, prev: number) {
    this.validatePeriod('validPeriodMonth', prev);
  }

  private validatePeriod(type: 'validPeriodMonth' | 'validPeriodYear', prevValue: number) {
    if (this.validPeriodYear === 0 && this.validPeriodMonth === 0) {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_PERIOD'));
      this.$nextTick(() => (this[type] = prevValue));
      return;
    }
    const accumulationValidPeriod = this.validPeriodYear * 12 + this.validPeriodMonth;
    this.accumulationConfig.basic = { ...this.accumulationConfig.basic, accumulationValidPeriod };
  }

  private checkUsable(usable: boolean, type: 'member' | 'product'): void {
    if (usable) return;
    if (confirm(this.$t(`ACCUMULATION.CONFIG_MODIFY.EXCLUDE_${type.toUpperCase()}`).toString())) return;
    this.$nextTick(() => (this.accumulationConfig.basic[`${type}AccumulationUsable`] = true));
  }

  private changeProductAccumulationUsable(usable: boolean): void {
    this.checkUsable(usable, 'product');
  }

  private changeMemberAccumulationUsable(usable: boolean): void {
    this.checkUsable(usable, 'member');
  }

  /**
   * init data & render (when view create and url change)
   */
  private init(): void {
    this.accumulationConfig = initConfig(getCurrentMallNo(this));
    this.getAccumulationsConfigurations();

    this.notificationAvailableInfo.map(item => {
      const channels = this.accumulationConfig.basic?.expireNotificationChannels;
      if (channels && channels.some(channel => channel === item.key)) {
        item.bChecked = true;
      }
    });

    throwBottomNavigation({
      buttons: [{ type: 'right', key: 'save', color: 'red', text: this.$t('SAVE') }],
      onClick: this.putAccumulationsConfigurations,
    });
  }

  /**
   * get accumulation configuration
   */
  private async getAccumulationsConfigurations(): Promise<void> {
    const { data }: NCPResponse<GetAccumulationConfigurations[]> = await this.$api.getAccumulationsConfigurations({
      params: { mallNo: getCurrentMallNo(this) },
    });

    if (data && data.length > 0) {
      this.accumulationConfig = { ...data[0] };
    }
    if (
      this.accumulationConfig.basic?.accumulationValidPeriod &&
      this.accumulationConfig.basic.accumulationValidPeriod > 0
    ) {
      this.validPeriod = true;
      this.validPeriodYear = Math.floor((this.accumulationConfig.basic?.accumulationValidPeriod || 0) / 12);
      this.validPeriodMonth = (this.accumulationConfig.basic?.accumulationValidPeriod || 0) % 12;
    }
    this.accumulationConfig.basic.accumulationValidPeriod = this.accumulationConfig.basic.accumulationValidPeriod || 12;
    if (!this.accumulationConfig.payment.signUpAccumulationUsable) {
      this.accumulationConfig.payment.signUpAccumulation = null;
    }
    if (!this.accumulationConfig.payment.reviewAccumulationUsable) {
      this.accumulationConfig.payment.reviewAccumulation = {
        photoReviewsAccumulation: null,
        photoReviewsLength: null,
        reviewsAccumulation: null,
        reviewsLength: null,
      };
    }
    if (!this.accumulationConfig.payment.birthdayAccumulationUsable) {
      this.accumulationConfig.payment.birthdayAccumulation = null;
    }
    if (!this.accumulationConfig.use.productPriceMinimumLimitUsable) {
      this.accumulationConfig.use.productPriceMinimum = null;
    }
    if (!this.accumulationConfig.use.minimumUsePriceLimitUsable) {
      this.accumulationConfig.use.minimumUsePrice = null;
    }
    if (!this.accumulationConfig.use.maximumUseRateLimitUsable) {
      this.accumulationConfig.use.maximumUseRate = null;
    }
  }

  private async putAccumulationsConfigurations(): Promise<void> {
    if (!this.validateSave()) return;

    if (!this.validPeriod) this.accumulationConfig.basic.accumulationValidPeriod = 0;
    if (!this.accumulationConfig.basic.accumulationName) {
      this.accumulationConfig.basic.accumulationName = this.$t(
        'ACCUMULATION.CONFIG_MODIFY.LIMIT_ACCUMULATION',
      ).toString();
    }
    if (!this.accumulationConfig.basic.accumulationUnit) {
      this.accumulationConfig.basic.accumulationUnit = this.$t('WON').toString();
    }
    if (!this.accumulationConfig.payment.reviewAccumulationUsable) {
      delete this.accumulationConfig.payment.reviewAccumulation;
    }
    const requestData: PutAccumulationsConfigurationsRequest = { data: { ...this.accumulationConfig } };
    const response = await this.$api.putAccumulationsConfigurations(requestData);
    if (response && response.status === 204) {
      alert(this.$t('ALERT_SAVED'));
    }
  }

  private changeValidPeriod(bUnlimited: boolean) {
    if (this.isUnlimitedValidPeriod() && !bUnlimited) {
      this.accumulationConfig.basic = { ...this.accumulationConfig.basic, accumulationValidPeriod: 12 };
    } else if (!this.isUnlimitedValidPeriod() && bUnlimited) {
      this.accumulationConfig.basic = { ...this.accumulationConfig.basic, accumulationValidPeriod: 0 };
    }
  }
  private isUnlimitedValidPeriod(): boolean {
    return this.accumulationConfig.basic?.accumulationValidPeriod === 0;
  }

  private isShowNotificationGuide(): boolean {
    return this.notificationAvailableInfo.some(
      ({ bChecked, bUsed, bAutoTemplate }) => bChecked && (!bUsed || !bAutoTemplate),
    );
  }

  // 상품 구매 적립금 - 기본 적립률
  private checkRegexAccumulationRate(value: string): string {
    const realNumRegex = /\d{1,3}(\.\d)?/g;
    if (value === '.' || value === '') return '';

    const inputValue = value.slice(-1) === '.' ? value.slice(0, -1) : value;
    if (value === '100.') return value.substring(0, value.length - 1);
    if (value.match(realNumRegex)[0] === inputValue) return value;
    return value.substring(0, value.length - 1);
  }

  private validateAccumulationRate(value: string): string {
    const point = value.split('.');
    const notAccessChar = point.length >= 3 || (point[1] && point[1].length > 1);
    const overMaxRate = Number(point[0]) > 100;

    if (overMaxRate) {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.MAX_RATE_DESCRIPTION'));
      this.accumulationConfig.basic.accumulationRate = 100;
      return '100';
    }

    if (value.slice(-1) === '.') return value.slice(0, -1);
    if (!notAccessChar) return value;
    return value.substring(0, value.length - 1);
  }

  // 회원가입 적립금
  // 일반/포토후기 적립금
  // 생일 적립금
  // 최소 기준금액
  // 최소 사용 적립금
  private validatePriceSize(value: string): string {
    if (value === '') return value;
    const min = 10;
    const max = 999999990;
    const overSize = Number(value) > max || Number(value) < min;
    if (overSize) {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.NUMBER_OVER', { min, max }));
      return value.substring(0, value.length - 1);
    }
    if (value.slice(-1) !== '0') {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.MUST_UNIT'));
      return value;
    }
    if (value === '0') {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.MUST_MIN_PRICE'));
      return value;
    }

    return value;
  }

  // 일반/포토후기 적립 글자수
  private validateReviewSize(value: string): string {
    if (value === '0') {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.REVIEW_UNDER'));
    } else if (Number(value) > 1000) {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.REVIEW_OVER'));
    }
    return value;
  }

  // 최대 사용 적립률
  private validateMaxRateSize(value: string): string {
    if (value === '0') {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.MAX_NUMBER_UNDER'));
    } else if (Number(value) > 100) {
      alert(this.$t('ACCUMULATION.CONFIG_MODIFY.MAX_NUMBER_OVER'));
    }
    return value;
  }

  // 저장 시
  private validateSave(): boolean {
    const condition = validationCondition(this.accumulationConfig);

    if (!isValidate(condition.noNotificationMethod, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_NOTICE_METHOD'))) {
      this.notificationGuideSection.focusCheckbox();
      return false;
    }
    if (!isValidate(condition.noBasicRate, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_BASIC_RATE'))) {
      this.accumulationRateInput.focus();
      return false;
    }
    if (!isValidate(condition.noSignupPoint, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_SIGNUP'))) {
      this.signUpInput.focus();
      return false;
    }
    if (!isValidate(condition.noReviewSize, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_REVIEW_SIZE'))) {
      if (!this.accumulationConfig.payment.reviewAccumulation.reviewsLength) {
        (this.$refs.reviewLengthTextSize as TextInput).focus();
        return false;
      }
      if (!this.accumulationConfig.payment.reviewAccumulation.photoReviewsLength) {
        (this.$refs.photoReviewsLengthTextSize as TextInput).focus();
        return false;
      }
      return false;
    }
    if (!isValidate(condition.noReviewPoint, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_REVIEW'))) {
      if (!this.accumulationConfig.payment.reviewAccumulation.reviewsAccumulation) {
        (this.$refs.reviewsAccumulationPriceSize as TextInput).focus();
        return false;
      }
      if (!this.accumulationConfig.payment.reviewAccumulation.photoReviewsAccumulation) {
        (this.$refs.photoReviewsAccumulationPriceSize as TextInput).focus();
        return false;
      }
      return false;
    }
    if (!isValidate(condition.noBirthdayPoint, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_BIRTHDAY'))) {
      this.birthdayInput.focus();
      return false;
    }
    if (!isValidate(condition.noMinPrice, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_MIN_PRICE'))) {
      this.productPriceMinimumInput.focus();
      return false;
    }
    if (!isValidate(condition.noMinUse, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_MIN_USE'))) {
      this.minimumUsePriceInput.focus();
      return false;
    }
    if (!isValidate(condition.noMaxUse, this.$t('ACCUMULATION.CONFIG_MODIFY.ALERT_MAX_USE'))) {
      this.maximumUseRateInput.focus();
      return false;
    }
    return true;
  }

  private isProductAccumulationUsable(): boolean {
    return this.accumulationConfig.basic?.productAccumulationUsable ?? false;
  }

  private changeProductAccumulationBasisType(bDiscount: boolean, bStandard: boolean, bPromotion: boolean) {
    let type = '';

    if (bDiscount) {
      type = 'DISCOUNTED_';
    } else {
      type = 'SALE_';
    }

    if (bStandard) {
      if (bPromotion) {
        type += 'PROMOTION_';
      } else {
        type += 'STANDARD_';
      }
    }

    type += 'PRICE';
    this.accumulationConfig.payment = { ...this.accumulationConfig.payment, productAccumulationBasisType: type };
  }
  get checkDiscount(): boolean {
    switch (this.accumulationConfig.payment?.productAccumulationBasisType) {
      case 'DISCOUNTED_PRICE':
      case 'DISCOUNTED_STANDARD_PRICE':
      case 'DISCOUNTED_PROMOTION_PRICE':
        return true;
    }
    return false;
  }
  set checkDiscount(checked: boolean) {
    this.changeProductAccumulationBasisType(checked, this.checkStandard, this.checkPromotion);
  }
  get checkStandard(): boolean {
    switch (this.accumulationConfig.payment?.productAccumulationBasisType) {
      case 'SALE_STANDARD_PRICE':
      case 'SALE_PROMOTION_PRICE':
      case 'DISCOUNTED_STANDARD_PRICE':
      case 'DISCOUNTED_PROMOTION_PRICE':
        return true;
    }
    return false;
  }
  set checkStandard(checked: boolean) {
    this.changeProductAccumulationBasisType(this.checkDiscount, checked, this.checkPromotion);
  }
  get checkPromotion(): boolean {
    switch (this.accumulationConfig.payment?.productAccumulationBasisType) {
      case 'SALE_PROMOTION_PRICE':
      case 'DISCOUNTED_PROMOTION_PRICE':
        return true;
    }
    return false;
  }
  set checkPromotion(checked: boolean) {
    this.changeProductAccumulationBasisType(this.checkDiscount, this.checkStandard, checked);
  }
}
