
































































import { PropType } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { InquiryInfo } from '@/types/questions';
import { i18n } from '@/views/contents/board/basic/Questions';
import { ProductInquiriesByInquiryNo } from '@/types/questions';
import { throwExternalWindowPopup } from '@/helpers/popup';

@Component
export default class QuestionsInquiryInfo extends Vue {
  @Prop({
    required: false,
    default: () => ({ inquiryNo: -1 }),
    type: Object as PropType<InquiryInfo>,
  })
  private readonly data!: InquiryInfo;

  @Prop({ default: true }) private readonly isHalfType?: boolean;

  @Prop({ default: false }) private readonly isShowOrderNoTitle?: boolean;

  private t(txt: string, list: string[] = []): string {
    return i18n.call(this, txt, list);
  }

  private isShowReplaySwitch = false;
  private replyTxt = '';

  private get currentSecretClsName(): string {
    return this.data.isSecretInquiry ? 'lock' : 'unlock';
  }

  private get isDataEmpty(): boolean {
    this.replyTxt = '';
    return !this.data || !Object.keys(this.data).length;
  }

  private get repliedProductInquires(): ProductInquiriesByInquiryNo['repliedProductInquires'] {
    if (this.isDataEmpty) {
      return [];
    } else {
      if (this.data['repliedProductInquires']) {
        return this.data['repliedProductInquires'];
      } else {
        return [];
      }
    }
  }

  private get isAdmin(): boolean {
    return !this.isDataEmpty && this.data.admin && this.data.admin.adminNo > -1;
  }

  private get isShowReplay(): boolean {
    if (this.isDataEmpty) {
      return false;
    } else if (this.isShowReplaySwitch || this.repliedProductInquires.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  private get registerName(): string {
    if (this.isDataEmpty) {
      return '';
    } else if (this.isAdmin) {
      return this.registerName;
    } else if (this.data.memberStatus === 'EXPELLED' || this.data.memberStatus === 'WITHDRAWN') {
      return this.t('WITHDRAWAL_MEMBER');
    } else {
      return `<a class="memberPopup" href="#" data-register-no="${this.data.registerNo}">${this.data.memberId} (${this.data.registerNo})</a>`;
    }
  }

  private setInquirySecret() {
    const txt = this.t(this.data.isSecretInquiry ? 'NORMAL' : 'SECRET');
    if (confirm(this.t('CHANGE_SECRET', [txt]))) {
      const data = {
        inquiryNo: this.data.inquiryNo,
        isSecret: !this.data.isSecretInquiry,
      };
      this.$api.putProductInquiriesSecretByInquiryNo({ data }).then(({ status }) => {
        if (status === 204) {
          this.fetch();
          this.$emit('setSecret', !this.data.isSecretInquiry);
          alert(this.t('ALERT_CHANGED'));
        }
      });
    }
  }

  private ShowReplaySwitch() {
    if (this.repliedProductInquires.length >= 100) {
      return alert(this.t('ONLY_REGISTER_100'));
    }
    this.isShowReplaySwitch = true;
  }

  private displayTypeChange({ target }: Event) {
    const value = target['value'];
    const displayStatusType = value === 'DISPLAY' ? 'EXHIBITION' : 'BLIND';
    if (!confirm(this.t('COMFIRM_CHANGE_DISPLAYTYPE', [this.t(displayStatusType)]))) {
      target['value'] = this.data['displayStatusType'];
      return;
    } else {
      this.$emit('changeDisplay', value);
    }
  }

  private fetch() {
    const { inquiryNo, memberId, memberStatus, mallNo, admin, displayStatusType } = this.data;
    this.$emit('fetch', { inquiryNo, memberId, memberStatus, mallNo, admin, displayStatusType });
  }

  private deleteReplied(inquiryNo: string, mallNo: string) {
    const txt = this.t('DELETE_ALERT_TEXT');
    if (confirm(txt)) {
      this.$api.deleteProductInquiriesByInquiryNo({ params: { inquiryNo, mallNo } }).then(() => {
        this.fetch();
        alert(this.t('THE_ANSWER_IS_DELETED'));
      });
    }
  }

  private open(e: MouseEvent) {
    const { registerNo } = e.target['dataset'];
    if (registerNo) {
      throwExternalWindowPopup(`/pro/crm/${registerNo}`, 'xlg');
    }
  }

  private save() {
    if (!this.replyTxt.trim().length) {
      alert(this.t('ENTER_YOUR_ANSWER'));
    } else {
      const params = { inquiryNo: this.data.inquiryNo.toString() };
      const data = { content: this.replyTxt };
      this.$api.postProductInquiriesReplay({ params, data }).then(() => {
        this.fetch();
        this.replyTxt = '';
        this.$emit('setReplied', true);
        this.isShowReplaySwitch = false;
        alert(this.t('SAVE_ANSWER_COMPLETED'));
      });
    }
  }

  public focus(): void {
    (this.$el as HTMLElement).focus();
  }

  public hideReplayArea() {
    this.isShowReplaySwitch = false;
  }
}
