




import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({ components: { AdminIframe } })
export default class ProductMainAddIframe extends Vue {
  private get pageType() {
    return this.$route.name === 'ProductMainAddDev' ? 'add' : 'edit';
  }

  private get remoteSrc() {
    const url = `/appearance/custom/product-main/${this.pageType}?`;
    const queryObject = { ...this.$route.query, serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + url + queryString;

    return src;
  }
}
