














































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ComponentItemsClaimDataType } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import NoticeBox from '@/components/popup/claim/ClaimApplyPopup/NoticeBox.vue';
import OrderProducts from '@/components/popup/claim/ClaimApplyPopup/OrderProducts.vue';
import ProductExchangeDetails from '@/components/popup/claim/ClaimApplyPopup/ProductExchangeDetails.vue';
import RefundExchangeAmount from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeAmount.vue';
import BenefitsPaid from '@/components/popup/claim/ClaimApplyPopup/BenefitsPaid.vue';
import ProductExchange from '@/components/popup/claim/ClaimApplyPopup/ProductExchange.vue';
import ProductDetails from '@/components/popup/claim/ClaimApplyPopup/ProductDetails.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  CancelExchangesPredict,
  PostCancelExchangesPredictRequest,
  PostCancelExchangesRequest,
  AdditionalPayType,
  RefundType,
  GetClaimsOrderOptionsOrderOptionNoApply,
  ResponsibleObjectType,
  ReasonType,
} from 'ncp-api-supporter';
@Component({
  components: {
    NoticeBox,
    OrderProducts,
    ProductExchange,
    ProductExchangeDetails,
    ProductDetails,
    RefundExchangeAmount,
    BenefitsPaid,
  },
})
export default class ExchangePopupBefore extends Vue {
  @Prop({ required: true }) private orderOptionsApply!: ComponentItemsClaimDataType;
  @Prop({ required: true }) private partnerNo!: string;
  @Prop({ required: true }) private memberNo!: string;
  @Prop({ required: true }) private mallNo!: string;
  @Prop({ required: true }) private claimOrderOptions!: GetClaimsOrderOptionsOrderOptionNoApply;
  @Prop({ default: false }) private escrowOrder!: boolean;

  private refOrderProducts: OrderProducts;
  private refProductDetails: ProductDetails;
  private refRefundExchangeAmount: RefundExchangeAmount;
  private refBenefitsPaid: BenefitsPaid;
  private refProductExchange: ProductExchange;
  private refProductExchangeDetails: ProductExchangeDetails;
  private claimType = 'ExchangePopupBefore';
  private showA = true;
  private showB = true;
  private zeroRefundShow = false;
  private refundBankInfoTrShow = true;
  private objJson = '';
  private displayedDeliveryAmtDiscountMessage = false;
  private mall = {
    countryCd: 'KR',
  };

  private postCancelExchangesPredict!: CancelExchangesPredict;
  public checkedExchangesProduct = false;
  private refundTypeDivShow = false;

  private isPartnerProduct = false;
  private sellerPaysClaimedDelivery = false;
  public setIsPartnerProduct(isPartnerProduct: boolean) {
    this.isPartnerProduct = isPartnerProduct;
  }

  private initPostCancelExchangesPredict() {
    this.postCancelExchangesPredict = {
      refundType: 'ACCOUNT',
      shipping: {
        requiresShipping: true,
        address: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
        shippingNo: 0,
        divided: false,
        deliveryGroupNo: 0,
        deliveryType: '',
        prepaid: true,
        deliveryTemplateNo: 0,
        combined: false,
        originalShippingNo: 0,
        hasAdjustedExchangeDeliveryAmt: true,
      },
      coupon: {
        productCoupons: [],
        cartCoupon: {
          couponName: '',
          couponIssueNo: 0,
          orderProductNo: 0,
          restores: false,
          couponNo: 0,
        },
      },
      amounts: {
        deliveryAdjustAmt: 0,
        adjustedAmounts: {
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          claimAmt: 0,
          additionalPayAmt: 0,
          payAmt: 0,
        },
        before: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        productAdjustAmt: 0,
        after: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        refund: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
            cancelTotalPrice: 0,
            exchangeTotalPrice: 0,
            exchangeProductAmtAdjustAmt: 0,
            exchangeAddTotalPrice: 0,
            productCouponDiscountAmt: 0,
            totalPayAmt: 0,
            totalDeliveryAmt: 0,
            totalExchangeAmt: 0,
          },
        },
      },
      exchangeShipping: {
        address: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
        divided: false,
        deliveryType: '',
        remoteDeliveryAmt: 0,
        prepaid: false,
        requiresShipping: false,
        deliveryAmt: 0,
        shippingNo: 0,
        returnDeliveryAmt: 0,
        deliveryGroupNo: 0,
        deliveryTemplateNo: 0,
        combined: false,
        originalShippingNo: 0,
      },
      overflowsPgAmt: false,
    };
    this.objJson = JSON.stringify(this.postCancelExchangesPredict);
  }

  private created() {
    this.initPostCancelExchangesPredict();
  }
  private clearData() {
    this.initPostCancelExchangesPredict();
    this.$nextTick(() => {
      this.refProductExchangeDetails.exchangeProductAmtAdjustAmt = 0;
      this.refProductExchangeDetails.exchangeProductAmtAdjustReason = '';
      this.refProductExchangeDetails.defaultCalculation();
    });
    this.checkedExchangesProduct = false;
  }

  private accumulationUsed = false;
  private exchangeCnt = 0;
  private responsibleObjectTypeValue = 'SELLER';
  private setData() {
    this.setRefComponent();
    this.responsibleObjectTypeValue = this.refOrderProducts.responsibleObjectTypeValue;
    const exchangeOptionNo = Number(this.refProductExchange.mallOptionValue);
    this.exchangeCnt = this.refProductExchange.exchangeCnt;
    const cancelCnt: number =
      this.refOrderProducts.orderCntArray === null ||
      this.refOrderProducts.orderCntArray === undefined ||
      this.refOrderProducts.orderCntArray.length == 0
        ? 0
        : this.refOrderProducts.orderCntArray[0];
    if (exchangeOptionNo !== null && exchangeOptionNo !== undefined && exchangeOptionNo !== 0) {
      const request: PostCancelExchangesPredictRequest = {
        data: {
          cancelOrderOptionNo: Number(this.orderOptionsApply.claimableOptions[0].orderOptionNo),
          responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue as ResponsibleObjectType,
          refundType: this.orderOptionsApply.refundTypes[0].refundType as RefundType,
          exchangeOptionNo: exchangeOptionNo,
          exchangeCnt: this.exchangeCnt,
          orderNo: this.orderOptionsApply.orderNo,
          cancelCnt: cancelCnt,
          sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
          productAdjustAmt:
            this.refProductExchangeDetails == undefined
              ? 0
              : this.refProductExchangeDetails.exchangeProductAmtAdjustAmt,
          exchangeProductNo: Number(this.refProductExchange.mallProductNo),
        },
      };

      if (!this.isPartnerProduct && this.refOrderProducts.responsibleObjectTypeValue === 'SELLER') {
        request.data.sellerPaysClaimedDelivery = true;
      } else if (this.refOrderProducts.responsibleObjectTypeValue === 'SELLER') {
        request.data.sellerPaysClaimedDelivery = this.sellerPaysClaimedDelivery;
      }

      this.$api.postCancelExchangesPredict(request).then(ret => {
        this.postCancelExchangesPredict = ret.data;
        this.accumulationUsed = this.refProductExchange.accumulationUsed;
        this.checkedExchangesProduct = true;
        this.refundTypeDivShow =
          this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt < 0 ||
          (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt === 0 &&
            this.orderOptionsApply.payType === 'ZERO_PAY');
        this.objJson = JSON.stringify(this.postCancelExchangesPredict);
      });
    } else {
      this.initPostCancelExchangesPredict();
    }
  }

  private close(): void {
    if (!confirm(this.$t('CLAIM.EXCHANGEPOPUP.CLOSECONFIRM').toString())) {
      return;
    }
    window.close();
  }

  private checkHoldDelivery(): boolean {
    return (
      this.orderOptionsApply.claimableOptions[this.orderOptionsApply.claimableOptions.length - 1].holdProcess == true &&
      !confirm(this.$t('CLAIM_CANCELPOPUP_CONFIRM').toString())
    );
  }

  private validationBeforeApply(): boolean {
    const exchangeProductAmtAdjustAmt: number = this.refProductExchangeDetails.exchangeProductAmtAdjustAmt;
    const exchangeProductAmtAdjustReason: string = this.refProductExchangeDetails.exchangeProductAmtAdjustReason;
    let refundType = '';
    if (exchangeProductAmtAdjustAmt > 0 && exchangeProductAmtAdjustReason == '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER6'));
      (this.refProductExchangeDetails.$refs.exchangeProductAmtAdjustReasonRef as TextInput).focus();
      return false;
    }

    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt == 0) {
      refundType = 'ZERO_REFUND';
    } else if (
      this.refRefundExchangeAmount.refundTypeValue === undefined ||
      this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt >= 0
    ) {
      refundType = null;
    } else {
      refundType = this.refRefundExchangeAmount.refundTypeValue;
    }

    let needBankAccount = false;
    switch (refundType) {
      case 'CASH':
      case 'ACCOUNT':
        needBankAccount = true;
        break;
      case 'PG':
        if (
          this.orderOptionsApply.payType === 'VIRTUAL_ACCOUNT' ||
          this.orderOptionsApply.payType === 'ESCROW_VIRTUAL_ACCOUNT'
        ) {
          needBankAccount = true;
        }
        break;
    }

    if (needBankAccount && this.refRefundExchangeAmount.refundBankValue == null) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER7'));
      (this.refRefundExchangeAmount.$refs.refundBankAccountRef as TextInput).focus();
      return false;
    }

    if (needBankAccount && this.refRefundExchangeAmount.refundBankAccount == '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER8'));
      (this.refRefundExchangeAmount.$refs.refundBankAccountRef as TextInput).focus();
      return false;
    }

    if (needBankAccount && this.refRefundExchangeAmount.refundBankDepositorName == '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER9'));
      (this.refRefundExchangeAmount.$refs.refundBankDepositorNameRef as TextInput).focus();
      return false;
    }

    let exchangePayType = '';
    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      exchangePayType = this.refRefundExchangeAmount.exchangePayType;
    }

    const needAdditionalPay: boolean = exchangePayType == 'CASH';

    let tradeBankAccountInfo = null;
    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      const bankStringArr =
        String(this.refRefundExchangeAmount.tradeBankAccountInfo).indexOf('|') >= 0
          ? String(this.refRefundExchangeAmount.tradeBankAccountInfo).split('|')
          : [null, null, null];
      tradeBankAccountInfo = {
        bank: bankStringArr[0],
        bankAccount: bankStringArr[1],
        bankDepositorName: bankStringArr[2],
      };
    }
    if (needAdditionalPay && (tradeBankAccountInfo == null || tradeBankAccountInfo.bank == null)) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER10'));
      (this.refRefundExchangeAmount.$refs.tradeBankAccountInfoRef as TextInput).focus();
      return false;
    }

    let remitter = '';
    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      remitter = this.refRefundExchangeAmount.remitter;
    }
    if (needAdditionalPay && remitter === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER11'));
      (this.refRefundExchangeAmount.$refs.remitterRef as TextInput).focus();
      return false;
    }
    return true;
  }

  private onClickApplyExchange() {
    if (!this.refProductExchange?.mallOptionValue) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER4'));
      return;
    }
    if (
      this.postCancelExchangesPredict.overflowsPgAmt &&
      this.refRefundExchangeAmount.refundTypeValue !== 'ACCOUNT' &&
      this.refRefundExchangeAmount.refundTypeValue !== 'ACCUMULATION'
    ) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER3'));
      return;
    }

    if (!this.validationBeforeApply()) {
      return;
    }

    if (this.checkHoldDelivery()) {
      return;
    }

    if (!confirm('교환신청하시겠습니까?')) {
      return;
    }

    let exchangePayType: AdditionalPayType = null;
    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      exchangePayType = this.refRefundExchangeAmount.exchangePayType as AdditionalPayType;
    }

    let remitter = '';
    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt > 0 && exchangePayType === 'CASH') {
      remitter = this.refRefundExchangeAmount.remitter;
    }

    let tradeBankAccountInfo = null;
    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt > 0 && exchangePayType === 'CASH') {
      const bankStringArr =
        String(this.refRefundExchangeAmount.tradeBankAccountInfo).indexOf('|') >= 0
          ? String(this.refRefundExchangeAmount.tradeBankAccountInfo).split('|')
          : [null, null, null];
      let bankNm = '';
      this.refRefundExchangeAmount.refundBankList.forEach((element: { value: string; name: string }) => {
        if (element.value == bankStringArr[0]) {
          bankNm = element.name;
        }
      });
      tradeBankAccountInfo = {
        bank: bankStringArr[0],
        bankName: bankNm,
        bankAccount: bankStringArr[1],
        bankDepositorName: bankStringArr[2],
      };
    } else {
      tradeBankAccountInfo = {
        bank: null,
        bankName: null,
        bankAccount: null,
        bankDepositorName: null,
      };
    }

    let bank = '';
    let bankNm = '';
    if (this.refRefundExchangeAmount.refundBankEditShow === false) {
      bank = null;
      bankNm = null;
    } else {
      bank = this.refRefundExchangeAmount.refundBankValue;
      this.refRefundExchangeAmount.refundBankList.forEach((element: { value: string; name: string }) => {
        if (element.value == this.refRefundExchangeAmount.refundBankValue) {
          bankNm = element.name;
        }
      });
    }

    let refundType = '';
    if (this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt == 0) {
      refundType = 'ZERO_REFUND';
    } else if (
      this.refRefundExchangeAmount.refundTypeValue == undefined ||
      this.postCancelExchangesPredict.amounts.adjustedAmounts.claimAmt >= 0
    ) {
      refundType = null;
    } else {
      refundType = this.refRefundExchangeAmount.refundTypeValue;
    }

    const request: PostCancelExchangesRequest = {
      data: {
        productAdjustReason: this.refProductExchangeDetails.exchangeProductAmtAdjustReason,
        additionalPayRemitter: remitter,
        reasonDetail: this.refOrderProducts.claimReasonDetail[0] as string,
        additionalPayBankAccount: {
          depositorName: tradeBankAccountInfo.bankDepositorName,
          bank: tradeBankAccountInfo.bank,
          bankName: tradeBankAccountInfo.bankName,
          account: tradeBankAccountInfo.bankAccount,
        },
        additionalPayType: exchangePayType,
        calculateParam: {
          refundType: refundType as any,
          cancelOrderOptionNo: Number(this.orderOptionsApply.claimableOptions[0].orderOptionNo),
          responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue as ResponsibleObjectType,
          exchangeOptionNo: Number(this.refProductExchange.mallOptionValue),
          exchangeCnt: Number(this.refProductExchange.exchangeCnt),
          orderNo: this.orderOptionsApply.orderNo,
          cancelCnt:
            this.refOrderProducts.orderCntArray === null ||
            this.refOrderProducts.orderCntArray === undefined ||
            this.refOrderProducts.orderCntArray.length == 0
              ? 0
              : this.refOrderProducts.orderCntArray[0],
          sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
          productAdjustAmt:
            this.refProductExchangeDetails === undefined
              ? 0
              : this.refProductExchangeDetails.exchangeProductAmtAdjustAmt,
          exchangeProductNo: Number(this.refProductExchange.mallProductNo),
        },
        reasonType: this.refOrderProducts.claimReasonType[0] as ReasonType,
        refundBankAccount: {
          depositorName: this.refRefundExchangeAmount.refundBankDepositorName,
          bank: bank,
          bankName: bankNm,
          account: this.refRefundExchangeAmount.refundBankAccount,
        },
      },
    };

    this.$api.postCancelExchanges(request).then(() => {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.PROCESS_SUCCESS_MESSAGE'));
      window.opener.location.reload();
      window.close();
    });
  }

  public getPayType(payType: string) {
    switch (payType) {
      case 'ACCOUNT':
        return 'ACCOUNT';

      case 'ACCUMULATION':
        return 'ACCUMULATION';

      case 'ZERO_PAY':
        return 'ZERO_REFUND';

      case 'VIRTUAL_ACCOUNT':
      case 'ESCROW_VIRTUAL_ACCOUNT':
        return 'PG';

      default:
        return 'PG';
    }
  }

  private onClickSameProduct() {
    (this.$refs.componentProductExchange as ProductExchange).onClickSameProduct();
  }

  private onClickSameOptionProduct() {
    (this.$refs.componentProductExchange as ProductExchange).onClickSameOptionProduct();
  }

  private onClickDifferentProduct() {
    (this.$refs.componentProductExchange as ProductExchange).onClickDifferentProduct();
  }

  private setRefComponent() {
    this.refOrderProducts = this.$refs.componentItemsClaim as OrderProducts;
    this.refProductExchange = this.$refs.componentProductExchange as ProductExchange;
    this.refProductExchangeDetails = this.$refs.componentProductExchangeDetails as ProductExchangeDetails;
    this.refProductDetails = this.$refs.componentProductDetails as ProductDetails;
    this.refRefundExchangeAmount = this.$refs.componentRefundExchangeAmount as RefundExchangeAmount;
    this.refBenefitsPaid = this.$refs.componentBenefitsPaid as BenefitsPaid;
  }
  mounted() {
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-1200');
    });
  }
}
