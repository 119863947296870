import { DefaultGridContainerOption, GridProp, InputNumber } from '@/types';
import { ApplyStatusType, SaleMethodType } from 'ncp-api-supporter/dist/types/enum';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { getFrontDisplayColumn } from '@/components/product/common/girdColumns/FrontDisplay';

export const noticeList: string[] = ['PRODUCT.ICON.APPLIED_PRODUCT_NOTICE_1', 'PRODUCT.ICON.APPLIED_PRODUCT_NOTICE_2'];

// AppliedProduct Grid
export const displayOptions: DefaultGridContainerOption = {
  showsRightArea: false,
  pageSizeKey: 'size',
};
export const gridProps = (): GridProp => {
  return {
    header: {
      height: 33,
    },
    options: {
      rowHeaders: [],
      bodyHeight: 'auto',
      heightResizable: true,
      minBodyHeight: 150,
      rowHeight: 31,
      columnOptions: {
        frozenCount: 4,
        resizable: true,
      },
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    columns: [
      {
        header: 'PRODUCT.COMMON.EDIT',
        name: 'edit',
        minWidth: 100,
        align: 'center',
        formatter: () => getAnchorHTML(`${window.$t('PRODUCT.COMMON.EDIT')}`),
      },
      {
        header: 'PRODUCT.COMMON.MALL',
        name: 'mallName',
        minWidth: 100,
        align: 'center',
      },
      {
        header: 'PRODUCT.COMMON.PRODUCT_NO',
        name: 'mallProductNo',
        minWidth: 110,
        align: 'center',
        formatter: ({ value }) => getAnchorHTML(value.toString()),
      },
      {
        header: 'PRODUCT.COMMON.PRODUCT_NAME',
        name: 'productName',
        minWidth: 200,
        align: 'center',
      },
      {
        header: 'PRODUCT.COMMON.APPLY_STATUS_TYPE',
        name: 'applyStatusType',
        minWidth: 200,
        align: 'center',
        formatter: ({ value }) => window.$t(`PRODUCT.COMMON.${value}`),
      },
      {
        header: 'PRODUCT.COMMON.SALE_STATUS_TYPE',
        name: 'saleStatusType',
        minWidth: 200,
        align: 'center',
        formatter: ({ value, row }) => {
          const saleStatusType = window.$t(`PRODUCT.COMMON.${value}`);
          if (value !== 'ON_SALE') return saleStatusType;
          const { salePeriod } = row;
          const { periodType } = salePeriod as any;
          const period = window.$t(`PRODUCT.ADD.SALES_PERIOD_${periodType}`);
          return `${saleStatusType}(${period})`;
        },
      },
      {
        header: 'PRODUCT.ADD.SALE_SETTING_STATUS_TYPE',
        name: 'saleSettingStatusType',
        minWidth: 200,
        align: 'center',
        formatter: ({ value }) => window.$t(`PRODUCT.ADD.${value}`),
      },
      getFrontDisplayColumn(200),
      {
        header: 'PRODUCT.COMMON.ADMIN',
        name: 'adminName',
        minWidth: 200,
        align: 'center',
      },
      {
        header: 'PRODUCT.COMMON.SALE_METHOD_TYPE',
        name: 'saleMethodType',
        minWidth: 200,
        align: 'center',
        formatter: ({ value }) => window.$t(`PRODUCT.COMMON.SALE_METHOD_TYPE_${value}`),
      },
      {
        header: 'PRODUCT.COMMON.BRAND',
        name: 'brandName',
        minWidth: 200,
        align: 'center',
        formatter: ({ value }) => value || '-',
      },
    ],
  };
};

export interface GetProductsSearchStickerParameter {
  mallNo: number;
  stickerNos: number;
  brandNo?: InputNumber;
  keywordType?: string;
  keywords?: string;
  applyStatus?: ApplyStatusType | '';
  allowsFrontDisplay?: boolean | '';
  saleMethod?: SaleMethodType | '';
  adminNo?: InputNumber;
  page: number;
  size: number;
  pageSize?: number;
}
export const getQueryParams = (mallNo: number, stickerNos: number): GetProductsSearchStickerParameter => {
  return {
    mallNo,
    stickerNos,
    brandNo: '',
    keywordType: '',
    keywords: '',
    applyStatus: '',
    allowsFrontDisplay: '',
    saleMethod: '',
    adminNo: '',
    page: 1,
    size: 30,
  };
};

// 오름차순 정렬
export function sortByName<T>(data: T[], keyName: string): T[] {
  return data.sort((a, b) => (a[keyName] < b[keyName] ? -1 : a[keyName] > b[keyName] ? 1 : 0));
}

export function convertQueryToNumber(query) {
  for (const key in query) {
    query[key] = key.includes('No') ? Number(query[key]) : query[key];
  }
  return query;
}
