








import { Component } from 'vue-property-decorator';
import PartnerNoticeForm, { NoticeForm } from '@/views/popups/partner/PartnerNoticeForm.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { mall } from '@/utils/mall';
import { PostNoticeRequest } from 'ncp-api-supporter';

@Component({
  components: { PartnerNoticeForm },
})
export default class PartnerNoticeFormRegistrationPopup extends WindowPopupMainVue {
  private initialNoticeForm: NoticeForm = {
    mallNo: mall.lastCreatedMall.mallNo,
    noticeAttachFiles: [],
    noticeCategory: undefined,
    noticeContent: '',
    noticeTitle: '',
    opened: true,
    searchContent: '',
  };

  private registerNotice(request: PostNoticeRequest['data']) {
    this.$api.PostNotice({ data: request }).then(() => {
      alert('게시글이 등록되었습니다.');
      this.onPositiveClick();
    });
  }
}
