import { GetServicesServiceNoMainDomainsResponse } from 'ncp-api-supporter';

export const DEFAULT_DOMAIN_SECURITY_INFO: GetServicesServiceNoMainDomainsResponse = {
  mallNo: 0,
  domainNo: 0,
  domain: '',
  domainStatus: 'DISCONNECTED',
  sslStatus: 'UNINSTALLED',
  domainExpirationDueDays: null,
  domainExpiredAt: null,
  sslExpiredAt: null,
  sslExpirationDueDays: null,
  skinType: 'COMBINE',
  mobileDomain: null,
  mobileDomainStatus: 'DISCONNECTED',
  mobileDomainExpiredAt: null,
  mobileSslStatus: 'UNINSTALLED',
  mobileSslExpiredAt: null,
  mobileDomainExpirationDueDays: null,
  mobileSslExpirationDueDays: null,
};

// 도메인/보안서버 만료일 안내문구 노출 기준일(30일)
export const DOMAIN_EXPIRATION_WARN_DUE_DATE = 30;
