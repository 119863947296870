









































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import CategorySelector, { CategoryType } from '@/components/product/common/CategorySelector.vue';
import {
  resetStocksRequest,
  SearchKeywordType,
  searchOptions,
  StockCategoryType,
} from '@/views/top/product/stock/ProductStock';
import { addMonth, getToday } from '@/utils/dateFormat';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import ChainingSelector from '@/components/common/ChainingSelector.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { StockParamsType } from '@/types/productStock';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import BrandSearcher from '@/components/common/input/data/BrandSearcher.vue';
import StockRangeInput from '@/components/product/common/StockRangeInput.vue';
import { getCurrentMallNo, mall } from '@/utils/mall';

@Component({
  components: {
    SearchButton,
    DateRangePicker,
    SelectBox,
    CheckboxGroup,
    TextInput,
    ChainingSelector,
    MallSelect,
    CategorySelector,
    SearchKeyword,
    BrandSearcher,
    StockRangeInput,
  },
})
export default class ProductStock extends Vue {
  @Ref() private readonly categorySelector;
  @Ref() private readonly stockRangeInput: StockRangeInput;
  @Ref() private readonly searchKeywordRef: SearchKeyword;
  private mallNo = '';
  private searchOptions = searchOptions;
  private startYmdt = addMonth(new Date(), -3);
  private endYmdt = getToday();
  private stockParams = resetStocksRequest();
  private categoryType: CategoryType = CategoryType.DISPLAY;
  private isOnlyOneMall = mall.isOnlyOneMall;

  @Watch('categoryType')
  private syncCategoryType() {
    if (this.categoryType === CategoryType.DISPLAY) {
      this.stockParams.categoryType = StockCategoryType.DISPLAY;
    } else {
      this.stockParams.categoryType = StockCategoryType.STANDARD;
    }
  }

  private get stocksRequest(): StockParamsType {
    const params = this.stockParams;
    params.mallNos = this.mallNo;
    params.startYmdt = this.startYmdt ? `${this.startYmdt} ${DEFAULT_TIME_RANGE.START}` : null;
    params.endYmdt = this.endYmdt ? `${this.endYmdt} ${DEFAULT_TIME_RANGE.END}` : null;
    return params;
  }

  private resetStocks() {
    this.stockParams = resetStocksRequest();
    this.$nextTick(() => this.searchKeywordRef.reset());
    this.setMallNo();
    this.resetPeriod();
    this.categoryType = CategoryType.DISPLAY;
  }

  created() {
    this.setMallNo();
  }

  private resetPeriod(): void {
    this.startYmdt = addMonth(new Date(), -3);
    this.endYmdt = getToday();
  }

  private setMallNo(): void {
    this.mallNo = getCurrentMallNo(this).toString();
  }

  private get keywordValidType(): 'currency' | '' {
    const numberTypeOptions = [
      SearchKeywordType.mallProductNo,
      SearchKeywordType.stockNo,
      SearchKeywordType.mallOptionNo,
    ];
    return numberTypeOptions.includes(this.stockParams.searchKeywordType) ? 'currency' : '';
  }

  private validateStockRange() {
    return this.stockRangeInput.validation();
  }
}
