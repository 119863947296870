import { RouteConfig } from 'vue-router';
import WideLayout from '@/views/WideLayout.vue';

import CouponManagementPopup from '@/views/popups/coupon/CouponManagementPopup.vue';
import CouponPayStatusPopup from '@/views/popups/coupon/CouponPayStatusPopup.vue';

// 설정
import AppliedDeliveryTemplatePopup from '@/views/popups/configuration/delivery/AppliedDeliveryTemplatePopup.vue';
import ShippingChargeAreaFeePopup from '@/views/popups/configuration/delivery/ShippingChargeAreaFeePopup.vue';
import PaycoLoginPopup from '@/views/popups/configuration/PaycoLoginPopup.vue';
import StandardContractFilePopup from '@/views/popups/configuration/basic/StandardContractFilePopup.vue';
import VariableGuidePopup from '@/views/contents/configuration/basic/ExternalServiceIframe/ExternalVariableGuidePopup.vue';

// 파트너
import PartnerContractFilesPopup from '@/views/popups/partner/PartnerContractFilesPopup.vue';
import PartnerNoticeFormModificationPopup from '@/views/popups/partner/PartnerNoticeFormModificationPopup.vue';
import EntryContractFilePopup from '@/views/popups/partner/entryContractFilePopups/EntryContractFilePopup.vue';
import EntryContractFilePopupReadOnly from '@/views/popups/partner/entryContractFilePopups/EntryContractFilePopupReadOnly.vue';
import EntryContractFilePopupRegistration from '@/views/popups/partner/entryContractFilePopups/EntryContractFilePopupRegistration.vue';
import EntryContractFilePopupCancel from '@/views/popups/partner/entryContractFilePopups/EntryContractFilePopupCancel.vue';
import EntryContractFilePopupModification from '@/views/popups/partner/entryContractFilePopups/EntryContractFilePopupModification.vue';

// 상품
import CopyProduct from '@/views/popups/product/CopyProduct.vue';
import ProductOptionsPopupIframe from '@/views/popups/product/ProductOptionsPopupIframe.vue';
import ProductCategoryListPopupIframe from '@/views/popups/product/ProductCategoryListPopupIframe.vue';
import ProductDeliveryDatePopupIframe from '@/views/popups/product/ProductDeliveryDatePopupIframe.vue';
import ProductCustomPropertiesPopupIframe from '@/views/popups/product/ProductCustomPropertiesPopupIframe.vue';
import ProductDeliveryInfoPopupIframe from '@/views/popups/product/ProductDeliveryInfoPopupIframe.vue';
import ProductDescriptionPopupIframe from '@/views/popups/product/ProductDescriptionPopupIframe.vue';
import ProductDescriptionTemplatePopupIframe from '@/views/popups/product/ProductDescriptionTemplatePopupIframe.vue';
import ProductDiscountPricePopupIframe from '@/views/popups/product/ProductDiscountPricePopupIframe.vue';
import ProductDiscountSettingPopupIframe from '@/views/popups/product/ProductDiscountSettingPopupIframe.vue';
import ProductDisplayCategoryBatchPopupIframe from '@/views/popups/product/ProductDisplayCategoryBatchPopupIframe.vue';
import ProductDisplayStatusPopupIframe from '@/views/popups/product/ProductDisplayStatusPopupIframe.vue';
import ProductExcludeInSearchPopupIframe from '@/views/popups/product/ProductExcludeInSearchPopupIframe.vue';
import ProductInformationDisclosurePopupIframe from '@/views/popups/product/ProductInformationDisclosurePopupIframe.vue';
import ProductManagerBatchPopupIframe from '@/views/popups/product/ProductManagerBatchPopupIframe.vue';
import ProductPromotionPopupIframe from '@/views/popups/product/ProductPromotionPopupIframe.vue';
import ProductModifyPromotionPopupIframe from '@/views/popups/product/ProductModifyPromotionPopupIframe.vue';
import ProductSalesPeriodPopupIframe from '@/views/popups/product/ProductSalesPeriodPopupIframe.vue';
import ProductSalesPricePopupIframe from '@/views/popups/product/ProductSalesPricePopupIframe.vue';
import ProductSalesStatusSettingPopupIframe from '@/views/popups/product/ProductSalesStatusSettingPopupIframe.vue';
import ProductScreeningPopupIframe from '@/views/popups/product/ProductScreeningPopupIframe.vue';
import ProductScreeningBatchPopupIframe from '@/views/popups/product/ProductScreeningBatchPopupIframe.vue';
import ProductSloganPopupIframe from '@/views/popups/product/ProductSloganPopupIframe.vue';
import ProductOptionSearchPopupIframe from '@/views/popups/product/ProductOptionSearchPopupIframe.vue';
import ProductWithOptionsSearchPopupIframe from '@/views/popups/product/ProductWithOptionsSearchPopupIframe.vue';
import ProductCopyPopupIframe from '@/views/popups/product/ProductCopyPopupIframe.vue';
import ProductGroupManagementCodePopupIframe from '@/views/popups/product/ProductGroupManagementCodePopupIframe.vue';
import ProductCommissionPurchasePopupIframe from '@/views/popups/product/ProductCommissionPurchasePopupIframe.vue';
import ProductIconPopupIframe from '@/views/popups/product/ProductIconPopupIframe.vue';
import ProductCertificationIframe from '@/views/popups/product/ProductCertificationIframe.vue';
import UpsertDutyInfo from '@/views/popups/product/productAdd/UpsertDutyInfo.vue';
import ProductOptionImage from '@/views/popups/product/productAdd/ProductOption/ProductOptionImage/ProductOptionImage.vue';
import OtherProductOption from '@/views/popups/product/productAdd/ProductOption/OtherProductOptionPopup/OtherProductOption.vue';
import StockModification from '@/views/popups/product/stock/StockModification.vue';
import StockExcelUpload from '@/views/popups/product/stock/StockExcelUpload.vue';
import ImageUpload from '@/views/popups/product/ImageUpload.vue';
import ProductOption from '@/views/popups/product/productAdd/ProductOption.vue';
import DisplayCategoriesExcelUpload from '@/views/popups/product/categorization/ExcelUpload.vue';
import DisplayCategoryExcelUploadIframe from '@/views/popups/product/categorization/ExcelUploadIframe.vue';
import DefaultGuideTemplateForm from '@/views/popups/product/productAdd/DefaultGuideTemplateForm.vue';
import ProductInquiry from '@/views/popups/product/ProductInquiry.vue';
import ProductJudgement from '@/views/popups/product/judgement/ProductJudgement.vue';

// 디자인 (전시)
import DesignProductInquiry from '@/views/popups/design/ProductInquiry.vue';
// 회원
import AccumulationsAssemblesPopup from '@/views/popups/member/AccumulationsAssemblesPopup.vue';
import UpsertCertificationInfo from '@/views/popups/product/productAdd/UpsertCertificationInfo.vue';
import AuthorityGroupMemberListPopup from '@/views/popups/configuration/AuthorityGroupMemberListPopup.vue';
import CouponIssuePlansPopup from '@/views/popups/member/CouponIssuePlansPopup.vue';
import SmsManager from '@/views/popups/member/SmsManager.vue';
import TemplateSelectManager from '@/views/popups/member/TemplateSelectManager.vue';
import KakaoTemplateManager from '@/views/popups/member/KakaoTemplateManager.vue';
import RegisterPlusId from '@/views/popups/member/RegisterPlusId.vue';
import WarehouseFormPopup from '@/views/popups/configuration/WarehouseFormPopup.vue';
import InquiryMember from '@/views/popups/member/InquiryMember.vue';
import SetConfigurationOption from '@/views/popups/product/productAdd/ProductOption/SetConfigurationOptionPopup/SetConfigurationOption.vue';
import SmsReservationDetail from '@/views/popups/member/SmsReservationDetail.vue';
import SmsSendManagerIframe from '@/views/popups/member/SmsSendManagerIframe.vue';
import AccumulationDailyStatusPopup from '@/views/popups/member/AccumulationDailyStatusPopup.vue';
import AccumulationManualStatusPopup from '@/views/popups/member/AccumulationManualStatusPopup.vue';
import ExcelBatchRegistrationPopup from '@/views/popups/member/ExcelBatchRegistrationPopup.vue';

// 주문
import OrderFirstDiscount from '@/views/popups/order/OrderFirstDiscount.vue';
import DeliveryFirstAmtDetail from '@/views/popups/order/DeliveryFirstAmtDetail.vue';
import NaverDelay from '@/views/popups/order/NaverDelay.vue';
import Benefit from '@/views/popups/order/Benefit.vue';
import OrderSpecificationPopup from '@/views/popups/order/OrderSpecificationPopup.vue';
import CancelAllApplyPopup from '@/views/popups/claim/CancelAllApplyPopup.vue';
import CancelApplyPopup from '@/views/popups/claim/CancelApplyPopup.vue';
import ExchangeApplyPopup from '@/views/popups/claim/ExchangeApplyPopup.vue';
import ReturnApplyPopup from '@/views/popups/claim/ReturnApplyPopup.vue';
import OrderProductInquiry from '@/views/popups/order/ProductInquiry.vue';
import DeliveryCombine from '@/views/popups/order/DeliveryCombine.vue';
import DeliveryInvoiceForm from '@/views/popups/order/DeliveryInvoiceForm.vue';
// 프로모션
import InquiryCoupon from '@/views/popups/promotion/InquiryCoupon.vue';
import CouponIssuanceUsePopup from '@/views/popups/promotion/CouponIssuanceUsePopup.vue';
import CouponCodeManagementPopup from '@/views/popups/promotion/CouponCodeManagementPopup.vue';
import PromotionProductInquiry from '@/views/popups/promotion/ProductInquiry.vue';

// 클레임
import CancelPopup from '@/views/popups/claim/CancelPopup.vue';
import ExchangePopup from '@/views/popups/claim/ExchangePopup.vue';
import ReturnPopup from '@/views/popups/claim/ReturnPopup.vue';
import AlreadyDeliveryPopup from '@/views/popups/claim/AlreadyDeliveryPopup.vue';
import CollectPopup from '@/views/popups/claim/CollectPopup.vue';

// board/basic article
import ArticleReplyView from '@/views/popups/board/basic/ArticleReplyView.vue';
import ArticleDetail from '@/views/popups/board/basic/ArticleDetail.vue';
import ExchangeAcceptPopup from '@/views/popups/claim/ExchangeAcceptPopup.vue';
import ReturnAcceptPopup from '@/views/popups/claim/ReturnAcceptPopup.vue';
import ReviewDetailIframe from '@/views/popups/board/basic/ReviewDetailIframe.vue';
// import ReviewDetail from '@/views/popups/board/basic/ReviewDetail.vue';
import InquiryDetailPopupIframe from '@/views/popups/board/basic/InquiryDetailPopupIframe.vue';
// import InquiryDetailPopup from '@/views/popups/board/basic/InquiryDetailPopup.vue';
import ReturnInfoEditPopup from '@/views/popups/claim/ReturnInfoEditPopup.vue';
import ModifySalePrice from '@/views/popups/product/ModifySalePrice.vue';
import NaverPayReturnHold from '@/components/popup/claim/NaverPayReturnHold.vue';
import MerchantUserManage from '@/views/popups/marketing/MerchantUserManage.vue';
import AdsUserManage from '@/views/popups/marketing/AdsUserManage.vue';
import PartnerNoticeFormRegistrationPopup from '@/views/popups/partner/PartnerNoticeFormRegistrationPopup.vue';
import PartnerNoticeCategoryChanger from '@/views/popups/partner/PartnerNoticeCategoryChanger.vue';
import ChangeAdmin from '@/views/popups/product/ChangeAdmin.vue';
import ServerExcelDownloadIframe from '@/views/popups/excel/ServerExcelDownLoadIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/popup/',
    name: 'popup',
    component: WideLayout,
    children: [
      // Common
      {
        path: 'excel-download',
        name: 'ServerExcelDownload',
        component: ServerExcelDownloadIframe,
      },
      {
        path: 'template',
        name: 'TemplateSelectManager',
        component: TemplateSelectManager,
      },
      {
        path: 'option',
        name: 'ProductOption',
        component: ProductOption,
      },
      {
        path: 'option-image',
        name: 'ProductOptionImage',
        component: ProductOptionImage,
      },
      {
        path: 'option-other-product',
        name: 'OtherProductOption',
        component: OtherProductOption,
      },
      {
        path: 'review-detail',
        name: 'ReviewDetail',
        component: ReviewDetailIframe,
      },
      {
        path: 'variable-guide',
        name: 'VariableGuidePopup',
        component: VariableGuidePopup,
      },
      {
        path: 'set-configuration-option',
        name: 'SetConfigurationOption',
        component: SetConfigurationOption,
      },
      {
        path: 'sms',
        name: 'SmsManager',
        component: SmsManager,
      },
      {
        path: 'stock-modification',
        name: 'StockModification',
        component: StockModification,
      },
      {
        path: 'display-categories-excel-upload',
        name: 'DisplayCategoriesExcelUpload',
        component: DisplayCategoriesExcelUpload,
      },
      {
        path: 'display-category-excel-batch-register',
        name: 'DisplayCategoryExcelUploadIframe',
        component: DisplayCategoryExcelUploadIframe,
      },
      {
        path: 'change-admin',
        name: 'ChangeAdmin',
        component: ChangeAdmin,
      },
      {
        path: 'stock-excel-upload',
        name: 'StockExcelUpload',
        component: StockExcelUpload,
      },
      {
        path: 'modify-sale-price',
        name: 'ModifySalePrice',
        component: ModifySalePrice,
      },
      {
        path: 'copy-product-prev',
        name: 'CopyProduct',
        component: CopyProduct,
      },
      {
        path: 'product-options',
        name: 'ProductOptionsPopupIframe',
        component: ProductOptionsPopupIframe,
      },
      {
        path: 'category-list',
        name: 'ProductCategoryListPopupIframe',
        component: ProductCategoryListPopupIframe,
      },
      {
        path: 'delivery-date',
        name: 'ProductDeliveryDatePopupIframe',
        component: ProductDeliveryDatePopupIframe,
      },
      {
        path: 'product-custom-properties',
        name: 'ProductCustomPropertiesPopupIframe',
        component: ProductCustomPropertiesPopupIframe,
      },
      {
        path: 'product-delivery-info',
        name: 'ProductDeliveryInfoPopupIframe',
        component: ProductDeliveryInfoPopupIframe,
      },
      {
        path: 'product-description',
        name: 'ProductDescriptionPopupIframe',
        component: ProductDescriptionPopupIframe,
      },
      {
        path: 'product-description-template/:segment',
        name: 'ProductDescriptionTemplatePopupIframe',
        component: ProductDescriptionTemplatePopupIframe,
      },
      {
        path: 'product-discount-price',
        name: 'ProductDiscountPricePopupIframe',
        component: ProductDiscountPricePopupIframe,
      },
      {
        path: 'product-discount-setting',
        name: 'ProductDiscountSettingPopupIframe',
        component: ProductDiscountSettingPopupIframe,
      },
      {
        path: 'product-display-category-batch',
        name: 'ProductDisplayCategoryBatchPopupIframe',
        component: ProductDisplayCategoryBatchPopupIframe,
      },
      {
        path: 'product-display-status',
        name: 'ProductDisplayStatusPopupIframe',
        component: ProductDisplayStatusPopupIframe,
      },
      {
        path: 'product-exclude-in-search',
        name: 'ProductExcludeInSearchPopupIframe',
        component: ProductExcludeInSearchPopupIframe,
      },
      {
        path: 'product-information-disclosure',
        name: 'ProductInformationDisclosurePopupIframe',
        component: ProductInformationDisclosurePopupIframe,
      },
      {
        path: 'product-manager-batch',
        name: 'ProductManagerBatchPopupIframe',
        component: ProductManagerBatchPopupIframe,
      },
      {
        path: 'product-promotion',
        name: 'ProductPromotionPopupIframe',
        component: ProductPromotionPopupIframe,
      },
      {
        path: 'modify-promotion',
        name: 'ProductModifyPromotionPopupIframe',
        component: ProductModifyPromotionPopupIframe,
      },
      {
        path: 'product-sales-period',
        name: 'ProductSalesPeriodPopupIframe',
        component: ProductSalesPeriodPopupIframe,
      },
      {
        path: 'product-sales-price',
        name: 'ProductSalesPricePopupIframe',
        component: ProductSalesPricePopupIframe,
      },
      {
        path: 'product-sales-status-setting',
        name: 'ProductSalesStatusSettingPopupIframe',
        component: ProductSalesStatusSettingPopupIframe,
      },
      {
        path: 'product-screening',
        name: 'ProductScreeningPopupIframe',
        component: ProductScreeningPopupIframe,
      },
      {
        path: 'product-screening-batch',
        name: 'ProductScreeningBatchPopupIframe',
        component: ProductScreeningBatchPopupIframe,
      },
      {
        path: 'product-slogan',
        name: 'ProductSloganPopupIframe',
        component: ProductSloganPopupIframe,
      },
      {
        path: 'product-option-search',
        name: 'ProductOptionSearchPopupIframe',
        component: ProductOptionSearchPopupIframe,
      },
      {
        path: 'product-with-options-search',
        name: 'ProductWithOptionsSearchPopupIframe',
        component: ProductWithOptionsSearchPopupIframe,
      },
      {
        path: 'copy-product',
        name: 'ProductCopyPopupIframe',
        component: ProductCopyPopupIframe,
      },
      {
        path: 'group-management-code',
        name: 'ProductGroupManagementCodePopupIframe',
        component: ProductGroupManagementCodePopupIframe,
      },
      {
        path: 'commission-purchase',
        name: 'ProductCommissionPurchasePopupIframe',
        component: ProductCommissionPurchasePopupIframe,
      },
      {
        path: 'icon-change',
        name: 'ProductIconPopupIframe',
        component: ProductIconPopupIframe,
      },
      {
        path: 'product-certification-info',
        name: 'ProductCertificationIframe',
        component: ProductCertificationIframe,
      },
      {
        path: 'image-upload',
        name: 'ImageUpload',
        component: ImageUpload,
      },
      //회원
      {
        path: 'accumulations-assembles',
        name: 'AccumulationsAssemblesPopup',
        component: AccumulationsAssemblesPopup,
      },
      {
        path: 'upsert-duty-info',
        name: 'UpsertDutyInfo',
        component: UpsertDutyInfo,
      },
      {
        path: 'upsert-certification-info',
        name: 'UpsertCertificationInfo',
        component: UpsertCertificationInfo,
      },

      {
        path: 'authority-group/admin-list',
        name: 'AuthorityGroupMemberListPopup',
        component: AuthorityGroupMemberListPopup,
      },
      {
        path: 'coupon-issue-plans',
        name: 'CouponIssuePlansPopup',
        component: CouponIssuePlansPopup,
      },
      {
        path: 'coupon-issue-plans-form',
        name: 'CouponManagementPopup',
        component: CouponManagementPopup,
      },
      {
        path: 'coupon-issue-plans-detail',
        name: 'CouponPayStatusPopup',
        component: CouponPayStatusPopup,
      },
      {
        path: 'inquiry-coupon',
        name: 'InquiryCoupon',
        component: InquiryCoupon,
      },
      {
        path: 'coupon-issuance-use',
        name: 'CouponIssuanceUsePopup',
        component: CouponIssuanceUsePopup,
        meta: {
          excelCreateMenuKey: 'PRO_COUPON_ISSUE_IN_PLAN',
        },
      },
      {
        path: 'coupon-code-management',
        name: 'CouponCodeManagementPopup',
        component: CouponCodeManagementPopup,
      },
      {
        path: 'kakao-template',
        name: 'KakaoTemplateManager',
        component: KakaoTemplateManager,
      },
      {
        path: 'register-plus-id',
        name: 'RegisterPlusId',
        component: RegisterPlusId,
      },
      {
        path: 'warehouse-edit',
        name: 'WarehouseFormPopup',
        component: WarehouseFormPopup,
      },
      {
        path: 'inquiry-member',
        name: 'InquiryMember',
        component: InquiryMember,
      },
      {
        path: 'sms-reservation-detail',
        name: 'SmsReservationDetail',
        component: SmsReservationDetail,
      },
      {
        path: 'sms-send',
        name: 'SmsSendManagerIframe',
        component: SmsSendManagerIframe,
      },
      {
        path: 'accumulations-status',
        name: 'AccumulationDailyStatusPopup',
        component: AccumulationDailyStatusPopup,
        meta: {
          excelCreateMenuKey: 'PRO_ACCUMULATION_SUMMARY_DETAIL',
        },
      },
      {
        path: 'accumulations-manual',
        name: 'AccumulationManualStatusPopup',
        component: AccumulationManualStatusPopup,
        meta: {
          excelCreateMenuKey: 'PRO_ACCUMULATION_SUMMARY',
        },
      },
      {
        path: 'excel-batch-registration',
        name: 'ExcelBatchRegistrationPopup',
        component: ExcelBatchRegistrationPopup,
      },
      {
        path: 'article-reply-view',
        name: 'ArticleReplyView',
        component: ArticleReplyView,
      },
      {
        path: 'article-detail',
        name: 'ArticleDetail',
        component: ArticleDetail,
      },
      {
        path: 'applied-delivery-template',
        name: 'AppliedDeliveryTemplatePopup',
        component: AppliedDeliveryTemplatePopup,
      },
      {
        path: 'cancel-all-apply',
        name: 'CancelAllApplyPopup',
        component: CancelAllApplyPopup,
      },
      {
        path: 'cancel-apply',
        name: 'CancelApplyPopup',
        component: CancelApplyPopup,
      },
      {
        path: 'exchange-apply',
        name: 'ExchangeApplyPopup',
        component: ExchangeApplyPopup,
      },
      {
        path: 'return-apply',
        name: 'ReturnApplyPopup',
        component: ReturnApplyPopup,
      },
      {
        path: 'cancel',
        name: 'CancelPopup',
        component: CancelPopup,
      },
      {
        path: 'exchange',
        name: 'ExchangePopup',
        component: ExchangePopup,
      },
      {
        path: 'return',
        name: 'ReturnPopup',
        component: ReturnPopup,
      },
      {
        path: 'cancel-accept',
        name: 'CancelAcceptPopup',
        component: CancelPopup,
      },
      {
        path: 'exchange-accept',
        name: 'ExchangeAcceptPopup',
        component: ExchangeAcceptPopup,
      },
      {
        path: 'return-accept',
        name: 'ReturnAcceptPopup',
        component: ReturnAcceptPopup,
      },
      {
        path: 'already-delivery',
        name: 'AlreadyDeliveryPopup',
        component: AlreadyDeliveryPopup,
      },
      {
        path: 'collect',
        name: 'CollectPopup',
        component: CollectPopup,
      },
      {
        path: 'return-info/edit',
        name: 'ReturnInfoEditPopup',
        component: ReturnInfoEditPopup,
      },
      {
        path: 'naverpay-hold',
        name: 'NaverPayReturnHold',
        component: NaverPayReturnHold,
      },
      {
        path: 'payco-login',
        name: 'PaycoLoginPopup',
        component: PaycoLoginPopup,
      },
      {
        path: 'contract-file',
        name: 'StandardContractFilePopup',
        component: StandardContractFilePopup,
      },
      {
        path: 'shipping-charge-area-fee',
        name: 'ShippingChargeAreaFeePopup',
        component: ShippingChargeAreaFeePopup,
      },
      {
        path: 'order/first-discount',
        name: 'OrderFirstDiscount',
        component: OrderFirstDiscount,
      },
      {
        path: 'order/last-discount',
        name: 'OrderLastDiscount',
        component: OrderFirstDiscount,
      },
      {
        path: 'delivery/first-amt-detail',
        name: 'DeliveryFirstAmtDetail',
        component: DeliveryFirstAmtDetail,
      },
      {
        path: 'order/naver-delay',
        name: 'NaverDelay',
        component: NaverDelay,
      },
      {
        path: 'delivery/last-amt-detail',
        name: 'DeliveryLastAmtDetail',
        component: DeliveryFirstAmtDetail,
      },
      {
        path: 'order/benefit',
        name: 'Benefit',
        component: Benefit,
      },
      {
        path: 'delivery/print-specification',
        name: 'OrderSpecificationPopup',
        component: OrderSpecificationPopup,
      },
      {
        path: 'delivery/combine',
        name: 'DeliveryCombine',
        component: DeliveryCombine,
      },
      {
        path: 'delivery/invoiceFormat/:invoiceType',
        name: 'DeliveryInvoiceForm',
        component: DeliveryInvoiceForm,
      },
      {
        path: 'inquiry-detail',
        name: 'InquiryDetail',
        component: InquiryDetailPopupIframe,
      },
      {
        path: 'guide-template',
        name: 'DefaultGuideTemplateForm',
        component: DefaultGuideTemplateForm,
      },
      // 상품
      {
        path: 'product/product-inquiry',
        name: 'ProductInquiry',
        component: ProductInquiry,
      },
      {
        path: 'product/product-judgement/:mallProductNo',
        name: 'ProductJudgement',
        component: ProductJudgement,
      },
      // 디자인
      {
        path: 'design/product-inquiry',
        name: 'DesignProductInquiry',
        component: DesignProductInquiry,
      },
      // 주문
      {
        path: 'order/product-inquiry',
        name: 'OrderProductInquiry',
        component: OrderProductInquiry,
      },
      // 프로모션
      {
        path: 'promotion/product-inquiry',
        name: 'PromotionProductInquiry',
        component: PromotionProductInquiry,
      },
      // 파트너 > 계약서류 조회
      {
        path: 'partner/edit/contract-files',
        name: 'PartnerContractFilesPopup',
        component: PartnerContractFilesPopup,
      },
      // 파트너 > 파트너 신규등록
      {
        path: 'partner/entry-contract-file',
        name: 'EntryContractFilePopup',
        component: EntryContractFilePopup,
      },
      // 파트너 > 파트너 수정 > 입점계약서 보기
      {
        path: 'partner/entry-contract-file-read-only',
        name: 'EntryContractFilePopupReadOnly',
        component: EntryContractFilePopupReadOnly,
      },
      // 파트너 > 파트너 수정 > 입점계약서 등록
      {
        path: 'partner/entry-contract-file-registration',
        name: 'EntryContractFilePopupRegistration',
        component: EntryContractFilePopupRegistration,
      },
      // 파트너 > 파트너 수정 > 입점계약서 계약등록취소/계약등록 변경취소
      {
        path: 'partner/entry-contract-file-cancel',
        name: 'EntryContractFilePopupCancel',
        component: EntryContractFilePopupCancel,
      },
      // 파트너 > 파트너 수정 > 입점계약서 계약등록취소/계약등록 변경취소
      {
        path: 'partner/entry-contract-file-modification',
        name: 'EntryContractFilePopupModification',
        component: EntryContractFilePopupModification,
      },
      {
        path: 'partner/notice-registration',
        name: 'PartnerNoticeFormRegistrationPopup',
        component: PartnerNoticeFormRegistrationPopup,
      },
      {
        path: 'partner/notice-modification',
        name: 'PartnerNoticeFormModificationPopup',
        component: PartnerNoticeFormModificationPopup,
      },
      {
        path: 'partner/notice-category-changer',
        name: 'PartnerNoticeCategoryChanger',
        component: PartnerNoticeCategoryChanger,
      },
      //마케팅
      {
        path: 'MerchantUserManage',
        name: 'MerchantUserManage',
        component: MerchantUserManage,
      },
      {
        path: 'AdsUserManage',
        name: 'AdsUserManage',
        component: AdsUserManage,
      },
    ],
  };
};
