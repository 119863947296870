import { GridProp } from '@/types';
import {
  getProductManagementCdColumn,
  getProductNameColumn,
  getProductNoColumn,
  getSalePeriodColumn,
  getSaleStatusColumn,
  getShippingCombinableColumn,
} from '@/components/product/common/girdColumns/ProductCommonGridColumns';
import { AppliedDeliveryTemplateQuery } from '@/types/delivery';

export const getAppliedDeliveryTemplateGridOption = (): GridProp => {
  return {
    header: {},
    columns: [
      getProductNoColumn('productNo'),
      getProductNameColumn(false),
      getProductManagementCdColumn('managementCode'),
      getSaleStatusColumn('saleStatus'),
      getShippingCombinableColumn(120, 'bundleDeliveryAvailable'),
      getSalePeriodColumn(250, 'salePeriodForDeliveryTemplate'),
    ],
    options: {
      scrollY: true,
      heightResizable: true,
      minBodyHeight: 40,
      bodyHeight: 200,
      columnOptions: {
        resizable: true,
      },
      pageOptions: {
        perPage: 15,
        page: 1,
        totalCount: 0,
      },
    },
    displayOptions: {
      pageSizeKey: 'size',
      showsRightArea: false,
    },
  };
};

export const getDefaultQuery = (deliveryTemplateNo: string, page = 1): AppliedDeliveryTemplateQuery => {
  return {
    deliveryTemplateNo,
    size: 15,
    page,
  };
};

export const getRadioOption = () => {
  return {
    addArea: {
      name: 'addArea',
      data: [
        {
          id: 'address',
          name: 'address',
          display: '주소 검색', // TODO 기획 수정예정
        },
        {
          id: 'state',
          name: 'state',
          display: '지역 검색', // TODO 기획 수정예정
        },
      ],
    },
  };
};

export const getDefaultPaginationQuery = () => {
  return {
    page: 1,
    size: 30,
  };
};
