






































import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { GetNoticeListRequest } from 'ncp-api-supporter';

import Grid from '@/components/common/grid/Main.vue';

import { isEmpty } from 'underscore';
import { getGridProps } from '@/views/contents/partner/notice/PartnerNoticeList';
import { writeExcelFile } from '@/utils/webExcel';
import { getToday } from '@/utils/dateFormat';
import { PARTNER_NOTICE_POPUP_TYPE } from '@/const/partner';
import { ValueOf } from '@/types';
import { PopupClose, throwWindowPopup } from '@/helpers/popup';

@Component({
  components: {
    Grid,
  },
})
export default class PartnerNoticeList extends Vue {
  @Ref()
  private readonly gridRef: Grid;

  private readonly gridProps = getGridProps();

  private notices = []; // NOTE: GetNoticeList['contents'] 타입을 사용합니다
  private totalCount = 0;

  private get query(): GetNoticeListRequest['params'] {
    return this.$route.query;
  }

  // 공지사항 검색
  @Watch('$route.query')
  private searchPartners() {
    if (isEmpty(this.query)) return;

    this.fetchNotices().then(({ data }) => {
      this.notices = data.contents;
      this.totalCount = data.totalCount;
    });
  }

  private fetchNotices(query?: GetNoticeListRequest['params']) {
    return this.$api.getNoticeList({ params: query ?? this.query });
  }

  // 공지사항 검색 결과 엑셀로 다운로드
  private downloadExcel() {
    const query = {
      ...this.query,
      page: 1,
      pageSize: 10000, // NOTE 기획요청으로 전체 데이터 조회를 위해 하드코딩
    };

    this.fetchNotices(query).then(({ data: { contents } }) => {
      contents.length > 0 &&
        writeExcelFile(contents, this.excelHeader, `board_article_${getToday()}`, true, this.excelContentFormatter);
    });
  }

  private get excelHeader() {
    return Object.fromEntries(this.gridProps.columns.map(({ name, header }) => [name, header]));
  }

  private get excelContentFormatter() {
    const customFormatter = {
      noticeTitle: ({ value }) => value,
    };

    return Object.fromEntries(
      this.gridProps.columns
        .filter(column => Boolean(column?.formatter))
        .map(({ name, formatter }) => [name, customFormatter[name] ?? formatter]),
    );
  }

  private deleteNotice() {
    const selectedNoticeNos = this.gridRef.getCheckedRowKeys();

    if (!selectedNoticeNos.length) {
      alert('공지사항을 선택해 주세요.');
      return;
    }

    if (!confirm('선택한 공지사항을 삭제하시겠습니까? 삭제한 공지사항은 복원되지 않습니다.')) return;

    const request = {
      pathParams: { noticeNos: selectedNoticeNos.join(',') },
    };

    this.$api.deleteNoticeByNoticeNo(request).then(({ data }) => {
      alert(`성공 ${data.successCount}건`);
      this.searchPartners();
    });
  }

  // 그리드 클릭 이벤트
  private onItemClicked({ columnName, rowKey }) {
    this.gridClickEventTriggerBy[columnName]?.(this.gridRef.getRow(rowKey));
  }

  private gridClickEventTriggerBy = {
    noticeTitle: ({ noticeNo }) => {
      throwWindowPopup(
        'PartnerNoticeFormModificationPopup',
        { noticeNo },
        'lg',
        ({ state }) => state === PopupClose.CONFIRM && this.searchPartners(),
      );
    },
  };

  private readonly popupType = PARTNER_NOTICE_POPUP_TYPE;
  private popUpBy(type: ValueOf<typeof PARTNER_NOTICE_POPUP_TYPE>) {
    this.popUpTrigger[type]?.();
  }

  // TODO 세부 작업하면서 구조가 변경될 수 있습니다
  private popUpTrigger = {
    [this.popupType.MOVE]: () => {
      const gridRef = this.$refs.gridRef as Grid;
      const noticeNos = gridRef.getCheckedRowKeys();
      const totalCount = noticeNos.length;

      if (totalCount < 1) {
        alert('공지사항을 선택해 주세요.');
        return;
      }

      const displayLabel = noticeNos.length === 1 ? gridRef.getRowAt(0).noticeTitle : `${noticeNos.length} 건`;

      throwWindowPopup(
        'PartnerNoticeCategoryChanger',
        { noticeNos, displayLabel },
        'lg',
        ({ state }) => state === PopupClose.CONFIRM && this.searchPartners(),
      );
    },
    [this.popupType.REGISTER]: () => {
      // NOTE data = null 인 경우 openId가 맵핑이 되지 않는 이슈가 있어 {} 값으로 보냄
      throwWindowPopup(
        'PartnerNoticeFormRegistrationPopup',
        {},
        'lg',
        ({ state }) => state === PopupClose.CONFIRM && this.searchPartners(),
      );
    },
  };
}
