export default Object.freeze({
  // MEMBER_GROUP_
  TITLE: '회원그룹 관리', // MEMBER_GROUP_TITLE

  SETTING: '회원그룹 설정',
  BTN_ADD: '+회원그룹추가',
  BTN_DELETE: '삭제',

  HINT_GROUPS_EMPTY: '회원그룹을 생성 해 주세요.',
  TITLE_GROUP_NAME: '그룹명',
  TITLE_GROUP_NUM: '회원수',
  ITEM_NUM: '명',

  DETAIL_EMPTY: '좌측에서 회원그룹을 추가 하시면, 상세설정이 가능합니다.',
  DETAIL_SETTING_TITLE: '그룹 상세설정',
  DETAIL_MALL_NAME: '쇼핑몰',
  DETAIL_GROUP_NAME: '그룹 이름',
  DETAIL_NEW_GROUP_NAME: '새 그룹명',
  DETAIL_GROUP_DESCRIPTION: '그룹 설명',
  DETAIL_POINT_SETTING: '그룹 적립금 혜택',
  DETAIL_POINT_USED: '사용',
  DETAIL_POINT_UNUSED: '사용 안 함',
  DETAIL_POINT: '적립',

  BTN_SETTING: '변경사항 저장',

  ERR_RESERVE_RATE: '입력형식이 잘못되었습니다.',
  ERR_RESERVE_RATE_100: '100 이하로 입력해주세요.',

  ERR_GROUP_NAME_NULL: '그룹 이름이 지정되지 않았습니다.',
  ERR_GROUP_POINT_RATE_NULL: '그룹 적립금 혜택이 지정되지 않았습니다',
  ERR_GROUP_POINT_RATE_MUST_LARGER_THAN_0: '그룹 적립금 혜택을 0%보다 크게 입력해주세요.',
  ERR_DELETE_GROUP_WITH_CUSTOMER: '회원이 등록된 회원그룹은 삭제할 수 없습니다. ',
  ERR_MODIFIED_CAN_NOT_CHANGE_ORDER: '변경사항이 있습니다. 변경사항 저장한 후에 순서를 조정하실 수 있습니다.',
  ERR_MODIFIED_NOT_SAVED: '변경사항이 있습니다.',
  COMFIRM_BEFORE_DELETE_GROUP: '등록된 회원그룹을 삭제하시겠습니까?',
  COMFIRM_BEFORE_MODIFY: '변경 된 내용을 저장하시겠습니까?',
});
