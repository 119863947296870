import { render, staticRenderFns } from "./NotificationNotAvailable.vue?vue&type=template&id=7dcbd211&scoped=true&"
import script from "./NotificationNotAvailable.vue?vue&type=script&lang=ts&"
export * from "./NotificationNotAvailable.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationNotAvailable.vue?vue&type=style&index=0&id=7dcbd211&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dcbd211",
  null
  
)

export default component.exports