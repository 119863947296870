



































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { drawRemainingDurationTime } from '@/utils/dateFormat';
import { Admin } from '@/types';
import { i18nMallCreate } from '@/const/contents/configuration/mallCreate';
import {
  GetSmsGodoAuthRequest,
  NCPResponse,
  PostSmsGodoAuthRequest,
  GetSmsGodoAuth,
  PostSmsGodoAuth,
} from 'ncp-api-supporter';
import { mall } from '@/utils/mall';
import Captcha, { MAX_COUNT_TO_SHOW_CAPTCHA } from '@/components/common/Captcha.vue';
/*
 * 팝업 화면에 노출 시킬 메세지
 * data.messages : string[]
 * */
@Component({
  components: { Captcha, TextInput, SelectBox },
})
export default class AuthenticateSecurity extends Vue {
  @Getter('admin/getAdmin') admin!: Admin;
  @Prop() private data;
  @Prop() private onPositiveClick!: () => void;
  @Prop() private onNegativeClick!: () => void;
  private inputSmsCode = '';
  private authSmsCodeExpireTime = 0;
  private firstCreatedMallNo: number | null = null;
  private intervalAuthTimer: ReturnType<typeof setInterval>;
  private retryErrorCount = 0;
  private isTimeOut = false;
  private beforeDestroy() {
    this.clearInterval();
  }

  private get needsCaptchaAuth(): boolean {
    return this.retryErrorCount >= MAX_COUNT_TO_SHOW_CAPTCHA;
  }

  created() {
    this.firstCreatedMallNo = mall.firstCreatedMall.mallNo;
  }
  @Watch('authSmsCodeExpireTime')
  private onAuthSmsExpireTimeChanged() {
    if (this.authSmsCodeExpireTime > 0) {
      this.runAuthTimer();
    } else {
      this.isTimeOut = true;
      this.clearInterval();
      alert(this.$t('AUTHORITY.SECURITY.TIME_OUT_MESSAGE'));
    }
  }
  private clearInterval() {
    if (this.intervalAuthTimer) {
      clearInterval(this.intervalAuthTimer);
      this.intervalAuthTimer = null;
    }
  }
  private runAuthTimer() {
    this.clearInterval();
    this.intervalAuthTimer = setInterval(() => {
      this.authSmsCodeExpireTime--;
    }, 1000);
  }
  private get expireTime(): string {
    if (this.authSmsCodeExpireTime > 0) {
      return drawRemainingDurationTime(this.authSmsCodeExpireTime * 1000);
    } else {
      return '-:-';
    }
  }
  /* events */
  private async onAuthSmsSendingButtonClicked() {
    alert(i18nMallCreate('RECEIVING_AUTH_CODE_ALERT'));
    const expireTime = await this.generateAuthCode();
    this.inputSmsCode = '';
    this.authSmsCodeExpireTime = expireTime;
  }
  private async generateAuthCode(): Promise<number> {
    const request: PostSmsGodoAuthRequest = {
      params: {
        mallNo: this.firstCreatedMallNo,
      },
    };
    try {
      const { data }: NCPResponse<PostSmsGodoAuth> = await this.$api.postSmsGodoAuth(request);
      if (data.expiredSecondTime > 0) return data.expiredSecondTime;
      else return -1;
    } catch (e) {
      if (e.code === 'FORBIDDEN_ERROR') {
        this.data?.authErrorMsg && alert(this.$t('MEMBER.MAIL.NO_AUTH_ALERT'));
        this.onNegativeClick();
        return;
      }
      if (e?.data?.code === 'GL0003') {
        // 중계서버 오류로 인증번호 발송 불가
        alert(this.$t('MEMBER.MAIL.FAIL_TO_SEND_AUTH_NUMBER'));
        this.onNegativeClick();
        return;
      }
      return -1;
    }
  }

  @Ref()
  private readonly captcha!: Captcha;
  private async onCompleteAuthButtonClicked() {
    let errMessage = '';
    if (!this.inputSmsCode) {
      errMessage = this.$t('AUTHORITY.SECURITY.HELP_MSG_PLEASE_AUTH_CODE') as string;
    }
    try {
      if (this.needsCaptchaAuth) {
        await this.captcha?.submitCode(true);
        this.retryErrorCount = 0;
        return;
      }

      if (!errMessage) {
        const request: GetSmsGodoAuthRequest = {
          params: { mallNo: this.firstCreatedMallNo, key: this.inputSmsCode },
        };
        const { data }: NCPResponse<GetSmsGodoAuth> = await this.$api.getSmsGodoAuth(request);
        if (data.authMatchResult === false) {
          this.retryErrorCount++;
          errMessage = this.$t('AUTHORITY.SECURITY.HELP_MSG_INPUT_CORRECTLY') as string;
        }
      }
    } catch (e) {
      this.captcha?.errorHandler(e, false);
      errMessage = e.data.message;
      console.error(e);
    }
    if (errMessage) {
      alert(errMessage);
    } else {
      // 모든 validation 통과
      alert(this.$t('AUTHORITY.SECURITY.HELP_MSG_COMPLETE_AUTH'));
      this.onPositiveClick();
    }
  }
}
