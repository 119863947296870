export default Object.freeze({
  TITLE: '주소찾기',
  INPUT: '주소 입력',
  RESULT: '검색 결과',
  SEARCH: '조회',
  EXAMPLE: '지번 또는 도로명을 입력해주세요. 예) 세종대로 110, 성남 시청, 삼평동 629',
  TABLE_TIT: '조회결과',
  TABLE_TIT_UNIT: '건',
  NOT_DATA: '검색 결과가 없습니다.',
  ALERT_MSG: '지번 또는 도로명을 입력해주세요',
  ZIP_CODE: '우편번호',
  ROAD_NAME: '도로명',
  NUMBER: '지번주소',
});
