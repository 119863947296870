

































































































import { Component, Vue } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import {
  applyTypeOption,
  getInitKakaoTemplateQuery,
  inspectionStatusTypeOption,
  searchTypeOption,
  templateTypeOption,
} from '@/const/contents/kakao';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import moment from 'moment';
import { isValidate } from '@/utils/validate';
import { getCurrentMallNo } from '@/utils/mall';

@Component({
  components: { SearchButton, DateRangePicker, SelectBox },
})
export default class NotificationTemplateSearchForm extends Vue {
  private kakaoTemplateQuery = getInitKakaoTemplateQuery(getCurrentMallNo(this));
  private searchTypeOption = searchTypeOption;
  private templateTypeOption = templateTypeOption;
  private inspectionStatusTypeOption = inspectionStatusTypeOption;
  private applyTypeOption = applyTypeOption;

  private validateDate(): boolean {
    const start = moment(this.kakaoTemplateQuery.startDate);
    const end = moment(this.kakaoTemplateQuery.endDate);
    const overPeriodYear = end.diff(start, 'year', true) <= 1;

    return isValidate(overPeriodYear, this.$t('MEMBER.KAKAO.PERIOD_MAX_LIMIT'));
  }

  private resetQuery() {
    this.kakaoTemplateQuery = getInitKakaoTemplateQuery(getCurrentMallNo(this));
    this.kakaoTemplateQuery.mallNo = getCurrentMallNo(this);
  }
}
