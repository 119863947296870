import { VNodeDirective } from 'vue';

const patternMap: Map<string, string> = new Map([['num', '^[0-9]*$']]);

// validate input text pattern
// data-pattern
function update(el: HTMLElement, binding: VNodeDirective): void {
  // const inputValue = (el as HTMLDataElement).value;
  const pattern = patternMap.get(binding.value);

  if (pattern === undefined) {
    throw new Error(`${pattern} is not a pattern`);
  }

  // const regex = new RegExp(pattern, 'g');
  // const valid = regex.test(inputValue);
}

export default { update };
