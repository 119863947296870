export default Object.freeze({
  DOWNLOAD: '엑셀 다운로드',
  DOWNLOAD_FILE: '엑셀 파일 다운로드',

  MENU_TYPE: '메뉴 분류',
  MENU_TYPE_MALL: '설정',
  MENU_TYPE_ORDER: '주문/배송',
  MENU_TYPE_PRODUCT: '상품',
  MENU_TYPE_MEMBER: '회원',
  MENU_TYPE_BOARD: '게시판',
  MENU_TYPE_PROMOTION: '프로모션',

  STATUS: '파일상태',
  STATUS_REQUEST: '생성요청',
  STATUS_GENERATE: '생성중',
  STATUS_CANCEL: '생성취소',
  STATUS_COMPLETE: '생성완료',
  STATUS_FAIL: '생성실패',

  MY_SELF: '내가 요청한 파일만 보기',

  NO: '번호',
  MENU_CATEGORY: '메뉴분류',
  FILE_NAME: '파일명',
  EXCEL_STATUS: '파일상태',
  REQUESTER: '요청자',
  DOWNLOAD_DATE: '다운로드 기간',
  REASON: '사유',
  PASSWORD: '비밀번호',
  PLACEHOLDER_REASON_SAMPLE: '예) 주문관리',
  REQUEST: '요청',

  ALERT_MISSING_CHECK: '조회하려는 {name}을 체크해주세요.',

  CONFIRM_CREATE:
    '엑셀 다운로드 파일을 생성 하시겠습니까?\n생성된 엑셀 파일은 엑셀다운로드 팝업창에서 확인 및 다운로드 가능합니다.',
  CONFIRM_DOWNLOAD: '엑셀 파일을 다운로드하시겠습니까?\n파일 용량이 큰 경우 다운로드 시간이 오래 걸릴 수 있습니다.',

  NOTICE_LIST:
    '개인정보의 안전성 확보조치 기준(고시)에 의거하여 개인정보를 다운로드 한 경우 사유 확인이 필요합니다.\n' +
    '개인정보 저장 시 암호화가 의무이므로 비밀번호를 필수로 입력합니다. 영문/숫자/특수문자 조합하여 10~16자 이내로 입력해주세요.\n' +
    '원활한 엑셀다운로드를 위해 AES-256 알고리즘을 지원하는 압축 프로그램 이용을 권고드립니다.',

  ALERT_REASON_EMPTY: '사유를 입력해주세요.',
  ALERT_PASSWORD_EMPTY: '비밀번호를 입력해주세요.',
  ALERT_PASSWORD_INVALID: '비밀번호는 영문/숫자/특수문자 조합하여\n10~16자 이내로 입력하셔야 합니다.',
  ALERT_NO_DOWNLOAD_PERMISSION: '파일 다운로드 권한이 없습니다.\n대표운영자에게 문의하시기 바랍니다.',
  EXPIRATION_DATE: '기간만료',
});
