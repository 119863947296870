















































import { Component, Vue, Watch } from 'vue-property-decorator';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';
import { i18nListByKey } from '@/utils/i18n';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { range1ToNI18n } from '@/utils/common';
import { throwPopup, throwWindowPopup } from '@/helpers/popup';
import {
  GetKakaoAutoTemplatesGroup,
  GetUsedKakaoTemplatesGroupRequest,
  KakaoTemplateSettingData,
  NCPResponse,
  TemplateGroupType,
} from 'ncp-api-supporter';
import KakaoTemplate from '@/views/contents/member/kakao/KakaoTemplate.vue';
import { namespace } from 'vuex-class';
import { getCurrentMallNo } from '@/utils/mall';
import NotificationConfig from '@/views/contents/member/kakao/NotificationConfig.vue';

const member = namespace('member');

@Component({
  components: { KakaoTemplate, NoticeBox, TabMenu, NotificationConfig },
})
export default class NotificationMain extends Vue {
  @member.Getter('getKakaoTemplateSetting')
  private kakaoTemplateSetting!: KakaoTemplateSettingData[];
  @member.Action('resetKakaoTemplateSetting')
  private resetKakaoTemplateSetting!: () => void;

  private menuList: TabMenuI[] = [
    { display: 'MEMBER.KAKAO.CONFIG_MENU', path: 'config' },
    { display: 'MEMBER.KAKAO.TEMPLATE_MENU', path: 'template' },
    { display: 'MEMBER.KAKAO.HISTORY_MENU', path: 'history' },
  ];
  private typeInfo = [
    { name: '회원 관련', type: 'MEMBER' },
    { name: '주문배송 관련', type: 'ORDER_DELIVERY' },
    { name: '문의 관련', type: 'INQUIRY' },
    // { name: '운영자 안내 관련', type: 'ADMIN' }, 그랜드오픈 2차 스펙 제외
  ];
  private kakaoSenderKey = null;

  private readonly noticeList = range1ToNI18n(7, 'MEMBER.KAKAO.TEMPLATE_NOTICE_');
  private templateGroups: GetKakaoAutoTemplatesGroup[] = [];

  private get type(): TemplateGroupType {
    switch (this.$route.query.templateType) {
      case 'MEMBER':
        return 'MEMBER';
      case 'ORDER_DELIVERY':
        return 'ORDER_DELIVERY';
      case 'INQUIRY':
        return 'INQUIRY';
      case 'ADMIN':
        return 'ADMIN';
    }
    return 'MEMBER';
  }

  private get isConfigPage(): boolean {
    return this.$route.name === 'NotificationConfig';
  }

  private isFocus(type: string): boolean {
    return type === this.type;
  }
  private setKakaoSenderKey(data) {
    this.kakaoSenderKey = data;
  }
  private openPopup() {
    if (this.kakaoSenderKey) {
      throwWindowPopup('SmsManager', { type: 'kakao', mallNo: getCurrentMallNo(this) }, 'md');
      return;
    }
    alert(this.$t('MEMBER.KAKAO.SENDER_PROFILE_REGISTER'));
  }

  private registerClickEvent() {
    if (!this.isConfigPage) return;
    const button = this.$el.querySelector('.notice_button');
    const oauthTypeButton = this.$el.querySelector('.oauth_type_button');
    oauthTypeButton?.addEventListener('click', this.popUpOauthTypeNotice);
    button?.addEventListener('click', NotificationMain.openNoticePopup);
  }

  private static openNoticePopup() {
    throwPopup({ name: 'KakaoTemplatePrecaution' });
  }

  private popUpOauthTypeNotice() {
    throwPopup({ name: 'OauthTypeNotice', data: { name: this.$route.name } });
  }

  // @Watch('type') //@fixme route 가 변경되면 어차피 type 도 변경 됨 특별한 오류 없으면 삭제하기
  public async getKakaoTemplate() {
    const request: GetUsedKakaoTemplatesGroupRequest = {
      pathParams: {
        groupType: this.type,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
    };

    try {
      const response: NCPResponse<GetKakaoAutoTemplatesGroup[]> = await this.$api.getUsedKakaoAutoTemplatesGroup(
        request,
      );
      this.templateGroups = response.data;
    } catch (err) {
      this.templateGroups = [];
      throw err;
    }
  }

  // @Watch('kakaoTemplateSetting') // @todo 얘 때문에 getKakaoTemplate 가 반복 호출 됨 특별한 일 없으면 카알 완료시점에 삭제하기
  @Watch('$route')
  private changeSelectedTemplate() {
    if (this.isConfigPage) this.getKakaoTemplate();
  }

  created() {
    this.changeSelectedTemplate();
    this.menuList = i18nListByKey(this, this.menuList, 'display');
  }

  mounted() {
    this.registerClickEvent();
  }

  updated() {
    this.registerClickEvent();
    this.resetKakaoTemplateSetting();
  }

  destroyed() {
    if (!this.isConfigPage) return;
    const button = this.$el.querySelector('.notice_button');
    button?.removeEventListener('click', NotificationMain.openNoticePopup);
  }
}
