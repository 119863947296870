



































import { Vue, Component, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { sendQueryString } from '@/utils/query';
import { ThrowInputValidationError } from '@/utils/validate';
import PartnerRegisterTab from '@/components/partner/register/PartnerRegisterTab.vue';

@Component({
  components: {
    PartnerRegisterTab,
    TextInput,
    NoticeBox,
  },
})
export default class PartnerRegisterSearchForm extends Vue {
  @Prop({ required: true })
  private readonly noticeList: string[];

  private searchForm = {
    companyName: '',
    registrationNo: null,
  };

  private sendQueryString() {
    try {
      this.validateSearchForm();
      sendQueryString(this, { ...this.searchForm }, true, true);
    } catch ({ target, message }) {
      message && alert(message);
      target && (this.$refs[`${target}Ref`] as TextInput).focus();
    }
  }

  private validateSearchForm() {
    if (!this.searchForm.companyName)
      ThrowInputValidationError.of('companyName', '검색하실 파트너사의 회사명을 입력하세요.');
    if (!this.searchForm.registrationNo)
      ThrowInputValidationError.of('registrationNo', '검색하실 파트너사의 사업자 등록번호를 입력하세요.');
  }
}
