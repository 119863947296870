





















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getCurrentMallNo } from '@/utils/mall';
import { throwPopup } from '@/helpers/popup';
import {
  getCampaignsRequirements,
  getConfigurationsCampaigns,
  changeCampaignStatus,
} from '@/components/popup/marketing/GoogleConfigureCheck';
import { getCampaigns } from '@/views/contents/marketing/googleAdConfig';
import { GetConfigurationsCampaigns, GetConfigurationsCampaignsRequirements } from 'ncp-api-supporter';

@Component({})
export default class AdManage extends Vue {
  private googleConfigIsAllTrue = false;
  private googleConfigData?: GetConfigurationsCampaignsRequirements = null;
  private adData?: GetConfigurationsCampaigns = null;

  @Watch('$route.query.mallNo')
  private changeMallNoQuery() {
    this.adData = null;
    this.adManageInit();
  }

  private get mallNo() {
    return getCurrentMallNo(this);
  }

  created() {
    this.adManageInit();
  }

  private async adManageInit() {
    const { hasCampaign } = await getCampaigns(this.mallNo); //캠페인이 있는지 확인

    if (hasCampaign) {
      // 캠페인이 있으면 화면에 캠페인 정보 데이터 렌더링 함
      await this.getCampaignData();
    }

    const { data, isAllTrue } = await getCampaignsRequirements(this.mallNo); // 캠페인 유효성 확인

    this.googleConfigIsAllTrue = isAllTrue; // *캠페인 유효성 확인하여 전역 변수 설정
    this.googleConfigData = data; // *캠페인 유효성 결과 전역 변수 설정

    if (!this.googleConfigIsAllTrue) {
      // 만약 유효성이 통과 안되면 '구글 설정 확인' 팝업 출력
      this.openGoogleConfigureCheckPopup(data);
    } else if (this.googleConfigIsAllTrue && !hasCampaign) {
      // 만약 유효성이 통과 되고 && 캠페인이 없으면 '구글 광고 등록' 팝업 출력
      this.openGoogleAdRegistPopup();
    }
  }

  private async getCampaignData() {
    const campaignData = await getConfigurationsCampaigns(this.mallNo);
    this.adData = campaignData === null ? null : campaignData[0];
  }

  private async startGoogleAdConfig() {
    if (this.googleConfigIsAllTrue) {
      this.openGoogleAdRegistPopup();
      return;
    }

    this.openGoogleConfigureCheckPopup(this.googleConfigData);
  }

  private openGoogleConfigureCheckPopup(requirementsData) {
    throwPopup({
      name: 'GoogleConfigureCheck',
      data: {
        requirementsData,
        mallNo: this.mallNo,
        adData: this.adData,
      },
    }).then(res => {
      if (res.data.state === 'reRender') {
        this.getCampaignData();
      }
    });
  }

  private openGoogleAdRegistPopup() {
    throwPopup({
      name: 'GoogleAdRegist',
      data: {
        mallNo: this.mallNo,
      },
    }).then(res => {
      if (res.data.state === 'reRender') {
        this.getCampaignData();
      }
    });
  }

  private async setChangeCampaignStatus() {
    await changeCampaignStatus('nowEnabled', this.mallNo, this.adData.campaignNo);
    this.getCampaignData();
  }
}
