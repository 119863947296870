import { GetProductsConfigurationNaverShopping, NaverShoppingMallGradeType } from 'ncp-api-supporter';

export const gradeCountMap = {
  PREMIUM: 499000,
  BIG_POWER: 99000,
  POWER: 49900,
  SPROUT: 9900,
  SEED: 9900,
} as const;

export type NaverShoppingGrade = keyof typeof gradeCountMap;

export const getDefaultNaverShopping = (): GetProductsConfigurationNaverShopping => ({
  supportsNaverShopping: false,
  authenticationKey: '',
  agreedToCollectingCPAOrder: false,
  mallGrade: 'SEED',
  reviewType: 'PRODUCT_REVIEW',
  maxProductCount: 0,
});

export const getCurrentCountByGrade = (naverShoppingGrade: NaverShoppingMallGradeType) =>
  gradeCountMap[naverShoppingGrade];
