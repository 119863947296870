






import { Vue, Component } from 'vue-property-decorator';
import { i18nMallCreate } from '@/const/contents/configuration/mallCreate';
import NoticeBox from '@/components/common/NoticeBox.vue';

@Component({
  components: { NoticeBox },
})
export default class MallCreate extends Vue {
  private noticeList = i18nMallCreate('NOTICE_MESSAGE').split('\n');
}
