





























































































































































































































import { Vue, Component } from 'vue-property-decorator';
// components
import SelectBox from '@/components/common/SelectBox.vue';
import PartnerMallSelectBox from '@/components/partner/register/PartnerMallSelectBox.vue';
import NewPartnerEntryContractYn from '@/components/partner/register/NewPartnerEntryContractYn.vue';
import MerchandiserSelect from '@/components/common/input/data/MerchandiserSelect.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import PartnerRegisterBasicInformation from '@/components/partner/register/PartnerRegisterBasicInformation.vue';

import { handleFeeRateBeforeBlur, handleFeeRateChange } from '@/views/contents/partner/hooks/feeRateReplaceHook';
import countries from '@/const/words/country';
import tradeBanks from '@/const/words/tradeBank';
import { GetPartnersActivePartnerNo, PostContractsRequest } from 'ncp-api-supporter';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { ThrowInputValidationError } from '@/utils/validate';
import { openCreditChangePopup } from '@/helpers/popup';
import { Admin } from '@/types';
import { adminStore } from '@/components/board/Inquiries/SearchQuery';

type ContractInfoType = Omit<PostContractsRequest['data'], 'commissionRate' | 'mallNo'>;
interface ContractInfo extends ContractInfoType {
  commissionRate: string;
  mallNo: string;
}

@Component({
  components: {
    PartnerRegisterBasicInformation,
    TextInput,
    MerchandiserSelect,
    NewPartnerEntryContractYn,
    SelectBox,
    PartnerMallSelectBox,
  },
})
export default class PartnerRegisterSearchDetail extends Vue {
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;
  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  private useEntryContract = false;

  private partnerInfo = {
    // 참조 오류 때문에 참조값 초기 선언
    partner: {
      settlementManager: {},
      internationalCode: {},
      tradeBank: {},
      business: {},
      office: {},
      representative: {},
    },
    admin: {
      contactInfo: {},
    },
  } as GetPartnersActivePartnerNo;

  private request: ContractInfo = {
    commissionRate: '0',
    contractContent: '',
    mallNo: '',
    memo: '',
    merchandiserNo: 0,
    partnerNo: 0,
  };

  private get countryLabel() {
    return countries.find(({ value }) => value === this.partnerInfo.partner?.countryCode)?.name;
  }

  private get bankName() {
    return tradeBanks.find(({ value }) => value === this.partnerInfo.partner.tradeBank.bank)?.name;
  }

  private get office() {
    const office = this.partnerInfo.partner.office;
    const defaultInfo = {
      address: '',
      jibunAddress: '',
    };

    if (!office) return defaultInfo;

    return {
      address: `[${office.zipCode}] ${office.address} ${office.detailAddress}`,
      jibunAddress: `${office.jibun} ${office.jibunDetail}`,
    };
  }

  private readonly handleFeeRateChange = handleFeeRateChange;
  private readonly handleFeeRateBeforeBlur = handleFeeRateBeforeBlur;
  private onFeeRateBlur(feeRate: string) {
    this.$set(this.request, 'commissionRate', feeRate);
  }

  private fetchPartnerInfo() {
    this.$api
      .getPartnersActivePartnerNo({
        pathParams: {
          partnerNo: this.$route.params.partnerNo.toString(),
        },
      })
      .then(({ data }) => {
        this.partnerInfo = data;
        this.$set(this.request, 'partnerNo', data.partner.partnerNo);
      });
  }

  private navigationEventTrigger = {
    save: () => this.save(),
    cancel: () => this.cancel(),
  };

  private save() {
    try {
      this.validateRequest();
      this.postContracts();
    } catch ({ target, message }) {
      message && alert(message);
      target && (this.$refs[`${target}Ref`] as HTMLElement)?.focus();
    }
  }

  private validateRequest() {
    if (!this.request.mallNo) ThrowInputValidationError.of('mallNo', '거래 쇼핑몰을 선택해주세요.');
    if (this.useEntryContract && !this.request.contractContent)
      ThrowInputValidationError.of('contractContent', '입점 계약서를 입력해주세요.');
    if (!this.request.merchandiserNo) ThrowInputValidationError.of('merchandiserNo', '담당자를 선택해주세요.');
  }

  private async postContracts() {
    if (this.admin.plan === 'BASIC') {
      const result = confirm(this.$t('PARTNER.ALERT_BASIC_NOT_REGISTERED') as string);
      result && openCreditChangePopup(this.commerceAccount, this.admin.mallNos[0].toString());

      return;
    }

    this.$api
      .postContracts({
        data: {
          ...this.request,
          contractContent: this.useEntryContract ? this.request.contractContent : '',
          mallNo: Number(this.request.mallNo),
          commissionRate: Number(this.request.commissionRate),
        },
      })
      .then(() => {
        alert('파트너 등록요청 완료');
        this.$router.push({ name: 'PartnerList' });
      });
  }

  private cancel() {
    this.$router.push({ name: 'PartnerRegisterSearch' });
  }

  private setBottomNav() {
    const buttons = [
      {
        type: 'right',
        key: 'cancel',
        color: 'gray',
        text: this.$t('CANCEL'),
      },
      {
        type: 'right',
        key: 'save',
        color: 'red',
        text: this.$t('PARTNER.REGISTER'),
      },
    ];

    throwBottomNavigation({
      buttons,
      onClick: key => this.navigationEventTrigger[key]?.(),
    });
  }

  private created() {
    this.fetchPartnerInfo();
    this.setBottomNav();
  }
}
