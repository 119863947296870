import store from '@/store';
import { Deferred } from '@/types/popup';
import router from '@/router';
import { TranslateResult } from 'vue-i18n';
import { RectangleSize } from 'ncp-api-supporter';
import { api } from '@/api';
import { refreshToken } from '@/utils/token';

export interface PopupData {
  [key: string]: any;
}

export interface PopupResult {
  state: string;
  data: PopupData | null;
}

export interface ThrowMessagePopup {
  message: TranslateResult;
  isConfirm?: boolean;
  headerText?: TranslateResult;
  showOkButtonOnly?: boolean;
  okButtonText?: TranslateResult;
  cancelButtonText?: TranslateResult;
  isCancelIng?: boolean;
}

// yes or no 팝업에서 버튼 응답 결과를 enum으로 변경
export enum PopupClose {
  'CLOSE' = 'close',
  'CONFIRM' = 'confirm',
}
// type WindowPopupSize = 'md' | 'lg' | 'xlg';
export enum WindowPopupSize {
  'sm' = 700,
  'sm-l' = 800,
  'md' = 900,
  'lg' = 1000,
  'xlg' = 1200,
  'xxlg' = 1560,
  'new' = 0, // 새창 열림
  'custom' = 0, // 직접 설정
}

type WindowPopupSizeKeywords = keyof typeof WindowPopupSize;

/*
 * [사용 방법]
 * : 공통의 팝업 UI
 *  https://nhnent.dooray.com/project/pages/2642698987633911352
 * */

const createDeferred = function() {
  const deferred: Deferred = {};
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred;
};

/**
 * div 팝업을 호출하는 함수
 * @param {options.componentName : string} component name
 * @param {options.data : any} 팝업 초기화 할때 필요한 데이터입니다. div 팝업 같은 경우 별도로 $api.get을 호출하기 보다는 부모에서 호출한 데이터가 재활용 되길 희망합니다.
 * @param {size : WindowPopupSize} 팝업 사이즈 (md, lg, xlg) default : lg
 * @returns {Promise} 팝업 프로미스 객체
 *
 * [ example 1 ]
 * throwPopup({
            name: 'ImageManager',
            data: {
                maxCheckableCount: 5,
                showMultipleCheckNumber: false,
                imageIds: []
                }
        }).then(function(result: {state : string, data : object}) {
            if(result.data && result.data.imageIds){
                ...
            }
        });
 * [ example 2 async mode ]
 * let popupResult :{state : string, data : object} = await throwPopup({
            name: 'ImageManager',
            data: {
                maxCheckableCount: 5,
                showMultipleCheckNumber: false,
                imageIds: []
                }
        });
   if(popupResult.state === "confirm"){
            ...
        }
 */
export const throwPopup = function(options) {
  options._deferred = createDeferred();
  options.id = store.getters['popup/sizeOfPopupList'] + 1;

  options.onClose = function(result: PopupResult) {
    options._deferred.resolve(result);
    store.commit('popup/REMOVE_POPUP', options.id);
  };

  store.commit('popup/ADD_POPUP', options);
  return options._deferred.promise;
};

/**
 * 간단 메세지 팝업 ( throwPopup을 wrap한 함수 )
 * 
 * -------------------------------------------------
 * 폐기예정입니다. throwMessagePopupWithProps를 사용하세요.
 * -------------------------------------------------
 * 
 * @param {message : string} 메세지 내용 (html tag 허용)
 * @param {isConfirm : boolean} yes or no (닫기, 확인) 선택 버튼 노출 여부.
 * @param {headerText : string} 기본 : 알림, 변경이 필요하면 기입한다.
 * @returns {Promise} 팝업 프로미스 객체
 *
 * [ example 1 ]
 * throwMessagePopup("변경 사항을 저장하시겠습니까?", true ,"극한의 경고").then((result)=>{
 *     if(result.state === PopupClose.CONFIRM){ console.log( '긍정 버튼 클릭 됨' ) }
 * });
 *
 * [ example 2 async mode ]
    let result :{state : string, data : object} = await throwMessagePopup("변경 사항을 저장하시겠습니까?", true, "극한의 경고");
    if(result.state === "confirm"){
            ...
        }
 */
export const throwMessagePopup = function(
  message: TranslateResult,
  isConfirm?: boolean,
  headerText?: TranslateResult,
  showOkButtonOnly?: boolean,
  okButtonText?: TranslateResult,
  cancelButtonText?: TranslateResult,
  isCancelIng?: boolean,
) {
  return throwPopup({
    name: 'Message',
    data: {
      message: message,
      headerText: headerText,
      isConfirm: isConfirm,
      showOkButtonOnly: showOkButtonOnly,
      okButtonText: okButtonText,
      cancelButtonText: cancelButtonText,
      isCancelIng: isCancelIng,
    },
  });
};

export const throwMessagePopupWithProps = (popupProps: ThrowMessagePopup) => {
  return throwPopup({
    name: 'Message',
    data: { ...popupProps },
  });
};

/**
 * window popup을 호출하는 함수 (기존 ncpPopup 대체)
 *
 * -------------------------------------------------
 * 폐기예정입니다. throwWindowPopupWithProps를 사용하세요.
 * -------------------------------------------------
 * @param componentName : router에 등록된 component의 name (다른 페이지의 name과 중복되지 않게 잘 등록하시길)
 * @param data :  팝업 초기화 시 필요한 데이터입니다. 타입을 특정할 수 없어 any로 지정하였습니다. (적어도 윈도우 팝업에서는 팝업 컴포넌트 내에서 $api.get을 별도로 실행하는게 맞는거 같습니다.)
 * @param param: {any} 팝업 url 에 추가될 쿼리
 * @param size :  팝업 사이즈 (md, lg, xlg) default : lg --> size 정보 url 에도 추가함
 * @param popupHeight :  팝업 높이
 * @param onClose : 자식 윈도우가 닫히는 케이스 (브라우저 x 버튼, UI 닫기 버튼 등)이 눌렸을때 콜백되는 함수 입니다. {state : PopupClose, data : ... }
 * @returns {childWindow : Window} 오픈된 팝업 객체를 리턴
 *
 * [ example ]
 * throwWindowPopup('SettingServiceInfo', this.testData, 'md', function(data) {console.log(data);});
 */
export const throwWindowPopup = (
  componentName: string,
  data: any,
  size: WindowPopupSizeKeywords,
  onClose?: Function,
  name?: string,
  query?: { [key: string]: any },
  sendPostMessage?: (childWindow: Window, path: string) => void,
  popupHeight?: number,
  params?: { [key: string]: string },
): Window => {
  // 브라우저 x 버튼, 팝업 negative 버튼, 팝업 positive 버튼. 세가지의 경우에 대해 동일한 형태의 응답을 리턴해야 한다.
  let hasExecutedCallback = false; // 브라우저 x 버튼이 눌린건지, UI 버튼이 눌린 것인지 판별

  // TODO : UI 설정하는 부분은 추후 더 개선되어야 한다.
  const width = size ? WindowPopupSize[size] : WindowPopupSize.lg;
  const height = popupHeight || 800;
  const x = (window.screenX || window.screenLeft || 0) + (screen.width - width) / 2;
  const y = (window.screenY || window.screenTop || 0) + (screen.height - height) / 2;

  let showsScroll = 'no'; // 이건 뭐하는 옵션이지??
  if (screen.width === 800 && screen.height === 600) {
    showsScroll = 'yes';
  }
  let childWindow: Window | null = null;
  const path = router.resolve({ name: componentName, params }).href;
  const id = new Date().getTime().toString();
  const popupName = name ?? id;
  const popupInitialData = {
    sizeClass: `type-${width}`,
    data: data,
  };

  const onPopupClosed = onClose => {
    return function() {
      if (!hasExecutedCallback) {
        // 브라우저 x 버튼으로 팝업을 닫은 경우임
        onClose && onClose({ state: PopupClose.CLOSE, data: null });
      }
      //localStorage.removeItem(`shopby_${id}`);

      // when refreshing action, don't remove popup parameter data.
      // [pressing 'F5' or calling js reload to refresh]
      const isRefresh = localStorage.getItem(`refresh_${id}`);
      if (!isRefresh) {
        localStorage.removeItem(`shopby_${id}`);
      } else {
        localStorage.removeItem(`refresh_${id}`);
      }
    };
  };

  const onPopupKeydown = function(e: KeyboardEvent) {
    //press F5 to refresh
    if (e.code === 'F5') {
      // it's a action to refresh page .
      localStorage.setItem(`refresh_${id}`, 'Y');
    }
  };

  // 1. inject initial data on localStorage
  localStorage.setItem(`shopby_${id}`, JSON.stringify(popupInitialData));

  // 2. inject callback
  (window as any)[id] = function(result) {
    // UI 닫기 버튼이 눌렸을때 이곳이 먼저 호출되고 onPopupClosed가 호출된다.
    if (result) {
      onClose && onClose(result);
      hasExecutedCallback = true;
    }
    childWindow?.removeEventListener('beforeunload', onPopupClosed);
    childWindow?.removeEventListener('keydown', onPopupKeydown);
  };

  let url = `${path}?width=${width}`;

  if (data) {
    url += `&popupId=${id}`;
  }

  if (query) {
    for (const key in query) {
      url += `&${key}=${query[key]}`;
    }
  }

  // 3. open child window
  if (size === 'new') {
    childWindow = window.open(url, popupName);
  } else {
    childWindow = window.open(
      url,
      popupName,
      `toolbar=no, channelmode=no, location=no, directories=no, menubar=no, resizable=no, top=${y}, left=${x}, scrollbars=${showsScroll}, width=${width}, height=${height}`,
    );
  }

  if (sendPostMessage) {
    sendPostMessage(childWindow, `${path}?popupId=${id}`);
  }
  /*
   * 유저 인터렉션 없이 브라우저 닫기 버튼을 누르게 되면 beforeunload는 호출되지 않는다. (크롬)
   * 그렇게 되면 localStorage를 clear할 방법이 없어진다.
   * 그래서 이곳에서 자식 윈도우에 대한 이벤트를 관리한다.
   * */
  childWindow?.addEventListener('beforeunload', onPopupClosed(onClose));
  childWindow?.addEventListener('keydown', onPopupKeydown);

  if (!childWindow) {
    throw new Error('new Popup Error : window.open return null');
  }

  try {
    //childWindow.moveTo(x, y);   // 이게 동작 하는건가???
    childWindow.focus();
  } catch (e) {
    console.log(e);
  }

  return childWindow;
};

// **** 새로운 윈도우 팝업 오픈 함수입니다 *** 2022.05.12
export interface ThrowWindowPopupProps {
  componentName: string;
  data: any;
  size: WindowPopupSizeKeywords;
  onClose?: Function;
  name?: string;
  query?: { [key: string]: any };
  sendPostMessage?: (childWindow: Window, path: string) => void;
  popupHeight?: number;
  params?: { [key: string]: string };
}

/**
 * window popup을 호출하는 함수 (기존 ncpPopup 대체)
 * @param componentName : router에 등록된 component의 name (다른 페이지의 name과 중복되지 않아야함)
 * @param data :  팝업 초기화 시 필요한 데이터입니다. 타입을 특정할 수 없어 any로 지정하였습니다. (적어도 윈도우 팝업에서는 팝업 컴포넌트 내에서 $api.get을 별도로 실행하는게 맞는거 같습니다.)
 * @param params: {any} 팝업 url 에 추가될 쿼리
 * @param size :  팝업 사이즈 (md, lg, xlg) default : lg --> size 정보 url 에도 추가함
 * @param popupHeight :  팝업 높이
 * @param onClose : 자식 윈도우가 닫히는 케이스 (브라우저 x 버튼, UI 닫기 버튼 등)이 눌렸을때 콜백되는 함수 입니다. {state : PopupClose, data : ... }
 * @returns {childWindow : Window} 오픈된 팝업 객체를 리턴
 */

export const throwWindowPopupWithProps = (popupProps: ThrowWindowPopupProps): Window => {
  const { componentName, data, size, onClose, name, query, sendPostMessage, popupHeight, params } = popupProps;
  return throwWindowPopup(componentName, data, size, onClose, name, query, sendPostMessage, popupHeight, params);
};

// **** 새로운 윈도우 팝업 오픈 함수입니다 *** 2022.05.12

/**
 * 외부 url을 window popup으로 호출하는 함수
 * @returns void
 *
 * [ example ]
 * openWindowPopup('www.nhn-commerce.com', 'md', {
 *   token: this.token,
 *   id: this.id
 * });
 * @param openUrl: 외부 url
 * @param size: WindowPopupSize - 팝업 사이즈 (md, lg, xlg) default : lg
 * @param data: 팝업 인증에 필요한 데이터를 객체 형태로 작성
 */
export const throwExternalWindowPopup = (
  openUrl: string,
  size: WindowPopupSizeKeywords = 'lg',
  data?: object,
  customSize?: RectangleSize,
): Window => {
  const width = size === 'custom' ? customSize.width : WindowPopupSize[size];
  const height = size === 'custom' ? customSize.height : 800;

  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', openUrl);
  form.setAttribute('target', 'throw_external_popup');
  form.setAttribute('enctype', 'application/x-www-form-urlencoded;charset=euc-kr');
  if (data) {
    let inputElement = '';
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        inputElement += data[key]
          .map((value, index) => `<input type='hidden' name='${key}[${index}]' value='${value}'>`)
          .join('');
      } else {
        inputElement += `<input type='hidden' name='${key}' value='${data[key]}'>`;
      }
    });
    form.innerHTML = inputElement;
  }

  document.body.appendChild(form);
  const popup = window.open(
    openUrl,
    'throw_external_popup',
    `resizable=yes,toolbar=yes,menubar=yes,location=yes,width=${width},height=${height}`,
  );
  if (data) form.submit();
  document.body.removeChild(form);
  return popup;
};

/**
 * localStorage 안쓰는 윈도우 팝업 open
 * @param openUrl: url
 * @param size: WindowPopupSize - 팝업 사이즈 (md, lg, xlg) default : lg
 * @param onClose : 자식 윈도우가 닫히는 케이스 (UI 닫기 버튼)이 눌렸을때 콜백되는 함수 입니다. {state : PopupClose, data : ... }, 브라우저 x 버튼 제외
 * @returns void
 */
export const throwNoPopupIdWindowPopup = (
  openUrl: string,
  size: WindowPopupSizeKeywords = 'lg',
  onClose?: Function,
): Window => {
  const width = WindowPopupSize[size];
  const height = 800;
  let popup: Window | null = null;

  const id = new Date().getTime().toString();

  (window as any)[id] = function(result) {
    if (result) {
      onClose && onClose(result);
    }
  };

  (window as any)[id] = function(result) {
    if (result) {
      onClose && onClose(result);
    }
  };

  const url = openUrl.includes('?') ? `${openUrl}&callbackId=${id}` : `${openUrl}?callbackId=${id}`;

  popup = window.open(
    url,
    'throw_external_popup',
    `resizable=yes,toolbar=yes,menubar=yes,location=yes,width=${width},height=${height}`,
  );

  return popup;
};

export const openCreditChangePopup = async (isCommerceAccount: boolean, mallNo: string) => {
  try {
    if (!isCommerceAccount) {
      confirm(window.$t('CREDIT_CHANGE_NEED_MASTER')) && window.open(process.env.VUE_APP_GODO_API_URL);

      return;
    }

    const { data } = await api.getMallsMallNoGodoAuth({ pathParams: { mallNo } });

    throwExternalWindowPopup(`${process.env.VUE_APP_GODO_API_URL}/order/regular-payment-popup.gd`, 'md', {
      token: data.token,
      shopNo: data.sno,
      type: 'shopbyUpgrade',
    });

    window.addEventListener('message', async ({ data }) => {
      if (data === 'commercePayComplete') {
        await refreshToken();
        location.reload();
      }
    });
  } catch (err) {
    console.error(err);
  }
};
