export default Object.freeze({
  // download
  DOWNLOAD: '엑셀 다운로드',
  DOWNLOAD_BY_GROUP: '그룹별 다운로드',
  DOWNLOAD_BY_GRADE: '등급별 다운로드',
  DOWNLOAD_BY_PARTNER: '파트너별 다운로드',
  DOWNLOAD_BY_OPTION: '옵션별 다운로드',
  DOWNLOAD_TEMPLATE: '엑셀 양식 다운로드',
  DOWNLOAD_SINGLE: '단독형 엑셀양식 다운로드',
  DOWNLOAD_COMBP: '조합형 엑셀양식 다운로드',
  DOWNLOAD_ARTICLE: '게시글 엑셀 다운로드',
  DOWNLOAD_ALL: '전체 엑셀 다운로드',
  DOWNLOAD_SETTLEMENT: '정산상세 엑셀 다운로드',
  DOWNLOAD_BATCH_REGISTER_SAMPLE: '일괄등록 샘플 다운로드',
  DOWNLOAD_INVOICE: '택배사 출고양식 다운',
  DETAIL: '상세보기',

  // upload
  UPLOAD: '엑셀 업로드',
  BATCH_REGISTER: '엑셀 일괄등록',
  FIND_FILES: '파일찾기',
  SAMPLE_UPLOAD: '샘플양식 다운로드',
  UPLOAD_FORM: '업로드 양식',

  // error
  PLEASE_CHECK_FORM_AGAIN: '엑셀양식을 다시 확인해주세요.',
  ERR_FILE_TYPE: '파일 양식이 올바르지 않습니다. 파일 확인 후 첨부해 주세요.',
  ERR_FORM: '엑셀 양식이 올바르지 않습니다. 파일 확인 후 첨부해 주세요.',
  TIP_ONLY_EXCEL: '엑셀파일만 등록해주세요.',
  NO_DATA: '엑셀 서식을 다시 확인해주세요.',
  DATA_ERROR: '엑셀 데이터를 정확히 입력해주세요.',
  NAME_ERROR: '등록 실패 했습니다. 확장자명을 다시 확인해주세요.',
  NO_EXCEL_ERROR: '엑셀 파일을 등록해주세요.',
  NO_SELECT_MALL: '쇼핑몰을 선택해주세요.',
  MESSAGE_UPLOADING_ADMIN_EXCEL:
    '변경 진행중입니다. 대상 상품이 많을 경우 다소 시간이 소요됩니다.<br>팝업을 닫더라도 요청하신 담당자 변경은 진행되나,<br>변경 완료 시 결과를 안내해드리므로 팝업을 닫지 말아주세요.',

  // fee
  POLICY_TITLE: 'NCP사용료_',
  POLICY_DETAIL_TITLE: 'NCP사용료상세_',

  // order
  DOWNLOAD_COLUMNS_RELEASE_TYPE: '구분',
  DOWNLOAD_COLUMNS_PAY_YMDT: '날짜',
  DOWNLOAD_COLUMNS_RECEIVER_ZIP_CD: '수화인우편',
  DOWNLOAD_COLUMNS_ADDRESS: '수화인주소',
  DOWNLOAD_COLUMNS_RECEIVER_NAME: '수화인명',
  DOWNLOAD_COLUMNS_RECEIVER_CONTACT1: '전화번호',
  DOWNLOAD_COLUMNS_RECEIVER_CONTACT2: '기타연락처',
  DOWNLOAD_COLUMNS_DELIVERY_MEMO: '메시지1',
  DOWNLOAD_COLUMNS_ORDER_MEMO: '메시지2',
  DOWNLOAD_COLUMNS_RELEASE_EMPTY: '공란',
  DOWNLOAD_COLUMNS_SHIPPING_NO: '고객주문번호',
  DOWNLOAD_NAME: '송장_{time}_{count}차_CJ출고양식',

  // product - PRIORITY
  PRODUCTNO: '상품번호',
  MALLNAME: '쇼핑몰',
  PRODUCTNAME: '상품명',
  PRODUCTMANAGEMENTCD: '상품관리코드',
  BRANDNAME: '브랜드',
  SALEMETHODTYPE: '판매방식',
  PARTNERNAME: '파트너사',
  ADMINNAME: '담당자',
  COMMISSIONRATE: '수수료율',
  SUPPLYPRICE: '공급가',
  PURCHASEPRICE: '매입가',
  APPROVALSTATUSTYPE: '승인상태',
  SALESTATUSTYPE: '판매상태',
  NAME: '판매설정',
  FRONTDISPLAYABLE: '전시상태',
  SALEPERIOD: '판매기간',
  SALEPRICE: '판매가',
  IMMEDIATEDISCOUNTAMOUNT: '즉시할인',
  APPLIEDIMMEDIATEDISCOUNTPRICE: '할인적용가',
  TOTALCNT: '재고수량',
  TOTALSALECNT: '판매수량',
  CANPROMOTION: '프로모션적용',
  REGISTERPRICECOMPARINGSITE: '가격비교사이트등록',
  DISPLAYCATEGORYNAMES: '전시카테고리',
  GROUPTYPE: '상품군',
  DELIVERABLE: '배송여부',
  SHIPPINGAREATYPE: '배송구분',
  DELIVERYFEETYPE: '배송비유형',
  DELIVERYCHARGE: '배송비',
  SHIPPINGCOMBINABLE: '묶음배송',

  // product - REVIEW
  INQUIRYNO: '문의번호',
  REPLIED: '답변상태',
  MALLNO: '쇼핑몰',
  MALLPRODUCTNO: '상품번호',
  ADMINYN: '담당자',
  ISSECRET: '비밀',
  INQUIRYTYPE: '문의유형',
  CONTENT: '문의내용',
  ORDERNO: '구매여부',
  MEMBER: '작성자',
  REGISTERDATETIME: '문의일시',
  REPLIEDDATETIME: '답변일시',

  // settlement
  ORDER_CNT: '판매수량 합계',
  SALES_AMT_SUM: '판매금액 합계(A)',
  COMMISSION_AMT: '판매수수료 합계(B)',
  DELIVERY_AMT: '배송비(C)',
  SETTLEMENT_AMT: '정산예상금액(I=A-B+C-D-E-F-G+H)',
  BANK_NAME: '은행명',
  TRADE_BANK_ACCOUNT: '계좌번호',
  TRADE_BANK_DEPOSITOR_NAME: '예금주',
  BUSINESS_REGISTRATION_NO: '사업자등록번호',
  SETTLEMENT_MANAGER_NAME: '담당자',
  SETTLEMENT_MANAGER_EMAIL: '이메일',
  SETTLEMENT_MANAGER_PHONE_NO: '연락처',
  SETTLEMTNT_NAME: '정산현황',

  ORDER_NO: '주문번호',
  PARTNER_NAME: '파트너사',
  COMPANY_NAME: '회사명',
  PARTNER_TYPE: '파트너 구분',
  VALUE_ADDED_TAX_TYPE_LABEL: '과세유형',
  MALL_PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  SALE_METHOD_TYPE_LABEL: '판매방식',
  OPTION_MANAGEMENT_CD: '옵션관리코드',
  OPTION: '옵션명',
  SETTLEMENT_DELIVERY_TYPE_LABEL: '배송구분(배송번호)',
  CLAIM_NO: '클레임번호',
  IMMEDIATE_DISCOUNTED_PRICE: '판매가(할인적용가)',
  ORDER_CNT_BY_PRODUCT: '판매수량',
  REFUND_CNT: '환불수량',
  SALES_AMT: '판매액(A)',
  PURCHASE_YN: '공급가 입력',
  PURCHASE_AMT_EXCEL: '공급가액(O)',
  SUPPLY_AMT_EXCEL: '매입가액(P)',
  COMMISSION_RATE_EXCEL: '수수료율(B)',
  COMMISSION_AMT_EXCEL: '판매수수료(C=A*B or A-(O or P))',
  MALL_DISCOUNT_AMT: '추가/상품할인 쇼핑몰분담',
  PARTNER_DISCOUNT_AMT: '추가/상품할인 파트너분담(D)',
  MALL_PRODUCT_ADJUST_AMT: '상품금액조정 쇼핑몰부담',
  PARTNER_PRODUCT_ADJUST_AMT: '상품금액조정 파트너부담(E)',
  MALL_DELIVERY_ADJUST_AMT: '배송비금액조정 쇼핑몰부담',
  PARTNER_DELIVERY_ADJUST_AMT: '배송비금액조정 파트너부담(F)',
  MALL_DELIVERY_AMT: '쇼핑몰배송비(G)',
  REFUND_ADJUST_AMT: '환불보류 금액조정(H)',
  SETTLEMENT_AMT_BY_PRODUCT: '옵션 매출금액(I=A-C-D-E-F-G+H)',
  PAY_YMDT: '결제완료일',
  REGISTER_YMDT: '매출집계일',
  MEMO: '비고',
});
