


















































import { Component, Mixins } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import AccumulationStatusMixin from '@/views/contents/member/accumulation/AccumulationStatusMixin';
import Grid from '@/components/common/grid/Main.vue';
import { dailyStatusColumn, statusOption } from '@/const/contents/accumulationPaymentDeduct';

@Component({
  components: { Grid },
})
export default class AccumulationDailyStatusPopup extends Mixins(WindowPopupMainVue, AccumulationStatusMixin) {
  private gridOption = statusOption;
  private gridColumn = dailyStatusColumn;

  created() {
    this.fetchDailyStatusDetail(this.data);
  }
}
