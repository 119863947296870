




















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import SearchKeywordInput from '@/components/common/input/SearchKeywordInput.vue';
import { Brand, NCPResponse, GetBrandsSearchRequest } from 'ncp-api-supporter';
import { convertToSearchKeywordDataType, i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { InputNumber, SearchKeywordData, SearchKeywordInputOption } from '@/types';
import debounce from 'lodash.debounce';

@Component({
  components: { SearchKeywordInput },
})
export default class Brands extends Vue {
  @PropSync('brandName') private brandNameSync!: string;
  @Prop() private readonly isSelectedMall: boolean;
  @Prop() private readonly mallNo: InputNumber;
  @Prop({ required: true })
  private readonly disabled: boolean;

  private searchKeywordInputOption: SearchKeywordInputOption = {
    useResetBtn: true,
    isDirect: true,
    useCount: true,
    maxLength: 42,
    placeholder: i18nForProduct('PLACEHOLDER_INSERT_BRAND'),
  };

  private brands: Brand[] = [];

  private get displayBrands(): SearchKeywordData[] {
    return convertToSearchKeywordDataType<Brand>({
      data: this.brands,
      key: 'brandNo',
      name: 'brandName',
    });
  }

  private selectedBrandByKeyword({ selectDisplayName }: SearchKeywordData): void {
    this.brandNameSync = selectDisplayName.toString();
  }

  private onClickBrands(): void {
    if (this.isSelectedMall) return;
    alert(this.$t('PRODUCT.ADD.SELECT_MALL'));
    this.$emit('focusOnMallSelect');
  }

  private async getBrandsKeyword() {
    if (!this.mallNo || !this.brandNameSync.length) return;
    const params: GetBrandsSearchRequest = { params: { mallNo: Number(this.mallNo), keyword: this.brandNameSync } };
    const { data }: NCPResponse<Brand[]> = await this.$api.getBrandsSearch(params);
    this.brands = data;
  }

  private searchBrand = debounce(() => this.getBrandsKeyword(), 500);
}
