import { DiscountPriceCalculator } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/DiscountPriceCalculator';
import { SupplyPriceCalculator } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SupplyPriceCalculator';
import { InputNumber } from '@/types';

export enum DiscountType {
  WON = 'AMOUNT',
  PERCENTAGE = 'PERCENT',
}

export enum CommissionType {
  PRODUCT = 'PRODUCT',
  CATEGORY = 'CATEGORY',
  PARTNER = 'PARTNER',
  PURCHASE = 'PURCHASE_PRICE',
}

export const RoundingMode = {
  UP: 'UP',
  DOWN: 'DOWN',
  HALF_UP: 'HALF_UP',
} as const;

export const ErrorType = {
  ALERT: 'ALERT',
  INLINE: 'INLINE',
} as const;

export const ErrorCode = {
  INVALID_UNIT: 'INVALID_UNIT',
  INVALID_RANGE: 'INVALID_RANGE',
} as const;

//
export class SalesInfoCalculator {
  private discountPriceCalculator: DiscountPriceCalculator;
  private supplyPriceCalculator: SupplyPriceCalculator;

  constructor(discountPriceCalculator: DiscountPriceCalculator, supplyPriceCalculator: SupplyPriceCalculator) {
    this.discountPriceCalculator = discountPriceCalculator;
    this.supplyPriceCalculator = supplyPriceCalculator;
  }

  public changeSalePrice(price: number): void {
    this.discountPriceCalculator.changeSalePrice(price);
  }

  public changeDiscountAmount(discount: number): void {
    this.discountPriceCalculator.changeDiscountAmount(discount);
  }

  public changeDiscountType(discountType: DiscountType): void {
    this.discountPriceCalculator.changeDiscountType(discountType);
  }

  public changeOptionPrice(price: number): void {
    this.discountPriceCalculator.changeOptionPrice(price);
  }

  public get maxDiscountAmount(): number {
    return this.discountPriceCalculator.maxDiscountAmount;
  }

  public get discountPriceResult(): number {
    return this.discountPriceCalculator.discountPriceResult;
  }

  public changeCommission(commission: InputNumber): void {
    this.supplyPriceCalculator.changeCommission(commission);
  }

  public changeCommissionType(commissionType: CommissionType): void {
    this.supplyPriceCalculator.changeCommissionType(commissionType);
  }

  public get commissionAmount(): number {
    return this.supplyPriceCalculator.commissionAmount;
  }

  public get supplyPriceResult(): number {
    return this.supplyPriceCalculator.calcSupplyPrice(this.discountPriceResult);
  }
}
