export default Object.freeze({
  INTERGRATE_MEMBER_CONVERSION_GUIDE: '통합회원 전환안내',
  INTERGRATE_MEMBER_DESCRIPTION: '통합회원 전환을 통해<br>NHN COMMERCE의 서비스를<br>하나의 아이디로 이용해 보세요.',
  INTERGRATE_MEMBER_POPUP_QUESTION01: '01. NHN COMMERCE 통합회원이란 무엇인가요?',
  INTERGRATE_MEMBER_POPUP_QUESTION02: '02. 통합회원 전환은 어떻게 진행하나요?',
  INTERGRATE_MEMBER_POPUP_QUESTION03: '03. 통합회원 전환을 하지 않아도 되나요?',
  INTERGRATE_MEMBER_POPUP_ANSWER01:
    '샵바이, 워크스페이스 등 NHN COMMERCE의 다양한 서비스를 <em style="color:#fa2828;">별도 회원가입과 로그인 없이 하나의 계정</em> 으로 이용할 수 있는 회원입니다.',
  INTERGRATE_MEMBER_POPUP_ANSWER02:
    'NHN커머스 홈페이지 로그인 후, <em style="color:#fa2828;">전환 안내 팝업 혹은 회원 정보 수정 페이지 내 ‘통합회원으로 전환’ 버튼을 클릭</em> 한 뒤 간단한 절차를 거치면 전환이 완료됩니다.',
  INTERGRATE_MEMBER_POPUP_ANSWER03: `전환을 진행하지 않을 경우 <em style="color:#fa2828;">적용되는 서비스가 제한</em> 될 수 있으니 가이드를<br>참고해 통합회원 전환을 진행해 보세요!
  <a style="color:#2193ca; text-decoration: underline;" rel="noopener noreferrer" href="https://www.nhn-commerce.com/customer/manual/view.gd?idx=147" target="_blank">통합회원 전환 가이드 바로가기</a>`,
  NOT_SHOW_N_DAY: '{0}일간 다시 보지 않기',
  NOT_TODAY_SHOW: '오늘 하루 보이지 않음',
  LINK_COMMERCE: 'NHN커머스 홈페이지 바로가기',
  CLOSE: '닫기',
});
