export const MODE = {
  REGISTER: 'REGISTER',
  EDIT: 'EDIT',
  COPY: 'COPY',
} as const;

export const EVENT_TYPE = {
  BATCH: 'batch',
  DELETE: 'delete',
  ADD: 'add',
} as const;

export const DISPLAY_TYPE = {
  MOBILE: 'MOBILE',
  PC: 'PC',
} as const;

export const NAV_BUTTON_KEY = {
  SAVE: 'SAVE',
  TEMP: 'TEMP',
  COPY: 'COPY',
  CANCEL: 'CANCEL',
} as const;

export const APPLY_STATUS = {
  APPROVAL_READY: 'APPROVAL_READY', // 승인대기
  FINISHED: 'FINISHED', // 승인완료
  AFTER_APPROVAL_READY: 'AFTER_APPROVAL_READY', // 수정 후 승인대기
  REGISTRATION_READY: 'REGISTRATION_READY', // 등록대기
  APPROVAL_REJECTION: 'APPROVAL_REJECTION', // 승인거부
  SALE_AGREEMENT_READY: 'SALE_AGREEMENT_READY', // 판매합의대기
  SALE_AGREEMENT_REJECTION: 'SALE_AGREEMENT_REJECTION', // 판매합의거부
  AFTER_APPROVAL_REJECTION: 'AFTER_APPROVAL_REJECTION', // 수정 후 승인거부
} as const;

export const SALE_STATUS = {
  PRE_APPROVAL_STATUS: 'PRE_APPROVAL_STATUS', // 승인완료이전
  ON_PRE_SALE: 'ON_PRE_SALE', // 예약판매중
  WAITING_SALE: 'WAITING_SALE', // 판매대기
  ON_SALE: 'ON_SALE', // 판매중
  END_SALE: 'END_SALE', // 판매종료
};

export const PARTNER_TYPE = {
  ALL: 'ALL',
  SHOP: 'SHOP',
  PARTNER: 'PARTNER',
};
