







import { Vue, Component } from 'vue-property-decorator';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';
import ShippingChargeTemplate from '@/views/contents/configuration/delivery/ShippingChargeTemplate.vue';
import { i18nForDeliveryGroupTemplate } from '@/const/contents/configuration/shippingCharge';

@Component({ components: { ShippingChargeTemplate, TabMenu } })
export default class ShippingCharge extends Vue {
  private menuList: TabMenuI[] = [
    { display: i18nForDeliveryGroupTemplate('DELIVERY_AMT_TEMPLATE2') as string, path: 'template' },
    { display: i18nForDeliveryGroupTemplate('AREA_FEE2') as string, path: 'area-fee' },
    { display: i18nForDeliveryGroupTemplate('WAREHOUSE') as string, path: 'warehouse' },
  ];
}
