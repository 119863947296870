






































































import { Component, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { saveBinaryDataToFile } from '@/utils/fileSaver';
import { NCPResponse, ProductExcelBulkAdminUpdate, Mall } from 'ncp-api-supporter';
import { Admin, ValidOption } from '@/types';
import { Getter } from 'vuex-class';
import moment from 'moment';

@Component({
  components: { FileInput, NoticeBox, MallSelect },
})
export default class ChangeAdmin extends WindowPopupMainVue {
  @Getter('admin/getAdmin') private readonly admin: Admin;
  @Getter('mall/getMalls') private readonly malls: Mall[];
  @Ref() private readonly mallSelect!: MallSelect;
  @Ref() private readonly fileInputRef: FileInput;

  private uploadedExcelFileName = '';
  private extensionValidOption: ValidOption<string> = {
    validType: 'excel',
    alertMsg: 'ALERT_DISALLOW_EXTENSION',
  };
  private uploadedExcelFile: File | null = null;
  private mallNo = 0;
  private isProcessing = false;
  private today = moment(new Date()).format('YYYYMMDD');

  get dialogues(): string[] {
    return this.$t('PRODUCT.EDIT.CHANGE_ADMIN_DIALOGUES')
      .toString()
      .trim()
      .split('\n');
  }

  private changeMall(mallNo: string) {
    this.mallNo = Number(mallNo);
  }

  private async downloadExcel() {
    const { data }: NCPResponse<ArrayBuffer> = await this.$api.getProductsExcelUpdateAdminForm();
    const fileName = `${this.$t('PRODUCT.EDIT.CHANGE_PRODUCT_ADMIN_EXCEL_FORM_NAME')}_${this.today}.xlsx`;
    saveBinaryDataToFile(data, fileName);
  }

  private uploadExcelFile(file: File): void {
    this.uploadedExcelFileName = file.name;
    this.uploadedExcelFile = file;
  }

  private removeFile(): void {
    this.uploadedExcelFile = null;
    this.uploadedExcelFileName = '';
    this.fileInputRef.clearFile();
    this.fileInputRef.resetFileName();
  }

  private async onSave() {
    if (!this.mallNo) {
      this.mallSelect.focus();
      return alert(this.$t('COMMON.EXCEL.NO_SELECT_MALL'));
    }

    if (!this.uploadedExcelFileName) return alert(this.$t('COMMON.EXCEL.NO_EXCEL_ERROR'));
    this.updateProductsAdminsWithLoading();
  }

  private updateProductsAdminsWithLoading() {
    this.isProcessing = true;
    this.updateProductsAdmins().finally(() => {
      this.isProcessing = false;
      this.resetPopData();
    });
  }

  private async updateProductsAdmins() {
    const formData = new FormData();
    formData.append('excelFile', this.uploadedExcelFile);
    try {
      const { data }: NCPResponse<ProductExcelBulkAdminUpdate> = await this.$api.putProductsExcelBulkAdminUpdate({
        params: { mallNo: this.mallNo },
        data: formData,
      });
      this.updateExcelResult(data);
    } catch (e) {
      console.error(e);
    }
  }

  private updateExcelResult(excelResultData: ProductExcelBulkAdminUpdate) {
    const { totalSuccessCount, totalFailCount, failExcelId } = excelResultData;
    const upddateResultMessage = this.$t('PRODUCT.EDIT.ALERT_ADMIN_CHANGE_COMPLETE');
    const successCountMessage = this.$t('SUCCESS_N', { count: totalSuccessCount }).toString();
    const failCountMessage = this.$t('FAIL_N', { count: totalFailCount }).toString();

    //성공만
    if (totalSuccessCount > 0 && totalFailCount <= 0) {
      alert(`${upddateResultMessage}\n${successCountMessage}`);
      window.opener.location.reload();
    }
    //실패만
    if (totalFailCount > 0 && totalSuccessCount <= 0) {
      alert(`${upddateResultMessage}\n${failCountMessage}`);
      this.downloadFailedExcel(failExcelId);
    }
    //성공+실패
    if (totalSuccessCount > 0 && totalFailCount > 0) {
      alert(`${upddateResultMessage}\n${successCountMessage}, ${failCountMessage}`);
      this.downloadFailedExcel(failExcelId);
      window.opener.location.reload();
    }
  }

  private async downloadFailedExcel(failExcelId: string) {
    const fileName = `${this.$t('PRODUCT.EDIT.FAILED_CHANGE_PRODUCT_ADMIN_EXCEL_NAME')}_${this.today}.xlsx`;
    try {
      const { data }: NCPResponse<ArrayBuffer> = await this.$api.getProductsExcelProductAdminFail({
        params: { failExcelId },
        responseType: 'arraybuffer',
      });
      saveBinaryDataToFile(data, fileName);
    } catch (e) {
      console.error(e);
    }
  }

  private resetPopData() {
    if (this.malls.length > 1) {
      this.mallNo = 0;
    }
    this.removeFile();
  }
}
