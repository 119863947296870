





























import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { getGridProps } from '@/views/contents/partner/list/PartnerList';
import { isEmpty } from 'underscore';
import { GetPartnersRequest, Partner } from 'ncp-api-supporter';
import rowMerger from '@/utils/grid/rowMerger';
import { KeyMap, MergedRowData } from '@/types/tui-grid/utils/rowMerger';

type PartnerListColumnName = PartnerList['gridProps']['columns'][number]['name'];

type MergedPartnerData = MergedRowData<Partner, PartnerListColumnName>;
type PartnerKeyMap = KeyMap<Partner, PartnerListColumnName>;

@Component({
  components: {
    Grid,
  },
})
export default class PartnerList extends Vue {
  @Ref()
  private readonly gridRef: Grid;

  private readonly gridProps = getGridProps();

  private partners: MergedRowData<Partner, PartnerListColumnName>[] = [];
  private totalCount = 0;

  private get query(): GetPartnersRequest['params'] {
    return this.$route.query;
  }

  // 파트너 검색
  @Watch('$route.query')
  private searchPartners() {
    if (isEmpty(this.query)) return;

    this.fetchPartners().then(({ data }) => {
      const keyMap: PartnerKeyMap = {
        partnerNo: [
          'partnerNo',
          'partnerName',
          'countryCode',
          'companyName',
          'partnerStatus',
          'registrationNo',
          'onlineMarketingDeclarationNo',
        ],
        mallNo: ['mallName', 'productRegistered', 'contractDateTime'],
      };
      this.partners = rowMerger<Partner, PartnerListColumnName>(data.contents as MergedPartnerData[], keyMap);
      this.totalCount = data.totalCount;
    });
  }
  // NOTE 기획요청으로 전체 데이터 조회를 위해 하드코딩
  private requestParams = { params: { ...this.query, page: 1, pageSize: 10000 } };

  private getCreateExcelRequestParams() {
    const createExcelRequestParams = { ...this.query };
    delete createExcelRequestParams.page;
    delete createExcelRequestParams.pageSize;

    return createExcelRequestParams;
  }

  private fetchPartners(query?: GetPartnersRequest['params']) {
    return this.$api.getPartners({ params: query ?? this.query });
  }

  // 그리드 클릭 이벤트
  private onItemClicked({ columnName, rowKey }) {
    const clickEvent = this.gridClickEventTriggerBy[columnName];
    if (!clickEvent) return;

    clickEvent(this.gridRef.getRow(rowKey));
  }

  private gridClickEventTriggerBy = {
    partnerName: ({ partnerNo, partnerStatus }) => {
      this.$router.push({
        name: 'PartnerModification',
        params: { partnerNo },
        query: { partnerStatus },
      });
    },
  };
}
