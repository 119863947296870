


















import { Vue, Component } from 'vue-property-decorator';
import { i18nSecurity } from '@/const/contents/configuration/operateSecurity';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import AdminSecurityAuthSetting from '@/views/contents/configuration/security/operateSecurity/AdminSecurityAuthSetting.vue';
import AdminIpAccessSetting from '@/views/contents/configuration/security/operateSecurity/AdminIpAccessSetting.vue';
import ExcelDownloadSecuritySetting from '@/views/contents/configuration/security/operateSecurity/ExcelDownloadSecuritySetting.vue';
import SettingByShoppingMall from '@/views/contents/configuration/security/operateSecurity/SettingByShoppingMall.vue';
import { Getter } from 'vuex-class';
import { SecurityConfiguration } from 'ncp-api-supporter';

@Component({
  components: {
    SettingByShoppingMall,
    ExcelDownloadSecuritySetting,
    AdminIpAccessSetting,
    AdminSecurityAuthSetting,
  },
})
export default class OperateSecurity extends Vue {
  @Getter('admin/getServiceNo')
  private readonly serviceNo: number;
  private ipRows = { len: 0 };

  private data: SecurityConfiguration = {
    adminSecurity: {
      otpUsed: false,
      emailUsed: false,
      displaySecurityUsed: false,
      loginSecurityUsed: false,
      smsUsed: false,
    },
    ipRestrict: {
      permittedIpAddresses: [],
      restrictedNations: [],
      used: false,
    },
    excelSecurity: {
      otpUsed: false,
      targetMenus: [],
      emailUsed: false,
      used: false,
      smsUsed: false,
    },
  };

  private i18nSecurity = i18nSecurity;

  created() {
    this.getServicesByServiceNoSecurityConfiguration();
    this.throwBottomNavigation();
  }

  async getServicesByServiceNoSecurityConfiguration() {
    const { data } = await this.$api.getServicesByServiceNoSecurityConfiguration({
      params: {
        serviceNo: this.serviceNo,
      },
    });

    this.data = { adminSecurity: data.adminSecurity, ipRestrict: data.ipRestrict, excelSecurity: data.excelSecurity };
  }

  async putServicesByServiceNoSecurityConfiguration(): Promise<boolean> {
    const request = {
      data: this.data,
      params: {
        serviceNo: this.serviceNo,
      },
    };

    const response = await this.$api.putServicesByServiceNoSecurityConfiguration(request);

    return response && response.status === 204;
  }

  private valiadtionData(type: 'security' | 'ip', target: SecurityConfiguration): boolean {
    const task = {
      security: (target: SecurityConfiguration): boolean => {
        return Object.keys(target.adminSecurity)
          .filter(key => key !== 'loginSecurityUsed' && key !== 'displaySecurityUsed')
          .some(key => target.adminSecurity[key]);
      },
      ip: (target: SecurityConfiguration): boolean => {
        const { permittedIpAddresses } = target.ipRestrict;
        return permittedIpAddresses.length && this.ipRows.len === permittedIpAddresses.length;
      },
    };
    return task[type]?.(target);
  }

  async save() {
    const { adminSecurity, ipRestrict } = this.data;

    if (
      (adminSecurity.loginSecurityUsed || adminSecurity.displaySecurityUsed) &&
      !this.valiadtionData('security', this.data)
    ) {
      alert(i18nSecurity('ALERT_SELECT_AUTH_METHOD'));
      return;
    }

    if (ipRestrict.used && !this.valiadtionData('ip', this.data)) {
      alert(i18nSecurity('ALERT_INSERT_ACCESS_IP'));
      return;
    }

    const result = await this.putServicesByServiceNoSecurityConfiguration();

    if (result) {
      alert(this.$t('ALERT_SAVED'));
    }
  }

  private throwBottomNavigation() {
    const buttons = [];
    buttons.push({
      type: 'right',
      key: 'save',
      color: 'red',
      text: this.$t('SAVE'),
    });

    throwBottomNavigation({
      buttons,
      onClick: (key: string) => {
        switch (key) {
          case 'save':
            this.save();
            break;
        }
      },
    });
  }
}
