import { ChangeDisplayOrderMsgOption, GridProp, OptColumn } from '@/types';
import { i18n } from '@/main';
import { i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { getSpanHTML } from '@/utils/grid/formatterUtils';
import { formatCurrency } from '@/utils/numberFormat';
import { getDisplayOrderHistoryColumn } from '@/components/product/common/girdColumns/ChangeHistory';

export const gridOptions = (): GridProp => ({
  displayOptions: {
    showsRightArea: false,
    pageSizeKey: 'size',
  },
  header: {
    height: 33,
    align: 'center',
  },
  options: {
    rowHeaders: [
      {
        type: 'checkbox',
        header: i18n.t('PRODUCT.ADD.SELECT').toString(),
      },
    ],
    scrollY: true,
    minBodyHeight: 60,
    bodyHeight: 420,
    rowHeight: 31,
    pageOptions: {
      useClient: true,
      type: 'scroll',
    },
  },
});

// 자동진열: rowNum 추가, displayOrder 삭제
export const autoColumn = (): OptColumn[] => [
  {
    header: 'PRODUCT.ADD.HEADER_NO',
    name: 'rowNum',
    width: 80,
    align: 'center',
    formatter: ({ row }) => `${row.history ? row.displayOrder : Number(row.rowKey) + 1}`,
  },
];
// 수동진열: displayOrder 추가, rowNum 삭제
export const manualColumn = (): OptColumn[] => [
  getDisplayOrderHistoryColumn('PRODUCT.ADD.DISPLAY_ORDER', 'displayOrder', 100),
];
export const columns = (): OptColumn[] => [
  // 상품번호
  {
    header: 'PRODUCT.ADD.PRODUCT_NO',
    name: 'productNo',
    width: 120,
    align: 'center',
  },
  // 상품명
  {
    header: 'PRODUCT.ADD.PRODUCT_NAME',
    name: 'productName',
    width: 400,
    align: 'center',
  },
  // 즉시할인가
  {
    header: 'PRODUCT.ADD.IMMEDIATE_DISCOUNT_AMOUNT',
    name: 'immediateDiscountAppliedPrice',
    width: 150,
    align: 'center',
    formatter: ({ value }) => `${formatCurrency(Number(value))}${i18nForProduct('AMOUNT')}`,
  },
  // 판매상태
  {
    header: 'PRODUCT.ADD.SALE_STATUS_TYPE',
    name: 'saleStatusType',
    width: 150,
    align: 'center',
    formatter: ({ value, row }) => {
      const periodText = row.isPeriodType && value === 'ON_SALE' ? `(${i18nForProduct('SALE_PERIOD')})` : '';
      return `${i18nForProduct(value.toString())}${periodText}`;
    },
  },
  // 판매설정태
  {
    header: 'PRODUCT.ADD.SALE_SETTING_STATUS_TYPE',
    name: 'saleSettingStatusType',
    width: 150,
    align: 'center',
    formatter: ({ value }) => i18nForProduct(value.toString()),
  },
  // 전시상태
  {
    header: 'PRODUCT.ADD.DISPLAY_YN',
    name: 'frontDisplayable',
    width: 150,
    align: 'center',
    formatter: ({ value }) => i18nForProduct(value ? 'DISPLAY_Y' : 'DISPLAY_N'),
  },
  // 품절상태
  {
    header: 'PRODUCT.ADD.SOLD_OUT',
    name: 'isSoldOut',
    width: 150,
    align: 'center',
    formatter: ({ value }) => i18nForProduct(value ? 'N_STOCK' : 'Y_STOCK'),
  },
  {
    header: 'PRODUCT.ADD.REMOVE',
    name: 'delete',
    width: 100,
    align: 'center',
    formatter: () => getSpanHTML(i18n.t('DELETE').toString()),
  },
];

export const message: ChangeDisplayOrderMsgOption = {
  needOrigin: 'PRODUCT.ADD.PLZ_SELECT_PRODUCT_TO_MOVE',
  selectedTarget: 'PRODUCT.ADD.SELECTED_TARGET_MSG',
};
