



























import { Component, Mixins, Watch } from 'vue-property-decorator';
import StatusSummaryBox from '@/components/root/parts/StatusSummaryBox.vue';
import SummaryData, { PeriodQueryKeyName, Summary } from '@/components/root/SummaryMixin';
import { namespace } from 'vuex-class';
import { GetInquiriesCounts, Mall, ProductInquiriesSummary } from 'ncp-api-supporter';

const mallStore = namespace('mall');

@Component({
  components: { StatusSummaryBox },
})
export default class QuestionSummary extends Mixins(SummaryData) {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private oneOnOnePeriodQueryKeyName: PeriodQueryKeyName = { start: 'periodInfoStartYmdt', end: 'periodInfoEndYmdt' };

  private summaries: Summary[] = [
    {
      key: 'totalProductInquiryCountInLastMonth',
      label: 'PRODUCT_INQUIRIES_ALL',
      count: null,
      path: {
        name: 'Questions',
        query: {
          ...this.getPeriodQueryObject(-1, 'month'),
        },
      },
      periodLabel: this.getSummaryPeriodI18n(1, 'month'),
    },
    {
      key: 'totalCount',
      label: 'ONE_ON_ONE_INQUIRIES_ALL',
      count: null,
      path: {
        name: 'Inquiries',
        query: {
          mallNos: '0',
          ...this.getPeriodQueryObject(-1, 'month', { queryKeyName: this.oneOnOnePeriodQueryKeyName }),
        },
      },
      periodLabel: this.getSummaryPeriodI18n(1, 'month'),
    },
    {
      key: 'unspecifiedCount',
      label: 'ONE_ON_ONE_INQUIRIES_NO_ASSIGN',
      count: null,
      path: {
        name: 'Inquiries',
        query: {
          isUnspecified: true,
          mallNos: '0',
          ...this.getPeriodQueryObject(-1, 'month', { queryKeyName: this.oneOnOnePeriodQueryKeyName }),
        },
      },
      periodLabel: this.getSummaryPeriodI18n(1, 'month'),
    },
    {
      key: 'delayedReplyCountInLastMonth',
      label: 'PRODUCT_INQUIRIES_UNANSWERED',
      count: null,
      path: {
        name: 'Questions',
        query: {
          replyStatusType: 'READY',
          ...this.getPeriodQueryObject(-1, 'month'),
        },
      },
      periodLabel: this.getSummaryPeriodI18n(1, 'month'),
    },
    {
      key: 'issuedCount',
      label: 'ONE_ON_ONE_INQUIRIES_UNANSWERED',
      count: null,
      path: {
        name: 'Inquiries',
        query: {
          inquiryStatuses: 'ISSUED',
          mallNos: '0',
          ...this.getPeriodQueryObject(-1, 'month', { queryKeyName: this.oneOnOnePeriodQueryKeyName }),
        },
      },
      periodLabel: this.getSummaryPeriodI18n(1, 'month'),
    },
    {
      key: 'inProgressCount',
      label: 'ONE_ON_ONE_INQUIRIES_PROGRESS',
      count: null,
      path: {
        name: 'Inquiries',
        query: {
          inquiryStatuses: 'IN_PROGRESS',
          mallNos: '0',
          ...this.getPeriodQueryObject(-1, 'month', { queryKeyName: this.oneOnOnePeriodQueryKeyName }),
        },
      },
      periodLabel: this.getSummaryPeriodI18n(1, 'month'),
    },
  ];

  private get mallNos(): number[] {
    return this.malls.map(({ mallNo }) => mallNo);
  }

  created() {
    if (this.productInquirySummary && this.oneToOneSummary) this.mapData();
    this.fetch();
  }

  @Watch('$route.query.latestRefresh')
  @Watch('mallNoQuery')
  async fetch() {
    await this.fetchData();
    this.mapData();
  }

  private async fetchData(): Promise<void> {
    await Promise.all([
      this.fetchProductInquiry({
        mallNo: this.mallNoQuery === 'ALL' ? null : this.mallNoQuery,
      }),
      this.fetchOneToOneInquiry(
        this.mallNoQuery === 'ALL'
          ? null
          : {
              mallNos: this.mallNoQuery,
            },
      ),
    ]);
  }

  private mapData(): void {
    this.mapSummaries<ProductInquiriesSummary | GetInquiriesCounts>({
      ...this.productInquirySummary,
      ...this.oneToOneSummary,
    });
  }

  private mapSummaries<DataType>(data: DataType): void {
    Object.entries(data).forEach(([k, v]) => {
      const matched = this.summaries.find(({ key }) => key === k);
      if (matched) matched.count = v;
    });
    this.summaries = this.summaries.map(summary => {
      const mallNoKeyLabels = ['PRODUCT_INQUIRIES_ALL', 'PRODUCT_INQUIRIES_UNANSWERED'];
      if (summary.query === undefined) return summary;
      const query = mallNoKeyLabels.includes(summary.label)
        ? { mallNo: this.mallNoQuery.toString() }
        : { mallNos: this.mallNoQuery.toString() };

      summary.query = this.mallNoQuery === 'ALL' ? null : query;
      return summary;
    });
  }

  private get summaryList(): Summary[] {
    return this.summaries;
  }

  private get unansweredSummary(): Summary {
    const count =
      this.summaries.find(({ key }) => key === 'delayedReplyCountInLastMonth').count +
      this.summaries.find(({ key }) => key === 'issuedCount').count;

    return {
      key: '',
      label: 'SUMMARY.UNANSWERED_INQUIRIES',
      count, // newOrder + depositWait
    };
  }
}
