export default Object.freeze({
  // BANNER_LIST_
  TITLE: '배너 관리',
  SETTING: '배너설정',

  // 그룹
  ADD_GROUP: '그룹 추가',
  GROUP_NAME: '그룹명',
  NEW_GROUP: '새 그룹',
  GROUP_INPUT_PLACEHOLDER: '그룹명을 입력해 주세요.',
  ALERT_NO_CHANGE: '변경된 내용이 없습니다. 다시 확인 후 진행해 주세요.',
  NEW_GROUP_GUIDE: '섹션은 그룹을 기준으로 추가할 수 있습니다.',
  NEW_GROUP_GUIDE_DETAIL: '좌측에서 섹션을 생성하시면 섹션 별 구좌에 대한 상세 설정이 가능합니다.',

  // 섹션
  ADD_SECTION: '섹션 추가',
  SECTION_NAME: '섹션명',
  SECTION_CODE: '섹션코드',
  NEW_SECTION: '새 섹션',
  SECTION_INPUT_PLACEHOLDER: '섹션명을 입력해 주세요.',
  ALERT_SECTION_SIZE_EMPTY: '섹션 사이즈를 입력하세요.',
  ALERT_BANNER_VISUAL_EMPTY: '배너이미지, 동영상 URL을 1개 이상 추가하세요.',
  CONFIRM_ACCOUNT_DOWN_SIZING: '구좌 감소 시 배너가 등록 되어있는 배너 구좌가 삭제 될 수 있습니다. 삭제하시겠습니까?',

  // 배너
  BANNER: '배너',
  BANNER_NAME: '배너명',
  ADD_BANNER_DESCRIPTION: '배너설명 등록',
  UPDATE_BANNER_DESCRIPTION: '배너설명 수정',
  PLACEHOLDER_BANNER_DESCRIPTION: '• 배너설명 문구를 입력해주세요.',
  ALERT_EMPTY_CONTENT: '내용을 입력해주세요.',
  ALERT_BANNER_LIMIT: '배너는 101개 이상 등록할 수 없습니다.',
  ADD_BANNER: '배너추가',
  BANNER_IMAGE: '배너이미지',
  FIND_IMAGE: '이미지찾기',
  ADD_MOUSEOVER_IMAGE: '마우스 오버 이미지 등록',
  ALERT_LANDING_URL_EMPTY: '랜딩 URL을 입력하세요.',
  ALERT_IMAGE_SIZE_OVER: '등록 실패 하였습니다.\n최대 1MB까지 등록가능합니다.',
  DISPLAY_MOUSE_OVER: '마우스 오버시 적용',
  COLOR_NOTICE: '좌우 여백 발생 시 해당 색으로 채워집니다.',
  NUMBER_OF: '구좌',
  NUMBER_OF_BANNER: '배너 구좌수',
  NUMBER_OF_BANNER_NAME: '구좌명',
  DEFAULT: '일반',
  IMAGE_MAP: '이미지맵',
  ADD_IMAGE_MAP: '이미지맵 등록',
  UPDATE_IMAGE_MAP: '이미지맵 수정',
  PLACEHOLDER_IMAGE_MAP: '• 이미지맵 HTML 소스 입력 예)',
  CONFIRM_PERMANENT_DELETE: '삭제된 내용은 복구가 불가능합니다 정말 삭제하시겠습니까?',
  THEME_DISPLAY: '기획전',
  NUMBER_OF_BANNER_INPUT_PLACEHOLDER: '구좌명을 입력하세요',
  DISPLAY_SETTING: '노출설정',
  ABOVE_BANNER_ORDER: '배너 순서 위',
  CONFILM_DELETE_BANNER: '배너 구좌 삭제 시 연동된 프론트 배너가 미출력 될 수 있습니다. 삭제하시겠습니까?',
  SAVE_SUCCESS: '저장되었습니다.',

  // 배너 노출 설정
  DISPLAY_SETTING_TITLE: '배너 노출 설정',
  DISPLAY_SETTING_NOTICE_1: '배너 세부 설정은 섹션에 설정된 값 중에서 선택할 수 있습니다.',
  DISPLAY_SETTING_NOTICE_2: '개별 설정’으로 저장된 값은, 섹션 설정에 따라 삭제될 수 있습니다.',
  DISPLAY_TYPE: '노출방식',
  DISPLAY_TYPE_PLATFORM: '플랫폼별 노출 설정',
  DISPLAY_TYPE_GRADE: '회원등급 노출 설정',
  DISPLAY_TYPE_GROUP: '회원그룹 노출 설정',
  RANDOM: '랜덤',
  SEQUENTIAL: '순차',
  SAME_AS_SECTION: '섹션 값과 동일',
  CUSTOM_OPTION: '개별 설정',

  // tree
  ALERT_UNSAVED: '저장하지 않은 내용이 있습니다.\n변경된 내용을 저장하시겠습니까?',

  // etc
  DISPLAY_SETTING_BY_PLATFORM: '플랫폼별 노출 설정',
  DISPLAY_SETTING_BY_GRADE: '회원등급 노출 설정',
  DISPLAY_SETTING_BY_GROUP: '회원그룹 노출 설정',
  ALL_GRADES: '전체 회원등급',
  ALL_GROUPS: '전체 회원그룹',

  DISPLAY_SETTING_DESC: '회원그룹을 ‘설정’ 한 상품은 비회원에게 노출되지 않습니다.',
  CURRENT_WINDOW: '현재창',
  NEW_WINDOW: '새창',
  VIDEO_URL: '동영상 URL',
  INPUT_VIDEO_URL: '동영상 URL 입력',
  LANDING_URL: '랜딩 URL',
  INPUT_LANDING_URL: '랜딩 URL 입력',
  MOUSE_OVER_IMAGE_CHECK: '마우스 오버 이미지 등록',

  PC: 'PC',
  MOBILE_APP: '모바일 앱',
  MOBILE_WEB: '모바일 웹',

  DISPLAY_YMD: '노출기간',
  YMD_ALWAYS: '상시',
  YMD_PERIOD: '기간지정',
  ORDER_OF_CHANGE: '변경할 순',
  CHANGE: '변경',
  FINAL_UPDATE: '최종수정',
});
