




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class Reject080ConfigIframe extends Vue {
  private get remoteSrc() {
    const { type } = this.$route.meta;

    return process.env.VUE_APP_ADMIN_REMOTE_URL + `/member/sms/reject?serviceType=PRO&type=${type}`;
  }
}
