import { Component, Ref, Vue } from 'vue-property-decorator';
import { GetClaimItem, ClaimItemOption, ActionType } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import { claimTypes, claimClassTypes } from '@/const/claim.ts';

type FormatClaim = GetClaimItem &
  ClaimItemOption & { _isMerge?: Record<string, any>; _attributes?: Record<string, any> };

@Component
export default class ContentBottomMixin extends Vue {
  @Ref('grid') protected readonly gridRef: Grid;

  // 프리미엄에서 유래, 셀 병합을 위한 메서드
  /**
   * claim array의 각 claim 객체에서 depths가 있는 데이터를 key1값에 넣고, key1 프로퍼티들을 상위 객체에 삽입하여 다시 삽입하여 array를 return하는 함수
   * 덤으로 tui-grid에 쓰일 _attribute와 rowspan 프로퍼티도 넣어준다. (근데 넣어주기만 한다. 값을 빈값으로 넣는다.)
   * @param arr claims
   * @param key1 options
   */
  // 대표로 'options'만 받는도록 타이핑, 다른걸 넣을 필요가 있을 때마다 하나씩 추가하기
  public claimFormatterList(arr: GetClaimItem[], key1: 'options') {
    const arry: FormatClaim[] = [];
    if (arr && arr.length > 0) {
      arr.forEach(claimItem => {
        claimItem[key1].forEach(item1 => {
          const obj: FormatClaim = {} as FormatClaim;
          Object.keys(claimItem).forEach(name => {
            if (name !== key1) {
              obj[name] = claimItem[name];
            }
          });

          Object.keys(item1).forEach(name1 => {
            obj[name1] = item1[name1];
          });

          if (claimItem.options.length <= 1) {
            this.$set(obj, '_isMerge', false);
          } else {
            this.$set(obj, '_isMerge', true);
          }
          this.$set(obj, '_attributes', {});
          this.$set(obj['_attributes'], 'rowSpan', {});

          arry.push(obj);
        });
      });

      return arry;
    }
  }

  /**
   * @param 행들을 merge 하고자 하는 row 리스트 (claims)
   * @param bigRow merge행이 많은 필드명 리스트
   * @param bigGroupFlag merge행 필드 중 임의로 하나
   */
  public mergeClaimRow(FormatClaims: FormatClaim[], bigRow: string[], bigGroupFlag: 'claimNo') {
    if (FormatClaims && FormatClaims.length === 0) {
      return;
    }

    const bigGroup: number[] = [];
    const newArry: FormatClaim[] = [];
    FormatClaims.forEach(formatClaimItem => {
      if (!bigGroup.includes(formatClaimItem[bigGroupFlag])) {
        bigGroup.push(formatClaimItem[bigGroupFlag]);
      }
    });

    bigGroup.forEach((claimNo): void => {
      const nums = FormatClaims.filter(item => claimNo === item[bigGroupFlag]);
      let firstRow = true;
      FormatClaims.forEach((item): void => {
        if (claimNo === item[bigGroupFlag]) {
          if (firstRow && nums.length > 1 && item._isMerge) {
            const rowSpanTag = bigRow;
            this.addRowSpan(rowSpanTag, item, nums.length);
            firstRow = false;
          }
          newArry.push(item);
        }
      });
    });

    return newArry;
  }

  public addRowSpan(arr: string[], tag: FormatClaim, length: number): void {
    if (arr.length === 0) {
      return;
    }
    arr.forEach(item => {
      if (length > 1) {
        this.$set(tag['_attributes']['rowSpan'], item, length);
      }
    });
  }

  // 그룹 체크 기능
  private isFirstCheck = false;
  private onCheck(event) {
    // 탈추조건: 첫번째 체크 이벤트가 아니면 탈출
    if (this.isFirstCheck) {
      return;
    }

    const { rowKey, checkType } = event;

    if (this.hasGroup(rowKey)) {
      const groupRowKeys = this.getRowKeysOfGroupByClaimNo(rowKey).filter(groupRowKey => groupRowKey !== rowKey);

      this.toggleGroupCheck(groupRowKeys, checkType);
    }
  }

  private hasGroup(rowKey) {
    const targetRow = this.gridRef.getRowAt(rowKey);
    if (targetRow === null) {
      return false;
    }

    const claimRows = this.gridRef.getData().filter(rowData => rowData.claimNo === targetRow.claimNo);

    return claimRows.length > 1;
  }

  private getRowKeysOfGroupByClaimNo(rowKey) {
    const checkedRow = this.gridRef.getRowAt(rowKey);
    const data = this.gridRef.getData();

    const groupRowKeys = data.reduce(
      (acc, currentClaimData: any) =>
        currentClaimData.claimNo === checkedRow.claimNo ? [...acc, currentClaimData.rowKey] : acc,
      [],
    );

    return groupRowKeys;
  }

  private toggleGroupCheck(groupRowKeys: number[], nextCheckedStatus: 'check' | 'uncheck') {
    groupRowKeys.forEach(rowKey => {
      this.isFirstCheck = true;

      nextCheckedStatus === 'check' ? this.gridRef.checkRow(rowKey) : this.gridRef.uncheckRow(rowKey);
    });
    this.isFirstCheck = false;
  }

  //이미출고
  protected async checkDelivery(
    claimNo: number,
    targetClaimStatus: typeof claimTypes.CANCEL | typeof claimClassTypes.CANCEL_EXCHANGE,
  ): Promise<{ nextActionType: ActionType; afterClaimNos: string[]; shippingNo: number }> {
    const request = {
      pathParams: {
        no: claimNo.toString(),
      },
    };

    const checkDelivery = {
      CANCEL: () => this.$api.getOptionCancelsNoCheckAlreadyDelivery(request),
      CANCEL_EXCHANGE: () => this.$api.getCancelExchangesNoCheckAlreadyDelivery(request),
    };

    const {
      data: { nextActionType, shippingNo, afterClaimNos },
    } = await checkDelivery[targetClaimStatus]();

    return { nextActionType, shippingNo, afterClaimNos };
  }

  protected async withdrawClaim(
    claimNo: number,
    targetClaimStatus: typeof claimTypes.CANCEL | typeof claimClassTypes.CANCEL_EXCHANGE,
  ): Promise<void> {
    const request = {
      pathParams: {
        no: claimNo.toString(),
      },
    };

    const withdraw = {
      CANCEL: () => this.$api.putOptionCancelsNoWithdraw(request),
      CANCEL_EXCHANGE: () => this.$api.putCancelExchangesNoWithdraw(request),
    };

    await withdraw[targetClaimStatus]();
  }

  protected makeAlreadyDeliveryMessage({
    type,
    shippingNo,
    afterClaimNos,
  }: {
    type: 'NORMAL_WITHDRAWAL' | 'EXISTS_AFTER_CLAIM';
    shippingNo?: number;
    afterClaimNos?: string[];
  }): string {
    let message = '';

    message +=
      type === 'NORMAL_WITHDRAWAL'
        ? this.$t('CLAIM.MESSAGE.WARNING_AUTO_WITHDRAW_CLAIM').toString()
        : `${this.$t('CLAIM.MESSAGE.ASK_AFTER_CLAIM_WITHDRAW_CONFIRM').toString()}`;

    message +=
      type === 'NORMAL_WITHDRAWAL'
        ? `\n${this.$t('CLAIM.COMMON.DELIVERY_NO').toString()}: ${shippingNo}`
        : `\n${this.$t('CLAIM.COMMON.AFTER_CLAIM_NO').toString()}: ${afterClaimNos}`;

    return message;
  }
}
