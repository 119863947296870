
















































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import { KakaoAutoTemplatesButton, KakaoTemplateAutoButtonLinkType } from 'ncp-api-supporter';
import SelectBox from '@/components/common/SelectBox.vue';
import { buttonLinkOption, ButtonLinkType } from '@/const/contents/kakao';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({
  components: { TextInput, SelectBox },
})
export default class ButtonConfig extends Vue {
  @PropSync('buttonData', { default: [] })
  private readonly buttonDataSync!: KakaoAutoTemplatesButton[];
  private readonly buttonLinkOption = buttonLinkOption;

  private isWebLink(linkType: KakaoTemplateAutoButtonLinkType): boolean {
    return linkType === ButtonLinkType.WL;
  }

  private getRowspan(linkType: KakaoTemplateAutoButtonLinkType): string {
    return this.isWebLink(linkType) ? '2' : '';
  }

  public emptyButtonName() {
    return this.buttonDataSync.some(({ name }, index) => {
      if (!name?.length) {
        const target = `buttonName${index}`;
        (this.$refs[target][0] as TextInput).focus();
        return true;
      }
    });
  }

  public emptyButtonLink() {
    return this.buttonDataSync.some(({ linkType, linkMo }, index) => {
      if (linkType !== ButtonLinkType.WL) return false;
      if (!linkMo?.length) {
        const target = `buttonLinkMo${index}`;
        (this.$refs[target][0] as TextInput).focus();
        return true;
      }
    });
  }

  private controlButton(index: number) {
    if (index) {
      this.buttonDataSync.splice(index, 1);
      return;
    }
    const MAX_SIZE = 5;
    if (this.buttonDataSync.length >= MAX_SIZE) {
      alert(this.$t('MEMBER.KAKAO.ALERT_BUTTON_MAX'));
      return;
    }
    this.buttonDataSync.push({ linkType: ButtonLinkType.WL, name: '', linkMo: '' });
  }
}
