















import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';

@Component({
  components: {
    ModalHeader,
  },
})
export default class DisplayCategoryDetail extends Vue {
  @Prop() private data!: string[];
}
