























import { Vue, Component, Prop } from 'vue-property-decorator';
import { unescapeHtml } from '@/utils/common';
interface Article {
  date: string;
  link: string;
  title: string;
  newArticle: boolean;
}

@Component
export default class ArticleList extends Vue {
  @Prop({ required: true })
  private readonly title!: string;
  @Prop({ required: true })
  private readonly detailLink!: string;
  @Prop({ required: true })
  private readonly articles!: Article[];
  private unescapeHtml = unescapeHtml;
}
