




























import RootElement from '@/components/common/RootElement.vue';
import ClickOutside from 'vue-click-outside';
import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';

interface Option {
  id: string;
  value: string;
  display: string;
  selected?: boolean;
}

@Component({
  components: { RootElement },
  directives: { ClickOutside },
})
export default class MultipleSelect extends Vue {
  @Prop({ default: false }) private disabled!: boolean;

  @Prop() private options?: Option[];

  private selectInfo = {
    name: 'multipleSelect',
    nonI18nConvert: false,
    options: [],
  };
  private initOver = false;

  private showFlg = false;
  private showOptions: Option[] = [];

  private get allSelected() {
    const selectedCnt = this.showOptions.filter(option => option.selected).length;
    return selectedCnt === this.showOptions.length || selectedCnt === 0;
  }
  @Model('change') readonly value!: string;
  @Watch('value', { immediate: false, deep: false })
  onValueChanged(next: string) {
    this.showOptions.forEach(option => {
      option.selected = next.indexOf(option.value) > -1;
    });
  }

  private selectedOption(option: Option) {
    const tempVals: string[] = [];
    this.showOptions.forEach(item => {
      if (item.id === option.id) {
        item.selected = !item.selected;
      }
      if (item.selected) {
        tempVals.push(item.value);
      }
    });
    this.$emit('change', tempVals.join(','));
  }

  private focus() {
    (this.$refs.numInput as HTMLInputElement).focus();
  }
  private async initOption() {
    if (this.options) {
      Object.assign(this.selectInfo, this.options);
    }
    if (this.selectInfo.options) {
      this.showOptions = this.selectInfo.options.map((option: Option) => {
        return {
          id: option.id,
          value: option.value,
          display: option.display,
          selected: this.value.indexOf(option.value) > -1,
        };
      });
      this.initOver = true;
    }
  }
  private async mounted() {
    await this.initOption();
  }
}
