// text helper
import { i18n } from '@/main';
// types
import tuiGrid, { RowKey } from '@/types/tui-grid';
import { CellRenderer } from '@/types/tui-grid/renderer';
import {
  PartnerRendererProps,
  PartnerRowData,
  RendererMapByContractStatus,
} from '@/views/contents/partner/modification/PartnerModificationTypes';
// get html
import {
  getGridTextSpan,
  getRadioContainer,
  getRadio,
  getLabel,
  appendChildren,
} from '@/views/contents/partner/modification/grid/gridHTMLs';

const getUseText = () => i18n.t('PARTNER.EDIT.USE').toString();
const getUnuseText = () => i18n.t('PARTNER.EDIT.NO_USE').toString();

// *** renderers ***
const renderUseDefaultCommissionRateText = (usesDefaultCommissionRate: boolean) => {
  return {
    getElement(): HTMLElement {
      return getGridTextSpan(usesDefaultCommissionRate ? getUseText() : getUnuseText());
    },
    render() {
      return;
    },
  };
};

const renderUseDefaultCommissionRateRadioGroup = (
  grid: tuiGrid,
  rowKey: RowKey,
  usesDefaultCommissionRate: boolean,
) => {
  const useCommissionRate = (used: boolean) => {
    const commissionRateInput = grid.getElement(rowKey, 'pureCommissionRate').children[0] as HTMLInputElement;
    commissionRateInput.disabled = false;

    grid.setValue(rowKey, 'usesDefaultCommissionRate', used);
  };

  const unuseCommissionRate = (used: boolean) => {
    const commissionRateInput = grid.getElement(rowKey, 'pureCommissionRate').children[0] as HTMLInputElement;
    commissionRateInput.disabled = true;

    grid.setValue(rowKey, 'usesDefaultCommissionRate', used);
  };

  const elementKey = `usesDefaultCommissionRate-${rowKey}`;
  const yRadioId = `${elementKey}-Y`;
  const nRadioId = `${elementKey}-N`;

  const useDefaultCommissionRateRadioContainer = getRadioContainer(elementKey);

  const useRadio = getRadio(yRadioId, elementKey, usesDefaultCommissionRate, () => useCommissionRate(true));
  const useLabel = getLabel(yRadioId, getUseText());

  const unuseRadio = getRadio(nRadioId, elementKey, !usesDefaultCommissionRate, () => unuseCommissionRate(false));
  const unuseLabel = getLabel(nRadioId, getUnuseText());

  const children = [useRadio, useLabel, unuseRadio, unuseLabel];
  appendChildren(useDefaultCommissionRateRadioContainer, children);

  return {
    getElement(): HTMLElement {
      return useDefaultCommissionRateRadioContainer;
    },

    render() {
      return;
    },
  };
};

// *** useDefaultCommissionRateRendererByContractStatus ***
export default (props: PartnerRendererProps<'usesDefaultCommissionRate'>): CellRenderer => {
  const contractStatus = props.grid.getValue<PartnerRowData, 'contractStatus'>(props.rowKey, 'contractStatus');

  const rendererMap: RendererMapByContractStatus<'usesDefaultCommissionRate'> = {
    WAITING({ value: isUseDefaultCommissionRated }) {
      return renderUseDefaultCommissionRateText(isUseDefaultCommissionRated);
    },

    INVESTIGATION({ grid, rowKey, value: isUseDefaultCommissionRated }) {
      return renderUseDefaultCommissionRateRadioGroup(grid, rowKey, isUseDefaultCommissionRated);
    },

    ACTIVE({ grid, rowKey, value: isUseDefaultCommissionRated }) {
      return renderUseDefaultCommissionRateRadioGroup(grid, rowKey, isUseDefaultCommissionRated);
    },
  };
  return rendererMap[contractStatus](props);
};
