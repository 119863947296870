
























import { Vue, Component } from 'vue-property-decorator';

export const PRODUCT_REGISTRATION_TYPE = {
  SINGLE: 'SINGLE',
  MAPPING: 'MAPPING',
  MASS: 'MASS',
} as const;

@Component
export default class ProductRegistration extends Vue {
  private i18nForProductCommon(word: string) {
    return this.$t(`PRODUCT.COMMON.${word}`).toString();
  }
  get registrationTypeList() {
    return [
      {
        title: 'TITLE_SINGLE',
        thumbnail: 'icon_option_set_4.svg',
        name: 'ProductAdd',
        type: PRODUCT_REGISTRATION_TYPE.SINGLE,
        descriptions: ['DESC_SINGLE_1', 'DESC_SINGLE_2'],
      },
      // 2차 그랜드 오픈 스펙 기준 세트상품 제외
      // {
      //   title: 'TITLE_MAPPING',
      //   thumbnail: 'icon_option_set_5.svg',
      //   name: 'ProductAdd',
      //   type: PRODUCT_REGISTRATION_TYPE.MAPPING,
      //   descriptions: ['DESC_MAPPING_1', 'DESC_MAPPING_2'],
      // },
      {
        title: 'TITLE_MASS',
        thumbnail: 'icon_option_set_6.svg',
        name: 'ProductBulkAdd',
        type: PRODUCT_REGISTRATION_TYPE.MASS,
        descriptions: ['DESC_MASS_1', 'DESC_MASS_2'],
        className: 'mass',
      },
    ];
  }
}
