import { OptColumn } from '@/types';
import { Row } from '@/types/tui-grid';
import { hyphenRenderer, selectRenderer } from '@/utils/grid/rendererUtils';
import { CustomCellRendererProps } from '@/views/contents/product/basic/GridProps';
import { i18n } from '@/main';
import {
  NCPResponse,
  ProductsSaleSettingStatusType,
  PutProductsSaleStatus,
  PutProductsSaleStatusErrorCode,
  PutProductsSaleStatusRequest,
} from 'ncp-api-supporter';
import { api } from '@/api';
import { changeStatusRowValidation } from '@/components/product/common/girdColumns/edit/ChangeStatusRowValidation';
import { RendererEventCallback } from '@/views/contents/product/basic/ProductList.vue';
import { errorCaseCounter, getErrorMessages } from '@/utils/i18nMessage';
import { BEFORE_APPROVAL_STATUS } from '@/const/contents/product';
import { ApplyStatusType } from '@/components/product/basic/SearchOptionTypes';

export const getUpdateSaleSettingStatusTypeColumn = (rendererEventCallback: RendererEventCallback): OptColumn => {
  return {
    header: 'PRODUCT.SALE_SETTING',
    name: 'saleSettingStatusType',
    align: 'center',
    minWidth: 160,
    renderer: (props: CustomCellRendererProps) => {
      props.className = 'sale-status';
      props.selectOptions = [
        { text: i18n.t('PRODUCT.SEARCH.AVAILABLE_FOR_SALE'), value: 'AVAILABLE_FOR_SALE' },
        { text: i18n.t('PRODUCT.SEARCH.STOP_SELLING'), value: 'STOP_SELLING' },
        { text: i18n.t('PRODUCT.SEARCH.PROHIBITION_SALE'), value: 'PROHIBITION_SALE' },
      ];

      const renderer = selectRenderer(props);
      const el = renderer.getElement();
      const row: Row = props.grid.getRow(props.rowKey);

      if (BEFORE_APPROVAL_STATUS.includes(row.applyStatusType as ApplyStatusType)) {
        return hyphenRenderer();
      }

      if (props.value === 'PROHIBITION_SALE') {
        el.setAttribute('disabled', 'true');
      }

      if (!changeStatusRowValidation(row)) {
        el.setAttribute('disabled', 'true');
      }

      props.callback = (event: Event) => {
        rendererEventCallback('change:sale-setting-status', event, row);
      };

      return renderer;
    },
  };
};

const updateSaleStatus = async (
  saleSettingStatusType: ProductsSaleSettingStatusType,
  mallProductNos: number | number[],
): Promise<ProductsSaleSettingStatusType | null> => {
  const request: PutProductsSaleStatusRequest = {
    data: {
      productNos: Array.isArray(mallProductNos) ? mallProductNos.map(v => v.toString()) : [mallProductNos.toString()],
      saleSettingStatusType: saleSettingStatusType as ProductsSaleSettingStatusType,
    },
  };

  try {
    const { data }: NCPResponse<PutProductsSaleStatus> = await api.putProductsSaleStatus(request);
    const isSucceed = !data.failures.length;

    // putProductsSaleStatus 는 재밋게도 상태수정이 불가할때에도 200을 내려준다.
    // 따라서 현재 프론트에서는 IsSucceed 에 걸리는 결과값만을 200으로 판단하기로 한다.
    const successCount = data.successNos.length;
    const successMethodMessage = i18n.t('PRODUCT.EDIT.ALERT_SOME_CHANGE_COMPLETE', {
      name: i18n.t(`PRODUCT.SEARCH.${saleSettingStatusType}`),
    });

    if (isSucceed) {
      const successCountMessage = i18n.t('SUCCESS_N', { count: successCount }).toString();
      const successMessage = `${successCountMessage} : ${successMethodMessage}`;
      alert(successMessage);
      return saleSettingStatusType;
    } else {
      const failures = data.failures;
      const duplicateCount =
        errorCaseCounter<PutProductsSaleStatusErrorCode>('NON_EDITABLE_SALE_STATUS', failures) + successCount;
      const successCountMessage = i18n.t('SUCCESS_N', { count: duplicateCount }).toString();
      const successMessage = `${successCountMessage} : ${successMethodMessage}`;
      const failMessages = getErrorMessages<PutProductsSaleStatusErrorCode>(
        ['NON_EDITABLE_APPLY_STATUS', 'NON_EDITABLE_PROHIBITION_PRODUCT', 'NON_EDITABLE_PRODUCT'],
        failures,
        'PRODUCT.EDIT',
      );

      if (!failMessages?.length) {
        alert(successMessage);
        return null;
      }
      const message = isSucceed ? successMessage + '\n' + failMessages.join('\n') : failMessages.join('\n');

      alert(message);
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};

export const statusUpdatesRelatedToSaleStatus = async (
  saleSettingStatusType: ProductsSaleSettingStatusType,
  mallProductNos: number | number[],
): Promise<ProductsSaleSettingStatusType> => {
  try {
    // 이무희 책임님이 판매중지/금지일 경우 노출안된다고 알려주셔서 updateFrontDisplay 는 변경하지않는다.
    //await updateFrontDisplay('N', mallProductNos, false); // 판매금지 상품은 전시상태 : 'N' 여야 한다.
    return updateSaleStatus(saleSettingStatusType, mallProductNos);
  } catch (error) {
    console.error(error);
  }
};

const updatedSaleStatusReflectInDOM = (
  changedStatus: ProductsSaleSettingStatusType | null,
  el: HTMLSelectElement,
  row: Row,
): void => {
  if (!changedStatus) {
    // 실패시 기존값으로 되돌린다.
    el.value = row.saleSettingStatusType.toString();
    return;
  }

  if (changedStatus !== 'PROHIBITION_SALE') return;
  el.setAttribute('disabled', 'true'); // 판매 금지일 시 편집불가 이므로 disabled
};

export const changeSaleSettingStatus = (event: Event, row: Row) => {
  const target = event.target as HTMLSelectElement;
  const saleSettingStatusType = target.value as ProductsSaleSettingStatusType;

  statusUpdatesRelatedToSaleStatus(saleSettingStatusType, row.mallProductNo as number).then(changedStatus => {
    updatedSaleStatusReflectInDOM(changedStatus, target, row);
  });
};
