


















































import { Vue, Component, Watch, Prop, PropSync, Ref } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { Mall, GetMembersRequest, NCPResponse, GetMembers } from 'ncp-api-supporter';
import { Getter } from 'vuex-class';
import { getMemberListDisplayOption, getMemberListGridOption, getMemberListInitQuery } from '@/const/contents/member';
import {
  getGradePopupOptions,
  getWithdrawalMemberPopupOptions,
  openLayerPopup,
  MemberPopupType,
  openWindowPopup,
  getAccumulationsPopupOptions,
  getCouponsIssuePlansPopupOptions,
} from '@/const/contents/memberPopup';
import { CheckGridEventProps, GridEventProps, MemberListContents, MemberListPopupType } from '@/types';
import { PopupClose, PopupResult, throwWindowPopup } from '@/helpers/popup';
import { SelectedMemberInfo } from '@/types/member';
import { getMallName } from '@/utils/mall';
import { isEmpty, omit } from 'underscore';
import { getUnitDigitStr } from '@/utils/dateFormat';
import store from '@/store';

@Component({
  components: {
    Grid,
  },
})
export default class MemberList extends Vue {
  @Getter('mall/getMalls')
  private readonly malls!: Mall[];
  @Ref()
  private readonly mallGrid!: Grid;

  @Prop({ default: '' })
  private readonly popupType!: MemberListPopupType;
  @PropSync('selectedMember')
  private selectedMemberSync!: SelectedMemberInfo;
  @PropSync('popupQuery', { default: null })
  private popupQuerySync!: GetMembersRequest['params'];
  @PropSync('resetSelectedMember', { default: true })
  private resetSelectedMemberSync: boolean;

  private gridProps = getMemberListGridOption(this.popupType === 'crm');
  private query = getMemberListInitQuery();
  private displayOption = getMemberListDisplayOption(this.popupType);

  private contents: MemberListContents = [];
  private totalCount = 0;

  private selected: string[] = [];

  private selectedMemberMap: Record<string, number[]> = {};
  private get selectedMemberCount() {
    return Object.values(this.selectedMemberMap).flat()?.length ?? 0;
  }

  private created() {
    if (!isEmpty(omit(this.$route.query, 'popupId', 'width')) || !isEmpty(this.popupQuerySync)) this.search();
  }

  mounted() {
    this.mallGrid.changeNoDataMessage(this.$t('MUST_SEARCH_MEMBER'));
  }

  @Watch('$route.query')
  private search(): void {
    this.initQuery();

    const request: GetMembersRequest = { params: this.query } as GetMembersRequest;

    if (this.resetSelectedMemberSync) {
      this.selectedMemberMap = {};
      this.resetSelectedMemberSync = false;
    }

    this.$api.getMembers(request).then((response: NCPResponse<GetMembers>) => {
      if (response && response.status === 200) {
        const { contents, totalCount } = response.data;
        const mallName = getMallName(Number(this.query.mallNo));
        const selectedMembers = this.selectedMemberMap[request.params.page];

        this.contents = contents.map(({ memberNo, ...rest }) => ({
          memberNo,
          mallName,
          ...rest,
          _attributes: {
            checked: selectedMembers?.includes(memberNo),
          },
        }));

        this.totalCount = totalCount;
        if (this.selectedMemberSync) {
          this.selected = this.selectedMemberSync.selected as string[];
          this.$nextTick(() => this.selectedMemberSync.selected.forEach(value => this.mallGrid.checkRow(value)));
        }
        this.mallGrid.changeNoDataMessage(this.$t('NO_RESULT'));
      }
    });
  }

  private initQuery(): void {
    this.query = !isEmpty(this.query) ? { ...getMemberListInitQuery(), ...this.$route.query } : this.query;
    this.popupQuerySync = this.query as GetMembersRequest['params'];

    if (!this.query.keyword || this.query.keyword.length === 0) {
      this.query.searchType = 'ALL';
    }
    if (this.query.birthmonth && this.query.dayOfBirthday) {
      this.query.birthday = `${getUnitDigitStr(this.query.birthmonth)}${getUnitDigitStr(this.query.dayOfBirthday)}`;
      delete this.query.birthmonth;
      delete this.query.dayOfBirthday;
    }
    if (this.query.birthmonth && !this.query.dayOfBirthday) {
      delete this.query.birthday;
    }
  }

  private getExistSelectedMember(): boolean {
    if (this.selected.length === 0) {
      alert(this.$t('MEMBER.LIST.ALERT_SELECT_MEMBER_NONE'));
      return false;
    }
    return true;
  }

  // 팝업 작업
  private changeGrade(): void {
    if (!this.getExistSelectedMember()) return;
    openLayerPopup(this.query.mallNo, this.selected, this.contents, getGradePopupOptions(), MemberPopupType.GRADE).then(
      this.PopupCloseCallback,
    );
  }

  private changeAccumulation(): void {
    if (!this.getExistSelectedMember()) return;
    openWindowPopup(
      this.query.mallNo,
      this.selected,
      this.contents,
      getAccumulationsPopupOptions(),
      MemberPopupType.ACCUMULATIONS_MEMBER_LIST,
      this.PopupCloseCallback,
      'AccumulationsAssemblesPopup',
    );
  }

  private giveCoupon(): void {
    if (!this.getExistSelectedMember()) return;
    openWindowPopup(
      this.query.mallNo,
      this.selected,
      this.contents,
      getCouponsIssuePlansPopupOptions(),
      MemberPopupType.COUPON_ISSUE_PLANS_MEMBER_LIST,
      this.PopupCloseCallback,
      'CouponIssuePlansPopup',
    );
  }

  private withdrawMember(): void {
    if (!this.getExistSelectedMember()) return;
    openLayerPopup(
      this.query.mallNo,
      this.selected,
      this.contents,
      getWithdrawalMemberPopupOptions(),
      MemberPopupType.WITHDRAWAL,
    ).then(this.PopupCloseCallback);
  }

  private onItemClicked(gridProps: GridEventProps): void {
    const isPremiumPlanType = store?.getters['admin/getAdmin']?.plan === 'PREMIUM';

    switch (gridProps.columnName) {
      case 'memberNo':
      case 'mallName':
      case 'name':
        if (isPremiumPlanType) return;
        throwWindowPopup('CrmMain', {}, 'xxlg', this.search, null, null, null, null, {
          memberNo: gridProps.rowKey.toString(),
        });
        break;
      case 'memberRegistrationModify':
        this.$router.push({ name: 'MemberEdit', query: { memberNo: gridProps.rowKey as string } });
        break;
    }
  }

  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.$set(
      this.selectedMemberMap,
      `${this.query.page}`,
      checkProps.selected.map(prop => Number(prop)),
    );
    this.selected = checkProps.selected as string[];

    if (this.popupType === '') return;
    const selectedMemberSmsInfo = this.mallGrid.getCheckedRows().map(({ smsAgreed, memberNo }) => {
      return { smsAgreed: Boolean(smsAgreed), memberNo: Number(memberNo) };
    });
    const rejectCount = this.mallGrid.getCheckedRows().filter(({ smsAgreed }) => !smsAgreed).length;
    this.selectedMemberSync = {
      selected: Object.values(this.selectedMemberMap)?.flat() ?? [],
      selectedMemberSmsInfo,
      rejectCount,
    };
  }

  private PopupCloseCallback(callback: PopupResult) {
    if (callback.state === PopupClose.CONFIRM) {
      this.search();
    }
  }
}
