import { i18n } from '@/main';
import { TranslateResult } from 'vue-i18n';

export const accumulationToolTipMessage = (): { [key: string]: TranslateResult } => {
  return {
    EXPIRE: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_EXPIRE'),
    PAY: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_PAY'),
    PAY_PRODUCT: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_PAY_PRODUCT'),
    PAY_GRADE: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_PAY_GRADE'),
    PAY_POINT: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_PAY_POINT'),
    JOIN: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_JOIN'),
    REVIEW: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_REVIEW'),
    BIRTH: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_BIRTH'),
    MIN: i18n.t('ACCUMULATION.CONFIG_MODIFY.TOOLTIP_MIN'),
  };
};
