import { RemoveResult, RemoveSelectedOption, ChangeDisplayOption } from '@/types';

export const INITIAL_DIGIT = 0;
export enum MOVE_TYPE {
  TOP,
  BOTTOM,
  UP,
  DOWN,
  CUSTOM,
  FIX,
}

export const sortByDisplayOrder = (changeDisplayOptions: ChangeDisplayOption[]): ChangeDisplayOption[] => {
  return changeDisplayOptions.sort((a, b) => {
    if (a.fixed && !b.fixed) return -1;
    return a.displayOrder - b.displayOrder;
  });
};

export const mapOriginAndPreviousDisplayOrder = (changeDisplayOptions: ChangeDisplayOption[]): void => {
  changeDisplayOptions.forEach(each => {
    const orderIdx = each.rowKey + 1;
    if (each.originDisplayOrder === undefined) {
      each.originDisplayOrder = orderIdx;
    }
    each.prevDisplayOrder = orderIdx;
  });
};

export const setChangeHistory = (total: number, changeDisplayOptions: ChangeDisplayOption[]) => {
  let idx = 0;
  while (idx < total) {
    const each = changeDisplayOptions[idx];
    const newDisplayOrder = idx + 1;
    changeDisplayOptions[idx] = {
      ...each,
      rowKey: idx,
      displayOrder: newDisplayOrder,
    };

    idx++;
  }
};

export const removeSelected = (
  { message, data, totalSize, origins }: RemoveSelectedOption,
  useRemoveOption = true,
): RemoveResult => {
  if (useRemoveOption) {
    if (!origins.length) {
      alert(window.$t(message.needOrigin));
      return;
    }
    if (!confirm(window.$t(message.confirmToRemove))) return;
  }

  mapOriginAndPreviousDisplayOrder(data);

  data = data.filter(each => !origins.includes(each.rowKey));
  totalSize = totalSize - origins.length;

  setChangeHistory(totalSize, data);
  return { data, totalSize };
};

export const getPreviousKeys = (data: ChangeDisplayOption[], origins: number[]) => {
  const previousKeys = [];
  data.forEach(each => {
    if (origins.includes(each.prevDisplayOrder - 1)) {
      previousKeys.push(each.displayOrder - 1);
    }
  });
  return previousKeys;
};
