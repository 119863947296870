



























import { Vue, Component, Prop } from 'vue-property-decorator';

export interface AlertOption {
  mainTitle: string;
  subTitle?: string[];
  message?: string[];
  buttons?: { text: string; color?: string; onClick: Function }[];
}

@Component
export default class Alert extends Vue {
  @Prop() private option: AlertOption;

  private isSubTitle(): boolean {
    return this.option.subTitle && this.option.subTitle.length > 0;
  }

  private isMessage(): boolean {
    return this.option.message && this.option.message.length > 0;
  }

  private isButtons(): boolean {
    return this.option.buttons && this.option.buttons.length > 0;
  }

  private getButtonColor(color?: string): {} {
    switch (color) {
      case 'red':
      case 'white':
      case 'black': {
        const result = {};
        result[`type-${color}`] = true;
        return result;
      }
    }

    return { 'type-red': true };
  }
}
