
























import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import NotificationHistorySearchForm from '@/views/contents/member/kakao/NotificationHistorySearchForm.vue';
import Grid from '@/components/common/grid/Main.vue';
import { getDisplayOptions } from '@/const/contents/sms';
import { getKakaoMessagesRequestQuery, getNotificationHistoryGirdOption } from '@/const/contents/kakao';
import { GetKakaoMessagesPopupResponse, GetKakaoMessagesRequest, KaKaoSendList } from 'ncp-api-supporter';
import { setNoDataMessage } from '@/helpers/grid';
import { isEmpty, omit } from 'underscore';
import { GridEventProps } from '@/types';
import { getCurrentMallNo } from '@/utils/mall';
import { checkSearchResult, openHistoryDetailsLayer } from '@/views/contents/member/kakao/SearchTemplates';
import { Route } from 'vue-router';
@Component({
  components: { NotificationHistorySearchForm, Grid },
})
export default class NotificationHistory extends Vue {
  @Ref()
  private readonly grid!: Grid;

  private get mallNo(): number {
    return Number(this.$route.query.mallNo);
  }

  private gridOption = getNotificationHistoryGirdOption();
  private displayOptions = getDisplayOptions();
  private contents: KaKaoSendList[] = [];
  private totalCount = 0;
  private myIp = '';

  @Watch('$route')
  private async searchNotificationHistory(currRoute: Route, prevRoute: Route) {
    if (isEmpty(omit(this.$route.query, 'mallNo'))) return;

    const needsReset = checkSearchResult(currRoute, prevRoute, 'MUST_SEARCH_HISTORY', this);
    if (needsReset) return;

    try {
      const request: GetKakaoMessagesRequest = {
        params: {
          ...(getKakaoMessagesRequestQuery(getCurrentMallNo(this)) as GetKakaoMessagesRequest['params']),
          ...this.$route.query,
        },
      };
      const { data } = await this.$api.getKakaoMessages(request);
      this.contents = data.contents;
      this.totalCount = data.totalCount;
      this.grid.changeNoDataMessage(this.$t('NO_SEARCH_RESULTS'));
    } catch (e) {
      console.error(e);
    }
  }

  private async onItemClicked(gridProps: GridEventProps) {
    if (gridProps.columnName !== 'resultStatus') return;

    const { sendDate, kakaoSendKey } = this.grid.getRowAt(gridProps.rowKey);

    await openHistoryDetailsLayer<GetKakaoMessagesPopupResponse>(
      'kakao',
      {
        mallNo: getCurrentMallNo(this),
        sendDate: sendDate.toString(),
        sendKey: kakaoSendKey.toString(),
      },
      this.myIp,
    ).catch(console.error);
  }

  created() {
    setNoDataMessage(this.$t('MUST_SEARCH_HISTORY').toString());
    this.fetchMyIp();
  }

  async fetchMyIp() {
    const { data } = await this.$api.getManagerInfo();
    if (data?.ip) this.myIp = data.ip;
  }
}
