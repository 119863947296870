









import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import {
  NCPResponse,
  GridLayout,
  PutGridLayoutsRequest,
  GetGridLayoutsGridNameRequest,
  GridLayoutDetail,
} from 'ncp-api-supporter';
import { Admin, ColumnOrder, OptColumn, ThrowPopupPromise } from '@/types';
import { throwPopup } from '@/helpers/popup';
import { namespace } from 'vuex-class';
import { DetailOpenOptions } from 'ncp-api-supporter/dist/types/modules/admin/admin';
import { OrderDetailOpenType } from 'ncp-api-supporter/dist/types/enum';
const adminStore = namespace('admin');

export type GridName =  // TODO : sdk 로 가야하나 지금 sdk 없앤다 안없앤다 해서 우선 여기둠
  | 'PRO_PRODUCT_GRID'
  | 'PRO_PRODUCT_JUDGEMENT_GRID'
  | 'PRO_PRODUCT_AGREEMENT_GRID'
  | 'PRO_REVIEW_GRID'
  | 'PRO_REVIEW_REPORT_GRID'
  | 'PRO_PRODUCT_INQUIRY_GRID'
  | 'PRO_STOCK_MANAGEMENT_GRID'
  | 'PRO_WMS_GRID'
  | 'PRO_ORDER_ALL_GRID'
  | 'PRO_ORDER_DEPOSIT_WAIT_GRID'
  | 'PRO_ORDER_PAY_DONE_GRID'
  | 'PRO_ORDER_PRODUCT_PREPARE_GRID'
  | 'PRO_ORDER_DELIVERY_PREPARE_GRID'
  | 'PRO_ORDER_DELIVERY_ING_GRID'
  | 'PRO_ORDER_DELIVERY_DONE_GRID'
  | 'PRO_ORDER_BUY_CONFIRM_GRID'
  | 'PRO_ORDER_PAY_FAIL_GRID'
  | 'PRO_ORDER_HOLD_DELIVERY_GRID'
  | 'PRO_ORDER_HOLD_BY_RESERVATION_GRID'
  | 'PRO_ORDER_DELIVERY_PREPARE_TAB2_GRID'
  | 'PRO_CLAIM_CANCEL_GRID'
  | 'PRO_CLAIM_EXCHANGE_GRID'
  | 'PRO_CLAIM_RETURN_GRID'
  | 'PRO_CLAIM_REFUND_GRID'
  | 'PRO_CS_GRID'
  | 'PRO_MEMBER_GRID'
  | 'PRO_MEMBER_FREEZE_GRID'
  | 'PRO_MEMBER_EXPEL_GRID'
  | 'PRO_PROMOTION_DISCOUNT_GRID'
  | 'PRO_PROMOTION_COUPON_GRID'
  | 'PRO_PROMOTION_COUPON_ISSUE_PLAN_GRID'
  | 'PRO_ACCUMULATION_PAYMENT_ALL_GRID';

@Component
export default class ColumnController extends Vue {
  @PropSync('columns', { required: true }) private columnsSync!: OptColumn[];
  @Prop({ required: true })
  private readonly originColumns!: OptColumn[];
  @Prop({ required: false })
  private readonly propGridName!: GridName; // this.$route.meta.gridName 보다 우선권 지님
  @Prop({ default: null })
  private readonly visibleFixedColumnNames!: string[];
  @adminStore.Getter('getAdmin')
  private readonly me!: Admin;
  @adminStore.Mutation('SET_DETAIL_OPEN_OPTIONS')
  private setStoreDetailOpenOption: (detailOpenOptions: DetailOpenOptions) => void;

  @Watch('propGridName')
  private changeGridName() {
    this.gridName = this.propGridName ?? this.$route.meta.gridName;
    this.initGridLayout();
  }

  private gridName: GridName | null = null;
  private gridLayouts: GridLayout[] = [];
  private columnOrder: ColumnOrder[] = [];
  private detailOpenOptions: DetailOpenOptions | null = null;
  private canBeDriven = !!this.propGridName || !!this.$route.meta?.gridName;

  mounted() {
    if (!this.canBeDriven) return;
    this.setGridName();
    this.initGridLayout();
  }

  private async initGridLayout() {
    const hasData = await this.fetchGridLayout();
    const defaultGridLayout = this.getDefaultGridLayout();
    if (hasData) {
      this.setGridColumns(this.gridLayouts);
      this.setDetailOpenOptions(this.detailOpenOptions);
      const isSafe = this.updateColumnOrder(this.gridLayouts);
      // TODO: updateColumnOrder 과정에서 문제 발견되면 defaultGridLayout 로 데이터 처음부터 다시 맵핑. 조악하지만 우선 이 방법 외에 생각 안 남
      if (!isSafe) {
        this.setGridColumns(defaultGridLayout);
        this.updateColumnOrder(defaultGridLayout);
        return;
      }

      return;
    }
    this.setGridColumns(defaultGridLayout);
    this.setDetailOpenOptions(null);
    this.updateColumnOrder(defaultGridLayout);

    // PUT 요청은 필요없음. fetchGridLayout 에 아무값도 없다고 PUT 으로 데이터를 인위적으로 만들 필요는 없음.
    // 진짜 그리드 레이아웃이 수정되면 그때 그리드 개인설정 팝업에서 하면 됨
    // await this.updateGridLayout(defaultGridLayout, true, this.detailOpenOptions);
  }

  private setGridName(): boolean {
    const gridName = this.propGridName ?? this.$route.meta.gridName;
    if (gridName === undefined) return false;
    this.gridName = gridName;
    return true;
  }

  private onOpenEditGridLayoutModal() {
    throwPopup({
      name: 'EditGridLayout',
      data: {
        columnOrders: this.columnOrder,
        visibleFixedColumnNames: this.visibleFixedColumnNames,
        detailOpenOptions: this.detailOpenOptions,
      },
    }).then(
      ({ state, data }: ThrowPopupPromise<{ gridLayouts: GridLayout[]; detailOpenOptions: DetailOpenOptions }>) => {
        if (state === 'confirm') {
          const { gridLayouts, detailOpenOptions } = data;
          this.patchColumns(gridLayouts, false, detailOpenOptions);
          this.setDetailOpenOptions(detailOpenOptions);
        }
      },
    );
  }

  private patchColumns(gridLayouts: GridLayout[], noAlert = true, detailOpenOptions?: DetailOpenOptions) {
    this.gridLayouts = gridLayouts;
    detailOpenOptions
      ? this.updateGridLayout(gridLayouts, noAlert, detailOpenOptions)
      : this.updateGridLayout(gridLayouts, noAlert);
    this.setGridColumns(gridLayouts);
    this.updateColumnOrder(gridLayouts);
    if (detailOpenOptions) this.setDetailOpenOptions(detailOpenOptions);
  }

  /**
   * @return boolean : 데이터가 있는지 없는지 여부
   */
  private async fetchGridLayout(): Promise<boolean> {
    const request: GetGridLayoutsGridNameRequest = {
      pathParams: {
        gridName: this.gridName,
      },
    };

    try {
      const {
        data: { columns, detailOpenOptions },
      }: NCPResponse<GridLayoutDetail> = await this.$api.getGridLayoutsGridName(request);

      this.gridLayouts = columns;
      this.detailOpenOptions = detailOpenOptions;
      return columns.length > 0;
    } catch (error) {
      console.error(error);
    }
  }

  private async updateGridLayout(
    columns: GridLayout[],
    noAlert = false,
    detailOpenOptions?: DetailOpenOptions,
  ): Promise<void> {
    const request: PutGridLayoutsRequest = {
      data: {
        gridName: this.gridName,
        columns,
      },
    };

    if (detailOpenOptions) Object.assign(request.data, { detailOpenOptions });

    try {
      await this.$api.putGridLayouts(request);
      if (request.data.columns.length <= 0 && !detailOpenOptions) this.$router.go(0);

      if (noAlert) return;
      alert(this.$t('GRID.LAYER.ALERT_SAVED'));
    } catch (error) {
      console.error(error);
    }
  }

  private getDefaultGridLayout(): GridLayout[] {
    return this.originColumns.map(({ header, name }, index) => {
      const intersectionItem = this.gridLayouts.find(dataItem => dataItem.columnName === name);
      const defaultVisible = name !== 'orderOptionNo';
      const visible = intersectionItem ? intersectionItem.visible : defaultVisible;

      return {
        name: header,
        columnName: name,
        displayOrder: index + 1,
        visible: visible ?? true,
      };
    });
  }

  /**
   * @return 데이터에 문제가 있으면 false 리턴
   */
  private updateColumnOrder(gridLayouts: GridLayout[]): boolean {
    let unknownColumnDetect = false;

    this.columnOrder = gridLayouts
      .map((outer, idx) => {
        let matchedColumn = this.originColumns.find(inner => inner.name === outer.columnName);
        if (!matchedColumn) {
          matchedColumn = this.originColumns[idx];
          outer.columnName = matchedColumn?.name ?? '';
          unknownColumnDetect = true;
        }
        const displayName: string = matchedColumn.header;

        return {
          ...outer,
          displayName,
        };
      })
      .filter(({ displayName }) => displayName !== null);

    const duplicateColumnDetect = gridLayouts.some(({ columnName }, outerIndex) =>
      gridLayouts.some((inner, innerIndex) => {
        if (outerIndex === innerIndex) return;
        if (columnName === inner.columnName) {
          return true;
        }
      }),
    );

    const lengthMissMatch = this.getDefaultGridLayout().length !== gridLayouts.length;

    /**
     * 벨리데이션 삼대장. 이 것 중 하나라도 통과 못하면 그리드는 초기값으로 되돌아간다.
     * 좀 과해보일 수도 있지만 이렇게 안하면 프론트에서 gridProps 코드 업데이트 하면 문제가 발생함.
     * @unknownColumnDetect 데이터베이스에 있는 컬럼이 프론트에 없을 때 true
     * @lengthMissMatch 데이터베이스에 있는 컬럼 갯수와 프론트 컬럼 갯수가 일치하지 않을 때 true
     * @duplicateColumnDetect 데이터베이스에 동일한 이름의 컬럼이 있을 때 true
     *
     */
    if (unknownColumnDetect || lengthMissMatch || duplicateColumnDetect) {
      return false;
    }
    return true;
  }

  // 이건 어떤 컬럼을 보이게할지 getLayout 을 토대로 맵핑하는거라 데이터 수정과는 상관이없음
  private setGridColumns(gridLayouts: GridLayout[]): void {
    const visibleGridLayout = gridLayouts.filter(({ visible }) => visible);

    this.columnsSync = this.originColumns
      .filter(({ name }) => visibleGridLayout.some(({ columnName }) => columnName === name))
      .map(column => {
        const { displayOrder } = visibleGridLayout.find(({ columnName }) => columnName === column.name);
        return {
          ...column,
          displayOrder,
        };
      })
      .sort((fst, snd) => fst.displayOrder - snd.displayOrder);
  }

  private setDetailOpenOptions(detailOpenOptions: DetailOpenOptions): void {
    if (detailOpenOptions === null) {
      const initOption = {
        ORDER_DETAIL: 'NEW_TAB' as OrderDetailOpenType,
      };
      this.detailOpenOptions = initOption;
      this.setStoreDetailOpenOption(initOption);
      return;
    }
    this.detailOpenOptions = detailOpenOptions;
    this.setStoreDetailOpenOption(detailOpenOptions);
  }
}
