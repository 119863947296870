import { DefaultRequestParams } from '@/const/finderOptions';
import { addDay, getToday } from '@/utils/dateFormat';

export const platformTypes = {
  PC: 'PC',
  MOBILE_WEB: 'MOBILE_WEB',
  MOBILE_APP: 'MOBILE_APP',
} as const;

export const shippingAreaTypes = {
  PARTNER: 'PARTNER_SHIPPING_AREA',
  MALL: 'MALL_SHIPPING_AREA',
} as const;

export const orderListTypes = {
  ORDER_LIST: 'ORDER_LIST',
  DEPOSIT_WAIT: 'DEPOSIT_WAIT',
  PAY_DONE: 'PAY_DONE',
  PRODUCT_PREPARE: 'PRODUCT_PREPARE',
  DELIVERY_PREPARE: 'DELIVERY_PREPARE',
  DELIVERY_ING: 'DELIVERY_ING',
  DELIVERY_DONE: 'DELIVERY_DONE',
  BUY_CONFIRM: 'BUY_CONFIRM',
  PAY_FAIL: 'PAY_FAIL',
  HOLD_DELIVERY: 'HOLD_DELIVERY',
  HOLD_BY_RESERVATION: 'HOLD_BY_RESERVATION',
  PREVIOUS: 'PREVIOUS',
} as const;

export const orderStatusTypes = {
  DEPOSIT_WAIT: 'DEPOSIT_WAIT',
  PAY_DONE: 'PAY_DONE',
  PRODUCT_PREPARE: 'PRODUCT_PREPARE',
  DELIVERY_PREPARE: 'DELIVERY_PREPARE',
  DELIVERY_ING: 'DELIVERY_ING',
  DELIVERY_DONE: 'DELIVERY_DONE',
  BUY_CONFIRM: 'BUY_CONFIRM',
} as const;

export const orderNoGridTypes = {
  ORDER_LIST_TAB2: 'PRO_ORDER_ALL_TAB2_GRID',
  DEPOSIT_WAIT: 'PRO_ORDER_DEPOSIT_WAIT_GRID',
  PAY_DONE: 'PRO_ORDER_PAY_DONE_GRID',
  PRODUCT_PREPARE: 'PRO_ORDER_PRODUCT_PREPARE_GRID',
  DELIVERY_PREPARE_TAB2: 'PRO_ORDER_DELIVERY_PREPARE_TAB2_GRID',
  PAY_FAIL: 'PRO_ORDER_PAY_FAIL_GRID',
  HOLD_DELIVERY: 'PRO_ORDER_HOLD_DELIVERY_GRID',
  HOLD_BY_RESERVATION: 'PRO_ORDER_HOLD_BY_RESERVATION_GRID',
  PREVIOUS: 'PREVIOUS',
} as const;

export const deliveryNoGridTypes = {
  ORDER_LIST: 'PRO_ORDER_ALL_GRID',
  DELIVERY_PREPARE: 'PRO_ORDER_DELIVERY_PREPARE_GRID',
  DELIVERY_ING: 'PRO_ORDER_DELIVERY_ING_GRID',
  DELIVERY_DONE: 'PRO_ORDER_DELIVERY_DONE_GRID',
  BUY_CONFIRM: 'PRO_ORDER_BUY_CONFIRM_GRID',
} as const;

export const payTypes = {
  CREDIT_CARD: 'CREDIT_CARD',
  ACCOUNT: 'ACCOUNT',
  MOBILE: 'MOBILE',
  REALTIME_ACCOUNT_TRANSFER: 'REALTIME_ACCOUNT_TRANSFER',
  VIRTUAL_ACCOUNT: 'VIRTUAL_ACCOUNT',
  GIFT: 'GIFT',
  ATM: 'ATM',
  PAYCO: 'PAYCO',
  ZERO_PAY: 'ZERO_PAY',
  ACCUMULATION: 'ACCUMULATION',
  PHONE_BILL: 'PHONE_BILL',
  POINT: 'POINT',
  YPAY: 'YPAY',
  KPAY: 'KPAY',
  PAYPIN: 'PAYPIN',
  INIPAY: 'INIPAY',
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
  NAVER_PAY: 'NAVER_PAY',
  KAKAO_PAY: 'KAKAO_PAY',
  NAVER_EASY_PAY: 'NAVER_EASY_PAY',
  ESCROW_REALTIME_ACCOUNT_TRANSFER: 'ESCROW_REALTIME_ACCOUNT_TRANSFER',
  ESCROW_VIRTUAL_ACCOUNT: 'ESCROW_VIRTUAL_ACCOUNT',
  SAMSUNG_PAY: 'SAMSUNG_PAY',
  ETC: 'ETC',
} as const;

export const defaultParams = {
  mallNos: '',
  orderRegisterType: undefined,
  holdByReservation: false,
  includeReservation: true,
  memberGroupNo: undefined,
  searchType: 'ALL',
  keyword: '',
  platformTypes: ['PC', 'MOBILE_WEB', 'MOBILE_APP'],
  payTypes: [],
  deliveryType: undefined,
  shippingAreaType: undefined,
  includeHoldDelivery: true,
  returnWayType: '',
  claimStatusTypes: DefaultRequestParams.claimStatusTypes,
  orderStatusTypes: DefaultRequestParams.orderStatusTypes,
  taskMessageSearchType: 'ALL',
  includeRecommender: true,
  startYmd: addDay(new Date(), -7),
  endYmd: getToday(),
  searchDateType: 'STATE_CHANGE_YMDT',
  partnerNo: '',
  delay: false,
  page: 1,
  size: 30,
  receiverInputLater: '',
};

export const sortTypes = {
  ORDERER_NAME: 'ORDERER_NAME',
  ORDER_NO: '',
  DELIVER_NO: '',
  RECEIVER_NAME: 'RECEIVER_NAME',
  ORDER_AMT: 'ORDER_AMT',
} as const;

export const focusTabName = {
  DELIVERY_TAB: 'deliveryTab',
  ORDER_TAB: 'orderTab',
} as const;

export const pgTypes = {
  DUMMY: 'DUMMY',
  PAYCO: 'PAYCO',
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
  KCP: 'KCP',
  INICIS: 'INICIS',
  NONE: 'NONE',
  KCP_MOBILE: 'KCP_MOBILE',
  KCP_APP: 'KCP_APP',
  NAVER_PAY: 'NAVER_PAY',
  LIIVMATE: 'LIIVMATE',
  PAYPALPRO: 'PAYPALPRO',
  ATHOR_NET: 'ATHOR_NET',
  KAKAO_PAY: 'KAKAO_PAY',
  NAVER_EASY_PAY: 'NAVER_EASY_PAY',
  LG_U_PLUS: 'LG_U_PLUS',
} as const;

export const orderListTab: string[] = [
  'CANCEL_NO_REFUND',
  'CANCEL_REQUEST',
  'CANCEL_PROC_REQUEST_REFUND',
  'CANCEL_PROC_WAITING_REFUND',
  'CANCEL_DONE',
  'RETURN_REQUEST',
  'RETURN_REJECT_REQUEST',
  'RETURN_PROC_BEFORE_RECEIVE',
  'RETURN_PROC_REQUEST_REFUND',
  'RETURN_PROC_WAITING_REFUND',
  'RETURN_REFUND_AMT_ADJUST_REQUESTED',
  'RETURN_NO_REFUND',
  'RETURN_DONE',
  'EXCHANGE_REQUEST',
  'EXCHANGE_REJECT_REQUEST',
  'EXCHANGE_PROC_BEFORE_RECEIVE',
  'EXCHANGE_PROC_REQUEST_PAY',
  'EXCHANGE_PROC_WAITING',
  'EXCHANGE_PROC_WAITING_PAY',
  'EXCHANGE_DONE_PAY_DONE',
  'EXCHANGE_PROC_REQUEST_REFUND',
  'EXCHANGE_PROC_WAITING_REFUND',
  'EXCHANGE_DONE_REFUND_DONE',
  'EXCHANGE_DONE',
];

export const orderCancelListTab: string[] = [
  'CANCEL_NO_REFUND',
  'CANCEL_REQUEST',
  'CANCEL_PROC_REQUEST_REFUND',
  'CANCEL_PROC_WAITING_REFUND',
  'CANCEL_DONE',
];

export const orderReturnListTab: string[] = [
  'RETURN_REQUEST',
  'RETURN_REJECT_REQUEST',
  'RETURN_PROC_BEFORE_RECEIVE',
  'RETURN_PROC_REQUEST_REFUND',
  'RETURN_PROC_WAITING_REFUND',
  'RETURN_DONE',
];

export const orderExchangeListTab: string[] = [
  'EXCHANGE_REQUEST',
  'EXCHANGE_REJECT_REQUEST',
  'EXCHANGE_PROC_BEFORE_RECEIVE',
  'EXCHANGE_PROC_REQUEST_PAY',
  'EXCHANGE_PROC_WAITING_PAY',
  'EXCHANGE_DONE_PAY_DONE',
  'EXCHANGE_PROC_REQUEST_REFUND',
  'EXCHANGE_PROC_WAITING_REFUND',
  'EXCHANGE_DONE_REFUND_DONE',
  'EXCHANGE_DONE',
];

export type orderDetailButtonType = 'printSpecification' | 'taskMessage' | 'csMessage';
