export default Object.freeze({
  TITLE: '적립금 지급현황',
  TABLE_HEADER_MEMBER_NO: '회원번호',
  TABLE_HEADER_MEMBER_NAME: '회원명',
  TABLE_HEADER_MALL_NAME: '쇼핑몰',
  TABLE_HEADER_MEMBER_ID: '아이디',
  TABLE_HEADER_MEMBER_GRADE_NAME: '회원등급',
  TABLE_HEADER_MEMBER_GROUP_NAMES: '회원그룹',
  TABLE_HEADER_LAST_LOGIN: '최종로그인',
  TABLE_HEADER_NOTIFICATION: '푸시알림수신',
  TABLE_HEADER_SMS_AGREE_YN: 'SMS수신',
  TABLE_HEADER_DIRECT_MALL_AGREE_YN: 'DM수신',

  MALL_NAME: '쇼핑몰',
  PAYMENT_COUNT: '총 지급 회원수',
  AMUNT: '지급적립금',
  PAY_TOTAL: '총 지급 적립금',
  MEMBER_GRADE: '지급회원등급',
  REQUEST_DATA_TIME: '예약지급 일시',
  PAY_MEMBER_GROUP: '지급회원그룹',
  PAYMENT_DATA_TIME: '지급완료 일시',
  COUNT_UNIT: '명',
  ALL_TARGETS_TAG: '회원전체',
  GRADE_POPUP_TITLE: '적립금 회원대상',
  GROUP_POPUP_TITLE: '적립금 회원대상',
  MEMBER_POPUP_TITLE: '회원정보',

  DISPLAY_GRADE_TITLE: '적립금 회원대상',
  DISPLAY_GROUP_TITLE: '적립금 회원대상',
});
