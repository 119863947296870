











































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { Grid as TuiGrid } from '@toast-ui/vue-grid';
import TextInput from '@/components/common/input/TextInput.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import getDesignPopupDisplayUrlManageGridProps from '@/components/popup/display/DesignPopupDisplayUrlManage';
import {
  DeleteDesignPopupsDisplayUrlsRequest,
  GetDesignPopupsDisplayUrl,
  GetDesignPopupsDisplayUrlsRequest,
  PostDesignPopupsDisplayUrlsRequest,
} from 'ncp-api-supporter';
import { CheckGridEventProps } from '@/types/grid';
import { PopupClose, throwMessagePopup } from '@/helpers/popup';
import { DisplayUrlType } from '@/types/designPopup';

@Component({
  components: {
    TextInput,
    ToolTip,
    Grid,
    TuiGrid,
  },
})
export default class DesignPopupDisplayUrlManage extends Vue {
  @Prop() private readonly data;
  @Prop() private readonly onNegativeClick!: Function;
  @Ref('itemTable') grid: Grid;

  private displayUrlsContent = [];
  private totalCount = 0;

  private gridProps = getDesignPopupDisplayUrlManageGridProps(this.onGridCellEvent);
  private pageNo = 0;
  private pcDesignPlatform = true;
  private mobileDesignPlatform = true;
  private pageName = '';
  private displayUrl = '';

  created() {
    this.searchDesignPopupsDisplayUrls(1, this.gridProps.options.pageOptions.perPage);
  }

  @Watch('gridProps.options.pageOptions.page', { immediate: false })
  watchPageChanged() {
    this.searchDesignPopupsDisplayUrls(
      this.gridProps.options.pageOptions.page,
      this.gridProps.options.pageOptions.perPage,
    );
  }

  @Watch('gridProps.options.pageOptions.perPage', { immediate: false })
  watchPerPageChanged() {
    this.searchDesignPopupsDisplayUrls(1, this.gridProps.options.pageOptions.perPage);
  }

  private initDisplayUrlAdd(): void {
    if (!confirm(this.$t('DISPLAY.DESIGN_POPUP.CONFIRM_INIT_DISPLAYPAGE').toString())) return;

    this.pageNo = 0;
    this.pcDesignPlatform = true;
    this.mobileDesignPlatform = true;
    this.pageName = '';
    this.displayUrl = '';
  }

  private displayUrlSave(): void {
    if (!this.pcDesignPlatform && !this.mobileDesignPlatform) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.PLATFORM_EMPTY'));
      return;
    }
    if (!this.pageName) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.PAGENAME_EMPTY'));
      return;
    }
    if (!this.displayUrl) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.DISPLAYURL_EMPTY'));
      return;
    }

    const request: PostDesignPopupsDisplayUrlsRequest = {
      data: {
        displayUrl: this.displayUrl,
        pageNo: this.pageNo ? this.pageNo : 0,
        mallNo: this.data.mallNo,
        pageName: this.pageName,
        platformTypes: [],
      },
    };

    if (this.pcDesignPlatform) {
      request.data.platformTypes.push('PC');
    }
    if (this.mobileDesignPlatform) {
      request.data.platformTypes.push('MOBILE_WEB');
    }

    this.$api.postDesignPopupsDisplayUrls(request).then(() => {
      this.displayUrl = '';
      this.pageNo = 0;
      this.pageName = '';
      this.pcDesignPlatform = true;
      this.mobileDesignPlatform = true;
      alert(this.$t('DISPLAY.DESIGN_POPUP.SAVE_DISPLAYURL_SUCCESS'));
      this.searchDesignPopupsDisplayUrls(1, this.gridProps.options.pageOptions.perPage);
    });
  }

  private deleteDisplayPages(): void {
    if (!this.selected || this.selected.length < 1) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.DELETE_SELECT_EMPTY'));
      return;
    }

    throwMessagePopup(
      this.$t('DISPLAY.DESIGN_POPUP.DELETE_DISPLAYURL_CONFIRM'),
      true,
      this.$t('DISPLAY.DESIGN_POPUP.DELETE_DISPLAYURL_CONFIRM_TITLE'),
      false,
      this.$t('CONFIRM'),
      this.$t('CANCEL'),
    ).then(result => {
      if (result.state === PopupClose.CONFIRM) {
        const deleteDesignPopupsDisplayUrlsRequest: DeleteDesignPopupsDisplayUrlsRequest = {
          params: {
            pageNos: this.selected.join(','),
          },
        };
        this.$api.deleteDesignPopupsDisplayUrls(deleteDesignPopupsDisplayUrlsRequest).then(() => {
          alert(this.$t('DISPLAY.DESIGN_POPUP.DELETE_DISPLAYURL_SUCCESS'));
          this.searchDesignPopupsDisplayUrls(1, this.gridProps.options.pageOptions.perPage);
          this.selected = [];
        });
      } else if (result.state === PopupClose.CLOSE) {
        return;
      }
    });
  }

  private onGridCellEvent(key: string, event: Event, props: DisplayUrlType): void {
    switch (key) {
      case 'modifyBtnCell': {
        this.pageNo = props.pageNo;
        this.pageName = props.pageName;
        this.displayUrl = props.displayUrl;
        this.pcDesignPlatform = props.pcDesignPlatform;
        this.mobileDesignPlatform = props.mobileDesignPlatform;
        break;
      }
    }
  }

  private selected = [];
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
  }

  private async searchDesignPopupsDisplayUrls(page: number, pageSize: number): Promise<void> {
    const request: GetDesignPopupsDisplayUrlsRequest = {
      params: {
        mallNo: this.data.mallNo,
        page,
        size: pageSize,
      },
    };
    try {
      const response = await this.$api.getDesignPopupsDisplayUrls(request);
      this.totalCount = response.data.totalCount;
      this.gridProps.options.pageOptions.totalCount = this.totalCount;
      this.displayUrlsContent = [];
      response.data.contents.forEach((el: GetDesignPopupsDisplayUrl) => {
        let showTime = '';
        if (el.registerDateTime) {
          showTime = el.registerDateTime;
        }
        if (el.updateDateTime) {
          showTime += showTime !== '' ? ' ' + el.updateDateTime : el.updateDateTime;
        }

        this.displayUrlsContent.push({
          displayUrl: el.displayUrl,
          pageNo: el.pageNo,
          registerAndUpdateDateTime: showTime,
          pageName: el.pageName,
          platformTypes: el.platformTypes,
          nameAndUrl: `${el.pageName} ${el.displayUrl}`,
          registerDateTime: el.registerDateTime,
          updateDateTime: el.updateDateTime,
        });
      });
    } catch (e) {
      console.error(e);
    }
  }
}
