import { OptionData } from '@/helpers/type';
import { DefaultGridContainerOption, GridProp, OptColumn } from '@/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { GetProductsConfigurableOptionsRequest } from 'ncp-api-supporter';

export const SearchKeywordType = {
  mallProductNo: 'mallProductNo',
  productName: 'productName',
  productManagementCd: 'productManagementCd',
  optionManagementCd: 'optionManagementCd',
  mallOptionNo: 'mallOptionNo',
} as const;

export const keywordTypesOptions: OptionData<keyof typeof SearchKeywordType>[] = [
  {
    name: 'STOCK.MANAGEMENT.PRODUCT_NAME',
    value: SearchKeywordType.productName,
  },
  {
    name: 'STOCK.MANAGEMENT.MALL_PRODUCT_NO',
    value: SearchKeywordType.mallProductNo,
  },
  {
    name: 'STOCK.MANAGEMENT.PRODUCT_MANAGEMENT_CD',
    value: SearchKeywordType.productManagementCd,
  },
  {
    name: 'STOCK.MANAGEMENT.MALL_OPTION_NO',
    value: SearchKeywordType.mallOptionNo,
  },
  {
    name: 'STOCK.MANAGEMENT.OPTION_MANAGEMENT_CD',
    value: SearchKeywordType.optionManagementCd,
  },
];

const deleteButtonColumn: OptColumn = {
  header: 'DELETE',
  name: 'delete',
  align: 'center',
  formatter: () => getAnchorHTML(i18n.t('DELETE')),
};

const getProductColumns = (): OptColumn[] => {
  return [
    {
      header: 'PRODUCT.COMMON.PRODUCT_NO',
      name: 'mallProductNo',
      align: 'center',
      minWidth: 135,
    },
    {
      header: 'PRODUCT.COMMON.PRODUCT_NAME',
      name: 'productName',
      minWidth: 134,
      align: 'center',
    },
  ];
};

const configurationOptionColumns = (): OptColumn[] => {
  return [
    {
      header: 'PRODUCT.OPTION.NAME_PRICE',
      name: 'combinedOptionNameValue',
      minWidth: 268,
      align: 'center',
    },
    {
      header: 'PRODUCT.OPTION.SALE_PRICE',
      name: 'optionSalePrice',
      minWidth: 134,
      align: 'center',
    },
    {
      header: 'PRODUCT.OPTION.STOCK',
      name: 'stockCnt',
      minWidth: 80,
      align: 'center',
      formatter: ({ value }) => value || window.$t(`PRODUCT.OPTION.SOLD_OUT`),
    },
    //TODO 현재 api 사용여부 없다 [getProductsConfigurableOptions]
    {
      header: 'PRODUCT.OPTION.WHETHER_TO_USE',
      name: 'useYn',
      minWidth: 107,
      align: 'center',
    },
    {
      header: 'PRODUCT.OPTION.OPTION_NO',
      name: 'mallOptionNo',
      minWidth: 134,
      align: 'center',
    },
    {
      header: 'PRODUCT.OPTION.SALE_STATUS',
      name: 'saleStatusType',
      minWidth: 134,
      align: 'center',
      formatter: ({ value }) => window.$t(`PRODUCT.OPTION.${value}`),
    },
  ];
};

export const getConfigurationOptionGridProps = (): GridProp => {
  return {
    columns: [...getProductColumns(), ...configurationOptionColumns()],
    header: {
      height: 40,
    },
    options: {
      rowHeaders: ['checkbox'],
      pageOptions: {
        page: 1,
        perPage: 30,
        totalCount: 0,
      },
      columnOptions: {
        resizable: true,
      },
      heightResizable: true,
      bodyHeight: 220,
    },
  };
};

export const selectedConfigurationOptionGridProps = (): GridProp => {
  return {
    columns: [...getProductColumns(), deleteButtonColumn, ...configurationOptionColumns()],
    header: {
      height: 40,
    },
    options: {
      columnOptions: {
        resizable: true,
      },
      heightResizable: true,
      bodyHeight: 220,
    },
  };
};

export const configurationContainerOptions: DefaultGridContainerOption = {
  pageSizeKey: 'size',
  hasSettingButton: false,
  hasExcelDownloadButton: false,
};

export const selectedConfigurationContainerOptions: DefaultGridContainerOption = {
  pageSizeKey: 'size',
  showsRightArea: false,
};

export const resetProductConfigurableOptions = (): GetProductsConfigurableOptionsRequest['params'] => ({
  page: 1,
  size: 30,
  mallProductNo: '',
  mallNo: '',
  deliveryTemplateNo: '',
  productName: '',
  productManagementCd: '',
  mallOptionNo: '',
  optionManagementCd: '',
});
