



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { SelectData, PickedData, SelectKeys } from '@/components/common/categories/MultiCategoriesSelector.vue';
import { RemoveEmitData } from '@/components/common/categories/parts/PickedList.vue';

/**
 * @MAX_DEPTH
 *
 * 박스를 미리 MAX_DEPTH 의 값 만큼 표시하고자 사용한다.
 * n차원 배열에서 최대 깊이를 계산하는것 자체가 자원 낭비라서 계산하지 않는다.
 * api 에서 이 값을 사전에 계산해주더라도, 각 카테고리별로 최대 깊이가 정해져있더라도 굳이 이 값을 옵션으로 두어 외부 노출할 필요없다고 생각한다.
 * 만일 4개만 보이게 하고 싶거나하면 이 컴포넌트 외부에서 stylesheet 로 처리 하는것이 가능하니깐.
 */
const MAX_DEPTH = 5;

@Component
export default class SelectFinder extends Vue {
  @Prop({ required: true })
  private readonly categories: SelectData[];
  @Prop({ required: true })
  private readonly keys!: SelectKeys;
  @Prop({ required: false })
  private readonly disabled: boolean;

  private depthData: (SelectData[] | null)[] = [];
  private picks: PickedData[] = [];
  private selectLastDepth = false;

  created() {
    this.initDepthData(MAX_DEPTH);
  }

  private initDepthData(maxDepth: number): void {
    const temp = Array(maxDepth).fill(null);
    temp[0] = this.categories;
    this.depthData = temp;
  }

  private setDepthData(depth: number, category: SelectData[]): void {
    this.depthData.splice(depth + 1, 1, category);
  }

  private removeDepthData(depth: number): void {
    // depth + 1 이후의 배열을 null 로 변경
    const deleteCount = MAX_DEPTH - depth - 1;
    const emptyArrays = Array(deleteCount).fill(null);

    this.depthData.splice(
      depth + 1, // 시작 index.
      deleteCount, // null 로 만들 갯수
      ...emptyArrays, // nulls
    );
  }

  private select(depth: number, category: SelectData): void {
    const { uniqueKey, nameKey, childKey } = this.keys;

    const id = category[uniqueKey];
    const name = category[nameKey];

    if (this.picks[depth]) this.picks = this.picks.slice(0, depth);
    this.picks.push({ id, name });

    if (depth !== MAX_DEPTH) this.removeDepthData(depth);

    if (category[childKey].length > 0) {
      this.setDepthData(depth, category[childKey]);
      this.selectLastDepth = false;
    } else {
      this.$emit('pick', this.generateFullName(category));
      this.selectLastDepth = true;
    }
  }

  private generateFullName(category: SelectData): SelectData {
    const { fullNameKey } = this.keys;
    let fullName = this.picks.map(pick => pick.name).join('>');
    if ('forAdults' in category) {
      const { forAdults } = category;
      if (forAdults) fullName = fullName + this.$t('PRODUCT.ADD.ADULT');
    }
    return {
      ...category,
      [fullNameKey]: fullName,
    };
  }

  public reset({ pickedDataEmpty }: RemoveEmitData): void {
    if (pickedDataEmpty) this.initDepthData(MAX_DEPTH);
  }
}
