












































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { ComponentItemsClaimDataType } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import { GetClaimsOrderOptionsOrderOptionNoApply, Bank, PostOptionCancelsPredict } from 'ncp-api-supporter';
@Component({
  components: { TextInput, SelectBox, RadioGroup },
})
export default class RefundExchangeAmount extends Vue {
  @Prop({ required: true }) private claimType!: string;
  @Prop({ default: '' }) private type!: string;
  @Prop({ required: true }) private mall;
  @Prop({ required: false }) private postPredict!: {};
  @Prop({ default: false }) private escrowOrder!: boolean;
  @Prop({ default: false }) private reSetData!: boolean;
  @Prop({ required: true }) private objJson!: string;
  @Prop({ required: true }) private orderOptionsApply!: ComponentItemsClaimDataType;
  @Prop({ default: false }) private modifyClaim!: boolean;
  @Prop({ default: false }) private accumulationUsed!: boolean;
  @Prop({ required: false }) private claimOrderOptions!: GetClaimsOrderOptionsOrderOptionNoApply;
  @Prop({ required: true }) private refundTypeDivShow: boolean;
  @Prop({ required: true }) public zeroRefundShow: boolean;
  @Prop({ required: true }) private refundBankInfoTrShow: boolean;
  @Prop({ required: false }) private orderStatusDepositWait: boolean;
  @Watch('postPredict')
  onPredictChange() {
    this.claimCalculation();
    if (
      this.claimType === 'ExchangePopupBefore' ||
      this.claimType === 'ExchangePopupAfter' ||
      this.claimType === 'Return'
    ) {
      this.createExchangeInfo(false);
    } else {
      this.createRefundInfo();
    }
  }
  private title = '';
  private subTitle = '';
  public claimTotal = 0;
  private accumulationPayAmt = 0;
  private mainPayAmt = 0;
  public exchangePayType = 'CASH';
  private exchangePayTypeDisabled = false;
  public tradeBankAccountInfo = '';
  public remitter = '';
  private refundTypes: { value: string; name: string }[] = [];
  private get refundTypeList() {
    return this.refundTypes;
  }
  private payTypeMap: Map<string, string> = new Map();
  private refundTypeMap: Map<string, string> = new Map();
  public refundBankValue = '';
  public refundBankAccount = '';
  public refundBankDepositorName = '';
  private refundInfoClaimRefundBank = '-';
  private totalExchangeAmt = 0;
  public refundBankList: Bank[] = [];
  public refundTypeValue = 'ACCOUNT';
  created() {
    this.setTitleName();
  }
  private setTitleName() {
    switch (this.claimType) {
      case 'CancelAll':
      case 'Cancel':
        this.title = this.$t('CLAIM.CANCELPOPUP.REFUND_AMOUNT').toString();
        this.subTitle = this.$t('CLAIM.CANCELPOPUP.REFUND_A_PLUS_B').toString();
        break;
      case 'ExchangePopupBefore':
        this.title = this.$t('CLAIM.EXCHANGEPOPUP.EXCHANGEPROCESSING').toString();
        this.subTitle = this.$t('CLAIM.EXCHANGEPOPUP.EXCHANGEPROCESSINGAMOUNT').toString();
        break;
      case 'ExchangePopupAfter':
        this.title = this.$t('CLAIM.EXCHANGEPOPUP.EXCHANGEPROCESSING').toString();
        this.subTitle = this.$t('CLAIM.EXCHANGEPOPUP.EXCHANGEPROCESSINGAMOUNT2').toString();
        break;
      case 'Return':
        this.title = this.$t('CLAIM.RETURNPOPUP.REFUND_EXPECTATION_CLAIM_AMT').toString();
        this.subTitle = this.$t('CLAIM.RETURNPOPUP.REFUND_AMOUNT_EXPECTATION_A_MINUS_B_PLUS_C').toString();
        break;
    }
  }
  private initPayTypeMap() {
    this.refundTypeMap.set('CANCEL_DEPOSIT', this.$t('CLAIM.CANCELPOPUP.CANCEL_DEPOSIT').toString());
    this.refundTypeMap.set('PAYCO', this.$t('CLAIM.CANCELPOPUP.PAYCO').toString());
    this.refundTypeMap.set('CASH', this.$t('CLAIM.CANCELPOPUP.CASH').toString());
    this.refundTypeMap.set('PAYPAL', this.$t('CLAIM.CANCELPOPUP.PAYPAL').toString());
    this.refundTypeMap.set('STRIPE', this.$t('CLAIM.CANCELPOPUP.STRIPE').toString());
    this.refundTypeMap.set('ZERO_REFUND', this.$t('CLAIM.CANCELPOPUP.ZERO').toString());
    this.refundTypeMap.set('KCP', this.$t('CLAIM.CANCELPOPUP.KCP_CREDIT').toString());
    this.refundTypeMap.set('CREDIT_CARD', this.$t('CLAIM.CANCELPOPUP.CREDIT_CARD').toString());
    this.refundTypeMap.set('VIRTUAL_ACCOUNT', this.$t('CLAIM.CANCELPOPUP.VIRTUAL_ACCOUNT').toString());
    this.refundTypeMap.set(
      'REALTIME_ACCOUNT_TRANSFER',
      this.$t('CLAIM.CANCELPOPUP.REALTIME_ACCOUNT_TRANSFER').toString(),
    );
    this.refundTypeMap.set('ESCROW_VIRTUAL_ACCOUNT', this.$t('CLAIM.CANCELPOPUP.ESCROW_VIRTUAL_ACCOUNT').toString());
    this.refundTypeMap.set(
      'ESCROW_REALTIME_ACCOUNT_TRANSFER',
      this.$t('CLAIM.CANCELPOPUP.ESCROW_REALTIME_ACCOUNT_TRANSFER').toString(),
    );
    this.refundTypeMap.set('LIIVMATE', this.$t('CLAIM.CANCELPOPUP.LIIVMATE').toString());
    this.refundTypeMap.set('ZERO_PAY', this.$t('CLAIM.CANCELPOPUP.ZERO_PAY').toString());
    this.refundTypeMap.set('MOBILE', this.$t('CLAIM.CANCELPOPUP.MOBILE').toString());
    this.refundTypeMap.set('SAMSUNG_PAY', this.$t('CLAIM.CANCELPOPUP.SAMSUNGPAY').toString());
    this.refundTypeMap.set('ACCUMULATION', this.$t('CLAIM.CANCELPOPUP.ACCUMULATION').toString());
    this.refundTypeMap.set('INICIS', this.$t('CLAIM.CANCELPOPUP.INICIS').toString());
    this.refundTypeMap.set('NAVER_EASY_PAY', this.$t('CLAIM.CANCELPOPUP.NAVEREASYPAY').toString());
    this.refundTypeMap.set('KAKAO_PAY', this.$t('CLAIM.CANCELPOPUP.KAKAOPAY').toString());
    this.refundTypeMap.set('NAVER_PAY', this.$t('CLAIM.CANCELPOPUP.NAVERPAY').toString());
    this.refundTypeMap.set('LG_U_PLUS', this.$t('CLAIM.CANCELPOPUP.LGUPLUS').toString());
    this.refundTypeMap.set('ACCOUNT', this.$t('CLAIM.CANCELPOPUP.ACCOUNT').toString());
    this.refundTypeMap.set('DUMMY', this.$t('CLAIM.CANCELPOPUP.DUMMY').toString());
    this.payTypeMap.set('CREDIT_CARD', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_CREDIT_CARD').toString());
    this.payTypeMap.set('PAYCO', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_PAYCO').toString());
    this.payTypeMap.set(
      'VIRTUAL_ACCOUNT',
      this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_VIRTUAL_ACCOUNT').toString(),
    );
    this.payTypeMap.set(
      'REALTIME_ACCOUNT_TRANSFER',
      this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_REALTIME_ACCOUNT_TRANSFER').toString(),
    );
    this.payTypeMap.set(
      'ESCROW_VIRTUAL_ACCOUNT',
      this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_ESCROW_VIRTUAL_ACCOUNT').toString(),
    );
    this.payTypeMap.set(
      'ESCROW_REALTIME_ACCOUNT_TRANSFER',
      this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_ESCROW_REALTIME_ACCOUNT_TRANSFER').toString(),
    );
    this.payTypeMap.set('MOBILE', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_MOBILE').toString());
    this.payTypeMap.set('POINT', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_POINT').toString());
    this.payTypeMap.set('ACCUMULATION', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_ACCUMULATION').toString());
    this.payTypeMap.set('ZERO_PAY', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_ZERO_PAY').toString());
    this.payTypeMap.set('NAVER_PAY', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_NAVER_PAY').toString());
    this.payTypeMap.set('YPAY', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_YPAY').toString());
    this.payTypeMap.set('KPAY', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_KPAY').toString());
    this.payTypeMap.set('PAYPIN', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_PAYPIN').toString());
    this.payTypeMap.set('INIPAY', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_INIPAY').toString());
    this.payTypeMap.set('ACCOUNT', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_ACCOUNT').toString());
    this.payTypeMap.set('PHONE_BILL', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_PHONE_BILL').toString());
    this.payTypeMap.set('GIFT', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_GIFT').toString());
    this.payTypeMap.set('ATM', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_ATM').toString());
    this.payTypeMap.set('TOASTCAM', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_TOASTCAM').toString());
    this.payTypeMap.set('ETC', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_ETC').toString());
    this.payTypeMap.set(
      'VERITRANS_CARD',
      this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_VERITRANS_CARD').toString(),
    );
    this.payTypeMap.set('PAYPAL', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_PAYPAL').toString());
    this.payTypeMap.set('STRIPE', this.$t('ORDER.ORDER_CONTENT_TOP.INIT_PAY_TYPES_ALL_STRIPE').toString());
  }
  mounted() {
    this.initPayTypeMap();
    this.claimCalculation();
    if (
      this.claimType === 'ExchangePopupBefore' ||
      this.claimType === 'ExchangePopupAfter' ||
      this.claimType === 'Return'
    ) {
      this.createExchangeInfo();
    }
    if (this.claimType === 'Cancel' || this.claimType === 'CancelAll') {
      this.createRefundInfo(); // 환불
    }
    this.$emit('setData', 'postPredict');
  }
  private createExchangeInfo(init = true) {
    this.totalExchangeAmt = (this.postPredict as PostOptionCancelsPredict).amounts.refund.order.totalExchangeAmt;
    this.exchangePayType =
      this.claimOrderOptions.availableAccumulationAmt >
        (this.postPredict as PostOptionCancelsPredict).amounts.adjustedAmounts.claimAmt &&
      this.accumulationUsed === true
        ? 'ACCUMULATION'
        : 'CASH';
    this.exchangePayTypeDisabled = !(
      this.claimOrderOptions.availableAccumulationAmt >
        (this.postPredict as PostOptionCancelsPredict).amounts.adjustedAmounts.claimAmt &&
      this.accumulationUsed === true
    );
    this.createRefundInfo(init);
  }
  private createRefundInfo(init = true) {
    this.$api.getBanks({ params: { countryCode: 'KR' } }).then(ret => {
      this.refundBankList = ret.data.filter(bank => bank.value !== 'ANONYMOUS');
      this.refundBankValue = this.refundBankList[0].value;
      this.refundBankAccount = '';
      this.refundBankDepositorName = '';
      const list: { value: string; name: string }[] = [];
      if (this.orderOptionsApply.refundTypes) {
        this.orderOptionsApply.refundTypes.forEach((refundTypes, index: number) => {
          list[index] = {
            value: refundTypes.refundType,
            name:
              refundTypes.label +
              (this.orderOptionsApply.payType != null &&
              true &&
              refundTypes.refundType !== 'ACCOUNT' &&
              refundTypes.refundType !== 'ACCUMULATION'
                ? '[' + this.refundTypeMap.get(this.orderOptionsApply.payType) + ']'
                : ''),
          };
        });
      }
      this.refundTypes = list;
      if (list.length > 0 && init) {
        this.refundTypeValue = (this.$parent as any).getPayType(this.orderOptionsApply.payType);
      }
      if (this.claimTotal === this.accumulationPayAmt && this.accumulationPayAmt !== 0) {
        this.refundTypes = [
          {
            name: '적립금환불',
            value: 'ACCUMULATION',
          },
        ];
        this.refundTypeValue = 'ACCUMULATION';
      }
      if ((this.claimType === 'Cancel' || this.claimType === 'Return') && this.claimTotal < 0) {
        this.refundTypeValue = 'ZERO_REFUND';
      }
      if (this.claimType === 'CancelAll' && this.type === 'before') {
        this.refundTypeValue = 'ZERO_REFUND';
      }
      if (
        this.orderOptionsApply.claimableOptions.some(item => {
          return item.orderStatusType === 'DEPOSIT_WAIT';
        })
      ) {
        this.refundTypeValue = 'ZERO_REFUND';
      }
      this.displayBankInfo('mounted');
    });
  }
  public refundBankEditShow = false;
  private refundBankShow = false;
  private displayBankInfo(method: string) {
    if (this.claimOrderOptions && this.claimOrderOptions.additionalPayBankAccounts) {
      this.tradeBankAccountInfo =
        this.claimOrderOptions.additionalPayBankAccounts[0]?.bank.bank +
        '|' +
        this.claimOrderOptions.additionalPayBankAccounts[0].bankAccount +
        '|' +
        this.claimOrderOptions.additionalPayBankAccounts[0].bankDepositorName;
    }
    let isVisibleBankEditor = false;
    switch (this.refundTypeValue) {
      case 'CASH':
      case 'ACCOUNT':
        isVisibleBankEditor = true;
        break;
      case 'PG':
        if (
          this.orderOptionsApply.payType === 'VIRTUAL_ACCOUNT' ||
          this.orderOptionsApply.payType === 'ESCROW_VIRTUAL_ACCOUNT'
        ) {
          isVisibleBankEditor = true;
        }
        break;
    }
    if (isVisibleBankEditor) {
      this.refundBankShow = false;
      this.refundBankEditShow = true;
    } else {
      this.refundBankAccount = '';
      this.refundBankDepositorName = '';
      this.refundBankValue = this.refundBankList[0].value;
      this.refundBankShow = true;
      this.refundBankEditShow = false;
    }
    if (this.escrowOrder) {
      if (this.refundTypeValue === 'ACCOUNT') {
        this.claimOrderOptions.claimableOptions.forEach((item, index: number) => {
          (this.$parent.$refs.componentItemsClaim as any).claimReasonTypeDisabled[index] = false;
        });
        (this.$parent.$refs.componentItemsClaim as any).responsibleObjectTypeDisabled = false;
        (this.$parent.$refs.componentProductDetails as any).chargeDeliveryAmtDisabled = false;
      } else if (this.refundTypeValue === 'PG' && method === 'refundType') {
        if (confirm(this.$t('CLAIM.RETURNPOPUP.CONFIRM2').toString()) === false) {
          this.$nextTick(() => {
            this.refundTypeValue = 'ACCOUNT';
            this.refundBankShow = false;
            this.refundBankEditShow = true;
          });
        } else {
          this.$nextTick(() => {
            this.claimOrderOptions.claimableOptions.forEach((item, index: number) => {
              (this.$parent.$refs.componentItemsClaim as any).claimReasonType[index] = 'OTHERS_SELLER';
              (this.$parent.$refs.componentItemsClaim as any).claimReasonDetail[index] = '';
              (this.$parent.$refs.componentItemsClaim as any).claimReasonTypeDisabled[index] = true;
              (this.$parent.$refs.componentItemsClaim as any).responsibleObjectTypeDisabled = true;
            });
            (this.$parent.$refs.componentItemsClaim as any).responsibleObjectTypeValue = 'SELLER';
            (this.$parent.$refs.componentProductDetails as any).chargeDeliveryAmt = 'true';
            (this.$parent.$refs.componentProductDetails as any).chargeDeliveryAmtDivFlg = 'true';
            (this.$parent.$refs.componentProductDetails as any).chargeDeliveryAmtDisabled = true;
            if (this.claimType === 'Return') {
              (this.$parent.$refs.componentReturnShippingFee as any).textDecoration = 'line-through';
              (this.$parent.$refs.componentReturnShippingFee as any).returnDeliveryAmtAdjustAmtDisabled = true;
              (this.$parent.$refs.componentReturnShippingFee as any).returnDeliveryAmtAdjustAmt = 0;
              (this.$parent.$refs.componentReturnShippingFee as any).returnDeliveryAmtAdjustReason = '';
              (this.$parent.$refs.componentReturnShippingFee as any).returnDeliveryAmtAdjustReasonDisabled = true;
            }
            this.$emit('setData');
          });
        }
      }
    }
  }
  private claimCalculation() {
    if (this.claimType === 'Cancel' || this.claimType === 'CancelAll' || this.claimType === 'Return') {
      this.claimTotal = (this.postPredict as PostOptionCancelsPredict).amounts.adjustedAmounts.claimAmt * -1;
    } else if (this.claimType === 'ExchangePopupBefore' || this.claimType === 'ExchangePopupAfter') {
      this.claimTotal = (this.postPredict as PostOptionCancelsPredict).amounts.adjustedAmounts.claimAmt;
    }
    this.accumulationPayAmt = (this.postPredict as PostOptionCancelsPredict).amounts.adjustedAmounts.accumulationPayAmt;
    this.mainPayAmt = (this.postPredict as PostOptionCancelsPredict).amounts.adjustedAmounts.mainPayAmt;
  }
}
