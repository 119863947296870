





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class OrderSettleInfo extends Vue {
  @Prop({ default: () => [] }) public settleInfo;
}
