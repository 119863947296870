



















import { Vue, Component, PropSync, Ref } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({
  components: {
    TextInput,
  },
})
export default class StockRangeInput extends Vue {
  @PropSync('minStockAmount', { required: true }) private minStockAmountSync!: number | '';
  @PropSync('maxStockAmount', { required: true }) private maxStockAmountSync!: number | '';

  @Ref() private readonly maxStockAmountInput: TextInput;

  public validation(): boolean {
    const min = this.minStockAmountSync;
    const max = this.maxStockAmountSync;

    if (max === null || max === '' || max >= min) return true;

    alert(this.$t('PRODUCT.SEARCH.ALERT_STOCK_ERROR'));
    this.maxStockAmountInput.focus();
    return false;
  }
}
