










































































import { Component, Watch } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  getAuthorityGroupAdminListSelectType,
  getAuthorityGroupAdminListGridOption,
} from '@/const/contents/configuration/authorityGroup';
import { sendQueryString } from '@/utils/query';
import { GetAuthorityGroupsAdminsByGroupNoRequest } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import { GridEventProps } from '@/types';
import { setNoDataMessage } from '@/helpers/grid';

@Component({
  components: { SelectBox, Grid },
})
export default class AuthorityGroupMemberListPopup extends WindowPopupMainVue {
  private selectType = getAuthorityGroupAdminListSelectType();
  private gridProps = getAuthorityGroupAdminListGridOption();

  private authorityGroupNo = '';
  private searchType = this.selectType.searchType[0].value;
  private keyword = '';

  private contents = [];
  private totalCount = 0;

  @Watch('$route')
  private onQueryStringChanged() {
    this.getAuthorityGroupsAdminsByGroupNo();
  }

  created() {
    setNoDataMessage(this.$t('NO_RESULT').toString());

    if (this.data && this.data.authorityGroupNo) {
      this.authorityGroupNo = this.data.authorityGroupNo;
    }

    if (this.$route && this.$route.query) {
      if (this.$route.query.authorityGroupNo) {
        this.authorityGroupNo = this.$route.query.authorityGroupNo as string;
      }
      if (this.$route.query.searchType) {
        this.searchType = this.$route.query.searchType as string;
      }
      if (this.$route.query.keyword) {
        this.keyword = this.$route.query.keyword as string;
      }
    }

    this.getAuthorityGroupsAdminsByGroupNo();
  }

  private getAuthorityGroupsAdminsByGroupNo(): void {
    const page = Number(this.$route.query.page);

    const request: GetAuthorityGroupsAdminsByGroupNoRequest = {
      pathParams: {
        authorityGroupNo: this.authorityGroupNo,
      },
      params: {
        ...this.$route.query,
        searchType: this.searchType,
        keyword: this.keyword,
        page: page || 1,
        pageSize: 30,
      },
    };
    this.$api.getAuthorityGroupsAdminsByGroupNo(request).then(response => {
      if (response && response.status === 200) {
        this.contents = response.data.contents;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  private sendQueryUrl(): void {
    const params = { ...this.$route.query, searchType: this.searchType, keyword: this.keyword };
    sendQueryString(this, params);
  }

  private onItemClicked(gridProps: GridEventProps): void {
    switch (gridProps.columnName) {
      case 'adminId': {
        const event = (gridProps as any).nativeEvent;
        const target = event.target as HTMLElement;
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        window.open(`/pro/configuration/management/admin/edit?adminNo=${gridProps.rowKey}`, '_blank');
        break;
      }
    }
  }
}
