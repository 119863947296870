import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import country from '@/const/words/country';
import { PARTNER_STATUS } from '@/const/partner';
import { i18n } from '@/main';

export const i18nForPartnerList = (word: string) => i18n.t(`PARTNER.PARTNER_LIST.${word}`).toString();

export const getGridProps = () =>
  ({
    header: {
      height: 32,
    },
    columns: [
      {
        header: i18nForPartnerList('PARTNER_NO'),
        name: 'partnerNo',
        align: 'center',
        minWidth: 180,
      },
      {
        header: i18nForPartnerList('PARTNER_NAME'),
        name: 'partnerName',
        align: 'center',
        minWidth: 180,
        formatter: ({ value }) => getAnchorHTML(value?.toString()),
      },
      {
        header: i18nForPartnerList('COUNTRY'),
        name: 'countryCode',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => country.find(({ value: countryCode }) => countryCode === value)?.name ?? '-',
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: i18nForPartnerList('COMPANY_NAME'),
        name: 'companyName',
        align: 'center',
        minWidth: 120,
      },
      {
        header: i18nForPartnerList('PARTNER_SHOPBY_STATUS'),
        name: 'partnerStatus',
        align: 'center',
        minWidth: 110,
        formatter: ({ value }) => {
          const hasStatus = Object.values(PARTNER_STATUS).some(partnerStatus => partnerStatus === value);
          return `${i18nForPartnerList(hasStatus ? `${value}` : 'NOT_REGISTERED_PARTNER')}`;
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: i18nForPartnerList('REGISTRATION_NO'),
        name: 'registrationNo',
        align: 'center',
        minWidth: 120,
        formatter: ({ row }) => `${row.business.registrationNo}`,
      },
      {
        header: i18nForPartnerList('OMD_NO'),
        name: 'onlineMarketingDeclarationNo',
        align: 'center',
        minWidth: 180,
        formatter: ({ row }) =>
          row.business.onlineMarketingDeclarationNo === '' ? '-' : row.business.onlineMarketingDeclarationNo,
      },
      {
        header: i18nForPartnerList('MALL_NAME'),
        name: 'mallName',
        align: 'center',
        minWidth: 100,
      },
      {
        header: i18nForPartnerList('CONTRACT_STATUS'),
        name: 'contractStatus',
        align: 'center',
        minWidth: 100,
        formatter({ value }) {
          switch (value) {
            case 'ACTIVE':
              return i18nForPartnerList('CONTRACT_STATUS_ACTIVE');
            case 'INVESTIGATION':
              return i18nForPartnerList('CONTRACT_STATUS_INVESTIGATION');
            case 'WAITING':
              return i18nForPartnerList('CONTRACT_STATUS_WAITING');
            case 'SUSPEND':
              return window.$t('PARTNER_PARTNER_LIST_CONTRACT_STATUS_SUSPEND');
            default:
              return i18nForPartnerList('NOSTATUS');
          }
        },
        copyOptions: {
          useFormattedValue: true
        },
      },
      {
        header: i18nForPartnerList('PRODUCT_REGISTERED'),
        name: 'productRegistered',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => i18nForPartnerList(value ? 'REGISTERED' : 'NOT_REGISTERED'),
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: i18nForPartnerList('COMMISSION_RATE'),
        name: 'commissionRate',
        align: 'center',
        minWidth: 140,
        formatter: ({ row, value }) => {
          if (row.usesDefaultCommissionRate) {
            return `${value}%`;
          }

          return i18nForPartnerList('NOT_USED');
        },
        copyOptions: {
          useFormattedValue: true
        },
      },
      {
        header: i18nForPartnerList('CREATED_DATE_TIME'),
        name: 'createdDateTime',
        align: 'center',
        minWidth: 140,
        formatter: ({ row }) => row.createdDateTime ?? '-',
      },
      {
        header: i18nForPartnerList('CONTRACTED_DATE_TIME'),
        name: 'contractDateTime',
        align: 'center',
        minWidth: 140,
        formatter: ({ row }) => row.contractDateTime ?? '-',
      },
    ],
    options: {
      rowHeaders: ['rowNum'],
      columnOptions: {
        resizable: true,
      },
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
        visiblePages: 5,
      },
    },
  } as const);
