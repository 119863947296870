







import { Vue, Component } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';

@Component({ components: { NoticeBox } })
export default class OperateSecurity extends Vue {
  private noticeList = this.$t('CONFIGURATION.SECURITY.OPERATION_SECURITY_DIALOGUES')
    .toString()
    .trim()
    .split('\n');

  private readonly location = 'http://www.law.go.kr/lsInfoP.do?lsiSeq=195040&efYd=20170726#0000'; // 법령 안내페이지
}
