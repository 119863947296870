


























































import { Vue, Component, PropSync, Prop, Ref } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import {
  marketingDisplayableOption,
  channelTypeOption,
} from '@/views/contents/product/basic/ProductAdd/marketingRegistration/MarketingRegistration';
import { MarketingSettings } from 'ncp-api-supporter';
import { marketingSettingFocusType } from '@/types';
import { MARKETING_TYPE } from '@/const/contents/product';

@Component({
  components: { RadioGroup, CheckboxGroup },
})
export default class MarketingRegistration extends Vue {
  @PropSync('marketingSettingInfo', { required: true })
  marketingSettingInfoSync: MarketingSettings[];
  @Prop({ required: true })
  private readonly isEditMode: boolean;
  @Prop({ required: true })
  private readonly isCopyMode: boolean;
  @Prop({ required: false })
  private readonly mallNo: string;

  @Ref()
  private channelTypeRef: CheckboxGroup;

  private initMarketingSettingInfo: MarketingSettings[] = [];
  private marketingDisplayableOption = marketingDisplayableOption;
  private channelTypeOption = channelTypeOption;

  private isMarketingDisplayable = false;
  private channelTypes = '';

  private initMarketingsInfo() {
    this.initMarketingSettingInfo = [...this.marketingSettingInfoSync];
  }

  private init() {
    this.initMarketingsInfo(); //초기값
    this.marketingSettingInfoSync = []; //default 노출 안 함
    this.channelTypes = this.marketingSettingInfoSync.map(({ channelType }) => channelType).join();
  }
  private fetchDisplayableOptions(isDisplayable: boolean) {
    return this.initMarketingSettingInfo.map(settingInfos => {
      settingInfos.displayable = isDisplayable;
      return settingInfos;
    });
  }
  private goToMarketingProductsSetting() {
    const routeData = this.$router.resolve({ name: 'NaverShoppingProductConfig', query: { mallNo: this.mallNo } });
    window.open(routeData.href, '_blank');
  }

  private handleDisplayableStatusChange() {
    this.marketingSettingInfoSync = this.isMarketingDisplayable ? this.fetchDisplayableOptions(true) : [];
    this.channelTypes = this.initMarketingSettingInfo.map(({ channelType }) => channelType).join();
  }

  private handleChannelTypeChange() {
    if (!this.marketingSettingInfoSync?.length) return;

    const naverShoppingInfo = this.marketingSettingInfoSync.find(
      ({ channelType }) => channelType === MARKETING_TYPE.NAVER_SHOPPING,
    );
    const googleMarketingInfo = this.marketingSettingInfoSync.find(
      ({ channelType }) => channelType === MARKETING_TYPE.GOOGLE,
    );

    const type = this.channelTypes.split(',');

    if (!this.channelTypes.length) {
      this.marketingSettingInfoSync = this.fetchDisplayableOptions(false);
    } else if (type.length === 1) {
      this.marketingSettingInfoSync = type.includes(MARKETING_TYPE.NAVER_SHOPPING)
        ? [{ ...naverShoppingInfo, displayable: true }]
        : [{ ...googleMarketingInfo, displayable: true }];
    } else {
      this.marketingSettingInfoSync = this.fetchDisplayableOptions(true);
    }
  }

  created() {
    this.init();
  }

  public onFocus(focusTarget: marketingSettingFocusType) {
    this[`${focusTarget}Ref`].focus();
  }
}
