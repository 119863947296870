


















































































import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { Mall } from 'ncp-api-supporter';
import MallSelect from '@/components/common/input/MallSelect.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  REVIEWS_DEFAULT_QUERY,
  SearchTypes,
  ReviewDisplayStatusTypes,
  ReviewTypes,
  Types,
  Ratings,
} from '@/const/contents/review';
import { sendQueryString } from '@/utils/query';
@Component({
  components: {
    MallSelect,
    SelectBox,
    TextInput,
    DateRangePicker,
  },
})
export default class Reviews extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  private query = { ...REVIEWS_DEFAULT_QUERY };
  private searchTypes = SearchTypes();
  private reviewDisplayStatusTypes = ReviewDisplayStatusTypes();
  private reviewTypes = ReviewTypes();
  private types = Types();
  private ratings = Ratings();
  private dateRangeOption = {
    periodTypes: ['td', '1w', '15d', '3m', '1y'],
  };
  search() {
    const routeQuery = this.getRouteQuery();
    sendQueryString(this, routeQuery, true, true);
  }
  getRouteQuery() {
    return {
      mallNo: this.query.mallNo ? this.query.mallNo : undefined,
      startDate: this.query.startDate,
      endDate: this.query.endDate,
      searchType: this.query.searchType === 'WRITER_NAME' ? undefined : this.query.searchType,
      keyword: this.query.searchType === 'WRITER_NAME' ? undefined : this.query.keyword,
      writerName: this.query.searchType === 'WRITER_NAME' ? this.query.keyword : undefined,
      reviewDisplayStatusType:
        this.query.reviewDisplayStatusType === 'ALL' ? undefined : this.query.reviewDisplayStatusType,
      reviewType: this.query.reviewType,
      reviewWrittenType: this.query.reviewWrittenType,
      rating: this.query.rating,
      page: this.query.page,
      size: this.query.pageSize,
    };
  }
  created() {
    this.initQuery();
  }
  initQuery() {
    this.query = { ...REVIEWS_DEFAULT_QUERY };
    this.query.mallNo = this.malls.length ? this.malls[this.malls.length - 1].mallNo : 0;
  }
  mounted() {
    this.search();
  }
}
