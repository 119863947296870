export default Object.freeze({
  // 정리 예정
  COMPLETE_SAVE: '저장되었습니다.',
  CONFIRM_SAVE: '저장하시겠습니까?',
  CONFIRM_DELETE_GROUP: '폴더를 삭제하시겠습니까?',
  CONFIRM_DELETE_TEMPLATE: '템플릿을 삭제하시겠습니까?',
  CONFIRM_DELETE_GROUP_TEMPLATE: '해당 폴더에는 템플릿이 포함되어 있습니다. 삭제하시겠습니까?',
  ENTER_GROUP_NAME: '폴더명을 입력해 주세요.',
  ENTER_TEMPLATE_NAME: '템플릿명을 입력해 주세요.',
  ENTER_TEMPLATE_TITLE: '제목을 입력해 주세요.',
  ENTER_TEMPLATE_CONTENT: '내용을 입력해 주세요.',
  REGISTER_GROUP_BEFORE_ADD_TEMPLATE: '폴더 저장 후 템플릿을 추가해 주세요.',
  SAVE_BEFORE_ADD_GROUP_AND_TEMPLATE: '저장 후 추가해 주세요.',
  SAVE_BEFORE_MOVE_GROUP_AND_TEMPLATE: '저장 후 이동해 주세요.',
  DETAIL_ONE: 'ⓘ e-mail의 템플릿을 설정할 수 있습니다.',
  DETAIL_TWO: 'ⓘ e-mail 템플릿은 e-mail 발송 시, 선택하여 사용이 가능합니다.',
  TITLE: 'e-mail 템플릿 유형',
  ADD_GROUP_BTN: '폴더 추가',
  ADD_TEMP_BTN: '템플릿 추가',
  TREE_EMPTY: '폴더를 생성해 주세요.',
  TEMP_FORM_TWO: '템플릿번호',
  TEMP_FORM_THREE: '템플릿명',
  TEMP_FORM_FIVE: '제목',
  TEMP_FORM_SIX: '내용',
  MODIFY_GROUP_TITLE: 'e-mail 템플릿 폴더 수정',
  ADD_GROUP_TITLE: 'e-mail 템플릿 폴더 추가',
  GROUP_FORM_ONE: '폴더 번호',
  GROUP_FORM_TWO: '폴더명',
  GROUP_EMPTY: '좌측 메뉴에서 폴더를 추가하시면 ,e-mail 템플릿을 추가/삭제 가능합니다.',
});
