import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import AdditionMain from '@/views/contents/addition/basic/AdditionMain.vue';
import AdditionAll from '@/views/contents/addition/basic/AdditionAll.vue';
import AppServiceMain from '@/views/contents/addition/basic/AppServiceMain.vue';

export default (): RouteConfig => {
  return {
    path: '/addition/',
    name: 'addition',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/main',
        name: 'AdditionMain',
        component: AdditionMain,
      },
      {
        path: 'basic/all',
        name: 'AdditionAll',
        component: AdditionAll,
      },
      {
        path: 'app-service/main',
        name: 'AppServiceMain',
        component: AppServiceMain,
      },
    ],
  };
};
