













































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { YorN } from 'ncp-api-supporter';
import { omit } from 'lodash-es';

export interface PromotionInfoOption {
  couponYn: YorN;
  promotionYn: YorN;
  additionalDiscountYn: YorN;
}
@Component({
  components: { ToolTip },
})
export default class PromotionInfo extends Vue {
  @PropSync('promotionInfo')
  private promotionInfoSync!: PromotionInfoOption;

  @Prop({ required: true })
  private readonly disabled: boolean;

  @Watch('promotionInfoSync.additionalDiscountYn')
  @Watch('promotionInfoSync.couponYn')
  // @Watch('promotionInfoSync.freeGiftYn') // TODO: 사은품 리얼배포 때 이거 주석푹면됨
  private watchPromotionInfoSync(): void {
    // TODO: 현재 API에서는 freeGiftYn가 포함되어서 오기때문에 어드민통합 사은품이 라얼배포 가기전까지는 'freeGiftYn'를 제외하고 체크하도록 한다.
    const promotionTypes = Object.values(omit(this.promotionInfoSync, ['promotionYn', 'freeGiftYn']));

    if (promotionTypes.every(type => type === 'N')) {
      this.changedPromotionType('N');
    }

    if (promotionTypes.every(type => type === 'Y')) {
      this.changedPromotionType('Y');
    }
  }

  private changedPromotionType(value: YorN | Event): void {
    let type = value;

    if (value instanceof Event) {
      type = (value.target as HTMLInputElement).value as YorN;
    } else {
      type = value;
    }

    for (const state in this.promotionInfoSync) {
      this.promotionInfoSync[state] = type;
    }
  }
}
