
















import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import { DomainByMall, NCPResponse } from 'ncp-api-supporter';

interface DomainMall {
  value: number | null;
  name: string;
  mallId: string;
}

@Component({
  components: { ModalHeader, SelectBox },
})
export default class MallDomainSelector extends Vue {
  @Prop() private readonly data;

  private selectMall: number | null = null;
  private domainMalls: DomainMall[] = [];

  created() {
    this.domainMalls = [{ value: null, name: this.$t('SELECT_SHOPPING_MALL').toString(), mallId: '' }];

    this.fetch();
  }

  // TODO : 여기서 또 요청하지말고 스토어 getDomainByMalls 쓰세요
  private async fetch() {
    const { data }: NCPResponse<DomainByMall[]> = await this.$api.getDomainByMalls();
    this.setOptions(data);
  }

  private setOptions(malls: DomainByMall[]): void {
    const options = malls.map(({ domains, mallNo, mallId }) => ({
      value: mallNo,
      mallId,
      name: domains.PC.mainDomain,
    }));

    this.domainMalls = this.domainMalls.concat(options);
  }

  get selectMallInfo(): { mallNo: number; mallId: string } | null {
    if (this.selectMall === null) return null;
    const { value, mallId } = this.domainMalls.find(({ value }) => value === this.selectMall);
    return { mallNo: value, mallId };
  }

  private onSubmit() {
    if (this.selectMall === null) return alert(this.$t('CHECK_SELECT_MALL'));
    this.$emit('confirm', this.selectMallInfo);
  }
}
