/* eslint-disable quotes */
export default Object.freeze({
  COMMENT1: `개인정보처리방침은 '정보통신망 이용촉진 및 정보보호 등에 관한 법률(약칭:정보통신망법)' 및 개인정보보호법에 따라 반드시 작성해야 합니다.`,
  COMMENT2: `개인정보처리방침 변경 시, 변경 사유 및 내용에 대해 공지해야 합니다.(ex. 쇼핑몰 팝업 공지 등)`,
  CHANGE_HISTORY: `변경이력`,
  PRIVACY: `개인정보`,
  TREATMENT_POLICY_CONTENT: `처리방침 내용`,
  LOAD_BASIC_CONTENTS: `기본제공내용 불러오기`,
  EFFECTIVE_DATE: `시행일`,
  COMMENT3: `작성된 내용은 시행일자 이전까지만 수정 가능합니다. (시행일자 변경 후, 내용 수정 가능)`,
  SAVE_CHANGES: `변경사항 저장`,
  POPINFO1: `치환텍스트 목록`,
  POPINFO2: `개인정보처리방침 '사용' 시, 치환텍스트를 통해 내용을 입력할 수 있습니다.`,
  POPINFO3: `치환 텍스트`,
  POPINFO4: `설명`,
  POPINFO5: `예시`,
  POPINFO6: `회사명`,
  POPINFO7: `(주)홍길동`,
  POPINFO8: `시행일`,
  POPINFO9: `2018년01월01일`,
  PLACEHOLDER: `시행일자 선택`,
});
