







































import { Component } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import NoticeCategorySelector from '@/components/partner/notice/NoticeCategorySelector.vue';
import { ManageNoticeCategoryType, PutNoticeRequest } from 'ncp-api-supporter';

@Component({
  components: { SelectBox, NoticeCategorySelector },
})
export default class PartnerNoticeCategoryChanger extends WindowPopupMainVue {
  private noticeCategory: ManageNoticeCategoryType = null;

  private changeCategory() {
    if (!this.noticeCategory) {
      alert('카테고리를 선택해 주세요.');
      return;
    }

    const data: PutNoticeRequest['data'] = {
      noticeNos: this.data.noticeNos,
      noticeCategory: this.noticeCategory,
    };

    this.$api.putNotice({ data }).then(({ data }) => {
      alert(`성공 ${data.successCount} 건`);
      this.onPositiveClick();
    });
  }

  private mounted() {
    this.$nextTick(() => this.resize());
  }
}
