export const PAGE_TYPE = {
  DOMAIN: 'DOMAIN',
  SECURE: 'SECURE',
} as const;

export const SSL_STATUS = {
  INSTALLED: 'INSTALLED',
  NOT_INSTALLED: 'NOT_INSTALLED',
  EXPIRATION: 'EXPIRATION',
} as const;
