// 상품 리스트 & 대량 상품 등록에서 공통으로 사용하는 수정 관련 로직을 정의합니다.
import { OptColumn } from '@/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import router from '@/router';
import { api } from '@/api';
import { ProductsDetailUrl } from 'ncp-api-supporter';

export const getEditGridColumn = (): OptColumn => {
  return {
    header: 'MODIFY',
    name: 'edit',
    align: 'center',
    minWidth: 50,
    formatter: ({ row }) => {
      const isProhibitionSale = row.saleSettingStatusType === 'PROHIBITION_SALE';
      return getAnchorHTML(i18n.t(isProhibitionSale ? 'VIEW' : 'MODIFY'));
    },
  };
};

export const openEditProductWindow = (productNo: number) => {
  const href = router.resolve({ name: 'ProductAdd' }).href;
  const path = `${href.replace('add', 'edit')}?mallProductNo=${productNo}`;
  window.open(path);
};

export const openProductFrontWindow = (
  { mallProductNo, mallNo }: { mallProductNo: string; mallNo: string },
  mallUrls: ProductsDetailUrl[],
) => {
  const { url } = mallUrls.find(mallUrl => mallUrl.mallNo === Number(mallNo));
  if (!url) return;

  const productUrl = url.replace('{productNo}', mallProductNo);
  const addPreviewUsable = productUrl.includes('?') ? productUrl + '&preview=true' : productUrl + '?preview=true';
  window.open(addPreviewUsable);
};

export const openFrontProductDetails = async (mallNo: number, productNo: number) => {
  const { data } = await api.getProductsDetailUrls();
  const foundIdx = data.findIndex(info => info.mallNo === mallNo);
  const path = data[foundIdx].url.replace('{productNo}', productNo.toString());
  window.open(path);
};
