



























import { Vue, Component, Prop, PropSync, Model, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { OptionData } from '@/helpers/type';
const duplicateCommaReplacer = string => string.replace(/[*,.*]{2,}/g, ',');
@Component({
  components: { SelectBox, TextInput },
})
export default class SearchKeyword extends Vue {
  @PropSync('searchKeywordType') private searchKeywordTypeSync;
  @PropSync('keyword')
  private inputSearchKeyword!: string;
  @Model('input') readonly value!: string;
  @Prop({ required: false }) private readonly searchKeywordTypes: OptionData<string>[];
  @Prop({ required: false, default: '' }) private readonly validType: string;
  @Prop({ required: false, default: true }) private readonly hasSearchType: boolean;
  @Prop({ required: false, default: '246px' }) private readonly width: string;
  @Prop({ required: false, default: false }) private readonly isTextArea: boolean;
  @Prop({ required: false, default: false }) private readonly showsCount: boolean;
  @Prop({ required: false, default: 'PRODUCT.SEARCH.PLACEHOLDER_KEYWORDS' })
  private readonly placeholder: string;
  private searchKeyword = '';

  @Watch('inputSearchKeyword')
  private onInputSearchKeyword() {
    this.$emit('input', this.searchKeyword);
  }

  private onChangeSearchKeywordType(): void {
    this.searchKeyword = '';
    this.inputSearchKeyword = '';
    this.$emit('input', this.searchKeyword);
  }

  private checkValueLength(value: string): string {
    const MAX_KEYWORD_COUNT = 10;
    const inputKeyword = duplicateCommaReplacer(value);
    const keywords = SearchKeyword.convertToKeywordList(inputKeyword);

    let strKeyword = keywords.join(',');
    if (strKeyword.startsWith(',')) {
      strKeyword = strKeyword.substring(1);
      keywords.shift();
    }
    this.searchKeyword = strKeyword;

    if (keywords.length > MAX_KEYWORD_COUNT) {
      alert(this.$t('PRODUCT.SEARCH.ALERT_KEYWORD_LIMIT_OVER'));
      return inputKeyword.substring(0, value.length - 1);
    }
    return inputKeyword;
  }

  private static convertToKeywordList(value: string): string[] {
    const keywordsWithSpacesRemoved = value.replace(/\r\n|\n|\r/g, ',').replace(/\s/g, '');
    return duplicateCommaReplacer(keywordsWithSpacesRemoved).split(',');
  }

  @Watch('value')
  private setInitValue(): void {
    if (this.value !== '') {
      this.inputSearchKeyword = this.value;
    }
  }
}
