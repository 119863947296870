














import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class LoadingSpinner extends Vue {
  @Getter('isShowingLoadingSpinner') private showingLoadingSpinner!: boolean;
}
