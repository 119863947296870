















import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { SETTLEMENT_HOLIDAY_OPTIONS } from '@/const/contents/configuration/mallCreate';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';

@Component({
  components: { SelectBox, RadioGroup },
})
export default class SettlementDay extends Vue {
  @PropSync('settlementDay', { required: true })
  private settlementDaySync;

  @PropSync('settlementHolidayType', { required: true })
  private settlementHolidayTypeSync;

  @Prop({ required: true })
  private readonly holidayTypeOptions;

  // NOTE: 기획상 정산예정일 셀렉트 옵션 일자는 1일 ~ 27일 사이
  private readonly settlementHolidayOptions = SETTLEMENT_HOLIDAY_OPTIONS;
}
