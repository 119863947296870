





























































































































































































































































import moment from 'moment'; // eslint-disable-line
import { Component, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import DateCalendar from '@/components/common/DateCalendar.vue';
import { DateCalendarOption, DateRangeOption } from '@/types';
import { getToday, getStrYMDHMSS } from '@/utils/dateFormat';
import { HOUR_SELECT_OPTIONS } from '@/components/common/datepicker/dateRange';
import { throwWindowPopupWithProps, throwWindowPopup, PopupClose, PopupResult, throwPopup } from '@/helpers/popup';
import { Getter } from 'vuex-class';
import NotificationGuide from '@/components/member/NotificationGuide.vue';
import {
  Mall,
  getCouponsIssuePlansCouponIssuePlanNoRequest,
  // PromotionCouponIssueTargetType,
  // PromotionCouponIssueTimeType,
  // PromotionNotificationToolsType,
  GetSmsGodoTokenPopupPointChargeRequest,
  NCPResponse,
  GetSmsGodoTokenPopupPointCharge,
  PostCouponsIssuePlansRequest,
  IssueTargetMember,
  PutCouponsIssuePlansRequest,
} from 'ncp-api-supporter';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { throwMessagePopup } from '@/helpers/popup';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { throwExternalWindowPopup } from '@/helpers/popup';

@Component({
  components: { SelectBox, RadioGroup, ToolTip, DateCalendar, DateRangePicker, MallSelect, NotificationGuide },
})
export default class CouponManagementPopup extends WindowPopupMainVue {
  @Getter('mall/getMalls') private malls!: Mall[];
  private showMalls: Mall[] = [];

  private payTermOption = {
    name: 'payTermOption',
    data: [
      {
        id: 'birthDay',
        value: 'BIRTHDAY',
        display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_BIRTHDAY'),
      },
      {
        id: 'signUpDate',
        value: 'JOIN',
        display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_SIGNUP'),
      },
    ],
  };
  private payTypeOption = {
    name: 'payTypeOption',
    data: [
      {
        id: 'immediately',
        value: 'IMMEDIATELY_ISSUE',
        display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_IMMEDIATELY'),
      },
      {
        id: 'scheduled',
        value: 'SCHEDULED_ISSUE',
        display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_SCHEDULED'),
      },
      { id: 'auto', value: 'PERIOD_ISSUE', display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_AUTO') },
    ],
  };

  // 알림 설정
  private isNoticeSetOption = false;
  private notificationChannel = ['SMS', 'EMAIL'];
  private popupKind = '';
  private startYmdt = '';
  private endYmdt = '';
  private startHms = '';
  private endHms = '';
  private sendMsgType = 'SMS / 이메일 ';
  private showPayTerm = true;
  private showPayPeriod = false;
  private issueStatusType = '';
  private colspanNum = 0;
  private payTermOptionDisable = false;
  private issueReasonDisable = false;
  private couponSearchDisable = false;
  private possibleToSend = false;
  private showInfoCoupon = false;

  private notificationOptionDisable = false;
  private notificationTypeDisable = false;
  private notificationTemplateType = 'ISSUE_COUPON_MEMBER';
  private showMemberSearch = true;
  private memberSearchBtnShow = true;
  private memberSearchDisable = false;
  private dateDisable = false;
  private monthDisable = false;
  private dateCalendarOption: DateCalendarOption = {
    fromRanges: getToday(),
    selectedYmd: getToday(),
    dateType: 'YmdH',
    selectedHour: '00',
  };
  private mallNos = '';
  private mallName = '';
  private mallNosDisable = false;
  private dateShow = false;
  private payType = 'IMMEDIATELY_ISSUE';
  private typeLabel = '';
  private payTerm = 'BIRTHDAY';
  private memberTargetCnt = 0;
  // private selectedHour = '00';
  private hoursOption = HOUR_SELECT_OPTIONS;
  protected requestDateTime = getToday() + ' 00:00:00';
  protected requestDate = getToday();
  private issueReason = '';
  // private notificationOptionValue = 'noSet';
  private couponList = [];
  private memberlist: number[] = [];
  private notificationOption = {
    name: 'notificationOption',
    data: [
      { id: 'noSet', value: 'noSet', display: this.$t('PROMOTION.COUPON_ISSUE.NOT_SET') },
      { id: 'set', value: 'set', display: this.$t('PROMOTION.COUPON_ISSUE.SET') },
    ],
  };
  private dateRangeOption: DateRangeOption = {
    name: 'joinDatePeriod',
    periodTypes: [],
    dateType: 'YmdHms',
    unchangeable: 'minute',
    fromRanges: getToday(),
    disabled: {
      startYmdt: false,
      endYmdt: false,
    },
  };

  private getToolTipMessage(): string {
    return this.$t('PROMOTION.COUPON_ISSUE.TOOLTIPMESSAGE').toString();
  }

  private getToolTipMessageCoupon(): string {
    return this.$t('PROMOTION.COUPON_ISSUE.TOOLTIPMESSAGECOUPON').toString();
  }

  private getToolTipMessageNotification(): string {
    return this.$t('PROMOTION.COUPON_ISSUE.TOOLTIPMESSAGENOTIFICATION').toString();
  }

  private getToolTipMessagePayTerm(): string {
    return this.$t('PROMOTION.COUPON_ISSUE.TOOLTIPMESSAGEPAYTERM').toString();
  }

  // private getCancelMessage(): string {
  //   return `쿠폰 지급을 취소하시겠습니까?
  //           취소 후에는 상태 변경이 불가능합니다.`;
  // }

  @Watch('popupKind')
  onPopupKindChanged() {
    this.colspanNum = 0;
    switch (this.popupKind) {
      case 'case1_1':
        //쇼핑몰
        this.mallNosDisable = false;

        // 대상 회원
        this.showMemberSearch = true;
        this.memberSearchDisable = false;

        //지급 시점
        this.dateShow = false;

        //지급 조건
        this.showPayTerm = false;

        //지급기간
        this.showPayPeriod = false;
        break;
      case 'case1_2':
        //쇼핑몰
        this.mallNosDisable = false;
        // if (this.showMalls.length == 2) {
        //   this.mallNosDisable = true;
        //   this.mallNos = this.showMalls[1].mallNo.toString();
        // }

        // 대상 회원
        this.showMemberSearch = true;
        this.memberSearchDisable = false;

        //지급 시점 지급유형을 예약지급으로 선택 시 출력
        this.dateShow = true;

        //지급 조건
        this.showPayTerm = false;

        //지급기간
        this.showPayPeriod = false;
        break;
      case 'case2':
        //쇼핑몰
        this.mallNosDisable = false;
        // if (this.showMalls.length == 2) {
        //   this.mallNosDisable = true;
        //   this.mallNos = this.showMalls[1].mallNo.toString();
        // }

        // 대상 회원
        this.showMemberSearch = false;

        // 지급 시점
        this.dateShow = false;

        //지급 조건
        this.showPayTerm = true;

        //지급기간
        this.showPayPeriod = true;

        break;
      case 'case3':
        this.colspanNum = 3;
        this.mallNosDisable = true;
        if ('PERIOD_ISSUE' === this.payType) {
          this.showMemberSearch = false;
          this.showPayPeriod = true;
          this.showPayTerm = true;
        } else {
          this.showMemberSearch = true;
          this.showPayPeriod = false;
          this.showPayTerm = false;
        }

        break;
      case 'case4': // 지급중..일까?
        this.colspanNum = 3;
        this.mallNosDisable = true;
        this.payTermOptionDisable = true;
        this.showMemberSearch = false;
        this.issueReasonDisable = true;
        this.showPayPeriod = true;
        this.dateRangeOption.disabled.startYmdt = true;
        this.dateRangeOption.disabled.endYmdt = false;
        if (this.payTerm == 'JOIN') {
          this.disabledUnchangeable = false;
        } else {
          this.disabledUnchangeable = true;
        }
        break;
      case 'case5_1':
        //즉시/예약지급
        this.colspanNum = 3;
        this.showPayTerm = false;
        this.showPayPeriod = false;
        this.issueReasonDisable = true;
        this.couponSearchDisable = true;
        this.notificationOptionDisable = true;
        this.notificationTypeDisable = true;

        this.showMemberSearch = true;
        this.dateShow = true;
        this.monthDisable = true;
        this.dateDisable = true;
        this.memberSearchBtnShow = false;

        if (this.issueStatusType == 'ISSUE_STOP' && this.payType == 'SCHEDULED_ISSUE') {
          this.memberSearchBtnShow = true;
          this.memberSearchDisable = true;
        } else {
          this.memberSearchDisable = false;
        }

        if (this.payType == 'IMMEDIATELY_ISSUE') {
          this.dateShow = false;
        }
        break;
      case 'case5_2': // 지급종료..일까?
        this.colspanNum = 3;
        this.showPayTerm = false;
        this.showPayPeriod = false;
        this.issueReasonDisable = true;
        this.couponSearchDisable = true;
        this.notificationOptionDisable = true;
        this.notificationTypeDisable = true;

        this.showMemberSearch = false;
        this.dateShow = false;
        this.showPayTerm = true;
        this.payTermOptionDisable = true;
        this.showPayPeriod = true;
        this.dateRangeOption.disabled.startYmdt = true;
        this.dateRangeOption.disabled.endYmdt = true;
        this.disabledUnchangeable = true;

        break;
    }
  }

  // private startHm = '00:00';
  // private endHm = '23:59';
  private disabledUnchangeable = true;
  private changePayTerm() {
    if (this.payTerm == 'BIRTHDAY') {
      this.disabledUnchangeable = true;
      this.startHms = '00:00';
      // this.endHm = '23:59';
      this.notificationTemplateType = 'ISSUE_COUPON_BIRTHDAY';
    } else {
      this.disabledUnchangeable = false;
      // this.startHm = '00:00';
      // this.endHm = '23:59';
      this.startHms =
        new Date().getMinutes() > 0
          ? moment(new Date())
              .add(1, 'hour')
              .format('HH') + ':00'
          : new Date().getHours().toString() + ':00';
      this.notificationTemplateType = 'ISSUE_COUPON_JOIN';
    }
    this.startYmdt = moment(new Date()).format('YYYY-MM-DD');
    this.endYmdt = moment(new Date()).format('YYYY-MM-DD');
    this.endHms = '23:59';
  }

  private oldPayType = '';
  private changePayType() {
    if (this.payType == 'PERIOD_ISSUE' || this.oldPayType == 'PERIOD_ISSUE') {
      if (
        (this.couponList.length > 0 || this.memberlist.length > 0) &&
        this.oldPayType != '' &&
        !confirm(this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_CHANGE_PAYTYPE').toString())
      ) {
        this.$nextTick(() => {
          this.payType = this.oldPayType;
        });
        return;
      } else {
        this.oldPayType = this.payType;
        this.couponList = [];
        this.memberlist = [];
        this.memberTargetCnt = 0;
        this.couponsitem = [];
        this.setCase();
      }
      if (this.payType == 'PERIOD_ISSUE') {
        this.startYmdt = moment(new Date()).format('YYYY-MM-DD');
        this.endYmdt = moment(new Date()).format('YYYY-MM-DD');
        this.endHms = '23:59';
        this.startHms = '00:00';
        this.payTerm = 'BIRTHDAY';

        this.showPayTerm = true;
        this.showPayPeriod = true;
        this.disabledUnchangeable = true;
        this.showMemberSearch = false;
        this.notificationTemplateType = 'ISSUE_COUPON_BIRTHDAY';
      } else {
        this.showPayTerm = false;
        this.showPayPeriod = false;
        this.disabledUnchangeable = false;
        this.showMemberSearch = true;
        this.notificationTemplateType = 'ISSUE_COUPON_MEMBER';
      }
    } else {
      this.oldPayType = this.payType;
      // this.memberlist = [];
      // this.memberTargetCnt = 0;
      // this.couponList = [];
      // this.couponsitem = [];
      this.setCase();
    }
    if (this.payType == 'SCHEDULED_ISSUE') {
      this.dateShow = true;
    } else {
      this.dateShow = false;
    }
  }

  private oldMall = '';
  private changeMallNo() {
    if (
      (this.couponList.length > 0 || this.memberlist.length > 0) &&
      this.oldMall != '' &&
      !confirm(this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_CHANGE_MALL').toString())
    ) {
      this.$nextTick(() => {
        this.mallNos = this.oldMall;
      });
    } else {
      this.oldMall = this.mallNos;
      this.memberlist = [];
      this.memberTargetCnt = 0;
      this.couponList = [];
      this.couponsitem = [];
    }
  }

  private setCase() {
    if (this.data.type != 'edit') {
      if (this.payType && this.payType == 'IMMEDIATELY_ISSUE') {
        //Case1  지급유형 : 즉시지급, 예약지급  신규 등록 시 팝업 화면
        this.popupKind = 'case1_1';
        this.requestDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
      } else if (this.payType && this.payType == 'SCHEDULED_ISSUE') {
        this.popupKind = 'case1_2';
        this.requestDateTime = `${moment().format('YYYY-MM-DD')} 00:00:00`;
      } else if (this.payType && this.payType == 'PERIOD_ISSUE') {
        // case2 지급유형 : 자동지급
        // 신규 등록 시 팝업 화면
        // 자동지급 시에는 대상회원, 지급시점 대신 지급조건, 지급 기간 설정 항목 출력
        this.popupKind = 'case2';
      }
    } else {
      if (this.issueStatusType == 'ISSUE_READY') {
        if (this.payType == 'SCHEDULED_ISSUE') {
          // case3 지급유형 : 예약지급, 자동지급
          // 지급상태 : 지급대기
          this.popupKind = 'case3';
        } else if (this.payType == 'PERIOD_ISSUE') {
          // case3 지급유형 : 예약지급, 자동지급
          // 지급상태 : 지급대기
          this.popupKind = 'case3';
        }
      } else if (this.issueStatusType == 'ISSUE_ING') {
        if (this.payType == 'PERIOD_ISSUE') {
          this.popupKind = 'case4';
        }
      } else if (this.issueStatusType == 'ISSUE_END') {
        if (this.payType == 'PERIOD_ISSUE') {
          this.popupKind = 'case4';
        } else if (this.payType == 'IMMEDIATELY_ISSUE') {
          this.popupKind = 'case5_1';
        } else if (this.payType == 'SCHEDULED_ISSUE') {
          this.popupKind = 'case5_1';
        }
      } else if (this.issueStatusType == 'ISSUE_STOP') {
        if (this.payType == 'SCHEDULED_ISSUE') {
          this.popupKind = 'case5_1';
        } else if (this.payType == 'PERIOD_ISSUE') {
          this.popupKind = 'case5_2';
        }
      }
    }
  }
  private isPopup = true;
  private divClass = 'container-wrap';
  created() {
    this.initMall(); //쇼핑몰
    if (this.data == null) {
      this.isPopup = false;
      this.divClass = 'contents';
      this.data = { mallNo: '', type: 'add' };
    }
    this.mallNos = this.data.mallNo;
    this.showMalls.forEach((column: Mall): void => {
      if (column.mallNo.toString() == this.mallNos) {
        this.mallName = column.mallName;
      }
    });

    if (this.data.type != 'add') {
      this.renderData();
      this.showInfoCoupon = true;
    } else {
      // const malls = mall.mallsSortedByCreationDate;
      // if (malls && malls.length > 0) {
      //   this.mallNos = malls[0].mallNo.toString();
      // }
      this.setCase();
      this.changePayType();
    }
  }

  private renderData() {
    const obj: getCouponsIssuePlansCouponIssuePlanNoRequest = {
      pathParams: {
        couponIssuePlanNo: String(this.data.rowKey),
      },
    };
    this.$api.getCouponsIssuePlansCouponIssuePlanNo(obj).then(response => {
      this.mallNos = response.data.mallNo.toString();
      this.payType = response.data.time.type;
      this.issueStatusType = response.data.issueStatusType;
      // this.startYmdt = response.data.time.periodStartYmdt;
      this.changePayType();
      this.payTerm = response.data.target.type;
      if (this.payTerm == 'BIRTHDAY') {
        this.disabledUnchangeable = true;
      } else {
        this.disabledUnchangeable = false;
      }
      if (response.data.time.requestYmdt != null && response.data.time.requestYmdt != undefined) {
        this.dateCalendarOption.selectedYmd = response.data.time.requestYmdt.substring(0, 10);
        this.dateCalendarOption.selectedHour = response.data.time.requestYmdt.substring(11, 13);
        this.requestDateTime = response.data.time.requestYmdt;
      }
      if (response.data.time.periodStartYmdt != null && response.data.time.periodStartYmdt != undefined) {
        this.dateRangeOption.fromRanges = response.data.time.periodStartYmdt.substring(0, 10);
        this.startYmdt = response.data.time.periodStartYmdt.substring(0, 10);
        this.startHms = response.data.time.periodStartYmdt.substring(11, 16);
      }
      if (response.data.time.periodEndYmdt != null && response.data.time.periodEndYmdt != undefined) {
        this.endYmdt = response.data.time.periodEndYmdt.substring(0, 10);
        this.endHms = response.data.time.periodEndYmdt.substring(11, 13) + ':59';
      }
      // this.endYmdt = response.data.time.periodEndYmdt;
      this.issueReason = response.data.issueReason;
      this.typeLabel = response.data.time.typeLabel;
      this.isNoticeSetOption = response.data.usesNotification;
      this.notificationChannel = response.data.notification.notificationTools;
      this.setCase();

      response.data.coupons.forEach(item => {
        this.couponList.push(item.no);
        this.couponsitem.push({ counponId: item.no, couponName: item.name, issuable: item.issuable });
      });
      if (response.data.target.member != null) {
        this.memberlist = response.data.target.member.memberNos;
        this.memberTargetCnt = this.memberlist.length;
      }
    });
  }

  private initMall() {
    this.malls.forEach((column: Mall): void => {
      if (column.used) {
        this.showMalls.push(column);
      }
    });
  }

  private onClickMemberSearchBtn() {
    if (this.mallNos == '') {
      alert(this.$t('PROMOTION.COUPON_ISSUE.NO_MALL'));
    } else {
      const popupData = { mallNo: this.mallNos };
      const mallNoOld = this.mallNos;
      throwWindowPopup(
        'InquiryMember',
        popupData,
        'xlg',
        ({ data, state }: PopupResult) => {
          if (state !== PopupClose.CONFIRM) return;
          if (mallNoOld != this.mallNos) {
            this.memberlist = [];
            this.memberTargetCnt = 0;
            return;
          }
          this.memberlist = data.selectedMember.selected;
          this.memberTargetCnt = this.memberlist.length;
        },
        JSON.stringify(popupData),
      );
    }
  }

  private onClickPayDetailBtn() {
    const popupData = {
      couponIssuePlanNo: String(this.data.rowKey),
    };
    throwWindowPopup('CouponPayStatusPopup', popupData, 'md', undefined, JSON.stringify(popupData));
  }

  private handleExcelBatchClick() {
    if (!this.mallNos?.length) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.NO_MALL'));
      return;
    }
    throwWindowPopupWithProps({
      componentName: 'ExcelBatchRegistrationPopup',
      data: { mallNo: this.mallNos },
      size: 'md',
      popupHeight: 400,
      onClose: ({ state, data: memberNos }) => {
        if (state === 'close') return;
        this.memberlist = memberNos;
        this.memberTargetCnt = memberNos.length;
      },
    });
  }

  private onSave() {
    if (this.data.type == 'edit') {
      this.updateCoupon();
    } else {
      this.addCoupon();
    }
  }

  private async updateCoupon() {
    if (await this.validate()) {
      const request = { pathParams: {}, data: {} } as PutCouponsIssuePlansRequest;
      const couponIssueTargetMember = {} as IssueTargetMember;
      this.$set(request.data, 'mallNo', Number(this.mallNos));
      // request.mallNo = Number(this.mallNos);
      switch (this.payType) {
        case 'IMMEDIATELY_ISSUE':
          this.$set(request.data, 'couponIssueTargetType', 'MEMBER');
          this.$set(couponIssueTargetMember, 'couponIssueMemberType', 'MEMBER_NO');
          this.$set(couponIssueTargetMember, 'memberNos', this.memberlist);
          this.$set(request.data, 'couponIssueTargetMember', couponIssueTargetMember);
          // request.couponIssueTargetType = 'MEMBER';
          // couponIssueTargetMember.couponIssueMemberType = 'MEMBER_NO';
          // couponIssueTargetMember.memberNos = this.memberlist;
          // request.couponIssueTargetMember = couponIssueTargetMember;
          break;
        case 'SCHEDULED_ISSUE':
          this.$set(request.data, 'couponIssueTargetType', 'MEMBER');
          this.$set(couponIssueTargetMember, 'couponIssueMemberType', 'MEMBER_NO');
          this.$set(couponIssueTargetMember, 'memberNos', this.memberlist);
          this.$set(request.data, 'couponIssueTargetMember', couponIssueTargetMember);
          // request.couponIssueTargetType = 'MEMBER';
          // couponIssueTargetMember.couponIssueMemberType = 'MEMBER_NO';
          // couponIssueTargetMember.memberNos = this.memberlist;
          // request.couponIssueTargetMember = couponIssueTargetMember;
          break;
        case 'PERIOD_ISSUE':
          this.$set(request.data, 'couponIssueTargetType', this.payTerm);
          // request.couponIssueTargetType = this.payTerm as PromotionCouponIssueTargetType;
          break;
      }
      this.$set(request.data, 'issueReason', this.issueReason);
      this.$set(request.data, 'couponIssueTimeType', this.payType);
      // request.issueReason = this.issueReason;
      // request.couponIssueTimeType = this.payType as PromotionCouponIssueTimeType;

      if (request.data.couponIssueTimeType === 'SCHEDULED_ISSUE') {
        this.$set(request.data, 'requestYmdt', this.requestDateTime);
        // request.requestYmdt = this.requestDateTime;
      } else if (request.data.couponIssueTimeType === 'PERIOD_ISSUE') {
        this.$set(request.data, 'periodStartYmdt', this.startYmdt + ' ' + this.startHms + ':00');
        this.$set(request.data, 'periodEndYmdt', this.endYmdt + ' ' + this.endHms + ':59');
        // request.periodStartYmdt = this.startYmdt + ' ' + this.startHms + ':00';
        // request.periodEndYmdt = this.endYmdt + ' ' + this.endHms + ':59';
      }
      this.$set(request.data, 'useNotification', this.isNoticeSetOption);
      // request.useNotification = this.isNoticeSetOption;
      if (request.data.useNotification == true) {
        this.$set(request.data, 'notificationTools', this.notificationChannel);
        // request.notificationTools = this.notificationChannel as PromotionNotificationToolsType[];
      }

      this.$set(request.data, 'couponNos', this.couponList);
      // request.couponNos = this.couponList;
      this.$set(request.pathParams, 'couponIssuePlanNo', String(this.data.rowKey));
      this.$api.putCouponsIssuePlans(request).then(() => {
        alert(this.$t('PROMOTION.ADDFORM.SUCCESS_MESSAGE3'));
        this.onPositiveClick();
      });
    }
  }
  private async addCoupon() {
    if (await this.validate()) {
      const request = { data: {} } as PostCouponsIssuePlansRequest;
      this.$set(request.data, 'mallNo', Number(this.mallNos));
      // request.mallNo = Number(this.mallNos);
      const couponIssueTargetMember = {} as IssueTargetMember;
      switch (this.payType) {
        case 'IMMEDIATELY_ISSUE':
          this.$set(request.data, 'couponIssueTargetType', 'MEMBER');
          this.$set(couponIssueTargetMember, 'couponIssueMemberType', 'MEMBER_NO');
          this.$set(couponIssueTargetMember, 'memberNos', this.memberlist);
          this.$set(request.data, 'couponIssueTargetMember', couponIssueTargetMember);
          // request.couponIssueTargetType = 'MEMBER';
          // couponIssueTargetMember.couponIssueMemberType = 'MEMBER_NO';
          // couponIssueTargetMember.memberNos = this.memberlist;
          // request.couponIssueTargetMember = couponIssueTargetMember;
          break;
        case 'SCHEDULED_ISSUE':
          this.$set(request.data, 'couponIssueTargetType', 'MEMBER');
          this.$set(couponIssueTargetMember, 'couponIssueMemberType', 'MEMBER_NO');
          this.$set(couponIssueTargetMember, 'memberNos', this.memberlist);
          this.$set(request.data, 'couponIssueTargetMember', couponIssueTargetMember);
          // request.couponIssueTargetType = 'MEMBER';
          // couponIssueTargetMember.couponIssueMemberType = 'MEMBER_NO';
          // couponIssueTargetMember.memberNos = this.memberlist;
          // request.couponIssueTargetMember = couponIssueTargetMember;
          break;
        case 'PERIOD_ISSUE':
          this.$set(request.data, 'couponIssueTargetType', this.payTerm);
          // request.couponIssueTargetType = this.payTerm as PromotionCouponIssueTargetType;
          break;
      }
      this.$set(request.data, 'issueReason', this.issueReason);
      this.$set(request.data, 'couponIssueTimeType', this.payType);
      // request.issueReason = this.issueReason;
      // request.couponIssueTimeType = this.payType as PromotionCouponIssueTimeType;

      if (request.data.couponIssueTimeType === 'SCHEDULED_ISSUE') {
        this.$set(request.data, 'requestYmdt', this.requestDateTime);
        // request.requestYmdt = this.requestDateTime;
      } else if (request.data.couponIssueTimeType === 'PERIOD_ISSUE') {
        this.$set(request.data, 'periodStartYmdt', this.startYmdt + ' ' + this.startHms + ':00');
        this.$set(request.data, 'periodEndYmdt', this.endYmdt + ' ' + this.endHms + ':59');
        // request.periodStartYmdt = this.startYmdt + ' ' + this.startHms + ':00';
        // request.periodEndYmdt = this.endYmdt + ' ' + this.endHms + ':59';
      }
      this.$set(request.data, 'useNotification', this.isNoticeSetOption);
      // request.useNotification = this.isNoticeSetOption;
      if (request.data.useNotification == true) {
        this.$set(request.data, 'notificationTools', this.notificationChannel);
        // request.notificationTools = this.notificationChannel as PromotionNotificationToolsType[];
      }
      this.$set(request.data, 'couponNos', this.couponList);
      // request.couponNos = this.couponList;
      this.$api.postCouponsIssuePlans(request).then(() => {
        if (request.data.couponIssueTimeType === 'SCHEDULED_ISSUE') {
          alert(this.$t('PROMOTION.ADDFORM.SUCCESS_MESSAGE2'));
          this.onPositiveClick();
        } else {
          alert(this.$t('PROMOTION.ADDFORM.SUCCESS_MESSAGE1'));
          this.onPositiveClick();
        }
      });
    }
  }

  private async validate(): Promise<boolean> {
    if (this.couponList.length <= 0) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT'));
      return false;
    }

    if ((this.payType == 'IMMEDIATELY_ISSUE' || this.payType == 'SCHEDULED_ISSUE') && this.memberlist.length <= 0) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT2'));
      return false;
    }

    if (this.payType == 'SCHEDULED_ISSUE' && getStrYMDHMSS(new Date()) > this.requestDateTime) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT_AFTER_THIS_TIME'));
      return false;
    }

    if (
      this.payType == 'PERIOD_ISSUE' &&
      this.payTerm === 'BIRTHDAY' &&
      moment(new Date()).format('YYYY-MM-DD') > this.startYmdt
    ) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT_AFTER_THIS_TIME1'));
      return false;
    }

    if (
      this.payType == 'PERIOD_ISSUE' &&
      this.payTerm === 'JOIN' &&
      getStrYMDHMSS(new Date()) > this.startYmdt + ' ' + this.startHms
    ) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT_AFTER_THIS_TIME1'));
      return false;
    }

    if (this.payType == 'PERIOD_ISSUE' && this.startYmdt + this.startHms > this.endYmdt + this.endHms) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT_AFTER_THIS_TIME2'));
      return false;
    }

    if (this.isNoticeSetOption == true && this.notificationChannel.length <= 0) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT_NOTICE_METHOD'));
      return false;
    }

    if (this.isNoticeSetOption == true && !this.possibleToSend) {
      sessionStorage.setItem('mallNoValue', this.mallNos);
      const authResult = await throwPopup({
        name: 'NotificationNotAvailable',
        data: {
          headerText: this.$t('MEMBER.POPUP.NOTIFICATION_HEADER_TEXT'),
          message: this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_PAYMENT_ACCUMULATION'),
          isConfirm: true,
          mallNoValue: this.mallNos,
          mallNoMust: true,
          templatetype: 'ISSUE_COUPON_MEMBER',
          notificationMethodType: this.notificationChannel,
          paymentTime: this.requestDateTime,
        },
      });
      if (authResult.state !== PopupClose.CONFIRM) {
        return false;
      }
    }
    if (!confirm(this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_DO_COUPON', [this.couponList.length]).toString())) {
      return false;
    }

    alert(this.$t('PROMOTION.COUPON_ISSUE.COUPON_WARN'));

    return true;
  }

  private onEnd() {
    if (!confirm(this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_END_COUPON').toString())) {
      return;
    }

    const obj = {
      pathParams: {
        couponIssuePlanNo: String(this.data.rowKey),
      },
    };
    this.$api.putCouponsIssuePlansTerminate(obj).then(() => {
      alert(this.$t('PROMOTION.ADDFORM.ISSUE_CANCELED'));
      this.onPositiveClick();
    });
  }

  private async onCancel() {
    //TODO cancel use the same api of end, is right?
    if (!confirm(this.$t('PROMOTION.COUPON_ISSUE.CANCELMESSAGE').toString())) {
      return;
    }

    const obj = {
      pathParams: {
        couponIssuePlanNo: String(this.data.rowKey),
      },
    };
    this.$api.putCouponsIssuePlansTerminate(obj).then(() => {
      alert(this.$t('PROMOTION.ADDFORM.ISSUE_END'));
      this.onPositiveClick();
    });
  }

  private changeSelectedDateTime(requestDateTime) {
    this.requestDateTime = requestDateTime;
    if (this.payType == 'SCHEDULED_ISSUE' && getStrYMDHMSS(new Date()) > this.requestDateTime) {
      alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT_AFTER_THIS_TIME'));
      return false;
    }
  }

  private couponsitem = [];
  private onClickCouponSearch() {
    if (this.mallNos == '') {
      alert(this.$t('PROMOTION.COUPON_ISSUE.NO_MALL'));
    } else {
      const mallNoOld = this.mallNos;
      const payTypeOld = this.payType;
      const popupData = {
        mallNo: this.mallNos,
        popType: this.payType === 'PERIOD_ISSUE' ? 'INSERT' : '',
        couponList: this.couponList,
        fromPage: 'fromCouponManagement',
        couponIssuePlanTimeType: this.payType,
      };
      throwWindowPopup(
        'InquiryCoupon',
        popupData,
        'xlg',
        ({ data, state }: PopupResult) => {
          this.showInfoCoupon = true;
          if (state !== PopupClose.CONFIRM) return;
          if (mallNoOld !== this.mallNos) {
            alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT4'));
            this.couponsitem = [];
            this.couponList = [];
            return;
          }
          if (
            (payTypeOld === 'PERIOD_ISSUE' && this.payType !== 'PERIOD_ISSUE') ||
            (payTypeOld !== 'PERIOD_ISSUE' && this.payType === 'PERIOD_ISSUE')
          ) {
            alert(this.$t('PROMOTION.COUPON_ISSUE.ALERT4'));
            return;
          }

          const couponListBack = JSON.parse(JSON.stringify(this.couponList));
          const couponsitemBack = JSON.parse(JSON.stringify(this.couponsitem));
          data.couponItems.forEach(itemNew => {
            let exsit = false;
            this.couponList.forEach(itemOld => {
              if (itemNew.counponId == itemOld) {
                exsit = true;
              }
            });
            if (exsit === false) {
              this.couponList.push(Number(itemNew.counponId));
              this.couponsitem.push({ counponId: itemNew.counponId, couponName: itemNew.couponName, issuable: true });
            }

            if (this.couponList.length > 10) {
              throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_CONFIRM_GT_10'));
              this.couponList = JSON.parse(JSON.stringify(couponListBack));
              this.couponsitem = JSON.parse(JSON.stringify(couponsitemBack));
              return;
            }
          });
        },
        JSON.stringify(popupData),
      );
    }
  }

  private issueReasonKeyUp() {
    this.issueReason = this.issueReason
      .replace(/[“]/g, '')
      .replace(/[<]/g, '')
      .replace(/[>]/g, '')
      .replace(/[\\]/g, '');
  }

  private dateCheck(): boolean {
    if (this.startYmdt + this.startHms > this.endYmdt + this.endHms) {
      throwMessagePopup(this.$t('PROMOTION.COUPON_ISSUE.DATE_RROR'));
      return false;
    }

    return true;
  }

  public async openChargePopup() {
    const request: GetSmsGodoTokenPopupPointChargeRequest = {
      params: {
        mallNo: Number(this.mallNos),
      },
    };
    try {
      const response: NCPResponse<GetSmsGodoTokenPopupPointCharge> = await this.$api.getSmsGodoTokenPopupPointCharge(
        request,
      );
      throwExternalWindowPopup(response.data.url, 'md', {
        token: response.data.token,
        godosno: response.data.sno,
        type: response.data.type,
      });
    } catch (err) {
      console.error(err);
    }
  }

  private removeCoupon(index: number): void {
    this.couponList.splice(index, 1);
    this.couponsitem.splice(index, 1);
  }
}
