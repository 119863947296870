















import { Vue, Component, Watch } from 'vue-property-decorator';
import Sitemap from '@/components/navigation/Sitemap.vue';

@Component({
  components: {
    Sitemap,
  },
})
export default class SitemapController extends Vue {
  private show = false;

  @Watch('$route.path')
  changedRoute() {
    this.show = false;
  }
}
