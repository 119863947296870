export default Object.freeze({
  HEADER_TITLE: '적립금 관리',
  GRIDS_HEADER_IS_EDITABLE: '수정',
  GRIDS_HEADER_MALL_NAME: '쇼핑몰',
  GRIDS_HEADER_RATE: '기본적립률',
  GRIDS_HEADER_NAME: '적립금명칭',
  GRIDS_HEADER_SING_UP_ACCUMULATION: '회원가입 적립금',
  GRIDS_HEADER_REVIEW_ACCUMULATION: '상품평 적립금',
  GRIDS_HEADER_PHOTO_REVIEW_ACCUMULATION: '포토상품평 적립금',
  GRIDS_HEADER_BIRTHDAY_ACCUMULATION: '생일자 회원 적립금',
  GRIDS_HEADER_APP_INSTALL_ACCUMULATION: '앱설치 적립금',
  GRIDS_HEADER_USE_MIN_PRODUCT_PRICE: '적립금 사용 최소 결제금액',
  GRIDS_HEADER_USE_MIN_PRICE: '적립금 사용 최소 적립금',
  GRIDS_HEADER_USE_MAX_RATE: '적립금 사용 최대 적립금',
  GRIDS_HEADER_GIVE_POINT: '적립금 지급 시점',
  GRIDS_EXPIRE_VALID_PERIOD: '적립금 유효기간',
  GRIDS_HEADER_EXPIRED_NOTIFICATION_POINT: '적립금 만료알림',
  GRIDS_HEADER_REGISTER_YMDT: '등록일시',
  GRIDS_HEADER_UPDATE_ADMIN: '최종 수정자',
  GRIDS_HEADER_UPDATE_YMDT: '최종수정일',

  POINT_TEXT: '구매확정 후',
  IMMEDIATE: '즉시',
  NEXT_DAY: '익일',
  AFTER_TWO_DAYS: '2일 전',
  AFTER_A_WEEK: '7일 전',
  AFTER_TWO_WEEK: '14일 전',
  AFTER_TWENTY_DAYS: '20일 전',
  AFTER_THIRTY_DAYS: '30일 전',
  NEXT_MONTH: '익월',

  NO_LIMIT: '제한없음',
  OUT_LIMIT: '원 이상 구매 시',
  WORDS_TEXT: '자 이상',
  PAYMENT_UNIT: '적립',
  TOTAL_PAYMENT: '상품합계 금액',
  EXPIRE_TEXT: '만료일 기준 {day}일 전',
});
