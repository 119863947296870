import { ModuleTree } from 'vuex';

const requireModules = require.context('./', true, /\.ts$/);

const modules = requireModules.keys().reduce((modules: ModuleTree<any>, fileName): object => {
  if (fileName === './common.ts') {
    const words = requireModules(fileName).default;
    for (const key in words) {
      modules[key] = words[key];
    }
  } else if (fileName !== './index.ts') {
    const words = requireModules(fileName).default;
    const pointSlash = /^\.\//;
    const slash = /\//g;
    let nameLength = fileName.length - 3;
    if (fileName.indexOf('/index.ts') === fileName.length - 9) {
      nameLength -= 6;
    }
    const prefix = fileName
      .substr(0, nameLength)
      .replace(pointSlash, '')
      .replace(slash, '.')
      .toUpperCase();

    for (const key in words) {
      modules[prefix + '.' + key] = words[key];
    }
  }
  return modules;
}, {});

export default modules;
