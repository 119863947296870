export function greaterThen(vueEntity, value, checkVal, checkFieldId: string, messageCode: string): boolean {
  if (value <= checkVal) {
    alert(vueEntity.$t(messageCode));
    vueEntity.$refs[checkFieldId].focus();
    return false;
  }
  return true;
}

export function between(vueEntity, aValue, bValue, checkFieldId: string, messageCode: string): boolean {
  if (aValue > bValue) {
    alert(vueEntity.$t(messageCode));
    vueEntity.$refs[checkFieldId].focus();
    return false;
  }
  return true;
}

/**
 * @param data : 존재하는지 확인할 값 혹은 boolean
 * @param alertMessage: 얼럿 메시지. alert(any)가능하기 때문에 타입 열어둠
 */
export const isValidate = (data, alertMessage): boolean => {
  if (!data) {
    alert(alertMessage);
    return false;
  }
  return true;
};

export enum ValidatePasswordState {
  SUCCESS, // 성공
  LESS_THEN_8, // 8자 미만
  MORE_THEN_20, // 20자 초과
  LESS_THEN_10_AT_LEAST_2, // 10자 미만, 2조합 이하
  MORE_THEN_10_AT_LEAST_1, // 10자 이상, 1조합 이하
  NOT_ALLOW_SPECIAL,
}
export const validatePassword = (password: string): ValidatePasswordState => {
  if (!password) {
    return ValidatePasswordState.SUCCESS;
  }

  if (password.length < 8) {
    return ValidatePasswordState.LESS_THEN_8;
  }

  if (password.length > 20) {
    return ValidatePasswordState.MORE_THEN_20;
  }

  const patternNumber = /[0-9]/g;
  const patternEnglish = /[a-zA-Z]/gi;
  const patternSpecial = new RegExp('[\\!\\@\\#\\$\\%\\^\\&\\+\\=\\-\\_\\.\\(\\)]', 'g');

  const filteredPassword = password
    .replace(patternNumber, '')
    .replace(patternEnglish, '')
    .replace(patternSpecial, '');

  if (filteredPassword.length > 0) {
    return ValidatePasswordState.NOT_ALLOW_SPECIAL;
  }

  const checkNumber = patternNumber.test(password) ? 1 : 0;
  const checkEnglish = patternEnglish.test(password) ? 1 : 0;
  const checkSpecial = patternSpecial.test(password) ? 1 : 0;
  const checkSum = checkNumber + checkEnglish + checkSpecial;

  if (password.length < 10 && checkSum < 3) {
    // 비밀번호길이가 8 ~ 9인 경우 필수로 '숫자 + 문자 + 특수' 이여야함.
    return ValidatePasswordState.LESS_THEN_10_AT_LEAST_2;
  }

  if (checkSum < 2) {
    // 비밀번호길이가 10 ~ 20인경우. '숫자 / 문자 /특수' 중 두가지 이상 조합이여야함.
    return ValidatePasswordState.MORE_THEN_10_AT_LEAST_1;
  }

  return ValidatePasswordState.SUCCESS;
};

/**
 * static of(target, message)
 * @param {string} target : 검증 실패한 target input 의 attribute name
 * @param {string} message : 검증 실패 시 노출할 메시지
 *
 * 오류를 핸들링하는 최상위에서 아래와 같이 활용할 수 있습니다.
 * catch(error) {
 *   if (error?.target) {
 *     - target 이 속한 component 의 focus 메소드 호출
 *     - alert(error.message);
 *     ex ) src/views/popups/partner/PartnerNoticeFormPopup > registerNotice method
 *   }
 * }
 */
export class ThrowInputValidationError extends Error {
  private readonly target: string;
  constructor(target, message) {
    super(message);
    this.target = target;
  }
  static of(target, message) {
    throw new ThrowInputValidationError(target, message);
  }
}
