




































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import DeliveryCell from '@/components/shipping/order/DeliveryCell.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { GetOrderProductsRequest, GetShippings, GetShippingsRequest, NCPResponse } from 'ncp-api-supporter';
import { orderCancelListTab, orderExchangeListTab, orderReturnListTab } from '@/const/order';
import { ClaimStatusType } from '@/types/claim';
import { claimTypes } from '@/const/claim';
import { openEditProductWindow, openFrontProductDetails } from '@/components/product/common/girdColumns/ProductEdit';
import { PopupClose, throwNoPopupIdWindowPopup } from '@/helpers/popup';
import ClaimButtons from '@/components/shipping/order/OrderProductInfo/ClaimButtons.vue';
import { ClaimValidationType, PostClaimsValidateOrderStatusRequest } from 'ncp-api-supporter/dist/types';
import { unescapeHtml } from '@/utils/common';
import OrderProductInfoMixin from '@/components/shipping/order/mixins/OrderProductInfoMixin';

@Component({
  components: { DeliveryCell, ClaimButtons, ToolTip },
})
export default class OrderProductInfo extends Mixins(OrderProductInfoMixin) {
  @Prop({ required: true }) public taskMessageInfo;
  @Prop({ default: 'orderAllListTab' }) public focusTabName;
  @Prop({ required: true }) private escrowPayType: boolean;
  @Prop({ required: true }) private payType!: string;

  private unescapeHtml = unescapeHtml;

  @Watch('focusTabName')
  focusTabNameChanged(name) {
    const productInfo = [];
    this.productInfo = [];
    [...this.formatterProductInfo(this.orgProductInfo, 'orderOptions')].map(value => {
      switch (name) {
        case 'orderListTab':
          if (!value.claimStatusType) {
            productInfo.push(value);
          }
          break;
        case 'orderCancelListTab':
          if (orderCancelListTab.includes(value.claimStatusType)) {
            productInfo.push(value);
          }
          break;
        case 'orderExchangeListTab':
          if (orderExchangeListTab.includes(value.claimStatusType)) {
            productInfo.push(value);
          }
          break;
        case 'orderReturnListTab':
          if (orderReturnListTab.includes(value.claimStatusType)) {
            productInfo.push(value);
          }
          break;
        case 'orderAllListTab':
          productInfo.push(value);
          break;
      }
    });
    if (productInfo.length > 0) {
      this.productInfo = [...this.mergeRow(productInfo)];
    }
    this.productInfo.map((item, val) => {
      if (item.setOptionJson !== null) {
        this.productInfo[val].setOptions = JSON.parse(item.setOptionJson);
      }
      this.taskMessageInfo.map(tItem => {
        this.productInfo[val].taskMessageStatus =
          item.orderOptionNo === tItem.orderOptionNo && tItem.taskMessageStatus === 'PROCESSING';
      });
    });
    this.formatterMallNoShippingGet();
  }

  private productInfo = [];
  private orgProductInfo = [];
  private shippingNosArr;
  // private theCancelAll = false;
  private orderCancelAll = false;
  private shippingResp = [];
  public canEditInvoiceStatus = [
    'DEPOSIT_WAIT',
    'PAY_DONE',
    'PRODUCT_PREPARE',
    'DELIVERY_PREPARE',
    'DELIVERY_ING',
    'DELIVERY_DONE',
  ];

  private getFocusTabName(name: string) {
    switch (name) {
      case 'orderListTab':
        return this.$t('ORDER.ORDER_DETAIL.ORDER_TAB_LIST');
      case 'orderCancelListTab':
        return this.$t('ORDER.ORDER_DETAIL.ORDER_TAB_CANCEL_LIST');
      case 'orderExchangeListTab':
        return this.$t('ORDER.ORDER_DETAIL.ORDER_TAB_EXCHANGE_LIST');
      case 'orderReturnListTab':
        return this.$t('ORDER.ORDER_DETAIL.ORDER_TAB_RETURN_LIST');
    }
  }

  public getOrderProductInfo(): void {
    const ProductInfoRequest: GetOrderProductsRequest = {
      params: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api.getOrderProducts(ProductInfoRequest).then(res => {
      this.productInfo = [];
      this.orgProductInfo = [...res.data];
      this.productInfo = [...this.formatterProductInfo(this.orgProductInfo, 'orderOptions')];
      this.checkCancelAll(this.productInfo);
      this.productInfo = [...this.mergeRow(this.productInfo)];

      this.productInfo.map((item, val) => {
        if (item.setOptionJson !== null) {
          this.productInfo[val].setOptions = JSON.parse(item.setOptionJson);
        }
        this.taskMessageInfo.map(tItem => {
          this.productInfo[val].taskMessageStatus =
            item.orderOptionNo === tItem.orderOptionNo && tItem.taskMessageStatus === 'PROCESSING';
        });
      });
      this.$emit('emitProductInfo', this.productInfo);
      this.$emit('emitOrderProductInfo', res.data);
      this.formatterMallNoShippingGet();
    });
  }

  private getCanCancelSub(item) {
    return (
      (item.orderStatusType === 'PRODUCT_PREPARE' ||
        item.orderStatusType === 'DELIVERY_PREPARE' ||
        item.orderStatusType === 'PAY_DONE' ||
        (item.orderStatusType === 'DEPOSIT_WAIT' &&
        this.payType !== 'VIRTUAL_ACCOUNT' && // 가상계좌/가상계좌-에스크로는 전체취소만 가능
          this.payType !== 'ESCROW_VIRTUAL_ACCOUNT')) &&
      !item.claimStatusType
    );
  }

  public checkCancelAll(arr): void {
    if (arr.length === 0) {
      return;
    }
    // const status = ['DEPOSIT_WAIT'];
    // this.theCancelAll = arr.every(item => {
    //   return status.indexOf(item.orderStatusType) > -1 && !item.claimStatusType;
    // });
    this.orderCancelAll = arr.every(item => {
      return item.canCancel;
    });
  }

  public formatterMallNoShippingGet(): void {
    this.shippingNosArr = [];
    const mallNoArr = [];
    const mallAndShipping = [];
    const formaterMAS = [];
    if (this.productInfo.length === 0) {
      return;
    }
    this.productInfo.forEach(item => {
      this.$set(item, 'addInvoice', true);
      mallAndShipping.push({ mallNo: item.mallNo, shippingNo: item.shippingNo });
      if (this.shippingNosArr.indexOf(item.shippingNo) < 0) {
        this.shippingNosArr.push(item.shippingNo);
      }
    });
    mallAndShipping.forEach(mas => {
      if (mallNoArr.indexOf(mas.mallNo) < 0) {
        mallNoArr.push(mas.mallNo);
        formaterMAS.push(mas);
      } else {
        formaterMAS.forEach(fmas => {
          if (fmas.mallNo === mas.mallNo) {
            fmas.shippingNo = fmas.shippingNo.toString() + ',' + mas.shippingNo;
          }
        });
      }
    });
    formaterMAS.forEach(fmas => {
      this.getTheShippings(fmas.mallNo, fmas.shippingNo);
    });
  }
  public getTheShippings(mallNo: string, shippingNos: string) {
    const shippingRequest: GetShippingsRequest = {
      params: {
        mallNo: mallNo,
        shippingNos: shippingNos,
      },
    };
    this.$api.getShippings(shippingRequest).then(res => {
      this.shippingResp = [];
      this.shippingResp = res.data;
      this.checkReceiverBtnShow();
      this.$emit('emitShippingResp', this.shippingResp);
      this.useShippingRespFormatterProductInfo();
      this.setDeliveryAmt(this.shippingResp, this.productInfo);
    });
  }
  public checkReceiverBtnShow(): void {
    const arr = [];
    this.productInfo.map(item => {
      if (item.claimFinished && arr.indexOf(item.shippingNo) < 0) {
        arr.push(item.shippingNo);
      }
    });
    this.shippingResp.map(item => {
      if (arr.indexOf(item.shippingNo) > -1) {
        this.$set(item, 'receiverChangeBtn', true);
      }
    });
  }
  public useShippingRespFormatterProductInfo(): void {
    const nullInvoice = [];
    this.shippingResp.forEach(item => {
      if (this.productInfo.length === 0) {
        return;
      }
      this.productInfo.map(pro => {
        if (pro.shippingNo === item.shippingNo && pro.mallNo === item.mallNo) {
          this.$set(pro, 'prepaid', item.prepaid);

          if (this.canEditInvoiceStatus.indexOf(pro.orderStatusType) < 0 && item.invoiceNo) {
            this.$set(pro, 'showChangeDelivery', true);
          }

          if (!item.invoiceNo || !item.deliveryCompanyType || (!item.deliveryType as any) === 'DIRECT_DELIVERY') {
            pro.addInvoice = true;

            if (!this.checkEditInvoice(pro.orderStatusType)) {
              if (nullInvoice.indexOf(pro.shippingNo) < 0) {
                nullInvoice.push(pro.shippingNo);
              }
            }
          } else {
            pro.addInvoice = false;
          }
        }
      });
    });
    if (nullInvoice.length === 0) {
      return;
    }
    nullInvoice.map(nullShippingNo => {
      this.productInfo.forEach(pro => {
        if (pro.shippingNo === nullShippingNo && this.checkEditInvoice(pro.orderStatusType)) {
          this.productInfo.map(theP => {
            if (theP.shippingNo === nullShippingNo) {
              this.$set(theP, 'hanAddInvoice', true);
            }
          });
        }
      });
    });
  }
  public checkEditInvoice(status) {
    return this.canEditInvoiceStatus.indexOf(status) > -1;
  }
  public setDeliveryAmt(shippingArr, productInfoArr): void {
    shippingArr.forEach(sItem => {
      productInfoArr.map(pItem => {
        if (sItem.shippingNo === pItem.shippingNo && sItem.mallNo === pItem.mallNo) {
          this.$set(pItem, 'baseDeliveryAmt', sItem.deliveryAmt);
          this.$set(pItem, 'adjustedDeliveryAmt', sItem.adjustedDeliveryAmt);
          this.$set(pItem, 'remoteDeliveryAmt', sItem.remoteDeliveryAmt);
          this.$set(pItem, 'adjustedRemoteDeliveryAmt', sItem.adjustedRemoteDeliveryAmt);
          this.$set(pItem, 'deliveryType', sItem.deliveryType);
          this.$set(pItem, 'combined', sItem.combined);
          this.$set(pItem, 'devided', sItem.devided);
        }
        if (pItem.holdDelivery === true && pItem.shippingNo === sItem.shippingNo) {
          this.$set(sItem, 'holdDelivery', true);
        }
        if (pItem.reservation.holdByReservation === true && pItem.shippingNo === sItem.shippingNo) {
          this.$set(sItem, 'holdByReservation', true);
        }
      });
    });
  }

  public totalPrice(ev) {
    if (ev.immediateDiscountedPrice > ev.adjustedAmt) {
      return ev.adjustedAmt;
    } else {
      return ev.adjustedAmt;
    }
  }

  public formatterProductInfo(arr, attribute: string) {
    const newArr = [];
    arr.forEach(item => {
      item[attribute].forEach(opItem => {
        const obj = {};
        Object.keys(item).forEach(name => {
          if (name !== attribute) {
            obj[name] = item[name];
          }
        });
        Object.keys(opItem).forEach(opName => {
          obj[opName] = opItem[opName];
        });
        newArr.push(obj);
      });
    });
    newArr.sort((a, b) => {
      return a.partnerNo - b.partnerNo;
    });
    return newArr;
  }
  public mergeRow(arr) {
    const bigGroup = [];
    const midGroup = [];
    const smallGroup = [];
    const newArr = [];

    if (arr.length === 0) {
      return;
    }
    arr.forEach(item => {
      if (bigGroup.indexOf(item.partnerNo) < 0) {
        bigGroup.push(item.partnerNo);
        item.bRowSpan = arr.filter(item1 => {
          return item1.partnerNo === item.partnerNo;
        }).length;
      }
      if (midGroup.indexOf(item.shippingNo) < 0) {
        midGroup.push(item.shippingNo);
      }
      if (smallGroup.indexOf(item.claimNo) < 0) {
        smallGroup.push(item.claimNo);
      }
    });
    midGroup.forEach(sItem => {
      arr.forEach(item => {
        if (item.shippingNo === sItem) {
          newArr.push(item);
        }
      });
    });
    smallGroup.forEach(sItem => {
      const group = [];
      newArr.forEach(item => {
        if (group.indexOf(item.claimNo) < 0 && sItem === item.claimNo) {
          group.push(item.claimNo);
          item.cRowSpan = newArr.filter(item1 => {
            return (
              item1.shippingNo === item.shippingNo && sItem === item1.claimNo && item1.partnerNo === item.partnerNo
            );
          }).length;
        }
      });
    });
    bigGroup.forEach(pnItem => {
      const group = [];
      newArr.forEach(item => {
        if (group.indexOf(item.shippingNo) < 0 && pnItem === item.partnerNo) {
          group.push(item.shippingNo);
          const shippingNoArr = newArr.filter(item1 => {
            return item1.shippingNo === item.shippingNo && pnItem === item1.partnerNo;
          });
          item.sRowSpan = shippingNoArr.length;
        }
      });
    });
    return newArr;
  }

  public claimStatusTypeLabel(type: ClaimStatusType | 'NONE') {
    let label;
    if (type === 'CANCEL_NO_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_CANCEL_NO_REFUND');
    }
    if (type === 'CANCEL_REQUEST') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_CANCEL_REQUEST');
    }
    if (type === 'CANCEL_PROC_REQUEST_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_CANCEL_PROC_REQUEST_REFUND');
    }
    if (type === 'CANCEL_PROC_WAITING_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_CANCEL_PROC_WAITING_REFUND');
    }
    if (type === 'CANCEL_DONE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_CANCEL_DONE');
    }
    if (type === 'RETURN_REQUEST') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_REQUEST');
    }
    if (type === 'RETURN_REJECT_REQUEST') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_REJECT_REQUEST');
    }
    if (type === 'RETURN_PROC_BEFORE_RECEIVE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_PROC_BEFORE_RECEIVE');
    }
    if (type === 'RETURN_PROC_REQUEST_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_PROC_REQUEST_REFUND');
    }
    if (type === 'RETURN_PROC_WAITING_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_PROC_WAITING_REFUND');
    }
    if (type === 'RETURN_REFUND_AMT_ADJUST_REQUESTED') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_REFUND_AMT_ADJUST_REQUESTED');
    }
    if (type === 'RETURN_DONE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_DONE');
    }
    if (type === 'RETURN_NO_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_RETURN_NO_REFUND');
    }
    if (type === 'EXCHANGE_REQUEST') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_REQUEST');
    }
    if (type === 'EXCHANGE_REJECT_REQUEST') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_REJECT_REQUEST');
    }
    if (type === 'EXCHANGE_PROC_BEFORE_RECEIVE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_PROC_BEFORE_RECEIVE');
    }
    if (type === 'EXCHANGE_PROC_REQUEST_PAY') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_PROC_REQUEST_PAY');
    }
    if (type === 'EXCHANGE_PROC_WAITING') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_PROC_WAITING');
    }
    if (type === 'EXCHANGE_PROC_WAITING_PAY') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_PROC_WAITING_PAY');
    }
    if (type === 'EXCHANGE_DONE_PAY_DONE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_DONE_PAY_DONE');
    }
    if (type === 'EXCHANGE_PROC_REQUEST_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_PROC_REQUEST_REFUND');
    }
    if (type === 'EXCHANGE_PROC_WAITING_REFUND') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_PROC_WAITING_REFUND');
    }
    if (type === 'EXCHANGE_DONE_REFUND_DONE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_DONE_REFUND_DONE');
    }
    if (type === 'EXCHANGE_DONE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_EXCHANGE_DONE');
    }
    if (type === 'NONE') {
      label = this.$t('ORDER.ORDER_DETAIL.CLAIM_STATUS_NONE');
    }
    return label;
  }

  private getDeliveryFeeTypeLabelHtml({ needs, className, label }): string {
    return needs ? `<span class="order-badge type-${className}">${this.$t(`${label}`)}</span>` : '';
  }
  // 배송비 묶음-true/나눔-true/착불-false 라벨
  private getDeliveryFeeTypeLabel({ combined, devided, prepaid }) {
    const labelInfo = [
      {
        needs: combined,
        className: 2,
        label: 'ORDER.ORDER_DETAIL.COMBINE',
      },
      {
        needs: devided,
        className: 3,
        label: 'ORDER.ORDER_DETAIL.DIVIDE',
      },
      {
        needs: !prepaid,
        className: 4,
        label: 'ORDER.ORDER_DETAIL.NOT_PRE_PAID',
      },
    ];
    return labelInfo.map(this.getDeliveryFeeTypeLabelHtml.bind(this)).join('');
  }

  private getShippingResp(shippingResp, item) {
    return shippingResp.find(shipping => shipping.shippingNo === item.shippingNo);
  }
  public popupReturn(orderProductOptionNo, escrow: boolean, orderStatusType: string, item): void {
    if (!this.validNaverReturn(item.payType, orderStatusType)) {
      return;
    }

    if (this.checkReturn(item)) {
      return;
    }

    if (escrow && orderStatusType !== 'DELIVERY_DONE' && orderStatusType !== 'BUY_CONFIRM') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_6'));
    } else {
      throwNoPopupIdWindowPopup(`/pro/popup/return-apply?orderProductOptionNo=${orderProductOptionNo}`, 'xlg');
    }
  }

  // 반품신청 가능한 네이버 주문인지 검증
  private validNaverReturn(payType: string, orderStatusType: string) {
    if (payType === 'NAVER_PAY' && orderStatusType === 'BUY_CONFIRM') {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return false;
    }

    return true;
  }

  public popupExchange(
    orderProductOptionNo,
    escrow,
    orderStatusType,
    shippingResp: GetShippings[],
    payType: string,
    item,
  ) {
    if (!this.validNaverExchange(payType)) {
      return;
    }

    let holdDeliveryState = false;
    let holdByReservation = false;
    if (shippingResp) {
      shippingResp.map(hData => {
        if ((hData as any).holdDelivery && hData.shippingNo === item.shippingNo) {
          holdDeliveryState = (hData as any).holdDelivery;
        }
        if ((hData as any).holdByReservation && hData.shippingNo === item.shippingNo) {
          holdByReservation = (hData as any).holdByReservation;
        }
      });
    }
    if (payType === 'ESCROW_REALTIME_ACCOUNT_TRANSFER' || payType === 'ESCROW_VIRTUAL_ACCOUNT ') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_13'));
      return;
    }
    if (
      holdDeliveryState &&
      (orderStatusType === 'PRODUCT_PREPARE' || orderStatusType === 'DELIVERY_PREPARE') &&
      !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_1') as string)
    ) {
      return;
    }
    if (holdByReservation && !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_2') as any)) {
      return;
    }
    if (escrow && orderStatusType !== 'DELIVERY_DONE' && orderStatusType !== 'BUY_CONFIRM') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_6'));
    } else {
      throwNoPopupIdWindowPopup(`/pro/popup/exchange-apply?orderProductOptionNo=${orderProductOptionNo}`, 'xlg');
    }
  }

  // 교환신청 가능한 네이버 주문인지 검증
  private validNaverExchange(payType: string) {
    if (payType === 'NAVER_PAY') {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return false;
    }

    return true;
  }

  public async popupCancelAll(orderNo: string, orderStatusType: string, shippingResp: [], item): Promise<void> {
    let holdDeliveryState = false;
    let holdByReservation = false;
    const isBefore = orderStatusType !== 'PAY_DONE';

    if (!this.validNaverCancelAll(item.payType, orderStatusType)) {
      return;
    }

    if (shippingResp) {
      shippingResp.map(hData => {
        if ((hData as any).holdDelivery && (hData as any).shippingNo === item.shippingNo) {
          holdDeliveryState = (hData as any).holdDelivery;
        }
        if ((hData as any).holdByReservation && (hData as any).shippingNo === item.shippingNo) {
          holdByReservation = (hData as any).holdByReservation;
        }
      });
    }
    if (holdByReservation && !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_2') as any)) {
      return;
    }
    if (
      holdDeliveryState &&
      (orderStatusType === 'PRODUCT_PREPARE' || orderStatusType === 'DELIVERY_PREPARE') &&
      !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_1') as any)
    ) {
      return;
    }

    if ((await this.isChangedOrderStatus(item.orderOptionNo, orderStatusType, item.claimStatusType)) === true) {
      return;
    }

    throwNoPopupIdWindowPopup(
      `/pro/popup/cancel-all-apply?orderNo=${orderNo}${isBefore ? '&type=before' : '&type=normal'}`,
      'xlg',
    );
    // );
  }

  private async isChangedOrderStatus(
    orderOptionNo: number,
    orderStatusType: string,
    claimStatusType: string,
  ): Promise<boolean> {
    const isChangedOrderStatus =
      (await this.postClaimsValidateOrderStatus(orderOptionNo, orderStatusType, claimStatusType)) ===
      'CHANGED_ORDER_STATUS';
    if (isChangedOrderStatus === true) {
      alert('ORDER.ORDER_DETAIL.CHANGED_ORDER_STATUS_ALERT');
    }
    return isChangedOrderStatus;
  }

  // 전체취소 가능한 네이버 주문인지 검증
  private validNaverCancelAll(payType: string, orderStatusType: string) {
    if (payType === 'NAVER_PAY' && orderStatusType !== 'PAY_DONE') {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return false;
    }

    return true;
  }

  public async openPopupCancel(
    orderOptionNo: number,
    escrow: boolean,
    orderStatusType: string,
    shippingResp: GetShippings[],
    item,
  ): Promise<void> {
    if (!this.validNaverCancel(item.payType, orderStatusType)) {
      return;
    }

    let holdDeliveryState = false;
    let holdByReservation = false;
    if (shippingResp) {
      shippingResp.map(hData => {
        if ((hData as any).holdDelivery && (hData as any).shippingNo === item.shippingNo) {
          holdDeliveryState = (hData as any).holdDelivery;
        }
        if ((hData as any).holdByReservation && (hData as any).shippingNo === item.shippingNo) {
          holdByReservation = (hData as any).holdByReservation;
        }
      });
    }
    if (holdByReservation && !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_2') as any)) {
      return;
    }
    if (
      holdDeliveryState &&
      (orderStatusType === 'PRODUCT_PREPARE' || orderStatusType === 'DELIVERY_PREPARE') &&
      !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_1') as any)
    ) {
      return;
    }
    if (item.payType === 'ESCROW_REALTIME_ACCOUNT_TRANSFER' || item.payType === 'ESCROW_VIRTUAL_ACCOUNT') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_15'));
      return;
    }
    if (escrow && orderStatusType != 'DELIVERY_DONE' && orderStatusType != 'BUY_CONFIRM') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_7'));
      return;
    }

    if ((await this.isChangedOrderStatus(orderOptionNo, orderStatusType, item.claimStatusType)) === true) {
      return;
    }

    throwNoPopupIdWindowPopup(`/pro/popup/cancel-apply?orderProductOptionNo=${orderOptionNo}`, 'xlg');
  }

  private async postClaimsValidateOrderStatus(
    orderOptionNo: number,
    orderStatusType: string,
    claimStatusType: string,
  ): Promise<string> {
    try {
      const data: PostClaimsValidateOrderStatusRequest['data'] = [
        {
          orderOptionNo,
          orderStatusType,
          claimStatusType,
        },
      ];
      const res: NCPResponse<ClaimValidationType> = await this.$api.postClaimsValidateOrderStatus({ data });
      return res.data.claimValidationType;
    } catch (e) {
      console.error(e);
    }
  }

  // 취소 가능한 네이버 주문인지 검증
  private validNaverCancel(payType: string, orderStatusType: string) {
    if (payType === 'NAVER_PAY' && orderStatusType === 'DEPOSIT_WAIT') {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return false;
    }

    return true;
  }

  public showClaimDetailBtn(type) {
    return !!type;
  }

  private openClaimPopup(claimNo: number, claimStatusType?: ClaimStatusType) {
    const claimType = claimStatusType.split('_')[0];
    const claimPopupTypes = {
      [claimTypes.CANCEL]: () => `/pro/popup/cancel?claimNo=${claimNo}`,
      [claimTypes.EXCHANGE]: () => `/pro/popup/exchange?claimNo=${claimNo}`,
      [claimTypes.RETURN]: () => `/pro/popup/return?claimNo=${claimNo}`,
      [claimTypes.REFUND]: () => {
        const claimType = Object.keys(claimTypes).find(claimType => claimStatusType.includes(claimType));
        return claimPopupTypes[claimType]();
      },
    };

    throwNoPopupIdWindowPopup(claimPopupTypes[claimType](), 'xlg', ({ state }) => {
      if (state === PopupClose.CONFIRM) {
        location.reload();
      }
    });
  }

  // TODO: 지금 주문상세의 클레임창은 상세보기 밖에 안뜸. 메인 이슈 처리후 수정 필요
  // private openClaimAcceptPopup(claimNo: number, claimType: ClaimType) {
  //   const query = `claimNo=${claimNo}`;
  //   const claimPopupTypes = {
  //     [claimTypes.CANCEL]: () => `${getHref('CancelAcceptPopup')}?${query}`,
  //     [claimTypes.EXCHANGE]: () => `${getHref('ExchangeAcceptPopup')}?${query}`,
  //     [claimTypes.RETURN]: () => `${getHref('ReturnAcceptPopup')}?${query}`,
  //   };
  //
  //   throwNoPopupIdWindowPopup(claimPopupTypes[claimType](), 'xlg', ({ state }) => {
  //     if (state === PopupClose.CONFIRM) {
  //       this.searchAgain();
  //     }
  //   });
  // }

  private onClickProductNo(productNo: number) {
    openEditProductWindow(productNo);
  }

  public checkReturn(data) {
    if (data.payType === 'ESCROW_REALTIME_ACCOUNT_TRANSFER' || data.payType === 'ESCROW_VIRTUAL_ACCOUNT') {
      let alertFlag = false;
      let confirmFlag = false;
      this.productInfo.map(item => {
        if (item.mallProductNo === data.mallProductNo && item.orderStatusType !== 'BUY_CONFIRM') {
          alertFlag = true;
          confirmFlag = true;
        }
      });
      if (alertFlag) {
        alert(this.$t('ORDER.ORDER_DETAIL.ALERT_14'));
        return true;
      }
      if (!confirmFlag) {
        return !confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_3') as any);
      }
    }
  }

  private onClickMallProductPage(mallNo: number, mallProductNo: number) {
    openFrontProductDetails(mallNo, mallProductNo);
  }
  mounted(): void {
    this.getOrderProductInfo();
  }

  private get isNaverPay() {
    return this.payType === 'NAVER_PAY';
  }
}
