


















































































































































































































































































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import TextInput, { TextInputValidationRegex } from '@/components/common/input/TextInput.vue';
import StockRangeInput from '@/components/product/common/StockRangeInput.vue';
import { getDefaultParams } from '@/components/product/basic/SearchQuery';
import { searchFormOptions } from '@/components/product/basic/SearchOptions';
import { ApplyStatusType, SortDirection, SortCriterion } from '@/components/product/basic/SearchOptionTypes';
import { getToday, addMonth } from '@/utils/dateFormat';
import CategorySelector from '@/components/product/common/CategorySelector.vue';
import MemberGradeSelect from '@/components/common/input/data/MemberGradeSelect.vue';
import MerchandiserSelect from '@/components/common/input/data/MerchandiserSelect.vue';
import BrandSelect from '@/components/common/input/data/BrandSelect.vue';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import { TabState } from '@/views/top/product/basic/ProductList.vue';
import { stringify } from 'qs';
import { ProductsSearchPostRequest } from 'ncp-api-supporter';
import PartnerFinder from '@/components/common/input/PartnerFinder.vue';
import { PartnerType } from '@/types';
import { BEFORE_APPROVAL_STATUS } from '@/const/contents/product';
import { Getter } from 'vuex-class';

type ProductsSearchPostRequestData = ProductsSearchPostRequest['data'];

// minStockCnt, maxStockCnt에 잠시 string이 담아지기 위한 임시 타입
// (stock-range-input 컴포넌트에서 빈 값이 담기는 경우를 구분해야하므로)
interface BindProductsSearchPostRequestData extends Omit<ProductsSearchPostRequestData, 'stockRange'> {
  stockRange?: {
    type?: string;
    stockCnt?: number;
    minStockCnt?: number | string;
    maxStockCnt?: number | string;
  };
}

const duplicateCommaReplacer = string => string.replace(/[*,.*]{2,}/g, ',');
@Component({
  components: {
    BrandSelect,
    MerchandiserSelect,
    MemberGradeSelect,
    CategorySelector,
    SearchButton,
    MallSelect,
    DateRangePicker,
    SelectBox,
    TextInput,
    CheckBoxGroup,
    StockRangeInput,
    SearchKeyword,
    PartnerFinder,
  },
})
export default class SearchForm extends Vue {
  @Getter('partner/getPartnerNo') private readonly partnerNo: number;
  @Prop({ required: true }) private readonly selectedClassification!: TabState;
  private readonly searchFormOptions = searchFormOptions;
  @Prop({ default: null })
  private readonly fixedMallNo!: number | null;
  @Prop({ required: false })
  private readonly fixedPartnerNo: number;
  @Prop({ default: null })
  private readonly fixedApplyStatusType!: ApplyStatusType | null;
  @Prop({ default: null })
  private readonly queryLimitSaleStatus!: string | null;
  @Prop({ default: null })
  private readonly queryLimitSaleSettingStatus!: string | null;
  private sortBy = 'MALL_PRODUCT_NO,DESC';
  private saleStatus = 'WAITING_SALE, ON_PRE_SALE, ON_SALE, END_SALE';
  private saleSettingStatus = 'AVAILABLE_FOR_SALE, STOP_SELLING, PROHIBITION_SALE';
  @Ref()
  private readonly mallSelect!: MallSelect;
  @Ref()
  private readonly stockRangeInput!: StockRangeInput;
  @Ref()
  private readonly searchKeyword!: SearchKeyword;
  private usedAdvancedSearch = false;
  private params: BindProductsSearchPostRequestData = {} as BindProductsSearchPostRequestData;
  private mallNos = '';
  private partnerType: PartnerType;

  created() {
    this.params = getDefaultParams();
    if (!this.$route.query?.selectedClassification) {
      this.mallNos = '';
      this.params.mallNos = [Number(this.mallNos)];
    }
  }
  mounted() {
    if (this.fixedMallNo !== null) this.setFixedMallData(this.fixedMallNo, this.fixedPartnerNo);
    if (this.fixedApplyStatusType !== null) this.setFixedApplyStatusTypeData(this.fixedApplyStatusType);
    if (this.queryLimitSaleStatus !== null) this.setQueryLimitSaleStatus(this.queryLimitSaleStatus);
    if (this.queryLimitSaleSettingStatus !== null)
      this.setQueryLimitSaleSettingStatus(this.queryLimitSaleSettingStatus);
    this.parseSearchQuery();
  }

  @Watch('params.stockRange.minStockCnt')
  @Watch('params.stockRange.maxStockCnt')
  private convertStockRangeTypeForZeroOrEmptyCase(): void {
    const stockRangeInfo = this.params.stockRange;

    const isAllTypeCase =
      [stockRangeInfo.minStockCnt, stockRangeInfo.maxStockCnt].every(e => e === null || e === '') ||
      (stockRangeInfo.minStockCnt === 0 && stockRangeInfo.maxStockCnt === '');
    if (isAllTypeCase) {
      stockRangeInfo.type = 'ALL';
      return;
    }

    if (stockRangeInfo.maxStockCnt === 0) {
      stockRangeInfo.type = 'RANGE';
      return;
    }
  }

  private parseSearchQuery() {
    const queryBuffet = this.$route.query;
    if (queryBuffet['saleSettingStatus.types']) {
      this.saleSettingStatus = queryBuffet['saleSettingStatus.types'].toString();
    }
    if (queryBuffet['maxStockCnt'] !== undefined) {
      this.usedAdvancedSearch = true;
      this.params.stockRange.maxStockCnt = Number(queryBuffet['maxStockCnt']);
    }
    if (queryBuffet['minStockCnt'] !== undefined) {
      this.usedAdvancedSearch = true;
      this.params.stockRange.minStockCnt = Number(queryBuffet['minStockCnt']);
    }
    if (queryBuffet['stockRange.type'] !== undefined) {
      this.usedAdvancedSearch = true;
      this.params.stockRange.type = queryBuffet['stockRange.type'].toString();
    }
  }
  private setFixedMallData(mallNo: number, partnerNo?: number): void {
    this.params.mallNos = [mallNo];
    this.params.patnerNo = partnerNo;
    this.mallNos = mallNo.toString();
  }
  private setFixedApplyStatusTypeData(applyStatusType: ApplyStatusType) {
    this.params.applyStatusType = applyStatusType;
  }
  // 체크 항목 맵핑 & 패러미터 맵핑
  private setQueryLimitSaleStatus(saleStatus: string) {
    const values = saleStatus.split(',');
    this.searchFormOptions.saleStatus.data = this.searchFormOptions.saleStatus.data.filter(({ value }) => {
      if (values.length > 1) {
        return values.includes(value) || value === 'ALL';
      } else {
        return values.includes(value);
      }
    });
    if (values.length <= 1) this.searchFormOptions.saleStatus.hasAll = false;
    this.params.saleStatus.types = values;
  }
  // 체크 항목 맵핑 & 패러미터 맵핑
  private setQueryLimitSaleSettingStatus(saleSettingStatus: string) {
    const values = saleSettingStatus.split(',');
    this.searchFormOptions.saleSettingStatus.data = this.searchFormOptions.saleSettingStatus.data.filter(
      ({ value }) => {
        if (values.length > 1) {
          return values.includes(value) || value === 'ALL';
        } else {
          return values.includes(value);
        }
      },
    );
    if (values.length <= 1) this.searchFormOptions.saleSettingStatus.hasAll = false;
    this.params.saleSettingStatus.types = values;
  }

  // SummaryTab.vue에서 selectedClassification이 변경될 수 있음
  @Watch('selectedClassification')
  ChangedSelectedClassification(value: TabState): void {
    this.resetParams();

    // 쿼리에 startYmd, endYmd 을 설정하고 진입하는 리다이렉팅인 경우 핸들 (ex: 상품 현황에서 진입)
    const { startYmd, endYmd } = this.$route.query;
    this.params.periodInfo.period.startYmdt = startYmd ?? '';
    this.params.periodInfo.period.endYmdt = endYmd ?? '';

    switch (value) {
      case 'ALL':
        this.mallNos = '';
        this.saleStatus = 'WAITING_SALE, ON_PRE_SALE, ON_SALE, END_SALE';
        this.saleSettingStatus = 'AVAILABLE_FOR_SALE, STOP_SELLING, PROHIBITION_SALE';
        break;
      case 'ON_SALE':
        this.mallNos = '';
        this.saleStatus = 'ON_SALE';
        this.saleSettingStatus = 'AVAILABLE_FOR_SALE, STOP_SELLING, PROHIBITION_SALE';
        break;
      case 'SOLD_OUT':
        this.mallNos = '';
        this.saleStatus = 'WAITING_SALE, ON_PRE_SALE, ON_SALE, END_SALE';
        this.saleSettingStatus = 'AVAILABLE_FOR_SALE, STOP_SELLING';
        this.usedAdvancedSearch = true;
        this.params.stockRange.type = 'NONE';
        this.params.stockRange.stockCnt = 0;
        this.params.stockRange.minStockCnt = 0;
        this.params.stockRange.maxStockCnt = 0;
        break;
      case 'ON_SALE_SUMMARY':
        this.mallNos = '';
        this.saleStatus = 'ON_SALE';
        this.saleSettingStatus = 'AVAILABLE_FOR_SALE';
        break;
      case 'STOCK_UNDER_TEN':
        this.mallNos = '';
        this.saleStatus = 'ON_SALE';
        this.saleSettingStatus = 'AVAILABLE_FOR_SALE';
        this.usedAdvancedSearch = true;
        this.params.stockRange.minStockCnt = 0;
        this.params.stockRange.maxStockCnt = 10;
        break;
    }
    this.$nextTick(() => this.sendQueryUrl(this.params));
  }
  private resetParams(): void {
    this.params = getDefaultParams();
    this.mallNos = '';
    if (this.fixedMallNo) {
      this.mallNos = this.fixedMallNo.toString();
      this.params.mallNos = [this.fixedMallNo];
    }
    if (this.fixedApplyStatusType) {
      this.params.applyStatusType = this.fixedApplyStatusType;
    }
    this.searchKeyword.reset();
    this.saleStatus = 'WAITING_SALE, ON_PRE_SALE, ON_SALE, END_SALE';
    this.saleSettingStatus = 'AVAILABLE_FOR_SALE, STOP_SELLING, PROHIBITION_SALE';
    if (this.queryLimitSaleStatus !== null) {
      this.saleStatus = this.queryLimitSaleStatus;
      this.params.saleStatus.types = this.saleStatus.split(',').filter(Boolean);
    }
    if (this.queryLimitSaleSettingStatus !== null) {
      this.saleSettingStatus = this.queryLimitSaleSettingStatus;
      this.params.saleSettingStatus.types = this.queryLimitSaleSettingStatus.split(',').filter(Boolean);
    }
    this.usedAdvancedSearch = false;
    this.sortBy = 'MALL_PRODUCT_NO,DESC';
  }
  private replaceDuplicateComma(value: string): string {
    const result = duplicateCommaReplacer(value);
    if (SearchForm.overTheKeywordLimit(result, 10)) {
      alert(this.$t('PRODUCT.SEARCH.ALERT_KEYWORD_LIMIT_OVER'));
      return result.substring(0, value.length - 1);
    }
    return result;
  }
  private static overTheKeywordLimit(value: string, limit = 10): boolean {
    const trim = duplicateCommaReplacer(value.replace(/\n/g, ',').replace(/\s/g, ''));
    const keywords = trim.split(',');
    return keywords.length > limit;
  }
  private resetPeriod(): void {
    this.params.periodInfo.period.startYmdt = addMonth(new Date(), -3);
    this.params.periodInfo.period.endYmdt = getToday();
  }
  private changeMallNos(mallNos: string) {
    this.params.mallNos = mallNos.split(',').map(Number);
  }
  @Watch('partnerNo')
  private changePartnerNo() {
    this.params.partnerNo = this.partnerNo;
  }
  private changeKeywords(keywords: string): void {
    this.params.keywordInfo.keywords = keywords
      .split(',')
      .map(keyword => keyword.trim())
      .filter(Boolean);
  }
  private changeApplyStatus(applyStatus: ApplyStatusType) {
    if (BEFORE_APPROVAL_STATUS.includes(applyStatus)) {
      this.saleStatus = '';
      this.saleSettingStatus = '';
      return;
    } else {
      this.saleStatus = 'WAITING_SALE, ON_PRE_SALE, ON_SALE, END_SALE';
      this.saleSettingStatus = 'AVAILABLE_FOR_SALE, STOP_SELLING, PROHIBITION_SALE';
    }
  }
  private changeDeliverable() {
    this.params.deliveryFeeType = 'ALL';
    this.params.allowsShippingTogether = 'ALL';
  }
  @Watch('saleStatus')
  private changeSaleStatus() {
    this.params.saleStatus.types = this.saleStatus
      .split(',')
      .filter(Boolean)
      .map(e => e.trim());
  }
  @Watch('saleSettingStatus')
  private changeSaleSettingStatus() {
    this.params.saleSettingStatus.types = this.saleSettingStatus
      .split(',')
      .filter(Boolean)
      .map(e => e.trim());
    // 이 경우엔 요청시 this.params.saleStatus 제거
    if (this.saleSettingStatus === 'PROHIBITION_SALE') {
      this.saleStatus = '';
      return;
    }
  }
  get disabledSaleStatus(): boolean {
    if (BEFORE_APPROVAL_STATUS.includes(this.params.applyStatusType)) {
      return true;
    }
    if (this.saleSettingStatus === 'PROHIBITION_SALE') {
      return true;
    }
    return false;
  }
  get disabledSaleSettingStatus(): boolean {
    return BEFORE_APPROVAL_STATUS.includes(this.params.applyStatusType);
  }
  private separateSortBy(arrayLikedValue: string): void {
    const tuple = arrayLikedValue.split(',');
    if (tuple.length !== 2) throw new Error('arrayLikedValue must be contain one comma');
    const [criterion, direction] = tuple;
    this.params.sort.sortDirection = direction as SortDirection;
    this.params.sort.sortCriterion = criterion as SortCriterion;
  }
  public beforeSendQueryValidation(): boolean {
    if (!this.usedAdvancedSearch || !this.stockRangeInput) return true;
    return this.stockRangeInput.validation();
  }
  public sendQueryUrl(searchRequestData?: BindProductsSearchPostRequestData) {
    const query = searchRequestData ? searchRequestData : { ...this.params };

    const unValid = !this.beforeSendQueryValidation();
    if (unValid) return;

    let params = stringify(query);
    const prevParams = stringify(this.$route.query);
    // stringify 비교라 오류 있을 수 있음
    if (params === prevParams) {
      params += `&dataRefresh=${Date.now()}`;
    }

    this.$router.replace(this.$route.path + '?' + params);
  }
  get searchInputValidType(): TextInputValidationRegex | '' {
    if (this.params.keywordInfo.type === 'PRODUCT_NAME') return '';
    if (this.params.keywordInfo.type === 'MALL_PRODUCT_NO') return 'productNos';
    if (this.params.keywordInfo.type === 'PRODUCT_MANAGEMENT_CD') return 'no-common-special-code';
    return '';
  }
}
