




import { Vue, Component } from 'vue-property-decorator';
@Component
export default class BasicInfo extends Vue {
  created() {
    console.log("BASICINFO's TOP ");
  }
}
