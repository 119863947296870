




































import Grid from '@/components/common/grid/Main.vue';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { GridEventProps } from '@/types';
import { throwWindowPopup, PopupClose, PopupResult } from '@/helpers/popup';
import { getCouponManagementGridOption } from '@/const/contents/couponManagement';
import { addMonth, getToday } from '@/utils/dateFormat';
import { getCouponManagementSelectType, getCouponManagementSearchDateType } from '@/const/contents/couponManagement';
import {
  GetCouponsIssuePlansRequest,
  CouponIssueSearchDateType,
  CouponIssueSearchType,
  CouponIssueTimeType,
  CouponIssueConditionTargetType,
  Mall,
} from 'ncp-api-supporter';
import moment from 'moment';
import { Getter } from 'vuex-class';
import { CellValue } from '@/types/tui-grid';
import { OptColumn } from '@/types/tui-grid/options';
@Component({
  components: {
    Grid,
  },
})
export default class CouponManagement extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  private showMalls: Mall[] = [];
  private contents = [];
  private totalCount = 0;
  private gridProps = getCouponManagementGridOption();
  private searchParams = {};
  @Watch('$route')
  private onQueryStringChanged() {
    this.search();
  }

  private created() {
    // this.search();
  }

  private search(): void {
    const query = {
      mallNos: '',
      searchType: getCouponManagementSelectType().theSearchType[0].value,
      keyword: '',
      searchDateType: getCouponManagementSearchDateType().thePeriodType[0].value,
      payType: 'ALL',
      birthDay: false,
      signUpDate: false,
      statusAll: true,
      statusReady: true,
      statusCancel: true,
      statusIng: true,
      statusEnd: true,
      startYmdt: addMonth(moment().toDate(), -3),
      endYmdt: getToday(),
      page: 1,
      pageSize: 30,
      hasNotIssuableCoupon: false,
    };

    Object.assign(query, this.$route.query);

    const requestQuery: GetCouponsIssuePlansRequest = {
      params: {
        page: 1,
        size: 30,
        endYmdt: '',
        startYmdt: '',
        mallNos: '',
        searchDateType: 'REGISTER',
        hasNotIssuableCoupon: false,
      },
    };

    requestQuery.params.page = query.page;
    requestQuery.params.size = query.pageSize;
    requestQuery.params.couponIssueStatusTypes = this.convertStatusTypes(
      query.statusAll,
      query.statusReady,
      query.statusCancel,
      query.statusIng,
      query.statusEnd,
    );
    requestQuery.params.endYmdt = query.endYmdt + ' 23:59:59';
    requestQuery.params.keyword = query.keyword;
    requestQuery.params.mallNos = this.convertMalls(query.mallNos);
    requestQuery.params.searchDateType = query.searchDateType as CouponIssueSearchDateType;
    requestQuery.params.searchType = query.searchType as CouponIssueSearchType;
    requestQuery.params.startYmdt = query.startYmdt + ' 00:00:00';
    if (query.payType != 'ALL') {
      requestQuery.params.timeType = query.payType as CouponIssueTimeType;
    } else {
      requestQuery.params.timeType = '' as CouponIssueTimeType;
    }
    let conditionTargetType = '';
    if (query.payType.toString() === 'PERIOD_ISSUE') {
      if (query.birthDay.toString() == 'true') {
        conditionTargetType = 'BIRTHDAY';
      }
      if (query.signUpDate.toString() == 'true') {
        conditionTargetType = 'JOIN,' + conditionTargetType;
      }
    }
    requestQuery.params.conditionTargetType = conditionTargetType as CouponIssueConditionTargetType;
    requestQuery.params.hasNotIssuableCoupon = query.hasNotIssuableCoupon.toString() === 'true';
    this.searchParams = requestQuery;
    this.$api.getCouponsIssuePlans(requestQuery).then(response => {
      if (response && response.status === 200) {
        this.contents = response.data.contents;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  private convertMalls(no: string): string {
    if (no == 'ALL') {
      const malls: string[] = [];
      this.malls.forEach(item => {
        malls.push(item.mallNo.toString());
      });
      return malls.join(',');
    } else {
      return no;
    }
  }

  private convertStatusTypes(all: boolean, ready: boolean, cancel: boolean, ing: boolean, end: boolean): string {
    let couponIssueStatusTypes = '';
    if (all.toString() == 'true') {
      couponIssueStatusTypes = 'ISSUE_READY,ISSUE_STOP,ISSUE_ING,ISSUE_END';
    } else {
      if (ready.toString() == 'true') {
        if (couponIssueStatusTypes == '') {
          couponIssueStatusTypes = 'ISSUE_READY';
        } else {
          couponIssueStatusTypes = couponIssueStatusTypes + ',ISSUE_READY';
        }
      }

      if (cancel.toString() == 'true') {
        if (couponIssueStatusTypes == '') {
          couponIssueStatusTypes = 'ISSUE_STOP';
        } else {
          couponIssueStatusTypes = couponIssueStatusTypes + ',ISSUE_STOP';
        }
      }

      if (ing.toString() == 'true') {
        if (couponIssueStatusTypes == '') {
          couponIssueStatusTypes = 'ISSUE_ING';
        } else {
          couponIssueStatusTypes = couponIssueStatusTypes + ',ISSUE_ING';
        }
      }

      if (end.toString() == 'true') {
        if (couponIssueStatusTypes == '') {
          couponIssueStatusTypes = 'ISSUE_END';
        } else {
          couponIssueStatusTypes = couponIssueStatusTypes + ',ISSUE_END';
        }
      }
    }

    return couponIssueStatusTypes;
  }
  private onClickAdd() {
    this.openCouponIssue('add', '');
  }

  private openCouponPayStatus(getGridValue: (targetKey: OptColumn['name']) => CellValue) {
    const issueCount = Number(getGridValue('issueCount'));
    const issueFailCount = Number(getGridValue('issueFailCount'));
    const totalIssueCount = issueCount + issueFailCount;
    if (!totalIssueCount) return;
    throwWindowPopup(
      'CouponPayStatusPopup',
      {
        couponIssuePlanNo: getGridValue('no').toString(),
        totalIssueCount,
      },
      'md',
      undefined,
      getGridValue('no').toString(),
    );
  }
  private onItemClicked(gridProps: GridEventProps): void {
    const getGridValue = (targetKey: OptColumn['name']) => gridProps.instance.getValue(gridProps.rowKey, targetKey);

    switch (gridProps.columnName) {
      case 'no':
        this.openCouponIssue('edit', getGridValue('mallNo').toString(), gridProps.rowKey.toString());
        break;
      case 'copy':
        this.openCouponIssue('copy', getGridValue('mallNo').toString(), gridProps.rowKey.toString());
        break;
      case 'issueCount':
        this.openCouponPayStatus(getGridValue);
        break;
      case 'issueStatusType':
        if (getGridValue('issueStatusType').toString() === 'ISSUE_READY') {
          if (confirm(this.$t('PROMOTION.COUPON_ISSUE.CONFIRM1').toString())) {
            const obj = {
              pathParams: {
                couponIssuePlanNo: gridProps.instance.getValue(gridProps.rowKey, 'no').toString(),
              },
            };
            this.$api.putCouponsIssuePlansTerminate(obj).then(() => {
              alert(this.$t('PROMOTION.ADDFORM.ISSUE_CANCELED'));
              location.reload();
            });
          }
        } else if (gridProps.instance.getValue(gridProps.rowKey, 'issueStatusType').toString() == 'ISSUE_ING') {
          if (confirm(this.$t('PROMOTION.COUPON_ISSUE.CONFIRM2').toString())) {
            const obj = {
              pathParams: {
                couponIssuePlanNo: gridProps.instance.getValue(gridProps.rowKey, 'no').toString(),
              },
            };
            this.$api.putCouponsIssuePlansTerminate(obj).then(() => {
              alert(this.$t('PROMOTION.ADDFORM.ISSUE_CANCELED'));
              location.reload();
            });
          }
        }
        break;
    }
  }

  private openCouponIssue(type: string, mallNo: string, rowKey?: string) {
    const popupData = {
      mallNo: mallNo,
      type: type, // 팝업 타입(회원 등급 변경, 회원 강제탈퇴)
      rowKey: rowKey,
    };

    throwWindowPopup(
      'CouponManagementPopup',
      popupData,
      'md',
      ({ state }: PopupResult) => {
        if (state !== PopupClose.CONFIRM) return;
        location.reload();
      },
      rowKey + mallNo + type,
    );
  }
}
