























































































import { Component } from 'vue-property-decorator';
import { RadioBoxGroupOption } from '@/helpers/type';
import { WithholdType } from 'ncp-api-supporter';
import { claimTypes } from '@/const/claim';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { formatCurrency, getOnlyNumbers } from '@/utils/numberFormat';

interface Form {
  withholdType: WithholdType;
  holdDetailReason?: string;
  returnDeliveryAdjustAmt?: string;
}

@Component({ components: { RadioGroup, SelectBox, TextInput } })
export default class NaverPayReturnHold extends WindowPopupMainVue {
  private isAdjustAmountExist = false;

  private withholdTypeSelects = [];
  private adjustAmountRadioOptions: RadioBoxGroupOption<boolean> = {
    name: 'genderOption',
    nonI18nConvert: true,
    data: [
      {
        id: 'status_ALL',
        value: false,
        display: this.$t('입력안함'),
      },
      {
        id: 'status_ISSUED',
        value: true,
        display: this.$t('입력함'),
      },
    ],
  };

  private formData: Form = {
    withholdType: 'RETURN_DELIVERYFEE',
    holdDetailReason: '',
    returnDeliveryAdjustAmt: '0',
  };

  onApprove() {
    if (!confirm(this.$t(`CLAIM.MESSAGE.ASK_${this.data.claimType}_WITH_HOLD`).toString())) {
      return false;
    }

    const { withholdType, holdDetailReason, returnDeliveryAdjustAmt } = this.formData;
    const request = {
      pathParams: {
        no: this.data.claimNo,
      },
      data: {
        withholdType,
        holdDetailReason,
        returnDeliveryAdjustAmt: this.isAdjustAmountExist ? getOnlyNumbers(returnDeliveryAdjustAmt) : 0, // TODO: 숫자타입임
      },
    };

    const approveWithHold = {
      EXCHANGE: () => this.$api.putClaimsNoNaverPayExchangeWithhold(request),
      RETURN: () => this.$api.putClaimsNoNaverPayReturnsWithhold(request),
    };

    approveWithHold[this.data.claimType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_REQUEST'));
      this.onPositiveClick();
    });
  }

  created() {
    this.setWithholdTypeSelects();
  }

  private setWithholdTypeSelects() {
    const returnSelects = [
      {
        name: '반품 배송비 청구',
        value: 'RETURN_DELIVERYFEE',
      },
      {
        name: '기타 반품 비용 청구',
        value: 'EXTRAFEEE',
      },
      {
        name: '반품 배송비 및 기타 반품 비용 청구',
        value: 'RETURN_DELIVERYFEE_AND_EXTRAFEEE',
      },
      {
        name: '반품 상품 미입고',
        value: 'RETURN_PRODUCT_NOT_DELIVERED',
      },
      {
        name: '기타 사유',
        value: 'ETC',
      },
    ];

    // 교환, 반품에 따라 셀렉박스 출력이 다름.
    const exchangeSelects = [
      {
        name: '교환 배송비 청구',
        value: 'EXCHANGE_DELIVERYFEE',
      },
      {
        name: '기타 교환 비용 청구',
        value: 'EXCHANGE_EXTRAFEE',
      },
      {
        name: '교환 상품 준비 중',
        value: 'EXCHANGE_PRODUCT_READY',
      },
      {
        name: '교환 상품 미입고',
        value: 'EXCHANGE_PRODUCT_NOT_DELIVERED',
      },
      {
        name: '기타 사유',
        value: 'ETC',
      },
    ];

    this.formData.withholdType = this.isReturnClaim ? 'RETURN_DELIVERYFEE' : 'EXCHANGE_DELIVERYFEE';
    this.withholdTypeSelects = this.isReturnClaim ? [...returnSelects] : [...exchangeSelects];
  }

  private get isReturnClaim() {
    return this.data.claimType === claimTypes.RETURN;
  }

  private onChangeReasonType(value) {
    const adjustTargetReasons = ['EXCHANGE_EXTRAFEE', 'EXTRAFEEE', 'RETURN_DELIVERYFEE_AND_EXTRAFEEE'];
    this.isAdjustAmountExist = adjustTargetReasons.includes(value);
  }

  private onChangeRefundHoldProposedAmt(refundHoldProposedAmt: string): string {
    return formatCurrency(getOnlyNumbers(refundHoldProposedAmt));
  }
}
