import { i18n } from '@/main';

const securityPath = 'CONFIGURATION.SECURITY.';

export const i18nSecurity = (message: string, key = securityPath): string => {
  return i18n.t(key + message).toString();
};

export enum AUTH_SECURITY {
  LOGIN = 'LOGIN',
  DISPLAY = 'DISPLAY',
}

export enum AUTH_METHOD {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  OTP = 'OTP',
}

export const getOperateSecurityCheckBoxOption = () => {
  const authMethod = {
    hasAll: false,
    noBrackets: true,
    data: [
      {
        id: AUTH_METHOD.EMAIL,
        value: AUTH_METHOD.EMAIL,
        display: securityPath + 'EMAIL_AUTH',
      },
      {
        id: AUTH_METHOD.SMS,
        value: AUTH_METHOD.SMS,
        display: securityPath + 'SMS_AUTH',
      },
      {
        id: AUTH_METHOD.OTP,
        value: AUTH_METHOD.OTP,
        display: securityPath + 'OTP_AUTH',
      },
    ],
  };

  return {
    authSecurity: {
      name: 'AuthSecurityCheckBox',
      hasAll: false,
      noBrackets: true,
      data: [
        {
          id: AUTH_SECURITY.LOGIN,
          value: AUTH_SECURITY.LOGIN,
          display: securityPath + 'USE_OF_SECURE_LOGIN',
        },
        // {
        //   id: AUTH_SECURITY.DISPLAY,
        //   value: AUTH_SECURITY.DISPLAY,
        //   display: securityPath + 'USE_OF_SCREEN_SECURE',
        // },
      ],
    },
    authMethodFromSecurity: {
      ...authMethod,
      data: [
        {
          id: AUTH_METHOD.OTP,
          value: AUTH_METHOD.OTP,
          display: securityPath + 'OTP_AUTH',
        },
      ],
      name: 'AuthMethodFromSecurityCheckBox',
    },
    securityScope: {
      name: 'SecurityScope',
      hasAll: false,
      noBrackets: true,
      data: [
        {
          id: 'setting',
          value: 'setting',
          display: 'SETTING',
        },
        {
          id: 'order',
          value: 'order',
          display: 'SHIPPING',
        },
        {
          id: 'member',
          value: 'member',
          display: 'MEMBER',
        },
        {
          id: 'board',
          value: 'board',
          display: 'BOARD',
        },
        {
          id: 'promotion',
          value: 'promotion',
          display: 'PROMOTION',
        },
      ],
    },

    authMethodFromExcel: {
      ...authMethod,
      name: 'AuthMethodFromExcelCheckBox',
    },
  };
};
export const getOperateSecurityRadioBoxOption = () => {
  return {
    accessRestrict: {
      name: 'radio-access-restrict',
      data: [
        { id: 'use-access-restrict', value: true, display: 'USED' },
        { id: 'not-use-access-restrict', value: false, display: 'NOT_USED' },
      ],
    },
    excelDownloadSecurity: {
      name: 'radio-excel-download-security',
      data: [
        { id: 'use-excel-download-security', value: true, display: 'USED' },
        { id: 'not-use-excel-download-security', value: false, display: 'NOT_USED' },
      ],
    },
    mallAccessRestrict: {
      name: 'radio-mall-access-restrict',
      data: [
        { id: 'use-mall-access-restrict', value: true, display: 'USED' },
        { id: 'not-use-mall-access-restrict', value: false, display: 'NOT_USED' },
      ],
    },
  };
};
export const getOperateSecurityToolTipOption = () => {
  return {
    authSecurity: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        subMessage: i18nSecurity('SECURITY_AUTH_WHETHER_DIALOGUES').split('\n'),
        mainTitle: i18nSecurity('SCREEN_SECURITY_SETTING_PAGE'),
        mainMessage: i18nSecurity('SCREEN_SECURITY_SETTING_PAGE_LIST').split('\n'),
      },
    },
    authMethodFromSecurity: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        mainMessage: i18nSecurity('AUTH_METHOD_DIALOGUES').split('\n'),
      },
    },
    registerAccessIp: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        mainMessage: i18nSecurity('IP_REGISTRATION_FOR_ADMINISTRATOR_ACCESS_DIALOGUES').split('\n'),
      },
    },
    accessRestrictByCountry: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        subMessage: i18nSecurity('ACCESS_RESTRICTIONS_BY_COUNTRY_SETTING_DIALOGUES').split('\n'),
      },
    },
    excelDownloadSecurity: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        mainMessage: i18nSecurity('EXCEL_DOWNLOAD_SECURITY_WHETHER_DIALOGUES').split('\n'),
      },
    },
    securityScope: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        subMessage: [i18nSecurity('DOWNLOAD_SECURITY_WHETHER_DIALOGUE')],
        mainTitle: i18nSecurity('SECURITY_SCOPE_APPLICATION_MENU'),
        mainMessage: i18nSecurity('SECURITY_SCOPE_APPLICATION_MENU_LIST').split('\n'),
      },
    },
    authMethodFromExcel: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        mainMessage: i18nSecurity('EXCEL_DOWNLOAD_AUTH_METHOD_DIALOGUES'),
      },
    },
    mallRegisterAccessIp: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        mainMessage: i18nSecurity('MALL_IP_ACCESS_RESTRICTION_ADD_DIALOGUES').split('\n'),
      },
    },
  };
};
