import { api } from '@/api';
import router from '@/router';
import { CampaignsStatus, GetCampaignsStatusRequest } from 'ncp-api-supporter';

function goGoogleAd(mallNo: number) {
  router.push({ name: 'GoogleAd', query: { mallNo: mallNo.toString() } });
}

function validateCampaignRequired(data: CampaignsStatus): boolean {
  if (data?.campaign?.isRequiredSnsCertification !== data?.campaign?.isRequiredClaimWebsite) {
    return false;
  } else {
    return true;
  }
}

export const getCampaigns = async (mallNo: number) => {
  const request: GetCampaignsStatusRequest = {
    params: {
      channelType: 'GOOGLE',
      mallNo,
    },
  };

  try {
    const { data } = await api.getCampaignsStatus(request);

    if (!data.campaign) goGoogleAd(mallNo);
    if (!validateCampaignRequired(data)) goGoogleAd(mallNo);

    return {
      getCampaigns: true,
      status: data.campaign.status,
      hasCampaign: data.campaign.hasCampaign,
    };
  } catch (e) {
    goGoogleAd(mallNo);
  }
};
