



















































import { Vue, Component, PropSync, Ref, Prop } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { DateRangeOption, ProductDescInfo } from '@/types';
import { RadioBoxGroupOption } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';
import { today } from '@/views/contents/product/basic/ProductAdd';
@Component({
  components: { ToolTip, RadioGroup, TextInput, DateRangePicker },
})
export default class ProductDesc extends Vue {
  @PropSync('productDescInfo', { required: true })
  private productDescInfoSync: ProductDescInfo;

  @PropSync('productDescModel', { required: true })
  private productDescModelSync: string;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private tooltipMsg = `<li>${this.$t('PRODUCT.ADD.PRODUCT_DESC_TOOLTIP_1')}</li>
                    <li>${this.$t('PRODUCT.ADD.PRODUCT_DESC_TOOLTIP_2')}</li>
                    <li>${this.$t('PRODUCT.ADD.PRODUCT_DESC_TOOLTIP_3')}</li>`;

  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'productDescYn',
    data: [
      {
        id: 'N',
        name: 'productDescYn',
        value: 'N',
        display: 'PRODUCT.COMMON.NOT_USE',
      },
      {
        id: 'Y',
        name: 'productDescYn',
        value: 'Y',
        display: 'PRODUCT.COMMON.USE',
      },
    ],
  };

  @Ref() private readonly productDescRef: TextInput;
  public focus(): void {
    this.productDescRef.focus();
  }

  private exposeDateRangeOption: DateRangeOption = {
    name: 'exposePeriod',
    hasPeriodBtn: false,
    fromRanges: today,
  };

  private onChangedProductDesc(val: string): string {
    // this.productDescInfoSync.text = escapeHtml(val);
    this.productDescInfoSync.text = val;
    return val;
  }

  // private productDesc = '';
  reset(): void {
    this.productDescModelSync = '';
  }
}
