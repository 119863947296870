export default Object.freeze({
  TITLE: '파트너 수정',
  TABLE_TITLE: '파트너사 정보',
  TABLE_TITLE_DISCLAIMER: '* 필수입력',
  TRADE_BANK_ACCOUNT_EDIT: '계좌정보수정',

  EXTERNAL_ACCESS: '외부접속',
  EXTERNAL_ACCESS_Y: '가능',
  EXTERNAL_ACCESS_N: '불가',
  EXTERNAL_ACCESS_GUIDE: '※ 외부접속 시 운영자 휴대폰 SMS 인증 절차를 실시합니다.',

  IP_TITLE: '접속 IP 설정',
  ADD_IP: 'IP 주소 등록',
  ADD_IP_GUIDE: 'IP 주소 등록안내',
  ADD_IP_GUIDE_P1: '현재 귀하의 회사 내부에서 사용중인 IP주소를 확인하시고 등록 해 주세요.',
  ADD_IP_GUIDE_P2:
    '운영자 접속 시 IP주소를 통해 회사 내부와 외부를 구분하여 외부접속일 경우 SMS 추가인증을 실시합니다.',
  ADD_IP_GUIDE_P3: '(등록요령)',
  ADD_IP_GUIDE_P4: '여러 개의 IP주소 등록 시 엔터 키 입력으로 구분 해 주세요.',
  ADD_IP_GUIDE_P5: 'IP 주소 대역으로 등록하시려면 - 기호로 구분 해 주세요. Ex)',
  SAMPLE_IP: ' 192.168.10.1-255',

  PRIVACY_MANAGER_TITLE: '개인정보 관리 책임자 정보',
  PRIVACY_MANAGER_NAME: '담당자명',
  PRIVACY_MANAGER_PHONE_NO: '전화번호',

  SETTLEMENT_MANAGER_TITLE: '정산관련 정보',

  MANAGER_INFO: '담당자 정보',
  MANAGER_NAME_JOB_POSITION: '담당자명/직급',
  MANAGER_JOB_DUTY_DEPARTMENT: '담당자 직책/부서',

  SAMPLE_URL_TITLE: '샘플 URL',

  SHOPPING_MALL_TITLE: '거래 쇼핑몰',

  NO: 'No.',
  SAVE: '변경사항 저장',
  DELETE: '파트너 삭제',
  NOTICE_WAITING_TITLE: '파트너사 담당자가 현재 아래 정보를 입력하고 있습니다. 잠시만 기다려주세요.',
  NOTICE_INVESTIGATION_TITLE:
    '아래 내용을 검토하신 후, 검토 완료를 클릭하시면 파트너 마스터 계정의 로그인이 가능해집니다.',
  CANCEL_NOTICE_MSG: '파트너 수정을 취소 하시겠습니까? 수정하신 내용은 저장되지 않습니다.',

  MALL_NAME: '쇼핑몰명',
  CHARGE_MD_NAME: '담당MD',
  USE_DEFAULT_COMMISSION_RATE: '기본수수료 사용',
  COMMISSION_RATE: '수수료율',
  AGREE_PROMOTION: '프로모션동의',
  CONTRACT_STATUS: '거래상태',
  CONTRACT_FILE: '계약 서류',
  CONTRACT_FILE_UPDATE_HISTORY: '계약 서류 변경 이력',
  STANDING_POINT_CONTRACT: '입점 계약서',
  START_YMDT: '거래일시',
  EXTERNAL_API_KEY: '외부시스템 연동코드',

  APPROVAL: '검토완료',
  APPROVAL_NOTICE_MSG: '검토완료 하실 경우 해당 파트너의 상품 등록이 가능해집니다.\n검토를 완료하시겠습니까?',
  APPROVAL_MSG: '검토완료되었습니다!',
  DISAPPROVAL: '반려',
  DISAPPROVAL_NOTICE_MSG: '반려하시면 파트너사 담당자에게 정보 재등록을 요청하는 이메일이 전송됩니다.',
  DISAPPROVAL_MSG: '반려되었습니다.',
  ACTIVE: '거래중',
  INVESTIGATION: '검토중',
  WAITING: '거래대기',
  SUSPEND: '일시중지',

  USE: '사용함',
  NO_USE: '사용안함',
  AGREED: '동의함',
  DISAGREED: '동의 안 함',

  RESEND_EMAIL: 'e메일 재 발송',
  NOTICE_RESEND_EMAIL: 'e메일 발송이 완료 되었습니다.',
  CHECK_CONTRACT: '보기',
  STANDING_POINT_CONTRACT_CANCEL: '계약등록취소',
  STANDING_POINT_CONTRACT_POPUP: '계약내용보기',
  STANDING_POINT_CONTRACT_CHANGE: '계약내용변경',
  STANDING_POINT_CONTRACT_CHANGE_CANCEL: '계약내용변경취소',
  STANDING_POINT_CONTRACT_ADD: '계약등록',

  INPUT_ERROR_MSG_WITH_CONSONANT: '을 입력하세요.',
  INPUT_ERROR_MSG_WITHOUT_CONSONANT: '를 입력하세요.',
  CONFIRM_DELETE_PARTNER: '파트너를 삭제 하시겠습니까?\n삭제 시 복구가 불가합니다.',
  CONFIRM_DELETE_MALL: '거래쇼핑몰을 삭제하시겠습니까?\n삭제 시 복구가 불가합니다.',

  CONFIRM_SAVE: '변경된 내용을 저장하시겠습니까?',
  NOTICE_SAVED: '변경사항이 저장되었습니다.',

  ALERT_NO_PARTNER_NAME: '파트너명을 입력하세요.',
  ALERT_NO_COMPANY_NAME: '회사명을 입력하세요.',
  ALERT_NO_BUSINESS_REGISTRATION_NO: '사업자 등록번호를 입력하세요.',
  ALERT_NO_REPRESENTATIVE_NAME: '대표자명을 입력하세요.',
  ALERT_NO_BUSINESS_TYPE: '업종을 입력하세요.',
  ALERT_NO_BUSINESS_CONDITION: '업태를 입력하세요.',
  ALERT_NO_REPRESENTATIVE_PHONE_NO: '대표전화번호를 입력하세요.',
  ALERT_NO_REPRESENTATIVE_EMAIL: '대표 email을 입력하세요.',

  ALERT_NO_SETTLEMENT_MANAGE_NAME: '담당자명을 입력하세요.',
  ALERT_NO_SETTLEMENT_MANAGE_PHONE_NO: '담당자 전화번호를 입력하세요.',
  ALERT_NO_SETTLEMENT_MANAGE_EMAIL: '담당자 email을 입력하세요.',
  ALERT_NO_TRADE_BANK_ACCOUNT: '담장자 계좌정보를 입력하세요.',
  ALERT_NO_TRADE_BANK_DEPOSITOR_NAME: '담장자 예금주명을 입력하세요.',

  ALERT_NO_MASTER_ADMIN_JOB_POSITION_NAME: '대표운영자 직급을 입력하세요.',
  ALERT_NO_MASTER_ADMIN_PHONE: '대표운영자 전화번호를 입력하세요.',
  ALERT_NO_MASTER_ADMIN_MOBILE: '대표운영자 휴대폰번호를 입력하세요.',
  ALERT_NO_MASTER_PERMITTED_IP_ADDRESS: '운영자 접속가능 IP를 입력해주세요.',

  PLZ_CHECK_PERMITTED_IP_ADDRESS: '운영자 접속가능 IP를 확인해주세요.',
  
  ERROR_ALERT: '입력형식이 잘못되었습니다.',
  ALERT_NO_COMMISSION_RATE: '수수료율을 입력해주세요.',
  CUSTOMER_CS_INFO: '고객 CS 운영자 정보',
  CS_TIME: 'CS 운영시간',
  CS_PHONE_NO_ALERT: '입력 내용이 올바르지 않습니다. 담당자의 전화번호 형식을 확인해주세요.',
  CS_EMAIL_ALERT: '입력 내용이 올바르지 않습니다. 담당자의 이메일 형식을 확인해주세요.'
});
