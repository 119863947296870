









































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { throwExternalWindowPopup, throwWindowPopup } from '@/helpers/popup';
import TextInput from '@/components/common/input/TextInput.vue';
import { putOrderOrdererRequest } from 'ncp-api-supporter/dist/types';
@Component({
  components: { TextInput },
})
export default class OrderOrdererInfo extends Vue {
  @Prop({ default: () => [] }) public memberInfo;
  @Prop({ default: false }) public memberInfoChange;

  private get orderNo(): string {
    return `${this.$route.query.orderNo}`;
  }

  public onClickOrdererPopup(): void {
    throwExternalWindowPopup(`/pro/crm/${this.memberInfo.memberNo}`, 'xlg');
  }
  public handleReceiverSms(receiverContact: string, mallNo: string) {
    // SMS 발송 팝업창
    throwWindowPopup('SmsSendManager', { phoneNos: [receiverContact] }, 'xlg', data => console.log(data), null, {
      mallNo,
    });
  }

  private async issuePreviousOrderTemporaryPassword() {
    if (!confirm(`${this.$t('ORDER.ORDER_DETAIL.CONFIRM_TO_ISSUE_TEMPORARY_PASSWORD')}`)) {
      return;
    }

    const temporaryPassword = await this.getTemporaryPassword();

    alert(`${this.$t('ORDER.ORDER_DETAIL.ALERT_TEMPORARY_PASSWORD', { temporaryPassword })}`);
  }

  private async getTemporaryPassword(): Promise<string> {
    const pathParams = {
      orderNo: this.orderNo,
    };

    // 이전주문의 경우 일반 주문과 orderNo 를 공유하지 않기 때문에 이전주문 임시 비밀번호 발급은 전용 api 를 호출해야함
    const { data } = await this.$api.putPreviousOrdersOrderNoTemporaryPassword({ pathParams });
    return data.initializedPassword;
  }

  public handleReceiverEmail() {
    if (confirm(this.$t('ORDER.ORDER_DETAIL.CONFIRM_4').toString())) {
      const request = {
        params: {
          orderNo: this.$route.query.orderNo as string,
        },
      };
      this.$api.putOrderInitializePassword(request).then(res => {
        const message = this.$t('ORDER.ORDER_DETAIL.ALERT_17')
          .toString()
          .replace('{%n}', res.data.initializedPassword.toString());
        alert(message);
      });
    }
  }
  public handleMemberInfoConfirm() {
    if (this.validation()) {
      const request: putOrderOrdererRequest = {
        params: {
          orderNo: this.$route.query.orderNo as string,
        },
        data: {
          name: this.memberInfo.name,
          contact1: this.memberInfo.contact1,
          email: this.memberInfo.email1 + '@' + this.memberInfo.email2,
          orderMemo: this.memberInfo.orderMemo,
        },
      };
      this.$api.putOrderOrderer(request).then(() => {
        alert(this.$t('SAVE_SUCCESS'));
        this.$emit('handleMemberInfoChange', false);
      });
    }
  }
  public validation() {
    if (this.memberInfo.name === '') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_INSERT_NAME'));
      return false;
    }
    if (this.memberInfo.contact1 === '') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_INSERT_MOBILE'));
      return false;
    }
    if (this.memberInfo.email1 === '' || this.memberInfo.email2 === '') {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_INSERT_EMAIL'));
      return false;
    }
    return true;
  }

  private get hasEmail() {
    return this.memberInfo.email1 !== null;
  }
}
