export default Object.freeze({
  COUNTRY: '국가',
  ADDRESS: '주소',
  BUSINESS_INFO: '사업자 기본정보',
  PARTNER_NAME: '파트너명',
  REPRESENT_EMAIL: '대표 e메일',
  SETTLEMENT: '담당자',
  SETTLEMENT_INFO: '정산관련 정보',
  SETTLEMENT_NAME: '담당자 이름',
  SETTLEMENT_PHONE_NO: '담당자 전화번호',
  SETTLEMENT_EMAIL: 'e메일 주소 / 전화번호',
  TRADE_BANK: '계좌정보',
  TRADE_BANK_NAME: '예금주명',
  ADMIN_INFO: '마스터 운영자 정보',
  ADMIN_ID: '마스터계정 ID',
  ADMIN_EMAIL: 'e메일 주소',
  ADMIN_NAME: '이름',
  ADMIN_JOB_POSITION: '직급',
  ADMIN_DEPARTMENT: '소속부서',
  ADMIN_JOB_DUTY: '직책',
  ADMIN_PHONE_NO: '전화번호',
  ADMIN_MOBILE_NO: '휴대폰번호',

  ERR_ADMIN_ID_NULL: '마스터 운영자가 없는 파트너사입니다. 관리자에게 문의 바랍니다.',

  REGISTER_MAG: '파트너 등록요청 완료',
  CANCEL_CONFIRM_MSG: '등록을 취소하시겠습니까?',
});
