

















import { Vue, Component, Prop } from 'vue-property-decorator';
import ThrottleRefreshButton from '@/components/root/parts/ThrottleRefreshButton.vue';
// 1차 오픈 스펙 제외
// import { LookupSettingData } from '@/components/popup/summary/LookupSetting.vue';
@Component({
  components: { ThrottleRefreshButton },
})
export default class StatusSummaryBox extends Vue {
  @Prop({ required: true })
  private readonly title!: string;
  @Prop({ required: false })
  private readonly period: string;
  // 1차 오픈 스펙 제외
  // @Prop({ required: true })
  // private readonly lookupSettingData!: LookupSettingData;

  // 1차 오픈 스펙 제외
  // private openPopupLookupSetting(): void {
  //   throwPopup({
  //     name: 'LookupSetting',
  //     data: this.lookupSettingData,
  //   });
  // }
}
