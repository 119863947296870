
































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { OptionType, ProductOptionType } from '@/types';
import { validateOptionPrice, validateTenUnit } from '@/views/popups/product/productAdd/ProductOption/ProductOption';

@Component({
  components: {
    ToolTip,
    SelectBox,
    TextInput,
  },
})
export default class CombinationOptionInfoForm extends Vue {
  @PropSync('optionList') private optionListSync!: ProductOptionType[];
  @Prop({ required: true }) private readonly option!: ProductOptionType;
  @Prop({ required: true }) private readonly optionIndex!: number;
  @Prop({ required: true }) private readonly hasAdditionalSetting!: boolean;
  @Prop({ required: true }) private readonly tabIndexes!: number[];
  @Prop({ required: true }) private readonly optionInfoLength!: number;
  @Prop({ required: true }) private readonly hasSupplyPrice!: () => boolean;
  @Prop({ required: true }) private readonly formClassName!: (index: number) => string;
  @Prop({ required: true }) private readonly isMapping!: boolean;
  @Prop({ required: true }) private readonly isMaster!: boolean;
  @Prop({ required: true }) private readonly isSlave!: boolean;
  @Prop({ required: true }) private readonly optionType!: () => OptionType;
  @Prop({ required: false, default: false }) private readonly isReadonly!: boolean;

  private validateTenUnit = validateTenUnit;
  private validateOptionPrice = validateOptionPrice;
  private addOptionInfo(index: number | null = null) {
    const defaultOptionInfo = {
      optionValue: '',
      optionPrice: null,
      stockCnt: null,
      managementCd: '',
      supplyPrice: null,
    };
    index === null
      ? this.optionListSync[this.optionIndex].optionInfoList.push(defaultOptionInfo)
      : this.optionListSync[this.optionIndex].optionInfoList.splice(index + 1, 0, defaultOptionInfo);
  }

  private deleteOptionInfo(optionInfoIndex: number) {
    this.$delete(this.optionListSync[this.optionIndex].optionInfoList, optionInfoIndex);
    const { length } = this.optionListSync[this.optionIndex].optionInfoList;
    if (length === 0) {
      this.addOptionInfo();
    }
  }

  private calculateTabIndex(increasedAmount: number, index: number): number {
    return this.tabIndexes[this.optionIndex] + this.optionInfoLength * index + increasedAmount;
  }

  get isDisabled() {
    return this.isMaster || this.isSlave || this.isReadonly;
  }
}
