export default Object.freeze({
  //src\components\popup\appearance\custom\OptionGuidePop.vue
  DISPLAY_OPTIONS_GUIDE: '옵션가 표시설정 안내',
  CLOSE_LAYER_POPUP: '레이어 팝업닫기',
  OPTIONS_GUIDE_ITEM1: '옵션상품의 옵션가에 대한 표시를 설정합니다.',
  OPTIONS_GUIDE_ITEM2: '선택된 옵션의 옵션정보가 표시되는 일부 페이지에 일괄 적용됩니다.',
  OPTIONS_GUIDE_ITEM3: '옵션가 : 옵션별 추가되거나 차감되는 금액이 표시됩니다. (옵션가 0월일 경우 미표시)',
  OPTIONS_GUIDE_ITEM4:
    '옵션판매가 : 판매가 - 즉시할인금액 ±옵션가를 계산하여 옵션상품 구매 시 지불할 금액이 표시됩니다.',
  OPTIONS_GUIDE_ITEM5: '노출 안 함 : 옵션가가 별도로 표시되지 않습니다.',

  //src\components\popup\appearance\custom\SettingGuidePop.vue
  DISPLAY_SETTING_GUIDE: '대체문구 표시설정 안내',
  SETTING_GUIDE_ITEM1: '가격대체문구가 출력되는 상품의 가격정보에 대한 노출을 설정합니다.',
  SETTING_GUIDE_ITEM2: '노출함 : 가격대체문구와 상품의 판매가, 할인/적립혜택, 쿠폰정보가 함께 표시됩니다.',
  SETTING_GUIDE_ITEM2_1: '* 쇼핑몰에 적용된 스킨에 따라 페이지별 노출되는 항목은 상이할 수 있습니다.',
  SETTING_GUIDE_ITEM3: '노출 안 함 : 가격대체문구만 표시됩니다.',
  EXPOSED: '노출함',
  DO_NOT_EXPOSE: '노출 안 함',

  //src\views\contents\appearance\custom\ExtraDesignsImg.vue
  FIND_FILES: '파일찾기',
  DELETE_FILES: '파일삭제',
  FILE_TYPE_ALERT: '파일만 등록해주세요.',
  FILE_SIZE_ALERT: '사이즈로 등록해주세요.',

  //src\views\contents\appearance\custom\Addition.vue
  DEFAULT_IMAGE_SETTING: '기본 이미지 설정',
  STOCK_DISPLAY_SETTING: '재고 노출 설정',
  STOCK_DISPLAY: '재고 노출',
  TEXT_DISPLAY_SETTING: '대체문구 표시설정',
  DISPLAY_SETTING_OF_PRICE: '가격정보 표시설정',
  OPTIONS_DISPLAY_SETTINGS: '옵션가 표시설정',
  SELECT_OPTION: '선택옵션 옵션가 표시설정',
  OPTION: '옵션가',
  OPTION_SELLING_PRICE: '옵션판매가',
  MALL_COMMON_SETTING: '쇼핑몰 공통설정',
  PRODUCT_RELATED_SETTINGS: '상품관련 공통설정',
  FAVICON_SETTINGS: '파비콘 설정',
  TIP_TEXT1:
    '파비콘은 브라우저 상단 타이틀 왼쪽에 보여지는 이미지입니다.<br>이미지 사이즈는 16X16 pixel, 확장자는 ico로 등록해주세요.',
  MOBILE_ICON_SET: '모바일샵 바로가기 아이콘 설정',
  TIP_TEXT2:
    '모바일샵 바로가기 아이콘은 모바일 기기에서 “쇼핑몰 바로가기“ 추가 시 기기에 노출되는 아이콘입니다.<br>기본으로 제공되며, 원하는 이미지로 수정이 가능합니다.<br>이미지 사이즈는 144X144 pixel, 확장자는 png로 등록해주세요.',
  SAVE_ALERT: '모바일샵 바로가기 아이콘이 미등록되어 기본 제공 홈 아이콘으로 변경됩니다.진행하시겠습니까?',
  SAVE_COMPLETE_ALERT: '저장이 완료되었습니다.',
  STOCK_INFO_TOOLTIP: `<strong>재고 노출 안내</strong>
    · 재고 노출 설정을 ‘노출 안 함＇으로 설정할 경우, 쇼핑몰 상품상세 및 장바구니 페이지에서 재고수량이 미노출됩니다.
    · shop API 상에서도 해당 쇼핑몰 내 전체 상품의 재고와 판매수량 관련 값이 ‘-999’로 응답합니다. 단, 판매가능재고가 0일 경우 재고수량은 0으로 응답합니다.
    `,
});
