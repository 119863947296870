

















































import { ReviewTypes, Types } from '@/const/contents/review';
import { ReviewsHistories } from '@/types/review';
import { Vue, Component, Prop } from 'vue-property-decorator';
/**
 * 레이어 팝업
 * 상품후기 변경이력 팝업
 * */
@Component({
  components: {},
})
export default class ReviewHistoryPopup extends Vue {
  @Prop() private data;
  @Prop() private readonly onNegativeClick!: () => void;
  private hostories: ReviewsHistories = [];
  mounted() {
    this.search();
  }
  async search() {
    const request = {
      pathParams: {
        reviewNo: this.data.reviewNo,
      },
    };
    const { data } = await this.$api.getReviewsReviewNoHistories(request);
    this.hostories = data.map(history => {
      const createdHistory: ReviewsHistories = history;
      const reviewTypeValue = history.isBestReview ? 'BEST' : 'NORMAL';
      const findedReview = ReviewTypes().find(reviewType => reviewType.value === reviewTypeValue);
      const attachYnLabel = history.attachYn === 'Y' ? 'PHOTO' : 'NORMAL';
      const findedType = Types().find(type => type.value === attachYnLabel);
      createdHistory.bestReviewLabel = findedReview ? findedReview.name : '';
      createdHistory.reviewTypeLabel = findedType ? findedType.name : '';
      return createdHistory;
    });
  }
}
