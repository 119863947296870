


















import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';
import { InputNumber } from '@/types';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
@Component({
  components: { RadioGroup, ToolTip },
})
export default class MinorPurchase extends Vue {
  @PropSync('minorPurchaseYn', { required: true })
  private minorPurchaseYnSync: YorN;
  @Prop({ required: true })
  private readonly mallNo: InputNumber;
  // @Prop({ required: true })
  // private readonly gradeType: AllNoneAny;
  @Prop({ required: true })
  private readonly productNo: number;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private toolTipMsg = this.$t('PRODUCT.ADD.MINOR_PURCHASE_TOOLTIP');
  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'minorPurchaseYn',
    data: [
      {
        id: 'minorPurchaseN',
        name: 'minorPurchaseYn',
        value: 'Y',
        display: 'PRODUCT.COMMON.NOT_USE',
      },
      {
        id: 'minorPurchaseY',
        name: 'minorPurchaseYn',
        value: 'N',
        display: 'PRODUCT.COMMON.USE',
      },
    ],
  };

  public resetMinorPurchase() {
    this.minorPurchaseYnSync = 'Y';
  }

  private goToAdultCertification() {
    if (this.mallNo) {
      window.open(`${this.$router.resolve({ name: 'MallCreate' }).href}/${this.mallNo}`, 'edit_mall');
      return;
    }
    this.$router.push({ name: 'MallList' });
  }
}
