











































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { formatCurrency } from '@/utils/numberFormat';
import { CommissionOption } from '@/views/contents/product/basic/ProductAdd/salesInfo/details/CommissionInfo.vue';
import CurrencyTextInput from '@/components/common/CurrencyTextInput.vue';
import { InputNumber } from '@/types';
@Component({
  components: { Currency: CurrencyTextInput, TextInput },
  computed: {
    isDirectInsertPurchasePrice(): boolean {
      return this.commissionInfo.type === 'PURCHASE_PRICE';
    },
    formattedImmediateDiscountPrice(): string {
      return formatCurrency(this.immediateDiscountPrice);
    },
  },
})
export default class ImmediateDiscountPrice extends Vue {
  // 사입-매입가
  @Prop({ required: true })
  private readonly isPurchaseType: boolean;

  @Prop()
  private readonly useOption!: boolean;

  @Prop()
  private readonly commissionInfo!: CommissionOption;

  @Prop({ default: 0 })
  private readonly immediateDiscountPrice!: number;

  @PropSync('supplyPrice', { default: 0 })
  private supplyPriceSync: number;

  @Prop({ required: true })
  private readonly defaultSupplyPrice: number;

  private formattedSalePrice: InputNumber = '';
  @Watch('isPurchaseType')
  private reset() {
    // 사입일 경우 '' 초기값 세팅 && 위탁인 경우 공급가 세팅
    this.formattedSalePrice = this.isPurchaseType ? '' : this.supplyPriceSync;
  }

  @Watch('formattedSalePrice')
  private setSupplyPrice() {
    if (this.isPurchaseType) {
      this.supplyPriceSync = this.formattedSalePrice === '' ? 0 : Number(this.formattedSalePrice);
    }
  }

  private initSupplyPrice = false;
  @Watch('defaultSupplyPrice')
  private setDefaultSupplyPrice() {
    this.initSupplyPrice = true;
    this.supplyPriceSync = this.defaultSupplyPrice;
    this.formattedSalePrice = this.defaultSupplyPrice;
  }

  @Watch('supplyPrice')
  private setFormattedSalePrice() {
    if (this.initSupplyPrice && !this.$route.path.includes('add')) {
      this.initSupplyPrice = false;
      return;
    }

    if (!this.isPurchaseType) {
      this.formattedSalePrice = this.supplyPriceSync;
    }
  }
}
