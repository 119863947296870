






































import { Vue, Component } from 'vue-property-decorator';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PopupList from '@/components/popup/Container.vue';
import { BROWSER, getBrowser } from '@/utils/common';

@Component({
  components: { PopupList, LoadingSpinner },
})
export default class AuthLayout extends Vue {
  private get isChrome(): boolean {
    return getBrowser() === BROWSER.CHROME;
  }
}
