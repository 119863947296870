
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ExtensionValidOption, MaxSizeValidOption } from '@/types';
import { PostImagesResponse, SlideImages } from 'ncp-api-supporter';
import FileInputMixin from '@/components/common/input/fileInput/FileInputMixin.vue';

@Component
export default class SlideImageFileInputMixin extends Mixins(FileInputMixin) {
  @Prop({ default: () => ({ validType: 'image', alertMsg: 'PRODUCT.COMMON.IMAGE_FILE_MAX_SIZE' }) })
  readonly extensionValidOption!: ExtensionValidOption;

  @Prop({ default: () => ({ validType: 'normal', alertMsg: 'PRODUCT.OPTION.TIP_10MB' }) })
  readonly maxSizeValidOption!: MaxSizeValidOption;

  addFile(mallNo: number, ImageUrlType: string, index: number, slideImages: SlideImages[]): void {
    const ref = `${ImageUrlType}${index}`;
    const file = Array.from((this.$refs[ref] as HTMLInputElement[])[0].files as FileList)[0];
    if (file) {
      this.uploadFile(mallNo, ImageUrlType, index, file, (data: PostImagesResponse) => {
        slideImages[index][ImageUrlType] = data.url;
      });
    }
  }

  private uploadFile(mallNo: number, ImageUrlType: string, index: number, file: File, callback: Function): void {
    if (!mallNo) {
      alert(this.$t('ALERT_NO_SELECTED', { value: this.$t('MALL') }));
      return;
    }
    if (!this.validateFileExtension(file)) return;
    if (!this.validateFileSize(file)) return;

    const data = new FormData();
    data.append('file', file);
    this.$api
      .postImages({ data, params: { mallNo: mallNo } })
      .then(res => {
        callback(res.data);
      })
      .finally(() => {
        const ref = `${ImageUrlType}${index}`;
        (this.$refs[ref] as HTMLInputElement).value = null;
      });
  }

  removeImg(ImageUrlType: string, index: number, slideImages: SlideImages[]): void {
    if (ImageUrlType === 'mainImageUrl') {
      slideImages[index].mainImageUrl = '';
    } else if (ImageUrlType === 'thumbImageUrl') {
      slideImages[index].thumbImageUrl = '';
    } else if (ImageUrlType === 'thumbImageUrlOnOver') {
      slideImages[index].thumbImageUrlOnOver = '';
    }
  }
}
