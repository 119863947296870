import { Component, Vue } from 'vue-property-decorator';
import {
  GetClaimsOrdersOrderNoAdditionalPaysRequest,
  GetClaimsOrdersOrderNoRefundsRequest,
  GetMembersMemberNo,
  GetOrdersByOrderNo,
  GetPaymentsRequest,
  PayType,
} from 'ncp-api-supporter';
import { OrderProductOptionNo } from './OrderDetail.type';
import { orderListTypes } from '@/const/order';
import { OrderListTypes } from '@/types/order';

type ToggleButtonType = 'open' | 'close';

@Component
export default class OrderDetails extends Vue {
  private showOtherPay = false;
  private showOrderInfo = true;
  private showOrderRefund = false;
  public editReturnPay = 0;
  public totalAccumulation = 0;
  public serviceNo = null;
  public memberInfo = {};
  public otherPayDetailList = [];
  public productInfo = [];
  public paymentInfo = [];
  public deliveryCompanyTypeArr = [];
  public returnContentList = [];
  public returnDetailList = [];
  public receiverListContent = [];
  public orderProductOptionNoArr: OrderProductOptionNo[] = [];
  public returnListNum = null;
  public otherPayListNum = null;
  public receiverListNum = null;
  public returnListFlagArr = [
    'CANCEL_DONE',
    'CANCEL_PROC_REQUEST_REFUND',
    'RETURN_DONE',
    'EXCHANGE_DONE_REFUND_DONE',
    'RETURN_NO_REFUND',
  ]; // 환불 정보
  public otherPayListFlagArr = ['EXCHANGE_REQUEST', 'EXCHANGE_DONE_PAY_DONE']; // 추가 결제
  public orderInfo = {
    firstPayInfo: {
      salePrice: '0',
      deliveryAmt: '0',
      totalDeliveryAmt: '0',
      couponAmt: '0',
      payAmt: '0',
      subPayAmt: '0',
      realPay: '0',
      registerYmdt: '',
    },
    lastPayInfo: {
      salePrice: '0',
      deliveryAmt: '0',
      totalDeliveryAmt: '0',
      couponAmt: '0',
      payAmt: '0',
      subPayAmt: '0',
      realPay: '0',
      editReturnPay: '0',
    },
  };
  public settleInfo = {
    infoPayType: '',
    detailInfo: '',
    payCashStatus: '',
    payCashUrl: '',
    payYmdt: '',
    registerYmdt: '',
  };
  public onlyInitPayClaimStatusType = [
    'CANCEL_DONE',
    'RETURN_DONE',
    'EXCHANGE_DONE_PAY_DONE',
    'EXCHANGE_DONE',
    'EXCHANGE_DONE_REFUND_DONE',
    'RETURN_NO_REFUND',
    'CANCEL_NO_REFUND',
  ]; // 최종결제정보

  public get orderStatus(): OrderListTypes {
    return this.$route.query.status as OrderListTypes;
  }

  public get isPreviousOrder(): boolean {
    return this.orderStatus === orderListTypes.PREVIOUS;
  }

  private otherPayButton = true;
  private orderRefundButton = true;
  private orderReceiverButton = true;

  public clickOtherPayButton(type: ToggleButtonType) {
    this.otherPayButton = type === 'open';
  }
  public clickOrderRefundButton(type: ToggleButtonType) {
    this.orderRefundButton = type === 'open';
  }
  public clickOrderReceiverButton(type: ToggleButtonType) {
    this.orderReceiverButton = type === 'open';
  }

  public getTotalAccumulation(arr) {
    let totalAccumulation = 0;
    if (arr.length === 0) {
      return;
    }
    arr.map(item => {
      totalAccumulation += Number(item.expectedAccumulationAmount);
    });
    this.totalAccumulation = totalAccumulation;
  }

  public getOtherPayList() {
    const request: GetClaimsOrdersOrderNoAdditionalPaysRequest = {
      pathParams: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api
      .getClaimsOrdersOrderNoAdditionalPays(request)
      .then(res => {
        this.otherPayDetailList = [];
        if (res.data.length > 0) {
          res.data.map(item => {
            this.productInfo.map(itemP => {
              if (itemP.claimNo === item.claimNo && this.otherPayListFlagArr.indexOf(itemP.claimStatusType) > -1) {
                this.otherPayDetailList.push(item);
              }
            });
          });
          this.otherPayListNum = this.otherPayDetailList.length;
        }
      })
      .finally(() => {
        this.showOtherPay = this.otherPayDetailList.length > 0;
      });
  }
  public getPayInfo(data): void {
    if (data.length === 0) {
      return;
    }
    data.forEach(item => {
      if (this.onlyInitPayClaimStatusType.indexOf(item.claimStatusType) > -1) {
        this.showOrderInfo = this.orderInfo.lastPayInfo.realPay === '0';
      }
    });
  }
  public getReturnListAndPayList(): void {
    const request: GetClaimsOrdersOrderNoRefundsRequest = {
      pathParams: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api.getClaimsOrdersOrderNoRefunds(request).then(res => {
      let editReturnPay = 0;
      this.returnContentList = res.data;
      this.returnContentList.map(item => {
        editReturnPay += Number(item.refundAmounts.refundAdjustAmt);
        this.productInfo.map(itemP => {
          if (item.claimNo === itemP.claimNo && this.returnListFlagArr.indexOf(itemP.claimStatusType) > -1) {
            this.returnDetailList.push(item);
          }
        });
      });
      this.orderInfo.lastPayInfo.editReturnPay = editReturnPay.toString();
      this.returnDetailList.map(item => {
        const claimFinishedArr = this.productInfo.filter(itemP => {
          return itemP.claimNo === item.claimNo;
        });
        if (claimFinishedArr.length > 0) {
          this.$set(item, 'claimFinished', claimFinishedArr[0].claimFinished);
        }
      });

      this.returnDetailList = this.returnDetailList.reduce((acc, returnDetail) => {
        return acc.includes(returnDetail) ? acc : [...acc, returnDetail];
      }, []);
      if (this.returnDetailList.length > 0) {
        this.returnListNum = this.returnDetailList.length;
        this.showOrderRefund = true;
      } else {
        this.showOrderRefund = false;
        this.returnListNum = 0;
      }
    });
  }
  public handleEmitShippingResp(data): void {
    this.receiverListContent = [];
    this.receiverListContent = data;
    this.receiverListContent.map(item => {
      this.$set(item, 'showReceiverInfo', true);
      this.$set(item, 'shippingInfoEditor', false);
    });
    this.receiverListNum = data.length;
  }
  public getTaskProductOptions(): void {
    this.orderProductOptionNoArr = [];
    this.productInfo.map(item => {
      const label = item.productName + ' | ' + item.optionName + ' : ' + item.optionValue;
      const option = {
        value: item.orderOptionNo,
        label: label,
        partnerNo: item.partnerNo,
        productName: item.productName,
        partnerDelivery: item.partnerDelivery,
      };
      this.orderProductOptionNoArr.push(option);
    });
  }

  public async getOrderMemberInfo(data): Promise<void> {
    // TODO: 쇼핑몰 비회원이면서 naverpay 주문했을 때, orderer.email이 null로 내려옴. 문의 중
    const isGuest = data.memberNo === 0;

    let memberOrdererData = null;
    if (!isGuest) {
      memberOrdererData = await this.fetchMemberInfo(data.memberNo);
    }

    this.memberInfo = {
      name: isGuest
        ? data.orderer.name
        : `${data.orderer.name} / ${memberOrdererData.memberId} / ${memberOrdererData.gradeName}`,
      email1: this.getEmailId(isGuest ? data.orderer : memberOrdererData),
      email2: this.getEmailDomain(isGuest ? data.orderer : memberOrdererData),
      contact1: data.orderer.contact1,
      orderMemo: data.orderMemo,
      memberNo: data.memberNo,
      memberType: isGuest ? 'guest' : 'member',
      mallNo: data.mallNo,
      memberStatus: memberOrdererData?.memberStatus,
      isPreviousOrder: false,
    };
  }

  private getEmailId(ordererData) {
    if (!ordererData.email) {
      return null;
    }
    return ordererData.email.split('@')[0];
  }

  private getEmailDomain(ordererData) {
    if (!ordererData.email) {
      return null;
    }
    return ordererData.email.split('@')[1];
  }

  private async fetchMemberInfo(memberNo: string): Promise<GetMembersMemberNo> {
    const response = await this.$api.getMembersMemberNo({
      pathParams: { memberNo },
    });

    return response.data;
  }

  public getPaymentInfo(): void {
    const paymentRequest: GetPaymentsRequest = {
      params: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api.getPayments(paymentRequest).then(res => {
      this.paymentInfo = [];
      this.paymentInfo = res.data;
      this.serviceNo = this.paymentInfo[0].serviceNo;
      this.getUsePaymentInfo(this.paymentInfo);
    });
  }
  public getUsePaymentInfo(arr): void {
    if (arr.length === 0) {
      return;
    }
    arr.map(item => {
      if (item.firstPay) {
        this.settleInfo = {
          infoPayType: this.payTypeLabel(item.payType),
          detailInfo: item.detailString,
          payCashStatus: item.cashReceiptStatus,
          payCashUrl: item.cashReceiptUrl,
          payYmdt: item.payYmdt,
          registerYmdt: this.orderInfo.firstPayInfo.registerYmdt,
        };
      }
    });
  }

  public getOrderInfoData(order: GetOrdersByOrderNo) {
    return {
      firstPayInfo: {
        salePrice: (order.firstBalance.standardAmt - order.firstBalance.immediateDiscountAmt).toLocaleString(),
        deliveryAmt: order.firstBalance.deliveryAmt.toLocaleString(),
        totalDeliveryAmt: (
          order.firstBalance.deliveryAmt +
          order.firstBalance.remoteDeliveryAmt -
          order.firstBalance.deliveryCouponDiscountAmt
        ).toLocaleString(),
        couponAmt: (
          order.firstBalance.additionalDiscountAmt +
          order.firstBalance.productCouponDiscountAmt +
          order.firstBalance.cartCouponDiscountAmt
        ).toLocaleString(),
        payAmt: order.firstBalance.payAmt.toLocaleString(),
        subPayAmt: order.firstBalance.subPayAmt.toLocaleString(),
        realPay: (order.firstBalance.payAmt - order.firstBalance.subPayAmt).toLocaleString(),
        registerYmdt: order.registerYmdt.toLocaleString(),
      },
      lastPayInfo: {
        salePrice: (order.lastBalance.standardAmt - order.lastBalance.immediateDiscountAmt).toLocaleString(),
        deliveryAmt: order.lastBalance.deliveryAmt.toLocaleString(),
        totalDeliveryAmt: (
          order.lastBalance.deliveryAmt +
          order.lastBalance.remoteDeliveryAmt -
          order.lastBalance.deliveryCouponDiscountAmt
        ).toLocaleString(),
        couponAmt: (
          order.lastBalance.additionalDiscountAmt +
          order.lastBalance.productCouponDiscountAmt +
          order.lastBalance.cartCouponDiscountAmt
        ).toLocaleString(),
        payAmt: order.lastBalance.payAmt.toLocaleString(),
        subPayAmt: order.lastBalance.subPayAmt.toLocaleString(),
        realPay: (order.lastBalance.payAmt - order.lastBalance.subPayAmt).toLocaleString(),
        editReturnPay: this.editReturnPay.toLocaleString(),
      },
    };
  }

  public payTypeLabel(type: PayType | null): string {
    return type ? this.$t(`ORDER.ORDER_DETAIL.PAY_TYPE_${type}`).toString() : '';
  }
}
