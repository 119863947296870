






























import { Component, Prop, Ref } from 'vue-property-decorator';
import SearchForm from '@/components/product/basic/SearchForm.vue';
import SearchResult from '@/components/product/basic/SearchResult.vue';
import { getDefaultProductInquiryGridProps } from '@/views/contents/product/basic/GridProps';
import { GridProp } from '@/types';
import { PopupClose } from '@/helpers/popup';
import WindowPopupMain from '@/views/popups/Main.vue';
import { ApplyStatusType } from '@/components/product/basic/SearchOptionTypes';
interface FixedData {
  mallNo: number;
  applyStatusType: ApplyStatusType;
  partnerNo?: number;
}
interface QueryLimit {
  saleStatus: string;
  saleSettingStatus: string;
}
export interface ParentData {
  fixed: FixedData;
  queryLimit: QueryLimit;
  directSend: boolean;
  rowPickLimitCount: number;
}
@Component({
  components: {
    SearchResult,
    SearchForm,
  },
})
export default class ProductInquiryCore extends WindowPopupMain {
  @Prop({ default: () => getDefaultProductInquiryGridProps })
  private getGridProps!: () => GridProp;
  private fixedMallNo: number | null = null;
  private fixedPartnerNo: number | null = null;
  private fixedApplyStatusType: string | null = null;
  private queryLimitSaleStatus: string | null = null;
  private queryLimitSaleSettingStatus: string | null = null;
  private rowPickLimitCount: number | null = null;
  @Ref()
  private readonly searchForm!: SearchForm;
  @Ref()
  private readonly searchResult!: SearchResult;
  beforeCreate() {
    this['useSessionRetention'] = true;
  }
  created() {
    const { fixed, queryLimit, rowPickLimitCount } = this.data;
    if (fixed !== undefined) {
      this.setFixedData(fixed as FixedData);
    }
    if (queryLimit !== undefined) {
      this.setQueryLimitData(queryLimit as QueryLimit);
    }
    if (rowPickLimitCount !== undefined && rowPickLimitCount >= 1) {
      this.setRowPickLimit(rowPickLimitCount);
    }
  }
  mounted() {
    const { directSend } = this.data;
    this.searchForm.sendQueryUrl();
    if (directSend !== undefined) {
      console.warn(
        'directSend property is Deprecated. The directSend function now works by default. Please delete the property.',
      );
    }
  }
  private setFixedData(fixedData: FixedData): void {
    const { mallNo, partnerNo, applyStatusType } = fixedData;
    if (mallNo) this.fixedMallNo = mallNo;
    if (partnerNo) this.fixedPartnerNo = partnerNo;
    if (applyStatusType) this.fixedApplyStatusType = applyStatusType;
  }
  private setQueryLimitData(queryLimit: QueryLimit): void {
    const { saleStatus, saleSettingStatus } = queryLimit;
    if (saleStatus) this.queryLimitSaleStatus = saleStatus;
    if (saleSettingStatus) this.queryLimitSaleSettingStatus = saleSettingStatus;
  }
  private setRowPickLimit(count: number): void {
    this.rowPickLimitCount = count;
  }
  private onSubmit(): void {
    const checkedProduct = this.searchResult.getCheckedProduct();
    if (checkedProduct.length <= 0) return alert(this.$t('CHECK_SELECT_PRODUCT'));
    if (this.rowPickLimitCount !== null && checkedProduct.length >= this.rowPickLimitCount) {
      return alert(this.$t('ALERT_MAX_COUNT_OVER', { n: this.rowPickLimitCount }));
    }
    if (this.data?.confirmMsg && !confirm(this.data?.confirmMsg)) return;
    this.$emit(PopupClose.CONFIRM.toString(), checkedProduct);
    this.onPositiveClick(checkedProduct);
  }
}
