
























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { sendQueryString } from '@/utils/query';
import { Mall } from 'ncp-api-supporter';
import { PeriodSearchTypes as periodSearchTypes, DisplableTypes, PlatformTypes2 } from '@/const/finderOptions';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { DEFAULT_PRODUCT_MAIN_LIST_QUERY } from '@/const/contents/design';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { RouteQuery } from '@/types/route';
import { getCurrentMallNo } from '@/utils/mall';

// 솔루션 내 등록된 쇼핑몰별 메인분류 관리 가능
// 메인분류 리스트 접근 시 기본 검색조건 기준 데이터 조회하여 검색결과 영역에 노출
@Component({ components: { DateRangePicker, SelectBox, TextInput, MallSelect } })
export default class ProductMain extends Vue {
  @Getter('mall/getMalls') private stateMalls!: Mall[];

  @Watch('$route.query')
  private onQueryStringChanged(query: RouteQuery) {
    if (query.size) {
      Object.assign(this.query, { size: query.size });
    }
  }

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  private periodSearchTypes = periodSearchTypes;
  private query = { ...DEFAULT_PRODUCT_MAIN_LIST_QUERY };
  private dateRangeOption = {
    selectedPeriodType: 'all',
    periodTypes: ['td', '1w', '15d', '3m', 'all'],
  };
  private displableTypes = DisplableTypes;
  private platformTypes = PlatformTypes2;

  created() {
    this.initQuery();
  }

  initQuery() {
    this.reset();
    Object.assign(this.query, { ...this.$route.query });

    this.search();
  }

  reset() {
    this.query = { ...DEFAULT_PRODUCT_MAIN_LIST_QUERY };
    this.query.mallNos = this.mallNo; // 디폴트 : 가장 최근에 등록된 쇼핑몰
  }

  search() {
    sendQueryString(this, { ...this.query }, true, true);
  }
}
