





































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchKeywordInput from '@/components/common/input/SearchKeywordInput.vue';
import { InputNumber, SearchKeywordData, SearchKeywordInputOption } from '@/types';
import {
  ClassType,
  ContractedPartner,
  GetMallsContractedPartnersByMallNoRequest,
  GroupType,
  NCPResponse,
} from 'ncp-api-supporter';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
@Component({
  components: {
    RadioGroup,
    SelectBox,
    SearchKeywordInput,
  },
})
export default class UpgradeService extends Vue {
  @Prop() private readonly isUpgradedService: boolean;
  @Prop() private readonly mallNo: InputNumber;
  @PropSync('groupType') private groupTypeSync!: GroupType;
  @PropSync('classType') private classTypeSync!: ClassType;

  private groupTypeOption: RadioBoxGroupOption<GroupType> = {
    name: 'groupType',
    data: [
      {
        id: 'delivery',
        name: 'groupType',
        value: 'DELIVERY',
        display: 'PRODUCT.COMMON.GROUP_TYPE_DELIVERY',
      },
      {
        id: 'service',
        name: 'groupType',
        value: 'SERVICE',
        display: 'PRODUCT.COMMON.GROUP_TYPE_SERVICE',
      },
    ],
  };

  private classTypesOption: OptionData<ClassType>[] = [
    {
      name: 'PRODUCT.COMMON.CLASS_TYPE_DEFAULT',
      value: 'DEFAULT',
    },
    {
      name: 'PRODUCT.COMMON.CLASS_TYPE_EVENT',
      value: 'EVENT',
    },
  ];

  private searchKeywordInputOption: SearchKeywordInputOption = {
    placeholder: this.$t('PRODUCT.ADD.PLACEHOLDER_PARTNER'),
    isDirect: true,
    useResetBtn: true,
  };

  private selectedPartnerByKeyword(selected: SearchKeywordData): void {
    console.log(selected);
  }

  private partners: ContractedPartner[] = [];
  private displayPartners: SearchKeywordData[] = [];
  // TODO: 자체공급사 입력 -> 기획자 확인 요망
  // NOTE: 현재 스펙에서는 숨김처리 하기 때문에 호출하지 않음.
  @Watch('mallNo')
  private async fetchPartners(): Promise<void> {
    if (!this.mallNo) return;
    const pathParams: GetMallsContractedPartnersByMallNoRequest['pathParams'] = {
      mallNo: this.mallNo.toString(),
    };
    const { data }: NCPResponse<ContractedPartner[]> = await this.$api.getMallsContractedPartnersByMallNo({
      pathParams,
    }); // FIXME : 요청 정보에 따라서 타임아웃 에러 발생하는듯?
    this.partners = data.filter(partner => partner.partnerStatus === 'ACTIVE' && partner.contractStatus === 'ACTIVE');
  }
}
