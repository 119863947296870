




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { getDefaultTradeBank, i18nMallCreate } from '@/const/contents/configuration/mallCreate';

@Component({
  components: { TextInput, SelectBox },
})
export default class AddBankAccount extends Vue {
  @Prop() private data;
  @Prop() private onPositiveClick!: Function;
  @Prop() private onNegativeClick!: Function;

  private tradeBank = getDefaultTradeBank();
  private isEdit = false;

  created() {
    if (this.data.bank.bank !== '') {
      this.tradeBank = this.data.bank;
      this.isEdit = true;
    }
  }

  private onClickSave(): void {
    const { bank, bankAccount, bankDepositorName } = this.tradeBank;
    const saveCondition =
      (bank === '' && bankAccount === '' && bankDepositorName === '') ||
      (bank !== '' && bankAccount !== '' && bankDepositorName !== '');

    if (saveCondition) {
      alert(this.$t('ALERT_SAVED'));
      this.onPositiveClick(this.tradeBank);
      return;
    }
    if (!this.validateData(bank, 'NO_BANK_MESSAGE')) return;
    if (!this.validateData(bankAccount, 'NO_ACCOUNT_MESSAGE')) return;
    if (!this.validateData(bankDepositorName, 'NO_DEPOSITOR_NAME_MESSAGE')) return;
  }

  private validateData(data: string, message: string): boolean {
    if (data === '') {
      alert(i18nMallCreate(message));
      return false;
    }
    return true;
  }
}
