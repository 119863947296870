

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { i18nForSecurity } from '@/const/contents/configuration/securityServer';

@Component
export default class SecurityServerSettingGuide extends Vue {
  @Prop()
  private readonly data: { mallNo: number };
  @Prop()
  private onPositiveClick: () => void;
  @Prop()
  private onNegativeClick: () => void;

  private i18nForSecurity = i18nForSecurity;

  private settingGuideOption = [
    {
      title: 'CHANGE_URL_TITLE',
      content1: 'CHANGE_URL_CONTENT_1',
      content2: 'CHANGE_URL_CONTENT_2',
      content3: 'CHANGE_URL_CONTENT_3',
      to: 'PgSetting',
    },
    {
      title: 'CHANGE_CALLBACK_URL',
      content1: 'CHANGE_CALLBACK_CONTENT_1',
      content2: 'CHANGE_CALLBACK_CONTENT_2',
      content3: 'CHANGE_CALLBACK_CONTENT_3',
      to: 'ExternalService',
    },
  ];
}
