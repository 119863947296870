import { Component, Vue } from 'vue-property-decorator';
import { unescapeHtml } from '@/utils/common';
import { OrderStatusType } from 'ncp-api-supporter';
import { TranslateResult } from 'vue-i18n';

interface TextOption {
  inputLabel: string;
  inputValue: string;
  required: boolean | null;
}

@Component
export default class OrderProductInfoMixin extends Vue {
  private generateOptionLabel(optionName: string, optionValue: string): string {
    const seperator = '|';
    const values = optionValue.split(seperator);
    return optionName
      .split(seperator)
      .map((name, index) => `<strong>${unescapeHtml(name)}</strong> : ${unescapeHtml(values[index])}`)
      .join(` ${seperator} `);
  }
  private generateTextOptionLabel(textOptions?: TextOption[]): string {
    if (!textOptions?.length) return '';

    return textOptions.map(({ inputLabel, inputValue }) => `<br />${inputLabel} : ${inputValue}`).join('');
  }
  public showOption({ optionName, optionValue, purchaserInputs }) {
    return this.generateOptionLabel(optionName, optionValue).concat(this.generateTextOptionLabel(purchaserInputs));
  }

  public orderStatusTypeLabel(type: OrderStatusType): TranslateResult {
    return type ? this.$t(`ORDER.ORDER_DETAIL.STATUS_${type}`) : '';
  }
}
