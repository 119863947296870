

























































import { Component } from 'vue-property-decorator';
import WindowPopupMain from '@/views/popups/Main.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { noticeList } from '@/views/popups/product/productAdd/ProductOption/ProductOptionImage/ProductOptionImage';
import ImageFileInput from '@/components/common/input/imageFileInput/ImageFileInput.vue';
import { ImageFileData } from '@/types';
import { OptionImage, YorN } from 'ncp-api-supporter';
@Component({
  components: { ImageFileInput, NoticeBox },
})
export default class ProductOptionImage extends WindowPopupMain {
  private noticeList: string[] = noticeList;

  private mainImage: ImageFileData = {
    url: '',
    name: '',
  };

  private get defaultImage(): ImageFileData {
    return {
      url: '',
      name: '',
    };
  }

  private options = {
    represent: {
      alt: 'PRODUCT.OPTION.OPTION_REPRESENT',
      // placeholder: 'PRODUCT.OPTION.PLACEHOLDER_LIMIT_100',
    },
    details: {
      alt: 'PRODUCT.OPTION.OPTION_DETAIL_IMG',
      // placeholder: 'PRODUCT.OPTION.PLACEHOLDER_LIMIT_45',
    },
  };

  private images: ImageFileData[] = [];

  private addDetails(): void {
    if (this.addableDetails() === false) return;
    this.images.push(this.defaultImage);
  }

  private addableDetails(): boolean {
    const MAX_SIZE = 5;
    const addable = this.images.length < MAX_SIZE;
    if (addable === false) {
      alert(this.$t('PRODUCT.OPTION.TIP_MAX_5CASE').toString());
    }
    return addable;
  }

  private removeDetails(idx: number): void {
    this.images.splice(idx, 1);
  }

  private onSave(): void {
    if (this.validImages() === false) return;

    const images = [
      this.getMappingOptionImages([this.mainImage], 'Y'),
      this.getMappingOptionImages(this.images, 'N'),
    ].flat();

    this.onPositiveClick({ images, index: this.data.index });
  }

  private validImages(): boolean {
    if (this.mainImage.url === '') {
      alert(this.$t('PRODUCT.OPTION.PLEASE_OPTION_REPRESENT').toString());
      return false;
    }
    if (this.images[0].url === '') {
      alert(this.$t('PRODUCT.OPTION.PLEASE_OPTION_DETAIL_IMG').toString());
      return false;
    }
    return true;
  }

  private getMappingOptionImages(images: ImageFileData[], mainYn: YorN): OptionImage[] {
    return [...images].reduce((acc, image) => {
      if (image.url !== '') {
        acc.push({ mainYn, mallOptionImageUrl: image.url });
      }
      return acc;
    }, []);
  }

  private mounted() {
    this.setInitImageData();
  }

  private setInitImageData(): void {
    this.data.images.length > 0 ? this.mapImageData() : this.resetImages();
  }

  private mapImageData(): void {
    this.mainImage = Object.assign({}, this.mainImage, this.getMappingImageData('Y')[0]);
    this.images = [...this.getMappingImageData('N')];
  }

  private getMappingImageData(mainYn: YorN): ImageFileData[] {
    return this.data.images
      .filter(image => image.mainYn === mainYn)
      .map(image => ({
        name: ProductOptionImage.getFileName(image.mallOptionImageUrl),
        url: image.mallOptionImageUrl,
      }));
  }

  private static getFileName(imageUrl: string): string {
    return imageUrl.split('/').pop();
  }

  private resetImages(): void {
    this.images.push(this.defaultImage);
  }
}
