

























import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { unescapeHtml } from '@/utils/common';
@Component({
  components: { TextInput },
})
export default class BuyerProductName extends Vue {
  @PropSync('buyerProductName')
  private buyerProductNameSync!: string;

  @Prop({ required: true })
  private readonly productName!: string;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private useProductName = false;

  private get unescapeHtmlProductName(): string {
    return unescapeHtml(this.productName);
  }
  @Watch('useProductName')
  private setProductName() {
    if (this.useProductName) {
      this.buyerProductNameSync = this.unescapeHtmlProductName;
    }
    // this.buyerProductNameSync = this.useProductName ? this.unescapeHtmlProductName : '';
  }

  @Watch('buyerProductName')
  private setUseProductName() {
    this.useProductName = this.buyerProductNameSync === this.unescapeHtmlProductName;
  }
}
