import { DirectiveOptions } from 'vue';

const replaceHandler = (event): void => {
  const regex = /(^\d{0,2}([.]\d?){0,1})$/; // 0 ~ 99.9
  let value: string = event.target.value;
  let result;

  if (regex.test(value)) return;

  let appearComma = false;
  for (let i = 0; i < value.length; i++) {
    // 숫자, . 외에 다른게 껴있는지 체크
    if (!/^[0-9.]/.test(value[i])) value = value.replace(value[i], '');
    // .가 여러개 있는지 체크
    if (value[i] === '.') {
      if (appearComma) value = value.replace(value[i], '');
      appearComma = true;
    }
  }
  const splitValue = value.split('.');
  result = splitValue[0].substring(0, 2);
  if (splitValue[1] !== undefined) result += `.${splitValue[1].substring(0, 1)}`;

  event.target.value = result;
};

const percentOneDecimal: DirectiveOptions = {
  bind(el: HTMLInputElement): void {
    el.addEventListener('keyup', replaceHandler);
    el.addEventListener('blur', replaceHandler);
  },
  unbind(el: HTMLInputElement): void {
    el.removeEventListener('keyup', replaceHandler);
    el.addEventListener('blur', replaceHandler);
  },
};
export default percentOneDecimal;
