




import { Component, Mixins } from 'vue-property-decorator';
import SmsPoint from '@/views/contents/member/sms/mixins/SmsPoint.vue';

import AdminIframe from '@/components/iframe/AdminIframe.vue';
import { openHistoryDetailsLayer } from '@/views/contents/member/kakao/SearchTemplates';
import { GetSmsGodoTokenPopupSmsDetailResponse } from 'ncp-api-supporter/dist/types';

@Component({
  components: { AdminIframe },
})
export default class SmsContentsIframe extends Mixins(SmsPoint) {
  private get remoteSrc() {
    const { iframePath } = this.$route.meta;
    const queryObject = { ...this.$route.query, serviceType: 'PRO', from: 'shop-by' };
    const queryString = new URLSearchParams(queryObject).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + iframePath + `?` + queryString;
  }

  public async handleOpenHistoryDetailsLayer({ mallNo, sendDate, sendKey, ip }) {
    await openHistoryDetailsLayer<GetSmsGodoTokenPopupSmsDetailResponse>(
      'sms',
      {
        mallNo,
        sendDate,
        sendKey,
      },
      ip,
    );
  }

  created() {
    window.addEventListener('message', ({ data }) => {
      switch (data.type) {
        case 'REQUEST_OPEN_CHARGE_POPUP':
          this.openChargePopup();

          break;

        case 'REQUEST_OPEN_SMS_RESULT_INFO_POPUP':
          this.handleOpenHistoryDetailsLayer(data.data);
          break;

        default:
          break;
      }
    });
  }
}
