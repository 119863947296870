







































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { getGridProps } from '@/views/contents/board/basic/Questions';
import { GetProductInquiries } from 'ncp-api-supporter';
import { RouteQuery } from '@/types/route';
import { getToday, addDay } from '@/utils/dateFormat';
import OrderInfo from './QuestionsOrderInfo.vue';
import InquiryInfo from './QuestionsInquiryInfo.vue';
import {
  GridType,
  GridEvent,
  OrdersByOrderNo,
  ProductInquiriesByInquiryNo,
  InquiryExtra,
  QuestionsParams,
} from '@/types/questions';
import { i18n } from '@/views/contents/board/basic/Questions';
import { $el } from '@/utils/element';
import { throwExternalWindowPopup } from '@/helpers/popup';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { setNoDataMessage } from '@/helpers/grid';
import $ from 'jquery';
import { NcpXhrError } from 'ncp-api-supporter';

@Component({ components: { Grid, OrderInfo, InquiryInfo } })
export default class QuestionsContent extends Vue {
  @Watch('$route.query')
  private onQueryStringChanged(query: RouteQuery) {
    this.search(query);
  }

  @Ref('questionGrid') private readonly grid: GridType;
  private gridProps = getGridProps(this);
  private rowDataByClick: InquiryExtra = {
    inquiryNo: -1,
    memberId: '',
    memberStatus: '',
    mallNo: -1,
    admin: {
      adminNo: -1,
      adminName: '',
    },
    displayStatusType: '',
    rowKey: -1,
    orderNo: '',
  };

  @Ref() private inquiryInfo: InquiryInfo;
  private isActiveMember(rowKey: number): boolean {
    const { member } = (this.grid as any).getRowAt(rowKey);
    return !(member.memberStatus === 'EXPELLED' || member.memberStatus === 'WITHDRAWN');
  }
  private onItemClicked(event: GridEvent): void {
    const {
      targetType,
      rowKey,
      instance,
      columnName,
      nativeEvent: { target },
    } = event;

    if (targetType === 'cell') {
      if (target && target['tagName'] === 'A') {
        switch (columnName) {
          case 'member':
            this.isActiveMember(Number(rowKey)) &&
              throwExternalWindowPopup(`/pro/crm/${target['dataset'].registerNo}`, 'xlg');
            return; //use return not break, It's for prevent event go on.
          case 'mallProductNo':
            openEditProductWindow(target['dataset'].mallProductNo);
            return;
        }
      }

      if (target && target['tagName'] === 'SELECT' && columnName === 'displayStatusType') {
        return;
      }

      const $radio = $el(document.querySelector('.tui-grid-cell-current-row input[type="radio"]'));
      const radio = $radio.getElement();
      radio['checked'] = true;
      //Add class, there are two purposes, one is add a background-color when row selected,
      //other one is when the user scrolls the page, keep the radioBtn selected.
      instance.addRowClassName(rowKey, 'questionGridAreaCurrentRow');
      if (this.rowDataByClick.rowKey !== rowKey) {
        if (this.rowDataByClick.rowKey !== -1)
          instance.removeRowClassName(this.rowDataByClick.rowKey, 'questionGridAreaCurrentRow');
        this.rowDataByClick.rowKey = rowKey as number;
      }
      //the 'member' cannot be destructuring assignment, because 'CellValue' not exist props whick need 'memberId' and 'memberStatus'.
      const { inquiryNo, member, mallNo, admin, orderNo, displayStatusType } = instance.getRow(rowKey);
      Object.assign(this.rowDataByClick, {
        rowKey,
        inquiryNo,
        memberId: member['memberId'],
        memberStatus: member['memberStatus'],
        mallNo,
        admin,
        orderNo,
        displayStatusType,
      });
      this.gridOnClick();
    }
  }

  private orderInfoData: OrdersByOrderNo = {};
  private inquiryInfoData: ProductInquiriesByInquiryNo = {};
  private getProductInquiriesByInquiryNo({
    inquiryNo,
    memberId,
    memberStatus,
    mallNo,
    admin,
    displayStatusType,
    isScroll = false,
  }: InquiryExtra) {
    this.$api.getProductInquiriesByInquiryNo({ params: { inquiryNo: inquiryNo.toString() } }).then(({ data }): void => {
      this.inquiryInfoData = Object.assign({}, { ...data, memberId, memberStatus, mallNo, admin, displayStatusType });
      this.setReplyOfInquiryByRowkey(data.replied);
      this.grid.setValue(this.rowDataByClick.rowKey, 'repliedDateTime', data.repliedDateTime);

      if (isScroll) {
        const pos = $('#js-purchase-history').offset().top - 53;
        $('html, body')
          .stop(true)
          .animate({ scrollTop: pos });
      }
    });
  }
  private gridOnClick() {
    const { inquiryNo, memberId, memberStatus, mallNo, admin, orderNo, displayStatusType } = this.rowDataByClick;

    if (orderNo) {
      this.$api
        .getOrdersByOrderNo({ params: { orderNo } })
        .then(({ data: { shippings, orderProducts, orderer } }): void => {
          this.orderInfoData = Object.assign({}, { ...shippings[0], ...orderProducts[0], orderer });
        });
    } else {
      this.orderInfoData = Object.assign({});
    }

    this.inquiryInfo.hideReplayArea();

    this.getProductInquiriesByInquiryNo({
      inquiryNo,
      memberId,
      memberStatus,
      mallNo,
      admin,
      displayStatusType,
      isScroll: true,
    });
  }

  private productInquiriesResponse: GetProductInquiries = {
    contents: [],
    totalPage: 0,
    totalCount: 0,
  };

  private defautParams: QuestionsParams = {
    mallNo: '',
    keyword: '',
    startYmd: addDay(new Date(), -7),
    endYmd: getToday(),
    searchType: 'ALL',
    searchDateType: 'REGISTER_YMDT',
    replyStatusType: 'ALL',
    displayStatusType: '',
    page: 1,
    size: 30,
  };

  private requestParams: QuestionsParams = { ...this.defautParams };

  private search(params: QuestionsParams = {}) {
    this.requestParams = Object.assign({ ...this.defautParams }, params);
    this.$api
      .getProductInquiries({ params: this.requestParams })
      .then(response => {
        if (response && response.status === 200) {
          Object.assign(this.productInquiriesResponse, { ...response.data });
        }
      })
      .catch(({ data: { status, code } }: NcpXhrError) => {
        if (status === 400 && code === 'AE001') {
          Object.assign(this.productInquiriesResponse, {
            contents: [],
            totalPage: 0,
            totalCount: 0,
          });
        }
      });
  }

  private displayTypeChangeByChildEvent(displayStatusType: string) {
    this.putProductInquiriesByInquiryNo(this.rowDataByClick.rowKey, displayStatusType, () => {
      this.grid.setValue(this.rowDataByClick.rowKey, 'displayStatusType', displayStatusType);
      const $select = $el(document.querySelector('.questionGridAreaCurrentRow select'));
      const select = $select.getElement();
      select['value'] = displayStatusType;
    });
  }

  private setReplyOfInquiryByRowkey(replied: boolean) {
    this.grid.setValue(this.rowDataByClick.rowKey, 'replied', replied);
  }

  private setSecretOfInquiryByRowkey(isSecret: boolean) {
    this.grid.setValue(this.rowDataByClick.rowKey, 'isSecret', isSecret);
  }

  private putProductInquiriesByInquiryNo(rowKey: number, displayStatusType: string, callback: Function) {
    const { inquiryNo } = this.productInquiriesResponse.contents[rowKey];
    const data = {
      displayStatusType,
      inquiryNo,
    };
    this.$api.putProductInquiriesByInquiryNo({ data }).then(({ status }) => {
      if (status === 204) {
        alert(this.t('ALERT_CHANGED'));
        if (callback) callback();
      }
    });
  }

  public changeDisplayStatusByGrid(rowKey: number, displayStatusType: string, oldVal: string, event) {
    if (
      !confirm(
        window.$t('BOARD.BASIC.QUESTIONS.COMFIRM_CHANGE_DISPLAYTYPE', [
          window.$t(`BOARD.BASIC.QUESTIONS.${displayStatusType}`),
        ]),
      )
    ) {
      event.target.value = oldVal;
      return;
    }
    this.putProductInquiriesByInquiryNo(rowKey, displayStatusType, () => {
      this.grid.setValue(this.rowDataByClick.rowKey, 'displayStatusType', displayStatusType);
      Object.assign(this.inquiryInfoData, { displayStatusType });
    });
  }

  private t(txt: string): string {
    return i18n.call(this, txt);
  }

  private created() {
    // for current page reload case.
    setNoDataMessage(this.$t('BOARD.REVIEW.NO_DATA') as string);
    // for first search with default mallno

    this.search(this.$route.query);
  }

  private mounted() {
    // for route switch case.
    setNoDataMessage(this.$t('BOARD.REVIEW.NO_DATA') as string);
  }
}
