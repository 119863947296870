import { warpHeaderTit } from '@/utils/grid/rendererUtils';

const gridProps = {
  header: {
    height: 51,
    columns: [
      {
        name: 'stockCnt',
        renderer: warpHeaderTit,
      },
    ],
  },
  options: {
    minRowHeight: 46,
    bodyHeight: 300,
    scrollX: true,
    scrollY: true,
    pageOptions: {
      page: 1,
      perPage: 10,
      totalCount: 0,
    },
  },
  columns: [
    {
      header: 'STOCK.MANAGEMENT.HISTORY_CHANGE_DATE_TIME',
      name: 'changeDateTime',
      minWidth: 100,
      align: 'center',
    },
    {
      header: 'STOCK.MANAGEMENT.HISTORY_UPDATE_CNT',
      name: 'updateCnt',
      minWidth: 50,
      align: 'center',
    },
    {
      header: 'STOCK.MANAGEMENT.HISTORY_STOCK_CNT',
      name: 'stockCnt',
      minWidth: 50,
      align: 'center',
    },
    {
      header: 'STOCK.MANAGEMENT.HISTORY_SAFETY_STOCK_CNT',
      name: 'safetyStockCnt',
      minWidth: 50,
      align: 'center',
      formatter: ({ value, isUsingWms }) => (isUsingWms ? value : '-'),
      copyOptions: {
        useFormattedValue: true,
      },
    },
    {
      header: 'STOCK.MANAGEMENT.HISTORY_CONTENTS',
      name: 'contents',
      minWidth: 350,
      align: 'center',
    },
    {
      header: 'STOCK.MANAGEMENT.HISTORY_ADMIN_NAME',
      name: 'adminName',
      minWidth: 100,
      align: 'center',
    },
  ],
};
export default gridProps;
