import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import './directives';
import './logncrash';
// import '@/assets/css/lib/bootstrap-3.3.2/bootstrap.min.css';

// import '@/assets/css/ncp/progressbar.css';
// import '@/assets/css/godo/common.css';
// import '@/assets/css/lib/tui/datepicker/tui-component-datepicker.css';  // 전달받은 css 적용 시 비정상 노출됨
import '@/assets/css/lib/summernote/summernote-lite.min.css';
import '@/assets/css/ncp/ncp.css';
import '@/assets/css/godo/godo.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-grid/dist/tui-grid.css';
//import 'tui-pagination/dist/tui-pagination.css';
// TODO : godo.css에 .tui-pagination.paging-wrap 여기에 스타일 재정의 되어 있는데 실제 tui-grid에서 paging-wrap는 사용되지 않는다. 해당 부분 수정해야 한다.
import '@/assets/css/lib/tui/pagination/common.css';
import '@/assets/css/lib/tui/pagination/tui-component-pagination.css';
import '@/assets/css/lib/tui/pagination/tui-board.css';
// 아래 tui-grid-theme.css는 실제 tui-grid base 스타일이 아니라 그냥 테이블에서 tui-grid 처럼 보이게만 해주는 css라 삭제한다.
//import '@/assets/css/ncp/tui-grid-theme.css';

Vue.config.productionTip = false;

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'kr', // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    kr: require('@/const/i18n/kr/index').default, // 中文语言包
    // 'ja-JP': require('./common/lang/zh'),   // 中文语言包
    // 'en-US': require('./common/lang/en'),    // 英文语言包
    // 'zh-CN': require('./common/lang/en')    // 英文语言包
  },
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
