









































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class OrderRefundInfo extends Vue {
  @Prop({ default: () => [] }) public returnDetailList;

  public changeBankInfo(claimNo): void {
    alert(claimNo);
  }
  private hasRefundAccount(returnDetailItem): boolean {
    return (
      returnDetailItem.refundBankAccount?.account === undefined ||
      returnDetailItem.refundBankAccount.account.length === 0
    );
  }
}
