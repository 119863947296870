import { GridProp } from '@/types';
import { changeDateFormat } from '@/utils/dateFormat';
import { i18n } from '@/main';
export const getCouponManagementSelectType = () => {
  return {
    theSearchType: [
      { name: window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SEARCH_TYPE_ALL'), value: 'ALL' },
      { name: window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SEARCH_TYPE_ISSUE_NO'), value: 'ISSUE_NO' },
      { name: window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SEARCH_TYPE_REGISTRANTER'), value: 'REGISTRANT' },
      { name: window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SEARCH_TYPE_MODIFIER'), value: 'MODIFIER' },
    ],
  };
};

export const getCouponManagementSearchDateType = () => {
  return {
    thePeriodType: [
      { name: window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SEARCH_DATE_TYPE_REGISTRATION'), value: 'REGISTER' },
      { name: window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SEARCH_DATE_TYPE_MODIFIED'), value: 'UPDATE' },
    ],
  };
};

export const getCouponPayStatusGridOption = (): GridProp => {
  return {
    header: {},
    columns: [
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_MEMBER_NUMBER',
        name: 'memberNo',
        align: 'center',
        minWidth: 85,
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
          customValue: (data): string => {
            return data.toString();
          },
        },
        formatter: cell => {
          if (cell.row.memberId === '탈퇴회원') {
            return cell.value.toString();
          }
          return `<a>${cell.value}</a>`;
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_ID',
        name: 'memberId',
        align: 'center',
        minWidth: 105,
        resizable: true,
        formatter: ({ value }) => {
          // if (!row.openIdProvider) return value.toString();

          switch (value) {
            case 'PAYCO':
              return `<span class="color-payco">${i18n.t('PAYCO_KO')}</span>`;
            case 'NAVER':
              return `<span class="color-naver">${i18n.t('NAVER_KO')}</span>`;
            case 'KAKAO':
              return `<span class="color-kakao">${i18n.t('KAKAO_KO')}</span>`;
            case 'FACEBOOK':
              return `<span class="color-facebook">${i18n.t('FACEBOOK_KO')}</span>`;
            default:
              return value.toString();
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_MEMBER_LEVEL',
        name: 'memberGradeName',
        align: 'center',
        minWidth: 105,
        resizable: true,
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_COUPON_NUMBER',
        name: 'couponNo',
        align: 'center',
        minWidth: 85,
        resizable: true,
      },
      {
        header: '지급 성공여부',
        name: 'issueFail',
        align: 'center',
        minWidth: 85,
        resizable: true,
        formatter: ({ value }) => {
          const label = `PROMOTION.COUPON_ISSUE.${value ? 'FAILURE' : 'SUCCESS'}`;
          return i18n.t(label).toString();
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_COUPON_STATUS',
        name: 'couponUseStatusType',
        align: 'center',
        minWidth: 85,
        resizable: true,
        formatter: cell => {
          if (cell.row.issueFail) {
            return '-';
          }
          if (cell.value == 'USABLE') {
            if (cell.row.isWithdrawAble) {
              return `${window.$t('PROMOTION.COUPON_ISSUE.COUPON_STATUS_AVAILABLE')}<br><a>${window.$t(
                'PROMOTION.COUPON_ISSUE.PAYBACK',
              )}</a>`;
            }
            return `${window.$t('PROMOTION.COUPON_ISSUE.COUPON_STATUS_AVAILABLE')}`;
          } else if (cell.value == 'USED') {
            return `${window.$t('PROMOTION.COUPON_ISSUE.COUPON_STATUS_USED')}`;
          } else if (cell.value == 'EXPIRED') {
            return `${window.$t('PROMOTION.COUPON_ISSUE.COUPON_STATUS_EXPIRED')}`;
          } else if (cell.value == 'WITHDRAW') {
            return `${window.$t('PROMOTION.COUPON_ISSUE.COUPON_STATUS_WITHDRAW')}`;
          } else {
            return '';
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_COUPON_ISSUEYMDT',
        name: 'issueYmdt',
        align: 'center',
        minWidth: 125,
        resizable: true, //TODO 发行失败的时候输出- 暂时不要这个判断
        formatter: cell => {
          if (cell.row.issueFail) {
            return '-';
          }
          return cell.value.toString();
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_COUPON_USEENDYMDT',
        name: 'useEndYmdt',
        align: 'center',
        minWidth: 125,
        resizable: true, //TODO 发行失败的时候输出-暂时不要这个判断
        formatter: cell => {
          if (cell.row.issueFail) {
            return '-';
          }
          return cell.value.toString();
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_COUPON_WITHDRAWYMDT',
        name: 'withdrawYmdt',
        align: 'center',
        minWidth: 125,
        resizable: true,
        formatter: cell => {
          if (cell.value == null || cell.value == undefined) {
            return '-';
          } else {
            return cell.value.toString();
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_COUPON_ADMINISSUEREASON',
        name: 'withdrawReason',
        align: 'center',
        minWidth: 125,
        resizable: true,
        formatter: cell => {
          if (cell.value == null || cell.value == undefined) {
            return '-';
          } else {
            return `<div title="${cell.value.toString()}">${cell.value.toString()}</div>`;
          }
        },
      },
    ],
    options: {
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOptions: { showsTopArea: true, showsRightArea: true, hasSettingButton: false, pageSizeKey: 'size' },
  };
};

export const getCouponManagementGridOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_NO', //지급번호
        name: 'no',
        align: 'center',
        minWidth: 80,
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
          customValue: (data): string => {
            return data.toString();
          },
        },
        formatter: cell => {
          return `<a>${cell.value}</a>`;
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_COPY', //복사
        name: 'copy',
        align: 'center',
        minWidth: 80,
        resizable: true,
        formatter: () => {
          return `<a>${window.$t('PROMOTION.COUPON_ISSUE.FORM_HEADER_COPY_VALUE')}</a>`;
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_MALL', //쇼핑몰
        name: 'mallName',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_TIMETYPE', //지급유형
        name: 'timeType', //
        align: 'center',
        minWidth: 80,
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ value }) => {
          if (value.toString() === 'IMMEDIATELY_ISSUE') {
            return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_IMMEDIATELY')}`;
          } else if (value.toString() === 'SCHEDULED_ISSUE') {
            return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_SCHEDULED')}`;
          } else if (value.toString() === 'PERIOD_ISSUE') {
            return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_AUTO')}`;
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_TIME_TYPE', //지급조건
        name: 'timeType1',
        minWidth: 80,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ row }) => {
          if (row.timeType.toString() === 'PERIOD_ISSUE') {
            if (row.couponIssueTargetType.toString() == 'BIRTHDAY') {
              return window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_BIRTHDAY');
            } else if (row.couponIssueTargetType.toString() == 'JOIN') {
              return window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_SIGNUP');
            }
            return ''; //TODO 等待API的关于生日和注册日的字段来判断
          } else {
            return window.$t('-');
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_ISSUESTATUSTYPE', //지급상태
        name: 'issueStatusType',
        minWidth: 80,
        align: 'center',
        resizable: true,
        copyOptions: {
          // useFormattedValue: true,
          customValue: (data): string => {
            if (data.toString() === 'ISSUE_READY') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_READY')}`;
            } else if (data.toString() === 'ISSUE_ING') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_ING')}`;
            } else if (data.toString() === 'ISSUE_END') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_END')}`;
            } else if (data.toString() === 'ISSUE_STOP') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_CANCEL')}`;
            }
            return '';
          },
        },
        formatter: ({ row }) => {
          if ((row.notIssuableCouponNos as string[]).length >= 1) {
            if (row.issueStatusType.toString() === 'ISSUE_READY') {
              return `<div class='alert' style="height:27px" title="${window.$t(
                'PROMOTION.COUPON_ISSUE.NOTICE_MESSAGE',
              )}">${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_READY')}<br><a>${window.$t(
                'PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_CANCEL',
              )}</a></div>`;
            } else if (row.issueStatusType.toString() === 'ISSUE_ING') {
              return `<div class='alert' style="height:27px" title="${window.$t(
                'PROMOTION.COUPON_ISSUE.NOTICE_MESSAGE',
              )}">${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_ING')}<br><a>${window.$t(
                'PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_END',
              )}</a></div>`;
            } else if (row.issueStatusType.toString() === 'ISSUE_END') {
              return `<div class='' style="height:27px" title="${window.$t(
                'PROMOTION.COUPON_ISSUE.NOTICE_MESSAGE',
              )}">${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_END')}</div>`;
            } else if (row.issueStatusType.toString() === 'ISSUE_STOP') {
              return `<div class='' style="height:27px" title="${window.$t(
                'PROMOTION.COUPON_ISSUE.NOTICE_MESSAGE',
              )}">${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_CANCEL')}</div>`;
            }
          } else {
            if (row.issueStatusType.toString() === 'ISSUE_READY') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_READY')}<br><a>${window.$t(
                'PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_CANCEL',
              )}</a>`;
            } else if (row.issueStatusType.toString() === 'ISSUE_ING') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_ING')}<br><a>${window.$t(
                'PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_END',
              )}</a>`;
            } else if (row.issueStatusType.toString() === 'ISSUE_END') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_END')}`;
            } else if (row.issueStatusType.toString() === 'ISSUE_STOP') {
              return `${window.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_STATUS_ISSUE_CANCEL')}`;
            }
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_REQUESTYMDT', //예약일
        name: 'requestYmdt',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ row }) => {
          if (row.requestYmdt === null) {
            return '-';
          } else {
            if (row.timeType.toString() === 'IMMEDIATELY_ISSUE' || row.timeType.toString() === 'PERIOD_ISSUE') {
              return '-';
            } else {
              return changeDateFormat(row.requestYmdt.toString(), 'YYYY-MM-DD HH:mm');
            }
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_ISSUEYMDTSTR', //지급기간
        name: 'issueYmdtStr',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ row }) => {
          if (row.timeType.toString() === 'PERIOD_ISSUE') {
            return (
              changeDateFormat(row.periodStartYmdt.toString(), 'YYYY-MM-DD HH:mm') +
              '~<br>' +
              changeDateFormat(row.periodEndYmdt.toString(), 'YYYY-MM-DD HH:mm')
            );
          } else if (row.issueYmdt) {
            return changeDateFormat(row.issueYmdt.toString(), 'YYYY-MM-DD HH:mm').replace('~', '~<br>');
          } else {
            return '-';
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_ISSUECOUNT', //지급현황
        name: 'issueCount',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ row }) => {
          const { issueCount, issueFailCount } = row;
          const total = Number(issueCount) + Number(issueFailCount);
          return total === 0 ? '-' : `<a>${issueCount}</a> / ${total}`;
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_REGISTERADMINNAME', //등록자
        name: 'registerAdminName',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_REGISTERYMDT', //등록일
        name: 'registerYmdt',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ value }) => {
          if (value === null) {
            return '-';
          } else {
            return changeDateFormat(value.toString(), 'YYYY-MM-DD HH:mm');
          }
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_UPDATEADMINNAME', //최종 수정자
        name: 'updateAdminName',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ value }) => {
          if (!value) {
            return '-';
          }
          return value;
        },
      },
      {
        header: 'PROMOTION.COUPON_ISSUE.GRID_UPDATEYMDT', //최종 수정일
        name: 'updateYmdt',
        minWidth: 100,
        align: 'center',
        resizable: true,
        copyOptions: {
          useFormattedValue: true,
        },
        formatter: ({ value }) => {
          if (value === null) {
            return '-';
          } else {
            return changeDateFormat(value.toString(), 'YYYY-MM-DD HH:mm');
          }
        },
      },
    ],
    options: {
      keyColumnName: 'no',
      rowHeight: 40,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};

export const ISSUE_STATUS = {
  TOTAL: 'TOTAL',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
} as const;
