
























import { Vue, Component, Watch } from 'vue-property-decorator';
import { isEmpty } from 'underscore';
import { GetPartnersActiveRequest, PartnersActive } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { getGridProps } from '@/components/partner/register/RegisterSearchGrid';

@Component({
  components: {
    Grid,
  },
})
export default class PartnerRegisterSearch extends Vue {
  private partners: PartnersActive[] = [];
  private totalCount = 0;

  private gridProps = getGridProps();

  @Watch('$route.query')
  private changedQuery() {
    const query = this.$route.query;
    if (isEmpty(query)) return;

    this.fetchPartners({
      companyName: `${query.companyName}`,
      registrationNo: `${query.registrationNo}`,
    });
  }

  private fetchPartners(params: GetPartnersActiveRequest['params']) {
    this.$api.getPartnersActive({ params }).then(({ data }) => {
      this.partners = data.contents;
      this.totalCount = data.totalCount;
    });
  }

  private setBottomNav(): void {
    throwBottomNavigation({
      buttons: [
        {
          type: 'right',
          key: 'save',
          color: 'red',
          text: '다음',
        },
      ],
      onClick: (key: string) => key === 'save' && this.writeDetail(),
    });
  }

  private writeDetail() {
    const partnerNo = (this.$refs.gridRef as Grid).getCheckedRowKeys()[0]?.toString();

    if (!partnerNo) {
      alert('파트너를 선택해주세요.');
      return;
    }

    this.$emit('write-detail', partnerNo);
  }

  private created() {
    this.setBottomNav();
  }
}
