export enum SearchKeywordType {
  mallProductNo = 'MALL_PRODUCT_NO',
  productName = 'PRODUCT_NAME',
  productManagementCd = 'PRODUCT_MANAGEMENT_CD',
  stockNo = 'STOCK_NO',
  optionManagementCd = 'OPTION_MANAGEMENT_CD',
  mallOptionNo = 'MALL_OPTION_NO',
}

export enum StockCategoryType {
  DISPLAY = 'DISPLAY_CATEGORY',
  STANDARD = 'CATEGORY',
}

export enum PeriodType {
  registerYMDT = 'REGISTER_YMDT',
  startYMDT = 'START_YMDT',
  endYMDT = 'END_YMDT',
  updateYMDT = 'UPDATE_YMDT',
}

export enum SaleStatusTypes {
  onPreSale = 'ON_PRE_SALE',
  waitingSale = 'WAITING_SALE',
  onSale = 'ON_SALE',
  endSale = 'END_SALE',
}

export enum SaleSettingStatusTypes {
  availableForSale = 'AVAILABLE_FOR_SALE',
  stopSelling = 'STOP_SELLING',
  prohibitionSale = 'PROHIBITION_SALE',
}

export const searchOptions = {
  //검색어
  searchKeywordTypes: [
    {
      value: SearchKeywordType.productName,
      name: 'STOCK.MANAGEMENT.PRODUCT_NAME',
    },
    {
      value: SearchKeywordType.mallProductNo,
      name: 'STOCK.MANAGEMENT.MALL_PRODUCT_NO',
    },
    {
      value: SearchKeywordType.productManagementCd,
      name: 'STOCK.MANAGEMENT.PRODUCT_MANAGEMENT_CD',
    },
    {
      value: SearchKeywordType.stockNo,
      name: 'STOCK.MANAGEMENT.STOCK_NO',
    },
    {
      value: SearchKeywordType.mallOptionNo,
      name: 'STOCK.MANAGEMENT.MALL_OPTION_NO',
    },
    {
      value: SearchKeywordType.optionManagementCd,
      name: 'STOCK.MANAGEMENT.OPTION_MANAGEMENT_CD',
    },
  ],

  //기간
  periodTypes: [
    {
      value: PeriodType.registerYMDT,
      name: 'STOCK.MANAGEMENT.TERM_REGISTER_YMDT',
    },
    {
      value: PeriodType.startYMDT,
      name: 'STOCK.MANAGEMENT.TERM_START_YMDT',
    },
    {
      value: PeriodType.endYMDT,
      name: 'STOCK.MANAGEMENT.TERM_END_YMDT',
    },
    {
      value: PeriodType.updateYMDT,
      name: 'STOCK.MANAGEMENT.TERM_UPDATE_YMDT',
    },
  ],

  //판매상태
  saleStatus: {
    name: 'saleStatus',
    hasAll: true,
    noBrackets: true,
    type: 'checkbox',
    data: [
      {
        id: 'all',
        value: 'ALL',
        display: 'ALL', // 전체
      },
      {
        id: 'on-pre-sale',
        value: 'ON_PRE_SALE',
        display: 'PRODUCT.PRODUCTLIST.ON_PRE_SALE', // 예약판매중
      },
      {
        id: 'waiting-sale',
        value: 'WAITING_SALE',
        display: 'PRODUCT.PRODUCTLIST.WAITING_SALE', // 판매대기
      },
      {
        id: 'on-sale',
        value: 'ON_SALE',
        display: 'PRODUCT.SEARCH.SALE_STATUS_ON_SALE', // 판매중
      },
      {
        id: 'end-sale',
        value: 'END_SALE',
        display: 'PRODUCT.SEARCH.SALE_STATUS_END_SALE', // 판매종료
      },
    ],
  },

  //판매설정
  saleSettingStatus: {
    name: 'saleSettingStatus',
    hasAll: true,
    noBrackets: true,
    data: [
      {
        id: 'all',
        value: 'ALL',
        display: 'ALL', // 전체
      },
      {
        id: 'available-for-sale',
        value: 'AVAILABLE_FOR_SALE',
        display: 'PRODUCT.SEARCH.AVAILABLE_FOR_SALE', // 판매가능
      },
      {
        id: 'stop-sale',
        value: 'STOP_SELLING',
        display: 'PRODUCT.SEARCH.STOP_SELLING', // 판매중지
      },
      {
        id: 'prohibition-sale',
        value: 'PROHIBITION_SALE',
        display: 'PRODUCT.SEARCH.PROHIBITION_SALE', // 판매금지
      },
    ],
  },
};

export const resetStocksRequest = () => ({
  mallNos: '',
  categoryType: StockCategoryType.DISPLAY,
  categoryNo: '',
  periodType: PeriodType.registerYMDT,
  startYmdt: '',
  endYmdt: '',
  platformDisplayType: 'NONE',
  minStockAmount: null,
  maxStockAmount: null,
  saleStatusTypes: 'ON_PRE_SALE,WAITING_SALE,ON_SALE,END_SALE',
  saleSettingStatusTypes: 'AVAILABLE_FOR_SALE,STOP_SELLING,PROHIBITION_SALE',
  searchKeywordType: SearchKeywordType.mallProductNo,
  keyword: '',
  brandNo: '',
});
