export default Object.freeze({
  REGISTER_DATE: '등록일',
  SEARCH_DETAILS: '상세검색',
  SAVE_TEMPLATE: '배송비 템플릿 {value}',
  WAREHOUSE_SELECT_DEFAULT: '{대표출고지 주소명} | {대표출고지 주소}',
  ADD_FEE_AREA_SELECT_DEFAULT: '{추가배송지명}',
  TITLE: '배송비 관리',
  ADD_GROUP: '묶음그룹 추가',
  DELETE: '삭제',
  ADD_GROUP_GUIDE: '배송 묶음그룹을 추가해 주세요.',
  GROUP_NAME: '그룹명',
  GROUP_COUNT: '그룹수',
  DETAIL_SETTING: '좌측에서 배송 묶음그룹을 추가하시면 묶음그룹 별 상세설정이 가능합니다.',
  GROUP_DETAIL_SETTING: '그룹 상세 설정',
  DELIVERY_AMT_TEMPLATE: '배송비템플릿',
  DELIVERY_AMT_TEMPLATE_ADD: '배송비 템플릿 추가',
  DELIVERY_AMT_TEMPLATE_NAME: '배송비템플릿명',
  PLACEHOLDER_DELIVERY_AMT_TEMPLATE_NAME: '배송비템플릿명 입력',
  DELIVERY_FEE_TEMPLATE_NAME: '배송비 템플릿명',
  DELIVERY_TYPE: '배송방법',
  DELIVERY_AMT_TYPE: '배송비유형',
  RELEASE_WAREHOUSE: '출고지',
  RETURN_WAREHOUSE: '반품 / 교환지',
  RETURN_WAREHOUSE_WITHOUT_SPACE: '반품/교환지',
  RETURN_AMT: '반품 / 교환배송비',
  RETURN_AMT_WITHOUT_SPACE: '반품/교환배송비',
  PRODUCT: '적용상품',
  VIEW: '보기',
  DELIVERY_AMT_TEMPLATE_ADD2: '배송비 템플릿을 추가하세요.',
  BIND_DELIVERY_AMT: '묶음배송비 산출방식',
  BIND_DELIVERY_AMT2: '최소부과 (각 상품별 산출된 배송비 중 가장 작은 배송비를 부과함)',
  BIND_DELIVERY_AMT3: '최대부과 (각 상품별 산출된 배송비 중 가장 큰 배송비를 부과함)',
  PAY_TYPE: '결제방식',
  AREA_FEE: '지역별 추가 배송비',
  NEW: '새로등록',
  CANCEL: '취소',
  SAVE3: '저장',
  SAVE: '변경사항 저장',
  SAVED: '저장되었습니다.',
  CONFIRM: '변경사항을 저장하지 않고 다른 그룹으로 이동합니다.',
  NEW_GROUP: '새 그룹',
  DELETE2: '등록된 배송비템플릿을 먼저 삭제해주세요.',
  DELETE3: '삭제하시겠습니까?',
  DELETE4: '삭제되었습니다.',
  CONFIRM2: '변경사항이 있습니다. 변경사항 저장한 후에 순서를 조정하실 수 있습니다.',
  ALERT3: '기본 배송비템플릿으로 설정된 템플릿은 삭제가 불가능합니다. 기본 배송비 템플릿 변경 후 삭제할 수 있습니다.',
  ALERT4: '배송비템플릿을 사용중인 상품이 있습니다. 상품의 배송비템플릿을 변경 후 삭제할 수 있습니다.',
  SHOW_DETAIL_TEMPLATE_MESSAGE: '배송비템플릿이 저장되지 않았습니다.',
  USE: '사용함',
  NOT_USE: '사용안함',
  PREPAID_TRUE: '선결제',
  PREPAID_FALSE: '착불',
  DELIVERY_AMT_TEMPLATE2: '배송비 템플릿관리',
  AREA_FEE2: '지역별 추가배송비 관리',

  WAREHOUSE: '입출고주소 관리',
  RETURN_WAREHOUSE_GUIDE: '반품/교환 배송비 가이드',
  RETURN_WAREHOUSE_GUIDE2:
    '반품/교환 배송비를 0원으로 입력하는 경우 무료 반품 / 교환으로 설정되니 금액을 정확히 확인하시기 바랍니다.',
  RETURN_WAREHOUSE_GUIDE3:
    '배송비유형과 관계없이 구매자귀책에 의한 반품/교환 시 "반품/교환배송비"에 설정한 금액으로 청구됩니다. (단, 직접송금/택배동봉 시 제외)',
  RETURN_WAREHOUSE_GUIDE4:
    '(구매자귀책) 반품/교환으로 인해 초도배송비가 재계산 될 경우 그 차액과 반품/교환배송비의 편도금액을 합산하여 청구됩니다.',
  RETURN_WAREHOUSE_GUIDE5: '최초결제 : 30,000원 이상 구매시 배송비 무료 조건 적용 초도배송비 ',
  RETURN_WAREHOUSE_GUIDE6: '2,500원',
  RETURN_WAREHOUSE_GUIDE7: ' 0원 청구 ',
  RETURN_WAREHOUSE_GUIDE8: ' 부분반품 : 무료배송 조건 깨짐 ',
  RETURN_WAREHOUSE_GUIDE9: ' 초도배송비 재계산 2,500원 + 반품/교환배송비 2,500원(편도) = 배송비 명목 5,000원 환불 제외',
  RETURN_WAREHOUSE_GUIDE10:
    '단, 배송비유형이 "무료"인 경우에는 초도배송비 재계산이 불가능하므로 반품/교환배송비가 왕복(편도X2)으로 부과됩니다.',
  DELIVERY_AMT_TEMPLATE_ADD3: '배송비템플릿등록',
  DELIVERY_COMPANY: '택배사 선택',
  DELIVERY_AMT_SETTING: '배송비설정',
  ORDER_AMT: '주문금액',
  ORDER_AMT2: '이상 배송비 무료 / 미만 시',
  ORDER_AMT3: '원',
  ORDER_AMT4: '주문 수량/금액에 상관 없이',
  ORDER_COUNT: '주문 수량',
  EACH_DELIVERY_FEE: '개당 배송비',
  SELECT: '선택하세요',
  MEASURE: '편도기준',
  DEFAULT: '위 배송비템플릿을 상품등록 시 기본배송비로 표시합니다.',
  SAVE4: '완료',
  SAVE2: '확인',
  CLOSE: '닫기',
  GUIDE: '반드시 ‘변경사항 저장’버튼을 클릭하셔야 배송비템플릿 추가 / 변경이 완료됩니다.',
  MESSAGE1: '배송비템플릿명을 입력해주세요!',
  MESSAGE2: '배송비유형을 선택해주세요!',
  MESSAGE3: '배송비를 설정해주세요.',
  MESSAGE4: '출고지를 선택해주세요!',
  MESSAGE5: '반품/교환지를 선택해주세요!',
  MESSAGE6: '반품 배송비를 입력해주세요!',
  MESSAGE7: '주소명을 입력해주세요!',
  MESSAGE8: '대체문구를 입력해주세요!',
  MESSAGE9: '주소를 입력해주세요!',
  MESSAGE10: 'Address 1를 입력해주세요!',
  MESSAGE11: 'Address 2를 입력해주세요!',
  MESSAGE12: 'City를 입력해주세요!',
  MESSAGE13: 'State/Province/Region를 입력해주세요!',
  MESSAGE14: 'Zip / Postal Code를 입력해주세요!',
  RELEASE_WAREHOUSE_ADD: '출고지 등록',
  RETURN_WAREHOUSE_ADD: '반품/교환지 등록',
  DELIVERY_AMT_TEMPLATE_EDIT: '배송비템플릿수정',
  NEW_ADDRESS_ADD: '신규주소 등록',
  ADDRESS_NAME: '주소명',
  COUNTRY: '국가',
  SUBSTITUTION: '대체문구',
  ADDRESS: '주소',
  POST_SELECT: '우편번호 찾기',
  DEFAULT_FLAG: '대표여부',
  DEFAULT_RELEASE: '대표출고지',
  DEFAULT_RETURN: '대표 반품/교환지',
  DEFAULT_RETURN_WITHOUT_SPACE: '대표반품/교환지',
  NAME_ERROR: '그룹명을 입력해주세요.',
  TEMPLATES_ERROR: '배송비템플릿을 추가해주세요.',
  ADDRESS_EDIT: '주소 수정',
  UP: '▲',
  DOWN: '▼',
  ALERT1: '저장되었습니다',
  ALERT2: '배송비템플릿을 추가해주세요.',
  ADDITIONAL_SHIPPING_COST: '지역별 추가배송비 등록',
  ADDITIONAL_SHIPPING: '지역별추가배송비',
  AREAFEE_ADD_TITLE: '지역별 추가배송비 추가',
  AREAFEE_ADD_MESSAGE1:
    '기본 제공되는 지역리스트 중 사용여부 및 추가배송비 설정을 통해 지역별추가배송비를 등록할 수 있습니다.',
  AREAFEE_ADD_MESSAGE2: '국가를 선택하여 해당 국가의 지역별로 추가배송비를 입력하세요.',
  AREAFEE_ADD_MESSAGE3:
    '한국의 경우 등록된 지역의 지번/도로명주소에 관계없이 주문서 적용이 가능하며 미국/캐나다의 경우 주(State)별로입력한 추가배송비가 적용됩니다. 추가배송비는 주문 발생 전까지 수정 가능합니다. 주문 발생 후 수정은 불가하며 복사 후 신규등록해 주세요.',
  AREAFEE_ADD_ADDITIONAL: '지역별추가배송비명',
  AREAFEE_ADD_NATIONAL: '국가선택',
  AREAFEE_ADD_NO: 'NO',
  AREAFEE_ADD_NAME: '지역명',
  AREAFEE_ADD_SHIPPINGCOST: '지역명',
  AREAFEE_ADD_ERROR: '입력형식이 잘못되었습니다.',
  AREAFEE_ADD_SAVE: '저장',
  AREAFEE_ADD_CANCEL: '취소',
  AREAFEE_LIST_DELETE: '복사',
  AREAFEE_LIST_REGISTRATION: '지역별추가배송비등록',
  AREAFEE_LIST_COUNTRY: '국가',
  AREAFEE_LIST_NUMBER: '등록지역수',
  AREAFEE_LIST_DATE: '등록일시',
  AREAFEE_LIST_PERSON: '등록자',
  AREAFEE_ADD_CIRCLE: '원',
  AREAFEE_ADD_CONFIRM1: '취소하시겠습니까?',
  AREAFEE_ADD_ALERT1: '지역별추가배송비명을 입력해주세요.',
  AREAFEE_ADD_ALERT2: '추가 배송비를 입력해주세요.',
  AREAFEE_ADD_ALERT3: '저장되었습니다.',
  AREAFEE_LIST_CONFIRM1: '선택항목을 삭제하시겠습니까?',
  AREAFEE_LIST_ALERT1: '선택항목이 없습니다.',
  AREAFEE_LIST_MODIFY: '지역별 추가배송비 수정',
  AREAFEE_LIST_ADDED: '지역별 추가배송비 추가',
  AREEFEE_ADD_ADDITIONAL_SETTING: '지역별추가배송비설정',
  AREAFEE_ADD_ADDITIONAL_SHIPPING_COST: '추가배송비 ',
  AREAFEE_MODIFY_REQURIED: '필수',
  AREAFEE_MODIFY_ADDITIONAL_SHIPPING_COSTS: '지역별 추가배송비 수정',

  WAREHOUSE_LIST_ADDRESS_TOTAL: '주소지 총',
  WAREHOUSE_LIST_GUN: '건',
  WAREHOUSE_LIST_DELETE: '선택주소 삭제',
  WAREHOUSE_LIST_REGISTRATION: '입출고 주소등록',
  WAREHOUSE_LIST_STATUS: '대표여부',
  WAREHOUSE_LIST_COUNTRY: '국가',
  WAREHOUSE_LIST_ADDRESS_NAME: '주소명',
  WAREHOUSE_LIST_ADDRESS: '주소',
  WAREHOUSE_LIST_REPRESENTATIVE_FOWARDING_PLACE: '대표 출고지',
  WAREHOUSE_LIST_REPRESENTATIVE_RETURN: '대표 반품/교환지',
  WAREHOUSE_LIST_KOREA: '대한민국',
  WAREHOUSE_LIST_CONFIRM1: '선택항목을 삭제하시겠습니까?',
  WAREHOUSE_LIST_ALERT1: '선택항목이 없습니다.',
  WAREHOUSE_LIST_FORWARDING_PLACE: '반품/출고지 등록',
  WAREHOUSE_LIST_REVISION: '반품/출고지 수정',
  WAREHOUSE_ADD_ADDRESS1: 'Address 1',
  WAREHOUSE_ADD_ADDRESS2: 'Address 2',
  WAREHOUSE_ADD_ZIP: 'Zip / Postal Code',
  WAREHOUSE_ADD_CITY: 'City',
  WAREHOUSE_ADD_STATE: ' State/Province/Region',
  WAREHOUSE_ADD_CONFIRM1: '취소하시겠습니까?',
  WAREHOUSE_ADD_ALERT1: '수정되었습니다.',
  WAREHOUSE_ADD_ALERT2: '저장되었습니다.',
  WAREHOUSE_ADD_ALERT3: '주소명을 입력해주세요.',
  WAREHOUSE_ADD_ALERT4: '대체문구를 입력해주세요.',
  WAREHOUSE_ADD_ALERT5: '주소를 입력해주세요.',
  WAREHOUSE_ADD_ALERT6: 'Address 1를 입력해주세요.',
  WAREHOUSE_ADD_ALERT7: 'Address 2를 입력해주세요.',
  WAREHOUSE_ADD_ALERT8: 'City를 입력해주세요.',
  WAREHOUSE_ADD_ALERT9: 'State/Province/Region를 입력해주세요.',
  WAREHOUSE_ADD_ALERT0: 'Zip / Postal Code를 입력해주세요.',
  WAREHOUSE_ADD_POSTAL_CODE: '우편번호 찾기',
  TITLE2: '배송비템플릿 적용상품보기',
  GROUP_NAME2: '묶음배송그룹',
  TEMPLATE_NAME: '배송템플릿명',
  ALL: '총',
  GE: '개',
  PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  MANAGEMENT_CODE: '상품관리코드',
  SALE_STATUS: '판매상태',
  BUNDLE_DELIVERY: '묶음배송 가능여부',
  OVERSEA_DELIVERY: '해외배송 여부',
  SALE_DATE: '판매기간',
  POSSIBLE: '가능',
  IMPOSSIBLE: '불가능',
  RESERVATION_ONSALE: '예약판매중',
  RESERVATION_FINISHED: '예약판매종료',
  READY: '판매대기',
  ONSALE: '판매중',
  STOP: '판매중지',
  SUB_TITLE: '묶음배송그룹은 상품관리',
  SUB_TITLE2: '배송비관리에서 설정 가능합니다.',
  PARCEL_DELIVERY: '택배/등기/소포',
  DIRECT_DELIVERY: '직접배송',
  DELIVERY_CONDITION_TYPE_SELECT: '배송비 유형 선택',
  FREE: '무료',
  CONDITIONAL: '조건부 무료',
  FIXED_FEE: '유료(고정 배송비)',
  QUANTITY_PROPOSITIONAL_FEE: '수량비례',
  NEW2: '신규등록',
  RETURN_ADDRESS: '반품주소지',
  RETURN_DELIVERY_AMT: '반품배송비',
  RETURN_DELIVERY_AMT_GUIDE: '반품/교환배송비 가이드',
  RETURN_DELIVERY_AMT_GUIDE2:
    '반품/교환 배송비를 0원으로 입력하는 경우 무료 반품 / 교환으로 설정되니 금액을 정확히 확인하시기 바랍니다.',
  RETURN_DELIVERY_AMT_GUIDE3:
    '배송비유형과 관계없이 구매자귀책에 의한 반품/교환 시 "반품/교환배송비"에 설정한 금액으로 청구됩니다. (단, 직접송금/택배동봉 시 제외)',
  RETURN_DELIVERY_AMT_GUIDE4:
    '(구매자귀책) 반품/교환으로 인해 초도배송비가 재계산 될 경우 그 차액과 반품/교환배송비의 편도금액을 합산하여 청구됩니다.',
  RETURN_DELIVERY_AMT_GUIDE5: '최초결제 : 30,000원 이상 구매시 배송비 무료 조건 적용 초도배송비',
  RETURN_DELIVERY_AMT6: '2,500원',
  RETURN_DELIVERY_AMT7: '0원 청구',
  RETURN_DELIVERY_AMT8: '부분반품 : 무료배송 조건 깨짐',
  RETURN_DELIVERY_AMT9: '초도배송비 재계산 2,500원 + 반품/교환배송비 2,500원(편도) = 배송비 명목 5,000원 환불 제외',
  RETURN_DELIVERY_AMT10:
    '단, 배송비유형이 "무료"인 경우에는 초도배송비 재계산이 불가능하므로 반품/교환배송비가 왕복(편도X2)으로 부과됩니다.',
  RELEASE_WAREHOUSE_SELECT: '출고지 선택',
  RETURN_WAREHOUSE_SELECT: '반품/교환지 선택',

  ADD_FEE_NAME: '지역별 추가배송비명',

  PLZ_RETURN_DELIVERY_FEE: '반품/교환배송비를 입력해주세요.',
  PLZ_RETURN_DELIVERY_AREA: '반품/교환지를 입력해주세요.',
  PLZ_WAREHOUSE_AREA: '출고지를 입력해주세요.',
  PLZ_DELIVERY_FEE: '배송비를 설정해주세요.',
  PLZ_DELIVERY_FEE_TYPE: '배송비 유형을 입력해주세요.',
  PLZ_DELIVERY_TEMPLATE_NAME: '배송비템플릿명을 입력해주세요.',
  NOTICE_QUANTITY_PROPOSITIONAL_FEE_UNIT: '수량비례의 단위는 1이상으로 설정해야 합니다.',

  ALERT_DELETED: '삭제되었습니다.',
  CONFIRM_DELETE: '삭제하시겠습니까?',
  ALERT_NOT_DELETED: '해당 입출고 주소를 사용 중인 배송비 템플릿이 존재하여 삭제가 불가합니다.',
  APPLIED_COUNT: '적용상품 (총 {value}개)',
  NO_DATA: '적용상품이 없습니다.',

  ALERT_NOT_DELETED_TEMPLATE: '해당 템플릿을 사용중인 상품이 존재하여 삭제가 불가합니다.',
});
