import Cookies from 'js-cookie';
import { I18N_LOCALE } from '@/const/cookie';
import { SummernoteOption } from '@/types/summernote/summernote';

export const langMap = {
  en: 'en-US',
  kr: 'ko-KR',
  ja: 'ja-JP',
  zh: 'zh-CN',
};

export const getDefaultOptions = (): SummernoteOption => ({
  lang: langMap[Cookies.get(I18N_LOCALE)],
  placeholder: '',
  width: 768,
  height: 300,
  tabsize: 2,
  noHideDimmed: false,
  fontSize: 12,
  imageFileSize: 5120,
  useVideo: false,
  disableDragAndDrop: false,
  insertList: ['link', 'picture'],
  disableImageUpload: false,
  focus: true,
  toolbar: [
    ['style', ['style']],
    ['fontsize', ['fontsize']],
    ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
    ['color', ['color']],
    // ['para', ['ul', 'ol', 'paragraph']],
    ['para', ['paragraph']],
    // ['table', ['table']],
    // ['insert', ['link', 'picture', 'video']],
    ['insert', ['link', 'picture']],
    ['view', ['fullscreen', 'codeview']],
  ],
  codeviewFilter: false,
  minHeight: 120,
});
