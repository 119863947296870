import { RadioBoxGroupOption } from '@/helpers/type';
import { i18n } from '@/main';
import {
  GetMembersJoinConfig,
  MallsDataResponse,
  OrderconfigsCartResponse,
  PostOrderconfigsOrderRequest,
  ContractfilesMallsResponse,
} from 'ncp-api-supporter';
import { TradeBankAccountItem } from '@/types/mallCreate';
import { ValueOf } from '@/types';

//TODO 임시 옵션
export const getIntroPageOptions = (name: string): RadioBoxGroupOption<string> => ({
  name: `introPage_${name}`,
  data: [
    {
      id: 'none',
      value: 'NONE',
      display: 'MALL.FORM.MALL_USELESS',
    },
    {
      id: 'no-access',
      value: 'NO_ACCESS',
      display: 'MALL.FORM.DISCONNECT',
    },
    {
      id: 'only-member',
      value: 'ONLY_MEMBER',
      display: 'MALL.FORM.MEMBER_CERTIFICATION',
    },
    {
      id: 'only-adult',
      value: 'ONLY_ADULT',
      display: 'MALL.FORM.ADULT_CERTIFICATION',
    },
  ],
});

// API 필드명과 동일하게 명명한다.

export const storagePeriod = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
  { name: '24', value: '24' },
  { name: '25', value: '25' },
  { name: '26', value: '26' },
  { name: '27', value: '27' },
  { name: '28', value: '28' },
  { name: '29', value: '29' },
  { name: '30', value: '30' },
];
export const storageMaxQuantity = [
  { name: '10', value: '10' },
  { name: '20', value: '20' },
  { name: '30', value: '30' },
  { name: '40', value: '40' },
  { name: '50', value: '50' },
  { name: '100', value: '100' },
];

export const getUsesOrNot = (name: string): RadioBoxGroupOption<boolean> => ({
  name: `orderConfig_${name}`,
  data: [
    {
      id: `${name}_yes`,
      value: true,
      display: 'MALL.FORM.MALL_USED',
    },
    {
      id: `${name}_no`,
      value: false,
      display: 'MALL.FORM.MALL_USELESS',
    },
  ],
});

export const joinApproveSetting = (): RadioBoxGroupOption<boolean> => ({
  name: `join-approve`,
  data: [
    {
      id: `approve-not-used`,
      value: false,
      display: 'MALL.FORM.APPROVE_NOT_USED',
    },
    {
      id: `approve-used`,
      value: true,
      display: 'MALL.FORM.APPROVE_USED',
    },
  ],
});

// 회원 인증 설정 - 인증 시점 설정
export const authenticationTimeType: RadioBoxGroupOption<string> = {
  name: 'mallJoinConfig.authenticationTimeType',
  data: [
    {
      id: 'JOIN_TIME',
      value: 'JOIN_TIME',
      display: 'MALL.FORM.MALLJOINCONFIG_TYPE2_JOIN_TIME',
    },
    {
      id: 'PAYMENT_TIME',
      value: 'PAYMENT_TIME',
      display: 'MALL.FORM.MALLJOINCONFIG_TYPE2_PAYMENT_TIME',
    },
  ],
};

// 회원 인증 설정 - 인증 수단 설정
export const memberAuthenticationType: RadioBoxGroupOption<string> = {
  name: 'mallJoinConfig.memberAuthenticationType',
  data: [
    {
      id: 'email',
      value: 'AUTHENTICATION_BY_EMAIL',
      display: 'MALL.FORM.MALLJOINCONFIG_TYPE_EMAIL',
    },
    {
      id: 'sms',
      value: 'SMS_AUTHENTICATION',
      display: 'MALL.FORM.MALLJOINCONFIG_TYPE_SMS',
    },
    {
      id: 'phone',
      value: 'AUTHENTICATION_BY_PHONE',
      display: 'MALL.FORM.MALLJOINCONFIG_TYPE_PHONE',
    },
  ],
};

// 회원 인증 설정 - NHN KCP 휴대폰인증 설정 설정
export const kcpAuthentication: RadioBoxGroupOption<string> = {
  name: 'mallJoinConfig.kcpAuthentication',
  data: [
    {
      id: 'KCP',
      value: 'KCP',
      display: 'MALL.FORM.MALL_USED',
    },
    {
      id: 'NONE',
      value: 'NONE',
      display: 'MALL.FORM.MALL_USELESS',
    },
  ],
};

export const getDefaultTradeBank = (): TradeBankAccountItem => ({
  bankAccount: '',
  bankDepositorName: '',
  bank: '',
  bankName: '',
});

export interface MallType extends Partial<MallsDataResponse> {
  mallId?: string;
}

export const getDefaultMallData = (): MallType => ({
  mallName: '',
  countryCode: 'KR', //고정값
  timeZone: 'ASIA_SEOUL', //고정값
  mallId: '',
  tradeBanks: [getDefaultTradeBank()],
  serviceCenter: {
    outboundEmail: '', //email과 같은값 전송
    phoneNo: '',
    email: '',
  },
  introRedirection: {
    pc: 'ONLY_ADULT',
    mobile: 'ONLY_MEMBER',
  },
});

export const getDefaultPayTypes = (): { [key in string]?: { exposure: boolean; enable?: boolean } } => ({
  ACCOUNT: { exposure: true },
  CREDIT_CARD: { exposure: false },
  REALTIME_ACCOUNT_TRANSFER: { exposure: false },
  VIRTUAL_ACCOUNT: { exposure: false },
  MOBILE: { exposure: false },
  ESCROW_REALTIME_ACCOUNT_TRANSFER: { exposure: false },
  ESCROW_VIRTUAL_ACCOUNT: { exposure: false },
  EASY_PAYCO: { exposure: false }, //페이코간편결제
  PAYCO: { exposure: false },
  NAVER_EASY_PAY: { exposure: false },
  NAVER_PAY: { exposure: false },
});

export const getDefaultCartConfig = (): OrderconfigsCartResponse['value'] => ({
  storagePeriodNoLimit: false,
  storagePeriod: 7,
  storageMaxQuantity: 10,
  cartEquivalentOptionUnitType: 'QUANTITY',
});

export const getDefaultMallJoinConfig = (): Partial<GetMembersJoinConfig> => ({
  memberAuthenticationType: 'AUTHENTICATION_BY_EMAIL',
  authenticationTimeType: 'JOIN_TIME',
});

export const getDefaultOrderConfig = (): Partial<PostOrderconfigsOrderRequest['data']> => ({
  naverOrderDeliveryMethodType: 'DELIVERY_TEMPLATE',
});

export const i18nMallCreate = (message: string, key = 'MALL.FORM.'): string => {
  return i18n.t(key + message).toString();
};

export const alertMessage = {
  emptyMallName: 'MALL_NAME_VALIDATION',
  emptyMallAddress: 'MALL_ADDRESS_VALIDATION',
  mallAddressLength: 'MALL_ADDRESS_LENGTH_VALIDATION',
  mallAddressDuplication: 'MALL_ADDRESS_DUPLICATION',
  phoneNo: 'MALL_PHONE_NO_VALIDATION',
  phoneNoLength: 'MALL_PHONE_NO_LENGTH_VALIDATION',
  email: 'MALL_EMAIL_VALIDATION',
  pcProductUri: 'PC_PRODUCT_URI_NO_LENGTH_VALIDATION',
};

export const tempAccount = {
  tempBank: 'KB',
  tempBankAccount: '0000000000',
  tempBankDepositorName: '임시',
};

export type UsedPhoneAuthenticationType = 'KCP' | 'NONE';

export const getContractFiles = (): ContractfilesMallsResponse => {
  return {
    domesticFiles: [
      {
        domestic: true,
        originalName: '',
        displayOrder: 0,
        uploadedName: '',
      },
    ],
    globalFiles: [
      {
        domestic: false,
        originalName: '',
        displayOrder: 0,
        uploadedName: '',
      },
    ],
    domesticGuide: '',
    globalGuide: '',
  };
};

export type FileType = 'domesticFiles' | 'globalFiles';

export const SETTLEMENT_HOLIDAY_OPTIONS = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
  { name: '24', value: '24' },
  { name: '25', value: '25' },
  { name: '26', value: '26' },
  { name: '27', value: '27' },
] as const;

export const SETTLEMENT_ORDER_STATUS_TYPE = {
  DELIVERY_ING: 'DELIVERY_ING',
  DELIVERY_DONE: 'DELIVERY_DONE',
  BUY_CONFIRM: 'BUY_CONFIRM',
} as const;

export type SettlementOrderStatusType = ValueOf<typeof SETTLEMENT_ORDER_STATUS_TYPE>;

export const SETTLEMENT_HOLIDAY_TYPE = {
  YESTERDAY: 'YESTERDAY',
  TOMORROW: 'TOMORROW',
} as const;

export type SettlementHolidayType = ValueOf<typeof SETTLEMENT_HOLIDAY_TYPE>;

export const settlementSettingRadioOption = {
  orderStatus: {
    name: 'settlement-order-status',
    data: Object.values(SETTLEMENT_ORDER_STATUS_TYPE).map(type => ({
      id: `settlement-${type}`,
      value: type,
      display: `MALL.FORM.${type}`,
    })),
  },
  domesticHolidayType: {
    name: 'domestic-holiday-type',
    data: Object.values(SETTLEMENT_HOLIDAY_TYPE).map(type => ({
      id: `domestic-${type}`,
      value: type,
      display: `MALL.FORM.${type}`,
    })),
  },
  overseaHolidayType: {
    name: 'oversea-holiday-type',
    data: Object.values(SETTLEMENT_HOLIDAY_TYPE).map(type => ({
      id: `oversea-${type}`,
      value: type,
      display: `MALL.FORM.${type}`,
    })),
  },
};
