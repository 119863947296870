
























































































































import { Vue, Component, Ref } from 'vue-property-decorator';
import { GetPartnersRequest } from 'ncp-api-supporter';

import SearchButton from '@/components/searchForm/SearchButton.vue';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import MerchandiserSelect from '@/components/common/input/data/MerchandiserSelect.vue';

import {
  getPartnerListRadioGroup,
  getPartnerListSelectBox,
  PARTNER_VALIDATION_INFO,
  PartnerValidType,
} from '@/const/contents/partner';
import { DEFAULT_TIME_RANGE, DEFAULT_DATE_RANGE } from '@/components/common/datepicker/dateRange';
import { PARTNER_STATUS, REGISTERED_PRODUCT } from '@/const/partner';

import { getDefaultPartnerSearchForm } from '@/views/top/partner/list/PartnerList';
import { i18nForPartnerList } from '@/views/contents/partner/list/PartnerList';
import { ValidationInformation } from '@/types';

@Component({
  components: {
    DateRangePicker,
    SearchButton,
    SearchKeyword,
    SelectBox,
    MallSelect,
    RadioGroup,
    TextInput,
    MerchandiserSelect,
  },
})
export default class PartnerList extends Vue {
  @Ref()
  private readonly searchButtonRef: SearchButton;

  private readonly i18nForPartnerList = i18nForPartnerList;

  private readonly partnerSelectBox = getPartnerListSelectBox();
  private readonly partnerRadioGroup = getPartnerListRadioGroup();

  private partnerSearchForm = { ...getDefaultPartnerSearchForm() };
  private get partnerSearchFormQuery(): GetPartnersRequest['params'] {
    return {
      ...this.partnerSearchForm,
      partnerStatus:
        this.partnerSearchForm.partnerStatus === PARTNER_STATUS.ALL ? undefined : this.partnerSearchForm.partnerStatus,
      productRegistered:
        this.partnerSearchForm.productRegistered === REGISTERED_PRODUCT.ALL
          ? undefined
          : this.partnerSearchForm.productRegistered === REGISTERED_PRODUCT.REGISTERED,
      startDateTime: `${
        !this.partnerSearchForm.startDateTime ? DEFAULT_DATE_RANGE.MIN : this.partnerSearchForm.startDateTime
      } ${DEFAULT_TIME_RANGE.START}`,
      endDateTime: `${
        !this.partnerSearchForm.endDateTime ? DEFAULT_DATE_RANGE.MAX : this.partnerSearchForm.endDateTime
      } ${DEFAULT_TIME_RANGE.END}`,
    };
  }

  private get keywordValidationInfo(): ValidationInformation<PartnerValidType> {
    return PARTNER_VALIDATION_INFO[this.partnerSearchForm.searchType] ?? { validType: '', size: null };
  }

  private changeMall(currMallNo: number) {
    this.$set(this.partnerSearchForm, 'mallNo', currMallNo);
  }

  private sendQueryUrl() {
    this.$nextTick(this.searchButtonRef.sendQueryUrl);
  }

  private resetAll() {
    this.partnerSearchForm = { ...getDefaultPartnerSearchForm() };
    this.sendQueryUrl();
  }

  private mounted() {
    this.sendQueryUrl();
  }
}
