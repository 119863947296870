export default Object.freeze({
  TITLE: '할인쿠폰 지급등록',
  DETAIL_ONE: '발행한 쿠폰을 회원에게 지급 설정하는 화면입니다.',
  DETAIL_TWO: '휴면회원 및 탈퇴회원은 할인쿠폰 지급 대상에서 제외됩니다.',
  DETAIL_THREE:
    '지급할 회원 대상은 회원별(등급/그룹) 또는 조건별(생일자, 신규회원가입, 신규앱설치)를 선택할 수 있습니다.',
  DETAIL_FOUR: '조건별 대상의 생일자, 신규회원가입, 신규앱설치는 설정한 기간 동안 조건에 맞게 지급됩니다.',
  FORM_TITLE: '지급 대상 설정',
  FORM_DISC: '* 필수입력',
  FORM_TITLE_ONE: '쇼핑몰',
  FORM_ONE_SELECT: '선택',
  FORM_TITLE_TWO: '지급 회원대상',
  FORM_TWO_RADIO_ONE: '회원별',
  FORM_TWO_BUTTON_ONE: '회원등급/그룹',
  FORM_TWO_BUTTON_TWO: '회원조회',
  FORM_TWO_DETAIL_ONE_BEFORE: '회원',
  FORM_TWO_DETAIL_ONE_AFTER: '명 선택되었습니다. (중복 회원 제외)',
  FORM_TWO_DETAIL_TWO: '예약지급인 경우 설정일과 지급일 회원대상이 일치하지 않을 수 있습니다.',
  FORM_TWO_RADIO_TWO: '조건별',
  FORM_TWO_SELECT_BIRTHDAY: '생일자',
  FORM_TWO_SELECT_JOIN: '신규회원가입',
  FORM_TWO_SELECT_INSTALL_APP: '신규앱설치',
  FORM_TWO_BIRTHDAY_DETAIL: 'ⓘ 가입된 회원의 생일 당일에 쿠폰이 순차 지급됩니다.',
  FORM_TWO_JOIN_DETAIL: 'ⓘ 신규로 가입한 회원에게 순차 지급됩니다.',
  FORM_TWO_INSTALL_APP_DETAIL: 'ⓘ 신규로 앱 설치한 회원에게 순차 지급됩니다.',
  FORM_TITLE_THREE: '지급 내용(사유)',
  FORM_TITLE_FOUR: '지급 설정',
  FORM_FOUR_RADIO_ONE: '즉시지급',
  FORM_FOUR_RADIO_TWO: '예약지급',
  FORM_FOUR_RADIO_THREE: '기간지급',
  FORM_TITLE_FIVE: '지급알림 설정',
  FORM_FIVE_RADIO_ONE: '설정안함',
  FORM_FIVE_RADIO_TWO: '설정함',
  FORM_FIVE_LABEL_ONE: '지급알림 수단설정',
  FORM_FIVE_CHECK_ONE: '전체선택',
  FORM_FIVE_CHECK_TWO: '카카오 알림톡',
  FORM_FIVE_CHECK_THREE: 'SMS',
  FORM_FIVE_CHECK_FOUR: '이메일',
  FORM_FIVE_LABEL_TWO: '지급알림 시간설정',
  FORM_FIVE_DETAIL_ONE: '설정한 알림수단을 사용처리 해야 정상 발송될 수 있습니다.',
  FORM_FIVE_DETAIL_TWO: '설정한 수단으로 실제 쿠폰을 지급받는 일자에 알림이 갑니다.',
  TABLE_TITLE: '할인쿠폰 설정',
  TABLE_FIELD: '쿠폰 개수',
  TABLE_COUPON_UNIT: '건',
  TABLE_HEADER_ONE: '쿠폰 리스트',
  TABLE_HEADER_TWO: '할인쿠폰 번호',
  TABLE_HEADER_THREE: '발행수량',
  TABLE_HEADER_FOUR: '발급현황',
  TABLE_HEADER_FIVE: '쿠폰이름',
  TABLE_HEADER_SIX: '발행기간',
  TABLE_ONE_CONTENT: '쿠폰',
  TABLE_ONE_DELETE: '삭제',
  TABLE_TWO_BUTTON: '쿠폰선택',
  SUBMIT_BUTTON: '할인쿠폰 지급하기',
  CANCEL_BUTTON: '취소',

  MALL_IS_EMPTY: '쇼핑몰을 선택해주세요.',
  TARGETS_IS_EMPTY: '지급 회원대상을 선택해주세요.',
  MEMBER_COUNT_IS_EMPTY: '선택된 회원이 0명입니다. 지급 회원 대상을 다시 설정해주세요.',
  MEMBER_COUNT_IS_EMPTY_WHEN_RESERVATION:
    '선택된 회원이 0명입니다. 예약지급일의 회원대상이 상이할 수 있으므로, 예약지급일 후 실제 지급된 회원을 체크하시기 바랍니다.',
  ALREADY_SELECTED_COUPON: '이미 선택된 쿠폰입니다.',
  IS_CANCEL: '작성한 내용을 취소하시겠습니까?',
  IS_CANCEL2: '쿠폰 수정을 취소하겠습니까? 수정하신 내용은 저장되지 않습니다.',
  NOT_VALID_DATE: '유효하지 않은 시간입니다.',
  NOT_CHECKED_NOTIFICATION_TOOLS: '지급알림 수단을 1개 이상 설정해주세요.',
  NOTIFICATION_DATE_MUST_FUTURE_THAN_REQUEST_DATE: '할인쿠폰 지급시간보다 지급알림을 과거 시간으로 설정할 수 없습니다.',
  NO_COUPON: '할인쿠폰을 1개 이상 설정해주세요.',
  SUCCESS_MESSAGE1:
    '할인쿠폰 지급 설정이 완료되었습니다.\n선택된 회원이 많은 경우, 지급 완료까지의 시간이 다소 소요될 수 있습니다.',
  SUCCESS_MESSAGE2: '할인쿠폰 지급 예약이 설정되었습니다.',
  SUCCESS_MESSAGE3: '수정되었습니다.',
  CONFIRM_MODIFY: '변경된 내용을 저장하시겠습니까?',
  CONFIRM_ISSUE_CANCEL: '쿠폰 지급을 취소하시겠습니까? 취소 후에는 수정이 불가능합니다',
  CONFIRM_ISSUE_END: '쿠폰 지급을 종료하시겠습니까? 종료 후에는 수정이 불가능합니다.',
  ISSUE_CANCELED: '쿠폰발급이 취소되었습니다.',
  ISSUE_END: '쿠폰발급이 종료되었습니다.',
  CONFIRM_COPY: '이 쿠폰을 복사등록 하시겠습니까?',
  CONFIRM_INIT_MEMBER: '회원별 설정한 회원이 초기화됩니다.',
});
