


























import { Component, Ref } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SmsSend from '@/views/contents/member/sms/SmsSend.vue';
@Component({
  components: { SmsSend },
})
export default class SmsSendManager extends WindowPopupMainVue {
  @Ref()
  private readonly smsSendForm!: SmsSend;
}
