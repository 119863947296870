




















































































import { Component, Vue } from 'vue-property-decorator';
import OrderProducts from '@/components/popup/claim/ClaimApplyPopup/OrderProducts.vue';
import DiscountShippingFee from '@/components/popup/claim/ClaimApplyPopup/DiscountShippingFee.vue';
import ProductDetails from '@/components/popup/claim/ClaimApplyPopup/ProductDetails.vue';
import RefundExchangeAmount from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeAmount.vue';
import BenefitsPaid from '@/components/popup/claim/ClaimApplyPopup/BenefitsPaid.vue';
import NoticeBox from '@/components/popup/claim/ClaimApplyPopup/NoticeBox.vue';
import { ComponentItemsClaimDataType, ClaimableOption } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import {
  PostOptionCancelsRequest,
  GetClaimsOrderOptionsOrderOptionNoApplyRequest,
  PostOptionCancelsPredictRequest,
  RefundType,
  ReasonType,
} from 'ncp-api-supporter';
import { uniqBy } from '@/utils/common';

@Component({
  components: {
    NoticeBox,
    BenefitsPaid,
    RefundExchangeAmount,
    OrderProducts,
    DiscountShippingFee,
    ProductDetails,
  },
})
export default class CancelApplyPopup extends Vue {
  private refOrderProducts;
  private refProductDetails;
  private refDiscountShippingFee;
  private refRefundExchangeAmount;
  private refBenefitsPaid;
  private claimType = 'Cancel';
  private title = '';
  private responsibleObjectType!: string;
  private displayedDeliveryAmtDiscountMessage = false;
  private refundTypeDivShow = true;
  private zeroRefundShow = false;
  private refundBankInfoTrShow = true;
  private refundTypeValue: string;
  private postOptionCancelsPredict;
  private showA = false;
  private showB = false;
  private overflowsPgAmt = false;
  private holdProcess = false;
  private holdByReservation = false;
  private errorFlg = false;
  private isShow = false;
  private orderStatusDepositWait = false;
  private orderOptionsApply: ComponentItemsClaimDataType = {
    orderNo: '',
    claimableOptions: [],
    refundTypes: [],
  };
  private mall = {
    countryCd: 'KR',
  };

  created() {
    this.initPostOptionCancelsPredict();
    this.getClaimOrderPredict();
  }

  private getClaimOrderPredict() {
    this.errorFlg = false;
    const request: GetClaimsOrderOptionsOrderOptionNoApplyRequest = {
      pathParams: {
        orderOptionNo: this.$route.query.orderProductOptionNo.toString(),
      },
      params: {
        claimType: 'CANCEL',
      },
    };
    this.$api
      .getClaimsOrderOptionsOrderOptionNoApply(request)
      .then(ret => {
        this.isShow = true;
        this.holdProcess = false;
        this.holdByReservation = false;
        this.orderOptionsApply.claimableOptions = [];
        ret.data.claimableOptions.forEach(item => {
          const claimableOption: ClaimableOption = {
            ...item,
            checkboxValue: item.orderOptionNo == ret.data.claimedOption.orderOptionNo,
            mallOptionNo: item.mallOptionNo,
            userInputText: item.userInputText,
            orderNo: item.orderNo,
            immediateDiscountAmt: item.immediateDiscountAmt,
            salePrice: item.salePrice,
            orderStatusType: item.orderStatusType,
            optionValue: item.optionValue,
            orderCnt: item.orderCnt,
            addPrice: item.addPrice,
            mallProductNo: item.mallProductNo,
            claimedCnt: item.claimedCnt,
            productName: item.productName,
            claimStatusType: item.claimStatusType,
            additionalDiscountAmt: item.additionalDiscountAmt,
            optionName: item.optionName,
            orderOptionNo: item.orderOptionNo,
            partnerNo: Number(ret.data.partnerNo),
            partnerName: ret.data.partnerName,
            payType: '',
            productUrl: item.productUrl,
            holdProcess: item.holdProcess,
            holdByReservation: item.holdByReservation || this.holdByReservation,
            optionManagementCd: item.optionManagementCd,
            usesOption: item.usesOption,
            hasSetOption: item.hasSetOption,
          };
          this.holdProcess = item.holdProcess || this.holdProcess;
          this.holdByReservation = item.holdByReservation || this.holdByReservation;
          this.orderStatusDepositWait = item.orderStatusType === 'DEPOSIT_WAIT';
          this.orderOptionsApply.claimableOptions.push(claimableOption);
        });
        this.orderOptionsApply.refundTypes = ret.data.refundTypes;
        this.orderOptionsApply.orderNo = ret.data.claimedOption.orderNo;
        this.orderOptionsApply.payType = ret.data.payType;
        this.showA = true;
        this.responsibleObjectType = 'BUYER';
        if (this.orderStatusDepositWait) {
          this.title = this.$t('CLAIM.CANCELPOPUP.CANCELLBEFOREDEPOS').toString();
        } else {
          this.title = this.$t('CLAIM.CANCELPOPUP.CANCELLATIONAFTERPAYMENT').toString();
        }
      })
      .catch(() => {
        window.opener.location.reload();
        window.close();
      });
  }

  private isPartnerProduct = false;
  private sellerPaysClaimedDelivery = false;

  public setIsPartnerProduct(isPartnerProduct: boolean) {
    this.isPartnerProduct = isPartnerProduct;
  }

  private objJson = '';

  private setData(predict) {
    this.setRefComponent();
    this.refOrderProducts.claimCalculation();
    this.$nextTick(() => {
      let request: PostOptionCancelsPredictRequest;
      if (predict === 'postPredict') {
        request = {
          data: {
            responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue,
            refundType: this.refRefundExchangeAmount.refundTypeValue,
            orderNo: this.orderOptionsApply.orderNo,
            sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
            orderOptionParams: uniqBy(this.refOrderProducts.orderOptionParams, 'orderOptionNo'),
          },
        };
      } else {
        const refundType =
          this.orderOptionsApply.payType === 'NAVER_PAY'
            ? this.findNaverRefundType()
            : (this.orderOptionsApply.refundTypes[0].refundType as RefundType);
        request = {
          data: {
            responsibleObjectType: 'BUYER',
            refundType,
            orderNo: this.orderOptionsApply.orderNo,
            sellerPaysClaimedDelivery: false,
            orderOptionParams: this.refOrderProducts.orderOptionParams,
          },
        };
      }

      if (!this.isPartnerProduct && this.refOrderProducts.responsibleObjectTypeValue === 'SELLER') {
        request.data.sellerPaysClaimedDelivery = true;
      } else if (this.refOrderProducts.responsibleObjectTypeValue === 'SELLER') {
        request.data.sellerPaysClaimedDelivery = this.sellerPaysClaimedDelivery;
      }

      this.$api.postOptionCancelsPredict(request).then(ret => {
        this.showB = true;
        this.postOptionCancelsPredict = ret.data;
        this.objJson = JSON.stringify(this.postOptionCancelsPredict);
        this.overflowsPgAmt = ret.data.overflowsPgAmt;
        this.refundTypeValue = this.getPayType(this.orderOptionsApply.payType);
        this.postOptionCancelsPredict.amounts.refund.order.totalDeliveryAmt =
          this.postOptionCancelsPredict.amounts.refund.coupon.cartCouponDiscountAmt -
          this.postOptionCancelsPredict.amounts.refund.delivery.totalDeliveryAmt;
        this.displayedDeliveryAmtDiscountMessage =
          this.postOptionCancelsPredict.amounts.before.delivery.deliveryGroupAmounts.alreadyPaidTotalDeliveryAmt !==
          this.postOptionCancelsPredict.amounts.before.delivery.deliveryGroupAmounts.totalDeliveryAmt;
        if (this.postOptionCancelsPredict.amounts.adjustedAmounts.claimAmt === 0) {
          if (this.orderOptionsApply.payType !== 'ZERO_PAY') {
            this.refundTypeDivShow = false;
            this.zeroRefundShow = true;
          }
          this.refundBankInfoTrShow = false;
          this.refundTypeValue = 'ZERO_REFUND';
        }
      });
    });
  }

  private findNaverRefundType(): RefundType {
    if (this.orderOptionsApply.payType !== 'NAVER_PAY') {
      return this.orderOptionsApply.refundTypes[0].refundType as RefundType;
    }

    // label은 서버에서 한글로 내려주기 때문에 한글로 비교
    return this.orderOptionsApply.refundTypes.find(
      ({ refundType, label }) => refundType === 'PG' && label === '네이버페이(주문형)',
    ).refundType as RefundType;
  }

  public getPayType(payType: string) {
    switch (payType) {
      case 'ACCOUNT':
        return 'ACCOUNT';

      case 'ACCUMULATION':
        return 'ACCUMULATION';

      case 'ZERO_PAY':
        return 'ZERO_REFUND';

      case 'VIRTUAL_ACCOUNT':
      case 'ESCROW_VIRTUAL_ACCOUNT':
        return 'PG';

      default:
        return 'PG';
    }
  }

  private initPostOptionCancelsPredict() {
    const postOptionCancelsPredictTemp = {
      shipping: {
        requiresShipping: true,
        address: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
        shippingNo: 0,
        hasAdjustedExchangeDeliveryAmt: false,
        divided: true,
        deliveryGroupNo: 0,
        deliveryType: '',
        prepaid: true,
        deliveryTemplateNo: 0,
        combined: true,
        originalShippingNo: 0,
      },
      coupon: {
        productCoupons: null,
        cartCoupon: null,
      },
      amounts: {
        deliveryAdjustAmt: 0,
        additionalDeliveryAmt: 0,
        adjustedAmounts: {
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          claimAmt: 0,
          additionalPayAmt: 0,
          payAmt: 0,
        },
        before: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        after: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        additionalPayAmt: 0,
        refund: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
      },
      overflowsPgAmt: false,
    };
    this.postOptionCancelsPredict = postOptionCancelsPredictTemp;
    this.objJson = JSON.stringify(postOptionCancelsPredictTemp);
  }

  private onClickClose() {
    if (!confirm(this.$t('CLAIM.CANCELPOPUP.CLOSE_BTN_CONFIRM').toString())) return;
    this.close();
  }

  private onClickApplyCancel() {
    if (this.refOrderProducts.optionNo.length === 0) {
      alert(this.$t('CLAIM.CANCELPOPUP.SELECT_ONE', { type: this.$t('CLAIM.COMMON.CANCEL') }));
      return false;
    }
    const orderOptionParams: any = [];
    for (let i = 0; i < this.refOrderProducts.optionNo.length; i++) {
      const claimCnt = this.refOrderProducts.orderCntMap.get(this.refOrderProducts.optionNo[i]);
      this.refOrderProducts.claimReasonType[i] &&
        orderOptionParams.push({
          claimCnt: claimCnt,
          orderOptionNo: this.refOrderProducts.optionNo[i] as number,
          reasonType: this.refOrderProducts.claimReasonType[i] as ReasonType,
          reasonDetail: this.refOrderProducts.claimReasonDetail[i] as string,
        });
    }

    let bank = '';
    let bankNm = '';
    if (this.refRefundExchangeAmount.refundBankEditShow === false) {
      bank = null;
      bankNm = null;
    } else {
      bank = this.refRefundExchangeAmount.refundBankValue;
      this.refRefundExchangeAmount.refundBankList.forEach((element: { value: string; name: string }) => {
        if (element.value == this.refRefundExchangeAmount.refundBankValue) {
          bankNm = element.name;
        }
      });
    }
    const request: PostOptionCancelsRequest = {
      data: {
        reasonDetail: this.isOnlyOneClaim(orderOptionParams) ? orderOptionParams[0].reasonDetail : null,
        calculateParam: {
          refundType: this.refRefundExchangeAmount.refundTypeValue,
          responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue,
          orderNo: this.orderOptionsApply.orderNo,
          sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
          orderOptionParams,
        },
        reasonType: this.isOnlyOneClaim(orderOptionParams) ? orderOptionParams[0].reasonType : null,
        refundBankAccount: {
          depositorName: this.refRefundExchangeAmount.refundBankDepositorName,
          bank: bank,
          bankName: bankNm,
          account: this.refRefundExchangeAmount.refundBankAccount,
        },
      },
    };

    if (
      this.overflowsPgAmt &&
      this.refRefundExchangeAmount.refundTypeValue != 'ACCOUNT' &&
      this.refRefundExchangeAmount.refundTypeValue != 'ACCUMULATION'
    ) {
      alert(this.$t('CLAIM.CANCELPOPUP.ALTER'));
      return;
    }
    if (!this.validation()) {
      return;
    }

    if (!confirm(this.$t('CLAIM.CANCELPOPUP.CONFIRM1').toString())) {
      return;
    }

    this.$api.postOptionCancels(request).then(() => {
      alert(this.$t('CLAIM.CANCELPOPUP.PROCESS_SUCCESS_MESSAGE'));
      window.opener.location.reload();
      this.close();
    });
  }

  private isOnlyOneClaim(orderOptionParams: unknown[]) {
    return orderOptionParams.length === 1;
  }

  private checkHoldByReservation(): boolean {
    return this.holdByReservation == true && !confirm(this.$t('CLAIM.CANCELPOPUP.RESERVATION_CONFIRM').toString());
  }

  private checkHoldDelivery(): boolean {
    return this.holdProcess == true && !confirm(this.$t('CLAIM.CANCELPOPUP.CONFIRM').toString());
  }

  private validation(): boolean {
    if (this.checkHoldDelivery()) {
      return false;
    }
    if (this.checkHoldByReservation()) {
      return false;
    }

    if (this.postOptionCancelsPredict.amounts.adjustedAmounts.payAmt != 0) {
      if (this.refOrderProducts.optionNo.length == 0) {
        alert(this.$t('CLAIM.CANCELPOPUP.SELECT_ONE', { type: this.$t('CLAIM.COMMON.CANCEL') }));
        return false;
      }

      let needBankAccount = false;
      switch (this.refRefundExchangeAmount.refundTypeValue) {
        case 'CASH':
        case 'ACCOUNT':
          needBankAccount = true;
          break;
        case 'PG':
          if (
            this.orderOptionsApply.payType === 'VIRTUAL_ACCOUNT' ||
            this.orderOptionsApply.payType === 'ESCROW_VIRTUAL_ACCOUNT'
          ) {
            needBankAccount = true;
          }
          break;
      }

      if (
        needBankAccount &&
        (this.refRefundExchangeAmount.refundBankValue == null || this.refRefundExchangeAmount.refundBankValue == '')
      ) {
        alert(this.$t('CLAIM.CANCELPOPUP.ALTER1'));
        this.refRefundExchangeAmount.$refs.refundBankAccountRef.focus();
        return false;
      }

      if (needBankAccount && this.refRefundExchangeAmount.refundBankAccount == '') {
        alert(this.$t('CLAIM.CANCELPOPUP.ALTER2'));
        this.refRefundExchangeAmount.$refs.refundBankAccountRef.focus();
        return false;
      }

      if (needBankAccount && this.refRefundExchangeAmount.refundBankDepositorName == '') {
        alert(this.$t('CLAIM.CANCELPOPUP.ALTER3'));
        this.refRefundExchangeAmount.$refs.refundBankDepositorNameRef.focus();
        return false;
      }
    }
    return true;
  }
  public close(): void {
    window.close();
  }
  private setRefComponent() {
    this.refOrderProducts = this.$refs.componentItemsClaim as OrderProducts;
    this.refProductDetails = this.$refs.componentProductDetails as ProductDetails;
    this.refDiscountShippingFee = this.$refs.componentDiscountShippingFee as DiscountShippingFee;
    this.refRefundExchangeAmount = this.$refs.componentRefundExchangeAmount as RefundExchangeAmount;
    this.refBenefitsPaid = this.$refs.componentBenefitsPaid as BenefitsPaid;
  }

  mounted() {
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-1200');
    });
  }
}
