export default Object.freeze({
  WORD: '수수료',
  SETTING: '수수료 설정',

  INFORMATION:
    '카테고리 수수료는 최종적으로 [변경사항 수정] 버튼을 클릭 후 반영됩니다.\n' +
    '[변경사항 수정] 버튼을 클릭하지 않고 쇼핑몰 탭을 이용하여 쇼핑몰 이동 시 이전에 설정한 수수료는 반영되지 않으니 반드시 [변경사항 수정] 버튼을 클릭해 주시기 바랍니다.\n' +
    '엑셀 다운로드 시 전체 카테고리에 설정된 수수료를 확인할 수 있습니다.',

  CONFIRM: '수수료를 입력하지 않은 카테고리는 0.0%로 저장됩니다.\n' + '변경사항을 수정하시겠습니까?',
});
