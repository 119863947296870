













































import { Component, Ref } from 'vue-property-decorator';
import SubmitButton from '@/components/SubmitButton.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { ValidOption } from '@/types';
import { getStorageFile } from '@/utils/storage';
import { range1ToNI18n } from '@/utils/common';
import { getToday } from '@/utils/dateFormat';
import { readExcelFile } from '@/utils/webExcel';
import { ParsedObject } from '@/utils/fileReader';
import { NCPResponse, PostMembersMemberNosExcel } from 'ncp-api-supporter';

@Component({
  components: { FileInput, SubmitButton, NoticeBox },
})
export default class ExcelBatchRegistrationPopup extends WindowPopupMainVue {
  @Ref() private readonly fileInputRef: FileInput;
  private readonly noticeList = range1ToNI18n(4, 'MEMBER.POPUP.EXCEL_BATCH_REGISTRATION_NOTICE_');
  private uploadedExcelFile: File | null = null;
  private uploadedExcelFileName = '';
  private extensionValidOption: ValidOption<string> = {
    validType: 'excel',
    alertMsg: this.$t('MEMBER.POPUP.WRONG_FILE_FORMAT') as string,
  };
  private fileInputStyle = 'width: 180px'; //파일명 길이에 따라 width 가변하도록 설정
  private excelMembers: number[] = []; //엑셀에서 추출된 회원번호

  private async readExcel(): Promise<void> {
    const MEMBER_NO = this.$t('MEMBER.POPUP.MEMBER_NO');
    const keyMapJson = { memberNos: MEMBER_NO };
    readExcelFile(this.uploadedExcelFile, keyMapJson, await this.fetchExcelMembers);
  }

  private async fetchExcelMembers(excelData: ParsedObject[]): Promise<void> {
    //엑셀을 읽어 회원번호만 배열로 리턴
    const memberNos = excelData.flatMap(row => Number(Object.values(row)));
    this.excelMembers = memberNos;
  }

  private checkExcelValid(): boolean {
    const MAXIMUM_FIVE_MEGA_BYTE = 1024 ** 2 * 5;
    const MAXIMUM_LENGTH_TEN_THOUSAND = 10000;
    //엑셀 파일 업로드 안함
    if (!this.uploadedExcelFileName || !this.uploadedExcelFile) {
      alert(this.$t('MEMBER.POPUP.NO_DATA'));
      return false;
    }
    //데이터 없거나 다른 열에 있음 | 엑셀 양식 - 첫 행부터 시작여부 확인 | 엑셀에 문자열 존재여부 체크(기획엔 없지만 방어코드용)
    const isIncorrectExcelFormat =
      !this.excelMembers.length || !this.excelMembers[0] || this.excelMembers.includes(NaN);
    if (isIncorrectExcelFormat) {
      alert(this.$t('MEMBER.POPUP.INVALID_EXCEL_FILE'));
      return false;
    }
    if (this.excelMembers.length >= MAXIMUM_LENGTH_TEN_THOUSAND) {
      alert(this.$t('MEMBER.POPUP.MAXIMUM_LENGTH_TEN_THOUSAND'));
      return false;
    }
    //5메가바이트 초과
    if (this.uploadedExcelFile.size > MAXIMUM_FIVE_MEGA_BYTE) {
      alert(this.$t('MEMBER.POPUP.MAXIMUM_FILE_SIZE_FIVE_MEGA_BYTE'));
      return false;
    }
    return true;
  }

  private async uploadExcel(): Promise<PostMembersMemberNosExcel> {
    const formData = new FormData();
    const mallNo = Number(this.data.mallNo);
    formData.append('excelFile', this.uploadedExcelFile);
    const { data: excelData }: NCPResponse<PostMembersMemberNosExcel> = await this.$api.postMembersMemberNosExcel({
      params: { mallNo },
      data: formData,
    });
    return excelData;
  }

  private handleDownloadExcelClick(): void {
    const TEMPLATE_FILE_NAME = 'target_member_template.xlsx';
    const excelFileName = this.$t('MEMBER.POPUP.BATCH_REGISTRATION_EXCEL_SAMPLE', { todayDate: getToday('YYYYMMDD') });
    getStorageFile(TEMPLATE_FILE_NAME, 'excel-sample', excelFileName as string);
  }

  private handleUploadExcelChange(excelFile: File): void {
    this.fileInputStyle = 'width: 100%';
    this.uploadedExcelFileName = excelFile.name;
    this.uploadedExcelFile = excelFile;
    this.readExcel();
  }

  private handleDeleteExcelClick(): void {
    this.fileInputStyle = 'width: 180px';
    this.uploadedExcelFile = null;
    this.uploadedExcelFileName = '';
    this.fileInputRef.clearFile();
  }

  private async handleSaveClick(): Promise<void> {
    if (!this.checkExcelValid()) {
      this.fileInputRef.focus();
      return;
    }
    const data = await this.uploadExcel();
    const successCount = data.memberNos.length;
    const failCount = data.failCounts;

    alert(this.$t('MEMBER.POPUP.SAVE_STATUS_ALERT', { successCount, failCount }));
    this.onPositiveClick(data.memberNos);
  }
}
