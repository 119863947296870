import orderStatusType from '@/const/words/orderStatusType';

export function getOrderStatusTypeName(code: string): string {
  let enumName = '';
  const tempOrderStatusType = orderStatusType.find((orderStatusType): boolean => orderStatusType.value === code);
  if (tempOrderStatusType) {
    enumName = tempOrderStatusType.name;
  }
  return enumName;
}
