export default Object.freeze({
  PAGE_TITLE: '1:1문의 관리',
  TAB_LIST: '1:1문의 답변',
  TAB_TYPE: '문의유형/담당자 관리',
  TAB_TEMPLATE: '답변 템플릿 관리',
  TH_1: '쇼핑몰 선택',
  TH_2: '검색어',
  TH_3: '문의유형 / 담당자',
  TH_4: '문의일시',
  TH_5: '진행상태',
  COMPCHECKBOX_1: '전체선택',
  COMPCHECKBOX_2: '답변대기',
  COMPCHECKBOX_3: '답변완료',
  COMPCHECKBOX_4: '진행중',
  SEARCHTYPE_1: '문의번호',
  SEARCHTYPE_2: '문의제목',
  SEARCHTYPE_3: '작성자',
  SEARCHTYPE_4: '담당자',
  INQUIRYTYPES_ALL: '1:1문의유형 전체',
  ADMINNO_ALL: '담당자 전체',
  TABLE_TIT_L: '조회결과',
  TABLE_TIT_R: '건',
  BTN_MODIFYINQUIRYTYPE: '문의유형/담당자 변경',
  ITEMTABLE_TH1: '쇼핑몰',
  ITEMTABLE_TH2: '문의일시',
  ITEMTABLE_TH3: '문의번호',
  ITEMTABLE_TH4: '문의제목',
  ITEMTABLE_TH5: '문의자',
  ITEMTABLE_TH6: '문의유형',
  ITEMTABLE_TH7: '담당자',
  ITEMTABLE_TH8: '진행상태',
  ITEMTABLE_TH9: '답변일시',

  ITEMTABLE_T4_POP: '1:1문의 상세보기',
  ITEMTABLE_T5_NULL: '잘못된 사용자',
  ITEMTABLE_T5_NOTNULL: '탈퇴회원',
  ITEMTABLE_T5_POP: '회원정보',
  ITEMTABLE_T6_NULL: '잘못된 문의유형',

  EXCEL_T6: '문의내용',
  EXCEL_T12: '답변내용',
  EXCEL_T13: 'SMS 답변 알림',
  EXCEL_T13_Y: '발송함',
  EXCEL_T13_N: '발송안함',
  EXCEL_T14: 'e메일 동시 답변',
  POP_TITLE: '문의유형/담당자 변경',
  ALERT_SELECT_NONE: '문의글을 선택해 주세요.',
  ALERT_SELECT_NCP: '네이버페이문의와 일반 문의를 동시에 수정할 수 없습니다.',
});
