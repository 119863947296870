const nodeElements = () => {
  return {
    internalNode: `
		<div class="tui-tree-content-wrapper">
      <button type="button" class="tui-tree-toggle-btn tui-js-tree-toggle-btn">-<span class="tui-ico-tree"></span></button>
      <span class="tui-tree-text tui-js-tree-text">{{nodeName}}</span>
	  </div>
	  <ul class="tui-tree-subtree tui-js-tree-subtree">{{children}}</ul>
	`,
    leafNode: ` 
		<div class="tui-tree-content-wrapper">
      <span class="tui-tree-text tui-js-tree-text">
        <span class="tui-tree-ico tui-ico-file-line"></span>
          {{nodeName}}
          {{if used}}<span class="badge">${window.$t('MEMBER.MAIL.USE')}</span>
          {{else}}<span class="badge type-2">${window.$t('MEMBER.MAIL.NOT_USE')}</span>
          {{/if}}
      </span>
		</div>
	`,
  };
};

export default nodeElements;
