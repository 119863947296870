export default Object.freeze({
  TITLE: '표준카테고리수수료 관리',
  TABLE_TIT: '카테고리 상세설정',
  EXALDOWN: '엑셀 다운로드',
  TAB_TIT: '설정',
  TABLE_TH1: '카테고리 경로',
  TABLE_TH2: '카테고리 번호',
  TABLE_TH3: '수수료',
  CATEGORY_ICON: '카테고리 아이콘',
  CATEGORY_ADULTS: '성인카테고리',
  CATEGORY_NAME: '카테고리명',
  CONFIG_INFO2: '카테고리 번호, URL은 최종 저장 후에 생성됩니다.',
  SAVEBTN: '변경사항 저장',
  ADULTDEFALUT: '설정',
  SUCCESS_ALERT: '수정완료',
  CHANGE_MALL_ALERT: '저장되지 않은 내용이 있습니다. 쇼핑몰을 이동 하시겠습니까?',

  CATEGORY_NO: '카테고리번호',
  CATEGORY_DEPTH1: '1depth',
  CATEGORY_DEPTH2: '2depth',
  CATEGORY_DEPTH3: '3depth',
  CATEGORY_DEPTH4: '4depth',
  CATEGORY_RATE: '수수료(%)',
  FULLNAME: '전체 카테고리명',
  DISPLAYORDER: '카테고리 노출순서',
  DISPLAYCODE: '코드',
  ORIGINTYPE: '원시유형',
});
