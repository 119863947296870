











































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import Article from '@/components/board/basic/mixins/ArticleMixin';
import { PutInquiriesRequest, Assignees, InquiryType, Failures } from 'ncp-api-supporter';
@Component({
  components: { SelectBox },
})
export default class TypeAndAdminPopup extends Mixins(Article) {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: Function;
  @Prop() private readonly onNegativeClick!: Function;

  private localInquiryTypeNo = 0;
  private localAssigneeNo = 0;
  private reason = '';
  private maxLength = 50;
  private inquiryTypes: InquiryType[] = [];
  private assignees: Assignees[] = [];
  private channel = '';

  get getInquiryTypeRequest() {
    return {
      params: {
        mallNo: Number(this.data.mallNo),
      },
    };
  }

  private async getInquiryTypes() {
    const { data } = await this.$api.getInquiryType(this.getInquiryTypeRequest);
    this.inquiryTypes = data;

    if (this.channel === 'NAVER_PAY') {
      this.inquiryTypes = this.inquiryTypes.filter((item: any): any => {
        return item.channel === 'NAVER_PAY';
      });
    } else {
      this.inquiryTypes = this.inquiryTypes.filter((item: any): any => {
        return item.channel !== 'NAVER_PAY';
      });
    }
  }

  get getInquiryTypesInquiryTypeNoRequest() {
    return {
      pathParams: {
        inquiryTypeNo: this.localInquiryTypeNo.toString(),
      },
      params: {
        mallNo: Number(this.data.mallNo),
      },
    };
  }
  private changeInquiryType() {
    this.$api.getInquiryTypesInquiryTypeNo(this.getInquiryTypesInquiryTypeNoRequest).then(ret => {
      const selectedInquiry = ret.data;
      if (selectedInquiry) {
        this.assignees = selectedInquiry.assignees;
      }
    });
  }

  private putInquiriesRequest: PutInquiriesRequest = {
    data: {
      inquiryNos: [],
      assigneeNo: 0,
      mallNo: 0,
      inquiryTypeNo: 0,
      reason: '',
    },
  };

  private getListFromStr(str: string): number[] {
    return str.split(',').map((item: string) => Number(item));
  }

  private async setInit() {
    if (this.data.mallNo) {
      this.putInquiriesRequest.data.mallNo = Number(this.data.mallNo);
      this.localInquiryTypeNo = this.data.inquiryTypeNo ? Number(this.data.inquiryTypeNo) : undefined;

      if (this.data.channel && this.data.channel.length > 0) {
        this.channel = this.data.channel.toString();
      }

      this.putInquiriesRequest.data.inquiryNos = this.getListFromStr(this.data.inquiryNos as string);

      await this.getInquiryTypes();

      const findInquiryType: InquiryType = this.inquiryTypes.find((inquiryType: InquiryType): {} => {
        return inquiryType.no === this.localInquiryTypeNo;
      });
      if (findInquiryType) {
        this.changeInquiryType();
      }
    }
  }

  mounted() {
    this.setInit();
  }

  private async apply() {
    if (!this.checkForm()) {
      return;
    }

    if (!confirm(this.$t('BOARD.INQUIRIES.POP_CONFIRM').toString())) {
      return;
    }

    this.putInquiriesRequest.data.inquiryTypeNo = Number(this.localInquiryTypeNo);
    this.putInquiriesRequest.data.assigneeNo = Number(this.localAssigneeNo);
    this.putInquiriesRequest.data.reason = this.reason;

    this.$api.putInquiries(this.putInquiriesRequest).then(ret => {
      const putInquiriesResponse = ret.data;
      const successMessage = this.getSuccessMessage(putInquiriesResponse.successCount);

      if (putInquiriesResponse.failCount > 0) {
        const failures = putInquiriesResponse.failures;
        let failMessageReason = '';

        if (failures) {
          failures.forEach((reasonAndTargetNo: Failures) => {
            if (reasonAndTargetNo) {
              failMessageReason =
                failMessageReason + reasonAndTargetNo.reason + this.getFailReason(reasonAndTargetNo.targetNos);
            }
          });
        }

        const failMessage = this.getFailMessage(putInquiriesResponse.failCount) + failMessageReason;
        alert(successMessage + failMessage);
      } else {
        alert(successMessage);
      }

      this.onPositiveClick();
    });
  }

  private getSuccessMessage(successCount: number): any {
    return successCount > 0
      ? this.$t('BOARD.INQUIRIES.PUT_INQUIRIES_SUCCESS').toString() +
          successCount +
          this.$t('CASE').toString() +
          ': ' +
          this.$t('BOARD.INQUIRIES.PUT_INQUIRIES_SUCCESS_MESSAGE').toString() +
          '\r'
      : '';
  }

  private getFailMessage(failCount: number): any {
    return this.$t('BOARD.INQUIRIES.PUT_INQUIRIES_FAIL').toString() + failCount + this.$t('CASE').toString() + ':';
  }

  private getFailReason(targetNos: number[]): any {
    let targetNosStr = '';

    if (targetNos) {
      targetNosStr = targetNos.join(',');

      if (targetNosStr.length > 0) {
        targetNosStr = '(' + targetNosStr + ')';
      }
    }

    return targetNosStr;
  }

  private checkForm(): boolean {
    if (!this.localInquiryTypeNo || this.localInquiryTypeNo === undefined) {
      alert(this.$t('BOARD.INQUIRIES.POP_WARN_NOTYPE'));
      return false;
    }
    if (!this.localAssigneeNo || this.localAssigneeNo === undefined) {
      alert(this.$t('BOARD.INQUIRIES.POP_WARN_NOADMIN'));
      return false;
    }
    if (this.reason === '' || this.reason.length == 0) {
      alert(this.$t('BOARD.INQUIRIES.POP_WARN_NOREASON'));
      return false;
    }
    return true;
  }
}
