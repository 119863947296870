export default Object.freeze({
  PREVIEW: '미리보기',
  PAGE_TIT: '게시판 관리',
  TABLE_TIT: '게시판 설정',
  ADD_BTN: '게시판 추가',
  REMOVE_BTN: '삭제',
  TREE_LINK_1: '1:1문의',
  TREE_LINK_2: '상품후기',
  TREE_LINK_3: '상품문의',
  ARCODION_LST_EMPTY: '게시판을 생성 해 주세요.',
  ARCODION_ITEM_EMPTY: '좌측에서 게시판을 추가 하시면, 상세설정이 가능합니다.',
  ARCODION_H: '게시판 상세 설정',
  BOARD_NO: '게시판 번호',
  BOARD_ID: '게시판 ID',
  BOARD_TYPE: '게시판 유형',
  BOARD_SET: '게시판 사용설정',
  BOARD_NAME: '게시판명',
  BOARD_DESCRIPTION: '게시판 설명',
  BOARD_NAME_PLACEHOLDER: '게시판명 입력',
  BOARD_DESCRIPTION_PLACEHOLDER: '게시판 설명 입력',
  USEARTICLEIMAGE: '게시글 대표이미지 사용',
  USE_CATEGORY: '카테고리 사용',
  MEMBER_WRITE: '회원 글쓰기',
  GUEST_WRITE: '비회원 글쓰기',
  SECRET: '비밀 글쓰기',
  REPLY: '답글',
  BOARD_FILE: '첨부파일',
  BOARD_REPLY: '댓글 기능',
  BOARD_IMAGE: '리스트 이미지 설정',
  BOARD_USEYN: '적립금 사용여부',
  ARCODION_TITILE: '게시판 카테고리',
  CATEGORY_TR: '카테고리 번호',
  CATEGORY_TITLE_TH: '카테고리',
  REMOVE_CATEGORY_BTN: '삭제',
  ADD_CATEGORY: '+카테고리 추가',
  SAVE_BTN: '변경사항 저장',
  CATEGORY_NAME_PLACEHOLDER: '카테고리명 입력',
  DETAIL_USED: '사용함',
  DETAIL_UNUSED: '사용 안 함',
  DETAIL_NEW_GRADE_NAME: '게시판명 입력',
  REVIEWS: '- 일반 후기 적립금 {0}자 이상 {1} 적립',
  PHOTO_REVIEWS: '- 포토 후기 {0}자 이상 {1} 적립',
  TAB_1: '게시판 상세설정',
  TAB_2: '게시판 추가설정',
  TAB_3: '상품후기 작성 예외설정',

  BOARD_NO_1: '1:1문의 게시판',
  BOARD_NO_2: '상품문의 게시판',
  BOARD_NO_3: '상품후기 게시판',
  BOARD_TYPE_1: '문의형',
  BOARD_TYPE_2: '카드형',
  BOARD_TYPE_3: '리스트형',

  TYPE_OPTION_1: '리스트형',
  TYPE_OPTION_2: '카드형',
  TYPE_OPTION_3: '댓글형',
  TYPE_OPTION_4: '문의형',
  IMAGE_OPTION_1: '노출 안 함',
  IMAGE_OPTION_2: '첨부파일 이미지',
  IMAGE_OPTION_3: '상품 대표 이미지',
  USERD_OPTION_1: '사용함',
  USERD_OPTION_2: '사용안함',

  ALERT_SAVED: '변경사항이 저장되었습니다.',

  ERR_BOARD_NAME_NULL: '게시판명을 입력해주세요.',
  ERR_BOARD_NAME_WITH_ERRCODE: '게시판명에 허용되지 않는 특수문자가 포함되어 있습니다.수정 후 다시 시도해주세요.',
  ERR_DELETE_BOARD_WITH_CUSTOMER: '게시글 등록이 있어 게시판을 삭제할 수 없습니다.',
  ERR_BOARD_CATEGORY_NAME_NULL: '게시판의 카테고리가 지정되지 않았습니다.',
  COMFIRM_BEFORE_MODIFY: '변경된 내용을 저장하시겠습니까?',
  COMFIRM_BEFORE_DELETE_BOARD: '게시판을 삭제하시겠습니까?',

  HELP_BOARD_REVIEW_LINK:
    '・상품후기 게시판은 기본으로 제공되는 게시판으로 게시판의 순서변경 및 일부 설정이 불가합니다.<br>' +
    '・상품후기 게시판에 대한 설정은 상품상세와 마이페이지>상품후기, 전체 상품후기 게시판에 적용됩니다.<br>' +
    '・상품후기는 ‘배송중~구매확정’상태의 구매내역이 존재하는 상품에 한하여 작성이 가능합니다.<br>' +
    '・배송중, 배송완료 상태의 주문상품에 대한 후기 작성 완료 시 해당 주문상품은 ‘구매확정‘처리가 됩니다.<br>' +
    '・회원의 경우, 상품상세와 마이페이지에서 후기 작성이 가능합니다.<br>' +
    '・비회원의 경우, 비회원 주문조회 페이지 내에서 후기 작성이 가능합니다.',
  HELP_BOARD_PRODUCT_LINK:
    '・상품문의 게시판은 기본으로 제공되는 게시판으로 게시판의 순서변경 및 일부 설정이 불가합니다.<br>' +
    '・상품문의 게시판에 대한 설정은 상품상세와 마이페이지>상품문의, 전체 상품문의 게시판에 적용됩니다.<br>' +
    '・상품문의는 쇼핑몰에 등록된 모든 상품에 대해 작성이 가능합니다.<br>' +
    '・단, 쇼핑몰 상품상세 접근이 불가한 등록대기/판매중지 상태의 상품은 상품문의 등록이 불가합니다.',
  HELP_BOARD_11_LINK:
    '・1:1문의 게시판은 기본으로 제공되는 게시판으로 게시판의 순서변경 및 일부 설정이 불가합니다.<br>' +
    '・1:1문의 게시판에 대한 설정은 마이페이지>1:1문의에 적용됩니다.<br>' +
    '・1:1문의는 작성 시 비밀글로 작성되며, 회원만 작성이 가능합니다.',
  HELP_BOARD_USEYN_LINK:
    '・상품후기에 대한 적립금 지급 설정은 ‘회원 > 적립금 관리 > 적립금 설정 관리’ 에서 하실 수 있습니다.',
  HELP_BOARD_IMAGE_SETTING:
    '・쇼핑몰 마이페이지 > 상품후기 리스트에 노출되는 썸네일 이미지를 설정할 수 있습니다.<br>' +
    '・설정 사항은 전체 상품후기, 포토 상품후기, 상품기준 상품후기 게시판, 상품상세페이지 상품후기 리스트에는 적용되지 않습니다.',

  //게시판 추가 설정'
  PER_PAGE: '페이지당 게시물 수',
  ALL_REVIEW: '전체 상품후기',
  PHOTO_REVIEW: '포토 상품후기',
  PRODUCT_REVIEW: '상품기준 상품후기',
  WIDGET_SETTING: '위젯설정',
  WIDGET_TOOLTIP:
    '・전체 상품후기, 포토 상품후기, 상품기준 상품후기 위젯을 생성하여 원하는 페이지에 삽입할 수 있습니다.<br>・위젯 생성 전에 출력할 상품후기 개수를 선택해주세요.',
  REVIEW_COUNT: '출력 후기 개수',
  USE_STATUS: '사용여부',
  ALL_PRODUCT_REVIEW_WIDGET_SETTING: '전체 상품후기 위젯설정',
  PHOTO_REVIEW_WIDGET_SETTING: '포토 상품후기 위젯설정',
  PRODUCT_REVIEW_WIDGET_SETTING: '상품기준 상품후기 위젯설정',
  PRODUCT_REVIEW_BENEFIT_TEXT: '상품후기 혜택 안내 문구',
  ACCUMULATION_BENEFIT: '적립금 혜택 안내문구',
  REVIEW_BENEFIT_PLACEHOLDER:
    "상품후기 작성 시 혜택에 대한 안내문구를 입력해주세요. 상품후기 작성 영역에 '혜택안내'로 노출됩니다. ex) 상품후기 등록 시 적립금 혜택을 드립니다.",
  ACCUMULATION_BENEFIT_PLACEHOLDER:
    '상품후기 등록 시 적립금 지급 최소 글자수 충족이 되지 않을 경우 출력될 문구를 설정합니다. 입력하지 않을 경우 출력되지 않습니다. ex) 상품후기를 등록하시겠습니까? 00자 이상 등록하시면 적립금 혜택을 드립니다.',
  PRODUCT_REVIEW_TEXT: '상품후기 작성 안내문구',
  WRITING_REVIEW_NOTICE_PLACEHOLDER:
    '상품후기를 작성하는 구매자들에게 표시될 안내문구를 설정합니다. ex) 00자 이상 등록하시면 적립금 혜택을 드립니다.',
  PHOTO_REVIEW_DISPLAY: '포토 상품후기 디스플레이 유형 설정',
  GATHERING_PHOTO_REVIEW: '포토 상품후기 모아보기',
  NO_PHOTO_REVIEW:
    '포토 상품후기가 없을 경우 대신 출력될 텍스트 문구를 설정할 수 있습니다. ex) 포토 상품후기를 작성해주세요.\n포토 상품후기 작성 시 특별한 혜택을 드립니다.',
  REVIEW_RECOMMEND: '후기 추천',
  REVIEW_RECOMMEND_TOOLTIP:
    '・쇼핑몰 회원이 마음에 드는 상품후기를 추천할 수 있습니다.<br>・‘사용함’ 설정 시 쇼핑몰 상품후기 정렬 기준 ‘추천순’이 추가됩니다.',
  PER_PAGE_TOOLTIP:
    '・쇼핑몰에 등록된 상품후기를 각 유형별 게시판 페이지별 게시글 노출 개수 설정이 가능합니다.<br>・게시글은 한 페이지당 최소 4개에서 최대 20개까지 설정할 수 있습니다.',
  REVIEW_ALL: '상품 후기',
  REVIEW_PHOTO: '포토 후기',
  REVIEW_REVIEWED_PRODUCTS: '상품별 후기',
  DESC_REVIEW_ALL: '설정된 전체 상품후기 게시판 위젯은 아래와 같이 페이지에 삽입됩니다.',
  DESC_REVIEW_PHOTO: '설정된 포토 상품후기 게시판 위젯은 아래와 같이 페이지에 삽입됩니다.',
  DESC_REVIEW_REVIEWED_PRODUCTS: '설정된 상품기준 상품후기 게시판 위젯은 아래와 같이 페이지에 삽입됩니다.',
  TIT_REVIEW_ALL: '전체 상품후기 게시판 위젯 미리보기',
  TIT_REVIEW_PHOTO: '포토 상품후기 게시판 위젯 미리보기',
  TIT_REVIEW_REVIEWED_PRODUCTS: '상품기준 상품후기 게시판 위젯 미리보기',
  OUTLINK_ALL_PRODUCT: '전체 상품후기 보기',
  REVIEW: '후기',
  RATE: '평점',
  TYPE1: '타입1',
  TYPE2: '타입2',
  EXCEPTION_PRODUCT_CONFIRM_MSG: '검색 된 상품을 상품후기 작성 예외상품으로 추가하시겠습니까?',
  NO_LIST: '게시글이 존재하지 않습니다.',
  CONFIRM_EXCEPTION_PRODUCT_DELETE: '상품을 삭제하시겠습니까? 저장 시 최종 반영됩니다.',
  ALERT_EXCEPTION_PRODUCT_DELETE: '삭제할 상품을 선택해주세요.',
});
