export default Object.freeze({
  AUTH_METHOD_TITLE: '인증수단 선택',
  AUTH_METHOD_DESCRIPTION: '운영자 관리 페이지에서 설정한 인증수단 정보로 인증번호가 발송됩니다.',

  // SECURITY.OTP
  OTP_AUTH_SETTING: 'OTP 인증 설정',

  AUTH_INFO: '인증정보',
  AUTH_COMPLETE: '인증완료',

  TOOLTIP_AUTH_INFO_0: '이메일/SMS 인증의 경우 인증번호 발송 후 인증번호 입력을 통해 접근이 가능합니다.',
  TOOLTIP_AUTH_INFO_1: 'OTP인증의 경우 Google Authenticator 앱 설치 후 이용이 가능합니다.',
  TOOLTIP_AUTH_INFO_2: '[OTP인증 Google Authenticator 사용방법]',
  TOOLTIP_AUTH_INFO_3: 'QR 코드 사용시',
  TOOLTIP_AUTH_INFO_4: '① 구글 플레이스토어 혹은 애플 앱스토어에서 Google Authenticator 앱을 다운로드합니다.',
  TOOLTIP_AUTH_INFO_5: '② Google Authenticator 앱 실행 후 설정 시작하기를 선택합니다.',
  TOOLTIP_AUTH_INFO_6: '③ 바코드 스캔 버튼을 클릭 후 QR 코드를 스캔합니다.',
  TOOLTIP_AUTH_INFO_7: '④ 스캔 후 노출되는 인증번호를 입력하여 인증합니다.',
  TOOLTIP_AUTH_INFO_8: 'OTP키 사용시',
  TOOLTIP_AUTH_INFO_9: '① 설정 시작하기 > 직접 입력을 선택합니다.',
  TOOLTIP_AUTH_INFO_10: '② 이메일 주소(계정)와 발급된 OTP 키를 입력합니다.',
  TOOLTIP_AUTH_INFO_11: '③ 시간 기준을 사용 설정했는지 확인한 후 인증번호를 입력하여 인증합니다.',

  AUTH_KEY_PLACEHOLDER: '인증번호 입력',

  OTP_KEY_SETTING: 'OTP 키로 설정',
  OTP_QR_SETTING: 'QR 코드로 설정',
  OTP_AUTH_KEY_DESCRIPTION: '앱에 표시된 6자리 코드를 입력해주세요.',

  OTP_KEY: 'OTP 키',

  OTP_AUTH_KEY_MESSAGE_0: 'OTP 인증번호를 입력해주세요.',
  OTP_AUTH_KEY_MESSAGE_1: 'Google Authenticator 앱에 표시된 6자리 코드를 입력해주세요',
  OTP_FIND_KEY: 'OTP 설정 단말기를 분실하셨나요?',

  NOTICE_FIND_OTP_KEY_0: '이메일로 OTP 키를 전송합니다.',
  NOTICE_FIND_OTP_KEY_1: 'OTP 키를 이용하면, Google OTP를 재설정할 수 있습니다.',

  OTP_AUTH_KEY: 'OTP 코드를 입력하겠습니다.',

  OTP_SETTING_COMPLETE: '설정완료',
});
