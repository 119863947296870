






import { Component, Vue } from 'vue-property-decorator';
interface Components {
  [key: string]: any;
}
const requireModules = require.context('./', true, /\.vue$/);
const components: Components = {};
//FIXME: 동적으로 컴포넌트를 몇십개씩 로드하는거같음
requireModules.keys().map((fileName: string) => {
  if (fileName.indexOf('Container.vue') < 0) {
    const name = fileName.slice(fileName.lastIndexOf('/') + 1, fileName.length - 4);
    components[name] = () => import(`@/views/top/${fileName.slice(2)}`);
  }
});

@Component({ components: components })
export default class ContentsContainer extends Vue {
  get routeName() {
    if (!components[this.$route.name]) {
      return '';
    }
    return this.$route.name;
  }
}
