const nodeElements = () => {
  return {
    leafNode: `
		<div class="tui-tree-content-wrapper bg-nav" style="padding-left: {{indent}}px !important">
			<span class="tui-tree-text tui-js-tree-text" style="float:left">{{nodeName}}</span>
		</div>
	`,
  };
};

export default nodeElements;
