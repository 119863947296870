




import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class PreviousOrdersIframe extends Vue {
  private get remoteSrc() {
    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/fulfillment/order/previous-orders?serviceType=PRO';
  }
}
