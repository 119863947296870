export default Object.freeze({
  GO_MANUAL_BTN: '수기주문 메뉴로 이동',
  FAIL_TITLE: '결제 승인에 실패하였습니다.',
  FAIL_MESSAGE: '실패사유',

  SUCCESS_TITLE: '수기 주문이 정상적으로 접수되었습니다.',
  ORDER_NO_TITLE: '주문번호',
  ADDRESS_INFO: '배송지 정보',
  RECIVER: '받는사람',
  ADDRESS: '주소',
  CONTACT_1: '휴대폰 번호',
  CONTACT_2: '연락처',
  DELIVERY_NOTICE: '배송메모',

  PAY_TITLE: '결제 정보',
  PAY_TYPE: '결제수단',
  BANK_NAME: '입금은행',
  PAYER: '입금자명',
  THE_COUNT: '입금계좌',
  PERIOD: '입금기한',
  GO_ORDER_DETAIL_BTN: '주문 상세로 가기',
});
