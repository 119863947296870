export default Object.freeze({
  TITEL: '할인쿠폰 사용현황',
  MALL_NAME: '쇼핑몰',
  COUPON_INFO: '쿠폰정보',
  COUPON_NO: '쿠폰번호',
  COUPON_USE: '발급/사용현황',
  COUPON_USE_PUB: '발급',
  COUPON_USE_USE: '사용',
  COUPON_USE_DONE: '철회완료',
  COUPON_USE_NUIT: '장',
  COUPON_COUNT: '총 쿠폰할인금액',
  COUPON_COUNT_NUIT: '원',

  // grid header
  HEADER_MEMBER_NO: '회원번호',
  HEADER_MEMBER_NAME: '회원명',
  HEADER_MEMBER_ID: '아이디',
  HEADER_MEMBER_GRADE: '회원등급',
  HEADER_COUPON_DISCOUNT: '쿠폰할인금액',
  HEADER_REGISTER_YDMT: '쿠폰발급일',
  HEADER_USE_YMDT: '쿠폰최종사용일',
});
