export default Object.freeze({
  PRODUCT_INFORMATION_INQUIRYEDIT: '상품문의 관리',
  RESPONSE_DELAY: '답변지연',
  KEY: '건',
  ENTER_SEARCH_TERM: '검색어 입력',

  SHOPPING_MALL: '쇼핑몰',
  TERM: '기간',
  PROCESSING_STATUS: '처리상태',
  ADVANCED_SEARCH: '상세검색',

  REGISTRATION_DATE: '등록일',
  REPLY_DATE: '답변일',
  ALL: '전체',
  WAITING_FOR_REPLY: '답변대기',
  ANSWER_COMPLETED: '답변완료',
  PRODUCT_NUMBER: '상품번호',
  PARTNER: '파트너사',
  WRITER: '작성자',
  MANAGER: '담당자',
  PRODUCT: '상품',
  DELIVERY: '배송',
  CANCEL: '취소',
  RETURN: '반품',
  EXCHANGE: '교환',
  REFUND: '환불',
  ETC: '기타',
  RESPONSE_STATUS: '답변상태',
  // ANSWER_COMPLETED: '답변완료',
  // WAITING_FOR_REPLY: '답변대기',
  // SHOPPING_MALL: '쇼핑몰',
  // PRODUCT_NUMBER: '상품번호',
  PRODUCT_NAME: '상품명',
  INQUIRY_TYPE: '문의유형',
  SECRET: '비밀',
  // SECRET: '비밀',
  NORMAL: '일반',
  INQUIRIES: '문의내용',
  PURCHASE_OR_NOT: '구매여부',
  PURCHASE: '구매',
  DO_NOT_PURCHASE: '구매안함',
  // PARTNER: '파트너사',
  // WRITER: '작성자',
  WRONG_USER: '잘못된 사용자',
  WITHDRAWAL_MEMBER: '탈퇴회원',
  INQUIRY_DATE: '문의일시',
  RESPONSE_DATE_AND_TIME: '답변일시',
  // RESPONSE_STATUS: '답변상태',
  // SHOPPING_MALL: '쇼핑몰',
  // PRODUCT_NUMBER: '상품번호',
  // PRODUCT_NAME: '상품명',
  // INQUIRY_TYPE: '문의유형',
  // SECRET: '비밀',
  // INQUIRIES: '문의내용',
  // PURCHASE_OR_NOT: '구매여부',
  // PARTNER: '파트너사',
  // WRITER: '작성자',
  // INQUIRY_DATE: '문의일시',
  // RESPONSE_DATE_AND_TIME: '답변일시',

  // ANSWER_COMPLETED: '답변완료',
  // WAITING_FOR_REPLY: '답변대기',
  // SECRET: '비밀',
  // NORMAL: '일반',
  // PURCHASE: '구매',
  // DO_NOT_PURCHASE: '구매안함',
  // WRONG_USER: '잘못된 사용자',
  // WITHDRAWAL_MEMBER: '탈퇴회원',
  NUMBER_OF_INQUIRY_RESULTS: '답변지연 조회결과 문의 건수',
  PRODUCT_INQUIRY_RESULT: '상품조회결과',
  // ALL: '전체',

  INQUIRYANSWER_DETAILS: '문의/답변 내역',
  // PURCHASE: '구매',
  COMMENT: '댓글',
  DELETE: '삭제',
  SAVE: '저장',

  REPLIED_ALERT_MSG1: '탈퇴회원',
  REPLIED_ALERT_MSG2:
    '고객이 확인 한 답변을 삭제할 경우, 추가 클레임이 발생할 수 있습니다.\r\n그래도 삭제하시겠습니까?',
  REPLIED_ALERT_MSG3: '답변저장 완료',
  REPLIED_ALERT_MSG4: '내용을 정확히 입력해주세요.',
  REPLIED_ALERT_MSG5: '답변삭제 완료',

  ORDERINFOR_TABLE_TXT1: '구매 내역 (문의시점 기준 최근 주문번호)',
  ORDERINFOR_TABLE_TXT2: '주문번호',
  ORDERINFOR_TABLE_TXT3: '상품번호',
  ORDERINFOR_TABLE_TXT4: '상품명',
  ORDERINFOR_TABLE_TXT5: '옵션명',
  ORDERINFOR_TABLE_TXT6: '판매금액',
  ORDERINFOR_TABLE_TXT7: '수량',
  ORDERINFOR_TABLE_TXT8: '구매자명',
  ORDERINFOR_TABLE_TXT9: '수령인',
  ORDERINFOR_TABLE_TXT10: '연락처1',
  ORDERINFOR_TABLE_TXT11: '연락처2',
  ORDERINFOR_TABLE_TXT12: '주소',
  ORDERINFOR_TABLE_TXT13: '배송비',
  ORDERINFOR_TABLE_TXT14: '결제일시',
  ORDERINFOR_TABLE_TXT15: '발송일시',
  ORDERINFOR_TABLE_TXT16: '택배사',
  ORDERINFOR_TABLE_TXT17: '배송시 요청사항',
});
