import { OptionData } from '@/helpers/type';

export const HOUR_MINUTE_SECOND_START_SELECT_OPTIONS: OptionData<string>[] = [
  { name: '00:00:00', value: '00' },
  { name: '01:00:00', value: '01' },
  { name: '02:00:00', value: '02' },
  { name: '03:00:00', value: '03' },
  { name: '04:00:00', value: '04' },
  { name: '05:00:00', value: '05' },
  { name: '06:00:00', value: '06' },
  { name: '07:00:00', value: '07' },
  { name: '08:00:00', value: '08' },
  { name: '09:00:00', value: '09' },
  { name: '10:00:00', value: '10' },
  { name: '11:00:00', value: '11' },
  { name: '12:00:00', value: '12' },
  { name: '13:00:00', value: '13' },
  { name: '14:00:00', value: '14' },
  { name: '15:00:00', value: '15' },
  { name: '16:00:00', value: '16' },
  { name: '17:00:00', value: '17' },
  { name: '18:00:00', value: '18' },
  { name: '19:00:00', value: '19' },
  { name: '20:00:00', value: '20' },
  { name: '21:00:00', value: '21' },
  { name: '22:00:00', value: '22' },
  { name: '23:00:00', value: '23' },
];
export const HOUR_MINUTE_SECOND_END_SELECT_OPTIONS: OptionData<string>[] = [
  { name: '23:59:59', value: '24' },
  { name: '22:59:59', value: '23' },
  { name: '21:59:59', value: '22' },
  { name: '20:59:59', value: '21' },
  { name: '19:59:59', value: '20' },
  { name: '18:59:59', value: '19' },
  { name: '17:59:59', value: '18' },
  { name: '16:59:59', value: '17' },
  { name: '15:59:59', value: '16' },
  { name: '14:59:59', value: '15' },
  { name: '13:59:59', value: '14' },
  { name: '12:59:59', value: '13' },
  { name: '11:59:59', value: '12' },
  { name: '10:59:59', value: '11' },
  { name: '09:59:59', value: '10' },
  { name: '08:59:59', value: '09' },
  { name: '07:59:59', value: '08' },
  { name: '06:59:59', value: '07' },
  { name: '05:59:59', value: '06' },
  { name: '04:59:59', value: '05' },
  { name: '03:59:59', value: '04' },
  { name: '02:59:59', value: '03' },
  { name: '01:59:59', value: '02' },
  { name: '00:59:59', value: '01' },
];
export const HOUR_MINUTEUNCHANGED_START_SELECT_OPTIONS: OptionData<string>[] = [
  { name: '00:00', value: '00:00' },
  { name: '01:00', value: '01:00' },
  { name: '02:00', value: '02:00' },
  { name: '03:00', value: '03:00' },
  { name: '04:00', value: '04:00' },
  { name: '05:00', value: '05:00' },
  { name: '06:00', value: '06:00' },
  { name: '07:00', value: '07:00' },
  { name: '08:00', value: '08:00' },
  { name: '09:00', value: '09:00' },
  { name: '10:00', value: '10:00' },
  { name: '11:00', value: '11:00' },
  { name: '12:00', value: '12:00' },
  { name: '13:00', value: '13:00' },
  { name: '14:00', value: '14:00' },
  { name: '15:00', value: '15:00' },
  { name: '16:00', value: '16:00' },
  { name: '17:00', value: '17:00' },
  { name: '18:00', value: '18:00' },
  { name: '19:00', value: '19:00' },
  { name: '20:00', value: '20:00' },
  { name: '21:00', value: '21:00' },
  { name: '22:00', value: '22:00' },
  { name: '23:00', value: '23:00' },
];
export const HOUR_MINUTEUNCHANGED_END_SELECT_OPTIONS: OptionData<string>[] = [
  { name: '00:59', value: '00:59' },
  { name: '01:59', value: '01:59' },
  { name: '02:59', value: '02:59' },
  { name: '03:59', value: '03:59' },
  { name: '04:59', value: '04:59' },
  { name: '05:59', value: '05:59' },
  { name: '06:59', value: '06:59' },
  { name: '07:59', value: '07:59' },
  { name: '08:59', value: '08:59' },
  { name: '09:59', value: '09:59' },
  { name: '10:59', value: '10:59' },
  { name: '11:59', value: '11:59' },
  { name: '12:59', value: '12:59' },
  { name: '13:59', value: '13:59' },
  { name: '14:59', value: '14:59' },
  { name: '15:59', value: '15:59' },
  { name: '16:59', value: '16:59' },
  { name: '17:59', value: '17:59' },
  { name: '18:59', value: '18:59' },
  { name: '19:59', value: '19:59' },
  { name: '20:59', value: '20:59' },
  { name: '21:59', value: '21:59' },
  { name: '22:59', value: '22:59' },
  { name: '23:59', value: '23:59' },
];
export const HOUR_SELECT_OPTIONS: OptionData<string>[] = [
  { name: '00', value: '00' },
  { name: '01', value: '01' },
  { name: '02', value: '02' },
  { name: '03', value: '03' },
  { name: '04', value: '04' },
  { name: '05', value: '05' },
  { name: '06', value: '06' },
  { name: '07', value: '07' },
  { name: '08', value: '08' },
  { name: '09', value: '09' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
];
export const MINUTE_SELECT_OPTIONS: OptionData<string>[] = [
  { name: '00', value: '00' },
  { name: '01', value: '01' },
  { name: '02', value: '02' },
  { name: '03', value: '03' },
  { name: '04', value: '04' },
  { name: '05', value: '05' },
  { name: '06', value: '06' },
  { name: '07', value: '07' },
  { name: '08', value: '08' },
  { name: '09', value: '09' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
  { name: '24', value: '24' },
  { name: '25', value: '25' },
  { name: '26', value: '26' },
  { name: '27', value: '27' },
  { name: '28', value: '28' },
  { name: '29', value: '29' },
  { name: '30', value: '30' },
  { name: '31', value: '31' },
  { name: '32', value: '32' },
  { name: '33', value: '33' },
  { name: '34', value: '34' },
  { name: '35', value: '35' },
  { name: '36', value: '36' },
  { name: '37', value: '37' },
  { name: '38', value: '38' },
  { name: '39', value: '39' },
  { name: '40', value: '40' },
  { name: '41', value: '41' },
  { name: '42', value: '42' },
  { name: '43', value: '43' },
  { name: '44', value: '44' },
  { name: '45', value: '45' },
  { name: '46', value: '46' },
  { name: '47', value: '47' },
  { name: '48', value: '48' },
  { name: '49', value: '49' },
  { name: '50', value: '50' },
  { name: '51', value: '51' },
  { name: '52', value: '52' },
  { name: '53', value: '53' },
  { name: '54', value: '54' },
  { name: '55', value: '55' },
  { name: '56', value: '56' },
  { name: '57', value: '57' },
  { name: '58', value: '58' },
  { name: '59', value: '59' },
];

export const DEFAULT_DATE_RANGE = {
  MIN: '1900-01-01',
  MAX: '2999-12-31',
};

export const DEFAULT_TIME_RANGE = {
  START: '00:00:00',
  END: '23:59:59',
  BLANK_START: '::00',
  BLANK_END: '::59',
};

export const DEFAULT_DATE_TIME_RANGE = {
  MIN: `${DEFAULT_DATE_RANGE.MIN} ${DEFAULT_TIME_RANGE.START}`,
  MAX: `${DEFAULT_DATE_RANGE.MAX} ${DEFAULT_TIME_RANGE.END}`,
};
