export default Object.freeze({
  TITLE: '보안인증',
  SELECTING_AUTH_TYPE: '인증수단 선택',
  AUTH_TYPE_MEMBER_SMS: 'NHN커머스 회원 휴대폰번호 인증',
  AUTH_TYPE_MEMBER_EMAIL: 'NHN커머스 회원 이메일 인증',
  AUTH_INFO: '인증정보',
  RECEIVING_AUTH_CODE: '인증번호 받기',
  TO_INPUT_AUTH_CODE: '인증번호 입력',
  TO_COMPLETE_AUTH: '인증 완료',
  REMAINING_EXPIRED_TIME: '남은 인증시간',
  HELP_MSG_PLEASE_AUTH_CODE: '인증번호를 입력해주세요.',
  HELP_MSG_PLEASE_REAUTH: '인증시간이 만료되었습니다. 인증번호 재전송 버튼을 클릭해주세요.',
  HELP_MSG_INPUT_CORRECTLY: '인증번호가 일치하지 않습니다. 확인 후 정확히 입력해주세요.',
  HELP_MSG_COMPLETE_AUTH: '보안인증이 완료되었습니다.',
  RESEND_AUTH_CODE: '인증번호 재전송',
  TIME_OUT_MESSAGE: '인증시간이 만료되었습니다. 인증번호 재전송 버튼을 클릭해주세요.',

  // captcha
  HELP_MSG_CAPTCHA: '자동 입력 방지를 위해 아래 이미지의 문자 및 숫자를 순서대로 정확히 입력해주세요.',
  CAPTCHA_ALT_IMAGE: '캡챠 이미지',
  CAPTCHA_REFRESH: '이미지 새로고침',
  CAPTCHA_PLACEHOLDER: '순서대로 숫자 및 문자 모두 입력',
  CAPTCHA_EMPTY_CODE: '자동등록방지 문자를 입력해주세요.',
});
