

































import { Vue, Component, Prop, Model, Ref } from 'vue-property-decorator';
import IpOctetInput from '@/components/common/input/ipAddress/IpOctetInput.vue';
import { InputNumber } from '@/types';
import { Ip } from '@/components/common/IpAddressInputList.vue';

type Protocol = 'IPv4'; // 현재 스펙엔 없으나 IPv6 지원 염두해두고 해당 컴포넌트 설계하자.

interface ProtocolOption {
  protocol: Protocol;
  octet: 4 | 6;
}

const protocolOption: ProtocolOption = {
  protocol: 'IPv4',
  octet: 4,
};

export type Ipv4Address = InputNumber[]; // 튜플로 4개만 받고싶은데 받을때/내보낼때 너무 힘들어져서 타협
export type bandwidth = InputNumber;

export type IpAddressType = Ipv4Address;

let uid = 0;

@Component({
  components: { IpOctetInput },
})
export default class IpAddressInput extends Vue {
  @Prop({ required: false, default: 'IPv4' }) private protocol!: Protocol;
  @Prop({ required: false, default: true }) private usingBand!: boolean;
  @Model('change')
  private value!: Ip;
  @Ref() private readonly bandwidthInput!: IpOctetInput;
  @Ref() private readonly ipOctetInputs: IpOctetInput[];

  private uid!: string;
  private option: ProtocolOption = protocolOption;
  private band = false;

  private address: IpAddressType = [];
  private bandwidth: bandwidth = '';

  beforeCreate(): void {
    this['uid'] = uid.toString();
    uid += 1;
  }

  created() {
    if (this.value.address.length >= this.option.octet - 1) {
      this.address = this.value.address.split('.').map(v => (v ? Number(v) : ''));
      this.bandwidth = this.value.bandwidth ? Number(this.value.bandwidth) : '';
      this.band = Boolean(this.value.bandwidth);
      return;
    }
    this.address = Array(this.option.octet).fill('');
  }

  get lastOctet(): InputNumber {
    return this.address[this.option.octet - 1];
  }

  get bandwidthLocked(): boolean {
    return this.lastOctet >= 255;
  }

  private change(_?, isLastOctet = false): void {
    if (isLastOctet === true) {
      this.band = false;
      this.bandwidth = '';
    }
    this.$emit('change', { address: this.address.join('.'), bandwidth: this.bandwidth.toString() });
  }

  private validateBandWidth(): boolean {
    if (this.bandwidth === '' || this.lastOctet < this.bandwidth) return true;
    alert(this.$t('CONFIGURATION.SECURITY.ALERT_IP_BAND_RANGE_WRONG'));
    this.bandwidth = '';
    this.change();
    this.bandwidthInput.focus();
    return false;
  }

  public validate(): boolean {
    const addressValid = this.ipOctetInputs.every(ipOctetInput => ipOctetInput.validate());
    if (!addressValid) return false;
    if (!this.band) return true;
    if (!this.bandwidthInput.validate()) return false;
    return this.validateBandWidth();
  }

  public focus(): void {
    this.ipOctetInputs[0].focus();
  }

  private focusMove(nextIndex: number): void {
    if (nextIndex < 0 || nextIndex >= this.option.octet) return;
    this.ipOctetInputs[nextIndex].focus();
  }

  private onFocusToBand(isChecked: boolean) {
    if (!isChecked) return;
    this.$nextTick(() => {
      this.bandwidthInput.focus();
    });
  }
}
