export default Object.freeze({
  TITLE: '상품 복사',
  DIALOGUES: `
    선택하신 {productNo} 상품을 복사합니다.
  	상품복사 유형을 선택해주세요.
  	재고수량 연동 안 함 선택 시: 재고수량 복사 여부 선택 가능합니다.
  	재고수량 연동 선택 시: 상품후기 복사 여부 선택 가능합니다.
  `,

  STOCK_QUANTITY_LINKAGE: '재고수량 연동',
  COPY_STOCK_QUANTITY: '재고수량 복사',
  COPY_PRODUCT_REVIEW: '상품후기 복사',
  SELECT_MALL: '쇼핑몰 선택',

  SYNC_Y: '연동함',
  SYNC_N: '연동 안 함',
  COPY_Y: '복사함',
  COPY_N: '복사 안 함',
  ALERT_COPY: '상품후기 복사 시 다른 쇼핑몰로 상품복사가 불가합니다.',
});
