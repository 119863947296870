
import { Component, Vue } from 'vue-property-decorator';
import { PopupClose, PopupData, PopupResult } from '@/helpers/popup';

// localStorage를 사용하지 않는 WindowPopupMain
// 최초진입 없이, 브라우저 초기화시에도 팝업을 띄워야하는 경우 사용목적
@Component
export default class NoLocalStorageWindowPopup extends Vue {
  public get callbackId() {
    return this.$route.query?.callbackId ?? '';
  }

  public onNegativeClick(data: PopupData | null = null) {
    this.closePopup({ state: PopupClose.CLOSE.toString(), data: data });
  }

  public onPositiveClick(data: PopupData | null = null) {
    this.closePopup({ state: PopupClose.CONFIRM.toString(), data: data });
  }

  protected closePopup(result?: PopupResult) {
    if (result) window.opener[this.callbackId as string](result);
    delete window.opener[this.callbackId as string];
    window.close();
  }
}
