

































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { messageTemplate } from '@/const/words/templateStrings';
import { PutSmsTemplatesAutoTemplatesTemplateTypeRequest, AutoSmsTemplateType } from 'ncp-api-supporter';
import { namespace } from 'vuex-class';
import unsavedDetector from '@/directives/input/unsavedDetector';
import { RadioBoxGroupOption } from '@/helpers/type';
import { getCurrentMallNo } from '@/utils/mall';
import { isValidate } from '@/utils/validate';
import _ from 'lodash';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

const member = namespace('member');
export interface TemplateType {
  content: string;
  name: string;
  nightUsable: boolean;
  type: AutoSmsTemplateType;
  usable: boolean;
}

@Component({
  components: { RadioGroup, ToolTip },
  directives: { unsavedDetector },
})
export default class SmsTemplate extends Vue {
  @Prop({ required: true })
  private readonly template!: TemplateType;
  @Prop({ required: true })
  private readonly contentIndex!: number;
  @Prop()
  private readonly availableSendSms!: () => boolean;

  @member.Action('changeSmsTemplateSetting')
  private changeSmsTemplateSetting!: (request: PutSmsTemplatesAutoTemplatesTemplateTypeRequest) => void;
  @member.Getter('getData')
  private autoSmsRequestData!: PutSmsTemplatesAutoTemplatesTemplateTypeRequest[];

  private isUsed = false;
  private isPreview = false;
  private content = this.template.content;
  private messageTemplate = messageTemplate();

  private usedOption: RadioBoxGroupOption<boolean> = {
    name: `message-template-${this.contentIndex}`,
    data: [
      { id: `used-${this.contentIndex}`, value: true, display: 'MEMBER.SMS_AUTO.USED' },
      { id: `not-used-${this.contentIndex}`, value: false, display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  };

  private get request(): PutSmsTemplatesAutoTemplatesTemplateTypeRequest {
    return {
      pathParams: {
        templateType: this.template.type,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
      data: {
        usable: this.isUsed,
        content: this.content,
      },
    };
  }

  private changeUsedType(index: number) {
    if (!this.isUsed && this.isPreview) {
      this.onUnused();
    }
    this.changeContent(index);
  }

  private changeContent(index: number) {
    if (index !== this.contentIndex) return;
    this.changeSmsTemplateSetting(this.request);
  }

  private convertTemplate() {
    this.messageTemplate.forEach(({ key, example }) => {
      this.content = this.isPreview ? this.content.replaceAll(key, example) : this.content.replaceAll(example, key);
    });
  }

  private onPreview() {
    if (!this.isUsed) {
      alert(this.$t('MEMBER.SMS_AUTO.WARN_USER'));
      return;
    }

    this.isPreview = true;
    this.convertTemplate();
    this.changeSmsTemplateSetting(this.request);
  }

  private onEdit() {
    this.isPreview = false;
    this.convertTemplate();
    this.changeSmsTemplateSetting(this.request);
  }

  private onUnused() {
    this.isPreview = false;
    this.convertTemplate();
  }

  private async onSave(): Promise<void> {
    if (!this.availableSendSms()) return;
    if (!isValidate(this.content, this.$t('MEMBER.SMS_AUTO.WARN_NULL'))) return;

    try {
      const response = await this.$api.putSmsTemplatesAutoTemplatesTemplateType(_.cloneDeep(this.request));
      const successCode = 204;
      if (response.status !== successCode) return;
      alert(this.$t('MEMBER.SMS_AUTO.SUCCESS_SEND'));
    } catch (err) {
      console.error(err);
      if (err.status !== 403) {
        alert(this.$t(err.message));
      }
    }
  }

  @Watch('template')
  private init() {
    this.isUsed = this.template.usable;
    this.content = this.template.content;
  }

  created() {
    this.init();
  }
}
