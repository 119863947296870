import { RouteConfig } from 'vue-router';
import AdminRegisterLayout from '@/views/AdminRegisterLayout.vue';

export default (): RouteConfig => {
  return {
    path: '/admin-register',
    name: 'AdminRegister',
    component: AdminRegisterLayout,
    meta: {
      nonMember: true,
    },
  };
};
