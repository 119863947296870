
































































































import { Component, Ref, Watch } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import Grid from '@/components/common/grid/Main.vue';
import { convertToI18n } from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  GetProductsConfigurableOptions,
  GetProductsConfigurableOptionsRequest,
  GetProductsMallProductNoRequest,
  NCPResponse,
  ProductsConfigurableOption,
} from 'ncp-api-supporter';
import { ConfigurableOptionType } from '@/types';
import {
  configurationContainerOptions,
  getConfigurationOptionGridProps,
  keywordTypesOptions,
  resetProductConfigurableOptions,
  SearchKeywordType,
  selectedConfigurationContainerOptions,
  selectedConfigurationOptionGridProps,
} from '@/views/popups/product/productAdd/ProductOption/SetConfigurationOptionPopup/SetConfigurationOption';
import { intersection } from 'underscore';
import { GridEventProps } from '@/types/tui-grid/event';

@Component({
  components: {
    TextInput,
    SearchButton,
    Grid,
    SelectBox,
  },
})
export default class SetConfigurationOption extends WindowPopupMainVue {
  private keywordTypesOptions = keywordTypesOptions;
  private keywordType = SearchKeywordType.productName;
  private gridProps = getConfigurationOptionGridProps();
  private selectedOptionGridProps = selectedConfigurationOptionGridProps();
  private containerOptions = configurationContainerOptions;
  private selectedOptionContainerOptions = selectedConfigurationContainerOptions;
  private configurationOptions: ConfigurableOptionType[] = [];
  private selectedConfigurationOptions: ConfigurableOptionType[] = [];
  private totalCount = 0;
  private keyword = '';
  @Ref() private readonly grid: Grid;

  @Watch('$route')
  private onQueryStringChanged(): void {
    this.searchConfigurationOption();
  }

  created() {
    this.searchConfigurationOption();
    this.selectedConfigurationOptions = this.data.savedConfigurationOptions;
    this.countCellsToMerge(this.selectedConfigurationOptions);
  }

  private async searchConfigurationOption(): Promise<void> {
    const params: GetProductsConfigurableOptionsRequest['params'] = resetProductConfigurableOptions();
    params.mallNo = this.data.mallNo;
    params.deliveryTemplateNo = this.data.deliveryTemplateNo.toString();
    params[this.keywordType] = this.keyword;
    const { page, size } = this.$route.query;
    if (size) {
      params.size = Number(size);
    }
    if (page) {
      params.page = Number(page);
    }
    const { data }: NCPResponse<GetProductsConfigurableOptions> = await this.$api.getProductsConfigurableOptions({
      params,
    });

    this.configurationOptions = data.contents;
    this.totalCount = data.totalCount;
    this.countCellsToMerge(this.configurationOptions);
  }

  //TODO api가 걸러주는지 확인 후 제거
  private removeProhibitionSale(data: ProductsConfigurableOption[]): ConfigurableOptionType[] {
    return data.filter(({ saleSettingStatusType }) => saleSettingStatusType !== 'PROHIBITION_SALE');
  }

  private countCellsToMerge(options: ConfigurableOptionType[]): void {
    let count = 1;
    let prevProductItem: ConfigurableOptionType = null;
    options.forEach((item, index) => {
      if (index === 0) {
        prevProductItem = item;
      } else if (prevProductItem.mallProductNo === item.mallProductNo) {
        count++;
      } else {
        this.setRowSpan(prevProductItem, count);
        prevProductItem = item;
        count = 1;
      }
    });
    this.setRowSpan(prevProductItem, count);
  }

  private setRowSpan(prevProductItem: ConfigurableOptionType, count: number): void {
    if (count <= 1) return;
    prevProductItem._attributes = {
      rowSpan: {
        mallProductNo: count,
        productName: count,
        saleStatusType: count,
      },
    };
  }

  private onItemClicked({ rowKey: selectedRowKey, columnName }: GridEventProps): void {
    if (columnName !== 'delete') return;
    this.selectedConfigurationOptions = this.selectedConfigurationOptions.filter(
      ({ rowKey }) => rowKey !== selectedRowKey,
    );
  }

  private onClickOptionAddition(): void {
    const checkedRows = this.grid.getCheckedRows();
    if (checkedRows.length <= 0) return alert(convertToI18n('PLEASE_SELECT'));

    const MAX_OPTION = 10;

    if (
      this.selectedConfigurationOptions.length >= MAX_OPTION ||
      checkedRows.length > MAX_OPTION ||
      this.selectedConfigurationOptions.length + checkedRows.length > MAX_OPTION
    ) {
      return alert(convertToI18n('PLEASE_MAX_10_SET'));
    }

    const addedIds: number[] = this.selectedConfigurationOptions.map(({ mallOptionNo }) => mallOptionNo);

    if (addedIds.length > 0) {
      const checkedIds = checkedRows.map(({ mallOptionNo }) => mallOptionNo);
      const duplicateIds = intersection(addedIds, checkedIds);

      if (duplicateIds.length === checkedIds.length) return alert(convertToI18n('ALREADY_ADD'));

      if (duplicateIds.length > 0) alert(convertToI18n('DUPLICATE_OPTIONS'));
    }

    const newRows = checkedRows.filter(({ mallOptionNo }) => !addedIds.includes(mallOptionNo as any));
    this.selectedConfigurationOptions = this.selectedConfigurationOptions.concat(newRows as any);

    this.countCellsToMerge(this.selectedConfigurationOptions);
  }

  private async onClickSaveBtn(): Promise<void> {
    await this.checkDeletedConfigurationOptions();

    if (this.selectedConfigurationOptions.length === 0) {
      return alert(convertToI18n('SELECT_SET_OPTION'));
    }

    this.onPositiveClick({ selectedOptions: this.selectedConfigurationOptions, index: this.data.index });
  }

  private async checkDeletedConfigurationOptions(): Promise<void> {
    const deletedOptions = await Promise.all(
      this.selectedConfigurationOptions.map(({ mallProductNo }, index) => this.getDeletedOption(mallProductNo, index)),
    );

    this.deleteConfigurationOptions(deletedOptions);
  }

  private deleteConfigurationOptions(deletedOptions: { isDeletedOption: boolean; index: number }[]) {
    let isDeleted = false;
    deletedOptions.forEach(({ isDeletedOption, index }) => {
      if (isDeletedOption === true) {
        this.selectedConfigurationOptions.splice(index, 1, null);
        isDeleted = true;
      }
    });

    this.selectedConfigurationOptions = this.selectedConfigurationOptions.filter(Boolean);

    if (isDeleted === false) return;
    alert(convertToI18n('SELECTABLE_N'));
  }

  private async getDeletedOption(
    mallProductNo: number,
    index: number,
  ): Promise<{ isDeletedOption: boolean; index: number }> {
    const request: GetProductsMallProductNoRequest = {
      pathParams: {
        mallProductNo: mallProductNo.toString(),
      },
    };
    try {
      await this.$api.getProductsMallProductNo(request);
      return { isDeletedOption: false, index };
    } catch (e) {
      return { isDeletedOption: true, index };
    }
  }

  private get selectedOptionsTotalCount(): number {
    return this.selectedConfigurationOptions.length;
  }

  private get keywordValidType(): 'number' | '' {
    const numberTypeOptions = ['mallProductNo', 'mallOptionNo'];
    return numberTypeOptions.includes(this.keywordType) ? 'number' : '';
  }

  private resetKeyword(): void {
    this.keywordType = SearchKeywordType.productName;
    this.keyword = '';
  }
}
