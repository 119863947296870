





































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  radioOption,
  selectOption,
  expandedReviewSettingInit,
  widgetConfigInit,
} from '@/views/contents/board/basic/boardSettingSub/BoardSetting.ts';
import SelectBox from '@/components/common/SelectBox.vue';
import { BoardData } from '@/types/boardSetting';
import {
  PutExpandedReviewSetting,
  ExpandedReviewSetting,
  WidgetReviewType,
  PutWidgetConfig,
  WidgetConfig,
} from 'ncp-api-supporter';
import { throwPopup } from '@/helpers/popup';
import unsavedDetector from '@/directives/input/unsavedDetector';
import { PreviewImgTypes } from '@/types';

@Component({
  components: { ToolTip, TextInput, RadioGroup, SelectBox },
  directives: { unsavedDetector },
})
export default class BoardAdditionalSetting extends Vue {
  @Prop({ required: true }) private boardData!: BoardData;

  private radioOption = radioOption;
  private selectOption = selectOption;
  private previewImgType: PreviewImgTypes = 'FIRST_TYPE';
  private isVisiblePreview = false;
  private expandedReviewSetting: ExpandedReviewSetting = expandedReviewSettingInit;
  private widgetConfig: WidgetConfig = widgetConfigInit;

  created() {
    this.expandedReviewSetting = { ...this.boardData.expandedReviewSetting };
    this.widgetConfig = { ...this.expandedReviewSetting.widgetConfig };
  }

  private openPopup(type: WidgetReviewType, widgetCount: number) {
    throwPopup({
      name: 'PreviewPopup',
      data: {
        type,
        widgetCount,
      },
    });
  }

  private typePreviewOpen(value: string) {
    this.isVisiblePreview = true;

    if (value === 'type1') this.previewImgType = 'FIRST_TYPE';
    if (value === 'type2') this.previewImgType = 'SECOND_TYPE';
  }

  private typePreviewClose() {
    this.isVisiblePreview = false;
  }

  public getExpandedReviewSetting(): PutExpandedReviewSetting {
    return {
      accumulationRewardNoticeText: this.expandedReviewSetting.accumulationRewardNoticeText,
      allReviewCntPerPage: this.expandedReviewSetting.allReviewCntPerPage,
      writingReviewNoticeText: this.expandedReviewSetting.writingReviewNoticeText,
      useGatheringPhotoReview: this.expandedReviewSetting.gatheringPhotoReviewUseYn === 'Y',
      reviewRewardNoticeText: this.expandedReviewSetting.reviewRewardNoticeText,
      useReviewRecommend: this.expandedReviewSetting.reviewRecommendUseYn === 'Y',
      photoReviewDisplayType: this.expandedReviewSetting.photoReviewDisplayType,
      photoReviewCntPerPage: this.expandedReviewSetting.photoReviewCntPerPage,
      noPhotoReviewText: this.expandedReviewSetting.noPhotoReviewText,
      productReviewCntPerPage: this.expandedReviewSetting.productReviewCntPerPage,
      widgetConfig: {
        allReviewWidgetCount: this.widgetConfig.allReviewWidgetCount,
        useAllReviewWidget: this.widgetConfig.allReviewWidgetUseYn === 'Y',
        photoReviewWidgetCount: this.widgetConfig.photoReviewWidgetCount,
        usePhotoReviewWidget: this.widgetConfig.photoReviewWidgetUseYn === 'Y',
        productReviewWidgetCount: this.widgetConfig.productReviewWidgetCount,
        useProductReviewWidget: this.widgetConfig.productReviewWidgetUseYn === 'Y',
      } as PutWidgetConfig,
    };
  }
}
