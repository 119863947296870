export default Object.freeze({
  //file: src\views\contents\board\basic\Questions.ts
  RESPONSE_STATUS: '답변상태',
  SHOPPING_MALL: '쇼핑몰',
  PRODUCT_NUMBER: '상품번호',
  PRODUCT_NAME: '상품명',
  INQUIRY_TYPE: '문의유형',
  SECRET: '비밀글',
  TITLE: '제목',
  INQUIRIES: '문의내용',
  NOT_PURCHASE: '구매 안 함',
  PURCHASE_HEADER: '구매여부',
  WRITER: '작성자',
  INQUIRY_DATE_AND_TIME: '문의일시',
  RESPONSE_DATE_AND_TIME: '답변일시',
  SELECT: '선택',
  PRODUCT: '상품',
  DELIVERY: '배송',
  CANCEL: '취소',
  RETURN: '반품',
  EXCHANGE: '교환',
  REFUND: '환불',
  OTHER: '기타',
  ANSWER_COMPLETED: '답변완료',
  WAITING_FOR_REPLY: '답변대기',
  ISSECRET: '비밀',
  NORMAL: '일반',
  PURCHASE: '구매함',
  WRONG_USER: '잘못된 사용자',
  WITHDRAWAL_MEMBER: '탈퇴회원',
  NON_MEMBER: '비회원',
  DISPLAYTYPE: '전시상태',
  DISPLAY: '전시중',
  BLIND: '블라인드',

  //file: src\views\contents\board\basic\Questions.vue
  COMFIRM_CHANGE_DISPLAYTYPE: '전시상태를 ‘{0}‘로 변경하시겠습니까?',
  ALERT_CHANGED: '변경되었습니다.',

  //file: src\views\top\board\basic\Questions.vue
  DELAYED_COUNT: '답변지연',
  KEY: '건',
  TERM: '기간',
  SEARCH_WORD: '검색어',
  SUBSCRIPTION_TYPE: '상세검색',
  REGISTRATION_DATE: '등록일',
  REPLY_DATE: '답변일',
  SEARCH: '통합검색',
  NAME: '작성자',
  EXHIBITION_STATUS: '전시상태',
  EXHIBITION: '전시',

  //file: src\views\contents\board\basic\QuestionsOrderInfo.vue
  PURCHASE_HISTORY: '구매내역(최근 주문번호 기준)',
  ORDER_NUMBER: '주문번호',
  OPTION_NAME: '옵션명',
  SALE_PRICE: '판매금액',
  QUANTITY: '수량',
  BUYERS_NAME: '구매자명',
  RECIPIENTS_NAME: '수령자명',
  CONTACT_1: '연락처1',
  CONTACT_2: '연락처2',
  SHIPPING_ADDRESS: '배송지주소',
  SHIPPING_FEE: '배송비',
  PAYMENT_DATE_AND_TIME: '결제일시',
  SHIPMENT_DATE_AND_TIME: '발송일시',
  COURIER: '택배사',
  DELIVERY_REQUEST: '배송요청사항',
  NONE_TEXT: '비회원 상품문의이거나, 최근 구매내역이 존재하지 않습니다.',

  //file: src\views\contents\board\basic\QuestionsInquiryInfo.vue
  INQUIRY_ANSWER_DETAILS: '문의/답변 내역',
  COMMENT: '댓글',
  DELETE: '삭제',
  SAVE: '저장',
  ENTER_YOUR_ANSWER: '답변을 입력해주세요.',
  ONLY_REGISTER_100: '답변(댓글)은 최대 100개까지만 등록이 가능합니다.',
  DELETE_ALERT_TEXT: '고객이 확인 한 답변을 삭제할 경우,\r추가 클레임이 발생할 수 있습니다.\r그래도 삭제하시겠습니까?',
  THE_ANSWER_IS_DELETED: '답변삭제가 완료되었습니다.',
  SAVE_ANSWER_COMPLETED: '답변저장 완료',
  CHANGE_SECRET: '‘{0}’로 변경하시겠습니까?',

  ORDER_DETAIL_BY_RECENT_ORDER_NUMBER: '최근 주문번호 기준 주문내역',
});
