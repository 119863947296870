







import { Vue, Component } from 'vue-property-decorator';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';
import { i18n } from '@/main';

@Component({ components: { TabMenu } })
export default class Sales extends Vue {
  private menuList: TabMenuI[] = [
    { display: i18n.t('STATISTIC.SALES.PERIOD_SALE_TAB') as string, path: 'period-sales' },
    // 2차 그랜드 오픈 스펙 기준 판매통계 추가 (기간별 판매통계 현황만 추가, 상품통계, 카테고리통계는 우선 제외)
    // { display: i18n.t('STATISTIC.SALES.PRODUCT_SALE_TAB') as string, path: 'product-sales' },
    // { display: i18n.t('STATISTIC.SALES.CATEGORY_SALE_TAB') as string, path: 'category-sales' },
  ];
}
