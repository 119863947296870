import getRootRoute from '@/router/modules/root';
import getAuthRoute from '@/router/modules/auth';
import getOauthRoute from '@/router/modules/oauth';
import getPopupRoute from '@/router/modules/popup';
import getAdditionRoute from '@/router/modules/addition';
import getAppearanceRoute from '@/router/modules/appearance';
import getBoardRoute from '@/router/modules/board';
import getConfigurationRoute from '@/router/modules/configuration';
import getMarketingRoute from '@/router/modules/marketing';
import getMemberRoute from '@/router/modules/member';
import getProductRoute from '@/router/modules/product';
import getPromotionRoute from '@/router/modules/promotion';
import getShippingRoute from '@/router/modules/shipping';
import getStatisticsRoute from '@/router/modules/statistics';
import getAdminRegisterRoute from '@/router/modules/admin-register';
import getCrmRootRoute from '@/router/modules/crm';
import getPartnerRoute from '@/router/modules/partner';
import getInstagramRoute from '@/router/modules/instagram';
import getEtcRoute from '@/router/modules/etc';
import getAppRoute from '@/router/modules/app';
import getPremiumRoute from '@/router/premium';
import { RouteConfig } from 'vue-router';
import { GridName } from '@/components/common/grid/ColumnController.vue';
import { ProExcelCreateMenu } from 'ncp-api-supporter';

export type ShopByRouteConfig = RouteConfig & {
  meta?: {
    gridName?: GridName;
    excelCreateMenuKey?: ProExcelCreateMenu;
  };
};

const routes: ShopByRouteConfig[] = [
  getRootRoute(),
  getAuthRoute(),
  getOauthRoute(),
  getPopupRoute(),
  getAdditionRoute(),
  getAppearanceRoute(),
  getBoardRoute(),
  getConfigurationRoute(),
  getMarketingRoute(),
  getMemberRoute(),
  getProductRoute(),
  getPromotionRoute(),
  getShippingRoute(),
  getStatisticsRoute(),
  getAdminRegisterRoute(),
  getAppRoute(),
  getCrmRootRoute(),
  getPartnerRoute(),
  getInstagramRoute(),
  getPremiumRoute(), //프리미엄에 노출될 contents

  ...getEtcRoute(),
];

export default routes;
