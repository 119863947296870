import AuthLayout from '@/views/AuthLayout.vue';
import { RouteConfig } from 'vue-router';
import AuthMain from '@/views/auth/AuthMain.vue';
import AuthChangePassword from '@/views/auth/AuthChangePassword.vue';

export default (): RouteConfig => {
  return {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '/',
        name: 'AuthMain',
        component: AuthMain,
      },
      {
        path: 'change-password',
        name: 'AuthChangePassword',
        component: AuthChangePassword,
      },
    ],
    meta: {
      nonMember: true,
    },
  };
};
