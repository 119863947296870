





















































































import { Vue, Component } from 'vue-property-decorator';
import SaleSummary from '@/components/root/SaleSummary.vue';
import DownloadManuals from '@/components/root/DownloadManuals.vue';
import QuestionSummary from '@/components/root/QuestionSummary.vue';
import ClaimSummary from '@/components/root/ClaimSummary.vue';
import DelaySummary from '@/components/root/DelaySummary.vue';
import ProductSummary from '@/components/root/ProductSummary.vue';
import ArticleList from '@/components/root/ArticleList.vue';
import { GetNoticesGodo, GodoNotice, NCPResponse } from 'ncp-api-supporter';
import CosPopup from '@/components/CosPopup.vue';
import { cosSectionCode } from '@/const/contents/cosSection';

const { VUE_APP_GODO_API_URL } = process.env;

@Component({
  components: {
    ProductSummary,
    DelaySummary,
    ClaimSummary,
    QuestionSummary,
    DownloadManuals,
    SaleSummary,
    ArticleList,
    CosPopup,
  },
  computed: {
    cosSectionCode() {
      return cosSectionCode;
    },
  },
})
export default class Home extends Vue {
  private readonly link = {
    notice: VUE_APP_GODO_API_URL + '/customer/board-list.gd?type=notice',
    upgrade:
      VUE_APP_GODO_API_URL +
      '/customer/patch-list.gd?searchYn=y&viewMode=&service=&position=&scope=&searchKey=&solution=shopby&searchVal=',
    faq: VUE_APP_GODO_API_URL + '/customer/faq-list.gd?link=gnb2_3&category=&page=1&category=shopbypro',
  };

  private noticeList: GodoNotice[] = [];
  private upgradeList: GodoNotice[] = [];
  private isMasterNotCommerceAccount = false;

  // private async fetchIsMasterNotCommerceAccount() {
  //   const { data } = await this.$api.getManagerInfo();
  //   return !data?.commerceAccount && data?.role === 'MASTER';
  // }

  created() {
    this.fetchArticleLists();
  }

  private async fetchArticleLists(): Promise<void> {
    const { data }: NCPResponse<GetNoticesGodo> = await this.$api.getNoticesGodo();
    this.setArticlesLists(data);
  }

  private setArticlesLists(data: GetNoticesGodo): void {
    const { notice, upgrade } = data;
    this.noticeList = notice;
    this.upgradeList = upgrade;
  }
}
