export default Object.freeze({
  // 정리 예정
  ONLY_EMAIL: '발송전용 e메일',
  CENTER_EMAIL: '고객센터 e메일',
  SAVE_BTN: '변경사항 저장',
  TH_1: '치환 텍스트',
  TH_2: '설명',
  TH_3: '예시',
  TH_4: '비고',
  SELECT_TEMPLATE: '톔플릿을 선택해 주세요.',
  ERR_TITLE_NULL: '메일 제목을 입력해 주세요.',
  ERR_CONTENT_NULL: '메일 템플릿을 입력해 주세요.',
  ERR_TEST: '테스트 메일 전송에 실패했습니다.',
  TEST_SUCCESS: '{0} 운영자님의 e메일 주소로 테스트 전송이 완료 되었습니다.',

  SEARCH_TITLE_TOP: '발송요청 일시',
  SEARCH_TITLE_BOTTOM: '발송요청 상태',
  MESSAGE_STATUS_ONE: '전체',
  MESSAGE_STATUS_TWO: '발송준비',
  MESSAGE_STATUS_THREE: '발송중',
  MESSAGE_STATUS_FOUR: '발송완료',
  MESSAGE_STATUS_CANCEL: '발송취소',
  MESSAGE_STATUS_FIVE: '발송실패',
  TABLE_HEADER_ONE: '요청 ID',
  TABLE_HEADER_TWO: '내용',
  POPUP_TITLE: 'e-mail 발송내용 상세보기',
  POPUP_TOP_HEADER: '요청ID',
  POPUP_BOTTOM_HEADER_ONE: '수신 메일',
  POPUP_BOTTOM_HEADER_TWO: '발송결과 상태',
  POPUP_BOTTOM_HEADER_THREE: '발송 일시',
});
