export default Object.freeze({
  // STATISTIC_MEMBER_
  TIT: '회원통계',
  TYPE: '회원유형',
  ALL_ID_MEMBER: '전체',
  MALL_ID_MEMBER: '쇼핑몰회원',
  PAYCO_ID_MEMBER: 'PAYCO 아이디회원',
  NAVER_ID_MEMBER: '네이버 아이디회원',
  KAKAO_ID_MEMBER: '카카오 아이디회원',
  FACEBOOK_ID_MEMBER: '페이스북 아이디회원',
  IAMSCHOOL_ID_MEMBER: '아이엠스쿨 아이디회원',
  LIIVMATE_ID_MEMBER: '리브메이트 아이디회원',
  NHNENT_ID_MEMBER: 'NHNENT 아이디회원',
  UNIONE_ID_MEMBER: '유니원 아이디회원',
  NCPSTORE_ID_MEMBER: 'NCP스토어 아이디회원',
  ABOUT_OPENID: 'OpenID 회원',
  INTEGRATED_MANAGEMENT_MEMBER: '통합관리회원',
  MEMBER_COUNT: '회원수',
  PERIOD_INFO: '기간별 회원현황',
  PERIOD_INFO_TOTAL: '일자별 회원 현황 종합',
  PERIOD_INFO_NOTICE_1: '회원 가입수는 회원가입일자 기준의 회원수입니다.',
  PERIOD_INFO_NOTICE_2: '휴먼 및 탈퇴회원은 휴먼전환일자 및 탈퇴일자 기준으로 회원수에서 제외됩니다.',
  PERIOD_INFO_NOTICE_3: '비율은 소수점 2자리까지 표시되며, 소수점 3자리에서 반올림됩니다.',
  SHOPPING_MALL: '쇼핑몰',
  MEMBER_GENDER: '회원성별',
  GENDER: '성별',
  MALE: '남성',
  FEMALE: '여성',
  SELECT_ALL: '전체선택',
  PLATFORM: '플랫폼',
  PLATFORM_TYPES: '플랫폼구분',
  PLATFORM_TYPE_PC: 'PC',
  PLATFORM_TYPE_MOBILE_WEB: '모바일 웹',
  PLATFORM_TYPE_MOBILE_APP: '모바일 앱',
  INQUIRY_PERIOD: '조회기간',
  PERIOD: '기간별',
  BY_PERIOD: '기간별',
  MONTHLY: '월별',
  ALL: '전체',
  MONTH: '월',
  YEAR: '년',
  CASE: '건',
  MALL: 'MALL',
  COMMON_TODAY: '오늘',
  COMMON_YESTERDAY: '어제',
  COMMON_3_DAY: '3일',
  COMMON_6_DAY: '6일',
  COMMON_1_WEEK: '1주일',
  COMMON_1_MONTH: '1개월',
  TOTAL: '합계',
  PLATFORM_CLASSIFICATION: '플랫폼 구분',
  LOOKUP: '조회',
  RESET: '초기화',
  PERIOD_MEMBER_STATUS: '기간별 회원현황 종합',
  DIVISION: '구분',
  UNCLASSIFIED: '미분류',
  ALL_ACCRUED: '전체(누적)',
  PERIOD_RATE: '기간비율(%)',
  ALL_RATE: '전체비율(%)',
  RATE_OF_TOTAL_MEMBER: '전체회원수 대비 비율(%)',
  PERIOD_RATE_OF_TOTAL_MEMBER: '전체회원수 대비 기간비율(%)',
  PERIOD_RATE_OF_TOTAL_MEMBER_TYPE: '전체유형회원수 대비 기간비율(%)',
  PERIOD_RATE_OF_TOTAL_GENDER_TYPE: '전체성별회원수 대비 기간비율(%)',
  PERIOD_RATE_OF_TOTAL_PLATFORM_TYPE: '전체플랫폼별회원수 대비 기간비율(%)',
  SMS_AGREED: 'SMS 수신동의',
  DMA_GREED: 'DM 수신동의',
  PUSH_AGREED: '푸시알림 수신동의',
  JOIN_TYPE: '가입유형',
  VERIFY_MESSAGE_GENDER: '회원성별을 선택해 주세요',
  VERIFY_MESSAGE_PLATFORM: '조회하려는 플랫폼구분을 체크해주세요.',
  VERIFY_MESSAGE_PERIOD_MAX_RANGE: '조회기간은 최대 3개월까지 가능합니다.',
  VERIFY_MESSAGE_MONTH_MAX_RANGE: '최대 5년 이전의 기간만 조회 가능합니다.',
  RESPONSE_FAIL_MESSAGE: '오류가 발생하였습니다.',
  JOIN_TYPE_COUNT_TABLE_TOOLTIP:
    '・ 간편로그인 회원 : 페이코+네이버+카카오+페이스북+구글+애플+앱카드\n・ 간편로그인 회원은 각 채널별 간편로그인 회원 수의 합계로 계산됩니다.',
  MUST_SEARCH: '회원 현황을 검색해주세요.',
});
