import { ProExcelCreateMenu } from 'ncp-api-supporter';
import { createExcel } from '@/components/common/grid/ExcelCreateUtil';
import { isEmpty } from 'underscore';

export const onCreateExcel = (tabMenu, query) => {
  const menuKeys = {
    PERIOD: 'PRO_STATISTICS_SALE_PERIOD_DAILY' as ProExcelCreateMenu,
    PRODUCT: 'PRO_STATISTICS_SALE_PRODUCT_DETAIL' as ProExcelCreateMenu,
    CATEGORY: 'PRO_STATISTICS_SALE_CATEGORY_DETAIL' as ProExcelCreateMenu,
  };

  if (isEmpty(query)) return;

  createExcel(true, menuKeys[tabMenu], query);
};

export const generateQuery = (newQuery, defaultQueryCb, propertyKey = 'mallNo') => {
  return Reflect.has(newQuery, propertyKey)
    ? newQuery
    : {
        ...defaultQueryCb(),
        ...newQuery,
      };
};
