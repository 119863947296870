



















































import { Vue, Component, Model, Prop, Watch, Ref } from 'vue-property-decorator';
import { CheckBoxGroupOption, ValueType } from '@/helpers/type';
import unsavedDetector from '@/directives/input/unsavedDetector';

@Component({
  directives: { unsavedDetector },
})
export default class CheckboxGroup extends Vue {
  /*
   * 이 컴포넌트는 프리미엄 어드민의 CompCheckBoxButton에서 유래되었습니다.
   * */
  @Prop({ required: true }) private options!: CheckBoxGroupOption<ValueType>;
  @Prop({ required: false, default: false }) private disabled!: boolean;
  @Model('change') readonly value!: string;

  private checkAll = true;
  private valueArr = [];

  @Watch('valueArr.length', { immediate: false, deep: false })
  onValueArrLengthChanged(next: number) {
    this.valueArr = this.options.data
      .filter(d => {
        if (this.valueArr.filter(v => v === d.value).length > 0) {
          return true;
        }

        return false;
      })
      .map(d => d.value);

    const length = this.options.hasAll ? this.options.data.length - 1 : this.options.data.length;
    this.checkAll = next === length;

    this.$emit('change', this.valueArr.toString(), this.checkAll);
  }

  @Watch('disabled')
  changeDisabled() {
    // disabled 는 상위 컴포넌트에서 변경될 수 있다.
    // disabled 가 변경될 경우 체크 값의 상태를 다시한번 확인하라.
    this.setValues();
  }

  private handleCheckAll() {
    if (!this.checkAll) {
      this.initValueArr();
    } else {
      this.valueArr = [];
    }
  }
  public initValueArr() {
    const arr = [];
    this.options.data.forEach((item, index: number) => {
      if (!this.options.hasAll || index !== 0) {
        arr.push(item.value as string);
      }
    });
    this.valueArr = arr;
  }
  @Ref() private readonly checkboxItems: HTMLInputElement[];
  public focus() {
    this.checkboxItems[0].focus();
  }

  private setValues() {
    const val = this.value || '';

    if (val) {
      const arr = val
        .split(',')
        .filter((item: string) => item !== '')
        .map(item => item.trim());

      this.valueArr = this.options.data
        .filter(d => {
          if (arr.filter(v => v === d.value + '').length > 0) {
            return true;
          }

          return false;
        })
        .map(d => d.value);
    } else {
      this.valueArr = [];
    }

    const length = this.options.hasAll ? this.options.data.length - 1 : this.options.data.length;
    this.checkAll = this.valueArr.length === length;
  }

  @Watch('value')
  private updateValue() {
    if (this.value !== this.valueArr.toString()) {
      this.setValues();
    }
  }

  @Watch('options.data')
  private changedData() {
    this.setValues();
  }

  mounted() {
    this.setValues();
  }
}
