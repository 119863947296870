

































import { Component } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { range1ToNI18n } from '@/utils/common';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import { keyMapJson } from '@/views/contents/product/stock/ProductStock';
import { i18nAllObject } from '@/utils/i18n';
import { readExcelFile } from '@/utils/webExcel';
import { ParsedObject } from '@/utils/fileReader';
import { NCPResponse, PutStocksExcelRequest } from 'ncp-api-supporter';
import { ExcelUploadDataType } from '@/types/productStock';
import { PutStocksExcel } from 'ncp-api-supporter/dist/types/modules/stock/stock';
import { api } from '@/api';

@Component({
  components: { FileInput, NoticeBox },
})
export default class StockExcelUpload extends WindowPopupMainVue {
  private readonly noticeList = range1ToNI18n(4, 'STOCK.MANAGEMENT.EXCEL_UPLOAD_NOTICE_');
  private uploadedExcelFileName = '';
  private extensionValidOption = {
    validType: 'excel',
    alertMsg: 'ALERT_DISALLOW_EXTENSION',
  };
  private uploadedExcelFile: File | null = null;

  private uploadExcelFile(file: File): void {
    this.uploadedExcelFileName = file.name;
    this.uploadedExcelFile = file;
  }

  private removeFile(): void {
    this.uploadedExcelFile = null;
    this.uploadedExcelFileName = '';
  }

  private async onClickSave() {
    if (!this.uploadedExcelFileName) return alert(this.$t('COMMON.EXCEL.NO_EXCEL_ERROR'));
    const columns = i18nAllObject(this, keyMapJson);
    await readExcelFile(this.uploadedExcelFile, columns, await this.convertExcelData);
  }

  private async convertExcelData(excelData: ParsedObject[]): Promise<void> {
    const isFailureRow =
      excelData.length <= 0 || excelData.filter((row, index) => index > 0 && row.stockNo === undefined).length > 0;
    if (isFailureRow) {
      return alert(this.$t('COMMON.EXCEL.NO_DATA'));
    }

    const data = excelData
      .filter((row, i) => i > 0)
      .map(row => {
        let handlerType = 'UNRESOLVED';
        if (!row.modifyStockType) {
          handlerType = 'NOT_CHANGED';
        } else if (row.modifyStockType == '1') {
          handlerType = 'ADJUST';
        } else if (row.modifyStockType == '2') {
          handlerType = 'EDIT';
        }

        const rowData = {
          mallName: row.mallName || '',
          handlerType,
          productName: row.productName || '',
          optionNameAndValue: row.optionNameAndValue || '',
          handlerTypeOriginInput: row.modifyStockType || '',
          optionManagementCode: row.optionManagementCd || '',
          extraManagementCode: row.extraManagementCd || '',
          currentStock: row.stockCnt ?? 0,
          stock: row.modifyStockCnt ?? 0,
          optionNo: row.mallOptionNo ?? 0,
          mallNo: row.mallNo ?? 0,
          productManagementCode: row.productManagementCd || '',
          productNo: row.mallProductNo ?? 0,
          stockNo: row.stockNo,
        };

        if (row.modifyStockType) {
          if (row.modifyStockCnt && isNaN(Number(row.modifyStockCnt))) {
            rowData.handlerType = 'UNRESOLVED';
            rowData.stock = 0;
          }
        }

        return rowData;
      });

    if (data.length === 0) {
      return alert(this.$t('COMMON.EXCEL.NO_DATA'));
    }

    await this.putStocksExcel(data as PutStocksExcelRequest['data']);
  }

  private async putStocksExcel(data: PutStocksExcelRequest['data']) {
    api.axios.defaults.headers.Version = '1.1';
    const excelUploadResult: ExcelUploadDataType = { isSuccess: true };
    try {
      const res: NCPResponse<PutStocksExcel> = await api.putStocksExcelJsonType({ data });
      const { failCount, stockFailNo } = res.data;
      // stockFailNo는 15자리 이상의 숫자로 이루어져 있어 string type으로 통신하기로 하였음
      if (failCount <= 0) {
        alert(this.$t('STOCK.MANAGEMENT.LAST_SAVE'));
      } else {
        excelUploadResult.isSuccess = false;
        alert(this.$t('STOCK.MANAGEMENT.EXCEL_FAIL', [failCount]));
        excelUploadResult.stockFailNo = stockFailNo;
      }
    } catch (e) {
      console.error(e);
    }
    api.axios.defaults.headers.Version = '1.0';
    this.onPositiveClick(excelUploadResult);
  }
}
