

































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { validatePassword, ValidatePasswordState } from '@/utils/validate';
import EmailInput from '@/components/common/input/EmailInput.vue';
import { namespace } from 'vuex-class';
import { Admin } from '@/types';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN, SESSION_INITIALIZE } from '@/const/cookie';
import { deleteCookie, getCookie, setCookie } from '@/utils/cookie';

const adminStore = namespace('admin');

@Component({ components: { EmailInput } })
export default class AuthPasswordChangeAfterLoginPopup extends Vue {
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;

  @Prop({ required: true })
  private name = '';
  @Prop({ default: false })
  private readonly mustBeChanged: boolean;

  private currentPassword = '';
  private newPassword = '';
  private newPasswordConfirm = '';

  private currentPasswordFailMessage = '';
  private newPasswordFailMessage = '';
  private newPasswordConfirmFailMessage = '';

  private putAdminsPassword(): Promise<boolean> {
    return this.$api
      .putAdminsPassword({
        data: {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        },
      })
      .then(response => {
        if (response && response.status === 204) {
          this.setCookie();
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private moveRoot(): void {
    this.$emit('moveRoot');
  }

  private changePasswordNextTime(): void {
    this.$api.putAdminsPasswordDate().then(({ status }) => {
      status === 204 && this.moveRoot();
    });
  }

  private changePassword(): void {
    if (!this.currentPassword) {
      alert(`${this.$t('AUTH.CURRENT_PASSWORD')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    if (this.currentPasswordFailMessage) {
      alert(this.currentPasswordFailMessage);
      return;
    }

    if (!this.newPassword) {
      alert(`${this.$t('AUTH.NEW_PASSWORD')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    if (this.newPasswordFailMessage) {
      alert(this.newPasswordFailMessage);
      return;
    }

    if (!this.newPasswordConfirm) {
      alert(`${this.$t('AUTH.NEW_PASSWORD_CONFIRM')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    if (this.newPasswordConfirmFailMessage) {
      alert(this.newPasswordConfirmFailMessage);
      return;
    }

    this.putAdminsPassword().then(success => {
      if (success) {
        this.moveRoot();
      }
    });
  }

  private validatePassword(type: string): void {
    if (type === 'current') {
      this.currentPasswordFailMessage = this.getFailMessage(this.currentPassword);
    } else if (type === 'new') {
      this.newPasswordFailMessage = this.getFailMessage(this.newPassword);
    } else if (type === 'new-confirm') {
      this.newPasswordConfirmFailMessage = this.getFailMessage(this.newPasswordConfirm);

      if (this.newPasswordConfirmFailMessage) {
        return;
      }

      if (!this.newPasswordConfirmFailMessage && this.newPassword !== this.newPasswordConfirm) {
        this.newPasswordConfirmFailMessage = this.$t('AUTH.CONFIRM_PASSWORD_DESC_2') as string;
      }
    }
  }

  private getFailMessage(password: string): string {
    const result = validatePassword(password);

    let failMessage = '';

    switch (result) {
      case ValidatePasswordState.LESS_THEN_8:
      case ValidatePasswordState.MORE_THEN_20:
      case ValidatePasswordState.LESS_THEN_10_AT_LEAST_2:
        failMessage = this.$t('ALERT_INVALID_PASSWORD_LEAST_2') as string;
        break;
      case ValidatePasswordState.MORE_THEN_10_AT_LEAST_1:
        failMessage = this.$t('ALERT_INVALID_PASSWORD_LEAST_1') as string;
        break;
      case ValidatePasswordState.NOT_ALLOW_SPECIAL:
        failMessage = this.$t('ALERT_INVALID_PASSWORD_SPECIAL') as string;
        break;
    }

    return failMessage;
  }
  private tokenInfo = {
    access: null,
    refresh: null,
  };
  private saveTokenToPreventAutoLoginWhenPasswordMustBeChanged() {
    if (!this.mustBeChanged) return;
    this.tokenInfo = {
      access: getCookie(SHOPBY_ACCESSTOKEN),
      refresh: getCookie(SHOPBY_REFRESHTOKEN),
    };
    deleteCookie(SHOPBY_ACCESSTOKEN);
    deleteCookie(SHOPBY_REFRESHTOKEN);
    deleteCookie(SESSION_INITIALIZE);
  }
  private setCookie() {
    if (!this.mustBeChanged) return;
    setCookie(SHOPBY_ACCESSTOKEN, this.tokenInfo.access);
    setCookie(SHOPBY_REFRESHTOKEN, this.tokenInfo.refresh);
  }
  private created() {
    this.saveTokenToPreventAutoLoginWhenPasswordMustBeChanged();
  }
}
