





























import { Vue, Component, Prop, PropSync, Model, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput, { TextInputValidationRegex } from '@/components/common/input/TextInput.vue';
import { OptionData } from '@/helpers/type';
const duplicateCommaReplacer = string => string.replace(/[*,.*]{2,}/g, ',');
@Component({
  components: { SelectBox, TextInput },
})
export default class SearchKeyword extends Vue {
  @PropSync('searchKeywordType') private searchKeywordTypeSync;
  @Model('input') readonly value!: string;
  @Prop({ required: false }) private readonly searchKeywordList: OptionData<string>[];
  @Prop({ required: false, default: '' }) private readonly validType: TextInputValidationRegex | '';
  @Prop({ required: false, default: true }) private readonly hasSearchType: boolean;
  @Prop({ required: false, default: '246px' }) private readonly width: string;
  @Prop({ required: false, default: true }) private readonly isTextArea: boolean;
  @Prop({ required: false, default: false }) private readonly showsCount: boolean;
  @Prop({ required: false, default: 'PRODUCT.SEARCH.PLACEHOLDER_KEYWORDS' })
  private readonly placeholder: string;
  @Prop({ required: false, default: false })
  private readonly disabled: boolean;
  @Prop({ default: false })
  private readonly needsInitValue: boolean;
  @Prop({ default: 10 })
  private readonly maxKeywordCount?: number;
  private searchKeyword = '';
  private inputSearchKeyword = '';
  public reset() {
    this.searchKeyword = '';
    this.inputSearchKeyword = '';
  }
  public getInputSearchKeyword(): string {
    return this.inputSearchKeyword ?? '';
  }
  @Watch('inputSearchKeyword')
  private onInputSearchKeyword() {
    this.$emit('input', this.searchKeyword);
  }
  private onChangeSearchKeywordType(): void {
    this.searchKeyword = '';
    this.inputSearchKeyword = '';
    this.$emit('input', this.searchKeyword);
  }
  private checkValueLength(value: string): string {
    const MAX_KEYWORD_COUNT = this.maxKeywordCount;
    const inputKeyword = duplicateCommaReplacer(value);
    const keywords = SearchKeyword.convertToKeywordList(inputKeyword);
    let strKeyword = keywords.join(',');
    if (strKeyword.startsWith(',')) {
      strKeyword = strKeyword.substring(1);
      keywords.shift();
    }
    this.searchKeyword = strKeyword;
    if (keywords.length > MAX_KEYWORD_COUNT) {
      alert(this.$t('PRODUCT.SEARCH.ALERT_KEYWORD_LIMIT_OVER', [MAX_KEYWORD_COUNT]));
      return inputKeyword.substring(0, value.length - 1);
    }
    return inputKeyword;
  }
  private valueChangedWhenBlur(value: string): string {
    this.$emit('update:keywords', this.searchKeyword);
    return value;
  }
  private static convertToKeywordList(value: string): string[] {
    const keywordsWithSpacesRemoved = value.replace(/\r\n|\n|\r/g, ',').replace(/\s/g, '');
    return duplicateCommaReplacer(keywordsWithSpacesRemoved).split(',');
  }
  @Watch('value')
  private initValue() {
    if (this.value === '') return;
    if (!this.needsInitValue) return;
    if (!this.inputSearchKeyword) {
      this.inputSearchKeyword = this.value;
    }
  }
}
