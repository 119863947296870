import {
  StatisticSummary,
  StatisticSummaryByPeriod,
  SyncIdMembersCount,
} from 'ncp-api-supporter/dist/types/modules/member/statistics';
import { percentage } from '@/utils/math';
import { MemberStatisticRowData, MemberStatisticTable, StatisticSynthesisDate, TableCategory } from '@/types';
import { mall } from '@/utils/mall';
import { addDay, getToday } from '@/utils/dateFormat';

const date = new Date();
export const today = getToday();
export const yesterday = addDay(today, -1);
export const defaultParams = () => ({
  mallNo: mall.lastCreatedMall.mallNo,
  startDate: yesterday,
  endDate: yesterday,
  joinRouteTypes: 'PC_WEB,MOBILE_WEB',
  genderTypes: '',
  memberType: '',
  memberJoinType: '',
  page: 1,
  pageSize: 30,
});

export const defaultDateInfo = (): StatisticSynthesisDate => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
});

export const getMembersByPeriodGridProps = () => ({
  header: {
    complexColumns: [
      {
        header: '성별',
        name: 'gender',
        childNames: ['maleMemberCount', 'femaleMemberCount', 'unclassifiedMemberCount'],
      },
      {
        header: '플랫폼',
        name: 'platform',
        childNames: ['pcMemberCount', 'mobileWebMemberCount'],
        // childNames: ['pcMemberCount', 'mobileWebMemberCount', 'mobileAppMemberCount'], // 오픈전까지 mobileAppMemberCount 숨김처리
      },
    ],
  },
  columns: [
    {
      header: '일자',
      name: 'date',
      align: 'center',
      minWidth: 60,
    },
    {
      header: '회원 수',
      name: 'totalMemberCount',
      align: 'center',
      minWidth: 60,
    },

    // { // 그랜드 오픈 전 숨김처리
    //   header: '쇼핑몰',
    //   name: 'mallNo',
    //   align: 'center',
    //   minWidth: 60,
    // },
    {
      header: '남성',
      name: 'maleMemberCount',
      align: 'center',
      minWidth: 60,
      formatter: ({ row }) => row.gender.maleMemberCount,
    },
    {
      header: '여성',
      name: 'femaleMemberCount',
      align: 'center',
      minWidth: 60,
      formatter: ({ row }) => row.gender.femaleMemberCount,
    },
    {
      header: '선택 안 함',
      name: 'unclassifiedMemberCount',
      align: 'center',
      minWidth: 60,
      formatter: ({ row }) => row.gender.unclassifiedMemberCount,
    },
    {
      header: 'PC웹',
      name: 'pcMemberCount',
      align: 'center',
      minWidth: 60,
      formatter: ({ row }) => row.joinRoute.pcMemberCount,
    },
    {
      header: '모바일웹',
      name: 'mobileWebMemberCount',
      align: 'center',
      minWidth: 60,
      formatter: ({ row }) => row.joinRoute.mobileWebMemberCount,
    },
    // { // 그랜드 오픈 전 숨김처리
    //   header: '모바일앱',
    //   name: 'joinRoute',
    //   align: 'right',
    //   minWidth: 60,
    //   formatter: cell => cell.value.mobileWebMemberCount,
    // },
  ],
  options: {
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },

  displayOptions: {
    showsRightArea: true,
    hasExcelDownloadButton: false,
    hasSettingButton: false,
  },
});

const getSyncIdCountSum = (syncIdMembersCount: SyncIdMembersCount) => {
  const sumTargets = ['PAYCO', 'KAKAO', 'NAVER', 'FACEBOOK'];
  return Object.keys(syncIdMembersCount).reduce(
    (sum, syncSite) => (sumTargets.includes(syncSite) ? (sum += syncIdMembersCount[syncSite]) : sum),
    0,
  );
};

// 통계값 계산 후 테이블 인터페이스에 맞게 데이터 return
export const getMemberStatisticTableData = <T extends MemberStatisticTable>(
  categoryName: TableCategory,
  statisticSummaryResponse: StatisticSummary,
  statisticSummaryByPeriodResponse: StatisticSummaryByPeriod,
): T => {
  const { totalMemberCount, memberType, gender, joinRoute } = statisticSummaryResponse; // 전체 회원 수 데이터
  const {
    totalMemberCount: searchMemberCount,
    memberType: searchMemberType,
    gender: searchGender,
    joinRoute: searchJoinRoute,
  } = statisticSummaryByPeriodResponse; // 검색 회원 수 데이터

  const tableData = {
    가입유형: {
      // 합계
      total: {
        totalMemberCount,
        totalRatio: percentage(totalMemberCount, totalMemberCount),
        searchMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(totalMemberCount, searchMemberCount),
      },

      // 일반회원
      mallMember: {
        totalMemberCount: memberType.mallMemberCount,
        totalRatio: percentage(totalMemberCount, memberType.mallMemberCount),
        searchMemberCount: searchMemberType.mallMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchMemberType.mallMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchMemberType.mallMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(
          memberType.mallMemberCount,
          searchMemberType.mallMemberCount,
        ),
      },

      // 간편로그인 회원
      syncIdMember: {
        totalMemberCount: getSyncIdCountSum(memberType.syncIdMembersCount),
        totalRatio: percentage(totalMemberCount, getSyncIdCountSum(memberType.syncIdMembersCount)),
        searchMemberCount: getSyncIdCountSum(searchMemberType.syncIdMembersCount),
        searchMemberRatio: percentage(searchMemberCount, getSyncIdCountSum(searchMemberType.syncIdMembersCount)),
        searchMemberRatioByTotalMember: percentage(
          totalMemberCount,
          getSyncIdCountSum(searchMemberType.syncIdMembersCount),
        ),
        searchMemberRatioBySpecificTotalMember: percentage(
          getSyncIdCountSum(memberType.syncIdMembersCount),
          getSyncIdCountSum(searchMemberType.syncIdMembersCount),
        ),
      },
    },

    성별: {
      // 합계
      total: {
        totalMemberCount,
        totalRatio: percentage(totalMemberCount, totalMemberCount),
        searchMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(totalMemberCount, searchMemberCount),
      },

      // 선택안함
      unclassified: {
        totalMemberCount: gender.unclassifiedMemberCount,
        totalRatio: percentage(totalMemberCount, gender.unclassifiedMemberCount),
        searchMemberCount: searchGender.unclassifiedMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchGender.unclassifiedMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchGender.unclassifiedMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(
          gender.unclassifiedMemberCount,
          searchGender.unclassifiedMemberCount,
        ),
      },

      // 남성
      male: {
        totalMemberCount: gender.maleMemberCount,
        totalRatio: percentage(totalMemberCount, gender.maleMemberCount),
        searchMemberCount: searchGender.maleMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchGender.maleMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchGender.maleMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(gender.maleMemberCount, searchGender.maleMemberCount),
      },

      // 여성
      female: {
        totalMemberCount: gender.femaleMemberCount,
        totalRatio: percentage(totalMemberCount, gender.femaleMemberCount),
        searchMemberCount: searchGender.femaleMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchGender.femaleMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchGender.femaleMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(gender.femaleMemberCount, searchGender.femaleMemberCount),
      },
    },

    플랫폼: {
      total: {
        totalMemberCount,
        totalRatio: percentage(totalMemberCount, totalMemberCount),
        searchMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(totalMemberCount, searchMemberCount),
      },
      pc: {
        totalMemberCount: joinRoute.pcMemberCount,
        totalRatio: percentage(totalMemberCount, joinRoute.pcMemberCount),
        searchMemberCount: searchJoinRoute.pcMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchJoinRoute.pcMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchJoinRoute.pcMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(joinRoute.pcMemberCount, searchJoinRoute.pcMemberCount),
      },
      mobileWeb: {
        totalMemberCount: joinRoute.mobileWebMemberCount,
        totalRatio: percentage(totalMemberCount, joinRoute.mobileWebMemberCount),
        searchMemberCount: searchJoinRoute.mobileWebMemberCount,
        searchMemberRatio: percentage(searchMemberCount, searchJoinRoute.mobileWebMemberCount),
        searchMemberRatioByTotalMember: percentage(totalMemberCount, searchJoinRoute.mobileWebMemberCount),
        searchMemberRatioBySpecificTotalMember: percentage(
          joinRoute.mobileWebMemberCount,
          searchJoinRoute.mobileWebMemberCount,
        ),
      },
    },
  };

  return tableData[categoryName] as T;
};

// 모든 테이블 데이터 0으로 초기화
export const initiateTableData = <T extends MemberStatisticTable>(categoryName: TableCategory): T => {
  const defaultRow: MemberStatisticRowData = {
    totalMemberCount: 0,
    totalRatio: '0.00',
    searchMemberCount: 0,
    searchMemberRatio: '0.00',
    searchMemberRatioByTotalMember: '0.00',
    searchMemberRatioBySpecificTotalMember: '0.00',
  };

  const defaultTableData = {
    가입유형: {
      total: null, // 합계
      mallMember: null, // 일반회원
      syncIdMember: null, // 간편로그인 회원
    },
    성별: {
      total: null, // 합계
      unclassified: null, // 선택안함
      male: null, // 남성
      female: null, // 여성
    },
    플랫폼: {
      total: null, // 합계
      pc: null, // pc웹
      mobileWeb: null, // 모바일웹
    },
  };

  Object.keys(defaultTableData[categoryName]).forEach(
    rowName => (defaultTableData[categoryName][rowName] = { ...defaultRow }),
  );

  return defaultTableData[categoryName] as T;
};
