














import { Vue, Component } from 'vue-property-decorator';
@Component
export default class MarketingThumb extends Vue {}
