











import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { NCPResponse, GetMemberGradesRequest, Grade } from 'ncp-api-supporter';
import { isEqual } from 'underscore';
import { OptionData } from '@/helpers/type';

@Component({
  components: {
    SelectBox,
  },
})
export default class MemberGradeSelect extends Vue {
  @Model('change') value!: number;
  @Prop({ default: '150px' })
  private width!: string;
  @Prop({ required: true })
  private readonly mallNo!: string;

  private selectedValue = 0;
  private readonly defaultSelectOption: OptionData<number> = {
    name: this.$t('PRODUCT.SEARCH.MEMBER_GRADE') as string,
    value: 0,
  };
  private selectOptions: OptionData<number>[] = [this.defaultSelectOption];

  created() {
    if (!this.mallNo) return;

    this.fetchMemberGrades(this.mallNo).then(() => this.checkBeforeSelection());
  }

  @Watch('mallNo')
  private async onChangeMallNo(mallNo) {
    if (mallNo) await this.fetchMemberGrades(mallNo);
    this.$nextTick(this.resetSelectedValue);
    // this.checkBeforeSelection();
  }

  private resetSelectedValue(): void {
    this.selectedValue = 0;
  }

  private alertByMallNoCondition(): void {
    if (this.mallNo) return;

    this.$emit('fail:no-mall-number');
    alert(this.$t('PRODUCT.PRODUCT.SELECT_MALL'));
  }

  private recentRequest!: GetMemberGradesRequest;
  private async fetchMemberGrades(mallNo: string): Promise<void> {
    const request: GetMemberGradesRequest = {
      params: {
        mallNo: Number(mallNo),
      },
    };
    if (this.selectOptions && isEqual(this.recentRequest, request)) return; // 중복 호출 방지

    const { data }: NCPResponse<Grade[]> = await this.$api.getMemberGrades(request);
    this.recentRequest = request;

    this.setSelectOptions(data);
  }

  private setSelectOptions(data: Grade[]): void {
    this.selectOptions = [
      this.defaultSelectOption,
      ...data
        .filter(({ used }) => used)
        .sort((c1, c2) => {
          // src/views/contents/member/basic/MemberGrade.vue
          if (!c2.used) {
            return -1;
          }
          if (!c1.used) {
            return 1;
          }

          // 기본
          if (c2.order === 0) {
            return -1;
          }
          if (c1.order === 0) {
            return 1;
          }

          return c1.order > c2.order ? 1 : -1;
        })
        .map(({ no, name }) => ({ value: no, name: name })),
    ];
  }

  private checkBeforeSelection(): void {
    if (!this.value) return;
    this.selectedValue = this.value;
  }
}
