export default Object.freeze({
  // SEARCH KEY WORD
  SEARCH_KEY_WORD_USER_ID: '아이디',
  SEARCH_KEY_WORD_NAME: '회원명',
  SEARCH_KEY_WORD_NICKNAME: '닉네임',
  SEARCH_KEY_WORD_EMAIL: 'e메일주소',
  SEARCH_KEY_WORD_MOBILE: '휴대폰번호',

  // SEARCH DATE
  SEARCH_DATE_ALL: '전체조회',
  SEARCH_DATE_PEROID: '기간선택',

  LIST_ITEM_MEMBER_NO: '회원번호',
  LIST_ITEM_MEMBER_NAME: '회원명',
  LIST_ITEM_MEMBER_ID: '아이디',
  LIST_ITEM_MEMBER_NICKNAME: '닉네임',
  LIST_ITEM_MEMBER_GRADE: '회원등급',
});
