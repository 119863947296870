




























































































































































import { Component } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import country from '@/const/words/country';
import { PopupClose, throwPopup } from '@/helpers/popup';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { Warehouse } from 'ncp-api-supporter';

@Component({
  components: { TextInput, SelectBox },
})
export default class WarehouseFormPopup extends WindowPopupMainVue {
  private query = {
    defaultReleaseWarehouse: false,
    usesSubstitutionText: false, // 대체문구 사용여부
    address: {
      overseaRegion: '',
      overseaCity: '',
      address: '',
      countryCd: 'KR',
      jibunAddress: '',
      detailAddress: '',
      zipCd: '',
      overseaAddress2: '',
      overseaAddress1: '',
    },
    name: '', // 입출고 주소명
    substitutionText: '', // 대체문구
    defaultReturnWarehouse: false,
  };

  private country = country;

  private isEdit(): boolean {
    return this.data.type === 'edit';
  }

  private isKr(): boolean {
    return this.query.address.countryCd === 'KR';
  }

  created() {
    const { warehouse } = this.data;

    if (this.isEdit()) {
      this.query.name = warehouse.name;
      this.query.defaultReleaseWarehouse = warehouse.defaultReleaseWarehouse;
      this.query.defaultReturnWarehouse = warehouse.defaultReturnWarehouse;
      this.query.usesSubstitutionText = !!warehouse.substitutionText;

      if (this.query.usesSubstitutionText) {
        this.query.substitutionText = warehouse.substitutionText;
      } else {
        this.query.address.countryCd = warehouse.address.countryCd;
        this.query.address.zipCd = warehouse.address.zipCd;

        if (this.isKr()) {
          this.query.address.address = warehouse.address.address;
          this.query.address.detailAddress = warehouse.address.detailAddress;
          this.query.address.jibunAddress = warehouse.address.jibunAddress;
        } else {
          this.query.address.overseaAddress1 = warehouse.address.overseaAddress1;
          this.query.address.overseaAddress2 = warehouse.address.overseaAddress2;
          this.query.address.overseaCity = warehouse.address.overseaCity;
          this.query.address.overseaRegion = warehouse.address.overseaRegion;
        }
      }
    }
  }

  private getRequestData() {
    const warehouseNo = this.isEdit() ? this.data.warehouse.warehouseNo : '';
    const request = {
      params: {
        warehouseNo,
      },
      data: { ...this.query, address: { ...this.query.address } },
    };

    if (request.data.usesSubstitutionText) {
      delete request.data.address;
    } else {
      delete request.data.substitutionText;

      if (this.isKr()) {
        delete request.data.address.overseaAddress1;
        delete request.data.address.overseaAddress2;
        delete request.data.address.overseaCity;
        delete request.data.address.overseaRegion;
      } else {
        delete request.data.address.address;
        delete request.data.address.jibunAddress;
        delete request.data.address.detailAddress;
      }
    }

    return request;
  }

  private postWarehouses(): Promise<Warehouse | boolean> {
    return this.$api
      .postWarehouses(this.getRequestData())
      .then(response => {
        if (response && response.status === 201) {
          return response.data;
        }

        return false;
      })
      .catch(() => false);
  }

  private putWarehousesByWarehouseNo(): Promise<boolean> {
    return this.$api
      .putWarehousesByWarehouseNo(this.getRequestData())
      .then(response => {
        if (response && response.status === 204) {
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private validate(): boolean {
    if (!this.query.name) {
      alert(this.getText('WAREHOUSE_ADD_ALERT_1'));
      (this.$refs.name as HTMLElement).focus();
      return false;
    }

    if (!this.query.usesSubstitutionText) {
      let addressMessage = '';

      if (this.isKr()) {
        if (!this.query.address.zipCd || !this.query.address.detailAddress) {
          (this.$refs.detailAddress as HTMLElement).focus();
          addressMessage = 'WAREHOUSE_ADD_ALERT_2';
        }
      } else {
        if (!this.query.address.overseaAddress1) {
          (this.$refs.overseaAddress1 as HTMLElement).focus();
          addressMessage = 'WAREHOUSE_ADD_ALERT_3';
        } else if (!this.query.address.overseaAddress2) {
          (this.$refs.overseaAddress2 as HTMLElement).focus();
          addressMessage = 'WAREHOUSE_ADD_ALERT_4';
        } else if (!this.query.address.zipCd) {
          (this.$refs.zipCd as HTMLElement).focus();
          addressMessage = 'WAREHOUSE_ADD_ALERT_5';
        } else if (!this.query.address.overseaCity) {
          (this.$refs.overseaCity as HTMLElement).focus();
          addressMessage = 'WAREHOUSE_ADD_ALERT_6';
        } else if (!this.query.address.overseaRegion) {
          (this.$refs.overseaRegion as HTMLElement).focus();
          addressMessage = 'WAREHOUSE_ADD_ALERT_7';
        }
      }

      if (addressMessage) {
        alert(this.getText(addressMessage));
        return;
      }
    } else {
      if (!this.query.substitutionText) {
        alert(this.getText('WAREHOUSE_ADD_ALERT_8'));
        (this.$refs.substitutionText as HTMLElement).focus();
        return false;
      }
    }

    return true;
  }

  private save(): void {
    if (!this.validate()) {
      return;
    }

    if (this.isEdit()) {
      this.putWarehousesByWarehouseNo().then(success => {
        if (success) {
          alert(this.getText('WAREHOUSE_ADD_SUCCESS'));
          this.onPositiveClick({ success });
        }
      });
    } else {
      this.postWarehouses().then(result => {
        if (!result) return;
        alert(this.getText('WAREHOUSE_ADD_SUCCESS'));
        this.onPositiveClick(result as Warehouse);
      });
    }
  }

  private openSearchAddressPopup() {
    throwPopup({
      name: 'SearchAddress',
      data: { keyword: '' },
    }).then(result => {
      if (result && result.state === PopupClose.CONFIRM) {
        const data = result.data;

        this.query.address.zipCd = data.zipCode;
        this.query.address.address = data.roadAddress;
        this.query.address.detailAddress = data.roadAddressExtra;
        this.query.address.jibunAddress = data.jibunAddress;
      }
    });
  }

  private getText(key: string): TranslateResult {
    return this.$t(`CONFIGURATION.DELIVERY.${key}`);
  }
}
