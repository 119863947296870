
























import { Vue, Component } from 'vue-property-decorator';
import throttle from 'lodash.throttle';
import MallNoticePopup from '@/components/popup/root/MallNoticePopup.vue';
import ShopByGuideButton from '@/components/root/parts/ShopByGuideButton.vue';
import ChatBot from '@/components/chatBot/ChatBot.vue';

type ScrollPosition = 'top' | 'middle' | 'bottom';
type ViewModal = 'NOTICE' | 'GUIDE' | 'CHATBOT';

const getScrollPosition = (x: number, scrollableHeight = 0): ScrollPosition => {
  if (x === 0) return 'top';
  if (x >= scrollableHeight) return 'bottom';
  return 'middle';
};
@Component({
  components: { MallNoticePopup, ShopByGuideButton, ChatBot },
})
export default class FloatingButton extends Vue {
  created() {
    window.addEventListener('scroll', this.throttleScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.throttleScroll);
  }

  private viewButton = true;
  private viewModal: ViewModal | null = null;

  private handleShow(target: ViewModal | null) {
    this.viewModal = this.viewModal === target ? null : target;
  }

  private throttleScroll = throttle(this.onScroll, 250);
  private scrollPosition: ScrollPosition = 'top';
  private onScroll() {
    const scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    this.scrollPosition = getScrollPosition(window.scrollY, scrollableHeight);
  }

  private bottom = document.body.scrollHeight;
  private scrollTo(y: number) {
    window.scrollTo(window.scrollX, y);
  }

  private handleViewChange() {
    this.viewButton = !this.viewButton;
    !this.viewButton && this.handleShow(null);
  }
}
