







import { Vue, Component } from 'vue-property-decorator';
import MallSelector from '@/components/MallSelector.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { i18nPaycoSetting } from '@/const/contents/configuration/paycoSetting';

@Component({ components: { MallSelector, NoticeBox } })
export default class PaycoSetting extends Vue {
  private noticeList = [i18nPaycoSetting('NOTICE')];
}
