export default Object.freeze({
  TAB_DELIVERY: '배송번호 기준 리스트',
  TAB_ORDER: '주문번호 기준 리스트',
  BTN_CHANGE_STATUS: '출고 처리',
  BTN_INVOICE: '송장번호 저장',
  BTN_DELIVERY_BY_SCAN: '스캐닝 출고 처리',
  BTN_COMBINE_DELIVERY: '묶음배송일괄처리',
  BTN_CANNCEL_COMBINE_DELIVEYR: '묶음배송 해제',
  BTN_INVOICE_FORMAGE: '양식설정',
  BTN_SPRINT_SPECIFICATION: '거래명세서 출력',
  BTN_DO_HOLD_DELIVEYR: '배송보류 처리',
  CHOICE_ORDER_PRODUCT_ALERT: '주문상품을 선택해 주세요.',
  CANCEL_COMBINE_CONFIRM: '묶음배송 해제하시겠습니까?',

  COMPANY_SELECT_ALL: '택배사선택',
  INVOICE_FORMAT_TITLE: '택배사 출고양식 설정',
  CONCEL_COMBINE_SUCCESS_ALERT: '묶음배송 해제 처리가 완료되었습니다.',
  OUT_LIMIT_COUNT: '거래명세서는 {limit}건까지 출력이 가능합니다. 건수 확인 후 진행해 주세요.',
  NOT_USEFULL_EXCEL: '다운로드 받은 송장업로드용 엑셀에서 송장번호만 기입/수정 후 업로드 해주세요',
  EXCEL_TIME_FORM_ERROR:
    '다운로드 받은 송장업로드용 엑셀의 주문일시 형식을 YYYY-MM-DD HH:MM:SS로 수정 후 업로드 해주세요',
  EXCEL_FILE_NAME_EMPTY_ALERT: '업로드 할 파일을 선택해 주세요!',
  EXCEL_FILE_NAME_INCLUDES_XLS_ALERT: '올바르지 않은 파일입니다',
  EXCEL_DELIVERY_COMPANY_TYPE_EMPTY_ALERT: '배송업체를 지정해주세요',
  CONFIRM_UPLOAD_EXCEL: '선택한 파일을 업로드 하시겠습니까?',
  BTN_UPLOADING_TXT: '업로드중',
  BTN_PRODUCT_PREPARE: '상품준비중 복원',
  BTN_DELAY_PRODUCT_NO_BY_NAVER: '네이버페이 발송지연 처리',
  IS_PARCEL_DELIVERY_ALERT: '송장할당 불가능한 배송주문이 포함되어 있습니다.',
  DO_DELIVERY_CONFIRM: '송장번호를 할당하시겠습니까?',
  CONTENT_TOP: '배송준비중 주문',
  TABLE_DELIVERY_NO_TITEL: '조회결과 배송 건수',
  TABLE_ORDER_NO_TITEL: '조회결과 주문 건수',

  NAVER_PAY_ORDER_PROCESS_ALERT: '네이버페이 주문형 주문은 네이버페이 센터를 통해 처리해 주세요.',
  NAVER_PAY_ORDER_PROCESS_IMPOSSIBLE_ALERT: '네이버페이 주문형 주문은 처리 불가합니다.',
});
