


































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { textOptionOptions } from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import { CustomerDemands } from 'ncp-api-supporter';

@Component({
  components: {
    SelectBox,
    TextInput,
  },
})
export default class TextOption extends Vue {
  @Prop({ required: false, default: false }) private readonly isReadonly;
  @PropSync('optionCnt', { required: true }) private optionCntSync!: number;
  @PropSync('textOptionList', { required: true }) private textOptionListSync!: CustomerDemands[];

  private selectOptions = textOptionOptions;
  private defaultOption: CustomerDemands = {
    inputText: '',
    useYn: 'Y',
    matchingType: 'OPTION',
    inputNo: 0,
    required: false,
  };

  @Watch('optionCntSync')
  private setOptionList() {
    const optionListLength = this.textOptionListSync.length;
    const differenceValue = Math.abs(optionListLength - this.optionCntSync);

    if (this.textOptionListSync.length > this.optionCntSync) {
      this.textOptionListSync.splice(-differenceValue);
    } else {
      for (let i = 0; i < differenceValue; i++) {
        this.pushOptionList();
      }
    }
  }

  created() {
    this.initOptionList();
  }

  private initOptionList(): void {
    if (this.textOptionListSync.length) return;
    this.textOptionListSync = Array(this.optionCntSync).fill({ ...this.defaultOption });
  }

  private pushOptionList(): void {
    this.textOptionListSync.push({ ...this.defaultOption });
  }

  private deleteOption(index): void {
    this.textOptionListSync.splice(index, 1);
    this.optionCntSync--;
  }
}
