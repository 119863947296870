






























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetSmsGodoCallingNumbers } from 'ncp-api-supporter';
import { throwExternalWindowPopup } from '@/helpers/popup';
import { SelectedCallingNumberInfo } from '@/types/member';
const { VUE_APP_GODO_API_URL } = process.env;

@Component
export default class SmsConfigNumberList extends Vue {
  @Prop() private onNegativeClick!: () => void;
  @Prop() private onPositiveClick!: (selectedCallingInfo: SelectedCallingNumberInfo) => void;
  @Prop() private data!: { callingNumberList: GetSmsGodoCallingNumbers[] };

  private selectedNumber = '';

  private registerCallingNumber() {
    throwExternalWindowPopup(`${VUE_APP_GODO_API_URL}/mygodo/sms/intro.gd`, 'xlg');
  }

  private selectCallingNumber() {
    if (!this.selectedNumber) {
      alert(this.$t('MEMBER.SMS.ALERT_SELECT_SEND_NO'));
      return;
    }

    const approveTime = this.data.callingNumberList.find(({ callback }) => callback === this.selectedNumber).apvdtime;
    this.onPositiveClick({ selectedNumber: this.selectedNumber, approveTime });
  }
}
