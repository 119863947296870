



















import { Vue, Component, Prop, Model, PropSync, Ref } from 'vue-property-decorator';
// components
import RadioGroup from '@/components/common/RadioGroup.vue';
// const
import { entryContractOptions } from '@/const/contents/partner';
// functions
import { throwWindowPopupWithProps, PopupResult } from '@/helpers/popup';

@Component({
  components: {
    RadioGroup,
  },
})
export default class NewPartnerEntryContractYn extends Vue {
  @Model('set-entry-contract')
  private entryContractContent = '';

  @Prop({ required: true })
  private readonly selectedMallNo!: string;

  @PropSync('useEntryContract')
  private useEntryContractSync!: boolean;

  @Ref()
  private readonly buttonRef: HTMLButtonElement;

  private readonly entryContractYnOptions = entryContractOptions;

  private handleClosePopup({ data }: PopupResult) {
    if (data) {
      this.$emit('set-entry-contract', data.content);
    }
  }

  private async openEntryContractPopup() {
    throwWindowPopupWithProps({
      componentName: 'EntryContractFilePopup',
      data: { content: this.entryContractContent },
      size: 'xlg',
      onClose: this.handleClosePopup,
      popupHeight: 700,
    });
  }

  public focus() {
    this.buttonRef.focus();
  }
}
