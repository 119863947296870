






















































import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  DomainByMall,
  GetOrderConfigsPaymentsPaycoRequest,
  NCPResponse,
  PaymentKeysPayco,
  PayType,
  PostOrderConfigsPaymentKeysShopByProRenewPGConfigsRequest,
  RenewPGConfigs,
} from 'ncp-api-supporter';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { getCurrentMallNo } from '@/utils/mall';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import {
  getCheckboxOption,
  getDefaultPaycoSetting,
  i18nPaycoSetting,
  renewInfo,
} from '@/const/contents/configuration/paycoSetting';
import { payTypes } from '@/const/order';
import { Getter } from 'vuex-class';
import { applyUrl } from '@/components/addition/GoTo';

@Component({ components: { NoticeBox, CheckboxGroup } })
export default class PaycoSetting extends Vue {
  @Getter('mall/getMallDomains') private readonly mallDomains!: DomainByMall[];
  private checkBoxOption = getCheckboxOption();
  private isPayco = false;
  private paycoSetting: PaymentKeysPayco = getDefaultPaycoSetting();
  private enablePayTypes = '';
  private i18nPaycoSetting = i18nPaycoSetting;
  private payTypes: PayType[] = [
    payTypes.CREDIT_CARD,
    payTypes.REALTIME_ACCOUNT_TRANSFER,
    payTypes.VIRTUAL_ACCOUNT,
    payTypes.PAYCO,
  ];

  private getApplyUrl(): string {
    const currentMallNo = getCurrentMallNo(this);
    const currentMallDomain = this.mallDomains.filter(mall => mall.mallNo === currentMallNo)[0];
    return applyUrl['PG_PAYCO'] + '&mall_id=' + currentMallDomain.mallId;
  }

  private get mallNo(): string {
    return getCurrentMallNo(this).toString();
  }

  private getSuffixAutoText(text: string): string {
    if (!text) {
      return '';
    }

    return `${text} (${i18nPaycoSetting('AUTO_SETTING_COMPLETE')})`;
  }

  @Watch('$route')
  private initPaycoSetting() {
    this.getOrderConfigsPaymentKeysPayco();
  }

  created() {
    this.initPaycoSetting();
  }

  //페이코 결제수단 및 설정
  private async getOrderConfigsPaymentKeysPayco() {
    const request: GetOrderConfigsPaymentsPaycoRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    try {
      const { data }: NCPResponse<PaymentKeysPayco> = await this.$api.getOrderConfigsPaymentsPayco(request);
      this.paycoSetting = data;
      this.isPayco = true;
      this.checkEnablePayTypes();
    } catch (e) {
      this.isPayco = false;
    }
  }

  private checkEnablePayTypes() {
    const enablePayTypes = [];
    this.paycoSetting.enablePayTypes.forEach(enablePayType => {
      const isEnablePayTypes = this.payTypes.includes(enablePayType);
      isEnablePayTypes && enablePayTypes.push(enablePayType);
    });
    this.enablePayTypes = enablePayTypes.join(',');
  }

  private async renewPGSetting() {
    const isSuccess = await this.postOrderConfigsPaymentKeysShopByProRenewPGConfigs();
    if (!isSuccess) return;
    await renewInfo(this);
  }

  //PG 설정 동기화를 요청
  private async postOrderConfigsPaymentKeysShopByProRenewPGConfigs(): Promise<boolean> {
    const request: PostOrderConfigsPaymentKeysShopByProRenewPGConfigsRequest = {
      data: {
        mallNo: Number(getCurrentMallNo(this)),
        pgType: 'PAYCO',
      },
    };

    try {
      const response: NCPResponse<RenewPGConfigs> = await this.$api.postOrderConfigsPaymentKeysShopByProRenewPGConfigs(
        request,
      );
      return response && response.status === 200 && response.data && response.data.success;
    } catch (e) {
      return false;
    }
  }
}
