// text helper
import { i18n } from '@/main';
// types
import tuiGrid, { RowKey } from '@/types/tui-grid';
import { CellRenderer } from '@/types/tui-grid/renderer';
import {
  AnchorTagInfo,
  RendererMapByContractStatus,
  PartnerRendererProps,
} from '@/views/contents/partner/modification/PartnerModificationTypes';
// get html
import {
  getGridAnchor,
  getGridAnchorGroup,
  getGridTextSpan,
  getGridTextSpanAndAnchor,
} from '@/views/contents/partner/modification/grid/gridHTMLs';
// popups
import {
  getEntryContractFilePopupReadOnly,
  getEntryContractFilePopupRegistration,
  getEntryContractFilePopupModification,
  getEntryContractFilePopupCancel,
} from '@/views/contents/partner/modification/popup/PartnerModificationPopup';
// cellReplacer
import { replaceCellByEntryContracts } from '@/views/contents/partner/modification/grid/replaceCell';
import { Contracts } from 'ncp-api-supporter';

// *** renderers ***
export const renderText = (text: string) => {
  return {
    getElement(): HTMLElement {
      return getGridTextSpan(text);
    },
    render() {
      return;
    },
  };
};

const renderEntryContractPopupOpenText = (grid: tuiGrid, rowKey: RowKey) => {
  const openEntryContractPopupText = i18n.t('PARTNER.EDIT.STANDING_POINT_CONTRACT_POPUP').toString();
  const contractNo = grid.getValue<Contracts, 'contractNo'>(rowKey, 'contractNo');
  return {
    getElement(): HTMLElement {
      return getGridAnchor({
        anchorText: openEntryContractPopupText,
        handleClick: getEntryContractFilePopupReadOnly(contractNo),
      });
    },

    render() {
      return;
    },
  };
};

export const renderEntryContractRegistrationText = (grid: tuiGrid, rowKey: RowKey) => {
  // 거래상태: 거래중, 입점계약서: X => 입점계약서 없이 거래중인 경우.
  const contractNo = grid.getValue<Contracts, 'contractNo'>(rowKey, 'contractNo');
  const unUseText = i18n.t('PARTNER.EDIT.NO_USE').toString();
  const registrationEntryContractAnchor = `[${i18n.t('PARTNER.EDIT.STANDING_POINT_CONTRACT_ADD')}]`;

  return {
    getElement(): HTMLElement {
      return getGridTextSpanAndAnchor(
        unUseText,
        registrationEntryContractAnchor,
        getEntryContractFilePopupRegistration(contractNo, grid, rowKey),
      );
    },

    render() {
      replaceCellByEntryContracts(grid, rowKey);
      return;
    },
  };
};

export const renderEntryContractModificationText = (grid: tuiGrid, rowKey: RowKey) => {
  // 거래상태 거래중, 입점계약서: O, 계약서 동의: O => 파트너 최초등록시 BPA가 입점계약서에 동의후 거래중인 경우.
  const contractNo = grid.getValue<Contracts, 'contractNo'>(rowKey, 'contractNo');

  const openEntryContract: AnchorTagInfo = {
    anchorText: `[${i18n.t('PARTNER.EDIT.STANDING_POINT_CONTRACT_POPUP')}]`,
    handleClick: getEntryContractFilePopupReadOnly(contractNo),
  };
  const modificationEntryContract: AnchorTagInfo = {
    anchorText: `[${i18n.t('PARTNER.EDIT.STANDING_POINT_CONTRACT_CHANGE')}]`,
    handleClick: getEntryContractFilePopupModification(contractNo, grid, rowKey),
  };

  return {
    getElement(): HTMLElement {
      return getGridAnchorGroup([openEntryContract, modificationEntryContract]);
    },

    render() {
      replaceCellByEntryContracts(grid, rowKey);
      return;
    },
  };
};

export const renderEntryContractModificationCancelText = (grid: tuiGrid, rowKey: RowKey) => {
  // 거래상태: 거래중, 기존 입점계약서 동의: O, 신규 입점계약서 동의: X => 기존에 사용하던 입점계약서를 변경하는경우. 이때 BPA가 신규 입점계약서에 동의전까지가 이 경우에 해당함.
  const contractNo = grid.getValue<Contracts, 'contractNo'>(rowKey, 'contractNo');

  const openEntryContract: AnchorTagInfo = {
    anchorText: `[${i18n.t('PARTNER.EDIT.STANDING_POINT_CONTRACT_POPUP')}]`,
    handleClick: getEntryContractFilePopupReadOnly(contractNo),
  };
  const cancelEntryContract: AnchorTagInfo = {
    anchorText: `[${i18n.t('PARTNER.EDIT.STANDING_POINT_CONTRACT_CHANGE_CANCEL')}]`,
    handleClick: getEntryContractFilePopupCancel(contractNo, grid, rowKey),
  };

  return {
    getElement(): HTMLElement {
      return getGridAnchorGroup([openEntryContract, cancelEntryContract]);
    },

    render() {
      replaceCellByEntryContracts(grid, rowKey);
      return;
    },
  };
};

export const renderEntryContractRegistrationCancelText = (grid: tuiGrid, rowKey: RowKey) => {
  // 거래상태: 거래중, 입점계약서: O, 계약서 동의: X => 입점계약서 없이 거래중이였으나, 그 후 SA가 입점계약서를 등록하려는 경우. 이때 BPA가 입점계약서 확인후 동의전까지가 이 경우에 해당함.
  const contractNo = grid.getValue<Contracts, 'contractNo'>(rowKey, 'contractNo');

  const unUseText = i18n.t('PARTNER.EDIT.NO_USE').toString();
  const cancelEntryContractAnchor = `[${i18n.t('PARTNER.EDIT.STANDING_POINT_CONTRACT_CANCEL')}]`;
  const openCancelPopup = getEntryContractFilePopupCancel(contractNo, grid, rowKey);

  return {
    getElement(): HTMLElement {
      return getGridTextSpanAndAnchor(unUseText, cancelEntryContractAnchor, openCancelPopup);
    },

    render() {
      replaceCellByEntryContracts(grid, rowKey);
      return;
    },
  };
};

// *** entryContractRendererByContractStatus ***
export default (props: PartnerRendererProps<'latestEntryContractAgreed'>): CellRenderer => {
  const contractStatus = props.grid.getValue<Contracts, 'contractStatus'>(props.rowKey, 'contractStatus');

  const rendererMap: RendererMapByContractStatus<'latestEntryContractAgreed'> = {
    WAITING() {
      return renderText('-');
    },

    INVESTIGATION({ grid, rowKey, value: latestEntryContractAgreed }) {
      // [입점계약서 X] => '사용안함'
      if (!latestEntryContractAgreed) return renderText(i18n.t('PARTNER.EDIT.NO_USE').toString());

      // [입점계약서 O + 계약서 동의 O] => '[계약내용보기]'
      return renderEntryContractPopupOpenText(grid, rowKey);
    },

    ACTIVE({ grid, rowKey, value: latestEntryContractAgreed }) {
      const entryContractSize = grid.getValue<Contracts, 'entryContractSize'>(rowKey, 'entryContractSize');

      // [입점계약서 X] : 입점계약서 없이 거래중인 경우. => '사용안함' / '[계약등록]'
      if (!entryContractSize) return renderEntryContractRegistrationText(grid, rowKey);

      // [입점계약서 O + 계약서 동의 O] : 파트너 최초등록시 BPA가 입점계약서에 동의후 거래중인 경우. => '[계약내용보기]' / '[계약내용변경]'
      if (latestEntryContractAgreed) return renderEntryContractModificationText(grid, rowKey);

      // [기존 입점계약서 동의 O + 신규 입점계약서 동의 X] : 기존에 사용하던 입점계약서를 변경하는경우. 이때 BPA가 신규 입점계약서에 동의전까지가 이 경우에 해당함. => '[계약내용보기]' / '[계약내용변경취소]'
      if (entryContractSize >= 2) return renderEntryContractModificationCancelText(grid, rowKey);

      // [입점계약서 O + 계약서 동의 X] : 입점계약서 없이 거래중이였으나, 그 후 SA가 입점계약서를 등록하려는 경우. 이때 BPA가 입점계약서 확인후 동의전까지가 이 경우에 해당함. => '사용안함' / '[계약등록취소]'
      if (!latestEntryContractAgreed) return renderEntryContractRegistrationCancelText(grid, rowKey);
    },
  };
  return rendererMap[contractStatus](props);
};
