








// DUTY: 과세, DUTYFREE: 면세, SMALL: 영세
type ValueAddedTaxType = 'DUTY' | 'DUTYFREE' | 'SMALL';

import SelectBox from '@/components/common/SelectBox.vue';
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
@Component({
  components: { SelectBox },
})
export default class ValueAddedTaxInfo extends Vue {
  @PropSync('valueAddedTaxType')
  private valueAddedTaxTypeSync!: ValueAddedTaxType;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private selectOptions: OptionData<ValueAddedTaxType>[] = [
    { name: 'PRODUCT.ADD.DUTY', value: 'DUTY' },
    { name: 'PRODUCT.ADD.DUTYFREE', value: 'DUTYFREE' },
    { name: 'PRODUCT.ADD.SMALL', value: 'SMALL' },
  ];
}
