export default Object.freeze({
  // article list
  ENTER_SEARCH_WORD: '검색어를 입력하세요.',
  FAIL_TO_GET_BOARD_INFO: '게시글 정보를 가져오는데 실패하였습니다.',
  SELECT_ARTICLE: '게시글을 선택해 주세요.',
  CONFIRM_DELETE_ARTICLE: '선택한 게시글을 삭제하시겠습니까? 삭제한 게시글은 복원되지 않습니다.',
  COMPLETE_DELETE: '게시글이 삭제되었습니다.',
  MULTI_SELECT: '하나의 게시글만 선택해 주세요.',
  REPLY_PERMISSION: '답글 등록이 불가한 게시글입니다.',
  FAIL_TO_FETCH_DATA: '데이터가 존재하지 않습니다.',
  REPLY_DEPTH: '답글의 답글은 작성할 수 없습니다.',
  SELECT_REPLY_ARTICLE: '답글을 선택해주세요.',

  TITLE: '게시글 관리',
  SHOPPING_MALL: '쇼핑몰',
  BTN_SEARCH: '조회',
  BTN_RESET: '초기화',
  NOTICE_BOARD: '게시판',
  FORM_THREE: '게시판 카테고리',
  SEARCH_WORD: '검색어',
  SEARCH_TYPE_ALL: '통합검색', //전체
  SEARCH_TYPE_TITLE: '제목',
  SEARCH_TYPE_CONTENT: '내용',
  SEARCH_TYPE_WRITER: '작성자',
  PLACEHOLDER: '검색어 입력',
  NOTICE: '공지',

  CREATE_DATE: '작성일시',
  SEARCH_RESULT: '조회결과',
  BTN_DELETE: '게시글 삭제',
  BTN_MOVE: '게시글 이동',
  BTN_ADD: '신규 게시글 등록',
  BTN_ADD_REPLY: '답글 등록',
  BTN_DELETE_REPLY: '답글 삭제',
  BTN_CREATE_EXCEL: '생성',
  BTN_MAIN_EXCEL: '상위 게시글만',
  BTN_INCLUDE_REPLY_EXCEL: '답글 포함',

  // grid of article list.
  HEADER_MALLNAME: '쇼핑몰',
  HEADER_NO: '게시글 번호',
  HEADER_REPLY_NO: '답글번호',
  HEADER_BOARD: '게시판',
  HEADER_TITLE: '제목',
  HEADER_REPLY: '답글',
  CONTENT_REPLY: '답글',
  HEADER_ISREPLY: '답글여부',
  HEADER_REPLY_COUNT: '답글수',
  HEADER_OPENED: '공개여부',
  HEADER_SECRETED: '비밀글',
  HEADER_READ_COUNT: '조회수',
  HEADER_WRITER: '작성자',
  HEADER_CREATION_DATE_TIME: '작성일시',
  HEADER_EDITOR: '최종수정자',
  HEADER_MODIFICATION_DATE_TIME: '최종수정일시',
  HEADER_PARENT_BOARD_ARTICLE: '답글여부',

  NONMEMBER: '비회원',
  INVALID_USER: '잘못된 사용자',
  EXIT_MEMBER: '탈퇴회원',
  SELECT_ONE: '게시판 선택',

  // reply view popup page.
  REPLY_VIEW_TITLE: '답글 조회',
  ARTICLE_TITLE: '제목',
  MALL_BOARD_NAME: '쇼핑몰명/게시판명',
  ARTICLE_OPENED: '공개여부',
  ARTICLE_PUBLIC: '공개',
  ARTICLE_PRIVATE: '비공개',
  ARTICLE_DELETE: '삭제',
  ARTICLE_NOTICE_SECRETED: '공지/비밀글여부',
  ARTICLE_WRITER: '작성자',
  ARTICLE_CREATE_DATE: '작성일자',
  ARTICLE_REPLY_NO: '작성자',
  ARTICLE_READ_COUNT: '조회수',
  ARTICLE_NO: '게시글번호',

  // article moved page.
  MOVED_TITLE: '게시글 이동',
  ARTICLE_NAME: '변경 대상 게시글',
  MOVED_TO_BOARD: '게시판 선택',
  TH_THREE1: '게시판 카테고리',
  BTN_APPLY: '적용',
  BTN_CANCEL: '취소',
  BOARD_REQUIRED: '게시판을 선택해 주세요',
  ALERT_TWO: '카테고리를 선택해 주세요',
  CATEGORY_NONE: '게시판 카테고리 선택',

  // article detail
  TITLE_CREATE: '게시글 등록',
  TITLE_UPDATE: '게시글 수정',
  TITLE_REPLY: '답글 등록 ',
  //ARTICLE_TITLE: '제목',
  ARTICLE_TITLE_PLACEHOLDER: '게시글 제목 등록',
  //ARTICLE_OPENED: '공개여부',
  OPENED_TRUE: '공개',
  OPENED_FALSE: '비공개',
  MALL_BOARD: '쇼핑몰/게시판',
  NOTICE_SECRETED: '공지/비밀글',
  TH_FOUR: '게시판 카테고리',
  TH_FIVE: '대표이미지',
  TH_FIVE_SPAN: '대표 이미지를 추가해 주세요.',
  TH_FIVE_SPAN_TWO: '이미지 찾기',
  //NOTICE: '공지',
  //ORDER_FIXED: '순서고정',
  SECRETED: '비밀글',
  PARENT_ARTICLE: '상위게시글',
  ATTACHED: '첨부파일 (최대10개)',
  BTN_ADD_FILE: '파일찾기',
  BTN_SAVE: '게시글 등록',
  BTN_REPLY: '답글 작성',
  //BTN_CANCEL: '취소',
  ALERT_BOARD_DISABLED: '쇼핑몰에 등록된 게시판이 없습니다. 게시판을 먼저 등록해 주세요.',
  ALERT_BOARD_NO_SELECT: '게시판을 선택해주세요.',
  ALERT_MALL_NO_SELECT: '쇼핑몰을 선택해주세요.',
  ALERT_CATEGORY_DISABLED: '게시판 카테고리를 선택해주세요.',
  ALERT_ARTICLE_TITLE: '제목을 입력해 주세요.',
  ALERT_SUMMERNOTE_EMPTY: '내용을 입력해 주시기 바랍니다.',
  ALERT_SUMMERNOTE_LONG: '본문의 길이가 너무 깁니다.',
  CONFIRM_SAVE: '게시글을 등록하시겠습니까?',
  CONFIRM_MODIFY: '게시글을 수정하시겠습니까?',
  ALERT_SUCCESS: '게시글이 등록되었습니다.',
  CONFIRM_CANCEL: '취소하시겠습니까? 수정하신 내용은 저장되지 않습니다.',
  ALERT_ERROR: '업로드된 파일이 없습니다.',
  CONFIRM_DELETE: '첨부파일을 삭제하시겠습니까?',
  ALERT_FILE_LENGTH: '첨부파일은 최대 10개만 등록 가능합니다.',
  PARENT_BOARD: '상위 게시물',
  AUTHOR: '작성자',
  AUTHOR_DATE: '작성일시',
  BOARD_NO: '게시글번호',
  SEARCH_TIME: '조회수',
  FIX_ORDER: '순서고정',
  SELECT_BOARD: '게시판 선택',
  SELECT_CATEGORY: '게시판 카테고리 선택',
  FILE_UPLOAD_MAX_10: '첨부파일은 최대 10개만 등록 가능합니다.',
  FILE_SIZE_MAX_5M: '파일용량이 5MB를 초과하였습니다.',
  FILE_EXE_CHK: '허용하지 않는 확장자입니다.\n (허용확장자 : JPG, JPEG, GIF, PNG, BMP)',
  SAVE_SUCCESS: '성공{0}건: 게시글 이동 완료\r',
  SAVE_FAILED: '실패 {0} 건:',
  DELETE_CONFIRM: '첨부파일을 삭제하시겠습니까?',
  POPUP_CANCEL_CONFIRM: '취소하시겠습니까? 수정하신 내용은 저장되지 않습니다.',
});
