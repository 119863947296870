




import { Component, Vue, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { i18n } from '@/main';

@Component({
  components: {
    NoticeBox,
  },
})
export default class StatisticNoticeBox extends Vue {
  @Prop({ required: true })
  private readonly statisticType!: 'SALES' | 'MEMBERS';

  private noticeList = {
    SALES: [
      i18n.t('STATISTIC.SALES.DATA_LIMIT_DAY'),
      i18n.t('STATISTIC.SALES.FIRST_PAY_SEARCH'),
      i18n.t('STATISTIC.SALES.FIRST_PAY'),
      i18n.t('STATISTIC.SALES.FIRST_PAY_CLAIM'),
      i18n.t('STATISTIC.SALES.CANCEL_COUNT'),
      i18n.t('STATISTIC.SALES.EXCEL_DOWNLOAD'),
    ],
    MEMBERS: [
      '회원 수는 회원가입 승인일자 기준의 회원 수입니다.',
      '승인대기 회원은 회원 수에서 제외되며 승인완료 시점에 회원 수에 포함됩니다.',
      '휴면 및 탈퇴 회원은 휴면 전환 일자 및 탈퇴 일자 기준으로 회원 수에서 제외됩니다.',
      '비율은 소수점 2자리까지 표시되며, 소수점 3자리에서 반올림됩니다.',
    ],
  };
}
