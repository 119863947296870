import Vue from 'vue';
import { logSend } from '@/utils/logUtils';

window.addEventListener('unhandledrejection', (e: any): any => {
  throw e.reason;
});
window.addEventListener(
  'error',
  (args: any): any => {
    let logncrashErr = false;
    if (args?.error?.config?.baseURL === 'https://api-logncrash.cloud.toast.com') {
      logncrashErr = true;
    }
    if (!logncrashErr) {
      logSend(JSON.stringify(args.error));
    }
    return true;
  },
  true,
);
Vue.config.errorHandler = (err: any): any => {
  const errBody = JSON.stringify(err);

  logSend(errBody);
};
