import { Route } from 'vue-router';
import { mall } from '@/utils/mall';
import { sendQueryString } from '@/utils/query';
import { GetKakaoMessagesPopupResponse, ManagerInfo, NCPResponse } from 'ncp-api-supporter';
import { api } from '@/api';
import { throwExternalWindowPopup } from '@/helpers/popup';
// import { HistoryDetail, HistoryDetailRequestInfo, historyDetailType } from '@/types';
import { HistoryDetailRequestInfo, historyDetailType } from '@/types';
import { AxiosPromise } from 'axios';
import { GetSmsGodoTokenPopupSmsDetailResponse } from 'ncp-api-supporter/dist/types';

export const checkSearchResult = (currRoute: Route, prevRoute: Route, msg, entity): boolean => {
  const { path: currPath, query: currQuery } = currRoute;
  const { path: prevPath, query: prevQuery } = prevRoute;

  const prevMallNo = prevQuery.mallNo ?? mall.lastCreatedMall.mallNo;
  const resetSearchResult = currQuery.mallNo !== prevMallNo.toString() || currPath !== prevPath;

  if (resetSearchResult) {
    sendQueryString(entity, { mallNo: currQuery.mallNo });
    entity.contents = [];
    entity.totalCount = 0;
    entity.grid.changeNoDataMessage(entity.$t(msg));
  }

  return resetSearchResult;
};
const mappedHistoryDetailKey = {
  kakao: {
    godosno: 'godoSno',
    sendKey: 'kakaoSendKey',
  },
  sms: {
    godosno: 'sno',
    sendKey: 'smsSendKey',
  },
};

const generateHistoryDetails = (type: historyDetailType, { mallNo, data, ip }) => {
  const adminInfo = JSON.parse(sessionStorage.getItem('admin'));
  const { id, name, serviceNo, privacy } = adminInfo;
  const key = mappedHistoryDetailKey[type];

  const { token, sendDate } = data;
  const historyDetails = {
    token,
    godosno: data[key.godosno],
    [key.sendKey]: data[key.sendKey],
    sendDate,
    mallNos: [mallNo], //NOTE: 배열로 넘겨야 함.
    privacyAccessor: JSON.stringify({
      id,
      name,
      partnerNo: 0,
      serviceNo,
      ip,
    }),
  };
  return privacy ? historyDetails : { ...historyDetails, userMasking: 'um' };
};

const fetchDetail = async (type: historyDetailType, { mallNo, sendDate, sendKey }: HistoryDetailRequestInfo) => {
  if (type === 'kakao') {
    return api.getKakaoMessagesPopup({
      params: {
        mallNo,
        sendDate,
        kakaoSendKey: sendKey,
      },
    }) as AxiosPromise<GetKakaoMessagesPopupResponse>;
  }
  return api.getSmsGodoTokenPopupSmsDetail({
    params: {
      mallNo,
      sendDate,
      smsSendKey: sendKey,
    },
  }) as GetSmsGodoTokenPopupSmsDetailResponse;
};

const fetchMyIp = async (ip: string | null): Promise<string> => {
  if (ip) return ip;
  const myIpResponse: NCPResponse<ManagerInfo> = await api.getManagerInfo();
  return myIpResponse.data.ip;
};

export async function openHistoryDetailsLayer<HistoryDetailResponse>(
  type: historyDetailType,
  info: HistoryDetailRequestInfo,
  ip = null,
) {
  const { data: detail } = await fetchDetail(type, info);
  const myIp = await fetchMyIp(ip);

  const data = generateHistoryDetails(type, {
    mallNo: info.mallNo,
    data: detail,
    ip: myIp,
  });

  throwExternalWindowPopup(detail.url, 'md', data);
}
