import { GridProp } from '@/types';
import { Inquiry } from 'ncp-api-supporter/dist/types/modules/manage/inquiry';
import { getAnchorHTML } from '@/utils/grid/formatterUtils'; //getDeleteHTML
import { addDay4YMDHMSS } from '@/utils/dateFormat';
import { rowNumRenderer } from '@/utils/grid/rendererUtils';
import { masking } from '@/utils/common';

export const getInquiriesListGridOption = (): GridProp<Inquiry> => {
  return {
    columns: [
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH1',
        name: 'mall',
        align: 'center',
        minWidth: 5,
        formatter: (cell: any) => {
          return cell.row.mallName;
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH2',
        name: 'issuedDateTime',
        align: 'center',
        minWidth: 85,
        formatter: (cell: any) => {
          if (
            (cell.row.inquiryStatus == 'IN_PROGRESS' || cell.row.inquiryStatus == 'ISSUED') &&
            addDay4YMDHMSS(new Date(), -1) > cell.value
          ) {
            return `<span class="text-red">${cell.value}</span>`;
          } else {
            return cell.value;
          }
        },
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH3',
        name: 'inquiryNo',
        align: 'center',
        minWidth: 5,
        formatter: ({ value }) => (value ? getAnchorHTML(value.toString()) : ''),
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH4',
        name: 'inquiryTitle',
        align: 'left',
        minWidth: 200,
        formatter: ({ value }) => (value ? getAnchorHTML(value.toString()) : ''),
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH5',
        name: 'issuer',
        align: 'center',
        minWidth: 120,
        formatter: (cell: any) => {
          if (cell?.row?.issuer?.issuerStatus === 'WITHDRAWN') {
            return window.$t('WITHDRAWAL_MEMBER').toString();
          }
          if (cell.row.external && cell.row.external.naverPay) {
            return `<a style="text-decoration: underline;"
                href="javascript:void(0)">${cell.row.external.naverPay.writerName} (${masking(
              cell.row.external.naverPay.naverId,
              3,
            )})</a>`;
          }

          if (cell.row.issuer) {
            // if (cell.memberStatus == 'WITHDRAWN') {
            //     return window.$t('INQUIRY_INQUIRY_ITEMTABLE_T5_NOTNULL');;
            // } todo
            if (cell.row.issuer.issuerId != '') {
              return `<a style="text-decoration: underline;"
                href="javascript:openIssuerDetail(${cell.row.issuer.issuerNo});">${cell.row.issuer.issuerId} (${cell.row.issuer.issuerNo})</a>`;
            } else {
              return `<a style="text-decoration: underline;"
                href="javascript:openIssuerDetail(${cell.row.issuer.issuerNo});">${cell.row.issuer.issuerNo}</a>`;
            }
          } else {
            if (cell.row.issuer.issuerEmail) {
              return cell.row.issuer.issuerEmail;
            } else {
              return window.$t('INQUIRY.INQUIRY.ITEMTABLE_T5_NULL');
            }
          }
        },
        copyOptions: {
          customValue: (cell: any): string => {
            if (cell.row.issuer) {
              if (cell.row.issuer.issuerId != '') {
                return `${cell.row.issuer.issuerId} (${cell.row.issuer.issuerNo})`;
              } else {
                return cell.row.issuer.issuerNo;
              }
            } else {
              if (cell.row.issuer.issuerEmail) {
                return cell.row.issuer.issuerEmail;
              } else {
                return window.$t('INQUIRY.INQUIRY.ITEMTABLE_T5_NULL');
              }
            }
          },
        },
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH6',
        name: 'inquiryTypeName',
        align: 'center',
        minWidth: 95,
        formatter(cell: any) {
          if (cell) {
            return cell.value;
          }

          return window.$t('INQUIRY.INQUIRY.ITEMTABLE_T6_NULL');
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH7',
        name: 'assignee',
        align: 'center',
        minWidth: 50,
        formatter(cell: any) {
          if (!cell) return '';
          // DB 완전 삭제된 운영자
          if (!cell.row.assignee) {
            return window.$t('BOARD.INQUIRIES.ITEMTABLE_TH7_DBDELETE'); //삭제된 운영자
          }

          // 담당자 미지정
          if (cell.row.assignee.assigneeStatus === 'UNASSIGNEED') {
            return `<span class="text-red">${window.$t('BOARD.INQUIRIES.ITEMTABLE_TH7_UNASSIGNEED')}</span>`;
          }

          //삭제된 운영자
          if (cell.row.inquiryStatus === 'ANSWERED' && cell.row.assignee.assigneeStatus === 'DELETE') {
            return `<span style="font-style: italic;">${cell.row.assignee.assigneeName}</span>`;
          }

          return cell.row.assignee.assigneeName || '';
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH8',
        name: 'inquiryStatus',
        align: 'center',
        minWidth: 40,
        formatter(cell: any) {
          if (cell.value == 'ANSWERED') return window.$t('BOARD.INQUIRIES.COMPCHECKBOX_4');
          if (cell.value == 'IN_PROGRESS') return window.$t('BOARD.INQUIRIES.COMPCHECKBOX_3');
          if (cell.value == 'ISSUED') return window.$t('BOARD.INQUIRIES.COMPCHECKBOX_2');
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'BOARD.INQUIRIES.ITEMTABLE_TH9',
        name: 'answeredDateTime',
        align: 'center',
        minWidth: 85,
        formatter(cell: any) {
          if (cell.row.inquiryStatus == 'ANSWERED') return cell.value;
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
    ],
    options: {
      // keyColumnName: 'no',
      rowHeaders: [
        'checkbox',
        {
          type: 'rowNum',
          renderer: props => {
            return rowNumRenderer(props.value);
          },
        },
      ],
      pageOptions: {
        page: 1,
        perPage: 10,
        totalCount: 0,
      },
    },
  };
};
