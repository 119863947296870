



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch, Ref, Mixins } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { mall } from '@/utils/mall';
import {
  DisplayPageInfo,
  GetDesignPopupsDisplayUrlsPlatformsRequest,
  MobileDesign,
  PcDesign,
  PopupSlideInfo,
  PostDesignPopups,
  PostDesignPopupsRequest,
  SlideImages,
} from 'ncp-api-supporter';
import { PopupClose, throwPopup } from '@/helpers/popup';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { getToday } from '@/utils/dateFormat';
import { OptionData } from '@/helpers/type';
import {
  DEFAULT_TIME_RANGE,
  HOUR_SELECT_OPTIONS,
  MINUTE_SELECT_OPTIONS,
} from '@/components/common/datepicker/dateRange';
import MallSelect from '@/components/common/input/MallSelect.vue';
import ColorPickerButton from '@/components/common/colorpicker/ColorPickerButton.vue';
import {
  getDisplayableOptions,
  getDisplayPeriodTypeOptions,
  getMobileScreenTypeMultiOptions,
  getMobileScreenTypeOptions,
  getOpenLocationTargetOptions,
  getPcScreenTypeMultiOptions,
  getPcScreenTypeOptions,
  getPopupDesignTypeOptions,
  getSlideCountOptions,
  getSlideDirectionOptions,
  getSlideImageTypeOptions,
  getSlideSpeedOptions,
  getUnitOptions,
  getImageDivClass,
  getDesignPopupEditorOptions,
} from '@/const/contents/designPopup';
import SlideImageFileInputMixin from '@/components/common/input/slideImageFileInput/SlideImageFileInputMixin.vue';
import Editor from '@/components/common/summernote/Editor.vue';

@Component({
  components: {
    MallSelect,
    TextInput,
    RadioGroup,
    DateRangePicker,
    SelectBox,
    ToolTip,
    Editor,
    ColorPickerButton,
  },
})
export default class DesignPopupAdd extends Mixins(SlideImageFileInputMixin) {
  private designPopupEditorOption = getDesignPopupEditorOptions();

  private pcScreenTypeOption = getPcScreenTypeOptions();
  private pcScreenTypeMultiOption = getPcScreenTypeMultiOptions();
  private mobileScreenTypeOption = getMobileScreenTypeOptions();
  private mobileScreenTypeMultiOption = getMobileScreenTypeMultiOptions();
  private displayableOption = getDisplayableOptions();
  private displayPeriodTypeOption = getDisplayPeriodTypeOptions();
  private popupDesignTypeOption = getPopupDesignTypeOptions();

  private unitOption = getUnitOptions();
  private slideDirectionOption = getSlideDirectionOptions();
  private slideSpeedOption = getSlideSpeedOptions();
  private slideCountOption = getSlideCountOptions();
  private slideImageTypeOption = getSlideImageTypeOptions();
  private openLocationTargetOption = getOpenLocationTargetOptions();

  @Ref() private readonly addMallSelectboxRef: HTMLInputElement;
  @Ref() private readonly addDesignPlatformCheckboxRef: HTMLInputElement;
  @Ref() private readonly addTitleTextboxRef: HTMLInputElement;
  @Ref() private readonly periodRangePicker: DateRangePicker;
  @Ref() private readonly timeRangePicker: DateRangePicker;
  @Ref() private readonly displayStartHourSelectBox: HTMLInputElement;

  @Ref() private editorRef!: Editor;

  @Ref() private readonly pcDesignScreenWidthRef: TextInput;
  @Ref() private readonly pcDesignScreenHeightRef: TextInput;
  @Ref() private readonly pcDesignScreenTopPositionRef: TextInput;
  @Ref() private readonly pcDesignScreenLeftPositionRef: TextInput;

  @Ref() private readonly mobileDesignScreenWidthRef: TextInput;
  @Ref() private readonly mobileDesignScreenHeightRef: TextInput;
  @Ref() private readonly mobileDesignScreenTopPositionRef: TextInput;
  @Ref() private readonly mobileDesignScreenLeftPositionRef: TextInput;

  @Ref() private readonly popupSlideInfoSlideMaxWidthRef: TextInput;
  @Ref() private readonly popupSlideInfoSlideMaxHeightRef: TextInput;
  @Ref() private readonly popupSlideInfoSlideMinHeightRef: TextInput;

  private get getImageDivClassStr(): string {
    return getImageDivClass(this.popupSlideInfo.slideCount);
  }

  private changeSelectMall(value: string): void {
    this.postDesignPopupData.mallNo = Number(value);
    this.pcDesignPlatform = true;
    this.mobileDesignPlatform = true;
    this.initPageInfoOption('PC');
    this.initPageInfoOption('MOBILE_WEB');
  }

  private onChangeColor(colorHexCode: string, platformDesignName: string, keyName: string): void {
    this[platformDesignName][keyName] = colorHexCode;
    if (this.copyPcDesign) {
      this.triggerMobileDesignChangeByCopyPcDesign('bgColor', 'bgColor');
    }
  }

  private pcDesignPlatform = true;
  private mobileDesignPlatform = true;

  private HOUR_SELECT_OPTIONS: OptionData<string>[] = HOUR_SELECT_OPTIONS;
  private MINUTE_SELECT_OPTIONS: OptionData<string>[] = MINUTE_SELECT_OPTIONS;

  private startYmd = getToday();
  private endYmd = getToday();
  private startHms = DEFAULT_TIME_RANGE.START;
  private endHms = DEFAULT_TIME_RANGE.END;
  private dateRageOption = {
    dateType: 'YmdHms',
    hasPeriodBtn: false,
  };

  private displayStartDate = getToday();
  private displayEndDate = getToday();
  private displayStartHour = '00';
  private displayStartMinute = '00';
  private displayEndHour = '23';
  private displayEndMinute = '59';

  @Watch('popupSlideInfo.slideCount', { immediate: false })
  private watchSlideCount(): void {
    const slideCountType = this.popupSlideInfo.slideCount;
    const isHasType = this.slideImages.length;
    const newSlideImages: SlideImages[] = [];
    let numArray = undefined;
    let needUpdate = false;
    if (slideCountType === 'TWO_BY_ONE' && isHasType !== 2) {
      numArray = [1, 2];
      needUpdate = true;
    } else if (slideCountType === 'THREE_BY_ONE' && isHasType !== 3) {
      numArray = [1, 2, 3];
      needUpdate = true;
    } else if (slideCountType === 'FOUR_BY_ONE' && isHasType !== 4) {
      numArray = [1, 2, 3, 4];
      needUpdate = true;
    } else if (slideCountType === 'TWO_BY_TWO' && isHasType !== 4) {
      numArray = [1, 2, 3, 4];
      needUpdate = true;
    } else if (slideCountType === 'THREE_BY_TWO' && isHasType !== 6) {
      numArray = [1, 2, 3, 4, 5, 6];
      needUpdate = true;
    } else if (slideCountType === 'FOUR_BY_TWO' && isHasType !== 8) {
      numArray = [1, 2, 3, 4, 5, 6, 7, 8];
      needUpdate = true;
    }
    if (needUpdate) {
      numArray.forEach((el: number) => {
        newSlideImages.push({
          thumbImageUrlOnOver: '',
          displayNo: el,
          openLocationTarget: 'SELF',
          hasUploaded: true,
          landingUrl: '',
          mainImageUrl: '',
          thumbImageUrl: '',
        });
      });
      if (isHasType > numArray.length && this.slideImagesSettingDisplayIndex > numArray.length - 1) {
        this.slideImagesSettingDisplayIndex = 0;
      }
      this.slideImages = newSlideImages;
    }
    this.changeSlideMinWidthByslideMaxWidth();
  }

  private changePcDesignPlatform({ currentTarget }: { currentTarget: HTMLInputElement }): void {
    if (currentTarget.checked) {
      this.initPageInfoOption('PC');
    }
  }

  private changeMobileDesignPlatform({ currentTarget }: { currentTarget: HTMLInputElement }): void {
    if (currentTarget.checked) {
      this.initPageInfoOption('MOBILE_WEB');
    }
  }

  @Watch('popupSlideInfo.slideMaxWidth', { immediate: false })
  private watchSlideMaxWidth(): void {
    this.changeSlideMinWidthByslideMaxWidth();
  }

  private changeSlideMinWidthByslideMaxWidth(): void {
    const slideMaxWidth = this.popupSlideInfo.slideMaxWidth;
    if (slideMaxWidth && /^[0-9.]/.test(String(slideMaxWidth)) && Number(slideMaxWidth) !== 0) {
      let slideMinWidth = 0;

      if (['THREE_BY_ONE', 'THREE_BY_TWO'].includes(this.popupSlideInfo.slideCount)) {
        slideMinWidth = Number((slideMaxWidth / 3).toFixed(1));
      } else if (['FOUR_BY_ONE', 'FOUR_BY_TWO'].includes(this.popupSlideInfo.slideCount)) {
        slideMinWidth = Number((slideMaxWidth / 4).toFixed(1));
      } else {
        slideMinWidth = Number((slideMaxWidth / 2).toFixed(1));
      }
      this.popupSlideInfo.slideMaxWidth = Number(slideMaxWidth);
      this.popupSlideInfo.slideMinWidth = slideMinWidth;
    }
  }

  private pcPageInfoOption: OptionData<string>[] = [];
  private mobilePageInfoOption: OptionData<string>[] = [];

  private popupSlideInfo: PopupSlideInfo = {
    slideSpeed: 4,
    slideMinWidth: null,
    slideMaxWidth: null,
    resizable: false,
    slideMinHeight: null,
    slideDirection: 'RIGHT',
    slideImages: [],
    slideCount: 'TWO_BY_ONE',
    slideMaxHeight: null,
  };

  private postDesignPopupData: PostDesignPopups = {
    isAppliedEquallyAsPc: false,
    displayConfig: {
      displayPeriodType: 'ALWAYS',
      displayStartDate: getToday(),
      displayStartTime: '00:00',
      displayEndDate: getToday(),
      displayEndTime: '23:59',
    },
    displayable: true,
    title: '',
    mallNo: mall.mallsSortedByCreationDate[0].mallNo,
    content: '',
    popupDesignType: 'NORMAL',
    visibleToday: true,
  } as PostDesignPopups;

  private pcDesign: PcDesign = {
    screenWidthUnit: 'PIXEL',
    screenWidth: 300,
    bgColor: '#FFFFFF',
    resizable: false,
    screenTopPosition: 0,
    displayPageInfo: [],
    screenHeight: 300,
    screenType: 'FIXED',
    screenLeftPosition: 0,
    screenHeightUnit: 'PIXEL',
  } as PcDesign;

  private mobileDesign: MobileDesign = {
    screenWidthUnit: 'PIXEL',
    screenWidth: 300,
    bgColor: '#FFFFFF',
    resizable: false,
    screenTopPosition: 0,
    displayPageInfo: [],
    screenHeight: 300,
    screenType: 'FIXED',
    screenLeftPosition: 0,
    screenHeightUnit: 'PIXEL',
  } as MobileDesign;

  private pcNormalScreenType = 'FIXED';
  private pcMultiScreenType = 'FIXED';
  private mobileNormalScreenType = 'FIXED';
  private mobileMultiScreenType = 'FIXED';

  private slideImagesSettingDisplayIndex = 0;

  private slideImages: SlideImages[] = [
    {
      thumbImageUrlOnOver: '',
      displayNo: 1,
      openLocationTarget: 'SELF',
      hasUploaded: true,
      landingUrl: '',
      mainImageUrl: '',
      thumbImageUrl: '',
    },
    {
      thumbImageUrlOnOver: '',
      displayNo: 2,
      openLocationTarget: 'SELF',
      hasUploaded: true,
      landingUrl: '',
      mainImageUrl: '',
      thumbImageUrl: '',
    },
  ];

  private pcDisplayPageInfoList: DisplayPageInfo[] = [
    {
      pageNo: null,
      parameter: '',
    },
  ];

  private mobileDisplayPageInfoList: DisplayPageInfo[] = [
    {
      pageNo: null,
      parameter: '',
    },
  ];

  private addPageInfoRow(platform: string): void {
    const obj: DisplayPageInfo = {
      pageNo: null,
      parameter: '',
    };
    if (platform === 'pc') {
      this.pcDisplayPageInfoList.push(obj);
    } else {
      this.mobileDisplayPageInfoList.push(obj);
    }
  }

  private removePageInfoRow(index: number, platform: string): void {
    if (platform === 'pc') {
      this.pcDisplayPageInfoList.splice(index, 1);
    } else {
      this.mobileDisplayPageInfoList.splice(index, 1);
    }
  }

  private copyPcDesign = false;

  private selectImageSetting(index: number): void {
    this.slideImagesSettingDisplayIndex = index;
  }

  private searchListPageParams = undefined;
  created() {
    this.searchListPageParams = this.$route.params;

    if (this.pcDesignPlatform) {
      this.initPageInfoOption('PC');
    }
    if (this.mobileDesignPlatform) {
      this.initPageInfoOption('MOBILE_WEB');
    }

    this.throwBottomNavigation();
  }

  private initPageInfoOption(platformType: string): void {
    const request: GetDesignPopupsDisplayUrlsPlatformsRequest = {
      params: {
        mallNo: this.postDesignPopupData.mallNo,
        platformType,
      },
    };

    this.$api.getDesignPopupsDisplayUrlsPlatforms(request).then(response => {
      if (response && response.data) {
        const tempOption: OptionData<string>[] = [];
        response.data.forEach(elem => {
          tempOption.push({
            name: `${elem.pageName}:${elem.displayUrl}`,
            value: `${elem.pageNo}`,
          });
        });
        if (platformType === 'PC') {
          this.pcPageInfoOption = tempOption;
        } else {
          this.mobilePageInfoOption = tempOption;
        }
      }
    });
  }

  private throwBottomNavigation(): void {
    const buttons = [];
    buttons.push(
      {
        type: 'right',
        key: 'back',
        color: 'gray',
        text: this.$t('LIST'),
      },
      {
        type: 'right',
        key: 'save',
        color: 'red',
        text: this.$t('SAVE'),
      },
    );

    throwBottomNavigation({
      buttons,
      onClick: (key: string) => {
        switch (key) {
          case 'save':
            this.save();
            break;
          case 'back':
            this.goBackList();
            break;
        }
      },
    });
  }

  private save(): void {
    // 쇼핑몰
    if (!this.postDesignPopupData.mallNo) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.MALL_SELECT_ALERT'));
      this.addMallSelectboxRef.focus();
      return;
    }
    // 플랫폼 구분
    if (!this.pcDesignPlatform && !this.mobileDesignPlatform) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.PLATFORM_CHECK_ALERT'));
      this.addDesignPlatformCheckboxRef.focus();
      return;
    } else {
      this.postDesignPopupData['platformTypes'] = [];
      if (this.pcDesignPlatform) {
        this.postDesignPopupData['platformTypes'].push('PC');
      }
      if (this.mobileDesignPlatform) {
        this.postDesignPopupData['platformTypes'].push('MOBILE_WEB');
      }
    }
    // 팝업 제목
    if (!this.postDesignPopupData.title) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.TITLE_INPUT_ALERT'));
      this.addTitleTextboxRef.focus();
      return;
    }

    // 노출 설정
    if (this.postDesignPopupData.displayConfig.displayPeriodType === 'PERIOD') {
      if (!this.endYmd) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.END_DATE_EMPTY'));
        this.periodRangePicker.focus('end');
        return;
      }

      this.postDesignPopupData.displayConfig.displayStartDate = this.startYmd;
      this.postDesignPopupData.displayConfig.displayEndDate = this.endYmd;
      this.postDesignPopupData.displayConfig.displayStartTime = this.startHms;
      this.postDesignPopupData.displayConfig.displayEndTime = this.endHms;
    } else if (this.postDesignPopupData.displayConfig.displayPeriodType === 'TIME') {
      if (!this.displayEndDate) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.END_DATE_EMPTY'));
        this.timeRangePicker.focus('end');
        return;
      }

      const timeStart = this.displayStartHour + ':' + this.displayStartMinute + ':00';
      const timeEnd = this.displayEndHour + ':' + this.displayEndMinute + ':00';
      if (timeStart > timeEnd) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.TIME_FORMAT_ERROR'));
        this.displayStartHourSelectBox.focus();
        return;
      }

      this.postDesignPopupData.displayConfig.displayStartDate = this.displayStartDate;
      this.postDesignPopupData.displayConfig.displayEndDate = this.displayEndDate;
      this.postDesignPopupData.displayConfig.displayStartTime = timeStart;
      this.postDesignPopupData.displayConfig.displayEndTime = timeEnd;
    } else {
      this.postDesignPopupData.displayConfig.displayStartDate = null;
      this.postDesignPopupData.displayConfig.displayEndDate = null;
      this.postDesignPopupData.displayConfig.displayStartTime = null;
      this.postDesignPopupData.displayConfig.displayEndTime = null;
    }

    if (this.postDesignPopupData.popupDesignType === 'NORMAL') {
      if (this.editorRef.getHtml() === '' || this.editorRef.getHtml() === '<p><br></p>') {
        alert(this.$t('DISPLAY.DESIGN_POPUP.EDITOR_INPUT_ALERT'));
        this.editorRef.focus();
        return;
      }
      this.postDesignPopupData.content = this.editorRef.getHtml();
      if (this.pcDesignPlatform) {
        this.onKeyup('PC', 'screenWidth', 'screenWidthUnit');
        this.onKeyup('PC', 'screenHeight', 'screenHeightUnit');

        if (!this.pcDesign.screenWidth) {
          alert(this.$t('DISPLAY.DESIGN_POPUP.SCREENWIDTH_INPUT_ALERT'));
          this.pcDesignScreenWidthRef.focus();
          return;
        }
        if (!this.pcDesign.screenHeight) {
          alert(this.$t('DISPLAY.DESIGN_POPUP.SCREENHEIGHT_INPUT_ALERT'));
          this.pcDesignScreenHeightRef.focus();
          return;
        }
        this.pcDesign.screenType = this.pcNormalScreenType;
      }

      if (this.mobileDesignPlatform) {
        this.onKeyup('MOBILE', 'screenWidth', 'screenWidthUnit');
        this.onKeyup('MOBILE', 'screenHeight', 'screenHeightUnit');

        if (!this.mobileDesign.screenWidth) {
          alert(this.$t('DISPLAY.DESIGN_POPUP.SCREENWIDTH_INPUT_ALERT'));
          this.mobileDesignScreenWidthRef.focus();
          return;
        }
        if (!this.mobileDesign.screenHeight) {
          alert(this.$t('DISPLAY.DESIGN_POPUP.SCREENHEIGHT_INPUT_ALERT'));
          this.mobileDesignScreenHeightRef.focus();
          return;
        }
        this.mobileDesign.screenType = this.mobileNormalScreenType;
      }
    }

    if (this.pcDesignPlatform) {
      if (this.pcDesign.screenTopPosition.toString() === '') {
        alert(
          this.$t('ALERT_INPUT_NOT_VALID', {
            fieldName:
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION') +
              ' ' +
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION_TOP'),
          }),
        );
        this.pcDesignScreenTopPositionRef.focus();
        return;
      }
      if (this.pcDesign.screenLeftPosition.toString() === '') {
        alert(
          this.$t('ALERT_INPUT_NOT_VALID', {
            fieldName:
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION') +
              ' ' +
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION_LEFT'),
          }),
        );
        this.pcDesignScreenLeftPositionRef.focus();
        return;
      }

      this.pcDesign.displayPageInfo = [];
      this.pcDisplayPageInfoList.forEach(elem => {
        if (elem.pageNo) {
          this.pcDesign.displayPageInfo.push({ pageNo: elem.pageNo, parameter: elem.parameter });
        }
      });
      if (this.pcDesign.displayPageInfo.length === 0) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.DISPLAYURL_EMPTY_ALERT'));
        return;
      }

      if (this.postDesignPopupData.popupDesignType === 'MULTI') {
        this.pcDesign.screenType = this.pcMultiScreenType;
      }
      this.postDesignPopupData['pcDesign'] = this.pcDesign;
    }

    if (this.mobileDesignPlatform) {
      if (this.mobileDesign.screenTopPosition.toString() === '') {
        alert(
          this.$t('ALERT_INPUT_NOT_VALID', {
            fieldName:
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION') +
              ' ' +
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION_TOP'),
          }),
        );
        this.mobileDesignScreenTopPositionRef.focus();
        return;
      }
      if (this.mobileDesign.screenLeftPosition.toString() === '') {
        alert(
          this.$t('ALERT_INPUT_NOT_VALID', {
            fieldName:
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION') +
              ' ' +
              this.$t('DISPLAY.DESIGN_POPUP.SCREEN_POSITION_LEFT'),
          }),
        );
        this.mobileDesignScreenLeftPositionRef.focus();
        return;
      }

      this.mobileDesign.displayPageInfo = [];
      this.mobileDisplayPageInfoList.forEach(elem => {
        if (elem.pageNo) {
          this.mobileDesign.displayPageInfo.push({ pageNo: elem.pageNo, parameter: elem.parameter });
        }
      });
      if (this.mobileDesign.displayPageInfo.length === 0) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.DISPLAYURL_EMPTY_ALERT'));
        return;
      }

      if (this.postDesignPopupData.popupDesignType === 'MULTI') {
        this.mobileDesign.screenType = this.mobileMultiScreenType;
      }
      this.postDesignPopupData['mobileDesign'] = this.mobileDesign;
    }

    if (this.postDesignPopupData.popupDesignType === 'MULTI') {
      if (!this.popupSlideInfo.slideMaxWidth) {
        alert(
          this.$t('DISPLAY.DESIGN_POPUP.ALERT_INPUT_NOT_VALID', {
            fieldName: this.$t('DISPLAY.DESIGN_POPUP.SLIDE_MAX') + ' ' + this.$t('DISPLAY.DESIGN_POPUP.WIDTH'),
          }),
        );
        this.popupSlideInfoSlideMaxWidthRef.focus();
        return;
      }
      if (!this.popupSlideInfo.slideMaxHeight) {
        alert(
          this.$t('DISPLAY.DESIGN_POPUP.ALERT_INPUT_NOT_VALID', {
            fieldName: this.$t('DISPLAY.DESIGN_POPUP.SLIDE_MAX') + ' ' + this.$t('DISPLAY.DESIGN_POPUP.HEIGHT'),
          }),
        );
        this.popupSlideInfoSlideMaxHeightRef.focus();
        return;
      }
      if (!this.popupSlideInfo.slideMinHeight) {
        alert(
          this.$t('DISPLAY.DESIGN_POPUP.ALERT_INPUT_NOT_VALID', {
            fieldName: this.$t('DISPLAY.DESIGN_POPUP.SLIDE_MIN') + ' ' + this.$t('DISPLAY.DESIGN_POPUP.HEIGHT'),
          }),
        );
        this.popupSlideInfoSlideMinHeightRef.focus();
        return;
      }

      for (let i = 0; i < this.slideImages.length; i++) {
        const slideImagesItem: SlideImages = this.slideImages[i];
        if (!slideImagesItem.mainImageUrl) {
          alert(this.$t('DISPLAY.DESIGN_POPUP.MAIN_IMAGE_EMPTY_ALERT'));
          if (!slideImagesItem.hasUploaded && this.slideImagesSettingDisplayIndex === i) {
            //:ref="`mainImageUrlLink${index}`"
            (this.$refs[`mainImageUrlLink${i}`][0] as HTMLInputElement).focus();
          }
          return;
        }

        if (!slideImagesItem.thumbImageUrl) {
          alert(this.$t('DISPLAY.DESIGN_POPUP.THUMB_IMAGE_EMPTY_ALERT'));
          if (!slideImagesItem.hasUploaded && this.slideImagesSettingDisplayIndex === i) {
            //:ref="`thumbImageUrlLink${index}`"
            (this.$refs[`thumbImageUrlLink${i}`][0] as HTMLInputElement).focus();
          }
          return;
        }
      }

      this.popupSlideInfo['slideImages'] = this.slideImages;
      this.postDesignPopupData['popupSlideInfo'] = this.popupSlideInfo;
    }

    const request: PostDesignPopupsRequest = {
      data: this.postDesignPopupData,
    };

    this.$api.postDesignPopups(request).then(() => {
      alert(this.$t('DISPLAY.DESIGN_POPUP.SAVE_SUCCESS'));
      this.goBackList();
    });
  }

  private goBackList(): void {
    this.$router.go(-1);
  }

  private designPopupDisplayUrlManagePopup(): void {
    if (!this.postDesignPopupData.mallNo || this.postDesignPopupData.mallNo === -1) {
      alert(this.$t('DISPLAY.DESIGN_POPUP.SELECT_MALL'));
      return;
    }
    throwPopup({
      name: 'DesignPopupDisplayUrlManage',
      data: {
        mallNo: this.postDesignPopupData.mallNo,
        pcDesignPlatform: this.pcDesignPlatform,
        mobileDesignPlatform: this.mobileDesignPlatform,
      },
    }).then((result: { state: string; data: object }) => {
      if (result && result.state === PopupClose.CLOSE) {
        this.initPageInfoOption('PC');
        this.initPageInfoOption('MOBILE_WEB');
      }
    });
  }

  private copySettingFromPcToMobile(e: Event): void {
    const val = (e.target as HTMLInputElement).checked;
    if (val === true) {
      if (!this.pcDesignPlatform) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.COPY_PC_ALERT'));
        (e.target as HTMLInputElement).checked = false;
        return;
      }

      if (!this.pcDisplayPageInfoList || this.pcDisplayPageInfoList.filter(elm => elm.pageNo).length === 0) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.DISPLAYURL_EMPTY_ALERT'));
        (e.target as HTMLInputElement).checked = false;
        return;
      }
      let pcShowPageInMobile = true;
      if (
        this.pcPageInfoOption &&
        this.mobilePageInfoOption &&
        this.pcPageInfoOption.length > 0 &&
        this.mobilePageInfoOption.length > 0
      ) {
        this.pcDisplayPageInfoList.forEach(elem => {
          const pageNoinMobile = this.mobilePageInfoOption.find(mElem => Number(mElem.value) == elem.pageNo);
          if (!pageNoinMobile) {
            pcShowPageInMobile = false;
            return;
          }
        });
      } else {
        pcShowPageInMobile = false;
      }
      if (!pcShowPageInMobile) {
        alert(this.$t('DISPLAY.DESIGN_POPUP.COPY_PC_ALERT'));
        (e.target as HTMLInputElement).checked = false;
        return;
      }

      this.mobileDesign.screenWidth = this.pcDesign.screenWidth;
      this.mobileDesign.screenWidthUnit = this.pcDesign.screenWidthUnit;
      this.mobileDesign.screenHeight = this.pcDesign.screenHeight;
      this.mobileDesign.screenHeightUnit = this.pcDesign.screenHeightUnit;
      this.mobileDesign.resizable = this.pcDesign.resizable;

      this.mobileDesign.screenTopPosition = this.pcDesign.screenTopPosition;
      this.mobileDesign.screenLeftPosition = this.pcDesign.screenLeftPosition;
      this.mobileDesign.bgColor = this.pcDesign.bgColor;
      this.mobileDisplayPageInfoList = this.pcDisplayPageInfoList.map(({ pageNo, parameter }) => ({
        pageNo,
        parameter,
      }));
    }
  }

  private triggerMobileDesignChangeByCopyPcDesign(pcDesignSetting: string, mobileDesignSetting: string): void {
    if (this.copyPcDesign && this.mobileDesignPlatform) {
      this.mobileDesign[mobileDesignSetting] = this.pcDesign[pcDesignSetting];
    }
  }

  private triggerMobileDisplayPageInfoListChangeByCopyPcDisplayPageInfoList(
    index: number,
    pcDisplayPageInfo: string,
    mobileDisplayPageInfo: string,
  ): void {
    if (this.copyPcDesign && this.mobileDesignPlatform) {
      this.mobileDisplayPageInfoList[index][mobileDisplayPageInfo] = this.pcDisplayPageInfoList[index][
        pcDisplayPageInfo
      ];
    }
  }
  showBigImg(index: number): void {
    this.slideImagesSettingDisplayIndex = index;
  }
  showOnOverImg(e: Event): void {
    const liElement = e.target as HTMLElement;
    const orgiImage = liElement.dataset['orgi'];
    const overImage = liElement.dataset['over'];
    if (!orgiImage || orgiImage === '' || !overImage || overImage === '') {
      return;
    }
    (liElement.firstChild as HTMLImageElement).src = liElement.dataset['over'];
  }
  recoverImg(e: Event): void {
    const resetImgPath = (e.target as HTMLElement).dataset['orgi'];
    if (!resetImgPath || resetImgPath === '') {
      return;
    }
    ((e.target as HTMLElement).firstChild as HTMLImageElement).src = resetImgPath;
  }

  private get maxSlideStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return `overflow: visible;text-align: left;`;
    }
    return 'overflow: hidden;text-align: left;';
  }

  private get maxImgStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return 'max-width: 100%;max-height: 100%;';
    } else {
      return '';
    }
  }

  private get minSlideStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return `overflow: visible;text-align: left;`;
    }
    return 'overflow: hidden;text-align: left;';
  }

  private get minImgStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return 'max-width: 100%;max-height: 100%;';
    } else {
      return '';
    }
  }

  private onKeyup(platformType: string, screenSizeParam: string, screenUnit: string): void {
    if (platformType === 'PC') {
      if (this.pcDesign[screenUnit] === 'PERCENT') {
        if (this.pcDesign[screenSizeParam] <= 0 || this.pcDesign[screenSizeParam] > 100) {
          this.pcDesign[screenSizeParam] = null;
        }
      }
    } else if (platformType === 'MOBILE') {
      if (this.mobileDesign[screenUnit] === 'PERCENT') {
        if (this.mobileDesign[screenSizeParam] <= 0 || this.mobileDesign[screenSizeParam] > 100) {
          this.mobileDesign[screenSizeParam] = null;
        }
      }
    }
  }
}
