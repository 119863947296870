export default Object.freeze({
  REGISTER_DATE: '등록일',
  KEYWORD: '검색어',
  DETAIL_SEARCH: '상세검색',
  CHANGE_DISPLAY_TYPE: '전시상태 변경',
  TYPE_CHANGE: '상품후기 구분 변경',
  SELECTED_REVIEW: '변경 대상 상품후기',
  DISPLAY_TYPE: '전시상태',
  TYPE: '상품후기 구분',
  NORMAL_TYPE: '일반 상품후기',
  BEST_TYPE: '우수 상품후기',
  WRITTER: '작성자',
  PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  REVIEW_NO: '상품후기번호',
  DISPLAY_TYPE_Y: '전시중',
  DISPLAY_TYPE_DELETE: '삭제',
  DISPLAY_TYPE_N: '블라인드',
  PHOTO_TYPE: '유형',
  PHOTO_TYPE_N: '일반',
  PHOTO_TYPE_Y: '포토',
  RATING: '평점',
  COMMENT: '댓글',
  RECOMMEND: '추천',
  POINT: '{point}점',
  CONTENT: '내용',
  OPTION_NO: '옵션번호',
  OPTION_NAME: '옵션명',
  WRONG_USER: '잘못된 사용자',
  WITHDRAWAL_MEMBER: '탈퇴회원',
  NAVERPAY_MEMBER: '네이버페이 구매자',
  NON_MEMBER: '비회원',
  HISTORY: '변경이력',
  CHANGE_REVIEW_DISPLAY_TYPE_SUCCESS_MSG: '성공{0}건:전시상태 변경완료\r',
  CHANGE_REVIEW_TYPE_SUCCESS_MSG: '성공{0}건:구분 변경완료\r',
  CHANGE_REVIEW_PROPERTY_FAILD_MSG: '실패{0}건:{1}\r',
  CHANGE_DELETED_MSG: '삭제된 상품평은 변경 불가합니다.',
  CHANGE_DISPLAY_TYPE_ALREADY_UPDATED_MSG: '변경 전/후 상품평 전시상태가 동일한 경우 변경 불가합니다.',
  CHANGE_TYPE_ALREADY_UPDATED_MSG: '변경 전/후 상품평 구분이 동일한 경우 변경 불가합니다.',
  ORDER_NO: '주문번호',
  REVIEW_DETAIL: '상품후기 상세정보',
  CHANGE_REASON_INPUT: '변경사유 입력',
  IMAGE: '이미지',
  FILE: '첨부파일',
  REVIEW_HISTORY: '상품후기 변경 이력',
  UPDATE_DATE: '변경일',
  TYPE2: '상품후기구분',
  ADMIN: '처리자',
  REASON: '사유',
  NO_DATA: '검색 결과가 없습니다.',
  UPDATED: '수정되었습니다.',
  ALERT_SELECT_REVIEW: '상품후기를 선택해주세요.',
  SHOPPING_MALL_ALL: '쇼핑몰을 선택하세요.',
  ENTER_CONTENT: '내용을 입력해주세요',
  NO_WRITE: '해당 메뉴의 쓰기 권한이 없습니다',
  WRITE_COMPLETE: '관리자 댓글이 등록되었습니다',
  WRITE_FAIL: '작성 실패',
  DELETE_COMMENT: '댓글을 삭제하시겠습니까?',
  DELETE_COMPLETE: '댓글 삭제 완료',
  MODIFY_COMPLETE: '저장이 완료되었습니다',
  COMPLETE_TITLE: '등록 상품 총 {0} 개',
  PRODUCT_REGISTRATION: '상품등록',
  PRODUCT_SEARCH_REGISTRATION: '상품조회 등록',
  PRODUCT_SEARCH: '상품조회',
  PRODUCT_NO_REGISTRATION: '상품번호 직접등록',
  ADD_PRODUCT: '상품추가',
  PRODUCT_NO_BULK_REGISTRATION: '상품번호 일괄등록',
  ERR_INPUT_TYPE: '숫자와 콤마만 입력할 수 있습니다.',
  ERR_PRODUCT_NO_LENGTH: '상품번호는 10자 미만으로 입력할 수 있습니다.',
  NOTICE:
    '상품의 상품후기를 작성할 수 없도록 설정할 수 있습니다.\n' +
    '상품후기 작성을 원하지 않는 상품일 경우 ‘상품후기 작성 예외상품’으로 등록하여 사용하시기 바랍니다.\n' +
    '상품후기 작성 예외상품’ 설정 이후 해당 상품에 상품후기 작성이 불가하며, 설정 이전에 등록된 상품후기는 기 설정된 전시상태에 따라 쇼핑몰 상품 상세 페이지에 노출됩니다.',
  ERR_EXCEL_EXTENSION: '상품 일괄 등록 양식이 아닙니다.',
  FAIL_EXTENSION_FILE_NAME: '상품후기작성예외설정_실패결과',
  NO_PRODUCT: '등록가능한 상품이 없습니다.',
  CONFIRM_NOT_AVAILABLE_PRODUCT:
    '입력하신 상품 {0}개 중 등록 불가능한 상품이 {1}개({2}) 있습니다. 해당 상품을 제외하고 추가하시겠습니까?',
  CONFIRM_AVAILABLE_PRODUCT: '입력하신 상품을 상품후기 작성 예외상품으로 추가하시겠습니까?',
  CONFIRM_MOVE_TAB: '페이지 이동 시 작성된 내용은 저장되지 않습니다.\n저장하지 않고 이동하시겠습니까?',
});
