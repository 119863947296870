





























import { Vue, Component } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';

@Component({
  directives: {
    ClickOutside,
  },
})
export default class AppDownload extends Vue {
  private show = false;

  onClickOutside(): void {
    this.show = false;
  }
}
