













































import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { GetDeliveryCompaniesRequest, PutShippingsAssignInvoiceRequest } from 'ncp-api-supporter';

@Component({
  components: { TextInput, SelectBox },
})
export default class DeliveryCell extends Vue {
  @Prop({ required: true }) item;
  @Prop({ required: true }) shippingRespItem;

  private modifyMode = false;
  private deliveryCompanyType = '';
  private invoiceNo = '';
  private deliveryCompanyTypeArr = [];

  private toggleModifyMode() {
    this.modifyMode = !this.modifyMode;
  }

  private get showModifyButton() {
    return this.hasInvoiceNo && this.isEditableOrder(this.item.orderStatusType);
  }

  private get showDash() {
    return !this.hasInvoiceNo && !this.isEditableOrder(this.item.orderStatusType);
  }

  private init() {
    this.modifyMode = !this.hasInvoiceNo && this.isEditableOrder(this.item.orderStatusType);
    if (
      this.shippingRespItem?.deliveryCompanyType === undefined ||
      this.shippingRespItem.deliveryCompanyType === null
    ) {
      this.deliveryCompanyType = undefined;
      return;
    }

    this.deliveryCompanyType = this.shippingRespItem?.deliveryCompanyType;
    this.invoiceNo = this.shippingRespItem.invoiceNo;
  }

  private get hasInvoiceNo(): boolean {
    return Boolean(this.shippingRespItem.invoiceNo);
  }

  private isEditableOrder(status: string): boolean {
    const canEditInvoiceStatus = ['PAY_DONE', 'PRODUCT_PREPARE', 'DELIVERY_PREPARE', 'DELIVERY_ING', 'DELIVERY_DONE'];

    return canEditInvoiceStatus.includes(status);
  }

  public getDeliveryCompaniesLabel(type) {
    let companyType = '';
    this.deliveryCompanyTypeArr.forEach(item => {
      if (item.deliveryCompanyType === type) {
        companyType = item.label;
      }
    });
    return companyType;
  }

  public onClickInvoiceInfo(): void {
    window.open(this.shippingRespItem.retrieveInvoiceUrl);
  }

  private onSaveInvoice(): void {
    if (this.deliveryCompanyType === undefined) {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_1'));
      return;
    }
    if (!this.invoiceNo.trim()) {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_2'));
      return;
    }

    const { item, deliveryCompanyType, invoiceNo } = this;
    const invoicesRequest: PutShippingsAssignInvoiceRequest = {
      data: {
        shippingNo: item.shippingNo,
        mallNo: item.mallNo,
        deliveryCompanyType,
        invoiceNo,
      },
    };

    this.$api.putShippingsAssignInvoice(invoicesRequest).then(() => {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_3'));
      location.reload();
    });
  }

  public getDeliveryCompanies(): void {
    const request: GetDeliveryCompaniesRequest = {
      params: {
        countryCd: 'KR',
      },
    };
    this.$api.getDeliveryCompanies(request).then(res => {
      this.deliveryCompanyTypeArr = [...res.data];
    });
  }

  mounted() {
    this.getDeliveryCompanies();
    this.init();
  }
}
