import { addDay, getToday } from '@/utils/dateFormat';
import { ClaimType } from '@/types/claim';
import { claimStatusTypes, claimTypes } from '@/const/claim';
import { RefundStatus } from '@/components/shipping/claim/searchOptions';
import { mall } from '@/utils/mall';
import { Route } from 'vue-router/types/router';

export const getRefundClaimStatusTypes = (checkedRefundStatuses: string[]): string => {
  const refundClaimStatusGroup = {
    [RefundStatus.REQUEST]: [
      claimStatusTypes.CANCEL_PROC_REQUEST_REFUND,
      claimStatusTypes.EXCHANGE_PROC_REQUEST_REFUND,
      claimStatusTypes.RETURN_PROC_REQUEST_REFUND,
    ],
    [RefundStatus.WAITING]: [
      claimStatusTypes.CANCEL_PROC_WAITING_REFUND,
      claimStatusTypes.RETURN_PROC_WAITING_REFUND,
      claimStatusTypes.EXCHANGE_PROC_WAITING_REFUND,
    ],
    [RefundStatus.ADJUST_REQUESTED]: [claimStatusTypes.RETURN_REFUND_AMT_ADJUST_REQUESTED],
    [RefundStatus.DONE]: [
      claimStatusTypes.CANCEL_DONE,
      claimStatusTypes.EXCHANGE_DONE,
      claimStatusTypes.EXCHANGE_DONE_REFUND_DONE,
      claimStatusTypes.RETURN_DONE,
    ],
  };

  return checkedRefundStatuses
    .reduce((acc, checkedRefundStatus) => [...acc, refundClaimStatusGroup[checkedRefundStatus]], [])
    .toString();
};

// const constToStr = (constObject: Record<string, any>) => Object.values(constObject).toString();
const claimStatusTypesToStr = (claimType: ClaimType) => {
  if (claimType === claimTypes.REFUND) {
    return [RefundStatus.REQUEST, RefundStatus.WAITING, RefundStatus.ADJUST_REQUESTED, RefundStatus.DONE].toString();
  }

  return Object.values(claimStatusTypes)
    .filter(claimStatus => claimStatus.includes(claimType))
    .toString();
};

// undefined 일때만 -> mallNo , '' -> allMallNos(''), mallNos='1234' -> mallnos=1234
export const getDefaultMall = (mallNo: undefined | string) => {
  if (mallNo === undefined) return mall.lastCreatedMall.mallNo;
  if (mallNo === '') return '';

  return mallNo;
};

export const getDefaultParams = (claimType: ClaimType, vueRoute: Route = null) => {
  const queries = vueRoute?.query;

  return {
    claimType: claimType,
    // payTypes: queries?.payTypes ?? constToStr(payTypes),
    // 프로에서 지원하는 PAY_TYPES만 하드코딩으로 초기화
    payTypes:
      queries?.payTypes ??
      'CREDIT_CARD,VIRTUAL_ACCOUNT,REALTIME_ACCOUNT_TRANSFER,MOBILE,ACCOUNT,ZERO_PAY,ESCROW_VIRTUAL_ACCOUNT,ESCROW_REALTIME_ACCOUNT_TRANSFER,ACCUMULATION,PAYCO,NAVER_EASY_PAY',
    // 모바일 앱 오픈스펙 제외
    platformTypes: queries?.platformTypes ?? 'PC,MOBILE_WEB',
    claimStatusTypes: queries?.claimStatusTypes ?? claimStatusTypesToStr(claimType),
    startYmd: queries?.startYmd ?? addDay(new Date(), -7),
    endYmd: queries?.endYmd ?? getToday(),
    delay: queries?.delay ?? false,
    mallNo: getDefaultMall(queries?.mallNo as string),
    keyword: queries?.keyWord ?? '',
    memberGroupNo: queries?.memberGroupNo ?? '',
    includesReservation: queries?.includesReservation ?? true,
    searchType: queries?.searchType ?? 'CLAIM_NO',
    searchDateType: queries?.searchDateType ?? 'APPLY_YMDT',
    includesHoldDelivery: queries?.includesHoldDelivery ?? true,
    returnWayType: queries?.returnWayType ?? '',
    isMember: queries?.isMember ?? '',
    deliveryCompanyType: queries?.deliveryCompanyType ?? '',
    memberGradeNo: queries?.memberGradeNo ?? 0,
    invoiceNoRegistered: queries?.invoiceNoRegistered ?? '',
    sortType: queries?.sortType ?? '',
    desc: queries?.desc ?? false,
    page: queries?.page ?? 1,
    size: queries?.size ?? 30,
    partnerNo: queries?.partnerNo ? Number(queries?.partnerNo) : null,
  };
};
