































import { Vue, Component, PropSync, Prop, Watch, Ref } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import { CheckType, Grade, MemberGradeDisplayInfo } from 'ncp-api-supporter';
import { convertToCheckBoxGroupOptionType } from '@/views/contents/product/basic/ProductAdd/basicInfo/BasicInfo';
import { CheckBoxGroupOption, RadioBoxGroupOption, ValueType } from '@/helpers/type';
import { ConvertToCheckBoxGroupOption, InputNumber } from '@/types';
@Component({
  components: { RadioGroup, CheckboxGroup },
})
export default class MemberGrade extends Vue {
  @PropSync('memberGroupDisplayInfo', { required: true })
  private memberGroupDisplayInfoSync: MemberGradeDisplayInfo;

  @PropSync('memberGradesModel', { required: true })
  private memberGradesModelSync: string;

  @Prop({ required: true })
  private readonly mallNo: InputNumber;

  @Prop({ required: true, default: false })
  private readonly disabled: boolean;

  private radioOption: RadioBoxGroupOption<CheckType> = {
    name: 'memberGradeDisplayYn',
    data: [
      {
        id: 'NONE',
        name: 'memberGradeDisplayYn',
        value: 'NONE',
        display: 'PRODUCT.COMMON.FOR_ALL',
      },
      {
        id: 'ALL',
        name: 'memberGradeDisplayYn',
        value: 'ALL',
        display: 'PRODUCT.COMMON.FOR_MEMBER',
      },
      {
        id: 'ANY',
        name: 'memberGradeDisplayYn',
        value: 'ANY',
        display: 'PRODUCT.COMMON.FOR_SPECIAL',
      },
    ],
  };

  private get isSelectedMall(): boolean {
    return this.mallNo > 0;
  }

  @Ref()
  private readonly exposeGradeConfig: RadioGroup;
  public focus(): void {
    this.exposeGradeConfig.focus();
  }

  private alertNotice(): void {
    if (this.isSelectedMall) return;
    alert(this.$t('PRODUCT.ADD.SELECT_MALL'));
    this.$emit('focusOnMallSelect');
  }

  @Watch('mallNo')
  private async fetchGrades(): Promise<void> {
    if (!this.isSelectedMall) return;

    const { data } = await this.$api.getMemberGrades({ params: { mallNo: Number(this.mallNo) } });
    this.grades = data;
    this.memberGroupDisplayInfoSync.info.concat(
      this.grades.map(grade => {
        if (grade.used) {
          return grade.no;
        }
      }),
    );

    if (this.memberGroupDisplayInfoSync.check === 'ALL' && this.memberGroupDisplayInfoSync.info.length === 0) {
      // 수정일 때 'ALL' 이면 전체 회원번호가 오지 않는다
      const gradeNos = data.map(({ no }) => no);
      this.memberGroupDisplayInfoSync.info = gradeNos;
      this.memberGradesModelSync = gradeNos.join(',');
      this.memberGradesModelSync.substring(0, gradeNos.length - 1);
    }
  }

  private grades: Grade[] = [];
  private changedMemberGradeDisplay(curr: MemberGradeDisplayInfo['check']) {
    if (curr === 'ANY') {
      this.memberGradesModelSync = this.grades.reduce((acc, curr) => (acc += curr.no + ','), '');
      this.memberGradesModelSync.substring(0, this.memberGradesModelSync.length - 1);
    }
  }

  private get getGradesOption(): CheckBoxGroupOption<ValueType> {
    const option: ConvertToCheckBoxGroupOption<Grade> = {
      name: 'memberGradeCheck',
      hasAll: true,
      noBrackets: true,
      data: this.grades,
      valueKey: 'no',
      displayName: 'name',
    };
    return convertToCheckBoxGroupOptionType(option);
  }

  private onCheckedGrades(value): void {
    this.memberGroupDisplayInfoSync.info = value === '' ? [] : value.split(',');
  }

  private mounted() {
    this.fetchGrades();
  }
}
