













import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import AppModalHeader from '@/components/popup/app/AppModalHeader.vue';

@Component({
  components: { AppModalHeader, ModalHeader },
})
export default class AppModal extends Vue {
  @Prop()
  private readonly modalTitle!: string;
}
