export default Object.freeze({
  // DISPLAY_POPUP_
  LIST: '팝업 관리',
  DISPLAY_YMDT: '전시기간',
  NAME: '팝업명',
  ADD_POPUP: '팝업 등록',
  PLATFORM_TYPE: '플랫폼별 노출 설정',
  PLATFORM_TYPE_PC: 'PC',
  PLATFORM_TYPE_MOBILE_WEB: '모바일 웹',
  PLATFORM_TYPE_MOBILE_APP: '모바일 앱',
  APPLY_PAGE_TYPE: '적용 페이지 설정',
  PAGE_TYPE_MAIN_1: '메인 페이지',
  PAGE_TYPE_MAIN: '메인페이지',
  PAGE_TYPE_CATEGORY: '전시 카테고리',
  PAGE_TYPE_EVENT: '기획전',
  PAGE_TYPE_PRODUCT: '상품',
  DISPLAY_YN_Y: '전시가능',
  DISPLAY_YN_N: '전시중지',
  DISPLAY_YN_NONE: '전시안함',
  DEL_MSG: '삭제하시겠습니까?',
  DETAIL: '팝업 등록/수정',
  BASIC_INFO: '기본정보',
  SIZE: '사이즈',
  WIDTH: '가로',
  HEIGHT: '세로',
  POPUP_TYPE: '형태',
  POPUP_TYPE_WINDOW: '윈도우 팝업',
  POPUP_TYPE_LAYER: '레이어 팝업',
  DISPLAY_TYPE_ALL: '전체 노출',
  DISPLAY_TYPE_CUSTOMIZED: '설정',
  CATEGORY_ALL: '전체 전시 카테고리',
  CATEGORY_PART: '일부 전시 카테고리',
  SELECTED_CATEGORY: '추가한 전시 카테고리',
  ERR_CAREGORY_REPEAT: '이미 추가된 전시 카테고리입니다.',
  EVENT_ALL: '전체 기획전',
  EVENT_PART: '일부 기획전',
  EVENT_PLACEHOLDER: '기획전번호 입력',
  SELECTED_EVENT: '추가한 기획전',
  PRODUCT_ALL: '전체 상품',
  PRODUCT_PART: '일부 상품',
  PRODUCT_SEARCH: '상품조회',
  PRODUCT_SEARCH_POP: '상품 조회',
  PRODUCT_SELECT_DEL: '선택상품 삭제',
  PRODUCT_ALL_DEL: '전체상품 삭제',
  EXPOSURE_PERIOD: '노출 기간',
  CONTENTS: '내용',
  DISPLAY_SPOT: '노출 우선순위',

  DETAIL_NO_DATA: '추가된 상품이 없습니다.',

  APPLY_STATUS_REGISTRATION_READY: '등록대기',
  APPLY_STATUS_APPROVAL_READY: '승인대기',
  APPLY_STATUS_APPROVAL_REJECTION: '승인거부',
  APPLY_STATUS_SALE_AGREEMENT_READY: '판매합의대기',
  APPLY_STATUS_SALE_AGREEMENT_REJECTION: '판매합의거부',
  APPLY_STATUS_AFTER_APPROVAL_READY: '수정 후 승인대기',
  APPLY_STATUS_AFTER_APPROVAL_REJECTION: '수정 후 승인거부',
  APPLY_STATUS_FINISHED: '승인완료',

  SALE_STATUS_PRE_APPROVAL_STATUS: '승인완료이전',
  SALE_STATUS_ON_PRE_SALE: '예약판매중',
  SALE_STATUS_WAITING_SALE: '판매대기',
  SALE_STATUS_ON_SALE: '판매중',
  SALE_STATUS_END_SALE: '판매종료',
  SALE_SETTING_STATUS_AVAILABLE_FOR_SALE: '판매가능',
  SALE_SETTING_STATUS_STOP_SELLING: '판매중지',
  SALE_SETTING_STATUS_PROHIBITION_SALE: '판매금지',

  ERR_EVENT_EMPTY: '기획전을 추가해주세요.',
  ERR_EVENT_NOT_HAVE: '추가 불가한 기획전입니다.',
  ERR_EVENT_REPEAT: '이미 추가된 기획전입니다.',
  ERR_PRODUCT_NO_SELECT: '상품옵션을 선택해주세요.',

  ERR_NAME_EMPYT: '팝업명을 입력해주세요.',
  ERR_WIDTH_EMPYT: '팝업 너비에 숫자를 입력해주세요.',
  ERR_HEIGHT_EMPYT: '팝업 높이에 숫자를 입력해주세요.',
  ERR_DISPLAY_TYPES_EMPYT: '플랫폼별 노출 설정 항목을 입력해 주세요.',
  ERR_PAGE_TYPES_EMPYT: '적용 페이지를 설정해주세요.',
  ERR_CONTENT_EMPYT: '내용을 입력해주세요.',
  ERR_CATEGORY_EMPYT: '전시 카테고리를 설정해주세요.',
  ERR_EVENT_EMPYT: '기획전을 설정해주세요.',
  ERR_PRODUCT_EMPYT: '상품을 설정해주세요.',

  ADD_SUCCESS: '생성되었습니다.',
  EDIT_SUCCESS: '수정되었습니다.',

  HERDER_POPUP_NO: '팝업번호',
  HERDER_POPUP_NAME: '팝업명',
  HERDER_POPUP_SIZE: '사이즈(px)',
  HERDER_POPUP_TYPE: '형태',
  HERDER_POPUP_DISPLAY_TYPE: '플랫폼별 노출설정',
  HERDER_APPLY_PAGE_INFO: '적용 페이지 설정',
  HERDER_DISPLAY_YMDT: '노출기간',
  HERDER_DISPLAY_NO: '우선순위',
  HERDER_DISPLAY_YN: '전시상태',
  HERDER_ADMIN_NAME: '담당자',

  HERDER_PRODUCT_NO: '상품번호',
  HERDER_PRODUCT_NAME: '상품명',
  HERDER_APPLY_STATUS: '승인상태',
  HERDER_SALE_STATUS: '판매상태',
  HERDER_SALE_SETTING_STATUS: '판매설정',
  HERDER_FRONT_DISPLAY_YN: '전시상태',
});
