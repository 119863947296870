


























import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import EmailHistorySearchForm from '@/views/contents/member/email/EmailHistorySearchForm.vue';
import { GetMailMessage, GetMailMessages, GetMailMessagesRequest, NCPResponse } from 'ncp-api-supporter';
import { getDisplayOptions } from '@/const/contents/sms';
import Grid from '@/components/common/grid/Main.vue';
import { getEmailHistoryGridOption, getInitEmailHistoryQuery } from '@/const/contents/mail';
import { GridEventProps } from '@/types';
import { throwPopup } from '@/helpers/popup';
import { getCurrentMallNo } from '@/utils/mall';
import moment from 'moment';
import { isEmpty, omit } from 'underscore';
import { Route } from 'vue-router';
import { checkSearchResult } from '@/views/contents/member/kakao/SearchTemplates';

@Component({
  components: { EmailHistorySearchForm, Grid },
})
export default class EmailHistory extends Vue {
  @Ref()
  private readonly grid!: Grid;

  private contents: GetMailMessage[] = [];
  private totalCount = 0;
  private gridOption = getEmailHistoryGridOption();
  private displayOptions = getDisplayOptions();

  @Watch('$route')
  private async searchMailHistory(currRoute: Route, prevRoute: Route) {
    const needsReset = checkSearchResult(currRoute, prevRoute, 'MUST_SEARCH_HISTORY', this);
    if (needsReset) return;

    try {
      if (isEmpty(omit(this.$route.query, 'mallNo'))) return;
      const request: GetMailMessagesRequest = {
        params: { ...getInitEmailHistoryQuery(getCurrentMallNo(this)), ...omit(this.$route.query, 'dataRefresh') },
      };
      const { data }: NCPResponse<GetMailMessages> = await this.$api.getMailMessages(request);

      const dateTime = (a: GetMailMessage, b: GetMailMessage) =>
        moment(b.requestDateTime)
          .toDate()
          .getTime() -
        moment(a.requestDateTime)
          .toDate()
          .getTime();
      this.contents = data.data.sort(dateTime);
      this.totalCount = data.totalCount;
      this.grid.changeNoDataMessage(this.$t('NO_RESULT'));
    } catch (e) {
      console.error(e);
    }
  }

  private onItemClicked(gridProps: GridEventProps) {
    if (gridProps.columnName !== 'title') return;

    const { requestId, sequence } = this.contents.filter((_, index) => gridProps.rowKey === index)[0];
    throwPopup({
      name: 'EmailHistoryDetail',
      data: { requestId, sequence },
    });
  }

  mounted() {
    this.grid.changeNoDataMessage(this.$t('MUST_SEARCH_HISTORY').toString());
  }
}
