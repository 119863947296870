












import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { NCPResponse, GetAdminsServiceMerchandisersRequest, Mds } from 'ncp-api-supporter';
import { OptionData } from '@/helpers/type';

@Component({
  components: {
    SelectBox,
  },
})
export default class MerchandiserSelect extends Vue {
  @Model('change') value!: number;
  @Prop({ default: '150px' })
  private width!: string;
  @Prop({ required: true })
  private readonly mallNo!: string;
  @Prop({ required: false, default: false })
  private disabled!: boolean;

  private selectedValue = 0;
  private readonly defaultSelectOption: OptionData<number> = {
    name: this.$t('PRODUCT.SEARCH.MERCHANDISER') as string,
    value: 0,
  };
  private selectOptions: OptionData<number>[] = [this.defaultSelectOption];

  focus() {
    (this.$refs.selectBoxRef as SelectBox).focus();
  }

  created() {
    if (this.mallNo && this.mallNo !== '0') {
      this.fetchMerchandisers(this.mallNo).then(() => this.checkBeforeSelection());
    }
  }

  @Watch('mallNo')
  private async onChangeMallNo(mallNo) {
    await this.fetchMerchandisers(mallNo);
    mallNo === '' ? this.$nextTick(this.checkBeforeSelection) : this.$nextTick(this.resetSelectedValue);
  }

  private resetSelectedValue(): void {
    this.selectedValue = 0;
  }

  private async fetchMerchandisers(mallNo: string): Promise<void> {
    const request: GetAdminsServiceMerchandisersRequest = {
      params: {
        mallNo: mallNo ? mallNo : '0', // mallNo 0 으로 넘기면 전체조회.... API 스팩
      },
    };

    const { data }: NCPResponse<Mds[]> = await this.$api.getAdminsServiceMerchandisers(request);

    this.setSelectOptions(data);
  }

  private setSelectOptions(data: Mds[]): void {
    this.selectOptions = [
      this.defaultSelectOption,
      ...data.map(({ adminNo, name, id }) => ({ value: adminNo, name: name + `(${id})` })),
    ];
  }

  private checkBeforeSelection(): void {
    if (!this.value) return;
    this.selectedValue = this.value;
  }
}
