


































































import { Vue, Component } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import store from '@/store';
import { addMonth } from '@/utils/dateFormat';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { Entry } from '@/types/main';
import { mall } from '@/utils/mall';
import { namespace } from 'vuex-class';
import { getHref } from '@/utils/route';
import { Menu } from 'ncp-api-supporter';

const menuStore = namespace('menu');
const summaryStore = namespace('summary');

@Component({
  directives: {
    ClickOutside,
  },
})
export default class Notificator extends Vue {
  @menuStore.Getter('getMenus')
  private readonly menus: Menu[]; // TODO: PRO 메뉴 API 나오면 권한별 알림 노출 구현
  @summaryStore.Getter('getNotificationSummary')
  private readonly summary;

  private getHref = getHref;

  private show = false;
  private totalCount = 0;

  private soldoutQuery = '{"searchPreset":"soldOutCnt"}';

  async created() {
    await this.fetchSummaryData();
    this.setTotalCount();
  }

  private setTotalCount(): void {
    this.totalCount = Object.entries(this.summary).reduce(
      (acc, [_, value]: [string, number]): number => acc + value,
      0,
    );
  }

  private getInitialEntry(): Entry[] {
    const entry: Entry[] = [];

    if (!store.getters['summary/getProductInquirySummary']) entry.push({ type: 'summary/fetchProductInquiry' });
    if (!store.getters['summary/getProductSummaries']) entry.push({ type: 'summary/fetchProductSummaries' });
    if (!store.getters['summary/getManageInquirySummary']?.issued) {
      entry.push({
        type: 'summary/fetchManageInquiresSummary',
        payload: { startDateTime: `${addMonth(new Date(), -1)} ${DEFAULT_TIME_RANGE.START}`, statuses: 'ISSUED' },
      }); // 1차 오픈은 프리미엄 스팩 따름. default 1개월
    }
    if (!store.getters['summary/getClaimInquirySummary']?.issued) {
      entry.push({
        type: 'summary/fetchClaimInquiresSummary',
        payload: { mallNos: mall.allMallNoString, period: 3, unit: 'MONTH' },
      }); // 1차 오픈은 프리미엄 스팩 따름. default 3개월
    }

    return entry;
  }

  async fetchSummaryData(): Promise<boolean> {
    try {
      await Promise.all(this.getInitialEntry().map(dispatch => store.dispatch(dispatch.type, dispatch.payload)));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  onClickOutside(): void {
    this.show = false;
  }
}
