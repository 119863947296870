import { i18n } from '@/main';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;
import {
  CombinationOptionType,
  GridProp,
  MappingType,
  OptionSelectBoxType,
  RadioRendererEventHandlerType,
} from '@/types';
import { RadioRenderer } from '@/utils/grid/rendererUtils';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { RowKey } from '@/types/tui-grid';
import { range1ToNI18n } from '@/utils/common';
import { getNegativeNum, getOnlyNumbers } from '@/utils/numberFormat';
import { GetProductsConfigurableRequest } from 'ncp-api-supporter/dist/types';

export const convertToI18n = (message: string, key = 'PRODUCT.OPTION.'): LocaleMessage => {
  return i18n.t(key + message);
};

export const getOptions = () => {
  return Array(6)
    .fill(null)
    .map((_, index) => {
      return index === 0
        ? { value: index, name: convertToI18n('NOT_USED') }
        : { value: index, name: `${index}${i18n.t('EACH')}` };
    });
};

export const toolTipMessage = () => `
               <div class="tool-tip-cont" style="width: 500px;">
                  <ul class="notice-list type-2">
                    <li>${convertToI18n('COMBINATION_TOOL_TIP1')}</li>
                    <li>${convertToI18n('COMBINATION_TOOL_TIP2')}</li>
                    <li>${convertToI18n('COMBINATION_TOOL_TIP3')}</li>
                  </ul>
                </div>`;

export const normalOptionTableHeaders = [
  'PRODUCT.OPTION.OUT_OF_STOCK_STATUS',
  'PRODUCT.OPTION.RESERVATION_STOCK_CNT',
  'PRODUCT.OPTION.STOCK_CNT',
  'PRODUCT.OPTION.WAITING_FOR_DELIVERY',
  'PRODUCT.OPTION.IMAGE',
  'PRODUCT.OPTION.WHETHER_TO_USE',
  'PRODUCT.OPTION.OPTION_NO',
  'PRODUCT.OPTION.STOCK_NO',
  'PRODUCT.OPTION.OPTION_MANAGEMENT_CD',
  'PRODUCT.OPTION.DELETE',
];

export const setOptionTableHeaders = [
  'PRODUCT.OPTION.OUT_OF_STOCK_STATUS',
  'PRODUCT.OPTION.RESERVATION_STOCK_CNT',
  'PRODUCT.OPTION.STOCK_CNT',
  'PRODUCT.OPTION.OPTION_NO',
  'PRODUCT.OPTION.IMAGE',
  'PRODUCT.OPTION.WHETHER_TO_USE',
  'PRODUCT.OPTION.SET_CONFIGURATION_BTN',
  'PRODUCT.OPTION.PRODUCT_NAME',
  'PRODUCT.OPTION.NAME_PRICE',
  'PRODUCT.OPTION.SALE_PRICE',
  'PRODUCT.OPTION.STOCK',
  'PRODUCT.OPTION.COUNT',
  'PRODUCT.OPTION.SELLING_PRICE',
];

const useYn = [
  {
    name: 'Y',
    value: 'Y',
  },
  {
    name: 'N',
    value: 'N',
  },
];

export const STOCK_STATUS_TYPE = {
  SOLD_OUT: 'SOLD_OUT',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  AVAILABLE: 'AVAILABLE',
} as const;

export const setOptionSaleStatusTypeOptions = [
  {
    name: 'PRODUCT.OPTION.OUT_OF_STOCK',
    value: STOCK_STATUS_TYPE.OUT_OF_STOCK,
  },
  {
    name: 'PRODUCT.OPTION.NORMAL',
    value: STOCK_STATUS_TYPE.AVAILABLE,
  },
];

export const selectOptions = {
  saleStatusType: (isMapping: boolean): OptionData<keyof typeof STOCK_STATUS_TYPE>[] => {
    if (isMapping) {
      return setOptionSaleStatusTypeOptions;
    } else {
      return [
        {
          name: 'PRODUCT.OPTION.SOLD_OUT',
          value: STOCK_STATUS_TYPE.SOLD_OUT,
        },
        ...setOptionSaleStatusTypeOptions,
      ];
    }
  },
  useYn: useYn,
};

export const textOptionOptions = {
  useYn: useYn,
  requiredYn: [
    {
      name: 'PRODUCT.OPTION.SELECT',
      value: false,
    },
    {
      name: 'PRODUCT.OPTION.REQUIRED',
      value: true,
    },
  ],
  matchingType: [
    {
      name: 'PRODUCT.OPTION.EACH_PRODUCT',
      value: 'PRODUCT',
    },
    {
      name: 'PRODUCT.OPTION.EACH_OPTION',
      value: 'OPTION',
    },
  ],
};

export const optionTypeRadioOption: RadioBoxGroupOption<string> = {
  name: 'optionSelectType',
  data: [
    { id: 'multi-option-type', value: 'MULTI', display: 'PRODUCT.OPTION.MULTI_TYPE' },
    { id: 'flat-option-type', value: 'FLAT', display: 'PRODUCT.OPTION.FLAT_TYPE' },
  ],
};

export const defaultOptionsToModify = {
  addPrice: null,
  purchasePrice: null,
  saleStatusType: '',
  reservationStockCnt: null,
  stockCnt: null,
  useYn: '',
};

//other product option
export const getOtherProductOptionGridProps = (radioEventHandler: RadioRendererEventHandlerType): GridProp => {
  return {
    columns: [
      {
        header: 'PRODUCT.COMMON.PRODUCT_NO',
        name: 'productNo',
        align: 'center',
        minWidth: 100,
      },
      {
        header: 'PRODUCT.COMMON.PRODUCT_NAME',
        name: 'productName',
        minWidth: 100,
        align: 'center',
      },
    ],
    header: {
      height: 40,
    },
    options: {
      rowHeaders: [
        {
          name: 'radioBtn',
          type: 'radio',
          header: convertToI18n('SELECT'),
          renderer: {
            type: RadioRenderer,
            options: {
              eventHandler: radioEventHandler,
            },
          },
        },
      ],
      pageOptions: {
        page: 1,
        perPage: 10,
        totalCount: 0,
      },
    },
  };
};

export const otherProductOptionGridContainerOptions = {
  showsRightArea: false,
  pageSizeKey: 'size',
};

export const resetProductConfigurable = (): GetProductsConfigurableRequest['params'] => ({
  productNo: null,
  productName: '',
  standardCategoryViewNo: null,
  displayCategoryViewNo: null,
  page: 1,
  size: 10,
  mallNo: 0,
});

export const isEmptyValue = (objectList: object[], value: string): boolean => {
  return objectList.some(obj => obj[value] === '');
};
//객체의 빈값 제거 (undefined, null, '', 빈객체, 빈배열)
//중첩된 객체의 빈값도 제거
export const removeEmptyValueOfObject = (obj: Record<string, any>): any => {
  return Object.keys(obj).reduce((acc, current) => {
    if (obj[current]?.constructor === Object || obj[current]?.constructor === Array) {
      const removedObj = removeEmptyValueOfObject(obj[current]);
      obj[current] = Object.keys(removedObj).length === 0 ? null : removedObj;
    }

    if (obj[current] !== null && obj[current] !== '' && obj[current] !== undefined) {
      return { ...acc, [current]: obj[current] };
    }
    return acc;
  }, {});
};

export function isMoreThanImmediateDiscountPrice(value: number): boolean {
  if (value >= 0) return false;
  return Math.abs(value) > this.immediateDiscountPrice;
}

export const isNotTenUnit = (value: number): boolean => {
  if (value === undefined || value === null) return false;
  return value % 10 !== 0;
};

export const validateTenUnit = (value: string): boolean => {
  if (value === '' || !isNotTenUnit(getOnlyNumbers(value))) return true;
  alert(convertToI18n('TIP_OPTION_PRICE_10'));
  return false;
};

export const SEPARATOR = '|';

export const defaultOptionInfo = {
  optionPrice: 0,
  stockCnt: 0,
  managementCd: '',
  purchasePrice: 0,
};

export const excelColumn = () => ({
  optionValue: convertToI18n('OPTION_VALUE'),
  addPrice: convertToI18n('OPTION_PRICE'),
  purchasePrice: convertToI18n('SUPPLY_PRICE'),
  stockCnt: convertToI18n('STOCK_CNT'),
  optionManagementCd: convertToI18n('OPTION_MANAGEMENT_CD'),
  mainImageUrl: convertToI18n('REPRESENT_IMG'),
  detailImageUrl: convertToI18n('DETAIL_IMG'),
  useYn: convertToI18n('WHETHER_TO_USE'),
});

export const getDefaultCombinationOptionExcel = (): CombinationOptionType<RowKey[]> => ({
  addPrice: 0,
  purchasePrice: 0,
  optionValue: [],
  stockCnt: 0,
  optionManagementCd: '',
  mallOptionNo: 0,
  reservationStockCnt: 0,
  useYn: 'Y',
  optionImages: [],
  stockNo: 0,
});

export const getToolTipMessageList = (size: number, message: string): string => {
  const toolTipMessageList = range1ToNI18n(size, message).map(message => `<li>${i18n.t(message)}</li>`);
  return `
     <div class="tool-tip-cont" style="width: 600px;">
        <ul class="notice-list type-2">${toolTipMessageList.join('')}</ul>
     </div>`;
};

export const getSetOptions = (): OptionSelectBoxType[] => [
  { value: 1, name: `1${i18n.t('EACH')}` },
  { value: 2, name: `2${i18n.t('EACH')}` },
];

export const defaultMappingInfo = (): MappingType => ({
  mallOptionNo: 0,
  mallProductNo: 0,
  mappingCount: null,
  salePrice: null,
  combinedOptionNameValue: '',
  optionSalePrice: null,
  productName: '',
  stockCnt: null,
});

// 품절상태부터 끝까지
export const setOptionTableWidth = [
  '150px',
  '150px',
  '100px',
  '100px',
  '100px',
  '100px',
  '150px',
  '200px',
  '250px',
  '100px',
  '100px',
  '150px',
  '150px',
];

export const validateOptionPrice = (value: string): string => {
  const negativeNum = getNegativeNum(value);
  const maxLength = negativeNum.startsWith('-') === true ? 10 : 9;
  return negativeNum.length > maxLength ? negativeNum.slice(0, -1) : negativeNum;
};
