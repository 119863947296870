

















import { Vue, Component, Prop, Model, Ref } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { namespace } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
import { mall } from '@/utils/mall';

const mallStore = namespace('mall');

interface MallItem {
  mallNo: string;
  mallName: string;
}

@Component({
  components: {
    SelectBox,
  },
})
export default class MallSelect extends Vue {
  @Prop({ required: false, default: false })
  private readonly allSelect!: boolean;
  @Prop({ required: false, default: 'ALL' })
  private readonly allSelectText!: string;
  @Prop({ required: false, default: '' })
  private readonly allSelectValue!: string;
  @Prop({ required: false, default: false })
  private readonly sortByCreation!: boolean;
  @Prop({ required: false, default: false })
  private readonly needAll: boolean;
  @Prop({ required: false, default: 'PRODUCT.ADD.SELECT_MALL' })
  private readonly needAllText: string;
  @Prop({ default: false })
  private readonly disabled!: boolean;
  @Prop({ default: () => [], required: false })
  private readonly mallsData: MallItem[];

  @Model('change')
  private readonly value!: string;
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];
  @Ref() private readonly selectBox: SelectBox;

  private mallList: MallItem[] = [];

  created() {
    this.init();
  }

  private mounted() {
    this.replaceMallList();
    this.updateSelectedValue(`${this.mallList[0].mallNo}`);
  }

  get selectedValue() {
    return this.value || '';
  }

  set selectedValue(val) {
    this.$emit('change', val, this.value);
  }

  private updateSelectedValue(mallNo: string) {
    this.selectedValue = mallNo;
  }

  private replaceMallList() {
    if (!this.mallsData.length) {
      return;
    }

    this.mallList = [...this.mallsData];
  }

  private init() {
    if (!this.malls) throw new Error('malls undefined');
    const mapper = (malls: Mall[]): MallItem[] =>
      malls.map(({ mallName, mallNo }: Mall) => ({
        mallName,
        mallNo: mallNo.toString(),
      }));
    const allSelect = { mallName: this.$t(this.allSelectText).toString(), mallNo: this.allSelectValue };

    const malls: Mall[] = this.sortByCreation ? mall.mallsSortedByCreationDate : this.malls;
    if (malls.length > 1) {
      this.mallList = this.allSelect ? [allSelect].concat(mapper(malls)) : mapper(malls);
      return;
    }

    this.mallList = mapper(malls);
  }

  public focus(): void {
    this.selectBox.focus();
  }
}
