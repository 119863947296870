

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetMailMessagesView, GetMailMessagesViewRequest, NCPResponse } from 'ncp-api-supporter';
import { getCurrentMallNo } from '@/utils/mall';

@Component
export default class EmailHistoryDetail extends Vue {
  @Prop() private readonly onNegativeClick!: () => void;
  @Prop() private readonly data!: { requestId: string; sequence: number };
  private title = '';
  private content = '';
  private secret = false;

  private async getMailData() {
    const request: GetMailMessagesViewRequest = {
      params: {
        mallNo: getCurrentMallNo(this),
        requestId: this.data.requestId,
        seq: this.data.sequence,
      },
    };
    const response: NCPResponse<GetMailMessagesView> = await this.$api.getMailMessagesView(request);
    this.title = response.data.title;
    this.content = response.data.content;
    this.secret = response.data.secret;
  }

  created() {
    this.getMailData();
  }
}
