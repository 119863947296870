

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { MemberPopupType } from '@/const/contents/memberPopup';
import {
  GetAccumulationsMembersAvailable,
  GetAccumulationsMembersAvailableRequest,
  GetCouponsIssueInMember,
  GetCouponsIssueInMemberRequset,
  GetOrdersByUser,
  GetOrdersByUserRequest,
  NCPResponse,
  PutMembersGradeRequest,
  PutWithDrawalMembersRequest,
} from 'ncp-api-supporter';
import { getMallName } from '@/utils/mall';
import TargetMemberList from '@/components/member/TargetMemberList.vue';
import SelectBox from '@/components/common/SelectBox.vue';

/**
 * 레이어 팝업
 * 회원등급 변경, 회원 강제탈퇴
 * */
@Component({
  components: {
    SelectBox,
    TargetMemberList,
    TextInput,
  },
})
export default class MemberGradeWithdrawalPopup extends Vue {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: () => void;
  @Prop() private readonly onNegativeClick!: () => void;

  // 회원 등급
  private selectGrade = 0;
  // 사유
  private reasonText = '';
  private hasCoupon = false;
  private hasAccumulation = false;
  private hasOrder = false;

  // 옵션 데이터
  get optionData() {
    return this.data.option.optionData[0];
  }

  get mallName() {
    return this.data.contents[0].mallName ? this.data.contents[0].mallName : getMallName(this.data.contents.mallNo);
  }

  // 레이어 타이틀
  get headerText(): string {
    return this.optionData.headerText ? this.optionData.headerText : this.$t('ALERT');
  }

  // TOP 가이드
  get topNoticeMessage(): object[] {
    return this.optionData.topNoticeMessage ? this.optionData.topNoticeMessage : [];
  }

  // 변경사유 타이틀
  get reasonTitle(): string {
    return this.optionData.reasonData.thTitle ? this.optionData.reasonData.thTitle : '';
  }

  private async onSaveClick() {
    if (this.isValidate()) {
      await this.sendApiRequest();
    }
  }

  private isValidate() {
    switch (this.data.type) {
      case MemberPopupType.GRADE:
      case MemberPopupType.MEMBER_MODIFY_GRADE:
        return this.validateChangeGrade();
      case MemberPopupType.WITHDRAWAL:
      case MemberPopupType.MEMBER_MODIFY_WITHDRAWAL:
        return this.validateChangeWithdrawal();
    }
    return true;
  }

  private validateChangeGrade(): boolean {
    if (this.selectGrade === 0) {
      alert(this.$t('MEMBER.POPUP.ALERT_GRADE_NO_NULL'));
      return false;
    } else {
      return true;
    }
  }

  private validateChangeWithdrawal(): boolean {
    if (this.reasonText.trim().length === 0) {
      alert(this.$t('MEMBER.POPUP.WITHDRAWAL_REASON'));
      return false;
    }
    if (this.hasOrder) {
      return confirm(this.$t('MEMBER.POPUP.ALERT_HAS_ORDER').toString());
    }
    if (this.hasCoupon || this.hasAccumulation) {
      return confirm(this.$t('MEMBER.POPUP.ALERT_HAS_BENEFIT').toString());
    }

    return confirm(this.$t('MEMBER.POPUP.CONFIRM_WITHDRAWAL').toString());
  }

  private init() {
    switch (this.data.type) {
      case MemberPopupType.GRADE:
      case MemberPopupType.MEMBER_MODIFY_GRADE:
        this.getMemberGrade();
        break;
      case MemberPopupType.WITHDRAWAL:
      case MemberPopupType.MEMBER_MODIFY_WITHDRAWAL:
        this.getMemberStatus();
        break;
    }
  }

  created(): void {
    this.init();
  }

  private async getMemberGrade() {
    const gradesResponse = await this.$api.getMemberGrades({ params: { mallNo: this.data.mallNo } });
    if (gradesResponse && gradesResponse.data) {
      this.memberGrades = await gradesResponse.data.sort((a, b) => (a.order > b.order ? 1 : -1));
    }
  }

  private async getMemberStatus() {
    await Promise.all([this.fetchAccumulationAvailable(), this.fetchCouponAvailable(), this.fetchOrdernAvailable()]);
  }

  private async fetchAccumulationAvailable() {
    const request: GetAccumulationsMembersAvailableRequest = {
      params: {
        mallNo: this.data.mallNo,
        memberNos: this.data.memberNos.join(','),
      },
    };
    const { data }: NCPResponse<GetAccumulationsMembersAvailable[]> = await this.$api.getAccumulationsMembersAvailable(
      request,
    );
    this.hasAccumulation = data.length > 0;
  }

  private async fetchCouponAvailable() {
    const request: GetCouponsIssueInMemberRequset = {
      params: {
        memberNos: this.data.memberNos.join(','),
      },
    };
    const { data }: NCPResponse<GetCouponsIssueInMember[]> = await this.$api.getCouponsIssueInMembers(request);
    this.hasCoupon = data.length > 0;
  }

  private async fetchOrdernAvailable() {
    const request: GetOrdersByUserRequest = {
      params: {
        memberNos: this.data.memberNos.join(','),
      },
    };
    const { data }: NCPResponse<GetOrdersByUser[]> = await this.$api.getOrdersByUser(request);
    this.hasOrder = data.length > 0;
  }

  // 회원등급
  private memberGrades = null;
  private get grades() {
    if (!this.memberGrades) return [];
    return this.memberGrades
      .filter(({ used }) => used)
      .sort((c1, c2) => {
        if (c2.order === 0) {
          return -1;
        }
        if (c1.order === 0) {
          return 1;
        }
      });
  }

  private alertResult(response, type: string) {
    if (response.status === 200) {
      let alertMsg = '';
      if (response.data.successCount > 0) {
        alertMsg = this.$t(`MEMBER.POPUP.${type}_SUCCESS_MSG`, [response.data.successCount]) as string;
      }
      if (response.data.failCount > 0) {
        for (let i = 0; i < response.data.failures.length; i++) {
          alertMsg += this.$t(`MEMBER.POPUP.${type}FAIL_MSG`, [
            response.data.failCount,
            response.data.failures[i].reason,
          ]);
        }
      }
      alert(alertMsg);
      this.onPositiveClick();
    }
  }

  private async sendApiRequest(): Promise<void> {
    switch (this.data.type) {
      case MemberPopupType.GRADE:
      case MemberPopupType.MEMBER_MODIFY_GRADE:
        try {
          const response = await this.$api.putMembersGrade(this.gradeRequest);
          this.alertResult(response, MemberPopupType.GRADE);
        } catch (err) {
          console.error(err);
          throw err;
        }
        break;
      case MemberPopupType.WITHDRAWAL:
      case MemberPopupType.MEMBER_MODIFY_WITHDRAWAL:
        try {
          const response = await this.$api.putWithdrawalMembersGrade(this.withdrawalRequest);
          this.alertResult(response, MemberPopupType.WITHDRAWAL);
        } catch (err) {
          console.error(err);
          throw err;
        }
        break;
    }
  }

  private get gradeRequest(): PutMembersGradeRequest {
    return {
      data: {
        reason: this.reasonText,
        gradeNo: this.selectGrade,
        memberNos: this.data.memberNos,
      },
    };
  }

  private get withdrawalRequest(): PutWithDrawalMembersRequest {
    return {
      data: {
        mallNo: this.data.mallNo,
        memberNos: this.data.memberNos,
        sendType: 'ALL',
        reason: this.reasonText,
      },
    };
  }
}
