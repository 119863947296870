
import { Vue, Component } from 'vue-property-decorator';
import { getCurrency } from '@/utils/common';
import { getOnlyNumbers } from '@/utils/numberFormat';
import { InputNumber } from '@/types';
@Component
export default class NumberWithCommaFormatterMixin extends Vue {
  protected formattedNumber: InputNumber = '';

  protected getCurrency = getCurrency;

  protected updateValue({ target }: { target: HTMLInputElement }, useFormattedNumber = true): void {
    if (!useFormattedNumber) return;

    if (target.name) {
      this[target.name] = getOnlyNumbers(target.value);
      return;
    }

    this.formattedNumber = getOnlyNumbers(target.value);
  }

  protected valueChangedReplaceHook(inputNumber: string): string {
    if (inputNumber === '') {
      return inputNumber;
    }

    if (inputNumber.includes('.')) {
      const decimal = inputNumber.split('.')[1];
      return decimal.length > 1 ? inputNumber.substring(0, inputNumber.length - 1) : inputNumber;
    }

    return getOnlyNumbers(inputNumber).toString();
  }
}
