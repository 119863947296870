


































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  Mall,
  ContractedPartner,
  Mds,
  OriginProductInfo,
  InspectionProductInfo,
  PlatformDisplayInfo,
  SaleMethodType,
  PromotionTextInfo,
  RelatedProductInformation,
} from 'ncp-api-supporter';
import { getMallName } from '@/utils/mall';
import TextInput from '@/components/common/input/TextInput.vue';
import { GetInspectionsView, Item, Reason } from 'ncp-api-supporter/dist/types/modules/product/product';
import { APPLY_STATUS } from '@/const/common';
import { getCheckKeysBy } from '@/const/contents/product';

@Component({
  components: { TextInput },
})
export default class BasicTable extends Vue {
  @Prop({ required: true }) private readonly inspectionView?: GetInspectionsView;
  @Prop({ required: true }) private readonly isApprovalReady?: boolean;
  @Prop({ required: true }) private readonly isAfterApprovalReady?: boolean;
  private readonly originProductInfo?: OriginProductInfo = this.inspectionView?.originProductInfo;
  private readonly inspectionProductInfo?: InspectionProductInfo = this.inspectionView?.inspectionProductInfo;
  private readonly modifiedParam: string[] = this.inspectionView?.modifiedParam || [];
  private readonly inspectionStatus: string = this.inspectionView?.inspectionStatus || '';

  @Getter('mall/getMalls') private readonly malls!: Mall[]; //쇼핑몰
  private partners: ContractedPartner[] = []; //파트너사
  private admins: Mds[] = []; //담당자

  private basicInfo = {
    mallNo: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.MALL'), ref: 'mallNo' },
    partnerNo: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.PARTNER'), ref: 'partnerNo' },
    saleMethodType: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.SALE_METHOD_TYPE'),
      ref: 'saleMethodType',
    },
    adminNo: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.ADMIN'), ref: 'adminNo' },
    categoryName: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.CATEGORY'), ref: 'categoryName' },
    displayCategoryNames: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.DISPLAY_CATEGORY'),
      ref: 'displayCategoryNames',
    },
    platformDisplayInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.PLATFORM_DISPLAYINFO'),
      ref: 'platformDisplayInfo',
    },
    productName: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.PRODUCT_NAME'), ref: 'productName' },
    promotionTextInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.PROMOTION_TEXT'),
      ref: 'promotionTextInfo',
    },
    brandName: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.BRAND'), ref: 'brandName' },
    minorPurchaseYn: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.MINOR_PURCHASE_YN'),
      ref: 'minorPurchaseYn',
    },
    paymentMeansControlYn: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.PAYMENT_MEANS'),
      ref: 'paymentMeansControlYn',
    },
    keywords: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.KEYWORDS'),
      ref: 'keywords',
    },
    urlShorteningYn: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.ADD.URL_USE_YN'),
      ref: 'urlShorteningYn',
    },
    relatedProductInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.ADD.RELATED_PRODUCT_CONFIG'),
      ref: 'relatedProductInfo',
    },
  };

  get isExposureBasicInfo() {
    return (
      this.modifiedParam.includes('naverPayLimitYn') ||
      this.modifiedParam.some(param => this.basicInfo?.hasOwnProperty(param)) ||
      this.inspectionStatus === APPLY_STATUS.APPROVAL_READY
    );
  }

  private getRelatedProductText(info: RelatedProductInformation | null) {
    if (!info) return this.$t('PRODUCT.COMMON.NOT_USE');

    const { configType, sortCriterion } = info;
    const config = this.$t(`PRODUCT.ADD.${configType}`);
    const sort = this.$t(`PRODUCT.COMMON.${sortCriterion}`);
    return `${config}, ${sort}`;
  }

  //노출항목
  private isExposureItem(modifiedItem: string): boolean {
    //승인대기
    if (this.inspectionStatus === APPLY_STATUS.APPROVAL_READY) return true;
    //수정후 승인대기
    if (this.inspectionStatus === APPLY_STATUS.AFTER_APPROVAL_READY) {
      return this.modifiedParam.includes(modifiedItem);
    }
    return false;
  }

  @Watch('basicInfo', { deep: true })
  private getReasons() {
    const checked = getCheckKeysBy(this.basicInfo);
    const reasons: Reason[] = [];
    const basicReasons: Item[] = checked.map((key: string) => ({
      item: this.basicInfo[key].name.toString(),
      detail: {
        data: (this.$refs[this.basicInfo[key].ref] as Element).outerHTML,
        comment: this.basicInfo[key].reason,
      },
    }));
    reasons.push({ section: 'BASIC_INFO', items: basicReasons });
    this.$emit('reasons', reasons);
  }

  //쇼핑몰
  private getMallName(mallNo: number): string {
    return getMallName(mallNo);
  }

  //파트너사
  private getPartnerName(partnerNo: number): string {
    const partner = this.partners.find(item => item.partnerNo === partnerNo);
    //쇼핑몰 자체 상품
    if (partner?.partnerType === 'SHOPBY') return this.$t('PARTNER.COMMON.SHOP') as string;
    //파트너사
    else return partner ? partner.partnerName : '';
  }

  //판매방식
  private getSaleMethodTypeName(saleMethodType: SaleMethodType): string {
    return saleMethodType ? (this.$t(`PRODUCT.COMMON.SALE_METHOD_TYPE_${saleMethodType}`) as string) : '';
  }

  //담당자
  private getAdminName(adminNo: number): string {
    const admin = this.admins.find(admin => admin.adminNo === adminNo);
    return admin ? admin.name : '';
  }

  //플랫폼 노출 설정 : 프로는 모바일 앱 설정 미제공
  getPlatformDisplayInfoName(platformDisplayInfo: PlatformDisplayInfo): string {
    let name = '';
    if (platformDisplayInfo.displayYn === 'Y') {
      return `${this.$t('ALL')} ${this.$t('PRODUCT.COMMON.DISPLAY')}`;
    }
    if (platformDisplayInfo.pcYn === 'Y') {
      name = 'PC';
    }
    if (platformDisplayInfo.mobileWebYn === 'Y') {
      name = name
        ? `${name}, ${this.$t('PRODUCT.COMMON.PLATFORM_DISPLAYINFO_MOBILEWEB')}`
        : (this.$t('PRODUCT.COMMON.PLATFORM_DISPLAYINFO_MOBILEWEB') as string);
    }
    return ` ${name} ${this.$t('PRODUCT.COMMON.DISPLAY')}`;
  }

  //홍보문구
  private getPromotionText(promotionTextInfo: PromotionTextInfo): string {
    const name = promotionTextInfo.text ? promotionTextInfo.text : '';
    const due = ` | ${this.$t('PRODUCT.COMMON.DUE')} ${promotionTextInfo.startYmd} ~  ${promotionTextInfo.endYmd}`;
    return name && promotionTextInfo.periodYn === 'Y' ? name + due : name;
  }

  //결제수단설정 : 프로는 '네이버페이 결제 불가' 또는 빈칸
  private getPaymentMeansText(paymentMeansControlYn: string, extraJson: any): string {
    if (extraJson.naverPayLimitYn === 'true' || extraJson.naverPayLimitYn === 'Y') {
      return this.$t('PRODUCT.COMMON.NAVER_PAY_LIMIT_Y') as string;
    }
    return '';
  }

  //특정 쇼핑몰에 계약된 파트너 모두 조회
  private async fetchMallsContractedPartnersByMallNo(mallNo?: string) {
    if (!mallNo) return;
    const { data } = await this.$api.getMallsContractedPartnersByMallNo({
      pathParams: { mallNo },
    });
    const filteredPartners = data.filter(
      partner =>
        partner.partnerStatus === 'ACTIVE' &&
        (partner.contractStatus === 'ACTIVE' || partner.contractStatus === 'SUSPEND'),
    );
    this.setMallsContractedPartnersByMallNo(filteredPartners);
  }
  private setMallsContractedPartnersByMallNo(data: ContractedPartner[]) {
    this.partners = data;
  }

  //특정 쇼핑몰의 상품 담당(MD) 운영자 조회
  private async fetchAdminsServiceMerchandisers(mallNo?: string) {
    if (!mallNo) return;
    const { data } = await this.$api.getAdminsServiceMerchandisers({
      params: { mallNo },
    });
    this.setAdminsServiceMerchandisers(data);
  }
  private setAdminsServiceMerchandisers(data: Mds[]) {
    this.admins = data;
  }

  async created() {
    //NOTE: '수정후 승인대기'는 승인완료 후 수정한 사항이라 쇼핑몰 변경할 수 없음. 따라서, originProductInfo.mallNo 정보만으로도 가능
    this.fetchMallsContractedPartnersByMallNo(this.originProductInfo?.mallNo.toString()); //파트너사
    this.fetchAdminsServiceMerchandisers(this.originProductInfo?.mallNo.toString()); //담당자
  }
}
