








































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { PERIOD_TYPE } from '@/const/contents/statistics';
import { addMonth, firstDayOfMonth, getFirstAndEndDay, getSelectMonths, getSelectYears } from '@/utils/dateFormat';
import { aMonthAgo, today } from '@/views/contents/statistics/sales/periodSales';
import { periodTypeOptions } from '@/views/contents/statistics/sales/searchOptions';
import { PeriodType } from '@/types/partner';

@Component({
  components: { DateRangePicker, SelectBox },
})
export default class DatePeriodPicker extends Vue {
  @PropSync('periodType', { default: PERIOD_TYPE.MONTH })
  private periodTypeSync!: PeriodType;
  @PropSync('selectYear', { default: null })
  private selectYearSync!: number | null;
  @PropSync('selectMonth', { default: null })
  private selectMonthSync!: number | null;
  @PropSync('startYmd', { default: aMonthAgo })
  private startYmdSync!: string;
  @PropSync('endYmd', { default: today })
  private endYmdSync!: string;
  @Prop({ default: () => ['td', 'ytd', '3d', '1w', '1m', '3m'] })
  private periodTypes!: PeriodType[];
  @Prop({ default: true })
  private selectPeriodType!: boolean;

  private readonly yearList = getSelectYears(this.$t('YEAR').toString(), true, this.rangeOfYear);
  private readonly monthList = getSelectMonths(this.$t('MONTH').toString());
  private readonly periodTypeOptions = periodTypeOptions;

  private get rangeOfYear(): number {
    const beforeFiveYears = addMonth(firstDayOfMonth(), -59);
    const [year] = beforeFiveYears.split('-');
    return new Date().getFullYear() - Number(year);
  }

  private get isMonthPeriodType() {
    return this.periodTypeSync === PERIOD_TYPE.MONTH;
  }

  private changeYmd(year, month) {
    if (this.isMonthPeriodType) {
      const { startYmd, endYmd } = getFirstAndEndDay(year, month);
      this.startYmdSync = startYmd;
      this.endYmdSync = endYmd;
    } else {
      this.startYmdSync = aMonthAgo;
      this.endYmdSync = today;
    }
  }

  private changeSelectYear(changedYear) {
    this.changeYmd(changedYear, this.selectMonthSync);
  }

  private changeSelectMonth(changedMonth) {
    this.changeYmd(this.selectYearSync, changedMonth);
  }
}
