export default Object.freeze({
  NOTICE:
    'PG신청정보 및 승인상태 확인, 타 PG사 관련 정보는 {tagStart}마이페이지>쇼핑몰 관리>전자결제 서비스{tagEnd}에서 상세히 확인할 수 있습니다.',

  APPLY_PG_TITLE: '전자결제(PG) 서비스를 신청해주세요.',
  APPLY_PG_KCP: 'KCP 서비스 신청',
  APPLY_PG_INICIS: 'KG이니시스 서비스 신청',
  APPLY_PG_TOSS: '토스페이먼츠 서비스 신청',

  KCP: 'KCP',
  INICIS: 'KG이니시스',
  TOSS: '토스페이먼츠',

  NORMAL_SETTING_TITLE: '일반결제 설정',
  ESCRO_SETTING_TITLE: '에스크로 설정',

  PAY_TYPE: '결제수단',
  PAY_TYPE_DESC_KCP: `'PAYCO' 결제수단은 KCP와 페이코 허브형을 계약한 경우에만 사용해주세요. 계약된 정보와 다를 경우 결제가 불가할 수 있습니다.`,

  KCP_SITE_CD: 'KCP 사이트 코드',
  KCP_SITE_KEY: 'KCP 사이트 키',

  AUTO_SETTING_COMPLETE_SUFFIX: '(자동 설정 완료)',

  UPDATE_INFO: '정보 갱신',

  LIMIT_PAY_WAIT_DAYS: '가상계좌 입금기한',
  LIMIT_PAY_WAIT_DAYS_TEXT_1: '결제 완료',
  LIMIT_PAY_WAIT_DAYS_TEXT_2: '일 이후 자동취소 처리함.',

  CALLBACK_URLS: '가상계좌 입금내역 실시간 통보 URL',
  CALLBACK_URLS_TOSS_MESSAGE: '결제 시 자동으로 전송이 되며, 가상계좌에 입금 시 자동으로 결제완료 처리가 됩니다.',

  INICIS_MERCHANT_ID: 'KG이니시스 상점 ID',
  INICIS_SIGN_KEY: 'KG이니시스 signKey',
  INICIS_KEY: 'KG이니시스 암호화 key',
  INICIS_INIAPI_KEY: 'INIAPI key - ',
  INICIS_INIAPI_IV: 'INIAPI iv - ',

  TOSS_ID: '토스페이먼츠 ID',
  TOSS_MERT_KEY: '토스페이먼츠 mertKey',

  TAX_TYPE_SETTING: '과세 정보 설정',
  COMPOUND_TAX_GUIDE: '복합 과세 안내',
  TAX_TYPE_SETTING_DESC_KCP:
    '반드시 KCP와 계약한 과세정보로 설정해주세요. 계약된 과세정보와 다르게 설정할 경우, 클레임 처리가 불가할 수 있습니다.',
  TAX_TYPE_SETTING_DESC_1:
    '복합과세(면세, 영세, 과세 상품 동시 취급)로 쇼핑몰을 운영하신다면 반드시 PG사와 복합과세 계약 후 사용하시기 바랍니다.',
  TAX_TYPE_SETTING_DESC_2:
    '복합과세(면세, 영세, 과세 상품 동시 취급)로 쇼핑몰을 운영하신다면 반드시 PG사와 복합과세 계약을 신청하시기 바랍니다.',
  TAX_TYPE_SETTING_DESC_3:
    '복합과세로 계약이 되어 있지 않은 상태에서 복합과세로 계약 변경 시 일부 면세 상품의 클레임 처리가 불가할 수 있습니다.',

  LIMIT_DELIVERY_ING_DAYS: '배송 소요일',

  PURCHASE_SAFETY_SIGN: '구매 안전 표시',

  PREVIEW: '미리보기',

  INICIS_LOGO_SETTING: '표시 로고 설정',
  INICIS_LOGO_SETTING_DESC:
    '[KG 이니시스 인증마크]에서 제공 받은 내용을 기입해주세요. {tagStart}KG이니시스 인증마크 바로가기>{tagEnd}',

  ALERT_SUCCESS_UPDATE_INFO: '정보 갱신이 완료되었습니다.\n잠시 후 새로고침됩니다.',

  TOOLTIP_LIMIT_PAY_0: '결제수단을 ‘가상계좌’로 주문한 건의 자동취소기간을 설정할 수 있습니다.',
  TOOLTIP_LIMIT_PAY_1: `{tagStart}‘설정>기본정책>쇼핑몰관리’{tagEnd}에서 설정한 ‘미입금 주문 자동취소’기능에서 설정한 기간이 더 짧은 경우, 무통장 주문 자동취소 설정에 따라 주문이 자동 취소처리됩니다.`,

  TOOLTIP_CALLBACK_0: '결제수단이 ‘가상계좌’인 주문에 대한 입금 확인 시 정보를 전달받기 위한 URL입니다.',
  TOOLTIP_CALLBACK_1: `NHN KCP 상점 관리자모드 [ 상점정보 관리 – 정보변경 – 공통 URL 정보 ]에 ＂공통 URL＂ 등록 후 ＂인코딩 설정＂을 반드시 ＂UTF-8＂로 해주셔야 합니다.`,
  TOOLTIP_CALLBACK_2:
    '쇼핑몰 도메인이 변경이 되었다면 정확한 실시간 통보를 위해 NHN KCP 상점 관리자 설정을 변경해주세요.',
  TOOLTIP_CALLBACK_3: `KG 이니시스 상점 관리자모드 [ 상점정보 > 계약정보 > 결제수단 정보 ]의 "가상계좌 항목"에 "입금통보URL(IP)"에 넣으세요.`,
  TOOLTIP_CALLBACK_4:
    '쇼핑몰 도메인이 변경이 되었다면 정확한 실시간 통보를 위해 KG이니시스 상점 관리자 설정을 변경해주세요.',

  TAX: '과세',
  TAX_FREE: '면세',
  COMPOUND_TAX: '복합과세',

  MARK: '표시함',
  NOT_MARK: '표시 안 함',

  CONFIRM_TO_UPDATE_CONFIGS_PAYMENTS_PAY_TYPE:
    '무통장입금은 단독 사용이 불가하여 반드시 그 외 결제수단을 1개 이상 노출해야합니다.\n설정을 변경할 경우 무통장입금을 포함한 모든 결제수단이 노출되지 않으며 쇼핑몰 사용이 제한될 수 있습니다.\n변경하시겠습니까?',

  NOTICE_FOR_BLOCKED_ACCOUNT:
    '무통장입금 실 결제금액이 한도금액(200만 원) 이상 누적된 경우 무통장입금 사용이 불가합니다.<br />PG 신청 및 사용설정 후 이용 가능하오니 참고 부탁드립니다.',
});
