import XLSX, { WorkBook } from 'xlsx';

interface GetDataFromExcelFile {
  (file: File): Promise<ParsedObject[]>;
}

export type ParsedObject = { [key: string]: string | number };

export const getDataFromExcelFile: GetDataFromExcelFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const arrayBuffer = (event.target as FileReader).result as ArrayBuffer;
      const workbook: WorkBook = XLSX.read(arrayBuffer, { type: 'binary' });
      resolve(XLSX.utils.sheet_to_json<ParsedObject>(workbook.Sheets[workbook.SheetNames[0]]));
    };

    reader.onerror = reject;

    reader.readAsBinaryString(file); // TO-BE : readAsArrayBuffer
  });
};

export const getBase64FromBlob = (blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
};
