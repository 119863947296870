



















import { Vue, Component, ModelSync, Ref } from 'vue-property-decorator';
// components
import TextInput from '@/components/common/input/TextInput.vue';
// types
import { GetPartnersExistsRequest } from 'ncp-api-supporter';
import { TranslateResult } from 'vue-i18n';
@Component({
  components: {
    TextInput,
  },
})
export default class PartnerName extends Vue {
  // NOTE: TextInput.vue 에 파트너명('partner-name')정규표현식 추가했습니다.
  // FIXME: 파트너명이 '신규이름    ' 처럼 이름 뒤 공백이 많아도 유효한 중복검사를 하는데,
  // '     신규이름' 이러한 경우 사용가능한 파트너명으로 응답받음.
  @ModelSync('partnerName', 'input')
  private partnerNameSync!: string;

  @Ref()
  private readonly partnerNameInputRef!: TextInput;

  private isPartnerNameDuplication = false;
  private isPartnerNameDuplicationMessage: TranslateResult = '';

  public focus() {
    this.partnerNameInputRef.focus();
  }

  private setDuplicationMessage(isDuplicate: boolean) {
    this.isPartnerNameDuplication = isDuplicate;
    this.isPartnerNameDuplicationMessage = isDuplicate ? this.$t('PARTNER.EXISTS_NG') : this.$t('PARTNER.EXISTS_OK');
    isDuplicate && this.focus();
  }

  private resetDuplicationMessage() {
    this.isPartnerNameDuplicationMessage = '';
    this.focus();
  }

  private isVoidedPartnerName() {
    this.resetDuplicationMessage();
    alert(this.$t('PARTNER.ERR_NAME_NULL'));
    return true;
  }

  public async isDuplicatedPartnerName(partnerName: string) {
    if (partnerName.trim().length) {
      const params: GetPartnersExistsRequest = {
        params: {
          name: partnerName,
        },
      };

      const { data } = await this.$api.getPartnersExists(params);
      this.setDuplicationMessage(data.exists);
      return data.exists;
    }
    return this.isVoidedPartnerName();
  }
}
