















































































































































































































































































































































































































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  PostTaskMessagesRequest,
  GetTaskMessagesRequest,
  DeleteTaskMessagesTaskMessageNoRequest,
  PutTaskMessagesTaskMessageNoComplateRequest,
  OrderFriendsCsStatusType,
  OrderFriendsCsType,
  TaskMessageTargetType,
  GetTaskMessagesTaskMessagesNoDetailsLastRequest,
  PostTaskMessagesTaskMessageNoDetailsRequest,
  PutTaskMessagesTaskMessageNoDetailsTaskMessageDetailNoRequest,
  GetTaskMessagesTaskMessagesNoDetailsLast,
  DeleteTaskMessagesTaskMessageNoDetailsTaskMessageDetailNoRequest,
} from 'ncp-api-supporter';
import FilterDeletedUser from '@/components/popup/shipping/mixins/FilterDeletedUser';

interface RegisterAdmin {
  registerAdminIp: string;
  registerAdminName: string;
  registerAdminNo: number;
  registerYmdt: string;
}

@Component({
  components: { SelectBox },
})
export default class TaskMessage extends Mixins(FilterDeletedUser) {
  @Prop() private showTaskMessage?: boolean;
  @Prop() private orderProductOptionNoArr;
  @Prop() private serviceNo;
  @Prop() private adminInfo;
  @Prop() private showTaskMessageIcon;
  @Prop() private showTaskMessageIconStyle;
  private taskContentLength = 0;
  private newTaskContentLength = 0;
  private taskTotalNum = 0;
  public taskProcessNum = 0;
  private taskContentList = [];
  public processList = [];
  public doneList = [];
  public detailContent = '';
  public showAllTask = false;
  public taskMessageInfo = [
    {
      orderOptionNo: 0,
      taskMessageStatus: '',
    },
  ];
  private taskMessageTargetDisabled = false;

  public task = {
    taskMessageStatus: 'PROCESSING',
    taskMessageType: 'PRODUCT',
    taskMessageTargetType: 'PARTNER',
    orderProductOptionNo: undefined,
    content: null,
  };

  public taskMessageStatusTypeArr = [
    { value: 'PROCESSING', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_STATUS_TYPE_PROCESSING' },
    { value: 'DONE', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_STATUS_TYPE_DONE' },
  ];
  public taskMessageTypeArr = [
    { value: 'PAY', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_PAY' },
    { value: 'PRODUCT', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_PRODUCT' },
    { value: 'DELIVERY', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_DELIVERY' },
    { value: 'CLAIM', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_CLAIM' },
    { value: 'REFUND', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_REFUND' },
    { value: 'ETC', label: 'ORDER.ORDER_DETAIL.TASK_MESSAGE_TYPE_ETC' },
  ];

  public taskMessageTargetTypeArr = [
    { value: 'PARTNER', label: 'ORDER.ORDER_DETAIL.MESSAGE_TARGET_TYPE_PARTNER' },
    { value: 'SERVICE', label: 'ORDER.ORDER_DETAIL.MESSAGE_TARGET_TYPE_SERVICE' },
  ];

  public taskContentInput() {
    if (this.task.content) {
      this.taskContentLength = this.task.content.length;
    } else {
      this.taskContentLength = 0;
    }
  }

  public newTaskContentInput(event) {
    if (event.newContent) {
      event.newTaskContentLength = event.newContent.length;
    } else {
      event.newTaskContentLength = 0;
    }
  }

  public detailTaskContentInput(event) {
    if (event.content) {
      event.contentLength = event.content.length;
    } else {
      event.contentLength = 0;
    }
  }

  private postTaskInfo(): void {
    let orderProductNm = '';
    let toTargetNo = this.serviceNo;
    if (!this.task.orderProductOptionNo) {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_10'));
      return;
    }
    if (!this.task.content) {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_11'));
      return;
    }
    this.orderProductOptionNoArr.map(item => {
      if (item.value === this.task.orderProductOptionNo) {
        orderProductNm = item.label;
        if (this.task.taskMessageTargetType === 'PARTNER') {
          toTargetNo = item.partnerNo;
        }
      }
    });
    const taskRequest: PostTaskMessagesRequest = {
      data: {
        taskMessageStatusType: this.task.taskMessageStatus as OrderFriendsCsStatusType,
        orderNo: this.$route.query.orderNo as string,
        taskMessageChannelType: 'SERVICE',
        orderProductOptionNo: this.task.orderProductOptionNo,
        toTargetNo: toTargetNo,
        taskMessageType: this.task.taskMessageType as OrderFriendsCsType,
        toTargetType: this.task.taskMessageTargetType as TaskMessageTargetType,
        content: this.task.content,
        productName: orderProductNm,
      },
    };
    this.$api.postTaskMessages(taskRequest).then(() => {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_12'));
      this.initTask();
      this.getTasks();
    });
  }

  public initTask() {
    this.task.taskMessageStatus = 'PROCESSING';
    this.task.taskMessageType = 'PRODUCT';
    this.task.taskMessageTargetType = 'SERVICE';
    this.task.orderProductOptionNo = undefined;
    this.task.content = null;
    this.taskContentLength = 0;
  }

  public getTasks(): void {
    const taskRequest: GetTaskMessagesRequest = {
      params: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api.getTaskMessages(taskRequest).then(res => {
      this.taskContentList = [];
      this.taskContentList = res.data;
      this.taskTotalNum = this.taskContentList.length;
      this.formatterList(res.data);
    });
  }

  public formatterList(data): void {
    if (data.length === 0) {
      this.processList = [];
      return;
    }
    this.doneList = [];
    this.processList = [];

    data.map(item => {
      // if (item.registerInfo.registerAdminNo) {
      //   item.registerInfo = this.filterDeletedUser<RegisterAdmin>(
      //     item.registerInfo,
      //     'registerAdminNo',
      //     'registerAdminName',
      //   );
      // }

      item = {
        no: item.no,
        contentLength: item.content.length,
        updateYmdt: item.updateYmdt,
        newContent: item.content,
        newTaskContentLength: item.content.length,
        newTaskMessageStatus: item.taskMessageStatusType,
        newTaskMessageType: item.taskMessageType,
        newTaskMessageTargetType: item.toTargetType,
        newOrderProductOptionNo: item.orderProductOptionNo,
        newOrderProductInfo: this.getOrderProductOptionInfo(item.orderProductOptionNo),
        partnerDelivery: this.getOrderProductOption(item.orderProductOptionNo)
          ? this.getOrderProductOption(item.orderProductOptionNo).partnerDelivery
          : true,
        upArrow: true,
        detailDisabled: false,
        addTaskMessageStatus: item.taskMessageStatusType,
        taskMessageType: item.taskMessageType,
        taskMessageStatusType: item.taskMessageStatusType,
        addContent: '',
        addContentLength: 0,
        registerInfo: item.registerInfo,
        content: item.content,
        productName: item.productName,
        taskMessageDetails: item.taskMessageDetails,
        taskMessageChannelType: item.taskMessageChannelType,
      };

      if (item.taskMessageDetails.length > 0) {
        item.detailLastIndex = item.taskMessageDetails.length - 1;
        item.taskMessageDetails.map((dItem, dIndex) => {
          item.taskMessageDetails[dIndex].contentLength = dItem.content.length;
          item.taskMessageDetails[dIndex].newContent = dItem.content;
          item.taskMessageDetails[dIndex].taskMessageStatusType = item.taskMessageStatusType;
        });
      }

      // item.hasTaskDetail = this.adminInfo.serviceNo !== item.fromTargetNo; // TODO: 공급사 추가 기능
      item.hasTaskDetail = item.taskMessageDetails.length > 0;
      item.showEdit = false;
      item.showTaskMessage = true;
      item.taskMessageStatus = 'PROCESSING';
      if (item.taskMessageStatusType === 'DONE') {
        item.upArrow = false;
        this.doneList.push(item);
      } else {
        this.processList.push(item);
      }
    });
    this.doneList = this.doneList.reverse();
    this.processList = this.processList.reverse();
    this.taskProcessNum = this.processList.length;
    this.handleTaskMessageStatus(this.processList);
  }
  public getOrderProductOptionInfo(orderProductOptionNo) {
    let label;
    this.orderProductOptionNoArr.map(item => {
      if (item.value === orderProductOptionNo) {
        label = item.label;
      }
    });
    return label?.split('|') as string[];
  }
  private getOrderProductOption(orderProductOptionNo: number) {
    return this.orderProductOptionNoArr.find(item => item.value === orderProductOptionNo);
  }

  public handleTaskMessageStatus(data) {
    data.map((item, index: number) => {
      if (item.taskMessageStatusType === 'PROCESSING') {
        this.taskMessageInfo[index] = {
          orderOptionNo: item.orderProductOptionNo,
          taskMessageStatus: item.taskMessageStatusType,
        };
      }
    });
  }

  public getTaskMessageStatusTypeLabel(type) {
    let label;
    switch (type) {
      case 'PROCESSING':
        label = this.$t('ORDER.CS_TASK.MESSAGE_STATUS_TYPE_PROCESSING');
        break;
      case 'DONE':
        label = this.$t('ORDER.CS_TASK.MESSAGE_STATUS_TYPE_DONE');
        break;
      default:
        label = '';
    }
    return label;
  }
  public getTaskMessageTypeName(csType) {
    let name;
    switch (csType) {
      case 'PAY':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_PAY');
        break;
      case 'PRODUCT':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_PRODUCT');
        break;
      case 'DELIVERY':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_DELIVERY');
        break;
      case 'CLAIM':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_CLAIM');
        break;
      case 'REFUND':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_REFUND');
        break;
      case 'ETC':
        name = this.$t('ORDER.CS_TASK.MESSAGE_TYPE_ETC');
        break;
      default:
        name = '';
    }
    return name;
  }
  private getTargetTypeLabel(type: TaskMessageTargetType) {
    return this.$t(`ORDER.CS_TASK.TARGET_TYPE_${type}`) as string;
  }
  public handleEdit(theItem, parentItem?): void {
    this.$set(theItem, 'showEdit', true);
    this.$set(theItem, 'detailDisabled', true);
    if (parentItem) {
      this.$set(parentItem, 'detailDisabled', true);
    }
  }
  public handleDetailsEdit(theItem, parentItem?): void {
    this.$set(theItem, 'showEdit', true);
    this.$set(theItem, 'detailDisabled', true);
    if (parentItem) {
      this.$set(parentItem, 'detailDisabled', true);
    }
  }
  public handleDelete(item): void {
    if (!confirm(this.$t('ORDER.CS_TASK.CONFIRM_1') as string)) {
      return;
    }

    this.getTaskNewestCheck(item).then(() => {
      const taskDeleteRequest: DeleteTaskMessagesTaskMessageNoRequest = {
        pathParams: {
          taskMessageNo: item.no,
        },
      };
      this.$api.deleteTaskMessagesTaskMessageNo(taskDeleteRequest).then(() => {
        this.getTasks();
      });
    });
  }
  public getTaskNewestCheck(item) {
    return new Promise(resolve => {
      const lastNoIndex: number = item.taskMessageDetails ? item.taskMessageDetails.length - 1 : 0;
      const detailLastRequest: GetTaskMessagesTaskMessagesNoDetailsLastRequest = {
        pathParams: {
          taskMessageNo: item.no,
        },
      };
      this.$api.getTaskMessagesTaskMessagesNoDetailsLast(detailLastRequest).then(res => {
        if (res.data.no === (item.taskMessageDetails[lastNoIndex] && item.taskMessageDetails[lastNoIndex].no)) {
          resolve(res.data);
        } else {
          alert(this.$t('ORDER.CS_TASK.ALERT_6'));
          return;
        }
      });
    });
  }
  public handleCompleted(item): void {
    let message = this.$t('ORDER.CS_TASK.CONFIRM_2') as string;
    this.getTaskNewestCheck(item).then((data: GetTaskMessagesTaskMessagesNoDetailsLast) => {
      if (data) {
        message = this.$t('ORDER.CS_TASK.CONFIRM_3') as string;
      }
      if (!confirm(message)) {
        return;
      }
      const request: PutTaskMessagesTaskMessageNoComplateRequest = {
        pathParams: {
          taskMessageNo: item.no,
        },
        data: {
          lastTaskMessageDetailNo: data ? data.no : 0,
        },
      };
      this.$api
        .putTaskMessagesTaskMessageNoComplate(request)
        .then(() => {
          alert(this.$t('ORDER.CS_TASK.ALERT_4'));
          this.getTasks();
        })
        .catch(() => {
          alert(this.$t('ORDER.CS_TASK.ALERT_5'));
        });
    });
  }
  public contentInput(ev): void {
    if (ev.newContent) {
      ev.contentLength = ev.newContent.length;
    } else {
      ev.contentLength = 0;
    }
  }
  public replaceContent(content) {
    return content.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
  public handleCancelBtn(item, dItem?): void {
    if (!confirm(this.$t('ORDER.CS_TASK.CONFIRM_4') as string)) {
      return;
    }
    if (dItem) {
      this.$set(dItem, 'showEdit', false);
      this.$set(dItem, 'contentLength', dItem.content.length);
      this.$set(dItem, 'newContent', dItem.content);
      this.$set(dItem, 'newTaskMessageStatus', dItem.taskMessageStatusType);
      this.$set(dItem, 'newTaskMessageType', dItem.taskMessageType);
      this.$set(dItem, 'newTaskMessageTargetType', dItem.toTargetType);
      this.$set(dItem, 'newOrderProductOptionNo', dItem.orderProductOptionNo);
      this.$set(dItem, 'detailDisabled', false);
    } else {
      this.$set(item, 'showEdit', false);
      this.$set(item, 'contentLength', item.content.length);
      this.$set(item, 'newContent', item.content);
      this.$set(item, 'newTaskMessageStatus', item.taskMessageStatusType);
      this.$set(item, 'newTaskMessageType', item.taskMessageType);
      this.$set(item, 'newTaskMessageTargetType', item.toTargetType);
      this.$set(item, 'newOrderProductOptionNo', item.orderProductOptionNo);
      this.$set(item, 'detailDisabled', false);
    }
  }
  public saveTask(item): void {
    this.getTaskNewestCheck(item).then(() => {
      if (!item.newOrderProductOptionNo) {
        alert(this.$t('ORDER.CS_TASK.ALERT_1'));
        return;
      }
      if (!item.newContent) {
        alert(this.$t('ORDER.CS_TASK.ALERT_2'));
        return;
      }
      const putRequest = {
        pathParams: {
          taskMessageNo: item.no.toString(),
        },
        data: {
          orderProductOptionNo: item.newOrderProductOptionNo,
          toTargetNo: item.toTargetNo,
          taskMessageType: item.newTaskMessageType,
          toTargetType: item.newTaskMessageTargetType,
          content: item.newContent,
        },
      };
      this.$api.putTaskMessagesTaskMessageNo(putRequest).then(() => {
        alert(this.$t('ORDER.CS_TASK.ALERT_3'));
        this.getTasks();
      });
    });
  }

  private showTaskMessageDone(event) {
    event.showTaskMessage = !event.showTaskMessage;
  }
  public addTaskMessageDetail(item) {
    const lastNoIndex: number = item.taskMessageDetails.length - 1;
    const detailLastRequest: GetTaskMessagesTaskMessagesNoDetailsLastRequest = {
      pathParams: {
        taskMessageNo: item.no,
      },
    };

    this.$api.getTaskMessagesTaskMessagesNoDetailsLast(detailLastRequest).then(res => {
      if (res.data.no === (item.taskMessageDetails[lastNoIndex] && item.taskMessageDetails[lastNoIndex].no)) {
        if (!item.newContent) {
          alert(this.$t('ORDER.CS_TASK.ALERT_8'));
          return;
        }
        if (item.addTaskMessageStatus === 'DONE') {
          if (!confirm(this.$t('ORDER.CS_TASK.CONFIRM_3') as string)) {
            return;
          }
        }
        const request: PostTaskMessagesTaskMessageNoDetailsRequest = {
          pathParams: {
            taskMessageNo: item.no,
          },
          data: {
            completion: item.addTaskMessageStatus === 'DONE',
            taskMessageChannelType: item.taskMessageChannelType,
            lastTaskMessageDetailNo: res.data ? res.data.no : 0,
            content: item.addContent,
          },
        };
        this.$api.postTaskMessagesTaskMessageNoDetails(request).then(() => {
          if (item.addTaskMessageStatus === 'DONE') {
            alert(this.$t('ORDER.CS_TASK.ALERT_4'));
          } else {
            alert(this.$t('ORDER.CS_TASK.ALERT_9'));
          }
          this.detailContent = '';
          this.getTasks();
        });
      } else {
        alert(this.$t('ORDER.CS_TASK.ALERT_6'));
        return;
      }
    });
  }

  public detailSave(dItem, item) {
    if (dItem.content === '') {
      alert(this.$t('ORDER.CS_TASK.ALERT_7'));
      return;
    }
    this.getTaskNewestCheck(item).then((checkRes: GetTaskMessagesTaskMessagesNoDetailsLast) => {
      const request: PutTaskMessagesTaskMessageNoDetailsTaskMessageDetailNoRequest = {
        pathParams: {
          taskMessageNo: item.no,
          taskMessageDetailNo: checkRes.no.toString(),
        },
        data: {
          lastTaskMessageDetailNo: Number(checkRes.no),
          content: dItem.newContent,
        },
      };
      this.$api.putTaskMessagesTaskMessageNoDetailsTaskMessageDetailNo(request).then(() => {
        alert(this.$t('ORDER.CS_TASK.ALERT_3'));
        this.getTasks();
      });
    });
  }

  public handleAddDetailDelete(item): void {
    if (!confirm(this.$t('ORDER.CS_TASK.CONFIRM_1') as string)) {
      return;
    }
    this.getTaskNewestCheck(item).then((res: GetTaskMessagesTaskMessagesNoDetailsLast) => {
      const request: DeleteTaskMessagesTaskMessageNoDetailsTaskMessageDetailNoRequest = {
        pathParams: {
          taskMessageNo: item.no,
          taskMessageDetailNo: res.no.toString(),
        },
      };
      this.$api.deleteTaskMessagesTaskMessageNoDetailsTaskMessageDetailNo(request).then(() => {
        this.getTasks();
      });
    });
  }

  public mounted(): void {
    this.getTasks();
  }

  @Watch('orderProductOptionNoArr')
  onChangedOrderProductOptionNoArr() {
    this.getTasks();
  }

  @Watch('task.orderProductOptionNo')
  onSelectedOrderProductOptionNo() {
    const orderProductOption = this.getOrderProductOption(this.task.orderProductOptionNo);
    if (orderProductOption && !orderProductOption.partnerDelivery) {
      this.task.taskMessageTargetType = 'SERVICE';
      this.taskMessageTargetDisabled = true;
    } else {
      this.task.taskMessageTargetType = 'PARTNER';
      this.taskMessageTargetDisabled = false;
    }
  }
}
