export default Object.freeze({
  // keyword select
  ALL: '전체',
  MEMBER_ID: '아이디',
  MEMBER_NAME: '회원명',
  MEMBER_NO: '회원번호',
  MEMBER_EMAIL: '이메일',
  MEMBER_MOBILE: '휴대폰번호',

  WITHDRAWAL_DATE: '탈퇴일',

  DETAIL_CONDITION: '상세검색',

  // forced select
  WITHDRAWAL_TYPE: '탈퇴유형',
  WITHDRAWAL_FORCED: '강제탈퇴',
  WITHDRAWAL_SELF: '직접탈퇴',

  // rejoinability select
  REJOINABILIY: '재가입 가능 여부',
  REJOIN_POSSIBLE: '가능',
  REJOIN_IMPOSSIBLE: '불가',

  // join type select
  JOIN_TYPE: '가입유형',
  JOIN_NORMAL: '일반회원',

  WITHDRAWAL_REASON: '탈퇴사유',
  EMAIL: '이메일',
  GRADE: '회원등급',

  CANCEL_WITHDRAWAL: '탈퇴철회',
  DELETE_WITHDRAWAL: '삭제처리',

  ALERT_NOT_SELECT: '회원을 선택해주세요',
  ALERT_CANCEL_SUCCESS: '성공{number}건: 탈퇴철회 완료',
  ALERT_FAIL: '실패{number}건: {reason}',
  ALERT_CANCEL_IMPOSSIBLE: '탈퇴철회가 불가한 회원입니다.',
  ALERT_CANCEL_IMPOSSIBLE_2: '탈퇴철회가 불가한 회원이 포함되어 있습니다.\r유예기간 내 회원을 선택해주세요.',
  ALERT_CANCEL_CONFIRM: '선택하신 회원의 탈퇴를 철회 하시겠습니까?\r탈퇴 철회 시 해당 회원의 정보가 복구됩니다.',

  ALERT_DELETE_SUCCESS: '성공{number}건: 회원 삭제 완료',
  ALERT_DELETE_CONFIRM: '선택하신 회원을 삭제하시겠습니까? 삭제 시 해당 회원의 정보 복구가 불가합니다.',
  ALERT_NO_PERMISSION: '해당 메뉴의 쓰기 권한이 없습니다.',
});
