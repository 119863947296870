import {
  InputValueType,
  MarketingSettings,
  OptionType,
  ProductCertificationInfo,
  SalePeriodInfo,
  YorN,
} from 'ncp-api-supporter';
import { addDay, changeDateFormat, getStrDate, getToday, isBefore } from '@/utils/dateFormat';
import { DEFAULT_DATE_RANGE, DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import {
  BasicInformation,
  ConvertToSearchKeywordDataType,
  InputNumber,
  DeliveryInformation,
  FailResult,
  FailResultType,
  ImagesInformation,
  NaverShoppingEPInformation,
  ProductInformation,
  SalesInformation,
  SearchKeywordData,
  VerifyProductInfoResult,
  RelatedProductInformation,
  StartAndEndYmdHm,
  DateRangeOption,
  OptionPopupDataType,
  ExtraInformation,
} from '@/types';
import {
  CONFIG_TYPE,
  CONTENT_USE_TYPE,
  IMMUTABLE_TYPE,
  NAVER_PRODUCT_STATUS,
  NAVER_PRODUCT_SALE_TYPE,
  OPTION_TYPE,
  SORT_CRITERION,
} from '@/const/contents/product';
import { convertToI18n } from '@/utils/i18n';
import { i18n } from '@/main';
import { PeriodType } from 'ncp-api-supporter/dist/types/enum';

export function i18nForProduct(word: string, data?: string, useCommon?: boolean): string {
  return convertToI18n('PRODUCT.ADD.', word, data, useCommon);
}

export const INITIAL_DIGIT = 0;
export const INITIAL_RANGE_MONTH = 3;

export const today = getToday();
const tomorrow = addDay(new Date(), 1);
export const threeMonths = addDay(new Date(), 90);
// export const threeMonths = addMonth(new Date(), INITIAL_RANGE_MONTH);

export const defaultBasicInfo = (mallNo: InputNumber = ''): BasicInformation => {
  return {
    mallNo,
    mallProductNo: 0,
    // TODO isSet 과 관련된 부분을 productType 으로 변경하기
    productType: 'SINGLE',
    groupType: 'DELIVERY', // 배송상품군으로 고정 및 항목 숨김
    classType: 'DEFAULT', // 자체 공급사로 고정 및 항목 숨김
    partnerNo: INITIAL_DIGIT,
    // 판매방식
    saleMethodType: 'CONSIGNMENT',
    // 담당자
    adminNo: '',
    // 표준카테고리
    categoryNo: '',
    // 전시카테고리
    displayCategoryNos: [],
    // 플랫폼별 노출 설정
    platformDisplayInfo: {
      displayYn: 'Y',
      pcYn: 'Y',
      mobileWebYn: 'Y',
      mobileYn: 'N', // 모바일앱 사용하지 않음
    },
    // 회원등급 노출 설정
    memberGradeDisplayInfo: {
      check: 'NONE',
      info: [] as InputValueType[],
    },
    // 상품명
    productName: '',
    // 브랜드
    brandName: '',
    // TODO: 추가 항목: 성인인증
    // 결제수단제어
    paymentMeansControlYn: 'N', // 숨김처리
    paymentMeans: [
      'PAYCO',
      'CREDIT',
      'REALTIME_TRANSFER',
      'DEPOSIT',
      'ESCROW_REALTIME_TRANSFER',
      'ESCROW_VIRTUAL_ACCOUNT',
    ],
    // 장바구
    cartInfo: {
      offEndYmd: '',
      offPeriodYn: 'N',
      offStartYmd: '',
      useYn: 'Y',
    },
    // 검색어
    keywords: [],

    urlShorteningYn: 'N',
    shortenUrl: null,
    productDescInfo: {
      useYn: 'N',
      text: '',
      periodYn: false,
      startYmd: today,
      endYmd: threeMonths,
    },
    extraJson: {
      // basic-info
      naverPayLimitYn: false,
    },
    minorPurchaseYn: 'Y',
    displayOrder: {
      type: 'AUTO',
      automaticDisplayType: SORT_CRITERION.LATEST_REGISTER_DATE,
    },
    nonmemberPurchaseYn: 'Y',
  };
};

export const defaultRelatedProductInfo = (): { relatedProductInfo: RelatedProductInformation } => {
  return {
    relatedProductInfo: {
      configType: CONFIG_TYPE.NOT_USED,
      sortCriterion: SORT_CRITERION.LATEST_REGISTER_DATE,
      products: [], // { productNo: number, displayOrder: number } []
    },
  };
};
export const defaultPeriodInfo = (): SalePeriodInfo => {
  return {
    startYmdt: tomorrow,
    periodType: 'REGULAR', // REGULAR: 상시 판매, PERIOD: 기간지정 판매
    endYmdt: `${threeMonths} ${DEFAULT_TIME_RANGE.END}`,
  };
};
export const defaultSalesInfo = (isMapping: boolean): SalesInformation => {
  return {
    contentsIfPausing: null,
    useContentsIfPausing: false,
    // 예약판매
    useReservation: false,
    reservationInfoModel: {
      deliveryYmdt: '',
      startYmdt: '',
      endYmdt: '',
      stockCnt: '',
    },
    // 판매기간
    salePeriodInfo: defaultPeriodInfo(),
    // 판매수수료
    commissionInfo: {
      rate: '',
      type: 'PRODUCT', // PRODUCT: 상품수수료, CATEGORY: 카테고리수수료, PARTNER: 파트너수수료, PURCHASE_PRICE: 공급가입력
    },
    // 공급/매입가
    purchasePrice: INITIAL_DIGIT,
    // 즉시할인
    immediateDiscountInfo: {
      unitType: 'AMOUNT', // AMOUNT: 원, PERCENT: %
      startYmdt: '',
      amount: INITIAL_DIGIT,
      endYmdt: '',
      periodYn: 'N',
    },
    // 판매가
    salePrice: '',
    // alternativePhrase: '',
    // 프로모션(ncp) -> 상품설정(pro)으로 이름 변경됨
    // promotionTextUseYn: 'N',
    // promotionTextInfo: {
    //   startYmd: today,
    //   endYmd: threeMonths,
    //   periodYn: 'N',
    //   text: '',
    // },
    promotionInfo: {
      couponYn: 'Y',
      promotionYn: 'Y',
      additionalDiscountYn: 'Y',
    },
    // 재고수
    productStockCnt: INITIAL_DIGIT,

    accumulationSaveYn: 'N', // 개별 적립금 설정
    accumulationRate: 0,
    accumulationUseYn: 'Y', // 적립금사용

    // 최소구매수량
    // 최대구매수량
    buyInfo: {
      min: 'N',
      max: 'N',
    },
    minBuyCnt: 0,
    maxBuyCountInfo: {
      maxBuyTimeCnt: '',
      maxBuyPersonCnt: '',
      maxBuyPeriodCnt: '',
      maxBuyDays: '',
    },
    // 옵션 사용여부
    optionType: isMapping ? 'MAPPING' : 'DEFAULT', // STANDARD: 단독형, COMBINATION: 조합형, DEFAULT: 기본생성, MAPPING: 맵핑,
    optionSelectType: 'MULTI',
    optionUseYn: 'N',
    options: [],
    customerDemands: null,
    // 인증정보
    certificationInfo: {
      data: [] as ProductCertificationInfo[],
      type: 'NOT_TARGET', //  TARGET: 인증대상, NOT_TARGET: 인증대상아님, DETAIL_PAGE: 상세페이지 별도표기
    },
    // 원산지
    placeOriginInfo: {
      placeOriginsYn: 'N',
      placeOrigin: '',
      placeOriginSeq: '',
    },
    // 제조일자
    manufactureYmdt: '',
    // 상품관리코드
    productManagementCd: '',
    // 부가세
    valueAddedTaxType: 'DUTY', //  DUTY: 과세, DUTYFREE: 면세, SMALL: 영세
    // 아이콘
    stickerInfos: [],
    saleSettingStatusType: null, // "AVAILABLE_FOR_SALE",
    saleStatusType: null, // 'ON_SALE',
    parentNo: 0,
    // sales-info
    // 상품관리코드 중복체크
    // productManagementCdIsUsable: false,
    // reservationUseYn: false,
    // 매입처 상품명
    buyerProductName: '',
    // 판매가대체 문구
    dutyInfo: {
      saveTitle: '',
      useYn: 'N',
      dutyContent: {
        categoryNo: 0,
        categoryName: '',
        contents: [],
      },
    },
    expirationYmdt: '',
    hsCode: '',
    // 아이콘 상시노출, 기간노출
    icons: {
      regularExposure: {
        iconNos: [],
      },
      periodExposure: {
        startYmd: today,
        endYmd: threeMonths,
        iconNos: [],
      },
    },
  };
};

export const defaultImagesInfo = (): ImagesInformation => {
  return {
    // image-info
    // 상품 이미지
    mallProductListImage: '', // 리스트 이미지 url
    // 리스트 이미지
    mallProductImages: [],
    // 상품 상세(상단)
    contentHeader: '',
    // 상품 상세
    content: '', // 등록된 옵션 이미지 사용여부가 N 인경우 필수값. (nullable)
    // 상품 상세(하단)
    contentFooter: '',
    addOptionImageYn: 'N',
    useStatus: {
      contentHeader: false,
      content: true,
      contentFooter: false,
    },
    productGuides: [],
    guideUseStatus: {},
  };
};

export const defaultDeliveryInfo = (): DeliveryInformation => ({
  // delivery - info
  // 배송여부
  deliveryYn: 'Y',
  // 묶음배송 가능여부
  deliveryCombinationYn: 'Y',
  // 해외배송 여부
  deliveryInternationalYn: 'N',
  // 템플릿: 배송안내, AS 안내, 환불안내, 교환안내
  deliveryTemplateNo: INITIAL_DIGIT,

  // 배송방법
  // 배송지 파트너 번호
  shippingAreaPartnerNo: INITIAL_DIGIT,
  // 배송 구분
  shippingAreaType: 'MALL_SHIPPING_AREA', // MALL_SHIPPING_AREA(자체 상품) / PARTNER_SHIPPING_AREA: 파트너물류센터 출고 (오픈스팩)
  // 배송비유형
  // 출고지
  // 반품/교환지
  // 반품/교환배송
  refundableYn: 'Y',
  // 배송지정일
  // deliveryDueDate: {
  //   daysOfTheWeek: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
  //   period: {
  //     endDate: threeMonths,
  //     startDate: today,
  //   },
  //   daysAfterPurchase: 1, // 주문일 기준
  // },

  // 판매자 특이사항/고객안내사
  deliveryCustomerInfo: '', // TODO: 기획서에는 없는데.. 상품 조회를 하면 이 값이 담겨 온다.. 어떻게 처리하면 좋을까..?
});

export const defaultNaverShoppingEPInfo = (): NaverShoppingEPInformation => ({
  naverShoppingEP: {
    priceComparePageId: '',
    productStatus: NAVER_PRODUCT_STATUS.NEW,
    categoryId: '',
    saleType: NAVER_PRODUCT_SALE_TYPE.NONE,
    isCrossBorderShipping: false,
    isParallelImport: false,
    isCustomOrder: false,
    hasExtraCharge: false,
    monthlyRentalFee: null,
    rentalContractPeriod: null,
    naverPayAvailableLocations: JSON.stringify(['PC', 'MOBILE']),
    naverPayAccumLocations: JSON.stringify(['PC', 'MOBILE']),
    brandCertificateYn: 'N',
  },
});

export const getDefaultMarketingSettingInfo = (): MarketingSettings[] => [
  {
    displayable: false,
    channelType: 'NAVER_SHOPPING',
    additionalInfo: null,
  },
  {
    displayable: false,
    channelType: 'GOOGLE',
  },
];

export const defaultExtraInfo = (): ExtraInformation => {
  return {
    applyStatusType: null,
    // comparingPriceSiteInfo: null,
    // customPropertyValues: '',
    deliveryDueDate: null, //--> null 값으로 온
    displayBrandNo: 0, //-> 이 값은 무엇이지..?
    // eanCode: '', // -> 있었던 것 같은데 뭐지
    frontDisplayYn: null, // 전시가능여부
    // maxBuyCountInfo: null,
    memberGroupDisplayInfo: null,
    // minBuyCnt: 0,
    // minorPurchaseYn: null,
    modifiable: true,
    synced: true, // boolean
    // unitPriceInfo: null, // ?? nullable
  };
};

const getTempBtn = tempSave => ({
  type: 'left',
  key: 'tempSave',
  color: 'white',
  text: i18n.t('TEMP_SAVE'),
  disabled: !tempSave,
});

const getListBtn = () => ({
  type: 'right',
  key: 'list',
  color: 'gray',
  text: i18n.t('LIST'),
});

const getSaveBtn = () => ({
  type: 'right',
  key: 'save',
  color: 'red',
  text: i18n.t('SAVE'),
});

export const getNavBtns = (tempSave?: boolean, save?: boolean) => {
  const tempBtn = getTempBtn(tempSave);
  const listBtn = getListBtn();
  const saveBtn = getSaveBtn();
  if (!tempSave && !save) {
    return [listBtn];
  }
  if (!tempSave) {
    return [listBtn, saveBtn];
  }
  return [tempBtn, listBtn, saveBtn];
};

const defaultSuccessResult: FailResult = { name: '', msg: '' };
const getFailResult = (type: FailResultType, failResult: FailResult): VerifyProductInfoResult => {
  return {
    success: false,
    fail: {
      type,
      ...failResult,
    },
  };
};

const verifyBasicInformation = (info: BasicInformation): FailResult => {
  if (!info.mallNo) return { name: 'mall', msg: 'PRODUCT.ADD.PLZ_SELECT_SHOPPING_MALL' };

  if (!info.adminNo) return { name: 'admin', msg: 'PRODUCT.ADD.PLZ_SELECT_ADMIN' };

  if (!info.categoryNo) return { name: 'category', msg: 'PRODUCT.ADD.PLZ_SELECT_STANDARD_CATEGORY' };

  if (info.platformDisplayInfo.displayYn === 'N') {
    const tempPlatformDisplayInfo = { ...info.platformDisplayInfo };
    delete tempPlatformDisplayInfo.displayYn;
    const unCheck = Object.values(tempPlatformDisplayInfo).every(value => value === 'N');
    if (unCheck) {
      return { name: 'platform', msg: 'PRODUCT.ADD.PLZ_SELECT_PLATFORM' };
    }
  }

  if (info.memberGradeDisplayInfo.check === 'ANY') {
    if (!info.memberGradeDisplayInfo.info.length) return { name: 'grade', msg: 'PRODUCT.ADD.PLZ_SELECT_GRADE' };
  }

  if (!info.productName) return { name: 'productName', msg: 'PRODUCT.ADD.PLZ_INSERT_PRODUCT_NAME' };

  if (info.productDescInfo.useYn === 'Y') {
    if (!info.productDescInfo.text) return { name: 'productDesc', msg: 'PRODUCT.ADD.PLZ_INSERT_PRODUCT_DESC' };
  }

  return defaultSuccessResult;
};

const verifySalesInformation = (info: SalesInformation, optionUseYn: YorN): FailResult => {
  const isEmpty = cnt => cnt === null || cnt === '';

  if (info.useReservation && isEmpty(info.reservationInfoModel.stockCnt)) {
    return { name: 'reservation', msg: 'PRODUCT.ADD.PLZ_INSERT_RESERVATION_STOCK_COUNT' };
  }

  if (info.useContentsIfPausing && !info.contentsIfPausing) {
    return { name: 'salePrice', msg: 'PRODUCT.ADD.PLZ_INSERT_ALTERNATIVE_PHRASE', 'sub-type': 'contentsIfPausing' };
  }

  // if ((info.useContentsIfPausing && info.salePrice === '') || (!info.useContentsIfPausing && !info.salePrice)) {
  if (isEmpty(info.salePrice)) {
    return { name: 'salePrice', msg: 'PRODUCT.ADD.PLZ_INSERT_SALE_PRICE', 'sub-type': 'price' };
  }

  if (
    info.immediateDiscountInfo?.periodYn === 'Y' &&
    (!info.immediateDiscountInfo.startYmdt || !info.immediateDiscountInfo.endYmdt)
  ) {
    return {
      name: 'immediateDiscountInfo',
      msg: 'PRODUCT.ADD.PLZ_SET_IMMEDIATE_DISCOUNT_PERIOD',
      'sub-type': 'immediateDiscountPeriod',
    };
  }

  if (info.optionUseYn === 'N' && isEmpty(info.productStockCnt)) {
    return { name: 'stockCnt', msg: 'PRODUCT.ADD.PLZ_INSERT_STOCK_COUNT' };
  }

  if (optionUseYn === 'Y' && !info.options?.length && !info.customerDemands?.length) {
    return { name: 'options', msg: 'PRODUCT.ADD.PLZ_INSERT_OPTIONS' };
  }

  if (info.placeOriginInfo.use && !info.placeOriginInfo.placeOriginSeq) {
    return { name: 'placeOrigin', msg: 'PRODUCT.ADD.PLZ_SELECT_PLACE_ORIGIN', 'sub-type': 'placeOrigin' };
  }

  if (info.placeOriginInfo.isImported && info.placeOriginInfo.placeOrigin === '') {
    return { name: 'placeOrigin', msg: 'PRODUCT.ADD.PLZ_INSET_PLACE_COMPANY_NAME', 'sub-type': 'name' };
  }

  if (info.placeOriginInfo.isDirect && info.placeOriginInfo.placeOrigin === '') {
    return { name: 'placeOrigin', msg: 'PRODUCT.ADD.PLZ_INSERT_PLACE_ORIGIN', 'sub-type': 'name' };
  }

  if (info.dutyInfo.useYn === 'Y') {
    const { contents, categoryNo, categoryName } = info.dutyInfo.dutyContent;
    if (!categoryNo || !categoryName || !contents.length)
      return { name: 'duty', msg: 'PRODUCT.ADD.PLZ_INSERT_DUTY_INFO' };
  }

  if (info.certificationInfo.type === 'TARGET') {
    if (
      !info.certificationInfo.data.length ||
      !info.certificationInfo.data.some(info => info.certificationCategoryNo)
    ) {
      return { name: 'certification', msg: 'PRODUCT.ADD.PLZ_INSERT_CERTIFICATION' };
    }
  }
  return defaultSuccessResult;
};

const verifyImagesInformation = (info: ImagesInformation): FailResult => {
  // if (info.addOptionImageYn === 'Y' && !hasOptionImage) {
  //   return { name: CONTENT_TYPE.BODY, msg: '상품상세에 옵션이미지 사용 시 옵션이미지가 등록 되어야합니다.' };
  // }
  // for (const contentStatus in info.useStatus) {

  // if (info.addOptionImageYn !== 'Y' && info.useStatus[contentStatus] && info[contentStatus] === '') {
  //   const contentName = {
  //     contentHeader: CONTENT_TYPE.HEADER,
  //     content: CONTENT_TYPE.BODY,
  //     contentFooter: CONTENT_TYPE.FOOTER,
  //   };
  //   return { name: contentName[contentStatus], msg: '내용을 입력해주세요.' };
  // }
  // }

  for (const status in info.guideUseStatus) {
    if (
      info.guideUseStatus[status] === CONTENT_USE_TYPE.DIRECT &&
      info.productGuides.find(guide => guide.type === status)?.content === ''
    ) {
      return { name: status, msg: '안내를 입력해주세요.' };
    }
  }
  return defaultSuccessResult;
};

const verifyDeliveryInformation = (info: DeliveryInformation): FailResult => {
  if (info.deliveryYn === 'Y' && !info.deliveryTemplateNo) {
    return { name: 'deliveryTemplate', msg: 'PRODUCT.ADD.PLZ_SELECT_DELIVERY_TEMPLATE' };
  }
  return defaultSuccessResult;
};

const verifyMarketingSettingInfo = (marketingSettings: MarketingSettings[]): FailResult => {
  const allNotDisplayable = marketingSettings.every(setting => setting.displayable === false); // displayable 모두 미사용시 true
  const naverMarketing = marketingSettings.find(
    setting => setting.displayable && setting.channelType === 'NAVER_SHOPPING',
  )?.additionalInfo;
  const hasNaverMarketingData = marketingSettings.length && naverMarketing?.saleType === NAVER_PRODUCT_SALE_TYPE.RENTAL;

  if (marketingSettings.length && allNotDisplayable) {
    return { name: 'channelType', msg: 'PRODUCT.ADD.SELECT_MARKETING_CHANNEL' };
  }
  if (hasNaverMarketingData && !naverMarketing?.monthlyRentalFee) {
    return { name: 'naverShoppingEP', msg: 'PRODUCT.ADD.INSERT_MONTHLY_FEE' };
  }
  if (hasNaverMarketingData && !naverMarketing?.rentalContractPeriod) {
    return { name: 'naverShoppingEP', msg: 'PRODUCT.ADD.INSERT_CONTRACT_PERIOD' };
  }

  return defaultSuccessResult;
};

const verifyProductInfoHandler = ({
  basicInfo,
  salesInfo,
  imagesInfo,
  deliveryInfo,
  optionUseYn,
  marketingSettingInfo,
}): VerifyProductInfoResult => {
  const basicInfoResult: FailResult = verifyBasicInformation(basicInfo);
  if (basicInfoResult.name) {
    return getFailResult('basic', basicInfoResult);
  }

  const salesInfoResult: FailResult = verifySalesInformation(salesInfo, optionUseYn);
  if (salesInfoResult.name) {
    return getFailResult('sales', salesInfoResult);
  }

  const imagesInfoResult: FailResult = verifyImagesInformation(imagesInfo);
  if (imagesInfoResult.name) {
    return getFailResult('images', imagesInfoResult);
  }

  const deliveryInfoResult: FailResult = verifyDeliveryInformation(deliveryInfo);
  if (deliveryInfoResult.name) {
    return getFailResult('delivery', deliveryInfoResult);
  }

  const marketingSettingInfoResult: FailResult = verifyMarketingSettingInfo(marketingSettingInfo);
  if (marketingSettingInfoResult.name) {
    return getFailResult('marketingSetting', marketingSettingInfoResult);
  }

  return {
    success: true,
    fail: null,
  };
};

export const navBtnClickEvent = () => {
  return {
    copy: () => '',
    tempSave: () => '',
    verifyProductInfo: (productInfo: ProductInformation): VerifyProductInfoResult =>
      verifyProductInfoHandler(productInfo),
  };
};

export function convertToSearchKeywordDataType<DataType>({
  data,
  key,
  name,
}: ConvertToSearchKeywordDataType<DataType>): SearchKeywordData[] {
  if (!data.length) return;
  return data.map(item => ({
    selectKey: item[key],
    selectDisplayName: item[name],
    selectName: item[name],
  }));
}

// reserved - sales
export function getReservationDateRangeOption(fromRanges = today): DateRangeOption {
  return {
    name: 'reservation',
    dateType: 'YmdHms',
    hasPeriodBtn: false,
    fromRanges,
  };
}

export function getDeliveryDate(date: string): string {
  return `${getStrDate(date)} ${DEFAULT_TIME_RANGE.START}`;
}

export function changeableDate(date: string): boolean {
  return isBefore('', date);
}

export function convertToYmdAndHms(startYmdt: string, endYmdt: string): StartAndEndYmdHm {
  startYmdt = startYmdt ?? `${today} ${getToday('HH:mm:ss')}`;
  endYmdt = endYmdt ?? `${addDay(new Date(), 7)} ${DEFAULT_TIME_RANGE.END}`;
  return {
    startYmd: getStrDate(startYmdt),
    endYmd: getStrDate(endYmdt),
    startHms: changeDateFormat(startYmdt, 'HH:mm:ss'),
    endHms: changeDateFormat(endYmdt, 'HH:mm:ss'),
  };
}

export function getNoImageIdx(images) {
  return images.findIndex(img => img.imageUrl.includes('no_img'));
}

export function convertToYmdt(ymd?: string, hms?: string): string {
  ymd = !ymd ? today : ymd;
  hms = !hms ? DEFAULT_TIME_RANGE.START : hms;
  return `${ymd} ${hms}`;
}

export function getDateRangeOption(type, dateRangeOption, targetYmd) {
  return {
    ...dateRangeOption,
    fromRanges: `${addDay(targetYmd, 1)}`,
  };
}

export function getDefaultReservationInfo(stockCnt = 0) {
  return {
    deliveryYmdt: '',
    startYmdt: '',
    endYmdt: '',
    stockCnt,
  };
}

const getEndDate = (periodType: PeriodType, endYmdt: string): string => {
  if (periodType === 'REGULAR') return `${DEFAULT_DATE_RANGE.MAX} ${DEFAULT_TIME_RANGE.END}`;

  const end = new Date(endYmdt).getTime();
  const now = new Date().getTime();
  return end > now ? endYmdt : `${threeMonths} ${DEFAULT_TIME_RANGE.END}`;
};
export const getDefaultSalePeriodInfo = (periodType: PeriodType, endYmdt: string): SalePeriodInfo => {
  return {
    startYmdt: `${today} ${DEFAULT_TIME_RANGE.START}`,
    periodType: periodType,
    endYmdt: getEndDate(periodType, endYmdt),
  };
};

export function getDefaultOption(optionType: OptionType = OPTION_TYPE.DEFAULT): OptionPopupDataType {
  return {
    optionType,
    options: [],
    customerDemands: null,
    optionSelectType: 'MULTI',
  };
}

const prohibitionSale = [
  'urlShorteningYn',
  'relatedProductInfo',
  'supplierProductName',
  'productGuides',
  'keywords',
  'promotionTextInfo',
  'contentHeader',
  'purchasePrice',
  'deliveryDueDate',
  'productName',
  'optionType',
  'maxBuyCountInfo',
  'deliveryYn',
  'minorPurchaseYn',
  'options',
  'categoryNo',
  'mallProductListImage',
  'mallProductImages',
  'commissionInfo',
  'salePeriodInfo',
  'salePeriodInfo.periodType',
  'shippingAreaPartnerNo',
  'promotionTextUseYn',
  'brandName',
  'certificationInfo',
  'accumulationUseYn',
  'shippingAreaType',
  'refundableYn',
  'comparingPriceSiteInfo',
  'immediateDiscountInfo',
  'paymentMeansControlYn',
  'accumulationRate',
  'platformDisplayInfo',
  'cartInfo',
  'paymentMeans',
  'classType',
  'customerDemands',
  'displayCategoryNos',
  'productManagementCd',
  'searchengineDisplayYn',
  'minBuyCnt',
  'groupType',
  'dutyInfo',
  'productStockCnt',
  'deliveryCombinationYn',
  'content',
  'nonmemberPurchaseYn',
  'saleMethodType',
  'partnerChargeAmt',
  'partnerNo',
  'deliveryInternationalYn',
  'eanCode',
  'deliveryInfo',
  'deliveryCustomerInfo',
  'deliveryTemplateNo',
  'addOptionImageYn',
  'memberGradeDisplayInfo',
  'customPropertyValues',
  'tempSave',
  'expirationYmdt',
  'contentFooter',
  'adminNo',
  'manufactureYmdt',
  'salePrice',
  'reservationInfoModel',
  'reservationInfo.config',
  'urlDirectDisplayYn',
  'stickerNos',
  'stickerInfos',
  'promotionInfo',
  'memberGroupDisplayInfo',
  'valueAddedTaxType',
  'hsCode',
  'unitPrice',
  'unitPriceInfo',
  'productNameEn',
  'extraJson',
  'placeOriginInfo',
  'mallNo',
  'optionUseYn',
];
export const immutableFields = (mode: 'UPDATE' | 'COPY', type: 'UPDATE' | 'SYNC' | 'PROHIBITION_SALE'): string[] => {
  /*
   * 승인완료전(editFlag.isReady)상태와 승인완료후 상태의 disabled 처리가 다른 항목들
   * 쇼핑몰, 파트너사, 배송여부, 해외배송여부, 예약판매관련항목들 -> editFlag.isReady. editFlag.isSlave 조건에 따라 각 컴프넌트에서 disabled처리
   * */

  /*
   * 기존(default) : '승인완료 이후' disabled 처리될 항목들
   * 이외의 항목들은 각 컴포넌트에서 조건에 따라 처리
   * ex) 옵션항목은 각 컴포넌트에서 isMaster, isSlave로 처리
   * */
  const update = ['mallNo', 'partnerNo', 'deliveryYn', 'deliveryInternationalYn', 'tempSave']; //재고연동없는 일반상품 수정
  const copy = update.slice(0, update.length - 1);
  const synced = ['productStockCnt', 'optionUseYn', 'reservationStockCnt', 'productManagementCd']; // 재고연동

  const fields = {
    [IMMUTABLE_TYPE.UPDATE]: {
      [IMMUTABLE_TYPE.UPDATE]: update,
      [IMMUTABLE_TYPE.SYNC]: update.concat(synced),
      [IMMUTABLE_TYPE.PROHIBITION_SALE]: prohibitionSale,
    },
    [IMMUTABLE_TYPE.COPY]: {
      [IMMUTABLE_TYPE.SYNC]: copy.concat(synced),
    },
  };

  return fields[mode][type] ?? [];
};
