



































































































import { Vue, Component, Ref } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  adRegistNoticeList,
  biddingStrategyType,
  getConfigCampaigns,
  changeCampaignStatus,
  adDataInit,
} from './GoogleConfigureCheck';
import NoticeBox from '@/components/common/NoticeBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { getCurrentMallNo } from '@/utils/mall';

@Component({
  components: {
    NoticeBox,
    TextInput,
    SelectBox,
    ToolTip,
  },
})
export default class GoogleAdRegist extends Vue {
  private isDisableInput = true;
  private noticeList = adRegistNoticeList();
  private adData = adDataInit;
  private adStatues = 'MODIFY';
  private biddingStrategyType = biddingStrategyType;

  @Ref('dailyBudget') private readonly dailyBudget;
  @Ref('cpaCost') private readonly cpaCost;
  @Ref('roasRate') private readonly roasRate;

  private get mallNo() {
    return getCurrentMallNo(this);
  }

  async created() {
    const resAdData = await getConfigCampaigns(this.mallNo);

    if (resAdData === null) {
      this.adStatues = 'REGIST';
    } else {
      this.adData = resAdData;
      this.adStatues = 'MODIFY';
    }
  }

  private changeDisableInput() {
    if (this.isDisableInput) {
      this.isDisableInput = false;
    } else {
      this.isDisableInput = true;
    }
  }

  private checkBudgetInputVal() {
    if (!this.adData.budget.dailyBudget) {
      alert(this.$t('MARKETING.CONFIGURATION.TARGET_CHANGE_BUDGET_ALERT_NO_TEXT'));
      this.dailyBudget.focus();
      return false;
    }

    if (this.adData.budget.dailyBudget > 100000 || this.adData.budget.dailyBudget < 10000) {
      alert(this.$t('MARKETING.CONFIGURATION.TARGET_CHANGE_BUDGET_ALERT'));
      this.dailyBudget.focus();
      return false;
    }

    return true;
  }

  private checkCpaInputVal() {
    if (!this.adData.biddingStrategy.cpaCost) {
      alert(this.$t('MARKETING.CONFIGURATION.TARGET_CHANGE_CPA_ALERT_NO_TEXT'));
      this.cpaCost.focus();
      return false;
    }

    if (this.adData.biddingStrategy.cpaCost < 1000 || this.adData.biddingStrategy.cpaCost > 200000) {
      alert(this.$t('MARKETING.CONFIGURATION.TARGET_CHANGE_CPA_ALERT'));
      this.cpaCost.focus();
      return false;
    }

    return true;
  }

  private checkRoasInputVal() {
    if (!this.adData.biddingStrategy.roasRate) {
      alert(this.$t('MARKETING.CONFIGURATION.TARGET_CHANGE_ROAS_ALER_NO_TEXT'));
      this.roasRate.focus();
      return false;
    }

    if (this.adData.biddingStrategy.roasRate < 10 || this.adData.biddingStrategy.roasRate > 9999) {
      alert(this.$t('MARKETING.CONFIGURATION.TARGET_CHANGE_ROAS_ALERT'));
      this.roasRate.focus();
      return false;
    }

    return true;
  }

  private async postGoogleAd() {
    if (!this.checkBudgetInputVal()) return;

    if (!this.isDisableInput) {
      if (this.adData.biddingStrategy.type === 'CPA') {
        if (!this.checkCpaInputVal()) return;
      } else {
        if (!this.checkRoasInputVal()) return;
      }
    }

    const type = this.adData.biddingStrategy.type;

    try {
      if (this.adStatues === 'REGIST') {
        this.postConfigurationsCampaign(type);
      } else {
        this.putConfigurationsCampaigns(type);
      }
    } catch (e) {
      alert(window.$t(`MARKETING.CONFIGURATION.FAIL_GOOGLE_CONFIG`));
      console.error(e);
    }
  }

  private async postConfigurationsCampaign(type) {
    const request = {
      data: {
        mallNo: this.mallNo,
        dailyBudget: Number(this.adData.budget.dailyBudget),
        biddingStrategyType: type,
        cpaCost: type === 'CPA' ? this.adData.biddingStrategy.cpaCost : null,
        roasRate: type === 'ROAS' ? this.adData.biddingStrategy.roasRate : null,
      },
    };

    try {
      await this.$api.postConfigurationsCampaigns(request);
      alert(window.$t(`MARKETING.CONFIGURATION.COMPLETE_GOOGLE_CONFIG`));
      this.$emit('confirm', { state: 'reRender' });
    } catch (e) {
      console.error(e);
      this.$emit('click:close');
    }
  }

  private async putConfigurationsCampaigns(type) {
    const request = {
      data: {
        mallNo: this.mallNo,
        campaignNo: this.adData.campaignNo,
        campaignBudgetId: this.adData.budget.budgetId,
        dailyBudget: Number(this.adData.budget.dailyBudget),
        biddingStrategyType: type,
        cpaCost: type === 'CPA' ? this.adData.biddingStrategy.cpaCost : null,
        roasRate: type === 'ROAS' ? this.adData.biddingStrategy.roasRate : null,
      },
    };

    try {
      await this.$api.putConfigurationsCampaigns(request);

      if (this.adData.status === 'PAUSED') {
        await changeCampaignStatus('nowPaused', this.mallNo, this.adData.campaignNo);
        this.$emit('confirm', { state: 'reRender' });
        return;
      }

      alert(window.$t(`MARKETING.CONFIGURATION.MODIFY_GOOGLE_CONFIG`));
      this.$emit('confirm', { state: 'reRender' });
    } catch (e) {
      console.error(e);
      this.$emit('click:close');
    }
  }
}
