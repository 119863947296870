export const KEYWORD_TYPE = {
  PRODUCT_NAME: 'PRODUCT_NAME',
  PRODUCT_NO: 'PRODUCT_NO',
  PRODUCT_MANAGEMENT_CD: 'PRODUCT_MANAGEMENT_CODE',
} as const;

export const SearchFormSelectorOption = {
  // 검색어 타입
  keywordType: [
    {
      name: 'PRODUCT.COMMON.PRODUCT_NAME',
      value: KEYWORD_TYPE.PRODUCT_NAME,
    },
    {
      name: 'PRODUCT.COMMON.PRODUCT_NO',
      value: KEYWORD_TYPE.PRODUCT_NO,
    },
    {
      name: 'PRODUCT.COMMON.PRODUCT_MANAGEMENT_CD',
      value: KEYWORD_TYPE.PRODUCT_MANAGEMENT_CD,
    },
  ],
  // 승인상태
  applyStatusType: [
    {
      name: 'PRODUCT.PRODUCT.REGISTER_WAIT',
      value: 'REGISTRATION_READY',
    },
    {
      name: 'PRODUCT.PRODUCT.APPROVAL_COMPLETED',
      value: 'FINISHED',
    },
  ],
  // 전시상태
  frontDisplayable: [
    {
      name: 'PRODUCT.COMMON.FRONT_DISPLAY',
      value: 'true',
    },
    {
      name: 'PRODUCT.COMMON.FRONT_NO_DISPLAY',
      value: 'false',
    },
  ],
  // 판매상태
  saleMethodType: [
    {
      name: 'PRODUCT.COMMON.SALE_METHOD_TYPE_PURCHASE',
      value: 'PURCHASE',
    },
    {
      name: 'PRODUCT.COMMON.SALE_METHOD_TYPE_CONSIGNMENT',
      value: 'CONSIGNMENT',
    },
  ],
};
