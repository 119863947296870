


























import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import SmsHistorySearchForm from '@/views/contents/member/sms/SmsHistorySearchForm.vue';
import Grid from '@/components/common/grid/Main.vue';
import { getDisplayOptions, getSmsHistoryGridOption, getSmsHistoryInitQuery } from '@/const/contents/sms';
import { GetSmsGodoSends, GetSmsGodoSendsRequest, NCPResponse, GetSmsGodoSendsContent } from 'ncp-api-supporter';
import { GridEventProps } from '@/types';
import { getCurrentMallNo } from '@/utils/mall';
import { isEmpty, omit } from 'underscore';
import { Route } from 'vue-router';
import { checkSearchResult, openHistoryDetailsLayer } from '@/views/contents/member/kakao/SearchTemplates';
import { GetSmsGodoTokenPopupSmsDetailResponse } from 'ncp-api-supporter/dist/types';
@Component({
  components: { SmsHistorySearchForm, Grid },
})
export default class SmsHistory extends Vue {
  @Ref()
  private readonly grid!: Grid;
  private contents: GetSmsGodoSendsContent[] = [];
  private totalCount = 0;
  private gridOption = getSmsHistoryGridOption();
  private displayOptions = getDisplayOptions();
  private myIp = '';

  @Watch('$route')
  private async searchHistory(currRoute: Route, prevRoute: Route) {
    const needsReset = checkSearchResult(currRoute, prevRoute, 'MUST_SEARCH_HISTORY', this);
    if (needsReset) return;

    try {
      if (isEmpty(omit(this.$route.query, 'mallNo'))) return;
      const request: GetSmsGodoSendsRequest = {
        params: { ...getSmsHistoryInitQuery(getCurrentMallNo(this)), ...this.$route.query },
      };
      const response: NCPResponse<GetSmsGodoSends> = await this.$api.getSmsGodoSends(request);
      this.totalCount = response.data.totalCount ?? 0;
      this.contents = response.data.contents ?? [];
      this.grid.changeNoDataMessage(this.$t('NO_RESULT'));
    } catch (e) {
      console.error(e);
    }
  }

  private async onItemClicked(gridProps: GridEventProps) {
    if (gridProps.columnName !== 'resultStatus') return;
    const { sendDate: date, smsSendKey: sendKey } = this.grid.getRowAt(gridProps.rowKey);
    await openHistoryDetailsLayer<GetSmsGodoTokenPopupSmsDetailResponse>(
      'sms',
      {
        mallNo: getCurrentMallNo(this),
        sendDate: date.toString(),
        sendKey: sendKey.toString(),
      },
      this.myIp,
    );
  }
  catch(err) {
    console.error(err);
  }

  mounted() {
    this.grid.changeNoDataMessage(this.$t('MUST_SEARCH_HISTORY'));
    this.fetchMyIp();
  }

  async fetchMyIp() {
    const { data } = await this.$api.getManagerInfo();
    if (data?.ip) this.myIp = data.ip;
  }
}
