


















import { Component, Vue, Prop, Watch, Model, PropSync } from 'vue-property-decorator';
import ChainingSelector from '@/components/common/ChainingSelector.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { OptionData } from '@/helpers/type';
import { DepthDataType } from '@/types';
import { GetDisplayCategoriesTreeRequest, GetStandardCategoriesTreeRequest, NCPResponse } from 'ncp-api-supporter';
import { DisplayCategoriesTree } from 'ncp-api-supporter/dist/types/modules/display/displayCategory';
import { GetStandardCategoriesTree } from 'ncp-api-supporter/dist/types/modules/display/standardCategory';
import { isEqual } from 'underscore';
import { getCurrentMallNo } from '@/utils/mall';

export enum CategoryType {
  DISPLAY = 'DISPLAY',
  STANDARD = 'STANDARD',
}

const categoryTypes: OptionData<CategoryType>[] = [
  {
    value: CategoryType.DISPLAY,
    name: 'PRODUCT.COMMON.DISPLAY_CATEGORY',
  },
  {
    value: CategoryType.STANDARD,
    name: 'PRODUCT.COMMON.CATEGORY',
  },
];

@Component({
  components: {
    SelectBox,
    ChainingSelector,
  },
})
export default class CategorySelector extends Vue {
  @Model('change') selectedValue!: string;
  @Prop({ required: true })
  private readonly mallNo!: string;
  @PropSync('categoryType') private categoryTypeSync!: string;
  @PropSync('categoryDepth') private categoryDepthSync!: string;

  private selectedCategoryNo = '';
  private categoryTypes: OptionData<CategoryType>[] = categoryTypes;

  private standardCategoryDepthData: DepthDataType | null = null;
  private displayCategoryDepthData: DepthDataType | null = null;

  created() {
    if (!this.mallNo) return;
    this.getCategoryDataByCategoryType();
  }

  @Watch('mallNo')
  private onChangeMallNo(mallNo) {
    if (this.categoryTypeSync === CategoryType.STANDARD) return;

    this.resetSelectedType();
    this.resetSelectedNo();
    mallNo ? this.getDisplayCategoryData() : (this.displayCategoryDepthData = null);
  }

  @Watch('selectedCategoryNo')
  private onChangeSelectedCategoryNo() {
    this.$emit('change', this.selectedCategoryNo);
  }

  private resetSelectedNo(): void {
    this.selectedCategoryNo = '';
    this.categoryDepthSync = '';
  }

  private resetSelectedType(): void {
    this.categoryTypeSync = CategoryType.DISPLAY;
  }

  private alertByMallNoCondition(): void {
    if (this.mallNo) return;

    this.$emit('fail:no-mall-number');
    alert(this.$t('PRODUCT.PRODUCT.SELECT_MALL'));
  }

  private onChangeCategoryType(): void {
    this.resetSelectedNo();
    this.getCategoryDataByCategoryType();
  }

  private getCategoryDataByCategoryType() {
    switch (this.categoryTypeSync) {
      case CategoryType.DISPLAY:
        this.mallNo ? this.getDisplayCategoryData() : (this.displayCategoryDepthData = null);
        break;
      case CategoryType.STANDARD:
        if (this.standardCategoryDepthData) return;
        this.getStandardCategoryData();
        break;
      default:
        throw new Error('Unknown category type');
    }
  }

  private onUpdatedDepth(depth: string): void {
    this.categoryDepthSync = depth;
  }

  get depthData(): DepthDataType {
    return this.categoryTypeSync === CategoryType.DISPLAY
      ? this.displayCategoryDepthData
      : this.standardCategoryDepthData;
  }

  private recentDisplayCategoriesTreeRequest!: GetDisplayCategoriesTreeRequest;
  private recentDisplayCategoriesTreeResponse!: NCPResponse<DisplayCategoriesTree[]>;
  private async getDisplayCategoryData(): Promise<void> {
    const request: GetDisplayCategoriesTreeRequest = {
      params: {
        mallNo: Number(this.mallNo),
      },
    };
    const sameCall = isEqual(this.recentDisplayCategoriesTreeRequest, request);

    const response: NCPResponse<DisplayCategoriesTree[]> = sameCall
      ? this.recentDisplayCategoriesTreeResponse
      : await this.$api.getDisplayCategoriesTree(request);
    this.recentDisplayCategoriesTreeRequest = request;
    this.recentDisplayCategoriesTreeResponse = response;

    this.setDisplayCategoryDepthData(response.data);
  }

  private async getStandardCategoryData(): Promise<void> {
    const request: GetStandardCategoriesTreeRequest = {
      params: {
        mallNo: this.mallNo || getCurrentMallNo(this).toString(),
      },
    };

    const { data }: NCPResponse<GetStandardCategoriesTree> = await this.$api.getStandardCategoriesTree(request);

    this.setStandardCategoryDepthData(data);
  }

  private setDisplayCategoryDepthData(data: DisplayCategoriesTree[]): void {
    this.displayCategoryDepthData = {
      data,
      labelKey: 'displayCategoryName',
      valueKey: 'displayCategoryNo',
      childrenKey: 'children',
    };
  }

  private setStandardCategoryDepthData(data: GetStandardCategoriesTree): void {
    this.standardCategoryDepthData = {
      data: data.tree,
      labelKey: 'name',
      valueKey: 'categoryNo',
      childrenKey: 'child',
    };
  }
}
