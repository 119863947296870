








































import { Component, Watch, Mixins } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { getProductReviewListGridOption } from '@/const/contents/review';
import ReviewsMixin from '@/views/contents/board/basic/mixins/ReviewsMixin';
import { CheckGridEventProps, GridEventProps, CreateExcel } from '@/types';
import { RowKey } from '@/types/tui-grid';
import { throwPopup, PopupClose, throwWindowPopupWithProps } from '@/helpers/popup';
import { ReviewDisplayStatusType } from 'ncp-api-supporter';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { throwExternalWindowPopup } from '@/helpers/popup';
import { setNoDataMessage, resetNoDataMessage } from '@/helpers/grid';
@Component({
  components: {
    Grid,
  },
})
export default class Reviews extends Mixins(ReviewsMixin) {
  private checkedRows: RowKey[] = [];
  private gridProps = getProductReviewListGridOption(this);
  private mallNo = 0;
  private createExcel: CreateExcel = {
    menuKey: 'PRO_PRODUCT_REVIEW_LIST',
    query: { ...this.$route.query },
  };

  @Watch('$route.query.mallNo')
  private getCurrentMallNo() {
    this.mallNo = Number(this.$route.query?.mallNo);
  }

  @Watch('$route')
  private onQueryStringChanged() {
    const { query } = this.$route;
    this.gridProps.options.pageOptions.page = query.page ? query.page : this.gridProps.options.pageOptions.page;
    this.gridProps.options.pageOptions.perPage = query.size ? query.size : this.gridProps.options.pageOptions.perPage;
    this.search();
  }
  search() {
    this.createExcel.query = { ...this.$route.query };
    this.getReviews({ ...this.$route.query });
    this.checkedRows = [];
  }
  private onItemClicked({ rowKey, columnName }: GridEventProps): void {
    if (!columnName) return;
    if (!rowKey) return;
    const review = this.reviews.find(review => review.reviewNo === rowKey);
    switch (columnName) {
      case 'reviewNo':
        this.openReviewDetail(rowKey as number, this.mallNo);
        break;
      case 'productNo':
        this.openProductDetail(review.productNo);
        break;
      case 'member':
        if (review.register.memberNo > 0 && review.register.memberStatus !== 'WITHDRAWN') {
          this.openMemberDetail(review.register.memberNo);
        }
        break;
      case 'history':
        this.openReviewHistory(rowKey as number);
        break;
    }
  }
  openReviewHistory(reviewNo: number) {
    throwPopup({
      name: 'ReviewHistoryPopup',
      data: {
        reviewNo,
      },
    });
  }
  openReviewDetail(reviewNo: number, mallNo: number) {
    throwWindowPopupWithProps({
      componentName: 'ReviewDetail',
      data: { reviewNo, mallNo },
      query: { reviewNo },
      size: 'xlg',
      onClose: this.detailCallback,
    });
  }
  detailCallback() {
    this.search();
  }
  openProductDetail(productNo: number) {
    openEditProductWindow(productNo);
  }
  openMemberDetail(memberNo: number) {
    throwExternalWindowPopup(`/pro/crm/${memberNo}`, 'xxlg');
  }
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.checkedRows = [...checkProps.selected];
  }
  changeMoreReviewProperty(property: string) {
    if (this.checkedRows.length) {
      throwPopup({
        name: 'UpdateReviewsPropertyPopup',
        data: {
          reviews: this.checkedRows,
          property,
        },
      }).then((result: { state: string; data: string }) => {
        if (result && result.state === PopupClose.CONFIRM) {
          this.search();
          return result;
        }
      });
    } else {
      alert(this.$t('BOARD.REVIEW.ALERT_SELECT_REVIEW'));
    }
  }
  displayStatusTypeChanged(rowKey: number, event: Event) {
    const value = (event.target as HTMLInputElement).value as ReviewDisplayStatusType;
    this.putReviewDisplayStatusType(rowKey.toString(), value).then(() => {
      alert(this.$t('BOARD.REVIEW.UPDATED'));
      this.search();
    });
  }
  reviewTypeChanged(rowKey: number, event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.putReviewBestReview(rowKey.toString(), value === 'BEST' ? 'Y' : 'N').then(() => {
      alert(this.$t('BOARD.REVIEW.UPDATED'));
      this.search();
    });
  }
  created() {
    setNoDataMessage(this.$t('BOARD.REVIEW.NO_DATA').toString());
  }
  private destroyed() {
    resetNoDataMessage();
  }
}
