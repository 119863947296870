export default Object.freeze({
  // STOCK_MANAGEMENT_
  TITLE: '상품재고관리',
  TOTAL_SEARCH_RESULT: '상품검색결과 총',
  NOTICE_DESCRIPTION1: '승인완료 된 상품의 옵션만 조회되며, 판매금지 된 상품의 옵션은 조회되지 않습니다.',
  NOTICE_DESCRIPTION2: '재고연동 복사 상품인 경우 마스터 상품의 옵션만 조회됩니다.',
  // NOTICE_DESCRIPTION3: '세트상품은 다른 상품의 옵션을 불러와 등록했기 때문에 조회되지 않습니다.',
  NOTICE_DESCRIPTION4: '마스터 상품의 정보는 재고연동 복사 상품에 동일하게 적용됩니다.',
  SEARCH_WORD: '검색어',
  PRODUCT_MANAGEMENT_CD_LIKE: '상품관리코드',
  INPUT_NUMBER_ERROR: '숫자만 입력 가능합니다.',
  KEY_WORD_NUMBER_ERROR: '숫자와 콤마만 입력할 수 있습니다',
  KEY_WORD_NULL_ERROR: '검색어를 입력해주세요.',
  PRODUCT_MANAGEMENTCD_LIKEKEYWORD_PLACEHOLDER: '일부 상품관리코드와 %를 이용한 부분 검색이 가능합니다.',
  SALES_STATUS: '판매상태',
  SALES_STATUS_ALL: '전체선택',
  SALES_STATUS_READY: '판매대기',
  SALES_STATUS_ONSALE: '판매중',
  SALES_STATUS_STOP: '판매중지',
  SALES_STATUS_FINISHED: '판매종료',
  BRAND_NAME: '브랜드명',
  ENTER_BRAND_NAME: '브랜드명 입력',
  PARTNERS: '파트너사',
  PARTNERS_PLACEHOLDER: '파트너명 입력',
  TERM_REGISTER_YMDT: '상품등록일',
  TERM_START_YMDT: '판매시작일',
  TERM_END_YMDT: '판매종료일',
  TERM_UPDATE_YMDT: '재고수정일',
  SELECT_PERIOD: '기간선택',
  ADVANCED_SEARCH: '상세검색',
  STOCKRANGETYPE_NONE: '재고여부',
  SOLD_OUT: '재고없음',
  UNDER_FIVE: '5개 이하',
  UNDER_TEN: '10개 이하',
  UNDER_FIFTY: '50개 이하',
  UNDER_HUNDRED: '100개 이하',
  NONE: '플랫폼구분',
  PC: 'PC',
  MOBILE_APP: '모바일 앱',
  MOBILE_WEB: '모바일 웹',
  PRODUCT_INQUIRY_RESULT: '상품조회결과',
  PRODUCT_OPTION: '상품옵션',
  ADJUST_BTN: '조정재고 일괄적용',
  EDIT_BTN: '수정재고 일괄적용',
  FINAL_STORAGE: '최종저장',
  EXCEL_DOWNLOAD: '재고변경용 엑셀다운로드',
  EXCEL_UPLOAD: '재고 일괄업로드',
  FAILURE_RESULT: '상품재고관리_실패결과',

  //message
  MAX_KEYWORD_MESSAGE: '검색어는 최대 10개까지 입력 가능합니다.',
  ERR_STOCK_CNT_MESSAGE: '재고수량 입력이 올바르지 않습니다.',
  HELP_EDIT_STOCK:
    '· 수정재고 : 기존 재고수량에서  증·차감(+/-)이 필요한 재고 수량<br>· 재고 수정 중 상품 주문으로 재고 수량에 변동이 생기면, 상품재고관리 리스트에 표시된 재고 수량과 관계없이 변동된 수량에서 수정재고 항목에 입력된 값이 증/차감되어 변경됩니다.<br>· 상품 선택 후 수정재고와 조정재고를 모두 입력하지 않은 공란의 상태로 저장 시 기존 재고수량이 유지됩니다. ',
  HELP_ADJUST_STOCK:
    '· 조정재고 : 저장 이후 재고수량에 적용 할 재고로 최종 반영 전 조정된 재고 수량<br>· 재고 수정 중 상품 주문으로 재고 수량이 변동되어도 입력된 조정재고 수량으로 변경됩니다.<br>· 상품 선택 후 수정재고와 조정재고를 모두 입력하지 않은 공란의 상태로 저장 시 기존 재고수량이 유지됩니다. ',

  //재고 변경 이력 팝업
  HISTORY_TITLE: '상품옵션 재고 변경 이력',
  SHOPPING_MALL_FULL: '쇼핑몰 전체',
  DISPLAY_CATEGORY: '전시 카테고리',
  TYPE_CATEGORY: '표준 카테고리',

  //그리드 헤더
  NO: '번호',
  MALL_NAME: '쇼핑몰',
  MALL_PRODUCT_NO: '상품번호',
  PRODUCT_MANAGEMENT_CD: '상품관리코드',
  PRODUCT_NAME: '상품명',
  MALL_OPTION_NO: '옵션번호',
  OPTION_MANAGEMENT_CD: '옵션관리코드',
  EXTRA_MANAGEMENT_CD: '추가관리코드',
  OPTION_NAME_AND_VALUE: '옵션명:옵션값',
  BRAND: '브랜드',
  SALE_PERIOD: '판매기간',
  CATEGORY_FULL_NAME: '전시카테고리',
  SALE_PRICE: '판매가',
  APPLY_DISCOUNT_SALE_PRICE: '즉시할인가',
  APPLY_OPTION_ADD_PRICE: '옵션적용가',
  STOCK_CNT: '재고수량',
  ADJUST_STOCK_CNT: '조정재고',
  EDIT_STOCK_CNT: '수정재고',
  STOCK_NO: '재고번호',
  SOLD_OUT_STATUS: '품절상태',
  SOLD_OUT_STATUS_PROHIBITION_SALE: '품절',
  SOLD_OUT_STATUS_AVAILABLE_FOR_SALE: '정상',
  SOLD_OUT_STATUS_STOP_SELLING: '임시품절',
  DELIVERY_WAITING_STOCK_CNT: '출고대기',
  STOCK_CHANGE_HISTORY_NO: '재고 변경 이력',
  UPDATE_ADMIN_NAME: '최종수정자',
  UPDATE_YMDT: '재고 수정일',
  MORE_THAN: '개 이상 ~',
  LESS_THAN: '개 이하',

  GRIDHEADER_SAFETYSTOCKCNT: '안전재고',
  GRIDHEADER_SAFETYSTOCKSYNCYN: '안전재고연동여부',
  GRID_SOLD_OUT: '품절',
  GRID_AVAILABLE_FOR_SALE: '판매가능',
  GRID_SAFETYSTOCKSYNCY: '안전재고연동',
  GRID_SAFETYSTOCKSYNCN: '안전재고연동안함',

  SALESTATUSTYPE_ERROR: '판매상태를 체크해주세요.',
  NO_DATA_ERROR: '데이터가 존재하지 않습니다.',
  ADJUSTABLE_RANGE: '조정가능 범위가 아닙니다.',

  POPUP_INPUT: '입력',
  SELECT_FILE: '파일선택이 완료되었을 경우에만 호출하라구 친구!',
  EXCEL_NO_DATA: '엑셀 서식을 다시 확인해주세요.',
  EXCEL_DATA_ERROR: '엑셀 데이터를 정확히 입력해주세요.',
  LAST_SAVE: '저장되었습니다.',
  EXCEL_NAME_ERROR: '등록 실패 했습니다. 확장자명을 다시 확인해주세요.',
  STOCK_MODIFY_TITLE_EDIT: '수정재고 일괄변경',
  STOCK_MODIFY_TITLE_ADJUST: '조정재고 일괄변경',

  //재고 수정 팝업
  STOCK_MODIFY_COUNT: '재고수정 개수',
  STOCK_MODIFY_SELECTED: '선택된 상품옵션',
  STOCK_MODIFY_EDIT_DESCRIPTION1:
    '건에 적용할 수정재고를 입력하세요.\n음수를 입력하는 경우 기존 재고수량에서 차감됩니다.',
  STOCK_MODIFY_EDIT_DESCRIPTION2: '저장 즉시 입력된 재고수정 개수가 가감되어 상품옵션의 재고수량이 변경됩니다.',
  STOCK_MODIFY_ADJUST_DESCRIPTION1: '건에 적용할 조정재고를 입력하세요.',
  STOCK_MODIFY_ADJUST_DESCRIPTION2: '저장 즉시 입력된 재고수정 개수가 적용되어 상품옵션의 재고수량이 변경됩니다.',
  STOCK_MODIFY_EDITCHECKCOUNT: '건 상품에 대해 수정재고 일괄 업데이트 됩니다.',
  STOCK_MODIFY_ADJUSTED_STOCK_COUNT: '조정재고 개수',
  STOCK_MODIFY_EDIT_STOCK_COUNT: '재고수정 개수',
  STOCK_MODIFY_CONFIRM: '선택된 상품옵션 {0}개의 재고수량을 변경하시겠습니까?',
  STOCK_MODIFY_EMPTY: '재고수정 개수를 입력해주세요.',
  STOCK_MODIFY_SAVED: '저장되었습니다.',
  EXCEL_FAIL: '{0}건 실패 되었습니다.',
  //재고 수정 결과 팝업
  MODIFICATION_RESULT_TITLE: '수정재고 일괄변경 결과',
  MODIFICATION_RESULT: '일괄적용 결과',
  MODIFICATION_RESULT_NOTICE1: '총 {0}개의 상품옵션 중',
  MODIFICATION_RESULT_NOTICE2: '개의 수정재고 변경이 불가합니다.',
  FAILURE_REASON: '실패사유',

  //재고 변경 이력 팝업
  HISTORY_CHANGE_DATE_TIME: '변경일시',
  HISTORY_UPDATE_CNT: '변경재고',
  HISTORY_STOCK_CNT: '최종재고<br>(판매가능재고)',
  HISTORY_SAFETY_STOCK_CNT: '안전재고',
  HISTORY_CONTENTS: '내용',
  HISTORY_ADMIN_NAME: '담당자',

  //엑셀 업로드 팝업
  EXCEL_UPLOAD_NOTICE_1:
    '재고변경용 엑셀다운로드 후 변경이 필요한 수정재고/조정재고/옵션관리코드 정보를 입력 후 업로드하세요.',
  EXCEL_UPLOAD_NOTICE_2: '일괄 작성된 수정 및 조정된 재고 수량기준으로 업로드 완료 시 일괄 변경됩니다.',
  EXCEL_UPLOAD_NOTICE_3:
    '재고 일괄업로드를 완료하면 마스터 상품과 재고 연동 복사된 상품의 옵션 정보도 즉시 변경됩니다.',
  EXCEL_UPLOAD_NOTICE_4:
    '재고변경용 엑셀다운로드 파일에서 재고번호를 삭제하는 경우 파일이 정상적으로 업로드되지 않습니다.',
  EXCEL_UPLOAD_FAILURE_MESSAGE: '{0}건 실패 되었습니다.',

  MALL_NO: '쇼핑몰번호',
  EXCELTITLE_HANDLERTYPE: '재고 변경 타입',
  EXCELTITLE_CURRENTSTOCK: '재고수량',
  CATEGORY_INFO_POP: '전시카테고리 추가정보',
  EXAMPLE: '보기',
  ERR_PRODUCT_NO_SELECT: '상품옵션을 선택해주세요',
  POP_AGAIN_MSG: '팝업을 다시 열어주세요.',
  ADJUST_CNT_MSG: '조정가능범위가 아닙니다.',
  EDIT_CNT_MSG: '마이너스 수정 재고가 재고수량보다 큽니다.',
  MODIFY_STOCK_TYPE: '재고선택',
  MODIFY_STOCK_CNT: '재고수량 입력',
  UPLOAD_ERR: '{0}건 실패 되었습니다.',
});
