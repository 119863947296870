import moment, { Moment, MomentInput, unitOfTime } from 'moment'; // eslint-disable-line
import { StartAndEndYmdHm } from '@/types';
import { OptionData } from '@/helpers/type';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';

export const getUnitDigitStr = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

export function changeDateFormat(time: string | Date, format: string): string {
  if (typeof time === 'string' && time === '') {
    return moment().format(format);
  }
  return moment(time).format(format);
}

export function getStrDate(date: Date | string, format = 'YYYY-MM-DD'): string {
  return moment(date).format(format);
}
export function getStrHms(date: Date | string): string {
  return moment(date).format('HH:mm:ss');
}
export function getToday(format = 'YYYY-MM-DD'): string {
  return getStrDate(new Date(), format);
}

export function getDownToday(): string {
  return moment(new Date()).format('YYYYMMDD_HHmmss');
}

export function getBeforeDate(firstDay?: number): string {
  let beforeDay = new Date().getDate() - 1;
  if (firstDay && beforeDay < firstDay) {
    beforeDay = firstDay;
  }
  if (beforeDay === 0) beforeDay = 1;
  const beforeDate = new Date().setDate(beforeDay);

  return getStrDate(moment(beforeDate).toDate());
}

export function firstDayOfMonth(): string {
  const firstDate = new Date().setDate(1);

  return getStrDate(moment(firstDate).toDate());
}

export function getFirstAndEndDay(year = moment().year(), month = moment().month() + 1): StartAndEndYmdHm {
  const startYmd = getStrDate(new Date(year, month - 1, 1));
  const endYmd = getStrDate(new Date(year, month, 0));
  return { startYmd, endYmd };
}

export function getStrYMDHMSS(date: Date | string): string {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function getStrYMDHM(date: Date | string): string {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export function addDay(date: Date | string, days: number): string {
  return moment(date)
    .add(days, 'days')
    .format('YYYY-MM-DD');
}

export function addMonth(date: Date | string, months: number): string {
  return moment(date)
    .add(months, 'months')
    .format('YYYY-MM-DD');
}

export function addYear(date: Date | string, years: number): string {
  return moment(date)
    .add(years, 'years')
    .format('YYYY-MM-DD');
}

export function getSelectYears(endWord: string, isPast = true, length = 3): OptionData<number>[] {
  const year = new Date().getFullYear();
  const result: OptionData<number>[] = [];
  if (isPast) {
    for (let i = length; i >= 0; i--) {
      const selectYear = { value: year - i, name: `${year - i}${endWord}` };
      result.push(selectYear);
    }
  } else {
    for (let i = 0; i <= length; i++) {
      const selectYear = { value: year + i, name: `${year + i}${endWord}` };
      result.push(selectYear);
    }
  }
  return result;
}

export function getSelectMonths(endWord: string, start = 1, end = 12): OptionData<number>[] {
  const result: OptionData<number>[] = [];
  for (let i = start; i <= end; i++) {
    const selectMonth = { value: i, name: `${i}${endWord}` };
    result.push(selectMonth);
  }
  return result;
}

export function getSelectDays(endWord: string, selectMonth: number): OptionData<number>[] {
  const result: { value: number; name: string }[] = [];
  const oddMonth = [1, 3, 5, 7, 8, 10, 12];
  const evenMonth = [4, 6, 9, 11];
  let days = 31;
  if (oddMonth.includes(selectMonth)) {
    days = 31;
  } else if (evenMonth.includes(selectMonth)) {
    days = 30;
  } else {
    days = 29;
  }
  for (let i = 1; i <= days; i++) {
    const selectDay = { value: i, name: `${i}${endWord}` };
    result.push(selectDay);
  }
  return result;
}

/**
 * 생년월일 년도 금년 기준으로 120년 이전까지
 * */
export function getSelectBirthDayYears(endWord: string): object[] {
  const year = new Date().getFullYear();
  const result: { value: number; name: string }[] = [];
  for (let i = 0; i <= 120; i++) {
    const selectYear = { value: year - i, name: `${year - i}${endWord}` };
    result.push(selectYear);
  }
  return result;
}

/**
 * Mutates the original moment by adding time.
 * https://momentjs.com/docs/#/manipulating/add/
 * @author AZu
 * @param date string
 * @param addDays []
 * @param format string
 */
export function momentAdd(date: string, addDays: [], format: string): string {
  return moment(date)
    .add(...addDays)
    .format(format);
}

// 남은 시간 : 03:23
export function drawRemainingDurationTime(remaining: number, format = 'mm:ss') {
  return moment.utc(remaining).format(format);
}

/**
 * 비교 대상 날짜가 n년 전에 속하는지 아닌지를 판별하는 함수
 * @param comparisonTarget 오늘 날짜와 비교할 대상 날짜 ex) moment()
 * @param periodYear 기준 년도 default: 3
 */
export function overPeriodYear(comparisonTarget: Moment, periodYear = 3): boolean {
  const startDate = moment()
    .startOf('month')
    .add(-1, 'month');
  return startDate.diff(comparisonTarget, 'years', true) > periodYear;
}

export function isDateType(value: string): boolean {
  const pattern = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;
  return pattern.test(value);
}

export function getDateDiff(start: MomentInput, end: MomentInput = '', unitOfTime: unitOfTime.Diff = 'month'): number {
  if (end === '') {
    end = getToday();
  }
  return moment(end).diff(moment(start), unitOfTime);
}

export function isAfter(comparisonYmdt: string, asOfYmdt = ''): boolean {
  return moment(asOfYmdt === '' ? getToday() : asOfYmdt).isAfter(comparisonYmdt);
}

export function isSameOrAfter(asOfYmdt = '', comparisonYmdt: string): boolean {
  return moment(asOfYmdt === '' ? getToday() : asOfYmdt).isSameOrAfter(comparisonYmdt);
}

export function isBefore(asOfYmdt = '', comparisonYmdt: string): boolean {
  return moment(asOfYmdt === '' ? getToday() : asOfYmdt).isBefore(comparisonYmdt);
}

export function isSameOrBefore(asOfYmdt = '', comparisonYmdt: string): boolean {
  return moment(asOfYmdt === '' ? getToday() : asOfYmdt).isSameOrBefore(comparisonYmdt);
}

// moment('2022-07-12').isBetween('2022-07-12', '2022-07-18', undefined, '[]'); //true
// moment('2022-07-18').isBetween('2022-07-12', '2022-07-18', undefined, '[]'); //true
export function isBetween(startYmd, endYmd, asOfYmd = ''): boolean {
  return moment(asOfYmd === '' ? getToday() : asOfYmd).isBetween(startYmd, endYmd, undefined, '[]');
}

// 'YYYY-MM-DD hh:mm:ss' -> 'YYYY-MM-DD<br/>hh:mm:ss'
export function getDateSpaceTime(ymdt: string): string {
  if (!ymdt) return '-';
  const [date, time] = ymdt.split(' ');
  return `${date}<br>${time}`;
}

export function getDateTime(date: MomentInput, type: 'START' | 'END'): string {
  return `${date} ${DEFAULT_TIME_RANGE[type]}`;
}

/**
 * @param ymd : string YYYY-MM-DD
 */
export function parseYmdDateFormat(ymd: string): Date {
  const [_, y, m, d] = ymd.match(/^(\d+)-(\d+)-(\d+)$/); //, h, m, s

  return new Date(Number(y), Number(m) - 1, Number(d));
}

export function addDay4YMDHMSS(date: Date | string, days: number): string {
  return moment(date)
    .add(days, 'days')
    .format('YYYY-MM-DD HH:mm:ss');
}

export function convertYmdtToYmd(ymdt: string): string {
  return ymdt.slice(0, 10);
}
