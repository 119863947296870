












































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AdminsService } from 'ncp-api-supporter';

// TODO icon 추가

@Component
export default class AdminRegisterSuccess extends Vue {
  @Prop() private adminInfo: AdminsService;

  private moveLoginPage(): void {
    this.$router.push('/auth');
  }
}
