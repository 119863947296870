



















































import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  CampaingsSeparationType,
  CampaingsChannels,
  GetCampaignsReports,
  GetCampaignsReportsRequest,
} from 'ncp-api-supporter';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import { getBeforeDate } from '@/utils/dateFormat';
import Grid from '@/components/common/grid/Main.vue';
import { CheckBoxGroupOption, ValueType } from '@/helpers/type';
import { checkboxData, datePeriodTypes } from '@/views/contents/marketing/googleAd/report';
import RadioGroup from '@/components/common/RadioGroup.vue';

@Component({
  components: {
    Grid,
    DateRangePicker,
    RadioGroup,
  },
})
export default class ReportSearchForm extends Vue {
  private readonly datePeriodTypes = datePeriodTypes;
  private readonly statusTypeCheck: CheckBoxGroupOption<ValueType> = checkboxData;
  private readonly channels: CampaingsChannels = 'GOOGLE';
  private separationType: CampaingsSeparationType = 'DATE';
  private startDate = getBeforeDate();
  private endDate = getBeforeDate();
  private mallNo = 0;

  created() {
    this.mallNo = Number(this.$route.query.mallNo);
  }

  @Watch('$route.query.mallNo')
  private changeMallNoQuery() {
    this.mallNo = Number(this.$route.query.mallNo);
  }

  private async getCampaignsReports(): Promise<GetCampaignsReports> {
    const request: GetCampaignsReportsRequest = {
      params: {
        channels: this.channels,
        separationType: this.separationType,
        startDate: this.startDate,
        endDate: this.endDate,
        mallNo: this.mallNo,
      },
    };
    const { data } = await this.$api.getCampaignsReports(request);

    return data;
  }

  private async setChannelData(): Promise<void> {
    try {
      const {
        data: { totalReport, segmentReports },
      } = await this.getCampaignsReports();

      if (!totalReport) {
        const channelData = {
          currentGridType: this.separationType,
          totalReport,
          reportSegment: segmentReports,
        };

        this.$emit('getChannelData', channelData);
        return;
      }

      const reportSegment = segmentReports.map(({ metrics, segments }) => {
        const segment = this.separationType === 'DATE' ? { date: segments.date } : { productNo: segments.productNo };

        return {
          ...metrics,
          ...segment,
        };
      });

      const channelData = {
        currentGridType: this.separationType,
        totalReport,
        reportSegment,
      };

      this.$emit('getChannelData', channelData);
    } catch (e) {
      alert(this.$t('MARKETING.GOOGLE_AD.CANT_FOUND_CAMPAIGN'));

      const channelData = {
        currentGridType: this.separationType,
        totalReport: null,
        reportSegment: [],
      };

      this.$emit('getChannelData', channelData);
      return;
    }
  }

  private resetSearchBox(): void {
    this.separationType = 'DATE';
    this.startDate = getBeforeDate();
    this.endDate = getBeforeDate();
  }
}
