import { addDay, getToday } from '@/utils/dateFormat';
import { DefaultRequestParams } from '@/const/finderOptions';
import { mall } from '@/utils/mall';
import { OrderListTypes } from '@/types/order';
import { Route } from 'vue-router/types/router';
import { PreviousOrdersMall, GetPreviousOrdersRequest, PreviousOrderSearchType } from 'ncp-api-supporter';
import store from '@/store';

/*
 * orderStatusType과 orderStatusTypes 히스토리
 * 요청할 때 쿼리는 둘 중 하나 취사선택해서 보내야한다. 단, 취사선택 기준이 지금 모호하다.(API쪽 답변)
 *
 * 단일검색: orderStatusType로 보내는 것이 더 낫다.
 * 복수검색: orderStatusTypes로 보내는 것이 낫다.
 *
 * 둘이 같이 요청하면 orderStatusType가 우선순위 높아진다고 한다. -> 근데 둘 중 하나만 보내달라고 함.
 *
 * 일단 프론트에선, 주문상태 선택이 가능한 리스트(주문통합, 배송보류, 예약관리)를 제외한 나머지 리스트는 orderStatusType만 담아 보냄.
 * 지연(delay=true)일 땐 SearchForm에서 orderStatusType만 보냄
 * */
const getDefaultOrderStatusType = (orderListTypes: OrderListTypes) => {
  switch (orderListTypes) {
    case 'ORDER_LIST':
    case 'HOLD_DELIVERY':
    case 'HOLD_BY_RESERVATION':
      return '';

    default:
      return orderListTypes;
  }
};

export const getDefaultOrderStatusTypes = (orderListTypes: OrderListTypes) => {
  switch (orderListTypes) {
    case 'ORDER_LIST':
      return DefaultRequestParams.orderStatusTypes.join(',');
    case 'HOLD_BY_RESERVATION':
      return 'DEPOSIT_WAIT,PAY_DONE';

    case 'HOLD_DELIVERY':
      return 'PRODUCT_PREPARE,DELIVERY_PREPARE';
  }

  return '';
};

const getDefaultHoldByReservation = (orderListTypes: OrderListTypes) => {
  return orderListTypes === 'HOLD_BY_RESERVATION';
};

const getDefaultHoldDelivery = (orderListTypes: OrderListTypes) => {
  return orderListTypes === 'HOLD_DELIVERY';
};

const getDefaultClaimStatusTypes = (orderListTypes: OrderListTypes) => {
  switch (orderListTypes) {
    case 'ORDER_LIST': // 정상주문 조회일 땐 calimStatusType을 없애야한다.
      return DefaultRequestParams.claimStatusTypes.join(',');
    case 'HOLD_DELIVERY':
    case 'HOLD_BY_RESERVATION': // 예약주문 검색시 claimStatusTypes없애야함(빈 문자열 불가),
      return undefined;
  }

  return DefaultRequestParams.claimStatusTypes.join(','); //  예약주문 검색시 없애야함(빈 문자열 불가), TODO: 프리미엄은 그럼 검색안되는거야?
};

const getDefaultSearchDateType = (orderListTypes: OrderListTypes) => {
  const orderYmdtTypes: OrderListTypes[] = ['HOLD_BY_RESERVATION', 'HOLD_DELIVERY', 'ORDER_LIST'];

  return orderYmdtTypes.includes(orderListTypes) ? 'ORDER_YMDT' : 'STATE_CHANGE_YMDT';
};

const getDefaultClaimType = (orderListTypes: OrderListTypes) => {
  switch (orderListTypes) {
    case 'ORDER_LIST':
    case 'DEPOSIT_WAIT':
      return 'NONE';

    default:
      return undefined;
  }
};

const getDefaultIncludeHoldDelivery = (orderListTypes: OrderListTypes) => {
  switch (orderListTypes) {
    case 'DELIVERY_PREPARE':
    case 'PRODUCT_PREPARE':
      return false;

    default:
      return true;
  }
};

// undefined 일때만 -> mallNo , '' -> allMallNos, mallNos='1234' -> mallnos=1234
export const getDefaultMall = (mallNos: undefined | string) => {
  if (mallNos === undefined) return mall.lastCreatedMall.mallNo;
  if (mallNos === '') return mall.allMallNoString;

  return mallNos;
};

export const getDefaultParams = (orderListTypes, vueRoute: Route = null) => {
  const queries = vueRoute?.query;

  return {
    mallNos: getDefaultMall(queries?.mallNos as string),
    orderRegisterType: queries?.orderRegisterType ?? undefined,
    holdByReservation: queries?.holdByReservation ?? getDefaultHoldByReservation(orderListTypes),
    includeReservation: queries?.includeReservation ?? true,
    memberGroupNo: queries?.memberGroupNo ?? undefined,
    searchType: queries?.searchType ?? 'ORDER_NO',
    keyword: queries?.keyword ?? '',
    platformTypes: queries?.platformTypes ?? 'PC,MOBILE_WEB',
    payTypes:
      queries?.payTypes ??
      'CREDIT_CARD,VIRTUAL_ACCOUNT,REALTIME_ACCOUNT_TRANSFER,MOBILE,ACCOUNT,ZERO_PAY,ESCROW_VIRTUAL_ACCOUNT,ESCROW_REALTIME_ACCOUNT_TRANSFER,ACCUMULATION,PAYCO,NAVER_EASY_PAY',
    deliveryType: queries?.deliveryType ?? undefined,
    shippingAreaType: queries?.shippingAreaType ?? undefined,
    includeHoldDelivery: queries?.includeHoldDelivery ?? getDefaultIncludeHoldDelivery(orderListTypes),
    holdDelivery: queries?.holdDelivery ?? getDefaultHoldDelivery(orderListTypes),
    returnWayType: queries?.returnWayType ?? '',
    orderStatusType: queries?.orderStatusType ?? getDefaultOrderStatusType(orderListTypes),
    orderStatusTypes: queries?.orderStatusTypes ?? getDefaultOrderStatusTypes(orderListTypes),
    claimStatusTypes: queries?.claimStatusTypes ?? getDefaultClaimStatusTypes(orderListTypes),
    taskMessageSearchType: queries?.taskMessageSearchType ?? 'ALL',
    includeRecommender: queries?.includeRecommender ?? true,
    startYmd: queries?.startYmd ?? addDay(new Date(), -7),
    endYmd: queries?.endYmd ?? getToday(),
    searchDateType: queries?.searchDateType ?? getDefaultSearchDateType(orderListTypes),
    delay: queries?.delay ?? false,
    page: queries?.page ?? 1,
    size: queries?.size ?? 30,
    isMember: queries?.isMember ?? '',
    deliveryCompanyType: queries?.deliveryCompanyType ?? '',
    memberGradeNo: queries?.memberGradeNo ?? 0,
    assignsInvoice: queries?.assignsInvoice ?? '',
    sortType: queries?.sortType ?? '',
    desc: queries?.desc ?? false,
    tabLabel: queries?.tabLabel ?? '',
    claimType: queries?.claimType ?? getDefaultClaimType(orderListTypes),
    naverDeliveryDelay: queries?.naverDeliveryDelay ?? false,
    partnerNo: queries?.partnerNo ? Number(queries?.partnerNo) : null,
    exchangedRelease: queries?.exchangedRelease ?? '',
  };
};

export const getDefaultPreviousMall = (mallNos: undefined | string) => {
  const malls: PreviousOrdersMall[] = store.getters['mall/getPreviousMalls'];
  if (mallNos === undefined) {
    return malls[0].mallNo;
  }
  if (mallNos === '') {
    return malls.map(({ mallNo }) => mallNo).join(',');
  }

  return mallNos;
};

export const getDefaultPreviousOrderQuery = (vueRoute: Route = null): GetPreviousOrdersRequest['params'] => {
  const queries = vueRoute?.query;
  const {
    searchType = 'ORDER_NO',
    keyword = '',
    startYmd = addDay(new Date(), -7),
    endYmd = getToday(),
    page = 1,
    size = 30,
    memberNo,
    claimType = 'ORDER',
  } = queries ?? {};

  return {
    mallNo: Number(getDefaultPreviousMall(queries?.mallNos as string | undefined)),
    searchType: searchType as PreviousOrderSearchType,
    keyword: keyword.toString(),
    startYmd: startYmd.toString(),
    endYmd: endYmd.toString(),
    page: Number(page),
    size: Number(size),
    memberNo: memberNo?.toString(),
    includeNormalOrder: claimType === 'ORDER',
  };
};
