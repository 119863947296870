import { RadioBoxGroupOption } from '@/helpers/type';
import { ContentOption, contentUseType, ImagesInfoRadioOption } from '@/types';
import { i18n } from '@/main';
import ImageFileInputMixin from '@/components/common/input/imageFileInput/ImageFileInputMixin.vue';
import { CONTENT_TYPE, CONTENT_USE_TYPE, GUIDE_TYPE } from '@/const/contents/product';

const defaultContentsRadioOption = (name: string): RadioBoxGroupOption<keyof typeof CONTENT_USE_TYPE> => {
  return {
    name,
    data: [
      { id: `${name}-not-use`, value: CONTENT_USE_TYPE.NOT_USE, display: 'PRODUCT.ADD.NOT_USE' },
      { id: `${name}-use`, value: CONTENT_USE_TYPE.USE, display: 'PRODUCT.ADD.USE' },
    ],
  };
};

export const contentsRadioOption = () => {
  const mainOption = defaultContentsRadioOption('contentMain');
  mainOption.data.push({ id: 'contentMain-option-image', value: 'OPTION_IMAGE', display: '등록된 옵션 이미지 사용' });
  return {
    [CONTENT_TYPE.HEADER]: defaultContentsRadioOption(CONTENT_TYPE.HEADER),
    [CONTENT_TYPE.BODY]: mainOption,
    [CONTENT_TYPE.FOOTER]: defaultContentsRadioOption(CONTENT_TYPE.FOOTER),
  };
};

export const contentsEditorOption = () => ({
  option: {
    placeholder: i18n.t('PRODUCT.ADD.PLZ_SELECT_MALL_FOR_IMAGES'),
  },
});

const defaultGuidesRadioOption = (name: string): RadioBoxGroupOption<keyof typeof CONTENT_USE_TYPE> => {
  return {
    name,
    data: [
      { id: `${name}NotUse`, value: CONTENT_USE_TYPE.NOT_USE, display: 'PRODUCT.ADD.NOT_USE' },
      { id: `${name}DirectInput`, value: CONTENT_USE_TYPE.DIRECT, display: 'PRODUCT.ADD.DIRECT_INPUT' },
      { id: `${name}Use`, value: CONTENT_USE_TYPE.USE_DEFAULT_TEMPLATE, display: 'PRODUCT.ADD.USE_DEFAULT_TEMPLATE' },
    ],
  };
};

export const templateRadioOption = (): ImagesInfoRadioOption['guide'] => {
  return {
    [GUIDE_TYPE.DELIVERY]: defaultGuidesRadioOption(GUIDE_TYPE.DELIVERY),
    [GUIDE_TYPE.AFTER_SERVICE]: defaultGuidesRadioOption(GUIDE_TYPE.AFTER_SERVICE),
    [GUIDE_TYPE.REFUND]: defaultGuidesRadioOption(GUIDE_TYPE.REFUND),
    [GUIDE_TYPE.EXCHANGE]: defaultGuidesRadioOption(GUIDE_TYPE.EXCHANGE),
  };
};

export const getContents = (): ContentOption<keyof typeof CONTENT_TYPE> => {
  const contents = {} as ContentOption<keyof typeof CONTENT_TYPE>;
  Object.values(CONTENT_TYPE).forEach(type => {
    contents[type] = {
      type,
      status: CONTENT_USE_TYPE.NOT_USE,
      content: '',
    };
  });
  return contents;
};

export const getGuidesTemplates = (): ContentOption<keyof typeof GUIDE_TYPE> => {
  const guides = {} as ContentOption<keyof typeof GUIDE_TYPE>;
  Object.values(GUIDE_TYPE).forEach(type => {
    guides[type] = {
      type,
      status: CONTENT_USE_TYPE.USE_DEFAULT_TEMPLATE,
      content: '',
      defaultContent: '',
      defaultTemplateNo: 0,
      templateNo: 0,
    };
  });
  return guides;
};

export const isAddableImage = (ImagesInfo: ImageFileInputMixin): boolean => {
  if (!ImagesInfo.isMallSelected) {
    ImagesInfo.$emit('alertMallSelect');
    return false;
  }
  return true;
};

export const getContentStatus = (content: string): contentUseType => {
  return content === null ? CONTENT_USE_TYPE.NOT_USE : CONTENT_USE_TYPE.USE;
};

export const getStatus = (templateNo, content) => {
  if (templateNo === null && content === null) return CONTENT_USE_TYPE.NOT_USE;
  if (templateNo !== null) return CONTENT_USE_TYPE.USE_DEFAULT_TEMPLATE;
  return CONTENT_USE_TYPE.DIRECT;
};
