import { claimClassTypes, claimStatusTypes, claimTypes } from '@/const/claim';

// computed 사용용, 타입추론이 잘 안되니 조심해서 사용
export function isCancelClaim(): boolean {
  return this.claimType === claimTypes.CANCEL;
}

export function isExchangeClaim(): boolean {
  return this.claimType === claimTypes.EXCHANGE;
}

export function isReturnClaim(): boolean {
  return this.claimType === claimTypes.RETURN;
}

export function isCancelProcRequestRefund(): boolean {
  return this.claimStatusType === claimStatusTypes.CANCEL_PROC_REQUEST_REFUND;
}

export function isCancelRequest(): boolean {
  return this.claimStatusType === claimStatusTypes.CANCEL_REQUEST;
}

export function isCancelProcWaitingRefund(): boolean {
  return this.claimStatusType === claimStatusTypes.CANCEL_PROC_WAITING_REFUND;
}

export function isCancelNoRefund(): boolean {
  return this.claimStatusType === claimStatusTypes.CANCEL_NO_REFUND;
}

export function isCancelDone(): boolean {
  return this.claimStatusType === claimStatusTypes.CANCEL_DONE;
}

export function isReturnDone(): boolean {
  return this.claimStatusType === claimStatusTypes.RETURN_DONE;
}

export function isExchangeRequest(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_REQUEST;
}

export function isExchangeProcBeforeReceive(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_PROC_BEFORE_RECEIVE;
}

export function isExchangeProcRequestRefund(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_PROC_REQUEST_REFUND;
}

export function isExchangeProcRequestPay(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_PROC_REQUEST_PAY;
}

export function isExchangeProcWaitingPay(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_PROC_WAITING_PAY;
}

export function isExchangeDonePayDone(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_DONE_PAY_DONE;
}

export function isExchangeRejectRequest(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_REJECT_REQUEST;
}

export function isExchangeProcWaiting(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_PROC_WAITING;
}

export function isExchangeProcWaitingRefund(): boolean {
  return this.claimStatusType === claimStatusTypes.EXCHANGE_PROC_WAITING_REFUND;
}

export function isReturnRequest(): boolean {
  return this.claimStatusType === claimStatusTypes.RETURN_REQUEST;
}

export function isReturnProcRequestRefund(): boolean {
  return this.claimStatusType === claimStatusTypes.RETURN_PROC_REQUEST_REFUND;
}

export function isReturnRefundAmtAdjustRequested(): boolean {
  return this.claimStatusType === claimStatusTypes.RETURN_REFUND_AMT_ADJUST_REQUESTED;
}

export function isReturnProcWaitingRefund(): boolean {
  return this.claimStatusType === claimStatusTypes.RETURN_PROC_WAITING_REFUND;
}

export function isReturnRejectRequest(): boolean {
  return this.claimStatusType === claimStatusTypes.RETURN_REJECT_REQUEST;
}

export function isReturnProcBeforeReceive(): boolean {
  return this.claimStatusType === claimStatusTypes.RETURN_PROC_BEFORE_RECEIVE;
}

export function isOrderCancel(): boolean {
  return this.claimClassType === claimClassTypes.ORDER_CANCEL;
}

export function isOptionCancel(): boolean {
  return this.claimClassType === claimClassTypes.OPTION_CANCEL;
}

export function isCancelExchange(): boolean {
  return this.claimClassType === claimClassTypes.CANCEL_EXCHANGE;
}

export function isReturnExchange(): boolean {
  return this.claimClassType === claimClassTypes.RETURN_EXCHANGE;
}
