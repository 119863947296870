












import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class NoticeBox extends Vue {
  @Prop({ required: true })
  noticeList: string[];
  @Prop({ default: false })
  isConvertI18n: boolean;
  @Prop({ default: false })
  isAlert!: boolean;
  @Prop({ default: null })
  title!: string | null;
}
