


















































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BenefitsPaid extends Vue {
  @Prop({ required: true }) private postPredict!: {};
  @Prop({ required: true }) private objJson!: string;
}
