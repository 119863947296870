


























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  selectBoxOptions,
  brandCertifyRadioOptions,
  naverPayCheckBoxOptions,
  naverAccumulationCheckBoxOptions,
  SINGLE_ITEM_SELECT_LENGTH,
  MONTHLY_RENTAL_FEE_MAX_LENGTH,
  RENTAL_CONTRACT_PERIOD_MAX_LENGTH,
  COMPARE_PRICE_PAGE_ID_MAX_LENGTH,
  CATEGORY_ID_MAX_LENGTH,
} from '@/views/contents/product/basic/ProductAdd/naverShoppingEPInfo/NaverShoppingEPInfo';
import { defaultNaverShoppingEPInfo } from '@/views/contents/product/basic/ProductAdd';
import { PostMarketingProductSettingRequest } from 'ncp-api-supporter';
import { NullableNaverShoppingEP, NaverMarketingDataProps } from '@/types';
import { MARKETING_TYPE, NAVER_PRODUCT_SALE_TYPE } from '@/const/contents/product';

@Component({
  components: {
    ModalHeader,
    SelectBox,
    RadioGroup,
    CheckboxGroup,
    ToolTip,
    TextInput,
  },
})
export default class ModifyNaverShoppingEPInfoLayerPopup extends Vue {
  @Prop() private data: NaverMarketingDataProps;

  private selectBoxOptions = selectBoxOptions();
  private brandCertifyRadioOptions = brandCertifyRadioOptions;
  private naverPayCheckBoxOptions = naverPayCheckBoxOptions;
  private naverAccumulationCheckBoxOptions = naverAccumulationCheckBoxOptions;
  private naverShoppingEP: NullableNaverShoppingEP = defaultNaverShoppingEPInfo().naverShoppingEP;

  private readonly naverPayAvailableTooltipOption = { message: this.$t('PRODUCT.ADD.NAVER_PAY_AVAILABLE_TOOLTIP') };
  private readonly naverPayAccumulationTooltipOption = {
    message: this.$t('PRODUCT.ADD.NAVER_PAY_ACCUMULATION_TOOLTIP'),
  };
  private readonly SINGLE_ITEM_SELECT_LENGTH = SINGLE_ITEM_SELECT_LENGTH;
  private readonly MONTHLY_RENTAL_FEE_MAX_LENGTH = MONTHLY_RENTAL_FEE_MAX_LENGTH;
  private readonly RENTAL_CONTRACT_PERIOD_MAX_LENGTH = RENTAL_CONTRACT_PERIOD_MAX_LENGTH;
  private readonly COMPARE_PRICE_PAGE_ID_MAX_LENGTH = COMPARE_PRICE_PAGE_ID_MAX_LENGTH;
  private readonly CATEGORY_ID_MAX_LENGTH = CATEGORY_ID_MAX_LENGTH;
  private readonly NAVER_PRODUCT_SALE_TYPE = NAVER_PRODUCT_SALE_TYPE;

  private get productCount(): number {
    return this.data.settings.length;
  }
  private get dialogues(): string[] {
    return this.$t('PRODUCT.EDIT.CHANGE_SALES_STATUS_DIALOGUES', { selectedGridData: this.productCount })
      .toString()
      .trim()
      .split('\n');
  }
  private naverPayAvailable = 'PC,MOBILE';
  private naverPayAccumulation = 'PC,MOBILE';

  private fetchInitAdditionalInfos() {
    const { data } = this.data;

    if (!data) {
      return;
    }
    const initInfos = data;
    for (const key in initInfos) {
      if (initInfos[key] === 'false') initInfos[key] = false;
      if (initInfos[key] === 'true') initInfos[key] = true;
    }
    this.naverShoppingEP = initInfos as NullableNaverShoppingEP;
  }

  private fetchInitNaverPayLocation() {
    this.naverPayAvailable = JSON.parse(this.naverShoppingEP.naverPayAvailableLocations).join();
    this.naverPayAccumulation = JSON.parse(this.naverShoppingEP.naverPayAccumLocations).join();
  }

  private handleConfirmClick(): void {
    if (this.naverShoppingEP.saleType === NAVER_PRODUCT_SALE_TYPE.RENTAL) {
      if (!this.naverShoppingEP.monthlyRentalFee) {
        alert(this.$t('PRODUCT.ADD.INSERT_MONTHLY_FEE'));
        return;
      }
      if (!this.naverShoppingEP.rentalContractPeriod) {
        alert(this.$t('PRODUCT.ADD.INSERT_CONTRACT_PERIOD'));
        return;
      }
    }
    this.naverShoppingEP.naverPayAccumLocations = JSON.stringify(this.naverPayAccumulation.split(','));
    this.naverShoppingEP.naverPayAvailableLocations = JSON.stringify(this.naverPayAvailable.split(','));

    const convertNaverShoppingInfo = Object.fromEntries(
      Object.entries(this.naverShoppingEP).filter(([_, value]) => !!value),
    );

    const settings = this.data.settings.map(({ productNo, displayable }) => {
      return {
        productNo: Number(productNo),
        displayable,
        additionalInfo: convertNaverShoppingInfo,
      };
    });

    const request: PostMarketingProductSettingRequest = {
      data: {
        mallNo: this.data.mallNo,
        channelType: MARKETING_TYPE.NAVER_SHOPPING,
        settings,
      },
    };
    // NOTE: 다수의 건 일괄 수정 시, 하나라도 실패건이 있으면 모두 롤백되어 실패로 처리됨 -> 즉 처리 성공 = 요청한 일괄 수 만큼 모두 성공 / 한건이라도 실패시 모두 실패로 처리됨
    this.$api
      .postMarketingProductSetting(request)
      .then(() => {
        alert(
          // NOTE: 다수의 건 일괄 수정 과 단건 수정 얼럿 분기처리
          request.data.settings.length > SINGLE_ITEM_SELECT_LENGTH
            ? this.$t('MARKETING.NAVER_SHOPPING.MODIFY_BULK_NAVER_SHOPPING_SUCCESS_MESSAGE', {
                count: this.productCount,
              })
            : this.$t('MARKETING.NAVER_SHOPPING.MODIFY_NAVER_SHOPPING_SUCCESS_MESSAGE'),
        );
        this.$emit('confirm');
      })
      .catch(() => {
        alert(
          this.$t('MARKETING.NAVER_SHOPPING.MODIFY_BULK_NAVER_SHOPPING_FAIL_MESSAGE', {
            count: this.productCount,
          }),
        );
        this.$emit('click:close');
      });
  }

  created() {
    this.fetchInitAdditionalInfos();
    this.fetchInitNaverPayLocation();
  }
}
