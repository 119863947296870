import { PGSetting, PgType } from 'ncp-api-supporter';
import { payTypes } from '@/const/order';

export const getPGSettingToolTipOption = (mallNo?: number) => {
  return {
    limitPayWaitDays: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        mainMessage: [
          window.$t('CONFIGURATION.PG.TOOLTIP_LIMIT_PAY_0'),
          window.$t(`CONFIGURATION.PG.TOOLTIP_LIMIT_PAY_1`, {
            tagStart: `<a href="/pro/configuration/basic/mall/${mallNo}" target="_blank">`,
            tagEnd: '</a>',
          }),
        ],
      },
    },
    kcpCallback: {
      name: 'List',
      style: {
        width: '610px',
      },
      listOption: {
        mainMessage: [
          window.$t('CONFIGURATION.PG.TOOLTIP_CALLBACK_0'),
          window.$t(`CONFIGURATION.PG.TOOLTIP_CALLBACK_1`),
          window.$t('CONFIGURATION.PG.TOOLTIP_CALLBACK_2'),
        ],
      },
    },
    inicisCallback: {
      name: 'List',
      style: {
        width: '610px',
      },
      listOption: {
        mainMessage: [
          window.$t('CONFIGURATION.PG.TOOLTIP_CALLBACK_0'),
          window.$t(`CONFIGURATION.PG.TOOLTIP_CALLBACK_3`),
          window.$t('CONFIGURATION.PG.TOOLTIP_CALLBACK_4'),
        ],
      },
    },
  };
};
export const getPGSettingCheckBoxOption = () => {
  return {
    payType: {
      name: 'PayTypeCheckBox',
      hasAll: false,
      noBrackets: true,
      data: [
        {
          id: payTypes.CREDIT_CARD + '',
          value: payTypes.CREDIT_CARD + '',
          display: window.$t('ORDER.PAY_TYPE.CREDIT_CARD'),
          disabled: true,
        },
        { id: payTypes.ACCOUNT, value: payTypes.ACCOUNT, display: window.$t('ORDER.PAY_TYPE.ACCOUNT'), disabled: true },
        {
          id: payTypes.VIRTUAL_ACCOUNT,
          value: payTypes.VIRTUAL_ACCOUNT,
          display: window.$t('ORDER.PAY_TYPE.VIRTUAL_ACCOUNT'),
          disabled: true,
        },
        { id: payTypes.MOBILE, value: payTypes.MOBILE, display: window.$t('ORDER.PAY_TYPE.MOBILE'), disabled: true },
      ],
    },
    escrowPayType: {
      name: 'EscrowPayTypeCheckBox',
      hasAll: false,
      noBrackets: true,
      data: [
        {
          id: payTypes.ESCROW_REALTIME_ACCOUNT_TRANSFER,
          value: 'ACCOUNT',
          display: window.$t('ORDER.PAY_TYPE.ACCOUNT_TRANSFER'),
          disabled: true,
        },
        {
          id: payTypes.ESCROW_VIRTUAL_ACCOUNT,
          value: 'VIRTUAL_ACCOUNT',
          display: window.$t('ORDER.PAY_TYPE.VIRTUAL_ACCOUNT'),
          disabled: true,
        },
      ],
    },
  };
};
export const getPGSettingRadioBoxOption = () => {
  return {
    taxType: {
      name: 'radio-tax-type',
      data: [
        { id: 'tax', value: 'TAX', display: window.$t('CONFIGURATION.PG.TAX') },
        { id: 'tax-free', value: 'TAX_FREE', display: window.$t('CONFIGURATION.PG.TAX_FREE') },
        { id: 'complex-tax', value: 'COMPLEX_TAX', display: window.$t('CONFIGURATION.PG.COMPOUND_TAX') },
      ],
    },
    exposeLogo: {
      name: 'radio-expose-logo',
      data: [
        { id: 'yes-expose-logo', value: true, display: window.$t('CONFIGURATION.PG.MARK') },
        { id: 'no-expose-logo', value: false, display: window.$t('CONFIGURATION.PG.NOT_MARK') },
      ],
    },
  };
};

export const getDefaultPGSetting = (): PGSetting => {
  return {
    limitDeliveryIngDays: 5,
    limitPayWaitDays: 3,
    pgType: '' as PgType,
    enablePayTypes: [],
    kcpData: {
      siteKey: '',
      siteCd: '',
    },
    callbackUrls: [],
    exposeLogo: false,
    tossPaymentsData: {
      mertKey: '',
      id: '',
    },
    inicisData: {
      merchantId: '',
      iniApiKey: false,
      logo: '',
      signKey: '',
      iniApiIv: false,
    },
    taxType: 'TAX',
  };
};
