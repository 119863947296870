export default Object.freeze({
  MEMBER_NAME: '회원명',
  MEMBER_ID: '아이디',
  MEMBER_NO: '회원번호',
  MOBILE_NUMBER: '휴대폰번호',
  PHONE_NUMBER: '전화번호',

  SEARCH_PLACEHOLDER: '검색어를 입력해주세요.',

  MAIN: '메인',
  MEMBER_GRADE: '회원등급',
  LAST_ACCESS: '최근접속',
  SIGN_UP_DATE: '가입일',

  MEMBER_DETAILS: '회원 정보',
  ORDER_DETAILS: '주문 내역',
  BENEFIT_DETAILS: '혜택 내역',
  POSTS_DETAILS: '게시글 내역',

  SELECT_ORDER_PERIOD: '주문 기간 선택',
  DAY_3: '3일',
  WEEK_1: '1주일',
  MONTH_1: '1개월',

  TOTAL_PAY_AMOUNT: '실 결제 금액',
  REAL_ORDER_CNT: '실 주문 건수',
  CLAIM_ORDER_CNT: '클레임 주문건수',
  AVAILABLE_ACCUMULATIONS: '사용가능 적립금',
  AVAILABLE_COUPON: '사용가능 쿠폰',

  COMMON_ALL: '전체',
  TITLE: '회원상세 관리',
  INQUIRIES_DETAILS: '상담내역',
  ACCUMULATIONS_DETAILS: '적립금 내역',
  COUPON_DETAILS: '쿠폰 내역',
  ONE_TO_ONE_INQUIRIES_DETAILS: '1:1 문의 내역',
  PRODUCT_REVIEW_DETAILS: '상품평 내역',
  PRODUCT_INQUIRIES_DETAILS: '상품문의 내역',
  MALL_NAME: '쇼핑몰명',
  MEMBER_TYPE: '회원유형',

  EMAIL: '이메일',
  MOBILE: '휴대폰',
  EMAIL_RECEIVE_SETTING_DATE: '이메일 수신동의일',

  AGREE: '동의',
  REJECT: '거부',

  MEMO: '메모',
  RECENT_ORDER: '최근 주문',
  MORE_DETAIL: '더 보기',

  ORDER_DATE: '주문일시',
  ORDER_NUMBER: '주문번호',
  PAY_STATUS: '결제상태',
  DELIVERY_PRE: '미배송',
  DELIVERY_IN: '배송중',
  DELIVERY_COMPLETED: '배송완료',
  CANCEL: '취소',
  EXCHANGE: '교환',
  RETURN: '반품',
  REFUND: '환불',

  PAID_TRUE: '결제확인',
  PAID_FALSE: '미결제',

  RECENT_INQUIRY_DATE: '문의일시',
  RECENT_INQUIRY_TITLE: '문의 제목',
  RECENT_INQUIRY_CONTENT: '문의 내용',
  RECENT_INQUIRY_STATUS: '진행상태',

  // order
  ORDER_INTEGRATION: '주문통합',
  CLAIM_ORDER: '클레임주문',
  PREVIOUS_ORDER: '이전 주문',

  ORDER_DELAY_ACCEPT: '접수지연',
  ORDER_DELAY_DELIVERY: '발송 지연',
  ORDER_DELAY_NAVER: '네이버페이 발송지연',
  ORDER_DELAY_HOLD: '배송보류 처리/지연',
  ORDER_DELAY_CANCEL: '취소지연',
  ORDER_DELAY_EXCHANGE: '교환지연',
  ORDER_DELAY_RETURN: '반품지연',

  ORDER_LIST: '주문내역',
  ORDER_NO: '주문번호',
  ORDERED_NAME: '주문자명',
  PRODUCT_NO: '상품번호',
  ORDERED_CONTACT: '주문자연락처',
  PRODUCT_NAME: '상품명',
  OPTION_VALUE: '옵션값',
  RECEIVER_NAME: '수령자명',
  RECEIVER_CONTACT1: '수령자연락처',
  NAVER_ORDER_NO: '네이버페이 주문번호',
  PAYCO_ORDER_NO: '페이코 주문번호',

  PAY_TYPE: '결제수단',
  LAST_PAY_AMOUNT: '실결제금액',
  ORDER_STATUS: '주문상태',
  CLAIM_STATUS: '클레임상태',
  CLAIM_RESULT: '클레임처리',
  OPTION_NAME_VALUE: '옵션명:옵션값',
  ORDER_COUNT: '수량',
  RESERVE_ORDER: '예약주문',
  DELIVERY_HOLD: '배송보류',
  BUSINESS_MESSAGE: '업무메시지',

  MEMBER_INFO: '회원정보',
  EXPIRE_ACCUMULATION_NEXT_MONTH: '익월 소멸예정 적립금',

  ACCUMULATION_STATUSES: '구분',
  ACCUMULATION_STATUSES_GIVE: '적립',
  ACCUMULATION_STATUSES_GIVE_2: '지급',
  ACCUMULATION_STATUSES_SUBTRACTION: '차감',
  ACCUMULATION_DATE: '적립/차감일',
  ACCUMULATION_DATE_2: '지급/차감일',
  ACCUMULATION_REGISTER_ADMIN: '처리자',
  ACCUMULATION_USE_END_DATE: '유효기간',
  ACCUMULATION_REASON: '사유',

  ACCUMULATION_ADD_BUTTON: '적립금 지급/차감',
  FOLD_BUTTON: '접어두기',
  ACCUMULATION_SAVE_BUTTON: '적립금 지급/차감 저장',

  ACCUMULATION_SETTING_TITLE: '적립금 지급/차감 설정',

  USABLE_COUPON: '사용가능 쿠폰',
  USED_COUPON: '사용완료 쿠폰',

  COUPON_NAME: '쿠폰명',
  COUPON_NO: '쿠폰번호',

  COUPON_USE_STATUS: '사용상태',
  COUPON_USE_STATUS_USABLE: '사용가능',
  COUPON_USE_STATUS_USED: '사용완료',
  COUPON_USE_STATUS_EXPIRED: '기간만료',
  COUPON_USE_STATUS_WITHDRAW: '철회완료',

  COUPON_ISSUE_TYPE: '발급구분',
  ISSUE_ADMIN_NAME: '지급자',
  ADMIN_ISSUE_REASON: '지급사유',
  COUPON_DISCOUT_AMOUNT: '실 할인금액',
  ISSUE_YMDT: '발급일',
  USE_END_YMDT: '만료일',
  USE_YMDT: '사용일',
  WITHDRAW_ADMIN_NAME: '철회자',
  WITHDRAW_YMDT: '철회일시',
  WITHDRAW_REASON: '철회사유',
  WITHDRAW_REASON_PLACEHOLDER: '철회사유를 입력해주세요.',
  CHOOSE_COUPON_STATUS: '사용상태를 선택해주세요.',

  COUPON_GIVE_BUTTON: '쿠폰 지급',
  COUPON_GIVE_SAVE_BUTTON: '쿠폰 지급 저장',
  COUPON_WITHDRAW_BUTTON: '쿠폰 철회',
  COUPON_WITHDRAW_SAVE_BUTTON: '쿠폰 철회 저장',

  COUPON_GIVE_SETTING: '쿠폰 지급 설정',
  COUPON_GIVE_REASON: '지급 사유',
  COUPON_GIVE_SELECT: '지급 쿠폰 선택',
  COUPON_GIVE_SEARCH: '쿠폰 조회',

  ALERT_SUCCESS_COUPON_WITHDRAW: '철회가 완료되었습니다',

  DOWNLOAD: '다운로드 발급',
  CODE_DESIGNATE: '지정코드 발급',
  CODE_RANDOM: '난수코드 발급',
  INSERT: '인서트 발급',
  CODE_DESIGNATE_ADMIN_ONLY: '지정코드 발급(어드민 전용)',

  GIVE_WITHDRAW: '지급철회',

  INQUIRY: '1:1문의',
  REVIEW: '상품후기',
  PRODUCT_INQUIRY: '상품문의',

  INQUIRY_STATUS: '처리상태',
  INQUIRY_STATUS_ISSUED: '답변대기',
  INQUIRY_STATUS_ANSWERED: '답변완료',
  INQUIRY_STATUS_IN_PROGRESS: '진행중',

  SEARCH_TYPE_ALL: '통합검색',
  INQUIRY_TITLE: '문의제목',
  INQUIRY_ASSIGNEE_NAME: '담당자명',

  INQUIRY_DATE: '문의일시',

  INQUIRY_ISSUE_TYPE: '문의유형',
  INQUIRY_ASSIGNEE: '담당자',

  INQUIRY_NUMBER: '문의번호',
  INQUIRY_ANSWERED_DATE: '답변일시',

  BLIND: '블라인드',
  DISPLAY: '전시중',

  DISPLAY_STATUS: '전시상태',
  DELETE: '삭제',

  REVIEW_TYPE: '상품후기 구분',
  REVIEW_TYPE_NORMAL: '일반 상품후기',
  REVIEW_TYPE_BEST: '우수 상품후기',

  REVIEW_WRITTEN_TYPE: '유형',
  REVIEW_WRITTEN_TYPE_NORMAL: '일반',
  REVIEW_WRITTEN_TYPE_PHOTO: '포토',

  REVIEW_NO: '상품후기번호',

  REVIEW_RATING: '평점',

  PRODUCT_INQUIRY_SEARCH_DATE_TYPE: '기간검색',
  PRODUCT_INQUIRY_SEARCH_DATE_TYPE_REGISTER: '등록일',
  PRODUCT_INQUIRY_SEARCH_DATE_TYPE_REPLY: '답변일',

  PRODUCT_INQUIRY_REPLY_STATUS_TYPE: '답변상태',

  PRODUCT_INQUIRY_NO: '상품문의번호',
  PRODUCT_INQUIRY_TITLE: '제목',
  PRODUCT_INQUIRY_CONTENT: '문의내용',

  PRODUCT_BUY_YN: '구매여부',
  PRODUCT_BUY_Y: '구매함',
  PRODUCT_BUY_N: '구매 안 함',

  // coupon
  DELETE_COUPON_ISSUANCE_HISTORY_1:
    '쿠폰 만료일 이후 미사용 상태로 1년이 경과된 쿠폰의 발급 내역은 삭제되어 조회가 불가합니다.<br> (단, 회원별 + 전체기간 기준으로 발급수량을 제한한 쿠폰에 대한 발급내역은 삭제되지 않습니다.)',
  DELETE_COUPON_ISSUANCE_HISTORY_2: '이에 따라 현황 내 발급건수와 조회결과가 일부 상이할 수 있습니다.',
  DELETE_COUPON_ISSUANCE_HISTORY_3: '내역 관리가 필요하신 경우 삭제 전 엑셀 파일을 별도로 다운받아 관리해주세요.',
  DELETE_COUPON_PAYMENT_HISTORY_1:
    '쿠폰 만료일 이후 미사용 상태로 1년이 경과된 쿠폰의 지급 내역은 삭제되어 조회가 불가합니다.<br> (단, 회원별 + 전체기간 기준으로 발급수량을 제한한 쿠폰에 대한 지급내역은 삭제되지 않습니다.)',
  DELETE_COUPON_PAYMENT_HISTORY_2: '이에 따라 현황 내 지급건수와 조회결과가 일부 상이할 수 있습니다.',

  // Old

  AVAILABLE_COUPON_COUNT: '보유할인쿠폰',
  AVAILABLE_COUPON_UNIT: '장',

  EMPTY_ORDER_MESSAGE: '주문내역이 없습니다.',
  MORE: '더보기',
  MONETARY_UNIT: '원',
  RECENT_ONE_ON_ONE_INQUIRY: '최근 1:1 문의',

  INQUIRY_ISSUER: '문의자',

  PROGRESS_STATUS: '진행상태',

  EMPTY_ONE_ON_ONE_INQUIRY_MESSAGE: '1:1 문의 내역이 없습니다.',
  RECENT_PRODUCT_INQUIRY: '최근 상품문의',
  EMPTY_PRODUCT_INQUIRY_MESSAGE: '최근 상품문의 내역이 없습니다.',

  MEMBER_TYPE_MALL: '쇼핑몰회원',
  MEMBER_TYPE_PAYCO: '페이코회원',
  MEMBER_TYPE_SYNC_ID: '연동아이디회원',
  MEMBER_TYPE_OPEN_ID: '오픈아이디회원',
  MALL_HEADER_TITLE: '쇼핑몰',
  INQUIRY_SEARCH_TITLE_LABEL: '문의 제목',
  INQUIRY_SEARCH_TITLE_PLACEHOLDER: '검색어 입력',
  INQUIRY_SEARCH_SUBMIT_LABEL: '조회',
  INQUIRY_SEARCH_RESET_LABEL: '초기화',
  INQUIRY_SEARCH_TYPE_AND_ASSIGNEE_LABEL: '문의유형 / 담당자',
  INQUIRY_SEARCH_PERIOD_LABEL: '문의 일시',
  INQUIRY_SEARCH_PROGRESS_LABEL: '진행 상태',
  INQUIRY_ALL_SELECT: '1:1 문의전체',
  INQUIRY_ASSIGNEE_ALL_SELECT: '담당자 전체',
  INQUIRY_SEARCH_RESULT_LABEL: '조회결과',
  INQUIRY_SEARCH_RESULT_UNIT: '건',
  NICK_NAME: '닉네임',
  REEVALUATIONS_DATE: '유지일',
  MEMBER_GROUP: '회원그룹',
  CERTIFICATE_STATUS: '인증여부',
  CERTIFICATE_DATE: '인증일',
  CERTIFICATE_AGREE: '이메일인증 완료',
  CERTIFICATE_DISAGREE: '인증 사용 안 함',
  ADULT_CERTIFICATE_STATUS: '성인인증 여부',
  ADULT_NOT_CERTIFICATED: '미인증',
  BIRTH_DAY: '생년월일',
  GENDER: '성별',
  REFUND_ACCOUNT_INFO: '환불계좌정보',
  REFUND_ACCOUNT_MODIFY: '계좌정보수정',
  REFUND_ACCOUNT_NO: '환불 계좌 번호',
  ACCOUNT_OWNER_NAME: '예금주명',

  EMAIL_EDIT: '메일주소수정',
  EMAIL_DUPLICATE_CHECK: '중복확인',
  ADDRESS: '주소',
  FIND_ZIP_CODE: '우편번호 찾기',
  SIGN_UP_ROUTE: '가입경로',
  LAST_LOGIN_DATE: '최종로그인',
  MEMBER_COMBINE_INFO: '회원연동 정보',
  SMS_AGREED: 'SMS 수신 동의',
  DISAGREED: '미동의',
  EMAIL_AGREED: 'e메일 수신 동의',
  PUSH_AGREED: '푸쉬 수신 동의',
  BLACKLIST_STATUS: '블랙리스트 여부',
  BLACKLIST_MEMBER: '블랙리스트 회원',
  PERSONAL_INFO_COLLECT_AGREE: '개인정보 수집/이용',
  PERSONAL_INFO_TREATMENT_AGREE: '개인정보 처리/위탁',
  PERSONAL_INFO_THIRD_OFFER: '개인정보 제3자 제공',
  MEMBER_STATUS: '회원상태',
  RECOMMENDER: '추천인',
  COMMENT: '회원정보 관련 메모',
  GRADE_UPDATE: '등급변경',
  GROUP_UPDATE: '그룹변경',
  SUSPENSION: '이용정지',
  EXPEL_MEMBER: '강제탈퇴',
  SAVE_CHANGES: '변경사항 저장',
  PC_WEB: 'PC웹',
  MOBILE_APP: '모바일앱',
  MOBILE_WEB: '모바일웹',
  WAITING: '가입대기',
  ACTIVE: '가입완료',
  FREEZE: '휴면',
  PAUSED: '이용정지',
  MALE: '남성',
  FEMALE: '여성',
  SELECT_BANK: '은행선택',
  ASK_CONFIRM_MESSAGE: '수정사항을 저장하시겠습니까?',
  MODIFY_SUCCESS_MESSAGE: '수정완료하였습니다.',
  MODIFY_FAILURE_MESSAGE: '수정 실패하였습니다.',
  REFUND_ACCOUNT_VALID_FAIL_MESSAGE: '환불계좌정보를 입력해 주시기 바랍니다.',
  EMAIL_VALID_FAIL_MESSAGE: '이메일 주소를 입력하세요.',
  EMAIL_DUPLICATED_VALID_FAIL_MESSAGE: '이메일 중복확인이 안되었습니다.',
  EMAIL_EMPTY_MESSAGE: '이메일 주소를 입력하세요.',
  EMAIL_IS_DUPLICATED_MESSAGE: '이메일 중복됨',
  EMAIL_DUPLICATED_CHECKED_MESSAGE: '확인되었습니다.',
  MEMBER_GRADE_UPDATE_POPUP: '회원등급변경팝업',
  MEMBER_GROUP_UPDATE_POPUP: '회원그룹변경팝업',
  ORDER_VALID_PERIOD: '조회기간은 최대 3개월까지 가능합니다.',

  PAPER: '장',

  USE_NOT_COUPON: '쿠폰 사용여부',
  COUPON_GIVE: '할인쿠폰 지급',
  PLEASE_ENTER_KEYWORD: '검색어를 입력하세요.',

  GRID_NONE_DISPLAY: '검색된 혜택 내역이 없습니다.',

  WITHDRAW_TTL: '쿠폰 철회',
  WITHDRAW_TITLE: '쿠폰철회',

  COUPON_TYPE: '혜택구분',
  COUPON_USE_STATUS_TYPE: '쿠폰상태',

  ISSUE_TYPE: '발급유형',
  REGISTER_ADMIN_NAME: '쿠폰 등록자',

  USED_YN: '사용여부',

  DETAIL: '상세보기',
  DISCOUNT: '상품 금액 할인',
  CART_DISCOUNT: '장바구니 금액 할인',

  WITHDRAW_OVER: '쿠폰철회가 완료 되었습니다.',
  SELECT_COUPON: '쿠폰 선택',
  PAYCO_VIP: 'PAYCO회원',
  SEE_ORDER: '주문보기',
});
