// 노출 플랫폼
import { OptColumn } from '@/types';
import { PlatformDisplayInfo } from 'ncp-api-supporter';
import { clone } from 'underscore';
import { i18n } from '@/main';

const platformDisplayNouns = {
  pcYn: 'PC',
  mobileWebYn: 'MOBILE_WEB',
  mobileYn: 'MOBILE_APP',
} as const;

/**
 * @convertPlatformDisplayInfoToNouns
 *
 * @params platformDisplayInfo: PlatformDisplayInfo
 *
 * @return
 * isAll 일때는 ['ALL'] 을 리턴한다.
 * isAll 이 아닐때는 사용하는값의 명칭 배열을 리턴한다.
 **/
const convertPlatformDisplayInfoToNouns = (platformDisplayInfo: PlatformDisplayInfo): string[] => {
  const clonedPlatformDisplayInfo = clone(platformDisplayInfo);
  delete clonedPlatformDisplayInfo.displayYn;
  delete clonedPlatformDisplayInfo.mobileYn; // mobileYn 은 모바일 앱 디스플레이 여부인데, Pro 에서는 모바일앱이 없기에..

  const USED = 'Y';
  const usedPlatform = Object.fromEntries(Object.entries(clonedPlatformDisplayInfo).filter(([_, v]) => v === USED));

  const usedPlatformLength = Object.keys(usedPlatform).length;
  const defaultLength = Object.keys(clonedPlatformDisplayInfo).length;

  const isAll = usedPlatformLength === defaultLength;

  return isAll ? ['ALL'] : Object.keys(usedPlatform).map(v => platformDisplayNouns[v]);
};

export const getPlatformDisplayInfoColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.STATUS.PLATFORM_DISPLAY.STATUS',
  name: 'platformDisplayInfo',
  align: 'center',
  minWidth,
  formatter: ({ value }) => {
    const nouns = convertPlatformDisplayInfoToNouns(value as PlatformDisplayInfo);
    const i18ns = nouns.map(noun => i18n.t(`PRODUCT.STATUS.PLATFORM_DISPLAY.${noun}`));
    return i18ns.join(', ');
  },
});
