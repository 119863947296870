



































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MarketingCountTable extends Vue {
  @Prop({ required: true })
  private readonly summaryTotalMemberCount: number;

  @Prop({ required: true })
  private readonly smsAgreedCount: number;

  @Prop({ required: true })
  private readonly emailAgreedCount: number;

  @Prop({ required: true })
  private readonly summaryTotalMemberCountPercentage: string;

  @Prop({ required: true })
  private readonly smsAgreedCountPercentage: string;

  @Prop({ required: true })
  private readonly emailAgreedCountPercentage: string;
}
