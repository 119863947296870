import { throwPopup, PopupClose, throwWindowPopup, PopupResult } from '@/helpers/popup';
import { Row } from '@/types/tui-grid';
import { GetMembersMemberNo, Member } from 'ncp-api-supporter/dist/types/modules/member/member';

export enum MemberPopupType {
  GRADE = 'GRADE', // 회원 등급 변경
  WITHDRAWAL = 'WITHDRAWAL', // 회원 강제탈퇴
  ACCUMULATIONS_MEMBER_LIST = 'ACCUMULATIONS_MEMBER_LIST', // 회원 리스트에서 -> 적립금 지급/차감 설정
  ACCUMULATIONS_PAGE = 'ACCUMULATIONS_PAGE', // 적립금 지급 관리 -> 적립금 지급/차감 설정
  COUPON_ISSUE_PLANS_MEMBER_LIST = 'COUPON_ISSUE_PLANS_MEMBER_LIST', // 회원 리스트에서 -> 쿠폰 지급 설정
  COUPON_ISSUE_PLANS_PAGE = 'COUPON_ISSUE_PLANS_PAGE', // 쿠폰 리스트에서 -> 쿠폰 지급 설정
  MEMBER_MODIFY_GRADE = 'MEMBER_MODIFY_GRADE',
  MEMBER_MODIFY_WITHDRAWAL = 'MEMBER_MODIFY_WITHDRAWAL',
}

export enum ValidPeriodType {
  VALID_PERIOD_DATE = 'PERIOD',
  EXPIRE_DATE_TIME = 'DATETIME',
  NONE = 'NO_LIMIT',
}

export interface PopupOptionData {
  optionData: [
    {
      headerText: string; // 팝업 타이틀
      isTopNotice?: boolean; // 탑 가이드 사용여부
      topNoticeMessage?: string[]; // 탑 가이드 내용
      isConfirm: boolean; // 저장 버튼 사용 여부
      isReason: boolean; // 변경 사유 표시 여부
      reasonData: { thTitle: string; isNecessary: boolean }; // thTitle: 사유 헤더 타이틀, isNecessary: 필수 여부
      isGrade?: boolean;
      accumulationPopupCase?: object;
    },
  ];
}

// 회원 리스트에서 선택된 회원 정보 배열
export function getSelectedMemberList(memberNos: string[], content): object[] {
  const result = [];
  for (let i = 0; i < memberNos.length; i++) {
    for (let j = 0; j < content.length; j++) {
      if (memberNos[i] == content[j].memberNo) {
        result.push(content[j]);
      }
    }
  }
  return result;
}
// 회원 등급변경 팝업 호출 옵션 데이터
export const getGradePopupOptions = (): PopupOptionData => {
  return {
    optionData: [
      {
        headerText: '회원등급 변경', // 팝업 타이틀
        isTopNotice: true, // 탑 가이드 사용여부
        topNoticeMessage: ['등급 변경 시 자동발송 설정에 따라 등급 변경 시 알림(메일, SMS, 알림톡)이 발송됩니다.'], // 탑 가이드 내용
        isConfirm: true, // 저장 버튼 사용 여부
        isReason: true, // 변경 사유 표시 여부
        reasonData: { thTitle: '변경사유', isNecessary: false }, // thTitle: 사유 헤더 타이틀, isNecessary: 필수 여부
        isGrade: true, // 회원 등급 select 표시 여부
      },
    ],
  };
};
// 회원 탈퇴 팝업 호출 옵션 데이터
export const getWithdrawalMemberPopupOptions = (): PopupOptionData => {
  return {
    optionData: [
      {
        headerText: '회원 강제탈퇴', // 팝업 타이틀
        isTopNotice: true, // 탑 가이드 사용여부
        topNoticeMessage: [
          '간편로그인 회원은 강제탈퇴 처리 시, 탈퇴 철회가 불가합니다.',
          '강제탈퇴 사유의 경우 알림(메일, SMS, 카카오알림톡) 템플릿에 치환코드 설정 시 내용이 고객에게 노출됩니다.',
        ], // 탑 가이드 내용
        isConfirm: true, // 저장 버튼 사용 여부
        isReason: true, // 변경 사유 표시 여부
        reasonData: { thTitle: '강제탈퇴 사유', isNecessary: true }, // thTitle: 사유 헤더 타이틀, isNecessary: 필수 여부
      },
    ],
  };
};
// 적립금 지급/차감 설정 팝업 호출 옵션 데이터
export const getAccumulationsPopupOptions = (content: Row | null = null): PopupOptionData => {
  return {
    optionData: [
      {
        headerText: '적립금 지급/차감 설정', // 팝업 타이틀
        isConfirm: true, // 저장 버튼 사용 여부
        isReason: true, // 변경 사유 표시 여부
        reasonData: { thTitle: '사유', isNecessary: true }, // thTitle: 사유 헤더 타이틀, isNecessary: 필수 여부
        accumulationPopupCase: { ...content },
      },
    ],
  };
};
// 쿠폰지급 설정 설정 팝업 호출 옵션 데이터
export const getCouponsIssuePlansPopupOptions = (): PopupOptionData => {
  return {
    optionData: [
      {
        headerText: '쿠폰 지급 설정', // 팝업 타이틀
        isConfirm: true, // 저장 버튼 사용 여부
        isReason: true, // 변경 사유 표시 여부
        reasonData: { thTitle: '지급 사유', isNecessary: false }, // thTitle: 사유 헤더 타이틀, isNecessary: 필수 여부
      },
    ],
  };
};
/**
 * 회원등급 변경, 회원 강제탈퇴
 * 레이어 팝업
 * */
export const openLayerPopup = function(
  mallNo: number,
  memberNos: string[],
  content: Member[] | GetMembersMemberNo,
  option: PopupOptionData,
  type: MemberPopupType,
) {
  return throwPopup({
    name: 'MemberGradeWithdrawalPopup',
    data: {
      mallNo: mallNo, // 쇼핑몰 번호
      memberNos: memberNos, // 선택 된 회원번호
      contents:
        type === MemberPopupType.GRADE || type === MemberPopupType.WITHDRAWAL
          ? getSelectedMemberList(memberNos, content)
          : content, // 선택 된 회원정보
      option: option,
      type: type, // 팝업 타입(회원 등급 변경, 회원 강제탈퇴)
    },
  }).then((result: PopupResult) => {
    if (result && result.state === PopupClose.CONFIRM) {
      return result;
    }
  });
};
// 회원 임시번호 발급
export const openLayerTempPasswordPopup = function(memberNo: number, content: GetMembersMemberNo) {
  return throwPopup({
    name: 'MemberTempPassword',
    data: {
      memberNo: memberNo, // 회원 번호
      contents: content, // 회원정보
    },
  }).then((result: PopupResult) => {
    if (result && result.state === PopupClose.CONFIRM) {
      return result;
    }
  });
};
/**
 * 적립금 지급/차감
 * 윈도우 팝업
 * */
export const openWindowPopup = function(
  mallNo: number,
  memberNos: string[],
  content: Member[] | null,
  option: PopupOptionData,
  type: MemberPopupType,
  close: Function,
  componentName: string,
) {
  const data = {
    mallNo: mallNo, // 쇼핑몰 번호
    memberNos: memberNos, // 선택 된 회원번호
    contents: getSelectedMemberList(memberNos, content), // 선택 된 회원정보
    option: option,
    type: type, // 팝업 타입(회원 등급 변경, 회원 강제탈퇴)
  };
  const query = mallNo ? { mallNo: mallNo.toString() } : {};
  return throwWindowPopup(componentName, data, 'md', close, null, query);
};
