

























import { Component, Vue, Ref, Watch, PropSync } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import {
  GetReviewsConfigurationsExcludedProductsRequest,
  ReviewsConfigurationsExcludedProductsContents,
} from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import { gridProps } from '../BoardSetting';
import { GridEventProps } from '@/types';
import { Row } from '@/types/tui-grid';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { getCurrentMallNo } from '@/utils/mall';
import { getDownToday } from '@/utils/dateFormat';
import { writeExcelFile } from '@/utils/webExcel';
import { ExceptionProductNosInfo } from '@/views/contents/board/basic/boardSettingSub/BoardExceptionSetting/BoardExceptionSetting.vue';
import { Action } from 'vuex-class';

@Component({
  components: { NoticeBox, TextInput, SearchKeyword, Grid },
})
export default class BoardExceptionSettingGrid extends Vue {
  @Action('setUnsavedForm')
  private readonly setUnsavedForm!: () => void;
  @Action('setInitUnsavedForm')
  private readonly setInitUnsavedForm!: () => void;
  @PropSync('exceptionProductNosInfo', { required: true })
  private exceptionProductNosInfoSync: ExceptionProductNosInfo;
  private totalCount = 0;
  private gridProps = gridProps;
  private exceptionProductGridData: ReviewsConfigurationsExcludedProductsContents[] = [];
  private prevExceptionProductGridData: ReviewsConfigurationsExcludedProductsContents[] = [];

  @Ref()
  private readonly gridRef: Grid;

  public isChangedGridData(): boolean {
    return JSON.stringify(this.exceptionProductGridData) !== JSON.stringify(this.prevExceptionProductGridData);
  }

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  async created() {
    await this.getExcludedProducts();
    this.prevExceptionProductGridData = [...this.exceptionProductGridData];
    this.setUnsavedExceptionGrid();
    this.gridRef.changeNoDataMessage(this.$t('PRODUCT.BULK_IMAGE.NO_DATA'));
  }

  @Watch('exceptionProductGridData')
  private setTotalCount() {
    this.totalCount = this.exceptionProductGridData.length;
  }

  @Watch('exceptionProductGridData')
  private setUnsavedExceptionGrid() {
    if (this.isChangedGridData()) {
      this.setUnsavedForm();
    } else {
      this.setInitUnsavedForm();
    }
  }

  private updateUnsavedMallProductNo(productNoToBeDeleted: number) {
    this.exceptionProductNosInfoSync.productNosForSave = this.exceptionProductNosInfoSync.productNosForSave.filter(
      product => product !== productNoToBeDeleted,
    );
  }

  private updateProductNoToBeDeleted(productNoToBeDeleted: number) {
    const isUnsavedMallProductNos = this.exceptionProductNosInfoSync.productNosForSave.includes(productNoToBeDeleted);
    if (isUnsavedMallProductNos) this.updateUnsavedMallProductNo(productNoToBeDeleted);
    else this.exceptionProductNosInfoSync.productNosForDelete.push(productNoToBeDeleted);
  }

  private confirmExceptionProductDeletion(): boolean {
    return confirm(this.$t('BOARD.BOARD.CONFIRM_EXCEPTION_PRODUCT_DELETE').toString());
  }

  private handleDeleteCheckedProductClick() {
    const checkedMallProductNos = this.gridRef.getCheckedRows().map(({ productNo }) => Number(productNo));
    if (checkedMallProductNos.length === 0) {
      alert(this.$t('BOARD.BOARD.ALERT_EXCEPTION_PRODUCT_DELETE'));
      return;
    }
    if (!this.confirmExceptionProductDeletion()) return;
    checkedMallProductNos.forEach(productNo => this.updateProductNoToBeDeleted(productNo));
    this.exceptionProductGridData = this.exceptionProductGridData.filter(
      ({ productNo }) => !checkedMallProductNos.includes(productNo),
    );
  }

  private handleDeleteProductClick(rowDataProductNo: number) {
    if (!this.confirmExceptionProductDeletion()) return;
    this.updateProductNoToBeDeleted(rowDataProductNo);
    this.exceptionProductGridData = this.exceptionProductGridData.filter(
      ({ productNo }) => productNo !== rowDataProductNo,
    );
  }

  private onItemClicked({ rowKey, columnName }: GridEventProps) {
    const rowData: Row = this.gridRef.getRow(rowKey);
    const productNo = Number(rowData.productNo);
    switch (columnName) {
      case 'productNo':
        openEditProductWindow(productNo);
        break;
      case 'delete':
        this.handleDeleteProductClick(productNo);
        break;
    }
  }

  @Watch('$route.query.mallNo')
  private resetPage() {
    this.$set(this.gridProps.options.pageOptions, 'page', 1);
    this.$set(this.gridProps.options.pageOptions, 'perPage', 30);
  }

  private changeSaleStatusTypeValueToText() {
    return this.exceptionProductGridData.map(excludedProduct => ({
      ...excludedProduct,
      saleStatusType: this.$t(`PRODUCT.COMMON.${excludedProduct.saleStatusType}`).toString(),
    }));
  }

  private getExcelColumns() {
    return {
      mallName: this.$t('PRODUCT.COMMON.MALL'),
      productNo: this.$t('PRODUCT.COMMON.PRODUCT_NO'),
      productName: this.$t('PRODUCT.COMMON.PRODUCT_NAME'),
      saleStatusType: this.$t('PRODUCT.COMMON.SALE_STATUS_TYPE'),
      appliedImmediateDiscountPrice: this.$t('PRODUCT.COMMON.IMMEDIATE_DISCOUNT'),
      displayCategoryNames: this.$t('PRODUCT.COMMON.DISPLAY_CATEGORY'),
    };
  }

  // 엑셀 다운로드
  private async downloadExcludedProductsExcel() {
    const fileName = `review_product_${getDownToday()}`;
    const excelData = this.changeSaleStatusTypeValueToText();
    const columns = this.getExcelColumns();
    writeExcelFile(excelData, columns, fileName, true);
  }

  async fetchExcludedProducts(request: GetReviewsConfigurationsExcludedProductsRequest) {
    try {
      const {
        data: { items },
      } = await this.$api.getReviewsConfigurationsExcludedProducts(request);
      this.exceptionProductGridData = items;
    } catch (e) {
      console.error(e);
    }
  }

  @Watch('gridProps.options.pageOptions', { deep: true })
  public searchExcludedProducts() {
    const { page, perPage } = this.gridProps.options.pageOptions;
    const request: GetReviewsConfigurationsExcludedProductsRequest = {
      params: {
        pageNumber: page,
        pageSize: perPage,
        hasTotalCount: true,
        mallNo: this.mallNo,
      },
    };
    this.fetchExcludedProducts(request);
  }

  public async getExcludedProducts(productNosBeSaved?: number[]) {
    const { page, perPage } = this.gridProps.options.pageOptions;
    const request: GetReviewsConfigurationsExcludedProductsRequest = {
      params: {
        pageNumber: page,
        pageSize: perPage,
        hasTotalCount: true,
        mallNo: this.mallNo,
        productNosBeSaved: productNosBeSaved ? productNosBeSaved?.join(',') : null,
      },
    };
    await this.fetchExcludedProducts(request);
  }
}
