








































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import DatePicker, { DateRangePickerEventType } from 'tui-date-picker';
import moment, { unitOfTime } from 'moment';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  DEFAULT_DATE_RANGE,
  DEFAULT_TIME_RANGE,
  HOUR_SELECT_OPTIONS,
  MINUTE_SELECT_OPTIONS,
  HOUR_MINUTEUNCHANGED_END_SELECT_OPTIONS,
  HOUR_MINUTEUNCHANGED_START_SELECT_OPTIONS,
} from '@/components/common/datepicker/dateRange';

import { DateRangeOption, DateRanges, Hms } from '@/types';
import { addMonth, getStrDate, getToday } from '@/utils/dateFormat';
import { OptionData } from '@/helpers/type';

const PERIOD_NAMES = {
  td: '오늘',
  ytd: '어제',
  '3d': '3일',
  '15d': '15일',
  '30d': '30일',
  '60d': '60일',
  '90d': '90일',
  '1w': '1주일',
  '1m': '1개월',
  '2m': '2개월',
  '3m': '3개월',
  '6m': '6개월',
  '1y': '1년',
  all: '전체',
} as const;

const PERIOD_DAY_RANGES: DateRanges = {
  td: [0, 'd'],
  ytd: [1, 'd'],
  '3d': [3, 'd'],
  '15d': [15, 'd'],
  '30d': [30, 'd'],
  '60d': [60, 'd'],
  '90d': [90, 'd'],
  '1w': [1, 'w'],
  '1m': [1, 'M'],
  '2m': [2, 'M'],
  '3m': [3, 'M'],
  '6m': [6, 'M'],
  '1y': [1, 'y'],
};

export type PeriodType = keyof typeof PERIOD_NAMES;

interface DateRangePickerInstance {
  setStartDate: (newDate: Date) => void;
  getStartDate: () => Date;
  setEndDate: (newDate: Date) => void;
  getEndDate: () => Date;
  setRanges: (ranges: [number | Date, number | Date][]) => void;
  on(
    eventName: DateRangePickerEventType | { [key in DateRangePickerEventType]?: Function },
    handler?: Function | object,
    context?: object,
  ): void;
  destroy(): void;
}

const defaultDateRangeOption: DateRangeOption = {
  name: 'period',
  dateType: 'Ymd',
  periodTypes: ['td', 'ytd', '3d', '1w', '1m'], // 오늘, 어제, 3, 1주일, 1개월
  hasPeriodBtn: true,
  fromRanges: DEFAULT_DATE_RANGE.MIN,
  toRanges: DEFAULT_DATE_RANGE.MAX,
  disabled: {
    startYmdt: false,
    endYmdt: false,
  },
  unchangeable: '',
};

@Component({
  components: {
    SelectBox,
  },
})
export default class DateRangePicker extends Vue {
  @Prop({ required: false, default: getToday() })
  private startYmd!: string;
  @Prop({ required: false, default: addMonth(new Date(), 3) })
  private endYmd!: string;
  @Prop({ required: false, default: DEFAULT_TIME_RANGE.START })
  private startHms!: string;
  @Prop({ required: false, default: DEFAULT_TIME_RANGE.END })
  private endHms!: string;
  @Prop({ required: false })
  private option!: DateRangeOption;
  @Prop({ required: false, default: true })
  private checkStartDate: boolean;
  @Prop({ required: false, default: 'COMMON.DATE_PICKER.ALERT_INVALID_START_DATE' })
  private alertLimitMsg: string;
  @Prop({ default: true })
  private readonly showDatePicker!: boolean;
  @Prop({ required: false, default: false })
  private readonly checkFromRanges: boolean;
  @Prop({ required: false, default: false })
  private readonly twoLineStyle!: boolean;
  @Prop({ required: false, default: false })
  private readonly disabledAll: boolean;
  @Prop({ required: false, default: false })
  private readonly startDisabled: boolean;
  @Prop({ required: false, default: false })
  private readonly endDisabled: boolean;
  @Prop({ required: false, default: null })
  private readonly checkToday: (prevSelectedPeriodType: PeriodType, currSelectedPeriodType: PeriodType) => void;
  @Prop({ required: false, default: false })
  private readonly prevDaySelect: boolean;
  @Prop({ required: false, default: false })
  private readonly dayLimit: number;
  @Prop({ required: false, default: true })
  private readonly selectBeforeDays: boolean;

  @Ref('containerStartDate')
  private readonly containerStartDate!: HTMLDivElement;
  @Ref('containerEndDate')
  private readonly containerEndDate!: HTMLDivElement;
  @Ref('inputStartDate')
  private readonly inputStartDate!: HTMLInputElement;
  @Ref('inputEndDate')
  private readonly inputEndDate!: HTMLInputElement;

  private startYmdClone = '';
  private endYmdClone = '';

  private startHm = '00:00';
  private endHm = '23:59';
  @Prop({ required: false, default: true })
  private disabledUnchangeable!: boolean;
  private changedHms(_, prev: string, el: HTMLSelectElement): void {
    if (this.getUnchangeable == 'minute') {
      this.changed.endHms.hour = this.endHm.split(':')[0];
      this.changed.endHms.minute = this.endHm.split(':')[1];
      this.changed.endHms.second = '59';
      this.changed.startHms.hour = this.startHm.split(':')[0];
      this.changed.startHms.minute = this.startHm.split(':')[1];
      this.changed.startHms.second = '00';
    }

    if (!this.isChangeableHms) {
      this.undoHmsChanges(prev, el);
      return;
    }

    const { type } = el.dataset;
    if (this.getUnchangeable == 'minute') {
      this.$emit(`update:startHms`, this.startHm);
      this.$emit(`update:endHms`, this.endHm);
    } else {
      this.$emit(`update:${type}`, `${this.format.hmsObjectToHmsString(this.changed[`${type}`])}`);
    }
  }

  private get isChangeableHms(): boolean {
    if (
      !this.startYmd ||
      this.startHms === DEFAULT_TIME_RANGE.BLANK_START ||
      !this.endYmd ||
      this.endHms === DEFAULT_TIME_RANGE.BLANK_END
    ) {
      return true;
    }
    return moment(`${this.startYmd} ${this.format.hmsObjectToHmsString(this.changed.startHms)}`).isSameOrBefore(
      `${this.endYmd} ${this.format.hmsObjectToHmsString(this.changed.endHms)}`,
    );
  }

  private undoHmsChanges(prev: string, el: HTMLSelectElement): void {
    this.alertCheckLimitMsg();

    const { type, time } = el.dataset;
    this.$nextTick(() => {
      this.changed[`${type}`][`${time}`] = prev;
      el.value = prev;
      el.value = prev;
    });
  }

  private rangePicker: DateRangePickerInstance = {} as DateRangePickerInstance; // Devtools 에서 이 인스턴스 검사 가능하게 하려고 빈객체 할당
  private selectedPeriodType: PeriodType | null = null;
  private HOUR_SELECT_OPTIONS: OptionData<string>[] = HOUR_SELECT_OPTIONS;
  private MINUTE_SELECT_OPTIONS: OptionData<string>[] = MINUTE_SELECT_OPTIONS;
  private HOUR_MINUTEUNCHANGED_END_SELECT_OPTIONS: OptionData<string>[] = HOUR_MINUTEUNCHANGED_END_SELECT_OPTIONS;
  private HOUR_MINUTEUNCHANGED_START_SELECT_OPTIONS: OptionData<string>[] = HOUR_MINUTEUNCHANGED_START_SELECT_OPTIONS;
  private changed = {
    startHms: {} as Hms,
    endHms: {} as Hms,
  };
  private dateRangeOption: DateRangeOption = { ...defaultDateRangeOption };

  private format = {
    hmsStringToHmsObject: (hms: string): Hms => {
      const [hour, minute, second] = hms.split(':');
      return {
        hour,
        minute,
        second,
      };
    },
    hmsObjectToHmsString: (objHms: Hms): string => {
      let selectedTime = '';
      for (const time in objHms) {
        selectedTime += `${objHms[time]}:`;
      }
      selectedTime = selectedTime.substring(0, selectedTime.length - 1);
      return selectedTime;
    },
  };

  @Watch('disabledUnchangeable')
  private onWatchDisabledUnchangeable() {
    if (
      this.dateRangeOption.unchangeable == null ||
      this.dateRangeOption.unchangeable == undefined ||
      this.dateRangeOption.unchangeable == ''
    ) {
      return;
    } else {
      if (this.disabledUnchangeable) {
        // this.rangePicker.setStartDate(null);
        // this.rangePicker.setEndDate(null);
        // this.$emit('update:startYmd', '');
        // this.$emit('update:endYmd', '');
        this.startHm = '00:00';
        this.endHm = '23:59';
      } else {
        // this.rangePicker.setStartDate(null);
        // this.rangePicker.setEndDate(null);
        // this.$emit('update:startYmd', '');
        // this.$emit('update:endYmd', '');
        this.startHm =
          new Date().getMinutes() > 0
            ? moment(new Date())
                .add(1, 'hour')
                .format('HH') + ':00'
            : new Date().getHours().toString() + ':00';
        this.endHm = '23:59';
      }
    }
  }

  @Watch('startYmd')
  private changeStartYmd(val: string): void {
    const date = moment(val).toDate();
    this.rangePicker.setStartDate(date);

    this.$nextTick(() => this.setSelectedPeriodType());
  }
  @Watch('endYmd')
  private changeEndYmd(val: string): void {
    const date = moment(val).toDate();
    this.rangePicker.setEndDate(date);

    this.$nextTick(() => this.setSelectedPeriodType());
  }
  @Watch('startHms')
  private changeStartHms(val: string): void {
    this.changed.startHms = { ...this.format.hmsStringToHmsObject(val) };
    this.startHm = val;
  }
  @Watch('endHms')
  private changeEndHms(val: string): void {
    this.changed.endHms = { ...this.format.hmsStringToHmsObject(val) };
    this.endHm = val;
  }

  public focus(focusType?: string) {
    if (focusType === 'end') {
      this.inputEndDate.focus();
    } else {
      this.inputStartDate.focus();
    }
  }

  private canCheckStartDate = false;
  private created() {
    this.setInitialDateRangeOption();
    this.isHmsType && this.setInitialSelectedHms();
    if (this.getUnchangeable !== 'minute') {
      this.setSelectedPeriodType();
    }

    this.canCheckStartDate = this.checkStartDate;
  }
  private setInitialDateRangeOption(): void {
    this.dateRangeOption = Object.assign({}, this.dateRangeOption, this.option);
  }
  get isHmsType(): boolean {
    return this.dateRangeOption.dateType === 'YmdHms';
  }
  get getUnchangeable(): string {
    return this.dateRangeOption.unchangeable;
  }

  private setInitialSelectedHms() {
    if (this.getUnchangeable == 'minute') {
      this.startHm = this.startHms;
      this.endHm = this.endHms;
    } else {
      this.changed.startHms = { ...this.format.hmsStringToHmsObject(this.startHms) };
      this.changed.endHms = { ...this.format.hmsStringToHmsObject(this.endHms) };
    }
  }

  private mounted() {
    this.setRangePicker();
  }

  private setRangePicker() {
    this.rangePicker = DatePicker.createRangePicker({
      language: 'ko',
      startpicker: {
        input: this.inputStartDate,
        container: this.containerStartDate,
        date: moment(this.startYmd).toDate(),
      },
      endpicker: {
        input: this.inputEndDate,
        container: this.containerEndDate,
        date: moment(this.endYmd).toDate(),
      },
      type: 'date',
      format: 'yyyy-MM-dd',
      selectableRanges: [
        [moment(this.dateRangeOption.fromRanges).toDate(), moment(this.dateRangeOption.toRanges).toDate()],
      ],
    });

    this.bindRangePickerUpdateEventHook();
  }

  private bindRangePickerUpdateEventHook(): void {
    this.rangePicker.on('change:start', () => {
      if (!this.rangePicker.getStartDate()) return;
      if (this.canCheckStartDate && !this.isChangeableDate()) return;

      if (this.dayLimit > 0) {
        const startDate = moment(this.rangePicker.getStartDate());
        const endDate = moment(this.rangePicker.getEndDate());

        startDate.format();
        endDate.format();

        if (-startDate.diff(endDate, 'days') > this.dayLimit) {
          const endDateSplit = endDate.format().split('-');
          const year = Number(endDateSplit[0]);
          const month = Number(endDateSplit[1]);
          const day = Number(endDateSplit[2].split('T')[0]);
          const newDay = new Date(year, month - 1, day).getDate() - this.dayLimit;

          this.rangePicker.setStartDate(new Date(year, month - 1, newDay));
          alert(this.$t('COMMON.DATE_PICKER.ALERT_INVALID_DAY_LIMIT', { day: this.dayLimit + 1 }));
          return;
        }
      }

      this.$emit('update:startYmd', moment(this.rangePicker.getStartDate()).format('YYYY-MM-DD'));
    });
    this.rangePicker.on('change:end', () => {
      const endDate = this.rangePicker.getEndDate();

      if (!this.selectBeforeDays) {
        const nowDay = moment()
          .subtract(this.prevDaySelect ? 1 : 0, 'days')
          .format('YYYY-MM-DD');
        const endDay = moment(endDate).format('YYYY-MM-DD');

        if (moment(nowDay).isBefore(endDay)) {
          if (nowDay === endDay) return;

          const now = new Date();
          const yesterday = new Date(now.setDate(now.getDate() - 1));

          this.rangePicker.setEndDate(yesterday);
          alert(this.$t('COMMON.DATE_PICKER.ALERT_INVALID_BEFORE_DAY', { day: this.dayLimit + 1 }));
          return;
        }
      }

      if (endDate === null) {
        this.$emit('update:endYmd', null);
      }

      if (this.canCheckStartDate && !this.isChangeableDate(endDate?.toString())) return;

      if (endDate) {
        this.$emit('update:endYmd', moment(endDate).format('YYYY-MM-DD'));
      }
    });
  }

  private isChangeableDate(endDate?: string): boolean {
    if (
      !this.startYmd ||
      this.startHms === DEFAULT_TIME_RANGE.BLANK_START ||
      !this.endYmd ||
      this.endHms === DEFAULT_TIME_RANGE.BLANK_END
    ) {
      return true;
    }

    let isChangeable = false;

    if (endDate) {
      isChangeable = moment(`${moment(endDate).format('YYYY-MM-DD')} ${this.endHms}`).isAfter(
        `${getStrDate(this.startYmd)} ${this.startHms}`,
      );
    } else {
      const startDate = this.rangePicker.getStartDate();
      isChangeable = this.isHmsType
        ? moment(`${moment(startDate).format('YYYY-MM-DD')} ${this.startHms}`).isSameOrBefore(
            `${this.endYmd} ${this.endHms}`,
          )
        : moment(startDate).isSameOrBefore(this.endYmd);
    }

    !isChangeable && this.undoDateChanges();
    return isChangeable;
  }

  private undoDateChanges(): void {
    this.alertCheckLimitMsg();
    this.rangePicker.setStartDate(moment(this.startYmd).toDate());
    this.rangePicker.setEndDate(moment(this.endYmd).toDate());
  }

  private alertCheckLimitMsg() {
    alert(this.$t(this.alertLimitMsg).toString());
  }

  get periodList(): {
    title: string;
    type: PeriodType;
  }[] {
    return this.dateRangeOption.periodTypes.map((type: PeriodType) => ({ title: PERIOD_NAMES[type], type }));
  }

  private setSelectedPeriodType() {
    const selectedPeriodType = this.matchPeriodForButtonActivation(this.startYmd, this.endYmd);
    this.checkToday && this.checkToday(this.selectedPeriodType, selectedPeriodType);
    this.selectedPeriodType = selectedPeriodType;
  }

  private matchPeriodForButtonActivation(startYmd = '', endYmd = ''): PeriodType | null {
    if (startYmd === '' || endYmd === '') return 'all';

    const startDate = moment(startYmd);
    const endDate = moment(endYmd);
    const today = moment();

    const startDiff = startDate.diff(today, 'days');
    const endDiff = endDate.diff(today, 'days');
    if (startDiff === 0 && endDiff === 0) return 'td';
    if (startDiff === -1 && endDiff === -1) return 'ytd';

    const getPeriodType = (): PeriodType =>
      this.dateRangeOption.periodTypes.find(key => {
        if (key === 'all') {
          return false;
        } else {
          const [amount, unit] = PERIOD_DAY_RANGES[key];
          let dayRangeFromToDay = moment(startYmd).add(amount, unit);
          if (unit === 'M' && endDate.date() !== dayRangeFromToDay.date()) {
            dayRangeFromToDay = dayRangeFromToDay.add(1, 'd');
          }
          if (endDate.isSame(dayRangeFromToDay) && key === 'td') {
            return startDiff === 0 && endDiff === 0;
          }
          if (endDate.isSame(dayRangeFromToDay) && key === 'ytd') {
            return startDiff === -1 && endDiff === -1;
          }
          return endDate.isSame(dayRangeFromToDay);
        }
      }) as PeriodType;
    return getPeriodType() ?? null;
  }

  private onClickPeriodButton(type: PeriodType) {
    this.selectedPeriodType = type;
    this.choicePeriodFromPresets(this.selectedPeriodType);
    this.$nextTick(() => this.setSelectedPeriodType());
  }

  @Watch('selectedPeriodType')
  private clickedPeriodType() {
    if (this.selectedPeriodType !== 'all') return;
    // 검색 폼에서 검색버튼 클릭 후, 변경되는 default 값에 따라 input 값 변
    this.choicePeriodFromPresets(this.selectedPeriodType);
  }

  private choicePeriodFromPresets(type: PeriodType): void {
    this.canCheckStartDate = false;
    const getDate = (amount?: number, unit: unitOfTime.Base = 'days'): Date =>
      amount
        ? moment()
            .add(amount, unit)
            .toDate()
        : moment().toDate();

    let start: Date = moment(DEFAULT_DATE_RANGE.MIN).toDate();
    let end: Date = moment(DEFAULT_DATE_RANGE.MAX).toDate();
    if (PERIOD_DAY_RANGES[type] !== undefined) {
      const [amount, unit] = PERIOD_DAY_RANGES[type];
      start = getDate(-amount, unit);
      end = getDate();

      if (this.option.fromStart && type !== 'td') {
        start = moment(this.startYmd).toDate();
        end = moment(start)
          .add(amount, unit)
          .toDate();
      } else if (this.option.fromToday) {
        start = getDate();
        end = getDate(amount, unit);
      }
    }

    if (type === 'all') {
      // TUI DateRangePicker 에는 start, end 시간을 null 로 세팅하면 빈 input 이 노출됨
      // 추가로 button 의 포커싱 처리를 위해 startYmd, endYmd 값을 '' 으로 처리
      start = null;
      end = null;
      this.$emit('update:startYmd', '');
      this.$emit('update:endYmd', '');
    }

    //현재 날짜에서 하루를 뺍니다
    if (type === 'ytd' || this.prevDaySelect) {
      end = getDate(-1);
    }

    this.rangePicker.setStartDate(start);
    this.rangePicker.setEndDate(end);
    this.setInitialSelectedHms();
    this.canCheckStartDate = this.checkStartDate;
  }

  private beforeDestroy() {
    this.rangePicker.destroy();
    this.rangePicker = null;
    this.format = null;
  }

  @Watch('option.fromRanges')
  private setRangesOption(): void {
    if (!this.checkFromRanges) return;
    this.dateRangeOption.fromRanges = this.option.fromRanges;

    if (!this.rangePicker) return;
    const dateRanges: [Date, Date][] = [
      [moment(this.option.fromRanges).toDate(), moment(this.dateRangeOption.toRanges).toDate()],
    ];
    this.rangePicker.setRanges(dateRanges);
  }
}
