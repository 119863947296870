




























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolTipOption } from '@/types/tooltip';
import { range1ToNI18n } from '@/utils/common';
import { TranslateResult } from 'vue-i18n';
const TYPE = {
  OrderCount: 'OrderCount',
  PayAmount: 'PayAmount',
} as const;

@Component
export default class MemberList extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption<keyof typeof TYPE>;
  @Prop({ default: () => () => null })
  private readonly onNegativeClick!: () => void;

  private tableHeaderWords = range1ToNI18n(5, 'MEMBER.LIST.ORDER_COUNT_TOOLTIP_TH_');

  private get isOrderCount(): boolean {
    return this.option.type === TYPE.OrderCount;
  }

  private get isPayAmount(): boolean {
    return this.option.type === TYPE.PayAmount;
  }

  private get width(): string {
    return this.isOrderCount ? '750px' : '500px';
  }

  private get infoMessage(): TranslateResult {
    return this.isOrderCount
      ? this.$t('MEMBER.LIST.ORDER_COUNT_TOOLTIP_MESSAGE')
      : this.$t('MEMBER.LIST.PAY_AMOUNT_TOOLTIP_MESSAGE');
  }
}
