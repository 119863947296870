




































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { PopupClose, throwPopup } from '@/helpers/popup';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { TranslateResult } from 'vue-i18n';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { OptionData } from '@/helpers/type';
import { ProductAddButtonType } from '@/types';
import {
  SecurityServerData,
  sslStatusType,
  deviceType,
  UpdatedSecurityServeData,
  SecurityServerAllData,
} from '@/types/securityServer';
import {
  getSecurityServerSelect,
  getSecurityServerToolTipOption,
  i18nForSecurity,
  SecurityServerToolTipOption,
} from '@/const/contents/configuration/securityServer';
import { getCurrentMallNo } from '@/utils/mall';
import { goToExternal } from '@/views/contents/configuration/security/security';
import { DISPLAY_TYPE } from '@/const/common';
import { PAGE_TYPE, SSL_STATUS } from '@/const/configuration';
import { PERSONAL_INFO_PROTECTION_ACT } from '@/const/url';
import { DeviceType, Domain, NCPResponse, PutDomainsDomainNoRequest, SslStatus } from 'ncp-api-supporter';
import { getStrYMDHM } from '@/utils/dateFormat';
import { namespace } from 'vuex-class';
import { getEncodeStr } from '@/utils/common';
const adminStore = namespace('admin');

@Component({ components: { NoticeBox, ToolTip, SelectBox } })
export default class SecurityServer extends Vue {
  @adminStore.Getter('getGodoSno')
  private readonly sno!: number;

  private i18nForSecurity = i18nForSecurity;
  private SSL_STATUS = SSL_STATUS;
  private DISPLAY_TYPE = DISPLAY_TYPE;
  private PERSONAL_INFO_PROTECTION_ACT = PERSONAL_INFO_PROTECTION_ACT;

  private selectOptions: OptionData<boolean>[] = getSecurityServerSelect().use;

  private mobileDomains: SecurityServerData[] = null;
  private pcDomains: SecurityServerData[] = null;

  private updatedMobileDomains: UpdatedSecurityServeData[] = [];
  private updatedPcDomains: UpdatedSecurityServeData[] = [];

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  private get toolTipOption(): SecurityServerToolTipOption {
    return getSecurityServerToolTipOption(this.sno);
  }

  private getExpiredDateTime(sslStatus: SslStatus, sslExpiredDateTime: string): string {
    if (!sslExpiredDateTime || this.isNotInstalled(sslStatus)) return '-';
    return getStrYMDHM(sslExpiredDateTime);
  }

  private mainDomain = '';
  /**
   * @domain: www.abc.co.kr || www.abc.com || www.abc.net
   * 1차 도메인 판별을 위해 abc 값을 mainDomain 의 값으로 할당
   **/
  private setMainDomain(domain: string) {
    this.mainDomain = domain.split('.')[1];
  }

  private getDomain(domain: string, deviceType: DeviceType, isMain = false): string {
    // if (deviceType === undefined) return null;

    if (isMain) {
      deviceType === DISPLAY_TYPE.PC && this.setMainDomain(domain);
      return `[${this.$t('MAIN')}] ${domain}`;
    }

    if (this.isMobileType(deviceType)) {
      return domain;
    }

    const divided = domain.split('.');
    if (this.mainDomain === divided[1]) {
      return domain;
    }

    // 2차 도메인 사용 시, www. 없이 바로 표기
    return domain.replace('www.', '');
  }

  private getUrl(domain: string, sslUsed: boolean, status: sslStatusType): string {
    // 보안서버관리 기획서 8p
    const isExpiration = this.isExpiration(status);
    // 기긴만료 + ssl 인증서 유효하지 않은 경우 && 보안서버 사용 함 -> 접근불가
    if (isExpiration && sslUsed) return '';
    // ssl 유효 && 보안서거 사용 함 -> https 로 리다이렉트
    if (!isExpiration && sslUsed) return `https://${domain}`;
    return `http://${domain}`;
  }

  private getSealSettingState(item: SecurityServerData): TranslateResult {
    if (this.isNotInstalled(item.sslStatus)) {
      return '-';
    }
    return item.displaysTrustSeal ? this.i18nForSecurity('SSL_USE') : this.i18nForSecurity('SSL_NOT_USE');
  }

  private isNotInstalled(status: sslStatusType): boolean {
    return status === this.SSL_STATUS.NOT_INSTALLED;
  }

  private isExpiration(status: sslStatusType): boolean {
    return status === this.SSL_STATUS.EXPIRATION;
  }

  private isMobileType(type: deviceType): boolean {
    return type === this.DISPLAY_TYPE.MOBILE;
  }

  private onChangeSslUsed(val: boolean, item: SecurityServerData, idx: number): void {
    if (this.isChangeable(val)) {
      this.setUpdatedDomains(idx, this.isMobileType(item.deviceType), {
        sslUsed: val,
        domainNo: item.domainNo,
        isUpdated: true,
      });
      return;
    }
    this.$nextTick(() => (item.sslUsed = !val));
  }

  private isChangeable(sslUsed: boolean): boolean {
    const value = sslUsed ? 'USE' : 'NOT_USE';
    return confirm(this.i18nForSecurity('CONFIRM_CHANGING_SECURITY_SERVER_USEYN', value).toString());
  }

  private setUpdatedDomains(idx: number, isMobileType: boolean, updatedDomain: Partial<SecurityServerData>) {
    if (isMobileType) {
      this.$set(this.mobileDomains, idx, { ...this.mobileDomains[idx], ...updatedDomain });
      return;
    }
    this.$set(this.pcDomains, idx, { ...this.pcDomains[idx], ...updatedDomain });
  }

  private goToExternal() {
    // goToExternal(PAGE_TYPE.DOMAIN, getEncodeStr(this.sno));
    goToExternal(PAGE_TYPE.DOMAIN);
  }

  private openGuidePopup(): void {
    throwPopup({
      name: 'SecurityServerSettingGuide',
      data: { mallNo: this.mallNo },
    });
  }

  private openSealSettingPopup(item: SecurityServerData, idx: number): void {
    if (this.isNotInstalled(item.sslStatus)) return;

    throwPopup({
      name: 'SealSetting',
      data: {
        displaysTrustSeal: item.displaysTrustSeal,
        trustSeal: item.trustSeal ?? '',
        encodedSon: getEncodeStr(this.sno),
      },
    }).then(({ state, data: { displaysTrustSeal, trustSeal } }) => {
      if (state === PopupClose.CONFIRM) {
        this.setUpdatedDomains(idx, this.isMobileType(item.deviceType), {
          trustSeal,
          displaysTrustSeal,
          domainNo: item.domainNo,
          isUpdated: true,
        });
      }
    });
  }

  private created() {
    this.init();
    this.resetSecurityServerData();
  }

  private init() {
    this.setBottomNavigator();
  }

  @Watch('$route')
  private async resetSecurityServerData(): Promise<void> {
    this.resetAllData();
    await this.fetchDomains();
    // await this.setDomainsByDevice();
    this.setEmptyDomains();
  }

  private resetAllData() {
    this.domains = [];
    this.resetAllDomains();
    this.updatedMobileDomains = [];
    this.updatedPcDomains = [];
    this.mainDomain = '';
  }

  //
  private domains: Domain[] = [];
  private async fetchDomains() {
    const { data }: NCPResponse<Domain[]> = await this.$api.getDomainsByMallNo({ mallNo: this.mallNo });
    this.domains = data;
    await this.setDomains(data);
    this.setAllDomains();
  }

  private allDomains = {} as SecurityServerAllData;
  private setAllDomains() {
    this.allDomains = {
      [DISPLAY_TYPE.PC]: this.pcDomains,
      [DISPLAY_TYPE.MOBILE]: this.mobileDomains,
    };
  }

  private getDomainsByDevice(domains: Domain[]): SecurityServerData[] | null {
    const { length } = domains;
    if (length === 0) return null;
    return this.getRowSpan(this.mapMainDomain(this.mapUseState(domains)), length);
  }

  private resetAllDomains() {
    this.mobileDomains = [];
    this.pcDomains = [];
  }

  private setDomains(data: Domain[]): void {
    this.resetAllDomains();
    if (data.length === 0) return;
    this.divideDomainsByDevice(data);
    this.mobileDomains = [...this.getDomainsByDevice(this.mobileDomains)];
    this.pcDomains = [...this.getDomainsByDevice(this.pcDomains)];
  }

  // mobile && pc 분리
  private divideDomainsByDevice(domains: Domain[]): void {
    domains.forEach(domain => {
      if (domain.deviceType === this.DISPLAY_TYPE.MOBILE) {
        this.mobileDomains.push(domain);
      } else {
        this.pcDomains.push(domain);
      }
    });
  }

  // ssl 인증서 상태에 따라 사용여부 설정
  private mapUseState(domains: SecurityServerData[]): SecurityServerData[] {
    return domains.reduce((mappedDomain, currDomain) => {
      mappedDomain.push({
        ...currDomain,
        sslUsed: this.isNotInstalled(currDomain.sslStatus) ? false : currDomain.sslUsed ?? true,
      });
      return mappedDomain;
    }, []);
  }

  // 대표 최상단으로 위치
  private mapMainDomain(domains: SecurityServerData[]): SecurityServerData[] {
    if (!domains[0].isMain) {
      const foundIdx = domains.findIndex(domain => domain.isMain);
      if (foundIdx >= 0) {
        const main = domains[foundIdx];
        domains.splice(foundIdx, 1);
        domains.unshift(main);
      }
    }
    return domains;
  }

  private getRowSpan(domains: SecurityServerData[], count: number): SecurityServerData[] {
    domains[0].rowSpan = count;
    return domains;
  }

  private setEmptyDomains() {
    this.updatedPcDomains = Array(this.pcDomains.length).fill(null);
    this.updatedMobileDomains = Array(this.mobileDomains.length).fill(null);
  }

  private setBottomNavigator() {
    throwBottomNavigation({
      buttons: [
        {
          type: 'right',
          key: 'save',
          color: 'red',
          text: this.$t('SAVE'),
        },
      ],
      onClick: (navType: ProductAddButtonType) => {
        if (navType === 'save') {
          this.putDomains();
          // this.fetchDomains();
        }
      },
    });
  }
  private result = [];
  private async putDomainsByDomainNo() {
    const mallNo = this.mallNo;
    const updated: SecurityServerData[] = this.pcDomains.concat(this.mobileDomains).filter(domain => domain.isUpdated);
    if (updated.length === 0) {
      alert(this.$t('ALERT_NOT_CHANGED'));
      return;
    }
    const promises = updated.map(domain => {
      const request: PutDomainsDomainNoRequest = {
        pathParams: {
          domainNo: domain.domainNo.toString(),
        },
        data: {
          mallNo,
          sslUsed: domain.sslUsed,
          trustSeal: domain.displaysTrustSeal ? domain.trustSeal : null,
        },
      };
      return this.$api
        .putDomainsDomainNoRequest(request)
        .then(() => true)
        .catch(e => e?.code);
    });
    return Promise.all(promises).then(res => res.filter(v => v));
  }
  private putDomains() {
    this.putDomainsByDomainNo().then(res => {
      this.resetSecurityServerData();

      if (['FORBIDDEN_ERROR', 'AU0003'].includes(res.toString())) {
        alert(this.$t('MEMBER.MAIL.NO_AUTH_ALERT').toString());
        return;
      }

      if (res.some(r => typeof r !== 'boolean' || false)) return;

      alert(this.$t('ALERT_SAVED'));
    });
  }
}
