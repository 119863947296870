




































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditForm from '@/components/product/management/standardFee/EditForm.vue';
import TreeContainer from '@/components/product/management/standardFee/tree/TreeContainer.vue';
import {
  GetStandardCategoriesTreeRequest,
  NCPResponse,
  PutStandardCategoriesCommissionsRequest,
} from 'ncp-api-supporter';
import { replaceKeyNames } from '@/utils/keyReplace';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { EventKeyword, NestedTreeNode, TreeNode } from '@/types/tree';
import { GetStandardCategoriesTree } from 'ncp-api-supporter/dist/types/modules/display/standardCategory';
import ExcelController from '@/components/product/management/standardFee/excel/ExcelController.vue';
import { getCurrentMallNo } from '@/utils/mall';

const tree = namespace('tree');

@Component({
  components: {
    ExcelController,
    EditForm,
    TreeContainer,
  },
})
export default class StandardFee extends Vue {
  @tree.Action('setTree')
  private readonly setTree!: () => void;
  @tree.Action('publishEvent')
  private readonly publishEvent!: (eventKeyword: EventKeyword) => void;
  @tree.Action('resetRecord')
  private readonly resetRecord!: () => void;
  @tree.Getter('getUpdatedNodes')
  private readonly getUpdatedNodes!: TreeNode[];

  private treeNodes: NestedTreeNode[] = [];

  created() {
    this.initBottomNavigation();
  }

  mounted() {
    this.fetchCategoriesTree();
  }

  @Watch('$route.query.mallNo')
  private async fetchCategoriesTree() {
    const request: GetStandardCategoriesTreeRequest = { params: { mallNo: getCurrentMallNo(this).toString() } };
    try {
      const { data }: NCPResponse<GetStandardCategoriesTree> = await this.$api.getStandardCategoriesTree(request);
      this.setTreeNodes(data);
    } catch (error) {
      const FORBIDDEN_ERROR_CODE = 'NCPE0004';
      if (error?.data?.code && error.data.code === FORBIDDEN_ERROR_CODE) this.$router.go(-1);
    }
  }

  private setTreeNodes(data: GetStandardCategoriesTree) {
    this.treeNodes = replaceKeyNames<NestedTreeNode>(data.tree, {
      name: 'nodeName',
      displayOrder: 'index',
      child: 'children',
    });

    this.setTree();
  }

  private onClickSubmit() {
    const accept = confirm(this.$t('PRODUCT.COMMISSION.CONFIRM').toString());
    if (!accept) return;

    this.updateCategoriesCommissions();
  }

  private async updateCategoriesCommissions() {
    await this.publishEvent('ASSEMBLE_NODE');

    const updatedNodes = this.getUpdatedNodes;

    if (!updatedNodes.length) return alert(this.$t('ALERT_NO_CHANGED'));

    const requestBody: PutStandardCategoriesCommissionsRequest = {
      data: {
        mallNo: getCurrentMallNo(this),
        commissionInfos: updatedNodes.map(treeNode => ({
          categoryNo: Number(treeNode.categoryNo),
          commissionRate: treeNode.commissionRate === '' ? 0 : Number(treeNode.commissionRate),
        })),
      },
    };

    try {
      await this.$api.putStandardCategoriesCommissions(requestBody);
      alert(this.$t('SAVE_SUCCESS'));
      this.resetRecord();
    } catch (error) {
      console.error(error);
    }
  }

  private initBottomNavigation(): void {
    throwBottomNavigation({
      buttons: [
        {
          key: 'ON_CLICK_SAVE',
          type: 'right',
          color: 'red',
          text: this.$t('SAVE_UPDATE').toString(),
        },
      ],
      onClick: (key: 'ON_CLICK_SAVE'): void => {
        if (key === 'ON_CLICK_SAVE') this.onClickSubmit();
      },
    });
  }
}
