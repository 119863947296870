

















import { Vue, Component, PropSync, Prop, Ref } from 'vue-property-decorator';
import { RadioBoxGroupOption } from '@/helpers/type';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { CombinationOptionType } from '@/types';
import { YorN } from 'ncp-api-supporter';

@Component({
  components: {
    RadioGroup,
  },
})
export default class OptionsInfo extends Vue {
  @PropSync('useOption', { required: true })
  private useOptionSync: YorN;

  @PropSync('options', { required: true })
  private optionsSync: CombinationOptionType<string>[];

  @Prop({ required: true })
  private readonly isEditMode: boolean;

  @Prop({ required: true })
  private readonly isSelectedMall: boolean;

  @Prop({ required: true })
  private readonly productName: string;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'useOption',
    data: [
      { id: 'useOptionFalse', value: 'N', display: 'PRODUCT.COMMON.NOT_USE' },
      { id: 'useOptionTrue', value: 'Y', display: 'PRODUCT.COMMON.USE' },
    ],
  };

  @Ref()
  private readonly useOptionRef: RadioGroup;
  private changedUseOptionStatus(curr: YorN, prev: YorN): void {
    if (!this.isRequired(curr, prev)) return;
    if (!this.isAddableDefaultOption(curr, prev)) return;
    this.$emit('add-option');
  }

  private isRequired(curr: YorN, prev: YorN): boolean {
    if (curr === 'Y' && !this.isSelectedMall) {
      this.undoChanges(prev, 'alertMallSelect');
      return false;
    }

    if (!this.isEditMode) return false;

    if (curr === 'Y' && this.productName === '') {
      this.undoChanges(prev, 'alertProductName');
      return false;
    }
    return true;
  }

  private undoChanges(prev: YorN, emitEvent: 'alertMallSelect' | 'alertProductName'): void {
    this.$emit(emitEvent);
    this.$nextTick(() => {
      this.useOptionSync = prev;
      // this.useOptionRef.value = prev;
    });
  }

  private isAddableDefaultOption(curr: YorN, prev: YorN): boolean {
    return curr !== prev && curr === 'Y' && this.optionsSync.length < 1;
  }
}
