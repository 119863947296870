





















import { Component, Vue } from 'vue-property-decorator';
import {
  GetClaimsOrderOptionsOrderOptionNoApplyRequest,
  GetClaimsOrderOptionsOrderOptionNoApply,
} from 'ncp-api-supporter';
import ExchangeApplyPopupBefore from '@/views/popups/claim/ExchangeApplyPopupBefore.vue';
import ExchangeApplyPopupAfter from '@/views/popups/claim/ExchangeApplyPopupAfter.vue';
import { ComponentItemsClaimDataType, ClaimableOption } from '@/components/popup/claim/ClaimApplyPopup/DataType';
@Component({ components: { ExchangeApplyPopupBefore, ExchangeApplyPopupAfter } })
export default class ExchangeApplyPopup extends Vue {
  private mallNo = '';
  private claimType = '';
  private escrowOrder = false;
  private orderOptionsApply: ComponentItemsClaimDataType = {
    orderNo: '',
    claimableOptions: [],
    refundTypes: [],
    payType: '',
  };
  private claimOrderOptions!: GetClaimsOrderOptionsOrderOptionNoApply;
  private orderStatusType = {};
  private orderStatusTypeMap: Map<string, number> = new Map([
    ['DEPOSIT_WAIT', 1],
    ['PAY_DONE', 2],
    ['PRODUCT_PREPARE', 3],
    ['DELIVERY_PREPARE', 4],
    ['DELIVERY_ING', 5],
    ['DELIVERY_DONE', 6],
    ['BUY_CONFIRM', 7],
    ['CANCEL_DONE', 8],
    ['RETURN_DONE', 9],
    ['EXCHANGE_DONE', 10],
    ['PAY_WAIT', 11],
    ['PAY_CANCEL', 12],
    ['PAY_FAIL', 13],
    ['DELETE', 14],
    ['EXCHANGE_WAIT', 15],
    ['REFUND_DONE', 16],
  ]);
  private partnerNo = '';
  private memberNo = '';
  private isShow = false;
  private holdProcess = false;
  private holdByReservation = false;
  created() {
    const request: GetClaimsOrderOptionsOrderOptionNoApplyRequest = {
      pathParams: {
        orderOptionNo: this.$route.query.orderProductOptionNo.toString(),
      },
      params: {
        claimType: 'EXCHANGE',
      },
    };
    this.$api
      .getClaimsOrderOptionsOrderOptionNoApply(request)
      .then(ret => {
        this.claimOrderOptions = ret.data;
        this.partnerNo = ret.data.partnerNo.toString();
        this.memberNo = ret.data.memberNo.toString();
        this.holdProcess = false;
        this.holdByReservation = false;
        if (ret.data.claimedOption.mallProductNo === ret.data.claimedOption.mallProductNo) {
          this.mallNo = ret.data.mallNo.toString();
          const claimableOption: ClaimableOption = {
            ...ret.data.claimedOption,
            checkboxValue: false,
            mallOptionNo: ret.data.claimedOption.mallOptionNo,
            userInputText: ret.data.claimedOption.userInputText,
            orderNo: ret.data.claimedOption.orderNo,
            immediateDiscountAmt: ret.data.claimedOption.immediateDiscountAmt,
            salePrice: ret.data.claimedOption.salePrice,
            orderStatusType: ret.data.claimedOption.orderStatusType,
            optionValue: ret.data.claimedOption.optionValue,
            orderCnt: ret.data.claimedOption.orderCnt,
            addPrice: ret.data.claimedOption.addPrice,
            mallProductNo: ret.data.claimedOption.mallProductNo,
            claimedCnt: ret.data.claimedOption.claimedCnt,
            productName: ret.data.claimedOption.productName,
            claimStatusType: ret.data.claimedOption.claimStatusType,
            additionalDiscountAmt: ret.data.claimedOption.additionalDiscountAmt,
            optionName: ret.data.claimedOption.optionName,
            orderOptionNo: ret.data.claimedOption.orderOptionNo,
            partnerNo: Number(ret.data.partnerNo),
            partnerName: ret.data.partnerName,
            payType: '',
            productUrl: ret.data.claimedOption.productUrl,
            holdProcess: ret.data.claimedOption.holdProcess || this.holdProcess,
            holdByReservation: ret.data.claimedOption.holdByReservation || this.holdByReservation,
            optionManagementCd: ret.data.claimedOption.optionManagementCd,
            usesOption: ret.data.claimedOption.usesOption,
            hasSetOption: ret.data.claimedOption.hasSetOption,
          };
          this.orderOptionsApply.claimableOptions.push(claimableOption);
        }
        this.orderOptionsApply.refundTypes = ret.data.refundTypes;
        this.orderOptionsApply.payType = ret.data.payType;
        this.orderOptionsApply.orderNo = ret.data.claimedOption.orderNo;
        this.escrowOrder =
          this.orderOptionsApply.payType.indexOf('ESCROW_REALTIME_ACCOUNT_TRANSFER') >= 0 ||
          this.orderOptionsApply.payType.indexOf('ESCROW_VIRTUAL_ACCOUNT') >= 0;
        if (
          this.orderStatusTypeMap.get(ret.data.claimedOption.orderStatusType) >=
          this.orderStatusTypeMap.get('DELIVERY_ING')
        ) {
          this.claimType = 'ExchangePopupAfter';
        } else {
          this.claimType = 'ExchangePopupBefore';
        }
        this.isShow = true;
      })
      .catch(error => {
        alert(error.data.message);
        window.opener.location.reload();
        window.close();
      });
  }
  mounted() {
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-1200');
    });
  }
}
