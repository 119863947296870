// 노출 플랫폼
import { OptColumn } from '@/types';
import { MemberGradeDisplayInfo } from 'ncp-api-supporter';
import { i18n } from '@/main';

interface MemberGrade {
  display: string;
  id: string;
  value: string;
}

export const getMemberGradeDisplayInfoColumn = (): OptColumn => ({
  header: 'PRODUCT.STATUS.MEMBER_GRADE.STATUS',
  name: 'memberGradeDisplayInfo',
  align: 'center',
  minWidth: 150,
  formatter: ({ row, value }) => {
    const memberGradeDisplayInfo = value as MemberGradeDisplayInfo;

    if (row.nonMemberPurchaseAvailable && memberGradeDisplayInfo.check !== 'ALL') {
      return memberGradeDisplayInfo.check === 'ANY'
        ? i18n.t('PRODUCT.STATUS.MEMBER_GRADE.SPECIFIC_MEMBER').toString()
        : i18n.t('PRODUCT.STATUS.MEMBER_GRADE.ALL').toString();
    } else {
      return i18n.t('PRODUCT.STATUS.MEMBER_GRADE.ONLY_MEMBER').toString();
    }
  },
});
