import { OptColumn } from '@/types';
import { throwPopup } from '@/helpers/popup';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { formatCurrency } from '@/utils/numberFormat';
import { Row } from '@/types/tui-grid';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';

export const getSupplyPriceHeader = () => ({
  name: 'supplyPrice',
  renderer: props => {
    props.tooltipId = 'supplyPrice';
    return headerWithTooptipRenderer(props);
  },
});

export const getReviewCountHeader = () => ({
  name: 'reviewCount',
  renderer: props => {
    props.tooltipId = 'reviewCount';
    return headerWithTooptipRenderer(props);
  },
});

interface CommissionType {
  rate: number;
  type: string;
}

export const getSupplyPriceColumn = (minWidth = 100): OptColumn => ({
  header: 'PRODUCT.SUPPLY_PRICE',
  name: 'supplyPrice',
  align: 'center',
  minWidth,
  formatter: ({ row, value }) => {
    const { hasOption, saleMethodType, commission } = row;
    const { type } = commission as CommissionType;
    const isSupplyPriceByOption = hasOption === true && saleMethodType === 'CONSIGNMENT' && type === 'PURCHASE_PRICE';
    const formattedValue = formatCurrency(value as number);
    return isSupplyPriceByOption ? getAnchorHTML(i18n.t('DETAILS')) : formattedValue;
  },
});

export const openSupplyPriceDetailsPopup = (rowData: Row) => {
  const { commission } = rowData;

  if (commission && commission['type'] !== 'PURCHASE_PRICE') {
    return;
  } else {
    throwPopup({
      name: 'SupplyPriceDetails',
      data: { mallProductNo: rowData.mallProductNo },
    });
  }
};
