import { PARTNER_NOTICE_SEARCH_TYPE, PARTNER_NOTICE_TARGET } from '@/const/partner';
import { PartnerNoticeSearchForm } from '@/types/partner';
import { addYear, getToday } from '@/utils/dateFormat';
import { mall } from '@/utils/mall';
import { ManageNoticeCategoryType } from 'ncp-api-supporter';
import { OptionData } from '@/helpers/type';

const DEFAULT_NUMBER = {
  YEAR_RAGE: 1,
  PAGE_SIZE: 30,
};

export const today = getToday();

export const getDefaultPartnerNoticeSearchForm = (): PartnerNoticeSearchForm => ({
  mallNo: Number(mall.lastCreatedMall.mallNo),
  noticeTarget: PARTNER_NOTICE_TARGET,
  noticeCategory: undefined,
  searchType: PARTNER_NOTICE_SEARCH_TYPE.ALL,
  keyword: '',
  startDateTime: addYear(today, -DEFAULT_NUMBER.YEAR_RAGE),
  endDateTime: today,
  page: 1,
  pageSize: DEFAULT_NUMBER.PAGE_SIZE,
});

export const getCategorySelectBox = (
  categories?: ManageNoticeCategoryType[],
): OptionData<ManageNoticeCategoryType>[] => {
  if (!categories) return [];

  return categories.map(value => ({
    name: `PARTNER.NOTICE.${value}`,
    value,
  }));
};
