


















import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WithdrawalMemberError extends Vue {
  private closePopup() {
    window.close();
  }
}
