export default Object.freeze({
  IS_MEMBER: '회원구분',
  MEMBER: '회원',
  NON_MEMBER: '비회원',

  IS_INVOICE_REGISTERED: '송장번호 등록여부',
  INVOICE_REGISTERED: '송장번호 등록함',
  NON_INVOICE_REGISTERED: '송장번호 등록 안 함',

  SORT_TYPE_STANDARD: '정렬기준',
  SORT_TYPE_CLAIM_DESC: '클레임번호 ↓',
  SORT_TYPE_ORDERER_NAME_ASC: '주문자명 ↑',
  SORT_TYPE_ORDERER_NAME_DESC: '주문자명 ↓',
  SORT_TYPE_CANCEL_AMOUNT_DESC: '취소금액 ↓',
  SORT_TYPE_CANCEL_AMOUNT_ASC: '취소금액 ↑',
  SORT_TYPE_EXCHANGE_AMOUNT_DESC: '교환금액 ↓',
  SORT_TYPE_EXCHANGE_AMOUNT_ASC: '교환금액 ↑',
  SORT_TYPE_RETURN_AMOUNT_DESC: '반품금액 ↓',
  SORT_TYPE_RETURN_AMOUNT_ASC: '반품금액 ↑',
  SORT_TYPE_REFUND_AMOUNT_DESC: '환불금액 ↓',
  SORT_TYPE_REFUND_AMOUNT_ASC: '환불금액 ↑',

  RETURN_WAY_TYPE_ALL: '반품수거방법',
  RETURN_WAY_TYPE_SELLER_COLLECT: '판매자수거요청',
  RETURN_WAY_TYPE_BUYER_DIRECT_RETURN: '구매자직접반품',
});
