




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class ProductBulkAddIframe extends Vue {
  private get remoteSrc() {
    const queryString = new URLSearchParams({ serviceType: 'PRO' }).toString();
    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/product/management/bulk/add?' + queryString;
  }
}
