export default Object.freeze({
  TITLE: '판매통계',
  TAB_PERIOD: '기간별 판매현황',
  TAB_PRODUCT: '상품 판매현황',
  TAB_CATEGORY: '카테고리 판매현황',
  NOTICE_HEAD_P_ONE: '클레임 구분',
  NOTICE_HEAD_UL_LI_ONE: '클레임 제외 : 결제(입금)완료 건만 조회',
  NOTICE_HEAD_UL_LI_TWO:
    '클레임 포함 : 결제(입금)완료 – 취소완료(환불완료) – 반품완료(환불완료) – 교환완료(환불완료) - 교환완료(결제완료) - 교환완료',
  NOTICE_HEAD_P_TWO: '판매건수 : 클레임 포함 조회시 부분취소(반품)일 경우 판매(주문)건수에는 포함되지 않습니다.',
  NOTICE_HEAD_P_THREE: '판매금액(A) : 판매가 – 즉시할인가',
  NOTICE_HEAD_P_FOUR:
    '(* 네이버페이 주문형으로 결제한 주문의 네이버 조정금액으로 인해 오차가 발생할 수 있습니다. 상세 금액은 네이버페이센터를 통해 확인해 주세요.)',
  NOTICE_HEAD_P_FIVE: '프로모션 할인금액(B) : 추가 할인금액 + 상품 금액 할인 + 장바구니 금액 할인',
  NOTICE_HEAD_P_SIX: '총 결제금액(F) : 실제 결제금액',
  NOTICE_HEAD_P_SEVEN:
    '회원그룹으로 조회 시,복수 회원그룹을 가진 회원의 데이터가 이중 집계되어 오차가 발생할 수 있습니다. ',
  FORM_LABEL_ONE: '쇼핑몰',
  FORM_LABEL_TWO: '회원유형',
  SELECT_ALL: '전체',
  MEMBER_TYPE_MALL: '쇼핑몰회원',
  FORM_LABEL_THREE: '회원성별',
  CHECK_ALL: '전체선택',
  GENDER_TYPES_MALE: '남성',
  GENDER_TYPES_FEMALE: '여성',
  GENDER_TYPES_UNKNOWN: '미분류',
  FORM_FOUR: '회원등급',
  FORM_FIVE: '회원그룹',
  FORM_SIX: '플랫폼구분',
  PLATFORM_TYPES_MOBILE_WEB: '모바일 웹',
  PLATFORM_TYPES_MOBILE_APP: '모바일 앱',
  FORM_SEVEN: '클레임구분',
  INCLUDES_CLAIM_FALSE: '클레임제외',
  INCLUDES_CLAIM_TRUE: '클레임포함',
  FORM_EIGHT: '결제수단구분',
  PAY_TYPE_ALL: '선택안함',
  FORM_NINE: '기간',
  DATE_TYPE_MONTH: '월별',
  DATE_TYPE_PERIOD: '기간별',
  TABLE_TAB_TITLE: '기간별 판매현황 종합',
  TABLE_TAB_ONE: '조회기간',
  TABLE_TAB_TWO: '쇼핑몰',
  TABLE_THREE_TITLE: '일자별 판매현황',
  PAGESIZE_INIT: '개 보기',
});
