































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SearchKeyword from '@/components/marketing/configuration/SearchKeyword.vue';
import MarketingThumb from '@/components/marketing/configuration/MarketingThumb.vue';
import Grid from '@/components/common/grid/Main.vue';
import { Row } from '@/types/tui-grid';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { GridEventProps } from '@/types';
import {
  authorityMenuNo,
  gridProp,
  searchKeywordList,
  reqeustParam,
  periodInfo,
} from '@/views/contents/marketing/productConfig/GoogleAdMarketing';
import {
  Mall,
  PostMarketingProductSettingRequest,
  ProductsSearchContent,
  ProductsSearchPostRequest,
  ProductSearchKeywordType,
} from 'ncp-api-supporter';
import { Getter } from 'vuex-class';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { getToday, addMonth } from '@/utils/dateFormat';
import { DEFAULT_DATE_RANGE, DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';

@Component({
  components: {
    MallSelect,
    SearchButton,
    SearchKeyword,
    Grid,
    MarketingThumb,
    DateRangePicker,
    SelectBox,
  },
})
export default class GoogleAdMarketing extends Vue {
  @Ref()
  private readonly searchKeyword: SearchKeyword;
  @Ref()
  private readonly grid!: Grid;

  private readonly marketingChannels = 'GOOGLE';

  private searchKeywordType: ProductSearchKeywordType = 'PRODUCT_NAME';
  private gridProp = gridProp();
  private searchKeywordList = searchKeywordList;
  private periodInfo = periodInfo;
  private reqeustParam: ProductsSearchPostRequest = reqeustParam;
  private contents: ProductsSearchContent[] = [];
  private totalCount = 0;
  private mallNo = '';
  private firstMallNo = '';
  private inputText = '';
  private showButton = false;
  private selectAllData = false;

  @Watch('$route.query')
  private reRenderList() {
    this.fetchMarketingProduct();
  }

  @Getter('mall/getMalls')
  private readonly malls!: Mall[];

  async created() {
    await this.checkShowButtonDisplay();
    this.firstMallNo = this.malls[this.malls.length - 1].mallNo.toString();
    this.reqeustParam.data.marketingChannels = ['GOOGLE'];

    if (this.$route.query.mallNo) {
      this.mallNo = this.$route.query.mallNo.toString();
    } else {
      this.mallNo = this.firstMallNo;
    }
  }

  private resetPeriod(): void {
    this.reqeustParam.data.periodInfo.period.startYmdt = addMonth(new Date(), -3);
    this.reqeustParam.data.periodInfo.period.endYmdt = getToday();
  }

  private async checkShowButtonDisplay() {
    const { data } = await this.$api.getManagerInfo();

    if (data.role === 'MASTER') {
      this.showButton = true;
      return;
    }

    const authorityGroupsRes = await this.$api.getAuthorityGroupsByGroupNo({
      pathParams: {
        authorityGroupNo: data.authority.no.toString(),
      },
    });

    const menuAuthorities = authorityGroupsRes.data.menuAuthorities;
    const authorityMenu = menuAuthorities.find(item => item.menuNo === authorityMenuNo);

    if (authorityMenu.writable == true) this.showButton = true;
  }

  private async fetchMarketingProduct() {
    if (this.mallNo === '') {
      alert(this.$t(`MARKETING.CONFIGURATION.PLEASE_SELECT`));
      return false;
    }

    this.applicateParam();
    const { data } = await this.$api.postProductsSearch(this.reqeustParam);
    this.contents = data.contents;
    this.totalCount = data.totalCount;

    if (this.selectAllData) {
      this.reqeustParam.data.periodInfo.period = {
        startYmdt: '',
        endYmdt: '',
      };
    }
  }

  private resetComponent() {
    if (this.reqeustParam.data.keywordInfo) {
      this.reqeustParam.data.keywordInfo.type = 'PRODUCT_NAME';
      this.reqeustParam.data.keywordInfo.keywords = null;
    }

    this.mallNo = this.firstMallNo;
    this.reqeustParam.data.mallNos = [];
    this.searchKeywordType = 'PRODUCT_NAME';
    this.inputText = '';
    this.searchKeyword.reset();
    this.reqeustParam.data.periodInfo.type = 'REGISTER_DATE';
    this.resetPeriod();
  }

  private applicateParam() {
    this.getPageNumber();

    const typeChangeNum = Number(this.mallNo);
    this.reqeustParam.data.mallNos = typeChangeNum === 0 ? [] : [typeChangeNum];

    if (this.reqeustParam.data.keywordInfo) {
      this.reqeustParam.data.keywordInfo.type = this.searchKeywordType;
      this.reqeustParam.data.keywordInfo.keywords = [this.inputText];
    }

    //날짜 변경: 만약 전체 선택이면
    if (!this.reqeustParam.data.periodInfo.period.startYmdt || !this.reqeustParam.data.periodInfo.period.endYmdt) {
      this.reqeustParam.data.periodInfo.period = {
        startYmdt: DEFAULT_DATE_RANGE.MIN,
        endYmdt: DEFAULT_DATE_RANGE.MAX,
      };

      this.reqeustParam.data.periodInfo.period.startYmdt += ` ${DEFAULT_TIME_RANGE.START}`;
      this.reqeustParam.data.periodInfo.period.endYmdt += ` ${DEFAULT_TIME_RANGE.END}`;

      this.selectAllData = true;
    } else {
      //날짜 변경: 만약 날짜 변경을 안했다면
      const result1 = this.reqeustParam.data.periodInfo.period.startYmdt.substr(0, 10);
      const result2 = this.reqeustParam.data.periodInfo.period.endYmdt.substr(0, 10);

      this.reqeustParam.data.periodInfo.period.startYmdt = result1 + ` ${DEFAULT_TIME_RANGE.START}`;
      this.reqeustParam.data.periodInfo.period.endYmdt = result2 + ` ${DEFAULT_TIME_RANGE.END}`;

      this.selectAllData = false;
    }
  }

  private getPageNumber() {
    if (this.$route.query.page) {
      this.reqeustParam.data = {
        ...this.reqeustParam.data,
        page: Number(this.$route.query.page),
        size: Number(this.$route.query.size),
      };
    } else {
      this.reqeustParam.data = {
        ...this.reqeustParam.data,
        page: 1,
        size: 30,
      };
    }
  }

  private onItemClicked({ rowKey, columnName }: GridEventProps) {
    const rowData: Row = this.grid.getRowAt(rowKey);
    switch (columnName) {
      case 'edit':
      case 'mallProductNo':
        openEditProductWindow(Number(rowData.mallProductNo));
        break;
    }
  }

  private async productDisplayable(displayable: boolean) {
    const checkedRows: Row[] = this.grid.getCheckedRows();

    if (checkedRows.length === 0) {
      alert(this.$t(`MARKETING.CONFIGURATION.PLEASE_CHECK`));
      return false;
    }

    const mallNo = Number(this.mallNo);
    const checkedProductNum = checkedRows.map(product => {
      return {
        productNo: Number(product.mallProductNo),
        displayable: displayable,
        additionalInfo: null,
      };
    });

    const request: PostMarketingProductSettingRequest = {
      data: {
        mallNo,
        channelType: this.marketingChannels,
        settings: checkedProductNum,
      },
    };

    await this.$api.postMarketingProductSetting(request);

    if (displayable == true) {
      alert(
        `${this.$t(`MARKETING.CONFIGURATION.SUCCESS`)} ${checkedRows.length} ${this.$t(`CASE`)}: ${this.$t(
          `MARKETING.CONFIGURATION.SUCCESS_DISPLAYABLE`,
        )}`,
      );
    } else if (displayable == false) {
      alert(
        `${this.$t(`MARKETING.CONFIGURATION.SUCCESS`)} ${checkedRows.length} ${this.$t(`CASE`)}: ${this.$t(
          `MARKETING.CONFIGURATION.SUCCESS_NO_DISPLAYABLE`,
        )}`,
      );
    }

    // location.reload();
    this.fetchMarketingProduct();
  }
}
