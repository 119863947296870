import { CellValue } from '@/types/tui-grid';
import { AnchorTagInfo } from '@/views/contents/partner/modification/PartnerModificationTypes';

export const getTextInput = (value: CellValue, isDisabled: boolean, handleChange?: EventListener) => {
  const textInput = document.createElement('input');

  textInput.type = 'text';
  textInput.value = value.toString();
  textInput.disabled = isDisabled;
  textInput.oninput = handleChange;

  textInput.setAttribute('style', 'width: 45px; text-align: center;');

  return textInput;
};

export const getGridAnchor = (anchorTagInfo: AnchorTagInfo) => {
  const anchor = document.createElement('a');

  anchor.href = 'javascript:;';
  anchor.classList.add('underline');
  anchor.innerText = anchorTagInfo.anchorText;
  anchor.onclick = anchorTagInfo.handleClick;

  return anchor;
};

export const getGridTextSpan = (text = '') => {
  const textSpan = document.createElement('span');
  textSpan.innerText = text;
  return textSpan;
};

export const getGridAnchorGroup = (anchorTagInfos: AnchorTagInfo[]) => {
  const div = document.createElement('div');

  anchorTagInfos.forEach((anchorInfo, idx, anchorTagInfos) => {
    const { anchorText, handleClick } = anchorInfo;
    const anchor = getGridAnchor({ anchorText });

    anchor.onclick = handleClick;
    div.appendChild(anchor);

    if (anchorTagInfos[idx + 1]) div.append(' / ');
  });

  return div;
};

export const getGridTextSpanAndAnchor = (leftText = '-', rightText = '-', anchorHandleClick?: EventListener) => {
  const div = document.createElement('div');
  const leftSpan = getGridTextSpan(leftText);
  const rightAnchor = getGridAnchor({ anchorText: rightText, handleClick: anchorHandleClick });
  const separator = getGridTextSpan(' / ');

  const elementGroup = [leftSpan, separator, rightAnchor];
  elementGroup.forEach(element => div.appendChild(element));

  return div;
};

export const getRadioContainer = (id: string) => {
  const radioContainer = document.createElement('div');

  radioContainer.id = id;
  radioContainer.classList.add('tui-grid-cell-content');

  return radioContainer;
};

export const getRadio = (id: string, name: string, isChecked: boolean, handleChange?: EventListener) => {
  const radio = document.createElement('input');

  radio.type = 'radio';
  radio.id = id;
  radio.name = name;
  radio.checked = isChecked;
  radio.onchange = handleChange;

  return radio;
};

export const getLabel = (id: string, innerText: string) => {
  const label = document.createElement('label');

  label.innerText = innerText;
  label.htmlFor = id;
  label.setAttribute('style', 'margin:0 0 0 2px; cursor: pointer;');

  return label;
};

export const appendChildren = (parent: HTMLElement, children: HTMLElement[]) => {
  children.forEach((child: HTMLElement) => parent.append(child));
};
