export default Object.freeze({
  // accumulation
  TITLE_MANUAL: '적립금 수동 지급/차감',
  TITLE_HISTORY: '적립금 지급/차감 현황',
  DETAIL_ONE: '적립금 적립/차감 조회는 전일 23시 59분 59초 기준 입니다.',
  DETAIL_TWO: '쇼핑몰 별로 설정된 단위 관계없이 숫자 합산으로 표기 됩니다.',
  DETAIL_THREE: '운영자가 지급한 적립금은 적립 항목 합산 처리 됩니다.',
  DETAIL_FOUR: '탈퇴회원의 적립금은 차감 항목 합산 처리 됩니다.',
  DETAIL_FIVE: '조회 시점에 따라 합산된 적립/잔액 값이 일부 상이할 수 있습니다.',
  SEARCH_MALL_TITLE: '쇼핑몰',
  PAYMENT_DEDUCT: '지급/차감',
  PAYMENT: '지급',
  DEDUCT: '차감',
  PAYMENT_TYPE: '지급유형',
  IMMEDIATELY_PAYMENT: '즉시지급',
  RESERVATION_PAYMENT: '예약지급',
  PAYMENT_DEDUCT_NO: '지급/차감번호',
  PAYMENT_DEDUCT_REASON: '지급/차감사유',
  CONDITION_REGISTER: '지급조건 등록일',
  CONDITION_UPDATE: '지급조건 수정일',
  PAYMENT_REQUEST_DATETIME: '지급 예약일시',
  PERIOD_MAX_LIMIT: '조회기간은 최대 1년까지 가능합니다.',

  // 적립금 수동 지급/차감 grid
  REGISTER_PAYMENT_DEDUCT: '지급/차감 등록',
  NO: '지급/차감번호',
  SORTATION: '구분',
  TYPE: '지급유형',
  STATUS: '지급/차감상태',
  REASON: '사유',
  RESERVATION_DATE: '예약일',
  CURRENT_SITUATION: '지급/차감현황',
  REGISTRAR: '등록자',
  REGISTER: '등록일',
  LATEST_UPDATER: '최종 수정자',
  LATEST_UPDATE: '최종 수정일',
  STATUS_WAITING: '지급대기',
  STATUS_COMPLETED: '지급완료',
  STATUS_CANCELED: '지급취소',
  STATUS_PROCESSING: '지급처리중',
  DELETED_ADMIN: '삭제된 운영자',
  SEARCH_DATE_MONTH_SELECT: '월별',
  SEARCH_DATE_PERIOD_SELECT: '일별',
  CONFIRM_CANCEL_ACCUMULATION: '적립금 지급을 취소하시겠습니까?\n취소 후에는 상태 변경이 불가능합니다.',
  GRID_TOOLTIP_MESSAGE_1: '지급/차감 중 예기치 못한 오류로 인해 지급/차감이 중단되었습니다.',
  GRID_TOOLTIP_MESSAGE_2:
    '이에 따라 일부 지급/차감이 정상적으로 진행되지 않았을 수 있으며, 내역 확인 및 추가 지급/차감 처리가 필요할 수 있습니다.',

  // 적립금 지급/차감 현황 grid
  INQUIRY_METHOD: '조회방식',
  INQUIRY_METHOD_TOOLTIP: '· 하단 적립금 현황 상세의 조회방식을 설정하실 수 있습니다.',
  INQUIRY_DAILY: '일자별',
  INQUIRY_CASE: '지급/차감 건별',
  ACCUMULATION_TOTAL: '적립금 합계',
  DAILY_ACCUMULATION_TOTAL: '일별 적립금 합계',
  ALL_ACCUMULATION_TOTAL: '전체 적립금 합계',
  PERIOD_ACCUMULATION_TOTAL: '조회기간 적립금 합계',
  BALANCE: '잔액',
  ALL_MEMBERSHIP_ACCUMULATION: '회원보유 적립금 (A) (A=B-C)',
  ALL_PAYMENT: '지급금액(B)',
  ALL_DEDUCT: '차감금액(C)',
  PAYMENT_AMOUNT: '지급금액',
  DEDUCT_AMOUNT: '차감금액',
  TOTAL_STATUS: '적립금 현황 종합',
  DETAIL_STATUS: '적립금 현황 상세',
  BY_CASE: '(건별)',
  BY_DAILY: '(일별)',
  PAYMENT_DEDUCT_DATE: '지급/차감일',
  ID: '아이디',
  MEMBER_NO: '회원번호',
  VALID_PERIOD: '유효기간',

  REGISTER_ADMIN_NAME: '변경자',
  DATE: '날짜',
  POP_TITLE: '회원정보',
  THE_AMOUNT: '적립금 적립',
  D_AMOUNT: '적립금 차감',
  THE_POP_TITLE: '일별 적립금 조회 상세',
  MEMBER_GRADE_NAME: '회원등급',
  MEMBER_GROUP_NAME: '회원그룹',
  MEMBER_NAME: '회원명',
  MEMBER_ID: '회원아이디',
  ORDER_NO: '주문번호',
  ORDER_YMDT: '주문일자',
  PAYMENT_ACCUMULATION: '적립금지급',
  REQUEST_NO: '적립금번호',
  PAYMENT_YMDT: '지급일자',
  ADD_REGISTER_YMDT: '적립일자',
  SUB_REGISGER_YMDT: '차감일자',
  WITHDRAWAL_MEMBER: '탈퇴회원',

  DAILY_STATUS_TITLE: '일별 적립금 지급/차감 현황',
  STATUS_TITLE: '적립금 지급/차감 현황',
  HISTORIES: '적립금 내역',
  WON: '원',
  SUCCESS_STATUS: '성공여부',
  FAILURE: '실패',
  SUCCESS: '성공',

  STATUS_REASON: '{0}사유',
  STATUS_DATE: '{0}일시',
  STATUS_COUNT_TITLE: '{0}현황',
  STATUS_COUNT: '총 {total}건 (성공 {success} 건, 실패 {fail} 건)',
  ALL_AMOUNT: '총 {0} 적립금',
  ACCU_AMOUNT: '{0} 적립금',
  ALL_PAYMENT_AMOUNT: '총 지급 적립금',
  ALL_DEDUCT_AMOUNT: '총 차감 적립금',
  STATUS_TOOLTIP:
    '· 지급/차감 내역 전체 건수 확인이 가능하며, 지급/차감에 성공한 건수과 실패한 건수를 별도로 확인할 수 있습니다\n· 건수를 클릭할 경우, 해당하는 내역만 하단에 출력하여 조회 가능합니다.',
});
