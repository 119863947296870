



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { throwWindowPopup } from '@/helpers/popup';

@Component({
  components: {},
})
export default class OrderLastInfo extends Vue {
  @Prop({ default: () => [] }) public orderInfo;
  @Prop({ default: true })
  private showDetailPopup: boolean;

  public onClickLastDeliveryAmtPopup(): void {
    throwWindowPopup('DeliveryLastAmtDetail', { orderNo: this.$route.query.orderNo, orderType: 'last' }, 'md');
  }
  public onClickLastDiscountAmt(): void {
    throwWindowPopup(`OrderLastDiscount`, { orderNo: this.$route.query.orderNo, orderType: 'last' }, 'md');
  }
}
