export default Object.freeze({
  BUSINESS_INFO: '기본정보',
  PARTNER_NAME: '기존 파트너명',
  REPRESENT_EMAIL: '대표 e메일',
  NEW_PARTNER_NAME: '신규 파트너명',

  ERR_NOT_CHECK_EXISTS: '파트너명을 중복체크한 뒤에 다시 등록해주세요.',
  ERR_EMAIL_NULL: 'e메일 주소를 입력해주세요.',

  REGISTER_MAG: '정상적으로 파트너가 등록되었습니다.',
  CANCEL_CONFIRM_MSG: '파트너 등록을 취소하시겠습니까?',
});
