















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  private mounted() {
    console.log('Footer mounted');
  }
}
