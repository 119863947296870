















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import AmountMixin from '@/components/popup/claim/AmountMixin';

@Component
export default class TdDeliveryDifference extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly afterDeliveryLineThrough!: 'none' | 'line-through';

  @Prop({ required: true })
  private readonly beforeDeliveryLineThrough!: 'none' | 'line-through';

  @Prop({ required: true })
  private readonly deliveryAmtModified;

  @Prop({ required: true })
  private readonly showExchangeShippingInfo!: boolean;
}
