import { GridProp } from '@/types';
import { i18n } from '@/main';
import { GetSkinBannersContents, GetSkinBannersGroups, BannerGroupType, GetSkinsMallsMallNo } from 'ncp-api-supporter';
import moment from 'moment';

export enum SEARCH_DATE_TYPE {
  REGISTER = 'REGISTER',
  UPDATE = 'UPDATE',
}

export enum SEARCH_TYPE {
  ALL = 'ALL',
  SKIN_CODE = 'SKIN_CODE',
  GROUP_NAME = 'GROUP_NAME',
}
export enum PLATFORM_TYPE {
  PC = 'PC',
  MOBILE = 'MOBILE_WEB',
  MOBILE_APP = 'MOBILE_APP',
}
export enum DISPLAY_PERIOD_TYPE {
  REGULAR = 'REGULAR',
  PERIOD = 'PERIOD',
}
export enum SIZE_TYPE {
  PERCENT = 'PERCENT',
  PIXEL = 'PIXEL',
}
export enum UNIT {
  PERCENT = '%',
  PIXEL = 'px',
}
export enum YN {
  Y = 'Y',
  N = 'N',
}
export enum LINK_TARGET {
  SELF = 'SELF',
  NEW = 'NEW',
}
export enum SLIDE_TYPE {
  SLIDE = 'SLIDE',
  FADE = 'FADE',
}
export enum SLIDE_SPEED {
  FAST = 'FAST',
  NORMAL = 'NORMAL',
  SLOW = 'SLOW',
}
export enum SLIDE_SPEED_NUM {
  FAST = 300,
  NORMAL = 1300,
  SLOW = 3300,
}
export enum SLIDE_NAVI_TYPE {
  VISIBLE = 'VISIBLE',
  INVISIBLE = 'INVISIBLE',
  UPLOAD = 'UPLOAD',
}
export enum COLOR_DEFAULT {
  FFF = '#ffffff',
}
export enum BANNER_GROUP_TYPE {
  LOGO = 'LOGO',
  SLIDE = 'SLIDE',
  NORMAL = 'NORMAL',
}
export enum DISPLAY_TYPE {
  VISIBLE = 'VISIBLE',
  INVISIBLE = 'INVISIBLE',
  WAIT = 'WAIT',
  DONE = 'DONE',
}
export enum MODE {
  ADD = 'add',
  MODIFY = 'modify',
}
export enum BUTTON_SIZE_TYPE {
  SIZE_ONE = 'SIZE_ONE',
  SIZE_TWO = 'SIZE_TWO',
  SIZE_THREE = 'SIZE_THREE',
  SIZE_FOUR = 'SIZE_FOUR',
  SIZE_FIVE = 'SIZE_FIVE',
  SIZE_SIX = 'SIZE_SIX',
}
export enum BUTTON_BORDER_TYPE {
  CIRCLE = 'CIRCLE',
  ROUND_SQUARE = 'ROUND_SQUARE',
  SQUARE = 'SQUARE',
}
export interface DesignSkin extends GetSkinsMallsMallNo {
  skinNameFormatter?: string;
}
export interface GroupNoData extends GetSkinBannersGroups {
  groupName?: string;
}

export const getBannerListGridOption = (): GridProp<GetSkinBannersContents> => {
  return {
    columns: [
      {
        header: 'MODIFY',
        name: 'modifyBtnCell',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          return `<a href="/pro/appearance/custom/banner/modifyForm?bannerNo=${cell.row.bannerNo}&mallNo=${
            cell.row.mallNo
          }">${i18n.t('MODIFY')}</a>`;
        },
      },
      {
        header: 'IMAGE',
        name: 'bannerImageUrl',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          let text = i18n.t('BANNER.BANNER.SELF');
          if (cell.row.openLocationType === LINK_TARGET.NEW) {
            text = i18n.t('BANNER.BANNER.BLANK');
          }
          return `<div class="banner-img-wrap">
                    <a href="#none" class="btnBn-img">
                        <img src="${cell.value}" alt="" data-no="${cell.row.bannerNo}">
                    </a>
                    <div class="banner-info-list">
                        <a href="#none" class="btnBn-close"></a>
                        <span>${i18n.t('BANNER.BANNER.BANNER_TITLE')}: ${cell.row.bannerTitle}</span>
                        <span>${i18n.t('BANNER.BANNER.LINK')}:&nbsp;${cell.row.bannerImageUrl}</span>
                        <span>${i18n.t('BANNER.BANNER.TARGET')}: ${text}</span>
                    </div>
                </div>`;
        },
      },
      {
        header: 'MALL',
        name: 'mallName',
        align: 'center',
        minWidth: 60,
      },
      {
        header: 'BANNER.BANNER.PLATFORM_TYPE',
        name: 'platformType',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          if (cell.value === PLATFORM_TYPE.PC) {
            return String(i18n.t('BANNER.BANNER.PC_WEB'));
          } else if (cell.value === PLATFORM_TYPE.MOBILE) {
            return String(i18n.t('BANNER.BANNER.MOBILE_WEB'));
          } else if (cell.value === PLATFORM_TYPE.MOBILE_APP) {
            return String(i18n.t('BANNER.BANNER.MOBILE_APP'));
          }
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'BANNER.BANNER.DESIGN_SKIN',
        name: 'skinName',
        align: 'center',
        minWidth: 60,
      },
      {
        header: 'BANNER.BANNER.BANNER_GROUPS',
        name: 'bannerGroupType',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          let text = i18n.t('BANNER.BANNER.LOGO');
          if (cell.value === BANNER_GROUP_TYPE.SLIDE) {
            text = i18n.t('BANNER.BANNER.SLIDE');
          } else if (cell.value === BANNER_GROUP_TYPE.NORMAL) {
            text = i18n.t('BANNER.BANNER.NORMAL');
          }
          return `<div class="text-close">${text}</div>
            <div class="banner-group-name">${cell.row.bannerGroupName}</div>`;
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'BANNER.BANNER.DISPLAY_STATUS',
        name: 'bannerDisplayType',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          let selectBox = '';
          let text = '';
          if (cell.value === DISPLAY_TYPE.VISIBLE) {
            selectBox = `<select data-arrayindex="${
              cell.row.arrayIndex
            }" class="display-select" name="" style="width: 90px;margin-bottom:6px;"> <option value="VISIBLE" selected="selected">${i18n.t(
              'BANNER.BANNER.VISIBLE',
            )}</option><option value="INVISIBLE">${i18n.t('BANNER.BANNER.INVISIBLE')}</option></select>`;
          } else if (cell.value === DISPLAY_TYPE.INVISIBLE) {
            selectBox = `<select data-arrayindex="${
              cell.row.arrayIndex
            }" class="display-select" name="" style="width: 90px;margin-bottom:6px;"> <option value="VISIBLE">${i18n.t(
              'BANNER.BANNER.VISIBLE',
            )}</option><option value="INVISIBLE" selected="selected">${i18n.t(
              'BANNER.BANNER.INVISIBLE',
            )}</option></select>`;
          } else if (cell.value === DISPLAY_TYPE.WAIT) {
            selectBox = `<select data-arrayindex="${
              cell.row.arrayIndex
            }" class="display-select" disabled="disabled" name="" style="width: 90px;margin-bottom:6px;">
            <option value="WAIT" selected="selected">${i18n.t('BANNER.BANNER.WAIT')}</option></select>`;
          } else if (cell.value === DISPLAY_TYPE.DONE) {
            selectBox = `<select data-arrayindex="${
              cell.row.arrayIndex
            }" class="display-select" disabled="disabled" name="" style="width: 90px;margin-bottom:6px;">
            <option value="DONE" selected="selected">${i18n.t('BANNER.BANNER.OVER')}</option></select>`;
          }
          if (cell.row.displayValue.displayPeriodType === DISPLAY_PERIOD_TYPE.REGULAR) {
            text = `<br>${i18n.t('BANNER.BANNER.ALWAYS')}`;
          } else {
            text = `<br>${cell.row.displayValue.startDateTime} ~ <br>${cell.row.displayValue.endDateTime}`;
          }
          return selectBox + text;
        },
      },
      {
        header: 'BANNER.BANNER.LOGIN_REGISTER',
        name: 'registerDateTime',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          const registerDateTime = `${moment(String(cell.value)).format('YYYY-MM-DD')}`;
          const updateDateTime = `<br>${moment(cell.row.updateDateTime).format('YYYY-MM-DD')}`;
          if (cell.row.updateDateTime) {
            return registerDateTime + updateDateTime;
          } else {
            return registerDateTime;
          }
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
    ],
    options: {
      rowHeaders: ['checkbox', 'rowNum'],
      keyColumnName: 'bannerNo',
      rowHeight: 170,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};
export const containerOptions = {
  hasExcelDownloadButton: false,
  hasSettingButton: false,
};
export const getBannerSelectType = () => {
  return {
    bannerSlideTimeData: [
      { value: 1, name: 'BANNER.BANNER.S1' },
      { value: 2, name: 'BANNER.BANNER.S2' },
      { value: 3, name: 'BANNER.BANNER.S3' },
      { value: 4, name: 'BANNER.BANNER.S4' },
      { value: 5, name: 'BANNER.BANNER.S5' },
      { value: 6, name: 'BANNER.BANNER.S6' },
      { value: 7, name: 'BANNER.BANNER.S7' },
      { value: 8, name: 'BANNER.BANNER.S8' },
      { value: 9, name: 'BANNER.BANNER.S9' },
      { value: 10, name: 'BANNER.BANNER.S10' },
      { value: 0, name: 'BANNER.BANNER.HAND_OPERATE' },
    ],
    bannerSlideSpeedData: [
      { value: SLIDE_SPEED.FAST, name: 'BANNER.BANNER.FAST_SPEED' },
      { value: SLIDE_SPEED.NORMAL, name: 'BANNER.BANNER.NORMAL_SPEED' },
      { value: SLIDE_SPEED.SLOW, name: 'BANNER.BANNER.LOW_SPEED' },
    ],
    bannerSizeUnitData: [
      { value: SIZE_TYPE.PIXEL, name: 'pixel' },
      { value: SIZE_TYPE.PERCENT, name: '%' },
    ],
    linkTargetData: [
      { value: LINK_TARGET.SELF, name: 'BANNER.BANNER.SELF' },
      { value: LINK_TARGET.NEW, name: 'BANNER.BANNER.BLANK' },
    ],
    periodTypeData: [
      { value: DISPLAY_PERIOD_TYPE.REGULAR, name: 'BANNER.BANNER.ALWAYS' },
      { value: DISPLAY_PERIOD_TYPE.PERIOD, name: 'BANNER.BANNER.PERIOD' },
    ],
    visibleYnData: [
      { value: true, name: 'BANNER.BANNER.VISIBLE' },
      { value: false, name: 'BANNER.BANNER.INVISIBLE' },
    ],
    searchTypeData: [
      { value: SEARCH_TYPE.ALL, name: 'BANNER.BANNER.ALL' },
      { value: SEARCH_TYPE.SKIN_CODE, name: 'BANNER.BANNER.SKIN_CODE' },
      { value: SEARCH_TYPE.GROUP_NAME, name: 'BANNER.BANNER.GROUP_NAME' },
    ],
    dateTypeData: [
      { value: SEARCH_DATE_TYPE.REGISTER, name: 'BANNER.BANNER.REGISTER' },
      { value: SEARCH_DATE_TYPE.UPDATE, name: 'BANNER.BANNER.UPDATE' },
    ],
    designSkinDefault: [
      {
        mallNo: 0,
        isWorkSkin: false,
        isLiveSkin: false,
        thumbnail: '',
        platform: '',
        skinNo: 0,
        skinName: '',
        skinCode: '',
        skinNameFormatter: 'BANNER.BANNER.PLEASE_SKIN',
        authorType: '',
        filename: '',
        updateDateTime: '',
        adminType: 'SERVICE',
        gitRepository: '',
        domainUrl: '',
        assetFileName: '',
        isZipSkin: true,
      },
    ],
    groupNoDataDefault: [
      {
        bannerGroupNo: 0,
        bannerGroupName: '',
        bannerGroupType: 'LOGO' as BannerGroupType,
        groupName: 'BANNER.BANNER.PLEASE_BANNER_GROUP',
      },
    ],
  };
};
export const getBannerRadioGroup = (name?: string) => {
  return {
    platformTypeOptions: {
      name: 'platformType',
      data: [
        {
          id: 'platform_pc',
          value: PLATFORM_TYPE.PC,
          display: 'PC_WEB',
        },
        {
          id: 'platform_mobile',
          value: PLATFORM_TYPE.MOBILE,
          display: 'MOBILE_WEB',
        },
      ],
    },
    ynOptions: {
      name: name,
      data: [
        {
          id: name + '_Y',
          value: true,
          display: 'BANNER.BANNER.VISIBLE',
        },
        {
          id: name + '_N',
          value: false,
          display: 'BANNER.BANNER.INVISIBLE',
        },
      ],
    },
    periodTypeOptions: {
      name: name,
      data: [
        {
          id: name + '_always',
          value: DISPLAY_PERIOD_TYPE.REGULAR,
          display: 'BANNER.BANNER.ALWAYS',
        },
        {
          id: name + '_period',
          value: DISPLAY_PERIOD_TYPE.PERIOD,
          display: 'BANNER.BANNER.PERIOD',
        },
      ],
    },
    slideTypeOptions: {
      name: 'slideType',
      data: [
        {
          id: 'slideType_slide',
          value: SLIDE_TYPE.SLIDE,
          display: 'BANNER.BANNER.SLIDE_CSS',
        },
        {
          id: 'slideType_fade',
          value: SLIDE_TYPE.FADE,
          display: 'BANNER.BANNER.FADE_CSS',
        },
      ],
    },
    slideNaviTypeOptions: {
      name: 'slideNaviType',
      data: [
        {
          id: 'slideNaviType_invisible',
          value: SLIDE_NAVI_TYPE.VISIBLE,
          display: 'BANNER.BANNER.VISIBLE',
        },
        {
          id: 'slideNaviType_visible',
          value: SLIDE_NAVI_TYPE.INVISIBLE,
          display: 'BANNER.BANNER.INVISIBLE',
        },
        {
          id: 'slideNaviType_upload',
          value: SLIDE_NAVI_TYPE.UPLOAD,
          display: 'BANNER.BANNER.DIRECT_REGISTER',
        },
      ],
    },
    displayStatusOptions: {
      name: 'displayStatus',
      data: [
        {
          id: 'displayStatus_all',
          value: '',
          display: 'ALL',
        },
        {
          id: 'displayStatus_Y',
          value: YN.Y,
          display: 'BANNER.BANNER.VISIBLE',
        },
        {
          id: 'displayStatus_N',
          value: YN.N,
          display: 'BANNER.BANNER.INVISIBLE',
        },
      ],
    },
    platformTypeAllOptions: {
      name: 'platformType',
      data: [
        {
          id: 'platform_all',
          value: '',
          display: 'ALL',
        },
        {
          id: 'platform_pc',
          value: PLATFORM_TYPE.PC,
          display: 'BANNER.BANNER.PC_MALL',
        },
        {
          id: 'platform_mobile',
          value: PLATFORM_TYPE.MOBILE,
          display: 'BANNER.BANNER.MOBILE_MALL',
        },
      ],
    },
  };
};
