










import { Vue, Component, Prop, Model, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { OptionData } from '@/helpers/type';
import { Brand, GetBrandsRequest } from 'ncp-api-supporter';
import { mall } from '@/utils/mall';
import { InputNumber } from '@/types';
@Component({
  components: { SelectBox },
})
export default class BrandSelect extends Vue {
  @Model('change') readonly value!: number | string;
  @Prop({ required: true }) private readonly mallNo!: string;
  @Prop({ default: '150px' }) private width!: string;
  private defaultBrandOption: OptionData<number | ''> = {
    name: this.$t('PRODUCT.SEARCH.BRAND') as string,
    value: '',
  };
  private brandOptions: OptionData<number | ''>[] = [this.defaultBrandOption];
  private brandNo: InputNumber = '';
  private brandOptionsByMallNo: Map<string, OptionData<number | ''>[]> = new Map();
  @Watch('brandNo')
  private onChangeBrandNo() {
    this.$emit('change', this.brandNo);
  }

  @Watch('mallNo')
  private onChangeMall(): void {
    this.brandNo = '';
    if (this.mallNo === '') {
      this.brandOptions = [this.defaultBrandOption];
      return;
    }
    this.fetchBrandOptions();
  }

  created() {
    this.initBrandOptions();
  }

  private initBrandOptions(): void {
    if ((!mall.isOnlyOneMall && this.mallNo === '') || isNaN(Number(this.mallNo))) return;
    this.fetchBrandOptions();
  }

  private checkSelectedMall() {
    if (this.mallNo) return;
    this.$emit('fail:no-mall-number');
    alert(this.$t('PRODUCT.PRODUCT.SELECT_MALL'));
  }

  private async fetchBrandOptions(): Promise<void> {
    if (!this.mallNo) return;

    if (this.hasBrandOption()) {
      this.brandOptions = this.brandOptionsByMallNo.get(this.mallNo);
      return;
    }

    const mallNo = Number(this.mallNo);
    const brandRequest: GetBrandsRequest = { params: { mallNo } };
    try {
      const brandResponse = await this.$api.getBrands(brandRequest);
      const brands: Brand[] = brandResponse.data;
      this.brandOptions = this.brandOptions.concat(this.generateSortedBrandOptions(brands));
      this.brandOptionsByMallNo.set(this.mallNo, this.brandOptions);
    } catch (e) {
      console.error(e);
    }
  }

  private hasBrandOption(): boolean {
    return this.brandOptionsByMallNo.has(this.mallNo);
  }

  private generateSortedBrandOptions(brands: Brand[]): OptionData<number | ''>[] {
    return brands
      .map(({ brandNo, brandName }) => ({ value: brandNo, name: brandName }))
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  }
}
