
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { range1ToNI18n } from '@/utils/common';

@Component
export default class KakaoTemplatePrecaution extends Vue {
  @Prop() private onNegativeClick!: () => void;

  private noticeList = range1ToNI18n(4, 'MEMBER.KAKAO.TEMPLATE_PRECAUTIONS_');
}
