



















































import { Component } from 'vue-property-decorator';
import WindowPopupMain from '@/views/popups/Main.vue';
import DeliveryTable from '@/views/popups/product/judgement/view/DeliveryTable.vue';
import ImageTable from '@/views/popups/product/judgement/view/ImageTable.vue';
import SaleTable from '@/views/popups/product/judgement/view/SaleTable.vue';
import BasicTable from '@/views/popups/product/judgement/view/BasicTable.vue';
import { GetInspectionsView, Reason } from 'ncp-api-supporter/dist/types/modules/product/product';
import { InspectionProductInfo, OriginProductInfo } from 'ncp-api-supporter';
import EtcTable from '@/views/popups/product/judgement/view/EtcTable.vue';
import { APPLY_STATUS } from '@/const/common';

@Component({
  components: { BasicTable, SaleTable, ImageTable, DeliveryTable, EtcTable },
})
export default class ProductJudgement extends WindowPopupMain {
  private inspectionView: GetInspectionsView | null = null;
  private originProductInfo: OriginProductInfo | null = null; //승인대기: 등록정보, 수정 후 승인대기 : 변경전 등록정보
  private inspectionProductInfo: InspectionProductInfo | null = null; //수정 후 승인대기 : 변경후 등록정보
  private isReject = false;

  private basicReasons: Reason[] = [];
  private saleReasons: Reason[] = [];
  private imageReasons: Reason[] = [];
  private deleveryReasons: Reason[] = [];
  private etcReasons: Reason[] = [];
  private rejectionReasons: Reason[] = [
    ...this.basicReasons,
    ...this.saleReasons,
    ...this.imageReasons,
    ...this.deleveryReasons,
    ...this.etcReasons,
  ];

  private setReasons(reasons: Reason[]) {
    reasons.forEach(reason => {
      switch (reason.section) {
        case 'BASIC_INFO':
          this.basicReasons = reasons;
          break;
        case 'SALE_INFO':
          this.saleReasons = reasons;
          break;
        case 'IMAGE_INFO':
          this.imageReasons = reasons;
          break;
        case 'DELIVERY_INFO':
          this.deleveryReasons = reasons;
          break;
        case 'ETC_INFO':
          this.etcReasons = reasons;
          break;
      }
    });
    this.rejectionReasons = [
      ...this.basicReasons,
      ...this.saleReasons,
      ...this.imageReasons,
      ...this.deleveryReasons,
      ...this.etcReasons,
    ];
    this.isReject = this.rejectionReasons.some(rejection => rejection.items.length > 0);
    this.rejectionReasons = this.rejectionReasons.filter(rejection => rejection.items.length > 0);
  }

  private approve(isApproval: boolean): void {
    if (!this.originProductInfo) return;
    const params = { mallProductNo: this.originProductInfo.mallProductNo.toString() };
    const data = {
      isConfirm: isApproval,
      reasons: this.rejectionReasons,
    };

    this.$api
      .putInspectionsMallProductNo({ params, data })
      .then(() => {
        if (isApproval) {
          alert(this.$t('PRODUCT.COMMON.APPROVE_SUCCESS'));
        } else {
          alert(this.$t('PRODUCT.COMMON.REJECT_SUCCESS'));
        }

        window.close();
      })
      .catch(() => {
        if (isApproval) {
          alert(this.$t('PRODUCT.COMMON.APPROVE_FAIL'));
        } else {
          alert(this.$t('PRODUCT.COMMON.REJECT_FAIL'));
        }
      });
  }

  //승인대기
  get isApprovalReady(): boolean {
    return this.inspectionView?.inspectionStatus === APPLY_STATUS.APPROVAL_READY;
  }

  //수정후 승인대기
  get isAfterApprovalReady(): boolean {
    if (!this.inspectionView?.modifiedParam) return false;
    const isExposureItems =
      this.inspectionView.modifiedParam.length > 0 &&
      this.inspectionView.inspectionStatus === APPLY_STATUS.AFTER_APPROVAL_READY;
    return isExposureItems;
  }

  private async fetchInspectionData() {
    const { data } = await this.$api.getInspectionsView({
      params: { mallProductNo: this.$route.params.mallProductNo },
    });
    this.setInspectionData(data);
  }
  private setInspectionData(data: GetInspectionsView) {
    const { inspectionProductInfo, originProductInfo } = data;
    this.inspectionProductInfo = inspectionProductInfo;
    this.originProductInfo = originProductInfo;
    this.inspectionView = data;
  }

  async created() {
    await this.fetchInspectionData(); //상품심사상세조회
  }
}
