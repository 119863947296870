









































































import { Vue, Component, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import Notificator from '@/components/navigation/header/Notificator.vue';
import { namespace } from 'vuex-class';
import {
  GetSmsGodoTokenPopupPointCharge,
  GetSmsGodoTokenPopupPointChargeRequest,
  Mall,
  NCPResponse,
} from 'ncp-api-supporter';
import { MallPointItem } from '@/components/root/parts/SmsPointSummary.vue';
import { throwExternalWindowPopup } from '@/helpers/popup';
import { Admin } from '@/types';

const adminStore = namespace('admin');
const mallStore = namespace('mall');
const pointStore = namespace('smsPoint');

@Component({
  components: { Notificator, NoticeBox },
})
export default class MallNoticePopup extends Vue {
  @mallStore.Getter('getUsedMalls')
  private readonly malls!: Mall[];
  @pointStore.Getter('getMallPointList')
  private readonly mallPoints!: MallPointItem[];
  @pointStore.Action('fetchPoints')
  private readonly fetchPoints: Function;
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;

  @Prop()
  private show;
  @Prop()
  private onShow;

  private mallPointList: MallPointItem[] | null = null;

  get expireIsComing(): boolean {
    if (this.admin.plan === 'BASIC') return false;
    return this.malls.some(({ expirationDueDays }) => expirationDueDays < 0);
  }

  get isProServicePlanType() {
    return this.admin.plan === 'PRO';
  }

  get expirationMalls() {
    return this.malls.filter(({ expirationDueDays }) => expirationDueDays <= 0);
  }

  /**
   * 0.1 ~ 500 point
   */
  get fewMallPointList(): MallPointItem[] {
    if (this.mallPoints === null || this.mallPoints.length <= 0) return [];

    return this.mallPointList.filter(({ point }) => {
      const isMin = Number(point) * 10 >= 1;
      const isMax = Number(point) <= 500;

      return isMin && isMax;
    });
  }

  get issued() {
    return this.expireIsComing || this.fewMallPointList.length > 0;
  }

  async created() {
    if (this.malls.length > 0) {
      this.mallPointList = this.malls.concat().map(({ mallName, mallNo }) => ({ mallName, mallNo, point: '0' }));
    }

    if (this.mallPoints === null || this.mallPoints.length <= 0) await this.fetchPoints();
    this.mapPoint();
  }

  private async openCommercePaymentPopup(type, mallNo) {
    const request = {
      pathParams: { mallNo },
    };
    try {
      //NOTE : https://nhnent.dooray.com/project/pages/3503608109683620414
      const response = await this.$api.getMallsMallNoGodoAuth(request);
      throwExternalWindowPopup(`${process.env.VUE_APP_GODO_API_URL}/order/regular-payment-popup.gd`, 'md', {
        token: response.data.token,
        shopNo: response.data.sno,
        type,
      });
    } catch (err) {
      console.error(err);
    }
  }

  private mapPoint(): void {
    if (this.mallPoints === null || this.mallPoints.length <= 0) return;

    this.mallPointList = this.mallPointList.map(item => {
      const pointData = this.mallPoints.find(({ mallNo }) => item.mallNo === Number(mallNo));
      if (pointData && pointData?.point) {
        return {
          ...item,
          point: pointData.point,
        };
      }
      return item;
    });
  }

  async openChargePopup(mallNo: number): Promise<void> {
    const request: GetSmsGodoTokenPopupPointChargeRequest = {
      params: {
        mallNo,
      },
    };
    try {
      const response: NCPResponse<GetSmsGodoTokenPopupPointCharge> = await this.$api.getSmsGodoTokenPopupPointCharge(
        request,
      );
      throwExternalWindowPopup(response.data.url, 'md', {
        token: response.data.token,
        godosno: response.data.sno,
        type: response.data.type,
      });
    } catch (err) {
      console.error(err);
    }
  }
}
