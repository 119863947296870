import tuiGrid, { RowKey } from '@/types/tui-grid';
// renderers
import {
  renderEntryContractRegistrationText,
  renderEntryContractModificationText,
  renderEntryContractModificationCancelText,
  renderEntryContractRegistrationCancelText,
} from '@/views/contents/partner/modification/grid/renderers/entryContractRendererByContractStatus';
import { PartnerRowData } from '@/views/contents/partner/modification/PartnerModificationTypes';

const replaceEntryContractsActive = (
  grid: tuiGrid,
  rowKey: RowKey,
  latestEntryContractAgreed: boolean,
  entryContractSize: number,
) => {
  const cell = grid.getElement(rowKey, 'latestEntryContractAgreed');
  const prevCell = cell.children[0];
  let nextCell: HTMLElement;

  if (!entryContractSize) {
    nextCell = renderEntryContractRegistrationText(grid, rowKey).getElement();
  } else if (latestEntryContractAgreed) {
    nextCell = renderEntryContractModificationText(grid, rowKey).getElement();
  } else if (entryContractSize >= 2) {
    nextCell = renderEntryContractModificationCancelText(grid, rowKey).getElement();
  } else if (!latestEntryContractAgreed) {
    nextCell = renderEntryContractRegistrationCancelText(grid, rowKey).getElement();
  }

  cell.replaceChild(nextCell, prevCell);
  return;
};

// BY ENTRY CONTRACTS
export const replaceCellByEntryContracts = (grid: tuiGrid, rowKey: RowKey) => {
  const latestEntryContractAgreed = grid.getValue<PartnerRowData, 'latestEntryContractAgreed'>(
    rowKey,
    'latestEntryContractAgreed',
  );
  const entryContractSize = grid.getValue<PartnerRowData, 'entryContractSize'>(rowKey, 'entryContractSize');

  replaceEntryContractsActive(grid, rowKey, latestEntryContractAgreed, entryContractSize);
};
