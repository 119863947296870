































import { Vue, Component } from 'vue-property-decorator';
import {
  getShippingWarehouseGridOption,
  i18nForDeliveryGroupTemplate,
} from '@/const/contents/configuration/shippingCharge';
import Grid from '@/components/common/grid/Main.vue';
import { GridEventProps } from '@/types';
import { PopupClose, throwWindowPopup } from '@/helpers/popup';

@Component({
  components: { Grid },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'ShippingChargeAreaFee') {
      this.resetNoDataMessage = false;
    }
    next();
  },
})
export default class ShippingChargeWarehouse extends Vue {
  private warehouses = [];

  private totalCount = 0;

  private gridProps = getShippingWarehouseGridOption();

  private i18nForDeliveryGroupTemplate = i18nForDeliveryGroupTemplate;

  private resetNoDataMessage = true;

  created() {
    this.getWarehouses();
  }

  private getWarehouses(): Promise<void> {
    return this.$api
      .getWarehouses()
      .then(response => {
        if (response && response.status === 200) {
          this.warehouses = response.data.contents;
          this.totalCount = response.data.totalCount;
        }
      })
      .catch();
  }

  private async deleteWarehousesByWarehouseNo(warehouseNo: number) {
    try {
      const { status } = await this.$api.deleteWarehousesByWarehouseNo({
        params: { warehouseNo: warehouseNo.toString() },
      });

      if (status === 204) {
        alert(this.i18nForDeliveryGroupTemplate('ALERT_DELETED'));
        await this.getWarehouses();
      }
    } catch (e) {
      console.error(e);
      if (e.status === 403) return;
      alert(this.i18nForDeliveryGroupTemplate('ALERT_NOT_DELETED'));
    }
  }

  private deleteWarehouse(warehouseNo: number): void {
    this.deleteWarehousesByWarehouseNo(warehouseNo);
  }

  private openWarehousePopup(warehouseNo: number | null): void {
    const warehouse = this.warehouses.filter(w => w.warehouseNo === warehouseNo)[0];
    const type = warehouse ? 'edit' : 'add';

    throwWindowPopup(
      'WarehouseFormPopup',
      { type, warehouse },
      'lg',
      ({ state }) => state === PopupClose.CONFIRM && this.getWarehouses(),
    );
  }

  private onItemClicked(gridProps: GridEventProps): void {
    switch (gridProps.columnName) {
      case 'name':
        this.openWarehousePopup(gridProps.rowKey as number);
        break;
      case 'delete':
        this.deleteWarehouse(gridProps.rowKey as number);
        break;
    }
  }
}
