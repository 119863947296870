/*
 * 참고 : https://gist.github.com/joduplessis/7b3b4340353760e945f972a69e855d11
 * General utils for managing cookies in Typescript.
 */
import cookies from 'js-cookie';

export function setCookie(name: string, value: string, expireDate?: Date): void {
  let cookie = `${name}=${value}; path=/ `;
  if (expireDate != undefined) {
    cookie += `;expires= ${expireDate.toUTCString()};`;
  }

  document.cookie = cookie;
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts && parts.length !== 2) return null;

  return parts
    .pop()
    .split(';')
    .shift();
}

export function deleteCookie(name: string): void {
  cookies.remove(name);
}
