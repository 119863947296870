






































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';

export interface LookupSettingData {
  title: string;
  dateRange: {
    start: string; // YYYY-MM-DD
    end: string; // YYYY-MM-DD
  };
}

interface FormData {
  dateRange: {
    start: string; // YYYY-MM-DD
    end: string; // YYYY-MM-DD
  };
}

@Component({
  components: {
    CheckboxGroup,
    DateRangePicker,
    ModalHeader,
  },
})
export default class LookupSetting extends Vue {
  @Prop({ required: true }) private readonly data!: LookupSettingData;
  private formData: FormData = {
    dateRange: { ...this.data.dateRange },
  };

  private onClickSubmit() {
    alert('구현필요');
  }
}
