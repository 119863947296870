import { i18n } from '@/main';
import { RefundType } from 'ncp-api-supporter';
import { AdjustedAmounts } from 'ncp-api-supporter/dist/types/modules/claim/claims';

// TODO: supporter에서 임포트 한거는 린트에러 발생함
export interface BankAccount {
  depositorName?: string;
  bank?: string;
  bankName?: string;
  account?: string;
}

export const validateBankAccount = (refundType: RefundType, BankAccount: BankAccount, isVirtualAccount = false) => {
  const isAccountRefundType = refundType === 'ACCOUNT';
  const isAccountEmpty = (isAccountRefundType || isVirtualAccount) && BankAccount.account.trim().length === 0;
  const isDepositerNameEmpty =
    (isAccountRefundType || isVirtualAccount) && BankAccount.depositorName.trim().length === 0;

  if (isAccountEmpty) {
    alert(i18n.t('CLAIM.MESSAGE.NEED_ACCOUNT'));
    return false;
  }

  if (isDepositerNameEmpty) {
    alert(i18n.t('CLAIM.MESSAGE.NEED_DEPOSITOR'));
    return false;
  }

  return true;
};

export const validateAdditionalPayRemitter = (additionalPayRemitter: string, checkValid: boolean) => {
  const invalidAdditionalPayRemitter = additionalPayRemitter.trim().length === 0 && checkValid;

  if (invalidAdditionalPayRemitter) {
    alert(i18n.t('CLAIM.MESSAGE.NEED_PAY_REMITTER'));
    return false;
  }

  return true;
};

export const validateRefundAdjust = (refundAdjustAmt: number, refundAdjustReason: string) => {
  const isRefundAdjustAmtZero = refundAdjustAmt === 0;
  const isRefundAdjustReasonEmpty =
    refundAdjustReason === null || refundAdjustReason === undefined || refundAdjustReason.trim().length === 0;

  if (!isRefundAdjustAmtZero && isRefundAdjustReasonEmpty) {
    alert(i18n.t('CLAIM.MESSAGE.NEED_ADJUST_REASON'));
    return false;
  }

  return true;
};

// 환불조정금액이 환불예상금액보다 큰 경우를 validate
export const validateRefundAdjustAmount = ({
  formRefundAdjustAmt,
  refundAdjustAmt,
  adjustedAmounts,
}: {
  formRefundAdjustAmt: number;
  refundAdjustAmt: number;
  adjustedAmounts: AdjustedAmounts;
}) => {
  const { payAmt } = adjustedAmounts;

  // 조정요청 -> 환불대기 -> 환불처리 스텝에서 연속적으로 조정금액을 입력할 수 있기 때문에 remitAmount 식 필요
  // 근데 프로 기획서에는 환불대기 상태일때 조정금액 입력 내용이 없음. 기획 QA때 확인
  const remitAmount = payAmt + refundAdjustAmt;

  if (formRefundAdjustAmt > remitAmount) {
    alert(i18n.t('금액조정은 환불예상금액을 초과할 수 없습니다.'));
    return false;
  }

  return true;
};
