












































































import { Vue, Component } from 'vue-property-decorator';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  getAdminCheckBoxOption,
  getAdminSelectType,
  getAdminToolTipOption,
} from '@/const/contents/configuration/admin';

@Component({
  components: {
    SearchButton,
    SelectBox,
    CheckboxGroup,
    TextInput,
    ToolTip,
  },
})
export default class Admin extends Vue {
  private selectType = getAdminSelectType();
  private checkBoxOption = getAdminCheckBoxOption();
  private tooltipOption = getAdminToolTipOption();

  private query = {
    searchType: this.selectType.searchType[0].value,
    keyword: '',
    status: this.checkBoxOption.status.data
      .filter(d => d.id !== 'all')
      .map(d => d.value)
      .join(','),
    department: '',
    jobDuty: '',
    notLoginedLongTime: false,
  };

  created() {
    this.getDepartments();
    this.getJobDuties();
  }

  private getDepartments(): void {
    this.$api.getDepartments().then(response => {
      if (response && response.status === 200) {
        response.data
          .sort((d1, d2) => {
            if (d1.departmentName > d2.departmentName) {
              return 1;
            } else if (d1.departmentName < d2.departmentName) {
              return -1;
            }

            return 0;
          })
          .map(department => {
            this.selectType.departmentType.push({
              name: department.departmentName,
              value: department.departmentNo + '',
            });
          });
      }
    });
  }

  private getJobDuties(): void {
    this.$api.getJobDuties().then(response => {
      if (response && response.status === 200) {
        response.data
          .sort((j1, j2) => {
            if (j1.jobDutyName > j2.jobDutyName) {
              return 1;
            } else if (j1.jobDutyName < j2.jobDutyName) {
              return -1;
            }

            return 0;
          })
          .map(jobDuty => {
            this.selectType.jobDutyType.push({
              name: jobDuty.jobDutyName,
              value: jobDuty.jobDutyNo + '',
            });
          });
      }
    });
  }

  private checkValidation(): boolean {
    const notCheckStatus = !this.query.status;
    if (notCheckStatus) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_STATUS_NONE'));
      return false;
    }

    return true;
  }

  private reset(): void {
    this.query = {
      searchType: 'ALL',
      keyword: '',
      status: this.checkBoxOption.status.data
        .filter(d => d.id !== 'all')
        .map(d => d.value)
        .join(','),
      department: '',
      jobDuty: '',
      notLoginedLongTime: false,
    };
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }
}
