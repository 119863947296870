// 상품 리스트 & 대량 상품 등록에서 공통으로 사용하는 복사 관련 로직을 정의합니다.
import { OptColumn } from '@/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { api } from '@/api';
import { Row } from '@/types/tui-grid';
import { DeleteProductRequest } from 'ncp-api-supporter';

export const getDeleteGridColumn = (): OptColumn => {
  return {
    header: 'DELETE',
    name: 'delete',
    align: 'center',
    minWidth: 50,
    formatter: ({ row }) => {
      return row.deletable ? getAnchorHTML(i18n.t('DELETE')) : '-';
    },
  };
};

const deleteConfirm = (deletable: boolean): boolean => {
  if (!deletable) return false;
  return confirm(i18n.t('PRODUCT.DELETE.CONFIRM_DELETE').toString());
};

const deleteProduct = async ({ mallNo, mallProductNos }: { mallNo: number; mallProductNos: number | number[] }) => {
  const request: DeleteProductRequest = {
    data: {
      mallNo,
      mallProductNos, // 단수 허용함
    },
  };
  try {
    await api.deleteProducts(request);
  } catch (error) {
    console.error(error);
  }
};

export const onDeleteProduct = async (row: Row): Promise<void> => {
  if (!deleteConfirm(!!row.deletable)) throw 'UN_CONFIRM';

  const mallNo = Number(row.mallNo);
  const mallProductNo = Number(row.mallProductNo);
  return deleteProduct({ mallNo, mallProductNos: mallProductNo });
};
