import { OptionData } from '@/helpers/type';

/* SELECT */
// 검색어
const keywordInfo: OptionData<string>[] = [
  {
    value: 'ALL',
    name: 'BOARD.INQUIRIES.SEARCHTYPE_ALL',
  },
  {
    value: 'INQUIRY_NO',
    name: 'BOARD.INQUIRIES.SEARCHTYPE_1',
  },
  {
    value: 'TITLE',
    name: 'BOARD.INQUIRIES.SEARCHTYPE_2',
  },
  {
    value: 'ISSUER',
    name: 'BOARD.INQUIRIES.SEARCHTYPE_3',
  },
  {
    value: 'ASSIGNEE',
    name: 'BOARD.INQUIRIES.SEARCHTYPE_4',
  },
];

const getProcessingStatus = {
  name: 'AdminCheckBox',
  hasAll: true,
  noBrackets: true,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    {
      id: 'issued',
      value: 'ISSUED',
      display: 'BOARD.INQUIRIES.COMPCHECKBOX_2',
    },
    {
      id: 'inprogress',
      value: 'IN_PROGRESS',
      display: 'BOARD.INQUIRIES.COMPCHECKBOX_3',
    },
    {
      id: 'answered',
      value: 'ANSWERED',
      display: 'BOARD.INQUIRIES.COMPCHECKBOX_4',
    },
  ],
};

const getTypeAdminStatus = {
  name: 'TypeAdminCheckBox',
  hasAll: false,
  noBrackets: true,
  data: [
    {
      id: 'isUnspecified',
      value: 'true',
      display: 'BOARD.INQUIRIES.COMPCHECKBOX_5',
    },
  ],
};

export const searchFormOptions = {
  getProcessingStatus,
  getTypeAdminStatus,
  keywordInfo,
} as const;
