


















































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import Grid from '@/components/common/grid/Main.vue';
import { getMemberGradeGridProps } from '@/const/contents/member';
import {
  GradeGrid,
  GetMemberGradeInfo,
  NCPResponse,
  PutMemberGradesGradeNoUsedRequest,
  PutMemberGradesRequest,
  PostMemberGradesBenefitsRequest,
} from 'ncp-api-supporter';
import { throwPopup } from '@/helpers/popup';
import { ChangeDisplayOption, ChangeDisplayOrderMsgOption, CheckGridEventProps, GridEventProps } from '@/types';
import { getCurrentMallNo } from '@/utils/mall';
import ChangeDisplayOrder from '@/components/product/changeDisplayOrder/ChangeDisplayOrder.vue';
import { MoveGradesInfo } from 'ncp-api-supporter/dist/types/modules/member/memberGrade';
import { Row } from '@/types/tui-grid';

type GradeGridWrap = GradeGrid & Partial<ChangeDisplayOption>;

@Component({ components: { ChangeDisplayOrder, NoticeBox, Grid } })
export default class MemberGrade extends Vue {
  @Ref()
  private readonly memberGradeGrid!: Grid;

  private noticeList = [
    `${this.$t('MEMBER.GRADE.NOTICE_0')}<br>${this.$t('MEMBER.GRADE.NOTICE_1')}`,
    this.$t('MEMBER.GRADE.NOTICE_2'),
    this.$t('MEMBER.GRADE.NOTICE_3'),
    `${this.$t('MEMBER.GRADE.NOTICE_4')}<br>${this.$t('MEMBER.GRADE.NOTICE_5')}`,
    this.$t('MEMBER.GRADE.NOTICE_6'),
  ];
  private readonly message: ChangeDisplayOrderMsgOption = {
    needOrigin: 'MEMBER.GRADE.NEED_ORIGIN',
    cannotMove: 'MEMBER.GRADE.NOT_CHANGE_ORDER',
  };

  private mallNo = -1;
  private contents: GradeGridWrap[] = [];
  private totalCount = 0;
  private changeableCount = 0;

  private gridProps = getMemberGradeGridProps(this.onGridCellEvent);

  private selected: number[] = [];
  private selectedGrades: (Row | GradeGridWrap)[] = [];
  private changeGrades: MoveGradesInfo[] = [];

  @Watch('$route')
  private onQueryChanged() {
    this.init();
  }

  created() {
    this.init();
  }

  private init(): void {
    this.mallNo = getCurrentMallNo(this);
    this.contents = [];
    this.totalCount = 0;

    this.getMemberGradesGrids();
  }

  private getChangeGrades(originOrder, targetOrder) {
    const selectedGrade = this.contents.find(({ displayOrder }) => displayOrder === originOrder);
    const newIndex = this.contents.find(({ displayOrder }) => displayOrder === targetOrder);

    this.changeGrades.push({
      gradeNo: selectedGrade.no,
      newOrder: newIndex.displayOrder,
      currentOrder: selectedGrade.displayOrder,
    });
  }

  // get memeber grades grids
  private getMemberGradesGrids(): void {
    this.$api
      .getMemberGradesGrids({ params: { mallNo: this.mallNo, pageSize: 100 } })
      .then((response: NCPResponse<{ contents: GradeGrid[]; totalCount: number }>) => {
        if (response && response.status === 200) {
          this.contents = this.sortContents(response.data.contents).map((value, index) => {
            return { ...value, displayOrder: index + 1 };
          });
          this.totalCount = response.data.totalCount;
          const unchangeableCount = this.contents.filter(({ used }) => !used).length;
          this.changeableCount = this.totalCount - unchangeableCount;
          this.$nextTick(() => this.selected.forEach(value => this.memberGradeGrid.checkRow(value)));
        }
      });
  }

  @Watch('changeGrades')
  private putMemberGrades() {
    if (!this.selected.length || this.changeGrades.length !== this.selected.length) return;
    if (this.selectedGrades.some(({ order, used }) => !used || order === 0)) {
      alert(this.$t('MEMBER.GRADE.NOT_CHANGE_ORDER'));
      this.changeGrades = [];
      return;
    }

    const request: PutMemberGradesRequest = {
      data: {
        mallNo: getCurrentMallNo(this),
        grades: this.changeGrades,
      },
    };
    this.$api
      .putMemberGrades(request)
      .then(response => {
        if (response && response.status === 204) {
          this.selected = this.changeGrades.map(({ newOrder }) => newOrder);
          this.selectedGrades = this.selected.map(value => {
            return this.contents.find(({ displayOrder }) => displayOrder === value);
          });
          this.changeGrades = [];
          this.getMemberGradesGrids();
        }
      })
      .catch(() => {
        this.changeGrades = [];
        this.selected = [];
        this.selectedGrades = [];
        this.getMemberGradesGrids();
      });
  }

  // delete member grades
  private deleteMemberGrades(arrGradeNo: number[]): Promise<boolean> {
    const request = {
      params: {
        gradeNos: arrGradeNo.join(','),
        mallNo: this.mallNo,
      },
    };

    return this.$api
      .deleteMemberGrades(request)
      .then(response => {
        return response && response.status === 204;
      })
      .catch(() => false);
  }

  private putMemberGradesGradeNoUsed(gradeNo: number): Promise<boolean> {
    const request: PutMemberGradesGradeNoUsedRequest = {
      pathParams: { gradeNo: gradeNo.toString() },
    };

    return this.$api
      .putMemberGradesGradeNoUsed(request)
      .then(response => {
        return response && response.status === 204;
      })
      .catch(() => {
        return false;
      });
  }

  // get member grade detail
  private getMemberGradesGradeNo(gradeNo: number): Promise<GetMemberGradeInfo> {
    return this.$api.getMemberGradesGradeNo({ pathParams: { gradeNo: gradeNo + '' } }).then(response => {
      if (response && response.status === 200) {
        return response.data;
      }
    });
  }

  // sort contents by order & used
  private sortContents(contents: GradeGridWrap[]): GradeGridWrap[] {
    return contents.sort((c1, c2) => {
      if (!c2.used) {
        return -1;
      }
      if (!c1.used) {
        return 1;
      }

      // 기본
      if (c2.order === 0) {
        return -1;
      }
      if (c1.order === 0) {
        return 1;
      }
    });
  }

  // delete grade before validate
  private deleteGrade(arrGradeNo: number[]): void {
    if (!arrGradeNo || arrGradeNo.length === 0) {
      alert(this.$t('MEMBER.GRADE.ALERT_NOT_SELECT'));
      return;
    }

    const arrTarget = this.contents.filter(c => arrGradeNo.filter(n => c.no === n).length > 0);
    if (arrTarget.filter(t => t.order === 0).length > 0) {
      alert(this.$t('MEMBER.GRADE.ALERT_NOT_DELETE_BASIC'));
      return;
    }

    if (arrTarget.filter(t => t.memberCount > 0).length > 0) {
      alert(this.$t('MEMBER.GRADE.ALERT_NOT_DELETE_EXIST_MEMBER'));
      return;
    }

    if (confirm(this.$t('MEMBER.GRADE.ALERT_DELETE_CONFIRM') as string)) {
      this.deleteMemberGrades(arrGradeNo).then(success => {
        if (success) {
          alert(this.$t('MEMBER.GRADE.ALERT_DELETE_SUCCESS'));
          this.init();
        }
      });
    }
  }

  // open grade modify popup
  private openGradeModifyPopup(grade?: GetMemberGradeInfo, memberCount?: number, order?: number): void {
    throwPopup({
      name: 'ModifyGrade',
      data: { grade: grade, memberCount: memberCount, order: order },
    }).then(result => {
      if (result.data) {
        this.init();
      }
    });
  }

  // add grade
  private addGrade(): void {
    if (this.contents.length < 100) {
      this.openGradeModifyPopup();
    } else {
      alert(this.$t('MEMBER.GRADE.ALERT_GRADE_LENGTH_LIMIT'));
    }
  }

  // delete grades
  private deleteSelectGrade(): void {
    const selectedNos = this.selectedGrades.map(({ no }) => no) as number[];
    this.deleteGrade(selectedNos);
  }

  private async payBenefit(): Promise<void> {
    if (!this.selected || this.selected.length === 0) {
      alert(this.$t('MEMBER.GRADE.ALERT_NOT_SELECT'));
      return;
    }

    if (!confirm(this.$t('MEMBER.GRADE.ALERT_BENEFIT_CONFIRM') as string)) {
      return;
    }

    const targetData = this.contents.filter(
      ({ displayOrder }) => this.selected.filter(value => displayOrder === value).length > 0,
    );

    const request: PostMemberGradesBenefitsRequest = {
      data: {
        mallNo: getCurrentMallNo(this),
        gradeNos: targetData.map(({ no }) => no),
      },
    };
    await this.$api.postMemberGradesBenefits(request);
    alert(this.$t('MEMBER.GRADE.ALERT_BENEFIT_SUCCESS'));
  }

  // grid item select change event
  private onGridCellEvent(key: string, event: Event, props: any): void {
    switch (key) {
      case 'used': {
        const item = this.contents.find(c => c.displayOrder === props.rowKey);

        if (!item) {
          return;
        }

        const target = ((event.target as HTMLElement) as unknown) as { value: string | boolean };

        const changeValue = target.value === 'true' || target.value === true;

        if (!changeValue && item.memberCount > 0) {
          alert(this.$t('MEMBER.GRADE.ALERT_ALREADY_EXIST_GRADE'));
          target.value = true;
          return;
        }

        item.used = target.value === 'true' || target.value === true;

        this.putMemberGradesGradeNoUsed(item.no).then(success => {
          if (success) {
            alert(this.$t('ALERT_SAVED'));
            this.init();
          }
        });
        break;
      }
    }
  }

  // grid item clicked
  private onItemClicked(gridProps: GridEventProps): void {
    const selected = gridProps.instance.getRow(gridProps.rowKey).no as number;

    switch (gridProps.columnName) {
      case 'name': {
        const c = this.contents.filter(c => c.displayOrder === gridProps.rowKey)[0];
        if (c) {
          this.getMemberGradesGradeNo(c.no).then(grade => {
            if (grade) {
              this.openGradeModifyPopup(grade, c.memberCount, c.order);
            }
          });
        }
        break;
      }
      case 'delete':
        this.deleteGrade([selected]);
        break;
    }
  }

  // grid checkbox clicked
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected as number[];
    this.selectedGrades = checkProps.instance.getCheckedRows();
  }
}
