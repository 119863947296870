export default Object.freeze({
  TITLE: 'SMS 수신자 조회',
  TR_1: '요청ID',
  TR_2: 'SMS 유형',
  TR_3: '발송 유형',

  ITEM_TR_1: '수신번호',
  ITEM_TR_2: '발송요청 상태',
  ITEM_TR_3: '수신결과 상태',
  ITEM_TR_4: '단말기수신일시',
  STATUS_1: '발송요청',
  STATUS_2: '발송처리중',
  STATUS_3: '발송성공',
  RET_1: '성공',
  RET_2: '실패',

  ERROR_SELECT_MEMBER_COUNT: '회원을 선택해 주세요.',
  ALERT_SELECT_MEMBER_NONE: '회원을 선택해주세요.',
  ALERT_NON_MALL: '회원 검색 후 진행해야 합니다.',
});
