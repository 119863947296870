import { CampaignsRequirements } from 'ncp-api-supporter/dist/types/modules/marketing/campaign';
import { GetCampaignsRequirementsRequest, NCPResponse } from 'ncp-api-supporter/dist/types';
import { api } from '@/api';
import { GetServicesMe } from 'ncp-api-supporter';

export const initialCampaignsRequirements = {
  zipCode: false,
  mallName: false,
  mallDomain: false,
  hasProduct: false,
  streetAddress: false,
  usesPg: false,
  representativeEmail: false,
  usesSsl: false,
  landLotAddress: false,
  representativePhoneNo: false,
  displayableProduct: false,
  registrationNo: false,
  domain: false,
  address: false,
};

export class CampaignsRequirementsValidation {
  private readonly mallNo: number;
  public loadingData: typeof initialCampaignsRequirements;
  public validationData: typeof initialCampaignsRequirements;
  private loadingDataKeys: string[];
  private campaignsRequirements: CampaignsRequirements;
  private registrationNo: string;

  constructor(mallNo: number) {
    this.mallNo = mallNo;
    this.loadingData = initialCampaignsRequirements;
    this.validationData = initialCampaignsRequirements;
  }

  public async init() {
    this.setLoadingAndValidationData();
    await Promise.all([this.getCampaignsRequirements(), this.getRegistrationNo()]);
    this.validateCampaignsRequirements();
  }

  public isAllValid(): boolean {
    return Object.values(this.validationData).every(isValid => isValid);
  }

  private setLoadingAndValidationData() {
    this.loadingData = { ...initialCampaignsRequirements };
    this.validationData = { ...initialCampaignsRequirements };
    this.loadingDataKeys = Object.keys(this.loadingData);
  }

  private setAllLoadingData(value: boolean) {
    this.loadingDataKeys.forEach(data => (this.loadingData[data] = value));
  }

  private async getCampaignsRequirements() {
    this.setAllLoadingData(true);
    const request: GetCampaignsRequirementsRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    try {
      const { data }: NCPResponse<CampaignsRequirements> = await api.getCampaignsRequirements(request);
      this.campaignsRequirements = data;
    } catch (e) {
      console.error(e);
    }
  }

  private async getRegistrationNo() {
    try {
      const { data }: NCPResponse<GetServicesMe> = await api.getServicesMe();
      this.registrationNo = data.business.registrationNo;
    } catch (e) {
      console.error(e);
    }
  }

  private setLoadingData(key: string, value: boolean) {
    this.loadingData[key] = value;
  }

  private setValidationData(key: string, value: boolean) {
    this.validationData[key] = value;
  }

  private validateRegistrationNo() {
    const regex = /\d{3}-\d{2}-\d{5}/;
    this.setValidationData('registrationNo', !!this.registrationNo && regex.test(this.registrationNo));
    this.setLoadingData('registrationNo', false);
  }

  private validateCampaignsRequirements() {
    Object.keys(this.campaignsRequirements).forEach(campaignsRequirementsKey => {
      this.setValidationData(campaignsRequirementsKey, !!this.campaignsRequirements[campaignsRequirementsKey]);
      this.setLoadingData(campaignsRequirementsKey, false);
    });

    const { mallDomain, usesSsl, zipCode, streetAddress, landLotAddress } = this.validationData;
    this.setValidationData('domain', mallDomain && usesSsl);
    this.setLoadingData('domain', false);
    this.setValidationData('address', zipCode && streetAddress && landLotAddress);
    this.setLoadingData('address', false);
    this.validateRegistrationNo();
  }
}
