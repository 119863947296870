export default Object.freeze({
  TITLE: '휴면회원 리스트',

  DORMANT_DATE: '휴면전환일',

  // keyword select
  ALL: '전체',
  MEMBER_NO: '회원번호',
  MEMBER_ID: '아이디',
  MEMBER_NAME: '이름',
  MEMBER_NAME_2: '회원명',
  MEMBER_NICKNAME: '닉네임',
  MEMBER_EMAIL: '이메일',
  MEMBER_MOBILE: '휴대폰번호',

  JOIN_DATE: '회원가입일',
  LAST_LOGIN_DATE: '최종로그인',

  // tooltip
  TOOLTIP_TITLE: '휴면회원',
  TOOLTIP_MESSAGE_1:
    '개인정보보호법 제39조의6에 따라, 1년 이상 서비스를 이용하지 않은 회원의 정보는 자동으로 휴면상태로 보관되며 이를 휴면회원이라고 합니다.',
  TOOLTIP_MESSAGE_2:
    '분리보관중인 휴면회원에게 메일이나 SMS를 발송하실 수 없으며, 휴면회원 정보는 관련 업무 담당자만 열람하도록 권한을 제한하시기 바랍니다.',
  TOOLTIP_MESSAGE_3:
    '회원이 쇼핑몰에 마지막으로 로그인한지 1년 경과 시 휴면회원으로 전환되며,\n휴면회원의 회원등급은 기본등급으로 초기화됩니다.',
  TOOLTIP_MESSAGE_4: '최종로그인일이 없는 회원의 경우 회원가입일 1년 경과 시 휴면회원으로 전환됩니다.',
});
