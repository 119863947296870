





































































































import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import {
  NCPResponse,
  PostSmsGodoRecipientExcelRequest,
  PostSmsGodoRecipientExcel,
  GetMembersSummaryRequest,
  MembersSummary,
} from 'ncp-api-supporter';
import { getStorageFile } from '@/utils/storage';
import { isValidate } from '@/utils/validate';
import { PopupClose, PopupResult, throwWindowPopup } from '@/helpers/popup';
import { SmsMemberNo } from '@/types/member';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SmsSendMixin from '@/views/contents/member/sms/mixins/SmsSendMixin.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import { getCurrentMallNo } from '@/utils/mall';
import { omit } from 'underscore';

@Component({
  components: { FileInput, RadioGroup },
})
export default class DestinationSelectBox extends SmsSendMixin {
  @PropSync('selectedType')
  private selectedTypeSync!: string;
  @PropSync('allSelectedCount')
  private allCountSync!: number;
  @PropSync('rejectSelectedCount')
  private rejectCountSync!: number;
  @PropSync('selectedMemberNoList')
  private memberNoListSync!: SmsMemberNo[];
  @PropSync('selectedPhoneNoList')
  private phoneNoListSync!: string[];
  @Prop({ default: false })
  private readonly isPopup!: boolean;
  @Ref()
  readonly phoneNoInput: HTMLInputElement;

  private oldSelectedMember = {};
  private query = {};
  private fileRenderType = 'text';

  @Watch('selectedTypeSync')
  private changeMemberCount() {
    if (this.selectedTypeSync === 'all') {
      this.getMemberCount();
    } else {
      this.allCountSync = 0;
      this.rejectCountSync = 0;
      this.memberNoListSync = [];
      this.phoneNoListSync = [];
    }
  }

  private getMemberCount() {
    const memberRequest: GetMembersSummaryRequest = {
      params: {
        mallNo: getCurrentMallNo(this),
        includesSmsAgreed: true,
      },
    };

    this.$api.getMembersSummary(memberRequest).then(({ data }: NCPResponse<MembersSummary>) => {
      this.allCountSync = data.totalCount;
      this.rejectCountSync = data.totalCount - data.smsAgreeCount;
    });
  }

  private openMemberPopup() {
    throwWindowPopup(
      'InquiryMember',
      { oldSelectedMember: this.oldSelectedMember, query: this.query, mallNo: getCurrentMallNo(this) },
      'xlg',
      ({ data, state }: PopupResult) => {
        if (state !== PopupClose.CONFIRM) return;
        this.$emit('update:selectedMemberNoList', data.selectedMember);
        this.oldSelectedMember = data.selectedMember;
        this.query = omit(data.query, 'popupId', 'width');
      },
      null,
      this.query,
    );
  }

  private onTextChanged() {
    const phoneNoRegex = /[^0-9-]+/g;
    this.phoneNoInput.value = this.phoneNoInput.value.replace(phoneNoRegex, '');
    this.directInput = this.phoneNoInput.value;
  }

  private addMemberList() {
    const regex = /(^\d{3}-\d{3,4}-\d{4}$)|(\d{11})/;
    const isPhoneNo = isValidate(regex.test(this.directInput), this.$t('MEMBER.SMS_DISPATCH.WRONG_PHONE_NUMBER'));
    const isDuplicate = this.phoneNoListSync.some(memberNo => memberNo === this.directInput);
    if (!isPhoneNo || isDuplicate) return;

    this.phoneNoListSync.unshift(this.directInput);
    this.allCountSync++;
    this.directInput = '';
  }

  private isSelectedPhoneNo(phoneNo: string): boolean {
    return phoneNo === this.currentPhoneNo;
  }

  private selectPhoneNo(phoneNo: string) {
    this.currentPhoneNo = phoneNo;
  }

  private deleteSelectedNumber() {
    if (this.currentPhoneNo === '') {
      return alert(this.$t('MEMBER.SMS_DISPATCH.MUST_SELECT_PHONE_NO'));
    }
    this.phoneNoListSync = this.phoneNoListSync.filter(phoneNo => phoneNo !== this.currentPhoneNo);
    this.allCountSync = this.phoneNoListSync.length;
    this.currentPhoneNo = '';
  }

  private deleteAllNumber() {
    this.phoneNoListSync = [];
    this.allCountSync = 0;
    this.currentPhoneNo = '';
  }

  private downloadExcel() {
    getStorageFile('godo_sms_recipient_template.xlsx', 'excel-sample');
  }

  private async uploadExcel(file: File): Promise<void> {
    const fileData = new FormData();
    fileData.append('excelFile', file);

    const request: PostSmsGodoRecipientExcelRequest = {
      data: fileData,
      params: { mallNo: getCurrentMallNo(this) },
    };
    try {
      const { data }: NCPResponse<PostSmsGodoRecipientExcel> = await this.$api.postSmsPostRecipientExcel(request);
      if (!data.smsRecipients.length) {
        alert(this.$t('MEMBER.SMS.ALERT_EXCEL'));
        this.fileRenderType = 'hidden';
        return;
      }
      this.fileRenderType = 'text';
      this.phoneNoListSync = data.smsRecipients.map(({ mobileNo }) => {
        return mobileNo;
      });
      this.allCountSync = this.phoneNoListSync.length;
    } catch (e) {
      this.fileRenderType = 'hidden';
    }
  }

  created() {
    this.selectedTypeSync = this.selectDestinationOption.data[1].value.toString();
  }
}
