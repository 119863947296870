

























import { Component, Prop, Ref, PropSync, Vue, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { AdminsServiceMerchandisers, InputNumber } from '@/types';

@Component({
  components: { SelectBox },
})
export default class AdminsInfo extends Vue {
  @PropSync('adminNo')
  private adminNoSync!: InputNumber;

  @Prop()
  private readonly isSelectedMall!: boolean;

  @Prop()
  private readonly mallNo!: InputNumber;

  @Prop({ required: true })
  private readonly disabled: boolean;

  @Ref() private readonly adminRef!: SelectBox;
  public focus(): void {
    this.adminRef.focus();
  }

  private admins: AdminsServiceMerchandisers[] = [];

  @Watch('mallNo')
  private async fetchAdmins(): Promise<void> {
    if (!this.mallNo) return;
    const { data } = await this.$api.getAdminsServiceMerchandisers({ params: { mallNo: this.mallNo.toString() } });
    this.admins = data.map((admin, idx) => ({
      ...admin,
      nameAndId: `${admin.name}(${admin.id ? admin.id : `${admin.name}_${idx + 1}`})`,
    }));
  }

  private changedAdminNo(curr: number, prev: number): void {
    if (!curr) {
      this.$nextTick(() => (this.adminNoSync = prev));
      return;
    }
  }

  private created() {
    this.init();
  }

  private init() {
    this.mallNo > 0 && this.fetchAdmins();
  }
}
