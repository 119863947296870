
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import {
  CallbackForImage,
  InputNumber,
  ExtensionValidOption,
  ImageFileUpload,
  MaxSizeValidOption,
  PostedImagesResponse,
} from '@/types';
import { NCPResponse, PostImagesRequest } from 'ncp-api-supporter';
import FileInputMixin from '@/components/common/input/fileInput/FileInputMixin.vue';
import { getCurrentMallNo } from '@/utils/mall';

export interface LimitOption {
  message: string;
  size: number;
}

@Component
export default class ImageFileInputMixin extends Mixins(FileInputMixin) implements ImageFileUpload {
  @Prop()
  protected readonly mallNo!: InputNumber;

  @Prop({ default: () => ({ validType: 'image', alertMsg: 'PRODUCT.COMMON.IMAGE_FILE_MAX_SIZE' }) })
  readonly extensionValidOption!: ExtensionValidOption;

  @Prop({ default: () => ({ validType: 'normal', alertMsg: 'PRODUCT.OPTION.TIP_10MB' }) })
  readonly maxSizeValidOption!: MaxSizeValidOption;

  @Prop({ default: null })
  protected readonly checkLimitOption: LimitOption;

  @Ref()
  protected readonly imageFileInput: HTMLInputElement;

  public get isMallSelected(): boolean {
    return this.mallNo > 0;
  }

  protected isMultiple = false;

  onUpload({ currentTarget }: { currentTarget: HTMLInputElement }): void {
    this.isMultiple = currentTarget.dataset.multiple !== undefined;
    this.isMultiple ? this.uploadImages(currentTarget) : this.uploadImage(currentTarget);

    this.resetImageInput();
  }

  callbackForPostedImage(file?: File): CallbackForImage {
    console.log(file);
    return (data: PostedImagesResponse) => console.log(data);
  }

  resetImageInput(): void {
    this.imageFileInput.value = '';
  }

  private file: File;
  private uploadImage(currentTarget: HTMLInputElement): void {
    this.file = currentTarget.files[0];
    this.executeUploading(this.file, this.callbackForPostedImage(this.file));
  }

  protected currentSize = 0;
  private isValidLimitSize(addedSize: number): boolean {
    const diff = this.checkLimitOption.size - (this.currentSize + addedSize);
    return diff >= 0;
  }

  protected minusCurrentSize() {
    if (this.checkLimitOption) {
      this.currentSize -= 1;
    }
  }

  private files: File[];
  private uploadImages(currentTarget: HTMLInputElement): void {
    this.files = Array.from(currentTarget.files);

    if (this.checkLimitOption !== null && !this.isValidLimitSize(this.files.length)) {
      alert(this.checkLimitOption.message);
      return;
    }

    this.currentSize += this.files.length;

    this.files.forEach(file => {
      this.executeUploading(file, this.callbackForPostedImage());
    });
  }

  private executeUploading(file: File, callback: CallbackForImage): void {
    if (!this.validateFileExtension(file)) return;
    if (!this.validateFileSize(file)) return;
    this.postImages(ImageFileInputMixin.getFormData(file), callback);
  }

  private static getFormData(file: File): FormData {
    const data: FormData = new FormData();
    data.append('file', file);
    return data;
  }

  private async postImages(formData: FormData, callback: CallbackForImage): Promise<void> {
    try {
      const mallNo = Number(this.mallNo) > 0 ? Number(this.mallNo) : getCurrentMallNo(this);
      const params: PostImagesRequest['params'] = { mallNo };
      const { data }: NCPResponse<PostedImagesResponse> = await this.$api.postImages({ data: formData, params });

      data.file = this.file;
      data.files = this.files;

      callback(data);
    } catch ({ data }) {
      if (data.code === 'error.toastImage.T0004') {
        alert(this.$t('PRODUCT.ICON.ALERT_NOT_ADDABLE'));
      }
    }
  }
}
