



































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import DeliveryCell from '@/components/shipping/order/DeliveryCell.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { PreviousOrderProduct } from 'ncp-api-supporter';
import ClaimButtons from '@/components/shipping/order/OrderProductInfo/ClaimButtons.vue';
import { unescapeHtml } from '@/utils/common';
import OrderProductInfoMixin from '@/components/shipping/order/mixins/OrderProductInfoMixin';
import { formatCurrency } from '@/utils/numberFormat';

@Component({
  components: { DeliveryCell, ClaimButtons, ToolTip },
})
export default class PreviousOrderProductInfo extends Mixins(OrderProductInfoMixin) {
  @Prop({ required: true })
  private orderProducts!: PreviousOrderProduct[];

  private unescapeHtml = unescapeHtml;
  private formatCurrency = formatCurrency;
}
