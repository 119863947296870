export default Object.freeze({
  ALL_SELECT: '전체',
  ADMIT_STATUS: '승인상태',
  APPROVAL_WAIT: '승인대기',
  APPROVAL_COMPLETED: '승인완료',
  REGISTER_WAIT: '등록대기',
  APPROVAL_WAIT_MODIFY: '수정 후 승인대기',
  TOTAL_COUNT: '건',
  JUDGE: '심사',
  JUDGEMENT: '상품심사',
  JUDGEMENT_BATCH: '일괄 상품심사',
  MALL_NAME: '쇼핑몰',
  SEARCH_WORD: '검색어',
  TERM: '기간',
  TERM_SELECT: '기간선택',
  MALL_PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  PRODUCT_MANAGEMENT_CD: '상품관리코드',
  PRODUCT_MANAGEMENT_CD_PART: '상품관리코드|부분검색',
  BRAND: '브랜드',
  PARTNER_NAME: '파트너사',
  PARTNER_NAME_INPUT: '파트너사명 입력',
  ADMIN_NAME: '담당자',
  ADMIN_NAME_INPUT: '담당자 입력',
  COMMISSION_RATE: '수수료',
  SALE_PERIOD: '판매기간',
  APPROVAL_REQUESTED_DATETIME: '승인요청일',
  PRODUCT_REGISTER: '상품등록일',
  LAST_UPDATE_DATE: '최종수정일',
  SALE_START_DATE: '판매시작일',
  SALE_END_DATE: '판매종료일',
  TODAY: '오늘',
  ONE_WEEK: '1주일',
  ONE_MONTH: '1개월',
  THREE_MONTHS: '3개월',
  SIX_MONTHS: '6개월',
  ONE_YEAR: '1년',
  KEYWORD: "복수 상품 검색이 가능합니다. (Enter 또는 ','로 력구분)",
  KEYWORD_TYPE: '숫자와 콤마만 입력할 수 있습니다.',
  KEYWORD_INPUT: '검색어를 입력해주세요.',
  SELECT_PRODUCT: '상품을 선택해 주세요.',
  SELECT_MALL: '쇼핑몰을 먼저 선택해주세요.',
  SELECT_JUDGE_DISABLE: '선택하신 상품은 심사 불가능한 상품입니다. 상품을 다시 선택해주세요.',
  NO_RESULTS: '검색결과가 없습니다.',

  //상품 공통 그리드 (변경 금지)
  ALL: '전체',
  FREE: '무료',
  CONDITIONAL: '조건부 무료',
  FIXED_FEE: '유료(고정 배송비)',
  API_KEYWORD: '수량비례',
  QUANTITY_PROPOSITIONAL_FEE: '수량비례',
  CONSIGNMENT: '위탁',
  PURCHASE: '사입',
  SUPPLY_PRICE_HELP:
    '· 옵션을 사용중인 상품의 경우, 판매수수료 설정 방식에 따라 옵션별로 공급가를 직접 입력하여 설정할 수 있습니다.<br>· 옵션별로 공급가를 설정한 경우 상세보기를 클릭하여 옵션별 공급가를 확인할 수 있습니다.',
});
