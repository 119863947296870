import { GridProp, OptColumn, ChangeDisplayOrderMsgOption } from '@/types';
import { selectRenderer } from '@/utils/grid/rendererUtils';
import ProductMain from '@/views/contents/appearance/custom/ProductMain.vue';
import { DisplableTypes, PlatformTypes2 as PlatformTypes } from '@/const/finderOptions';
import { DEFAULT_DATE_RANGE } from '@/components/common/datepicker/dateRange';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { getCurrency } from '@/utils/common';
import { PostProductsSearchByKey } from 'ncp-api-supporter';
import { getDisplayOrderHistoryColumn } from '@/components/product/common/girdColumns/ChangeHistory';
export const DEFAULT_PRODUCT_MAIN_LIST_QUERY = {
  mallNos: 0,
  searchDateType: 'REGISTER_DATE',
  startDate: DEFAULT_DATE_RANGE.MIN,
  endDate: DEFAULT_DATE_RANGE.MAX,
  title: '',
  platformType: 'ALL',
  displayYn: 'ALL',
  page: 1,
  size: 30,
};
const SaleStatusType = () => {
  return {
    PRE_APPROVAL_STATUS: i18n.t('DESIGN.SECTION.PRE_APPROVAL_STATUS'),
    ON_PRE_SALE: i18n.t('DESIGN.SECTION.ON_PRE_SALE'),
    WAITING_SALE: i18n.t('DESIGN.SECTION.WAITING_SALE'),
    ON_SALE: i18n.t('DESIGN.SECTION.ON_SALE'),
    END_SALE: i18n.t('DESIGN.SECTION.END_SALE'),
  };
};
const SaleSettingStatusType = () => {
  return {
    AVAILABLE_FOR_SALE: i18n.t('DESIGN.SECTION.AVAILABLE_FOR_SALE'),
    STOP_SELLING: i18n.t('DESIGN.SECTION.STOP_SELLING'),
    PROHIBITION_SALE: i18n.t('DESIGN.SECTION.PROHIBITION_SALE'),
  };
};
export const SectionDisplayTypes = () => [
  {
    name: i18n.t('DESIGN.SECTION.GALLERY'),
    value: 'GALLERY',
    img: [require('@/assets/img/img_display_1.svg'), require('@/assets/img/img_display_6.svg')],
    platformTypes: ['PC', 'MOBILE_WEB'],
    isDefault: true,
    displayHeight: {
      pcCnt: 1,
      disable: false,
      mobileWebCnt: 2,
    },
    displayWidth: {
      pcCnt: 4,
      disable: true,
      mobileWebCnt: 2,
    },
  },
  {
    name: i18n.t('DESIGN.SECTION.LIST'),
    value: 'LIST',
    img: [require('@/assets/img/img_display_2.svg'), require('@/assets/img/img_display_7.svg')],
    platformTypes: ['PC', 'MOBILE_WEB'],
    isDefault: false,
    displayHeight: {
      pcCnt: 5,
      disable: false,
      mobileWebCnt: 5,
    },
    displayWidth: {
      pcCnt: 1,
      disable: true,
      mobileWebCnt: 1,
    },
  },
  {
    name: i18n.t('DESIGN.SECTION.CART'),
    value: 'CART',
    img: [require('@/assets/img/img_display_3.svg')],
    platformTypes: ['PC'],
    isDefault: false,
    displayHeight: {
      pcCnt: 1,
      disable: false,
    },
    displayWidth: {
      pcCnt: 4,
      disable: true,
    },
  },
  {
    name: i18n.t('DESIGN.SECTION.SIMPLE_IMAGE'),
    value: 'SIMPLE_IMAGE',
    img: [require('@/assets/img/img_display_4.svg')],
    platformTypes: ['PC'],
    isDefault: false,
    displayHeight: {
      pcCnt: 1,
      disable: false,
    },
    displayWidth: {
      pcCnt: 4,
      disable: true,
    },
  },
  {
    name: i18n.t('DESIGN.SECTION.PRODUCT_MOVE'),
    value: 'PRODUCT_MOVE',
    img: [require('@/assets/img/img_display_5.svg')],
    platformTypes: ['PC'],
    isDefault: false,
    displayHeight: {
      pcCnt: 1,
      disable: true,
    },
    displayWidth: {
      pcCnt: 4,
      disable: false,
    },
  },
  {
    name: i18n.t('DESIGN.SECTION.SWIPE'),
    value: 'SWIPE',
    img: [require('@/assets/img/img_display_8.svg')],
    platformTypes: ['MOBILE_WEB'],
    isDefault: false,
    displayHeight: {
      disable: true,
      mobileWebCnt: 1,
    },
    displayWidth: {
      disable: false,
      mobileWebCnt: 5,
    },
  },
];
export const ProductDisplayTypes = () => [
  {
    id: 'ALL_PRODUCT',
    value: 'ALL_PRODUCT',
    display: i18n.t('DESIGN.SECTION.ALL_PRODUCT'),
    default: true,
    exceptProducts: 'Y',
    displayOrderType: ['REGISTER'], //REGISTER 자동 ADMIN_SETTING 수동
  },
  {
    id: 'CATEGORY',
    value: 'CATEGORY',
    display: i18n.t('DESIGN.SECTION.CATEGORY'),
    default: false,
    exceptProducts: 'Y',
    displayOrderType: ['REGISTER'],
  },
  {
    id: 'PRODUCT',
    value: 'PRODUCT',
    display: i18n.t('DESIGN.SECTION.PRODUCT'),
    default: false,
    exceptProducts: 'N',
    displayOrderType: ['REGISTER', 'ADMIN_SETTING'],
  },
];
export const ExceptProducts = () => [
  { id: 'Y', value: 'Y', display: i18n.t('DESIGN.SECTION.DISPLAY_Y') },
  { id: 'N', value: 'N', display: i18n.t('DESIGN.SECTION.DISPLAY_N') },
];
export const message = (): ChangeDisplayOrderMsgOption => {
  return {
    needOrigin: i18n.t('DESIGN.SECTION.MESSAGE_1') as string,
    selectedTarget: i18n.t('DESIGN.SECTION.MESSAGE_2') as string,
    confirmToRemove: i18n.t('DESIGN.SECTION.MESSAGE_3') as string,
  };
};
export const getProductMainListGridOption = (vue: ProductMain): GridProp => {
  return {
    columns: [
      {
        header: 'DESIGN.SECTION.UPDATE',
        name: 'update',
        align: 'center',
        minWidth: 60,
        formatter: getAnchorHTML(i18n.t('MODIFY')),
      },
      {
        header: 'DESIGN.SECTION.MALL_NAME',
        name: 'mallName',
        align: 'center',
        minWidth: 60,
      },
      {
        header: 'DESIGN.SECTION.TITLE',
        name: 'title',
        align: 'center',
        minWidth: 60,
      },
      {
        header: 'DESIGN.SECTION.PLATFORM_TYPE',
        name: 'platformTypes',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => {
          let platformTypes = value
            .toString()
            .split(',')
            // Only show 'PC' 'MOBILE_WEB', 'MOBILE_APP' doesn't need to displayed temporarily. by 샵바이Pro-화면개발/1118
            .filter(p => p !== 'MOBILE_APP');
          platformTypes = platformTypes.map(platformType => {
            const findedPlatformType = PlatformTypes.find(PlatformType => PlatformType.value === platformType);
            // ?? 데이터에 모바일앱이 없을것 같은데 프리미엄 데이터라서 있음
            return findedPlatformType ? findedPlatformType.name : platformType;
          });
          return platformTypes.toString();
        },
      },
      {
        header: 'DESIGN.SECTION.DISPLAY_TYPE',
        name: 'displayType',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => {
          const findedSectionDisplayType = SectionDisplayTypes().find(
            sectionDisplayType => sectionDisplayType.value === value,
          );
          return findedSectionDisplayType ? (findedSectionDisplayType.name as string) : '';
        },
      },
      {
        header: 'DESIGN.SECTION.DISPLAYABLE',
        name: 'displayYn',
        align: 'center',
        minWidth: 60,
        renderer: props => {
          props.selectOptions = [];
          DisplableTypes.forEach(DisplableType => {
            if (DisplableType.value !== 'ALL') {
              props.selectOptions.push({ text: DisplableType.name, value: DisplableType.value });
            }
          });
          props.callback = event => vue['onGridChangeEvent'](props.columnInfo.name, props.rowKey, event);
          return selectRenderer(props);
        },
      },
      {
        header: 'DESIGN.SECTION.REGISTER_YMDT',
        name: 'updateYmdt',
        align: 'center',
        minWidth: 60,
        formatter: ({ value, row }) => {
          if (value) {
            return `${(row.registerYmdt as string).substr(0, 10)}<br>${(value as string).substr(0, 10)}`;
          }
          return (row.registerYmdt as string).substr(0, 10);
        },
      },
    ],
    options: {
      keyColumnName: 'sectionNo',
      rowHeaders: ['checkbox', 'rowNum'],
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};
const registerColumn = (header = 'PRODUCT.ADD.HEADER_NO', name = 'displayOrder', width = 50): OptColumn => ({
  header,
  name,
  width,
  align: 'center',
  formatter: ({ row }) => {
    return row.displayOrder as string;
  },
});
export const defalutColumns = (): OptColumn<PostProductsSearchByKey>[] => [
  {
    header: 'DESIGN.SECTION.PRODUCT_NO',
    name: 'mallProductNo',
    align: 'center',
    minWidth: 110,
    formatter: ({ value }) => {
      return getAnchorHTML(value.toString());
    },
  },
  {
    header: 'DESIGN.SECTION.PRODUCT_NAME',
    name: 'productName',
    align: 'center',
    minWidth: 200,
  },
  {
    header: 'DESIGN.SECTION.APPLIED_IMMEDIATE_DISCOUNT_PRICE',
    name: 'appliedImmediateDiscountPrice',
    align: 'center',
    minWidth: 80,
    formatter: ({ value }): string => {
      return getCurrency(value);
    },
  },
  {
    header: 'DESIGN.SECTION.SALE_STATUS_TYPE',
    name: 'saleStatusType',
    align: 'center',
    minWidth: 80,
    formatter: ({ value }): string => {
      const saleStatusType = SaleStatusType();
      return saleStatusType[value as string];
    },
  },
  {
    header: 'DESIGN.SECTION.SALE_SETTING_STATUS_TYPE',
    name: 'saleSettingStatusType',
    align: 'center',
    minWidth: 80,
    formatter: ({ value }): string => {
      const saleSettingStatusType = SaleSettingStatusType();
      return saleSettingStatusType[value as string];
    },
  },
  {
    header: 'DESIGN.SECTION.DISPLAYABLE2',
    name: 'frontDisplayable',
    align: 'center',
    minWidth: 80,
    formatter: ({ value }): string => {
      const text = value ? i18n.t('DESIGN.SECTION.DISPLAYABLE_Y2') : i18n.t('DESIGN.SECTION.DISPLAYABLE_N2');
      return text as string;
    },
  },
  {
    header: 'DESIGN.SECTION.SOLD_OUT',
    name: 'isSoldOut',
    align: 'center',
    minWidth: 80,
    formatter: ({ value }): string => {
      // const productStockCnt = row.productStock ? row.productStock.representCnt : 0;
      // const text = value ? i18n.t('품절') : productStockCnt;
      const text = value ? i18n.t('DESIGN.SECTION.SOLD_OUT_Y') : i18n.t('DESIGN.SECTION.SOLD_OUT_N');
      return text as string;
    },
  },
  {
    header: 'DELETE',
    name: 'delete',
    align: 'center',
    minWidth: 60,
    formatter: () => getAnchorHTML(window.$t('DELETE')),
  },
];
export const getDisplayProductGridOption = (type: string): GridProp<PostProductsSearchByKey> => {
  const columns: OptColumn<PostProductsSearchByKey>[] = [
    type === 'register' ? registerColumn() : getDisplayOrderHistoryColumn('No.'),
    ...defalutColumns(),
  ] as OptColumn<PostProductsSearchByKey>[];
  return {
    columns: columns,
    options: {
      // keyColumnName: 'mallProductNo',
      rowHeaders: ['checkbox'],
      heightResizable: true,
      scrollY: true,
      minBodyHeight: 60,
      bodyHeight: 458,
    },
    header: {},
    displayOptions: {
      showsTopArea: true,
      showsRightArea: false,
      hasSettingButton: false,
      hasExcelDownloadButton: false,
      showPageNavigation: false,
    },
  };
};

// skin
export const DESIGN_SKIN_UPLOAD_CONTAINER = 'ncp'; // TODO: ShopBy-Pro-1
export enum DesignSkinAuthorType {
  NHN_GODO = 'NHN커머스',
  USER = '사용',
}
