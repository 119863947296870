









import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Popup } from '@/types/popup';
import PopupItem from './Main.vue';
import { PopupClose } from '@/helpers/popup';
import $el from '@/utils/element';

const DEFUALT_ZINDEX = 1000;
@Component({
  components: {
    PopupItem,
  },
})
export default class PopupContainer extends Vue {
  @Getter('popup/getPopupList') popupList!: Popup[];
  @Getter('popup/getLastPopupItem') lastPopupItem!: Popup;

  @Watch('popupList')
  onPopupListChanged() {
    const $body = $el(document.body);
    if (this.popupList.length > 0) {
      if (!$body.hasClass('popup-open')) {
        $body.addClass('popup-open');
      }
    } else {
      $body.removeClass('popup-open');
    }
  }

  get zIndex() {
    return DEFUALT_ZINDEX + (this.lastPopupItem.id as number) * 100;
  }
  private closeLastPopup(event: Event) {
    event.stopPropagation();
    (this.lastPopupItem.onClose as Function)({ state: PopupClose.CLOSE, data: null });
  }
}
