

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetClaimsNo } from 'ncp-api-supporter';

@Component
export default class ReturnWithdrawReason extends Vue {
  @Prop({ required: true })
  protected withdrawReason!: GetClaimsNo['withdrawReason'];
}
