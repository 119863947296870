

















import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { getCurrency } from '@/utils/common';
import { InputNumber } from '@/types';
import { getOnlyNumbers } from '@/utils/numberFormat';
import { i18n } from '@/main';
@Component({
  components: { TextInput },
})
export default class CurrencyTextInput extends Vue {
  @PropSync('value', { required: true })
  private valueSync: InputNumber;

  @Prop({ required: false })
  private readonly id: string;

  @Prop({ required: false, default: null })
  private readonly maxLength: number;

  @Prop({ required: false, default: false })
  private readonly isRightSide: boolean;

  @Prop({ required: false, default: false })
  private readonly disabled: boolean;

  @Prop({ default: () => i18n.t('WON') })
  private labelText!: string;

  @Ref()
  private readonly textInput: TextInput;
  public focus(): void {
    this.textInput.focus();
  }

  private getCurrency = getCurrency;

  private onKeyup({ target }: { target: HTMLInputElement }) {
    // TODO: 비어있는 값 검사를 위해 아래 내용 주석처리 함
    // 사용처에서 특별한 문제 없으면 주석내용 삭제하기
    // if (target.value === null || target.value === '') {
    //   this.valueSync = 0;
    // } else {
    //   this.valueSync = getOnlyNumbers(target.value);
    // }
    this.valueSync = target.value === '' ? '' : getOnlyNumbers(target.value);
  }

  private valueChangedWhenBlur(value: string): string {
    return value;
  }
}
