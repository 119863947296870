


















import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { AllDepthDataType, ChainingSelectorType, DepthDataType } from '@/types/product';

@Component({
  components: { SelectBox },
})
export default class ChainingSelector extends Vue {
  @Model('change') selectedValue!: string;
  @Prop({ required: false, default: true })
  private readonly showLeftBar!: boolean;
  @Prop({ default: null })
  private readonly depthData!: DepthDataType | null;
  @Prop({ default: true })
  private readonly hasQuery!: boolean;
  @Prop({ default: true })
  private readonly isArrow?: boolean;
  private depthList: ChainingSelectorType[] = [];
  private selectedDepthList: AllDepthDataType[] = [];
  private depth = 5;

  created() {
    this.initDepth();
  }

  @Watch('depthData')
  setDepthData() {
    this.initDepth();
    if (!this.depthData?.data) return;

    const allDepthData: AllDepthDataType[] = this.generateAllDepthData(this.depthData);
    this.setDepth(1, allDepthData);
  }

  private resetDepth(index: number): void {
    const firstItem: AllDepthDataType[] = this.getDefaultDepth(index + 1);
    this.depthList[index].data = firstItem;
    this.depthList[index].disabled = true;
  }

  private funcIterator(startIndex: number, func: Function, length: number = this.depth): void {
    for (let i = startIndex; i < length; i++) {
      func(i);
    }
  }

  // 외부에서 이 메서드에 접근하고싶다면 직접적으로 호출하지마시구, depthData 에 null 할당해주시면 됩니다.
  private initDepth(depth = 5, startIndex = 0): void {
    this.$emit('change', '');
    if (startIndex === 0) {
      this.depthList = [];
      this.depth = depth;
      this.funcIterator(startIndex + 1, this.setDepth, this.depth + 1);
    } else {
      this.funcIterator(startIndex, this.resetDepth);
    }
  }

  // recursive
  private generateAllDepthData({ data, labelKey, valueKey, childrenKey }: DepthDataType): AllDepthDataType[] {
    if (data.length) {
      return data.map(item => {
        const label = item[labelKey];
        const value = item[valueKey];
        const children = this.generateAllDepthData({ data: item[childrenKey], labelKey, valueKey, childrenKey });
        return { value, label, children };
      });
    } else {
      return [];
    }
  }

  private getDefaultDepth(depth: number): AllDepthDataType[] {
    return [{ value: '', label: `${depth}Depth`, children: [] }];
  }

  private setDepth(depth = 1, allDepthData: AllDepthDataType[] = []): void {
    const index = depth - 1;
    const data: AllDepthDataType[] = this.getDefaultDepth(depth);
    if (allDepthData.length) {
      this.depthList[index].data = data.concat(allDepthData);
      this.$set(this.depthList[index], 'disabled', false);
    } else {
      const lastDepth = index === this.depth;
      if (lastDepth) return;

      let depthData = { data, disabled: true };
      if (!index) depthData = { data, disabled: false };
      this.$set(this.depthList, index, depthData);
    }
  }

  private onChangeDepth(value, _, { dataset }): void {
    const { depth } = dataset;

    // value 가 없단 것은 초기값을 선택했다는 것이다. 따라서 이전 댑스에만 값이 있으므로 1을 감산 한다.
    if (!value) {
      this.$emit('update:depth', depth - 1);
    } else {
      this.$emit('update:depth', depth);
    }

    const depthNo = Number(depth);
    const DEPTH_INDEX = depthNo - 1;
    const NEXT_DEPTH = depthNo + 1;

    this.initDepth(this.depth, depthNo);
    const selectedItem = this.depthList[DEPTH_INDEX].data.find(item => item.value.toString() === value.toString());
    this.selectedDepthList[DEPTH_INDEX] = selectedItem;
    this.emitSelectedValue(selectedItem, depthNo);
    if (selectedItem) {
      this.setDepth(NEXT_DEPTH, selectedItem.children);
    } else {
      this.setDepth(NEXT_DEPTH);
    }
  }

  private emitSelectedValue(selectedItem: AllDepthDataType, currentDepthNo: number): void {
    const FIRST_DEPTH = 1;
    const PREV_DEPTH_INDEX = currentDepthNo - 2;

    if (selectedItem.value === '' && currentDepthNo !== FIRST_DEPTH) {
      const prevValue = this.selectedDepthList[PREV_DEPTH_INDEX].value;
      this.$emit('change', prevValue);
    } else {
      this.$emit('change', selectedItem.value);
    }
  }

  private validate(): boolean {
    if (this.hasQuery && this.depthData.data.length <= 0) {
      alert(this.$t('PRODUCT.NO_CATEGORY_DATA'));
      return false;
    }

    return true;
  }

  private onClickDepth() {
    if (!this.validate()) return;
    this.$emit('click');
  }
}
