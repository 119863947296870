
































import { Component, Ref } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import Editor from '@/components/common/summernote/Editor.vue';
import { PopupClose } from '@/helpers/popup';

@Component({
  components: { Editor },
})
export default class StandardContractFilePopup extends WindowPopupMainVue {
  @Ref()
  private readonly editorRef: Editor;

  mounted() {
    this.editorRef.setHtml(this.data.content);
  }

  private sendData() {
    this.closePopup({ state: PopupClose.CLOSE.toString(), data: { content: this.editorRef.getHtml() } });
  }
  private cancel() {
    this.closePopup({ state: PopupClose.CLOSE.toString(), data: null });
  }
}
