























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ComponentItemsClaimDataType } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import OrderProducts from '@/components/popup/claim/ClaimApplyPopup/OrderProducts.vue';
import { PopupClose, PopupResult, throwWindowPopup } from '@/helpers/popup';
import { MallOption, CustomerDemands, GetExchangeProductsProductNoRequest, MaxBuyCountInfo } from 'ncp-api-supporter';
import SelectBox from '@/components/common/SelectBox.vue';
import { unescapeHtml } from '@/utils/common';
import { Options } from 'ncp-api-supporter';

@Component({
  components: { SelectBox },
})
export default class ProductExchange extends Vue {
  @Prop({ required: true }) private orderOptionsApply!: ComponentItemsClaimDataType;
  @Prop({ required: false }) private partnerNo!: string;
  @Prop({ required: false }) private memberNo!: string;
  @Prop({ required: true }) private claimType!: string;
  @Prop({ required: false }) private mallNo!: string;
  @Prop({ required: true }) public checkedExchangesProduct: boolean;
  private noData = true;
  private saleCnt = 0;
  private selectOptionDisabled = false;
  public accumulationUsed = false;
  public differentProductClick = false;
  public clickSameProduct = false;
  public mallOptionValue = '';
  public mallOptionValueArr = [];
  public exchangeProductOptions = [];
  private unescapeHtml = unescapeHtml;
  private minBuyCount = 0;
  private maxBuyPeriodCount = 0;
  private maxBuyPersonCount = 0;
  private maxBuyDays = 0;
  private maxBuyTimeCount = 0;
  private totalBuyCount = 0;
  private beforeExchangeCnt = 0;

  private get disableExchangeCntInput() {
    return this.mallOptionValue === '';
  }
  public onClickSameProduct() {
    if (this.checkedExchangesProduct === true) {
      if (!confirm(this.$t('CLAIM.EXCHANGEPOPUP.CONFIRM3').toString())) {
        return;
      }
    }
    this.selectOptionDisabled = false;
    const productNo: number = this.orderOptionsApply.claimableOptions[0].mallProductNo;
    const request: GetExchangeProductsProductNoRequest = {
      pathParams: {
        productNo: productNo.toString(),
      },
      params: {
        mallNo: this.mallNo,
      },
    };
    this.$api.getExchangeProductsProductNo(request).then(async ({ data }) => {
      if (data.saleSettingStatusType !== 'AVAILABLE_FOR_SALE') {
        alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER18').toString());

        return false;
      }

      const {
        partnerName,
        productName,
        salePrice,
        immediateDiscountAmount,
        hasOption,
        optionType,
        accumulationUsed,
        options,
        minBuyCnt,
        maxBuyCountInfo,
      } = data;

      this.partnerName = partnerName;
      this.partnerNo = String(this.orderOptionsApply.claimableOptions[0].partnerNo);
      this.mallProductNo = productNo.toString();
      this.productName = productName;
      this.salePrice = salePrice;
      this.immediateDiscountAmount = immediateDiscountAmount;
      this.mallOptionMap = new Map();
      this.hasOption = hasOption;
      this.mallInputs = [];
      this.productType = optionType;
      this.accumulationUsed = accumulationUsed;
      this.exchangeProductOptions = options;
      this.minBuyCount = minBuyCnt;
      this.setMinMaxBuyCountInfo(maxBuyCountInfo);

      await this.$api.getProductsProductNoCustomerDemands({ params: { productNo: productNo.toString() } }).then(res => {
        res.data.forEach((productCustomerDemands: CustomerDemands) => {
          this.mallInputs.push({
            mallProductInputNo: productCustomerDemands.inputNo.toString(),
            inputText: productCustomerDemands.inputText,
          });
        });
      });

      if (Number(this.memberNo) !== 0) {
        const { data } = await this.$api.postOrdersBuyCount({
          data: {
            memberNo: Number(this.memberNo),
            maxLimitPersonMallProductNos: [Number(this.mallProductNo)],
            maxLimitPeriodProducts: [],
          },
        });
        this.totalBuyCount = data[0].buyCount;
      }

      await this.$api.getProductsMallProductNoOptions({ params: { mallProductNo: this.mallProductNo } }).then(res => {
        this.mallOption = res.data;
        this.mallOptionValue = '';
        this.exchangeCnt = '' as any;
        this.optionPrice = '';
        this.optionAmt = '';
        this.saleCnt = 0;
        this.mallOptionValueArr = [
          {
            name: '옵션을 선택해 주세요.',
            value: '',
          },
        ];
        this.mallOption.forEach(ele => {
          this.mallOptionValueArr.push({
            name: this.getProductOption(ele.optionName, ele.optionValue, ele.stockCnt),
            value: ele.mallOptionNo.toString(),
            disabled: ele.stockCnt <= 0,
          });
        });
        if (this.hasOption == false && res.data[0].stockCnt === 0) {
          alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER'));
          return false;
        } else {
          res.data.forEach((mallOption: MallOption) => {
            this.exchangeProductOptions.map(res => {
              if (res.mallOptionNo === mallOption.mallOptionNo) {
                (mallOption as any).additionalDiscountAmount = res.additionalDiscountAmount;
              }
              this.mallOptionMap.set(mallOption.mallOptionNo.toString(), mallOption);
            });
          });
        }
        this.differentProductClick = false;
        if (this.mallOptionMap.size === 1) {
          this.mallOptionValue = res.data[0].mallOptionNo.toString();
          const orderedOption: any = this.mallOptionMap.get(this.mallOptionValue);
          if (orderedOption) {
            this.exchangeCnt = this.getInitialExchangeCnt(orderedOption);
            this.saleCnt = orderedOption?.stockCnt ?? 0;
            if (this.orderOptionsApply.claimableOptions[0].mallProductNo.toString() == this.mallProductNo) {
              if (this.orderOptionsApply.claimableOptions[0].orderCnt < this.saleCnt) {
                this.exchangeCnt = (this.$parent.$refs.componentItemsClaim as OrderProducts).orderCntArray[0];
              } else {
                this.exchangeCnt = this.saleCnt;
              }
            }
            this.optionPrice = String(this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0);
            this.optionAmt = String(
              (this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0) * this.exchangeCnt,
            );
            this.optionAddPrice = String(orderedOption?.additionalDiscountAmount ?? 0);
          } else {
            this.saleCnt = 0;
            this.optionPrice = '';
            this.optionAmt = '';
            this.mallOptionValue = null;
          }
          this.$emit('setData');
        }
        this.noData = false;
      });
    });
  }

  private setMinMaxBuyCountInfo({ maxBuyDays, maxBuyPeriodCnt, maxBuyPersonCnt, maxBuyTimeCnt }: MaxBuyCountInfo) {
    this.maxBuyDays = maxBuyDays;
    this.maxBuyPeriodCount = maxBuyPeriodCnt;
    this.maxBuyPersonCount = maxBuyPersonCnt;
    this.maxBuyTimeCount = maxBuyTimeCnt;
  }

  public onClickSameOptionProduct() {
    if (this.checkedExchangesProduct === true) {
      if (!confirm(this.$t('CLAIM.EXCHANGEPOPUP.CONFIRM3').toString())) {
        return;
      }
    }
    const productNo: number = this.orderOptionsApply.claimableOptions[0].mallProductNo;
    const request: GetExchangeProductsProductNoRequest = {
      pathParams: {
        productNo: productNo.toString(),
      },
      params: {
        mallNo: this.mallNo,
      },
    };
    this.$api.getExchangeProductsProductNo(request).then(async response => {
      if (response.data.saleSettingStatusType !== 'AVAILABLE_FOR_SALE') {
        alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER18').toString());
        return false;
      }

      this.partnerName = response.data.partnerName;
      this.mallProductNo = productNo.toString();
      this.productName = response.data.productName;
      this.salePrice = response.data.salePrice;
      this.immediateDiscountAmount = response.data.immediateDiscountAmount;
      this.mallOptionMap = new Map();
      this.hasOption = response.data.hasOption;
      this.mallInputs = [];
      this.productType = response.data.optionType;
      this.accumulationUsed = response.data.accumulationUsed;
      this.exchangeProductOptions = response.data.options;
      this.setMallOptionMap(response.data.options);
      this.differentProductClick = false;
      this.clickSameProduct = true;
      this.mallOption = response.data.options;
      this.exchangeCnt = (this.$parent.$refs.componentItemsClaim as OrderProducts).orderCntArray[0];
      this.optionPrice = '';
      this.optionAmt = '';
      this.saleCnt = 0;
      this.mallOptionValueArr = [];
      this.selectOptionDisabled = true;
      const mallOption = this.mallOption.filter(
        item => item.mallOptionNo === Number(this.orderOptionsApply.claimableOptions[0].mallOptionNo.toString()),
      );
      mallOption.forEach(ele => {
        this.mallOptionValueArr.push({
          name: this.getProductOption(ele.optionName, ele.optionValue, ele.stockCnt),
          value: ele.mallOptionNo.toString(),
          disableOption: ele.stockCnt <= 0,
        });
      });
      this.mallOptionValue = this.orderOptionsApply.claimableOptions[0].mallOptionNo.toString();
      const orderedOption: any = this.mallOptionMap.get(this.mallOptionValue); // undefined
      if (orderedOption === undefined) {
        this.onClickRemove();

        alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER18') as string);
        return;
      }
      this.saleCnt = orderedOption?.stockCnt ?? 0;
      this.optionPrice = String(this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0);
      this.optionAmt = String(
        (this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0) * this.exchangeCnt,
      );
      this.optionAddPrice = String(orderedOption?.additionalDiscountAmount ?? 0);
      this.$emit('setData');
      this.noData = false;
      await this.$api.getProductsProductNoCustomerDemands({ params: { productNo: productNo.toString() } }).then(res => {
        res.data.forEach((productCustomerDemands: CustomerDemands) => {
          this.mallInputs.push({
            mallProductInputNo: productCustomerDemands.inputNo.toString(),
            inputText: productCustomerDemands.inputText,
          });
        });
      });
    });
  }

  private setMallOptionMap(options: Options[]) {
    const mapData = options.map(mallOption => {
      this.exchangeProductOptions.forEach(res => {
        if (res.mallOptionNo === mallOption.mallOptionNo) {
          mallOption.additionalDiscountAmount = res.additionalDiscountAmount;
        }
      });

      return [mallOption.mallOptionNo.toString(), mallOption];
    });

    mapData.forEach(([mallOptionNo, mallOption]) => {
      this.mallOptionMap.set(mallOptionNo, mallOption);
    });
  }

  public onClickDifferentProduct(): void {
    if (this.checkedExchangesProduct === true) {
      if (!confirm(this.$t('CLAIM.EXCHANGEPOPUP.CONFIRM3').toString())) {
        return;
      }
    }
    throwWindowPopup(
      'OrderProductInquiry',
      {
        fixed: {
          mallNo: Number(this.mallNo),
          partnerNo: Number(this.partnerNo),
          applyStatusType: 'FINISHED',
        },
        queryLimit: {
          saleStatus: 'ON_PRE_SALE,ON_SALE',
          saleSettingStatus: 'AVAILABLE_FOR_SALE',
        },
        directSend: true,
      },
      'xxlg',
      (callbackData: PopupResult) => {
        if (callbackData.state === PopupClose.CLOSE) return;
        this.callback(callbackData.data);
      },
    );
  }

  private getProductOption(name, value, stockCnt) {
    const optionName = name.split('|');
    const optionValue = value.split('|');
    const optionFullName = [];
    for (let i = 0; i < optionName.length; i++) {
      optionFullName[i] = optionName[i] + ':' + optionValue[i] + this.showSoldOut(stockCnt);
    }
    return unescapeHtml(optionFullName.join(' | '));
  }
  public exchangeCnt = 1;
  private partnerName = '';
  public mallProductNo = '0';
  private productName = '';
  private shippingAreaType = '';
  private mallOption = [];
  private mallOptionMap = new Map();
  private salePrice = 0;
  private immediateDiscountAmount = 0;
  private additionalDiscountAmount = 0;
  private optionPrice = '';
  private optionAmt = '';
  private optionAddPrice = '';
  private hasOption = false;
  private productType = '';
  public mallInputs: {
    mallProductInputNo: string;
    inputText: string;
  }[] = [];
  public listMallInput: string[] = [];
  created() {
    this.listMallInput = this.orderOptionsApply.claimableOptions.flatMap(({ userInputText }) =>
      userInputText.flatMap(({ inputValue }) => unescapeHtml(inputValue ?? '')),
    );
  }
  private showSoldOut(stockCnt: number): string {
    return stockCnt <= 0 ? ' [품절]' : '';
  }
  private onChangedMallOption() {
    this.clickSameProduct = false;
    const orderedOption: any = this.mallOptionMap.get(this.mallOptionValue);
    this.exchangeCnt = this.getInitialExchangeCnt(orderedOption);
    this.beforeExchangeCnt = this.getInitialExchangeCnt(orderedOption);
    if (orderedOption) {
      this.saleCnt = orderedOption?.stockCnt ?? 0;
      this.optionPrice = String(this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0);
      this.optionAmt = String(
        (this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0) * this.exchangeCnt,
      );
      this.optionAddPrice = String(orderedOption?.additionalDiscountAmount ?? 0);
      if (this.orderOptionsApply.claimableOptions[0].mallProductNo.toString() == this.mallProductNo) {
        if (this.orderOptionsApply.claimableOptions[0].orderCnt < this.saleCnt) {
          this.exchangeCnt = (this.$parent.$refs.componentItemsClaim as OrderProducts).orderCntArray[0];
        } else {
          this.exchangeCnt = this.saleCnt;
        }
      }
      if (this.differentProductClick === true) {
        this.exchangeCnt = 1;
      }
    } else {
      this.saleCnt = 0;
      this.optionPrice = '';
      this.optionAmt = '';
    }
    this.$emit('setData');
  }
  // 교환상품 선택할 때, 첫 exchangeCnt 결정하는 메서드
  private getInitialExchangeCnt(mallOption) {
    //  mallOption을 기존코드가 temp에 담아쓰고 있어 any
    if (mallOption === undefined) {
      return 0;
    }
    return mallOption.stockCnt < this.orderOptionsApply.claimableOptions[0].orderCnt
      ? mallOption.stockCnt
      : this.orderOptionsApply.claimableOptions[0].orderCnt;
  }
  private changeExchangeCnt(type) {
    if (type === 'minus') {
      this.exchangeCnt = this.exchangeCnt - 1;
    } else if (type === 'plus') {
      this.exchangeCnt = this.exchangeCnt + 1;
    }
    this.onExchangeCnt();
  }
  private beforeTotalBuyCount = this.totalBuyCount - this.beforeExchangeCnt;

  private get isExceedMaxBuyPeriodCount(): boolean {
    return this.maxBuyPeriodCount && this.exchangeCnt + this.beforeTotalBuyCount > this.maxBuyPeriodCount;
  }

  private get isExceedMaxBuyPersonCount(): boolean {
    return this.maxBuyPersonCount && this.exchangeCnt + this.beforeTotalBuyCount > this.maxBuyPersonCount;
  }

  private get isExccedMaxBuyTimeCount(): boolean {
    return this.maxBuyTimeCount && this.exchangeCnt > this.maxBuyTimeCount;
  }
  private onExchangeCnt() {
    if (this.exchangeCnt < this.minBuyCount) {
      confirm(this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_MIN_BUY_COUNT_BELOW_MESSAGE').toString());
    }
    if (this.exchangeCnt < 1) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_EXCHANGE_MINIMUM_MESSAGE'));
      this.exchangeCnt = 1;
    }
    /**
     * 1인, 1회, 기간제한의 최대구매수량에 대한 설정 시 입금대기건 부터 수량을 체크하여 교환 시 수량 체크
     */
    if (this.isExceedMaxBuyPeriodCount || this.isExceedMaxBuyPersonCount || this.isExccedMaxBuyTimeCount) {
      if (!confirm(this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_MAX_BUY_COUNT_ABOVE_MESSAGE').toString())) {
        this.exchangeCnt = this.exchangeCnt - 1;
      }
    }

    if (this.exchangeCnt > this.saleCnt) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_EXCHANGE_MAXIMUM_MESSAGE', { num: this.saleCnt }));
      this.exchangeCnt = this.saleCnt;
    }
    const parentRefs = this.$parent.$refs as any;
    parentRefs.componentProductExchangeDetails.exchangeProductAmtAdjustAmt = 0;
    parentRefs.componentProductExchangeDetails.exchangeProductAmtAdjustAmt = 0;
    if (this.claimType === 'ExchangePopupAfter') {
      parentRefs.componentRefundExchangeFee.returnDeliveryAmtAdjustAmt = 0;
      parentRefs.componentRefundExchangeFee.returnDeliveryAmtAdjustReason = '';
      parentRefs.componentRefundExchangeFee.exchangeDeliveryAmtAdjustAmt = 0;
      parentRefs.componentRefundExchangeFee.exchangeDeliveryAmtAdjustReason = '';
    }
    const orderedOption = this.mallOptionMap.get(this.mallOptionValue);
    if (orderedOption) {
      this.optionAmt = String(
        (this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0) * this.exchangeCnt,
      );
    }
    this.$emit('setData');
  }
  private onClickRemove() {
    this.mallOptionValue = null;
    this.noData = true;
    this.$emit('clearData');
  }
  private callback(items) {
    this.noData = true;
    (window as any).displayExchangeProductOptionInputArea = undefined;
    items.map(
      async (item): Promise<any> => {
        this.shippingAreaType = item.shippingAreaType;
        this.partnerName = item.partnerName;
        this.mallProductNo = item.mallProductNo;
        this.productName = item.productName;
        this.salePrice = item.salePrice;
        this.immediateDiscountAmount = item.immediateDiscountAmount;
        this.mallOptionMap = new Map();
        this.hasOption = item.hasOption;
        this.mallInputs = [];
        this.productType = item.optionType;

        const request: GetExchangeProductsProductNoRequest = {
          pathParams: {
            productNo: item.mallProductNo,
          },
          params: {
            mallNo: this.mallNo,
          },
        };

        await this.$api.getExchangeProductsProductNo(request).then(async ret => {
          this.accumulationUsed = ret.data.accumulationUsed;
          this.exchangeProductOptions = ret.data.options;
        });

        await this.$api.getProductsProductNoCustomerDemands({ params: { productNo: item.mallProductNo } }).then(res => {
          res.data.forEach((productCustomerDemands: CustomerDemands) => {
            this.mallInputs.push({
              mallProductInputNo: productCustomerDemands.inputNo.toString(),
              inputText: productCustomerDemands.inputText,
            });
          });
        });

        await this.$api.getProductsMallProductNoOptions({ params: { mallProductNo: this.mallProductNo } }).then(res => {
          this.mallOption = res.data;
          this.mallOptionValue = '';
          this.exchangeCnt = '' as any;
          this.saleCnt = 0;
          this.optionPrice = '';
          this.optionAmt = '';
          this.selectOptionDisabled = false;
          this.mallOptionValueArr = [
            {
              name: '옵션을 선택해 주세요.',
              value: '',
            },
          ];
          this.mallOption.forEach(ele => {
            this.mallOptionValueArr.push({
              name: this.getProductOption(ele.optionName, ele.optionValue, ele.stockCnt),
              value: ele.mallOptionNo.toString(),
              disableOption: ele.stockCnt <= 0,
            });
          });
          if (this.hasOption == false && res.data[0].stockCnt == 0) {
            alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER'));
            return false;
          } else {
            res.data.forEach((mallOption: MallOption) => {
              this.exchangeProductOptions.map(res => {
                if (res.mallOptionNo === mallOption.mallOptionNo) {
                  (mallOption as any).additionalDiscountAmount = res.additionalDiscountAmount;
                }
                this.mallOptionMap.set(mallOption.mallOptionNo.toString(), mallOption);
              });
            });
          }
          this.differentProductClick = true;
          // this.mallOptionMap.size == 1 일때만 계산하도록 되어있었음. 옵션 없는 제품을 의미하는 듯 하다.
          if (this.mallOptionMap.size === 1) {
            this.mallOptionValue = res.data[0].mallOptionNo.toString();
            const orderedOption: any = this.mallOptionMap.get(this.mallOptionValue);
            if (orderedOption) {
              this.exchangeCnt = 1;
              this.saleCnt = orderedOption?.stockCnt ?? 0;
              this.optionPrice = String(this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0);
              this.optionAmt = String(
                (this.salePrice - this.immediateDiscountAmount + orderedOption?.addPrice ?? 0) * this.exchangeCnt,
              );
              this.optionAddPrice = String(orderedOption?.additionalDiscountAmount ?? 0);
            } else {
              this.saleCnt = 0;
              this.optionPrice = '';
              this.optionAmt = '';
              this.optionAddPrice = '';
              this.mallOptionValue = null;
            }
            this.$emit('setData');
          }
          this.noData = false;
        });
      },
    );
  }
}
