import { Failure } from '@/types';
import { i18n } from '@/main';

// 0 개 가능
export const errorCaseCounter = <FailErrorCode>(
  errorCode: FailErrorCode,
  failures: Failure<FailErrorCode>[],
): number => {
  return failures.reduce((acc, failure) => {
    if (failure.errorCode === errorCode) acc += 1;
    return acc;
  }, 0);
};

export const getErrorMessages = <FailErrorCode>(
  errorCodes: FailErrorCode[],
  failures: Failure<FailErrorCode>[],
  i18nLocationString?: string,
): string[] => {
  return errorCodes.reduce((acc, errorCode) => {
    const count = errorCaseCounter(errorCode, failures);
    if (count <= 0) return acc;
    const failCountMessage = i18n.t('FAIL_N', { count }).toString();
    const reasonMessage = i18nLocationString
      ? i18n.t(i18nLocationString + '.' + errorCode.toString()).toString()
      : i18n.t(errorCode.toString()).toString();
    const failMessage = `${failCountMessage} : ${reasonMessage}`;
    if (failMessage) acc.push(failMessage);
    return acc;
  }, []);
};
