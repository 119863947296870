





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';

const mallStore = namespace('mall');

@Component
export default class MallDueDaysSummary extends Vue {
  @mallStore.Getter('getUsedMalls')
  private readonly malls!: Mall[];
  @Prop({ required: true })
  private readonly selectMallNo!: '' | number;

  get selectedMallExpirationDueDays() {
    return this.malls.find(({ mallNo }) => this.selectMallNo === mallNo).expirationDueDays;
  }
}
