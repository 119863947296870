

























import { Component } from 'vue-property-decorator';
import WindowPopupMain from '@/views/popups/Main.vue';
import KakaoTemplate from '@/views/contents/member/kakao/KakaoTemplate.vue';
import {
  GetKakaoAutoTemplatesGroup,
  GetKakaoAutoTemplatesGroupRequest,
  TemplateGroupType,
  NCPResponse,
} from 'ncp-api-supporter';
import { getCurrentMallNo } from '@/utils/mall';

@Component({
  components: { KakaoTemplate },
})
export default class TemplateSelectManager extends WindowPopupMain {
  private templateData: GetKakaoAutoTemplatesGroup[] = [];
  private templateTypeName = '';

  public async getKakaoUseTemplate(groupType: TemplateGroupType) {
    const request: GetKakaoAutoTemplatesGroupRequest = {
      pathParams: {
        groupType,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
    };
    try {
      const response: NCPResponse<GetKakaoAutoTemplatesGroup[]> = await this.$api.getKakaoAutoTemplatesGroup(request);
      this.templateData = response.data.filter(
        ({ inspectionStatus, status }) =>
          inspectionStatus === 'APPROVE' && !(inspectionStatus === 'APPROVE' && status === 'STOP'),
      );
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  created() {
    this.getKakaoUseTemplate(this.data.templateType);
    this.templateTypeName = this.data.templateTypeName;
  }
}
