



































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  WidgetReviewType,
  GetReviewsConfigurationsWidget,
  GetReviewsConfigurationsWidgetPreviewReviewedProducts,
} from 'ncp-api-supporter';

@Component({})
export default class PreviewThumb extends Vue {
  @Prop()
  private readonly widget?: GetReviewsConfigurationsWidget | GetReviewsConfigurationsWidgetPreviewReviewedProducts;
  @Prop()
  private readonly type?: WidgetReviewType;
}
