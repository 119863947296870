






























import { Vue, Component } from 'vue-property-decorator';
import { Mall } from 'ncp-api-supporter';
import { namespace } from 'vuex-class';

export interface MallPointItem {
  mallName: string;
  mallNo: number;
  point: string;
}

const mallStore = namespace('mall');
const pointStore = namespace('smsPoint');
const summaryStore = namespace('summary');

@Component
export default class SmsPointSummary extends Vue {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];
  @pointStore.Action('fetchPoints')
  private readonly fetchPoints: Function;
  @pointStore.Getter('getMallPointList')
  private readonly mallPoints!: MallPointItem[];
  @summaryStore.Getter('getMallNoQuery')
  protected readonly mallNoQuery!: 'ALL' | number;

  private mappedPointData = false; // 포인트 갱신 안된 상태에서는 안보이기 위함

  private mallPointList: MallPointItem[] | null = null;

  private sumPoints(): number {
    return this.mallPointList.reduce((acc, { point }) => {
      return point ? acc + Number(point) : acc;
    }, 0);
  }
  private getSelectedMallPoint(): number {
    const { point } = this.mallPointList.find(({ mallNo }) => mallNo === Number(this.mallNoQuery));
    return Number(point);
  }
  get totalPoint(): string {
    const isAllOrNothing = this.mallNoQuery === 'ALL' || !this.mallNoQuery;
    const total = isAllOrNothing ? this.sumPoints() : this.getSelectedMallPoint();

    return total > 0 ? total.toFixed(1).toString() : '0';
  }

  async created() {
    if (this.malls.length > 0) {
      this.mallPointList = this.malls.concat().map(({ mallName, mallNo }) => ({ mallName, mallNo, point: '0' }));
    }

    if (this.mallPoints === null || this.mallPoints.length <= 0) await this.fetchPoints();
    this.mapPoint();
  }

  private mapPoint(): void {
    if (this.mallPoints === null || this.mallPoints.length <= 0) return;
    this.mallPointList = this.mallPointList.map(item => {
      const pointData = this.mallPoints.find(({ mallNo }) => item.mallNo === Number(mallNo));
      if (pointData && pointData?.point) {
        return {
          ...item,
          point: pointData.point,
        };
      }
      return item;
    });
    this.mappedPointData = true;
  }
}
