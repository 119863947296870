
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ExtensionValidOption, ExtensionValidType, MaxSizeValidOption, MaxSizeValidType } from '@/types';

const ExtensionValidation = {
  excel: '.xlsx',
  image: '.jpg, .jpeg, .gif, .png, .bmp',
};

const MaxSizeValidation = {
  thumbnail: 10240,
  normal: 10485760,
  attached: 5242880,
};

let uid = 0;

@Component
export default class FileInputMixin extends Vue {
  @Prop({ default: () => ({ validType: '', alertMsg: '' }) })
  readonly extensionValidOption!: ExtensionValidOption;

  @Prop({ default: () => ({ validType: '', alertMsg: '' }) })
  readonly maxSizeValidOption!: MaxSizeValidOption;

  @Prop({ required: false, default: null })
  readonly removeButtonHandler!: () => void | null;

  private uid!: string;

  private fileName = '';

  private hasRemoveFileButton = false;

  created() {
    if (!this.removeButtonHandler) return;
    this.hasRemoveFileButton = true;
  }

  public get hasFile(): boolean {
    return this.fileName !== '';
  }

  protected get id(): string {
    return 'file_' + this.uid;
  }

  protected get accept(): string {
    const type = this.extensionValidOption.validType.toLowerCase();
    return type === '' ? '' : ExtensionValidation[type];
  }

  onUpload({ currentTarget }: { currentTarget: HTMLInputElement }): void {
    const file = currentTarget.files[0]; // not multiple
    if (!file) return;
    if (!this.validateFileExtension(file)) return;
    if (!this.validateFileSize(file)) return;

    this.fileName = file.name;
    this.$emit('change', file);
  }

  protected validateFileExtension(file: File): boolean {
    const type = this.extensionValidOption.validType;

    if (type === '' || !file) return true;

    if (FileInputMixin.isValidExtension(type, file.name.toLowerCase())) return true;

    this.extensionValidOption.alertMsg !== '' && alert(this.$t(this.extensionValidOption.alertMsg).toString());
    return false;
  }

  protected validateFileSize(file: File): boolean {
    const type = this.maxSizeValidOption.validType;

    if (type === '' || !file) return true;

    if (FileInputMixin.isValidFileSize(type, file.size)) return true;

    this.maxSizeValidOption.alertMsg && alert(this.$t(this.maxSizeValidOption.alertMsg).toString());
    return false;
  }

  private static isValidExtension(type: ExtensionValidType, fileName: string): boolean {
    const regexString = ExtensionValidation[type]
      .replaceAll('.', '')
      .split(',')
      .map(v => v.trim())
      .join('|');
    const regexp = new RegExp(`.(${regexString})$`, 'ig');

    if (!regexp.test(fileName)) return false;

    return `.${regexString.replaceAll('|', '.')}`.includes(RegExp.lastMatch);
  }

  private static isValidFileSize(type: MaxSizeValidType, fileSize: number): boolean {
    const maxSize = MaxSizeValidation[type];
    return fileSize <= maxSize;
  }

  protected beforeCreate(): void {
    this['uid'] = uid.toString();
    uid += 1;
  }

  protected removeFile(): void {
    this.fileName = '';
    this.removeButtonHandler();
  }
}
