import { ClaimOption } from 'ncp-api-supporter';

export const immediateDiscountedAmt = ({
  claimedOption,
  needSum = false,
}: {
  claimedOption: ClaimOption;
  needSum: boolean;
}): number => {
  const { salePrice, addPrice, immediateDiscountAmt, orderCnt } = claimedOption;
  const immediateDiscountedAmt = salePrice + addPrice - immediateDiscountAmt;

  return needSum ? immediateDiscountedAmt * orderCnt : immediateDiscountedAmt;
};

export const exchangeImmediateDiscountedAmt = ({
  claimOptions,
  needSum = false,
}: {
  claimOptions: ClaimOption | ClaimOption[];
  needSum: boolean;
}) => {
  const FIRST_ITEM = 0; // 교환은 무조건 1:1 교환 뿐, 하지만 데이터는 배열로 내려와서 0번째 아이템을 가져와 계산

  const { salePrice, addPrice, immediateDiscountAmt, orderCnt } = Array.isArray(claimOptions)
    ? claimOptions[FIRST_ITEM]
    : claimOptions;

  const immediateDiscountedAmt = salePrice + addPrice - immediateDiscountAmt;
  return needSum ? immediateDiscountedAmt * orderCnt : immediateDiscountedAmt;
};
