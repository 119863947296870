import { PostExcelsCompressDownloadsRequest } from 'ncp-api-supporter';
import { api } from '@/api';
import { saveAs } from 'file-saver';

export interface CertificationData {
  reason: string;
  password: string;
}

export type CertificationDataKey = keyof CertificationData;

const fetchExcelDownload = async (excelNo: number, reason?: string, password?: string): Promise<Blob> => {
  const request: PostExcelsCompressDownloadsRequest = {
    data: {
      excelNo,
    },
  };

  if (reason !== undefined) Object.assign(request.data, { reason });
  if (password !== undefined) Object.assign(request.data, { password });

  try {
    const response = await api.postExcelsCompressDownload(request);
    return new Blob([response.data], { type: 'application/zip' });
  } catch (error) {
    const errorText = error.data
      ? await new Response(error.data).text()
      : JSON.stringify({ code: 'EB0005', message: '' });
    const { code, message } = JSON.parse(errorText);
    switch (code) {
      case 'EB0005':
        alert(window.$t('EXCEL.ALERT_NO_DOWNLOAD_PERMISSION'));
        break;
      default:
        alert(message);
        break;
    }

    throw new Error(code);
  }
};

export const handleExcelDownloadProcess = async (
  excelNo: number,
  fileName: string,
  certificationData?: CertificationData,
): Promise<void> => {
  let blob: Blob;
  if (certificationData === undefined) {
    blob = await fetchExcelDownload(excelNo);
  } else {
    const { reason, password } = certificationData;
    blob = await fetchExcelDownload(excelNo, reason, password);
  }

  const fileNameWithExtension = `${fileName}.zip`;
  saveAs(blob, fileNameWithExtension.normalize('NFC'));
};
