






import { Vue, Component } from 'vue-property-decorator';
import { i18nListByKey } from '@/utils/i18n';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';

@Component({
  components: {
    TabMenu,
  },
})
export default class Email extends Vue {
  private menuList: TabMenuI[] = [
    { display: 'MEMBER.MAIL.TAB_CONFIG', path: 'config' },
    { display: 'MEMBER.MAIL.TAB_TEMPLATE', path: 'template' },
    { display: 'MEMBER.MAIL.TAB_HISTORY', path: 'history' },
  ];

  created() {
    this.menuList = i18nListByKey(this, this.menuList, 'display');
  }
}
