




























































































import { Vue, Component, Ref } from 'vue-property-decorator';

import { GetNoticeListRequest, ManageNoticeCategoryType } from 'ncp-api-supporter';

import SearchButton from '@/components/searchForm/SearchButton.vue';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { getDefaultPartnerNoticeSearchForm } from '@/views/top/partner/notice/PartnerNoticeList';
import { getPartnerNoticeSelectBox } from '@/const/contents/partner';
import { OptionData } from '@/helpers/type';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';

@Component({
  components: {
    DateRangePicker,
    SearchButton,
    SearchKeyword,
    SelectBox,
    MallSelect,
    RadioGroup,
    TextInput,
  },
})
export default class PartnerNoticeList extends Vue {
  @Ref()
  private readonly searchButtonRef: SearchButton;

  private readonly selectBox = getPartnerNoticeSelectBox();
  private categorySelectBox: OptionData<ManageNoticeCategoryType>[] = [];

  private searchForm = getDefaultPartnerNoticeSearchForm();
  private get searchFormQuery(): GetNoticeListRequest['params'] {
    return {
      ...this.searchForm,
      mallNo: `${this.searchForm.mallNo}`,
      noticeCategory: this.searchForm.noticeCategory === 'ALL' ? undefined : this.searchForm.noticeCategory,
      startDateTime: `${this.searchForm.startDateTime} ${DEFAULT_TIME_RANGE.START}`,
      endDateTime: `${this.searchForm.endDateTime} ${DEFAULT_TIME_RANGE.END}`,
    };
  }

  private changeMallNo(mallNo: number) {
    this.searchForm.mallNo = mallNo;
  }

  private sendQueryUrl() {
    this.$nextTick(this.searchButtonRef.sendQueryUrl);
  }

  private resetAll() {
    this.searchForm = { ...getDefaultPartnerNoticeSearchForm() };
    this.sendQueryUrl();
  }

  private created() {
    this.fetchNoticeCategories();
  }

  private mounted() {
    this.sendQueryUrl();
  }

  private fetchNoticeCategories() {
    this.$api.getNoticesCategories().then(({ data }) => this.mapCategorySelectBox(data));
  }

  private mapCategorySelectBox(categories: ManageNoticeCategoryType[]) {
    this.categorySelectBox = categories.map(value => ({
      name: `PARTNER.NOTICE.${value}`,
      value,
    }));
  }
}
