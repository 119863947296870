




import AdminIframe from '@/components/iframe/AdminIframe.vue';

export default {
  components: { AdminIframe },
  computed: {
    remoteSrc() {
      return process.env.VUE_APP_ADMIN_REMOTE_URL + '/popup/variable-guide';
    },
  },
  methods: {
    receiveMessage(e) {
      if (e.data.result === 'SUCCESS') {
        window.opener.postMessage({ successChangeGroupManagementCode: true });
        window.close();
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  },
};
