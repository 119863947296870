
import { Component, Vue } from 'vue-property-decorator';
import {
  GetKakaoAutoTemplateTypes,
  GetKakaoAutoTemplateTypesRequest,
  GetSmsTemplatesAutoGroupsRequest,
  NCPResponse,
  SmsTemplatesAutoGroup,
} from 'ncp-api-supporter';
import { formatSmsTemplates, formatTemplateGroups } from '@/views/contents/member/sms/mixins/SmsAutomationGetTemplate';
import { getCurrentMallNo } from '@/utils/mall';

@Component
export default class SmsAutomationTemplate extends Vue {
  public templateGroups: SmsTemplatesAutoGroup[] = [];

  private async fetchSmsTemplates(mallNo: number): Promise<void> {
    const request: GetSmsTemplatesAutoGroupsRequest = { params: { mallNo } };
    try {
      const { data }: NCPResponse<SmsTemplatesAutoGroup[]> = await this.$api.getSmsTemplatesAutoGroups(request);
      this.templateGroups = formatSmsTemplates(data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private async fetchKakaoTemplates(mallNo): Promise<void> {
    mallNo = mallNo ?? getCurrentMallNo(this);
    const request: GetKakaoAutoTemplateTypesRequest = { params: { mallNo } };
    try {
      const { data }: NCPResponse<GetKakaoAutoTemplateTypes[]> = await this.$api.getKakaoAutoTemplateTypes(request);
      this.templateGroups = formatTemplateGroups(data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async getTemplateGroups(type: 'sms' | 'kakao' = 'sms', mallNo: number = getCurrentMallNo(this)) {
    if (type === 'sms') {
      await this.fetchSmsTemplates(mallNo);
    } else {
      await this.fetchKakaoTemplates(mallNo);
    }
  }
}
