import { api } from '@/api';
import {
  CampaignsAccountsAccessUsers,
  CampaignsAccountsAccessUsersType,
  CampaignsAccountsAccessRole,
} from 'ncp-api-supporter';

interface EmailMasking {
  email: string;
  accessRole: CampaignsAccountsAccessRole;
}

const authorityMenuNo = 8406;
//구글 OAuth2 로그인 라이브러리 andpoint
export const gsiUrl = 'https://accounts.google.com/gsi/client';
//우리가 만든 구글 ouath 클라이언트 아이디
export const clientId = '318516554720-acqle85jksqvk832bqeep300gkmcp1f5.apps.googleusercontent.com';

export const popupClose = () => window.close();

const isEmailMasking = async (): Promise<boolean> => {
  const { data } = await api.getManagerInfo();
  if (data.role === 'MASTER') return false;

  const authorityGroupsRes = await api.getAuthorityGroupsByGroupNo({
    pathParams: {
      authorityGroupNo: data.authority.no.toString(),
    },
  });

  const menuAuthorities = authorityGroupsRes.data.menuAuthorities;
  const authorityMenu = menuAuthorities.find(item => item.menuNo === authorityMenuNo);

  return !authorityMenu.writable;
};

const setEmailMasking = (users: CampaignsAccountsAccessUsers[]): EmailMasking[] => {
  return users.map(user => {
    const emailStr = user.email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    const strLength = emailStr.toString().split('@')[0].length - 3;
    const email = user.email.replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*');

    return {
      email,
      accessRole: user.accessRole,
    };
  });
};

export const getAccessUsers = async (
  mallNo: number,
  userType: CampaignsAccountsAccessUsersType,
): Promise<EmailMasking[] | CampaignsAccountsAccessUsers[]> => {
  const request = {
    params: {
      mallNo,
      userType,
    },
  };

  const {
    data: { users },
  } = await api.getCampaignsAccountsAccessUsers(request);
  const isMaksingEmail = await isEmailMasking();
  return isMaksingEmail ? setEmailMasking(users) : users;
};
