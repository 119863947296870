







































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Amounts } from 'ncp-api-supporter/dist/types';
import { isReturnClaim, isReturnExchange, isReturnProcRequestRefund } from '@/components/popup/claim/claimConditions';
import { claimTypes, responsibleObjectTypes } from '@/const/claim';
import { ClaimClassType, ResponsibleObjectType } from '@/types/claim';
import { Shipping } from 'ncp-api-supporter';
import { DeliveryCalculator } from '@/components/popup/claim/ClaimPopup/calculator';
import AmountMixin from '@/components/popup/claim/AmountMixin';

@Component({
  computed: {
    isReturnClaim,
    isReturnExchange,
    isReturnProcRequestRefund,
    hasRefundHoldReason() {
      return this.refundHoldReason !== null;
    },
    isSellerResponsible() {
      return this.responsibleObjectType === responsibleObjectTypes.SELLER;
    },
    beforeDeliveryLineThrough() {
      const { alreadyPaidTotalDeliveryAmt, totalDeliveryAmt } = this.amounts.before.delivery.deliveryGroupAmounts;

      return alreadyPaidTotalDeliveryAmt !== totalDeliveryAmt && this.delivery.prepaid ? 'line-through' : 'none';
    },
    afterDeliveryLineThrough() {
      if (this.isSellerResponsible && this.sellerPaysClaimedDelivery) {
        return this.amounts.returnDelivery?.processedDeliveryAmt > 0 ? 'line-through' : 'none';
      }
      return 'none';
    },
    differenceLineThrough() {
      if (this.isExchangeClaim) {
        return 'none';
      }
      if (this.isSellerResponsible && this.sellerPaysClaimedDelivery && this.delivery.difference < 0) {
        return 'line-through';
      }

      return 'none';
    },
  },
})
export default class ReturnAmount extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly claimClassType!: ClaimClassType;

  @Prop({ required: true })
  private readonly responsibleObjectType!: ResponsibleObjectType;

  @Prop({ required: true })
  private readonly amounts!: Amounts;

  @Prop({ required: true })
  private readonly shipping!: Shipping;

  @Prop({ required: true })
  private readonly sellerPaysClaimedDelivery!: boolean;

  @Prop({ required: true })
  private readonly refundAdjustAmt!: number;

  @Prop({ required: true })
  private readonly refundHoldReason!: string;

  private deliveryCalculator: DeliveryCalculator = null;

  // 반품상품금액 row
  private returnProductAmt = {
    totalProductAmt: 0, // 상품합계
    // additionalDiscountAmt: 0, // 추가할인 오픈스펙제외
    productCouponDiscountAmt: 0, // 상품쿠폰

    get totalReturnProductAmt(): number {
      // return this.totalProductAmt - this.additionalDiscountAmt - this.productCouponDiscountAmt; 추가할인 오픈스펙제외
      return this.totalProductAmt - this.productCouponDiscountAmt;
    },
  };

  // 반품배송비차감 row
  private returnDelivery = {
    prepaid: false,
    deliveryAmt: 0, // 기본배송비,
    remoteDeliveryAmt: 0, // 지역별추가
    adjustAmt: 0, // 금액조정

    get returnDeliveryDeduction() {
      // 반품배송비차감
      return this.deliveryAmt + this.remoteDeliveryAmt - this.adjustAmt;
    },
  };

  // 장바구니쿠폰재계산
  private cartCoupon = {
    cartCouponDiscountAmt: 0,
  };

  // 배송비차액(+) row
  private delivery = {
    prepaid: false,
    totalDeliveryAmt: 0, // 교환 조정전 배송비
    adjustAmt: 0, // 조정금액,
    sellerPaysClaimedDelivery: false, // 판매자 부담 여부
    afterTotalDeliveryAmt: 0, // 변경후배송비,
    beforeTotalDeliveryAmt: 0, // 변경전배송비,
    difference: 0, // 배송비 차액

    get hasAdjustAmtAndSellerPays(): boolean {
      return this.adjustAmt > 0 && this.sellerPaysClaimedDelivery;
    },
  };

  created() {
    this.deliveryCalculator = new DeliveryCalculator({
      claimType: claimTypes.RETURN,
      claimClassType: this.claimClassType,
      amounts: this.amounts,
    });
    this.setExchangeAmountData();
  }

  private setExchangeAmountData() {
    this.setReturnProductAmt();
    this.setReturnDelivery();
    this.setCartCoupon();
    this.setDelivery();
  }

  private setReturnProductAmt() {
    const { order, coupon } = this.amounts.refund;
    this.returnProductAmt.totalProductAmt = order.standardAmt - order.immediateDiscountAmt;
    // this.returnProductAmt.additionalDiscountAmt = order.additionalDiscountAmt; 추가할인 오픈스펙제외
    this.returnProductAmt.productCouponDiscountAmt = coupon.productCouponDiscountAmt;
  }

  private setReturnDelivery() {
    this.returnDelivery.prepaid = this.shipping.prepaid;
    this.returnDelivery.deliveryAmt = this.amounts.returnDelivery.returnDeliveryAmt;
    this.returnDelivery.remoteDeliveryAmt = this.amounts.returnDelivery.returnRemoteDeliveryAmt;
    this.returnDelivery.adjustAmt = this.amounts.returnDeliveryAdjustAmt;
  }

  private setCartCoupon() {
    this.cartCoupon.cartCouponDiscountAmt = this.amounts.refund.coupon.cartCouponDiscountAmt;
  }

  private setDelivery() {
    this.delivery.prepaid = this.shipping.prepaid;
    this.delivery.sellerPaysClaimedDelivery = this.sellerPaysClaimedDelivery;
    this.delivery.totalDeliveryAmt = this.amounts.refund.delivery.totalDeliveryAmt;
    this.delivery.beforeTotalDeliveryAmt = this.deliveryCalculator.beforeDeliveryAmt();
    this.delivery.afterTotalDeliveryAmt = this.deliveryCalculator.afterDeliveryAmt();
    this.delivery.difference = this.deliveryCalculator.deliveryDifference();
  }

  // 반품교환의 조정 전 금액
  /**
   삭선조건 히스토리: https://nhnent.dooray.com/project/posts/2784101162368723396
   금액을 인자 받아야해서 method로 제작
   */
  private lineThrough(amounts: number): 'line-through' | 'none' {
    const isSellerResponsible = this.responsibleObjectType === responsibleObjectTypes.SELLER;
    const hasAmount = amounts > 0;

    return isSellerResponsible && hasAmount ? 'line-through' : 'none';
  }
}
