












































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  selectOptions,
  STOCK_STATUS_TYPE,
  convertToI18n,
  defaultOptionsToModify,
  isEmptyValue,
  isMoreThanImmediateDiscountPrice,
  isNotTenUnit,
  SEPARATOR,
  defaultMappingInfo,
  removeEmptyValueOfObject,
  validateOptionPrice,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import {
  ProductOptionType,
  ProductOptionInfo,
  OptionsToModifyType,
  CombinationOptionType,
  ValidationFuncType,
  InputNumber,
  MappingType,
} from '@/types';
import { isValidate } from '@/utils/validate';
import { YorN } from 'ncp-api-supporter/dist/types/enum';
import { OptionRegistrationType } from '@/views/popups/product/productAdd/ProductOption.vue';
import NormalOptionTable from '@/views/popups/product/productAdd/ProductOption/CombinationOption/NormalOptionTable.vue';
import SetOptionTable from '@/views/popups/product/productAdd/ProductOption/CombinationOption/SetOptionTable.vue';
import { formatCurrency } from '@/utils/numberFormat';
import { unescapeHtml } from '@/utils/common';

@Component({
  components: {
    NormalOptionTable,
    SetOptionTable,
    SelectBox,
    TextInput,
  },
})
export default class CombinationOptionList extends Vue {
  @Prop({ required: true }) private readonly mallNo!: number;
  @Prop({ required: true }) private readonly useReservation!: boolean;
  @Prop({ required: true }) private readonly immediateDiscountPrice!: InputNumber;
  @Prop({ required: true }) private readonly hasSupplyPrice!: boolean;
  @Prop({ required: true }) private readonly optionType!: () => keyof typeof OptionRegistrationType;
  @Prop({ required: true }) private readonly isMapping!: boolean;
  @Prop({ required: true }) private readonly deliveryTemplateNo!: number;
  @Prop({ required: true }) private readonly isMaster: boolean;
  @Prop({ required: true }) private readonly isSlave: boolean;
  @Prop({ required: true }) private readonly isSelectable!: boolean;
  @Prop({ required: true }) private readonly isFinishedReservationSales!: boolean;
  @Prop({ required: false, default: false }) private readonly isReadonly!: boolean;

  private inputOptionList: ProductOptionType[] = [];
  private inputOptionInfoList: ProductOptionInfo[][] = [];
  private selectBoxOptions = selectOptions;
  private combinationOptionList: CombinationOptionType<string[]>[] = [];
  private imageBtnIndexes: number[] = [];
  private selectedOptions: number[] = [];
  private optionsToModify: OptionsToModifyType = { ...defaultOptionsToModify };
  private validateOptionPriceLength = validateOptionPrice;

  @Ref() private readonly setOptionTableRef!: SetOptionTable;
  @Ref() private readonly normalOptionTableRef!: NormalOptionTable;

  public resetSelectedOptions(): void {
    this.selectedOptions = [];
  }

  public get combinationOptionListLength(): number {
    return this.combinationOptionList.length;
  }

  public updateOptionData(
    optionList: CombinationOptionType<string[]>[],
    imageBtnIndexes: number[],
    isSetMappingOption = false,
  ): void {
    this.inputOptionList = optionList[0].optionName.split(SEPARATOR).map(optionName => ({ optionName }));
    this.combinationOptionList = optionList;
    this.imageBtnIndexes = imageBtnIndexes;
    this.selectedOptions = [];
    isSetMappingOption && this.$nextTick(this.setOptionTableRef.setMappingOptionData);
  }

  public validateParentValue() {
    if (this.useReservation === false) {
      this.combinationOptionList.forEach(option => this.$set(option, 'reservationStockCnt', 0));
    }

    if (this.hasSupplyPrice === false) {
      this.combinationOptionList.forEach(option => this.$set(option, 'purchasePrice', 0));
    }
  }

  public resetCombinationOptions(): void {
    this.combinationOptionList = [];
    this.inputOptionList = [];
  }

  public validateOptionList(): boolean {
    if (!this.isMapping) {
      this.changeSaleStatusType();
    }

    if (isEmptyValue(this.inputOptionList, 'optionName')) {
      alert(convertToI18n('EMPTY_OPTION_NAME_MESSAGE'));
      return false;
    }

    if (this.isEmptyOptionValue()) {
      alert(convertToI18n('EMPTY_OPTION_VALUE_MESSAGE'));
      return false;
    }

    if (this.validateOptionPrice(isNotTenUnit)) {
      alert(convertToI18n('TIP_OPTION_PRICE_10'));
      return false;
    }

    if (this.isMapping) {
      return this.validateSetOptionList();
    }

    if (this.validateOptionPrice(isMoreThanImmediateDiscountPrice.bind(this))) {
      alert(convertToI18n('OPTION_PRICE_MESSAGE'));
      return false;
    }

    return true;
  }

  public async validateConfigurationOption() {
    await this.setOptionTableRef.validateConfigurationOption(this.combinationOptionList);
  }

  private validateSetOptionList(): boolean {
    const isRegisteredConfigurationOption = this.setOptionTableRef.isRegisteredConfigurationOption.every(
      registeredConfigurationOption => registeredConfigurationOption,
    );

    if (!isRegisteredConfigurationOption) {
      alert(convertToI18n('PLEASE_MORE_1'));
      return false;
    }

    const MAX_ADD_PRICE = 1000000000;
    const isInvalidPrice = this.combinationOptionList.some(({ addPrice }) => addPrice > MAX_ADD_PRICE);
    if (isInvalidPrice === true) {
      alert(convertToI18n('PLEASE_UNDER_10_BILLION'));
      return false;
    }
    return true;
  }

  public getCombinationOptionList(): CombinationOptionType<string>[] {
    const optionName = this.inputOptionList.map(({ optionName }) => optionName).join(SEPARATOR);
    return this.combinationOptionList.map(({ saleStatusType, optionValue, optionName: _, ...restOption }) => ({
      ...restOption,
      forcedSoldOut: saleStatusType === STOCK_STATUS_TYPE.OUT_OF_STOCK,
      optionValue: optionValue.join(SEPARATOR),
      optionName,
    }));
  }

  private changeSaleStatusType(): void {
    this.combinationOptionList.forEach(({ stockCnt }, index) => {
      stockCnt === 0 && this.$set(this.combinationOptionList[index], 'saleStatusType', STOCK_STATUS_TYPE.SOLD_OUT);
    });
  }

  private validateOptionPrice(validationCallBack: ValidationFuncType): boolean {
    return this.combinationOptionList.some(({ addPrice }) => validationCallBack(addPrice));
  }

  private isEmptyOptionValue(): boolean {
    return this.combinationOptionList.some(({ optionValue }) => optionValue.some(value => value === ''));
  }

  public combineOptions(inputOptionList: ProductOptionType[]): void {
    this.inputOptionList = JSON.parse(JSON.stringify(inputOptionList));
    this.inputOptionInfoList = this.inputOptionList.map(({ optionInfoList }) => optionInfoList);
    this.imageBtnIndexes = [];
    const defaultOptionInfo = {
      addPrice: 0,
      purchasePrice: 0,
      optionValue: [],
      stockCnt: 0,
      optionManagementCd: '',
    };

    const combinationOptionList = this.generateCombinationOptions(defaultOptionInfo, 0);
    const maxCount = this.isMapping ? 50 : 1000;
    if (combinationOptionList.length > maxCount) {
      alert(this.$t('PRODUCT.OPTION.MAX_OPTION_COUNT_MESSAGE', [formatCurrency(maxCount)]));
    } else {
      this.combinationOptionList = combinationOptionList;
    }
  }

  private generateCombinationOptions(
    combinedOptionInfo: CombinationOptionType<string[]>,
    key: number,
    combinationOptionList = [],
  ): CombinationOptionType<string[]>[] {
    this.inputOptionInfoList[key].forEach((currentOptionInfo: ProductOptionInfo) => {
      const addedOption = this.getAddedOption(combinedOptionInfo, currentOptionInfo, combinationOptionList);

      if (key + 1 < this.inputOptionInfoList.length) {
        this.generateCombinationOptions(addedOption, key + 1, combinationOptionList);
      } else {
        const defaultCombinationOption = {
          optionImages: [],
          mallOptionNo: 0,
          reservationStockCnt: 0,
          useYn: 'Y' as YorN,
          stockNo: 0,
        };
        let combinationOption = { ...defaultCombinationOption, ...addedOption, optionName: this.combinedOptionName };
        if (this.isMapping) {
          combinationOption = { ...combinationOption, ...this.getMappingData() };
        }
        combinationOptionList.push(combinationOption);
      }
    });
    return combinationOptionList;
  }

  private getMappingData(): { mappings: MappingType[] } {
    return { mappings: [defaultMappingInfo()] };
  }

  private getAddedOption(
    combinedOptionInfo: CombinationOptionType<string[]>,
    currentOptionInfo: ProductOptionInfo,
    combinationOptionList,
  ): CombinationOptionType<string[]> {
    const { addPrice, purchasePrice, optionValue, stockCnt, optionManagementCd } = combinedOptionInfo;
    this.removeNullValue(currentOptionInfo);
    const {
      optionPrice,
      supplyPrice,
      stockCnt: currentStockCnt,
      managementCd,
      optionValue: currentValue,
    } = currentOptionInfo;

    const addedValue = optionValue.length
      ? [...optionValue, currentValue]
      : this.getFirstValueArray(currentValue, combinationOptionList);
    const addedStockCnt = stockCnt + currentStockCnt;
    return {
      addPrice: Number(addPrice) + Number(optionPrice),
      purchasePrice: purchasePrice + supplyPrice,
      optionValue: addedValue.map(value => unescapeHtml(value)),
      stockCnt: addedStockCnt,
      optionManagementCd: optionManagementCd + managementCd,
      saleStatusType: addedStockCnt ? STOCK_STATUS_TYPE.AVAILABLE : STOCK_STATUS_TYPE.SOLD_OUT,
    };
  }

  private removeNullValue(optionInfo: ProductOptionInfo) {
    for (const key in optionInfo) {
      if (optionInfo[key] === null) {
        this.$set(optionInfo, key, 0);
      }
    }
  }

  private getFirstValueArray(currentValue: string, combinationOptionList): string[] {
    this.imageBtnIndexes.push(combinationOptionList.length);
    return [currentValue];
  }

  //TODO 저장버튼 누를때 재사용
  private get combinedOptionName(): string {
    return this.inputOptionList.map(({ optionName }) => optionName).join(SEPARATOR);
  }

  private get selectedOptionLength(): number {
    return this.selectedOptions.length;
  }

  private get isSelectedOption(): boolean {
    const message = this.isMapping ? 'SET_NO_DATA_SELECTED' : 'NO_DATA_SELECTED';
    return isValidate(this.selectedOptionLength !== 0, convertToI18n(message));
  }

  private deleteSelectedOption(): void {
    if (!this.isSelectedOption) return;
    const deleteConfirm = confirm(
      this.$t('PRODUCT.OPTION.DELETE_OPTION_MESSAGE', [this.selectedOptionLength]).toString(),
    );
    if (!deleteConfirm) return;

    this.selectedOptions.forEach(selectedOption => {
      if (this.isDeletable(selectedOption) === false) return;

      delete this.combinationOptionList[selectedOption];
      if (this.isMapping) {
        this.setOptionTableRef.deleteConfigurationOption(selectedOption);
      }
    });

    this.combinationOptionList = this.combinationOptionList.filter(_ => true);
    if (this.combinationOptionListLength === 0) {
      this.inputOptionList = [];
    }
    this.resetSelectedOptions();
  }

  private isDeletable(index: number): boolean {
    if (this.combinationOptionList[index].deletable === false) {
      alert(convertToI18n('DELETABLE_N'));
      return false;
    }
    return true;
  }

  private onClickBatchModification() {
    if (!this.isSelectedOption) return;
    if (this.optionsToModify.addPrice % 10 !== 0) {
      return alert(convertToI18n('TIP_OPTION_PRICE_10'));
    }
    const optionsToModify = removeEmptyValueOfObject(this.optionsToModify);

    let isAlert = false;
    this.selectedOptions.forEach(selectedOption => {
      const copiedOption = { ...optionsToModify };
      const { stockCnt } = this.combinationOptionList[selectedOption];
      if (this.isAvailable(optionsToModify.saleStatusType) && stockCnt === 0) {
        isAlert = true;
        delete copiedOption.saleStatusType;
      }
      Object.assign(this.combinationOptionList[selectedOption], copiedOption);
    });
    if (isAlert) {
      alert(convertToI18n('SOLD_OUT_MODIFICATION'));
    }
    this.updateStockStatus(optionsToModify);
    this.optionsToModify = { ...defaultOptionsToModify };
    this.resetSelectedOptions();
  }

  private updateStockStatus(optionsToModify: Partial<OptionsToModifyType>): void {
    const hasSaleStatusType = Object.prototype.hasOwnProperty.call(optionsToModify, 'saleStatusType');
    const hasStockCnt = Object.prototype.hasOwnProperty.call(optionsToModify, 'stockCnt');

    if (!this.isMapping && hasStockCnt) {
      this.normalOptionTableRef.updateStockStatus('setSaleStatusType');
    }

    if (hasSaleStatusType) {
      const ref = this.isMapping ? 'setOptionTableRef' : 'normalOptionTableRef';
      this[ref].updateStockStatus('setStockCnt');
    }
  }

  private isAvailable(saleStatusType: keyof typeof STOCK_STATUS_TYPE): boolean {
    return saleStatusType === STOCK_STATUS_TYPE.AVAILABLE || saleStatusType === STOCK_STATUS_TYPE.OUT_OF_STOCK;
  }
}
