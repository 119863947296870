























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OrderInformation extends Vue {
  @Prop({ required: true })
  private readonly ordererName!: string;

  @Prop({ required: true })
  private readonly ordererEmail!: string;

  @Prop({ required: true })
  private readonly ordererContact1!: string;

  @Prop({ required: true })
  private readonly mallName!: string;

  @Prop({ required: true })
  private readonly orderYmdt!: string;
}
