import { RadioBoxGroupOption } from '@/helpers/type';
import { NAVER_PRODUCT_SALE_TYPE, NAVER_PRODUCT_STATUS } from '@/const/contents/product';
import { CheckBoxGroupOption } from '@/helpers/type';

export const SINGLE_ITEM_SELECT_LENGTH = 1;
export const MONTHLY_RENTAL_FEE_MAX_LENGTH = 9;
export const RENTAL_CONTRACT_PERIOD_MAX_LENGTH = 3;
export const COMPARE_PRICE_PAGE_ID_MAX_LENGTH = 50;
export const CATEGORY_ID_MAX_LENGTH = 8;

export const brandCertifyRadioOptions: RadioBoxGroupOption<string> = {
  name: 'createEpOption',
  data: [
    {
      id: 'not-certified',
      name: 'not-certified',
      value: 'N',
      display: 'MEMBER.LIST.NOT_CERTIFY',
    },
    {
      id: 'certified',
      name: 'certified',
      value: 'Y',
      display: 'MARKETING.NAVER_SHOPPING.CERTIFICATION',
    },
  ],
};

export const selectBoxOptions = () => {
  const naverProductSaleTypeOptions = keys => {
    return keys.map(key => ({ name: `PRODUCT.ADD.${key}`, value: key }));
  };

  return {
    naverProductSaleType: [...naverProductSaleTypeOptions(Object.keys(NAVER_PRODUCT_SALE_TYPE))],
    naverProductStatus: [...naverProductSaleTypeOptions(Object.keys(NAVER_PRODUCT_STATUS))],
  };
};

export const naverPayCheckBoxOptions: CheckBoxGroupOption<string> = {
  name: 'naver_pay',
  hasAll: false,
  noBrackets: false,
  data: [
    { id: 'naverPayPC', value: 'PC', display: 'MARKETING.NAVER_SHOPPING.INDICATION_PC' },
    { id: 'naverPayMobile', value: 'MOBILE', display: 'MARKETING.NAVER_SHOPPING.INDICATION_MOBILE' },
  ],
};

export const naverAccumulationCheckBoxOptions: CheckBoxGroupOption<string> = {
  name: 'naver_pay',
  hasAll: false,
  noBrackets: true,
  data: [
    { id: 'naverAccumulationPC', value: 'PC', display: 'MARKETING.NAVER_SHOPPING.INDICATION_PC' },
    { id: 'naverAccumulationMobile', value: 'MOBILE', display: 'MARKETING.NAVER_SHOPPING.INDICATION_MOBILE' },
  ],
};
