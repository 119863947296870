







import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProExcelCreateMenu } from 'ncp-api-supporter';
import { createExcel } from '@/components/common/grid/ExcelCreateUtil';
import { TranslateResult } from 'vue-i18n';

@Component
export default class ExcelCreateButton extends Vue {
  /**
   * @excelCreateMenuKey
   *
   * PostExcelsRequest.params.menu 로 들어갈 값
   */
  @Prop({ required: false, default: null })
  private readonly excelCreateMenuKey!: ProExcelCreateMenu | undefined;
  @Prop({ required: true })
  private readonly hasData!: boolean;
  @Prop({ required: true })
  private readonly createExcelFileQuery: { [key: string]: any }; // eslint-disable-line
  @Prop({ required: false })
  private readonly extraInfo!: string;
  @Prop({ required: false })
  private readonly buttonName!: string;

  get label(): TranslateResult {
    return this.buttonName ? this.$t(this.buttonName) : this.$t('CREATE');
  }

  get menuKey(): ProExcelCreateMenu {
    return this.excelCreateMenuKey ?? this.$route.meta.excelCreateMenuKey ?? null;
  }

  private onCreateExcel(): void {
    createExcel(this.hasData, this.menuKey, this.createExcelFileQuery, this.extraInfo);
  }
}
