import { i18n } from '@/main';
// types
import {
  PartnerRendererProps,
  RendererMapByContractStatus,
} from '@/views/contents/partner/modification/PartnerModificationTypes';
import { Contracts, PartnerStatus } from 'ncp-api-supporter';
import { CellRenderer } from '@/types/tui-grid/renderer';
// htmls
import { getGridAnchor, getGridTextSpan } from '@/views/contents/partner/modification/grid/gridHTMLs';
// popup
import { throwPartnerContractFilesPopup } from '@/views/contents/partner/modification/popup/PartnerModificationPopup';

const renderText = (text: string) => {
  return {
    getElement(): HTMLElement {
      return getGridTextSpan(text);
    },
    render() {
      return;
    },
  };
};

const renderOpenContractFilesPopupText = (contractNo: number, contractStatus: PartnerStatus, mallNo: number) => {
  const anchorText = (contractStatus === 'ACTIVE' ? `${i18n.t('VIEW')}/${i18n.t('CHANGE')}` : i18n.t('VIEW')) as string;

  return {
    getElement(): HTMLElement {
      return getGridAnchor({
        anchorText,
        handleClick: () => throwPartnerContractFilesPopup(contractNo, contractStatus, mallNo),
      });
    },
    render() {
      return;
    },
  };
};

export default (props: PartnerRendererProps<'contractFiles'>): CellRenderer => {
  const contractNo = props.grid.getValue<Contracts, 'contractNo'>(props.rowKey, 'contractNo');
  const contractStatus = props.grid.getValue<Contracts, 'contractStatus'>(props.rowKey, 'contractStatus');
  const mallNo = props.grid.getValue<Contracts, 'mallNo'>(props.rowKey, 'mallNo');

  const rendererMap: RendererMapByContractStatus<'contractFiles'> = {
    WAITING() {
      return renderText('-');
    },

    INVESTIGATION() {
      return renderOpenContractFilesPopupText(contractNo, 'INVESTIGATION', mallNo);
    },

    ACTIVE() {
      return renderOpenContractFilesPopupText(contractNo, 'ACTIVE', mallNo);
    },
  };
  return rendererMap[contractStatus]();
};
