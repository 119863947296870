



















import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator';
import {
  AdminsService,
  PostAdminsTempServiceDepartmentsRequest,
  PostAdminsTempServiceJobDutiesRequest,
} from 'ncp-api-supporter';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import AdminForm from '@/views/contents/configuration/management/adminChild/AdminForm.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import AdminPrivacyTerms from '@/components/configuration/AdminPrivacyTerms.vue';

// TODO API 확인
// TODO validate 로직
// TODO 대표 운영자, 상품 담당자 여부 확인

@Component({ components: { AdminPrivacyTerms, AdminForm, TextInput, CheckboxGroup } })
export default class AdminRegister extends Vue {
  @Prop() private tempCode;

  @PropSync('adminInfo') private adminInfoSync: AdminsService;

  @Ref('adminForm')
  private adminForm: AdminForm;

  private password = '';
  private smsAuthKey = '';

  private privacyTermsAgree = false;

  private isMaster(): boolean {
    return this.adminInfoSync.adminRole === 'MASTER';
  }

  created() {
    this.throwBottomNavigation();
  }

  private putAdminsTempService(): Promise<boolean> {
    const request = {
      data: {
        tempCode: this.tempCode,
        adminId: this.adminInfoSync.adminId,
        password: this.password,
        passwordConfirm: this.password,
        jobPositionName: this.adminInfoSync.jobPositionName,
        mobileNo: this.adminInfoSync.contact.mobileNo,
        phoneNo: this.adminInfoSync.contact.phoneNo,
        smsAuthKey: this.smsAuthKey,
        departmentNo: this.adminInfoSync.department.no,
        jobDutyNo: this.adminInfoSync.jobDuty.no,
        externalAccessEnabled: this.adminInfoSync.externalAccessEnabled,
      },
    };

    if (!this.adminInfoSync.department.no) {
      delete request.data.departmentNo;
    }
    if (!this.adminInfoSync.jobDuty.no) {
      delete request.data.jobDutyNo;
    }

    return this.$api
      .putAdminsTempService(request)
      .then(response => {
        if (response && response.status === 204) {
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private async createDepartment() {
    const request: PostAdminsTempServiceDepartmentsRequest = {
      data: {
        tempCode: this.tempCode,
        departmentName: this.adminInfoSync.department.name,
      },
    };
    try {
      const { data } = await this.$api.postAdminsTempServiceDepartments(request);
      this.adminInfoSync.department = {
        name: data.name,
        no: data.no,
      };
    } catch (e) {
      console.error(e);
    }
  }
  private async createJobDuty() {
    const request: PostAdminsTempServiceJobDutiesRequest = {
      data: {
        tempCode: this.tempCode,
        jobDutyName: this.adminInfoSync.jobDuty.name,
      },
    };
    try {
      const { data } = await this.$api.postAdminsTempServiceJobDuties(request);
      this.adminInfoSync.jobDuty = {
        name: data.name,
        no: data.no,
      };
    } catch (e) {
      console.error(e);
    }
  }

  private async checkCreatedInfo() {
    if (this.adminInfoSync.department.name && !this.adminInfoSync.department.no) {
      await this.createDepartment();
    }
    if (this.adminInfoSync.jobDuty.name && !this.adminInfoSync.jobDuty.no) {
      await this.createJobDuty();
    }
  }

  private async save() {
    if (!this.adminForm.validate()) {
      return;
    }

    if (!this.privacyTermsAgree) {
      alert(this.$t('CONFIGURATION.ADMIN.ALERT_CHECK_PRIVACY_TERMS_AGREE'));
      return;
    }

    await this.checkCreatedInfo();

    const success = await this.putAdminsTempService();
    if (success) {
      this.$emit('updateStatus');
    }
  }

  private throwBottomNavigation(): void {
    const buttons = [];
    buttons.push({
      type: 'right',
      key: 'save',
      color: 'red',
      text: this.$t('SAVE'),
    });

    throwBottomNavigation({
      buttons,
      onClick: (key: string) => {
        switch (key) {
          case 'save':
            this.save();
            break;
        }
      },
    });
  }
}
