












import { Vue, Component } from 'vue-property-decorator';
import ItemCalendar from '@/components/partner/Settlement/ItemCalendar.vue';
import ItemTable from '@/components/partner/Settlement/ItemTable.vue';
import { GetSettlementsRequest, OrderFriendsPartnerType, GetSettlements } from 'ncp-api-supporter';

export interface SearchDetailData {
  mallNo: number;
  year: number;
  month: number;
  partnerType: string;
  settlementDate: string;
}

@Component({
  components: {
    ItemCalendar,
    ItemTable,
  },
})
export default class Settlements extends Vue {
  private settlements: GetSettlements = { contents: [], totalCount: 0 };
  private pageSize = 30;

  private searchDetailData: SearchDetailData = {
    mallNo: 0,
    year: 0,
    month: 0,
    partnerType: '',
    settlementDate: '',
  };

  // NOTE - 거의 프리미엄에서 가져옴
  private async searchDetail(page = 1, size?: number) {
    if (size) {
      this.pageSize = size;
    }

    const request: GetSettlementsRequest = {
      params: {
        mallNo: this.searchDetailData.mallNo,
        year: this.searchDetailData.year,
        month: this.searchDetailData.month,
        page,
        size: this.pageSize,
      },
    };
    if (this.searchDetailData.partnerType !== '') {
      request.params.partnerType = this.searchDetailData.partnerType as OrderFriendsPartnerType;
    }

    const { data } = await this.$api.getSettlements(request);
    this.settlements = data;
  }
}
