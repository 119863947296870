





















import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import Slick from 'vue-slick';
import axios from 'axios';

@Component({ components: { Slick } })
export default class ReturnImage extends Vue {
  @Prop() private readonly data: { initSlideIndex: number; imageUrls: string[] };

  @Prop() private onPositiveClick!: () => void;

  @Prop() private onNegativeClick!: () => void;

  @Ref() private readonly slick!: Slick;

  private slickOptions = {
    infinite: true,
    draggable: false,
    accessibility: false,
    variableWidth: true,
    slidesToShow: 1,
    arrows: true,
    dots: false,
    swipeToSlide: true,
    initialSlide: this.data.initSlideIndex,
  };

  private onDownloadImage(): void {
    const imgUrl = this.data.imageUrls[(this.slick as any).currentSlide()];
    const fileName = imgUrl.split('/').pop();

    axios({
      method: 'get',
      url: imgUrl,
      responseType: 'arraybuffer',
    })
      .then(response => {
        this.forceFileDownload(response, fileName);
      })
      .catch(() => alert(this.$t('CLAIM.MESSAGE.FAIL_IMAGE_DOWNLOAD')));
  }

  forceFileDownload(response: any, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
}
