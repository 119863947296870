



































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
@Component({
  components: { TextInput },
})
export default class ReturnShippingFee extends Vue {
  @Prop({ required: true }) private objJson!: string;
  @Prop({ required: true }) private postPredict!: {};
  @Prop({ default: false }) private escrowOrder!: boolean;
  @Prop({ default: '' }) private pageName!: string;
  @Prop({ default: 'SELLER_COLLECT' }) private returnWayType!: string;
  @Prop({ default: '' }) private refundType!: string;
  @Prop({ required: true }) private claimOrderOptions!: {};
  @Prop({ default: false }) private modifyClaim!: boolean;
  public returnDeliveryAmtAdjustAmt = 0;
  public returnDeliveryAmtAdjustReason = '';
  private textDecoration = 'none';
  private returnDeliveryAmtAdjustAmtDisabled = false;
  private returnDeliveryAmtAdjustReasonDisabled = false;
  private returnDeliveryAmt = 0;
  private returnRemoteDeliveryAmt = 0;
  private totalReturnDeliveryAmt = 0;
  private whoDoesPayReturnDeliveryAdjustAmt: string = this.$t('CLAIM.RETURNPOPUP.WHODOESPAY').toString();
  private disabled = true;
  private created() {
    this.onRefundTypeChanged();
    this.deliveryCalculation();
  }
  @Watch('postPredict')
  onPredictChange() {
    this.deliveryCalculation();
  }

  @Watch('refundType')
  onRefundTypeChanged() {
    if (this.pageName == 'ReturnPopup') {
      if (this.escrowOrder) {
        if (this.refundType == 'KCP') {
          this.returnDeliveryAmtAdjustAmt = 0;
          this.returnDeliveryAmtAdjustReason = '';
          this.returnDeliveryAmtAdjustAmtDisabled = true;
        } else if (this.refundType == 'CASH') {
          this.returnDeliveryAmtAdjustAmtDisabled = false;
          this.returnDeliveryAmtAdjustReasonDisabled = false;
        } else {
          this.returnDeliveryAmtAdjustAmtDisabled = false;
          this.returnDeliveryAmtAdjustReasonDisabled = false;
        }
      }
    }
  }

  private returnDeliveryAmtAdjustAmtChange() {
    if (!this.returnDeliveryAmtAdjustAmt) {
      this.returnDeliveryAmtAdjustAmt = 0;
      this.returnDeliveryAmtAdjustReason = '';
    }
    if (this.returnDeliveryAmtAdjustAmt > (this.postPredict as any).amounts.returnDelivery.totalPureReturnDeliveryAmt) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALERT_ADJUSTMENT_DELIVERY'));
      this.returnDeliveryAmtAdjustAmt = 0;
    }
    this.$emit('setData');
  }

  private deliveryCalculation() {
    const isPrepaid = (this.postPredict as any).shipping.prepaid;
    this.returnDeliveryAmt = (this.postPredict as any).amounts.returnDelivery.returnDeliveryAmt;
    this.returnRemoteDeliveryAmt = (this.postPredict as any).amounts.returnDelivery.returnRemoteDeliveryAmt;
    this.totalReturnDeliveryAmt =
      (isPrepaid ? this.returnDeliveryAmt + this.returnRemoteDeliveryAmt : 0) - this.returnDeliveryAmtAdjustAmt;

    if ((this.$parent.$refs.componentItemsClaim as any).responsibleObjectTypeValue === 'BUYER') {
      this.returnDeliveryAmtAdjustAmtDisabled = false;
      this.returnDeliveryAmtAdjustReasonDisabled = false;
      this.textDecoration = 'none';
    } else {
      this.returnDeliveryAmtAdjustAmtDisabled = true;
      this.returnDeliveryAmtAdjustReasonDisabled = true;
      this.textDecoration = 'line-through';
    }
    if (this.returnDeliveryAmt === 0) {
      this.returnDeliveryAmtAdjustReasonDisabled = true;
    }
    if (!isPrepaid) {
      this.returnDeliveryAmtAdjustAmtDisabled = true;
      this.returnDeliveryAmtAdjustReasonDisabled = true;
    }
  }

  public clearText(temp: number) {
    this.returnDeliveryAmtAdjustReason = '';
    this.returnDeliveryAmtAdjustAmt = temp;
  }

  protected onlyCommaNumber(event: Event) {
    const $input = event.target as HTMLInputElement;

    if ($input.value === '' || $input.value === '0') {
      $input.value = '0';
      return;
    }

    $input.value = $input.value
      .toString()
      .replace(/\D/g, '')
      .replace(/^0+/, '');
    // .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
