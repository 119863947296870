






























































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  computed: {
    isIpAccessControl(): boolean {
      return this.option.type === 'IpAccessControl';
    },
    isPartnerModification(): boolean {
      return this.option.type === 'PartnerIpAccessControl';
    },
  },
})
export default class AdminForm extends Vue {
  @Prop({ required: true })
  private readonly option;
}
