































import { Vue, Component, Watch } from 'vue-property-decorator';
import { GetAuthorityGroupsRequest } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import { getAuthorityGroupGridOption } from '@/const/contents/configuration/authorityGroup';
import { GridEventProps } from '@/types';
import { throwWindowPopup } from '@/helpers/popup';
import { setNoDataMessage } from '@/helpers/grid';

@Component({ components: { Grid } })
export default class AuthorityGroup extends Vue {
  private gridProps = getAuthorityGroupGridOption();
  private gridDisplayOptions = { hasExcelDownloadButton: false, hasSettingButton: false };

  private contents = [];
  private totalCount = 0;

  @Watch('$route')
  private onQueryStringChanged() {
    this.getAuthorityGroups();
  }

  created() {
    setNoDataMessage(this.$t('NO_RESULT').toString());
    this.getAuthorityGroups();
  }

  private getAuthorityGroups(): void {
    const query = { page: 1, pageSize: 30 };
    Object.assign(query, this.$route.query);

    const request: GetAuthorityGroupsRequest = {
      params: query,
    };

    this.$api.getAuthorityGroups(request).then(response => {
      if (response && response.status === 200) {
        this.contents = response.data.contents;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  // delete authority group
  private deleteAuthorityGroupsByGroupNo(authorityGroupNo): Promise<boolean> {
    return this.$api
      .deleteAuthorityGroupsByGroupNo({
        pathParams: {
          authorityGroupNo,
        },
      })
      .then(response => response && response.status === 204)
      .catch(() => false);
  }

  // delete authority group
  private deleteAuthorityGroup(authorityGroupNo: number): void {
    const authorityGroup = this.contents.filter(c => c.no === authorityGroupNo)[0];
    if (!authorityGroup) {
      return;
    }

    if (authorityGroup.adminCount > 0) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_CAN_NOT_DELETE'));
      return;
    }

    if (!confirm(this.$t('CONFIGURATION.AUTHORITY_GROUP.CONFIRM_DELETE') as string)) {
      return;
    }

    this.deleteAuthorityGroupsByGroupNo(authorityGroupNo + '').then(success => {
      if (success) {
        alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_SUCCESS_DELETE'));
        this.$router.go(0);
      }
    });
  }

  private onItemClicked(gridProps: GridEventProps): void {
    const target = (gridProps as any).nativeEvent.target as HTMLElement;

    switch (gridProps.columnName) {
      case 'name':
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        this.$router.push(`/configuration/management/authority-group/edit?groupNo=${gridProps.rowKey}`);
        break;
      case 'adminCount': {
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        throwWindowPopup(
          'AuthorityGroupMemberListPopup',
          {
            authorityGroupNo: gridProps.rowKey,
          },
          'md',
          null,
          '',
          { authorityGroupNo: gridProps.rowKey + '' },
        );

        break;
      }
      case 'copy':
        if (target.tagName.toUpperCase() !== 'A') {
          return;
        }

        if (this.totalCount >= 100) {
          alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_MAX_100'));
          return;
        }

        this.$router.push(`/configuration/management/authority-group/register?groupNo=${gridProps.rowKey}`);
        break;
      case 'delete':
        if (target.tagName.toUpperCase() !== 'BUTTON') {
          return;
        }

        this.deleteAuthorityGroup(Number(gridProps.rowKey));
        break;
    }
  }

  private addAuthorityGroup(): void {
    if (this.totalCount >= 100) {
      alert(this.$t('CONFIGURATION.AUTHORITY_GROUP.ALERT_MAX_100'));
      return;
    }

    this.$router.push('/configuration/management/authority-group/register');
  }
}
