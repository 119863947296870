








































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { StatisticSynthesisPeriod } from '@/types';
import {
  GetStatisticsSalesPeriodSummary,
  GetStatisticsSalesProductSummaryContents,
  ProExcelCreateMenu,
} from 'ncp-api-supporter';
import { createExcel } from '@/components/common/grid/ExcelCreateUtil';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

type SummaryProps = null | GetStatisticsSalesProductSummaryContents | GetStatisticsSalesPeriodSummary['summary'];
type SummaryKeys = keyof GetStatisticsSalesProductSummaryContents | keyof GetStatisticsSalesPeriodSummary['summary'];

@Component({
  components: {
    ToolTip,
  },
  filters: {
    isEmpty(salesByPeriodContentsPeriod: SummaryProps, keyName: SummaryKeys): '' | number {
      if (salesByPeriodContentsPeriod === null) {
        return '';
      }
      return salesByPeriodContentsPeriod[keyName];
    },
    comma(value: number | ''): string {
      if (value === '') {
        return '';
      }
      if (value === 0) {
        return '0';
      }
      return value.toLocaleString();
    },
  },
})
export default class StatisticSynthesis extends Vue {
  @Prop({ required: true, default: 'PERIOD' })
  private readonly statisticType: 'PERIOD' | 'PRODUCT' | 'CATEGORY';

  @Prop({ required: false, default: null })
  private readonly period: null | StatisticSynthesisPeriod;

  @Prop({ required: false, default: null })
  private readonly summary: null | SummaryProps;

  @Prop({ required: true })
  private readonly isInit: boolean;

  private colWidths = {
    PERIOD: ['100px', '100px', '180px', '180px', '150px', '120px', '120px', '200px', '120px'],
    PRODUCT: ['100px', '100px', '180px', '180px', '150px', '200px', '120px'],
    CATEGORY: ['100px', '100px', '180px', '180px', '150px', '200px', '120px'],
  };

  private saleAmountTooltipOption = {
    name: 'List',
    style: {
      position: 'absolute',
      left: '-250px',
      width: '500px',
    },
    listOption: {
      mainMessage: [
        this.$t('STATISTIC.SALES.SALES_AMT_TOOLTIP_SALE_AMT'),
        this.$t('STATISTIC.SALES.SALES_AMT_TOOLTIP_SALE_DISCOUNT'),
        // this.$t('STATISTIC.SALES.SALES_AMT_TOOLTIP_NAVER_PAY'),
      ],
    },
  };

  private promotionTooltipOption = { message: this.$t('STATISTIC.SALES.PROMOTION_TOOLTIP') };

  private totalSalesAmountTooltipOption = {
    style: {
      position: 'absolute',
      left: '-250px',
      width: '500px',
    },
    message: this.$t('STATISTIC.SALES.TOTAL_SALES_AMT_TOOLTIP'),
  };

  private downloadExcelTooltipOption = {
    style: {
      position: 'absolute',
      left: '-500px',
    },
    message: this.$t('STATISTIC.SALES.SALES_BY_DATE_EXCEL_DOWNLOAD_TOOLTIP'),
  };

  private onCreateExcel(): void {
    const menuKey = 'PRO_STATISTICS_SALE_PERIOD' as ProExcelCreateMenu;

    createExcel(true, menuKey, { ...this.$route.query, servicePlan: 'PRO' });
  }
}
