






import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AppAllIframe extends Vue {
  private get remoteSrc() {
    return process.env.VUE_APP_APP_STORE_URL + '/shopby?hideLayout=true';
  }
}
