



































import { Vue, Component, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { ToolTipOption } from '@/types/tooltip';

@Component({
  components: { NoticeBox },
})
export default class SmsCertCode extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption;
}
