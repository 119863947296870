





































































































































import { Component, Ref } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import deliveryCountry from '@/const/words/deliveryCountry';
import WindowPopupMain from '@/views/popups/Main.vue';
import { AreafeeDetail, PostAreafee } from 'ncp-api-supporter/dist/types/modules/delivery/areas';
import CurrencyTextInput from '@/components/common/CurrencyTextInput.vue';
import { InputNumber, Mode } from '@/types';
import { PopupClose, throwPopup } from '@/helpers/popup';
import { MODE, PARTNER_TYPE } from '@/const/common';
import SubmitButton from '@/components/SubmitButton.vue';
import SelectedAreaFee from '@/views/popups/configuration/delivery/SelectedAreaFee.vue';
import { getDefaultAreaQuery, i18nForDelivery } from '@/const/contents/configuration/shippingCharge';

@Component({
  components: { SelectedAreaFee, SubmitButton, Currency: CurrencyTextInput, SelectBox, TextInput, ToolTip },
})
export default class ShippingChargeAreaFeePopup extends WindowPopupMain {
  // data: { mode: MODE, areaFeeNo: number };

  @Ref()
  private readonly currencyRef: CurrencyTextInput;

  private readonly countries = deliveryCountry;
  private area = {} as PostAreafee;
  private selectedIdxList: number[] = [];

  private batchFee: InputNumber = '';

  private i18nForDelivery = i18nForDelivery;

  private get mode(): Mode {
    return this.data.mode;
  }

  private get isKorea(): boolean {
    return this.area?.countryCd === 'KR';
  }

  private get isAll(): boolean {
    if (this.area.details === undefined) return false;
    const { length } = this.area.details;
    return length > 0 && this.selectedIdxList.length === length;
  }

  private get isPartner(): boolean {
    return this.data.partnerType === PARTNER_TYPE.PARTNER;
  }

  private getClassName(defaultArea: boolean): string {
    return defaultArea ? 'standard' : '';
  }

  private originCd = '';
  private fetchAreaFeeByAreaFeeNo(type: 'init' | 'reset') {
    this.$api
      .getAreafeesByAreaFeeNo({
        params: {
          areaFeeNo: this.data.areaFeeNo.toString(),
        },
      })
      .then(res => {
        if (res && res.status === 200) {
          if (type === 'init') {
            this.originCd = res.data.countryCd;
          }
          this.area = Object.assign({}, this.area, getDefaultAreaQuery('', res.data, this.mode));
        }
      });
  }

  private fetchAreas() {
    const countryCd = this.area.countryCd;
    this.$api
      .getAreas({
        params: {
          countryCd,
        },
      })
      .then(res => {
        if (res && res.status === 200) {
          const newDetails = res.data.map(area => ({
            ...area,
            extraDeliveryAmt: 0,
          }));
          this.$set(this.area, 'details', newDetails);
        }
      });
  }

  protected created() {
    this.init('init');
  }

  private init(type: 'init' | 'reset', countryCd?: string) {
    const registerOtherCountry = type === 'reset' && countryCd !== this.originCd;
    if (registerOtherCountry || this.mode === MODE.REGISTER) {
      this.area = {
        ...getDefaultAreaQuery(countryCd ?? 'KR'),
        name: this.area.name,
        partnerNo: this.data.partnerNo,
      };
      // this.area = Object.assign({}, this.area, getDefaultAreaQuery(countryCd ?? 'KR'));
      this.fetchAreas();
      return;
    }
    this.fetchAreaFeeByAreaFeeNo(type);
  }

  private onChangeCountryCd(_, prev) {
    if (confirm(this.i18nForDelivery('CONFIRM_CHANGE_COUNTRY'))) {
      this.init('reset', this.area.countryCd);
      return;
    }
    this.$nextTick(() => (this.area.countryCd = prev));
  }

  private onChange({ target }: { target: HTMLInputElement }) {
    const { checked, value, name } = target;
    switch (name) {
      case 'rowCheck':
        this.checkboxHandler(checked, value);
        break;
    }
  }

  private setFees() {
    if (this.selectedIdxList.length === 0) {
      alert(this.i18nForDelivery('ALERT_SELECT_AREA'));
      return;
    }
    if (!this.batchFee) {
      alert(this.i18nForDelivery('ALERT_BATCH_FEE'));
      return;
    }

    this.selectedIdxList.forEach(idx => {
      this.$set(this.area.details[idx], 'extraDeliveryAmt', this.batchFee);
    });
    this.resetSelectedIdxAndBatchFee();
  }

  private delete() {
    let count = 0;
    this.selectedIdxList.forEach(selectedIdx => {
      const area = this.area.details[selectedIdx - count];
      if (!area.defaultArea) {
        this.$delete(this.area.details, selectedIdx - count);
        count++;
      }
    });
    this.resetSelectedIdxAndBatchFee();
  }

  private deleteArea() {
    if (this.selectedIdxList.length === 0) {
      alert(this.i18nForDelivery('ALERT_SELECT_AREA'));
      return;
    }

    const defaultArea = this.selectedIdxList.some(idx => this.area.details[idx].defaultArea);
    if (defaultArea && !confirm(this.i18nForDelivery('CONFIRM_DELETE_WITHOUT_STANDARD_AREAS'))) return;
    this.delete();
  }

  private addAreaFee(data: AreafeeDetail) {
    this.area.details.push(data);
    this.$nextTick(() => {
      const { length } = this.area.details;
      this.currencyRef[length - 1].focus();
    });
  }

  private openAddAreaForm() {
    throwPopup({
      name: 'AddArea',
      data: {
        countryCd: this.area.countryCd,
      },
    }).then(({ state, data }) => {
      if (PopupClose.CONFIRM === state) {
        this.addAreaFee({ ...data });
      }
    });
  }

  private checkboxHandler(checked: boolean, value: string) {
    value === 'all' ? this.checkedAll(checked) : this.checkedArea(checked, Number(value));
  }

  private checkedAll(checked: boolean) {
    if (checked) {
      this.selectedIdxList = this.area.details.map((_, idx) => idx);
      return;
    }
    this.selectedIdxList = [];
  }

  private checkedArea(checked: boolean, no: number) {
    if (checked) {
      this.selectedIdxList.push(no);
      return;
    }
    this.selectedIdxList = this.selectedIdxList.filter(areaNo => areaNo !== no);
  }

  private onCancel() {
    if (confirm(this.i18nForDelivery('CONFIRM_CANCEL'))) {
      this.onNegativeClick();
    }
  }

  private addAndCopyArea() {
    this.$api
      .postAreafees({
        data: {
          ...this.area,
        },
      })
      .then(res => {
        if (res && res.status === 201) {
          alert(this.$t('ALERT_SAVED'));
          this.onPositiveClick(res.data);
        }
      });
  }

  private putArea() {
    this.$api
      .putAreafeesByAreaFeeNo({
        params: {
          areaFeeNo: this.data.areaFeeNo,
        },
        data: {
          ...this.area,
        },
      })
      .then(res => {
        if (res && res.status === 204) {
          alert(this.$t('ALERT_UPDATED'));
          this.onPositiveClick();
        }
      });
  }

  private emptyExtraDeliveryAmtIdx(): number {
    return this.area.details.findIndex(detail => detail.extraDeliveryAmt !== 0 && !detail.extraDeliveryAmt);
  }
  private setDefaultAreasExtraDeliveryAmt() {
    this.area.details = this.area.details.map(detail => {
      if (detail.defaultArea && detail.extraDeliveryAmt.toString() === '') {
        return {
          ...detail,
          extraDeliveryAmt: 0,
        };
      }
      return detail;
    });
  }
  private isValidExtraDeliveryAmt(): boolean {
    this.setDefaultAreasExtraDeliveryAmt();
    const emptyExtraDeliveryAmtIdx = this.emptyExtraDeliveryAmtIdx();
    if (emptyExtraDeliveryAmtIdx > 0) {
      alert(this.i18nForDelivery('ALERT_EXTRA_AMT'));
      this.currencyRef[emptyExtraDeliveryAmtIdx].focus();
      return false;
    }
    return true;
  }

  private onSave() {
    if (this.area.name === '') {
      alert(this.i18nForDelivery('ALERT_NAME'));
      (this.$refs.templateName as TextInput).focus();
      return;
    }
    if (!this.isValidExtraDeliveryAmt()) return;
    this.mode !== MODE.EDIT ? this.addAndCopyArea() : this.putArea();
  }

  private resetSelectedIdxAndBatchFee() {
    this.selectedIdxList = [];
    this.batchFee = '';
  }
}
