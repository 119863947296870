




import { Vue, Component, Watch } from 'vue-property-decorator';
import TuiGrid from 'tui-grid';
import { Getter } from 'vuex-class';
import store from '@/store';
import { setNoDataMessage } from '@/helpers/grid';
import '@/assets/css/godo/utils.css';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN, SESSION_INITIALIZE } from '@/const/cookie';
import { deleteCookie, getCookie } from '@/utils/cookie';

@Component
export default class App extends Vue {
  @Getter('lnb/isLnbShowing') isLnbShowing!: boolean;

  private unloadGuard = (event: BeforeUnloadEvent) => {
    const hasUnsavedForm = store.getters['hasUnsavedForm'] && this.$route.meta && this.$route.meta.detection;

    if (!hasUnsavedForm) return;
    event.preventDefault();
    event.returnValue = '';
  };

  created() {
    if (!getCookie(SESSION_INITIALIZE)) {
      deleteCookie(SHOPBY_ACCESSTOKEN);
      deleteCookie(SHOPBY_REFRESHTOKEN);
      deleteCookie('adminInfo');
    }
    this.onLnbShowingChanged();
    this.initGridSetting();
  }

  mounted() {
    window.$i18n = this.$i18n;
    window.$t = this.$t;

    window.addEventListener('beforeunload', this.unloadGuard);
  }

  destroyed() {
    window.removeEventListener('beforeunload', this.unloadGuard);
  }

  // 최상단 부모에서 store 변경을 감지하여 처리
  @Watch('isLnbShowing')
  onLnbShowingChanged() {
    if (this.isLnbShowing) {
      document.body.classList.remove('min');
    } else {
      document.body.classList.add('min');
    }
  }

  private initGridSetting(): void {
    setNoDataMessage(this.$t('NO_RESULT').toString());

    TuiGrid.applyTheme('clean', {
      outline: {
        border: '#ddd',
        showVerticalBorder: false,
      },
      area: {
        body: {
          background: '#fff',
        },
      },
      cell: {
        normal: {
          background: '#fff',
          border: '#eee',
          showVerticalBorder: true,
          showHorizontalBorder: true,
          text: '#333',
        },
        header: {
          background: '#fafbfc',
          border: '#eee',
          text: '#333',
        },
        selectedHeader: {
          background: '#fafbfc',
          text: '#333',
        },
      },
    });
  }
}
