





























































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import moment from 'moment';
import {
  GetSmsUnsubscribeConfig,
  GetSmsUnsubscribeConfigRequest,
  NCPResponse,
  PostSmsGodoSendRequest,
} from 'ncp-api-supporter';
import NoticeBox from '@/components/common/NoticeBox.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import DateCalendar from '@/components/common/DateCalendar.vue';
import DestinationSelectBox from '@/views/contents/member/sms/DestinationSelectBox.vue';
import ReplacementTextTable from '@/components/common/ReplacementTextTable.vue';
import SmsPoint from '@/views/contents/member/sms/mixins/SmsPoint.vue';
import OpenAdNoticePopupMixin from '@/views/contents/member/sms/mixins/OpenAdNoticePopupMixin.vue';
import SmsSendMixin, { MAX_LENGTH } from '@/views/contents/member/sms/mixins/SmsSendMixin.vue';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { throwPopup } from '@/helpers/popup';
import { isValidate } from '@/utils/validate';
import { MessageTemplate, SmsSendBottomNavKey, SmsType } from '@/types/member';
import { direct, excel, member, order } from '@/const/words/templateStrings';
import { getCurrentMallNo } from '@/utils/mall';
import unsavedDetector from '@/directives/input/unsavedDetector';
import Reject080StatusMixin from '@/views/contents/member/sms/mixins/Reject080StatusMixin.vue';
import store from '@/store';

@Component({
  components: { ReplacementTextTable, DestinationSelectBox, DateCalendar, RadioGroup, SelectBox, NoticeBox },
  directives: { unsavedDetector },
})
export default class SmsSend extends Mixins(SmsPoint, SmsSendMixin, OpenAdNoticePopupMixin, Reject080StatusMixin) {
  @Prop({ default: false })
  private readonly isPopup!: boolean;
  @Prop({ required: false })
  private readonly popupPhoneNoList: string[];

  @Ref()
  private readonly titleInput!: HTMLInputElement;
  @Ref()
  private readonly contentTextarea!: HTMLTextAreaElement;
  @Ref()
  private readonly reserveDateCalendar!: DateCalendar;

  private TITLE_LENGTH = MAX_LENGTH.LMS_TITLE;

  private get templateStrings(): MessageTemplate[] {
    if (this.isPopup) {
      return order();
    } else if (this.showSelectedNumber) {
      return member();
    } else if (this.selectDestinationType === 'directInput') {
      return direct();
    } else {
      return excel();
    }
  }

  private get textareaWidth(): string {
    return this.isPopup ? '340px' : '400px';
  }

  private get previewContent(): string[] {
    const adContent = { content: this.content, adBlockNo: this.adBlockerNo };
    let previewContent = this.isAdType ? this.$t('MEMBER.SMS_DISPATCH.AD_PHRASE', adContent).toString() : this.content;

    this.templateStrings.forEach(({ key, example }) => {
      previewContent = previewContent.replaceAll(key, example);
    });

    return previewContent.split('\n');
  }

  private setMemberList() {
    if (!this.isPopup) return;
    this.phoneNoList = this.popupPhoneNoList;
  }

  private changeMemberNoList(data) {
    if (!data.selectedMemberSmsInfo) return;
    this.memberNoList = data.selectedMemberSmsInfo;
    this.allCount = data.selected.length;
    this.rejectCount = data.rejectCount;
  }

  private checkTitleMaxLength() {
    if (this.getByteLength(this.title) > this.maxTitleLength) {
      const sliceLength = this.maxTitleLength - this.getByteLength(this.title);
      this.titleInput.value = this.title.slice(0, sliceLength);
      this.title = this.title.slice(0, sliceLength);
    }
  }

  private checkContentMaxLength() {
    if (this.contentLength > this.realMaxContentLength) {
      const sliceLength = this.realMaxContentLength - this.contentLength;
      this.contentTextarea.value = this.content.slice(0, sliceLength);
      this.content = this.content.slice(0, sliceLength);
    }
  }

  @Watch('smsType')
  private async typeValueController(_: SmsType, prev: SmsType) {
    this.inputController();

    if (this.smsType === 'INFORMATION') return;
    await this.getUnsubscribeConfig();
    if (!(this.unsubscribeUsed && this.unsubscribeStatus === 'OPEN')) {
      alert(this.$t('MEMBER.SMS_DISPATCH.ALERT_AD'));
      this.$nextTick(() => (this.smsType = prev));
    }
  }

  @Watch('sendType')
  private inputController() {
    if (this.title.length > this.maxTitleLength) {
      this.title = this.title.slice(0, this.maxTitleLength);
    }
    if (this.content.length > this.realMaxContentLength) {
      this.content = this.content.slice(0, this.realMaxContentLength);
    }
  }

  private async getUnsubscribeNo(): Promise<void> {
    const request: GetSmsUnsubscribeConfigRequest = {
      params: { mallNo: getCurrentMallNo(this) },
    };
    const { data }: NCPResponse<GetSmsUnsubscribeConfig> = await this.$api.getSmsUnsubscribeConfig(request);
    this.adBlockerNo = data.unsubscribeNo;
  }

  private changeSelectedDateTime(requestDateTime: string) {
    this.requestDateTime = requestDateTime;
    this.isDatePickerReset = false;
  }

  private isFormPassed(): boolean {
    if (this.sendType === 'LMS') {
      const isLmsTitle = isValidate(this.title.length, this.$t('MEMBER.SMS.TITLE_PLACEHOLDER'));
      if (!isLmsTitle) {
        this.titleInput.focus();
        return false;
      }
    }
    const isContent = isValidate(this.content.length, this.$t('MEMBER.SMS.CONTENT_PLACEHOLDER'));
    if (!isContent) {
      this.contentTextarea.focus();
      return false;
    }
    if (this.sendConfigType === 'reservation') {
      const MINUTE_STANDARD = 10;
      const targetDate = moment(this.requestDateTime);
      const now = moment();
      const diffMinutes = targetDate.diff(now, 'm') >= MINUTE_STANDARD;

      const isOverStandardMinute = isValidate(diffMinutes, this.$t('MEMBER.SMS_DISPATCH.ERR_TIME'));
      if (!isOverStandardMinute) {
        this.reserveDateCalendar.focus();
        return false;
      }
    }
    const isCallingNumber = isValidate(this.callingNumber, this.$t('MEMBER.SMS.MUST_REGISTER_CALLING_NUMBER'));
    if (!isCallingNumber) return false;
    const isUsageSms = isValidate(this.useType, this.$t('MEMBER.SMS.MUST_USAGE'));
    if (!isUsageSms) return false;
    if (!this.isPopup) {
      const existSelectDest = isValidate(this.selectDestCount, this.$t('MEMBER.SMS.MUST_HAVE_DEST'));
      if (!existSelectDest) return false;
    }

    if (!this.isAdType) return true;
    //NOTE: 광고성 SMS발송조건 정책변경 -> 기존 SmsSendRejectMessage 레이어 팝업 삭제
    const allDisagreedSms = this.allCount === this.rejectCount;
    if (allDisagreedSms) {
      alert(this.$t('MEMBER.SMS_DISPATCH.NO_MEMBER_SEND'));
      return false;
    } else if (this.rejectCount) {
      this.memberNoList = this.memberNoList?.filter(({ smsAgreed }) => smsAgreed);
    } else if (!this.rejectCount) {
      return confirm(this.$t('MEMBER.SMS.CONFIRM_SEND', [this.allCount]) as string);
    }
    return true;
  }

  private openCallingNumberList() {
    if (!confirm(`${this.$t('MEMBER.SMS.CHANGE_CALLING_NUMBER_CONFIRM')}`)) return;

    const isPremiumPlanType = store.getters['admin/getAdmin'].plan === 'PREMIUM';
    const premiumConfigPage = {
      path: '/premium/sms/main/config',
      query: { mallNo: getCurrentMallNo(this).toString() },
    };
    const configPage = isPremiumPlanType
      ? premiumConfigPage
      : { name: 'SmsConfig', query: { mallNo: getCurrentMallNo(this).toString() } };

    if (this.isPopup) {
      const routeData = this.$router.resolve(configPage);
      window.open(routeData.href, '_blank');
      return;
    }
    this.$router.push(configPage);
  }

  public onClickBottomNav(key: SmsSendBottomNavKey) {
    switch (key) {
      case 'dispatchPreview':
        this.dispatchPreview();
        break;
      case 'dispatchReset':
        this.init();
        break;
      case 'dispatchSend':
        this.dispatchSms();
        break;
      default:
        break;
    }
  }

  @Watch('$route.query')
  private async init() {
    this.smsType = this.smsTypeList[0].value;
    this.sendType = this.sendTypeOption.data[0].value;
    this.selectDestinationType = this.selectDestinationOption.data[1].value;
    this.sendConfigType = this.sendConfigOption.data[0].value;
    this.title = '';
    this.content = '';
    this.isDatePickerReset = true;
    this.memberNoList = [];
    this.allCount = 0;
    this.rejectCount = 0;
    this.setMemberList();
    this.getUnsubscribeNo();
  }

  private dispatchSms() {
    if (!this.isFormPassed()) return;
    this.postSms();
  }

  private get templateStringParams(): { [key: string]: string } {
    const templateObject = {};
    this.templateStrings.forEach(({ key, example }) => {
      templateObject[key] = example;
    });
    return templateObject;
  }

  private get recipientType() {
    if (this.selectDestinationType === 'all') {
      return 'ALL';
    }
    if (!this.isPopup && this.showSelectedNumber) {
      return 'MEMBER_NO';
    }
    return 'DIRECT';
  }

  private get dispatchSmsRequest(): PostSmsGodoSendRequest {
    // smsSendKey values
    const unixTime = Math.floor(new Date().getTime() / 1000); // unixtime
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // 랜덤 4자리 숫자
    const requestSmsGodoSendData = {
      recipientType: this.recipientType,
      messageType: this.smsType,
      requestDateTime: this.requestDateTime,
      title: this.dispatchTitle,
      content: this.dispatchContent,
      adBlockerNo: this.adBlockerNo,
      smsType: this.sendType,
      smsSendKey: `${unixTime}${randomNumber}`,
    };
    return {
      params: {
        mallNo: getCurrentMallNo(this),
      },
      data:
        this.recipientType === 'ALL'
          ? { ...requestSmsGodoSendData }
          : {
              ...requestSmsGodoSendData,
              recipients: this.isPopup
                ? this.popupPhoneNoList.map(value => value.split('-').join(''))
                : this.dispatchRecipients,
            },
    };
  }

  private async postSms() {
    try {
      await this.$api.postSmsGodoSend(this.dispatchSmsRequest);
      alert(this.$t('MEMBER.SMS_DISPATCH.SUCCESS_SEND'));
      await this.init();
      Promise.all([this.getUnsubscribeNo(), this.fetchSmsPoint(false)]);
    } catch (e) {
      if (e.status === 403 || e.message) return;
      alert(this.$t('MEMBER.SMS_DISPATCH.FAIL_SEND'));
    }
  }

  private dispatchPreview() {
    throwPopup({
      name: 'SmsSendPreview',
      data: {
        title: this.title || '',
        contents: this.previewContent,
      },
    });
  }

  created() {
    this.init();
    throwBottomNavigation({
      buttons: this.buttonList,
      onClick: (key: SmsSendBottomNavKey) => this.onClickBottomNav(key),
    });
  }
}
