




import { Vue, Component, Prop } from 'vue-property-decorator';

export type Mode = 'global' | 'local';

@Component
export default class ThrottleRefreshButton extends Vue {
  @Prop({ default: 'local' })
  private readonly mode!: Mode;
  @Prop({ required: true })
  private readonly throttleSecond!: number;

  refresh() {
    switch (this.mode) {
      case 'global':
        this.globalRefresh();
        break;
      case 'local':
        this.localRefresh();
        break;
    }
  }

  get latestGlobalRefreshDateTime(): number {
    const { latestRefresh } = this.$route.query;
    return latestRefresh ? Number(latestRefresh) : 0;
  }

  globalRefresh(): void {
    const pass = this.throttleChecker(this.latestGlobalRefreshDateTime, this.throttleSecond);

    if (!pass) return;
    this.$router.replace({ query: { latestRefresh: Date.now().toString() } }).catch(() => null);
  }

  private latestLocalRefreshDateTime = 0;

  localRefresh(): void {
    const pass = this.throttleChecker(this.latestLocalRefreshDateTime, this.throttleSecond);

    if (!pass) return;
    this.latestLocalRefreshDateTime = Date.now();
  }

  throttleChecker(latestDateTime: number, throttleSecond: number): boolean {
    if (latestDateTime !== 0 && latestDateTime + 1000 * throttleSecond > Date.now()) {
      const nextSecond = (latestDateTime + 1000 * throttleSecond - Date.now()) / 1000;
      alert(this.$t('SUMMARY.ALERT_REFRESH_BLOCKED', { second: Math.ceil(nextSecond) }));
      return false;
    }

    this.$emit('refresh');
    return true;
  }
}
