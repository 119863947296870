import { CheckBoxGroupOption } from '@/helpers/type';
import { i18n } from '@/main';
import { PaymentKeysPayco } from 'ncp-api-supporter';
import { throwMessagePopup } from '@/helpers/popup';

export const getCheckboxOption = (): CheckBoxGroupOption<string> => ({
  name: 'PayTypeCheckBox',
  hasAll: false,
  noBrackets: true,
  data: [
    {
      id: 'CREDIT_CARD',
      value: 'CREDIT_CARD',
      display: 'MALL.FORM.CREDIT_CARD',
    },
    {
      id: 'REALTIME_ACCOUNT_TRANSFER',
      value: 'REALTIME_ACCOUNT_TRANSFER',
      display: 'MALL.FORM.REALTIME_ACCOUNT_TRANSFER',
    },
    {
      id: 'VIRTUAL_ACCOUNT',
      value: 'VIRTUAL_ACCOUNT',
      display: 'MALL.FORM.VIRTUAL_ACCOUNT',
    },
    {
      id: 'PAYCO',
      value: 'PAYCO',
      display: 'MALL.FORM.PAYCO_POINT',
    },
  ],
});

export const getDefaultPaycoSetting = (): PaymentKeysPayco => ({
  sellerKey: '',
  enablePayTypes: [],
  cpId: '',
});

export const i18nPaycoSetting = (message: string, key = 'CONFIGURATION.PAYCO.'): string => {
  return i18n.t(key + message).toString();
};

export const renewInfo = async vueComponent => {
  setTimeout(() => vueComponent.$router.go(0), 3000);
  await throwMessagePopup(i18n.t('CONFIGURATION.PG.ALERT_SUCCESS_UPDATE_INFO'));
  vueComponent.$router.go(0);
};
