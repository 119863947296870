













































































































































































































import { Vue, Component, PropSync, Watch, Prop, Ref } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import { NCPResponse, YorN } from 'ncp-api-supporter';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { GetDeliveryTemplate, DeliveryCompany } from 'ncp-api-supporter/dist/types/modules/delivery/deliveryTemplate';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { formatCurrency } from '@/utils/numberFormat';
import {
  defaultDeliveryTemplate,
  DefaultDeliveryTemplate,
  selectOption,
  toolTipMsg,
} from '@/views/contents/product/basic/ProductAdd/deliveryInfo/DeliveryInfo';
import { TranslateResult } from 'vue-i18n';
import {
  InputNumber,
  DeliveryInfoFocusType,
  DeliveryInformation,
  EditFlag,
  DeliveryInformationModel,
  PartnerType,
} from '@/types';
import { i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { PopupClose, throwPopup } from '@/helpers/popup';
import { MODE, PARTNER_TYPE } from '@/const/common';
import { DELETE_ERROR, IMMUTABLE_FIELD, PRODUCT_FORM_ERROR_TYPE } from '@/const/contents/product';
import { namespace } from 'vuex-class';
import { PartnerState } from '@/store/modules/partner';
import { ProductManagementMode } from '@/views/contents/product/basic/ProductAdd.vue';

enum WareHouse {
  releaseWarehouse = 'releaseWarehouse',
  returnWarehouse = 'returnWarehouse',
}
const partnerStore = namespace('partner');

@Component({
  components: { SelectBox, ToolTip, RadioGroup, TextInput },
})
export default class DeliveryInfo extends Vue {
  @partnerStore.Getter('getPartnerNo')
  private readonly selectedPartnerNo: number;
  @partnerStore.Getter('getPartnerType')
  private readonly selectedPartnerType: PartnerType;
  @partnerStore.Action('setPartnerNo')
  private readonly setPartnerNo: (state: PartnerState) => void;
  @PropSync('deliveryInfo')
  private deliveryInfoSync!: DeliveryInformation;

  @Prop({ required: true })
  private readonly deliveryInfoModel: DeliveryInformationModel;

  @Prop()
  private readonly mallNo: InputNumber;

  @Prop()
  private readonly isSelectedMall: boolean;

  @Prop({ required: true })
  private readonly disabled;

  @Prop({ required: true })
  private readonly editFlag: EditFlag;

  @Prop({ required: true })
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly isCopyWithStockMode: boolean;

  @Prop({ required: true })
  private readonly mode: ProductManagementMode;

  private readonly IMMUTABLE_FIELD = IMMUTABLE_FIELD;

  @Ref()
  private readonly deliveryTemplateRef!: SelectBox;

  @Ref()
  private readonly deliveryInfoRef!: RadioGroup;

  private selectOption: OptionData<YorN>[] = selectOption;
  private toolTipMsg = this.$t(toolTipMsg);
  private deliveryCompanies: DeliveryCompany[];
  private deliveryCompanyName = '';
  private isEditInit = this.mode.isEdit;

  public alertDeliveryTemplate(): void {
    alert(i18nForProduct('PLZ_SELECT_TEMPLATE'));
    this.onFocus('deliveryInfo');
  }

  public onFocus(focusTarget: DeliveryInfoFocusType): void {
    this[`${focusTarget}Ref`].focus();
  }

  private get useDelivery(): boolean {
    return this.deliveryInfoSync.deliveryYn === 'Y';
  }

  private get vacantTemplates(): boolean {
    return !this.deliveryTemplates.length;
  }

  private deliveryTemplateNo: InputNumber = '';
  private changedDeliveryTemplates(templateNo: number): void {
    // this.isAddedTemplate = false;
    this.deliveryInfoSync.deliveryTemplateNo = templateNo;
  }

  private get needAllForTemplates(): boolean {
    return !this.isSelectedMall || this.vacantTemplates;
  }

  private get needAllTextForTemplates(): TranslateResult {
    if (!this.isSelectedMall) {
      return this.$t('PRODUCT.ADD.PLZ_SELECT_SHOPPING_MALL');
    }
    if (this.vacantTemplates) {
      return this.$t('PRODUCT.ADD.PLZ_ADD_TEMPLATE');
    }
    return '';
  }

  private checkSelectedMall(): void {
    this.$emit(this.deleteError, ['INVALID_DELIVERY_TEMPLATE_NO', 'NON_EDITABLE_DELIVERY_TEMPLATE_NO']);
    !this.isSelectedMall && this.$emit('alertMallSelect');
  }

  private changedDeliveryYn(curr: YorN): void {
    if (!this.editFlag.isMapping || curr === 'Y') return;
    this.undoChanges();
  }

  private isDisabled(item: string): boolean {
    return (
      (!this.editFlag.isReady && this.immutableFields.includes(item)) ||
      (this.editFlag.isReady && this.editFlag.isSlave && this.immutableFields.includes(item))
    );
  }

  private undoChanges(): void {
    alert(i18nForProduct('NOTICE_DELIVERY_TEMPLATE'));
    this.$nextTick(() => {
      this.deliveryInfoSync.deliveryYn = 'Y';
    });
  }

  private deliveryTemplates: GetDeliveryTemplate[] = [];
  @Watch('mallNo')
  private async resetDeliveryTemplate() {
    if (!this.selectedPartnerNo || this.isEditInit) {
      this.isEditInit = false;
      return;
    }
    await this.fetchDeliveryTemplates();
    this.setDefaultDeliveryTemplate();
  }

  private async fetchDeliveryTemplates(): Promise<void> {
    this.deliveryTemplates = await this.getDeliveryTemplates();
    this.setDefaultDeliveryTemplate();
  }

  @Watch('selectedPartnerNo')
  private updateSelectedPartnerNo(newValue) {
    if (!newValue) {
      return;
    }

    this.fetchDeliveryTemplates();
  }

  private async fetchDeliveryCompanies(): Promise<void> {
    const { data }: NCPResponse<DeliveryCompany[]> = await this.$api.getDeliveryCompanies({
      params: { countryCd: 'KR' },
    });
    this.deliveryCompanies = data;
  }

  @Watch('selectedDeliveryTemplate', { deep: true })
  private getDeliveryCompanyName() {
    const selectedDeliveryCompanyName = this.deliveryCompanies?.find(
      company => company.deliveryCompanyType === this.selectedDeliveryTemplate.deliveryCompanyType,
    )?.label;
    this.deliveryCompanyName = selectedDeliveryCompanyName ? selectedDeliveryCompanyName : '';
  }

  @Watch('selectedPartnerType')
  private getShippingAreaType() {
    this.deliveryInfoSync.shippingAreaType =
      this.selectedPartnerType === 'SHOP' ? 'MALL_SHIPPING_AREA' : 'PARTNER_SHIPPING_AREA';
  }

  private async getDeliveryTemplates(): Promise<GetDeliveryTemplate[]> {
    if (!this.mallNo) return;
    this.deliveryInfoSync.shippingAreaPartnerNo = await this.getPartnerNo();
    if (this.deliveryInfoSync.shippingAreaPartnerNo === 0) return; // as resetPartnerState
    const { data }: NCPResponse<GetDeliveryTemplate[]> = await this.$api.getDeliveryTemplates({
      params: { partnerNo: this.deliveryInfoSync.shippingAreaPartnerNo },
    });
    return data;
  }

  private async getPartnerNo(): Promise<number> {
    //쇼핑몰이 속한 서비스의 배송파트너 조회 (파트너사 : 쇼핑몰 자체 상품)
    if (this.selectedPartnerType === PARTNER_TYPE.SHOP) {
      const { data } = await this.$api.getMallsDeliveryPartnersByMallNo({
        params: { mallNo: this.mallNo.toString() },
      });
      this.setPartnerNo({ partnerNo: data.partnerNo });
    }
    // 파트너사 입력한 경우
    return this.selectedPartnerNo;
  }

  private setDefaultDeliveryTemplate(): void {
    if (this.preventDefaultTemplateNo) {
      this.preventDefaultTemplateNo = false;
      return;
    }
    const defaultTemplate = this.deliveryTemplates.find(template => template.default);
    this.deliveryInfoSync.deliveryTemplateNo = this.getDefaultTemplateNo(defaultTemplate);
    this.deliveryTemplateNo = this.deliveryInfoSync.deliveryTemplateNo;
  }

  private getDefaultTemplateNo(defaultTemplate: DefaultDeliveryTemplate): number {
    return defaultTemplate ? defaultTemplate.templateNo : this.deliveryTemplates[0].templateNo;
  }

  private get selectedDeliveryTemplate(): DefaultDeliveryTemplate {
    // TODO deliveryTypeLabel -> undefined error 때문에 임시처리함. 정리예정
    return this.deliveryInfoSync.deliveryTemplateNo
      ? { ...defaultDeliveryTemplate, ...this.getSelectedDefaultDeliveryTemplate() }
      : { ...defaultDeliveryTemplate };
  }

  private getSelectedDefaultDeliveryTemplate(): GetDeliveryTemplate {
    const current = this.deliveryTemplates.find(
      template => template.templateNo === this.deliveryInfoSync.deliveryTemplateNo,
    );
    if (current) return current;

    const defaultTemplate = this.deliveryTemplates.find(template => template.default);
    if (defaultTemplate) return defaultTemplate;
  }

  private get releaseWarehouseAddress(): string {
    return this.getWarehouseAddress(WareHouse.releaseWarehouse);
  }

  private get returnWarehouseAddress(): string {
    return this.getWarehouseAddress(WareHouse.returnWarehouse);
  }

  private get returnDeliveryAmt(): string {
    const amt = this.selectedDeliveryTemplate.deliveryFee.returnDeliveryAmt;
    return !amt ? '0' : formatCurrency(amt);
  }

  private getWarehouseAddress(warehouses: WareHouse): string {
    return this.selectedDeliveryTemplate[warehouses].address
      ? this.selectedDeliveryTemplate[warehouses].address.addressStr
      : this.selectedDeliveryTemplate[warehouses].substitutionText;
  }

  private setAddedTemplate(templateNo: number) {
    this.deliveryInfoSync.deliveryTemplateNo = templateNo;
    this.deliveryTemplateNo = templateNo;
    this.fetchDeliveryTemplates();
  }
  private openPopup(type: string): void {
    if (!this.isSelectedMall) {
      this.checkSelectedMall();
      return;
    }
    switch (type) {
      case 'addDeliveryTemplate':
        throwPopup({
          name: 'DeliveryTemplateForm',
          data: {
            mode: MODE.REGISTER,
            template: null,
            partnerType: this.selectedPartnerType,
            partnerNo: this.selectedPartnerNo,
          },
        }).then(res => {
          if (res && res.state === PopupClose.CONFIRM) {
            this.setAddedTemplate(res.data);
          }
        });
        break;
    }
  }

  private preventDefaultTemplateNo = false;
  @Watch('deliveryInfoModel.deliveryTemplateNo')
  private setInit() {
    if (!this.deliveryInfoModel.deliveryTemplateNo) return;
    this.deliveryTemplateNo = this.deliveryInfoModel.deliveryTemplateNo;
    this.preventDefaultTemplateNo = true;
  }
  // error
  @Prop({ required: true })
  private readonly formError: { [errorKey: string]: string };
  @Prop({ required: true })
  private readonly formErrorType: typeof PRODUCT_FORM_ERROR_TYPE;
  @Prop({ required: true })
  private readonly deleteError: typeof DELETE_ERROR;

  created() {
    this.fetchDeliveryCompanies();
  }
  mounted() {
    if (this.mode.isCopy) this.resetDeliveryTemplate();
  }
}
