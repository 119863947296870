export default Object.freeze({
  // 운영관리 > SMS 관리 > 자동SMS 발송내역 조회
  NOTICE1: 'ⓘ 쇼핑몰에서 회원에게 발송한 자동SMS 발송내역을 조회할 수 있습니다.',
  NOTICE2: 'ⓘ 발송내역은 현재 기준으로 최대 30일 전까지만 조회 가능합니다.',
  NOTICE3: 'Ⓘ 요청ID 클릭 시, 수신자를 조회할 수 있습니다.',
  NOTICE4: 'Ⓘ 발송된 SMS의 상세내용은 내용 클릭 시 확인이 가능합니다.',
  DATE_TTL: '발송요청 일시',
  MESSAGE_TYPE: '발송 유형',
  LAYER_TTL: 'SMS 발송내용 상세보기',
  RECIPIENT_TTL: 'SMS 수신자 조회',
  REQUEST_ID: '요청ID',
  SMS_TYPE: 'SMS 유형',
  SEND_TYPE: '발송 유형',
  RECIPIENT_NO: '수신번호',
  STATUS_NAME: '발송요청 상태',
  RESULT_CODE: '수신결과 상태',
  RESULT_DATE: '단말기수신일시',
  REQUEST__ID: '요청 ID',
  CONTENTS: '내용',
  SEND: '즉시발송',

  DETAIL_SMS: 'SMS 예약발송 내역 상세보기',
  DETAIL_LMS: 'LMS 예약발송 내역 상세보기',
  TOOLTIP_TITLE: '예약취소 시 유의사항',
  TOOLTIP_MESSAGE_1: '예약취소 시 취소된 항목은 예약발송 내역에서 제거되며, 재발송이 불가합니다.',
  TOOLTIP_MESSAGE_2: '재발송이 필요한 경우, 수동 SMS발송 기능을 통해 새로 발송요청을 해주셔야 합니다.',
  TOOLTIP_MESSAGE_3:
    '휴면전환사전안내/광고성 SMS 수신현황안내 등 법적으로 필수 안내되어야 하는 항목 또한\n예약취소 시 자동으로 재발송되지 않으므로 유의하시기 바랍니다.',
  TOOLTIP_MESSAGE_4:
    'SMS와 LMS는 개별 전체취소 처리만 가능합니다. 전체취소 시 예약발송 내역이 LMS(SMS)내역에도 존재할 수 있으니, 반드시 SMS와 LMS유형으로 모두 조회하여 확인하시기 바랍니다.',
  CANCEL_SELECTED_RESERVATION: '선택 예약취소',
  CANCEL_ALL_RESERVATION: '전체 예약취소',
  ALERT_MUST_SELECT: '예약취소할 발송내역을 선택해주세요.',
  ALERT_MAX_CASE: '선택취소는 최대 100개까지 가능합니다.',
  CONFIRM_CANCEL: 'SMS 발송예약 중{0}건을 취소하시겠습니까?',
  ALERT_FAIL: '예약취소에 실패하였습니다.',
  ALERT_SUCCESS_SELECT: '선택 예약취소가 완료되었습니다.',
  ALERT_SUCCESS_ALL: '전체 예약취소가 완료되었습니다.',
  CONFIRM_ALL_CANCEL_SMS:
    'SMS 발송예약을 전체 취소 하시겠습니까?\n취소하고자 하는 메시지가 LMS에도 존재할 수 있으니, 발송예약 관리 검색조건을 LMS로 변경하여 확인해주시기 바랍니다.',
  CONFIRM_ALL_CANCEL_LMS:
    'LMS 발송예약을 전체 취소 하시겠습니까?\n취소하고자 하는 메시지가 SMS에도 존재할 수 있으니, 발송예약 관리 검색조건을 SMS로 변경하여 확인해주시기 바랍니다.',
});
