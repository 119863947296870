import { OptionData } from '@/helpers/type';
import {
  SaleStatusCheckBoxGroupOption,
  SalesSettingStatusType,
  SalesStatusType,
  Deliverable,
  DeliveryFeeType,
  AllowsShippingTogether,
  AllowsFrontDisplay,
  PlatformType,
  SaleMethodType,
  ApplyStatusType,
} from '@/components/product/basic/SearchOptionTypes';

/* SELECT */
// 검색어
const keywordInfo: OptionData<string>[] = [
  {
    value: 'PRODUCT_NAME',
    name: 'PRODUCT.SEARCH.NAME',
  },
  {
    value: 'MALL_PRODUCT_NO',
    name: 'PRODUCT.SEARCH.NUMBER',
  },
  {
    value: 'PRODUCT_MANAGEMENT_CD',
    name: 'PRODUCT.SEARCH.MANAGEMENT_CODE',
  },
];

// 기간
const periodInfo: OptionData<string>[] = [
  {
    value: 'REGISTER_DATE',
    name: 'PRODUCT.SEARCH.REGISTER_DATE',
  },
  {
    value: 'PRODUCT_MODIFIED_DATE',
    name: 'PRODUCT.SEARCH.MODIFIED_DATE',
  },
  {
    value: 'SALE_START_DATE',
    name: 'PRODUCT.SEARCH.SALE_START_DATE',
  },
  {
    value: 'SALE_END_DATE',
    name: 'PRODUCT.SEARCH.SALE_END_DATE',
  },
  {
    value: 'PRE_SALE_START_DATE',
    name: 'PRODUCT.SEARCH.PRE_SALE_START_DATE',
  },
  {
    value: 'PRE_SALE_END_DATE',
    name: 'PRODUCT.SEARCH.PRE_SALE_END_DATE',
  },
];

// 상품상태 : 승인상태
const applyStatus: OptionData<ApplyStatusType>[] = [
  {
    value: 'ALL',
    name: 'PRODUCT.COMMON.APPLY_STATUS_TYPE', // 승인상태
  },
  {
    value: 'REGISTRATION_READY',
    name: 'PRODUCT.COMMON.REGISTRATION_READY', // 등록대기
  },
  {
    value: 'APPROVAL_READY',
    name: 'PRODUCT.COMMON.APPROVAL_READY', // 승인대기
  },
  {
    value: 'APPROVAL_REJECTION',
    name: 'PRODUCT.COMMON.APPROVAL_REJECTION', // 승인거부
  },
  {
    value: 'SALE_AGREEMENT_READY',
    name: 'PRODUCT.COMMON.SALE_AGREEMENT_READY', // 판매합의대기
  },
  {
    value: 'SALE_AGREEMENT_REJECTION',
    name: 'PRODUCT.COMMON.SALE_AGREEMENT_REJECTION', // 판매합의거부
  },
  {
    value: 'FINISHED',
    name: 'PRODUCT.COMMON.FINISHED', // 승인완료
  },
  {
    value: 'AFTER_APPROVAL_READY',
    name: 'PRODUCT.COMMON.AFTER_APPROVAL_READY', // 수정후 승인대기
  },
  {
    value: 'AFTER_APPROVAL_REJECTION',
    name: 'PRODUCT.COMMON.AFTER_APPROVAL_REJECTION', // 수정후 승인거부
  },
];

const saleStatus: SaleStatusCheckBoxGroupOption<SalesStatusType | 'ALL'> = {
  name: 'saleStatus',
  hasAll: true,
  noBrackets: true,
  data: [
    {
      id: 'sale-status-all',
      value: 'ALL',
      display: 'ALL', // 전체
    },
    {
      id: 'on-pre-sale',
      value: 'ON_PRE_SALE',
      display: 'PRODUCT.SEARCH.SALE_STATUS_ON_PRE_SALE', // 예약판매중
    },
    {
      id: 'waiting-sale',
      value: 'WAITING_SALE',
      display: 'PRODUCT.SEARCH.SALE_STATUS_WAITING_SALE', // 판매 대기
    },
    {
      id: 'on-sale',
      value: 'ON_SALE',
      display: 'PRODUCT.SEARCH.SALE_STATUS_ON_SALE', // 판매중
    },
    {
      id: 'end-sale',
      value: 'END_SALE',
      display: 'PRODUCT.SEARCH.SALE_STATUS_END_SALE', // 판매종료
    },
  ],
};

const saleSettingStatus: SaleStatusCheckBoxGroupOption<SalesSettingStatusType | 'ALL'> = {
  name: 'saleSettingStatus',
  hasAll: true,
  noBrackets: true,
  data: [
    {
      id: 'sale-setting-status-all',
      value: 'ALL',
      display: 'ALL', // 전체
    },
    {
      id: 'available-for-sale',
      value: 'AVAILABLE_FOR_SALE',
      display: 'PRODUCT.SEARCH.AVAILABLE_FOR_SALE', // 판매가능
    },
    {
      id: 'stop-sale',
      value: 'STOP_SELLING',
      display: 'PRODUCT.SEARCH.STOP_SELLING', // 판매중지
    },
    {
      id: 'prohibition-sale',
      value: 'PROHIBITION_SALE',
      display: 'PRODUCT.SEARCH.PROHIBITION_SALE', // 판매금지
    },
  ],
};

const deliverables: OptionData<Deliverable>[] = [
  {
    value: 'ALL',
    name: 'PRODUCT.SEARCH.DELIVERABLE',
  },
  {
    value: 'Y',
    name: 'PRODUCT.SEARCH.DELIVERABLE_Y',
  },
  {
    value: 'N',
    name: 'PRODUCT.SEARCH.DELIVERABLE_N',
  },
];

const deliveryFeeType: OptionData<DeliveryFeeType>[] = [
  {
    value: 'ALL',
    name: 'PRODUCT.SEARCH.DELIVERY_FEE_TYPE',
  },
  {
    value: 'FREE',
    name: 'PRODUCT.SEARCH.DELIVERY_FEE_TYPE_FREE',
  },
  {
    value: 'CONDITIONAL_FREE',
    name: 'PRODUCT.SEARCH.DELIVERY_FEE_TYPE_CONDITIONAL_FREE',
  },
  {
    value: 'FIXED_FEE',
    name: 'PRODUCT.SEARCH.DELIVERY_FEE_TYPE_FIXED_FEE',
  },
  {
    value: 'QUANTITY_PROPOSITIONAL_FEE',
    name: 'PRODUCT.SEARCH.DELIVERY_FEE_TYPE_QUANTITY_PROPOSITIONAL_FEE',
  },
];

const allowsShippingTogether: OptionData<AllowsShippingTogether>[] = [
  {
    value: 'ALL',
    name: 'PRODUCT.SEARCH.ALLOWS_SHIPPING_TOGETHER',
  },
  {
    value: 'Y',
    name: 'PRODUCT.SEARCH.ALLOWS_SHIPPING_TOGETHER_Y',
  },
  {
    value: 'N',
    name: 'PRODUCT.SEARCH.ALLOWS_SHIPPING_TOGETHER_N',
  },
];

const allowsFrontDisplay: OptionData<AllowsFrontDisplay>[] = [
  {
    value: 'ALL',
    name: 'PRODUCT.SEARCH.ALLOWS_FRONT_DISPLAY',
  },
  {
    value: 'Y',
    name: 'PRODUCT.SEARCH.ALLOWS_FRONT_DISPLAY_Y',
  },
  {
    value: 'N',
    name: 'PRODUCT.SEARCH.ALLOWS_FRONT_DISPLAY_N',
  },
];

const platformType: OptionData<PlatformType>[] = [
  {
    value: 'ALL',
    name: 'PRODUCT.SEARCH.PLATFORM_TYPE',
  },
  {
    value: 'PC',
    name: 'PRODUCT.SEARCH.PLATFORM_TYPE_PC',
  },
  {
    value: 'MOBILE_WEB',
    name: 'PRODUCT.SEARCH.PLATFORM_TYPE_MOBILE_WEB',
  },
];

// const onlyMappingProduct: OptionData<OnlyMappingProduct>[] = [
//   {
//     value: 'ALL',
//     name: 'PRODUCT.SEARCH.ONLY_MAPPING_PRODUCT',
//   },
//   {
//     value: 'Y',
//     name: 'PRODUCT.SEARCH.ONLY_MAPPING_PRODUCT_Y',
//   },
//   {
//     value: 'N',
//     name: 'PRODUCT.SEARCH.ONLY_MAPPING_PRODUCT_N',
//   },
// ];

const saleMethodType: OptionData<SaleMethodType>[] = [
  {
    value: 'ALL',
    name: 'PRODUCT.SEARCH.SALE_METHOD_TYPE',
  },
  {
    value: 'PURCHASE',
    name: 'PRODUCT.SEARCH.SALE_METHOD_TYPE_PURCHASE',
  },
  {
    value: 'CONSIGNMENT',
    name: 'PRODUCT.SEARCH.SALE_METHOD_TYPE_CONSIGNMENT',
  },
];

export type SortBy = [string, 'DESC' | 'ASC'] | null;

const sortType: { value: SortBy; name: string }[] = [
  // TODO : API 에 대응하는 value 필요
  {
    value: ['MALL_PRODUCT_NO', 'DESC'], // df. 상품번호 내림
    name: 'PRODUCT.SEARCH.SORT_BY',
  },
  {
    value: ['UPDATE_DATE_TIME', 'DESC'],
    name: 'PRODUCT.SEARCH.SORT_BY_MODIFY_DESC', // 수정일 내림
  },
  {
    value: ['UPDATE_DATE_TIME', 'ASC'],
    name: 'PRODUCT.SEARCH.SORT_BY_MODIFY_ASC', // 수정일 오름
  },
  {
    value: ['STOCK_CNT', 'DESC'],
    name: 'PRODUCT.SEARCH.SORT_BY_STOCK_DESC', // 재고수량 내림
  },
  {
    value: ['STOCK_CNT', 'ASC'],
    name: 'PRODUCT.SEARCH.SORT_BY_STOCK_ASC', // 재고수량 오름
  },
  {
    value: ['SALE_CNT', 'DESC'],
    name: 'PRODUCT.SEARCH.SORT_BY_SALE_DESC', // 판매량 내림
  },
  {
    value: ['REVIEW_CNT', 'DESC'],
    name: 'PRODUCT.SEARCH.SORT_BY_REVIEW_DESC', // 상품후기 내림
  },
];

export const searchFormOptions = {
  keywordInfo,
  periodInfo,
  applyStatus,
  saleStatus,
  saleSettingStatus,
  deliverables,
  deliveryFeeType,
  allowsShippingTogether,
  allowsFrontDisplay,
  platformType,
  // onlyMappingProduct,
  saleMethodType,
  sortType: sortType.map(({ value, name }) => ({ value: value.join(','), name })),
} as const;
