




import { Component, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import SummaryTab from '@/components/common/SummaryTab.vue';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { TextInput, SummaryTab, SelectBox, AdminIframe },
})
export default class AppList extends Vue {
  private remoteSrc = '';

  private setRemoteSrc() {
    const queryObject = { ...this.$route.query, serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();
    this.remoteSrc = process.env.VUE_APP_ADMIN_REMOTE_URL + this.$route.path + '?' + queryString;
  }

  created() {
    this.setRemoteSrc();
  }
}
