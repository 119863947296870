







































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SmsSendRejectMessage extends Vue {
  @Prop() private data;
  @Prop() private onPositiveClick!: (data?: { result?: string }) => void;
  @Prop() private onNegativeClick!: () => void;

  private onSaveButtonClick() {
    this.onPositiveClick({ result: 'except' });
  }
}
