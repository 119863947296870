
import { Component, Vue } from 'vue-property-decorator';
import { NavButton } from '@/types/bottomNav';
import { LocaleMessage } from 'vue-i18n';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { SendConfigType, SelectDestinationType, SendType, SmsMemberNo, SmsType } from '@/types/member';

export const MAX_LENGTH = {
  NONE: 0,
  LMS_TITLE: 40,
  SMS_CONTENT: 90,
  LMS_CONTENT: 2000,
  AD_DEFAULT: 33,
} as const;

@Component
export default class SmsSendMixin extends Vue {
  protected readonly buttonList: NavButton[] = [
    { type: 'left', key: 'dispatchPreview', color: 'white', text: this.$t('MEMBER.SMS.PREVIEW') },
    { type: 'left', key: 'dispatchReset', color: 'white', text: this.$t('RESET') },
    { type: 'right', key: 'dispatchSend', color: 'red', text: this.$t('MEMBER.SMS_DISPATCH.BTN_SEND') },
  ];

  protected memberNoList: SmsMemberNo[] = [];
  protected phoneNoList: string[] = [];
  protected currentPhoneNo = '';
  protected adBlockerNo = '';

  protected readonly noticeList = [
    'MEMBER.SMS.DISPATCH_NOTICE_1',
    'MEMBER.SMS.DISPATCH_NOTICE_2',
    'MEMBER.SMS.DISPATCH_NOTICE_3',
  ];
  protected smsType: SmsType | '' = '';
  protected readonly smsTypeList: OptionData<SmsType>[] = [
    { id: 'info', value: 'INFORMATION', name: 'MEMBER.SMS.INFO' },
    { id: 'ad', value: 'AD', name: 'MEMBER.SMS.AD' },
  ];
  protected sendType: SendType | '' = '';
  protected readonly sendTypeOption: RadioBoxGroupOption<SendType> = {
    name: 'sendType',
    data: [
      { id: 'sms', value: 'SMS', display: 'MEMBER.SMS.TYPE_SMS' },
      { id: 'lms', value: 'LMS', display: 'MEMBER.SMS.TYPE_LMS' },
    ],
  };
  protected selectDestinationType: SelectDestinationType | '' = '';
  protected readonly selectDestinationOption: RadioBoxGroupOption<SelectDestinationType> = {
    name: 'selectDestination',
    data: [
      { id: 'all', value: 'all', display: 'MEMBER.SMS.ALL_MEMBER' },
      { id: 'select', value: 'select', display: 'MEMBER.SMS.SELECT_MEMBER' },
      { id: 'directInput', value: 'directInput', display: 'MEMBER.SMS.DIRECT_INPUT' },
      // { id: 'uploadExcel', value: 'uploadExcel', display: 'COMMON.EXCEL.UPLOAD' },
    ],
  };
  protected allCount = 0;
  protected rejectCount = 0;
  protected directInput = '';
  protected sendConfigType: SendConfigType | '' = '';
  protected readonly sendConfigOption: RadioBoxGroupOption<SendConfigType> = {
    name: 'sendConfig',
    data: [
      { id: 'immediately', value: 'immediately', display: 'MEMBER.SMS.IMMEDIATELY_SEND' },
      { id: 'reservation', value: 'reservation', display: 'MEMBER.SMS.RESERVATION_SEND' },
    ],
  };
  protected title = '';
  protected content = '';
  protected requestDateTime = '';
  protected isDatePickerReset = false;

  protected get isAdType(): boolean {
    return this.smsType === 'AD';
  }

  get dispatchContent(): string {
    const contentLength = this.content.length;
    const excludeAdLength = contentLength - MAX_LENGTH.AD_DEFAULT;
    const isAdOverLength = this.isAdType && contentLength > this.maxContentLength;
    return isAdOverLength ? this.content.substr(MAX_LENGTH.NONE, excludeAdLength) : this.content;
  }

  get dispatchTitle(): string {
    const overTitleLength = this.title.length > MAX_LENGTH.LMS_TITLE;
    return overTitleLength ? this.title.substr(MAX_LENGTH.NONE, MAX_LENGTH.LMS_TITLE) : this.title;
  }

  get dispatchRecipients(): (string | number)[] {
    if (this.selectDestinationType === 'directInput') {
      return this.phoneNoList.map(value => value.split('-').join(''));
    } else if (this.selectDestinationType === 'uploadExcel') {
      return this.phoneNoList;
    } else {
      return this.memberNoList.map(({ memberNo }) => memberNo);
    }
  }

  protected get maxTitleLength(): number {
    return this.sendType === 'SMS' ? MAX_LENGTH.NONE : MAX_LENGTH.LMS_TITLE;
  }

  protected get maxContentLength(): number {
    return this.sendType === 'SMS' ? MAX_LENGTH.SMS_CONTENT : MAX_LENGTH.LMS_CONTENT;
  }

  protected get realMaxContentLength(): number {
    return this.maxContentLength;
  }

  protected get contentPlaceholder(): LocaleMessage {
    return this.isAdType
      ? this.$t('MEMBER.SMS_DISPATCH.PLACEHOLDER_AD_CONTENT', [this.adBlockerNo])
      : this.$t('MEMBER.SMS.CONTENT_PLACEHOLDER');
  }

  protected getByteLength(message: string): number {
    let length, index, char;
    // eslint-disable-next-line no-cond-assign
    for (length = index = 0; (char = message.charCodeAt(index++)); length += char >> 7 ? 2 : 1);
    return length;
  }

  protected get contentLength(): number {
    const basicLength = this.getByteLength(this.content);
    return this.isAdType ? basicLength + MAX_LENGTH.AD_DEFAULT : basicLength;
  }

  protected get showSelectedNumber(): boolean {
    return this.selectDestinationType === 'all' || this.selectDestinationType === 'select';
  }

  protected get selectDestCount(): number {
    if (this.showSelectedNumber) {
      return this.allCount;
    } else if (this.selectDestinationType === 'directInput' || this.selectDestinationType === 'uploadExcel') {
      return this.phoneNoList.length;
    }
    return 0;
  }
}
