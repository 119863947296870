





































































































import { Component, Prop, Ref, Mixins, Watch } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import { PostImagesResponse, GetSkinsMallsMallNo } from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import { PlatformRadioInfo, Skin, SkinFileProcessingPopupInfo } from '@/types/design';
import { getCurrentMallNo } from '@/utils/mall';
import SkinListMixin from '@/views/contents/appearance/basic/mixins/SkinListMixin';
import axios, { CancelTokenSource } from 'axios';
import { throwPopup, PopupClose, throwMessagePopup } from '@/helpers/popup';
import RadioGroup from '@/components/common/RadioGroup.vue';

@Component({
  components: {
    RadioGroup,
    ModalHeader,
    TextInput,
  },
})
export default class DesignSkinEdit extends Mixins(SkinListMixin) {
  @Prop() private data!: Skin;

  private skin: Skin = {} as Skin;
  private skinFile: File = null;
  private platformRadioInfo: PlatformRadioInfo = {
    option: {
      name: 'platform',
      data: [
        {
          id: 'platformPC',
          value: 'PC',
          display: 'APPEARANCE.BASIC.SKIN.SKIN_PLATFORM_PC',
        },
        {
          id: 'platformMobile',
          value: 'MOBILE_WEB',
          display: 'APPEARANCE.BASIC.SKIN.SKIN_PLATFORM_MOBILE_WEB',
        },
      ],
    },
    value: '',
    disabled: false,
  };

  get getTitle(): string {
    if (this.skin.code) {
      return this.$t('APPEARANCE.BASIC.SKIN.SKIN_MODIFY_POPUP_TIT') as string;
    } else {
      return this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_TIT') as string;
    }
  }

  get getSkinCode(): string {
    if (this.skin.code) {
      return this.skin.code;
    } else {
      return this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_NO_CODE') as string;
    }
  }

  private get getImgFileName(): string {
    const fileNames: string[] = this.skin.thumbnail?.split('/');
    return fileNames ? fileNames[fileNames.length - 1] : '';
  }

  private get getZipFileName(): string {
    const fileNames: string[] = this.skin.filename.split('/');
    return fileNames[fileNames.length - 1];
  }

  created() {
    this.skin = this.data;
    this.platformRadioInfo.value = this.skin.platform ?? 'PC';
    this.platformRadioInfo.disabled = this.skin.platform !== undefined;
  }

  @Watch('platformRadioInfo.value')
  private changeSkinPlatform(): void {
    this.skin.platform = this.platformRadioInfo.value;
  }

  // 업로드
  @Ref() private readonly zipUploadInput!: HTMLInputElement;
  private async addZip({ target }: Event) {
    const zipFile = Array.from(this.zipUploadInput.files as FileList)[0];

    if (zipFile) {
      this.skinFile = zipFile;
      this.skin.filename = zipFile.name;
      this.skin.zipFileName = zipFile.name;

      this.$forceUpdate();
    }
    (target as HTMLInputElement).value = '';
  }

  private getSkinFileFormData(keyName: string): FormData {
    const skinFileFormData: FormData = new FormData();
    this.skinFile && skinFileFormData.append(keyName, this.skinFile);

    return skinFileFormData;
  }

  // 썸네일 이미지
  @Ref() private readonly imageUploadInput!: HTMLInputElement;
  private addImg({ target }: Event): void {
    const imageFile = Array.from(this.imageUploadInput.files as FileList)[0];
    if (imageFile) {
      this.upload(imageFile, (data: PostImagesResponse) => {
        this.skin.thumbnail = data.url;
        this.skin.imgFileName = imageFile.name;

        this.$forceUpdate();
      });
    }
    (target as HTMLInputElement).value = '';
  }
  private upload(file: File, setSkinImage: (data: PostImagesResponse) => void): void {
    const FILE_EXT_REG = /\.(jpg|jpeg|png|gif)$/;
    const FILE_MAX_SIZE = 10 * 1024 * 1024; // 10MB 까지 업로드 가능
    if (!file.name.toLowerCase().match(FILE_EXT_REG)) {
      alert(this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_THUMBNAIL_FILE_EXT'));
      return;
    }
    if (file.size > FILE_MAX_SIZE) {
      alert(this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_THUMBNAIL_FILE_LIMIT_CAPACITY'));
      return;
    }
    const data = new FormData();
    data.append('file', file);
    this.$api.postImages({ data, params: { mallNo: getCurrentMallNo(this) } }).then(res => {
      setSkinImage(res.data);
    });
  }
  private removeImage(): void {
    this.skin.thumbnail = '';
    this.skin.imgFileName = '';

    this.$forceUpdate();
  }

  // confirm pop-up information for uploadOrModifySkinFile according to processName
  private getSkinFileProcessingPopupInfo(processName: 'upload' | 'modify'): SkinFileProcessingPopupInfo | null {
    const skinFileFormData: FormData = this.getSkinFileFormData('file');

    switch (processName) {
      case 'upload':
        return {
          popupTitle: this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_UPLOADING_TIT'),
          processingMessage: this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_UPLOADING_MSG', {
            skinName: this.skin.name,
          }),
          successMessage: this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_MSG_UPLOAD_SUCCESS'),
          request: this.getPostSkinsRequest(this.skin, skinFileFormData),
          postOrPutSkinFile: this.postSkins,
        };
      case 'modify':
        return {
          popupTitle: this.$t('APPEARANCE.BASIC.SKIN.SKIN_MODIFY_POPUP_TIT'),
          processingMessage: this.$t('APPEARANCE.BASIC.SKIN.SKIN_MODIFY_POPUP_MODIFYING_MSG', {
            skinName: this.skin.name,
          }),
          successMessage: this.$t('APPEARANCE.BASIC.SKIN.SKIN_MODIFY_POPUP_MSG_MODIFY_SUCCESS'),
          request: this.getPutSkinsSkinNoRequest(this.skin, skinFileFormData),
          postOrPutSkinFile: this.putSkinsSkinNo,
        };
      default:
        console.error('getTreatSkinFilePopupInfo error');
        return;
    }
  }

  // 캔슬 가능한 '진행 중' 팝업 띄우면서 api 요청하기
  private async postOrPutSkinFileWithProcessingPopup(skinFileProcessingPopupInfo: SkinFileProcessingPopupInfo) {
    const { popupTitle, processingMessage, request, postOrPutSkinFile } = skinFileProcessingPopupInfo;
    const source: CancelTokenSource = axios.CancelToken.source();

    throwPopup({
      name: 'CancelHttpRequest',
      data: {
        headerText: popupTitle,
        message: processingMessage,
      },
    }).then(() => {
      source.cancel();
    });

    await postOrPutSkinFile({ ...request, cancelToken: source.token });
  }

  private closePrevPopupAndThisPopup(): void {
    (this.lastPopupItem.onClose as Function)({ state: PopupClose.CLOSE, data: null });
    this.$emit('click:close');
  }

  // 진행 중 팝업과 함께하는 스킨 업로드 및 수정
  private async uploadOrModifySkinFile(processName: 'upload' | 'modify'): Promise<void> {
    const skinFileProcessingPopupInfo: SkinFileProcessingPopupInfo | null = this.getSkinFileProcessingPopupInfo(
      processName,
    );
    if (!skinFileProcessingPopupInfo) return;
    const { popupTitle, successMessage } = skinFileProcessingPopupInfo;

    try {
      await this.postOrPutSkinFileWithProcessingPopup(skinFileProcessingPopupInfo);
      this.closePrevPopupAndThisPopup();
      throwMessagePopup(successMessage, false, popupTitle);
    } catch (e) {
      if (e.code !== 'CANCEL_ERROR') {
        this.closePrevPopupAndThisPopup();
        console.error(e);
      }
    }
  }

  //스킨 업로드 하기
  private async uploadSkin(): Promise<void> {
    if (!(await this.validate())) return;

    this.skin.init = !this.skin.code;
    //TODO 신규 스킨은 고도스킨 / 사용자 스킨 선택 여부가 없다
    this.skin.authorType = 'USER';

    await this.uploadOrModifySkinFile('upload');
  }

  // 스킨 수정 하기
  private async modifySkin(): Promise<void> {
    if (!(await this.validate())) return;

    await this.uploadOrModifySkinFile('modify');
  }

  @Ref() private readonly skinNameRef!: TextInput;
  private async validate(): Promise<boolean> {
    await this.checkInUseWorkspace(this.data.mallNo);
    if (this.isInUseWorkspace) {
      alert(this.$t('APPEARANCE.BASIC.SKIN.WORKSPACE_NOT_ALLOWED_UPLOADING'));
      return false;
    }

    // get all skinName for duplicated skin name validation
    if (!this.skin?.name) {
      alert(this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_MSG_CHECK_NAME'));
      return false;
    }

    // The test about duplicated skin name is underway at the Back-end

    if (this.skin.isZipSkin) {
      if (!this.skin.filename) {
        alert(this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_MSG_CHECK_ZIP'));
        return false;
      }

      // 'pureNameMatcher' is matcher to erase the name of the extension.
      const pureNameMatcher = /.+(?=\.)/m;
      const filenameRegex = /^[0-9a-zA-Z]*$/;

      const thumbnailFileName = this.skin.imgFileName || this.getImgFileName;
      const pureSkinFilename = this.skin.filename.match(pureNameMatcher)[0];
      const pureThumbnailFilename = thumbnailFileName !== '' ? thumbnailFileName.match(pureNameMatcher)[0] : null;

      const isInvalidFilename =
        !filenameRegex.test(pureSkinFilename) || (pureThumbnailFilename && !filenameRegex.test(pureThumbnailFilename));
      if (isInvalidFilename) {
        alert(this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_MSG_CHECK_FILE_NAME'));
        return false;
      }

      const FILE_EXT_REG = /\.(zip)$/;
      if (!this.skin.filename.toLowerCase().match(FILE_EXT_REG)) {
        alert(this.$t('APPEARANCE.BASIC.SKIN.SKIN_NEW_POPUP_UPLOAD_FAILED'));
        this.$emit('click:close');
        return;
      }
    }

    return true;
  }

  private async getAllSkinName(): Promise<string[]> {
    const request = this.getGetSkinsMallsMallNoRequest(this.data.mallNo);
    const data = await this.getSkinsMallsMallNo(request);
    const skinNames = data.map((item: GetSkinsMallsMallNo): string => {
      if (item.platform === this.data.platform) {
        return item.skinName;
      }
    });
    return skinNames;
  }
}
