export default Object.freeze({
  //ADMIN DEPARTMENT
  TITLE: '운영자 관리',
  ACCOUNT: '운영자 계정관리',
  POSITION: '부서/직책관리',
  JOURNAL: '운영자 로그조회',
  DEPARTMENT: '부서명',
  DEPARTMENT_TITLE: '직책명',
  ERRMESSAGE: '더 이상 움직일 수 없습니다.',
  ADD_GROUP: '부서추가',
  ADD_GROUP_JOBDUTY: '직책추가',
  DELETE_GROUP: '삭제',
  NEW_GROUP: '새 부서명을 입력하세요',
  NEW_GROUP2: '새 직책명을 입력하세요',
  ADD_GROUP_GUIDE: '배송 묶음그룹을 추가해 주세요.',
  CONFIRM: '저장 후 이동해 주세요.',
  SET_DEPARTMENT: '부서명 상세설정',
  SAVE: '변경사항 저장',
  JOBDUTY_TITLE: '직책명',
  SET_JOBDUTY: '직책명 상세설정',
  NAME_ERROR: '부서명을 입력해주세요.',
  NAME_ERROR2: '직책명을 입력해주세요.',
  DELETE: '삭제하시겠습니까?',
  UPDATE_SUCCESS: '변경사항이 저장되었습니다.',
  UPDATE_WHETHER: '변경사항을 저장하시겠습니까?',
});
