







































import {
  DisplayCategoriesTree,
  StandardCategoriesTree,
  StandardCategory,
  DisplayCategoriesView,
  YorN,
} from 'ncp-api-supporter';
import { Component, Prop, Vue, Model, Ref, Watch } from 'vue-property-decorator';
import SearchModeSwitchButton from '@/components/common/categories/parts/SearchModeSwitchButton.vue';
import KeywordFinder from '@/components/common/categories/parts/KeywordFinder.vue';
import SelectFinder from '@/components/common/categories/parts/SelectFinder.vue';
import PickedList, { RemoveEmitData } from '@/components/common/categories/parts/PickedList.vue';

export type KeywordData = StandardCategory | DisplayCategoriesView; // FIXME: 이 방법 밖에 없나.. ㅠㅠ
export type SelectData = StandardCategoriesTree | DisplayCategoriesTree;
export type PickedData = { id: number; name: string };
export type SearchMode = 'keyword' | 'select';
export type SelectMode = 'single' | 'multiple';
export interface MultiCategorySelectorData {
  keywordFindData: KeywordData[];
  selectFindData: SelectData[];
}
export interface MultiCategorySelectorOptions {
  selectMode: SelectMode;
  keywordKeys: KeywordKeys;
  selectKeys: SelectKeys;
}
export interface KeywordKeys {
  uniqueKey: string;
  fullNameKey: string;
}
export interface SelectKeys {
  uniqueKey: string;
  fullNameKey: string;
  nameKey: string;
  childKey: string;
}

@Component({
  components: {
    SearchModeSwitchButton,
    KeywordFinder,
    SelectFinder,
    PickedList,
  },
})
export default class MultiCategoriesSelector extends Vue {
  @Model('change') readonly value: number[];

  @Prop({ required: true })
  private readonly data: MultiCategorySelectorData;
  @Prop({ required: true })
  private readonly options: MultiCategorySelectorOptions;
  @Prop({ default: true })
  private readonly canUseStandard: boolean;
  @Prop({ default: false })
  private disabled!: boolean;
  @Prop({ default: '' })
  private className: string;
  @Prop({ required: false, default: false })
  private disabledKeyword?: boolean;
  @Prop({ default: () => [] })
  private readonly defaultSelectCategoriesNo!: number[];
  @Prop({ default: false })
  private readonly noQuery!: boolean;
  @Prop({ default: false })
  private readonly updateMinorPurchaseYn: (isForAdults: boolean) => void;
  @Prop({ default: false })
  private readonly setIsBookCategory: (isBook: boolean) => void;
  @Prop({ required: false })
  private readonly resetCategoryData!: () => void;
  @Prop({ required: true })
  private readonly mode!: 'DISPLAY' | 'STANDARD';
  @Prop({ required: false })
  private readonly minorPurchaseYn?: YorN;

  @Ref()
  private readonly switchButton!: SearchModeSwitchButton;
  @Ref()
  private readonly keywordFinder!: KeywordFinder;
  @Ref()
  private readonly selectFinder!: SelectFinder;

  private searchMode: SearchMode = 'keyword';
  private pickedData: PickedData[] = [];
  private isEditLoading = false;
  @Watch('defaultSelectCategoriesNo')
  @Watch('data.keywordFindData')
  initKeywordFindData() {
    if (this.defaultSelectCategoriesNo.length > 0) {
      this.initDefaultData(this.defaultSelectCategoriesNo);
    }
  }

  private initDefaultData(defaultSelectCategoriesNo: number[]): void {
    const categories: KeywordData[] = defaultSelectCategoriesNo
      .map(no => this.reverseDataParseFromUniqueKey(no))
      .filter(Boolean);
    categories.forEach(category => {
      this.setChosenCategory(category, 'keyword');
    });
  }

  private reverseDataParseFromUniqueKey(uniqueNo: number): KeywordData {
    const { uniqueKey } = this.options.keywordKeys;
    return this.data.keywordFindData.find(item => item[uniqueKey] === uniqueNo);
  }

  private setChosenCategory(category: KeywordData | SelectData, searchMode: SearchMode): void {
    const { uniqueKey } = searchMode === 'keyword' ? this.options.keywordKeys : this.options.selectKeys;

    const unique = category[uniqueKey];
    const alreadyExists = this.pickedData.some(item => item.id === unique);
    if (alreadyExists) return;

    if (this.options.selectMode === 'single') {
      this.pickedData = [this.convertToPickedData(category, searchMode)];
    }
    if (this.options.selectMode === 'multiple') {
      this.pickedData.push(this.convertToPickedData(category, searchMode));
    }

    this.setStandardCategoryData(category);
    this.emitChosenData();
  }

  private setStandardCategoryData(category: KeywordData | SelectData) {
    if ('originType' in category) {
      this.setIsBookCategory(category.originType === 'BOOK');
    }
    // 수정페이지 로딩 & 미성년자 구매불가( 성인인증 사용함 ) &  성인인증필요한 카테고리가 아닌 경우 : updateMinorPurchaseYn 하지않고 return
    if (this.isEditLoading && this.minorPurchaseYn === 'N' && !this.isAdultCategory(category)) {
      this.isEditLoading = false;
      return;
    }
    this.updateMinorPurchaseYnAccordingToCategory(category);
    this.isEditLoading = false;
  }

  private isAdultCategory(category: KeywordData | SelectData): boolean {
    /*
     * forAdults : 카테고리 트리 선택할 때 표준 카테고리 트리 조회 ( getStandardCategoriesTree )에서 성인카테고리 여부
     * requiresAdultCertification : 카테고리 키워드 검색할 때 전체 표준카테고리 조회 ( getStandardCategories ) 에서 성인 인증 여부,
     * */
    if ('forAdults' in category) {
      return category.forAdults;
    }
    if ('requiresAdultCertification' in category) {
      return category.requiresAdultCertification;
    }
  }

  private updateMinorPurchaseYnAccordingToCategory(category: KeywordData | SelectData) {
    if ('forAdults' in category) {
      this.updateMinorPurchaseYn(category.forAdults);
    }

    if ('requiresAdultCertification' in category) {
      this.updateMinorPurchaseYn(category.requiresAdultCertification);
    }
  }
  @Watch('pickedData')
  private emitChosenData(): void {
    this.$emit(
      'change',
      this.pickedData.map(category => category.id),
    );
  }

  private convertToPickedData(category: KeywordData | SelectData, searchMode: SearchMode): PickedData {
    const { uniqueKey, fullNameKey } = searchMode === 'keyword' ? this.options.keywordKeys : this.options.selectKeys;
    return {
      id: category[uniqueKey],
      name: category[fullNameKey],
    };
  }

  public reset() {
    this.searchMode = 'keyword';
    this.pickedData = [];
  }

  private finderReset(data: RemoveEmitData): void {
    const currentComponent = this.searchMode === 'keyword' ? this.keywordFinder : this.selectFinder;
    currentComponent.reset(data);
  }

  public focus(): void {
    this.switchButton.focus();
  }

  created() {
    if (this.$route.path.includes('edit')) this.isEditLoading = true;
  }
}
