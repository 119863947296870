// NOTE: ./PartnerRegisterIndex.vue 의 <router-link> v-for를 위해 사용됩니다.
export default [
  {
    name: 'PartnerRegisterNew',
    title: 'PARTNER.REGISTER.LOGIN_NEW_TITLE', // 파트너 신규등록
    descriptions: ['PARTNER.REGISTER.LOGIN_NEW_CONTENT1', 'PARTNER.REGISTER.LOGIN_NEW_CONTENT2'],
    thumbnail: 'icon_partner_new@2x',
  },
  {
    name: 'PartnerRegisterSearch',
    title: 'PARTNER.REGISTER.LOGIN_POOL_CONTRACT_TITLE', // 파트너 검색/등록
    descriptions: ['PARTNER.REGISTER.LOGIN_POOL_CONTRACT_CONTENT1', 'PARTNER.REGISTER.LOGIN_POOL_CONTRACT_CONTENT2'],
    thumbnail: 'icon_partner_search@2x',
  },
  {
    name: 'PartnerRegisterCopySearch',
    title: 'PARTNER.REGISTER.LOGIN_POOL_COPY_TITLE', //파트너 복사등록
    descriptions: ['PARTNER.REGISTER.LOGIN_POOL_COPY_CONTENT1', 'PARTNER.REGISTER.LOGIN_POOL_COPY_CONTENT2'],
    thumbnail: 'icon_option_general@2x',
  },
];
