





















import { Vue, Component } from 'vue-property-decorator';
import {
  AdminRole,
  AdminsService,
  PostDepartmentsRequest,
  PostJobDutyRequest,
  PutAdminsServiceByAdminNoRequest,
} from 'ncp-api-supporter';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { unescapeHtml } from '@/utils/common';
import { Action, Getter, namespace } from 'vuex-class';
import { Admin } from '@/types';
import AdminForm from '@/views/contents/configuration/management/adminChild/AdminForm.vue';
import { logout } from '@/utils/token';
import AdminPrivacyTerms from '@/components/configuration/AdminPrivacyTerms.vue';

const adminStore = namespace('admin');

@Component({ components: { AdminPrivacyTerms, AdminForm } })
export default class AdminEdit extends Vue {
  @Getter('admin/getAdmin') private me: Admin;

  @Action('setInitUnsavedForm')
  private readonly setInitUnsavedForm!: () => void;

  @adminStore.Action('setCommerceAccount')
  private setCommerceAccount: (commerceAccount: boolean) => void;

  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  @adminStore.Action('setSelectedAdmin')
  private setSelectedAdmin: (selectedAdminRole: AdminRole) => void;

  private adminNo = 0;

  private adminInfo: AdminsService = {
    adminName: '',
    authorityGroup: {
      no: 0,
      name: '',
    },
    jobDuty: {
      no: 0,
      name: '',
    },
    contact: {
      mobileNo: '',
      phoneNo: '',
      email: '',
    },
    adminId: '',
    adminRole: null,
    otp: {
      used: false,
    },
    department: {
      no: 0,
      name: '',
    },
    jobPositionName: '',
    externalAccessEnabled: false,
    commerceAccount: false,
    permittedIpAddresses: [],
  };
  private originInfo: AdminsService = { ...this.adminInfo };

  private useIpAccessControl = false;
  private ipRowsLength = 0;

  private smsAuthKey = '';

  private myIp = '';

  private isMaster(): boolean {
    return this.adminInfo.adminRole === 'MASTER';
  }

  created() {
    this.adminNo = Number(this.$route.query.adminNo);

    this.getAdminsServiceByAdminNo().then((): void => {
      this.throwBottomNavigation();
    });

    this.fetchManagerInfo();
  }

  /**
   * get admin info by admin no
   */
  private getAdminsServiceByAdminNo(): Promise<void> {
    return this.$api.getAdminsServiceByAdminNo({ params: { adminNo: this.adminNo + '' } }).then(response => {
      if (response && response.status === 200) {
        if (!response.data.authorityGroup) {
          response.data.authorityGroup = this.adminInfo.authorityGroup;
        }
        if (!response.data.department) {
          response.data.department = this.adminInfo.department;
        }
        if (!response.data.jobDuty) {
          response.data.jobDuty = this.adminInfo.jobDuty;
        }
        if (!response.data.contact) {
          response.data.contact = this.adminInfo.contact;
        }

        response.data.jobPositionName = unescapeHtml(response.data.jobPositionName);

        this.adminInfo = response.data;
        this.ipRowsLength = response.data.permittedIpAddresses.length;
        this.useIpAccessControl = this.ipRowsLength > 0;
        this.setSelectedAdmin(response.data.adminRole);

        this.originInfo = {
          ...this.adminInfo,
          authorityGroup: { ...this.adminInfo.authorityGroup },
          department: { ...this.adminInfo.department },
          jobDuty: { ...this.adminInfo.jobDuty },
          contact: { ...this.adminInfo.contact },
          otp: { ...this.adminInfo.otp },
        };
      }
    });
  }

  /**
   * delete admin by admin no
   */
  private deleteAdminsServiceByAdminNo(): Promise<boolean> {
    return this.$api
      .deleteAdminsServiceByAdminNo({
        params: {
          adminNo: this.adminNo + '',
        },
      })
      .then((response): boolean => {
        if (response && response.status === 204) {
          return true;
        }
        return false;
      })
      .catch((): boolean => {
        return false;
      });
  }

  private putAdminsServiceByAdminNo(): Promise<boolean> {
    const request: PutAdminsServiceByAdminNoRequest = {
      params: {
        adminNo: this.adminNo + '',
      },
      data: {
        certKey: this.smsAuthKey,
        phone: this.adminInfo.contact.phoneNo,
        authorityGroupNo: this.adminInfo.authorityGroup.no,
        mobile: this.adminInfo.contact.mobileNo,
        departmentNo: this.adminInfo.department.no,
        jobPositionName: this.adminInfo.jobPositionName,
        email: this.adminInfo.contact.email,
        externalAccessEnabled: this.adminInfo.externalAccessEnabled,
        jobDutyNo: this.adminInfo.jobDuty.no,
        permittedIpAddresses: this.useIpAccessControl ? this.adminInfo.permittedIpAddresses : null,
      },
    };

    if (!this.adminInfo.department.no) {
      delete request.data.departmentNo;
    }
    if (!this.adminInfo.jobDuty.no) {
      delete request.data.jobDutyNo;
    }
    if (!this.smsAuthKey) {
      delete request.data.certKey;
    }

    return this.$api
      .putAdminsServiceByAdminNo(request)
      .then(response => {
        if (response && response.status === 204) {
          return true;
        }

        return false;
      })
      .catch(error => {
        const errorControl = {
          404: () => alert(this.$t('CONFIGURATION.ADMIN.ALERT_DELETED_GROUP')),
        };
        errorControl[error.status]?.();
        return false;
      });
  }

  private isEditAdminInfo(): boolean {
    for (const key in this.adminInfo) {
      if (this.adminInfo[key] instanceof Object) {
        for (const childKey in this.adminInfo[key]) {
          if (this.adminInfo[key][childKey] !== this.originInfo[key][childKey]) {
            return true;
          }
        }
      } else if (this.adminInfo[key] !== this.originInfo[key]) {
        return true;
      }
    }

    return false;
  }

  /**
   * delete admin
   */
  private delete(): void {
    if (!confirm(this.$t('CONFIGURATION.ADMIN.CONFIRM_DELETE') as string)) {
      return;
    }

    this.deleteAdminsServiceByAdminNo().then((success: boolean) => {
      if (success) {
        alert(this.$t('CONFIGURATION.ADMIN.ALERT_DELETE_SUCCESS'));

        if (this.adminNo === this.me.no) {
          logout();
        } else {
          this.$router.push({ name: 'Admin' });
        }
      }
    });
  }

  private async createDepartment() {
    const request: PostDepartmentsRequest = {
      data: {
        departmentName: this.adminInfo.department.name,
      },
    };
    try {
      const { data } = await this.$api.postDepartments(request);
      this.adminInfo.department = {
        name: data.name,
        no: data.no,
      };
    } catch (e) {
      console.error(e);
    }
  }

  private async createJobDuty() {
    const request: PostJobDutyRequest = {
      data: {
        jobDutyName: this.adminInfo.jobDuty.name,
      },
    };
    try {
      const { data } = await this.$api.postJobDuties(request);
      this.adminInfo.jobDuty = {
        name: data.name,
        no: data.no,
      };
    } catch (e) {
      console.error(e);
    }
  }

  private async checkCreatedInfo() {
    if (this.adminInfo.department.name && !this.adminInfo.department.no) {
      await this.createDepartment();
    }
    if (this.adminInfo.jobDuty.name && !this.adminInfo.jobDuty.no) {
      await this.createJobDuty();
    }
  }

  private async fetchManagerInfo() {
    const { data } = await this.$api.getManagerInfo();
    this.setCommerceAccount(data.commerceAccount);
    this.myIp = data?.ip ?? '';
  }

  private async save() {
    if (!(this.$refs.adminForm as AdminForm).validate()) {
      return;
    }

    await this.checkCreatedInfo();
    const success = await this.putAdminsServiceByAdminNo();
    if (success) {
      alert(this.$t('SAVE_SUCCESS'));
      await this.$router.push({ name: 'Admin' });
    }
  }

  private throwBottomNavigation(): void {
    const buttons = [];
    if (!this.isMaster()) {
      buttons.push({
        type: 'left',
        key: 'delete',
        text: this.$t('CONFIGURATION.ADMIN.DELETE_ADMIN'),
      });
    }
    buttons.push({
      type: 'right',
      key: 'list',
      text: this.$t('LIST'),
    });
    buttons.push({
      type: 'right',
      key: 'save',
      color: 'red',
      text: this.$t('SAVE'),
    });

    throwBottomNavigation({
      buttons,
      onClick: (key: string) => {
        switch (key) {
          case 'delete':
            this.delete();
            break;
          case 'list':
            if (this.isEditAdminInfo() && !confirm(this.$t('CONFIGURATION.ADMIN.CONFIRM_CANCEL_EDIT') as string)) {
              break;
            }

            this.setInitUnsavedForm();

            this.$router.push({ name: 'Admin' });
            break;
          case 'save':
            this.save();
            break;
        }
      },
    });
  }
}
