




























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ClaimData } from 'ncp-api-supporter';
import AmountMixin from '@/components/popup/claim/AmountMixin';

// 반품리스트 기획서 p.58
// 반품처리내역과 반품승인처리 팝업 디자인이 다름
@Component
export default class BenefitRecalculate extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly amounts!: ClaimData['amounts'];

  private accumulation = {
    totalAccumulationAmt: 0, // 재계산 합계
    beforeAccumulationAmt: 0, // 변경 전 적립금
    afterAccumulationAmt: 0, // 변경 후 적립금
  };

  created() {
    this.setAccumulation();
  }

  setAccumulation() {
    const { after, before } = this.amounts;
    this.accumulation = {
      ...this.accumulation,
      beforeAccumulationAmt: before.accumulation.accumulationAmt,
      afterAccumulationAmt: after.accumulation.accumulationAmt,
      totalAccumulationAmt: after.accumulation.accumulationAmt - before.accumulation.accumulationAmt,
    };
  }
}
