import { Vue, Component } from 'vue-property-decorator';
import $ from 'jquery';

@Component
export default class DeliveryScanning extends Vue {
  public scanningType = 'INVOICE';
  public theOptionCode = null;
  public invoiceNoFlag = true;
  public currentScannedCnt = 0;
  public totalCount = 0;
  public constants = {
    QUANTITY_OUT: 'quantity out',
    NO_MATCH: 'no match',
    SUCCESS: 'success',
  };

  public groupBy(list) {
    const map = new Map();
    list.forEach(item => {
      const key = item.orderNo;
      item.product.taskMessageNo = item.taskMessageNo;
      item.product.orderCnt = item.orderCnt;
      item.product.orderNo = item.orderNo;
      item.product.theScannedCnt = item.theScannedCnt;
      item.product.orderProductOptionNos = item.orderProductOptionNo;
      item.product.orderProductOptionNo = item.orderProductOptionNo[0];

      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item.product]);
      } else {
        collection.push(item.product);
      }
    });

    return map;
  }
  public convertTo2Column(a, b?) {
    if (!b) {
      b = {};
    }
    return {
      orderProductOptionNos: a.orderProductOptionNos,
      orderProductOptionNos1: b.orderProductOptionNos,
      orderNo: a.orderNo,
      mallProductNo: a.mallProductNo,
      productName: a.productName,
      optionName: a.optionName,
      optionValue: a.optionValue,
      optionManagementCd: a.optionManagementCd,
      orderCnt: a.orderCnt,
      orderProductOptionNo: a.orderProductOptionNo,
      mallOptionNo: a.mallOptionNo,
      taskMessageNo: a.taskMessageNo,
      mallProductNo1: b.mallProductNo,
      productName1: b.productName,
      optionName1: b.optionName,
      optionValue1: b.optionValue,
      optionManagementCd1: b.optionManagementCd,
      orderCnt1: b.orderCnt,
      orderProductOptionNo1: b.orderProductOptionNo,
      mallOptionNo1: b.mallOptionNo,
      taskMessageNo1: b.taskMessageNo,
      filled: a.filled,
      filled1: b.filled,
      theScannedCnt: a.theScannedCnt,
      theScannedCnt1: b.theScannedCnt,
    };
  }
  public mergeRow(className, isProductNo = false, isProductName = false) {
    let rowCount = 0;
    $(
      $(`${this.scanningType === 'OPTION' ? '#optionScanning' : '#invoiceScanning'} ${className}`)
        .get()
        .reverse(),
    ).each((index, el) => {
      const $prevRow = $(el)
        .parent()
        .prev();
      const $prevColumn = $prevRow.find(className);
      const elOrderNo = $(el)
        .parent()
        .find('.rowSpanOrderNo')
        .text();
      if (
        $prevColumn.text() === $(el).text() &&
        (!isProductNo || $prevRow.find('.rowSpanOrderNo').text() === elOrderNo) &&
        (!isProductName ||
          ($prevColumn.prev().text() ===
            $(el)
              .prev()
              .text() &&
            $prevRow.find('.rowSpanOrderNo').text() === elOrderNo))
      ) {
        $(el).remove();
        rowCount += 1;
      } else {
        $(el).attr('rowspan', rowCount + 1);
        rowCount = 0;
      }
    });
  }

  public addScanCnt($optionCd) {
    const $scannedCnt = $optionCd.next().next();
    let scannedCnt = Number($scannedCnt.text());
    const orderCnt = Number($optionCd.next().text());
    scannedCnt = scannedCnt + 1;
    $scannedCnt.text(scannedCnt);

    this.currentScannedCnt = this.currentScannedCnt + 1;
    this.theOptionCode = null;

    if (scannedCnt === orderCnt) {
      $optionCd.prev().addClass('fill_div');
      $optionCd.addClass('fill_div');
      $optionCd.next().addClass('fill_div');
      $optionCd
        .next()
        .next()
        .addClass('fill_div');
      $optionCd
        .next()
        .next()
        .next()
        .addClass('fill_div');
    }

    if (this.currentScannedCnt === this.totalCount) {
      this.invoiceNoFlag = false;
      $('#invoiceNo').focus();
    }
  }
  public handleAddClick(itm, name: string, arr): void {
    const n = name;
    if (itm[n] === undefined) {
      return;
    }
    if (name === 'mallProductNo') {
      if (itm.theScannedCnt < itm.orderCnt) {
        itm.theScannedCnt += 1;
        if (itm.theScannedCnt === itm.orderCnt) {
          itm.filled = true;
        }
        arr.push([]);
        arr.pop([]);
      } else {
        alert(this.$t('ORDER.SCANNING.ALERT_M8'));
        this.theOptionCode = null;
        $('#optionCode').focus();
        return;
      }
    } else {
      if (itm.theScannedCnt1 < itm.orderCnt1) {
        itm.theScannedCnt1 += 1;
        if (itm.theScannedCnt1 === itm.orderCnt1) {
          itm.filled1 = true;
        }
        arr.push([]);
        arr.pop([]);
      } else {
        alert(this.$t('ORDER.SCANNING.ALERT_M8'));
        this.theOptionCode = null;
        $('#optionCode').focus();
        return;
      }
    }

    this.currentScannedCnt = this.currentScannedCnt + 1;
    this.theOptionCode = null;
    if (this.currentScannedCnt === this.totalCount) {
      this.invoiceNoFlag = false;
      $('#invoiceNo').focus();
    }
  }

  public scanToTable() {
    const optionCode = this.theOptionCode.trim();
    let resp = {};
    const $optionCodeRows = [];

    $('#optionScanning .gridRow').each(function() {
      $(this)
        .find('.optionManagementCd')
        .each(function() {
          if (
            optionCode ===
            $(this)
              .text()
              .trim()
          ) {
            $optionCodeRows.push($(this));
          }
        });
    });

    for (let index = 0; index < $optionCodeRows.length; index++) {
      const $scannedCnt = $optionCodeRows[index].next().next();
      const scannedCnt = Number($scannedCnt.text());
      const orderCnt = Number($optionCodeRows[index].next().text());

      if (scannedCnt === orderCnt && index === $optionCodeRows.length - 1) {
        alert(this.$t('ORDER.SCANNING.ALERT_M8'));
        this.theOptionCode = null;
        $('#optionCode').focus();

        resp = {
          result: false,
          msg: this.constants.QUANTITY_OUT,
        };

        return false;
      } else if (scannedCnt === orderCnt && $optionCodeRows.length > 1) {
        continue;
      }

      this.addScanCnt($optionCodeRows[index]);

      resp = {
        result: true,
        msg: this.constants.SUCCESS,
        isLast: this.currentScannedCnt === this.totalCount,
      };

      return resp;
    }

    return {
      result: false,
      msg: this.constants.NO_MATCH,
    };
  }
}
