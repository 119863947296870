export default Object.freeze({
  TITLE: '약관/개인정보처리방침 관리',

  // main tab title
  ABOUT_US: '쇼핑몰/회사소개',
  TERMS_OF_SERVICE: '이용약관',
  PRIVACY_POLICY: '개인정보처리방침',
  PERSONAL_INFORMATION: '개인정보 수집/동의 항목',
  WITHDRAWAL: '이용/탈퇴안내',
  // TERMS_HISTORY_MAIN_TITLE: '{title} 변경이력',

  // sub tab title in PERSONAL_INFORMATION
  MEMBER: '회원',
  ORDER: '주문',
  GUEST_BOARD: '[비회원] 게시글/문의글',

  // sub title in PERSONAL_INFORMATION
  NECESSARY: '[필수]',
  MUST: '(필수)',
  SELECT: '[선택]',
  OPTIONAL: '(선택)',
  PERSONAL_INFORMATION_COLLECTION_AND_USE: '개인정보 수집/이용',
  PERSONAL_INFORMATION_PROCESS_CONSIGNMENT: '개인정보 처리/위탁',
  PERSONAL_INFORMATION_THIRD_PARTY_PROVISION: '개인정보 제 3자 제공',
  PI_SELLER_PROVISION: '개인정보 판매자 제공',
  ORDER_INFO_AGREE: '주문 상품정보 동의',
  CLEARANCE_INFO_COLLECTION_AND_USE: '통관정보 수집/이용',
  TRANSFER_AGREE: '개인정보 국외이전 동의',

  // sub title in WITHDRAWAL
  WITHDRAWAL_GUIDE: '탈퇴안내',
  ACCESS_GUIDE: '이용안내',

  // notice
  NOTICE_ABOUT_US_0: `회사소개에 작성된 내용은 쇼핑몰 '회사소개' 영역에 보여집니다.`,
  NOTICE_ABOUT_US_1: `변경된 내용은 복구할 수 없으므로, 작성된 내용을 신중히 검토하신 후 저장해주세요.`,
  NOTICE_TERMS_OF_SERVICE_0: `표준약관은 공정거래위원회의 '전자상거래 표준약관(제10023호)'를 사용합니다.`,
  NOTICE_TERMS_OF_SERVICE_1: `'이용약관은 가급적 '표준약관' 사용을 권장합니다.`,
  NOTICE_PRIVACY_POLICY_0: `개인정보처리방침은 '정보통신망 이용촉진 및 정보보호 등에 관한 법률(약칭:정보통신망법)' 및 개인정보보호법에 따라 반드시 작성해야 합니다.`,
  NOTICE_PRIVACY_POLICY_1: `개인정보처리방침 변경 시, 변경 사유 및 내용에 대해 홈페이지 첫 화면의 공지사항란 또는 별도의 창을 통하여 공지해야 합니다. (ex: 쇼핑몰 팝업 공지 등)`,
  NOTICE_PERSONAL_INFORMATION_0: '회원/비회원의 개인정보 수집에 필요한 항목을 설정합니다.',
  NOTICE_PERSONAL_INFORMATION_1:
    '[필수]항목은 반드시 입력해야 하는 내용이며, [선택]항목은 사용여부를 설정할 수 있습니다.',
  NOTICE_PERSONAL_INFORMATION_2: '각 항목에 작성된 내용은 쇼핑몰 운영방침에 따라 수정해주세요.',
  NOTICE_WITHDRAWAL: '쇼핑몰 이용 및 탈퇴 시, 안내될 내용을 설정할 수 있습니다.',

  // table
  USED_YN: '사용여부',
  USED_Y: '사용함',
  USED_N: '사용 안 함',
  EXPOSURE_Y: '노출함',
  EXPOSURE_N: '노출 안 함',
  CONTENTS: '내용',
  EFFECTIVE_DAY: '시행일',
  REGISTER_DATE: '저장일',
  ADMIN: '처리자',
  IP: 'IP',
  EXPOSURE_YN: '노출여부',
  SHOW: '보기',
  TERM_TITLE: '제목',
  STANDARD_BUTTON: '기본제공내용 불러오기',
  USE_FAIR_LOGO: '공정거래위원회 로고 사용여부',
  SELECT_FAIR_LOGO: '공정거래위원회 로고 선택',

  REPLACED_TEXT_BUTTON: '치환 텍스트 보기',
  TERMS_HISTORY_BUTTON: '변경 이력',
  ALERT_EMPTY_CONTENTS: '{title} 내용을 입력해주세요.',
  ALERT_SAVE_EFFECTIVE_DAY:
    '변경된 {title}은 [시행일]로 최종 저장되며, 시행일 7일전부터 쇼핑몰에 노출됩니다.진행하시겠습니까?',
  ALERT_SELECT_EFFECTIVE_DAY: '시행일을 선택해주세요.',
  NOTICE_CHANGE_DAY: '시행일을 변경해주세요',
  ALERT_SAVE_TERMS_OF_SERVICE_SUCCESS: '저장되었습니다.\n변경된 이용약관은 [시행일] 7일전부터 쇼핑몰에 노출됩니다.',

  // replacement text popup text
  RT_NOTICE: '치환텍스트를 이용하여 필요한 항목을 추가로 제공할 수 있습니다.',
  RT_COMPANY_NAME_DESC: '서비스 회사명',
  RT_COMPANY_NAME_EXAMPLE: 'NHN커머스',
  RT_COMPANY_NAME_NOTE: '기초정보 관리에서 설정한 회사명',

  RT_REPRESENTATIVE_NAME_DESC: '서비스 대표명',
  RT_REPRESENTATIVE_NAME_EXAMPLE: '홍길동',
  RT_REPRESENTATIVE_NAME_NOTE: '기초정보 관리에서 설정한 대표자명',

  RT_BESINESS_REGISTRATION_NO_DESC: '서비스 사업자등록번호',
  RT_BESINESS_REGISTRATION_NO_EXAMPLE: '123-12-12345',
  RT_BESINESS_REGISTRATION_NO_NOTE: '기초정보 관리에서 설정한 사업자등록번호',

  RT_ONLINE_MARKETING_BUSINESS_DECLATION_NO_DESC: '서비스 통신판매업신고번호',
  RT_ONLINE_MARKETING_BUSINESS_DECLATION_NO_EXAMPLE: '제 2015-서울구로-0049',
  RT_ONLINE_MARKETING_BUSINESS_DECLATION_NO_NOTE: '기초정보 관리에서 설정한 통신판매업신고번호',

  RT_PRIVACY_MANAGER_NAME_DESC: '서비스 개인정보관리책임자 명',
  RT_PRIVACY_MANAGER_NAME_EXAMPLE: '홍길동',
  RT_PRIVACY_MANAGER_NAME_NOTE: '기초정보 관리에서 설정한 개인정보 관리 책임자명',

  RT_ZIP_CD_DESC: '서비스 주소우편번호',
  RT_ZIP_CD_EXAMPLE: '8393',
  RT_ZIP_CD_NOTE: '기초정보 관리에서 설정한 주소정보',

  RT_ADDRESS_DESC: '서비스 신주소',
  RT_ADDRESS_EXAMPLE: '서울특별시 구로구 디지털로26길 72 (NHN한국사이버결제)',
  RT_ADDRESS_NOTE: '기초정보 관리에서 설정한 주소정보',

  RT_ADDRESS_DETAIL_DESC: '서비스 신주소상세',
  RT_ADDRESS_DETAIL_EXAMPLE: 'NHN커머스',
  RT_ADDRESS_DETAIL_NOTE: '기초정보 관리에서 설정한 주소정보',

  RT_JIBUN_ADDRESS_DESC: '서비스 지번주소',
  RT_JIBUN_ADDRESS_EXAMPLE: '서울특별시 구로구 구로동 222-22 NHN한국사이버결제',
  RT_JIBUN_ADDRESS_NOTE: '기초정보 관리에서 설정한 주소정보',

  RT_JIBUN_ADDRESS_DETAIL_DESC: '서비스 지번주소 상세',
  RT_JIBUN_ADDRESS_DETAIL_EXAMPLE: 'NHN커머스',
  RT_JIBUN_ADDRESS_DETAIL_NOTE: '기초정보 관리에서 설정한 주소정보',

  RT_MALL_NAME_DESC: '쇼핑몰명',
  RT_MALL_NAME_EXAMPLE: '샵바이프로몰',
  RT_MALL_NAME_NOTE: '쇼핑몰 등록 시 설정한 쇼핑몰명',

  RT_WEB_DOMAIN_DESC: '웹도메인',
  RT_WEB_DOMAIN_EXAMPLE: 'www.shopbypromall.co.kr',
  // RT_WEB_DOMAIN_NOTE: '',

  RT_TERMS_START_DATE_DESC: '시행일',
  RT_TERMS_START_DATE_EXAMPLE: '시행일자 : 2020년 06월 04일',
  RT_TERMS_START_DATE_NOTE: '약관/개인정보처리방침 내용에서 설정한 시행일 정보',

  RT_PARTNER_NAME_DESC: '파트너사명',
  // RT_PARTNER_NAME_EXAMPLE: '',
  RT_PARTNER_NAME_NOTE: '본사에서 등록형공급사(파트너)운영자도 함께 관리할 수 있도록 추가 기능 제공 고려 필요',

  REPRESENTATIVE_PHONE: '서비스 대표전화번호',
  REPRESENTATIVE_PHONE_EX: '02-1234-5678',
  REPRESENTATIVE_PHONE_NOTE: '기초정보 관리에서 설정한 대표전화번호',
  REPRESENTATIVE_EMAIL: '서비스 대표이메일',
  REPRESENTATIVE_EMAIL_EX: 'shopbypromall@nhn-commerce.com',
  REPRESENTATIVE_EMAIL_NOTE: '기초정보 관리에서 설정한 대표이메일',
  BUSINESS_TYPE: '서비스 업종',
  BUSINESS_TYPE_EX: '제조',
  BUSINESS_TYPE_NOTE: '기초정보 관리에서 설정한 업종',
  BUSINESS_CONDITION: '서비스 업태',
  BUSINESS_CONDITION_EX: '아동복',
  BUSINESS_CONDITION_NOTE: '기초정보 관리에서 설정한 업태',
  PRIVACY_MANAGER_POSITION: '서비스 개인정보보호책임자 직위',
  PRIVACY_MANAGER_POSITION_EX: '팀장',
  PRIVACY_MANAGER_POSITION_NOTE: '기초정보 관리에서 설정한 보호책임자 직위',
  PRIVACY_MANAGER_TEAM: '서비스 개인정보보호책임자 소속',
  PRIVACY_MANAGER_TEAM_EX: '개발기획팀',
  PRIVACY_MANAGER_TEAM_NOTE: '기초정보 관리에서 설정한 보호책임자 소속',
  PRIVACY_MANAGER_EMAIL: '서비스 개인정보보호책임자 이메일',
  PRIVACY_MANAGER_EMAIL_EX: 'honggildong@nhn-commerce.com',
  PRIVACY_MANAGER_EMAIL_NOTE: '기초정보 관리에서 설정한 보호책임자 이메일',
  PRIVACY_MANAGER_PHONE: '서비스 개인정보보호책임자 전화번호',
  PRIVACY_MANAGER_PHONE_EX: '02-1234-5678',
  PRIVACY_MANAGER_PHONE_NOTE: '기초정보 관리에서 설정한 보호책임자 전화번호',

  EXPOSURE_MALL_TERMS_HISTORY: '변경이력 쇼핑몰 노출',
  KR_HORIZONTAL: '국문 가로',
  KR_HORIZONTAL_SMALL: '국문 가로 small',
  KR_VERTICAL: '국문 세로',
  KR_SYMBOL: '국문 심볼',
  KR_GRAY: '국문 흑백',
  EN_HORIZONTAL: '영문 가로',
  EN_VERTICAL: '영문 세로',

  EFFECTIVE_DAY_NOTICE_1:
    '시행일이 미래날짜인 경우 내용 수정이 가능하며, 시행일이 과거날짜인 경우 시행일을 변경 후 내용 수정이 가능합니다.\n* 시행일 변경 후 저장 시, 해당 데이터 변경이력에 저장됩니다.',
  EFFECTIVE_DAY_NOTICE_2:
    '변경이력 쇼핑몰 노출 체크 시, 변경 이력 팝업에 저장된 이용약관, 개인정보처리방침이 쇼핑몰에 제공되며 체크 해제 시 현재 시행중인 약관만 쇼핑몰에 노출됩니다.',
  EFFECTIVE_DAY_NOTICE_3: '시행 예정인 약관은 반드시 7일전에 고지해야 합니다.',

  FAIR_LOGO_MESSAGE:
    '공정거래위원회가 심사한 인터넷 사이버몰 이용표준약관(표준약관 제10023호)을 귀사의 약관으로 사용하면서,\n' +
    '표준약관 사용안내 및 준수사항에 따르고 이를 이행하겠다는 전제아래 귀하의 쇼핑몰 홈페이지 초기화면에 표시하면 됩니다.\n' +
    '* 표준약관 중 다른 내용 기재 후 공정거래위원회 로고 사용 시 시정조치 및 이행조치를 받을 수 있습니다.',

  HISTORY_NOTICE: '변경/저장된 약관을 조회할 수 있습니다.',

  CONFIRM_CHANGE_EXPOSURE: '상태로 변경하시겠습니까?',
  CONFIRM_DELETE_HISTORY:
    '삭제하신 이용약관은 복구가 불가능하며, 삭제 시 현재 시행중인 이용약관이 노출됩니다. \n정말 삭제하시겠습니까?',

  ALL: '전체',
  TITLE_TERMS_HISTORY: '변경이력',

  TERM_USE_EFFECT_Y: '시행중',
  TERM_USE_EFFECT_N: '시행예정',

  DELETED: '{value}에 시행 예정인 이용약관이 삭제되었습니다.',
  CHANGED: '노출상태가 변경되었습니다.',
  SAVED_DATE: '저장일',
  ENFORCEMENT_DATE: '시행일',
  PERSONAL_INFORMATION_CUSTOM: '개인정보 수집/동의 항목-추가',
  CHANGED_THING: '변경사항',
  SELECT_NAME_USE: '이용약관',
  SELECT_NAME_REGULAR_PAYMENT_USE: '정기결제(배송) 이용약관',
  SELECT_NAME_AUTO_APPROVAL_USE: '자동 승인 이용약관',
  SELECT_NAME_PI_PROCESS: '개인정보처리방침',
  SELECT_NAME_E_COMMERCE: '전자금융거래이용약관',
  SELECT_NAME_ACCESS_GUIDE: '이용안내',
  SELECT_NAME_WITHDRAWAL_GUIDE: '탈퇴안내',
  SELECT_NAME_PI_COLLECTION_AND_USE_REQUIRED: '회원 > 회원가입시 개인정보 수집/이용(필수)',
  SELECT_NAME_PI_COLLECTION_AND_USE_OPTIONAL: '회원 > 회원가입시 개인정보 수집/이용(선택)',
  SELECT_NAME_PI_PROCESS_CONSIGNMENT: '회원 > 개인정보 처리/위탁(선택)',
  SELECT_NAME_PI_THIRD_PARTY_PROVISION: '회원 > 개인정보 제3자 제공(선택)',
  SELECT_NAME_PI_SELLER_PROVISION: '주문 > 개인정보 판매자 제공(필수)',
  SELECT_NAME_PI_COLLECTION_AND_USE_ON_ORDER: '주문 > 회원 주문시 개인정보 수집/이용(필수)',
  SELECT_NAME_ORDER_INFO_AGREE: '주문 > 주문정보 동의(필수)',
  SELECT_NAME_CLEARANCE_INFO_COLLECTION_AND_USE: '주문 > 통관정보 수집/이용(필수)',
  SELECT_NAME_TRANSFER_AGREE: '주문 > 개인정보 국외이전 동의(필수)',
  // PI_LIQUOR_PURCHASE_PROVISION: '주문 > 주류구매 개인정보 제공 동의(필수)',
  SELECT_NAME_PI_COLLECTION_AND_USE_FOR_GUEST_ON_ARTICLE:
    '비회원 게시글/문의글 > 비회원 게시글/문의글 작성시 개인정보 수집/이용(필수)',
});
