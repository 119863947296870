export default Object.freeze({
  OPERATION_SECURITY_SETTING: '운영 보안 설정',
  ADMIN_OPERATION_SECURITY_SETTING: '관리자 운영 보안 설정',
  ADMIN_SECURITY_AUTH_SETTING: '관리자 보안인증 설정',
  ADMIN_IP_ACCESS_RESTRICTION_SETTING: '관리자 IP 접속제한 설정',
  MALL_IP_ACCESS_RESTRICTION_SETTING: '쇼핑몰 IP 접속제한 설정',
  EXCEL_DOWNLOAD_SECURITY_SETTING: '엑셀 다운로드 보안 설정',
  DOWNLOAD_SECURITY_SETTING: '다운로드 보안 설정',
  SECURITY_AUTH_WHETHER: '보안인증 사용여부',
  ADMIN_IP_ACCESS_RESTRICTION_WHETHER: '관리자 IP 접속제한 사용여부',
  DOWNLOAD_SECURITY_WHETHER: '다운로드 보안 설정 사용여부',
  MALL_IP_ACCESS_RESTRICTION_WHETHER: '쇼핑몰 IP 접속제한 사용여부',
  USE_OF_SECURE_LOGIN: '보안로그인 사용',
  USE_OF_SCREEN_SECURE: '화면보안접속 사용',
  SETTING_BY_SHOPPING_MALL: '쇼핑몰별 설정',
  AUTH_METHOD: '인증수단',
  EMAIL_AUTH: '이메일인증',
  SMS_AUTH: 'SMS인증',
  OTP_AUTH: 'OTP인증',

  OPERATION_SECURITY_DIALOGUES:
    '정보통신망을 통해 외부에서 개인정보처리시스템(쇼핑몰 등)에 접속 시 단순히 아이디와 비밀번호만을 이용할 경우, 해킹에 의해 접속정보가 유출되어 쇼핑몰이 쉽게 위험에 노출되게 됩니다.\n' +
    '이러한 위험성을 감소시키기 위해 아이디/비밀번호를 이용하는 인증과 별도로 휴대폰, 일회용비밀번호(OTP), IP인증 등을 활용한 추가적인 안전한 인증수단을 적용하여야 합니다.\n' +
    '해당 법령을 준수하지 않을 경우 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 73조에 따라 2년 이하의 징역 또는 2천만원 이하의 벌금에 처할 수 있습니다.\n' +
    '정보통신망 이용촉진 및 정보보호 등에 관한 법률 내용을 필독하시고 "관리자 보안로그인" 또는 "관리자 IP 접속제한” 을 설정하시기를 권장합니다.',

  ALERT_FIRST_CREATED_MALL_WARN: '생성일이 가장 빠른 쇼핑몰에 발신정보가 설정되지 않은 경우 발송이 불가합니다.',
  ALERT_SMS_POINT_WARN: '인증번호 SMS는 잔여포인트가 있어야 발송됩니다. ({쇼핑몰명}잔여포인트 : N)',
  GO_TO_SETTING: '설정 바로가기',
  GO_TO_SMS_CHARGE: 'SMS포인트 충전하기',
  ALERT_OTP_WARN: 'OTP인증을 사용하는 경우 운영자별로 OTP를 설정해야 합니다.',

  ALERT_SELECT_AUTH_METHOD: '인증수단을 1개 이상 선택해주세요.',
  ALERT_INSERT_ACCESS_IP: '운영자 접속가능 IP를 입력해주세요.',

  SECURITY_AUTH_WHETHER_DIALOGUES:
    '보안로그인 ‘사용함’ 설정할 경우 로그인 시 설정된 인증수단의 보안인증을 통해 로그인이 가능합니다. (1회 인증 시 인증한 날짜 기준으로 추가 인증 없이 해당 일자의 23:59:59까지 로그인이 가능하며, 익일 0시에 로그인 시도 시 재인증이 필요합니다.)\n' +
    '화면보안접속 ‘사용함’ 설정할 경우 화면보안접속 페이지 접근 시 설정된 인증수단의 보안인증을 통해 접근이 가능합니다. (1회 인증 시 인증한 날짜 기준으로 추가 인증 없이 해당 일자의 23:59:59까지 화면보안 페이지 접근이 가능하며, 익일 0시에 로그인 시도 시 재인증이 필요합니다.)',

  SCREEN_SECURITY_SETTING_PAGE: '[화면보안 설정 페이지]',
  SCREEN_SECURITY_SETTING_PAGE_LIST:
    '설정 > 관리 정책 > 운영자 관리\n' +
    '설정 > 관리 정책 > 권한그룹 관리\n' +
    '설정 > 보안 정책 > 운영 보안 설정\n' +
    '주문/배송 > 자동입금확인',
  // '회원 > SMS 관리',

  AUTH_METHOD_DIALOGUES:
    // '로그인한 운영자 정보에 인증정보(SMS, 이메일)가 없는 경우, 보안인증화면이 노출되지 않습니다.\n' +
    '관리자 보안인증 설정을 사용 시, 반드시 운영자 정보에 인증정보를 등록해주세요.\n' +
    // '이메일인증은 주문처리 및 프로모션 등으로 인해 이메일이 대량으로 발송될 경우 인증번호 전송이 지연될 수 있으므로 SMS인증을 사용하시길 권장합니다.\n' +
    // 'SMS 인증 시 생성일이 가장 빠른 쇼핑몰에서 인증번호가 발송됩니다.<br />- 해당 쇼핑몰의 SMS 잔여포인트를 확인해주세요.\n' +
    'OTP인증의 경우 Google Authenticator 앱 설치 후 이용이 가능합니다.',

  EXCEL_DOWNLOAD_AUTH_METHOD_DIALOGUES:
    '엑셀 생성 및 다운로드를 시도한 운영자 정보에 인증정보(SMS, 이메일)가 없는 경우, 보안인증화면이 노출되지 않습니다.\n' +
    '다운로드 보안인증 설정을 사용 시, 반드시 운영자 정보에 인증정보를 등록해주세요. \n' +
    '이메일인증은 주문처리 및 프로모션 등으로 인해 이메일이 대량으로 발송될 경우 인증번호 전송이 지연될 수 있으므로 SMS인증을 사용하시길 권장합니다.\n' +
    'OTP인증의 경우 Google Authenticator 앱 설치 후 이용이 가능합니다.',

  IP_REGISTRATION_FOR_ADMINISTRATOR_ACCESS: '관리자 접속 가능 IP 등록',
  IP_REGISTRATION_FOR_ADMINISTRATOR_ACCESS_DIALOGUES:
    '관리자 접속제한 ‘사용함‘ 설정 시 접속가능 IP로 등록된 IP만 접속이 가능합니다.\n' +
    '유동적으로 변경되는 IP 등록 시 접속이 제한되실 수 있으니 주의바랍니다.',

  ACCESS_RESTRICTIONS_BY_COUNTRY_SETTING: '국가별 접속제한 설정',
  ACCESS_RESTRICTIONS_BY_COUNTRY_SETTING_DIALOGUES:
    '국가별 접속제한 설정의 경우, 국가별 IP를 체크하여 접속을 제한하는 설정입니다. \n' +
    '- 국가별 접속제한 설정 시 사설 IP + 해당 국가 IP(봇포함) 접속불가하며 허용 국가의 공인 IP는 접속이 가능합니다. (사설 IP 대역 : 10.0.0.0 ~ 10.255.255.255, 172.16.0.0 ~ 172.31.255.255, 192.168.0.0 ~ 192.168.255.255)',
  ALLOWED_COUNTRY: '허용국가',
  BLOCKED_COUNTRY: '하단국가',

  EXCEL_DOWNLOAD_SECURITY_WHETHER_DIALOGUES:
    '엑셀 다운로드 보안 ‘사용함’ 설정 시 체크된 보안범위 내에서 엑셀 생성 또는 다운로드 버튼 클릭하여 설정된 인증수단의 보안인증을 통해 생성 및 다운로드 가능합니다.<br />(1회 인증 시 로그인 세션 만료 시까지 보안범위(체크한 메뉴) 내에서 추가 인증 없이 엑셀 생성 및 다운로드 가능합니다.) \n' +
    '생성된 엑셀을 다운로드 하기전에 세션이 만료되거나 인증되지 않은 보안범위인 경우 재인증이 필요합니다.\n' +
    '정보통신망법 개인정보 보호조치에 따라 개인정보의 안전성 확보를 위한 다운로드 보안 설정을 ‘사용’하시길 권장합니다.',

  SECURITY_SCOPE_SETTING: '보안범위 설정',
  DOWNLOAD_SECURITY_WHETHER_DIALOGUE:
    '보안범위 설정 시 대메뉴 기준으로 범위 지정이 가능하며, 보안범위 적용 메뉴 내 엑셀 생성 및 다운로드가 가능합니다.',
  SECURITY_SCOPE_APPLICATION_MENU: '[보안범위 적용 메뉴]',
  SECURITY_SCOPE_APPLICATION_MENU_LIST:
    '설정 > 개인정보접속기록 조회\n' +
    '주문/배송 > 주문 관리\n' +
    '주문/배송 > 취소/교환/반품/환불 관리\n' +
    '회원 > 회원 관리\n' +
    '게시판 > 게시판 관리\n' +
    '프로모션 > 프로모션 관리',

  MALL_IP_ACCESS_RESTRICTION_ADD: '쇼핑몰 접속제한 IP 등록',
  MALL_IP_ACCESS_RESTRICTION_ADD_DIALOGUES:
    '쇼핑몰 접속제한 ‘사용함‘ 설정 시 접속제한 IP로 등록된 IP는 접속이 불가합니다.<br /><span class="text-red">* 쇼핑몰에 접속할 수 없으므로 등록 시 주의하시기 바랍니다.</span>\n' +
    '쇼핑몰 접속제한 ‘사용함‘ 설정 후 IP 접속 제한 등록 시 입력된 특정 ip만 접속불가 나머지 사설+공인 접속가능\n' +
    '유동적으로 변경되는 IP 등록 시 접속이 제한되실 수 있으니 주의바랍니다.',

  ALERT_IP_BAND_RANGE_WRONG: '대역 앞자리 보다 큰 값을 입력하여주세요.',
  ALERT_IP_OCTET_EMPTY: '관리자 접속가능 IP를 입력해주세요.',
  ALERT_IP_ADDRESS_GUIDE: '등록된 IP만 관리자에 접속 가능합니다. (현재 접속 IP : {ip})',

  //personal access history
  PERSONAL_NOTICE_MESSAGE:
    '개인정보의 기술적 관리적 보호조치 기준에 따라 정보통신 서비스 제공자 등은 개인정보취급자가 개인정보처리시스템에 접속한 기록을 월 1회 이상 정기적으로 확인/감독하여야 하며, 시스템 이상 유무의 확인 등을 위해 최소 2년 이상 접속기록을 보존/관리하여야 합니다.\n' +
    '회원정보 접속기록 기간 조회의 경우 오늘 기준 2년 이내 데이터를 최대 6개월까지 선택하여 조회가 가능합니다.\n' +
    '운영자정보 접속기록 기간 조회의 경우 오늘 기준 5년 이내 데이터를 최대 6개월까지 선택하여 조회가 가능합니다.',
  PERSONAL_NOTICE_TITLE: '개인정보의 안정성 확보에 필요한 조치에 관한 사항',
  CONNECTION_DATE: '접속 일시',
  CONNECTION_IP: '접속IP',
  ADMIN_ID: '운영자ID',
  CONNECTION_URL: '접속 URL',
  PERFORMANCE: '수행업무',
  MEMBER_RECODE: '회원정보 접속기록',
  ADMIN_RECODE: '운영자정보 접속기록',
  STORAGE_PERIOD: '{0}년 이내에 데이터만 조회 가능합니다.',
  UP_TO_6_MONTHS: '조회기간은 최대 6개월까지 조회 가능합니다.',
  MEMBER_ID: '회원ID',
  DETAIL: '상세',
  PC_MANAGER: 'PC 관리자',
  ALL_MALLS_HISTORIES: '전체 쇼핑몰 대상 처리 내역',

  ACCESS: '접근',
  SEARCH: '조회',
  CHANGE: '수정',
  DELETE: '삭제',
  ADD: '등록',
  DOWNLOAD: '엑셀 다운로드',
  UPLOAD: '엑셀 업로드',
  CERTIFICATION_SUCCESS: '인증',
  CERTIFICATION_FAILURE: '인증(실패)',
  ANSWER: '답변',
  LOGIN: '로그인',
  LOGIN_FAIL: '로그인(실패)',
  LOGOUT: '로그아웃',

  //workingDetail
  WORKING_DETAIL_TITLE_CUSTOMER: '회원정보 접속기록',
  WORKING_DETAIL_TITLE_ADMIN: '운영자정보 접속기록',
  SEARCH_KEYWORD: '검색조건',
  COUNT: '처리건수',
  IDENTIFIERS: '처리대상',
  FILE_NAME: '파일명',
  REASON: '사유',
  CERTIFY_TYPE: '인증수단',
  CERTIFY_VALUE: '인증번호',
  ALL_SEARCH_TYPE: '통합검색',
  WITHDRAW_COUPON: '쿠폰 철회',
  CREATE: '엑셀 생성',
});
