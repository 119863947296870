



























import { Component, Mixins, Watch } from 'vue-property-decorator';
import StatusSummaryBox from '@/components/root/parts/StatusSummaryBox.vue';
import SummaryData, { Summary } from '@/components/root/SummaryMixin';
import { namespace } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';

const mallStore = namespace('mall');

@Component({
  components: { StatusSummaryBox },
})
export default class ClaimSummary extends Mixins(SummaryData) {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private periodQueryObject = this.getPeriodQueryObject(-3, 'month');

  // TODO: 왠지 summary 숫자들이 프리미엄 payTypes의 검색결과 수로 출력되는거 같다.
  private summaries: Summary[] = [
    {
      key: 'refundProcessCount',
      label: 'SUMMARY.TOTAL_CLAIM',
      count: null,
    },
    {
      key: 'cancelRequestCount',
      label: 'CANCELLATION_REQUEST',
      count: null,
      path: {
        name: 'CancelIframe',
        query: {
          claimStatusTypes: 'CANCEL_REQUEST',
          claimType: 'CANCEL',
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'cancelProcessCount',
      label: 'CANCELLATION_PROCESSING',
      count: null,
      path: {
        name: 'CancelIframe',
        query: {
          claimType: 'CANCEL',
          claimStatusTypes: 'CANCEL_PROC_REQUEST_REFUND,CANCEL_PROC_WAITING_REFUND',
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'returnRequestCount',
      label: 'RETURN_REQUEST',
      count: null,
      path: {
        name: 'ReturnIframe',
        query: {
          claimType: 'RETURN',
          claimStatusTypes: 'RETURN_REQUEST,RETURN_REJECT_REQUEST',
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'returnProcessCount',
      label: 'RETURN_PROCESSING',
      count: null,
      path: {
        name: 'ReturnIframe',
        query: {
          claimType: 'RETURN',
          claimStatusTypes:
            'RETURN_PROC_BEFORE_RECEIVE,RETURN_PROC_REQUEST_REFUND,RETURN_PROC_WAITING_REFUND,RETURN_REFUND_AMT_ADJUST_REQUESTED',
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'exchangeRequestCount',
      label: 'EXCHANGE_REQUEST',
      count: null,
      path: {
        name: 'ExchangeIframe',
        query: {
          claimType: 'EXCHANGE',
          claimStatusTypes: 'EXCHANGE_REQUEST',
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'exchangeProcessCount',
      label: 'EXCHANGE_PROCESSING',
      count: null,
      path: {
        name: 'ExchangeIframe',
        query: {
          claimType: 'EXCHANGE',
          claimStatusTypes:
            'EXCHANGE_PROC_BEFORE_RECEIVE,EXCHANGE_PROC_REQUEST_PAY,EXCHANGE_PROC_WAITING,EXCHANGE_PROC_REQUEST_REFUND',
          ...this.periodQueryObject,
        },
      },
    },
  ];

  private get mallNos(): number[] {
    return this.malls.map(({ mallNo }) => mallNo);
  }

  created() {
    if (this.getClaimInquirySummary) this.mapData();
    this.fetch();
  }

  @Watch('$route.query.latestRefresh')
  @Watch('mallNoQuery')
  async fetch() {
    await this.fetchData();
    this.mapData();
  }

  private async fetchData(): Promise<void> {
    const mallNos = this.mallNoQuery === 'ALL' ? this.mallNos.join(',') : this.mallNoQuery.toString();
    await this.fetchClaimInquiresSummary({ mallNos });
  }

  private mapData(): void {
    this.mapSummaries(this.getClaimInquirySummary);
  }

  private mapSummaries<DataType = object>(data: DataType): void {
    Object.entries(data).forEach(([k, v]) => {
      const matched = this.summaries.find(({ key }) => key === k);
      if (matched) matched.count = v;
    });
    this.summaries = this.summaries.map(summary => {
      summary.query = this.mallNoQuery === 'ALL' ? { mallNo: '' } : { mallNo: this.mallNoQuery.toString() };
      return summary;
    });
  }

  private get refundProcessCount(): Summary {
    return this.summaries.find(({ key }) => key === 'refundProcessCount');
  }

  private get summaryList(): Summary[] {
    return [...this.summaries].splice(1, this.summaries.length);
  }
}
