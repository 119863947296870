export default Object.freeze({
  ONLY_NUM: '숫자만 입력하세요. (최대 {maxLength}자)',
  NAVER_SHOPPING_TITLE: '네이버 쇼핑 설정',
  USE_NAVER_SHOPPING: '네이버 쇼핑 사용설정',
  STOP_CREATEION_TIME: '생성 중지 일시',
  NOT_USED_NOTICE:
    '서비스를 사용하시려면 <a style="text-decoration: underline" href="https://marketing.nhn-commerce.com/opening/naver-shopping.gd" target="_blank">NHN커머스 > 마케팅 > 네이버쇼핑</a> 에서 사용 신청 후 사용이 가능합니다.',
  CREATE_EP_TOOLTIP:
    '네이버쇼핑에 상품을 삭제하고 싶은 경우 "EP 생성 중지" 설정 순서.\n' +
    '⓵ 네이버쇼핑 상품 설정페이지 또는 상품 상세페이지에서 원하는 상품의 네이버쇼핑 노출여부를 "노출 안 함"으로 체크한 후 저장\n' +
    '⓶ EP 생성 상태를 "생성"으로 선택 후 저장 (마지막 설정 EP를 네이버쇼핑에 전송하기 위함)\n' +
    '⓷ 마지막 EP 생성 시간(00:00, 9:00, 11:00, 13:00, 15:00, 17:00)이후 EP 생성 상태를 "생성 중지"로 선택한 후 저장\n' +
    '* EP 생성 상태를 "생성 중지"로 선택 시 생성 중지 날짜가 노출됩니다.\n' +
    '* 네이버쇼핑에 노출되는 상품 전체 또는 일부 삭제를 원하는 경우 반드시 위의 네이버 "EP 생성 중지" 설정에 맞게 진행해주세요 (별도 설정 없이 EP 생성 상태를 "생성 중지"로 저장 시 마지막 생성된 EP파일이 네이버쇼핑에 전송되어 노출됩니다.)\n' +
    '* EP란 네이버쇼핑 기준 서비스 가능한 상품의 상품정보의 데이터를 말합니다.',
  COMMON_CERTIFIED_KEY: '네이버 공통 인증키',
  COMMON_CERTIFIED_KEY_TOOLTIP:
    '* 네이버공통인증키는"네이버쇼핑파트너존 > 정보관리 > 정보수정"에서 확인하실 수 있습니다.\n' +
    '만일 네이버공통인증키를 확인하실 수 없으시다면 네이버쇼핑파트너센터 측으로 네이버공통인증키의 확인방법에 대하여 문의 주시기 바랍니다\n' +
    '[쇼핑광고 고객센터]1588-3819 [검색광고 고객센터]1588-5896',
  INPUT_COMMON_CERTIFIED_KEY: '네이버 공통 인증키 입력',
  PLEASE_WRITE_COMMON_KEY: '네이버 공통 인증키를 입력해주세요.',
  PLEASE_CHECK_DUPLICATE: '네이버 공통 인증키 중복확인을 해주세요',
  USAGE_COMMON_KEY: '사용 가능한 네이버 공통 인증키입니다.',
  DUPLICATE_COMMON_KEY: `이미 사용중인 네이버 공통 인증키입니다. '중복여부'을(를) 입력하여 주세요.`,
  ALREADY_SET_KEY: `이미 사용중인 네이버 공통 인증키입니다.`,
  CPA_AGREE_CHECK: 'CPA주문수집 동의여부',
  AGREE_AT: '동의 일시',
  NAVER_MALL_GRADE: '네이버쇼핑몰 몰등급',
  PREMIUM: '프리미엄',
  BIG_POWER: '빅파워',
  POWER: '파워',
  SPROUT: '새싹',
  SEED: '씨앗',
  CERTIFICATION: '인증함',
  EP_MAX_NUMBER: '*EP 최대 상품수',
  SET_NUMBER_PRODUCT_REVIEWS: '네이버쇼핑 상품평 개수 설정',
  CREATE_EP_WITH_PRODUCT_NUM: '(상품후기)의 상품평 개수로 EP 생성',
  PRODUCT_DB_URL: '상품 DB URL',
  PRODUCT_DB_URL_TOOLTIP:
    '[전체상품] URL을 복사하여 "네이버쇼핑 파트너존 > 상품관리 > 등록요청"의 [EP URL]영역에 붙여넣기합니다.\n' +
    '요청사유 입력 후 등록요청 가능하며 네이버쇼핑 상품 데이터(EP)생성은 최대 24시간 안에 확인 가능합니다.',
  PRODUCT_DB_URL_DESC: 'EP 생성을 [생성]으로 설정 시, 상품 DB URL을 생성합니다.',
  SELLING_INDEX: '판매지수',
  NAVER_SHOP_PARTNERZONE: '네이버 쇼핑파트너센터 바로가기',
  CHECK_DB_REGIST: 'DB등록 방법 확인',
  CLASS_INFO: '네이버 쇼핑몰 등급안내',
  EP_CREATE_POSSIBLE_PRODUCT: '네이버쇼핑 EP 생성 가능 상품 수',
  EP_CREATE_PRODUCT: '네이버쇼핑 EP 생성 상품 수',
  CLASS_INFO_DESC1:
    '네이버쇼핑몰 몰등급에 따라 네이버쇼핑상품데이터(EP) 생성이 가능한 상품 수 차이가 있으며<br>' +
    '샵바이프로는 좀 더 안전하게 이용하실 수 있도록 생성 가능 상품 수 보다 적게 생성 됩니다.',
  CLASS_INFO_DESC2: `네이버쇼핑몰 몰등급은 네이버쇼핑파트너센터 홈 상단 [광고현황요약] 및 [<a style="text-decoration: underline" href="https://center.shopping.naver.com/" target="_blank">쇼핑파트너센터</a> > 정보관리 > 정보수정 > 서비스정보]에서 확인 가능하며, 샵바이프로에서 설정한 쇼핑몰 몰등급과 상이하여 상품수가 등급 한도를 초과한 경우 정상 수신이 되지 않습니다. <br>
    <strong class="text-red">3일 연속 실패한 경우, 네이버쇼핑 서비스 이용이 중지</strong>될 수 있으니 유의하여 주시기 바랍니다.<br>
    ※ 네이버쇼핑몰 몰등급은 매월 1회 등급 산정이 진행되니 참고 바랍니다.`,
  LESS_THEN_TEN_THOUSAND_EACH: '만 개 미만',
  CPA_AGREE_TOOLTIP:
    '네이버에서 CPA 주문수집에 동의하신 경우에만 주문완료 시 주문정보를 네이버측으로 전송합니다.\n' +
    'CPA 주문수집이 정상적으로 이루어져야만 차후 CPA로의 과금전환이 이루어질 수 있습니다.\n' +
    '주문 수집에 동의하신 뒤에는 반드시 체크하여주시기 바라며, CPA 주문수집에 대한 문의는 네이버 쇼핑 파트너존으로 문의 주시기 바랍니다.',
  COMMON_CERTIFIED_KEY_GUIDE_1:
    '네이버 공동인증키는 "네이버 쇼핑파트너센터 > 정보관리 > 정보수정"에서 확인하실 수 있습니다. <a style="text-decoration: underline" href="https://adcenter.shopping.naver.com/member/login/form.nhn?targetUrl=%2Fmain.nhn" target="_blank">바로가기><a>',
  COMMON_CERTIFIED_KEY_GUIDE_2: '네이버 공동인증키 변경 및 삭제 시 네이버 서비스 이용에 제한이 생길 수 있습니다.',
  CPA_GUIDE_NOTICE_1: '네이버에서 CPA 주문수집에 동의하신 경우에만 주문완료 시 주문정보를 네이버측으로 전송합니다.',
  CPA_GUIDE_NOTICE_2: 'CPA 주문수집이 정상적으로 이루어져야만 차후 CPA로의 과금전환이 이루어질 수 있습니다.',
  CPA_GUIDE_NOTICE_3:
    '주문 수집에 동의하신 뒤에는 반드시 체크해주세요. CPA 주문수집에 대한 문의는 네이버 쇼핑파트너센터로 문의 주시기 바랍니다.',

  //마케팅 > 마케팅 상품설정 > 네이버 쇼핑
  PRODUCT_EP_GUIDE_1: `<a style="text-decoration: underline" href="/pro/marketing/naver-shopping">마케팅 > 마케팅 설정 > 네이버쇼핑 설정<a>에서 사용 설정 여부가 '사용함'인 경우에만 상품 EP가 생성됩니다.`,
  PRODUCT_EP_GUIDE_2:
    '네이버쇼핑 사용함 설정 시 다음 EP생성 시간부터 반영됩니다. EP생성 전까지는 DB URL 미리보기 제공되지 않습니다.',
  PRODUCT_EP_GUIDE_3:
    '전체상품 DB URL을 복사 "<a style="text-decoration: underline" href="https://center.shopping.naver.com" target="_blank">네이버 쇼핑파트너센터<a>>상품관리>등록요청"의 [EP URL]영역에 입력해야 상품EP가 수집됩니다.',
  PRODUCT_EP_GUIDE_4: `네이버 쇼핑몰 상품 EP(DB URL : 상품 정보를 요약한 파일로 EP URL과 동일)은 네이버 쇼핑 상품 수집 주기 1시간 전에 생성됩니다. <br>
   - 네이버쇼핑 정책에 따라 아래와 같이 상품정보가 입력된 상품은 EP 생성시 제외됩니다. 이점 유의하시기 바랍니다. <br>
    ex) 상품정보에 이미지가 없는 경우 / 가격 대체 문구가 있는 경우 등`,
  PRODUCT_EP_GUIDE_5: `노출 여부 '노출함', 연동 대상이 'Y'인 경우에만 DB URL이 생성됩니다.`,

  PRODUCT_EP_GUIDE_TITLE: '상품 EP 생성 안내',
  PREVIEW_ALL_PRODUCT_DB_URL: '전체상품 DB URL 미리보기',
  PREVIEW_SALES_INDEX_DB_URL: '판매지수 DB URL 미리보기',
  COPY_ALL_PRODUCT_DB_URL: '전체상품 DB URL 복사하기',
  CHECK_REGISTER_DB_GUIDE: 'DB등록 방법 확인 >',
  BULK_CORRECTION_ADDITIONAL_INFORMATION: '추가정보 일괄 수정',

  AVAILABLE_ADS_TOOLTIP: `• 해당 마케팅 채널에 상품 정보가 연동될 수 있는 조건인 경우 ‘Y’로 노출됩니다. 
• 해당 마케팅 채널에 상품 정보가 연동될 수 없는 조건인 경우 ‘N’로 노출됩니다. 
• 네이버쇼핑 EP 생성 조건
  전시 상태 ‘전시함‘ 인 경우, 삭제 / 임시 삭제되지 않은 상품인 경우,
  승인 여부가 ‘승인완료’ 상태인 경우, 승인 이후 ‘수정 후 승인대기 및 승인거절’ 상태인 경우,
  판매 설정 상태가 ‘판매금지‘가 아닌 경우`,

  //EP수정 레이어 팝업
  MODIFY_EP_INFO_POPUP_TITLE: `네이버쇼핑 EP 정보 수정`,
  MODIFY_EP_INFO_POPUP_BULK_TITLE: `네이버쇼핑 EP 정보 일괄 수정`,
  CATEGORY_ID: '네이버 카테고리 ID',
  CATEGORY_ID_GUIDE_1: '해당하는 카테고리에 매칭하는데 도움이 됩니다.',
  CATEGORY_ID_GUIDE_2:
    '네이버쇼핑의 전체 카테고리 리스트는 <a href="https://center.shopping.naver.com/" target="_blank">[네이버 쇼핑파트너센터]</a>에서 다운로드 할 수 있습니다.',
  COMPARE_PRICE_PAGE_ID: '가격비교 페이지 ID',
  COMPARE_PRICE_PAGE_GUIDE_1: '네이버 가격비교 페이지 ID를 입력할 경우 네이버 가격비교 추천에 도움이 됩니다.',
  COMPARE_PRICE_PAGE_GUIDE_2:
    '예) search.shopping.naver.com/catalog/<strong style="color: #e4464e">8535546055</strong>',
  SALES_METHOD_INCOME_MANUFACTURING: '판매방식 구분',
  MONTHLY_RENTAL_FEE: '월렌탈료',
  RENTAL_CONTRACT_PERIOD: '렌탈계약기간',
  MONTHLY_RENTAL_FEE_NOTICE: '월렌탈료가 총렌탈료(=판매가)보다 작아야 네이버쇼핑에 정상 노출됩니다.',
  PRODUCT_STATUS: '상품 상태',
  IMPORT_MANUFACTURE_STATUS: '수입 및 제작 여부',
  OVERSEAS_PURCHASING_AGENCY: '해외구매대행',
  PARALLEL_IMPORT: '병행수입',
  CUSTOM_MADE: '주문제작',
  ADDITIONAL_INSTALLATION_FEE: '별도 설치비 있음',
  ENABLED_INDICATION_NAVER_PAY: '네이버 페이 사용 가능 표시',
  INDICATION_PC: 'PC 표시',
  INDICATION_MOBILE: '모바일 표시',
  CAPABLE_DISPLAY_NAVER_PAY: '네이버페이 적립 가능 표시',
  BRAND_CERTIFICATION_PRODUCT_STATUS: '브랜드 인증상품 여부',
  BRAND_CERTIFICATION_PRODUCT_GUIDE_1: `네이버쇼핑 브랜드패키지에 가입한 브랜드 본사로부터 인증 받은 상품만 '인증함'으로 체크해주시길 바랍니다.`,
  BRAND_CERTIFICATION_PRODUCT_GUIDE_2: `상품이 N개의 브랜드로 구성된 경우에는 '인증 안 함'으로 체크하시기 바랍니다.`,
  BRAND_CERTIFICATION_PRODUCT_GUIDE_3:
    '브랜드 본사로부터 인증 받지 않은 브랜드상품을 인증함으로 체크하거나 상품의 브랜드 인증 정보를 잘못 체크하여 네이버쇼핑 및 브랜드사로부터 불이익 발생 시 그 책임은 상점에게 있습니다.',
  MODIFY_BULK_NAVER_SHOPPING_SUCCESS_MESSAGE: `성공 {count}건 : 네이버쇼핑 EP 정보 일괄 수정 완료`,
  MODIFY_NAVER_SHOPPING_SUCCESS_MESSAGE: '저장되었습니다.',
  MODIFY_BULK_NAVER_SHOPPING_FAIL_MESSAGE: `실패 {count}건 : 네이버쇼핑 EP 정보 일괄 수정 실패`,
  MODIFY_BULK_NAVER_SHOPPING_DISPLAYABLE_SUCCESS_MESSAGE: `성공 {count}건 : 노출(으)로 변경 완료`,
  MODIFY_BULK_NAVER_SHOPPING_NOT_DISPLAYABLE_SUCCESS_MESSAGE: `성공 {count}건 : 미노출(으)로 변경 완료`,
  MODIFY_BULK_NAVER_SHOPPING_DISPLAYABLE_FAIL_MESSAGE: `실패 {count}건 : 노출(으)로 변경 실패`,
  MODIFY_BULK_NAVER_SHOPPING_NOT_DISPLAYABLE_FAIL_MESSAGE: `실패 {count}건 : 미노출(으)로 변경 실패`,
});
