







import { Vue, Component } from 'vue-property-decorator';
import { i18nListByKey } from '@/utils/i18n';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';

@Component({
  components: {
    TabMenu,
  },
})
export default class GoogleAdConfig extends Vue {
  private menuList: TabMenuI[] = [
    { display: 'MARKETING.CONFIGURATION.PRODUCT_MANAGE', path: 'product-manage' },
    { display: 'MARKETING.CONFIGURATION.ACCOUNT_MANAGE', path: 'account-manage' },
    { display: 'MARKETING.CONFIGURATION.AD_MANAGE', path: 'ad-manage' },
    { display: 'MARKETING.CONFIGURATION.REPORT', path: 'report' },
  ];

  created() {
    this.menuList = i18nListByKey(this, this.menuList, 'display');
  }
}
