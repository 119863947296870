export const getRadioGroup = () => ({
  name: 'opened',
  data: [
    {
      id: 'opened-y',
      name: 'opened-y',
      value: true,
      display: '공개',
    },
    {
      id: 'opened-n',
      name: 'opened-n',
      value: false,
      display: '비공개',
    },
  ],
  nonI18nConvert: true,
});
