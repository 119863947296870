import { KakaoTemplateSettingData, PutSmsTemplatesAutoTemplatesTemplateTypeRequest } from 'ncp-api-supporter';
import { ActionContext } from 'vuex';
import { MemberState } from '@/types/member';
import { RootState } from '@/store/root';
import { Vue } from 'vue-property-decorator';

export default {
  namespaced: true,
  state: {
    smsTemplateSetting: [],
    kakaoTemplateSetting: {},
  },
  mutations: {
    CHANGE_SMS_TEMPLATE_SETTING: (
      state: MemberState,
      smsRequest: PutSmsTemplatesAutoTemplatesTemplateTypeRequest,
    ): void => {
      let isDuplicate = false;

      state.smsTemplateSetting.forEach((smsData, index) => {
        if (smsData.pathParams.templateType === smsRequest.pathParams.templateType) {
          state.smsTemplateSetting[index].data.usable = smsRequest.data.usable;
          state.smsTemplateSetting[index].data.content = smsRequest.data.content;
          isDuplicate = true;
        }
      });

      if (!isDuplicate) {
        state.smsTemplateSetting = state.smsTemplateSetting.concat([smsRequest]);
      }
    },
    RESET_SMS_TEMPLATE_SETTING: (state: MemberState): void => {
      state.smsTemplateSetting = [];
    },
    CHANGE_KAKAO_TEMPLATE_SETTING: (state: MemberState, settingData: KakaoTemplateSettingData): void => {
      Vue.set(state.kakaoTemplateSetting, settingData.templateNo, {
        used: settingData.used,
        templateType: settingData.templateType,
      });
    },
    RESET_KAKAO_TEMPLATE_SETTING: (state: MemberState): void => {
      state.kakaoTemplateSetting = {};
    },
  },
  actions: {
    changeSmsTemplateSetting(
      { commit }: ActionContext<MemberState, RootState>,
      smsRequest: PutSmsTemplatesAutoTemplatesTemplateTypeRequest,
    ): void {
      commit('CHANGE_SMS_TEMPLATE_SETTING', smsRequest);
    },
    resetSmsTemplateSetting({ commit }: ActionContext<MemberState, RootState>): void {
      commit('RESET_SMS_TEMPLATE_SETTING');
    },
    changeKakaoTemplateSetting(
      { commit }: ActionContext<MemberState, RootState>,
      settingData: KakaoTemplateSettingData,
    ): void {
      commit('CHANGE_KAKAO_TEMPLATE_SETTING', settingData);
    },
    resetKakaoTemplateSetting({ commit }: ActionContext<MemberState, RootState>): void {
      commit('RESET_KAKAO_TEMPLATE_SETTING');
    },
  },
  getters: {
    getData(state: MemberState): PutSmsTemplatesAutoTemplatesTemplateTypeRequest[] {
      return [...state.smsTemplateSetting];
    },
    getKakaoTemplateSetting(state: MemberState): KakaoTemplateSettingData[] {
      return Object.entries(state.kakaoTemplateSetting).map(([templateNo, usedAndTemplateType]) => ({
        used: usedAndTemplateType.used,
        templateType: usedAndTemplateType.templateType,
        templateNo: Number(templateNo),
      }));
    },
  },
};
