















import { Component, Prop, Vue } from 'vue-property-decorator';
import SyncFailListItem from '@/components/shipping/order/SyncFailListItem.vue';
import { OrdersExternalFailHistory } from 'ncp-api-supporter';

@Component({ components: { SyncFailListItem } })
export default class SyncFailList extends Vue {
  @Prop({ required: false, default: [] })
  private readonly syncFailList: OrdersExternalFailHistory[];
}
