export default Object.freeze({
  TITLE: 'NCP사용료 가이드',
  NOTICE_CRITERIA: 'NCP서비스 이용요금 산정 기준 (결제액 구간별 요금제)',
  NOTICE_CALC: '해당 월 ‘적용 쇼핑몰’의 결제액 합계 금액 X 해당 결제액 구간의 적용 요율',
  SETTLEMENT_CRITERIA: '정산 기준',
  DIVISION: '구분',
  CONTENT: '내용',
  APPLY_RATE: '월별 결제액 구간 적용요율',
  APPLY_RATE_CONTENT_1: '- 구간 적용요율 : 결제액 구간 별 적용요율 (1.6% ~ 별도협의 ) 기준 산정',
  APPLY_RATE_CONTENT_2: '- 협의 적용요율 : 결제액 상관없이 적용되는 적용요율 기준 산정',
  EXTRA_SERVICE_USE: '부가서비스 사용여부',
  EXTRA_SERVICE_CONTENT: '- 이용요금 적용요율에서 0.05~0.1% 차감',
  SPECIAL_DISCOUNT: '특별 할인',
  SPECIAL_DISCOUNT_CONTENT_1: '- 이용요금 면제 : 서비스 개시일로부터 2개월간 이용요금 면제',
  SPECIAL_DISCOUNT_CONTENT_2: '- 적용요율 차감 : 특별한 사유(서비스 장애/점검 등)에 따른 결제액 구간 별 적용요율 차감',
  ADJUST_DISCOUNT: '조정할인',
  ADJUST_DISCOUNT_CONTENT_1: '- 이용요금 면제 : 서비스 성격에 따라 이용요금을 전면 면제',
  ADJUST_DISCOUNT_CONTENT_2: '- 적용요율 차감 : 제공자와 이용자간 계약에 따라 예외적으로 결제액 구간 별 적용요율 차감',
  FINAL_SETTLEMENT_EXAMPLE: '최종 정산금액 예시',
  EXAMPLE_NOTICE_1: '구간 적용요율은 부가서비스 사용여부, 특별할인, 조정할인 등에 따라 변경될 수 있음.',
  EXAMPLE_NOTICE_2:
    '최종 정산금액은 최소금액설정, 최대금액설정에 따라 정산금액과 상이할 수 있음. (최종 정산금액이 최소금액인 경우, 노란색표시 / 최대금액인 경우, 빨간색 표시)',
  EXAMPLE_NOTICE_3: '특별할인이나 조정할인에 이용요금면제가 포함되어있는 경우에는 최종 정산금액이 면제될 수 있음.',
  EXAMPLE_NOTICE_4: '최종 정산금액이 조정되는 경우에는 조정 최종 정산금액에 조정된 금액이 초록색으로 표기됨.',
  USE_PERIOD: '사용 기간',
  MALL_NAME: '쇼핑몰명',
  SETTLEMENT_PRICE: '정산금액 (A×F)',
  FINAL_SETTLEMENT_PRICE: '최종 정산금액(D나E에 이용요금 면제가 있는경우에는 전체 면제)',
  EXAMPLE_YEAR_MONTH: '2018년 6월',
  MALL_A: 'A쇼핑몰',
  MALL_B: 'B쇼핑몰',
  MALL_C: 'C쇼핑몰',
  MALL_D: 'D쇼핑몰',
  MALL_E: 'E쇼핑몰',
  FEE_EXEMPTION: '이용요금 면제',
  MONEY_UNIT: ' 원',
  TOTAL_SALES: '매출합계 (A)',
  NCP_RATE: '기준 적용요율(B)',
  EXTRA_SERVICE: '부가서비스',
  EXTRA_SERVICE_DC_RATE: '부가서비스 할인율(C)',
  SPECIAL_DISCOUNT_D: '특별할인(D)',
  ADJUST_DISCOUNT_E: '조정할인(E)',
  FINAL_APPLY_RATE: '최종적용요율 (F=B-C-D-E)',
  COUNT: '개',
  CLOSE: '닫기',
});
