
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolTipOption } from '@/types/tooltip';

@Component
export default class NaverSetting extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption;
}
