import { GridProp } from '@/types';
import { headerWithTooptipRenderer, hiddenDescriptionRenderer } from '@/utils/grid/rendererUtils';
import $el from '@/utils/element';

export enum MENU_AUTHORITY_TYPE {
  NONE = 'NONE',
  READ = 'READ',
  READ_WRITE = 'READ_WRITE',
  INDIVIDUAL_SETTING_SUB_MENU = 'INDIVIDUAL_SETTING_SUB_MENU',
}

export interface MenuDataType {
  menuNo: number;
  name: string;
  order: number;

  authorityType: MENU_AUTHORITY_TYPE;

  parent: MenuDataType;
  children: MenuDataType[];

  checked: boolean;
  depth: number;
  show: boolean;
  isProductMenu: boolean;

  [key: string]: any;
}

export const getAuthorityGroupSelectType = () => {
  return {
    privacyType: [
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.PRIVACY_AUTHORITY'), value: '' },
      { name: window.$t('MAY'), value: true },
      { name: window.$t('UNABLE'), value: false },
    ],

    marketingType: [
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.PRODUCT_AUTHORITY'), value: '' },
      { name: window.$t('Y'), value: true },
      { name: window.$t('N'), value: false },
    ],

    smsType: [
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.SMS_AUTHORITY'), value: '' },
      { name: window.$t('Y'), value: true },
      { name: window.$t('N'), value: false },
    ],

    viewMenuType: [
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.VIEW_BY_MENU_1'), value: 0 },
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.VIEW_BY_MENU_2'), value: 1 },
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.VIEW_BY_MENU_3'), value: 2 },
    ],

    authorityMenuType: [
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.SELECT_AUTHORITY'), value: '' },
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_NONE'), value: MENU_AUTHORITY_TYPE.NONE },
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_READ'), value: MENU_AUTHORITY_TYPE.READ },
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_READ_WRITE'), value: MENU_AUTHORITY_TYPE.READ_WRITE },
    ],
  };
};
export const getAuthorityGroupGridOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP',
        name: 'name',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return `<a>${cell.value}</a>`;
        },
        whiteSpace: 'pre-line',
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_DESC',
        name: 'description',
        align: 'center',
        minWidth: 200,
        whiteSpace: 'pre-line',
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_ADMIN',
        name: 'adminCount',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (!cell.value) {
            return `${0}${window.$t('PERSONS')}`;
          }

          return `<a>${cell.value}${window.$t('PERSONS')}</a>`;
        },
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.PRIVACY_AUTHORITY',
        name: 'permitsPrivateInformation',
        align: 'center',
        minWidth: 120,
        formatter: cell => {
          return cell.value ? window.$t('MAY') : window.$t('UNABLE');
        },
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.PRODUCT_AUTHORITY',
        name: 'allowsMarketingDirection',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return cell.value ? 'Y' : 'N';
        },
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_MALL_LENGTH',
        name: 'malls',
        align: 'center',
        minWidth: 100,
        renderer: props => {
          if (!props.value) {
            props.cellText = '';
          } else {
            props.cellText = `${(props.value as []).length}${window.$t('EACH')}`;
          }

          props.hiddenDescription = '';
          if (props.value) {
            const arr = [];
            let index = 0;
            props.value.map(mall => {
              if (!arr[index]) {
                arr[index] = '';
              }

              if (index > 0 || (index === 0 && arr[index].length > 0)) {
                if (arr[index].length === 0) {
                  arr[index - 1] += ', ';
                } else {
                  arr[index] += ', ';
                }
              }

              if (arr[index].length + mall.mallName.length > 80) {
                ++index;
                arr[index] = '';
              }

              arr[index] += mall.mallName;
            });

            props.hiddenDescription = arr.join('<br>');
          }

          // 해당 툴팁만 특별히 좌측 정렬 적용하기 위해 예외처리 추가
          const renderer = hiddenDescriptionRenderer(props);
          try {
            const target = $el(renderer.getElement());
            target.find('.tool-tip')[0].getElement().style.textAlign = 'left';
          } catch (e) {
            console.error(e);
          }

          return renderer;
        },
      },
      // {
      //   header: 'CONFIGURATION.AUTHORITY_GROUP.SMS_AUTHORIT',
      //   name: 'smsReceived',
      //   align: 'center',
      //   minWidth: 100,
      //   formatter: cell => {
      //     return cell.value ? 'Y' : 'N';
      //   },
      // },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_CREATOR',
        name: 'creatorName',
        align: 'center',
        minWidth: 200,
        whiteSpace: 'pre-line',
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_CREATE_DATE',
        name: 'creationDateTime',
        align: 'center',
        minWidth: 150,
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_LAST_MODIFIER',
        name: 'modifierName',
        align: 'center',
        minWidth: 200,
        whiteSpace: 'pre-line',
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_LAST_MODIFY_DATE',
        name: 'modificationDateTime',
        align: 'center',
        minWidth: 150,
      },
      {
        header: 'COPY_INSERT',
        name: 'copy',
        align: 'center',
        minWidth: 100,
        formatter: () => {
          return `<a>${window.$t('COPY_INSERT')}</a>`;
        },
      },
      {
        header: 'DELETE',
        name: 'delete',
        align: 'center',
        minWidth: 100,
        formatter: () => {
          return `<button class="btnDel-sm">${window.$t('DELETE')}</button>`;
        },
      },
    ],
    options: {
      keyColumnName: 'no',
      pageOptions: {
        perPage: 30,
        page: 1,
      },
    },
  };
};

export const getAuthorityGroupAdminListSelectType = () => {
  return {
    searchType: [
      { name: window.$t('ALL'), value: 'ALL' },
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.ADMIN_ID'), value: 'ADMIN_ID' },
      { name: window.$t('CONFIGURATION.AUTHORITY_GROUP.ADMIN_NAME'), value: 'ADMIN_NAME' },
    ],
  };
};
export const getAuthorityGroupAdminListGridOption = (): GridProp => {
  return {
    header: {
      columns: [
        {
          name: 'productManager',
          renderer: props => {
            props.tooltipId = 'authority-group-admin-list-product-manager';
            return headerWithTooptipRenderer(props);
          },
        },
      ],
    },
    columns: [
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.ADMIN_ID',
        name: 'adminId',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return `<a>${cell.value || ''}</a>`;
        },
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.ADMIN_NAME',
        name: 'adminName',
        align: 'center',
        minWidth: 100,
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.PRODUCT_MANAGER',
        name: 'productManager',
        align: 'center',
        minWidth: 150,
        formatter: cell => {
          return cell.value ? 'Y' : 'N';
        },
      },
      {
        header: 'DEPARTMENT',
        name: 'departmentName',
        align: 'center',
        minWidth: 100,
      },
      {
        header: 'POSITION',
        name: 'jobPositionName',
        align: 'center',
        minWidth: 100,
      },
      {
        header: 'DUTY',
        name: 'jobDutyName',
        align: 'center',
        minWidth: 100,
      },
      {
        header: 'CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_REGISTERED_DATE',
        name: 'authorityRegisteredDateTime',
        align: 'center',
        minWidth: 150,
      },
    ],
    options: {
      keyColumnName: 'adminNo',
      pageOptions: {
        perPage: 30,
        page: 1,
      },
    },
    displayOptions: {
      showsRightArea: false,
      headerTooltipOption: [
        {
          id: '#authority-group-admin-list-product-manager',
          popupData: {
            data: {
              message: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_GROUP_ADMIN_LIST_PRODUCT_MANAGER_TOOLTIP'),
            },
          },
        },
      ],
    },
  };
};

export const getAuthorityGroupToolTipOption = () => {
  return {
    selectedMall: {
      message: window.$t('CONFIGURATION.AUTHORITY_GROUP.SELECTED_MALL_TOOLTIP'),
    },
    privacyAuthority: {
      message: window.$t('CONFIGURATION.AUTHORITY_GROUP.PRIVACY_AUTHORITY_TOOLTIP'),
    },
    adminGuidanceSmsAuthority: {
      message: window.$t('CONFIGURATION.AUTHORITY_GROUP.ADMIN_GUIDANCE_SMS_AUTHORITY_TOOLIP'),
    },
  };
};
export const getAuthorityGroupCheckBoxOption = () => {
  return {
    getMallCheckBoxOption: data => {
      return {
        name: 'MallCheckbox',
        hasAll: false,
        noBrackets: true,
        data: data,
      };
    },

    allSelect: {
      name: 'AllSelectCheckbox',
      hasAll: false,
      noBrackets: true,
      data: [
        {
          id: 'all-select-mall',
          value: 'true',
          display: window.$t('ALL_CHECK'),
        },
      ],
    },

    md: {
      name: 'md',
      hasAll: false,
      noBrackets: false,
      data: [
        {
          id: 'md-check',
          value: 'true',
          display: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_MD'),
        },
      ],
    },
  };
};
type MenuData = {
  id: string;
  value: string;
  display: string;
  disabled?: boolean;
};
export const getAuthorityGroupRadioBoxOption = () => {
  return {
    privacyAuthority: {
      name: 'radio-privacy-authority',
      data: [
        { id: 'enable-privacy-authority', value: true, display: window.$t('MAY') },
        { id: 'not-enable-privacy-authority', value: false, display: window.$t('UNABLE') },
      ],
    },
    smsAuthority: {
      name: 'radio-sms-authority',
      data: [
        { id: 'receive-sms-authority', value: true, display: window.$t('CONFIGURATION.AUTHORITY_GROUP.SMS_RECEIVE') },
        {
          id: 'not-receive-sms-authority',
          value: false,
          display: window.$t('CONFIGURATION.AUTHORITY_GROUP.SMS_NOT_RECEIVE'),
        },
      ],
    },

    getMenuAuthorityRadioOption: menu => {
      const data: MenuData[] = [
        {
          id: `none-menu-${menu.menuNo}`,
          value: MENU_AUTHORITY_TYPE.NONE,
          display: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_NONE'),
        },
        {
          id: `read-menu-${menu.menuNo}`,
          value: MENU_AUTHORITY_TYPE.READ,
          display: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_READ'),
        },
        {
          id: `read-write-menu-${menu.menuNo}`,
          value: MENU_AUTHORITY_TYPE.READ_WRITE,
          display: window.$t('CONFIGURATION.AUTHORITY_GROUP.AUTHORITY_READ_WRITE'),
        },
      ];

      if (
        menu.children &&
        menu.children.length > 0 &&
        menu.authorityType === MENU_AUTHORITY_TYPE.INDIVIDUAL_SETTING_SUB_MENU
      ) {
        data.push({
          id: `individual-setting-sub-menu-${menu.menuNo}`,
          value: MENU_AUTHORITY_TYPE.INDIVIDUAL_SETTING_SUB_MENU,
          display: window.$t('CONFIGURATION.AUTHORITY_GROUP.INDIVIDUAL_SETTING_SUB_MENU'),
        });
      }


      if(process.env.VUE_APP_KAKAO_FRIEND_MENU_NO.split(',').map(Number).includes(menu.menuNo)){
        return {
          name: `radio-menu-authority-${menu.menuNo}`,
          data: data.map(item => ({
            ...item,
            disabled: item.value === MENU_AUTHORITY_TYPE.READ,
          }))
        };
      }

      return {
        name: `radio-menu-authority-${menu.menuNo}`,
        data,
      };
    },
  };
};
