
























































import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import {
  CertificationData,
  CertificationDataKey,
  handleExcelDownloadProcess,
} from '@/views/popups/excel/ExcelDownloadRequest';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';

interface ExcelContentSummary {
  fileName: string;
  excelNo: number;
  menuCategory: string;
  fileType: string;
}

type FormData = {
  [key in CertificationDataKey]: DataField;
};

interface DataField {
  value: string;
  regexp?: RegExp;
}

const validate = ({ value, regexp }: DataField): boolean => {
  return regexp.test(value);
};

const replaceToRegexp = ({ value, regexp }: DataField): string => {
  return value.replace(regexp, '');
};
@Component({
  components: { NoticeBox, ModalHeader },
})
export default class ServerExcelReasonLayerPopup extends Vue {
  @Prop({ required: true })
  private readonly data!: ExcelContentSummary;

  @Ref() private readonly reasonInput!: HTMLInputElement;
  @Ref() private readonly passwordInput!: HTMLInputElement;

  private formData: FormData = {
    reason: {
      value: '',
      regexp: /[^a-zA-Z0-9ㄱ-힣\s]/g,
    },
    password: {
      value: '', // is plain text password. please very careful
      regexp: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\\!~@#$%^&*()_+\-=])[A-Za-z0-9\\!~@#$%^&*()_+\-=]{10,16}$/,
    },
  };

  // submit 후 destroy 되는 컴포넌트여서 reset 이 필요하지는 않으나 파일 패스워드를 핸들링하니 보험들어놓기
  private reset(): void {
    const { reason, password } = this.formData;
    reason.value = '';
    password.value = '';
  }

  private onReplace(dataField: DataField): void {
    dataField.value = replaceToRegexp(dataField);
  }

  get noticeList(): string[] {
    return this.$t('EXCEL.NOTICE_LIST')
      .toString()
      .trim()
      .split('\n');
  }

  private async onSubmitForm(): Promise<void> {
    const formValidated = this.formValidate(this.formData);
    if (!formValidated) return;

    const { reason, password } = this.formData;
    const certificationData: CertificationData = {
      reason: reason.value,
      password: password.value,
    };

    const { excelNo, fileName } = this.data;

    await handleExcelDownloadProcess(excelNo, fileName, certificationData);

    this.reset();
    this.$emit('close');
  }

  private formValidate({ reason, password }: FormData) {
    if (reason.value === '') {
      alert(this.$t('EXCEL.ALERT_REASON_EMPTY'));
      this.reasonInput.focus();
      return false;
    }

    if (password.value === '') {
      alert(this.$t('EXCEL.ALERT_PASSWORD_EMPTY'));
      this.passwordInput.focus();
      return false;
    }

    if (!validate(password)) {
      alert(this.$t('EXCEL.ALERT_PASSWORD_INVALID'));
      this.passwordInput.focus();
      return false;
    }

    return true;
  }
}
