











import { Vue, Component } from 'vue-property-decorator';
import { PostAuthTokenRequest, AuthToken } from 'ncp-api-supporter';
import { i18n } from '@/main';
import {  setSessionCookie, setToken } from '@/utils/token';
import { SecondAuthData } from '@/const/contents/auth';
import { Mutation } from 'vuex-class';
import { Admin } from '@/types';
import AuthSecondPopup from '@/views/auth/authPopups/AuthSecondPopupIframe.vue';


const { VUE_APP_REDIRECT_URL, VUE_APP_NODE_ENV, VUE_APP_COMMERCE_MAIN } = process.env;

@Component({
  components: {
    AuthSecondPopup,
  },
})
export default class OauthCodeReceiver extends Vue {
  @Mutation('admin/SET_ADMIN') private setAdmin!: (admin: Admin) => void;
  
  private secondAuthData: SecondAuthData = {
    key: '',
    plan: '',
    target: {
      email: '',
      enableOtp: false,
      mobile: '',
    },
    type: '',
    types: [],
  };
  private isSecondAuthPopupOpen = false;
  private closePopup() {
    this.isSecondAuthPopupOpen = false;

    window.location.href = '/pro/auth';
  }

  private completeSecondAuth(authToken: AuthToken) {
    this.setToken(authToken);

    alert('관리자 접속이 승인되었습니다.');
    this.isSecondAuthPopupOpen = false;
    this.moveRoot();
  }

  private moveRoot(): void {
    const continuePath = this.$route.query?.redirectUrl?.toString() ?? '/';
    this.$router.push(continuePath);
  }

  private setToken(authToken: AuthToken) {
    const { accessToken, refreshToken, autoLogoutMin } = authToken;
    const admin = setToken(accessToken, refreshToken, autoLogoutMin);

    setSessionCookie();

    if (admin) {
      this.setAdmin(admin);
    }
  }


  private postTokenHandler() {
    const code = this.$route.query?.code?.toString();
    const redirectUrl = this.$route.query?.state?.toString();
    code && this.postAuthToken(code, redirectUrl);
  }

  private postAuthToken(code: string, redirectUrl?: string) {
    const request: PostAuthTokenRequest = {
      data: {
        adminType: 'SERVICE',
        grantType: 'OAUTH_CODE',
        code,
      },
    };

    if (VUE_APP_NODE_ENV === 'local') {
      request.data.redirectUrl = `${VUE_APP_REDIRECT_URL}/oauth/redirect`;
    }

    this.$api
      .postAuthToken(request)
      .then(res => {
        if (res.data.planType === 'PREMIUM') {
          alert(i18n.t('ALERT_CHECK_PLAN_TYPE', { planType: res.data.planType }));
          window.location.href = process.env.VUE_APP_PREMIUM_ADMIN_URL;

          return;
        }

        this.$router.push({
          name: 'AuthMain',
          query: { ...res.data, autoLogoutMin: res.data.autoLogoutMin.toString(), redirectUrl },
        });
      })
      .catch(e => {
       if (e.data.status === 400 && e.data.code && e.data.code.includes('AU1001')) {
          this.secondAuthData = {
            ...this.secondAuthData,
            ...e.data.body,
          };

          this.isSecondAuthPopupOpen = true;
          return;
        }
        alert(e.data.message);

        window.open(VUE_APP_COMMERCE_MAIN, '_self');
      });
  }

  created() {
    this.postTokenHandler();
  }
}
