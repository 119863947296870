





















import { Component, Prop, Vue } from 'vue-property-decorator';
import ServiceList from '@/components/app/basic/ServiceList.vue';
import { AdditionalService } from '@/types/addition';

import { RemoteCode } from '@/api/godo/type';

export type ItemViewType = 'app' | null;

@Component({
  components: { ServiceList },
})
export default class ServiceSection extends Vue {
  @Prop({ required: true })
  private readonly data!: AdditionalService;
  @Prop({ required: true })
  private readonly currentRemoteCode!: RemoteCode | null;
  @Prop({ default: null })
  private readonly viewType?: ItemViewType;

  private srcDoc = '';

  public setSrcDoc(srcDoc: Document): void {
    this.srcDoc = srcDoc.documentElement.innerHTML;
  }

  public reset(): void {
    this.srcDoc = null;
  }
}
