
















































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
@Component({
  components: { TextInput },
})
export default class RefundExchangeFee extends Vue {
  @Prop({ required: true }) private postPredict!: {};
  @Prop({ required: true }) private objJson!: string;
  @Prop({ default: '' }) private pageName!: string;
  @Prop({ default: 'SELLER_COLLECT' }) private returnWayType!: string;
  @Watch('postPredict')
  onPredictChange() {
    this.deliveryCalculation();
  }
  private textDecoration = 'none';
  private returnDeliveryAmt = 0;
  private returnRemoteDeliveryAmt = 0;
  private exchangeDeliveryAmt = 0;
  private exchangeRemoteDeliveryAmt = 0;
  private totalReturnExchangeDeliveryAmt = 0;
  private returnDeliveryAmtAdjustAmtDisabled = false;
  private returnDeliveryAmtAdjustReasonDisabled = false;
  private exchangeDeliveryAmtAdjustAmtDisabled = false;
  private exchangeDeliveryAmtAdjustReasonDisabled = false;
  public returnDeliveryAmtAdjustAmt = 0;
  public returnDeliveryAmtAdjustReason = '';
  public exchangeDeliveryAmtAdjustAmt = 0;
  public exchangeDeliveryAmtAdjustReason = '';
  private returnDeliveryAmtAdjustAmtChange() {
    if (!this.returnDeliveryAmtAdjustAmt) {
      this.returnDeliveryAmtAdjustAmt = 0;
      this.returnDeliveryAmtAdjustReason = '';
    }
    if (this.returnDeliveryAmtAdjustAmt > (this.postPredict as any).amounts.returnDelivery.totalPureReturnDeliveryAmt) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALERT_ADJUSTMENT_DELIVERY'));
      this.returnDeliveryAmtAdjustAmt = 0;
    }
    this.$emit('setData');
  }
  private exchangeDeliveryAmtAdjustAmtChange() {
    if (!this.exchangeDeliveryAmtAdjustAmt) {
      this.exchangeDeliveryAmtAdjustAmt = 0;
      this.exchangeDeliveryAmtAdjustReason = '';
    }
    if (this.exchangeDeliveryAmtAdjustAmt > (this.postPredict as any).amounts.exchangeDelivery.totalDeliveryAmt) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALERT_ADJUSTMENT_DELIVERY'));
      this.exchangeDeliveryAmtAdjustAmt = 0;
    }
    this.$emit('setData');
  }
  private deliveryCalculation() {
    const isPrepaid = (this.postPredict as any).shipping.prepaid;
    this.returnDeliveryAmt = (this.postPredict as any).amounts.returnDelivery.returnDeliveryAmt;
    this.returnRemoteDeliveryAmt = (this.postPredict as any).amounts.returnDelivery.returnRemoteDeliveryAmt;
    this.exchangeDeliveryAmt = (this.postPredict as any).amounts.exchangeDelivery.deliveryAmt;
    this.exchangeRemoteDeliveryAmt = (this.postPredict as any).amounts.exchangeDelivery.remoteDeliveryAmt;
    const {
      prepaidDeliveryAmt: prepaidExchangeDeliveryAmt,
      prepaidRemoteDeliveryAmt: prepaidExchangeRemoteDeliveryAmt,
    } = (this.postPredict as any).amounts.exchangeDelivery;
    this.totalReturnExchangeDeliveryAmt =
      (isPrepaid ? this.returnDeliveryAmt + this.returnRemoteDeliveryAmt : 0) -
      this.returnDeliveryAmtAdjustAmt +
      prepaidExchangeDeliveryAmt +
      prepaidExchangeRemoteDeliveryAmt -
      (isPrepaid ? this.exchangeDeliveryAmtAdjustAmt : 0);
    if ((this.$parent.$refs.componentItemsClaim as any).responsibleObjectTypeValue === 'BUYER') {
      this.returnDeliveryAmtAdjustAmtDisabled = false;
      this.returnDeliveryAmtAdjustReasonDisabled = false;
      this.exchangeDeliveryAmtAdjustAmtDisabled = false;
      this.exchangeDeliveryAmtAdjustReasonDisabled = false;
      this.textDecoration = 'none';
    } else {
      this.returnDeliveryAmtAdjustAmtDisabled = true;
      this.returnDeliveryAmtAdjustReasonDisabled = true;
      this.exchangeDeliveryAmtAdjustAmtDisabled = true;
      this.exchangeDeliveryAmtAdjustReasonDisabled = true;
      this.textDecoration = 'line-through';
    }
    if (this.returnDeliveryAmt === 0) {
      this.returnDeliveryAmtAdjustAmtDisabled = true;
      this.returnDeliveryAmtAdjustReasonDisabled = true;
    }
    if (this.exchangeDeliveryAmt === 0) {
      this.exchangeDeliveryAmtAdjustAmtDisabled = true;
      this.exchangeDeliveryAmtAdjustReasonDisabled = true;
    }
    if (!isPrepaid) {
      this.exchangeDeliveryAmtAdjustAmtDisabled = true;
      this.exchangeDeliveryAmtAdjustReasonDisabled = true;
    }
  }
  protected onlyCommaNumber(event: Event) {
    const $input = event.target as HTMLInputElement;
    if ($input.value === '' || $input.value === '0') {
      $input.value = '0';
      return;
    }
    $input.value = $input.value
      .toString()
      .replace(/\D/g, '')
      .replace(/^0+/, '');
    // .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
