import { GridProp, InputNumber, Mode } from '@/types';
import { DeliveryTemplate } from 'ncp-api-supporter/dist/types/modules/delivery/deliveryTemplate';
import { i18n } from '@/main';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { convertToI18n } from '@/utils/i18n';
import { MODE } from '@/const/common';
import { PostAreafee } from 'ncp-api-supporter';
import { getDateSpaceTime } from '@/utils/dateFormat';
import { OptionData } from '@/helpers/type';
import { rowNumRenderer } from '@/utils/grid/rendererUtils';
import { DeliveryTemplateSingleQuery } from '@/types/delivery';

export function i18nForDeliveryGroupTemplate(word: string, data?: string, useDefault?: boolean): string {
  return convertToI18n('DELIVERY.GROUP_TEMPLATE.', word, data, useDefault);
}
export function i18nForDelivery(word: string, data?: string, useDefault?: boolean): string {
  return convertToI18n('CONFIGURATION.DELIVERY.', word, data, useDefault);
}

export const getDefaultQuery = (): DeliveryTemplateSingleQuery => {
  return {
    templateName: '',
    startDate: '',
    endDate: '',
    deliveryType: '',
    deliveryConditionType: '',
    prepaid: '',
    areaFee: '',
    releaseWarehouseNo: '',
    returnWarehouseNo: '',
    page: 1,
    size: 30,
  };
};

export const getShippingChargeSelectType = () => {
  return {
    deliveryType: [
      { name: 'ALL', value: '' },
      { name: 'DELIVERY.GROUP_TEMPLATE.PARCEL_DELIVERY', value: 'PARCEL_DELIVERY' },
      { name: 'DELIVERY.GROUP_TEMPLATE.DIRECT_DELIVERY', value: 'DIRECT_DELIVERY' },
    ],
    deliveryConditionType: [
      { name: 'ALL', value: '' },
      { name: 'DELIVERY.GROUP_TEMPLATE.FREE', value: 'FREE' },
      { name: 'DELIVERY.GROUP_TEMPLATE.CONDITIONAL', value: 'CONDITIONAL' },
      { name: 'DELIVERY.GROUP_TEMPLATE.FIXED_FEE', value: 'FIXED_FEE' },
      { name: 'DELIVERY.GROUP_TEMPLATE.QUANTITY_PROPOSITIONAL_FEE', value: 'QUANTITY_PROPOSITIONAL_FEE' },
    ],
    prepaid: [
      { name: 'DELIVERY.GROUP_TEMPLATE.PAY_TYPE', value: '' },
      { name: 'DELIVERY.GROUP_TEMPLATE.PREPAID_TRUE', value: true },
      { name: 'DELIVERY.GROUP_TEMPLATE.PREPAID_FALSE', value: false },
    ],
    areaFee: [
      { name: 'DELIVERY.GROUP_TEMPLATE.ADDITIONAL_SHIPPING', value: '' },
      { name: 'DELIVERY.GROUP_TEMPLATE.USE', value: true },
      { name: 'DELIVERY.GROUP_TEMPLATE.NOT_USE', value: false },
    ],
    releaseWarehouseNo: [{ name: i18nForDeliveryGroupTemplate('RELEASE_WAREHOUSE'), value: '' }] as {
      name: string;
      value: InputNumber;
    }[],
    returnWarehouseNo: [{ name: i18nForDeliveryGroupTemplate('RETURN_WAREHOUSE_WITHOUT_SPACE'), value: '' }] as {
      name: string;
      value: InputNumber;
    }[],
    deliveryTypeFromEditPopup: [
      { name: 'DELIVERY.GROUP_TEMPLATE.PARCEL_DELIVERY', value: 'PARCEL_DELIVERY' },
      { name: 'DELIVERY.GROUP_TEMPLATE.DIRECT_DELIVERY', value: 'DIRECT_DELIVERY' },
    ],
    deliveryConditionTypeEditPopup: [
      { name: 'DELIVERY.GROUP_TEMPLATE.SELECT', value: '' },
      { name: 'DELIVERY.GROUP_TEMPLATE.FREE', value: 'FREE' },
      { name: 'DELIVERY.GROUP_TEMPLATE.CONDITIONAL', value: 'CONDITIONAL' },
      { name: 'DELIVERY.GROUP_TEMPLATE.FIXED_FEE', value: 'FIXED_FEE' },
      { name: 'DELIVERY.GROUP_TEMPLATE.QUANTITY_PROPOSITIONAL_FEE', value: 'QUANTITY_PROPOSITIONAL_FEE' },
    ],
  };
};
export const getShippingChargeRadioBoxOption = () => {
  return {
    payType: {
      name: 'radio-pay-type',
      data: [
        { id: 'pre-payment', value: true, display: 'DELIVERY.GROUP_TEMPLATE.PREPAID_TRUE' },
        { id: 'cash-on-delivery', value: false, display: 'DELIVERY.GROUP_TEMPLATE.PREPAID_FALSE' },
      ],
    },
    usesAreaFee: {
      name: 'radio-use-area-fee',
      data: [
        { id: 'not-use-area-fee', value: false, display: 'DELIVERY.GROUP_TEMPLATE.NOT_USE' },
        { id: 'use-area-fee', value: true, display: 'DELIVERY.GROUP_TEMPLATE.USE' },
      ],
    },
  };
};
export const getShippingChargeTemplateGridOption = (): GridProp<DeliveryTemplate> => {
  return {
    header: {},
    columns: [
      {
        header: 'DELIVERY.GROUP_TEMPLATE.DELIVERY_FEE_TEMPLATE_NAME',
        name: 'templateName',
        align: 'center',
        minWidth: 60,
        formatter: cell => getAnchorHTML(cell.value?.toString()),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.DELIVERY_TYPE',
        name: 'deliveryType',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          if (cell.value === 'PARCEL_DELIVERY') {
            return i18nForDeliveryGroupTemplate('PARCEL_DELIVERY').toString();
          } else if (cell.value === 'DIRECT_DELIVERY') {
            return i18nForDeliveryGroupTemplate('DIRECT_DELIVERY').toString();
          }
          return '';
        },
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.DELIVERY_AMT_TYPE',
        name: 'deliveryConditionType',
        align: 'center',
        minWidth: 60,
        formatter: cell => i18nForDeliveryGroupTemplate(cell.value?.toString()),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.RELEASE_WAREHOUSE',
        name: 'releaseWarehouse',
        align: 'center',
        minWidth: 60,
        formatter: cell => (cell.value as { name }).name,
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.RETURN_WAREHOUSE_WITHOUT_SPACE',
        name: 'returnWarehouse',
        align: 'center',
        minWidth: 60,
        formatter: cell => (cell.value as { name }).name,
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.RETURN_AMT_WITHOUT_SPACE',
        name: 'returnDeliveryAmt',
        align: 'right',
        minWidth: 60,
        formatter: ({ row }) => row.deliveryFee.returnDeliveryAmt?.toString(),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.REGISTER_DATE',
        name: 'registerYmdt',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => getDateSpaceTime(value?.toString()),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.PRODUCT',
        name: 'product',
        align: 'center',
        minWidth: 60,
        formatter: () => getAnchorHTML(i18nForDeliveryGroupTemplate('VIEW')),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.DELETE',
        name: 'templateGroupNo',
        align: 'center',
        minWidth: 60,
        formatter: () => getAnchorHTML(i18n.t('DELETE')),
      },
    ],
    options: {
      keyColumnName: 'templateNo',
      rowHeaders: [
        {
          type: 'rowNum',
          renderer: props => {
            return rowNumRenderer(props.value);
          },
        },
      ],
      pageOptions: {
        page: 1,
        perPage: 30,
        totalCount: 0,
      },
    },
  };
};
export const displayOptions = {
  hasExcelDownloadButton: false,
  hasSettingButton: false,
};
export const getShippingChargeAreaFeeGridOption = (): GridProp => {
  return {
    header: {},
    columns: [
      {
        header: 'DELIVERY.GROUP_TEMPLATE.AREAFEE_LIST_DELETE',
        name: 'copy',
        align: 'center',
        minWidth: 30,
        formatter: () => getAnchorHTML(i18nForDeliveryGroupTemplate('AREAFEE_LIST_DELETE')),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.ADD_FEE_NAME',
        name: 'name',
        align: 'center',
        minWidth: 60,
        formatter: cell => getAnchorHTML(cell.value?.toString()),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.COUNTRY',
        name: 'countryCdLabel',
        align: 'center',
        minWidth: 60,
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.AREAFEE_LIST_NUMBER',
        name: 'areaCnt',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          if (!cell.value) {
            return '0';
          }

          return cell.value + '';
        },
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.REGISTER_DATE',
        name: 'registerYmdt',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => getDateSpaceTime(value?.toString()),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.DELETE',
        name: 'delete',
        align: 'center',
        minWidth: 60,
        formatter: () => getAnchorHTML(i18nForDeliveryGroupTemplate('DELETE')),
      },
    ],
    options: {
      keyColumnName: 'areaFeeNo',
      rowHeaders: ['rowNum'],
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};
export const getShippingWarehouseGridOption = () => {
  return {
    header: {},
    columns: [
      {
        header: 'DELIVERY.GROUP_TEMPLATE.DEFAULT_FLAG',
        name: 'default',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          let text = '';
          if (cell.row.defaultReleaseWarehouse) {
            text += i18nForDeliveryGroupTemplate('DEFAULT_RELEASE') as string;
          }
          if (cell.row.defaultReturnWarehouse) {
            if (text) {
              text += '<br>';
            }
            text += i18nForDeliveryGroupTemplate('DEFAULT_RETURN_WITHOUT_SPACE') as string;
          }
          if (!text) {
            text = '-';
          }

          return text;
        },
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.AREAFEE_LIST_COUNTRY',
        name: 'countryCdLabel',
        align: 'center',
        minWidth: 60,
        formatter: cell => {
          if (cell.row.substitutionText) {
            return '-';
          }

          return cell.row.address && (cell.row.address as { countryCdLabel }).countryCdLabel;
        },
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.WAREHOUSE_LIST_ADDRESS_NAME',
        name: 'name',
        align: 'center',
        minWidth: 60,
        formatter: cell => getAnchorHTML(cell.value?.toString()),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.WAREHOUSE_LIST_ADDRESS',
        name: 'address',
        align: 'center',
        whiteSpace: 'normal',
        minWidth: 60,
        formatter: cell => {
          if (cell.row.substitutionText) {
            return cell.row.substitutionText || '';
          }

          let text = '';

          if (cell.row.address) {
            const address = cell.row.address as { countryCd; zipCd; addressStr };

            if (address.countryCd === 'KR') {
              text = `(${address.zipCd}) ${address.addressStr}`;
            } else {
              text = address.addressStr.substring(0, address.addressStr.length - 4);
            }
          }

          return text;
        },
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.REGISTER_DATE',
        name: 'registerYmdt',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => getDateSpaceTime(value?.toString()),
      },
      {
        header: 'DELIVERY.GROUP_TEMPLATE.DELETE',
        name: 'delete',
        align: 'center',
        minWidth: 60,
        formatter: () => getAnchorHTML(i18nForDeliveryGroupTemplate('DELETE')),
      },
    ],
    options: {
      keyColumnName: 'warehouseNo',
      rowHeaders: ['rowNum'],
      pageOptions: {
        perPage: 30,
        page: 1,
      },
    },
  };
};

// area-fee

export const getDefaultAreaQuery = (countryCd: string, initQuery?: PostAreafee, mode?: Mode): PostAreafee => {
  const query: PostAreafee = {
    name: '',
    countryCd,
    details: [],
  };
  if (initQuery !== undefined) {
    Object.assign(query, initQuery);
    if (mode === MODE.COPY) {
      query.name = '';
    }
  }
  return query;
};

export const getSelectOptions = (): { warehouses: OptionData<number>[]; areaFee: OptionData<number>[] } => {
  return {
    warehouses: [{ name: i18nForDeliveryGroupTemplate('WAREHOUSE_SELECT_DEFAULT'), value: 0 }],
    areaFee: [{ name: i18nForDeliveryGroupTemplate('ADD_FEE_AREA_SELECT_DEFAULT'), value: 0 }],
  };
};

export const getTemplateNo = (value: InputNumber | null): number => {
  if (value === '' || value === null) return 0;
  return Number(value);
};
