





























































import { Component } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { clientId, getAccessUsers, gsiUrl, popupClose } from './userManage';
import { CampaignsAccountsAccessUsersType, CampaignsAccountsAccessUsers } from 'ncp-api-supporter';

@Component
export default class AdsUserManage extends WindowPopupMainVue {
  private readonly clientId: string = clientId;
  private readonly gsiUrl: string = gsiUrl;
  private readonly popupClose: () => void = popupClose;
  private users: CampaignsAccountsAccessUsers[] = [];
  private userType: CampaignsAccountsAccessUsersType = 'ADS';
  private mallNo = 0;

  async created() {
    this.mallNo = this.data.mallNo;
    this.users = await getAccessUsers(this.mallNo, this.userType);
    this.createOauthScript(this.mallNo, this.userType);
  }

  private createOauthScript(mallNo: number, userType: CampaignsAccountsAccessUsersType) {
    const apiScript = document.createElement('script');
    apiScript.src = gsiUrl;
    document.getElementsByTagName('head')[0].appendChild(apiScript);

    window['handleCredentialResponse'] = async res => {
      const request = {
        data: {
          mallNo: mallNo,
          userType: userType,
          idToken: res.credential,
        },
      };

      try {
        await this.$api.postCampaignsAccountsAccessUser(request);
        alert(window.$t('MARKETING.GOOGLE_AD.INVITE_MAIL_ADMIT'));
        this.users = await getAccessUsers(this.mallNo, this.userType);
      } catch (e) {
        console.error(e);
      }
    };
  }
}
