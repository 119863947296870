





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatCurrency } from '@/utils/numberFormat';
import { NCPResponse } from 'ncp-api-supporter';
import { GetProductsMallProductNoOptionsRequest } from 'ncp-api-supporter/dist/types';
import { MallOption } from 'ncp-api-supporter/dist/types/modules/product/option';
import { unescapeHtml } from '@/utils/common';

@Component
export default class SupplyPriceDetails extends Vue {
  @Prop() private readonly data;

  private optionList: MallOption[] = [];

  created() {
    this.searchOptions();
  }

  private async searchOptions() {
    const request: GetProductsMallProductNoOptionsRequest = {
      params: {
        mallProductNo: this.data.mallProductNo,
      },
    };

    const { data }: NCPResponse<MallOption[]> = await this.$api.getProductsMallProductNoOptions(request);
    this.optionList = data;
  }

  private getOptionNameAndValue({ optionName, optionValue }: MallOption): string {
    const optionNames = optionName.split('|');
    const optionValues = optionValue.split('|');
    const decodedOptionValues = optionValues.map(value => unescapeHtml(value));
    const SEPERATOR = ' | ';
    return optionNames.reduce((acc, name, index) => {
      acc += `${unescapeHtml(name)} : ${decodedOptionValues[index]}`;
      if (index !== optionNames.length - 1) acc += SEPERATOR;
      return acc;
    }, '');
  }

  private getSupplyPrice(price: number): string {
    return formatCurrency(price);
  }
}
