





























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { MessageTemplate, MessageTemplateDesc } from '@/types/member';

@Component
export default class ReplacementTextTable extends Vue {
  @Prop() private data!: MessageTemplate[] | MessageTemplateDesc;
  @Prop({ default: true }) private isShowNote!: boolean;
  @Prop({ default: false }) private showAddButton: boolean;
  @Prop({ default: true }) private showExample!: boolean;
  @Prop({ default: false }) private leftAlign: boolean;

  private get colgroupWidth(): string[] {
    const columnLength: number = this.isShowNote ? 4 : 3;
    return Array(columnLength).fill((100 / columnLength).toFixed(4) + '%');
  }

  @Emit('inputReplacementText')
  private addReplacementText(key) {
    return key;
  }
}
