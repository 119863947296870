













import { Vue, Component } from 'vue-property-decorator';
import ExcelDownload from '@/components/product/categorization/excel/ExcelDownload';
import { throwWindowPopupWithProps } from '@/helpers/popup';
import { ParsedObject } from '@/utils/fileReader';
import { getCurrentMallNo, getMallName } from '@/utils/mall';

export interface MallSummary {
  mallNo: number;
  mallName: string;
}

interface UploadPopupConfirmEvent {
  state: 'confirm';
  data: ParsedObject[];
}

interface UploadPopupCloseEvent {
  state: 'close';
  data: MouseEvent;
}

@Component
export default class ExcelController extends Vue {
  get currentMall(): MallSummary {
    const mallNo = getCurrentMallNo(this);
    const mallName = getMallName(mallNo);

    return {
      mallNo,
      mallName,
    };
  }

  private openUploadPopup() {
    const mallNo = this.currentMall.mallNo;

    throwWindowPopupWithProps({
      componentName: 'DisplayCategoryExcelUploadIframe',
      data: { mallNo },
      query: { mallNo },
      size: 'md',
      popupHeight: 500,
    });
  }

  private async fetchCategoriesTree(e: MessageEvent) {
    if (e.data === 'SUCCESS') {
      this.$emit('update');
    }
  }

  // 엑셀 다운로드
  private downloadDisplayCategoriesExcel() {
    const excelDownload = new ExcelDownload(this.currentMall);
    excelDownload.run();
  }

  mounted() {
    window.addEventListener('message', this.fetchCategoriesTree);
  }
}
