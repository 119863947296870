import { OptColumn } from '@/types';
import { APPLY_STATUS } from '@/const/common';
import { i18n } from '@/main';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { ApplyStatusType } from '@/components/product/basic/SearchOptionTypes';

export const getProductJudgementColumn = (): OptColumn => {
  return {
    header: 'PRODUCT.STATUS.APPLY.STATUS',
    name: 'applyStatusType',
    align: 'center',
    minWidth: 100,
    formatter: ({ value }): string => {
      //승인대기, 승인거부, 판매합의거부, 수정 후 승인대기,수정 후 승인거부
      const anchorApplyStatus: ApplyStatusType[] = [
        APPLY_STATUS.APPROVAL_READY,
        APPLY_STATUS.APPROVAL_REJECTION,
        APPLY_STATUS.SALE_AGREEMENT_REJECTION,
        APPLY_STATUS.AFTER_APPROVAL_READY,
        APPLY_STATUS.SALE_AGREEMENT_REJECTION,
      ];
      const text = i18n.t(`PRODUCT.STATUS.APPLY.${value}`).toString();
      return anchorApplyStatus.includes(value as ApplyStatusType) ? getAnchorHTML(text) : text;
    },
  };
};
