



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { NaverClaimData } from 'ncp-api-supporter';

@Component
export default class NaverPayAmountDetail extends Vue {
  @Prop({ required: true })
  private naverExternalData!: NaverClaimData;

  private comma(value: number): string {
    if (value === 0) {
      return '0';
    }

    return value.toLocaleString();
  }
}
