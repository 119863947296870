export default Object.freeze({
  FORWARDING: '출고 후 반품신청',
  PRODUCT: '반품상품',
  BUTTON_SHOW: '열기/닫기',
  RETURNMETHOD: '반품수거방법',
  RETURNWAYTYPE1: '판매자수거요청',
  RETURNWAYTYPE2: '구매자직접반품',
  RETURNIMAGE: '반품 이미지',
  RETURNDELIVERYINVOICE: '반품접수정보',
  SELECTEDVALUE: '택배사 선택',
  CJ: 'CJ대한통운',
  POST: '우체국',
  HANJIN: '한진택배',
  GTX: 'gtx로지스',
  LOTTE: '롯데택배',
  KGB: 'kgb택배',
  LOGEN: '로젠택배',
  GSI: 'gsi Express',
  KGL: 'KG로지스',
  INTRAS: 'INTRAS',
  UPS: 'UPS',
  YAMATO: '야마토운수',
  SAGAWA: '사가와택배',
  JAPANPOST: '일본우편',
  CHUNIL: '천일택배',
  KDEXP: '경동택배',
  HDEXP: '합동택배',
  ILYANG: '일양택배',
  POST_EMS: '우체국 EMS',
  KYOUNGDONG: '경동택배',
  DAESIN: '대신택배',
  CVS: 'CVS편의점택배',
  DHL: 'DHL',
  FEDEX: 'FEDEX',
  GSM: 'GSM국제택배',
  WARPEX: 'WarpEx',
  WIZWA: 'WIZWA',
  ACI: 'ACI Express',
  PANTOS: '범한판토스',
  CJ_INTERNATIONAL: 'CJ대한통운(국제택배)',
  TNT: 'TNT',
  CU: 'CU편의점택배',
  KUNYOUNG: '건영택배',
  LOTTE_INTERNATIONAL: '롯데택배(국제택배)',
  HONAM: '호남택배',
  HANIPS: '한의사랑',
  IPARCEL: 'i_Parcel',
  SLX: 'SLX택배',
  USPS: 'USPS',
  WONDERS: '원더스퀵',
  REGISTPOST: '우편등기',
  DHLDE: 'DHL(독일)',
  EZUSA: 'EZUSA',
  SWGEXP: '성원글로벌',
  DAEWOON: '대운글로벌',
  DADREAM: '다드림',
  CVSNET: 'GSPostbox택배',
  ETC: '기타',
  INVOICE_NUMBER: '반품 송장번호 입력',
  SELLERINFORMATION: '판매자정보',
  PARTNERNAME: '파트너사',
  CONTACT: '연락처',
  CHULGOJIADDRESS: '출고지주소',
  CONSIGNMENT: '위탁배송',
  RETURNSORREPLACEMENTS: '반품/교환지주소',
  FORWARDING2: '최초출고정보',
  RECEIVER: '수령자명',
  SHIPPINGADDRESS: '배송지주소',
  RETURNCOLLINFO: '반품수거정보',
  RETURNNAME: '반품자명',
  CELLPHONE: '휴대폰번호',
  LANDLINE: '전화번호',
  SUGEOJIJUSO: '수거지주소',
  CHANGEPOSTALCODE: '우편번호변경',
  REFERENCEPOINTS: '수거시참고사항',
  AMOUNTDETAILS: '반품상품 금액상세',
  ITEMAMOUNT: '반품상품금액',
  TOTALAMOUNT: '반품금액합계 ⓐ',
  RETURNMERCHANDISE: '반품상품 배송비상세 및 조정',
  SHIPPINGCOST: '반품배송비',
  DEFAULTSHIPPINGCOST: '기본배송비',
  ADDITIONALREGIONAL: '지역별추가',
  ADJUSTMENTOPTIONAL: '금액조정(선택)',
  ADJUSTMENT: '조정사유',
  ONCHANGERETURNWAYTYPE:
    "구매자 직접반품의 경우 반품배송비를 구매자가 부담하기에 판매자 귀책이 반영되지 않습니다. 판매자 귀책 적용이 필요하다면 '판매자 수거요청'으로 재설정해 주세요.",
  DELIVERYTOOLTIP3: '나눔배송된 경우 원배송기준으로 재계산됩니다.',
  CLOSECONFIRM: '반품신청을 종료하시겠습니까??',
  ALERT1: '구매자직접반품을 선택하여 반품배송비가 0원으로 변경되었습니다. 최종 확인 후 신청 접수해 주세요.',
  ALERT2: '반품상품을 하나 이상 선택해주세요.',
  ALERT3: '요청금액이 최초 실 결제금액을 초과하였습니다. 환불방법을 현금환불로 변경하여 신청해 주세요.',
  ALERT4: '환불은행을 선택해 주세요.',
  ALERT5: '환불계좌번호를 입력해 주세요.',
  ALERT6: '예금주를 입력해 주세요',
  ALERT7: '조정사유를 입력해주세요.',
  ALERT8: '반품자명을 입력해 주세요',
  ALERT9: '반품수거정보 휴대전화를 입력해 주세요.',
  ALERT10: '반품수거정보 수거지주소을 입력해 주세요.',
  ALTER11: '정확한 휴대폰번호를 입력해 주세요.',
  CONFIRM: '반품신청하시겠습니까?',
  COL9: '반품구분/사유',
  TOTALDELIVERYCOST: '배송비합계 ⓑ',
  WHODOESPAY: '※ 쇼핑몰 부담으로 조정됩니다.',
  ALERT11: '금액조정은 기본배송비와 지역별 추가배송비의 합을 초과할 수 없습니다.',
  ALERT12: '반품자명을 입력해 주세요.',
  RETURNSINFORMATION: '반품정보',
  CONFIRM2: '환불방법을 PG사 환불로 변경 시 귀책 대상 및 금액조정이 불가합니다. 변경하시겠습니까?',
  RETURNRESULT: '반품처리내역',
  DESC5: '간편결제 또는 PG결제건은 반품금액만큼 부분취소 처리됩니다.',
  CLAIMTEXT2:
    '단, 일부 신용카드 또는 휴대폰결제 등 경우에 따라 부분취소가 안될 경우 환불방법을 ‘현금환불’로 변경하여 현금으로 환불처리 해주세요.',
  DESC7: '무통장입금/가상계좌 입금건은 반드시 환불절차를 완료해주세요.',
  DESC4: '반품 후 수거된 상품의 재고는 [재고복원] 신청한 경우에만 환불완료 이후 자동으로 복원처리 됩니다. ',
  DESC8:
    '네이버페이 주문형 주문의 NCP 화면 내 금액은 예상 금액이며, 네이버페이의 조정 및 정책에 따라 변경될 수 있습니다. ',
  DESC9: '네이버페이 주문형 주문의 최종 환불 금액은 네이버페이 판매자센터를 통해 확인해 주세요. ',
  CLAIMNO: '클레임번호',
  APPLYRETURN: '반품신청',
  APPROVAL: '반품승인',
  APPROVALWITHDRAWRETURN: '반품반려신청 승인',
  ALERT13:
    '변경사항이 저장되었습니다. 이후 클레임 단계를 진행해 주세요. \n환불금액 조정이 필요한 경우, 입력 후 환불 처리해 주세요.',
  ALERT14: '변경사항이 저장되었습니다. 이후 클레임 단계를 진행해 주세요.',
  MODIRECIPIENTINFO: '반품수거정보 수정',
  NECESSARY: '필수',
  SHIPPINGADDRESS1: '배송 주소',
  FINDZIPCODE: '우편번호 찾기',
  SHIPPINGMESSAGE: '배송메시지',
  CANCEL: '취소',
  ALERT15: '변경된 사항이 없습니다.',
  ALERT16: '수정되었습니다.',
  RETURN: '반품',
  REFUND_EXPECTATION_CLAIM_AMT: '환불예상금액',
  REFUND_AMOUNT_EXPECTATION_A_MINUS_B_PLUS_C: '환불예상금액 ⓐ-ⓑ+ⓒ',
  ASK_RETURN_WITHDRAW_APPROVE: '반품반려신청 승인하시겠습니까?',
});
