















import { Component, Mixins, Model, Prop, Ref } from 'vue-property-decorator';
import FileInputMixin from '@/components/common/input/fileInput/FileInputMixin.vue';
import { FileNameRenderType } from '@/types';
@Component
export default class FileInput extends Mixins(FileInputMixin) {
  @Prop({ default: 'input' })
  private readonly fileNameRenderType!: FileNameRenderType;
  @Prop({ default: 'FIND_FILE' })
  private readonly findFileButtonName!: string;
  @Prop({ default: false })
  private readonly buttonStyle!: boolean;
  @Prop({ required: false, default: 'width:180px;' })
  private readonly inputStyle: string;

  @Model('change') value!: File;

  @Ref()
  private readonly fileInput!: HTMLInputElement;

  public focus(): void {
    this.fileInput.focus();
  }

  public clearFile() {
    this.fileInput.value = '';
  }

  public resetFileName() {
    this.removeFile();
  }
}
