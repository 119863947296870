




























































































































































































import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { ComponentItemsClaimDataType, ClaimableOption } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import { getOrderStatusTypeName } from '@/components/popup/claim/ClaimApplyPopup/ClaimApplyPopup';
import ProductDetails from '@/components/popup/claim/ClaimApplyPopup/ProductDetails.vue';
import DiscountShippingFee from '@/components/popup/claim/ClaimApplyPopup/DiscountShippingFee.vue';
import RefundExchangeAmount from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeAmount.vue';
import BenefitsPaid from '@/components/popup/claim/ClaimApplyPopup/BenefitsPaid.vue';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { unescapeHtml, uniqBy } from '@/utils/common';
import { ReturnOrderOptions } from 'ncp-api-supporter';
import { ReasonType } from 'ncp-api-supporter/dist/types/enum';
import TdClaimProductName from '@/components/popup/claim/TdClaimProductName.vue';

interface TotalProductPriceOption {
  totalProductPrice?: number;
  totalProductPriceOrderCnt?: number;
}

@Component({
  components: { TdClaimProductName, SelectBox },
})
export default class OrderProducts extends Vue {
  @Prop({ required: true }) private claimType!: string;
  @Prop({ default: false }) private checkboxShow!: boolean;
  @Prop({ default: false }) private escrowOrder!: boolean;
  @Prop({ required: false }) private postPredict!: {};
  @Prop({ required: true }) private orderOptionsApply!: ComponentItemsClaimDataType; //GetClaimsOrderOptionsOrderOptionNoApplyResponse;
  @Prop({ default: '' }) private refundType!: string;
  @Prop({ default: false }) private modifyClaim!: boolean;
  @PropSync('returnOrderOptions')
  private returnOrderOptionsSync!: ReturnOrderOptions[];
  @PropSync('sellerPaysClaimedDelivery') private sellerPaysClaimedDeliverySync: boolean;

  // 선택된 옵션번호 관리 { [옵션번호(string)]: 선택여부(boolean) }
  private selectedOptionMap = new Map();

  private unescapeHtml = unescapeHtml;
  private refOrderProducts;
  private refProductDetails;
  private refDiscountShippingFee;
  private refRefundExchangeAmount;
  private refBenefitsPaid;
  private claimableOptions: (ClaimableOption & TotalProductPriceOption)[] = [];
  private selectAll = false;
  private disabledAllCheck = false;
  private disabledCheckbox = false;
  public claimReasonDetail: string[] = [];
  private claimReasonTypeDisabled: boolean[] = [];
  public orderCntMap: Map<number, {}> = new Map();
  public optionNoMap: Map<number, {}> = new Map();
  public claimReasonTypeMap: Map<number, {}> = new Map();
  public claimReasonDetailMap: Map<number, {}> = new Map();
  public allOptionNo: number[] = [];
  public optionNo: number[] = [];
  private selectDisable: boolean[] = [];
  public orderCntArray: number[] = [];
  public claimReasonType: string[] = [];
  private orderOptionParams: ReturnOrderOptions[] = [];
  public responsibleObjectTypeValue = 'BUYER';
  public responsibleObjectTypeDisabled = false;

  mounted() {
    this.claimType === 'Cancel' || this.claimType === 'CancelAll'
      ? this.$emit('setData', 'postPredict')
      : this.$emit('setData');
  }

  private get sellerFaultReasonsList() {
    const list = [
      {
        value: 'BUYER',
        name: this.$t('CLAIM.CANCELPOPUP.BUYER'),
      },
      {
        value: 'SELLER',
        name: this.$t('CLAIM.CANCELPOPUP.SELLER'),
      },
    ];
    return list;
  }

  private get claimReasonTypeList() {
    const list = [
      {
        value: 'CHANGE_MIND',
        name: this.$t('CLAIM.CANCELPOPUP.CHANGE_MIND'),
      },
      {
        value: 'DEFECTIVE_PRODUCT',
        name: this.$t('CLAIM.CANCELPOPUP.DEFECTIVE_PRODUCT'),
      },
      {
        value: 'WRONG_DELIVERY',
        name: this.$t('CLAIM.CANCELPOPUP.WRONG_DELIVERY'),
      },
      {
        value: 'WRONG_PRODUCT_DETAIL',
        name: this.$t('CLAIM.CANCELPOPUP.WRONG_PRODUCT_DETAIL'),
      },
      {
        value: 'DELAY_DELIVERY',
        name: this.$t('CLAIM.CANCELPOPUP.DELAY_DELIVERY'),
      },
      {
        value: 'OUT_OF_STOCK',
        name: this.$t('CLAIM.CANCELPOPUP.OUT_OF_STOCK'),
      },
      {
        value: 'OTHERS_SELLER',
        name: this.$t('CLAIM.CANCELPOPUP.OTHERS_SELLER'),
      },
      {
        value: 'OTHERS_BUYER',
        name: this.$t('CLAIM.CANCELPOPUP.OTHERS_BUYER'),
      },
    ];

    //네이버페이 주문인 경우: 단순변심(색상,사이즈), 상품불량, 파손, 배송누락/오배송, 상품상세 정보와 다름, 판매자 배송 지연, 상품 품절/재고 없음
    const naverPayClaimReasonList = [
      {
        value: 'CHANGE_MIND',
        name: this.$t('CLAIM.CANCELPOPUP.CHANGE_MIND'),
      },
      {
        value: 'DEFECTIVE_PRODUCT',
        name: this.$t('CLAIM.CANCELPOPUP.DEFECTIVE_PRODUCT'),
      },
      {
        value: 'WRONG_DELIVERY',
        name: this.$t('CLAIM.CANCELPOPUP.WRONG_DELIVERY'),
      },
      {
        value: 'WRONG_PRODUCT_DETAIL',
        name: this.$t('CLAIM.CANCELPOPUP.WRONG_PRODUCT_DETAIL'),
      },
      {
        value: 'DELAY_DELIVERY',
        name: this.$t('CLAIM.CANCELPOPUP.DELAY_DELIVERY'),
      },
      {
        value: 'OUT_OF_STOCK',
        name: this.$t('CLAIM.CANCELPOPUP.OUT_OF_STOCK'),
      },
    ];

    return this.isNaverPay() ? naverPayClaimReasonList : list;
  }

  private responsibleObjectTypeText = '';
  private rowspanNum = 0;
  private rowSpanPartnerNoMap: Map<number, { isRowSpan: boolean; rowSpans: number }> = new Map();
  private rowSpanMallProductNoMap: Map<number, { isRowSpan: boolean; rowSpans: number }> = new Map();

  @Watch('refundType')
  onRefundTypeChanged() {
    if (this.escrowOrder) {
      this.disabledAllCheck = true;
      this.selectAll = true;
      this.disabledCheckbox = true;

      if (this.refundType === 'KCP') {
        this.claimReasonTypeDisabled[0] = true;
      } else if (this.refundType === 'CASH') {
        this.claimReasonTypeDisabled[0] = false;
      } else {
        this.claimReasonTypeDisabled[0] = false;
      }
    }
  }

  private showOrderStatusTypeName(code: string) {
    return getOrderStatusTypeName(code);
  }

  private getType() {
    if (this.claimType === 'Cancel' || this.claimType === 'CancelAll' || this.claimType === 'ExchangePopupBefore') {
      return this.$t('CLAIM.EXCHANGEPOPUP.CANCEL');
    }

    if (this.claimType === 'Return' || this.claimType === 'ExchangePopupAfter') {
      return this.$t('CLAIM.EXCHANGEPOPUP.RETURN');
    }
  }

  private setOrderClaimCnt() {
    for (let i = 0; i < this.optionNo.length; i++) {
      const claimCnt = this.orderCntMap.get(this.optionNo[i]) || 0;
      this.orderOptionParams[i] = {
        claimCnt: Number(claimCnt),
        orderOptionNo: this.optionNo[i],
        reasonType: this.claimReasonType[i] as ReasonType,
        reasonDetail: this.claimReasonDetail[i],
      };
    }
  }

  private orderCntChange(orderOptionNo: number, index: number, orderCnt: number) {
    if (this.orderCntArray[index] < 1) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_CNT_MINIMUM_MESSAGE', { type: this.getType() }));
      this.orderCntArray[index] = 1;
      this.$refs.claimCntInputRef[0].value = 1; // v-model이 배열로 되어있어 반영이 안됨 그래서 ref로 직접변경
    }

    if (this.orderCntArray[index] > orderCnt) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_CNT_MAXIMUM_MESSAGE', { type: this.getType(), num: orderCnt }));
      this.orderCntArray[index] = orderCnt;
      this.$refs.claimCntInputRef[0].value = orderCnt;
    }

    this.orderCntMap.set(orderOptionNo, this.orderCntArray[index]);
    this.optionNoMap.set(orderOptionNo, this.allOptionNo[index]);
    this.claimReasonTypeMap.set(orderOptionNo, this.claimReasonType[index]);
    this.claimReasonDetailMap.set(orderOptionNo, this.claimReasonDetail[index]);
    this.setOrderClaimCnt();

    this.claimableOptions.forEach((item, index: number) => {
      item.totalProductPrice = item.salePrice + item.addPrice - item.immediateDiscountAmt;
      this.claimableOptions[index].totalProductPrice = item.totalProductPrice;
      this.claimableOptions[index].totalProductPriceOrderCnt = item.totalProductPrice * this.orderCntArray[index];
      this.$forceUpdate();
    });

    if (this.claimType === 'Cancel' || this.claimType === 'CancelAll') {
      this.$emit('setData', 'postPredict');
    } else {
      this.$emit('setData');
    }
  }

  private claimReasonTypeChange(orderOptionNo: number, index: number, isPartnerProduct: boolean) {
    this.$emit('setIsPartnerProduct', isPartnerProduct);
    this.$nextTick(() => {
      this.orderCntMap.set(orderOptionNo, this.orderCntArray[index]);
      this.optionNoMap.set(orderOptionNo, this.allOptionNo[index]);
      this.claimReasonTypeMap.set(orderOptionNo, this.claimReasonType[index]);
      this.claimReasonDetailMap.set(orderOptionNo, this.claimReasonDetail[index]);
      this.setOrderClaimCnt();
      this.sellerPaysClaimedDeliverySync = this.responsibleObjectTypeValue === 'SELLER';

      if (this.claimType === 'Cancel') {
        this.$emit('setData', 'postPredict');
      } else {
        this.$emit('setData', false);
      }
    });
  }

  private claimReasonChange(orderOptionNo: number, index: number) {
    this.orderCntMap.set(orderOptionNo, this.orderCntArray[index]);
    this.optionNoMap.set(orderOptionNo, this.allOptionNo[index]);
    this.claimReasonTypeMap.set(orderOptionNo, this.claimReasonType[index]);
    this.claimReasonDetailMap.set(orderOptionNo, this.claimReasonDetail[index]);
  }

  private get checkAll(): boolean {
    const selectedOptionSize = [...this.selectedOptionMap.values()].filter(Boolean).length;

    return this.claimableOptions.length === selectedOptionSize;
  }
  private set checkAll(checked: boolean) {
    let selected = [];
    if (checked) {
      selected = this.claimableOptions.map(claimableOption => claimableOption.orderOptionNo);
    }

    this.claimableOptions.forEach(({ orderOptionNo }) => {
      this.selectedOptionMap.set(`${orderOptionNo}`, checked);
    });

    this.optionNo = [...selected];
    if (this.optionNo.length === 0) {
      for (let i = 0; i < this.claimableOptions.length; i++) {
        this.selectDisable[i] = true;
        this.claimReasonTypeDisabled[i] = true;
        this.claimableOptions[i].checkboxValue = false;
      }
      this.$emit('defaultPredict');
    } else {
      this.orderOptionParams = this.optionNo.map((optionNo, index) => {
        this.selectDisable[index] = false;
        this.claimReasonTypeDisabled[index] = false;
        this.claimableOptions[index].checkboxValue = true;
        const claimCnt = this.orderCntMap.get(this.optionNo[index]) || 0;
        return {
          claimCnt: Number(claimCnt),
          orderOptionNo: this.optionNo[index],
          reasonType: this.claimReasonType[index] as ReasonType,
          reasonDetail: this.claimReasonDetail[index],
        };
      });
      if (checked) {
        if (this.claimType === 'Cancel' || this.claimType === 'CancelAll') {
          this.$emit('setData', 'postPredict');
        } else {
          this.$emit('setData');
        }
      }
    }
  }

  private onChangeCheckBoxes(e: Event, i: number, orderProductOptionNo: number) {
    this.selectedOptionMap.set(`${orderProductOptionNo}`, (e.target as HTMLInputElement).checked);

    const orderOptionParams: ReturnOrderOptions[] = [];
    this.orderCntMap.set(orderProductOptionNo, this.orderCntArray[i]);
    this.optionNoMap.set(orderProductOptionNo, this.allOptionNo[i]);
    this.claimReasonTypeMap.set(orderProductOptionNo, this.claimReasonType[i]);
    this.claimReasonDetailMap.set(orderProductOptionNo, this.claimReasonDetail[i]);
    if (this.optionNo.length === 0) {
      this.selectDisable[i] = this.claimReasonTypeDisabled[i] = this.optionNo.indexOf(orderProductOptionNo) < 0;
      this.$emit('defaultPredict');
    } else {
      this.setOrderClaimCnt();
      this.selectDisable[i] = this.claimReasonTypeDisabled[i] = this.optionNo.indexOf(orderProductOptionNo) < 0;
      this.orderOptionParams = orderOptionParams;

      if (this.claimType === 'Cancel' || this.claimType === 'CancelAll') {
        this.$emit('setData', 'postPredict');
      } else {
        this.$emit('setData');
      }
    }
  }

  private onClickModifyClaim() {
    this.$emit('onClickModifyClaim');
  }

  private defaultClaimableOptions = null;
  private isChangedOrderCnt = false;
  private setDefaultClaimableOptions(defaultClaimableOptions) {
    if (this.isChangedOrderCnt) return;
    this.defaultClaimableOptions = defaultClaimableOptions.map(d => {
      const { salePrice, addPrice, immediateDiscountAmt, orderCnt } = d;
      const totalProductPrice = salePrice + addPrice - immediateDiscountAmt;
      return {
        ...d,
        totalProductPrice,
        totalProductPriceOrderCnt: totalProductPrice * orderCnt,
      };
    });
  }
  private newClaimableOptions() {
    return uniqBy(this.orderOptionsApply.claimableOptions, 'orderOptionNo').map((option, idx) => ({
      ...option,
      orderCnt: this.isChangedOrderCnt ? this.orderCntArray[idx] : option.orderCnt,
    }));
  }

  private addSelectedOptionNo(optionNo: number) {
    !this.optionNo.includes(optionNo) && this.optionNo.push(optionNo);
  }

  public claimCalculation(escrowDisable = true) {
    if (escrowDisable) {
      const newClaimableOptions = this.newClaimableOptions();
      this.setDefaultClaimableOptions(newClaimableOptions);
      this.claimableOptions = newClaimableOptions;
    }
    let partnerNo = -1;
    let mallProductNo: number | null = null;

    this.claimableOptions.forEach((item, index: number) => {
      if (this.selectedOptionMap.size > 0) {
        item.checkboxValue = !!this.selectedOptionMap.get(`${item.orderOptionNo}`);
      }

      item.totalProductPrice = item.salePrice + item.addPrice - item.immediateDiscountAmt;
      this.claimableOptions[index].totalProductPrice = item.totalProductPrice;
      this.claimableOptions[index].totalProductPriceOrderCnt = item.totalProductPrice * item.orderCnt;
      !this.allOptionNo.includes(item.orderOptionNo) && this.allOptionNo.push(item.orderOptionNo);

      switch (this.claimType) {
        case 'CancelAll':
          this.addSelectedOptionNo(item.orderOptionNo);
          this.orderOptionParams.push({
            claimCnt: item.claimedCnt,
            orderOptionNo: item.orderOptionNo,
          });
          if (index > 0) {
            this.rowspanNum++;
          }
          this.selectDisable[index] = true;
          this.claimReasonType[index] = 'CHANGE_MIND';
          if (partnerNo == item.partnerNo) {
            this.rowSpanPartnerNoMap.set(index, { isRowSpan: false, rowSpans: 1 });
          } else {
            this.rowSpanPartnerNoMap.set(index, {
              isRowSpan: true,
              rowSpans: this.claimableOptions.filter(e => {
                return e.partnerNo == item.partnerNo;
              }).length,
            });
          }
          if (mallProductNo == item.mallProductNo) {
            this.rowSpanMallProductNoMap.set(index, { isRowSpan: false, rowSpans: 1 });
          } else {
            this.rowSpanMallProductNoMap.set(index, {
              isRowSpan: true,
              rowSpans: this.claimableOptions.filter(e => {
                return e.mallProductNo == item.mallProductNo;
              }).length,
            });
          }
          partnerNo = item.partnerNo;
          mallProductNo = item.mallProductNo;
          this.orderCntMap.set(item.orderOptionNo, item.orderCnt);
          this.orderCntArray[index] = item.orderCnt;
          this.responsibleObjectTypeText = this.$t('CLAIM.CANCELPOPUP.BUYER').toString();

          break;

        case 'Cancel':
          if (item.checkboxValue) {
            this.addSelectedOptionNo(item.orderOptionNo);
            this.selectDisable[index] = this.claimReasonTypeDisabled[index] = false;
            this.orderOptionParams.push({
              claimCnt: item.claimedCnt,
              orderOptionNo: item.orderOptionNo,
              reasonType: 'CHANGE_MIND',
              reasonDetail: null,
            });
          } else {
            this.selectDisable[index] = this.claimReasonTypeDisabled[index] = true;
            this.rowspanNum++;
          }
          this.claimReasonType[index] = 'CHANGE_MIND';
          this.rowSpanPartnerNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          this.rowSpanMallProductNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          partnerNo = item.partnerNo;
          mallProductNo = item.mallProductNo;
          this.orderCntMap.set(item.orderOptionNo, item.orderCnt);
          this.orderCntArray[index] = item.orderCnt;
          this.responsibleObjectTypeText = this.$t('CLAIM.CANCELPOPUP.BUYER').toString();
          break;

        case 'Return':
          if (this.escrowOrder) {
            item.checkboxValue = true;
            this.rowspanNum++;
          }
          if (item.checkboxValue) {
            this.addSelectedOptionNo(item.orderOptionNo);
            this.selectDisable[index] = this.claimReasonTypeDisabled[index] = false;
            this.orderOptionParams.push({
              claimCnt: item.claimedCnt,
              orderOptionNo: item.orderOptionNo,
              reasonType: 'CHANGE_MIND',
              reasonDetail: null,
            });
          } else {
            this.selectDisable[index] = true;
            this.rowspanNum++;
          }
          this.claimReasonType[index] = 'CHANGE_MIND';
          this.rowSpanPartnerNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          this.rowSpanMallProductNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          partnerNo = item.partnerNo;
          mallProductNo = item.mallProductNo;
          this.orderCntMap.set(item.orderOptionNo, item.orderCnt);
          this.orderCntArray[index] = item.orderCnt;
          this.responsibleObjectTypeText = this.$t('CLAIM.CANCELPOPUP.BUYER').toString();
          if (this.escrowOrder && escrowDisable) {
            this.selectDisable[index] = true;
            this.disabledAllCheck = true;
            this.selectAll = true;
            this.disabledCheckbox = true;
          }
          break;

        case 'ExchangePopupBefore':
          this.addSelectedOptionNo(item.orderOptionNo);
          this.orderOptionParams.push({
            claimCnt: item.claimedCnt,
            orderOptionNo: item.orderOptionNo,
            reasonType: 'CHANGE_MIND',
            reasonDetail: null,
          });
          if (index > 0) {
            this.rowspanNum++;
          }
          this.rowSpanPartnerNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          this.rowSpanMallProductNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          partnerNo = item.partnerNo;
          mallProductNo = item.mallProductNo;
          this.orderCntMap.set(item.orderOptionNo, item.orderCnt);
          this.orderCntArray[index] = item.orderCnt;
          this.claimReasonType[index] = 'CHANGE_MIND';
          this.responsibleObjectTypeText = this.$t('CLAIM.CANCELPOPUP.BUYER').toString();
          break;

        case 'ExchangePopupAfter':
          this.rowSpanPartnerNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          this.rowSpanMallProductNoMap.set(index, { isRowSpan: true, rowSpans: 1 });
          partnerNo = item.partnerNo;
          mallProductNo = item.mallProductNo;
          this.orderCntMap.set(item.orderOptionNo, item.orderCnt);
          this.orderCntArray[index] = item.orderCnt;
          this.claimReasonType[index] = 'CHANGE_MIND';
          this.responsibleObjectTypeText = this.$t('CLAIM.CANCELPOPUP.BUYER').toString();
          break;
      }
      if (this.escrowOrder && escrowDisable) {
        this.claimReasonType[index] = 'OTHERS_SELLER';
        this.responsibleObjectTypeValue = 'SELLER';
        this.responsibleObjectTypeText = this.$t('CLAIM.CANCELPOPUP.SELLER').toString();
        this.responsibleObjectTypeDisabled = true;
        this.claimReasonTypeDisabled[index] = true;
      }
    });
  }

  private changeOrderCnt(type, index, item) {
    this.isChangedOrderCnt = true;
    const claimCntInputRef = Number(this.$refs.claimCntInputRef[index].value);
    if (type === 'minus') {
      this.$refs.claimCntInputRef[index].value = claimCntInputRef - 1;
      this.orderCntArray[index] = claimCntInputRef - 1;
    } else {
      this.$refs.claimCntInputRef[index].value = claimCntInputRef + 1;
      this.orderCntArray[index] = claimCntInputRef + 1;
    }

    if (this.$refs.claimCntInputRef[index].value < 1) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_CNT_MINIMUM_MESSAGE', { type: this.getType() }));
      this.orderCntArray[index] = 1;
      this.$refs.claimCntInputRef[index].value = 1;
    }

    if (this.defaultClaimableOptions[index].orderCnt < this.$refs.claimCntInputRef[index].value) {
      alert(
        this.$t('CLAIM.EXCHANGEPOPUP.CLAIM_CNT_MAXIMUM_MESSAGE', {
          type: this.getType(),
          num: this.defaultClaimableOptions[index].orderCnt,
        }),
      );
      this.orderCntArray[index] = this.defaultClaimableOptions[index].orderCnt;
      this.$refs.claimCntInputRef[0].value = this.defaultClaimableOptions[index].orderCnt;
    }
    this.orderCntMap.set(item.orderOptionNo, this.orderCntArray[index]);
    this.setOrderClaimCnt();

    this.claimableOptions.forEach((item, index: number) => {
      item.totalProductPrice = item.salePrice + item.addPrice - item.immediateDiscountAmt;
      this.claimableOptions[index].totalProductPrice = item.totalProductPrice;
      this.claimableOptions[index].totalProductPriceOrderCnt = item.totalProductPrice * this.orderCntArray[index];
      this.$forceUpdate(); // 배열에 데이터를 담아 쓰는 형태라 업데이트 되는 타이밍이 한박자 느림. cnt가 바뀔때마다 forceUpdate
    });

    this.$emit(
      'setReturnOrderOptions',
      this.orderOptionParams.filter(({ reasonType }) => reasonType),
    );
    if (this.claimType === 'Cancel') {
      this.$emit('setData', 'postPredict');
    } else {
      this.$emit('setData');
    }
  }

  private setRefComponent() {
    this.refOrderProducts = this.$parent.$refs.componentItemsClaim as OrderProducts;
    this.refProductDetails = this.$parent.$refs.componentProductDetails as ProductDetails;
    this.refDiscountShippingFee = this.$parent.$refs.componentDiscountShippingFee as DiscountShippingFee;
    this.refRefundExchangeAmount = this.$parent.$refs.componentRefundExchangeAmount as RefundExchangeAmount;
    this.refBenefitsPaid = this.$parent.$refs.componentBenefitsPaid as BenefitsPaid;
    // this.refReturnShippingFee = this.$parent.$refs.componentReturnShippingFee as ReturnShippingFee;
  }

  private onClickProductNo(productNo: number) {
    openEditProductWindow(productNo);
  }

  private isNaverPay() {
    return this.orderOptionsApply.payType === 'NAVER_PAY';
  }

  private created() {
    this.initializeSelectedOptionNo();
  }

  private initializeSelectedOptionNo() {
    // 상위 컴포넌트에서 단일 "취소" 선택 시 선택한 옵션 번호가 check 된 상태로 나올 수 있게 초기 세팅
    const orderProductOptionNo = this.$route?.query?.orderProductOptionNo;
    if (Number(orderProductOptionNo) > 0) {
      this.selectedOptionMap.set(orderProductOptionNo, true);
    }
  }
}
