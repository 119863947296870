













































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { throwWindowPopup } from '@/helpers/popup';
import { Receiver } from 'ncp-api-supporter';
import { replaceNextLineContent } from '@/utils/common';

@Component({
  components: { TextInput },
})
export default class PreviousOrderReceiveInfo extends Vue {
  @Prop({ default: () => [] })
  private receivers: Receiver[];
  @Prop({ default: 0 })
  private mallNo: number;

  private replaceNextLineContent = replaceNextLineContent;
  private showReceiverInfos: boolean[] = this.receivers.map(() => true);

  private deliveryCompany(deliveryCompanyType: string) {
    return deliveryCompanyType ? this.$t(`CLAIM.RETURNPOPUP.${deliveryCompanyType}`) : '';
  }

  public handleReceiverSms(receiverContact: string, mallNo: number) {
    // SMS 발송 팝업창
    throwWindowPopup('SmsSendManager', { phoneNos: [receiverContact] }, 'xlg', null, null, {
      mallNo,
    });
  }

  private handleToggleClick(index: number) {
    this.showReceiverInfos = { ...this.showReceiverInfos, [index]: !this.showReceiverInfos[index] };
  }
}
