
















































































import { PropType } from 'vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { GetOrdersByOrderNo } from 'ncp-api-supporter';
import { i18n } from '@/views/contents/board/basic/Questions';

@Component
export default class QuestionsOrderInfo extends Vue {
  @Prop({
    required: true,
    default: () => {
      return {};
    },
    type: Object as PropType<GetOrdersByOrderNo>,
  })
  private readonly data!: GetOrdersByOrderNo;

  private get dataLength(): boolean {
    if (!this.data || !Object.keys(this.data).length) {
      return false;
    }
    return true;
  }

  private format(num = 0): string {
    return num
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      .replace('.00', '');
  }

  private t(txt: string): string {
    return i18n.call(this, txt);
  }
}
