






























































import { Component, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { DomainByMall, PutSmsUnsubscribeConfigRequest } from 'ncp-api-supporter';
import { clearBottomNavigation, throwBottomNavigation } from '@/helpers/bottomNav';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import PostRequestButton from '@/components/addition/PostRequestButton.vue';
import { Getter, namespace } from 'vuex-class';
import Reject080StatusMixin, {
  ApplyInfoDescription,
  UnsubscribeStatus,
  UnsubscribeStatusType,
} from '@/views/contents/member/sms/mixins/Reject080StatusMixin.vue';
import { NavButton } from '@/types/bottomNav';
import { RadioBoxGroupOption } from '@/helpers/type';
import { getCurrentMallNo } from '@/utils/mall';
import moment from 'moment';

const adminStore = namespace('admin');

@Component({
  components: { ToolTip, RadioGroup, PostRequestButton },
})
export default class Reject080Config extends Reject080StatusMixin {
  @Getter('mall/getMallDomains') private malls!: DomainByMall[];
  @adminStore.Getter('getGodoSno')
  private readonly godoSno!: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  private post080Query = { mall_id: null };

  private buttonList: NavButton[] = [{ type: 'right', key: 'configSave', color: 'red', text: this.$t('SAVE') }];
  private applyInfoTitleDescription: ApplyInfoDescription<string> = {};
  private applyInfoContentDescription: ApplyInfoDescription<string[]> = {
    NONE: ['MEMBER.REJECT080.STATUS_NO_APPLY'],
    APPLICATION: ['MEMBER.REJECT080.STATUS_PREV_PAY'],
    PAID: ['MEMBER.REJECT080.STATUS_COMPLETE_PAY'],
    OPEN: ['MEMBER.REJECT080.STATUS_COMPLETE_ACTIVE_1', 'MEMBER.REJECT080.STATUS_COMPLETE_ACTIVE_2'],
    TERMINATION: ['MEMBER.REJECT080.STATUS_CANCEL'],
  };

  // 사용여부
  private usedOption: RadioBoxGroupOption<boolean> = {
    name: 'usedReject080',
    data: [
      { id: 'usedReject', value: true, display: 'MEMBER.SMS_AUTO.USED' },
      { id: 'notUsedReject', value: false, display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  };

  @Watch('unsubscribeStatus')
  private changeStatus() {
    if (this.unsubscribeStatus !== UnsubscribeStatus.OPEN) {
      clearBottomNavigation();
    } else {
      throwBottomNavigation({
        buttons: this.buttonList,
        onClick: this.configSave,
      });
    }
  }

  private checkStatus(state: UnsubscribeStatusType): boolean {
    return UnsubscribeStatus[state] === this.unsubscribeStatus;
  }

  private get isRed(): boolean {
    return this.checkStatus(UnsubscribeStatus.OPEN) || this.checkStatus(UnsubscribeStatus.TERMINATION);
  }

  private get applyInfoTitle(): string {
    return this.applyInfoTitleDescription[this.unsubscribeStatus];
  }

  private get applyInfoContents(): string[] {
    return this.applyInfoContentDescription[this.unsubscribeStatus];
  }

  private async configSave() {
    const request: PutSmsUnsubscribeConfigRequest = {
      params: {
        mallNo: getCurrentMallNo(this),
      },
      data: {
        used: this.unsubscribeUsed,
      },
    };

    try {
      await this.$api.putSmsUnsubscribeConfig(request);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private resetValue() {
    this.changeStatus();
    const [year, month, day] = moment(this.cancelDate).toArray();
    this.applyInfoTitleDescription = {
      APPLICATION: 'MEMBER.REJECT080.PREV_PAY_TITLE',
      PAID: 'MEMBER.REJECT080.COMPLETE_PAY_TITLE',
      OPEN: 'MEMBER.REJECT080.COMPLETE_ACTIVE_TITLE',
      TERMINATION: this.$t('MEMBER.REJECT080.CANCEL_TITLE', { year, month: month + 1, day }).toString(),
    };
  }

  //NOTE : 솔루션 내 수신거부 번호 연장 프로세스 추후 개선 건으로 인한 해당 버튼 미 노출 처리 필요 (주석처리)
  // private openExtensionPopup(): void {
  //   throwExternalWindowPopup('https://www.nhn-commerce.com/echost/power/add/convenience/sms-refusal-process.gd');
  // }

  async created() {
    await this.getUnsubscribeConfig();
    this.resetValue();
  }

  private apply() {
    const mallNo = this.$route.query.mallNo;
    const { mallId } = this.malls.find(mall => mall.mallNo === Number(mallNo)) ?? this.malls[0];
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.post080Query = { mall_id: mallId };
  }
}
