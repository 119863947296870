import { claimStatusTypes } from '@/const/claim.ts';

// 기획에 사라진 클레임상태 임시 주석처리
export default [
  { value: claimStatusTypes.CANCEL_NO_REFUND, name: 'CLAIM.CLAIM_STATUS_TYPE.CANCEL_NO_REFUND' },
  { value: claimStatusTypes.CANCEL_REQUEST, name: 'CLAIM.CLAIM_STATUS_TYPE.CANCEL_REQUEST' },
  { value: claimStatusTypes.CANCEL_PROC_REQUEST_REFUND, name: 'CLAIM.CLAIM_STATUS_TYPE.CANCEL_PROC_REQUEST_REFUND' },
  { value: claimStatusTypes.CANCEL_PROC_WAITING_REFUND, name: 'CLAIM.CLAIM_STATUS_TYPE.CANCEL_PROC_WAITING_REFUND' },
  { value: claimStatusTypes.CANCEL_DONE, name: 'CLAIM.CLAIM_STATUS_TYPE.CANCEL_DONE' },
  { value: claimStatusTypes.RETURN_REQUEST, name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_REQUEST' },
  { value: claimStatusTypes.RETURN_REJECT_REQUEST, name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_REJECT_REQUEST' },
  { value: claimStatusTypes.RETURN_PROC_BEFORE_RECEIVE, name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_PROC_BEFORE_RECEIVE' },
  { value: claimStatusTypes.RETURN_PROC_REQUEST_REFUND, name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_PROC_REQUEST_REFUND' },
  {
    value: claimStatusTypes.RETURN_REFUND_AMT_ADJUST_REQUESTED,
    name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_REFUND_AMT_ADJUST_REQUESTED',
  },
  { value: claimStatusTypes.RETURN_PROC_WAITING_REFUND, name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_PROC_WAITING_REFUND' },
  { value: claimStatusTypes.RETURN_DONE, name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_DONE' },
  {
    value: claimStatusTypes.RETURN_NO_REFUND,
    name: 'CLAIM.CLAIM_STATUS_TYPE.RETURN_NO_REFUND',
  },
  { value: claimStatusTypes.EXCHANGE_REQUEST, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_REQUEST' },
  { value: claimStatusTypes.EXCHANGE_REJECT_REQUEST, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_REJECT_REQUEST' },
  {
    value: claimStatusTypes.EXCHANGE_PROC_BEFORE_RECEIVE,
    name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_PROC_BEFORE_RECEIVE',
  },
  { value: claimStatusTypes.EXCHANGE_PROC_WAITING, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_PROC_WAITING' },
  { value: claimStatusTypes.EXCHANGE_PROC_REQUEST_PAY, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_PROC_REQUEST_PAY' },
  {
    value: claimStatusTypes.EXCHANGE_PROC_REQUEST_REFUND,
    name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_PROC_REQUEST_REFUND',
  },
  // { value: claimStatusTypes.EXCHANGE_PROC_WAITING_PAY, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_PROC_WAITING_PAY' },
  { value: claimStatusTypes.EXCHANGE_DONE_PAY_DONE, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_DONE_PAY_DONE' },
  // {
  //   value: claimStatusTypes.EXCHANGE_PROC_WAITING_REFUND,
  //   name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_PROC_WAITING_REFUND',
  // },
  { value: claimStatusTypes.EXCHANGE_DONE_REFUND_DONE, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_DONE_REFUND_DONE' },
  { value: claimStatusTypes.EXCHANGE_DONE, name: 'CLAIM.CLAIM_STATUS_TYPE.EXCHANGE_DONE' },
];
