import { api } from '@/api';
import { PostExcelsRequest, ProExcelCreateMenu } from 'ncp-api-supporter';
import { i18n } from '@/main';
import { throwWindowPopup } from '@/helpers/popup';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';

/**
 * BE 에서 qs stringify 포멧이 아닌 아래와 같은 포멧으로 넘겨달라 하여 따로 추가한 쿼리스트링 생성하는 재귀함수입니다.
 * https://nickdrane.com/build-your-own-nested-query-string-encoder/
 * 특수한 요구사항이라 jQuery.params, qs.stringify 등 보편적인 메서드로 대체하면 안됩니다.
 *
 * ex )
 * 배열의 경우
 * as-is : saleSettingStatus[types][0]=AVAILABLE_FOR_SALE&saleSettingStatus[types][1]=STOP_SELLING
 * to-be : saleSettingStatus.types=STOP_SELLING&saleSettingStatus.types=PROHIBITION_SALE
 *
 * 중첩 객체의 경우
 * as-is : periodInfo[period][startYmdt]=value
 * to-be : periodInfo.period.startYmdt
 *
 */

function encode(queryObj, nesting = '') {
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    // Handle a second base case where the value to encode is an array
    if (Array.isArray(val)) {
      return val.map(subVal => [nesting + key, subVal].join('=')).join('&');
    } else if (typeof val === 'object' && val !== null && val !== undefined) {
      return encode(val, nesting + `${key}.`);
    } else if (val !== null && val !== undefined) {
      return [nesting + key, val].join('=');
    }
  });

  return pairs.filter(Boolean).join('&');
}

export async function createExcel(
  hasData: boolean,
  menuKey: ProExcelCreateMenu,
  createExcelFileQuery: { [key: string]: any },
  extraInfo?: string,
): Promise<any> {
  if (!menuKey || !createExcelFileQuery) return alert(i18n.t('ALERT_WRONG_ACCESS'));
  if (!hasData) return alert(i18n.t('NOT_DATA'));

  if (!confirm(i18n.t('EXCEL.CONFIRM_CREATE').toString())) return;

  const dateTimes = createExcelFileQuery.startDateTime?.split(' ');
  if (dateTimes?.length === 1) {
    createExcelFileQuery.startDateTime = `${createExcelFileQuery.startDateTime} ${DEFAULT_TIME_RANGE.START}`;
    createExcelFileQuery.endDateTime = `${createExcelFileQuery.endDateTime} ${DEFAULT_TIME_RANGE.END}`;
  }

  const queryString = encode(createExcelFileQuery);
  const request: PostExcelsRequest = {
    data: {
      queryString: encodeURI(queryString),
      menu: menuKey,
      extraInfo: extraInfo || null,
    },
  };

  try {
    await api.postExcels(request);
    throwWindowPopup('ServerExcelDownload', null, 'xlg', () => null, 'excel_popup');
  } catch (error) {
    console.error(error);
  }
}
