





import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalIframe from '@/components/iframe/ModalIframe.vue';

@Component({ components: { ModalIframe } })

export default class OtpAuthSettingIframe extends Vue {
  @Prop() private onNegativeClick!: Function;

  private get remoteSrc() {
    const queryObject = { 
      serviceType: 'PRO' 
    };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + '/popup/otp-setting?' + queryString;

    return src;
  }
    private receiveMessage(e: MessageEvent) {
    if (e.data.closeOtpSetting || e.data.otpSettingComplete) {
      this.onNegativeClick()
      return;
    }
  }

  mounted() {
    window.addEventListener('message', this.receiveMessage);
  }

  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage);
  }

}
