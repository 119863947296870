















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { throwWindowPopup } from '@/helpers/popup';

@Component({
  components: {},
})
export default class OrderInfo extends Vue {
  @Prop({ default: () => [] }) public orderInfo;
  @Prop({ default: true })
  private showDetailPopup: boolean;

  public onClickFirstDeliveryAmtPopup(): void {
    throwWindowPopup('DeliveryFirstAmtDetail', { orderNo: this.$route.query.orderNo, orderType: 'first' }, 'sm');
  }
  public onClickFirstDiscountAmt(): void {
    throwWindowPopup(`OrderFirstDiscount`, { orderNo: this.$route.query.orderNo, orderType: 'first' }, 'md');
  }
}
