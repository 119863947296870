






import { Vue, Component } from 'vue-property-decorator';
import MallSelector from '@/components/MallSelector.vue';
@Component({
  components: {
    MallSelector,
  },
})
export default class AccountManage extends Vue {}
