export default Object.freeze({
  // CONTRACT_POPUP_
  TITLE: '입점 계약등록',
  CANCEL_TITLE: '입점 계약 취소',
  MODIFICATION_TITLE: '입점 계약내용 변경',

  MARK: '등록된 내용은 파트너사 동의 후, 적용됩니다.',
  NEW_MARK: '해당 파트너사에 체결할 ‘입점 계약‘ 내용을 입력/수정해주세요.',
  AGREED_MARK: '파트너사가 동의한 입점 계약내용입니다.',

  CANCEL_MARK1: '파트너사의 동의가 필요한 입점 계약 내용입니다.',
  CANCEL_MARK2: '취소 시 해당 입점 계약 내용은 등록/변경되지 않습니다.',
  CANCEL_MARK3:
    '이전 계약된 내용이 있다면 해당 내용이 유지되며, 이전 계약된 내용이 없다면 입점 계약서 미사용 상태로 유지됩니다.',

  MODIFICATION_MARK1: '입점 계약내용 변경 시, 변경 사유도 함께 입력해 주세요.',
  MODIFICATION_MARK2: '변경된 내용은 파트너사 동의 후, 적용됩니다.',

  AGREED_TH: '동의일',
  CANCEL_TH: '계약내용 변경일',
  CHANGE_REASON: '변경 사유',

  CONTENTS: '내용',

  MODIFICATION_CONFIRM_MSG: '입점 계약내용을 변경하시겠습니까?',
  MODIFICATION_NO_CHANGE_REASON_MSG: '변경 사유를 입력해주세요.',
  CHANGE_REASON_PLACEHOLDER: '입점 계약내용 변경 사유를 입력해주세요.',

  SUCCESS_MSG: '저장되었습니다.',
  CANCEL_SAVE: '등록 취소',

  CANCEL_BUTTON: '계약 취소',
  MODIFICATION_BUTTON: '변경',
  REGISTRATION_BUTTON: '등록',

  CANCEL_CONFIRM: '입점 계약 등록/변경을 취소하시겠습니까?',
  BLOCK_CANCEL_MSG:
    '파트너 동의가 완료되어 취소 불가합니다.\n계약 내용 수정이 필요한 경우 계약 내용 변경 및 재동의를 받아야 합니다.',
  ERR_CONTENT_NULL: '내용을 입력해 주세요.',
});
