const nodeElements = {
  internalNode: `
		<div class="tui-tree-content-wrapper" style="padding-left: {{indent}}px !important;">
			<button type="button" class="tui-tree-toggle-btn tui-js-tree-toggle-btn">-<span class="tui-ico-tree"></span></button>
			<span class="tui-tree-text tui-js-tree-text">
				<span class="tui-tree-ico tui-ico-file-line"></span>
				<span class="tui-tree-ico tui-ico-folder"></span>
				{{nodeName}}
			</span>
	  </div>
	  <ul class="tui-tree-subtree tui-js-tree-subtree">{{children}}</ul>
	`,
  leafNode: `
		<div class="tui-tree-content-wrapper" style="padding-left: {{indent}}px !important">
			<span class="tui-tree-text tui-js-tree-text">
				<span class="tui-tree-ico tui-ico-file-line"></span>
				<span class="tui-tree-ico tui-ico-file"></span>
				{{nodeName}}
			</span>
		</div>
	`,
};

export default nodeElements;
