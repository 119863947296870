











import { Vue, Component } from 'vue-property-decorator';
import MallSelector from '@/components/MallSelector.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { namespace } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
const mallModule = namespace('mall');
@Component({
  components: {
    MallSelector,
    NoticeBox,
  },
})
export default class IconList extends Vue {
  @mallModule.Getter('getMalls')
  private readonly malls!: Mall[];

  private noticeList = ['PRODUCT.ICON.GUIDE1', 'PRODUCT.ICON.GUIDE2', 'PRODUCT.ICON.GUIDE3'];
}
