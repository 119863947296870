export const PARTNER_KEYWORD_TYPE = {
  ALL: 'ALL',
  PARTNER_NAME: 'PARTNER_NAME',
  COMPANY_NAME: 'COMPANY_NAME',
  REGISTRATION_NO: 'REGISTRATION_NO',
} as const;

export const PARTNER_PERIOD_TYPE = {
  CREATED: 'CREATED',
  CONTRACT: 'CONTRACT',
} as const;

export const PARTNER_STATUS = {
  ALL: 'ALL',
  WAITING: 'WAITING',
  INVESTIGATION: 'INVESTIGATION',
  ACTIVE: 'ACTIVE',
} as const;

export const REGISTERED_PRODUCT = {
  ALL: 'ALL',
  REGISTERED: 'REGISTERED',
  NOT_REGISTERED: 'NOT_REGISTERED',
} as const;

export const PARTNER_NOTICE_SEARCH_TYPE = {
  ALL: 'ALL',
  TITLE: 'TITLE',
  CONTENT: 'CONTENT',
  WRITER: 'WRITER',
} as const;

export const PARTNER_NOTICE_POPUP_TYPE = {
  REGISTER: 'REGISTER',
  MOVE: 'MOVE',
  DELETE: 'DELETE',
} as const;

export const PARTNER_NOTICE_TARGET = 'SERVICE_TO_PARTNER' as const;

export const PARTNER_REGISTER_CONTRACT_TAB = {
  SEARCH: 'SEARCH',
  DETAIL: 'DETAIL',
} as const;
