


































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import TuiCalendar, { IEventWithoutCreationPopup, ISchedule, IEventScheduleObject } from 'tui-calendar';
import 'tui-calendar/dist/tui-calendar.css';

import { GetHolidaysYearRequest, GetHolidaysYear } from 'ncp-api-supporter';
import { changeDateFormat, getToday, getStrDate } from '@/utils/dateFormat';

@Component
export default class Calendar extends Vue {
  @PropSync('year', { default: 0 })
  private yearSync!: number;
  @PropSync('month', { default: 0 })
  private monthSync!: number;

  @Prop({ required: false, default: true })
  private readonly showHoliday: boolean;
  @Prop({ required: false, default: true })
  private readonly showHeader: boolean;
  @Prop({ required: false, default: true })
  private readonly showTitleToday: boolean;
  @Prop({ required: false, default: [] })
  private readonly schedules: ISchedule[];
  @Prop({ required: false })
  private readonly movedMonth: () => Promise<void>;
  @Prop({ required: false })
  private readonly clickSchedule: (event: IEventScheduleObject) => void;

  private holidayData: {
    id: GetHolidaysYear['holiday'];
    calendarId: GetHolidaysYear['holiday'];
    title: GetHolidaysYear['holidayName'];
    category: string;
    start: GetHolidaysYear['holiday'];
    end: GetHolidaysYear['holiday'];
    isReadOnly: boolean;
    color: string;
    bgColor: string;
    customStyle: string;
    borderColor: string;
    isVisible: boolean;
  }[] = [];
  private tuiCalendar: TuiCalendar;

  private beginYear: number = new Date().getFullYear() - 5 >= 2018 ? new Date().getFullYear() - 5 : 2018;
  private renderMonth = changeDateFormat(getToday(), 'YYYY.MM');

  private async setCurrentYearMonth(type?: string) {
    if (type === 'prev') {
      this.yearSync = this.monthSync - 1 === 0 ? this.yearSync - 1 : this.yearSync;
      this.monthSync = this.monthSync - 1 === 0 ? 12 : this.monthSync - 1;
      return;
    }
    if (type === 'next') {
      this.yearSync = this.monthSync + 1 === 13 ? this.yearSync + 1 : this.yearSync;
      this.monthSync = this.monthSync + 1 === 13 ? 1 : this.monthSync + 1;
      return;
    }
  }

  private get weeksName(): string[] {
    return [
      window.$t('SUN_SHOR') as string,
      window.$t('MON_SHOR') as string,
      window.$t('TUE_SHOR') as string,
      window.$t('WED_SHOR') as string,
      window.$t('THU_SHOR') as string,
      window.$t('FRI_SHOR') as string,
      window.$t('SAT_SHOR') as string,
    ];
  }

  private get showToday() {
    const week = Number(changeDateFormat(getToday(), 'd'));
    const today = changeDateFormat(getToday(), 'YYYY.MM.DD');
    return `${today} (${this.weeksName[week]})`;
  }
  private get prveDisabled() {
    let flg = false;
    if (this.yearSync === this.beginYear && this.monthSync === 1) {
      flg = true;
    }
    return flg;
  }
  private get nextDisabled() {
    let flg = false;
    const nextYear = new Date().getFullYear() + 1;
    if (this.yearSync === nextYear && this.monthSync === 12) {
      flg = true;
    }
    return flg;
  }
  private async moveMonth(type: string) {
    if (type === 'prev') {
      this.tuiCalendar.prev();
    } else {
      this.tuiCalendar.next();
    }
    await this.setCurrentYearMonth(type);
    const tempDate = new Date(this.yearSync, this.monthSync - 1, 1);
    this.renderMonth = changeDateFormat(getStrDate(tempDate), 'YYYY.MM');
    this.tuiCalendar.setDate(tempDate);

    await this.movedMonth();
  }

  private initCalender() {
    this.tuiCalendar = new TuiCalendar('#calendar', {
      defaultView: 'month',
      theme: {
        'common.border': '1px solid #e5e5e5',
        'common.backgroundColor': 'white',
        'common.holiday.color': '#ff4040',
        'common.saturday.color': '#135de6',
        'common.dayname.color': '#333',
        'common.today.color': '',

        // creation guide style
        'common.creationGuide.backgroundColor': 'rgba(81, 92, 230, 0.05)',
        'common.creationGuide.border': '1px solid #515ce6',

        // month header 'dayname'
        'month.dayname.height': '31px',
        'month.dayname.borderLeft': '1px solid #e5e5e5',
        'month.dayname.paddingLeft': '10px',
        'month.dayname.paddingRight': '10px',
        'month.dayname.backgroundColor': 'inherit',
        'month.dayname.fontSize': '12px',
        'month.dayname.fontWeight': 'normal',
        'month.dayname.textAlign': 'left',

        // month day grid cell 'day'
        'month.holidayExceptThisMonth.color': 'rgba(255, 64, 64, 0.4)',
        'month.dayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
        'month.weekend.backgroundColor': 'inherit',
        'month.day.fontSize': '14px',

        // month schedule style
        'month.schedule.borderRadius': '0px',
        'month.schedule.height': '20px',
        'month.schedule.marginTop': '0px',
        'month.schedule.marginLeft': '0px',
        'month.schedule.marginRight': '0px',
      },
      month: {
        daynames: this.weeksName,
        moreLayerSize: {
          height: 'auto',
        },
        grid: {
          footer: {
            height: 10,
          },
        },
      },
      template: {
        monthGridHeader: model => {
          const date = new Date(model.date);
          let template = `<span class="tui-full-calendar-weekday-grid-date">${date.getDate()}</span>`;
          if (model.isToday) {
            template += `<span class="tag custom-today">${window.$t('TODAY')}</span>`;
          }
          return template;
        },
      },
    });

    this.tuiCalendar.on({
      clickSchedule: this.clickSchedule,
      beforeCreateSchedule: (event: IEventWithoutCreationPopup) => {
        if (event.guide['clearGuideElement']) event.guide['clearGuideElement']();
      },
    });
  }

  @Watch('schedules')
  private initSchedule() {
    const temp = [];
    const tempDate = new Date(this.yearSync, this.monthSync - 1, 1);
    this.renderMonth = changeDateFormat(getStrDate(tempDate), 'YYYY.MM');
    this.tuiCalendar.setDate(tempDate);
    this.tuiCalendar.clear();
    this.tuiCalendar.createSchedules(temp.concat(this.holidayData, this.schedules));
    this.tuiCalendar.render();
  }

  private async initHoliday() {
    const holidays: GetHolidaysYear[] = [];
    let request: GetHolidaysYearRequest = {
      pathParams: {
        year: this.beginYear.toString(),
      },
    };
    for (let i = this.beginYear; i <= new Date().getFullYear() + 1; i++) {
      request = {
        pathParams: {
          year: i.toString(),
        },
      };
      const { data } = await this.$api.getHolidaysYear(request);
      holidays.push(...data);
    }
    this.holidayData.push(
      ...holidays.map(holiday => {
        return {
          id: holiday.holiday,
          calendarId: holiday.holiday,
          title: holiday.holidayName,
          category: 'allday',
          start: holiday.holiday,
          end: holiday.holiday,
          isReadOnly: true,
          color: '#9a1313',
          bgColor: 'rgba(218,27,27,0.2)',
          customStyle: 'text-decoration: underline; margin: 0px 10px; cursor: default;',
          borderColor: '#da1b1b',
          isVisible: true,
        };
      }),
    );
  }

  private async mounted() {
    this.initCalender();
    if (this.showHoliday) await this.initHoliday();
    this.initSchedule();
  }
}
