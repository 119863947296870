





















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { DeleteDutyCategoriesTemplatesRequest, DutyCategoriesTemplate, NCPResponse } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import { GridProp } from '@/types';
import { gridProps } from '@/components/popup/product/basic/DutyInfoList';

@Component({
  components: { NoticeBox, Grid },
})
export default class DutyInfoList extends Vue {
  @Prop() private readonly onPositiveClick: (data: DutyCategoriesTemplate) => void;
  @Prop() private readonly onNegativeClick: () => void;
  @Prop() private readonly data: boolean;

  private gridProps: GridProp = gridProps;

  private created(): void {
    this.fetchDutyTemplates();
  }

  private templates: DutyCategoriesTemplate[] = [];
  private async fetchDutyTemplates(): Promise<void> {
    const { data }: NCPResponse<DutyCategoriesTemplate[]> = await this.$api.getDutyCategoriesTemplates();
    this.templates = data;
    this.totalPage = data.length ? Math.ceil(data.length / this.pageSize) : 1;
  }

  private selectProductDuty(dutyInfo): void {
    const check = !this.data || confirm(this.$t('PRODUCT.DUTY.NO_SAVED_SURE').toString());
    if (!check) {
      this.onNegativeClick();
      return;
    }
    this.onPositiveClick(dutyInfo);
  }

  private async deleteProductDuty(dutyInfo): Promise<void> {
    const params: DeleteDutyCategoriesTemplatesRequest['params'] = { no: dutyInfo.no };
    await this.$api.deleteDutyCategoriesTemplates({ params });
    await this.fetchDutyTemplates();
  }

  private totalPage = 1;
  private page = 1;
  private pageSize = 10;
  private visablePageButtonSize = 10;
  get firstPage() {
    return this.page - 5 > 0 ? this.page - 5 : 1;
  }
  get lastPage() {
    const lastPage = this.page - 5 > 0 ? this.page + 4 : this.visablePageButtonSize;
    return lastPage <= this.totalPage ? lastPage : this.totalPage;
  }
  get productDuties() {
    const start = this.page === 1 ? 0 : (this.page - 1) * this.pageSize - 1;
    const end = this.page === 1 ? this.page * this.pageSize : this.page * this.pageSize - 1;
    return this.templates.slice(start, end);
  }
  changePage(page: number) {
    this.page = page;
  }
}
