export default Object.freeze({
  NAME: '상품명',
  NUMBER: '상품번호',
  SALE_STATUS: '판매상태',
  SALE_SETTING: '판매설정',
  SALE_PERIOD: '판매기간',
  RESERVATION_PERIOD: '예약판매기간',
  DISPLAY_SETTING: '전시상태 설정',
  DISPLAY_PLATFORM: '노출 플랫폼',
  DISPLAY_GRADE: '노출 회원등급',
  DISPLAY_CATEGORY: '전시카테고리',
  SHORT_URL: '단축 URL',
  SALE_PRICE: '판매가',
  IMMEDIATE_DISCOUNT: '즉시할인',
  IMMEDIATE_DISCOUNT_PRICE: '즉시할인가',
  ADDITIONAL_DISCOUNT: '추가할인',
  ADDITIONAL_DISCOUNT_PRICE: '추가할인가',
  WITH_ADDITIONAL_DISCOUNT: '추가할인 함께보기',
  ACCUMULATION_RATE: '상품 적립률',
  OPTION: '옵션',
  RESERVATION_STOCK_COUNT: '예약재고수량',
  STOCK_COUNT: '재고수량',
  DELIVERY_WAIT: '출고대기',
  APPLY_PROMOTION: '프로모션 적용',
  HANDLER: '담당자',
  PARTNER: '파트너사',
  SALES_METHOD: '판매방식',
  COMMISSION_RATE: '수수료율',
  SUPPLY_PRICE: '공급가',
  PURCHASE_PRICE: '매입가',
  SALES_COUNT: '판매수량',
  PRODUCT_REVIEWS: '상품후기',
  DELIVERABLE: '배송여부',
  DELIVERY_FEE_TYPE: '배송비 유형',
  DELIVERY_CHARGE: '배송비',
  SHIPPING_COMBINABLE: '묶음배송 여부',
  POSSIBLE_SHIPPING_COMBINABLE: '묶음배송 가능여부',
  DISPLAY_NAVER_SHOPPING: '네이버 쇼핑 노출여부',
  BRAND: '브랜드',
  PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  REGISTRATION_AND_MODIFICATION_DATE: '등록일/수정일',
  CHANGE_HISTORY: '상품정보 변경이력',

  TOTAL: '전체',
  ON_SALE: '판매중',
  SOLD_OUT: '품절',

  SELECTED: '선택한 {0}을',
  TARGET_LOCATION: '번 위치로',
  MOVE: '이동',
  FIX: '상단고정',
  NEED_TARGET: '이동 순서를 입력해주세요.',

  SET: '세트',

  NO_CATEGORY_DATA: '등록된 카테고리가 없습니다.',
  NO_BRAND_DATA: '등록된 브랜드가 없습니다.',

  // IMAGE
  MAIN: '대표',
  IMAGE: '{title}이미지',
  RECOMMEND_IMAGE_SIZE: '권장 {W}x{H}',
  SELECT_PRODUCT_ADD: '선택상품 추가',
  PRODUCT_REVIEW_TOOLTIP_MESSAGE: "상품후기 개수에 전시상태가 '블라인드'이거나, 삭제된 후기는 포함되지 않습니다.",
  SUPPLY_PRICE_TOOLTIP_MESSAGE:
    '· 옵션을 사용중인 상품의 경우, 판매수수료 설정 방식에 따라 옵션별로 공급가를 직접 입력하여 설정할 수 있습니다.\n· 옵션별로 공급가를 설정한 경우 상세보기를 클릭하여 옵션별 공급가를 확인할 수 있습니다.\n· 옵션별로 공급가를 설정한 경우 엑셀 다운로드 시 첫번째 옵션의 공급가가 출력됩니다.',
});
