




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class ProductAddIframe extends Vue {
  private get remoteSrc() {
    const { query, path } = this.$route;
    const type = path.includes('set') ? 'set' : 'single';
    let action = 'add';

    if (path.includes('edit')) {
      action = 'edit';
    } else if (path.includes('copy')) {
      action = 'copy';
    }

    const queryString = new URLSearchParams({ serviceType: 'PRO', ...query }).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + `/product/management/${type}/${action}?` + queryString;
  }
}
