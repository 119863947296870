export const STYLE_TYPE = {
  DISABLE: 'disable',
  ENABLE: 'enable',
  FOCUS: 'focus',
} as const;

export const TEXT_TYPE = {
  CODE: 'code',
  TEXT: 'text',
} as const;
