























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { GetServicesServiceNoMainDomainsResponse, SslStatusByCommerce } from 'ncp-api-supporter';
import { DOMAIN_EXPIRATION_WARN_DUE_DATE } from '@/views/top/root/initialData';

type DomainSecurityInfo = GetServicesServiceNoMainDomainsResponse;
type DomainStatus = GetServicesServiceNoMainDomainsResponse['domainStatus'];
type SecurityStatus = GetServicesServiceNoMainDomainsResponse['sslStatus'];
type DomainSecurityRecommendation = {
  text: 'NECESSARY' | 'CAUTION' | 'COMPLETE';
  color: 'text-red' | 'text-orange' | 'text-green';
  icon: 'blocked' | 'warning' | 'completed';
};

@Component({
  directives: { ClickOutside },
})
export default class DomainSecurityConnection extends Vue {
  @Prop({ required: true, default: '' })
  private readonly mallName!: string;

  @Prop({ required: true, default: {} })
  private readonly domainSecurityInfo!: DomainSecurityInfo;

  private showConnectionStatusLayer = false;

  get isCombine() {
    return this.domainSecurityInfo.skinType === 'COMBINE';
  }

  private getIsExpiredClose(dueDay: null | number) {
    return !!dueDay && dueDay <= DOMAIN_EXPIRATION_WARN_DUE_DATE;
  }

  private getIsSslInstalled(status: SslStatusByCommerce) {
    return ['INSTALLED', 'WAITING_EXTENSION'].includes(status);
  }

  private getIsSslUnConnect(status: SslStatusByCommerce) {
    return ['UNINSTALLED', 'EXPIRATION'].includes(status);
  }

  // 도메인/보안서버 연결상태 - 필요 | 주의 | 완료
  get domainSecurityRecommendation(): DomainSecurityRecommendation {
    const {
      domain,
      domainStatus,
      sslStatus,
      mobileSslStatus,
      mobileSslExpirationDueDays,
      sslExpirationDueDays,
      domainExpirationDueDays,
    } = this.domainSecurityInfo;
    const isUnConnect = !domain || domainStatus === 'EXPIRATION' || this.getIsSslUnConnect(sslStatus);
    const isConnect = domainStatus === 'CONNECTED' && sslStatus === 'INSTALLED';

    // CASE: '필요'
    if (
      (this.isCombine && isUnConnect) ||
      (!this.isCombine && (isUnConnect || this.getIsSslUnConnect(mobileSslStatus)))
    ) {
      return { text: 'NECESSARY', color: 'text-red', icon: 'blocked' };
    }

    // CASE: '주의'
    if (
      this.getIsExpiredClose(domainExpirationDueDays) ||
      this.getIsExpiredClose(mobileSslExpirationDueDays) ||
      this.getIsExpiredClose(sslExpirationDueDays)
    ) {
      return { text: 'CAUTION', color: 'text-orange', icon: 'warning' };
    }

    // CASE: '완료'
    if ((this.isCombine && isConnect) || (!this.isCombine && isConnect && mobileSslStatus === 'INSTALLED')) {
      return { text: 'COMPLETE', color: 'text-green', icon: 'completed' };
    }

    return { text: 'NECESSARY', color: 'text-red', icon: 'blocked' };
  }

  private getStatusColor(status: DomainStatus | SecurityStatus) {
    return status === 'CONNECTED' || status === 'INSTALLED' ? 'text-green' : 'text-red';
  }

  onClickOutside(): void {
    this.showConnectionStatusLayer = false;
  }
}
