



































































































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { PartnersInfoByNo } from 'ncp-api-supporter';

type CsValueProperties = 'phoneNo' | 'emailId' | 'emailDomain';

const PHONE_REGEX = /[\d-]/g;
const EMAIL_REGEX = /[\s‘”<>`(),:;@\\[\]]/g;

@Component
export default class CsAdminInformation extends Vue {
  @PropSync('partnerInfo')
  private partnerInfoSync: PartnersInfoByNo;
  private NAME_MAX_LENGTH = 30;
  private PHONE_NO_MAX_LENGTH = 13;
  private EMAIL_ID_MAX_LENGTH = 64;
  private EMAIL_DOMAIN_MAX_LENGTH = 255;
  private OPERATION_INFO_MAX_LENGTH= 255;
  private emailId = '';
  private emailDomain = '';
  private error = {
    phoneNo: false,
    emailId: false,
    emailDomain: false,
  };

  @Watch('emailId')
  changeEmailId() {
    this.changeEmail();
  }

  @Watch('emailDomain')
  changeEmailDomain() {
    this.changeEmail();
  }

  @Watch('partnerInfoSync', { immediate: true, deep: true })
  initCsManager() {
    if (this.partnerInfoSync) {
      const [id, domain] = this.partnerInfoSync.csManager.email.split('@');
      this.emailId = id || '';
      this.emailDomain = domain || '';
    }
  }

  private get isDisabled() {
    return this.partnerInfoSync.partnerStatus === 'WAITING';
  }

  private changeEmail() {
    const value = !this.emailId.length && !this.emailDomain.length ? '' : `${this.emailId}@${this.emailDomain}`;
    this.partnerInfoSync.csManager.email = value;
  }

  private checkError(property: CsValueProperties) {
    const { phoneNo } = this.partnerInfoSync.csManager;

    if (property === 'phoneNo' && phoneNo.length && phoneNo.match(PHONE_REGEX)?.join('') !== phoneNo) {
      this.error.phoneNo = true;
    }

    if (property === 'emailId' && this.emailId.replace(EMAIL_REGEX, '') !== this.emailId) {
      this.error.emailId = true;
    }

    if (property === 'emailDomain' && this.emailDomain.replace(EMAIL_REGEX, '') !== this.emailDomain) {
      this.error.emailDomain = true;
    }
  }

  private handleErrorCheck(property: CsValueProperties) {
    if (property === 'phoneNo' && this.error.phoneNo) {
      const { phoneNo } = this.partnerInfoSync.csManager;

      if (!phoneNo.length || phoneNo.match(PHONE_REGEX).join('') === phoneNo) {
        this.error.phoneNo = false;
      }
    }

    if (property === 'emailId' && this.error.emailId) {
      if (!this.emailId.length || this.emailId.replace(EMAIL_REGEX, '') === this.emailId) {
        this.error.emailId = false;
      }
    }

    if (property === 'emailDomain' && this.error.emailDomain) {
      if (!this.emailDomain.length || this.emailDomain.replace(EMAIL_REGEX, '') === this.emailDomain) {
        this.error.emailDomain = false;
      }
    }
  }
}
