


























































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import TableTabMenu from '@/components/common/TableTabMenu.vue';
import {
  CategoryType,
  getPersonalAccessHistoryGridOption,
  menuList,
  resetQuery,
  customerWorkingTypes,
  adminWorkingTypes,
  workingLabel,
} from '@/const/contents/configuration/personalAccessHistory';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import Grid from '@/components/common/grid/Main.vue';
import { i18nSecurity } from '@/const/contents/configuration/operateSecurity';
import { getDateDiff, getDateTime } from '@/utils/dateFormat';
import { NCPResponse, GetPrivacyAccessHistoriesRequest, GetPrivacyAccessHistories } from 'ncp-api-supporter';
import { convertQueryParamItemToBoolean, sendQueryString } from '@/utils/query';
import { GridEventProps } from '@/types';
import { Row } from '@/types/tui-grid';
import { mall, getMallName } from '@/utils/mall';
import { throwPopup } from '@/helpers/popup';
import { WorkingDetailType } from '@/components/popup/configuration/WorkingDetail.vue';

@Component({
  components: { TableTabMenu, SearchButton, TextInput, DateRangePicker, SelectBox, NoticeBox, Grid, MallSelect },
})
export default class PersonalAccessHistory extends Vue {
  private menuList = menuList();
  private mallNo = mall.lastCreatedMall.mallNo;
  private query = resetQuery(this.mallNo);
  private totalCount = 0;
  private personalAccessHistories = [];
  private gridProps = getPersonalAccessHistoryGridOption(this.query.category);
  private updateQuery = true;
  @Ref() private readonly gridRef: Grid;
  @Ref() private readonly searchButton: SearchButton;

  created() {
    this.searchPersonalAccessHistories();
  }

  @Watch('query.category')
  private resetQueryString() {
    this.resetQuery();
    this.changeQuery();
  }

  @Watch('query.startDateTime')
  @Watch('query.endDateTime')
  private changedDate() {
    this.query.page = 1;
  }

  @Watch('mallNo')
  private init() {
    this.resetQuery(false);
    this.updateQuery = true;
  }

  @Watch('$route.query')
  private onQueryStringChanged() {
    if (this.updateQuery === false) {
      this.updateQuery = true;
      return;
    }
    this.searchPersonalAccessHistories(true);
  }

  private changeQuery() {
    sendQueryString(this, this.query);
    this.searchPersonalAccessHistories();
    this.updateQuery = false;
  }

  private resetQuery(isMallNoResetNeeded = true): void {
    if (isMallNoResetNeeded) {
      this.mallNo = mall.mallsSortedByCreationDate[0].mallNo;
    }
    this.query = resetQuery(this.mallNo, this.query.category);
  }

  private async searchPersonalAccessHistories(updateQuery = false) {
    updateQuery && Object.assign(this.query, convertQueryParamItemToBoolean(this.$route.query));
    const data = { ...this.query };
    data.startDateTime = getDateTime(this.query.startDateTime, 'START');
    data.endDateTime = getDateTime(this.query.endDateTime, 'END');
    data.mallNo = this.mallNo;
    const request: GetPrivacyAccessHistoriesRequest = { params: data };
    try {
      const { data }: NCPResponse<GetPrivacyAccessHistories> = await this.$api.getPrivacyAccessHistories(request);

      // The grid should also display the mall name in here, but each element of 'data.contents' does not have a property about it.
      // Therefore, a list in which 'mallName' property is added to each element is created,
      // and assigned to 'historiesData'. It is used as the grid data.
      const historiesData = data.contents.map(e => ({
        ...e,
        mallName: this.mallNo ? getMallName(this.mallNo) : '전체',
      }));
      this.personalAccessHistories = historiesData;
      this.totalCount = data.totalCount;
    } catch (err) {
      console.error(err);
    }
  }

  private noticeList = i18nSecurity('PERSONAL_NOTICE_MESSAGE').split('\n');

  private checkValidation(): boolean {
    const startMonth = getDateDiff(this.query.startDateTime, new Date(), 'year');
    const maxStartYear = this.query.category === CategoryType.CUSTOMER ? 2 : 5;
    if (startMonth >= maxStartYear) {
      alert(this.$t('CONFIGURATION.SECURITY.STORAGE_PERIOD', [maxStartYear]));
      return false;
    }
    const dateDiff = getDateDiff(new Date(), this.query.endDateTime);
    const MAX_SEARCH_MONTH = 6;
    if (dateDiff >= MAX_SEARCH_MONTH) {
      alert(i18nSecurity('UP_TO_6_MONTHS'));
      return false;
    }
    return true;
  }

  private onItemClicked({ rowKey, columnName }: GridEventProps) {
    if (rowKey === undefined) return;
    const rowData = this.gridRef.getRowAt(rowKey);
    if (Number((rowData.workingDetail as WorkingDetailType).count) === 0) return;
    if (workingLabel(rowData.workingType, rowData.workingDetail).includes('FAIL')) return;

    const workingTypes = this.query.category === 'CUSTOMER' ? customerWorkingTypes : adminWorkingTypes;
    const isDetailPopup = workingTypes.includes(rowData.workingType as string) === true;
    if (columnName !== 'workingTypeDescription' || isDetailPopup === false) return;
    this.openDetailHistoryPopup(rowData);
  }

  private openDetailHistoryPopup(rowData: Row) {
    throwPopup({
      name: 'WorkingDetail',
      data: { ...rowData, categoryType: this.query.category },
    });
  }
}
