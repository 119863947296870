


















































import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import { orderListTypes, orderNoGridTypes, deliveryNoGridTypes } from '@/const/order';
import Grid from '@/components/common/grid/Main.vue';
import ContentBottom from '@/components/shipping/order/ContentBottom.vue';
import OrderGridsColumns from '@/components/shipping/order/mixins/OrderGridsColumns';
import ContentButton from '@/components/shipping/order/ContentButton.vue';
import { GridEvent } from '@/types/claim';
import { throwExternalWindowPopup } from '@/helpers/popup';
import { getHref } from '@/utils/route';
import { CheckGridEventProps } from '@/types';
import { getDefaultPreviousOrderQuery } from '@/components/shipping/order/mixins/searchQuery';
import ClickOutside from 'vue-click-outside';
import { PreviousOrdersContent } from 'ncp-api-supporter';
import { changeDateFormat } from '@/utils/dateFormat';

export type PreviousOrdersGridContent = Omit<PreviousOrdersContent, 'orderOptions' | 'ordererContact'> & {
  productName: string;
  orderYmdt: string;
  orderMemo: string;
  orderContact: string;
};

export type PreviousOrdersContentForSms = Omit<PreviousOrdersGridContent, 'receiverContact'> & {
  mallNo: number;
  receiverContact1: string;
};

@Component({
  components: {
    ContentButton,
    ContentBottom,
    Grid,
  },
  directives: { ClickOutside },
})
export default class OrderPrevious extends Mixins(OrderGridsColumns) {
  @Ref()
  protected readonly gridRef: Grid;

  private selected = [];
  private query = getDefaultPreviousOrderQuery(this.$route);
  private contents: PreviousOrdersGridContent[] = [];
  private totalCount = 0;

  private get mallNo(): number {
    return this.query.mallNo;
  }

  created() {
    this.orderListTypes = orderListTypes.PREVIOUS;
    this.setGridData(this.orderListTypes);
    this.fetchOrder();
  }

  @Watch('$route.query')
  private async fetchOrder(): Promise<void> {
    this.initQuery();

    const { data } = await this.$api.getPreviousOrders({ params: this.query });

    this.setTotalCount(data.totalCount);
    this.setContents(data.contents);

    this.updateGridContents();
  }

  private setTotalCount(totalCount: number) {
    this.totalCount = totalCount;
  }

  private setContents(contents: PreviousOrdersContent[]) {
    const generateGridContent = (contents: PreviousOrdersContent[]): PreviousOrdersGridContent[] => {
      const MAIN_PRODUCT_COUNT = 1;
      return contents.map(({ ordererContact, orderOptions, ...rest }) => {
        const productCount = orderOptions.length;
        const { productName, orderYmdt, orderMemo, payYmdt } = orderOptions[0]; // 기획상 0번째 상품을 기준으로 사용함
        const productNameLabel =
          productCount > MAIN_PRODUCT_COUNT ? `${productName} 외 ${productCount - MAIN_PRODUCT_COUNT}건` : productName;

        return {
          ...rest,
          orderMemo,
          orderContact: ordererContact,
          productName: productNameLabel,
          orderYmdt,
          payYmdt,
        };
      });
    };

    this.contents = generateGridContent(contents);
  }

  private updateGridContents() {
    this.gridRef.setData(this.contents);
  }

  private initQuery() {
    this.query = { ...getDefaultPreviousOrderQuery(this.$route) };
  }

  private get extraInfo() {
    return `${changeDateFormat(this.query.startYmd, 'YYYYMMDD')}_${changeDateFormat(this.query.endYmd, 'YYYYMMDD')}`;
  }

  private onGridItemClick({ columnName, rowKey, instance }: GridEvent) {
    if (columnName === undefined) return;

    const { memberNo, orderNo } = instance.getRowAt(Number(rowKey));
    switch (columnName) {
      case 'ordererName':
        if (!memberNo) return;
        throwExternalWindowPopup(`/pro/crm/${memberNo}`, 'xlg');
        break;
      case 'orderNo':
        window.open(`${getHref('OrderPreviousDetail')}?orderNo=${orderNo}`, '_blank');
        break;
    }
  }

  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
  }

  private handleSmsSendClick(sendType: 'SELECT' | 'SEARCH') {
    const orders =
      sendType === 'SELECT'
        ? ((this.gridRef.getCheckedRows() as unknown) as PreviousOrdersGridContent[])
        : this.contents;

    this.openSmsSendPopup(sendType, this.mapOrdersToSendSms(orders));
  }

  private mapOrdersToSendSms(orders: PreviousOrdersGridContent[]): PreviousOrdersContentForSms[] {
    return orders.map(({ receiverContact, ...rest }) => ({
      ...rest,
      mallNo: this.mallNo,
      receiverContact1: receiverContact,
    }));
  }

  public async setGridData(orderListTypes): Promise<void> {
    const data = [];
    this.gridProps.columns = [];
    this.containerOptions.gridName = orderNoGridTypes[orderListTypes]
      ? orderNoGridTypes[orderListTypes]
      : deliveryNoGridTypes[orderListTypes];
    this.listColumns(orderListTypes).map((item, index: number): {} => {
      const columnName = this.getColumnName(item);

      return (data[index] = {
        columnName,
        visible: true,
        displayOrder: index,
      });
    });
    data.forEach(item => {
      this.gridProps.columns.push(this.getOrderPreviousColumns(item.columnName, item.visible, item.displayOrder));
    });
  }
}
