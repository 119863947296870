import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GetClaimsSummary, GetOrderOptionsSaleSummaryMall, ProductInquiriesSummary } from 'ncp-api-supporter';
import { SummaryState } from '@/types/summary';
import { GetInquiriesCounts } from 'ncp-api-supporter/dist/types/modules/manage/inquiry';
import { QueryString } from '@/types';
import { stringify } from 'qs';
import { addMonth, addDay, getStrDate, addYear } from '@/utils/dateFormat';
import { Dictionary } from 'vue-router/types/router';

const summaryStore = namespace('summary');

type PeriodType = 'day' | 'month' | 'year';

export interface Summary<KeyType = string> {
  key: KeyType;
  label: string;
  count: number | null;
  path?: {
    name: string;
    query?: Dictionary<string | number | ('' | number) | boolean>;
  };
  query?: QueryString;
  periodLabel?: string;
}

export interface GetPeriodQueryOptions {
  endDate?: Date;
  queryKeyName?: PeriodQueryKeyName;
}

export interface PeriodQueryKeyName {
  start?: string;
  end?: string;
}

export interface PeriodQueryObject {
  [key: string]: string;
}

@Component
export default class SummaryData extends Vue {
  @summaryStore.Action('fetchSaleInquirySummary')
  protected readonly fetchSaleInquirySummary!: Function; // TODO : 타입정의
  @summaryStore.Action('fetchProductInquiry')
  protected readonly fetchProductInquiry!: Function;
  @summaryStore.Action('fetchOneToOneInquiry')
  protected readonly fetchOneToOneInquiry!: Function;
  @summaryStore.Action('fetchManageInquiresSummary')
  protected readonly fetchManageInquiresSummary!: Function;
  @summaryStore.Action('fetchClaimInquiresSummary')
  protected readonly fetchClaimInquiresSummary!: Function;
  @summaryStore.Action('fetchDelaySummary')
  protected readonly fetchDelaySummary!: Function;
  @summaryStore.Action('fetchProductSummary')
  protected readonly fetchProductSummary!: Function;
  @summaryStore.Getter('getSaleSummary')
  protected readonly saleSummary!: GetOrderOptionsSaleSummaryMall;
  @summaryStore.Getter('getOneToOneSummary')
  protected readonly oneToOneSummary!: GetInquiriesCounts;
  @summaryStore.Getter('getClaimInquirySummary')
  protected readonly getClaimInquirySummary!: GetClaimsSummary;
  @summaryStore.Getter('getProductInquirySummary')
  protected readonly productInquirySummary!: ProductInquiriesSummary;
  @summaryStore.Getter('getManageInquirySummary')
  protected readonly manageInquirySummary!: SummaryState['manageInquirySummary']; // TODO : 이거안쓰지않나? 많으니깐 햇갈려..
  @summaryStore.Getter('getMallNoQuery')
  protected readonly mallNoQuery!: 'ALL' | number;
  @summaryStore.Getter('getDelaySummary')
  protected readonly delaySummary!: SummaryState['delayInquirySummary'];
  @summaryStore.Getter('getProductSummary')
  protected readonly productSummary: SummaryState['productMainInquirySummary'];

  protected getSummaryPeriodI18n(period: number, type: PeriodType): string {
    return (
      '(' + this.$t('SUMMARY.LATELY') + ' ' + this.$t(`SUMMARY.PERIOD_${type.toUpperCase()}`, { period: period }) + ')'
    );
  }

  protected getDisplayCount(count: number | null): string {
    return count !== null ? count.toString() : 'N';
  }

  protected getToPath(path: string, query: QueryString | null): string {
    if (!query) return path;
    const separator = path.includes('?') ? '&' : '?';
    return path + separator + stringify(query);
  }

  // 조회 기간 관련한 쿼리 객체를 생성하는 메서드
  // period 관련한 query keyname이 통일되어있지 않아 options.queryKeyName으로 동적 할당 (ex: InquiriesList)
  protected getPeriodQueryObject(
    distanceFromEndDate: number,
    periodType: PeriodType,
    options?: GetPeriodQueryOptions,
  ): PeriodQueryObject {
    const endDate = options?.endDate ?? new Date();
    const queryKeyName = options?.queryKeyName ?? { start: 'startYmd', end: 'endYmd' };
    let funcForCalcul;
    switch (periodType) {
      case 'day':
        funcForCalcul = addDay;
        break;
      case 'month':
        funcForCalcul = addMonth;
        break;
      case 'year':
        funcForCalcul = addYear;
    }
    return {
      [queryKeyName.start]: funcForCalcul(endDate, distanceFromEndDate),
      [queryKeyName.end]: getStrDate(endDate),
    };
  }
}
