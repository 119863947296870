



































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DemoPopup extends Vue {
  @Prop() private data;
  @Prop() private onPositiveClick!: Function;
  @Prop() private onNegativeClick!: Function;
}
