





























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { getSmsReservationHistoryInitQuery } from '@/const/contents/sms';
import { getFirstAndEndDay, getSelectMonths, getSelectYears } from '@/utils/dateFormat';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { SendType } from '@/types/member';
import { getCurrentMallNo } from '@/utils/mall';
import moment from 'moment';
import { SmsGodoSearchType } from 'ncp-api-supporter';
import { isValidate } from '@/utils/validate';
import { isEmpty, omit } from 'underscore';

@Component({
  components: { RadioGroup, SelectBox, SearchButton },
})
export default class SmsReservationSearchForm extends Vue {
  private queryParams = getSmsReservationHistoryInitQuery(getCurrentMallNo(this));
  // 발송예약일시
  private selectYear = new Date().getFullYear();
  private selectMonth = new Date().getMonth() + 1;
  private readonly yearList = getSelectYears(this.$t('YEAR').toString(), false);
  private readonly monthList = getSelectMonths(this.$t('MONTH').toString());
  // 검색어
  private readonly searchTypeList: OptionData<SmsGodoSearchType>[] = [
    { value: 'TOPHONE', name: 'MEMBER.SMS_RESERVATION.RECIPIENT_NO' },
    { value: 'MSG', name: 'MEMBER.SMS.CONTENT' },
  ];
  // 유형
  private readonly sendTypeOption: RadioBoxGroupOption<SendType> = {
    name: 'sendType',
    data: [
      { id: 'send-sms', value: 'SMS', display: 'SMS' },
      { id: 'send-lms', value: 'LMS', display: 'LMS' },
    ],
  };

  private changeSelectDate() {
    const { startYmd, endYmd } = getFirstAndEndDay(this.selectYear, this.selectMonth);
    this.queryParams.startDate = startYmd;
    this.queryParams.endDate = endYmd;
  }

  private validateQuery(): boolean {
    const startDate = moment()
      .startOf('month')
      .add(1, 'month');
    const beforeDate = startDate.diff(this.queryParams.startDate, 'years', true) >= -3;
    return isValidate(beforeDate, this.$t('MEMBER.KAKAO.ALERT_MAX_DATE'));
  }

  @Watch('$route.query')
  private resetValue() {
    if (!isEmpty(omit(this.$route.query, 'mallNo'))) return;
    this.queryParams = getSmsReservationHistoryInitQuery(getCurrentMallNo(this));
    this.queryParams.mallNo = getCurrentMallNo(this);
    this.selectYear = moment(this.queryParams.startDate)
      .toDate()
      .getFullYear();
    this.selectMonth =
      moment(this.queryParams.startDate)
        .toDate()
        .getMonth() + 1;
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }

  created() {
    this.resetValue();
  }
}
