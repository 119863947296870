


































































import { Vue, Component, Ref } from 'vue-property-decorator';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { Getter } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
import { getDormantMemberSelectType, getDormantMemberInitQuery } from '@/const/contents/member';
import { DormantMemberQuery } from '@/types/member';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';

@Component({ components: { MallSelect, ToolTip, DateRangePicker, SearchButton, SelectBox } })
export default class DormantMember extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  @Ref() private readonly searchButton!: SearchButton;

  private selectType = getDormantMemberSelectType();

  private query: DormantMemberQuery = getDormantMemberInitQuery();

  private startDateTime = this.query.startDateTime.slice(0, 10);
  private endDateTime = this.query.endDateTime.slice(0, 10);

  created() {
    if (!this.$route.query) {
      return;
    }
    Object.assign(this.query, this.$route.query);

    this.startDateTime = this.query.startDateTime.slice(0, 10);
    this.endDateTime = this.query.endDateTime.slice(0, 10);
  }

  private updateDateTime(): void {
    this.query.startDateTime = this.startDateTime ? this.startDateTime + ' 00:00:00' : '';
    this.query.endDateTime = this.endDateTime ? this.endDateTime + ' 23:59:59' : '';
  }

  private resetQuery(): void {
    this.query = getDormantMemberInitQuery();
    this.startDateTime = this.query.startDateTime.slice(0, 10);
    this.endDateTime = this.query.endDateTime.slice(0, 10);
  }

  private sendQueryUrl(): void {
    this.searchButton.sendQueryUrl();
  }
}
