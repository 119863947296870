import { TranslateResult } from 'vue-i18n';
import { i18n } from '@/main';
import { CellValue } from '@/types/tui-grid';

export const periodFormatter = (period): string => {
  const isPeriod = period && period?.startYmdt && period?.endYmdt;
  return isPeriod ? `${period.startYmdt} ~ ${period.endYmdt}` : '-';
};

export const getAnchorHTML = (text: TranslateResult, classNames?: string): string =>
  `<a href="javascript:;" ${classNames ? `class='${classNames}'` : 'class="underline"'}>${text}</a>`;

export const getDeleteHTML = () => `<button class="btnDel-sm">${i18n.t('PRODUCT.ICON.HEADER_DELETE')}</button>`;

export const getSpanHTML = (value: string, type = '', className = 'underline') => {
  const style = className === 'underline' ? 'text-decoration: underline; cursor: pointer;' : null;
  return `<span class="${className}" data-type="${type}" style="${style}">${value}</span>`;
};

export const getButtonHTML = (value: string, type = '', className = 'double_click_allow type-white') => {
  return `<button data-type="${type}" class="${className}">${value}</button>`;
};

export const emptyValueFormatter = (value, valueToDisplay?): string => (value ? valueToDisplay || value : '-');

export const getHighlightHTML = (value): string => `<span class="highlight">${value}</span>`;

export const getYnRadioHTML = (elementKey: string, isYChecked: CellValue | boolean, yText: string, nText: string) => {
  return `
  <div id='${elementKey}' class='tui-grid-cell-content'>
    <input type='radio' id='${elementKey}-Y' name='${elementKey}' value='true' ${isYChecked ? 'checked' : ''} ></input>
    <label for='${elementKey}-Y' style='margin:0 0 0 2px; cursor: pointer;' >${yText}</label>
    <input type='radio' id='${elementKey}-N' name='${elementKey}' value='false' ${isYChecked ? '' : 'checked'}></input>
    <label for='${elementKey}-N' style='margin:0 0 0 2px; cursor: pointer;'>${nText}</label>
  </div>
  `;
};
