/* eslint-disable quotes */
export default Object.freeze({
  COMMENT1: `표준약관은 공정거래위원회의 '전자상거래 표준약관(제10023호)'를 사용합니다.`,
  COMMENT2: `이용약관은 가급적 '표준약관' 사용을 권장합니다.`,
  CHANGE_HISTORY: `변경이력`,
  TERMS_AND_CONDITIONS: `약관 내용`,
  LOAD_BASIC_CONTENTS: `기본제공내용 불러오기`,
  EFFECTIVE_DATE: `시행일`,
  COMMENT3: `작성된 내용은 시행일자 이전까지만 수정 가능합니다. (시행일자 변경 후, 내용 수정 가능)`,
  SAVE_CHANGES: `변경사항 저장`,
  TEXT_LIST: `치환텍스트 목록`,
  COMMENT_ALERT: `이용약관 '직접입력 사용' 시, 치환텍스트를 통해 내용을 입력할 수 있습니다.`,
  SUBSTITUTION: `치환`,
  TEXT_DESCRIPTION: `텍스트	설명`,
  EXAMPLE: `예시`,
  COMPANY_NAME: `회사명`,
  LTD: `(주)홍길동`,
  MALL_NAME: `쇼핑몰명`,
  INFO: `홍길동몰`,
  DATE: `2018년01월01일`,
});
