








import { Vue, Component } from 'vue-property-decorator';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import PopupList from '@/components/popup/Container.vue';
import $el from '@/utils/element';

@Component({
  components: { LoadingSpinner, PopupList },
})
/*
 * 이 Layout은 새창에서 열리는 페이지에 대한 레이아웃이다.
 * 일반 레이어 팝업과 유사하게 생겨서 SPA 특성에 맞게 레이어 형태의 꽉찬 팝업으로 마크업, 기획 수정을 요청하였으나
 * 이전 화면을 보면서 설정할 수 없다는 이유로 설득당함. 맞는 말임.
 * */
// TODO : 당장 급한 작업은 아닌 관계로 /view/wideUtils 대충 넣어두었다. 추후 정리해야 한다.
export default class WideLayout extends Vue {
  created() {
    $el(document.body).addClass('layer-popup');
  }
  mounted() {
    const $wrapper = $el(this.$refs.popupWrapper as HTMLElement);

    const popupId = this.$route && this.$route.query && this.$route.query.popupId ? this.$route.query.popupId : '';

    if (popupId) {
      let sizeClass = '';

      try {
        const data = localStorage.getItem(`shopby_${popupId}`);
        const initialData = data ? JSON.parse(data) : '';
        sizeClass = initialData.sizeClass;
      } catch (e) {
        console.error(e);
      }

      if (!sizeClass && this.$route && this.$route.query && this.$route.query.width) {
        sizeClass = `type-${this.$route.query.width}`;
      }

      if (sizeClass) {
        $wrapper.addClass(sizeClass);
      }
    }
  }
}
