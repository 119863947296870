export default Object.freeze({
  PAYMENT_TITLE: '적립금 관리',
  TOP_NOTICE_1: '즉시 지급인 경우 지급 시점 회원정보 기준으로 지급 됩니다.',
  TOP_NOTICE_2: '예약 지급인 경우 예약 발송시점 회원정보 기준으로 지급 됩니다.',
  TOP_NOTICE_3: '휴면회원 및 탈퇴회원은 적립금 지급 대상에서 제외됩니다.',
  TOP_NOTICE_4: '예약발송인 경우 설정일과 발송일 회원대상이 일치하지 않을 수 있습니다.',
  TOP_NOTICE_5: '지급된 적립금은 발송 완료일 기준 익일 적립금 적립/차감 조회에서 확인 하실 수 있습니다.',
  TAB_CONFIGURATION_LIST: '적립금설정관리',
  TAB_PAYMENT_LIST: '적립금 지급관리',
  TAB_ACCUMULATION_LIST: '적립금 적립/차감 조회',
  TOP_TBODY_STATUS: '지급설정 상태 조회',
  TOP_TBODY_ACCUMULATION_REGISGER: '적립금 지급등록',
  TOP_TBODY_SEARCH_TYPE_PAYMENT_NO: '적립금번호',
  TOP_TBODY_PERIOD_TYPE_PAYMENT: '지급일시',
  TOP_TBODY_PERIOD_TYPE_REQUEST: '예약일시',

  FORM_HEADER_IMMEDIATELY: '지급설정',
  FORM_HEADER_PAYMENT_COUNT: '지급 적립금',
  FORM_HEADER_VALID_PERIOD: '적립금 유효기간',
  FORM_HEADER_STATUS: '지급상태',
  FORM_PAYMENT_VALIDPERIOD_1: '지급일 기준',
  FORM_PAYMENT_VALIDPERIOD_5: '기본 유효기간에 따름',
  FORM_PAYMENT_VIEW_DETAIL_VIEW: '보기',
  FORM_PAYMENT_REASON_TAG: '예약',
  FORM_HEADER_NO_NEXT_LINE_1: '적립금',
  FORM_HEADER_NO_NEXT_LINE_2: '번호',
  PAYMENT_NO_MUST_NUMBER: '숫자만 입력할 수 있습니다.',
  CHECKBOX_MUST_SELECT: '지급상태값은 1개 이상 선택해야 합니다.',
  POPUP_TITLE: '적립금 지급현황',
});
