





















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import { ClaimType } from '@/types/claim';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({ components: { SelectBox, TextInput } })
export default class SearchKeywordInput extends Vue {
  @Prop({ required: true })
  private readonly claimType!: ClaimType;
  @Prop({ required: true })
  private readonly mallNo!: number;
  @PropSync('searchType')
  private searchTypeSync!: string;
  @PropSync('keyword')
  private keywordSync!: string;
  @PropSync('searchKeywordTypeOptions')
  private searchKeywordTypeOptionsSync!: OptionData<string>[];

  private handleKeyDownEnter(event: KeyboardEvent) {
    if (event.isComposing || event.keyCode === 229) return;
    this.$emit('search');
  }
  // 프리미엄과 다르게 고정값으로 내려줌. 변경 대비해서 주석처리
  // @Watch('mallNo')
  // public async resetSearchKeywordTypes() {
  //   const types = await this.fetchSearchTypes();
  //   this.setSearchTypeKeywordsOptions(types);
  // }
  //
  // private async fetchSearchTypes(): Promise<GetClaimsSearchConditionsTypes[]> {
  //   if (this.claimType === claimTypes.REFUND) {
  //     const response: NCPResponse<GetRefundsSearchConditions> = await this.$api.getRefundsSearchConditions({
  //       params: { mallNo: this.mallNo.toString() },
  //     });
  //     return response.data.types;
  //   }
  //
  //   const response: NCPResponse<GetClaimsSearchConditions> = await this.$api.getClaimsSearchConditions({
  //     params: { mallNo: Number(this.mallNo), claimType: this.claimType },
  //   });
  //   return response.data.types;
  // }

  // private setSearchTypeKeywordsOptions(types: GetClaimsSearchConditionsTypes[]): void {
  //   const options: OptionData<string>[] = types.reduce((acc, { label, type }) => {
  //     const searchKeywordTypeOption = {
  //       name: label,
  //       value: type,
  //     };
  //     return [...acc, searchKeywordTypeOption];
  //   }, []);
  //
  //   this.searchKeywordTypeOptionsSync = [...options];
  // }
}
