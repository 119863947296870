export default Object.freeze({
  // MEMBER_POPUP_
  MEMBER_NAME: '변경 대상회원',
  UPDATE_REASON: '변경 사유',
  REASON_HINT: '변경사유 입력',
  ALERT_REASON_NULL: '사유를 입력해 주세요.',
  ALERT_REASON_MAX: '글자수를 초과 하였습니다.',

  // Grade
  GRADE_TITLE: '회원등급 변경',
  MEBER_GRADE: '회원등급 선택',
  GRADE_HINT_1: '회원등급 혜택으로 설정 된 할인쿠폰이 발행 됩니다.',
  GRADE_HINT_2: '회원등급 수동변경은 다음 등급 변경일 전까지 유지됩니다.',
  ALERT_GRADE_NO_NULL: '회원 등급을 선택해 주세요.',
  GRADE_SUCCESS_MSG: '성공 {0}건: 회원등급 변경완료\r',
  GRADE_FAIL_MSG: '실패 {0}건: {1}',

  // Group
  GROUP_TITLE: '회원그룹 변경',
  MEBER_GROUP: '회원그룹 선택',
  UPDATE_TYPE: '추가/삭제 여부',
  ALERT_GROUP_NO_NULL: '회원 그룹을 선택해 주세요.',
  GROUP_SUCCESS_MSG: '성공 {0}건: 회원그룹 변경완료\r',

  // Accumulate
  ACCUMULATE_SETTING_TITLE: '적립금 지급/차감 설정',
  ACCUMULATE_MEMBER: '지급 대상자',
  ACCUMULATE_A_D: '지급/차감',
  ACCUMLATE_PAYMENTS: '지급',
  ACCUMLATE_DEDUC: '차감',
  ACCUMLATE_DEDUCTION: '차감 (지급취소)',
  ACCUMLATE_PRICE: '금액',
  AMOUNT_PLACEHOLDER: '금액 입력',
  REASON_PLACEHOLDER: '변경사유 입력',
  PAYMENT_REASON: '지급 사유',
  VALIDITY_PERIOD: '유효기간 설정',
  VALIDITY_PERIOD_DEFAULT: '기본 유효기간에 따름',
  VALIDITY_PERIOD_CUSTOM: '별도 유효기간 지정',
  PERIODYMD_PLACEHOLDER: '유효기간 종료일 선택',
  ALERT_AMOUNT_NULL: '{0} 금액을 입력하세요.',
  ALERT_AMOUNT_ZERO: '0 은 {0} 할 수 없습니다.',
  ALERT_REASON_DETAIL_NULL: '사유를 입력하세요.',
  ALERT_PERIOD_YMD_NULL: '별도 유효기간을 지정해 주세요.',
  ACCUMULATE_SUCCESS_MSG: '성공{0}건:{1} {2}완료\r',
  NOTICE_SETTING: '알림 설정',
  EMAIL: '이메일',
  NOTICE_SETTING_NO: '설정 안 함',
  NOTICE_SETTING_YES: '설정함',
  ACCUMULATE_REASON_GUIDE:
    '· 쇼핑몰 대상회원의 ‘마이페이지 > 적립금‘ 페이지에 입력한 지급/차감사유가 노출됩니다. 작성 시 유의하시길 바랍니다.',
  VALIDITY_PERIOD_GUIDE:
    '· 별도 유효기간을 지정하지 않을 경우, ‘회원 > 적립금 관리 > 적립금 설정 관리‘에서 설정한 쇼핑몰별 기본 유효기간에 따라 적립금이 지급됩니다.',
  NOTICE_SETTING_GUIDE:
    '· 알림 발송 시점의 수단별 상태에 따라 알림이 발송되지 않을 수 있습니다. \n' +
    '· 알림수단의 사용여부, 자동 발송항목의 사용여부 및 야간발송 제한 설정에 따라 알림이 발송되지 않을 수 있습니다.\n' +
    '· SMS 선택 시, SMS 포인트가 부족할 경우 부족한 포인트만큼 일부 또는 전체 회원을 대상으로 알림 발송에 실패할 수 있습니다.\n' +
    '· 회원별 수신동의여부에 따라 알림이 발송되지 않을 수 있습니다.',
  VALIDITY_PERIOD_DATE: '기간지정',
  EXPIRE_DATE_TIME: '만료일지정',
  VALIDITY_PERIOD_NONE: '제한없음',
  VALIDITY_PERIOD_DATE_TEXT1: '지급일 기준',
  VALIDITY_PERIOD_DATE_TEXT2: '까지 사용가능  (총 {0} 개월 )',
  VALIDITY_PERIOD_DATE_EXCEPTION: '적립금 유효기간은 최소 1개월 이상입니다.',
  NOTICE_ERROR_MSG: '현재 {0} 알림 발송이 불가합니다. ',
  NOTICE_ERROR_REASON_TITLE: '[설정 안내]',
  ACCUMULATE_AMOUNT_FAIL1: '금액을 입력해주세요.',
  ACCUMULATE_AMOUNT_FAIL2: '적립금은 10원 단위로 입력 가능합니다.',
  ACCUMULATE_AMOUNT_FAIL3: '10원 이상으로 입력해주세요.',
  ACCUMULATE_AMOUNT_FAIL4: '999,999,990원 이하로 입력해주세요.',
  ACCUMULATE_REASON_FAIL: '10원 이상으로 입력해주세요.',
  VALIDITY_PERIOD_FAIL: '유효기간 만료일을 입력해주세요.',
  ACCUMULATE_CONFIRM_SUCCESS: '저장하시겠습니까?',
  ACCUMULATE_REQUEST_SUCCESS:
    '적립금 지급/차감 설정이 완료되었습니다.\n' +
    '선택한 회원의 수가 많을 경우, 실제 지급/차감 시까지 시간이 다소 소요될 수 있습니다.\n' +
    '(지급 결과는 ‘적립금 지급/차감 관리‘ 메뉴에서 확인할 수 있습니다.) ',
  SELECT_MALL: '쇼핑몰을 선택하세요.',
  TARGET_MEMBER: '대상 회원',
  PAYMENT_DETAIL: '지급 상세',
  DEDUCT_DETAIL: '차감 상세',
  PAYMENT_TIME: '지급 시점',
  CANCEL_PAYMENT: '지급 취소',
  SELECT_VALID_EXPIRE_PERIOD: '유효기간 만료일 선택',
  PAYMENT_DATE_TOOLTIP:
    '지급 시점을 ‘예약지급‘으로 설정한 경우, 예약시점이 도래하기 전에 선택한 회원이 휴면회원 혹은 탈퇴회원으로 전환될 경우, \n해당 회원은 제외하고 적립금을 지급합니다.',
  TARGET_MEMBER_DESCRIPTION:
    '선택 후 대상 회원 중 일부가 휴면회원 및 탈퇴회원으로 전환된 경우 해당 회원은 발급 대상에서 제외됩니다.',
  EXCEL_BATCH_REGISTRATION: '엑셀 일괄등록',
  NUMBER_PLACEHOLDER: '숫자만 입력하세요.',
  ALERT_RESET_MEMBER: '쇼핑몰을 수정할 경우 일부 설정값이 초기화됩니다.\n수정하시겠습니까?',
  ALERT_SELECT_MALL: '쇼핑몰을 선택해주세요.',
  ALERT_RESET_DATE:
    '지급일을 수정할 경우 만료일 설정값이 초기화됩니다.\n수정하시겠습니까?\n(지급일은 만료일 이전으로만 설정 가능합니다.)',
  ALERT_AFTER_THIS_TIME: '예약지급 시점은 현재 시점 이후로만 설정 가능합니다.',
  ALERT_PAYMENT_TARGET: '지급대상을 선택해주세요.',
  ALERT_RESERVATION_AFTER_THIS_TIME: '예약지급 시점은 현재 시점 이후로만 설정 가능합니다.',
  ALERT_REASON: '사유를 입력해주세요.',
  ALERT_AMOUNT: '금액을 입력해주세요.',
  ALERT_VALID_EXPIRE_PERIOD: '유효기간 만료일을 입력해주세요.',
  ALERT_ALARM: '알림수단을 선택해주세요.',
  NOTIFICATION_HEADER_TEXT: '알림 발송 불가 안내',
  CONFIRM_PAYMENT_ACCUMULATION: '현재 알림 발송이 불가한 상태입니다.<br>적립금을 지급하시겠습니까?',

  // GiveCoupon
  GIVE_COUPON_TITLE: '쿠폰 지급 설정',
  SEARCH_TYPE: '검색어',
  SEARCH_TYPE_NAME: '쿠폰명',
  SEARCH_TYPE_NO: '쿠폰번호',
  TERM_ISSUE_START_YMD: '발행시작일',
  TERM_ISSUE_END_YMD: '발행종료일',
  TERM_REGISTER_YMD: '쿠폰등록일',
  ISSUE_TYPE: '쿠폰 발급 유형',
  ISSUE_TYPE_DOWNLOAD: '다운로드 발급',
  ISSUE_TYPE_CODE_DESIGNATE: '지정코드 발급',
  ISSUE_TYPE_CODE_RANDOM: '난수코드 발급',
  ISSUE_TYPE_INSERT: '인서트 발급',
  COUPON_TYPE: '혜택 구분',
  COUPON_TYPE_PRODUCT: '상품 금액 할인',
  COUPON_TYPE_CART: '장바구니 금액 할인',
  STATUS_TYPE_ISSUE_READY: '발급대기',
  STATUS_TYPE_ISSUE_ING: '발급 중',
  STATUS_TYPE_ISSUE_STOP: '발급중지',
  STATUS_TYPE_ISSUE_END: '발급종료',
  GIVE_COUPON_MEMBER: '지급 대상회원',
  GIVE_COUPON_MEMBER_BY_GRADE: '지급 대상 회원등급',
  REASON_PAYMENT: '지급사유',
  REASON_PAYMENT_PLACEHOLDER: '지급사유 입력',
  HEADER_COUPON_NO: '쿠폰번호',
  HEADER_STATUS_TYPE: '쿠폰상태',
  HEADER_COUPON_NAME: '쿠폰명',
  HEADER_COUPON_TYPE: '혜택구분',
  HEADER_ISSUE_TYPE: '발급유형',
  HEADER_ISSUE_START_YMDT: '발행 시작일',
  HEADER_ISSUE_END_YMDT: '발행 종료일',
  HEADER_REGISTER_ADMIN: '쿠폰 등록자',
  ERROR_SELECT_COUPON: '쿠폰을 선택해 주세요',
  ERROR_REASON_NULL: '지급사유를 입력하세요.',
  GIVE_COUPON_APPLY_CONFIRM: '할인쿠폰을 지급하시겠습니까?',
  GIVE_COUPON_CLOSE_CONFIRM: '쿠폰 지급을 취소하시겠습니까?',
  GIVE_COUPON_SUCCESS_MSG: '성공{0}건:할인쿠폰 지급완료\r',
  COUPON_ISSUE_TIME_TYPE: '지급 유형',
  COUPON_ISSUE_TIME_TYPE_TITLE1: '즉시지급',
  COUPON_ISSUE_TIME_TYPE_TITLE2: '예약지급',
  COUPON_ISSUE_TIME_TYPE_TITLE3: '자동지급',
  GIVE_COUPON_NOS_TITLE: '지급 쿠폰 선택',
  GIVE_COUPON_SEARCH: '쿠폰 조회',
  GIVE_COUPON_SELECT: '선택 쿠폰',
  DELETE: '삭제',
  GIVE_COUPON_SELECT_GUIDE:
    '· 선택 쿠폰 중 발급이 불가한 쿠폰은 삭선으로 표기됩니다. \n' +
    '· 자동지급 시, 발급 상태가 ‘발급중‘ 상태인 ‘자동발급’ 쿠폰만 지급 가능합니다.\n' +
    '· 즉시/예약지급 시, 전체 발급유형의 쿠폰 선택이 가능하며, ‘발급종료‘를 제외한 모든 상태의 쿠폰 발급이 가능합니다. ',

  // MemberGradeEvaluation
  EVALUATION_TITLE: '회원등급평가설정',
  EVALUATION_CONDITION_TYPE: '등급평가조건',
  EVALUATION_PERIOD: '평가기간',
  EVALUATION_PERIOD_SELECT: '기간선택',
  EVALUATION_PERIOD_HINT: '평가기간의 월말까지 조건기준으로 익월 등급조정 됩니다.',
  EVALUATION_VALID_PERIOD: '등급 유지기간',
  EVALUATION_DAY_OF_ADJUSTMENT: '등급 조정일',
  EVALUATION_DAY_EACH_MONTH: '매월',
  EVALUATION_DAY: '일',
  EVALUATION_DAY_OF_ADJUSTMENT_HINT: '설정한 등급 조정일 기준으로 회원혜택(적립금 및 쿠폰)이 지급됩니다.',
  EVALUATION_SAVE_CONFIRM: '변경 된 내용을 저장하시겠습니까?',
  EVALUATION_CLOSE_CONFIRM: '수정을 취소 하시겠습니까? 수정하신 내용은 저장되지 않습니다.',
  EVALUATION_OK_MSG1:
    '등급평가조건이 변경 되었습니다. 기존 등급평가조건으로 설정된 값에 문제 없는지 반드시 확인하시기 바랍니다.',
  EVALUATION_OK_MSG2: '변경사항이 저장 되었습니다.',

  ERR_INPUT_FORMAT: '입력형식이 잘못되었습니다.',

  // ProhibitMember
  HEADER_TITLE: '회원 이용정지',
  HEADER_TARGET_MEMBERS: '대상회원',
  HEADER_STOP_USING_DISTINCTION: '이용정지 구분',
  HEADER_SHUTDOWN: '정지기간',
  HEADER_STOPUSING_UNTIL_SPECIFIC_DATE: '특정날짜까지 이용정지',
  HEADER_PERMANENT_DISCONTINUATION_OF_USE: '영구이용 정지',
  HERDER_REASONS_FOR_DISCONTINUATION_OF_USE: '이용정지 사유',
  PLACE_HOLDER_ENTER_THE_REASON_TO_STOP_USING: '이용정지 사유 입력',
  HEADER_STOP_USING_NOTICE: '이용정지 알림',
  HEADER_CONTENT_OF_CUSTORMER_NOTICE: '고객알림 내용',
  PLACE_HOLDER_ENTER_CUSTOMER_NOTIFICATION_CONTENT: '고객 알림내용 입력',
  DATA_DISPLAY_STOP_USING: '이용정지',
  DATA_DISPLAY_RELIEVE_STOP: '이용정지 해제',
  DATA_DISPLAY_E_MAIL: 'e메일',
  DATA_DISPLAY_SEND_ALL: '모두 발송',
  PLACE_HOLDER_VALUE: '이용 정지 해제는 고객알림이 발송되지 않습니다.',
  ALERT_PLEASE_ENTER_THE_REASON_TO_STOP_USING: '이용정지 사유를 입력하세요.',
  ALERT_PLEASE_ENTER_THE_CONTENT_OF_CUSTOMER_NOTIFICATION: '고객 알림 내용을 입력하세요.',
  ALERT_ARE_YOU_SURE_YOU_WANT_TO_STOP_USING_IT: '이용정지를 진행하시겠습니까?',

  // WithdrawalMember
  WITHDRAWAL_REASON: '강제탈퇴 사유를 입력해주세요.',
  WITHDRAWAL_SUCCESS_MSG: '성공 {0}건:강제탈퇴 완료\r',
  WITHDRAWAL_FAIL_MSG: '실패 {0}건: {1}',
  CONFIRM_WITHDRAWAL:
    '선택하신 회원을 강제탈퇴 처리하시겠습니까?\n간편로그인 회원인 경우 탈퇴 철회 및 재가입 불가/해제 처리가 불가합니다.',
  ALERT_HAS_BENEFIT:
    '사용가능한 쿠폰/적립금을 보유중인 회원이 포함되어있습니다. 탈퇴처리 시 보유중인 회원혜택은 모두 삭제 처리됩니다.\n선택한 회원을 탈퇴처리하시겠습니까?',
  ALERT_HAS_ORDER:
    '진행중인 주문건이 포함되어 있습니다.\n' +
    '탈퇴처리 시 해당 회원은 쇼핑몰에 접속 불가하며, 관리자에서 주문내역은 확인 가능합니다.\n' +
    '선택한 회원을 탈퇴처리하시겠습니까?',

  //ExcelBatchRegistration
  EXCEL_BATCH_REGISTRATION_TITLE: '대상 회원 일괄등록',
  UPLOAD_FORM: '업로드 양식',
  BATCH_REGISTRATION_SAMPLE_DOWNLOAD: '일괄등록 샘플 다운로드',
  EXCEL_UPLOAD: '엑셀 업로드',
  EXCEL_BATCH_REGISTRATION_NOTICE_1: '일괄등록용 엑셀 다운로드 후 지급할 대상 회원의 회원번호를 입력 후 업로드하세요.',
  EXCEL_BATCH_REGISTRATION_NOTICE_2:
    '일괄등록용 엑셀 다운로드 파일에서 항목을 변경하는 경우 파일이 정상적으로 업로드되지 않습니다.',
  EXCEL_BATCH_REGISTRATION_NOTICE_3: '선택한 쇼핑몰의 가입완료 회원만 발급 대상 회원으로 선택 가능합니다.',
  EXCEL_BATCH_REGISTRATION_NOTICE_4: '일괄 등록 시 최대 1만건 까지 등록가능합니다.',
  WRONG_FILE_FORMAT: '잘못된 형식의 파일입니다.',
  NO_DATA: '엑셀파일을 등록해주세요.',
  INVALID_EXCEL_FILE: '엑셀 서식을 다시 확인해주세요.',
  MAXIMUM_LENGTH_TEN_THOUSAND: '1회 업로드시 최대 1만개까지 입력 가능 합니다.',
  MAXIMUM_FILE_SIZE_FIVE_MEGA_BYTE: '파일 크기는 5MB 이내로 첨부 가능합니다.',
  BATCH_REGISTRATION_EXCEL_SAMPLE: '대상 회원 일괄업로드 양식_{todayDate}.xlsx',
  SAVE_STATUS_ALERT: '저장되었습니다.\n 성공: {successCount}건, 실패: {failCount}건',
  MEMBER_NO: '회원번호',

  // common
  OPEN: '펼치기',
  CLOSE: '접기',
  COUNT: '명',
  PRICE_WON: '원',
});
