




import { Vue, Component, PropSync } from 'vue-property-decorator';
// components
import MallSelect from '@/components/common/input/MallSelect.vue';

@Component({
  components: { MallSelect },
})
export default class PartnerMallSelectBox extends Vue {
  @PropSync('selectedMallNo')
  private selectedMallNoSync!: string;

  @PropSync('entryContractContent')
  private entryContractContentSync!: string;

  private async setEntryContractContentByMallConfiguration(mallNo: string) {
    const { data } = await this.$api.getMallsConfigurations({
      pathParams: { mallNo },
      params: { key: 'PARTNER_CONTRACT' },
    });

    this.entryContractContentSync = data?.entryContractContentsOfMall ?? '';
  }
}
