





























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { IconDetails } from '@/types';
@Component
export default class IconsList extends Vue {
  @PropSync('exposureIcons', { required: true })
  private exposureIconsSync: IconDetails;

  @Prop({ required: true })
  private readonly mallNo: number;

  @Prop({ required: true })
  private readonly id: string;

  @Prop({ required: true })
  private readonly fetchedIcons: IconDetails[];

  @Prop({ required: true })
  private readonly disabled: boolean;

  private get isChosenMall(): boolean {
    return !!this.mallNo;
  }

  private getDisabledState(disabledType: string): boolean {
    return disabledType !== null && disabledType !== this.id;
  }

  private i18nForProduct = i18nForProduct;
  private checked(idx, { target }: { target: HTMLInputElement }) {
    this.$emit('checked', { idx, checked: target.checked });
  }
}
