















import { Component, Vue, Prop, Model, Watch, Ref } from 'vue-property-decorator';
import MultiCategoriesSelector, {
  MultiCategorySelectorData,
  MultiCategorySelectorOptions,
  SearchMode,
} from '@/components/common/categories/MultiCategoriesSelector.vue';
import {
  GetDisplayCategoriesTreeRequest,
  DisplayCategoriesView,
  NCPResponse,
  GetDisplayCategoriesRequest,
  DisplayCategoriesTree,
} from 'ncp-api-supporter';

const options: MultiCategorySelectorOptions = {
  selectMode: 'single',
  keywordKeys: {
    uniqueKey: 'depth5DisplayCategoryNo',
    fullNameKey: 'fullCategoryName',
  },
  selectKeys: {
    uniqueKey: 'displayCategoryNo',
    fullNameKey: 'fullCategoryName',
    nameKey: 'displayCategoryName',
    childKey: 'children',
  },
} as const;

@Component({
  components: {
    MultiCategoriesSelector,
  },
})
export default class MultiDisplayCategoriesSelector extends Vue {
  @Model('change') readonly value: number[];
  @Prop({ required: true }) mallNo!: number;
  @Prop({ default: '' })
  private readonly className: string;
  @Prop({ default: false })
  private readonly disabled!: boolean;
  @Prop({ default: 'single' })
  private readonly selectMode!: 'single' | 'multiple';

  @Ref() selector!: MultiCategoriesSelector;

  private data: MultiCategorySelectorData = {
    keywordFindData: [],
    selectFindData: [],
  };
  private readonly options: MultiCategorySelectorOptions = options;

  created() {
    this.options.selectMode = this.selectMode;

    if (!this.mallNo) return;
    this.fetchKeywordFindData(this.mallNo);
  }

  get noQuery(): boolean {
    return this.mallNo !== 0;
  }

  @Watch('mallNo')
  changedMallNo(mallNo: number) {
    this.reset();
    this.fetchKeywordFindData(mallNo);
  }

  private reset() {
    this.data = {
      keywordFindData: [],
      selectFindData: [],
    };
    this.selector.reset();
  }

  private async fetchKeywordFindData(mallNo: number): Promise<void> {
    const request: GetDisplayCategoriesRequest = {
      params: {
        mallNo,
      },
    };

    const { data }: NCPResponse<DisplayCategoriesView[]> = await this.$api.getDisplayCategoriesViews(request);
    this.data.keywordFindData = data;
  }

  private async fetchSelectFindData(mallNo: number): Promise<void> {
    const request: GetDisplayCategoriesTreeRequest = {
      params: {
        mallNo,
      },
    };

    const { data }: NCPResponse<DisplayCategoriesTree[]> = await this.$api.getDisplayCategoriesTree(request);
    this.data.selectFindData = data;
  }

  private noDataEvent(type: SearchMode): void {
    if (type === 'keyword') {
      if (!this.mallNo) {
        this.$emit('error:no-mall');
        return;
      }
      this.$emit('error:no-data');
    }
    if (type === 'select') {
      if (this.mallNo) this.fetchSelectFindData(this.mallNo);
    }
  }

  public focus(): void {
    this.selector.focus();
  }
}
