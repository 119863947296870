export default Object.freeze({
  HEADER_ORDER_STATUS_TYPE_THREE: '구분',
  HEADER_OTHER_STATUS_TYPE_LABEL: '결제실패',
  HEADER_OTHER_STATUS_TYPE_LABEL_ELSE: '결제포기',
  HEADER_ORDER_STATUS_TYPE: '주문상태',
  HEADER_CLAIM_STATUS_TYPE: '클레임상태',
  HEADER_HOLD_DELIVERY_YN: '배송보류',
  HEADER_DEPOSIT_WAIT_PROC_BTN: '입금확인 처리',
  HEADER_PAY_DONE_PROC_BTN: '상품준비중처리',
  HEADER_PRODUCT_PREPARE_PROC_BTN: '배송준비중처리',
  HEADER_DELIVERY_PREPARE_PROC_BTN: '배송중처리',
  HEADER_DELIVERING_PROC_BTN: '배송완료처리',
  HEADER_DELIVERY_DONE_PROC_BTN: '구매확정처리',
  PLATFORM_TYPE_PC: 'PC',
  PLATFORM_TYPE_MOBILE_WEB: '모바일웹',
  PLATFORM_TYPE_MOBILE_APP: '모바일앱',
  SHIPPING_AREA_TYPE_PARTNER_SHIPPING_AREA: '파트너사 배송',
  SHIPPING_AREA_TYPE_MALL_SHIPPING_AREA: '쇼핑몰 배송',
  ADD: '추가',
  DELIVERY_NUIT: '묶음',
  SHARE: '나눔',
  DELIVERY_AMT_TYPE_ARRIVE: '착불',
  DELIVERY_AMT_TYPE_BEFORE: '선결제',
  DELIVERY_PROC_BTN: '출고',
  PROC_EACH_BTN_OUT: '출고준비',
  PROC_EACH_BTN_ORDER: '주문접수',
  DELIVERY_NONE_BTN: '배송완료',
  PAY_DONE_BTN: '입금확인',
  PRODUCT_PREPARE_BTN: '상품준비중',
  DELIVERY_PREPARE_BTN: '배송준비중',
  DELIVERING_BTN: '배송중',
  DELIVERY_DONE_BTN: '배송완료',
  BUY_CONFIRM_BTN: '구매확정',
  WITHDRAWAL_MEMBER: '탈퇴회원',
  DELIVERY_ING: '배송중일자',
  DELIVERY_HOLD_DATE: '배송보류일자',
  RESERVATION_START_YMDT: '예약판매시작일',
  DELIVERY_COMPLETION_DATE: '배송완료일자',
  DELIVERY_PREPARING_DATE: '배송준비중일자',
  PRODUCT_PREPARING_DATE: '상품준비중일자',
  PAYMENT_DATE: '결제일자',
  PURCHASE_CONFIRMATION_DATE: '구매확정일자',
  PREPARATION_DATE: '구매확정일자',
  ORDER_DATE: '주문일자',
});
