


























import { Vue, Component, PropSync, Ref, Prop } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
@Component({
  components: { ToolTip, TextInput },
})
export default class ProductName extends Vue {
  @PropSync('productName', { required: true })
  private productNameSync: string;

  @PropSync('productNameModel', { required: true })
  private productNameModelSync: string;

  @Prop({ required: true })
  private readonly disabled: boolean;

  @Ref()
  private readonly productNameRef: TextInput;
  public focus(): void {
    this.productNameRef.focus();
  }

  public reset(): void {
    this.productNameModelSync = '';
  }

  // private proNameSync = '';
  private onChangedProductName(val: string): string {
    this.productNameSync = val;
    // this.productNameSync = escapeHtml(val);
    return val;
  }
}
