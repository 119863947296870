export default Object.freeze({
  TITLE: '상품이미지 일괄 관리',
  UPLOAD_TITLE: '상품이미지 업로드',
  IMAGE_NOTICE_TITLE: '상품이미지 일괄 등록 가이드',
  IMAGE_NOTICE_1: '① 상품이미지를 등록할 쇼핑몰을 선택합니다.',
  IMAGE_NOTICE_2: '② [엑셀 양식 다운로드]를 클릭하여 업로드 양식을 다운로드합니다.',
  IMAGE_NOTICE_3: '③ 엑셀 업로드 전 상품 이미지 파일을 먼저 업로드합니다.',
  IMAGE_NOTICE_3_1: '- 상품 이미지는 1회 업로드 시 최대 1500개까지 등록 가능합니다.',
  IMAGE_NOTICE_3_2:
    '- 등록 가능한 이미지 개수 : 대표 이미지 1개, 리스트이미지 1개, 추가이미지 9개, 상품상세 (상단) 9개, 상품상세 9개, 상품상세(하단) 9개',
  IMAGE_NOTICE_3_3:
    '- 외부 이미지 저장소에 등록된 이미지를 사용하고자 하실 경우, 엑셀 업로드 시 상품이미지 정보에 외부 이미지 경로를 입력하여 이미지를 저장하실 수 있습니다.',
  IMAGE_NOTICE_4: '④ ② 에서 다운로드 받은 양식에 등록할 상품 이미지의 정보를 입력합니다.',
  IMAGE_NOTICE_4_1:
    '- 엑셀 업로드 시 엑셀 양식 > 상품 이미지 항목에서 종류별 이미지(상품 대표이미지, 리스트이미지, 추가이미지, 상세(상단)이미지, 상세이미지, 상세(하단)이미지)',
  IMAGE_NOTICE_4_2: '추가 시 등록 가능한 개수를 초과한 경우 먼저 입력된 파일이 최종 반영되어 성공처리 됩니다.',
  IMAGE_NOTICE_5: '⑤ 상품 업로드 시 성공/실패 여부를 확인할 수 있습니다.',
  IMAGE_NOTICE_5_1: '- 엑셀 업로드 완료 후 등록 실패한 상품이 1건 이상일 경우, 실패 결과 파일이 다운로드 됩니다.',
  MALL_SELECTION_MESSAGE: '쇼핑몰 선택 후 양식을 다운로드하세요',
  TOOL_TIP_MESSAGE:
    '엑셀 업로드 시 이미지 항목별로 등록 가능한 개수를 초과하여 이미지 정보를 입력한 경우, 먼저 입력된 이미지 정보만 정상등록 처리됩니다.<br>예시) 이미지 종류 중 상품 대표이미지(main)의 이미지를 해당 예시 mainimage1.png, main^^image2.png 2개 등록한 경' +
    '우<br>→ main^\\^image1.png : 성공처리<br>→ main^/^image2.png : 실패처리<br>',
  IMAGE_TOOL_TIP_MESSAGE:
    '상품이미지 대량등록은 개별 파일, 폴더별로 업로드가 가능하며, zip파일은 업로드가 불가합니다.<br>지원하는 파일 형식: JPG, JPEG, GIF, PNG, BMP',
  SAMPLE_EXCEL_NAME: '상품이미지일괄관리_업로드양식',
  FAIL_EXCEL_NAME: '상품이미지일괄관리_실패결과',

  //그리드
  NO: 'No',
  TOTAL_COUNT: '총 이미지 등록 수',
  SUCCESS_COUNT: '이미지 등록 성공건수',
  FAILURE_COUNT: '이미지 등록 실패건수',
  HELP_FAILURE_COUNT:
    '이미지 등록 실패건수가 1건 이상일 경우, 실패 결과 파일이 다운로드 됩니다.<br>- 실패 결과 파일에서 실패된 상품의 이미지 정보를 확인해주세요.<br> (부분 실패의 경우 실패된 이미지는 별도로 표기되지 않으며, 구분된 셀 색상 정보로 확인해주세요.)',
  NO_DATA: '등록된 상품이 없습니다.',
  GRID_TITLE: '등록 성공 상품 목록',
  CASE: '건 중',
  UPLOAD_COMPLETE: '총 {0} 건 중 {1}건 등록 완료',
  TOTAL: '총',

  //이미지 등록 팝업
  IMAGE_UPLOAD_TITLE: '이미지 등록',
  IMAGE_UPLOAD_MESSAGE: '이미지를 먼저 등록해주세요.',

  // Upload Form
  TITLE_BULK_PRODUCT: '상품 업로드',
  UPLOAD_FORM: '업로드 양식',
  TITLE_BULK_IMAGE: '상품이미지 업로드',
  EXCEL_UPLOAD_FAILURE_MSG_BULK_PRODUCT: '대량 상품 등록 양식이 아닙니다.',
  EXCEL_UPLOAD_FAILURE_MSG_BULK_IMAGE: '상품이미지 일괄 등록 양식이 아닙니다.',
  UPLOAD_POPUP_TITLE_BULK_IMAGE: '상품이미지 일괄 등록 결과',
  UPLOAD_POPUP_TITLE_BULK_PRODUCT: '대량상품 등록 결과',
  UPLOAD_POPUP_TITLE_PRODUCT: '상품 일괄 등록 결과',
});
