// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UNIT_TYPE } from '@/types';
import { i18n } from '@/main';
import axios from 'axios';
import { api } from '@/api';
/**
 * Type 'string | undefined' is not assignable to type 'string'.
 * so judge the type transform string.
 * @author AZu
 * @param needToI18nStr string of need to i18n
 */
export function toI18n(needToI18nStr): string {
  if (typeof needToI18nStr === 'string') {
    return needToI18nStr;
  } else {
    return '';
  }
}

/**
 * Get center popup options.
 * @author AZu
 * @param w the window.open width
 * @param h the window.open heigth
 * @param sScroll is show scroll bar :"yes" or no
 */
export function centerWinOptions(w: number, h: number, sScroll = 'no'): string {
  const x = (window.screenX || window.screenLeft || 0) + (screen.width - w) / 2;
  const y = (window.screenY || window.screenTop || 0) + (screen.height - h) / 2;

  let sOption = '';
  sOption += 'toolbar=no, channelmode=no, location=no, directories=no, resizable=yes, menubar=no';
  sOption += ', scrollbars=' + sScroll + ', left=' + x + ', top=' + y + ', width=' + w + ', height=' + h;
  return sOption;
}

/**
 * Center a popup window on screen.
 * @author AZu
 * @param sURL  need to open url
 * @param sWindowName the name of window.open
 * @param w the window.open width
 * @param h the window.open height
 * @param sScroll is show scroll bar :"yes" or no
 */
export function openWin(sURL: string, sWindowName: string, w: number, h: number, sScroll = 'no') {
  const win = window.open(sURL, sWindowName, centerWinOptions(w, h, sScroll));
  if (win) win.focus();
  return win;
}

const unescapedHtmlRegex = /[&<>"' ]/g;
const escapedHtmlRegex = /&amp;|&lt;|&gt;|&quot;|&#39;|&nbsp;/g;

const htmlMap = new Map<string, string>([
  ['&', '&amp;'],
  ['<', '&lt;'],
  ['>', '&gt;'],
  ['"', '&quot;'],
  ["'", '&#39;'],
  [' ', '&nbsp;'],

  ['&amp;', '&'],
  ['&lt;', '<'],
  ['&gt;', '>'],
  ['&quot;', '"'],
  ['&#39;', "'"],
  ['&nbsp;', ' '],
]);

export function escapeHtml(html: string): string {
  return html.replace(unescapedHtmlRegex, (substring: string): string => htmlMap.get(substring) || '');
}

export function unescapeHtml(html: string): string {
  return html?.replace(escapedHtmlRegex, (substring: string): string => htmlMap.get(substring) || '');
}

export function replaceNextLineContent(content?: string) {
  return content?.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

/**
 * ref eg: src\views\product\ProductList.vue
 * @author AZu
 */
export function getCurrency(value, usePoint = false): string {
  if (value === '') {
    return '';
  }
  if (value == null || isNaN(value)) {
    if (usePoint) return '0.00';
    return '0';
  }

  let retVal = '';
  if (usePoint) {
    const parts = parseFloat(value)
      .toFixed(2)
      .toString()
      .split('.');
    if (parts.length == 2) {
      retVal = `${parts[0].replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}.${parts[1]}`;
    } else {
      retVal = '0';
    }
  } else {
    retVal = parseInt(value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return retVal;
}
/**
 * image upload
 * @author AZu
 * @param files Input's files when input on changed.
 * @param mallNo
 */
export function uploadFile(files: any, mallNo: number): any {
  return new Promise((resolve: any, reject: any): void => {
    axios
      .all(
        Object.values(files).map((file: any): any => {
          const data = new FormData();
          data.append('file', file);
          return api.postImages({ data, params: { mallNo } });
        }),
      )
      .then(
        axios.spread((...responses): void => {
          resolve(responses);
        }),
      )
      .catch((err: any): void => {
        reject(err);
      });
  });
}
/**
 * Check if a string is HTML
 * @author AZu
 * @param str
 */
export function isHTML(str: string): boolean {
  const a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }

  return false;
}

/**
 * productNameConvertor
 * @author AZu
 */
export function productNameConvertor(columnValue, row): string {
  const productName = columnValue;
  let extraTag = '';
  if (row['optionType'] === 'MAPPING') {
    extraTag = extraTag.concat('<span class="tag pink">' + window.$t('PRODUCT.SET') + '</span>'); //세트
  }

  const TAG = {
    pcYn: '<span class="tag">PC</span>',
    mobileWebYn: '<span class="tag">MW</span>',
  };

  const targetPlatforms = ['pcYn', 'mobileWebYn']
    .filter(v => {
      return row.platformDisplayInfo[v] === 'Y';
    })
    .map(v => {
      return v;
    });
  if (targetPlatforms.length > 1) {
    return extraTag ? `<div class="uio_bx">${extraTag}</div> ${productName}` : productName;
  }

  const tag = targetPlatforms.map((v: 'pcYn' | 'mobileYn') => {
    return TAG[v];
  });
  const value = `<div class="uio_bx">${extraTag}${tag.join('')}</div> ${productName}`;

  return value;
}
/**
 * getStockCntColumnValue
 * @author AZu
 */
export function getStockCntColumnValue(
  representStockCnt: number,
  stockCnt: number,
  optionType: string,
  isSoldOut: boolean,
): string {
  const representCnt = representStockCnt;
  const isCombinationOption = optionType === 'COMBINATION';

  if (isSoldOut) {
    if (stockCnt === 0) {
      return window.$t('PRODUCT_PRODUCTLIST_SOLD_OUT'); //품절
    }
    return isCombinationOption ? `${window.$t('PRODUCT_PRODUCTLIST_SOLD_OUT')} (${stockCnt})` : `${stockCnt}`; //품절
  }

  return isCombinationOption ? `${representCnt} (${stockCnt})` : `${stockCnt}`;
}

export const range1ToNI18n = (size: number, word = ''): string[] => {
  return Array.from({ length: size }, (_, i) => i + 1).map(idx => `${word}${idx}`);
};

//NOTE: 01022223333 -> 010-2222-3333
export const regxPhoneNo = (phoneNo: string): string => {
  const pattern = /^(\d{2,3})(\d{3,4})(\d{4})$/;

  if (pattern.test(phoneNo)) {
    return phoneNo.replace(pattern, '$1-$2-$3');
  }
  return phoneNo;
};

export const masking = (str: string, start = 0) => {
  if (!str) {
    return str;
  }

  let newStr = '';
  for (let i = 0; i < str.length; i++) {
    if (i < start) {
      newStr += str[i];
    } else {
      newStr += '*';
    }
  }
  return newStr;
};

// center text masking
// ex) sdj -> s*j
export const maskingName = name => {
  if (!name) {
    return name;
  }

  if (name.length > 2) {
    let newName = '';
    for (let i = 0; i < name.length; i++) {
      if (i === 0 || i === name.length - 1) {
        newName += name[i];
      } else {
        newName += '*';
      }
    }
    return newName;
  } else if (name.length == 2) {
    return name.replace(/.$/, '*');
  } else {
    return name;
  }
};

// email domain masking
// ex) dj.son@nhn-commerce.com -> dj.son@**********
export const maskingEmail = email => {
  if (!email) {
    return email;
  }

  const arr = email.split('@');
  const domain = arr[1];

  if (!domain) {
    return email;
  }

  let newEmail = arr[0] + '@';

  for (let i = 0; i < domain.length; i++) {
    newEmail += '*';
  }

  return newEmail;
};

// phone number masking
// ex) 01012341234 -> 010-**34-**34
// ex) 0111231234 -> 010-**3-**34
export const maskingPhoneNo = (phoneNo: string): string => {
  const pattern = /^(\d{3})\d{2}(\d{1,2})\d{2}(\d{2})$/;

  if (pattern.test(phoneNo)) {
    return phoneNo.replace(pattern, '$1-**$2-**$3');
  }
  return phoneNo.replace(/\d/g, '*');
};

// 임시비밀번호, 인증번호 masking
// ex) 임시비밀번호 : 123456 -> 임시비밀번호 : ******
export const maskingAuthNo = (message: string): string => {
  const existAuthNo = (text: string) =>
    text.includes(i18n.t('TEMP_PASSWORD').toString()) || text.includes(i18n.t('AUTH_NUMBER').toString());
  if (!existAuthNo(message)) return message;

  const authList = message.split(':').map(auth => {
    return existAuthNo(auth) ? auth : auth.replaceAll(/[^\s]/g, '*');
  });
  return authList.join(':');
};

// Compose the originObj only with the keys (Operates like 'pick' of 'underscore')
export function pick<OriginObjectType, MappedObjectType>(
  originObj: OriginObjectType,
  keys: string[],
): MappedObjectType {
  return keys.reduce((mappedObj, key) => {
    if (originObj && originObj[key]) {
      const originValue = originObj[key];
      if (Object.prototype.toString.call(originValue).includes('object Object')) {
        mappedObj[key] = { ...originValue };
      } else {
        mappedObj[key] = originValue;
      }
    }
    return mappedObj;
  }, {} as MappedObjectType);
}

export enum BROWSER {
  CHROME = 'CHROME',
  SAFARI = 'SAFARI',
  IE = 'IE',
  FIREFOX = 'FIREFOX',
  OPERA = 'OPERA',
}
export function getBrowser(): string {
  const agent = navigator.userAgent.toLowerCase();

  if (agent.indexOf('trident') > -1) {
    return BROWSER.IE;
  } else if (agent.indexOf('chrome') > -1) {
    return BROWSER.CHROME;
  } else if (agent.indexOf('safari') > -1) {
    return BROWSER.SAFARI;
  } else if (agent.indexOf('firefox') > -1) {
    return BROWSER.FIREFOX;
  } else if (agent.indexOf('opera') > -1) {
    return BROWSER.OPERA;
  }

  return '';
}

export function getEncodeStr(data: number | string): string {
  if (data === undefined) return '';
  return btoa(data.toString()).replaceAll('+/=', '-_,');
}

export function splitStringBy(value: string, separator = '.'): string[] {
  return value?.includes(separator) ? value.split(separator) : [value];
}

export function lastInArray<ValueType>(values: ValueType[]): ValueType {
  return values[values.length - 1];
}

export function errorCode(code: string, separator = '.'): string {
  if (!code) return '';
  return lastInArray<string>(splitStringBy(code, separator));
}

export function uniqBy<Data>(data: Data[], key: keyof Data): Data[] {
  return data.reduce((acc: Data[], curr: Data) => {
    if (!acc.some(d => d[key] === curr[key])) {
      acc.push(curr);
    }
    return acc;
  }, []);
}
