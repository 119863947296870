export default Object.freeze({
  IS_MEMBER: '회원구분',
  MEMBER: '회원',
  NON_MEMBER: '비회원',

  IS_INVOICE_REGISTERED: '송장번호 등록여부',
  INVOICE_REGISTERED: '송장번호 등록함',
  NON_INVOICE_REGISTERED: '송장번호 등록 안 함',

  NORMAL_ORDER: '정상주문',
  CLAIM_ORDER: '클레임주문',
  ALL_ORDER: '주문통합',
  ORDER_NO: '주문번호',
  ORDERER_NAME: '주문자명',
  SORT_TYPE_DELIVER_NO_ASC: '배송번호 ↑',
  SORT_TYPE_RECEIVER_NAME_ASC: '수령자명 ↑',
  SORT_TYPE_RECEIVER_NAME_DESC: '수령자명 ↓',
  SORT_TYPE_ORDER_NO_ASC: '주문번호 ↑',
  SORT_TYPE_PAY_ORDER_AMT_ASC: '총 결제금액 ↑',
  SORT_TYPE_PAY_ORDER_AMT_DESC: '총 결제금액 ↓',
  SORT_TYPE_ORDERER_NAME_ASC: '주문자명 ↑',
  SORT_TYPE_ORDERER_NAME_DESC: '주문자명 ↓',
  SORT_TYPE_DEFAULT: '정렬기준',
});
