import pgTypes from '@/const/words/pgTypes.ts';
import { PgType } from '@/types/order';
import { i18n } from '@/main';
import { ClaimOrderOption, GetClaimsNo, ReasonType } from 'ncp-api-supporter';
import { ClaimOption } from 'ncp-api-supporter/dist/types/modules/claim/claims';
import { RefundType, ResponsibleObjectType } from 'ncp-api-supporter/dist/types/enum';

export const getClaimOrderOptions = (claimedOptions: ClaimOption[]): ClaimOrderOption[] => {
  if (!claimedOptions) {
    return [];
  }

  return claimedOptions.map(({ reasonType, reasonDetail, orderOptionNo }) => ({
    reasonType,
    reasonDetail,
    orderOptionNo,
  }));
};

export const generateRefundTypeSelectList = (adjustedAmounts, pgType: PgType, refundPayTypeLabel: string) => {
  const refundSelectList = [];

  // 환불금액이 없으면 ZERO_REFUND 추가
  if (adjustedAmounts.claimAmt === 0) {
    refundSelectList.push({ value: 'ZERO_REFUND', name: i18n.t('0원 결제') });
  }
  // 환불금액이 적립금반환만 있는 경우
  if (adjustedAmounts.mainPayAmt === 0 && adjustedAmounts.accumulationPayAmt > 0) {
    refundSelectList.push({ value: 'ACCUMULATION', name: i18n.t('적립금 복원') });
  }
  // 카드취소/현금환불액이 있는 경우
  if (adjustedAmounts.mainPayAmt > 0) {
    // 무통장은 무조건 추가
    refundSelectList.push({ value: 'ACCOUNT', name: i18n.t('현금환불') });

    // PG를 사용한 경우 PG의 라벨 - [payTypeLabel]
    if (pgType != 'NONE') {
      const selectedPgType = pgTypes.find(pgTypeItem => pgTypeItem.value === pgType);
      refundSelectList.push({ value: 'PG', name: `${selectedPgType.label}[${refundPayTypeLabel}]` });
    }
  }

  return refundSelectList;
};

export const getDefaultForm = (claimNoResponse: GetClaimsNo = null) => {
  return {
    claimReasonType: (claimNoResponse?.claimReasonType as ReasonType) ?? null,
    claimReasonDetail: claimNoResponse?.claimReasonDetail ?? '',
    refundAdjustAmt: claimNoResponse?.refundAdjustAmt?.toString() ?? '0',
    refundAdjustReason: claimNoResponse?.refundAdjustReason ?? '',
    returnWayType: claimNoResponse?.returnWayType ?? null,
    refundType: (claimNoResponse?.displayRefundType.refundType as RefundType) ?? null,
    responsibleObjectType: (claimNoResponse?.responsibleObjectType as ResponsibleObjectType) ?? null,
    sellerPaysClaimedDelivery: claimNoResponse?.sellerPaysClaimedDelivery ?? false,
    returnDeliveryAdjustAmt: claimNoResponse?.returnDeliveryAdjustAmt ?? 0,
    returnDeliveryAmtAdjustReason: claimNoResponse?.returnDeliveryAmtAdjustReason ?? '',
    additionalPayType: claimNoResponse?.additionalPayType ?? null,
    additionalPayRemitter: claimNoResponse?.additionalPayRemitter || claimNoResponse?.ordererName || '',
    additionalPayBankAccount: claimNoResponse?.additionalPayBankAccount ?? null,
    returnDeliveryProposedAmt: claimNoResponse?.returnDeliveryAdjustAmt ?? 0,
    returnDeliveryProposedReason: claimNoResponse?.returnDeliveryAmtAdjustReason ?? '',
    claimOrderOptions: getClaimOrderOptions(claimNoResponse?.claimedOptions),
    refundBankAccount: {
      bank: claimNoResponse?.refundBankAccount?.bank ?? 'KDB',
      account: claimNoResponse?.refundBankAccount?.account ?? '',
      bankName: claimNoResponse?.refundBankAccount?.bankName ?? null,
      depositorName: claimNoResponse?.refundBankAccount?.depositorName ?? '',
    },
    returnDelivery: {
      invoiceNo: claimNoResponse?.returnDelivery?.invoiceNo ?? null,
      deliveryCompanyType: claimNoResponse?.returnDelivery?.deliveryCompanyType ?? '',
      evadesReturnProcess: claimNoResponse?.returnDelivery?.evadesReturnProcess ?? false,
    },
  };
};

export const getDefaultAcceptForm = (claimNoResponse: GetClaimsNo) => {
  const { returnDelivery } = claimNoResponse;

  return {
    deliveryCompanyType: returnDelivery?.deliveryCompanyType ?? '',
    invoiceNo: returnDelivery?.invoiceNo ?? '',
    evadesReturnProcess: returnDelivery?.evadesReturnProcess ?? false, // TODO:  무적반품 };
  };
};
