import { Location } from 'vue-router';

type QueryString = Location['query'];

interface ConvertParameters {
  [key: string]: number | string | string[];
}

export const getParametersByQuery = <T>(query: QueryString, typeParameter: T): ConvertParameters => {
  const result = Object.keys(typeParameter)
    .filter(key => query[key] === '' || !!query[key])
    .map(key => {
      const isNumber = typeof typeParameter[key] === 'number';
      if (isNumber)
        return {
          [key]: Number(query[key]),
        };

      const isArray = Array.isArray(typeParameter[key]);
      if (isArray) {
        const isArrayLiked = query[key].includes(',');
        return {
          [key]: isArrayLiked ? query[key].toString().split(',') : query[key].toString(),
        };
      }

      // is other format : string 으로 처리
      return {
        [key]: query[key].toString(),
      };
    });

  return Object.assign({}, ...result);
};
