export default Object.freeze({
  TITLE: '기본정보',
  SERVICE_INFO: '서비스 기본정보',
  COMPANY_NAME: '회사명',
  COMPANY_REG_NUMBER: '사업자등록번호',
  COMPANY_REG_NUMBER_CONFIRM_LINK: '사업자 정보 확인 링크',
  COMPANY_REG_NUMBER_CONFIRM_LINK_INFO:
    '공정거래위원회의 통신판매사업자 정보 공개 페이지로 이동할 수 있는 링크를 입력합니다.\n' +
    '별도로 입력하지 않는 경우 사업자등록번호 자동 적용됩니다.',
  REPRESENTATIVE_NAME: '대표자명',
  SECTORS: '업종',
  BUSINESS: '업태',
  REPRESENTATIVE_PHONE_NUMBER: '대표전화번호',
  FAX_NUMBER: '팩스번호',
  ONLY_NUMBERS: '숫자만 입력 가능',
  REPRESENTATIVE_EMAIL: '대표이메일',
  MAIL_ORDER_REPORT_NUMBER: '통신판매업 신고번호',
  ADDRESS: '사업장 주소',
  FIND_POSTAL_CODE: '우편번호 찾기',
  CONNECTION_IP_SETTING: '접속 IP 설정',
  IP_ADDRESS_REGISTRATION: 'IP 주소 등록',
  PERSONAL_INFORMATION: '개인정보보호책임자',
  CONTACT_NAME: '담당자명',
  OFFICER_NAME: '보호책임자 이름',
  OFFICER_NAME_INFO:
    '쇼핑몰 하단 개인정보처리담당자로 출력됩니다.\n' + '전자상거래법 준수를 위해 해당 항목을 반드시 입력해주세요.',
  OFFICER_PHONE_NUMBER: '보호책임자 전화번호',
  OFFICER_SPOT: '보호책임자 소속/직위',
  OFFICER_EMAIL: '보호책임자 이메일',
  DETAIL_ADDRESS: '상세주소',
});
