












































































































































































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ArticleTuiEditor from '@/components/common/summernote/Editor.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import ListAttached from '@/components/board/basic/ListAttached.vue';
import { Mall, GetBoardsBoardNoPostsPostNoResponse } from 'ncp-api-supporter';
import { articleOpenedOption, getArticleInit } from '@/const/contents/board';
import { Getter } from 'vuex-class';
import { Row } from '@/types/tui-grid/store/data';
import Articles from '@/components/board/basic/mixins/ArticleMixin';
import { getOperator } from '@/const/contents/board';
import { mall } from '@/utils/mall';

@Component({
  components: { TextInput, SelectBox, ToolTip, RadioGroup, ArticleTuiEditor, ListAttached, MallSelect },
})
export default class ArticleDetail extends Mixins(WindowPopupMainVue, Articles) {
  @Ref('articleEditor') private readonly aEditor!: ArticleTuiEditor;
  @Getter('mall/getMalls') private malls!: Mall[];
  @Ref() private readonly articleTitleRef: TextInput;
  @Ref() private readonly boardSelectRef: SelectBox;
  @Ref() private readonly mallSelectRef: SelectBox;
  private boardNos: { name: string; value: number }[] = [];
  private readonly articleOpenedOption = articleOpenedOption;
  private isOnlyOneMall = mall.isOnlyOneMall;
  //private row: Row;
  private mode = '';
  private article = getArticleInit();

  private mounted() {
    this.init();
  }

  /**
   * -------- init page logic --------
   * 1. init data for editing article [editing,replying,modifying-reply]
   * 2. init data for creating a article.[mall,board select-box value setting]
   * 3. set article content (rich editor value) when editing article
   * 4. generate values of board select-box
   * --------------------------------------------------------
   */
  private async init(): Promise<void> {
    this.mode = this.data.mode;
    // 1
    if (this.mode !== 'create') {
      const result = await this.getArticleDetail(this.data);
      this.setResult(result);
    } else {
      //2
      const mallNoParam =
        this.malls && this.malls[this.malls.length - 1] && this.malls[this.malls.length - 1].mallNo
          ? Number(this.malls[this.malls.length - 1].mallNo)
          : -1;
      this.article.mallNo = this.data.mallNo ? this.data.mallNo : mallNoParam;
      this.article.boardNo = this.data.boardNo ? this.data.boardNo : -1;
    }

    // 3
    if (this.mode === 'edit' || this.mode === 'replyModify') {
      this.aEditor.setHtml(this.article.content);
    }

    // 4
    this.initBoardSelect();
  }

  //--- save article by api (creating&editting article, reply, modify reply)------------
  //1. check form
  //2. call api from sub method (create,reply & edit,reply-modification article)
  //3. close popup by calling common method.
  //------------------------------------------------------------------------------------
  private async saveArticle(): Promise<void> {
    let result = '';
    // 1
    if (!this.checkForm()) return;

    // save confirm
    const msg =
      this.mode !== 'replyModify' && this.mode !== 'edit'
        ? this.$t('BOARD.BASIC.ARTICLE.CONFIRM_SAVE') //create confirm
        : this.$t('BOARD.BASIC.ARTICLE.CONFIRM_MODIFY'); //edit confirm

    if (!confirm(msg as string)) {
      return;
    }
    // get article content in rich editor
    this.article.content = this.aEditor.getHtml();

    if (this.mode !== 'replyModify' && this.mode !== 'edit') {
      // 2 (create article, reply)
      result = await this.createArticle(this.article, this.mode);
    } else {
      // 2 (edit article, reply modify)
      result = await this.modifyArticle(this.article, this.mode);
    }
    // 3
    this.onPositiveClick({ result: result });
  }
  /**
   * mall select-box changing logic.
   */
  private changeMall(): void {
    this.article.boardNo = -1;
    this.initBoardSelect();
  }

  /**
   * --- click reply button logic, to open article detail popup page ----
   */
  private onReplyClick(): void {
    const name = 'reply_' + this.data.row.no;
    this.articleReply(this.data.row, 'reply', name);
  }

  /** -- confirm when clicking cancel button */
  private doCancel(): void {
    if (confirm(this.$t('BOARD.BASIC.ARTICLE.POPUP_CANCEL_CONFIRM') as string)) {
      this.onNegativeClick();
    }
  }

  /**
   * --------article detail popup for (article reply)-----
   * 1. generate parameter for popup
   * 2. call common method of article detail popup
   * ------------------------------------------------------
   */
  private articleReply(rowData: Row, mode = 'create', name?: string): void {
    const popupData = {
      mallNo: this.data.mallNo,
      boardNo: this.data.boardNo,
      parentTitle: this.data.row.title,
      parentContent: this.data.row.content,
      mode: mode,
      row: rowData,
    };
    //throwWindowPopup('ArticleDetail', popupData, 'lg', this.detailCallback);
    this.articleDetailPopup(popupData, this.search, name);
  }

  /**
   * After replying article,fire message event for refresh parents data
   */
  private search(): void {
    if (window.opener.opener) {
      window.opener.postMessage('reply_end', window.location);
    } else {
      //this.onPositiveClick({ result: 'reply' });
      window.opener.postMessage('refresh_articleList', window.location);
    }
  }

  /**
   * --- board select box init...----
   */
  private async initBoardSelect(): Promise<void> {
    this.boardNos = await this.getSelectBoardNos(this.article.mallNo);
    if (this.article.boardNo === -1 && this.boardNos.length > 0) {
      this.article.boardNo = this.boardNos[0].value;
    }
  }

  /**
   * validation for value of form properties
   */
  private checkForm(): boolean {
    // 1. title required
    if (!this.article.title) {
      alert(this.$t('BOARD.BASIC.ARTICLE.ALERT_ARTICLE_TITLE'));
      this.articleTitleRef.focus();
      return false;
    }
    // 2. content required
    if (!this.aEditor.getHtml()) {
      alert(this.$t('BOARD.BASIC.ARTICLE.ALERT_SUMMERNOTE_EMPTY'));
      this.aEditor.focus();
      return false;
    }
    // 3. content size
    if (this.aEditor.getHtml().length > 16777215) {
      alert(this.$t('BOARD.BASIC.ARTICLE.ALERT_SUMMERNOTE_LONG'));
      this.aEditor.focus();
      return false;
    }
    // 4. board select-box values
    if (!this.boardNos && this.boardNos.length === 0) {
      alert(this.$t('BOARD.BASIC.ARTICLE.ALERT_BOARD_DISABLED'));
      this.boardSelectRef.focus();
      return false;
    }
    // 5. board select-box selected value required
    if (!this.article.boardNo) {
      alert(this.$t('BOARD.BASIC.ARTICLE.ALERT_BOARD_NO_SELECT'));
      this.boardSelectRef.focus();
      return false;
    }

    // 6. mall select-box selected value required
    if (!this.article.mallNo) {
      alert(this.$t('BOARD.BASIC.ARTICLE.ALERT_MALL_NO_SELECT'));
      this.mallSelectRef.focus();
      return false;
    }
    return true;
  }

  /**
   * Set element value from a article data of api
   * (for reply,modify reply,edit article)
   */
  private setResult(res: GetBoardsBoardNoPostsPostNoResponse): void {
    this.article.title = res.title as string;
    this.article.mallNo = this.data.mallNo;
    this.article.boardNo = this.data.boardNo ? this.data.boardNo : this.data.row.boardNo;
    this.article.no = Number(res.no);
    this.article.noticed = res.noticed as boolean;
    this.article.opened = res.opened as boolean;
    this.article.readCount = res.readCount as number;
    this.article.files = this.mode !== 'reply' ? res.files : [];
    this.article.content = res.content.replaceAll('\n', '<br>') as string;
    this.article.creationDateTime = res.writeDateTime.substring(0, 16) as string;
    //this.article.categoryNo = res.categoryNo;
    this.article.secreted = res.secreted;
    this.article.orderFixed = res.orderFixed;

    //-- without result value.
    this.article.writer = this.data.row.writer;
    this.article.writerName = getOperator(this.data.row, 'writer', '');
    this.article.parentTitle = this.mode === 'replyModify' ? this.data.parentTitle : res.title;
    this.article.parentContent = this.mode === 'replyModify' ? this.data.parentContent : res.content;

    // reply title
    if (this.mode === 'reply') {
      this.article.title = ('[RE]:' + this.article.title).substring(0, 50);
    }
  }
}
