import { formatCurrency } from '@/utils/numberFormat';
import { changeDateFormat } from '@/utils/dateFormat';
import {
  GetInputHtmlType,
  GetInputType,
  GetNameAndValueArrayType,
  GetOptionNameAndValueType,
} from '@/types/productStock.d';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import {
  getBrandNameColumn,
  getSalePriceColumn,
} from '@/components/product/common/girdColumns/ProductCommonGridColumns';
import { i18n } from '@/main';
import { DefaultGridContainerOption } from '@/types';

export enum StockManageType {
  safetyStock = 'SAFETY_STOCK',
  edit = 'EDIT',
  adjust = 'ADJUST',
}

export enum StockType {
  adjustStock = 'adjustStockCnt',
  editStock = 'editStockCnt',
  optionManagement = 'optionManagementCd',
}

const getNameAndValueArray: GetNameAndValueArrayType = (index, nameArray, valueArray) => {
  const nameAndValueArray = [];
  for (let i = 0; i < index; i++) {
    const tempNameAndValue = nameArray[i] + ':' + valueArray[i];
    nameAndValueArray.push(tempNameAndValue);
  }
  return nameAndValueArray;
};

const getOptionNameAndValue: GetOptionNameAndValueType = (optionType, optionName, optionValue) => {
  const COMBINATION = 'COMBINATION',
    REQUIRED = 'REQUIRED',
    DEFAULT = 'DEFAULT';
  let index = 0;
  let nameArray = [];
  let valueArray = [];
  let nameAndValueArray: string[] = [];

  switch (optionType) {
    case COMBINATION:
      nameArray = optionName.split('|');
      valueArray = optionValue.split('|');

      if (nameArray.length === valueArray.length) {
        index = nameArray.length;
      }

      nameAndValueArray = getNameAndValueArray(index, nameArray, valueArray);
      return nameAndValueArray.join('|');

    case REQUIRED:
      return `${optionName}:${optionValue}`

    case DEFAULT:
      return optionName;

    default:
      return '-';
  }
};

const getInput = ({ className, maxLength, rowData, value, name, readonly }: GetInputType): string => {
  return `<input type="text" class="${className}" maxlength="${maxLength}" data-row-key="${
    rowData.rowKey
  }" value="${value || ''}" ${readonly} data-name="${name}" style="height: 23px">`;
};

const getInputHtml = ({ className, value, maxLength, rowData, name }: GetInputHtmlType): string => {
  const safetyStockSyncYn = rowData.safetyStockSyncYn === 'Y';
  const NO_INDEX = -1;
  const hasClassName = className.indexOf('safetyStockInput') > NO_INDEX;
  if (hasClassName && safetyStockSyncYn) {
    const readonly = 'readonly';
    return getInput({ className, maxLength, rowData, value, name, readonly });
  }
  const readonly = rowData.itemTableRefs.getCheckedRowKeys().includes(rowData.rowKey) ? '' : 'readonly';
  return getInput({ className, maxLength, rowData, value, name, readonly });
};

export const productStockGridProps = {
  columns: [
    {
      header: 'STOCK.MANAGEMENT.NO',
      name: 'number',
      align: 'center',
      minWidth: 50,
    },
    {
      header: 'STOCK.MANAGEMENT.MALL_NAME',
      name: 'mallName',
      minWidth: 100,
      align: 'center',
    },
    {
      header: 'STOCK.MANAGEMENT.MALL_PRODUCT_NO',
      name: 'mallProductNo',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        const { value, row } = cell;
        return row.stockSyncYn === 'Y' ? `(M)${value}` : value;
      },
    },
    //상품관리코드
    {
      header: 'STOCK.MANAGEMENT.PRODUCT_MANAGEMENT_CD',
      name: 'productManagementCd',
      align: 'center',
      minWidth: 100,
      formatter: ({ value }) => (value ? value : '-'),
    },
    // 상품명
    {
      header: 'STOCK.MANAGEMENT.PRODUCT_NAME',
      name: 'productName',
      align: 'center',
      minWidth: 150,
      formatter: ({ value }) => (value ? value : '-'),
    },
    //  옵션명:옵션값
    {
      header: 'STOCK.MANAGEMENT.OPTION_NAME_AND_VALUE',
      name: 'optionNameAndValue',
      align: 'center',
      minWidth: 150,
      formatter: cell => {
        const { optionType, optionName, optionValue } = cell.row;
        return getOptionNameAndValue(optionType, optionName, optionValue);
      },
      copyOptions: {
        useFormattedValue: true,
      },
    },
    //  옵션번호
    {
      header: 'STOCK.MANAGEMENT.MALL_OPTION_NO',
      name: 'mallOptionNo',
      align: 'center',
      minWidth: 100,
      formatter: ({ value }) => (value ? value : '-'),
    },
    //옵션관리코드
    {
      header: 'STOCK.MANAGEMENT.OPTION_MANAGEMENT_CD',
      name: StockType.optionManagement,
      minWidth: 180,
      align: 'center',
      editOptions: {
        type: 'text',
        useViewMode: true,
      },
      formatter: cell => {
        const { value, row } = cell;
        const isItem = row.itemYn === 'Y';
        const className = `optionManagementCdInput ${isItem ? 'noEdit' : 'editInput'}`;
        const inputHtmlParameter = {
          className,
          value: value || '',
          maxLength: 45,
          rowData: row,
          name: StockType.optionManagement,
        };
        return getInputHtml(inputHtmlParameter);
      },
    },
    //수정재고
    {
      header: 'STOCK.MANAGEMENT.EDIT_STOCK_CNT',
      name: StockType.editStock,
      minWidth: 80,
      align: 'center',
      editOptions: {
        type: 'text',
        useViewMode: false,
      },
      formatter: cell => {
        const { value, row } = cell;
        const className = 'stockInput editStockInput editInput';
        const inputHtmlParameter = {
          className,
          value,
          maxLength: 8,
          rowData: row,
          name: StockType.editStock,
        };
        return getInputHtml(inputHtmlParameter);
      },
    },
    //  조정재고
    {
      header: 'STOCK.MANAGEMENT.ADJUST_STOCK_CNT',
      name: StockType.adjustStock,
      minWidth: 80,
      align: 'center',
      editOptions: {
        type: 'text',
        useViewMode: false,
      },
      formatter: cell => {
        const { value, row } = cell;
        const className = 'stockInput adjustStockInput editInput';
        const inputHtmlParameter = {
          className,
          value,
          maxLength: 8,
          rowData: row,
          name: StockType.adjustStock,
        };
        return getInputHtml(inputHtmlParameter);
      },
    },
    //재고수량
    {
      header: 'STOCK.MANAGEMENT.STOCK_CNT',
      name: 'stockCnt',
      minWidth: 80,
      align: 'center',
      formatter: ({ value }) => formatCurrency(value),
    },
    //  재고번호
    {
      header: 'STOCK.MANAGEMENT.STOCK_NO',
      name: 'stockNo',
      minWidth: 100,
      align: 'center',
    },
    //출고대기
    {
      header: 'STOCK.MANAGEMENT.DELIVERY_WAITING_STOCK_CNT',
      name: 'deliveryWaitingStockCnt',
      minWidth: 100,
      align: 'center',
    },
    //  재고변경이력
    {
      header: 'STOCK.MANAGEMENT.STOCK_CHANGE_HISTORY_NO',
      name: 'stockChangeHistoryNo',
      minWidth: 100,
      align: 'center',
      formatter: cell => {
        const { stockNo, wmsUseYn } = cell.row;
        return `<a href="javascript:;" class="gridStockChangeHistory" data-stock-no=${stockNo} 
                    data-wms-use-yn="${wmsUseYn}" >${window.$t('STOCK.MANAGEMENT.EXAMPLE')}</a>`;
      },
    },
    //  판매가
    getSalePriceColumn(100),
    //  즉시할인가
    {
      header: 'STOCK.MANAGEMENT.APPLY_DISCOUNT_SALE_PRICE',
      name: 'applyDiscountSalePrice',
      minWidth: 100,
      align: 'center',
      formatter: cell => {
        const { value } = cell;
        return value ? formatCurrency(value) : '-';
      },
    },
    //  옵션적용가
    {
      header: 'STOCK.MANAGEMENT.APPLY_OPTION_ADD_PRICE',
      name: 'applyOptionAddPrice',
      minWidth: 100,
      align: 'center',
      formatter: cell => {
        const { value, row } = cell;
        const optionUsed = row.optionType !== 'DEFAULT';
        return optionUsed ? formatCurrency(value) : '-';
      },
    },
    //  브랜드
    getBrandNameColumn(80),
    //  전시카테고리
    {
      header: 'STOCK.MANAGEMENT.CATEGORY_FULL_NAME',
      name: 'categoryFullName',
      minWidth: 250,
      align: 'center',
      formatter: ({ value }) => (value ? value : '-'),
    },
    //  판매기간
    {
      header: 'STOCK.MANAGEMENT.SALE_PERIOD',
      name: 'salePeriod',
      minWidth: 250,
      align: 'center',
      formatter: cell => {
        const { saleStartYmdt, saleEndYmdt } = cell.row;
        return [saleStartYmdt, saleEndYmdt].join('~');
      },
      copyOptions: {
        useFormattedValue: true,
      },
    },
    {
      header: 'STOCK.MANAGEMENT.SOLD_OUT_STATUS',
      name: 'forcedSoldOut',
      minWidth: 80,
      align: 'center',
      formatter: ({ value, row }) => {
        if (value === true) {
          return i18n.t('PRODUCT.OPTION.OUT_OF_STOCK');
        } else if (row.stockCnt === 0) {
          return i18n.t('PRODUCT.OPTION.SOLD_OUT');
        } else {
          return i18n.t('PRODUCT.OPTION.NORMAL');
        }
      },
    },
    //  최종 수정자
    {
      header: 'STOCK.MANAGEMENT.UPDATE_ADMIN_NAME',
      name: 'updateAdminName',
      minWidth: 100,
      align: 'center',
      formatter: ({ value }) => (!value ? i18n.t('MALL.LIST.DELETED_ADMIN') : value),
    },
    //  재고수정일
    {
      header: 'STOCK.MANAGEMENT.UPDATE_YMDT',
      name: 'updateYmdt',
      minWidth: 130,
      align: 'center',
      formatter: cell => {
        const { value } = cell;
        if (value) {
          return changeDateFormat(value, 'YYYY-MM-DD HH:mm');
        }
        return '';
      },
    },
  ],
  header: {
    columns: [
      {
        name: StockType.editStock,
        renderer: props => {
          props.tooltipId = StockType.editStock;
          return headerWithTooptipRenderer(props);
        },
      },
      {
        name: StockType.adjustStock,
        renderer: props => {
          props.tooltipId = StockType.adjustStock;
          return headerWithTooptipRenderer(props);
        },
      },
    ],
  },
  options: {
    columnOptions: {
      frozenCount: 5,
      resizable: true,
    },
    rowHeaders: ['checkbox'],
    scrollY: true,
    heightResizable: true,
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },
};
export const getContainerOptions = (): DefaultGridContainerOption => ({
  visibleFixedColumnNames: ['mallName', 'mallProductNo', 'productManagementCd', 'productName'],
  hasExcelDownloadButton: false,
  pageSizeKey: 'size',
  pageSizeList: [
    {
      value: '30',
      name: 'PAGE_SIZE_30',
    },
    {
      value: '50',
      name: 'PAGE_SIZE_50',
    },
    {
      value: '100',
      name: 'PAGE_SIZE_100',
    },
  ],
  headerTooltipOption: [
    {
      id: `#${StockType.editStock}`,
      popupData: { data: { message: i18n.t('STOCK.MANAGEMENT.HELP_EDIT_STOCK') } },
    },
    {
      id: `#${StockType.adjustStock}`,
      popupData: { data: { message: i18n.t('STOCK.MANAGEMENT.HELP_ADJUST_STOCK') } },
    },
  ],
});

//엑셀
export const keyMapJson = {
  stockNo: 'STOCK.MANAGEMENT.STOCK_NO', //
  modifyStockType: 'STOCK.MANAGEMENT.MODIFY_STOCK_TYPE', //
  modifyStockCnt: 'STOCK.MANAGEMENT.MODIFY_STOCK_CNT',
  optionManagementCd: 'STOCK.MANAGEMENT.OPTION_MANAGEMENT_CD',
  extraManagementCd: 'STOCK.MANAGEMENT.EXTRA_MANAGEMENT_CD',
  mallNo: 'STOCK.MANAGEMENT.MALL_NO',
  mallName: 'STOCK.MANAGEMENT.MALL_NAME',
  mallProductNo: 'STOCK.MANAGEMENT.MALL_PRODUCT_NO',
  productManagementCd: 'STOCK.MANAGEMENT.PRODUCT_MANAGEMENT_CD',
  productName: 'STOCK.MANAGEMENT.PRODUCT_NAME',
  mallOptionNo: 'STOCK.MANAGEMENT.MALL_OPTION_NO',
  optionNameAndValue: 'STOCK.MANAGEMENT.OPTION_NAME_AND_VALUE',
  stockCnt: 'STOCK.MANAGEMENT.STOCK_CNT',
};
