







































































import { Component, Watch, Mixins, Prop } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import FileInputMixin from '@/components/common/input/fileInput/FileInputMixin.vue';
import { saveBinaryDataToFile } from '@/utils/fileSaver';
import { FileType } from '@/const/contents/configuration/mallCreate';

interface DomesticFileType {
  domestic: boolean;
  originalName: string;
  displayOrder: number;
  uploadedName: string;
}

@Component({
  components: { TextInput, ToolTip, RadioGroup, SelectBox },
})
export default class ContractFileForm extends Mixins(FileInputMixin) {
  @Prop()
  private readonly mallNo: number;
  @Prop()
  private readonly title: string;
  @Prop()
  private readonly tooltip: string;
  @Prop()
  private readonly fileType: FileType;
  @Prop()
  private readonly guide: string;
  @Prop()
  private readonly contractFiles: DomesticFileType[];

  private copyGuide = '';

  @Watch('guide')
  insertGuide() {
    this.copyGuide = this.guide;
  }

  private validateFile(file): boolean | FormData {
    const fileElements = file.name.split('.');
    const fileExe = fileElements[fileElements.length - 1];
    const exes = ['doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'gif', 'png', 'pdf'];

    if (!exes.includes(fileExe)) {
      alert(this.$t('MALL.FORM.INCORRECT_FILE'));
      return false;
    }

    if (!this.validateFileSize(file)) {
      alert(this.$t('PRODUCT.ADD.IMAGE_MAX_SIZE'));
      return false;
    }
    return true;
  }

  private addFile(e, idx: number) {
    const [file] = e.target.files;
    if (!this.validateFile(file)) return;

    const data = new FormData();
    data.append('file', file);
    data.append('mallNo', this.mallNo as any);
    data.append('domestic', (this.fileType === 'domesticFiles') as any);
    data.append('displayOrder', (this.contractFiles[idx].displayOrder + idx) as any);

    this.$api
      .postStorageContracts({ data })
      .then(res => {
        this.$emit('addFile', { fileType: this.fileType, idx, file: res.data });
      })
      .catch(err => {
        const errCode = {
          table: {
            '99999': () => alert(this.$t('ERR.API.CONNECTION_IS_NOT_SMOOTH')),
          },
          display(code) {
            this.table[code]?.();
          },
        };

        errCode.display(err.data.code);
      });
  }

  private removeCurrentFile(idx: number) {
    this.$emit('removeCurrentFile', { fileType: this.fileType, idx });
  }

  private downloadFile(idx: number) {
    const fileName = this.contractFiles[idx].uploadedName;
    const saveAs = this.contractFiles[idx].originalName;

    this.$api.getStorageContracts({ params: { fileName, saveAs } }).then((res: any) => {
      saveBinaryDataToFile(res.data, fileName);
    });
  }

  private addField() {
    this.$emit('addField', this.fileType);
  }

  private removeField(idx: number) {
    this.$emit('removeField', { fileType: this.fileType, idx });
  }

  private addGuide() {
    const guideType = this.fileType === 'domesticFiles' ? 'domesticGuide' : 'globalGuide';
    const validGuidText = this.copyGuide.slice(0, 1000);

    this.copyGuide = validGuidText;
    this.$emit('addGuide', { guideType, content: this.copyGuide });
  }
}
