





import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';
import Reject080List from './Reject080List.vue';

@Component({
  components: { AdminIframe, Reject080List },
})
export default class Reject080ListIframe extends Vue {
  private showIframe = process.env.VUE_APP_NODE_ENV !== 'production';

  private get remoteSrc() {
    const { type } = this.$route.meta;

    return process.env.VUE_APP_ADMIN_REMOTE_URL + `/member/sms/reject/list?serviceType=PRO&type=${type}`;
  }
}
