





























































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PutShippingsShippingNoSimpleRequest, PostShippingsCombineRequest } from 'ncp-api-supporter';
import $ from 'jquery';
import { getHref } from '@/utils/route';

@Component({
  components: {},
})
export default class DeliveryCombine extends Vue {
  @Watch('checkAll')
  onCheckAll(next: boolean) {
    if (this.orderListContent && this.orderListContent.length > 0) {
      this.orderListContent.map(item => {
        item.checked = next;
      });
    }
  }
  private orderListContent = [];
  private checkAll = false;
  private noData = false;
  private count = 0;
  private fullStyle = 'display: block; top: 34px; height: 462px; left: 0px; right: 18px';

  private handleClickModify(flag: boolean, name: string, ev): void {
    if (flag) {
      ev[name] = true;
      this.$nextTick(() => {
        $('.inputText').focus();
      });
    }
  }
  private handleBlur($event, name: string, tag: string, ev): void {
    ev[name] = false;
    if ($event.target.value !== ev[tag]) {
      if ($event.target.value.trim() === '') {
        return;
      }
      ev[tag] = $event.target.value;
      if (confirm(this.$t('ORDER.COMBINE.CHANGE_DATA_CONFIRM') as string)) {
        this.$nextTick(() => {
          const request: PutShippingsShippingNoSimpleRequest = {
            pathParams: {
              shippingNo: ev.shippingNo,
            },
            data: {
              zipCd: ev.receiverZipCd,
              address: ev.receiverAddress,
              customsIdNumber: ev.customsIdNumber,
              detailAddress: ev.receiverDetailAddress,
            },
          };
          $('#loading').show();
          this.$api
            .putShippingsShippingNoSimple(request)
            .then(() => {
              ev[tag] = $event.target.value;
            })
            .finally(() => {
              $('#loading').hide();
            });
        });
      }
    }
  }
  private formatterList(arr, key1, key2) {
    const arry = [];
    if (arr && arr.length > 0) {
      let groupIndex = 0;
      const groupIndexArr = [];
      const shippingIndexArr = [];
      const orderIndexArr = [];
      arr.forEach(item => {
        let rowSpan = 0;

        item[key1].forEach(item1 => {
          let shippingRowSpan = 0;
          const sameOrderNo = [];
          item1[key2].sort((a, b) => {
            return a.orderNo - b.orderNo;
          });
          item1[key2].forEach((item2, index2: number) => {
            shippingRowSpan += 1;
            rowSpan += 1;
            groupIndex += 1;
            const orderRowSpan = item1[key2].filter(e => {
              return item2.orderNo === e.orderNo;
            }).length;
            if (sameOrderNo.indexOf(item2.orderNo[0]) < 0) {
              sameOrderNo.push(item2.orderNo);
              const orderSpan = {
                orderNo: item2.orderNo,
                orderRowSpan: 1,
                index: groupIndex - 1,
              };
              orderIndexArr.push(orderSpan);
            } else {
              orderIndexArr.map(order => {
                if (order.orderNo !== item2.orderNo && order.index > index2) {
                  order.index += 1;
                }
                if (order.orderNo === item2.orderNo) {
                  order.orderRowSpan = orderRowSpan;
                }
              });
            }
            const obj = {};
            Object.keys(item).forEach(name => {
              obj[name] = item[name];
            });
            Object.keys(item1).forEach(name1 => {
              obj[name1] = item1[name1];
            });
            Object.keys(item2).forEach(name2 => {
              obj[name2] = item2[name2];
            });
            obj['rowSpanBtn'] = 0;
            obj['rowSpanShipping'] = 0;
            obj['orderRowSpan'] = 0;
            obj['checked'] = false;
            obj['modZip'] = false;
            obj['modAddress'] = false;
            obj['modDetailAddress'] = false;
            obj['modId'] = false;
            obj['zipInputShow'] = false;
            obj['addressInputShow'] = false;
            obj['detailAddressInputShow'] = false;
            obj['idInputShow'] = false;
            arry.push(obj);
          });

          const shippingItm = {
            firstShippingIndex: groupIndex - shippingRowSpan,
            shippingRowSpan: shippingRowSpan,
          };
          shippingIndexArr.push(shippingItm);
        });
        const itm = {
          firstGroupIndex: groupIndex - rowSpan,
          rowSpan: rowSpan,
        };
        groupIndexArr.push(itm);
      });
      if (groupIndexArr && groupIndexArr.length > 0) {
        groupIndexArr.forEach(items => {
          arry[items.firstGroupIndex]['rowSpanBtn'] = items.rowSpan;
          arry.forEach((itm, idxn: number) => {
            if (idxn >= items.firstGroupIndex && idxn < items.firstGroupIndex + items.rowSpan) {
              if (itm.receiverZipCd !== arry[items.firstGroupIndex].receiverZipCd) {
                arry.forEach((tm, dx: number) => {
                  if (dx >= items.firstGroupIndex && dx < items.firstGroupIndex + items.rowSpan) {
                    tm['modZip'] = true;
                  }
                });
              }
              if (itm.receiverAddress !== arry[items.firstGroupIndex].receiverAddress) {
                arry.forEach((tm, dx: number) => {
                  if (dx >= items.firstGroupIndex && dx < items.firstGroupIndex + items.rowSpan) {
                    tm['modAddress'] = true;
                  }
                });
              }
              if (itm.receiverDetailAddress !== arry[items.firstGroupIndex].receiverDetailAddress) {
                arry.forEach((tm, dx: number) => {
                  if (dx >= items.firstGroupIndex && dx < items.firstGroupIndex + items.rowSpan) {
                    tm['modDetailAddress'] = true;
                  }
                });
              }
              if (itm.customsIdNumber !== arry[items.firstGroupIndex].customsIdNumber) {
                arry.forEach((tm, dx: number) => {
                  if (dx >= items.firstGroupIndex && dx < items.firstGroupIndex + items.rowSpan) {
                    tm['modId'] = true;
                  }
                });
              }
            }
          });
        });
      }
      if (shippingIndexArr && shippingIndexArr.length > 0) {
        shippingIndexArr.forEach(shipItm => {
          arry[shipItm.firstShippingIndex]['rowSpanShipping'] = shipItm.shippingRowSpan;
        });
      }
      if (orderIndexArr && orderIndexArr.length > 0) {
        orderIndexArr.forEach(orderItm => {
          arry[orderItm.index]['orderRowSpan'] = orderItm.orderRowSpan;
        });
      }
      return arry;
    }
  }

  private handleOpenPopup(orderNo): void {
    window.open(`${getHref('OrderDetailIframe')}?orderNo=${orderNo}`, '_blank');
  }
  private combineProcess(index: number, num: number): void {
    let cancelCombine = false;
    const row = this.orderListContent.filter((item, idx: number) => {
      if (idx >= index && idx < index + num) {
        return item.checked === true;
      }
    });
    if (row.length > 0) {
      row.forEach(item => {
        if (item.hasTaskMessage === true) {
          cancelCombine = true;
        }
      });
    }

    if (cancelCombine && !confirm(this.$t('ORDER.COMBINE.HAS_TASK_MESSAGE_CONFIRM') as string)) {
      return;
    }
    if (row.length < 2) {
      alert(this.$t('ORDER.COMBINE.ATLEAST_TWO_CONFIRM'));
      return;
    }

    if (row && row.length > 1) {
      const strArr = [];
      row.forEach(item => {
        if (item.receiverZipCd !== row[0].receiverZipCd) {
          if (strArr.indexOf(this.$t('ORDER.COMBINE.ALERT_TEXT_RECEIVER_ZIP_CD')) === -1) {
            strArr.push(this.$t('ORDER.COMBINE.ALERT_TEXT_RECEIVER_ZIP_CD'));
          }
        }
        if (item.receiverAddress !== row[0].receiverAddress) {
          if (strArr.indexOf(this.$t('ORDER.COMBINE.ALERT_TEXT_RECEIVER_ADDRESS')) === -1) {
            strArr.push(this.$t('ORDER.COMBINE.ALERT_TEXT_RECEIVER_ADDRESS'));
          }
        }
        if (item.receiverDetailAddress !== row[0].receiverDetailAddress) {
          if (strArr.indexOf(this.$t('ORDER.COMBINE.ALERT_TEXT_RECEIVER_DETAIL_ADDRESS')) === -1) {
            strArr.push(this.$t('ORDER.COMBINE.ALERT_TEXT_RECEIVER_DETAIL_ADDRESS'));
          }
        }
        if (item.customsIdNumber !== row[0].customsIdNumber) {
          if (strArr.indexOf(this.$t('ORDER.COMBINE.ALERT_TEXT_CUSTOMS_ID_NUMBER')) === -1) {
            strArr.push(this.$t('ORDER.COMBINE.ALERT_TEXT_CUSTOMS_ID_NUMBER'));
          }
        }
      });
      const strs = strArr.join(',');
      if (strArr.length > 0) {
        alert(this.$t('ORDER.COMBINE.NOT_SAME_ALERT', { str: strs }));
        return;
      }
    }
    if (!confirm(this.$t('ORDER.COMBINE.MAKE_SURE_COMBINE_CONFIRM') as string)) {
      return;
    }

    const postData = this.getCombineRequestData(row);

    const request: PostShippingsCombineRequest = {
      data: postData,
    };

    $('#loading').show();
    this.$api
      .postShippingsCombine(request)
      .then(() => {
        alert(this.$t('ORDER.COMBINE.CONFIRM'));
        location.reload();
        window.opener.location.reload();
      })
      .finally(() => {
        $('#loading').hide();
      });
  }

  private getCombineRequestData(row) {
    return row.reduce((acc, item) => {
      const mallNoAlreadyExist = acc.find(acc => acc.mallNo === item.mallNo);

      if (mallNoAlreadyExist) {
        const removedAcc = acc.filter(acc => acc.mallNo !== item.mallNo);
        return [
          ...removedAcc,
          { mallNo: item.mallNo, shippingNos: [...mallNoAlreadyExist.shippingNos, item.shippingNo] },
        ];
      }

      return [...acc, { mallNo: item.mallNo, shippingNos: [item.shippingNo] }];
    }, []);
  }

  private getList(): void {
    const request: any = {
      params: {},
    };

    if (Object.keys(this.$route.query).length === 0) {
      return;
    }
    this.$set(request, 'params', this.$route.query);
    Object.keys(request.params).forEach(key => {
      if (request.params[key] === 'undefined') {
        this.$set(request.params, key, null);
      }
      if (request.params[key] === 'true') {
        this.$set(request.params, key, true);
      }
      if (request.params[key] === 'false') {
        this.$set(request.params, key, false);
      }
    });
    request.params.payTypes = (this.$route.query.payTypes as any).split(',');
    request.params.platformTypes = (this.$route.query.platformTypes as any).split(',');
    $('#loading').show();
    this.$api
      .getOrderOptionsAbleToCombineShipping(request)
      .then(res => {
        this.noData = res.data && res.data.length === 0;
        this.orderListContent = this.formatterList(res.data, 'combineDeliverys', 'combineDeliveryOrderProductOptions');
        if (this.orderListContent) {
          this.count = this.orderListContent.length;
          this.fullStyle = '';
        }
      })
      .finally(() => {
        $('#loading').hide();
      });
  }
  private deliveryType(name) {
    if (name === 'PARCEL_DELIVERY') {
      return this.$t('ORDER.COMBINE.DELIVERY_TYPE_PARCEL_DELIVERY');
    } else {
      return this.$t('ORDER.COMBINE.DELIVERY_TYPE_DIRECT_DELIVERY');
    }
  }
  mounted(): void {
    this.getList();
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-1200');
    });
  }
}
