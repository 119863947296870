import moment from 'moment';
import { isValidate } from '@/utils/validate';
import { i18n } from '@/main';
import { DeleteAccumulationsAssemblesRequestNoRequest, Mall } from 'ncp-api-supporter';
import { api } from '@/api';
import { CellRendererProps } from '@/types/tui-grid/renderer';
import $el from '@/utils/element';
import store from '@/store';

export const validateDateRange = (startDateTime: string, endDateTime: string) => {
  const startOverEnd = moment(startDateTime).diff(endDateTime, 'days') <= 0;
  const overPeriod = moment(startDateTime).diff(moment(endDateTime), 'days') < -365;

  if (!isValidate(startOverEnd, i18n.t('ALERT_NO_OVER_END_DATE'))) return false;
  return isValidate(!overPeriod, i18n.t('ACCUMULATION.PERIOD_MAX_LIMIT'));
};

export const cancelAccumulationsPayments = async (mallNo: number, requestNo: string): Promise<boolean> => {
  if (!confirm(i18n.t('ACCUMULATION.CONFIRM_CANCEL_ACCUMULATION').toString())) return false;
  const request: DeleteAccumulationsAssemblesRequestNoRequest = {
    params: { mallNo },
    pathParams: { requestNo },
  };
  await api.deleteAccumulationsAssemblesRequestNo(request);
  return true;
};

export class AccumulationStatusRenderer {
  $container;
  button!: HTMLButtonElement;
  tooltip!: HTMLSpanElement;
  paymentDeductNo!: string;
  mallName!: string;

  constructor(props: CellRendererProps) {
    const $container = $el(document.createElement('div'));
    const text = document.createElement('span');
    text.innerHTML = props.grid.getColumnValues('statusLabel')[props.rowKey];
    $container.append(text);
    this.$container = $container.getElement();

    if (props.value === 'WAITING') {
      this.paymentDeductNo = props.grid.getColumnValues('no')[props.rowKey];
      this.mallName = props.grid.getColumnValues('mallName')[props.rowKey];
      if (this.paymentDeductNo) this.addCancelButton();
    } else if (props.value === 'PROCESSING') {
      this.addTooltip();
    }
  }

  addCancelButton() {
    this.button = document.createElement('button');
    this.button.setAttribute('class', 'underline');
    this.button.innerHTML = i18n.t('ACCUMULATION.STATUS_CANCELED').toString();
    this.$container.append(document.createElement('br'));
    this.$container.append(this.button);
  }

  addTooltip() {
    this.tooltip = document.createElement('span');
    this.tooltip.innerHTML = `<div class="tool-tip-wrap">
        <button type="button" class="icon-tooltip icon-help"></button>
        <div class="tool-tip">
          <div class="tool-tip-cont">
            <ul class="notice-list type-2">
              <li>${i18n.t('ACCUMULATION.GRID_TOOLTIP_MESSAGE_1')}</li>
              <li>${i18n.t('ACCUMULATION.GRID_TOOLTIP_MESSAGE_2')}</li>
            </ul>
          </div>
        </div>
      </div>`;
    this.tooltip.style.position = 'absolute';
    this.$container.append(this.tooltip);
    this.tooltip.addEventListener('click', this.onClickTooltip);
  }

  mounted() {
    if (this.paymentDeductNo) {
      this.button.addEventListener('click', () => this.registerButtonEvent(this.paymentDeductNo));
    }
  }

  beforeDestroy() {
    if (this.tooltip) {
      this.tooltip.removeEventListener('click', this.onClickTooltip);
    }
    if (this.button && this.paymentDeductNo) {
      this.button.removeEventListener('click', () => this.registerButtonEvent(this.paymentDeductNo));
    }
  }

  get currentMallNo() {
    const malls: Mall[] = store.getters['mall/getMalls'];
    const { mallNo }: Mall = malls.find(({ mallName }) => mallName === this.mallName);
    return mallNo;
  }

  async registerButtonEvent(paymentDeductNo: string) {
    const result = await cancelAccumulationsPayments(this.currentMallNo, paymentDeductNo);
    if (!result) return;
    this.$container.innerHTML = i18n.t('ACCUMULATION.STATUS_CANCELED').toString();
  }

  onClickTooltip({ target }) {
    const $tooltip = target.nextElementSibling;

    if (!$tooltip.classList.contains('on')) {
      $tooltip.classList.add('on');
    } else {
      $tooltip.classList.remove('on');
    }
  }

  getElement = () => this.$container;
}
