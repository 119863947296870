






















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import MessageTemplateBox from '@/views/contents/member/sms/MessageTemplateBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import {
  GetKakaoConfig,
  NCPResponse,
  PutKakaoConfigRequest,
  GetKakaoConfigRequest,
  DeleteKakaoConfigPlusRequest,
  NotificationsConfigurationsResponse,
  PutKakaoTemplatesAutoRequest,
  TemplatesSetting,
} from 'ncp-api-supporter';
import { namespace } from 'vuex-class';
import { PopupResult, throwWindowPopup } from '@/helpers/popup';
import { getCurrentMallNo } from '@/utils/mall';
import store from '@/store';

const member = namespace('member');

@Component({
  components: { RadioGroup, MessageTemplateBox },
})
export default class NotificationConfig extends Vue {
  @member.Getter('getKakaoTemplateSetting')
  private kakaoTemplateSetting!: TemplatesSetting[];

  private kakaoUsed = false;
  private existPlusFriend = false;
  private kakaoPlusId = '';
  private kakaoSenderKey = '';

  private readonly usedOption: RadioBoxGroupOption<boolean> = {
    name: `smsConfig`,
    data: [
      { id: `config-used`, value: true, display: 'MEMBER.SMS_AUTO.USED' },
      { id: `config-notUsed`, value: false, display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  };

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  private async changeKakaoUsed(value: boolean) {
    if (!value) return;

    const { data }: NCPResponse<NotificationsConfigurationsResponse> = await this.$api.getNotificationsConfigurations({
      params: { mallNo: getCurrentMallNo(this) },
    });
    if (data.smsUsed && data.smsSendno) return;
    if (!data.smsUsed) {
      alert(this.$t('MEMBER.KAKAO.SMS_NOT_USED'));
      this.$nextTick(() => (this.kakaoUsed = false));
      return;
    }
    if (!data.smsSendno) {
      alert(this.$t('MEMBER.KAKAO.NO_SMS_SEND_NO'));
      this.$nextTick(() => (this.kakaoUsed = false));
      return;
    }
  }

  private openRegisterPopup() {
    throwWindowPopup(
      'RegisterPlusId',
      { mallNo: this.mallNo },
      'md',
      ({ data: { senderKey, plusId } }: PopupResult) => {
        this.kakaoSenderKey = senderKey;
        this.kakaoPlusId = plusId;
        this.existPlusFriend = plusId?.length > 0 && senderKey?.length > 0;
      },
    );
  }

  private async deletePlusFriendId() {
    if (!confirm(this.$t('MEMBER.KAKAO.DELETE_CONFIRM_MESSAGE').toString())) return;

    try {
      const request: DeleteKakaoConfigPlusRequest = { params: { mallNo: getCurrentMallNo(this) } };
      const response = await this.$api.deleteKakaoConfigPlus(request);
      if (response && response.status === 204) {
        alert(this.$t('MEMBER.KAKAO.RESET_CONFIG'));
        await this.getInitialData();
      }
    } catch (e) {
      console.error(e);
    }
  }

  private async onSave() {
    if (!this.kakaoPlusId) {
      return alert(this.$t('MEMBER.KAKAO.MUST_REGISTER_PLUS_ID'));
    }
    await Promise.all([this.saveKakaoConfig(), this.saveKakaoTemplateSetting()])
      .then(() => {
        alert(this.$t('SAVE_SUCCESS'));
      })
      .catch(e => e?.data?.code === 'AU0003' && alert(this.$t('MEMBER.MAIL.NO_AUTH_ALERT')));
  }

  private async saveKakaoConfig() {
    const request: PutKakaoConfigRequest = {
      data: { mallNo: this.mallNo, kakaoUsed: this.kakaoUsed },
    };
    await this.$api.putKakaoConfig(request);
  }

  private async saveKakaoTemplateSetting() {
    const templatesSetting = [...this.kakaoTemplateSetting];
    const request: PutKakaoTemplatesAutoRequest = {
      data: { mallNo: getCurrentMallNo(this), templatesSetting },
    };
    await this.$api.putKakaoTemplatesAutoConfig(request);
    store.dispatch('member/resetKakaoTemplateSetting');
  }

  @Watch('mallNo')
  private async getInitialData() {
    const request: GetKakaoConfigRequest = {
      params: { mallNo: getCurrentMallNo(this) },
    };
    const { data }: NCPResponse<GetKakaoConfig> = await this.$api.getKakaoConfig(request);
    this.existPlusFriend = Boolean(data.kakaoPlusId);
    this.kakaoUsed = data.kakaoUsed ?? false;
    this.kakaoPlusId = data.kakaoPlusId ?? '';
    this.kakaoSenderKey = data.kakaoSenderKey ?? '';
    this.$emit('kakaoSenderKey', data.kakaoSenderKey ?? '');
  }

  created() {
    this.getInitialData();
    throwBottomNavigation({
      buttons: [{ type: 'right', key: 'save', color: 'red', text: this.$t('SAVE') }],
      onClick: this.onSave,
    });
  }
}
