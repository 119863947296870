import { Component, Vue } from 'vue-property-decorator';
import {
  GetMemberGradesRequest,
  GetMemberGroupsRequest,
  Grade,
  Group,
  NCPResponse,
  PostAccumulationsPaymentsTargets,
  TargetsType,
} from 'ncp-api-supporter';

@Component
export default class AccumulationTargetCountMixin extends Vue {
  public targetCount = 0;
  public targetMembers: (PostAccumulationsPaymentsTargets | TargetsType)[] = [];

  public async getTargetCount(mallNo: number): Promise<void> {
    if (this.targetMembers.some(({ type }) => type !== 'MEMBER_NO')) {
      const gradeGroupMembers = await Promise.all(this.fetchTotalMemberCount(mallNo));
      this.targetCount = gradeGroupMembers.flatMap(value => value).reduce((accu, curr) => accu + curr.memberCount, 0);
      return;
    }

    this.targetCount = this.targetMembers
      ? this.targetMembers.reduce((previousValue, { values }) => previousValue + values.length, 0)
      : 0;
  }

  protected fetchTotalMemberCount(mallNo: number): Promise<(Grade | Group)[]>[] {
    return this.targetMembers.map(async ({ type, values }) => {
      if (type === 'GRADE') {
        const request: GetMemberGradesRequest = {
          params: { mallNo, gradeNos: values[0] },
        };
        const gradesResponse: NCPResponse<Grade[]> = await this.$api.getMemberGrades(request);
        if (!gradesResponse.data) return;
        return gradesResponse.data;
      } else if (type === 'GROUP') {
        const request: GetMemberGroupsRequest = {
          params: { mallNo, groupNos: values.join(',') },
        };
        const memberGroups: NCPResponse<Group[]> = await this.$api.getMemberGroups(request);
        if (!memberGroups.data) return;
        return memberGroups.data;
      }
    });
  }
}
