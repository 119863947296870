
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ToolTipOption } from '@/types/tooltip';

/**
 * List ToolTip Content
 * subMessage - title - messageList 형태의 툴팁

<div class="tool-tip-cont">
  <span>안녕하세요 오우오우<br><br></span>
  <span>안녕하세요 오우오우<br><br></span>
  ...
  <span class="tit">[화면보안 설정 페이지]</span>
  <ul class="notice-list type-2">
    <li>하이염 하이염</li>
    <li>하이염 하이염</li>
    .....
  </ul>
</div>

 */

@Component
export default class List extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption;

  private get isCloseType(): boolean {
    return this.option.listOption.type === 'close';
  }

  private getSubMessageHtml(message: string): string {
    const arr = message.split('\n');

    if (arr.length < 2) {
      return `${message}<br><br>`;
    }

    let html = '';
    arr.map((m, index) => {
      if (index > 0) {
        html += '<br>';
      }
      html += m;
    });

    if (
      this.option.listOption.mainTitle ||
      (this.option.listOption.mainMessage && this.option.listOption.mainMessage.length > 0)
    ) {
      html += '<br><br>';
    }

    return html;
  }

  private getMainMessageHtml(message: string): string {
    const arr = message.split('\n');

    if (arr.length < 2) {
      return message;
    }

    let html = '';
    arr.map((m, index) => {
      if (index > 0) {
        html += '<br>';
      }
      html += m;
    });

    return html;
  }
}
