



































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { getWithdrawalMemberGridProp, getWithdrawalMemberInitQuery } from '@/const/contents/member';
import Grid from '@/components/common/grid/Main.vue';
import { GetWithdrawalMembersRequest } from 'ncp-api-supporter';
import { WithdrawalMemberQuery } from '@/types/member';
import { CheckGridEventProps } from '@/types';
import { addDay, getToday } from '@/utils/dateFormat';
import moment from 'moment';
import { isEmpty } from 'underscore';

@Component({ components: { Grid } })
export default class WithdrawalMember extends Vue {
  @Ref()
  private readonly withdrawalMemberGrid!: Grid;

  private gridProps = getWithdrawalMemberGridProp();

  private query: WithdrawalMemberQuery = getWithdrawalMemberInitQuery();

  private contents = [];
  private totalCount = 0;

  private selected = [];

  @Watch('$route.query')
  private onQueryChanged() {
    this.init();
  }

  created() {
    if (!isEmpty(this.$route.query)) this.init();
  }

  mounted() {
    this.withdrawalMemberGrid.changeNoDataMessage(this.$t('MUST_SEARCH_WITHDRAWAL_MEMBER'));
  }

  private init(): void {
    this.query = getWithdrawalMemberInitQuery();
    Object.assign(this.query, this.$route.query);

    this.getWithdrawalMembers();
  }

  // get withdrawal member
  private getWithdrawalMembers(): void {
    if (this.query.searchType === 'MEMBER_NO' && !this.query.keyword) {
      this.query.searchType = 'ALL';
    }
    const request: GetWithdrawalMembersRequest = {
      params: this.query,
    };

    this.$api.getWithdrawalMembers(request).then(response => {
      if (response && response.status === 200) {
        this.totalCount = response.data.totalCount;
        this.contents = response.data.contents;

        const today = moment(getToday()).toDate();

        this.contents = this.contents.map(c => {
          const [year, month, day] = c.withdrawalDateTime.slice(0, 10).split('-');
          const withdrawalDateTime = new Date(addDay(new Date(year, month, day), 5));

          if ((today as any) - (withdrawalDateTime as any) > 0) {
            c.gracePeriod = true;
          }
          return c;
        });
      }
      this.withdrawalMemberGrid.changeNoDataMessage(this.$t('NO_RESULT'));
    });
  }

  // put withdrawal member restore
  private putWithdrawalMembersRestore(): void {
    const request = {
      data: {
        mallNo: this.query.mallNo,
        memberNumbers: this.selected,
      },
    };
    this.$api
      .putWithdrawalMembersRestore(request)
      .then(response => {
        if (response && response.status === 200) {
          let message = '';
          if (response.data.successCount > 0) {
            message += this.$t('MEMBER.WITHDRAWAL.ALERT_CANCEL_SUCCESS', { number: response.data.successCount });
          }
          if (response.data.failCount > 0) {
            if (message.length > 0) {
              message += '\r';
            }
            message += this.$t('MEMBER.WITHDRAWAL.ALERT_FAIL', {
              number: response.data.failCount,
              reason: response.data.failReasons,
            });
          }

          if (message.length > 0) {
            alert(message);
          }

          this.init();
        } else {
          alert(this.$t('ALERT_ERROR'));
        }
      })
      .catch(e => {
        if (e.status === 403) return;
        alert(this.$t('ALERT_ERROR'));
      });
  }

  // delete withdrawal member
  private deleteWithdrawalMembers(): void {
    const request = {
      params: {
        mallNo: this.query.mallNo,
        memberNos: this.selected.join(','),
      },
    };

    this.$api
      .deleteWithdrawalMembers(request)
      .then(response => {
        if (response && response.status === 200) {
          let message = '';
          if (response.data.successCount > 0) {
            message += this.$t('MEMBER.WITHDRAWAL.ALERT_DELETE_SUCCESS', { number: response.data.successCount });
          }
          if (response.data.failCount > 0) {
            if (message.length > 0) {
              message += '\r';
            }
            message += this.$t('MEMBER.WITHDRAWAL.ALERT_FAIL', {
              number: response.data.failCount,
              reason: response.data.failReasons,
            });
          }

          if (message.length > 0) {
            alert(message);
          }

          this.init();
        } else {
          alert(this.$t('ALERT_ERROR'));
        }
      })
      .catch(() => {
        // alert(this.$t('ALERT_ERROR'));
      });
  }

  // click cancel withdral member button
  private cancelWithdrawal(): void {
    if (this.selected.length === 0) {
      alert(this.$t('MEMBER.WITHDRAWAL.ALERT_NOT_SELECT'));
      return;
    }

    const arrTarget = this.contents.filter(c => {
      return this.selected.filter(s => s === c.memberNo).length > 0;
    });

    const arrTemp = arrTarget.filter(t => t.gracePeriod);

    if (arrTemp.length === arrTarget.length) {
      alert(this.$t('MEMBER.WITHDRAWAL.ALERT_CANCEL_IMPOSSIBLE'));
      return;
    }

    if (arrTemp.length > 0) {
      alert(this.$t('MEMBER.WITHDRAWAL.ALERT_CANCEL_IMPOSSIBLE_2'));
      return;
    }

    if (confirm(this.$t('MEMBER.WITHDRAWAL.ALERT_CANCEL_CONFIRM') as string)) {
      this.putWithdrawalMembersRestore();
    }
  }

  // click remove withdral member button
  private removeWithdrawal(): void {
    if (this.selected.length === 0) {
      alert(this.$t('MEMBER.WITHDRAWAL.ALERT_NOT_SELECT'));
      return;
    }

    if (confirm(this.$t('MEMBER.WITHDRAWAL.ALERT_DELETE_CONFIRM') as string)) {
      this.deleteWithdrawalMembers();
    }
  }

  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
  }
}
