import { render, staticRenderFns } from "./GoogleAd.vue?vue&type=template&id=36444d5a&scoped=true&"
import script from "./GoogleAd.vue?vue&type=script&lang=ts&"
export * from "./GoogleAd.vue?vue&type=script&lang=ts&"
import style0 from "./GoogleAd.vue?vue&type=style&index=0&id=36444d5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36444d5a",
  null
  
)

export default component.exports