export default Object.freeze({
  COMMENT1: '전자금융거래이용약관 내용은 쇼핑몰 운영방침에 따라 수정해주세요.',
  CHANGE_HISTORY: '변경이력',
  ELECTRONIC: '전자금융거래',
  TERMS_OF_USE: '이용약관 내용',
  USED: '사용함',
  NOT_USED: '사용 안 함',
  LOAD_BASIC_CONTENTS: '기본제공내용 불러오기',
  EFFECTIVE_DATE: '시행일',
  COMMENT2: '작성된 내용은 시행일자 이전까지만 수정 가능합니다. (시행일자 변경 후, 내용 수정 가능)',
  SAVE_CHANGES: '변경사항 저장',
  POPINFO1: '치환텍스트 목록',
  POPINFO2: "전자금융거래이용약관 '사용' 시, 치환텍스트를 통해 내용을 입력할 수 있습니다.",
  POPINFO3: '치환 텍스트',
  POPINFO4: '설명',
  POPINFO5: '예시',
  POPINFO6: '회사명',
  POPINFO7: '(주)홍길동',
  POPINFO8: '웹 도메인',
  POPINFO9: '시행일',
  POPINFO10: '2018년01월01일',
});
