export default Object.freeze({
  // CONTRACT_DOWNLOAD
  TITLE: '계약 서류 정보',

  NOTICE_1: 'ⓘ 파트너 어드민 등록 시 파트너사에서 등록한 계약 서류를 확인/변경할 수 있습니다.',
  NOTICE_2: 'ⓘ 모든 계약 서류는 정보 보안에 유의하여 관리해주세요.',
  NOTICE_3: 'ⓘ [파일변경] 버튼을 클릭하여 파일 업로드 시 기존 파일은 삭제됩니다.',
  NOTICE_4: 'ⓘ 파일은 jpg, jpeg, png, pdf파일로 등록 가능하며, 용량은 1개/10MB미만 입니다.',

  TABLE_HEADER: '계약 서류',
  UPDATE_CONTRACT_FILE: '파일변경',
  ADD_CONTRACT_FILE: '파일등록',
  CONTRACT_DOWNLOAD: '다운로드',
  CONTRACT_DOWNLOAD_ALL: '전체 다운로드',

  DOMESTIC_DOCUMENT_0: '사업자등록증',
  DOMESTIC_DOCUMENT_1: '통장사본',
  DOMESTIC_DOCUMENT_2: '법인인감증명서',

  GLOBAL_DOCUMENT_0: '계좌증빙서',

  DOCUMENT: '서류',

  ALERT_UPDATE_CONTRACT_FILE: '기존에 등록된 파일은 영구삭제되며 복원이 불가능합니다. 계약 서류를 변경하시겠니까?',
  ALERT_UPDATE_CONTRACT_FILE_COMPLETE: '계약 서류가 변경되었습니다.',

  //계약 서류 변경 이력
  CHANGE_CONTRACT_FILE_TITLE: '변경 계약 서류',
  ADMIN_ID: '운영자 ID',
});
