



































import { Vue, Component, Ref } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { Getter } from 'vuex-class';
import {
  Mall,
  NCPResponse,
  GetProductsExcelFailResultsRequest,
  GetProductsExcelRegisterFormRequest,
  PostProductsSearchByKeyRequest,
  PostProductsExcel,
} from 'ncp-api-supporter';
import UploadForm from '@/components/product/common/UploadForm.vue';
import { throwWindowPopup } from '@/helpers/popup';
import { setNoDataMessage } from '@/helpers/grid';
import { containerOptions, gridProps } from '@/views/contents/product/basic/ProductBulkAdd';
import { GridEventProps } from '@/types';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { openCopyProductPopup } from '@/components/product/common/girdColumns/ProductCopy';
import { getDownToday, getStrYMDHMSS, getToday } from '@/utils/dateFormat';
import { openSupplyPriceDetailsPopup } from '@/components/product/common/girdColumns/ProductSupplyPrice';
import { saveBinaryDataToFile } from '@/utils/fileSaver';
import { ExcelUploadType } from '@/types/ProductThumbnailRegistration';
import { PostProductsSearchByKey } from 'ncp-api-supporter/dist/types/modules/product/product';
import { Row } from '@/types/tui-grid';

@Component({
  components: { Grid, UploadForm },
})
export default class ProductBulkAdd extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  private gridProps = gridProps;
  private gridContainerOptions = containerOptions();
  private uploadDate = '';
  private mallProductNos: number[] = [];
  private successCount = 0;
  private productsTotalCount = 0;
  private gridTotalCount = 0;
  private productsData: PostProductsSearchByKey[] = [];
  private uploadedImages: string[] = [];
  private mallNo = '';

  @Ref() private readonly grid: Grid;

  created() {
    setNoDataMessage(this.$t('PRODUCT.BULK_IMAGE.NO_DATA').toString());
  }

  private async searchProducts() {
    const request: PostProductsSearchByKeyRequest = {
      data: {
        mallNos: [this.mallNo],
        mallProductNos: this.mallProductNos,
      },
    };
    try {
      const { data }: NCPResponse<PostProductsSearchByKey[]> = await this.$api.postProductsSearchByKey(request);
      this.productsData = data;
      this.gridTotalCount = data.length;
      this.uploadDate = getStrYMDHMSS(new Date());
    } catch (e) {
      console.error(e);
    }
  }

  private async postExcelFile({ excelFile, resultPopup, mallNo }: ExcelUploadType) {
    const formData = new FormData();
    formData.append('excelFile', excelFile);
    formData.append('mallNo', mallNo);
    formData.append('uploadedImages', this.uploadedImages.toString());
    try {
      const { data }: NCPResponse<PostProductsExcel> = await this.$api.postProductsExcelBulk({ data: formData });
      const { failCount, seq, successCount, successMallProductNos } = data;
      const totalCount = successCount + failCount;
      resultPopup({ successCount, failCount, totalCount });
      this.productsTotalCount = totalCount;
      this.successCount = successCount;
      if (failCount > 0) {
        const mallName = this.getMallName(mallNo);
        await this.downloadFailedExcel(seq, mallName);
      }

      if (successMallProductNos.length) {
        this.mallProductNos = successMallProductNos;
        await this.searchProducts();
      }
    } catch (e) {
      console.error(e);
    }
  }

  private getMallName(mallNo: string) {
    return this.malls.find(mall => mall.mallNo === Number(mallNo)).mallName;
  }

  private async downloadFailedExcel(failureNumber: number, mallName: string) {
    const fileName = `${mallName}_${this.$t('PRODUCT.BULK_PRODUCT.FAILED_EXCEL_FILE_NAME')}_${getDownToday()}.xlsx`;
    const params: GetProductsExcelFailResultsRequest['params'] = { seq: failureNumber, mallNo: Number(this.mallNo) };
    try {
      const { data } = await this.$api.getProductsExcelFailResults({ params, responseType: 'arraybuffer' });
      saveBinaryDataToFile(data, fileName);
    } catch (e) {
      console.error(e);
    }
  }

  private onItemClicked({ rowKey, columnName }: GridEventProps) {
    const rowInfo: Row = this.grid.getRowAt(rowKey);

    switch (columnName) {
      case 'edit':
      case 'mallProductNo':
        openEditProductWindow(Number(rowInfo.mallProductNo));
        break;
      case 'copy':
        openCopyProductPopup(rowInfo);
        break;
      case 'supplyPrice':
        openSupplyPriceDetailsPopup(rowInfo);
        break;
      default:
        break;
    }
  }

  private openImageUploadPopup() {
    throwWindowPopup(
      'ImageUpload',
      { mallNo: this.mallNo, uploadedFiles: this.uploadedImages },
      'md',
      ({ data }) => (this.uploadedImages = data),
      null,
      null,
      null,
      500,
    );
  }

  private async downloadSampleExcel() {
    try {
      const request: GetProductsExcelRegisterFormRequest = {
        params: {
          mallNo: Number(this.mallNo),
        },
      };
      const mallName = this.getMallName(this.mallNo);
      const fileName = `${mallName}_${this.$t('PRODUCT.BULK_PRODUCT.EXCEL_SAMPLE_FILE_NAME')}_${getToday(
        'YYYYMMDD',
      )}.xlsx`;
      const { data }: NCPResponse<ArrayBuffer> = await this.$api.getProductsExcelRegisterForm(request);
      saveBinaryDataToFile(data, fileName);
    } catch (e) {
      console.error(e);
    }
  }
}
