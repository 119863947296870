
















































import { Component, Mixins, PropSync, Ref } from 'vue-property-decorator';
import { ArticleFile, CallbackForImage, ImageFileUpload, PostedImagesResponse } from '@/types';
import ImageFileInputMixin from '@/components/common/input/imageFileInput/ImageFileInputMixin.vue';
import { ExtensionType, MAX_ADDABLE_SIZE } from '@/const/contents/board';

/**[TODO]
 * Uploading attached file other than image until releated api is added at next phase of improvement,
 * now, it is only for image attached file uploading
 * */
@Component
export default class ListAttached extends Mixins(ImageFileInputMixin) implements ImageFileUpload {
  @PropSync('articleAttacheds')
  private articleAttachedsSync!: ArticleFile[];

  callbackForPostedImage(): CallbackForImage {
    return this.getCallback();
  }

  resetImageInput(): void {
    this.fileInputRef.value = '';
  }

  private get imageList(): ArticleFile[] {
    //const exts = ExtensionType.split(',');
    return this.articleAttachedsSync.filter(item =>
      ExtensionType.some(ext => ext === this.getFileExtensionType(item.originalName)),
    );
  }

  private get otherFileList(): ArticleFile[] {
    //const exts = ExtensionType.split(',');
    return this.articleAttachedsSync.filter(item =>
      ExtensionType.every(ext => ext !== this.getFileExtensionType(item.originalName)),
    );
  }

  private getFileExtensionType(fileName: string): string {
    let ext = '';
    if (fileName) {
      const nArr = fileName.split('.');
      ext = nArr[nArr.length - 1];
      if (ext) {
        ext = '.' + ext.toLocaleLowerCase();
      }
    }
    return ext;
  }

  private get isAddable(): boolean {
    const attachedTotalCount = this.articleAttachedsSync.length;
    return attachedTotalCount < MAX_ADDABLE_SIZE; //max:10
  }

  @Ref()
  private readonly fileInputRef!: HTMLInputElement;

  private addFile(): void {
    if (!this.isAddable) {
      alert(this.$t('BOARD.BASIC.ARTICLE.FILE_UPLOAD_MAX_10'));
      return;
    }
    this.fileInputRef.click();
  }

  private getCallback(): CallbackForImage {
    return (data: PostedImagesResponse) => {
      this.articleAttachedsSync.push({
        originalName: data.file.name,
        uploadedName: data.url,
      });
    };
  }

  private remove(idx: number): void {
    if (confirm(this.$t('BOARD.BASIC.ARTICLE.DELETE_CONFIRM') as string)) {
      this.articleAttachedsSync.splice(idx, 1);
    }
  }

  /**
   * Download image of attached.[TODO for other type file]
   * 1. get download url: uploadedFileName [toast domain]
   * 2. download file by XMLHttpRequest
   */
  private downloadFile(attachedImage: ArticleFile) {
    // 1
    const uploadedFileName = attachedImage.uploadedName;
    const originalFileName = attachedImage.originalName;
    // 2
    if (uploadedFileName) {
      const url = uploadedFileName;
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.open('GET', url, true);
      xmlhttp.responseType = 'blob';
      xmlhttp.onload = function() {
        if (this.status == 200) {
          const blob = this.response;
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = originalFileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      xmlhttp.send();
    }
  }
}
