










































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { ContractfilesMallsResponse } from 'ncp-api-supporter/dist/types/modules/admin/contractFile';
import { throwWindowPopup } from '@/helpers/popup';
import ContractFileForm from '@/components/configuration/ContractFileForm.vue';

interface DomesticFileType {
  domestic: boolean;
  originalName: string;
  displayOrder: number;
  uploadedName: string;
}

@Component({
  components: { ContractFileForm, TextInput, ToolTip, RadioGroup, SelectBox },
})
export default class ContractFileManagement extends Vue {
  @Prop({ required: true })
  private readonly mallNo!: string;

  @Prop()
  private contractInfo: ContractfilesMallsResponse;
  private domesticFiles: DomesticFileType[] = [];
  private globalFiles: DomesticFileType[] = [];
  private domesticGuide = '';
  private globalGuide = '';
  private standardContractContent = '';
  private initialContractData: DomesticFileType = {
    originalName: '',
    uploadedName: '',
    domestic: false,
    displayOrder: 3,
  };

  created() {
    this.initStandardContract();
  }

  @Watch('contractInfo')
  private initContractFiles() {
    const { domesticFiles, globalFiles, domesticGuide, globalGuide } = this.contractInfo;

    this.domesticFiles = domesticFiles.length ? [...domesticFiles] : [{ ...this.initialContractData }];
    this.globalFiles = globalFiles.length ? [...globalFiles] : [{ ...this.initialContractData }];
    this.domesticGuide = domesticGuide;
    this.globalGuide = globalGuide;
  }

  private initStandardContract() {
    this.$api
      .getContractsEntryConfigurations({
        params: {
          mallNo: Number(this.mallNo),
        },
      })
      .then(res => {
        if (!res.data.entryContractContentsOfMall) return;
        this.standardContractContent = res.data.entryContractContentsOfMall;
      });
  }

  private addGuide({ guideType, content }) {
    this.$emit('addGuide', { guideType, content });
  }

  private addStandardContractFile() {
    throwWindowPopup(
      'StandardContractFilePopup',
      { mallNo: this.mallNo, content: this.standardContractContent },
      'md',
      this.registStandardContract,
      null,
    );
  }

  private addFields(fileType) {
    this[fileType].push({ ...this.initialContractData });
  }

  private removeField({ fileType, idx }) {
    this[fileType].splice(idx, 1);
  }

  private removeCurrentFile({ fileType, idx }) {
    this.$set(this[fileType], idx, { ...this.initialContractData });
  }

  private uploadFile({ fileType, idx, file }) {
    this.$set(this[fileType], idx, file);
    this.$emit('addContract', { fileType, files: this[fileType].map(file => file.uploadedName) });
  }

  private registStandardContract(returnData) {
    if (!returnData.data) return;

    this.standardContractContent = returnData.data.content;
    this.$emit('addStandardContract', returnData.data.content);
  }

  private delStandardContractFile() {
    if (!confirm(this.$t('MALL.FORM.STANDING_DELETE_ALERT') as string)) return;
    this.standardContractContent = '';
    this.$emit('addStandardContract', '');
  }
}
