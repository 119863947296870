export default Object.freeze({
  TITLE: '전시상품 우선순위 관리',
  DESCRIPTION1: '전시 우선순위를 설정할 상품을 등록/삭제하실 수 있습니다.',
  DESCRIPTION2: '전시상품 우선순위를 ▲▼ 버튼을 통해 설정하실 수 있습니다.',
  DESCRIPTION3: '설정된 전시상품 우선순위로 사용자 화면에 상품 노출 순서를 관리할 수 있습니다.',
  DELETE_PRODUCT_INQUIRY: '상품조회 삭제',
  PRODUCT_INQUIRY: '상품조회',
  PRODUCT_NUMBER: '상품번호',
  PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  PRODUCT_MANAGEMENT_CODE_PARTIAL_SEARCH: '상품관리코드|부분검색',
  ADD_PRODUCT: '상품추가',
  // PRODUCT_NUMBER: '상품번호',
  // PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  DELETE_SELECTED_PRODUCT: '선택상품 삭제',
  TOP: '최상단',
  BOTTOMMOST: '최하단',
  SAVE_CHANGES: '변경사항 저장',

  // DELETE_PRODUCT_INQUIRY: '상품조회 삭제',
  PRODUCT_REGISTRATION: '상품등록',
  PRODUCT_INQUIRY_REGISTRATION: '상품조회 등록',
  DIRECT_REGISTRATION_OF_NUMBER: '번호 직접등록',
  PRIORITY_ITEMS_ON_DISPLAY_TOTAL: '전시상품 우선순위 상품 총',
  ITEM: '개',

  PLACEHOLDER1: "상품번호로 복수 상품 등록이 가능합니다. (Enter 또는 ','로 구분)",
  PLACEHOLDER2: "상품관리코드로 복수 상품 등록이 가능합니다. (Enter 또는 ','로 구분)",

  // PRODUCT_NUMBER: '상품번호',
  // PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  PRODUCT_NAME: '상품명',
  BRAND: '브랜드',
  PARTNER: '파트너사',
  APPROVAL_STATUS: '승인상태',
  WAITING_FOR_REGISTRATION: '등록대기',
  WAITING_FOR_APPROVAL: '승인대기',
  REJECTION_OF_APPROVAL: '승인거부',
  WAITING_FOR_SALES_AGREEMENT: '판매합의대기',
  REJECTION_OF_SALES_AGREEMENT: '판매합의거부',
  WAITING_FOR_APPROVAL_AFTER_MODIFICATION: '수정 후 승인대기',
  REJECT_APPROVAL_AFTER_MODIFICATION: '수정 후 승인거부',
  APPROVAL_COMPLETED: '승인완료',
  SALES_STATUS: '판매상태',
  BEFORE_APPROVAL_COMPLETION: '승인완료이전',
  WAITING_FOR_PRE_ORDER: '예약판매대기',
  PRE_ORDER: '예약판매중',
  WAITING_FOR_SALE: '판매대기',
  SALE: '판매중',
  END_OF_SALE: '판매종료',
  SALES_SETTING_STATUS: '판매설정 상태',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION_OF_SALE: '판매금지',
  EXHIBITION_STATUS: '전시상태',
  DISPLAY_POSSIBLE: '전시가능',
  NO_EXHIBITION: '전시안함',
  DISCOUNT_APPLICABLE_PRICE: '할인적용가',
  AVAILABLE_STOCK: '재고수량',
  SOLD_OUT: '품절',
  DELETE: '삭제',

  // NO_EXHIBITION: '전시안함',
  // DISPLAY_POSSIBLE: '전시가능',
  NO_DELIVERY: '배송안함',
  DELIVERY: '배송',
  BUY: '사입',
  CONSIGNMENT: '위탁',

  IS_MODIFIED: '저장되지 않은 내용이 있습니다. 쇼핑몰을 이동 하시겠습니까?',
  GRID_LENGTH_ALERT: '상품을 선택해주세요',
  GRID_MANY_ROWS_ALERT:
    '선택하신 상품을 전시상품 우선순위에서 삭제하시겠습니까? 하단 변경사항 저장버튼을 통해 최종 삭제됩니다.',
  PLEASE_SELECT_ALERT: '이동할 상품을 선택해 주세요',
  CONFIRM1: '해당 상품',
  COMFIRM2: '을 전시상품 우선순위에서 삭제하시겠습니까? 하단 변경사항 저장버튼을 통해 최종 삭제됩니다.',
  MESSAGE1: '브라우저 다른 거 사용해주세요.. ㅜㅜ',
  // eslint-disable-next-line quotes
  ERROR1: `'fileId'를 잘 넣으라구 친구!`,
  ERROR2: '파일선택이 완료되었을 경우에만 호출하라구 친구!',

  // PRODUCT_NUMBER: '상품번호',
  // PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  REGISTRATION_RESULT: '등록결과',
  ORDER: '순서',
  FAILURE: '실패',
  SUCCESS: '성공',
  REGISTRATION_FAILED_MSG: '등록 실패하였습니다. 확장자명을 다시 확인해주세요',
  // PRODUCT_NUMBER: '상품번호',
  // PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  YOU_CAN_ONLY_ENTER_NUMBERS_AND_COMMAS: '숫자와 콤마만 입력할 수 있습니다.',
  THE_PRODUCT_NUMBER_CAN_BE_UP_TO_10_CHARACTERS_LONG: '상품 번호는 10자 이하로 입력할 수 있습니다.',
  PRODUCTS_HAVE_BEEN_REGISTERED: '개 상품이 등록되었습니다. 하단 변경사항 저장버튼을 통해 최종으로 저장됩니다.',
  THERE_ARE_NO_PRODUCTS_AVAILABLE_FOR_REGISTRATION: '등록 가능한 상품이 없습니다.',
  OUTSIDE: ' 외',
  // ITEM: '개',
  PRODUCT_ENTERED: '입력하신 상품 ',
  AMONG_THE_PRODUCTS_THAT_CANNOT_BE_REGISTERED: '개 중 등록 불가능한 상품이 ',
  // ITEM: '개',
  WOULD_YOU_LIKE_TO_SAVE: ' 있습니다. 해당 상품을 제외하고 저장하시겠습니까?',
  THE_PRODUCT_THAT_CANNOT_BE_REGISTERED: '입력하신 상품관리코드 중 등록 불가능한 상품이 ',
  WOULD_YOU_LIKE_TO_SAVE_IT_WITHOUT: '개 있습니다. 해당 상품을 제외하고 저장하시겠습니까?',
  PLEASE_ENTER_YOUR_PRODUCT_NUMBER: '상품번호를 입력해주세요.',
  // DELETE: '삭제',
  ADD: '추가',
  PRODUCT_INQUIRY_REGISTRATION_POP: '상품조회 등록 팝업',
  SANG_FORM_INQUIRY_DELETE_POPUP: '상폼조회 삭제 팝업',

  EMPTYALERT: '상품을 선택해주세요.',
  ADDCONFIRM1: '선택한 상품을 전시상품 우선순위에',
  ADDCONFIRM2: '서',
  ADDCONFIRM3: '하시겠습니까?<br>전시상품 우선순위 관리의 변경사항 저장버튼을 통해 최종 ',
  ADDCONFIRM4: '됩니다.',

  ADDALLCONFIRM1: '조회된 상품을 전시상품 우선순위에',
  ADDALLCONFIRM2: '서',
  ADDALLCONFIRM3: '전체 ',
  ADDALLCONFIRM4: '하시겠습니까?<br>전시상품 우선순위 관리의 변경사항 저장버튼을 통해 최종 ',
  ADDALLCONFIRM5: '됩니다.',

  ADDSELECTEDBTN: '선택상품 삭제',
  ADDALLBTN: '검색결과 전체 삭제',

  NO_REG_PRODUCT_ALERT: '등록된 상품이 없습니다.',

  HAS_BEEN_SAVED: '저장되었습니다.',
});
