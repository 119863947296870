







































import { Vue, Component, Ref } from 'vue-property-decorator';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import {
  displayOptions,
  getDefaultQuery,
  getShippingChargeTemplateGridOption,
  i18nForDeliveryGroupTemplate,
} from '@/const/contents/configuration/shippingCharge';
import Grid from '@/components/common/grid/Main.vue';
import { GridEventProps, Mode } from '@/types';
import { PopupClose, throwPopup, throwWindowPopup } from '@/helpers/popup';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  DeliveryTemplate,
  GetDeliveryTemplateSingleRequest,
} from 'ncp-api-supporter/dist/types/modules/delivery/deliveryTemplate';
import { MODE } from '@/const/common';
import ShippingChargeSearchForm from '@/views/contents/configuration/delivery/ShippingChargeSearchForm.vue';
import { setNoDataMessage } from '@/helpers/grid';

@Component({
  components: {
    ShippingChargeSearchForm,
    TextInput,
    SearchButton,
    DateRangePicker,
    SelectBox,
    Grid,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'ShippingChargeAreaFee') {
      this.resetNoDataMessage = false;
    }
    next();
  },
})
export default class ShippingChargeTemplate extends Vue {
  private i18nForDeliveryGroupTemplate = i18nForDeliveryGroupTemplate;

  private resetNoDataMessage = true;

  private gridProps = getShippingChargeTemplateGridOption();
  private displayOptions = displayOptions;

  private query = { ...getDefaultQuery() } as GetDeliveryTemplateSingleRequest['params'];

  private contents: DeliveryTemplate[] = [];
  private totalCount = 0;

  private mode: Mode = null;
  private delete(templateNo: number) {
    if (!confirm(this.$t('CONFIRM_DELETE').toString())) return;
    this.$api
      .deleteDeliveryTemplateGroupsByGroupNo({
        params: {
          templateGroupNo: this.getDeliveryTemplate(templateNo).templateGroupNo,
        },
      })
      .then(() => {
        alert(this.$t('ALERT_DELETED'));
        this.reset();
      });
  }

  private async deleteTemplate(templateNo: number) {
    const { data } = await this.$api.getDeliveryTemplateCheckUsing({
      params: {
        templateNo,
      },
    });
    data.using ? alert(this.i18nForDeliveryGroupTemplate('ALERT_NOT_DELETED_TEMPLATE')) : this.delete(templateNo);
  }

  private openDeliveryTemplateFormPopup(templateNo?: number): void {
    this.mode = templateNo > 0 ? MODE.EDIT : MODE.REGISTER;
    const template = this.contents.filter(c => c.templateNo === templateNo)[0];

    throwPopup({
      name: 'DeliveryTemplateForm',
      data: { mode: this.mode, template },
    }).then(result => {
      if (result && result.state === PopupClose.CONFIRM) {
        if (this.mode === MODE.REGISTER) {
          this.query.page = 1;
        }
        this.reset();
      }
    });
  }

  @Ref()
  private readonly searchForm: ShippingChargeSearchForm;
  private reset() {
    this.searchForm.getDeliveryTemplateSingle();
  }

  private openAppliedDeliveryTemplatePopup(templateNo: number) {
    throwWindowPopup('AppliedDeliveryTemplatePopup', this.getDeliveryTemplate(templateNo), 'lg');
  }

  private getDeliveryTemplate(templateNo: number): DeliveryTemplate {
    return this.contents.find(content => content.templateNo === templateNo);
  }

  @Ref()
  private readonly grid: Grid;
  private onItemClicked({ columnName, rowKey }: GridEventProps) {
    switch (columnName) {
      case 'templateName':
        this.openDeliveryTemplateFormPopup(rowKey as number);
        break;
      case 'product':
        this.openAppliedDeliveryTemplatePopup(Number(rowKey));
        break;
      case 'templateGroupNo':
        this.deleteTemplate(Number(rowKey));
    }
  }

  private created() {
    setNoDataMessage('등록된 템플릿이 없습니다.');
  }
}
