



































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { OptionData } from '@/helpers/type';
import TextInput from '@/components/common/input/TextInput.vue';
import { INITIAL_DIGIT } from '@/views/contents/product/basic/ProductAdd';
import {
  CommissionType,
  ErrorCode,
  ErrorType,
  SalesInfoCalculator,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { ErrorCodeType, InputNumber, SalesInfoCalculatorErrorInfo } from '@/types';
import { CommissionRateType } from 'ncp-api-supporter';
import { formatCurrency, getOnlyNumbers } from '@/utils/numberFormat';
import NumberWithCommaFormatterMixin from '@/views/contents/product/basic/ProductAdd/salesInfo/NumberWithCommaFormatterMixin.vue';
import { Getter } from 'vuex-class';
import { PARTNER_TYPE } from '@/const/common';
import { PartnerType } from '@/const/contents/partner';

export interface CommissionOption {
  rate: InputNumber;
  type: CommissionRateType;
}

@Component({
  components: { TextInput, ToolTip, SelectBox },
})
export default class CommissionInfo extends Mixins(NumberWithCommaFormatterMixin) {
  @Getter('partner/getPartnerType') private readonly selectedPartnerType: PartnerType;
  @Getter('partner/getPartnerNo') private readonly selectedPartnerNo: number;
  @PropSync('commissionInfo')
  private commissionInfoSync!: CommissionOption;

  @PropSync('supplyPrice')
  private supplyPriceSync!: number;

  @Prop()
  private readonly mallNo!: InputNumber;

  @Prop({ required: true })
  private readonly categoryNo: number;

  @Prop({ required: true })
  private readonly isMapping: boolean;

  @Prop({ required: true })
  private readonly isPurchaseTypeBySaleMethod: boolean;

  @Prop()
  private readonly isSelectedMall: boolean;

  @Prop()
  private readonly defaultCategoryRate!: number;

  @Prop()
  private readonly defaultSupplyPrice: number;

  @Prop()
  private readonly useOption!: boolean;

  @Prop()
  private readonly calculator!: SalesInfoCalculator;

  @Prop({ required: true })
  private readonly defaultCommissionType: CommissionRateType;

  @Prop({ required: true })
  private readonly defaultCommissionRate: number;

  @Prop({ required: true })
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly formError;

  @Prop({ required: true })
  private readonly formErrorType;

  private selectOptions: OptionData<CommissionType>[] = [
    // 판매수수료 상품수수료, 카테고리수수료, 파트너 수수료, 공급가입력 수수료
    { name: 'PRODUCT.ADD.COMMISSION_INFO_PRODUCT', value: CommissionType.PRODUCT },
    { name: 'PRODUCT.ADD.COMMISSION_INFO_CATEGORY', value: CommissionType.CATEGORY },
    { name: 'PRODUCT.ADD.COMMISSION_INFO_PURCHASE_PRICE', value: CommissionType.PURCHASE },
  ];
  private isPartnerBaseFeeConfig: boolean;

  @Watch('isPurchaseTypeBySaleMethod')
  private setCommissionType() {
    // 사입으로 변경 시 상품 수수료 0% 로 초기화
    this.commissionInfoSync.type = 'PRODUCT';
    this.commissionInfoSync.rate = 0;
    this.blurredCommissionAmount(0);
    this.changedCommissionType(CommissionType.PRODUCT);
  }

  private alertMallSelect(): void {
    this.$emit('alertMallSelect');
  }

  private get isProductType(): boolean {
    return this.commissionInfoSync.type === CommissionType.PRODUCT;
  }

  private get isPurchaseType(): boolean {
    return this.commissionInfoSync.type === CommissionType.PURCHASE;
  }

  private get isCategoryType(): boolean {
    return this.commissionInfoSync.type === CommissionType.CATEGORY;
  }

  private get isPartnerType(): boolean {
    return this.commissionInfoSync.type === CommissionType.PARTNER;
  }

  private get showsInput(): boolean {
    if (this.isMapping) {
      return !this.isPurchaseType;
    }
    return !this.useOption || !this.isPurchaseType;
  }

  private checkSelectedMall(): void {
    if (!this.isSelectedMall) {
      this.alertMallSelect();
    }
  }

  @Watch('categoryNo')
  private setCategoryCommissionType() {
    // 카테고리 번호가 변화되면 카테고리로 리셋 -> 문제 생기면 임시 카테고리 번호 만들어서
    // 수정이나 복사 시 갖고 있는 카테고리 번호와 처음 비교하여 아래부분 리턴 시키고, null 로 세팅하기
    if (this.categoryNo >= 0) {
      this.commissionInfoSync.type = CommissionType.CATEGORY;
      this.commissionInfoSync.rate = this.defaultCategoryRate;
      this.changedCommissionType(CommissionType.CATEGORY);
    }
  }

  private changedCommissionType(type: CommissionType): void {
    this.formattedNumber = type === CommissionType.PURCHASE ? this.defaultSupplyPrice : this.supplyPriceSync;
    this.commissionInfoSync.rate =
      type === CommissionType.CATEGORY ? this.defaultCategoryRate : this.commissionInfoSync.rate;
    this.calculator.changeCommissionType(type);
    this.updateCategoryCommissionRate();
  }

  @Watch('defaultCategoryRate')
  private updateCategoryCommissionRate(): void {
    if (this.isProductType) {
      this.blurredCommissionAmount(Number(this.commissionInfoSync.rate));
      return;
    }
    this.blurredCommissionAmount(this.isCategoryType ? this.defaultCategoryRate : INITIAL_DIGIT);
  }

  private blurredCommissionAmount(commission: number): string {
    try {
      this.resetError();
      this.setCommissionAmount(commission);
      this.setSupplyPrice();
      return this.isPurchaseType ? commission.toString() : formatCurrency(commission);
    } catch (e) {
      this.errorHandler(e.errorInfo);
      this.resetDiscountAmountAndSupplyPrice();
    }
  }

  private setCommissionAmount(commission: InputNumber): void {
    this.calculator.changeCommission(commission);
    this.commissionInfoSync.rate = this.calculator.commissionAmount;
  }

  private setSupplyPrice(): void {
    if (this.isPurchaseType) {
      // 공급가
      this.supplyPriceSync = this.formattedNumber === '' ? 0 : Number(this.formattedNumber);
    } else {
      // 수수료
      this.supplyPriceSync = Math.max(0, this.calculator.supplyPriceResult);
    }
  }

  private resetDiscountAmountAndSupplyPrice(): void {
    this.commissionInfoSync.rate = '';
    this.setCommissionAmount(this.commissionInfoSync.rate);
    this.setSupplyPrice();
  }

  private ErrorCode = ErrorCode;
  private errorCode: ErrorCodeType | '' = '';
  private errorHandler(error: SalesInfoCalculatorErrorInfo): void {
    if (error.type === ErrorType.ALERT) {
      alert(this.$t(error.msg));
      return;
    }
    this.errorCode = error.code as ErrorCodeType;
  }

  private resetError(): void {
    this.errorCode = '';
  }

  private initFlag = false;
  @Watch('defaultSupplyPrice')
  private initSupplyPrice() {
    this.initFlag = true;
    this.formattedNumber = this.defaultSupplyPrice;
    this.supplyPriceSync = this.defaultSupplyPrice;
  }

  @Watch('defaultCommissionType')
  private setDefaultCommissionType() {
    if (this.defaultCommissionType === null) return;
    this.commissionInfoSync.type = this.defaultCommissionType;
  }

  @Watch('defaultCommissionRate')
  private setDefaultCommissionRate() {
    if (this.defaultCategoryRate === null) return;
    this.commissionInfoSync.rate = this.defaultCommissionRate;
  }

  @Watch('useOption')
  private setSupplyPriceByUseOptionStatus() {
    if (this.useOption) return;
    if (this.commissionInfoSync.type === CommissionType.PURCHASE) {
      this.changedCommissionType(CommissionType.PURCHASE);
      this.supplyPriceSync = getOnlyNumbers(this.formattedNumber.toString());
    }
  }

  @Watch('selectedPartnerType')
  private setCommissionOptions() {
    //"파트너사 : 쇼핑몰 자체 상품"선택한 경우 또는 "파트너사 설정: 기본수수료 사용안함"으로 설정되어 있는 경우 -> "판매수수료"미노출
    if (this.selectedPartnerType === PARTNER_TYPE.SHOP || !this.isPartnerBaseFeeConfig) {
      this.selectOptions = this.selectOptions.filter(option => option.value !== CommissionType.PARTNER);
      return;
    }
    const partnerOptionData = { name: 'PRODUCT.ADD.COMMISSION_INFO_PARTNER', value: CommissionType.PARTNER };
    this.selectOptions.splice(2, 0, partnerOptionData);
  }

  @Watch('selectedPartnerNo')
  private async fetchGetContracts() {
    if (this.selectedPartnerType === PARTNER_TYPE.SHOP || !this.selectedPartnerNo) return; //as resetPartnerState
    const { data } = await this.$api.getContracts({ params: { partnerNo: this.selectedPartnerNo } });
    this.isPartnerBaseFeeConfig = data.contents.some(content => content.usesDefaultCommissionRate);
    this.setCommissionOptions();
  }
}
