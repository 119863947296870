














import { Vue, Component } from 'vue-property-decorator';
import { i18nListByKey } from '@/utils/i18n';
import { Route } from 'vue-router';

interface MemberTabMenu {
  display: string;
  name: string;
}

@Component
export default class AccumulationTabMenu extends Vue {
  private menuList: MemberTabMenu[] = [
    { display: 'ACCUMULATION.TITLE_MANUAL', name: 'AccumulationPaymentDeductManual' },
    { display: 'ACCUMULATION.TITLE_HISTORY', name: 'AccumulationPaymentDeductHistory' },
  ];

  private get currentName(): Route['name'] {
    return this.$route.name;
  }

  created() {
    this.menuList = i18nListByKey(this, this.menuList, 'display');
  }
}
