






import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({ components: { TextInput } })
export default class TdAdditionalPayRemitter extends Vue {
  @Prop({ required: true })
  private readonly isModifyMode: boolean;

  @Prop({ required: true })
  private readonly showModifyButton: boolean;

  @PropSync('additionalPayRemitter')
  private additionalPayRemitterSync: string;
}
