





























































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import unsavedDetector from '@/directives/input/unsavedDetector';
import { TranslateResult } from 'vue-i18n';
const emojiRegex = require('emoji-regex');

export interface MessageAlert {
  visible: boolean;
  message: TranslateResult;
}

// TODO : 필요시 추가 후 공지하세요.
const TextInputValidationRegexes = {
  number: /[^0-9]/g, // 숫자만 가능
  numbers: /[^0-9.*,.*\s\n]/g, // 숫자, 콤마, 스페이스, 엔터 가능
  productNos: /[^0-9,\n]/g, //숫자, ',' 엔터 가능
  currency: /[^0-9,\s]/g, //숫자, ',' 공백만 가능
  'negative-num': /[^0-9-]+/g, //숫자, '-'만 가능 (음수)
  'decimal-num': /[^0-9.]+/g, //숫자, '.'만 가능 (소수)
  'no-special': /[^a-zA-Z0-9ㄱ-힣]/g, // 한글, 영문, 숫자만 가능
  'no-part-special': /['"‘”<>\\`(),:;@[\]\s]/g, // 일부 특수문자(‘”<>\`(),:;@[])와 공백 불가
  'no-special-space': /[^a-zA-Z0-9ㄱ-힣\s]/g, // 한글, 영문, 숫자, 공백만 가능
  'no-space': /\s/g, // 공백만 불가
  'no-ko': /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, // 한글만 불가
  'no-emoji': emojiRegex(),
  'no-common-special': /['"<>₩\\‘”`]/g, // 공통 제한 문자 불가
  'no-common-special-code': /['"<>₩?\\*\\‘”`]/g, // 공통 제한 문자 불가
  'option-code': /[\\*?'"‘”`<>]/g,
  'no-quotation': /['"‘”]/g, // ('), (") 따옴표, 쌍따옴표 불가
  'outbound-email-id': /[^a-z0-9_.+-]/g, // 소문자, 숫자, _.+- 만 가능
  'partner-email-id': /[^a-zA-Z0-9+\-_!#$%&*/=?^`{|}~.']/g, // 대소문자, 숫자, _.+- 만 가능
  'partner-email-domain': /[^a-zA-Z0-9\-.]/g, // 소대문자, 숫자, . + - 만 가능
  'email-id': /[^A-Za-z0-9._-]/g, // 영문, 숫자, '.', '_', '-'만 가능 (공백 불가)
  'email-domain': /[^a-z0-9.-]/g, // 영문, 숫자, '.', '-' 만 가능 (공백 불가)
  'mall-name': /[^a-zA-Z0-9ㄱ-힣+-.,_\s]/g, // 영문(대소문), 숫자, 한글, '.', ',', '_', '-'만 가능 (공백 불가)
  'registration-id': /[^a-z0-9-.,_-s@]/g, // 영문, 숫자, '.', '_', '-', '@'만 가능 (공백 불가)
  'admin-id': /[^a-z0-9]+/g, // 영문 소문자, 숫자만 가능 (공백 불가)
  // 'management-cd': /[^a-zA-Zㄱ-힣0-9,\s\n]/g, // 한글, 영문, 숫자, 콤마, 스페이스, 엔터 가능
  'management-cd': /[^0-9a-zA-Z]/g, // 영문, 숫자 가능 1차 임시처리임
  'hs-cd': /[\\`'"*?<>₩]/g,
  reason: /['"‘”<>\\`]/g, // ‘ “ < > \ ` 는 입력 불가
  'invoice-no': /[^0-9a-zA-Z]/g, // 영문 대소문자, 숫자만 가능 (공백 불가)
  'invoice-no-underline': /[^0-9a-zA-Z_]/g,
  'url-validate': /[^0-9a-zA-Z-_.~!*'();:@&%=+$,\\/?#[\]]/g,
  'option-value': /[|]/g, // |만 불가
  'partner-name': /[^a-zA-Z0-9ㄱ-힣-.&,_\s]/g, // 파트너 등록시. 영문(대소포함), 한글, - . & , _ 공백 가능.
  'board-id': /[^0-9a-z-]/g, // 영문, 한글, 하이픈만 허용
};

export type TextInputValidationRegex = keyof typeof TextInputValidationRegexes;

@Component({
  directives: { unsavedDetector },
})
export default class TextInput extends Vue {
  @Prop({ default: false })
  private isTextArea!: boolean;
  @Prop({ default: '150px' })
  private width!: string;
  @Prop({ default: 'left' })
  private readonly textAlign: 'left' | 'right' | 'center';
  @Prop({ default: 0 })
  private maxLength!: number;
  @Prop({ default: false })
  private showsCount!: boolean;
  @Prop({ default: '' })
  private countText!: string;
  @Prop({ default: false })
  private disabled!: boolean;
  @Prop({ default: false })
  private readonly!: boolean;
  @Prop({ default: '' })
  private placeholder!: string;
  @Prop({ default: '' })
  private description!: string;
  @Prop({ default: '' })
  private labelText!: string;
  @Prop({ default: '' })
  private name!: string;
  @Prop({ default: '' })
  private value!: string;
  @Prop({ default: '' })
  private validType!: '' | TextInputValidationRegex;
  @Prop({ default: null })
  private valueChangedReplaceHook: (value: string) => string | null;
  @Prop({ default: null })
  private valueChangedWhenBlur: (value: string) => string | null;
  @Prop({ default: false })
  private autoFocus: boolean;
  @Prop({ default: false })
  private id!: string | false; // false 시 id 렌더 안함
  @Prop({ default: null })
  private tabIndex!: number;
  @Prop({ default: false })
  private password!: boolean;
  @Prop({ default: null })
  private messageAlert!: MessageAlert;
  @Prop({ default: true })
  private isClassName!: boolean;
  @Ref('input')
  readonly input: HTMLInputElement;
  @Ref('textarea')
  readonly textarea: HTMLTextAreaElement;

  private validateHelper = 0;

  // TODO : width 100% + 글자카운터 시 줄바꿈 되는 문제 : 마크업 필요
  private get fullStyle() {
    const width = this.width;
    return width === '100%' ? { width } : {};
  }

  private get textLength() {
    return this.value ? this.value.length : 0;
  }

  private get listeners(): Record<string, Function | Function[]> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { input, ...listeners } = this.$listeners;
    return listeners;
  }

  private onTextChanged() {
    const regex = TextInputValidationRegexes[this.validType];
    const $el = this.isTextArea ? this.textarea : this.input;

    // 추가적인 validation regex 가 필요하다면 TextInputValidationRegexes 를 수정할것
    if (regex) $el.value = $el.value.replace(regex, '');

    //상품정보에 HSCODE 를 특정 정보로 사용할 수 있도록 입력 제한을 풀어주기로 협의했습니다.
    if (this.validType === 'hs-cd') {
      $el.value = $el.value.replace(emojiRegex(), '');
    }

    if (this.valueChangedReplaceHook) $el.value = this.valueChangedReplaceHook($el.value);
    if (this.maxLength) this.checkMaxLength($el);
    if (this.autoFocus) this.focus();
    this.$emit('input', $el.value);
  }

  public focus(): void {
    const $el = this.isTextArea ? this.textarea : this.input;

    $el.focus();
  }

  private checkMaxLength(el: HTMLTextAreaElement | HTMLInputElement) {
    const maxLength = Number(this.maxLength);
    if (el.value.length > maxLength) {
      el.value = this.value;
    }
  }

  public onBlurTextArea() {
    const $el = this.isTextArea ? this.textarea : this.input;

    if (this.validateHelper >= 1) {
      this.validateHelper = 0;
      return;
    }

    if (this.valueChangedWhenBlur) {
      $el.value = this.valueChangedWhenBlur($el.value);
      this.$emit('on-input-blur', $el.value);
      if (this.autoFocus) {
        this.focus();
        this.validateHelper++;
      }
      return;
    }
    this.onTextChanged();
  }
}
