




























import { Vue, Component, Ref } from 'vue-property-decorator';
import Slick from 'vue-slick';
import { Banner } from '@/types/addition';
import { banners } from '@/views/contents/addition/basic/AdditionalService';
import { PromotionSlider } from '@/components/addition/PromotionSlider';

@Component({ components: { Slick } })
export default class PromotionBanner extends Vue {
  @Ref()
  private readonly slideElement!: HTMLUListElement;
  @Ref()
  private readonly prevArrow!: HTMLAnchorElement;
  @Ref()
  private readonly nextArrow!: HTMLAnchorElement;

  private banners: Banner[] = banners;
  private slider: PromotionSlider | null = null;

  @Ref()
  private readonly slick!: Slick;

  mounted() {
    const { slideElement, prevArrow, nextArrow } = this;

    this.slider = new PromotionSlider({ slideElement, prevArrow, nextArrow });
  }
}
