

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  GetSettlementsconfigurationsRequest,
  PutSettlementsconfigurationsRequest,
  SettlementsconfigurationsResponse,
} from 'ncp-api-supporter';
import { Nullable } from 'ncp-api-supporter/dist/types';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  SETTLEMENT_HOLIDAY_TYPE,
  SETTLEMENT_ORDER_STATUS_TYPE,
  SettlementHolidayType,
  SettlementOrderStatusType,
  settlementSettingRadioOption,
} from '@/const/contents/configuration/mallCreate';
import SettlementDay from '@/views/contents/configuration/basic/MallCreate/SettlementDay.vue';

const DEFAULT_SETTLEMENT_DAY = 5;

type SettlementConfiguration = Omit<
  SettlementsconfigurationsResponse,
  'domesticSettlementHolidayType' | 'overseasSettlementHolidayType' | 'settlementTargetStatus'
> & {
  settlementTargetStatus: SettlementOrderStatusType;
  domesticSettlementHolidayType: SettlementHolidayType;
  overseasSettlementHolidayType: SettlementHolidayType;
};

@Component({
  components: { SettlementDay, SelectBox, RadioGroup },
})
export default class SettlementSetting extends Vue {
  @Prop({ required: true })
  private readonly isEditPage: boolean;

  @Prop({ required: true })
  private readonly mallNo: Nullable<string>;

  public get settlementConfigurationRequest(): PutSettlementsconfigurationsRequest['data'] {
    return {
      ...this.initialSettlementConfigurationRequest,
      domesticSettlementDay: this.domesticSettlementDay,
      domesticSettlementHolidayType: this.domesticSettlementHolidayType,
      overseasSettlementDay: this.overseasSettlementDay,
      overseasSettlementHolidayType: this.overseasSettlementHolidayType,
      settlementTargetStatus: this.settlementTargetStatus,
    };
  }

  private readonly radioOption = settlementSettingRadioOption;

  private settlementTargetStatus: SettlementOrderStatusType = SETTLEMENT_ORDER_STATUS_TYPE.BUY_CONFIRM;

  private domesticSettlementDay = DEFAULT_SETTLEMENT_DAY;
  private domesticSettlementHolidayType: SettlementHolidayType = SETTLEMENT_HOLIDAY_TYPE.YESTERDAY;

  private overseasSettlementDay = DEFAULT_SETTLEMENT_DAY;
  private overseasSettlementHolidayType: SettlementHolidayType = SETTLEMENT_HOLIDAY_TYPE.YESTERDAY;

  private initialSettlementConfigurationRequest: Nullable<PutSettlementsconfigurationsRequest['data']> = null;

  private async fetchSettlementsConfigurations() {
    const request: GetSettlementsconfigurationsRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };
    const { data } = await this.$api.getSettlementsconfigurations(request);
    this.initializeSettlementConfiguration((data as unknown) as SettlementConfiguration);
  }

  private initializeSettlementConfiguration(configuration: SettlementConfiguration) {
    this.initialSettlementConfigurationRequest = {
      ...configuration,
    };

    const {
      settlementTargetStatus,
      domesticSettlementDay,
      domesticSettlementHolidayType,
      overseasSettlementDay,
      overseasSettlementHolidayType,
    } = configuration;

    this.settlementTargetStatus = settlementTargetStatus;

    this.domesticSettlementDay = domesticSettlementDay;
    this.domesticSettlementHolidayType = domesticSettlementHolidayType;

    this.overseasSettlementDay = overseasSettlementDay;
    this.overseasSettlementHolidayType = overseasSettlementHolidayType;
  }

  private created() {
    this.fetchSettlementsConfigurations();
  }
}
