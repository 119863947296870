




















































import { Component, Mixins } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SmsPoint from '@/views/contents/member/sms/mixins/SmsPoint.vue';
import { range1ToNI18n } from '@/utils/common';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { PopupClose, throwPopup } from '@/helpers/popup';
import {
  GetSmsGodoCallingNumbers,
  GetSmsGodoCallingNumbersRequest,
  NCPResponse,
  PutSmsGodoSettingsRequest,
} from 'ncp-api-supporter';
import { RadioBoxGroupOption } from '@/helpers/type';
import { getCurrentMallNo } from '@/utils/mall';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({
  components: { TextInput, RadioGroup, NoticeBox },
})
export default class SmsConfig extends Mixins(SmsPoint) {
  private readonly noticeList = range1ToNI18n(5, 'MEMBER.SMS.USE_CONFIG_NOTICE_');
  private readonly descriptionList = range1ToNI18n(5, 'MEMBER.SMS.CALL_DESC_');

  private useOption: RadioBoxGroupOption<boolean> = {
    name: 'smsConfig',
    data: [
      { id: `config-used`, value: true, display: 'MEMBER.SMS_AUTO.USED' },
      { id: `config-notUsed`, value: false, display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  };

  private buttonList = [{ type: 'right', key: 'configSave', color: 'red', text: this.$t('SAVE') }];
  private approveTime = '';

  private async fetchCallingNumberList(): Promise<GetSmsGodoCallingNumbers[]> {
    const request: GetSmsGodoCallingNumbersRequest = { params: { mallNo: getCurrentMallNo(this) } };
    const { data }: NCPResponse<GetSmsGodoCallingNumbers[]> = await this.$api.getSmsGodoCallingNumbers(request);
    return data;
  }

  private async openCallingNumberList() {
    const callingNumberList = await this.fetchCallingNumberList();
    const { state, data } = await throwPopup({
      name: 'SmsConfigNumberList',
      data: { callingNumberList },
    });
    if (state !== PopupClose.CONFIRM) return;

    this.callingNumber = data.selectedNumber;
    this.approveTime = data.approveTime;
    await this.configSave();
  }

  private async configSave() {
    const request: PutSmsGodoSettingsRequest = {
      params: { mallNo: getCurrentMallNo(this) },
      data: {
        fromPhoneNumber: this.callingNumber,
        smsUse: this.useType,
        registerYmdt: this.approveTime,
      },
    };
    try {
      await this.$api.putSmsGodoSettings(request);
      alert(this.$t('MEMBER.SMS.ALERT_SAVE'));
    } catch (e) {
      if (e.status !== 403) {
        alert(this.$t(e.message));
      }
    }
    await this.fetchInitConfig();
  }

  private init() {
    throwBottomNavigation({
      buttons: this.buttonList,
      onClick: this.configSave,
    });
  }

  created() {
    this.init();
  }
}
