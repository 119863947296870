







































































































import { Component, Ref, Watch } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import CategorySelector, { CategoryType } from '@/components/product/common/CategorySelector.vue';
import Grid from '@/components/common/grid/Main.vue';
import {
  getOtherProductOptionGridProps,
  resetProductConfigurable,
  otherProductOptionGridContainerOptions,
  convertToI18n,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import { NCPResponse, ProductsConfigurable, SimpleProduct } from 'ncp-api-supporter';
import { RowKey } from '@/types/tui-grid';

@Component({
  components: {
    TextInput,
    SearchButton,
    CategorySelector,
    Grid,
  },
})
export default class OtherProductOption extends WindowPopupMainVue {
  private categoryType: CategoryType = CategoryType.DISPLAY;
  private categoryNo: null | number = null;
  private gridProps = getOtherProductOptionGridProps(this.radioEventHandler);
  private containerOptions = otherProductOptionGridContainerOptions;
  private productsConfigurableParams = resetProductConfigurable();
  private productList: SimpleProduct[] = [];
  private totalCount = 0;
  private selectedProductNo: number | null = null;
  @Ref() private readonly grid: Grid;

  @Watch('$route')
  private onQueryStringChanged(): void {
    this.searchProductList();
  }

  created() {
    this.searchProductList();
  }

  private async searchProductList(): Promise<void> {
    const { page } = this.$route.query;
    if (page) {
      this.productsConfigurableParams.page = Number(page);
    }
    this.productsConfigurableParams.mallNo = Number(this.data.mallNo);

    if (this.categoryType === CategoryType.DISPLAY && this.categoryNo !== null) {
      this.productsConfigurableParams.displayCategoryViewNo = this.categoryNo;
      this.productsConfigurableParams.standardCategoryViewNo = null;
    }

    if (this.categoryType === CategoryType.STANDARD && this.categoryNo !== null) {
      this.productsConfigurableParams.standardCategoryViewNo = this.categoryNo;
      this.productsConfigurableParams.displayCategoryViewNo = null;
    }

    const { data }: NCPResponse<ProductsConfigurable> = await this.$api.getProductsConfigurable({
      params: this.productsConfigurableParams,
    });
    this.productList = data.contents;
    this.totalCount = data.totalCount;
  }

  private resetProductList(): void {
    this.productsConfigurableParams = resetProductConfigurable();
    this.categoryType = CategoryType.DISPLAY;
  }

  private radioEventHandler(_, rowKey: RowKey): void {
    this.selectedProductNo = this.productList[rowKey].productNo;
  }

  private saveOtherProductOption(): void {
    if (this.data.optionCnt > 0) {
      const confirmDeletion = confirm(convertToI18n('SURE_CALL_DELETE').toString());
      if (!confirmDeletion) return;
    }
    this.onPositiveClick({ productNo: this.selectedProductNo });
  }
}
