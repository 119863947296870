import { GetKakaoAutoTemplateTypes, GetSmsAutoTemplates, SmsTemplatesAutoGroup } from 'ncp-api-supporter';

export const formatTemplate = (data: GetKakaoAutoTemplateTypes[], type: string): GetSmsAutoTemplates[] => {
  return data
    .filter(({ templateGroupType }) => templateGroupType === type)
    .map(({ usableNight, templateType, templateTypeLabel }) => {
      return {
        nightUsable: !usableNight,
        name: templateTypeLabel,
        type: templateType,
      };
    });
};

export const formatTemplateGroups = (data: GetKakaoAutoTemplateTypes[]): SmsTemplatesAutoGroup[] => {
  const types = data.map(({ templateGroupType }) => templateGroupType);
  const MUST_BE_HIDDEN = ['MEMBER', 'ADMIN']; // 회원 항목에는 법적 고지 내용이 있어 미발송 시 법적 이슈 발생하여 발송제한 항목에서 제외시킴 (https://nhnent.dooray.com/project/posts/3168796803781564611)
  return data
    .filter(({ templateGroupType }, index) => !types.includes(templateGroupType, index + 1))
    .filter(({ templateGroupType }) => !MUST_BE_HIDDEN.includes(templateGroupType))
    .map(({ templateGroupType, templateGroupTypeLabel }) => {
      return {
        type: templateGroupType.toUpperCase(),
        name: templateGroupTypeLabel,
        templates: formatTemplate(data, templateGroupType),
      };
    });
};

export const formatSmsTemplates = (data: SmsTemplatesAutoGroup[]): SmsTemplatesAutoGroup[] => {
  const templates = data.map(({ templates }) => templates);
  const formatTemplate = templates.map(template =>
    template.map(template => {
      return { ...template, nightUsable: !template.nightUsable };
    }),
  );
  return data.map((data, i) => {
    return { ...data, templates: formatTemplate[i] };
  });
};
