





























































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
  convertToI18n,
  defaultMappingInfo,
  getToolTipMessageList,
  setOptionSaleStatusTypeOptions,
  setOptionTableHeaders,
  setOptionTableWidth,
  STOCK_STATUS_TYPE,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import CombinationOptionTableMixin from '@/views/popups/product/productAdd/ProductOption/CombinationOption/Mixins/CombinationOptionTableMixin.vue';
import { PopupClose, PopupData, throwWindowPopup } from '@/helpers/popup';
import { CombinationOptionType, ConfigurableOptionType, MappingType } from '@/types';
import { getOnlyNumbers } from '@/utils/numberFormat';
import { GetProductsMallProductNoRequest } from 'ncp-api-supporter';

type StockStatusType = keyof typeof STOCK_STATUS_TYPE;
@Component({
  components: { TextInput, SelectBox, ToolTip },
})
export default class SetOptionTable extends Mixins(CombinationOptionTableMixin) {
  @Prop({ required: true }) private readonly deliveryTemplateNo!: number;
  @Prop({ required: true }) private readonly isSelectable!: boolean;
  @Prop({ required: false, default: false }) private readonly isReadonly!: boolean;
  //TODO 세트옵션 판매가, 구성옵션 판매가 api 확인 후 수정
  private tableHeaders: string[] = setOptionTableHeaders;
  private saleStatusTypeOptions = setOptionSaleStatusTypeOptions;
  private stockStatusToolTipMessage = getToolTipMessageList(4, 'PRODUCT.OPTION.SET_OPTION_STOCK_STATUS_TOOL_TIP');
  public isRegisteredConfigurationOption: boolean[] = [];
  private savedConfigurationOptions: ConfigurableOptionType[][] = [];
  private setOptionTableWidth = setOptionTableWidth;

  @Watch('optionListSync')
  private initOptionData() {
    this.isRegisteredConfigurationOption = Array(this.optionColumnListSync.length).fill(false);
    this.savedConfigurationOptions = this.optionColumnListSync.map(_ => []);
  }

  mounted() {
    this.$nextTick(this.setConfigurationOptionData);
  }

  public deleteConfigurationOption(index: number): void {
    this.isRegisteredConfigurationOption.splice(index, 1);
  }

  public setMappingOptionData(): void {
    this.optionColumnListSync.forEach(optionColumn => {
      const newMappings = optionColumn.mappings.reduce((acc, mapping) => {
        if (mapping.itemProductSaleStatusType !== 'PROHIBITION_SALE') {
          const newMapping = this.convertMapping(mapping);
          acc.push(newMapping);
        }
        return acc;
      }, []);
      //구성옵션이 선택불가한 경우
      if (newMappings.length === 0) {
        delete optionColumn.mappings;
      } else {
        this.$set(optionColumn, 'mappings', newMappings);
      }
    });

    this.setConfigurationOptionData();
  }

  private convertMapping(mapping: MappingType): MappingType {
    const {
      itemOptionNo,
      itemProductNo,
      mappingCnt,
      mappingOptionSalePrice,
      itemCombinedOptionNameValue,
      itemOriginalMappingOptionSalePrice,
      itemProductName,
      itemStockCount,
      saleStatusType,
    } = mapping;
    return {
      mallOptionNo: itemOptionNo,
      mallProductNo: itemProductNo,
      mappingCount: mappingCnt,
      salePrice: mappingOptionSalePrice,
      combinedOptionNameValue: itemCombinedOptionNameValue,
      optionSalePrice: itemOriginalMappingOptionSalePrice,
      productName: itemProductName,
      stockCnt: itemStockCount,
      saleStatusType,
    };
  }

  private setConfigurationOptionData() {
    this.savedConfigurationOptions = this.optionColumnListSync.map(({ mappings }) => mappings);

    this.isRegisteredConfigurationOption = this.savedConfigurationOptions.map(
      savedConfigurationOption => savedConfigurationOption.length !== 0,
    );
  }

  private openSetConfigurationOptionPopup(index: number): void {
    throwWindowPopup(
      'SetConfigurationOption',
      {
        mallNo: this.mallNo.toString(),
        index,
        savedConfigurationOptions: this.savedConfigurationOptions[index],
        deliveryTemplateNo: this.deliveryTemplateNo,
      },
      'xlg',
      this.configurationOptionCallback,
    );
  }

  private getConfigurationOptionBtnText(index: number): string {
    return this.isRegisteredConfigurationOption[index] ? 'PRODUCT.OPTION.REGISTER_OVER' : 'PRODUCT.OPTION.REGISTER';
  }

  private getConfigurationOptionBtnClassName(index: number): string {
    return this.isRegisteredConfigurationOption[index] ? 'type-black' : 'type-white';
  }

  public async validateConfigurationOption(options: CombinationOptionType<string[]>[]) {
    await Promise.all(
      options.map(async ({ mappings }, index) => {
        const deletedOptions = await Promise.all(
          mappings.map(({ mallProductNo }, mappingIndex) => this.getDeletedOption(mallProductNo, index, mappingIndex)),
        );
        let isDeleted = false;
        deletedOptions.forEach(({ isDeletedOption, index, mappingIndex }) => {
          if (isDeletedOption === true) {
            this.$set(this.isRegisteredConfigurationOption, index, false);
            options[index]?.mappings.splice(mappingIndex, 1, defaultMappingInfo());
            isDeleted = true;
          }
        });
        if (isDeleted === false) return;
        alert(convertToI18n('SELECTABLE_N_SAVE'));
      }),
    );
  }

  private async getDeletedOption(
    mallProductNo: number,
    mappingIndex: number,
    index: number,
  ): Promise<{ isDeletedOption: boolean; mappingIndex: number; index: number }> {
    const request: GetProductsMallProductNoRequest = {
      pathParams: {
        mallProductNo: mallProductNo.toString(),
      },
    };
    try {
      await this.$api.getProductsMallProductNo(request);
      return { isDeletedOption: false, mappingIndex, index };
    } catch (e) {
      return { isDeletedOption: true, mappingIndex, index };
    }
  }

  private configurationOptionCallback({ state, data }: PopupData) {
    if (state === PopupClose.CLOSE) return;
    const { index, selectedOptions } = data;
    //TODO 추가할 수 없는 세트옵션
    // if (this.validateConfigurationOption(selectedOptions) === false) {
    //   alert(convertToI18n('SELECTABLE_N'));
    // }
    this.$set(this.optionColumnListSync[index], 'mappings', this.getMappingsData(selectedOptions));
    this.$set(this.isRegisteredConfigurationOption, index, true);
    this.$set(this.savedConfigurationOptions, index, selectedOptions);
  }

  private getMappingsData(options: ConfigurableOptionType[]): MappingType[] {
    return options.map(
      ({ mallOptionNo, mallProductNo, combinedOptionNameValue, optionSalePrice, productName, stockCnt }) => ({
        mallOptionNo,
        mallProductNo,
        mappingCount: 1,
        salePrice: optionSalePrice,
        combinedOptionNameValue,
        optionSalePrice,
        productName,
        stockCnt,
      }),
    );
  }

  private getRowSpan(index: number): number {
    return this.optionColumnListSync[index].mappings.length;
  }

  private getTotalMappingCount(index: number): number {
    return this.optionColumnListSync[index].mappings.reduce((acc, { mappingCount }) => acc + mappingCount, 0);
  }

  private calculateConfigurationOptionStockCnt(columnIndex: number): number[] {
    const { mappings } = this.optionColumnListSync[columnIndex];
    //TODO mappingCount 빈값 / 0으로 전송시 에러처리 없다 => 제외
    return mappings.reduce((acc, { stockCnt, mappingCount }) => {
      if (mappingCount) {
        acc.push(Math.floor(stockCnt / mappingCount));
      }
      return acc;
    }, []);
  }

  private getSetOptionStockCnt(columnIndex: number): string {
    if (this.isRegisteredConfigurationOption[columnIndex] === false) return '-';
    const configurationOptionStocks = this.calculateConfigurationOptionStockCnt(columnIndex);
    //수량 입력 안했을때는 제외하여 계산하였고 모두 0이라면 재고도 0으로 처리
    if (configurationOptionStocks.length === 0) {
      this.setStockData(columnIndex, { stockCnt: 0, saleStatusType: STOCK_STATUS_TYPE.SOLD_OUT });
      return '-';
    }
    const setOptionStockCnt = this.getMinimumValue(configurationOptionStocks);
    const { isSetSaleStatusType, saleStatusType } = this.getSaleStatusTypeState(setOptionStockCnt, columnIndex);
    const stockData = { stockCnt: setOptionStockCnt, saleStatusType };
    this.setStockData(columnIndex, stockData, isSetSaleStatusType);

    return this.getCurrency(setOptionStockCnt);
  }

  private getSaleStatusTypeState(
    setOptionStockCnt: number,
    index: number,
  ): { isSetSaleStatusType: boolean; saleStatusType: StockStatusType } {
    const isSetSaleStatusType =
      setOptionStockCnt === 0 ||
      (setOptionStockCnt !== 0 && this.optionColumnListSync[index].saleStatusType === STOCK_STATUS_TYPE.SOLD_OUT);
    const saleStatusType = setOptionStockCnt === 0 ? STOCK_STATUS_TYPE.SOLD_OUT : STOCK_STATUS_TYPE.AVAILABLE;
    return { isSetSaleStatusType, saleStatusType };
  }

  private setStockData(
    index: number,
    { stockCnt, saleStatusType }: { stockCnt: number; saleStatusType: StockStatusType },
    isSetSaleStatusType = true,
  ) {
    this.$set(this.optionColumnListSync[index], 'stockCnt', stockCnt);
    isSetSaleStatusType && this.$set(this.optionColumnListSync[index], 'saleStatusType', saleStatusType);
  }

  private getMinimumValue(array: number[]): number {
    return array.reduce((previous, current) => (previous > current ? current : previous));
  }

  private onBlurSalePrice(event: { target: HTMLInputElement }, columnIndex: number, mappingIndex: number): void {
    //TODO 우선 구성 옵션 등록 전에는 빈값으로 출력 수량도??
    if (this.isRegisteredConfigurationOption[columnIndex] === false) {
      event.target.value = '';
      return;
    }
    if (!this.validateTenUnit(event.target.value)) return;
    this.$set(
      this.optionColumnListSync[columnIndex].mappings[mappingIndex],
      'salePrice',
      getOnlyNumbers(event.target.value),
    );
  }

  private getMappingValue(value: number): string {
    return value === null ? '' : this.formatCurrency(value);
  }

  private calculateAddPrice(index: number) {
    const addPrice = this.optionColumnListSync[index].mappings.reduce(
      (acc, { salePrice, mappingCount }) => acc + salePrice * mappingCount,
      0,
    );
    this.optionColumnListSync[index].addPrice = addPrice;
    return this.getTotalMappingCount(index) === 0 ? '-' : this.getCurrency(addPrice);
  }
}
