





















































































































import { Component, Ref } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  GetKakaoAuthNumber,
  GetKakaoAuthNumberRequest,
  GetKakaoConfigCategoryAll,
  NCPResponse,
  PostKakaoConfigPlus,
  PostKakaoConfigPlusRequest,
} from 'ncp-api-supporter';
import { getKakaoConfigPlusIdInitData, categoryAllText, businessType } from '@/const/contents/kakao';
import { isValidate } from '@/utils/validate';
import { BusinessType, KakaoConfigBusinessType } from '@/types/member';
import { namespace } from 'vuex-class';
import { Admin } from '@/types';
import Captcha, { MAX_COUNT_TO_SHOW_CAPTCHA } from '@/components/common/Captcha.vue';

const admin = namespace('admin');
const MAX_PLUS_ID_LENGTH = 30;
@Component({
  components: { Captcha, SelectBox, TextInput, ToolTip },
})
export default class RegisterPlusId extends WindowPopupMainVue {
  @Ref() private readonly plusIdInput!: HTMLInputElement;
  @Ref() private readonly phoneNumberInput!: TextInput;
  @Ref() private readonly categoryCodeSelectBox!: SelectBox[];
  @Ref() private readonly tokenInput!: TextInput;

  @admin.Getter('getAdmin')
  private readonly adminInfo!: Admin;

  private readonly MAX_PLUS_ID_LENGTH = MAX_PLUS_ID_LENGTH;
  private kakaoConfigPlusIdData = null;
  private originCategoryData: GetKakaoConfigCategoryAll = {} as GetKakaoConfigCategoryAll;
  private categoryData: GetKakaoConfigCategoryAll = {
    firstBusinessType: [],
    secondBusinessType: [],
    thirdBusinessType: [],
  } as GetKakaoConfigCategoryAll;
  private categoryAllText = categoryAllText;
  private businessCategoryType: KakaoConfigBusinessType = {
    firstBusinessType: '',
    secondBusinessType: '',
    thirdBusinessType: '',
  };
  private authNumber = '';
  private agreeTerms = false;

  private get categoryCode(): string {
    return Object.values(this.businessCategoryType).join('');
  }

  private get plusId(): string {
    return `@${this.kakaoConfigPlusIdData.plusId}`;
  }

  private businessTypeKeys = Object.keys(businessType) as BusinessType[];

  private resetChildrenBusinessTypeValue(nextIdx: number) {
    const count = this.businessTypeKeys.length - nextIdx;
    for (let i = 0; i < count; i++) {
      const key = this.businessTypeKeys[nextIdx + i];
      this.businessCategoryType[key] = '';
    }
  }
  private setCategoryData(childKey: BusinessType) {
    const code = Object.values(this.businessCategoryType).join('');
    this.categoryData[childKey] = this.originCategoryData[childKey].filter(({ parentCode }) => parentCode === code);
  }
  private onChangeCategoryCode(value, prevValue, { dataset }): void {
    const nextIdx = Number(dataset.depth);
    const childKey = this.businessTypeKeys[Number(nextIdx)];

    if (nextIdx === this.businessTypeKeys.length) return;

    this.resetChildrenBusinessTypeValue(nextIdx);
    this.setCategoryData(childKey);
  }

  private validateCommon(): boolean {
    if (!isValidate(this.kakaoConfigPlusIdData.plusId, this.$t('MEMBER.KAKAO.ALERT_ENTER_PLUS_ID'))) {
      this.plusIdInput.focus();
      return false;
    }
    const hasEmptyValue = Object.values(this.businessCategoryType).some(v => !v);
    if (!isValidate(!hasEmptyValue, this.$t('MEMBER.KAKAO.ALERT_SELECT_BUSINESS_CATEGORY'))) {
      Object.values(this.businessCategoryType).every((businessType, index) => {
        if (!businessType.length) {
          this.categoryCodeSelectBox[index].focus();
          return false;
        }
      });
      return false;
    }
    if (!isValidate(this.kakaoConfigPlusIdData.phoneNumber, this.$t('MEMBER.KAKAO.ALERT_INPUT_ADMIN_PHONE_NUMBER'))) {
      this.phoneNumberInput.focus();
      return false;
    }
    return true;
  }

  private validateRegisterPlusId(): boolean {
    if (!this.validateCommon()) return false;
    const token = this.kakaoConfigPlusIdData.token;
    if (!isValidate(token, this.$t('MEMBER.KAKAO.ALERT_INPUT_AUTH_NUMBER'))) {
      this.tokenInput.focus();
      return false;
    }
    return isValidate(this.agreeTerms, this.$t('MEMBER.KAKAO.ALERT_MUST_AGREE'));
  }

  private async fetchBusinessCategoryAll(): Promise<void> {
    const { code, data, message } = (await this.$api.getKakaoConfigCategories()).data;
    if (code === 'fail') {
      alert(this.$t('MEMBER.KAKAO.FETCH_CATEGORY_ERROR', [message]));
    } else {
      this.originCategoryData = data;
      this.categoryData.firstBusinessType = data.firstBusinessType;
    }
  }

  private async fetchAuthNumber(): Promise<void> {
    if (!this.kakaoConfigPlusIdData.phoneNumber) {
      alert(this.$t('MEMBER.KAKAO.ALERT_INPUT_ADMIN_PHONE_NUMBER'));
      this.phoneNumberInput.focus();
      return;
    }

    try {
      const request: GetKakaoAuthNumberRequest = {
        params: {
          mallNo: this.data.mallNo,
          phoneNumber: this.kakaoConfigPlusIdData.phoneNumber,
          plusId: this.plusId,
        },
      };

      const { data }: NCPResponse<GetKakaoAuthNumber> = await this.$api.getKakaoAuthNumber(request);
      this.authNumber = data.authNumber;
      alert(this.$t('MEMBER.KAKAO.SUCCESS_SEND_AUTH'));
    } catch (e) {
      if (e?.data?.code === 'K0002') {
        alert(this.$t('MEMBER.KAKAO.ALERT_CERTIFICATION_FIRST'));
        this.plusIdInput.focus();
      }
    }
  }

  @Ref()
  private readonly captcha: Captcha;
  private retryErrorCount = 0;
  private get needsCaptchaAuth(): boolean {
    return this.retryErrorCount >= MAX_COUNT_TO_SHOW_CAPTCHA;
  }

  private registrationSuccess(senderKey) {
    alert(this.$t('MEMBER.KAKAO.SUCCESS_REGISTER_PLUS_ID'));
    this.retryErrorCount = 0;
    this.onPositiveClick({ senderKey: senderKey, plusId: this.plusId });
  }
  private registeredCategory(message: string): string {
    const categoryCode = message.replace(/[^0-9]/g, '');
    const { length } = categoryCode;
    const categoryCodeMap = [
      { code: categoryCode.substring(0, 3), key: 'firstBusinessType' },
      { code: categoryCode.substring(3, length - 4), key: 'secondBusinessType' },
      { code: categoryCode.substring(length - 4, length), key: 'thirdBusinessType' },
    ];

    const filteredCategoryName = (categories, code, parentCode) =>
      categories.filter(c => (parentCode ? c.code === code && c.parentCode === parentCode : c.code === code))[0]?.name;
    let parentCode = '';
    return categoryCodeMap
      .map(({ code, key }) => {
        const _parentCode = parentCode;
        parentCode += code;
        return filteredCategoryName(this.originCategoryData[key], code, _parentCode);
      })
      .join(' > ');
  }
  private registrationFailure(e) {
    this.needsCaptchaAuth && this.captcha?.errorHandler(e);

    if (e?.data?.message) {
      const { message } = e.data;
      if (message.includes(this.$t('MEMBER.KAKAO.CERT_CODE').toString())) {
        alert(this.$t('MEMBER.KAKAO.ALERT_INVALID_AUTH_NUMBER'));
        this.retryErrorCount += 1;
        this.tokenInput.focus();
        return;
      } else if (e?.data?.code === 'K0028') {
        alert(this.$t('MEMBER.KAKAO.ALERT_INVALID_PLUS_ID'));
        this.plusIdInput.focus();
        return;
      } else if (e?.data?.code === 'K0026') {
        alert(this.$t('MEMBER.KAKAO.ALERT_INVALID_CATEGORY', { category: this.registeredCategory(e.data.message) }));
        return;
      }
      const [_, convertedMessage] = message.split('msg :');
      if (convertedMessage?.replace(/^\s+|\)/g, '')) {
        alert(convertedMessage.replace(/^\s+|\)/g, ''));
        return;
      }

      message && alert(message);
    }
  }

  private async registerPlusId(): Promise<void> {
    if (!this.validateRegisterPlusId()) return;

    try {
      this.needsCaptchaAuth && (await this.captcha?.submitCode(true));
      const request: PostKakaoConfigPlusRequest = {
        data: {
          ...this.kakaoConfigPlusIdData,
          plusId: this.plusId,
          categoryCode: this.categoryCode,
        },
      };
      const { data }: NCPResponse<PostKakaoConfigPlus> = await this.$api.postKakaoConfigPlus(request);
      this.registrationSuccess(data.senderKey);
    } catch (e) {
      this.registrationFailure(e);
    }
  }

  created() {
    this.kakaoConfigPlusIdData = getKakaoConfigPlusIdInitData(this.data.mallNo);
    this.fetchBusinessCategoryAll();
  }
}
