





































import { Component, Prop, Vue } from 'vue-property-decorator';
import MessageTemplate from '@/views/contents/member/sms/MessageTemplate.vue';
import { SmsTemplatesAutoGroup } from 'ncp-api-supporter';
import { throwWindowPopup } from '@/helpers/popup';
import { getCurrentMallNo } from '@/utils/mall';

@Component({
  components: { MessageTemplate },
})
export default class MessageTemplateBox extends Vue {
  @Prop({ default: [] })
  private readonly templateData!: SmsTemplatesAutoGroup[];
  @Prop()
  private readonly availableSendSms!: () => boolean;

  private get type(): string {
    return this.$route.query.templateType?.toString() || 'MEMBER';
  }

  private get templateByType(): SmsTemplatesAutoGroup[] {
    return this.templateData.filter(template => template.type === this.type);
  }

  private get isSmsPage(): boolean {
    return this.$route.name === 'SmsAutomation';
  }

  private isFocus(type: string) {
    return type === this.type;
  }

  private openPopup() {
    throwWindowPopup('SmsManager', { type: 'sms', mallNo: getCurrentMallNo(this) }, 'md', () => null);
  }
}
