



















































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
import Slick from 'vue-slick';
import { getCurrentMallNo } from '@/utils/mall';
import ClickOutside from 'vue-click-outside';

const SLIDES_TO_SHOW = 5;

@Component({ components: { Slick }, directives: { ClickOutside } })
export default class MallSelector extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];

  // 다국어 쇼핑몰은 기본제공기능이 아니므로 초기 제공 시 국기 아이콘과 서비스 이용불가 표기 제외
  @Prop({ required: false, default: true })
  private showOnlyKr!: boolean; // show only our country

  private showingAllMalls = false;
  private slickOptions = {
    infinite: false,
    draggable: false,
    speed: 0,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: SLIDES_TO_SHOW,
    nextArrow: '.tab-next',
    prevArrow: '.tab-prev',
    centerMode: false,
  };

  @Ref()
  private readonly slick!: Slick;

  private get mallList(): Mall[] {
    const malls = this.showOnlyKr ? this.malls.filter(({ countryCode }) => countryCode === 'KR') : this.malls;
    return malls.reverse();
  }

  private get currentMallNo(): number {
    return getCurrentMallNo(this);
  }

  private get showTab() {
    let flg = false;
    if (this.malls && this.malls.length && this.malls.length > 1) {
      flg = true;
    }
    return flg;
  }

  created() {
    const currentMallNo = getCurrentMallNo(this);
    const hasValid = this.malls.some(({ mallNo }) => mallNo === currentMallNo);
    if (hasValid) return;
    alert(this.$t('ALERT_MALL_FORBIDDEN'));
    this.$router.go(-1);
  }

  mounted() {
    this.$nextTick(this.slickMoveCurrentPosition);
  }

  @Watch('currentMallNo')
  private slickMoveCurrentPosition(): void {
    const currentMallIndex = this.mallList.findIndex(({ mallNo }) => mallNo === this.currentMallNo);
    this.slick.goTo(currentMallIndex);

    this.showingAllMalls = false;
  }

  private isAbroadCountryCode(countryCode: string): boolean {
    return this.showOnlyKr && countryCode !== 'KR';
  }

  private onClickOutside(): void {
    this.showingAllMalls = false;
  }
}
