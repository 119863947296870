























































import { Component } from 'vue-property-decorator';
import { GetOrderProductsRequest } from 'ncp-api-supporter';
import WindowPopupMain from '@/views/popups/Main.vue';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';

@Component
export default class Benefit extends WindowPopupMain {
  private accumulationList = [];
  private totalAccumulation = '0';
  private isLast = false;
  mounted() {
    this.getOrderProductInfo();
  }
  private getOrderProductInfo() {
    const ProductInfoRequest: GetOrderProductsRequest = {
      params: {
        orderNo: this.data.orderNo as string,
      },
    };
    this.$api.getOrderProducts(ProductInfoRequest).then(res => {
      this.getAccululationList(res.data);
    });
  }
  private getAccululationList(arr) {
    let totalAccumulation = 0;
    this.accumulationList = [];
    if (arr.length === 0) {
      return;
    }
    arr.map(item => {
      item.orderOptions.map(opItem => {
        if (opItem.possibleToGetAccumulationAmt === true) {
          this.accumulationList.push({
            name: opItem.productName,
            mallNo: opItem.mallNo,
            mallProductNo: opItem.mallProductNo,
            optionName: this.getProductOption(opItem.optionName, opItem.optionValue),
            usesOption: opItem.usesOption,
            accumulationAmt: opItem.accumulationAmt.toLocaleString(),
          });
          totalAccumulation += Number(opItem.accumulationAmt);
        }
      });
    });
    this.totalAccumulation = totalAccumulation.toLocaleString();
  }

  private getProductOption(name, value) {
    const optionName = name.split('|');
    const optionValue = value.split('|');
    const optionFullName = [];

    for (let i = 0; i < optionName.length; i++) {
      optionFullName[i] = optionName[i] + ':' + optionValue[i];
    }
    return optionFullName.join(' | ');
  }

  private onClickMallProductPage(mallProductNo: number) {
    openEditProductWindow(mallProductNo);
  }
}
