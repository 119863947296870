















































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  isCancelRequest,
  isCancelProcRequestRefund,
  isCancelProcWaitingRefund,
} from '@/components/popup/claim/claimConditions';
import ClaimPopupMixin from '@/components/popup/claim/ClaimPopupMixin';
import NoticeBox from '@/components/popup/claim/ClaimPopup/NoticeBox.vue';
import ClaimProducts from '@/components/popup/claim/ClaimPopup/ClaimProducts';
import AmountDetail from '@/components/popup/claim/ClaimPopup/AmountDetail.vue';
import DeliveryRecalculate from '@/components/popup/claim/ClaimPopup/DeliveryRecalculate.vue';
import RefundAmount from '@/components/popup/claim/ClaimPopup/RefundAmount.vue';
import BenefitRecalculate from '@/components/popup/claim/BenefitRecalculate.vue';
import { claimClassTypes, claimTypes, responsibleObjectTypes } from '@/const/claim';
import { PutOptionCancelsNoRequest } from 'ncp-api-supporter/dist/types';
import { ClaimPopupForm } from '@/types/claim';
import { getDefaultForm } from '@/components/popup/claim/claimOptions';
import NoLocalStorageWindowPopup from '@/views/popups/NoLocalStorageWindowPopup.vue';
import { PopupClose, PopupResult, throwPopup } from '@/helpers/popup';

// 취소리스트 기획서 p.40
@Component({
  computed: {
    isCancelRequest,
    isCancelProcWaitingRefund,
    isCancelProcRequestRefund,
    isCancelAcceptPopup() {
      return this.$route.path.includes('cancel-accept');
    },
    showWithdrawalButton(): boolean {
      // 취소승인처리 팝업과 취소처리내역팝업 '취소신청 철회'버튼 빼고 기능, UI가 동일하다.
      if (this.isCancelAcceptPopup) {
        return;
      }

      return this.isCancelRequest || this.isCancelProcWaitingRefund || this.isCancelProcRequestRefund;
    },
    showRefundButton(): boolean {
      return this.isCancelProcRequestRefund;
    },
    showApproveButton(): boolean {
      return this.isCancelRequest;
    },
  },
  components: {
    NoticeBox,
    ClaimProducts,
    AmountDetail,
    RefundAmount,
    BenefitRecalculate,
    DeliveryRecalculate,
  },
})
export default class CancelPopup extends Mixins(ClaimPopupMixin, NoLocalStorageWindowPopup) {
  private formData: ClaimPopupForm = getDefaultForm();

  private get isOrderCancel(): boolean {
    return this.claimClassType === claimClassTypes.ORDER_CANCEL;
  }

  @Watch('formData.responsibleObjectType', { immediate: false })
  private predictClaimAmounts() {
    this.fetchPredictAmounts();
  }

  private async fetchPredictAmounts() {
    if (!this.isPredictableClaim) {
      return;
    }

    this.formData.sellerPaysClaimedDelivery = this.formData.responsibleObjectType === responsibleObjectTypes.SELLER; // TODO: 추출, 판매자부담 체크박스 유무는 귀책대상을 따라간다.
    const { responsibleObjectType, sellerPaysClaimedDelivery, refundType } = this.formData;

    const predictAmounts = {
      [claimClassTypes.ORDER_CANCEL]: () =>
        this.$api.postOrderCancelsPredict({ data: { refundType, orderNo: this.claimNoResponse.orderNo } }),
      [claimClassTypes.OPTION_CANCEL]: () =>
        this.$api.postOptionCancelsNoPredict({
          pathParams: {
            no: this.$route.query.claimNo.toString(),
          },
          data: {
            responsibleObjectType,
            sellerPaysClaimedDelivery,
          },
        }),
    };

    const { data } = await predictAmounts[this.claimClassType]();
    this.amounts = data.amounts;
  }

  async created() {
    await this.fetchClaimNo();
    this.setDefaultForm();
  }

  private setDefaultForm() {
    this.formData = getDefaultForm(this.claimNoResponse);
  }

  private async onWithdraw() {
    const { validationType, afterClaimNos } = await this.checkWithdraw();
    const { state }: PopupResult = await throwPopup({
      name: 'WithdrawMessage',
      data: {
        claimType: this.claimType,
        validationType,
        afterClaimNos,
      },
    });

    if (state !== PopupClose.CONFIRM) {
      return;
    }

    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
    };

    const apiMap = {
      [claimClassTypes.ORDER_CANCEL]: () => this.$api.putOrderCancelsNoWithdraw(request),
      [claimClassTypes.OPTION_CANCEL]: () => this.$api.putOptionCancelsNoWithdraw(request),
    };

    await apiMap[this.claimClassType]();
    alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
    this.onPositiveClick();
  }

  private onRefund() {
    if (!this.validateNaverPayClaim()) {
      return;
    }

    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_REFUND_PROCESS').toString())) {
      return;
    }
    const { refundType, refundBankAccount } = this.formData;

    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
      data: {
        refundBankAccount:
          refundType === 'ACCOUNT'
            ? refundBankAccount
            : {
                bankName: null,
                depositorName: null,
                account: null,
                bank: null,
              },
      },
    };

    const apiMap = {
      [claimClassTypes.ORDER_CANCEL]: () => this.$api.putOrderCancelsNoRefundCash(request),
      [claimClassTypes.OPTION_CANCEL]: () => this.$api.putOptionCancelsNoCashRefund(request),
    };

    apiMap[this.claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }

  private onApprove() {
    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_CANCEL_APPROVE').toString())) {
      return;
    }

    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
    };

    const apiMap = {
      [claimClassTypes.ORDER_CANCEL]: () => this.$api.putOrderCancelsNoApprove(request),
      [claimClassTypes.OPTION_CANCEL]: () => this.$api.putOptionCancelsNoApprove(request),
    };

    apiMap[this.claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }

  private onPutClaim(modifyType: 'reason' | 'refundAccount'): Promise<void> {
    const {
      responsibleObjectType,
      refundType,
      sellerPaysClaimedDelivery,
      claimReasonType,
      claimReasonDetail,
    } = this.formData;

    let data: PutOptionCancelsNoRequest['data'] = {
      reasonType: this.isOrderCancel ? claimReasonType : null,
      reasonDetail: this.isOrderCancel ? claimReasonDetail : null,
      refundType,
      responsibleObjectType,
      sellerPaysClaimedDelivery,
      claimOrderOptions: [],
    };

    data = this.getClaimReasonRequestFormat<typeof claimTypes.CANCEL>(
      data,
      this.formData,
      modifyType,
      this.claimClassType,
    );
    data = this.getClaimRefundAccountRequestFormat<typeof claimTypes.CANCEL>(data, this.formData, modifyType);

    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
      data: data as PutOptionCancelsNoRequest['data'],
    };

    //TODO: 전체취소 request 타이핑
    const putCancel = {
      [claimClassTypes.ORDER_CANCEL]: () => this.$api.putOrderCancelsNo(request as any),
      [claimClassTypes.OPTION_CANCEL]: () => this.$api.putOptionCancelsNo(request),
    };

    return putCancel[this.claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_SAVE_MODIFY'));
      this.fetchClaimNo(); // 변경된 데이터만 formData에 set해야해서 다시 fetch
      return;
    });
  }
}
