import { Vue, Component, Ref } from 'vue-property-decorator';
import { RemoteCode } from '@/api/godo/type';
import { fetch } from '@/api/godo';
import { namespace } from 'vuex-class';
import ServiceSection from '@/components/addition/ServiceSection.vue';
import { Admin } from '@/types/admin';

interface LatestFetchedData {
  code: RemoteCode | null;
  data: Document | null;
}

class SrcDocFetcher {
  private latestFetchedData: LatestFetchedData = {
    code: null,
    data: null,
  };
  private readonly godoSno!: number;

  constructor(godoSno: number) {
    this.godoSno = godoSno;
  }

  public async fetchIframe(code: RemoteCode, ecCode: string): Promise<boolean> {
    if (code === this.latestFetchedData.code) return true;

    try {
      const { data } = await fetch.iframe({
        sno: this.godoSno,
        code,
        ecCode,
      });
      if (data.body.textContent === 'UNABLE_CODE') {
        alert('대응하는 콘텐츠가 없습니다.');
        return false;
      }

      // same request blocker
      this.latestFetchedData.code = code;
      this.latestFetchedData.data = data;
      return true;
    } catch (err) {
      console.error(err);
    }
  }

  public getSrcDoc(): Document {
    return this.latestFetchedData.data;
  }
}

const adminStore = namespace('admin');

@Component
export default class SrcdocModel extends Vue {
  @Ref()
  protected readonly serviceSection!: ServiceSection[];

  @adminStore.Getter('getGodoSno')
  private readonly godoSno!: number;
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;

  protected currentRemoteCode: RemoteCode | null = null;

  private srcDocFetcher: SrcDocFetcher | null = null;

  created() {
    this.srcDocFetcher = new SrcDocFetcher(this.godoSno); // this.godoSno
  }

  public async fetch(clickedCode: RemoteCode, idx: number): Promise<void> {
    let ecCode = '';
    const { plan } = this.admin;

    if (plan === 'BASIC') {
      ecCode = 'rental_mxfreebasic_ready';
    } else if (plan === 'PRO') {
      ecCode = 'rental_mx_ready';
    }

    this.resetData();
    const fetchCode = clickedCode.includes('service_pg') ? 'service_pg_info' : clickedCode;
    const fetched = await this.srcDocFetcher.fetchIframe(fetchCode, ecCode);
    const srcDoc = this.srcDocFetcher.getSrcDoc();
    this.serviceSection[idx].setSrcDoc(srcDoc);
    this.currentRemoteCode = clickedCode;

    if (!fetched) return this.resetData();
  }

  private resetData(): void {
    this.serviceSection.forEach(section => section.reset());
  }
}
