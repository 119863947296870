






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getStrDate } from '@/utils/dateFormat';
import { TERMS_TYPE } from '@/const/contents/configuration/terms';
import { TermsType } from 'ncp-api-supporter';

@Component({
  filters: {
    getStrDate,
  },
})
export default class TermsHistoryDetails extends Vue {
  @Prop({ required: true })
  private readonly data: {
    title: string;
    contents: string;
    enforcementDateTime: string;
    registeredDateTime: string;
    termsType: TermsType;
    used: boolean;
  };
  @Prop()
  private onNegativeClick: () => void;

  private get detailsOption() {
    if ([TERMS_TYPE.USE, TERMS_TYPE.PI_PROCESS].some(termsType => termsType === this.data.termsType)) {
      return {
        date: this.data.enforcementDateTime,
        label: 'CONFIGURATION.TERMS.ENFORCEMENT_DATE',
        used: this.data.used,
      };
    }

    return {
      date: this.data.registeredDateTime,
      label: 'CONFIGURATION.TERMS.SAVED_DATE',
      used: this.data.used,
    };
  }
}
