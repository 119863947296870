



















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import percentOneDecimal from '@/directives/input/percentOneDecimal';
import { TreeNode, TreeStoreEvent } from '@/types/tree';
import unsavedDetector from '@/directives/input/unsavedDetector';

const tree = namespace('tree');

export interface StandardCategoryTreeNode extends TreeNode {
  depth: number;
  categoryNo: number;
  commissionRate: string;
}

@Component({
  directives: {
    percentOneDecimal,
    unsavedDetector,
  },
})
export default class EditForm extends Vue {
  @tree.Action('recordNode')
  private readonly recordNode: (payload: { node: TreeNode; uniqueKey: string }) => void;
  @tree.Getter('getNode')
  private readonly getNode: TreeNode;
  @tree.Getter('getPath')
  private readonly getPath: string[];
  @tree.Getter('getEvent')
  private readonly getEvent: TreeStoreEvent;

  @Watch('getEvent.eventCount', { deep: true })
  subscribeEvent() {
    const eventKeyword = this.getEvent.eventName;
    switch (eventKeyword) {
      case 'RESET_RECODE':
      case 'SET_NODE':
        this.setData(this.getNode);
        break;
      case 'ASSEMBLE_NODE':
        this.postData();
        break;
      case 'SET_TREE':
        this.resetData();
        break;
    }
  }

  private data: TreeNode = null as TreeNode;
  private defaultCommissionRate = '0.0'; // 주의: 이 값은 input value 값과 상이함.

  private getData(): TreeNode {
    const commissionRate = this.getCommissionRate();
    return { ...this.data, commissionRate };
  }

  get path(): string {
    return this.getPath.join(' > ');
  }

  private setData(data): void {
    this.data = data;
    this.defaultCommissionRate = data.commissionRate || '0.0';
  }

  private postData(): void {
    if (!this.data) return;

    const node = this.getData();
    const uniqueKey = 'categoryNo';

    this.recordNode({ node, uniqueKey });
  }

  private resetData(): void {
    this.data = null as TreeNode;
    this.defaultCommissionRate = '0.0';
  }

  private getCommissionRate(): string | null {
    const inputElement = this.$refs.commissionRateInput as HTMLInputElement;
    if (!inputElement) return null;
    const inputValue = inputElement.value;
    const DEFAULT_COMMISSION_RATE = '0.0';
    if (!inputValue || inputValue === DEFAULT_COMMISSION_RATE) return '';
    const MAX_COMMISSION_RATE = '99.9';

    return Number(inputValue) > Number(MAX_COMMISSION_RATE) ? MAX_COMMISSION_RATE : inputValue;
  }
}
