import { RouteConfig } from 'vue-router';
import Reject080Main from '@/views/contents/member/sms/Reject080Main.vue';
import Reject080Config from '@/views/contents/member/sms/Reject080Config.vue';
import Reject080List from '@/views/contents/member/sms/Reject080List.vue';
import DefaultLayoutForPremium from '@/views/DefaultLayoutForPremium.vue';
import SmsMain from '@/views/contents/member/sms/SmsMain.vue';
import SmsConfig from '@/views/contents/member/sms/SmsConfig.vue';
import SmsSend from '@/views/contents/member/sms/SmsSend.vue';
import SmsAutomation from '@/views/contents/member/sms/SmsAutomation.vue';
import SmsHistory from '@/views/contents/member/sms/SmsHistory.vue';
import SmsReservation from '@/views/contents/member/sms/SmsReservation.vue';
import NotificationMain from '@/views/contents/member/kakao/NotificationMain.vue';
import NotificationConfig from '@/views/contents/member/kakao/NotificationConfig.vue';
import NotificationTemplate from '@/views/contents/member/kakao/NotificationTemplate.vue';
import NotificationHistory from '@/views/contents/member/kakao/NotificationHistory.vue';

export default (): RouteConfig => {
  const breadcrumb = {
    UNSUBSCRIBE_080: ['운영관리', '080수신거부 관리'],
    SMS: ['운영관리', 'SMS 관리'],
    KAKAO_NOTIFICATION_TALK: ['운영관리', '카카오 알림톡'],
  };
  return {
    path: '/premium',
    name: 'premium',
    component: DefaultLayoutForPremium,
    children: [
      //080수신거부
      {
        path: 'sms/reject',
        name: 'Reject080Main',
        component: Reject080Main,
        redirect: { name: 'Reject080Config' },
        children: [
          {
            path: 'config',
            name: 'Reject080Config',
            component: Reject080Config,
            meta: {
              breadcrumb: breadcrumb.UNSUBSCRIBE_080,
              type: 'premium',
            },
          },
          {
            path: 'list',
            name: 'Reject080List',
            component: Reject080List,
            meta: {
              breadcrumb: breadcrumb.UNSUBSCRIBE_080,
              type: 'premium',
            },
          },
        ],
      },
      //SMS관리
      {
        path: 'sms/main',
        name: 'SmsMain',
        component: SmsMain,
        redirect: { name: 'SmsConfig' },
        children: [
          {
            path: 'config',
            name: 'SmsConfig',
            component: SmsConfig,
            meta: {
              breadcrumb: breadcrumb.SMS,
              detection: true,
              detectConfirm: true,
            },
          },
          {
            path: 'send',
            name: 'SmsSend',
            component: SmsSend,
            meta: {
              breadcrumb: breadcrumb.SMS,
              detection: true,
              detectConfirm: true,
            },
          },
          {
            path: 'automation',
            name: 'SmsAutomation',
            component: SmsAutomation,
            meta: {
              breadcrumb: breadcrumb.SMS,
              detection: true,
            },
          },
          {
            path: 'history',
            name: 'SmsHistory',
            component: SmsHistory,
            meta: {
              breadcrumb: breadcrumb.SMS,
            },
          },
          {
            path: 'reservation',
            name: 'SmsReservation',
            component: SmsReservation,
            meta: {
              breadcrumb: breadcrumb.SMS,
            },
          },
        ],
      },
      //카카오 알림톡
      {
        path: 'kakao/notification',
        name: 'NotificationMain',
        component: NotificationMain,
        redirect: { name: 'NotificationConfig' },
        children: [
          {
            path: 'config',
            name: 'NotificationConfig',
            component: NotificationConfig,
            meta: {
              breadcrumb: breadcrumb.KAKAO_NOTIFICATION_TALK,
              detection: true,
            },
          },
          {
            path: 'template',
            name: 'NotificationTemplate',
            component: NotificationTemplate,
            meta: {
              breadcrumb: breadcrumb.KAKAO_NOTIFICATION_TALK,
            },
          },
          {
            path: 'history',
            name: 'NotificationHistory',
            component: NotificationHistory,
            meta: {
              breadcrumb: breadcrumb.KAKAO_NOTIFICATION_TALK,
            },
          },
        ],
      },
    ],
  };
};
