







import { Vue, Component } from 'vue-property-decorator';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';
import { i18n } from '@/main';

@Component({ components: { TabMenu } })
export default class Members extends Vue {
  private menuList: TabMenuI[] = [{ display: i18n.t('기간별 회원 현황') as string, path: 'period-sales' }];
}
