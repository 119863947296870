export default Object.freeze({
  TITLE: '할인쿠폰 선택',
  TITLE_DETAIL: 'ⓘ 인서트 쿠폰만 조회되어 나옵니다.',
  FORM_LABEL_ONE: '검색어',
  FORM_INPUT_PLACEHOLDER: '검색어를 입력하세요',
  FORM_LABEL_TWO: '쿠폰 발급 기간',
  FORM_LABEL_THREE: '혜택 구분',
  TABLE_TITLE_BEFORE: '조회결과 총',
  TABLE_TITLE_AFTER: '건',
  SEARCH_TYPE_ALL: '전체',
  SEARCH_TYPE_NAME: '쿠폰명',
  SEARCH_TYPE_NO: '쿠폰번호',
  SEARCH_DATE_TYPE_START: '발행시작일',
  SEARCH_DATE_TYPE_END: '발행종료일',
  SEARCH_DATE_TYPE_REGISTER: '쿠폰등록일',
  COUPON_TYPE_PRODUCT: '상품 금액 할인',
  COUPON_TYPE_CART: '장바구니 금액 할인',
  STATUS_TYPE_READY: '발급대기',
  STATUS_TYPE_ING: '발급 중',
  STATUS_TYPE_STOP: '발급중지',
  STATUS_TYPE_END: '발급종료',
  ISSUE_TYPE_DOWNLOAD: '다운로드 발급',
  ISSUE_TYPE_DESIGNATE: '지정코드 발급',
  ISSUE_TYPE_RANDOM: '난수코드 발급',
  ISSUE_TYPE_INSERT: '인서트 발급',
  ISSUE_TYPE_ADMIN_ONLY: '지정코드 발급(어드민 전용)',
  TABLE_HEADER_ONE: '선택',
  TABLE_HEADER_TWO: '쿠폰번호',
  TABLE_HEADER_THREE: '쇼핑몰',
  TABLE_HEADER_FOUR: '쿠폰상태',
  TABLE_HEADER_FIVE: '쿠폰이름',
  TABLE_HEADER_SIX: '혜택구분',
  TABLE_HEADER_SEVEN: '발행유형',
  TABLE_HEADER_EIGHT: '발행수량',
  TABLE_HEADER_NIGH: '발급현황',
  TABLE_HEADER_TEN: '사용현황',
  TABLE_HEADER_ELEVEN: '발행 시작일',
  TABLE_HEADER_TWELVE: '발행 종료일',
  TABLE_HEADER_THIRTEEN: '등록자',
  TABLE_HEADER_FOURTEEN: '등록일',
  TABLE_HEADER_FIFTEEN: '최종수정자',
  TABLE_HEADER_SIXTEEN: '최종수정일',
  SUBMIT_BTN: '적용',
  CANCEL_BTN: '취소',
  ERROR_SELECT_COUPON: '쿠폰을 선택해 주세요.',
  ERROR_COUPON_DUPLICATE: '중복된 쿠폰번호가 있습니다.',
  ERROR_COUPON_CANNOT_SELECTED: '발급중지 쿠폰은 선택할 수 없습니다.',
});
