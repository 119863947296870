// popup
import { throwWindowPopupWithProps, ThrowWindowPopupProps, throwPopup } from '@/helpers/popup';
// types
// import { GetContractFiles } from 'ncp-api-supporter';
import tuiGrid, { RowKey } from '@/types/tui-grid';
import { PartnerStatus } from 'ncp-api-supporter';

// 계약서 팝업
export const throwPartnerContractFilesPopup = (contractNo: number, contractStatus: PartnerStatus, mallNo: number) => {
  // const { domesticDocuments, globalDocuments } = files;
  // if (!(domesticDocuments.length || globalDocuments.length)) return;

  const popupProps: ThrowWindowPopupProps = {
    componentName: 'PartnerContractFilesPopup',
    data: { contractNo, contractStatus, mallNo },
    size: 'md',
    popupHeight: 500,
  };
  throwWindowPopupWithProps(popupProps);
};

//계약 서류 변경 이력 팝업
export const throwPartnerContractFilesUpdateHistoryPopup = (mallNo: number) => {
  throwPopup({
    name: 'ContractFilesUpdateHistory',
    data: { mallNo },
  });
};

export const getEntryContractFilePopupReadOnly = (contractNo: number) => {
  const popupProps: ThrowWindowPopupProps = {
    componentName: 'EntryContractFilePopupReadOnly',
    data: { contractNo },
    size: 'md',
    popupHeight: 600,
  };

  return () => {
    throwWindowPopupWithProps(popupProps);
  };
};

export const getEntryContractFilePopupCancel = (contractNo: number, grid: tuiGrid, rowKey: RowKey) => {
  const popupProps: ThrowWindowPopupProps = {
    componentName: 'EntryContractFilePopupCancel',
    data: { contractNo },
    size: 'md',
    onClose: ({ state, data: { latestEntryContractAgreed, entryContractSize } }) => {
      if (state === 'close') return;

      grid.setValue(rowKey, 'latestEntryContractAgreed', latestEntryContractAgreed);
      grid.setValue(rowKey, 'entryContractSize', entryContractSize);
    },
    popupHeight: 600,
  };

  return () => {
    throwWindowPopupWithProps(popupProps);
  };
};

export const getEntryContractFilePopupRegistration = (contractNo: number, grid: tuiGrid, rowKey: RowKey) => {
  const popupProps: ThrowWindowPopupProps = {
    componentName: 'EntryContractFilePopupRegistration',
    data: { contractNo },
    size: 'md',
    onClose: ({ state, data: { latestEntryContractAgreed, entryContractSize } }) => {
      if (state === 'close') return;

      grid.setValue(rowKey, 'latestEntryContractAgreed', latestEntryContractAgreed);
      grid.setValue(rowKey, 'entryContractSize', entryContractSize);
    },
    popupHeight: 600,
  };
  return () => {
    throwWindowPopupWithProps(popupProps);
  };
};

export const getEntryContractFilePopupModification = (contractNo: number, grid: tuiGrid, rowKey: RowKey) => {
  const popupProps: ThrowWindowPopupProps = {
    componentName: 'EntryContractFilePopupModification',
    data: { contractNo },
    size: 'md',
    onClose: ({ state, data: { latestEntryContractAgreed, entryContractSize } }) => {
      if (state === 'close') return;

      grid.setValue(rowKey, 'latestEntryContractAgreed', latestEntryContractAgreed);
      grid.setValue(rowKey, 'entryContractSize', entryContractSize);
    },
    popupHeight: 600,
  };

  return () => {
    throwWindowPopupWithProps(popupProps);
  };
};
