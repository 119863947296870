




















import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Grid } from '@toast-ui/vue-grid';
import { i18nListByKey } from '@/utils/i18n';
import gridProps from '@/components/popup/product/stock/StockChangeHistory';
import { GetStocksStockNoChangeHistories } from 'ncp-api-supporter';

@Component({
  components: {
    Grid,
  },
})
export default class StockChangeHistory extends Vue {
  @Prop() private readonly data;
  @Prop() private readonly onNegativeClick!: Function;
  @Ref('tuiGrid') grid: Grid;

  private gridColumns = gridProps.columns;
  private gridOptions = gridProps.options;
  private gridHeader = gridProps.header;
  private pageSize = 10;

  created() {
    this.gridColumns = i18nListByKey(this, this.gridColumns, 'header');
  }

  mounted() {
    this.grid.invoke('on', 'beforePageMove', (event: any) => {
      this.searchStockChangeHistory(event.page);
      event.stop();
    });
    this.searchStockChangeHistory(1);
  }

  private async searchStockChangeHistory(page: number) {
    const request = {
      pathParams: {
        stockNo: this.data.stockNo,
      },
      params: {
        page,
        size: this.pageSize,
      },
    };
    try {
      const response = await this.$api.getStocksStockNoChangeHistories(request);
      const data: GetStocksStockNoChangeHistories = response.data;
      data.contents['isUsingWms'] = this.data.isUsingWms;
      const pageState = {
        totalCount: data.totalCount,
        page,
        perPage: this.pageSize,
      };
      this.grid.invoke('resetData', data.contents, { pageState });
    } catch (e) {
      console.error(e);
    }
  }
}
