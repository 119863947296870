export default Object.freeze({
  PAGE_TIT: 'SMS 관리',
  TABLE_TIT: '자동SMS 유형',
  DETAIL_ONE: 'ⓘ 자동 SMS 발송을 설정할 수 있습니다.',
  DETAIL_TWO: 'Ⓘ 자동 SMS 발송은 사용함/사용안함으로 발송 여부를 설정할 수 있습니다.',
  DETAIL_THREE:
    "Ⓘ '배송지 입력 안내'는 SMS Appkey가 등록되어 있어야 하며, 발송요금/내역은 TOAST Cloud에서 확인할 수 있습니다.",
  USED: '사용함',
  NOT_USED: '사용 안 함',
  ARCODION_LST_EMPTY: '',
  BTN_PREVIEW: '미리보기',
  BTN_EDIT: '편집하기',
  BTN_HELP: '치환 텍스트 보기',
  BTN_SAVE: '저장',
  WARN_USER: '사용 메시지만 미리보기가 가능합니다.',
  WARN_NULL: '템플릿 작성을 완료해주세요.',
  SUCCESS_SEND: '변경사항이 저장되었습니다.',
  ALERT_UNSAVED: '변경사항이 저장되지 않을 수 있습니다.',
  INTERCHANGE_GUIDE:
    '자동SMS 발송메시지 설정 시 아래 치환텍스트를 이용하여 SMS 수신자에게 알맞은 내용을 전송할 수 있습니다.',
  TH_1: '치환 텍스트',
  TH_2: '설명',
  TH_3: '예시',
  TH_4: '비고',
  HELP_TITLE: '치환텍스트 목록',
  CHANGE: '변경',
});
