import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import SkinListIframe from '@/views/contents/appearance/basic/SkinListIframe.vue';
import AdditionIframe from '@/views/contents/appearance/custom/AdditionIframe.vue';
import Banner from '@/views/contents/appearance/custom/Banner.vue';
import BannerAddForm from '@/views/contents/appearance/custom/BannerAddForm.vue';
import PopupIframe from '@/views/contents/appearance/custom/PopupIframe.vue';
import PopupAddIframe from '@/views/contents/appearance/custom/PopupAddIframe.vue';
import PopupFormIframe from '@/views/contents/appearance/custom/PopupFormIframe.vue';
import ProductMainIframe from '@/views/contents/appearance/custom/ProductMainIframe.vue';
import ProductMainAddIframe from '@/views/contents/appearance/custom/ProductMainAddIframe.vue';
import HeadlessBanners from '@/views/contents/appearance/custom/HeadlessBanners.vue';
import HeadlessBannersAdd from '@/views/contents/appearance/custom/HeadlessBannersAdd.vue';
import HeadlessBannersEdit from '@/views/contents/appearance/custom/HeadlessBannersEdit.vue';
import SkinBanners from '@/views/contents/appearance/custom/SkinBanners.vue';
import SkinBannersAdd from '@/views/contents/appearance/custom/SkinBannersAdd.vue';
import SkinBannersEdit from '@/views/contents/appearance/custom/SkinBannersEdit.vue';

export default (): RouteConfig => {
  return {
    path: '/appearance',
    name: 'appearance',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/skins',
        name: 'SkinListIframe',
        component: SkinListIframe,
      },
      {
        path: 'custom/addition',
        name: 'AdditionDev',
        component: AdditionIframe,
      },
      {
        path: 'custom/banner',
        name: 'Banner',
        component: Banner,
      },
      {
        path: 'custom/banner/addForm',
        name: 'BannerAddForm',
        component: BannerAddForm,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '배너 관리', '배너 등록'],
        },
      },
      {
        path: 'custom/banner/modifyForm',
        name: 'BannerModifyForm',
        component: BannerAddForm,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '배너 관리', '배너 수정'],
        },
      },
      // 어드민 통합(팝업창 관리)
      {
        path: 'custom/popup',
        name: 'PopupIframe',
        component: PopupIframe,
      },
      {
        path: 'custom/popup/registration',
        name: 'PopupAdd',
        component: PopupAddIframe,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '팝업창 관리', '팝업창 등록'],
        },
      },
      {
        path: 'custom/popup/modify',
        name: 'PopupModify',
        component: PopupFormIframe,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '팝업창 관리', '팝업창 수정'],
        },
      },

      // 어드민 통합(팝업창 관리) END
      {
        path: 'custom/product-main',
        name: 'ProductMainDev',
        component: ProductMainIframe,
      },
      {
        path: 'custom/product-main/add',
        name: 'ProductMainAddDev',
        component: ProductMainAddIframe,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '상품 진열 관리', '상품 진열 등록'],
        },
      },
      {
        path: 'custom/product-main/edit',
        name: 'ProductMainEditDev',
        component: ProductMainAddIframe,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '상품 진열 관리', '상품 진열 수정'],
        },
      },
      {
        path: 'custom/headless-banners',
        name: 'HeadlessBanners',
        component: HeadlessBanners,
      },
      {
        path: 'custom/headless-banners/add',
        name: 'HeadlessBannersAdd',
        component: HeadlessBannersAdd,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '헤드리스 배너 관리', '헤드리스 배너 등록'],
        },
      },
      {
        path: 'custom/headless-banners/edit',
        name: 'HeadlessBannersEdit',
        component: HeadlessBannersEdit,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '헤드리스 배너 관리', '헤드리스 배너 수정'],
        },
      },
      {
        path: 'custom/skin-banners',
        name: 'SkinBanners',
        component: SkinBanners,
      },
      {
        path: 'custom/skin-banners/add',
        name: 'SkinBannersAdd',
        component: SkinBannersAdd,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '스킨 배너 관리', '스킨 배너 등록'],
        },
      },
      {
        path: 'custom/skin-banners/edit',
        name: 'SkinBannersEdit',
        component: SkinBannersEdit,
        meta: {
          breadcrumb: ['디자인', '디자인 설정', '스킨 배너 관리', '스킨 배너 수정'],
        },
      },
    ],
  };
};
