






































import { ProductCertificationInfo } from 'ncp-api-supporter';
import { Vue, Component, PropSync, Ref, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import { throwWindowPopup } from '@/helpers/popup';
import { CertificationInfoDetails, UpsertType } from '@/types';

type CertificationType = 'NOT_TARGET' | 'TARGET' | 'DETAIL_PAGE';
interface CertificationOption {
  data: ProductCertificationInfo[];
  type: CertificationType;
}
@Component({
  components: {
    RadioGroup,
  },
})
export default class CertificationInfo extends Vue {
  @PropSync('certificationInfo')
  private certificationInfoSync!: CertificationOption;

  @Prop({ required: true })
  private readonly disabled: boolean;

  @Prop()
  private readonly standardCategoryNo!: number;

  private readonly radioOption: RadioBoxGroupOption<CertificationType> = {
    name: 'certificationInfo',
    data: [
      {
        id: 'certificationInfoNotTarget',
        value: 'NOT_TARGET',
        display: 'PRODUCT.ADD.CERTIFICATION_INFO_NOT_TARGET',
      },
      { id: 'certificationInfoTarget', value: 'TARGET', display: 'PRODUCT.ADD.CERTIFICATION_INFO_TARGET' },
    ],
  };

  @Ref()
  private readonly certificationRef: HTMLButtonElement;
  public focus(): void {
    this.certificationRef.focus();
  }

  public resetCertification() {
    this.$set(this.certificationInfoSync, 'type', 'NOT_TARGET');
    this.$set(this.certificationInfoSync, 'data', []);
  }

  private upsertCertificationInfo(type: UpsertType = 'insert'): void {
    if (!this.standardCategoryNo) {
      alert(this.$t('PRODUCT.ADD.PLZ_SELECT_STANDARD_CATEGORY_FOR_CERTIFICATION').toString());
      this.$emit('focusOnStandardCategory');
      return;
    }
    throwWindowPopup(
      'UpsertCertificationInfo',
      { type, categoryNo: this.standardCategoryNo, certifications: this.certificationInfoSync.data },
      'xxlg',
      data => this.setCertificationInfo(data.data),
      null,
      null,
      null,
      820,
    );
  }

  private setCertificationInfo(data: CertificationInfoDetails[]) {
    if (!data) return;
    this.certificationInfoSync.data = (data.map(
      ({
        certificationCategoryNo,
        certificationAuthority,
        certificationNumber,
        certificationCompanyName,
        certificationDate,
      }) => {
        return {
          certificationCategoryNo,
          certificationContents: [
            certificationAuthority,
            certificationNumber,
            certificationCompanyName,
            certificationDate,
          ],
        };
      },
    ) as unknown) as ProductCertificationInfo[];
  }
}
