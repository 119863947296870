// ----- 검색 조건 옵션 ----
import { CheckBoxGroupOption, GroupOptionData, OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { PayTypeCheckValue, PlatformTypesCheckValue } from '@/components/shipping/claim/searchOptions.type';
import { platformTypes } from '@/const/order';
import payType from '@/const/words/payType';
import { PeriodType } from '@/types/partner';

function ascending(optionX, optionY) {
  return optionX.name < optionY.name ? -1 : optionX.name === optionY.name ? 0 : 1;
}

export const periodTypeOptions: OptionData<PeriodType>[] = [
  { name: 'STATISTIC.SALES.MONTHLY', value: 'MONTH' },
  { name: 'STATISTIC.SALES.PERIOD', value: 'PERIOD' },
];

const includeClaimOptions: RadioBoxGroupOption<boolean> = {
  name: 'genderOption',
  nonI18nConvert: false,
  data: [
    { id: 'noIncludeClaim', value: false, display: 'STATISTIC.SALES.FIRST_PAY_OPTION' },
    { id: 'includeClaim', value: true, display: 'STATISTIC.SALES.FIRST_PAY_CLAIM_OPTION' },
  ],
};
const platformTypeOptions: CheckBoxGroupOption<PlatformTypesCheckValue> = {
  name: 'platformTypes',
  hasAll: true,
  noBrackets: true,
  nonI18nConvert: false,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    {
      id: 'pc',
      value: platformTypes.PC,
      display: 'PC_WEB',
    },
    {
      id: 'mobileWeb',
      value: platformTypes.MOBILE_WEB,
      display: 'MOBILE_WEB',
    },
    // TODO: 샵바이 프로에서 모바일 앱 기능 제공 시점에 노출
    // {
    //   id: 'mobileApp',
    //   value: platformTypes.MOBILE_APP,
    //   display: 'MOBILE_APP',
    // },
  ],
};

const payTypesCheckData = (): GroupOptionData<PayTypeCheckValue>[] =>
  payType.map(({ value, name }) => ({ id: value, value: value, display: name }));
const payTypes: CheckBoxGroupOption<PayTypeCheckValue> = {
  name: 'payTypes',
  hasAll: true,
  noBrackets: true,
  nonI18nConvert: false,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    ...payTypesCheckData(),
  ],
};
const memberJoinTypeOptions: OptionData<'' | 'MEMBER' | 'NON_MEMBER'>[] = [
  {
    value: '',
    name: 'STATISTIC.SALES.MEMBER_JOIN_TYPE',
  },
  {
    value: 'MEMBER',
    name: 'STATISTIC.SALES.MEMBER',
  },
  {
    value: 'NON_MEMBER',
    name: 'STATISTIC.SALES.NON_MEMBER',
  },
];

const genderOptions: OptionData<'MALE,FEMALE,UNKNOWN' | 'UNKNOWN' | 'MALE' | 'FEMALE' | ''>[] = [
  { name: 'STATISTIC.SALES.MEMBER_GENDER', value: '' },
  { name: 'ALL', value: 'MALE,FEMALE,UNKNOWN' },
  { name: 'GANDER_MALE', value: 'MALE' },
  { name: 'GANDER_FEMALE', value: 'FEMALE' },
  { name: 'STATISTIC.SALES.UNKNOWN', value: 'UNKNOWN' },
];

const memberGradeOptions: OptionData<number>[] = [
  {
    value: 0,
    name: 'STATISTIC.SALES.MEMBER_GRADE',
  },
];

const memberTypeOptions: OptionData<'ALL' | 'MALL' | 'SIMPLE_LOGIN'>[] = [
  { name: 'ALL', value: 'ALL' },
  { name: 'MEMBER.LIST.BASIC_MEMBER', value: 'MALL' },
  { name: 'MEMBER.LIST.SIMPLE_LOGIN', value: 'SIMPLE_LOGIN' },
];

const openIdOptions: OptionData<'PAYCO,NAVER,KAKAO,FACEBOOK,GOOGLE,APPLE,APP_CARD' | 'PAYCO' | 'NAVER' | 'KAKAO' | 'FACEBOOK' | 'GOOGLE' | 'APPLE' | 'APP_CARD'>[] = [
  { name: 'ALL', value: 'PAYCO,NAVER,KAKAO,FACEBOOK,GOOGLE,APPLE,APP_CARD' },
  { name: 'PAYCO_KO', value: 'PAYCO' },
  { name: 'NAVER_KO', value: 'NAVER' },
  { name: 'KAKAO_KO', value: 'KAKAO' },
  { name: 'FACEBOOK_KO', value: 'FACEBOOK' },
  { name: 'GOOGLE_KO', value: 'GOOGLE' },
  { name: 'APPLE_KO', value: 'APPLE' },
  { name: 'APP_CARD_KO', value: 'APP_CARD' },
];

const searchKeywordTypeOptions: OptionData<'NO' | 'NAME' | 'MANAGEMENT_CD'>[] = [
  {
    value: 'NAME',
    name: 'STOCK.MANAGEMENT.PRODUCT_NAME',
  },
  {
    value: 'NO',
    name: 'STOCK.MANAGEMENT.MALL_PRODUCT_NO',
  },
  {
    value: 'MANAGEMENT_CD',
    name: 'STOCK.MANAGEMENT.PRODUCT_MANAGEMENT_CD',
  },
];

const brandOptions: OptionData<number>[] = [
  {
    value: 0,
    name: 'STATISTIC.SALES.BRAND',
  },
];

const saleMethodTypeOptions: OptionData<'PURCHASE,CONSIGNMENT' | 'PURCHASE' | 'CONSIGNMENT'>[] = [
  {
    value: 'PURCHASE,CONSIGNMENT',
    name: 'STATISTIC.SALES.SALES_METHOD',
  },
  {
    value: 'PURCHASE',
    name: 'STATISTIC.SALES.PURCHASE',
  },
  {
    value: 'CONSIGNMENT',
    name: 'STATISTIC.SALES.CONSIGNMENT',
  },
];

// 옵션데이터 가공 유틸
export function makeBrandOptions(getBrandsResponseData) {
  const fetchedBrandOptions = getBrandsResponseData
    .map(({ brandName, displayBrandNo }) => ({
      name: brandName,
      value: displayBrandNo,
    }))
    .sort(ascending);

  return [brandOptions[0], ...fetchedBrandOptions];
}

// 탭별 search 옵션
export const getPeriodSalesSearchOptions = () => ({
  payTypes,
  memberTypeOptions,
  memberJoinTypeOptions,
  genderOptions,
  memberGradeOptions,
  includeClaimOptions,
  platformTypeOptions,
  openIdOptions,
});

export const getProductSalesSearchOptions = () => ({
  searchKeywordTypeOptions,
  memberTypeOptions,
  genderOptions,
  periodTypeOptions,
  memberGradeOptions,
  includeClaimOptions,
  platformTypeOptions,
  memberJoinTypeOptions,
  openIdOptions,
  brandOptions,
  saleMethodTypeOptions,
});

export const getCategorySalesSearchOptions = () => ({
  memberTypeOptions,
  genderOptions,
  periodTypeOptions,
  memberGradeOptions,
  includeClaimOptions,
  platformTypeOptions,
  memberJoinTypeOptions,
  openIdOptions,
  brandOptions,
});
