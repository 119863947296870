





































































































































































































































































































































import { Vue, Component, PropSync, Watch, Ref, Prop } from 'vue-property-decorator';
import ReservedSales from '@/views/contents/product/basic/ProductAdd/salesInfo/details/ReservedSales.vue';
import SalesPeriod from '@/views/contents/product/basic/ProductAdd/salesInfo/details/SalesPeriod.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import CommissionInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/CommissionInfo.vue';
import ImmediateDiscountInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/ImmediateDiscountInfo.vue';
import PlaceOriginInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/PlaceOriginInfo.vue';
import SalePriceInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/SalePriceInfo.vue';
import ImmediateDiscountPrice from '@/views/contents/product/basic/ProductAdd/salesInfo/details/ImmediateDiscountPrice.vue';
import AccumulationRateConfig from '@/views/contents/product/basic/ProductAdd/salesInfo/details/AccumulationRateConfig.vue';
import PromotionInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/PromotionInfo.vue';
import AccumulationUseInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/AccumulationUseInfo.vue';
import StockCntInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/StockCntInfo.vue';
import OptionsInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/OptionsInfo.vue';
import DutyInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/DutyInfo.vue';
import CertificationInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/CertificationInfo.vue';
import BuyerProductName from '@/views/contents/product/basic/ProductAdd/salesInfo/details/BuyerProductName.vue';
import ManufactureInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/ManufactureAndExpirationInfo.vue';
import ValueAddedTaxInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/ValueAddedTaxInfo.vue';
import IconsInfo from '@/views/contents/product/basic/ProductAdd/salesInfo/details/IconsInfo.vue';
import MinPurchaseQuantity from '@/views/contents/product/basic/ProductAdd/salesInfo/details/MinPurchaseQuantity.vue';
import MaxPurchaseQuantity from '@/views/contents/product/basic/ProductAdd/salesInfo/details/MaxPurchaseQuantity.vue';
import ProductManagementCd from '@/views/contents/product/basic/ProductAdd/salesInfo/details/ProductManagementCd.vue';
import {
  CommissionRateType,
  GetAccumulationConfigurations,
  GetAccumulationConfigurationsRequest,
  Mall,
  NCPResponse,
} from 'ncp-api-supporter';
import {
  CommissionType,
  RoundingMode,
  SalesInfoCalculator,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { SalesInfoCalculatorCreatorByRoundingStrategy } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculatorCreatorByRoundingStrategy';
import {
  DiscountPriceCalculatorInfo,
  RoundingRule,
  SalesInfoCalculatorInfo,
  SalesInformation,
  SupplyPriceCalculatorInfo,
  SalesInfoFocusType,
  SalePriceFocusType,
  SalesInformationModel,
  EditFlag,
  CommissionInfoType,
  MaxPurchaseType,
} from '@/types';
import OptionDetails from '@/views/contents/product/basic/ProductAdd/salesInfo/details/OptionDetails.vue';
import { namespace } from 'vuex-class';
import { ProductManagementMode, SalesInfoRefModel } from '@/views/contents/product/basic/ProductAdd.vue';
import { DELETE_ERROR, PRODUCT_FORM_ERROR_TYPE } from '@/const/contents/product';
import { formatCurrency } from '@/utils/numberFormat';
import { getDateDiff } from '@/utils/dateFormat';

const mallModule = namespace('mall');

export type UseState = {
  useNormalOption: boolean;
  reservation: boolean;
  useTextOption: boolean;
  useOption: boolean;
};
export type SalePriceInformation = {
  hasSalePrice: boolean;
  commissionType: CommissionRateType;
  immediateDiscountPrice: number;
};

@Component({
  components: {
    OptionDetails,
    ProductManagementCd,
    IconsInfo,
    ValueAddedTaxInfo,
    ManufactureInfo,
    BuyerProductName,
    CertificationInfo,
    DutyInfo,
    OptionsInfo,
    StockCntInfo,
    AccumulationUseInfo,
    PromotionInfo,
    AccumulationRateConfig,
    ImmediateDiscountPrice,
    SalePriceInfo,
    PlaceOriginInfo,
    ImmediateDiscountInfo,
    CommissionInfo,
    TextInput,
    SelectBox,
    ToolTip,
    SalesPeriod,
    ReservedSales,
    MinPurchaseQuantity,
    MaxPurchaseQuantity,
  },
})
export default class SalesInfo extends Vue {
  @mallModule.Getter('getMalls')
  private malls!: Mall[];

  @PropSync('salesInfo', { required: true })
  private salesInfoSync!: SalesInformation;

  @PropSync('salesInfoModel', { required: true })
  private salesInfoModelSync: SalesInformationModel;

  @PropSync('optionPopupOpened')
  private optionPopupOpenedSync: boolean;

  @PropSync('maxPurchaseType')
  private maxPurchaseTypeSync: MaxPurchaseType;

  @Prop({ required: true })
  private readonly editFlag: EditFlag;

  @Prop({ required: true })
  private readonly mode: ProductManagementMode;

  @Prop({ required: true })
  private readonly salesInfoRefModel: SalesInfoRefModel;

  @Prop({ required: true })
  private readonly categoryNo: number;

  @Prop({ required: true })
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly isDeepSea: boolean;

  @Ref()
  private readonly reservationRef: ReservedSales;

  @Ref()
  private readonly salesPeriod: SalesPeriod;

  @Ref()
  private readonly salePriceRef: SalePriceInfo;

  @Ref()
  private readonly commissionInfoRef: CommissionInfo;

  @Ref()
  private readonly stockCntRef: StockCntInfo;

  @Ref()
  private readonly minPurchaseRef: MinPurchaseQuantity;

  @Ref()
  private readonly optionsRef: OptionDetails;

  @Ref()
  private readonly dutyRef: DutyInfo;

  @Ref()
  private readonly certificationRef: CertificationInfo;

  @Ref()
  private readonly iconsRef: IconsInfo;

  @Ref()
  private readonly placeOriginRef: PlaceOriginInfo;

  @Ref()
  private readonly immediateDiscountInfoRef: ImmediateDiscountInfo;

  private get isPurchaseType(): boolean {
    // PURCHASE: 사입-매입가, CONSIGNMENT: 위탁-공급가
    return this.salesInfoRefModel.saleMethodType === 'PURCHASE';
  }

  //예약판매기간이 종료된 경우 -> 예약재고수량 disabled 처리
  private get isFinishedReservationSales() {
    return this.salesInfoSync.reservationInfoModel?.endYmdt
      ? getDateDiff(new Date(), this.salesInfoSync.reservationInfoModel.endYmdt, 'milliseconds') <= 0
      : false;
  }

  public resetAllOriginCountry() {
    this.placeOriginRef.resetAllOriginCountry();
  }

  public resetCertification() {
    this.certificationRef.resetCertification();
  }

  public initializeSalesInfo(): void {
    this.immediateDiscountPrice = 0;
    this.maxImmediateDiscountAmount = 0;
    this.createCalculator();
  }

  public setFinalSalesInfo(): void {
    this.reservationRef.setReservationInfoModel();
    this.salesPeriod.setSalesPeriod();
    // this.commissionInfoRef.setFinalCommissionInfo();
    this.optionsRef.setSavedOptions();
    this.iconsRef.setIcons();
  }

  public onFocus(focusTarget: SalesInfoFocusType, subType?: SalePriceFocusType): void {
    this[`${focusTarget}Ref`].focus(subType);
  }

  public reset() {
    const commissionInfo: CommissionInfoType = {
      type: CommissionType.PRODUCT,
      rate: 0,
    };
    this.$set(this.salesInfoSync, 'stickerInfos', []);
    this.$set(this.salesInfoSync, 'commissionInfo', commissionInfo);
  }

  private alertMallSelect(): void {
    this.$emit('alertMallSelect');
  }

  private alertProductName(): void {
    this.$emit('alertProductName');
  }

  private focusOnStandardCategory(): void {
    this.$emit('focusOnStandardCategory');
  }

  private alertDeliveryTemplate(): void {
    this.$emit('alertDeliveryTemplate');
  }

  private addOptionDefault(): void {
    this.optionsRef.addOptionDefault();
  }

  private setOptionPopupState(isPopupOpened: boolean): void {
    this.optionPopupOpenedSync = isPopupOpened;
  }

  private alertSalePrice(): void {
    this.salePriceRef.alertSalePrice();
  }

  private get hasSalePrice(): boolean {
    return this.salesInfoSync.salePrice > 0;
  }

  private get useState(): UseState {
    return {
      useOption: this.salesInfoModelSync.optionUseYn === 'Y' || this.mode.isMapping,
      useNormalOption: this.salesInfoSync.optionUseYn === 'Y',
      useTextOption: this.salesInfoSync.customerDemands?.length > 0,
      reservation: this.salesInfoSync.useReservation,
    };
  }

  private get salePriceInfo(): SalePriceInformation {
    return {
      hasSalePrice: this.hasSalePrice,
      commissionType: this.salesInfoSync.commissionInfo.type,
      immediateDiscountPrice: this.immediateDiscountPrice,
    };
  }

  // accumulation rate config
  private defaultAccumulationRate = 0;
  private accumulationUseYnDisabled = false;
  @Watch('salesInfoRefModel.mallNo')
  private async fetchAccumulationRateByMallNo(): Promise<void> {
    if (!this.salesInfoRefModel.isSelectedMall) {
      this.defaultAccumulationRate = 0;
      return;
    }
    const params: GetAccumulationConfigurationsRequest['params'] = {
      mallNo: Number(this.salesInfoRefModel.mallNo),
    };
    const { data }: NCPResponse<GetAccumulationConfigurations[]> = await this.$api.getAccumulationsConfigurations({
      params,
    });
    this.defaultAccumulationRate = data[0].basic.accumulationRate;
    this.accumulationUseYnDisabled = !data[0].basic.productAccumulationUsable;
  }

  // options
  private get reservationOptionStockCnt(): number {
    const filtered = this.salesInfoSync.options?.filter(option => option.reservationStockCnt > 0);
    if (!filtered) return 0;
    return filtered.reduce((acc, { reservationStockCnt }) => acc + reservationStockCnt, 0);
  }

  private get optionStockCnt(): number {
    const filtered = this.salesInfoSync.options?.filter(option => option.stockCnt > 0);
    if (!filtered) return 0;
    return filtered.reduce((acc, { stockCnt }) => acc + stockCnt, 0);
  }

  private changedSalePrice(price: number) {
    this.salePriceRef.changedSalePrice(formatCurrency(price));
  }

  private optionPrice = 0;
  @Watch('optionPrice')
  private changedOptionPrice() {
    this.calculator.changeOptionPrice(this.optionPrice);
    this.immediateDiscountPrice = this.calculator.discountPriceResult;
    this.maxImmediateDiscountAmount = this.calculator.maxDiscountAmount;
  }

  private immediateDiscountPrice = 0;
  private maxImmediateDiscountAmount = 0;

  private calculator!: SalesInfoCalculator;
  private createCalculator() {
    this.calculator = SalesInfoCalculatorCreatorByRoundingStrategy(this.getPriceInfo());
    this.immediateDiscountPrice = this.calculator.discountPriceResult;
    this.maxImmediateDiscountAmount = this.calculator.maxDiscountAmount;
  }

  private getPriceInfo(): SalesInfoCalculatorInfo {
    const discountInfo: DiscountPriceCalculatorInfo = {
      rule: this.roundingRule,
      salePrice: Number(this.salesInfoSync.salePrice),
      optionPrice: this.optionPrice,
      discountAmount: Number(this.salesInfoSync.immediateDiscountInfo.amount),
      discountType: this.salesInfoSync.immediateDiscountInfo.unitType,
    };
    const commissionInfo: SupplyPriceCalculatorInfo = {
      commissionAmount: this.salesInfoSync.commissionInfo.rate,
      commissionType: this.salesInfoSync.commissionInfo.type as CommissionType,
    };
    return { discountInfo, commissionInfo };
  }

  private get roundingRule(): RoundingRule {
    const defaultRoundingRule: RoundingRule = {
      roundingMode: RoundingMode.DOWN,
      scale: -1,
    };
    if (this.salesInfoRefModel.mallNo) {
      // 아무래도 뭔가 이상한게... mallNo 에 따라 라운딩룰이 적용되는데..
      const mall = this.malls.find(item => item.mallNo === this.salesInfoRefModel.mallNo) as Mall;
      return mall ? (mall.roundingRule as RoundingRule) : defaultRoundingRule;
    }
    return defaultRoundingRule;
  }

  // @Watch('salesInfoRefModel.mallNo')
  // private resetSalesInfo(): void {
  //   option을 reset 하는 경우 calculartor.changeOptionPrice 를 0으로 초기화 해야함
  //   this.stockCntRef.resetStockCntInfo();
  //   this.immediateDiscountInfoRef.reset();
  //   this.initializeSalesInfo();
  // }

  private created(): void {
    this.initializeSalesInfo();
  }

  // error
  @Prop({ required: true })
  private readonly formError: { [errorKey: string]: string };
  @Prop({ required: true })
  private readonly formErrorType: typeof PRODUCT_FORM_ERROR_TYPE;
  @Prop({ required: true })
  private readonly deleteError: typeof DELETE_ERROR;
}
