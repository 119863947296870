













import { Component, Vue } from 'vue-property-decorator';
import AppModal from '@/components/popup/app/AppModal.vue';
import SelectBox from '@/components/common/SelectBox.vue';

@Component({
  components: { AppModal, SelectBox },
})
export default class AppInstall extends Vue {}
