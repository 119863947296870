

































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
@Component({
  components: { CheckboxGroup },
})
export default class AdminPrivacyTerms extends Vue {
  @PropSync('privacyTermsAgree', { default: true })
  private privacyTermsAgreeSync: boolean;

  @Prop({ default: false })
  private disabled?: boolean;

  private changeTermsAgree({ target }): void {
    this.privacyTermsAgreeSync = target.checked;
  }
}
