













































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { OptionData } from '@/helpers/type';
import { ProductsSaleSettingStatusType } from 'ncp-api-supporter';
import { statusUpdatesRelatedToSaleStatus } from '@/components/product/common/girdColumns/edit/UpdateSalesSettingStatus';
import { i18n } from '@/main';

const saleSettingStatus: OptionData<ProductsSaleSettingStatusType | ''>[] = [
  { value: '', name: 'PRODUCT.EDIT.SELECT_SALES_SETTING_STATUS' }, //판매상태 선택
  { value: 'AVAILABLE_FOR_SALE', name: 'PRODUCT.STATUS.SALE.AVAILABLE_FOR_SALE' }, //판매가능
  { value: 'STOP_SELLING', name: 'PRODUCT.STATUS.SALE.STOP_SELLING' }, //판매중지
  { value: 'PROHIBITION_SALE', name: 'PRODUCT.STATUS.SALE.PROHIBITION_SALE' }, //판매금지
];

@Component({
  components: {
    ModalHeader,
    SelectBox,
  },
})
export default class ChangeSaleStatus extends Vue {
  @Prop() private data!: number[]; // mallProductNos
  private readonly saleSettingStatus = saleSettingStatus;
  private selectedSaleSettingStatus: ProductsSaleSettingStatusType | '' = '';

  @Ref() selectBox: SelectBox;

  get dialogues(): string[] {
    return this.$t('PRODUCT.EDIT.CHANGE_SALES_STATUS_DIALOGUES')
      .toString()
      .trim()
      .split('\n');
  }

  get productCount(): number {
    return this.data.length;
  }

  private validation(): boolean {
    if (!this.selectedSaleSettingStatus) {
      alert(this.$t('PRODUCT.EDIT.ALERT_CHANGE_SALES_SETTING_STATUS_MISSING'));
      this.selectBox.focus();
      return false;
    }

    return true;
  }

  private onSubmit(): void {
    if (!this.validation()) return;

    if (this.selectedSaleSettingStatus === 'PROHIBITION_SALE') {
      const confirmFrontDisplayUpdate = confirm(i18n.t('PRODUCT.EDIT.CONFIRM_CHANGE_TO_PROHIBITION_SALE').toString());
      if (!confirmFrontDisplayUpdate) return;
    }

    statusUpdatesRelatedToSaleStatus(this.selectedSaleSettingStatus as ProductsSaleSettingStatusType, this.data).then(
      () => {
        this.$emit('confirm');
      },
    );
  }
}
