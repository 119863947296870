




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class ProductSalesPricePopupIframe extends Vue {
  private get remoteSrc() {
    const query = this.$route.query;
    const queryObject = { ...query, serviceType: 'PRO' };
    const queryString = new URLSearchParams(queryObject).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/popup/product-sales-price?' + queryString;
  }
}
