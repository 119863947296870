








































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  accumulationManualInitQuery,
  searchTypeOption,
  periodTypeOption,
  dateRangeOption,
  paymentDeductOption,
  paymentTypeOption,
} from '@/const/contents/accumulationPaymentDeduct';
import { getStrDate } from '@/utils/dateFormat';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { validateDateRange } from '@/views/contents/member/accumulation/AccumulationPaymentDeduct';
import { mall } from '@/utils/mall';
import AccumulationTabMenu from '@/views/top/member/accumulation/AccumulationTabMenu.vue';
@Component({
  components: { AccumulationTabMenu, MallSelect, RadioGroup, DateRangePicker, SelectBox, SearchButton },
})
export default class AccumulationPaymentDeductManual extends Vue {
  private readonly searchTypeOption = searchTypeOption;
  private readonly periodTypeOption = periodTypeOption;
  private readonly dateRangeOption = dateRangeOption;
  private readonly paymentDeductOption = paymentDeductOption;
  private readonly paymentTypeOption = paymentTypeOption;
  private accumulationManualQuery = { ...accumulationManualInitQuery(), ...this.$route.query };
  private startDateTime = getStrDate(this.accumulationManualQuery.startDateTime);
  private endDateTime = getStrDate(this.accumulationManualQuery.endDateTime);
  private selectedMallNo = '';

  @Watch('accumulationManualQuery.requestGroupType')
  private changePaymentDeductType() {
    const currType = this.accumulationManualQuery.requestGroupType;

    if (currType === 'SUB') {
      this.accumulationManualQuery.requestTypes = 'DIRECT_SUB';
    } else if (currType === 'ADD') {
      this.paymentTypeOption.data[0].value = 'DIRECT_ADD,RESERVE_ADD';
      this.accumulationManualQuery.requestTypes = 'DIRECT_ADD,RESERVE_ADD';
    } else if (currType === 'ALL') {
      this.paymentTypeOption.data[0].value = 'DIRECT_ADD,RESERVE_ADD,DIRECT_SUB';
      this.accumulationManualQuery.requestTypes = 'DIRECT_ADD,RESERVE_ADD,DIRECT_SUB';
    }
  }

  @Watch('startDateTime')
  private changeStartDateTime(_: string, prev: string) {
    if (!validateDateRange(this.startDateTime, this.endDateTime)) {
      this.$nextTick(() => (this.startDateTime = prev));
    }
    this.accumulationManualQuery.startDateTime = `${this.startDateTime} ${DEFAULT_TIME_RANGE.START}`;
  }

  @Watch('endDateTime')
  private changeEndDateTime(curr: string, prev: string) {
    if (!curr || !validateDateRange(this.startDateTime, this.endDateTime)) {
      this.$nextTick(() => (this.endDateTime = prev));
    }
    this.accumulationManualQuery.endDateTime = `${this.endDateTime} ${DEFAULT_TIME_RANGE.END}`;
  }

  private changeSelectedMallNo(value: number | '') {
    if (value === '') {
      this.accumulationManualQuery.mallNos = mall.allMallNoString;
    } else {
      this.accumulationManualQuery.mallNos = value.toString();
    }
  }

  private resetQuery(init = false) {
    this.accumulationManualQuery = init
      ? { ...accumulationManualInitQuery(), ...this.$route.query }
      : { ...accumulationManualInitQuery() };
    this.startDateTime = getStrDate(this.accumulationManualQuery.startDateTime);
    this.endDateTime = getStrDate(this.accumulationManualQuery.endDateTime);
    const mallNos = this.accumulationManualQuery.mallNos;
    this.selectedMallNo = mallNos.includes(',') ? '' : mallNos;
    this.changePaymentDeductType();
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }

  created() {
    this.resetQuery(true);
  }
}
