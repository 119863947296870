
















import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import ServiceItem from '@/components/app/basic/ServiceItem.vue';
import { AdditionalServiceData } from '@/types/addition';

import $ from 'jquery';
import 'slick-carousel'; // 💩
import { RemoteCode } from '@/api/godo/type';
import { ItemViewType } from '@/components/addition/ServiceSection.vue';

const initSlider = (el: HTMLElement): void => {
  $(el).slick({
    arrows: false,
    dots: true,
    appendDots: $(el).parents('.exr_section'),
    speed: 500,
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true,
    infinite: false,
  });
};

@Component({
  components: { ServiceItem },
})
export default class ServiceList extends Vue {
  @Prop()
  private readonly items: AdditionalServiceData[];
  @Prop({ required: true })
  private readonly currentRemoteCode!: RemoteCode | null;
  @Prop({ required: true })
  private readonly isDetail!: boolean;
  @Prop({ default: null })
  private readonly viewType?: ItemViewType;
  @Ref()
  private readonly slideElement!: HTMLUListElement;

  mounted() {
    initSlider(this.slideElement);
  }
}
