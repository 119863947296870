




















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
@Component({
  components: { TextInput },
})
export default class NumberRangeInput extends Vue {
  @Prop({ default: 'WON' })
  private readonly unitWord!: string;
  @PropSync('startNumber', { default: null })
  private startNumberSync!: number | null;
  @PropSync('endNumber', { default: null })
  private endNumberSync!: number | null;
}
