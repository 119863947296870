






























import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import Tree from '@/components/common/tree/Tree.vue';
import { NestedTreeNode, TreeNode, TreeOptions, EventKeyword, ValidationHook } from '@/types/tree';
import { namespace } from 'vuex-class';
import nodeElements from '@/views/contents/board/basic/tree/nodeElements';

const tree = namespace('tree');
@Component({
  components: {
    Tree,
  },
})
export default class TreeContainer extends Vue {
  @Prop({ required: true })
  private readonly treeNodes!: NestedTreeNode[];
  @Prop({ default: 1 })
  private readonly defaultSelectNodeIndex!: number;
  @Prop({ required: false, default: null })
  private readonly validationHook: ValidationHook;
  @tree.Getter('getNode')
  private readonly getNode: TreeNode;
  @tree.Getter('getPath')
  private readonly getPath: string[];
  @tree.Action('publishEvent')
  private readonly publishEvent: (eventKeyword: EventKeyword) => void;
  @Ref() tree!: Tree;
  private readonly nodeElements = nodeElements();

  private btnControl = false;
  private get options(): TreeOptions {
    return {
      internalNodeName: this.$t('CATEGORY').toString(),
      leafNodeName: this.$t('CATEGORY').toString(),
      defaultSelection: true,
      defaultSelectNodeIndex: this.defaultSelectNodeIndex,
      defaultNodeData: {
        children: [],
        used: true,
      },
    };
  }

  @Watch('getPath')
  private setBoardNode() {
    if (this.getNode && this.getNode.boardNo > 0) {
      this.btnControl = true;
    } else {
      this.btnControl = false;
    }
  }

  public getTreeData() {
    return this.tree.getTreeData();
  }
}
