










































































































































import { Component } from 'vue-property-decorator';
import { ClaimOption, GetClaimsNo, NCPResponse } from 'ncp-api-supporter';
import { ClaimType } from '@/types/claim';
import { claimTypes } from '@/const/claim';
import { isExchangeClaim, isReturnClaim } from '@/components/popup/claim/claimConditions';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { formatCurrency, getOnlyNumbers } from '@/utils/numberFormat';
import TdClaimProductName from '@/components/popup/claim/TdClaimProductName.vue';

interface Form {
  restoreStockOrderOptionNos: number[];
  refundOnHold: '' | boolean;
  refundHoldProposedAmt: string;
  refundOnHoldReason: string;
}

// 교환리스트 기획서 p.60
// 반품리스트 기획서 p.52
@Component({
  components: {
    TdClaimProductName,
    TextInput,
    SelectBox,
    RadioGroup,
  },
  computed: {
    isExchangeClaim,
    isReturnClaim,
  },
})
export default class CollectPopup extends WindowPopupMainVue {
  private formData: Form = {
    restoreStockOrderOptionNos: [],
    refundOnHold: '',
    refundHoldProposedAmt: '',
    refundOnHoldReason: '',
  };

  private showRequestAmountInput = false;
  private claimType: null | ClaimType = null;
  private claimedOptions: ClaimOption[] = [];
  private refundHoldOptions: OptionData<'' | boolean>[] = [
    {
      value: '',
      name: 'CLAIM.MESSAGE.NEED_SELECT',
    },
    {
      value: false,
      name: 'CLAIM.COMMON.REFUND_HOLD_NO_REQUEST',
    },
    {
      value: true,
      name: 'CLAIM.COMMON.REFUND_HOLD_REQUEST',
    },
  ];
  private requestAmountOptions: RadioBoxGroupOption<boolean> = {
    name: 'requestAmountOptions',
    nonI18nConvert: false,
    data: [
      { id: 'requestAmt_false', value: false, display: 'CLAIM.COMMON.INPUT_DISABLE' },
      { id: 'requestAmt_true', value: true, display: 'CLAIM.COMMON.INPUT_ABLE' },
    ],
  };

  private get checkAll(): boolean {
    if (this.claimedOptions.length === 0) {
      return false;
    }

    return this.formData.restoreStockOrderOptionNos.length === this.claimedOptions.length;
  }

  private set checkAll(checked: boolean) {
    let selected = [];
    if (checked) {
      selected = this.claimedOptions.map(claimedOption => claimedOption.orderOptionNo);
    }

    this.formData.restoreStockOrderOptionNos = [...selected];
  }

  created() {
    this.fetchClaimNo();
  }

  private async fetchClaimNo() {
    const { data }: NCPResponse<GetClaimsNo> = await this.$api.getClaimsNo({
      pathParams: {
        no: this.data.claimNo.toString(),
      },
    });

    this.claimType = data.claimType as ClaimType;
    this.claimedOptions = data.claimedOptions;
  }

  private onCollect() {
    if (!this.validation()) {
      return;
    }

    const claimNo = this.data.claimNo.toString();
    const apiMap = {
      [claimTypes.EXCHANGE]: () =>
        this.$api.putReturnExchangesNoCollect({
          pathParams: {
            no: claimNo,
          },
          data: {
            restoresStock: this.checkAll,
          },
        }),

      [claimTypes.RETURN]: () =>
        this.$api.putReturnsNoCollect({
          pathParams: {
            no: claimNo,
          },
          data: {
            refundOnHoldReason:
              this.formData.refundOnHoldReason.trim().length === 0 ? null : this.formData.refundOnHoldReason.trim(),
            restoreStockOrderOptionNos: this.formData.restoreStockOrderOptionNos,
            refundOnHold: this.formData.refundOnHold as boolean,
            refundHoldProposedAmt: getOnlyNumbers(this.formData.refundHoldProposedAmt),
          },
        }),
    };

    return apiMap[this.claimType]()
      .then(() => {
        alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
        this.onPositiveClick();
      })
      .catch(error => {
        alert(error.data.message);
      });
  }

  private validation(): boolean {
    switch (this.claimType) {
      case claimTypes.EXCHANGE: {
        if (!confirm(this.$t('CLAIM.MESSAGE.ASK_COLLECT').toString())) {
          return false;
        }
        break;
      }

      case claimTypes.RETURN: {
        if (this.formData.refundOnHold === '') {
          alert(this.$t('CLAIM.MESSAGE.NEED_REFUND_HOLD_PROCESS'));
          return false;
        }

        // '수거완료(환불보류)' 이면서 '요청금액 입력함' 체크
        if (this.formData.refundOnHold && this.showRequestAmountInput) {
          if (this.formData.refundHoldProposedAmt === '' || this.formData.refundHoldProposedAmt === '0') {
            alert(this.$t('CLAIM.MESSAGE.VALID_AMOUNT'));
            return false;
          }
        }

        if (this.formData.refundOnHold && this.formData.refundOnHoldReason.trim() === '') {
          alert(this.$t('CLAIM.MESSAGE.VALID_REFUND_REQUEST_REASON'));
          return false;
        }

        if (!confirm(this.$t('CLAIM.MESSAGE.ASK_COLLECT').toString())) {
          return false;
        }
        break;
      }
    }

    return true;
  }

  private onChangeRefundHoldProposedAmt(refundHoldProposedAmt: string): string {
    return formatCurrency(getOnlyNumbers(refundHoldProposedAmt));
  }

  private onInputRefundReason(e: InputEvent) {
    this.formData.refundOnHoldReason = (e.target as HTMLTextAreaElement).value;
  }
}
