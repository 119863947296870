














import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrdersExternalFailHistory } from 'ncp-api-supporter';

@Component
export default class SyncFailList extends Vue {
  @Prop({ required: true })
  private readonly syncFailItem!: OrdersExternalFailHistory;

  private get systemLabel() {
    const labels = {
      NAVER_PAY: '네이버페이(주문형)',
      CJ_WMS: 'CJ아이넥스',
    };

    return labels[this.syncFailItem.externalSystemType];
  }
}
