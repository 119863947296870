























/**
 * components > board > inquires 에 있던 SummaryTab을 common으로 쓸 수 있도록 복사 후 일부 수정하였습니다.
 */
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

export interface SummaryItem<T extends string = string> {
  name: string;
  count: number;
  value: T;
}

@Component
export default class SummaryTab extends Vue {
  @PropSync('value')
  private valueSync: string;
  @Prop({ required: true })
  private readonly tabList: SummaryItem[];
  @Prop({ required: false, default: '' })
  private readonly unitString: string;

  created() {
    if (!this.tabList.length) throw new TypeError('SummaryTab :: Please add at least one SummaryItem.');
  }

  private onClickTab(value: string): void {
    this.valueSync = value;
  }
}
