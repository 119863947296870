export interface TargetObject {
  [key: string]: any; // eslint-disable-line
}

export interface NewKeys {
  [key: string]: string;
}

export function replaceKeyNames<ReturnObject>(array: TargetObject[], newKeys: NewKeys): ReturnObject[] {
  return array.map(object => replaceKeyName<ReturnObject>(object, newKeys)); // eslint-disable-line
}

export function replaceKeyName<ReturnObject>(object: TargetObject, newKeys: NewKeys): ReturnObject {
  const keyValues = Object.keys(object).map(key => {
    const newKey = newKeys[key] || key;

    if (Array.isArray(object[key])) {
      return {
        [newKey]: replaceKeyNames<ReturnObject>(object[key], newKeys),
      };
    }

    return {
      [newKey]: object[key],
    };
  });

  return Object.assign({}, ...keyValues);
}
