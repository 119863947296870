
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  GetSmsGodoPointRequest,
  GetSmsGodoTokenPopupPointChargeRequest,
  NCPResponse,
  GetSmsGodoPoint,
  GetSmsGodoTokenPopupPointCharge,
  GetNotificationsConfigurationsRequest,
} from 'ncp-api-supporter';
import { getMallNo4Notification } from '@/utils/mall';
import { throwExternalWindowPopup } from '@/helpers/popup';

@Component
export default class SmsPoint extends Vue {
  public callingNumber = '';
  public point = 0;
  public useType = false;

  @Watch('$route.query')
  public async fetchInitConfig() {
    await Promise.all([this.fetchSmsPoint(false), this.fetchMallsConfig()]);
  }

  public async fetchSmsPoint(isAlert = true): Promise<void> {
    const request: GetSmsGodoPointRequest = {
      params: {
        mallNos: getMallNo4Notification(this).toString(),
      },
    };
    try {
      const response: NCPResponse<GetSmsGodoPoint[]> = await this.$api.getSmsGodoPoint(request);
      this.point = response.data[0].point;
    } catch (err) {
      if (isAlert) alert(this.$t('MEMBER.SMS.RETRY_MESSAGE'));
      this.point = 0;
      console.error(err);
    }
  }

  public async openChargePopup(): Promise<void> {
    const request: GetSmsGodoTokenPopupPointChargeRequest = {
      params: {
        mallNo: getMallNo4Notification(this),
      },
    };
    try {
      const response: NCPResponse<GetSmsGodoTokenPopupPointCharge> = await this.$api.getSmsGodoTokenPopupPointCharge(
        request,
      );
      throwExternalWindowPopup(response.data.url, 'md', {
        token: response.data.token,
        godosno: response.data.sno,
        type: response.data.type,
      });
    } catch (err) {
      console.error(err);
    }
  }

  public get registerNumber(): string {
    return this.callingNumber ? this.callingNumber : (this.$t('MEMBER.SMS.NO_CALLING_NUMBER') as string);

    // if (this.callingNumber.length === 8) {
    //   const [_, first, second] = this.callingNumber.match(/^(\d{4})(\d{4})$/);
    //   return `${first}-${second}`;
    // } else {
    //   const [_, first, second, third] = this.callingNumber.match(/^(\d{3})(\d{3,4})(\d{3,4})$/);
    //   return `${first}-${second}-${third}`;
    // }
  }

  public async fetchMallsConfig(mallNo: number = getMallNo4Notification(this)): Promise<void> {
    const request: GetNotificationsConfigurationsRequest = {
      params: {
        mallNo: mallNo,
      },
    };
    const { data }: NCPResponse<any> = await this.$api.getNotificationsConfigurations(request);
    this.useType = data.smsUsed ?? false;
    this.callingNumber = data.smsSendno ?? '';
  }

  created() {
    this.fetchInitConfig();
  }
}
