












import { Component, Vue, Prop, PropSync, Ref } from 'vue-property-decorator';
import { SearchMode } from '@/components/common/categories/MultiCategoriesSelector.vue';

@Component
export default class SearchModeSwitchButton extends Vue {
  @Prop({ required: true, default: false }) private readonly keywordReady!: boolean;
  @Prop({ required: true, default: false }) private readonly selectReady!: boolean;
  @Prop() private disabled?: boolean;
  @PropSync('searchMode') private searchModeSync!: SearchMode;
  @Ref() button: HTMLButtonElement;

  searchModeSwitch(): void {
    if (!this.keywordReady) {
      this.$emit('error:no-data', 'keyword');
      return;
    }

    if (!this.selectReady) this.$emit('error:no-data', 'select');

    this.searchModeSync = this.searchModeSync === 'keyword' ? 'select' : 'keyword';
  }

  public focus(): void {
    this.button.focus();
  }
}
