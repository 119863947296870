






import { Vue, Component } from 'vue-property-decorator';
import RegisterSearchForm from '@/components/partner/register/RegisterSearchForm.vue';
@Component({
  components: { RegisterSearchForm },
})
export default class PartnerRegisterSearch extends Vue {}
