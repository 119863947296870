









































import { Component, PropSync, Vue } from 'vue-property-decorator';
import { AdminMemo } from 'ncp-api-supporter';
import sanitizeHtml from '@/utils/sanitizeHtml';
import TextInput from '@/components/common/input/TextInput.vue';
@Component({
  components: { TextInput },
})
export default class PreviousOrderAdminMemo extends Vue {
  @PropSync('adminMemos', { required: true })
  private adminMemosSync: AdminMemo[];

  private memoInput = '';

  private async handleDeleteClick(memoNo: number, deletable: boolean) {
    if (!confirm('관리자 메모를 삭제하시겠습니까? 삭제한 메모는 복구할 수 없습니다.')) {
      return;
    }
    if (!deletable) {
      return alert('해당 메뉴의 쓰기 권한이 없습니다.');
    }

    try {
      const request = {
        pathParams: {
          orderNo: this.$route.query?.orderNo?.toString() ?? '',
          memoNo: memoNo.toString(),
        },
      };
      await this.$api.deletePreviousOrdersOrderNoAdminMemosMemoNo(request);
      this.adminMemosSync = this.adminMemosSync.filter(({ no }) => no !== memoNo);
      alert('메모 삭제가 완료되었습니다.');
    } catch (e) {
      console.error(e);
    }
  }

  private async handleSaveClick() {
    const message = this.invalidMemoInput();

    if (message) {
      alert(message);
      return;
    }

    try {
      const request = {
        pathParams: {
          orderNo: this.$route.query?.orderNo?.toString() ?? '',
        },
        data: {
          memo: sanitizeHtml(this.memoInput),
        },
      };
      const { data } = await this.$api.putPreviousOrdersOrderNoAdminMemos(request);
      this.adminMemosSync = [...this.adminMemosSync, data];
      alert('메모가 저장되었습니다.');
      this.memoInput = '';
    } catch (e) {
      console.error(e);
    }
  }

  private invalidMemoInput() {
    if (!this.memoInput) {
      return '메모를 입력해주세요.';
    }
    const MAX_MEMO_COUNT = 100;
    if (this.adminMemosSync.length >= MAX_MEMO_COUNT) {
      return '관리자 메모는 최대 100개까지만 등록이 가능합니다.';
    }
    return false;
  }
}
