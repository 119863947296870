



import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import 'summernote/dist/summernote-lite.js';
import 'summernote/dist/lang/summernote-ja-JP.min.js';
import 'summernote/dist/lang/summernote-zh-CN.min.js';
import 'summernote/dist/lang/summernote-ko-KR.min.js';

import $ from 'jquery';

import { isHTML, uploadFile } from '@/utils/common';
import { getDefaultOptions } from '@/components/common/summernote/Summernote';
import { CallbackOption, SummernoteOption, JQuery, StyleType, TextType } from '@/types/summernote/summernote';
import { STYLE_TYPE, TEXT_TYPE } from '@/const/summernote';
import { getCurrentMallNo } from '@/utils/mall';
import { PostImagesResponse } from 'ncp-api-supporter';
import { InputNumber } from '@/types';

@Component
export default class Summernote extends Vue {
  @Prop({ required: false })
  private readonly option: SummernoteOption;

  @Prop({ required: false, default: '' })
  private mallNo: InputNumber;

  @Prop({ required: false, default: '' })
  private data: string;

  @Prop({ required: false, default: 'SummernoteLite' + Math.floor(Math.random() * 100) + 1 })
  private readonly editorId: string;

  @Prop({ required: false, default: false })
  private readonly disable: boolean;

  @Prop({ required: false, default: 'COMMON_FILE_SIZE_ERR_IMG' })
  private readonly checkSizeMsg?: string;

  @Prop({ required: false, default: 'COMMON_FILE_YTPE_ERR_IMAGE' })
  private readonly checkTypeMsg?: string;

  private $summernote: JQuery;
  private _mallNo = 0;

  public initializeOptions() {
    this.setOptions();
  }

  public onStyleEvent(type: StyleType) {
    this.$summernote.summernote(type);
  }

  public onSetTextEvent(type: TextType, value: string) {
    this.$summernote.summernote(type === TEXT_TYPE.TEXT ? 'insertText' : type, value);
  }

  public getHtml(): string {
    const html = this.$summernote.summernote(TEXT_TYPE.CODE);
    const value = this.getMsWordValidatedValue(html);
    return value === '<p><br></p>' ? '' : value;
  }

  public getText(): string {
    const newLineAndTabPattern = /[\t|\n]/g;

    return (this.$refs.summernote as HTMLElement).parentElement
      .querySelector('.note-editable')
      .textContent.replace(newLineAndTabPattern, '');
  }

  public reset() {
    this.$summernote.summernote('reset');
  }

  private insertNode(node: JQuery.Node) {
    this.$summernote.summernote('insertNode', node);
  }

  private customOption = {} as SummernoteOption;
  private callbackOption: { callbacks: CallbackOption } = {
    callbacks: {
      onImageUpload: this.uploadFile,
      onChange: (contents: string) => this.onEvent('onChange', contents),
      onFocus: () => this.$parent.$emit(STYLE_TYPE.FOCUS),
    },
  };

  private run(option: SummernoteOption) {
    this.$summernote.summernote(option);
    this.disable && this.onStyleEvent(STYLE_TYPE.DISABLE);
  }

  private setOptions() {
    this.customOption = Object.assign({}, this.customOption, getDefaultOptions());

    if (this.option) {
      this.customOption = Object.assign({}, this.customOption, this.option);
    }

    this.customOption = Object.assign({}, this.customOption, this.callbackOption);

    this.run(this.customOption);
  }

  private uploadFile(files: FileList): void {
    let size;
    const someFileOfSize = Object.values(files).find((file: File): boolean => {
      size = file.size / 1024;
      return Math.ceil(size) > this.customOption.imageFileSize;
    });
    if (someFileOfSize) {
      let msg = '';
      if (this.checkSizeMsg === 'COMMON_FILE_SIZE_ERR_IMG') {
        msg = this.$t(this.checkSizeMsg, [this.customOption.imageFileSize / 1024 + 'M']).toString();
      } else {
        msg = this.$t(this.checkSizeMsg, [this.customOption.imageFileSize, size]).toString();
      }
      alert(msg);
      return;
    }

    const someFileOfname = Object.values(files).find((file: File): boolean => {
      return !file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp)$/);
    });
    if (someFileOfname) {
      alert(this.$t(this.checkTypeMsg));
      return;
    }

    uploadFile(files, this._mallNo).then((responses: { data: PostImagesResponse }[]) => {
      const data: PostImagesResponse[] = responses.map(({ data }) => ({ url: data.url }));

      data.forEach((imgInfo: PostImagesResponse): void => {
        const $img = $('<img>').attr('src', imgInfo.url);
        this.insertNode($img[0]);
      });
    });
  }

  private emitToParentComponent(event, data) {
    this.$parent.$emit(event, data);
  }

  private onEvent(event: string, value = '') {
    const validatedValue = this.getMsWordValidatedValue(value);

    if (event === 'onChange') {
      const inputObj = { html: validatedValue === '<p><br></p>' ? '' : validatedValue, text: validatedValue };
      if (this.editorId) {
        Object.assign(inputObj, { editorId: this.editorId });
      }
      this.emitToParentComponent('input', inputObj);
      return;
    }
    this.$emit(event, validatedValue);
  }

  private getMsWordValidatedValue(value: string) {
    const msWordOpTagRegex = /<(\/o:p|o:p)([^>]*)>/g;
    //NOTE - ms word에서 복붙시 o:p태그가 함께 오는 것을 막음
    const deleteOpTagsValue = value.replace(msWordOpTagRegex, '');
    //NOTE - ms word에서 복붙시 border inline style 속성이 0으로 오는 현상이 있어 1로 강제 변환함
    return deleteOpTagsValue.replaceAll('border="0"', 'border="1"');
  }

  @Watch('data', { immediate: false, deep: true })
  private onDataChanged(data: string) {
    this.setData(data);
  }

  private setData(data: string) {
    this.reset();

    if (!data) return;
    const type = isHTML(data) ? TEXT_TYPE.CODE : TEXT_TYPE.TEXT;
    this.onSetTextEvent(type, data);
  }

  @Watch('disable')
  private onDisableChanged(disable: boolean) {
    const type = disable ? STYLE_TYPE.DISABLE : STYLE_TYPE.ENABLE;
    this.onStyleEvent(type);
  }

  private get hasMallNo() {
    return this.mallNo > 0;
  }
  private mounted() {
    if (!this.hasMallNo) {
      this._mallNo = getCurrentMallNo(this);
    } else {
      this._mallNo = Number(this.mallNo);
    }
    this.$summernote = ($(this.$refs.summernote) as any) as JQuery;
    this.initializeOptions();
    this.$parent.$emit('editorInitedComplete');
  }

  @Watch('mallNo')
  private watchMallNo() {
    if (!this.hasMallNo) {
      this._mallNo = getCurrentMallNo(this);
    } else {
      this._mallNo = Number(this.mallNo);
    }
  }

  private beforeDestroy() {
    this.$summernote.summernote('destroy');
  }
}
