



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import { getDownToday } from '@/utils/dateFormat';
import { GroupOption } from '@/helpers/type';
import { i18nAllObject } from '@/utils/i18n';
import { readExcelFile, writeExcelFile } from '@/utils/webExcel';
import { ParsedObject } from '@/utils/fileReader';
import { DeliveryCompany, PutShippingsAssignInvoicesRequest } from 'ncp-api-supporter';
import { Invoice } from 'ncp-api-supporter/dist/types/modules/order/backendShipping';
import { createExcel } from '@/components/common/grid/ExcelCreateUtil';

@Component({ components: { SelectBox, RadioGroup, FileInput } })
export default class ContentInvoice extends Vue {
  @Prop({ required: true }) public deliveryCompanyTypeArr: DeliveryCompany[];
  @Prop({ required: true }) private data;
  @Prop({ required: true }) public createExcel;

  public deliveryCompanyType = '';
  public releaseYnRadioValue = 'N';
  private uploadedExcelFileName = '';
  private excelData = [];
  private saveComplete = false;
  private saveCompleteData = [];
  private extensionValidOption = {
    validType: 'excel',
    alertMsg: 'ALERT_DISALLOW_EXTENSION',
  };
  private uploadedExcelFile: File | null = null;
  private keyMapJson = {
    shippingNo: 'ORDER.LIST_GRIDS.HEADER_DELIVER_NO',
    receiverName: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_NAME',
    receiverContact1: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_CONTACT1',
    receiverZipCd: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_ZIP_CD',
    address: 'ORDER.LIST_GRIDS.HEADER_ADDRESS',
    customsIdNumber: 'ORDER.LIST_GRIDS.HEADER_CUSTOMS_ID_NUMBER',
    deliveryTypeLabel: 'ORDER.LIST_GRIDS.HEADER_DELIVER_TYPE',
    invoiceNo: 'ORDER.LIST_GRIDS.HEADER_INVOICE_NO',
    orderYmdt: 'ORDER.LIST_GRIDS.HEADER_ORDER_YMDT',
    orderNo: 'ORDER.LIST_GRIDS.HEADER_ORDER_NO',
    ordererName: 'ORDER.LIST_GRIDS.HEADER_ORDER_NAME',
    productName: 'ORDER.LIST_GRIDS.HEADER_PRODUCT_NAME',
  };

  public resultExcel = {
    columns: {
      shippingNo: 'ORDER.LIST_GRIDS.RESULT_SHIPPING_NO',
      receiverName: 'ORDER.LIST_GRIDS.RESULT_RECEIVER_NAME',
      receiverContact1: 'ORDER.LIST_GRIDS.RESULT_RECEIVER_CONTACT1',
      receiverZipCd: 'ORDER.LIST_GRIDS.RESULT_RECEIVER_ZIP_CD',
      address: 'ORDER.LIST_GRIDS.RESULT_ADDRESS',
      customsIdNumber: 'ORDER.LIST_GRIDS.RESULT_CUSTOMS_ID_NUMBER',
      deliveryTypeLabel: 'ORDER.LIST_GRIDS.RESULT_DEVELIVERY_TYPE_LABEL',
      invoiceNo: 'ORDER.LIST_GRIDS.RESULT_INVOICE_NO',
      orderYmdt: 'ORDER.LIST_GRIDS.RESULT_ORDER_YMDT',
      orderNo: 'ORDER.LIST_GRIDS.RESULT_ORDER_NO',
      ordererName: 'ORDER.LIST_GRIDS.RESULT_ORDER_NAME',
      productName: 'ORDER.LIST_GRIDS.RESULT_PRODUCT_NAME',
      successYn: 'ORDER.LIST_GRIDS.RESULT_SUCCESS_YN',
      failReason: 'ORDER.LIST_GRIDS.RESULT_FAIL_REASON',
    },
    formatters: {
      successYn: data => {
        if (data.value === 'Y') {
          return `${window.$t('ORDER.LIST_GRIDS.RESULT_SUCCESS_Y')}`;
        } else {
          return `${window.$t('ORDER.LIST_GRIDS.RESULT_SUCCESS_N')}`;
        }
      },
    },
    fileName: 'result',
  };

  private releaseYnRadio: GroupOption<string> = {
    name: 'releaseYnRadio',
    data: [
      { id: 'releaseN', value: 'N', display: 'ORDER.LIST_GRIDS.RELEASE_N' },
      { id: 'releaseY', value: 'Y', display: 'ORDER.LIST_GRIDS.RELEASE_Y' },
    ],
  };

  private async onClickSave(excelData: ParsedObject[]) {
    if (!this.uploadedExcelFileName) return alert(this.$t('COMMON.EXCEL.NO_EXCEL_ERROR'));
    if (!this.deliveryCompanyType) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.EXCEL_DELIVERY_COMPANY_TYPE_EMPTY_ALERT'));
      return;
    }
    if (confirm(this.$t('ORDER.DELIVERY_PREPARE.CONFIRM_UPLOAD_EXCEL') as string)) {
      this.excelData = excelData
        .filter((row, i) => i >= 0)
        .map(row => {
          return {
            shippingNo: row.shippingNo,
            receiverName: row.receiverName,
            receiverContact1: row.receiverContact1,
            receiverZipCd: row.receiverZipCd,
            address: row.address,
            customsIdNumber: row.customsIdNumber,
            deliveryTypeLabel: row.deliveryTypeLabel,
            invoiceNo: row.invoiceNo,
            orderYmdt: row.orderYmdt,
            orderNo: row.orderNo,
            ordererName: row.ordererName,
            productName: row.productName,
            successYn: row.successYn,
          };
        });
      const theOption = this.excelData.map(item => {
        return {
          shippingNo: item.shippingNo,
          deliveryCompanyType: this.deliveryCompanyType,
          invoiceNo: item.invoiceNo ? item.invoiceNo : '',
        };
      });

      const request: PutShippingsAssignInvoicesRequest = {
        data: {
          requestChannelType: 'SERVICE',
          invoices: theOption as Invoice[],
          releaseYn: this.releaseYnRadioValue,
        },
      };

      const { data } = await this.$api.putShippingsAssignInvoices(request);
      this.saveCompleteData = data;
      this.saveComplete = true;
    }
  }

  private async convertExcelData(excelData: ParsedObject[]): Promise<void> {
    const isFailureRow =
      excelData.length <= 0 ||
      excelData.filter((row, index) => index > 0 && row.shippingNo === undefined).length > 0 ||
      !this.saveComplete;
    if (isFailureRow) {
      return alert(this.$t('COMMON.EXCEL.NO_DATA'));
    }

    const columns = i18nAllObject(this, this.resultExcel.columns);

    this.excelData = this.excelData.map((row, index) => ({
      ...row,
      failReason: this.saveCompleteData[index].failReason,
      invoiceNo: this.saveCompleteData[index].invoiceNo,
      successYn: this.saveCompleteData[index].successYn,
      deliveryCompanyType: this.saveCompleteData[index].deliveryCompanyType,
    }));

    writeExcelFile(
      this.excelData,
      columns,
      this.resultExcel.fileName + '_' + getDownToday(),
      true,
      this.resultExcel.formatters,
    );

    this.$emit('fetchOrder');
  }

  private uploadExcelFile(file: File): void {
    this.uploadedExcelFileName = file.name;
    this.uploadedExcelFile = file;
  }
  private removeFile(): void {
    this.uploadedExcelFile = null;
    this.uploadedExcelFileName = '';
  }
  private handleInvoiceExcel(): void {
    if (this.data.length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.NO_EXCEL_DOWN_ALERT') as string);
      return;
    }
    this.createExcel.query.size = '10000';
    this.createExcel.query.deliveryType = 'PARCEL_DELIVERY';
    let cancelCombine = false;
    this.data.forEach(item => {
      if (item.taskMessage === true) {
        cancelCombine = true;
      }
    });
    if (cancelCombine && !confirm(this.$t('ORDER.LIST_GRIDS.HAS_TASK_MESSAGE_CONFIRM') as string)) {
      return;
    }
    createExcel(this.data, 'PRO_DELIVERY_PREPARE_INVOICE', this.createExcel.query, null);
  }
  private async handleExcelUpload() {
    const columns = i18nAllObject(this, this.keyMapJson);
    await readExcelFile(this.uploadedExcelFile, columns, await this.onClickSave);
  }
  private async handleExcelDown() {
    const columns = i18nAllObject(this, this.keyMapJson);
    await readExcelFile(this.uploadedExcelFile, columns, await this.convertExcelData);
  }
}
