import { OptColumn } from '@/types';
import { Row } from '@/types/tui-grid';
import { hyphenRenderer, selectRenderer } from '@/utils/grid/rendererUtils';
import { CustomCellRendererProps } from '@/views/contents/product/basic/GridProps';
import { i18n } from '@/main';
import { NCPResponse, PutProductsFrontdisplayRequest, PutProductsFrontdisplay, YorN } from 'ncp-api-supporter';
import { api } from '@/api';
import { RendererEventCallback } from '@/views/contents/product/basic/ProductList.vue';
import { errorCaseCounter, getErrorMessages } from '@/utils/i18nMessage';
import { PutProductsFrontDisplayErrorCode } from 'ncp-api-supporter/dist/types/modules/product/product';
import { BEFORE_APPROVAL_STATUS } from '@/const/contents/product';
import { ApplyStatusType } from '@/components/product/basic/SearchOptionTypes';

export const getUpdateFrontDisplayColumn = (rendererEventCallback: RendererEventCallback): OptColumn => {
  return {
    header: 'PRODUCT.STATUS.FRONT_DISPLAY.STATUS',
    name: 'frontDisplayable',
    align: 'center',
    minWidth: 160,
    renderer: (props: CustomCellRendererProps) => {
      props.value = props.value ? 'Y' : 'N';
      props.className = 'front-display';
      props.selectOptions = [
        { text: i18n.t('PRODUCT.STATUS.FRONT_DISPLAY.Y'), value: 'Y' },
        { text: i18n.t('PRODUCT.STATUS.FRONT_DISPLAY.N'), value: 'N' },
      ];

      const renderer = selectRenderer(props);
      const el: HTMLSelectElement | HTMLSpanElement = renderer.getElement();
      const row: Row = props.grid.getRow(props.rowKey);

      if (BEFORE_APPROVAL_STATUS.includes(row.applyStatusType as ApplyStatusType)) {
        return hyphenRenderer();
      }

      if (row.saleSettingStatusType === 'PROHIBITION_SALE') {
        el.setAttribute('disabled', 'true');
      }

      const changeApproveStatusType = ['FINISHED', 'AFTER_APPROVAL_READY', 'AFTER_APPROVAL_REJECTION'];
      const isNotApprove = !changeApproveStatusType.some(v => v === row.applyStatusType);
      const isProhibitionSale = props.value === 'PROHIBITION_SALE';
      if (isNotApprove || isProhibitionSale) {
        el.setAttribute('disabled', 'true');
      }

      props.callback = (event: Event) => {
        rendererEventCallback('change:front-display', event, row);
      };

      return renderer;
    },
  };
};

export const updateFrontDisplay = async (
  frontDisplayYn: YorN,
  mallProductNos: number | number[],
): Promise<YorN | null> => {
  const request: PutProductsFrontdisplayRequest = {
    data: {
      productNos: Array.isArray(mallProductNos) ? mallProductNos.map(v => v.toString()) : [mallProductNos.toString()],
      frontDisplayYn,
    },
  };
  try {
    const { data }: NCPResponse<PutProductsFrontdisplay> = await api.putProductsFrontdisplay(request);
    const isSucceed = !data.failures.length;

    const successCount = data.successNos.length;
    const successMethodMessage = i18n.t('PRODUCT.EDIT.ALERT_SOME_CHANGE_COMPLETE', {
      name: i18n.t('PRODUCT.EDIT.' + frontDisplayYn).toString(),
    });

    if (isSucceed) {
      const successCountMessage = i18n.t('SUCCESS_N', { count: successCount }).toString();
      const successMessage = `${successCountMessage} : ${successMethodMessage}`;
      alert(successMessage);
      return frontDisplayYn;
    } else {
      const failures = data.failures;
      const duplicateCount = errorCaseCounter<PutProductsFrontDisplayErrorCode>('NOT_CHANGED', failures) + successCount;
      const successCountMessage = i18n.t('SUCCESS_N', { count: duplicateCount }).toString();
      const successMessage = `${successCountMessage} : ${successMethodMessage}`;
      const failMessages = getErrorMessages<PutProductsFrontDisplayErrorCode>(
        ['NOT_FOUND', 'SALE_PROHIBITED', 'NOT_APPLIED', 'NON_EDITABLE_PRODUCT'],
        failures,
        'PRODUCT.EDIT',
      );
      if (!failMessages?.length) {
        alert(successMessage);
        return null;
      }
      const message = isSucceed ? successMessage + '\n' + failMessages.join('\n') : failMessages.join('\n');

      alert(message);
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};

const updatedReflectInDOM = (changedStatus: YorN | null, el: HTMLSelectElement, row: Row): void => {
  if (!changedStatus) el.value = row.frontDisplayable ? 'Y' : 'N'; // 실패시 기존값으로 되돌린다.
};

export const changeFrontDisplay = (event: Event, row: Row) => {
  const target = event.target as HTMLSelectElement;
  const frontDisplayYn = target.value as YorN;

  updateFrontDisplay(frontDisplayYn, row.mallProductNo as number).then(changedStatus => {
    updatedReflectInDOM(changedStatus, target, row);
  });
};
