












































import { Component, Mixins, Prop } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { ReviewDisplayStatusTypes } from '@/const/contents/review';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { ReviewDisplayStatusType, YorN } from 'ncp-api-supporter';
import PutReviewsPropertyMixin from '@/views/contents/board/basic/mixins/PutReviewsPropertyMixin';
import { i18n } from '@/main';
/**
 * 레이어 팝업
 * 상품후기 전시상태 변경
 * */
@Component({
  components: {
    TextInput,
    RadioGroup,
  },
})
export default class UpdateReviewsPropertyPopup extends Mixins(PutReviewsPropertyMixin) {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: () => void;
  @Prop() private readonly onNegativeClick!: () => void;
  private displayStatusType: ReviewDisplayStatusType = 'DISPLAY';
  private bestReviewYn: YorN = 'N';
  async onSaveClick() {
    let response = null;
    if (this.data.property === 'displayStatusType') {
      response = await this.putReviewsDisplayStatusType(this.data.reviews, this.displayStatusType);
    } else {
      response = await this.putReviewsBestReview(this.data.reviews, this.bestReviewYn);
    }
    if (response.status === 200) {
      let alertMsg = '';
      const { successReviewNos, failReviewResults } = response.data;
      const alreadyUpdatedFailReviewResults = failReviewResults
        .filter(result => result.failCode === 'ALREADY_UPDATED')
        .map(result => {
          result.reasonText =
            this.data.property === 'displayStatusType'
              ? this.failCode.ALREADY_UPDATED[0]
              : this.failCode.ALREADY_UPDATED[1];
          return result;
        });
      // 동일한 상태로 변경실패한건은 변경성공건으로 처리
      if (alreadyUpdatedFailReviewResults.length || successReviewNos.length) {
        alertMsg =
          this.data.property === 'displayStatusType'
            ? (this.$t('BOARD.REVIEW.CHANGE_REVIEW_DISPLAY_TYPE_SUCCESS_MSG', [
                alreadyUpdatedFailReviewResults.length + successReviewNos.length,
              ]) as string)
            : (this.$t('BOARD.REVIEW.CHANGE_REVIEW_TYPE_SUCCESS_MSG', [
                alreadyUpdatedFailReviewResults.length + successReviewNos.length,
              ]) as string);
      }
      const alreadyDeletedFailReviewResults = failReviewResults
        .filter(result => result.failCode === 'ALREADY_DELETED')
        .map(result => {
          result.reasonText = this.failCode.ALREADY_DELETED[0];
          return result;
        });
      const notExistFailReviewResults = failReviewResults
        .filter(result => result.failCode === 'NOT_EXIST')
        .map(result => {
          result.reasonText = this.failCode.NOT_EXIST[0];
          return result;
        });
      // if (alreadyUpdatedFailReviewResults.length > 0) {
      //   alertMsg += this.$t('BOARD.REVIEW.CHANGE_REVIEW_PROPERTY_FAILD_MSG', [
      //     alreadyUpdatedFailReviewResults.length,
      //     alreadyUpdatedFailReviewResults[0].reasonText,
      //   ]);
      // }
      if (alreadyDeletedFailReviewResults.length > 0) {
        alertMsg += this.$t('BOARD.REVIEW.CHANGE_REVIEW_PROPERTY_FAILD_MSG', [
          alreadyDeletedFailReviewResults.length,
          alreadyDeletedFailReviewResults[0].reasonText,
        ]);
      }
      if (notExistFailReviewResults.length > 0) {
        alertMsg += this.$t('BOARD.REVIEW.CHANGE_REVIEW_PROPERTY_FAILD_MSG', [
          notExistFailReviewResults.length,
          notExistFailReviewResults[0].reasonText,
        ]);
      }
      alert(alertMsg);
      this.onPositiveClick();
    }
  }
  private displayOption = {
    name: 'displayOption',
    data: ReviewDisplayStatusTypes()
      .filter(displableType => displableType.value !== 'ALL' && displableType.value !== 'DELETE')
      .map(displableType => {
        return { id: displableType.value, value: displableType.value, display: displableType.name };
      }),
  };
  private bestReviewOption = {
    name: 'bestReviewOption',
    data: [
      { id: 'N', value: 'N', display: i18n.t('BOARD.REVIEW.NORMAL_TYPE') },
      { id: 'Y', value: 'Y', display: i18n.t('BOARD.REVIEW.BEST_TYPE') },
    ],
  };
  private failCode = {
    ALREADY_UPDATED: [
      i18n.t('BOARD.REVIEW.CHANGE_DISPLAY_TYPE_ALREADY_UPDATED_MSG'),
      i18n.t('BOARD.REVIEW.CHANGE_TYPE_ALREADY_UPDATED_MSG'),
    ],
    ALREADY_DELETED: [i18n.t('BOARD.REVIEW.CHANGE_DELETED_MSG')],
    NOT_EXIST: [i18n.t('BOARD.REVIEW.CHANGE_DELETED_MSG')],
  };
}
