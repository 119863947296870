

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { drawRemainingDurationTime } from '@/utils/dateFormat';
import { NCPResponse } from 'ncp-api-supporter';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { GetCampaignsSmsAuthRequest, PostCampaignsSmsAuthRequest } from 'ncp-api-supporter/dist/types';
import { PostCampaignsSmsAuth } from 'ncp-api-supporter/dist/types/modules/marketing/campaign';
import { isValidate } from '@/utils/validate';
@Component({
  components: { TextInput, SelectBox, NoticeBox },
})
export default class SmsAuthentication extends Vue {
  @Prop({ required: true })
  private data: { mallNo: number; expireTime?: number };
  @Prop({ required: true }) private onPositiveClick!: () => void;
  private readonly noticeList = [
    this.$t('MARKETING.GOOGLE_AD.AUTH_NOTICE1'),
    this.$t('MARKETING.GOOGLE_AD.AUTH_NOTICE2'),
  ];

  private phoneNumber: string | null = null;
  private authCode = '';
  private authCodeExpireTime = 900; //15분
  private currentRemainingTime = 0;
  private authTimer: ReturnType<typeof setInterval>;
  private isFirstTry = true;
  private verificationId: string | null = null;

  created() {
    if (this.data.expireTime) this.authCodeExpireTime = this.data.expireTime;
  }

  beforeDestroy() {
    this.clearAuthTimer();
  }

  private clearAuthTimer() {
    if (!this.authTimer) return;
    clearInterval(this.authTimer);
    this.authTimer = null;
  }

  @Watch('currentRemainingTime')
  private clearAuthTimerWhenTimeOut() {
    if (this.currentRemainingTime) return;
    this.clearAuthTimer();
    alert(this.$t('MARKETING.GOOGLE_AD.AUTH_TIME_OUT'));
  }

  private runAuthTimer() {
    alert(this.$t('MARKETING.GOOGLE_AD.AUTH_CODE'));

    this.clearAuthTimer();
    this.authTimer = setInterval(() => {
      this.currentRemainingTime--;
    }, 1000);
  }

  private resetCurrentRemainingTime() {
    this.currentRemainingTime = this.authCodeExpireTime;
  }

  private async handleAuthCodeRequestsClick() {
    if (!isValidate(this.phoneNumber, this.$t('MARKETING.GOOGLE_AD.INPUT_PHONE_NUM'))) return;
    this.resetCurrentRemainingTime();
    const request: PostCampaignsSmsAuthRequest = {
      data: {
        phoneNumber: this.phoneNumber,
        mallNo: this.data.mallNo,
      },
    };
    try {
      const { data }: NCPResponse<PostCampaignsSmsAuth> = await this.$api.postCampaignsSmsAuth(request);
      this.isFirstTry = false;
      this.verificationId = data.verificationId;
      this.runAuthTimer();
    } catch (e) {
      console.error(e);
    }
  }

  private get displayedExpireTime(): string {
    return !this.currentRemainingTime
      ? '- 분 - 초'
      : drawRemainingDurationTime(this.currentRemainingTime * 1000, 'mm분 ss초');
  }

  private async getCampaignsSmsAuth() {
    const request: GetCampaignsSmsAuthRequest = {
      params: {
        mallNo: this.data.mallNo,
        phoneNumber: this.phoneNumber,
        verificationId: this.verificationId,
        verificationCode: this.authCode,
      },
    };
    try {
      await this.$api.getCampaignsSmsAuth(request);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private async handleConfirmClick() {
    if (!isValidate(this.authCode, this.$t('MARKETING.GOOGLE_AD.INPUT_CODE'))) return;

    try {
      await this.getCampaignsSmsAuth();
      await this.onPositiveClick();
    } catch (e) {
      alert(this.$t('MARKETING.GOOGLE_AD.FAIL_AUTH'));
    }
  }
}
