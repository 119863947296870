import { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import DefaultLayout from '@/views/DefaultLayout.vue';

export default (): RouteConfig => {
  return {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
    ],
  };
};
