






















import { Vue, Component } from 'vue-property-decorator';
import PageTitle from '@/components/PageTitle.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import HeaderNav from '@/components/navigation/Header.vue';
import Footer from '@/components/navigation/Footer.vue';
import PopupList from '@/components/popup/Container.vue';
import BottomNav from '@/components/BottomNav.vue';
import TopContentContainer from './top/Container.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import FloatingButton from '@/components/root/parts/FloatingButton.vue';
import YoutubeButton from '@/components/root/parts/YoutubeButton.vue';
import { ToolTipOption } from '@/types/tooltip';
import { isEmpty } from 'underscore';

@Component({
  components: {
    ToolTip,
    TopContentContainer,
    BottomNav,
    LoadingSpinner,
    HeaderNav,
    Footer,
    PopupList,
    PageTitle,
    FloatingButton,
    YoutubeButton,
  },
})
export default class DefaultLayoutForPremium extends Vue {
  private exceptList = ['ProductRegistration'];
  private tooltipOption: ToolTipOption = {};

  get isExistTooltip() {
    return !isEmpty(this.tooltipOption);
  }
  get isExceptsRoute() {
    return this.exceptList.indexOf(this.$route.name || '') > -1;
  }
  get homeClass() {
    if (this.$route.name === 'Home') {
      return 'type02';
    }
    if (this.$route.name === 'AdditionMain') {
      return 'width1320';
    }
    return '';
  }
}
