
























































































import { Component, PropSync, Prop, Ref, Mixins } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  ErrorCode,
  ErrorType,
  SalesInfoCalculator,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { InputNumber, ErrorCodeType, SalePriceFocusType, SalesInfoCalculatorErrorInfo } from '@/types';
import { formatCurrency, getOnlyNumbers } from '@/utils/numberFormat';
import NumberWithCommaFormatterMixin from '@/views/contents/product/basic/ProductAdd/salesInfo/NumberWithCommaFormatterMixin.vue';

@Component({
  components: { TextInput },
})
export default class SalePriceInfo extends Mixins(NumberWithCommaFormatterMixin) {
  @PropSync('useContentsIfPausing', { required: true })
  private useContentsIfPausingSync: boolean;

  @PropSync('salePrice')
  private salePriceSync!: number;

  @PropSync('contentsIfPausing', { required: true })
  private contentsIfPausingSync: string;

  @PropSync('supplyPrice')
  private supplyPriceSync!: InputNumber;

  @PropSync('maxImmediateDiscountAmount')
  private maxImmediateDiscountAmountSync!: InputNumber;

  @PropSync('immediateDiscountPrice')
  private immediateDiscountPriceSync!: InputNumber;

  @Prop({ required: true })
  private readonly isMapping: boolean;

  @Prop()
  private readonly calculator!: SalesInfoCalculator;

  @Ref()
  private readonly priceRef!: TextInput;

  @Ref()
  private readonly contentsIfPausingRef!: TextInput;

  @Prop()
  private readonly formError;

  @Prop()
  private readonly formErrorType;

  @Prop()
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly disabled;

  public alertSalePrice(): void {
    alert(this.$t('PRODUCT.ADD.PLZ_INSERT_SALE_PRICE_FOR_OPTION').toString());
    this.focus('price');
    this.$emit('alertSalePrice');
  }

  public focus(type: SalePriceFocusType): void {
    this[`${type}Ref`].focus();
  }

  public changedSalePrice(inputPriceValue: string): string {
    const price = getOnlyNumbers(inputPriceValue);
    try {
      this.resetError();
      this.setSalePrice(price);
      this.setSupplyAndDiscountPrice();
      return formatCurrency(price);
    } catch (e) {
      this.errorHandler(e.errorInfo);
      this.resetSalePriceByError();
      return '';
    }
  }

  private setSalePrice(price: number): void {
    this.calculator.changeSalePrice(price);
    this.salePriceSync = price;
  }

  private setSupplyAndDiscountPrice(): void {
    this.supplyPriceSync = Math.max(0, this.calculator.supplyPriceResult);
    this.immediateDiscountPriceSync = Math.max(0, this.calculator.discountPriceResult);
    this.maxImmediateDiscountAmountSync = this.calculator.maxDiscountAmount;
  }

  private ErrorCode = ErrorCode;
  private errorCode: ErrorCodeType | '' = '';
  private errorHandler(error: SalesInfoCalculatorErrorInfo): void {
    if (error.type === ErrorType.ALERT) {
      alert(this.$t(error.msg));
      return;
    }
    this.errorCode = error.code as ErrorCodeType;
  }

  private resetError(): void {
    this.errorCode = '';
  }

  private onChange({ target }: { target: HTMLInputElement }) {
    if (!target.checked) {
      this.contentsIfPausingSync = null;
    }
  }

  private resetSalePriceByError(): void {
    this.salePriceSync = 0;
    this.setSupplyAndDiscountPrice();
  }
}
