import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import { GridProp } from '@/types';
import { ProductManageSummaryTab } from '@/types/marketing';
import { CampaignsProductStatusesIssue } from 'ncp-api-supporter';
import { CellValue, Dictionary } from '@/types/tui-grid';
import { issueTooltipMaker } from '@/utils/grid/rendererUtils';

const replaceString = (str: string, origin: string, want: string): string => str.replace(origin, want);

export const gridProp = (): GridProp => ({
  header: {
    columns: [
      {
        name: 'expirationDateTime',
        renderer: props => {
          props.tooltipId = 'expirationDateTime';
          return headerWithTooptipRenderer(props);
        },
      },
    ],
  },
  columns: [
    {
      header: 'MARKETING.CONFIGURATION.MALL_PRODUCT_NO',
      name: 'productNo',
      align: 'center',
      width: 100,
      formatter: ({ row }) => {
        return getAnchorHTML(row['productNo'].toString());
      },
    },
    {
      header: 'MARKETING.CONFIGURATION.PRODUCT_NAME',
      name: 'productName',
      width: 100,
      align: 'center',
    },
    {
      header: 'MARKETING.CONFIGURATION.PRODUCT_STATE',
      name: 'status',
      align: 'center',
      width: 80,
      formatter: ({ row }) => {
        if (row.status === 'APPROVED') return i18n.t('MARKETING.CONFIGURATION.APPROVED').toString();
        if (row.status === 'PENDING') return i18n.t('MARKETING.CONFIGURATION.PENDING').toString();
        if (row.status === 'DISAPPROVED') return i18n.t('MARKETING.CONFIGURATION.DISAPPROVED').toString();
      },
    },
    {
      header: 'MARKETING.CONFIGURATION.ISSUES',
      name: 'issues',
      renderer: issueTooltipMaker,
      formatter: ({ value }) => {
        const { servability, description } = value as CampaignsProductStatusesIssue;
        if (servability === 'disapproved') return `<b class="alert-icon red-icon"></b>${description}`;
        if (servability === 'unaffected') return `<b class="alert-icon yellow-icon"></b>${description}`;
        if (description === undefined) return `<div class="center">-</div>`;
      },
    },
    {
      header: 'MARKETING.CONFIGURATION.RESOLUTION',
      name: 'resolution',
      width: 100,
      formatter: ({ row }) => {
        const { issues } = row as Dictionary<CellValue>;
        const { resolution } = issues as CampaignsProductStatusesIssue;
        if (resolution === 'merchant_action') return i18n.t('MARKETING.CONFIGURATION.IMPOSSIABLE').toString();
        if (resolution === 'pending_processing') return i18n.t('MARKETING.CONFIGURATION.POSSIABLE').toString();
        if (resolution === '') return `<div class="center">-</div>`;
      },
      align: 'center',
    },
    {
      header: 'MARKETING.CONFIGURATION.CREATION_DATA_TIME',
      name: 'creationDateTime',
      align: 'center',
      width: 200,
      formatter: ({ value }) => {
        if (value) {
          return replaceString(value.toString(), 'T', ' ');
        }

        return '-';
      },
    },

    {
      header: 'MARKETING.CONFIGURATION.LASTUPDATE_DATA_TIME',
      name: 'lastUpdateDateTime',
      align: 'center',
      width: 200,
      formatter: ({ value }) => {
        if (value) {
          return replaceString(value.toString(), 'T', ' ');
        }

        return '-';
      },
    },
    {
      header: 'MARKETING.CONFIGURATION.EXPIRATION_DATA_TIME',
      name: 'expirationDateTime',
      align: 'center',
      width: 200,
      formatter: ({ value }) => replaceString(value.toString(), 'T', ' '),
    },
  ],
  options: {
    rowHeaders: ['rowNum'],
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },
  displayOptions: {
    hasExcelDownloadButton: false,
    hasSettingButton: false,
    headerTooltipOption: [
      {
        id: '#expirationDateTime',
        popupData: {
          data: { message: i18n.t('MARKETING.CONFIGURATION.EXPIRATION_DATA_TIME_TOOLTIP'), leftAlign: true },
        },
      },
    ],
  },
});

export const checkboxData = {
  name: 'statusCheckType',
  data: [
    { id: 'approved', value: 'APPROVED', display: 'MARKETING.CONFIGURATION.APPROVED' },
    { id: 'pending', value: 'PENDING', display: 'MARKETING.CONFIGURATION.PENDING' },
    { id: 'disapproved', value: 'DISAPPROVED', display: 'MARKETING.CONFIGURATION.DISAPPROVED' },
  ],
  hasAll: false,
};

export const getSummaryTab = (summary): ProductManageSummaryTab[] => {
  return [
    {
      name: i18n.t('MARKETING.CONFIGURATION.APPROVED_COUNT').toString(),
      count: summary.approvedCount,
    },
    {
      name: i18n.t('MARKETING.CONFIGURATION.EXPIRING_COUNT').toString(),
      count: summary.expiringCount,
    },
    {
      name: i18n.t('MARKETING.CONFIGURATION.PENDING_COUNT').toString(),
      count: summary.pendingCount,
    },
    {
      name: i18n.t('MARKETING.CONFIGURATION.DISAPPROVED_COUNT').toString(),
      count: summary.disapprovedCount,
    },
  ];
};
