
























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
@Component({
  components: { SearchKeyword },
})
export default class AddKeywords extends Vue {
  @PropSync('keywords', { required: true })
  private keywordsSync: string[];

  @PropSync('keywordsModel', { required: true })
  private keywordsModelSync: string;

  @Prop({ required: false, default: false })
  private readonly disabled;

  private onInputSearchKeyword(value: string): void {
    if (!this.keywordsModelSync) return;
    this.keywordsSync = (value === undefined ? this.keywordsModelSync : value).split(',').filter(Boolean);
  }

  private created() {
    this.initializeKeywords();
  }

  private initializeKeywords() {
    if (this.keywordsSync.length > 0) {
      this.keywordsSync = this.keywordsSync.map(keyword => keyword.trim()).filter(Boolean);
    }
  }

  reset(): void {
    this.keywordsModelSync = '';
  }
}
