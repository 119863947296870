export default Object.freeze({
  GENERAL: '일반',
  PROMOTION: '프로모션 공지',
  WARNING: '위해정보',
  SAFETY_TRADE: '안전거래',
  SYSTEM: '시스템',

  ALL: '전체',
  TITLE: '제목',
  CONTENT: '내용',
  WRITER: '작성자',

  MALL_NAME: '쇼핑몰',
  NOTICE_NO: '게시글 번호',
  NOTICE_CATEGORY: '공지사항 카테고리',
  DISCLOSURE_YN: '공개여부',
  DISCLOSURE_Y: '공개',
  DISCLOSURE_N: '비공개',
  VIEWS: '조회수',
  CREATED_DATE_TIME: '작성일시',
  UPDATER: '최종수정자',
  MODIFIED_DATE_TIME: '최종수정일시',

  PLZ_NOTICE_TITLE: '제목을 입력해 주세요.',
  PLZ_NOTICE_CATEGORY: '공지사항 카테고리를 선택해 주세요.',
  PLZ_NOTICE_CONTENT: '내용을 입력해 주시기 바랍니다.',
});
