import {
  GetKakaoMessagesRequest,
  GetKakaoTemplatesAutoRequest,
  InspectionStatusType,
  KakaoTemplateAutoButtonLinkType,
  KakaoTemplateAutoSearchType,
  PostKakaoConfigPlusRequest,
  PostKakaoTemplatesAutoRequest,
  TemplateGroupType,
} from 'ncp-api-supporter';
import { addDay, getFirstAndEndDay, getStrYMDHMSS, getToday } from '@/utils/dateFormat';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { GridProp, InputValue } from '@/types';
import { DetailTemplateType, KakaoConfigBusinessType, LinkButtonType } from '@/types/member';
import { maskingAuthNo, regxPhoneNo } from '@/utils/common';
import { getAnchorHTML, getSpanHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';

// 알림톡 템플릿 관리 start
type emptyValueWithTemplateQuery = InputValue<GetKakaoTemplatesAutoRequest['params']>;
export const getInitKakaoTemplateQuery = (mallNo: number): emptyValueWithTemplateQuery => {
  return {
    mallNo,
    page: 1,
    pageSize: 30,
    searchType: 'ALL',
    keyword: '',
    startDate: addDay(new Date(), -7),
    endDate: getToday(),
    templateGroupType: '',
    inspectionStatus: '',
    apply: '',
  };
};

export const searchTypeOption: OptionData<KakaoTemplateAutoSearchType>[] = [
  { value: 'ALL', name: 'MEMBER.KAKAO.SEARCH_ALL' },
  { value: 'CODE', name: 'MEMBER.KAKAO.SEARCH_CODE' },
  { value: 'TITLE', name: 'MEMBER.KAKAO.SEARCH_TITLE' },
  { value: 'CONTENT', name: 'MEMBER.KAKAO.SEARCH_CONTENT' },
];

export const templateTypeOption: OptionData<TemplateGroupType>[] = [
  // { value: 'ALL', name: 'ALL' },
  { value: 'MEMBER', name: 'MEMBER.KAKAO.TEMPLATE_GROUP_MEMBER' },
  { value: 'ORDER_DELIVERY', name: 'MEMBER.KAKAO.TEMPLATE_GROUP_ORDER_DELIVERY' },
  { value: 'INQUIRY', name: 'MEMBER.KAKAO.TEMPLATE_GROUP_INQUIRY' },
  { value: 'ADMIN', name: 'MEMBER.KAKAO.TEMPLATE_GROUP_ADMIN' },
];

export const inspectionStatusTypeOption: OptionData<InspectionStatusType>[] = [
  // { value: 'ALL', name: 'ALL' },
  { value: 'REGISTER', name: 'MEMBER.KAKAO.STATUS_REGISTER' },
  { value: 'REQUEST', name: 'MEMBER.KAKAO.STATUS_REQUEST' },
  { value: 'APPROVE', name: 'MEMBER.KAKAO.STATUS_APPROVE' },
  { value: 'REJECT', name: 'MEMBER.KAKAO.STATUS_REJECT' },
];

export const applyTypeOption: OptionData<boolean>[] = [
  // { value: 'ALL', name: 'ALL' },
  { value: true, name: 'MEMBER.KAKAO.APPLY' },
  { value: false, name: 'MEMBER.KAKAO.NOT_APPLY' },
];

export const getNotificationTemplateGridOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'MEMBER.KAKAO.SEARCH_CODE',
        name: 'templateCode',
        align: 'center',
        width: 200,
        formatter: ({ value }) => getAnchorHTML(value.toString()),
      },
      // {
      //   header: 'MEMBER.SMS_HISTORY.TYPE',
      //   name: 'templateGroupTypeLabel',
      //   align: 'center',
      // },
      {
        header: 'MEMBER.KAKAO.TEMPLATE_TYPE',
        name: 'templateGroupType',
        align: 'center',
        formatter: ({ value }) => i18n.t(`MEMBER.KAKAO.TEMPLATE_GROUP_${value}`).toString(),
      },
      {
        header: 'MEMBER.KAKAO.SEARCH_TITLE',
        name: 'templateName',
        align: 'center',
        width: 250,
        formatter: ({ value }) => getAnchorHTML(value.toString()),
      },
      {
        header: 'MEMBER.KAKAO.SEARCH_CONTENT',
        name: 'templateContent',
        align: 'center',
        ellipsis: true,
        width: 200,
      },
      {
        header: 'MEMBER.KAKAO.TEMPLATE_STATUE',
        name: 'inspectionStatus',
        align: 'center',
        formatter: ({ row: { status }, value }) => {
          const hasStoppedTemplate = status === 'STOP' && value === 'APPROVE';
          const inspectionStatusLabel = i18n.t(`MEMBER.KAKAO.${value}`).toString();
          if (!hasStoppedTemplate) return inspectionStatusLabel;

          const notice = getSpanHTML(i18n.t(`MEMBER.KAKAO.${status}`).toString(), '', 'red');
          return inspectionStatusLabel + notice;
        },
      },
      {
        header: 'MEMBER.KAKAO.APPLICABLE_OR_NOT',
        name: 'apply',
        align: 'center',
        formatter: ({ value }) => i18n.t(`MEMBER.KAKAO.${value ? 'APPLIED' : 'NOT_APPLIED'}`).toString(),
      },
      {
        header: 'MEMBER.KAKAO.REGISTER_DATE',
        name: 'createdTime',
        align: 'center',
        width: 170,
      },
    ],
    header: {
      align: 'center',
    },
    options: {
      rowHeaders: ['rowNum'],
    },
  };
};
// 알림톡 템플릿 관리 end

// 알림톡 템플릿 등록 start
export enum ButtonLinkType {
  WL = 'WL',
  DS = 'DS',
}
export const getButtonsInitData = () => [{ linkType: ButtonLinkType.WL, name: '', linkMo: '' }];
export const getKakaoTemplateInitData = (mallNo: number): PostKakaoTemplatesAutoRequest => {
  return {
    data: {
      isSecurity: false,
      buttons: getButtonsInitData(),
      templateName: '',
      templateGroupType: 'MEMBER',
      templateContent: '',
      categoryCode: '',
      mallNo,
    },
  };
};

export const linkButtonData: LinkButtonType = {
  templateParentCategory: [],
  templateChildCategory: [],
  parentCategory: '',
  childCategory: '',
};

export const detailTemplateData: DetailTemplateType = {
  inspectResultComment: [],
  inspectionStatus: '',
  applyTemplate: false,
  templateCode: '',
  senderKey: '',
  comment: '',
  categoryCode: '',
};

export const buttonUsedOption: RadioBoxGroupOption<boolean> = {
  name: `template-button`,
  data: [
    { id: `template-button-used`, value: true, display: 'MEMBER.SMS_AUTO.USED' },
    { id: `template-button-notUsed`, value: false, display: 'MEMBER.SMS_AUTO.NOT_USED' },
  ],
};

export const buttonLinkOption: OptionData<KakaoTemplateAutoButtonLinkType>[] = [
  { id: 'link-wl', value: ButtonLinkType.WL, name: 'MEMBER.KAKAO.WEB_LINK' },
  { id: 'link-ds', value: ButtonLinkType.DS, name: 'MEMBER.KAKAO.DELIVERY_SERVICE' },
];

export const buttonCondition = (data: PostKakaoTemplatesAutoRequest['data'], templateCategoryCode: string) => {
  const noTemplateName = data.templateName.length;
  const noTemplateContent = data.templateContent.length;
  const MAX_CODE = 9999;
  const noOverLimitCode = Number(templateCategoryCode) <= MAX_CODE;

  return {
    noTemplateName,
    noTemplateContent,
    noOverLimitCode,
  };
};
// 알림톡 템플릿 등록 end

// 알림톡 발송내역 조회 start
export type emptyValueWithMessagesQuery = InputValue<GetKakaoMessagesRequest['params']>;
export const getKakaoMessagesRequestQuery = (mallNo: number): emptyValueWithMessagesQuery => {
  const { startYmd } = getFirstAndEndDay();
  return {
    page: 1,
    pageSize: 30,
    mallNo,
    startDate: startYmd.toString(),
    searchType: 'CONTENT',
    searchKeyword: '',
    isSuccess: '',
  };
};

export const historySearchOption: OptionData<string>[] = [
  { value: 'CONTENT', name: 'MEMBER.KAKAO.KEYWORDS_TYPE_CONTENT' },
  { value: 'PHONE', name: 'MEMBER.KAKAO.KEYWORDS_TYPE_PHONE' },
];

export const sendResultOption: OptionData<boolean>[] = [
  { value: true, name: 'MEMBER.KAKAO.SUCCESS' },
  { value: false, name: 'MEMBER.KAKAO.FAIL' },
];

export const getNotificationHistoryGirdOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'MEMBER.KAKAO.RECIPIENT_NO',
        name: 'phoneNo',
        align: 'center',
        formatter: ({ value }) => regxPhoneNo(value.toString()),
      },
      {
        header: 'CONTENTS',
        name: 'msg',
        width: 400,
        whiteSpace: 'pre-line',
        formatter: ({ value }) => {
          const contents = value.toString().split('\n');
          return contents.map(content => maskingAuthNo(content)).join('\n');
        },
      },
      {
        header: 'MEMBER.KAKAO.SEND_STATUS',
        name: 'resultStatus',
        align: 'center',
        formatter: ({ value }) => {
          const text = i18n.t(`MEMBER.KAKAO.${value === true ? 'SUCCESS' : 'FAIL'}`);
          return getAnchorHTML(text);
        },
      },
      {
        header: 'MEMBER.KAKAO.SEND_DATE',
        name: 'sendDate',
        align: 'center',
        formatter: ({ value }) => getStrYMDHMSS(value.toString()),
      },
    ],
    header: {
      align: 'center',
    },
    options: {
      rowHeaders: ['rowNum'],
      rowHeight: 'auto',
    },
  };
};
// 알림톡 발송내역 조회 end

// 플러스친구 아이디 등록 start
export const getKakaoConfigPlusIdInitData = (mallNo: number): PostKakaoConfigPlusRequest['data'] => {
  return {
    mallNo,
    phoneNumber: '',
    token: '',
    plusId: '',
    categoryCode: '',
  };
};

export const businessType = {
  firstBusinessType: 'firstBusinessType',
  secondBusinessType: 'secondBusinessType',
  thirdBusinessType: 'thirdBusinessType',
} as const;

export const categoryAllText: KakaoConfigBusinessType = {
  [businessType.firstBusinessType]: 'MEMBER.KAKAO.MAIN_CATEGORY',
  [businessType.secondBusinessType]: 'MEMBER.KAKAO.MIDDLE_CATEGORY',
  [businessType.thirdBusinessType]: 'MEMBER.KAKAO.SUB_CATEGORY',
};
