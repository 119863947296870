
































































import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class ColumnItem extends Vue {
  // 그리드 설정 아이콘 버튼 눌렸을때 나타나는 팝업에서만 사용된다.
  @Prop()
  private gridLayout;
  @Prop()
  private idx!: number;

  private moveItem(type: string) {
    this.$emit('moveItem', { type, idx: this.idx });
  }
}
