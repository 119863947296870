export default Object.freeze({
  TITLE: '상품등록 유형을 선택해 주세요.',
  TITLE_NO_OPTION: '상품 등록(옵션 없음)',
  TITLE_NO_OPTION_EXP1: '옵션 등록 불가',
  TITLE_NO_OPTION_EXP2: '옵션 추가금액 등록 불가',
  TITLE_OPTION: '상품 등록(옵션 있음)',
  TITLE_OPTION_EXP1: '다수의 상품을 옵션으로 등록 가능',
  TITLE_OPTION_EXP2: '옵션 추가금액 등록 가능',
  TITLE_SETOPTION: '세트상품 등록',
  TITLE_SETOPTION_EXP1: '기등록된 옵션을 불러와 세트로 등록 가능',
  TITLE_SETOPTION_EXP2: '옵션 추가금액 등록 가능',
});
