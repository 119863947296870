




























import { Component, Prop, Mixins, PropSync } from 'vue-property-decorator';
import {
  CallbackForImage,
  ImageFileData,
  ImageFileInputOption,
  ImageFileInputOptionType,
  PostedImagesResponse,
} from '@/types';
import ImageFileInputMixin from '@/components/common/input/imageFileInput/ImageFileInputMixin.vue';

@Component
export default class ImageFileInput extends Mixins(ImageFileInputMixin) {
  @PropSync('image')
  private imageSync!: ImageFileData;

  @Prop({ required: false })
  private readonly propOption!: ImageFileInputOptionType;

  private option: ImageFileInputOption = {
    alt: 'PRODUCT.OPTION.IMAGE',
    width: {
      input: '180px',
      'preview-max': '50px',
    },
    placeholder: '',
  };

  callbackForPostedImage(): CallbackForImage {
    return (data: PostedImagesResponse) => {
      this.imageSync = {
        url: data.url,
        name: data.file.name,
      };
    };
  }

  private get inputStyle(): string {
    return `width: ${this.option.width.input};`;
  }

  private get previewStyle(): string {
    return `max-width: ${this.option.width['preview-max']}; margin: 5px;`;
  }

  created(): void {
    this.option = Object.assign({}, this.option, this.propOption);
  }

  private removeImage(): void {
    this.imageSync = {
      url: '',
      name: '',
    };
  }
}
