















import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { PickedData } from '@/components/common/categories/MultiCategoriesSelector.vue';
export interface RemoveEmitData {
  removeData: PickedData;
  pickedDataEmpty: boolean;
}

@Component
export default class PickedList extends Vue {
  @PropSync('pickedData', { required: true })
  private readonly pickedDataSync: PickedData[];
  @Prop({ required: false })
  private readonly disabled!: boolean;
  @Prop({ required: false })
  private readonly resetCategoryData!: () => void;
  @Prop({ required: true })
  private readonly mode!: 'DISPLAY' | 'STANDARD';

  private remove(index: number): void {
    const isEditMode = this.$route.path.includes('edit');
    if (this.mode === 'STANDARD') {
      if (isEditMode) {
        const isConfirm = confirm(this.$t('PRODUCT.ADD.REMOVE_CATEGORY_MESSAGE').toString());
        if (!isConfirm) return;
      }
      this.resetCategoryData();
    }
    const removeData: PickedData = this.pickedDataSync.splice(index, 1)[0];
    const pickedDataEmpty = 0 >= this.pickedDataSync.length;
    const emitData: RemoveEmitData = {
      removeData,
      pickedDataEmpty,
    };
    this.$emit('remove', emitData);
  }
}
