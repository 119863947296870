















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { claimReasonTypes } from '@/const/claim';
import { ClaimReasonType } from '@/types/claim';

@Component({ components: { TextInput, SelectBox } })
export default class TdReasonType extends Vue {
  @Prop({ required: true })
  private readonly modifyMode!: boolean;

  @Prop({ required: true })
  private readonly originalPayType!: string;

  @PropSync('claimReasonType')
  private claimReasonTypeSync!: ClaimReasonType;

  @PropSync('claimReasonDetail')
  private claimReasonDetailSync!: string;

  //네이버페이 주문인 경우: 단순변심(색상,사이즈), 상품불량, 파손, 배송누락/오배송, 상품상세 정보와 다름, 판매자 배송 지연, 상품 품절/재고 없음
  private reasonTypeOptions = this.isNaverPay()
    ? [
        { name: 'CLAIM.COMMON.CHANGE_MIND', value: claimReasonTypes.CHANGE_MIND },
        { name: 'CLAIM.COMMON.DEFECTIVE_PRODUCT', value: claimReasonTypes.DEFECTIVE_PRODUCT },
        { name: 'CLAIM.COMMON.WRONG_DELIVERY', value: claimReasonTypes.WRONG_DELIVERY },
        { name: 'CLAIM.COMMON.WRONG_PRODUCT_DETAIL', value: claimReasonTypes.WRONG_PRODUCT_DETAIL },
        { name: 'CLAIM.COMMON.DELAY_DELIVERY', value: claimReasonTypes.DELAY_DELIVERY },
        { name: 'CLAIM.COMMON.OUT_OF_STOCK', value: claimReasonTypes.OUT_OF_STOCK },
      ]
    : [
        { name: 'CLAIM.COMMON.CHANGE_MIND', value: claimReasonTypes.CHANGE_MIND },
        { name: 'CLAIM.COMMON.DEFECTIVE_PRODUCT', value: claimReasonTypes.DEFECTIVE_PRODUCT },
        { name: 'CLAIM.COMMON.WRONG_DELIVERY', value: claimReasonTypes.WRONG_DELIVERY },
        { name: 'CLAIM.COMMON.WRONG_PRODUCT_DETAIL', value: claimReasonTypes.WRONG_PRODUCT_DETAIL },
        { name: 'CLAIM.COMMON.DELAY_DELIVERY', value: claimReasonTypes.DELAY_DELIVERY },
        { name: 'CLAIM.COMMON.CANCEL_BEFORE_PAY', value: claimReasonTypes.CANCEL_BEFORE_PAY },
        { name: 'CLAIM.COMMON.OUT_OF_STOCK', value: claimReasonTypes.OUT_OF_STOCK },
        { name: 'CLAIM.COMMON.OTHERS_SELLER', value: claimReasonTypes.OTHERS_SELLER },
        { name: 'CLAIM.COMMON.OTHERS_BUYER', value: claimReasonTypes.OTHERS_BUYER },
      ];

  private isNaverPay() {
    return this.originalPayType === 'NAVER_PAY';
  }
}
