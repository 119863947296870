


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { range1ToNI18n } from '@/utils/common';

@Component
export default class AdSmsNotice extends Vue {
  @Prop() private onNegativeClick!: () => void;
  @Prop() private data;

  private get isSmsSend(): boolean {
    return this.data.name === 'SmsAutomation';
  }

  private noticeTextList = range1ToNI18n(2, 'MEMBER.SMS.AD_NOTICE_TEXT_');
}
