/* eslint-disable quotes */
export default Object.freeze({
  //==>BatchConfirm
  COLLECTIVE_PRODUCT_REVIEW: '일괄 상품심사',
  SELECTED: '선택하신',
  REVIEW_IS_CONDUCTED_FOR_THE_PRODUCT: '건 상품에 대해 일괄 상품심사를 진행합니다.',
  PRODUCT_REVIEW: '상품심사',
  SALES_APPROVAL: '판매승인',
  REJECTION_OF_APPROVAL: '승인거부',
  REGISTRATION_OF_APPROVAL: '승인거부 의견 등록',
  SEPARATE_APPROVAL: '별도 승인거부 의견',
  AMENDMENT_REQUEST: '수정요청',
  PLEASE_ENTER_YOUR: '승인거부의견을 입력해주세요',
  CONFIRM: '확인',
  CANCEL: '취소',

  //==>ComparingPriceSite
  REGISTER_PRICE_COMPARISON_SITE: '가격비교사이트 등록',
  // REGISTER_PRICE_COMPARISON_SITE: '가격비교사이트 등록',
  DO_NOT_REGISTER: '등록 안함',
  ENROLLMENT: '등록',
  SELECT_ALL: '전체선택',
  NAVER_SHOPPING: '네이버쇼핑',
  DAUM_SHOPPING_HOW: '다음쇼핑하우',
  OVERCHARGE: '에누리',
  DANAWA: '다나와',
  KUCHA: '쿠차',
  PAYCO_SHOPPING: '페이코쇼핑',
  // CONFIRM: '확인'
  // CANCEL: '취소',

  //==>CopyMallProduct
  PRODUCT_COPY: '상품복사',
  SELECTED_PRODUCT_NUMBER: '선택하신 상품번호',
  COPY_THE_PRODUCT: '상품을 복사합니다.',
  PLEASE_SELECT_THE_PRODUCT: '상품복사 유형을 선택해주세요.',
  WHEN_SELECTING_DO_NOT_LINK_: '재고수량 연동 안함 선택 시: 재고수량 복사 여부 선택 가능합니다.',
  WHEN_SELECTING_INVENTORY_: '재고수량 연동 선택 시: 상품평 복사 여부 선택 가능합니다.',
  SELECT_SHOPPING_MALL: '쇼핑몰선택',
  STOCK_QUANTITY_LINKAGE: '재고수량 연동',
  DO_NOT_LINK_INVENTORY_QUANTITY: '재고수량 연동 안함',
  // STOCK_QUANTITY_LINKAGE: '재고수량 연동',
  IN_CASE_OF_SETTING_THE_STOCK: '재고수량 연동으로 설정할 경우',
  WHEN_A_SALE_OCCURS_ON_A_COPIED: '- 복사된 상품에서 판매가 발생하면 복사한 상품의 재고수량이 차감됩니다.',
  HOWEVER_THE_ACTUAL_SALES: '단, 2개 상품의 실제 판매수량은 별도 관리됩니다.',
  COPY_STOCK_QUANTITY: '재고수량 복사',
  DO_NOT_COPY: '복사 안함',
  COPY: '복사',
  COPY_STOCK_QUANTITY2: '재고수량 복사: 복사할 상품과 재고연동 없이 재고수량만 복사',
  COPY_PRODUCT_REVIEW: '상품평 복사',
  // DO_NOT_COPY: '복사 안함',
  // COPY: '복사',
  // PRODUCT_COPY: '상품복사',
  // CANCEL: '취소',

  //==>CustomProperty
  INFORMATION: '정보',
  CLOSE: '닫기',

  //==>ModifyDelivery
  DELIVERY_SPECIFIED_DATE_APPLIED: '배송지정일 적용',
  DELIVERY_SPECIFIED_DATE: '배송지정일',
  NOT_SET: '설정안함',
  SETTINGS: '설정',
  TERM: '기간',
  // NOT_SET: '설정안함',
  // SETTINGS: '설정',
  DAY_OF_THE_WEEK: '요일',
  // SELECT_ALL: '전체선택',
  MONTH: '월',
  ANGER: '화',
  NUMBER: '수',
  NECK: '목',
  GOLD: '금',
  BASED_ON_ORDER_DATE: '주문일 기준',
  // NOT_SET: '설정안함',
  // SETTINGS: '설정',
  THE_DELIVERY_DATE_PERIODORDATE_IS_CALCULATED: '배송지정일 기간/일자는 영업일 기준으로 계산하여 설정 됩니다.',
  // CONFIRM: '확인',
  // CANCEL: '취소',
  FROM: '부터',
  WORK: '일',

  //==>DisplayCategoryInfo
  EXHIBITION_CATEGORY_INFORMATION: '전시카테고리 정보',
  // CLOSE: '닫기',

  //==>EditImages
  IMAGE_INFORMATION: '이미지 정보',
  PRODUCT_IMAGE: '상품 이미지',
  PLEASE_REGISTER_A_REPRESENTATIVE: '대표이미지를 등록해주세요.',
  WHEN_PRODUCT_IMAGES_ARE_REGISTERED:
    '상품이미지 등록 시 자동으로 리스트, 확대, 고정 이미지에 리사이징되어 전시됩니다. (추가이미지 최대 ',
  LIST_IMAGE: '리스트 이미지',
  // LIST_IMAGE: '리스트 이미지',
  RECOMMENDED_720X400: '권장 720x400',
  PRODUCT_DETAILS_TOP: '상품 상세(상단)',
  // DO_NOT_REGISTER: '등록 안함',
  // ENROLLMENT: '등록',
  PLEASE_REGISTER_PRODUCT_DETAILS: '상품상세 항목을 등록해주세요.',
  PRODUCT_DETAILS: '상품 상세',
  DIRECT_IMAGE_REGISTRATION: '이미지 직접등록',
  USE_REGISTERED_OPTION_IMAGE: '등록된 옵션이미지 사용',
  // PLEASE_REGISTER_PRODUCT_DETAILS: '상품상세 항목을 등록해주세요.',
  PRODUCT_DETAILS_BOTTOM: '상품 상세(하단)',
  // DO_NOT_REGISTER: '등록 안함',
  // ENROLLMENT: '등록',
  PLEASE_REGISTER_A_REPRESENTATIVE_IMAGE: '대표이미지를 등록해주세요.',
  SAVE: '저장',
  // CANCEL: '취소',
  EIMAGEMSG1: '이미지파일(Jpg,jpge,gif,png,bmp)만 등록해주세요.',
  EIMAGEMSG2: '해당 상품에 등록된 옵션 이미지가 없습니다.',
  EIMAGEMSG3: '입력 불가한 항목이 있습니다. 다시확인해주세요.',
  EIMAGEMSG4: '이미지 정보 수정이 완료되었습니다.',
  EIMAGEMSG5: '이미지 정보 수정이 실패했습니다.',

  //==>modifyFrontDisplay
  EXHIBITION_STATUS: '전시상태',
  // SELECTED: '선택하신',
  CHANGES_THE_DISPLAY_STATUS_OF: '건 상품에 대해 전시상태를 일괄 변경합니다.',
  CHANGE_OF_EXHIBITION_STATUS: '전시상태 변경',
  // CHANGE_OF_EXHIBITION_STATUS: '전시상태 변경',
  DISPLAY_POSSIBLE: '전시가능',
  NO_EXHIBITION: '전시안함',
  // CONFIRM: '확인',
  // CANCEL: '취소',
  PLEASE_ENTER_A_VALUE: '값을 입력해주세요',

  //==>ModifyImmediateDiscount
  INSTANT_DISCOUNT_SETTING: '즉시할인 설정',
  // SELECTED: '선택하신',
  INSTANT_DISCOUNT_IS_SET_COLLECTIVELY: '건 상품에 대해 즉시할인을 일괄 설정합니다.',
  IMMEDIATE_DISCOUNT: '즉시할인',
  PERIOD_SETTING: '기간설정',
  // CONFIRM: '확인',
  // CANCEL: '취소',
  WON: '원',
  DAYS_3: '3일',
  DAYS_7: '7일',
  DAYS_15: '15일',
  DAYS_30: '30일',
  DAYS_60: '60일',
  DAYS_90: '90일',
  THE_PRICE_IN_UNITS_OF_10_WON: '가격은 10원 단위로 입력 가능합니다.',
  THE_PRICE_FOR_LESS_THAN_1_BILLION_WON: '가격은 10억원 미만으로 입력 가능합니다.',
  // PLEASE_ENTER_A_VALUE: '값을 입력해주세요',

  //==>ModifyPromotion
  APPLY_PROMOTION: '프로모션 적용',
  PROMOTION: '프로모션',
  POSSIBLE: '가능',
  ADDITIONAL_DISCOUNT: '추가할인',
  COUPON: '쿠폰',
  IMPOSSIBLE: '불가능',
  // CONFIRM: '확인',
  // CANCEL: '취소',

  //==>ModifyPromotionText
  CHANGE_OF_PROMOTIONAL_TEXT: '홍보문구 변경',
  // SELECTED: '선택하신',
  TEXT_FOR_THE_PRODUCT_IS_CHANGED_COLLECTIVELY: '건 상품에 대해 홍보문구를 일괄 변경합니다.',
  PR_TEXT: '홍보문구',
  // PERIOD_SETTING: '기간설정',
  // CONFIRM: '확인',
  // CANCEL: '취소',
  ENTER_PROMOTIONAL_TEXT: '홍보문구 입력',

  //==>ModifySalePeriod
  CHANGE_OF_SALES_PERIOD: '판매기간 변경',
  // SELECTED:'선택하신',
  CHANGES_THE_SALES_PERIOD_FOR: '건 상품에 대한 판매기간을 일괄 변경합니다.',
  SALES_PERIOD: '판매기간',
  ALWAYS_ON_SALE: '상시 판매',
  PERIOD_DESIGNATED_SALES: '기간지정 판매',
  // CONFIRM: '확인',
  // CANCEL:'취소',

  //==>ModifySalePrice
  ENTER_ONLY_NUMBERS: '숫자만 입력',
  CHANGE_IN_SELLING_PRICE: '판매가 변경',
  // SELECTED: '선택하신',
  COLLECTIVELY_CHANGE_THE_SELLING_PRICE: '건 상품에 대해 판매가를 일괄 변경합니다.',
  IN_THE_CASE_OF_CHARACTERISTIC_PRODUCTS: '특성상품의 경우 판매가 변경이 불가능할 수 있습니다.',
  ALSO_IF_YOU_CHANGE_THE_SELLING_PRICE:
    '또한, 옵션이 등록된 상품의 판매가를 변경하실 경우 옵션 별 실제 판매가가 변경됩니다.',
  CHANGE_PRICE: '변경할 판매가',
  AT_THE_CURRENT_SELLING_PRICE: '현재 판매가에서',
  // CONFIRM: '확인',
  // CANCEL: '취소',
  IMPRESSION: '인상',
  CUT: '인하',
  CHANGE_TO: '으로 변경',
  PRICE_IN_UNITS_OF_10_WON: '가격은 10원 단위로 입력 가능합니다',
  // PLEASE_ENTER_A_VALUE: '값을 입력해주세요',

  //==>ModifySaleStatus
  CHANGE_OF_SALES_STATUS: '판매상태 변경',
  // SELECTED: '선택하신',
  CHANGES_THE_SALES_STATUS_OF: '건 상품에 대해 판매상태를 일괄 변경합니다.',
  PRODUCTS_THAT_HAVE_BEEN_BANNED_FOR: '판매금지로 변경 된 상품은 판매제개가 불가능하니 신중히 변경해주세요.',
  WHEN_IT_IS_CHANGED_TO_PROHIBITED_FOR: `판매금지로 변경 시에 전시상태는 '전시안함'으로 자동 변경됩니다.`,
  WHEN_CHANGING_A_DISCONTINUED_PRODUCT: `판매중지 상품을 판매중으로 변경 시 전시상태도 '전시가능'으로 변경해야 상품이 노출됩니다.`,
  DEPENDING_ON_THE_PRE_ORDER_PERIOD: '예약판매기간과 판매기간에 따라 예약판매중 또는 판매중으로 변경합니다.',
  SALES_STATUS: '판매상태',
  // CONFIRM: '확인',
  // CANCEL: '취소',
  // CHANGE_OF_SALES_STATUS: '판매상태 변경',
  ON_SALEORON_RESERVATION_SALE: '판매가능', //'판매중/예약판매중',
  STOP_SELLING: '판매중지',
  PROHIBITION_OF_SALE: '판매금지',
  IF_YOU_CHANGE_TO_PROHIBITION_OF_SALES:
    '판매금지로 변경 할 경우 판매재개가 불가능합니다. 판매금지로 변경하시겠습니까?',
  // PLEASE_ENTER_A_VALUE: '값을 입력해주세요',

  //==>UrlDirectDisplay
  FRONT_UNEXPOSED_SETTING: '프론트 미노출 설정',
  YOU_CAN_COLLECTIVELY_CHANGE_WHETHER: `상품에 설정된 '프론트 미노출' 여부를 일괄 변경할 수 있습니다.`,
  IT_IS_NOT_EXPOSED_IN_THE_EXHIBITION: '‘프론트 미노출’ 설정 시, ‘전시카테고리’ 및 ‘검색영역’ 에 노출되지 않습니다.',
  FRONT_UNEXPOSED: '프론트 미노출',
  // FRONT_UNEXPOSED_SETTING: '프론트 미노출 설정',
  // FRONT_UNEXPOSED: '프론트 미노출',
  FRONT_EXPOSURE: '프론트 노출',
  // SAVE: '저장',
  // CLOSE: '닫기',
});
