import { OptionData, GroupOptionData, CheckBoxGroupOption, GroupOption } from '@/helpers/type';
import { platformTypes } from '@/const/order';
import {
  PayTypeCheckValue,
  MemberOptionValue,
  ClaimStatusCheckValue,
  PlatformTypesCheckValue,
  InvoiceNoRegisteredOptionValue,
  OrderStatusCheckValue,
  ExchangedReleaseValue,
} from './searchOptions.type';
import claimStatusTypes from '@/const/words/claimStatusTypes.ts';
import orderStatusTypes from '@/const/words/orderStatusTypes.ts';
import payType from '@/const/words/payType.ts';
import { orderStatusTypes as orderStatusTypesConsts } from '@/const/order';
import { OrderListTypes } from '@/types/order';

export const enum SortOrderNoOptionValues {
  SORT_TYPE_ORDER_NO_ASC = 'SORT_TYPE_ORDER_NO_ASC',
  SORT_TYPE_ORDERER_NAME_DESC = 'SORT_TYPE_ORDERER_NAME_DESC',
  SORT_TYPE_ORDERER_NAME_ASC = 'SORT_TYPE_ORDERER_NAME_ASC',
  SORT_TYPE_PAY_ORDER_AMT_DESC = 'SORT_TYPE_PAY_ORDER_AMT_DESC',
  SORT_TYPE_PAY_ORDER_AMT_ASC = 'SORT_TYPE_PAY_ORDER_AMT_ASC',
}
export const enum SortDeliveryNoOptionValues {
  SORT_TYPE_DELIVER_NO_ASC = 'SORT_TYPE_DELIVER_NO_ASC',
  SORT_TYPE_RECEIVER_NAME_DESC = 'SORT_TYPE_RECEIVER_NAME_DESC',
  SORT_TYPE_RECEIVER_NAME_ASC = 'SORT_TYPE_RECEIVER_NAME_ASC',
}

const payTypesCheckData = (): GroupOptionData<PayTypeCheckValue>[] =>
  payType.map(({ value, name }) => ({ id: value, value: value, display: name }));

const claimStatusCheckData = (): GroupOptionData<ClaimStatusCheckValue>[] => {
  return claimStatusTypes.map(({ value, name }) => ({ id: value, value: value, display: name }));
};

const orderStatusCheckData = (orderListTypes: OrderListTypes): GroupOptionData<OrderStatusCheckValue>[] => {
  switch (orderListTypes) {
    case 'HOLD_BY_RESERVATION':
      return [
        {
          id: orderStatusTypesConsts.DEPOSIT_WAIT,
          value: orderStatusTypesConsts.DEPOSIT_WAIT,
          display: 'ORDER.ORDER_STATUS_TYPE.DEPOSIT_WAIT',
        },
        {
          id: orderStatusTypesConsts.PAY_DONE,
          value: orderStatusTypesConsts.PAY_DONE,
          display: 'ORDER.ORDER_STATUS_TYPE.PAY_DONE',
        },
      ];

    case 'HOLD_DELIVERY':
      return [
        {
          id: orderStatusTypesConsts.PRODUCT_PREPARE,
          value: orderStatusTypesConsts.PRODUCT_PREPARE,
          display: 'ORDER.ORDER_STATUS_TYPE.PRODUCT_PREPARE',
        },
        {
          id: orderStatusTypesConsts.DELIVERY_PREPARE,
          value: orderStatusTypesConsts.DELIVERY_PREPARE,
          display: 'ORDER.ORDER_STATUS_TYPE.DELIVERY_PREPARE',
        },
      ];
  }

  return orderStatusTypes.map(({ value, name }) => ({ id: value, value: value, display: name }));
};

const searchDateOptions = (
  orderListTypes: OrderListTypes,
): OptionData<'NONE' | 'ORDER_YMDT' | 'RESERVATION_START_YMDT' | 'STATE_CHANGE_YMDT'>[] => {
  let options = [];

  switch (orderListTypes) {
    case 'PAY_DONE':
      options = [
        {
          value: 'STATE_CHANGE_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.PAYMENT_DATE`,
        },
      ];
      break;

    case 'BUY_CONFIRM':
      options = [
        {
          value: 'STATE_CHANGE_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.PURCHASE_CONFIRMATION_DATE`,
        },
      ];
      break;

    case 'PRODUCT_PREPARE':
      options = [
        {
          value: 'STATE_CHANGE_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.PRODUCT_PREPARING_DATE`,
        },
      ];
      break;

    case 'DELIVERY_PREPARE':
      options = [
        {
          value: 'STATE_CHANGE_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.DELIVERY_PREPARING_DATE`,
        },
      ];
      break;

    case 'DELIVERY_DONE':
      options = [
        {
          value: 'STATE_CHANGE_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.DELIVERY_COMPLETION_DATE`,
        },
      ];
      break;

    case 'HOLD_BY_RESERVATION':
      options = [
        {
          value: 'RESERVATION_START_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.RESERVATION_START_YMDT`,
        },
      ];
      break;

    case 'HOLD_DELIVERY':
      options = [
        {
          value: 'STATE_CHANGE_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.DELIVERY_HOLD_DATE`,
        },
      ];
      break;

    case 'DELIVERY_ING':
      options = [
        {
          value: 'STATE_CHANGE_YMDT',
          name: `ORDER.ORDER_GRID_COLUMNS.DELIVERY_ING`,
        },
      ];
      break;
  }

  return [
    {
      value: 'ORDER_YMDT',
      name: `ORDER.ORDER_GRID_COLUMNS.ORDER_DATE`,
    },
    ...options,
  ];
};

const searchKeywordTypeOptions: OptionData<string>[] = [];

const platformTypeOptions: CheckBoxGroupOption<PlatformTypesCheckValue> = {
  name: 'platformTypes',
  hasAll: true,
  noBrackets: true,
  nonI18nConvert: false,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    {
      id: 'pc',
      value: platformTypes.PC,
      display: 'PC_WEB',
    },
    {
      id: 'mobileWeb',
      value: platformTypes.MOBILE_WEB,
      display: 'MOBILE_WEB',
    },
    // 추후 지원 예정
    // {
    //   id: 'mobileApp',
    //   value: platformTypes.MOBILE_APP,
    //   display: 'MOBILE_APP',
    // },
  ],
};

const payTypes: CheckBoxGroupOption<PayTypeCheckValue> = {
  name: 'payTypes',
  hasAll: true,
  noBrackets: true,
  nonI18nConvert: false,
  data: [
    {
      id: 'all',
      value: 'ALL',
      display: 'ALL_CHECK',
    },
    ...payTypesCheckData(),
  ],
};

const claimStatusOptions = (): CheckBoxGroupOption<ClaimStatusCheckValue> => {
  return {
    name: 'claimStatusTypes',
    hasAll: true,
    noBrackets: true,
    data: [
      {
        id: 'all',
        value: 'ALL',
        display: 'ALL_CHECK',
      },
      ...claimStatusCheckData(),
    ],
  };
};

const orderStatusOptions = (orderListTypes: OrderListTypes): CheckBoxGroupOption<OrderStatusCheckValue> => {
  return {
    name: 'orderStatusTypes',
    hasAll: true,
    noBrackets: true,
    data: [
      {
        id: 'all',
        value: 'ALL',
        display: 'ALL_CHECK',
      },
      ...orderStatusCheckData(orderListTypes),
    ],
  };
};

const memberOptions: OptionData<MemberOptionValue>[] = [
  {
    value: '',
    name: 'ORDER.SEARCH_FORM.IS_MEMBER',
  },
  {
    value: true,
    name: 'ORDER.SEARCH_FORM.MEMBER',
  },
  {
    value: false,
    name: 'ORDER.SEARCH_FORM.NON_MEMBER',
  },
];

const exchangedRelease: OptionData<ExchangedReleaseValue>[] = [
  {
    value: '',
    name: 'CLAIM.COMMON.EXCHANGED',
  },
  {
    value: true,
    name: 'Y',
  },
  {
    value: false,
    name: 'N',
  },
];

const memberGradeOptions: OptionData<number>[] = [
  {
    value: 0,
    name: 'CLAIM.COMMON.MEMBER_GRADE',
  },
];

const deliveryCompanyOptions: OptionData<string>[] = [
  {
    value: '',
    name: 'CLAIM.COMMON.DELIVERY_COMPANY',
  },
];

const invoiceNoRegisteredOptions: OptionData<InvoiceNoRegisteredOptionValue>[] = [
  {
    value: '',
    name: 'ORDER.SEARCH_FORM.IS_INVOICE_REGISTERED',
  },
  {
    value: true,
    name: 'ORDER.SEARCH_FORM.INVOICE_REGISTERED',
  },
  {
    value: false,
    name: 'ORDER.SEARCH_FORM.NON_INVOICE_REGISTERED',
  },
];

// TODO: /order-options/order-grid ORDER_NO, DELIVER_NO 미지원

const orderNoSortTypeOptions = (): OptionData<SortOrderNoOptionValues>[] => {
  return [
    {
      value: SortOrderNoOptionValues.SORT_TYPE_ORDER_NO_ASC,
      name: 'ORDER.SEARCH_FORM.SORT_TYPE_DEFAULT',
    },
    {
      value: SortOrderNoOptionValues.SORT_TYPE_ORDERER_NAME_DESC,
      name: 'ORDER.SEARCH_FORM.SORT_TYPE_ORDERER_NAME_DESC',
    },
    {
      value: SortOrderNoOptionValues.SORT_TYPE_ORDERER_NAME_ASC,
      name: 'ORDER.SEARCH_FORM.SORT_TYPE_ORDERER_NAME_ASC',
    },
    {
      value: SortOrderNoOptionValues.SORT_TYPE_PAY_ORDER_AMT_DESC,
      name: 'ORDER.SEARCH_FORM.SORT_TYPE_PAY_ORDER_AMT_DESC',
    },
    {
      value: SortOrderNoOptionValues.SORT_TYPE_PAY_ORDER_AMT_ASC,
      name: 'ORDER.SEARCH_FORM.SORT_TYPE_PAY_ORDER_AMT_ASC',
    },
  ];
};

const deliveryNoSortTypeOptions = (): OptionData<SortDeliveryNoOptionValues>[] => [
  {
    value: SortDeliveryNoOptionValues.SORT_TYPE_DELIVER_NO_ASC,
    name: 'ORDER.SEARCH_FORM.SORT_TYPE_DEFAULT',
  },
  {
    value: SortDeliveryNoOptionValues.SORT_TYPE_RECEIVER_NAME_DESC,
    name: 'ORDER.SEARCH_FORM.SORT_TYPE_RECEIVER_NAME_DESC',
  },
  {
    value: SortDeliveryNoOptionValues.SORT_TYPE_RECEIVER_NAME_ASC,
    name: 'ORDER.SEARCH_FORM.SORT_TYPE_RECEIVER_NAME_ASC',
  },
];

const statusTypeRadio = (orderListTypes): GroupOption<string> => {
  const orderDisplayValue =
    orderListTypes === 'PREVIOUS' ? 'ORDER.SEARCH_FORM.ALL_ORDER' : 'ORDER.SEARCH_FORM.NORMAL_ORDER';

  return {
    name: 'statusRadioType',
    data: [
      { id: 'order', value: 'ORDER', display: orderDisplayValue },
      { id: 'claim', value: 'CLAIM', display: 'ORDER.SEARCH_FORM.CLAIM_ORDER' },
    ],
  };
};

export const searchFormOptions = (orderListTypes: OrderListTypes) => ({
  payTypes,
  memberOptions,
  exchangedRelease,
  memberGradeOptions,
  platformTypeOptions,
  deliveryCompanyOptions,
  searchKeywordTypeOptions,
  invoiceNoRegisteredOptions,
  statusTypeRadio: statusTypeRadio(orderListTypes),
  orderNoSortTypeOptions: orderNoSortTypeOptions(),
  deliveryNoSortTypeOptions: deliveryNoSortTypeOptions(),
  searchDateOptions: searchDateOptions(orderListTypes),
  claimStatusOptions: claimStatusOptions(),
  orderStatusOptions: orderStatusOptions(orderListTypes),
  periodRangeOption:
    orderListTypes === 'PREVIOUS'
      ? { periodTypes: ['3d', '1w', '15d', '1m', '3m'] }
      : { periodTypes: ['td', 'ytd', '3d', '1w', '1m', '3m'] },
});
