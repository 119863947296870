




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdditionalPayType } from 'ncp-api-supporter';

@Component({
  components: {},
})
export default class OrderOtherPayInfo extends Vue {
  @Prop({ default: () => [] }) public otherPayDetailList;

  public additionalPayType(type: AdditionalPayType | null) {
    let label;
    switch (type) {
      case 'CASH':
        label = this.$t('ORDER.ORDER_DETAIL.PAY_TYPE_ACCOUNT');
        break;
      case 'ACCUMULATION':
        label = this.$t('ORDER.ORDER_DETAIL.PAY_TYPE_ACCUMULATION');
        break;
      case 'NAVER_PAY':
        label = this.$t('ORDER.ORDER_DETAIL.PAY_TYPE_NAVER_PAY');
        break;
      default:
        label = '';
    }
    return label;
  }
}
