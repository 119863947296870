import { i18n } from '@/main';
// types
import {
  PartnerRendererProps,
  RendererMapByContractStatus,
} from '@/views/contents/partner/modification/PartnerModificationTypes';
import { Contracts } from 'ncp-api-supporter';
import { CellRenderer } from '@/types/tui-grid/renderer';
// htmls
import { getGridAnchor, getGridTextSpan } from '@/views/contents/partner/modification/grid/gridHTMLs';
// popup
import { throwPartnerContractFilesUpdateHistoryPopup } from '@/views/contents/partner/modification/popup/PartnerModificationPopup';

const renderText = (text: string) => {
  return {
    getElement(): HTMLElement {
      return getGridTextSpan(text);
    },
    render() {
      return;
    },
  };
};

const renderOpenContractFilesPopupText = (mallNo: number) => {
  return {
    getElement(): HTMLElement {
      return getGridAnchor({
        anchorText: i18n.t('VIEW') as string,
        handleClick: () => throwPartnerContractFilesUpdateHistoryPopup(mallNo),
      });
    },
    render() {
      return;
    },
  };
};

export default (props: PartnerRendererProps<'contractFiles'>): CellRenderer => {
  const mallNo = props.grid.getValue<Contracts, 'mallNo'>(props.rowKey, 'mallNo');
  const contractStatus = props.grid.getValue<Contracts, 'contractStatus'>(props.rowKey, 'contractStatus');

  const rendererMap: RendererMapByContractStatus<'contractFiles'> = {
    WAITING() {
      return renderText('-');
    },

    INVESTIGATION() {
      return renderText('-');
    },

    ACTIVE() {
      return renderOpenContractFilesPopupText(mallNo);
    },
  };
  return rendererMap[contractStatus]();
};
