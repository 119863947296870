











































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Amounts } from 'ncp-api-supporter';
import { ClaimType } from '@/types/claim';
import AmountMixin from '@/components/popup/claim/AmountMixin.ts';
import { ClaimCoupon } from 'ncp-api-supporter/dist/types/modules/claim/claims';

// 취소, 반품 클레임 금액상세
@Component
export default class AmountDetail extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  @Prop({ required: true })
  private readonly amounts!: Amounts;

  @Prop({ required: true })
  private readonly coupon!: ClaimCoupon;

  // 취소,교환 상품 금액상세
  private productAmt = {
    standardAmt: 0, // 상품금액
    immediateDiscountAmt: 0, // 즉시할인금액
    productCouponDiscountAmt: 0, // 상품쿠폰 재계산(-)
    productCoupons: [],
    // additionalDiscountAmt: 0, // 추가할인(-), 오픈스펙에서 제외

    // 상품합계(+)
    productTotalAmt() {
      return this.standardAmt - this.immediateDiscountAmt;
    },

    // 취소 금액합계 a
    totalAmt() {
      return this.productTotalAmt() - this.productCouponDiscountAmt;
    },
  };

  private get hasCoupons() {
    return this.productAmt.productCoupons && this.productAmt.productCoupons.length !== 0;
  }

  created() {
    this.setProductAmt();
  }

  private setProductAmt() {
    // const { amounts, coupon } = this.claimData;
    const { standardAmt, immediateDiscountAmt } = this.amounts.refund.order;

    this.productAmt = {
      ...this.productAmt,
      standardAmt,
      immediateDiscountAmt,
      productCouponDiscountAmt: this.amounts.refund.coupon.productCouponDiscountAmt,
      productCoupons: this.coupon?.productCoupons ?? [],
      // additionalDiscountAmt, // 오픈스펙에서 제외
    };
  }
}
