














import { Component, Prop, Vue } from 'vue-property-decorator';
import { toolTipComponents } from '@/components/common/tooltip/tooltip';
import ClickOutside from 'vue-click-outside';

const components = toolTipComponents;

@Component({ directives: { ClickOutside }, components })
export default class GridHeaderTooltip extends Vue {
  @Prop({ required: true })
  private readonly data: { name?: string; data: object };
  @Prop()
  private readonly onNegativeClick!: () => void;

  private isOpened = false;

  private closeTooltip() {
    if (this.isOpened) this.onNegativeClick();
    this.isOpened = !this.isOpened;
  }

  private get isDefault(): boolean {
    return !this.data.name;
  }
}
