import { OptColumn } from '@/types';

export const getFrontDisplayColumn = (width = 80): OptColumn => {
  return {
    header: 'PRODUCT.COMMON.FRONT_DISPLAYABLE',
    name: 'frontDisplayable',
    width,
    align: 'center',
    formatter: ({ value }) =>
      value ? window.$t('PRODUCT.COMMON.FRONT_DISPLAYABLE_Y') : window.$t('PRODUCT.COMMON.FRONT_DISPLAYABLE_N'),
  };
};
