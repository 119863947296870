


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BankAccount, PreviousRefund } from 'ncp-api-supporter';
import { formatCurrency } from '@/utils/numberFormat';

@Component({
  components: {},
})
export default class PreviousOrderRefundInfo extends Vue {
  @Prop({ default: () => [] })
  private refundInfo: PreviousRefund;

  private formatCurrency = formatCurrency;

  private hasRefundAccount(refundBankAccount: BankAccount): boolean {
    return refundBankAccount?.account !== undefined && refundBankAccount.account.length !== 0;
  }
}
