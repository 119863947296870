






















import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import TotalSalesTable from '@/components/partner/TotalSales.vue';
import Grid from '@/components/common/grid/Main.vue';
import { getPartnerSalesGridProps, partnerSalesQuery } from '@/const/contents/partner';
import { TotalSales } from 'ncp-api-supporter';
import { DailySalesHistory } from 'ncp-api-supporter/dist/types/modules/orderFriends/settlements';
import { CreateExcel, GridEventProps } from '@/types';
import { createExcel } from '@/components/common/grid/ExcelCreateUtil';
import { getStrDate } from '@/utils/dateFormat';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { RowKey } from '@/types/tui-grid';
@Component({
  components: { TotalSalesTable, Grid },
})
export default class PartnerSales extends Vue {
  @Ref()
  private gridRef: Grid;

  private totalSalesHistory: TotalSales[] = [];
  private dailySalesHistory: DailySalesHistory = { contents: [], totalCount: 0 };
  private dailySalesGridProps = getPartnerSalesGridProps();

  created() {
    this.search();
  }

  @Watch('$route.query')
  private async search() {
    const {
      data: { dailySalesHistory, totalSalesHistory },
    } = await this.$api.getSales({
      params: this.params,
    });
    this.dailySalesHistory = dailySalesHistory;
    this.totalSalesHistory = totalSalesHistory;
  }

  private get params() {
    const startDateTime = `${this.$route.query.startDateTime ?? partnerSalesQuery().startDateTime} ${
      DEFAULT_TIME_RANGE.START
    }`;
    const endDateTime = `${this.$route.query.endDateTime ?? partnerSalesQuery().endDateTime} ${DEFAULT_TIME_RANGE.END}`;
    return { ...partnerSalesQuery(), ...this.$route.query, startDateTime, endDateTime };
  }

  private get createExcelQuery(): CreateExcel {
    const { startYmd, endYmd } = this.getSearchYmd('YYYYMMDD');
    const extraInfo = `${startYmd}_${endYmd}`;

    return {
      query: {
        ...this.params,
        servicePlan: 'PRO',
      },
      extraInfo,
    };
  }

  private getSearchYmd(format = 'YYYY-MM-DD'): { startYmd: string; endYmd: string } {
    const startYmd = getStrDate(this.params.startDateTime.toString(), format);
    const endYmd = getStrDate(this.params.endDateTime.toString(), format);
    return { startYmd, endYmd };
  }

  private onItemClicked(gridProps: GridEventProps) {
    const { columnName, rowKey } = gridProps;
    if (columnName !== 'download') return;
    this.createDailySaleExcel(rowKey);
  }

  private createDailySaleExcel(rowKey: RowKey) {
    const { mallNo } = this.params;
    const hasData = this.dailySalesHistory.contents.length > 0;
    const extraInfo = getStrDate(this.gridRef.getRow(rowKey).createDate.toString(), 'YYYYMMDD');
    const date = getStrDate(this.dailySalesHistory.contents[rowKey].createDate);
    createExcel(
      hasData,
      'PRO_SETTLEMENT_DAILY_SALE',
      {
        mallNo,
        startDateTime: date,
        endDateTime: date,
        servicePlan: 'PRO',
      },
      extraInfo,
    );
  }
}
