








































































import { Component } from 'vue-property-decorator';
import { GetOrderProductsRequest } from 'ncp-api-supporter';
import { i18nObjectByKey } from '@/utils/i18n';
import WindowPopupMain from '@/views/popups/Main.vue';
@Component
export default class OrderDiscount extends WindowPopupMain {
  private orderInfo = {};
  private productInfo = [];
  private couponInfo = [];
  private couponNoData = {
    id: 'productCouponLayerPopup',
    width: 450,
    maxHeight: 500,
    title: 'ORDER.ORDER_DETAIL.PRODUCT_COUPON_TITLE',
    nonFooter: true,
  };
  private discountNoData = {
    id: 'productCouponLayerPopup',
    width: 450,
    maxHeight: 500,
    title: 'ORDER.ORDER_DETAIL.DISCOUNT_TITLE',
    nonFooter: true,
  };
  private cartDiscountData = {
    id: 'productCouponLayerPopup',
    width: 450,
    maxHeight: 500,
    title: 'ORDER.ORDER_DETAIL.CART_DISCOUNT_TITLE',
    nonFooter: true,
  };
  private isLast = false;

  public created(): void {
    this.couponNoData = i18nObjectByKey(this, this.couponNoData, 'title');
    this.discountNoData = i18nObjectByKey(this, this.discountNoData, 'title');
    this.cartDiscountData = i18nObjectByKey(this, this.cartDiscountData, 'title');

    this.checkFirstOrLast();
  }

  private mounted() {
    this.fetchDiscountData();
  }

  private async fetchDiscountData() {
    await this.getCouponInfo();
    await this.getOrderInfo();
    await this.getOrderProductInfo();
  }

  private async getCouponInfo(): Promise<void> {
    const couponRequest = {
      params: {
        orderNo: this.data.orderNo as string,
      },
    };

    const res = await this.$api.getCouponsUse(couponRequest);
    this.couponInfo = [...res.data];
  }

  private async getOrderInfo(): Promise<void> {
    const orderRequest = {
      params: {
        orderNo: this.data.orderNo as string,
      },
    };

    const res = await this.$api.getOrdersByOrderNo(orderRequest);
    const order = res.data;

    const cartCoupon = this.couponInfo.find(item => item.couponType === 'CART');
    const cartDiscountNo = cartCoupon?.couponNo;

    this.orderInfo = {
      cartCouponFirst: order.firstBalance.cartCouponDiscountAmt,
      cartCouponLast: order.lastBalance.cartCouponDiscountAmt,
      cartDiscountNum: cartDiscountNo,
    };
  }

  private async getOrderProductInfo(): Promise<void> {
    const ProductInfoRequest: GetOrderProductsRequest = {
      params: {
        orderNo: this.data.orderNo as string,
      },
    };

    const res = await this.$api.getOrderProducts(ProductInfoRequest);

    this.productInfo = [...res.data];
    this.setCouponNoForProductInfo();
  }

  private setCouponNoForProductInfo() {
    this.productInfo.forEach((pItem, pIndex) => {
      const usedCoupon = this.couponInfo.find(item => item.orderProductNo === pItem.orderProductNo);

      this.productInfo[pIndex].couponNo = usedCoupon?.couponNo;
    });
  }

  private clickCouponNo(couponNo) {
    window.open(`/pro/promotion/basic/coupons/modify?couponNo=${couponNo}`, '_blank');
  }

  private handleWindowClose(): void {
    window.close();
  }

  private checkFirstOrLast(): void {
    this.isLast = this.data.orderType === 'last';
  }
}
