import { PopupList, Popup } from '@/types/popup';

const popupStore = {
  namespaced: true,
  state: {
    popupList: [],
  },
  mutations: {
    ADD_POPUP: function(state: PopupList, popup: Popup) {
      state.popupList.push(popup);
    },
    REMOVE_POPUP: function(state: PopupList, popupIndex: number) {
      state.popupList.splice(popupIndex - 1, 1);
    },
  },
  actions: {},
  getters: {
    getPopupList: function(state: PopupList) {
      return state.popupList;
    },
    getLastPopupItem: function(state: PopupList) {
      if (state.popupList.length > 0) {
        return state.popupList[state.popupList.length - 1];
      } else {
        return null;
      }
    },
    sizeOfPopupList: function(state: PopupList) {
      return state.popupList.length;
    },
  },
};
export default popupStore;
