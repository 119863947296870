export default Object.freeze({
  PAGE_TITLE: '1:1문의 상세보기',
  INFO_TITLE: '1:1 문의 정보',
  BTN_CHANGE: '변경',
  POP_MODIFY: '문의유형/담당자 변경',
  WITHDRAWN: '탈퇴회원',
  T1_TH_1: '문의유형 / 담당자',
  T1_TH_2: '문의번호 / 문의자',
  T1_TH_3: '진행상태',
  T1_TH_4: '문의일시',
  ADMIN_TITLE: '담당자 변경내역',
  T2_TH_1: '변경일시',
  T2_TH_2: '변경자',
  T2_TH_3: '문의유형',
  T2_TH_4: '담당자',
  T2_TH_5: '변경사유',
  CONTENT_TITLE: '1:1문의 내용',
  T3_TH_1: '문의제목',
  T3_TH_2: '문의내용',
  T3_TH_3: '상품정보',
  T3_TH_4: '첨부파일',
  ORDER_TITLE: '주문정보',
  T4_TH_1: '주문번호',
  T4_TH_2: '결제일시',
  T4_TH_3: '담당자명1',
  T4_TH_4: '담당자명2',
  T4_TH_5: '결제금액',
  T4_TH_6: '배송비',

  TEMPLETE_ALL: '답변 템플릿 설정',

  STATUS_ISSUED: '답변대기',
  STATUS_ANSWERED: '답변완료',
  STATUS_IN_PROGRESS: '진행중',

  SMS_CHECKBOX: 'SMS 답변 알림',
  EMAIL_CHECKBOX: 'e메일 동시 답변',
  TRANSMISSION: '전송함',
  CELLPHONENUMBER: '휴대폰번호',
  MAILADDRESS: 'e메일주소',
  ANSWER_TITLE: '1:1문의 답변',
  T5_TH_1: '답변일시',
  T5_TH_2: '답변내용',
  T5_TH_3: 'SMS 답변 알림',
  T5_TH_4: 'e메일 동시 답변',
  T5_SHIP: '발송함',
  T5_BALSONG: '발송안함',

  BTN_SAVE: '임시저장',
  BTN_SEND: '답변전송',
  CONFIRM_SAVE: '답변을 임시저장 하시겠습니까?',
  CONFIRM_SEND: '답변을 전송하시겠습니까?',
  CONFIRM_HAD_CONTENT: '이미 등록된 답변이 있습니다. 업데이트 하시겠습니까?',
  WARN_SAVE: '이미 등록된 답변이 있을 경우 임시저장이 불가합니다',
  SUCCESS_SAVE: '임시저장되었습니다.',
  SUCCESS_SEND: '답변이 발송되었습니다.',
  ERRER_SAVE: '임시저장에 실패했습니다.',
  POP_CRM: '회원정보',
});
