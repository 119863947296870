




import { Vue, Component } from 'vue-property-decorator';
import DormantMember from './DormantMember.vue';

@Component({
  components: { DormantMember },
})
export default class DormantMemberIframe extends Vue {}
