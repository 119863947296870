var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('status-summary-box',{attrs:{"title":_vm.$t('SUMMARY.SALES_STATUS').toString(),"period":_vm.getSummaryPeriodI18n(1, 'month')},on:{"refresh":_vm.fetch}},[_c('div',{staticClass:"box type01"},[_c('p',{staticClass:"repre-cnt"},[_vm._v(" "+_vm._s(_vm.$t(_vm.newOrderSummary.label))+" "),_c('strong',{staticClass:"color01"},[_vm._v(_vm._s(_vm.getDisplayCount(_vm.newOrderSummary.count)))]),_vm._v(" "+_vm._s(_vm.$t('CASE'))+" ")]),_c('div',{staticClass:"list"},[_c('ul',{staticClass:"list01"},_vm._l((_vm.splitSummaries.left),function(ref){
var label = ref.label;
var count = ref.count;
var path = ref.path;
return _c('li',{key:label},[_c('p',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t(("SUMMARY." + label))))]),_c('p',{staticClass:"cnt"},[_c('router-link',{attrs:{"to":path}},[_c('strong',[_vm._v(_vm._s(_vm.getDisplayCount(count)))])]),_vm._v(" "+_vm._s(_vm.$t('CASE'))+" ")],1)])}),0),_c('ul',{staticClass:"list02"},_vm._l((_vm.splitSummaries.right),function(ref){
var label = ref.label;
var count = ref.count;
var path = ref.path;
return _c('li',{key:label},[_c('p',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t(("SUMMARY." + label))))]),_c('p',{staticClass:"cnt"},[_c('router-link',{attrs:{"to":path}},[_c('strong',[_vm._v(_vm._s(_vm.getDisplayCount(count)))])]),_vm._v(" "+_vm._s(_vm.$t('CASE'))+" ")],1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }