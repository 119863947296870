export default Object.freeze({
  // profile setting
  TITLE: '설정',
  LANGUAGE_USE: '사용언어 설정',
  LANGUAGE_KOREAN: '한국어',
  LANGUAGE_CHINESE: '中文',
  LANGUAGE_JAPANESE: '日本語',
  LANGUAGE_ENGLISH: 'ENGLISH',
  TIME_ZONE: '사용 시간대 설정',
  ASIA_SEOUL: 'UTC+9 대한민국・일본',
  GMT_Z: 'UTC+0',
  AMERICA_EASTERN: 'UTC-5 미국 Eastern',
  AMERICA_CENTRAL: 'UTC-6 미국 Central',
  AMERICA_MOUNTAIN: 'UTC-7 미국 Mountain',
  AMERICA_PACIFIC: 'UTC-8 미국 Pacific',
  LEFT_MENU_OPEN: '좌측 메뉴 열림 설정',
  OPEN: '항상 열기',
  CLOSE: '숨기기',
  SUCCESSFULLY_SAVED: '변경사항이 저장되었습니다.',
});
