import { api, tokenApi } from '@/api';
import cookies from 'js-cookie';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN, TOKEN_EXPIRE_TIME, SESSION_INITIALIZE } from '@/const/cookie';
const { VUE_APP_ADMIN_REMOTE_URL, VUE_APP_CONSOLIDATED_AUTH, VUE_APP_REDIRECT_URL, VUE_APP_WORKSPACE_URL } = process.env;
import { PostAuthTokenRequest } from 'ncp-api-supporter';
// import * as jwt from 'jsonwebtoken'; // jwonwebtoken은 elliptic 패키지 의존성을 가지고 chunk_vendors.js에 서버 내 절대 경로를 노출 시킨다.
import JwtDecode from 'jwt-decode';
import { Admin } from '@/types/admin';
import { deleteCookie, getCookie } from '@/utils/cookie';
import once from 'lodash.once';
import { decrypt, encrypt } from './crypto';
type decodedTokenType = null | { [key: string]: any } | string;

export const decodeToken = (token: string): null | { [key: string]: any } | string => JwtDecode(token);

export function setToken(accessToken: string, refreshToken: string, autoLogoutMin: number): Admin {
  api.axios.defaults.headers.accessToken = accessToken;
  const admin: decodedTokenType = decodeToken(accessToken);
  cookies.set(SHOPBY_ACCESSTOKEN, accessToken, { expires: (1.0 / 24 / 60) * 30 }); // 30분
  cookies.set(SHOPBY_REFRESHTOKEN, refreshToken, { expires: (1.0 / 24 / 60) * autoLogoutMin }); // autoLogoutMin 분
  // 쿠키에는 encodeURIComponent 된 데이터만 들어감
  cookies.set(encodeURIComponent(encrypt(TOKEN_EXPIRE_TIME)), encrypt(autoLogoutMin.toString()), { expires: 1.0 }); // 1d
  cookies.set('adminInfo', JSON.stringify(admin));
  sessionStorage.setItem('admin', JSON.stringify(admin));

  return admin as Admin;
}

export const setSessionCookie = () => {
  cookies.set(SESSION_INITIALIZE, SESSION_INITIALIZE); //브라우저 종료 여부 확인
};

export function bindAdminInfo(accessToken: string): Admin {
  api.axios.defaults.headers.accessToken = accessToken;
  const admin: decodedTokenType = decodeToken(accessToken);
  cookies.set('adminInfo', JSON.stringify(admin));
  sessionStorage.setItem('admin', JSON.stringify(admin));

  return admin as Admin;
}

export function moveStandardAdmin() {
  let url = window.location.origin;
  if (!url.includes('admin.shopby.co.kr')) {
    // Test 환경
    url = 'https://alpha-admin.shopby.co.kr';
  }
  console.log(
    'moveStandardAdmin',
    `${url}/auth/token?key=${getCookie(SHOPBY_ACCESSTOKEN)}&refreshToken=${getCookie(SHOPBY_REFRESHTOKEN)}`,
  );

  window.location.href = `${url}/auth/token?key=${getCookie(SHOPBY_ACCESSTOKEN)}&refreshToken=${getCookie(
    SHOPBY_REFRESHTOKEN,
  )}`;
}

export const logout = once(async function logout() {
  try {
    await api.deleteAuthToken();
  } catch (e) {
    console.error(e);
  }
  sessionStorage.clear();

  deleteCookie(SHOPBY_ACCESSTOKEN);
  deleteCookie(SHOPBY_REFRESHTOKEN);
  deleteCookie(SESSION_INITIALIZE);
  deleteCookie(encodeURIComponent(encrypt(TOKEN_EXPIRE_TIME)));

  deleteCookie('adminInfo');
  deleteCookie('READYSHOP_SESS'); // 통합 로그인 기능으로 인해 샵바이 스탠다드의 쿠키 제거
  deleteCookie('READYSHOP_SESSAT');
  deleteCookie('READYSHOP_SESSRT');

  window.location.href = `${VUE_APP_ADMIN_REMOTE_URL}/logout?redirectUrl=${encodeURIComponent(
    `${VUE_APP_REDIRECT_URL}/auth`
  )}`;
});

export async function refreshToken(token?: string): Promise<boolean> {
  let success = false;
  let planType = 'PRO';

  const refreshToken = token ?? cookies.get(SHOPBY_REFRESHTOKEN);
  if (refreshToken && refreshToken.length) {
    const request: PostAuthTokenRequest = {
      data: {
        adminType: 'SERVICE',
        grantType: 'REFRESH_TOKEN',
        refreshToken,
      },
    };
    try {
      const { data } = await tokenApi.postAuthToken(request);

      setToken(data.accessToken, data.refreshToken, data.autoLogoutMin);

      planType = data.planType;
      success = true;
    } catch (e) {
      console.error(e);
      logout(); // refreshToken 요청 실패시 토큰 지우고 탈출
    }
  }

  // 토큰 갱신시 스탠다드 토큰인 경우에는 스탠다드 화면이로 이동하도록 예외처리 추가
  if (planType === 'STANDARD') {
    moveStandardAdmin();
    throw 'standard admin';
  }

  return success;
}

/**
 * API 호출 시 RT 만료시간을 로컬에서 연장함
 * RT 의 만료시간은 서버에서는 24시간이고, 프론트에서는 autoLogoutMin 를 사용하는 것이 보안정책
 * 최초 토큰발급 24시간 후에는 서버에서 만료 시킴
 */
export function refreshTokenCalledApi(): void {
  const refreshToken = cookies.get(SHOPBY_REFRESHTOKEN);
  const encryptedAutoLogoutMin = cookies.get(encodeURIComponent(encrypt(TOKEN_EXPIRE_TIME)));
  const autoLogoutMin = Number(encryptedAutoLogoutMin ? decrypt(encryptedAutoLogoutMin) : '0') || 0;

  if (!refreshToken || !refreshToken.length) {
    return;
  }

  cookies.set(SHOPBY_REFRESHTOKEN, refreshToken, { expires: (1.0 / 24 / 60) * autoLogoutMin }); // autoLogoutMin 분
}

export const logoutConsolidatedUser = () => {
  sessionStorage.clear();
  deleteCookie(SHOPBY_ACCESSTOKEN);
  deleteCookie(SHOPBY_REFRESHTOKEN);
  deleteCookie(SESSION_INITIALIZE);
  deleteCookie('adminInfo');

  const workspace = `${encodeURIComponent(`${VUE_APP_WORKSPACE_URL}/logout.html`)}`;
  const commerce = `${encodeURIComponent(`${VUE_APP_CONSOLIDATED_AUTH}/logout?continue=${VUE_APP_REDIRECT_URL}/auth`)}`;

  const commerceAccountLogoutUri = `${VUE_APP_ADMIN_REMOTE_URL}/logout?redirectUrl=${encodeURIComponent(
    `${VUE_APP_REDIRECT_URL}/logout.html?redirectUri=${workspace}?redirectUri=${commerce}`
  )}`;

  window.open(commerceAccountLogoutUri, '_self');
};
