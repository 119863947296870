


























import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { RadioBoxGroupOption } from '@/helpers/type';
import { Mall, YorN } from 'ncp-api-supporter';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { InputNumber } from '@/types';
import copy from 'copy-to-clipboard';
@Component({
  components: {
    ToolTip,
    RadioGroup,
  },
})
export default class UrlDirectDisplay extends Vue {
  @PropSync('urlDirectDisplayYn') private urlDirectDisplayYnSync!: YorN;
  @Prop() private readonly url: string;
  @Prop() private readonly mallNo!: InputNumber;
  @Prop() private readonly malls!: Mall[];
  @Prop({ required: true })
  private readonly disabled: boolean;
  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'uslDirectDisplayYn',
    data: [
      {
        id: 'useUrlN',
        name: 'uslDirectDisplayYn',
        value: 'N',
        display: 'PRODUCT.COMMON.NOT_USE',
      },
      {
        id: 'useUrlY',
        name: 'uslDirectDisplayYn',
        value: 'Y',
        display: 'PRODUCT.COMMON.USE',
      },
    ],
  };

  // 쇼핑몰 선택된 경우 해당 쇼핑몰 탭이 선택된 상태로 페이지를 출력하며, 미선택 시 가장 최근 등록된 쇼핑몰(df)이 선택된 상태로 출력
  private get toolTipMsg(): string {
    // const url = `${this.$router.resolve({ name: 'Sns' }).href}?mallNo=${this.defaultMallNo}`;
    return `<li>${this.$t('PRODUCT.ADD.USL_DIRECT_DISPLAY_TOOLTIP_1')}</li>`;
    // return `<li>${this.$t('PRODUCT.ADD.USL_DIRECT_DISPLAY_TOOLTIP_1')}</li>
    //         <li>${this.$t('PRODUCT.ADD.USL_DIRECT_DISPLAY_TOOLTIP_2')}
    //           (${this.$t(
    //   'PRODUCT.ADD.USL_DIRECT_DISPLAY_TOOLTIP_3',
    // )}<a href="javascript:onclick=window.open('${url}','configuration_url')">${this.$t(
    //   'PRODUCT.ADD.GO_DIRECT',
    // )}</a>)</li>`;
  }

  private get defaultMallNo(): number {
    return this.mallNo ? this.mallNo : this.malls[0].mallNo;
  }

  private copyUrl(): void {
    copy(this.url);
  }
}
