











import { Component, Mixins, Watch } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import MessageTemplateBox from '@/views/contents/member/sms/MessageTemplateBox.vue';
import { namespace } from 'vuex-class';
import SmsAutomationGetTemplateVue from '@/views/contents/member/sms/mixins/SmsAutomationGetTemplate.vue';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { throwPopup } from '@/helpers/popup';
import { isValidate } from '@/utils/validate';
import { messageTemplate } from '@/const/words/templateStrings';
import OpenAdNoticePopupMixin from '@/views/contents/member/sms/mixins/OpenAdNoticePopupMixin.vue';
import { NavButton } from '@/types/bottomNav';
import {
  GetNotificationsConfigurationsRequest,
  PutSmsTemplatesAutoTemplatesTemplateTypeRequest,
  NotificationsConfigurationsResponse,
} from 'ncp-api-supporter';
import { SmsAutoBottomNavKey } from '@/types/member';
import { getMallNo4Notification } from '@/utils/mall';

const member = namespace('member');

@Component({
  components: { MessageTemplateBox, NoticeBox },
})
export default class SmsAutomation extends Mixins(SmsAutomationGetTemplateVue, OpenAdNoticePopupMixin) {
  private readonly noticeList = [
    'MEMBER.SMS.AUTO_SEND_NOTICE_1',
    'MEMBER.SMS.AUTO_SEND_NOTICE_2',
    'MEMBER.SMS.DISPATCH_NOTICE_3',
    'MEMBER.SMS.AUTO_SEND_NOTICE_4',
    'MEMBER.SMS.AUTO_SEND_NOTICE_5',
    'MEMBER.SMS.AUTO_SEND_NOTICE_6',
    'MEMBER.SMS.AUTO_SEND_NOTICE_7',
    'MEMBER.SMS.AUTO_SEND_NOTICE_8',
  ];

  @member.Getter('getData')
  private readonly autoSmsRequestData!: PutSmsTemplatesAutoTemplatesTemplateTypeRequest[];
  @member.Action('resetSmsTemplateSetting')
  private readonly resetSmsTemplateSetting!: () => void;

  private buttonList: NavButton[] = [
    {
      type: 'left',
      key: 'autoExchange',
      color: 'white',
      text: this.$t('MEMBER.SMS_AUTO.BTN_HELP'),
    },
    { type: 'right', key: 'autoSave', color: 'red', text: this.$t('SAVE') },
  ];

  @Watch('$route.query')
  private updateLatestData() {
    this.getTemplateGroups();
    this.generateBottomNav();
  }

  private autoExchange() {
    throwPopup({
      name: 'ReplacementTextList',
      data: { template: messageTemplate(), noticeList: [this.$t('MEMBER.SMS_AUTO.INTERCHANGE_GUIDE')] },
    });
  }

  private async availableSendSms(): Promise<boolean> {
    const data = await this.fetchMallsConfig();
    const isUsageSms = isValidate(data.smsUsed, this.$t('MEMBER.SMS.MUST_USAGE'));

    if (!isUsageSms) return false;
    return isValidate(data.smsSendno.length, this.$t('MEMBER.SMS.MUST_REGISTER_CALLING_NUMBER'));
  }

  private async validateSms(): Promise<boolean> {
    if (!(await this.availableSendSms())) return false;
    return this.autoSmsRequestData.every(data => isValidate(data.data.content, this.$t('MEMBER.SMS_AUTO.WARN_NULL')));
  }

  private async autoSave() {
    if (!(await this.validateSms())) return;

    const promises = this.autoSmsRequestData.map(data => this.$api.putSmsTemplatesAutoTemplatesTemplateType(data));
    try {
      await Promise.all(promises);
      alert(this.$t('MEMBER.SMS_AUTO.SUCCESS_SEND'));
    } catch (e) {
      if (e.status !== 403) {
        alert(this.$t(e.message));
      }
    }
    this.resetSmsTemplateSetting();
    this.updateLatestData();
  }

  private onClickBottomNav(key: SmsAutoBottomNavKey) {
    switch (key) {
      case 'autoExchange':
        this.autoExchange();
        break;
      case 'autoSave':
        this.autoSave();
        break;
      default:
        break;
    }
  }

  private generateBottomNav() {
    throwBottomNavigation({
      buttons: this.buttonList,
      onClick: (key: SmsAutoBottomNavKey) => this.onClickBottomNav(key),
    });
  }

  public async fetchMallsConfig(): Promise<NotificationsConfigurationsResponse> {
    const request: GetNotificationsConfigurationsRequest = {
      params: {
        mallNo: getMallNo4Notification(this),
      },
    };
    const { data } = await this.$api.getNotificationsConfigurations(request);
    return data;
  }

  created() {
    this.updateLatestData();
  }
}
