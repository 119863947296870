



























































































































































import { Component, Mixins } from 'vue-property-decorator';
import {
  isCancelExchange,
  isExchangeProcBeforeReceive,
  isExchangeProcRequestPay,
  isExchangeProcRequestRefund,
  isExchangeProcWaiting,
  isExchangeRequest,
  isExchangeRejectRequest,
  isReturnExchange,
} from '@/components/popup/claim/claimConditions';
import { getDefaultForm } from '@/components/popup/claim/claimOptions';
import { ClaimPopupForm } from '@/types/claim';
import { claimClassTypes, claimTypes } from '@/const/claim';
import ClaimPopupMixin from '@/components/popup/claim/ClaimPopupMixin';
import NoLocalStorageWindowPopup from '@/views/popups/NoLocalStorageWindowPopup.vue';
import NoticeBox from '@/components/popup/claim/ClaimPopup/NoticeBox.vue';
import ReturnWithdrawReason from '@/components/popup/claim/ClaimPopup/ReturnWithdrawReason.vue';
import ClaimProducts from '@/components/popup/claim/ClaimPopup/ClaimProducts';
import ClaimInformation from '@/components/popup/claim/ClaimPopup/ClaimInformation.vue';
import ExchangeRelease from '@/components/popup/claim/ClaimPopup/Exchange/ExchangeRelease.vue';
import AmountDetail from '@/components/popup/claim/ClaimPopup/Exchange/AmountDetail.vue';
import ReturnDeliveryAmount from '@/components/popup/claim/ClaimPopup/ReturnDeliveryAmount.vue';
import DeliveryRecalculate from '@/components/popup/claim/ClaimPopup/DeliveryRecalculate.vue';
import ExchangeProcessAmount from '@/components/popup/claim/ClaimPopup/Exchange/ExchangeProcessAmount.vue';
import BenefitRecalculate from '@/components/popup/claim/BenefitRecalculate.vue';
import NaverPayAmountDetail from '@/components/popup/claim/NaverPayAmountDetail.vue';
import { PopupClose, PopupResult, throwPopup, throwWindowPopup } from '@/helpers/popup';
import { PutCancelExchangesNoRequest, PutReturnExchangesNoRequest } from 'ncp-api-supporter';
import { AdditionalPayType, ReasonType } from 'ncp-api-supporter/dist/types/enum';
import { AddressInfo } from 'ncp-api-supporter/dist/types/modules/claim/cancelExchanges';

// 교환리스트 기획서 p.40
@Component({
  components: {
    NoticeBox,
    ReturnWithdrawReason,
    ClaimProducts,
    ClaimInformation,
    ExchangeRelease,
    AmountDetail,
    ReturnDeliveryAmount,
    ExchangeProcessAmount,
    BenefitRecalculate,
    DeliveryRecalculate,
    NaverPayAmountDetail,
  },
  computed: {
    isCancelExchange,
    isReturnExchange,
    isExchangeRequest,
    isExchangeRejectRequest,
    isExchangeProcWaiting,
    isExchangeProcRequestPay,
    isExchangeProcRequestRefund,
    isExchangeProcBeforeReceive,
    isNaverPayClaim(): boolean {
      return this.claimNoResponse.originalPayType === 'NAVER_PAY';
    },
    hasWitdhrawReason() {
      return this.claimNoResponse.withdrawReason !== null;
    },
    showWithdrawalButton() {
      return (
        this.isExchangeRequest ||
        this.isExchangeProcWaiting ||
        this.isExchangeProcRequestPay ||
        this.isExchangeProcBeforeReceive ||
        this.isExchangeProcRequestRefund
      );
    },
    showWithdrawApproveButton() {
      return this.isExchangeRejectRequest;
    },
    showApproveButton() {
      return this.isExchangeRequest;
    },
    showDepositConfirmButton() {
      return this.isExchangeProcRequestPay;
    },
    showRefundButton() {
      return this.isExchangeProcRequestRefund;
    },
    showNaverPayWithHoldButton(): boolean {
      if (!this.isNaverPayClaim) {
        return false;
      }

      return this.isExchangeProcWaiting;
    },
    showNaverPayWithHoldCancelButton(): boolean {
      if (!this.isNaverPayClaim) {
        return false;
      }

      return this.isExchangeProcWaiting;
    },
    showNaverPayAmountDetail() {
      return this.isNaverPayClaim && !!this.claimNoResponse.externalData?.naver;
    },
  },
})
export default class ExchangePopup extends Mixins(ClaimPopupMixin, NoLocalStorageWindowPopup) {
  private formData: ClaimPopupForm = getDefaultForm();

  async created() {
    await this.fetchClaimNo();
    this.setDefaultForm();
  }

  private setDefaultForm() {
    this.formData = getDefaultForm(this.claimNoResponse);
  }

  // TODO: 기획서 p.28 교환신청철회 버튼 2개 있음. 처리가능여부, 얼럿도 다름
  private async onWithdraw(): Promise<void> {
    const { validationType, afterClaimNos } = await this.checkWithdraw();

    const { state }: PopupResult = await throwPopup({
      name: 'WithdrawMessage',
      data: {
        claimType: this.claimType,
        validationType,
        afterClaimNos,
      },
    });

    if (state !== PopupClose.CONFIRM) {
      return;
    }

    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
    };

    const withdrawApi = {
      [claimClassTypes.CANCEL_EXCHANGE]: () => this.$api.putCancelExchangesNoWithdraw(request),
      [claimClassTypes.RETURN_EXCHANGE]: () => this.$api.putReturnExchangesNoWithdraw(request),
    };

    await withdrawApi[this.claimClassType]();
    alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
    this.onPositiveClick();
  }

  private onExchangeWithdrawApprove(): void {
    if (!confirm(this.$t('CLAIM.EXCHANGEPOPUP.ASK_EXCHANGE_WITHDRAW_APPROVE').toString())) {
      return;
    }

    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
    };

    this.$api.putReturnExchangesNoApproveWithdraw(request).then(() => {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.PROCESS_SUCCESS_MESSAGE'));
      this.onPositiveClick();
    });
  }

  private onRefund(): void {
    if (!this.validateNaverPayClaim()) {
      return;
    }

    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_REFUND_PROCESS').toString())) {
      return;
    }

    const { refundType, refundBankAccount } = this.formData;
    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
      data: {
        refundBankAccount:
          refundType === 'ACCOUNT'
            ? refundBankAccount
            : {
                bankName: null,
                depositorName: null,
                account: null,
                bank: null,
              },
      },
    };

    const refundApis = {
      [claimClassTypes.CANCEL_EXCHANGE]: () => this.$api.putCancelExchangesNoCashRefund(request),
      [claimClassTypes.RETURN_EXCHANGE]: () => this.$api.putReturnExchangesNoCashRefund(request),
    };

    refundApis[this.claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }

  private onApprove(): void {
    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_EXCHANGE_ACCEPT').toString())) {
      return;
    }

    const { invoiceNo, deliveryCompanyType, evadesReturnProcess } = this.formData.returnDelivery;
    const request = { pathParams: { no: this.$route.query.claimNo.toString() } };
    const data = {
      invoiceNo: invoiceNo?.length ? invoiceNo : null,
      deliveryCompanyType: deliveryCompanyType.length ? deliveryCompanyType : null,
      evadesReturnProcess,
    };

    const refundApis = {
      [claimClassTypes.CANCEL_EXCHANGE]: () => this.$api.putCancelExchangesNoApprove(request),
      [claimClassTypes.RETURN_EXCHANGE]: () => this.$api.putReturnExchangesNoApprove({ ...request, data }),
    };

    refundApis[this.claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }

  private onConfirmDeposit(): void {
    if (!this.validateNaverPayClaim()) {
      return;
    }

    if (this.formData.additionalPayRemitter.trim().length === 0) {
      alert(this.$t('CLAIM.MESSAGE.VALID_DEPOSITOR_NAME'));
      return;
    }

    if (!confirm(this.$t('CLAIM.MESSAGE.CONFIRM_DEPOSIT').toString())) {
      return;
    }

    const { additionalPayBankAccount, additionalPayRemitter } = this.formData;
    const request = {
      pathParams: {
        no: this.$route.query.claimNo.toString(),
      },
      data: {
        additionalPayBankAccount,
        additionalPayRemitter,
      },
    };

    const confirmApi = {
      [claimClassTypes.CANCEL_EXCHANGE]: () => this.$api.putCancelExchangesNoCongirmDeposit(request),
      [claimClassTypes.RETURN_EXCHANGE]: () => this.$api.putReturnExchangesNoConfirmDeposit(request),
    };

    confirmApi[this.claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
      this.onPositiveClick();
    });
  }

  // TODO: 교환 수정 문의중 https://nhnent.dooray.com/project/posts/3018336869532409171
  private onPutClaim(): Promise<void> {
    const {
      responsibleObjectType,
      refundType,
      sellerPaysClaimedDelivery,
      additionalPayRemitter,
      additionalPayBankAccount,
      additionalPayType,
      refundBankAccount,
      returnWayType,
      returnDeliveryProposedAmt,
    } = this.formData;

    // TODO: 취소교환 PUT request, 반품교환 API 안정화 후 PUT request 정리 필요
    const cancelExchangeRequest: PutCancelExchangesNoRequest = {
      pathParams: { no: this.claimNoResponse.no.toString() },
      data: {
        sellerPaysClaimedDelivery,
        responsibleObjectType,
        refundType,
        additionalPayRemitter: additionalPayRemitter.length === 0 ? null : additionalPayRemitter, // 추가결제시만 (nullable)
        additionalPayBankAccount, // 추가결제시만 (nullable)
        reasonType: this.claimNoResponse.claimReasonType as ReasonType,
        reasonDetail: this.claimNoResponse.claimReasonDetail,
        additionalPayType: additionalPayType as AdditionalPayType,
        productAdjustAmt: this.claimNoResponse.exchangeDifferenceAdjustAmt ?? 0,
        refundBankAccount:
          refundType === 'ACCOUNT'
            ? refundBankAccount
            : {
                bankName: null,
                depositorName: null,
                account: null,
                bank: null,
              },
      },
    };

    //TODO: 반품 교환 변경 확인필요 https://nhnent.dooray.com/project/posts/3018336869532409171
    const returnExchangeRequest: PutReturnExchangesNoRequest = {
      pathParams: {
        no: this.claimNoResponse.no.toString(),
      },
      data: {
        responsibleObjectType,
        reasonType: this.claimNoResponse.claimReasonType as ReasonType,
        reasonDetail: this.claimNoResponse.claimReasonDetail,
        additionalPayRemitter: additionalPayRemitter.length === 0 ? null : additionalPayRemitter, // 추가결제시만 (nullable)
        additionalPayBankAccount,
        sellerPaysClaimedDelivery,
        returnWayType,
        productAdjustAmt: 0, // 이게 왜 supporter 타입이 0이지..?
        exchangeDeliveryProposedAmt: this.claimNoResponse.exchangeDeliveryAdjustAmt,
        exchangeDeliveryAdjustReason: this.claimNoResponse.exchangeDeliveryAmtAdjustReason,
        returnAddress: (this.claimNoResponse.returnAddress as AddressInfo) ?? null,
        returnExchangeImages: this.claimNoResponse.claimImageUrls,
        refundType,
        returnDeliveryProposedAmt,
        additionalPayType: additionalPayType as AdditionalPayType,
        exchangeAddress: this.claimNoResponse.claimData.exchangeShipping?.address ?? null,
        returnDeliveryAdjustReason: this.claimNoResponse.returnDeliveryAmtAdjustReason,
        invoiceNo: this.formData.returnDelivery.invoiceNo,
        refundBankAccount:
          refundType === 'ACCOUNT'
            ? refundBankAccount
            : {
                bankName: null,
                depositorName: null,
                account: null,
                bank: null,
              },
      },
    };

    const putClaim = {
      [claimClassTypes.CANCEL_EXCHANGE]: () => this.$api.putCancelExchangesNo(cancelExchangeRequest),
      [claimClassTypes.RETURN_EXCHANGE]: () => this.$api.putReturnExchangesNo(returnExchangeRequest),
    };

    return putClaim[this.claimClassType]().then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_SAVE_MODIFY'));
    });
  }

  private openNaverPayReturnHold() {
    throwWindowPopup(
      'NaverPayReturnHold',
      { claimNo: String(this.$route.query.claimNo), claimType: claimTypes.EXCHANGE },
      'lg',
      result => {
        if (result.state === 'close') return;

        location.reload();
      },
    );
  }

  private onNaverPayReleaseHold() {
    if (!confirm(this.$t('CLAIM.MESSAGE.ASK_EXCHANGE_RELEASE_HOLD').toString())) {
      return false;
    }

    const request = {
      pathParams: {
        no: String(this.$route.query.claimNo),
      },
    };

    this.$api.putClaimsNoNaverPayExchangesReleaseHold(request).then(() => {
      alert(this.$t('CLAIM.MESSAGE.COMPLETE_REQUEST'));
      location.reload();
    });
  }
}
