export default Object.freeze({
  //파트너 등록
  LOGIN_TITLE: '파트너 등록',
  LOGIN_PLEASE_SELECT_METHOD: '파트너 등록 방법을 선택해 주세요.',
  LOGIN_NEW_TITLE: '파트너 신규등록',
  LOGIN_NEW_CONTENT1: 'shop by를 처음 사용하는',
  LOGIN_NEW_CONTENT2: '새로운 파트너사를 등록합니다.',
  LOGIN_POOL_CONTRACT_TITLE: '파트너 검색/등록',
  LOGIN_POOL_CONTRACT_CONTENT1: '현재 shop by를 사용하고 있는',
  LOGIN_POOL_CONTRACT_CONTENT2: '파트너사를 검색하여 등록 합니다.',
  LOGIN_POOL_COPY_TITLE: '파트너 복사등록',
  LOGIN_POOL_COPY_CONTENT1: 'shop by를 사용중인',
  LOGIN_POOL_COPY_CONTENT2: '파트너사의 추가계정을 등록합니다.',
});
