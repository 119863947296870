import { i18n } from '@/main';
// apis
import {
  putContractsContractNoApprovalRequest,
  putContractsContractNoDisapprovalRequest,
} from '@/views/contents/partner/modification/apis/apiRequests';
import { api } from '@/api';
// types
import tuiGrid, { RowKey } from '@/types/tui-grid';
import { Contracts, PartnerStatus } from 'ncp-api-supporter';
import { PartnerRowData } from '@/views/contents/partner/modification/PartnerModificationTypes';
// requests
import {
  getContractsRequest,
  postPartnersPartnerNoResendEmailRequest,
} from '@/views/contents/partner/modification/apis/apiRequests';

const getNewContract = (newContracts: Contracts[], contractNo: number) => {
  const findSameRow = (newContract: Contracts) => newContract.contractNo === contractNo;
  return newContracts.find(findSameRow);
};

export const getHandleApproval = (grid: tuiGrid, rowKey: RowKey) => {
  return async (event: Event) => {
    event.preventDefault();

    const willApprove = confirm(i18n.t('PARTNER.EDIT.APPROVAL_NOTICE_MSG').toString());
    if (willApprove) {
      const queryString = new URLSearchParams(window.location.search);
      const partnerStatus = queryString.get('partnerStatus') as PartnerStatus;
      const contractNo = grid.getValue<PartnerRowData, 'contractNo'>(rowKey, 'contractNo');
      const partnerNo = grid.getValue<PartnerRowData, 'partnerNo'>(rowKey, 'partnerNo');

      const approvalRequest = putContractsContractNoApprovalRequest(contractNo.toString());
      await api.putContractsContractNoApprovalRequest(approvalRequest);

      const { data: contracts } = await api.getContracts(getContractsRequest(partnerNo));
      const newContract = contracts.contents.find((newContract: Contracts) => newContract.contractNo === contractNo);

      grid.setRow<Contracts>(rowKey, newContract);

      alert(i18n.t('PARTNER.EDIT.APPROVAL_MSG'));

      if (partnerStatus === 'INVESTIGATION') {
        window.location.href = `/pro/configuration/partner/modification/${partnerNo}?partnerStatus=ACTIVE`;
      }
    }
  };
};

export const getHandleDisApproval = (grid: tuiGrid, rowKey: RowKey) => {
  return async (event: Event) => {
    event.preventDefault();

    const willDisapprove = confirm(i18n.t('PARTNER.EDIT.DISAPPROVAL_NOTICE_MSG').toString());
    if (willDisapprove) {
      const queryString = new URLSearchParams(window.location.search);
      const partnerStatus = queryString.get('partnerStatus') as PartnerStatus;
      const contractNo = grid.getValue<PartnerRowData, 'contractNo'>(rowKey, 'contractNo');
      const partnerNo = grid.getValue<PartnerRowData, 'partnerNo'>(rowKey, 'partnerNo');

      const disApprovalRequest = putContractsContractNoDisapprovalRequest(contractNo.toString());

      await api.putContractsContractNoDisapprovalRequest(disApprovalRequest);
      const { data: contracts } = await api.getContracts(getContractsRequest(partnerNo));

      grid.setRow<Contracts>(rowKey, getNewContract(contracts.contents, contractNo));

      alert(i18n.t('PARTNER.EDIT.DISAPPROVAL_MSG'));

      if (partnerStatus === 'INVESTIGATION') {
        window.location.href = `pro/configuration/partner/modification/${partnerNo}?partnerStatus=WAITING`;
      }
    }
  };
};

export const resendEmail = async (partnerNo: string, contractNo?: number) => {
  const request = postPartnersPartnerNoResendEmailRequest(partnerNo, contractNo);
  const { status } = await api.postPartnersPartnerNoResendEmail(request);

  status === 204 && alert(i18n.t('PARTNER.EDIT.NOTICE_RESEND_EMAIL'));
};
