













































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { responsibleObjectTypes, returnWayTypes } from '@/const/claim';
import { ClaimClassType, ResponsibleObjectType, ReturnWayType } from '@/types/claim';
import { Amounts, Shipping } from 'ncp-api-supporter';
import { isReturnExchange } from '@/components/popup/claim/claimConditions';
import AmountMixin from '@/components/popup/claim/AmountMixin';

// 반품상품 배송비상세 및 조정
// 프리미엄과 다르게, 처리내역팝업에선 반품배송비 금액조정이 없음
@Component({
  computed: {
    isReturnExchange,
    isSellerResponsible() {
      return this.responsibleObjectType === responsibleObjectTypes.SELLER;
    },
    isBuyerCollect() {
      return this.returnWayType === returnWayTypes.BUYER_DIRECT_RETURN;
    },
    lineThrough() {
      return this.isSellerResponsible ? 'line-through' : 'none';
    },
    adjustAmountInputDisabled() {
      return this.isSellerResponsible || this.isBuyerCollect || !this.returnDelivery.prepaid;
    },
  },
})
export default class ReturnDeliveryAmount extends Mixins(AmountMixin) {
  @Prop({ required: false, default: null })
  private readonly claimClassType: null | ClaimClassType;

  @Prop({ required: true })
  private readonly amounts!: Amounts;

  @Prop({ required: true })
  private readonly shipping!: Shipping;

  @Prop({ required: true }) // TODO: 반품수거방법이 변경될때마다 predict 호출 후 바뀜, 근데 프로기획서에선 신청 이후 변경 불가라고함.
  private readonly returnWayType!: ReturnWayType;

  @Prop({ required: true })
  private readonly responsibleObjectType!: ResponsibleObjectType;

  @Prop({ required: false, default: '' })
  private readonly exchangeDeliveryAmtAdjustReason!: null | string;

  @PropSync('returnDeliveryAdjustAmt', { required: true })
  private returnDeliveryAdjustAmtSync!: string;

  @PropSync('returnDeliveryAmtAdjustReason', { required: true })
  private returnDeliveryAmtAdjustReasonSync!: string;

  // 반품배송비 row
  private returnDelivery = {
    returnDeliveryAmt: 0, // 기본배송비
    returnRemoteDeliveryAmt: 0, // 지역별추가
    returnDeliveryAdjustAmt: 0, // 금액조정(선택)
    returnDeliveryAmtAdjustReason: '', // 조정사유
    prepaid: false,
  };

  // 교환(재발송) 배송비 row
  private exchangeDelivery = {
    deliveryAmt: 0, // 기본배송비
    remoteDeliveryAmt: 0, // 지역별추가배송비
    exchangeDeliveryAdjustAmt: 0, // 금액조정(선택)
    exchangeDeliveryAmtAdjustReason: '', // 조정사유
  };

  // 배송비합계 (b) row
  private totalDelivery = {
    totalDeliveryAmt: 0,
  };

  @Watch('amounts')
  recalculateAmounts() {
    this.setReturnDelivery();
    this.setExchangeDelivery();
    this.setTotalDelivery();
  }

  created() {
    this.setReturnDelivery();
    this.setExchangeDelivery();
    this.setTotalDelivery();
  }

  private setReturnDelivery() {
    const { returnDeliveryAmt, returnRemoteDeliveryAmt } = this.amounts.returnDelivery;
    const prepaid = this.shipping.prepaid;

    this.returnDelivery = {
      prepaid,
      returnDeliveryAmt,
      returnRemoteDeliveryAmt,
      returnDeliveryAdjustAmt: Number(this.returnDeliveryAdjustAmtSync), // returnDeliveryAdjustAmt가 set되지 않는 문제가 있음
      returnDeliveryAmtAdjustReason: this.returnDeliveryAmtAdjustReasonSync, // returnDeliveryAmtAdjustReasonSync set되지 않는 문제가 있음
    };
  }

  private setExchangeDelivery() {
    if (this.amounts.exchangeDelivery === undefined) {
      return;
    }

    const { deliveryAmt, remoteDeliveryAmt } = this.amounts.exchangeDelivery;
    const exchangeDeliveryAdjustAmt = this.amounts.exchangeDeliveryAdjustAmt;

    this.exchangeDelivery = {
      deliveryAmt,
      remoteDeliveryAmt,
      exchangeDeliveryAdjustAmt,
      exchangeDeliveryAmtAdjustReason: this.exchangeDeliveryAmtAdjustReason,
    };
  }

  private setTotalDelivery() {
    const isExchangeClaim = this.claimClassType !== null;
    const { prepaidReturnDeliveryAmt, prepaidReturnRemoteDeliveryAmt } = this.amounts.returnDelivery;
    const returnDeliveryAdjustAmt = this.amounts.returnDeliveryAdjustAmt;
    const isPrepaid = this.shipping.prepaid;

    // 교환시 수식
    if (isExchangeClaim) {
      const {
        prepaidDeliveryAmt: prepaidExchangeDeliveryAmt,
        prepaidRemoteDeliveryAmt: prepaidExchangeRemoteDeliveryAmt,
      } = this.amounts.exchangeDelivery;
      const exchangeDeliveryAdjustAmt = this.amounts.exchangeDeliveryAdjustAmt;

      this.totalDelivery.totalDeliveryAmt =
        prepaidReturnDeliveryAmt +
        prepaidReturnRemoteDeliveryAmt -
        (isPrepaid ? returnDeliveryAdjustAmt : 0) +
        prepaidExchangeDeliveryAmt +
        prepaidExchangeRemoteDeliveryAmt -
        (isPrepaid ? exchangeDeliveryAdjustAmt : 0);
      return;
    }

    // 취소, 반품시 수식
    this.totalDelivery.totalDeliveryAmt =
      prepaidReturnDeliveryAmt + prepaidReturnRemoteDeliveryAmt - (isPrepaid ? returnDeliveryAdjustAmt : 0);
  }
}
