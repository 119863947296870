import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
// import CouponList from '@/views/contents/promotion/basic/CouponList.vue';
// import CouponDetail from '@/views/contents/promotion/basic/CouponDetail.vue';
// import CouponManagement from '@/views/contents/promotion/basic/CouponManagement.vue';
import CouponManagementIframe from '@/views/contents/promotion/basic/CouponManagementIframe.vue';

// import CouponList from '@/views/contents/promotion/basic/CouponList.vue';
import CouponIssueIframe from '@/views/contents/promotion/basic/CouponIssueIframe.vue';
// import CouponDetail from '@/views/contents/promotion/basic/CouponDetail.vue';
// import CouponManagement from '@/views/contents/promotion/basic/CouponManagement.vue';
import AdminIframeContent from '@/views/contents/AdminIframeContent.vue';
// import FreeGiftConfigurationIframe from '@/views/contents/promotion/free-gift/ConfigurationIframe.vue';
// import FreeGiftConditionListIframe from '@/views/contents/promotion/free-gift/ConditionListIframe.vue';
// import FreeGiftConditionAddIframe from '@/views/contents/promotion/free-gift/ConditionAddIframe.vue';
// import FreeGiftConditionEditIframe from '@/views/contents/promotion/free-gift/ConditionEditIframe.vue';

import AdditionalDiscountManagement from '@/views/contents/promotion/additionalDiscount/ManagementIframe.vue';
import AdditionalDiscountRegistrationIframe from '@/views/contents/promotion/additionalDiscount/RegistrationIframe.vue';
import AdditionalDiscountModificationIframe from '@/views/contents/promotion/additionalDiscount/ModificationIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/promotion',
    name: 'Promotion',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/coupons',
        // name: 'CouponList',
        // component: CouponList,
        name: 'CouponListIframe',
        component: CouponIssueIframe,
        meta: {
          excelCreateMenuKey: 'PRO_COUPON_LIST',
        },
      },
      {
        path: 'basic/coupons/add',
        name: 'AddCouponIframe',
        component: CouponIssueIframe,
        // name: 'AddCoupon',
        // component: CouponDetail,
        meta: {
          breadcrumb: ['프로모션', '혜택 관리', '쿠폰 관리', '쿠폰 등록'],
        },
      },
      {
        path: 'basic/coupons/modify',
        name: 'ModifyCouponIframe',
        component: CouponIssueIframe,
        // name: 'ModifyCoupon',
        // component: CouponDetail,
        meta: {
          breadcrumb: ['프로모션', '혜택 관리', '쿠폰 관리', '쿠폰 수정'],
        },
      },
      {
        path: 'basic/coupons/copy',
        name: 'CopyCouponIframe',
        component: CouponIssueIframe,
        // name: 'CopyCoupon',
        // component: CouponDetail,
        meta: {
          breadcrumb: ['프로모션', '혜택 관리', '쿠폰 관리', '쿠폰 등록'],
        },
      },
      {
        path: 'basic/coupon',
        name: 'CouponManagementIframe',
        component: CouponManagementIframe,
        // name: 'CouponManagement',
        // component: CouponManagement,
      },

      // NOTE: 추가할인

      {
        path: 'additional-discount/management',
        name: 'AdditionalDiscountManagement',
        component: AdditionalDiscountManagement,
      },

      {
        path: 'additional-discount/management/registration',
        name: 'AdditionalDiscountRegistrationIframe',
        component: AdditionalDiscountRegistrationIframe,
        meta: {
          breadcrumb: ['프로모션', '추가할인 관리', '추가할인 등록'],
        },
      },

      {
        path: 'additional-discount/management/modification',
        name: 'AdditionalDiscountModificationIframe',
        component: AdditionalDiscountModificationIframe,
        meta: {
          breadcrumb: ['프로모션', '추가할인 관리', '추가할인 수정'],
        },
      },

      {
        path: 'event/list',
        name: 'EventList',
        component: AdminIframeContent,
        meta: {
          breadcrumb: ['프로모션', '기획전 관리', '기획전 관리'],
        },
      },
      {
        path: 'event/add',
        name: 'EventAdd',
        component: AdminIframeContent,
        meta: {
          breadcrumb: ['프로모션', '기획전 관리', '기획전 등록'],
        },
      },
      {
        path: 'event/edit',
        name: 'EventEdit',
        component: AdminIframeContent,
        meta: {
          breadcrumb: ['프로모션', '기획전 관리', '기획전 수정'],
        },
      },
      {
        path: 'event/copy',
        name: 'EventCopy',
        component: AdminIframeContent,
        meta: {
          breadcrumb: ['프로모션', '기획전 관리', '기획전 복사'],
        },
      },
      // {
      //   path: 'free-gift/configuration',
      //   name: 'FreeGiftConfiguration',
      //   component: FreeGiftConfigurationIframe,
      // },
      // {
      //   path: 'free-gift/condition-list',
      //   name: 'FreeGiftConditionList',
      //   component: FreeGiftConditionListIframe,
      // },
      // {
      //   path: 'free-gift/condition-add',
      //   name: 'FreeGiftConditionAdd',
      //   component: FreeGiftConditionAddIframe,
      // },
      // {
      //   path: 'free-gift/condition-edit/:givConditionNo',
      //   name: 'FreeGiftConditionEdit',
      //   component: FreeGiftConditionEditIframe,
      // },
    ],
  };
};
