import { buttonRenderer, selectRenderer, warpHeaderTit } from '@/utils/grid/rendererUtils';
import $el from '@/utils/element';
import { Row } from '@/types/tui-grid';
import { OptionData } from '@/helpers/type';
import { DesignPopupListQuery, DesignPopupListRouterQuery } from '@/types/designPopup';
import { langMap } from '@/components/common/summernote/Summernote';
import Cookies from 'js-cookie';
import { I18N_LOCALE } from '../cookie';
import { SummernoteOption } from '@/types/summernote/summernote';

export const getDesignPopupEditorOptions = (): SummernoteOption => ({
  lang: langMap[Cookies.get(I18N_LOCALE)],
  placeholder: '',
  width: 768,
  height: 300,
  tabsize: 2,
  noHideDimmed: false,
  fontSize: 12,
  imageFileSize: 5120,
  useVideo: false,
  disableDragAndDrop: false,
  insertList: ['link', 'picture'],
  disableImageUpload: false,
  focus: true,
  toolbar: [
    ['fontsize', ['fontsize']],
    ['font', ['bold', 'italic', 'underline', 'strikethrough']],
    ['color', ['color']],
    ['para', ['paragraph']],
    ['table', ['table']],
    ['insert', ['link', 'picture']],
    ['view', ['codeview']],
  ],
  codeviewFilter: false,
  minHeight: 120,
});

export const getPcScreenTypeOptions = () => {
  return {
    name: 'pcScreenTypeOption',
    data: [
      {
        id: 'pcScreenTypeFixed',
        value: 'FIXED',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_FIXED',
      }, //고정 레이어창
      {
        id: 'pcScreenTypeFixedTop',
        value: 'FIXED_TOP',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_FIXED_TOP',
      }, //상단 고정 레이어창
      {
        id: 'pcScreenTypeLayer',
        value: 'LAYER',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_LAYER',
      }, //이동 레이어창
      {
        id: 'pcScreenTypeWindow',
        value: 'WINDOW',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_WINDOW',
      }, //윈도우 팝업창
    ],
  };
};

export const getPcScreenTypeMultiOptions = () => {
  return {
    name: 'pcScreenTypeOption',
    data: [
      {
        id: 'pcScreenTypeFixed',
        value: 'FIXED',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_MULTI_PC_FIXED',
      }, //고정 레이어창
      {
        id: 'pcScreenTypeLayer',
        value: 'LAYER',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_MULTI_PC_LAYER',
      }, //이동 레이어창
      {
        id: 'pcScreenTypeWindow',
        value: 'WINDOW',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_MULTI_PC_WINDOW',
      }, //윈도우 팝업창
    ],
  };
};

export const getMobileScreenTypeOptions = () => {
  return {
    name: 'mobileScreenTypeOption',
    data: [
      {
        id: 'mobileScreenTypeFixed',
        value: 'FIXED',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_MOBILE_FIXED',
      }, //고정 레이어창
      {
        id: 'mobileScreenTypeFixedTop',
        value: 'FIXED_TOP',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_MOBILE_FIXED_TOP',
      }, //상단 고정 레이어창
    ],
  };
};

export const getMobileScreenTypeMultiOptions = () => {
  return {
    name: 'mobileScreenTypeOption',
    data: [
      {
        id: 'mobileScreenTypeFixed',
        value: 'FIXED',
        display: 'DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_MULTI_MOBILE_FIXED',
      }, //고정 레이어창
    ],
  };
};

export const getDisplayableOptions = () => {
  return {
    name: 'displayableOption',
    data: [
      { id: 'displayableTrue', value: true, display: 'DISPLAY.DESIGN_POPUP.DISPLAYABLE_LABEL_TRUE' },
      { id: 'displayableFalse', value: false, display: 'DISPLAY.DESIGN_POPUP.DISPLAYABLE_LABEL_FALSE' },
    ],
  };
};

export const getDisplayPeriodTypeOptions = () => {
  return {
    name: 'displayPeriodTypeOption',
    data: [
      {
        id: 'displayPeriodTypeAlways',
        value: 'ALWAYS',
        display: 'DISPLAY.DESIGN_POPUP.DISPLAY_PERIOD_TYPE_LABEL_ALWAYS',
      },
      {
        id: 'displayablePeriod',
        value: 'PERIOD',
        display: 'DISPLAY.DESIGN_POPUP.DISPLAY_PERIOD_TYPE_LABEL_PERIOD',
      },
      {
        id: 'displayableTime',
        value: 'TIME',
        display: 'DISPLAY.DESIGN_POPUP.DISPLAY_PERIOD_TYPE_LABEL_TIME',
      },
    ],
  };
};

export const getPopupDesignTypeOptions = () => {
  return {
    name: 'popupDesignTypeOption',
    data: [
      {
        id: 'popupDesignTypeNormal',
        value: 'NORMAL',
        display: 'DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE_LABEL_NORMAL',
      },
      {
        id: 'popupDesignTypeMulti',
        value: 'MULTI',
        display: 'DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE_LABEL_MULTI',
      },
    ],
  };
};

export const getUnitOptions = (): OptionData<string>[] => {
  return [
    { name: 'Pixel', value: 'PIXEL' },
    { name: '%', value: 'PERCENT' },
  ];
};

export const getSlideDirectionOptions = (): OptionData<string>[] => {
  return [
    { name: 'DISPLAY.DESIGN_POPUP.SLIDE_DIRECTION_FIXED', value: 'FIXED' },
    { name: 'DISPLAY.DESIGN_POPUP.SLIDE_DIRECTION_RIGHT', value: 'RIGHT' },
    { name: 'DISPLAY.DESIGN_POPUP.SLIDE_DIRECTION_LEFT', value: 'LEFT' },
    { name: 'DISPLAY.DESIGN_POPUP.SLIDE_DIRECTION_UP', value: 'UP' },
    { name: 'DISPLAY.DESIGN_POPUP.SLIDE_DIRECTION_DOWN', value: 'DOWN' },
  ];
};

export const getSlideSpeedOptions = (): OptionData<string>[] => {
  return [
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
  ];
};

export const getSlideCountOptions = () => {
  return {
    name: 'slideCountOption',
    data: [
      { id: 'slideCountTwoByOne', value: 'TWO_BY_ONE', display: 'DISPLAY.DESIGN_POPUP.TWO_BY_ONE' },
      { id: 'slideCountTwoByTwo', value: 'TWO_BY_TWO', display: 'DISPLAY.DESIGN_POPUP.TWO_BY_TWO' },
      { id: 'slideCountThreeByOne', value: 'THREE_BY_ONE', display: 'DISPLAY.DESIGN_POPUP.THREE_BY_ONE' },
      { id: 'slideCountThreeByTwo', value: 'THREE_BY_TWO', display: 'DISPLAY.DESIGN_POPUP.THREE_BY_TWO' },
      { id: 'slideCountFourByOne', value: 'FOUR_BY_ONE', display: 'DISPLAY.DESIGN_POPUP.FOUR_BY_ONE' },
      { id: 'slideCountFourByTwo', value: 'FOUR_BY_TWO', display: 'DISPLAY.DESIGN_POPUP.FOUR_BY_TWO' },
    ],
  };
};

export const getSlideImageTypeOptions = () => {
  return {
    name: 'slideImageTypeOption',
    data: [
      {
        id: 'slideImageTypeUploadTrue',
        value: true,
        display: 'DISPLAY.DESIGN_POPUP.POPUP_IMAGE_REGISTRATION_UPLOAD',
      },
      {
        id: 'slideImageTypeUploadFalse',
        value: false,
        display: 'DISPLAY.DESIGN_POPUP.POPUP_IMAGE_REGISTRATION_URL',
      },
    ],
  };
};

export const getOpenLocationTargetOptions = (): OptionData<string>[] => {
  return [
    { name: 'DISPLAY.DESIGN_POPUP.OPEN_LOCATION_TARGET_SELF', value: 'SELF' },
    { name: 'DISPLAY.DESIGN_POPUP.OPEN_LOCATION_TARGET_BLANK', value: 'BLANK' },
  ];
};

export const DEFAULT_DESIGNPOPUP_MAIN_LIST_QUERY: DesignPopupListQuery = {
  mallNo: null,
  keyword: '',
  searchDateType: 'REGISTER',
  startDate: '',
  endDate: '',
  popupType: 'ALL',
  displayType: 'ALL',
  displayPageNo: null,
  page: 1,
  size: 30,
};

export const getSearchQuery = (
  query: DesignPopupListQuery,
  urlQuery: DesignPopupListRouterQuery,
): DesignPopupListQuery => {
  if (!urlQuery) {
    return query;
  }

  if (urlQuery.mallNo) {
    query.mallNo = Number(urlQuery.mallNo);
  } else {
    query.mallNo = null;
  }

  if (urlQuery.keyword) {
    query.keyword = urlQuery.keyword;
  } else {
    query.keyword = '';
  }

  if (urlQuery.searchDateType) {
    query.searchDateType = urlQuery.searchDateType;
  }

  if (urlQuery.startDate) {
    query.startDate = urlQuery.startDate;
  } else {
    query.startDate = '';
  }

  if (urlQuery.endDate) {
    query.endDate = urlQuery.endDate;
  } else {
    query.endDate = '';
  }

  if (urlQuery.popupType) {
    query.popupType = urlQuery.popupType;
  } else {
    query.popupType = 'ALL';
  }

  if (urlQuery.displayType) {
    query.displayType = urlQuery.displayType;
  } else {
    query.displayType = 'ALL';
  }

  if (urlQuery.displayPageNo) {
    query.displayPageNo = Number(urlQuery.displayPageNo);
  } else {
    query.displayPageNo = null;
  }

  if (urlQuery.page) {
    query.page = Number(urlQuery.page);
  }

  if (urlQuery.size) {
    query.size = Number(urlQuery.size);
  }

  return query;
};

export const getDesignPopupListSearchType = () => {
  return {
    searchDateType: [
      //기간 검색 종류 (REGISTER, UPDATE, PERIOD)
      { name: 'DISPLAY.DESIGN_POPUP.TERM_REGISTER_YMDT', value: 'REGISTER' },
      { name: 'DISPLAY.DESIGN_POPUP.TERM_UPDATE_YMDT', value: 'UPDATE' },
      { name: 'DISPLAY.DESIGN_POPUP.TERM_PERIOD', value: 'PERIOD' },
    ],
    popupType: [
      //팝업 종류 (NORMAL: 일반 팝업, MULTI: 멀티 팝업)
      { name: 'DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE', value: 'ALL' },
      { name: 'DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE_LABEL_NORMAL', value: 'NORMAL' },
      { name: 'DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE_LABEL_MULTI', value: 'MULTI' },
    ],
    displayType: [
      //전체(df), 노출함, 노출 안 함
      { name: 'DISPLAY.DESIGN_POPUP.DESIGN_POPUP_DISPLAY_TYPE_ALL', value: 'ALL' },
      { name: 'DISPLAY.DESIGN_POPUP.DESIGN_POPUP_DISPLAY_TYPE_VISIBLE', value: 'VISIBLE' },
      { name: 'DISPLAY.DESIGN_POPUP.DESIGN_POPUP_DISPLAY_TYPE_INVISIBLE', value: 'INVISIBLE' },
    ],
  };
};

export const getInitDisplayUrlOptions = (): OptionData<number>[] => {
  return [
    {
      value: null,
      name: window.$t('DISPLAY.DESIGN_POPUP.DISPLAY_PAGE').toString(),
    },
  ];
};

export const getUnitStr = (unit: string): string => {
  if (unit === 'PIXEL') {
    return 'px';
  } else {
    return '%';
  }
};

export const getScreenTypeStr = (screenType: string): string => {
  if (screenType === 'FIXED') {
    return window.$t('DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_FIXED');
  } else if (screenType === 'FIXED_TOP') {
    return window.$t('DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_FIXED_TOP');
  } else if (screenType === 'LAYER') {
    return window.$t('DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_LAYER');
  } else if (screenType === 'WINDOW') {
    return window.$t('DISPLAY.DESIGN_POPUP.SCREEN_TYPE_LABEL_NORMAL_PC_WINDOW');
  } else {
    return '';
  }
};

export const pcAndMobileTemplateTttle = (params): string => {
  let titleStr = '';
  let pcDisplayPageInfo = '';
  if (params.pcDesign.displayPageInfo.length > 0) {
    pcDisplayPageInfo = params.pcDesign.displayPageInfo.map(page => page.pageName).join(', ');
  }
  let mobileDisplayPageInfo = '';
  if (params.mobileDesign.displayPageInfo.length > 0) {
    mobileDisplayPageInfo = params.mobileDesign.displayPageInfo.map(page => page.pageName).join(', ');
  }

  titleStr =
    `${window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_LABEL_PC')} ${window.$t(
      'DISPLAY.DESIGN_POPUP.POPUP_WINDOWS_INFO',
    )}` +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_TYPE')} : ${getScreenTypeStr(params.pcDesign.screenType)}` +
    (params.popupDesignType === 'MULTI'
      ? ''
      : `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_SIZE')} : ${params.pcDesign.screenWidth}${getUnitStr(
          params.pcDesign.screenWidthUnit,
        )} X ${params.pcDesign.screenHeight}${getUnitStr(params.pcDesign.screenHeightUnit)}`) +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.POPUP_DISPLAY_PAGE')} : ${pcDisplayPageInfo}` +
    `\n` +
    `\n${window.$t('MOBILE_WEB')} ${window.$t('DISPLAY.DESIGN_POPUP.POPUP_WINDOWS_INFO')}` +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_TYPE')} : ${getScreenTypeStr(params.mobileDesign.screenType)}` +
    (params.popupDesignType === 'MULTI'
      ? ''
      : `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_SIZE')} : ${params.mobileDesign.screenWidth}${getUnitStr(
          params.mobileDesign.screenWidthUnit,
        )} X ${params.mobileDesign.screenHeight}${getUnitStr(params.mobileDesign.screenHeightUnit)}`) +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.POPUP_DISPLAY_PAGE')} : ${mobileDisplayPageInfo}`;
  return titleStr;
};

export const onlyPcTemplateTitle = (params): string => {
  let titleStr = '';
  let pcDisplayPageInfo = '';
  if (params.pcDesign.displayPageInfo.length > 0) {
    pcDisplayPageInfo = params.pcDesign.displayPageInfo.map(page => page.pageName).join(', ');
  }

  titleStr =
    `${window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_LABEL_PC')} ${window.$t(
      'DISPLAY.DESIGN_POPUP.POPUP_WINDOWS_INFO',
    )}` +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_TYPE')} : ${getScreenTypeStr(params.pcDesign.screenType)}` +
    (params.popupDesignType === 'MULTI'
      ? ''
      : `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_SIZE')} : ${params.pcDesign.screenWidth}${getUnitStr(
          params.pcDesign.screenWidthUnit,
        )} X ${params.pcDesign.screenHeight}${getUnitStr(params.pcDesign.screenHeightUnit)}`) +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.POPUP_DISPLAY_PAGE')} : ${pcDisplayPageInfo}`;

  return titleStr;
};

export const onlyMobileTemplateTitle = (params): string => {
  let titleStr = '';
  let mobileDisplayPageInfo = '';
  if (params.mobileDesign.displayPageInfo.length > 0) {
    mobileDisplayPageInfo = params.mobileDesign.displayPageInfo.map(page => page.pageName).join(', ');
  }

  titleStr =
    `${window.$t('MOBILE_WEB')} ${window.$t('DISPLAY.DESIGN_POPUP.POPUP_WINDOWS_INFO')}` +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_TYPE')} : ${getScreenTypeStr(params.mobileDesign.screenType)}` +
    (params.popupDesignType === 'MULTI'
      ? ''
      : `\n${window.$t('DISPLAY.DESIGN_POPUP.WINDOWS_SIZE')} : ${params.mobileDesign.screenWidth}${getUnitStr(
          params.mobileDesign.screenWidthUnit,
        )} X ${params.mobileDesign.screenHeight}${getUnitStr(params.mobileDesign.screenHeightUnit)}`) +
    `\n${window.$t('DISPLAY.DESIGN_POPUP.POPUP_DISPLAY_PAGE')} : ${mobileDisplayPageInfo}`;
  return titleStr;
};

export const titleWithTooptipRenderer = props => {
  const aLinkHtml = document.createElement('a');
  aLinkHtml.classList.add('underline', 'type-black', 'type-popup');
  aLinkHtml.style.width = '100%';
  aLinkHtml.href = 'javascript:void(0);';
  aLinkHtml.addEventListener('click', props.callback);

  aLinkHtml.innerHTML = `<span class="ellipsis" style="display:block">${props.title}</span>`;
  return {
    getElement(): HTMLElement {
      if (props.param.pcDesign && props.param.mobileDesign) {
        aLinkHtml.title = pcAndMobileTemplateTttle(props.param);
        return $el(aLinkHtml).getElement() as HTMLElement;
      } else if (props.param.pcDesign) {
        aLinkHtml.title = onlyPcTemplateTitle(props.param);
        return $el(aLinkHtml).getElement() as HTMLElement;
      } else if (props.param.mobileDesign) {
        aLinkHtml.title = onlyMobileTemplateTitle(props.param);
        return $el(aLinkHtml).getElement() as HTMLElement;
      } else {
        return $el(aLinkHtml).getElement() as HTMLElement;
      }
    },
  };
};

//체크박스, No, 수정, 쇼핑몰, 플랫폼 구분, 팝업유형, 팝업제목, 노출여부, 등록일/수정일, 보기
export const getDesignPopupGridProps = onGridCellEvent => {
  return {
    columns: [
      //수정
      {
        header: 'MODIFY',
        name: 'modifyBtnCell',
        align: 'center',
        minWidth: 80,
        formatter: () => {
          return `<a class="modifyBtnArea">${window.$t('MODIFY')}</a>`;
        },
      },
      //쇼핑몰
      {
        header: 'MALL',
        name: 'mallName',
        align: 'center',
        minWidth: 100,
      },
      //플랫폼 구분
      {
        header: 'DISPLAY.DESIGN_POPUP.PLATFORM_TYPES',
        name: 'platformTypes',
        align: 'center',
        minWidth: 120,
        formatter: cell => {
          if (cell.row.platformTypes) {
            const platForms: string[] = cell.row.platformTypes;
            if (platForms.includes('PC') && platForms.includes('MOBILE_WEB')) {
              return window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_LABEL_PC') + '<br/>' + window.$t('MOBILE_WEB');
            } else if (platForms.includes('PC')) {
              return window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_LABEL_PC');
            } else if (platForms.includes('MOBILE_WEB')) {
              return window.$t('MOBILE_WEB');
            }
          } else {
            return '';
          }
        },
        copyOptions: {
          customValue: (value): string => {
            const platForms: string[] = value as string[];
            if (platForms.indexOf('PC') !== -1 && platForms.indexOf('MOBILE_WEB') !== -1) {
              return window.$t('PC') + ',' + window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
            } else if (platForms.indexOf('PC') !== -1) {
              return window.$t('PC');
            } else if (platForms.indexOf('MOBILE_WEB') !== -1) {
              return window.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
            }
          },
        },
      },
      //팝업유형
      {
        header: 'DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE',
        name: 'popupDesignType',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (cell.row.popupDesignType === 'NORMAL') {
            return window.$t('DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE_LABEL_NORMAL');
          } else if (cell.row.popupDesignType === 'MULTI') {
            return window.$t('DISPLAY.DESIGN_POPUP.POPUP_DESIGN_TYPE_LABEL_MULTI');
          }
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      //팝업제목
      {
        header: 'DISPLAY.DESIGN_POPUP.POPUP_TITLE',
        name: 'title',
        align: 'center',
        minWidth: 250,
        renderer: props => {
          const row: Row = props.grid.getRow(props.rowKey);
          props.param = {
            pcDesign: row.pcDesign,
            mobileDesign: row.mobileDesign,
            popupDesignType: row.popupDesignType,
          };
          props.title = row.title;
          props.callback = event => {
            onGridCellEvent('goModifyPage', event, props.rowKey);
          };
          return titleWithTooptipRenderer(props);
        },
      },
      //노출여부
      {
        header: 'DISPLAY.DESIGN_POPUP.DISPLAYABLE',
        name: 'displayType',
        align: 'center',
        minWidth: 120,
        renderer: props => {
          const row = props.grid.getRow(props.rowKey);

          if (row.displayType !== 'VISIBLE' && row.displayType !== 'INVISIBLE') {
            let disableSelectBox: string;
            if (row.displayType === 'WAIT') {
              disableSelectBox = `<select style="width:63px;" disabled=disabled><option value='WAIT'>${window.$t(
                'DISPLAY.DESIGN_POPUP.WAIT',
              )}</option></select>`;
            } else if (row.displayType === 'DONE') {
              disableSelectBox = `<select style="width:63px;" disabled=disabled><option value='DONE'>${window.$t(
                'DISPLAY.DESIGN_POPUP.DONE',
              )}</option></select>`;
            }

            let timeArea = '';

            if (row.displayConfig.displayPeriodType === 'PERIOD' || row.displayConfig.displayPeriodType === 'TIME') {
              const startDate = row.displayConfig.displayStartDate;
              const endDate = row.displayConfig.displayEndDate;

              const startTime = row.displayConfig.displayStartTime.slice(0, 5);
              const endTime = row.displayConfig.displayEndTime.slice(0, 5);

              if (row.displayConfig.displayPeriodType === 'PERIOD') {
                // 2021-01-01 00:00~2021-01-15 00:00
                timeArea =
                  '<div><br>' + startDate + ' ' + startTime + '~' + '<br/>' + endDate + ' ' + endTime + '</div>';
              } else if (row.displayConfig.displayPeriodType === 'TIME') {
                // 2021-01-01~2021-01-15 00:00~02:00
                timeArea =
                  '<div><br>' + startDate + '~' + endDate + ' ' + '<br/>' + startTime + '~' + endTime + '</div>';
              }
            }

            const divText = disableSelectBox + timeArea;

            return warpHeaderTit({ columnInfo: { header: divText } });
          } else {
            props.width = '63px';
            props.selectOptions = [
              { text: window.$t('DISPLAY.DESIGN_POPUP.DISPLAYABLE_LABEL_TRUE'), value: 'VISIBLE' },
              { text: window.$t('DISPLAY.DESIGN_POPUP.DISPLAYABLE_LABEL_FALSE'), value: 'INVISIBLE' },
            ];
            props.callback = event => {
              onGridCellEvent('changeStatus', event, props.rowKey);
            };

            let timeArea = '';
            let timeAreaFirstHalf = '';
            let timeAreaLatterHalf = '';

            if (row.displayConfig.displayPeriodType === 'PERIOD' || row.displayConfig.displayPeriodType === 'TIME') {
              const startDate = row.displayConfig.displayStartDate;
              const endDate = row.displayConfig.displayEndDate;

              const startTime = row.displayConfig.displayStartTime.slice(0, 5);
              const endTime = row.displayConfig.displayEndTime.slice(0, 5);

              if (row.displayConfig.displayPeriodType === 'PERIOD') {
                // 2021-01-01 00:00~2021-01-15 00:00
                timeAreaFirstHalf = startDate + ' ' + startTime + '~';
                timeAreaLatterHalf = endDate + ' ' + endTime;
                // timeArea = startDate + ' ' + startTime + '~' + '<br/>' + endDate + ' ' + endTime;
              } else if (row.displayConfig.displayPeriodType === 'TIME') {
                // 2021-01-01~2021-01-15 00:00~02:00
                timeAreaFirstHalf = startDate + '~' + endDate + ' ';
                timeAreaLatterHalf = startTime + '~' + endTime;
                // timeArea = startDate + '~' + endDate + ' ' + '<br/>' + startTime + '~' + endTime;
              }
            } else {
              timeArea = window.$t('DISPLAY.DESIGN_POPUP.DISPLAY_PERIOD_TYPE_LABEL_ALWAYS');
            }

            return {
              getElement(): HTMLElement {
                const internalDiv: HTMLDivElement = document.createElement('div');
                internalDiv.appendChild(selectRenderer(props).getElement());
                const dataTimeDiv: HTMLDivElement = document.createElement('div');
                if (
                  row.displayConfig.displayPeriodType === 'PERIOD' ||
                  row.displayConfig.displayPeriodType === 'TIME'
                ) {
                  dataTimeDiv.append(document.createElement('br') as HTMLElement);
                  dataTimeDiv.append(timeAreaFirstHalf);
                  dataTimeDiv.append(document.createElement('br') as HTMLElement);
                  dataTimeDiv.append(timeAreaLatterHalf);
                } else {
                  dataTimeDiv.append(document.createElement('br') as HTMLElement);
                  dataTimeDiv.append(timeArea);
                }
                internalDiv.appendChild(dataTimeDiv);
                return internalDiv;
              },
            };
          }
        },
      },
      //등록일/수정일
      {
        header: 'REGISTER_UPDATE_DATE',
        name: 'registerAndUpdateDate',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          const registerDate = cell.row.registerDate;
          const updateDate = !cell.row.updateDate ? '' : cell.row.updateDate;
          if (updateDate) {
            return `${registerDate} <br/> ${updateDate}`;
          } else {
            return `${registerDate}`;
          }
        },
        copyOptions: {
          customValue: (value): string => {
            return value;
          },
        },
      },
      {
        header: 'VIEW',
        name: 'thirdPartyProvisionAgreed',
        align: 'center',
        minWidth: 100,
        renderer: props => {
          props.btnText = window.$t('DISPLAY.DESIGN_POPUP.GRID_DATA_BUTTON_PREVIEW');
          props.callback = event => {
            onGridCellEvent('displayPreviewLayer', event, props.rowKey);
          };
          const btnRenderer = buttonRenderer(props);
          btnRenderer.getElement().className = 'double_click_allow type-white';
          return btnRenderer;
        },
      },
    ],
    options: {
      keyColumnName: 'popupNo',
      rowHeight: 100,
      rowHeaders: [
        {
          type: 'checkbox',
          width: 70,
        },
        {
          type: 'rowNum',
          width: 80,
        },
      ],
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};

export const getImageDivClass = (slideCount: string): string => {
  let className = 'img-area02';
  switch (slideCount) {
    case 'TWO_BY_ONE':
    case 'TWO_BY_TWO':
      className = 'img-area02';
      break;
    case 'THREE_BY_ONE':
    case 'THREE_BY_TWO':
      className = 'img-area02 type-2';
      break;
    case 'FOUR_BY_ONE':
    case 'FOUR_BY_TWO':
      className = 'img-area02 type-3';
      break;
  }
  return className;
};
