import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Popup } from '@/types/popup';
import { CancelTokenSource } from 'axios';
import { TableTabMenuItem } from '@/components/common/TableTabMenu.vue';
import { Skin } from '@/types/design';
import { getCurrentMallNo } from '@/utils/mall';
import {
  GetSkinsMallsMallNo,
  GetSkinsMallsMallNoRequest,
  PostSkinsRequest,
  GetSkinsSkinNoRequest,
  PutSkinsSkinNoRequest,
  DeleteSkinsSkinNoRequest,
  PutSkinsSkinNoStatusRequest,
  DesignSkinStatus,
  DesignSkinPlatform,
  GetSkinsSkinNo,
  GetSkinsSkinNoDownloadRequest,
  GetMallsConfigurationsRequest,
} from 'ncp-api-supporter';
import { Admin } from '@/types';

@Component
export default class SkinListMixin extends Vue {
  @Getter('popup/getLastPopupItem') lastPopupItem!: Popup;
  @Getter('admin/getAdmin') admin!: Admin;

  protected key = getCurrentMallNo(this);
  protected mallSkins: GetSkinsMallsMallNo[] = [];

  // 현재 설정된 디자인 스킨
  protected currentSkinMenuList: TableTabMenuItem[] = [
    { name: 'PC', title: 'PC' },
    { name: 'MOBILE', title: 'Mobile' },
  ];
  protected currentSkinFocusTabName = 'PC';

  // 사용중인 디자인 스킨
  protected currentLiveSkin: GetSkinsMallsMallNo = {} as GetSkinsMallsMallNo;

  // 작업중인 디자인 스킨
  protected currentWorkSkin: GetSkinsMallsMallNo = {} as GetSkinsMallsMallNo;

  // 보유 스킨 리스트
  protected retainedPcSkinsCount = 0;
  protected retainedMobileSkinsCount = 0;
  protected retainedSkinsMenuList: TableTabMenuItem[] = [
    {
      name: 'PC',
      title: this.$t('APPEARANCE.BASIC.SKIN.RETAINED_MENU_TAB_PC', { retainedPcSkinsCount: this.retainedPcSkinsCount }),
    },
    {
      name: 'MOBILE',
      title: this.$t('APPEARANCE.BASIC.SKIN.RETAINED_MENU_TAB_MOBILE', {
        retainedMobileSkinsCount: this.retainedMobileSkinsCount,
      }),
    },
  ];
  protected retainedSkinsFocusTabName = 'PC';

  protected retainedSkinList: GetSkinsMallsMallNo[] = [];

  public getGetSkinsMallsMallNoRequest(mallNo: number): GetSkinsMallsMallNoRequest {
    const request: GetSkinsMallsMallNoRequest = {
      pathParams: {
        mallNo: mallNo,
      },
    };
    return request;
  }
  public async getSkinsMallsMallNo(request: GetSkinsMallsMallNoRequest): Promise<GetSkinsMallsMallNo[]> {
    const { data } = await this.$api.getSkinsMallsMallNo(request);
    return data;
  }
  public getCurrentLiveSkin(mallSkins: GetSkinsMallsMallNo[], platform: DesignSkinPlatform): GetSkinsMallsMallNo {
    return mallSkins.find(item => item.platform === platform && item.isLiveSkin);
  }
  public getCurrentWorkSkin(mallSkins: GetSkinsMallsMallNo[], platform: DesignSkinPlatform): GetSkinsMallsMallNo {
    return mallSkins.find(item => item.platform === platform && item.isWorkSkin);
  }
  public getRetainedSkinList(mallSkins: GetSkinsMallsMallNo[], platform: DesignSkinPlatform): GetSkinsMallsMallNo[] {
    const skins: GetSkinsMallsMallNo[] = mallSkins.filter((item: GetSkinsMallsMallNo): boolean => {
      return item.platform === platform; // && !item.isLiveSkin && !item.isWorkSkin;
    });
    return skins;
  }
  public getRetainedSkinsCount(mallSkins: GetSkinsMallsMallNo[], platform: DesignSkinPlatform): number {
    const skins: GetSkinsMallsMallNo[] = mallSkins.filter((item: GetSkinsMallsMallNo): boolean => {
      return item.platform === platform; // && !item.isLiveSkin && !item.isWorkSkin;
    });
    return skins.length;
  }

  public getPostSkinsRequest(skin: Skin, skinFileFormData: FormData): PostSkinsRequest {
    const request: PostSkinsRequest = {
      params: {
        name: skin.name,
        filename: skin.filename,
        thumbnail: skin.thumbnail,
        platform: skin.platform,
        mallNo: skin.mallNo,
        adminNo: this.admin.no,
        init: skin.init,
        skinAuthorType: skin.authorType,
      },
      data: skinFileFormData,
    };
    return request;
  }
  public async postSkins(request: PostSkinsRequest): Promise<void> {
    await this.$api.postSkins(request);
  }

  public getGetSkinsSkinNoRequest(skinNo: number): GetSkinsSkinNoRequest {
    const request: GetSkinsSkinNoRequest = {
      pathParams: {
        skinNo: skinNo,
      },
    };
    return request;
  }
  public async getSkinsSkinNo(request: GetSkinsSkinNoRequest): Promise<GetSkinsSkinNo> {
    const { data } = await this.$api.getSkinsSkinNo(request);
    return data;
  }

  public getPutSkinsSkinNoRequest(skin: Skin, skinFileFormData: FormData): PutSkinsSkinNoRequest {
    const request: PutSkinsSkinNoRequest = {
      pathParams: {
        skinNo: skin.skinNo,
      },
      params: {
        name: skin.name,
        platform: skin.platform,
        mallNo: skin.mallNo,
        thumbnail: skin.thumbnail,
        skinCode: skin.code,
      },
      data: skinFileFormData,
    };
    return request;
  }
  public async putSkinsSkinNo(request: PutSkinsSkinNoRequest): Promise<void> {
    await this.$api.putSkinsSkinNo(request);
  }

  public getDeleteSkinsSkinNoRequest(skinNo: number): DeleteSkinsSkinNoRequest {
    const request: DeleteSkinsSkinNoRequest = {
      pathParams: {
        skinNo: skinNo,
      },
      params: {},
    };
    return request;
  }
  public async deleteSkinsSkinNo(request: DeleteSkinsSkinNoRequest, source: CancelTokenSource): Promise<void> {
    if (source) {
      const pathParams = request.pathParams;
      await this.$api.deleteSkinsSkinNo({ pathParams, cancelToken: source.token, params: {} });
    } else {
      await this.$api.deleteSkinsSkinNo(request);
    }
  }

  public getPutSkinsSkinNoStatusRequest(skinNo: number, status: DesignSkinStatus): PutSkinsSkinNoStatusRequest {
    const request: PutSkinsSkinNoStatusRequest = {
      pathParams: {
        skinNo: skinNo,
      },
      data: {
        status: status,
      },
    };
    return request;
  }
  public async putSkinsSkinNoStatus(request: PutSkinsSkinNoStatusRequest): Promise<void> {
    await this.$api.putSkinsSkinNoStatus(request);
  }

  public getGetSkinsSkinNoDownloadRequest(skinNo: number, mallNo: number): GetSkinsSkinNoDownloadRequest {
    const request: GetSkinsSkinNoDownloadRequest = {
      pathParams: {
        skinNo: skinNo,
      },
      params: {
        mallNo: mallNo,
      },
    };
    return request;
  }

  public open(url: string) {
    window.open(url, '_blank');
  }

  protected isInUseWorkspace = false;
  protected async checkInUseWorkspace(mallNo: number) {
    const request: GetMallsConfigurationsRequest = {
      pathParams: {
        mallNo: `${mallNo}`,
      },
      params: {
        key: 'WORKSPACE_CONFIG',
      },
    };
    try {
      const { data } = await this.$api.getMallsConfigurations(request);
      this.isInUseWorkspace = !!data?.used;
    } catch (e) {
      this.isInUseWorkspace = false;
    }
  }
}
