
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { PlatformTable } from '@/types';

@Component
export default class PlatformCountTable extends Vue {
  @Prop({ required: true })
  private readonly platform: PlatformTable;
}
