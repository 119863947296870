export default Object.freeze({
  // changePasswordPopup
  POPUP_HEADER: '비밀번호 변경',
  POPUP_OLD: '현재 비밀번호',
  POPUP_NEW: '새 비밀번호',
  NEW_GUID: '영문,숫자,특수문자를 조합하여 8~20자리로 설정하셔야 합니다.',
  NEW_ERROR_ONE: '8자리 이상의 비밀번호를 입력 해 주세요.',
  NEW_ERROR_TWO: '영문,숫자,특수문자 중 2종류 이상 조합하여 입력해주세요.',
  NEW_ERROR_TRE: '영문,숫자,특수문자를 3종류 모두 조합하여 입력해주세요.',
  NEW_ERROR_FOUR: '!@#$%^&+=-_.() 특수문자만 사용 가능합니다.',
  POPUP_CONFIRM: '새 비밀번호 확인',
  POPUP_CONFIRM_GUID: '동일한 비밀번호를 입력해 주세요.',
  POPUP_CONFIRM_SAME: '비밀번호가 동일합니다.',
  POPUP_CONFIRM_ERROR: '비밀번호를 다시 확인해 주세요.',
  DESCRIPTION_FIRST_UP: '비밀번호는 영문,숫자,특수문자를 조합하여 8~20자리로 설정하셔야 합니다.',
  DESCRIPTION_FIRST_DOWN: '(10자리 이상 시에는 2종류 이상 조합, 미만 시에는 3종류 모두 조합)',
  DESCRIPTION_SECOND: '특수문자는 !@#$%^&+=-_.() 만 사용 가능합니다.',
  DESCRIPTION_THIRD:
    '연속적인 숫자나 생일, 전화번호 등 추측하기 쉬운 개인정보 및 아이디와 비슷한 비밀번호는 사용하지 않는 것을 권고합니다.',
  POPUP_SUBMIT: '비밀번호 변경',
  POPUP_CANCLE: '취소',
  POST_ADMINS_PASSWORD_CURRENTPASSWORD_ERROR: '현재 비밀번호를 입력해 주세요.',
  PUT_ADMINS_PASSWORD_NEWPASSWORD_ERROR: '새 비밀번호를 입력해 주세요.',
  PUT_ADMINS_PASSWORD_CONFIRMPASSWORD_ERROR: '새 비밀번호 확인을 입력해 주세요.',
  PUT_ADMINS_PASSWORD_NEWPASSWORD_FORM_ERROR: '영문,숫자,특수문자를 조합하여 8~20자리로 설정하셔야 합니다.',
});
