




































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import IconsList from '@/views/contents/product/basic/ProductAdd/salesInfo/details/IconsList.vue';
import { IconDetails, IconsInformation, InputNumber, StickerInfos } from '@/types';
import { GetStickersByMallNoRequest, NCPResponse, Sticker } from 'ncp-api-supporter';
import { addMonth, getStrDate, getToday } from '@/utils/dateFormat';
import { INITIAL_RANGE_MONTH, today } from '@/views/contents/product/basic/ProductAdd';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';

@Component({
  components: { DateRangePicker, ToolTip, IconsList },
})
export default class IconsInfo extends Vue {
  @PropSync('stickerInfos', { required: true })
  private stickerInfosSync: StickerInfos[];

  @Prop({ required: true })
  private readonly mallNo: InputNumber;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private fetchedIcons: IconDetails[] = [];

  private icons: IconsInformation = {
    regularExposure: {
      iconNos: [],
    },
    periodExposure: {
      startYmd: today,
      endYmd: addMonth(new Date(), INITIAL_RANGE_MONTH),
      iconNos: [],
    },
  };
  public resetIcons() {
    this.stickerInfosSync = [];
  }

  // 상품 저장/수정/복사 시 호출
  public setIcons() {
    const stickerInfos: StickerInfos[] = [];
    for (const icon in this.icons) {
      this.icons[icon].iconNos.forEach(stickerNo => {
        if (icon === 'regularExposure') {
          stickerInfos.push({ stickerNo });
        } else {
          stickerInfos.push({
            stickerNo,
            displayStartedAt: `${getStrDate(this.icons[icon].startYmd)} ${DEFAULT_TIME_RANGE.START}`,
            displayEndedAt: `${getStrDate(this.icons[icon].endYmd)} ${DEFAULT_TIME_RANGE.END}`,
          });
        }
      });
    }
    this.stickerInfosSync = stickerInfos;
  }

  private get today(): string {
    return getToday();
  }

  private checkedIcon(type: 'regular' | 'period', { idx, checked }) {
    if (this.fetchedIcons.length === 0) return;
    if (checked) {
      this.$set(this.fetchedIcons[idx], 'disabledType', type);
      return;
    }
    this.$set(this.fetchedIcons[idx], 'disabledType', null);
  }

  @Watch('mallNo')
  private async fetchIcons(): Promise<void> {
    if (this.mallNo > 0) {
      const params: GetStickersByMallNoRequest['params'] = {
        mallNo: Number(this.mallNo),
      };
      const { data }: NCPResponse<Sticker[]> = await this.$api.getStickersByMallNo({ params });
      this.fetchedIcons = [...data].filter(icon => icon.useYn === 'Y').map(icon => ({ ...icon, disabledType: null }));
      this.setStickerInfos();
      return;
    }

    this.fetchedIcons = [];
  }

  private divideIconsByPeriodType(sticker: StickerInfos) {
    const idx = this.fetchedIcons?.findIndex(icon => icon.stickerNo === sticker.stickerNo);
    if (idx < 0) return;
    if (sticker.displayStartedAt !== null) {
      this.$set(this.icons.periodExposure, 'startYmd', sticker.displayStartedAt);
      this.$set(this.icons.periodExposure, 'endYmd', sticker.displayEndedAt);
      this.icons.periodExposure.iconNos.push(sticker.stickerNo);
      this.checkedIcon('period', { idx, checked: true });
      return;
    }
    this.icons.regularExposure.iconNos.push(sticker.stickerNo);
    this.checkedIcon('regular', { idx, checked: true });
  }

  private setStickerInfos() {
    if (this.stickerInfosSync.length === 0) return;
    this.stickerInfosSync.forEach(this.divideIconsByPeriodType);
  }

  private get rangePickerOption() {
    let fromRanges = `${today} ${DEFAULT_TIME_RANGE.START}`;
    if (this.icons.periodExposure && this.icons.periodExposure.startYmd) {
      fromRanges = this.icons.periodExposure.startYmd;
    }
    return { hasPeriodBtn: false, fromRanges };
  }
}
