
















































































import { Component, Watch } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  getSmsReservationDetailGridOption,
  getSmsReservationDetailToolTipOption,
  getSmsReservationHistoryInitQuery,
} from '@/const/contents/sms';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { CheckGridEventProps } from '@/types';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { isValidate } from '@/utils/validate';
import {
  GetSmsGodoReservedHistory,
  GetSmsGodoReservedHistoryContent,
  GetSmsGodoReservedHistoryRequest,
  NCPResponse,
  PutSmsGodoCancelReserveRequest,
  SmsGodoCancelReserve,
} from 'ncp-api-supporter';
import { getCurrentMallNo } from '@/utils/mall';
import { omit } from 'underscore';

@Component({
  components: { ToolTip, TextInput, SearchButton, Grid },
})
export default class SmsReservationDetail extends WindowPopupMainVue {
  private toolTipOption = getSmsReservationDetailToolTipOption();
  private queryParams = getSmsReservationHistoryInitQuery(getCurrentMallNo(this), 'info', this.routeQueryDate);
  private gridOption = getSmsReservationDetailGridOption();
  private contents: GetSmsGodoReservedHistoryContent[] = [];
  private totalCount = 0;
  private selected: SmsGodoCancelReserve[] = [];
  private get routeQueryDate() {
    const { startDate, endDate } = this.$route.query;
    return { startDate: startDate.toString(), endDate: endDate.toString() };
  }

  private get title(): string {
    return `MEMBER.SMS_RESERVATION.DETAIL_${this.data.type}`;
  }

  private onRowChecked(checkProps: CheckGridEventProps) {
    this.selected = checkProps.selected.map(index => {
      return {
        phoneNumber: this.contents[index].phoneNo,
        smsContent: this.contents[index].msg,
        sendDateTime: this.contents[index].sendDate,
        cancelMode: 'CANCEL',
        smsType: this.data.type,
        phoneKey: this.contents[index].phoneKey,
      };
    });
  }

  private validateCancelSelectedReservation(): boolean {
    const selectedCount = this.selected.length;
    if (!isValidate(selectedCount, this.$t('MEMBER.SMS_RESERVATION.ALERT_MUST_SELECT'))) return false;
    const MAX_SELECT_COUNT = 100;
    if (!isValidate(selectedCount <= MAX_SELECT_COUNT, this.$t('MEMBER.SMS_RESERVATION.ALERT_MAX_CASE'))) return false;

    return confirm(this.$t('MEMBER.SMS_RESERVATION.CONFIRM_CANCEL', [selectedCount]).toString());
  }

  private getCancelReservationRequest(cancelMode: string): PutSmsGodoCancelReserveRequest {
    return {
      params: { mallNo: getCurrentMallNo(this) },
      data:
        cancelMode === 'CANCEL'
          ? this.selected
          : [
              {
                cancelMode,
                smsContent: this.contents[0].msg,
                smsSendKey: this.$route.query.smsSendKey.toString(),
                sendDateTime: this.contents[0].sendDate,
                smsType: this.data.type,
              },
            ],
    };
  }

  private async cancelSelectedReservation() {
    if (!this.validateCancelSelectedReservation()) return;

    try {
      await this.$api.putSmsGodoCancelReserve(this.getCancelReservationRequest('CANCEL'));
      alert(this.$t('MEMBER.SMS_RESERVATION.ALERT_SUCCESS_SELECT'));
      this.onNegativeClick();
    } catch (e) {
      alert(this.$t('MEMBER.SMS_RESERVATION.ALERT_FAIL'));
    }
  }

  private async cancelAllReservation() {
    const confirmMessage = this.$t(`MEMBER.SMS_RESERVATION.CONFIRM_ALL_CANCEL_${this.data.type}`).toString();
    if (!confirm(confirmMessage)) return this.onNegativeClick();

    try {
      await this.$api.putSmsGodoCancelReserve(this.getCancelReservationRequest('CANCEL_ALL'));
      alert(this.$t('MEMBER.SMS_RESERVATION.ALERT_SUCCESS_ALL'));
      this.onNegativeClick();
    } catch (e) {
      alert(this.$t('MEMBER.SMS_RESERVATION.ALERT_FAIL'));
    }
  }

  @Watch('$route.query')
  private async fetchReservationDetail() {
    const routeQuery = omit(this.$route.query, 'popupId', 'width', 'dataRefresh');
    const request: GetSmsGodoReservedHistoryRequest = {
      params: {
        ...getSmsReservationHistoryInitQuery(getCurrentMallNo(this), 'info'),
        ...routeQuery,
      },
    };
    const { data }: NCPResponse<GetSmsGodoReservedHistory> = await this.$api.getSmsGodoReservedHistory(request);

    this.queryParams.smsSendKey = routeQuery.smsSendKey.toString();
    this.totalCount = data.totalCount ?? 0;
    this.contents = data.contents ?? [];
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }

  created() {
    this.fetchReservationDetail();
  }
}
