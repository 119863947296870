





























import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import {
  GetStatisticMembersSummaryByPeriodRequest,
  GetStatisticsMembersByPeriod,
  StatisticsMembersByPeriodParam,
  StatisticSummary,
  StatisticSummaryByPeriod,
} from 'ncp-api-supporter';
import { StatisticSynthesisPeriod } from '@/types';
import { getMembersByPeriodGridProps, defaultParams } from '@/views/contents/statistics/members/PeriodMembers';
import Grid from '@/components/common/grid/Main.vue';
import StatisticNoticeBox from '@/components/statistics/StatisticNoticeBox.vue';
import PeriodMemberForm from '@/views/contents/statistics/members/PeriodMemberForm.vue';
import StatisticSynthesis from '@/components/statistics/members/StatisticSynthesis.vue';
import { generateQuery } from '@/views/contents/statistics/sales/statisticUtils';
import { getMallName, mall } from '@/utils/mall';

interface StatisticMembersSummaryByPeriod {
  statisticSummary: null | StatisticSummary;
  statisticSummaryByPeriod: null | StatisticSummaryByPeriod;
}

@Component({
  components: {
    Grid,
    StatisticNoticeBox,
    PeriodMemberForm,
    StatisticSynthesis,
  },
})
export default class PeriodMembers extends Vue {
  @Ref()
  private readonly gridRef!: Grid;
  private membersByPeriodGridProps = getMembersByPeriodGridProps();

  private memberSearchPeriod: null | StatisticSynthesisPeriod = null;
  private statisticMembersSummaryByPeriod: StatisticMembersSummaryByPeriod = {
    statisticSummary: null,
    statisticSummaryByPeriod: null,
  };
  private statisticsMembersContents: null | GetStatisticsMembersByPeriod['contents'] = [];
  private statisticsMembersTotalCount = 0;

  @Watch('$route.query')
  private updateQuery() {
    const newQuery = generateQuery(this.$route.query, defaultParams);
    if (newQuery?.memberType && newQuery.memberType !== 'MALL') {
      newQuery.providers = newQuery.memberType;
      newQuery.memberType = 'OPEN_ID';
    }
    return Promise.all([this.fetchStatisticMembersSummary(newQuery), this.fetchStatisticsMembersByPeriod(newQuery)]);
  }

  private async fetchStatisticMembersSummary(queries) {
    const params = { ...queries } as GetStatisticMembersSummaryByPeriodRequest['params'];

    const res = await this.$api.getStatisticMembersSummaryByPeriod({ params });
    const { statisticSummary, statisticSummaryByPeriod } = res.data;
    this.statisticMembersSummaryByPeriod = { statisticSummary, statisticSummaryByPeriod };

    if (!this.$route.query?.mallNo) return;
    const { startDate, endDate } = this.$route.query;
    this.memberSearchPeriod = { startYmd: startDate as string, endYmd: endDate as string };
  }

  // 회원 일자별 통계 fetch
  private async fetchStatisticsMembersByPeriod(queries) {
    const params = { ...queries } as StatisticsMembersByPeriodParam;
    const res = await this.$api.getStatisticsMembersByPeriod({ params });

    const { contents, totalCount } = res.data;

    this.statisticsMembersContents = contents;
    this.statisticsMembersTotalCount = totalCount;

    this.isInit = !this.$route.query?.mallNo;
    this.changeNoDataMessage();
  }

  private searchedMallName = mall.isOnlyOneMall ? getMallName(mall.onlyOneMallNo) : null;
  @Watch('$route.query.mallNo')
  private changedMall() {
    const mallNo = Number(this.$route.query?.mallNo);
    this.searchedMallName = mallNo > 0 ? getMallName(mallNo) : null;
  }

  private isInit = true;
  private changeNoDataMessage() {
    const message = this.isInit ? 'STATISTIC.MEMBER.MUST_SEARCH' : 'NO_RESULT';
    this.$nextTick(() => this.gridRef.changeNoDataMessage(this.$t(message).toString()));
  }
  private setInitState() {
    this.isInit = true;
    this.changeNoDataMessage();
  }
  private created() {
    this.setInitState();
  }
}
