export default [
  { value: 'GY', name: '가이아나' },
  { value: 'GG', name: '건지' },
  { value: 'GT', name: '과테말라' },
  { value: 'GU', name: '괌' },
  { value: 'GR', name: '그리스' },
  { value: 'NL', name: '네덜란드' },
  { value: 'NO', name: '노르웨이' },
  { value: 'NZ', name: '뉴질랜드' },
  { value: 'TW', name: '대만' },
  { value: 'KR', name: '대한민국' },
  { value: 'DK', name: '덴마크' },
  { value: 'GL', name: '덴마크' },
  { value: 'DE', name: '독일' },
  { value: 'LV', name: '라트비아' },
  { value: 'RU', name: '러시아' },
  { value: 'LB', name: '레바논' },
  { value: 'RO', name: '루마니아' },
  { value: 'LU', name: '룩셈부르크' },
  { value: 'LT', name: '리투아니아' },
  { value: 'LI', name: '리히텐슈타인' },
  { value: 'PT_MADEIRA', name: '마데이라' },
  { value: 'MO', name: '마카오' },
  { value: 'MY', name: '말레이시아' },
  { value: 'MT', name: '몰타' },
  { value: 'US', name: '미국(대륙)' },
  { value: 'U2', name: '미국(알래스카,하와이)' },
  { value: 'BH', name: '바레인' },
  { value: 'VN', name: '베트남' },
  { value: 'BE', name: '벨기에' },
  { value: 'BZ', name: '벨리즈' },
  { value: 'GB_NORTHERN_ISLAND', name: '북아일랜드' },
  { value: 'BG', name: '불가리아' },
  { value: 'BR', name: '브라질' },
  { value: 'BN', name: '브루나이' },
  { value: 'SA', name: '사우디아라비아' },
  { value: 'SM', name: '산마리노' },
  { value: 'SE', name: '스웨덴' },
  { value: 'CH', name: '스위스' },
  { value: 'GB_SCOTLAND', name: '스코틀랜드' },
  { value: 'ES', name: '스페인(에스파냐)' },
  { value: 'SK', name: '슬로바키아' },
  { value: 'SI', name: '슬로베니아' },
  { value: 'SG', name: '싱가폴' },
  { value: 'AE', name: '아랍에미리트' },
  { value: 'IS', name: '아이슬란드' },
  { value: 'IE', name: '아일랜드' },
  { value: 'AD', name: '안도라' },
  { value: 'EE', name: '에스토니아' },
  { value: 'EC', name: '에콰도르' },
  { value: 'SV', name: '엘살바도르' },
  { value: 'GB', name: '영국' },
  { value: 'YE', name: '예멘' },
  { value: 'AT', name: '오스트리아' },
  { value: 'AX', name: '올랜드 제도' },
  { value: 'JO', name: '요르단' },
  { value: 'UY', name: '우루과이' },
  { value: 'GB_WALES', name: '웨일스' },
  { value: 'IL', name: '이스라엘' },
  { value: 'EG', name: '이집트' },
  { value: 'IT', name: '이탈리아' },
  { value: 'ID', name: '인도네시아' },
  { value: 'JP', name: '일본' },
  { value: 'JE', name: '저지' },
  { value: 'CN', name: '중국' },
  { value: 'CZ', name: '체코' },
  { value: 'CL', name: '칠레' },
  { value: 'ES_CANARY', name: '카나리아 제도' },
  { value: 'CA', name: '캐나다' },
  { value: 'CR', name: '코스타리카' },
  { value: 'CO', name: '콜롬비아' },
  { value: 'KW', name: '쿠웨이트' },
  { value: 'HR', name: '크로아티아' },
  { value: 'CY', name: '키프로스' },
  { value: 'TH', name: '태국' },
  { value: 'TR', name: '터키' },
  { value: 'PY', name: '파라과이' },
  { value: 'PE', name: '페루' },
  { value: 'PT', name: '포르투갈' },
  { value: 'PL', name: '폴란드' },
  { value: 'FR', name: '프랑스' },
  { value: 'GF', name: '프랑스령 기아나' },
  { value: 'FI', name: '핀란드' },
  { value: 'HU', name: '헝가리' },
  { value: 'AU', name: '호주' },
  { value: 'HK', name: '홍콩' },
];
