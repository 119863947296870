// NOTE: 파트너 등록/수정 시 수수료율 input에 사용되는 유효성검사 훅스 입니다.

export const handleFeeRateChange = (value: string) => {
  // 01 과 같이 가장높은자리수가 0일 때
  const isZeroStart = value.indexOf('.') < 1 && value.length > 1 && value[0] === '0';

  // .0. 과 같이 소수점이 다수 찍히는 현상일 때
  const isManyDot = value.indexOf('.') !== value.lastIndexOf('.');

  // 수수료율이 100을 넘을 때
  const isHundredOrOver = Number(value) >= 100;
  // 10.1.1 과같이 정상적인 소수점 표기가 아닐 때
  const isIncorrectFloatExp = isNaN(Number(value));
  // 소수점숫자가 첫째자리를 넘어설 때
  const isOverFirstFloatPlace = value.split('.')[1]?.length >= 2;

  // .2 과같이 정수부가 없는 소수일 때
  const hasNoInteger = value[0] === '.';

  if (isZeroStart) return value.slice(1, value.length);
  if (isManyDot || hasNoInteger) return '';
  if (isHundredOrOver || isIncorrectFloatExp || isOverFirstFloatPlace) return value.slice(0, value.length - 1);
  return value;
};

export const handleFeeRateBeforeBlur = (value: string) => {
  // 10. 과같이 소수점을 찍고 소수점숫자를 입력하지 않았을 때
  const hasExtraFloatingPoint = value[value.length - 1] === '.';
  // 1.0 과 같이 소수점숫자가 0일 때
  const isInteger = value.split('.')[1] === '0';

  if (hasExtraFloatingPoint) return value.slice(0, value.length - 1);
  if (isInteger) return value.split('.')[0];
  return value;
};
