







































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getToday, addDay } from '@/utils/dateFormat';
import DateRangePicker from '@/components/common/DateRangePicker.vue';

import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import MultipleSelect from '@/components/common/input/MultipleSelect.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import NumberRangeInput from '@/components/common/input/NumberRangeInput.vue';

import { Mall } from 'ncp-api-supporter';
import {
  getMemberListInitQuery,
  getMemberListSelectType,
  getMemberOrderBySelectOptions,
} from '@/const/contents/member';
import { mall } from '@/utils/mall';
import { isEqual } from 'underscore';

@Component({
  components: {
    NumberRangeInput,
    SearchButton,
    DateRangePicker,
    SelectBox,
    RadioGroup,
    CheckboxGroup,
    MultipleSelect,
    TextInput,
  },
})
export default class MemberList extends Vue {
  @Prop({ default: null })
  private readonly selectedMallNo!: number;

  private selectType = getMemberListSelectType();

  private readonly memberOrderBySelectOptions = getMemberOrderBySelectOptions;

  private gradeNos = [{ name: this.$t('ALL'), value: '' }];
  private dayOfBirthdayList = [];

  private query = {
    ...getMemberListInitQuery(),
    ...this.$route.query,
  };

  private showHiddenRow = false;
  private lastLogin = '';
  private dormantRelease = '';

  private get malls(): Mall[] {
    return mall.mallsSortedByCreationDate;
  }

  private changeAgeGroup(value: number | null) {
    if (value === -2) {
      this.query.birthmonth = new Date().getMonth() + 1;
      this.dayOfBirthdayList = this.selectType.getBirthDay(this.query.birthmonth);
      this.query.dayOfBirthday = new Date().getDate();
    } else {
      delete this.query.birthmonth;
      delete this.query.dayOfBirthday;
    }
  }

  private changeLastLogin(value: string) {
    if (value === '' && this.query.loginAttempted !== undefined) {
      delete this.query.loginAttempted;
      delete this.query.lastLoginDateFrom;
      delete this.query.lastLoginDateTo;
    } else if (value === 'USE_LOGIN') {
      this.query.loginAttempted = true;
      this.query.lastLoginDateFrom = addDay(new Date(), -7);
      this.query.lastLoginDateTo = getToday();
    } else {
      this.query.loginAttempted = false;
      delete this.query.lastLoginDateFrom;
      delete this.query.lastLoginDateTo;
    }
  }

  private changeDormantRelease(value: string) {
    if (value === '' && this.query.dormantReleaseUse !== undefined) {
      delete this.query.dormantReleaseUse;
    } else if (value === 'USE_DORMANT_RELEASE') {
      this.query.dormantReleaseUse = true;
      this.query.dormantReleaseFrom = addDay(new Date(), -7);
      this.query.dormantReleaseTo = getToday();
    } else {
      this.query.dormantReleaseUse = false;
      delete this.query.dormantReleaseFrom;
      delete this.query.dormantReleaseTo;
    }
  }

  private changeBirthdayList() {
    this.dayOfBirthdayList = this.selectType.getBirthDay(this.query.birthmonth);
  }

  get disableOpenIdSelect(): boolean {
    if (this.query.types !== 'OPEN_ID') {
      this.query.openIdProviders = this.selectType.openIdProviders[0].value;
    }

    return this.query.types !== 'OPEN_ID';
  }

  get useBirthSelect(): boolean {
    return this.query.ageGroup === -2;
  }

  get useLastLoginDateSelect(): boolean {
    return this.lastLogin === 'USE_LOGIN';
  }

  get useDormantReleaseDateSelect(): boolean {
    return this.dormantRelease === 'USE_DORMANT_RELEASE';
  }

  created() {
    this.initQuery();
    this.setQueryFromUrl();
    this.getMemberGrades();
  }

  @Watch('$route.query')
  private initQuery(): void {
    this.query = { ...{}, ...getMemberListInitQuery(), ...this.$route.query };
    if (this.selectedMallNo) {
      this.query.mallNo = this.selectedMallNo;
    }
  }

  private setQueryFromUrl(): void {
    if (!this.$route.query) {
      return;
    }

    Object.assign(this.query, this.$route.query);

    if (this.query.searchType === 'ALL') {
      this.query.searchType = this.selectType.searchType[0].value;
    }
  }

  private changeMall(): void {
    this.query.gradeNos = this.gradeNos[0].value + '';
    this.getMemberGrades();
  }

  private toggleHiddenRow(): void {
    this.showHiddenRow = !this.showHiddenRow;
  }

  private isValidateQuery(): boolean {
    if (this.query.types !== 'OPEN_ID') {
      delete this.query.openIdProviders;
    }

    if (this.query.gender === 'NOT_SELECT') {
      delete this.query.gender;
    }

    if (this.query.ageGroup === -2) {
      delete this.query.ageGroup;
    }

    if (
      (this.query.reserveBalanceFrom && !this.query.reserveBalanceTo) ||
      (!this.query.reserveBalanceFrom && this.query.reserveBalanceTo) ||
      Number(this.query.reserveBalanceFrom) > Number(this.query.reserveBalanceTo)
    ) {
      alert(this.$t('MEMBER.LIST.ALERT_INPUT_ACCUMULATION'));
      return false;
    }

    if (
      (this.query.loginCountFrom && this.query.loginCountTo < 0) ||
      (this.query.loginCountFrom < 0 && this.query.loginCountTo) ||
      Number(this.query.loginCountFrom) > Number(this.query.loginCountTo)
    ) {
      alert(this.$t('MEMBER.LIST.ALERT_LOGIN_COUNT'));
      return false;
    }

    if (this.query.loginCountFrom === 0) {
      this.query.loginCountFrom = '0';
    }

    if (this.query.loginCountTo === 0) {
      this.query.loginCountTo = '0';
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, pageSize, ...query } = this.query;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page: routePage, pageSize: routePageSize, ...routeQuery } = this.$route.query;
    if (!isEqual(query, routeQuery)) {
      this.query.page = 1;
      this.query.pageSize = 30;
    }
    return true;
  }

  private resetQuery(): void {
    const preMallNo = this.query.mallNo;

    this.query = { ...getMemberListInitQuery() };
    this.lastLogin = '';
    this.dormantRelease = '';
    if (this.selectedMallNo) {
      this.query.mallNo = this.selectedMallNo;
    }
    if (this.query.mallNo !== preMallNo) {
      this.getMemberGrades();
    }
  }

  private search() {
    this.$emit('search');
  }

  // get grade list
  private getMemberGrades(): Promise<void> {
    return this.$api.getMemberGrades({ params: { mallNo: this.query.mallNo } }).then(response => {
      if (response && response.status === 200) {
        this.gradeNos = this.gradeNos.slice(0, 1);

        response.data
          .filter(g => g.used)
          .sort((g1, g2) => {
            if (g1.order === 0) {
              return 1;
            }
            return g1.order - g2.order;
          })
          .map(g => {
            this.gradeNos.push({ name: g.name, value: g.no.toString() });
          });
      }
    });
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }
}
