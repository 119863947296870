
















import { Vue, Component, Prop } from 'vue-property-decorator';

export interface TabMenuI {
  display: string;
  path: string;
}

@Component
export default class TabMenu extends Vue {
  @Prop({ required: true })
  private menuList!: TabMenuI[];

  private get currentMallNoQuery(): { mallNo?: string } {
    return this.$route.query.mallNo ? { mallNo: this.$route.query.mallNo.toString() } : {};
  }
}
