







































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NaverPayProcessInfo extends Vue {
  @Prop() private readonly onNegativeClick!: () => void;
}
