

























import { Vue, Component, PropSync, Prop, Watch, Ref } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { formatCurrency, getOnlyNumbers } from '@/utils/numberFormat';
import { InputNumber } from '@/types';
import CurrencyTextInput from '@/components/common/CurrencyTextInput.vue';
import { IMMUTABLE_FIELD } from '@/const/contents/product';
@Component({
  components: { Currency: CurrencyTextInput, TextInput },
})
export default class StockCntInfo extends Vue {
  @PropSync('productStockCnt')
  private productStockCntSync!: number;

  @Prop()
  private readonly isMapping: boolean;

  @Prop()
  private readonly useOption: boolean;

  @Prop()
  private readonly optionCnt: InputNumber;

  @Prop({ required: true })
  private readonly immutableFields;

  @Ref() private readonly stockCntRef: TextInput;
  public focus(): void {
    this.stockCntRef.focus();
  }

  public resetStockCntInfo(): void {
    this.formattedProductStockCnt = '';
    this.productStockCntSync = 0;
  }

  private formattedProductStockCnt = '';

  private IMMUTABLE_FIELD = IMMUTABLE_FIELD;

  @Watch('productStockCnt')
  private initProductStockCnt() {
    if (this.productStockCntSync > 0) {
      this.formattedProductStockCnt = this.productStockCntSync.toString();
    }
  }

  @Watch('optionCnt')
  @Watch('useOption')
  private setProductStockCnt() {
    if (this.useOption) {
      const optionCnt = getOnlyNumbers(this.optionCnt.toString());
      this.productStockCntSync = optionCnt;
      this.formattedProductStockCnt = formatCurrency(optionCnt);
    }
  }
}
