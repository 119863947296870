import { RadioBoxGroupOption } from '@/helpers/type';

export const marketingDisplayableOption: RadioBoxGroupOption<boolean> = {
  name: 'marketingDisplayable',
  data: [
    { id: 'marketingDisplayableTrue', value: true, display: 'PRODUCT.ADD.EXPOSE' },
    { id: 'marketingDisplayableFalse', value: false, display: 'PRODUCT.ADD.NO_EXPOSE' },
  ],
};

export const channelTypeOption = {
  data: [
    {
      display: 'PRODUCT.ADD.ALL',
      id: 'sale_status_all',
      value: 'ALL',
    },
    {
      display: 'PRODUCT.ADD.NAVER_SHOPPING',
      id: 'naver_shopping',
      value: 'NAVER_SHOPPING',
    },
    {
      display: 'PRODUCT.ADD.GOOGLE_AD',
      id: 'google_ad',
      value: 'GOOGLE',
    },
    //TODO 확인
    // {
    //   display: 'PRODUCT.ADD.CRITEO',
    //   id: 'criteo',
    //   value: 'CRITEO',
    // },
    // {
    //   display: 'PRODUCT.ADD.MOBON',
    //   id: 'mobon',
    //   value: 'MOBON',
    // },
  ],
  hasAll: true,
  name: 'channalSort',
  noBrackets: false,
};
