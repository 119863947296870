import { RouteConfig } from 'vue-router';

import CrmLayoutIframe from '@/views/CrmLayoutIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/crm/:memberNo',
    component: CrmLayoutIframe,
  };
};
