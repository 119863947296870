import {
  getBrandNameColumn,
  getCommonGridColumns,
  getDeliveryColumns,
  getDiscountColumns,
  getDisplayCategoryNamesColumn,
  getOptionColumn,
  getProductColumns,
  getSalePeriodColumn,
} from '@/components/product/common/girdColumns/ProductCommonGridColumns';
import { getStockCountColumn } from '@/components/product/common/girdColumns/StockCount';
import { getEditGridColumn } from '@/components/product/common/girdColumns/ProductEdit';
import { getCopyGridColumn } from '@/components/product/common/girdColumns/ProductCopy';
import { getSupplyPriceHeader } from '@/components/product/common/girdColumns/ProductSupplyPrice';
import { i18n } from '@/main';

export const gridProps = {
  columns: [
    getEditGridColumn(),
    getCopyGridColumn(),
    ...getProductColumns(),
    //판매기간
    getSalePeriodColumn(),
    //전시카테고리
    getDisplayCategoryNamesColumn(),
    ...getDiscountColumns(),
    //옵션
    getOptionColumn(),
    //재고수량
    getStockCountColumn(),
    ...getCommonGridColumns(),
    ...getDeliveryColumns(),
    //브랜드
    getBrandNameColumn(),
  ],
  header: {
    height: 50,
    columns: [getSupplyPriceHeader()],
  },
  options: {
    scrollY: true,
    heightResizable: true,
    columnOptions: {
      resizable: true,
    },
  },
};

export const containerOptions = () => {
  return {
    showsRightArea: false,
    headerTooltipOption: [
      {
        id: '#supplyPrice',
        popupData: { data: { message: i18n.t('PRODUCT.PRODUCT.SUPPLY_PRICE_HELP') } },
      },
    ],
  };
};
