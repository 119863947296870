










import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import $ from 'jquery';
import { sendTokenPostMessage } from './util';

@Component({})
export default class AdminIframe extends Vue {
  @Prop({ required: true, default: '' })
  private src: string;

  @Prop({ required: false, default: '' })
  private spinnerId: string;

  @Prop({ required: false })
  private customStyle;

  @Ref()
  public readonly iFrame: HTMLIFrameElement;

  @Watch('src')
  private updateSrc() {
    this.sendMessageIFrame();
    $(`#${this.spinnerId}`).show();

    this.iFrame.setAttribute('url', this.src);
  }

  private receiveMessage(e: MessageEvent) {
    if (e.data !== 'loaded') {
      return;
    }

    this.sendMessageIFrame();
    $(`#${this.spinnerId}`).hide();
  }

  private sendMessageIFrame() {
    sendTokenPostMessage(this.iFrame);
  }

  mounted() {
    this.iFrame.onload = () => {
      $(`#${this.spinnerId}`).hide();
    };

    window.addEventListener('message', this.receiveMessage);
  }

  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }
}
