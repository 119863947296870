export default Object.freeze({
  TITLE: '수기주문 관리',
  MALL: '쇼핑몰',
  MEMBER_SEP: '회원 구분',
  MEMBER: '회원',
  ID_PLACEHOLD: '아이디 입력',
  SEARCH: '조회',
  NOT_MEMBER: '비회원',
  PRODUCT_SEARCH: '상품 조회',
  PROCUCT_REGISTER: '상품 조회 등록',
  PRODUCT_POP_SEARCH: '상품 조회',
  REGISTER_PRODUCT_NO: '상품번호 직접 등록',
  TEXT_AREA_PLACEHOLDER: "복수 상품 등록(최대 30개)이 가능합니다.(2개 이상 입력 시 ','로 구분)",
  ADD: '추가',
  LIST_TITLE: '주문 리스트',
  REMOVE_LIST: '선택 상품 삭제',
  PRODUCT_INFO_TITLE: '상품정보',
  SALE_AMT: '판매가',
  SALE_AMT_1: '(할인적용가)',
  TOTAL_AMT_TITLE: '상품합계',
  TOTAL_AMT_TITLE_1: '(할인적용가)',
  EMPTY_TEXT_1: '[상품 조회] 및 직접 등록으로 [추가] 시 주문 리스트가 생성됩니다.',
  EMPTY_TEXT_2: '생성된 주문 리스트 확인 후 [주문 확정]하여 결제 진행해 주세요.',
  OPTION: '옵션',
  OPTION_NEED_ALL_TEXT: '옵션을 선택해 주세요.',
  ADD_OPTION_BTN: '옵션추가',
  YUAN: '원',
  PRODUCT_INPUT_TEXT: '옵션별 구매자 작성형',
  OPTION_INPUT_TEXT: '상품별 구매자 작성형',
  DELIVERY_AMT: '배송비',
  ARIVE_PAY: '(착불)',
  OPTION_UNIT: '개',
  SAVE_ORDER_SHEET: '주문확정',
  MODIFY_ORDER_SHEET: '주문수정',

  COUPON_TITLE: '할인 정보',
  COUPON_AMT: '쿠폰 할인금액',
  SHOW_COUPON_BTN: '쿠폰적용',
  PRIVATE_USE: '사용',
  ERROR_ACCUMULATION: '입력형식이 잘못되었습니다.',
  APPLY_ACCUMULATION_BTN: '전액사용',
  HAS: '보유',
  USEABLE: '사용 가능',

  ORDER_TITLE: '주문자 정보',
  THIE_ORDER_MAN: '주문자',
  PHONE_NUM: '휴대폰 번호',
  EMAIL: 'e메일 주소',
  DOMIN_NEED_ALL_TEXT: '직접입력',

  ADDRESS_TITLE: '배송지 정보',
  CHOSE_ADDRESS: '배송지 선택',
  NEWEST_ADDRESS: '최근 배송지',
  NEW_ADDRESS: '신규 배송지',
  RECIVER: '받는사람',
  ADREES: '주소',
  ADDRESS_SEARCH: '우편번호 찾기',
  ADDRESS_PHONE_NUM: '휴대폰 번호',
  INPUT_ALERT: '입력형식이 잘못되었습니다.',
  CONNECT: '연락처',
  CUSTOM_ID: '개인통관고유부호',
  CUSTOM_ID_TEXT_1: '개인통관고유부호란',
  CUSTOM_ID_TEXT_2: '개인통관고유부호란 해외직구상품의 배송 시 사용되는 개인고유번호 입니다.',
  CUSTOM_ID_TEXT_3: '국내 통관 절차를 위해 상품 받는사람의 개인통관부호를 입력해 주세요.',
  ADDRESS_MEMP: '배송 메모',
  INPUT_ADDRESS: '직접입력',
  CUSTOMS_ID_NUM: '받는사람의 개인통관고유부호를 입력해 주세요.',
  DELIVERY_MEMO_ALL_TEXT: '배송 메모를 선택해 주세요.',
  DELIVERY_MEMO_PLACEHOLDER: '배송 메모를 입력해 주세요.',

  PAY_PART_TITLE: '최종 결제금액',
  PRODUCT_AMT_TOTAL: '총 상품합계',
  PRODUCT_AMT_TOTAL_2: '(할인적용가)',
  PAY_COUPON_AMT: '총 쿠폰 할인금액',
  PAY_DELIVERY_AMT: '총 배송비',
  PAY_ACCUMULATION_NAME: '적립금',
  PAY_ACCUMUALTION_USE_AMT: '사용금액',
  PAY_PAYMEMT_AMT: '총 결제금액',
  PAY_PG_TYPE: 'PG타입',
  PAY_PAY_TYPE: '결제수단',
  PAY_PAYMENT_BTN: '결제하기',

  CONFIRM_1: '쇼핑몰 변경 시 정보가 모두 초기화됩니다. 변경하시겠습니까?',
  CONFIRM_2: '상세주소를 입력하지 않고 주문하시겠습니까?',
  CONFIRM_3: '선택하신 상품을 삭제하시겠습니까?',
  CONFIRM_4: '회원 선택 시 입력된 정보가 모두 초기화 됩니다. 회원구분을 변경하시겠습니까?',
  CONFIRM_5: '비회원 선택 시 입력된 정보가 모두 초기화 됩니다. 회원구분을 변경하시겠습니까?',
  CONFIRM_6: '아이디 재 조회 시 입력된 정보가 모두 초기화됩니다. 재 조회하시겠습니까?',
  CONFIRM_7: '주문수정 시 입력한 하단 정보(쿠폰, 배송지 등)가 초기화 됩니다. 주문수정을 계속 하시겠습니까?',

  ALERT_1: '주문자명을 입력해 주세요.',
  ALERT_2: '주문자 휴대폰번호를 입력해 주세요.',
  ALERT_3: '이메일정보를 입력해 주세요.',
  ALERT_4: '받는사람을 입력해 주세요.',
  ALERT_5: '주소 입력해 주세요.',
  ALERT_6: '받는사람 휴대폰번호를 입력해 주세요.',
  ALERT_7: '개인통관고유부호를 정확히 입력해 주세요.',
  ALERT_8: '상품번호 및 상품상태가 유효하지 않습니다. 확인 후 등록해 주세요.',
  ALERT_9: '주문 가능한 {num}개 상품이 등록되었습니다.',
  ALERT_10: '이미 등록된 상품입니다.',
  ALERT_11: '주문 가능한 {nums}개 상품이 등록되었습니다.',
  ALERT_12: '삭제할 상품을 선택해 주세요.',
  ALERT_13: '사용 가능한 임직원포인트을(를) 초과했습니다. 다시 입력해 주세요.',
  ALERT_14: '사용 가능한 {name}이(가) 없습니다',
  ALERT_15: '비회원은 쿠폰을 적용할 수 없습니다.',
  ALERT_16: '적용 가능한 쿠폰이 없습니다.',
  ALERT_17: '아이디를 입력해 주세요.',
  ALERT_18: '존재하지 않는 아이디입니다. 확인 후 다시 입력해 주세요.',
  ALERT_19: '주문확정된 상태입니다. 상품 추가는 주문수정 버튼 클릭 후 진행해 주세요.',
  ALERT_20: '상품번호를 확인해 주세요.',
  ALERT_21: '아이디를 입력 후 조회해 주세요.',
  ALERT_22: '아이디를 확인해 주세요.',
  ALERT_23: '상품을 추가해 주세요.',
  ALERT_24: '옵션을 선택해 주세요.',
  ALERT_25: '구매자 작성형을 입력해 주세요.',
  ALERT_26: '주문 리스트 상품 전체가 주문 확정되었습니다. 쿠폰/{name} 적용 후 결제 진행해 주세요.',
  ALERT_27: '기존 등록된 상품을 포함하여 최대 30개까지 등록 가능합니다.',

  OPTION_TEXT_PRODUCT: '상품별 구매자 작성형',
  OPTION_TEXT_OPTION: '옵션별 구매자 작성형',

  COUPON_POP_TITLE: '쿠폰 적용 팝업',
  COUPON_POP_OPEN_TITLE: '수기주문 쿠폰 적용',
  ADDRESS_POP_TITLE: '우편번호 찾기',
  EMAIL_ERROR_TEXT_1: '특수문자는 !@#$%^&+=-_.()만 입력 가능합니다.',
  EMAIL_ERROR_TEXT_2: '올바른 이메일을 입력해 주세요.',
  PRODUCT_POP_TITLE: '수기주문 상품 추가',
  NAME_UNIT: '외',
  CNT_NUIT: '건',
  FREE: '무료',

  INIT_PA_TYPE_DUMMY: '없음',
  INIT_PA_TYPE_PAYCO: 'PAYCO',
  INIT_PA_TYPE_PAYPAL: 'PAYPAL',
  INIT_PA_TYPE_STRIPE: 'STRIPE',
  INIT_PA_TYPE_KCP: 'KCP',
  INIT_PA_TYPE_INICIS: '이니시스',
  INIT_PA_TYPE_NONE: 'PG없음',
  INIT_PA_TYPE_KCP_MOBILE: 'KCP(모바일)',
  INIT_PA_TYPE_KCP_APP: 'KCP(앱)',
  INIT_PA_TYPE_NAVER_PAY: '네이버페이(주문형)',
  INIT_PA_TYPE_LIIVMATE: '리브메이트',
  INIT_PA_TYPE_PAYPALPRO: 'PAYPAL PRO',
  INIT_PA_TYPE_ATHOR_NET: 'AthorizeNet',
  INIT_PA_TYPE_KAKAO_PAY: '카카오페이',
  INIT_PA_TYPE_NAVER_EASY_PAY: '네이버페이(간편결제)',
  INIT_PA_TYPE_LG_U_PLUS: '토스페이먼츠',
  INIT_PA_TYPE_VERITRANS: 'Veritrans',
});
