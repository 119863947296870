export default Object.freeze({
  BASIC_IMFORMATION: '기본정보',

  MALL_NAME: '쇼핑몰',
  SELECT_MALL_ALL: '쇼핑몰을 선택하세요.',

  PLATFORM_TYPES: '플랫폼 구분',
  PLATFORM_TYPES_LABEL_PC: 'PC웹',
  PLATFORM_TYPES_LABEL_MOBILE: '모바일웹',
  PLATFORM_TYPES_MOBILE: '모바일',

  POPUP_TITLE: '팝업제목',
  POPUP_TITLE_PLACEHOLDER: '팝업 제목을 입력해주세요.',

  DISPLAYABLE: '노출여부',
  DISPLAYABLE_LABEL_TRUE: '노출함',
  DISPLAYABLE_LABEL_FALSE: '노출 안 함',

  DISPLAY_PERIOD_TYPE: '노출 설정',
  DISPLAY_PERIOD_TYPE_LABEL_ALWAYS: '항상 열림',
  DISPLAY_PERIOD_TYPE_LABEL_PERIOD: '특정 기간 동안 열림',
  DISPLAY_PERIOD_TYPE_LABEL_TIME: '특정 시간 동안 열림',
  DISPLAY_PERIOD_TIME: '시간',
  DISPLAY_PERIOD_TIME_WAVY_LINE: '~',

  VISIBLETODAY: '오늘 하루 보이지 않음',
  VISIBLETODAY_LABEL: '사용',

  POPUP_DESIGN_TYPE: '팝업유형',
  POPUP_DESIGN_TYPE_LABEL_NORMAL: '일반팝업',
  POPUP_DESIGN_TYPE_LABEL_MULTI: '멀티팝업',

  POPUP_CONTENT: '팝업 내용',

  SCREEN_TYPE: '팝업창 종류',
  SCREEN_TYPE_LABEL_NORMAL_PC_FIXED: '고정 레이어창',
  SCREEN_TYPE_LABEL_NORMAL_PC_FIXED_TOP: '상단 고정 레이어창',
  SCREEN_TYPE_LABEL_NORMAL_PC_LAYER: '이동 레이어창',
  SCREEN_TYPE_LABEL_NORMAL_PC_WINDOW: '윈도우 팝업창',
  SCREEN_TYPE_LABEL_MULTI_PC_FIXED: '고정 레이어창',
  SCREEN_TYPE_LABEL_MULTI_PC_LAYER: '이동 레이어창',
  SCREEN_TYPE_LABEL_MULTI_PC_WINDOW: '윈도우 팝업창',
  SCREEN_TYPE_LABEL_NORMAL_MOBILE_FIXED: '고정 레이어창',
  SCREEN_TYPE_LABEL_NORMAL_MOBILE_FIXED_TOP: '상단 고정 레이어창',
  SCREEN_TYPE_LABEL_MULTI_MOBILE_FIXED: '고정 레이어창',

  SCREEN_SIZE: '창크기',
  SCREEN_SIZE_TOOL_TIP_MESSAGE:
    '권장 사이즈는 PC : 가로 최대 1000px / 80% 이하, 모바일 : 가로 최대 400px / 80% 이하이며, 높이(height)는 이미지 크기에 따라 스크롤 또는 여백이 발생될 수 있습니다.',
  SCREEN_SIZE_MULTIPLICATION: 'X',
  SCREEN_SIZE_RESIZABLE_LABEL: '브라우저 가로 창크기(width)에 에디터 이미지를 맞춤',

  SCREEN_POSITION: '창위치',
  SCREEN_POSITION_TOOL_TIP_MESSAGE: `레이어창은 브라우저 화면 내 왼쪽 상단을 기준으로 계산되어 노출됩니다.
  윈도우 팝업창은 모니터 화면 왼쪽 상단을 기준으로 계산되어 노출됩니다.`,
  SCREEN_POSITION_TOP: '상단',
  SCREEN_POSITION_LEFT: '좌측',
  PIXEL: 'Pixel',

  BACKGROUND_COLOR: '배경색상',

  DISPLAY_PAGE: '팝업 노출 페이지',
  DISPLAY_PAGE_TOOL_TIP_MESSAGE: `파라메터 : 특정 카테고리, 상세 페이지등 팝업을 노출할 경우 해당 페이지의 파라메터를 입력해야 합니다.
  예) 카테고리(카테고리NO=001)에 팝업을 노출할 경우 &quot;상품리스트 페이지 : /pages/product/view.html&quot;
  선택 후 cateCd=001 파라메터 입력`,
  DISPLAY_PAGE_MANAGE_BUTTON: '관리',
  DISPLAY_PAGE_LABEL_PARAM: '파라메터',
  DISPLAY_PAGE_PARAM_PLACEHOLDER: '파라메터 직접 입력',
  DISPLAY_PAGE_ROW_ADD_BUTTON: '행추가',
  DISPLAY_PAGE_ROW_REMOVE_BUTTON: '행삭제',

  COPY_PC_DESIGN_LABEL: 'PC웹 내용과 동일하게 적용 (팝업창 종류 제외)',
  COPY_PC_DESIGN_TOOL_TIP_MESSAGE: `팝업창 종류를 제외한 항목(창크기, 창위치, 배경색상, 팝업 노출 페이지)이 PC웹에서 작성하신 내용에 자동 입력됩니다. (적용 해제 시, 자동 입력된 항목 유지)
  - PC에 추가로 등록한 팝업 노출 페이지는 모바일에도 추가 후 동일 적용해야 합니다.`,

  POPUP_SLIDE: '팝업 효과 설정',
  SLIDE_DIRECTION: '이미지 이동방법',

  SLIDE_DIRECTION_FIXED: '이동하지 않음(고정)',
  SLIDE_DIRECTION_RIGHT: '오른쪽에서 왼쪽으로 이동',
  SLIDE_DIRECTION_LEFT: '왼쪽에서 오른쪽으로 이동',
  SLIDE_DIRECTION_UP: '아래쪽에서 위쪽으로 이동',
  SLIDE_DIRECTION_DOWN: '위쪽에서 아래쪽으로 이동',

  SLIDE_SPEED: '이미지 이동속도',
  SLIDE_COUNT: '이미지 개수',
  TWO_BY_ONE: '2x1',
  THREE_BY_ONE: '3x1',
  FOUR_BY_ONE: '4x1',
  TWO_BY_TWO: '2x2',
  THREE_BY_TWO: '3x2',
  FOUR_BY_TWO: '4x2',

  SLIDE_MAX_SIZE: '큰 이미지 크기',
  WIDTH: '가로',
  HEIGHT: '세로',
  SLIDE_MIN_SIZE: '작은 이미지 크기',
  SLIDE_MAX: '큰 이미지',
  SLIDE_MIN: '작은 이미지',
  ALERT_INPUT_NOT_VALID: '{fieldName} 크기를 입력해주세요.',

  POPUP_IMAGE_SETTING: '팝업 이미지 설정',
  POPUP_IMAGE_SETTING_TOOL_TIP_MESSAGE: `팝업 이미지는 직접 업로드 또는 이미지호스팅 URL로 입력이 가능합니다.
  큰 이미지는 상단에 작은 이미지 클릭 시, 크게 확인하는 이미지로 사용됩니다.
  작은 이미지1, 2는 이미지 개수에서 선택한 배열(예: 2X1) 이미지 중 작게 보여지는 이미지 롤링 적용입니다.`,
  POPUP_IMAGE_NAME_PREFIX: '이미지',
  POPUP_IMAGE_REGISTRATION_TYPE: '이미지 등록방식',
  POPUP_IMAGE_REGISTRATION_UPLOAD: '직접 업로드',
  POPUP_IMAGE_REGISTRATION_URL: '이미지호스팅 URL입력',
  POPUP_IMAGE_URL_INPUT_PLACEHOLDER: '외부 이미지 URL을 입력해주세요.',

  POPUP_SLIDE_IMAGE_LABEL_RESIZABLE: '크기에 맞게 이미지를 맞춤',
  POPUP_SLIDE_IMAGE_RESIZABLE_TOOL_TIP_MESSAGE: `크기에 맞게 이미지를 맞춤 설정 시, 
  업로드된 이미지가 설정한 이미지 크기에 맞게 사이즈 조정됩니다.`,

  MAIN_IMAGE: '큰 이미지',
  THUMB_IMAGE: '작은 이미지1',
  THUMB_IMAGE_ON_OVER: '작은 이미지2',

  DELETE_FILE: '파일삭제',

  LANDING_URL: '링크 주소',
  LANDING_URL_PLACEHOLDER: '이미지 링크를 입력해주세요.',
  OPEN_LOCATION_TARGET_SELF: '현재창',
  OPEN_LOCATION_TARGET_BLANK: '새창',
  NO_IMAGE: '이미지 없음',

  MALL_SELECT_ALERT: '쇼핑몰을 선택해주세요.',
  PLATFORM_CHECK_ALERT: 'PC웹 또는 모바일웹을 체크해주세요.',
  TITLE_INPUT_ALERT: '팝업 제목을 입력해주세요.',
  EDITOR_INPUT_ALERT: '팝업 내용을 입력해주세요.',
  SCREENWIDTH_INPUT_ALERT: '창 가로크기를 입력해 주세요.',
  SCREENHEIGHT_INPUT_ALERT: '창 세로크기를 입력해 주세요.',
  DISPLAYURL_EMPTY_ALERT: '팝업 노출 페이지를 선택해주세요.',
  MAIN_IMAGE_EMPTY_ALERT: '큰 이미지를 등록해주세요.',
  THUMB_IMAGE_EMPTY_ALERT: '작은 이미지를 등록해주세요.',
  END_DATE_EMPTY: '종료일자는 반드시 값이 있어야 합니다.',
  TIME_FORMAT_ERROR: '노출 설정 시간격식이 맞지 않습니다.',

  SAVE_SUCCESS: '저장이 완료되었습니다.',
  MODIFY_SUCCESS: '수정되었습니다.',
  COPY_PC_ALERT: 'PC웹에 적용된 팝업 노출 페이지를 모바일웹에 등록해주세요.',
  DELETE_POPUP_CONFIRM: '삭제하신 팝업창은 복구가 불가능합니다.정말 삭제하시겠습니까?',
  DELETE_SUCCESS: '팝업창이 삭제되었습니다.',

  PREVIEW_POPUP_TITLE_NORMAL: '일반팝업 미리보기',
  PREVIEW_POPUP_TITLE_MULTI: '멀티팝업 미리보기',
  PREVIEW_POPUP_BUTTON_CLOSE: '닫기',
  PREVIEW_POPUP_BUTTON_INVISIBLETODAY: '오늘 하루 보이지 않음',

  MANAGE_DISPLAYURLS_TITLE: '팝업 노출 페이지 관리',
  MANAGE_DISPLAYURLS_LABLE: '팝업 노출 페이지 등록/수정',
  MANAGE_DISPLAYURLS_PLATFORMTYPES: '플랫폼 구분',
  MANAGE_DISPLAYURLS_PAGENAME: '페이지명',
  MANAGE_DISPLAYURLS_PLACEHOLDER: '페이지명을 입력해주세요. (최대 20자)',
  MANAGE_DISPLAYURLS_URL: 'URL',
  MANAGE_DISPLAYURLS_URL_PLACEHOLDER: '도메인 제외한 상대경로를 등록해주세요. (예 : pages/product/view.html)',
  MANAGE_DISPLAYURLS_URL_TOOL_TIP_MESSAGE: `URL 등록 시 절대 경로가 아닌 상대 경로로 등록해주세요. (중복 등록 불가)
  절대경로 : shopbypromall.shopbypromall.com/pages/product/view.html
  상대경로 : pages/product/view.html`,
  MANAGE_DISPLAYURLS_BUTTON_ADD: '페이지 추가',
  MANAGE_DISPLAYURLS_BUTTON_INIT_ADD: '초기화',
  MANAGE_DISPLAYURLS_BUTTON_UPDATE: '페이지 수정',
  MANAGE_DISPLAYURLS_BUTTON_DELETE: '선택삭제',
  CONFIRM_INIT_DISPLAYPAGE: '팝업 노출 페이지를 초기화 하시겠습니까?',

  MANAGE_DISPLAYURLS_GRID_PAGENAME_URL: '페이지명/URL',
  MANAGE_DISPLAYURLS_GRID_LIST: '팝업 노출 페이지 리스트',

  PLATFORM_EMPTY: 'PC웹 또는 모바일웹을 선택해주세요.',
  PAGENAME_EMPTY: '페이지명을 입력해주세요.',
  DISPLAYURL_EMPTY: '팝업 노출 페이지 URL을 입력해주세요.',
  SAVE_DISPLAYURL_SUCCESS: '페이지 추가되었습니다.',
  DELETE_SELECT_EMPTY: '팝업 노출 페이지 선택해주세요.',
  DELETE_DISPLAYURL_CONFIRM: '삭제하신 팝업창은 복구가 불가능합니다.정말 삭제하시겠습니까?',
  DELETE_DISPLAYURL_CONFIRM_TITLE: '‘팝업 노출 페이지’삭제',
  DELETE_DISPLAYURL_SUCCESS: '삭제되었습니다.',

  SEARCH_KEYWORD_PLACEHOLDER: '팝업 제목을 입력해주세요.',
  KEYWORD_TOO_LONG: '검색어 길이가 너무 깁니다.',
  SEARCH_PERIOD: '기간검색',
  SELECT_MALL: '쇼핑몰을 선택해주세요.',

  DELETE_BUTTON: '선택삭제',
  ADD_BUTTON: '팝업창 등록',
  DELETE_SELECT_POPUP_EMPTY: '삭제할 팝업창을 선택해주세요.',
  DELETE_POPUPS_CONFIRM: '선택한 {value} 개의 팝업창을 정말로 삭제 하시겠습니까?<br/>삭제시 복구가 불가능합니다.',
  DELETE_POPUPS_TITLE: `‘팝업창’삭제`,

  TO_VISIBLE_CONFIRM: '[팝업 제목]을 노출함 상태로 변경하시겠습니까 ?',
  TO_INVISIBLE_CONFIRM: '[팝업 제목]을 노출 안 함 상태로 변경하시겠습니까 ?',
  STATUS_CHANGE_TITLE: '상태 변경',
  STATUS_CHANGE_SUCCESS: '노출 상태가 변경되었습니다.',

  POPUP_WINDOWS_INFO: '팝업창 정보',
  WINDOWS_TYPE: '창종류',
  WINDOWS_SIZE: '창크기',
  POPUP_DISPLAY_PAGE: '팝업 노출 페이지',

  WAIT: '대기',
  DONE: '종료',

  TERM_REGISTER_YMDT: '등록일',
  TERM_UPDATE_YMDT: '수정일',
  TERM_PERIOD: '노출기간',

  DESIGN_POPUP_DISPLAY_TYPE_ALL: '노출여부',
  DESIGN_POPUP_DISPLAY_TYPE_VISIBLE: '노출함',
  DESIGN_POPUP_DISPLAY_TYPE_INVISIBLE: '노출 안 함',

  GRID_DATA_BUTTON_PREVIEW: '미리보기',
});
