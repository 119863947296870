





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import { GetInspectionsReject } from 'ncp-api-supporter/dist/types/modules/product/inspection';
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { DutyInformation } from '@/types';
import { throwWindowPopup } from '@/helpers/popup';
import { UPSERT_TYPE } from '@/views/contents/product/basic/ProductAdd/salesInfo/details/DutyInfo.vue';
import { PRODUCT_OPTION_TYPE } from '@/views/popups/product/productAdd/ProductOption.vue';
import { unescapeHtml } from '@/utils/common';
import { ProductsSearchContent } from 'ncp-api-supporter/dist/types/modules/product/product';
import { APPROVAL_ITEMS_BTN_ID } from '@/const/contents/product';

type RejectReasons = {
  section?: string;
  items?: RejectReason[];
};
type RejectReason = {
  item?: string;
  detail?: {
    data?: string; //<td>contents</td>
    comment?: string;
  };
};
//NOTE : BASIC_INFO: 기본정보, SALE_INFO: 판매정보, IMAGE_INFO: 이미지정보, DELIVERY_INFO: 배송정보, ETC_INFO: 별도 승인거부 의견
type ProductSectionTitle = 'BASIC_INFO' | 'SALE_INFO' | 'IMAGE_INFO' | 'DELIVERY_INFO' | 'ETC_INFO';
@Component({
  components: {
    ModalHeader,
    NoticeBox,
    TextInput,
  },
})
export default class ApprovalRejectionView extends Vue {
  @Prop() private readonly data?: ProductsSearchContent;
  private rejectReasons: RejectReasons[] = [];
  private readonly mallProductNo?: string = this.data?.mallProductNo.toString();

  //notice
  private readonly noticeList = [
    this.$t('PRODUCT.COMMON.PRODUCT_JUDGE_RESULT_NOTI_MESSAGE_1'),
    this.$t('PRODUCT.COMMON.PRODUCT_JUDGE_RESULT_NOTI_MESSAGE_2'),
  ];

  private getSectionName(section: ProductSectionTitle) {
    return this.$t(`PRODUCT.COMMON.${section}`).toString();
  }

  private hasComments(reason: RejectReason, reasons: RejectReasons) {
    return reason.detail?.comment && !['ETC_INFO', 'IMAGE_INFO'].includes(reasons.section);
  }

  //버큰 클릭 : 팝업 오픈
  private openPopup(event: Event) {
    const clickedButton = event.target as HTMLInputElement;
    switch (clickedButton.id) {
      case APPROVAL_ITEMS_BTN_ID.dutyContent:
      case APPROVAL_ITEMS_BTN_ID.inspectionDutyContent:
        this.openDutyContentPopup(); //상품정보고시
        break;
      case APPROVAL_ITEMS_BTN_ID.certificationInfo:
      case APPROVAL_ITEMS_BTN_ID.inspectionCertificationInfo:
        this.openCertificationInfoPopup(); //인증정보
        break;
      case APPROVAL_ITEMS_BTN_ID.productOptionInfo:
      case APPROVAL_ITEMS_BTN_ID.inspectionProductOptionInfo:
        this.openOptionPopup(); //옵션
        break;
    }
  }

  //팝업: 상품정보고시
  private async openDutyContentPopup(): Promise<void> {
    if (!this.mallProductNo) return;
    const { data: dutyCategories } = await this.$api.getDutyCategories();
    const { data: productDuty } = await this.$api.getProductDuty({
      params: { productNo: this.mallProductNo },
    });
    const dutyInfo: DutyInformation = {
      saveTitle: '',
      useYn: 'Y',
      dutyContent: {
        categoryNo: productDuty.categoryNo,
        categoryName: productDuty.categoryName,
        contents: productDuty.contents,
      },
    };

    throwWindowPopup('UpsertDutyInfo', { type: UPSERT_TYPE.insert, dutyCategories, dutyInfo, isReadonly: true }, 'md');
  }

  //팝업: 인증정보
  // todo certifications 데이터 필요할까 ?
  private openCertificationInfoPopup(): void {
    throwWindowPopup(
      'UpsertCertificationInfo',
      {
        categoryNo: this.data?.categoryNo,
        isReadonly: true,
      },
      'lg',
      () => null,
      '',
      undefined,
      undefined,
      820,
    );
  }

  //팝업 : 옵션
  private async openOptionPopup(): Promise<void> {
    if (!this.data || !this.mallProductNo) return;
    const { saleMethodType, mallNo, mallProductNo, deliveryTemplateNo, purchasePrice } = this.data;
    const { data: productInfo } = await this.$api.getProductsMallProductNo({
      pathParams: { mallProductNo: this.mallProductNo },
    });
    const { reservationInfo, options, optionSelectType } = productInfo;

    throwWindowPopup(
      'ProductOption',
      {
        type: PRODUCT_OPTION_TYPE.MODIFICATION,
        mallNo,
        mallProductNo,
        deliveryTemplateNo,
        saleMethodType,
        useReservation: reservationInfo,
        purchasePrice: purchasePrice,
        savedOption: {
          options: options.map((option: { optionName: string }) => ({
            ...option,
            optionName: unescapeHtml(option.optionName),
          })),
          optionSelectType,
        },
        isReadonly: true,
      },
      'xlg',
    );
  }

  //상품심사거절 조회
  private async fetchInspectionRejectData() {
    if (!this.mallProductNo) return;
    const { data } = await this.$api.getInspectionsReject({
      params: { mallProductNo: this.mallProductNo },
    });
    this.setInspectionRejectData(data);
  }
  private setInspectionRejectData(data: GetInspectionsReject) {
    const { rejectReasons } = data;
    this.rejectReasons = rejectReasons ?? [];
  }
  async created() {
    await this.fetchInspectionRejectData(); //상품심사거절 조회
  }

  /**
   * WARN : v-html 을 통해 html template 을 렌더해야하는데,
   * td 가 부모일 경우, 아닐 경우 가 있어 아닐 경우 콘테츠에서 <td /> 가 있으면 해당 문자열을 제거함
   */
  private removeHTMLTemplateTd(htmlTemplate: string) {
    return htmlTemplate.replaceAll('<td>', '').replaceAll('</td>', '');
  }
}
