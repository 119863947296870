export default Object.freeze({
  TITLE: '아이콘 관리',
  GUIDE1: '등록된 아이콘은 상품등록/수정 시, 선택할 수 있습니다.',
  GUIDE2: '아이콘은 상품에 적용된 아이콘 중 진열순서가 빠른 순 부터 노출됩니다.',
  GUIDE3: '아이콘 이미지의 경우 세로 사이즈 20px 기준으로 등록된 이미지 비율에 맞춰 리사이징 됩니다.',
  BUTTON_DELETE: '선택 삭제',
  BUTTON_ADD: '아이콘 등록',
  SAVE: '변경사항 저장',
  CONFIRM1: '상품에 등록된 스티커가 삭제됩니다. 삭제하시겠습니까?',
  HEADER_MODIFY: '수정',
  HEADER_TYPE: '형식',
  HEADER_NAME: '아이콘명',
  HEADER_CONTENT: '미리보기',
  HEADER_USEYN: '사용여부',
  HEADER_PRODUCT: '적용상품',
  HEADER_DELETE: '삭제',
  HEADER_ORDER: '진열순서',
  HEADER_UNIT: '개',
  USE: '사용함',
  NOT_USE: '사용 안 함',
  VIEW: '보기',
  IMAGE: '이미지',
  TEXT: '텍스트',
  TITLE_UPDATE: '아이콘 수정',
  TITLE_ADD: '아이콘 등록',
  TITLE_MODIFY: '아이콘 수정',
  GUIDE4: '이미지 등록 가이드',
  GUIDE5: '파일용량 10kb 미만',
  GUIDE6: '파일형식 jpg, jpeg, gif, png, bmp',
  PLACEHOLDER: '내용을 입력해주세요.',
  IMAGE_UPLOAD: '이미지 업로드',
  ALERT: '등록 실패하였습니다.\r\n최대 10KB까지 가능합니다.',
  ALERT_SAVE: '스티커가 저장되었습니다.',
  ALERT1: '아이콘을 선택해주세요',
  ALERT2: '내용을 입력해주세요.',
  ALERT3: '이미지파일(Jpg,jpge,gif,png,bmp)만 등록해주세요.',
  CONFIRM_EDIT: '상품에 등록된 아이콘이 수정됩니다. 수정하시겠습니까?',
  CONFIRM_REMOVE: '선택한 아이콘을 삭제하시겠습니까?\n' + '삭제 시 적용된 상품에서도 해당 아이콘 모두 제거됩니다.\n',
  REMOVED: '아이콘 삭제가 완료되었습니다.\n',
  CONFIRM_USE:
    '사용여부를 변경하시겠습니까? 사용 함으로 변경 시 적용된 상품이 있는경우 쇼핑몰에 해당 아이콘이 노출됩니다.\n' +
    '변경 후 하단 [저장]버튼 클릭 시 최종 저장됩니다.\n',
  CONFIRM_NOT_USE:
    '사용여부를 변경하시겠습니까? 사용 안 함으로 변경 시 쇼핑몰에 해당 아이콘이 노출되지 않습니다.\n' +
    '변경 후 하단 [저장]버튼 클릭 시 최종 저장됩니다.\n',
  CONFIRM_SAVE: '저장하시겠습니까?\n' + '설정한 진열순서 및 사용여부가 반영됩니다.',
  SAVED: '저장 완료되었습니다.\n',
  CONFIRM_EXIT:
    '페이지를 이동할 경우 변경된 설정은 저장되지 않습니다.\n' + '저장 하지 않고 페이지 이동하시겠습니까? \n',
  APPLIED_PRODUCT_PLACEHOLDER: '복수 상품 검색이 가능합니다. (Enter 또는 ‘,’로 구분)',
  APPLIED_PRODUCT_TITLE: '적용상품 보기',
  APPLIED_PRODUCT_NOTICE_1: '아이콘이 적용된 상품리스트를 확인할 수 있습니다.',
  APPLIED_PRODUCT_NOTICE_2:
    '[수정] 버튼 및 상품번호 클릭 시 해당 상품 수정페이지가 출력되며, 수정페이지에서 아이콘 적용/해제가\n' +
    '            가능합니다.',
  CONFIRM_TO_LEAVE:
    '페이지를 이동할 경우 변경된 설정은 저장되지 않습니다.\n' + '저장 하지 않고 페이지 이동하시겠습니까?',

  //  IconUpsert
  ADD_NOTICE_1:
    '아이콘 형식이 이미지일경우 jpg, jpeg, gif, png, bmp 형식만 등록 가능하며 용량은 최대 10KB 미만으로 등록해주세요.',
  ADD_NOTICE_2: '아이콘 이미지는 세로 20px 기준으로 등록된 이미지 비율에 맞춰 리사이징 됩니다.',
  ADD_NOTICE_3: '아이콘명은 아이콘 관리용으로 쇼핑몰에는 노출되지 않습니다.',
  ADD_NOTICE_4:
    '해당 아이콘을 특정 상품에 설정 값으로 적용하는 경우 등록된 이미지 또는 텍스트 내용이 쇼핑몰에 반영됩니다.',

  HEADER_CONFIG: '아이콘 설정',
  ADD_IMG: '이미지 등록',
  MODIFY_IMG: '이미지 변경',
  PLACEHOLDER_ICON_NAME: '아이콘명을 입력하세요.',
  PLACEHOLDER_ICON_CONTENT: '텍스트 형식의 아이콘 내용을 입력해주세요.',

  NEED_ORIGIN: '아이콘을 선택해주세요.',
  SELECTED: '선택한 아이콘을',
  PLZ_ICON_NAME: '아이콘명을 입력해주세요.',
  PLZ_ICON_IMAGE: '아이콘 이미지를 등록해주세요.',
  PLZ_ICON_TEXT: '텍스트 아이콘을 입력해주세요.',
  SAVED_ICON: '저장되었습니다.',

  ICON: '아이콘',

  SEARCH_RESULT: '검색결과 총',

  NOTICE_FILE_EXT: '이미지 파일만 업로드 가능합니다. \n (확장자 : JPG, JPEG, GIF, PNG, BMP)',
  NOTICE_LIMIT_CAPACITY: '이미지 파일은 1개당 최대 10KB까지 업로드 가능합니다.',

  NO_DATA_MSG_FOR_APPLIED_PRODUCT: '적용된 상품이 없습니다.',

  ALERT_MAX_ADDABLE_SIZE: '아이콘은 최대 200개까지 등록 가능합니다.',
  ALERT_NOT_REMOVABLE: '해당 아이콘은 삭제가 불가합니다.\n상품의 아이콘을 변경 후 다시 시도해주세요.',
  ALERT_NOT_ADDABLE: '파일명 길이 제한으로 업로드에 실패하였습니다.',
});
