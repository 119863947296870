// text helper
import { i18n } from '@/main';
// types
import tuiGrid, { RowKey } from '@/types/tui-grid';
import { CellRenderer } from '@/types/tui-grid/renderer';
import {
  AnchorTagInfo,
  RendererMapByContractStatus,
  PartnerRendererProps,
  PartnerRowData,
} from '@/views/contents/partner/modification/PartnerModificationTypes';
// get html
import {
  getGridAnchorGroup,
  getGridTextSpan,
  getGridTextSpanAndAnchor,
} from '@/views/contents/partner/modification/grid/gridHTMLs';
// apiMethods
import {
  getHandleApproval,
  getHandleDisApproval,
  resendEmail,
} from '@/views/contents/partner/modification/apis/apiMethods';
// cellReplacer
import { PartnerStatus } from 'ncp-api-supporter';

type WaitingActive = Exclude<PartnerStatus, 'INVESTIGATION'>;

// *** renderers ***
export const renderTextByContractStatus = (contractStatus: WaitingActive, partnerNo?: string, contractNo?: number) => {
  const queryString = new URLSearchParams(window.location.search);
  const partnerStatus = queryString.get('partnerStatus') as PartnerStatus;
  const contractStatusText = i18n.t(`PARTNER.EDIT.${contractStatus}`) as string;
  const resendEmailAnchor = i18n.t('PARTNER.EDIT.RESEND_EMAIL') as string;

  return {
    getElement(): HTMLElement {
      return partnerStatus === 'ACTIVE' && contractStatus === 'WAITING'
        ? getGridTextSpanAndAnchor(contractStatusText, resendEmailAnchor, () => resendEmail(partnerNo, contractNo))
        : getGridTextSpan(contractStatusText);
    },
    render() {
      return;
    },
  };
};

const renderApprovalSelectText = (grid: tuiGrid, rowKey: RowKey) => {
  const approvalText = i18n.t('PARTNER.EDIT.APPROVAL') as string;
  const disApprovalText = i18n.t('PARTNER.EDIT.DISAPPROVAL') as string;

  const approval: AnchorTagInfo = { anchorText: approvalText, handleClick: getHandleApproval(grid, rowKey) };
  const disApproval: AnchorTagInfo = { anchorText: disApprovalText, handleClick: getHandleDisApproval(grid, rowKey) };

  return {
    getElement(): HTMLElement {
      return getGridAnchorGroup([approval, disApproval]);
    },

    render() {
      return;
    },
  };
};

// *** contractStatusRendererByContractStatus ***
export default (props: PartnerRendererProps<'contractStatus'>): CellRenderer => {
  const contractStatus = props.grid.getValue<PartnerRowData, 'contractStatus'>(props.rowKey, 'contractStatus');
  const contractNo = props.grid.getValue<PartnerRowData, 'contractNo'>(props.rowKey, 'contractNo');
  const partnerNo = props.grid.getValue<PartnerRowData, 'partnerNo'>(props.rowKey, 'partnerNo');

  const rendererMap: RendererMapByContractStatus<'contractStatus'> = {
    WAITING() {
      return renderTextByContractStatus('WAITING', partnerNo.toString(), contractNo);
    },

    INVESTIGATION({ grid, rowKey }) {
      return renderApprovalSelectText(grid, rowKey);
    },

    ACTIVE() {
      return renderTextByContractStatus('ACTIVE');
    },
  };
  return rendererMap[contractStatus](props);
};
