import { DeliveryNoGridTypes, FocusTabName, OrderNoGridTypes } from '@/types/order';

export function orderGridsDefaultColumns(orderGridType: OrderNoGridTypes | DeliveryNoGridTypes) {
  const columns = {
    PRO_ORDER_ALL_GRID: [
      '배송번호',
      '배송메모',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송보류',
      '주문상태',
      '클레임상태',
      '교환여부',
      '배송비 유형',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '업무메시지',
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '상품합계',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_ALL_TAB2_GRID: [
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      '배송보류',
      '업무메시지',
      '주문상태',
      '클레임상태',
      '교환여부',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '수량',
      '상품합계',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송번호',
      '배송메모',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '배송비 유형',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_DEPOSIT_WAIT_GRID: [
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      '업무메시지',
      '입금확인 처리',
      '교환여부',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송번호',
      '배송메모',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_PAY_DONE_GRID: [
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      '업무메시지',
      '상품준비중처리',
      '교환여부',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송번호',
      '배송메모',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_PRODUCT_PREPARE_GRID: [
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      '업무메시지',
      '배송준비중처리',
      '교환여부',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송번호',
      '배송메모',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_DELIVERY_PREPARE_GRID: [
      '배송번호',
      '배송메모',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송중처리',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '업무메시지',
      '교환여부',
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_DELIVERY_PREPARE_TAB2_GRID: [
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      '업무메시지',
      '배송중처리',
      '교환여부',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송번호',
      '배송메모',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_DELIVERY_ING_GRID: [
      '배송번호',
      '배송메모',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송완료처리',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '업무메시지',
      '교환여부',
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_DELIVERY_DONE_GRID: [
      '배송번호',
      '배송메모',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '구매확정처리',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '업무메시지',
      '교환여부',
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_BUY_CONFIRM_GRID: [
      '배송번호',
      '배송메모',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '업무메시지',
      '교환여부',
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_PAY_FAIL_GRID: [
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문자명',
      '주문자연락처',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '수량',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '개인통관고유부호',
      '배송방법',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '주문일시',
      '결제실패구분',
      '실패사유',
      '주문상품옵션번호',
    ],
    PRO_ORDER_HOLD_DELIVERY_GRID: [
      '배송보류처리일시',
      '플랫폼구분',
      '쇼핑몰구분',
      '예약주문',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문자명',
      '주문자연락처',
      '업무메시지',
      '주문상태',
      '교환여부',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '수량',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송번호',
      '배송메모',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '주문일시',
      '결제일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PRO_ORDER_HOLD_BY_RESERVATION_GRID: [
      '예약판매시작일',
      '플랫폼구분',
      '쇼핑몰구분',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문자명',
      '주문자연락처',
      '업무메시지',
      '주문상태',
      '교환여부',
      'HS CODE',
      '파트너사',
      '상품번호',
      '상품관리코드',
      '상품명',
      '옵션관리코드',
      '옵션명:옵션값',
      '즉시할인가',
      '공급가',
      '상품쿠폰할인',
      '주문쿠폰할인',
      '주문금액',
      '수량',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송번호',
      '배송비 결제방식',
      '기본배송비',
      '지역별배송비',
      '배송비합계',
      '실결제금액',
      '배송비 유형',
      '개인통관고유부호',
      '배송방법',
      '택배사',
      '송장번호',
      '주문일시',
      '상품준비중처리일시',
      '배송준비중처리일시',
      '배송중처리일시',
      '배송완료처리일시',
      '구매확정일시',
      '회원번호',
      '주문상품옵션번호',
      '적립금사용',
    ],
    PREVIOUS: [
      '플랫폼구분',
      '회원등급',
      '결제수단',
      '주문번호',
      '주문메모',
      '주문자명',
      '주문자연락처',
      '상품명',
      '주문금액',
      '상품합계',
      '수령자명',
      '수령자연락처',
      '우편번호',
      '주소',
      '배송메모',
      '배송비합계',
      '실결제금액',
      '개인통관고유부호',
      '주문일시',
      '결제일시',
      '회원번호',
    ],
  };
  return columns[orderGridType];
}

export function defaultDisplay() {
  return [
    { columnName: 'platformType', i18n: '플랫폼구분' },
    { columnName: 'mallNo', i18n: '쇼핑몰구분' },
    { columnName: 'orderRegisterType', i18n: '주문구분' },
    { columnName: 'reservation', i18n: '예약주문' },
    { columnName: 'memberGroupInfoJson', i18n: '회원그룹' },
    { columnName: 'payType', i18n: '결제수단' },
    { columnName: 'orderNo', i18n: '주문번호' },
    { columnName: 'ordererName', i18n: '주문자명' },
    { columnName: 'ordererContact1', i18n: '주문자연락처' },
    { columnName: 'memberNo', i18n: '회원번호' },
    { columnName: 'holdDelivery', i18n: '배송보류' },
    { columnName: 'shippingAreaType', i18n: '배송구분' },
    { columnName: 'taskMessage', i18n: '업무메시지' },
    { columnName: 'orderStatusType', i18n: '주문상태' },
    { columnName: 'orderStatusType', i18n: '결제실패구분' },
    { columnName: 'claimStatusType', i18n: '클레임상태' },
    { columnName: 'partnerNo', i18n: '파트너사' },
    { columnName: 'hsCode', i18n: 'HS CODE' },
    { columnName: 'mallProductNo', i18n: '상품번호' },
    { columnName: 'productManagementCd', i18n: '상품관리코드' },
    { columnName: 'productName', i18n: '상품명' },
    { columnName: 'productNameEn', i18n: '영문상품명' },
    { columnName: 'optionManagementCd', i18n: '옵션관리코드' },
    { columnName: 'displayOptionName', i18n: '옵션명' },
    { columnName: 'displayOptionName', i18n: '옵션명:옵션값' },
    { columnName: 'immediateDiscountedPrice', i18n: '즉시할인가' },
    { columnName: 'additionalDiscountAmt', i18n: '추가할인' },
    { columnName: 'buyPrice', i18n: '추가할인적용가' },
    { columnName: 'orderCnt', i18n: '수량' },
    { columnName: 'totalBuyAmt', i18n: '상품합계' },
    { columnName: 'receiverName', i18n: '수령자명' },
    { columnName: 'receiverContact1', i18n: '수령자휴대전화' },
    { columnName: 'receiverContact1', i18n: '수령자연락처' },
    { columnName: 'receiverZipCd', i18n: '우편번호' },
    { columnName: 'address', i18n: '주소' },
    { columnName: 'shippingNo', i18n: '배송번호' },
    { columnName: 'deliveryAmtInAdvance', i18n: '배송비 결제방식' }, // 배송비 결제방식
    { columnName: 'deliveryConditionType', i18n: '배송비 유형' },
    { columnName: 'customsIdNumber', i18n: '개인통관고유부호' },
    { columnName: 'deliveryType', i18n: '배송방법' },
    { columnName: 'deliveryCompanyType', i18n: '택배사' },
    { columnName: 'deliveryCompanyType', i18n: '택배사선택' },
    { columnName: 'invoiceNo', i18n: '송장번호' },
    { columnName: 'invoiceNo', i18n: '송장번호입력' },
    { columnName: 'orderYmdt', i18n: '주문일시' },
    { columnName: 'payYmdt', i18n: '결제일시' },
    { columnName: 'orderAcceptYmdt', i18n: '상품준비중처리일시' },
    { columnName: 'releaseReadyYmdt', i18n: '배송준비중처리일시' },
    { columnName: 'releaseYmdt', i18n: '배송중처리일시' },
    { columnName: 'deliveryCompleteYmdt', i18n: '배송완료처리일시' },
    { columnName: 'buyConfirmYmdt', i18n: '구매확정일시' },
    { columnName: 'recommenderId', i18n: '추천인' },
    { columnName: 'deliveryMemo', i18n: '배송메모' },
    { columnName: 'exchange', i18n: '교환여부' },
    { columnName: 'adjustedAmt', i18n: '기본배송비' },
    { columnName: 'deliveryAmt', i18n: '기본배송비' },
    { columnName: 'remoteDeliveryAmt', i18n: '지역별배송비' },
    { columnName: 'totalDeliveryAmt', i18n: '배송비합계' },
    { columnName: 'orderMemo', i18n: '주문메모' },
    { columnName: 'userInputTextStr', i18n: '구매자작성형' },
    { columnName: 'procEachBtn', i18n: '입금확인 처리' },
    { columnName: 'procEachBtn', i18n: '상품준비중처리' },
    { columnName: 'procEachBtn', i18n: '배송준비중처리' },
    { columnName: 'procEachBtn', i18n: '배송중처리' },
    { columnName: 'procEachBtn', i18n: '배송완료처리' },
    { columnName: 'procEachBtn', i18n: '구매확정처리' },
    { columnName: 'holdDeliveryYmdt', i18n: '배송보류처리일시' },
    { columnName: 'reservationStartYmdt', i18n: '예약판매시작일' },
    { columnName: 'failInfo', i18n: '실패사유' },
    { columnName: 'deliveryExternalKey', i18n: 'WMS연동번호' },
    { columnName: 'deliveryExternalKey', i18n: 'WMS 연동번호' },
    { columnName: 'totalLastProductCouponDiscountAmt', i18n: '상품쿠폰할인' },
    { columnName: 'lastCartCouponDiscountAmt', i18n: '주문쿠폰할인' },
    { columnName: 'orderAmt', i18n: '주문금액' },
    { columnName: 'immediateDiscountedPrice', i18n: '즉시할인가' },
    { columnName: 'lastMainPayAmt', i18n: '실결제금액' },
    { columnName: 'memberGradeName', i18n: '회원등급' },
    { columnName: 'supplyPrice', i18n: '공급가' },
    { columnName: 'informationConsent', i18n: '정보동의' }, // TODO: 1차 배포 제외
    { columnName: 'drinkBuy', i18n: '주류구매' }, // TODO: 1차 배포 제외
    { columnName: 'orderOptionNo', i18n: '주문상품옵션번호' },
    { columnName: 'lastSubPayAmt', i18n: '적립금사용' },
  ];
}

export function typeMergeRow(focusTabName: FocusTabName, gridName) {
  const typeMergeRow = {
    deliveryTab: {
      PRO_ORDER_ALL_GRID: {
        bigRow: [
          'shippingNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'procBtn',
          'deliveryAmtInAdvanceYn',
          'remoteDeliveryAmt',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'procEachBtn',
          'deliveryAmt',
          'adjustedAmt',
          'payType',
          'lastSubPayAmt',
        ],
        midRow: [
          'mallNo',
          'platformType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_DELIVERY_PREPARE_GRID: {
        bigRow: [
          'shippingNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'procBtn',
          'deliveryAmtInAdvanceYn',
          'remoteDeliveryAmt',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'procEachBtn',
          'deliveryAmt',
          'adjustedAmt',
          'payType',
          'lastSubPayAmt',
        ],
        midRow: [
          'mallNo',
          'platformType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_DELIVERY_ING_GRID: {
        bigRow: [
          'deliveryExternalKey',
          'shippingNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'procBtn',
          'deliveryAmtInAdvanceYn',
          'remoteDeliveryAmt',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'procEachBtn',
          'payType',
          'lastSubPayAmt',
        ],
        midRow: [
          'mallNo',
          'platformType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_DELIVERY_DONE_GRID: {
        bigRow: [
          'shippingNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'remoteDeliveryAmt',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'payType',
          'lastSubPayAmt',
        ],
        midRow: [
          'mallNo',
          'platformType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_BUY_CONFIRM_GRID: {
        bigRow: [
          'deliveryExternalKey',
          'shippingNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'remoteDeliveryAmt',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'payType',
          'lastSubPayAmt',
        ],
        midRow: [
          'mallNo',
          'platformType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
    },
    orderTab: {
      PRO_ORDER_ALL_TAB2_GRID: {
        bigRow: [
          'orderNo',
          'shippingNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'remoteDeliveryAmt',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'deliveryAmt',
          'adjustedAmt',
          'payType',
        ],
        midRow: [
          'mallNo',
          'platformType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastSubPayAmt',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_DEPOSIT_WAIT_GRID: {
        bigRow: [
          'orderNo',
          'shippingNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'remoteDeliveryAmt',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'deliveryAmt',
          'adjustedAmt',
          'procEachBtn',
          'payType',
        ],
        midRow: [
          'mallNo',
          'platformType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastSubPayAmt',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_PAY_DONE_GRID: {
        bigRow: [
          'platformType',
          'mallNo',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'payType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        midRow: [
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'shippingNo',
          'deliveryMemo',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'adjustedAmt',
          'remoteDeliveryAmt',
          'lastSubPayAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_PRODUCT_PREPARE_GRID: {
        bigRow: [
          'platformType',
          'mallNo',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'payType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        midRow: [
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'shippingNo',
          'deliveryMemo',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'adjustedAmt',
          'remoteDeliveryAmt',
          'lastSubPayAmt',
        ],
        smallRow: ['partnerNo', 'hsCode', 'mallProductNo', 'productManagementCd', 'productName', 'productNameEn'],
      },
      PRO_ORDER_DELIVERY_PREPARE_TAB2_GRID: {
        bigRow: [
          'platformType',
          'mallNo',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'payType',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'procEachBtn',
          'shippingNo',
        ],
        midRow: [
          'orderNo',
          'deliveryMemo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'adjustedAmt',
          'deliveryAmt',
          'remoteDeliveryAmt',
          'lastSubPayAmt',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_PAY_FAIL_GRID: {
        bigRow: [
          'platformType',
          'mallNo',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'payType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'procBtn',
          'failInfo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        midRow: [
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'shippingNo',
          'deliveryMemo',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryAmtInAdvanceYn',
          'adjustedAmt',
          'remoteDeliveryAmt',
          'lastSubPayAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
        ],
      },
      PRO_ORDER_HOLD_DELIVERY_GRID: {
        bigRow: [
          'platformType',
          'mallNo',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'payType',
          'shippingAreaType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'shippingNo',
          'deliveryAmtInAdvanceYn',
          'deliveryConditionType',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'deliveryNo',
          'reservationYn',
          'deliveryMemo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        midRow: ['lastSubPayAmt'],
        smallRow: [
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
          'failInfo',
        ],
      },
      PRO_ORDER_HOLD_BY_RESERVATION_GRID: {
        bigRow: [
          'platformType',
          'mallNo',
          'orderRegisterType',
          'reservation',
          'memberGroupInfoJson',
          'recommenderId',
          'payType',
          'orderNo',
          'ordererName',
          'ordererContact1',
          'memberNo',
          'lastMainPayAmt',
          'totalDeliveryAmt',
        ],
        midRow: [
          'receiverName',
          'receiverContact1',
          'receiverZipCd',
          'address',
          'shippingNo',
          'deliveryAmtInAdvanceYn',
          'deliveryConditionType',
          'customsIdNumber',
          'deliveryType',
          'deliveryCompanyType',
          'invoiceNo',
          'lastSubPayAmt',
        ],
        smallRow: [
          'shippingAreaType',
          'partnerNo',
          'hsCode',
          'mallProductNo',
          'productManagementCd',
          'productName',
          'productNameEn',
          'totalBuyAmt',
        ],
      },
    },
  };
  return typeMergeRow[focusTabName][gridName];
}
