































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MemberCountTable extends Vue {
  @Prop({ required: true })
  private readonly summaryTotalMemberCount: number;

  @Prop({ required: true })
  private readonly summaryTotalPeriodMemberCount: number;

  @Prop({ required: true })
  private readonly totalMemberCountPercentage: string;
}
