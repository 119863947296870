













































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import {
  NCPResponse,
  PutProductsMultiCategoryMappings,
  PutProductsMultiCategoryMappingsRequest,
} from 'ncp-api-supporter';
import MultiDisplayCategoriesSelector from '@/components/common/categories/MultiDisplayCategoriesSelector.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { i18n } from '@/main';
import { getErrorMessages } from '@/utils/i18nMessage';
import { PutProductsMultiCategoryMappingsErrorCode } from 'ncp-api-supporter/dist/types/modules/product/product';

type ChangeMethod = 'updatedProductNos' | 'addedProductNos' | 'removedProductNos';

const changeMethodI18n = {
  updatedProductNos: 'CHANGE',
  addedProductNos: 'ADD',
  removedProductNos: 'DELETE',
};

export interface ChangeDisplayCategoryData {
  mallNo: number;
  productNos: number[];
}

export interface Failures {
  message: string;
  mallProductNo: number;
}

const changeMethods: RadioBoxGroupOption<ChangeMethod> = {
  name: 'changeMethods',
  data: [
    {
      id: 'updatedProductNos',
      name: 'updatedProductNos',
      value: 'updatedProductNos',
      display: 'CHANGE',
    },
    {
      id: 'addedProductNos',
      name: 'addedProductNos',
      value: 'addedProductNos',
      display: 'ADD',
    },
    {
      id: 'removedProductNos',
      name: 'removedProductNos',
      value: 'removedProductNos',
      display: 'DELETE',
    },
  ],
};

@Component({
  components: {
    ToolTip,
    MultiDisplayCategoriesSelector,
    ModalHeader,
    RadioGroup,
  },
})
export default class ChangeDisplayCategory extends Vue {
  @Prop() private readonly data!: ChangeDisplayCategoryData;

  @Ref() categoriesSelector: MultiDisplayCategoriesSelector;
  @Ref() methodSelector: RadioGroup;

  private readonly changeMethods = changeMethods;
  private selectMethod: ChangeMethod | null = null;
  private displayCategoryNos: number[] = [];

  get productCount(): number {
    return this.data.productNos.length;
  }

  private validation(): boolean {
    if (!this.displayCategoryNos.length) {
      alert(this.$t('PRODUCT.EDIT.ALERT_CHANGE_DISPLAY_CATEGORY_MISSING'));
      this.categoriesSelector.focus();
      return false;
    }

    if (!this.selectMethod) {
      alert(this.$t('ALERT_NO_SELECTED', { value: this.$t('PRODUCT.EDIT.TREATMENT_METHOD') }));
      this.methodSelector.focus();
      return false;
    }

    return true;
  }

  private onClickSubmit(): void {
    if (!this.validation()) return;

    const apiRequest = this.getUpdateProductDisplayCategoryRequest();
    this.updateProductDisplayCategory(apiRequest).then(() => {
      this.$emit('confirm');
    });
  }

  private getUpdateProductDisplayCategoryRequest(): PutProductsMultiCategoryMappingsRequest {
    return {
      data: {
        displayCategoryNos: this.displayCategoryNos,
        [this.selectMethod]: this.data.productNos,
      },
    };
  }

  private async updateProductDisplayCategory(request: PutProductsMultiCategoryMappingsRequest): Promise<void> {
    try {
      const { data }: NCPResponse<PutProductsMultiCategoryMappings> = await this.$api.putProductsMultiCategoryMappings(
        request,
      );
      const isSucceed = !data.failures.length;

      // putProductsSaleStatus 는 재밋게도 상태수정이 불가할때에도 200을 내려준다.
      // 따라서 현재 프론트에서는 IsSucceed 에 걸리는 결과값만을 200으로 판단하기로 한다.
      const successCount = data.successNos.length;
      const successMethodMessage = i18n.t('PRODUCT.EDIT.ALERT_SOME_CHANGE_COMPLETE', {
        name: i18n.t(changeMethodI18n[this.selectMethod]),
      });

      if (isSucceed) {
        const successCountMessage = i18n.t('SUCCESS_N', { count: successCount }).toString();
        const successMessage = `${successCountMessage} : ${successMethodMessage}`;
        alert(successMessage);
      } else {
        const failures = data.failures;
        const successCountMessage = i18n.t('SUCCESS_N', { count: successCount }).toString();
        const successMessage = `${successCountMessage} : ${successMethodMessage}`;
        const failMessages = getErrorMessages<PutProductsMultiCategoryMappingsErrorCode>(
          [
            'INVALID_PRODUCT_NO',
            'OTHER_MALL_PRODUCT',
            'CHANGE_DISPLAY_CATEGORY_IS_EMPTY',
            'SALE_PROHIBITED',
            'UNKNOWN_ERROR',
          ],
          failures,
          'PRODUCT.EDIT',
        );
        if (!failMessages?.length) {
          alert(successMessage);
          return null;
        }
        const message = successCount ? successMessage + '\n' + failMessages.join('\n') : failMessages.join('\n');

        alert(message);
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
