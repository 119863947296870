/**
 * RadioGroup, CheckboxGroup, SelectBox 등에 빈번히 쓰일 옵션 객체 생성함수 정리하기 위해 만듭니다.
 * @dependency src/const/i18n/kr/index.ts
 *
 * @symbol 만들어진 옵션객체의 id 를 토대로 HTMLElement 가 생겨나는데, id가 곂치는걸 방지하기 위해 쓰이는 인자 이름 입니다.
 */

type OptionType = 'radio' | 'checkbox';

export const radioDisplayYnOptionGenerator = (symbol: string | number = 'radio') => ({
  name: 'displayYnOption',
  type: 'radio',
  data: [
    { id: `category-show-${symbol}`, value: 'Y', display: 'PRODUCT.CATEGORIZATION.DISPLAY' },
    { id: `category-hide-${symbol}`, value: 'N', display: 'PRODUCT.CATEGORIZATION.UN_DISPLAY' },
  ],
});
