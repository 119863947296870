




















































import { Mixins, Component, Watch, Prop } from 'vue-property-decorator';
import { GetClaims, NCPResponse } from 'ncp-api-supporter';
import { ClaimStatusType, ClaimType, GridEvent } from '@/types/claim';
import { claimClassTypes, claimTypes } from '@/const/claim';
import { GetRefunds } from 'ncp-api-supporter/dist/types/modules/claim/refunds';
import {
  PopupClose,
  PopupResult,
  throwExternalWindowPopup,
  throwMessagePopup,
  throwNoPopupIdWindowPopup,
  throwPopup,
  throwWindowPopup,
  throwWindowPopupWithProps,
} from '@/helpers/popup';
import { Row } from '@/types/tui-grid';
import { getDefaultParams, getRefundClaimStatusTypes, getDefaultMall } from '@/components/shipping/claim/searchQuery';
import { getGridProps } from '@/views/contents/shipping/claim/gridColumns';
import { getHref } from '@/utils/route';
import _ from 'underscore';
import ContentBottomMixin from '@/components/shipping/claim/ContentBottomMixin.ts';
import Grid from '@/components/common/grid/Main.vue';
import { Getter } from 'vuex-class';
import { DetailOpenOptions } from 'ncp-api-supporter/dist/types/modules/admin/admin';
import ClickOutside from 'vue-click-outside';

@Component({
  components: { Grid },
  directives: { ClickOutside },
})
export default class ContentBottom extends Mixins(ContentBottomMixin) {
  @Getter('admin/getDetailOpenOptions')
  private detailOpenOptions: DetailOpenOptions;

  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  //TODO: 타이핑
  private query;
  private gridProps = getGridProps(this.claimType);
  private containerOptions = {
    hasExcelDownloadButton: true,
    pageSizeKey: 'size',
  };

  private claimSearchResponse = {
    contents: [],
    totalCount: 0,
  };

  private get isCancelList() {
    return this.claimType === claimTypes.CANCEL;
  }

  @Watch('$route.query')
  private updateQuery() {
    this.fetchClaims();
  }

  private created() {
    this.fetchClaims();
  }

  private mounted() {
    this.gridRef.changeNoDataMessage(this.$t('NO_RESULT'));
  }

  private async fetchClaims(): Promise<void> {
    this.initQuery();
    const request = {
      params: { ...this.query },
    };

    let response: NCPResponse<GetClaims | GetRefunds>;
    this.claimType === claimTypes.REFUND
      ? (response = await this.$api.getRefunds(request))
      : (response = await this.$api.getClaims(request));

    this.setContents(response.data);
  }

  private setContents(claimResponseData: GetClaims | GetRefunds) {
    if (claimResponseData.contents.length === 0) {
      this.claimSearchResponse.contents = [];
      this.claimSearchResponse.totalCount = claimResponseData.totalCount;
      this.gridRef.changeNoDataMessage(this.$t('NO_RESULT'));
      return;
    }

    if (this.claimType === claimTypes.REFUND) {
      this.claimSearchResponse.contents = claimResponseData.contents;
      this.claimSearchResponse.totalCount = claimResponseData.totalCount;
      return;
    }

    const formattedContents = [...this.claimFormatterList((claimResponseData as GetClaims).contents, 'options')];
    // columns의 name
    const mergeTargets = [
      'mallName',
      'claimYmdt',
      'claimCompleteYmdt',
      'invoiceNo',
      'procEachBtn',
      'claimStatusType',
      'claimNo',
      'mallNo',
      'orderRegisterType',
      'reserved',
      'payType',
      'shippingAreaType',
      'memberGroupInfoJson',
      'orderNo',
      'ordererName',
      'ordererContact1',
      'memberNo',
      'receiverName',
      'receiverContact1',
      'claimAmt',
      'partnerNo',
      'hasTaskMessage',
    ];

    this.claimSearchResponse.contents = [...this.mergeClaimRow(formattedContents, mergeTargets, 'claimNo')];
    this.claimSearchResponse.totalCount = claimResponseData.totalCount;
  }

  private initQuery() {
    this.query = { ...getDefaultParams(this.claimType, this.$route), ...this.$route.query };

    this.query.mallNo = getDefaultMall(this.$route.query.mallNo as string);
    if (this.claimType === claimTypes.REFUND) {
      this.query.claimStatusTypes = getRefundClaimStatusTypes(this.query.claimStatusTypes.split(','));
    }
  }

  private onGridItemClick({ nativeEvent, columnName, rowKey, instance }: GridEvent) {
    if (columnName === undefined) {
      return;
    }

    const { claimNo, claimStatusType, memberNo, orderNo } = instance.getRowAt(Number(rowKey));
    switch (columnName) {
      case 'procEachBtn':
        this.onClickProcEachBtn(Number(claimNo), (nativeEvent.target as Element).className);
        break;

      case 'ordererName':
        throwExternalWindowPopup(`/pro/crm/${memberNo}`, 'xlg');
        break;

      case 'claimNo':
        this.openClaimPopup(Number(claimNo), this.claimType, claimStatusType as ClaimStatusType);
        break;

      case 'orderNo':
        this.openOrderDetail(orderNo.toString());
        break;

      case 'hasTaskMessage':
        this.openOrderDetail(orderNo.toString());
        break;
    }
  }

  private onClickProcEachBtn(claimNo: number, className: string) {
    switch (className) {
      case 'cancel_detail':
      case 'exchange_detail':
      case 'return_detail':
        this.openClaimPopup(claimNo, this.claimType);
        break;

      case 'cancel_accept':
      case 'exchange_accept':
      case 'return_accept':
        this.openClaimAcceptPopup(claimNo, this.claimType);
        break;

      case 'already_delivery':
        this.onClickAlreadyDelivery(claimNo, this.claimType);
        break;

      case 'collect':
        this.openCollectPopup(claimNo);
        break;
    }
  }

  private searchAgain() {
    this.updateQuery();
  }

  // 클레임 처리 내역 팝업
  private openClaimPopup(claimNo: number, claimType: ClaimType, claimStatusType?: ClaimStatusType) {
    const query = `claimNo=${claimNo}`;
    const claimPopupTypes = {
      [claimTypes.CANCEL]: () => `${getHref('CancelPopup')}?${query}`,
      [claimTypes.EXCHANGE]: () => `${getHref('ExchangePopup')}?${query}`,
      [claimTypes.RETURN]: () => `${getHref('ReturnPopup')}?${query}`,
      [claimTypes.REFUND]: () => {
        // Refund는 클레임 상태가 아니라 환불인 클레임들을 모은 것. 그래서 실제 claimType을 찾는 코드
        const claimType = Object.keys(claimTypes).find(claimType => claimStatusType.includes(claimType));
        return claimPopupTypes[claimType]();
      },
    };

    throwNoPopupIdWindowPopup(claimPopupTypes[claimType](), 'xlg', ({ state }) => {
      if (state === PopupClose.CONFIRM) {
        this.searchAgain();
      }
    });
  }

  private openOrderDetail(orderNo: string) {
    const openOrderDetail = {
      NEW_TAB: () => window.open(`${getHref('OrderDetail')}?orderNo=${orderNo}`, '_blank'),
      TAB: () => window.open(`${getHref('OrderDetail')}?orderNo=${orderNo}`, 'ORDER_DETAIL'),
      WINDOW: () =>
        window.open(
          `${getHref('OrderDetail')}?orderNo=${orderNo}`,
          'ORDER_DETAIL_WINDOW',
          'toolbar=yes,location=yes,menubar=yes',
        ),
      NEW_WINDOW: () =>
        window.open(`${getHref('OrderDetail')}?orderNo=${orderNo}`, '_blank', 'toolbar=yes,location=yes,menubar=yes'),
    };

    openOrderDetail[this.detailOpenOptions.ORDER_DETAIL]();
  }

  // 클레임 승인처리 팝업
  private openClaimAcceptPopup(claimNo: number, claimType: ClaimType) {
    const query = `claimNo=${claimNo}`;
    const claimPopupTypes = {
      [claimTypes.CANCEL]: () => `${getHref('CancelAcceptPopup')}?${query}`,
      [claimTypes.EXCHANGE]: () => `${getHref('ExchangeAcceptPopup')}?${query}`,
      [claimTypes.RETURN]: () => `${getHref('ReturnAcceptPopup')}?${query}`,
    };

    throwNoPopupIdWindowPopup(claimPopupTypes[claimType](), 'xlg', ({ state }) => {
      if (state === PopupClose.CONFIRM) {
        this.searchAgain();
      }
    });
  }

  protected async onClickAlreadyDelivery(claimNo: number, claimType: ClaimType) {
    if (claimType === claimTypes.RETURN || claimType === claimTypes.REFUND) {
      return;
    }

    const targetClaimStatus = claimType === claimTypes.CANCEL ? claimTypes.CANCEL : claimClassTypes.CANCEL_EXCHANGE;
    const { nextActionType, shippingNo, afterClaimNos } = await this.checkDelivery(claimNo, targetClaimStatus);

    switch (nextActionType) {
      case 'ALREADY_SHIPPING':
        throwWindowPopup('AlreadyDeliveryPopup', { claimNo }, 'md', ({ state }) => {
          if (state === PopupClose.CONFIRM) {
            this.searchAgain();
          }
        });
        return;

      case 'COMBINE_WITHDRAWAL':
      case 'NORMAL_WITHDRAWAL':
        alert(this.makeAlreadyDeliveryMessage({ type: 'NORMAL_WITHDRAWAL', shippingNo }));
        break;

      case 'EXISTS_AFTER_CLAIM':
        if (!confirm(this.makeAlreadyDeliveryMessage({ type: 'EXISTS_AFTER_CLAIM', afterClaimNos }))) {
          return;
        }
        break;
    }

    await this.withdrawClaim(claimNo, targetClaimStatus);
    alert(this.$t('CLAIM.MESSAGE.COMPLETE_PROCESS'));
    this.searchAgain();
  }

  private openCollectPopup(claimNo: number) {
    throwWindowPopup('CollectPopup', { claimNo }, 'md', ({ state }) => {
      if (state === PopupClose.CONFIRM) {
        this.searchAgain();
      }
    });
  }

  private async onDeleteOrders() {
    const checkedRows: Row[] = this.gridRef.getCheckedRows();
    const checkedClaimCount = checkedRows.length;

    if (checkedClaimCount === 0) {
      alert(this.$t('CLAIM.MESSAGE.NEED_ORDER_PRODUCT_SELECT'));
      return;
    }

    const orderNos = this.removeDuplicateOrders(checkedRows);
    const { state }: PopupResult = await throwPopup({
      name: 'RemoveOrderMessage',
      data: {
        listType: 'CLAIM',
        checkedClaimCount: orderNos.length,
      },
    });

    if (state === PopupClose.CLOSE) {
      return;
    }

    try {
      await this.$api.deleteOrders({
        params: {
          orderNos: orderNos.toString(),
        },
      });

      await throwMessagePopup(
        this.$t('CLAIM.MESSAGE.DELETE_SUCCESS'),
        false,
        this.$t('CLAIM.POPUP.ORDER_DELETE_TITLE'),
      );
      this.searchAgain();
    } catch (err) {
      // await throwMessagePopup(this.$t(err.message), false, this.$t('CLAIM.POPUP.ORDER_DELETE_TITLE')); 취소리스트 p.23
    }
  }

  private removeDuplicateOrders(checkedRows: Row[]) {
    return _.unique(checkedRows.map(({ orderNo }) => orderNo));
  }

  // SMS 발송 취소리스트 기획서 p.24
  /*
   * sms발송팝업 개발중: https://github.nhnent.com/ncp/shopby-pro-admin/pull/1244
   * */
  private showSmsMenu = false;
  private openSmsSendPopup(sendType: 'SELECT' | 'SEARCH') {
    const claims = sendType === 'SELECT' ? this.gridRef.getCheckedRows() : this.claimSearchResponse.contents;
    if (claims.length === 0) {
      alert(this.$t(`CLAIM.MESSAGE.VALID_SMS_${sendType}_ORDER`));
      return;
    }

    if (!this.isAllSameMall(claims)) {
      alert(this.$t(`CLAIM.MESSAGE.VALID_SAME_MALL`));
      return;
    }

    const recipients = claims.map(claim => claim.ordererContact);

    throwWindowPopupWithProps({
      componentName: 'SmsSendManagerIframe',
      data: { mallNo: claims[0].mallNo },
      query: { mallNo: claims[0].mallNo, recipients, type: 'RESTOCK' },
      size: 'xlg',
    });
  }

  private isAllSameMall = data => {
    const mallNos = data.map(d => d.mallNo);
    return mallNos.every(mallNo => mallNo === mallNos[0]);
  };

  private onClickOutside() {
    this.showSmsMenu = false;
  }
}
