

















































import { Vue, Component, PropSync, Prop, Ref } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { GetProductsCheckManagementCodeRequest, NCPResponse } from 'ncp-api-supporter';
import { InputNumber } from '@/types';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

@Component({
  components: { TextInput, ToolTip },
})
export default class ProductManagementCd extends Vue {
  @PropSync('productManagementCd', { default: '' }) private productManagementCdSync!: string;
  @PropSync('hsCode') private hsCodeSync!: string;
  @Prop() private mallNo!: InputNumber;
  @Prop({ required: false, default: 0 }) private mallProductNo!: number;
  @Prop({ required: false })
  private readonly disabled: boolean;

  private isDuplicate(type: 'duplication' | 'single'): boolean {
    const duplication = type === 'duplication' ? this.isDuplicateManagementCd : !this.isDuplicateManagementCd;
    return this.isChecked && duplication && !this.isVacant;
  }

  private get isVacant(): boolean {
    return this.productManagementCdSync === '';
  }

  private get isChosenMall(): boolean {
    return this.mallNo !== '' || Number(this.mallNo) !== 0;
  }
  private checkMallNo(): void {
    if (this.isChosenMall) return;
    this.$emit('alertMallSelect');
  }

  @Ref() private readonly managementCd!: TextInput;
  private isDuplicateManagementCd = false;
  private isChecked = false;
  private checkedManagementCdCache: string[] = [];
  private async checkDuplicateManagementCd(): Promise<void> {
    if (this.vacateProductManagementCd()) return;
    this.isDuplicateManagementCd = await this.fetchDuplicateManagementCdResult();
    this.setDuplicationState();
  }

  private vacateProductManagementCd(): boolean {
    if (this.isVacant) {
      alert(this.$t('PRODUCT.ADD.PLZ_MANAGEMENT_CD').toString());
      this.managementCd.focus();
      this.isDuplicateManagementCd = false;
      this.isChecked = false;
    }
    return this.isVacant;
  }

  private onInputManagementCd(managementCd: string): string {
    this.resetDuplicationState(managementCd);
    return managementCd;
  }

  private setDuplicationState(): void {
    if (!this.isDuplicateManagementCd) {
      this.checkedManagementCdCache.push(this.productManagementCdSync);
    }
    this.isChecked = true;
  }

  private resetDuplicationState(managementCd: string): void {
    this.isChecked = this.checkedManagementCdCache.length > 0 && this.checkedManagementCdCache.includes(managementCd);
  }

  private async fetchDuplicateManagementCdResult(): Promise<boolean> {
    const params: GetProductsCheckManagementCodeRequest['params'] = {
      mallNo: Number(this.mallNo),
      productManagementCd: this.productManagementCdSync,
      mallProductNo: this.mallProductNo,
    };

    const {
      data: { exist },
    }: NCPResponse<{ exist: boolean }> = await this.$api.getProductsCheckManagementCode({
      params,
    });
    return exist;
  }
}
