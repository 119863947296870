import { getToday, addDay } from '@/utils/dateFormat';
import { GridProp } from '@/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import Reviews from '@/views/contents/board/basic/custom/Reviews.vue';
import { multiSelectRenderer } from '@/utils/grid/rendererUtils';
import { GetReviewInfo } from 'ncp-api-supporter';
import { i18n } from '@/main';
export const REVIEWS_DEFAULT_QUERY = {
  mallNo: 0,
  startDate: addDay(new Date(), -7),
  endDate: getToday(),
  searchType: 'WRITER_NAME',
  keyword: '',
  reviewDisplayStatusType: 'ALL',
  reviewType: 'ALL',
  reviewWrittenType: 'ALL',
  rating: 'ALL',
  page: 1,
  pageSize: 30,
};
export const SearchTypes = () => {
  return [
    { value: 'WRITER_NAME', name: window.$t('BOARD.REVIEW.WRITTER') },
    { value: 'PRODUCT_NO', name: window.$t('BOARD.REVIEW.PRODUCT_NO') },
    { value: 'PRODUCT_NAME', name: window.$t('BOARD.REVIEW.PRODUCT_NAME') },
    { value: 'REVIEW_NO', name: window.$t('BOARD.REVIEW.REVIEW_NO') },
  ];
};
export const ReviewDisplayStatusTypes = () => {
  return [
    { value: 'ALL', name: window.$t('BOARD.REVIEW.DISPLAY_TYPE') },
    { value: 'DISPLAY', name: window.$t('BOARD.REVIEW.DISPLAY_TYPE_Y') },
    { value: 'DELETE', name: window.$t('BOARD.REVIEW.DISPLAY_TYPE_DELETE') },
    { value: 'BLIND', name: window.$t('BOARD.REVIEW.DISPLAY_TYPE_N') },
  ];
};
export const ReviewTypes = () => {
  return [
    { value: 'ALL', name: window.$t('BOARD.REVIEW.TYPE') },
    { value: 'NORMAL', name: window.$t('BOARD.REVIEW.NORMAL_TYPE') },
    { value: 'BEST', name: window.$t('BOARD.REVIEW.BEST_TYPE') },
  ];
};
export const Types = () => {
  return [
    { value: 'ALL', name: window.$t('BOARD.REVIEW.PHOTO_TYPE') },
    { value: 'NORMAL', name: window.$t('BOARD.REVIEW.PHOTO_TYPE_N') },
    { value: 'PHOTO', name: window.$t('BOARD.REVIEW.PHOTO_TYPE_Y') },
  ];
};
export const Ratings = () => {
  return [
    { value: 'ALL', name: window.$t('BOARD.REVIEW.RATING') },
    { value: 'ONE_POINT', name: window.$t('BOARD.REVIEW.POINT', { point: 1 }) },
    { value: 'TWO_POINT', name: window.$t('BOARD.REVIEW.POINT', { point: 2 }) },
    { value: 'THREE_POINT', name: window.$t('BOARD.REVIEW.POINT', { point: 3 }) },
    { value: 'FOUR_POINT', name: window.$t('BOARD.REVIEW.POINT', { point: 4 }) },
    { value: 'FIVE_POINT', name: window.$t('BOARD.REVIEW.POINT', { point: 5 }) },
  ];
};
const shortWord = function(value: string, limit: number) {
  return value.length > limit ? value.slice(0, limit) + '...' : value;
};
export const getProductReviewListGridOption = (vue: Reviews): GridProp<GetReviewInfo> => {
  return {
    columns: [
      {
        header: 'BOARD.REVIEW.REVIEW_NO',
        name: 'reviewNo',
        align: 'center',
        minWidth: 80,
        formatter: ({ value }) => {
          return getAnchorHTML(value.toString());
        },
      },
      {
        header: 'BOARD.REVIEW.DISPLAY_TYPE',
        name: 'displayStatusType',
        align: 'center',
        minWidth: 120,
        renderer: props => {
          const row = props.grid.store.data.rawData.find(row => props.rowKey === row.rowKey);
          if (row.deleteYn === 'Y') {
            props.text = i18n.t('DELETE');
          } else if (row.externalReview) {
            switch (row.displayStatusType) {
              case 'BLIND':
                props.text = i18n.t('BOARD.REVIEW.DISPLAY_TYPE_N');
                break;
              case 'DISPLAY':
                props.text = i18n.t('BOARD.REVIEW.DISPLAY_TYPE_Y');
                break;
            }
          } else if (row.displayStatusType === 'DELETE') {
            props.text = i18n.t('DELETE');
          } else {
            props.isSelect = true;
            props.className = 'selectDisplayStatus h-22';
            props.selectOptions = [];
            ReviewDisplayStatusTypes().forEach(reviewDisplayType => {
              if (reviewDisplayType.value !== 'ALL' && reviewDisplayType.value !== 'DELETE') {
                props.selectOptions.push({ text: reviewDisplayType.name, value: reviewDisplayType.value });
              }
            });
            props.change = event => vue['displayStatusTypeChanged'](props.rowKey, event);
          }
          return multiSelectRenderer(props);
        },
      },
      {
        header: 'BOARD.REVIEW.TYPE',
        name: 'reviewType',
        align: 'center',
        minWidth: 130,
        renderer: props => {
          const reviewTypes = ReviewTypes();
          const row = props.grid.store.data.rawData.find(row => props.rowKey === row.rowKey);
          if (row.displayStatusType === 'DELETE') {
            const review = reviewTypes.find(reviewType => reviewType.value === row.reviewType);
            props.text = review.name;
          } else {
            props.isSelect = true;
            props.selectOptions = [];
            reviewTypes.forEach(reviewType => {
              if (reviewType.value !== 'ALL') {
                props.selectOptions.push({ text: reviewType.name, value: reviewType.value });
              }
            });
            props.change = event => vue['reviewTypeChanged'](props.rowKey, event);
            props.width = '65%';
          }
          return multiSelectRenderer(props);
        },
      },
      {
        header: 'BOARD.REVIEW.PHOTO_TYPE',
        name: 'attachYn',
        align: 'center',
        minWidth: 60,
        formatter: ({ value }) => {
          const typeValue = value === 'Y' ? 'PHOTO' : 'NORMAL';
          const type = Types().find(type => type.value === typeValue);
          return type.name;
        },
      },
      {
        header: 'MALL',
        name: 'mallName',
        align: 'center',
        minWidth: 150,
      },
      {
        header: 'BOARD.REVIEW.CONTENT',
        name: 'content',
        align: 'left',
        minWidth: 200,
        formatter: ({ value }) => {
          return `<div class="ellipsis">${shortWord(value as string, 12)}</div>`;
        },
      },
      {
        header: 'BOARD.REVIEW.RATING',
        name: 'rating',
        align: 'center',
        minWidth: 30,
      },
      {
        header: 'BOARD.REVIEW.COMMENT',
        name: 'commentCount',
        align: 'center',
        minWidth: 30,
      },
      {
        header: 'BOARD.REVIEW.RECOMMEND',
        name: 'recommendCnt',
        align: 'center',
        minWidth: 30,
      },
      {
        header: 'BOARD.REVIEW.PRODUCT_NO',
        name: 'productNo',
        align: 'center',
        minWidth: 85,
        formatter: ({ value }) => {
          return getAnchorHTML(value.toString());
        },
      },
      {
        header: 'BOARD.REVIEW.PRODUCT_NAME',
        name: 'productName',
        align: 'left',
        minWidth: 200,
        formatter: ({ value }) => {
          return `<div class="ellipsis">${shortWord(value as string, 15)}</div>`;
        },
      },
      {
        header: 'BOARD.REVIEW.OPTION_NO',
        name: 'optionNo',
        align: 'center',
        minWidth: 80,
      },
      {
        header: 'BOARD.REVIEW.OPTION_NAME',
        name: 'optionName',
        align: 'left',
        minWidth: 200,
        formatter: ({ value }) => {
          return `<div class="ellipsis">${value}</div>`;
        },
      },
      {
        header: 'BOARD.REVIEW.WRITTER',
        name: 'member',
        align: 'center',
        minWidth: 150,
        formatter: ({ row }) => {
          if (!row.register) {
            return i18n.t('BOARD.REVIEW.WRONG_USER');
          }

          const isNaverPay = row?.externalReviewSource === 'NAVERPAY';
          // 탈퇴회원
          if (row.register.memberStatus === 'WITHDRAWN') {
            return i18n.t('BOARD.REVIEW.WITHDRAWAL_MEMBER');
          }

          //비회원
          if (row.register.memberNo === 0) {
            return isNaverPay
              ? i18n.t('BOARD.REVIEW.NAVERPAY_MEMBER')
              : `${row.register.memberName}(${i18n.t('BOARD.REVIEW.NON_MEMBER')})`;
          }

          //회원
          if (row.register.memberNo > 0) {
            const member = `${row.register.memberId}(${row.register.memberNo})`;

            return isNaverPay
              ? `${i18n.t('BOARD.REVIEW.NAVERPAY_MEMBER')}(<a href="javascript:;" class="memberPopup">${
                  row.register.memberId
                })</a>`
              : `<a href="javascript:;" class="memberPopup">${member}</a>`;
          }
        },
      },
      {
        header: 'BOARD.REVIEW.ORDER_NO',
        name: 'orderNo',
        align: 'center',
        minWidth: 150,
        formatter: ({ value }) => {
          return value as string;
        },
      },
      {
        header: 'BOARD.REVIEW.REGISTER_DATE',
        name: 'registerDateTime',
        align: 'center',
        minWidth: 150,
      },
      {
        header: 'BOARD.REVIEW.HISTORY',
        name: 'history',
        align: 'center',
        minWidth: 85,
        formatter: () => {
          return getAnchorHTML(i18n.t('VIEW'));
        },
      },
    ],
    options: {
      keyColumnName: 'reviewNo',
      rowHeaders: ['checkbox'],
      minRowHeight: 50,
      rowHeight: 50,
      columnOptions: { resizable: true },
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};
