


























import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { GetSettlements, GetSettlement } from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';

import { GridEventProps } from '@/types';
import {
  getPartnerSettlementsGridProps,
  partnerSettlementsQuery,
  getPeriodTypeName,
  getStateName,
  PeriodType,
  StateType,
} from '@/const/contents/partner';
import { formatCurrency } from '@/utils/numberFormat';
import { SearchDetailData } from '@/views/contents/partner/Settlements.vue';
import { createExcel } from '@/components/common/grid/ExcelCreateUtil';
import { changeDateFormat } from '@/utils/dateFormat';

@Component({
  components: { Grid },
})
export default class ItemTable extends Vue {
  @Ref()
  private gridRef: Grid;

  @Prop({ required: false, default: { contents: [], totalCount: 0 } })
  private readonly settlements: GetSettlements;
  private settlementsGridProps = getPartnerSettlementsGridProps();

  @Prop({ required: true })
  private readonly searchDetailData: SearchDetailData;

  private get params() {
    return { ...partnerSettlementsQuery(), ...this.$route.query, ...this.searchDetailData };
  }

  private onItemClicked({ columnName, rowKey }: GridEventProps) {
    if (columnName !== 'download') return;
    const partnerNo = Number(this.gridRef.getRow(rowKey).partnerNo);
    const partnerName = this.gridRef.getRow(rowKey).partnerName.toString();
    this.download('byOption', partnerNo, partnerName);
  }

  private download(type: string, partnerNo?: number, partnerName?: string) {
    const hasData = this.settlements.contents.length > 0;
    const extraInfo = changeDateFormat(this.searchDetailData.settlementDate, 'YYYYMMDD');

    if (type === 'byPartner') {
      createExcel(hasData, 'PRO_SETTLEMENT_BY_PARTNER', { ...this.params, servicePlan: 'PRO' }, extraInfo);
    }
    if (type === 'byOption') {
      if (partnerNo && partnerName) {
        createExcel(hasData, 'PRO_SETTLEMENT_DETAIL', { ...this.params, partnerNo, servicePlan: 'PRO' }, extraInfo);
      } else {
        createExcel(hasData, 'PRO_SETTLEMENT_BY_OPTION', { ...this.params, servicePlan: 'PRO' }, extraInfo);
      }
    }
  }

  private formatData(responseData: GetSettlement[]) {
    return responseData.map(settlement => {
      return {
        settlementDate: settlement.settlementsInfo.settlementsFormatDate,
        settlementPeriodType: getPeriodTypeName(settlement.settlementsInfo.periodType as PeriodType),
        settlementState: getStateName(settlement.settlementsInfo.settlementsState as StateType),
        settlementPeriod: settlement.settlementsInfo.settlementsPeriod.replace(/[{}]+/g, ''), //문자열 내 중괄호 없애주는 정규식
        partnerName: settlement.partnerInfo.partnerName,
        partnerType:
          settlement.countryCode === 'KR'
            ? this.$t('PARTNER.SALES.PARTNER_TYPE_DOMESTIC')
            : this.$t('PARTNER.SALES.PARTNER_TYPE_OVERSEAS'),
        salesAmt: formatCurrency(settlement.salesAmt),
        commissionAmt: formatCurrency(settlement.commissionAmt),
        deliveryAmt: formatCurrency(settlement.deliveryAmt),
        partnerDiscountAmt: formatCurrency(settlement.partnerDiscountAmt),
        deductedAmt: formatCurrency(settlement.deductedAmt),
        settlementAmt: formatCurrency(settlement.settlementAmt),
        bankName: settlement.partnerInfo.tradeBankName,
        tradeBankAccount: settlement.partnerInfo.tradeBankAccount,
        tradeBankDepositorName: settlement.partnerInfo.tradeBankDepositorName,
        partnerNo: settlement.partnerNo,
      };
    });
  }
}
