import { Component, Vue } from 'vue-property-decorator';
import {
  GetMallsMallNoRequest,
  GetNotificationsConfigurationsRequest,
  MallDetail,
  NCPResponse,
  NotificationsConfigurationsResponse,
} from 'ncp-api-supporter';
import { getCurrentMallNo } from '@/utils/mall';

@Component
export default class EmailInfoMixin extends Vue {
  public serviceCenterEmail = '';
  public outboundEmail = '';
  public useType = true;

  public async fetchMainUsedType() {
    const request: GetNotificationsConfigurationsRequest = { params: { mallNo: getCurrentMallNo(this) } };
    try {
      const { data }: NCPResponse<NotificationsConfigurationsResponse> = await this.$api.getNotificationsConfigurations(
        request,
      );
      this.useType = data.emailUsed;
    } catch (e) {
      this.useType = false;
      console.error(e);
    }
  }

  public async fetchMailInfo() {
    const request: GetMallsMallNoRequest = { pathParams: { mallNo: getCurrentMallNo(this).toString() } };
    const {
      data: { serviceCenter },
    }: NCPResponse<MallDetail> = await this.$api.getMallsMallNo(request);
    this.serviceCenterEmail = serviceCenter.email;
    this.outboundEmail = serviceCenter.outboundEmail;
  }
}
