




import { Vue, Component } from 'vue-property-decorator';
import { orderListTypes, focusTabName } from '@/const/order';
import ContentBottom from '@/components/shipping/order/ContentBottom.vue';

@Component({
  components: {
    ContentBottom,
  },
})
export default class OrderBuyConfirm extends Vue {
  private orderListTypes = orderListTypes.BUY_CONFIRM;
  private orderSearchTypes = focusTabName.DELIVERY_TAB;
}
