


























import { Vue, Component, Prop,  Watch, PropSync } from 'vue-property-decorator';
import type { GetBrandsViewRequest, BrandsView } from 'ncp-api-supporter';
import type { InputNumber } from '@/types';
import { camelCase } from 'lodash-es';
import ClickOutside from 'vue-click-outside';
import debounce from 'lodash.debounce';

type SearchedBrand = {
  brandNo: number;
  brandName: string;
  highlightBrandName: string;
};

@Component({
  directives: {
    ClickOutside
  }
})
export default class BrandSearcher extends Vue {
  @Prop({ required: true }) private readonly mallNo!: string;
  @PropSync('brandNo') private brandNoSync: InputNumber;
  
  private internalBrandName = '';
  private filteredBrands: SearchedBrand[] = [];

  private isOpen = false;

  @Watch('mallNo')
  private onChangeMall(): void {
    this.brandNoSync = '';
  }

  private searchBrand = debounce((e: Event) => this.searchBrandsByKeyword(e), 500);
  @Watch('brandNoSync') 
  private onChangeBrandNo(): void {
    if(!this.brandNoSync){
      this.internalBrandName = ''
    }
  }
  private async searchBrandsByKeyword(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    this.internalBrandName = value
    this.isOpen = true;

    await this.fetchBrandsSearch();
  }

  private convertBrands(brands: BrandsView[]) {
    return brands.map((brand) => ({
      brandNo: brand.lastDepthBrandNo,
      brandName: this.makeBrandFullName(brand),
      highlightBrandName: this.makeHighlightBrandName(this.makeBrandFullName(brand)),
    }));
  }

  private makeBrandFullName(brand: BrandsView): string {
    const brandNameByDepthMap = new Map<number, Record<'name' | 'subName', string>>();
    
    Object.entries(brand).forEach(([key, value]) => {
      const match = key.match(/^depth(\d+)(Name|SubName)$/);
      if (match) {
        const [_, depth, fieldName] = match;

      if (!brandNameByDepthMap.has(Number(depth ?? 0))) {
        brandNameByDepthMap.set(Number(depth ?? 0), {name: '', subName: ''});
      }

      brandNameByDepthMap.get(Number(depth ?? 0))[camelCase(fieldName)] = value;
      }
    }); 

    const brandNameWithSubName = []

    brandNameByDepthMap.forEach((value) => {
      const fullName = value.subName ? `${value.name}(${value.subName})` : value.name;

      brandNameWithSubName.push(fullName);
    })
    
    return brandNameWithSubName.filter(Boolean).join(' > ');
  }

  private makeHighlightBrandName(brandName: string) {
    return brandName.replace(new RegExp(this.internalBrandName, 'gi'), (match) => `<strong>${match}</strong>`);
  }

  private async fetchBrandsSearch() {
    if (!this.mallNo || this.internalBrandName.length < 2) return;
  
    const params: GetBrandsViewRequest = { params: { mallNo: Number(this.mallNo), brandName: this.internalBrandName } };
    const { data } = await this.$api.getBrandsView(params)
    this.filteredBrands = this.convertBrands(data);
  }

  private setBrand(brand: SearchedBrand) {
    this.isOpen = false;
    if (!brand.brandNo) {
      return;
    }
    this.brandNoSync = brand.brandNo; 
    this.internalBrandName = brand.brandName;
  }

  private closeSearchList() {
    this.isOpen = false;
  }
}
