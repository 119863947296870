











import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import { sortTypes } from '@/const/order';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { SortOptionValues } from '@/components/shipping/claim/searchOptions';
import { SortType } from '@/types/order';
import { SortDeliveryNoOptionValues, SortOrderNoOptionValues } from '@/components/shipping/order/mixins/searchOptions';

@Component({ components: { SelectBox, TextInput } })
export default class SearchKeywordInput extends Vue {
  @Prop({ required: true }) private readonly tabLabel!: 'orderTab' | 'deliveryTab';
  @Prop({ required: true }) private readonly sortTypeOptions!: OptionData<SortOptionValues>[];
  @PropSync('sortType') private sortTypeSync!: SortType;
  @PropSync('desc') private descSync!: boolean;

  public sortOptionValue: SortOrderNoOptionValues | SortDeliveryNoOptionValues =
    this.tabLabel === 'orderTab'
      ? SortOrderNoOptionValues.SORT_TYPE_ORDER_NO_ASC
      : SortDeliveryNoOptionValues.SORT_TYPE_DELIVER_NO_ASC;

  private created() {
    this.convertSortTypeParams();
  }

  @Watch('$route.query.tabLabel')
  private initSortOptionValue() {
    this.sortOptionValue =
      this.tabLabel === 'orderTab'
        ? SortOrderNoOptionValues.SORT_TYPE_ORDER_NO_ASC
        : SortDeliveryNoOptionValues.SORT_TYPE_DELIVER_NO_ASC;
  }

  private convertSortTypeParams() {
    switch (this.sortOptionValue) {
      case SortDeliveryNoOptionValues.SORT_TYPE_DELIVER_NO_ASC:
        this.sortTypeSync = sortTypes.DELIVER_NO;
        this.descSync = false;
        break;

      case SortDeliveryNoOptionValues.SORT_TYPE_RECEIVER_NAME_DESC:
        this.sortTypeSync = sortTypes.RECEIVER_NAME;
        this.descSync = true;
        break;

      case SortDeliveryNoOptionValues.SORT_TYPE_RECEIVER_NAME_ASC:
        this.sortTypeSync = sortTypes.RECEIVER_NAME;
        this.descSync = false;
        break;

      case SortOrderNoOptionValues.SORT_TYPE_ORDER_NO_ASC:
        this.sortTypeSync = sortTypes.ORDER_NO;
        this.descSync = false;
        break;

      case SortOrderNoOptionValues.SORT_TYPE_ORDERER_NAME_DESC:
        this.sortTypeSync = sortTypes.ORDERER_NAME;
        this.descSync = true;
        break;

      case SortOrderNoOptionValues.SORT_TYPE_ORDERER_NAME_ASC:
        this.sortTypeSync = sortTypes.ORDERER_NAME;
        this.descSync = false;
        break;

      case SortOrderNoOptionValues.SORT_TYPE_PAY_ORDER_AMT_DESC:
        this.sortTypeSync = sortTypes.ORDER_AMT;
        this.descSync = true;
        break;

      case SortOrderNoOptionValues.SORT_TYPE_PAY_ORDER_AMT_ASC:
        this.sortTypeSync = sortTypes.ORDER_AMT;
        this.descSync = false;
        break;
    }
  }
}
