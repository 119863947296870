





























import { Vue, Component, Watch } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import {
  getShippingChargeAreaFeeGridOption,
  i18nForDelivery,
  i18nForDeliveryGroupTemplate,
} from '@/const/contents/configuration/shippingCharge';
import { GridEventProps, Mode } from '@/types';
import { PopupClose, throwWindowPopup } from '@/helpers/popup';
import { setNoDataMessage } from '@/helpers/grid';
import { MODE } from '@/const/common';
import { getDefaultPaginationQuery } from '@/const/contents/configuration/deliveryTemplate';
import { Areafee, NCPResponse } from 'ncp-api-supporter';

@Component({ components: { Grid } })
export default class ShippingChargeAreaFee extends Vue {
  private gridProps = getShippingChargeAreaFeeGridOption();
  private i18nForDeliveryGroupTemplate = i18nForDeliveryGroupTemplate;

  private query = getDefaultPaginationQuery();

  private contents: Areafee[] = [];
  private totalCount = 0;

  @Watch('$route.query')
  private changeQuery(): void {
    this.getAreaFees();
  }

  private created() {
    this.init();
  }

  private init() {
    setNoDataMessage(i18nForDelivery('NO_DATA_AREAS_FEE'));
    this.getAreaFees();
  }

  private redirect() {
    this.$router
      .replace({
        query: {
          page: '1',
          size: '30',
        },
      })
      .catch(() => null);
    this.getAreaFees();
  }

  private getAreaFees(): void {
    if (this.$route.query.page) {
      this.$set(this.query, 'page', Number(this.$route.query.page));
    }
    if (this.$route.query.size) {
      this.$set(this.query, 'size', Number(this.$route.query.size));
    }

    this.$api.getAreafees({ params: this.query }).then(response => {
      if (response && response.status === 200) {
        this.contents = response.data.contents;
        this.totalCount = response.data.totalCount;
      }
    });
  }

  private openShippingChargeAreaFeePopup(mode: Mode = MODE.REGISTER, areaFeeNo = 0): void {
    throwWindowPopup(
      'ShippingChargeAreaFeePopup',
      {
        mode,
        areaFeeNo,
      },
      'lg',
      ({ state }) => {
        if (PopupClose.CONFIRM === state) {
          this.redirect();
        }
      },
    );
  }

  private async checkUsing(no: number): Promise<boolean> {
    try {
      const { data } = await this.$api.getAreafeesCheckUsing({
        params: {
          no,
        },
      });
      return data.using;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private async delete(areaFeeNos: string) {
    const { status }: NCPResponse<any> = await this.$api.deleteAreafeesBulk({
      params: { areaFeeNos },
    });
    if (status === 204) {
      alert(this.i18nForDeliveryGroupTemplate('ALERT_DELETED'));
      this.redirect();
    }
  }

  private async deleteAreaFeesBulk(areaFeeNos: number) {
    this.checkUsing(areaFeeNos).then(res => {
      if (!res) {
        alert(i18nForDelivery('ALERT_FAIL_MSG'));
        return;
      }

      if (!confirm(this.i18nForDeliveryGroupTemplate('CONFIRM_DELETE'))) return;
      this.delete(areaFeeNos.toString());
    });
  }

  private onItemClicked({ columnName, rowKey }: GridEventProps): void {
    const areaFeeNo = Number(rowKey);
    switch (columnName) {
      case 'copy':
        this.openShippingChargeAreaFeePopup(MODE.COPY, areaFeeNo);
        break;
      case 'name':
        this.openShippingChargeAreaFeePopup(MODE.EDIT, areaFeeNo);
        break;
      case 'delete':
        this.deleteAreaFeesBulk(areaFeeNo);
        break;
    }
  }
}
