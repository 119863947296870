




































import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import _ from 'underscore';
import { BottomNavObject, NavButton } from '@/types/bottomNav';

@Component
export default class BottomNav extends Vue {
  @Getter('bottomNav/getBottomNav') private bottomNavigation!: BottomNavObject;

  get leftButtonList() {
    if (this.bottomNavigation && this.bottomNavigation.buttons) {
      return this.filterButtonsBy('left');
    }
    return [];
  }
  get rightButtonList() {
    if (this.bottomNavigation && this.bottomNavigation.buttons) {
      return this.filterButtonsBy('right');
    }
    return [];
  }

  private clickNavButton(key: string) {
    if (this.bottomNavigation && this.bottomNavigation.onClick) {
      this.bottomNavigation.onClick(key);
    }
  }

  private filterButtonsBy(type: 'right' | 'left'): NavButton[] {
    return _.filter(this.bottomNavigation.buttons, { type }).map(btn => ({
      ...btn,
      disabled: !!btn?.disabled,
    }));
  }
}
