










































































import { Component } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import { DelayedDispatchReasonType } from 'ncp-api-supporter';
import { addDay } from '@/utils/dateFormat';
import SelectBox from '@/components/common/SelectBox.vue';
import WindowPopupMain from '@/views/popups/Main.vue';
import { DateCalendarOption } from '@/types';
import DateCalendar from '@/components/common/DateCalendar.vue';

@Component({ components: { SelectBox, DateCalendar } })
export default class NaverDelay extends WindowPopupMain {
  private reasonSelectOption: OptionData<DelayedDispatchReasonType>[] = [
    { value: 'PRODUCT_PREPARE', name: '상품준비중' },
    { value: 'CUSTOMER_REQUEST', name: '고객 요청' },
    { value: 'CUSTOM_BUILD', name: '주문 제작' },
    { value: 'RESERVED_DISPATCH', name: '예약 발송' },
    { value: 'ETC', name: '기타' },
  ];

  private DueDateOption: DateCalendarOption = {
    selectedYmd: addDay(new Date(), 1),
    name: 'dueDateOption',
    fromRanges: addDay(new Date(), 1),
  };

  private formData = {
    dispatchDueDate: addDay(new Date(), 1),
    delayedDispatchReasonType: 'PRODUCT_PREPARE' as DelayedDispatchReasonType,
    dispatchDelayDetailReason: '',
    invoiceNo: '',
  };

  private onDeliveryDelay() {
    const { dispatchDueDate, dispatchDelayDetailReason, delayedDispatchReasonType } = this.formData;

    this.$api
      .putNaverPayDelayProductOrder({
        data: {
          orderOptionNos: this.data.orderProductOptionNos,
          dispatchDueDate,
          delayedDispatchReasonType,
          dispatchDelayDetailReason,
        },
      })
      .then(() => {
        alert(this.$t('네이버페이 발송지연 처리가 요청되었습니다. 상세 처리내용은 주문상세에서 확인해주세요.'));
        this.onPositiveClick();
      });
  }

  private onClose() {
    window.close();
  }

  private onChangeDueDate(requestDateTime: string) {
    this.formData.dispatchDueDate = requestDateTime;
  }
}
