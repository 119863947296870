








import { Vue, Component, Prop } from 'vue-property-decorator';
import GuideModalIframe from '@/components/iframe/GuideModalIframe.vue';

@Component({ components: { GuideModalIframe } })
export default class ShopByGuideButton extends Vue {
  @Prop()
  private show;
  @Prop()
  private onShow;

  private get categoryKeyword() {
    const keyword = this.$route.path.split('/')[1].toUpperCase();

    switch (keyword) {
      case 'SHIPPING':
        return 'ORDER_DELIVERY';

      case 'APPEARANCE':
        return 'DESIGN';

      default:
        return keyword;
    }
  }

  private get remoteSrc() {
    const queryObject = { serviceType: 'PRO', category: this.categoryKeyword };
    const queryString = new URLSearchParams(queryObject).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/shopby-guide' + '?' + queryString;
  }
}
