


















































import { Vue, Component, PropSync, Ref, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { PlatformDisplayInfo, YorN } from 'ncp-api-supporter';
@Component({
  components: { RadioGroup },
})
export default class PlatformDisplay extends Vue {
  @PropSync('platformDisplayInfo')
  private platformDisplayInfoSync!: PlatformDisplayInfo;
  @Prop({ required: true })
  private readonly disabled: boolean;

  private radioOption = {
    name: 'platformDisplayYn',
    data: [
      {
        id: 'Y',
        name: 'platformDisplayYn',
        value: 'Y',
        display: 'ALL',
      },
      {
        id: 'N',
        name: 'platformDisplayYn',
        value: 'N',
        display: 'SETTING',
      },
    ],
  };

  @Ref()
  private readonly platformRef: RadioGroup;
  public focus(): void {
    this.platformRef.focus();
  }

  private changePlatFormDisplayType(curr: YorN): void {
    curr === 'N' && this.initializeData();
  }

  private initializeData(): void {
    this.platformDisplayInfoSync.pcYn = 'Y';
    this.platformDisplayInfoSync.mobileWebYn = 'Y';
    this.platformDisplayInfoSync.mobileYn = 'N';
  }
}
