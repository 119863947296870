















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import { OptionData } from '@/helpers/type';
import { MallDetail, NCPResponse, TradeBank } from 'ncp-api-supporter';
import { BankAccount } from 'ncp-api-supporter/dist/types';

@Component({ components: { SelectBox } })
export default class TdAdditionalBankAccount extends Vue {
  @Prop({ required: true })
  private readonly mallNo!: number;

  @Prop({ required: true })
  private readonly isModifyMode!: boolean;

  @Prop({ required: true })
  private readonly showModifyButton!: boolean;

  @PropSync('additionalPayBankAccount', { required: true })
  private additionalPayBankAccountSync!: BankAccount;

  private tradeBankOptions: OptionData<string>[] = [];
  private tradeBanks: TradeBank[] = [];
  private tradeBank = '';

  async created() {
    await this.fetchTradeBanks();
    this.setTradeBankSelectList();
  }

  protected async fetchTradeBanks() {
    const {
      data: { tradeBanks },
    }: NCPResponse<MallDetail> = await this.$api.getMallsMallNo({
      pathParams: {
        mallNo: this.mallNo.toString(),
      },
    });

    this.tradeBanks = tradeBanks;
  }

  private setTradeBankSelectList() {
    this.tradeBankOptions = this.tradeBanks.map(tradeBank => ({
      value: tradeBank.bankAccount,
      name: `${tradeBank.bankAccount} / ${tradeBank.bank} (${this.$t('예금주')} : ${tradeBank.bankDepositorName} )`,
    }));

    this.tradeBank = this.additionalPayBankAccountSync.account;
  }
}
