










































































import { Component, Ref, Vue } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import { getCurrentMallNo, getMallName } from '@/utils/mall';
import { PopupClose, PopupResult, throwPopup, throwWindowPopup } from '@/helpers/popup';
import { getStorageFile } from '@/utils/storage';
import { validateFileNameExtension } from '@/utils/webExcel';
import {
  GetReviewsConfigurationsExcludedProductsAvailable,
  GetReviewsConfigurationsExcludedProductsAvailableRequest,
  GetReviewsConfigurationsExcludedProductsExcelFailHistoriesRequest,
} from 'ncp-api-supporter';
import { saveBinaryDataToFile } from '@/utils/fileSaver';
import { getToday } from '@/utils/dateFormat';
import { ResultPopupData } from '@/types/ProductThumbnailRegistration';
import BoardExceptionSettingGrid from '@/views/contents/board/basic/boardSettingSub/BoardExceptionSetting/BoardExceptionSettingGrid.vue';

interface AvailableProductNosResultCountInfo {
  totalCount: number;
  notAvailableProductNosCount: number;
  notAvailableProductNos: number[];
}

export interface ExceptionProductNosInfo {
  productNosForSave: number[];
  productNosForDelete: number[];
}

@Component({
  components: { NoticeBox, TextInput, SearchKeyword, BoardExceptionSettingGrid },
})
export default class BoardExceptionSetting extends Vue {
  private mallProductNosKeyword = '';
  private mallProductNoErrorText = '';
  private exceptionProductNosInfo: ExceptionProductNosInfo = {
    productNosForSave: [],
    productNosForDelete: [],
  };

  private noticeList = this.$t('BOARD.REVIEW.NOTICE')
    .toString()
    .split('\n');

  private errorMsg = {
    numberType: this.$t('BOARD.REVIEW.ERR_INPUT_TYPE').toString(),
    inputLength: this.$t('BOARD.REVIEW.ERR_PRODUCT_NO_LENGTH').toString(),
  };

  @Ref()
  private readonly exceptionSettingGridRef: BoardExceptionSettingGrid;

  public getExceptionProductNosInfo(): ExceptionProductNosInfo {
    return this.exceptionProductNosInfo;
  }

  public isChangedGridData(): boolean {
    return this.exceptionSettingGridRef.isChangedGridData();
  }

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  private get mallProductNoKeywords(): string[] {
    return this.mallProductNosKeyword.split(',');
  }

  private getAvailableProductNosResultCountInfo({
    availableProductNos,
    notAvailableProductNos,
  }: GetReviewsConfigurationsExcludedProductsAvailable): AvailableProductNosResultCountInfo {
    const availableProductNosCount = availableProductNos.length;
    const notAvailableProductNosCount = notAvailableProductNos.length;
    return {
      totalCount: availableProductNosCount + notAvailableProductNosCount,
      notAvailableProductNosCount,
      notAvailableProductNos,
    };
  }

  private confirmNotAvailableProductNosRegistration(
    excludedProductsInfo: GetReviewsConfigurationsExcludedProductsAvailable,
  ): boolean {
    const {
      totalCount,
      notAvailableProductNosCount,
      notAvailableProductNos,
    } = this.getAvailableProductNosResultCountInfo(excludedProductsInfo);
    return confirm(
      this.$t('BOARD.REVIEW.CONFIRM_NOT_AVAILABLE_PRODUCT', [
        totalCount,
        notAvailableProductNosCount,
        notAvailableProductNos.join(','),
      ]).toString(),
    );
  }

  //입력한 상품번호
  private validateInputProductNos(excludedProductsInfo: GetReviewsConfigurationsExcludedProductsAvailable): boolean {
    const hasAvailableProductNos = excludedProductsInfo?.availableProductNos?.length > 0;
    const hasNotAvailableProductNos = excludedProductsInfo?.notAvailableProductNos?.length > 0;
    if (!hasAvailableProductNos && hasNotAvailableProductNos) {
      alert(this.$t('BOARD.REVIEW.NO_PRODUCT'));
      return false;
    }
    if (hasAvailableProductNos && hasNotAvailableProductNos) {
      return this.confirmNotAvailableProductNosRegistration(excludedProductsInfo);
    }
    if (hasAvailableProductNos && !hasNotAvailableProductNos) {
      return confirm(this.$t('BOARD.REVIEW.CONFIRM_AVAILABLE_PRODUCT').toString());
    }
    return true;
  }

  private async getExcludedProductsAvailable(
    mallProductNos: number[],
  ): Promise<GetReviewsConfigurationsExcludedProductsAvailable> {
    const request: GetReviewsConfigurationsExcludedProductsAvailableRequest = {
      params: {
        mallNo: this.mallNo,
        productNos: mallProductNos.join(','),
      },
    };
    try {
      const { data } = await this.$api.getReviewsConfigurationsExcludedProductsAvailable(request);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  private async searchExcludedProducts(mallProductNos: number[], shouldValidateProductNos = false) {
    const excludedProductsInfo = await this.getExcludedProductsAvailable(mallProductNos);
    if (shouldValidateProductNos && !this.validateInputProductNos(excludedProductsInfo)) return;

    this.exceptionProductNosInfo.productNosForSave = [
      ...this.exceptionProductNosInfo.productNosForSave,
      ...excludedProductsInfo.availableProductNos,
    ];
    this.exceptionSettingGridRef.getExcludedProducts(this.exceptionProductNosInfo.productNosForSave);
  }

  private openProductSearchPopup() {
    const data = {
      fixed: {
        mallNo: this.mallNo,
      },
      queryLimit: {
        saleSettingStatus: 'AVAILABLE_FOR_SALE,STOP_SELLING',
      },
      directSend: true,
      rowPickLimitCount: 500,
      confirmMsg: this.$t('BOARD.BOARD.EXCEPTION_PRODUCT_CONFIRM_MSG'),
    };

    const productSearchPopupCallback = (callbackData: PopupResult) => {
      if (callbackData.state === PopupClose.CLOSE) return;
      const mallProductNos = callbackData.data.map(({ mallProductNo }) => mallProductNo);
      this.searchExcludedProducts(mallProductNos);
    };

    throwWindowPopup('ProductInquiry', data, 'xxlg', productSearchPopupCallback);
  }

  private checkProductNosWithValidationFunc(validateProductNo: (productNo: string) => boolean): boolean {
    if (this.mallProductNosKeyword.length === 0) return false;
    return this.mallProductNoKeywords.every(productNo => validateProductNo(productNo));
  }

  private isNumberTypeProductNo(productNo: string): boolean {
    return productNo === '0' || Boolean(Number(productNo));
  }

  private isLessThanTenProductNo(productNo: string): boolean {
    const MAX_PRODUCT_NO_LENGTH = 10;
    return productNo.length < MAX_PRODUCT_NO_LENGTH;
  }

  private validateMallProductNos() {
    const isNumberTypeProductNo = this.checkProductNosWithValidationFunc(this.isNumberTypeProductNo);
    if (isNumberTypeProductNo === false) {
      this.mallProductNoErrorText = this.errorMsg.numberType;
      return false;
    }

    const isLessThanTenProductNo = this.checkProductNosWithValidationFunc(this.isLessThanTenProductNo);
    if (isLessThanTenProductNo === false) {
      this.mallProductNoErrorText = this.errorMsg.inputLength;
      return false;
    }
    return true;
  }

  private convertProductNosToNumber(): number[] {
    return this.mallProductNoKeywords.map(mallProductNo => Number(mallProductNo));
  }

  private handleProductSearchClick() {
    if (this.validateMallProductNos() === false) return;
    this.mallProductNoErrorText = '';
    const mallProductNos = this.convertProductNosToNumber();
    this.searchExcludedProducts(mallProductNos, true);
  }

  private handleExcelDownloadClick() {
    getStorageFile(
      '상품후기 작성불가 상품 엑셀업로드 양식.xlsx',
      'excel-sample',
      'Upload_Review_ProductNo_Sample.xlsx',
    );
  }

  private get mallName(): string {
    return getMallName(this.mallNo);
  }

  private async downloadFailExcelFile(failHistoryId: string) {
    const request: GetReviewsConfigurationsExcludedProductsExcelFailHistoriesRequest = {
      params: {
        historyId: failHistoryId,
      },
      responseType: 'arraybuffer',
    };
    try {
      const { data } = await this.$api.getReviewsConfigurationsExcludedProductsExcelFailHistories(request);
      const fileName = `${this.mallName}_${this.$t('BOARD.REVIEW.FAIL_EXTENSION_FILE_NAME')}_${getToday(
        'YYYYMMDD',
      )}.xlsx`;
      saveBinaryDataToFile(data, fileName);
    } catch (e) {
      console.error(e);
    }
  }

  private openResultPopup(popupData: ResultPopupData, failHistoryId: string) {
    throwPopup({
      name: 'UploadResult',
      data: {
        result: popupData,
        type: 'PRODUCT',
      },
    });
    if (popupData.failCount > 0) {
      this.downloadFailExcelFile(failHistoryId);
    }
  }

  private getExcelFormData(excelFile: File): FormData {
    const formData = new FormData();
    formData.append('excelFile', excelFile);
    formData.append('mallNo', this.mallNo.toString());
    return formData;
  }

  private async postExcelFile(excelFile: File) {
    const formData = this.getExcelFormData(excelFile);
    try {
      const {
        data: { requestedCount, successCount, failCount, failHistoryId, registeredProductNos },
      } = await this.$api.postReviewsConfigurationsExcludedProductsExcelBulk({ data: formData });

      this.openResultPopup({ successCount, failCount, totalCount: requestedCount }, failHistoryId);
      this.exceptionProductNosInfo.productNosForSave = [
        ...this.exceptionProductNosInfo.productNosForSave,
        ...registeredProductNos,
      ];
      this.exceptionSettingGridRef.getExcludedProducts(this.exceptionProductNosInfo.productNosForSave);
    } catch (e) {
      console.error(e);
    }
  }

  private handleExcelUploadClick({ target }: { target: HTMLInputElement }) {
    const excelFile = target.files[0];
    target.value = null;
    const isExelFile = validateFileNameExtension(excelFile, 'BOARD.REVIEW.ERR_EXCEL_EXTENSION');
    if (!isExelFile) return;
    this.postExcelFile(excelFile);
  }
}
