

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ColorPicker from '@/components/common/colorpicker/ColorPicker.vue';
import { throwLoadingSpinner } from '@/helpers/loading';
import ClickOutside from 'vue-click-outside';

@Component({
  components: {
    ColorPicker,
  },
  directives: {
    ClickOutside,
  },
})
export default class ColorPickerButton extends Vue {
  @Prop({ default: '#ffffff' }) public defaultColorHexCode: string;
  @Prop({ default: false }) public defaultClickabled: boolean;

  private cilckColor = false;
  private isEnableColorPicker = false;
  private temporarySelectColorHexCode = '#ffffff';
  private selectColorHexCode = '#ffffff';

  @Watch('defaultColorHexCode')
  onPropertyReceived() {
    this.temporarySelectColorHexCode = this.defaultColorHexCode;
    this.selectColorHexCode = this.defaultColorHexCode;
  }

  mounted() {
    this.temporarySelectColorHexCode = this.defaultColorHexCode;
    this.selectColorHexCode = this.defaultColorHexCode;
  }

  private onClickColorPicker() {
    if (this.defaultClickabled) {
      this.isEnableColorPicker = false;
      return;
    }
    throwLoadingSpinner(true);
    this.isEnableColorPicker = true;
  }
  private onClickOutside() {
    if (!this.cilckColor && this.isEnableColorPicker) {
      this.isEnableColorPicker = false;
      throwLoadingSpinner(false);
    }
    this.cilckColor = false;
  }

  private onSelectColor() {
    this.selectColorHexCode = this.temporarySelectColorHexCode;
  }

  private onClickSubmit() {
    this.onSelectColor();
    this.$emit('onSelect', this.selectColorHexCode);
    this.onClickOutside();
  }

  public onUpdateColor(selectedColor: { color: string; origin: string }) {
    if (selectedColor.origin === 'palette') {
      this.cilckColor = true;
    }
    this.temporarySelectColorHexCode = selectedColor.color;
  }
}
