import { DomainByMall, PreviousOrdersMall, Mall } from 'ncp-api-supporter'; // GetMallsMdsRequest
import { DomainByMallData, MallState } from '@/types';
import { api } from '@/api';
import { ActionContext } from 'vuex';

export default {
  namespaced: true,
  state: { malls: [], mallDomains: [], previousMalls: [] },
  actions: {
    async fetchMalls({ commit }: ActionContext<MallState, any>): Promise<void> {
      const { data } = await api.getMalls();

      commit('SET_MALLS', data);
    },
    async fetchMallDomains({ commit }: ActionContext<MallState, any>): Promise<void> {
      const { data } = await api.getDomainByMalls();

      commit('SET_MALL_DOMAINS', data);
    },
    async fetchPreviousMalls({ commit }: ActionContext<MallState, PreviousOrdersMall[]>): Promise<void> {
      const { data } = await api.getPreviousOrdersMalls();

      commit('SET_PREVIOUS_MALLS', data);
    },
  },
  mutations: {
    SET_MALLS(state: MallState, malls: Mall[]): void {
      state.malls = malls;
    },
    SET_MALL_DOMAINS(state: MallState, mallDomains: DomainByMall[]): void {
      state.mallDomains = mallDomains;
    },
    SET_PREVIOUS_MALLS(state: MallState, previousMalls: PreviousOrdersMall[]): void {
      state.previousMalls = previousMalls;
    },
  },
  getters: {
    getMalls(state: MallState): Mall[] {
      return state.malls.filter(
        ({ used, mallStatus }) => used && (mallStatus === 'ACTIVE' || mallStatus === 'BLOCKED'),
      );
    },
    getUsedMalls(state: MallState): Mall[] {
      return state.malls.filter(({ used }) => used);
    },
    getMallDomains(state: MallState): DomainByMall[] {
      return [...state.mallDomains];
    },
    getDomainByMalls(state: MallState): DomainByMallData[] {
      return state.mallDomains.map(({ domains, mallNo }) => {
        const { mallName, countryCode, createdDateTime } = state.malls.find(item => item.mallNo === mallNo);

        return {
          mallNo,
          mallName,
          createdDateTime,
          countryCode,
          domains,
        };
      });
    },
    getPreviousMalls(state: MallState): PreviousOrdersMall[] {
      return [...state.previousMalls];
    },
  },
};
