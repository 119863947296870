







































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import {
  AUTH_METHOD,
  AUTH_SECURITY,
  getOperateSecurityCheckBoxOption,
  getOperateSecurityToolTipOption,
  i18nSecurity,
} from '@/const/contents/configuration/operateSecurity';
import AuthNoticeBox from '@/views/contents/configuration/security/operateSecurity/AuthNoticeBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

// TODO 화면 접속 보안 설정 해야함
// TODO 이메일, SMS 인증은 기획에서 빠짐 - 추후에 순차적으로 적용 예정

@Component({
  components: {
    ToolTip,
    CheckboxGroup,
    AuthNoticeBox,
  },
})
export default class AdminSecurityAuthSetting extends Vue {
  @PropSync('adminSecurity', { required: true })
  private adminSecuritySync!: {
    otpUsed: boolean;
    emailUsed: boolean;
    displaySecurityUsed: boolean;
    loginSecurityUsed: boolean;
    smsUsed: boolean;
  };

  private readonly i18nSecurity = i18nSecurity;

  private authSecurity = '';
  private authMethod = '';

  private toolTipOption = getOperateSecurityToolTipOption();
  private checkBoxOption = getOperateSecurityCheckBoxOption();

  @Watch('adminSecuritySync')
  private initAdminSecurity(): void {
    this.authSecurity = '';
    this.authMethod = '';

    if (this.adminSecuritySync.loginSecurityUsed) {
      this.authSecurity += AUTH_SECURITY.LOGIN;
    }

    if (this.adminSecuritySync.displaySecurityUsed) {
      this.authSecurity += AUTH_SECURITY.DISPLAY;
    }

    if (this.adminSecuritySync.emailUsed) {
      this.authMethod += AUTH_METHOD.EMAIL;
    }

    if (this.adminSecuritySync.smsUsed) {
      this.authMethod += AUTH_METHOD.SMS;
    }

    if (this.adminSecuritySync.otpUsed) {
      this.authMethod += AUTH_METHOD.OTP;
    }
  }

  private changeAuthSecurity(): void {
    this.adminSecuritySync.loginSecurityUsed = this.authSecurity.includes(AUTH_SECURITY.LOGIN);
    this.adminSecuritySync.displaySecurityUsed = this.authSecurity.includes(AUTH_SECURITY.DISPLAY);

    if (!this.adminSecuritySync.loginSecurityUsed && !this.adminSecuritySync.displaySecurityUsed) {
      this.authMethod = '';
      this.changeAuthMethod();
    }
  }

  private changeAuthMethod(): void {
    this.adminSecuritySync.emailUsed = this.authMethod.includes(AUTH_METHOD.EMAIL);
    this.adminSecuritySync.smsUsed = this.authMethod.includes(AUTH_METHOD.SMS);
    this.adminSecuritySync.otpUsed = this.authMethod.includes(AUTH_METHOD.OTP);
  }
}
