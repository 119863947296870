


































































































































































import { Vue, Component, PropSync } from 'vue-property-decorator';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import {
  selectBoxOptions,
  brandCertifyRadioOptions,
  naverPayCheckBoxOptions,
  naverAccumulationCheckBoxOptions,
} from '@/views/contents/product/basic/ProductAdd/naverShoppingEPInfo/NaverShoppingEPInfo';
import { NaverShoppingEPInformation } from '@/types';
import { NAVER_EXTRA, NAVER_PRODUCT_SALE_TYPE } from '@/const/contents/product';

@Component({
  components: {
    ToolTip,
    RadioGroup,
    TextInput,
    SelectBox,
    CheckboxGroup,
  },
})
export default class NaverShoppingEPInfo extends Vue {
  @PropSync('naverShoppingEP')
  private naverShoppingEPSync!: NaverShoppingEPInformation['naverShoppingEP'];

  private readonly NAVER_EXTRA = NAVER_EXTRA;
  private readonly NAVER_PRODUCT_SALE_TYPE = NAVER_PRODUCT_SALE_TYPE;

  private selectBoxOptions = selectBoxOptions();
  private brandCertifyRadioOptions = brandCertifyRadioOptions;
  private naverPayCheckBoxOptions = naverPayCheckBoxOptions;
  private naverAccumulationCheckBoxOptions = naverAccumulationCheckBoxOptions;

  private naverPayAvailableTooltipOption = { message: this.$t('PRODUCT.ADD.NAVER_PAY_AVAILABLE_TOOLTIP') };
  private naverPayAccumulationTooltipOption = { message: this.$t('PRODUCT.ADD.NAVER_PAY_ACCUMULATION_TOOLTIP') };

  private naverPayAvailable = 'PC,MOBILE';
  private naverPayAccumulation = 'PC,MOBILE';

  private handleNaverPayLocationChange(isAccumulation: boolean) {
    isAccumulation
      ? (this.naverShoppingEPSync.naverPayAccumLocations = JSON.stringify(this.naverPayAccumulation.split(',')))
      : (this.naverShoppingEPSync.naverPayAvailableLocations = JSON.stringify(this.naverPayAvailable.split(',')));
  }

  private handleSaleTypeChange() {
    if (this.naverShoppingEPSync.saleType === NAVER_PRODUCT_SALE_TYPE.RENTAL) {
      return;
    }
    this.naverShoppingEPSync.monthlyRentalFee = null;
    this.naverShoppingEPSync.rentalContractPeriod = null;
  }
}
