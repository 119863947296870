











































import { Component, Prop, Mixins } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import Article from '@/components/board/basic/mixins/ArticleMixin';
@Component({
  components: { SelectBox },
})
export default class ArticlMovePopup extends Mixins(Article) {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: Function;
  @Prop() private readonly onNegativeClick!: Function;

  private boardNo = -1;
  private boardNos: { name: string; value: number }[] = [];

  async created() {
    this.boardNos = await this.getSelectBoardNos(this.data.mallNo, true);
    /*if (this.data.articleNumbers.split(',') > 1) {
      this.boardNo = -1;
    } else {
      this.boardNo = this.data.boardNo;
    }*/
    //this.boardNo = -1;
  }

  /**
   * ------- moved article logic --------
   * 1. check form (validation )
   * 2. call api of moved article
   * 3. call callback method.(close popup)
   * -------------------------------------
   */
  private async movedBtnClick(): Promise<void> {
    // 1
    if (!this.checkForm()) return;
    // 2
    const result = await this.movedArticle(this.data, this.boardNo);
    // 3
    this.onPositiveClick({ result: result });
  }

  private checkForm(): boolean {
    if (Number(this.boardNo) == -1) {
      alert(this.$t('BOARD.BASIC.ARTICLE.BOARD_REQUIRED'));
      return false;
    }
    return true;
  }
}
