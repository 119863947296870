







import { Vue, Component } from 'vue-property-decorator';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';
import { i18nListByKey } from '@/utils/i18n';
@Component({
  components: { TabMenu },
})
export default class Reject080Main extends Vue {
  private menuList: TabMenuI[] = [
    { display: 'MEMBER.REJECT080.CONFIG_TITLE', path: 'config' },
    { display: 'MEMBER.REJECT080.LIST_TITLE', path: 'list' },
  ];

  created() {
    this.menuList = i18nListByKey(this, this.menuList, 'display');
  }
}
