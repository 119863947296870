




import { Vue, Component } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';
import CouponList from './CouponList.vue';

@Component({
  components: { AdminIframe, CouponList },
})
export default class CouponIssueIframe extends Vue {
  private get remoteSrc() {
    const queryObject = { serviceType: 'PRO', ...this.$route.query };
    const queryString = new URLSearchParams(queryObject).toString();
    const src = process.env.VUE_APP_ADMIN_REMOTE_URL + this.$route.path + '?' + queryString;

    return src;
  }
}
