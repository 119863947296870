export default Object.freeze({
  // SecurityServer
  MOBILE: '모바일웹',
  PC: 'PC웹',
  SERVER_NOTICE: `보안서버 설정은`,
  SERVER_NOTICE_0: `마이페이지 > 도메인 연결`,
  SERVER_NOTICE_1: `에서 쇼핑몰에 연결한 도메인 별로 사용 설정이 가능합니다.`,
  SERVER_NOTICE_2: 'PC웹과 모바일웹 보안서버는 별도로 적용하셔야 합니다.',
  SERVER_NOTICE_3: '보안서버 설치 후, 사용 시 일부 서비스는 정상적인 이용을 위해 설정을 변경해주셔야 합니다. ',
  CHECK_SERVER_NOTICE: '안내 확인하기>',
  CONFIG_DOMAINS: '도메인별 보안서버 관리',
  APPLY_SERVER: '보안서버 신청하기',
  TYPE: '구분',
  DOMAIN: '도메인',
  SSL: 'SSL인증서',
  CERTIFICATION_EXPIRATION: '인증서 만료일',
  USE_YN: '사용여부',
  SEAL: '인증서 씰 설정',

  USE: '사용함',
  NOT_USE: '사용 안 함',

  NOT_INSTALLED: '미설치',
  INSTALLED: '설치완료',
  EXPIRATION: '기간만료',

  NOTICE_DEFAULT_APPLIED_SECURITY_SERVER: '임시도메인은 보안서버가 기본으로 적용됩니다.',
  NOTICE_LAW:
    '개인정보 보호법 제 29조 및 그 시행령에 따라 보안서버(SSL) 구축 의무사항 위반 시 민원이 발생할 경우 사전경고 없이 최고 3,000만원의 과태료가 부과될 수 있습니다. ',
  CHECK_LAW: '관련법령 확인하기>',

  // seal setting
  DETAILS_CONFIG: '인증서 씰 상세 설정',
  USE_CONFIG: '표시설정',
  SCRIPT: '인증서 씰 스크립트',
  PLACEHOLDER_SCRIPT: '마이페이지>쇼핑몰 관리>보안서버 관리에서 복사한 인증서 씰 코드를 입력해주세요.',
  NOTICE_1: '인증서 씰 표시 설정 시 쇼핑몰 하단에 설정한 인증마크가 표기됩니다.',
  NOTICE_2: '인증서 씰 스크립트는',
  NOTICE_3: '마이페이지>쇼핑몰 관리>보안서버 관리',
  NOTICE_4: '에서 확인할 수 있습니다.',
  SSL_USE: '표시함',
  SSL_NOT_USE: '표시 안 함',
  ALERT_INSERT_SCRIPT: '인증서 씰 스크립트를 입력해주세요.',
  ALERT_CHANGED_SCRIPT: '인증서 씰 상세 설정이 변경되었습니다.\n변경사항은 하단 저장버튼 클릭 시 최종 반영됩니다.',
  CONFIRM_CHANGING_SECURITY_SERVER_USEYN: `해당 도메인의 보안서버 사용여부를 '{value}'으로 변경하시겠습니까? \n변경사항은 하단 저장버튼 클릭 시 최종 반영됩니다.`,

  // security server setting guide
  PRECAUTIONS: '보안서버 사용 시 유의사항',
  CHANGE_URL_TITLE: '가상계좌 입금내역 실시간 통보 URL 변경',
  CHANGE_URL_CONTENT_1: `결제수단으로 가상계좌를 사용하는 경우, 보안서버 사용 시 NHN KCP, 이니시스 상점 관리자 설정에서
            입금통보url정보를 https://~로 변경해주셔야 합니다.`,
  CHANGE_URL_CONTENT_2: '설정 > 결제정책 > 전자결제(PG) 설정',
  CHANGE_URL_CONTENT_3: '의 툴팁을 참고해주세요.',
  CHANGE_CALLBACK_URL: '간편로그인 Callback URL 변경',
  CHANGE_CALLBACK_CONTENT_1: `페이코/네이버/카카오/페이스북 등 간편로그인 기능을 사용하는 경우, 보안서버 사용 시 각 인증업체의 개발자
            센터에서 Callback URL에 입력된 정보를 https://~ 로 변경해주셔야 합니다.`,
  CHANGE_CALLBACK_CONTENT_2: '설정 > 기본정책 > 외부서비스 설정',
  CHANGE_CALLBACK_CONTENT_3: '의 툴팁을 참고해주세요.',

  SSL_STATUS_TOOL_TIP_1:
    '보안서버(Secure Server)란 인터넷 상에서 개인정보를 암호화 하여 전송기능을 제공하는 서버로서 개인정보 보호의 필수적인 장치입니다.',
  SSL_STATUS_TOOL_TIP_2: 'SSL인증서는',
  SSL_STATUS_TOOL_TIP_3: '[보안서버 신청하기]',
  SSL_STATUS_TOOL_TIP_4: '를 통해 신청 및 설치할 수 있습니다.',
  SSL_STATUS_TOOL_TIP_5:
    '보안서버 신청 시 설치기간은 2~7일로 다소 소요될 수 있으며, 설치완료 시 ‘설치완료‘로 표기되며 사용여부 설정이 가능합니다.',
  SSL_STATUS_TOOL_TIP_6:
    '인증서 만료일이 지난 SSL인증서는 ‘기간만료’로 표기되며 보안서버 만료로 사이트 접속이 되지 않을 수 있으니 사용여부를 ‘사용 안 함‘으로 변경하시거나',
  SSL_STATUS_TOOL_TIP_7: '보안서버 관리',
  SSL_STATUS_TOOL_TIP_8: '에서 ssl인증서 사용기간을 연장해주세요.',

  SSL_USE_TOOL_TIP_1: 'SSL인증서의 상태가 ‘설치완료’ / ‘기간만료’일 때 사용여부 설정이 가능합니다.',
  SSL_USE_TOOL_TIP_2:
    '‘사용함’으로 설정 시 해당 도메인에 보안서버가 적용되어, https프로토콜로 쇼핑몰 접근이 가능합니다.',
  SSL_USE_TOOL_TIP_3:
    'SSL인증서가 ‘설치완료’상태이고 유효하더라도 사용여부를 ‘사용 안 함’으로 설정 시 보안서버가 적용되지 않을 수 있으므로 유의 바랍니다.',

  SSL_SEAL_TOOL_TIP_1: 'SSL인증서의 상태가 ‘설치완료’ / ‘기간만료’일 때 인증서 씰 설정이 가능합니다.',
  SSL_SEAL_TOOL_TIP_2: '인증서 씰 ‘표시함’설정 시 쇼핑몰 하단에 설정한 보안서버 인증마크가 표기됩니다.',
  SSL_SEAL_TOOL_TIP_3:
    '보안서버 사용여부가 ‘사용 안 함’일 경우에는 인증 씰 설정을 ‘표시함’으로 설정하더라도 쇼핑몰에 노출되지 않습니다.',
  SSL_SEAL_TOOL_TIP_4:
    '쇼핑몰에 인증마크 표기 시 고객의 개인정보를 안전하게 관리하는 기업이라는 이미지를 부각시킴으로 신뢰성을 향상 시킬 수 있습니다. 인증마크로 개인정보보호 안전성을 사용자에게 알릴 수 있으며, 인증마크를 클릭하면 일련번호, 발급 업체명 등의 관련정보를 확인 할 수 있습니다.',
});
