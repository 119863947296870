var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show)?_c('div',{staticClass:"alarm-layer-popup on",attrs:{"id":"notice-popup"}},[_c('div',{staticClass:"alarm-popup",staticStyle:{"display":"block"},attrs:{"id":"popup_alarm"}},[_c('div',{staticClass:"popup-head"},[_c('p',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('HEADER.MALL_NOTICE')))])]),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){return _vm.onShow(null)}}},[_c('span',{staticClass:"blind"},[_vm._v(_vm._s(_vm.$t('CLOSE')))])]),_c('div',{staticClass:"popup-body"},[_c('notice-box',{staticStyle:{"margin-top":"0"},attrs:{"notice-list":_vm.$t('HEADER.NOTICE_LIST')
              .toString()
              .split('\n')}}),(_vm.isProServicePlanType && _vm.expirationMalls.length)?_c('div',{staticClass:"point-info-area"},[_c('p',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('HEADER.ACCOUNT_EXTENSION_NOTICE')))]),_c('ul',_vm._l((_vm.expirationMalls),function(ref){
              var mallName = ref.mallName;
              var mallNo = ref.mallNo;
              var expirationDueDays = ref.expirationDueDays;
return _c('li',{key:mallNo,staticClass:"shop-list"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(mallName))]),_c('div',{staticClass:"point-info"},[_c('span',{staticClass:"remain"},[_vm._v(_vm._s(_vm.$t('HEADER.EXPIRATION_DATE')))]),_c('span',{staticClass:"mall"},[_c('strong',[_vm._v(" "+_vm._s(Math.abs(expirationDueDays))+" ")]),_vm._v(" "+_vm._s(_vm.$t('DAY'))+" "+_vm._s(_vm.$t('HEADER.OVER'))+" ")]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.openCommercePaymentPopup('shopbyExtend', mallNo)}}},[_vm._v(" "+_vm._s(_vm.$t('HEADER.EXTENSION'))+" ")])])])}),0)]):_vm._e(),(_vm.fewMallPointList.length > 0)?_c('div',{staticClass:"point-info-area"},[_c('p',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('HEADER.SMS_POINT_NOTICE')))]),_c('ul',_vm._l((_vm.fewMallPointList),function(ref){
              var mallName = ref.mallName;
              var mallNo = ref.mallNo;
              var point = ref.point;
return _c('li',{key:mallNo,staticClass:"shop-list"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(mallName))]),_c('div',{staticClass:"point-info"},[_c('span',{staticClass:"remain"},[_vm._v(_vm._s(_vm.$t('HEADER.CURRENT_POINT')))]),_c('span',{staticClass:"point"},[_c('strong',[_vm._v(" "+_vm._s(Number(point) .toFixed(1) .toString())+" ")]),_vm._v(" "+_vm._s(_vm.$t('SERVICE_POINT'))+" ")]),_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.openChargePopup(mallNo)}}},[_vm._v(_vm._s(_vm.$t('HEADER.CHARGE')))])])])}),0)]):_vm._e()],1)])]):_vm._e(),(_vm.issued)?_c('a',{staticClass:"call",attrs:{"href":"#notice-popup","data-popup":"popup_alarm"},on:{"click":function($event){$event.preventDefault();return _vm.onShow('NOTICE')}}},[_vm._v(" "+_vm._s(_vm.$t('HEADER.VIEW_MALL_NOTICE'))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }