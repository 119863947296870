








































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import {
  radioOptions,
  gradeSelectBoxOptions,
  attachBottomNav,
} from '@/views/contents/marketing/naverShopping/naverShoppingConfigRenderData';
import { Getter } from 'vuex-class';
import {
  Mall,
  GetProductsConfigurationNaverShopping,
  GetProductsConfigurationNaverShoppingRequest,
  PutProductsConfigurationNaverShoppingRequest,
} from 'ncp-api-supporter';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { throwPopup } from '@/helpers/popup';
import {
  getCurrentCountByGrade,
  getDefaultNaverShopping,
} from '@/views/contents/marketing/naverShopping/naverShoppingConfigRules';

@Component({
  components: { ToolTip, TextInput, RadioGroup, SelectBox },
})
export default class NaverShoppingConfig extends Vue {
  @Getter('mall/getMalls')
  private readonly malls!: Mall[];

  @Ref('authenticationKeyInput')
  private readonly authenticationKeyInput!: TextInput;

  private readonly radioOptions = radioOptions;
  private readonly gradeSelectBoxOptions = gradeSelectBoxOptions;
  private initAuthenticationKey = '';

  private naverShopping: GetProductsConfigurationNaverShopping = getDefaultNaverShopping();
  private duplicateCheckAuthenticationKeyPassedCode = '';

  get mallNo() {
    return Number(this.$route.query.mallNo) || this.malls[this.malls.length - 1].mallNo;
  }

  @Watch('mallNo')
  private async init() {
    const config = await this.fetchNaverShoppingConfig();
    this.naverShopping = { ...getDefaultNaverShopping(), ...config };
    this.initAuthenticationKey = this.naverShopping.authenticationKey;
  }

  private handleUsableChange() {
    if (this.naverShopping.supportsNaverShopping) {
      return;
    }

    this.naverShopping = getDefaultNaverShopping();
  }

  created() {
    attachBottomNav(this.putNaverShoppingConfig);
    this.init();
  }

  private resetPassedCode() {
    this.duplicateCheckAuthenticationKeyPassedCode = '';
  }

  private async fetchNaverShoppingConfig() {
    const request: GetProductsConfigurationNaverShoppingRequest = {
      params: {
        mallNo: this.mallNo,
      },
    };

    const { data } = await this.$api.getProductsConfigurationNaverShopping(request);
    return data;
  }

  private validateNaverShopping(): boolean {
    //case1: 기존 데이터 X - 신규등록 시점에 인증키 입력 안했을 때
    const isEmptyAuthenticationKey =
      this.naverShopping.supportsNaverShopping &&
      !this.naverShopping.authenticationKey?.length &&
      !this.initAuthenticationKey?.length;

    if (isEmptyAuthenticationKey) {
      alert(this.$t('MARKETING.NAVER_SHOPPING.PLEASE_WRITE_COMMON_KEY'));
      this.authenticationKeyInput.focus();
      return false;
    }

    //case2: 인증키 입력 후, 미인증
    const isModify =
      this.initAuthenticationKey !== this.naverShopping.authenticationKey &&
      this.naverShopping.authenticationKey?.length;
    if (isModify && !this.duplicateCheckAuthenticationKeyPassedCode) {
      alert(this.$t('MARKETING.NAVER_SHOPPING.PLEASE_CHECK_DUPLICATE'));
      return false;
    }

    return true;
  }

  private putNaverShoppingConfig() {
    if (!this.validateNaverShopping()) return;
    const request: PutProductsConfigurationNaverShoppingRequest = {
      data: {
        mallNo: this.mallNo,
        code: this.duplicateCheckAuthenticationKeyPassedCode,
        supportsNaverShopping: this.naverShopping.supportsNaverShopping,
        agreedToCollectingCPAOrder: this.naverShopping.agreedToCollectingCPAOrder,
        reviewType: this.naverShopping.reviewType,
        authenticationKey: this.naverShopping.authenticationKey,
        mallGrade: this.naverShopping.mallGrade,
      },
    };

    if (this.naverShopping.authenticationKey === this.initAuthenticationKey) {
      delete request.data.code; // 인증키 수정하지 않았을 경우 code를 보내지 않음
    }

    this.$api.putProductsConfigurationNaverShopping(request).then(() => {
      alert(this.$t('ALERT_SAVED'));
      this.init();
    });
  }

  private async duplicateCheckAuthenticationKey() {
    if (!this.naverShopping.authenticationKey) {
      alert(this.$t('MARKETING.NAVER_SHOPPING.PLEASE_WRITE_COMMON_KEY'));
      this.authenticationKeyInput.focus();
      return;
    }

    try {
      const request = {
        data: {
          mallNo: this.mallNo,
          authenticationKey: this.naverShopping.authenticationKey,
        },
      };

      const { data } = await this.$api.postConfigurationNaverShoppingDuplicate(request);

      this.duplicateCheckAuthenticationKeyPassedCode = data.code;
      alert(this.$t('MARKETING.NAVER_SHOPPING.USAGE_COMMON_KEY'));
    } catch (e) {
      alert(this.$t('MARKETING.NAVER_SHOPPING.ALREADY_SET_KEY'));
    }
  }

  get maxProductCount() {
    return getCurrentCountByGrade(this.naverShopping.mallGrade);
  }

  private openTooltipPopup() {
    throwPopup({
      name: 'NaverShoppingTooltip',
    });
  }
}
