

















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PopupClose, throwPopup } from '@/helpers/popup';
import { getCurrentMallNo } from '@/utils/mall';
import { PostCampaignsSettingsRequest, PostCampaignsVerifyDomainRequest } from 'ncp-api-supporter/dist/types';
import { CampaignsRequirementsValidation } from '@/components/popup/marketing/CampaignsRequirementsValidation';
import { GetCampaignsStatusRequest } from 'ncp-api-supporter';
import { CampaignsStatus } from 'ncp-api-supporter/dist/types/modules/marketing/campaign';

@Component
export default class GoogleAd extends Vue {
  private googleAdTermToPopupName = {
    merchantCenter: 'MerchantCenterTerm',
    advertisingProgram: 'AdvertisingProgramTerm',
    thirdPartyProvisionOfPersonalInformation: 'ThirdPartyProvisionOfPersonalInformationTerm',
  } as const;

  private allAgreement = false;
  private agreements = [false, false, false];
  private requiredSnsCertification = false;
  private isRequiredClaimWebsite = false;
  private isLoading = false;

  created() {
    this.getCampaigns();
  }

  private moveAccountManagePage() {
    this.$router.push({ name: 'AccountManage', query: { mallNo: this.mallNo.toString() } });
  }

  private validateCampaignRequired(data: CampaignsStatus): boolean {
    if (data?.campaign?.isRequiredClaimWebsite) {
      this.isRequiredClaimWebsite = true;
      return false;
    }
    if (data?.campaign?.isRequiredSnsCertification) {
      this.requiredSnsCertification = true;
      return false;
    }
    return true;
  }

  private async getCampaignsStatus(): Promise<CampaignsStatus> {
    const request: GetCampaignsStatusRequest = {
      params: {
        channelType: 'GOOGLE',
        mallNo: this.mallNo,
      },
    };
    try {
      const { data } = await this.$api.getCampaignsStatus(request);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  private resetCampaignInfo() {
    this.isRequiredClaimWebsite = false;
    this.requiredSnsCertification = false;
  }

  @Watch('$route.query.mallNo')
  private async getCampaigns() {
    const data = await this.getCampaignsStatus();
    if (!data?.campaign) {
      this.resetCampaignInfo();
      return;
    }
    if (!this.validateCampaignRequired(data)) return;
    this.moveAccountManagePage();
  }

  @Watch('$route.query.mallNo')
  private resetAgreements() {
    this.agreements = this.agreements.map(() => false);
  }

  @Watch('agreements')
  private setAllAgree() {
    this.allAgreement = this.agreements.every(agreement => agreement);
  }

  private get mallNo(): number {
    return getCurrentMallNo(this);
  }

  private handleAllAgree({ target }: { target: HTMLInputElement }) {
    this.agreements = this.agreements.map(() => target.checked);
  }

  private openGoogleAdTermPopup(name: (GoogleAd['googleAdTermToPopupName'])[keyof GoogleAd['googleAdTermToPopupName']]) {
    throwPopup({
      name,
    });
  }

  private refreshPage() {
    this.$router.go(0);
  }

  private async openSmsAuthentication(isAlert = true) {
    if (isAlert) alert(this.$t('MARKETING.GOOGLE_AD.ALERT_ADD_CODE'));

    const authResult = await throwPopup({
      name: 'SmsAuthentication',
      data: {
        mallNo: this.mallNo,
      },
    });

    if (authResult.state === PopupClose.CONFIRM) {
      this.moveAccountManagePage();
      return;
    }
    this.refreshPage();
  }

  private retryVerifyDomain(isAlert = true) {
    if (!isAlert) {
      this.postCampaignsVerifyDomain();
      return;
    }

    const retryVerifyDomain = confirm(this.$t('MARKETING.GOOGLE_AD.ALERT_ADD_CODE_FAIL').toString());
    if (retryVerifyDomain) {
      this.postCampaignsVerifyDomain();
      return;
    }
    this.isLoading = false;
    this.refreshPage();
  }

  private async postCampaignsVerifyDomain() {
    this.isLoading = true;
    const request: PostCampaignsVerifyDomainRequest = {
      data: {
        channelType: 'Google',
        mallNo: this.mallNo,
      },
    };
    try {
      await this.$api.postCampaignsVerifyDomain(request);
      this.isLoading = false;
      this.openSmsAuthentication();
    } catch (e) {
      this.retryVerifyDomain();
      console.error(e);
    }
  }

  private async processCampaignsSettingsError() {
    const data = await this.getCampaignsStatus();
    if (data?.campaign?.isRequiredClaimWebsite) {
      this.retryVerifyDomain();
      return;
    }
    if (data?.campaign?.isRequiredSnsCertification) {
      this.openSmsAuthentication();
      return;
    }
    this.moveAccountManagePage();
  }

  private async postCampaignsSettings() {
    this.isLoading = true;
    const request: PostCampaignsSettingsRequest = {
      data: {
        mallNo: this.mallNo,
      },
    };
    try {
      await this.$api.postCampaignsSettings(request);
      this.isLoading = false;
      this.openSmsAuthentication();
    } catch (e) {
      if (e.data.code === 'DOMAIN_VERIFICATION_FAIL') this.retryVerifyDomain();
      this.processCampaignsSettingsError();
    }
  }

  private async openSellerInformationPopup() {
    const { state } = await throwPopup({
      name: 'CampaignsRequirementsConfirmation',
      data: {
        mallNo: this.mallNo,
      },
    });
    if (state === PopupClose.CLOSE) return;
    this.postCampaignsSettings();
  }

  private confirmStartCampaign(): boolean {
    return confirm(this.$t('MARKETING.GOOGLE_AD.CONFIRM_START_AD').toString());
  }

  private checkRequiredClaimWebsite(): boolean {
    if (this.isRequiredClaimWebsite === false) return false;
    if (!this.confirmStartCampaign()) return;
    this.retryVerifyDomain(false);
    this.isRequiredClaimWebsite = false;
    return true;
  }

  private checkRequiredSnsCertification(): boolean {
    if (this.requiredSnsCertification === false) return false;
    this.openSmsAuthentication(false);
    this.requiredSnsCertification = false;
    return true;
  }

  private async handleStartBtn() {
    if (this.allAgreement === false) {
      alert(this.$t('MARKETING.GOOGLE_AD.PLEASE_ALL_AGREE'));
      return;
    }

    if (this.checkRequiredClaimWebsite() === true) return;

    if (this.checkRequiredSnsCertification() === true) return;

    if (this.confirmStartCampaign() === false) return;

    const campaignsRequirements = new CampaignsRequirementsValidation(this.mallNo);

    await campaignsRequirements.init();
    if (campaignsRequirements.isAllValid()) {
      this.postCampaignsSettings();
      return;
    }
    this.openSellerInformationPopup();
  }
}
