import { refreshToken, refreshTokenCalledApi } from '@/utils/token';
import Vue from 'vue';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN } from '@/const/cookie';
import ncpApi, { NcpXhrError, XhrErrorCode } from 'ncp-api-supporter';
import { i18n } from '@/main';
import router from '@/router/index';
import store from '@/store/index';
import { throwLoadingSpinner } from '@/helpers/loading';
import { errorCode } from '@/utils/common';
import { ERROR_CODE } from '@/const/contents/sms';
import { AxiosRequestConfig } from 'axios';
import { getCookie } from '@/utils/cookie';

type CustomTimeoutInfo = Record<string, number>;

export const api = ncpApi.createAdminApi(
  process.env.VUE_APP_API_URL as string,
  SHOPBY_ACCESSTOKEN,
  process.env.VUE_APP_STORAGE_API_URL,
);

export const tokenApi = ncpApi.createAdminApi(
  process.env.VUE_APP_API_URL as string,
  SHOPBY_ACCESSTOKEN,
  process.env.VUE_APP_STORAGE_API_URL,
);

const convertMinutesToMilliseconds = (time: number) => 1000 * 60 * time;

let loadingCount = 0;

function addLoading(): void {
  throwLoadingSpinner(true);
  loadingCount++;
}

function closeLoading(): void {
  loadingCount--;
  if (loadingCount === 0) {
    throwLoadingSpinner(false);
  }
}

const customTimeoutInfo: CustomTimeoutInfo = {
  /*
   * key: api 엔드포인트
   * value: convertMinutesToMilliseconds의 인자로 원하는 시간 단위를 넣습니다
   * ex) 1 === 1분, 5 === 5분, 2.5 === 2분 30초,
   * */
  '/campaigns/settings': convertMinutesToMilliseconds(2),
  '/order-options/sale-summary-mall': convertMinutesToMilliseconds(5),
};

const setCustomTimeout = (config: AxiosRequestConfig, customTimeoutInfo: CustomTimeoutInfo) => {
  config.timeout = customTimeoutInfo[config.url] ?? config.timeout;
};

api.axios.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const authTokenApi = config.url.includes('auth/token');
    const adminExpire = store.getters['admin/getAdmin']?.exp * 1000;
    const hasRefreshTokenCookie = await getCookie(SHOPBY_REFRESHTOKEN);
    const hasAccessTokenCookie = await getCookie(SHOPBY_ACCESSTOKEN);
    const isExpiredServerAccessToken = new Date(adminExpire) < new Date();

    //at쿠키 & at토큰 만료됐을때 => 401 오류일때
    if ((!hasAccessTokenCookie || isExpiredServerAccessToken) && hasRefreshTokenCookie && !authTokenApi) {
      const refreshFlg = await refreshToken();
      if (refreshFlg) {
        config.headers.accessToken = await getCookie(SHOPBY_ACCESSTOKEN); //token 성공
      } else {
        this.$router.replace({ name: 'ErrorPageUnauthorized' }); //token 실패
      }
    }

    setCustomTimeout(config, customTimeoutInfo);

    if (config?.params?.dataRefresh) {
      delete config.params.dataRefresh;
    }

    addLoading();
    setCustomTimeout(config, customTimeoutInfo);

    // get api -> 수정 -> put or post api 호출을 가정
    store.commit('INIT_UNSAVED_FORM');

    // ClientLocation 값이 존재하면 권한 체크를 하게됨
    // 로그아웃 시 ClientLocation 값이 존재하면 쓰기권한을 체크하기 때문에 로그아웃 시에는 ClientLocation 값을 주지 않음
    const notUsedClientLocation = config.url === '/auth/token' && config.method === 'delete';
    if (!notUsedClientLocation) {
      config.headers.ClientLocation = window.location.origin + window.location.pathname.split('?')[0];
    }

    return config;
  },
  error => {
    closeLoading();
    return Promise.reject(error);
  },
);

api.axios.interceptors.response.use(
  response => {
    closeLoading();
    refreshTokenCalledApi();
    return response;
  },
  async (err: NcpXhrError): Promise<NcpXhrError> => {
    closeLoading();
    if (err.code === XhrErrorCode.TOKEN_ERROR) {
      //NOTE: refreshToken 401 오류
      refreshToken().then((refreshFlg: boolean): void => {
        // 스탠다드 계정인 경우에는 refreshToken 내에서 예외 처리함
        // 따라서 여기는 정상적인 처리를 한다.
        if (refreshFlg) {
          location.reload();
        } else {
          this.$router.replace({ name: 'AuthMain' });
        }
      });
    }

    if (err.code === XhrErrorCode.FORBIDDEN_ERROR) {
      const { code: errorCode } = err.data;
      // @ts-ignore
      const routerName = (router.app as any)._route.name;
      const noalertRouterNames = [
        'EmailConfig',
        'MallCreate',
        'SecurityServer',
        'ExternalService',
        'NotificationConfig',
      ];

      if (errorCode === 'AU0002') {
        window.location.href = '/pro/no-permission';
      } else if (errorCode === 'AU0003') {
        if (!noalertRouterNames.includes(routerName)) {
          alert(i18n.t('MEMBER.MAIL.NO_AUTH_ALERT'));
        }
        throw err;
      }
    }

    if (err.code === XhrErrorCode.NOTFOUND_ERROR) {
      //do nothing
    }

    if (err.code === XhrErrorCode.TIMEOUT_ERROR || err.code === XhrErrorCode.NETWORK_ERROR) {
      //do nothing
    }

    if (err.code === XhrErrorCode.SERVER_ERROR) {
      // window.location.href = '/errordata';
    }

    if (err.code === XhrErrorCode.CLIENT_ERROR) {
      const noalert = [];
      const routerName = (router.app as any)._route.name;
      if (routerName === 'Login') {
        noalert.push('99999');
      }
      if (routerName === 'AddCoupon' || routerName === 'ModifyCoupon' || routerName === 'CopyCoupon') {
        noalert.push('error.promotion.coupon.C0003');
      }

      const message = err.data.message || err.data.result.message;
      const code = err.data.code || err.data.result.code;
      const noalertRouterNames = [
        'MemberInformation',
        'MemberInformationModify',
        'MallCreate',
        'PaycoSetting',
        'EmailConfig',
        'ShippingChargeAreaFee',
        'ProductAdd',
        'ProductEdit',
        'ProductCopy',
        'ShippingChargeWarehouse',
        'Questions',
        'CollectPopup',
        'IconList',
        'AdminRegister',
        'RegisterPlusId',
        'AuthChangePassword',
        'ProductOption',
        'SetConfigurationOption',
        'OauthCodeReceiver',
      ];
      if (noalertRouterNames.includes(routerName)) {
        noalert.push(code);
      } else if (routerName === 'PgSetting' && err.data.status === 400) {
        noalert.push(code);
      } else if (routerName === 'PeriodSales' && err.data.code === 'V0004') {
        noalert.push(code);
      } else if (routerName === 'AuthMain' && err.data.code && err.data.code.includes('AU100')) {
        noalert.push(code);
      } else if (routerName === 'AdminEdit' && code && Object.values(ERROR_CODE).includes(errorCode(code))) {
        noalert.push(code);
      } else if (err.data.path === '/campaigns/settings' || err.data.path === '/campaigns/verify-domain') {
        noalert.push(code);
      } else if (err.data?.errors?.some(({ reason }) => reason.includes('Invalid IP'))) {
        noalert.push(code);
      }

      // 내 계정정보 / otp재설정 오류시 alert 두 번 출력으로 인해 추가
      else if (routerName === 'Home' && err.data.path === '/admins/otp/config') {
        noalert.push(code);
      }

      noalert.push('IS0002'); // 인스타그램 토큰 정보 유효하지 않음 에러 코드

      // 삭제된 스킨을 '사용스킨' 혹은 '작업스킨'으로 변경 시도할 때 나타나는 alert는 따로 제작
      if (routerName === 'SkinList' && err.data.code === 'S0001') {
        const splitPaths = err.data.path.split('/');
        const lastItem = splitPaths.length ? splitPaths[splitPaths.length - 1] : '';
        lastItem === 'status' && noalert.push(code);
      }

      if (message && !noalert.some((item): boolean => item === code)) {
        const errors = err.data?.errors;
        if (errors === undefined) {
          alert(message);
        } else {
          const errorMessages = errors.map(({ reason }) => reason).join('\n');
          alert(message + '\n' + errorMessages);
        }
      }
    }

    return Promise.reject(err);
  },
);
Vue.prototype.$api = api;

// NOTE:  nhn-commerce.com 으로보내는 API
const commerceApi = ncpApi.createAdminApi(process.env.VUE_APP_COMMERCE_API_URL, SHOPBY_ACCESSTOKEN);

commerceApi.axios.interceptors.request.use(
  config => {
    config.headers.accessToken = getCookie(SHOPBY_ACCESSTOKEN);

    return config;
  },
  error => Promise.reject(error),
);

Vue.prototype.$commerceApi = commerceApi;

export const cosWebApi = ncpApi.createAdminApi(process.env.VUE_APP_COS_WEB_API_URL as string, SHOPBY_ACCESSTOKEN);
Vue.prototype.$cosWebApi = cosWebApi;
