import { DefaultGridContainerOption, GridProp } from '@/types';
import {
  GetSmsGodoReservedHistoryRequest,
  GetSmsGodoSendsContent,
  GetSmsGodoSendsRequest,
  SmsGodoMode,
} from 'ncp-api-supporter';
import { changeDateFormat, getFirstAndEndDay } from '@/utils/dateFormat';
import { rowNumRenderer, warpHeaderTit } from '@/utils/grid/rendererUtils';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { ToolTipOption } from '@/types/tooltip';
import { i18n } from '@/main';
import { range1ToNI18n } from '@/utils/common';
import { i18nList } from '@/utils/i18n';
import { FormatterProps } from '@/types/tui-grid/store/column';

export const getDisplayOptions = (): DefaultGridContainerOption => {
  return {
    hasExcelDownloadButton: false,
    hasSettingButton: false,
  };
};

// SMS 발송내역 조회
const { startYmd, endYmd } = getFirstAndEndDay();

export const getSmsHistoryInitQuery = (mallNo: number): GetSmsGodoSendsRequest['params'] => {
  return {
    mallNo,
    mode: 'list',
    startDate: startYmd,
    endDate: endYmd,
    page: 1,
    pageSize: 30,
    sendResult: '',
    keyword: '',
    searchType: 'MSG',
  };
};

export const getSmsHistoryGridOption = (): GridProp<GetSmsGodoSendsContent> => {
  return {
    columns: [
      {
        header: 'MEMBER.SMS_HISTORY.TYPE',
        name: 'msgKind',
        align: 'center',
        minWidth: 80,
      },
      {
        header: 'MEMBER.SMS_HISTORY.CONTENT',
        name: 'msg',
        align: 'left',
        minWidth: 450,
        whiteSpace: 'pre-wrap',
      },
      {
        header: 'MEMBER.SMS_HISTORY.SEND_COUNT_SUCCESS_FAIL',
        name: 'sucCount',
        align: 'center',
        minWidth: 150,
        whiteSpace: 'pre-wrap',
        formatter: ({ row }: FormatterProps<GetSmsGodoSendsContent>) => {
          if (!row.failCount) return row.sucCount.toString();
          return `${row.sendCount}\n(${row.sucCount}/${row.failCount})`;
        },
      },
      {
        header: 'MEMBER.SMS_HISTORY.SEND_STATUS',
        name: 'resultStatus',
        align: 'center',
        minWidth: 150,
        formatter: ({ row }) => {
          if (row.sucCount === row.sendCount) return getAnchorHTML(i18n.t('MEMBER.SMS_HISTORY.SUCCESS'));
          return row.sucCount
            ? getAnchorHTML(i18n.t('MEMBER.SMS_HISTORY.PART_FAIL'))
            : getAnchorHTML(i18n.t('MEMBER.SMS_HISTORY.FAIL'));
        },
      },
      {
        header: 'MEMBER.SMS_HISTORY.SEND_DATE',
        name: 'sendDate',
        align: 'center',
        minWidth: 150,
      },
    ],
    header: {
      align: 'center',
      columns: [
        {
          name: 'sucCount',
          renderer: warpHeaderTit,
        },
      ],
    },
    options: {
      rowHeaders: [
        {
          type: 'rowNum',
          renderer: props => {
            return rowNumRenderer(props.value);
          },
        },
      ],
      rowHeight: 'auto',
    },
  };
};

// SMS 예약발송 관리
export const getSmsReservationHistoryInitQuery = (
  mallNo: number,
  mode: SmsGodoMode = 'list',
  period: { startDate: string; endDate: string } = { startDate: startYmd, endDate: endYmd },
): GetSmsGodoReservedHistoryRequest['params'] => {
  return {
    mallNo,
    mode,
    startDate: period.startDate,
    endDate: period.endDate,
    page: 1,
    pageSize: mode === 'list' ? 30 : 10,
    keyword: '',
    smsType: 'SMS',
    searchType: 'TOPHONE',
    smsSendKey: '',
  };
};

export const getSmsReservationHistoryGridOption = (smsType: string): GridProp => {
  return {
    columns: [
      {
        header: 'MEMBER.SMS_HISTORY.TYPE',
        name: 'type',
        align: 'center',
        minWidth: 80,
        formatter: () => smsType ?? 'SMS',
      },
      {
        header: 'MEMBER.SMS_HISTORY.CONTENT',
        name: 'msg',
        align: 'left',
        minWidth: 450,
        whiteSpace: 'pre-wrap',
      },
      {
        header: 'MEMBER.SMS_HISTORY.SEND_COUNT',
        name: 'sendCount',
        align: 'center',
        minWidth: 150,
        formatter: ({ row }) => getAnchorHTML(row.sendCount.toString()),
      },
      {
        header: 'MEMBER.SMS_HISTORY.RESERVATION_DATE',
        name: 'sendDate',
        align: 'center',
        minWidth: 150,
      },
    ],
    header: {
      align: 'center',
    },
    options: {
      rowHeaders: [
        {
          type: 'rowNum',
          renderer: props => {
            return rowNumRenderer(props.value);
          },
        },
      ],
    },
  };
};

// SMS/LMS 예약발송 내역 상세보기
export const getSmsReservationDetailToolTipOption = (): ToolTipOption => {
  return {
    name: 'List',
    style: { width: '500px' },
    listOption: {
      mainTitle: i18n.t('MEMBER.SMS_RESERVATION.TOOLTIP_TITLE').toString(),
      mainMessage: i18nList(range1ToNI18n(4, 'MEMBER.SMS_RESERVATION.TOOLTIP_MESSAGE_')),
      type: 'close',
    },
  };
};

export const getSmsReservationDetailGridOption = (): GridProp => {
  return {
    options: {
      rowHeaders: [
        { type: 'checkbox', width: 86 },
        {
          type: 'rowNum',
          width: 172,
          renderer: props => {
            return rowNumRenderer(props.value, 10);
          },
        },
      ],
      bodyHeight: 280,
      pageOptions: {
        perPage: 10,
      },
    },
    columns: [
      {
        header: 'MEMBER.KAKAO.RECIPIENT_NO',
        name: 'phoneNo',
        align: 'center',
        minWidth: 291,
        formatter: ({ value }) => value.toString(),
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'MEMBER.SMS_HISTORY.RESERVATION_DATE',
        name: 'sendDate',
        align: 'center',
        minWidth: 291,
      },
    ],
    header: {
      align: 'center',
    },
    displayOptions: {
      showsRightArea: false,
    },
  };
};

// 080 수신번호 관리
export const getReject080ListGridOption = (): GridProp => {
  return {
    columns: [
      {
        header: 'MEMBER.REJECT080.REJECT_NUMBER',
        name: 'unSubscribePhoneNo',
        align: 'center',
        minWidth: 80,
        formatter: ({ value }) => {
          return value?.toString()?.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
        },
        copyOptions: {
          useFormattedValue: true,
        },
      },
      {
        header: 'MEMBER.REJECT080.REGISTER_DATE',
        name: 'registerDateTime',
        align: 'center',
        minWidth: 150,
        formatter: ({ value }) => changeDateFormat(value.toString(), 'YYYY-MM-DD HH:mm'),
      },
    ],
    header: {
      align: 'center',
    },
    options: {
      rowHeaders: [
        {
          type: 'rowNum',
          renderer: props => {
            return rowNumRenderer(props.value);
          },
        },
      ],
    },
  };
};

export const ERROR_CODE = {
  INVALID_NUMBER: 'E0301',
  EXCEEDED_ATTEMPTS: 'E0300',
};
