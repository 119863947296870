export const getChatbotConfig = (sno, shopUrl, tempShopUrl, userId) => {
  return `
    var ic3_config = function() {
        this.page.bot.repoId = 'IC'; // {required} infochatter3 저장소 아이디
        this.page.bot.agentName = "Shopby-Pro"
        this.page.bot.agentId = "138004"
        this.page.bot.name = "NHN커머스 커밋"
        this.page.ca.caRepoId = 'sh1a24c7d9by'; // {required} 채팅상담솔루션 연계 아이디
        this.page.ca.caUrl = 'https://pickbot.ai/chatassist'; // {required} 채팅상담솔루션 API 주소
        this.page.chatUIUrl = 'https://pickbot.ai/shopby/chatbot'; //{required} 인포채터3 챗봇 URL (테마 및 chatUI 연계)
        this.page.restAPI = 'https://pickbot.ai/shopby/restapi'; //{required} 인포채터3 Rest API 주소 (자동완성 / 의견)
        this.page.shop.shopId = '${sno}'; //{option} NHN커머스 Shop ID
        this.page.shop.shopUrl = '${shopUrl}'; //{option} NHN커머스 Shop URL (main)
        this.page.shop.tempShopUrl = '${tempShopUrl}'; //{option} NHN커머스 Shop URL (sub)
        this.page.user.userId = '${userId}'; // {required} user id
        this.page.setting.width = '490'; //{option}  챗봇UI의 가로 사이즈
        this.page.setting.height = '850'; //{option} 챗봇 UI의 세로 사이즈
        this.page.bot.categoryName = '공통'; //{option} FAQ 카테고리 (인포채터 FAQ 에서 사용할 카테고리명 이 값을 안넣고 사용하면 "공통" 카테고리가 기본 적용된다.
        this.page.deployId = '{jdODPBy1UeZxcxnjVuaUfQ==}y9YXx0wl8BJcYGa76qhV9/WXC/MMcpRmY50RMdvHFPs='; // 배포아이디
        this.page.launcher.size = 50;
        this.page.launcher.style = [
          {
            className: 'ic-embed-launcher',
            attribute: {
              right: '31px !important'
            }
          },
          {
            className: 'ic-embed-open-icon',
            attribute: {
              right: '31px !important'
            }
          }
        ]
      };
    `;
};

export const chatbotEmbedSrc = 'https://pickbot.ai/shopby/chatbot/embed-chatbot/embed.js';
export const chatbotEmbedSrcId = 'ic3_data3';
export const chatbotConfigScriptId = 'ic3_data2';
