
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class IssueStopReason extends Vue {
  @Prop() private data;
  @Prop() private onNegativeClick!: Function;

  private get showChangeLabel() {
    const labels = [];
    if (this.data.isCountOver) {
      labels.push(this.$t('PROMOTION.COUPON.ISSUE_LIMIT_CNT') as string);
    }
    if (this.data.isPeriodEnd) {
      labels.push(this.$t('PROMOTION.COUPON.TERM_ISSUE_END_YMD') as string);
    }
    if (this.data.issueStopped) {
      labels.push(this.$t('PROMOTION.COUPON.STATUS_TYPE') as string);
    }

    return labels.join('/');
  }
}
