




























































import { Component } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import { i18nAllObject } from '@/utils/i18n';
import { readExcelFile, writeExcelFile } from '@/utils/webExcel';
import { ParsedObject } from '@/utils/fileReader';
import { GetProductsExcelSampleUrlRequest } from 'ncp-api-supporter';
import { i18nModifySalePrice, KEY_MAP_JSON } from '@/views/popups/product/ModifySalePrice';
import { getStorageFile } from '@/utils/storage';
import { getToday } from '@/utils/dateFormat';
const url = require('url');
import moment from 'moment';

@Component({
  components: { FileInput, NoticeBox },
})
export default class ModifySalePrice extends WindowPopupMainVue {
  private isFailure = false;
  private failureData = null;
  private failureFileName = `${this.$t('PRODUCT.PRODUCTLIST.EXCEL_RESULT_FILE_NAME')}_${getToday(
    'YYYYMMDD HH:mm:ss',
  )}.xlsx`;
  private readonly noticeList = this.$t('PRODUCT.PRODUCTLIST.MODIFY_PRICE_NOTICE')
    .toString()
    .trim()
    .split('\n');

  private uploadedExcelFileName = '';
  private extensionValidOption = {
    validType: 'excel',
    alertMsg: 'PRODUCT.PRODUCTLIST.ALERT_DISALLOW_EXTENSION',
  };
  private uploadedExcelFile: File | null = null;

  private uploadExcelFile(file: File): void {
    this.uploadedExcelFileName = file.name;
    this.uploadedExcelFile = file;
  }

  private removeFile(): void {
    this.uploadedExcelFile = null;
    this.uploadedExcelFileName = '';
  }

  private async onClickSampleDown() {
    const request: GetProductsExcelSampleUrlRequest = {
      params: {
        sampleType: 'PRICE_DISCOUNT',
      },
    };
    try {
      const { data } = await this.$api.getProductsExcelSampleUrl(request);
      const {
        query: { fileName, container },
      } = url.parse(data.sampleUrl, true);
      const excelFileName = `${this.$t('PRODUCT.PRODUCTLIST.EXCEL_SAMPLE_FILE_NAME')}_${getToday('YYYYMMDD')}.xlsx`;
      await getStorageFile(fileName, container, excelFileName);
    } catch (e) {
      console.error(e);
    }
  }

  private onClickSave() {
    if (!this.uploadedExcelFileName) return alert(this.$t('COMMON.EXCEL.NO_EXCEL_ERROR'));
    const columns = i18nAllObject(this, KEY_MAP_JSON);
    readExcelFile(this.uploadedExcelFile, columns, this.convertExcelData);
  }

  private convertExcelData(excelData: ParsedObject[]): void {
    if (excelData.length > 500) {
      alert(i18nModifySalePrice('UPLOAD_MAX_LENGTH'));
      return;
    }

    // 첫번째 row 의 productNo 기준으로 유효한지 판단한다.
    const firstRow = excelData.find((row, i) => i === 0);
    if (
      firstRow &&
      isNaN(parseInt(firstRow.productNo.toString(), 10)) &&
      !firstRow.salePrice &&
      !firstRow.immediateDiscountValue
    ) {
      alert(i18nModifySalePrice('ALERT_DISALLOW_EXTENSION'));
      return;
    }

    const dupMallProductNo = [];
    const excelDataGroup = this.groupBy(excelData, 'productNo');
    for (const prop in excelDataGroup) {
      if (Object.prototype.hasOwnProperty.call(excelDataGroup, prop)) {
        if (excelDataGroup[prop].length > 1) {
          dupMallProductNo.push(prop);
        }
      }
    }

    const data: any = excelData
      .filter((row, i) => i >= 0)
      .map(row => {
        const startYmdt = row.immediateDiscountStartDateTime;
        const endYmdt = row.immediateDiscountEndDateTime;

        if ((row.productNo && !this.isPositiveInteger(row.productNo)) || row.productNo > 2147483647) {
          // 상품번호 정수가 아닐 경우
          row['result'] = i18nModifySalePrice('MSFAILURE');
          return row;
        }

        // 판매가가 정수가 아닐 경우
        if (row.salePrice && !this.isPositiveInteger(row.salePrice)) {
          row['result'] = i18nModifySalePrice('MSFAILURE');
          return row;
        }

        if (dupMallProductNo.includes(row.productNo)) {
          row['result'] = i18nModifySalePrice('MSFAILURE');
          return row;
        }

        // 즉시할인가 숫자 확인
        if (row.immediateDiscountValue && !this.isPositiveInteger(row.immediateDiscountValue)) {
          row['result'] = i18nModifySalePrice('MSFAILURE');
          return row;
        }
        // 즉시할인 타입 확인
        if (row.immediateDiscountUnitType && !this.rangeNumeric(row.immediateDiscountUnitType, 1, 2)) {
          row['result'] = i18nModifySalePrice('MSFAILURE');
          return row;
        }
        // 시작일 종료일 중에 하나만 있는 경우
        if ((!startYmdt && endYmdt) || (startYmdt && !endYmdt)) {
          row['result'] = i18nModifySalePrice('MSFAILURE');
          return row;
        }
        if (startYmdt && endYmdt) {
          if (!moment(startYmdt, 'YYYYMMDDHH', true).isValid() || !moment(endYmdt, 'YYYYMMDDHH', true).isValid()) {
            row['result'] = i18nModifySalePrice('MSFAILURE');
            return row;
          }
        }
        return row;
      })
      .filter(row => {
        // 사전 데이터 검증에서 실패인 경우
        if (row.result === i18nModifySalePrice('MSFAILURE')) {
          return false;
        }
        return true;
      })
      .map(row => {
        let startYmdt = row.immediateDiscountStartDateTime as string;
        let endYmdt = row.immediateDiscountEndDateTime as string;

        if (startYmdt + ''.length && endYmdt + ''.length) {
          startYmdt = moment(startYmdt, 'YYYYMMDDHH')
            .set({ minute: 0, second: 0, millisecond: 0 })
            .format('YYYY-MM-DD HH:mm:ss');
          endYmdt = moment(endYmdt, 'YYYYMMDDHH')
            .set({ minute: 59, second: 59, millisecond: 0 })
            .format('YYYY-MM-DD HH:mm:ss');
        }

        let immediateDiscountUnitType = null;
        if (row.immediateDiscountUnitType + '' === '1') {
          immediateDiscountUnitType = 'AMOUNT';
        } else if (row.immediateDiscountUnitType + '' === '2') {
          immediateDiscountUnitType = 'PERCENT';
        }

        const rowData = {
          productNo: row.productNo,
          salePrice: row.salePrice ? parseInt(row.salePrice.toString().replace(/[^0-9]/g, ''), 10) : null,
          immediateDiscountValue: row.immediateDiscountValue
            ? parseInt(row.immediateDiscountValue.toString().replace(/[^0-9]/g, ''), 10)
            : null,
          immediateDiscountUnitType: immediateDiscountUnitType,
          immediateDiscountStartDateTime: startYmdt,
          immediateDiscountEndDateTime: endYmdt,
        };

        return rowData;
      })
      .filter((row: any) => {
        // 처리할 데이터가 있는지 확인한다.
        if (
          !row.productNo &&
          !row.salePrice &&
          !row.immediateDiscountValue &&
          !row.immediateDiscountStartDateTime &&
          !row.immediateDiscountEndDateTime
        ) {
          return false;
        }

        // 사전 데이터 검증에서 실패인 경우
        if (row.result === i18nModifySalePrice('MSFAILURE')) {
          return false;
        }
        return true;
      });

    // 일괄 업로드 서버 요청 전 데이터 확인
    if (data.length === 0) {
      const failureCount = excelData.filter(row => row.result === i18nModifySalePrice('MSFAILURE')).length;
      if (failureCount > 0) {
        // 서버 전송하기 전 검증에서 이미 유효한 데이터가 없는 경우MD_KEY MSFAILURE MSSUCCESS
        alert(this.$t('PRODUCT.PRODUCTLIST.RESULT_MESSAGE', [0, failureCount]));
        this.isFailure = true;
        this.failureData = excelData;
      } else {
        alert(i18nModifySalePrice('MSUPLOAD_FAILED_NO_INPUT_VALUE'));
      }
      return;
    }

    this.$api.putProductsPrice({ data }).then(({ data: { failures, successNos } }: any) => {
      this.resultExcel(failures, successNos, excelData);
    });
  }

  private writeExcel(data) {
    const keyMapJson: any = KEY_MAP_JSON;
    keyMapJson.result = 'PRODUCT.PRODUCTLIST.MSFAILURE';
    const columns = i18nAllObject(this, keyMapJson);
    writeExcelFile(data, columns, this.failureFileName, true);
  }

  private resultExcel(failures, successNos, originData) {
    let resultArr = [];
    successNos.forEach(o => {
      resultArr.push({ productNo: o, result: i18nModifySalePrice('MSSUCCESS') });
    });

    if (failures.length) {
      resultArr = resultArr.concat(
        failures.map((item: any): any => {
          return { productNo: item.mallProductNo, result: i18nModifySalePrice('MSFAILURE') };
        }),
      );
    }

    const resultArrGroup = this.groupBy(resultArr, 'productNo');
    for (let i = 0; i < originData.length; i++) {
      const rd = originData[i];
      const productNo = rd.productNo;
      if (resultArrGroup[productNo] != null) {
        Object.assign(originData[i], { result: resultArrGroup[productNo][0].result });
      } else {
        originData[i]['result'] = i18nModifySalePrice('MSFAILURE');
      }
    }

    const successCount = successNos.length;
    const originDataFailureCount = originData.filter(row => row.result === i18nModifySalePrice('MSFAILURE')).length;
    alert(this.$t('PRODUCT.PRODUCTLIST.RESULT_MESSAGE', [successCount, originDataFailureCount]));
    this.isFailure = true;
    this.failureData = originData;
  }

  private groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  private isPositiveInteger(str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  }

  private rangeNumeric(n, min, max) {
    if (this.isNumeric(n) && this.isNumeric(min) && this.isNumeric(max)) {
      return n >= min && n <= max;
    }
    return false;
  }
  private isNumeric(n) {
    return !isNaN(n);
  }

  private downloadResult() {
    this.writeExcel(this.failureData);
  }
}
