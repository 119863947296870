export default Object.freeze({
  TAB_1: '1:1문의 관리',
  TAB_2: '문의유형/담당자 관리',

  UNANSWER: '미답변',
  NOADMIN: '담당자 미지정',
  UNANSWER_SELF: '담당 미답변',
  INQUIRY_DATE: '문의일시',
  TYPE_ADMIN: '문의유형/담당자',
  PROCESSING_STATUS: '처리상태',
  COMPCHECKBOX_1: '전체선택',
  COMPCHECKBOX_2: '답변대기',
  COMPCHECKBOX_3: '진행중',
  COMPCHECKBOX_4: '답변완료',

  COMPCHECKBOX_5: '담당자미지정',

  SEARCHTYPE_ALL: '통합검색',
  SEARCHTYPE_1: '문의번호',
  SEARCHTYPE_2: '문의제목',
  SEARCHTYPE_3: '문의자',
  SEARCHTYPE_4: '담당자명',

  BTN_MODIFYINQUIRYTYPE: '문의유형/담당자 변경',

  INQUIRYTYPES_ALL: '문의유형',
  ADMINNO_ALL: '담당자',

  ITEMTABLE_TH1: '쇼핑몰',
  ITEMTABLE_TH2: '문의일시',
  ITEMTABLE_TH3: '문의번호',
  ITEMTABLE_TH4: '문의제목',
  ITEMTABLE_TH5: '문의자',
  ITEMTABLE_TH6: '문의유형',
  ITEMTABLE_TH7: '담당자',
  ITEMTABLE_TH8: '처리상태',
  ITEMTABLE_TH9: '답변일시',
  ITEMTABLE_TH7_DBDELETE: '삭제된 운영자',
  ITEMTABLE_TH7_UNASSIGNEED: '담당자 미지정',

  WARN_NOROWS: '문의를 선택해주세요.',
  WARN_NCP: '네이버페이문의와 일반 문의를 동시에 수정할 수 없습니다.',
  WARN_MALL: '문의유형/담당자 변경은 동일한 쇼핑몰의 문의만 일괄 변경이 가능합니다.',

  POP_TYPEANDADMIN_TIT: '문의유형/담당자 변경',
  POPTABLE_TH2: '변경 대상',
  POPTABLE_TH5: '변경사유',
  POP_PLACEHOLDER: '사유를 입력하세요',
  POP_TH_2_NER: '네이버페이문의',

  POP_WARN_NOTICE_BOX: '답변완료 상태의 문의는 유형 및 담당자 변경이 불가합니다.',
  POP_WARN_NOTYPE: '문의유형을 선택해주세요.',
  POP_WARN_NOADMIN: '담당자를 선택해주세요.',
  POP_WARN_NOREASON: '사유를 입력해주세요.',
  POP_CONFIRM: '문의유형 및 담당자를 변경하시겠습니까?',
  POP_MODIFY: '문의유형/담당자',

  PUT_INQUIRIES_FAIL: '실패',
  PUT_INQUIRIES_SUCCESS: '성공',
  PUT_INQUIRIES_SUCCESS_MESSAGE: '문의유형/담당자 변경완료',

  TITLE: '문의유형 설정',
  TYPE_BTN_ADD: '문의유형 추가',
  TYPE_BTN_MINUS: '삭제',
  DETAIL_TITLE: '문의유형 상세설정',
  TH_1: '문의유형명',
  TH_2: '문의유형 설명',
  PLACEHOLDER_NAME: '문의유형명 입력',
  PLACEHOLDER_DESCRIPTION: '문의유형 설명 입력',
  DETAIL_CONTACTPERSON: '담당자',
  DETAIL_DEL: '삭제',
  ADD_CONTACTPERSON: '추가',

  NEW_TYPE_NAME: '새 문의유형',

  RESULT_NONE_DATA: '검색 결과가 없습니다.',

  WARN_LENGTH: '선택된 문의유형이 없습니다.',
  WARN_TYPE_LENGTH: '문의유형은 최대 50개까지만 등록 가능합니다.',
  WARN_TYPECHANNEL: '네이버페이문의 유형은 삭제할 수 없습니다.',
  WARN_COUNT: '1:1문의 등록이 있어 문의유형을 삭제할 수 없습니다.',
  WARN_NO_TYPE: '쇼핑몰 별 문의유형 1개는 필수입니다.',
  CONFIRM_DEL: '문의유형을 삭제하시겠습니까?',
  WARN_TYPENAME: '문의유형명을 작성해주세요.',
  WARN_ADMINS: '담당자를 설정해주세요.',
  CONFIRM_SAVE: '변경된 내용을 저장하시겠습니까?',
  WARM_TYPE_DEL_COUNT: '{inquiryName}에 답변대기/진행중 상태의 문의가 있어 삭제할 수 없습니다.',
  SUCCESS_MOD: '변경사항이 저장 되었습니다.',

  WARN_SAME_ADMIN: '이미 선택한 운영자 입니다.',
  WARN_ADMIN_OVER100: '담당자는 문의유형별로 최대 100명까지 설정 가능합니다.',
  CONFIRM_LEAVE: '페이지 이동 시 변경사항이 저장되지 않을 수 있습니다.',
  WARN_FIRST_SELECT_MALL: '쇼핑몰을 먼저 선택해주세요. ',
});
