
























































import { Vue, Component } from 'vue-property-decorator';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { getAuthorityGroupSelectType } from '@/const/contents/configuration/authorityGroup';
import TextInput from '@/components/common/input/TextInput.vue';

interface AuthorityGroupQuery {
  name?: string;
  allowsMarketingDirection?: boolean | '';
  permitsPrivateInformation?: boolean | '';
  smsReceived?: boolean | '';
}

@Component({ components: { SearchButton, SelectBox, TextInput } })
export default class AuthorityGroup extends Vue {
  private query: AuthorityGroupQuery = {
    name: '',
    allowsMarketingDirection: '',
    permitsPrivateInformation: '',
    smsReceived: '',
  };

  private selectType = getAuthorityGroupSelectType();

  created() {
    Object.assign(this.query, this.$route.query);
  }

  private reset(): void {
    this.query = {
      name: '',
      allowsMarketingDirection: '',
      permitsPrivateInformation: '',
      smsReceived: '',
    };
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }
}
