export default Object.freeze({
  DELIVERY_TITLE: '묶음배송 일괄처리',
  DELIVERY_HEADER_NOTICE1:
    '묶음배송이 가능한 조건은 아래와 같으며, 세부 정보가 다를 경우 "더블 클릭"하여 정보를 변경할 수 있습니다.',
  DELIVERY_HEADER_NOTICE2: '(※ 묶음배송되는 주문의 배송비는 총합으로 표기되며, 클레임 시 개별 처리됩니다.)',
  DELIVERY_NOTICE_1: '1. 묶음배송 그룹이 같은 주문',
  DELIVERY_NOTICE_2: '2. 수령자명/수령자휴대전화/배송지주소/개인통관고유부호 정보가 모두 동일한 주문',
  DELIVERY_NOTICE_3:
    '단, 지번/도로명으로 각각 다르게 입력된 경우 실제주소지가 동일하더라도 묶음배송 처리가 불가능합니다.',
  DELIVERY_NOTICE_4: '3. WMS를 사용하지 않는 쇼핑몰의 주문',
  DELIVERY_VALIDATION_ALERT: '묶음배송을 위해 배송번호와 기본주소를 확인해 주세요.',
  DELETE_WARNING: '삭제된 주문 정보는 복구가 불가하며 영구 삭제됩니다.',

  // OrderSpecificationPopup
  SPECIFICATION_PRINT_BTN: '출력',
  SPECIFICATION_PREV: '이전',
  SPECIFICATION_NEXT: '다음',
  SPECIFICATION_NOTICE_HEAD:
    '거래명세서는 개인정보에 유의하여 관리해주시고, 불필요한 거래명세서는 파기해주세요.거래명세서로 인해 발생되는 책임은 해당 업체에 있습니다.',
  PRINT_SPECIFICATION_ORDER_BY_SHIPPING_NO: "거래명세서는 '배송번호' 기준으로 출력됩니다.",
  SPECIFICATION_PAGE_TIT: '거래명세서',
  SPECIFICATION_THFILL: '공급자',
  SPECIFICATION_FORM_ONE: '사업자등록번호',
  SPECIFICATION_JUSTIFY_ONE: '회사명',
  SPECIFICATION_JUSTIFY_TWO: '대표자',
  SPECIFICATION_JUSTIFY_THREE: '주소',
  SPECIFICATION_JUSTIFY_FOUR: '업태',
  SPECIFICATION_JUSTIFY_FIVE: '종목',
  SPECIFICATION_JUSTIFY_SIX: '전화번호',
  SPECIFICATION_JUSTIFY_SEVEN: '주문메모',
  SPECIFICATION_2_JUSTIFY_ONE: '작성일자',
  SPECIFICATION_2_THFILL: '수령자/주문자',
  SPECIFICATION_2_JUSTIFY_TWO: '배송번호',
  SPECIFICATION_2_JUSTIFY_THREE: '수령자명',
  SPECIFICATION_2_JUSTIFY_FOUR: '주문자명',
  SPECIFICATION_2_JUSTIFY_FIVE: '수령자 주소',
  SPECIFICATION_CONTACT1: '수령자 연락처1',
  SPECIFICATION_CONTACT2: '수령자 연락처2',
  SPECIFICATION_2_JUSTIFY_SIX: '배송메모',
  SPECIFICATION_TABLE_ONE: 'SEQ.',
  SPECIFICATION_TABLE_TWO: '주문번호',
  SPECIFICATION_TABLE_THREE: '상품코드',
  SPECIFICATION_TABLE_FOUR: '상품명',
  SPECIFICATION_TABLE_FIVE: '단가',
  SPECIFICATION_TABLE_SIX: '수량',
  SPECIFICATION_TABLE_SEVEN: '금액',
  SPECIFICATION_TABLE_NODATA: '==이하여백==',
  SPECIFICATION_TABLE_TOTAL: '합계',
  SPECIFICATION_CANCEL_BTN: '닫기',

  // DeliveryInvoiceForm
  INVOICE_TITLE: '택배사 출고양식 설정',
  INVOICE_TITLE1: '택배사 반품양식 설정',
  INVOICE_SELECT_TITLE: '택배사',
  INVOICE_SELECT_CJ: 'CJ대한통운',
  INVOICE_ADD_BTN: '항목추가',
  INVOICE_INIT_BTN: '순서초기화',
  INVOICE_SAVE_BTN: '저장',
  INVOICE_CLOSE_BTN: '닫기',
  INVOICE_TABLE_HEADER_LABEL: '항목명',
  INVOICE_TABLE_HEADER_TWO: '순서',
  INVOICE_TABLE_HEADER_LABEL_INPUT: '항목값 일괄 입력',
  INVOICE_TABLE_HEADER_DELETE: '삭제',
  INVOICE_SAVE_CHECK: '항목명을 입력해주세요.',
  INVOICE_SAVE_SUCCESS: '저장 되었습니다.',
  INVOICE_CLOSE_MESSAGE: '설정값이 저장되지 않았습니다. 저장하지 않고 팝업창을 닫으시겠습니까?',

  PLACEHOLDER_VALUE_15: '15자까지 입력가능',
  PLACEHOLDER_VALUE_10: '10자까지 입력가능',

  TOOLTIP_DEFAULT_FORMAT: '기본 항목의 항목값에는 해당하는 배송정보 값이 자동 출력됩니다.',
  TOOLTIP_ADDITIONAL_LABEL: '추가 항목의 항목값은 직접 입력한 값으로 일괄 출력됩니다.',
  TOOLTIP_NONE_LABEL: '추가 항목의 항목값에 아무것도 입력하지 않으면 공란으로 일괄 출력됩니다.',
});
