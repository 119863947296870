import { api } from './../../../api/index';
import {
  ConfigurationsCampaignsStatusType,
  GetConfigurationsCampaignsCampaignNo,
  GetConfigurationsCampaigns,
} from 'ncp-api-supporter';

export const getCampaignsRequirements = async (mallNo: number) => {
  const request = {
    params: {
      mallNo: mallNo,
    },
  };

  const { data } = await api.getConfigurationsCampaignsRequirements(request);
  const isAllTrue = Object.values(data).every(value => value === true);

  return {
    isAllTrue,
    data,
  };
};

export const getGoogleCheckRequireOption = () => [
  {
    label: window.$t('MARKETING.CONFIGURATION.MERCHANT_USER_REGIST'),
    key: 'hasMerchantAccounts',
    type: 'merchantRegist',
  },
  {
    label: window.$t('MARKETING.CONFIGURATION.HAS_APPROVED_PRODUCT'),
    key: 'hasApprovedProduct',
    tooltip: window.$t('MARKETING.CONFIGURATION.GOOGLE_CONFIG_POPUP_TOOLTIP1'),
    route: '/marketing/google-ad-config/product-manage',
  },
  {
    label: window.$t('MARKETING.CONFIGURATION.ADS_USER_REGIST'),
    key: 'hasAdsAccounts',
    type: 'adUserRegist',
  },
  {
    label: window.$t('MARKETING.CONFIGURATION.ADS_PAYMENT_REGIST'),
    url:
      'https://ads.google.com/aw/billing/billingsetups?ocid=378793410&euid=409025694&__u=9127484206&uscid=378793410&__c=1005655090&authuser=0',
    key: 'hasBillingPayment',
  },
];

export const configureCheckNoticeList = () => [
  window.$t('MARKETING.CONFIGURATION.GOOGLE_COFIGURE_NOTICE1'),
  window.$t('MARKETING.CONFIGURATION.GOOGLE_COFIGURE_NOTICE2'),
];

export const adRegistNoticeList = () => [
  window.$t('MARKETING.CONFIGURATION.GOOGLE_AD_REGIST__NOTICE1'),
  window.$t('MARKETING.CONFIGURATION.GOOGLE_AD_REGIST__NOTICE2'),
  window.$t('MARKETING.CONFIGURATION.GOOGLE_AD_REGIST__NOTICE3'),
  window.$t('MARKETING.CONFIGURATION.GOOGLE_AD_REGIST__NOTICE4'),
  window.$t('MARKETING.CONFIGURATION.GOOGLE_AD_REGIST__NOTICE5'),
  window.$t('MARKETING.CONFIGURATION.GOOGLE_AD_REGIST__NOTICE6'),
];

export const biddingStrategyType = [
  {
    value: 'CPA',
    name: 'MARKETING.CONFIGURATION.CPA',
  },
  {
    value: 'ROAS',
    name: 'MARKETING.CONFIGURATION.ROAS',
  },
];

export const adDataInit: GetConfigurationsCampaignsCampaignNo = {
  campaignNo: 0,
  id: 0,
  name: '',
  status: 'ENABLED',
  budget: {
    budgetId: 0,
    totalBudget: 0,
    dailyBudget: null,
  },
  biddingStrategy: {
    type: 'CPA',
    cpaCost: null,
    roasRate: null,
  },
};

export const getConfigurationsCampaigns = async (mallNo): Promise<GetConfigurationsCampaigns[] | null> => {
  const campaignsReq = {
    params: {
      mallNo: mallNo,
    },
  };

  const { data } = await api.getConfigurationsCampaigns(campaignsReq);

  if (data.length === 0) {
    return null;
  } else {
    return data;
  }
};

export const getConfigCampaigns = async (mallNo: number): Promise<GetConfigurationsCampaignsCampaignNo | null> => {
  const campaignsData = await getConfigurationsCampaigns(mallNo);

  if (!campaignsData) return null;

  const campaignNo = campaignsData[0].campaignNo;

  const campaignReq = {
    pathParams: {
      campaignNo: String(campaignNo),
    },
    params: {
      mallNo: mallNo,
    },
  };

  const { data } = await api.getConfigurationsCampaignsCampaignNo(campaignReq);
  return data;
};

const setCampaignStatus = async (status: string, mallNo: number, campaignNo: number) => {
  if (confirm(window.$t(`MARKETING.CONFIGURATION.AD_${status}_ALERT`) as string)) {
    const request = {
      data: {
        mallNo: mallNo,
        campaignNo: campaignNo,
        campaignStatus: status as ConfigurationsCampaignsStatusType,
      },
    };

    try {
      await api.putConfigurationsCampaignsStatus(request);
      alert(window.$t(`MARKETING.CONFIGURATION.AD_${status}_ALERT_COMPLETE`));
    } catch (e) {
      alert(window.$t(`MARKETING.CONFIGURATION.FAIL_GOOGLE_CONFIG`));
      console.error(e);
    }
  } else {
    return;
  }
};

export const changeCampaignStatus = async (type: string, mallNo: number, campaignNo: number) => {
  let status = '';

  if (type === 'nowPaused') status = 'ENABLED';
  if (type === 'nowEnabled') status = 'PAUSED';

  await setCampaignStatus(status, mallNo, campaignNo);
};
