




































import { Component, Prop, Vue } from 'vue-property-decorator';

// 취소리스트 p.21
@Component
export default class RemoveOrderMessage extends Vue {
  @Prop() private readonly data: { listType: 'ORDER' | 'CLAIM'; checkedClaimCount: number };
  @Prop() private onPositiveClick!: () => void;
  @Prop() private onNegativeClick!: () => void;

  private get isClaimList(): boolean {
    return this.data.listType === 'CLAIM';
  }
}
