














import { Component, Vue, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

// default header 일 시 라인수 줄이고 싶어서 만듬
@Component
export default class ModalHeader extends Vue {
  @Prop({ required: true, default: '' }) title!: TranslateResult;
  @Prop({ required: false }) onClickCloseHook!: Function;
}
