export default Object.freeze({
  //CLAIM_EXCHANGEPRODUCT
  PAGETITLE: '교환출고상품 선택',
  SEARCHTYPE: '검색어',
  KEYWORD: "복수 상품 검색이 가능합니다. (Enter 또는 ','로 구분)",
  MALLPRODUCTNO: '상품번호',
  PRODUCTNAME: '상품명',
  SELECTMALL: '쇼핑몰',
  CATEGORYTYPE: '전시카테고리',
  PARTNERNAME: '파트너',
  PARTNER_SEARCH: '브랜드 검색',
  ADDITIONALDISCOUNT: '추가할인여부',
  ADDITIONALDISCOUNT_ALL: '전체',
  ADDITIONALDISCOUNT_Y: '추가할인 있음',
  ADDITIONALDISCOUNT_N: '추가할인 없음',
  ALERT_MESSAGE: '추가하실 상품을 선택해주세요',
  ADDSELECTEDBTN: '선택상품 추가',
  CLOSE: '닫기',
  MALLNAME: '쇼핑몰',
  SALEMETHODTYPE: '판매방식',
  PARTNERNM: '파트너명',
  ADMINNAME: '담당자',
  COMMISSIONRATE: '수수료',
  APPLIEDADDITIONALDISCOUNTPRICE: '판매가 할인적용가',
  ADDITIONALDISCOUNTAMOUNT: '추가할인',
  APPLYDISCOUNTPRICE: '추가할인 적용가',
});
