export default Object.freeze({
  // list
  COUPON_NAME: '쿠폰명',
  COUPON_NO: '쿠폰번호',
  TERM_REGISTER_YMD: '쿠폰 등록일',
  TERM_ISSUE_START_YMD: '발급 시작일',
  TERM_ISSUE_END_YMD: '발급 종료일',
  COUPON_TYPE: '혜택구분',
  COUPON_TYPE_PRODUCT: '상품적용 쿠폰',
  COUPON_TYPE_CART: '주문적용 쿠폰',
  ISSUE_TYPE: '발급유형',
  ISSUE_TYPE_DOWNLOAD: '다운로드 발급',
  ISSUE_TYPE_CODE: '코드 발급',
  ISSUE_TYPE_CODE_DESIGNATE: '코드 발급(직접 입력)',
  ISSUE_TYPE_CODE_RANDOM: '코드 발급(자동 생성)',
  ISSUE_TYPE_INSERT: '자동 발급',
  STATUS_TYPE: '발급상태',
  STATUS_TYPE_ISSUE_READY: '발급대기',
  STATUS_TYPE_ISSUE_ING: '발급중',
  STATUS_TYPE_ISSUE_STOP: '발급중지',
  STATUS_TYPE_ISSUE_END: '발급종료',
  ADD_COUPON: '쿠폰 등록',
  SELECT_PAYMENT_COUPON: '지급 쿠폰 선택',
  DISCOUNT_AMT: '할인금액',
  DISCOUNT_AMT_2: '쿠폰 할인금액',
  NO_LIMIT: '제한없음',
  ISSUANCE_STATUS: '발급현황',
  TOTAL_ISSUE_CNT_BY_ADMIN: '운영자 발급현황',
  TOTAL_USE_CNT: '사용현황',
  CHEDULED_ISSUE_PLAN: '예약지급대기',
  ISSUE_START_YMDT: '발급시작일',
  ISSUE_END_YMDT: '발급종료일',
  REGISTER_ADMIN: '등록자',
  REGISTER_YMD: '등록일',
  UPDATE_ADMIN: '최종 수정자',
  UPDATE_YMD: '최종 수정일',
  ISSUANCE_TIP_1: '· 발급현황이 ‘일반 발급수 / 쿠폰의 총 발급수량’로 표기됩니다.',
  ISSUANCE_TIP_2:
    '· 쿠폰에서 설정한 발급방식에 따라 발급된 건을 ‘일반 발급’, 운영자가 회원을 직접 선택하여 발급된 건을 ‘운영자 발급’으로 구분합니다.',
  ISSUANCE_TIP_3:
    '· 운영자 발급은 쿠폰의 발급수량 제한에 영향을 받지 않고 발급 가능하며, 운영자 발급수는 ‘운영자 발급현황‘ 항목에서 확인하실 수 있습니다.',
  COUPON_PAYMENTS: '지급 예정 쿠폰',
  NOT_SELECTED_COUPON: '선택된 쿠폰이 없습니다.',
  SET_PAYMENT_TERMS: '지급 조건 설정',
  MEMBER_TARGET: '대상 회원',
  MEMBER_INQUIRY: '회원 조회',
  EXCEL_BATCH_REGISTRATION: '엑셀 일괄등록',
  SELECTED_MEMBERS: '회원이 {0}명 선택되었습니다.',
  MEMBERS_TIPS:
    '선택 후 대상 회원 중 일부가 휴면회원 및 탈퇴회원으로 전환된 경우 해당 회원은 발급 대상에서 제외됩니다.',
  ISSUE_REASON: '지급 사유',
  ISSUE_TIME_TYPE: '지급 시점',
  PAYMENT_TYPE_HELP:
    '· 지급 시점을 ‘예약지급‘으로 설정한 경우, 쿠폰의 발급상태 및 회원의 상태에 따라 쿠폰 발급이 불가할 수 있습니다.<br>· 예약시점이 도래하기 전에 선택한 쿠폰의 발급상태가 ‘발급종료‘로 변경될 경우, 해당 쿠폰은 발급되지 않습니다.<br>· 예약시점이 도래하기 전에 선택한 회원이 휴면회원 혹은 탈퇴회원으로 전환될 경우, 해당 회원은 제외하고 쿠폰을 발급합니다.',
  ISSUE_TIME_TYPE_IMMEDIATELY_ISSUE: '즉시 지급',
  ISSUE_TIME_TYPE_SCHEDULED_ISSUE: '예약 지급',
  USE_NOTIFICATION: '알림 설정',
  USE_NOTIFICATION_HELP:
    '· 알림 발송 시점의 수단별 상태에 따라 알림이 발송되지 않을 수 있습니다.<br>· 알림수단의 사용여부, 자동 발송항목의 사용여부 및 야간발송 제한 설정에 따라 알림이 발송되지 않을 수 있습니다.<br>· SMS 선택 시, SMS 포인트가 부족할 경우 부족한 포인트만큼 일부 또는 전체 회원을 대상으로 알림 발송에 실패할 수 있습니다.<br>· 회원별 수신동의여부에 따라 알림이 발송되지 않을 수 있습니다.',
  NOT_SET: '설정 안 함',
  SET: '설정함',
  NOTIFICATION_TOOLS: '알림수단',
  NOTIFICATION_TOOLS_SMS: 'SMS',
  NOTIFICATION_TOOLS_EMAIL: '이메일',
  PAYMENTS: '지급',
  CODE_COPY: '코드 복사',
  CODE_MANAGEMENT: '코드 관리',
  PROMOTION_CODE_TITLE: '{0}의 {1}',
  TOTAL_CODES_LIST: '코드 목록 총 ',
  CODE_TITLE: '코드',

  // pop layer
  ISSUE_STOP_REASON: '발급중지 사유 안내',
  ISSUE_STOP_COUNT_OVER: '· 설정한 쿠폰의 총 발급수량이 초과되었습니다.',
  ISSUE_STOP_PERIOD_END: '· 설정한 쿠폰의 발급기간이 종료되었습니다.',
  ISSUE_STOP_ISSUE_STOPPED: '· 쿠폰의 상태값을 ‘발급중지‘로 변경하였습니다.',
  ISSUE_STOP_CHANGE: '· 쿠폰의 {0}을 수정하여 발급을 재개할 수 있습니다.',
  COUPON_CODE_MANAGEMENT: '쿠폰 코드 관리',
  CODE_MANAGEMENT_TIP_1: '생성된 코드를 조회하거나, 신규로 코드를 생성할 수 있습니다.',
  CODE_MANAGEMENT_TIP_2: '하단 목록에서 [추가생성]을 클릭 시, 입력한 수량만큼 미발급 상태의 코드가 신규로 생성됩니다.',
  CODE_MANAGEMENT_TIP_3:
    '운영자가 회원을 직접 선택하여 코드 발급 쿠폰을 발급할 경우, 해당 코드는 발급여부에 ‘운영자 발급’으로 표시됩니다.',
  CODE_MANAGEMENT_TIP_4:
    '(운영자 발급 시, 코드를 신규로 생성하여 발급하며 기존 발급된 코드 및 쿠폰 발급수량에 영향을 주지 않습니다.)',
  ADD_BUTTON: '추가생성',
  DATE: '생성일자',
  CODE_STATUS: '발급여부',
  CODE_STATUS_ISSUED: '발급',
  CODE_STATUS_NOT_ISSUED: '미발급',
  ISSUANCE_USE: '쿠폰 발급/사용 현황',
  WITHDRAW_COUNT: '철회현황',
  TOTAL_DISCOUNT_AMT: '총 할인금액',
  NORMAL_ISSUANCE: '일반 발급',
  ADMIN_ISSUANCE: '운영자 발급',
  WITHDRAW: '철회',
  USE: '사용',
  ISSUANCE_STATUS_TIP:
    '· 쿠폰에서 설정한 발급방식에 따라 발급된 건을 ‘일반 발급’, 운영자가 회원을 직접 선택하여 발급된 건을 ‘운영자 발급’으로 구분합니다.<br>· 운영자 발급은 쿠폰의 발급수량 제한에 영향을 받지 않고 발급 가능합니다.',
  TOTAL_DISCOUNT_AMT_TIP:
    '· ‘총 할인금액’이란 쿠폰을 사용하여 회원이 할인 받은 총 금액을 의미합니다.<br>· 주문의 클레임 처리로 인해 쿠폰이 복원될 경우, 복원 시점에 해당 쿠폰으로 할인 받은 금액만큼 총 할인금액에서 차감됩니다.',
  MEMBER_GRADE_TITLE: '회원등급',
  ADMIN_ISSUED: '발급구분',
  COUPON_USE_STATUS_TYPE: '사용상태',
  COUPON_USE_STATUS_TYPE_USABLE: '사용가능',
  COUPON_USE_STATUS_TYPE_USED: '사용완료',
  COUPON_USE_STATUS_TYPE_EXPIRED: '기간만료',
  COUPON_USE_STATUS_TYPE_WITHDRAW: '철회완료',
  WITHDRAW_CANCEL: '지급철회',
  COUPON_DISCOUNT_AMT: '실 할인금액',
  ISSUE_YMDT: '발급일',
  USE_END_YMDT: '만료일',
  USE_YMDT: '최종 사용일',
  WITHDRAW_YMDT: '철회일',
  ADMIN_ISSUE_REASON: '지급사유',
  WITHDRAW_REASON: '철회사유',
  TOTAL_ISSUED_LIST: '쿠폰 내역',
  COUPON_WITHDRAW: '쿠폰 철회',
  WITHDRAW_REASON_PLACEHOLDER: '철회사유를 입력해주세요.',
  DOWNLOAD: '엑셀 다운로드',
  COUPON_CODE: '쿠폰 코드',
  SURE_ADD_CODE: '{0}개의 코드를 추가로 생성하시겠습니까?',
  ALERT_MAX: '쿠폰은 최대 100,000개까지 발급할 수 있습니다.',

  // search pop
  COUPON_SEARCH: '쿠폰 조회',
  ADD_SELECTED: '선택 추가',

  // detail
  BTN_TO_ISSUE_ING: '발급 재시작',
  // basic
  BASIC_INFO: '기본정보',
  SELECT_MALL: '쇼핑몰을 선택하세요.',
  NAME_PLACEHOLDER: '쿠폰명을 입력해주세요.',
  REASON: '쿠폰설명',
  REASON_PLACEHOLDER: '쿠폰설명을 입력해주세요.',
  SCHEDULED_ISSUE_PLAN: '(예약지급대기중)',
  ISSUED_CNT: '발급 {0}장',
  USE_CNT: '사용 {0}장',
  // issuance
  ISSUE_INFO: '발급정보',
  ISSUE_TYPE_2: '발급 유형',
  ISSUE_HELP:
    '· 쿠폰의 발급 유형은 ‘다운로드 발급‘, ‘코드 발급‘, ‘자동 발급‘으로 구분되며, 각 유형에 맞는 발급조건 설정이 가능합니다.<br>· 관리자에서 직접 회원을 지정하여 쿠폰을 발급할 경우, 설정한 발급유형 및 발급 제한 조건과 무관하게 발급 가능합니다.<br>· 다운로드 발급 : 회원이 쇼핑몰에서 직접 쿠폰을 다운로드하여 발급받는 형태의 쿠폰입니다.<br>· 코드 발급 : 회원이 프로모션 코드를 입력하여 쿠폰을 발급받는 형태의 쿠폰입니다.<br>                         쿠폰 등록/수정 시 코드를 직접 입력하거나, 원하는 수량만큼 임의의 코드를 자동으로 생성할 수 있습니다.<br>· 자동 발급 : 쿠폰 지급 관리, 회원 등급 관리 등의 메뉴에서 설정한 조건에 따라 자동으로 발급되는 형태의 쿠폰입니다.',
  ISSUE_LIMIT_CNT: '총 발급수량',
  DAILY_ISSUE_LIMIT: '일별 총 발급수량',
  LIMIT_N: '발급 무제한',
  LIMIT_Y: '발급 제한',
  PER_PERSON_LIMIT: '회원별 발급수량',
  ISSUE_PER_PERSON_LIMIT: '전체 발급기간 기준',
  DAILY_ISSUE_PER_PERSON_LIMIT: '하루 기준',
  ISSUANCE_PERIOD: '발급기간',
  POSSIBLE_DAYS_OF_WEEK: '발급가능 요일',
  ISSUE_TIME: '발급가능 시간대',
  MEMBER_GRADE: '발급대상 회원등급',
  GRADE_ALL: '전체등급',
  ISSUE_PLATFORM: '발급가능 플랫폼',
  PLATFORM_TYPE_PC: 'PC웹',
  PLATFORM_TYPE_MOBILE_WEB: '모바일웹',
  CODE_TYPE: '코드 등록 방식',
  CODE_TYPE_DESIGNATE: '직접 입력',
  CODE_TYPE_RANDOM: '자동 생성',
  PROMOTIONAL_CODE: '프로모션 코드',
  DUPLICATE_CHECK: '중복체크',
  N_CODE_LENGTH: '{0}자리',
  CHOOSE_GRADE: '특정 회원등급',
  ALL_GRADE: '전체 회원등급',
  // amount
  AMOUNT_INFO: '금액설정',
  MIN_LIMIT: '최소 기준 금액',
  MAX_LIMIT: '최대 할인 금액',
  LIMIT_BY_PRODUCT: '상품 금액 기준',
  LIMIT_BY_CART: '주문 금액 기준',
  LIMIT_BENEFITS: '혜택 제한',
  SKIPS_ACCUMULATION: '적립금 적립 제한',
  NOT_ACCUMULATION: '적립금 적립불가',
  NOT_ORDER_COUPON: '주문 쿠폰 적용불가',
  NOT_CART_COUPON: '주문 쿠폰 사용제한',
  NOT_PRODUCT_COUPON: '상품 쿠폰 적용불가',
  COST_SHARING: '비용분담 설정',
  SERVICE_SHARE: '쇼핑몰 부담',
  PARTNER_SHARE: '파트너 부담',
  // target
  TARGET_INFO: '대상설정',
  DISCOUNT_TARGET: '할인 대상',
  TARGET_HELP:
    '· 쿠폰 유형을 ‘주문 적용 쿠폰’으로 선택한 경우, ‘전체상품’ 외 대상 상품 설정이 불가합니다.<br>· 주문 적용 쿠폰은 전체 상품 중 동일한 배송비템플릿을 사용하는 상품을 구매하는 경우에만 사용할 수 있습니다.',
  TARGET_TYPE_ALL_PRODUCT: '전체상품',
  TARGET_TYPE_CATEGORY: '전시 카테고리',
  TARGET_TYPE_PRODUCT: '개별상품',
  EXCLUDE_TARGET: '할인 대상 제외',
  CATEGORY_SELECT: '카테고리 선택',
  PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  PRODUCT_PRICE: '판매가',
  IMMEDIATE_DISCOUNT: '즉시할인가',
  SALES_STATUS: '판매상태',
  SALE_SETTING: '판매설정',
  PRODUCT_ACCUMULATION_RATE: '상품 적립률',
  APPLY_PROMOTION: '프로모션 적용',
  REGISTRATION_DATE: '등록일자',
  TARGET_PRODUCT: '대상 상품',
  EXCLUDE_PRODUCT: '제외 상품',
  TARGET_TIPS: '대상상품은 최대 500개까지 등록할 수 있습니다.',
  EXCLUDE_TIPS: '제외상품은 최대 500개까지 등록할 수 있습니다.',
  NO_DATA_TARGET: '등록된 대상 상품이 없습니다.',
  NO_DATA_EXCLUDE: '등록된 제외 상품이 없습니다.',
  DELETE_SELECTED: '선택삭제',
  SEARCH_PRODUCT: '상품 조회',
  EXCEL_BATCH_ADD: '엑셀 일괄등록',
  EXCEL_DOWNLOAD: '엑셀 양식 다운로드',

  // use
  USE_INFO: '사용설정',
  USABLE_PLATFORM: '사용가능 플랫폼',
  PERIOD_OF_USE: '사용기간',
  USE_DAY_TYPE: '쿠폰 발급일 기준',
  USE_END_YMDT_TYPE: '사용종료일 지정',
  CAN_BE_USED_UNTIL: '까지 사용가능',

  SELECT_PERIOD: '기간선택',
  TODAY: '당일',
  LATER_DAYS: '+{0}일',
  MONTH_END_DAY: '해당월 말일',

  NUMBER_PLACEHOLDER: '숫자만 입력하세요.',
  GO_NEXT_ADD: '계속 등록',
  GO_LIST: '등록정보 확인',

  // error message
  MSG_NOT_SELECTED_COUPON: '지급할 쿠폰을 선택해주세요.',
  MSG_NOT_ONLY_MALL: '동일 쇼핑몰 쿠폰만 선택해주세요.',
  MSG_SET_OTHERS: '발급상태가 ‘발급종료’인 쿠폰은 선택이 불가합니다.<br>해당 쿠폰을 제외하고 추가하시겠습니까?',
  MSG_CONFIRM_GT_10: '지급 예정 쿠폰은 1회 최대 10개까지 선택 가능합니다.',
  MSG_STATUS_TYPE_IS_EMPTY: '발급상태를 선택해주세요.',
  MSG_MALL_IS_EMPTY: '쇼핑몰을 선택해주세요.',
  MSG_CHANGE_MALL: '쇼핑몰 수정할 경우 일부 설정값이 초기화됩니다.<br>수정하시겠습니까?',
  MSG_COUPON_NAME_IS_EMPTY: '쿠폰명을 입력해주세요.',
  MSG_PROMOTIONAL_CODE_IS_EMPTY: '프로모션 코드를 입력해주세요.',
  MSG_DUPLICATE_CHECK_NG: '이미 사용중인 코드입니다.',
  MSG_DUPLICATE_CHECK_NG_2: '입력된 프로모션 코드는 이미 사용중인 코드입니다.',
  MSG_DUPLICATE_CHECK_OK: '사용가능한 코드입니다.',
  MSG_ISSUE_START_AFTER_USE_END: '발급시작일은 사용종료일 전으로만 설정 가능합니다.',
  MSG_ISSUE_END_AFTER_USE_END: '발급종료일은 사용종료일 전으로만 설정 가능합니다.',
  MSG_USE_END_BEFORE_ISSUE_END: '사용종료일은 발급기간 후로만 설정 가능합니다.',
  MSG_ISSUE_LIMIT_IS_EMPTY: '발급수량을 입력해주세요.',
  MSG_ISSUE_LIMIT_GT_100000: '쿠폰코드 자동 생성 시 발급수량은 10만개를 초과할 수 없습니다.',
  MSG_DAILY_ISSUE_LIMIT_IS_EMPTY: '일별 총 발급수량을 입력해주세요.',
  MSG_DAILY_ISSUE_LIMIT_GT_ISSUE_LIMIT: '일별 총 발급 제한 수량은 총 발급수량 이하로만 설정 가능합니다.',
  MSG_PERSONAL_ISSUE_LIMIT_NOT_CKECKED: '회원별 발급수량 기준을 선택해주세요.',
  MSG_ISSUE_PER_PERSON_LIMIT_IS_EMPTY: '회원별 발급수량을 입력해주세요.',
  MSG_ISSUE_PER_PERSON_LIMIT_GT_ISSUE_LIMIT: '회원별 발급수량은 총 발급수량 이하로만 설정 가능합니다.',
  MSG_DAILY_PER_PERSON_LIMIT_GT_DAILY_LIMIT: '회원별 하루 발급수량은 일별 총 발급수량 이하로만 설정 가능합니다.',
  MSG_DAILY_PER_PERSON_LIMIT_GT_PER_PERSON_LIMIT:
    '회원별 하루 발급수량은 회원별 전체기간 발급수량 이하로만 설정 가능합니다.',
  MSG_ISSUE_DATE_IS_EMPTY: '발급기간을 선택해주세요.',
  MSG_DAYS_OF_WEEK_NOT_CKECKED: '발급 가능한 요일을 선택해주세요.',
  MSG_ISSUE_END_HOUR_BEFORE_ISSUE_START_HOUR: '발급 종료 시간은 시작시간보다 미래로 설정해주세요.',
  MSG_MEMBER_GRADE_NOT_CKECKED: '발급대상 회원등급을 선택해주세요.',
  MSG_ISSUABLE_PLATFORM_NOT_CKECKED: '발급 가능한 플랫폼을 선택해주세요.',
  MSG_DISCOUNT_IS_EMPTY: '할인금액을 입력해주세요.',
  MSG_TEN_WON: '{0}은 10원 단위로 입력 가능합니다.',
  MSG_MAX_DISCOUNT_IS_EMPTY: '최대 할인금액을 입력해주세요.',
  MSG_MIN_SALE_PRICE_IS_EMPTY: '최소 기준 금액을 입력해주세요.',
  MSG_DISCOUNT_AMT_GT_MIN_SALE_PRICE: '할인금액을 최소 기준금액 이하로 입력해주세요. ',
  MSG_COUPON_TARGETS_IS_EMPTY: '할인 대상 상품을 선택해주세요.',
  MSG_EXCLUDES_TARGET_IS_EMPTY: '할인 대상에서 제외할 상품을 선택해주세요.',
  MSG_USABLE_PLATFORM_NOT_CKECKED: '사용 가능한 플랫폼을 선택해주세요.',
  MSG_USE_END_DAY_IS_EMPTY: '쿠폰의 사용기간을 선택해주세요.',
  MSG_CODE_STATUS_IS_EMPTY: '발급여부를 선택해주세요.',
  MSG_NOT_SELECTED_PRODUCT: '상품을 선택해주세요.',
  MSG_DEL_PRODUCT_CONFIRM: '선택한 {0}개의 상품을 삭제하시겠습니까?',
  MSG_ADD_PRODUCT_RESULT: '추가 되었습니다. 성공 {0}건 실패 {1}건',
  MSG_ADD_PRODUCT_GT_500: '대상상품은 최대 500개까지 선택할 수 있습니다.<br>초과된 개수만큼 제외하고 추가됩니다.',
  MSG_CHANGE_TARGET_CONFIRM: '설정방식을 수정할 경우 기존에 설정한 정보는 초기화됩니다.<br>수정하시겠습니까?',

  MSG_SAVE_FINISH: '저장이 완료되었습니다.',
  MSG_TO_END: '쿠폰의 발급상태를 발급종료로 변경하시겠습니까?<br>발급종료로 변경 시 쿠폰의 재발급이 불가합니다.',
  MSG_TO_STOP:
    '쿠폰의 발급상태를 발급중지로 변경하시겠습니까?<br>발급중지로 변경 시 쿠폰의 발급이 일시적으로 중단됩니다.',
  MSG_TO_ING: '쿠폰의 발급상태를 발급중으로 변경하시겠습니까?<br>발급중으로 변경 시 쿠폰의 발급이 재개됩니다.',
  MSG_REQUESTYMDT_BEFORE_NOW: '지급 시점은 현재 시점 이후로만 설정 가능합니다.',
  MSG_MEMBER_NOT_SELECTED: '지급대상을 선택해주세요.',
  MSG_NOTIFICATION_TOOLS_NOT_CHECKED: '알림수단을 선택해주세요.',
  MSG_PAYMENTS_CONFIRM: '선택한 {0}개의 쿠폰을 지급하시겠습니까?',
  MSG_COUPON_PAYMENTS:
    '쿠폰 지급 설정이 완료되었습니다.<br>선택한 회원의 수가 많을 경우, 실제 지급 시까지 시간이 다소 소요될 수 있습니다.<br>(지급 결과는 ‘쿠폰 지급 관리’ 메뉴에서 확인할 수 있습니다.)',
  MSG_WITHDRAW_CONFIRM: '지급된 쿠폰을 철회할 경우 고객의 쿠폰리스트에서 삭제됩니다.<br>쿠폰을 철회하시겠습니까?',
  MSG_WITHDRAW: '철회가 완료되었습니다.',
});
