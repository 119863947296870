


























































































































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import ChainingSelector from '@/components/common/ChainingSelector.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { Parameters, getDefaultParams, emptyParameter, adminStore } from '@/components/board/Inquiries/SearchQuery';
import { searchFormOptions } from '@/components/board/Inquiries/SearchOptions';
import { Admin } from '@/types';
import { Mall } from 'ncp-api-supporter';
import { Getter } from 'vuex-class';
import SearchKeyword from '@/components/board/Inquiries/SearchKeyword.vue';
import { getParametersByQuery } from '@/components/board/Inquiries/QueryParser';
import { TabState } from '@/views/contents/board/basic/InquiriesList.vue';
import { getCurrentMallNo } from '@/utils/mall';
import { setNoDataMessage } from '@/helpers/grid';
import { addDay, getToday } from '@/utils/dateFormat';

const today = getToday();
const aWeekAgo = addDay(today, -7);
// const duplicateCommaReplacer = string => string.replace(/[*,.*]{2,}/g, ','); todo

@Component({
  components: {
    SearchButton,
    MallSelect,
    ChainingSelector,
    DateRangePicker,
    SelectBox,
    TextInput,
    CheckBoxGroup,
    SearchKeyword,
  },
})
export default class SearchForm extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
  @adminStore.Getter('getAdmin')
  private readonly me!: Admin;

  @Prop({ required: true }) private readonly selectedClassification!: TabState;
  private readonly searchFormOptions = searchFormOptions;

  @Ref()
  private readonly searchButton: SearchButton;
  @Ref()
  private readonly mallSelect!: MallSelect;

  private params: Parameters = {} as Parameters;

  mounted() {
    setNoDataMessage(this.$t('BOARD.INQUIRIES.RESULT_NONE_DATA').toString());
  }

  created() {
    setNoDataMessage(this.$t('BOARD.INQUIRIES.RESULT_NONE_DATA').toString());
    const hasQuery = this.$route.path !== this.$route.fullPath;
    hasQuery ? this.parseQueryString() : (this.params = getDefaultParams());
    if (this.malls && this.$route.query.mallNos !== '0') {
      this.params.mallNos = getCurrentMallNo(this);
    }
  }

  search() {
    this.searchButton.sendQueryUrl();
  }

  @Watch('params.mallNos')
  private changeMall(): void {
    this.params.inquiryTypeNo = '';
    if (this.params.mallNos != 0) {
      this.getInquiryTypes();
    } else {
      this.inquiryTypes = [];
    }
  }

  @Watch('selectedClassification')
  ChangedSelectedClassification(value: TabState): void {
    this.resetParams();

    switch (value) {
      case 'TAB1': //답변지연
        if (this.malls.length > 1) {
          this.params.mallNos = 0;
        }
        this.params.inquiryStatuses = 'ISSUED,IN_PROGRESS';
        // this.params.periodInfoStartYmdt = addMonth(new Date(), -3);
        // this.params.periodInfoEndYmdt = addDay(new Date(), -1);
        this.params.periodInfoStartYmdt = aWeekAgo;
        this.params.periodInfoEndYmdt = today;
        break;
      case 'TAB2': //담당자 미지정
        if (this.malls.length > 1) {
          this.params.mallNos = 0;
        }
        this.params.periodInfoStartYmdt = aWeekAgo;
        this.params.periodInfoEndYmdt = today;
        this.params.isUnspecified = 'true';
        break;
      case 'TAB3': //‘{운영자명}’담당 미답변
        // this.params.assigneeNo = ''; // todo API self type
        if (this.malls.length > 1) {
          this.params.mallNos = 0;
        }
        this.params.periodInfoStartYmdt = aWeekAgo;
        this.params.periodInfoEndYmdt = today;
        this.params.inquiryStatuses = 'ISSUED,IN_PROGRESS';
        this.params.assigneeNo = this.me.no.toString();
        break;
    }

    this.$nextTick(() => this.searchButton.sendQueryUrl());
  }

  private parseQueryString(): void {
    const parsedParams = getParametersByQuery<Parameters>(this.$route.query, emptyParameter);
    this.params = Object.assign(getDefaultParams(), parsedParams);
  }

  private resetParams(): void {
    this.params = getDefaultParams();
    if (this.malls) {
      this.params.mallNos = getCurrentMallNo(this);
    }
  }

  // todo
  // private reset(): void {
  //   this.query = { ...DefaultQuery };
  // }

  private beforeSearchValidation(): boolean {
    // todo
    return true;
  }

  private inquiryTypes = [];
  private assignees = [];
  get getInquiryTypeRequest() {
    return {
      params: {
        mallNo: Number(this.params.mallNos),
      },
    };
  }

  private getInquiryTypes(): void {
    this.$api.getInquiryType(this.getInquiryTypeRequest).then(ret => {
      this.inquiryTypes = ret.data;
    });
  }

  get getInquiryTypesInquiryTypeNoRequest() {
    return {
      pathParams: {
        inquiryTypeNo: this.params.inquiryTypeNo,
      },
      params: {
        mallNo: Number(this.params.mallNos),
      },
    };
  }
  private changeInquiryType(): void {
    this.params.assigneeNo = '';
    this.$api.getInquiryTypesInquiryTypeNo(this.getInquiryTypesInquiryTypeNoRequest).then(ret => {
      const selectedInquiry = ret.data;
      if (selectedInquiry) {
        this.assignees = selectedInquiry.assignees;
      } else {
        this.assignees = [];
      }
    });
  }

  private checkSelectedMall(): void {
    if (!this.params.mallNos || this.params.mallNos === 0) {
      alert(this.$t('BOARD.INQUIRIES.WARN_FIRST_SELECT_MALL'));
      this.mallSelect.focus();
      return;
    }
  }
}
