export default Object.freeze({
  PLEASE_MALL: '쇼핑몰을 선택하세요.',
  PLEASE_SELECT: '선택하세요',

  NOTICE_DEPOSIT_CANCEL: "'입금대기'상품의 주문취소는 별도의 환불 절차가 필요하지 않습니다.",
  NOTICE_PG_OPTION_CANCEL: '간편결제 또는 PG결제건은 취소금액만큼 결제가 부분취소 처리됩니다.',
  NOTICE_PG_OPTION_RETURN: '간편결제 또는 PG결제건은 반품금액만큼 결제가 부분취소 처리됩니다.',
  NOTICE_PG_OPTION_CANCEL_REFUND:
    '단, 부분취소가 불가한 일부 PG사의 경우 환불방법을 ‘현금환불’로 변경하여 환불처리해 주세요.',
  NOTICE_ACCOUNT_REFUND:
    '결제수단이 무통장입금/가상계좌인 주문은 반드시 환불절차를 완료해야 취소완료 상태로 변경됩니다.',
  NOTICE_VIRTUAL_ACCOUNT:
    '가상계좌 주문 건의 경우, PG환불 처리 시 PG사 정책에 따라 환불 수수료가 부과될 수 있습니다. 사용 중인 PG사 측으로 사전 문의 부탁드립니다.',
  NOTICE_ACCOUNT_REFUND_RETURN:
    '결제수단이 무통장입금/가상계좌인 주문은 반드시 환불절차를 완료해야 반품완료 상태로 변경됩니다.',
  NOTICE_RETURN_COLLECT:
    '반품 상품은 수거완료처리 시 [재고복원] 신청한 경우에만 반품완료 이후 자동으로 복원처리 됩니다.',
  ORDER_DELETE: '선택한 {n} 개의 주문을 정말로 삭제 하시겠습니까?',
  ORDER_DELETE_WARNING:
    '삭제처리는 주문번호 단위로 처리되므로 선택된 클레임과 주문번호가 동일한 모든 상품이 삭제됩니다. 삭제된 주문 정보는 복구가 불가하며 영구 삭제됩니다.',

  NEED_MALL_SELECT: '쇼핑몰을 먼저 선택해주세요.',
  NEED_ORDER_PRODUCT_SELECT: '선택된 주문 상품이 없습니다.',
  NEED_SELECT: '선택해주세요.',
  NEED_REFUND_HOLD_PROCESS: '환불처리 항목을 선택해주세요.',
  NEED_ACCOUNT: '계좌번호를 입력하세요.',
  NEED_DEPOSITOR: '예금주를 입력하세요.',
  NEED_PAY_REMITTER: '입금자를 입력하세요.',
  NEED_ADJUST_REASON: '조정사유를 입력해주세요',

  VALID_PERIOD: '조회기간은 최대 3개월까지 가능합니다.',
  VALID_CLAIM_STATUS: '조회하려는 클레임상태를 체크해주세요.',
  VALID_ORDER_STATUS: '조회하려는 주문상태를 체크해주세요.',
  VALID_PLATFORM_TYPE: '조회하려는 플랫폼구분을 체크해주세요.',
  VALID_PAY_TYPE: '조회하려는 결제수단을 체크해주세요.',
  VALID_SMS_SELECT_ORDER: '선택된 주문상품이 없습니다.',
  VALID_SMS_SEARCH_ORDER: '검색된 주문 상품이 없습니다.',
  VALID_SAME_MALL: '동일한 쇼핑몰의 주문만 선택해주세요.',
  VALID_DELIVERY_COMPANY: '택배사를 선택해 주세요',
  VALID_INVOICE_NO: '송장번호를 입력해주세요.',
  VALID_REFUND_REQUEST_REASON: '환불조정 사유를 입력해 주세요.',
  VALID_AMOUNT: '금액을 입력해주세요.',
  VALID_DEPOSITOR_NAME: '입금자명을 입력해 주세요.',

  CONFIRM_DEPOSIT: '입금확인하시겠습니까?',
  CONFIRM_ALREADY_DELIVERY: '출고처리하시겠습니까?',
  CONFIRM_REFUND_PROCESS: '환불처리 하시겠습니까?',
  CONFIRM_EXCHANGE_ACCEPT: '교환승인처리 하시겠습니까?',
  CONFIRM_RETURN_ACCEPT: '반품승인처리 하시겠습니까?',
  CONFIRM_CANCEL_APPROVE: '취소승인처리 하시겠습니까?',

  COMPLETE_ALREADY_DELIVERY: '출고처리 완료되었습니다.',
  COMPLETE_PROCESS: '처리되었습니다.',
  COMPLETE_REQUEST: '처리 요청되었습니다.',
  COLLECT_FAIL: '수거완료 처리가 실패하였습니다.\n다시 시도해주세요.',
  COMPLETE_SAVE_MODIFY: '변경사항이 저장되었습니다.',

  WARNING_AUTO_WITHDRAW_CLAIM:
    '배송번호 단위로 출고처리가 가능합니다. 해당 클레임은 자동철회되니 배송번호 확인 후 출고 처리해 주세요.',

  ASK_WITHDRAW: '철회하시겠습니까?',
  ASK_CLAIM_WITHDRAW: '클레임을 철회하시겠습니까?',
  ASK_AFTER_CLAIM_WITHDRAW: '철회 시 후순위 클레임의 결제/환불 금액이 변동됩니다.',
  ASK_AFTER_CLAIM_WITHDRAW_CONFIRM: '철회 시 후순위 클레임의 결제/환불 금액이 변동됩니다.\n클레임을 철회하시겠습니까?',
  ASK_COLLECT: '수거완료 하시겠습니까?',
  ASK_EXCHANGE_WITH_HOLD: '교환보류 처리하시겠습니까?',
  ASK_RETURN_WITH_HOLD: '반품보류 처리하시겠습니까?',
  ASK_EXCHANGE_RELEASE_HOLD: '교환보류 해제하시겠습니까?',
  ASK_RETURN_RELEASE_HOLD: '반품보류 해제하시겠습니까?',
  ASK_NAVER_RETURN_REQUEST_APPROVE: '반품요청 승인하시겠습니까?',

  DELETE_SUCCESS: '삭제되었습니다',

  PLACE_HOLDER_INVOICE_NO: '반품 송장번호 입력',

  NO_EXCHANGE_PROCESS_AMOUNT: '교환처리 금액이 발생하지 않았습니다.',

  FAIL_IMAGE_DOWNLOAD: '이미지 다운로드 실패하였습니다.',

  TOOLTIP_DIVIDE_DELIVERY_RECALCULATE: '나눔배송된 경우 원배송기준으로 재계산됩니다.',
  TOOLTIP_DELIVERY_TEMPLATE: '배송비는 상품에 적용된 배송비 템플릿의 ‘결제방식 > 선결제/착불’ 설정에 따라 부과됩니다.',
  TOOLTIP_DELIVERY_NOT_PREPAID_CALCULATION: '착불인 경우 발생하는 배송비는 표기되나 최종 금액에 반영되지 않습니다.',

  NAVER_PAY_CENTER_PROCESS_ALERT: '네이버페이 주문형 주문은 네이버페이 센터를 통해 처리해 주세요.',
});
