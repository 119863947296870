




import { Vue, Component } from 'vue-property-decorator';
import { GetInstagramTokenRequest } from 'ncp-api-supporter/dist/types/modules/manage/instagram';
import { i18nExternal } from '@/const/contents/configuration/externalService';
import { i18n } from '@/main';

@Component
export default class InstagramCodeReceiver extends Vue {
  created() {
    const code = typeof this.$route.query.code === 'string' ? this.$route.query.code : '';
    if (window.opener === null) {
      alert(i18n.t('ALERT_WRONG_ACCESS'));
      window.close();
    }
    const opener = window.opener as Window;
    const mallNo = Number(new URLSearchParams(opener.location.search).get('mallNo'));

    this.connectInstagram(code, mallNo)
      .then(() => {
        opener.location.reload();
        window.close();
      })
      .catch(() => {
        alert(i18nExternal('INSTAGRAM_INTEGRATION_FAIL_MESSAGE'));
        window.close();
      });
  }

  connectInstagram(code: string, mallNo: number) {
    const request: GetInstagramTokenRequest = {
      params: {
        mallNo,
        code,
        redirectUri: location.origin + location.pathname,
      },
    };

    return this.$api.getInstagramToken(request);
  }
}
