import { i18n } from '@/main';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { formatCurrency } from '@/utils/numberFormat';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import { mall } from '@/utils/mall';
import { addDay, getToday } from '@/utils/dateFormat';

export const getDefaultParams = () => ({
  mallNo: mall.lastCreatedMall.mallNo,
  startYmd: addDay(new Date(), -7),
  endYmd: getToday(),
  includesClaim: false,
  platformTypes: 'PC,MOBILE_WEB,MOBILE_APP',
  memberGradeNo: 0,
  genderTypes: 'MALE,FEMALE,UNKNOWN',
  memberType: 'ALL',
  memberJoinType: '', // 확인 필요
  displayBrandNo: 0,
  categoryNo: '',
  categoryDepth: '',
  page: 1,
  size: 30,
});

// --- 그리드 옵션
export const getSalesByCategoryGridProps = () => ({
  header: {
    columns: [
      {
        name: 'excelDownload',
        renderer: props => {
          props.tooltipId = 'excelDownload';
          return headerWithTooptipRenderer(props);
        },
      },
    ],
  },
  columns: [
    {
      header: 'STATISTIC.SALES.STANDARD_CATEGORY',
      name: 'categoryName',
      align: 'center',
      minWidth: 60,
    },
    {
      header: 'STATISTIC.SALES.ORDER_COUNT',
      name: 'totalOrderCnt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.PRODUCT_SALES_COUNT',
      name: 'totalProductCnt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.SALES_COUNT',
      name: 'totalSaleCnt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.SALES_AMOUNT_A',
      name: 'totalSaleAmt',
      align: 'center',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.COMMISSION_AMOUNT',
      name: 'totalCommissionAmt',
      align: 'right',
      minWidth: 60,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.PROMOTION_PRODUCT_DISCOUNT_AMOUNT',
      name: 'totalMallPromotionAmt',
      align: 'right',
      minWidth: 180,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.TOTAL_PAYMENT',
      name: 'totalPayAmt',
      align: 'right',
      minWidth: 160,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'STATISTIC.SALES.MALL_PROFIT_AMOUNT',
      name: 'totalMallProfitAmt',
      align: 'right',
      minWidth: 160,
      formatter: cell => formatCurrency(cell.value),
    },
    {
      header: 'DOWNLOAD',
      name: 'excelDownload',
      align: 'center',
      minWidth: 60,
      formatter: () => getAnchorHTML(i18n.t('DOWNLOAD')),
    },
  ],
  options: {
    pageOptions: {
      page: 1,
      perPage: 30,
      totalCount: 0,
    },
  },

  displayOptions: {
    showsRightArea: true,
    hasExcelDownloadButton: false,
    pageSizeKey: 'size',
    hasSettingButton: false,
    headerTooltipOption: [
      {
        id: '#excelDownload',
        popupData: {
          data: { message: i18n.t('STATISTIC.SALES.SALES_BY_DATE_EXCEL_DOWNLOAD_TOOLTIP'), leftAlign: true },
        },
      },
    ],
  },
});
