
































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { DeliveryCompany, GetMemberGradesRequest, Grade, NCPResponse } from 'ncp-api-supporter';
import { ClaimType } from '@/types/claim';
import { OptionData } from '@/helpers/type';
import { ClaimParams } from '@/components/shipping/claim/searchOptions.type';
import { claimTypes } from '@/const/claim';
import { i18n } from '@/main';
import { sendQueryString } from '@/utils/query';
import { addMonth, getToday } from '@/utils/dateFormat';
import { searchFormOptions } from '@/components/shipping/claim/searchOptions.ts';
import { getDefaultParams } from '@/components/shipping/claim/searchQuery';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import SearchKeywordInput from '@/components/shipping/claim/SearchKeywordInput.vue';
import SortTypeSelect from '@/components/shipping/claim/SortTypeSelect.vue';
import DelayCount from '@/components/shipping/claim/DelayCount.vue';
import { mall } from '@/utils/mall';
import _ from 'underscore';
import moment from 'moment';
import PartnerFinder from '@/components/common/input/PartnerFinder.vue';
import { namespace } from 'vuex-class';
const partnerStore = namespace('partner');

@Component({
  components: {
    PartnerFinder,
    MallSelect,
    SearchButton,
    SelectBox,
    DateRangePicker,
    CheckBoxGroup,
    SearchKeywordInput,
    SortTypeSelect,
    DelayCount,
  },
})
export default class SearchForm extends Vue {
  @partnerStore.Getter('getPartnerNo')
  private readonly partnerNo: number;
  @partnerStore.Action('resetPartnerState')
  private readonly resetPartnerState: () => void;

  @Ref('searchKeywordInput')
  private readonly searchKeywordInputRef: SearchKeywordInput;
  @Ref('sortTypeSelect')
  private readonly sortTypeSelectRef: SortTypeSelect;
  @Ref('mallSelect')
  private readonly mallSelectRef!: MallSelect;
  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  private isOnlyOneMall = mall.isOnlyOneMall;
  private searchFormOptions = searchFormOptions(this.claimType);
  private params: ClaimParams = getDefaultParams(this.claimType, this.$route);

  @Watch('$route.query')
  setEmptyQuery() {
    if (_.isEmpty(this.$route.query)) {
      this.resetParams();
    }
  }

  @Watch('partnerNo')
  private changePartnerNo(newPartnerNo) {
    this.params.partnerNo = newPartnerNo;
  }

  private get isCancelClaim(): boolean {
    return this.claimType === claimTypes.CANCEL;
  }

  private get isExchangeClaim(): boolean {
    return this.claimType === claimTypes.EXCHANGE;
  }

  private get isReturnClaim(): boolean {
    return this.claimType === claimTypes.RETURN;
  }

  private get isRefundClaim(): boolean {
    return this.claimType === claimTypes.REFUND;
  }

  private resetParams(): void {
    this.params = { ...getDefaultParams(this.claimType) };
    this.sortTypeSelectRef.initSortOptionValue();
    this.resetPartnerState();
  }

  private search() {
    if (!this.validateParams()) {
      return;
    }

    const routeQuery = {
      ...this.params,
    };

    sendQueryString(this, routeQuery, false, true);
  }

  private validateParams(): boolean {
    if (!this.validatePeriod()) {
      return false;
    }

    if (this.params.claimStatusTypes.length === 0) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_ORDER_STATUS'));
      return false;
    }

    if (this.params.platformTypes.length === 0) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_PLATFORM_TYPE'));
      return false;
    }

    if (this.params.payTypes.length === 0) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_PAY_TYPE'));
      return false;
    }

    return true;
  }

  private validatePeriod(): boolean {
    const isOverMaxDate = moment(this.params.startYmd).diff(this.params.endYmd, 'month', true) < -3;

    if (isOverMaxDate) {
      alert(i18n.t('CLAIM.MESSAGE.VALID_PERIOD'));
      return false;
    }
    return true;
  }

  private validateMallNoSelected() {
    if (!this.params.mallNo) {
      alert(this.$t('CLAIM.MESSAGE.NEED_MALL_SELECT'));
    }
  }

  private searchDelayedClaim() {
    const routeQuery = getDefaultParams(this.claimType);
    routeQuery.delay = true;
    routeQuery.mallNo = this.params.mallNo;
    routeQuery.startYmd = addMonth(new Date(), -3);
    routeQuery.endYmd = getToday();

    sendQueryString(this, routeQuery, false);
  }

  private mounted() {
    // this.searchKeywordInputRef.resetSearchKeywordTypes(); // 프리미엄과 다르게 프로에서는 고정값
    this.fetchMemberGrades();
    this.fetchDeliveryCompanies();
  }

  @Watch('params.mallNo')
  private async fetchMemberGrades(): Promise<void> {
    if (!this.params.mallNo || this.params.mallNo === '') {
      this.setMemberGradeOptions([]);
      return;
    }
    const request: GetMemberGradesRequest = {
      params: {
        mallNo: Number(this.params.mallNo),
        used: true,
      },
    };

    const { data }: NCPResponse<Grade[]> = await this.$api.getMemberGrades(request);

    const sortedData = data.sort((gradeX, gradeY) => gradeX.order - gradeY.order);
    const gradeOptions = sortedData.map(grade => ({
      value: grade.no,
      name: grade.name,
    }));

    this.setMemberGradeOptions(gradeOptions);
  }

  private setMemberGradeOptions(memberGradeOptions: OptionData<number>[]) {
    this.searchFormOptions.memberGradeOptions = [this.searchFormOptions.memberGradeOptions[0], ...memberGradeOptions];
    this.setDefaultMemberGradeNo();
  }

  private setDefaultMemberGradeNo() {
    const DEFAULT = 0;
    const memberGradeNoQuery = Number(this.$route.query?.memberGradeNo);

    if (!memberGradeNoQuery) {
      this.params.memberGradeNo = DEFAULT;
    }

    const hadMemberGrade = this.searchFormOptions.memberGradeOptions.find(
      memberGradeOption => memberGradeOption.value === memberGradeNoQuery,
    );

    this.params.memberGradeNo = hadMemberGrade ? memberGradeNoQuery : DEFAULT;
  }

  private async fetchDeliveryCompanies(): Promise<void> {
    const { data }: NCPResponse<DeliveryCompany[]> = await this.$api.getDeliveryCompanies({
      params: { countryCd: 'KR' },
    });

    const deliveryCompanyOptions = data.map(deliveryCompany => ({
      name: deliveryCompany.label,
      value: deliveryCompany.deliveryCompanyType,
    }));

    this.setDeliveryCompanyOptions(deliveryCompanyOptions);
  }

  private setDeliveryCompanyOptions(deliveryCompanyOptions: OptionData<string>[]) {
    this.searchFormOptions.deliveryCompanyOptions = [...deliveryCompanyOptions];

    this.setDefaultDeliveryCompany();
  }

  private setDefaultDeliveryCompany() {
    this.params.deliveryCompanyType = '';

    const DEFAULT = '';
    const deliveryCompanyTypeQuery = this.$route.query?.deliveryCompanyType;

    if (!deliveryCompanyTypeQuery) {
      this.params.deliveryCompanyType = DEFAULT;
    }

    const hasDeliveryCompany = this.searchFormOptions.deliveryCompanyOptions.find(
      deliveryCompanyOption => deliveryCompanyOption.value === deliveryCompanyTypeQuery,
    );

    this.params.deliveryCompanyType = hasDeliveryCompany ? deliveryCompanyTypeQuery : DEFAULT;
  }

  // TODO: checkAll 일 때 empty string으로 보내야 하는데, url로 최초 진입시 check-box가 전부 해제됨
  // private setEmptyCheckAll() {
  //   if (this.searchFormOptions.payTypes.data.length - 1 === this.params.payTypes.split(',').length) {
  //     this.params.payTypes = '';
  //     return;
  //   }
  // }
}
