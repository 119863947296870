export default Object.freeze({
  // HEADER.
  ACCOUNT_EXPIRATION: '쇼핑몰 남은 사용기간',
  SMS_POINT: 'SMS 잔여포인트',
  GO_TO_MY_GODO: '마이페이지 바로가기',
  GO_TO_FAQ: '자주 묻는 질문 (FAQ) 바로가기',
  EXPIRATION_DUE_DAYS: '남은 사용기간',
  PAYMENT_DUE_DATE: '결제 예정일',
  VIEW_MALL_NOTICE: '쇼핑몰 알림 보기',
  SCROLL_TO_TOP: '위로 이동',
  SCROLL_TO_BOTTOM: '밑으로 이동',

  MALL_NOTICE: '쇼핑몰 알림',
  NOTICE_LIST:
    'SMS 잔여포인트의 경우 SMS 건당 1포인트, LMS 건당 3포인트, 알림톡 건당 0.6 포인트 차감됩니다.\n' +
    'SMS 잔여포인트가 없는 경우 자동 및 개별 SMS 발송이 불가하므로 포인트를 충전해주세요.\n' +
    'SMS 잔여포인트가 부족한 쇼핑몰 전체가 노출됩니다.\n' +
    '쇼핑몰 연장 안내의 경우 결제 예정일에 정상적으로 연장 처리가 되지 않은 경우 노출됩니다.\n' +
    '︎정상적으로 연장 처리되지 않은 경우 쇼핑몰/관리자 이용이 제한되므로 반드시 연장해주시기 바랍니다.',
  ACCOUNT_EXPIRATION_NOTICE: '쇼핑몰 사용기간 만료 안내',
  ACCOUNT_EXPIRATION_WARN: '사용기간이 만료되는 경우 전체 쇼핑몰 접속이 불가합니다.',
  ACCOUNT_EXPIRATION_HEAD: '쇼핑몰 사용기간이',
  ACCOUNT_EXPIRATION_TAIL: '남았습니다.',
  MALL_CONTINUE: '쇼핑몰 연장',

  ACCOUNT_EXTENSION_NOTICE: '쇼핑몰 연장 안내',
  EXPIRATION_DATE: '만료일',
  OVER: '경과',
  EXTENSION: '연장',

  SHOP_BY_GUIDE: 'shop by 맞춤 가이드',
  CHAT_BOT: '챗봇 상담',

  SMS_POINT_NOTICE: 'SMS 잔여포인트 부족 안내',
  CURRENT_POINT: '잔여포인트',
  CHARGE: '충전',
  GUIDE_YOUTUBE_TITLE: '동영상 가이드',
  GUIDE_YOUTUBE_DESC1: `자주 묻는 질문들은 유튜브채널 고셀러TV에서`,
  GUIDE_YOUTUBE_DESC2: `동영상으로 쉽고 빠르게 확인해보세요.`,
  GUIDE_YOUTUBE_ANCHOR: '고셀러TV 바로가기',
  GUIDE_YOUTUBE_ERR1: '일시적으로 동영상을 불러올 수 없습니다.',
  GUIDE_YOUTUBE_ERR2: '상단',
  GUIDE_YOUTUBE_ERR3: '버튼을 통해 확인해주세요',

  DAYS_LEFT: '일 남음',
  SECURITY_SERVER: '보안서버',
  DOMAIN_SSL: '도메인/보안서버 관리',
  DOMAIN_SSL_CONNECT_STATUS: '도메인/보안서버 연결상태',
  DOMAIN: '도메인',
});
