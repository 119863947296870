













import { Vue, Component, Model, Ref } from 'vue-property-decorator';
import IpAddressInput from '@/components/common/input/ipAddress/IpAddressInput.vue';

export interface Ip {
  address: string;
  bandwidth: string;
}

export interface IpWrap {
  no: number;
  ip: Ip;
}

let ipNo = 1;

export const getDefaultIpWrap = (): IpWrap => ({
  no: ipNo++,
  ip: {
    address: '',
    bandwidth: '',
  },
});

@Component({
  components: { IpAddressInput },
})
/**
 * 리스트의 유효성 여부를 검증하고자 할 경우 상위컴포넌트에서 validate 를 호출하세요
 * ex) const isPassed = ipAddressInputList.validate()
 */
export default class IpAddressInputList extends Vue {
  @Model('change') private value!: IpWrap[];
  private ips: IpWrap[] = [getDefaultIpWrap()];

  @Ref() private readonly ipAddressInputs: IpAddressInput[];

  created() {
    if (this.value.length > 0) this.ips = this.value;
  }

  private change(): void {
    this.$emit('change', this.ips);
  }

  private changedIpRows(val: number) {
    this.$emit('changedIpRows', val);
  }

  private addRow(): void {
    this.ips.push(getDefaultIpWrap());

    this.changedIpRows(this.ips.length);
    this.$nextTick(() => {
      this.ipAddressInputs[this.ips.length - 1].focus();
    });
  }

  private deleteRow(index: number): void {
    this.ips.splice(index, 1);
    this.changedIpRows(this.ips.length);
    this.change();
  }

  public validate(): boolean {
    return this.ipAddressInputs.every(ipAddressInput => ipAddressInput.validate());
  }
}
