





































import { Vue, Component, Model, Prop } from 'vue-property-decorator';
// components
import TextInput from '@/components/common/input/TextInput.vue';
// types
import { PartnerOffice } from 'ncp-api-supporter';
import { PopupResult, PopupClose, PopupData } from '@/helpers/popup';
// callbacks
import { throwPopup } from '@/helpers/popup';

@Component({
  components: {
    TextInput,
  },
})
export default class AddressInput extends Vue {
  @Prop({ required: false, default: false })
  private readonly disabledAll!: boolean;
  @Model()
  private address!: PartnerOffice;

  private setNewAddress(data: PopupData) {
    const { zipCode, roadAddress, roadAddressExtra, jibunAddress } = data;
    this.address.zipCode = zipCode;
    this.address.address = roadAddress;
    this.address.detailAddress = roadAddressExtra;
    this.address.jibun = jibunAddress;
    this.address.jibunDetail = '';
    this.address.localAddress = '';
  }
  private async openSearchAddressPopup() {
    const popupOption = {
      name: 'SearchAddress',
      data: { keyword: '' },
    };

    const addressResult: PopupResult = await throwPopup(popupOption);
    const isConfirmed = addressResult?.state === PopupClose.CONFIRM;
    if (isConfirmed) this.setNewAddress(addressResult.data);
  }
}
