

















































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { ClaimOption, ClaimOrderOption } from 'ncp-api-supporter';
import { claimStatusTypes, claimTypes } from '@/const/claim';
import { immediateDiscountedAmt } from '@/components/popup/claim/claimFormula';
import {
  isCancelClaim,
  isCancelExchange,
  isCancelProcRequestRefund,
  isExchangeClaim,
  isCancelNoRefund,
  isCancelRequest,
  isReturnRefundAmtAdjustRequested,
  isReturnProcRequestRefund,
  isReturnProcBeforeReceive,
  isReturnRequest,
  isOrderCancel,
} from '@/components/popup/claim/claimConditions';
import { ClaimClassType, ClaimStatusType, ClaimType, ResponsibleObjectType } from '@/types/claim';
import TdReasonType from '@/components/popup/claim/ClaimPopup/ClaimProducts/TdReasonType.vue';
import TdResponsibleType from '@/components/popup/claim/ClaimPopup/ClaimProducts/TdResponsibleType.vue';
import TdClaimProductName from '@/components/popup/claim/TdClaimProductName.vue';
import { openEditProductWindow } from '@/components/product/common/girdColumns/ProductEdit';
import { payTypes } from '@/const/order';

@Component({
  components: { TdResponsibleType, TdReasonType, TdClaimProductName },
  computed: {
    isOrderCancel,
    isCancelRequest,
    isCancelClaim,
    isCancelExchange,
    isCancelNoRefund,
    isCancelProcRequestRefund,
    isExchangeClaim,
    isReturnRequest,
    isReturnProcRequestRefund,
    isReturnProcBeforeReceive,
    isReturnRefundAmtAdjustRequested,
    showHoldDelivery(): boolean {
      return this.isCancelClaim || this.isCancelExchange;
    },
    showModifyButton(): boolean {
      return (
        this.isCancelProcRequestRefund ||
        this.isCancelRequest ||
        this.isReturnRefundAmtAdjustRequested ||
        this.isReturnProcRequestRefund ||
        this.isReturnProcBeforeReceive ||
        this.isReturnRequest
      );
    },
    cancelRowSpan(): number {
      /**
       * 취소 구분 사유 병합
       * - 자동취소: 백엔드쪽에서는 '미입금취소'라고 부른다. 자동취소 = 미입금취소 = 미입금취소는 취소완료[환불없음]로 귀결
       * - 수동취소: 상품(옵션)별 선택 -> 부분취소를 의미
       */
      return this.isCancelNoRefund ? 0 : 1;
    },
  },
  methods: {
    openEditProductWindow,
  },
})
export default class ClaimProducts extends Vue {
  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  @Prop({ required: true })
  private readonly claimClassType!: ClaimClassType;

  @Prop({ required: true })
  private readonly claimStatusType!: ClaimStatusType;

  @Prop({ required: true })
  private readonly originalPayType!: string;

  @Prop({ required: true })
  private readonly claimedOptions!: ClaimOption[];

  @Prop({ required: false, default: null })
  private readonly onPutClaim: (modifyType: 'reason' | 'refundAccount') => Promise<void>;

  @PropSync('claimOrderOptions', { required: true })
  private claimOrderOptionsSync!: ClaimOrderOption[];

  @PropSync('responsibleObjectType', { required: true })
  private responsibleObjectTypeSync!: ResponsibleObjectType;

  private isModifyMode = false;

  private immediateDiscountedAmt = immediateDiscountedAmt;

  private toggleModifyMode() {
    if (this.originalPayType === payTypes.NAVER_PAY) {
      alert(this.$t('CLAIM.MESSAGE.NAVER_PAY_CENTER_PROCESS_ALERT'));
      return;
    }

    this.isModifyMode = !this.isModifyMode;
  }

  private modifyClaimReason() {
    this.onPutClaim('reason').then(() => {
      this.toggleModifyMode();
    });
  }

  private isFirstItem(index: number) {
    return index === 0;
  }

  // 히스토리: https://nhnent.dooray.com/project/posts/2711625814921673057
  private showStockStatus(claimedOption: ClaimOption): string {
    const { restoresStock, claimStatusType } = claimedOption;

    const exceptTargets: ClaimStatusType[] = [
      claimStatusTypes.RETURN_REQUEST,
      claimStatusTypes.RETURN_REJECT_REQUEST,
      claimStatusTypes.RETURN_PROC_BEFORE_RECEIVE,
      claimStatusTypes.EXCHANGE_REQUEST,
      claimStatusTypes.EXCHANGE_REJECT_REQUEST,
      claimStatusTypes.EXCHANGE_PROC_BEFORE_RECEIVE,
    ];
    const includeTargets: ClaimStatusType[] = [
      claimStatusTypes.RETURN_DONE,
      claimStatusTypes.RETURN_NO_REFUND,
      claimStatusTypes.EXCHANGE_DONE_PAY_DONE,
      claimStatusTypes.EXCHANGE_DONE_REFUND_DONE,
      claimStatusTypes.EXCHANGE_DONE,
    ];

    // 프로는 취소교환도 복원라벨 등장 대상이다.
    const isTargetClaim = this.claimType === claimTypes.EXCHANGE || this.claimType === claimTypes.RETURN;
    const isExceptClaimStatusType = exceptTargets.includes(claimStatusType as ClaimStatusType);
    const isTargetClaimStatusType = includeTargets.includes(claimStatusType as ClaimStatusType);

    if (restoresStock && isTargetClaim && !isExceptClaimStatusType) {
      if (isTargetClaimStatusType) {
        return this.$t('CLAIM.COMMON.STOCK_RESTORE_COMPLETE').toString();
      } else {
        return this.$t('CLAIM.COMMON.STOCK_RESTORE_EXPECTED').toString();
      }
    }
  }
}
