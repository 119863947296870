





























import { Vue, Component, Watch } from 'vue-property-decorator';
import { GridEventProps, StatisticSynthesisPeriod } from '@/types';
import { getSalesByProductGridProps, getDefaultParams } from '@/views/contents/statistics/sales/productSales';
import {
  GetStatisticsSalesProductContents,
  GetStatisticsSalesProductRequest,
  GetStatisticsSalesProductSummaryContents,
  GetStatisticsSalesProductSummaryRequest,
} from 'ncp-api-supporter';
import Grid from '@/components/common/grid/Main.vue';
import ProductSalesForm from '@/views/contents/statistics/sales/ProductSalesForm.vue';
import StatisticSynthesis from '@/components/statistics/sales/StatisticSynthesis.vue';
import StatisticNoticeBox from '@/components/statistics/StatisticNoticeBox.vue';
import { generateQuery, onCreateExcel } from '@/views/contents/statistics/sales/statisticUtils';

@Component({
  components: {
    Grid,
    ProductSalesForm,
    StatisticSynthesis,
    StatisticNoticeBox,
  },
})
export default class ProductSales extends Vue {
  private salesByProductGridProps = getSalesByProductGridProps();

  private salesByProductPeriod: null | StatisticSynthesisPeriod = null;
  private salesByProductSummary: null | GetStatisticsSalesProductSummaryContents = null;
  private salesByProductContents: GetStatisticsSalesProductContents[] = [];
  private salesByProductTotalCount = 0;

  @Watch('$route.query')
  private updateQuery() {
    const newQuery = generateQuery(this.$route.query, getDefaultParams);
    return Promise.all([this.fetchSalesProductStatisticsSummary(newQuery), this.fetchSalesProductStatistics(newQuery)]);
  }

  // 상품별 판매현황 종합 fetch
  private async fetchSalesProductStatisticsSummary(queries) {
    const params = { ...queries } as GetStatisticsSalesProductSummaryRequest['params'];
    const res = await this.$api.getStatisticsSalesProductSummary({ params });

    const { summary, startYmd, endYmd } = res.data;
    this.salesByProductPeriod = { startYmd, endYmd };

    this.salesByProductSummary = summary;
  }

  // 상품별 판매현황 목록 fetch
  private async fetchSalesProductStatistics(queries) {
    const params = { ...queries } as GetStatisticsSalesProductRequest['params'];
    const res = await this.$api.getStatisticsSalesProduct({ params });

    const { contents, totalCount } = res.data;

    this.salesByProductContents = contents;
    this.salesByProductTotalCount = totalCount;
  }

  private onItemClicked({ columnName }: GridEventProps) {
    switch (columnName) {
      case 'download':
        onCreateExcel('PRODUCT', { ...this.$route.query });
    }
  }
}
