/*
 * jquery를 대체할 수 있는 간단한 html element util들
 * (http://youmightnotneedjquery.com/)
 * */

// TODO : Element vs HtmlElement
// https://dom.spec.whatwg.org/#interface-element
// https://html.spec.whatwg.org/multipage/dom.html#htmlelement
export function hasClass(elem: Element, cls: string): boolean {
  return elem.classList.contains(cls);
}

export function addClass(elem: Element, cls: string): void {
  elem.classList.add(cls);
}

export function removeClass(elem: Element, cls: string): void {
  elem.classList.remove(cls);
}

export const $el = function($container: Element) {
  return {
    getElement() {
      return $container;
    },
    remove() {
      $container.parentNode.removeChild($container);
    },
    focus() {
      ($container as HTMLElement).focus();
    },
    text(text: string) {
      if (text) {
        ($container as HTMLElement).textContent = text;
      } else {
        return ($container as HTMLElement).innerText; //vs .textContent;
      }
    },
    addClass(className: string) {
      addClass($container, className);
    },
    removeClass(className: string) {
      removeClass($container, className);
    },
    hasClass(className: string) {
      return hasClass($container, className);
    },
    append(element: HTMLElement) {
      $container.appendChild(element);
    },
    attr(attrName: string, value: number | string) {
      if (value) {
        $container.setAttribute(attrName, value as string);
      } else {
        return $container.getAttribute(attrName);
      }
    },
    removeAttr(attribute: string) {
      $container.removeAttribute(attribute);
    },
    find(selector: string) {
      const $$elements = [];
      Array.prototype.forEach.call($container.querySelectorAll(selector), function(childElement) {
        $$elements.push($el(childElement));
      });
      return $$elements;
    },
    prev() {
      return $el($container.previousElementSibling);
    },
    next() {
      return $el($container.nextElementSibling);
    },
    closest(selector: string) {
      let tempEl = $container;
      while (tempEl) {
        if (tempEl.matches(selector)) {
          return $el(tempEl);
        }
        tempEl = tempEl.parentElement;
      }
    },
  };
};

export default $el;
