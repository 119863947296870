// putProductsCategoryMappings

import { i18n } from '@/main';
import { Row } from '@/types/tui-grid';

export const sameMallValidation = (rows: Row[]): boolean => {
  if (!rows.length) {
    alert(i18n.t('ALERT_NO_SELECTED', { value: i18n.t('PRODUCT') }));
    return false;
  }

  const allSame = rows.every(({ mallNo }) => rows[0].mallNo === mallNo);
  if (allSame) return allSame;
  alert(i18n.t('PRODUCT.EDIT.ALERT_BULK_CHANGE_DISPLAY_CATEGORY'));
  return allSame;
};
