



































































































































































































































































































import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import { MemberPopupType, ValidPeriodType } from '@/const/contents/memberPopup';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import DateCalendar from '@/components/common/DateCalendar.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import {
  AccumulationValidPeriodType,
  GetAccumulationsAssemblesRequestNo,
  GetAccumulationsAssemblesRequestNoRequest,
  Mall,
  ManageAccumulationsRequestType,
  ManageReasonType,
  NCPResponse,
  PostAccumulationsAssemblesRequest,
  PutAccumulationsAssemblesRequestNoRequest,
} from 'ncp-api-supporter';
import moment from 'moment';
import { throwWindowPopupWithProps, PopupClose, PopupResult, throwPopup, throwWindowPopup } from '@/helpers/popup';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { RadioBoxGroupOption, ValueType } from '@/helpers/type';
import { addDay, getStrDate, getToday, getUnitDigitStr } from '@/utils/dateFormat';
import { DateCalendarOption, InputNumber } from '@/types';
import { TranslateResult } from 'vue-i18n';
import { cancelAccumulationsPayments } from '@/views/contents/member/accumulation/AccumulationPaymentDeduct';
import NotificationGuide from '@/components/member/NotificationGuide.vue';
import { isValidate } from '@/utils/validate';
import { getMallName, mall } from '@/utils/mall';
import { putAccumulationsAssemblesRequestNoRequestData } from '@/const/contents/accumulationPaymentDeduct';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import AccumulationTargetCountMixin from '@/views/popups/member/AccumulationTargetCountMixin';
import TargetMemberList from '@/components/member/TargetMemberList.vue';

const ONE_YEAR = 12;

@Component({
  components: {
    TargetMemberList,
    NotificationGuide,
    SelectBox,
    MallSelect,
    TextInput,
    RadioGroup,
    ToolTip,
    DateCalendar,
    CheckBoxGroup,
  },
})
export default class AccumulationsAssemblesPopup extends Mixins(WindowPopupMainVue, AccumulationTargetCountMixin) {
  @Ref()
  private readonly expireDateCalendar!: DateCalendar;
  @Ref()
  private readonly paymentTimeDateCalendar!: DateCalendar;
  @Ref()
  private readonly targetMemberButton!: HTMLButtonElement;
  @Ref()
  private readonly reasonInput!: TextInput;
  @Ref()
  private readonly priceInput!: TextInput;
  @Ref()
  private readonly notificationGuideSection!: NotificationGuide;

  private mallList: Mall[] = [];
  private selectedMallNo: InputNumber = '';
  private mallName = '';
  // 지급 수정 데이터
  private paymentData: GetAccumulationsAssemblesRequestNo = {} as GetAccumulationsAssemblesRequestNo;

  private requestTypeOption = 'ADD';
  // 지급 차감
  private requestTypeOptions = {
    name: 'requestTypeOptions',
    data: [
      { id: 'add', value: 'ADD', display: this.$t('MEMBER.POPUP.ACCUMLATE_PAYMENTS') },
      { id: 'sub', value: 'DIRECT_SUB', display: this.$t('MEMBER.POPUP.ACCUMLATE_DEDUC') },
    ],
  };

  private paymentType = true;
  // 지급 유형
  private paymentTypeOptions: RadioBoxGroupOption<boolean> = {
    name: 'paymentTypeOptions',
    data: [
      { id: 'immediately-payment', value: true, display: 'ACCUMULATION.IMMEDIATELY_PAYMENT' },
      { id: 'reservation-payment', value: false, display: 'ACCUMULATION.RESERVATION_PAYMENT' },
    ],
  };

  // 사유
  private reasonText = '';
  private reasonMaxLength = 200;

  // 지금/차감 금액
  private price = '';
  private validateHelper = 0;

  // 유효 기간 설정
  private isValidPeriod = false;
  private paymentDateTime = `${new Date().getHours() + 1 === 24 ? addDay(new Date(), 1) : getToday()} ${getUnitDigitStr(
    new Date().getHours() + 1 === 24 ? 0 : new Date().getHours() + 1,
  )}:00:00`;
  private expireDateTime = '';
  private isValidRadioChecked = ValidPeriodType.VALID_PERIOD_DATE;
  private validDateYear = 1;
  private validDateMonth = 0;

  private paymentTime: DateCalendarOption = {
    dateType: 'YmdH',
    name: 'payment_date',
    fromRanges: new Date().getHours() + 1 === 24 ? addDay(new Date(), 1) : getToday(),
    selectedHour: getUnitDigitStr(new Date().getHours() + 1 === 24 ? 0 : new Date().getHours() + 1),
  };
  private validPeriodSetting: DateCalendarOption = {
    name: 'valid_period_setting',
    fromRanges: this.paymentDateTime || getToday(),
    selectedYmd: '',
  };

  // 알림 설정
  private isNoticeSetOption = false;
  private notificationChannel: ValueType[] = ['SMS', 'EMAIL'];
  private possibleToSend = false;

  get isRegisterConfigType(): boolean {
    return this.data.type === MemberPopupType.ACCUMULATIONS_PAGE;
  }

  get isWaitingStatus(): boolean {
    return this.optionData.accumulationPopupCase.status === 'WAITING';
  }

  // 지급 case2: 수정가능 - 지급대기 && 예약지급
  get isAccumulationPaymentEdit(): boolean {
    return this.isWaitingStatus && !this.paymentData.immediately;
  }

  // 지급 case3: 수정불가(조회만가능)
  get isAccumulationPaymentInquiry(): boolean {
    const inquiryStatus = ['COMPLETED', 'CANCELED', 'PROCESSING'];
    return inquiryStatus.includes(this.paymentData.status) || (this.isWaitingStatus && this.paymentData.immediately);
  }

  get fixMallType(): boolean {
    return this.isAccumulationPaymentEdit || this.isAccumulationPaymentInquiry;
  }

  get isPayment(): boolean {
    return this.requestTypeOption === 'ADD';
  }

  get targetMemberButtonName(): TranslateResult {
    if (!this.isAccumulationPaymentInquiry) {
      return this.$t('MEMBER.SMS_DISPATCH.MEMBER_SEARCH_BTN');
    }
    return this.isPayment ? this.$t('MEMBER.POPUP.PAYMENT_DETAIL') : this.$t('MEMBER.POPUP.DEDUCT_DETAIL');
  }

  get disableMallSelector(): boolean {
    return !this.isRegisterConfigType || mall.isOnlyOneMall;
  }

  get validPeriod(): number {
    return this.isValidPeriod && this.isValidRadioChecked === ValidPeriodType.VALID_PERIOD_DATE
      ? this.validPeriodDateSum
      : 0;
  }

  get selectedExpireDateTime(): string {
    return this.isValidPeriod && this.isValidRadioChecked === ValidPeriodType.EXPIRE_DATE_TIME
      ? `${this.expireDateTime} ${DEFAULT_TIME_RANGE.END}`
      : '';
  }

  get validPeriodType(): AccumulationValidPeriodType {
    return this.isValidPeriod ? this.isValidRadioChecked : 'NONE';
  }

  get selectedRequestTypeOption(): ManageAccumulationsRequestType {
    if (this.requestTypeOption === 'ADD') {
      return this.paymentType ? 'DIRECT_ADD' : 'RESERVE_ADD';
    }
    return this.requestTypeOption as ManageAccumulationsRequestType;
  }

  // 옵션 데이터
  get optionData() {
    return this.data.option.optionData[0];
  }

  // 쇼핑몰 - select box
  get mallNo(): number {
    return this.isRegisterConfigType ? mall.onlyOneMallNo : this.data.mallNo;
  }

  // 레이어 타이틀
  get headerText(): string {
    return this.optionData.headerText ? this.optionData.headerText : this.$t('ALERT');
  }

  // TOP 가이드
  get topNoticeMessage(): object[] {
    return this.optionData.topNoticeMessage ? this.optionData.topNoticeMessage : [];
  }

  // 변경사유 타이틀
  get reasonTitle(): string {
    return this.optionData.reasonData.thTitle ? this.optionData.reasonData.thTitle : '';
  }

  get reasonType(): string {
    if (this.requestTypeOption == 'ADD') {
      return 'ADD_MANUAL';
    } else {
      return 'SUB_MANUAL';
    }
  }

  // 지급 차감 체크에 따른 헤더 타이틀 변경
  get priceTitle(): string {
    if (this.requestTypeOption == 'ADD') {
      return this.$t('MEMBER.POPUP.ACCUMLATE_PAYMENTS') + ' ' + this.$t('MEMBER.POPUP.ACCUMLATE_PRICE');
    } else {
      return this.$t('MEMBER.POPUP.ACCUMLATE_DEDUC') + ' ' + this.$t('MEMBER.POPUP.ACCUMLATE_PRICE');
    }
  }

  get validPeriodValue() {
    return {
      VALID_PERIOD_DATE: ValidPeriodType.VALID_PERIOD_DATE,
      EXPIRE_DATE_TIME: ValidPeriodType.EXPIRE_DATE_TIME,
      NONE: ValidPeriodType.NONE,
    };
  }

  // 유효기간 설정 기간지정 개월 수 계산
  get validPeriodDateSum(): number {
    return Number(this.validDateYear) * ONE_YEAR + Number(this.validDateMonth);
  }

  // 유효기간 설정 기간지정 총 개월 수 표시 문구
  get validPeriodYears() {
    return this.$t('MEMBER.POPUP.VALIDITY_PERIOD_DATE_TEXT2', [this.validPeriodDateSum]) as string;
  }

  private openMemberPopup() {
    if (!this.selectedMallNo) {
      alert(this.$t('MEMBER.POPUP.ALERT_SELECT_MALL'));
      return;
    }

    if (this.isAccumulationPaymentInquiry) {
      const data = {
        no: this.paymentData.no,
        mallName: getMallName(this.selectedMallNo),
        mallNo: this.selectedMallNo,
        date: getStrDate(this.paymentData.registerDateTime.toString()),
      };
      throwWindowPopup('AccumulationManualStatusPopup', data, 'md');
    } else {
      throwWindowPopup(
        'InquiryMember',
        { mallNo: this.selectedMallNo },
        'xlg',
        ({ data, state }: PopupResult) => {
          if (state !== PopupClose.CONFIRM) return;
          this.targetMembers = [{ type: 'MEMBER_NO', values: data.selectedMember.selected }];
          this.targetCount = data.selectedMember.selected.length;
        },
        null,
      );
    }
  }

  private handleExcelBatchClick() {
    if (!this.selectedMallNo) {
      alert(this.$t('MEMBER.POPUP.ALERT_SELECT_MALL'));
      return;
    }

    throwWindowPopupWithProps({
      componentName: 'ExcelBatchRegistrationPopup',
      data: { mallNo: this.selectedMallNo },
      size: 'md',
      popupHeight: 400,
      onClose: ({ state, data: memberNos }) => {
        if (state === 'close') return;
        this.targetMembers = [{ type: 'MEMBER_NO', values: memberNos }];
        this.targetCount = memberNos.length;
      },
    });
  }

  @Watch('validDateYear')
  private checkValidDateYear(_: number, prev: number) {
    if (this.validPeriodDateSum === 0 && this.isValidPeriod) {
      alert(this.$t('MEMBER.POPUP.VALIDITY_PERIOD_DATE_EXCEPTION'));
      this.$nextTick(() => (this.validDateYear = prev));
      return;
    }
  }

  @Watch('validDateMonth')
  private checkValidDateMonth(_: number, prev: number) {
    if (this.validPeriodDateSum === 0 && this.isValidPeriod) {
      alert(this.$t('MEMBER.POPUP.VALIDITY_PERIOD_DATE_EXCEPTION'));
      this.$nextTick(() => (this.validDateMonth = prev));
      return;
    }
  }

  @Watch('isValidPeriod')
  private changeUseValidPeriod(curr: boolean, prev: boolean) {
    const checkSavedValue = this.optionData.accumulationPopupCase.status && this.paymentData.validPeriodType !== 'NONE';
    if (checkSavedValue) return;
    if (curr && curr !== prev) {
      this.isValidRadioChecked = this.validPeriodValue.VALID_PERIOD_DATE;
      this.validDateYear = 1;
    }
  }

  @Watch('paymentType')
  private changePaymentType() {
    if (this.paymentTime) {
      this.validPeriodSetting.fromRanges = getToday();
      this.paymentDateTime = `${
        new Date().getHours() + 1 === 24 ? addDay(new Date(), 1) : getToday()
      } ${getUnitDigitStr(new Date().getHours() + 1 === 24 ? 0 : new Date().getHours() + 1)}:00:00`;
    }
  }

  private isValidateAmount(value: string): string {
    if (this.validateHelper >= 1 || value === '') {
      this.validateHelper = 0;
      return value;
    }
    const price = parseInt(value, 10);
    if (price > 999999990) {
      alert(this.$t('MEMBER.POPUP.ACCUMULATE_AMOUNT_FAIL4'));
      this.focusAmount();
    } else if (price % 10 !== 0) {
      alert(this.$t('MEMBER.POPUP.ACCUMULATE_AMOUNT_FAIL2'));
      this.focusAmount();
    } else if (price === 0) {
      alert(this.$t('MEMBER.POPUP.ACCUMULATE_AMOUNT_FAIL3'));
      this.focusAmount();
    }
    return value;
  }

  private focusAmount() {
    this.priceInput.focus();
    this.validateHelper++;
  }

  private async validatePaymentSetting(): Promise<boolean> {
    if (!isValidate(this.targetCount > 0, this.$t('MEMBER.POPUP.ALERT_PAYMENT_TARGET'))) {
      this.targetMemberButton.focus();
      return false;
    }
    if (!this.paymentType) {
      const beforeCurrentTime = moment().diff(this.paymentDateTime, 'hours', true);
      if (!isValidate(beforeCurrentTime < 0, this.$t('MEMBER.POPUP.ALERT_AFTER_THIS_TIME'))) {
        this.paymentTimeDateCalendar.focus();
        return false;
      }
    }
    if (!isValidate(this.reasonText, this.$t('MEMBER.POPUP.ALERT_REASON'))) {
      this.reasonInput.focus();
      return false;
    }
    if (!isValidate(this.price, this.$t('MEMBER.POPUP.ALERT_AMOUNT'))) {
      this.priceInput.focus();
      return false;
    }
    const price = parseInt(this.price, 10);
    if (price % 10 !== 0) {
      alert(this.$t('MEMBER.POPUP.ACCUMULATE_AMOUNT_FAIL2'));
      this.priceInput.focus();
      return false;
    } else if (price < 10) {
      alert(this.$t('MEMBER.POPUP.ACCUMULATE_AMOUNT_FAIL3'));
      this.priceInput.focus();
      return false;
    }
    const isExpireTime = this.isValidPeriod && this.isValidRadioChecked === ValidPeriodType.EXPIRE_DATE_TIME;
    if (isExpireTime) {
      if (!isValidate(this.expireDateTime, this.$t('MEMBER.POPUP.ALERT_VALID_EXPIRE_PERIOD'))) {
        this.expireDateCalendar.focus();
        return false;
      }
    }
    if (!this.isNoticeSetOption) return confirm(this.$t('MEMBER.POPUP.ACCUMULATE_CONFIRM_SUCCESS').toString());
    if (!isValidate(this.notificationChannel.length, this.$t('MEMBER.POPUP.ALERT_ALARM'))) {
      this.notificationGuideSection.focusCheckbox();
      return false;
    }
    if (!this.possibleToSend) {
      const { state }: PopupResult = await throwPopup({
        name: 'NotificationNotAvailable',
        data: {
          headerText: this.$t('MEMBER.POPUP.NOTIFICATION_HEADER_TEXT'),
          message: this.$t('MEMBER.POPUP.CONFIRM_PAYMENT_ACCUMULATION'),
          isConfirm: true,
          mallNoValue: this.selectedMallNo,
          mallNoMust: true,
          notificationMethodType: this.notificationChannel,
        },
      });
      return state === PopupClose.CONFIRM;
    }

    return confirm(this.$t('MEMBER.POPUP.ACCUMULATE_CONFIRM_SUCCESS').toString());
  }

  @Watch('selectedMallNo')
  private changeSelectedMallNo(_: InputNumber, prev: InputNumber) {
    this.$router.replace({ path: this.$route.fullPath, query: { mallNo: this.selectedMallNo.toString() } });
    if (
      this.targetCount <= 0 ||
      this.disableMallSelector ||
      this.isAccumulationPaymentInquiry ||
      this.isWaitingStatus ||
      this.validateHelper >= 1
    ) {
      this.validateHelper = 0;
      return;
    }
    this.validateHelper++;
    if (!confirm(this.$t('MEMBER.POPUP.ALERT_RESET_MEMBER').toString())) {
      this.$nextTick(() => (this.selectedMallNo = prev));
      return;
    }
    this.paymentData.targets = [];
    this.targetCount = 0;
  }

  private changeSelectedDateTime(requestDateTime: string) {
    this.expireDateTime = requestDateTime;
  }

  private changePaymentTime(paymentTime: string) {
    const paymentDate = moment(paymentTime);
    const existExpireTime = this.isValidPeriod && this.isValidRadioChecked === ValidPeriodType.EXPIRE_DATE_TIME;
    const overExpireTime = moment(this.expireDateTime).diff(paymentTime, 'days') < 0;
    if (existExpireTime && overExpireTime) {
      if (!confirm(this.$t('MEMBER.POPUP.ALERT_RESET_DATE').toString())) {
        this.paymentTimeDateCalendar.setValue(getStrDate(this.paymentDateTime), null, null);
        return;
      }
      this.isValidPeriod = false;
      this.isValidRadioChecked = ValidPeriodType.VALID_PERIOD_DATE;
      this.validPeriodSetting.fromRanges = paymentTime;
      this.expireDateTime = '';
      this.expireDateCalendar.resetValue(true);
      this.expireDateCalendar.unsetDatePicker();
      this.expireDateCalendar.setDatePicker();
    }

    const beforeCurrentTime = moment().diff(paymentDate, 'hours', true);
    if (!isValidate(beforeCurrentTime < 0, this.$t('MEMBER.POPUP.ALERT_AFTER_THIS_TIME'))) {
      this.$nextTick(() => {
        this.paymentTimeDateCalendar.setValue(null, moment(this.paymentDateTime).format('HH'), null);
      });
      return;
    }

    this.paymentDateTime = paymentTime;
    this.validPeriodSetting.fromRanges = this.paymentDateTime;
  }

  private get accumulationsAssemblesRequest(): PostAccumulationsAssemblesRequest {
    return {
      data: {
        expireDateTime: this.selectedExpireDateTime,
        reason: this.reasonType as ManageReasonType,
        amount: parseInt(this.price),
        requestType: this.selectedRequestTypeOption,
        paymentRequestHour: moment(this.paymentDateTime)
          .toDate()
          .getHours(),
        immediately: this.paymentType,
        targets: this.targetMembers,
        validPeriod: this.validPeriod,
        reasonDetail: this.reasonText,
        productDisplayName: '',
        notificationChannels: this.isNoticeSetOption ? this.notificationChannel : [],
        excludeMemberNos: [],
        mallNo: Number(this.selectedMallNo),
        paymentRequestDate: this.paymentType ? '' : getStrDate(this.paymentDateTime),
        validPeriodType: this.validPeriodType,
      },
    };
  }

  private async sendApiRequest(): Promise<void> {
    await this.$api.postAccumulationsAssembles(this.accumulationsAssemblesRequest);
  }

  private async fetchDetailAccumulationPayments() {
    const request: GetAccumulationsAssemblesRequestNoRequest = {
      pathParams: { requestNo: this.optionData.accumulationPopupCase.no },
      params: { mallNo: this.data.mallNo },
    };
    const {
      data,
    }: NCPResponse<GetAccumulationsAssemblesRequestNo> = await this.$api.getAccumulationsAssemblesRequestNo(request);
    const selectedHour = getUnitDigitStr(
      moment(data.requestDateTime)
        .toDate()
        .getHours(),
    );
    this.paymentData = data;
    this.mallName = getMallName(data.mallNo);
    this.selectedMallNo = data.mallNo;
    this.requestTypeOption = data.requestGroup === 'ADD' ? 'ADD' : 'DIRECT_SUB';
    this.paymentType = data.immediately;
    this.paymentTime = { ...this.paymentTime, selectedYmd: getStrDate(data.requestDateTime), selectedHour };
    this.paymentDateTime = getStrDate(data.requestDateTime);
    this.reasonText = data.reasonDetail;
    this.price = data.amount.toString();
    this.isValidPeriod = data.validPeriodType !== 'NONE';
    this.isValidRadioChecked = data.validPeriodType as ValidPeriodType;
    this.validPeriodSetting.selectedYmd =
      data.validPeriodType === ValidPeriodType.EXPIRE_DATE_TIME ? data.expireDateTime : '';
    data.validPeriodType === ValidPeriodType.EXPIRE_DATE_TIME &&
      this.expireDateCalendar.setValue(getStrDate(data.expireDateTime));
    this.validDateYear = Math.floor(data.validPeriod / ONE_YEAR);
    this.validDateMonth = data.validPeriod % ONE_YEAR;
    this.isNoticeSetOption = data.notificationChannels.length > 0;
    this.targetMembers = data.targets;
    this.notificationChannel = data.notificationChannels;
    await this.getTargetCount(this.paymentData.mallNo);
  }

  private async editPaymentSetting() {
    const request: PutAccumulationsAssemblesRequestNoRequest = {
      data: { ...putAccumulationsAssemblesRequestNoRequestData, ...this.accumulationsAssemblesRequest.data },
      pathParams: { requestNo: this.optionData.accumulationPopupCase.no },
    };
    await this.$api.putAccumulationsAssemblesRequestNo(request);
  }

  private async cancelPayment() {
    if (!this.selectedMallNo) return;
    const result = await cancelAccumulationsPayments(this.selectedMallNo, this.optionData.accumulationPopupCase.no);
    if (result) this.onNegativeClick();
  }

  private async onSaveClick() {
    if (!(await this.validatePaymentSetting())) return;

    const isRegisterAccumulations =
      this.optionData.accumulationPopupCase.isTrusted || this.data.type === MemberPopupType.ACCUMULATIONS_MEMBER_LIST;
    if (isRegisterAccumulations) {
      await this.sendApiRequest();
    } else if (this.isAccumulationPaymentEdit) {
      await this.editPaymentSetting();
    }
    alert(this.$t('MEMBER.POPUP.ACCUMULATE_REQUEST_SUCCESS'));
    this.onPositiveClick();
  }

  created() {
    if (this.optionData.accumulationPopupCase.status) {
      this.fetchDetailAccumulationPayments();
    } else if (this.data.type === MemberPopupType.ACCUMULATIONS_MEMBER_LIST) {
      this.targetMembers = [{ type: 'MEMBER_NO', values: this.data.memberNos }];
      this.targetCount = this.data.memberNos.length;
    }
  }

  mounted() {
    this.mallList = mall.mallsSortedByCreationDate;
    this.selectedMallNo = this.disableMallSelector ? this.mallNo : '';
  }
}
