




















































































import { getHref } from '@/utils/route';
import { throwExternalWindowPopup, throwWindowPopup } from '@/helpers/popup';
import ClickOutside from 'vue-click-outside';
import TableTabMenu, { TableTabMenuItem } from '@/components/shipping/order/TableTabMenu.vue';
import Grid from '@/components/common/grid/Main.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import ContentButton from '@/components/shipping/order/ContentButton.vue';
import ContentInvoice from '@/components/shipping/order/ContentInvoice.vue';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { getDefaultMall, getDefaultParams } from '@/components/shipping/order/mixins/searchQuery';
import ContentBottomMixin from '@/components/shipping/order/mixins/ContentBottomMixin';
import { typeMergeRow } from '@/components/shipping/order/mixins/OrderGridsDefaultColumns';
import { FocusTabName, OrderListTypes } from '@/types/order';
import { Getter } from 'vuex-class';
import { mall } from '@/utils/mall';
import { sendQueryString } from '@/utils/query';
import { GridEvent } from '@/types/claim';
import {
  DeliveryCompany,
  GetDeliveryCompaniesRequest,
  GetOrderOptionsDeliveryGrid,
  GetOrderOptionsOrderGrid,
  NCPResponse,
  PutOrderOptionChangeStatusRequest,
} from 'ncp-api-supporter';
import { OrderStatusType } from 'ncp-api-supporter/dist/types/enum';
import { DetailOpenOptions } from 'ncp-api-supporter/dist/types/modules/admin/admin';
import _ from 'underscore';
import { unescape } from 'lodash-es';

@Component({
  components: {
    Grid,
    TableTabMenu,
    SelectBox,
    ContentButton,
    ContentInvoice,
  },
  directives: { ClickOutside },
})
export default class ContentBottom extends Mixins(ContentBottomMixin) {
  @Ref('grid') protected readonly gridRef: Grid;
  @Ref('contentInvoice') protected readonly contentInvoiceRef: ContentInvoice;
  @Getter('admin/getDetailOpenOptions') private detailOpenOptions: DetailOpenOptions;
  @Prop({ required: true }) public orderListTypes!: OrderListTypes;
  @Prop({ required: true }) public orderSearchType!: FocusTabName;

  // tablabel 쿼리가 있으면 fetch가 2번 일어남, 그리드 탭이 있어서 tabLabe 쿼리 쓰는걸로 추정
  @Watch('$route.query')
  private updateQuery() {
    if (
      (this.orderListTypes === 'ORDER_LIST' || this.orderListTypes === 'DELIVERY_PREPARE') &&
      !this.$route.query.tabLabel
    ) {
      this.focusTabName = 'deliveryTab';
      this.onChangeGridTab('deliveryTab');
    }
    this.fetchOrder();
  }
  private query;
  private $gridContainer: HTMLElement | null = null;
  public createExcel = {
    menuKey: '',
    query: {},
    extraInfo: null,
  };
  private tabGridType: string;
  public tabLabel = 'deliveryTab';
  private focusTabName: FocusTabName = this.orderSearchType;
  private showPrintSpecificationMenu = false;
  private menuList: TableTabMenuItem[] = [
    { name: 'deliveryTab', title: this.$t('ORDER.LIST_GRIDS.DELIVERY_TAB') },
    { name: 'orderTab', title: this.$t('ORDER.LIST_GRIDS.ORDER_TAB') },
  ];

  private orderSearchResponse = {
    contents: [],
    totalCount: 0,
  };

  public deliveryCompanyTypeArr: DeliveryCompany[] = [
    {
      deliveryCompanyType: '',
      label: '',
    },
  ];

  public canPrintSpecification = [
    'ORDER_LIST',
    'PAY_DONE',
    'PRODUCT_PREPARE',
    'DELIVERY_PREPARE',
    'DELIVERY_ING',
    'DELIVERY_DONE',
    'BUY_CONFIRM',
  ];

  created() {
    if (this.orderListTypes === 'ORDER_LIST' || this.orderListTypes === 'DELIVERY_PREPARE') {
      this.$route.query.tabLabel
        ? (this.focusTabName = this.$route.query.tabLabel as FocusTabName)
        : sendQueryString(this, { tabLabel: this.orderSearchType }, false);
    }

    this.showPrintSpecificationMenu = this.canPrintSpecification.includes(this.orderListTypes);
    this.tabGridType = this.orderListTypes;
    if (this.orderListTypes === 'ORDER_LIST' || this.orderListTypes === 'DELIVERY_PREPARE') {
      this.tabGridType =
        !this.$route.query.tabLabel || this.$route.query.tabLabel === 'deliveryTab'
          ? this.orderListTypes
          : ((this.orderListTypes + '_TAB2') as OrderListTypes);
    }

    this.tabLabel = (this.$route.query.tabLabel as string) || this.tabLabel;
    this.setGridData(this.tabGridType);
    this.fetchOrder();
  }
  private async fetchOrder(): Promise<void> {
    this.initQuery();

    // this.query.mallNos = !this.query.mallNos || this.query.mallNos === '' ? mall.allMallNoString : this.query.mallNos;
    this.query.claimStatusTypes = this.query.claimType === 'NONE' ? undefined : this.query.claimStatusTypes;

    const request = {
      params: this.query,
    };

    let response: NCPResponse<GetOrderOptionsOrderGrid | GetOrderOptionsDeliveryGrid>;
    this.focusTabName === 'orderTab'
      ? (response = await this.$api.getOrderOptionsOrderGrid(request))
      : (response = await this.$api.getOrderOptionsDeliveryGrid(request));

    await this.setContents(response.data); // await 해야 setCommunicateFailBgColor가 동작한다,, 근데 setContetns는 비동기도 아닌데 왜 해야하는지 이유를 모르겠다.
    this.setCommunicateFailBgColor();
  }

  private setContents(orderResponseData: GetOrderOptionsOrderGrid | GetOrderOptionsDeliveryGrid) {
    if (orderResponseData.contents.length === 0) {
      this.orderSearchResponse.contents = [];
      this.orderSearchResponse.totalCount = orderResponseData.totalCount;
      return;
    }
    let formattedContents = [];
    if (this.focusTabName === 'orderTab') {
      formattedContents = [
        ...this.orderFormatterList(
          (orderResponseData as GetOrderOptionsOrderGrid).contents,
          'orderDeliveryGridDatas',
          'orderProductGridDatas',
          'orderProductOptionGridDatas',
        ),
      ];
    } else {
      formattedContents = [
        ...this.orderFormatterList(
          (orderResponseData as GetOrderOptionsOrderGrid).contents,
          'deliveryOrderGridDatas',
          'orderProductGridDatas',
          'orderProductOptionGridDatas',
        ),
      ];
    }

    const getMergeRow = typeMergeRow(this.focusTabName, this.containerOptions.gridName);
    const bigRow = getMergeRow.bigRow;
    const midRow = getMergeRow.midRow;
    const smallRow = getMergeRow.smallRow;
    bigRow.push('mallNo');
    bigRow.push('platformType');
    bigRow.push('memberGradeName');
    bigRow.push('ordererName');
    bigRow.push('ordererContact1');
    bigRow.push('orderAmt');

    switch (this.focusTabName) {
      case 'deliveryTab':
        this.orderSearchResponse.contents = [
          ...this.orderMergeRow(formattedContents, bigRow, midRow, smallRow, 'shippingNo', 'orderNo', 'mallProductNo'),
        ];
        break;
      case 'orderTab':
        this.orderSearchResponse.contents = [
          ...this.orderMergeRow(formattedContents, bigRow, midRow, smallRow, 'orderNo', 'shippingNo', 'mallProductNo'),
        ];
        break;
    }
    this.orderSearchResponse.totalCount = orderResponseData.totalCount;
    this.setExcelDown();
  }

  private initQuery() {
    this.query = { ...getDefaultParams(this.orderListTypes, this.$route), ...this.$route.query };

    this.query.mallNos = getDefaultMall(this.$route.query.mallNos as string);
    this.query.claimStatusTypes = this.query.claimType === 'NONE' ? undefined : this.query.claimStatusTypes;
  }

  private onChangeGridTab(name: FocusTabName) {
    this.tabLabel = name;
    const routeQuery = { ...this.$route.query };
    const tabGridType = name === 'deliveryTab' ? this.orderListTypes : this.orderListTypes + '_TAB2';
    routeQuery.tabLabel = name;
    sendQueryString(this, routeQuery, false);
    this.setGridData(tabGridType);
  }

  private onGridItemClick({ nativeEvent, columnName, rowKey, instance }: GridEvent) {
    if (columnName === undefined) {
      return;
    }
    const { memberNo, orderNo, orderProductOptionNo, deliveryCompanyType, invoiceNo, mallNo } = instance.getRowAt(
      Number(rowKey),
    );
    switch (columnName) {
      case 'procEachBtn':
        this.onClickProcEachBtn(
          orderNo.toString(),
          Number(orderProductOptionNo),
          (nativeEvent.target as Element).className,
          (nativeEvent.target as Element).id,
          deliveryCompanyType as string,
          invoiceNo as string,
          Number(mallNo),
        );
        break;

      case 'ordererName':
        if (memberNo !== 0) {
          throwExternalWindowPopup(`/pro/crm/${memberNo}`, 'xlg');
        }
        break;

      case 'orderNo':
        this.openOrderDetail(orderNo.toString(), this.orderListTypes);
        break;

      case 'taskMessage':
        this.openOrderDetail(orderNo.toString(), this.orderListTypes);
        break;
    }
  }

  private onClickProcEachBtn(
    orderNo: string,
    orderProductOptionNo: number,
    className: string,
    id: string,
    deliveryCompanyType: string,
    invoiceNo: string,
    mallNo: number,
  ) {
    this.gridRef.checkRow(this.gridRef.getFocusedCell().rowKey);
    const request: PutOrderOptionChangeStatusRequest = {
      data: {
        orderStatusType: id as OrderStatusType,
        options: [
          {
            deliveryCompanyType: deliveryCompanyType,
            invoiceNo: invoiceNo,
            mallNo: mallNo,
            orderOptionNo: orderProductOptionNo,
          },
        ],
        // holdByReservation: this.orderListTypes === 'HOLD_BY_RESERVATION',
      },
    };
    switch (className) {
      case 'changeStatusGroup':
        if (id === 'PAY_DONE') {
          if (!confirm(this.$t('ORDER.LIST_GRIDS.CHANGE_STATUS_CONFIRM') as string)) {
            return;
          }
          this.$api.putPaymentsConfirm([orderNo.toString()]).then(() => {
            alert(this.$t('ORDER.LIST_GRIDS.PAY_CONFIRM_HANDLE_DONE'));
            this.fetchOrder();
          });
        }
        if (id === 'DELIVERY_ING' || id === 'DELIVERY_DONE' || id === 'BUY_CONFIRM') {
          if (!confirm(this.$t('ORDER.LIST_GRIDS.CHANGE_STATUS_CONFIRM') as string)) {
            return;
          }
          const checkedRowList = this.gridRef.getCheckedRows();
          if (id === 'DELIVERY_ING' && this.tabLabel !== 'orderTab') {
            checkedRowList.map(item => {
              item.deliveryCompanyType = this.gridRef
                .getElement(this.gridRef.getFocusedCell().rowKey, 'deliveryCompanyType')
                .querySelector('select').value;
              item.invoiceNo = this.gridRef
                .getElement(this.gridRef.getFocusedCell().rowKey, 'invoiceNo')
                .querySelector('input').value;
            });
          }
          this.gridRef.uncheckRow(this.gridRef.getFocusedCell().rowKey);
          (this.$refs.contentButton as ContentButton).changeStatusProc(id, checkedRowList);
        }
        break;
      case 'changeStatusEach':
        if (!confirm(this.$t('ORDER.LIST_GRIDS.CHANGE_STATUS_CONFIRM') as string)) {
          return;
        }
        this.$api.putOrderOptionChangeStatus(request).then(() => {
          alert(this.$t('ORDER.LIST_GRIDS.PAY_CONFIRM_HANDLE_DONE'));
          this.fetchOrder();
        });
        break;
    }
  }

  private openOrderDetail(orderNo: string, orderListTypes: OrderListTypes) {
    const open = (...args) =>
      window.open(`${getHref('OrderDetailIframe')}?orderNo=${orderNo}&status=${orderListTypes}`, ...args);
    const openOrderDetail = {
      NEW_TAB: () => open('_blank'),
      TAB: () => open('ORDER_DETAIL'),
      WINDOW: () => open('ORDER_DETAIL_WINDOW', 'toolbar=yes,location=yes,menubar=yes'),
      NEW_WINDOW: () => open('_blank', 'toolbar=yes,location=yes,menubar=yes'),
    };

    openOrderDetail[this.detailOpenOptions.ORDER_DETAIL]();
  }

  private async getDeliveryCompanies() {
    const request: GetDeliveryCompaniesRequest = {
      params: {
        countryCd: 'KR',
      },
    };
    const response: NCPResponse<DeliveryCompany[]> = await this.$api.getDeliveryCompanies(request);
    this.deliveryCompanyTypeArr = response.data; // 오픈스펙에서 제외 CJ 만 제공
  }

  private isFirstCheck = false;
  private onCheck(event) {
    if (
      this.isFirstCheck ||
      (this.tabLabel === 'orderTab' && this.orderListTypes !== 'DEPOSIT_WAIT') ||
      this.orderListTypes === 'PAY_DONE' ||
      this.orderListTypes === 'PRODUCT_PREPARE'
    ) {
      return;
    }
    const { rowKey, checkType } = event;
    const key = this.orderListTypes === 'DELIVERY_PREPARE' ? 'shippingNo' : 'orderNo';
    if (this.hasGroup(rowKey, key)) {
      const groupRowKeys = this.getRowKeysOfGroupByKey(rowKey, key).filter(groupRowKey => groupRowKey !== rowKey);

      this.toggleGroupCheck(groupRowKeys, checkType);
    }
  }
  private hasGroup(rowKey, key = 'orderNo') {
    const targetRow = this.gridRef.getRowAt(rowKey);
    if (targetRow === null) {
      return false;
    }
    const orderNoRows = this.gridRef.getData().filter(rowData => rowData[key] === targetRow[key]);
    return orderNoRows.length > 1;
  }

  private getRowKeysOfGroupByKey(rowKey, key = 'orderNo') {
    const checkedRow = this.gridRef.getRowAt(rowKey);
    const data = this.gridRef.getData();

    return data.reduce(
      (acc, currentOrderData: any) =>
        currentOrderData[key] === checkedRow[key] ? [...acc, currentOrderData.rowKey] : acc,
      [],
    );
  }

  private toggleGroupCheck(groupRowKeys: number[], nextCheckedStatus: 'check' | 'uncheck') {
    groupRowKeys.forEach(rowKey => {
      this.isFirstCheck = true;

      nextCheckedStatus === 'check' ? this.gridRef.checkRow(rowKey) : this.gridRef.uncheckRow(rowKey);
    });
    this.isFirstCheck = false;
  }

  private handleSmsSendClick(sendType: 'SELECT' | 'SEARCH') {
    const orders = sendType === 'SELECT' ? this.gridRef.getCheckedRows() : this.orderSearchResponse.contents;

    this.openSmsSendPopup(sendType, orders);
  }

  private openPopupPrintSpecification() {
    if (this.orderSearchResponse.contents.length === 0) {
      alert(this.$t('ORDER.LIST_GRIDS.EXCEL_NO_DATA_ALERT'));
      return;
    }

    let printSpecification = [];
    const request = {
      params: this.query,
    };

    // 임시로 <br /> 태그 삭제하도록 처리
    const extractTextPattern = /(<([^>]+)>)/gi;
    const replaceExtractText = (text = '') => text.replace(extractTextPattern, '');

    this.$api.getOrderOptionsExcel(request).then(res => {
      const data = res.data.map(({ optionName, optionValue, productName, productNameEn, ...rest }) => ({
        ...rest,
        optionName: replaceExtractText(unescape(optionName)),
        optionValue: replaceExtractText(unescape(optionValue)),
        productName: replaceExtractText(unescape(productName)),
        productNameEn: replaceExtractText(unescape(productNameEn)),
      }));

      if (this.gridRef.getCheckedRowKeys().length === 0) {
        printSpecification = data.filter(pItem => {
          return this.canPrintSpecification.includes(pItem.orderStatusType as string);
        });
      } else {
        printSpecification = this.gridRef.getCheckedRows().filter(pItem => {
          return this.canPrintSpecification.includes(pItem.orderStatusType as string);
        });
      }
      printSpecification = printSpecification.filter(item => {
        return item.claimStatusType === null;
      });

      const shippingNos = printSpecification.map(pItem => {
        return pItem.shippingNo;
      });

      if (shippingNos.length > 0) {
        const orderStatus = this.$route.name !== 'OrderList' ? this.$route.query.orderStatusType : undefined;
        throwWindowPopup('OrderSpecificationPopup', { shippingNos, orderStatus }, 'lg');
      } else {
        alert(this.$t('ORDER.LIST_GRIDS.NO_ORDERNO_ALERT'));
      }
    });
  }

  private setExcelDown() {
    this.createExcel.menuKey = this.$route.meta.excelCreateMenuKey;
    if (this.orderListTypes === 'ORDER_LIST') {
      if (this.tabLabel === 'deliveryTab') {
        this.createExcel.menuKey = 'PRO_ORDER_LIST_BY_DELIVERY_NO';
      } else {
        this.createExcel.menuKey = 'PRO_ORDER_LIST_BY_ORDER_NO';

        this.createExcel.extraInfo = this.hasNaverPayOrder(this.orderSearchResponse.contents)
          ? 'hasNaverPayOrder'
          : null;
      }
    }
    if (this.orderListTypes === 'DELIVERY_PREPARE') {
      if (this.tabLabel === 'deliveryTab') {
        this.createExcel.menuKey = 'PRO_DELIVERY_PREPARE_BY_DELIVERY_NO';
      } else {
        this.createExcel.menuKey = 'PRO_DELIVERY_PREPARE_BY_ORDER_NO';

        this.createExcel.extraInfo = this.hasNaverPayOrder(this.orderSearchResponse.contents)
          ? 'hasNaverPayOrder'
          : null;
      }
    }

    const isNoQuery =
      _.isEmpty(this.$route.query) || (Object.keys(this.$route.query).length === 1 && this.$route.query['tabLabel']);
    this.createExcel.query = isNoQuery
      ? { ...getDefaultParams(this.orderListTypes) }
      : { ...getDefaultParams(this.orderListTypes), ...this.$route.query };
    (this.createExcel.query as any).mallNos =
      !this.query.mallNos || this.query.mallNos === '' ? mall.allMallNoString : this.query.mallNos;
  }

  hasNaverPayOrder(contents) {
    return contents.some(({ payType }) => payType === 'NAVER_PAY');
  }

  mounted() {
    this.getDeliveryCompanies();
    this.$nextTick(() => {
      this.bindEvents();
    });
  }

  destroyed() {
    this.removeEvents();
  }

  private bindEvents(): void {
    this.$gridContainer = document.querySelector('.tui-grid-container');
    this.$gridContainer.addEventListener('change', this.onInputChange);
    this.$gridContainer.addEventListener('input', this.keyUpInvoiceText);
  }

  private removeEvents(): void {
    this.$gridContainer.removeEventListener('change', this.onInputChange);
  }

  private onInputChange(event: Event): void {
    const target = event.target as any;
    const targetSelectors = ['invoiceNoInput', 'companySelect'];

    if (!targetSelectors.includes(target.classList[0])) {
      return;
    }

    const { rowKey } = target.dataset;
    if (target.classList.contains('invoiceNoInput')) {
      this.gridRef.setValue(rowKey, 'invoiceNo', target.value);
      return;
    }
    return;
  }

  private keyUpInvoiceText(event: Event) {
    const regex = /[^0-9a-zA-Z]/g; // 영문 대소문자, 숫자만 가능 (공백 불가);
    if (regex) (event.target as any).value = (event.target as any).value.replace(regex, '');
  }

  private setCommunicateFailBgColor() {
    this.orderSearchResponse.contents.forEach(({ rowKey, communicateFail }) => {
      if (communicateFail) {
        this.gridRef.addRowClassName(rowKey, 'fail-order');
      }
    });
  }
}
