




































































































































import { Component, Mixins } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
// components
import NoticeBox from '@/components/common/NoticeBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
// utils
import { saveBinaryDataToFile } from '@/utils/fileSaver';
import { GetContractFiles } from 'ncp-api-supporter';
import FileInputMixin from '@/components/common/input/fileInput/FileInputMixin.vue';

@Component({
  components: { NoticeBox, TextInput },
})
export default class PartnerContractFilesPopup extends Mixins(WindowPopupMainVue, FileInputMixin) {
  private DOMESTIC_DOCUMENT_DEFAULT_POINT = 3;
  private contractFiles: GetContractFiles = {
    globalDocuments: [],
    domesticDocuments: [],
  };

  private get defaultDomesticDocuments() {
    return this.contractFiles.domesticDocuments.slice(0, this.DOMESTIC_DOCUMENT_DEFAULT_POINT);
  }

  private get additionalDomesticDocuments() {
    return this.contractFiles.domesticDocuments.slice(this.DOMESTIC_DOCUMENT_DEFAULT_POINT);
  }

  private get hasAttachedFiles() {
    const hasDomesticDocAttached = this.contractFiles.domesticDocuments.some(doc => doc.originalName);
    const hasGlobalDocAttached = this.contractFiles.globalDocuments.some(doc => doc.originalName);
    return hasDomesticDocAttached || hasGlobalDocAttached;
  }

  //NOTE: return 을 string 으로 받음.
  private get isDomestic() {
    return this.contractFiles.domesticDocuments.length > 0 ? 'true' : 'false';
  }

  private drawContractName(title, defaultTitle) {
    return title ?? defaultTitle;
  }
  private drawFileButtonName(isFileName: boolean) {
    return isFileName ? this.$t('PARTNER.POPUP.UPDATE_CONTRACT_FILE') : this.$t('PARTNER.POPUP.ADD_CONTRACT_FILE');
  }

  private validateFile(file): boolean | FormData {
    const fileElements = file.name.split('.');
    const fileExe = fileElements[fileElements.length - 1];
    //NOTE 확장자 확인필요 : 프로 쇼핑몰 수정 계약서류랑 파트너 등록시 계약서류랑 가능한 확장자가 상이함
    const exes = ['doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'gif', 'png', 'pdf'];

    if (!exes.includes(fileExe)) {
      alert(this.$t('MALL.FORM.INCORRECT_FILE'));
      return false;
    }

    if (!this.validateFileSize(file)) {
      alert(this.$t('PRODUCT.ADD.IMAGE_MAX_SIZE'));
      return false;
    }
    return true;
  }

  private async uploadContractFile(e, doc, originTitle, displayOrder) {
    const [file] = e.target.files;
    if (!this.validateFile(file)) return;

    doc.originalName ? this.updateContractFile(file, doc, originTitle) : this.addContractFile(file, displayOrder);
  }

  private async updateContractFile(file, doc, originTitle) {
    if (!confirm(this.$t('PARTNER.POPUP.ALERT_UPDATE_CONTRACT_FILE') as string)) return;

    const formData = new FormData();
    formData.append('file', file);

    const { uploadedName, title } = doc;
    const requestParams = {
      contractNo: this.data.contractNo,
      uploadedName,
      title: title ?? originTitle,
    };
    try {
      await this.$api.putContractsFiles({ params: requestParams, data: formData });
      alert(this.$t('PARTNER.POPUP.ALERT_UPDATE_CONTRACT_FILE_COMPLETE') as string);
      this.getContractsFiles();
    } catch (e) {
      alert(e.data.message);
    }
  }

  private async addContractFile(file, displayOrder) {
    const data = new FormData();
    data.append('file', file);
    data.append('mallNo', this.data.mallNo);
    data.append('domestic', this.isDomestic);
    data.append('displayOrder', displayOrder);
    try {
      await this.$api.postStorageContracts({ params: { contractNo: this.data.contractNo }, data });
      this.getContractsFiles();
    } catch (e) {
      alert(e.data.message);
    }
  }

  private async downloadContractFile(fileName: string, saveAs: string) {
    if (!fileName) return;
    const { data: storageContracts } = await this.$api.getStorageContracts({ params: { fileName, saveAs } });
    saveBinaryDataToFile(storageContracts, saveAs);
  }

  private downloadAll() {
    const files = Object.values(this.contractFiles).flat();
    files.forEach(doc => this.downloadContractFile(doc.uploadedName, doc.originalName));
  }

  private async getContractsFiles() {
    const { data: contractFiles } = await this.$api.getContractsFiles({ params: { contractNo: this.data.contractNo } });
    this.contractFiles = contractFiles;
  }

  created() {
    this.getContractsFiles();
  }
}
