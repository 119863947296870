export default Object.freeze({
  // SMS 관리 > SMS 관리
  PAGE_TITLE: 'SMS 관리',
  TAB_DISPATCH: 'SMS 발송',
  TAB_USE_CONFIG: '사용설정',
  TAB_TEMPLATECONFIG: 'SMS 템플릿 설정',
  TAB_AUTOSMS: '자동 SMS 설정',
  AUTO_SMS_TYPE: '자동 SMS 유형',
  AUTO_NOTIFICATION_TYPE: '자동 알림톡 유형',
  SEND_DETAIL: '발송 상세설정',
  TAB_SENDLIST: 'SMS 발송내역 조회',
  TAB_RESERVATION_CONFIG: 'SMS 예약발송 관리',
  MESSAGETYPE_LABEL: '발송 유형',
  MESSAGETYPE_ALL: '전체',
  MESSAGETYPE_SMS: 'SMS',
  MESSAGETYPE_LMS: 'LMS',
  USAGELIST_NOTICE1: 'ⓘ 자동 SMS 발송 시 사용한 금액을 조회할 수 있습니다.',
  USAGELIST_NOTICE2: 'Ⓘ 매달 7일 이후에 전월의 사용량을 확인할 수 있습니다.',
  SUMMARY_TABLE_TITLE: '총 사용금액 합계',
  DETAIL_TABLE_TITLE: '사용금액 상세',
  USE_CONFIG_NOTICE_1: 'SMS 발송 기능을 사용하시려면, SMS 발송 항목을 사용함으로 설정해주세요.',
  USE_CONFIG_NOTICE_2: 'SMS 발송 기능을 사용하시려면, SMS 포인트를 충전해 주세요.',
  USE_CONFIG_NOTICE_3: 'SMS 발송 건당 1포인트 차감되며, 발송실패건은 포인트로 복구됩니다.',
  USE_CONFIG_NOTICE_4: '90byte가 넘어가는 경우 LMS로 자동 발송 되며, 건당 3포인트 차감 됩니다.',
  USE_CONFIG_NOTICE_5: '발송번호는 고객에게 발송되는 발신자 번호로 사용됩니다.',
  DISPATCH_NOTICE_1: '광고성/정보성 SMS를 발송할 수 있습니다.',
  DISPATCH_NOTICE_2: "광고성 SMS 발송은 '080 수신거부 번호'가 등록되어 있어야 사용이 가능합니다.",
  DISPATCH_NOTICE_3:
    '광고성 SMS 발송 시 관련 법령에 따라 적절한 조치를 취하여 발송해야 합니다. 상세 유의사항은 <a class="notice_button">[여기]</a>에서 확인 가능합니다.',
  AUTO_SEND_NOTICE_1: '자동 SMS 발송을 설정할 수 있습니다.',
  AUTO_SEND_NOTICE_2: '자동 SMS 발송은 사용함/사용 안 함으로 발송 여부를 설정할 수 있습니다.',
  AUTO_SEND_NOTICE_4:
    '\'개인정보 이용내역 안내\' 템플릿을 사용하실 경우 매월 1일마다 통지 대상에게 안내가 발송됩니다. <a class="terms_tooltip">더보기</a>',
  AUTO_SEND_NOTICE_5:
    '\'광고성 SMS 수신현황안내\' 템플릿을 사용하실 경우 매월 1일 통지 대상에게 안내가 발송됩니다. <a class="ad_sms_tooltip">더보기</a>',
  AUTO_SEND_NOTICE_6:
    '관계 법령에 따라 정보주체 외 개인정보 수집(간편 로그인/가입, 회원연동) 시 수집출처 등을 안내하여야 합니다. 상세 내용은 <a class="oauth_type_button">[여기]</a>에서 확인 가능합니다.<br><br>',
  AUTO_SEND_NOTICE_7:
    '카카오알림톡 기능을 함께 사용하실 경우, 동일한 발송항목을 SMS, 알림톡 모두 사용함으로 설정하더라도 알림톡이 우선으로 발송됩니다.<br> 단, 알림톡 발송 실패 시 알림톡 템플릿에 저장된 내용으로 SMS/LMS 대체 발송됩니다.',
  AUTO_SEND_NOTICE_8:
    'ex) ‘회원가입완료’ 항목이 SMS:사용함, 알림톡:사용함 일 경우 알림톡만 발송. 단, 알림톡 발송 실패 시 알림톡 템플릿에 저장된 내용으로 SMS/LMS 발송',
  TERMS_NOTICE_TITLE: '개인정보 이용내역 안내 템플릿 사용 안내',
  TERMS_NOTICE_TEXT_1:
    '개인정보 보호법 제39조의8에 따라 수집한 이용자의 개인정보 이용내역을 주기적으로 이용자에게 통지하여야 합니다.',
  TERMS_NOTICE_TEXT_2:
    '아래 항목 중 한가지 이상에 해당하는 법적 의무대상자는 반드시 개인정보 이용내역을 통지하고 수신동의 여부를 확인하여야 합니다.<br/>' +
    '1. 정보통신서비스 부문 전년도(법인인 경우에는 전 사업연도를 말한다) 매출액이 100억원 이상인 정보통신서비스 제공자등<br/>' +
    '2. 전년도 말 기준 직전 3개월간 그 개인정보가 저장ㆍ관리되고 있는 이용자 수가 일일평균 100만명 이상인 정보통신서비스 제공자등',
  TERMS_NOTICE_TEXT_3:
    '개인정보 이용내역 통지 시, 아래 항목들을 명시하시기 바랍니다.<br/>' +
    '1. 개인정보의 수집ㆍ이용 목적 및 수집한 개인정보의 항목<br/>' +
    '2. 개인정보를 제공받은 자와 그 제공 목적 및 제공한 개인정보의 항목',
  AD_NOTICE_TITLE_SMS: '광고성 SMS 수신현황안내 템플릿 사용 안내',
  AD_NOTICE_TITLE_MAIL: '광고성 정보 수신현황안내 템플릿 사용 안내',
  AD_NOTICE_TEXT_1: '정보통신망법 제50조에 따라 2년마다 광고성 정보 수신자의 수신동의 여부를 확인하여야 합니다.',
  AD_NOTICE_TEXT_2:
    '수신동의 여부 확인 시, 아래 항목들을 명시하시기 바랍니다.<br/>' +
    '1. 전송자의 명칭<br/>' +
    '2. 수신자의 수신동의 사실과 수신에 동의한 날짜<br/>' +
    '3. 수신동의에 대한 유지 또는 철회의 의사를 표시하는 방법',
  REMAINING_POINT: 'SMS 잔여 포인트',
  USE_CONFIG: 'SMS 사용설정',
  POINT: '포인트',
  INQUIRY: '조회하기',
  CHARGE_POINT: 'SMS 포인트 충전하기',
  RETRY_MESSAGE: '다시 한 번 시도해주세요.',
  TYPE: 'SMS 유형',
  SEND_TYPE: '발송 유형',
  TYPE_SMS: 'SMS (90byte)',
  TYPE_LMS: 'LMS (2,000byte)',
  CALLING_NUMBER: '발신 번호',
  NO_CALLING_NUMBER: '등록된 발신번호가 없습니다.',
  MUST_REGISTER_CALLING_NUMBER: '사용설정에서 발신번호를 먼저 등록해주세요.',
  MUST_USAGE: 'SMS가 ‘사용 안 함’상태입니다. 사용설정 후 다시 시도해주세요.',
  MUST_HAVE_DEST: '수신대상을 선택해주세요.',
  CONFIRM_SEND: '선택된 {0}명에게 발송하시겠습니까?',
  NOT_CAN_SEND: '전송가능한 회원이 없습니다.',
  CHANGE_CALLING_NUMBER: '발신번호 변경',
  CHANGE_CALLING_NUMBER_CONFIRM: '발신번호 등록을 위해 사용설정으로 이동하시겠습니까?',
  SELECT_DEST: '수신 대상 선택',
  SELECT_DEST_NOTICE_1: '회원이 {all}명 선택되었습니다.',
  SELECT_DEST_NOTICE_2: '(수신거부 대상자 {reject}명 포함)',
  SELECT_DEST_NOTICE_3: '광고성인 경우, 광고성 수신 미동의 회원은 발송 시 제외됩니다.',
  SELECT_DEST_NOTICE_4: '전체 회원 선택 시 휴면회원 및 탈퇴회원은 수신 대상에서 제외됩니다.',
  DIRECT_NOTICE_1:
    '수신 대상으로 ‘직접 입력’으로 선택할 경우, 수신번호의 회원여부 및 수신동의 여부와 무관하게 발송처리됩니다.',
  DIRECT_NOTICE_2:
    '비회원에게 SMS를 발송하기 위해서는 사전에 휴대폰번호에 대한 개인정보 수집동의가 필요하며, ‘광고성’ SMS발송 시에는 발송대상으로부터 광고성 수신동의를 받아야 하므로 발송 시 유의하시기 바랍니다.',
  DIRECT_NOTICE_3:
    '사전에 개인정보 수집동의 또는 광고성 수신동의를 받지 않아 발생하는 불이익에 대하여 당사는 책임을 지지 않습니다.',
  EXCEL_NOTICE:
    '엑셀 업로드를 통해 비회원에게 SMS를 발송할 경우, 수신번호의 회원여부 및 수신동의 여부와 무관하게 발송처리됩니다.',
  ALERT_EXCEL: '엑셀 양식이 올바르지 않습니다. 파일 확인 후 첨부해 주세요.',
  ALERT_SAVE: '저장되었습니다.',
  ALL_MEMBER: '전체 회원',
  SELECT_MEMBER: '회원 선택',
  DIRECT_INPUT: '직접 입력',
  SEND_CONFIG: '발송 설정',
  IMMEDIATELY_SEND: '즉시 발송',
  RESERVATION_SEND: '예약 발송',
  TITLE: '제목',
  TITLE_PLACEHOLDER: '제목을 입력해주세요.',
  CONTENT: '내용',
  CONTENT_PLACEHOLDER: '내용을 입력해 주세요.',
  INFO: '정보성',
  AD: '광고성',
  SAVE: '저장',
  EDIT: '편집하기',
  PREVIEW: '미리보기',
  USED_TEMPLATE: '사용중인 템플릿',
  CALL_DESC_1: '유선 전화번호 : 02-YYY-YYYY (지역 번호 포함하여 등록)',
  CALL_DESC_2: '대표 전화번호 : 15YY, 16YY, 18YY(번호 앞에 지역 번호 사용 금지)',
  CALL_DESC_3: '공통 서비스 식별 번호 : 0N0 계열(번호 앞에 지역 번호 사용 금지)',
  CALL_DESC_4: '최소 8자리 ~ 최대 11자리까지의 발신 번호 입력 가능',
  CALL_DESC_5: '존재하지 않는 번호 대역으로 메시지 전송 불가(예:070-0YYY, 070-1YYY, 010-0YYY, 010-1YYY)',

  // SMS 발신번호 목록
  CALLING_NUMBER_LIST: 'SMS 발신번호 목록',
  NO: '번호',
  SEND_NO: '발신번호',
  REGISTER_SEND_NO: '발신번호 등록',
  SELECT_SEND_NO: '발신번호 선택',
  ALERT_SELECT_SEND_NO: '발신번호를 선택해주세요.',

  // sms template string
  MALL_NAME: '쇼핑몰명',
  MEMBER_NAME: '회원명',
  TEMP_PWD: '임시비밀번호',
  SERVICE_CENTER_TEL: '고객센터전화번호',
  BAN_START_DATE: '이용정지시작일',
  BAN_END_DATE: '이용정지종료일',
  INACTIVE_DATE: '휴면회원처리예정일',
  SMS_AGREE_DATE: 'SMS수신동의설정 최종변경일',
  POINT_NAME: '적립금명',
  POINT_DEL_MONTH: '소멸예정월',
  POINT_DEL_DATE: '적립금 소멸예정일',
  POINT_DEL_TOTAL: '소멸예정 적립금 합계',
  BANK_NAME: '은행명',
  ACCOUNT_HOLDER: '예금주명',
  ACCOUNT: '결제계좌번호',
  TOTAL_PAY_AMT: '적립금+실결제금액',
  SUB_PAY_AMT: '적립금',
  MAIN_PAY_AMT: '실결제금액',
  DEPOSIT_DATE: '입금기한',
  DEPOSIT_DATE_SHORT: '입금기한 축약형',
  DEPOSIT_DATE_SHORT_EXPECT_YEAR: '입금기한축약형(년도제외)',
  PRODUCT_MAIN_NAME: '주문대표상품명',
  PRODUCT_COUNT: '상품수',
  PRODUCT_MAIN_DELIVERY: '배송대표상품명',
  DELIVERY_NAME: '택배사명',
  DELIVERY_NUMBER: '송장번호',
  RETURN_PRODUCT: '반품대표상품명',
  MEMBER_ID: '회원아이디',
  SMS_AGREED_ACCEPT: 'sms수신동의여부',
  SMS_AGREED_DATE: 'sms수신동의일',
  EMAIL_AGREED: '이메일수신동의여부',
  EMAIL_AGREED_DATE: '이메일수신동의일',
  ORDERER_NAME: '주문자명',
  ORDER_NO: '주문번호',
  DOMAIN: '쇼핑몰대표도메인',
  CERT_CODE: '인증번호',
  EXCHANGE_PRODUCT: '교환반품대표상품명',
  GRADE_NAME: '회원등급명',
  EXCEL_MEMBER_NAME: '회원명, 엑셀 업로드 항목 중 이름',
  ORDER_NAME: '주문자명',
  PC_WEB_DOMAIN: 'PC 웹 도메인',
  MOBILE_WEB_DOMAIL: '모바일 웹 도메인',
  MEMBER_ID_SMS: '회원 아이디',
  CERT_NO: '비밀번호찾기 인증번호',
  EXPIRE_YMDT: '비밀번호찾기 만료일시',
  CHANGE_PWD_YMDT: '비밀번호 변경일시',
  NOT_MEMBER_TEMP_PWD: '임시 비회원 주문 비밀번호',
  RESET_PWD_LINK: '회원 비밀번호 초기화 링크',
  CERT_EMAIL: '이메일 인증 코드',
  PROHIBITION_REASON: '이용정지 사유',
  INACTIVE_YMDT: '휴면회원처리예정일시',
  OAUTH_TYPE: '개인정보 수집출처',
  EXPEL_YMDT: '강제탈퇴 일시',
  EXPEL_REASON: '강제탈퇴 사유',
  SMS_AGREED: 'SMS 수신동의여부',
  SMS_AGREE_YMDT: 'SMS수신동의설정 최종변경일시',
  DIRECT_MAIL_AGREED: 'e메일 수신동의여부',
  DIRECT_MAIL_AGREED_YMDT: 'e메일 수신동의설정 최종변경일시',
  PUSH_NOTIFICATION_AGREED: '푸시 수신동의여부',
  PUSH_NOTIFICATION_AGREED_YMDT: '푸시 수신동의설정 최종변경일시',
  NOTIFICATION_DATE: '개인정보이용내역 안내 일시',
  ACCUMULATION_NAME: '적립금명',
  ACCUMULATIONS: '소멸예정 적립금 개수',
  ACCUMULATION_EXPIRE_YMDT: '소멸예정 적립금 일시',
  ACCUMULATION_AMT: '소멸예정 적립금 금액',
  BANK_ACCOUNT: '가상계좌번호',
  LIMIT_PAY_YMDT: '가상계좌 입금기한 일시',
  REGISTER_YMDT: '주문일시',
  DEPOSIT_DATETIME: '입금확인일시',
  PRODUCT_NAME: '상품명',
  OPTION_NAME: '옵션명',
  USER_INPUT_TEXT: '구매자작성형 옵션',
  DISCOUNTED_PRICE: '할인적용가',
  ORDER_CNT: '주문수량',
  IMMEDIATE_DISCOUNTED_AMT: '상품합계 금액',
  DELIVERY_AMT: '배송비',
  DISCOUNT_AMT: '할인금액',
  PAY_TYPE_LABEL: '결제수단명',
  RECEIVER_NAME: '수령자명',
  RECEIVER_CONTACT: '수령자 연락처',
  RECEIVER_ADDRESS: '수령자 주소',
  REFUND_PRODUCT_AMT: '환불 상품금액',
  REFUND_DELIVERY_AMT: '환불 배송비금액',
  REFUND_SUBTRACTION_AMT: '환불 차감금액',
  REFUND_AMT: '환불금액',
  REFUND_SUB_PAY_AMT: '적립금 환불금액',
  ADDITIONAL_MALL_INFO: '몰 추가정보(API 기준)',
  INQUIRY_CONTENT: '1:1문의 문의내용',
  ANSWER_CONTENT: '1:1문의 답변내용',
  INQUIRY_CD: '상품문의 문의유형',
  INQUIRY_REGISTER_YMDT: '상품문의 문의일시',
  MALL_PRODUCT_NO: '상품문의 상품번호',
  PRODUCT_INQUIRY_NAME: '상품문의 상품명',
  INQUIRY_PRODUCT_CONTENT: '상품문의 문의내용',
  INQUIRY_PRODUCT_ANSWER: '상품문의 답변내용',
  INQUIRY_REPLY_YMDT: '상품문의 답변일시',
  MALL_SERVICE_CENTER_NO: '쇼핑몰 고객센터 전화번호',
  SERVICE_COMPANY_NAME: '서비스 회사명',
  SERVICE_REPRESENTATIVE_NAME: '서비스 대표명',
  SERVICE_BUSINESS_REGISTRATION_NO: '서비스 사업자등록번호',
  SERVICE_ONLINE_MARKETING_BUSINESS_DECLARATION_NO: '서비스 통신판매업신고번호',
  SERVICE_PRIVACY_MANAGER_NAME: '서비스 개인정보관리책임자 명',
  SERVICE_ZIP_CD: '서비스 주소우편번호',
  SERVICE_NEW_ADDRESS: '서비스 신주소',
  SERVICE_NEW_ADDRESS_DETAIL: '서비스 신주소상세',
  SERVICE_JIBUN_ADDRESS: '서비스 지번주소',
  SERVICE_JIBUN_ADDRESS_DETAIL: '서비스 지번주소 상세',

  EXAMPLE_MALL_NAME: '샵바이프로몰',
  EXAMPLE_MEMBER_NAME: '홍길동',
  EXAMPLE_BAN_START_DATE: '2017년08월11일',
  EXAMPLE_POINT_NAME: '마일리지',
  EXAMPLE_POINT_DEL_DATE: '2017-08-11',
  EXAMPLE_BANK_NAME: '신한은행',
  EXAMPLE_PRODUCT: '뽀송뽀송 아기 속옷',
  EXAMPLE_YMDT: '2017년08월11일 15시 20분',
  EXAMPLE_AGREED: '동의/비동의',
  EXAMPLE_TEMP_PWD: 'password12!!',
  EXAMPLE_TEMP_PWD_DETAIL: 'password12!!\n회원 임시비밀번호 발송, 비회원 주문 임시비밀번호 발송',
  EXAMPLE_SERVICE_CENTER_TEL: '02-0000-0000',
  EXAMPLE_POINT_DEL_MONTH: '8월',
  EXAMPLE_DELIVERY_NAME: 'CJ대한통운',
  EXAMPLE_DEPOSIT_DATE_SHORT_EXPECT_YEAR: '03월 07일',
  EXAMPLE_PROHIBITION_REASON: '욕설, 비방 사용',
  EXAMPLE_EXPEL_REASON: '해킹',
  EXAMPLE_NOTIFICATION_DATE: '2019년 05월17일',
  EXAMPLE_ACCUMULATIONS: '2개',
  EXAMPLE_BANK_NAME_2: '우리은행',
  EXAMPLE_OPTION_NAME: '라벤더',
  EXAMPLE_USER_INPUT_TEXT: '구성품 2개',
  EXAMPLE_PAY_TYPE_LABEL: '신용카드',
  EXAMPLE_RECEIVER_ADDRESS: '서울특별시 구로구 디지털로26길 …',
  EXAMPLE_REFUND_AMT: '적립금 포함 금액',
  EXAMPLE_INQUIRY_CONTENT: '언제 배송오는건가요?....',
  EXAMPLE_ANSWER_CONTENT: '안녕하세요 고객님. 불편을 드려..',
  EXAMPLE_INQUIRY_CD: '주문',
  EXAMPLE_INQUIRY_PRODUCT_CONTENT: '언제 재입고 되나요?...',
  EXAMPLE_INQUIRY_PRODUCT_ANSWER: '안녕하세요 고객님. 해당 상품은..',
  EXAMPLE_MALL_SERVICE_CENTER_NO: '쇼핑몰관리에서 설정한 고객센터 전화번호',
  EXAMPLE_SERVICE_COMPANY_NAME: 'NHN커머스',
  EXAMPLE_SERVICE_REPRESENTATIVE_NAME: '홍길동',
  EXAMPLE_SERVICE_ONLINE_MARKETING_BUSINESS_DECLARATION_NO: '제 2015-서울구로-0049',
  EXAMPLE_SERVICE_NEW_ADDRESS: '서울특별시 구로구 디지털로26길 72 (NHN한국사이버결제)',
  EXAMPLE_SERVICE_JIBUN_ADDRESS: '서울특별시 구로구 구로동 222-22 NHN한국사이버결제',
  EXAMPLE_MEMBER_ID: 'gildong123',
  EXAMPLE_SMS_AGREED: '동의',
  EXAMPLE_SMS_AGREED_DATE: '8/11/17',
  EXAMPLE_EMAIL_AGREED: '거부',
  EXAMPLE_EMAIL_AGREED_DATE: '8/11/17',
  EXAMPLE_ORDERER_NAME: '홍길동',
  EXAMPLE_ORDER_NO: '202005171609370000',
  EXAMPLE_DOMAIN: 'https://www.shopbypromall.com',
  EXAMPLE_CERT_CODE: '123456',

  NOTE_EXPIRED: "영구정지인 경우 '기한없음'으로 표시",
  NOTE_PRODUCT: '최대 20byte(한글: 10자, 영문/숫자: 20자)까지 표시 후, 줄임말(...)처리',
  NOTE_SHOP_MALL_NAME: '쇼핑몰관리에서 설정한 쇼핑몰 명',
  NOTE_MALL_NAME: '비회원 SMS 발송 시, 수신대상의 명',
  NOTE_TEMP_PWD: '시스템에 의해 자동 생성된 비밀번호 발송(회원 임시비밀번호 발송, 비회원 주문 임시비밀번호 발송)',
  NOTE_SERVICE_CENTER_TEL: '쇼핑몰관리에서 설정한 고객센터 전화번호',
  NOTE_POINT_NAME: '쇼핑몰관리에서 설정한 적립금명',
  NOTE_ACCOUNT: '쇼핑몰 무통장 또는 은행가상계좌',
  NOTE_DEPOSIT_DATE:
    '쇼핑몰 무통장: 미입금주문 자동취소일\n은행가상계좌: 미입금주문 자동취소일 / 가상계좌 만기일 중 짧은 것',
  NOTE_DEPOSIT_DATE_SHORT: '입금기한 축약형',
  NOTE_PC_WEB_DOMAIN: '쇼핑몰관리에서 설정한 PC 웹 도메인',
  NOTE_MOBILE_WEB_DOMAIN: '쇼핑몰관리에서 설정한 모바일 웹 도메인',
  NOTE_MEMBER_NAME: '비회원 SMS 발송 시, 수신대상의 명',
  NOTE_CERT_NO: '시스템에 의해 자동 생성된 인증번호 발송',
  NOTE_REASON: '본사 운영자 입력내용 표시',
  NOTE_NOTIFICATION_DATE:
    '개인정보 이용내역 발송배치 일자 안내 첫 발송: 회원가입이후 11개월 이후 설정 일자 두번째 발송일부터 이전 발송일 이후 11개월 주기로 설정일자',
  NOTE_ACCUMULATIONS: '테이블 행 개수 계산용',
  NOTE_SERVICE_ADDRESS: '기초정보 관리에서 설정한 주소정보',
  NOTE_SERVICE_COMPANY_NAME: '기초정보 관리에서 설정한 회사명',
  NOTE_SERVICE_REPRESENTATIVE_NAME: '기초정보 관리에서 설정한 대표자명',
  NOTE_SERVICE_BUSINESS_REGISTRATION_NO: '기초정보 관리에서 설정한 사업자등록번호',
  NOTE_SERVICE_ONLINE_MARKETING_BUSINESS_DECLARATION_NO: '기초정보 관리에서 설정한 통신판매업신고번호',
  NOTE_SERVICE_PRIVACY_MANAGER_NAME: '기초정보 관리에서 설정한 개인정보 관리 책임자명',
  NOTE_SMS_AGREED: '동의/거부',
  NOTE_SMS_AGREED_DATE: '수신동의여부가 거부인 경우 빈 값',
  NOTE_EMAIL_AGREED: '동의/거부',
  NOTE_EMAIL_AGREED_DATE: '수신동의여부가 거부인 경우 빈 값',
  NOTE_DOMAIN: '쇼핑몰에 연결된 대표도메인(연결된도메인이 없는 경우 임시도메인)',

  ADMIN_NAME: '운영자명',
  EXAMPLE_ADMIN_NAME: '홍길동',
  ADMIN_ID: '운영자아이디',
  EXAMPLE_ADMIN_ID: 'hongzzang',

  NOTE_OAUTH_TYPE: '타 업체를 통해 개인정보를 수집한 경우(ex. 간편 로그인)  개인정보를 수집한 출처',

  TOOL_TIP_AREA_TITLE: '[SMS 인증코드 발송] 설정 권장 예시',
  TOOL_TIP_AREA_EXAMPLE_1: 'SMS 기본 사용설정',
  TOOL_TIP_AREA_EXAMPLE_2: "SMS 발송항목 중 'SMS 인증코드 발송'",
  TOOL_TIP_AREA_EXAMPLE_3: '알림톡 기본 사용설정',
  TOOL_TIP_AREA_EXAMPLE_4: "알림톡 발송항목 중 'SMS 인증코드 발송'",
  TOOL_TIP_AREA_NOTICE_1:
    'SMS 인증코드 발송은 회원인증, 비밀번호 찾기 등 휴대폰번호로 인증 시도 시 사용되는 항목입니다.',
  TOOL_TIP_AREA_NOTICE_2:
    '해당 항목을 알림톡만 사용함 으로 설정한 경우, 카카오 알림톡을 사용하지 않는 등 수신이 불가한 회원에게는 발송이 실패되어 인증이 불가할 수 있습니다.',
  TOOL_TIP_AREA_NOTICE_3:
    ' 인증코드 발송 항목은 SMS로만 발송될 수 있도록 권장 예시를 참고하시어 사용하시길 권장합니다.',
});
