





















































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import Grid from '@/components/common/grid/Main.vue';
import {
  getHistoryGridProp,
  getTermsHistoriesGridColumns,
  getTermsHistoryDefaultQuery,
  getTermsSelectOption,
  historyGridDisplayOptions,
  TERMS_TYPE,
  getCustomTermsTitle,
  getTermsCustomSelectOption,
} from '@/const/contents/configuration/terms';
import { EventType, ValueWithAll } from '@/types';
import { getTermsHistoryTitle, i18nForTerms } from '@/views/contents/configuration/basic/terms/Terms';
import {
  TermsHistoryPopupData,
  termsHistoryMainTitleType,
  termsHistorySelectType,
  TermsSelectOption,
} from '@/types/terms';
import {
  GetTermsHistoriesRequest,
  History,
  TermsType,
  TermsCustomHistories,
  TermsCustomResponse,
} from 'ncp-api-supporter';
import { throwPopup } from '@/helpers/popup';
import { api } from '@/api';
import { getStrDate, isSameOrAfter } from '@/utils/dateFormat';
import { today } from '@/views/contents/product/basic/ProductAdd';
import { isArray } from 'util';
import { Row } from 'tui-grid';

@Component({
  components: { SelectBox, NoticeBox, Grid },
})
export default class TermsHistory extends Vue {
  @Prop({ required: true })
  private data: TermsHistoryPopupData;
  @Prop()
  private onPositiveClick: () => void;
  @Prop()
  private onNegativeClick: () => void;

  private i18nForTerms = i18nForTerms;
  private DEFAULT_PAGE_SIZE = 10;
  private TERMS_CUSTOM_SIZE = 100;
  private TERMS_CUSTOM_PAGE = 1;
  private subTitleTermsCustomSelectData = [];
  private termsCustom: TermsCustomResponse[] = [];
  private totalCount = 0;
  private cacheHistories: History[] | TermsCustomHistories[] = [];
  private filteredHistories: History[] | TermsCustomHistories[] = [];
  private effectiveTermsNo = 0;
  private gridProp = getHistoryGridProp();
  private displayOptions = historyGridDisplayOptions;
  private columns = getTermsHistoriesGridColumns(this.data.termsType, this.effectiveTermsNo, this.onGridChangeEvent);

  public initializeTermsHistory(title: termsHistoryMainTitleType) {
    this.title = title;
    this.subTitle = 'ALL';
    this.resetQuery(title);
  }

  private query: GetTermsHistoriesRequest['params'] = getTermsHistoryDefaultQuery(
    this.data.mallNo,
    this.data.termsType,
  );

  @Watch('$route.query')
  private setQuery() {
    const page = this.$route.query.page === undefined ? 1 : Number(this.$route.query.page);
    this.query = {
      ...this.query,
      page,
      pageSize: 10,
    };
    this.fetchHistories();
  }

  private resetRouterQuery() {
    // query 를 새로주지 않으면, 다음 항목을 선택한 후 페이지수가 존재할 때 queryString 에 찍힌
    // 이전 페이지 값으로 바로 넘어간다
    this.$router
      .push({
        query: {
          mallNo: this.data.mallNo.toString(),
        },
      })
      .catch(() => null);
  }

  private resetQuery(termsType: termsHistoryMainTitleType, reset = false) {
    this.query = getTermsHistoryDefaultQuery(this.data.mallNo, termsType);

    this.resetRouterQuery();

    if (!reset) {
      this.query = {
        ...this.query,
        ...this.$route.query,
      };
    }
    this.fetchHistories();
  }

  @Ref()
  private readonly grid: Grid;

  public onGridChangeEvent(name: string, key: number, event: EventType<HTMLSelectElement>): void {
    this.changeUseState(key, event);
  }

  private isChangeable(title: string, value: boolean): boolean {
    return confirm(
      `${title} ${i18nForTerms(value ? 'EXPOSURE_Y' : 'EXPOSURE_N')} ${i18nForTerms('CONFIRM_CHANGE_EXPOSURE')}`,
    );
  }

  private undoChanges(idx: number, value: boolean, target: HTMLSelectElement) {
    this.$nextTick(() => {
      this.$set(this.filteredHistories[idx], 'showHistory', !value);
      target.value = (!value).toString();
    });
  }

  private changeUseState(rowKey: number, { target }: { target: HTMLSelectElement }) {
    const history: History = (this.grid.getRowAt(rowKey) as any) as History;
    const currValue = JSON.parse(target.value);
    const title = getTermsHistoryTitle(this.effectiveTermsNo)(history).replace('<br>', '');

    if (!this.isChangeable(title, currValue)) {
      this.undoChanges(rowKey, currValue, target);
      return;
    }
    this.patchTerms(history.termsNo.toString(), currValue);
  }

  private openDetailsPopup(rowData: Row) {
    if (this.title === 'TERMS_CUSTOM') {
      const { title, registeredDateTime, customConfigUpdated } = rowData;
      const parsedContent = JSON.parse(customConfigUpdated.toString()) as TermsCustomResponse | TermsCustomResponse[];
      const { contents, used } = isArray(parsedContent) ? parsedContent[0] : parsedContent;

      throwPopup({
        name: 'TermsHistoryDetails',
        data: {
          title: getCustomTermsTitle(title.toString()),
          contents,
          used,
          registeredDateTime,
          termsType: '',
          enforcementDateTime: '',
        },
      });

      return;
    }

    const history = (rowData as unknown) as History;
    const title = getTermsHistoryTitle(this.effectiveTermsNo)(history).replace('<br>', ' ');

    throwPopup({
      name: 'TermsHistoryDetails',
      data: {
        title,
        contents: history.contents,
        termsType: history.termsType,
        enforcementDateTime: history.enforcementDateTime,
        registeredDateTime: history.registeredDateTime,
        used: history.used,
      },
    });
  }

  private onItemClicked({ rowKey, nativeEvent: { target } }): void {
    const history = this.grid.getRowAt(rowKey);
    const type = target.dataset.type;
    if (type === undefined) return;

    switch (type) {
      case 'delete':
        this.deleteHistory(history.termsNo.toString(), history.enforcementDateTime.toString());
        break;
      case 'details':
        this.openDetailsPopup(history);
    }
  }

  private title: termsHistoryMainTitleType = TERMS_TYPE.USE;
  private subTitle: ValueWithAll<TermsType> = 'ALL';
  private selectOption: TermsSelectOption['termsHistory'] = getTermsSelectOption().termsHistory;
  private get subTitleSelectData(): termsHistorySelectType {
    return this.selectOption.depth2[this.title];
  }
  private changedTitle(depth: 'depth1' | 'depth2') {
    if (depth === 'depth1') {
      this.initializeTermsHistory(this.title);
      this.columns = getTermsHistoriesGridColumns(this.title, this.effectiveTermsNo, this.onGridChangeEvent);

      if (this.title === 'TERMS_CUSTOM') {
        this.$api
          .getTermsCustom({
            params: {
              page: this.TERMS_CUSTOM_PAGE,
              pageSize: this.TERMS_CUSTOM_SIZE,
              mallNo: this.query.mallNo,
            },
          })
          .then(res => {
            this.subTitleTermsCustomSelectData = getTermsCustomSelectOption(res.data);
          })
          .catch(({ error }) => alert(error.data.message));
      }

      return;
    }
    if (this.subTitle === 'ALL') {
      this.initializeTermsHistory(this.title);
      return;
    }
    this.resetQuery(this.subTitle);
  }

  mounted() {
    this.init();
  }

  private init() {
    this.title = this.data.termsType;
    this.resetQuery(this.title);
  }

  @Watch('effectiveTermsNo')
  private changedEffectiveTermsNo() {
    if (!this.effectiveTermsNo) return;
    this.columns = getTermsHistoriesGridColumns(this.title, this.effectiveTermsNo, this.onGridChangeEvent);
  }
  private setEffectiveTermsNo(histories) {
    const index = histories.findIndex(history => isSameOrAfter(today, getStrDate(history.enforcementDateTime)));
    this.effectiveTermsNo = histories[index]?.termsNo;
  }

  private fetchHistories() {
    if (this.title === 'TERMS_CUSTOM') {
      api
        .getTermsCustomHistories({
          params: {
            mallNo: this.query.mallNo,
            page: this.query.page,
            pageSize: this.query.pageSize,
            isExcludeEnforceOrder: this.query.isExcludeEnforceOrder,
            customTermsNos: this.subTitle === 'ALL' ? '' : this.subTitle,
          },
        })
        .then(({ data: { contents, totalCount } }) => {
          this.filteredHistories = contents;
          this.totalCount = totalCount;
        })
        .catch(({ error }) => alert(error.data.message));

      return;
    }

    api
      .getTermsHistories({
        params: this.query,
      })
      .then(res => {
        if (this.query.page === 1 || this.query.page?.toString() === '1') {
          this.setEffectiveTermsNo(res.data.contents);
        }
        this.cacheHistories = res.data.contents;
        this.filteredHistories = res.data.contents;
        this.totalCount = res.data.totalCount;
      });
  }

  private patchTerms(termsNo: string, used: boolean) {
    this.$api
      .patchTermsByTermsNo({
        pathParams: {
          termsNo,
        },
        params: {
          mallNo: this.data.mallNo,
          showInHistory: used,
        },
      })
      .then(({ status }) => {
        if (status === 204) {
          alert(i18nForTerms('CHANGED'));
          this.resetQuery(this.title);
        }
      });
  }

  private deleteHistory(termsNo: string, enforcementDateTime: string) {
    if (!confirm(i18nForTerms('CONFIRM_DELETE_HISTORY'))) return;
    this.$api
      .deleteTermsByTermsNo({
        params: {
          mallNo: this.data.mallNo,
        },
        pathParams: {
          termsNo,
        },
      })
      .then(res => {
        if (res.status === 204) {
          alert(i18nForTerms('DELETED', getStrDate(enforcementDateTime), false));
          this.resetQuery(this.title);
        }
      });
  }
}
