import { GridProp } from '@/types';
import { i18nSecurity } from '@/const/contents/configuration/operateSecurity';
import { TableTabMenuItem } from '@/components/common/TableTabMenu.vue';
import { addDay, getToday } from '@/utils/dateFormat';
import { GetPrivacyAccessHistoriesRequest } from 'ncp-api-supporter/dist/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { WorkingDetailType } from '@/components/popup/configuration/WorkingDetail.vue';

export const customerWorkingTypes = [
  'SEARCH',
  'DOWNLOAD',
  'UPLOAD',
  'CHANGE',
  'DELETE',
  'ADD',
  'CREATE',
  'CERTIFICATION_SUCCESS',
  'CERTIFICATION',
  'LOGIN',
];
export const adminWorkingTypes = ['CERTIFICATION', 'SEARCH', 'ADD', 'DELETE', 'CHANGE', 'LOGIN'];
export const workingLabel = (workingType, workingDetail = null) => {
  if (workingType !== 'CERTIFICATION') return workingType;
  if (!workingDetail?.message) return `${workingType}_SUCCESS`;
  return `${workingType}_FAILURE`;
};
export const getPersonalAccessHistoryGridOption = (category: string): GridProp => {
  return {
    header: {},
    columns: [
      {
        header: 'MALL',
        name: 'mallName',
        align: 'center',
        minWidth: 50,
      },
      {
        header: 'CONFIGURATION.SECURITY.CONNECTION_DATE',
        name: 'accessDateTime',
        align: 'center',
        minWidth: 60,
      },
      {
        header: 'CONFIGURATION.SECURITY.CONNECTION_IP',
        name: 'ip',
        align: 'center',
        minWidth: 50,
      },
      {
        header: 'CONFIGURATION.SECURITY.ADMIN_ID',
        name: 'adminId',
        align: 'center',
        minWidth: 50,
        formatter: cell => {
          const value = cell.value as string;
          return value ? value : i18n.t('MALL.LIST.DELETED_ADMIN').toString();
        },
      },
      {
        header: 'CONFIGURATION.SECURITY.CONNECTION_URL',
        name: 'menuUri',
        align: 'center',
        minWidth: 400,
        formatter: ({ value }) => {
          return process.env.VUE_APP_STANDARD_ADMIN_URL + value;
        },
      },
      {
        header: 'CONFIGURATION.SECURITY.PERFORMANCE',
        name: 'workingTypeDescription',
        align: 'center',
        minWidth: 30,
        formatter: cell => {
          const workingType = cell.row.workingType as string;
          const workingTypes = category === 'CUSTOMER' ? customerWorkingTypes : adminWorkingTypes;
          const workingDetail = cell.row.workingDetail as WorkingDetailType;
          const label = workingLabel(workingType, workingDetail);
          const showsDetail =
            workingTypes.includes(label) === true &&
            (workingDetail.count === undefined || Number(workingDetail.count) > 0);
          return showsDetail
            ? `${i18nSecurity(label)} ${getAnchorHTML(i18nSecurity('DETAIL'), 'detail')}`
            : i18nSecurity(label);
        },
      },
    ],
    options: {
      columnOptions: {
        resizable: true,
      },
      pageOptions: {
        perPage: 30,
        page: 1,
      },
    },
    displayOptions: {
      hasExcelDownloadButton: false,
    },
  };
};

export const CategoryType = {
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
} as const;

export const resetQuery = (mallNo, category = 'CUSTOMER'): GetPrivacyAccessHistoriesRequest['params'] => ({
  adminId: '',
  startDateTime: addDay(new Date(), -7),
  endDateTime: getToday(),
  category,
  page: 1,
  pageSize: 30,
  mallNo,
});

export const menuList = (): TableTabMenuItem[] => [
  { name: CategoryType.CUSTOMER, title: i18nSecurity('MEMBER_RECODE') },
  { name: CategoryType.ADMIN, title: i18nSecurity('ADMIN_RECODE') },
];
