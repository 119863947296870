































































































import { Component, Prop, Ref, Mixins } from 'vue-property-decorator';
import ArticleReplyGrid from '@/components/common/grid/Main.vue';
import { getArticleListGridOption, getOperator } from '@/const/contents/board';

import {
  CheckGridEventProps,
  GridEventProps,
  //DefaultGridContainerOption
} from '@/types';
import Article from '@/components/board/basic/mixins/ArticleMixin';
import { Row } from '@/types/tui-grid';

@Component({
  components: {
    ArticleReplyGrid,
  },
})
export default class ArticleReplyViewPopup extends Mixins(Article) {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: () => void;
  @Prop() private readonly onNegativeClick!: () => void;
  @Ref('articleReplyGrid') private readonly grid: ArticleReplyGrid;

  // grid columns and options
  private gridProps = getArticleListGridOption();
  private totalCount = 0;
  private contents = [];
  // grid column
  private replyGridPropsColumns = this.gridProps.columns.filter(item => {
    item.header = item.name === 'no' ? window.$t('BOARD.BASIC.ARTICLE.HEADER_REPLY_NO') : item.header;
    return item.name !== 'reply' && item.name !== 'replyCount';
  });

  // article creator
  get articleWriter(): string {
    return getOperator(this.data, 'writer', '');
  }

  created() {
    this.gridProps.options.bodyHeight = 240; //'auto';
    this.getArticleReplies();
  }

  /**
   * ------ Get  replying list of a article. ----
   * 1. call api for get replying list.
   * 2. set replying list to gird.
   * ---------------------------------------------
   */
  private async getArticleReplies(): Promise<void> {
    const res = await this.getArticleReplyList(this.data);
    if (res) {
      res['contents'].forEach((item, i) => (item['index'] = i + 1));
      this.contents = res['contents'];
      this.totalCount = res['totalCount'];
    }
  }

  // ---- delete reply article by clicking delete button----
  // 1. get deleted rows' data
  // 2. to delete logic (detail to sub method)
  //--------------------------------------------------------
  private clickReplyDeleteBtn(): boolean {
    // 1.
    const selectedArticles = this.grid.getCheckedRowKeys();
    // check whether to select row.
    if (selectedArticles.length === 0) {
      window.alert(this.$t('BOARD.BASIC.ARTICLE.SELECT_REPLY_ARTICLE'));
      return false;
    }
    // get parameter for delete article.
    const param = selectedArticles.map(key => this.grid.getRowAt(key).no).join(',');
    // 2.
    this.deleteArticle(this.data.row.boardNo, param, this.getArticleReplies);
    return true;
  }

  /**
   *----- article detail popup ---------
   * 1. generate parameter for article detail popup
   * 2. call common method in ArticleMixin to popup page.
   * -----------------------------------
   */
  private articleDetail(rowData: Row, mode = 'create', name?: string): void {
    // 1
    const popupData = {
      mallNo: this.data.mallNo,
      boardNo: this.data.boardNo,
      parentTitle: this.data.row.title,
      parentContent: this.data.row.content,
      mode: mode,
      row: rowData,
    };
    //throwWindowPopup('ArticleDetail', popupData, 'lg', this.detailCallback);
    // 2
    this.articleDetailPopup(popupData, this.getArticleReplies, name);
  }

  /**
   * ----- edit article (reply article parent)
   */
  private articleDetailEdit(): void {
    const popupData = {
      mallNo: this.data.mallNo,
      boardNo: this.data.boardNo,
      mode: 'edit',
      row: this.data.row,
    };

    this.articleDetailPopup(popupData, this.refreshPopup, `article_${this.data.row.no}`);
  }

  private refreshPopup() {
    this.$router.go(0);
  }

  //------ Open popup page to do logic when clicking grid cell---------
  // 1. open article detail window popup (create&edit,reply)
  //    (click cell link in '게시글 번호','제목','답글' cloumn of gird)
  // grid columns refer getArticleListGridOption @/const/contents/board.ts
  //-------------------------------------------------------------------
  private onItemClicked(gridProps: GridEventProps): void {
    const rowData: Row = this.grid.getRowAt(gridProps.rowKey);
    switch (gridProps.columnName) {
      case 'no': //게시글 번호
      case 'title': //제목
        // 1.
        this.articleDetail(rowData, 'replyModify', `article_reply_${rowData.no}`);
        break;
    }
  }
  private selected;
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
  }
  public refresh(): void {
    location.reload();
  }
}
