
















































































































































































































import { Vue, Component } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { addMonth, getToday } from '@/utils/dateFormat';
import { GetCouponsIssuePlansSummaryCountRequset } from 'ncp-api-supporter';
import { throwMessagePopup } from '@/helpers/popup';
import { sendQueryString } from '@/utils/query';
import { getCouponManagementSelectType, getCouponManagementSearchDateType } from '@/const/contents/couponManagement';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { mall } from '@/utils/mall';

@Component({ components: { SelectBox, SearchButton, ToolTip, DateRangePicker, RadioGroup, MallSelect } })
export default class CouponManagement extends Vue {
  private selectType = getCouponManagementSelectType();
  private searchDateType = getCouponManagementSearchDateType();
  private displayReadyCount = 0;
  private displayIngCount = 0;
  private displayCouponCount = 0;
  private statusAllDisabled = false;
  private statusReadyDisabled = false;
  private statusCancelDisabled = false;
  private statusIngDisabled = false;
  private statusEndDisabled = false;
  private query = {
    mallNos: '',
    searchType: getCouponManagementSelectType().theSearchType[0].value,
    keyword: '',
    searchDateType: getCouponManagementSearchDateType().thePeriodType[0].value,
    payType: 'ALL',
    birthDay: true,
    signUpDate: true,
    statusAll: true,
    statusReady: true,
    statusCancel: true,
    statusIng: true,
    statusEnd: true,
    startYmdt: addMonth(new Date(), -3),
    endYmdt: getToday(),
    hasNotIssuableCoupon: false,
  };
  private payTypeOption = {
    name: 'payTypeOption',
    data: [
      { id: 'all', value: 'ALL', display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SEARCH_TYPE_ALL') },
      {
        id: 'immediately',
        value: 'IMMEDIATELY_ISSUE',
        display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_IMMEDIATELY'),
      },
      {
        id: 'scheduled',
        value: 'SCHEDULED_ISSUE',
        display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_SCHEDULED'),
      },
      { id: 'auto', value: 'PERIOD_ISSUE', display: this.$t('PROMOTION.COUPON_ISSUE.TOP_TBODY_SET_STATUS_AUTO') },
    ],
  };

  private getToolTipMessage(): string {
    return this.$t('PROMOTION.COUPON_ISSUE.TOOLMESSAGE').toString();
  }

  private onStatusChangeAll() {
    this.query.statusIng = this.query.statusAll;
    this.query.statusEnd = this.query.statusAll;
    this.query.statusReady = this.query.statusAll;
    this.query.statusCancel = this.query.statusAll;
  }

  private onStatusChange() {
    if (this.query.statusIng && this.query.statusEnd && this.query.statusReady && this.query.statusCancel) {
      this.query.statusAll = true;
    } else {
      this.query.statusAll = false;
    }
  }

  private created() {
    // this.initMall(); //쇼핑몰

    let requestQuery: GetCouponsIssuePlansSummaryCountRequset;
    this.$api.getCouponsIssuePlansSummaryCount(requestQuery).then(response => {
      if (response && response.status === 200) {
        this.displayReadyCount = response.data.issueReadyCount;
        this.displayIngCount = response.data.issueIngCount;
        this.displayCouponCount = response.data.issueProblemCount;
      }
    });
    const malls = mall.mallsSortedByCreationDate;
    if (malls && malls.length > 0) {
      this.mallReset = malls[0].mallNo.toString();
    }
    this.query.mallNos = this.mallReset;

    if (this.$route.query != undefined && this.$route.query != null && JSON.stringify(this.$route.query) != '{}') {
      Object.assign(this.query, this.$route.query);

      this.query.birthDay = this.$route.query.birthDay === 'true';
      this.query.signUpDate = this.$route.query.signUpDate === 'true';
      this.query.statusAll = this.$route.query.statusAll === 'true';
      this.query.statusReady = this.$route.query.statusReady === 'true';
      this.query.statusCancel = this.$route.query.statusCancel === 'true';
      this.query.statusIng = this.$route.query.statusIng === 'true';
      this.query.statusEnd = this.$route.query.statusEnd === 'true';
    }
  }

  private mallReset = '';
  mounted() {
    sendQueryString(this, this.query, true, true);
  }

  private reset() {
    this.query = {
      mallNos: this.mallReset,
      searchType: getCouponManagementSelectType().theSearchType[0].value,
      keyword: '',
      searchDateType: getCouponManagementSearchDateType().thePeriodType[0].value,
      payType: 'ALL',
      birthDay: true,
      signUpDate: true,
      statusAll: true,
      statusReady: true,
      statusCancel: true,
      statusIng: true,
      statusEnd: true,
      startYmdt: addMonth(new Date(), -3),
      endYmdt: getToday(),
      hasNotIssuableCoupon: false,
    };
  }

  private dateCheck(): boolean {
    if (this.query.startYmdt > this.query.endYmdt) {
      throwMessagePopup(this.$t('PROMOTION.COUPON_ISSUE.DATE_RROR'));
      return false;
    }

    if (this.whetherMoreThan(this.query.startYmdt, this.query.endYmdt, 12) == false) {
      throwMessagePopup(this.$t('PROMOTION.COUPON_ISSUE.DATE_MORE'));
      return false;
    }

    return true;
  }
  private checkValidation(): boolean {
    if (this.dateCheck() == false) {
      return false;
    }

    if (
      this.query.statusAll == false &&
      this.query.statusReady == false &&
      this.query.statusCancel == false &&
      this.query.statusIng == false &&
      this.query.statusEnd == false
    ) {
      throwMessagePopup(
        this.$t('PROMOTION.COUPON_ISSUE.NO_PAY_TYPE'),
        undefined,
        undefined,
        undefined,
        undefined,
        '확인',
      );
      return false;
    }

    return true;
  }

  private whetherMoreThan(start: string, end: string, months: number) {
    let whether = true;
    const endDay = end.split('-');
    const startDay = start.split('-');
    if (parseInt(endDay[0]) === parseInt(startDay[0])) {
      if (parseInt(endDay[1]) - parseInt(startDay[1]) > months) {
        whether = false;
      } else if (parseInt(endDay[1]) - parseInt(startDay[1]) === months) {
        if (parseInt(endDay[2]) - parseInt(startDay[2]) > 0) {
          whether = false;
        }
      }
    } else {
      if (parseInt(endDay[0]) - parseInt(startDay[0]) > 1) {
        whether = false;
      } else {
        if (parseInt(startDay[1]) + months - parseInt(endDay[1]) < 12) {
          whether = false;
        } else {
          if (parseInt(endDay[2]) - parseInt(startDay[2]) > 0) {
            whether = false;
          }
        }
      }
    }
    return whether;
  }

  private changePayType() {
    switch (this.query.payType) {
      case 'ALL':
        this.statusAllDisabled = false;
        this.statusReadyDisabled = false;
        this.statusCancelDisabled = false;
        this.statusIngDisabled = false;
        this.statusEndDisabled = false;

        this.query.statusAll = true;
        this.query.statusReady = true;
        this.query.statusCancel = true;
        this.query.statusIng = true;
        this.query.statusEnd = true;

        this.query.birthDay = true;
        this.query.signUpDate = true;
        break;
      case 'IMMEDIATELY_ISSUE':
        this.statusAllDisabled = true;
        this.statusReadyDisabled = true;
        this.statusCancelDisabled = true;
        this.statusIngDisabled = true;
        this.statusEndDisabled = false;

        this.query.statusAll = false;
        this.query.statusReady = false;
        this.query.statusCancel = false;
        this.query.statusIng = false;
        this.query.statusEnd = true;

        this.query.birthDay = false;
        this.query.signUpDate = false;
        break;
      case 'SCHEDULED_ISSUE':
        this.statusAllDisabled = true;
        this.statusReadyDisabled = false;
        this.statusCancelDisabled = false;
        this.statusIngDisabled = true;
        this.statusEndDisabled = false;

        this.query.statusAll = false;
        this.query.statusReady = true;
        this.query.statusCancel = true;
        this.query.statusIng = false;
        this.query.statusEnd = true;

        this.query.birthDay = false;
        this.query.signUpDate = false;
        break;
      case 'PERIOD_ISSUE':
        this.statusAllDisabled = false;
        this.statusReadyDisabled = false;
        this.statusCancelDisabled = false;
        this.statusIngDisabled = false;
        this.statusEndDisabled = false;

        this.query.statusAll = true;
        this.query.statusReady = true;
        this.query.statusCancel = true;
        this.query.statusIng = true;
        this.query.statusEnd = true;

        this.query.birthDay = true;
        this.query.signUpDate = true;
        break;
    }
  }

  private showReadyCount() {
    this.reset();
    this.query.mallNos = 'ALL';
    this.query.statusAll = false;
    this.query.statusIng = false;
    this.query.statusEnd = false;
    this.query.statusReady = true;
    this.query.statusCancel = false;
    this.query.startYmdt = addMonth(new Date(), -12);
    this.query.endYmdt = getToday();
    sendQueryString(this, this.query, true, true);
  }

  private showIngCount() {
    this.reset();
    this.query.mallNos = 'ALL';
    this.query.statusAll = false;
    this.query.statusIng = true;
    this.query.statusEnd = false;
    this.query.statusReady = false;
    this.query.statusCancel = false;
    this.query.startYmdt = addMonth(new Date(), -12);
    this.query.endYmdt = getToday();
    sendQueryString(this, this.query, true, true);
  }

  private showCouponRequired() {
    this.reset();
    this.query.mallNos = 'ALL';
    this.query.hasNotIssuableCoupon = true;
    this.query.statusEnd = false;
    this.query.statusAll = false;
    this.query.statusCancel = false;
    this.query.statusReady = true;
    this.query.statusIng = true;
    this.query.birthDay = false;
    this.query.signUpDate = false;
    sendQueryString(this, this.query, true, true);
    this.query.hasNotIssuableCoupon = false;
  }
}
