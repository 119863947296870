/**
 * ref eg: src\views\product\ProductList.vue
 * @author AZu
 */
import { UNIT_TYPE } from '@/types';
import { i18n } from '@/main';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

export function getCurrency(value, usePoint = false): string {
  if (!value || isNaN(value)) {
    if (usePoint) return '0.00';
    return '0';
  }

  if (!usePoint) {
    return parseInt(value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const parts = parseFloat(value)
    .toFixed(2)
    .toString()
    .split('.');

  if (parts.length === 2) {
    return `${parts[0].replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}.${parts[1]}`;
  } else {
    return '0';
  }
}

export function formatCurrency(num = 0): string {
  return num.toLocaleString(undefined, { minimumFractionDigits: 0 });
}

/*
import banks from '@/const/words/bank';
export function getbankName(code: string): string {
  return getEnumName(code, 'bank');
}

export function getEnumName(code: string, type: string): string {
  let enumName = '';
  switch (type) {
    case 'bank':
      let tempBank = banks.find((bank): boolean => bank.value === code);
      if (tempBank) {
        enumName = tempBank.name;
      }
      break;
  }
  return enumName;
}
*/

export const numberWithUnitFormatter = (num: number, type: keyof typeof UNIT_TYPE): TranslateResult => {
  const localeNum = num.toLocaleString();
  return i18n.t(`N_${type}`, [localeNum]);
};

export const filterUnit = (value: number, unit = 10): number => (value % unit !== 0 ? 0 : value);

export const getOnlyNumbers = (value: string, isNegative = false): number => {
  if (value === '' || value === '0') {
    return 0;
  }

  const regExp = isNegative ? /[^0-9-]+/g : /[^0-9]/g;
  const replaced = value.replace(regExp, '');
  if (replaced === '-') {
    return 0;
  }

  return parseInt(replaced, 10);
};

// 첫번째 -만 허용
export const getNegativeNum = (value: string): string => {
  const valueWithoutStr = value.replace(/[^0-9-]+/g, '');
  const index = valueWithoutStr.indexOf('-');
  if (index === -1) return valueWithoutStr;
  const replacedValue = valueWithoutStr.replace(/[^0-9]+/g, '');
  if (index === 0) {
    return '-' + replacedValue;
  }
  return replacedValue;
};

export const getCurrencyWithUnit = (price: number, unit = 'WON'): string => `${formatCurrency(price)}${i18n.t(unit)}`;
