export default Object.freeze({
  TITLE: '기획전 등록',
  MODIFY_TITLE: '기획전 수정',
  COPY_TITLE: '기획전 복사',
  INFO: '기본정보',
  REQUIRED: '* 필수입력',
  MALL: '쇼핑몰',
  TYPE: '기획전 유형',
  GENERAL: '일반',
  EXTERNAL: '외부링크',
  EVENT_NUMBER: '기획전 번호 사용',
  DIRECT: 'URL 직접입력',
  REGULAR: '상시',
  PERIOD: '기간지정',
  RANGE_ERROR: '시작일자는 종료일자보다 클 수 없습니다.',
  ALL: '전체',
  SETTING: '설정',
  PC: 'PC',
  MOBILE_APP: '모바일 앱',
  MOBILE_WEB: '모바일 웹',
  ALL_GRADES: '전체 회원등급',
  ALL_GROUPS: '전체 회원그룹',
  VIP: '회원',
  GRADE: '등급',
  GROUP: '그룹',
  GRADE_LOOK: '회원등급 설정보기',
  GROUP_LOOK: '회원그룹 설정보기',
  FILE: '이미지로 등록 ',
  HTML: 'HTML로 등록',
  ZERO: '0건',
  ONE: '1건',
  TWO: '2건',
  THREE: '3건',
  FOUR: '4건',
  FIVE: '5건',
  SIX: '6건',
  SEVEN: '7건',
  EIGHT: '8건',
  NINE: '9건',
  TEN: '10건',
  ONE_COUNT: '1개',
  TWO_COUNT: '2개',
  THREE_COUNT: '3개',
  FOUR_COUNT: '4개',
  FIVE_COUNT: '5개',
  SIX_COUNT: '6개',
  SEVEN_COUNT: '7개',
  EIGHT_COUNT: '8개',
  NINE_COUNT: '9개',
  TEN_COUNT: '10개',
  DELETE_COUPON: '입력중인 할인쿠폰정보가 삭제됩니다. 변경하시겠습니까?',
  CHANGE_EVENT_TYPE_EXTERNAL:
    "기획전 유형 변경 시 '상단이미지, 할인쿠폰, 상품 설정' 에 등록된 내용이 초기화 됩니다. 변경 하시겠습니까?",
  USE_COUPON_TIP: '프로모션 불가상품에는 할인쿠폰을 사용할 수 없습니다. 상품섹션 대상 상품 등록시 확인해주세요.',
  DELETE_ALREADY_ISSUED_COUPON:
    '기획전에 대한 할인쿠폰을 삭제하더라도 고객에게 이미 발행된 쿠폰은 삭제 되지 않습니다. 할인쿠폰을 삭제하시겠습니까?',
  PLEASE_SELECT: '상품섹션에서 삭제할 상품을 선택해주세요',
  SURE_DELETE: '선택하신 상품을 상품섹션에서 삭제하시겠습니까?',
  COUPON: '쿠폰',
  SECTION: '상품섹션',
  ORDER: '순서',
  DISPLAY: '전시',
  PRODUCT_NO: '상품번호',
  PRODUCT_NAME: '상품명',
  BRAND: '브랜드',
  PARTNER: '파트너사',
  ADMIT_STATUS: '승인상태',
  SALE_STATUS: '판매상태',
  NO_DATA: '등록된 상품이 없습니다.',
  DISCOUNT: '할인적용가',
  STOCK: '재고수량',
  GRADE_SETTING: '회원등급 노출',
  GROUP_SETTING: '회원그룹 노출',
  DELETE: '삭제',
  DOWN_NO_DATA: '데이터가 없습니다.',
  CHECK_EVENT_URL_DUPLICATION: '기획전 url 중복체크를 하세요.',
  PLEASE_INPUT_URL: 'url을 입력하세요.',
  SPECIAL_CHARACTER_RESTRICTIONS: '특수문자는 -, _, /, ., =, &, ?만 입력 가능하며 한글은 사용할 수 없습니다.',
  START_ENGLISH_OR_NUMBERS: 'URL은 영문 또는 숫자로 시작해야 합니다.(예시. 2017exhibition)',
  URL_ALREADY_USE: '이미 사용중인 url입니다.',
  AVAILABLE_URL: '사용 가능한 url입니다.',
  ENTER_EVENT_NAME: '기획전명을 입력하세요',
  ENTER_PROMOTION_TEXT: '홍보문구를 등록해주세요.',
  SPECIAL_CHARACTER_RESTRICTIONS2: '홍보문구에는 특수문자를 사용할 수 없습니다.',
  ENTER_EVENT_URL: '기획전 URL을 입력하세요',
  PLEASE_PLATFORM_SETTING: '플랫폼별 노출을 설정해주세요.',
  ENTER_GRADE: '회원등급을 설정해주세요.',
  ENTER_GROUP: '회원그룹을 설정해주세요.',
  ENTER_COUPON_NO: '할인쿠폰번호를 입력하세요.',
  ENTER_SECTION_NAME: '섹션명을 입력하세요.',
  ENTER_SECTION_IMAGE: '섹션 이미지를 등록하세요.',
  ONLY_IMAGE_FILE: '이미지 파일만 등록해주세요.',
  IMAGE_LIMIT: '1024KB 이하의 이미지만 업로드 가능합니다.',
  PLEASE_CHOOSE_MOVE: '이동할 상품을 선택해 주세요',
  LOGIN_NO_PRODUCT: '등록 가능한 상품이 없습니다.',
  CANT_DATA1: '입력하신 상품 ',
  CANT_DATA2: '개 중 등록 불가능한 상품이 ',
  CANT_DATA3: '개 (',
  CANT_DATA4: ') 있습니다.\n해당 상품을 제외하고 저장하시겠습니까?',
  OTH: '외',
  IND: '개',
  EVENT: '기획전 ',
  EVENT2: ' 생성 완료되었습니다.',
  NOTICE1: '기존 기획전에서 삭제된 카테고리는 복사되지 않습니다.',
  NOTICE2: '기존 기획전에서 변경된 카테고리는 변경된 내용으로 복사됩니다.',
  NOTICE3: '복사된 할인쿠폰의 발행기간이 유효한지 확인하고, 유효한 쿠폰으로 등록해주세요.',
  NOTICE4: '복사된 상품섹션 대상상품의 품절여부와 판매상태를 확인 후 노출 가능한 상품으로 등록해주세요.',
  EVENT_TEXT: '이벤트',
  EVENT_NAME: '기획전명',
  PROMOTION_TEXT: '홍보문구 등록',
  EVENT_URL: '기획전 상세 URL',
  CHECK_EVENT_URL: '중복체크',
  DES_1: '전시기간 시작일 이후에는 URL 변경불가합니다.',
  DES_2: '영문/숫자/특수문자(-, _, /)만 입력 가능하며, 영문 또는 숫자로 시작해야 합니다.(예시.2017exhibition)',
  DISPLAY_PERIOD: '전시기간',
  CATEGORY: '전시 카테고리',
  MAIN_IMAGE: '대표이미지',
  MAIN_PC_IMG: 'PC웹 리스트 이미지',
  MAIN_MOBILE_IMG: '모바일 리스트 이미지',
  BEST: '권장',
  TAG: '태그',
  TAG_INFO: '태그당 최대 10글자(띄어쓰기 없이), ‘,’(콤마)로 구분하여 총 10개까지 입력 가능합니다.',
  DISPLAY_INFO: '노출정보',
  DISPLAY_PLAT_SETTING: '플랫폼별 노출 설정',
  GRADE_DISPLAY_SETTING: '회원등급 노출 설정',
  NOT_SETTING: '설정안함',
  LOOK_SETTING: '설정보기',
  GRADE_SETTING_NOT_DISPLAY: '회원등급을 ‘설정’ 한 상품은 비회원에게 노출되지 않습니다.',
  GROUP_DISPLAY_SETTING: '회원그룹 노출 설정',
  GROUP_SETTING_NOT_DISPLAY: '회원그룹을 ‘설정’ 한 상품은 비회원에게 노출되지 않습니다.',
  TOP_IMG_SETTING: '상단이미지 설정',
  PC_DETAIL_IMG: 'PC 상세 이미지',
  PC_WEB_DETAIL_IMG: 'PC웹 상세 이미지',
  MOBILE_DETAIL_IMG: '모바일 상세 이미지',
  COUPON_SETTING: '할인쿠폰 설정',
  COUPON_COUNT: '쿠폰 개수',
  COUPON_NOT_LOGIN: '할인쿠폰 등록 안함',
  PLACEHOLDER_ENTER_EVENT_NAME: '기획전명을 입력해 주세요.',
  INPUT_PROMOTION_TEXT: '홍보문구 입력',
  EVENT_URL_INPUT: '기획전 상세URL 입력',
  TAG_INPUT: '태그 입력',
  MAX_100: '상품번호 복수 등록(최대 100개) 가능합니다. (Enter 또는 ‘,’로 구분)',
  COUPON_LIST: '쿠폰리스트',
  COUPON_NUM: '할인쿠폰 번호',
  COUPON_ISSUE_COUNT: '발행수량',
  COUPON_ISSUE_PERIOD: '발행기간',
  COUPON_IMG: '쿠폰이미지',
  COUPON_FIND: '쿠폰찾기',
  IMG_FIND: '이미지찾기',
  COMMON_IMG: '공통 이미지',
  COUPON_USE_GUIDE: '쿠폰 사용안내',
  COUPON_ISSUE_OVER: '쿠폰 발급완료',
  ISSUE_OVER_GUIDE: '기발급완료 안내',
  COUPON_ISSUE_BEFORE: '쿠폰발행 시작 전',
  COUPON_ISSUE_SOLDOUT: '쿠폰 발행완료(매진)',
  COUPON_ISSUE_EXPIRED: '쿠폰발행마감(종료)',
  EDITOR_LOGIN: '에디터 등록',
  SECTION_SETTING: '상품섹션 설정',
  SECTION_COUNT: '섹션 개수',
  SECTION_NONE: '상품 섹션 없음',
  SECTION_PRODUCT_WARNING:
    '‘기획전’과 ‘상품’에 설정된 ‘노출 설정’ 값이 다른 경우, 기획전에 상품이 노출되지 않을 수 있습니다.',
  PRODUCT_DISPLAY: '행당상품 노출',
  PC_WEB: 'PC웹',
  MOBILE: '모바일',
  SECTION_TITLE: '상품섹션 타이틀',
  TEXT_INPUT: '텍스트 입력',
  IMG_LOGIN: '이미지 등록',
  PRODUCT_SETTING: '대상상품 설정',
  EVENT_PRODUCT_SETTING_GUIDE: '기획전 대상상품 설정 가이드',
  COUPON_LOGIN_EVENT: '1. 할인쿠폰 등록 기획전',
  COUPON_LOGIN_EVENT_INFO1:
    '기획전 등록 불가 상품상태: 판매종료, 판매금지, 삭제요청, 등록대기 상태 상품은 등록 불가합니다.',
  COUPON_LOGIN_EVENT_INFO2: '할인쿠폰 사용불가 설정인 상품은 등록 불가합니다.',
  COUPON_LOGIN_EVENT_INFO3: '파트너사 프로모션 사용 미동의 상품은 등록 불가합니다.',
  COUPON_NOTT_LOGIN_EVENT: '2. 할인쿠폰 미등록 기획전',
  COUPON_NOTT_LOGIN_EVENT_INFO:
    '기획전 등록 불가 상품상태: 판매종료, 판매금지, 삭제요청, 등록대기 상태 상품은 등록 불가합니다.',
  PRODUCT_LOGIN: '상품조회 등록',
  PRODUCTNO_ALL_LOGIN: '상품번호 일괄등록',
  PRODUCT_DIRECT_LOGIN: '상품번호 직접등록',
  PRODUCT_SEARCH: '상품조회',
  ADD_PRODUCT: '상품추가',
  SECTION_PRODUCT_ALL: '상품섹션 대상 상품 총',
  REMOVE_SELECT_PRODUCT: '선택상품 삭제',
  TOP: '최상단',
  BOTTOM: '최하단',
  OPEN_CLOSE: '열기/닫기',
  SAVE: '저장',
  CANCEL: '취소',
  TO_EVENT_LIST: '기획전 목록으로',
  MALL_URL: '쇼핑몰 URL',
  NOT_DISPLAY: '전시안함',
  SET: '세트',
  APPROVAL_READY: '승인대기',
  FINISHED: '승인완료',
  AFTER_APPROVAL_READY: '수정 후 승인대기',
  REGISTRATION_READY: '등록대기',
  APPROVAL_REJECTION: '승인거부',
  SALE_AGREEMENT_READY: '판매합의대기',
  SALE_AGREEMENT_REJECTION: '판매합의거부',
  AFTER_APPROVAL_REJECTION: '수정 후 승인거부',
  END_SALE: '판매종료',
  PROHIBITION_SALE: '판매금지',
  STOP_SELLING: '판매중지',
  ON_SALE: '판매중',
  WAITING_SALE: '판매대기',
  ON_PRE_SALE: '예약판매중',
  END_PRE_SALE: '예약판매종료',
  OUT_OF_STOCK: '품절',
  COUPON_CHOOSE: '쿠폰 선택',
  SURE_DELETE_GOON: '해당 상품섹션이 삭제 됩니다. 계속 진행하시겠습니까?',
  SURE_DELET_PRODUCT_FROM_SECTION: '상품을 상품섹션에서 삭제하시겠습니까?',
  PRODUCT__SEARCH: '상품 조회',
  ONLY_NUMBER_COMA: '숫자와 콤마만 입력할 수 있습니다',
  PRODUCTNO_ONLY_UNDER_TEN: '상품 번호는 10자 이하로 입력할 수 있습니다.',
  SURE_MALL_CHANGE: '쇼핑몰 변경 시 상품정보가 초기화 됩니다. 계속 진행하시겠습니까?',
  NO_CHANGES: '변경된 내용이 없습니다. 다시 확인 후 진행해 주세요.',
  MODIFICATION_COMPLETED: '수정 완료되었습니다.',
  DELETE_INFO1: '상품섹션 대상상품 중 삭제된 상품 ',
  DELETE_INFO2: '개가 제거됩니다.',
  CHANGE_CANT_PROMOTION_PRODUCT:
    '상품섹션 대상상품 중 프로모션 허용불가로 변경된 상품이 있습니다.\n해당 상품에는 설정된 할인쿠폰을 사용할 수 없습니다.',
  FIND: '카테고리 찾기로 변경',
  SEARCH: '카테고리 검색으로 변경',
  INPUT_CATENAME: '카테고리명 입력',
  SELECT: '선택',
  PREVIOUS: '이전 카테고리를 선택해주세요.',
  SELECTED_CATE: '선택한 카테고리:',
  NO_RESULT: '검색결과가 없습니다.',
});
