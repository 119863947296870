


















import { Component, Mixins } from 'vue-property-decorator';
import { AdditionalService } from '@/types/addition';

import { additionalMainServices } from '@/views/contents/addition/basic/AdditionalService';
import ServiceSection from '@/components/addition/ServiceSection.vue';
import SrcdocModel from '@/components/addition/SrcdocModel';

@Component({
  components: { ServiceSection },
})
export default class AdditionMain extends Mixins(SrcdocModel) {
  private additionalService: AdditionalService[] = additionalMainServices;
}
