








import { Vue, Component, Ref } from 'vue-property-decorator';
import SearchResult from '@/components/product/basic/SearchResult.vue';
import { Row } from '@/types/tui-grid';
import { changeSaleSettingStatus } from '@/components/product/common/girdColumns/edit/UpdateSalesSettingStatus';
import { changeFrontDisplay } from '@/components/product/common/girdColumns/edit/UpdateFrontDisplay';
import { getProductListGridProps } from '@/views/contents/product/basic/GridProps';

interface ProductListPublicMethods {
  rendererEventCallback: RendererEventCallback;
}

export interface RendererEventCallback {
  (eventName: string, event: Event, row: Row): void;
}

@Component({
  components: {
    SearchResult,
  },
})
export default class ProductList extends Vue implements ProductListPublicMethods {
  @Ref()
  private readonly searchResult!: SearchResult;

  private getGridProps = getProductListGridProps;

  public rendererEventCallback(eventName: string, event: Event, row: Row): void {
    switch (eventName) {
      case 'change:sale-setting-status':
        changeSaleSettingStatus(event, row);
        break;
      case 'change:front-display':
        changeFrontDisplay(event, row);
        break;
    }
  }
}
