











import { Vue, Component, Watch } from 'vue-property-decorator';
import { getCampaigns } from '../googleAdConfig';
import ReportSearchForm from '@/components/marketing/configuration/googleMarketing/ReportSearchForm.vue';
import ReportGridForm from '@/components/marketing/configuration/googleMarketing/ReportGridForm.vue';
import { CampaingsSeparationType, GetCampaignsReportsDataTotalReport } from 'ncp-api-supporter';
import { ReportSegment } from '@/types/marketing';

@Component({
  components: {
    ReportSearchForm,
    ReportGridForm,
  },
})
export default class GoogleAdMarketing extends Vue {
  private mallNo = 0;
  private currentGridType: CampaingsSeparationType = 'DATE';
  private totalReportData?: GetCampaignsReportsDataTotalReport | null = null;
  private reportSegment: ReportSegment[] = [];

  @Watch('$route.query.mallNo')
  private changeMallNoQuery(): void {
    this.mallNo = Number(this.$route.query.mallNo);
    getCampaigns(this.mallNo);
  }

  created() {
    this.mallNo = Number(this.$route.query.mallNo);
  }

  private getChannelData(data) {
    this.currentGridType = data.currentGridType;
    this.totalReportData = data.totalReport;
    this.reportSegment = data.reportSegment;
  }
}
