




































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { DepthDataType } from '@/types';
import { OptionData } from '@/helpers/type';
import { Brand, GetMemberGradesRequest, GetStandardCategoriesTreeRequest, Grade, NCPResponse } from 'ncp-api-supporter';
import { getFirstAndEndDay, getSelectMonths, getSelectYears } from '@/utils/dateFormat';
import {
  getCategorySalesSearchOptions as getSearchOptions,
  makeBrandOptions,
} from '@/views/contents/statistics/sales/searchOptions';
import { GetStandardCategoriesTree } from 'ncp-api-supporter/dist/types/modules/display/standardCategory';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import CheckBoxGroup from '@/components/common/CheckboxGroup.vue';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
import CategorySelector from '@/components/product/common/CategorySelector.vue';
import ChainingSelector from '@/components/common/ChainingSelector.vue';
import { getDefaultParams } from '@/views/contents/statistics/sales/categorySales';

@Component({
  components: {
    MallSelect,
    SelectBox,
    DateRangePicker,
    RadioGroup,
    SearchButton,
    CheckBoxGroup,
    SearchKeyword,
    CategorySelector,
    ChainingSelector,
  },
})
export default class ProductSalesForm extends Vue {
  private params = getDefaultParams();

  private searchOptions = getSearchOptions();

  private memberType = 'ALL';
  private selectedOpenId = 'PAYCO,NAVER,KAKAO,FACEBOOK,GOOGLE,APPLE,APP_CARD';
  private periodType = 'MONTH';
  private selectYear = new Date().getFullYear();
  private selectMonth = new Date().getMonth() + 1;
  private yearList = getSelectYears(this.$t('YEAR').toString(), true, 5);
  private monthList = getSelectMonths(this.$t('MONTH').toString());
  private standardCategoryDepthData: DepthDataType | null = null;

  private get isMonthPeriodType(): boolean {
    return this.periodType === 'MONTH';
  }

  private get disableMemberType(): boolean {
    return this.memberType === 'ALL' || this.memberType === 'BASIC_MEMBER';
  }

  @Watch('periodType')
  private resetDateParams() {
    if (this.isMonthPeriodType) {
      this.changeSelectDate();
    }
  }

  @Watch('params.mallNo')
  private fetchOptions() {
    return Promise.all([this.fetchBrand(), this.fetchMemberGrades()]);
  }

  private async fetchBrand(): Promise<void> {
    if (!this.params.mallNo) {
      this.searchOptions.brandOptions = [this.searchOptions.brandOptions[0]];
      return;
    }

    const { data }: NCPResponse<Brand[]> = await this.$api.getBrands({
      params: {
        mallNo: Number(this.params.mallNo),
      },
    });

    this.searchOptions.brandOptions = makeBrandOptions(data);
  }

  private async fetchMemberGrades(): Promise<void> {
    if (!this.params.mallNo) {
      this.setMemberGradeOptions([]);
      return;
    }
    const request: GetMemberGradesRequest = {
      params: {
        mallNo: Number(this.params.mallNo),
        used: true,
      },
    };

    const memberGradeResponse: NCPResponse<Grade[]> = await this.$api.getMemberGrades(request);
    this.setMemberGradeOptions(this.makeGradeOptions(memberGradeResponse));
  }

  private makeGradeOptions(memberGradeResponse) {
    const sortedData = memberGradeResponse.data.sort((gradeX, gradeY) => gradeX.order - gradeY.order);
    return sortedData.map(grade => ({
      value: grade.no,
      name: grade.name,
    }));
  }

  private setMemberGradeOptions(memberGradeOptions: OptionData<number>[]) {
    this.searchOptions.memberGradeOptions = [this.searchOptions.memberGradeOptions[0], ...memberGradeOptions];
    this.setDefaultMemberGradeNo();
  }

  private setDefaultMemberGradeNo() {
    const DEFAULT = 0;
    const memberGradeNoQuery = Number(this.$route.query?.memberGradeNo);

    if (!memberGradeNoQuery) {
      this.params.memberGradeNo = DEFAULT;
    }

    const hadMemberGrade = this.searchOptions.memberGradeOptions.find(
      memberGradeOption => memberGradeOption.value === memberGradeNoQuery,
    );

    this.params.memberGradeNo = hadMemberGrade ? memberGradeNoQuery : DEFAULT;
  }

  private changeSelectDate() {
    const { startYmd, endYmd } = getFirstAndEndDay(this.selectYear, this.selectMonth);

    this.params.startYmd = startYmd;
    this.params.endYmd = endYmd;
  }

  private async fetchStandardCategoryData(): Promise<void> {
    const request: GetStandardCategoriesTreeRequest = {
      params: {
        mallNo: this.params.mallNo.toString(),
      },
    };

    const { data }: NCPResponse<GetStandardCategoriesTree> = await this.$api.getStandardCategoriesTree(request);

    this.standardCategoryDepthData = {
      data: data.tree,
      labelKey: 'name',
      valueKey: 'categoryNo',
      childrenKey: 'child',
    };
  }

  private onUpdatedDepth(depth: string): void {
    this.params.categoryDepth = depth;
  }

  private validateMallNoSelected() {
    if (!this.params.mallNo) {
      alert(this.$t('쇼핑몰을 먼저 선택해주세요.'));
    }
  }

  private reset() {
    this.params = getDefaultParams();
    this.resetDetailFormValues();
    this.resetStandardCategoryData();
  }

  // param이 아닌, 폼 컴포넌트 내부에서만 사용하는 form value 리셋
  private resetDetailFormValues() {
    this.memberType = 'ALL';
    this.selectedOpenId = 'PAYCO,NAVER,KAKAO,FACEBOOK,GOOGLE,APPLE,APP_CARD';
    this.periodType = 'MONTH';
    this.selectYear = new Date().getFullYear();
    this.selectMonth = new Date().getMonth() + 1;
  }

  private resetStandardCategoryData() {
    this.standardCategoryDepthData = null;
    this.fetchStandardCategoryData();
  }

  private onChangeMemberType() {
    if (this.memberType === 'SIMPLE_LOGIN') {
      this.params.memberType = this.selectedOpenId;
      return;
    }

    this.params.memberType = this.memberType;
  }

  created() {
    this.changeSelectDate();
    this.fetchStandardCategoryData();
  }
}
