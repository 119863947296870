




























































































































































import { Vue, Component, PropSync, Ref } from 'vue-property-decorator';

import TextInput from '@/components/common/input/TextInput.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import DateCalendar from '@/components/common/DateCalendar.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import NotificationGuide from '@/components/member/NotificationGuide.vue';

import {
  throwWindowPopupWithProps,
  throwWindowPopup,
  throwMessagePopup,
  PopupClose,
  PopupResult,
  throwPopup,
} from '@/helpers/popup';
import { getToday, getStrYMDHMSS } from '@/utils/dateFormat';
import { CouponPayment, DateCalendarOption } from '@/types';
import { RadioBoxGroupOption } from '@/helpers/type';
import { RadioCouponIssueTimeTypes } from '@/const/contents/promotion';
import { PostCouponsIssuePlansRequest, PromotionNotificationToolsType } from 'ncp-api-supporter';

@Component({
  components: {
    TextInput,
    ToolTip,
    RadioGroup,
    DateCalendar,
    CheckboxGroup,
    NotificationGuide,
  },
})
export default class PaymentsInfo extends Vue {
  // 지급 예정 쿠폰
  @PropSync('paymentList')
  private paymentListSync!: CouponPayment[];

  private toModify(couponNo: string) {
    const routeData = this.$router.resolve({
      path: '/promotion/basic/coupons/modify',
      query: { couponNo },
    });
    window.open(routeData.href, '_blank');
  }
  private removePayment(couponNo: number) {
    this.paymentListSync = this.paymentListSync.filter(item => item.no !== couponNo);
  }

  // 지급 조건 설정
  // 쇼핑몰
  private get showMallName() {
    return this.paymentListSync && this.paymentListSync.length > 0 ? this.paymentListSync[0].mallName : '';
  }
  private get mallNo() {
    return this.paymentListSync && this.paymentListSync.length > 0 ? this.paymentListSync[0].mallNo.toString() : '';
  }

  // 대상 회원
  private memberList = [];
  @Ref() private readonly memberPopBtnRef: HTMLButtonElement;
  private openMemberPop() {
    if (this.paymentListSync.length === 0) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_NOT_SELECTED_COUPON'));
      return;
    }
    throwWindowPopup(
      'InquiryMember',
      // TODO
      { mallNo: this.paymentListSync[0].mallNo },
      'xlg',
      ({ data, state }: PopupResult) => {
        if (state === PopupClose.CONFIRM) {
          this.memberList = data.selectedMember.selected;
        }
      },
    );
  }

  private handleExcelBatchClick() {
    throwWindowPopupWithProps({
      componentName: 'ExcelBatchRegistrationPopup',
      data: { mallNo: this.mallNo },
      size: 'md',
      popupHeight: 400,
      onClose: ({ state, data: memberNos }) => {
        if (state === 'close') return;
        this.memberList = memberNos;
      },
    });
  }

  // 지급 사유
  private issueReason = '';

  // 지급 사정
  private couponIssueTimeType = 'IMMEDIATELY_ISSUE';
  private couponIssueTimeTypes: RadioBoxGroupOption<string> = RadioCouponIssueTimeTypes;
  private requestYmdt = getToday() + ' 00:00:00';
  private requestYmdtOptions: DateCalendarOption = {
    dateType: 'YmdH',
    fromRanges: getToday(),
    selectedYmd: getToday(),
    selectedHour: '00',
  };
  @Ref() requestYmdtRef!: DateCalendar;
  private changeSelectedDateTime(requestDateTime) {
    this.requestYmdt = requestDateTime;
    if (getStrYMDHMSS(new Date()) > this.requestYmdt) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_REQUESTYMDT_BEFORE_NOW'));
      return;
    }
  }

  // 알림 설정
  private useNotification = false;
  private notificationTools = ['SMS', 'EMAIL'];
  @Ref() private readonly notificationGuideRef: NotificationGuide;
  // 지급
  private async payments() {
    // 선택된 쿠폰이 없을 경우
    if (this.paymentListSync.length === 0) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_NOT_SELECTED_COUPON'));
      return;
    }
    // 선택된 대상회원이 없을 경우
    if (this.memberList.length === 0) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_MEMBER_NOT_SELECTED')).then(() => {
        this.memberPopBtnRef.focus();
      });
      return;
    }
    // 예약지급 시간이 현재 시간 이전으로 선택된 경우
    if (this.couponIssueTimeType === 'SCHEDULED_ISSUE' && getStrYMDHMSS(new Date()) > this.requestYmdt) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_REQUESTYMDT_BEFORE_NOW')).then(() => {
        this.requestYmdtRef.focus();
      });
      return;
    }
    // 알림설정을 ‘설정함’으로 선택 + 선택된 알림수단이 없을 경우
    if (this.useNotification && this.notificationTools.length === 0) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_NOTIFICATION_TOOLS_NOT_CHECKED'));
      return;
    }
    // 알림설정을 ‘설정함’으로 선택 + 알림 발송이 불가할 경우
    let goNext = true;
    if (this.useNotification && this.notificationGuideRef.haveNotification) {
      sessionStorage.setItem('mallNoValue', this.paymentListSync[0].mallNo.toString());
      const result = await throwPopup({
        name: 'NotificationNotAvailable',
        data: {
          headerText: this.$t('MEMBER.POPUP.NOTIFICATION_HEADER_TEXT'),
          message: this.$t('PROMOTION.COUPON_ISSUE.CONFIRM_PAYMENT_ACCUMULATION'),
          isConfirm: true,
          mallNoValue: this.paymentListSync[0].mallNo,
          mallNoMust: true,
          notificationMethodType: this.notificationTools,
          templatetype: 'ISSUE_COUPON_MEMBER',
        },
      });
      goNext = result.state === PopupClose.CONFIRM;
    } else {
      // (유효성 체크 시 이상이 없을 경우)
      const result = await throwMessagePopup(
        this.$t('PROMOTION.COUPON.MSG_PAYMENTS_CONFIRM', [this.paymentListSync.length]),
        true,
      );
      goNext = result.state === PopupClose.CONFIRM;
    }
    if (goNext) {
      const request = {
        data: {
          useNotification: this.useNotification,
          couponIssueTargetType: 'MEMBER',
          couponIssueTargetMember: {
            couponIssueMemberType: 'MEMBER_NO',
            memberNos: this.memberList,
          },
          mallNo: this.paymentListSync[0].mallNo,
          couponNos: this.paymentListSync.map(item => item.no),
          couponIssueTimeType: this.couponIssueTimeType,
          issueReason: this.issueReason,
        },
      } as PostCouponsIssuePlansRequest;
      if (this.useNotification) {
        request.data.notificationTools = this.notificationTools as PromotionNotificationToolsType[];
      }
      if (this.couponIssueTimeType === 'SCHEDULED_ISSUE') {
        request.data.requestYmdt = this.requestYmdt;
      }
      await this.$api.postCouponsIssuePlans(request);
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_COUPON_PAYMENTS')).then(() => {
        this.$emit('payments');
      });
    }
  }

  public focus() {
    document.getElementById('coupon_payments_info').scrollIntoView();
  }
  private mounted() {
    this.focus();
  }
}
