




















































import { Component, Ref } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';

import Editor from '@/components/common/summernote/Editor.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import SelectBox from '@/components/common/SelectBox.vue';

import sanitizeHtml from '@/utils/sanitizeHtml';
// requests
import { getContractsEntryContractsRequest } from '@/views/contents/partner/modification/apis/apiRequests';

@Component({
  components: { Editor, NoticeBox, SelectBox },
})
export default class EntryContractFilePopupReadOnly extends WindowPopupMainVue {
  @Ref()
  private readonly editorRef?: Editor;

  private latestAgreedDateTime = '';
  private agreedDateTimes = [];
  private contents = new Map();

  private handleContentChange(value: string) {
    this.editorRef.setHtml(sanitizeHtml(this.contents.get(value)));
  }

  private async getEntryContracts() {
    const request = getContractsEntryContractsRequest(this.data.contractNo.toString(), true);

    const { data: entryContracts } = await this.$api.getContractsEntryContracts(request);
    const latestEntryContract = entryContracts[entryContracts.length - 1];

    return { entryContracts, latestEntryContract };
  }

  private createAgreedDateTimesSelectBoxOption({ agreedDateTime }) {
    return {
      value: agreedDateTime,
      name: agreedDateTime,
    };
  }

  private async init() {
    const { entryContracts, latestEntryContract } = await this.getEntryContracts();

    // 최신
    this.latestAgreedDateTime = latestEntryContract.agreedDateTime;
    this.editorRef.setHtml(sanitizeHtml(latestEntryContract.contents));

    // 전체
    this.agreedDateTimes = entryContracts.map(this.createAgreedDateTimesSelectBoxOption).reverse();
    this.contents = new Map(
      entryContracts.map(({ agreedDateTime, contents }) => [agreedDateTime, sanitizeHtml(contents)]),
    );
  }

  mounted() {
    this.init();
  }
}
