

























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Amounts, ClaimOption } from 'ncp-api-supporter';
import { ClaimType } from '@/types/claim';
import { exchangeImmediateDiscountedAmt } from '@/components/popup/claim/claimFormula';
import AmountMixin from '@/components/popup/claim/AmountMixin';
import { ClaimCoupon } from 'ncp-api-supporter/dist/types/modules/claim/claims';

// 주의: UI는 비슷하지만 안에서 동작하는게 취소,반품과 다름. 이름은 같지만 path는 다른 두 컴포넌트로 나뉘어져있음.
@Component
export default class AmountDetail extends Mixins(AmountMixin) {
  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  @Prop({ required: true })
  private readonly amounts!: Amounts;

  @Prop({ required: true })
  private readonly coupon!: ClaimCoupon;

  @Prop({ required: true })
  private readonly exchangeOption!: ClaimOption;

  @Prop({ required: true })
  private readonly claimedOption!: ClaimOption;

  @Prop({ required: true })
  private readonly exchangeDifferenceAdjustAmt!: number;

  @Prop({ required: true })
  private readonly exchangeDifferenceAdjustReason!: string;

  // 상품합계차액 row (+)
  private productAmt = {
    exchangeProductAmt: 0, // 교환상품
    returnProductAmt: 0, // 반품상품

    difference() {
      // 상품합계차액
      return this.exchangeProductAmt - this.returnProductAmt;
    },
  };

  // 추가할인차액 row (-) // 오픈스펙에서 제외
  // private additionalDiscountAmt = {
  //   exchangeProductDiscountAmt: 0, // 교환상품
  //   returnProductDiscountAmt: 0, // 반품상품
  //
  //   get difference() {
  //     return this.exchangeProductDiscountAmt - this.returnProductDiscountAmt;
  //   },
  // };

  // 상품쿠폰 재계산 row (-)
  private couponRecalculated = {
    recalculatedAmt: 0,
    productCoupons: [],
  };

  // 교환금액합계 row (a)
  private exchangeAmt = {
    totalExchangeAmt: 0,
  };

  created() {
    this.setProductAmt();
    // this.setAdditionalDiscountAmt(); // 오픈스펙에서 제외
    this.setCoupon();
    this.setExchangeAmt();
  }

  private setProductAmt() {
    this.productAmt.exchangeProductAmt = exchangeImmediateDiscountedAmt({
      claimOptions: this.exchangeOption,
      needSum: true,
    });
    this.productAmt.returnProductAmt = exchangeImmediateDiscountedAmt({
      claimOptions: this.claimedOption,
      needSum: true,
    });
  }

  private setCoupon() {
    const recalculatedAmt = this.amounts.refund.coupon.productCouponDiscountAmt;
    const productCoupons = this.coupon.productCoupons;

    if (!productCoupons) {
      this.couponRecalculated = { ...this.coupon, productCoupons: [], recalculatedAmt };
      return;
    }

    this.couponRecalculated = { recalculatedAmt, productCoupons };
  }

  private setExchangeAmt() {
    const { productCouponDiscountAmt } = this.amounts.refund.coupon;

    // 상품쿠폰 재계산(productCouponDiscountAmt) 표기는 minusComma, 계산은 +로 해야한다.
    this.exchangeAmt.totalExchangeAmt =
      this.productAmt.difference() + productCouponDiscountAmt - (this.exchangeDifferenceAdjustAmt || 0); // additionalDiscountAmt 차액 계산이 오픈스펙 제외로 빠져있다.
  }
}
