























































import { Component, Prop, Watch } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import unsavedDetector from '@/directives/input/unsavedDetector';
import { GetKakaoAutoTemplatesGroup, KakaoTemplateSettingData, KakaoAutoTemplatesButton } from 'ncp-api-supporter';
import { RadioBoxGroupOption } from '@/helpers/type';
import { PopupClose, PopupResult, ThrowWindowPopupProps, throwWindowPopupWithProps } from '@/helpers/popup';
import { namespace } from 'vuex-class';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { getCurrentMallNo } from '@/utils/mall';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

const member = namespace('member');
export type SelectTemplate = Omit<KakaoTemplateSettingData, 'used'>;

@Component({
  components: { RadioGroup, ToolTip },
  directives: { unsavedDetector },
})
export default class KakaoTemplate extends WindowPopupMainVue {
  @Prop({ required: true })
  private readonly templateData!: GetKakaoAutoTemplatesGroup;
  @Prop({ required: true })
  private readonly contentIndex!: number;
  @Prop({ default: false })
  private readonly isNotification: boolean;
  @member.Action('changeKakaoTemplateSetting')
  changeKakaoTemplateSetting!: (settingData: KakaoTemplateSettingData) => void;

  private isActiveTag = false;
  private template: GetKakaoAutoTemplatesGroup = { ...this.templateData };

  @Watch('templateData')
  private setTemplateData() {
    this.template = { ...this.templateData };
  }

  private usedOption: RadioBoxGroupOption<boolean> = {
    name: `ipName-${this.contentIndex}`,
    data: [
      { id: `used-${this.contentIndex}`, value: true, display: 'MEMBER.SMS_AUTO.USED' },
      { id: `notUsed-${this.contentIndex}`, value: false, display: 'MEMBER.SMS_AUTO.NOT_USED' },
    ],
  };

  private get hasButtons(): boolean {
    return this.template.buttons?.length > 0;
  }

  private get isPopupPage(): boolean {
    return this.$route.name === 'TemplateSelectManager';
  }

  private get isUsedTemplate(): boolean {
    return this.template.apply;
  }

  private get canShowSecondButtonName(): boolean {
    return this.template.buttons[0].name.length < 8 && this.template.buttons[1]?.name.length < 9;
  }

  private get moreButtonLength(): number {
    const displayButtonLength = this.canShowSecondButtonName ? 2 : 1;
    const templateButtonLength = this.template.buttons ? this.template.buttons.length : 0;
    return templateButtonLength - displayButtonLength;
  }

  private get hasStopped(): boolean {
    return this.template.status === 'STOP';
  }

  private get hasNoTemplate(): boolean {
    return this.template.templateContent === this.$t('MEMBER.KAKAO.NO_TEMPLATE').toString();
  }

  private get noAvailableTemplate(): boolean {
    const isBasicTemplate = this.template.templateName.slice(0, 2) === 's:';
    const isWaitTemplate = this.template.inspectionStatus === 'APPROVE' && this.template.status === 'WAIT';
    return this.hasStopped || (this.template.used && isBasicTemplate && isWaitTemplate);
  }

  private setStopTemplate() {
    this.template.templateContent = this.$t('MEMBER.KAKAO.NO_TEMPLATE').toString();
    this.template.templateName = this.$t('MEMBER.KAKAO.NO_TEMPLATE').toString();
    this.template.used = false;
  }

  mounted() {
    if (!this.noAvailableTemplate) return;
    this.hasStopped && this.setStopTemplate();
  }

  @Watch('template', { deep: true })
  private changeUsedOption() {
    this.changeKakaoTemplateSetting({
      used: this.template.used,
      templateType: this.template.templateType,
      templateNo: this.template.templateNo,
    });
  }

  private get selectTemplate(): KakaoTemplateSettingData & {
    templateNo: number;
    templateContent: string;
    templateName: string;
    buttons: KakaoAutoTemplatesButton[];
  } {
    const { templateType, templateCode, templateNo, templateContent, templateName, buttons } = this.template;
    return {
      used: true,
      templateType,
      templateCode,
      templateNo,
      templateContent,
      templateName,
      buttons,
    };
  }

  private showMoreTag() {
    this.isActiveTag = !this.isActiveTag;
  }

  private openChangeTemplatePopup() {
    const data = {
      templateType: this.$route.query.templateType || 'MEMBER',
      templateTypeName: this.template.templateGroupTypeLabel,
    };
    const templateSelectManagerPopup: ThrowWindowPopupProps = {
      componentName: 'TemplateSelectManager',
      data,
      size: 'md',
      onClose: ({ state, data }: PopupResult) => {
        if (state === PopupClose.CLOSE) return;
        const { templateNo, templateContent, used, templateName, buttons } = data;
        this.template = {
          ...this.template,
          templateNo,
          templateContent,
          used,
          templateName,
          buttons,
        };
      },
      query: { mallNo: getCurrentMallNo(this) },
    };
    throwWindowPopupWithProps(templateSelectManagerPopup);
  }
}
