













































import { Vue, Component, Watch, Prop, PropSync, Ref } from 'vue-property-decorator';

import Grid from '@/components/common/grid/Main.vue';
import PaymentsInfo from '@/components/promotion/basic/coupons/list/PaymentsInfo.vue';

import { CouponListSearchDefaultQuery, getCouponListGridOption } from '@/const/contents/promotion';
import {
  PromotionSearchKeywordType,
  PromotionSearchDateType,
  PromotionCouponType,
  PromotionIssueType,
  GetCouponsRequest,
} from 'ncp-api-supporter';
import { CouponPayment, CheckGridEventProps, SelectedCouponInfo } from '@/types';
import { throwMessagePopup, PopupClose, throwPopup, throwWindowPopup } from '@/helpers/popup';
import { formatCurrency } from '@/utils/numberFormat';
import { getStrYMDHMSS } from '@/utils/dateFormat';
import { mall } from '@/utils/mall';

@Component({
  components: {
    Grid,
    PaymentsInfo,
  },
})
export default class CouponList extends Vue {
  private initOver = false;
  @Prop({ required: false, default: false })
  private readonly popupType: boolean;
  @Prop({ required: false, default: '' })
  private readonly mallNo: string;
  @Prop({ required: false, default: '' })
  private readonly popType: string;
  @PropSync('selectedCoupon')
  private selectedCouponSync!: SelectedCouponInfo;
  @Ref('couponGrid')
  private couponGridRef!: Grid;

  // grid
  private coupons = [];
  private totalCount = 0;
  private gridProps = null;
  private selected = [];
  private onItemClicked({ columnName, rowKey, targetType, nativeEvent }): void {
    if (targetType === 'cell') {
      switch (columnName) {
        case 'edit':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'A') {
            this.$router.push({
              path: '/promotion/basic/coupons/modify',
              query: { couponNo: rowKey },
            });
          }
          break;
        case 'copy':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'A') {
            const routeData = this.$router.resolve({
              path: '/promotion/basic/coupons/copy',
              query: { couponNo: rowKey },
            });
            window.open(routeData.href, '_blank');
          }
          break;
        case 'couponStatusTypeLabel':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'A') {
            const clickCoupon = this.coupons.find(coupon => coupon.no === rowKey);
            if (clickCoupon) {
              throwPopup({
                name: 'IssueStopReason',
                data: {
                  isCountOver: clickCoupon.issueLimitCnt <= clickCoupon.totalIssueCnt,
                  isPeriodEnd: clickCoupon.issueEndYmdt < getStrYMDHMSS(new Date()),
                  issueStopped: clickCoupon.issueStopped,
                },
              });
            }
          }
          break;
        case 'issueType':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'BUTTON') {
            const clickCoupon = this.coupons.find(coupon => coupon.no === rowKey);
            if (clickCoupon) {
              if (nativeEvent.target.dataset.type === 'code_copy') {
                const oInput = document.createElement('input');
                oInput.value = clickCoupon.promotionCode;
                document.body.appendChild(oInput);
                oInput.select();
                document.execCommand('copy');
                document.body.removeChild(oInput);
              } else {
                throwWindowPopup(
                  'CouponCodeManagementPopup',
                  { couponNo: rowKey, totalCount: clickCoupon.issueLimitCnt },
                  'md',
                );
              }
            }
          }
          break;
        case 'issuance':
        case 'totalIssueCntByAdmin':
        case 'totalUseCnt':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'A') {
            const clickCoupon = this.coupons.find(coupon => coupon.no === rowKey);
            if (clickCoupon) {
              const showDiscountAmt = clickCoupon.fixed
                ? formatCurrency(clickCoupon.discountAmt) + this.$t('WON')
                : `${clickCoupon.discountPercent}% (${this.$t('MAX')} ${formatCurrency(
                    clickCoupon.maxDiscountAmt,
                  )} ${this.$t('WON')})`;
              // 일반 발급 건만 출력되는 상태로 발급/사용현황 팝업 출력
              // 운영자 발급 건만 출력되는 상태로 발급/사용현황 팝업 출력
              // 사용상태가 사용완료인 건만 출력되는 상태로 발급/사용현황 팝업 출력
              const popData = {
                couponNo: rowKey,
                type: columnName,
                showDiscountAmt,
                discount: clickCoupon,
              };
              throwWindowPopup('CouponIssuanceUsePopup', popData, 'xlg');
            }
          }
          break;
      }
    }
  }
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
    if (this.popupType) {
      this.selectedCouponSync.coupons = this.selected;
      this.selectedCouponSync.couponItems = [];
      this.selectedCouponSync.coupons.forEach(item => {
        this.selectedCouponSync.couponItems.push({
          counponId: item.toString(),
          couponName: checkProps.instance.getValue(item, 'couponName').toString(),
        });
      });
    }
  }

  // payment
  private showPayments = false;
  private paymentList: CouponPayment[] = [];
  private async selectPaymentCoupon() {
    // 선택한 쿠폰이 없을 경우
    if (this.selected.length === 0) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_NOT_SELECTED_COUPON'));
      return;
    }

    // 선택한 쿠폰에 쇼핑몰이 다른 쿠폰이 선택되어 있을 경우
    let mallNos = [];
    let selectedCoupons = this.coupons.filter(coupon => {
      if (this.selected.some(selectNo => selectNo === coupon.no)) {
        mallNos.push(coupon.mallNo);
        return true;
      } else {
        return false;
      }
    });
    if (this.paymentList.length > 0) {
      mallNos.push(this.paymentList[0].mallNo);
    }
    mallNos = Array.from(new Set(mallNos));
    if (mallNos.length > 1) {
      throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_NOT_ONLY_MALL'));
      return;
    }

    // 선택한 쿠폰에 ‘발급종료’ 쿠폰이 선택되어 있을 경우
    let haveIssueEnd = false;
    selectedCoupons = selectedCoupons.filter(coupon => {
      if (coupon.couponStatusType === 'ISSUE_END') {
        haveIssueEnd = true;
        return false;
      } else {
        return true;
      }
    });
    let goNext = true;
    if (haveIssueEnd) {
      const result = await throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_SET_OTHERS'), true);
      goNext = result.state === PopupClose.CONFIRM;
    }

    if (goNext) {
      // 선택한 쿠폰이 11개 이상인 경우
      selectedCoupons = selectedCoupons.filter(selectedCoupon => {
        return !this.paymentList.some(payment => payment.no === selectedCoupon.no);
      });
      if (this.paymentList.length + selectedCoupons.length > 10) {
        throwMessagePopup(this.$t('PROMOTION.COUPON.MSG_CONFIRM_GT_10'));
        return;
      }
      this.paymentList.push(
        ...selectedCoupons.map(selectedCoupon => {
          const showDiscountAmt = selectedCoupon.fixed
            ? formatCurrency(selectedCoupon.discountAmt) + this.$t('WON')
            : `${selectedCoupon.discountPercent}%(${this.$t('MAX') +
                formatCurrency(selectedCoupon.maxDiscountAmt) +
                this.$t('WON')})`;

          return {
            no: selectedCoupon.no,
            couponName: selectedCoupon.couponName,
            showDiscountAmt,
            mallNo: selectedCoupon.mallNo,
            mallName: selectedCoupon.mallName,
            fixed: selectedCoupon.fixed,
            discountAmt: selectedCoupon.discountAmt,
            discountPercent: selectedCoupon.discountPercent,
            maxDiscountAmt: selectedCoupon.maxDiscountAmt,
          };
        }),
      );
      this.showPayments = true;
      this.$nextTick(() => {
        if (this.paymentsInfoRef) {
          this.paymentsInfoRef.focus();
        }
      });
    }
  }
  private payments() {
    this.showPayments = false;
    this.paymentList = [];
    this.couponGridRef.uncheckAll();
  }

  @Ref()
  private readonly paymentsInfoRef: PaymentsInfo;

  // search
  @Watch('$route.query')
  private onQueryStringChanged() {
    this.search();
  }
  private searchParams: null | GetCouponsRequest['params'] = null;
  private search() {
    const searchQuery = CouponListSearchDefaultQuery(this.popupType, this.mallNo, this.popType);
    if (this.$route.query) {
      const urlQuery = this.$route.query;
      if (urlQuery.mallNo) {
        if (urlQuery.mallNo === 'ALL') {
          let mallNos = '';
          const malls = mall.mallsSortedByCreationDate;
          if (malls && malls.length > 0) {
            malls.forEach((mall, idx) => {
              if (mall.used) {
                if (idx !== 0) {
                  mallNos += ',';
                }
                mallNos += mall.mallNo;
              }
            });
          }
          searchQuery.mallNos = mallNos;
        } else {
          searchQuery.mallNos = urlQuery.mallNo.toString();
        }
      }
      if (urlQuery.searchKeywordType && urlQuery.keyword) {
        searchQuery.searchKeywordType = urlQuery.searchKeywordType as PromotionSearchKeywordType;
        searchQuery.keyword = urlQuery.keyword as string;
      }
      if (urlQuery.searchDateType) {
        searchQuery.searchDateType = urlQuery.searchDateType as PromotionSearchDateType;
      }
      if (urlQuery.startYmd) {
        searchQuery.startYmd = urlQuery.startYmd as string;
      }
      if (urlQuery.endYmd) {
        searchQuery.endYmd = urlQuery.endYmd as string;
      }
      if (urlQuery.couponType) {
        searchQuery.couponType = urlQuery.couponType as PromotionCouponType;
      }
      if (urlQuery.issueType) {
        searchQuery.issueType = urlQuery.issueType as PromotionIssueType;
      }
      if (urlQuery.statusTypes) {
        searchQuery.statusTypes = urlQuery.statusTypes as string;
      }
      if (urlQuery.page) {
        searchQuery.page = Number(urlQuery.page);
      }
      if (urlQuery.size) {
        searchQuery.size = Number(urlQuery.size);
      }
    }
    const request = {
      params: searchQuery,
    } as GetCouponsRequest;
    this.$api.getCoupons(request).then(response => {
      if (response && response.status === 200) {
        this.coupons = response.data.contents.map(coupon => {
          return {
            ...coupon,
            couponStatusTypeLabel: this.$t(`PROMOTION.COUPON.STATUS_TYPE_${coupon.couponStatusType}`),
          };
        });
        this.totalCount = response.data.totalCount;

        this.searchParams = request.params;
      }
    });
  }
  private created() {
    this.gridProps = getCouponListGridOption(this.popupType);
    this.initOver = true;
    this.search();
  }
}
