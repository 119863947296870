




















import { Vue, Component, Model, Prop, Ref } from 'vue-property-decorator';
import { OptionData, ValueType } from '@/helpers/type';
import unsavedDetector from '@/directives/input/unsavedDetector';

@Component({
  directives: { unsavedDetector },
})
export default class SelectBox extends Vue {
  /*
   * 이 컴포넌트는 프리미엄 어드민의 CompSelectBox에서 유래되었습니다.
   * */
  @Prop({ default: '전체' }) private needAllText!: string;
  @Prop({ default: false }) private needAll!: boolean;
  @Prop({ default: false }) private needFirst!: boolean;
  @Prop({ default: false }) private nonI18nConvert!: boolean;
  @Prop({ default: false }) private disabled!: boolean;
  //@Prop({required: true, default: function(){return {data: []}}}) private options!: FormInputOption;
  @Prop({ required: true }) private data!: OptionData<ValueType>[];
  @Prop({ default: 'value' }) private selectValue!: string | number;
  @Prop({ default: 'value' }) private selectKey!: string | number;
  @Prop({ default: 'name' }) private selectDisplayName!: string;
  @Prop({ default: false }) private readonly needsIndex!: boolean;
  @Model('change') readonly value!: number | string;
  @Ref() private readonly select: HTMLSelectElement;

  getKey(entity, selectKey, index) {
    const key = entity[selectKey];
    return this.needsIndex ? key + index : key;
  }

  get selectedValue() {
    const needAllNumberValue = this.needAll && typeof this.value === 'number' && this.value === 0;
    if (this.value === undefined || needAllNumberValue) return '';
    if (this.value === null) return '';

    return this.value; // || this.data[0][this.selectValue];
  }
  set selectedValue(val) {
    this.$emit('change', val, this.value, this.$refs.select);
  }

  private onClickSelectBox(event) {
    this.$emit('click', event);
  }

  private onFocusSelectBox(event) {
    this.$emit('focus', event);
  }

  public focus() {
    this.select.focus();
  }
  public blur() {
    this.select.blur();
  }
}
