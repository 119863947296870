

















import Slick from 'vue-slick';
import { Vue, Component, Prop } from 'vue-property-decorator';
/**
 * 레이어 팝업
 * 미리보기
 * */
@Component({
  components: { Slick },
})
export default class Preview extends Vue {
  @Prop() private data;
  @Prop() private readonly onNegativeClick!: () => void;
}
