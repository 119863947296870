















import { Component, Prop, Vue } from 'vue-property-decorator';
import { readExcelFile, validateFileNameExtension } from '@/utils/webExcel';
import {
  convertToI18n,
  getDefaultCombinationOptionExcel,
  excelColumn,
  isMoreThanImmediateDiscountPrice,
  isNotTenUnit,
  SEPARATOR,
  STOCK_STATUS_TYPE,
} from '@/views/popups/product/productAdd/ProductOption/ProductOption';
import { ParsedObject } from '@/utils/fileReader';
import { getStorageFile } from '@/utils/storage';
import { CombinationOptionType, InputNumber, OptionExcelDataType } from '@/types';
import { TargetObject } from '@/utils/keyReplace';
import { GetProductsExcelSampleUrlRequest, YorN } from 'ncp-api-supporter';
const url = require('url');
@Component({})
export default class CombinationOptionForm extends Vue {
  @Prop({ required: true }) private readonly immediateDiscountPrice!: InputNumber;
  private excelColumn = excelColumn();
  private excelData: TargetObject[] = [];

  private async onClickSampleExcelDownload() {
    //TODO api 판매수수료 설정 -> 공급가 입력시 공급가 항목 노출
    const request: GetProductsExcelSampleUrlRequest = {
      params: {
        sampleType: 'OPTION_COMBINATION',
      },
    };
    try {
      const { data } = await this.$api.getProductsExcelSampleUrl(request);
      const {
        query: { fileName, container },
      } = url.parse(data.sampleUrl, true);
      const excelFileName = `${convertToI18n('EXCEL_SAMPLE_FILE_NAME')}.xlsx`;
      await getStorageFile(fileName, container, excelFileName);
    } catch (e) {
      console.error(e);
    }
  }

  private onChangeExcelBatchRegistration({ target }: { target: HTMLInputElement }): void {
    const file = target.files[0];
    const EXTENSION_ERROR_MESSAGE = 'COMMON.EXCEL.TIP_ONLY_EXCEL';
    const excelFileName = validateFileNameExtension(file, EXTENSION_ERROR_MESSAGE);
    if (excelFileName === '') return;
    readExcelFile(file, this.excelColumn, this.readExcelFileCallBack);
    target.value = null;
  }

  private readExcelFileCallBack(data: ParsedObject[]): void {
    this.excelData = data;
    const defaultColumn = Object.keys(this.excelColumn);
    const registeredColumn = data.map(excelRow => Object.keys(excelRow));
    const registeredNameList = registeredColumn.map(row => row.filter(key => !defaultColumn.includes(key)));
    const registeredColumnExcludingName = registeredColumn.map(row => row.filter(key => defaultColumn.includes(key)));

    const isSuitableExcelData = registeredNameList.every((nameList, index) =>
      this.validateExcelData(nameList, registeredColumnExcludingName[index], index),
    );
    if (!isSuitableExcelData) return;
    this.convertExcelData(registeredNameList[0]);
  }

  private validateExcelData(
    registeredNameList: string[],
    registeredColumnExcludingName: string[],
    index: number,
  ): boolean {
    //TODO 옵션 이름 최대 5개까지 제한 - 기획서 없어서 확인 필요
    const MAX_OPTION_NAME_CNT = 5;
    if (registeredNameList.length > MAX_OPTION_NAME_CNT) {
      alert(convertToI18n('TIP_MAX_5'));
      return false;
    }

    if (!registeredNameList.every(name => this.validateOptionNameAndValue(name))) return false;
    if (!this.validateOptionPrice(index)) return false;
    if (!this.validateSupplyPrice(index)) return false;
    if (!this.validateStockCnt(index)) return false;
    if (!this.validateManagementCd(index)) return false;
    if (!this.validateUseYn(index)) return false;
    return this.validateImages(registeredColumnExcludingName, index);
    //TODO 옵션명 빈값 + (해당 옵션의) 옵션값 입력
    //TODO 옵션명 입력 + (해당 옵션의) 옵션값 전체 빈값
  }

  private validateImages(registeredColumnExcludingName: string[], index: number): boolean {
    const isMainImage = registeredColumnExcludingName.includes('mainImageUrl');
    const isDetailImage = registeredColumnExcludingName.includes('detailImageUrl');
    const urlFormatList = ['http://', 'https://'];
    const extensions = ['jpeg', 'jpg', 'bmp', 'png', 'gif'];
    if (!isMainImage && !isDetailImage) return true;

    // 상세이미지가 입력된 상태에서 대표이미지 미입력 시
    if (isDetailImage && !isMainImage) {
      alert(this.$t('PRODUCT.OPTION.CHECK_OPTION_IMG_REQUIRED_REPRESENT_IMG'));
      return false;
    }

    // 대표이미지가 입력된 상태에서 상세이미지 미입력 시
    if (!isDetailImage && isMainImage) {
      alert(this.$t('PRODUCT.OPTION.CHECK_REPRESENT_IMG_REQUIRED_OPTION_IMG'));
      return false;
    }

    const mainImageUrl = this.excelData[index].mainImageUrl.toLocaleString();
    //대표이미지 url 형식인지 확인
    if (!mainImageUrl.startsWith(urlFormatList[0]) && !mainImageUrl.startsWith(urlFormatList[1])) {
      alert(this.$t('PRODUCT.OPTION.CHECK_REPRESENT_IMG_URL'));
      return false;
    }

    let isAllowableExtensionForMainImage = false;
    extensions.forEach(extension => {
      if (mainImageUrl.endsWith(extension)) {
        isAllowableExtensionForMainImage = true;
      }
    });

    // 입력된 대표이미지의 확장자가 허용 가능한 확장자가 아닌 경우
    if (!isAllowableExtensionForMainImage) {
      alert(this.$t('PRODUCT.OPTION.CHECK_REPRESENT_IMG_TYPE'));
      return false;
    }

    this.excelData[index].detailImageUrl = this.convertImageUrlToImageUrlList(index);

    //상세이미지 url 형식인지 확인
    let isUrlFormat = true;
    this.excelData[index].detailImageUrl.forEach(imageUrl => {
      if (!imageUrl.startsWith(urlFormatList[0]) && !imageUrl.startsWith(urlFormatList[1])) {
        isUrlFormat = false;
        return;
      }
    });

    // 입력된 상세이미지가 URL 형태가 아닌 경우
    if (!isUrlFormat) {
      alert(this.$t('PRODUCT.OPTION.CHECK_OPTION_IMG_URL'));
      return false;
    }

    let isAllowableExtensionForDetailImage = false;
    this.excelData[index].detailImageUrl.forEach(imageUrl => {
      extensions.forEach(extension => {
        if (imageUrl.endsWith(extension)) {
          isAllowableExtensionForDetailImage = true;
        }
      });

      if (!isAllowableExtensionForDetailImage) return;
    });

    // 입력된 상세이미지의 확장자가 허용 가능한 확장자가 아닌 경우
    if (!isAllowableExtensionForDetailImage) {
      alert(this.$t('PRODUCT.OPTION.CHECK_OPTION_IMG_TYPE'));
      return false;
    }

    //TODO 이미지 개수 제한이 안나와있음
    // const MAX_DETAIL_IMAGE_LENGTH = 5;
    //
    // if (this.excelData[index].detailImageUrl.length > MAX_DETAIL_IMAGE_LENGTH) {
    //   alert('상세이미지는 5개까지 가능');
    //   return false;
    // }

    return true;
  }

  private convertImageUrlToImageUrlList(index: number): string[] {
    return this.excelData[index].detailImageUrl
      .replace(/\r\n|\n|\r/g, SEPARATOR)
      .split(SEPARATOR)
      .filter(image => image !== '');
  }

  //재고수량 검사
  private validateStockCnt(index: number): boolean {
    const { stockCnt } = this.excelData[index];

    if (stockCnt === undefined) {
      this.addDefaultValue('stockCnt', index);
      return true;
    }

    if (typeof stockCnt !== 'number' || stockCnt < 0) {
      //TODO 기획서에는 재고수량 수자 확인이 없음
      alert('재고수량은 숫자만 입력 가능합니다.');
      return false;
    }

    const MAX_STOCK_CNT_LENGTH = 8;

    // 입력된 재고수량이 8자를 초과한 경우
    // if (this.isString(stockCnt, 'stockCnt', index) || this.isWithinRange(stockCnt, MAX_STOCK_CNT_LENGTH)) return true;
    if (stockCnt.toString().length > MAX_STOCK_CNT_LENGTH) {
      alert(this.$t('PRODUCT.OPTION.STOCK_COUNT_UNDER_BILLION'));

      return false;
    }

    return true;
  }

  private validateManagementCd(index: number): boolean {
    const { optionManagementCd } = this.excelData[index];
    if (optionManagementCd === undefined) return true;

    // 입력된 옵션관리코드가 45자를 초과한 경우
    const MAX_MANAGEMENT_CD_LENGTH = 45;
    const strOptionManagementCd = optionManagementCd.toString();
    if (strOptionManagementCd.length > MAX_MANAGEMENT_CD_LENGTH) {
      alert(this.$t('PRODUCT.OPTION.OPTION_MANAGEMENT_CD_MODIFY_45'));
      return false;
    }

    // 입력된 옵션 관리 코드에 입력불가한 문자 (‘”<>\`)가 포함된 경우
    const regex = /['"‘”<>₩`]/g;
    if (regex.test(strOptionManagementCd)) {
      alert(this.$t('PRODUCT.OPTION.OPTION_MANAGEMENT_CD_INVALID_TEXT'));
      return false;
    }
    return true;
  }

  private validateUseYn(index: number): boolean {
    const { useYn } = this.excelData[index];

    if (useYn === undefined) {
      this.excelData[index].useYn = 'Y';
      return true;
    }

    // 사용여부에 Y,N 이외의 값이 입력된 경우
    const useYnUpperCase = useYn.toLocaleUpperCase();
    if (useYnUpperCase !== 'Y' && useYnUpperCase !== 'N') {
      alert(this.$t('PRODUCT.OPTION.TIP_USEYN_INVALID'));
      return false;
    }
    return true;
  }

  // private isWithinRange(value: RowKey, maxLength: number): boolean {
  //   return value === undefined || value.toString().length <= maxLength;
  // }
  //
  // private isString(value: RowKey, key: string, index: number): boolean {
  //   if (typeof value !== 'string') return false;
  //   this.excelData[index][key] = 0;
  //   return true;
  // }

  private addDefaultValue(key: string, index: number) {
    this.excelData[index][key] = 0;
  }

  //옵션가 검사
  private validateOptionPrice(index: number): boolean {
    const { addPrice } = this.excelData[index];
    const MAX_PRICE_LENGTH = 9;

    if (addPrice === undefined) {
      this.addDefaultValue('addPrice', index);
      return true;
    }

    // 입력된 옵션가가 숫자가 아닌 경우,
    if (typeof addPrice !== 'number') {
      alert(this.$t('PRODUCT.OPTION.OPTION_VALUE_NUMBER_ONLY'));
      return false;
    }

    // 입력된 옵션가가 9자를 초과한 경우
    if (addPrice.toString().length > MAX_PRICE_LENGTH) {
      alert(this.$t('PRODUCT.OPTION.PLEASE_UNDER_10_BILLION'));
      return false;
    }

    // 옵션가가 1원 단위로 입력된 경우
    if (isNotTenUnit(addPrice)) {
      alert(this.$t('PRODUCT.OPTION.TIP_OPTION_PRICE_10'));
      return false;
    }

    // 입력된 마이너스 옵션가가 ‘판매가-즉시할인금액’보다 클 경우
    if (isMoreThanImmediateDiscountPrice.call(this, addPrice)) {
      alert(this.$t('PRODUCT.OPTION.OPTION_PRICE_MESSAGE'));

      return false;
    }

    return true;
  }

  //공급가 검사
  private validateSupplyPrice(index: number): boolean {
    const { purchasePrice } = this.excelData[index];
    const MAX_SUPPLY_PRICE_LENGTH = 9;

    if (purchasePrice === undefined) {
      this.addDefaultValue('purchasePrice', index);
      return true;
    }

    // 입력된 공급가가 숫자가 아닌 경우
    if (typeof purchasePrice !== 'number') {
      alert(this.$t('PRODUCT.OPTION.SUPPLY_NUMBER_ONLY'));
      return false;
    }

    // 입력된 공급가가 9자를 초과한 경우
    if (purchasePrice.toString().length > MAX_SUPPLY_PRICE_LENGTH) {
      alert(this.$t('PRODUCT.OPTION.PLEASE_SUPPLY_UNDER_10_BILLION'));
      return false;
    }

    return true;
  }

  private validateOptionNameAndValue(name: string): boolean {
    const optionValueList: string[] = this.excelData.map(excelRow => (excelRow[name] = excelRow[name]?.toString()));

    //옵션명이 255자 이상일 시
    const MAX_NAME_LENGTH = 255;
    if (name.length > MAX_NAME_LENGTH) {
      alert(this.$t('PRODUCT.OPTION.PLEASE_MODIFY'));
      return false;
    }

    //옵션명이 입력된 상태에서 옵션값 미입력 시
    if (this.isEmptyValue(optionValueList)) {
      alert(this.$t('PRODUCT.OPTION.TIP_OPTION_VALUE'));
      return false;
    }

    // 옵션값이 255자 이상일 시
    if (this.checkValueLength(optionValueList)) {
      alert(this.$t('PRODUCT.OPTION.PLEASE_VALUE_MODIFY'));
      return false;
    }

    // 옵션명에 [|] 포함될 시
    if (name.includes(SEPARATOR)) {
      alert(convertToI18n('TIP_SPECIAL_TEXT'));
      return false;
    }

    return true;
  }

  private isEmptyValue(valueList: string[]): boolean {
    return valueList.some(value => value === undefined);
  }

  private checkValueLength(valueList: string[]): boolean {
    const MAX_VALUE_LENGTH = 255;
    return valueList.some(value => value.length > MAX_VALUE_LENGTH);
  }

  private convertExcelData(registeredNameList: string[]): void {
    const copiedData = [...this.excelData];
    const optionName = registeredNameList.join(SEPARATOR);
    const optionList: CombinationOptionType<string[]>[] = [];

    copiedData.forEach((row, index) => {
      const optionInfo: CombinationOptionType<string[]> = { optionName, optionValue: [] };
      const optionValues: string[] = [];
      registeredNameList.forEach(name => {
        optionValues.push(row[name]);
        delete row[name];
      });
      optionInfo.optionValue = optionValues;
      const combinedOptionInfo: OptionExcelDataType = { ...optionInfo, ...row };
      this.setOptionList(combinedOptionInfo, optionList, index);
    });
    this.sortByOptionValue(optionList);
    this.$emit('update:option-data', optionList, this.getImageIndexes(optionList));
  }

  private setOptionList(
    combinedOptionInfo: OptionExcelDataType,
    optionList: CombinationOptionType<string[]>[],
    index: number,
  ): void {
    const combinationOption: CombinationOptionType<string[]> = this.convertExcelImageToOptionImage(combinedOptionInfo);
    optionList[index] = Object.assign(getDefaultCombinationOptionExcel(), combinationOption);
    optionList[index].useYn = optionList[index].useYn.toUpperCase() as YorN;
    this.setSaleStatusType(optionList[index]);
  }

  private convertExcelImageToOptionImage(option: OptionExcelDataType): CombinationOptionType<string[]> {
    if (option.mainImageUrl === undefined) return option;

    // 옵션 대표이미지 추가
    option.optionImages = [{ mainYn: 'Y', mallOptionImageUrl: option.mainImageUrl }];

    // 옵션 상세이미지 추가
    option.detailImageUrl.forEach(imgUrl => option.optionImages.push({ mainYn: 'N', mallOptionImageUrl: imgUrl }));

    delete option.mainImageUrl;
    delete option.detailImageUrl;
    return option;
  }

  private setSaleStatusType(option: CombinationOptionType<string[]>): void {
    option.saleStatusType = option.stockCnt ? STOCK_STATUS_TYPE.AVAILABLE : STOCK_STATUS_TYPE.SOLD_OUT;
  }

  private getImageIndexes(optionList: CombinationOptionType<string[]>[]): number[] {
    let prevOptionValue: string | number = '';
    return optionList.reduce((imageIndexes, { optionValue }, index) => {
      if (prevOptionValue !== optionValue[0]) {
        imageIndexes.push(index);
        prevOptionValue = optionValue[0];
      }
      return imageIndexes;
    }, []);
  }

  private sortByOptionValue(optionList: CombinationOptionType<string[]>[]): void {
    optionList.sort((a, b) => (a.optionValue[0] < b.optionValue[0] ? -1 : a.optionValue[0] > b.optionValue[0] ? 1 : 0));
  }
}
