// 메인 두군데서 쓰고, 따로 필요할때만 갱신하기위해 스토어로 묶습니다.

import { ActionContext } from 'vuex';
import { RootState } from '@/store/root';
import { api } from '@/api';
import { NCPResponse } from 'ncp-api-supporter';
import { MallPointState } from '@/types/smsPoint';

export default {
  namespaced: true,
  state: {
    smsPointList: [],
  },
  actions: {
    async fetchPoints({ commit }: ActionContext<MallPointState, RootState>): Promise<void> {
      const { data }: NCPResponse<any> = await api.getSmsGodoPoint();

      commit('SET_POINTS', data);
    },
  },
  mutations: {
    SET_POINTS(state: MallPointState, data: any): void {
      state.smsPointList = data;
    },
  },
  getters: {
    getMallPointList(state: MallPointState): any {
      return [...state.smsPointList];
    },
  },
};
