


































































































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { returnWayTypes } from '@/const/claim';
import { ClaimStatusType, ClaimType, ReturnWayType } from '@/types/claim';
import { DeliveryAddress, ReturnAddress, WarehouseInfo } from 'ncp-api-supporter/dist/types/modules/claim/claims';
import { isExchangeClaim, isReturnClaim, isReturnRequest } from '@/components/popup/claim/claimConditions';
import { DeliveryCompany, NCPResponse } from 'ncp-api-supporter';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { PopupClose, throwPopup, throwWindowPopup } from '@/helpers/popup';

@Component({
  components: { RadioGroup, TextInput, SelectBox },
  computed: {
    isExchangeClaim,
    isReturnClaim,
    isReturnRequest,
    isSellerCollect() {
      return this.returnWayType === returnWayTypes.SELLER_COLLECT;
    },
  },
})
export default class ClaimInformation extends Vue {
  @Prop({ required: true })
  private readonly claimType!: ClaimType;

  @Prop({ required: true })
  private readonly claimStatusType!: ClaimStatusType;

  @Prop({ required: true })
  private readonly returnWayType!: ReturnWayType;

  @Prop({ required: true })
  private readonly partnerPhoneNo!: string;

  @Prop({ required: true })
  private readonly releaseWarehouse!: WarehouseInfo;

  @Prop({ required: true })
  private readonly returnWarehouse!: WarehouseInfo;

  @Prop({ required: true })
  private readonly deliveryAddress!: DeliveryAddress;

  @Prop({ required: true })
  private readonly returnAddress!: ReturnAddress;

  @Prop({ required: true })
  private readonly exchangeAddress!: ReturnAddress;

  @Prop({ required: false, default: null })
  private readonly claimImageUrls: null | string[];

  @PropSync('deliveryCompanyType', { required: true })
  private deliveryCompanyTypeSync!: string;

  @PropSync('invoiceNo', { required: true })
  private invoiceNoSync!: string;

  private showDetail = false;
  private returnWayTypeOptions: RadioBoxGroupOption<string> = {
    name: 'returnWayType',
    data: [
      { id: returnWayTypes.SELLER_COLLECT, value: returnWayTypes.SELLER_COLLECT, display: '판매자수거요청' },
      {
        id: returnWayTypes.BUYER_DIRECT_RETURN,
        value: returnWayTypes.BUYER_DIRECT_RETURN,
        display: 'CLAIM.COMMON.BUYER_DIRECT_RETURN',
      },
    ],
  };
  private deliveryCompanyOptions: OptionData<string>[] = [
    {
      value: '',
      name: 'CLAIM.COMMON.DELIVERY_COMPANY',
    },
  ];

  created() {
    this.fetchDeliveryCompanies();
  }

  private async fetchDeliveryCompanies(): Promise<void> {
    const { data }: NCPResponse<DeliveryCompany[]> = await this.$api.getDeliveryCompanies({
      params: { countryCd: 'KR' },
    });

    const deliveryCompanyOptions = data.map(deliveryCompany => ({
      name: deliveryCompany.label,
      value: deliveryCompany.deliveryCompanyType,
    }));

    this.setDeliveryCompanyOptions(deliveryCompanyOptions);
  }

  private setDeliveryCompanyOptions(deliveryCompanyOptions: OptionData<string>[]) {
    this.deliveryCompanyOptions = [...deliveryCompanyOptions];
  }

  private onOpenModifyReturnInfoPopup() {
    const claimNo = this.$route.query.claimNo;
    const { name, contact1, contact2, zipCd, note, address, detailAddress, jibunAddress } = this.returnAddress;

    throwWindowPopup(
      'ReturnInfoEditPopup',
      { claimNo, returnInfo: { name, contact1, contact2, zipCd, note, address, detailAddress, jibunAddress } },
      'sm-l',
      ({ state }) => {
        if (state === PopupClose.CONFIRM) {
          this.$emit('fetchClaimAgain');
        }
      },
    );
  }

  private onOpenImage(clickedImageIndex: number) {
    throwPopup({
      name: 'ReturnImage',
      data: {
        initSlideIndex: clickedImageIndex,
        imageUrls: this.claimImageUrls,
      },
    });
  }
}
