export default Object.freeze({
  //마케팅 상품 설정
  SUCCESS_NO_DISPLAYABLE: '미노출(으)로 변경 완료',
  SUCCESS_DISPLAYABLE: '노출(으)로 변경 완료',
  PLEASE_CHECK: '상품을 선택해주세요',
  FACEBOOK: '페이스북',
  SUCCESS: '성공',
  ALL: '전체 선택',
  GOOGLE_AD: '구글 광고',
  CRITEO: '크리테오',
  MOBON: '모비온',
  NAVER_SHOPPING: '네이버 쇼핑',
  YES_DISPLAYABLE: '노출',
  NO_DISPLAYABLE: '노출 안 함',
  MARKETING_CHANNEL_REGIST: '마케팅 채널 등록',
  MARKETING_CHANNEL_DISPLAYABLE: '마케팅 채널 노출',
  CHANNEL_TYPE: '채널 구분',
  MARKETING_CHANNEL_DESCRIPTION:
    '상품 등록 이후 마케팅 채널에 상품 노출이 필요한 경우 마케팅 > 마케팅 설정 > 마케팅 상품 설정에서 일괄 관리로 수정이 가능합니다.',
  DISPLAYABLE: '노출 여부',
  ADDITIONAL_INFO: '추가 정보',
  AVAILABLE_ADS: '연동 대상',
  APPLIED_ADDITIONAL_DISCOUNT_PRICE: '즉시 할인가',
  APPLIED_IMMEDIATE_DISCOUNT_PRICE: '즉시 할인',
  FRONT_DISPLAYABLE: '노출 여부',
  PLEASE_SELECT: '쇼핑몰을 선택하세요',
  PRODUCT_ALL_DISPLAYABLE: '마케팅 상품 일괄 노출',
  PRODUCT_ALL_NO_DISPLAYABLE: '마케팅 상품 일괄 미노출',
  MALL_NAME: '쇼핑몰',
  SHOP_MALL: '쇼핑몰',
  PRODUCT_NAME: '상품명',
  SALE_PRICE: '판매가',
  MALL_PRODUCT_NO: '상품번호',
  PRODUCT_MANAGEMENT_CD: '상품관리코드',
  PRODUCT_MANAGEMENT_CD_LIKE: '상품관리코드 | 부분검색',
  GROUP_MANAGEMENT_CODE: '그룹관리코드',
  SEARCH_QUERY: '검색어',
  THUMB_TIT: '마케팅 채널 별 처리 시점',
  THUMB_DESC_GOOGLE: '구글 쇼핑: 상품 상태 변경 시 API를 통해 상품 상태 값이 Google Merchant Center에 전송됩니다.',
  THUMB_DESC_NAVER:
    '네이버 쇼핑: 전체 EP 생성 시간: 0시, 09시, 11시, 13시, 15시, 17시, 19시 / 요약 EP 생성 시간: 09시, 11시, 13시, 15시, 17시, 19시',
  DISPLAYABLE_TOOLTIP:
    '해당 마케팅 채널에 상품 정보가 노출될 수 있도록 EP생성 또는 API 전송의 대상으로 선택한 정보임을 표시합니다.',
  VAILABLE_ADS_TOOLTIP:
    '• 해당 마케팅 채널에 상품 정보가 연동될 수 있는 조건인 경우 ‘Y’로 노출됩니다. \r• 해당 마케팅 채널에 상품 정보가 연동될 수 없는 조건인 경우 ‘N’로 노출됩니다. \r• 구글 광고 연동 조건 : 판매 상태가 ‘판매중’인 경우, 전시 상태가 ‘전시함’인 경우, 판매가가 0원이 아닌 경우, 마케팅 상품 설정 노출 여부가 ‘노출함’ 인 경우 ',

  //구글 광고 설정
  EXPIRATION_DATA_TIME_TOOLTIP:
    '만료일 경과 시 상품 광고가 게재되지 않습니다\r만료일을 갱신하시려면 상품을 수정하여 정보를 업데이트하셔야 합니다.',
  EXPIRATION_DATA_TIME: '만료일',
  LASTUPDATE_DATA_TIME: '최종 수정일',
  POSSIABLE: '가능',
  IMPOSSIABLE: '불가능',
  PRODUCT_MANAGE: '상품 관리',
  ACCOUNT_MANAGE: '계정 관리',
  AD_MANAGE: '광고 관리',
  REPORT: '리포트',
  GOOGLE_AD_CONFIG_THUMB01: '리스트에서 Google Merchant Center에 등록된 상품의 문제점을 확인할 수 있습니다.',
  GOOGLE_AD_CONFIG_THUMB02:
    '상품 정보를 수정하여 해결할 수 있습니다. (샵바이프로에서 해당 상품의 정보 수정 시 Google Merchant Center 상품 정보에 즉시 반영)',
  APPROVED_COUNT: '활성 상품',
  EXPIRING_COUNT: '만료 임박 상품',
  PENDING_COUNT: '대기중 상품',
  DISAPPROVED_COUNT: '비승인 상품',
  SEARCH_CONDITION: '검색 조건',
  PRODUCT_STATE: '상품 상태',
  APPROVED: '승인',
  PENDING: '승인 대기',
  DISAPPROVED: '비승인',
  PRODUCT_LIST: '상품 리스트',
  ISSUES: '문제',
  RESOLUTION: '문제해결 가능여부',
  CREATION_DATA_TIME: '등록일',
  PRODUCT_MANAGE_TOOLTIP: '업데이트 기준은 구글로부터 2시간 단위로 조회됩니다.',
  FINAL_UPDATE_DATE: '최종 업데이트 일시',
  SEARCH_PRODUCT_STATE: '상품 상태를 조회해주세요',
  CHECK_PRODUCT_STATE: '상품 상태를 선택해주세요',
  NO_SEARCH_PRODUCT_STATE: '조회결과가 없습니다',
  PENDING_FOR_MERCHANT_CENTER: `쇼핑몰 상품이 Google Merchant Center로 업로드 중입니다.<br>
  업로드된 상품 정보는 최초 연동 시점 이후 최대 4시간 이내 확인 가능합니다.<br>
  (구글 광고에 전송 및 노출될 상품 정보는 <a href="/pro/marketing/product/google" target="_blank">마케팅 상품 설정</a>에서 확인 가능합니다)`,

  //구글 광고 설정 > 계정관리
  ACCOUNT_MANAGE_THUMB_DESC01: 'Google Merchant Center, Google Ads의 계정 상태 및 문제를 확인할 수 있습니다.',
  ACCOUNT_MANAGE_THUMB_DESC02:
    '구글에서 계정을 정기적으로 검토하며, 문제가 발생할 경우 경고 및 계정 정지 등의 조치를 취할 수 있습니다.',
  ACCOUNT_MANAGE_THUMB_DESC03:
    '계정이 정지된 경우, 문제 해결 후 "Merchant Center > 제품 > 진단 > 계정문제"에서 구글에 검토 요청이 가능합니다.',
  ACCOUNT_MANAGE_THUMB_DESC04:
    '하단 [사용자 관리] 버튼을 클릭하여 사용자를 등록한 뒤 등록한 구글 계정으로 Merchant Center와 Ads에 접속할 수 있습니다.',
  ACCOUNT_MANAGE_THUMB_DESC05:
    '광고 관리에서 생성한 캠페인은 Google Ads 계정으로 동기화되며, 광고비는 구글에서 직접 청구됩니다.',
  MERCHANT_CENTER_BASIC_INFO: 'Merchant Center 계정 기본 정보',
  MERCHANT_CENTER_ID: '계정 ID',
  MERCHANT_CENTER_OWNERSHIP: '사이트 소유권 인증',
  MERCHANT_CENTER_OWNERSHIP_TOOLTIP:
    '소유권 인증 후 대표도메인을 변경한 후, [소유권 재인증] 버튼을 클릭하거나,<br>Merchant Center 계정에 직접 접속하여 변경된 도메인 정보로 재인증을 받을 수 있습니다.',
  OWNERSHIP_SUCCESS: '인증완료',
  OWNERSHIP_FALSE: '인증실패',
  MERCHANT_CENTER_REOWNERSHIP: '소유권 재인증',
  REOWNERSHIP_FALSE: '소유권 인증 실패',
  REOWNERSHIP_POPUP_DESC: '대표도메인에 보안서버를 적용하여 주십시요',
  REOWNERSHIP_CONFIG: '설정하기',
  ACCOUNT_ISSUES: '계정 문제',
  MORE: '자세히 알아보기',
  RECLAIM_SUCCESS: '재인증 성공',
  NO_ACCOUNT_ISSUE: '보고된 계정 문제가 없습니다.',
  PAUSE: '중지',
  ENABLED: '진행중',
  NO_BILLING_PAYMENT_ALERT: 'Google에 결제 수단이 등록되어 있지 않습니다. 결제수단을 등록해주세요.',
  GOOGLE_AD_MANAGE: '구글 광고 관리',
  AD_STATUS: '광고 상태',
  AD_DAY_BUDGET: '광고 일 예산',
  BIDDING_STRATEGY: '입찰 전략',
  TARGET_CPA: '타겟 CPA',
  TARGET_AD_BUDGET: '타겟 광고 투자수익',
  AD_PAUSED_ALERT: '현재 광고가 진행중 입니다. 정말 중지 하시겠습니까?',
  AD_PAUSED_ALERT_COMPLETE: '광고가 중지 되었습니다.',
  AD_ENABLED_ALERT: '현재 광고가 중지중 입니다. 정말 시작 하시겠습니까?',
  AD_ENABLED_ALERT_COMPLETE: '광고가 시작 되었습니다.',
  STOP: '중지',
  CHANGE: '변경',
  GOOGLE_COFIGURE_CHECK: '구글 설정 확인',
  GOOGLE_COFIGURE_NOTICE1: '구글 광고(캠페인)를 시작하기 위해서 아래 항목을 모두 설정해야 합니다.',
  GOOGLE_COFIGURE_NOTICE2: '설정이 모두 완료되면 구글 광고(캠페인)를 시작할 수 있습니다.',
  MERCHANT_USER_REGIST: 'Merchant Center 사용자 등록',
  ADS_USER_REGIST: 'Ads 사용자 등록',
  ADS_PAYMENT_REGIST: 'Ads 결제 수단 등록',
  HAS_APPROVED_PRODUCT: 'Merchant Center 상품 승인',
  PRODUCT_REGIST: '상품 등록',
  MARKETING_EXPOSE_CONFIG: '마케팅 채널 노출 설정',
  GOOGLE_CONFIG_POPUP_TOOLTIP1: `Merchant Center에 등록된 상품이 1개 이상 “승인” 상태여야 합니다.<br>정책 위반으로 인해 비승인 상태인 경우 광고를 등록할 수 없습니다. <a href='https://support.google.com/merchants/answer/6149970?hl=ko'>광고 정책 바로가기></a>`,
  GOOGLE_CONFIG_POPUP_TOOLTIP2: `상품 > 상품 관리 > 상품 등록/수정 시 마케팅 노출 설정이 구글 광고가 선택된 상태에서 ‘노출함’ 으로 설정된 상품이1개 이상 설정되어 있어야 합니다.`,
  GOOGLE_AD_REGIST: '구글 광고 등록',
  BUDGET_CONFIG: '예산 설정',
  GOOGLE_AD_REGIST__NOTICE1:
    '<span style="color:red">구글 광고 비용은 NHN커머스가 아닌 Ads 계정에 등록하신 결제 수단을 통해 청구됩니다.</span>',
  GOOGLE_AD_REGIST__NOTICE2: 'Google Ads에 입력한 카드 정보가 올바르지 않을 경우 광고는 즉시 중지됩니다.',
  GOOGLE_AD_REGIST__NOTICE3: '일 광고비 예산은 최소 10,000원부터 최대 100,000원까지 천원 단위로 입력 가능합니다.',
  GOOGLE_AD_REGIST__NOTICE4:
    '선택하신 입찰 전략에 따라 타겟 비용 및 비율이 자동으로 설정되거나 직접 입력할 수 있습니다.',
  GOOGLE_AD_REGIST__NOTICE5:
    '<span style="color:red">높은 타겟 비용 및 비율로 설정했을 경우 일 광고 예산보다 2배 이상 초과할 수 있습니다.</span>',
  GOOGLE_AD_REGIST__NOTICE6:
    '​광고비는 구글 러닝머신에 의해 일 광고 예산 보다 적거나 많게 사용될 수 있습니다. 단, 광고 진행기간의 총 예산 보다 많이 사용되지 않습니다.',
  BUDGET_RANGE: '10,000~100,000 (천 단위)',
  BUDGET_RANGE2: '10,00~100,000 (천 단위)',
  DAY_AD_BUDGET: '일 광고 예산 금액',
  CPA: '전환',
  ROAS: '전환 가치',
  GOOGLE_AD_REGIST_POPUP_TOOLTIP1:
    '캠페인에서 중점을 두고자 하는 측정항목을 선택하고 측정항목을 최적화할 수 있는 입찰 옵션을 선택해 주세요',
  GOOGLE_AD_REGIST_POPUP_TOOLTIP2:
    '설정한 타겟 전환당비용(CPA) 이하에서 전환수를 최대한 높일 수 있도록 입찰가가 설정됩니다.<br>일부 전환은 타겟보다 비용이 높거나 낮을 수 있으며, 타겟 전환당비용 미 설정 시 설정하신 일 예산에 따라 자동으로 금액이 설정됩니다.',
  GOOGLE_AD_REGIST_POPUP_TOOLTIP3:
    '광고주가 광고에 지출하는 비용으로 얻고자 하는 전환 가치의 평균을 입력해 주세요.<br>전환 가치 ÷ 광고 비용 x 100% = 타겟 광고 투자수익(ROAS) 비율＇ 공식에서 구한 ​비율 값을 입력합니다.<br>일부 전환은 타겟 ROAS보다 비용이 더 높거나 더 낮을 수 있으며, 타겟 광고 투자수익 미 설정 시 설정하신 일 예산에 따라 자동으로 비율 값이 설정됩니다.',
  TARGET_CHANGE_CONFIG_CPA: '타겟 전환 당 비용(CPA) 설정',
  TARGET_CHANGE_CONFIG_ROAS: '타겟 광고 투자수익(ROAS) 설정',
  TARGET_AD_ROAS: '타겟 광고 투자수익(ROAS)',
  TARGET_CHANGE_BUDGET_ALERT: '최소/최대 일 예산을 확인 후 입력 바랍니다.',
  TARGET_CHANGE_BUDGET_ALERT_NO_TEXT: '일 광고 예산 금액을 입력해주세요.',
  TARGET_CHANGE_CPA_ALERT: '최소/최대 타겟 전환당비용을 확인 후 입력 바랍니다.',
  TARGET_CHANGE_CPA_ALERT_NO_TEXT: '타겟 전환 당 비용을 입력해주세요.',
  TARGET_CHANGE_ROAS_ALERT: '최소/최대 타겟 광고 투자수익률을 확인 후 입력 바랍니다. ',
  TARGET_CHANGE_ROAS_ALER_NO_TEXT: '타겟 광고 투자수익률을 입력해주세요.',
  COMPLETE_GOOGLE_CONFIG: '구글 광고 설정이 완료되었습니다.',
  MODIFY_GOOGLE_CONFIG: '구글 광고 설정이 완료되었습니다.',
  FAIL_GOOGLE_CONFIG: '구글 광고 설정이 실패되었습니다.',
  CRITERIA_INQUIRY: '조회 기준',
  DATE: '일자별',
  DAY: '일자',
  PRODUCT: '상품별',
  TOTAL_REPORT: '기간별 리포트 종합',
  PRODUCT_REPORT: '상품별 리포트 종합',
  DATE_REPORT: '일자별 리포트 종합',
  PURCHASE_CONVERSIONS: '구매전환 수',
  AD_BILL: '광고 집행 금액',
  PURCHASE_CONVERSIONS_BILL: '구매전환 금액',
  PURCHASE_CONVERSIONS_COUNT: '구매전환 수',
  EXPOSE_COUNT: '노출수',
  CLICK_COUNT: '클릭수',
  CLICK_PER: '클릭율(%)',
  CLICK_BILL: '클릭당 비용',
  PRODUCT_NO: '상품번호',
  NO_CAMPAIGN_DATA: '캠페인의 데이터가 없습니다.',
  NOW_LOADING: '로딩중...',
  START: '시작',
});
