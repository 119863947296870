


























































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { Brand, Mds } from 'ncp-api-supporter';
import {
  KEYWORD_TYPE,
  SearchFormSelectorOption,
} from '@/components/popup/product/management/AppliedProductSearchForm.ts';
import {
  GetProductsSearchStickerParameter,
  getQueryParams,
  sortByName,
} from '@/components/popup/product/management/AppliedProduct';
import { MdsDisplay } from '@/types';
import SearchKeyword from '@/components/product/common/SearchKeyword.vue';
@Component({
  components: {
    SearchKeyword,
    TextInput,
    SelectBox,
    SearchButton,
  },
  computed: {
    validType(): 'numbers' | 'management-cd' | '' {
      if (this.keywordType === KEYWORD_TYPE.PRODUCT_NO) return 'numbers';
      if (this.keywordType === KEYWORD_TYPE.PRODUCT_MANAGEMENT_CD) return 'management-cd';
      return '';
    },
  },
})
export default class AppliedProductSearchForm extends Vue {
  @Prop({ required: true })
  private readonly admins: Mds[];
  @Prop({ required: true })
  private readonly brands: Brand[];

  private queryParams: GetProductsSearchStickerParameter = {
    ...getQueryParams(0, 0),
    ...this.$route.query,
  };
  private option = SearchFormSelectorOption;
  private sortedAdmins: MdsDisplay[] = [];
  private sortedBrands: Brand[] = [];

  public sendQuery() {
    this.queryParams = {
      ...getQueryParams(0, 0),
      ...this.$route.query,
    };
  }

  @Watch('admins')
  @Watch('brands')
  private setSortedData() {
    this.sortedBrands = sortByName([...this.brands], 'brandName');
    this.sortedAdmins = sortByName([...this.admins], 'name');
    this.setDisplayNameInAdmins();
  }
  private setDisplayNameInAdmins() {
    this.sortedAdmins.forEach(admin => (admin.displayName = `${admin.name} (${admin.id})`));
  }
  // 검색어
  private keywordType = KEYWORD_TYPE.PRODUCT_NAME;
  @Ref()
  private readonly searchKeywordRef: SearchKeyword;

  // 최종 검색 조건
  private get query(): GetProductsSearchStickerParameter {
    if (this.queryParams.keywords) {
      this.queryParams.keywordType = this.keywordType;
      this.queryParams.keywords = this.searchKeywordRef
        ?.getInputSearchKeyword()
        .split(',')
        .map(v => v.trim())
        .join(',');
    }
    return this.queryParams;
  }

  @Ref()
  private readonly search: SearchButton;
  private reset(): void {
    this.queryParams = {
      ...getQueryParams(0, 0),
    };

    this.$nextTick(() => {
      this.keywordType = KEYWORD_TYPE.PRODUCT_NAME;
      this.searchKeywordRef.reset();
      this.search.sendQueryUrl();
    });
  }
}
