export default Object.freeze({
  TOP_NOTICE_1: '즉시 지급인 경우 지급 시점 회원정보 기준으로 지급 됩니다.',
  TOP_NOTICE_2: '예약 지급인 경우 예약 발송시점 회원정보 기준으로 지급 됩니다.',
  TOP_NOTICE_3: '전체 회원은 지급 시점 설정한 일시 기준 순차 지급 됩니다.',
  TOP_NOTICE_4: '회원등급 평가 기간 발송설정하는 경우 지급대상이 상이 할 수 있습니다.',
  TAB_CONFIGURATION_LIST: '적립금설정관리',
  TAB_LIST: '적립금 지급관리',
  TAB_ACCUMULATION_LIST: '적립금 적립/차감 조회',
  TABLE_TITLE: '기본 설정',
  TITLE_DISCLAIMER: '* 필수입력',
  TBODY_MALL: '쇼핑몰',
  TBODY_SET: '지급설정',
  TBODY_IMMEDIATELY: '즉시지급',
  TBODY_IMMEDIATELY_NOT: '예약지급',
  TBODY_MEMBER: '지급 회원대상',
  TBODY_MEMBER_ALL: '전체회원',
  TBODY_MEMBER_CHOSE: '회원선택',
  TBODY_TARGET_BTN: '지급대상변경',
  TARGET_CANCLE_BTN: '취소',
  MEMBER_SELECT_GROUP_BTN: '회원등급/그룹 선택',
  MEMBER_SELECT_BTN: '회원 조회',
  MEMBER_DESCRIPTIONS_1_1: '회원',
  MEMBER_DESCRIPTIONS_1_2: '명 선택되었습니다. (중복 회원 제외)',
  MEMBER_DESCRIPTIONS_2: '휴면회원 및 탈퇴회원은 적립금 지급 대상에서 제외됩니다.',
  MEMBER_DESCRIPTIONS_3: '예약발송인 경우 설정일과 발송일 회원대상이 일치하지 않을 수 있습니다.',
  SET: '지급설정',
  MOUNT: '지급 적립금',
  MOUNT_PLACEHOLDER: '적립금을 입력하세요.',
  MOUNT_DEXCRIPTION_1: '숫자만 입력 할 수 있습니다.',
  MOUNT_DEXCRIPTION_2: '100 단위로 입력 가능합니다. ex ) 100 , 1000, 1100, 2500',
  REASON: '지급 사유',
  REASON_PLACEHOLDER: '지급 사유를 입력하세요.',
  REASON_DEXCRIPTION: '몰 회원 적립금 지급 사유 표기되는 항목에 작성한 지급사유가 노출됩니다.',
  VALIDPERIOD: '유효기간설정',
  VALIDPERIOD_BASE: '기본 유효기간에 따름',
  VALIDPERIOD_SET: '별도유효기간 지정',
  VALIDPERIOD_SET_TITEL: '적립금 지급일 기준',
  VALIDPERIOD_DEXCRIPTION_1: '월단위 유효기간을 설정 하실 수 있습니다.',
  VALIDPERIOD_DEXCRIPTION_2: '일단위 유효기간 지급은 회원관리&gt;회원조회 메뉴에서 지급하실 수 있습니다.',
  NOTIFICATION: '지급알림 설정',
  NOTIFICATION_STATUS_ALL: '전체선택',
  NOTIFICATION_STATUS_KAKAOL: '카카오알림톡',
  NOTIFICATION_STATUS_SMS: 'SMS',
  NOTIFICATION_STATUS_EMAIL: '이메일',
  NOTIFICATION_CONFIGER_TITLE: '지급알림 수단설정',
  NOTIFICATION_TIME: '지급알림 시간설정',
  NOTIFICATION_TIME_DEXCRIPTION: '설정한 알림수단을 사용처리 해야 정상 발송될 수 있습니다.',
  SAVE: '적립금 지급하기',
  KEEP: '저장',
  CANCEL: '취소',
  DELETE: '적립금 지급취소',
  DELETE_COMFIRM: '취소하시면 적립금 예약 지급이 취소처리 됩니다. 취소하시겠습니까?',
  CANCEL_ALERT: '작성한 내용을 취소하시겠습니까?',
  CHANGE_BTN: '수정',
  EXCLUDES_TITLE: '지급제외대상',
  EXCLUDES_SET_NOT: '설정안함',
  EXCLUDES_SET: '설정',
  EXCLUDES_MEMBER_SEARCH: '회원 조회',
  EXCLUDES_DEXCRIPTION_1_1: '지급 대상',
  EXCLUDES_DEXCRIPTION_1_2: '명 중',
  EXCLUDES_DEXCRIPTION_1_3: '명 지급 제외 선택 되었습니다.',
  AMOUNT_ALERT: '지급 적립금을 정확하게 입력해주세요.',

  ADD_MALL_NO: '적립금을 지급할 쇼핑몰을 입력해주세요.',
  TARGET_ALERT: '지급 회원대상을 선택해주세요.',
  AMOUNT_ALERT_EMPTY: '지급 적립금을 입력해주세요.',
  ADD_REASON_ALERT_EMPTY: '지급사유를 입력해주세요.',
  ADD_NOTIFICATION_TIME_CHECK: '적립금 지급시간보다 지급알림을 과거 시간으로 설정할 수 없습니다.',
  TIME_SET_OLD_TIME_ALERT: '적립금 지급시간을 과거 시간으로 설정할 수 없습니다.',
  ADD_NOTIFICATION_TIME_USELESS_ALERT: '유효하지 않은 알림시간 입니다.',
  NOTIFICATION_CHANNEL_EMPTY_ALERT: '알림수단을 선택해주세요.',
  VALIDPERIOD_ATLEAST_ONE_YEAR_ALERT: '적립금 유효기간은 최소 1개월이상 입니다.',
  FINISH_POST_PAYMENT: '적립금 지급이 완료 되었습니다.',
  CANCEL_POST_PAYMENT: '작성한 내용을 취소하시겠습니까?',
  DELETE_SUCESS_ALERT: '지급예약취소 성공했습니다.',
  MODIFY_SUCESS_ALERT: '적립금 지급 설정이 저장되었습니다.',
  MEMBER_SELECT_POPUP_TITLE: '회원등급/그룹 선택 팝업',
  MEMBER_SEARCH_POPUP_TITLE: '회원 조회 팝업',
});
