




















import { Vue, Component, Prop } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';

export const MAX_COUNT_TO_SHOW_CAPTCHA = 10;

@Component({
  components: { TextInput },
})
export default class Captcha extends Vue {
  @Prop({ required: false, default: 'inherit' })
  private readonly width?: string;

  private code = '';

  public async submitCode(alertMsg = false) {
    if (!this.code) {
      alertMsg && alert(`${this.$t('AUTHORITY.SECURITY.CAPTCHA_EMPTY_CODE')}`);
      throw new Error('AUTHORITY.SECURITY.CAPTCHA_EMPTY_CODE');
    }

    await this.$api.postCaptchaVerify({
      params: {
        code: this.code,
        key: null,
      },
    });
  }

  public errorHandler(error, useAlert = true) {
    const captcha = 'captcha';
    if (error.data) {
      const { path, message } = error.data;
      path.includes(captcha) && useAlert && alert(message);
    }
  }

  private url = '';
  private async fetchImg() {
    const { data } = await this.$api.getCaptchaImage({
      params: {
        key: null,
      },
    });
    this.url = data.url;
  }

  private created() {
    this.fetchImg();
  }
}
