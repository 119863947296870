import { OptColumn } from '@/types';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';
import { Row } from '@/types/tui-grid';
import { GetProductsHistoriesRequest, NCPResponse, GetProductsHistories } from 'ncp-api-supporter';
import { api } from '@/api';
import { throwPopup } from '@/helpers/popup';

export const getChangeHistoryColumn = (): OptColumn => {
  return {
    header: 'PRODUCT.CHANGE_HISTORY',
    name: 'changeHistory',
    align: 'center',
    minWidth: 100,
    formatter: getAnchorHTML(i18n.t('VIEW')),
  };
};

const getChangeHistory = async (mallProductNo: string): Promise<GetProductsHistories> => {
  const request: GetProductsHistoriesRequest = {
    params: {
      mallProductNo,
    },
  };

  try {
    const { data }: NCPResponse<GetProductsHistories> = await api.getProductsHistories(request);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const throwHistoryLayerPopup = (histories: GetProductsHistories) => {
  throwPopup({
    name: 'ChangeHistory',
    data: histories,
  }).catch(console.error);
};

export const openChangeHistoryLayerPopup = ({ mallProductNo }: Row) => {
  getChangeHistory(mallProductNo.toString()).then(throwHistoryLayerPopup);
};

export const getDisplayOrderHistoryColumn = (
  header = 'PRODUCT.ADD.HEADER_NO',
  name = 'displayOrder',
  width = 50,
): OptColumn => ({
  header,
  name,
  width,
  align: 'center',
  formatter: ({ row }) => {
    if (row.fixed) return '';
    if (!row.originDisplayOrder) return row.displayOrder as string;
    if (row.originDisplayOrder === row.displayOrder) return row.displayOrder as string;
    return `<span><span>${row.originDisplayOrder} → </span><span style="color: red">${row.displayOrder}</span></span>`;
  },
});
