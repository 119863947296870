export default Object.freeze({
  TITLE: 'NCP 사용료 조회',
  NOTICE_FEE: 'NCP서비스 사용료에 대하여, SA의 쇼핑몰별로 결제액 구간별 요금 및 할인혜택을 설정할 수 있습니다.',
  NOTICE_CALC: 'NCP사용료 정산은 전월(1일~말일)의 최종 이용금액이 익월 10일 이내에 청구됩니다.',
  KEYWORD: '검색어',
  KEYWORD_PLACEHOLDER: '검색어 입력',
  ALL: '전체',
  MALL_NO: '쇼핑몰번호',
  MALL_NAME: '쇼핑몰명',
  ENTER_KEYWORD: '검색어 입력',
  SEARCH: '조회',
  RESET: '초기화',
  USE_PERIOD: '사용 기간',
  PERIOD_MONTH: '월별',
  PERIOD_ALL: '전체',
  SETTLEMENT_STATUS: '정산상태',
  UNCONFIRM: '미확정',
  CONFIRM: '확정',
  RESULT_TITLE: '총 정산 현황 | 조회결과 ',
  RESULT_SIZE: ' 건',
  RESULT_USE_PERIOD: '사용기간',
  SERVICE_NO: '서비스번호',
  SERVICE_NAME: '서비스명',
  SETTLEMENT_BASE_AMT: '매출합계(A)',
  FINAL_RATE: '최종적용요율(B)',
  FINAL_SETTLEMENT_AMT: '최종 정산금액',
  FINAL_ADJUSTED_SETTLEMENT_AMT: '최종 정산금액(조정포함)',
  DETAIL_RESULT_TITLE: '정산 현황 상세 | ',
  DETAIL_INFORMATION: '서비스의 정산 현황 상세는 쇼핑몰명이나 최종 정산금액(조정포함)을 선택 시, 조회가 가능합니다.',
  SETTLEMENT_PERIOD_TYPE_LABEL: '정산 유형',
  SETTLEMENT_PERIOD_MONTH: '월정산',
  SETTLEMENT_PERIOD_ALL: '전체정산',
  NCP_RATE_TYPE: '기준 적용요율 유형',
  RATE: '기준 적용요율',
  EXTRA_SERVICE_CNT: '부가서비스',
  EXTRA_SERVICE_RATE_SUM: '부가서비스 할인율',
  FINAL_SD_RATE: '특별할인',
  FINAL_AD_RATE: '조정할인',
  SETTLEMENT_AMT: '정산금액(A*B)',
  ADJUST_MEMO: '사유',
  CONTRACT_YMDT: '계약기간',
  DEFAULT_POLICY_LABEL: '적용 유형',
  DUE_YEAR_MONTH: '적용 기간',
  BUSINESS_REGISTRATION_NO: '사업자 번호',
  CONFIRM_TYPE: '정산 상태',
  CONFIRM_TYPE_Y: '확정',
  CONFIRM_TYPE_N: '미확정',
  REGISTER_ADMIN_NAME: '등록자',
  REGISTER_YMDT: '등록일',
  UPDATE_ADMIN_NAME: '최종수정자',
  UPDATE_YMDT: '최종수정일',
  ADJUST_UPDATE_ADMIN_NAME: '최종 정산금액 조정자',
  ADJUST_UPDATE_YMDT: '최종 정산금액 조정일',
});
