export default Object.freeze({
  INFO: '안내',
  CANCEL: '취소',
  EXCHANGE: '교환',
  CANCEL_EXCHANGE: '취소교환',
  RETURN_EXCHANGE: '반품교환',
  RETURN: '반품',
  COMPLETE: '완료',
  CHANGE: '변경',
  MODIFY: '수정',
  SET: '세트',
  SET_COMPOSITION: '세트구성',

  STOCK_RESTORE_COMPLETE: '[재고복원완료]',
  STOCK_RESTORE_EXPECTED: '[재고복원예정]',

  CANCEL_YMDT: '취소신청일시',
  CANCEL_COMPLETE_YMDT: '취소완료일시',
  EXCHANGE_YMDT: '교환신청일시',
  EXCHANGE_COMPLETE_YMDT: '교환완료일시',
  RETURN_YMDT: '반품신청일시',
  RETURN_COMPLETE_YMDT: '반품완료일시',
  REFUND_YMDT: '환불신청일시',
  REFUND_COMPLETE_YMDT: '환불완료일시',

  RETURN_WAY_TYPE: '반품수거방법',
  INVOICE_NO: '송장번호',
  RETURN_INVOICE_NO: '반품송장번호',
  DELIVERY_NO: '배송번호',
  DELIVERY_INVOICE_NO: '배송번호/송장번호',
  CLAIM_NO: '클레임번호',
  AFTER_CLAIM_NO: '후순위 클레임 번호',
  CLAIM_STATUS_TYPE: '클레임상태',
  MALL_NAME: '쇼핑몰구분',
  NOT_INPUT: '미입력',

  DEPOSITOR: '예금주',
  PAY_TYPE: '결제방법',
  ACCUMULATION_USED: '적립금 전액 사용',

  PLATFORM_TYPE: '플랫폼구분',
  PLATFORM_TYPE_PC: 'PC',
  PLATFORM_TYPE_MOBILE_WEB: '모바일웹',
  PLATFORM_TYPE_MOBILE_APP: '모바일앱',

  MEMBER_GRADE: '회원등급',
  DELIVERY_COMPANY_TYPE: '택배사',

  CLAIM_PROC: '클레임처리',
  PAYTYPE: '결제수단',

  EXCHANGED: '교환여부',
  RESERVED: '예약주문',
  TASK_MESSAGE: '업무메시지',
  ORDER_NO: '주문번호',
  MALL_PRODUCT_NO: '상품번호',
  PRODUCT_MANAGEMENT_CD: '상품관리코드',
  OPTION_MANAGEMENT_CD: '옵션관리코드',
  OPTION_NO: '옵션번호',
  PRODUCT_NAME: '상품명',
  STOCK_NO: '재고번호',
  STOCK_APPLY: '재고복원 신청',

  RESPONSIBLE_TARGET: '귀책대상',
  HOLD_PROCESS: '배송보류',
  DISPLAY_OPTION_NAME: '옵션명:옵션값',
  COUNT: '수량',
  CNT_CANCEL: '취소수량',
  CNT_EXCHANGE: '교환수량',
  CNT_RETURN: '반품수량',
  CNT_REFUND: '환불수량',
  CNT_PRODUCT: '상품수',

  PHONE_NO: '연락처',
  CELL_PHONE: '휴대전화',
  PHONE: '일반전화',

  ORDERER: '주문자',
  ORDERER_NAME: '주문자명',
  ORDERER_CONTACT: '주문자 연락처',
  DELIVERY_COMPANY: '배송업체',

  MEMBER_NO: '회원번호',
  MEMBER_GROUP_INFO_JSON: '회원그룹',
  WITHDRAWAL_MEMBER: '탈퇴회원',
  RECEIVER: '수령자',
  RECEIVER_NAME: '수령자명',
  RECEIVER_NAME_PHONE: '수령자명(연락처/추가연락처)',
  RECEIVER_CONTACT: '수령자연락처',

  DISCOUNTED_SALE_PRICE: '판매가(할인적용가)',
  PRODUCT_SUM: '상품합계',
  ORDER_STATUS: '주문상태',

  IMMEDIATE_DISCOUNT_PRICE: '즉시할인가',
  CANCEL_CLAIM_AMT: '취소금액',
  EXCHANGE_CLAIM_AMT: '교환금액',
  RETURN_CLAIM_AMT: '반품금액',
  REFUND_CLAIM_AMT: '환불금액',
  REFUND_EXPECTATION_CLAIM_AMT: '환불예상금액',

  ORDER_YMDT: '주문일시',
  PAY_YMDT: '결제일시',
  ORDER_ACCEPT_YMDT: '상품준비중처리일시',
  RELEASE_READY_YMDT: '배송준비중처리일시',
  RELEASE_YMDT: '배송중처리일시',
  DELIVERY_COMPLETE_YMDT: '배송완료처리일시',
  BUY_CONFIRM_YMDT: '구매확정일시',
  PRODUCT_RELEASE_DATE: '상품출고일',

  PURCHASE_PRICE: '공급가',
  MEMBER_ID: '아이디',
  ORDER_PRODUCT_OPTION_NO: '주문상품옵션번호',

  REFUND_PAY_TYPE: '환불수단',

  REGISTER_TYPE: '주문구분',
  REGISTER_TYPE_NORMAL: '주문구분',
  REGISTER_TYPE_ADMIN: '수기구분',
  REGISTER_TYPE_ARIEL: '외부연계주문',

  PG_REFUND: 'PG',
  ACCOUNT_REFUND: '현금환불',
  ACCUMULATION_REFUND: '적립금 복원',
  ZERO_REFUND_REFUND: '0원환불',

  BUYER: '구매자',
  SELLER: '판매자',

  ORDER_CNT: '주문수량',
  CANCEL_CNT: '취소수량',
  CANCEL_EXCHANGE_CNT: '취소수량',
  RETURN_EXCHANGE_CNT: '반품수량',
  RETURN_CNT: '반품수량',

  CANCEL_TYPE_REASON: '취소구분/사유',
  EXCHANGE_TYPE_REASON: '교환구분/사유',
  RETURN_TYPE_REASON: '반품구분/사유',
  REFUND_REQUEST_REASON: '환불조정 사유',

  DELIVERY_TYPE: '배송방법',
  PARCEL_DELIVERY: '택배/등기/소포',
  DIRECT_DELIVERY: '직접배송(화물배달)',

  RELEASE: '출고',

  CANCEL_PRODUCT: '취소상품',
  EXCHANGE_RELEASE_PRODUCT: '교환출고상품',

  CANCEL_PRODUCT_AMOUNT: '취소상품금액',
  RETURN_PRODUCT_AMOUNT: '반품상품금액',
  EXCHANGE_PRODUCT_AMOUNT: '교환상품금액',
  INPUT_DISABLE: '입력 안함',
  INPUT_ABLE: '입력함',

  REFUND_PROCESS: '환불처리',
  REFUND_AMOUNT_REQUEST: '환불조정 요청금액',
  COLLECT_COMPLETE: '수거완료',
  REFUND_HOLD_NO_REQUEST: '수거완료(환불진행)',
  REFUND_HOLD_REQUEST: '수거완료(환불조정)',

  CANCEL_EXCHANGE_PRODUCT: '교환취소상품',
  RETURN_EXCHANGE_PRODUCT: '교환반품상품',
  RETURN_PRODUCT: '반품상품',

  ORDER_INFORMATION: '주문정보',
  EXCHANGE_INFORMATION: '교환정보',
  RETURN_INFORMATION: '반품정보',

  EXCHANGE_TYPE: '교환구분',
  RETURN_TYPE: '반품구분',
  EXCHANGE_REASON: '교환사유',
  RETURN_REASON: '반품사유',

  RETURN_PRODUCT_IMAGE: '반품이미지',
  RETURN_EXCHANGE_PRODUCT_IMAGE: '교환반품 이미지',

  SELLER_COLLECT: '판매자수거요청',
  BUYER_DIRECT_RETURN: '구매자직접반품',
  RETURN_APPLY_INFO: '반품접수정보',
  FIRST_RELEASE_INFO: '최초출고정보',
  EXCHANGE_RELEASE_INFO: '교환출고정보',
  SELLER_INFO: '판매자정보',
  RELEASE_ADDRESS: '출고지주소',
  DELIVERY_ADDRESS: '배송지주소',
  COLLECT_ADDRESS: '수거지주소',
  COLLECT_MEMO: '수거시참고사항',
  RETURN_EXCHANGE_ADDRESS: '반품/교환지주소',
  RETURN_COLLECT_INFO: '반품수거정보',
  RETURNER_NAME: '반품자명',
  RETURNER_NAME_PHONE: '반품자명(연락처/추가연락처)',
  ADDRESS_MESSAGE: '배송지메시지',
  CUSTOMS_ID_NUMBER: '개인통관고유부호',

  ADDITIONAL_PAY_TYPE_CASH: '무통장입금',
  ADDITIONAL_PAY_TYPE_ACCUMULATION: '적립금차감',
  ADDITIONAL_PAY_TYPE_NAVER_PAY: '네이버',

  RELEASE_PROCESS: '출고처리',
  ACCEPT: '승인',

  AFTER: '변경후',
  BEFORE: '변경전',

  ADJUST_AMOUNT: '금액조정',
  PRODUCT_COUPON: '상품쿠폰',

  RETURN_DELIVERY_AMOUNT: '반품배송비',
  EXCHANGE_DELIVERY_AMOUNT: '교환(재발송)배송비',
  EXCHANGE_DELIVERY_AMOUNT_PLUS: '교환(재발송)배송비추가(+)',
  BASIC_DELIVERY_AMOUNT: '기본배송비',
  REMOTE_DELIVERY_AMOUNT: '지역별추가',

  DELIVERY_AMOUNT_PLUS: '기본배송비(+)',
  REMOTE_DELIVERY_AMOUNT_PLUS: '지역별추가(+)',
  DELIVERY_ADJUST_AMOUNT: '금액조정(선택)(-)',
  ORDER_COUPON_RECALCULATE_MINUS: '주문쿠폰재계산(-)',
  PRODUCT_COUPON_RECALCULATE_MINUS: '상품쿠폰재계산(-)',
  CART_COUPON_RECALCULATE_MINUS: '장바구니쿠폰재계산(-)',
  DELIVERY_DIFFERENCE_PLUS: '배송비차액(+)',
  RETURN_DELIVERY_PLUS: '반품배송비추가(+)',
  RETURN_DELIVERY_MINUS: '반품배송비차감(-)',
  REFUND_ADJUST_AMOUNT: '환불 금액조정 (-)',
  EXCHANGE_PROCESS_AMOUNT: '교환처리금액',
  BEFORE_DELIVERY_AMOUNT: '변경전배송비',
  AFTER_DELIVERY_AMOUNT: '변경후배송비',

  NO_COUPON: '적용된 쿠폰 없음',
  COUPON_NO: '쿠폰번호',
  RESTORE: '복원예정',

  NOT_PRE_PAID: '착불',
  DIVIDE: '나눔',
  COMBINE: '묶음',
  ADJUST_REASON: '조정사유',
  SELLER_REFUND_ADJUST_REASON: '판매자 환불조정 신청 사유',
  SELLER_REQUEST_REFUND_ADJUST_AMOUNT: '판매자 조정 요청 금액',

  ACCUMULATION_AMOUNT: '적립금사용반환',
  MAIN_PAY_AMOUNT: '카드취소/현금환불액',

  REFUND_WAY: '환불방법',
  REFUND_ACCOUNT_NO: '환불계좌번호',
  ACCOUNT_NO: '계좌번호',
  PAY_ACCOUNT_NO: '입금계좌번호',
  PAY_REMITTER: '입금자',

  PRODUCT_SUM_DIFFERENCE: '상품합계차액',
  PRODUCT_SUM_DIFFERENCE_PLUS: '상품합계차액(+)',
  EXCHANGE_PRODUCT: '교환상품',

  ZERO_REFUND_AMOUNT: '환불금액없음',
  DEPOSIT_CANCEL: '미입금취소처리',

  CHANGE_MIND: '단순변심(색상,사이즈)',
  DEFECTIVE_PRODUCT: '상품불량/파손',
  WRONG_DELIVERY: '배송누락/오배송',
  WRONG_PRODUCT_DETAIL: '상품상세 정보와 다름',
  DELAY_DELIVERY: '판매자 배송 지연',
  CANCEL_BEFORE_PAY: '입금전취소',
  OUT_OF_STOCK: '상품 품절/재고 없음',
  OTHERS_SELLER: '기타(판매자 귀책)',
  OTHERS_BUYER: '기타(구매자 귀책)',
});
