

















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import { getKakaoMessagesRequestQuery, historySearchOption, sendResultOption } from '@/const/contents/kakao';
import SelectBox from '@/components/common/SelectBox.vue';
import { getFirstAndEndDay, getSelectMonths, getSelectYears, overPeriodYear } from '@/utils/dateFormat';
import moment from 'moment';
import { isValidate } from '@/utils/validate';
import { getCurrentMallNo } from '@/utils/mall';
import { GetKakaoMessagesRequest } from 'ncp-api-supporter';
@Component({
  components: { SelectBox, SearchButton },
})
export default class NotificationHistorySearchForm extends Vue {
  private notificationHistoryQuery = getKakaoMessagesRequestQuery(
    getCurrentMallNo(this),
  ) as GetKakaoMessagesRequest['params'];
  private readonly requestYearOption = getSelectYears(this.$t('YEAR').toString());
  private readonly requestMonthOption = getSelectMonths(this.$t('MONTH').toString());
  private readonly historySearchOption = historySearchOption;
  private readonly sendResultOption = sendResultOption;

  private selectedYear = new Date().getFullYear();
  private selectedMonth = new Date().getMonth() + 1;

  @Watch('selectedYear')
  @Watch('selectedMonth')
  private setChangeDate() {
    const { startYmd } = getFirstAndEndDay(this.selectedYear, this.selectedMonth);
    this.notificationHistoryQuery.startDate = startYmd;
  }

  private validateDate(): boolean {
    const start = moment(this.notificationHistoryQuery.startDate);
    const isPastDate = moment().diff(start, 'day') > 0;

    return isValidate(!(isPastDate && overPeriodYear(start)), this.$t('MEMBER.KAKAO.ALERT_MAX_DATE'));
  }

  @Watch('$route.query')
  private resetQuery() {
    this.notificationHistoryQuery = getKakaoMessagesRequestQuery(
      getCurrentMallNo(this),
    ) as GetKakaoMessagesRequest['params'];
  }

  private sendQueryUrl(): void {
    (this.$refs.searchButton as SearchButton).sendQueryUrl();
  }
}
