

























































































































































































































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { toolTipMessage } from '@/const/contents/configuration/externalService';
import { getBoardRadioBoxOption } from '@/const/contents/configuration/board';
import { BoardData } from '@/types/boardSetting';
import { Action } from 'vuex-class';
import { ThrowMessagePopup } from '@/helpers/popup';

@Component({
  components: { RadioGroup, ToolTip, TextInput },
})
export default class BoardSettingSub extends Vue {
  private toolTipMessage = toolTipMessage();

  @PropSync('propData')
  private propDataInfo?: BoardData;
  @Prop({ required: false, default: -1 })
  private readonly currentBoardNo?: number;
  @Action('setDetectUnsavedFormMessage')
  private readonly setDetectUnsavedFormMessage!: (message: ThrowMessagePopup) => void;

  private boardIdMaxLength = 20;
  private maxNameLength = 40;
  private maxDescriptionLength = 200;
  private radioOption = getBoardRadioBoxOption();
  private data: BoardData = {};
  private prevData: BoardData = {};

  @Watch('data', { immediate: false, deep: true })
  private dataChange() {
    this.$emit('update:propData', this.data);
  }

  public resetData() {
    this.propDataInfo = { ...this.prevData };
  }

  private get isProductReview() {
    return this.data.boardNo === -3;
  }

  // @NOTE 1차 배포 제외 스펙 - 상품후기 비회원 글쓰기 불가로 사용안함으로 비활성화 처리
  private get isWritableGuest() {
    return this.data.boardNo >= 0;
  }
  private created() {
    this.data = { ...this.propDataInfo };
    this.prevData = { ...this.data };
    if (!this.isWritableGuest) {
      // @NOTE 1차 배포 제외 스펙 - 상품후기 비회원 글쓰기 불가로 사용안함으로 비활성화 처리
      this.$set(this.data, 'guestWriteYn', 'N');
      this.$set(this.data, 'guestPostingUsed', false);
    }
  }
}
