export default Object.freeze({
  // STATISTIC_MEMBER_
  TIT: '회원통계',
  LIST: [
    {
      memberTypeValue: 'ALL',
      memberTypeName: 'STATISTIC_PROMOTION_ALL',
    },
    {
      memberTypeValue: 'MALL',
      memberTypeName: 'STATISTIC_PROMOTION_SHOPPING_MALL_MEMBERS',
    },
    {
      memberTypeValue: 'PAYCO',
      memberTypeName: 'STATISTIC_PROMOTION_PAYCO_ID_MEMBER',
    },
    {
      memberTypeValue: 'OPENID',
      memberTypeName: 'STATISTIC_PROMOTION_ABOUT_OPENID',
    },
    {
      memberTypeValue: 'NEOID',
      memberTypeName: 'STATISTIC_PROMOTION_INTEGRATED_MANAGEMENT_MEMBER',
    },
  ],
});
