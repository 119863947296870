



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { MemberTypeTable } from '@/types';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
@Component({
  components: { ToolTip },
})
export default class JoinTypeCountTable extends Vue {
  @Prop({ required: true })
  private readonly memberType!: MemberTypeTable;

  private tooltipMsg = `${this.$t('STATISTIC.MEMBER.JOIN_TYPE_COUNT_TABLE_TOOLTIP')}`;
}
