




























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { PopupClose, PopupResult, throwPopup, throwWindowPopup } from '@/helpers/popup';
import { PayType, PutShippingsShippingNoRequest } from 'ncp-api-supporter';

@Component({
  components: { TextInput },
})
export default class OrderReceiverInfo extends Vue {
  @Prop({ default: () => [] }) public receiverListContent;
  @Prop({ required: false, default: null }) private payType: null | PayType;

  private get isNaverPayOrder(): boolean {
    return this.payType === 'NAVER_PAY';
  }

  private deliveryCompany(deliveryCompanyType: string) {
    return deliveryCompanyType === null ? '' : this.$t(`CLAIM.RETURNPOPUP.${deliveryCompanyType}`);
  }

  public handleReceiverInfoChange(item) {
    if (this.isNaverPayOrder) {
      alert(this.$t('ORDER.DELIVERY_PREPARE.NAVER_PAY_ORDER_PROCESS_ALERT').toString());
      return;
    }

    if (!item.invoiceNo) {
      item.shippingInfoEditor = true;
    } else {
      alert(this.$t('ORDER.ORDER_DETAIL.ALERT_5'));
    }
  }

  public handleReceiverSms(receiverContact: string, mallNo: string) {
    // SMS 발송 팝업창
    throwWindowPopup('SmsSendManager', { phoneNos: [receiverContact] }, 'xlg', data => console.log(data), null, {
      mallNo,
    });
  }

  public handleShowReceiverInfo(event) {
    event.showReceiverInfo = !event.showReceiverInfo;
  }

  private onAddressSearchClicked(item) {
    throwPopup({
      name: 'SearchAddress',
      data: { keyword: '' },
    }).then((result: PopupResult) => {
      if (result && result.state === PopupClose.CONFIRM) {
        const data = result.data;
        item.receiver.zipCd = data.zipCode;
        item.receiver.address = data.roadAddress;
        item.receiver.detailAddress = data.roadAddressExtra;
        item.receiver.jibunAddress = data.jibunAddress;
      }
    });
  }

  public onClickSaveButton(item) {
    if (this.formValidation(item)) {
      const request: PutShippingsShippingNoRequest = {
        pathParams: {
          shippingNo: item.shippingNo,
        },
        data: {
          orderNo: this.$route.query.orderNo as string,
          name: item.receiver.name,
          zipCd: item.receiver.zipCd,
          address: item.receiver.address,
          detailAddress: item.receiver.detailAddress,
          jibunAddress: item.receiver.jibunAddress,
          contact1: item.receiver.contact1,
          contact2: item.receiver.contact2,
          customsIdNumber: item.customsIdNumber || '',
          memo: item.memo || '',
        },
      };
      this.$api.putShippingsShippingNo(request).then((): any => {
        alert(this.$t('ORDER.ORDER_DETAIL.ALERT_SAVE'));
        item.shippingInfoEditor = false;
        item.addressView = '(' + item.receiver.zipCd + ')' + item.receiver.address + ' ' + item.receiver.detailAddress;
      });
    }
  }
  public formValidation(item) {
    const name = item.receiver.name;
    const zipCd = item.receiver.zipCd;
    const address = item.receiver.address;
    const detailAddress = item.receiver.detailAddress;
    const jibunAddress = item.receiver.jibunAddress;
    const contact1 = item.receiver.contact1;
    // const customsIdNumber = item.customsIdNumber;

    if (name === '') {
      alert(this.$t('ORDER.ORDER_DETAIL.VALIDATION_NAME'));
      this.$refs['name_' + item.shippingNo][0].focus();
      return false;
    }

    if (contact1 === '') {
      alert(this.$t('ORDER.ORDER_DETAIL.VALIDATION_CONTACT1'));
      this.$refs['contact1_' + item.shippingNo][0].focus();
      return false;
    }

    if (zipCd === '' || address === '' || detailAddress === '' || jibunAddress === '') {
      alert(this.$t('ORDER.ORDER_DETAIL.VALIDATION_ADDRESS'));
      this.$refs['detailAddress_' + item.shippingNo][0].focus();
      return false;
    }

    // if (customsIdNumber === '') {
    //   alert(this.$t('ORDER.ORDER_DETAIL.VALIDATION_CUSTOMS_ID_NUMBER'));
    //   this.$refs['customsIdNumber_' + item.shippingNo][0].focus();
    //   return false;
    // }

    return true;
  }

  public replaceContent(content) {
    return content?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
