

























import { Vue, Component, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { ToolTipOption } from '@/types/tooltip';
import { i18nForDelivery } from '@/const/contents/configuration/shippingCharge';

export const TYPE = {
  AREA: 'AREA',
  CONFIG: 'CONFIG',
} as const;

@Component({
  components: { NoticeBox },
  computed: {
    isArea(): boolean {
      return this.option.type === TYPE.AREA;
    },
    isAreaConfig(): boolean {
      return this.option.type === TYPE.CONFIG;
    },
  },
})
export default class DeliveryTemplate extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption<keyof typeof TYPE>;
  private i18nForDelivery = i18nForDelivery;
}
