




import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import tuiColorPicker from 'tui-color-picker';
import 'tui-color-picker/dist/tui-color-picker.css';

interface TuiColorPicker {
  create: ({ container: HTMLElement }) => TuiColorPicker;
  on: (eventName: string, callBackFunction: Function) => void;
  setColor: (defaultColorHexCode: string) => void;
}

interface Color {
  color: string;
  origin: string;
}

@Component
export default class ColorPicker extends Vue {
  @Prop({ default: '#ffffff' }) public defaultColorHexCode: string;
  @Ref() private readonly colorPickerContainerRef: HTMLElement;

  public tuiColorPicker: TuiColorPicker = tuiColorPicker;
  public colorPicker!: TuiColorPicker;
  public selectColorHexCode = '#ffffff';

  @Watch('defaultColorHexCode')
  onPropertyReceived() {
    this.selectColorHexCode = this.defaultColorHexCode;
  }

  mounted() {
    this.init();
  }

  private init() {
    this.colorPicker = this.tuiColorPicker.create({
      container: this.colorPickerContainerRef,
    });

    this.colorPicker.setColor(this.defaultColorHexCode);

    this.bindEvents();
  }

  private bindEvents() {
    this.colorPicker.on('selectColor', (event: Color) => this.onSelectColor(event));
  }

  private onSelectColor(colorData: Color) {
    this.selectColorHexCode = colorData.color;
    this.$emit('onSelect', colorData);
  }
}
