




















































































import { Component, Mixins, Prop, PropSync, Ref } from 'vue-property-decorator';
import { MallProductImage, YorN } from 'ncp-api-supporter';
import { CallbackForImage, ImageFileUpload, PostedImagesResponse } from '@/types';
import ImageFileInputMixin from '@/components/common/input/imageFileInput/ImageFileInputMixin.vue';
import { isAddableImage } from '@/views/contents/product/basic/ProductAdd/imagesInfo/ImagesInfo';

@Component
export default class MainImage extends Mixins(ImageFileInputMixin) implements ImageFileUpload {
  @PropSync('mallProductImages')
  private mallProductImagesSync!: MallProductImage[];

  @Prop({ required: true })
  private readonly disabled: boolean;

  private readonly RECOMMEND_IMAGE_SIZE = { W: 640, H: 640 };

  callbackForPostedImage(): CallbackForImage {
    return this.isMultiple ? this.getCallback('N') : this.getCallback('Y');
  }

  resetImageInput(): void {
    this.isMultiple ? (this.additionalImageInputRef.value = '') : (this.representImageInputRef.value = '');
  }

  get isMallSelected(): boolean {
    return this.mallNo > 0;
  }

  private get mainImage(): MallProductImage {
    return this.mallProductImagesSync.find((image: MallProductImage) => image.mainYn === 'Y');
  }

  private get hasMainImage(): boolean {
    return this.mainImage !== undefined;
  }

  private get isAddable(): boolean {
    const representImageCount = this.mainImage === undefined ? 0 : 1;
    const imagesTotalCount = this.mallProductImagesSync.length;
    const MAX_ADDABLE_SIZE = 9;
    return representImageCount > 0 ? imagesTotalCount < MAX_ADDABLE_SIZE + 1 : imagesTotalCount < MAX_ADDABLE_SIZE;
  }

  @Ref()
  private readonly representImageInputRef!: HTMLInputElement;

  @Ref()
  private readonly additionalImageInputRef!: HTMLInputElement;

  private addImage({ currentTarget }: { currentTarget: HTMLInputElement }): void {
    if (!isAddableImage(this)) return;
    currentTarget.name === 'represent' ? this.representImageInputRef.click() : this.additionalImageInputRef.click();
  }

  private getCallback(mainYn: YorN): CallbackForImage {
    return (data: PostedImagesResponse) => {
      this.mallProductImagesSync.push({
        mainYn,
        imageUrl: data.url,
      });
    };
  }

  private removeImage(index?: number): void {
    this.minusCurrentSize();
    index === undefined ? this.remove(this.representImageIndex) : this.remove(index);
  }

  private get representImageIndex(): number {
    return this.mallProductImagesSync.findIndex(image => image.mainYn === 'Y');
  }

  private remove(idx: number): void {
    this.mallProductImagesSync.splice(idx, 1);
  }
}
