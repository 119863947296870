



























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import SelectBox from '@/components/common/SelectBox.vue';

@Component({
  components: {
    SelectBox,
  },
})
export default class BenefitRecalculate extends Vue {
  @Prop({ required: true })
  private readonly isModifyMode: boolean;

  @Prop({ required: true })
  private readonly refundTypeLabel: string;

  @Prop({ required: true })
  private readonly refundPayTypeLabel: string;

  @Prop({ required: true })
  private readonly showRefundTypeSelect: boolean;

  @Prop({ required: true })
  private readonly refundTypeOptions: OptionData<string>;

  @Prop({ required: true })
  private readonly isZeroRefund: boolean;

  @Prop({ required: true })
  private readonly isCancelNoRefund: boolean;

  @PropSync('refundType')
  private readonly refundTypeSync: boolean;
}
