


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClaimOption } from 'ncp-api-supporter';
import { exchangeImmediateDiscountedAmt } from '@/components/popup/claim/claimFormula';
import TdClaimProductName from '@/components/popup/claim/TdClaimProductName.vue';

@Component({ components: { TdClaimProductName } })
export default class ReleaseProduct extends Vue {
  @Prop({ required: true })
  private readonly exchangeOption!: ClaimOption;

  private exchangeImmediateDiscountedAmt = exchangeImmediateDiscountedAmt;
}
