













































































import { Vue, Component, Prop } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { PopupClose, throwPopup } from '@/helpers/popup';
import { AreaDetails } from 'ncp-api-supporter';
import { AreafeeDetail } from 'ncp-api-supporter/dist/types/modules/delivery/areas';
import { i18nForDelivery } from '@/const/contents/configuration/shippingCharge';
@Component({
  components: { RadioGroup, SelectBox, TextInput },
})
export default class AddArea extends Vue {
  @Prop() private data;
  @Prop() private onPositiveClick!: (data: AreafeeDetail) => void;
  @Prop() private onNegativeClick!: () => void;

  private i18nForDelivery = i18nForDelivery;

  private onClick({ target }) {
    if (!target.dataset.type) return;
    const { type } = target.dataset;
    switch (type) {
      case 'search-address':
        this.openAddressPopup();
    }
  }

  private addedData: AreaDetails = {
    address: null,
    roadAddress: '',
    jibunAddress: '',
    zipCd: '',
    countryCd: this.data.countryCd,
  };

  private openAddressPopup() {
    throwPopup({
      name: 'SearchAddress',
      data: { keyword: '' },
    }).then(({ state, data }) => {
      if (PopupClose.CONFIRM === state) {
        this.addedData.address = this.getAddress(data.jibunAddress);
        this.addedData.zipCd = data.zipCode;
        this.addedData.roadAddress = data.roadAddress + data.roadAddressExtra;
        this.addedData.jibunAddress = data.jibunAddress;
      }
    });
  }
  private getAddress(address: string): string {
    if (this.addedData.address && address === undefined) return;
    const numIdx = address.match(/\d/).index;
    return address.substring(0, numIdx).trim();
  }
  private save() {
    if (this.addedData.roadAddress === '' && this.addedData.jibunAddress === '') {
      alert(this.i18nForDelivery('ALERT_PLZ_ADDRESS'));
      return;
    }
    this.$api
      .postAreas({
        data: this.addedData,
      })
      .then(res => {
        this.onPositiveClick({
          areaNo: res.data.areaNo,
          ...this.addedData,
          extraDeliveryAmt: null,
          defaultArea: false,
        });
      });
    // this.onPositiveClick(this.addedData);
  }
}
