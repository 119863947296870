export default Object.freeze({
  TITLE: '쇼핑몰 관리',
  BTN_NEW_SHOPPING_MALL_ADD: '신규 쇼핑몰 등록',
  NAME_TITLE: '쇼핑몰명',
  MALL_NO: '쇼핑몰번호',
  EXPIRATION_DUE_DAYS: '남은 사용기간',
  DUE_DAYS: '{expirationDueDays}일 ',
  PAYMENT_DATE: '(결제 예정일 : {paymentDueDate})',
  COUNTRYCODE_TITLE: '판매대상국가',
  PC_TITLE: 'PC웹',
  MOBILE_TITLE: '모바일웹',
  USED_TITLE: '사용여부',
  REGISTERADMIN_TITLE: '등록자',
  CREATEDDATETIME_TITLE: '등록일',
  GOTO_RIGHT_NOW: '바로가기',
  NOT_USE_STATE: '사용 안 함',
  SEE_APP_INFO: '앱 정보보기',
  SERVICE_IS_NO_DATA: '해당 서비스에 쇼핑몰이 존재하지않습니다.',
  HELP_WHATIS_STANDARD_MALL:
    '· 기준 몰이란 최초로 생성된 쇼핑몰을 뜻하며 기준 아이콘이 표시됩니다.<br>· shop by pro는 기준 몰 연장 시 해당 관리자에서 생성한 모든 쇼핑몰이 연장됩니다.',
  HELP_MALL_LIMIT_COUNT: '쇼핑몰은 최대 20개까지 등록 가능합니다.',
  LAST_MODIFIER: '최종 수정자',
  LAST_MODIFIED_DATE: '최종수정일',
  DELETED_ADMIN: '삭제된 운영자',
  STANDARD: '기준',
  ALERT_BASIC_NOT_REGISTERED:
    'basic의 경우 쇼핑몰 등록이 불가합니다. pro 요금제 이용 시, 추가 서비스 이용이 가능합니다. 요금제를 변경 하시겠습니까?',
  MESSAGE_PAYMENT_INFO_TITLE: '쇼핑몰 결제 안내',
  MESSAGE_PAYMENT_CONTENT:
    '쇼핑몰 등록 결제는 통합회원으로 전환된 대표운영자만 가능합니다. <br> · 통합회원 전환은 NHN커머스 홈페이지에서 가능합니다.',
  BUTTON_GO_COMMERCE: 'NHN커머스 홈페이지 바로가기',
  NOTICE_AFTER_MALL_REGISTER_INFO:
    '쇼핑몰 등록 후 쇼핑몰 수정 페이지에서 운영상 필요한 필수 정보들을 반드시 입력 하시길 바랍니다.',
  EXTENSION: '연장',
});
