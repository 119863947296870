

































import { Vue, Component, Watch } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import { getBannerListGridOption, SEARCH_DATE_TYPE, SEARCH_TYPE, containerOptions } from '@/const/contents/banner';
import { Getter } from 'vuex-class';
import $ from 'jquery';
import {
  Mall,
  GetSkinBannersRequest,
  BannerSearchDateType,
  BannerSearchType,
  YorN,
  BannerPlatformType,
  GetSkinBannersBannerNoPreviewRequest,
  PutSkinBannersDisplayableRequest,
  DeleteSkinBanners,
} from 'ncp-api-supporter';
import { QueryString } from '@/types/common';
import { CheckGridEventProps } from '@/types';
import { throwPopup } from '@/helpers/popup';
import { throwMessagePopup, PopupClose } from '@/helpers/popup';
import axios, { CancelTokenSource } from 'axios';
import { Popup } from '@/types/popup';

@Component({
  components: {
    Grid,
  },
})
export default class Banner extends Vue {
  private gridProps = getBannerListGridOption();
  private contents = [];
  private totalCount = 0;
  private query = {};
  private gridContainerOptions = containerOptions;

  @Getter('popup/getLastPopupItem') lastPopupItem!: Popup;
  @Getter('mall/getMalls') private malls!: Mall[];

  mounted() {
    const no = String(this.malls && this.malls[this.malls.length - 1].mallNo);
    this.$router.push({ query: { mallNo: no } });
    this.initQuery();
    // this.search();
    this.attachEvent();
  }
  beforeDestroyed() {
    $('body').off('.display-select');
    $('body').off('.btnBn-img');
  }
  private attachEvent(): void {
    $('body').on('change', '.display-select', $.proxy(this.onChangeSelect, this));
    $('body').on('click', '.btnBn-img', $.proxy(this.clickBannerImage, this));
  }
  private onChangeSelect(e): boolean {
    const i = $(e.target).data('arrayindex');
    const value = $(e.target).val();
    let status = String(this.$t('BANNER.BANNER.VISIBLE'));
    if (value === 'INVISIBLE') {
      status = String(this.$t('BANNER.BANNER.INVISIBLE'));
    }
    if (confirm(String(this.$t('BANNER.BANNER.SURE_CHANGE', [this.contents[i].bannerTitle, status])))) {
      const displayable = status === String(this.$t('BANNER.BANNER.INVISIBLE')) ? false : true;
      const request: PutSkinBannersDisplayableRequest = {
        pathParams: {
          bannerNo: String(this.contents[i].bannerNo),
        },
        params: {
          displayable: displayable,
        },
      };
      this.$api
        .putSkinBannersDisplayable(request)
        .then(() => {
          alert(this.$t('BANNER.BANNER.VISIBLE_CHANGE'));
          this.search();
        })
        .catch(event => {
          if (status === String(this.$t('BANNER.BANNER.INVISIBLE'))) {
            $(e.target).val('VISIBLE');
          } else {
            $(e.target).val('INVISIBLE');
          }
          alert(event.data.result.message);
          return false;
        });
    } else {
      if (status === String(this.$t('BANNER.BANNER.INVISIBLE'))) {
        $(e.target).val('VISIBLE');
      } else {
        $(e.target).val('INVISIBLE');
      }
      return false;
    }
  }
  private clickBannerImage(e: Event): void {
    const bannerNo = $(e.target).data('no');
    const request: GetSkinBannersBannerNoPreviewRequest = {
      pathParams: {
        bannerNo: bannerNo,
      },
    };
    this.$api.getSkinBannersBannerNoPreview(request).then(res => {
      throwPopup({
        name: 'BannerPreview',
        data: {
          results: res.data,
        },
      });
    });
  }
  private initQuery(): void {
    this.query = {
      mallNo: '' as '' | number,
      searchType: SEARCH_TYPE.ALL,
      searchKeyword: '',
      startDate: '',
      endDate: '',
      searchDateType: SEARCH_DATE_TYPE.REGISTER,
      platformType: '',
      skinNo: '' as '' | number,
      displayStatus: '' as string,
      bannerGroupNo: '' as '' | number,
    };
  }
  @Watch('$route')
  private onQueryStringChanged() {
    const { query } = this.$route;
    // this.gridProps.options.pageOptions.page = query.page ? query.page : this.gridProps.options.pageOptions.page;
    this.gridProps.options.pageOptions.page = query.page ? query.page : 1;
    this.gridProps.options.pageOptions.perPage = query.pageSize
      ? query.pageSize
      : this.gridProps.options.pageOptions.perPage;
    this.search();
  }
  private getSkinBannersRequest(query: QueryString): GetSkinBannersRequest {
    const platformType = query.platformType as string;
    const request = {
      params: {
        mallNo: query.mallNo ? Number(query.mallNo) : undefined,
        skinNo: query.skinNo ? Number(query.skinNo) : undefined,
        bannerNos: query.bannerNos ? String(query.bannerNos) : undefined,
        searchDateType: query.searchDateType as BannerSearchDateType,
        startDate: query.startDate as string,
        endDate: query.endDate as string,
        searchType: query.searchType as BannerSearchType,
        searchKeyword: query.searchKeyword as string,
        bannerGroupNo: query.bannerGroupNo ? Number(query.bannerGroupNo) : undefined,
        displayStatus: query.displayStatus ? (query.displayStatus as YorN) : undefined,
        platformType: platformType === 'ALL' ? undefined : (platformType as BannerPlatformType),
        page: Number(query.page),
        size: Number(query.perPage),
      },
    };
    return request;
  }
  private async getSkinBanners(request: GetSkinBannersRequest): Promise<void> {
    const { data } = await this.$api.getSkinBanners(request);
    this.contents = data.contents;
    this.pushIndex(this.contents);
    this.totalCount = data.totalCount;
  }
  private search(): void {
    const { query } = this.$route;
    query.page = this.gridProps.options.pageOptions.page;
    query.perPage = this.gridProps.options.pageOptions.perPage;

    const request = this.getSkinBannersRequest(query);
    this.getSkinBanners(request);
  }
  private pushIndex(array): void {
    array.forEach((e, index) => {
      e.arrayIndex = index;
    });
  }
  private checkedRows = [];
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.checkedRows = checkProps.selected;
  }

  private async removeBanner(): Promise<void> {
    if (this.checkedRows.length === 0) {
      alert(this.$t('BANNER.BANNER.PLEASE_CHOOSE_DELETE'));
    } else {
      throwMessagePopup(
        this.$t('BANNER.BANNER.SURE_DELETE', [this.checkedRows.length]),
        true,
        this.$t('BANNER.BANNER.POPUP_TITLE'),
        false,
        this.$t('CONFIRM'),
        this.$t('CANCEL'),
      ).then(result => {
        if (result.state === PopupClose.CONFIRM) {
          const source: CancelTokenSource = axios.CancelToken.source();
          throwMessagePopup(
            this.$t('BANNER.BANNER.DELETE_ING', [this.checkedRows.length]),
            false,
            this.$t('BANNER.BANNER.POPUP_TITLE'),
            false,
            '',
            this.$t('CANCEL'),
            true,
          ).then(() => {
            source.cancel();
          });

          const request: DeleteSkinBanners = {
            params: {
              bannerNos: this.checkedRows.join(','),
            },
          };
          setTimeout(() => {
            this.deleteSkinBanners(request, source).then(() => {
              (this.lastPopupItem.onClose as Function)({ state: PopupClose.CLOSE, data: null });
              throwMessagePopup(
                this.$t('BANNER.BANNER.DELETE_ALREADY'),
                true,
                this.$t('BANNER.BANNER.POPUP_TITLE'),
                true,
                this.$t('CONFIRM'),
              );
              this.search();
            });
          }, 1000);
        }
      });
    }
  }
  private async deleteSkinBanners(request: DeleteSkinBanners, source: CancelTokenSource): Promise<void> {
    if (source) {
      const params = request.params;
      await this.$api.deleteSkinBanners({ params, cancelToken: source.token });
    } else {
      await this.$api.deleteSkinBanners(request);
    }
  }
  private clickBannerAdd(): void {
    this.$router.push({ name: 'BannerAddForm' });
  }
}
