






















































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SelectBox from '@/components/common/SelectBox.vue';
import { Grid as TuiGrid } from '@toast-ui/vue-grid';
import { GridEventProps } from '@/types/tui-grid/event';
import { CellValue, Row, RowKey } from '@/types/tui-grid/store/data';
import { OptRow } from '@/types/tui-grid/options';
import { InternalProp } from '@/types/tui-grid';
import { i18nListByKey } from '@/utils/i18n';
import {
  OptColumn,
  OptHeader,
  GridPropOptions,
  DefaultGridContainerOption,
  DefaultGridOption,
  ColumnOrder,
  CreateExcel,
} from '@/types/grid';
import { DEFAULT_GRID_PAGE_SIZE_KEY, DEFAULT_GRID_PER_PAGE, DEFAULT_GRID_PER_PAGE_OPTIONS } from '@/const/default';
import { convertQueryParamItemToBoolean, sendQueryString } from '@/utils/query';
import _ from 'underscore';
import ColumnController from '@/components/common/grid/ColumnController.vue';
import { TranslateResult } from 'vue-i18n';
import { resetNoDataMessage } from '@/helpers/grid';
import { throwPopup } from '@/helpers/popup';
import ExcelCreateButton from '@/components/common/grid/ExcelCreateButton.vue';

const resetDefaultGridContainerOption = (): DefaultGridContainerOption => {
  return {
    showsTopArea: true,
    hasBoarder: true,
    showsRightArea: true,
    hasSettingButton: true,
    hasPageSizeSelector: true,
    hasExcelDownloadButton: true,
    pageSizeKey: DEFAULT_GRID_PAGE_SIZE_KEY,
    pageSizeList: DEFAULT_GRID_PER_PAGE_OPTIONS,
    showPageNavigation: true,
  };
};
const resetDefaultGridOption = (): DefaultGridOption => {
  return {
    rowHeight: 40,
    minBodyHeight: 190,
    bodyHeight: 480,
    pageOptions: {
      perPage: 30,
      page: 1,
      totalCount: 0,
    },
  };
};

/*
 * @toast-ui/vue-grid, tui-grid 두가지 버전의 그리드가 존재한다.
 * 전자는 tui-grid를 vue에 맞게 한번 wrap한 패키지이다.
 * 전자는 vue 전용이기는 하나 타입스크립트를 염두하지 않아 타입이 없다.
 * 그래서 후자를 사용했다. 그런데 체크 박스 체크 시 브라우저가 먹통되는 문제가 있다.
 * 그래서 둘을 섞어 사용하게 되었다. 전자의 기능 후자의 타입. 2020-01-05 hslee
 * */
@Component({
  components: { ExcelCreateButton, ColumnController, SelectBox, TuiGrid },
})
export default class GridComponent extends Vue {
  @Getter('lnb/isLnbShowing') isLnbShowing!: boolean;

  @Prop({ required: false, default: 0 }) private totalCount!: number;
  @Prop({ required: false }) private onItemClicked!: Function;
  @Prop({ required: false }) private onRowChecked!: Function;

  // @toast-ui/vue-grid 구성요소 네가지
  @Prop({ required: true }) private header!: OptHeader;
  @Prop({ required: true }) private columns!: OptColumn[];
  @Prop({ required: true }) private options!: GridPropOptions;
  @Prop({ required: true }) private data!: OptRow[];
  // 컨테이너 구성요소 옵션
  @Prop({ required: false }) private displayOptions!: DefaultGridContainerOption;
  // 엑셀 생성 쿼리
  @Prop({ default: null }) private readonly createExcel!: CreateExcel | null;
  @Prop({ default: true }) private readonly showCreateExcel!: boolean;
  @Prop({ default: true }) private readonly isQueryStringMode!: boolean;
  @Prop({ required: false, default: true })
  private readonly resetNoDataMessage: boolean;
  @Prop({ default: 30 })
  private readonly defaultPageSize!: number;
  @Prop({ required: false })
  private readonly handleExcelDownloadButtonClick!: () => void;

  @Ref('tuiGrid') private tuiGrid: TuiGrid;

  @Watch('displayOptions', { deep: true })
  private changeDisplayOptions() {
    this.mergedContainerOption = Object.assign(this.mergedContainerOption, this.displayOptions);
  }
  private mergedContainerOption: DefaultGridContainerOption = resetDefaultGridContainerOption();
  private mergedGridOption: GridPropOptions = resetDefaultGridOption();
  private convertedColumns: OptColumn[] = [];

  private columnOrders: ColumnOrder[] = [];
  private mergedGridHeader: OptHeader;
  private standardColumnOrders: ColumnOrder[] = [];
  private disabledCnt = 0;
  private gridDefaultHeight = {
    height: 50,
  };

  private currentPageSize = DEFAULT_GRID_PER_PAGE;
  private pageSizeKey = DEFAULT_GRID_PAGE_SIZE_KEY;

  private created() {
    // columns.header의 i18n key를 실제 텍스트로 변환
    this.convertedColumns = i18nListByKey(this, this.columns, 'header');
    this.mergedContainerOption = Object.assign(this.mergedContainerOption, this.displayOptions); // prop으로 전달받은 option과 default option 병합
    this.mergedGridOption = Object.assign(this.mergedGridOption, this.options); // prop으로 전달받은 option과 default option 병합
    this.mergedGridHeader = Object.assign(this.header ? this.header : {}, this.gridDefaultHeight); // 디자인팀에서 고정값으로 요청
    if (!this.mergedContainerOption.showPageNavigation) {
      delete this.mergedGridOption.pageOptions;
    }
    this.pageSizeKey = this.mergedContainerOption.pageSizeKey;
    this.currentPageSize = this.mergedGridOption.pageOptions?.perPage;
  }

  private invokeRowChecked(checkType: string, selected: RowKey[], gridProps: GridEventProps) {
    this.onRowChecked && this.onRowChecked({ checkType: checkType, selected: selected, ...gridProps });
  }
  private bindGridEvent() {
    // check 관련 이벤트를 proxy
    this.tuiGrid.invoke('on', 'check', gridEvent => {
      this.invokeRowChecked('check', this.getCheckedRowKeys(), gridEvent as GridEventProps);
    });
    this.tuiGrid.invoke('on', 'uncheck', gridEvent => {
      this.invokeRowChecked('uncheck', this.getCheckedRowKeys(), gridEvent as GridEventProps);
    });
    this.tuiGrid.invoke('on', 'checkAll', gridEvent => {
      this.invokeRowChecked('checkAll', this.getCheckedRowKeys(), gridEvent as GridEventProps);
    });
    this.tuiGrid.invoke('on', 'uncheckAll', gridEvent => {
      this.invokeRowChecked('uncheckAll', this.getCheckedRowKeys(), gridEvent as GridEventProps);
    });

    // SELECT, INPUT 인 경우 네이티브 동작하도록
    this.tuiGrid.invoke('on', 'mousedown', gridEvent => {
      const tagName = (gridEvent as any).nativeEvent.target.tagName; // eslint-disable-line
      if (tagName === 'SELECT' || tagName === 'INPUT') {
        gridEvent.stop();
      }
    });
    // check 시 클릭 이벤트 발동 안되도록
    this.tuiGrid.invoke('on', 'click', gridEvent => {
      if ((gridEvent as GridEventProps).columnName !== '_checked') {
        this.onItemClicked && this.onItemClicked(gridEvent);
      }
    });
  }

  onClickHeaderTooltip(register = true) {
    if (!this.mergedContainerOption.headerTooltipOption) return;

    this.mergedContainerOption.headerTooltipOption.forEach(({ id, popupData }) => {
      const tooltipButton = this.$el.querySelector(id);
      const openTooltipPopup = e => {
        throwPopup({ name: 'GridHeaderTooltip', data: popupData });

        e.preventDefault();
        e.stopPropagation();
      };

      if (register) {
        tooltipButton?.addEventListener('mousedown', openTooltipPopup);
      } else {
        tooltipButton?.removeEventListener('mousedown', openTooltipPopup);
      }
    });
  }

  async mounted() {
    // await this.initGridFromApi();

    const pagination = this.tuiGrid.invoke('getPagination');
    if (pagination) {
      this.tuiGrid.invoke('on', 'beforePageMove', gridEvent => {
        this.moveToPage((gridEvent as GridEventProps).page, Number(this.currentPageSize));
        gridEvent.stop();
      });
    }
    this.bindGridEvent();
    window.addEventListener('resize', this.onWindowResized);
    this.tuiGrid.invoke('refreshLayout');
    this.onClickHeaderTooltip();
  }

  private beforeDestroyed() {
    this.onClickHeaderTooltip(false);
  }

  private destroyed() {
    window.removeEventListener('resize', this.onWindowResized);
    this.resetNoDataMessage && resetNoDataMessage();
  }

  private get isShippingDomain(): boolean {
    return this.$route.path.includes('shipping');
  }

  private get orderedColumns() {
    if (!(this.columnOrders.length > 0)) return this.convertedColumns;

    let columnOrder = null;
    return _.sortBy(this.convertedColumns, column => {
      columnOrder = _.findWhere(this.columnOrders, { columnName: column.name });
      // console.log(columnOrder.displayOrder);
      return columnOrder.displayOrder;
    });
  }

  private get titleWrapperClass() {
    return [
      this.mergedContainerOption.titleWrapperClass ?? 'table-tit-area',
      { 'no-border': !this.mergedContainerOption.hasBoarder },
    ];
  }

  // events
  @Watch('isLnbShowing')
  onLnbShowingChanged() {
    // TODO : lnb toggle triggered > store changed > detect store > add jquery animation class 그러다보니 animation 끝나는 타이밍의 너비를 계산할 수가 없다.
    // WARN : 그래서 .gnb-wrap : transition: width 0.5s; 스타일에 지정된 0.5s 만큼 setTimeout 한다.
    setTimeout(() => {
      this.tuiGrid.invoke('refreshLayout');
    }, 500);
  }

  @Watch('data')
  onDataChanged() {
    let fromURLQueries = Object.assign(
      { page: 1, [this.pageSizeKey]: this.defaultPageSize },
      convertQueryParamItemToBoolean(this.$route.query),
    );

    if (!this.isQueryStringMode) {
      fromURLQueries = Object.assign(
        { page: 1, [this.pageSizeKey]: this.defaultPageSize },
        { page: this.mergedGridOption.pageOptions.page, [this.pageSizeKey]: this.mergedGridOption.pageOptions.perPage },
      );
    }

    const pagination = this.tuiGrid.invoke('getPagination');
    if (pagination) {
      const pageState = {
        totalCount: this.totalCount,
        page: Number(fromURLQueries.page) || 1,
        perPage: Number(fromURLQueries[this.pageSizeKey]) || this.defaultPageSize,
      };
      this.currentPageSize = pageState.perPage.toString();
      this.tuiGrid.invoke('resetData', this.data, { pageState });
    } else {
      this.tuiGrid.invoke('resetData', this.data);
    }
  }

  @Watch('convertedColumns')
  changedConvertedColumns(val) {
    if (val) this.tuiGrid.invoke('setColumns', val);
  }

  @Watch('columns')
  changeColumns() {
    this.convertedColumns = i18nListByKey(this, this.columns, 'header');
  }

  private onWindowResized() {
    if (this.tuiGrid) {
      this.tuiGrid.invoke('refreshLayout');
    }
  }
  // private onGridSettingButtonClicked() {
  //   throwPopup({
  //     name: 'EditTuiGrid',
  //     data: {
  //       columnOrders: this.columnOrders,
  //       standardColumnOrders: this.standardColumnOrders,
  //       disabledCnt: this.disabledCnt,
  //       gridName: this.GRID_NAME,
  //     },
  //   });
  // }
  private onPageSizeOptionChanged(pageSize: number) {
    this.moveToPage(1, pageSize);
  }
  private moveToPage(page: number | string, pageSize = this.defaultPageSize) {
    if (this.isQueryStringMode) {
      if (this.isShippingDomain) {
        //TODO: 주문,클레임 도메인에서는 빈문자열 쿼리를 지우면 안되는 상황이러서 따로 예외처리함 ㅠㅠ.. 오픈 이후에 그리드 옵션에 pagination empty query 옵션을 만들겠습니다.
        sendQueryString(this, { ...this.$route.query, page: Number(page), [this.pageSizeKey]: pageSize }, false);
      } else {
        sendQueryString(this, { ...this.$route.query, page: Number(page), [this.pageSizeKey]: pageSize });
      }
    } else {
      this.options.pageOptions[this.pageSizeKey] = pageSize;
      this.options.pageOptions.page = page;
    }
  }
  // TODO: grid 에서 지원하는 메소드로 메시지 변경 가능하다면 변경하기
  public changeNoDataMessage(message: TranslateResult): void {
    const gridStateContent = this.$el.querySelector('.tui-grid-layer-state-content');
    gridStateContent.innerHTML = `<p>${message}</p>`;
    // 데이터 있는 조건 검색 후 데이터 없는 조건 검색하면 데이터 문구가 겹쳐 버리는 버그가 있음.. nextTick도 안 먹어서
    setTimeout(this.checkNoDataMessage, 0);
  }
  private checkNoDataMessage() {
    const gridStateContent = this.$el.querySelector('.tui-grid-layer-state-content');

    if (gridStateContent.children.length > 1) {
      gridStateContent.firstElementChild.remove();
    }
  }
  public getCheckedRows() {
    return this.tuiGrid.invoke('getCheckedRows') as Row[];
  }
  public getCheckedRowKeys(): RowKey[] {
    return this.tuiGrid.invoke('getCheckedRowKeys') as RowKey[];
  }
  public getRowAt(key): Row {
    return this.tuiGrid.invoke('getRowAt', key) as Row;
  }
  public getRow(key): Row {
    return this.tuiGrid.invoke('getRow', key) as Row;
  }
  public getFocusedCell() {
    return this.tuiGrid.invoke('getFocusedCell');
  }
  public getElement(rowKey: RowKey, columnName: string) {
    return this.tuiGrid.invoke('getElement', rowKey, columnName);
  }
  public removeRow(rowKey: RowKey): void {
    this.tuiGrid.invoke('removeRow', rowKey);
  }
  public getData(): Omit<Row, InternalProp>[] {
    return this.tuiGrid.invoke('getData');
  }
  public setData(data: OptRow[]) {
    this.tuiGrid.invoke('resetData', data);
  }
  public setValue(rowKey: RowKey, columnName: string, value: CellValue): void {
    this.tuiGrid.invoke('setValue', rowKey, columnName, value);
  }
  public setBodyHeight(value: number): void {
    this.tuiGrid.invoke('setBodyHeight', value);
  }
  public uncheckAll(): void {
    return this.tuiGrid.invoke('uncheckAll');
  }

  public addRowClassName(rowKey: RowKey, className: string): void {
    this.tuiGrid.invoke('addRowClassName', rowKey, className);
  }

  public removeRowClassName(rowKey: RowKey, className: string): void {
    this.tuiGrid.invoke('removeRowClassName', rowKey, className);
  }

  public checkRow(rowKey: RowKey): void {
    this.tuiGrid.invoke('check', rowKey);
  }

  public uncheckRow(rowKey: RowKey): void {
    this.tuiGrid.invoke('uncheck', rowKey);
  }

  public disableRowCheck(rowKey: RowKey): void {
    this.tuiGrid.invoke('disableRowCheck', rowKey);
  }

  private onExcelDownloadButtonClicked() {
    // TODO : 정확히 무슨 동작하는지 잘 모름
    /*if (this.data && this.data.length > 0) {
			const columns = i18nAllObject(this, this.gridInfo.excelDownInfo.columns);
			let fileName = this.gridInfo.excelDownInfo.fileName;
			if (this.gridInfo.excelDownInfo.nameDateFormat !== undefined) {
				fileName += changeDateFormat(new Date(), this.gridInfo.excelDownInfo.nameDateFormat);
			}
			writeExcel(this.data, columns, fileName, this.gridInfo.excelDownInfo.formatters);
		} else {
			alert(this.$t('NOT_DATA'));
		}*/
  }

  private pickColumnNameFromStandard(originColumnOrders: ColumnOrder[]): ColumnOrder[] {
    const tempColumnOrders = [];
    const standardColumnOrderGroups = _.groupBy(this.standardColumnOrders, 'columnName');

    _.forEach(originColumnOrders, (columnOrder: ColumnOrder) => {
      if (standardColumnOrderGroups[columnOrder.columnName]) {
        tempColumnOrders.push({
          displayName: standardColumnOrderGroups[columnOrder.columnName][0].displayName,
          ...columnOrder,
        });
      }
    });
    return tempColumnOrders;
  }
  // private async initGridFromApi() {
  //   this.standardColumnOrders = this.convertedColumns.map((column, index) => {
  //     return { columnName: column.name, displayOrder: index, visible: true, displayName: column.header };
  //   });
  //   // 프로즌 처리는 없다. (disabledCnt)
  //
  //   const { data } = await this.$api.getGridLayouts({ params: { gridName: this.GRID_NAME } });
  //   if (data) this.columnOrders = this.pickColumnNameFromStandard(data);
  //
  //   console.log(this.standardColumnOrders, this.columnOrders);
  // }
}
