export default Object.freeze({
  CANCEL_DELAY: '취소 지연',
  EXCHANGE_DELAY: '교환 지연',
  RETURN_DELAY: '반품 지연',

  CANCEL_APPLY_YMDT: '취소신청일자',
  CANCEL_COMPLETE_YMDT: '취소완료일자',
  EXCHANGE_APPLY_YMDT: '교환신청일자',
  EXCHANGE_COMPLETE_YMDT: '교환완료일자',
  RETURN_APPLY_YMDT: '반품신청일자',
  RETURN_COMPLETE_YMDT: '반품완료일자',
  REFUND_APPLY_YMDT: '환불신청일자',
  REFUND_COMPLETE_YMDT: '환불완료일자',

  ORDER_DELETE: '삭제처리',

  DETAIL_SEARCH: '상세검색',
  INCLUDE_RESERVATION: '예약주문 함께보기',
  INCLUDE_HOLD_DELIVERY: '배송보류 함께보기',
  PERIOD_SEARCH: '기간검색',

  ALREADY_DELIVERY: '이미출고',
  SHOW_DETAIL: '상세보기',
  CANCEL_ACCEPT: '취소승인',
  EXCHANGE_ACCEPT: '교환승인',
  RETURN_ACCEPT: '반품승인',
  DISAPPROVAL_ACCEPT: '반려승인처리',
  COLLECT_COMPLETE: '수거완료',

  SMS_SEND: 'SMS 발송',
  SELECT_ORDER_SEND: '선택 주문 발송',
  SEARCH_ORDER_SEND: '검색 주문 발송',
});
