export default Object.freeze({
  COLLECT_DAMAGED_PRODUCT: '상품 확인 시 사용 흔적이 있거나 손상이 있을 경우 상세 사유를 기재해 주세요.',
  COLLECT_RESPONSIBLE: '구매자 귀책여부 확인 후 귀책 반영하여 최종 환불 처리하겠습니다.',
  COLLECT_RESTORE_STOCK: '재고복원을 신청하고 수거완료 처리한 경우 교환완료 시점에 재고가 자동으로 복원 됩니다.',
  COLLECT_REFUND_REQUEST: '환불금액의 조정이 필요한 경우 수거완료(환불조정)항목을 선택해주세요.',

  EXCHANGE_PROCESS_ADDITIONAL: '교환처리금액은 고객이 추가로 결제하거나 운영자가 고객에게 환불해야 합니다.',
  EXCHANGE_ADDITIONAL_ACCUMULATION:
    '추가결제 : 교환처리금액이 양수(+)인 경우에 해당하며 무통장입금 또는 적립금차감으로 결제처리 할 수 있습니다.',
  EXCHANGE_REFUND:
    '환불 : 교환처리금액이 음수(-)인 경우에 해당하며 최초 결제 시 결제수단 또는 현금으로 환불처리 할 수 있습니다.',
  EXCHANGE_REFUND_EXCEPTION:
    "단, 부분취소가 불가한 일부 PG사의 경우 환불방법을 '현금환불'로 변경하여 환불처리해 주세요.",
  CANCEL_EXCHANGE_RESTORE:
    '출고 전, 교환취소 상품은 별도의 수거 절차가 필요하지 않으며 교환사유에 따라 재고 복원이 자동으로 처리됩니다.',
  RETURN_EXCHANGE_RESTORE:
    '출고 후, 교환반품 상품은 수거완료처리시 [재고복원] 신청한 경우에만 교환완료 이후 자동으로 복원처리 됩니다.',
});
