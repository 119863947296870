












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClaimType } from '@/types/claim';
import { isCancelClaim, isExchangeClaim, isReturnClaim } from '@/components/popup/claim/claimConditions';

@Component({
  computed: {
    isCancelClaim,
    isExchangeClaim,
    isReturnClaim,
  },
})
export default class NoticeBox extends Vue {
  @Prop({ required: true })
  private readonly claimType!: ClaimType;
}
