







































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  GetSmsGodoPoint,
  GetSmsGodoPointRequest,
  GetSmsTemplatesAutoTemplatesTemplateType,
  NCPResponse,
  PostMembersResetPasswordRequest,
} from 'ncp-api-supporter';
import RadioGroup from '@/components/common/RadioGroup.vue';

@Component({
  components: { RadioGroup },
})
export default class MemberTempPassword extends Vue {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: () => void;
  @Prop() private readonly onNegativeClick!: () => void;

  private autoMailUsed = false;
  private tempPasswordMailUsed = false;
  private smsUsed = false;
  private tempPasswordSmsUsed = false;
  private point = 0;
  private kakaoUsed = false;

  private notificationSendType = 'EMAIL';
  private notificationSendTypeOption = {
    name: 'notificationSendTypeOption',
    data: [
      { id: 'email', value: 'EMAIL', display: this.$t('MEMBER.REGISTRATION.MEMBER_TEMP_PASSWORD_NOTIFY_TYPE_EMAIL') },
      { id: 'sms', value: 'SMS', display: this.$t('MEMBER.REGISTRATION.MEMBER_TEMP_PASSWORD_NOTIFY_TYPE_SMS') },
      { id: 'all', value: 'ALL', display: this.$t('MEMBER.REGISTRATION.MEMBER_TEMP_PASSWORD_NOTIFY_TYPE_ALL') },
    ],
  };

  private async onSaveClick() {
    if (this.isValidate()) {
      await this.sendApiRequest();
    }
  }

  private isValidate() {
    if (this.notificationSendType === 'EMAIL' || this.notificationSendType === 'ALL') {
      if (this.data.contents.email.trim().length == 0) {
        alert(this.$t('MEMBER.REGISTRATION.ALERT_NO_EMAIL'));
        return false;
      }
      if (!this.autoMailUsed || !this.tempPasswordMailUsed) {
        alert(this.$t('MEMBER.REGISTRATION.ALERT_DONT_SEND_MAIL'));
        return false;
      }
    }

    if (this.notificationSendType === 'SMS' || this.notificationSendType === 'ALL') {
      if (this.data.contents.mobileNo.trim().length == 0) {
        alert(this.$t('MEMBER.REGISTRATION.ALERT_NO_MOBILE_NO'));
        return false;
      }
      if (!this.smsUsed || !this.tempPasswordSmsUsed) {
        alert(this.$t('MEMBER.REGISTRATION.ALERT_DONT_SEND_SMS'));
        return false;
      }
      if (Number(this.point) < 1) {
        alert(this.$t('MEMBER.REGISTRATION.ALERT_SMS_POINT'));
        return false;
      }
    }
    return true;
  }

  private async init() {
    await Promise.all([
      this.getNotificationsConfigurations(),
      this.getSmsAutoTemplates(),
      this.getMailTemplatesAutoTemplates(),
      this.fetchSmsPoint(),
    ]);
  }

  created(): void {
    this.init();
  }

  private async getMailTemplatesAutoTemplates(): Promise<void> {
    try {
      const { data } = await this.$api.getMailTemplatesAutoTemplates({
        pathParams: {
          templateType: 'MEMBER_TEMP_PASSWORD',
        },
        params: {
          mallNo: this.data.contents.mallNo,
        },
      });
      this.tempPasswordMailUsed = data.used;
    } catch (e) {
      console.log(e);
      this.tempPasswordMailUsed = false;
    }
  }

  private async getSmsAutoTemplates(): Promise<void> {
    try {
      const {
        data,
      }: NCPResponse<GetSmsTemplatesAutoTemplatesTemplateType> = await this.$api.getSmsTemplatesAutoTemplatesTemplateType(
        {
          pathParams: {
            templateType: 'MEMBER_TEMP_PASSWORD',
          },
          params: {
            mallNo: this.data.contents.mallNo,
          },
        },
      );
      this.tempPasswordSmsUsed = data.usable;
    } catch (e) {
      console.log(e);
      this.tempPasswordSmsUsed = false;
    }
  }

  public async fetchSmsPoint(): Promise<void> {
    const request: GetSmsGodoPointRequest = {
      params: {
        mallNos: this.data.contents.mallNo.toString(),
      },
    };
    try {
      const response: NCPResponse<GetSmsGodoPoint[]> = await this.$api.getSmsGodoPoint(request);
      this.point = response.data[0].point;
    } catch (err) {
      this.point = 0;
      console.error(err);
    }
  }

  private async getNotificationsConfigurations(): Promise<void> {
    const { data } = await this.$api.getNotificationsConfigurations({ params: { mallNo: this.data.contents.mallNo } });

    if (data) {
      this.smsUsed = data.smsUsed;
      this.autoMailUsed = data.emailUsed;
      this.kakaoUsed = data.kakaoUsed;
    }
  }

  private async sendApiRequest(): Promise<void> {
    try {
      const response = await this.$api.postMembersResetPassword(this.tempPasswordRequest);
      if (response.status == 204) {
        alert(this.$t('MEMBER.REGISTRATION.SUCCESS_ISSUANCE'));
        this.onPositiveClick();
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  private get tempPasswordRequest(): PostMembersResetPasswordRequest {
    return {
      data: {
        mallNo: this.data.contents.mallNo,
        memberNo: this.data.contents.memberNo,
        notificationType: this.notificationSendType,
      },
    };
  }
}
