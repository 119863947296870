export default Object.freeze({
  WAREHOUSE_ADD_TITLE: '입출고 주소 등록',

  ADDRESS_NAME: '주소명',
  ADDRESS_NAME_PLACEHOLDER: '입출고주소명입력',
  COUNTRY: '국가',
  SUBSTITUTION: '대체문구',
  ADDRESS: '주소',
  SEARCH_ZIPCODE: '우편번호 찾기',
  INCERT_SUBSTITUTION: '대체문구 입력',
  DEFAULT_FLAG: '대표여부',
  DEFAULT_RELEASE: '대표출고지',
  DEFAULT_RETURN: '대표 반품/교환지',

  WAREHOUSE_ADD_ALERT_1: '주소명을 입력해주세요.',
  WAREHOUSE_ADD_ALERT_2: '주소를 입력해주세요.',
  WAREHOUSE_ADD_ALERT_3: 'Address 1를 입력해주세요.',
  WAREHOUSE_ADD_ALERT_4: 'Address 2를 입력해주세요.',
  WAREHOUSE_ADD_ALERT_5: 'Zip / Postal Code를 입력해주세요.',
  WAREHOUSE_ADD_ALERT_6: 'City를 입력해주세요.',
  WAREHOUSE_ADD_ALERT_7: 'State/Province/Region를 입력해주세요.',
  WAREHOUSE_ADD_ALERT_8: '대체문구를 입력해주세요.',
  WAREHOUSE_ADD_SUCCESS: '저장완료되었습니다.',

  // areaFee
  SELECTED_AREA_FEE_TITLE: '선택한 지역에 추가배송비',
  BATCH_BUTTON: '일괄적용',
  DELETE_AREA_BUTTON: '선택삭제',
  ADD_AREA_BUTTON: '지역추가',

  NO_DATA_AREAS_FEE: '등록된 지역별추가배송비가 없습니다.',

  TOOL_TIP_AREA_FEE_TITLE: '지역별 추가배송비 설정 안내',
  TOOL_TIP_AREA_FEE_CONTENT_1: '기본 제공되는 지역리스트와 추가한 지역 별로 추가배송비 설정을 할 수 있습니다.',
  TOOL_TIP_AREA_FEE_CONTENT_2: '국가를 선택하여 해당 국가의 지역별로 추가배송비를 입력하세요.',
  TOOL_TIP_AREA_FEE_CONTENT_3:
    '한국의 경우 등록된 지역의 지번/도로명주소에 관계없이 주문서 적용이 가능하며 미국/캐나다의 경우 주(State)별로\n입력한 추가배송비가 적용됩니다.',

  TOOL_TIP_AREA_FEE_CONFIG_CONTENT_1:
    '추가배송비를 적용할 지역명을 관리할 수 있으며, [지역추가]에서 검색한 주소로 지역명을 추가하고 삭제할 수 있습니다.',
  TOOL_TIP_AREA_FEE_CONFIG_CONTENT_2: '단, 기본으로 제공되는 지역명은 삭제가 불가합니다.',

  AREA_FEE_FORM_TITLE: '지역별 추가배송비 등록',
  AREA_FEE_DEFAULT_CONFIGURATION: '기본설정',
  AREA_FEE_CONFIGURATION: '지역별추가배송비명',
  PLACEHOLDER_EXTRA_AMT: '지역별추가배송비명 입력',
  SELECT_COUNTRY: '국가선택',
  CONFIGURATION_EXTRA_AMT: '지역별 추가배송비 설정',
  NO: 'No.',
  AREA_NAME: '지역명',
  DELIVERY: '추가배송',
  CONFIRM_CHANGE_COUNTRY: '국가 변경 시 변경된 내용은 저장되지 않습니다. 변경하시겠습니까?',
  ALERT_SELECT_AREA: '지역을 선택해주세요.',
  ALERT_BATCH_FEE: '일괄 적용할 배송비를 입력해주세요.',
  CONFIRM_DELETE_WITHOUT_STANDARD_AREAS: '기본으로 제공되는 지역명은 삭제가 불가합니다. 제외하고 삭제하시겠습니까?',
  CONFIRM_CANCEL: '취소하시겠습니까?',
  ALERT_NAME: '지역별추가배송비명을 입력해주세요.',
  ALERT_EXTRA_AMT: '지역별추가배송비를 입력해주세요.',

  // add-area
  ADD_AREA: '지역 추가',
  SEARCH_ADDRESS: '주소 검색',
  ROADADDRESS: '도로명',
  JIBUNADDRESS: '지번',
  ALERT_PLZ_ADDRESS: '주소를 입력해주세요.',

  ALERT_FAIL_MSG: '해당 추가배송비를 사용중인 배송비 템플릿이 존재하여 삭제가 불가합니다.',
});
