







import { Vue, Component } from 'vue-property-decorator';
import MerchantCenterAccountInfo from '@/components/marketing/configuration/googleMarketing/MerchantCenterAccountInfo.vue';
import AdsAccountInfo from '@/components/marketing/configuration/googleMarketing/AdsAccountInfo.vue';

@Component({
  components: {
    MerchantCenterAccountInfo,
    AdsAccountInfo,
  },
})
export default class AccountManage extends Vue {}
