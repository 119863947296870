import { CertificationInfo } from 'ncp-api-supporter';
import {
  CertificationInfoDetails,
  CertificationNoticeList,
  CertificationValidResult,
  RequiredCertificationType,
} from '@/types';
import { i18n } from '@/main';

const requiredCertificationDateType = () => [
  i18n.t('PRODUCT.CERTIFICATION.MUST_DATE_TYPE_1'),
  i18n.t('PRODUCT.CERTIFICATION.MUST_DATE_TYPE_2'),
  i18n.t('PRODUCT.CERTIFICATION.MUST_DATE_TYPE_3'),
];

const requiredCertificationKidsType = () => [
  i18n.t('PRODUCT.CERTIFICATION.MUST_KID_TYPE_1'),
  i18n.t('PRODUCT.CERTIFICATION.MUST_KID_TYPE_2'),
  i18n.t('PRODUCT.CERTIFICATION.MUST_KID_TYPE_3'),
];

export const requiredTypes = () => ({
  date: requiredCertificationDateType(),
  kid: requiredCertificationKidsType(),
});

export const noticeList = (): CertificationNoticeList => ({
  top: [
    i18n.t('PRODUCT.CERTIFICATION.PLEASE_INPUT_CORRECT').toString(),
    i18n.t('PRODUCT.CERTIFICATION.PLEASE_HONEST').toString(),
  ],
  bottom: [
    `${i18n.t(
      'PRODUCT.CERTIFICATION.INFO1',
    )} <a href="http://www.rra.go.kr/ko/license/A_c_search.do" target="_blank" class="search_link">${i18n.t(
      'PRODUCT.CERTIFICATION.INFO2',
    )}&gt;${i18n.t('PRODUCT.CERTIFICATION.INFO3')}</a> ${i18n.t('PRODUCT.CERTIFICATION.INFO4')}`,
    `${i18n.t(
      'PRODUCT.CERTIFICATION.TIP1',
    )}<a href="http://www.safetykorea.kr/release/certificationsearch" target="_blank" class="search_link"> ${i18n.t(
      'PRODUCT.CERTIFICATION.TIP2',
    )}&gt;${i18n.t('PRODUCT.CERTIFICATION.TIP3')}</a> ${i18n.t('PRODUCT.CERTIFICATION.INFO4')}`,
  ],
});

export const getDefaultCertificationInfoDetails = (): CertificationInfoDetails => ({
  certificationName: '',
  certificationCategoryNo: '',
  certificationAuthority: '',
  certificationNumber: '',
  certificationCompanyName: '',
  certificationDate: '',
});

export const getDefaultCertification = (): CertificationInfo => ({
  requiresCompanyCertification: false,
  certificationNo: null,
  certificationCategory: '',
});

export const alertMsg = (type: RequiredCertificationType, symbolList?: string[]): void => {
  if (!type) return;
  switch (type) {
    case 'basic':
      alert(i18n.t('PRODUCT.CERTIFICATION.MUST_ITEM'));
      return;
    case 'date':
      alert(i18n.t('PRODUCT.CERTIFICATION.MUST_DATE'));
      return;
    case 'categoryNo':
      alert(i18n.t('PRODUCT.CERTIFICATION.SAME'));
      return;
    case 'symbol':
      alert(i18n.t('PRODUCT.CERTIFICATION.NEXT_PROHIBIT') + symbolList.join(', '));
      return;
    case 'add':
      alert(i18n.t('PRODUCT.CERTIFICATION.CERTIFICATION_20'));
      return;
  }
};

export const hasInvalidSymbol = (text: string): boolean => {
  const symbolList: string[] = ['\\', '*', '?', '"', '<', '>'];
  return symbolList.some(symbol => text.includes(symbol));
  // if (hasSymbol) {
  //   alertMsg('symbol', symbolList);
  // }
  // return hasSymbol;
};

const isBasicRequired = (info: CertificationInfoDetails): boolean =>
  info.certificationAuthority !== '' && info.certificationNumber !== '';

const isSpecificRequired = (info: CertificationInfoDetails): { type: 'kid' | 'date'; value: boolean } => {
  let result;
  const { kid, date } = requiredTypes();
  const kidsType = kid.includes(info.certificationName);
  const dateType = date.includes(info.certificationName);
  if (kidsType || dateType) {
    result = { type: 'kid', value: info.certificationCompanyName !== '' };
  }
  if (dateType) {
    if (result.value === false) return result;
    result = { type: 'date', value: info.certificationDate !== '' };
  }
  return result;
};

export const isAllValid = (type: RequiredCertificationType): boolean => {
  if (!type) {
    return true;
  }
  alertMsg(type);
  return false;
};

export const getValidResult = (info: CertificationInfoDetails): CertificationValidResult => {
  const isBasic = info.certificationName !== '' ? isBasicRequired(info) : true;

  let isKid = true,
    isDate = true;
  if (info.certificationName) {
    const required = isSpecificRequired(info);
    isKid = required && required.type === 'kid' ? required.value : true;
    isDate = required && required.type === 'date' ? required.value : true;
  }

  return { isBasic, isKid, isDate };
};
