// hook
import { handleFeeRateBeforeBlur, handleFeeRateChange } from '@/views/contents/partner/hooks/feeRateReplaceHook';
// types
import { PartnerRendererProps, PartnerRowData } from '@/views/contents/partner/modification/PartnerModificationTypes';
// get html
import { getTextInput, getGridTextSpan } from '@/views/contents/partner/modification/grid/gridHTMLs';

// *** commissionRateRenderer ***
export default ({ rowKey, value, grid }: PartnerRendererProps<'pureCommissionRate'>) => {
  const contractStatus = grid.getValue<PartnerRowData, 'contractStatus'>(rowKey, 'contractStatus');
  const pureCommissionRate = grid.getValue<PartnerRowData, 'pureCommissionRate'>(rowKey, 'pureCommissionRate');
  const usesDefaultCommissionRate = grid.getValue<PartnerRowData, 'usesDefaultCommissionRate'>(
    rowKey,
    'usesDefaultCommissionRate',
  );

  let commissionRateHTML: HTMLElement;

  if (contractStatus === 'WAITING') {
    commissionRateHTML = getGridTextSpan(pureCommissionRate.toString());
  } else {
    const handleBlur = ({ target }) => {
      target.value = handleFeeRateBeforeBlur(target.value);
    };

    const handleCommissionRateChange = ({ target }) => {
      const validCommissionRate = handleFeeRateChange(target.value);

      target.value = validCommissionRate;
      grid.setValue(rowKey, 'pureCommissionRate', validCommissionRate);
    };

    commissionRateHTML = getTextInput(value, !usesDefaultCommissionRate, handleCommissionRateChange);
    commissionRateHTML.onblur = handleBlur;
  }

  return {
    getElement(): HTMLElement {
      return commissionRateHTML;
    },

    render() {
      return;
    },
  };
};
