




































import { Component, Prop, Ref, Model } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import FileInput from '@/components/common/input/fileInput/FileInput.vue';
import { TranslateResult } from 'vue-i18n';
import { getDataFromExcelFile, ParsedObject } from '@/utils/fileReader';
@Component({
  components: {
    FileInput,
    NoticeBox,
  },
})
export default class ExcelUpload extends WindowPopupMainVue {
  @Model('parsed')
  private readonly value!: ParsedObject[];
  @Prop({ required: true })
  private readonly title!: TranslateResult;
  @Prop({ required: false })
  private readonly noticeList!: TranslateResult[];

  public getData<DataType>(): DataType {
    return this.data;
  }

  @Ref()
  private readonly fileInput!: FileInput;

  private isProcessing = false;
  private parsedExcelFileData: ParsedObject[] = [];

  private async readExcelFileSetData(file: File): Promise<void> {
    this.isProcessing = true;
    this.parsedExcelFileData = await getDataFromExcelFile(file);
    this.isProcessing = false;
  }

  private onClickSubmit(): void {
    if (!this.fileInput.hasFile) {
      alert(this.$t('COMMON.EXCEL.NO_EXCEL_ERROR'));
      this.fileInput.focus();
      return;
    }

    if (this.parsedExcelFileData.length <= 0) {
      alert(this.$t('COMMON.EXCEL.NO_DATA'));
      this.fileInput.focus();
      return;
    }

    this.onPositiveClick(this.parsedExcelFileData);
  }
}
