




import { Component, Vue } from 'vue-property-decorator';
import AdminIframe from '@/components/iframe/AdminIframe.vue';

@Component({
  components: { AdminIframe },
})
export default class CollectionSettingIframe extends Vue {
  private get remoteSrc() {
    const query = this.$route.query;
    const params = this.$route.params;
    const queryObject = { serviceType: 'PRO', ...query, ...params };
    const queryString = new URLSearchParams(queryObject).toString();

    return process.env.VUE_APP_ADMIN_REMOTE_URL + '/member/basic/collection-setting?' + queryString;
  }
}
