


















































import { Component, Ref } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import Editor from '@/components/common/summernote/Editor.vue';

import sanitizeHtml from '@/utils/sanitizeHtml';
import { getContractsEntryContractsRequest } from '@/views/contents/partner/modification/apis/apiRequests';
import { GetContractsEntryContractsService } from 'ncp-api-supporter';
import { api } from '@/api';

@Component({
  components: { Editor, NoticeBox },
})
export default class EntryContractFilePopupCancel extends WindowPopupMainVue {
  @Ref()
  private readonly editorRef?: Editor;

  private createdDateTime = '';

  private async getEntryContracts() {
    const request = getContractsEntryContractsRequest(this.data.contractNo.toString());

    const { data: entryContracts } = await this.$api.getContractsEntryContracts(request);
    const latestEntryContract = entryContracts[entryContracts.length - 1];

    return { entryContracts, latestEntryContract };
  }

  private agreedEntryContract({ agreed }: GetContractsEntryContractsService) {
    if (agreed) {
      alert(this.$t('CONTRACT.POPUP.BLOCK_CANCEL_MSG'));

      return true;
    }

    return false;
  }

  private async cancelEntryContract() {
    const cancelEntryContract = confirm(this.$t('CONTRACT.POPUP.CANCEL_CONFIRM').toString());

    const { latestEntryContract } = await this.getEntryContracts();

    if (cancelEntryContract && !this.agreedEntryContract(latestEntryContract)) {
      await api.deleteContractsEntryContracts({ pathParams: { contractNo: this.data.contractNo } });

      const { entryContracts, latestEntryContract } = await this.getEntryContracts();

      const latestEntryContractAgreed = latestEntryContract?.agreed;
      const entryContractSize = entryContracts.length;

      this.onPositiveClick({
        latestEntryContractAgreed: latestEntryContractAgreed,
        entryContractSize: entryContractSize,
      });

      this.onPositiveClick({ latestEntryContractAgreed, entryContractSize });

      return;
    }
  }

  private async init() {
    const { latestEntryContract } = await this.getEntryContracts();
    this.editorRef.setHtml(sanitizeHtml(latestEntryContract.contents));
    this.createdDateTime = latestEntryContract.createdDateTime;
  }

  mounted() {
    this.init();
  }
}
