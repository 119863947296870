import { AUTH_METHOD, i18nSecurity } from '@/const/contents/configuration/operateSecurity';

export enum LOGIN_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  SECOND_AUTH = 'SECOND_AUTH',
  IP_BLOCK = 'IP_BLOCK',
  NATION_BLOCK = 'NATION_BLOCK',
}

export const getAuthRadioBoxOption = () => {
  return {
    secondAuthMethodRadioOption: {
      name: 'secondAuthType',
      data: [
        // { id: 'email', value: AUTH_METHOD.EMAIL, display: 'CONFIGURATION.SECURITY.EMAIL_AUTH' },
        // { id: 'sms', value: AUTH_METHOD.SMS, display: 'CONFIGURATION.SECURITY.SMS_AUTH' },
        { id: 'otp', value: AUTH_METHOD.OTP, display: 'CONFIGURATION.SECURITY.OTP_AUTH' },
      ],
    },
  };
};

export interface SecondAuthData {
  key: string;
  plan?: string;
  target: {
    email?: string;
    enableOtp: boolean;
    mobile?: string;
  };
  type?: string;
  types: string[];

  [key: string]: any;
}

export const getAuthToolTipOption = () => {
  return {
    secondAuth: {
      name: 'List',
      style: {
        width: '500px',
      },
      listOption: {
        subMessage: i18nSecurity('SECURITY_AUTH_WHETHER_DIALOGUES').split('\n'),
        mainTitle: i18nSecurity('SCREEN_SECURITY_SETTING_PAGE'),
        mainMessage: i18nSecurity('SCREEN_SECURITY_SETTING_PAGE_LIST').split('\n'),
      },
    },
  };
};
