import { PutAccumulationsConfigurationsRequest } from 'ncp-api-supporter';
import { CheckBoxGroupOption, OptionData, RadioBoxGroupOption } from '@/helpers/type';
import { i18n } from '@/main';
import { AccumulationConfig } from '@/types/member';
import { AccumulationReviewType, NotificationAvailable } from '@/types';
import { ToolTipOption } from '@/types/tooltip';
import { accumulationToolTipMessage } from '@/const/words/accumulationToolTip';

export enum NotificationGuideType {
  expired = 'expired',
  paymentDeduct = 'paymentDeduct',
  coupon = 'coupon',
  validation = 'validation',
}

export type NotificationGuideTypeKeywords = keyof typeof NotificationGuideType;

export const basicConfig: PutAccumulationsConfigurationsRequest['data']['basic'] = {
  accumulationValidPeriod: 12,
  expireNotificationUsable: true,
  accumulationUnit: '원',
  memberAccumulationUsable: false,
  expireNotificationBeforeDay: 30,
  accumulationRate: 0,
  expireNotificationChannels: ['EMAIL', 'SMS'],
  accumulationReserveTime: 'AFTER_A_WEEK',
  accumulationName: '적립금',
  productAccumulationUsable: false,
};

export const useConfig: PutAccumulationsConfigurationsRequest['data']['use'] = {
  excludingReserveWhenUseCoupon: false,
  minimumUsePriceLimitUsable: false,
  productPriceMinimumLimitUsable: false,
  maximumUseRateLimitUsable: false,
  productPriceMinimum: 0,
  minimumUsePrice: 0,
  excludingReserveWhenUseAccumulation: false,
  maximumUseRate: 0,
};

export const paymentConfig: PutAccumulationsConfigurationsRequest['data']['payment'] = {
  signUpAccumulation: 0,
  signUpAccumulationUsable: false,
  reviewAccumulation: {
    photoReviewsAccumulation: 0,
    photoReviewsLength: 0,
    reviewsLength: 0,
    reviewsAccumulation: 0,
  },
  appInstallAccumulation: 0,
  reviewAccumulationUsable: false,
  productAccumulationBasisType: 'SALE_PRICE',
  appInstallAccumulationUsable: false,
  birthdayAccumulation: 0,
  birthdayAccumulationUsable: false,
};

export const initConfig = (mallNo: number): PutAccumulationsConfigurationsRequest['data'] => {
  return {
    mallNo,
    updateAdminNo: 10,
    temporary: true,
    basic: { ...basicConfig },
    use: { ...useConfig },
    payment: { ...paymentConfig },
  };
};

export const getRadioBoxOption = (name: string, displayType = 'USE', reverse = false): RadioBoxGroupOption<boolean> => {
  return {
    name,
    data: [
      {
        id: `${name}_0`,
        value: !reverse,
        display: `ACCUMULATION.CONFIG_MODIFY.${displayType}${reverse ? '_NOT' : ''}`,
      },
      {
        id: `${name}_1`,
        value: reverse,
        display: `ACCUMULATION.CONFIG_MODIFY.${displayType}${!reverse ? '_NOT' : ''}`,
      },
    ],
  };
};

export const notificationTimeSelectOption = (): OptionData<number>[] => {
  return [
    { name: i18n.t('ACCUMULATION.CONFIG_MODIFY.N_DAYS_AGO', [2]).toString(), value: 2 },
    { name: i18n.t('ACCUMULATION.CONFIG_MODIFY.N_DAYS_AGO', [7]).toString(), value: 7 },
    { name: i18n.t('ACCUMULATION.CONFIG_MODIFY.N_DAYS_AGO', [14]).toString(), value: 14 },
    { name: i18n.t('ACCUMULATION.CONFIG_MODIFY.N_DAYS_AGO', [20]).toString(), value: 20 },
    { name: i18n.t('ACCUMULATION.CONFIG_MODIFY.N_DAYS_AGO', [30]).toString(), value: 30 },
  ];
};

export const notificationMethodCheckboxOption: CheckBoxGroupOption<string> = {
  name: 'checkbox_expire',
  hasAll: true,
  noBrackets: true,
  data: [
    { id: 'checkbox_expire_all', value: 'ALL', display: 'ALL' },
    { id: 'checkbox_expire_sms', value: 'SMS', display: 'ACCUMULATION.CONFIG_MODIFY.SMS' },
    { id: 'checkbox_expire_email', value: 'EMAIL', display: 'ACCUMULATION.CONFIG_MODIFY.EMAIL' },
  ],
};

export const notificationAvailableInfo = (): NotificationAvailable[] => {
  return [
    { key: 'SMS', text: 'SMS', bChecked: false, bUsed: false, bAutoTemplate: false },
    {
      key: 'EMAIL',
      text: i18n.t('MEMBER.POPUP.EMAIL').toString(),
      bChecked: false,
      bUsed: false,
      bAutoTemplate: false,
    },
  ];
};

export const arrAccumulationReserveTime: OptionData<string>[] = [
  { value: 'IMMEDIATE', name: 'ACCUMULATION.CONFIG_MODIFY.IMMEDIATE' },
  { value: 'NEXTDAY', name: 'ACCUMULATION.CONFIG_MODIFY.NEXTDAY' },
  { value: 'DAY_AFTER_TOMMOROW', name: 'ACCUMULATION.CONFIG_MODIFY.DAY_AFTER_TOMMOROW' },
  { value: 'AFTER_A_WEEK', name: 'ACCUMULATION.CONFIG_MODIFY.AFTER_A_WEEK' },
  { value: 'AFTER_TWO_WEEK', name: 'ACCUMULATION.CONFIG_MODIFY.AFTER_TWO_WEEK' },
  { value: 'AFTER_TWENTY_DAYS', name: 'ACCUMULATION.CONFIG_MODIFY.AFTER_TWENTY_DAYS' },
  { value: 'AFTER_THIRTY_DAYS', name: 'ACCUMULATION.CONFIG_MODIFY.AFTER_THIRTY_DAYS' },
];

export const reviewAccumulation: AccumulationReviewType[] = [
  { type: 'reviews', title: 'ACCUMULATION.CONFIG_MODIFY.BASIC_REVIEW' },
  { type: 'photoReviews', title: 'ACCUMULATION.CONFIG_MODIFY.PHOTO_REVIEW' },
];

export const accumulationToolTipOption = (messageType: string, width = '750px'): ToolTipOption => {
  const getMessage = accumulationToolTipMessage()[messageType].toString();
  return {
    name: 'List',
    listOption: {
      mainMessage: getMessage.split('\n'),
    },
    style: { width },
  };
};

export const validationCondition = (
  accumulationConfig: PutAccumulationsConfigurationsRequest['data'],
): AccumulationConfig => {
  const noNotificationMethod = !(
    accumulationConfig.basic.expireNotificationUsable && !accumulationConfig.basic.expireNotificationChannels.length
  );
  const noBasicRate = !(
    accumulationConfig.basic.productAccumulationUsable &&
    !accumulationConfig.basic.accumulationRate &&
    accumulationConfig.basic.accumulationRate !== 0
  );
  const noSignupPoint = !(
    accumulationConfig.payment.signUpAccumulationUsable && !accumulationConfig.payment.signUpAccumulation
  );
  const noReviewSize = !(
    accumulationConfig.payment.reviewAccumulationUsable &&
    (!accumulationConfig.payment.reviewAccumulation.reviewsLength ||
      !accumulationConfig.payment.reviewAccumulation.photoReviewsLength)
  );
  const noReviewPoint = !(
    accumulationConfig.payment.reviewAccumulationUsable &&
    (!accumulationConfig.payment.reviewAccumulation.reviewsAccumulation ||
      !accumulationConfig.payment.reviewAccumulation.photoReviewsAccumulation)
  );
  const noBirthdayPoint = !(
    accumulationConfig.payment.birthdayAccumulationUsable && !accumulationConfig.payment.birthdayAccumulation
  );
  const noMinPrice = !(
    accumulationConfig.use.productPriceMinimumLimitUsable && !accumulationConfig.use.productPriceMinimum
  );
  const noMinUse = !(accumulationConfig.use.minimumUsePriceLimitUsable && !accumulationConfig.use.minimumUsePrice);
  const noMaxUse = !(accumulationConfig.use.maximumUseRateLimitUsable && !accumulationConfig.use.maximumUseRate);

  return {
    noNotificationMethod,
    noBasicRate,
    noSignupPoint,
    noReviewSize,
    noReviewPoint,
    noBirthdayPoint,
    noMinPrice,
    noMinUse,
    noMaxUse,
  };
};
