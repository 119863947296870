









































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import {
  BannerGroupType,
  BannerImageLinkTarget,
  GetSkinBannersGroupsRequest,
  Mall,
  PostSkinBannersRequest,
  BannerButtonSizeType,
  BannerPlatformType,
  BannerDisplayPeriodType,
  BannerSizeUnit,
  BannerSlideSpeed,
  BannerSlideType,
  BannerSlideNaviType,
  GetSkinBannersBannerNoRequest,
  BannerButtonBorderType,
  GetSkinBannersBannerNo,
  PutSkinBannersRequest,
  PostImagesResponse,
} from 'ncp-api-supporter';
import SelectBox from '@/components/common/SelectBox.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  PLATFORM_TYPE,
  DISPLAY_PERIOD_TYPE,
  SIZE_TYPE,
  LINK_TARGET,
  SLIDE_TYPE,
  SLIDE_SPEED,
  SLIDE_NAVI_TYPE,
  COLOR_DEFAULT,
  BANNER_GROUP_TYPE,
  MODE,
  BUTTON_SIZE_TYPE,
  BUTTON_BORDER_TYPE,
  UNIT,
  getBannerSelectType,
  getBannerRadioGroup,
  SLIDE_SPEED_NUM,
  DesignSkin,
  GroupNoData,
} from '@/const/contents/banner';
import DateCalendar from '@/components/common/DateCalendar.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import { getToday } from '@/utils/dateFormat';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { DateRangeOption } from '@/types';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import Slick from 'vue-slick';
import { SlickOptions } from '@/types/slick';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import ColorPickerButton from '@/components/common/colorpicker/ColorPickerButton.vue';
import { namespace } from 'vuex-class';
import $ from 'jquery';
import $el from '@/utils/element';
const mallStore = namespace('mall');

@Component({
  components: {
    SelectBox,
    RadioGroup,
    DateCalendar,
    DateRangePicker,
    TextInput,
    MallSelect,
    ToolTip,
    Slick,
    ColorPickerButton,
  },
})
export default class BannerAddForm extends Vue {
  @Ref() private readonly imageUploadSingle!: HTMLInputElement;
  @Ref() private readonly bannerFile!: HTMLInputElement;
  @Ref() private readonly activeImage!: HTMLInputElement;
  @Ref() private readonly inactiveImage!: HTMLInputElement;
  @Ref() private slick!: Slick;
  @Ref() private mallNo!: HTMLInputElement;
  @Ref() private bannerSkinNo!: HTMLInputElement;
  @Ref() private bannerGroupNo!: HTMLInputElement;
  @Ref() private bannerTitle!: HTMLInputElement;
  @Ref() private bannerWidth!: HTMLInputElement;
  @Ref() private bannerHeight!: HTMLInputElement;
  @Ref() private boxCont!: HTMLInputElement;

  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];
  private slickOptions: SlickOptions = {
    infinite: true,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: SLIDE_SPEED_NUM.NORMAL,
    arrows: true,
    dots: true,
    fade: false,
    // prevArrow: '',
    // nextArrow: '',
    dotsClass: 'init-slick-dot slick-dots',
  };
  private singleSlickOptions: SlickOptions = {
    infinite: false,
    draggable: false,
    autoplay: false,
    arrows: false,
    dots: false,
  };

  private allDisplayPeriod = DISPLAY_PERIOD_TYPE.REGULAR;
  private startDateTimeAllYmd = getToday();
  private startDateTimeAllHms = DEFAULT_TIME_RANGE.START;
  private endDateTimeAllYmd = getToday();
  private endDateTimeAllHms = DEFAULT_TIME_RANGE.END;
  private visibleAll = true;
  private sizeCheckBox = true;
  private allCheck = false;
  private bannerImagesSingleShow = false;
  private bannerImagesManyShow = false;
  private mode = MODE.ADD as MODE; //등록 or 수정
  private unit = UNIT.PIXEL;
  private bannerGroupType = '';
  // 수정
  private mallName = '';
  private platformTypeName = '';
  private skinName = '';
  private modifyData: GetSkinBannersBannerNo = {
    bannerId: '',
    bannerNo: 0,
    bannerGroupNo: 0,
    bannerGroupName: '',
    bannerGroupCode: '',
    bannerGroupType: BANNER_GROUP_TYPE.LOGO,
    mallNo: 0,
    skinNo: 0,
    skinName: '',
    skinCode: '',
    bannerTitle: '',
    displayValue: {
      displayPeriodType: DISPLAY_PERIOD_TYPE.REGULAR,
      startDateTime: '',
      endDateTime: '',
    },
    platformType: PLATFORM_TYPE.PC,
    visible: true,
    resizable: true,
    sizeUnitType: SIZE_TYPE.PERCENT,
    width: 0,
    height: 0,
    slideBannerConfig: {
      slideSpeedType: SLIDE_SPEED.NORMAL,
      slideTime: 0,
      slideEffectType: SLIDE_TYPE.SLIDE,
      usableSlideButton: true,
      slideButtonColor: '',
      slideNavigationType: SLIDE_NAVI_TYPE.VISIBLE,
      slideNavigationInfo: {
        buttonBorderType: BUTTON_BORDER_TYPE.CIRCLE,
        activeButtonColor: '',
        inactiveButtonColor: '',
        buttonSizeType: BUTTON_SIZE_TYPE.SIZE_ONE,
      },
    },
    bannerImages: [],
  };

  private query = {
    mallNo: '' as string | number,
    platformType: PLATFORM_TYPE.PC as BannerPlatformType,
    skinNo: '' as string | number,
    bannerGroupNo: '' as string | number,
    visible: true,
    displayPeriodType: DISPLAY_PERIOD_TYPE.REGULAR as BannerDisplayPeriodType,
    startDateTimeYmd: getToday(),
    startDateTimeHms: DEFAULT_TIME_RANGE.START,
    endDateTimeYmd: getToday(),
    endDateTimeHms: DEFAULT_TIME_RANGE.END,
    bannerTitle: '',
    sizeUnitType: SIZE_TYPE.PIXEL as BannerSizeUnit,
    width: '' as string | number,
    height: '' as string | number,
    originWidth: 600,
    originHeight: 166,
    bannerImages: [
      {
        visible: true,
        landingUrl: '',
        inactiveNavigationImageUrl: '',
        displayValue: {
          startDateTimeYmd: getToday(),
          startDateTimeHms: DEFAULT_TIME_RANGE.START,
          endDateTimeYmd: getToday(),
          endDateTimeHms: DEFAULT_TIME_RANGE.END,
          displayPeriodType: DISPLAY_PERIOD_TYPE.REGULAR as BannerDisplayPeriodType,
        },
        imageUrl: '',
        description: '',
        activeNavigationImageUrl: '',
        openLocationType: LINK_TARGET.SELF as BannerImageLinkTarget,
        check: false,
        bannerImageName: '',
        activeNaviImageName: '',
        inactiveNaviImageName: '',
        originWidth: 600,
        originHeight: 166,
      },
      {
        visible: true,
        landingUrl: '',
        inactiveNavigationImageUrl: '',
        displayValue: {
          startDateTimeYmd: getToday(),
          startDateTimeHms: DEFAULT_TIME_RANGE.START,
          endDateTimeYmd: getToday(),
          endDateTimeHms: DEFAULT_TIME_RANGE.END,
          displayPeriodType: DISPLAY_PERIOD_TYPE.REGULAR as BannerDisplayPeriodType,
        },
        imageUrl: '',
        description: '',
        activeNavigationImageUrl: '',
        openLocationType: LINK_TARGET.SELF as BannerImageLinkTarget,
        check: false,
        bannerImageName: '',
        activeNaviImageName: '',
        inactiveNaviImageName: '',
        originWidth: 600,
        originHeight: 166,
      },
      {
        visible: true,
        landingUrl: '',
        inactiveNavigationImageUrl: '',
        displayValue: {
          startDateTimeYmd: getToday(),
          startDateTimeHms: DEFAULT_TIME_RANGE.START,
          endDateTimeYmd: getToday(),
          endDateTimeHms: DEFAULT_TIME_RANGE.END,
          displayPeriodType: DISPLAY_PERIOD_TYPE.REGULAR as BannerDisplayPeriodType,
        },
        imageUrl: '',
        description: '',
        activeNavigationImageUrl: '',
        openLocationType: LINK_TARGET.SELF as BannerImageLinkTarget,
        check: false,
        bannerImageName: '',
        activeNaviImageName: '',
        inactiveNaviImageName: '',
        originWidth: 600,
        originHeight: 166,
      },
    ],
    imageUrl: '' as string, //배너 이미지 주소
    description: '' as string, //배너 이미지 설명
    landingUrl: '' as string, //배너 이미지 링크 주소
    openLocationType: LINK_TARGET.SELF as BannerImageLinkTarget,
    bannerFileName: '',
    slideSpeedType: SLIDE_SPEED.NORMAL as BannerSlideSpeed,
    slideTime: 3,
    slideEffectType: SLIDE_TYPE.SLIDE as BannerSlideType,
    usableSlideButton: true,
    slideButtonColor: COLOR_DEFAULT.FFF as string,
    slideNavigationType: SLIDE_NAVI_TYPE.VISIBLE as BannerSlideNaviType,
    inactiveButtonColor: COLOR_DEFAULT.FFF as string,
    activeButtonColor: COLOR_DEFAULT.FFF as string,
    buttonSizeType: BUTTON_SIZE_TYPE.SIZE_ONE as BannerButtonSizeType,
    buttonBorderType: BUTTON_BORDER_TYPE.CIRCLE as BannerButtonBorderType,
  };

  // RadioGroup option
  private platformTypeOptions = getBannerRadioGroup().platformTypeOptions;
  private visibleYnOptions = getBannerRadioGroup('visibleYn').ynOptions;
  private visibleYnAllOptions = getBannerRadioGroup('visibleYnAll').ynOptions;
  private usableSlideButtonYnOptions = getBannerRadioGroup('usableSlideButtonYn').ynOptions;
  private displayPeriodTypeOptions = getBannerRadioGroup('displayPeriodType').periodTypeOptions;
  private displayPeriodTypeAllOptions = getBannerRadioGroup('displayPeriodTypeAll').periodTypeOptions;
  private slideTypeOptions = getBannerRadioGroup().slideTypeOptions;
  private slideNaviTypeOptions = getBannerRadioGroup().slideNaviTypeOptions;

  // SelectBox data
  private bannerSlideTimeData = getBannerSelectType().bannerSlideTimeData;
  private bannerSlideSpeedData = getBannerSelectType().bannerSlideSpeedData;
  private bannerSizeUnitData = getBannerSelectType().bannerSizeUnitData;
  private bannerImageLinkTargetData = getBannerSelectType().linkTargetData;
  private displayPeriodTypeData = getBannerSelectType().periodTypeData;
  private bannerImageLinkTargetManyData = getBannerSelectType().linkTargetData;
  private visibleYnData = getBannerSelectType().visibleYnData;

  private designSkinDefault = getBannerSelectType().designSkinDefault as DesignSkin[];
  private groupNoDataDefault = getBannerSelectType().groupNoDataDefault;

  private allDesignSkin: DesignSkin[] = [];
  private designSkin: DesignSkin[] = [];
  private groupNoData: GroupNoData[] = [];

  private calendarOption: DateRangeOption = {
    name: 'calendarOption',
    dateType: 'YmdHms',
    hasPeriodBtn: false,
    fromRanges: getToday(),
  };

  private calendarAllOption: DateRangeOption = {
    name: 'calendarAllOption',
    dateType: 'YmdHms',
    hasPeriodBtn: false,
    fromRanges: getToday(),
  };
  private calendarOptionMany(index: number): DateRangeOption {
    return {
      name: 'calendarManyOption' + index,
      dateType: 'YmdHms',
      hasPeriodBtn: false,
      fromRanges: getToday(),
    };
  }

  // life-cycle
  private created() {
    this.navInit();
    if (this.$route.query.bannerNo) {
      this.mode = MODE.MODIFY;
    } else {
      this.mode = MODE.ADD;
    }
  }
  private async mounted() {
    if (this.mode === MODE.MODIFY) {
      await this.modiInitData();
    } else if (this.mode === MODE.ADD) {
      this.addInitData();
    }
    this.$nextTick(() => {
      this.allStyleReturn();
    });
  }
  private judgeMode() {
    if (this.mode === MODE.MODIFY) {
      this.modiInitData();
    } else if (this.mode === MODE.ADD) {
      this.addInitData();
    }
  }
  @Watch('query.sizeUnitType')
  unitChange() {
    this.allStyleReturn();
  }
  @Watch('query.width')
  widthChange() {
    this.allStyleReturn();
  }
  @Watch('query.height')
  heightChange() {
    this.allStyleReturn();
  }
  @Watch('query.originWidth')
  originWidthChange() {
    this.allStyleReturn();
  }
  @Watch('query.originHeight')
  originHeightChange() {
    this.allStyleReturn();
  }
  @Watch('query.bannerImages')
  bannerImagesChange() {
    this.refreshSlick();
  }
  private allStyleReturn() {
    this.styleReturn();
    this.styleReturn(true);
  }
  private refreshSlick() {
    // slick가 연동할수 있게
    const currIndex = this.slick['currentSlide']();
    this.slick['destroy']();
    this.$nextTick(() => {
      this.slick['create']();
      this.slick.goTo(currIndex);
      this.setButtonImage();
    });
  }

  private onChangePlatformType(platformType: string) {
    this.query.skinNo = 0;
    if (!platformType) {
      this.designSkin = this.allDesignSkin;
      return;
    }
    const designSkin = this.allDesignSkin.filter(({ platform }) => platform === platformType);
    this.designSkin = [...this.designSkinDefault, ...designSkin];
  }

  @Watch('query.mallNo')
  mallChange() {
    if (this.query.mallNo) {
      // 몰번호 존재할때
      this.$api.getSkinsMallsMallNo({ pathParams: { mallNo: this.query.mallNo as number } }).then(res => {
        if (res.data && res.data.length !== 0) {
          this.designSkin = [];
          this.designSkin = [...this.designSkinDefault, ...res.data];
          this.allDesignSkin = [...this.designSkinDefault, ...res.data];
          this.designSkin.forEach((e, i) => {
            if (i !== 0) {
              // 사용중 스킨 선택
              if (e.isLiveSkin && !e.isWorkSkin && e.platform === 'PC') {
                this.query.skinNo = e.skinNo;
              }
              // 스킨 이름 formatter
              const key = 'skinNameFormatter';
              const formatter = this.skinNameFormatter(e.isLiveSkin, e.isWorkSkin);
              const platform = e.platform === 'PC' ? 'PC' : this.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
              e[key] = `[${platform}] ${e.skinName} (${e.skinCode})${formatter}`;
            }
          });
          this.onChangePlatformType(this.query.platformType);
          if (this.designSkin.length === 2) {
            this.query.skinNo = this.designSkin[1].skinNo;
          }
          if (this.mode === MODE.MODIFY) {
            this.query.skinNo = Number(this.modifyData.skinNo);
            this.designSkin.forEach(e => {
              if (this.query.skinNo === e.skinNo) {
                this.skinName = e.skinName;
              }
            });
          }
        } else if (res.data && res.data.length === 0) {
          // 디자인 스킨 존재하지 않을때
          this.designSkin = [...this.designSkinDefault];
          this.allDesignSkin = [...this.designSkinDefault];
          this.query.skinNo = 0;
        }
        if (this.groupNoData.length === 2) {
          this.query.bannerGroupNo = this.groupNoData[1].bannerGroupNo;
        } else {
          this.query.bannerGroupNo = 0;
        }
      });
    } else {
      // 몰번호 존재하지 않을때
      this.query.skinNo = 0;
    }
  }
  @Watch('query.skinNo')
  skinNoChange() {
    if (this.query.skinNo) {
      const request: GetSkinBannersGroupsRequest = {
        params: {
          mallNo: Number(this.query.mallNo),
          skinNo: Number(this.query.skinNo),
        },
      };
      this.$api.getSkinBannersGroups(request).then(res => {
        if (res.data) {
          this.groupNoData = [];
          this.groupNoData = [...this.groupNoDataDefault, ...res.data];
          this.groupNoData.forEach(e => {
            const groupType = this.returnGroupTypeText(e.bannerGroupType);
            e.groupName = `${groupType} - ${e.bannerGroupName}`;
          });
          this.query.bannerGroupNo = 0;
          if (this.groupNoData.length === 2) {
            this.query.bannerGroupNo = this.groupNoData[1].bannerGroupNo;
          }
          if (this.mode === MODE.MODIFY) {
            this.query.bannerGroupNo = this.modifyData.bannerGroupNo;
          }
        }
      });
    } else {
      this.query.bannerGroupNo = 0;
      if (this.groupNoData.length === 2) {
        this.query.bannerGroupNo = this.groupNoData[1].bannerGroupNo;
      }
    }
  }
  @Watch('query.bannerGroupNo')
  bannerGroupNoChange() {
    if (this.query.bannerGroupNo) {
      this.groupNoData.forEach(e => {
        if (e.bannerGroupNo === this.query.bannerGroupNo) {
          this.bannerGroupType = e.bannerGroupType;
        }
      });
      if (this.bannerGroupType === BANNER_GROUP_TYPE.SLIDE) {
        // 슬라이드
        this.bannerImagesManyShow = true;
        this.bannerImagesSingleShow = false;
      } else {
        // 슬라이드 아닐때
        this.bannerImagesManyShow = false;
        this.bannerImagesSingleShow = true;
      }
    } else {
      this.bannerImagesManyShow = false;
      this.bannerImagesSingleShow = false;
    }
  }
  @Watch('bannerGroupType')
  bannerGroupTypeChange() {
    if (this.bannerGroupType !== BANNER_GROUP_TYPE.SLIDE) {
      this.query.bannerImages.forEach(e => {
        e.visible = true;
        e.landingUrl = '';
        e.inactiveNavigationImageUrl = '';
        e.displayValue.startDateTimeYmd = getToday();
        e.displayValue.startDateTimeYmd = getToday();
        e.displayValue.startDateTimeHms = DEFAULT_TIME_RANGE.START;
        e.displayValue.endDateTimeYmd = getToday();
        e.displayValue.endDateTimeHms = DEFAULT_TIME_RANGE.END;
        e.displayValue.displayPeriodType = DISPLAY_PERIOD_TYPE.REGULAR as BannerDisplayPeriodType;
        e.imageUrl = '';
        e.description = '';
        e.activeNavigationImageUrl = '';
        e.openLocationType = LINK_TARGET.SELF as BannerImageLinkTarget;
        e.check = false;
        e.bannerImageName = '';
        e.activeNaviImageName = '';
        e.inactiveNaviImageName = '';
      });
    } else {
      this.query.imageUrl = '';
      this.query.description = '';
      this.query.landingUrl = '';
      this.query.openLocationType = LINK_TARGET.SELF;
      this.query.bannerFileName = '';
    }
  }
  private navInit(): void {
    throwBottomNavigation({
      buttons: [
        {
          type: 'right',
          key: 'back',
          color: 'gray',
          text: this.$t('LIST'),
        },
        {
          type: 'right',
          key: 'save',
          color: 'red',
          text: this.$t('SAVE'),
        },
      ],
      onClick: (key: string) => {
        switch (key) {
          case 'back': //목록;
            this.clickBack();
            break;
          case 'save': //저장;
            this.save();
            break;
        }
      },
    });
  }
  private addInitData(): void {
    this.onChangeColor('#ffffff', this.query, 'slideButtonColor');
    this.onChangeColor('#ffffff', this.query, 'activeButtonColor');
    this.onChangeColor('#ffffff', this.query, 'inactiveButtonColor');
  }
  private modiInitData(): void {
    const request: GetSkinBannersBannerNoRequest = {
      pathParams: {
        bannerNo: String(this.$route.query.bannerNo),
      },
    };
    this.$api.getSkinBannersBannerNo(request).then(res => {
      this.modifyData = res.data;

      this.query.mallNo = Number(res.data.mallNo);
      this.malls.forEach(e => {
        if (this.query.mallNo === e.mallNo) {
          this.mallName = e.mallName;
        }
      });

      this.query.platformType = res.data.platformType as BannerPlatformType;
      this.platformTypeOptions.data.forEach(e => {
        if (this.query.platformType === e.value) {
          this.platformTypeName = this.$t(e.display) as string;
        }
      });

      // this.designSkin.forEach(e => { //선후 순서 때문에 mallChange()에
      //   if (this.query.skinNo === e.skinNo) {
      //     this.skinName = e.skinName;
      //   }
      // });

      this.query.bannerGroupNo = res.data.bannerGroupNo; //선후 순서 때문에 skinNoChange()에도
      this.query.bannerTitle = res.data.bannerTitle;
      this.query.visible = res.data.visible;
      this.query.displayPeriodType = res.data.displayValue.displayPeriodType as BannerDisplayPeriodType;
      // ymd,hms로 분리
      if (this.query.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD) {
        const start: string[] = String(res.data.displayValue.startDateTime).split(' ');
        const end: string[] = String(res.data.displayValue.endDateTime).split(' ');
        this.query.endDateTimeHms = end[1];
        this.query.startDateTimeHms = start[1];
        this.query.startDateTimeYmd = start[0];
        this.$nextTick(() => {
          this.query.endDateTimeYmd = end[0];
        });
      }
      this.sizeCheckBox = !res.data.resizable;
      if (!this.sizeCheckBox) {
        // 등록한 이미지 사이즈대로 노출 안한 상태
        this.query.sizeUnitType = res.data.sizeUnitType as BannerSizeUnit;
        if (res.data.sizeUnitType === SIZE_TYPE.PIXEL) {
          this.query.height = res.data.height;
        }
        this.query.width = res.data.width;
      }

      if (res.data.bannerGroupType === BANNER_GROUP_TYPE.SLIDE) {
        // many banner
        if (res.data.bannerImages.length > 3) {
          for (let i = 0; i < res.data.bannerImages.length - 3; i++) {
            this.addBannerImages();
          }
        } else if (res.data.bannerImages.length < 3) {
          this.query.bannerImages.splice(
            res.data.bannerImages.length - this.query.bannerImages.length - 1,
            res.data.bannerImages.length - this.query.bannerImages.length,
          );
          this.query.bannerImages.length = res.data.bannerImages.length;
        }
        let isUpload = false;
        if (res.data.slideBannerConfig.slideNavigationType === String(SLIDE_NAVI_TYPE.UPLOAD)) {
          isUpload = true;
        }
        res.data.bannerImages.forEach((e, i) => {
          this.query.bannerImages[i].visible = e.visible;
          this.query.bannerImages[i].landingUrl = e.landingUrl;
          if (isUpload) {
            this.query.bannerImages[i].inactiveNavigationImageUrl = e.inactiveNavigationImageUrl;
            this.query.bannerImages[i].activeNavigationImageUrl = e.activeNavigationImageUrl;
            this.query.bannerImages[i].activeNaviImageName = this.getUrlName(e.activeNavigationImageUrl);
            this.query.bannerImages[i].inactiveNaviImageName = this.getUrlName(e.inactiveNavigationImageUrl);
          }
          // ymd,hms로 분리
          this.query.bannerImages[i].displayValue.displayPeriodType = e.displayValue
            .displayPeriodType as BannerDisplayPeriodType;
          if (this.query.bannerImages[i].displayValue.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD) {
            const start: string[] = String(e.displayValue.startDateTime).split(' ');
            const end: string[] = String(e.displayValue.endDateTime).split(' ');
            this.query.bannerImages[i].displayValue.startDateTimeYmd = start[0];
            this.query.bannerImages[i].displayValue.startDateTimeHms = start[1];
            this.query.bannerImages[i].displayValue.endDateTimeHms = end[1];
            this.$nextTick(() => {
              this.query.bannerImages[i].displayValue.endDateTimeYmd = end[0];
            });
          }
          this.query.bannerImages[i].imageUrl = e.imageUrl;
          this.query.bannerImages[i].description = e.description;
          this.query.bannerImages[i].openLocationType = e.openLocationType as BannerImageLinkTarget;
          this.query.bannerImages[i].bannerImageName = this.getUrlName(e.imageUrl);
          this.$nextTick(() => {
            this.query.bannerImages[i].originWidth = 0;
            this.query.bannerImages[i].originHeight = 0;

            this.getImageSizeByUrl(e.imageUrl, (width, height) => {
              this.query.bannerImages[i].originWidth = width;
              this.query.bannerImages[i].originHeight = height;
            });
          });
        });
        this.query.slideSpeedType = res.data.slideBannerConfig.slideSpeedType as BannerSlideSpeed;
        this.bannerSlideSpeedChange();
        this.query.slideTime = res.data.slideBannerConfig.slideTime;
        this.slideTimeChange();
        this.query.slideEffectType = res.data.slideBannerConfig.slideEffectType as BannerSlideType;
        this.bannerSlideTypeChange();

        this.query.usableSlideButton = res.data.slideBannerConfig.usableSlideButton;
        this.usableSlideButtonYnChange();
        if (this.query.usableSlideButton) {
          this.query.slideButtonColor = res.data.slideBannerConfig.slideButtonColor;
          this.onChangeColor(this.query.slideButtonColor, this.query, 'slideButtonColor');
        }
        this.query.slideNavigationType = res.data.slideBannerConfig.slideNavigationType as BannerSlideNaviType;
        this.slideNaviTypeChange();
        if (this.query.slideNavigationType === SLIDE_NAVI_TYPE.VISIBLE) {
          this.query.activeButtonColor = res.data.slideBannerConfig.slideNavigationInfo.activeButtonColor;
          this.onChangeColor(this.query.activeButtonColor, this.query, 'activeButtonColor');
          this.query.inactiveButtonColor = res.data.slideBannerConfig.slideNavigationInfo.inactiveButtonColor;
          this.onChangeColor(this.query.inactiveButtonColor, this.query, 'inactiveButtonColor');
          this.query.buttonSizeType = res.data.slideBannerConfig.slideNavigationInfo.buttonSizeType;
          this.sizeChange();
          this.query.buttonBorderType = res.data.slideBannerConfig.slideNavigationInfo.buttonBorderType;
          this.borderChange();
        }
      } else {
        // single banner
        this.query.landingUrl = res.data.bannerImages[0].landingUrl;
        this.query.description = res.data.bannerImages[0].description;
        this.query.imageUrl = res.data.bannerImages[0].imageUrl;
        this.query.openLocationType = res.data.bannerImages[0].openLocationType;
        this.query.bannerFileName = this.getUrlName(this.query.imageUrl);
        this.$nextTick(() => {
          this.query.originWidth = 0;
          this.query.originHeight = 0;

          this.getImageSizeByUrl(res.data.bannerImages[0].imageUrl, (width, height) => {
            this.query.originWidth = width;
            this.query.originHeight = height;
          });
        });
      }
    });
  }
  private getImageSizeByUrl(url: string, callback) {
    // url을 통하여 원본 그림 사이즈 가져오기
    const img = new Image();
    img.src = url;
    if (img.complete) {
      callback(img.width, img.height);
    } else {
      img.onload = function() {
        callback(img.width, img.height);
      };
    }
  }
  private getUrlName(url: string): string {
    const index = url.lastIndexOf('/');
    let str = '';
    str = url.substr(index + 1, url.length);
    return str;
  }
  private keyupWidth(e): void {
    const value = e.target.value;
    e.target.value = value.replace(/[^0-9]/g, '');
    this.query.width = e.target.value;
  }
  private keyupHeight(e): void {
    const value = e.target.value;
    e.target.value = value.replace(/[^0-9]/g, '');
    this.query.height = e.target.value;
  }

  private skinNameFormatter(isLiveSkin: boolean, isWorkSkin: boolean): string {
    let text = '';
    if (isLiveSkin && isWorkSkin) {
      text = String(this.$t('BANNER.BANNER.USING_OR_WORKING'));
    } else if (isLiveSkin && !isWorkSkin) {
      text = String(this.$t('BANNER.BANNER.USING'));
    } else if (!isLiveSkin && isWorkSkin) {
      text = String(this.$t('BANNER.BANNER.WORKING'));
    }
    return text;
  }

  private clickBack(): void {
    this.$router.push({ name: 'Banner' });
  }
  private returnGroupTypeText(groupType: BannerGroupType): string {
    // BannerGroupType의 한국어 문구 나타나기
    let text = String(this.$t('BANNER.BANNER.LOGO'));
    if (groupType === BANNER_GROUP_TYPE.SLIDE) {
      text = String(this.$t('BANNER.BANNER.SLIDE'));
    } else if (groupType === BANNER_GROUP_TYPE.NORMAL) {
      text = String(this.$t('BANNER.BANNER.NORMAL'));
    }
    return text;
  }
  private returnBannerGroupType(): BannerGroupType {
    // 선택한 bannerGroupNo 는 어느 BannerGroupType인지 판단하기
    let type: BannerGroupType = BANNER_GROUP_TYPE.LOGO;
    if (this.groupNoData) {
      this.groupNoData.forEach(e => {
        if (this.query.bannerGroupNo === e.bannerGroupNo) {
          type = e.bannerGroupType;
        }
      });
    }
    return type;
  }
  private styleReturn(wrap?: boolean): string {
    // wrap = true .banner-preview-wrap 영역 가리킨다
    if (!this.sizeCheckBox) {
      // resizing
      if (this.query.sizeUnitType === SIZE_TYPE.PIXEL) {
        const width = this.query.width;
        const height = this.query.height;
        if (wrap) {
          if (Number(width) > 1425) {
            // min 1194 max 1425
            return `width:100%;height:${height}px;`;
          } else {
            return `width:${width}px;height:${height}px;`;
          }
        } else {
          return `width:${width}px;height:${height}px;`;
        }
      } else if (this.query.sizeUnitType === SIZE_TYPE.PERCENT) {
        if (wrap) {
          if (Number(this.query.width) > 100) {
            return `width:100%;height:auto;`;
          } else {
            return `width:${this.query.width}%;height:auto;`;
          }
        } else {
          if (Number(this.query.width) > 100) {
            return `width:${this.query.width}%;height:auto;`;
          } else {
            return `width:100%;height:auto;`;
          }
        }
      }
    } else {
      // 등록한 이미지 사이즈대로 노출
      if (wrap) {
        if (String(this.returnBannerGroupType()) === BANNER_GROUP_TYPE.SLIDE) {
          let maxWidth = 0;
          let maxHeight = 0;
          this.query.bannerImages.forEach(e => {
            if (e.originWidth > maxWidth) {
              maxWidth = e.originWidth;
            }
            if (e.originHeight > maxHeight) {
              maxHeight = e.originHeight;
            }
          });
          if (maxWidth > 1420) {
            return `width: 100%; height:${maxHeight}px;`;
          } else {
            return `width: ${maxWidth}px; height:${maxHeight}px;`;
          }
        } else {
          if (this.query.originWidth > 1420) {
            return `width: 100%; height:auto;`;
          } else {
            return `width: ${this.query.originWidth}px; height:auto;`;
          }
        }
      } else {
        return 'width:auto;height:auto;';
      }
    }
  }
  private returnNullBanner() {
    return {
      visible: true,
      landingUrl: '',
      inactiveNavigationImageUrl: '',
      displayValue: {
        startDateTimeYmd: getToday(),
        startDateTimeHms: DEFAULT_TIME_RANGE.START,
        endDateTimeYmd: getToday(),
        endDateTimeHms: DEFAULT_TIME_RANGE.END,
        displayPeriodType: DISPLAY_PERIOD_TYPE.REGULAR,
      },
      imageUrl: '',
      description: '',
      activeNavigationImageUrl: '',
      openLocationType: LINK_TARGET.SELF,
      check: false,
      bannerImageName: '',
      activeNaviImageName: '',
      inactiveNaviImageName: '',
      originWidth: 600,
      originHeight: 166,
    };
  }
  private addBannerImages(): void {
    this.query.bannerImages.push(this.returnNullBanner());
    this.allCheck = false;
  }
  private checkCount(): number {
    let trueCount = 0;
    this.query.bannerImages.forEach(e => {
      if (e && e.check) {
        trueCount++;
      }
    });
    return trueCount;
  }
  private zeroTip(): void {
    const trueCount = this.checkCount();
    if (trueCount === 0) {
      alert(this.$t('BANNER.BANNER.NO_SELECT_IMAGE'));
      return;
    }
  }
  private removeBannerImages(): void {
    this.zeroTip();
    const newArray = [];
    this.query.bannerImages.forEach(e => {
      if (!e.check) {
        newArray.push(e);
      }
    });
    this.query.bannerImages = newArray;
    if (this.query.bannerImages.length === 0) {
      this.query.bannerImages = [
        {
          visible: true,
          landingUrl: '',
          inactiveNavigationImageUrl: '',
          displayValue: {
            startDateTimeYmd: getToday(),
            startDateTimeHms: DEFAULT_TIME_RANGE.START,
            endDateTimeYmd: getToday(),
            endDateTimeHms: DEFAULT_TIME_RANGE.END,
            displayPeriodType: DISPLAY_PERIOD_TYPE.REGULAR,
          },
          imageUrl: '',
          description: '',
          activeNavigationImageUrl: '',
          openLocationType: LINK_TARGET.SELF,
          check: false,
          bannerImageName: '',
          activeNaviImageName: '',
          inactiveNaviImageName: '',
          originWidth: 600,
          originHeight: 166,
        },
      ];
      this.allCheck = false;
    }
  }
  private orderControl(type: string): void {
    this.zeroTip();
    let front = [];
    let behind = [];
    let result = [];
    this.query.bannerImages.forEach(e => {
      if (e.check) {
        front = [...front, e];
      } else {
        behind = [...behind, e];
      }
    });
    if (type === 'top') {
      result = [...front, ...behind];
      this.query.bannerImages = result;
    } else if (type === 'bottom') {
      result = [...behind, ...front];
      this.query.bannerImages = result;
    } else if (type === 'up') {
      let index = 0;
      this.query.bannerImages.forEach((e, i) => {
        if (e.check) {
          if (i !== 0) {
            index = i;
            const prev = this.query.bannerImages[index - 1];
            const now = this.query.bannerImages[index];
            this.query.bannerImages.splice(index - 1, 1, now);
            this.query.bannerImages.splice(index, 1, prev);
          }
        }
      });
    } else if (type === 'down') {
      let index = 0;
      const array = this.query.bannerImages;
      for (let i = array.length - 1; i > -1; i--) {
        if (array[i].check) {
          if (i !== array.length - 1) {
            index = i;
            const next = this.query.bannerImages[index + 1];
            const now = this.query.bannerImages[index];
            this.query.bannerImages.splice(index + 1, 1, now);
            this.query.bannerImages.splice(index, 1, next);
          }
        }
      }
    }
  }
  private handleAllCheck(): void {
    this.query.bannerImages.forEach(e => {
      if (this.allCheck) {
        e.check = true;
      } else {
        e.check = false;
      }
    });
  }
  private handleCheck(): void {
    const bannerLength = this.query.bannerImages.length;
    const count = this.checkCount();
    if (count === bannerLength) {
      this.allCheck = true;
    } else {
      this.allCheck = false;
    }
  }
  // 단개 이미지
  private addImageSingle({ target }: Event): void {
    const imageFile = Array.from(this.imageUploadSingle.files as FileList)[0];
    if (imageFile) {
      this.upload(imageFile, data => {
        // eslint-disable-line
        this.query.imageUrl = data.url;
        this.query.bannerFileName = imageFile.name;
        this.query.originWidth = data.property.width;
        this.query.originHeight = data.property.height;
      });
    }
    (target as HTMLInputElement).value = '';
  }
  private upload(file: File, callback: (data: PostImagesResponse) => void): void {
    // eslint-disable-line
    const FILE_EXT_REG = /\.(jpg|jpeg|png|gif|bmp)$/;
    if (!file.name.toLowerCase().match(FILE_EXT_REG)) {
      alert(this.$t('BANNER.BANNER.NOT_ALLOW'));
      return;
    }
    const MAX_SIZE = 10 * 1024 * 1024;
    const fileSize = file.size;
    if (MAX_SIZE < fileSize) {
      alert(this.$t('BANNER.BANNER.MAX_10'));
      return;
    }
    const data = new FormData();
    data.append('file', file);
    this.$api.postImages({ data, params: { mallNo: Number(this.query.mallNo) } }).then(res => {
      callback(res.data);
    });
  }
  private removeSingleImageFile(): void {
    this.query.imageUrl = '';
    this.query.bannerFileName = '';
    this.query.originWidth = 600;
    this.query.originHeight = 166;
  }
  private removeImage(index: number): void {
    this.query.bannerImages[index].bannerImageName = '';
    this.query.bannerImages[index].imageUrl = '';
    this.query.bannerImages[index].originWidth = 600;
    this.query.bannerImages[index].originHeight = 166;
  }

  private addImage(index: number): void {
    const imageFile = Array.from(this.bannerFile[index].files as FileList)[0];
    if (imageFile) {
      this.upload(imageFile, data => {
        // eslint-disable-line
        this.query.bannerImages[index].imageUrl = data.url;
        this.query.bannerImages[index].bannerImageName = imageFile.name;
        this.query.bannerImages[index].originWidth = data.property.width;
        this.query.bannerImages[index].originHeight = data.property.height;
      });
    }
  }

  private addActiveImage(index: number): void {
    const imageFile = Array.from(this.activeImage[index].files as FileList)[0];
    if (imageFile) {
      this.upload(imageFile, data => {
        // eslint-disable-line
        this.query.bannerImages[index].activeNavigationImageUrl = data.url;
        this.query.bannerImages[index].activeNaviImageName = imageFile.name;
        const id = $('.upload-init').find('button')[index].id;
        this.appendToNaviBtn(true, id, data.url);
      });
    }
  }

  private addInactiveImage(index: number): void {
    const imageFile = Array.from(this.inactiveImage[index].files as FileList)[0];
    if (imageFile) {
      this.upload(imageFile, data => {
        // eslint-disable-line
        this.query.bannerImages[index].inactiveNavigationImageUrl = data.url;
        this.query.bannerImages[index].inactiveNaviImageName = imageFile.name;
        const id = $('.upload-init').find('button')[index].id;
        this.appendToNaviBtn(false, id, data.url);
      });
    }
  }
  private allUse(): void {
    this.zeroTip();
    this.query.bannerImages.forEach((e, index) => {
      if (e.check) {
        e.visible = this.visibleAll;
        e.displayValue.displayPeriodType = this.allDisplayPeriod;
        if (this.allDisplayPeriod === DISPLAY_PERIOD_TYPE.PERIOD) {
          this.query.bannerImages[index].displayValue.endDateTimeYmd = this.endDateTimeAllYmd;
          this.query.bannerImages[index].displayValue.endDateTimeHms = this.endDateTimeAllHms;
          this.query.bannerImages[index].displayValue.startDateTimeYmd = this.startDateTimeAllYmd;
          this.query.bannerImages[index].displayValue.startDateTimeHms = this.startDateTimeAllHms;
        }
      }
    });
  }
  // slick css modify start
  private usableSlideButtonYnChange(): void {
    if (this.query.usableSlideButton) {
      this.slickOptions.arrows = true;
    } else {
      this.slickOptions.arrows = false;
    }
    this.slick.reSlick();
    this.setButtonImage();
  }
  private bannerSlideSpeedChange(): void {
    // 전환 속도 선택
    const speed = this.query.slideSpeedType;
    let change = 0;
    if (speed === SLIDE_SPEED.FAST) {
      change = SLIDE_SPEED_NUM.FAST;
    } else if (speed === SLIDE_SPEED.NORMAL) {
      change = SLIDE_SPEED_NUM.NORMAL;
    } else if (speed === SLIDE_SPEED.SLOW) {
      change = SLIDE_SPEED_NUM.SLOW;
    }
    this.slickOptions.speed = change;
    this.slick.reSlick();
    this.setButtonImage();
  }
  private setButtonImage(): void {
    if (this.query.slideNavigationType === SLIDE_NAVI_TYPE.UPLOAD) {
      const $id = $el(document.querySelector('.upload-init')).getElement();
      this.query.bannerImages.forEach((e, i) => {
        const id = this.findByTag($id, 'BUTTON')[i].id;
        if (e.activeNavigationImageUrl) {
          this.appendToNaviBtn(true, id, e.activeNavigationImageUrl);
        }
        if (e.inactiveNavigationImageUrl) {
          this.appendToNaviBtn(false, id, e.inactiveNavigationImageUrl);
        }
      });
    }
  }
  private findByTag($container: Element, selector: string) {
    const $$elements = [];
    $container.childNodes.forEach(e => {
      if (e.childNodes) {
        e.childNodes.forEach(ele => {
          if (ele.nodeName === selector) {
            $$elements.push(ele);
          }
        });
      } else {
        if (e.nodeName === selector) {
          $$elements.push(e);
        }
      }
    });
    return $$elements;
  }
  private appendToNaviBtn(type: boolean, id: string, url: string): JQuery<HTMLElement> {
    if (type) {
      // 활성 버튼 activeNavigationImageUrl
      return $(
        `<style type="text/css">.banner-preview-wrap .upload-init.slick-dots li.slick-active button#${id}{
            background: url(${url}) no-repeat !important;
            width: auto !important;
            height: auto !important;
            font-size:0 !important;
            }</style>`,
      ).appendTo('body');
    } else {
      // 비활성 버튼 inactiveNavigationImageUrl
      return $(
        `<style type="text/css">.banner-preview-wrap .upload-init.init-slick-dot button#${id}{
            background: url(${url}) no-repeat !important;
            width: auto !important;
            height: auto !important;
            font-size:0 !important;
            }</style>`,
      ).appendTo('body');
    }
  }
  private bannerSlideTypeChange(): void {
    if (this.query.slideEffectType === SLIDE_TYPE.FADE) {
      this.slickOptions.fade = true;
    } else {
      this.slickOptions.fade = false;
    }
    this.slick.reSlick();
    this.setButtonImage();
  }
  private slideTimeChange(): void {
    // 전환 시간 설정
    if (this.query.slideTime === 0) {
      this.slickOptions.autoplay = false;
    } else {
      this.slickOptions.autoplay = true;
      this.slickOptions.autoplaySpeed = this.query.slideTime * 1000;
    }
    this.slick.reSlick();
    this.setButtonImage();
  }
  private slideNaviTypeChange(): void {
    const type = this.query.slideNavigationType;
    if (type === SLIDE_NAVI_TYPE.VISIBLE) {
      this.slickOptions.dots = true;
      this.slickOptions.dotsClass = `init-slick-dot slick-dots ${this.naviNowBorder()} ${this.naviNowSize()}`;
      this.onChangeColor(this.query.activeButtonColor, this.query, 'activeButtonColor');
      this.onChangeColor(this.query.inactiveButtonColor, this.query, 'inactiveButtonColor');
    } else if (type === SLIDE_NAVI_TYPE.INVISIBLE) {
      this.slickOptions.dots = false;
    } else if (type === SLIDE_NAVI_TYPE.UPLOAD) {
      this.slickOptions.dots = true;
      this.slickOptions.dotsClass = 'init-slick-dot slick-dots upload-init';
    }
    this.slick.reSlick();
    this.setButtonImage();
  }
  private onChangeColor(colorHexCode: string, query, keyName: string): void {
    query[keyName] = colorHexCode;
    if (keyName === 'slideButtonColor') {
      this.appenToBodyBackColor('.slick-next:before', colorHexCode);
      this.appenToBodyBackColor('.slick-next:after', colorHexCode);
      this.appenToBodyBackColor('.slick-prev:before', colorHexCode);
      this.appenToBodyBackColor('.slick-prev:after', colorHexCode);
    }
    if (keyName === 'activeButtonColor') {
      this.appenToBodyBackColor('.slick-dots li.slick-active button', colorHexCode);
    }
    if (keyName === 'inactiveButtonColor') {
      this.appenToBodyBackColor('.slick-dots li button', colorHexCode);
    }
  }
  private appenToBodyBackColor(className: string, backColor: string): void {
    $(
      `<style type="text/css">.banner-preview-wrap ${className}{background-color: ${backColor} !important}</style>`,
    ).appendTo('body');
  }
  private naviNowSize(): string {
    if (this.query.buttonSizeType === BUTTON_SIZE_TYPE.SIZE_ONE) {
      return 'slick-dots-8';
    } else if (this.query.buttonSizeType === BUTTON_SIZE_TYPE.SIZE_TWO) {
      return 'slick-dots-10';
    } else if (this.query.buttonSizeType === BUTTON_SIZE_TYPE.SIZE_THREE) {
      return 'slick-dots-12';
    } else if (this.query.buttonSizeType === BUTTON_SIZE_TYPE.SIZE_FOUR) {
      return 'slick-dots-16';
    } else if (this.query.buttonSizeType === BUTTON_SIZE_TYPE.SIZE_FIVE) {
      return 'slick-dots-20';
    } else if (this.query.buttonSizeType === BUTTON_SIZE_TYPE.SIZE_SIX) {
      return 'slick-dots-26';
    }
  }
  private naviNowBorder(): string {
    if (this.query.buttonBorderType === BUTTON_BORDER_TYPE.CIRCLE) {
      return 'border-100';
    } else if (this.query.buttonBorderType === BUTTON_BORDER_TYPE.ROUND_SQUARE) {
      return 'border-20';
    } else if (this.query.buttonBorderType === BUTTON_BORDER_TYPE.SQUARE) {
      return 'border-0';
    }
  }
  private borderChange(): void {
    this.slickOptions.dotsClass = `init-slick-dot slick-dots ${this.naviNowBorder()} ${this.naviNowSize()}`;
    this.slick.reSlick();
  }
  private sizeChange(): void {
    this.slickOptions.dotsClass = `init-slick-dot slick-dots ${this.naviNowBorder()} ${this.naviNowSize()}`;
    this.slick.reSlick();
  }
  // slick css modify end
  private checkForm(): boolean {
    if (!this.query.mallNo) {
      alert(this.$t('BANNER.BANNER.PLEASE_SELECT_MALL'));
      this.mallNo.focus();
      return false;
    }
    if (!this.query.skinNo) {
      alert(this.$t('BANNER.BANNER.PLEASE_SELECT_SKIN'));
      this.bannerSkinNo.focus();
      return false;
    }
    if (!this.query.bannerGroupNo) {
      alert(this.$t('BANNER.BANNER.PLEASE_SELECT_BANNER_GROUP'));
      this.bannerGroupNo.focus();
      return false;
    }
    if (!this.query.bannerTitle.trim()) {
      alert(this.$t('BANNER.BANNER.PLEASE_BANNER_TITLE'));
      this.bannerTitle.focus();
      return false;
    }
    if (!this.sizeCheckBox) {
      if (this.query.sizeUnitType === SIZE_TYPE.PERCENT) {
        if (!this.query.width) {
          alert(this.$t('BANNER.BANNER.PLEASE_BANNER_SIZE'));
          this.bannerWidth.focus();
          return false;
        }
      } else if (this.query.sizeUnitType === SIZE_TYPE.PIXEL) {
        if (!this.query.width || !this.query.height) {
          alert(this.$t('BANNER.BANNER.PLEASE_BANNER_SIZE'));
          if (!this.query.width) {
            this.bannerWidth.focus();
          } else {
            this.bannerHeight.focus();
          }
          return false;
        }
      }
    }
    if (this.isBannerImagesNull()) {
      alert(this.$t('BANNER.BANNER.PLEASE_BANNER_IMAGE'));
      return false;
    }
    return true;
  }
  private isBannerImagesNull(): boolean {
    let flg = false;
    if (String(this.returnBannerGroupType()) !== BANNER_GROUP_TYPE.SLIDE) {
      // other
      flg = !this.query.imageUrl;
    } else {
      // SLIDE
      this.query.bannerImages.forEach(e => {
        if (!e.imageUrl) {
          flg = true;
        }
      });
    }
    return flg;
  }
  private returnDateCombo(ymd: string, hms: string): string {
    return ymd + ' ' + hms;
  }
  private save(): void {
    if (!this.checkForm()) {
      return;
    }
    const startDateTime = this.returnDateCombo(this.query.startDateTimeYmd, this.query.startDateTimeHms);
    const endDateTime = this.returnDateCombo(this.query.endDateTimeYmd, this.query.endDateTimeHms);
    // 등록 mode

    // isSlide
    let isSlide = false;
    if (this.returnBannerGroupType() === BANNER_GROUP_TYPE.SLIDE) {
      isSlide = true;
    }

    // haveBannerHeight
    let haveBannerHeight = false;
    if (!this.sizeCheckBox && this.query.sizeUnitType === SIZE_TYPE.PIXEL) {
      haveBannerHeight = true;
    }
    // bannerImages
    const bannerImages = [];
    if (isSlide) {
      let haveActive = false;
      if (this.query.slideNavigationType === SLIDE_NAVI_TYPE.UPLOAD) {
        haveActive = true;
      }

      this.query.bannerImages.forEach((e, i) => {
        const startDateTime = this.returnDateCombo(e.displayValue.startDateTimeYmd, e.displayValue.startDateTimeHms);
        const endDateTime = this.returnDateCombo(e.displayValue.endDateTimeYmd, e.displayValue.endDateTimeHms);
        bannerImages.push({
          displayOrder: i,
          visible: e.visible,
          landingUrl: e.landingUrl,
          inactiveNavigationImageUrl: haveActive ? e.inactiveNavigationImageUrl : undefined,
          displayValue: {
            startDateTime: e.displayValue.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD ? startDateTime : undefined,
            endDateTime: e.displayValue.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD ? endDateTime : undefined,
            displayPeriodType: e.displayValue.displayPeriodType,
          },
          imageUrl: e.imageUrl,
          description: e.description,
          activeNavigationImageUrl: haveActive ? e.activeNavigationImageUrl : undefined,
          openLocationType: e.openLocationType,
        });
      });
    } else {
      bannerImages.push({
        imageUrl: this.query.imageUrl,
        landingUrl: this.query.landingUrl,
        description: this.query.description,
        openLocationType: this.query.openLocationType,
        displayValue: {
          startDateTime: this.query.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD ? startDateTime : undefined,
          endDateTime: this.query.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD ? endDateTime : undefined,
          displayPeriodType: this.query.displayPeriodType,
        },
        visible: this.query.visible,
      });
    }
    let haveSlideBtnColor = false;
    if (isSlide && this.query.usableSlideButton) {
      haveSlideBtnColor = true;
    }
    let isSlideNaviY = false;
    if (isSlide && this.query.slideNavigationType === SLIDE_NAVI_TYPE.VISIBLE) {
      isSlideNaviY = true;
    }

    const request: any = {
      // eslint-disable-line
      data: {
        // bannerNo: Number(this.$route.query.bannerNo),
        platformType: this.query.platformType,
        skinNo: Number(this.query.skinNo),
        resizable: !this.sizeCheckBox,
        visible: this.query.visible,
        bannerGroupNo: Number(this.query.bannerGroupNo),
        bannerTitle: this.query.bannerTitle,
        displayValue: {
          startDateTime: this.query.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD ? startDateTime : undefined,
          endDateTime: this.query.displayPeriodType === DISPLAY_PERIOD_TYPE.PERIOD ? endDateTime : undefined,
          displayPeriodType: this.query.displayPeriodType,
        },
        sizeUnitType: this.sizeCheckBox ? undefined : this.query.sizeUnitType,
        width: this.sizeCheckBox ? undefined : Number(this.query.width),
        height: haveBannerHeight ? Number(this.query.height) : undefined,
        mallNo: Number(this.query.mallNo),
        slideBannerConfig: !isSlide
          ? undefined
          : {
              slideSpeedType: this.query.slideSpeedType,
              slideTime: Number(this.query.slideTime),
              slideEffectType: this.query.slideEffectType,
              usableSlideButton: this.query.usableSlideButton,
              slideButtonColor: haveSlideBtnColor ? this.query.slideButtonColor : undefined,
              slideNavigationType: this.query.slideNavigationType,
              slideNavigationInfo: !isSlideNaviY
                ? undefined
                : {
                    inactiveButtonColor: this.query.inactiveButtonColor,
                    activeButtonColor: this.query.activeButtonColor,
                    buttonSizeType: this.query.buttonSizeType,
                    buttonBorderType: this.query.buttonBorderType,
                  },
            },
        bannerImages: bannerImages,
      },
    };
    if (this.mode === MODE.ADD) {
      this.$api.postSkinBanners(request as PostSkinBannersRequest).then((): void => {
        alert(this.$t('BANNER.BANNER.SAVE_SUCCESS'));
      });
    } else if (this.mode === MODE.MODIFY) {
      delete request.data.skinNo;
      delete request.data.platformType;
      request.data['bannerNo'] = Number(this.$route.query.bannerNo);
      this.$api.putSkinBanners(request as PutSkinBannersRequest).then((): void => {
        alert(this.$t('BANNER.BANNER.SAVE_SUCCESS'));
      });
    }
  }
}
