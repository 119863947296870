import { i18n } from '@/main';
import { ValueType } from '@/helpers/type';
import { ToolTipOption } from '@/types/tooltip';
import { getEncodeStr } from '@/utils/common';

export function i18nForSecurity(word: string, data?: ValueType): string {
  const SERVER = 'SECURITY.SERVER.';
  const value = data ? { value: i18n.t(`${SERVER}${data}`) } : null;
  return i18n.t(`${SERVER}${word}`, value).toString();
}

type SecurityServerToolTipType = 'sslStatus' | 'use' | 'sslSeal';
export type SecurityServerToolTipOption = { [key in SecurityServerToolTipType]: ToolTipOption };
export const getSecurityServerToolTipOption = (sno: number): SecurityServerToolTipOption => {
  const encodeGodoSno = getEncodeStr(sno);
  return {
    sslStatus: {
      name: 'List',
      style: {
        'text-align': 'left',
        width: '500px',
      },
      listOption: {
        mainMessage: [
          i18nForSecurity('SSL_STATUS_TOOL_TIP_1'),
          `${i18nForSecurity(
            'SSL_STATUS_TOOL_TIP_2',
          )} <a href="https://hosting.nhn.com/valueadd/ssl_service.php" target="_blank"><strong>${i18nForSecurity(
            'SSL_STATUS_TOOL_TIP_3',
          )}</strong></a> ${i18nForSecurity('SSL_STATUS_TOOL_TIP_4')}`,
          i18nForSecurity('SSL_STATUS_TOOL_TIP_5'),
          `${i18nForSecurity(
            'SSL_STATUS_TOOL_TIP_6',
          )} <a href="https://www.nhn-commerce.com/mygodo/my_godo_secure_list.php?sno=${encodeGodoSno}" target="_blank"><strong>${i18nForSecurity(
            'SSL_STATUS_TOOL_TIP_7',
          )}</strong></a>${i18nForSecurity('SSL_STATUS_TOOL_TIP_8')}`,
        ],
      },
    },
    use: {
      name: 'List',
      style: {
        'text-align': 'left',
        left: '-300px',
        width: '500px',
      },
      listOption: {
        mainMessage: [
          i18nForSecurity('SSL_USE_TOOL_TIP_1'),
          i18nForSecurity('SSL_USE_TOOL_TIP_2'),
          i18nForSecurity('SSL_USE_TOOL_TIP_3'),
        ],
      },
    },
    sslSeal: {
      name: 'List',
      style: {
        'text-align': 'left',
        left: '-500px',
        width: '500px',
      },
      listOption: {
        mainMessage: [
          i18nForSecurity('SSL_SEAL_TOOL_TIP_1'),
          i18nForSecurity('SSL_SEAL_TOOL_TIP_2'),
          i18nForSecurity('SSL_SEAL_TOOL_TIP_3'),
          i18nForSecurity('SSL_SEAL_TOOL_TIP_4'),
        ],
      },
    },
  };
};

export const getSecurityServerSelect = () => ({
  use: [
    { name: i18nForSecurity('USE'), value: true },
    { name: i18nForSecurity('NOT_USE'), value: false },
  ],
});
