export default Object.freeze({
  COMMENTS1: '회원/비회원의 개인정보 수집에 필요한 항목을 설정합니다.',
  COMMENTS2: '[필수]항목은 반드시 입력해야 하는 내용이며, [선택]항목은 사용여부를 설정할 수 있습니다.',
  COMMENTS3: '각 항목에 작성된 내용은 쇼핑몰 운영방침에 따라 수정해주세요.',
  SIGN_UP: '회원가입',
  REQUIRED_COLL_INFO: '[필수] 개인정보 수집/이용',
  CONTENTS: '내용',
  LOAD_BASIC_CONTENTS: '기본제공내용 불러오기',
  OPTIONAL_COLLECTION_USE_OF: '[선택] 개인정보 수집/이용',
  USED: '사용함',
  NOT_USED: '사용 안 함',
  OPTIONAL_PERSONAL_INFO: '[선택] 개인정보 처리/위탁',
  OPTIONAL_PROVISION_OF: '[선택] 개인정보 제3자 제공',
  COMMON_ORDER: '주문공통',
  REQUIRED_PERSONAL_SELLER: '[필수] 개인정보 판매자 제공',
  REQUIRED_AGREE_TO: '[필수] 주문 상품정보 동의',
  OPTIONAL_COLLECT_USE: '[선택] 통관정보 수집/이용',
  OPTIONAL_AGREE_TO: '[선택] 개인정보 국외이전 동의',
  NONMEMBERSPOSTS: '비회원 | 게시글/문의글',
  SAVE_CHANGES: '변경사항 저장',
  POPINFO1: '치환텍스트 목록',
  POPINFO2: '치환텍스트를 통해 내용을 입력할 수 있습니다.',
  POPINFO3: '치환 텍스트',
  POPINFO4: '설명',
  POPINFO5: '예시',
  POPINFO6: '파트너사명',
  POPINFO7: '1개 파트너사인 경우,<br> :(주)파트너사<br> 2개 이상 파트너사인 경우,<br> :(주)하나파트너, (주)둘파트너',
});
