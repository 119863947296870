



















































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  SEARCH_DATE_TYPE,
  SEARCH_TYPE,
  BANNER_GROUP_TYPE,
  getBannerSelectType,
  getBannerRadioGroup,
  DesignSkin,
  GroupNoData,
} from '@/const/contents/banner';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { Getter } from 'vuex-class';
import { BannerGroupType, BannerPlatformType, GetSkinBannersGroupsRequest, YorN, Mall } from 'ncp-api-supporter';

@Component({
  components: {
    SelectBox,
    SearchButton,
    DateRangePicker,
    RadioGroup,
    MallSelect,
  },
})
export default class Banner extends Vue {
  @Ref() datePeriod!: DateRangePicker;
  @Getter('mall/getMalls') private malls!: Mall[];
  private query = {
    mallNo: this.malls && this.malls[this.malls.length - 1].mallNo,
    searchType: SEARCH_TYPE.ALL,
    searchKeyword: '',
    startDate: '',
    endDate: '',
    searchDateType: SEARCH_DATE_TYPE.REGISTER,
    platformType: '',
    skinNo: '' as string | number,
    displayStatus: '' as string,
    bannerGroupNo: '' as string | number,
  };
  // SelectBox data
  private searchTypeData = getBannerSelectType().searchTypeData;
  private dateTypeData = getBannerSelectType().dateTypeData;
  private designSkinDefault = getBannerSelectType().designSkinDefault as DesignSkin[];
  private groupNoDataDefault = getBannerSelectType().groupNoDataDefault;

  // RadioGroup option
  private displayStatusOptions = getBannerRadioGroup().displayStatusOptions;
  private platformTypeOptions = getBannerRadioGroup().platformTypeAllOptions;

  private allDesignSkin: DesignSkin[] = [];
  private designSkin: DesignSkin[] = [];
  private groupNoData: GroupNoData[] = [];

  private onChangePlatformType(platformType: string) {
    this.query.skinNo = 0;
    if (!platformType) {
      this.designSkin = this.allDesignSkin;
      return;
    }
    const designSkin = this.allDesignSkin.filter(({ platform }) => platform === platformType);
    this.designSkin = [...this.designSkinDefault, ...designSkin];
  }

  @Watch('query.mallNo')
  mallChange() {
    this.query.platformType = '';
    if (this.query.mallNo) {
      // 몰번호 존재할때
      this.$api.getSkinsMallsMallNo({ pathParams: { mallNo: this.query.mallNo as number } }).then(res => {
        if (res.data && res.data.length !== 0) {
          this.designSkin = [];
          this.designSkin = [...this.designSkinDefault, ...res.data];
          this.allDesignSkin = [...this.designSkinDefault, ...res.data];
          this.designSkin.forEach((e, i) => {
            if (i !== 0) {
              // 첮번째 아닐때
              // 사용중 스킨 선택
              if (e.isLiveSkin && !e.isWorkSkin && e.platform === 'PC') {
                this.query.skinNo = e.skinNo;
              }
              // 스킨 이름 formatter
              const key = 'skinNameFormatter';
              const formatter = this.skinNameFormatter(e.isLiveSkin, e.isWorkSkin);
              const platform = e.platform === 'PC' ? 'PC' : this.$t('DISPLAY.DESIGN_POPUP.PLATFORM_TYPES_MOBILE');
              e[key] = `[${platform}] ${e.skinName} (${e.skinCode})${formatter}`;
            }
          });
          // skin 한개일때
          if (this.designSkin.length === 2) {
            this.query.skinNo = this.designSkin[1].skinNo;
          }
        } else if (res.data && res.data.length === 0) {
          // 디자인 스킨 존재하지 않을때
          this.designSkin = [...this.designSkinDefault];
          this.allDesignSkin = [...this.designSkinDefault];
          this.query.skinNo = 0;
        }
        if (this.groupNoData.length === 2) {
          this.query.bannerGroupNo = this.groupNoData[1].bannerGroupNo;
        } else {
          this.query.bannerGroupNo = 0;
        }
      });
    } else {
      // 몰번호 존재하지 않을때
      this.query.skinNo = 0;
    }
  }
  @Watch('query.skinNo')
  skinNoChange() {
    if (this.query.skinNo) {
      const request: GetSkinBannersGroupsRequest = {
        params: {
          mallNo: Number(this.query.mallNo),
          skinNo: Number(this.query.skinNo),
        },
      };
      this.$api.getSkinBannersGroups(request).then(res => {
        if (res.data) {
          this.groupNoData = [];
          this.groupNoData = [...this.groupNoDataDefault, ...res.data];
          this.groupNoData.forEach((e, i) => {
            if (i !== 0) {
              const groupType = this.returnGroupType(e.bannerGroupType);
              e.groupName = `${groupType} - ${e.bannerGroupName}`;
            }
          });
          this.query.bannerGroupNo = 0;
          // 그룹이 한개일때
          if (this.groupNoData.length === 2) {
            this.query.bannerGroupNo = this.groupNoData[1].bannerGroupNo;
          }
        }
      });
    } else {
      this.query.bannerGroupNo = 0;
      if (this.groupNoData.length === 2) {
        this.query.bannerGroupNo = this.groupNoData[1].bannerGroupNo;
      }
    }
  }
  private created() {
    this.reset();
  }
  private get getSkinBannersRequest() {
    return {
      params: {
        mallNo: Number(this.query.mallNo),
        searchType: this.query.searchType,
        searchKeyword: this.query.searchKeyword,
        startDate: this.query.startDate,
        endDate: this.query.endDate,
        searchDateType: this.query.searchDateType,
        platformType: this.query.platformType as BannerPlatformType,
        skinNo: Number(this.query.skinNo),
        displayStatus: this.query.displayStatus as YorN,
        bannerGroupNo: Number(this.query.bannerGroupNo),
      },
    };
  }

  private reset(): void {
    this.query = {
      mallNo: this.malls && this.malls[this.malls.length - 1].mallNo,
      searchType: SEARCH_TYPE.ALL,
      searchKeyword: '',
      startDate: '',
      endDate: '',
      searchDateType: SEARCH_DATE_TYPE.REGISTER,
      platformType: '',
      skinNo: 0,
      displayStatus: '' as string,
      bannerGroupNo: '' as string | number,
    };
  }

  private skinNameFormatter(isLiveSkin: boolean, isWorkSkin: boolean): string {
    let text = '';
    if (isLiveSkin && isWorkSkin) {
      text = String(this.$t('BANNER.BANNER.USING_OR_WORKING'));
    } else if (isLiveSkin && !isWorkSkin) {
      text = String(this.$t('BANNER.BANNER.USING'));
    } else if (!isLiveSkin && isWorkSkin) {
      text = String(this.$t('BANNER.BANNER.WORKING'));
    }
    return text;
  }

  private returnGroupType(groupType: BannerGroupType): string {
    let text = String(this.$t('BANNER.BANNER.LOGO'));
    if (groupType === BANNER_GROUP_TYPE.SLIDE) {
      text = String(this.$t('BANNER.BANNER.SLIDE'));
    } else if (groupType === BANNER_GROUP_TYPE.NORMAL) {
      text = String(this.$t('BANNER.BANNER.NORMAL'));
    }
    return text;
  }
}
