import { SalesInfoCalculatorInfo } from '@/types';
import {
  RoundingMode,
  SalesInfoCalculator,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import { DiscountPriceCalculator } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/DiscountPriceCalculator';
import { SupplyPriceCalculator } from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SupplyPriceCalculator';

export type SalesInfoCalculatorCreatorByRoundingStrategy = (discount: number) => number;

export function SalesInfoCalculatorCreatorByRoundingStrategy({
  discountInfo,
  commissionInfo,
}: SalesInfoCalculatorInfo): SalesInfoCalculator {
  let roundingStrategy: SalesInfoCalculatorCreatorByRoundingStrategy;
  const scaled = Math.pow(10, discountInfo.rule.scale);

  switch (discountInfo.rule.roundingMode) {
    case RoundingMode.DOWN:
      roundingStrategy = discount => Math.floor(discount * scaled) / scaled;
      break;
    case RoundingMode.UP:
      roundingStrategy = discount => Math.ceil(discount * scaled) / scaled;
      break;
    case RoundingMode.HALF_UP:
      roundingStrategy = discount => Math.round(discount * scaled) / scaled;
      break;
    default:
      throw new Error('INVALID_ROUNDING_MODE');
  }

  return new SalesInfoCalculator(
    new DiscountPriceCalculator(discountInfo, roundingStrategy),
    new SupplyPriceCalculator(commissionInfo),
  );
}
