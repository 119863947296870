





















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Mall, PlaceOrigin, OriginProductInfo, InspectionProductInfo, Item, Sticker } from 'ncp-api-supporter';
import { formatCurrency } from '@/utils/numberFormat';
import { throwWindowPopup } from '@/helpers/popup';
import { unescapeHtml } from '@/utils/common';
import { DutyInformation } from '@/types';
import { PRODUCT_OPTION_TYPE } from '@/views/popups/product/productAdd/ProductOption.vue';
import { UPSERT_TYPE } from '@/views/contents/product/basic/ProductAdd/salesInfo/details/DutyInfo.vue';
import {
  CommissionInfo,
  DutyContent,
  GetInspectionsView,
  ImmediateDiscountInfo,
  PlaceOriginInfo,
  SalePeriodInfo,
  UnitPriceInfo,
  Reason,
  MaxBuyCountInfo,
} from 'ncp-api-supporter/dist/types/modules/product/product';
import TextInput from '@/components/common/input/TextInput.vue';
import { APPLY_STATUS } from '@/const/common';
import { getCheckKeysBy } from '@/const/contents/product';
import Stickers from '@/components/product/common/Stickers.vue';
import { omit, omitBy } from 'lodash-es';

type CertificationType = 'NOT_TARGET' | 'TARGET' | 'DETAIL_PAGE';
type ValueAddedTaxType = 'DUTY' | 'DUTYFREE' | 'SMALL'; //과세 | 면세 | 영세
type ReservationInfo = {
  deliveryYmdt: string;
  stockCnt: number;
  onReservationSale: boolean;
  startYmdt: string;
  endYmdt: string;
};
type RoundingRule = {
  roundingMode: string;
  scale: number;
};
@Component({
  components: { TextInput, Stickers },
})
export default class SaleTable extends Vue {
  @Prop({ required: true }) private readonly inspectionView?: GetInspectionsView;
  @Prop({ required: true }) private readonly isApprovalReady?: boolean;
  @Prop({ required: true }) private readonly isAfterApprovalReady?: boolean;
  private readonly originProductInfo?: OriginProductInfo = this.inspectionView?.originProductInfo;
  private readonly inspectionProductInfo?: InspectionProductInfo = this.inspectionView?.inspectionProductInfo;
  private readonly modifiedParam: string[] = this.inspectionView?.modifiedParam || [];
  private readonly inspectionStatus: string = this.inspectionView?.inspectionStatus || '';

  @Getter('mall/getMalls') private readonly malls!: Mall[]; //쇼핑몰
  private roundingRule: RoundingRule = { roundingMode: '', scale: 0 };
  private placeOrigins: PlaceOrigin[] = []; //원산지

  private saleInfo = {
    reservationInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.RESERVATION_INFO'),
      ref: 'reservationInfo',
    },
    salePeriodInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.SALE_PERIOD_INFO'),
      ref: 'salePeriodInfo',
    },
    commissionInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.COMMISSION_INFO'),
      ref: 'commissionInfo',
    },
    salePrice: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.SALE_PRICE'), ref: 'salePrice' },
    contentsIfPausing: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.CONTENTS_IF_PAUSING'),
      ref: 'contentsIfPausing',
    },
    immediateDiscountInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.IMMEDIATE_DISCOUNT_INFO'),
      ref: 'immediateDiscountInfo',
    },
    accumulationRate: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.ACCUMULATION_RATE'),
      ref: 'accumulationRate',
    },
    accumulationUseYn: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.ACCUMULATION_USEYN'),
      ref: 'accumulationUseYn',
    },
    minBuyCnt: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.MIN_BUY_CNT'),
      ref: 'minBuyCnt',
    },
    maxBuyCountInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.MAX_BUY_COUNT_INFO'),
      ref: 'maxBuyCountInfo',
    },
    promotionInfo: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.PROMOTION_INFO'), ref: 'promotionInfo' },
    productStockCnt: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.PRODUCT_STOCK_CNT'),
      ref: 'productStockCnt',
    },
    optionUseYn: { check: false, reason: '', name: this.$t('PRODUCT.COMMON.OPTION_USE_YN'), ref: 'optionUseYn' },
    options: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.OPTIONS'),
      ref: 'options',
    },
    dutyContent: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.DUTY_CONTENT'),
      ref: 'dutyContent',
    },
    certificationInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.CERTIFICATION_INFO'),
      ref: 'certificationInfo',
    },
    placeOriginInfo: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.PLACE_ORIGIN_INFO'),
      ref: 'placeOriginInfo',
    },
    supplierProductName: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.SUPPLIER_PRODUCT_NAME'),
      ref: 'supplierProductName',
    },
    manufactureYmdt: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.MANUFACTURE_YMDT'),
      ref: 'manufactureYmdt',
    },
    expirationYmdt: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.EXPIRATION_YMDT'),
      ref: 'expirationYmdt',
    },
    productManagementCd: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.PRODUCT_MANAGEMENT_CD'),
      ref: 'productManagementCd',
    },
    hsCode: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.HS_CODE'),
      ref: 'hsCode',
    },
    valueAddedTaxType: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.VALUE_ADDED_TAX_TYPE'),
      ref: 'valueAddedTaxType',
    },
    iconAlwaysDisplay: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.ICON_ALWAYS_DISPLAY'),
      ref: 'iconAlwaysDisplay',
    },
    iconExposurePeriod: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.ICON_EXPOSURE_PERIOD'),
      ref: 'iconExposurePeriod',
    },
    iconPeriodDisplay: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.ICON_PERIOD_DISPLAY'),
      ref: 'iconPeriodDisplay',
    },
  };

  get isExposureSaleInfo() {
    return (
      this.modifiedParam.some(param => this.saleInfo?.hasOwnProperty(param)) ||
      this.inspectionStatus === APPLY_STATUS.APPROVAL_READY
    );
  }

  // 사용가능한 프로모션 목록
  private getApplicablePromotions(type: 'EXISTING' | 'AFTER') {
    const promotionsNameMap = {
      additionalDiscountYn: this.$t('PRODUCT.ADDITIONAL_DISCOUNT'),
      couponYn: this.$t('PRODUCT.COMMON.COUPON'),
      freeGiftYn: this.$t('PRODUCT.COMMON.FREE_GIFT'),
    };

    const productInfo =
      type === 'EXISTING' ? this.originProductInfo.promotionInfo : this.inspectionProductInfo.promotionInfo;

    const promotions = omit(productInfo, ['promotionYn']);

    const applicablePromotions = omitBy(promotions, value => value === 'N');

    return Object.keys(applicablePromotions)
      .map(key => promotionsNameMap[key])
      .join();
  }

  //노출항목
  private isExposureItem(modifiedItem: string): boolean {
    //승인대기
    if (this.inspectionStatus === APPLY_STATUS.APPROVAL_READY) return true;
    //수정후 승인대기
    if (this.inspectionStatus === APPLY_STATUS.AFTER_APPROVAL_READY) {
      return this.modifiedParam.includes(modifiedItem);
    }
    return false;
  }

  @Watch('saleInfo', { deep: true })
  private getReasons() {
    const checked = getCheckKeysBy(this.saleInfo);
    const reasons: Reason[] = [];
    const saleReasons: Item[] = checked.map((key: string) => ({
      item: this.saleInfo[key].name.toString(),
      detail: {
        data: (this.$refs[this.saleInfo[key].ref] as Element).outerHTML,
        comment: this.saleInfo[key].reason,
      },
    }));
    reasons.push({ section: 'SALE_INFO', items: saleReasons });
    this.$emit('reasons', reasons);
  }

  //예약판매
  private getReservationInfoText(reservationInfo: ReservationInfo): string {
    if (reservationInfo) {
      const reservationPeriod = `${this.$t('PRODUCT.COMMON.RESERVATION_PERIOD')}:${reservationInfo.startYmdt} ~ ${
        reservationInfo.endYmdt
      }`;
      const reservationDeliveryStartymd = `${this.$t('PRODUCT.COMMON.RESERVATION_DELIVERY_STARTYMD')}:${
        reservationInfo.deliveryYmdt
      }`;
      const reservationStock = `${reservationInfo.stockCnt} ${this.$t('EACH')}`;
      return `${this.$t('SETTING')}, ${reservationPeriod}, ${reservationDeliveryStartymd}, ${reservationStock}`;
    } else {
      return this.$t('PRODUCT.COMMON.NOT_SET') as string;
    }
  }

  //판매기간
  private getSalePeriodInfo(salePeriodInfo: SalePeriodInfo): string {
    const periodType = this.$t(`PRODUCT.COMMON.SALE_PERIOD_INFO_${salePeriodInfo.periodType}`) as string;
    return `${periodType}, ${salePeriodInfo.startYmdt} ~ ${salePeriodInfo.endYmdt}`;
  }

  //판매수수료
  private getCommissionInfo(commissionInfo: CommissionInfo): string {
    const commisionType = this.$t(`PRODUCT.COMMON.COMMISSION_INFO_${commissionInfo.type}`);
    return commissionInfo.type ? `${commisionType}, ${commissionInfo.rate.toFixed(1)}%` : '';
  }

  //판매가
  private getSalePriceText(salePrice: number, unitPriceInfo: UnitPriceInfo): string {
    if (unitPriceInfo && unitPriceInfo.price) {
      return `${unitPriceInfo.name} ${unitPriceInfo.type} ${unitPriceInfo.price}${this.$t('WON')}`;
    } else {
      return `${formatCurrency(salePrice)}${this.$t('WON')}`;
    }
  }

  //즉시할인
  private getImmediateDiscountInfo(immediateDiscountInfo: ImmediateDiscountInfo): string {
    let text = formatCurrency(immediateDiscountInfo.amount);
    if (immediateDiscountInfo.unitType === 'AMOUNT') {
      text = text + this.$t('WON');
    } else {
      text = text + '%';
    }
    if (immediateDiscountInfo.periodYn === 'Y') {
      const period = ` | ${this.$t('PRODUCT.COMMON.DUE_SET')} ${immediateDiscountInfo.startYmdt} ~ ${
        immediateDiscountInfo.endYmdt
      }`;
      text = text + period;
    }
    return text;
  }

  //즉시할인가
  private getDiscountPriceText(immediateDiscountInfo: ImmediateDiscountInfo, salePrice: number): string {
    return `${formatCurrency(this.getDiscountPrice(immediateDiscountInfo, salePrice))}${this.$t('WON')} `;
  }

  private getDiscountPrice(immediateDiscountInfo: ImmediateDiscountInfo, salePrice: number): number {
    const isWon = immediateDiscountInfo.unitType === 'AMOUNT';
    let discountAmt = 0;
    const discountRateAmt = (salePrice * immediateDiscountInfo.amount) / 100;

    if (isWon) {
      discountAmt = immediateDiscountInfo.amount;
      return salePrice - discountAmt;
    }
    if (this.roundingRule && discountRateAmt) {
      const { roundingMode, scale } = this.roundingRule;
      const scaled = Math.pow(10, scale);
      const scaledDiscountRateAmt = discountRateAmt * scaled;
      if (roundingMode === 'DOWN') {
        discountAmt = Math.floor(scaledDiscountRateAmt) / scaled;
      } else if (roundingMode === 'UP') {
        discountAmt = Math.ceil(scaledDiscountRateAmt) / scaled;
      } else {
        // HALF_UP
        discountAmt = Math.round(scaledDiscountRateAmt) / scaled;
      }
    }
    return salePrice - discountAmt;
  }

  //공급가
  private getPurchasePrice(productInfo: OriginProductInfo): string {
    const { saleMethodType, purchasePrice, immediateDiscountInfo, salePrice, commissionInfo } = productInfo;
    if (
      saleMethodType === 'PURCHASE' ||
      (saleMethodType === 'CONSIGNMENT' && commissionInfo.type === 'PURCHASE_PRICE')
    ) {
      //사입 또는 ( 위탁 & 판매수수료:공급가 ) 인 경우
      return formatCurrency(purchasePrice) + this.$t('WON');
    } else {
      const discountPrice = this.getDiscountPrice(immediateDiscountInfo, salePrice);
      const calcComissionPrice = this.getCalcComissionPrice(discountPrice, commissionInfo.rate);
      return commissionInfo.rate
        ? `${formatCurrency(calcComissionPrice)}${this.$t('WON')}`
        : `${formatCurrency(discountPrice)}${this.$t('WON')}`;
    }
  }

  private getCalcComissionPrice(discountPrice: number, rate: number) {
    const fees = (discountPrice * rate) / 100;
    return discountPrice - Math.round(fees * 10) / 10;
  }

  //최대구매수량
  private getMaxBuyCountInfo(maxBuyCountInfo: MaxBuyCountInfo): string {
    if (maxBuyCountInfo.maxBuyPersonCnt) {
      return `${this.$t('PRODUCT.ADD.USE_CONFIG2')}, ${this.$t('PRODUCT.ADD.MAXIMUM_FOR_ONE_PERSON_PURCHASE')} ${
        maxBuyCountInfo.maxBuyPersonCnt
      }${this.$t('PRODUCT.ADD.STOCK_CNT_UNIT')}${this.$t('PRODUCT.ADD.TXT_CAUTION_POSSIBILITY')}`;
    }
    if (maxBuyCountInfo.maxBuyTimeCnt) {
      return `${this.$t('PRODUCT.ADD.USE_CONFIG2')}, ${this.$t('PRODUCT.ADD.MAXIMUM_ON_A_SINGLE_PURCHASE')} ${
        maxBuyCountInfo.maxBuyTimeCnt
      }${this.$t('PRODUCT.ADD.STOCK_CNT_UNIT')}${this.$t('PRODUCT.ADD.TXT_CAUTION_POSSIBILITY')}`;
    }
    if (maxBuyCountInfo.maxBuyDays) {
      return `${this.$t('PRODUCT.ADD.USE_CONFIG2')}, ${maxBuyCountInfo.maxBuyDays}${this.$t(
        'PRODUCT.COMMON.MAX_BUY_COUNT_INFO_CNT',
      )} ${maxBuyCountInfo.maxBuyPeriodCnt}${this.$t('PRODUCT.ADD.STOCK_CNT_UNIT')}${this.$t(
        'PRODUCT.ADD.TXT_CAUTION_POSSIBILITY',
      )}`;
    }
    return `${this.$t('NOT_SET')}`;
  }

  //옵션
  private async openOptionPopup(productInfo: OriginProductInfo): Promise<void> {
    const {
      saleMethodType,
      mallNo,
      mallProductNo,
      deliveryTemplateNo,
      reservationInfo,
      purchasePrice,
      options,
    } = productInfo;
    const { data: product } = await this.$api.getProductsMallProductNo({
      pathParams: { mallProductNo: mallProductNo.toString() },
    });
    const { optionSelectType } = product;
    throwWindowPopup(
      'ProductOption',
      {
        type: PRODUCT_OPTION_TYPE.MODIFICATION,
        mallNo,
        mallProductNo,
        deliveryTemplateNo,
        saleMethodType,
        useReservation: reservationInfo,
        purchasePrice: purchasePrice,
        savedOption: {
          options: options.map((option: { optionName: string }) => ({
            ...option,
            optionName: unescapeHtml(option.optionName),
          })),
          optionSelectType,
        },
        isReadonly: true,
      },
      'xlg',
    );
  }

  //상품정보고시
  private async openDutyContentPopup(dutyContent: DutyContent): Promise<void> {
    const { data: dutyCategories } = await this.$api.getDutyCategories();
    const dutyInfo: DutyInformation = {
      saveTitle: '',
      useYn: 'Y',
      dutyContent,
    };
    throwWindowPopup('UpsertDutyInfo', { type: UPSERT_TYPE.insert, dutyCategories, dutyInfo, isReadonly: true }, 'md');
  }

  //인증정보
  private openCertificationInfoPopup(productInfo: OriginProductInfo): void {
    throwWindowPopup(
      'UpsertCertificationInfo',
      {
        categoryNo: productInfo.categoryNo,
        certifications: productInfo.certificationInfo.data,
        isReadonly: true,
      },
      'lg',
      () => null,
      '',
      undefined,
      undefined,
      820,
    );
  }

  //인증정보
  private getCertificationInfo(type: CertificationType): string {
    if (type) return this.$t(`PRODUCT.COMMON.CERTIFICATION_INFO_${type}`) as string;
    else return '';
  }

  //원산지
  private getPlaceOriginInfo(placeOriginInfo: PlaceOriginInfo): string {
    const place = this.placeOrigins.find(placeOrigin => placeOrigin.originNo === placeOriginInfo?.placeOriginSeq);
    if (!place) return '';

    let depth2 = '';
    if (place.originCountyCode === 'KR') {
      depth2 = place.depth2;
    } else if (place.originCountyCode === 'EX') {
      depth2 = placeOriginInfo.placeOrigin || '';
    } else {
      depth2 = `${place.depth2} | ${placeOriginInfo.placeOrigin}`;
    }

    return `${place.originCountry} > ${place.depth1} > ${depth2}`;
  }

  //부가세
  private getValueAddedTaxType(valueAddedTaxType: ValueAddedTaxType): string {
    return valueAddedTaxType ? (this.$t(`PRODUCT.COMMON.VALUE_ADDED_TAX_TYPE_${valueAddedTaxType}`) as string) : '';
  }

  //아이콘 - 상시노출
  private getAlwaysSticker(stickers: Sticker[]) {
    return stickers.filter(sticker => !sticker.displayStartedAt && !sticker.displayEndedAt);
  }

  //아이콘 - 기간노출
  private getPeriodSticker(stickers: Sticker[]) {
    return stickers.filter(sticker => sticker.displayStartedAt && sticker.displayEndedAt);
  }

  //아이콘 - 노출기간
  private getStickerExposurePeriod(stickers: Sticker[]) {
    const periodSticker = stickers.find(sticker => sticker.displayStartedAt && sticker.displayEndedAt);
    return periodSticker ? `${periodSticker.displayStartedAt} ~ ${periodSticker.displayEndedAt}` : '';
  }

  //원산지 전체 조회
  private async fetchPlaceOrigins() {
    const { data } = await this.$api.getPlaceOrigins();
    this.setPlaceOrigin(data);
  }

  private setPlaceOrigin(data: PlaceOrigin[]) {
    this.placeOrigins = data;
  }

  async created() {
    //NOTE: '수정후 승인대기'는 승인완료 후 수정한 사항이라 쇼핑몰 변경할 수 없음. 따라서, originProductInfo.mallNo정보만으로도 가능
    if (this.originProductInfo?.mallNo) {
      const mall = this.malls.find(item => item.mallNo === this.originProductInfo?.mallNo) as Mall;
      this.roundingRule = mall?.roundingRule;
    }
    this.fetchPlaceOrigins(); //원산지
  }
}
