




































import { Vue, Component } from 'vue-property-decorator';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { namespace } from 'vuex-class';
import ThrottleRefreshButton from '@/components/root/parts/ThrottleRefreshButton.vue';
import { GetServicesByServiceNo } from 'ncp-api-supporter/dist/types/modules/admin/service';
import SmsPointSummary from '@/components/root/parts/SmsPointSummary.vue';
import { Admin } from '@/types';
import MallDueDaysSummary from '@/components/root/parts/MallDueDaysSummary.vue';
import DomainSecurityConnection from '@/components/root/parts/DomainSecurityConnection.vue';
import { GetMainMenus, Mall, GetServicesServiceNoMainDomainsResponse, NcpXhrError } from 'ncp-api-supporter';
import isUndefined from 'lodash-es/isUndefined';
import { DEFAULT_DOMAIN_SECURITY_INFO } from '@/views/top/root/initialData';

const summaryStore = namespace('summary');
const adminStore = namespace('admin');
const menuStore = namespace('menu');
const mallStore = namespace('mall');

@Component({
  components: { MallDueDaysSummary, SmsPointSummary, ThrottleRefreshButton, MallSelect, DomainSecurityConnection },
})
export default class Home extends Vue {
  @summaryStore.Mutation('SET_MALL_NO')
  private setMallNoState!: (mallNo: 'ALL' | number) => void;

  @summaryStore.Getter('getMallNoQuery')
  protected readonly mallNoQuery!: 'ALL' | number;

  @adminStore.Action('fetchGodoSno')
  private readonly fetchGodoSno!: Function;

  @adminStore.Getter('getAdminDetail')
  private readonly adminDetail: null | GetServicesByServiceNo;
  @adminStore.Getter('getAdmin')
  private readonly admin!: Admin;

  @menuStore.Getter('getMenus')
  private readonly menus!: GetMainMenus[];

  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private mallNo: '' | number = '';
  private mallName = '';

  private hasDomainMenuReadAuth = false;

  private domainSecurityInfos: Omit<
    GetServicesServiceNoMainDomainsResponse,
    | 'skinType'
    | 'mobileDomain'
    | 'mobileDomainStatus'
    | 'mobileDomainExpiredAt'
    | 'mobileSslStatus'
    | 'mobileSslExpiredAt'
    | 'mobileSslExpirationDueDays'
    | 'mobileDomainExpirationDueDays'
  >[] = [];
  private domainSecurityInfo: Omit<
    GetServicesServiceNoMainDomainsResponse,
    | 'skinType'
    | 'mobileDomain'
    | 'mobileDomainStatus'
    | 'mobileDomainExpiredAt'
    | 'mobileSslStatus'
    | 'mobileSslExpiredAt'
    | 'mobileSslExpirationDueDays'
    | 'mobileDomainExpirationDueDays'
  > = DEFAULT_DOMAIN_SECURITY_INFO;

  async created() {
    await this.fetchDomainSecurityInfo();
    await this.setAdminDetail();

    this.mallNo = this.mallNoQuery === 'ALL' ? '' : this.mallNoQuery;

    this.mallName =
      this.mallNoQuery === 'ALL' ? '' : this.malls.find(({ mallNo }) => mallNo === Number(this.mallNo)).mallName;

    this.domainSecurityInfo =
      this.mallNoQuery === 'ALL'
        ? this.domainSecurityInfo
        : this.domainSecurityInfos.find(({ mallNo }) => mallNo === Number(this.mallNo));

    // "도메인/보안서버 관리" 메뉴 읽기권한 있는지 체크. 현재로서는 이렇게 할 수 밖에 없음
    const domainMenu = this.menus
      .find(({ name }) => name === this.$t('SETTING'))
      ?.menus.find(({ uri }) => uri === '/pro/two-depth/configuration/basic')
      ?.menus.find(({ uri }) => uri === '/pro/configuration/basic/domain-ssl');
    this.hasDomainMenuReadAuth = !isUndefined(domainMenu);
  }

  get isProServicePlanType() {
    return this.admin.plan === 'PRO';
  }

  private async setAdminDetail(): Promise<void> {
    await this.fetchGodoSno();
  }

  private updatedMallNo(): void {
    const selectedMallNo = this.mallNo === '' ? 'ALL' : this.mallNo;

    this.setMallNoState(selectedMallNo);

    this.mallName =
      this.mallNo === '' ? '' : this.malls.find(({ mallNo }) => mallNo === Number(selectedMallNo)).mallName;

    this.domainSecurityInfo =
      this.mallNo === ''
        ? DEFAULT_DOMAIN_SECURITY_INFO
        : this.domainSecurityInfos.find(({ mallNo }) => mallNo === Number(this.mallNo));
  }

  /**
   * 도메인/보안서버 연결상태 정보 호출
   */
  private async fetchDomainSecurityInfo(): Promise<void> {
    try {
      const { data } = await this.$commerceApi.getServicesServiceNoMainDomains({
        pathParams: { serviceNo: this.adminDetail.serviceNo.toString() },
      });

      this.domainSecurityInfos = data;
    } catch (err) {
      const error = err as NcpXhrError

      if (error.data.code !== 'M0001') {
        throw error;
      }
    }
  }
}
