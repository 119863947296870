
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class RootElement extends Vue {
  /*
   * 프리미엄에서 가져왔는데 정확히 뭔지 분석 안됨
   * */
  @Prop({ default: 'div' })
  private tag!: string;

  private render(createElement) {
    return createElement(this.tag, this.$slots.default);
  }
}
