


















































import { Component, Watch, Vue } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import {
  GetOptionsStocksExcelRequest,
  GetOptionsStocksRequest,
  GetStocksExcelFailResultRequest,
  Mall,
  NCPResponse,
  PostStocksEditRequest,
  PostStocksRequest,
} from 'ncp-api-supporter';
import {
  productStockGridProps,
  getContainerOptions,
  StockManageType,
  StockType,
} from '@/views/contents/product/stock/ProductStock';
import { Getter } from 'vuex-class';
import { ItemTableType, StockInfoListType, StockManageInfoType, SuccessListType } from '@/types/productStock';
import { addMonth, getStrYMDHM, getToday } from '@/utils/dateFormat';
import { PopupData, throwWindowPopup } from '@/helpers/popup';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import { throwPopup } from '@/helpers/popup';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { CheckGridEventProps } from '@/types';
import { PeriodType, SearchKeywordType, StockCategoryType } from '@/views/top/product/stock/ProductStock';
import $ from 'jquery';
import { StocksResponse } from 'ncp-api-supporter/dist/types/modules/stock/stock';
import { getNegativeNum } from '@/utils/numberFormat';
import { saveBinaryDataToFile } from '@/utils/fileSaver';
import { i18n } from '@/main';
import { convertQueryParamItemToBoolean } from '@/utils/query';
import { getCurrentMallNo } from '@/utils/mall';
import { api } from '@/api';

@Component({
  components: {
    Grid,
  },
})
export default class ProductStock extends Vue {
  private mallProductCount = 0;
  private mallProductOptionCount = 0;
  private checkedRowsCount = 0;
  private stocks = [];
  private stocksRequest = {} as GetOptionsStocksRequest;
  private gridProps = productStockGridProps;
  private gridContainerOptions = getContainerOptions();
  private itemTable: ItemTableType;
  private gridContainer: HTMLElement | null = null;
  @Getter('mall/getMalls') private malls!: Mall[];
  @Watch('$route')
  private onQueryStringChanged() {
    this.search();
  }

  created() {
    this.search(false);
    this.setBottomNavigation();
  }

  mounted() {
    this.itemTable = this.$refs.itemTable as ItemTableType;
  }

  beforeDestroy() {
    this.removeGridEvent();
  }

  private setBottomNavigation(): void {
    const buttons = [
      {
        type: 'right',
        key: 'save',
        color: 'red',
        text: this.$t('SAVE'),
      },
    ];
    const onClick = key => key === 'save' && this.onClickSaveButton();

    throwBottomNavigation({
      buttons,
      onClick,
    });
  }

  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.checkedRowsCount = checkProps.selected.length;
  }

  private getStockInfo(row): StockManageInfoType {
    const stockInfo = { stockManageType: StockManageType.safetyStock, stockManageCnt: 0 };
    if (row.changeSafetyStock) {
      stockInfo.stockManageType = StockManageType.safetyStock;
      stockInfo.stockManageCnt = row.adjustStockCnt !== null ? Number(row.adjustStockCnt) : null;
    } else if (row.editStock) {
      stockInfo.stockManageType = StockManageType.edit;
      stockInfo.stockManageCnt = row.editStockCnt !== null ? Number(row.editStockCnt) : null;
    } else {
      stockInfo.stockManageType = StockManageType.adjust;
      stockInfo.stockManageCnt = row.adjustStockCnt !== null ? Number(row.adjustStockCnt) : null;
    }
    return stockInfo;
  }

  private getStockInfoList(): StockInfoListType {
    const checkedRows = this.itemTable.getCheckedRows();
    const stockInfoList: StockInfoListType = checkedRows.map(row => {
      const { stockManageType, stockManageCnt } = this.getStockInfo(row);
      const {
        optionManagementCd,
        extraManagementCd,
        mallOptionNo,
        safetyStockCnt,
        safetyStockSyncYn,
        mallNo,
        mallProductNo,
        stockNo,
      } = row;

      const stockInfo = {
        optionManagementCode: optionManagementCd,
        extraManagementCode: extraManagementCd,
        mallOptionNo,
        stockManageCnt,
        stockManageType,
        safetyStockRequest: {
          safetyStockCnt: safetyStockCnt === '-' ? 0 : safetyStockCnt,
          safetyStockSyncYn: safetyStockSyncYn !== 'N',
        },
        mallNo,
        mallProductNo,
        stockNo,
      };
      return stockInfo;
    });

    return stockInfoList;
  }

  private async onClickSaveButton() {
    if (!this.checkedRowsCount) {
      return alert(this.$t('STOCK.MANAGEMENT.ERR_PRODUCT_NO_SELECT'));
    }
    const stockInfoList = this.getStockInfoList();
    const request: PostStocksRequest = { data: stockInfoList };

    try {
      const { data }: NCPResponse<StocksResponse> = await this.$api.postStocks(request);
      const failureList = data.stockManageResults.filter(item => !!item.failCode);
      if (failureList.length > 0) {
        alert(this.$t('STOCK.MANAGEMENT.ADJUSTABLE_RANGE'));
        $('.editInput').val('');
      } else {
        alert(this.$t('STOCK.MANAGEMENT.LAST_SAVE'));
        await this.search();
      }
    } catch (e) {
      console.error(e);
    }
  }

  private resetStocksRequest(isWholePeriod = true): void {
    this.stocksRequest.params = {
      mallNos: getCurrentMallNo(this).toString(),
      page: 1,
      categoryType: StockCategoryType.DISPLAY,
      periodType: PeriodType.registerYMDT,
      startYmdt: isWholePeriod ? null : `${addMonth(new Date(), -3)} ${DEFAULT_TIME_RANGE.START}`,
      endYmdt: isWholePeriod ? null : `${getToday()} ${DEFAULT_TIME_RANGE.END}`,
      platformDisplayType: 'NONE',
      minStockAmount: null,
      maxStockAmount: null,
      saleStatusTypes: '',
      saleSettingStatusTypes: '',
      size: 30,
      searchKeywordType: SearchKeywordType.mallProductNo,
      categoryNo: null,
      keyword: null,
      brandNo: null,
    };
  }

  private async search(isWholePeriod = true) {
    this.resetStocksRequest(isWholePeriod);
    if (isWholePeriod) {
      Object.assign(this.stocksRequest.params, convertQueryParamItemToBoolean(this.$route.query));
    } else {
      this.stocksRequest.params.saleStatusTypes = 'ON_PRE_SALE,WAITING_SALE,ON_SALE,END_SALE';
      this.stocksRequest.params.saleSettingStatusTypes = 'AVAILABLE_FOR_SALE,STOP_SELLING,PROHIBITION_SALE';
    }
    this.removeLastComma();
    try {
      const { data } = await this.$api.getOptionsStocks(this.stocksRequest);
      this.stocks = data.stockViews.map((stockView, index) => {
        return {
          ...stockView,
          changeSafetyStock: false,
          editStock: false,
          number: data.mallProductOptionCount - index,
          itemTableRefs: this.itemTable,
        };
      });
      this.mallProductCount = data.mallProductCount;
      this.mallProductOptionCount = data.mallProductOptionCount;
      this.gridProps.options.pageOptions.totalCount = data.mallProductCount;
      this.$nextTick(() => this.setGridEvent());
    } catch (error) {
      //TODO api keyword 수정필요
      console.error(error);
      this.resetStocksRequest();
      Object.assign(this.$route.query, this.stocksRequest.params);
    }
  }

  private removeLastComma() {
    if (this.stocksRequest.params.keyword?.endsWith(',')) {
      this.stocksRequest.params.keyword = this.stocksRequest.params.keyword.slice(0, -1);
    }
  }

  private validateGridInput(name: string, value: string): string {
    switch (name) {
      case StockType.adjustStock:
        return value.replace(/[^0-9]+/g, '');
      case StockType.editStock:
        return getNegativeNum(value);
      case StockType.optionManagement:
        return value.replace(/[\\'"<>`]/g, '');
    }
  }

  private gridInputHandler(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target.classList.contains('editInput')) return;

    const { name } = target.dataset;
    target.value = this.validateGridInput(name, target.value);
  }

  private getCurrentStockCnt(rowKey: string): number {
    const checkedRows = this.itemTable.getCheckedRows();
    return checkedRows.filter(row => row.rowKey === Number(rowKey))[0].stockCnt;
  }

  private calculateStockCnt(target: HTMLInputElement, isEditStock: boolean) {
    const { rowKey } = target.dataset;
    const inputValue = Number(target.value);
    const currentStockCnt = this.getCurrentStockCnt(rowKey);
    const adjustStock = isEditStock ? currentStockCnt + inputValue : inputValue;
    const editStock = isEditStock ? inputValue : inputValue - currentStockCnt;
    this.itemTable.setValue(rowKey, StockType.adjustStock, adjustStock || '0');
    this.itemTable.setValue(rowKey, StockType.editStock, editStock || '0');
  }

  private gridInputChangeHandler(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target.classList.contains('editInput')) return;
    const { rowKey, name } = target.dataset;
    const isEditStock = name === StockType.editStock;

    if (isEditStock) {
      const currentStockCnt = this.getCurrentStockCnt(rowKey);
      const unAdjustableRange = currentStockCnt + Number(target.value) < 0;
      if (unAdjustableRange) {
        (event.target as HTMLInputElement).value = '';
        return alert(this.$t('STOCK.MANAGEMENT.ADJUSTABLE_RANGE'));
      }
    } else if (name === StockType.optionManagement) {
      this.itemTable.setValue(rowKey, StockType.optionManagement, target.value);
      return;
    }
    this.calculateStockCnt(target, isEditStock);
  }

  private stockChangeHistoryHandler(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.className !== 'gridStockChangeHistory') return;
    const { stockNo, wmsUseYn } = target.dataset;
    throwPopup({
      name: 'StockChangeHistory',
      data: {
        stockNo,
        isUsingWms: wmsUseYn === 'Y',
      },
    });
  }

  private setGridEvent(): void {
    this.gridContainer = document.querySelector('.tui-grid-container');
    this.gridContainer.addEventListener('change', this.gridInputChangeHandler);
    this.gridContainer.addEventListener('input', this.gridInputHandler);
    this.gridContainer.addEventListener('click', this.stockChangeHistoryHandler);
  }

  private removeGridEvent(): void {
    this.gridContainer.removeEventListener('change', this.gridInputChangeHandler);
    this.gridContainer.removeEventListener('input', this.gridInputHandler);
    this.gridContainer.removeEventListener('click', this.stockChangeHistoryHandler);
  }

  private openPopup(type: string) {
    const checkedRows = this.itemTable.getCheckedRows();
    this.checkedRowsCount = checkedRows.length;
    if (checkedRows.length === 0) {
      return alert(this.$t('STOCK.MANAGEMENT.ERR_PRODUCT_NO_SELECT'));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const checkedRowsPopupData = checkedRows.map(({ itemTableRefs, ...otherAttributes }) => otherAttributes);
    const popupData = { type, checkedRows: checkedRowsPopupData };
    throwWindowPopup('StockModification', popupData, 'md', this.stockModificationCallback);
  }

  private async stockModificationCallback(callbackData) {
    if (callbackData.state === 'close') return;
    const { successData, failureData } = callbackData.data;
    const modifiedStockData = successData.dataList;
    if (failureData) {
      await throwPopup({
        name: 'StockModificationResult',
        data: {
          failureData,
          totalCount: this.checkedRowsCount,
        },
      });
    }
    modifiedStockData.forEach(stockData => {
      this.changeGridInputValue(stockData);
    });
    await this.saveStockCount(successData);
    this.itemTable.uncheckAll();
    await this.search();
  }

  private async saveStockCount(successData) {
    const stockInfos = successData.dataList.map(({ mallOptionNo, mallNo, mallProductNo, stockNo }) => ({
      mallOptionNo,
      mallNo,
      mallProductNo,
      stockNo,
    }));

    const request: PostStocksEditRequest = {
      data: {
        stockInfos,
        stockManageCnt: successData.stockManageCnt,
      },
    };
    if (successData.type === StockManageType.adjust) {
      await this.$api.postStocksAdjust(request);
    } else {
      await this.$api.postStocksEdit(request);
    }
  }

  private changeGridInputValue({ editStock, adjustStock, rowKey }: SuccessListType): void {
    this.itemTable.setValue(rowKey, StockType.editStock, editStock);
    this.itemTable.setValue(rowKey, StockType.adjustStock, adjustStock);
  }

  private openExcelUploadPop() {
    throwWindowPopup('StockExcelUpload', 1, 'md', this.getExcelUploadResult);
  }
  private async getExcelUploadResult({ state, data }: PopupData): Promise<void> {
    if (state === 'close') return;

    if (!data.isSuccess) {
      await this.saveFailureExcelData(data.stockFailNo);
    }
    this.$router.go(0);
  }

  private async saveFailureExcelData(stockFailNo: string): Promise<void> {
    api.axios.defaults.headers.Version = '1.1';
    const request: GetStocksExcelFailResultRequest = {
      pathParams: {
        stockFailNo,
      },
    };
    const { data } = await api.getStocksExcelFailResult(request);
    const currentData = getStrYMDHM(new Date());
    // 공백,-,:삭제 => yyyymmddhhmm
    const fileName = `${i18n.t('STOCK.MANAGEMENT.FAILURE_RESULT')}_${currentData.replace(/[\s-:]/g, '')}.xlsx`;
    saveBinaryDataToFile(data, fileName);
    api.axios.defaults.headers.Version = '1.0';
  }

  private async downloadExcel() {
    if (this.mallProductCount === 0) {
      return alert(this.$t('NOT_DATA'));
    }
    const request: GetOptionsStocksExcelRequest = {
      params: this.stocksRequest.params,
      responseType: 'arraybuffer',
    };
    const { data }: NCPResponse<ArrayBuffer> = await this.$api.getOptionsStocksExcel(request);
    const fileName = `${i18n.t('STOCK.MANAGEMENT.TITLE')}_${getToday()}.xlsx`;
    saveBinaryDataToFile(data, fileName);
  }
}
