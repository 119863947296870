


































































































import { Component, Ref, Mixins, Watch } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import ArticleReplyGrid from '@/components/common/grid/Main.vue';
import { getArticleListGridOption, getMemberNo, getOperator } from '@/const/contents/board';
import Article from '@/components/board/basic/mixins/ArticleMixin';
import { CheckGridEventProps, GridEventProps } from '@/types';
import { Row } from '@/types/tui-grid';
import { throwExternalWindowPopup } from '@/helpers/popup';
//import { sendQueryString } from '@/utils/query';

@Component({
  components: {
    ArticleReplyGrid,
  },
})
export default class ArticleReplyView extends Mixins(WindowPopupMainVue, Article) {
  @Ref('articleReplyGrid') private readonly grid: ArticleReplyGrid;

  // grid columns and options
  private gridProps = getArticleListGridOption();
  private totalCount = 0;
  private contents = [];
  private refreshList = '0';

  // grid column.
  private replyGridPropsColumns = this.gridProps.columns.filter(item => {
    item.header = item.name === 'no' ? window.$t('BOARD.BASIC.ARTICLE.HEADER_REPLY_NO') : item.header;
    return item.name !== 'reply' && item.name !== 'replyCount';
  });

  get articleWriter(): string {
    return getOperator(this.data.row, 'writer', '');
  }

  async created() {
    // get article details.
    const result = await this.getArticleDetail(this.data);
    Object.assign(this.data.row, result);

    // set gird height.
    this.gridProps.options.bodyHeight = 240; //'auto';
    //this.gridProps.options.pageOptions.perPage = 100;
    //this.gridProps.options.minBodyHeight = 300;

    // get article replies
    this.getArticleReplies();
  }

  mounted() {
    // add listener for refreshing parent page, listen reply popup saved
    // 제목 link to open edit article popup -> clicking reply button to open reply popup
    window.addEventListener('message', this.refreshParent);
  }

  @Watch('$route.query')
  private updateQuery() {
    // for grid pagination.
    this.getArticleReplies();
  }

  /**
   * --- init reply article's parent info. -----
   * 1. update basic data value from getting the newst parent article detail info. by calling api
   */
  private async init(): Promise<void> {
    // 1
    const result = await this.getArticleDetail(this.data);
    Object.assign(this.data.row, result);
  }

  /**
   * ------ Get  replying list of a article. ----
   * 0. get pagination info. from route query(page, pageSize)
   * 1. call api for get replying list.
   * 2. set replying list to gird.
   * ---------------------------------------------
   */
  private async getArticleReplies(): Promise<void> {
    // 0
    const page = this.$route.query.page;
    const pageSize = this.$route.query.pageSize;
    if (page && pageSize) {
      this.data.page = page;
      this.data.pageSize = pageSize;
    }

    // 1
    const res = await this.getArticleReplyList(this.data);

    // 2
    if (res) {
      res['contents'].forEach((item, i) => (item['index'] = i + 1));
      this.contents = res['contents'];
      this.totalCount = res['totalCount'];
    }
  }

  // ---- delete reply article by clicking delete button----
  // 1. get deleted rows' data
  // 2. to delete logic (@see sub method [deleteArticle] in ArticleMixin)
  //--------------------------------------------------------
  private clickReplyDeleteBtn(): boolean {
    // 1.
    const selectedArticles = this.grid.getCheckedRowKeys();
    // check whether to select row.
    if (selectedArticles.length === 0) {
      window.alert(this.$t('BOARD.BASIC.ARTICLE.SELECT_REPLY_ARTICLE'));
      return false;
    }
    // get parameter for delete article.
    const param = selectedArticles.map(key => this.grid.getRowAt(key).no).join(',');
    // 2.
    this.deleteArticle(this.data.row.boardNo, param, this.refreshSelfAndParent);
    return true;
  }
  /**
   *----- article detail popup ---------
   * 1. generate parameter for article detail popup
   * 2. call common method in ArticleMixin to popup page.
   * -----------------------------------
   */
  private articleDetail(rowData: Row, mode = 'create', name?: string): void {
    // 1
    const popupData = {
      mallNo: this.data.mallNo,
      boardNo: this.data.boardNo,
      parentTitle: this.data.row.title,
      parentContent: this.data.row.content,
      mode: mode,
      row: rowData,
    };
    //throwWindowPopup('ArticleDetail', popupData, 'lg', this.detailCallback);
    // 2
    this.articleDetailPopup(popupData, this.getArticleReplies, name);
  }

  /**
   * ----- edit article which is reply parent -----------
   * 1. generate parameter for article detail popup
   * 2. call common method in ArticleMixin to popup page.
   * ----------------------------------------------------
   */
  private articleDetailEdit(): void {
    // 1
    const popupData = {
      mallNo: this.data.mallNo,
      boardNo: this.data.boardNo,
      mode: 'edit',
      row: this.data.row,
    };
    // 2
    this.articleDetailPopup(popupData, this.refreshPopup, `article_${this.data.row.no}`);
  }

  /**
   * ---- callback logic after editing atricle saved----
   * //1.set refresh flag.
   * //2. refresh page.
   * 3. post message event to repfresh article list on parent.
   * ------------------------------------------------
   */
  private refreshPopup() {
    // 1
    //localStorage.setItem(`refresh_${this.popupId}`, 'Y');
    // 2
    //location.reload();

    /** refresh article basic info. part.*/
    this.init();

    // 3
    window.opener.postMessage('refresh_articleList', window.location);
  }

  /**
   * --- call back method (refesh page) for child's child reply popup saved ----
   * reply popup by clicking reply button which is on article edit popup page by clicking 제목 link.
   * self popup -> clicking 제목 link to open article edit popup -> clicking reply button to open reply popup
   * 1. refresh self reply list and parent article list.
   * --------------------------------------------------------------------------------------
   */
  public refreshParent(_event: MessageEvent): void {
    if (_event.data === 'reply_end') {
      // 1
      this.refreshSelfAndParent();
    }
  }

  /**
   * ---- refresh self reply list and parent article list.(after deleted reply)
   * 1. refresh reply list
   * 2. fire post message event to refresh article list of parent page.
   */
  private refreshSelfAndParent(): void {
    // 1
    this.getArticleReplies();
    // 2
    window.opener.postMessage('refresh_articleList', window.location);
  }

  /**
   * ----- open member crm popup ()--------------
   * 1. get memberNo for crm popup
   * 2. call common method to open member crm popup.
   * --------------------------------------------
   */
  private openMemberCrm(rowData: Row, category: string): void {
    // 1
    const memberNo = getMemberNo(rowData, category);
    // not open crm without member no.
    if (!memberNo) return;

    // 2
    throwExternalWindowPopup(`/pro/crm/${memberNo}`, 'xlg');
  }

  /**------ Open popup page to do logic when clicking grid cell---------
   * 1. open article detail window popup (edit)
   *     (click cell link in '게시글 번호','제목' cloumn of gird)
   * 2. open crm popup page (작성자가,회원 또는 휴면회원인 경우 )
   *  grid columns refer getArticleListGridOption @see'@/const/contents/board.ts'
   *-------------------------------------------------------------------
   */
  private onItemClicked(gridProps: GridEventProps): void {
    const rowData: Row = this.grid.getRowAt(gridProps.rowKey);

    // clicking header cell to return.
    if (rowData === null) {
      return;
    }
    switch (gridProps.columnName) {
      // 1
      case 'no': //게시글 번호
      case 'title': //제목
        this.articleDetail(rowData, 'replyModify', `article_reply_${rowData.no}`);
        break;
      case 'writer': //작성자
      case 'editor': //최종수정자 [api todo]
        // 2 crm
        this.openMemberCrm(rowData, gridProps.columnName);
        break;
    }
  }

  private selected;
  private onRowChecked(checkProps: CheckGridEventProps): void {
    this.selected = checkProps.selected;
  }

  private beforeDestroy() {
    window.removeEventListener('message', this.refreshParent);
  }
}
