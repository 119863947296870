export default Object.freeze({
  TITEL: '전체주문 조회',
  TITLE_PRODUCT_PREPARE: '상품준비중 주문',
  PAY_DONE_TITEL: '결제완료 주문',
  TITLE_DELIVERY_PREPARE: '배송준비중 주문',
  SEND_DELAY: '발송 지연',
  NAVER_SEND_DELAY: '네이버페이 발송지연',
  CASE: '건',
  ALL: '전체',
  MALL: '쇼핑몰',
  PARTNER: '파트너사 지정 조회',
  PARTNER_PLACEHOLDER: '파트너명 입력',
  BTN_CANCEL: '초기화',
  BTN_SEARCH: '조회',
  ORDER_REGISTER_TYPE: '주문구분',
  MEMBER_GROUP: '회원그룹',
  SEARCH_WORD: '검색어',
  ORDER_DATE: '주문일시',
  PLATFORM_TYPES: '플랫폼구분',
  ORDER_STATS: '주문상태',
  PAY_TYPE: '결제수단',
  DELIVERY_TYPE: '배송방법',
  SHIPPING_AREA_TYPE: '배송구분',
  CLAIM_STATUS_TYPE: '클레임구분',
  TASK_MESSAGE_SEARCH_TYPE: '업무메시지',
  RESERVATION_LABEL: '예약주문 함께보기',
  SEARCH_WORD_PLACEHOLDER: '검색어 입력',
  INCLUDE_RECOMMENDER: '추천인 주문 함께보기',
  PLATFORM_LABEL_PC: 'PC',
  PLATFORM_LABEL_MOBILE_WEB: '모바일 웹',
  PLATFORM_LABEL_MOBILE_APP: '모바일 앱',
  ORDER_STATUS_LABEL_DEPOSIT_WAIT: '입금대기',
  ORDER_STATUS_LABEL_PAY_DONE: '결제완료',
  ORDER_STATUS_LABEL_PRODUCT_PREPARE: '상품준비중',
  ORDER_STATUS_LABEL_DELIVERY_PREPARE: '배송준비중',
  ORDER_STATUS_LABEL_DELIVERY_ING: '배송중',
  ORDER_STATUS_LABEL_DELIVERY_DONE: '배송완료',
  ORDER_STATUS_LABEL_BUY_CONFIRM: '구매확정',
  ORDER_STATUS_LABEL_REFUND_DONE: '환불완료',
  PAY_TYPE_ALL: '전체선택',
  INCLUDE_HOLDER_DELIVERY: '배송보류 함께보기',
  SEARCH_DATE_TYPE: '기간',

  INIT_PAY_TYPES_ALL_CREDIT_CARD: '신용카드',
  INIT_PAY_TYPES_ALL_PAYCO: 'PAYCO',
  INIT_PAY_TYPES_ALL_VIRTUAL_ACCOUNT: '가상계좌',
  INIT_PAY_TYPES_ALL_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체',
  INIT_PAY_TYPES_ALL_ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  INIT_PAY_TYPES_ALL_ESCROW_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체-에스크로',
  INIT_PAY_TYPES_ALL_MOBILE: '휴대폰결제',
  INIT_PAY_TYPES_ALL_POINT: '포인트결제',
  INIT_PAY_TYPES_ALL_ACCUMULATION: '적립금 전액 사용',
  INIT_PAY_TYPES_ALL_ZERO_PAY: '0원결제',
  INIT_PAY_TYPES_ALL_NAVER_PAY: '네이버페이 주문형',
  INIT_PAY_TYPES_ALL_YPAY: '옐로페이',
  INIT_PAY_TYPES_ALL_KPAY: '케이페이',
  INIT_PAY_TYPES_ALL_PAYPIN: '페이핀',
  INIT_PAY_TYPES_ALL_INIPAY: 'INIPay 간편결제',
  INIT_PAY_TYPES_ALL_ACCOUNT: '무통장입금',
  INIT_PAY_TYPES_ALL_PHONE_BILL: '전화결제',
  INIT_PAY_TYPES_ALL_GIFT: '상품권',
  INIT_PAY_TYPES_ALL_ATM: 'ATM',
  INIT_PAY_TYPES_ALL_TOASTCAM: '토스트캠',
  INIT_PAY_TYPES_ALL_ETC: '기타결제수단',
  INIT_PAY_TYPES_ALL_VERITRANS_CARD: 'Veritrans CreditCard',
  INIT_PAY_TYPES_ALL_PAYPAL: 'PAYPAL',
  INIT_PAY_TYPES_ALL_STRIPE: 'STRIPE',
  INIT_PAY_TYPES_ALL_NAVER_EASY_PAY: '네이버페이 결제형',
  INIT_PAY_TYPES_ALL_KAKAO_PAY: '카카오페이',
  INIT_PAY_TYPES_ALL_SAMSUNG_PAY: '삼성페이',

  ORDER_REGISTER_NORMAL: '일반주문',
  ORDER_REGISTER_ADMIN: '수기주문',
  ORDER_REGISTER_ARIEL: '외부연계주문',

  SEARCH_TYPE_ORDER_NO: '주문번호',
  SEARCH_TYPE_ORDERER_NAME: '주문자명',
  SEARCH_TYPE_MEMBER_NO: '회원번호',
  SEARCH_TYPE_MALL_PRODUCT_NO: '상품번호',
  SEARCH_TYPE_RECOMMENDER_ID: '추천인',
  SEARCH_TYPE_ORDERER_CONTACT1: '주문자연락처',
  SEARCH_TYPE_HS_CODE: 'HS CODE',
  SEARCH_TYPE_PRODUCT_MANAGEMENT_CD: '상품관리코드',
  SEARCH_TYPE_PRODUCT_NAME: '상품명',
  SEARCH_TYPE_PRODUCT_NAME_EN: '영문상품명',
  SEARCH_TYPE_OPTION_MANAGEMENT_CD: '옵션관리코드',
  SEARCH_TYPE_OPTION_VALUE: '옵션값',
  SEARCH_TYPE_DELIVERY_NO: '배송번호',
  SEARCH_TYPE_INVOICE_NO: '송장번호',
  SEARCH_TYPE_RECEIVER_NAME: '수령자명',
  SEARCH_TYPE_RECEIVER_CONTACT1: '수령자연락처',
  SEARCH_TYPE_NAVER_PAY_ORDER_NO: '네이버페이 주문번호',

  DELIVERY_TYPE_PARCEL_DELIVERY: '택배/등기/소포',
  DELIVERY_TYPE_DIRECT_DELIVERY: '직접배송(화물배달)',

  SHIPPING_AREA_TYPE_PARTNER_SHIPPING_AREA: '파트너사 배송',
  SHIPPING_AREA_TYPE_MALL_SHIPPING_AREA: '쇼핑몰 배송',

  CLAIM_STATUS_TYPE_CANCEL_DONE: '취소',
  CLAIM_STATUS_TYPE_RETURN_DONE: '반품',
  CLAIM_STATUS_TYPE_EXCHANGE_DONE: '교환',

  TASK_MESSAGE_SEARCH_TYPE_PROCESSING: '처리중',
  TASK_MESSAGE_SEARCH_TYPE_EXCLUDE_PROCESSING: '처리중 제외',

  PAY_FAIL_TITLE: '결제실패 주문',

  HOLD_DELIVERY_TITLE: '배송보류 관리',
  HOLD_DELIVERY_TOP_ONE: '배송보류 처리',
  HOLD_DELIVERY_TOP_TWO: '배송보류 지연',
  HOLD_DELIVERY_TOP_CASE: '건',
  HOLD_DELIVERY_TABLE_BUTTON: '배송보류 해제',
  CHECKLIST_EMPTY: '주문상품을 선택해 주세요.',
  CHECKLIST_FALSE:
    '업무메시지가 포함된 주문이 있습니다.\n' +
    '배송보류 해제 하시겠습니까?\n' +
    '배송보류 해제 시, 출고준비 처리/출고 처리가 가능합니다.\n',
  CHECKLIST_TRUE:
    '선택한 항목을 배송보류 해제 하시겠습니까?\n' + '배송보류 해제하시면 출고준비 처리/출고 처리가 가능합니다.',
  CHECKLIST_RESERVATION_TRUE:
    '배송시작일 이전 주문이 있습니다.\n' +
    '주문메뉴로 이동 시, 주문처리가 가능해집니다.\n' +
    '정말 이동하시겠습니까?\n',
  PUT_SUCCESS: '처리되었습니다.',

  HOLD_BY_RESERVATION_TITLE: '예약주문 관리',
  HOLD_BY_RESERVATION_TABLE_BUTTON_ONE: '입금확인 처리',
  HOLD_BY_RESERVATION_TABLE_BUTTON_TWO: '주문메뉴로 이동',
  HOLD_BY_RESERVATION_CHECK_ORDER_STATUS: '주문상태를 확인해 주세요.',

  ORDER_STATUS_TYPES_EMPTY_ALERT: '조회하려는 주문상태를 체크해주세요',
  PARTNER_SEARCH_NO_LABEL: '검색결과가 없습니다.',
  RECEIVER_SELECT: '배송지입력',
  RECEIVER_SELECT_FALSE: '입력완료',
  RECEIVER_SELECT_TRUE: '입력대기',
  CLAIM_STATUS_DETAIL_LABEL: '클레임상태',
  CLAIM_STATUS_DETAIL_TITLE: '클레임상태값 설명',
  CLAIM_STATUS_DETAIL_CANCEL_NO_REFUND: '취소완료[환불없음]: 입금 전, 주문취소된 취소완료 건',
  CLAIM_STATUS_DETAIL_CANCEL_REQUEST: '취소신청[승인대기]: 판매자의 승인이 필요한 주문취소 요청 건',
  CLAIM_STATUS_DETAIL_CANCEL_PROC_REQUEST_REFUND: '취소처리[환불보류]: 쇼핑몰 운영자의 환불처리가 필요한 취소처리 건',
  CLAIM_STATUS_DETAIL_CANCEL_PROC_WAITING_REFUND:
    '취소처리[환불대기]: 진행되고 있는 다른 클레임이 종료된 후 환불이 가능한 취소처리 건',
  CLAIM_STATUS_DETAIL_CANCEL_DONE: '취소완료[환불완료]: 취소상품금액 환불이 정상 처리된 취소완료 건',
  CLAIM_STATUS_DETAIL_RETURN_REQUEST: '반품신청[승인대기]: 판매자의 승인이 필요한 반품신청 건',
  CLAIM_STATUS_DETAIL_RETURN_REJECT_REQUEST: '반품처리[철회대기]: 쇼핑몰 운영자의 승인이 필요한 반품반려신청 건',
  CLAIM_STATUS_DETAIL_RETURN_PROC_BEFORE_RECEIVE:
    '반품처리[수거진행]: 반품승인이 완료되어 상품수거 중인 반품처리 건 (판매자의 수거완료 필요)',
  CLAIM_STATUS_DETAIL_RETURN_PROC_REQUEST_REFUND: '반품처리[환불보류]: 쇼핑몰 운영자의 환불처리가 필요한 반품처리 건',
  CLAIM_STATUS_DETAIL_RETURN_PROC_WAITING_REFUND:
    '반품처리[환불대기]: 진행되고 있는 다른 클레임이 종료된 후 환불이 가능한 반품처리 건',
  CLAIM_STATUS_DETAIL_RETURN_DONE: '반품완료[환불완료]: 반품상품금액 환불이 정상 처리된 반품완료 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_REQUEST: '교환신청[승인대기]: 판매자의 승인이 필요한 교환신청 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_REJECT_REQUEST: '교환처리[철회대기]: 쇼핑몰 운영자의 승인이 필요한 교환반려신청 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_PROC_BEFORE_RECEIVE:
    '교환처리[수거진행]: 교환 승인이 완료되어 기 발송된 상품을 수거 중인 교환처리 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_PROC_REQUEST_PAY:
    '교환처리[결제대기]: 교환상품 차액이 발생되어, 구매자의 결제가 필요한 교환처리 건 (쇼핑몰 운영자의 입금확인 필요)',
  CLAIM_STATUS_DETAIL_EXCHANGE_PROC_WAITING_PAY:
    '교환처리[입금처리대기]: 구매자의 입금이 확인 되었으나, 다른 클레임이 진행중인 교환처리 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_DONE_PAY_DONE: '교환완료[결제완료]: 교환상품의 추가금액을 결제 완료한 교환 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_PROC_REQUEST_REFUND: '교환처리[환불보류]: 쇼핑몰 운영자의 환불처리가 필요한 교환처리 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_PROC_WAITING_REFUND:
    '교환처리[환불대기]: 진행되고 있는 다른 클레임이 종료된 후 환불이 가능한 교환처리 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_DONE_REFUND_DONE: '교환완료[환불완료]: 환불이 완료된 교환완료 건',
  CLAIM_STATUS_DETAIL_EXCHANGE_DONE: '교환완료: 교환상품 차액이 발생하지 않은 교환완료 건',
  RETURN_WAY_TYPE_ALL: '반품수거방법',
  RETURN_WAY_TYPE_SELLER_COLLECT: '판매자수거요청',
  RETURN_WAY_TYPE_BUYER_DIRECT_RETURN: '구매자직접반품',
  REFUNDS_TYPE_REQUEST: '환불보류',
  REFUNDS_TYPE_DONE: '환불완료',
  REFUNDS_TYPE_WAITING: '환불대기',
  RETURN_REFUND_AMT_ADJUST_REQUESTED: '조정요청',
  LIST_STATE_CHANGE_YMDT: '결제일시',
  LIST_ORDER_YMDT: '주문일시',
  PLATFORM_TYPE_EMPTY_ALERT: '조회하려는 플랫폼구분을 체크해주세요',
  ORDER_STATUS_TYPE_EMPTY_ALERT: '조회하려는 주문상태를 체크해주세요',
  LIST_STATE_CHANGE_YMDT_1: '출고준비처리일시',
  DELIVERY_PREPARE_DELAY_TEXT: '네이버페이 발송지연',

  // deposit-wait
  DEPOSIT_WAIT_CONTENT_TOP_TITEL: '입금대기 주문',
  // buy-confirm
  BUY_CONFIRM_CONTENT_TOP_TITEL: '구매확정 주문',
  BUY_CONFIRM_STATE_CHANGE_YMDT: '구매확정일시',
  BUY_CONFIRM_ORDER_YMDT: '주문일시',
  // delivery-done
  DELIVERY_DONE_STATE_CHANGE_YMDT: '배송완료일시',
  DELIVERY_DONE_ORDER_YMDT: '주문일시',
  DELIVERY_DONE_CONTENT_TITEL: '배송완료 주문',
  // deliverying
  TITLE_DELIVERY_ING: '배송중 주문',
  DELIVERYING_STATE_CHANGE_YMDT: '출고일시',
  DELIVERYING_ORDER_YMDT: '주문일시',
});
