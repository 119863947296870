




































































import { Vue, Component, Watch } from 'vue-property-decorator';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { range1ToNI18n } from '@/utils/common';
import { partnerSalesQuery, partnerSalesType } from '@/const/contents/partner';
import DatePeriodPicker from '@/components/common/DatePeriodPicker.vue';
import { PERIOD_TYPE } from '@/const/contents/statistics';
import { defaultDateInfo, getYearMonth } from '@/views/contents/statistics/sales/periodSales';
import { GetSalesRequest } from 'ncp-api-supporter';
import { isEmpty } from 'underscore';
import { getFirstAndEndDay, getBeforeDate } from '@/utils/dateFormat';

@Component({
  components: { DatePeriodPicker, NoticeBox, SelectBox, SearchButton, MallSelect },
})
export default class PartnerSales extends Vue {
  private readonly noticeList = range1ToNI18n(5, 'PARTNER.SALES.NOTICE_');
  private readonly partnerSalesType = partnerSalesType;
  private partnerSalesQuery: GetSalesRequest['params'] = { ...partnerSalesQuery() };
  private periodType = PERIOD_TYPE.MONTH;
  private selectYear: number | null = null;
  private selectMonth: number | null = null;
  private currentMonth: number = new Date().getMonth() + 1;
  private currentYear: number = new Date().getFullYear();

  private reset() {
    this.partnerSalesQuery = { ...partnerSalesQuery() };
    this.periodType = PERIOD_TYPE.MONTH;
    this.resetDate();
  }

  private resetDate() {
    const { year, month } = defaultDateInfo();
    this.selectYear = year;
    this.selectMonth = month;
  }

  private get isSameDate() {
    return this.currentMonth === this.selectMonth && this.currentYear === this.selectYear;
  }

  @Watch('periodType')
  private changePeriodType() {
    const { startDateTime: defaultStartTime, endDateTime: defaultEndTime } = partnerSalesQuery();
    const { startYmd, endYmd } = getFirstAndEndDay(this.selectYear, this.selectMonth);
    const periodEndDate = this.isSameDate ? defaultEndTime : endYmd;

    this.partnerSalesQuery = {
      ...this.partnerSalesQuery,
      startDateTime: this.periodType === PERIOD_TYPE.MONTH ? defaultStartTime : startYmd,
      endDateTime: this.periodType === PERIOD_TYPE.MONTH ? defaultEndTime : periodEndDate,
    };
    this.resetDate();
  }

  @Watch('partnerSalesQuery.endDateTime')
  private changeEndDate() {
    if (this.periodType === PERIOD_TYPE.MONTH && this.isSameDate) {
      this.partnerSalesQuery.endDateTime = getBeforeDate();
    }
  }

  @Watch('$route.query')
  private initialSetting() {
    if (!isEmpty(this.$route.query)) {
      this.partnerSalesQuery = { ...partnerSalesQuery(), ...this.$route.query };
      const { year, month } = getYearMonth(this.$route.query.startDateTime.toString());
      this.selectYear = year;
      this.selectMonth = month;
      return;
    }

    this.resetDate();
    this.reset();
    this.changePeriodType();
  }

  created() {
    this.initialSetting();
  }
}
