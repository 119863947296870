







import { Vue, Component } from 'vue-property-decorator';
@Component
export default class SubmitButton extends Vue {
  destroy() {
    this.$off('click');
  }
}
