export default Object.freeze({
  // STATISTIC_PROMOTION_
  TIT: '프로모션통계',
  ADDITIONAL_DISCOUNT: '추가할인 판매현황',
  DISCOUNT_COUPON: '할인쿠폰 판매현황',
  CLAIM_CLASSIFICATION: '클레임 구분',
  NO_CLAIMS: '클레임 제외 : 결제(입금)완료 건만 조회',
  CLAIM_INCLUDED_LI: '클레임 포함 :결제(입금)완료 – 취소완료(환불완료) – 반품완료(환불완료)',
  CLAIM_INCLUDED_SPAN: '교환완료(환불완료) - 교환완료(결제완료) - 교환완료',
  NUMBER_OF_SALES: '판매건수 : 클레임 포함 조회시 부분취소(반품)일 경우 판매(주문)건수에는 포함되지 않습니다.',
  SALE_PRICE_A: '판매금액(A) : 판매가 - 즉시할인가',
  NAVER_PAY_WARNING:
    '(* 네이버페이 주문형으로 결제한 주문의 네이버 조정금액으로 인해 오차가 발생할 수 있습니다. 상세 금액은 네이버페이센터를 통해 확인해 주세요.)',
  AMOUNT_PRODUCT_SALES: '상품판매금액(C) : 판매금액(A) - 추가할인금액(B)',
  EXCEL_DOWNLOAD_WARNING:
    '※ 엑셀다운로드 : 조회조건에 따른 엑셀 다운로드시 해당 추가할인이 포함된 주문 건이 다운로드됩니다.(주문번호 기준)',
  MEMBER_GROUP_LOOKING_WARNING:
    '회원그룹으로 조회 시, 복수 회원그룹을 가진 회원의 데이터가 이중 집계되어 오차가 발생할 수 있습니다. ',
  SHOPPING_MALL: '쇼핑몰',
  LOOKUP: '조회',
  RESET: '초기화',
  PARTNER: '파트너',
  BRAND: '브랜드',
  MEMBER_TYPE: '회원유형',
  MEMBER_GENDER: '회원 성별',
  MEMBERSHIP_LEVEL: '회원등급',
  MEMBER_GROUP: '회원그룹',
  PLATFORM_CLASSIFICATION: '플랫폼 구분',
  PROMOTION_SEARCH: '프로모션 검색',
  INQUIRY_PERIOD: '조회기간',
  DISCOUNT_SALES_STATUS: '추가할인 판매현황 종합',
  YEAR: '년',
  MONTH: '월',
  ADDITIONAL_DISCOUNT_DETAILS_SALES_STATUS: '추가할인세부 판매현황',
  KEY: '건',
  SHOPPING_MALL_MEMBERS: '쇼핑몰회원',
  PAYCO_ID_MEMBER: 'PAYCO아이디회원',
  ABOUT_OPENID: 'OpenID 회원',
  INTEGRATED_MANAGEMENT_MEMBER: '통합관리회원',
  BY_PERIOD: '기간별',
  MONTHLY: '월별',
  ALL: '전체',
  INCLUDES_NO_CLAIMS: '클레임 제외',
  CLAIM_INCLUDED: '클레임 포함',
  ADDITIONAL_DISCOUNT_NAME: '추가할인명',
  ADDITIONAL_DISCOUNT_NUMBER: '추가할인번호',
  ADDITIONAL_DISCOUNT_REGISTRANT: '등록자',
  SELECT_ALL: '전체선택',
  SELECT_MALE: '남성',
  SELECT_FEMALE: '여성',
  SELECT_UNKNOWN: '미분류',
  ADDITIONAL_DISCOUNT_FIRSTHEADER: '추가 할인',
  NUMBER_OF_SALES_HEADER: '판매건수',
  NUMBER_OF_PRODUCTS_SOLD: '판매 상품수',
  SALES_QUANTITY: '판매수량',
  SALES_AMOUNT_A: '판매금액(A)',
  ADDITIONAL_DISCOUNT_AMOUNT_B: '추가할인금액(B)',
  PRODUCT_SALES_AMOUNT_C: '상품판매금액(C)@(C=A-B)',
  VIEW_DETAILS: '상세보기',
  DOWNLOAD: '다운로드',
  DIVISION: '구분',
  TOTAL_DISCOUNT_AMOUNT: '할인금액 합계',
  DISCOUNT_AMOUNT: '할인금액',
  RATIO: '비율(%)',
  SHOPPING_MALL_BURDEN: '쇼핑몰 부담',
  PARTNER_BURDEN: '파트너 부담',
  MALLADDITIONALDISCOUNTAMTBONE: '추가할인금액(쇼핑몰)(B1)',
  MALLADDITIONALDISCOUNTAMTBTOW: '추가할인금액(파트너)(B2)',
  MALLADDITIONALDISCOUNTAMTC: '상품판매금액(C)@(C=A-B1-B2)',
  ENTER_PARTNER_NAME: '파트너사명 입력',
  ENTER_SEARCH_TERM: '검색어 입력',
  ENTER_BRAND_NAME: '브랜드명 입력',
  COUPON_CLAIM_INCLUDED:
    '클레임 포함 : 결제(입금)완료 – 취소완료(환불완료) – 반품완료(환불완료) – 교환완료(환불완료) - 교환완료(결제완료) - 교환완료',
  COUPON_PRODUCT_PRICE_DISCOUNT_SALES_AMOUNT: '상품 금액 할인 선택 시 -> 상품판매금액(C) : 판매금액-상품 금액 할인',
  COUPON_AMOUNT_D: '총 할인쿠폰 금액(D) : 상품 금액 할인+장바구니 금액 할인+장바구니 배송비 할인',
  COUPON_EXCEL_DOWNLOAD:
    '※ 엑셀다운로드 : 조회조건에 따른 엑셀 다운로드시 해당 추가할인이 포함된 주문 건이 다운로드됩니다.(주문번호 기준)>',
  COUPON_PROMOTIONAL_BENEFITS: '프로모션 혜택구분',
  COUPON_DISCOUNT_ON_PRODUCT_AMOUNT: '상품 금액 할인',
  COUPON_DISCOUNT_ON_SHOPPING_CART_AMOUNT: '장바구니 금액 할인',
  MOBILE_WEBSITE: '모바일 웹',
  MOBILE_APP: '모바일 앱',
  COUPON_TOTALPRODUCTCOUPONAMT: '상품 금액 할인(B)',
  COUPON_PRODUCT_SALES_AMOUNT_C: '상품판매금액(C)@(C=A-B)',
  COUPON_TOTAL_DISCOUNT_COUPON_AMOUNT_D: '총 할인쿠폰 금액(D)',
  COUPON_PRODUCT_PRICE_DISCOUNT_RATE_E: '상품 금액 할인 비율(%)@(E)(E=B/D)',
  COUPON_DISCOUNT_COUPON_NUMBER: '할인쿠폰번호',
  COUPON_DISCOUNT_COUPON_NAME: '할인쿠폰명',
  COUPON_DISCOUNT_COUPON_REGISTER: '쿠폰등록자',
  COUPON_PRODUCT_AMOUNT_DISCOUNT_SHOPPING_MALL_B1: '상품 금액 할인(쇼핑몰)(B1)',
  COUPON_PRODUCT_AMOUNT_DISCOUNT_PARTNER_B2: '상품 금액 할인(파트너)(B2)',
  COUPON_PRODUCT_SALES_AMOUNT_BUYPRICE: '상품판매금액(C)@(C=A-B1-B2)',
  COUPON_SHOPPING_CART_DISCOUNT_B: '장바구니 금액 할인(B)',
  COUPON_DISCOUNT_RATE_OF_SHOPPING_CART_AMOUNT_E: '장바구니 금액 할인비율(%)(E)@(E=B/D)',
  COUPON_TOTAL_PAYMENT_AMOUNT: '총 결제금액',
  COUPON_COMPREHENSIVE_DISCOUNT_COUPON_SALES: '할인쿠폰 판매현황 종합',
  COUPON_DISCOUNT_COUPON_DETAILS_SALES_STATUS: '할인쿠폰세부 판매현황',
  COUPON_NOTICE_HEAD:
    '(* 네이버페이 주문형으로 결제한 주문의 네이버 조정금액으로 인해 오차가 발생할 수 있습니다. 상세 금액은 네이버페이센터를 통해 확인해 주세요.)',
  COUPON_COMMODITY_DISCOUNT: '상품 금액 할인',
});
