


















import { Vue, Component, Prop } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { ToolTipOption } from '@/types/tooltip';
import { toolTipComponents } from '@/components/common/tooltip/tooltip';

const components = toolTipComponents;

@Component({
  directives: { ClickOutside },
  components,
})
export default class ToolTip extends Vue {
  @Prop({ required: true })
  private option: ToolTipOption;
  private show = false;
  private toolTipOption: ToolTipOption = {
    iconType: 'icon-tooltip icon-help',
  };

  get childName(): string {
    for (const key in components) {
      if (key === this.option.name) {
        return key;
      }
    }
    return 'Default';
  }

  get style(): {} {
    return this.option.style || {};
  }

  private changeToolTipShow(): void {
    this.show = !this.show;
  }

  private hideToolTip(): void {
    this.show = false;
  }

  created() {
    this.toolTipOption = Object.assign({}, this.toolTipOption, this.option);
  }
}
