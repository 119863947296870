import { Component, Vue } from 'vue-property-decorator';
import { deliveryNoGridTypes, orderNoGridTypes } from '@/const/order';
import { defaultDisplay, orderGridsDefaultColumns } from '@/components/shipping/order/mixins/OrderGridsDefaultColumns';
import { emptyValueFormatter, getAnchorHTML } from '@/utils/grid/formatterUtils';
import { GridLayout, Mall, NCPResponse, GridNameType } from 'ncp-api-supporter';
import { throwWindowPopupWithProps } from '@/helpers/popup';
import { Getter } from 'vuex-class';
import PAY_TYPE from '@/const/i18n/kr/order/PAY_TYPE';
import { formatCurrency } from '@/utils/numberFormat';

@Component
export default class OrderGridsColumns extends Vue {
  @Getter('mall/getMalls') public malls: Mall[];
  public showSmsMenu = false;
  public size = 30;
  public orderListTypes;
  public label: string;
  public tabLabel: string;
  public deliveryCompanyTypeArr?;
  public defaultDisplay = defaultDisplay();
  public containerOptions = {
    hasExcelDownloadButton: true,
    pageSizeKey: 'size',
    gridName: '',
  };

  public gridProps = {
    header: {
      height: 33,
    },
    options: {
      rowHeaders: ['checkbox'],
      header: {
        height: 50,
      },
      columnOptions: {
        minWidth: 50,
        resizable: true,
      },
      minRowHeight: 50,
      rowHeight: 40,
      bodyHeight: 480,
      displayRowCount: 30,
      useClientSort: false,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
        centerAlign: true,
      },
      copyOptions: {
        useFormattedValue: true,
      },
    },
    keyColumnName: 'orderProductOptionNo',
    columnOptions: {
      useFormattedValue: true,
    },
    columns: [],
  };

  public listColumns(orderListTypes) {
    return orderGridsDefaultColumns(
      orderNoGridTypes[orderListTypes] ? orderNoGridTypes[orderListTypes] : deliveryNoGridTypes[orderListTypes],
    );
  }

  public isAllSameMall(data) {
    const [firstMallNo, ...mallNos] = data.map(d => d.mallNo);
    return mallNos.every(mallNo => mallNo === firstMallNo);
  }

  public openSmsSendPopup(sendType: 'SELECT' | 'SEARCH', orders) {
    if (orders.length === 0) {
      alert(this.$t(`CLAIM.MESSAGE.VALID_SMS_${sendType}_ORDER`));
      return;
    }

    if (!this.isAllSameMall(orders)) {
      alert(this.$t(`CLAIM.MESSAGE.VALID_SAME_MALL`));
      return;
    }

    const recipients = orders.map(claim => claim.receiverContact1);

    throwWindowPopupWithProps({
      componentName: 'SmsSendManagerIframe',
      data: { mallNo: orders[0].mallNo },
      query: { mallNo: orders[0].mallNo, recipients, type: 'RESTOCK' },
      size: 'xlg',
    });
  }

  public hideSmsMenu() {
    this.showSmsMenu = false;
  }

  public async setGridData(orderListTypes): Promise<void> {
    this.containerOptions.gridName = orderNoGridTypes[orderListTypes]
      ? orderNoGridTypes[orderListTypes]
      : deliveryNoGridTypes[orderListTypes];

    const { data: gridLayouts }: NCPResponse<GridLayout[]> = await this.$api.getGridLayouts({
      params: { gridName: this.containerOptions.gridName as GridNameType },
    });

    this.gridProps.columns = this.listColumns(orderListTypes).map((item, displayOrder) => {
      const columnName = this.getColumnName(item);
      const intersectionItem = gridLayouts.find(dataItem => dataItem.columnName === columnName);
      const defaultVisible = name !== 'orderOptionNo';
      const visible = intersectionItem?.visible ?? defaultVisible;

      return this.getOrderColumns(columnName, visible, displayOrder);
    });
  }

  public getColumnName(i18n: string) {
    for (let i = 0; i < this.defaultDisplay.length; i++) {
      if (this.defaultDisplay[i].i18n === i18n) {
        return this.defaultDisplay[i].columnName;
      }
    }
  }

  protected getMallName(e) {
    if (this.malls.length === 0) {
      return;
    }
    const name = this.malls.filter(item => {
      return item.mallNo === e;
    });
    if (name.length > 0) {
      return name[0].mallName;
    } else {
      return '_';
    }
  }

  protected getKorCurrency(value) {
    if (!value || isNaN(value)) {
      return 0;
    }
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  public gridHidden(type: boolean) {
    return type !== true;
  }

  public getOrderColumns(str: string, visible = true, displayOrder: number) {
    let orderColumns;
    switch (str) {
      case 'platformType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PLATFORM_TYPE',
          name: 'platformType',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '플랫폼구분',
          formatter: function(data) {
            if (data.value) {
              if (data.value === 'MOBILE_WEB') {
                return window.$t('ORDER.ORDER_GRID_COLUMNS.PLATFORM_TYPE_MOBILE_WEB');
              } else if (data.value === 'MOBILE_APP') {
                return window.$t('ORDER.ORDER_GRID_COLUMNS.PLATFORM_TYPE_MOBILE_APP');
              } else {
                return data.row.platformTypeLabel;
              }
            }
            return '-';
          },
        };
        break;
      case 'mallNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MALLNO',
          name: 'mallNo',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '쇼핑몰구분',
          formatter: data => {
            return this.getMallName(data.value);
          },
        };
        break;
      case 'orderRegisterType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_REGISTER_TYPE',
          name: 'orderRegisterType',
          align: 'center',
          minWidth: 60,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문구분',
          formatter: function(data) {
            if (data.value) {
              return data.row.orderRegisterTypeLabel;
            }
            return '-';
          },
        };
        break;
      case 'reservation':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RESERVATION_YN',
          name: 'reservation',
          align: 'center',
          minWidth: 60,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '예약주문',
          formatter: function(data) {
            if (data.value === true) {
              return 'Y';
            }
            return '-';
          },
        };
        break;
      case 'memberGroupInfoJson':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MEMBER_GROUP_INFO_JSON',
          name: 'memberGroupInfoJson',
          align: 'center',
          minWidth: 60,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '회원그룹',
          formatter: function(data) {
            if (!data || data.value === '' || data.value === '[]') {
              return '-';
            }
            const memberGroupInfo = JSON.parse(data.value);
            if (memberGroupInfo === null || memberGroupInfo.length <= 0) {
              return '-';
            } else if (memberGroupInfo.length == 1) {
              return memberGroupInfo[0].memberGroupName;
            }
            return `<a href="javascript:;" class="member-group-name-grid" data-json='${JSON.stringify(
              memberGroupInfo,
            ).replace(/"/g, '\\"')}'>${memberGroupInfo[0].memberGroupName}</a>`;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: function(data) {
              if (!data) {
                return '-';
              }
              const memberGroupInfo = JSON.parse(data);
              if (memberGroupInfo === null || memberGroupInfo.length <= 0) {
                return '-';
              } else if (memberGroupInfo.length == 1) {
                return memberGroupInfo[0].memberGroupName;
              }
            },
          },
        };
        break;
      case 'recommenderId':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RECOMMENDER_ID',
          name: 'recommenderId',
          align: 'center',
          minWidth: 60,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '추천인',
          formatter: function(data) {
            return !data.value ? '-' : data.value;
          },
        };
        break;
      case 'payType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PAY_TYPE',
          align: 'center',
          minWidth: 80,
          name: 'payType',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '결제수단',
          formatter: function(data): string {
            return data.row.payTypeLabel;
          },
        };
        break;
      case 'orderNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_NO',
          align: 'center',
          minWidth: 160,
          name: 'orderNo',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문번호',
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        if (this.orderListTypes !== 'OrderPayFail') {
          // 결제실패
          orderColumns.formatter = data => {
            const naverPayDiv =
              data.row.payType === 'NAVER_PAY'
                ? `<div> (<span style="color: #03CF5D">N</span>: ${data.row?.externalOrderNo ?? '-'})</div>`
                : '';
            if (data.row.orderStatusType === 'PAY_CANCEL' || data.row.orderStatusType === 'PAY_FAIL') {
              return data.value + naverPayDiv;
            }
            const hold = data.row.hold;
            let className = '';
            if (hold === true) {
              className = 'hold';
            }
            return `<a href="javascript:;" class=${className}>${data.value}</a> ${naverPayDiv}`;
          };
        }
        break;
      case 'ordererName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_NAME',
          align: 'center',
          minWidth: 80,
          name: 'ordererName',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문자명',
          formatter: data => {
            if (data.row.memberStatus === 'WITHDRAWN') {
              return window.$t('ORDER.ORDER_GRID_COLUMNS.WITHDRAWAL_MEMBER');
            }
            const memberNo = data.row.memberNo || 0;
            return memberNo === 0 ? data.value : getAnchorHTML(data.value);
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'ordererContact1':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_CONTACT1',
          align: 'center',
          minWidth: 100,
          name: 'ordererContact1',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문자연락처',
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'holdDelivery':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_HOLD_DELIVERY_YN',
          name: 'holdDelivery',
          align: 'center',
          minWidth: 60,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송보류',
          formatter: function(data) {
            if (!data.value || data.value === false) {
              return '-';
            }
            return 'Y';
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: function(data) {
              if (!data || data === false) {
                return '-';
              }
              return 'Y';
            },
          },
        };
        break;
      case 'memberNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MEMBER_NO',
          align: 'center',
          minWidth: 80,
          name: 'memberNo',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '회원번호',
          formatter: function(data) {
            if (data.value === 0) {
              return '-';
            }
            return data.value;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: function(data) {
              if (data === 0) {
                return '-';
              }
              return data;
            },
          },
        };
        break;
      case 'shippingAreaType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_SHIPPING_ARER_TYPE_LABEL',
          name: 'shippingAreaType',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송구분',
          formatter: function(data) {
            if (data.value === 'PARTNER_SHIPPING_AREA') {
              return window.$t('ORDER.ORDER_GRID_COLUMNS.SHIPPING_AREA_TYPE_PARTNER_SHIPPING_AREA');
            } else if (data.value === 'MALL_SHIPPING_AREA') {
              return window.$t('ORDER.ORDER_GRID_COLUMNS.SHIPPING_AREA_TYPE_MALL_SHIPPING_AREA');
            } else {
              return '';
            }
          },
        };
        break;
      case 'taskMessage':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TASK_MESSAGE_YN',
          name: 'taskMessage',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '업무메시지',
          formatter: data => {
            const { taskMessageCnt, taskMessageProcessCnt } = data.row;
            // taskMessageCnt > 0 이면 처리중으로 표시
            if (taskMessageCnt === 0) {
              return '';
            }

            if (taskMessageProcessCnt > 0) {
              return `<a href="#"><span class="badge-task"/></a>`;
            }

            return `<a href="#"><span class="badge-task02"/></a>`;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: data => {
              if (data === true) {
                return 'Y';
              } else {
                return '';
              }
            },
          },
        };
        break;
      case 'orderStatusType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_STATUS_TYPE',
          align: 'center',
          minWidth: 80,
          name: 'orderStatusType',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문상태',
        };
        if (this.orderListTypes === 'OrderPayFail') {
          // 결제실패
          orderColumns.header = 'ORDER.LIST_GRIDS.HEADER_ORDER_STATUS_TYPE_THREE';
          orderColumns.formatter = data => {
            if (data.row.orderStatusType == 'PAY_FAIL') {
              return `<font color="red">${window.$t('ORDER.ORDER_GRID_COLUMNS.HEADER_OTHER_STATUS_TYPE_LABEL')}</font>`;
            }
            return window.$t('ORDER.ORDER_GRID_COLUMNS.HEADER_OTHER_STATUS_TYPE_LABEL_ELSE');
          };
        } else {
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_ORDER_STATUS_TYPE';
          orderColumns.formatter = data => {
            const holdDeliveryLabel =
              data.row.holdDelivery === true
                ? `<br/>[${window.$t('ORDER.ORDER_GRID_COLUMNS.HEADER_HOLD_DELIVERY_YN')}]`
                : '';
            return data.row.orderStatusTypeLabel + holdDeliveryLabel;
          };
        }
        break;
      case 'failInfo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_FAIL_INFO',
          align: 'center',
          minWidth: 300,
          name: 'failInfo',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '실패사유',
          formatter: data => {
            if (data.row.orderStatusType == 'PAY_FAIL') {
              return data.row.failInfo;
            }
            return '';
          },
        };
        break;
      case 'claimStatusType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_CLAIM_STATUS_TYPE',
          align: 'center',
          minWidth: 135,
          name: 'claimStatusType',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '클레임상태',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.row.claimStatusTypeLabel;
          },
        };
        break;
      case 'partnerNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PARTER_NO',
          name: 'partnerNo',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '파트너사',
          formatter: function(data) {
            return data.row.shippingAreaType === 'MALL_SHIPPING_AREA' ? '쇼핑몰 자체 상품' : data.row.partnerName;
          },
        };
        break;
      case 'hsCode':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_HS_CODE',
          name: 'hsCode',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: 'HS CODE',
        };
        break;
      case 'mallProductNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MALL_PRODUCT_NO',
          name: 'mallProductNo',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품번호',
        };
        break;
      case 'productManagementCd':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PRODUCT_MANAGEMENT_CD',
          name: 'productManagementCd',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품관리코드',
        };
        break;
      case 'previousProductName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PRODUCT_NAME',
          name: 'productName',
          align: 'left',
          minWidth: 200,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품명',
          formatter: ({ row }) => {
            const extraCount = row.orderOptions.length - 1;
            const extraCountLabel = extraCount > 0 ? ` 외 ${extraCount} 건` : '';
            return `${row.orderOptions[0].productName}${extraCountLabel}`;
          },
        };
        break;
      case 'productName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PRODUCT_NAME',
          name: 'productName',
          align: 'left',
          minWidth: 200,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품명',
          formatter: data => {
            const exchangeTag = `<span class="tag skyblue">${window.$t('ORDER.LIST_GRIDS.EXCHANGE_FORWARDING')}</span>`;
            if (data.row.isSetOption) {
              const setTag = `<span class="tag pink">${window.$t('ORDER.LIST_GRIDS.PRODUCT_NAME_TAG')}</span>`;

              data.row.exchange ? `${exchangeTag} ${setTag} ${data.value}` : `${setTag} ${data.value}`;
            }
            if (data.row.exchange) {
              return `${exchangeTag} ${data.value}`;
            }
            return data.value;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'productNameEn':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PRODUCT_NAME_EN',
          name: 'productNameEn',
          align: 'left',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '영문상품명',
        };
        break;
      case 'optionManagementCd':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_OPTION_MANAGEMENT_CD',
          name: 'optionManagementCd',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '옵션관리코드',
        };
        break;
      case 'displayOptionName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DISPLAY_OPTION_NAME',
          name: 'displayOptionName',
          align: 'left',
          minWidth: 150,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '옵션명: 옵션값',
          formatter: function(data) {
            const mallAdditionalProductNo = data.row.mallAdditionalProductNo;
            if (parseInt(mallAdditionalProductNo) > 0) {
              return `<span class="tag orange"> ${window.$t('ORDER.ORDER_GRID_COLUMNS.ADD')}</span> ${data.value}`;
            }
            return data.value;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'immediateDiscountedPrice':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_IMMEDIATE_DISCOUNT_PRICE',
          name: 'immediateDiscountedPrice',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '즉시할인가',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'additionalDiscountAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ADDITIONAL_DISCOUNT_AMT',
          name: 'additionalDiscountAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '추가할인',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'buyPrice':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_BUY_PRICE',
          name: 'buyPrice',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'orderCnt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_CNT',
          name: 'orderCnt',
          align: 'center',
          minWidth: 30,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '수량',
          formatter: data => {
            if (data.value > 1) {
              return `<span class="highlight">${data.value}</span>`;
            }
            return data.value;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'totalBuyAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TOTAL_BUY_AMT',
          name: 'totalBuyAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품합계',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'totalLastProductCouponDiscountAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_LAST_PRODUCT_COUPON_DISCOUNT_AMT',
          name: 'totalLastProductCouponDiscountAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품쿠폰할인',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'lastCartCouponDiscountAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_LAST_CART_COUPON_DISCOUNT_AMT',
          name: 'lastCartCouponDiscountAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문쿠폰할인',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'orderAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_AMT',
          name: 'orderAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문금액',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'receiverName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_NAME',
          name: 'receiverName',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '수령자명',
        };
        break;
      case 'receiverContact1':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_CONTACT1',
          name: 'receiverContact1',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '수령자연락처',
        };
        break;
      case 'receiverZipCd':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_ZIP_CD',
          name: 'receiverZipCd',
          align: 'center',
          minWidth: 60,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '우편번호',
        };
        break;
      case 'address':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ADDRESS',
          name: 'address',
          align: 'left',
          minWidth: 200,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주소',
        };
        break;
      case 'shippingNo': // shippingNo???
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVER_NO',
          name: 'shippingNo',
          align: 'center',
          minWidth: 150,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송번호',
          whiteSpace: 'nowrap',
          maxHeight: 40,
          formatter: data => {
            if (data.row.combineDelivery === true) {
              return `<div>${data.value} <br/><span class="tag yellow" style="padding-bottom: 0px;">${window.$t(
                'ORDER.ORDER_GRID_COLUMNS.DELIVERY_NUIT',
              )}</span></div>`;
            } else if (data.row.divideDelivery === true) {
              return `${data.value}  <br/><span class="tag orange" style="padding-bottom: 0px;">${window.$t(
                'ORDER.ORDER_GRID_COLUMNS.SHARE',
              )}</span>`;
            }
            return data.value;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'deliveryAmtInAdvance':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVER_AMT_ADVANCE_YN',
          name: 'deliveryAmtInAdvance',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '결제방식', // 배송비 결제방식
          formatter: function(data) {
            if (data.row.delivery === false) {
              return '-';
            }
            return data.value === false
              ? `${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERY_AMT_TYPE_ARRIVE')}`
              : `${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERY_AMT_TYPE_BEFORE')}`;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: function(data) {
              return data === false
                ? `${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERY_AMT_TYPE_ARRIVE')}`
                : `${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERY_AMT_TYPE_BEFORE')}`;
            },
          },
        };
        break;
      case 'deliveryConditionType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVER_CONDITION_TYPE',
          name: 'deliveryConditionType',
          align: 'center',
          minWidth: 110,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송비 유형',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.row.deliveryConditionTypeLabel;
          },
        };
        break;
      case 'customsIdNumber':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_CUSTOMS_ID_NUMBER',
          name: 'customsIdNumber',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '개인통관고유부호',
        };
        break;
      case 'deliveryType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVER_TYPE',
          name: 'deliveryType',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송방법',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.row.deliveryTypeLabel;
          },
        };
        break;
      case 'deliveryCompanyType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVER_COMPANY_TYPE',
          name: 'deliveryCompanyType',
          align: 'center',
          minWidth: 125,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '택배사',
        };
        if (
          (this.orderListTypes === 'ORDER_LIST' || this.orderListTypes === 'DELIVERY_PREPARE') &&
          this.tabLabel === 'deliveryTab'
        ) {
          // 배송준비중
          orderColumns.formatter = data => {
            let theOpt = '';
            let theSelect = '';
            let content = '';
            if (this.deliveryCompanyTypeArr.length > 0) {
              this.deliveryCompanyTypeArr.map(dct => {
                if (data.value === dct.deliveryCompanyType) {
                  theOpt += `<option selected value="${dct.deliveryCompanyType}">${dct.label}</option>`;
                } else {
                  if (dct.deliveryCompanyType === 'CJ') {
                    theOpt += `<option selected value="${dct.deliveryCompanyType}">${dct.label}</option>`;
                  } else {
                    theOpt += `<option value="${dct.deliveryCompanyType}">${dct.label}</option>`;
                  }
                }
              });
              if (data.row.deliveryType === 'DIRECT_DELIVERY' || data.row.delivery === false) {
                const name = 'companySelect';
                theSelect = `<select data-group-key="${data.row.shippingNo}" class="${name}" disabled>${theOpt}</select>`;
              } else {
                const name = 'companySelect';
                theSelect = `<select data-group-key="${data.row.shippingNo}" class="${name}">${theOpt}</select>`;
              }
              content = `<span class="tui-grid-content-before"></span><span class="tui-grid-content-after"></span><span class="tui-grid-content-input">${theSelect}</span>`;
            }
            return `${content}`;
          };
        } else {
          orderColumns.formatter = data => {
            return !data.row.deliveryCompanyTypeLabel ? '-' : data.row.deliveryCompanyTypeLabel;
          };
        }
        break;
      case 'invoiceNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_INVOICE_NO',
          name: 'invoiceNo',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '송장번호',
          formatter: '',
        };
        if (
          (this.orderListTypes === 'ORDER_LIST' || this.orderListTypes === 'DELIVERY_PREPARE') &&
          this.tabLabel === 'deliveryTab'
        ) {
          // 배송준비중
          orderColumns.formatter = data => {
            if (data.row.deliveryType === 'DIRECT_DELIVERY' || data.row.delivery === false) {
              return `<input type="text" data-row-key="${data.row.rowKey}" data-column-name="${data.column.name}" data-group-key="${data.row.shippingNo}" class="invoiceNoInput" maxlength disabled value="${data.value}"/>`;
            } else {
              if (data.value === null) data.value = '';
              return `<input type="text" data-row-key="${data.row.rowKey}" data-column-name="${data.column.name}" data-group-key="${data.row.shippingNo}" class="invoiceNoInput" value="${data.value}"/>`;
            }
          };
        } else {
          orderColumns.formatter = data => {
            return !data.value ? '-' : data.value;
          };
        }
        break;
      case 'orderYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_YMDT',
          name: 'orderYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문일시',
        };
        break;
      case 'payYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PAY_YMDT',
          name: 'payYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '결제일시',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.value;
          },
        };
        break;
      case 'orderAcceptYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_ACCEPT_YMDT',
          name: 'orderAcceptYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품준비중처리일시',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.value;
          },
        };
        break;
      case 'releaseReadyYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RELEASE_READY_YMDT',
          name: 'releaseReadyYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송준비중처리일시',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.value;
          },
        };
        break;
      case 'releaseYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RELEASE_YMDT',
          name: 'releaseYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송중처리일시',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.value;
          },
        };
        break;
      case 'deliveryCompleteYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVER_COMPLETE_YMDT',
          name: 'deliveryCompleteYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송완료처리일시',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.value;
          },
        };
        break;
      case 'buyConfirmYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_BUY_CONFIRM_YMDT',
          name: 'buyConfirmYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '구매확정일시',
          formatter: function(data) {
            if (!data.value) {
              return '-';
            }
            return data.value;
          },
        };
        break;
      case 'adjustedAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ADJUST_AMT',
          name: 'deliveryAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '기본배송비',
          formatter: data => {
            if (data.row.deliveryYn === false) {
              return '-';
            }
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'remoteDeliveryAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_REMOTE_DELIVERY_AMT',
          name: 'remoteDeliveryAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '지역별배송비',
          formatter: data => {
            if (data.row.deliveryYn === false) {
              return '-';
            }
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'totalDeliveryAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TOTAL_DELIVERY_AMT',
          name: 'totalDeliveryAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송비합계',
          formatter: ({ row: { deliveryYn, deliveryAmt, remoteDeliveryAmt } }) => {
            if (deliveryYn === false) {
              return '-';
            }
            return this.getKorCurrency(deliveryAmt + remoteDeliveryAmt);
          },
        };
        break;

      case 'lastPayAmount':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TOTAL_MAIN_AMT',
          name: 'lastPayAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '실결제금액',
          formatter: data => {
            if (data.row.deliveryYn === false) {
              return '-';
            }
            return this.getKorCurrency(data.value - data.row.lastSubPayAmt);
          },
        };
        break;
      case 'drinkBuy': // TODO: 1차 배포 제외
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DRINK_BUY',
          name: 'drinkBuy',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주류구매',
        };
        break;
      case 'memberGradeName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MEMBER_GROUP',
          name: 'memberGradeName',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '회원등급',
          formatter: data => {
            if (data.row.memberGradeName === null) {
              return '-';
            }
            return data.row.memberGradeName;
          },
        };
        break;
      case 'supplyPrice':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_SUPPLY_PRICE',
          name: 'supplyPrice',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '공급가',
        };
        break;
      case 'informationConsent': // TODO: 1차 배포 제외
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_INFORMATION_CONSENT',
          name: 'informationConsent',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '정보동의',
        };
        break;
      case 'exchange':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_EXCHANGE',
          name: 'exchange',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          formatter: data => {
            if (data.value === true) {
              return 'Y';
            } else {
              return 'N';
            }
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: data => {
              if (data === true) {
                return 'Y';
              } else {
                return 'N';
              }
            },
          },
        };
        break;
      case 'orderMemo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_MEMO',
          name: 'orderMemo',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문메모',
        };
        break;
      case 'procBtn':
        orderColumns = {
          header: '',
          align: 'center',
          minWidth: 100,
          name: 'procEachBtn',
          visible,
          order: displayOrder,
          i18n: '결제완료처리, 주문접수처리, 상품준비중처리, 배송준비중처리, 배송중처리, 배송완료처리, 구매확정처리',
          formatter: '',
        };
        if (this.orderListTypes === 'PAY_DONE') {
          // 결제완료
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_PAY_DONE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<button type="button" class="changeStatusEach underline" id="PRODUCT_PREPARE" orderProductOptionNo="${
              data.row.orderProductOptionNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.PRODUCT_PREPARE_BTN')}</button>`;
          };
        } else if (this.orderListTypes === 'PRODUCT_PREPARE') {
          // 상품준비중
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_PRODUCT_PREPARE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<button type="button" class="changeStatusEach underline" id="DELIVERY_PREPARE" orderProductOptionNo="${
              data.row.orderProductOptionNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERY_PREPARE_BTN')}</button>`;
          };
        } else if (this.orderListTypes === 'DELIVERY_PREPARE') {
          // 배송준비중
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DELIVERY_PREPARE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<button type="button" class="changeStatusGroup underline" id="DELIVERY_ING" orderProductOptionNos="${
              data.row.orderProductOptionNoStr
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERING_BTN')}</button>`;
          };
        } else if (this.orderListTypes === 'DELIVERY_ING') {
          // 배송중
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DELIVERING_PROC_BTN';
          orderColumns.formatter = data => {
            return `<button type="button" class="changeStatusGroup underline" id="DELIVERY_DONE" orderProductOptionNos="${
              data.row.orderProductOptionNoStr
            }" orderProductOptionNo="${data.row.orderProductNo}">${window.$t(
              'ORDER.ORDER_GRID_COLUMNS.DELIVERY_DONE_BTN',
            )}</button>`;
          };
        } else if (this.orderListTypes === 'DEPOSIT_WAIT') {
          // 입금대기
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DEPOSIT_WAIT_PROC_BTN';
          orderColumns.formatter = data => {
            return `<button type="button" class="changeStatusGroup underline" id="PAY_DONE" orderProductOptionNos="${
              data.row.orderNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.PAY_DONE_BTN')}</button>`;
          };
        } else if (this.orderListTypes === 'DELIVERY_DONE') {
          // 배송완료
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DELIVERY_DONE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<button type="button" class="changeStatusGroup underline" id="BUY_CONFIRM" orderProductOptionNos="${
              data.row.orderNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.BUY_CONFIRM_BTN')}</button>`;
          };
        }
        break;
      case 'procEachBtn':
        orderColumns = {
          header: '',
          align: 'center',
          minWidth: 100,
          name: 'procEachBtn',
          visible,
          order: displayOrder,
          i18n: '결제완료처리, 주문접수처리, 상품준비중처리, 배송중처리, 배송완료처리, 구매확정처리',
          formatter: '',
        };
        if (this.orderListTypes === 'PAY_DONE') {
          // 결제완료
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_PAY_DONE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<a href="javascript:;" type="button" class="changeStatusEach" id="PRODUCT_PREPARE" orderProductOptionNo="${
              data.row.orderProductOptionNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.PRODUCT_PREPARE_BTN')}</a>`;
          };
        } else if (this.orderListTypes === 'PRODUCT_PREPARE') {
          // 상품준비중
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_PRODUCT_PREPARE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<a href="javascript:;" type="button" class="changeStatusEach" id="DELIVERY_PREPARE" orderProductOptionNo="${
              data.row.orderProductOptionNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERY_PREPARE_BTN')}</a>`;
          };
        } else if (this.orderListTypes === 'DELIVERY_PREPARE') {
          // 배송준비중
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DELIVERY_PREPARE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<a href="javascript:;" type="button" class="changeStatusGroup" id="DELIVERY_ING" orderProductOptionNos="${
              data.row.orderProductOptionNoStr
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.DELIVERING_BTN')}</a>`;
          };
        } else if (this.orderListTypes === 'DELIVERY_ING') {
          // 배송중
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DELIVERING_PROC_BTN';
          orderColumns.formatter = data => {
            return `<a href="javascript:;" type="button" class="changeStatusGroup" id="DELIVERY_DONE" orderProductOptionNos="${
              data.row.orderProductOptionNoStr
            }" orderProductOptionNo="${data.row.orderProductNo}">${window.$t(
              'ORDER.ORDER_GRID_COLUMNS.DELIVERY_DONE_BTN',
            )}</a>`;
          };
        } else if (this.orderListTypes === 'DEPOSIT_WAIT') {
          // 입금대기
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DEPOSIT_WAIT_PROC_BTN';
          orderColumns.formatter = data => {
            return `<a href="javascript:;" type="button" class="changeStatusGroup" id="PAY_DONE" orderProductOptionNos="${
              data.row.orderNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.PAY_DONE_BTN')}</a>`;
          };
        } else if (this.orderListTypes === 'DELIVERY_DONE') {
          // 배송완료
          orderColumns.header = 'ORDER.ORDER_GRID_COLUMNS.HEADER_DELIVERY_DONE_PROC_BTN';
          orderColumns.formatter = data => {
            return `<a href="javascript:;" type="button" class="changeStatusGroup" id="BUY_CONFIRM" orderProductOptionNos="${
              data.row.orderNo
            }">${window.$t('ORDER.ORDER_GRID_COLUMNS.BUY_CONFIRM_BTN')}</a>`;
          };
        }
        break;
      case 'userInputTextStr':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_USER_INPUT_TEXT_STR',
          name: 'userInputTextStr',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '구매자작성형',
        };
        break;
      case 'deliveryMemo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVERY_MEMO',
          name: 'deliveryMemo',
          align: 'center',
          minWidth: 150,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송메모',
        };
        break;
      // hold-delivery
      case 'holdDeliveryYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_HOLD_DELIVERY_YMT',
          name: 'holdDeliveryYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          formatter: function(data) {
            if (data.value == null) {
              return '-';
            }

            return data.value;
          },
        };
        break;
      // holdby-reservation
      case 'reservationStartYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RESERVATION_START_YMT',
          name: 'reservationStartYmdt',
          align: 'center',
          minWidth: 150,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
        };
        break;

      case 'deliveryExternalKey':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVERY_EXTERNAL_KEY',
          name: 'deliveryExternalKey',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          formatter: data => {
            return !data.value ? '-' : data.value;
          },
        };
        break;

      case 'orderOptionNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_OPTION_NO',
          name: 'orderOptionNo',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문상품옵션번호',
          formatter: data => {
            return emptyValueFormatter(data.row.orderProductOptionNo);
          },
        };
        break;

      case 'lastSubPayAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_LAST_SUB_PAY_AMT',
          name: 'lastSubPayAmt',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '적립금사용',
          formatter: ({ value }) => formatCurrency(value),
        };
        break;

      default:
        orderColumns = {
          header: '',
          name: '',
          align: '',
          order: displayOrder,
        };
        break;
    }
    return orderColumns;
  }

  public getOrderPreviousColumns(columnName: string, visible = true, displayOrder: number) {
    let orderColumns;
    switch (columnName) {
      case 'platformType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PLATFORM_TYPE',
          name: 'platformType',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '플랫폼구분',
          formatter: data => {
            if (data.value) {
              if (data.value === 'MOBILE_WEB') {
                return window.$t('ORDER.ORDER_GRID_COLUMNS.PLATFORM_TYPE_MOBILE_WEB');
              } else if (data.value === 'MOBILE_APP') {
                return window.$t('ORDER.ORDER_GRID_COLUMNS.PLATFORM_TYPE_MOBILE_APP');
              } else {
                return data.value;
              }
            }
            return '-';
          },
        };
        break;
      case 'mallNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MALLNO',
          name: 'mallNo',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '쇼핑몰구분',
          formatter: data => {
            return this.getMallName(data.value);
          },
        };
        break;
      case 'payType':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PAY_TYPE',
          align: 'center',
          minWidth: 80,
          name: 'payType',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '결제수단',
          formatter: (data): string => {
            return PAY_TYPE[data.row.payType];
          },
        };
        break;
      case 'orderNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_NO',
          align: 'center',
          minWidth: 160,
          name: 'orderNo',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문번호',
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        if (this.orderListTypes !== 'OrderPayFail') {
          // 결제실패
          orderColumns.formatter = data => {
            const naverPayDiv =
              data.row.payType === 'NAVER_PAY'
                ? `<div> (<span style="color: #03CF5D">N</span>: ${data.row?.externalOrderNo ?? '-'})</div>`
                : '';
            if (data.row.orderStatusType === 'PAY_CANCEL' || data.row.orderStatusType === 'PAY_FAIL') {
              return data.value + naverPayDiv;
            }
            const hold = data.row.hold;
            let className = '';
            if (hold === true) {
              className = 'hold';
            }
            return `<a href="javascript:;" class=${className}>${data.value}</a> ${naverPayDiv}`;
          };
        }
        break;
      case 'ordererName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_NAME',
          align: 'center',
          minWidth: 80,
          name: 'ordererName',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문자명',
          formatter: data => {
            if (data.row.memberStatus === 'WITHDRAWN') {
              return window.$t('ORDER.ORDER_GRID_COLUMNS.WITHDRAWAL_MEMBER');
            }
            const memberNo = data.row.memberNo || 0;
            return memberNo === 0 ? data.value : getAnchorHTML(data.value);
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'ordererContact1': // 이전 주문 리스트
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_CONTACT1',
          align: 'center',
          minWidth: 100,
          name: 'orderContact',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문자연락처',
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'memberNo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MEMBER_NO',
          align: 'center',
          minWidth: 80,
          name: 'memberNo',
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '회원번호',
          formatter: data => {
            if (data.value === 0) {
              return '-';
            }
            return data.value;
          },
          copyOptions: {
            useFormattedValue: true,
            customValue: function(data) {
              if (data === 0) {
                return '-';
              }
              return data;
            },
          },
        };
        break;
      case 'productName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PRODUCT_NAME',
          name: 'productName',
          align: 'left',
          minWidth: 200,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품명',
          copyOptions: {
            useFormattedValue: true,
            customValue: value => value,
          },
        };
        break;
      case 'buyPrice':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_BUY_PRICE',
          name: 'buyPrice',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'totalBuyAmt': // 이전 주문 리스트
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TOTAL_BUY_AMT',
          name: 'salePrice',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '상품합계',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'orderAmt': // 이전 주문 리스트
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_AMT',
          name: 'purchasePrice',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문금액',
          formatter: data => {
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'receiverName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_NAME',
          name: 'receiverName',
          align: 'center',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '수령자명',
        };
        break;
      case 'receiverContact1': // 이전 주문 리스트
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_CONTACT1',
          name: 'receiverContact',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '수령자연락처',
        };
        break;
      case 'receiverZipCd':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_RECEIVER_ZIP_CD',
          name: 'receiverZipCd',
          align: 'center',
          minWidth: 60,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '우편번호',
        };
        break;
      case 'address': // 이전 주문 리스트
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ADDRESS',
          name: 'receiverAddress',
          align: 'left',
          minWidth: 200,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주소',
        };
        break;
      case 'customsIdNumber':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_CUSTOMS_ID_NUMBER',
          name: 'customsIdNumber',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '개인통관고유부호',
        };
        break;
      case 'orderYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_YMDT',
          name: 'orderYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문일시',
        };
        break;
      case 'payYmdt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_PAY_YMDT',
          name: 'payYmdt',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '결제일시',
          formatter: data => {
            if (!data.value) {
              return '-';
            }
            return data.value;
          },
        };
        break;
      case 'totalDeliveryAmt': // 이전 주문 리스트
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TOTAL_DELIVERY_AMT',
          name: 'deliveryAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송비합계',
          formatter: ({ row: { deliveryAmt } }) => {
            return this.getKorCurrency(deliveryAmt);
          },
        };
        break;
      case 'lastMainPayAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TOTAL_MAIN_AMT',
          name: 'mainPayAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '실결제금액',
          formatter: data => {
            if (data.row.deliveryYn === false) {
              return '-';
            }
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'lastPayAmt':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_TOTAL_MAIN_AMT',
          name: 'mainPayAmt',
          align: 'right',
          minWidth: 80,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '실결제금액',
          formatter: data => {
            if (data.row.deliveryYn === false) {
              return '-';
            }
            return this.getKorCurrency(data.value);
          },
        };
        break;
      case 'memberGradeName':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_MEMBER_GROUP',
          name: 'memberGradeName',
          align: 'center',
          minWidth: 130,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '회원등급',
          formatter: data => {
            return data.row.memberGradeNames?.length > 0 ? data.row.memberGradeNames.join(',') : '-';
          },
        };
        break;
      case 'orderMemo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_ORDER_MEMO',
          name: 'orderMemo',
          align: 'center',
          minWidth: 100,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '주문메모',
        };
        break;
      case 'deliveryMemo':
        orderColumns = {
          header: 'ORDER.LIST_GRIDS.HEADER_DELIVERY_MEMO',
          name: 'deliveryMemo',
          align: 'center',
          minWidth: 150,
          hidden: this.gridHidden(visible),
          visible,
          order: displayOrder,
          i18n: '배송메모',
        };
        break;

      default:
        orderColumns = {
          header: '',
          name: '',
          align: '',
          order: displayOrder,
        };
        break;
    }
    return orderColumns;
  }

  public mergeRow(
    arr,
    bigRow,
    midRow,
    smallRow,
    bigGroupFlag: string,
    middleGroupFlag: string,
    smallGroupFlag: string,
  ) {
    if (arr && arr.length === 0) {
      return;
    }
    const bigGroup = [];
    const newArry = [];
    arr.forEach((item): void => {
      if (bigGroup.indexOf(item[bigGroupFlag]) < 0) {
        bigGroup.push(item[bigGroupFlag]);
      }
    });
    bigGroup.forEach((bigItem): void => {
      const nums = arr.filter(item => {
        return bigItem === item[bigGroupFlag];
      });

      let firstRow = true;
      arr.forEach((item): void => {
        if (bigItem === item[bigGroupFlag]) {
          if (firstRow && nums.length > 1) {
            const rowSpanTag = bigRow;
            this.addRowSpan(rowSpanTag, item, nums.length);
            firstRow = false;
          }
          newArry.push(item);
        }
      });
      const middleGroup = [];
      nums.forEach((numItem): void => {
        if (middleGroup.indexOf(numItem[middleGroupFlag]) < 0) {
          middleGroup.push(numItem[middleGroupFlag]);
        }
      });
      middleGroup.forEach(smallItem => {
        const orderNums = nums.filter(item1 => {
          return smallItem === item1[middleGroupFlag];
        });
        let orderFirstRow = true;
        newArry.map(newItem => {
          if (smallItem === newItem[middleGroupFlag] && newItem[bigGroupFlag] === bigItem && orderFirstRow) {
            const rowSpanTag = midRow;
            this.addRowSpan(rowSpanTag, newItem, orderNums.length);
            orderFirstRow = false;
          }
        });
        const smallGroup = [];
        orderNums.forEach(sItem => {
          if (smallGroup.indexOf(sItem[smallGroupFlag]) < 0) {
            smallGroup.push(sItem[smallGroupFlag]);
          }
        });
        smallGroup.forEach(smItem => {
          const length = orderNums.filter(item2 => {
            return smItem === item2[smallGroupFlag];
          }).length;
          const smallFirstRow = true;
          newArry.map(smNewItem => {
            if (smItem === smNewItem[smallGroupFlag] && smNewItem[middleGroupFlag] === smallItem && smallFirstRow) {
              const rowSpanTag = smallRow;
              this.addRowSpan(rowSpanTag, smNewItem, length);
              orderFirstRow = false;
            }
          });
        });
      });
    });
    return newArry;
  }

  public addRowSpan(arr, tag, length: number): void {
    if (arr.length === 0) {
      return;
    }
    arr.forEach(items => {
      if (length > 1) {
        this.$set(tag['_attributes']['rowSpan'], items, length);
      }
    });
  }

  public formatterList(arr, key1, key2, key3) {
    const arry = [];
    if (arr && arr.length > 0) {
      arr.forEach(item => {
        item[key1].forEach(item1 => {
          item1[key2].forEach(item2 => {
            item2[key3].forEach(item3 => {
              const obj = {};
              Object.keys(item).forEach(name => {
                if (name !== key1) {
                  obj[name] = item[name];
                }
              });
              Object.keys(item1).forEach(name1 => {
                if (name1 !== key2) {
                  obj[name1] = item1[name1];
                }
              });
              Object.keys(item2).forEach(name2 => {
                if (name2 !== key3) {
                  obj[name2] = item2[name2];
                }
              });
              Object.keys(item3).forEach(name3 => {
                obj[name3] = item3[name3];
              });
              this.$set(obj, '_attributes', {});
              this.$set(obj['_attributes'], 'rowSpan', {});
              arry.push(obj);
            });
          });
        });
      });
      return arry;
    }
  }

  public getDisabled(name: string): boolean {
    const routeName = this.getRouteName(this.orderListTypes);
    return routeName.includes(name);
  }

  public getRouteName(name): string[] {
    switch (name) {
      case 'OrderList':
      case 'OrderDepositWait.vue':
        return ['platformType'];

      case 'OrderHoldDelivery':
        return ['holdDeliveryYmdt'];

      case 'OrderHoldByReservation':
        return ['reservationStartYmdt'];

      default:
        return [''];
    }
  }

  public isEmpty(value): boolean {
    return value === null || value === undefined || value === '';
  }
}
