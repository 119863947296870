import { Component, Vue } from 'vue-property-decorator';
import ORDER_DETAIL from '@/const/i18n/kr/order/ORDER_DETAIL';

@Component
export default class FilterDeletedUser extends Vue {
  public filterDeletedUser<T>(user: T, key: string, chageVal: string): T {
    const cloneUser = { ...user };
    const getAdminsServiceByAdminNo = async (no): Promise<boolean> => {
      return await this.$api
        .getAdminsServiceByAdminNo({ params: { adminNo: no } })
        .then(() => true)
        .catch(() => false);
    };

    getAdminsServiceByAdminNo(cloneUser[key]).then(res => {
      if (!res) cloneUser[chageVal] = this.$t(ORDER_DETAIL.DELETED_USER);
    });

    return cloneUser;
  }
}
