export default Object.freeze({
  // 정리 예정
  DETAIL_ONE: 'ⓘ 광고성/정보성 e-mail을 발송할 수 있습니다.',
  DETAIL_TWO:
    'Ⓘ 광고성 e-mail 발송은 수신자가 수신거부를 선택할 수 있도록 수신거부 기능을 제공해야하여, e-mail 유형을 꼭 광고성으로 체크해주세요.',
  DETAIL_THREE_ONE: 'Ⓘ 광고성 e-mail 발송 시 상세 유의사항은',
  DETAIL_THREE_LINK: '[여기]',
  DETAIL_THREE_TWO: '확인 가능합니다.',
  DETAIL_FOUR_ONE: 'Ⓘ e-mail 발송/사용내역은',
  DETAIL_FOUR_LINK: 'TOAST Cloud',
  DETAIL_FOUR_TWO: '에서 확인할 수 있습니다.',
  POPUP_TITLE: '광고메일 전송 시 유의사항',
  POPUP_1: '정보통신망법에 따라',
  POPUP_1_A: '(한국인터넷진흥원 관련 내용 확인)',
  POPUP_1_2: '상업성 광고메일이나 업체 홍보 메일을 발송하는 경우, 아래 사항을 준수해야 합니다.',
  POPUP_2:
    '1. 광고성 메일은 명시적으로 수신 동의를 한 수신자에게만 발송해야 합니다. 이를 위반하여 분쟁이 발생하는 경우 책임은 광고메일 발송자에게 있습니다.',
  POPUP_3: '2. 제목이 시작되는 부분에 (광고) 문구를 표시해야 합니다.',
  POPUP_4: '3. 본문에 전송자의 명칭, 전자우편주소,전화번호 및 주소를 포함하는 발송자 정보를 표시해야 합니다.',
  POPUP_5:
    '4. 본문에 수신자가 수신 거부 또는 수신 동의 철회 의사를 쉽게 표시할 수 있도록 하기 위한 안내문을 명시하고, 수신 거부 또는 수신 동의 철회 여부를 간편하게 선택할 수 있도록 기술적 조치를 해야 합니다. 이 경우 그 안내문과 기술적 조치는 한글과 영문으로 명시해야 합니다.',
  POPUP_6: "NCP에서는 정보통신망법을 준수할 수 있도록, '광고메일'에 대해 아래와 같은 기술적 조치를 제공합니다. ",
  POPUP_7: '· 제목에 (광고) 문구를 삽입합니다.',
  POPUP_8: '· 수신자가 수신 거부를 선택할 수 있도록 한글과 영문 형태로 수신 거부 기능을 제공합니다.',
  POPUP_9: '· 수신 거부 대상 메일 주소에는 광고메일을 발송하지 않습니다.',
  TR_1: 'e-mail유형',
  TR_2: '템플릿 설정',
  TR_3: '발송 e메일',
  TR_4: '수신 대상 회원 선택',
  TR_5: '발송 설정',
  TR_6: '제목',
  TR_7: '내용',
  TR_8: '수신거부 문구',
  TEMPLATE_INUSE: '템플릿 선택',
  DETAIL_EMAIL: '발송 e메일 수정을 원하실 경우, 서비스 관리 > 쇼핑몰 관리에서 발송전용e메일을 수정해 주세요.',
  RECIPIENT_ALL: '전체 회원',
  RECIPIENT_SELECT_MEMBER: '회원 선택',
  MEMBER_SELECT_BTN: '회원등급/그룹 선택',
  MEMBER_SEARCH_BTN: '회원 조회',
  UPLOAD_FILE: '첨부파일',
  DESCRIPTION_1_1: '총',
  DESCRIPTION_1_2: '명',
  DESCRIPTION_1_3: '회원 선택 되었습니다. (중복 회원 제외)',
  DESCRIPTION_2: '광고성인 경우, 광고성 수신 미동의 회원은 발송 시 제외됩니다.',
  DESCRIPTION_3: '휴면회원 및 탈퇴회원은 수신 대상에서 제외됩니다.',
  DESCRIPTION_4: '예약발송인 경우 설정일과 발송일 수신 대상 회원이 일치하지 않을 수 있습니다.',
  DESCRIPTION_5: '광고성 메일의 경우, 수신자가 메일을 수신 거부 할 수 있는 기능이 메일 하단에 아래와 같이 추가 됩니다.',
  DESCRIPTION_6: '메일 수신을 원치 않으시면 [수신거부]를 클릭하세요.',
  DESCRIPTION_7: 'If you do not want to receive it, please click a [Unsubscription]',
  SENDIMM: '즉시 발송',
  SENDBACK: '예약 발송',
  TITLE_AD: '(광고)',
  TITLE_PLACEHOLDER: '제목을 입력해 주세요.',
  TITLE_PREVIEW: 'e-mail 발송내용 미리보기',
  BTN_SAVE: '보내기',
  BTN_PREVIEW: '미리보기',
  BTN_RESET: '초기화',
  POPUP_TEMPLATE_SELECTOR: '템플릿 선택',
  POPUP_MEMBER_SELECT: '회원등급/그룹 선택 팝업',
  POPUP_MEMBER_SEARCH: '회원 조회 팝업',
  SUCCESS_SEND: 'e-mail 발송요청에 성공했습니다.',
  ERR_APP_KEY: '쇼핑몰 수정에서 e-mail Appkey를 등록해 주셔야 발송이 가능합니다.',
  ERR_RECIPIENT_COUNT: '수신대상 회원을 선택해 주세요.',
  ERR_TIME: '10분 이후로 지정해 주세요',
});
