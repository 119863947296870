


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { RadioBoxGroupOption } from '@/helpers/type';
import TextInput from '@/components/common/input/TextInput.vue';
import { i18nForSecurity } from '@/const/contents/configuration/securityServer';
import { goToExternal } from '@/views/contents/configuration/security/security';
import { PAGE_TYPE } from '@/const/configuration';
import { SealSettingData } from '@/types/securityServer';

@Component({ components: { RadioGroup, TextInput } })
export default class SealSetting extends Vue {
  @Prop({ required: true })
  private data: SealSettingData;
  @Prop()
  private onPositiveClick: (data: SealSettingData) => void;
  @Prop()
  private onNegativeClick: () => void;

  private displaysTrustSeal: boolean = this.data.displaysTrustSeal;
  private trustSeal: string = this.data.trustSeal;
  private i18nForSecurity = i18nForSecurity;

  private usedOption: RadioBoxGroupOption<boolean> = {
    name: 'radio-ssl-seal',
    data: [
      { id: 'use-ssl-seal', value: true, display: this.i18nForSecurity('SSL_USE') },
      { id: 'not-use-ssl-seal', value: false, display: this.i18nForSecurity('SSL_NOT_USE') },
    ],
  };

  private goToExternal() {
    goToExternal(PAGE_TYPE.SECURE, this.data.encodedSon);
  }

  private save(): void {
    if (this.displaysTrustSeal && !this.trustSeal) {
      alert(this.i18nForSecurity('ALERT_INSERT_SCRIPT'));
      return;
    }

    alert(this.i18nForSecurity('ALERT_CHANGED_SCRIPT'));

    this.onPositiveClick({ displaysTrustSeal: this.displaysTrustSeal, trustSeal: this.trustSeal });
  }
}
