

















import { Vue, Component, Prop } from 'vue-property-decorator';
import Tree from '@/components/common/tree/Tree.vue';
import nodeElements from '@/components/product/management/standardFee/tree/nodeElements';
import { NestedTreeNode, TreeOptions, ValidationHook } from '@/types/tree';
import { Getter } from 'vuex-class';

@Component({
  components: {
    Tree,
  },
})
export default class TreeContainer extends Vue {
  @Prop({ required: true })
  private readonly treeNodes: NestedTreeNode[];

  @Getter('hasUnsavedForm')
  private readonly hasUnsavedForm!: boolean;

  private readonly nodeElements = nodeElements;

  private readonly options: TreeOptions = {
    internalNodeName: this.$t('CATEGORY').toString(),
    leafNodeName: this.$t('CATEGORY').toString(),
    customKeywords: {
      nodeName: this.$t('PRODUCT.CATEGORIZATION.NEW').toString(),
      nodeLabel: this.$t('PRODUCT.CATEGORIZATION.NAME').toString(),
    },
  };

  private readonly validationHook: ValidationHook = {
    changeNode: this.beforeChangeNodeValidation,
  };

  private beforeChangeNodeValidation(_, { prevNodeData }): boolean {
    if (prevNodeData === null) return true;
    const { commissionRate, isLeaf } = prevNodeData;
    if (commissionRate === '' && isLeaf && this.hasUnsavedForm) {
      const accept = confirm(this.$t('PRODUCT.COMMISSION.CONFIRM').toString());
      if (accept) {
        this.$emit('submit');
      }

      return false;
    }

    return true;
  }
}
