import store from '@/store';
import { mall } from '@/utils/mall';
import { addMonth, getToday } from '@/utils/dateFormat';
import { productNameConvertor } from '@/utils/common';
import { formatCurrency } from '@/utils/numberFormat';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { Mall } from 'ncp-api-supporter';
import {
  CouponListSearchQuery,
  CouponDetailInformation,
  CouponListTopQuery,
  CouponCodeManagementQuery,
  CouponIssuanceUseQuery,
} from '@/types';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import moment from 'moment';
import { i18n } from '@/main';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';

// coupon detail
export const defaultCouponDetailInfo = (): CouponDetailInformation => {
  return {
    pageType: 'AddCoupon',
    basicInfo: {
      mallNo: '',
      couponName: '',
      reason: '',
    },
    issuanceInfo: {
      issueType: 'DOWNLOAD',
      issueLimitCnt: '',
      usesDailyIssueLimit: false,
      dailyIssueLimitCnt: '',
      usesPersonalIssueLimit: false,
      issuePerPersonLimitCheck: true,
      issuePerPersonLimitCnt: '',
      dailyIssuePerPersonLimitCheck: false,
      dailyIssuePerPersonLimitCnt: '',
      issueStartYmd: '',
      issueEndYmd: '',
      issueStartHms: DEFAULT_TIME_RANGE.BLANK_START,
      issueEndHms: DEFAULT_TIME_RANGE.BLANK_END,
      allDaysOfWeek: true,
      possibleDaysOfWeek: '1,2,3,4,5,6,7',
      issueAllHour: true,
      issueStartHour: '00',
      issueEndHour: '24',
      memberGradeNos: '',
      memberGradeList: [],
      allPlatformTypes: true,
      allMemberGradeNoTypes: true,
      issuablePlatformTypes: 'PC,MOBILE_WEB',
      codeType: 'DESIGNATE',
      promotionCode: '',
      couponCodeLength: 8,
    },
    amountInfo: {
      couponType: 'PRODUCT',
      discountAmt: '',
      fixed: true,
      discountPercent: '',
      maxDiscountAmt: '',
      usesMinLimit: true,
      minSalePrice: '',
      serviceSharePercent: 100,
      partnerSharePercent: 0,
      skipsAccumulation: false,
      notUsableOtherCoupon: false,
      notUsableCartCoupon: false,
      cartSkipsAccumulation: false,
    },
    targetInfo: {
      couponTargetType: 'ALL_PRODUCT',
      excludeTarget: false,
      selectedCategories: [],
      selectedProduct: [],
      excludeProduct: [],
    },
    useInfo: {
      allUsablePlatformTypes: true,
      usablePlatformTypes: 'PC,MOBILE_WEB',
      usePeriodType: 'useDays',
      useDays: -1,
      useEndYmdt: '',
    },
  };
};
export const setCouponDetailInfo = (oldData, pageType?: string): CouponDetailInformation => {
  const detailInfo = defaultCouponDetailInfo();
  detailInfo.pageType = pageType;

  // 쿠폰 수정
  if (pageType === 'ModifyCoupon') {
    // 쿠폰 수정 CASE
    // 쿠폰의 발급수가 0건이고, 쿠폰이 예약지급 flag값을 가지지 않은 상태
    if (oldData.couponSummary.totalIssueCnt === 0 && !oldData.isScheduledIssuePlan) {
      detailInfo.modifyType = 'NO_ISSUED';
    }
    // 쿠폰 발급건수가 1건 이상이거나, 쿠폰이 예약지급 flag 값을 가지고 있는 경우
    if (oldData.couponSummary.totalIssueCnt > 0 || oldData.isScheduledIssuePlan) {
      detailInfo.modifyType = 'HAVE_ISSUED';
      detailInfo.modifyDisabled = true;
    }
    // 쿠폰의 발급상태가 발급종료인 경우
    if (oldData.statusType === 'ISSUE_END') {
      detailInfo.modifyType = 'ISSUE_END';
      detailInfo.modifyDisabled = true;
    }

    detailInfo.basicInfo.couponName = oldData.couponName;
    detailInfo.basicInfo.reason = oldData.reason;
    const malls = store.getters['mall/getMalls'];
    const mallName = malls.find((mall: Mall) => mall.mallNo === oldData.mallNo).mallName;
    detailInfo.basicInfo.mallName = mallName;
    detailInfo.basicInfo.statusType = oldData.statusType;
    detailInfo.basicInfo.statusTypeName = i18n.t(`PROMOTION.COUPON.STATUS_TYPE_${detailInfo.basicInfo.statusType}`);
    detailInfo.basicInfo.isScheduledIssuePlan = oldData.isScheduledIssuePlan;
    detailInfo.basicInfo.totalUseCnt = oldData.couponSummary.totalUseCnt;
    detailInfo.basicInfo.totalIssueCnt = oldData.couponSummary.totalIssueCnt;
    detailInfo.issueStopped = oldData.issuableConstraint.issueStopped;
    detailInfo.issuanceInfo.issueStartYmd = moment(oldData.issuableConstraint.issueStartYmdt).format('YYYY-MM-DD');
    detailInfo.issuanceInfo.issueEndYmd = moment(oldData.issuableConstraint.issueEndYmdt).format('YYYY-MM-DD');
    detailInfo.issuanceInfo.issueStartHms = moment(oldData.issuableConstraint.issueStartYmdt).format('HH:mm:ss');
    detailInfo.issuanceInfo.issueEndHms = moment(oldData.issuableConstraint.issueEndYmdt).format('HH:mm:ss');
  }

  // 기본정보
  detailInfo.basicInfo.mallNo = oldData.mallNo.toString();

  // 발급정보
  if (
    oldData.issuableConstraint.issueType === 'CODE_DESIGNATE' ||
    oldData.issuableConstraint.issueType === 'CODE_RANDOM'
  ) {
    detailInfo.issuanceInfo.issueType = 'CODE';
    detailInfo.issuanceInfo.codeType = oldData.issuableConstraint.issueType.split('_')[1];
    detailInfo.issuanceInfo.issueTypeName = i18n.t('PROMOTION.COUPON.ISSUE_TYPE_CODE');
    detailInfo.issuanceInfo.codeTypeName = i18n.t(
      detailInfo.issuanceInfo.codeType === 'DESIGNATE'
        ? 'PROMOTION.COUPON.CODE_TYPE_DESIGNATE'
        : 'PROMOTION.COUPON.CODE_TYPE_RANDOM',
    );
  } else {
    detailInfo.issuanceInfo.issueType = oldData.issuableConstraint.issueType;
    detailInfo.issuanceInfo.issueTypeName = i18n.t(
      detailInfo.issuanceInfo.issueType === 'DOWNLOAD'
        ? 'PROMOTION.COUPON.ISSUE_TYPE_DOWNLOAD'
        : 'PROMOTION.COUPON.ISSUE_TYPE_INSERT',
    );
  }
  detailInfo.issuanceInfo.isPeriodEnd = oldData.issuableConstraint?.isPeriodEnd;
  detailInfo.issuanceInfo.issueLimitCnt = oldData.issuableConstraint.issueLimitCnt.toString();
  if (oldData.issuableConstraint.issueType === 'CODE_DESIGNATE' && pageType === 'ModifyCoupon') {
    detailInfo.issuanceInfo.promotionCode = oldData.issuableConstraint.promotionCode;
  }
  if (oldData.issuableConstraint.issueType === 'CODE_RANDOM') {
    detailInfo.issuanceInfo.couponCodeLength = oldData.issuableConstraint.couponCodeLength;
  }
  if (detailInfo.issuanceInfo.issueType !== 'INSERT') {
    detailInfo.issuanceInfo.usesPersonalIssueLimit =
      Number(oldData.issuableConstraint.issuePerPersonLimitCnt) > 0 ||
      Number(oldData.issuableConstraint.dailyIssuePerPersonLimitCnt) > 0;
    if (detailInfo.issuanceInfo.usesPersonalIssueLimit) {
      if (oldData.issuableConstraint.issueType === 'CODE_RANDOM') {
        detailInfo.issuanceInfo.issuePerPersonLimitCnt = oldData.issuableConstraint.issuePerPersonLimitCnt.toString();
      } else {
        detailInfo.issuanceInfo.issuePerPersonLimitCheck =
          Number(oldData.issuableConstraint.issuePerPersonLimitCnt) > 0;
        if (detailInfo.issuanceInfo.issuePerPersonLimitCheck) {
          detailInfo.issuanceInfo.issuePerPersonLimitCnt = oldData.issuableConstraint.issuePerPersonLimitCnt.toString();
        }
        detailInfo.issuanceInfo.dailyIssuePerPersonLimitCheck =
          Number(oldData.issuableConstraint.dailyIssuePerPersonLimitCnt) > 0;
        if (detailInfo.issuanceInfo.dailyIssuePerPersonLimitCheck) {
          detailInfo.issuanceInfo.dailyIssuePerPersonLimitCnt = oldData.issuableConstraint.dailyIssuePerPersonLimitCnt.toString();
        }
      }
    }
    // 발급대상 회원등급
    // detailInfo.issuanceInfo.memberGradeNo = oldData.issuableConstraint.memberGradeNo;
    if (oldData.issuableConstraint.memberGradeNos[0] === 0) {
      detailInfo.issuanceInfo.allMemberGradeNoTypes = true;
    } else {
      detailInfo.issuanceInfo.allMemberGradeNoTypes = false;
      detailInfo.issuanceInfo.memberGradeNos = oldData.issuableConstraint.memberGradeNos.toString();
    }
  }
  if (
    oldData.issuableConstraint.issueType === 'DOWNLOAD' ||
    oldData.issuableConstraint.issueType === 'CODE_DESIGNATE'
  ) {
    detailInfo.issuanceInfo.usesDailyIssueLimit = Number(oldData.issuableConstraint.dailyIssueLimitCnt) > 0;
    if (detailInfo.issuanceInfo.usesDailyIssueLimit) {
      detailInfo.issuanceInfo.dailyIssueLimitCnt = oldData.issuableConstraint.dailyIssueLimitCnt.toString();
    }
    detailInfo.issuanceInfo.allDaysOfWeek = oldData.issuableConstraint.possibleDaysOfWeek.length === 7;
    detailInfo.issuanceInfo.possibleDaysOfWeek = oldData.issuableConstraint.possibleDaysOfWeek.join(',');
    detailInfo.issuanceInfo.issueAllHour =
      oldData.issuableConstraint.issueStartHour === 0 && oldData.issuableConstraint.issueEndHour === 24;
    if (!detailInfo.issuanceInfo.issueAllHour) {
      detailInfo.issuanceInfo.issueStartHour =
        oldData.issuableConstraint.issueStartHour > 9
          ? oldData.issuableConstraint.issueStartHour.toString()
          : `0${oldData.issuableConstraint.issueStartHour}`;
      detailInfo.issuanceInfo.issueEndHour =
        oldData.issuableConstraint.issueEndHour > 9
          ? oldData.issuableConstraint.issueEndHour.toString()
          : `0${oldData.issuableConstraint.issueEndHour}`;
    }
    detailInfo.issuanceInfo.allPlatformTypes = oldData.issuableConstraint.issuablePlatformTypes.length === 2;
    detailInfo.issuanceInfo.issuablePlatformTypes = oldData.issuableConstraint.issuablePlatformTypes.join(',');
  }

  // 금액설정
  detailInfo.amountInfo.couponType = oldData.couponType;
  detailInfo.amountInfo.couponTypeName = i18n.t(
    detailInfo.amountInfo.couponType === 'PRODUCT'
      ? 'PROMOTION.COUPON.COUPON_TYPE_PRODUCT'
      : 'PROMOTION.COUPON.COUPON_TYPE_CART',
  );
  detailInfo.amountInfo.fixed = oldData.discount.fixed;
  if (oldData.discount.fixed) {
    detailInfo.amountInfo.discountAmt = oldData.discount.discountAmt.toString();
  } else {
    detailInfo.amountInfo.discountPercent = oldData.discount.discountPercent.toString();
    detailInfo.amountInfo.maxDiscountAmt = oldData.discount.maxDiscountAmt.toString();
  }
  detailInfo.amountInfo.usesMinLimit = oldData.discount.usesMinLimit;
  if (oldData.discount.usesMinLimit) {
    detailInfo.amountInfo.minSalePrice = oldData.discount.minSalePrice.toString();
  }
  detailInfo.amountInfo.serviceSharePercent = oldData.productCouponDetail?.serviceSharePercent;
  detailInfo.amountInfo.partnerSharePercent = 100 - oldData.productCouponDetail?.serviceSharePercent;
  if (oldData.couponType === 'PRODUCT') {
    detailInfo.amountInfo.skipsAccumulation = oldData.productCouponDetail.skipsAccumulation;
    detailInfo.amountInfo.notUsableCartCoupon = !oldData.productCouponDetail.usableCartCoupon;
  } else {
    detailInfo.amountInfo.notUsableOtherCoupon = !oldData.cartCouponDetail.usableOtherCoupon;
    detailInfo.amountInfo.cartSkipsAccumulation = oldData.cartCouponDetail.skipsAccumulation;
  }

  // 대상설정
  if (oldData.couponType === 'PRODUCT') {
    detailInfo.targetInfo.couponTargetType = oldData.productCouponDetail.couponTargetType;
    if (oldData.productCouponDetail.couponTargetType === 'CATEGORY') {
      detailInfo.targetInfo.selectedCategories = oldData.productCouponDetail.couponTargets.map(item => {
        return {
          id: item.targetNo,
          name: item.targetName,
        };
      });
    }
    if (oldData.productCouponDetail.couponTargetType === 'PRODUCT') {
      detailInfo.targetInfo.oldTargets = oldData.productCouponDetail.couponTargets;
    } else {
      detailInfo.targetInfo.oldTargets = oldData.productCouponDetail.couponExcludeTargets;
    }
    detailInfo.targetInfo.excludeTarget = oldData.productCouponDetail.excludesTarget;
  }

  // 사용설정
  detailInfo.useInfo.allUsablePlatformTypes = oldData.usableConstraint.usablePlatformTypes.length === 2;
  detailInfo.useInfo.usablePlatformTypes = oldData.usableConstraint.usablePlatformTypes.join(',');
  detailInfo.useInfo.useDays = oldData.usableConstraint.useDays;
  detailInfo.useInfo.usePeriodType = oldData.usableConstraint.useDays === -1 ? 'useEndYmdt' : 'useDays';
  if (oldData.usableConstraint.useDays === -1) {
    if (moment(new Date()).format('YYYY-MM-DD HH:mm:ss') > oldData.usableConstraint.useEndYmdt) {
      detailInfo.useInfo.useEndYmdt = getToday() + ' ' + DEFAULT_TIME_RANGE.END;
    } else {
      detailInfo.useInfo.useEndYmdt = oldData.usableConstraint.useEndYmdt;
    }
  }

  return detailInfo;
};

export const RadioCouponIssueType = {
  name: 'radio-issueType',
  data: [
    {
      id: 'issueType_DOWNLOAD',
      value: 'DOWNLOAD',
      display: 'PROMOTION.COUPON.ISSUE_TYPE_DOWNLOAD',
    },
    {
      id: 'issueType_CODE',
      value: 'CODE',
      display: 'PROMOTION.COUPON.ISSUE_TYPE_CODE',
    },
    {
      id: 'issueType_INSERT',
      value: 'INSERT',
      display: 'PROMOTION.COUPON.ISSUE_TYPE_INSERT',
    },
  ],
};
export const RadioCouponDailyIssueLimits = {
  name: 'radio-dailyIssueLimit',
  data: [
    {
      id: 'dailyIssueLimit_N',
      value: false,
      display: 'PROMOTION.COUPON.LIMIT_N',
    },
    {
      id: 'dailyIssueLimit_Y',
      value: true,
      display: 'PROMOTION.COUPON.LIMIT_Y',
    },
  ],
};
export const RadioCouponPerPersonLimits = {
  name: 'radio-perPersonLimit',
  data: [
    {
      id: 'perPersonLimit_N',
      value: false,
      display: 'PROMOTION.COUPON.LIMIT_N',
    },
    {
      id: 'perPersonLimit_Y',
      value: true,
      display: 'PROMOTION.COUPON.LIMIT_Y',
    },
  ],
};
export const RadioCouponAllDaysOfWeek = {
  name: 'radio-allDaysOfWeek',
  data: [
    {
      id: 'allDaysOfWeek_Y',
      value: true,
      display: 'ALL',
    },
    {
      id: 'allDaysOfWeek_N',
      value: false,
      display: 'SETTING',
    },
  ],
};
export const CheckboxCouponWeeks = {
  name: 'weeks',
  hasAll: false,
  noBrackets: false,
  type: 'checkbox',
  data: [
    {
      id: 'MON',
      value: 1,
      display: 'MON',
    },
    {
      id: 'TUE',
      value: 2,
      display: 'TUE',
    },
    {
      id: 'WED',
      value: 3,
      display: 'WED',
    },
    {
      id: 'THU',
      value: 4,
      display: 'THU',
    },
    {
      id: 'FRI',
      value: 5,
      display: 'FRI',
    },
    {
      id: 'SAT',
      value: 6,
      display: 'SAT',
    },
    {
      id: 'SUN',
      value: 7,
      display: 'SUN',
    },
  ],
};
export const RadioCouponIssueAllHours = {
  name: 'radio-issueAllHour',
  data: [
    {
      id: 'issueAllHour_Y',
      value: true,
      display: 'ALL',
    },
    {
      id: 'issueAllHour_N',
      value: false,
      display: 'SETTING',
    },
  ],
};
export const SelectCouponIssueEndHour = () => {
  const issueEndHours = [];
  for (let i = 24; i > 0; i--) {
    const tempH = i < 10 ? '0' + i : i.toString();
    issueEndHours.push({
      name: tempH,
      value: tempH,
    });
  }
  return issueEndHours;
};
export const RadioCouponAllPlatformTypes = {
  name: 'radio-allPlatformTypes',
  data: [
    {
      id: 'allPlatformTypes_Y',
      value: true,
      display: 'ALL',
    },
    {
      id: 'allPlatformTypes_N',
      value: false,
      display: 'SETTING',
    },
  ],
};
export const RadioCouponAllMemberGradeNosTypes = {
  name: 'radio-allMemberGradeNosTypes',
  data: [
    {
      id: 'allMemberGradeNosTypes_Y',
      value: true,
      display: 'ALL',
    },
    {
      id: 'allMemberGradeNosTypes_N',
      value: false,
      display: 'PROMOTION.COUPON.CHOOSE_GRADE',
    },
  ],
};
export const CheckboxCouponPlatformTypes = {
  name: 'platformTypes',
  hasAll: false,
  noBrackets: false,
  type: 'checkbox',
  data: [
    {
      id: 'PC',
      value: 'PC',
      display: 'PROMOTION.COUPON.PLATFORM_TYPE_PC',
    },
    {
      id: 'MOBILE_WEB',
      value: 'MOBILE_WEB',
      display: 'PROMOTION.COUPON.PLATFORM_TYPE_MOBILE_WEB',
    },
  ],
};
export const RadioCouponCodeTypes = {
  name: 'radio-codeTypes',
  data: [
    {
      id: 'codeTypes_DESIGNATE',
      value: 'DESIGNATE',
      display: 'PROMOTION.COUPON.CODE_TYPE_DESIGNATE',
    },
    {
      id: 'codeTypes_RANDOM',
      value: 'RANDOM',
      display: 'PROMOTION.COUPON.CODE_TYPE_RANDOM',
    },
  ],
};
export const SelectCouponCodeLength = () => {
  const codeLengthList = [];
  for (let i = 5; i <= 20; i++) {
    codeLengthList.push({
      name: i18n.t('PROMOTION.COUPON.N_CODE_LENGTH', [i]),
      value: i,
    });
  }
  return codeLengthList;
};
export const RadioCouponTypes = {
  name: 'radio-couponTypes',
  data: [
    {
      id: 'couponTypes_PRODUCT',
      value: 'PRODUCT',
      display: 'PROMOTION.COUPON.COUPON_TYPE_PRODUCT',
    },
    {
      id: 'couponTypes_CART',
      value: 'CART',
      display: 'PROMOTION.COUPON.COUPON_TYPE_CART',
    },
  ],
};
export const SelectCouponFixedTypes = () => {
  return [
    {
      name: i18n.t('WON') as string,
      value: true,
    },
    {
      name: '%',
      value: false,
    },
  ];
};
export const RadioCouponAllUsePlatform = {
  name: 'radio-allUsePlatforms',
  data: [
    {
      id: 'allUsePlatform_Y',
      value: true,
      display: 'ALL',
    },
    {
      id: 'allUsePlatform_N',
      value: false,
      display: 'SETTING',
    },
  ],
};
export const CheckboxCouponUsablePlatforms = {
  name: 'usablePlatforms',
  hasAll: false,
  noBrackets: false,
  type: 'checkbox',
  data: [
    {
      id: 'PC',
      value: 'PC',
      display: 'PROMOTION.COUPON.PLATFORM_TYPE_PC',
    },
    {
      id: 'MOBILE_WEB',
      value: 'MOBILE_WEB',
      display: 'PROMOTION.COUPON.PLATFORM_TYPE_MOBILE_WEB',
    },
  ],
};
const dayLabel = (day: number): string => {
  switch (day) {
    case -1:
      return 'SELECT_PERIOD';
    case 0:
      return 'TODAY';
    case 31:
      return 'MONTH_END_DAY';
    default:
      return 'LATER_DAYS';
  }
};
export const SelectCouponUseDaysTypes = () => {
  const useDays = [];
  const basicDays = 30;
  const plusDays = [60, 90, 180, 365, 31];
  const $t = (word: string, day: number) => i18n.t(`PROMOTION.COUPON.${word}`, [day]).toString();

  for (let day = -1; day <= basicDays; day++) {
    useDays.push({
      name: $t(dayLabel(day), day),
      value: day,
    });
  }

  for (let i = 0; i < plusDays.length; i++) {
    useDays.push({
      name: $t(dayLabel(plusDays[i]), plusDays[i]),
      value: plusDays[i],
    });
  }

  return useDays;
};

export const RadioCouponTargetTypes = {
  name: 'radio-couponTargetTypes',
  data: [
    {
      id: 'couponTargetTypes_ALL_PRODUCT',
      value: 'ALL_PRODUCT',
      display: 'PROMOTION.COUPON.TARGET_TYPE_ALL_PRODUCT',
    },
    {
      id: 'couponTargetTypes_CATEGORY',
      value: 'CATEGORY',
      display: 'PROMOTION.COUPON.TARGET_TYPE_CATEGORY',
    },
    {
      id: 'couponTargetTypes_PRODUCT',
      value: 'PRODUCT',
      display: 'PROMOTION.COUPON.TARGET_TYPE_PRODUCT',
    },
  ],
};
export const RadioCouponExcludeTargetTypes = {
  name: 'radio-excludeTargetTypes',
  data: [
    {
      id: 'excludeTargetTypes_N',
      value: false,
      display: 'PROMOTION.COUPON.NOT_SET',
    },
    {
      id: 'excludeTargetTypes_Y',
      value: true,
      display: 'PROMOTION.COUPON.SET',
    },
  ],
};
export const getCouponTargetGridOption = (checkbox: boolean, excludeFlg: boolean) => {
  const columns = [
    {
      header: 'PROMOTION.COUPON.PRODUCT_NO',
      name: 'mallProductNo',
      align: 'center',
      minWidth: 110,
    },
    {
      header: 'PROMOTION.COUPON.PRODUCT_NAME',
      name: 'productName',
      minWidth: 200,
      align: 'left',
      formatter: ({ value, row }): string => {
        return productNameConvertor(value, row);
      },
    },
    {
      header: 'PROMOTION.COUPON.PRODUCT_PRICE',
      name: 'salePrice',
      align: 'center',
      minWidth: 80,
      formatter: ({ value }) => {
        return formatCurrency(value);
      },
    },
    {
      header: 'PROMOTION.COUPON.IMMEDIATE_DISCOUNT',
      name: 'appliedImmediateDiscountPrice',
      align: 'center',
      minWidth: 80,
      formatter: ({ value }) => {
        return formatCurrency(value);
      },
    },
    {
      header: 'PROMOTION.COUPON.SALES_STATUS',
      name: 'saleStatusType',
      align: 'center',
      minWidth: 100,
      formatter: ({ value }) => {
        return i18n.t(`PRODUCT.STATUS.SALE.${value}`);
      },
    },
    {
      header: 'PROMOTION.COUPON.SALE_SETTING',
      name: 'saleSettingStatusType',
      align: 'center',
      minWidth: 100,
      formatter: ({ value }) => {
        return i18n.t(`PRODUCT.SEARCH.${value}`);
      },
    },
    {
      header: 'PROMOTION.COUPON.PRODUCT_ACCUMULATION_RATE',
      name: 'accumulationRate',
      align: 'center',
      minWidth: 100,
      formatter: ({ value }) => {
        if (value) {
          return value + '%';
        } else {
          return i18n.t('NOT_USED');
        }
      },
    },
    {
      header: 'PROMOTION.COUPON.APPLY_PROMOTION',
      name: 'canPromotion',
      align: 'center',
      minWidth: 100,
      formatter: ({ row, value }) => {
        if (row.applyStatusType === 'REGISTRATION_READY') {
          return '-';
        } else {
          return value ? 'Y' : 'N';
        }
      },
    },
  ];
  if (!excludeFlg) {
    columns.push({
      header: 'PROMOTION.COUPON.REGISTRATION_DATE',
      name: 'registerYmdt',
      align: 'center',
      minWidth: 120,
    });
  }
  const rowHeaders = checkbox || !excludeFlg ? ['checkbox', 'rowNum'] : ['rowNum'];
  return {
    header: {},
    columns,
    options: {
      columnOptions: {
        resizable: true,
      },
      keyColumnName: 'mallProductNo',
      minBodyHeight: 60,
      bodyHeight: 217,
      rowHeaders,
      heightResizable: true,
    },
    displayOption: {
      showsRightArea: false,
      showPageNavigation: false,
    },
  };
};

// coupon list
export const SelectCouponSearchKeywordTypes = [
  { value: 'COUPON_NAME', name: 'PROMOTION.COUPON.COUPON_NAME' },
  { value: 'COUPON_NO', name: 'PROMOTION.COUPON.COUPON_NO' },
];
export const SelectCouponSearchDateTypes = [
  { value: 'REGISTER_YMD', name: 'PROMOTION.COUPON.TERM_REGISTER_YMD' },
  { value: 'ISSUE_START_YMD', name: 'PROMOTION.COUPON.TERM_ISSUE_START_YMD' },
  { value: 'ISSUE_END_YMD', name: 'PROMOTION.COUPON.TERM_ISSUE_END_YMD' },
];
export const RadioCouponSearchCouponTypes = {
  name: 'couponType',
  data: [
    { id: 'couponType_ALL', value: '', display: 'ALL' },
    { id: 'couponType_PRODUCT', value: 'PRODUCT', display: 'PROMOTION.COUPON.COUPON_TYPE_PRODUCT' },
    { id: 'couponType_CART', value: 'CART', display: 'PROMOTION.COUPON.COUPON_TYPE_CART' },
  ],
};
export const RadioCouponSearchIssueTypes = {
  name: 'issueType',
  data: [
    { id: 'issueType_ALL', value: '', display: 'ALL' },
    { id: 'issueType_DOWNLOAD', value: 'DOWNLOAD', display: 'PROMOTION.COUPON.ISSUE_TYPE_DOWNLOAD' },
    {
      id: 'issueType_CODE_DESIGNATE',
      value: 'CODE_DESIGNATE',
      display: 'PROMOTION.COUPON.ISSUE_TYPE_CODE_DESIGNATE',
    },
    { id: 'issueType_CODE_RANDOM', value: 'CODE_RANDOM', display: 'PROMOTION.COUPON.ISSUE_TYPE_CODE_RANDOM' },
    { id: 'issueType_INSERT', value: 'INSERT', display: 'PROMOTION.COUPON.ISSUE_TYPE_INSERT' },
  ],
};

export const CheckboxCouponSearchStatusTypes = {
  name: 'statusType',
  hasAll: true,
  noBrackets: true,
  type: 'checkbox',
  data: [
    {
      id: 'statusType_ALL',
      value: '',
      display: 'ALL',
    },
    {
      id: 'statusType_ISSUE_READY',
      value: 'ISSUE_READY',
      display: 'PROMOTION.COUPON.STATUS_TYPE_ISSUE_READY',
    },
    {
      id: 'statusType_ISSUE_ING',
      value: 'ISSUE_ING',
      display: 'PROMOTION.COUPON.STATUS_TYPE_ISSUE_ING',
    },
    {
      id: 'statusType_ISSUE_STOP',
      value: 'ISSUE_STOP',
      display: 'PROMOTION.COUPON.STATUS_TYPE_ISSUE_STOP',
    },
    {
      id: 'statusType_ISSUE_END',
      value: 'ISSUE_END',
      display: 'PROMOTION.COUPON.STATUS_TYPE_ISSUE_END',
    },
  ],
};
export const RadioCouponIssueTimeTypes = {
  name: 'issueTimeTypes',
  data: [
    {
      id: 'issueTimeTypes_PRODUCT',
      value: 'IMMEDIATELY_ISSUE',
      display: 'PROMOTION.COUPON.ISSUE_TIME_TYPE_IMMEDIATELY_ISSUE',
    },
    {
      id: 'issueTimeTypes_CART',
      value: 'SCHEDULED_ISSUE',
      display: 'PROMOTION.COUPON.ISSUE_TIME_TYPE_SCHEDULED_ISSUE',
    },
  ],
};
export const CouponListTopDefaultQuery = (popupType: boolean, mallNo: string, popType: string): CouponListTopQuery => {
  const defaultQuery = {
    mallNo: '',
    searchKeywordType: 'COUPON_NAME',
    keyword: '',
    searchDateType: 'REGISTER_YMD',
    startYmd: addMonth(new Date(), -3),
    endYmd: getToday(),
    couponType: '',
    issueType: '',
    statusTypes: 'ISSUE_READY,ISSUE_ING,ISSUE_STOP,ISSUE_END',
  };
  if (popupType) {
    defaultQuery.mallNo = mallNo;
    if (popType === 'INSERT') {
      defaultQuery.issueType = 'INSERT';
      defaultQuery.statusTypes = 'ISSUE_ING';
    } else {
      defaultQuery.statusTypes = 'ISSUE_READY,ISSUE_ING,ISSUE_STOP';
    }
  } else {
    const malls = mall.mallsSortedByCreationDate;
    if (malls && malls.length > 0) {
      defaultQuery.mallNo = malls[0].mallNo.toString();
    }
  }
  return defaultQuery;
};
export const CouponListSearchDefaultQuery = (
  popupType: boolean,
  mallNo: string,
  popType: string,
): CouponListSearchQuery => {
  const malls = mall.mallsSortedByCreationDate;
  let mallNos = popupType ? mallNo : '';
  if (!popupType && malls && malls.length > 0) {
    mallNos = malls[0].mallNo.toString();
  }
  let issueType = undefined;
  let statusTypes = 'ISSUE_READY,ISSUE_ING,ISSUE_STOP,ISSUE_END';
  if (popupType) {
    if (popType === 'INSERT') {
      issueType = 'INSERT';
      statusTypes = 'ISSUE_ING';
    } else {
      statusTypes = 'ISSUE_READY,ISSUE_ING,ISSUE_STOP';
    }
  }
  return {
    page: 1,
    size: 30,
    mallNos,
    searchDateType: 'REGISTER_YMD',
    startYmd: addMonth(new Date(), -3),
    endYmd: getToday(),
    issueType,
    statusTypes,
  };
};

export const getCouponListGridOption = (popupType?: boolean) => {
  let columns = [
    {
      header: 'MODIFY',
      name: 'edit',
      align: 'center',
      minWidth: 40,
      formatter: () => {
        return getAnchorHTML(i18n.t('MODIFY'));
      },
    },
    {
      header: 'COPY',
      name: 'copy',
      align: 'center',
      minWidth: 40,
      formatter: () => {
        return getAnchorHTML(i18n.t('COPY'));
      },
    },
    {
      header: 'PROMOTION.COUPON.COUPON_NO',
      name: 'couponNo',
      align: 'center',
      minWidth: 60,
      formatter: cell => {
        return cell.row.no;
      },
    },
    {
      header: 'MALL',
      name: 'mallName',
      align: 'center',
      minWidth: 100,
    },
    {
      header: 'PROMOTION.COUPON.STATUS_TYPE',
      name: 'couponStatusTypeLabel',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        if (cell.row.couponStatusType === 'ISSUE_STOP') {
          return `<div class="info-area"><a href="javascript:;" class="info-icon"></a>${cell.value}</div>`;
        } else {
          return cell.value;
        }
      },
    },
    {
      header: 'PROMOTION.COUPON.COUPON_NAME',
      name: 'couponName',
      align: 'center',
      minWidth: 200,
    },
    {
      header: 'PROMOTION.COUPON.ISSUE_TYPE',
      name: 'issueType',
      align: 'center',
      minWidth: 130,
      formatter: cell => {
        const showLabel = i18n.t(`PROMOTION.COUPON.ISSUE_TYPE_${cell.row.issueType}`) as string;
        if (cell.row.issueType === 'CODE_DESIGNATE') {
          const showTitle = i18n.t('PROMOTION.COUPON.PROMOTION_CODE_TITLE', [
            cell.row.mallName,
            cell.row.promotionCode,
          ]);
          return `<div title="${showTitle}"><div>${showLabel}</div><button class="crmBtn type-white" data-type="code_copy">${i18n.t(
            'PROMOTION.COUPON.CODE_COPY',
          )}</button></div>`;
        } else if (cell.row.issueType === 'CODE_RANDOM') {
          return `<div>${showLabel}</div><button class="crmBtn type-white" data-type="code_management"${
            popupType ? ' disabled' : ''
          }>${i18n.t('PROMOTION.COUPON.CODE_MANAGEMENT')}</button>`;
        } else {
          return showLabel;
        }
      },
    },
    {
      header: 'PROMOTION.COUPON.COUPON_TYPE',
      name: 'couponType',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        return i18n.t(`PROMOTION.COUPON.COUPON_TYPE_${cell.value}`);
      },
    },
    {
      header: 'PROMOTION.COUPON.DISCOUNT_AMT',
      name: 'discountAmt',
      align: 'center',
      minWidth: 150,
      formatter: cell => {
        if (cell.row.fixed) {
          return formatCurrency(cell.row.discountAmt) + i18n.t('WON');
        } else {
          return `${cell.row.discountPercent}%(${i18n.t('MAX') +
            formatCurrency(cell.row.maxDiscountAmt) +
            i18n.t('WON')})`;
        }
      },
    },
    {
      header: 'PROMOTION.COUPON.MIN_LIMIT',
      name: 'minSalePrice',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        if (cell.row.usesMinLimit) {
          return formatCurrency(cell.row.minSalePrice) + i18n.t('WON') + ' ' + i18n.t('MORE_THAN');
        } else {
          return i18n.t('PROMOTION.COUPON.NO_LIMIT');
        }
      },
    },
    {
      header: 'PROMOTION.COUPON.ISSUANCE_STATUS',
      name: 'issuance',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        return `<a class="underline" href="javascript:;">${cell.row.totalIssueCnt}</a> / ${cell.row.issueLimitCnt}`;
      },
    },
    {
      header: 'PROMOTION.COUPON.TOTAL_ISSUE_CNT_BY_ADMIN',
      name: 'totalIssueCntByAdmin',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        return getAnchorHTML(cell.value);
      },
    },
    {
      header: 'PROMOTION.COUPON.TOTAL_USE_CNT',
      name: 'totalUseCnt',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        return getAnchorHTML(cell.value);
      },
    },
    {
      header: 'PROMOTION.COUPON.CHEDULED_ISSUE_PLAN',
      name: 'scheduledIssuePlan',
      align: 'center',
      minWidth: 100,
      formatter: cell => {
        return cell.value ? 'Y' : 'N';
      },
    },
    {
      header: 'PROMOTION.COUPON.ISSUE_START_YMDT',
      name: 'issueStartYmdt',
      align: 'center',
      minWidth: 120,
    },
    {
      header: 'PROMOTION.COUPON.ISSUE_END_YMDT',
      name: 'issueEndYmdt',
      align: 'center',
      minWidth: 120,
    },
    {
      header: 'PROMOTION.COUPON.REGISTER_ADMIN',
      name: 'registerAdminName',
      align: 'center',
      minWidth: 80,
    },
    {
      header: 'PROMOTION.COUPON.REGISTER_YMD',
      name: 'registerYmdt',
      align: 'center',
      minWidth: 120,
    },
    {
      header: 'PROMOTION.COUPON.UPDATE_ADMIN',
      name: 'updateAdminName',
      align: 'center',
      minWidth: 80,
      formatter: cell => {
        if (cell.value) {
          return cell.value;
        }
      },
    },
    {
      header: 'PROMOTION.COUPON.UPDATE_YMD',
      name: 'updateYmdt',
      align: 'center',
      minWidth: 120,
      formatter: cell => {
        if (cell.value) {
          return cell.value;
        }
      },
    },
  ];
  if (popupType) {
    columns = columns.filter(column => column.header !== 'MODIFY' && column.header !== 'COPY');
  }
  return {
    header: {
      columns: [
        {
          name: 'issuance',
          renderer: props => {
            props.tooltipId = 'issuance_tips';
            return headerWithTooptipRenderer(props);
          },
        },
      ],
    },
    columns,
    options: {
      minRowHeight: 65,
      keyColumnName: 'no',
      rowHeaders: ['checkbox'],
      heightResizable: true,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOption: {
      headerTooltipOption: [
        {
          id: '#issuance_tips',
          popupData: { name: 'CouponList', data: { type: 'issuance' } },
        },
      ],
      showsTopArea: true,
      showsRightArea: true,
      pageSizeKey: 'size',
      gridName: 'PRO_PROMOTION_COUPON_GRID',
      hasSettingButton: !popupType,
      hasExcelDownloadButton: !popupType,
    },
  };
};
export const CheckboxCouponCodeStatusTypes = {
  name: 'couponCodeStatusType',
  hasAll: true,
  noBrackets: true,
  type: 'checkbox',
  data: [
    {
      id: 'couponCodeStatusType_ALL',
      value: '',
      display: 'ALL',
    },
    {
      id: 'couponCodeStatusType_ISSUED',
      value: 'ISSUED',
      display: 'PROMOTION.COUPON.CODE_STATUS_ISSUED',
    },
    {
      id: 'couponCodeStatusType_NOT_ISSUED',
      value: 'NOT_ISSUED',
      display: 'PROMOTION.COUPON.CODE_STATUS_NOT_ISSUED',
    },
    // { //todo
    //   id: 'couponCodeStatusType_ADMIN_ISSUANCE',
    //   value: 'ADMIN_ISSUANCE',
    //   display: 'PROMOTION.COUPON.ADMIN_ISSUANCE',
    // },
  ],
};

export const CouponCodeManagementDefaultQuery = (popupId, couponNo): CouponCodeManagementQuery => {
  return {
    popupId,
    couponNo,
    couponCodeStatusType: 'ISSUED,NOT_ISSUED',
  };
};

export const getCouponCodesListGridOption = () => {
  return {
    columns: [
      {
        header: 'No.',
        name: 'index',
        align: 'center',
        width: 40,
        renderer: props => {
          return {
            getElement: () => {
              const {
                rowKey,
                grid: {
                  store: {
                    data: {
                      pageOptions: { page, perPage },
                    },
                  },
                },
              } = props;
              const rowNo = (page - 1) * perPage + rowKey + 1;
              const el = document.createElement('span');
              const text = document.createTextNode(rowNo.toString());
              el.appendChild(text);
              return el;
            },
          };
        },
      },
      {
        header: 'PROMOTION.COUPON.CODE_TITLE',
        name: 'couponCode',
        align: 'center',
        minWidth: 40,
      },
      {
        header: 'PROMOTION.COUPON.CODE_STATUS',
        name: 'couponCodeStatusType',
        align: 'center',
        minWidth: 40,
        formatter: cell => {
          return cell.value === 'ISSUED'
            ? i18n.t('PROMOTION.COUPON.CODE_STATUS_ISSUED')
            : i18n.t('PROMOTION.COUPON.CODE_STATUS_NOT_ISSUED');
        },
      },
      {
        header: 'PROMOTION.COUPON.DATE',
        name: 'generateYmdt',
        align: 'center',
        minWidth: 40,
        formatter: cell => {
          return moment(cell.value).format('YYYY-MM-DD HH:mm:ss');
        },
      },
    ],
    options: {
      heightResizable: true,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
    displayOption: {
      showsTopArea: true,
      showsRightArea: true,
      pageSizeKey: 'size',
      hasSettingButton: false,
      hasPageSizeSelector: false,
      hasExcelDownloadButton: false,
    },
  };
};

export const CouponIssuanceUseDefaultQuery = (popupId, couponNo): CouponIssuanceUseQuery => {
  return {
    popupId,
    couponNo,
  };
};
export const getCouponIssuanceListGridOption = () => {
  return {
    columns: [
      {
        header: 'MEMBER_NO',
        name: 'memberNo',
        align: 'center',
        minWidth: 70,
        formatter: cell => {
          return cell.row.memberId === '탈퇴회원' ? cell.value : getAnchorHTML(cell.value);
        },
      },
      {
        header: 'ID',
        name: 'memberId',
        align: 'center',
        minWidth: 75,
      },
      {
        header: 'PROMOTION.COUPON.MEMBER_GRADE_TITLE',
        name: 'memberGradeName',
        align: 'center',
        minWidth: 80,
      },
      {
        header: 'PROMOTION.COUPON.ADMIN_ISSUED',
        name: 'adminIssued',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return cell.value ? i18n.t('PROMOTION.COUPON.ADMIN_ISSUANCE') : i18n.t('PROMOTION.COUPON.NORMAL_ISSUANCE');
        },
      },
      {
        header: 'PROMOTION.COUPON.COUPON_USE_STATUS_TYPE',
        name: 'couponUseStatusType',
        align: 'center',
        minWidth: 75,
        formatter: cell => {
          const showLabel = i18n.t(`PROMOTION.COUPON.COUPON_USE_STATUS_TYPE_${cell.value}`);
          if (cell.row.adminIssued && cell.value === 'USABLE') {
            return `<div>${showLabel}</div><a class="underline" href="javascript:;">${i18n.t(
              'PROMOTION.COUPON.WITHDRAW_CANCEL',
            )}</a>`;
          } else {
            return showLabel;
          }
        },
      },
      {
        header: 'PROMOTION.COUPON.COUPON_DISCOUNT_AMT',
        name: 'couponDiscountAmt',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (cell.row.couponUseStatusType === 'USED') {
            return formatCurrency(cell.value) + i18n.t('WON');
          } else {
            return '-';
          }
        },
      },
      {
        header: 'PROMOTION.COUPON.ISSUE_YMDT',
        name: 'issueYmdt',
        align: 'center',
        minWidth: 125,
      },
      {
        header: 'PROMOTION.COUPON.USE_END_YMDT',
        name: 'useEndYmdt',
        align: 'center',
        minWidth: 125,
      },
      {
        header: 'PROMOTION.COUPON.USE_YMDT',
        name: 'useYmdt',
        align: 'center',
        minWidth: 125,
        formatter: cell => {
          if (cell.row.couponUseStatusType === 'USED') {
            return cell.value;
          } else {
            return '-';
          }
        },
      },
      {
        header: 'PROMOTION.COUPON.WITHDRAW_YMDT',
        name: 'withdrawYmdt',
        align: 'center',
        minWidth: 125,
        formatter: cell => {
          if (cell.row.couponUseStatusType === 'WITHDRAW') {
            return cell.value;
          } else {
            return '-';
          }
        },
      },
      {
        header: 'PROMOTION.COUPON.ADMIN_ISSUE_REASON',
        name: 'adminIssueReason',
        align: 'center',
        minWidth: 80,
        formatter: cell => {
          return `<div title="${cell.value}">${cell.value}</div>`;
        },
      },
      {
        header: 'PROMOTION.COUPON.WITHDRAW_REASON',
        name: 'withdrawReason',
        align: 'center',
        minWidth: 80,
        formatter: cell => {
          if (cell.row.couponUseStatusType === 'WITHDRAW') {
            return `<div title="${cell.value}">${cell.value}</div>`;
          }
        },
      },
    ],
    options: {
      keyColumnName: 'couponIssueNo',
      minRowHeight: 45,
      heightResizable: true,
      pageOptions: {
        perPage: 30,
        page: 1,
        totalCount: 0,
      },
    },
  };
};
