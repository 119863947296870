import { RadioBoxGroupOption } from '@/helpers/type';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { i18n } from '@/main';

const getCreateEpOptions = (): RadioBoxGroupOption<boolean> => {
  return {
    name: 'createEpOption',
    data: [
      {
        id: 'create-ep',
        name: 'create-ep',
        value: true,
        display: 'USE',
      },
      {
        id: 'stop-create-ep',
        name: 'stop-create-ep',
        value: false,
        display: 'NOT_USED',
      },
    ],
  };
};

//1차 오픈 스팩엔 값이 1개임 나중에 2개로 만들 예정
const getProductNumberOptions = () => {
  return {
    name: 'productNumberOptions',
    data: [
      {
        id: 'product-number',
        name: 'product-number',
        value: 'PRODUCT_REVIEW',
        display: 'MARKETING.NAVER_SHOPPING.CREATE_EP_WITH_PRODUCT_NUM',
      },
    ],
  };
};

export const radioOptions = {
  createEpOptions: getCreateEpOptions(),
  productNumberOptions: getProductNumberOptions(),
};

export const gradeSelectBoxOptions = [
  {
    value: 'PREMIUM',
    name: 'MARKETING.NAVER_SHOPPING.PREMIUM',
    count: 499000,
  },
  {
    value: 'BIG_POWER',
    name: 'MARKETING.NAVER_SHOPPING.BIG_POWER',
    count: 99000,
  },
  {
    value: 'POWER',
    name: 'MARKETING.NAVER_SHOPPING.POWER',
    count: 49900,
  },
  {
    value: 'SPROUT',
    name: 'MARKETING.NAVER_SHOPPING.SPROUT',
    count: 9900,
  },
  {
    value: 'SEED',
    name: 'MARKETING.NAVER_SHOPPING.SEED',
    count: 9900,
  },
];

export const attachBottomNav = (onSaveCallback: () => void) => {
  const buttons = [
    {
      type: 'right',
      key: 'save',
      color: 'red',
      text: i18n.t('SAVE'),
    },
  ];

  const onClick = (key: string) => {
    switch (key) {
      case 'naver-shopping-partner':
        window.open(`https://adcenter.shopping.naver.com/member/login/form.nhn?targetUrl=%2Fmain.nhn`);
        break;
      case 'check-db-regist':
        window.open(`https://marketing.nhn-commerce.com/board/view.gd?postNo=276`);
        break;
      case 'save':
        onSaveCallback();
        break;
    }
  };

  throwBottomNavigation({ buttons, onClick });
};
