










import { Vue, Component, Model } from 'vue-property-decorator';
import mockApi, { FakeCountry } from '@/api/mock'; // TODO
import GridPicker, { KeyMap } from '@/components/common/GridPicker.vue';

@Component({
  components: { GridPicker },
})
export default class CountriesPicker extends Vue {
  @Model('change') private readonly value!: FakeCountry[];

  private fakeCountries: FakeCountry[] = [];
  private fakeKeyMap: KeyMap = {
    name: 'name',
    pick: 'pick',
    unique: 'id',
  };
  private headlines = [this.$t('ALLOWED_COUNTRY').toString(), this.$t('BLOCKED_COUNTRY').toString()];

  created() {
    this.fetchFakeCountries();
  }

  async fetchFakeCountries(): Promise<void> {
    this.fakeCountries = await mockApi.countries.get();
    console.warn(
      "FAKE API 경고! 👽 '국가별 접속제한 설정' 은 가짜 데이터 입니다.\n연관 컴포넌트 : src/components/common/input/data/CountriesPicker.vue",
    ); // TODO
  }

  private change() {
    this.$emit('change', this.fakeCountries);
  }
}
