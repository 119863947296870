

























import { Vue, Component } from 'vue-property-decorator';
import { i18nSecurity } from '@/const/contents/configuration/operateSecurity';
import { getMallNo4Notification, mall } from '@/utils/mall';
import { Admin } from '@/types';
import { Getter } from 'vuex-class';
import {
  GetSmsGodoTokenPopupPointCharge,
  GetSmsGodoTokenPopupPointChargeRequest,
  NCPResponse,
} from 'ncp-api-supporter';
import { throwExternalWindowPopup } from '@/helpers/popup';

@Component
export default class AuthNoticeBox extends Vue {
  @Getter('admin/getAdmin') private me: Admin;

  private readonly i18nSecurity = i18nSecurity;
  private firstCreatedMallNo: number | null = null;

  created() {
    this.firstCreatedMallNo = mall.firstCreatedMall.mallNo;
  }

  async openChargePopup(): Promise<void> {
    const request: GetSmsGodoTokenPopupPointChargeRequest = {
      params: {
        mallNo: getMallNo4Notification(this),
      },
    };
    try {
      const response: NCPResponse<GetSmsGodoTokenPopupPointCharge> = await this.$api.getSmsGodoTokenPopupPointCharge(
        request,
      );
      throwExternalWindowPopup(response.data.url, 'md', {
        token: response.data.token,
        godosno: response.data.sno,
        type: response.data.type,
      });
    } catch (err) {
      console.error(err);
    }
  }
}
