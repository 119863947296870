export default Object.freeze({
  MON: '월',
  TUE: '화',
  WED: '수',
  THU: '목',
  FRI: '금',
  SAT: '토',
  SUN: '일',

  TODAY: '오늘',

  MONTH: '월',
  ALERT_INVALID_START_DATE: '시작일자는 종료일자보다 클 수 없습니다.',
  ALERT_INVALID_DAY_LIMIT: '검색기간은 {day}일 미만으로 선택하세요',
  ALERT_INVALID_BEFORE_DAY: '현재 날짜 이후로는 선택할 수 없습니다.',
});
