export default Object.freeze({
  NOTICE_0: `자동 지급 여부를 '사용함' 으로 설정시 해당 등급의 정기 지급 적립금, 적용된 쿠폰이 매월 1일에 정기적으로 쇼핑몰에서 지급됩니다.`,
  NOTICE_1: `단, 사용 여부를 '사용 안 함' 으로 설정한 경우 자동 지급 여부를 '사용함' 설정해도 자동 지급 되지 않습니다.`,
  NOTICE_2: `자동 지급을 원하지 않는 경우 '사용 안 함' 적용해야 합니다.`,
  NOTICE_3: `자동 지급을 '사용 안 함' 상태에서 '사용함' 상태로 변경 시, 설정 이후에 다가오는 1일부터 설정된 값으로 혜택이 지급됩니다.`,
  NOTICE_4: `혜택 지급 시, SMS, 이메일 사용 시 알림이 발송되며, 야간시간 발송 제한 설정되지 않은 경우 야간시간에 전송될 수 있으니 주의바랍니다.`,
  NOTICE_5: `단, 포인트 없을 경우 SMS 발송되지 않습니다.`,
  NOTICE_6: `수동으로 혜택 지급을 원하는 경우 회원 등급 관리 페이지 내 [등급 혜택 즉시지급] 버튼을 통해 가능합니다.`,

  GRID_TITLE: '회원 등급',

  ADD: '등급추가',
  DELETE: '선택삭제',
  PAY_BENEFIT: '등급 혜택 즉시 지급',

  ALERT_NOT_SELECT: '회원 등급을 선택해주세요.',
  ALERT_NOT_DELETE_BASIC: '기본등급은 삭제가 불가합니다.',
  ALERT_NOT_DELETE_EXIST_MEMBER: '해당 등급에 회원이 존재하여 삭제가 불가합니다.',
  ALERT_DELETE_CONFIRM: '선택한 등급을 삭제하시겠습니까?',
  ALERT_DELETE_SUCCESS: '삭제되었습니다.',
  ALERT_GRADE_LENGTH_LIMIT: '회원 등급은 최대 100개까지 등록 가능합니다.',
  ALERT_ALREADY_EXIST_GRADE: '해당 등급에 회원이 존재하여 사용 안 함으로 변경이 불가합니다.',
  CONFIRM_SAVE: "저장하시겠습니까? \n자동지급 여부 '사용함' 설정 시 매월 1일 설정된 적립금 및 쿠폰 혜택이 지급됩니다.",

  DISPLAY_ORDER: '진열<br/>순서',
  DISPLAY_ORDER_BASIC: '기본',
  GRADE_NAME: '등급명',
  GRADE_NO: '등급 번호',
  WHETHER_TO_USE: '사용여부',
  USE: '사용',
  NOT_USE: '사용 안 함',
  AUTO_PAY: '자동지급<br/>여부',
  AUTO_PAY_GRID: '자동지급 여부',
  MEMBER_LENGTH: '회원수',
  PAY_POINT: '구매 시 지급<br/>적립금(%)',
  REGULAR_POINT: '정기 지급<br/>적립금(원)',
  COUPON: '혜택적용 쿠폰',
  COUPON_CELL_TEXT: '{name} 외 {number}건',
  COUPON_CELL_DESC: '{name} / {discountPercent}% (최대 {discountAmount}원)',
  GRADE_DESC: '등급 설명',

  ADD_GRADE: '회원 등급 추가',
  EDIT_GRADE: '회원 등급 수정',
  ADD_GRADE_NAME_PLACEHOLDER: '등급명을 입력하세요.',
  NEED_ORIGIN: '회원 등급을 선택해주세요.',
  NOT_CHANGE_ORDER:
    '기본 등급 또는 사용하지 않는 회원 등급은 순서 변경이 불가합니다.\n사용함으로 변경 후 다시 시도해주세요.',

  RESERVE_RATE: '구매 시 지급 적립금(%)',
  RESERVE_RATE_PLACEHOLDER: '구매금액 적립 % 입력',
  REGULAR_AMOUNT: '정기 지급 적립금(원)',
  REGULAR_AMOUNT_PLACEHOLDER: '적립금 정액 입력',
  ADD_COUPON: '쿠폰 혜택 적용',
  SEARCH_COUPON: '쿠폰 검색',
  EDIT_COUPON: '쿠폰 변경',
  GRADE_DESC_PLACEHOLDER: '등급 설명을 입력해주세요.',

  ALERT_BENEFIT_CONFIRM:
    '선택한 등급의 적립금 및 쿠폰 혜택을 즉시 지급하시겠습니까?\n지급 시 관리자 내 설정된 수단으로 알림이 전송될 수 있습니다.\n* 사용하지 않는 등급은 혜택 지급이 불가합니다.',
  ALERT_BENEFIT_SUCCESS: '회원 등급 혜택 지급이 완료되었습니다.',
});
