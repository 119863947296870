



















import { Vue, Component } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { namespace } from 'vuex-class';
import { DomainByMallData } from '@/types';
import moment from 'moment';

const mallStore = namespace('mall');

@Component({
  directives: {
    ClickOutside,
  },
})
export default class MallListMenu extends Vue {
  @mallStore.Getter('getDomainByMalls')
  private readonly domainByMalls!: DomainByMallData[];

  private show = false;

  onClickOutside(): void {
    this.show = false;
  }

  get sortedDomainByMalls() {
    const sortByLatestCreationDate = (a: DomainByMallData, b: DomainByMallData): number => {
      const dateL = moment(a.createdDateTime)
        .toDate()
        .getTime();
      const dateR = moment(b.createdDateTime)
        .toDate()
        .getTime();
      return dateL < dateR ? 1 : -1;
    };
    return this.domainByMalls.sort(sortByLatestCreationDate);
  }

  private getMallUrl(mall: DomainByMallData): string {
    const httpProtocol = mall.domains.ssl ? 'https://' : 'http://';
    return httpProtocol + mall.domains.PC.mainDomain ?? httpProtocol + mall.domains.MOBILE.mainDomain ?? '#blocked'; // TODO: 없을경우 어드민 내 404 연결
  }
}
