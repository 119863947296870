export default Object.freeze({
  PAGE_TITLE: '문의유형/담당자 변경',
  TH_1: '변경대상',
  TH_2: '문의유형',
  TH_3: '담당자',
  TH_4: '변경 사유',
  PLACEHOLDER: '사유를 입력하세요',
  ERROR_INQUIRYTYPENO: '문의유형을 선택해 주세요.',
  ERROR_ADMINNO: '담당자를 선택해 주세요.',
  ERROR_COMMENT: '사유를 입력해 주세요.',
  CONFIRM_COMMIT: '문의유형 담당자를 변경하시겠습니까?',
});
