import { Route } from 'vue-router';
import { getCookie } from '@/utils/cookie';
import { LNB_SETTING, SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN } from '@/const/cookie';
import { refreshToken, bindAdminInfo, setSessionCookie } from '@/utils/token';
import { clearBottomNavigation } from '@/helpers/bottomNav';
import { PopupClose, ThrowMessagePopup, throwMessagePopup } from '@/helpers/popup';
import store from '@/store/index';
import { Entry } from '@/types/main';
import cookies from 'js-cookie';

function isJustQueryChanged(to: Route, from: Route) {
  // vue router는 단순 query string change 만으로도 호출되기 때문에
  // 이곳에서 단순 query string change인지 path 변경인지 판단한다.
  return to.path === from.path;
}

async function initPremium(to: Route) {
  const queryPremiumRefreshToken = to?.query?.refresh as string;

  if (queryPremiumRefreshToken) {
    await cookies.set(SHOPBY_REFRESHTOKEN, queryPremiumRefreshToken);
    await refreshToken(queryPremiumRefreshToken);
    store.dispatch('mall/fetchMalls');
    setSessionCookie();
  }
}

function removePremiumQuery(to) {
  if (to.path.includes('/premium')) {
    history.replaceState({}, null, `/pro${to.path}`);
  }
}

export function readCookieStatus(): void {
  const lnbOpen = getCookie(LNB_SETTING);
  if (lnbOpen === 'false') {
    store.commit('lnb/SET_LNB_SHOWING', false);
  }
}

export function getInitialEntry(): Entry[] {
  const entry: Entry[] = [];
  const isPremiumPlanType = store.getters['admin/getAdmin'].plan === 'PREMIUM';

  if (store.getters['mall/getMalls'].length <= 0) entry.push({ type: 'mall/fetchMalls' });
  if (store.getters['mall/getMallDomains'].length <= 0) entry.push({ type: 'mall/fetchMallDomains' });
  if (isPremiumPlanType) return entry;
  if (store.getters['mall/getPreviousMalls'].length <= 0) entry.push({ type: 'mall/fetchPreviousMalls' });
  if (store.getters['admin/getGodoSno'] === null) entry.push({ type: 'admin/fetchGodoSno' });
  if (store.getters['menu/getMenus'].length <= 0) entry.push({ type: 'menu/fetchMenus' });

  return entry;
}

export async function fetchInitialData(routeInfo: Route = null) {
  const accessToken = getCookie(SHOPBY_ACCESSTOKEN);
  // NOTE: init api 예외 처리
  const exceptionalPathNames = ['NoPermission', 'NotFound'];
  const isExcludeInitCalls =
    accessToken == null || accessToken.length == 0 || exceptionalPathNames.includes(routeInfo?.name);
  if (isExcludeInitCalls) return;
  readCookieStatus();
  return await Promise.all(getInitialEntry().map(dispatch => store.dispatch(dispatch.type, dispatch.payload)));
}

// to: Route,
// from: Route,
// next: (to?: RawLocation | false | ((vm: V) => any) | void) => void
export async function initLogin(to: Route, from: Route, next): Promise<void> {
  if (to.path.includes('/oauth')) {
    next();
    return;
  }

  if (to.path.includes('/auth')) {
    next();
    return;
  }

  if (to.path.includes('/admin-register')) {
    next();
    return;
  }

  //샵바이 프리미엄에서 query로 넘겨주는 경우
  if (to.path.includes('/premium')) {
    await initPremium(to);
    next();
    return;
  }

  // 로그인 정보가 없는 경우 login 페이지로 리다이렉트
  const accessToken = getCookie(SHOPBY_ACCESSTOKEN);
  if (!to.meta.nonMember && (accessToken == null || accessToken.length == 0)) {
    //refreshToken : 1일
    //accessToken : 5분 --> refreshToken 으로 계속 갱신
    refreshToken().then((refreshFlg: boolean): void => {
      if (refreshFlg) {
        // TODO : refresh token 이거 작동 원리 분석 안됨. 분석 후 malls get 처리할것
        next();
      } else {
        if (process.env.NODE_ENV === 'production' || window.location.host.includes('shopby')) {
          window.location.href = `/pro/auth?redirectUrl=${to.fullPath}`;
        } else {
          window.location.href = `/auth?redirectUrl=${to.fullPath}`;
        }
      }
    });
  } else {
    // 통합 로그인. 샵바이 스탠다드를 통해 로그인 되는 경우 adminInfo 정보가 없기 때문에 페이지가 정상 로딩되지 않는다. 강제로 넣어준다.
    if (accessToken && !getCookie('adminInfo')) {
      bindAdminInfo(getCookie(SHOPBY_ACCESSTOKEN));
    }
    next();
  }
}

export async function initState(to: Route, from: Route, next): Promise<void> {
  if (!isJustQueryChanged(to, from)) {
    clearBottomNavigation();
    if (store.getters['partner/getPartnerNo'] !== 0) {
      await store.dispatch('partner/resetPartnerState');
    }
  }
  await fetchInitialData(to);
  next();
  removePremiumQuery(to); //반드시 next()이후에 실행
}

export async function detectUnsavedForm(to: Route, from: Route, next): Promise<void> {
  const hasUnsavedForm = store.getters['hasUnsavedForm'];
  const isFrom = !!from.meta?.detection;
  const isDetect = isFrom && hasUnsavedForm;
  const isWindowConfirm = !!from.meta?.detectConfirm;
  const {
    message,
    isConfirm,
    headerText,
    showOkButtonOnly,
    okButtonText,
    cancelButtonText,
  }: ThrowMessagePopup = store.getters['getDetectUnsavedFormMessage'];

  if (isDetect && !isWindowConfirm) {
    const result = await throwMessagePopup(
      window.$t(message),
      isConfirm,
      headerText,
      showOkButtonOnly,
      window.$t(okButtonText),
      window.$t(cancelButtonText),
    );

    if (result.state === PopupClose.CONFIRM) {
      next();
      store.commit('INIT_DETECT_UNSAVED_MESSAGE');
    }
    return;
  } else if (isDetect && isWindowConfirm) {
    if (!confirm(window.$t(message))) return;
    next();
    store.commit('INIT_DETECT_UNSAVED_MESSAGE');
    return;
  }

  next();
}
