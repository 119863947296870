export const getCampaignsRequirementsOption = (
  mallNo: number,
): { label: string; settingUrl: string; key: string; tooltipMsg?: string }[] => [
  {
    label: window.$t('MARKETING.GOOGLE_AD.REPRESENTATIVE_PHONE_NUM'),
    settingUrl: '/configuration/basic/info',
    key: 'representativePhoneNo',
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.BUSINESS_ADDRESS'),
    settingUrl: '/configuration/basic/info',
    key: 'address',
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.EMAIL_LABEL'),
    settingUrl: '/configuration/basic/info',
    key: 'representativeEmail',
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.REGISTRATION_NUM_LABEL'),
    settingUrl: '/configuration/basic/info',
    key: 'registrationNo',
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.MALL_LABEL'),
    settingUrl: `/configuration/basic/mall/${mallNo}`,
    key: 'mallName',
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.DOMAIN_LABEL'),
    settingUrl: `/configuration/basic/domain-ssl`,
    key: 'domain',
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.PG_LABEL'),
    settingUrl: `/configuration/payment/pg?mallNo=${mallNo}`,
    key: 'usesPg',
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.PRODUCT_LABEL'),
    settingUrl: `/product/basic/management/add?parentNo=0&type=SINGLE&synced=N&mallNo=${mallNo}`,
    key: 'hasProduct',
    tooltipMsg: window.$t('MARKETING.GOOGLE_AD.PRODUCT_TOOLTIP'),
  },
  {
    label: window.$t('MARKETING.GOOGLE_AD.MARKETING_LABEL'),
    settingUrl: `/marketing/product/google?mallNo=${mallNo}`,
    key: 'displayableProduct',
    tooltipMsg: window.$t('MARKETING.GOOGLE_AD.MARKETING_TOOLTIP'),
  },
];
