
















import { Vue, Component, Model, Ref, Watch, Prop } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import { InputNumber } from '@/types';
@Component({
  components: { TextInput },
})
export default class IpOctetInput extends Vue {
  @Model('change')
  private readonly value!: InputNumber;
  private valueSync: InputNumber = '';

  @Prop({ default: false })
  private disabled!: boolean;

  @Ref()
  private readonly input!: TextInput;

  created() {
    this.valueSync = this.value;
  }

  @Watch('value')
  updateValueSync(val: InputNumber): void {
    this.valueSync = val;
  }

  public focus(): void {
    this.input.focus();
  }

  private onKeyup(value: InputNumber): void {
    // IPv4 대역폭은 0 ~ 255 (8bit) 을 넘지 못하기 때문에 255 초과 입력시 강제 보정
    if (value > 255) {
      this.valueSync = 255;
      this.$emit('change', 255);
      return;
    }
    this.$emit('change', value);

    if (value.toString().length >= 3) this.$emit('change:max-length-over');
  }

  private onKeydown(key: KeyboardEvent['key'], value: InputNumber): void {
    // 두자리 수 입력 시 25 초과 일 시 다음값이 어떤 숫자가 오든 255 초과기 때문에 해당 정보 emit.
    if (value > 25) {
      this.$emit('change:max-length-over');
    }

    this.$emit('change', value);

    if (key === 'Backspace' && value === '') this.$emit('change:min-length-under');
  }

  public validate(): boolean {
    if (this.value !== '') return true;
    alert(this.$t('CONFIGURATION.SECURITY.ALERT_IP_OCTET_EMPTY'));
    this.input.focus();
    return false;
  }
}
