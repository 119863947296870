import { termsHistoryMainTitleType } from '@/types/terms';
import { History, TermsType } from 'ncp-api-supporter';
import {
  pITypeList,
  TERMS_HISTORY_MAIN_TITLE,
  TERMS_TYPE,
  withdrawalTypeList,
} from '@/const/contents/configuration/terms';
import { getStrDate, isSameOrAfter } from '@/utils/dateFormat';
import { today } from '@/views/contents/product/basic/ProductAdd';
import { convertToI18n } from '@/utils/i18n';

export function i18nForTerms(word: string, data?: string, useDefault?: boolean): string {
  const TERMS = 'CONFIGURATION.TERMS.';
  return convertToI18n(TERMS, word, data, useDefault);
}

export const getTermsTypeList = (type: termsHistoryMainTitleType | TermsType = TERMS_TYPE.USE): TermsType[] => {
  if (type === TERMS_HISTORY_MAIN_TITLE.PERSONAL_INFORMATION) {
    return pITypeList;
  }
  if (type === TERMS_HISTORY_MAIN_TITLE.WITHDRAWAL) {
    return withdrawalTypeList;
  }
  return [type as TermsType];
};

export const getTermsHistoryTitle = (effectiveTermsNo?: number) => (history: History): string => {
  const { termsType, enforcementDateTime, termsNo } = history;
  if (termsType === TERMS_TYPE.USE || termsType === TERMS_TYPE.PI_PROCESS) {
    const isOver = isSameOrAfter(today, getStrDate(enforcementDateTime?.toString()));
    const title = i18nForTerms(`SELECT_NAME_${termsType}`);

    if (termsNo === effectiveTermsNo) {
      return `${title}<br>(${i18nForTerms('TERM_USE_EFFECT_Y')})`;
    }
    return `${title}${isOver ? '' : `<br>(${i18nForTerms('TERM_USE_EFFECT_N')})`}`;
  }

  return `${i18nForTerms(`SELECT_NAME_${termsType}`)}`;
};
