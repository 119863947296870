

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import AppModalHeader from '@/components/popup/app/AppModalHeader.vue';
import AppStatement from '@/components/popup/app/AppStatement.vue';
import AppModal from '@/components/popup/app/AppModal.vue';
import {
  AppsAppNoInstalledAppInstalledNo,
  DomainType,
  GetAppsAppNoInstalledAppInstalledNoRequest,
  ProductPaymentType,
} from 'ncp-api-supporter';
import { convertYmdtToYmd } from '@/utils/dateFormat';
import { Nullable } from 'ncp-api-supporter/dist/types';

interface Authority {
  domainLabel: string;
  authorityLabel: string;
  description: string;
}

const DOMAIN_TO_LABEL_MAP: Record<DomainType, string> = {
  ADMIN: 'admin',
  DELIVERY: 'delivery',
  DISPLAY: 'display',
  MANAGE: 'manage',
  MEMBER: 'member',
  ORDER: 'order',
  ORDER_FRIENDS: 'order-friends',
  PRODUCT: 'product',
  PROMOTION: 'promotion',
  CLAIM: 'claim',
  WORKSPACE: 'workspace',
};

@Component({
  components: { AppModal, AppStatement, AppModalHeader },
})
export default class AppInstallInfo extends Vue {
  @Prop({ required: true }) data!: { appNo: number; appInstalledNo: number; mallNo: number };

  private isFetching = true;
  private appName = '';
  private teamName = '';
  private paymentType: ProductPaymentType = 'FREE';
  private description = '';
  private authorities: Authority[] = [];
  private installedDate = '';
  private expiredDate = '';
  private iconUrl = '';
  private productType = '';
  private privacyAgreement: Nullable<string> = null;

  async created() {
    this.fetchAppDetail(await this.getAppDetail());
    this.isFetching = false;
  }

  private async getAppDetail(): Promise<AppsAppNoInstalledAppInstalledNo> {
    const request: GetAppsAppNoInstalledAppInstalledNoRequest = {
      pathParams: {
        appNo: this.data.appNo.toString(),
        appInstalledNo: this.data.appInstalledNo.toString(),
      },
      params: {
        mallNo: this.data.mallNo,
      },
    };

    return (await this.$api.getAppsAppNoInstalledAppInstalledNo(request)).data;
  }

  private fetchAppDetail(appDetailResponse: AppsAppNoInstalledAppInstalledNo) {
    const {
      name,
      teamName,
      paymentTypes,
      description,
      writableAuthorities,
      readableAuthorities,
      installedDateTime,
      expireDateTime,
      iconUrl,
      productType,
      privacyAgreement,
    } = appDetailResponse;

    this.appName = name;
    this.teamName = teamName;
    this.description = description;
    this.paymentType = paymentTypes.includes('FREE') ? 'FREE' : 'IN_APP_PURCHASE'; // TODO: paymentsTypes가 배열이 아닌 단일 값으로 바뀔 예정으로 전달 받음
    this.fetchAuthorities(writableAuthorities, readableAuthorities);
    this.installedDate = convertYmdtToYmd(installedDateTime ?? '');
    this.expiredDate = convertYmdtToYmd(expireDateTime ?? '');
    this.iconUrl = iconUrl;
    this.productType = productType;
    this.privacyAgreement = privacyAgreement;
  }

  private fetchAuthorities(writableAuthorities: DomainType[], readableAuthorities: DomainType[]) {
    this.authorities = writableAuthorities
      .map<Authority>(domainType => ({
        domainLabel: DOMAIN_TO_LABEL_MAP[domainType],
        authorityLabel: this.$t('APP.READ_WRITE').toString(),
        description: this.$t('APP.AUTHORITY_DESCRIPTION', {
          domain: this.$t(`APP.${domainType}_DESCRIPTION`),
          authority: this.$t('APP.READ_WRITE_DESCRIPTION'),
        }).toString(),
      }))
      .concat(
        readableAuthorities.map<Authority>(domainType => ({
          domainLabel: DOMAIN_TO_LABEL_MAP[domainType],
          authorityLabel: this.$t('APP.READ').toString(),
          description: this.$t('APP.AUTHORITY_DESCRIPTION', {
            domain: this.$t(`APP.${domainType}_DESCRIPTION`),
            authority: this.$t('APP.READ_DESCRIPTION'),
          }).toString(),
        })),
      );
  }
}
