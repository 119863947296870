
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClaimOption, UserInputText } from 'ncp-api-supporter';
import { unescapeHtml } from '@/utils/common';

@Component
export default class TdClaimProductName extends Vue {
  @Prop({ required: true })
  private readonly claimedOption: ClaimOption;

  private unescapeHtml = unescapeHtml;

  private get existOptionManagementCd() {
    return Boolean(this.claimedOption.optionManagementCd);
  }

  private getTextInfoLabel({ inputLabel, inputValue }: UserInputText): string {
    return `-${unescapeHtml(inputLabel ?? '')}: ${unescapeHtml(inputValue ?? '')}`;
  }

  private showOption(optionName: string, optionValue: string) {
    const opName: string[] = optionName.split('|');
    const opValue: string[] = optionValue.split('|');
    let returnValue = '';
    for (let i = 0; i < opName.length; i++) {
      returnValue = returnValue + opName[i] + ':' + opValue[i] + ' | ';
    }

    return unescapeHtml(returnValue.substring(0, returnValue.length - 3));
  }
}
