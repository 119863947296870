


















































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import {
  GetDesignPopupsPopupNo,
  MobileDesign,
  PcDesign,
  PopupSlideInfo,
  SlideImages,
} from 'ncp-api-supporter/dist/types/modules/display/designPopup';
import { getUnitStr } from '@/const/contents/designPopup';
import $ from 'jquery';

@Component({ components: { RadioGroup } })
export default class DesignPopupPreviewPopup extends Vue {
  @Prop() private data: GetDesignPopupsPopupNo;
  @Prop() private onNegativeClick!: Function;

  private platformType = 'PC';
  private content: string;
  private pcDesign: PcDesign;
  private mobileDesign: MobileDesign;
  private popupDesignType: string;
  private popupSlideInfo: PopupSlideInfo;
  private visibleToday: boolean;

  private currentBigImgShow = 0;

  private platformTypeOption = {
    name: 'platformTypeOption',
    data: [
      { id: 'platformTypePcPreview', value: 'PC', display: 'PC_WEB' },
      { id: 'platformTypeMobilePreview', value: 'MOBILE_WEB', display: 'MOBILE_WEB' },
    ],
  };

  changeImg(e: Event, imgSrc: string, index: number): void {
    this.setSelectedLiDisplayStatus('js_big_item_', index, 'js_img_class li_display');
  }

  mouseEnter(e: Event, imgSrc: string): void {
    const item = (e.target as HTMLLinkElement).firstElementChild as HTMLImageElement;
    item.src = '';
    item.src = imgSrc;
  }

  mouseLeft(index: number, selected?: number): void {
    this.changeLiImage('s_img_', index, 'orgi', selected);
  }

  private getJumpToUrl({ landingUrl }): string {
    if (landingUrl) {
      return landingUrl;
    } else {
      return 'javascript:;';
    }
  }

  private get pcDivStyle(): string {
    let cssStyle = '';
    if (!this.pcDesign['resizable']) {
      cssStyle = 'margin: 0 auto;overflow: scroll;margin-top: 15px;';
      cssStyle += `width: ${this.pcDesign.screenWidth}${getUnitStr(this.pcDesign.screenWidthUnit)};height: ${
        this.pcDesign.screenHeight
      }${getUnitStr(this.pcDesign.screenHeightUnit)};`;
    } else {
      cssStyle = 'margin: 0 auto;margin-top: 15px;width: 100%;height: 100%;';
    }
    if (this.pcDesign['bgColor']) {
      cssStyle += `backgroundColor: ${this.pcDesign.bgColor};`;
    }
    return cssStyle;
  }

  private get pcBtnListStyle(): string {
    return '';
  }

  private get mobileDivStyle(): string {
    let cssStyle = '';
    if (!this.mobileDesign['resizable']) {
      cssStyle = 'margin: 0 auto;overflow: hidden;margin-top: 15px;';
      cssStyle += `width: ${this.mobileDesign.screenWidth}${getUnitStr(this.mobileDesign.screenWidthUnit)};height: ${
        this.mobileDesign.screenHeight
      }${getUnitStr(this.mobileDesign.screenHeightUnit)};`;
    } else {
      cssStyle = 'margin: 0 auto;margin-top: 15px;width: 100%;height: 100%;';
    }
    if (this.mobileDesign['bgColor']) {
      cssStyle += `backgroundColor: ${this.mobileDesign.bgColor};`;
    }
    return cssStyle;
  }

  private get mobileBtnListStyle(): string {
    return '';
  }

  private get maxSlideStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return `overflow: visible;width: ${this.popupSlideInfo.slideMaxWidth}px;height: ${this.popupSlideInfo.slideMaxHeight}px`;
    }
    return `width: ${this.popupSlideInfo.slideMaxWidth}px;height: ${this.popupSlideInfo.slideMaxHeight}px`;
  }

  private get minSlideStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return `overflow: visible;width: ${this.popupSlideInfo.slideMinWidth}px;height: ${this.popupSlideInfo.slideMinHeight}px`;
    }
    return `width: ${this.popupSlideInfo.slideMinWidth}px;height: ${this.popupSlideInfo.slideMinHeight}px`;
  }

  private get maxImgStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return 'max-width: 100%;max-height: 100%;';
    } else {
      return '';
    }
  }

  private get minImgStyle(): string {
    if (this.popupSlideInfo.resizable) {
      return 'max-width: 100%;max-height: 100%;';
    } else {
      return '';
    }
  }

  private get slideBtnListStyle(): string {
    return '';
  }

  private platformTypes: string[] = [];
  private radioPlatformTypesFlag = 'ALL';

  created() {
    this.content = this.data.content;
    this.pcDesign = this.data.pcDesign;
    this.mobileDesign = this.data.mobileDesign;
    this.popupSlideInfo = this.data.popupSlideInfo;
    this.popupDesignType = this.data.popupDesignType;
    this.visibleToday = this.data.visibleToday;
    this.platformTypes = this.data.platformTypes;

    if (
      this.platformTypes.includes('PC') &&
      this.platformTypes.includes('MOBILE_WEB') &&
      this.pcDesign &&
      this.mobileDesign
    ) {
      this.radioPlatformTypesFlag = 'ALL';
    } else if (
      this.platformTypes.includes('PC') &&
      this.pcDesign &&
      (!this.platformTypes.includes('MOBILE_WEB') || !this.mobileDesign)
    ) {
      this.platformType = 'PC';
      this.radioPlatformTypesFlag = 'PC';
    } else if (
      this.platformTypes.includes('MOBILE_WEB') &&
      this.mobileDesign &&
      (!this.platformTypes.includes('PC') || !this.pcDesign)
    ) {
      this.platformType = 'MOBILE_WEB';
      this.radioPlatformTypesFlag = 'MOBILE_WEB';
    }

    if (this.popupDesignType === 'MULTI' && this.popupSlideInfo) {
      this.setSlideStartIndex();
    }
  }

  private setSlideStartIndex() {
    this.currentBigImgShow = 0;
  }

  private changeLiImage(refPrefix: string, index: number, datasetId: string, selected: number) {
    const imageElement = this.$refs[refPrefix + index][selected] as HTMLImageElement;
    imageElement.src = imageElement.dataset[datasetId];
  }

  private setSelectedLiDisplayStatus(refPrefix: string, index: number, className: string) {
    if (this.popupSlideInfo.slideDirection === 'FIXED') {
      for (let i = 0; i < 8; i++) {
        if (this.$refs['js_big_item_' + i]) {
          (this.$refs['js_big_item_' + i][0] as HTMLElement).className = 'js_img_class li_hide';
        }
      }
      (this.$refs[refPrefix + index][0] as HTMLElement).className = className;
    } else {
      switch (this.popupSlideInfo.slideDirection) {
        case 'LEFT': {
          const moveToX = `${-1 * this.popupSlideInfo.slideMaxWidth * index}px`;
          $('#movingUlRef').css({
            transform: `translateX(${moveToX}`,
            transition: `${this.popupSlideInfo.slideSpeed}s`,
          });
          break;
        }
        case 'RIGHT': {
          const moveToX = `${-1 * this.popupSlideInfo.slideMaxWidth * (this.getElementCntBy() - 1 - index)}px`;
          $('#movingUlRef').css({
            transform: `translateX(${moveToX}`,
            transition: `${this.popupSlideInfo.slideSpeed}s`,
          });
          break;
        }
        case 'UP': {
          const moveToY = `${-1 * this.popupSlideInfo.slideMaxHeight * index}px`;
          $('#movingUlRef').css({
            transform: `translateY(${moveToY}`,
            transition: `${this.popupSlideInfo.slideSpeed}s`,
          });
          break;
        }
        case 'DOWN': {
          const moveToY = `${-1 * this.popupSlideInfo.slideMaxHeight * (this.getElementCntBy() - 1 - index)}px`;
          $('#movingUlRef').css({
            transform: `translateY(${moveToY}`,
            transition: `${this.popupSlideInfo.slideSpeed}s`,
          });
          break;
        }
      }
    }
  }

  private get outerULStyle(): string {
    if (this.popupSlideInfo.slideDirection === 'LEFT' || this.popupSlideInfo.slideDirection === 'RIGHT') {
      return `overflow: hidden;border: 1px solid #ced2d8;width: ${this.popupSlideInfo.slideMaxWidth}px;`;
    } else if (this.popupSlideInfo.slideDirection === 'UP' || this.popupSlideInfo.slideDirection === 'DOWN') {
      return `overflow: hidden;border: 1px solid #ced2d8;height: ${this.popupSlideInfo.slideMaxHeight}px;`;
    } else {
      return ``;
    }
  }

  private get reverseSlideImages(): SlideImages[] {
    const reverseSlideImages: SlideImages[] = [];
    if (this.popupSlideInfo.slideImages) {
      this.popupSlideInfo.slideImages.forEach(image => {
        reverseSlideImages.push(image);
      });
      reverseSlideImages.reverse();
    }
    return reverseSlideImages;
  }

  private get innerULStyle(): string {
    const totalCnt = this.getElementCntBy();
    switch (this.popupSlideInfo.slideDirection) {
      case 'LEFT':
        return `width: ${this.popupSlideInfo.slideMaxWidth * totalCnt}px;transform: translateX(0px);`;
      case 'RIGHT':
        return `width: ${this.popupSlideInfo.slideMaxWidth * totalCnt}px;transform: translateX(${-1 *
          this.popupSlideInfo.slideMaxWidth *
          (totalCnt - 1)}px);`;
      case 'UP':
        return `height: ${this.popupSlideInfo.slideMaxHeight * totalCnt}px;transform: translateY(0px);`;
      case 'DOWN':
        return `height: ${this.popupSlideInfo.slideMaxHeight * totalCnt}px;transform: translateY(${-1 *
          this.popupSlideInfo.slideMaxHeight *
          (totalCnt - 1)}px);`;
      default:
        return ``;
    }
  }

  private getElementCntBy(): number {
    switch (this.popupSlideInfo.slideCount) {
      case 'TWO_BY_ONE':
        return 2;
      case 'TWO_BY_TWO':
        return 4;
      case 'THREE_BY_ONE':
        return 3;
      case 'THREE_BY_TWO':
        return 6;
      case 'FOUR_BY_ONE':
        return 4;
      case 'FOUR_BY_TWO':
        return 8;
      default:
        return 8;
    }
  }
}
