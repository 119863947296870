









import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { applyUrl } from '@/components/addition/GoTo';

@Component
export default class PostRequestButton extends Vue {
  @Prop({ required: true })
  private readonly query!: null | { [key: string]: string };
  @Prop({ required: false, default: 'PRODUCT.COMMON.APPLY' })
  private readonly buttonText!: string;
  @Prop({ required: true })
  private readonly remoteCode!: 'DREAM_SECURITY' | 'SMS_REFUSAL';

  get action() {
    return applyUrl[this.remoteCode];
  }

  @Ref()
  private readonly form: HTMLFormElement;

  @Watch('query')
  private changedQuery() {
    this.$nextTick(this.submit);
  }

  private submit() {
    this.form.submit();
  }
}
