


































































import { Component } from 'vue-property-decorator';
import WindowPopupMainVue from '../Main.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import MallSelect from '@/components/common/input/MallSelect.vue';
import { copyFormOptions } from '@/views/popups/product/CopyProductOptions';
import { YorN } from 'ncp-api-supporter';

interface FormData {
  mallNo: number;
  syncStockYn: YorN;
  copyStockYn: YorN;
  copyReviewYn: YorN;
}

export interface ThrowCopyData {
  mallProductNo: number;
  copyOptions: FormData;
}

@Component({
  components: {
    MallSelect,
    RadioGroup,
  },
})
export default class CopyProduct extends WindowPopupMainVue {
  private readonly copyFormOptions = copyFormOptions;
  private isItem = false; // 자재 상품 여부? 상품복사 관련 pro 기획이 제공되지않아 ncp 와 동일하게 처리

  created() {
    if (this.data) {
      this.isItem = this.data.isItem === 'Y';
      this.formData.mallNo = this.data.mallNo;
    }
  }

  private formData: FormData = {
    mallNo: 0,
    syncStockYn: 'N',
    copyStockYn: 'N',
    copyReviewYn: 'N',
  };

  get dialogues(): string[] {
    return this.$t('PRODUCT.COPY.DIALOGUES', { productNo: this.data.mallProductNo })
      .toString()
      .trim()
      .split('\n');
  }

  private openCopyProductWindow(data: ThrowCopyData): void {
    const { syncStockYn, mallNo, copyStockYn, copyReviewYn } = data.copyOptions;
    const originMallYn = this.data.mallNo === Number(mallNo) ? 'Y' : 'N';
    const href = this.$router.resolve({ name: 'ProductCopy' }).href;
    const path = `${href}?mallNo=${mallNo}&originMallYn=${originMallYn}&parentNo=${data.mallProductNo}&synced=${syncStockYn}&copyReviewYn=${copyReviewYn}&copyStockYn=${copyStockYn}`;
    window.open(path, 'copy_product');
  }

  private onSubmit() {
    this.openCopyProductWindow({ mallProductNo: Number(this.data.mallProductNo), copyOptions: this.formData });
    this.closePopup();
  }

  private onChangeCopyReview(value: string) {
    if (value === 'Y') {
      alert(this.$t('PRODUCT.COPY.ALERT_COPY'));
    }
    this.formData.mallNo = this.data.mallNo;
  }
}
