var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"mm-shop domain-security",on:{"click":function($event){_vm.showConnectionStatusLayer = !_vm.showConnectionStatusLayer}}},[_c('em',{staticClass:"layer-opener"},[_vm._v(_vm._s(_vm.$t('HEADER.DOMAIN_SSL_CONNECT_STATUS')))]),_c('span',{staticClass:"status-icon",class:_vm.domainSecurityRecommendation.icon}),_c('em',{class:_vm.domainSecurityRecommendation.color},[_vm._v(" "+_vm._s(_vm.$t(("ADMIN.DOMAIN_SSL." + (_vm.domainSecurityRecommendation.text))))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConnectionStatusLayer),expression:"showConnectionStatusLayer"}],staticClass:"mm-shop-tool"},[_c('section',[_c('header',[_c('span',{class:{ 'mall-name': _vm.domainSecurityInfo.domain.length }},[_vm._v(_vm._s(_vm.mallName))]),_c('span',[_vm._v(" "+_vm._s(_vm.domainSecurityInfo.domain || '')+" "+_vm._s(!_vm.isCombine && !!_vm.domainSecurityInfo.mobileDomain ? ("| " + (_vm.domainSecurityInfo.mobileDomain)) : '')+" ")])]),_c('main',[_c('table',[_vm._m(0),_c('tr',[_c('th',[_c('em',[_vm._v(_vm._s(_vm.$t('HEADER.DOMAIN')))])]),_c('td',[_c('span',{class:_vm.getStatusColor(_vm.domainSecurityInfo.domainStatus)},[_vm._v(" ● ")]),_c('span',[_vm._v(_vm._s(_vm.$t(("ADMIN.DOMAIN." + (_vm.domainSecurityInfo.domainStatus)))))]),(
                  _vm.domainSecurityInfo.domainStatus === 'CONNECTED' &&
                    _vm.getIsExpiredClose(_vm.domainSecurityInfo.domainExpirationDueDays)
                )?_c('span',{staticClass:"text-red"},[_vm._v(" ("+_vm._s(_vm.domainSecurityInfo.domainExpirationDueDays)+" "+_vm._s(_vm.$t('HEADER.DAYS_LEFT'))+") ")]):_vm._e()])]),_c('tr',[_c('th',[_c('em',[_vm._v(_vm._s(_vm.$t('HEADER.SECURITY_SERVER')))])]),(_vm.isCombine)?_c('td',[_c('span',{class:_vm.getStatusColor(_vm.domainSecurityInfo.sslStatus)},[_vm._v(" ● ")]),_c('span',[_vm._v(_vm._s(_vm.$t(("ADMIN.SSL_SECURITY." + (_vm.domainSecurityInfo.sslStatus)))))]),(
                  _vm.getIsSslInstalled(_vm.domainSecurityInfo.sslStatus) &&
                    _vm.getIsExpiredClose(_vm.domainSecurityInfo.sslExpirationDueDays)
                )?_c('span',{staticClass:"text-red"},[_vm._v(" ("+_vm._s(_vm.domainSecurityInfo.sslExpirationDueDays)+" "+_vm._s(_vm.$t('HEADER.DAYS_LEFT'))+") ")]):_vm._e()]):_c('td',[_c('div',{staticClass:"flex-wrap"},[_c('span',[_vm._v("PC")]),_c('span',[_c('span',{class:_vm.getStatusColor(_vm.domainSecurityInfo.sslStatus)},[_vm._v(" ● ")]),_c('span',[_vm._v(_vm._s(_vm.$t(("ADMIN.SSL_SECURITY." + (_vm.domainSecurityInfo.sslStatus)))))]),(
                      _vm.getIsSslInstalled(_vm.domainSecurityInfo.sslStatus) &&
                        _vm.getIsExpiredClose(_vm.domainSecurityInfo.sslExpirationDueDays)
                    )?_c('span',{staticClass:"text-red"},[_vm._v(" ("+_vm._s(_vm.domainSecurityInfo.sslExpirationDueDays)+" "+_vm._s(_vm.$t('HEADER.DAYS_LEFT'))+") ")]):_vm._e()])]),_c('div',{staticClass:"flex-wrap"},[_c('span',[_vm._v(_vm._s(_vm.$t('MOBILE')))]),_c('span',[_c('span',{class:_vm.getStatusColor(_vm.domainSecurityInfo.mobileSslStatus)},[_vm._v(" ● ")]),_c('span',[_vm._v(_vm._s(_vm.$t(("ADMIN.SSL_SECURITY." + (_vm.domainSecurityInfo.mobileSslStatus)))))]),(
                      _vm.getIsSslInstalled(_vm.domainSecurityInfo.mobileSslStatus) &&
                        _vm.getIsExpiredClose(_vm.domainSecurityInfo.mobileSslExpirationDueDays)
                    )?_c('span',{staticClass:"text-red"},[_vm._v(" ("+_vm._s(_vm.domainSecurityInfo.mobileSslExpirationDueDays)+" "+_vm._s(_vm.$t('HEADER.DAYS_LEFT'))+") ")]):_vm._e()])])])])])]),_c('footer',[_c('div',{staticStyle:{"text-align":"center"}},[_c('router-link',{attrs:{"to":{ path: '/configuration/basic/domain-ssl' }}},[_c('button',{staticClass:"crmBtn type-red",attrs:{"type":"button"}},[_vm._v(_vm._s(_vm.$t('HEADER.DOMAIN_SSL')))])])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('colgroup',[_c('col',{attrs:{"width":"60px"}}),_c('col',{attrs:{"width":"auto"}})])}]

export { render, staticRenderFns }