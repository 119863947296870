




















































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import {
  getOperateSecurityRadioBoxOption,
  getOperateSecurityToolTipOption,
  i18nSecurity,
} from '@/const/contents/configuration/operateSecurity';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import IpAddressInputList, { IpWrap } from '@/components/common/IpAddressInputList.vue';
import CountriesPicker from '@/components/common/input/data/CountriesPicker.vue';

const setIpItem = (no: number, address: string, bandwidth: string): IpWrap => ({
  no,
  ip: { address, bandwidth },
});

@Component({
  components: {
    CountriesPicker,
    IpAddressInputList,
    RadioGroup,
    ToolTip,
  },
})
export default class AdminIpAccessSetting extends Vue {
  @PropSync('ipRows') private ipRowsSync!: { len: number };
  @PropSync('ipRestrict', { required: true })
  private ipRestrictSync!: { permittedIpAddresses: string[]; restrictedNations: string[]; used: boolean };

  private permittedIpAddresses: IpWrap[] = [];

  private readonly i18nSecurity = i18nSecurity;
  private myIp = '';

  private toolTipOption = getOperateSecurityToolTipOption();
  private radioBoxOption = getOperateSecurityRadioBoxOption();

  created() {
    this.fetchMyIp();
  }

  @Watch('ipRestrict')
  private setPremittedIps() {
    const { permittedIpAddresses } = this.ipRestrictSync;

    if (!permittedIpAddresses.length) return;
    this.changedIpRows(permittedIpAddresses.length);

    const result = permittedIpAddresses.map(item => item.split('-'));
    this.permittedIpAddresses = result.map(([address, bandwidth], idx) => setIpItem(idx, address, bandwidth ?? ''));
  }

  private changedIpRows(val: number) {
    this.ipRowsSync.len = val;
  }

  private changeUsed(): void {
    this.ipRestrictSync.permittedIpAddresses = [];
    this.permittedIpAddresses = [];
    this.changedIpRows(this.ipRestrictSync.used ? 1 : 0);
  }

  private changePermittedIpAddresses(): void {
    this.ipRestrictSync.permittedIpAddresses = this.permittedIpAddresses
      .map(pia => {
        const arr = pia.ip.address.split('.').filter(ia => ia);

        if (arr && arr.length === 4) {
          return `${pia.ip.address}${pia.ip.bandwidth ? `-${pia.ip.bandwidth}` : ''}`;
        }
      })
      .filter(item => item);
  }

  async fetchMyIp() {
    const { data } = await this.$api.getManagerInfo();

    if (data?.ip) this.myIp = data.ip;
  }
}
