




























































import { Component } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { i18nExternal } from '@/const/contents/configuration/externalService';

@Component({
  components: { TextInput, NoticeBox },
})
export default class PaycoLoginPopup extends WindowPopupMainVue {
  private i18nExternal = i18nExternal;
  private noticeList = i18nExternal('PAYCO_LOGIN_NOTICE').split('\n');
  private mallName = '';
  private company = '';
  private agreeTerms = '';

  private onClickSaveButton() {
    if (this.mallName === '') {
      return alert(i18nExternal('EMPTY_MALL_NAME'));
    }
    if (this.company === '') {
      return alert(i18nExternal('EMPTY_COMPANY'));
    }

    //TODO 통신실패일 경우
    // alert(i18nExternal('PAYCO_LOGIN_FAILURE'));

    //TODO 5007에러
    // alert(i18nExternal('PAYCO_LOGIN_EXCEEDED'));

    this.onPositiveClick();
  }
}
