













































import { Component, Vue } from 'vue-property-decorator';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';

@Component({
  components: { ModalHeader },
})
export default class NaverShoppingTooltip extends Vue {}
