import { BottomNavState, BottomNavObject } from '@/types/bottomNav';

const bottomNavStore = {
  namespaced: true,
  state: {
    bottomNavigation: null,
  },
  mutations: {
    SET_BOTTOM_NAV: function(state: BottomNavState, bottomNav: BottomNavObject) {
      state.bottomNavigation = bottomNav;
    },
    UNSET_BOTTOM_NAV: function(state: BottomNavState) {
      state.bottomNavigation = null;
    },
  },
  actions: {},
  getters: {
    getBottomNav: function(state: BottomNavState) {
      return state.bottomNavigation;
    },
  },
};

export default bottomNavStore;
