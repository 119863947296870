



















import { Component, Prop, Vue } from 'vue-property-decorator';
import Message from '@/components/popup/common/Message.vue';
import NotificationGuide from '@/components/member/NotificationGuide.vue';
@Component({
  components: { NotificationGuide, Message },
})
export default class NotificationNotAvailable extends Vue {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: () => void;
  @Prop() private readonly onNegativeClick!: () => void;
}
