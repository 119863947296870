























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ComponentItemsClaimDataType } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import NoticeBox from '@/components/popup/claim/ClaimApplyPopup/NoticeBox.vue';
import OrderProducts from '@/components/popup/claim/ClaimApplyPopup/OrderProducts.vue';
import ProductExchangeDetails from '@/components/popup/claim/ClaimApplyPopup/ProductExchangeDetails.vue';
import RefundExchangeAmount from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeAmount.vue';
import BenefitsPaid from '@/components/popup/claim/ClaimApplyPopup/BenefitsPaid.vue';
import ProductExchange from '@/components/popup/claim/ClaimApplyPopup/ProductExchange.vue';
import ProductDetails from '@/components/popup/claim/ClaimApplyPopup/ProductDetails.vue';
import ReturnAddress from '@/components/popup/claim/ClaimApplyPopup/ReturnAddress.vue';
import RefundExchangeFee from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeFee.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import {
  ReturnExchangesPredict,
  PostReturnExchangesPredictRequest,
  PostReturnExchangesRequest,
  AdditionalPayType,
  RefundType,
  GetClaimsOrderOptionsOrderOptionNoApply,
  ResponsibleObjectType,
  ReasonType,
} from 'ncp-api-supporter';
@Component({
  components: {
    RefundExchangeFee,
    ReturnAddress,
    NoticeBox,
    OrderProducts,
    ProductExchange,
    ProductExchangeDetails,
    ProductDetails,
    RefundExchangeAmount,
    BenefitsPaid,
  },
})
export default class ExchangeApplyPopupAfter extends Vue {
  @Prop({ required: true }) private orderOptionsApply!: ComponentItemsClaimDataType;
  @Prop({ required: true }) private partnerNo!: string;
  @Prop({ required: true }) private memberNo!: string;
  @Prop({ required: true }) private mallNo!: string;
  @Prop({ default: false }) private escrowOrder!: boolean;
  @Prop({ required: true }) private claimOrderOptions!: GetClaimsOrderOptionsOrderOptionNoApply;
  private refOrderProducts: OrderProducts;
  private refProductDetails: ProductDetails;
  private refRefundExchangeAmount: RefundExchangeAmount;
  private refBenefitsPaid: BenefitsPaid;
  private refProductExchange: ProductExchange;
  private refProductExchangeDetails: ProductExchangeDetails;
  private refReturnAddress: ReturnAddress;
  private refRefundExchangeFee: RefundExchangeFee;
  private claimType = 'ExchangePopupAfter';
  private showA = true;
  private showB = true;
  private zeroRefundShow = false;
  private refundBankInfoTrShow = true;
  private objJson = '';
  private postReturnExchangesPredict!: ReturnExchangesPredict;
  public checkedExchangesProduct = false;
  private displayedDeliveryAmtDiscountMessage = false;
  private refundTypeDivShow = false;
  private returnWayType = 'SELLER_COLLECT';
  private returnAddressButton = false;
  private mall = {
    countryCd: 'KR',
  };
  private initPostReturnExchangesPredict() {
    this.postReturnExchangesPredict = {
      refundType: 'ACCOUNT',
      shipping: {
        requiresShipping: false,
        address: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
        shippingNo: 0,
        hasAdjustedExchangeDeliveryAmt: false,
        divided: false,
        deliveryGroupNo: 0,
        deliveryType: '',
        prepaid: true,
        deliveryTemplateNo: 0,
        combined: false,
        originalShippingNo: 0,
      },
      coupon: {
        productCoupons: [],
        cartCoupon: {
          couponName: '',
          couponIssueNo: 0,
          orderProductNo: 0,
          restores: false,
          couponNo: 0,
        },
      },
      amounts: {
        returnDelivery: {
          totalReturnDeliveryAmt: 0,
          totalPureReturnDeliveryAmt: 0,
          prepaidTotalPureReturnDeliveryAmt: 0,
          prepaidProcessedDeliveryAmt: 0,
          returnRemoteDeliveryAmt: 0,
          returnDeliveryAmt: 0,
          prepaidReturnRemoteDeliveryAmt: 0,
          processedDeliveryAmt: 0,
          prepaid: false,
          prepaidTotalReturnDeliveryAmt: 0,
          prepaidReturnDeliveryAmt: 0,
        },
        exchangeDelivery: {
          prepaidRemoteDeliveryAmt: 0,
          deliveryAmt: 0,
          remoteDeliveryAmt: 0,
          totalDeliveryAmt: 0,
          prepaidDeliveryAmt: 0,
          totalPrepaidDeliveryAmt: 0,
        },
        deliveryAdjustAmt: 0,
        returnDeliveryAdjustAmt: 0,
        adjustedAmounts: {
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          claimAmt: 0,
          additionalPayAmt: 0,
          payAmt: 0,
        },
        before: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        productAdjustAmt: 0,
        after: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        exchangeDeliveryAdjustAmt: 0,
        refund: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
              alreadyPaidTotalDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
            cancelTotalPrice: 0,
            exchangeTotalPrice: 0,
            exchangeProductAmtAdjustAmt: 0,
            exchangeAddTotalPrice: 0,
            productCouponDiscountAmt: 0,
            totalPayAmt: 0,
            totalDeliveryAmt: 0,
            totalExchangeAmt: 0,
          },
        },
      },
      exchangeShipping: {
        address: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
        divided: false,
        deliveryType: '',
        remoteDeliveryAmt: 0,
        prepaid: true,
        requiresShipping: false,
        deliveryAmt: 0,
        shippingNo: 0,
        returnDeliveryAmt: 0,
        deliveryGroupNo: 0,
        deliveryTemplateNo: 0,
        combined: false,
        originalShippingNo: 0,
      },
      overflowsPgAmt: false,
    };
    this.objJson = JSON.stringify(this.postReturnExchangesPredict);
  }
  private clearData() {
    this.initPostReturnExchangesPredict();
    this.$nextTick(() => {
      this.refProductExchangeDetails.exchangeProductAmtAdjustAmt = 0;
      this.refProductExchangeDetails.exchangeProductAmtAdjustReason = '';
      this.refRefundExchangeFee.returnDeliveryAmtAdjustAmt = 0;
      this.refRefundExchangeFee.returnDeliveryAmtAdjustReason = '';
      this.refRefundExchangeFee.exchangeDeliveryAmtAdjustAmt = 0;
      this.refRefundExchangeFee.exchangeDeliveryAmtAdjustReason = '';
      this.refProductExchangeDetails.defaultCalculation();
    });
    this.checkedExchangesProduct = false;
  }
  private created() {
    this.initPostReturnExchangesPredict();
  }
  private accumulationUsed = false;
  private setData() {
    this.setRefComponent();
    const exchangeOptionNo = Number(this.refProductExchange.mallOptionValue);
    const exchangeCnt: number = this.refProductExchange.exchangeCnt;
    const cancelCnt: number =
      this.refOrderProducts.orderCntArray === null ||
      this.refOrderProducts.orderCntArray === undefined ||
      this.refOrderProducts.orderCntArray.length === 0
        ? 0
        : this.refOrderProducts.orderCntArray[0];
    if (exchangeOptionNo) {
      const request: PostReturnExchangesPredictRequest = {
        data: {
          cancelOrderOptionNo: Number(this.orderOptionsApply.claimableOptions[0].orderOptionNo),
          responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue as ResponsibleObjectType,
          exchangeCnt: exchangeCnt,
          orderNo: this.orderOptionsApply.orderNo,
          cancelCnt: cancelCnt,
          sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
          returnWayType: this.refReturnAddress.returnWayType,
          productAdjustAmt:
            this.refProductExchangeDetails === undefined
              ? 0
              : this.refProductExchangeDetails.exchangeProductAmtAdjustAmt,
          returnAddress: {
            address: this.refReturnAddress.address,
            name: this.refReturnAddress.returnName,
            jibunAddress: this.refReturnAddress.returnJibunAddress,
            detailAddress: this.refReturnAddress.detailAddress,
            zipCd: this.refReturnAddress.zipCd,
            contact1: this.refReturnAddress.returnContact1,
            contact2: this.refReturnAddress.returnContact2,
          },
          exchangeOptionNo: exchangeOptionNo,
          returnDeliveryProposedAmt: this.refRefundExchangeFee.returnDeliveryAmtAdjustAmt,
          exchangeAddress: {
            address: this.refReturnAddress.exchangeAddress,
            name: this.refReturnAddress.exchangeName,
            jibunAddress: this.refReturnAddress.exchangeJibunAddress,
            detailAddress: this.refReturnAddress.exchangeDetailAddress,
            zipCd: this.refReturnAddress.exchangeZipCd,
            contact1: this.refReturnAddress.exchangeContact1,
            contact2: this.refReturnAddress.exchangeContact2,
          },
          exchangeDeliveryProposedAmt: this.refRefundExchangeFee.exchangeDeliveryAmtAdjustAmt,
          exchangeProductNo: Number(this.refProductExchange.mallProductNo),
          refundType:
            this.orderOptionsApply.payType === 'NAVER_PAY'
              ? 'PG'
              : (this.orderOptionsApply.refundTypes[0].refundType as RefundType),
        },
      };
      this.$api.postReturnExchangesPredict(request).then(response => {
        this.postReturnExchangesPredict = response.data;
        this.accumulationUsed = this.refProductExchange.accumulationUsed;
        this.refundTypeDivShow =
          this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt < 0 ||
          (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt === 0 &&
            this.orderOptionsApply.payType === 'ZERO_PAY');
        this.checkedExchangesProduct = true;
        this.objJson = JSON.stringify(this.postReturnExchangesPredict);
      });
    } else {
      this.initPostReturnExchangesPredict();
    }
  }
  private close(): void {
    if (!confirm(this.$t('CLAIM.EXCHANGEPOPUP.CLOSECONFIRM').toString())) {
      return;
    }
    window.close();
  }
  private validation(): boolean {
    if (this.postReturnExchangesPredict !== null) {
      const returnDeliveryAmtAdjustAmt = this.refRefundExchangeFee.returnDeliveryAmtAdjustAmt;
      const totalDeliveryAmt =
        this.postReturnExchangesPredict.amounts.returnDelivery.returnDeliveryAmt +
        this.postReturnExchangesPredict.amounts.returnDelivery.returnRemoteDeliveryAmt;
      if (returnDeliveryAmtAdjustAmt > totalDeliveryAmt) {
        this.refRefundExchangeFee.returnDeliveryAmtAdjustAmt = returnDeliveryAmtAdjustAmt;
        alert(this.$t('CLAIM.RETURNPOPUP.ALTER11'));
        return false;
      }
      const exchangeDeliveryAmtAdjustAmt = this.refRefundExchangeFee.exchangeDeliveryAmtAdjustAmt;
      if (
        exchangeDeliveryAmtAdjustAmt >
        this.postReturnExchangesPredict.amounts.exchangeDelivery.deliveryAmt +
          this.postReturnExchangesPredict.amounts.exchangeDelivery.remoteDeliveryAmt
      ) {
        this.refRefundExchangeFee.exchangeDeliveryAmtAdjustAmt = exchangeDeliveryAmtAdjustAmt;
        alert(this.$t('CLAIM.RETURNPOPUP.ALTER11'));
        return false;
      }
    }
    //반품주소지 정보 valid
    if (this.refReturnAddress.returnName === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER15'));
      (this.refReturnAddress.$refs.returnNameRef as TextInput).focus();
      return false;
    }
    if (this.refReturnAddress.returnContact1 === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER16'));
      (this.refReturnAddress.$refs.returnContact1Ref as TextInput).focus();
      return false;
    }
    if (this.refReturnAddress.returnContact1.replaceAll('-', '').length < 10) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER19'));
      (this.refReturnAddress.$refs.returnContact1Ref as TextInput).focus();
      return false;
    }
    if (this.refReturnAddress.zipCd === '' || this.refReturnAddress.address === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER17'));
      (this.refReturnAddress.$refs.returnDetailAddress as TextInput).focus();
      return false;
    }
    if (this.refReturnAddress.exchangeName === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER12'));
      (this.refReturnAddress.$refs.exchangeNameRef as TextInput).focus();
      return false;
    }
    if (this.refReturnAddress.exchangeContact1 === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER13'));
      (this.refReturnAddress.$refs.exchangeContact1Ref as TextInput).focus();
      return false;
    }
    if (this.refReturnAddress.exchangeContact1.replaceAll('-', '').length < 10) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER19'));
      (this.refReturnAddress.$refs.exchangeContact1Ref as TextInput).focus();
      return false;
    }
    if (this.refReturnAddress.exchangeZipCd === '' || this.refReturnAddress.exchangeAddress === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER14'));
      (this.refReturnAddress.$refs.exchangeDetailAddressRef as TextInput).focus();
      return false;
    }
    return true;
  }
  private validationBeforeApply(): boolean {
    if (
      this.refProductExchangeDetails.exchangeProductAmtAdjustAmt > 0 &&
      this.refProductExchangeDetails.exchangeProductAmtAdjustReason === ''
    ) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER6'));
      (this.refProductExchangeDetails.$refs.exchangeProductAmtAdjustReasonRef as TextInput).focus();
      return false;
    }
    if (
      this.refRefundExchangeFee.returnDeliveryAmtAdjustAmt > 0 &&
      this.refRefundExchangeFee.returnDeliveryAmtAdjustReason === ''
    ) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER6'));
      (this.refProductExchangeDetails.$refs.returnDeliveryAmtAdjustReasonRef as TextInput).focus();
      return false;
    }
    if (
      this.refRefundExchangeFee.exchangeDeliveryAmtAdjustAmt > 0 &&
      this.refRefundExchangeFee.exchangeDeliveryAmtAdjustReason === ''
    ) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER6'));
      (this.refProductExchangeDetails.$refs.exchangeDeliveryAmtAdjustReasonRef as TextInput).focus();
      return false;
    }
    let refundType = '';
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt === 0) {
      refundType = 'ZERO_REFUND';
    } else if (
      this.refRefundExchangeAmount.refundTypeValue === undefined ||
      this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt >= 0
    ) {
      refundType = null;
    } else {
      refundType = this.refRefundExchangeAmount.refundTypeValue;
    }
    let needBankAccount = false;
    switch (refundType) {
      case 'CASH':
      case 'ACCOUNT':
        needBankAccount = true;
        break;
      case 'PG':
        if (
          this.orderOptionsApply.payType === 'VIRTUAL_ACCOUNT' ||
          this.orderOptionsApply.payType === 'ESCROW_VIRTUAL_ACCOUNT'
        ) {
          needBankAccount = true;
        }
        break;
    }
    if (needBankAccount && this.refRefundExchangeAmount.refundBankValue === null) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER7'));
      (this.refRefundExchangeAmount.$refs.refundBankAccountRef as TextInput).focus();
      return false;
    }
    if (needBankAccount && this.refRefundExchangeAmount.refundBankAccount === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER8'));
      (this.refRefundExchangeAmount.$refs.refundBankAccountRef as TextInput).focus();
      return false;
    }
    if (needBankAccount && this.refRefundExchangeAmount.refundBankDepositorName === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER9'));
      (this.refRefundExchangeAmount.$refs.refundBankDepositorNameRef as TextInput).focus();
      return false;
    }
    let exchangePayType = '';
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      exchangePayType = this.refRefundExchangeAmount.exchangePayType;
    }
    const needAdditionalPay: boolean = exchangePayType === 'CASH';
    let tradeBankAccountInfo = null;
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      const bankStringArr =
        String(this.refRefundExchangeAmount.tradeBankAccountInfo).indexOf('|') >= 0
          ? String(this.refRefundExchangeAmount.tradeBankAccountInfo).split('|')
          : [null, null, null];
      tradeBankAccountInfo = {
        bank: bankStringArr[0],
        bankAccount: bankStringArr[1],
        bankDepositorName: bankStringArr[2],
      };
    }
    if (needAdditionalPay && (tradeBankAccountInfo === null || tradeBankAccountInfo.bank === null)) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER10'));
      (this.refRefundExchangeAmount.$refs.tradeBankAccountInfoRef as TextInput).focus();
      return false;
    }
    let remitter = '';
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      remitter = this.refRefundExchangeAmount.remitter;
    }
    if (needAdditionalPay && remitter === '') {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER11'));
      (this.refRefundExchangeAmount.$refs.remitterRef as TextInput).focus();
      return false;
    }
    return true;
  }
  private onClickApplyExchange() {
    if (!this.validation()) {
      return;
    }
    const exchangeOptionNo = Number(this.refProductExchange.mallOptionValue);
    if (!exchangeOptionNo) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER4'));
      return;
    }
    if (
      this.postReturnExchangesPredict.overflowsPgAmt &&
      this.refRefundExchangeAmount.refundTypeValue !== 'ACCOUNT' &&
      this.refRefundExchangeAmount.refundTypeValue !== 'ACCUMULATION'
    ) {
      alert(this.$t('CLAIM.EXCHANGEPOPUP.ALTER3'));
      return;
    }
    if (!this.validationBeforeApply()) {
      return;
    }
    if (!confirm('교환신청하시겠습니까?')) {
      return;
    }
    let exchangePayType: AdditionalPayType = null;
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt > 0) {
      exchangePayType = this.refRefundExchangeAmount.exchangePayType as AdditionalPayType;
    }
    let remitter = '';
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt > 0 && exchangePayType === 'CASH') {
      remitter = this.refRefundExchangeAmount.remitter;
    }
    let tradeBankAccountInfo = null;
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt > 0 && exchangePayType === 'CASH') {
      const bankStringArr =
        String(this.refRefundExchangeAmount.tradeBankAccountInfo).indexOf('|') >= 0
          ? String(this.refRefundExchangeAmount.tradeBankAccountInfo).split('|')
          : [null, null, null];
      let bankNm = '';
      this.refRefundExchangeAmount.refundBankList.forEach((element: { value: string; name: string }) => {
        if (element.value === bankStringArr[0]) {
          bankNm = element.name;
        }
      });
      tradeBankAccountInfo = {
        bank: bankStringArr[0],
        bankName: bankNm,
        bankAccount: bankStringArr[1],
        bankDepositorName: bankStringArr[2],
      };
    } else {
      tradeBankAccountInfo = {
        bank: null,
        bankName: null,
        bankAccount: null,
        bankDepositorName: null,
      };
    }
    let bank = '';
    let bankNm = '';
    if (this.refRefundExchangeAmount.refundBankEditShow === false) {
      bank = null;
      bankNm = null;
    } else {
      bank = this.refRefundExchangeAmount.refundBankValue;
      this.refRefundExchangeAmount.refundBankList.forEach((element: { value: string; name: string }) => {
        if (element.value === this.refRefundExchangeAmount.refundBankValue) {
          bankNm = element.name;
        }
      });
    }
    let refundType = '';
    if (this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt === 0) {
      refundType = 'ZERO_REFUND';
    } else if (
      this.refRefundExchangeAmount.refundTypeValue === undefined ||
      this.postReturnExchangesPredict.amounts.adjustedAmounts.claimAmt >= 0
    ) {
      refundType = null;
    } else {
      refundType = this.refRefundExchangeAmount.refundTypeValue;
    }
    const request: PostReturnExchangesRequest = {
      data: {
        returnExchangeImages: [],
        productAdjustReason: this.refProductExchangeDetails.exchangeProductAmtAdjustReason,
        additionalPayRemitter: remitter,
        reasonDetail: this.refOrderProducts.claimReasonDetail[0] as string,
        additionalPayBankAccount: {
          depositorName: tradeBankAccountInfo.bankDepositorName,
          bank: tradeBankAccountInfo.bank,
          bankName: tradeBankAccountInfo.bankName,
          account: tradeBankAccountInfo.bankAccount,
        },
        additionalPayType: exchangePayType,
        purchaserInputs: this.refProductExchange.mallInputs.map(({ inputText }, index) => ({
          inputValue: this.refProductExchange.listMallInput[index],
          inputLabel: inputText,
        })),
        calculateParam: {
          cancelOrderOptionNo: Number(this.orderOptionsApply.claimableOptions[0].orderOptionNo),
          responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue as ResponsibleObjectType,
          exchangeCnt: this.refProductExchange.exchangeCnt,
          orderNo: this.orderOptionsApply.orderNo,
          cancelCnt:
            this.refOrderProducts.orderCntArray === null ||
            this.refOrderProducts.orderCntArray === undefined ||
            this.refOrderProducts.orderCntArray.length === 0
              ? 0
              : this.refOrderProducts.orderCntArray[0],
          sellerPaysClaimedDelivery: this.refOrderProducts.responsibleObjectTypeValue === 'SELLER',
          returnWayType: this.refReturnAddress.returnWayType,
          productAdjustAmt:
            this.refProductExchangeDetails === undefined
              ? 0
              : this.refProductExchangeDetails.exchangeProductAmtAdjustAmt,
          returnAddress: {
            address: this.refReturnAddress.address,
            name: this.refReturnAddress.returnName,
            jibunAddress: this.refReturnAddress.returnJibunAddress,
            detailAddress: this.refReturnAddress.detailAddress,
            zipCd: this.refReturnAddress.zipCd,
            contact1: this.refReturnAddress.returnContact1,
            contact2: this.refReturnAddress.returnContact2,
          },
          refundType: refundType as any,
          exchangeOptionNo: Number(this.refProductExchange.mallOptionValue),
          returnDeliveryProposedAmt: this.refRefundExchangeFee.returnDeliveryAmtAdjustAmt,
          exchangeAddress: {
            address: this.refReturnAddress.exchangeAddress,
            name: this.refReturnAddress.exchangeName,
            jibunAddress: this.refReturnAddress.exchangeJibunAddress,
            detailAddress: this.refReturnAddress.exchangeDetailAddress,
            zipCd: this.refReturnAddress.exchangeZipCd,
            contact1: this.refReturnAddress.exchangeContact1,
            contact2: this.refReturnAddress.exchangeContact2,
            customsIdNumber: this.refReturnAddress.exchangeCustomsIdNumber,
          },
          exchangeDeliveryProposedAmt: this.refRefundExchangeFee.exchangeDeliveryAmtAdjustAmt,
          exchangeProductNo: Number(this.refProductExchange.mallProductNo),
        },
        returnDeliveryAdjustReason: this.refRefundExchangeFee.returnDeliveryAmtAdjustReason,
        exchangeDeliveryAdjustReason: this.refRefundExchangeFee.exchangeDeliveryAmtAdjustReason,
        reasonType: this.refOrderProducts.claimReasonType[0] as ReasonType,
        invoiceNo: this.refReturnAddress.invoiceNo.length !== 0 ? this.refReturnAddress.invoiceNo : null,
        deliveryCompanyType:
          this.refReturnAddress.deliveryCompanyType.length !== 0 ? this.refReturnAddress.deliveryCompanyType : null,
        returnDeliveryNote: this.refReturnAddress.returnDeliveryNote,
        exchangeDeliveryNote: this.refReturnAddress.exchangeDeliveryNote,
        refundBankAccount: {
          depositorName: this.refRefundExchangeAmount.refundBankDepositorName,
          bank: bank,
          bankName: bankNm,
          account: this.refRefundExchangeAmount.refundBankAccount,
        },
      },
    };
    this.$api.postReturnExchanges(request).then(() => {
      alert(this.$t('처리되었습니다.'));
      window.opener.location.reload();
      window.close();
    });
  }
  public getPayType(payType: string) {
    switch (payType) {
      case 'ACCOUNT':
        return 'ACCOUNT';
      case 'ACCUMULATION':
        return 'ACCUMULATION';
      case 'ZERO_PAY':
        return 'ZERO_REFUND';
      case 'VIRTUAL_ACCOUNT':
      case 'ESCROW_VIRTUAL_ACCOUNT':
        return 'PG';
      default:
        return 'PG';
    }
  }
  private onClickSameProduct() {
    (this.$refs.componentProductExchange as ProductExchange).onClickSameProduct();
  }
  private onClickSameOptionProduct() {
    (this.$refs.componentProductExchange as ProductExchange).onClickSameOptionProduct();
  }
  private onClickDifferentProduct() {
    (this.$refs.componentProductExchange as ProductExchange).onClickDifferentProduct();
  }
  private clickReturnAddressButton(type) {
    type === 'open' ? (this.returnAddressButton = true) : (this.returnAddressButton = false);
  }
  private setRefComponent() {
    this.refOrderProducts = this.$refs.componentItemsClaim as OrderProducts;
    this.refProductExchange = this.$refs.componentProductExchange as ProductExchange;
    this.refProductExchangeDetails = this.$refs.componentProductExchangeDetails as ProductExchangeDetails;
    this.refProductDetails = this.$refs.componentProductDetails as ProductDetails;
    this.refRefundExchangeAmount = this.$refs.componentRefundExchangeAmount as RefundExchangeAmount;
    this.refBenefitsPaid = this.$refs.componentBenefitsPaid as BenefitsPaid;
    this.refReturnAddress = this.$refs.componentReturnAddress as ReturnAddress;
    this.refRefundExchangeFee = this.$refs.componentRefundExchangeFee as RefundExchangeFee;
  }
  mounted() {
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-1200');
    });
  }
}
