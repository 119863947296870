






import { Vue, Component } from 'vue-property-decorator';
import MallSelector from '@/components/MallSelector.vue';
import { Getter } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';

@Component({
  components: {
    MallSelector,
  },
})
export default class AccumulationSetting extends Vue {
  @Getter('mall/getMalls') private malls!: Mall[];
}
