


















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ProductEdit extends Vue {
  private get isSlave(): boolean {
    return this.$route.query.synced === 'Y' && Number(this.parentNo) > 0;
  }
  private get parentNo(): string {
    return this.$route.query?.parentNo?.toString();
  }
}
