import { i18n } from '@/main';
import { getDownToday } from '@/utils/dateFormat';
import { GetStandardCategoriesExcelRequest, NCPResponse, StandardCategoriesExcel } from 'ncp-api-supporter';
import { writeExcelFile } from '@/utils/webExcel';
import { api } from '@/api';

interface MallSummary {
  mallNo: number;
  mallName: string;
}

export default class ExcelDownload {
  private readonly mallSummary!: MallSummary;

  constructor(mallSummary) {
    this.mallSummary = mallSummary;
  }

  private static getColumns = () => {
    return {
      depth1CategoryNo: i18n.t('CATEGORY_NO'),
      depth1CategoryName: '1depth',
      depth2CategoryNo: i18n.t('CATEGORY_NO'),
      depth2CategoryName: '2depth',
      depth3CategoryNo: i18n.t('CATEGORY_NO'),
      depth3CategoryName: '3depth',
      depth4CategoryNo: i18n.t('CATEGORY_NO'),
      depth4CategoryName: '4depth',
      commissionRate: i18n.t('PRODUCT.COMMISSION.WORD') + '(%)',
    };
  };

  // standard_category_{현재일시}
  private static getFileName = (): string => {
    const date = getDownToday();

    return `standard_category_${date}`;
  };

  private static generateWebExcelFile = (data: StandardCategoriesExcel[]): void => {
    const fileName = ExcelDownload.getFileName();
    const columns = ExcelDownload.getColumns();

    writeExcelFile<StandardCategoriesExcel>(data, columns, fileName, true);
  };

  private static fetchStandardCategoriesExcelData = async (mallNo: number): Promise<StandardCategoriesExcel[]> => {
    const request: GetStandardCategoriesExcelRequest = {
      params: {
        mallNo,
      },
    };

    try {
      const { data }: NCPResponse<StandardCategoriesExcel[]> = await api.getStandardCategoriesExcel(request);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  public run = async () => {
    const { mallNo } = this.mallSummary;

    const data: StandardCategoriesExcel[] = await ExcelDownload.fetchStandardCategoriesExcelData(mallNo);
    if (data) ExcelDownload.generateWebExcelFile(data);
  };
}
