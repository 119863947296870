import { orderStatusTypes } from '@/const/order.ts';

export default [
  { value: orderStatusTypes.DEPOSIT_WAIT, name: 'ORDER.ORDER_STATUS_TYPE.DEPOSIT_WAIT' },
  { value: orderStatusTypes.PAY_DONE, name: 'ORDER.ORDER_STATUS_TYPE.PAY_DONE' },
  { value: orderStatusTypes.PRODUCT_PREPARE, name: 'ORDER.ORDER_STATUS_TYPE.PRODUCT_PREPARE' },
  { value: orderStatusTypes.DELIVERY_PREPARE, name: 'ORDER.ORDER_STATUS_TYPE.DELIVERY_PREPARE' },
  { value: orderStatusTypes.DELIVERY_ING, name: 'ORDER.ORDER_STATUS_TYPE.DELIVERY_ING' },
  { value: orderStatusTypes.DELIVERY_DONE, name: 'ORDER.ORDER_STATUS_TYPE.DELIVERY_DONE' },
  { value: orderStatusTypes.BUY_CONFIRM, name: 'ORDER.ORDER_STATUS_TYPE.BUY_CONFIRM' },
];
