





































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { ClaimClassType, ClaimType } from '@/types/claim';
import { isReturnClaim, isReturnExchange } from '@/components/popup/claim/claimConditions';
import { OptionData } from '@/helpers/type';
import { DeliveryCompany, NCPResponse, ReturnAddress } from 'ncp-api-supporter';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { throwPopup } from '@/helpers/popup';

@Component({
  components: {
    TextInput,
    SelectBox,
  },
  computed: {
    isReturnExchange,
    isReturnClaim,
    isNoDeliveryOrder() {
      // 배송안함 상품인지
      return this.returnAddress === undefined || this.returnAddress === null;
    },
  },
})
export default class ClaimInformation extends Vue {
  @Prop({ required: true })
  private readonly claimType: ClaimType;

  @Prop({ required: false, default: null })
  private readonly claimClassType: null | ClaimClassType;

  @Prop({ required: true })
  private readonly claimReasonType!: string;

  @Prop({ required: true })
  private readonly claimReasonDetail!: string;

  @Prop({ required: false, default: null })
  private readonly returnWayType: string;

  @Prop({ required: false, default: null })
  private readonly returnAddress: null | ReturnAddress;

  @Prop({ required: false, default: null })
  private readonly exchangeAddress: null | ReturnAddress;

  @Prop({ required: false, default: null })
  private readonly claimImageUrls: null | string[];

  @PropSync('deliveryCompanyType', { required: false, default: null })
  private deliveryCompanyTypeSync!: null | string;

  @PropSync('invoiceNo', { required: false, default: null })
  private invoiceNoSync!: null | string;

  private deliveryCompanyOptions: OptionData<string>[] = [];

  created() {
    this.fetchDeliveryCompanies();
  }

  private async fetchDeliveryCompanies(): Promise<void> {
    const { data }: NCPResponse<DeliveryCompany[]> = await this.$api.getDeliveryCompanies({
      params: { countryCd: 'KR' },
    });

    const deliveryCompanyOptions = data.map(deliveryCompany => ({
      name: deliveryCompany.label,
      value: deliveryCompany.deliveryCompanyType,
    }));

    this.setDeliveryCompanyOptions(deliveryCompanyOptions);
  }

  private setDeliveryCompanyOptions(deliveryCompanyOptions: OptionData<string>[]) {
    this.deliveryCompanyOptions = [...deliveryCompanyOptions];
  }

  private onOpenImage(clickedImageIndex: number) {
    throwPopup({
      name: 'ReturnImage',
      data: {
        initSlideIndex: clickedImageIndex,
        imageUrls: this.claimImageUrls,
      },
    });
  }
}
