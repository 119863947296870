




































import { Component, Mixins } from 'vue-property-decorator';
import OrderDetails from '@/components/shipping/order/mixins/OrderDetail';
import { GetCsRequest, GetTaskMessagesRequest, GetCs, NCPResponse, Mall, GetOrdersByOrderNo } from 'ncp-api-supporter';
import { namespace } from 'vuex-class';

const mallStore = namespace('mall');

@Component({
  components: {},
})
export default class OrderDetail extends Mixins(OrderDetails) {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private mallNm = '';
  private orderNo = this.$route.query.orderNo;
  private csProcessNum = 0;
  private csTotalNum = 0;
  private taskProcessNum = 0;
  private taskTotalNum = 0;
  private csAutoList = [];
  private needHandleList = [];
  private orderDetail: GetOrdersByOrderNo | null = null;

  private get isNaverPayOrder() {
    return !!this.orderDetail?.externalOrderNo;
  }

  private async fetchOrderDetail() {
    const { data }: NCPResponse<GetOrdersByOrderNo> = await this.$api.getOrdersByOrderNo({
      params: { orderNo: this.$route.query.orderNo.toString() },
    });

    this.orderDetail = data;
  }

  private setMallName() {
    const currentMall = this.malls.find(mall => mall.mallNo === this.orderDetail.mallNo);
    this.mallNm = currentMall.mallName;
  }

  private async GetCs() {
    const csRequest: GetCsRequest = {
      params: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    const { data }: NCPResponse<GetCs[]> = await this.$api.getCs(csRequest);

    this.csAutoList = data.filter(item => {
      return item.csPathType === 'SYSTEM';
    });
    this.needHandleList = data.filter(item => {
      return item.csPathType !== 'SYSTEM';
    });
    this.csTotalNum = this.needHandleList.length;
    if (this.needHandleList.length > 0) {
      this.csProcessNum = this.needHandleList.filter(cs => {
        return cs.csStatusType === 'PROCESSING';
      }).length;
    }
  }

  public getTasks() {
    const taskRequest: GetTaskMessagesRequest = {
      params: {
        orderNo: this.$route.query.orderNo as string,
      },
    };
    this.$api.getTaskMessages(taskRequest).then(({ data }) => {
      this.taskTotalNum = data.length;
      this.taskProcessNum = data.filter(item => {
        return item.taskMessageStatusType !== 'DONE';
      }).length;
    });
  }

  async created() {
    await this.fetchOrderDetail();
    this.setMallName();
    this.GetCs();
    this.getTasks();
  }
}
