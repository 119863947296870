




import { Vue, Component } from 'vue-property-decorator';
import ProductAdd from './ProductAdd.vue';

@Component({
  components: {
    ProductAdd,
  },
})
export default class ProductEdit extends Vue {}
