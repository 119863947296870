







































import { Component, Vue, Prop } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
@Component({
  components: { TextInput },
})
export default class CouponPayBack extends Vue {
  @Prop() private readonly data: { couponIssueNo: string };
  @Prop() private onNegativeClick!: Function;
  @Prop() private onPositiveClick!: Function;
  private payBackReason = '';

  private payBackReasonKeyUp() {
    this.payBackReason = this.payBackReason
      .replace(/[“]/g, '')
      .replace(/[<]/g, '')
      .replace(/[>]/g, '')
      .replace(/[\\]/g, '');
  }

  private onPayBackClick() {
    if (this.payBackReason == undefined || this.payBackReason == '') {
      alert(this.$t('PROMOTION.COUPON_ISSUE.NO_REASON'));
      return;
    }
    if (!confirm(window.$t('PROMOTION.COUPON_ISSUE.ADD_REASON_CONFIRM'))) {
      return;
    }

    const obj = {
      pathParams: {
        couponIssueNo: String(this.data.couponIssueNo),
      },
      data: {
        reason: this.payBackReason,
      },
    };
    this.$api.putCouponsIssuesCouponIssueNoWithdraw(obj).then(() => {
      alert(this.$t('PROMOTION.COUPON_ISSUE.PAYBACK_OK'));
      this.onPositiveClick();
    });
  }
}
