


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class MessagePopup extends Vue {
  @Prop() private data;
  @Prop() private readonly onPositiveClick!: () => void;
  @Prop() private readonly onNegativeClick!: () => void;

  get headerText(): TranslateResult {
    return this.data.headerText ?? this.$t('ALERT');
  }
  get leftBtnText(): TranslateResult {
    return this.data.cancelButtonText ?? this.$t('CLOSE');
  }
  get rightBtnText(): TranslateResult {
    return this.data.okButtonText ?? this.$t('CONFIRM');
  }
}
