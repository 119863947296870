


























import { Vue, Component, Prop } from 'vue-property-decorator';
import type { AppsAppNoInstalledMalls, Nullable } from 'ncp-api-supporter';
import { executeApp } from '../../navigation/lnb';
import ModalHeader from '@/components/popup/layout/ModalHeader.vue';
import SelectBox from '@/components/common/SelectBox.vue';

type ModalData = {
  appInstalledMalls: Nullable<AppsAppNoInstalledMalls[]>;
  selectedAppNo: number;
};

@Component({ components: { ModalHeader, SelectBox } })
export default class SelectMallModal extends Vue {
  @Prop() private data: ModalData;

  private selectedMallNo = 0;

  private initialOption = {
    id: 0,
    value: 0,
    name: '쇼핑몰을 선택해 주세요.',
  };

  private handleSelectMall(mallNo: number) {
    const { appInstalledMalls, selectedAppNo } = this.data;
    this.selectedMallNo = mallNo;
    const selectedMall = appInstalledMalls?.find(mall => mall.mallNo === mallNo);

    executeApp(selectedAppNo, selectedMall);
    this.$emit('click:close');
  }
}
