




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TargetMemberList extends Vue {
  @Prop() private readonly data;

  private isMemberSelectedArea = false;

  private get changeOneMember(): string {
    if (this.data.contents[0]) {
      return `${this.data.contents[0].name}(${this.data.contents[0].id ||
        this.getMemberId(this.data.contents[0].openIdProvider)})`;
    } else {
      return `${this.data.contents.memberName}(${this.data.contents.memberId || this.$t('MEMBER.LIST.SIMPLE_LOGIN')})`;
    }
  }

  private getMemberId(openIdProvider) {
    switch (openIdProvider) {
      case 'PAYCO':
        return this.$t('PAYCO_KO').toString();
      case 'NAVER':
        return this.$t('NAVER_KO').toString();
      case 'KAKAO':
        return this.$t('KAKAO_KO').toString();
      case 'FACEBOOK':
        return this.$t('FACEBOOK_KO').toString();
    }
  }
}
