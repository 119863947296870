


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import {
  configureCheckNoticeList,
  getGoogleCheckRequireOption,
  getCampaignsRequirements,
} from './GoogleConfigureCheck';
import { throwWindowPopup, throwPopup, PopupClose } from '@/helpers/popup';
import { GetConfigurationsCampaignsRequirements, GetConfigurationsCampaignsCampaignNo } from 'ncp-api-supporter';

@Component({
  components: {
    NoticeBox,
    ToolTip,
  },
})
export default class GoogleConfigureCheck extends Vue {
  @Prop() private readonly data;

  private isReStartValidate = false;
  private getGoogleCheckRequireOption = getGoogleCheckRequireOption();
  private noticeList = configureCheckNoticeList();
  private requirementsData: GetConfigurationsCampaignsRequirements = this.data.requirementsData;
  private mallNo: number = this.data.mallNo;
  private adData?: GetConfigurationsCampaignsCampaignNo = this.data.adData;

  private async reStartAdManage() {
    this.isReStartValidate = true;
    const { isAllTrue, data } = await getCampaignsRequirements(this.mallNo);

    if (isAllTrue) {
      this.$emit('click:close');
      this.openGoogleAdRegistPopup();
    }

    this.requirementsData = data;
    this.isReStartValidate = false;
  }

  private openGoogleAdRegistPopup() {
    throwPopup({
      name: 'GoogleAdRegist',
      data: {
        mallNo: this.mallNo,
      },
    }).then(res => {
      if (res.state === PopupClose.CLOSE) {
        this.$emit('click:close');
      } else if (res.data.state === 'reRender') {
        this.$emit('confirm', { state: 'reRender' });
      }
    });
  }

  private openConfigPopup(type: string) {
    if (type === 'merchantRegist') throwWindowPopup('MerchantUserManage', { mallNo: this.mallNo }, 'md');
    if (type === 'adUserRegist') throwWindowPopup('AdsUserManage', { mallNo: this.mallNo }, 'md');
  }

  private cancelPopup() {
    this.$emit('click:close');

    if (this.adData) {
      return;
    } else {
      this.$router.go(-1);
    }
  }
}
