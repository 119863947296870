














import { Component, Prop, Vue } from 'vue-property-decorator';
import { Popup } from '@/types/popup';
import { PopupClose } from '@/helpers/popup';

const requireModules = require.context('./', true, /\.vue$/);
const components = {};
requireModules.keys().map((fileName: string) => {
  if (fileName.indexOf('Container.vue') < 0) {
    const name = fileName.slice(fileName.lastIndexOf('/') + 1, fileName.length - 4);
    components[name] = () => import(`@/components/popup/${fileName.slice(2)}`);
  }
});

@Component({ components: components })
export default class PopupMain extends Vue {
  @Prop({ required: true }) private popup!: Popup;
  @Prop({ required: true }) private zIndex!: number;

  private get clonedData() {
    // 팝업 오픈시 전달되는 data 의 참조 제거
    return this.popup.data && JSON.parse(JSON.stringify(this.popup.data));
  }

  private close(state: number | string, data: object | undefined) {
    (this.popup.onClose as Function)({ state: state, data: data });
  }
  private onPositiveClick(data: object) {
    this.close(PopupClose.CONFIRM, data);
  }
  private onNegativeClick(data: object) {
    this.close(PopupClose.CLOSE, data);
  }
}
