
































































import { Component, Mixins, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { OptionData } from '@/helpers/type';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { formatCurrency, getOnlyNumbers } from '@/utils/numberFormat';
import {
  CommissionType,
  DiscountType,
  ErrorCode,
  ErrorType,
  SalesInfoCalculator,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';
import {
  DateRangeOption,
  ErrorCodeType,
  ImmediateDiscountOption,
  SalesInfoCalculatorErrorInfo,
  StartAndEndYmdHm,
} from '@/types';
import NumberWithCommaFormatterMixin from '@/views/contents/product/basic/ProductAdd/salesInfo/NumberWithCommaFormatterMixin.vue';
import { today } from '@/views/contents/product/basic/ProductAdd';
import { DEFAULT_TIME_RANGE } from '@/components/common/datepicker/dateRange';
import DateRangePicker from '@/components/common/DateRangePicker.vue';

@Component({
  components: { ToolTip, SelectBox, TextInput, DateRangePicker },
  filters: { formatCurrency },
})
export default class ImmediateDiscountInfo extends Mixins(NumberWithCommaFormatterMixin) {
  // 사입-매입가
  @Prop({ required: true })
  private readonly isPurchaseType: boolean;

  @PropSync('immediateDiscountInfo')
  private immediateDiscountInfoSync!: ImmediateDiscountOption;

  @PropSync('immediateDiscountPrice')
  private immediateDiscountPriceSync!: number;

  @PropSync('maxImmediateDiscountAmount', { default: 0 })
  private maxImmediateDiscountAmountSync!: number;

  @PropSync('supplyPrice')
  private supplyPriceSync!: number;

  @PropSync('immediateRate')
  private immediateRateSync: number;

  @PropSync('defaultSupplyPrice', { required: true })
  private defaultSupplyPriceSync: number;

  @Prop()
  private readonly calculator!: SalesInfoCalculator;

  @Prop({ required: true })
  private readonly salePrice: number;

  @Prop()
  private readonly commissionRateType: CommissionType;

  @Prop({ required: true })
  private readonly disabled: boolean;

  @Ref()
  private readonly immediateDiscountPeriodRef;

  public focus(type: 'immediateDiscountPeriod'): void {
    this[`${type}Ref`].focus();
  }

  private selectOptions: OptionData<DiscountType>[] = [
    { name: 'PRODUCT.ADD.AMOUNT', value: DiscountType.WON },
    { name: 'PRODUCT.ADD.PERCENT', value: DiscountType.PERCENTAGE },
  ];
  private isPeriodConfigSync = false; //기간설정 체크했는지 여부
  public reset() {
    this.formattedNumber = 0;
  }

  private get isAmount(): boolean {
    return this.immediateDiscountInfoSync.unitType === 'AMOUNT';
  }

  private changedDiscountAmount(discount: string): string {
    const amount = getOnlyNumbers(discount);
    try {
      this.resetError();
      this.setDiscountAmount(amount);
      this.changedAllDataByDiscountAmount();
      return this.getCurrency(amount);
    } catch (e) {
      this.errorHandler(e.errorInfo);
      this.resetByError();
      return '';
    }
  }

  private setDiscountAmount(discount: number): void {
    this.calculator.changeDiscountAmount(discount);
    this.immediateRateSync = discount;
    this.immediateDiscountInfoSync.amount = discount;
  }

  private changedAllDataByDiscountAmount(): void {
    this.immediateDiscountPriceSync = this.calculator.discountPriceResult;
    // 공급가 직접 입력일 경우 계산기 중지 (중지하지 않으면, 다른 입력값에 따라 공급가 변화)
    if (this.commissionRateType === CommissionType.PURCHASE) {
      if (!this.supplyPriceSync) {
        // 공급가 초기값 세팅
        this.supplyPriceSync = this.defaultSupplyPriceSync;
      }
      return;
    }

    if (!this.isPurchaseType) {
      this.supplyPriceSync = this.calculator.supplyPriceResult;
    }
  }

  private changedDiscountType(type: DiscountType): void {
    this.calculator.changeDiscountType(type);
    // 즉시 할인 단위 변경 시 % 일 경우 0 으로 초기화, 원일 경우 이전 값 세팅
    this.changedDiscountAmount(this.formattedNumber.toString());
    this.changedMaxImmediateDiscountAmount();
  }

  private changedMaxImmediateDiscountAmount(): void {
    this.maxImmediateDiscountAmountSync = this.calculator.maxDiscountAmount;
  }

  private ErrorCode = ErrorCode;
  private errorCode: ErrorCodeType | '' = '';
  private errorHandler(error: SalesInfoCalculatorErrorInfo): void {
    if (error.type === ErrorType.ALERT) {
      alert(this.$t(error.msg));
      return;
    }
    this.errorCode = error.code as ErrorCodeType;
  }

  private resetError(): void {
    this.errorCode = '';
  }

  private resetByError(): void {
    this.formattedNumber = 0;
    this.immediateDiscountInfoSync.amount = 0;
    this.changedAllDataByDiscountAmount();
  }

  @Watch('immediateDiscountInfoSync.amount', { immediate: true })
  private initImmediateDiscountAmount() {
    if (this.isAmount && this.immediateDiscountInfoSync.amount >= 0) {
      this.formattedNumber = this.immediateDiscountInfoSync.amount;
    }
  }

  @Watch('immediateDiscountInfoSync.periodYn')
  private initPeriod() {
    if (!this.$route.path.includes('edit')) return;
    this.isPeriodConfigSync = this.immediateDiscountInfoSync.periodYn === 'Y';
    if (
      !this.isPeriodConfigSync ||
      !this.immediateDiscountInfoSync.startYmdt ||
      !this.immediateDiscountInfoSync.endYmdt
    )
      return;
    const dateStart = this.immediateDiscountInfoSync.startYmdt.split(' ');
    this.dateRange.startYmd = dateStart[0];
    this.dateRange.startHms = dateStart[1];
    if (today > this.immediateDiscountInfoSync.startYmdt) {
      this.immediateDiscountDateRangeOption.fromRanges = this.dateRange.startYmd;
    }

    const dateEnd = this.immediateDiscountInfoSync.endYmdt.split(' ');
    this.dateRange.endYmd = dateEnd[0];
    this.dateRange.endHms = dateEnd[1];
  }

  @Watch('salePrice')
  private changedSalePrice() {
    if (this.salePrice > 0) return;
    if (this.salePrice === 0) {
      this.immediateDiscountInfoSync.amount = 0;
      this.changedDiscountAmount('0');
    }
  }

  // 수수료 타입이 카테고리 및 상품 수수료 이면서 옵션 사용할 때 서버에서 공급가는 0으로 응답 됨
  // 이 때 공급가 계산해서 화면 출력 및 초기 값 설정이 필요함 (디비에서 값을 가져오는 것 처럼)
  @Watch('immediateDiscountPrice')
  private changedSupplyPriceSync() {
    if (
      this.supplyPriceSync === 0 &&
      this.commissionRateType !== CommissionType.PURCHASE &&
      this.immediateDiscountPriceSync > 0
    ) {
      this.defaultSupplyPriceSync = this.calculator.supplyPriceResult;
    }
  }

  //기간설정
  private dateRange: StartAndEndYmdHm = {
    startYmd: '',
    endYmd: '',
    startHms: DEFAULT_TIME_RANGE.START,
    endHms: DEFAULT_TIME_RANGE.END,
  };

  private immediateDiscountDateRangeOption: DateRangeOption = {
    name: 'reservation',
    dateType: 'YmdHms',
    hasPeriodBtn: false,
    fromRanges: today,
  };

  private onPeriodConfigChange({ target }: { target: HTMLInputElement }) {
    this.isPeriodConfigSync = target.checked;
    this.immediateDiscountInfoSync.periodYn = this.isPeriodConfigSync ? 'Y' : 'N';
    if (!this.isPeriodConfigSync) {
      this.immediateDiscountInfoSync.startYmdt = '';
      this.immediateDiscountInfoSync.endYmdt = '';
    } else {
      this.setImmediateDiscountPeriod();
    }
  }

  private setImmediateDiscountPeriod(): void {
    this.setStartYmdt();
    this.setEndYmdt();
  }
  @Watch('dateRange.startYmd')
  @Watch('dateRange.startHms')
  private setStartYmdt(): void {
    if (!this.dateRange.startYmd) return;
    this.immediateDiscountInfoSync.startYmdt = `${this.dateRange.startYmd} ${this.dateRange.startHms}`;
  }
  @Watch('dateRange.endYmd')
  @Watch('dateRange.endHms')
  private setEndYmdt(): void {
    if (!this.dateRange.endYmd) return;
    this.immediateDiscountInfoSync.endYmdt = `${this.dateRange.endYmd} ${this.dateRange.endHms}`;
  }
}
