import { OptColumn } from '@/types';
import { emptyValueFormatter } from '@/utils/grid/formatterUtils';
import { ProductsSearchContent } from 'ncp-api-supporter';
type ProductStock = ProductsSearchContent['productStock'];

// 예약 재고수량
export const getReservationStockCountColumn = (): OptColumn => {
  return {
    header: 'PRODUCT.RESERVATION_STOCK_COUNT',
    name: 'reservationStockCnt',
    align: 'center',
    minWidth: 100,
    formatter: ({ value }) => emptyValueFormatter(Number(value)),
  };
};

// 재고 수량
export const getStockCountColumn = (): OptColumn => {
  return {
    header: 'PRODUCT.STOCK_COUNT',
    name: 'totalCnt',
    align: 'center',
    minWidth: 100,
    formatter: ({ row }) => {
      const productStock = row.productStock as ProductStock;
      const totalCount = productStock.totalCnt;
      return emptyValueFormatter(totalCount);
    },
  };
};

// 출고 대기
export const getDeliveryWaitingStockCountColumn = (): OptColumn => {
  return {
    header: 'PRODUCT.DELIVERY_WAIT',
    name: 'deliveryWaitingStockCnt',
    align: 'center',
    minWidth: 100,
    formatter: ({ row }) => {
      const productStock = row.productStock as ProductStock;
      const deliveryWaitingStockCnt = productStock.deliveryWaitingStockCnt;
      return emptyValueFormatter(deliveryWaitingStockCnt);
    },
  };
};

// 판매 수량
export const getSaleCountColumn = (): OptColumn => {
  return {
    header: 'PRODUCT.SALES_COUNT',
    name: 'saleCnt',
    align: 'center',
    minWidth: 100,
    formatter: ({ row }) => {
      const productStock = row.productStock as ProductStock;
      const totalSaleCnt = productStock.totalSaleCnt;
      return totalSaleCnt.toString();
    },
  };
};
