export default Object.freeze({
  // MEMBER_REGISTRATION_
  INFO: '기본 정보',
  ADD_INFO: '부가 정보',
  PERSONAL_INFO: '개인정보 수집·이용 선택동의 내역',
  SHOP: '쇼핑몰',
  GRADE: '회원등급',
  MEMBER_NAME: '회원명',
  MEMBER_NO: '회원번호',
  MEMBER_ID: '아이디',
  NICKNAME: '닉네임',
  PASSWORD: '비밀번호',
  EMAIL: '이메일',
  MOBILE_NO: '휴대폰번호',
  PHONE_NO: '전화번호',
  ADDRESS: '주소',
  BIRTHDAY: '생년월일',
  GENDER: '성별',
  REFUND_ACCOUNT_BANK: '환불계좌 정보',
  COMMENT: '회원정보 관련 메모',
  PERSONAL_INFO_COLLECT_AGREE: '개인정보 수집/이용',
  PERSONAL_INFO_TREATMENT_AGREE: '개인정보 처리/위탁',
  PERSONAL_INFO_THIRD_OFFER: '개인정보 제3자 제공',
  SELECT_SHOP: '쇼핑몰을 선택하세요.',
  SELECT_GRADE: '회원등급을 선택하세요.',
  SELECT_YEAR: '년도',
  SELECT_MONTH: '월',
  SELECT_DAY: '일',
  SELECT_BANK: '은행 선택',
  PLACEHOLDER_NAME: '이름 입력',
  PLACEHOLDER_ID: '아이디 입력',
  PLACEHOLDER_NICKNAME: '닉네임 입력',
  PLACEHOLDER_PASSWORD: '비밀번호 입력',
  PLACEHOLDER_PASSWORD_CONFIRM: '비밀번호 확인',
  PLACEHOLDER_MOBILE_NO: '휴대폰번호 - 없이 입력',
  PLACEHOLDER_PHONE_NO: '전화번호 - 없이 입력',
  PLACEHOLDER_REFUND_ACCOUNT_NO: '계좌번호 - 없이 입력',
  PLACEHOLDER_REFUND_ACCOUNT_OWNER_NAME: '예금주',
  BTN_DOUBLE_CHECK: '중복확인',
  BTN_EDIT: '수정하기',
  BTN_ZIP_CODE_SEARCH: '우편번호 찾기',
  TEXT_GUIDE1: '회원 등록 시 자동 생성됩니다.',
  TEXT_GUIDE2: '비밀번호는 영문,숫자,특수문자 모두 조합하여 8~20자로 입력하셔야 합니다.',
  CHECKBOX_LABEL_EMAIL: '메일 수신 동의',
  CHECKBOX_LABEL_SMS: 'SMS 수신 동의',
  GENDER_NO: '선택 안 함',
  GENDER_MAN: '남자',
  GENDER_WOMAN: '여자',
  GRADE_UPDATE: '등급변경',
  MEMBER_WITHDRAWAL: '회원탈퇴',
  VALIDATE_ID_ALERT1: '아이디는 최소 5자 이상 입력해주세요.',
  VALIDATE_PASSWORD_ALERT1: '비밀번호는 영문,숫자,특수문자 모두 조합하여 8~20자로 입력하셔야 합니다.',
  VALIDATE_PASSWORD_ALERT2: '비밀번호와 비밀번호확인 값이 일치하지 않습니다.',
  VALIDATE_PASSWORD_ALERT3: '비밀번호가 10자 이하인 경우 영문, 숫자, 특수문자 3가지 모두 조합하여 입력해주세요.',
  VALIDATE_MOBILE_NO: '11자~12자 이내로 입력해주세요.',
  VALIDATE_ADDRESS_ALERT: '상세주소를 입력해주세요.',
  VALIDATE_BIRTHDAY_ALERT1: '생년월일을 선택해주세요.',
  VALIDATE_BIRTHDAY_ALERT2: '생년월일 항목을 정확하게 입력해주세요.',
  VALIDATE_REFUND_ALERT1: '환불계좌정보를 입력해주세요.',
  VALIDATE_REFUND_ALERT2: '생년월일을 선택해주세요.',
  VALIDATE_DOUBLE_CHECK_ALERT: '중복확인이 안되었습니다.',
  ALERT_DUPLICATE_TYPE: '이미 사용중인 {0}입니다.\n다른 {0}을(를) 입력하여 주세요.',
  ALERT_USABLE_TYPE: '사용 가능한 {0}입니다.',
  ALERT_SELECT_MALL: '쇼핑몰을 선택해 주세요.',
  ALERT_WITHDRAWAL_ID: '회원 탈퇴한 아이디 입니다. 탈퇴 후 동일한 아이디로 5일간 재가입 불가합니다.',
  CHECK_SELECT_FILED: '선택해주세요.',
  INVALID_INPUT: '에 입력불가한 문자가 포함되어 있습니다.\n다시 한 번 확인해주세요.',
  VALID_DOMAIN: '도메인을 올바르게 입력해주세요.',
  VALID_ID: '올바른 아이디를 입력해주세요.',
  NECESSARY_AGREE: '필수',
  SELECT_AGREE: '선택',
  AGREE_NO: '미동의',
  AGREE_OK: '동의',
  REJECT: '거부',
  // 회원 수정
  MEMBER_STATUS: '회원상태',
  TEMPORARY_PASSWORD: '임시 비밀번호 발급',
  REGISTER_TYPE: '가입유형',
  CERTIFICATION_CHECK: '인증여부',
  ADULT_AUTHENTICATION: '성인인증 여부',
  REGISTER_DATE_PATH: '회원가입일(가입경로)',
  LAST_LOGIN: '최종로그인(IP)',
  DORMANT_MEMBER_UNLOCK_DATE: '휴면해제일',
  LOGIN_COUNT: '로그인횟수',
  MEMBER_TEMP_PASSWORD_TITLE: '임시 비밀번호 발급',
  MEMBER_TEMP_PASSWORD_NOTIFY_TYPE_GUIDE: '임시 비밀번호 발급안내',
  MEMBER_TEMP_PASSWORD_NOTIFY_TYPE_EMAIL: '이메일',
  MEMBER_TEMP_PASSWORD_NOTIFY_TYPE_SMS: 'SMS',
  MEMBER_TEMP_PASSWORD_NOTIFY_TYPE_ALL: '모두 발송',
  SEND_TEMP_PASSWORD: '발급',
  ALERT_NO_EMAIL: '이메일 정보가 없는 회원입니다.',
  ALERT_DONT_SEND_MAIL: '메일발송이 불가합니다.\n' + '자동 메일 설정을 확인해주세요.\n',
  ALERT_NO_MOBILE_NO: '휴대폰 번호가 없는 회원입니다.',
  ALERT_DONT_SEND_SMS: 'SMS 발송이 불가합니다.\n' + '자동 SMS 설정을 확인해주세요.\n',
  ALERT_SMS_POINT: 'SMS포인트가 부족합니다.\n충전 후 다시 시도해 주세요.',
  SUCCESS_ISSUANCE: '발급완료',
  ACTIVE: '가입완료',
  DORMANT: '휴면',
  BASIC: '일반회원',
  CERTIFICATED: '{1}인증 완료 (인증일: {0})',
  NOT_CERTIFICATED: '미인증',

  ALERT_CHECK_EXIST: '중복 확인이 필요합니다.',
});
