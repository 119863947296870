













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { GenderTable, MemberTypeTable, PlatformTable, StatisticSynthesisPeriod } from '@/types';
import { StatisticSummary, StatisticSummaryByPeriod } from 'ncp-api-supporter/dist/types/modules/member/statistics';
import { percentage } from '@/utils/math';
import MemberCountTable from '@/components/statistics/members/MemberCountTable.vue';
import MarketingCountTable from '@/components/statistics/members/MarketingCountTable.vue';
import JoinTypeCountTable from '@/components/statistics/members/JoinTypeCountTable.vue';
import GenderCountTable from '@/components/statistics/members/GenderCountTable.vue';
import PlatformCountTable from '@/components/statistics/members/PlatformCountTable.vue';
import { initiateTableData, getMemberStatisticTableData } from '@/views/contents/statistics/members/PeriodMembers';

type SummaryProps = null | StatisticSummaryByPeriod | StatisticSummary | StatisticSummary['marketingAgreement'];
type SummaryKeys = keyof StatisticSummaryByPeriod | keyof StatisticSummary;

@Component({
  components: {
    MemberCountTable,
    MarketingCountTable,
    JoinTypeCountTable,
    GenderCountTable,
    PlatformCountTable,
  },
})
export default class StatisticSynthesis extends Vue {
  @Prop({ required: false, default: null })
  private readonly period: null | StatisticSynthesisPeriod;

  @Prop({ required: false, default: null })
  private readonly statisticSummaryResponse: null | StatisticSummary;

  @Prop({ required: false, default: null })
  private readonly statisticSummaryByPeriodResponse: null | StatisticSummaryByPeriod;

  @Prop()
  private readonly searchedMallName: null | string;

  private percentage = percentage;

  // 회원 전체 종합
  private statisticSummary = {
    totalMemberCount: 0,
  };

  // 회원 조회기간 종합
  private statisticSummaryByPeriod = {
    totalMemberCount: 0,
  };

  // 마케팅 수신 동의
  private marketingAgreement = {
    summaryTotalMemberCountPercentage: '0.00', // 전체 회원 수 비율
    smsAgreedCount: 0, // sms 수신동의 회원 수
    smsAgreedCountPercentage: '0.00', // sms 수신동의 회원 수 비율
    emailAgreedCount: 0, // email 수신동의 회원 수
    emailAgreedCountPercentage: '0.00', // email수신동의 회원 수 비율
  };

  // 가입 유형
  private memberType: MemberTypeTable = initiateTableData<MemberTypeTable>('가입유형');

  // 성별
  private gender: GenderTable = initiateTableData<GenderTable>('성별');

  // 플랫폼 (joinRoute)
  private platform: PlatformTable = initiateTableData<PlatformTable>('플랫폼');

  @Watch('statisticSummaryResponse', { deep: true })
  private resetData() {
    this.initiateData(this.statisticSummaryResponse, this.statisticSummaryByPeriodResponse);
  }

  private initiateData(
    statisticSummaryResponse: StatisticSummary,
    statisticSummaryByPeriodResponse: StatisticSummaryByPeriod,
  ) {
    this.setStatisticSummaryByPeriod(statisticSummaryByPeriodResponse);
    this.setStatisticSummary(statisticSummaryResponse);
    this.setMarketingAgreement(statisticSummaryResponse);
    this.setMemberType(statisticSummaryResponse, statisticSummaryByPeriodResponse);
    this.setGender(statisticSummaryResponse, statisticSummaryByPeriodResponse);
    this.setPlatform(statisticSummaryResponse, statisticSummaryByPeriodResponse);
  }

  private setStatisticSummary(statisticSummaryResponse: StatisticSummary) {
    const { totalMemberCount } = statisticSummaryResponse;
    this.statisticSummary = { totalMemberCount };
  }

  private setStatisticSummaryByPeriod(statisticSummaryByPeriodResponse: StatisticSummaryByPeriod) {
    const { totalMemberCount } = statisticSummaryByPeriodResponse;
    this.statisticSummaryByPeriod = { totalMemberCount };
  }

  private setMarketingAgreement(statisticSummaryResponse: StatisticSummary) {
    if (!statisticSummaryResponse) {
      return;
    }

    const { totalMemberCount } = statisticSummaryResponse;
    const { smsAgreedCount, emailAgreedCount } = statisticSummaryResponse.marketingAgreement;
    this.marketingAgreement = {
      smsAgreedCount,
      emailAgreedCount,
      smsAgreedCountPercentage: percentage(totalMemberCount, smsAgreedCount),
      emailAgreedCountPercentage: percentage(totalMemberCount, emailAgreedCount),
      summaryTotalMemberCountPercentage: percentage(totalMemberCount, totalMemberCount),
    };
  }

  private setMemberType(
    statisticSummaryResponse: StatisticSummary,
    statisticSummaryByPeriodResponse: StatisticSummaryByPeriod,
  ) {
    if (!statisticSummaryResponse || !statisticSummaryByPeriodResponse) {
      return;
    }

    this.memberType = getMemberStatisticTableData<MemberTypeTable>(
      '가입유형',
      statisticSummaryResponse,
      statisticSummaryByPeriodResponse,
    );
  }

  private setGender(
    statisticSummaryResponse: StatisticSummary,
    statisticSummaryByPeriodResponse: StatisticSummaryByPeriod,
  ) {
    if (!statisticSummaryResponse || !statisticSummaryByPeriodResponse) {
      return;
    }

    this.gender = getMemberStatisticTableData<GenderTable>(
      '성별',
      statisticSummaryResponse,
      statisticSummaryByPeriodResponse,
    );
  }

  private setPlatform(
    statisticSummaryResponse: StatisticSummary,
    statisticSummaryByPeriodResponse: StatisticSummaryByPeriod,
  ) {
    if (!statisticSummaryResponse || !statisticSummaryByPeriodResponse) {
      return;
    }

    this.platform = getMemberStatisticTableData<PlatformTable>(
      '플랫폼',
      statisticSummaryResponse,
      statisticSummaryByPeriodResponse,
    );
  }
}
