















































































import { Component, Vue } from 'vue-property-decorator';
import OrderProducts from '@/components/popup/claim/ClaimApplyPopup/OrderProducts.vue';
import DiscountShippingFee from '@/components/popup/claim/ClaimApplyPopup/DiscountShippingFee.vue';
import ProductDetails from '@/components/popup/claim/ClaimApplyPopup/ProductDetails.vue';
import RefundExchangeAmount from '@/components/popup/claim/ClaimApplyPopup/RefundExchangeAmount.vue';
import BenefitsPaid from '@/components/popup/claim/ClaimApplyPopup/BenefitsPaid.vue';
import { ComponentItemsClaimDataType, ClaimableOption } from '@/components/popup/claim/ClaimApplyPopup/DataType';
import NoticeBox from '@/components/popup/claim/ClaimApplyPopup/NoticeBox.vue';
import {
  ProductsDetailUrl,
  GetOrderProductsRequest,
  GetOrderProductsOrderOption,
  GetClaimsOrderOptionsOrderOptionNoApplyRequest,
  PostOrderCancelsPredictRequest,
  PostOrderCancelsRequest,
} from 'ncp-api-supporter';

@Component({
  components: { NoticeBox, BenefitsPaid, RefundExchangeAmount, OrderProducts, DiscountShippingFee, ProductDetails },
})
export default class CancelAllApplyPopup extends Vue {
  private refOrderProducts;
  private refProductDetails;
  private refDiscountShippingFee;
  private refRefundExchangeAmount;
  private refBenefitsPaid;
  private displayedDeliveryAmtDiscountMessage = false;
  private objJson = '';
  private postOptionCancelsPredict!: any;
  private escrowOrder = false;
  private claimOrderOptions = '';
  private claimType = 'CancelAll';
  private type = '';
  private title = '';
  private showA = false;
  private mall = {
    countryCd: 'KR',
  };
  private orderOptionsApply: ComponentItemsClaimDataType = {
    orderNo: '',
    claimableOptions: [],
    refundTypes: [],
    payType: '',
  };

  private productsDetailUrls: ProductsDetailUrl[] = [];
  private refundTypeDivShow = true;
  private zeroRefundShow = false;
  private refundBankInfoTrShow = true;
  private refundTypeValue: string;
  private async getProductsDetailUrls() {
    await this.$api.getProductsDetailUrls().then((ret: any) => {
      this.productsDetailUrls = ret.data.map(d => {
        d.url = this.addProductDetailUrlPreview(d.url);
        return d;
      });
    });
  }
  private holdByReservation = false;
  private holdProcess = false;
  private isShow = false;
  private orderStatusDepositWait = false;

  created() {
    this.type = String(this.$route.query.type);
    this.initPostOptionCancelsPredict();
    this.getProductsDetailUrls();
    this.getClaimOrderPredict();
    if ('before' === this.type) {
      this.title = this.$t('CLAIM.CANCELPOPUP.CANCELLBEFOREDEPOS').toString();
    } else if ('normal' === this.type) {
      this.title = this.$t('CLAIM.CANCELPOPUP.CANCELLATIONAFTERPAYMENT').toString();
    }
  }

  private getClaimOrderPredict() {
    this.setRefComponent();
    const request: GetOrderProductsRequest = {
      params: {
        orderNo: String(this.$route.query.orderNo),
      },
    };
    this.type === 'before' ? (this.orderStatusDepositWait = true) : (this.orderStatusDepositWait = false);
    const partnerNoMap: Map<number, ClaimableOption[]> = new Map();
    this.$api.getOrderProducts(request).then((ret: any) => {
      this.holdByReservation = false;
      ret.data.forEach((item: any) => {
        item.orderOptions.forEach((element: GetOrderProductsOrderOption) => {
          const productsDetailUrls: ProductsDetailUrl[] = this.productsDetailUrls.filter(
            (item: ProductsDetailUrl): any => {
              return item.mallNo === element.mallNo;
            },
          );
          const claimableOption: ClaimableOption = {
            ...element,
            checkboxValue: false,
            mallOptionNo: element.mallOptionNo,
            userInputText: [],
            orderNo: element.orderNo,
            immediateDiscountAmt: element.immediateDiscountAmt,
            salePrice: element.salePrice,
            orderStatusType: element.orderStatusType,
            optionValue: element.optionValue,
            orderCnt: element.orderCnt,
            addPrice: element.addPrice,
            mallProductNo: element.mallProductNo,
            claimedCnt: element.orderCnt,
            productName: element.productName,
            claimStatusType: element.claimStatusType,
            additionalDiscountAmt: element.additionalDiscountAmt,
            optionName: element.optionName,
            orderOptionNo: element.orderOptionNo,
            partnerNo: element.partnerNo,
            partnerName: element.partnerName,
            payType: element.payType,
            productUrl:
              productsDetailUrls && productsDetailUrls.length > 0
                ? productsDetailUrls[0].url.replace('${mallProductNo}', element.mallProductNo.toString())
                : '',
            holdProcess: false,
            holdByReservation: element.reservation.holdByReservation || this.holdByReservation,
            optionManagementCd: element.optionManagementCd,
            usesOption: element.usesOption,
            hasSetOption: element.setOptionJson != '' && element.setOptionJson != null,
            setOptions: JSON.parse(element.setOptionJson),
          };
          this.holdByReservation = element.reservation.holdByReservation || this.holdByReservation;
          const temp: ClaimableOption[] | undefined = partnerNoMap.get(element.partnerNo);
          if (temp) {
            temp.push(claimableOption);
            partnerNoMap.set(element.partnerNo, temp);
          } else {
            const temp: ClaimableOption[] = [];
            temp.push(claimableOption);
            partnerNoMap.set(element.partnerNo, temp);
          }
        });
      });
      partnerNoMap.forEach((value: ClaimableOption[]) => {
        value.forEach((mapValue: ClaimableOption) => {
          this.orderOptionsApply.claimableOptions.push(mapValue);
        });
      });

      const request: GetClaimsOrderOptionsOrderOptionNoApplyRequest = {
        pathParams: {
          orderOptionNo: this.orderOptionsApply.claimableOptions[0].orderOptionNo.toString(),
        },
        params: {
          claimType: 'CANCEL',
        },
      };

      this.orderOptionsApply.orderNo = String(this.$route.query.orderNo);
      if (this.type === 'normal') {
        this.$api
          .getClaimsOrderOptionsOrderOptionNoApply(request)
          .then((ret: any) => {
            this.isShow = true;
            this.claimOrderOptions = ret.data;
            this.orderOptionsApply.refundTypes = ret.data.refundTypes;
            this.orderOptionsApply.payType = ret.data.payType;
            this.refundTypeValue = this.getPayType(ret.data.payType);
            this.showA = true;

            const postOrderCancelsPredictRequest: PostOrderCancelsPredictRequest = {
              data: {
                refundType: this.getPayType(this.orderOptionsApply.payType),
                orderNo: String(this.$route.query.orderNo),
              },
            };
            this.escrowOrder =
              this.orderOptionsApply.payType.indexOf('ESCROW_REALTIME_ACCOUNT_TRANSFER') >= 0 ||
              this.orderOptionsApply.payType.indexOf('ESCROW_VIRTUAL_ACCOUNT') >= 0;
            this.$api.postOrderCancelsPredict(postOrderCancelsPredictRequest).then((ret: any) => {
              this.postOptionCancelsPredict = ret.data;
              this.postOptionCancelsPredict.amounts.beforeAccumulationAmt = this.postOptionCancelsPredict.amounts.before.accumulation.accumulationAmt;
              this.postOptionCancelsPredict.amounts.afterAccumulationAmt = this.postOptionCancelsPredict.amounts.after.accumulation.accumulationAmt;
              this.postOptionCancelsPredict.amounts.totalAccumulationAmt =
                this.postOptionCancelsPredict.amounts.afterAccumulationAmt -
                this.postOptionCancelsPredict.amounts.beforeAccumulationAmt;
              this.objJson = JSON.stringify(ret.data);
              this.postOptionCancelsPredict.amounts.refund.order.totalDeliveryAmt =
                this.postOptionCancelsPredict.amounts.refund.coupon.cartCouponDiscountAmt -
                this.postOptionCancelsPredict.amounts.refund.delivery.totalDeliveryAmt;
              if (this.postOptionCancelsPredict.amounts.adjustedAmounts.payAmt === 0) {
                this.refundTypeDivShow = false; // 환불방법
                this.zeroRefundShow = true; // 환불금액 없음
                this.refundBankInfoTrShow = false; // 환불계좌번호
                this.refundTypeValue = 'ZERO_REFUND';
              }
            });
          })
          .catch(() => {
            window.opener.location.reload();
            window.close();
          });
      } else {
        const postOrderCancelsPredictRequest: PostOrderCancelsPredictRequest = {
          data: {
            refundType: 'ACCOUNT',
            orderNo: String(this.$route.query.orderNo),
          },
        };
        this.$api.postOrderCancelsPredict(postOrderCancelsPredictRequest).then((ret: any) => {
          this.postOptionCancelsPredict = ret.data;
          this.postOptionCancelsPredict.amounts.beforeAccumulationAmt = this.postOptionCancelsPredict.amounts.before.accumulation.accumulationAmt;
          this.postOptionCancelsPredict.amounts.afterAccumulationAmt = this.postOptionCancelsPredict.amounts.after.accumulation.accumulationAmt;
          this.postOptionCancelsPredict.amounts.totalAccumulationAmt =
            this.postOptionCancelsPredict.amounts.afterAccumulationAmt -
            this.postOptionCancelsPredict.amounts.beforeAccumulationAmt;
          this.objJson = JSON.stringify(ret.data);
          this.refundTypeValue = 'ZERO_REFUND';
          this.refundBankInfoTrShow = false; // 환불계좌번호
          this.refundTypeDivShow = false;
        });
        this.showA = true;
        this.isShow = true;
      }
    });
    this.refOrderProducts?.claimCalculation();
  }

  public getPayType(payType: string) {
    switch (payType) {
      case 'ACCOUNT':
        return 'ACCOUNT';

      case 'ACCUMULATION':
        return 'ACCUMULATION';

      case 'ZERO_PAY':
        return 'ZERO_REFUND';

      case 'VIRTUAL_ACCOUNT':
      case 'ESCROW_VIRTUAL_ACCOUNT':
        return 'PG';

      default:
        return 'PG';
    }
  }

  public close(): void {
    window.close();
  }

  private onClickClose() {
    if (!confirm(this.$t('CLAIM.CANCELPOPUP.CLOSE_BTN_CONFIRM').toString())) return;
    this.close();
  }

  private onClickApplyCancel() {
    this.setRefComponent();
    const orderOptionParams: {
      claimCnt: number;
      orderOptionNo: number;
    }[] = [];
    for (let i = 0; i < this.refOrderProducts.optionNo.length; i++) {
      const claimCnt = this.refOrderProducts.orderCntMap.get(this.refOrderProducts.optionNo[i]) || 0;

      orderOptionParams.push({
        claimCnt: claimCnt,
        orderOptionNo: this.refOrderProducts.optionNo[i],
      });
    }

    let bank = '';
    let bankNm = '';
    if (this.refRefundExchangeAmount.refundBankEditShow === false) {
      bank = null;
      bankNm = null;
    } else {
      bank = this.refRefundExchangeAmount.refundBankValue;
      this.refRefundExchangeAmount.refundBankList.forEach((element: { value: string; name: string }) => {
        if (element.value == this.refRefundExchangeAmount.refundBankValue) {
          bankNm = element.name;
        }
      });
    }
    const request: PostOrderCancelsRequest = {
      data: {
        responsibleObjectType: this.refOrderProducts.responsibleObjectTypeValue,
        refundType: this.refRefundExchangeAmount.refundTypeValue,
        reasonDetail: this.refOrderProducts.claimReasonDetail[0],
        orderNo: this.orderOptionsApply.orderNo,
        reasonType: this.refOrderProducts.claimReasonType[0],
        refundBankAccount: {
          depositorName: this.refRefundExchangeAmount.refundBankDepositorName,
          bank: bank,
          bankName: bankNm,
          account: this.refRefundExchangeAmount.refundBankAccount,
        },
      },
    };
    if (!this.validation()) {
      return;
    }

    this.$api.postOrderCancels(request).then(() => {
      alert(this.$t('CLAIM.CANCELPOPUP.PROCESS_SUCCESS_MESSAGE'));
      window.opener.location.reload();
      this.close();
    });
  }

  private validation(): boolean {
    if (this.checkHoldDelivery()) {
      return false;
    }
    if (this.checkHoldByReservation()) {
      return false;
    }

    let needBankAccount = false;
    switch (this.refRefundExchangeAmount.refundTypeValue) {
      case 'CASH':
      case 'ACCOUNT':
        needBankAccount = true;
        break;
      case 'PG':
        if (
          this.orderOptionsApply.payType === 'VIRTUAL_ACCOUNT' ||
          this.orderOptionsApply.payType === 'ESCROW_VIRTUAL_ACCOUNT'
        ) {
          needBankAccount = true;
        }
        break;
    }

    if (needBankAccount && this.refRefundExchangeAmount.refundBankValue == '') {
      alert(this.$t('CLAIM.CANCELPOPUP.ALTER1'));
      this.refRefundExchangeAmount.$refs.refundBank.focus();
      return false;
    }

    if (needBankAccount && this.refRefundExchangeAmount.refundBankAccount == '') {
      alert(this.$t('CLAIM.CANCELPOPUP.ALTER2'));
      this.refRefundExchangeAmount.$refs.refundBankAccountRef.focus();
      return false;
    }

    if (needBankAccount && this.refRefundExchangeAmount.refundBankDepositorName == '') {
      alert(this.$t('CLAIM.CANCELPOPUP.ALTER3'));
      this.refRefundExchangeAmount.$refs.refundBankDepositorNameRef.focus();
      return false;
    }

    return true;
  }

  private checkHoldByReservation(): boolean {
    return this.holdByReservation == true && !confirm(this.$t('CLAIM.CANCELPOPUP.RESERVATION_CONFIRM').toString());
  }
  private checkHoldDelivery(): boolean {
    return this.holdProcess == true && !confirm(this.$t('CLAIM.CANCELPOPUP.CONFIRM').toString());
  }

  private initPostOptionCancelsPredict() {
    const postOptionCancelsPredictTemp = {
      shipping: {
        requiresShipping: true,
        address: {
          address: '',
          name: '',
          jibunAddress: '',
          detailAddress: '',
          zipCd: '',
          contact1: '',
          contact2: '',
        },
        shippingNo: 0,
        hasAdjustedExchangeDeliveryAmt: false,
        divided: true,
        deliveryGroupNo: 0,
        deliveryType: '',
        prepaid: true,
        deliveryTemplateNo: 0,
        combined: true,
        originalShippingNo: 0,
      },
      coupon: {
        productCoupons: [
          {
            couponName: '',
            couponIssueNo: 0,
            orderProductNo: 0,
            restores: true,
            couponNo: 0,
          },
        ],
        cartCoupon: {
          couponName: '',
          couponIssueNo: 0,
          orderProductNo: 0,
          restores: true,
          couponNo: 0,
        },
      },
      amounts: {
        deliveryAdjustAmt: 0,
        additionalDeliveryAmt: 0,
        adjustedAmounts: {
          mainPayAmt: 0,
          accumulationPayAmt: 0,
          claimAmt: 0,
          additionalPayAmt: 0,
          payAmt: 0,
        },
        before: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        after: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
        additionalPayAmt: 0,
        refund: {
          delivery: {
            deliveryAmt: 0,
            remoteDeliveryAmt: 0,
            totalDeliveryAmt: 0,
            deliveryGroupAmounts: {
              prepaidRemoteDeliveryAmt: 0,
              payOnRemoteDeliveryAmt: 0,
              deliveryAmt: 0,
              remoteDeliveryAmt: 0,
              payOnDeliveryAmt: 0,
              totalDeliveryAmt: 0,
              prepaidDeliveryAmt: 0,
              totalPrepaidDeliveryAmt: 0,
            },
          },
          coupon: {
            cartCouponDiscountAmt: 0,
            productCouponDiscountAmt: 0,
          },
          accumulation: {
            accumulationAmt: 0,
          },
          order: {
            immediateDiscountAmt: 0,
            additionalDiscountAmt: 0,
            standardAmt: 0,
            mainPayAmt: 0,
            accumulationPayAmt: 0,
            remainingPgPayAmt: 0,
            payAmt: 0,
          },
        },
      },
      overflowsPgAmt: false,
    };
    this.postOptionCancelsPredict = postOptionCancelsPredictTemp;
    this.objJson = JSON.stringify(postOptionCancelsPredictTemp);
  }

  private setRefComponent() {
    this.refOrderProducts = this.$refs.componentItemsClaim as OrderProducts;
    this.refProductDetails = this.$refs.componentProductDetails as ProductDetails;
    this.refDiscountShippingFee = this.$refs.componentDiscountShippingFee as DiscountShippingFee;
    this.refRefundExchangeAmount = this.$refs.componentRefundExchangeAmount as RefundExchangeAmount;
    this.refBenefitsPaid = this.$refs.componentBenefitsPaid as BenefitsPaid;
  }

  public addProductDetailUrlPreview(url: string): string {
    const preview = '?preview=true';
    return url ? url + preview : url;
  }
  protected mounted() {
    this.$nextTick(() => {
      document.querySelector('#wrapper').classList.add('type-1200');
    });
  }
}
