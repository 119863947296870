







































































import { Vue, Component } from 'vue-property-decorator';
import {
  getOperateSecurityRadioBoxOption,
  getOperateSecurityToolTipOption,
  i18nSecurity,
} from '@/const/contents/configuration/operateSecurity';
import AuthNoticeBox from '@/views/contents/configuration/security/operateSecurity/AuthNoticeBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import { namespace } from 'vuex-class';
import { Mall } from 'ncp-api-supporter';
import IpAddressInputList, { Ip } from '@/components/common/IpAddressInputList.vue';
import { FakeCountry } from '@/api/mock';
import CountriesPicker from '@/components/common/input/data/CountriesPicker.vue';
const mallModule = namespace('mall');

@Component({
  components: {
    CountriesPicker,
    IpAddressInputList,
    RadioGroup,
    ToolTip,
    CheckboxGroup,
    AuthNoticeBox,
  },
})
export default class SettingByShoppingMall extends Vue {
  @mallModule.Getter('getMalls')
  private readonly malls!: Mall[];

  private readonly i18nSecurity = i18nSecurity;

  private mallAccessRestrict = false;

  private toolTipOption = getOperateSecurityToolTipOption();
  private radioBoxOption = getOperateSecurityRadioBoxOption();

  private permittedIpAddressList: Ip[] = [];
  private countries: FakeCountry[] = [];
}
