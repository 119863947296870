







































import { Vue, Component, Prop, Watch, Ref, PropSync } from 'vue-property-decorator';
import Grid from '@/components/common/grid/Main.vue';
import {
  gridOptions,
  columns,
  autoColumn,
  manualColumn,
  message,
} from '@/views/contents/product/basic/ProductAdd/basicInfo/details/RelatedProduct';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import ChangeDisplayOrder from '@/components/product/changeDisplayOrder/ChangeDisplayOrder.vue';
import {
  ChangeDisplayOption,
  ChangeDisplayOrderMsgOption,
  CheckGridEventProps,
  GridEventProps,
  GridProp,
  OptColumn,
  RelatedProductInformation,
  RelatedProductDetail,
  RemoveSelectedOption,
} from '@/types';
import { removeSelected } from '@/components/product/changeDisplayOrder/ChangeDisplayOrder';
import {
  GetRelatedProductsByProductNoResponse,
  ProductsSaleSettingStatusType,
  ProductsSaleStatusType,
  RelatedProductDetails,
} from 'ncp-api-supporter';
import { setNoDataMessage } from '@/helpers/grid';
import { PopupClose, PopupResult, throwWindowPopup } from '@/helpers/popup';
import {
  CONFIG_TYPE,
  DELETE_ERROR,
  IMMUTABLE_FIELD,
  PRODUCT_FORM_ERROR_TYPE,
  SORT_CRITERION,
} from '@/const/contents/product';
import { i18nForProduct } from '@/views/contents/product/basic/ProductAdd';
import { RowKey } from '@/types/tui-grid';
import { ProductsSearchContent } from 'ncp-api-supporter/dist/types/modules/product/product';

type SelectedProducts = Partial<RelatedProductDetails> & ChangeDisplayOption & { isPeriodType?: boolean };

@Component({
  components: {
    ToolTip,
    Grid,
    ChangeDisplayOrder,
  },
})
export default class RelatedProduct extends Vue {
  @PropSync('relatedProductInfo', { required: true })
  private relatedProductInfoSync: RelatedProductInformation;
  @Prop({ required: true })
  private readonly showRelatedProduct!: boolean;
  @Prop({ required: true })
  private readonly displayCategoryNos!: number[];
  @Prop({ required: true })
  private readonly productNo: number;

  @Prop({ required: true })
  private readonly immutableFields;

  @Prop({ required: true })
  private readonly editFlag;

  @Prop({ required: true })
  private readonly mallNo: number;

  private readonly IMMUTABLE_FIELD = IMMUTABLE_FIELD;

  private CONFIG_TYPE = CONFIG_TYPE;
  private SORT_CRITERION = SORT_CRITERION;
  private i18nForProduct = i18nForProduct;
  private get isCustomType(): boolean {
    return this.relatedProductInfoSync.sortCriterion === SORT_CRITERION.CUSTOM_ORDER;
  }

  public setFinalRelatedProducts() {
    this.relatedProductInfoSync.products = this.relatedProducts.map(
      ({ displayOrder, productNo }) =>
        ({
          displayOrder,
          productNo,
        } as RelatedProductDetail),
    );
  }

  @Ref()
  private readonly grid: Grid;

  private gridOptions: GridProp = gridOptions();
  private defaultColumns: OptColumn[] = columns();
  private manualColumns: OptColumn[] = manualColumn().concat(this.defaultColumns);
  private autoColumns: OptColumn[] = autoColumn().concat(this.defaultColumns);
  private columns: OptColumn[] = [...this.autoColumns];
  private selected: number[] = [];
  private totalCount = 0;

  private message: ChangeDisplayOrderMsgOption = message;

  private resetColumns() {
    this.columns = this.isCustomType ? this.manualColumns : this.autoColumns;
  }

  @Watch('relatedProductInfoSync.sortCriterion')
  private sortProductsByDisplayType(): void {
    this.resetColumns();
    this.$nextTick(() => this.grid.setData(this.relatedProducts));
    this.removeClassName(this.relatedProducts.map((_, idx) => idx));
  }

  private removeClassName(rowKeys: RowKey[]) {
    this.$nextTick(() => rowKeys.forEach(key => this.grid.removeRowClassName(key, 'current-row')));
  }

  private setClassName(gridEvent?: Partial<CheckGridEventProps>): void {
    const { checkType, selected, rowKey } = gridEvent;
    if (checkType === 'uncheck') {
      this.grid.removeRowClassName(rowKey, 'current-row');
      return;
    }
    if (checkType === 'uncheckAll') {
      this.removeClassName(selected);
      return;
    }
    selected.forEach(key => this.grid.addRowClassName(key, 'current-row'));
  }

  private onRowChecked(gridEvent: CheckGridEventProps): void {
    this.selected = gridEvent.selected as number[];
    this.setClassName(gridEvent);
  }

  private remove(removeOption: RemoveSelectedOption): void {
    const removedResults = removeSelected(removeOption);
    if (!removedResults) return;
    this.relatedProducts = removedResults.data;
    this.totalCount = removedResults.totalSize;
    alert(this.$t('PRODUCT.ADD.REMOVED'));
  }

  private onClickRemove(rowKey: number): void {
    const origins = rowKey >= 0 ? [rowKey] : (this.grid.getCheckedRowKeys() as number[]);
    if (!this.relatedProducts) return;
    const removeOption: RemoveSelectedOption = {
      data: this.relatedProducts,
      totalSize: this.totalCount,
      origins,
      message: {
        needOrigin: this.i18nForProduct('PLZ_SELECT_PRODUCT_TO_REMOVE'),
        confirmToRemove: this.i18nForProduct('CONFIRM_REMOVE_N', origins.length.toString(), false),
      },
    };
    this.remove(removeOption);
  }

  private onItemClicked({ rowKey, columnName }: GridEventProps): void {
    if (columnName !== 'delete') return;
    this.onClickRemove(Number(rowKey));
  }

  private previousKeys = [];
  private customizePreviousKeys(rowKeys: number[]) {
    this.previousKeys = rowKeys;
  }

  private relatedProducts: SelectedProducts[] = [];

  private setRelatedProducts({ products, count }: GetRelatedProductsByProductNoResponse) {
    this.relatedProducts = products.map((product, idx) => ({
      ...product,
      rowKey: product.displayOrder - 1 ?? idx,
      displayOrder: product.displayOrder ?? idx + 1,
      isPeriodType: product.salePeriodType === 'PERIOD',
    }));
    this.resetColumns();
    this.totalCount = count;
  }

  @Watch('productNo')
  private fetchRelatedProducts() {
    if (this.productNo > 0) {
      this.$api
        .getRelatedProductsByProductNo({
          pathParams: {
            productNo: this.productNo,
          },
        })
        .then(res => {
          if (res && res.status === 200) {
            this.setRelatedProducts(res.data);
          }
        });
    }
  }

  private init() {
    setNoDataMessage(i18nForProduct('NO_DATA_MSG_FOR_RELATED_PRODUCT'));
    this.productNo > 0 && this.fetchRelatedProducts();
    this.totalCount = this.relatedProductInfoSync.products.length;
  }

  private created() {
    this.init();
  }

  // 관련상품 조회 팝업
  private openProductInquiryPopup() {
    throwWindowPopup(
      'ProductInquiry',
      {
        fixed: {
          mallNo: this.mallNo,
        },
        queryLimit: {
          saleSettingStatus: 'AVAILABLE_FOR_SALE,STOP_SELLING',
        },
        directSend: true,
        rowPickLimitCount: 500,
      },
      'xxlg',
      (callbackData: PopupResult) => {
        if (callbackData.state === PopupClose.CLOSE) return;
        this.setSelectedProducts(callbackData.data as ProductsSearchContent[]);
      },
    );
  }

  private mapToRelatedProductDetails(product: ProductsSearchContent): any {
    return {
      productName: product.productName,
      productNo: product.mallProductNo,
      immediateDiscountAppliedPrice: product.appliedImmediateDiscountPrice,
      saleStatusType: product.saleStatusType as ProductsSaleStatusType,
      saleSettingStatusType: product.saleSettingStatusType as ProductsSaleSettingStatusType,
      salePeriodType: product.salePeriod.periodType,
      isPeriodType: product.salePeriod.periodType === 'PERIOD',
      frontDisplayable: product.frontDisplayable,
      isSoldOut: product.isSoldOut,
      displayOrder: null,
    };
  }

  // 성공/실패
  private getValidSelectedProducts(
    products: ProductsSearchContent[],
  ): { addableProducts: RelatedProductDetails[]; failCount: number } {
    const addableProducts: RelatedProductDetails[] = [];
    let failCount = 0;

    products.forEach(pro => {
      const { mallProductNo } = pro;
      const duplicate = this.relatedProducts.some(product => product.productNo === mallProductNo);
      duplicate ? failCount++ : addableProducts.push(this.mapToRelatedProductDetails(pro));
    });

    return {
      addableProducts,
      failCount,
    };
  }

  private mapToSelectedProducts(products: RelatedProductDetails[], count: number): SelectedProducts[] {
    let newIdx = count;
    return products.map((pro, idx) => {
      newIdx = count + idx;
      return {
        ...pro,
        rowKey: newIdx,
        displayOrder: newIdx + 1,
      };
    });
  }

  // 500개 초과 검증
  private getNewRelatedProducts(
    products?: RelatedProductDetails[],
  ): { newRelatedProducts: SelectedProducts[]; successCount: number } {
    const MAX_AMOUNT = 500;
    const { length: prevCount } = this.relatedProducts;
    const { length: addableCount } = products;
    const totalCount = prevCount + addableCount;

    let newRelatedProducts: SelectedProducts[] = [];
    let successCount = 0;
    if (MAX_AMOUNT >= totalCount) {
      newRelatedProducts = [...this.relatedProducts].concat(this.mapToSelectedProducts(products, prevCount));
      successCount = addableCount;
    } else {
      if (addableCount >= MAX_AMOUNT) {
        newRelatedProducts = this.mapToSelectedProducts(products.slice(0, MAX_AMOUNT), 0);
        successCount = MAX_AMOUNT;
      } else {
        newRelatedProducts = [...this.relatedProducts].slice(0, Math.max(MAX_AMOUNT - addableCount, 0));
        const successProducts = products.slice(0, MAX_AMOUNT - newRelatedProducts.length);
        newRelatedProducts = newRelatedProducts.concat(
          this.mapToSelectedProducts(successProducts, newRelatedProducts.length),
        );
        successCount = successProducts.length;
      }
    }
    return {
      newRelatedProducts,
      successCount,
    };
  }

  // 추가 가능한 관련상품 등록
  private setValidSelectedProducts(newProducts: SelectedProducts[]) {
    this.relatedProducts = newProducts;
    this.totalCount = newProducts.length;
  }

  private getSuccessAndFailMessage(successCount: number, failCount: number): string {
    const message = this.i18nForProduct('SUCCESS', successCount.toString(), false);
    const hasSuccessResult = successCount > 0;
    const hasFailResult = failCount > 0;
    if (hasFailResult) {
      return `${message}\n${i18nForProduct('FAIL_SELECTED_PRODUCTS', failCount.toString(), false)}`;
    }
    if (hasSuccessResult) {
      return message;
    }
    return null;
  }

  private alertResult(successCount: number, failCount: number) {
    const message = this.getSuccessAndFailMessage(successCount, failCount);
    message !== null && alert(message);
  }

  private setSelectedProducts(products: ProductsSearchContent[]) {
    // 성공/실패 체크
    const { addableProducts, failCount } = this.getValidSelectedProducts(products);
    // 500개 초과 검증
    const { newRelatedProducts, successCount } = this.getNewRelatedProducts(addableProducts);
    this.setValidSelectedProducts(newRelatedProducts);
    this.alertResult(successCount, failCount);
  }

  // error
  @Prop({ required: true })
  private readonly formError: { [errorKey: string]: string };
  @Prop({ required: true })
  private readonly formErrorType: typeof PRODUCT_FORM_ERROR_TYPE;
  @Prop({ required: true })
  private readonly deleteError: typeof DELETE_ERROR;
}
