























































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { Getter, namespace, Action } from 'vuex-class';
import {
  Mall,
  PutInquiriesConfigurationsRequest,
  PutProductInquiriesConfigurationsRequest,
  PutReviewsConfigurationsRequest,
  GetInquiriesConfigurationsRequest,
  GetProductInquiriesConfigurationsRequest,
  GetReviewsConfigurationsRequest,
  PutCommonBoardsRequest,
  PutCommonBoardsOrderRequest,
  BoardsDetail,
  PutReviewsConfigurationsExcludedProductsSyncRequest,
} from 'ncp-api-supporter';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import { getCurrentMallNo } from '@/utils/mall';
import { NestedTreeNode, TreeNode, TreeStoreEvent } from '@/types/tree';
import { replaceKeyNames } from '@/utils/keyReplace';
import { formatCurrency } from '@/utils/numberFormat';
import TextInput from '@/components/common/input/TextInput.vue';
import TreeContainer from '@/views/contents/board/basic/tree/TreeContainer.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';

import { BoardData } from '@/types/boardSetting';
import TableTabMenu, { TableTabMenuItem } from '@/components/common/TableTabMenu.vue';
import BoardExceptionSetting from '@/views/contents/board/basic/boardSettingSub/BoardExceptionSetting/BoardExceptionSetting.vue';
import BoardAdditionalSetting from '@/views/contents/board/basic/boardSettingSub/BoardAdditionalSetting.vue';
import BoardSettingSub from '@/views/contents/board/basic/boardSettingSub/BoardSettingSub.vue';
import { ThrowMessagePopup } from '@/helpers/popup';

const tree = namespace('tree');

const BoardType = {
  detail: 'detail',
  additional: 'additional',
  exception: 'exception',
} as const;

@Component({
  name: 'BoardSettingPrev',
  components: {
    TreeContainer,
    RadioGroup,
    ToolTip,
    TextInput,
    BoardSettingSub,
    TableTabMenu,
    BoardAdditionalSetting,
    BoardExceptionSetting,
  },
})
export default class BoardSetting extends Vue {
  @tree.Action('setTree')
  private readonly setTree!: () => void;
  @tree.Getter('getNode')
  private readonly getNode: TreeNode;
  @tree.Getter('getEvent')
  private readonly getEvent: TreeStoreEvent;
  @Getter('mall/getMalls') private malls!: Mall[];
  @Getter('hasUnsavedForm')
  private readonly hasUnsavedForm!: boolean;
  @Getter('getDetectUnsavedFormMessage')
  private readonly getDetectUnsavedFormMessage!: ThrowMessagePopup;
  @Action('setInitDetectUnsavedFormMessage')
  private readonly setInitDetectUnsavedFormMessage!: () => void;
  @Action('setInitUnsavedForm')
  private readonly setInitUnsavedForm!: () => void;

  private REVIEW_BOARD_NO = -3;
  private BoardType = BoardType;
  private prevFocusedTabValue: keyof typeof BoardType = BoardType.detail;
  private focusedTabName: keyof typeof BoardType = BoardType.detail;
  private selectedTabName: keyof typeof BoardType = BoardType.detail;

  @Ref()
  treeContainer!: TreeContainer;
  @Ref()
  private readonly exceptionSettingRef: BoardExceptionSetting;
  @Ref()
  private readonly additionalSettingRef: BoardAdditionalSetting;
  @Ref()
  private readonly detailSettingRef: BoardSettingSub;
  @Ref() private readonly boardNameRef: TextInput;

  private menuList: TableTabMenuItem[] = [
    { name: BoardType.detail, title: this.$t('BOARD.BOARD.TAB_1').toString() },
    { name: BoardType.additional, title: this.$t('BOARD.BOARD.TAB_2').toString() },
    { name: BoardType.exception, title: this.$t('BOARD.BOARD.TAB_3').toString() },
  ];

  private changeNode(): boolean {
    if (this.focusedTabName !== BoardType.exception && this.focusedTabName !== BoardType.additional) return true;
    return this.checkUnsavedForm();
  }

  @Watch('getNode')
  changeFocusedTab() {
    if (this.getNode?.boardNo === this.REVIEW_BOARD_NO) return;
    this.focusedTabName = BoardType.detail;
    this.selectedTabName = BoardType.detail;
  }

  private throwConfirmMessage(): boolean {
    const { message }: ThrowMessagePopup = this.getDetectUnsavedFormMessage;
    const accept = confirm(this.$t(message.toString()).toString());

    return accept;
  }

  private resetBoardData() {
    this.setInitDetectUnsavedFormMessage();
    this.setInitUnsavedForm();
    if (this.isProductReview() && this.focusedTabName === BoardType.detail) {
      this.detailSettingRef[0].resetData();
    }
    this.focusedTabName = this.selectedTabName;
    this.prevFocusedTabValue = this.selectedTabName;
  }

  private get isChangedExceptionGrid(): boolean {
    return this.focusedTabName === BoardType.exception && this.exceptionSettingRef[0].isChangedGridData();
  }

  private checkUnsavedForm() {
    if ((!this.hasUnsavedForm || !this.getNode.isLeaf) && !this.isChangedExceptionGrid) {
      this.focusedTabName = this.selectedTabName;
      this.prevFocusedTabValue = this.selectedTabName;
      return true;
    }

    const confirmResult = this.throwConfirmMessage();

    if (confirmResult) {
      this.resetBoardData();
      return true;
    }

    this.focusedTabName = this.prevFocusedTabValue;
    this.selectedTabName = this.prevFocusedTabValue;
    return false;
  }

  private isProductReview(boardNo = this.currentBoardNo): boolean {
    return boardNo === this.REVIEW_BOARD_NO;
  }

  private get currentBoardNo(): number {
    if (this.getNode) {
      return this.getNode.boardNo;
    }
    return -1;
  }

  private get currentItemIdx(): number {
    return this.boards.findIndex(board => board.boardNo === this.currentBoardNo);
  }

  private get topTreeItemCount(): number {
    return this.boards.findIndex(board => board.boardNo > 0);
  }

  private get inquiriesRes(): PutInquiriesConfigurationsRequest {
    const board = this.boardsSub.find(board => board.boardNo === -1);
    if (board) {
      const { fileUsed: attachmentUsed, imageDisplayType, boardDescription: description, boardName, mallNo } = board;
      const data = {
        attachmentUsed,
        imageDisplayType,
        description,
        boardName,
        mallNo,
      };
      return {
        data,
      };
    } else {
      return {
        data: {
          attachmentUsed: false,
          imageDisplayType: '',
          description: '',
          boardName: '',
          mallNo: -1,
        },
      };
    }
  }

  private get productInquiriesRes(): PutProductInquiriesConfigurationsRequest {
    const board = this.boardsSub.find(board => board.boardNo === -2);
    if (board) {
      const {
        replyUsed: canReply,
        guestPostingUsed: guestWritable,
        secretPostingUsed: secretUsable,
        memberPostingUsed: memberWritable,
        boardSet: boardUsable,
        fileUsed: canAttach,
        imageDisplayType: boardImageType,
        boardDescription: description,
        boardName,
        mallNo,
      } = board;
      const data = {
        canReply,
        guestWritable,
        secretUsable,
        memberWritable,
        boardUsable,
        canAttach,
        boardImageType,
        description,
        boardName,
      };
      return {
        params: {
          mallNo,
        },
        data,
      };
    } else {
      return {
        params: {
          mallNo: this.mallNo,
        } /*  */,
        data: {
          canReply: false,
          guestWritable: false,
          secretUsable: false,
          memberWritable: false,
          boardUsable: false,
          canAttach: false,
          boardImageType: '',
          description: '',
          boardName: '',
        },
      };
    }
  }

  private getExpandedReviewSetting(board: BoardData) {
    return this.focusedTabName === BoardType.additional
      ? this.additionalSettingRef[0].getExpandedReviewSetting()
      : board.expandedReviewSetting;
  }

  private get reviewsInquiriesRes(): PutReviewsConfigurationsRequest {
    const board = this.boardsSub.find(board => board.boardNo === -3);

    if (board) {
      const {
        replyUsed: canReply,
        commentUseYn,
        boardType,
        guestPostingUsed: guestWritable,
        imageDisplayType: boardImageType,
        secretPostingUsed: secretUsable,
        memberPostingUsed: memberWritable,
        fileUsed: canAttach,
        mallNo,
        boardDescription: description,
        boardName,
      } = board;
      const data = {
        canReply,
        canComment: commentUseYn === 'Y',
        expandedReviewSetting: this.getExpandedReviewSetting(board),
        boardType,
        guestWritable,
        boardImageType,
        secretUsable,
        memberWritable,
        canAttach,
        mallNo,
        description,
        boardName,
      };
      return {
        data,
      };
    } else {
      return {
        data: {
          canReply: false,
          boardType: '',
          guestWritable: false,
          boardImageType: '',
          secretUsable: false,
          memberWritable: false,
          canAttach: false,
          mallNo: -1,
          description: '',
          boardName: '',
          expandedReviewSetting: {
            allReviewCntPerPage: 20,
            photoReviewCntPerPage: 20,
            productReviewCntPerPage: 20,
            reviewRewardNoticeText: '',
            accumulationRewardNoticeText: '',
            writingReviewNoticeText: '',
            photoReviewDisplayType: 'FIRST_TYPE',
            useGatheringPhotoReview: true,
            noPhotoReviewText: '',
            useReviewRecommend: true,
            widgetConfig: {
              allReviewWidgetCount: 10,
              useAllReviewWidget: true,
              photoReviewWidgetCount: 10,
              usePhotoReviewWidget: true,
              productReviewWidgetCount: 20,
              useProductReviewWidget: false,
            },
          },
          canComment: false,
        },
      };
    }
  }

  private mallNo = 0;
  private boards: BoardData[] = [];
  private boardsSub: BoardData[] = [];
  private treeNodes: NestedTreeNode[] = [];
  private defaultSelectNodeIndex = 1;

  get getBoardRequest() {
    return {
      params: {
        mallNo: this.mallNo,
      },
    };
  }

  /**
   * boardNo
   * -1:1:1 문의
   * -2:상품문의 ProductInquiries
   * -3:상품후기 ReviewsInquiries
   * >0:일반 게시판
   */
  private async getInquiries(request: GetInquiriesConfigurationsRequest) {
    await this.$api.getInquiriesConfigurations(request).then((ret): void => {
      this.$set(ret.data, 'boardNo', -1);
      this.$set(ret.data, 'boardName', ret.data.name);
      this.boards[0] = ret.data;
    });
  }

  private async getProductInquiries(request: GetProductInquiriesConfigurationsRequest) {
    await this.$api.getProductInquiriesConfigurations(request).then((ret): void => {
      this.$set(ret.data, 'boardNo', -2);
      this.boards[2] = ret.data;
    });
  }

  private async getReviewsInquiries(request: GetReviewsConfigurationsRequest) {
    await this.$api.getReviewsConfigurations(request).then((ret): void => {
      this.$set(ret.data, 'boardNo', -3);
      this.boards[1] = ret.data;
    });
  }

  private renderBoards() {
    this.boardsSub = this.treeNodes.map(board => {
      let data: BoardData = {};
      if (board.boardNo == -1) {
        //-1:1:1 문의
        data = {
          boardSet: true,
          boardName: board.nodeName,
          boardDescription: board.description,
          memberPostingUsed: board.memberPostingUsed,
          guestPostingUsed: false,
          secretPostingUsed: true,
          replyUsed: true,
          fileUsed: board.attachmentUsed,
          imageDisplayType: 'ATTACHMENT',
        };
      } else if (board.boardNo == -2) {
        //-2:상품문의
        data = {
          boardSet: true,
          boardName: board.nodeName,
          boardDescription: board.description,
          memberPostingUsed: board.memberWriteYn === 'Y' ? true : false,
          guestPostingUsed: false,
          secretPostingUsed: board.secretUseYn === 'Y' ? true : false,
          replyUsed: true,
          fileUsed: board.attachUseYn === 'Y' ? true : false,
          imageDisplayType: 'PRODUCT_MAIN_IMAGE',
        };
      } else if (board.boardNo == -3) {
        //-3:상품후기
        data = {
          boardType: board.reviewBoardType,
          boardSet: true,
          boardName: board.nodeName,
          boardDescription: board.description,
          memberPostingUsed: board.memberWriteYn === 'Y' ? true : false,
          guestPostingUsed: board.guestWriteYn === 'Y' ? true : false,
          secretPostingUsed: false,
          replyUsed: false,
          fileUsed: board.attachUseYn === 'Y' ? true : false,
          imageDisplayType: 'PRODUCT_IMAGE',
          useYn: board.reviewAccumulationInfo.useYn === 'Y' ? true : false,
        };
        if (data.useYn) {
          Object.assign(data, {
            reviewsLength: formatCurrency(Number(board.reviewAccumulationInfo.reviewsLength)),
            reviewsAccumulation: formatCurrency(Number(board.reviewAccumulationInfo.reviewsAccumulation)),
            photoReviewsLength: formatCurrency(Number(board.reviewAccumulationInfo.photoReviewsLength)),
            photoReviewsAccumulation: formatCurrency(Number(board.reviewAccumulationInfo.photoReviewsAccumulation)),
          });
        }
      } else if (board.boardNo > 0) {
        //>0:일반 게시판
        data = {
          boardId: board.boardId,
          boardType: board.displayType,
          boardSet: board.used,
          boardName: board.nodeName,
          boardDescription: board.boardDescription,
          memberPostingUsed:
            board.boardNo <= -2 ? (board.memberWriteYn === 'Y' ? true : false) : board.memberPostingUsed,
          guestPostingUsed: board.boardNo <= -2 ? (board.guestWriteYn === 'Y' ? true : false) : board.guestPostingUsed,
          secretPostingUsed: board.boardNo <= -2 ? (board.secretUseYn === 'Y' ? true : false) : board.secretPostingUsed,
          replyUsed: board.replyUsed,
          fileUsed: board.attachmentUsed,
          boardDisplayType: false,
        };
      }
      return { ...board, ...data };
    });
  }

  private async getAllBoards() {
    this.boards = [];
    const request = this.getBoardRequest;
    await this.getInquiries(request);
    await this.getReviewsInquiries(request);
    await this.getProductInquiries(request);

    this.$api.getBoards(request).then((ret): void => {
      this.boards = this.boards.concat(ret.data);
      this.setTreeNodes(this.boards);

      this.boardsSub.splice(0);
      this.$nextTick(() => {
        this.renderBoards();
      });
    });
  }

  private setTreeNodes(templates: BoardData[]) {
    this.treeNodes = replaceKeyNames<NestedTreeNode>(templates, { boardName: 'nodeName', boardNo: 'boardNo' });
    this.setTree();
  }

  @Watch('getEvent.eventCount', { deep: false })
  subscribeEvent() {
    const eventKeyword = this.getEvent.eventName;
    switch (eventKeyword) {
      case 'UP_NODE': {
        this.onOrderUp();
        break;
      }
      case 'DOWN_NODE': {
        this.onOrderDown();
        break;
      }
    }
  }
  private onOrderUp(): void {
    const idx = this.currentItemIdx;
    if (idx === this.topTreeItemCount) return;
    const idxBoard = this.boards.splice(idx, 1);
    this.boards.splice(idx - 1, 0, idxBoard[0]);
    this.defaultSelectNodeIndex = idx;
    this.setTreeNodes(this.boards);
  }

  private onOrderDown(): void {
    const idx = this.currentItemIdx;
    if (idx === this.boards.length - 1) return;
    const idxBoard = this.boards.splice(idx, 1);
    this.boards.splice(idx + 1, 0, idxBoard[0]);
    this.defaultSelectNodeIndex = idx + 2;
    this.setTreeNodes(this.boards);
  }

  private async setInquiries() {
    const request: PutInquiriesConfigurationsRequest = {
      ...this.inquiriesRes,
    };
    await this.$api.putInquiriesConfigurations(request);
  }

  private async setProductInquiries() {
    const request: PutProductInquiriesConfigurationsRequest = {
      ...this.productInquiriesRes,
    };
    await this.$api.putProductInquiriesConfigurations(request);
  }

  private async setReviewsInquiries() {
    const request: PutReviewsConfigurationsRequest = {
      ...this.reviewsInquiriesRes,
    };
    await this.$api.putReviewsConfigurations(request);
  }

  private async setBoardsDetail() {
    const boards = this.boardsSub.filter(board => board.boardNo > 0);

    if (boards.length) {
      const updates = boards.map((board: BoardsDetail & { fileUsed?: boolean; boardSet?: boolean }) => {
        const {
          boardNo: no,
          boardName: name,
          boardDescription: description,
          memberPostingUsed,
          guestPostingUsed,
          secretPostingUsed,
          replyUsed,
          fileUsed: attachmentUsed,
          boardSet: used,
          boardId,
        } = board;

        return {
          no,
          name,
          description,
          memberPostingUsed,
          guestPostingUsed,
          secretPostingUsed,
          replyUsed,
          used,
          attachmentUsed,
          boardId,
        };
      });

      const boardsDataRequest: PutCommonBoardsRequest = {
        data: {
          mallNo: this.mallNo,
          updates,
        },
      };
      await this.$api.putCommonBoards(boardsDataRequest);
    }
  }

  private async setBoardsSort() {
    const boards = this.boards.filter(board => board.boardNo > 0);
    const boardsSortRequest: PutCommonBoardsOrderRequest = {
      data: {
        mallNo: this.mallNo,
        updates: boards.map((board: BoardsDetail, index: number) => {
          return {
            boardNo: board.boardNo,
            newOrder: index,
          };
        }),
      },
    };

    await this.$api.putCommonBoardsOrder(boardsSortRequest);
  }

  private async setExcludedProduct() {
    const { productNosForSave, productNosForDelete } = this.exceptionSettingRef[0].getExceptionProductNosInfo();
    const request: PutReviewsConfigurationsExcludedProductsSyncRequest = {
      data: {
        mallNo: this.mallNo,
        productNosForSave,
        productNosForDelete,
      },
    };
    try {
      await this.$api.putReviewsConfigurationsExcludedProductsSync(request);
    } catch (e) {
      console.error(e);
    }
  }

  private isValidBoardInfo(): boolean {
    const hasEmptyBoardName = this.boardsSub.some(({ boardName }) => !boardName);
    hasEmptyBoardName && alert(this.$t('BOARD.BOARD.ERR_BOARD_NAME_NULL'));
    return !hasEmptyBoardName;
  }

  private async saveAll() {
    if (!this.isValidBoardInfo()) return;

    await this.setInquiries();
    await this.setProductInquiries();
    await this.setReviewsInquiries();
    await this.setBoardsSort();
    await this.setBoardsDetail();
    if (this.isProductReview() && this.focusedTabName === BoardType.exception) await this.setExcludedProduct();

    alert(this.$t('ALERT_SAVED'));
    this.getAllBoards();
  }

  private async init() {
    this.defaultSelectNodeIndex = 1;
    await this.getAllBoards();
    throwBottomNavigation({
      buttons: [
        {
          type: 'right',
          key: 'save',
          color: 'red',
          text: this.$t('SAVE'),
        },
      ],
      onClick: (key: string): void => {
        if (key === 'save') this.saveAll();
      },
    });
  }

  @Watch('$route')
  private onQueryChanged() {
    this.mallNo = getCurrentMallNo(this);
    this.init();
  }

  mounted() {
    this.mallNo = getCurrentMallNo(this);
    this.init();
  }
}
