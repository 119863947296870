// 승인 상태
export default Object.freeze({
  STATUS: '승인상태',

  REGISTRATION_READY: '등록대기',
  APPROVAL_READY: '승인대기',
  APPROVAL_REJECTION: '승인거부',
  SALE_AGREEMENT_READY: '판매합의대기',
  SALE_AGREEMENT_REJECTION: '판매합의거부',
  FINISHED: '승인완료',
  AFTER_APPROVAL_READY: '수정 후 승인대기',
  AFTER_APPROVAL_REJECTION: '수정 후 승인거부',
});
