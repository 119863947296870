/* eslint-disable quotes */
export default Object.freeze({
  SEARCH_FOR_MULTIPLE_PRODUCTS: "복수 상품 검색이 가능합니다. (Enter 또는 ','로 구분)",
  PARTIAL_SEARCH_IS_POSSIBLE: '일부 상품관리코드와 %를 이용한 부분 검색이 가능합니다.',
  ENTER_BRAND: '브랜드명 입력',
  ENTER_PARTNER: '파트너명 입력',
  ENTER_THE_PERSON: '담당자 입력',
  NO_SEARCH_RESULTS: '검색결과 없음.',

  BRAND_NAME: '브랜드명',
  PARTNER: '파트너사',

  MESSAGE1: '추가하실 상품을 선택해주세요',
  MESSAGE2: '조회된 상품이 없습니다.',
  MESSAGE3: '검색 된 상품을 모두 추가할인 대상 상품으로 추가하시겠습니까?',
  MESSAGE4: '최대',
  MESSAGE5: '개까지 추가할 수 있습니다',

  //slot panel
  PRODUCT_INQUIRY: '상품조회',
  SHOPPING_MALL: '쇼핑몰',
  SEARCH_WORD: '검색어',
  PRODUCT_STATUS: '상품상태',
  SALES_STATUS: '판매상태',
  EXHIBITION_CATEGORY: '전시카테고리',
  ADVANCED_SEARCH: '상세검색',
  MANAGER: '담당자',
  SALES_FEE_METHOD: '판매수수료 방식',
  ONLY_PRODUCTS_WITH: '* 공급가 직접 입력된 상품만 조회됩니다.',
  ADDITIONAL_ITEMS: '추가항목',
  // ADDITIONAL_ITEMS: '추가항목',

  //data mixin
  CONFIRM_MSG_ADDONE:
    '선택한 상품을 전시상품 우선순위에서 추가 하시겠습니까?<br>전시상품 우선순위 관리의 변경사항 저장버튼을 통해 최종 추가됩니다',
  CONFIRM_MSG_ADDALL:
    '선택한 상품을 전시상품 우선순위에전체 추가 하시겠습니까?<br>전시상품 우선순위 관리의 변경사항 저장버튼을 통해 최종 추가됩니다',
  CONFIRM_MSG_DELONE:
    '선택한 상품을 전시상품 우선순위에서 추가 하시겠습니까?<br>전시상품 우선순위 관리의 변경사항 저장버튼을 통해 최종 삭제됩니다',
  CONFIRM_MSG_DELALL:
    '선택한 상품을 전시상품 우선순위에전체 추가 하시겠습니까?<br>전시상품 우선순위 관리의 변경사항 저장버튼을 통해 최종 삭제됩니다',

  TEXT_ADDSELECTEDBTN: '선택상품 추가',
  TEXT_ADDALLBTN: '검색결과 전체 추가',
  TEXT_DELSELECTEDBTN: '선택상품 삭제',
  TEXT_DELALLBTN: '검색결과 전체 삭제',
  TEXT_CANCELBTN: '취소',

  PLEASE_SELECT_A_MALL_FIRST: '쇼핑몰을 먼저 선택해주세요',
  PRODUCT_NUMBER: '상품번호',
  PRODUCT_NAME: '상품명',
  PRODUCT_MANAGEMENT_CODE: '상품관리코드',
  PRODUCT_MANAGEMENT_CODE_SEARCH: '상품관리코드|부분검색 ',
  APPROVAL_STATUS: '승인상태',
  WAITING_FOR_REGISTRATION: '등록대기',
  WAITING_FOR_APPROVAL: '승인대기',
  REJECTION_OF_APPROVAL: '승인거부',
  WAITING_FOR_SALES_AGREEMENT: '판매합의대기',
  REJECTION_OF_SALES_AGREEMENT: '판매합의거부',
  APPROVAL_COMPLETED: '승인완료',
  WAITING_FOR_APPROVAL2: '수정 후 승인대기',
  REJECT_APPROVAL: '수정 후 승인거부',
  AVAILABILITY: '재고여부',
  NO_STOCK: '재고없음',
  IN_STOCK: '재고 있음',
  N5_OR_LESS: '5개 이하',
  N10_OR_LESS: '10개 이하',
  N50_OR_LESS: '50개 이하',
  N100_OR_LESS: '100개 이하',
  WHETHER_PROMOTION: '프로모션 여부',
  POSSIBLE: '가능',
  IMPOSSIBLE: '불가',
  EXHIBITION_STATUS: '전시상태',
  DISPLAY_POSSIBLE: '전시가능',
  NO_EXHIBITION: '전시안함',
  ADDITIONAL_DISCOUNT: '추가할인여부',
  ADDITIONAL_DISCOUNT_AVAILABLE: '추가할인 있음',
  NO_ADDITIONAL_DISCOUNT: '추가할인 없음',
  PLATFORM_CLASSIFICATION: '플랫폼구분',
  MOBILE_APP: '모바일 앱',
  MOBILE_WEB: '모바일 웹 ',
  ALL: '전체',
  BUY: '사입',
  CONSIGNMENT: '위탁',
  // ALL: '전체',
  COMMISSION_RATE: '수수료율',
  SUPPLY_PRICE: '공급가',
  PRE_APPROVAL_STATUS: '승인완료이전',
  ON_PRE_SALE: '예약판매중',
  WAITING_FOR_SALE: '판매대기',
  SALE: '판매중',
  END_OF_SALE: '판매종료',
  AVAILABLE_FOR_SALE: '판매가능',
  STOP_SELLING: '판매중지',
  PROHIBITION_OF_SALE: '판매금지',

  POP_INFO1: "'상품관리코드|부분검색' 안내",
  POP_INFO2: "'상품관리코드|부분검색'은 일부 상품관리코드와 %를 이용한 부분검색이 가능합니다.",
  POP_INFO3: '<b>[검색어]</b> 입력: 검색어가 포함된 상품관리코드를 검색합니다.',
  POP_INFO4: '<b>[검색어%]</b> 입력: 검색어가 앞에 포함된 상품관리코드를 검색합니다.',
  POP_INFO5: '<b>[%검색어]</b> 입력: 검색어가 뒤에 포함된 상품관리코드를 검색합니다.',

  TSHOPPING_MALL: '쇼핑몰',
  TPRODUCT_NUMBER: '상품번호',
  TPRODUCT_NAME: '상품명',
  TPRODUCT_MANAGEMENT_CODE: '상품관리코드',
  TBRAND: '브랜드',
  TPARTNER: '파트너사',
  TMANAGER: '담당자',
  TFEES: '수수료',
  TSUPPLY_PRICE: '공급가',
  TPURCHASE_PRICE: '매입가',
  TAPPROVAL_STATUS: '승인상태',
  TWAITING_FOR_REGISTRATION: '등록대기',
  TWAITING_FOR_APPROVAL: '승인대기',
  TREJECTION_OF_APPROVAL: '승인거부',
  TWAITING_FOR_SALES_AGREEMENT: '판매합의대기',
  TREJECTION_OF_SALES_AGREEMENT: '판매합의거부',
  TWAITING_FOR_APPROVAL_AFTER_MODIFICATION: '수정 후 승인대기',
  TREJECT_APPROVAL_AFTER_MODIFICATION: '수정 후 승인거부',
  TAPPROVAL_COMPLETED: '승인완료',
  TSALES_STATUS: '판매상태',
  TSALES_SETTING_STATUS: '판매설정',
  TEND_OF_SUBSCRIPTION_SALE: '예약판매종료',
  TEND_OF_SALE: '판매종료',
  TPRE_ORDER: '예약판매중',
  TON_SALE_ON_RESERVATION_SALE: '판매중/예약판매중',
  TSTOP_SELLING: '판매중지',
  TPROHIBITION_OF_SALE: '판매금지',
  TDISPLAY_POSSIBLE: '전시가능',
  TNO_EXHIBITION: '전시안함',
  TPRE_ORDER_PERIOD: '예약판매기간',
  TSALES_PERIOD: '판매기간',
  TDISCOUNT_APPLICABLE_PRICE: '할인적용가',
  TADDITIONAL_DISCOUNT: '추가할인',
  TADDITIONAL_DISCOUNT_APPLICABLE: '추가할인 적용가',
  // TADDITIONAL_DISCOUNT_APPLICABLE: '추가할인 적용가',
  TINVENTORY_QUANTITY: '재고수량', //재고수량&(판매가능재고)
  TAPPLY_PROMOTION: '프로모션 적용',
  TEXHIBITION_CATEGORY: '전시카테고리',

  //methods mixin
  MSOLD_OUT: '품절',
  MWAITING_FOR_REGISTRATION: '등록대기',
  MWAITING_FOR_APPROVAL: '승인대기',
  MREJECTION_OF_APPROVAL: '승인거부',
  MWAITING_FOR_SALES_AGREEMENT: '판매합의대기',
  MREJECTION_OF_SALES_AGREEMENT: '판매합의거부',
  MWAITING_FOR_APPROVAL_AFTER: '수정 후 승인대기',
  MREJECT_APPROVAL_AFTER: '수정 후 승인거부',
  MAPPROVAL_COMPLETED: '승인완료',
  MBEFORE_APPROVAL_COMPLETION: '승인완료이전',
  MWAITING_FOR_PRE_ORDER: '예약판매대기',
  MPRE_ORDER: '예약판매중',
  MWAITING_FOR_SALE: '판매대기',
  MSALE: '판매중',
  MEND_OF_SALE: '판매종료',
  MAVAILABLE_FOR_SALE: '판매가능',
  MSTOP_SELLING: '판매중지',
  MPROHIBITION_OF_SALE: '판매금지',
  MDISPLAY_POSSIBLE: '전시가능',
  MNO_EXHIBITION: '전시안함',
  MYOU_CAN_ONLY_ENTER_NUMBERS: '숫자와 콤마만 입력할 수 있습니다',
  MMINIMUM_VALUE_CANNOT_BE_GREATER: '최소값이 최대값보다 클 수 없습니다.',
  ADDITIONALDISCOUNTNAME: '추가할인명',
});
