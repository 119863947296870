



























import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import SmsReservationSearchForm from '@/views/contents/member/sms/SmsReservationSearchForm.vue';
import {
  getDisplayOptions,
  getSmsReservationHistoryGridOption,
  getSmsReservationHistoryInitQuery,
} from '@/const/contents/sms';
import Grid from '@/components/common/grid/Main.vue';
import {
  GetSmsGodoReservedHistory,
  GetSmsGodoReservedHistoryContent,
  GetSmsGodoReservedHistoryRequest,
  NCPResponse,
} from 'ncp-api-supporter';
import { GridEventProps } from '@/types';
import { throwWindowPopup } from '@/helpers/popup';
import { getCurrentMallNo } from '@/utils/mall';
import { Route } from 'vue-router';
import { isEmpty, omit } from 'underscore';
import { checkSearchResult } from '@/views/contents/member/kakao/SearchTemplates';

@Component({
  components: { SmsReservationSearchForm, Grid },
})
export default class SmsReservation extends Vue {
  @Ref()
  private readonly grid!: Grid;

  private contents: GetSmsGodoReservedHistoryContent[] = [];
  private totalCount = 0;
  private gridOption = getSmsReservationHistoryGridOption(this.$route.query.smsType?.toString());
  private displayOptions = getDisplayOptions();
  private startDate = getSmsReservationHistoryInitQuery(getCurrentMallNo(this)).startDate;
  private endDate = getSmsReservationHistoryInitQuery(getCurrentMallNo(this)).endDate;

  @Watch('$route')
  private async searchHistory(currRoute: Route, prevRoute: Route) {
    const needsReset = checkSearchResult(currRoute, prevRoute, 'MUST_SEARCH_HISTORY', this);
    if (needsReset) return;

    try {
      if (isEmpty(omit(this.$route.query, 'mallNo', 'smsSendKey'))) return;
      const request: GetSmsGodoReservedHistoryRequest = {
        params: {
          ...getSmsReservationHistoryInitQuery(getCurrentMallNo(this)),
          ...omit(this.$route.query, 'mallNo', 'smsSendKey'),
        },
      };
      const response: NCPResponse<GetSmsGodoReservedHistory> = await this.$api.getSmsGodoReservedHistory(request);

      const { startDate, endDate } = this.$route.query;
      this.startDate = startDate.toString();
      this.endDate = endDate.toString();
      this.totalCount = response.data.totalCount ?? 0;
      this.contents = response.data.contents ?? [];
      this.grid.changeNoDataMessage(this.$t('NO_RESULT'));
      this.gridOption = getSmsReservationHistoryGridOption(this.$route.query.smsType?.toString());
    } catch (e) {
      console.error(e);
    }
  }

  private onItemClicked(gridProps: GridEventProps) {
    if (gridProps.columnName !== 'sendCount') return;

    const type = this.$route.query.smsType?.toString();
    const smsSendKey = this.grid.getRowAt(gridProps.rowKey).smsSendKey;

    throwWindowPopup('SmsReservationDetail', { type }, 'md', () => this.searchHistory(this.$route, this.$route), null, {
      ...getSmsReservationHistoryInitQuery(getCurrentMallNo(this), 'info', {
        startDate: this.startDate,
        endDate: this.endDate,
      }),
      smsType: type,
      smsSendKey: smsSendKey.toString(),
    });
  }

  mounted() {
    this.grid.changeNoDataMessage(this.$t('MUST_SEARCH_HISTORY'));
  }
}
