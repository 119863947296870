export default Object.freeze({
  // grouplist template
  PERMISSION_GROUP_MANAGEMENT: '권한그룹 관리',
  QUERY_RESULTS: '조회결과',
  DELETE_PERMISSION_GROUP: '권한그룹 삭제',
  PERMISSION_GROUP_REPLICATION_REGISTRATION: '권한그룹 복사등록',
  REGISTER_NEW_PERMISSION_GROUP: '신규 권한그룹 등록',

  // grouplist script
  DELETEGROUPS_UNCHECKED: '삭제 할 권한그룹을 선택 해 주세요.',
  DELETEGROUPS_ARE_YOU_SURE: '삭제한 권한그룹 정보는 복구되지 않습니다. 이 권한그룹을 삭제 하시겠 습니까?',
  UPDATEGROUPS_UNCHECKED: '복사 등록 할 권한그룹을 선택 해 주세요.',
  PERMISSION_GROUP_HAS_BEEN_DELETED: '권한그룹이 삭제 되었습니다.',
  QUERY_OPERATORS_WITH_PERMISSION: '권한보유 운영자 조회',

  // group popup
  GROUPPOPUP_TITLE: '권한보유 운영자 조회',
  NO_SEARCH_RESULTS: '검색결과가 없습니다.',
});
