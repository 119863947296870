export const DEFAULT_GRID_PER_PAGE = '30';
export const DEFAULT_GRID_PAGE_SIZE_KEY = 'pageSize';
export const DEFAULT_GRID_PER_PAGE_OPTIONS = [
  {
    value: '30',
    name: 'PAGE_SIZE_30',
  },
  {
    value: '50',
    name: 'PAGE_SIZE_50',
  },
  {
    value: '100',
    name: 'PAGE_SIZE_100',
  },
  {
    value: '200',
    name: 'PAGE_SIZE_200',
  },
];
