












































import { Vue, Component } from 'vue-property-decorator';
import {
  getOperateSecurityCheckBoxOption,
  getOperateSecurityRadioBoxOption,
  getOperateSecurityToolTipOption,
  i18nSecurity,
} from '@/const/contents/configuration/operateSecurity';
import AuthNoticeBox from '@/views/contents/configuration/security/operateSecurity/AuthNoticeBox.vue';
import CheckboxGroup from '@/components/common/CheckboxGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';

@Component({
  components: {
    RadioGroup,
    ToolTip,
    CheckboxGroup,
    AuthNoticeBox,
  },
})
export default class ExcelDownloadSecuritySetting extends Vue {
  private readonly i18nSecurity = i18nSecurity;

  private excelDownloadSecurity = false;
  private securityScope = '';
  private authMethodFromExcel = '';

  private toolTipOption = getOperateSecurityToolTipOption();
  private checkBoxOption = getOperateSecurityCheckBoxOption();
  private radioBoxOption = getOperateSecurityRadioBoxOption();
}
