






































































































































































































































































import { Component, Ref, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/common/SelectBox.vue';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ChainingSelector from '@/components/common/ChainingSelector.vue';
import ReplacementTextTable from '@/components/common/ReplacementTextTable.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import ButtonConfig from '@/views/contents/member/kakao/popup/ButtonConfig.vue';
import { isValidate } from '@/utils/validate';
import { getStrYMDHMSS } from '@/utils/dateFormat';
import {
  templateTypeOption,
  buttonUsedOption,
  getKakaoTemplateInitData,
  buttonCondition,
  linkButtonData,
  detailTemplateData,
  getButtonsInitData,
} from '@/const/contents/kakao';
import {
  BusinessCategoryType,
  GetKakaoTemplatesCategoriesRequest,
  NCPResponse,
  PostKakaoTemplatesAutoRequest,
  PutKakaoTemplateAutoRequest,
  PutKakaoTemplatesAutoInspectionRequest,
  DeleteKakaoTemplateAutoRequest,
  TemplateGroupType,
} from 'ncp-api-supporter';
import { MessageTemplateDesc } from '@/types/member';
import { kakaoTemplateObject } from '@/const/words/templateStrings';
import { getCurrentMallNo } from '@/utils/mall';

export enum KakaoTemplateType {
  REGISTER = 'REGISTER',
  DETAIL = 'DETAIL',
}
@Component({
  components: { ButtonConfig, ToolTip, ReplacementTextTable, ChainingSelector, RadioGroup, TextInput, SelectBox },
})
export default class KakaoTemplateManager extends WindowPopupMainVue {
  @Ref() private readonly templateNameInput!: TextInput;
  @Ref() private readonly templateContentInput!: HTMLInputElement;
  @Ref() private readonly buttonConfig!: ButtonConfig;

  private kakaoTemplateData = getKakaoTemplateInitData(getCurrentMallNo(this));
  private linkButtonData = linkButtonData;
  private detailTemplateData = detailTemplateData;
  private readonly templateTypeOption = templateTypeOption;
  private readonly buttonUsedOption = buttonUsedOption;
  private getStrYMDHMSS = getStrYMDHMSS;
  private buttonUsedType = false;
  private isPreview = false;
  private previewTemplateContent = this.kakaoTemplateData.data.templateContent;
  private etcComment = '';

  private get etcCommentLength(): number {
    return this.etcComment.length;
  }

  private get templateNo(): null | number {
    return this.isRegisterType ? null : this.data.template.templateNo;
  }

  private get isRegisterType(): boolean {
    return this.data.type === KakaoTemplateType.REGISTER;
  }

  private get isApproved(): boolean {
    return this.detailTemplateData.inspectionStatus === 'APPROVE';
  }

  private get isRequest(): boolean {
    return this.detailTemplateData.inspectionStatus === 'REQUEST';
  }

  private get isRegister(): boolean {
    return this.detailTemplateData.inspectionStatus === 'REGISTER';
  }

  private get disableTemplate(): boolean {
    return !this.isRegisterType && (this.isRequest || this.isApproved);
  }

  private get inspectTemplate(): boolean {
    return (
      !this.isRegisterType &&
      (this.detailTemplateData.inspectionStatus === 'REJECT' || this.isApproved || this.isRequest)
    );
  }

  private get filterChildCategory(): BusinessCategoryType[] {
    if (this.isRegisterType) this.linkButtonData.childCategory = '';
    return this.linkButtonData.templateChildCategory.filter(
      ({ parentCode }) => parentCode === this.linkButtonData.parentCategory,
    );
  }

  private get templateCategoryCode(): string {
    const INIT_INDEX = 0;
    const etcCategory = ({ groupName }: BusinessCategoryType) => groupName === this.$t('MEMBER.KAKAO.ETC');
    const etcChildCategory = this.linkButtonData.templateChildCategory.filter(etcCategory);

    if (this.linkButtonData.parentCategory === '') {
      const etcParentCategory = this.linkButtonData.templateParentCategory.filter(etcCategory);
      return etcParentCategory[INIT_INDEX].code + etcChildCategory[INIT_INDEX].code;
    }
    if (this.linkButtonData.childCategory === '') {
      return this.linkButtonData.parentCategory + etcChildCategory[INIT_INDEX].code;
    }
    return this.linkButtonData.parentCategory + this.linkButtonData.childCategory;
  }

  private get kakaoTemplateStrings(): MessageTemplateDesc {
    return kakaoTemplateObject[this.kakaoTemplateData.data.templateGroupType as TemplateGroupType]();
  }

  private get originButtons() {
    return this.data?.template?.buttons;
  }
  @Watch('buttonUsedType')
  private changeButtonUsedType() {
    if (this.buttonUsedType) return;

    const needsOriginButtons = !this.isRegisterType && this.originButtons?.length > 0;

    this.kakaoTemplateData.data.buttons = needsOriginButtons ? [...this.originButtons] : [...getButtonsInitData()];
  }

  private showPreview() {
    this.isPreview = !this.isPreview;
    this.previewTemplateContent = this.convertTemplate();
  }

  private convertTemplate(): string {
    let content = this.kakaoTemplateData.data.templateContent;
    this.kakaoTemplateStrings.forEach(({ key, desc }) => {
      content = content.replaceAll(key, desc);
    });
    return content;
  }

  private inputReplacementText(key: string) {
    const cursorPosition = this.templateContentInput.selectionStart;
    if (!cursorPosition) {
      this.kakaoTemplateData.data.templateContent += key;
      return;
    }
    const content = this.kakaoTemplateData.data.templateContent;
    this.kakaoTemplateData.data.templateContent =
      content.slice(0, cursorPosition) + key + content.slice(cursorPosition);
  }

  private validateTemplateForm(isModify = false): boolean {
    const condition = buttonCondition(this.kakaoTemplateData.data, this.templateCategoryCode);

    if (!isModify && !isValidate(condition.noTemplateName, this.$t('MEMBER.KAKAO.ALERT_TEMPLATE_NAME'))) {
      this.templateNameInput.focus();
      return false;
    }
    if (!isValidate(condition.noTemplateContent, this.$t('MEMBER.KAKAO.ALERT_TEMPLATE_CONTENT'))) {
      this.templateContentInput.focus();
      return false;
    }
    const noButtonName = !(this.buttonUsedType && this.buttonConfig.emptyButtonName());
    if (!isValidate(noButtonName, this.$t('MEMBER.KAKAO.ALERT_BUTTON_NAME'))) return false;
    const noButtonLink = !(this.buttonUsedType && this.buttonConfig.emptyButtonLink());
    if (!isValidate(noButtonLink, this.$t('MEMBER.KAKAO.ALERT_BUTTON_CONTENT'))) return false;
    // return isValidate(
    //   isModify || (!isModify && condition.noOverLimitCode),
    //   this.$t('MEMBER.KAKAO.ALERT_TEMPLATE_CODE'),
    // );
    return true;
  }

  private validateDeleteTemplate(): boolean {
    const usingTemplate = this.data.template.used && this.isApproved;
    if (!isValidate(!usingTemplate, this.$t('MEMBER.KAKAO.ALERT_USING_TEMPLATE'))) return false;
    const defaultTempate = this.detailTemplateData.templateCode.includes('default');
    if (!isValidate(!defaultTempate, this.$t('MEMBER.KAKAO.ALERT_DEFAULT_TEMPLATE'))) return false;
    return confirm(this.$t('MEMBER.KAKAO.CONFIRM_DELETE').toString());
  }

  private detailTemplateValue() {
    const { comments, templateCode, inspectionStatus, apply, senderKey, security, categoryCode } = this.data.template;
    this.detailTemplateData = {
      ...this.detailTemplateData,
      inspectResultComment: [...comments],
      templateCode,
      inspectionStatus,
      applyTemplate: apply,
      senderKey,
      categoryCode,
    };

    this.kakaoTemplateData.data = {
      ...this.kakaoTemplateData.data,
      ...this.data.template,
      isSecurity: security,
    };

    this.linkButtonData.parentCategory = '';
    this.linkButtonData.childCategory = '';

    if (this.detailTemplateData.categoryCode) {
      this.linkButtonData.parentCategory = this.detailTemplateData.categoryCode.substring(0, 3);
      this.linkButtonData.childCategory = this.detailTemplateData.categoryCode.substring(3, 6);
    }

    this.buttonUsedType = this.originButtons?.length > 0;
    this.changeButtonUsedType();
  }

  private modifiedValue() {
    this.kakaoTemplateData.data.categoryCode = this.templateCategoryCode;
    this.kakaoTemplateData.data.buttons = this.buttonUsedType
      ? this.kakaoTemplateData.data.buttons?.map((button, index) => {
          return { ...button, ordering: index + 1 };
        })
      : null;
  }

  created() {
    this.fetchTemplateCategoryData();
    if (!this.isRegisterType) this.detailTemplateValue();
  }

  private get request(): DeleteKakaoTemplateAutoRequest {
    return {
      pathParams: {
        templateNo: this.templateNo,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
    };
  }
  private async fetchTemplateCategoryData(): Promise<void> {
    const request = {
      ...this.request,
      pathParams: {
        categoryCode: this.kakaoTemplateData.data.categoryCode,
      },
    } as GetKakaoTemplatesCategoriesRequest;
    const { data } = await this.$api.getKakaoTemplatesCategories(request);

    this.linkButtonData.templateParentCategory = data.firstBusinessType;
    this.linkButtonData.templateChildCategory = data.secondBusinessType;
  }

  private close(status, msg = '') {
    if (status === 204) {
      msg && alert(this.$t(msg).toString());
      this.onPositiveClick();
    }
  }
  private async registerTemplate(): Promise<void> {
    if (!this.validateTemplateForm()) return;

    this.modifiedValue();
    const request: PostKakaoTemplatesAutoRequest = { ...this.kakaoTemplateData };
    try {
      const response: NCPResponse<void> = await this.$api.postKakaoTemplatesAuto(request);
      this.close(response.status, 'MEMBER.KAKAO.ALERT_SUCCESS');
    } catch (e) {
      console.error(e);
    }
  }

  private requestInspect() {
    if (!this.validateTemplateForm()) return;
    if (!confirm(this.$t('MEMBER.KAKAO.CONFIRM_INSPECT').toString())) return;
    this.modifiedValue();
    this.putKakaoTemplatesInspect().then(status => this.close(status, 'MEMBER.KAKAO.SUCCESS_INSPECT_TEMPLATE'));
  }

  private async putKakaoTemplatesInspect(): Promise<number> {
    const request: PutKakaoTemplatesAutoInspectionRequest = {
      pathParams: {
        templateNo: this.templateNo,
      },
      data: {
        mallNo: getCurrentMallNo(this),
        comment: this.etcComment,
      },
    };
    const { status } = await this.$api.putKakaoTemplatesAutoInspection(request);
    return status;
  }

  private async deleteTemplate(): Promise<void> {
    if (!this.validateDeleteTemplate()) return;

    const { status } = await this.$api.deleteKakaoTemplateAuto(this.request);
    this.close(status, 'MEMBER.KAKAO.SUCCESS_DELETE_TEMPLATE');
  }

  private async modifyTemplate(): Promise<void> {
    if (!this.validateTemplateForm(true)) return;

    this.modifiedValue();
    const request: PutKakaoTemplateAutoRequest = {
      pathParams: {
        templateNo: this.templateNo,
      },
      data: {
        ...this.kakaoTemplateData.data,
      },
    };
    const { status } = await this.$api.putKakaoTemplateAuto(request);
    this.close(status, 'MEMBER.KAKAO.SUCCESS_MODIFY_TEMPLATE');
  }
}
