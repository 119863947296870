
























import { Component, Mixins, Watch } from 'vue-property-decorator';
import NoticeBox from '@/components/common/NoticeBox.vue';
import { NavButton } from '@/types/bottomNav';
import { throwBottomNavigation } from '@/helpers/bottomNav';
import TreeContainer from '@/views/contents/member/email/tree/TreeContainer.vue';
import { NestedTreeNode, TreeNode } from '@/types/tree';
import { Action, Getter, namespace } from 'vuex-class';
import Cookies from 'js-cookie';
import {
  GetMailTemplatesAutoGroupsRequest,
  NCPResponse,
  PutMailTemplatesAutoTemplatesRequest,
  GetMailTemplatesAutoGroups,
  PostMailTemplatesAutoTemplatesTestRequest,
  GetMailAutoTemplatesDefaultResponse,
  GetMailAutoTemplatesDefaultRequest,
} from 'ncp-api-supporter';
import { replaceKeyNames } from '@/utils/keyReplace';
import TemplateEditForm from '@/views/contents/member/email/tree/TemplateEditForm.vue';
import { ThrowMessagePopup, throwPopup } from '@/helpers/popup';
import { isValidate } from '@/utils/validate';
import { mailTemplate } from '@/const/words/templateStrings';
import { getCurrentMallNo } from '@/utils/mall';
import EmailInfoMixin from '@/views/contents/member/email/EmailInfoMixin';
import $el from '@/utils/element';
import { range1ToNI18n } from '@/utils/common';
import OpenAdNoticePopupMixin from '@/views/contents/member/sms/mixins/OpenAdNoticePopupMixin.vue';

type EmailAutoTemplateBottomNavKey = 'openReplacementText' | 'sendTestMail' | 'onSave';

const tree = namespace('tree');

@Component({
  components: { TemplateEditForm, TreeContainer, NoticeBox },
})
export default class EmailAutoTemplate extends Mixins(EmailInfoMixin, OpenAdNoticePopupMixin) {
  @tree.Action('setTree')
  private readonly setTree!: () => void;
  @tree.Getter('getNode')
  private readonly getNode!: TreeNode;
  @tree.Action('setNode')
  private readonly setNode!: (data: { node: TreeNode; path: string[] }) => void;
  @tree.Action('resetStore')
  private readonly resetStore: () => void;
  @Getter('hasUnsavedForm')
  private readonly hasUnsavedForm!: boolean;
  @Getter('getDetectUnsavedFormMessage')
  private readonly getDetectUnsavedFormMessage!: ThrowMessagePopup;
  @Action('setInitUnsavedForm')
  private readonly setInitUnsavedForm!: () => void;
  @Action('setUnsavedForm')
  private readonly setUnsavedForm!: () => void;
  @Action('setInitDetectUnsavedFormMessage')
  private readonly setInitDetectUnsavedFormMessage!: () => void;

  private isUpdated = false;

  private treeNodes: NestedTreeNode[] = [];
  private defaultSelectNodeIndex = 2;
  private templateCount = 0;
  private readonly noticeList = range1ToNI18n(5, 'MEMBER.MAIL.TEMPLATE_NOTICE_');
  private readonly buttonList: NavButton[] = [
    { type: 'left', key: 'openReplacementText', color: 'white', text: this.$t('MEMBER.MAIL.REPLACEMENT_TEXT') },
    { type: 'left', key: 'sendTestMail', color: 'white', text: this.$t('MEMBER.MAIL.SEND_TEST') },
    { type: 'right', key: 'onSave', color: 'red', text: this.$t('SAVE') },
  ];
  private firstClick = ['주문배송 관련', '프로모션 관련', '문의 관련'];

  private checkUnsavedForm() {
    if (!this.hasUnsavedForm || !this.getNode.isLeaf) return true;
    const { message }: ThrowMessagePopup = this.getDetectUnsavedFormMessage;

    const accept = confirm(this.$t(message.toString()).toString());
    if (accept) {
      this.setInitDetectUnsavedFormMessage();
      this.setInitUnsavedForm();
      return true;
    }
    return false;
  }

  private async resetTemplate() {
    if (!confirm(this.$t('MEMBER.MAIL.TEMPLATE_RESET_CONFIRM').toString())) return;

    const request: GetMailAutoTemplatesDefaultRequest = {
      pathParams: {
        templateType: this.getNode.type,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
    };
    const response: NCPResponse<GetMailAutoTemplatesDefaultResponse> = await this.$api.getMailTemplatesAutoTemplatesDefault(
      request,
    );
    this.setNode({ node: { ...this.getNode, ...response.data }, path: ['', response.data.name] });
    this.setUnsavedForm();
  }

  private openReplacementText() {
    throwPopup({
      name: 'ReplacementTextList',
      data: { template: mailTemplate(), noticeList: [this.$t('MEMBER.MAIL.INTERCHANGE_GUIDE')] },
    });
  }

  private async sendTestMail() {
    const request: PostMailTemplatesAutoTemplatesTestRequest = {
      pathParams: {
        templateType: this.getNode.type,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
    };

    try {
      await this.$api.postMailTemplatesAutoTemplatesTest(request);
      const adminName = JSON.parse(Cookies.get('adminInfo')).name;
      alert(this.$t('MEMBER.MAIL.SEND_SUCCESS', [adminName]));
    } catch (e) {
      alert(this.$t('MEMBER.MAIL.SEND_FAIL'));
    }
  }

  private validateMailTemplate(): boolean {
    const existTitle = isValidate(this.getNode.title.length, this.$t('MEMBER.MAIL.TITLE_ALERT'));
    if (!existTitle) return false;
    const existContent = isValidate(this.getNode.content.length, this.$t('MEMBER.MAIL.CONTENT_ALERT'));
    if (!existContent) return false;

    if (!this.outboundEmail) {
      alert(this.$t('MEMBER.MAIL.MUST_EMAIL'));
      return false;
    }

    if (!this.serviceCenterEmail) {
      alert(this.$t('MEMBER.MAIL.MUST_SERVICE_EMAIL'));
      return false;
    }
    return isValidate(this.useType, this.$t('MEMBER.MAIL.NOT_USE_ALERT'));
  }

  private async onSave() {
    if (!this.validateMailTemplate()) return;

    const request: PutMailTemplatesAutoTemplatesRequest = {
      pathParams: {
        templateType: this.getNode.type,
      },
      params: {
        mallNo: getCurrentMallNo(this),
      },
      data: {
        usable: this.getNode.used,
        title: this.getNode.title,
        content: this.getNode.content,
        senderMailType: this.getNode.senderMailType,
      },
    };
    try {
      await this.$api.putMailTemplatesAutoTemplates(request);
      this.defaultSelectNodeIndex = Number(this.getNode.nodeId.split('-').pop()) % this.templateCount;
    } catch (e) {
      if (e.status !== 403) {
        alert(this.$t(e.message));
      }
    }
    await this.getAutoTemplate(this.defaultSelectNodeIndex);
  }

  private onClickBottomNav(key: EmailAutoTemplateBottomNavKey) {
    switch (key) {
      case 'openReplacementText':
        this.openReplacementText();
        break;
      case 'sendTestMail':
        this.sendTestMail();
        break;
      case 'onSave':
        this.onSave();
        break;
      default:
        break;
    }
  }

  @Watch('$route.query')
  private async fetchTemplateInfo() {
    await Promise.all([this.fetchMainUsedType(), this.fetchMailInfo(), this.getAutoTemplate()]);
  }

  private async getAutoTemplate(defaultIndex = 2) {
    this.resetStore();
    this.defaultSelectNodeIndex = defaultIndex;
    const request: GetMailTemplatesAutoGroupsRequest = { params: { mallNo: getCurrentMallNo(this) } };
    try {
      const { data }: NCPResponse<GetMailTemplatesAutoGroups[]> = await this.$api.getMailTemplatesAutoGroups(request);
      this.setTreeNodes(data);
      this.templateCount = data.reduce((acc, { templates }) => acc + templates.length, 0) + data.length + 1;
    } catch (e) {
      console.error(e);
    }
  }

  private setTreeNodes(templates: GetMailTemplatesAutoGroups[]) {
    this.treeNodes = replaceKeyNames<NestedTreeNode>(templates, { name: 'nodeName', templates: 'children' });
    this.setTree();
  }

  private toggleNode(event) {
    event.stopPropagation();
    if (this.getNode.isLeaf) return;
    const selectedNode = $el(document.querySelector(`#${this.getNode.nodeId}`));

    if (!selectedNode.getElement()?.classList) return;

    if (this.firstClick.includes(this.getNode.nodeName)) {
      const idx = this.firstClick.indexOf(this.getNode.nodeName);
      if (idx > -1) this.firstClick.splice(idx, 1);
      return;
    }

    if (selectedNode.find('.tui-tree-subtree')[0].getElement().style.display === 'none') {
      selectedNode.find('.tui-tree-subtree')[0].getElement().style.display = '';
    } else {
      selectedNode.find('.tui-tree-subtree')[0].getElement().style.display = 'none';
    }
    selectedNode.getElement().classList.toggle('tui-tree-closed');
    selectedNode.getElement().classList.toggle('tui-tree-opened');
  }

  private registerToggleEvent() {
    const selectedNode = document.querySelector('.tui-tree-root');
    selectedNode?.addEventListener('click', this.toggleNode);
  }

  async created() {
    throwBottomNavigation({
      buttons: this.buttonList,
      onClick: (key: EmailAutoTemplateBottomNavKey) => this.onClickBottomNav(key),
    });
    await this.fetchTemplateInfo();
  }

  updated() {
    this.registerToggleEvent();
  }

  beforeDestroyed() {
    const selectedNode = document.querySelector('.tui-tree-root');
    selectedNode?.removeEventListener('click', this.toggleNode);
  }
}
