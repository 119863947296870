import cookies from 'js-cookie';
import { SHOPBY_ACCESSTOKEN, SHOPBY_REFRESHTOKEN, TOKEN_EXPIRE_TIME } from '@/const/cookie';
import { encrypt } from '@/utils/crypto';

export const sendTokenPostMessage = (ref: HTMLIFrameElement ) => {

  ref.contentWindow.postMessage(
    {
      SHOPBY_ACCESS_TOKEN: cookies.get(SHOPBY_ACCESSTOKEN),
      SHOPBY_REFRESH_TOKEN: cookies.get(SHOPBY_REFRESHTOKEN),
      [encodeURIComponent(encrypt(TOKEN_EXPIRE_TIME))]: cookies.get(encodeURIComponent(encrypt(TOKEN_EXPIRE_TIME))),
    },
    process.env.VUE_APP_ADMIN_REMOTE_URL,
  );
};