








































import { Component, Mixins, Watch } from 'vue-property-decorator';
import StatusSummaryBox from '@/components/root/parts/StatusSummaryBox.vue';
import SummaryData, { Summary } from '@/components/root/SummaryMixin';
import { namespace } from 'vuex-class';
import { GetOrderOptionsSaleSummaryMall, Mall } from 'ncp-api-supporter';

const mallStore = namespace('mall');

@Component({
  components: { StatusSummaryBox },
})
export default class SaleSummary extends Mixins(SummaryData) {
  @mallStore.Getter('getMalls')
  private readonly malls!: Mall[];

  private periodQueryObject = this.getPeriodQueryObject(-1, 'month');

  private summaries: Summary[] = [
    {
      key: 'depositWait',
      label: 'DEPOSIT_WAIT',
      count: null,
      path: {
        name: 'OrderDepositWaitIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'newOrder',
      label: 'PAY_DONE',
      count: null,
      path: {
        name: 'OrderPayDoneIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'productPrepare',
      label: 'PRODUCT_PREPARE',
      count: null,
      path: {
        name: 'OrderProductPrepareIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'deliveryPrepare',
      label: 'DELIVERY_PREPARE',
      count: null,
      path: {
        name: 'OrderDeliveryPrepareIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'holdDelivery',
      label: 'HOLD_DELIVERY',
      count: null,
      path: {
        name: 'OrderHoldDeliveryIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'reserveOrder',
      label: 'RESERVATION',
      count: null,
      path: {
        name: 'OrderPreOrderIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'deliveryIng',
      label: 'DELIVERY_ING',
      count: null,
      path: {
        name: 'OrderDeliveringIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'deliveryDone',
      label: 'DELIVERY_DONE',
      count: null,
      path: {
        name: 'OrderDeliveryDoneIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
    {
      key: 'buyConfirm',
      label: 'BUY_CONFIRM',
      count: null,
      path: {
        name: 'OrderBuyConfirmIframe',
        query: {
          ...this.periodQueryObject,
        },
      },
    },
  ];

  private get mallNos(): number[] {
    return this.malls.map(({ mallNo }) => mallNo);
  }

  created() {
    if (this.saleSummary) this.mapData();
    this.fetch();
  }

  @Watch('$route.query.latestRefresh')
  @Watch('mallNoQuery')
  async fetch() {
    await this.fetchData();
    this.mapData();
  }

  private async fetchData(): Promise<void> {
    await this.fetchSaleInquirySummary({
      mallNos: this.mallNoQuery === 'ALL' ? this.mallNos.join(',') : this.mallNoQuery,
    });
  }

  private mapData(): void {
    this.mapSummaries<GetOrderOptionsSaleSummaryMall>(this.saleSummary);
  }

  get mallParams(): string {
    return this.mallNoQuery === 'ALL' ? '' : `?mallNo=${this.mallNoQuery}`;
  }

  private mapSummaries<DataType = object>(data: DataType): void {
    Object.entries(data).forEach(([k, v]) => {
      const matched = this.summaries.find(({ key }) => key === k);
      if (matched) matched.count = v;
    });
    this.summaries = this.summaries.map(summary => {
      summary.query = this.mallNoQuery === 'ALL' ? { mallNos: '' } : { mallNos: this.mallNoQuery.toString() };
      return summary;
    });
  }

  // 1차 오픈 스펙 제외
  // private lookupSettingData: LookupSettingData = {
  //   title: this.$t('SUMMARY.SALES_STATUS').toString(),
  //   dateRange: {
  //     start: addDay(new Date(), -7),
  //     end: getToday(),
  //   },
  // };

  /**
   * 결제완료는 프로에서는 newOrder 이고
   * 신규주문은 newOrder + depositWait 라고한다.
   * 엄~~청~~ 햇갈린다.
   */
  private get newOrderSummary(): Summary {
    const newOrderCount =
      this.summaries.find(({ key }) => key === 'newOrder').count +
      this.summaries.find(({ key }) => key === 'depositWait').count;

    return {
      key: '',
      label: 'NEW_ORDER',
      count: newOrderCount, // newOrder + depositWait
    };
  }

  private get splitSummaries(): { left: Summary[]; right: Summary[] } {
    const right = [...this.summaries];
    const left = right.splice(0, 6);

    return {
      left,
      right,
    };
  }
}
