






import { Vue, Component } from 'vue-property-decorator';
import { i18nListByKey } from '@/utils/i18n';
import TabMenu, { TabMenuI } from '@/components/common/TabMenu.vue';

@Component({
  components: {
    TabMenu,
  },
})
export default class SmsMain extends Vue {
  private menuList: TabMenuI[] = [
    { display: 'MEMBER.SMS.TAB_USE_CONFIG', path: 'config' },
    { display: 'MEMBER.SMS.TAB_DISPATCH', path: 'send' },
    { display: 'MEMBER.SMS.TAB_AUTOSMS', path: 'automation' },
    { display: 'MEMBER.SMS.TAB_SENDLIST', path: 'history' },
    { display: 'MEMBER.SMS.TAB_RESERVATION_CONFIG', path: 'reservation' },
  ];

  created() {
    this.menuList = i18nListByKey(this, this.menuList, 'display');
  }
}
