






























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { GetSettlementsRequest } from 'ncp-api-supporter';
import { range1ToNI18n } from '@/utils/common';
import { partnerSettlementsQuery, partnerSalesType } from '@/const/contents/partner';
import MallSelect from '@/components/common/input/MallSelect.vue';
import SearchButton from '@/components/searchForm/SearchButton.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import NoticeBox from '@/components/common/NoticeBox.vue';
import DatePeriodPicker from '@/components/common/DatePeriodPicker.vue';
import { defaultDateInfo, getYearMonth } from '@/views/contents/statistics/sales/periodSales';
import { PERIOD_TYPE } from '@/const/contents/statistics';
import { isEmpty } from 'underscore';

@Component({
  components: { DatePeriodPicker, NoticeBox, SelectBox, SearchButton, MallSelect },
})
export default class SearchForm extends Vue {
  private readonly noticeList = range1ToNI18n(5, 'PARTNER.SALES.NOTICE_');
  private readonly partnerSalesType = partnerSalesType;
  private partnerSettlementsQuery: GetSettlementsRequest['params'] = { ...partnerSettlementsQuery() };
  private periodType = PERIOD_TYPE.MONTH;
  private selectYear: number | null = null;
  private selectMonth: number | null = null;

  private reset() {
    this.partnerSettlementsQuery = { ...partnerSettlementsQuery() };
    this.periodType = PERIOD_TYPE.MONTH;
  }

  private resetDate() {
    const { year, month } = defaultDateInfo();
    this.selectYear = year;
    this.selectMonth = month;
  }

  private allReset() {
    this.resetDate();
    this.reset();
  }

  @Watch('$route.query')
  private initialSetting() {
    if (!isEmpty(this.$route.query)) {
      this.partnerSettlementsQuery = { ...partnerSettlementsQuery(), ...this.$route.query };
      const { year, month } = getYearMonth(this.$route.query.startDateTime.toString());
      this.selectYear = year;
      this.selectMonth = month;
      return;
    }

    this.allReset();
  }

  created() {
    this.initialSetting();
  }
}
