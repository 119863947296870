import { RouteConfig } from 'vue-router';
import NoPermission from '@/views/etc/NoPermission.vue';
import WithdrawalMemberError from '@/views/etc/WithdrawalMemberError.vue';
import AdminAccountError from '@/views/etc/AdminAccountError.vue';
import AdminExpiryTerm from '@/views/etc/AdminExpiryTerm.vue';
import NotFound from '@/views/etc/NotFound.vue';

export default (): RouteConfig[] => {
  return [
    {
      path: '/no-permission',
      name: 'NoPermission',
      component: NoPermission,
      meta: {
        nonMember: true,
      },
    },
    {
      path: '/withdrawal-member-error',
      name: 'WithdrawalMemberError',
      component: WithdrawalMemberError,
    },
    {
      path: '/admin-account-error',
      name: 'AdminAccountError',
      component: AdminAccountError,
    },
    {
      path: '/admin-expiry-term',
      name: 'AdminExpiryTerm',
      component: AdminExpiryTerm,
    },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound,
    },
  ];
};
