export default Object.freeze({
  DUTY_TTL: '상품정보제공고시',
  CALL_SAVED_INFO: '저장된 상품정보고시 불러오기',
  SELECTED: '선택하신',
  CASE: '건',
  TIP_CHANGE_ALL: '상품에 대해 상품정보제공고시를 일괄 변경합니다.',
  PRODUCT_DUTY_GROUP: '상품군',
  SELECT_PRODUCT_GROUP: '상품군을 선택해주세요',
  ALL_INPUT: '상품상세 참조로 전체입력',
  CALENDAR: '캘린더',
  DIRECT_INPUT: '직접입력',
  INPUT: '을/를 입력해주세요.',
  REGISTER_INFO: '상품정보제공고시 등록',
  NO_REGISTER: '등록안함',
  REGISTER: '등록',
  INPUT_TTL: '제목을 입력해주세요',
  TIP_ABLE: '등록할 경우, 타 상품등록 시 간편하게 사용 가능합니다.',
  SAVE: '저장',
  CANCEL: '취소',
  SAVED_INFO: '저장된 상품정보제공고시',
  PLEASE_USE_USAL: '자주 쓰는 상품정보제공고시를 저장해서 사용하세요(최대 100개 등록가능)',
  SELECT: '선택',
  DELETE: '삭제',
  NO_SAVED: '등록된 상품정보제공고시가 없습니다.',
  DETAIL_REFERENCE: '상품상세 참조',
  NO_SAVED_SURE: '입력중인 내용은 저장되지 않습니다. 상품군을 변경하시겠습니까?',
});
