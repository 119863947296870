


































import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EditForm from '@/components/product/categorization/EditForm.vue';
import TreeContainer from '@/components/product/categorization/tree/TreeContainer.vue';
import { replaceKeyNames } from '@/utils/keyReplace';
import {
  GetDisplayCategoriesTreeRequest,
  PutDisplayCategoriesSyncRequest,
  NCPResponse,
  SyncCategoryTrees,
} from 'ncp-api-supporter';
import { throwBottomNavigation, clearBottomNavigation } from '@/helpers/bottomNav';
import { NestedTreeNode, TreeStoreEvent } from '@/types/tree';
import ExcelController from '@/components/product/categorization/excel/ExcelController.vue';
import { DisplayCategoriesTree } from 'ncp-api-supporter/dist/types/modules/display/displayCategory';
import { getCurrentMallNo } from '@/utils/mall';

const tree = namespace('tree');
const bottomNav = namespace('bottomNav');

@Component({
  components: {
    ExcelController,
    EditForm,
    TreeContainer,
  },
})
export default class CategoryDisplay extends Vue {
  @tree.Action('setTree')
  private readonly setTree: () => void;
  @bottomNav.Getter('getBottomNav')
  private readonly hasBottomNav;
  @tree.Getter('getEvent')
  private readonly getEvent: TreeStoreEvent;

  @Ref()
  treeContainer!: TreeContainer;
  @Ref()
  editForm!: EditForm;

  private getTreeData() {
    return this.treeContainer.getTreeData();
  }

  private treeNodes: NestedTreeNode[] = [];

  @Watch('getEvent.eventCount', { deep: true })
  subscribeEvent() {
    const eventKeyword = this.getEvent.eventName;
    switch (eventKeyword) {
      case 'ADD_NODE': {
        if (!this.hasBottomNav) this.initBottomNavigation();
        break;
      }
      case 'RESET_STORE':
        clearBottomNavigation();
        break;
    }
  }

  mounted() {
    this.fetchCategoriesTree();
  }

  @Watch('$route.query.mallNo')
  private async fetchCategoriesTree() {
    const request: GetDisplayCategoriesTreeRequest = {
      params: { mallNo: getCurrentMallNo(this), hasProductCount: true },
    };
    try {
      const { data }: NCPResponse<DisplayCategoriesTree[]> = await this.$api.getDisplayCategoriesTree(request);
      this.setTreeNodes(data);
      if (data.length) this.initBottomNavigation();
    } catch (error) {
      const FORBIDDEN_ERROR_CODE = 'NCPE0004';
      if (error?.data?.code && error.data.code === FORBIDDEN_ERROR_CODE) this.$router.go(-1);
    }
  }

  private setTreeNodes(data: DisplayCategoriesTree[]) {
    this.treeNodes = replaceKeyNames<NestedTreeNode>(data, { displayCategoryName: 'nodeName', displayOrder: 'index' });

    this.setTree();
  }

  private async onSubmit(): Promise<void> {
    if (!this.editForm.validation()) return;
    await this.updateCategoriesTree();
    this.fetchCategoriesTree();
  }

  private async updateCategoriesTree() {
    const updatedTree = this.getTreeData();

    if (!updatedTree.length) return;
    const requestBody: SyncCategoryTrees[] = replaceKeyNames<SyncCategoryTrees>(updatedTree, {
      nodeName: 'displayCategoryName',
      index: 'displayOrder',
    });

    const request: PutDisplayCategoriesSyncRequest = {
      params: {
        mallNo: getCurrentMallNo(this),
      },
      data: {
        displayCategoryTrees: requestBody,
      },
    };

    try {
      await this.$api.putDisplayCategoriesSync(request);
      alert(this.$t('ALERT_SAVED'));
    } catch (error) {
      console.error(error);
    }
  }

  private initBottomNavigation(): void {
    throwBottomNavigation({
      buttons: [
        {
          key: 'ON_CLICK_SAVE',
          type: 'right',
          color: 'red',
          text: this.$t('SAVE_UPDATE').toString(),
        },
      ],
      onClick: (key: 'ON_CLICK_SAVE'): void => {
        if (key === 'ON_CLICK_SAVE') this.onSubmit();
      },
    });
  }
}
