export default Object.freeze({
  // TODO: 이거 파일이름 수정할거임 gridLayout => gridEdit
  // GRID_LAYER_
  TITLE: '그리드 개인설정',
  CHECK_EXPOSURE: '체크컬럼 노출',
  CHECK_UNEXPOSED: '체크컬럼 미노출',
  ALL_EXPOSURE: '전체 노출',
  ALL_UNEXPOSED: '전체 미노출',
  ORDER: '순서',
  COLUMN_NAME: '컬럼명',
  EXPOSURE_TYPE: '노출여부',
  EXPOSURE: '노출',
  UNEXPOSED: '미노출',
  ALL_ITEM: '전체 그리드 항목',
  DISPLAY_ITEM: '노출 그리드 항목',

  WARN_SETTING: '노출 조회항목이 많은 경우 검색 속도가 느려질 수 있습니다.',
  INFO_SETTING: 'Shift 버튼을 누른 상태에서 선택하면 여러 항목을 동시에 선택할 수 있습니다.',

  ALL_UNEXPOSED_MSG: '그리드 컬럼은 최소 1개이상 노출되어야합니다',
  NOT_SELECTED_MSG: '컬럼을 선택해주세요.',
  SAVE_MSG: '컬럼 개인설정이 저장되었습니다.',

  SORT_BY_DEFAULT: '기본순서',
  SORT_BY_ASC: '가나다순',
  SORT_BY_DESC: '가나다역순',

  ALERT_SAVED: '그리드 개인설정이 저장되었습니다.',
  ALERT_UNKNOWN_COLUMN_DETECT: '컬럼이 추가/변경되어 기존 그리드 개인설정을 업데이트합니다.',
  ALERT_UNSELECT_MOVE_TO_LEFT: '삭제할 항목을 선택해주세요.',
  ALERT_UNSELECT_MOVE_TO_RIGHT: '이동할 항목을 선택해주세요.',
});
