




















import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import { IEventScheduleObject, ISchedule } from 'tui-calendar';
import $ from 'jquery';
import { GetSettlementsMonthlyRequest, OrderFriendsPartnerType, GetSettlementsMonthly } from 'ncp-api-supporter';
import { formatCurrency } from '@/utils/numberFormat';
import { partnerSettlementsQuery, getPeriodTypeName, PeriodType } from '@/const/contents/partner';
import { SearchDetailData } from '@/views/contents/partner/Settlements.vue';
import Calendar from '@/components/common/Calendar.vue';

@Component({
  components: {
    Calendar,
  },
})
export default class ItemCalendar extends Vue {
  @PropSync('searchDetailData', { required: true })
  private searchDetailDataSync: SearchDetailData;
  @Prop({ required: true })
  private readonly searchDetail: (page: number, size?: number) => void;

  private schedules: ISchedule[] = [];

  private mallNo = 0;
  private countryCode = '';
  private partnerType = '';
  private year: number = new Date().getFullYear();
  private month: number = new Date().getMonth() + 1;

  private async searchSummary() {
    const request: GetSettlementsMonthlyRequest = {
      params: {
        mallNo: this.mallNo,
        year: this.year,
        month: this.month,
      },
    };
    if (this.partnerType !== '') {
      request.params.partnerType = this.partnerType as OrderFriendsPartnerType;
    }

    const { data } = await this.$api.getSettlementsMonthly(request);
    this.setSchedules(data);
  }

  private setSchedules(data: GetSettlementsMonthly[]) {
    const schedules: ISchedule[] = [];
    const domestic = data.find((item: GetSettlementsMonthly) => item.countryCode === this.countryCode);
    const overseas = data.find((item: GetSettlementsMonthly) => item.countryCode !== this.countryCode);
    if (domestic) {
      schedules.push(...this.createSettlementData(domestic));
    }
    if (overseas) {
      schedules.push(...this.createSettlementData(overseas));
    }
    this.schedules = schedules;
  }

  private getTagColor = (state: string) => {
    return state === 'CONFIRM' ? '#4B96FF' : '#FF5D15';
  };

  private createSettlementData(summary: GetSettlementsMonthly) {
    const settlementInfo = summary.settlementsInfo;
    const result = [];
    const periodData = {
      title: `${getPeriodTypeName(settlementInfo.periodType as PeriodType)}: ${settlementInfo.settlementsPeriod.replace(
        /[{}]+/g, //문자열 내 중괄호 없애주는 정규식
        '',
      )}`,
      category: 'allday',
      start: settlementInfo.settlementsFormatDate,
      end: settlementInfo.settlementsFormatDate,
      isReadOnly: true,
      color: 'white',
      bgColor: this.getTagColor(settlementInfo.settlementsState),
      borderColor: this.getTagColor(settlementInfo.settlementsState),
      customStyle: 'cursor: default;',
    };

    const amtData = {
      title: `${this.$t('SETTLEMENT.LIST.ESTIMATED_AMT')}: ${formatCurrency(summary.settlementAmt)}${this.$t('WON')}`,
      category: 'allday',
      start: settlementInfo.settlementsFormatDate,
      end: settlementInfo.settlementsFormatDate,
      isReadOnly: true,
      color: 'white',
      bgColor: this.getTagColor(settlementInfo.settlementsState),
      raw: summary,
      customStyle: 'text-decoration: underline;',
      borderColor: this.getTagColor(settlementInfo.settlementsState),
    };
    result.push(periodData);
    result.push(amtData);
    return result;
  }

  private clickSchedule(event: IEventScheduleObject) {
    const summary = event.schedule.raw;
    if (!summary) {
      return;
    }
    const settlementsInfo = summary.settlementsInfo;
    // 색상 초기화.
    $('.tui-full-calendar-weekday-schedule').removeClass('custom-select');
    $(event.event.target)
      .parent()
      .addClass('custom-select');
    $(event.event.target)
      .parent()
      .addClass(event.schedule['bgColor'] === '#4B96FF' ? 'confirm' : 'pending');

    this.searchDetailDataSync = {
      mallNo: Number(summary.mallNo),
      year: settlementsInfo['year'],
      month: settlementsInfo['month'],
      partnerType: summary.countryCode === 'KR' ? 'DOMESTIC' : 'OVERSEAS',
      settlementDate: settlementsInfo['settlementsFormatDate'],
    };
    this.searchDetail(1);
  }

  @Watch('$route.query')
  private async setSummaryOption() {
    this.mallNo = Number(this.params.mallNo);
    this.year = Number(this.params.year);
    this.month = Number(this.params.month);
    this.partnerType = this.params.partnerType;
    await this.searchSummary();
  }

  private get params() {
    return { ...partnerSettlementsQuery(), ...this.$route.query };
  }

  private async mounted() {
    await this.setSummaryOption();
  }
}
