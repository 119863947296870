


















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GetDeliveryTemplate, InspectionProductInfo, OriginProductInfo } from 'ncp-api-supporter';
import { GetDeliveryTemplateDeliveryFee } from 'ncp-api-supporter/dist/types/modules/delivery/deliveryTemplate';
import TextInput from '@/components/common/input/TextInput.vue';
import { GetInspectionsView, Item, Reason } from 'ncp-api-supporter/dist/types/modules/product/product';
import { APPLY_STATUS } from '@/const/common';
import { formatCurrency } from '@/utils/numberFormat';
import { getCheckKeysBy } from '@/const/contents/product';

@Component({
  components: {
    TextInput,
  },
})
export default class DeliveryTable extends Vue {
  @Prop({ required: true }) private readonly inspectionView?: GetInspectionsView;
  @Prop({ required: true }) private readonly isApprovalReady?: boolean;
  @Prop({ required: true }) private readonly isAfterApprovalReady?: boolean;
  private readonly originProductInfo?: OriginProductInfo = this.inspectionView?.originProductInfo;
  private readonly inspectionProductInfo?: InspectionProductInfo = this.inspectionView?.inspectionProductInfo;
  private readonly modifiedParam: string[] = this.inspectionView?.modifiedParam || [];
  private readonly inspectionStatus: string = this.inspectionView?.inspectionStatus || '';

  private deliveryTemplates: GetDeliveryTemplate[] = []; //배송비 유형

  private deliveryInfo = {
    deliveryCombinationYn: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.DELIVERY_COMBINATION_YN'),
      ref: 'deliveryCombinationYn',
    },
    deliveryInternationalYn: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.DELIVERY_INTERNATIONAL_YN'),
      ref: 'deliveryInternationalYn',
    },
    deliveryTypeAndCompany: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.DELIVERY_TYPE'),
      ref: 'deliveryTypeAndCompany',
    },
    deliveryConditionType: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.DELIVERY_CONDITION_TYPE'),
      ref: 'deliveryConditionType',
    },
    releaseWarehouse: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.RELEASE_WAREHOUSE'),
      ref: 'releaseWarehouse',
    },
    returnWarehouse: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.RETURN_WAREHOUSE'),
      ref: 'returnWarehouse',
    },
    returnDeliveryAmt: {
      check: false,
      reason: '',
      name: this.$t('PRODUCT.COMMON.RETURN_DELIVERY_AMT'),
      ref: 'returnDeliveryAmt',
    },
  };

  get isExposureDeliveryInfo() {
    return (
      this.modifiedParam.includes('deliveryTemplateNo') ||
      this.modifiedParam.some(param => this.deliveryInfo?.hasOwnProperty(param)) ||
      this.inspectionStatus === APPLY_STATUS.APPROVAL_READY
    );
  }

  //노출항목
  private isExposureItem(modifiedItem: string): boolean {
    //승인대기
    if (this.inspectionStatus === APPLY_STATUS.APPROVAL_READY) return true;
    //수정후 승인대기
    if (this.inspectionStatus === APPLY_STATUS.AFTER_APPROVAL_READY) {
      return this.modifiedParam.includes(modifiedItem);
    }
    return false;
  }

  @Watch('deliveryInfo', { deep: true })
  private getReasons() {
    const checked = getCheckKeysBy(this.deliveryInfo);

    const reasons: Reason[] = [];
    const deliveryReasons: Item[] = checked.map((key: string) => ({
      item: this.deliveryInfo[key].name.toString(),
      detail: {
        data: (this.$refs[this.deliveryInfo[key].ref] as Element).outerHTML,
        comment: this.deliveryInfo[key].reason,
      },
    }));
    reasons.push({ section: 'DELIVERY_INFO', items: deliveryReasons });
    this.$emit('reasons', reasons);
  }

  //배송비 유형
  private getDeliveryFeeLabel(deliveryFee: GetDeliveryTemplateDeliveryFee): string {
    if (!deliveryFee) return '';
    //유료(고정배송비)
    if (deliveryFee.deliveryConditionType === 'FIXED_FEE') {
      return `${this.$t('PRODUCT.COMMON.FIXED_FEE')} ${deliveryFee.deliveryAmt}`;
    }
    //조건부 무료 : 주문금액 XXX원 이상 배송비 무료 미만 시 XXX원 배송비
    if (deliveryFee.deliveryConditionType === 'CONDITIONAL') {
      return `${this.$t('PRODUCT.COMMON.ORDER_AMT')} ${deliveryFee.criteria}${this.$t('PRODUCT.COMMON.CONDITION')} ${
        deliveryFee.deliveryAmt
      }${this.$t('WON')}${this.$t('PRODUCT.COMMON.FIXED_FEE')} `;
    }
    //수량 비례 : {설정한 수량}개당 {설정한 금액}
    if (deliveryFee.deliveryConditionType === 'QUANTITY_PROPOSITIONAL_FEE') {
      return `${deliveryFee.perOrderCnt}${this.$t('PRODUCT.COMMON.EACH')} ${deliveryFee.deliveryAmt}${this.$t('WON')}`;
    }
    //무료
    if (deliveryFee.deliveryConditionType === 'FREE') {
      return this.$t('PRODUCT.COMMON.FREE') as string;
    }
    return '';
  }

  //반품/교환배송비
  private getReturnDeliveryAmt(returnDeliveryAmt: number): string {
    return `${this.$t('PRODUCT.COMMON.ONEWAY_STANDARD')} ${formatCurrency(returnDeliveryAmt)}${this.$t('WON')}`;
  }

  //배송비 템플릿 검색
  private async fetchDeliveryTemplates(originProductInfo?: OriginProductInfo) {
    const { data } = await this.$api.getDeliveryTemplates({
      params: {
        partnerNo:
          originProductInfo?.shippingAreaType === 'MALL_SHIPPING_AREA'
            ? originProductInfo?.shippingAreaPartnerNo
            : originProductInfo?.partnerNo || 0,
      },
    });
    this.deliveryTemplates = data;
  }

  //변경전 배송비 유형
  private get originDeliveryTemplate(): GetDeliveryTemplate | undefined {
    return this.deliveryTemplates.find(
      deliveryTemplate => deliveryTemplate.templateNo === this.originProductInfo?.deliveryTemplateNo,
    );
  }

  //변경후 배송비 유형
  private get deliveryTemplate(): GetDeliveryTemplate | undefined {
    return this.deliveryTemplates.find(
      deliveryTemplate => deliveryTemplate.templateNo === this.inspectionProductInfo?.deliveryTemplateNo,
    );
  }

  created() {
    this.fetchDeliveryTemplates(this.originProductInfo); //배송비유형
  }
}
