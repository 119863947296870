


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import DateRangePicker from '@/components/common/DateRangePicker.vue';
import Grid from '@/components/common/grid/Main.vue';
import { productGridProp, dateGridProp } from '@/views/contents/marketing/googleAd/report';
import { CampaingsSeparationType, GetCampaignsReportsDataTotalReport } from 'ncp-api-supporter';
import { ReportSegment } from '@/types/marketing';

@Component({
  components: {
    Grid,
    DateRangePicker,
  },
})
export default class ReportGridForm extends Vue {
  @Prop({ required: false, default: false })
  private currentGridType: CampaingsSeparationType = 'DATE';
  @Prop({ required: false, default: false })
  private totalReportData?: GetCampaignsReportsDataTotalReport | null = null;
  @Prop({ required: false, default: false })
  private reportSegment: ReportSegment[] = [];
  private readonly dateGridProp = dateGridProp();
  private readonly productGridProp = productGridProp();

  private getGridProp(propName: 'columns' | 'displayOptions' | 'options' | 'header') {
    return this.currentGridType === 'DATE' ? this.dateGridProp[propName] : this.productGridProp[propName];
  }
}
