
































import { Component, Prop, Vue } from 'vue-property-decorator';
import TextInput from '@/components/common/input/TextInput.vue';
import EmailInput from '@/components/common/input/EmailInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import { AdminsService, PostDepartmentsRequest, PostJobDutyRequest } from 'ncp-api-supporter';
import AdminForm from '@/views/contents/configuration/management/adminChild/AdminForm.vue';
import { unescapeHtml } from '@/utils/common';
import { Getter, namespace } from 'vuex-class';
import { Admin } from '@/types';
import { PutAdminsServiceByAdminNoRequest, AdminRole } from 'ncp-api-supporter/dist/types';
const { VUE_APP_CONSOLIDATED_AUTH } = process.env;

const adminStore = namespace('admin');

@Component({ components: { TextInput, EmailInput, SelectBox, AdminForm } })
export default class EditMyAccount extends Vue {
  @Prop() private data;
  @Prop() private onPositiveClick!: Function;
  @Prop() private onNegativeClick!: Function;

  @Getter('admin/getAdmin') private me: Admin;

  @adminStore.Getter('commerceAccount')
  private readonly commerceAccount: boolean;

  @adminStore.Action('setSelectedAdmin')
  private setSelectedAdmin: (selectedAdminRole: AdminRole) => void;

  private readonly VUE_APP_CONSOLIDATED_AUTH = VUE_APP_CONSOLIDATED_AUTH;

  private adminInfo: AdminsService = {
    adminName: '',
    authorityGroup: {
      no: 0,
      name: '',
    },
    jobDuty: {
      no: 0,
      name: '',
    },
    contact: {
      mobileNo: '',
      phoneNo: '',
      email: '',
    },
    adminId: '',
    adminRole: null,
    otp: {
      used: false,
    },
    department: {
      no: 0,
      name: '',
    },
    jobPositionName: '',
    externalAccessEnabled: false,
    commerceAccount: false,
  };

  private smsAuthKey = '';

  private get isCommerceAccountMaster(): boolean {
    return this.commerceAccount && this.me.role === 'MASTER';
  }

  created() {
    this.getAdminsServiceByAdminNo();
  }

  /**
   * get admin info by admin no
   */
  private getAdminsServiceByAdminNo(): Promise<void> {
    return this.$api.getAdminsServiceByAdminNo({ params: { adminNo: this.me.no + '' } }).then(response => {
      if (response && response.status === 200) {
        if (!response.data.authorityGroup) {
          response.data.authorityGroup = this.adminInfo.authorityGroup;
        }
        if (!response.data.department) {
          response.data.department = this.adminInfo.department;
        }
        if (!response.data.jobDuty) {
          response.data.jobDuty = this.adminInfo.jobDuty;
        }
        if (!response.data.contact) {
          response.data.contact = this.adminInfo.contact;
        }

        response.data.jobPositionName = unescapeHtml(response.data.jobPositionName);

        this.adminInfo = response.data;
        this.setSelectedAdmin(response.data.adminRole);
      }
    });
  }

  private putAdminsServiceByAdminNo(): Promise<boolean> {
    const request: PutAdminsServiceByAdminNoRequest = {
      params: {
        adminNo: this.me.no + '',
      },
      data: {
        certKey: this.smsAuthKey,
        phone: this.adminInfo.contact.phoneNo,
        authorityGroupNo: this.adminInfo.authorityGroup.no,
        mobile: this.adminInfo.contact.mobileNo,
        departmentNo: this.adminInfo.department.no,
        jobPositionName: this.adminInfo.jobPositionName,
        email: this.adminInfo.contact.email,
        externalAccessEnabled: this.adminInfo.externalAccessEnabled,
        jobDutyNo: this.adminInfo.jobDuty.no,
      },
    };

    if (!this.adminInfo.department.no) {
      delete request.data.departmentNo;
    }
    if (!this.adminInfo.jobDuty.no) {
      delete request.data.jobDutyNo;
    }
    if (!this.smsAuthKey) {
      delete request.data.certKey;
    }

    return this.$api
      .putAdminsServiceByAdminNo(request)
      .then(response => {
        if (response && response.status === 204) {
          return true;
        }

        return false;
      })
      .catch(() => false);
  }

  private async checkCreatedInfo() {
    if (this.adminInfo.department.name && !this.adminInfo.department.no) {
      await this.createDepartment();
    }
    if (this.adminInfo.jobDuty.name && !this.adminInfo.jobDuty.no) {
      await this.createJobDuty();
    }
  }

  private async createDepartment() {
    const request: PostDepartmentsRequest = {
      data: {
        departmentName: this.adminInfo.department.name,
      },
    };
    try {
      const { data } = await this.$api.postDepartments(request);
      this.adminInfo.department = {
        name: data.name,
        no: data.no,
      };
    } catch (e) {
      console.error(e);
    }
  }

  private async createJobDuty() {
    const request: PostJobDutyRequest = {
      data: {
        jobDutyName: this.adminInfo.jobDuty.name,
      },
    };
    try {
      const { data } = await this.$api.postJobDuties(request);
      this.adminInfo.jobDuty = {
        name: data.name,
        no: data.no,
      };
    } catch (e) {
      console.error(e);
    }
  }

  private async save(): Promise<void> {
    if (!(this.$refs.adminForm as AdminForm).validate()) {
      return;
    }

    await this.checkCreatedInfo();
    this.putAdminsServiceByAdminNo().then(success => {
      if (success) {
        alert(this.$t('SAVE_SUCCESS'));
        this.onPositiveClick();
      }
    });
  }
}
