


























































































import { Component } from 'vue-property-decorator';
import DateCalendar from '@/components/common/DateCalendar.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import WindowPopupMain from '@/views/popups/Main.vue';
import { PopupClose, PopupResult, throwPopup } from '@/helpers/popup';
import { PutReturnsNoReturnAddressRequest } from 'ncp-api-supporter';

@Component({
  components: { SelectBox, DateCalendar, TextInput },
})
export default class ReturnInfoEditPopup extends WindowPopupMain {
  private returnInfo = {
    name: '',
    contact1: '',
    contact2: '',
    zipCd: '',
    note: '',
    address: '',
    detailAddress: '',
    jibunAddress: '',
  };

  created() {
    this.initReturnInfo();
  }

  initReturnInfo() {
    this.returnInfo = { ...this.data.returnInfo };
  }

  private onClickModifyBtn() {
    if (!this.validation()) {
      return;
    }

    if (!this.isChangeAddress()) {
      alert(this.$t('변경된 사항이 없습니다.'));
      return;
    }

    const { name, contact1, contact2, zipCd, note, address, detailAddress, jibunAddress } = this.returnInfo;

    const request: PutReturnsNoReturnAddressRequest = {
      pathParams: {
        no: this.data.claimNo,
      },
      data: {
        name,
        contact1,
        contact2,
        zipCd,
        note,
        address,
        detailAddress,
        jibunAddress,
      },
    };

    this.$api.putReturnsNoReturnAddress(request).then(() => {
      alert(this.$t('수정되었습니다.'));
      this.onPositiveClick();
    });
  }

  private validation() {
    if (this.returnInfo.name.trim() === '') {
      alert(this.$t('수령자명을 입력하세요'));
      return false;
    }

    if (this.returnInfo.contact1.trim() === '') {
      alert(this.$t('휴대전화를 입력하세요'));
      return false;
    }

    const hpRegExp = /^\d{3}-?\d{3,4}-?\d{4}$/;
    if (!hpRegExp.test(this.returnInfo.contact1)) {
      alert(this.$t('잘못된 휴대폰 번호입니다. 휴대폰 번호를 정확하게 입력하세요.'));
      return false;
    }

    return true;
  }

  private isChangeAddress(): boolean {
    const originalReturnInfo = this.data;

    const isChanged = Object.keys(originalReturnInfo).some(
      returnInfoKey => this[returnInfoKey] !== this.returnInfo[returnInfoKey],
    );

    return isChanged;
  }

  private onSearchAddress() {
    throwPopup({
      name: 'SearchAddress',
      data: { keyword: '' },
    }).then((result: PopupResult) => {
      if (result && result.state === PopupClose.CONFIRM) {
        const { zipCode, roadAddress, roadAddressExtra, jibunAddress } = result.data;

        this.returnInfo.zipCd = zipCode;
        this.returnInfo.address = roadAddress;
        this.returnInfo.detailAddress = roadAddressExtra;
        this.returnInfo.jibunAddress = jibunAddress;
      }
    });
  }
}
