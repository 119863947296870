











import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { RadioBoxGroupOption } from '@/helpers/type';
import { YorN } from 'ncp-api-supporter';
import RadioGroup from '@/components/common/RadioGroup.vue';
@Component({
  components: {
    RadioGroup,
  },
})
export default class AccumulationUseInfo extends Vue {
  @PropSync('accumulationUseYn')
  private accumulationUseYnSync!: YorN;

  @Prop({ required: true })
  private readonly disabled: boolean;

  private radioOption: RadioBoxGroupOption<YorN> = {
    name: 'accumulationUseYn',
    data: [
      { id: 'accumulationUseY', value: 'Y', display: 'PRODUCT.ADD.ACCUMULATION_USE_Y' },
      { id: 'accumulationUseN', value: 'N', display: 'PRODUCT.ADD.ACCUMULATION_USE_N' },
    ],
  };
}
