



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClaimOption } from 'ncp-api-supporter';
import { ClaimClassType, ClaimType, ResponsibleObjectType } from '@/types/claim';
import { isCancelExchange, isReturnClaim } from '@/components/popup/claim/claimConditions';
import { immediateDiscountedAmt } from '@/components/popup/claim/claimFormula';
import TdClaimProductName from '@/components/popup/claim/TdClaimProductName.vue';

@Component({
  components: { TdClaimProductName },
  computed: {
    isCancelExchange,
    isReturnClaim,
    showHoldDelivery(): boolean {
      return this.isCancelExchange;
    },
  },
})
export default class ClaimProduct extends Vue {
  @Prop({ required: true })
  private readonly claimType: ClaimType;

  @Prop({ required: false, default: null })
  private readonly claimClassType: null | ClaimClassType;

  @Prop({ required: false, default: [] })
  private readonly claimedOptions: ClaimOption[];

  @Prop({ required: false, default: null })
  private responsibleObjectType!: ResponsibleObjectType;

  private immediateDiscountedAmt = immediateDiscountedAmt;

  private isFirstItem(index: number) {
    return index === 0;
  }
}
