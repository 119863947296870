




























import { Vue, Component } from 'vue-property-decorator';
import Logo from '@/components/navigation/header/Logo.vue';
@Component({
  components: {
    Logo,
  },
})
export default class NotFound extends Vue {
  private goBack() {
    this.$router.go(-1);
  }
  private goToMain() {
    this.$router.replace('/');
  }
}
