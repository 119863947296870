














import { Component, Prop, Vue } from 'vue-property-decorator';
import { Sticker } from 'ncp-api-supporter/dist';

@Component
export default class Stickers extends Vue {
  @Prop({ required: true }) private readonly stickers?: Sticker[];

  private addComma(stickersLength: number, index: number): string {
    return stickersLength - 1 === index ? '' : ', ';
  }
}
