import axios, { AxiosResponse } from 'axios';
import { stringify } from 'qs';
import { RemoteCode } from '@/api/godo/type';
import { throwLoadingSpinner } from '@/helpers/loading';

const { VUE_APP_GODO_API_URL } = process.env;

const axiosCreator = (baseURL: string) => {
  const timeout = 30000;
  return axios.create({
    baseURL,
    timeout,
  });
};

const api = axiosCreator(VUE_APP_GODO_API_URL);

class ApiLoading {
  private count = 0;

  public show() {
    this.count++;
    throwLoadingSpinner(true);
  }

  public hide() {
    this.count--;
    throwLoadingSpinner(false);
  }
}

const apiLoading = new ApiLoading();

api.interceptors.request.use(
  config => {
    apiLoading.show();
    return config;
  },
  error => {
    apiLoading.hide();
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    apiLoading.hide();
    return response;
  },
  error => {
    apiLoading.hide();
    return Promise.reject(error);
  },
);

export const fetch = {
  iframe({ code, sno, ecCode }: { code: RemoteCode; sno: number; ecCode: string }): Promise<AxiosResponse<Document>> {
    return api.post(
      '/gate/remoteGodoPage.php',
      stringify({
        code,
        detail: { sno, ecCode },
        apiMode: 'remote',
      }),
      { responseType: 'document' },
    );
  },
};
