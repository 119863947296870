






















































































































import { Component, Watch } from 'vue-property-decorator';
import { formatCurrency, getCurrencyWithUnit } from '@/utils/numberFormat';

import WindowPopupMainVue from '@/views/popups/Main.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import Grid from '@/components/common/grid/Main.vue';

import { CouponIssuanceUseDefaultQuery, getCouponIssuanceListGridOption } from '@/const/contents/promotion';
import { throwExternalWindowPopup, throwPopup, PopupClose } from '@/helpers/popup';
import { sendQueryString } from '@/utils/query';
import { GetCouponsIssueRequset } from 'ncp-api-supporter';

@Component({
  components: {
    ToolTip,
    Grid,
  },
})
export default class CouponIssuanceUsePopup extends WindowPopupMainVue {
  private initOver = false;
  private showInfo = null;

  // grid
  private searchParams = null;
  private couponIssues = [];
  private totalCount = 0;
  private gridProps = getCouponIssuanceListGridOption();
  private async onItemClicked({ columnName, rowKey, targetType, nativeEvent }) {
    if (targetType === 'cell') {
      switch (columnName) {
        case 'memberNo':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'A') {
            const clickIssuance = this.couponIssues.find(issues => issues.couponIssueNo === rowKey);
            if (clickIssuance) {
              throwExternalWindowPopup(`/pro/crm/${clickIssuance.memberNo}`, 'xlg');
            }
          }
          break;
        case 'couponUseStatusType':
          if (nativeEvent && nativeEvent.target && nativeEvent.target.tagName === 'A') {
            const result = await throwPopup({
              name: 'WithdrawIssue',
              data: { couponIssueNo: rowKey },
            });
            if (result.state === PopupClose.CONFIRM) {
              this.setSummary();
              this.setQueryAndSearch();
            }
          }
          break;
      }
    }
  }

  @Watch('$route.query')
  private onQueryStringChanged() {
    this.setQueryAndSearch();
  }

  private filterIssue(type) {
    const searchQuery = CouponIssuanceUseDefaultQuery(this.popupId, this.data.couponNo);
    const urlQuery = this.$route.query;
    const size = (urlQuery && urlQuery.size) ?? 30;
    switch (type) {
      case 'NORMAL':
      case 'ADMIN':
        searchQuery.adminIssued = type === 'ADMIN';
        break;
      case 'WITHDRAW':
      case 'USED':
        searchQuery.couponUseStatusType = type;
        break;
    }
    sendQueryString(this, { ...searchQuery, size });
  }

  private setQueryAndSearch() {
    const urlQuery = this.$route.query;
    if (!urlQuery) {
      return;
    }
    const request: GetCouponsIssueRequset = {
      params: {
        couponNos: this.data.couponNo,
        page: (urlQuery.page ?? 1) as number,
        size: (urlQuery.size ?? 30) as number,
      },
    };

    if (urlQuery.adminIssued) {
      request.params.adminIssued = urlQuery.adminIssued.toString() === 'true';
    }
    if (urlQuery.couponUseStatusType) {
      request.params.couponUseStatusType = urlQuery.couponUseStatusType as string;
    }

    this.$api.getCouponsIssue(request).then(response => {
      if (response && response.status === 200) {
        this.couponIssues = response.data.contents;
        this.totalCount = response.data.totalCount;

        this.searchParams = request.params;
      }
    });
  }
  private discountAmtLabel(): string {
    if (!this.data?.discount) return getCurrencyWithUnit(0);

    const { discount } = this.data;
    const { discountPercent, maxDiscountAmt, discountAmt } = discount;

    if (discount.fixed) {
      return getCurrencyWithUnit(discountAmt);
    }
    return `${discountPercent}% (${this.$t('MAX')} ${getCurrencyWithUnit(maxDiscountAmt)})`;
  }
  private async setSummary() {
    const couponSummary = await this.$api.getCouponsCouponNoSummary({ pathParams: { couponNo: this.data.couponNo } });
    this.showInfo = {
      mallName: couponSummary.data.mallName,
      couponName: couponSummary.data.couponName,
      couponNo: couponSummary.data.couponNo,
      showDiscountAmt: this.discountAmtLabel(),
      totalIssueCnt: couponSummary.data.couponSummary.totalIssueCnt,
      totalNormalIssueCnt: couponSummary.data.couponSummary.totalNormalIssueCnt,
      totalIssueCntByAdmin: couponSummary.data.couponSummary.totalIssueCntByAdmin,
      withdrawCount: couponSummary.data.withdrawCount,
      totalUseCnt: couponSummary.data.couponSummary.totalUseCnt,
      showTotalDiscountAmt: formatCurrency(couponSummary.data.totalCouponDiscountAmt),
    };
  }

  async created() {
    if (this.popupId && this.data && this.data.couponNo) {
      await this.setSummary();
      this.initOver = true;

      const searchQuery = CouponIssuanceUseDefaultQuery(this.popupId, this.data.couponNo);
      switch (this.data.type) {
        case 'issuance':
        case 'totalIssueCntByAdmin':
          searchQuery.adminIssued = this.data.type === 'totalIssueCntByAdmin';
          break;
        case 'totalUseCnt':
          searchQuery.couponUseStatusType = 'USED';
          break;
      }
      const urlQuery = this.$route.query;
      const page = (urlQuery && urlQuery.page) ?? undefined;
      const size = (urlQuery && urlQuery.size) ?? undefined;
      sendQueryString(this, { ...searchQuery, page, size }, true, true);
    }
  }
}
