// 상품 리스트 & 대량 상품 등록에서 공통으로 사용하는 복사 관련 로직을 정의합니다.
import { OptColumn } from '@/types';
import { Row } from '@/types/tui-grid';
import { throwWindowPopup } from '@/helpers/popup';
import { getAnchorHTML } from '@/utils/grid/formatterUtils';
import { i18n } from '@/main';

export function canCopiedProduct(row) {
  const isSlave = row.parentMallProductNo > 0;

  return row.applyStatusType !== 'REGISTRATION_READY' && row.optionType !== 'MAPPING' && !isSlave;
}

export const getCopyGridColumn = (): OptColumn => {
  return {
    header: 'COPY',
    name: 'copy',
    align: 'center',
    minWidth: 50,
    formatter: ({ row }) => {
      const isSlave = row.parentMallProductNo > 0;
      const canCopied = row.applyStatusType !== 'REGISTRATION_READY' && row.optionType !== 'MAPPING' && !isSlave;
      return canCopied ? getAnchorHTML(i18n.t('COPY')) : '-';
    },
  };
};

export const openCopyProductPopup = ({
  applyStatusType,
  saleStatusType,
  optionType,
  mallProductNo,
  mallNo,
  isItem,
}: Row) => {
  const canCopied = applyStatusType !== 'REGISTRATION_READY' && optionType !== 'MAPPING';
  if (!canCopied) return; // onItemClicked 는 해당 셀이 클릭 가능한 상태인지, 불가능한 상태인지 특정할 수 없어서 여기서 한번 더 거른다.

  throwWindowPopup('CopyProduct', { mallProductNo, mallNo, isItem, saleStatusType }, 'md', () => null);
};
