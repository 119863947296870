

































































import { Component, Vue } from 'vue-property-decorator';
import EmailInput from '@/components/common/input/EmailInput.vue';
import TextInput from '@/components/common/input/TextInput.vue';

@Component({ components: { TextInput, EmailInput } })
export default class AuthPasswordChangePopup extends Vue {
  private adminId = '';
  private email = '';

  private postAdminsPasswordEmail(): Promise<boolean> {
    return this.$api
      .postAdminsPasswordEmail({
        data: {
          adminName: '',
          adminType: 'SERVICE',
          adminId: this.adminId,
          callbackUrl: `${window.location.href}/change-password`,
          adminEmail: this.email,
        },
      })
      .then(response => response && response.status === 200)
      .catch(error => {
        if (error.data.code === 'A0004') return false;
        const message = error?.data?.message;

        message && alert(message);
        return false;
      });
  }

  private openPasswordChangeGuidePopup(): void {
    this.close();
    this.$emit('openPopup', 'AuthPasswordChangeGuidePopup');
  }

  private sendPasswordChangeEmail(): void {
    if (!this.adminId) {
      (this.$refs.adminId as HTMLElement).focus();
      alert(`${this.$t('ADMIN_ID')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    const tempEmail = {
      id: '',
      domain: '',
    };
    if (this.email) {
      [tempEmail.id, tempEmail.domain] = this.email.split('@');
    }

    if (!tempEmail.id || !tempEmail.domain) {
      (this.$refs.email as EmailInput).focus();
      alert(`${this.$t('AUTH.EMAIL')}${this.$t('CHECK_INPUT_FILED')}`);
      return;
    }

    this.postAdminsPasswordEmail().then(success => {
      if (success) {
        alert(this.$t('AUTH.ALERT_SEND_EMAIL_SUCCESS'));
        this.close();
      }
    });
  }

  private close(): void {
    this.adminId = '';
    this.email = '';

    this.$emit('closePopup');
  }
}
