


















import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Tree from '@/components/common/tree/Tree.vue';
import TreeController from '@/components/product/categorization/tree/TreeController.vue';
import nodeElements from '@/components/product/categorization/tree/nodeElements';
import { NestedTreeNode, TreeNode, TreeOptions, ValidationHook } from '@/types/tree';
import { isValidate } from '@/utils/validate';
const tree = namespace('tree');

@Component({
  components: {
    Tree,
    TreeController,
  },
})
export default class TreeContainer extends Vue {
  @tree.Getter('getNode')
  private readonly getNode: TreeNode;

  @Prop({ required: true })
  private readonly treeNodes: NestedTreeNode[];

  @Ref() tree!: Tree;

  private readonly nodeElements = nodeElements;

  private readonly options: TreeOptions = {
    internalNodeName: this.$t('CATEGORY').toString(),
    leafNodeName: this.$t('CATEGORY').toString(),
    editable: true,
    draggable: true,
    defaultSelection: true,
    parentDataTrack: true,
    defaultNodeData: {
      children: [],
      displayYn: 'Y',
    },
    customKeywords: {
      nodeName: this.$t('PRODUCT.CATEGORIZATION.NEW').toString(),
      nodeLabel: this.$t('PRODUCT.CATEGORIZATION.NAME').toString(),
    },
  };

  private readonly validationHook: ValidationHook = {
    moveNode: this.productCountedNodeMoveValidation,
    removeNode: this.productCountedNodeRemoveValidation,
  };

  private productCountedNodeMoveValidation(newParentNodeData: TreeNode): boolean {
    return isValidate(!newParentNodeData?.productCount, this.$t('PRODUCT.CATEGORIZATION.ALERT_TREE_BEFORE_MOVE'));
  }

  private productCountedNodeRemoveValidation(): boolean {
    return isValidate(!this.getNode?.productCount, this.$t('PRODUCT.CATEGORIZATION.ALERT_DELETE_FAIL'));
  }

  public getTreeData() {
    return this.tree.getTreeData();
  }
}
