











import { Vue, Component } from 'vue-property-decorator';
import { focusTabName, orderListTypes } from '@/const/order.ts';
import SearchForm from '@/components/shipping/order/SearchForm.vue';

@Component({
  components: {
    SearchForm,
  },
})
export default class OrderHoldDelivery extends Vue {
  private orderListTypes = orderListTypes.HOLD_DELIVERY;
  private orderSearchTypes = focusTabName.ORDER_TAB;
}
