import { addMonth, getToday } from '@/utils/dateFormat';
import { ProductsSearchPostRequest } from 'ncp-api-supporter';

export const typedParameters: ProductsSearchPostRequest['data'] = {
  mallNos: [0],
  keywordInfo: {
    type: null,
    keywords: [''],
  },
  periodInfo: {
    type: null,
    period: {
      startYmdt: '',
      endYmdt: '',
    },
  },
  applyStatusType: null,
  saleStatus: {
    types: ['END_SALE', 'ON_PRE_SALE', 'ON_SALE', 'WAITING_SALE'],
    isAll: false,
  },
  saleSettingStatus: {
    types: [],
    isAll: false,
  },
  categoryCondition: {
    type: null,
    depth: 0,
    no: 0,
  },
  stockRange: {
    type: null,
    stockCnt: 0,
    minStockCnt: 0,
    maxStockCnt: 0,
  },
  deliverable: null,
  deliveryFeeType: null,
  allowsShippingTogether: 'ALL',
  allowsFrontDisplay: null,
  platformType: null,
  memberGradeNo: 0,
  brandNo: 0,
  adminNo: 0,
  onlyMappingProduct: '',
  saleMethodType: null,
  sort: {
    sortDirection: 'ASC',
    sortCriterion: 'UPDATE_DATE_TIME',
  },
  page: 0,
  size: 0,
  partnerNo: null,
  allowsPromotion: null,
  canApplyCoupon: null,
  canApplyAdditionalDiscount: null,
  hasAdditionalDiscount: null,
  commission: {
    type: null,
  },
  shippingAreaType: null,
  onlyParentProduct: null,
  customPropertyValueNos: null,
  needsAdditionalDiscountInfo: 'N',
};

export const getDefaultParams = (): ProductsSearchPostRequest['data'] => {
  //  ProductsSearchPostResponse
  return {
    mallNos: [],
    keywordInfo: {
      type: 'PRODUCT_NAME',
      keywords: [],
    },
    periodInfo: {
      type: 'REGISTER_DATE',
      period: {
        startYmdt: addMonth(new Date(), -3),
        endYmdt: getToday(),
      },
    },
    applyStatusType: 'ALL',
    saleStatus: {
      types: [],
      isAll: true,
    },
    saleSettingStatus: {
      types: [],
      isAll: true,
    },
    categoryCondition: {
      type: 'DISPLAY',
      depth: null,
      no: null,
    },
    stockRange: {
      type: 'ALL',
      stockCnt: null,
      minStockCnt: null,
      maxStockCnt: null,
    },
    deliverable: 'ALL',
    deliveryFeeType: 'ALL',
    allowsShippingTogether: 'ALL',
    allowsFrontDisplay: 'ALL',
    platformType: 'ALL',
    memberGradeNo: null,
    brandNo: null,
    adminNo: null,
    onlyMappingProduct: 'ALL',
    saleMethodType: 'ALL',
    sort: {
      sortDirection: 'DESC',
      sortCriterion: 'MALL_PRODUCT_NO',
    },
    page: 1,
    size: 30,
    partnerNo: null,
    allowsPromotion: null,
    canApplyCoupon: null,
    canApplyAdditionalDiscount: null,
    hasAdditionalDiscount: null,
    commission: {
      type: null,
    },
    shippingAreaType: null,
    onlyParentProduct: null,
    customPropertyValueNos: null,
    needsAdditionalDiscountInfo: 'N',
  };
};
