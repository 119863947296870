








import { Vue, Component } from 'vue-property-decorator';
import PartnerRegisterTab from '@/components/partner/register/PartnerRegisterTab.vue';

@Component({
  components: { PartnerRegisterTab },
})
export default class PartnerRegisterSearchDetail extends Vue {}
