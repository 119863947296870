export default Object.freeze({
  TITLE: '접근 권한 없음',
  DISP1: '해당 페이지에 접근 권한이 없습니다.',
  DISP2: '운영자에게 문의 바랍니다.',
  DISP3: '감사합니다.',
  BTN_MAIN: '메인으로 이동',
  WITHDRAWAL_MEMBER: '탈퇴 회원입니다.',
  ADMIN_ACCOUNT_TITLE: '운영자 계정등록 삭제',
  ADMIN_ACCOUNT_DELETED_ACCOUNT: '관리자에 의해 계정등록이 삭제되었습니다.',
  ADMIN_ACCOUNT_PLZ_CHECK_ACCOUNT: '확인 후 재 신청 진행해 주시기 바랍니다.',
  ADMIN_ACCOUNT_THANK_YOU: '감사합니다.',
});
