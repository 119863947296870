import { RouteConfig } from 'vue-router';
import AuthLayout from '@/views/AuthLayout.vue';
import OauthCodeReceiver from '@/views/auth/OauthCodeReceiver.vue';

export default (): RouteConfig => {
  return {
    path: '/oauth',
    component: AuthLayout,
    children: [
      {
        path: 'redirect',
        name: 'OauthCodeReceiver',
        component: OauthCodeReceiver,
      },
    ],
    meta: {
      nonMember: true,
    },
  };
};
