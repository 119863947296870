






































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import CurrencyTextInput from '@/components/common/CurrencyTextInput.vue';
import {
  getSelectOptions,
  getShippingChargeRadioBoxOption,
  getShippingChargeSelectType,
  getTemplateNo,
  i18nForDeliveryGroupTemplate,
} from '@/const/contents/configuration/shippingCharge';
import {
  DeliveryTemplate,
  DeliveryTemplateSingle,
  PostDeliveryTemplateSingleRequest,
} from 'ncp-api-supporter/dist/types/modules/delivery/deliveryTemplate';
import { InputNumber, Mode, PartnerType } from '@/types';
import { MODE, PARTNER_TYPE } from '@/const/common';
import { pick } from '@/utils/common';
import { PopupClose, throwWindowPopup } from '@/helpers/popup';
import { Warehouse } from 'ncp-api-supporter/dist/types/modules/delivery/warehouses';

@Component({ components: { Currency: CurrencyTextInput, RadioGroup, TextInput, SelectBox } })
export default class DeliveryTemplateForm extends Vue {
  @Prop()
  private data: { mode: Mode; template: DeliveryTemplate; partnerType: PartnerType; partnerNo: number };
  @Prop()
  private onPositiveClick: (value?: number) => void;
  @Prop()
  private onNegativeClick: () => void;

  private get isEditMode(): boolean {
    return this.data.mode === MODE.EDIT;
  }

  private selectType = getShippingChargeSelectType();

  private radioOption = getShippingChargeRadioBoxOption();

  private i18nForDeliveryGroupTemplate = i18nForDeliveryGroupTemplate;

  private arrDeliveryCompany = [{ name: this.i18nForDeliveryGroupTemplate('DELIVERY_COMPANY'), value: '' }];

  private warehouses = [];

  private areaFee = [];

  // PostDeliveryTemplateSingleRequest['data'] 세부 입력 항목 InputNumber 로 사용해야 함
  private query: any = {
    template: {
      default: false,
      deliveryFee: {
        deliveryConditionType: '',
        deliveryAmt: '' as InputNumber,
        perOrderCnt: '' as InputNumber,
        deliveryConditionTypeLabel: '',
        returnDeliveryAmt: '' as InputNumber,
        deliveryFeeRanges: null,
        remoteAreaFeeConditionCheck: false, // boolean - 지역별 추가 배송비 무료 체크 여부 (NCP 는 현재 false 고정)
        criteria: 0 as InputNumber,
      },
      name: '',
      deliveryType: 'PARCEL_DELIVERY',
      returnWarehouseNo: 0,
      deliveryCompanyType: '' as InputNumber,
      releaseWarehouseNo: 0,
    },
    prepaid: true,
    usesAreaFee: false,
    areaFeeNo: 0,
    partnerNo: this.data.partnerNo,
  };

  private get isParcel(): boolean {
    return this.query.template.deliveryType !== 'DIRECT_DELIVERY';
  }

  private get deliveryTypeText(): string {
    if (this.isConditional) return this.i18nForDeliveryGroupTemplate('ORDER_AMT2');
    if (this.isFixedFee) return this.i18nForDeliveryGroupTemplate('ORDER_AMT4');
    return this.i18nForDeliveryGroupTemplate('EACH_DELIVERY_FEE');
  }

  private get isFree(): boolean {
    return this.query.template.deliveryFee.deliveryConditionType === 'FREE';
  }

  private get isFixedFee(): boolean {
    return this.query.template.deliveryFee.deliveryConditionType === 'FIXED_FEE';
  }

  private get isQuantityPropositionalFee(): boolean {
    return this.query.template.deliveryFee.deliveryConditionType === 'QUANTITY_PROPOSITIONAL_FEE';
  }

  private get isConditional(): boolean {
    return this.query.template.deliveryFee.deliveryConditionType === 'CONDITIONAL';
  }

  private get canSetDetails(): boolean {
    return this.isConditional || this.isFixedFee || this.isQuantityPropositionalFee;
  }

  private get deliveryConditionTypes(): { name: string; value: string }[] {
    //파트너사는 "수량비례" 미노출
    if (this.data.partnerType === PARTNER_TYPE.PARTNER) {
      return this.selectType.deliveryConditionTypeEditPopup.filter(type => type.value !== 'QUANTITY_PROPOSITIONAL_FEE');
    }
    return this.selectType.deliveryConditionTypeEditPopup;
  }

  private isValid(value: InputNumber, isOver = false): boolean {
    if (value === '') return false;

    if (isOver) {
      return value > 0;
    }
    return value >= 0;
  }

  private get isValidDeliveryAmt(): boolean {
    return this.isValid(this.query.template.deliveryFee.deliveryAmt);
  }

  private onChangeDeliveryConditionType(value) {
    if (value !== 'FREE') {
      this.query.template.deliveryFee.criteria = 0;
      this.query.template.deliveryFee.deliveryAmt = '';
    }
  }

  created() {
    this.getDeliveryCompanies();
    this.getWarehouses();
    this.getAreafees();
  }

  private mounted() {
    if (this.isEditMode) {
      this.setEditTemplateData();
    }
  }

  private setEditTemplateData() {
    this.query.template = {
      ...this.query.template,
      ...pick(this.data.template, Object.keys(this.query.template)),
    };
    this.query.prepaid = this.data.template.prepaid;
    this.query.usesAreaFee = this.data.template.usesAreaFee;
    this.query.areaFeeNo = getTemplateNo(this.data.template.areaFeeNo);
    this.query.template.name = this.data.template.templateName;
    this.query.template.returnWarehouseNo = getTemplateNo(this.data.template.returnWarehouse.warehouseNo);
    this.query.template.releaseWarehouseNo = getTemplateNo(this.data.template.releaseWarehouse.warehouseNo);
  }

  private getDeliveryCompanies(): void {
    this.$api.getDeliveryCompanies({ params: { countryCd: 'KR' } }).then(response => {
      if (response && response.status === 200) {
        response.data.map(d => {
          this.arrDeliveryCompany.push({ name: d.label, value: d.deliveryCompanyType });
        });
      }
    });
  }

  private setInitWarehouses(defaultReleaseWarehouse: number, defaultReturnWarehouse: number) {
    const { returnWarehouseNo, releaseWarehouseNo } = this.query.template;

    if (!returnWarehouseNo || !releaseWarehouseNo) {
      this.query.template.releaseWarehouseNo = defaultReleaseWarehouse;
      this.query.template.returnWarehouseNo = defaultReturnWarehouse;
    }
  }

  private setWarehouses(totalCount: number, contents: Warehouse[]) {
    this.warehouses = getSelectOptions().warehouses;
    let defaultReleaseWarehouse = 0;
    let defaultReturnWarehouse = 0;
    contents.map(w => {
      if (totalCount > 0) {
        if (w.defaultReleaseWarehouse) {
          defaultReleaseWarehouse = w.warehouseNo;
        }
        if (w.defaultReturnWarehouse) {
          defaultReturnWarehouse = w.warehouseNo;
        }
      }
      this.warehouses.push({ name: w.summary, value: w.warehouseNo });
    });
    this.setInitWarehouses(defaultReleaseWarehouse, defaultReturnWarehouse);
  }
  private getWarehouses(): void {
    this.$api
      .getWarehouses()
      .then(({ status, data }) => {
        if (status === 200) {
          this.setWarehouses(data.totalCount, data.contents);
        }
      })
      .catch();
  }

  private getAreafees(): void {
    this.$api.getAreafees({ params: { page: 1, size: 10000, partnerNo: this.data.partnerNo } }).then(response => {
      if (response && response.status === 200) {
        this.areaFee = getSelectOptions().areaFee;
        response.data.contents.map(a => {
          this.areaFee.push({ name: a.name, value: a.areaFeeNo });
        });
        if (!this.isEditMode && this.areaFee.length > 1) {
          this.query.areaFeeNo = this.areaFee[1].value;
        }
      }
    });
  }

  private templateNo = 0;
  private postDeliveryTemplateSingle(request: PostDeliveryTemplateSingleRequest): Promise<boolean> {
    return this.$api
      .postDeliveryTemplateSingle(request)
      .then(response => {
        if (response && response.status === 200) {
          this.templateNo = response.data.templateNo;
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  }

  private addAreaFee(): void {
    // 지역별 추가 배송비 새로등록
    throwWindowPopup(
      'ShippingChargeAreaFeePopup',
      { mode: MODE.REGISTER, partnerType: this.data.partnerType, partnerNo: this.data.partnerNo },
      'lg',
      ({ state, data }) => {
        if (state === PopupClose.CONFIRM) {
          this.getAreafees();
          this.$set(this.query, 'areaFeeNo', data.areaFeeNo);
        }
      },
    );
  }

  private addWarehouse(warehouseTypeName: 'releaseWarehouseNo' | 'returnWarehouseNo'): void {
    // 출고지, 반품/교환지 새로등록
    throwWindowPopup('WarehouseFormPopup', { type: 'add' }, 'lg', ({ state, data }) => {
      if (state === PopupClose.CONFIRM) {
        this.getWarehouses();
        this.$set(this.query.template, warehouseTypeName, data.warehouseNo);
      }
    });
  }

  private validate(): boolean {
    if (!this.query.template.name) {
      // 배송비 템플릿
      alert(this.i18nForDeliveryGroupTemplate('PLZ_DELIVERY_TEMPLATE_NAME'));
      (this.$refs.name as HTMLElement).focus();
      return false;
    }

    if (!this.query.template.deliveryFee.deliveryConditionType) {
      // 배송비 유형
      alert(this.i18nForDeliveryGroupTemplate('PLZ_DELIVERY_FEE_TYPE'));
      (this.$refs.deliveryConditionType as HTMLElement).focus();
      return false;
    }

    if (this.isConditional && !this.isValid(this.query.template.deliveryFee.criteria)) {
      // 조건부 무료
      alert(this.i18nForDeliveryGroupTemplate('PLZ_DELIVERY_FEE'));
      (this.$el.querySelector('#criteria') as HTMLElement).focus();
      return false;
    }

    if (this.isQuantityPropositionalFee && !this.isValid(this.query.template.deliveryFee.perOrderCnt, true)) {
      // 수량비례
      alert(this.i18nForDeliveryGroupTemplate('NOTICE_QUANTITY_PROPOSITIONAL_FEE_UNIT'));
      (this.$el.querySelector('#perOrderCnt') as HTMLElement).focus();
      return false;
    }

    if (!this.isFree && !this.isValidDeliveryAmt) {
      // 배송비
      alert(this.i18nForDeliveryGroupTemplate('PLZ_DELIVERY_FEE'));
      (this.$el.querySelector('#deliveryAmt') as HTMLElement).focus();
      return false;
    }

    if (!this.query.template.releaseWarehouseNo) {
      alert(this.i18nForDeliveryGroupTemplate('PLZ_WAREHOUSE_AREA'));
      (this.$refs.releaseWarehouseNo as HTMLElement).focus();
      return false;
    }

    if (!this.query.template.returnWarehouseNo) {
      alert(this.i18nForDeliveryGroupTemplate('PLZ_RETURN_DELIVERY_AREA'));
      (this.$refs.returnWarehouseNo as HTMLElement).focus();
      return false;
    }

    if (this.query.template.deliveryFee.returnDeliveryAmt === '') {
      alert(this.i18nForDeliveryGroupTemplate('PLZ_RETURN_DELIVERY_FEE'));
      (this.$refs.returnDeliveryAmt as HTMLElement).focus();
      return false;
    }

    return true;
  }

  private save(): void {
    if (!this.validate()) {
      return;
    }
    const request: PostDeliveryTemplateSingleRequest = {
      data: {
        ...this.query,
        template: {
          ...this.query.template,
          deliveryFee: {
            ...this.query.template.deliveryFee,
          },
        },
      },
    };
    this.isEditMode ? this.setEditRequest(request) : this.setPostRequest(request);
  }

  private setPostRequest(request) {
    if (!this.isParcel || !request.data.template.deliveryCompanyType) {
      delete request.data.template.deliveryCompanyType;
    }

    if (this.query.template.deliveryFee.deliveryConditionType === 'FREE') {
      delete request.data.template.deliveryFee.deliveryAmt;
      delete request.data.template.deliveryFee.perOrderCnt;
      delete request.data.template.deliveryFee.criteria;
    } else if (this.isConditional) {
      delete request.data.template.deliveryFee.perOrderCnt;
    } else if (this.isFixedFee) {
      delete request.data.template.deliveryFee.perOrderCnt;
      delete request.data.template.deliveryFee.criteria;
    } else if (this.isQuantityPropositionalFee) {
      delete request.data.template.deliveryFee.criteria;
    }

    if (!this.query.usesAreaFee || !this.query.areaFeeNo) {
      delete request.data.areaFeeNo;
    }

    this.postDeliveryTemplateSingle(request).then(success => {
      if (success) {
        alert(this.i18nForDeliveryGroupTemplate('SAVED'));
        this.onPositiveClick(this.templateNo);
      }
    });
  }

  private setEditRequest(request: PostDeliveryTemplateSingleRequest) {
    const data: DeliveryTemplateSingle = {
      ...request.data.template,
      templateNo: this.data.template.templateNo,
      templateGroupNo: this.data.template.templateGroupNo,
      areaFeeNo: this.query.areaFeeNo,
      usesAreaFee: this.query.usesAreaFee,
      prepaid: this.query.prepaid,
    };
    if (data.deliveryCompanyType === '') {
      data.deliveryCompanyType = null;
    }

    this.$api
      .putDeliveryTemplateSingle({ data })
      .then(response => {
        if (response && response.status === 204) {
          alert(this.$t('ALERT_UPDATED'));
          this.onPositiveClick();
        }
      })
      .catch(() => {
        return false;
      });
  }
}
