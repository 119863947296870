import { SalesInfoCalculatorErrorInfo } from '@/types';
import {
  DiscountType,
  ErrorCode,
  ErrorType,
} from '@/views/contents/product/basic/ProductAdd/salesInfo/calculator/SalesInfoCalculator';

export class SalesInfoCalculatorError extends Error {
  public errorInfo: SalesInfoCalculatorErrorInfo;
  constructor(errorInfo: SalesInfoCalculatorErrorInfo) {
    super();
    this.errorInfo = errorInfo;
  }
}

export function salePriceValidator(price: number, optionPrice: number, discountAmount: number): void {
  if (price % 10 !== 0) {
    throw new SalesInfoCalculatorError({ type: ErrorType.INLINE, code: ErrorCode.INVALID_UNIT });
  }
  if (optionPrice && price < Number(discountAmount) + optionPrice) {
    throw new SalesInfoCalculatorError({
      type: ErrorType.ALERT,
      msg: 'PRODUCT.ADD.ALERT_MIN_PRICE_WITH_OPTION_PRICE',
    });
  }
  if (price < Number(discountAmount)) {
    throw new SalesInfoCalculatorError({ type: ErrorType.ALERT, msg: 'PRODUCT.ADD.ALERT_MIN_PRICE' });
  }
}

export function discountAmountValidator({
  discount,
  type,
  optionPrice,
  maxDiscountAmount,
  salePrice,
  maxDiscountRate,
}): void {
  function validateDiscountWon(): void {
    if (discount % 10 !== 0) {
      throw new SalesInfoCalculatorError({ type: ErrorType.INLINE, code: ErrorCode.INVALID_UNIT });
    }
    if (optionPrice && discount > maxDiscountAmount) {
      throw new SalesInfoCalculatorError({
        type: ErrorType.ALERT,
        msg: 'PRODUCT.ADD.ALERT_MAX_DISCOUNT_PRICE_WITH_OPTION_PRICE',
      });
    }
    if (discount > salePrice) {
      throw new SalesInfoCalculatorError({ type: ErrorType.ALERT, msg: 'PRODUCT.ADD.ALERT_MAX_DISCOUNT_PRICE' });
    }
  }

  function validateDiscountPercentage(): void {
    if (discount < 0 || discount > 100) {
      throw new SalesInfoCalculatorError({ type: ErrorType.INLINE, code: ErrorCode.INVALID_RANGE });
    }
    if (discount > maxDiscountRate) {
      throw new SalesInfoCalculatorError({
        type: ErrorType.ALERT,
        msg: 'PRODUCT.ADD.ALERT_MAX_DISCOUNT_PRICE_WITH_OPTION_PRICE',
      });
    }
  }

  type === DiscountType.WON ? validateDiscountWon() : validateDiscountPercentage();
}

export function commissionRateValidator(commission: number): void {
  if (commission < 0) {
    throw new SalesInfoCalculatorError({ type: ErrorType.INLINE, code: ErrorCode.INVALID_RANGE });
  }
  if (commission > 99.9) {
    throw new SalesInfoCalculatorError({ type: ErrorType.ALERT, msg: 'PRODUCT.ADD.ALERT_RANGE_MSG_FOR_COMMISSION' });
  }
}
