











































































































import Grid from '@/components/common/grid/Main.vue';
import { Component, Watch } from 'vue-property-decorator';
import WindowPopupMainVue from '@/views/popups/Main.vue';
import { getCouponPayStatusGridOption, ISSUE_STATUS } from '@/const/contents/couponManagement';
import { GetCouponsIssuePlansSummaryRequset, GetCouponsIssueRequset, CouponIssueContent } from 'ncp-api-supporter';
import { GridEventProps, IssueCouponQuery, IssueStatusType } from '@/types';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import { PopupClose, throwPopup, throwExternalWindowPopup } from '@/helpers/popup';
import { uniqBy } from '@/utils/common';

@Component({
  components: {
    Grid,
    ToolTip,
  },
})
export default class CouponPayStatusPopup extends WindowPopupMainVue {
  private issueContent = [];
  private gridProps = getCouponPayStatusGridOption();
  private coupons = [];
  private mallName = '';
  private issueReason = '';
  private no = '';
  private couponIssueTimeTypeName = '';
  private couponIssueTimeType = '';
  private couponIssueTargetType = '';
  private issueYmdt = '';
  private periodStartYmdt = '';
  private periodEndYmdt = '';
  private payTarget = '';
  private issueCount = 0;
  private issueFailCount = 0;
  private searchParams = {};
  private readonly issueStatus = ISSUE_STATUS;

  private get tooltipMsg(): string {
    return `<li>${this.$t('PROMOTION.COUPON_ISSUE.ISSUE_STATUS_NOTICE_1')}</li><li>${this.$t(
      'PROMOTION.COUPON_ISSUE.ISSUE_STATUS_NOTICE_2',
    )}</li>`;
  }

  private get isTotal(): boolean {
    const query = this.$route.query;
    return !Reflect.has(query, 'issueFail') || query.issueFail === 'null';
  }

  private get issueFail(): boolean | null {
    return this.isTotal ? null : this.$route.query.issueFail === 'true';
  }

  private get searchedTotalCount(): number {
    return this.isTotal ? this.data.totalIssueCount : this.issueFail ? this.issueFailCount : this.issueCount;
  }

  created() {
    this.search(1, 30, null);
  }

  @Watch('$route')
  private onQueryStringChanged() {
    const query = (this.$route.query as any) as IssueCouponQuery;
    this.search(Number(query.page), Number(query.size), this.issueFail);
  }

  private search(page = 1, size: number, issueFail: null | boolean): void {
    if (this.coupons.length === 0) {
      const couponIssue: GetCouponsIssuePlansSummaryRequset = {
        pathParams: {
          couponIssuePlanNo: this.data.couponIssuePlanNo.toString(),
        },
      };
      this.searchParams = couponIssue;
      this.$api.getCouponsIssuePlansSummary(couponIssue).then(async res => {
        this.mallName = res.data.mallName;
        this.no = res.data.no.toString();
        this.couponIssueTimeType = res.data.couponIssueTimeType;
        this.couponIssueTargetType = res.data.couponIssueTargetType;
        this.issueYmdt = res.data.issueYmdt;
        this.issueCount = res.data.issueCount;
        this.issueFailCount = res.data.issueFailCount;
        this.periodStartYmdt = res.data.periodStartYmdt;
        this.periodEndYmdt = res.data.periodEndYmdt;
        this.couponIssueTimeTypeName = this.getCouponIssueTimeType(res.data.couponIssueTimeType);
        res.data.coupons.forEach(item => {
          this.coupons.push(item.no);
        });
      });

      this.handleGetCouponIssue(page, size, issueFail);
    } else {
      this.handleGetCouponIssue(page, size, issueFail);
    }
  }

  private queryExcel = {};
  private handleGetCouponIssue(page = 1, size = 30, issueFail: null | boolean): void {
    const couponIssueRequest: GetCouponsIssueRequset = {
      params: {
        couponNos: this.coupons.join(','),
        couponIssuePlanNo: Number(this.data.couponIssuePlanNo.toString()),
        sortType: 'COUPON_ISSUE_NO',
        issueFail,
        page,
        size,
      },
    };
    couponIssueRequest.params.page = page;
    couponIssueRequest.params.size = size;
    couponIssueRequest.params.issueFail = issueFail;
    this.queryExcel = couponIssueRequest.params;
    this.$api.getCouponsIssue(couponIssueRequest).then(res => {
      this.issueContent = [];
      this.issueContent = res.data.contents;

      this.issueReason =
        res.data.contents[0].adminIssueReason == undefined || res.data.contents[0].adminIssueReason == ''
          ? '-'
          : res.data.contents[0].adminIssueReason;

      this.payTarget = this.getPayTarget(this.couponIssueTimeType, res.data.contents, this.couponIssueTargetType);
    });
  }

  private getCouponIssueTimeType(data): string {
    let returnValue = '';
    switch (data.toString()) {
      case 'IMMEDIATELY_ISSUE':
        returnValue = '즉시지급';
        break;
      case 'SCHEDULED_ISSUE':
        returnValue = '예약지급';
        break;
      case 'PERIOD_ISSUE':
        returnValue = '자동지급';
        break;
    }
    return returnValue;
  }

  private getPayTarget(data: string, contents: CouponIssueContent[], couponIssueTargetType: string): string {
    let returnValue = '';
    const memberNo = uniqBy(contents, 'memberNo');

    switch (data.toString()) {
      case 'IMMEDIATELY_ISSUE':
        returnValue = '직접 선택 (총 ' + memberNo.length.toString() + ' 명)';
        break;
      case 'SCHEDULED_ISSUE':
        returnValue = '직접 선택 (총 ' + memberNo.length.toString() + ' 명)';
        break;
      case 'PERIOD_ISSUE':
        if (couponIssueTargetType == 'BIRTHDAY') {
          returnValue = '생일 회원';
        } else {
          returnValue = '신규가입 회원';
        }

        break;
    }
    return returnValue;
  }
  private onItemClicked(gridProps: GridEventProps): void {
    switch (gridProps.columnName) {
      case 'memberNo':
        if (gridProps.instance.getValue(gridProps.rowKey, 'memberId').toString() === '탈퇴회원') {
          break;
        }
        throwExternalWindowPopup(
          `/pro/crm/${gridProps.instance.getValue(gridProps.rowKey, 'memberNo').toString()}`,
          'xlg',
        );
        break;
      case 'couponUseStatusType':
        throwPopup({
          name: 'CouponPayBack',
          data: { couponIssueNo: gridProps.instance.getValue(gridProps.rowKey, 'couponIssueNo').toString() },
        }).then(result => {
          if (result && result.state === PopupClose.CONFIRM) {
            this.search(1, 30, null);
          }
        });
        break;
    }
  }

  private getIssueFail(type: IssueStatusType): IssueCouponQuery['issueFail'] {
    return type === this.issueStatus.TOTAL ? 'null' : `${this.issueStatus.FAILURE === type}`;
  }
  private onCountClicked(type: IssueStatusType, count: number) {
    if (!count) return;
    const defaultQuery: IssueCouponQuery = {
      page: '1',
      size: '30',
      issueFail: this.getIssueFail(type),
    };
    this.$router
      .replace({
        query: {
          ...this.$route.query,
          ...defaultQuery,
        },
      })
      .catch(() => null);
  }
}
